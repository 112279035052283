<div class="panel count-panel count-panel-warning">
	<div class="card-block card-body py-0 px-3">
		<!-- body content -->
		<div class="row">
			<div class="col-sm-4 count-panel-icon p-0 text-center">
				<i class="{{ widget.widgetIcon }} fa-3x py-3" aria-hidden="true"></i>
			</div>
			<div class="col-sm-8 px-3 py-1 count-panel-text">
				<h6 class="mb-0">
					{{ widget.widgetName }}
				</h6>
				<p class="mb-0">
					<b *ngIf="isLoadingData">{{ "LOADING" | translate }}</b>

					<!-- <b *ngIf="!isLoadingData">{{displayNum}}</b> -->
					<ng-container *ngIf="!isLoadingData">
						<!-- <div class="container" *ngIf="isPresentUsed">
							<div>
								<b (click)="viewUsed()" tooltip="{{ 'REPORTS.REPORT_TOOLTIP_USED_TAGS' | translate }}"
									container="body" placement="bottom">{{used}}</b>
							</div>
							<div>
								<b (click)="view()" tooltip="{{ 'REPORTS.REPORT_TOOLTIP_TOTAL_TAGS' | translate }}"
									container="body" placement="bottom">({{total}})</b>
							</div>
						</div> -->
						<b (click)="view()"
							tooltip="{{ tooltip }}" container="body"
							placement="bottom">{{total}}</b>

					</ng-container>

				</p>
			</div>
		</div>
	</div>
</div>

<!-- popup templates -->
<!-- modal templates -->