<div class="py-2 px-0 mt-2">
  <ul class="col-sm-12 px-0 py-2 list-group mt-2">
    <li class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
        [ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'irn', 'custom-list-item-dark': selectedMenuItem != 'irn'}"
        (click)="sideItemClick('irn')">
      <h6 class="flaot-left ml-2 mb-0 sidebar-list-text">
        <i class="fal fa-inbox mx-2" aria-hidden="true"></i>
        {{ 'COMMISSIONING.COM_SIDEBAR_IRN' | translate }}
      </h6>
    </li>
    <li class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
        [ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'mcc', 'custom-list-item-dark': selectedMenuItem != 'mcc'}"
        (click)="sideItemClick('mcc')">
      <h6 class="flaot-left ml-2 mb-0 sidebar-list-text">
        <i class="far fa-wrench mx-2" aria-hidden="true"></i>
        {{ 'COMMISSIONING.COM_SIDEBAR_MC' | translate }}
      </h6>
    </li>
    <li class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
        [ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'otn', 'custom-list-item-dark': selectedMenuItem != 'otn'}"
        (click)="sideItemClick('otn')">
      <h6 class="flaot-left ml-2 mb-0 sidebar-list-text">
        <i class="far fa-screwdriver mx-2" aria-hidden="true"></i>
        {{ 'COMMISSIONING.COM_SIDEBAR_OTN' | translate }}
      </h6>
    </li>
    <li class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
        [ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'pcc', 'custom-list-item-dark': selectedMenuItem != 'pcc'}"
        (click)="sideItemClick('pcc')">
      <h6 class="flaot-left ml-2 mb-0 sidebar-list-text">
        <i class="far fa-plug mx-2" aria-hidden="true"></i>
        {{ 'COMMISSIONING.COM_SIDEBAR_PCC' | translate }}
      </h6>
    </li>
    <!--<li class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
        [ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'ccc', 'custom-list-item-dark': selectedMenuItem != 'ccc'}"
        (click)="sideItemClick('ccc')">
      <h6 class="flaot-left ml-2 mb-0 sidebar-list-text">
        <i class="fal fa-box-check mx-2" aria-hidden="true"></i>
        {{ 'COMMISSIONING.COM_SIDEBAR_CCC' | translate }}
      </h6>
    </li>
    <li class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
        [ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'stnptn', 'custom-list-item-dark': selectedMenuItem != 'stnptn'}"
        (click)="sideItemClick('stnptn')">
      <h6 class="flaot-left ml-2 mb-0 sidebar-list-text">
        <i class="fal fa-network-wired mx-2" aria-hidden="true"></i>
        {{ 'COMMISSIONING.COM_SIDEBAR_STNPTN' | translate }}
      </h6>
    </li>
    <li class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
        [ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'shc', 'custom-list-item-dark': selectedMenuItem != 'shc'}"
        (click)="sideItemClick('shc')">
      <h6 class="flaot-left ml-2 mb-0 sidebar-list-text">
        <i class="fas fa-hand-holding mx-2" aria-hidden="true"></i>
        {{ 'COMMISSIONING.COM_SIDEBAR_SHC' | translate }}
      </h6>
    </li>
    <li class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
    [ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'facility_acceptance', 'custom-list-item-dark': selectedMenuItem != 'facility_acceptance'}"
    (click)="sideItemClick('facility_acceptance')">
  <h6 class="flaot-left ml-2 mb-0 sidebar-list-text">
    <i class="fas fa-industry-alt mx-2" aria-hidden="true"></i>
    {{ 'COMMISSIONING.COM_SIDEBAR_FA' | translate }}
  </h6>
  </li>
  <li class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
    [ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'work_item', 'custom-list-item-dark': selectedMenuItem != 'work_item'}"
    (click)="sideItemClick('work_item')">
  <h6 class="flaot-left ml-2 mb-0 sidebar-list-text">
    <i class="fal fa-dolly-flatbed-alt mx-2" aria-hidden="true"></i>
    {{ 'COMMISSIONING.COM_SIDEBAR_WI' | translate }}
  </h6>
  </li>-->
    <li class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
      [ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'punchlist', 'custom-list-item-dark': selectedMenuItem != 'punchlist'}"
      (click)="sideItemClick('punchlist')">
      <h6 class="flaot-left ml-2 mb-0 sidebar-list-text">
        <i class="far fa-paste mx-2" aria-hidden="true"></i>
        {{ 'COMMISSIONING.COM_SIDEBAR_PUNCHLISTS' | translate }}
      </h6>
    </li>
    <li class="list-group-item border-0 rounded-0 list-item-bg px-0 py-3 list-cursor custom-list-item-dark"
      (click)="sideItemClick('checksheets')">
      <div class="sidebar-list-text ">
        <h6 class="flaot-left ml-2 mb-0 list-cursor sidebar-list-tex">
          <i class="far fa-ballot-check mx-2" aria-hidden="true"></i>
          {{ 'COMMISSIONING.COM_SIDEBAR_CS' | translate }}
          <i class="fa fa-chevron-down float-right mr-3" [ngClass]="{'animate-arrow-0': !isCSCollapsed, 'animate-arrow-180': isCSCollapsed}"
           aria-hidden="true"></i>
        </h6>
      </div>
    </li>
    <ul *ngIf="isCSCollapsed" class="col-sm-12 px-0 py-0 list-group rounded-0" id="csSubMenu">
      <li class="list-group-item rounded-0 border-0 px-0 py-2 list-item-bg list-cursor" [ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'managecs', 'sub-menu-item-list': selectedMenuItem != 'managecs'}"
				(click)="sideItemClick('managecs')">
					<h6 class="flaot-left mb-0 ml-4">
						<i class="fal fa-tools mx-2" aria-hidden="true"></i>
           {{ 'COMMISSIONING.COM_SIDEBAR_MANAGE_CS' | translate }}
					</h6>
			</li>
      <li class="list-group-item rounded-0 border-0 px-0 py-2 list-item-bg list-cursor" [ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'managechk', 'sub-menu-item-list': selectedMenuItem != 'managechk'}"
        (click)="sideItemClick('managechk')">
            <h6 class="flaot-left mb-0 ml-4">
              <i class="fal fa-tools mx-2" aria-hidden="true"></i>
            {{ 'COMMISSIONING.COM_SIDEBAR_MANAGE_CHK' | translate }}
            </h6>
      </li>
      <li class="list-group-item rounded-0 border-0 px-0 py-2 list-item-bg list-cursor" [ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'issuecs', 'sub-menu-item-list': selectedMenuItem != 'issuecs'}"
        (click)="sideItemClick('issuecs')">
          <h6 class="flaot-left mb-0 ml-4">
            <i class="fal fa-print mx-2" aria-hidden="true"></i>
          {{ 'COMMISSIONING.COM_SIDEBAR_ISSUE_CS' | translate }}
          </h6>
      </li>
      <li class="list-group-item rounded-0 border-0 px-0 py-2 list-item-bg list-cursor" [ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'receivecs', 'sub-menu-item-list': selectedMenuItem != 'receivecs'}"
        (click)="sideItemClick('receivecs')">
        <h6 class="flaot-left mb-0 ml-4">
          <i class="fal fa-hand-receiving mx-2" aria-hidden="true"></i>
        {{ 'COMMISSIONING.COM_SIDEBAR_RECEIVE_CS' | translate }}
        </h6>
      </li>
    </ul>
    <!--<li class="list-group-item border-0 rounded-0 list-item-bg px-0 py-3 list-cursor custom-list-item-dark"
    (click)="sideItemClick('utilities')">
    <div class="sidebar-list-text ">
      <h6 class="flaot-left ml-2 mb-0 list-cursor sidebar-list-tex">
        <i class="far fa-ballot-check mx-2" aria-hidden="true"></i>
        {{ 'COMMISSIONING.COM_SIDEBAR_UTILITIES' | translate }}
        <i class="fa fa-chevron-down float-right mr-3" [ngClass]="{'animate-arrow-0': !isUtilitiesCollapsed, 'animate-arrow-180': isUtilitiesCollapsed}"
         aria-hidden="true"></i>
      </h6>
    </div>
  </li>
    <ul *ngIf="isUtilitiesCollapsed" class="col-sm-12 px-0 py-0 list-group rounded-0" id="utilitiesSubMenu">
      <li class="list-group-item rounded-0 border-0 px-0 py-2 list-item-bg list-cursor"
				[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'discipline', 'sub-menu-item-list': selectedMenuItem != 'discipline'}"
				(click)="sideItemClick('discipline')">
				<h6 class="flaot-left mb-0 ml-4">
					<i class="fas fa-business-time mx-2" aria-hidden="true"></i>
					{{ 'COMMISSIONING.COM_SIDEBAR_DISCIPLINE' | translate }}
				</h6>
			</li>
			<li class="list-group-item rounded-0 border-0 px-0 py-2 list-item-bg list-cursor"
				[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'functionCode', 'sub-menu-item-list': selectedMenuItem != 'functionCode'}"
				(click)="sideItemClick('functionCode')">
				<h6 class="flaot-left mb-0 ml-4">
					<i class="fas fa-code mx-2" aria-hidden="true"></i>
					{{ 'COMMISSIONING.COM_SIDEBAR_FUNCTION_CODE' | translate }}
				</h6>
			</li>
			<li class="list-group-item rounded-0 border-0 px-0 py-2 list-item-bg list-cursor"
				[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'assetGroups', 'sub-menu-item-list': selectedMenuItem != 'assetGroups'}"
				(click)="sideItemClick('assetGroups')">
				<h6 class="flaot-left mb-0 ml-4">
					<i class="fas fa-layer-group mx-2" aria-hidden="true"></i>
					{{ 'COMMISSIONING.COM_SIDEBAR_ASSET_GROUPS' | translate }}
				</h6>
			</li>
			<li class="list-group-item rounded-0 border-0 px-0 py-2 list-item-bg list-cursor"
				[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'valueList', 'sub-menu-item-list': selectedMenuItem != 'valueList'}"
				(click)="sideItemClick('valueList')">
				<h6 class="flaot-left mb-0 ml-4">
					<i class="fas fa-list-ol mx-2" aria-hidden="true"></i>
					{{ 'COMMISSIONING.COM_SIDEBAR_VALUE_LIST' | translate }}
				</h6>
			</li>
			<li class="list-group-item rounded-0 border-0 px-0 py-2 list-item-bg list-cursor"
				[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'vendor', 'sub-menu-item-list': selectedMenuItem != 'vendor'}"
				(click)="sideItemClick('vendor')">
				<h6 class="flaot-left mb-0 ml-4">
					<i class="fas fa-male mx-2" aria-hidden="true"></i>
					{{ 'COMMISSIONING.COM_SIDEBAR_VENDOR' | translate }}
				</h6>
			</li>
			<li class="list-group-item rounded-0 border-0 px-0 py-2 list-item-bg list-cursor"
				[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'phase', 'sub-menu-item-list': selectedMenuItem != 'phase'}"
				(click)="sideItemClick('phase')">
				<h6 class="flaot-left mb-0 ml-4">
					<i class="fas fa-moon mx-2" aria-hidden="true"></i>
					{{ 'COMMISSIONING.COM_SIDEBAR_PHASE' | translate }}
				</h6>
			</li>
    </ul>-->
  </ul>
</div>
