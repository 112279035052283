import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorage } from '../util/localstorage.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SessionValidationService } from '../user/login/session-validation-request.service';
import { WsCallback } from '../util/ws-callback.interface';
import { WsType } from '../util/ws-type';
import { WsResponse } from '../util/ws-response.model';
import { Openid } from '../user/login/openid.service';
import { RecentProject } from '../landing/recent-project.service';
import { LoggedUser } from '../user/logged-user.model';
import { UserVariable } from '../util/common/user-variable';
import { RouteUtil } from '../util/route-util';
@Component({
	selector: 'app-splash',
	templateUrl: './splash.component.html',
	styleUrls: ['./splash.component.css']
})
export class SplashComponent implements OnInit, WsCallback {
	private loggedInUser: LoggedUser;
	private TIMEOUT: number = 1000;
	private projectId: string = null;
	private clientId: string = null;
	private forwardUrl: string = null;

	constructor(private translate: TranslateService, private router: Router, public sessionValidationRequest: SessionValidationService, private openIdService: Openid,
		public recentProjectService: RecentProject, private route: ActivatedRoute) {

		//Load initial data
		if (localStorage.getItem(LocalStorage.GOOGLE_API_KEY) == null || localStorage.getItem(LocalStorage.GOOGLE_CLIENT_ID) == null 
			|| localStorage.getItem(LocalStorage.AUTH0_CONFIGURATIONS)) {
			openIdService.getGoogleAPIKeys(this);
			openIdService.getAuth0Configurations(this);
		} else {
			//check the login validity
			this.doLogin();
		}
	}

	private doLogin() {
		if ((JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER)) != null) &&
			(JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER)).authtoken != null) && 
			(UserVariable.getUserToken() != null)) {
			//validate
			this.loggedInUser = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER));
			this.sessionValidationRequest.validateSession(this);
		} else {
			RouteUtil.forward(this.router, 'login', true, this.route);
		}
	}

	ngOnInit() {}

	onSuccess(data: WsResponse, serviceType: WsType) {
		if (serviceType == WsType.SESSION_VALIDATION) {
			this.route.paramMap.subscribe(params => {
				if (params.get('fwd') != null) {
					if (this.checkFwdParameters(params)) {
						this.getProject();
					} else {
						setTimeout(() => this.router.navigateByUrl(params.get('fwd')), this.TIMEOUT);
					}
				} else {
					this.recentProjectService.getDefaultRecentProject(this.loggedInUser.userId.toString(), this);
				}
			});
		} else if (serviceType == WsType.GET_GOOGLE_KEYS) {
			//save data in the local storage
			var payload = JSON.parse(data.payload);
			localStorage.setItem(LocalStorage.GOOGLE_API_KEY, payload.key);
			localStorage.setItem(LocalStorage.GOOGLE_CLIENT_ID, payload.clientid);
			//Reload the page
			//window.location.reload();
			this.doLogin();
		} else if (serviceType == WsType.GET_DEFAULT_RECENT_PROJECT) {
			if (data.payload != null) {
				//There is a default project
				if(data.payload.isDefaultProject == '1'){
					localStorage.setItem(LocalStorage.DEFAULT_PROJECT_ID, data.payload.projectId);
					localStorage.setItem(LocalStorage.DEFAULT_PROJECT_TYPE, '1');
					localStorage.setItem(LocalStorage.DEFAULT_PROJECT_NAME, data.payload.projectName);
					localStorage.setItem(LocalStorage.DEFAULT_CLIENT_ID, data.payload.clientId);
				}
				sessionStorage.setItem(LocalStorage.PROJECT_TYPE, '1');
				localStorage.removeItem(LocalStorage.PROJECT_NAME);
				UserVariable.clientId = data.payload.clientId;
				UserVariable.projectId = data.payload.projectId;
				UserVariable.projectName = data.payload.projectName;
				RouteUtil.forward(this.router, 'home', true, this.route);
			} else {
				//There is no default project
				//setTimeout(() => this.router.navigateByUrl('landing'), this.TIMEOUT);
				RouteUtil.forward(this.router, 'landing', true, this.route);
			}
		} else if (serviceType == WsType.GET_PROJECT) {
			if (data.payload != null){
				this.changeProject(data.payload)
			} else {
				console.log("Project not found. Navigate to landing")
				this.router.navigateByUrl('landing');
			}
		} else if (serviceType == WsType.ADD_UPDATE_RECENT_PROJECT){
			console.log("update recent project success")
		} else if (serviceType == WsType.GET_AUTH0_CONFIGURATIONS) {
			//save data in the local storage
			var payload = JSON.parse(data.payload);
			localStorage.setItem(LocalStorage.AUTH0_CONFIGURATIONS, JSON.stringify(payload));
			//Reload the page
			this.doLogin();
		}
	}
	onFail(data: WsResponse, serviceType: WsType) {
		if (serviceType == WsType.SESSION_VALIDATION) {
			RouteUtil.forward(this.router, 'login', true, this.route);
		} else if (serviceType == WsType.GET_GOOGLE_KEYS) {
			RouteUtil.forward(this.router, 'login', true, this.route);
		} else if (serviceType == WsType.GET_DEFAULT_RECENT_PROJECT) {
			localStorage.removeItem(LocalStorage.PROJECT_NAME);
		} else if (serviceType == WsType.GET_PROJECT){
			console.log('failed to get requested project')
			this.router.navigateByUrl('landing');
		} else if (serviceType == WsType.ADD_UPDATE_RECENT_PROJECT){
			console.log('update recent project failed')
		} else if (serviceType == WsType.GET_AUTH0_CONFIGURATIONS) {
			RouteUtil.forward(this.router, 'login', true, this.route);
		}
	}

	/**
	 * check url params to change project
	 * @param params 
	 */
	checkFwdParameters(params: any): boolean{
		let needToChangeProject: boolean = false
		let fwd = params.get('fwd')
		console.log('fwd= '+ fwd)
		var urlParams = new URLSearchParams(fwd);
		this.projectId = urlParams.get("projectId");
		if (this.projectId == null) {
			this.projectId = urlParams.get("projectid");
		}
		this.clientId = urlParams.get("clientId");
		if (this.clientId == null) {
			this.clientId =urlParams.get("clientid");
		}
		
		if ((this.clientId != null) && (this.projectId != null)) {
			this.forwardUrl = fwd;
			needToChangeProject = true;
		} else {
			needToChangeProject = false;
		}
		return needToChangeProject;
	}

	/**
	 * get project data to load
	 */
	getProject(){
		UserVariable.projectId = this.projectId;
		UserVariable.clientId = this.clientId;
		let token = UserVariable.getUserToken()
		this.recentProjectService.getProject(token, this)
	}

	/**
	 * change project and forward to requested page 
	 * @param projectData 
	 */
	changeProject(projectData: any){
		UserVariable.projectId = projectData.id;
		UserVariable.clientId = projectData.clientId;
		UserVariable.projectName = projectData.projectName;
		var recentProjectModel = {
			projectId: projectData.id,
			userId: UserVariable.userId,
			isDefaultProject: 0,
			projectType: 1
		}
		localStorage.setItem(LocalStorage.CLIENT_NAME, projectData.clientName);
		localStorage.setItem(LocalStorage.PROJECT_NAME, projectData.projectName);
		localStorage.setItem(LocalStorage.PROJECT_TYPE, '1');
		sessionStorage.setItem(LocalStorage.PROJECT_TYPE, '1');
		localStorage.setItem(LocalStorage.PROJECT_ID, UserVariable.projectId);
		sessionStorage.setItem(LocalStorage.PROJECT_ID, UserVariable.projectId);
		localStorage.setItem(LocalStorage.CLIENT_ID, projectData.clientId);
		this.recentProjectService.addUpdateRecentProject(JSON.stringify(recentProjectModel), this);

		setTimeout(() => this.router.navigateByUrl(this.forwardUrl), this.TIMEOUT);
	}

}
