import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorage } from '../../util/localstorage.service';
import { TranslateService } from '@ngx-translate/core';
import { UserVariable } from '../../util/common/user-variable';
import { AlertService } from '../../util/alert/alert.service';
import { SharedService } from '../../util/shared.service';
import { GridOptions, Module, AllModules } from "@ag-grid-enterprise/all-modules";
import { ProcedureStoreService } from './procedurestore.service';
import { CapabilityService } from '../../util/capability.service'
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PSDeleteButtonRender } from '../procedurestore/cell-render/delete-button/delete-button.component'
import { LoadingService } from '../../util/loading/loading.service';

@Component({
   selector: 'app-procedurestore',
   templateUrl: './procedurestore.component.html',
   styleUrls: ['./procedurestore.component.css']
})
export class ProcedureStoreComponent implements OnInit {
   public isMinimized: boolean = false
   public rowData: any[] = [];
   private procedureData: any[] = [];
   private gridApi;
   private gridColumnApi;
   public columnDefs;
   public customColumn;
   public ps_translated_data: any = null;
   public frameworkComponents;
   public gridOptions: GridOptions;
   public defaultColDef: any;
   public projectId: string = UserVariable.projectId
   public modifyStoreAvailability: boolean = false;
   public deleteProcedureStore: boolean = false;
   public addProcedureToStore: boolean = false;
   public deleteProcedureFromStore: boolean = false;
   public viewProcedureInstanceOutputList
   public title: string = '';
   public displayModel: boolean = false;
   public bsModalRef: BsModalRef;
   public facilityList: Array<any> = []
   public selectedFacility: Array<any> = [{ 'id': 'all', 'text': 'ALL' }, { 'id': 'none', 'text': 'None' }]
   public facilityId: string;
   public modules: Module[] = AllModules;

   constructor(private procedureStoreService: ProcedureStoreService, private sharedService: SharedService, private translate: TranslateService, private alertService: AlertService,
      private capabilityService: CapabilityService, private modalService: BsModalService, private loadingService: LoadingService, private router: Router) {

      this.modifyStoreAvailability = this.capabilityService.isCapabilityAssigned("setAvailabilityProcedureStore");
      this.deleteProcedureStore = this.capabilityService.isCapabilityAssigned("deleteProcedureFromStore");
      this.addProcedureToStore = this.capabilityService.isCapabilityAssigned("addProcedureToStore");
      this.deleteProcedureFromStore = this.capabilityService.isCapabilityAssigned("deleteProcedureFromStore");
      this.viewProcedureInstanceOutputList = this.capabilityService.isCapabilityAssigned("viewProcedureInstanceOutputList")

      let browserLang = translate.getBrowserLang();
      this.gridOptions = <GridOptions>{
         context: {
            componentParent: this
         }
      };
      this.defaultColDef = {
			resizable: true,
			sortable: true,
		};
      translate.getTranslation(browserLang).subscribe((res: string) => {
         this.ps_translated_data = res['PROCEDURE_STORE']

         this.columnDefs = [
            {
               headerName: this.ps_translated_data.PROCEDURE, field: 'name', autoHeight: true, cellClass: "cell-wrap-text"
            },
            {
               headerName: this.ps_translated_data.SYSTEM, field: 'systemName', autoHeight: true, cellClass: "cell-wrap-text"
            },
            {
               headerName: this.ps_translated_data.FACILITY, field: 'facilityName', autoHeight: true, cellClass: "cell-wrap-text"
            },
            {
               headerName: this.ps_translated_data.VERSION, field: 'storeVersion', autoHeight: true, cellClass: "cell-wrap-text",
            },
            {
               headerName: this.ps_translated_data.NOTES, field: 'content', autoHeight: true, cellClass: "cell-wrap-text"
            },

         ]
         if (this.modifyStoreAvailability) {
            this.columnDefs.push({
               headerName: this.ps_translated_data.AVAILABLE, field: 'storeAvailability', autoHeight: true, cellClass: "cell-wrap-text", width: 85,
               cellRenderer: function (params) {
                  var eDiv = document.createElement('div');
                  var chkVal;
                  if (params.data.storeAvailability == 1) {
                     eDiv.innerHTML = '<input type="checkbox" id="chkAvailability" procedureId=' + params.data.id + ' procedureStoreId= ' + params.data.procedureStoreId + ' checked>';
                  } else {
                     eDiv.innerHTML = '<input type="checkbox" id="chkAvailability" procedureId=' + params.data.id + ' procedureStoreId= ' + params.data.procedureStoreId + '>';
                  }
                  var eButton = eDiv.querySelectorAll("[type='checkbox']")[0];
                  eButton.addEventListener('click', function () {
                     loadingService.showLoading(true, null, "", null)
                     if (this.checked) {
                        chkVal = "on";
                     } else {
                        chkVal = "off";
                     }
                     procedureStoreService.updateProcedureStore(params.data.id, chkVal, params.data.procedureStoreId)
                        .subscribe(data => {
                           procedureStoreService.tableReloadRequest(params.data.id, params.data.procedureStoreId, "updateProcedureStore")
                           // loadingService.hideLoading()
                           alertService.success(data.statusDescription)
                        },
                           error => {
                              procedureStoreService.tableReloadRequest(params.data.id, params.data.procedureStoreId, "updateProcedureStore")
                              // loadingService.hideLoading()
                              alertService.error(error.statusDescription)
                           });
                  });
                  return eDiv;
               }

            })
         } else {
            this.columnDefs.push({
               headerName: this.ps_translated_data.AVAILABLE, field: 'storeAvailability', autoHeight: true, cellClass: "cell-wrap-text", width: 85,
               cellRenderer: function (params) {
                  var eDiv = document.createElement('div');
                  var chkVal
                  if (params.data.storeAvailability == 1) {
                     eDiv.innerHTML = '<input type="checkbox" procedureId=' + params.data.id + ' procedureStoreId= ' + params.data.procedureStoreId + ' checked disabled>';
                  } else {
                     eDiv.innerHTML = '<input type="checkbox" procedureId=' + params.data.id + ' procedureStoreId= ' + params.data.procedureStoreId + ' disabled>';
                  }
                  var eButton = eDiv.querySelectorAll("[type='checkbox']")[0];
                  eButton.addEventListener('click', function () {
                     loadingService.showLoading(true, null, "", null)
                     if (this.checked) {
                        chkVal = "on";
                     } else {
                        chkVal = "off";
                     }
                     procedureStoreService.updateProcedureStore(params.data.id, chkVal, params.data.procedureStoreId)
                        .subscribe(data => {
                           procedureStoreService.tableReloadRequest(params.data.id, params.data.procedureStoreId, "updateProcedureStore")
                           // loadingService.hideLoading()
                           alertService.success(data.statusDescription)
                        },
                           error => {
                              procedureStoreService.tableReloadRequest(params.data.id, params.data.procedureStoreId, "updateProcedureStore")
                              // loadingService.hideLoading()
                              alertService.error(error.statusDescription)
                           });

                  });
                  return eDiv;
               },
               hide:!this.modifyStoreAvailability

            })
         }
         this.columnDefs.push({
            headerName: this.ps_translated_data.PUBLISH_TIME, field: 'lastUpdateTime', autoHeight: true, cellClass: "cell-wrap-text"
         })
         this.columnDefs.push({
            headerName: this.ps_translated_data.IN_REVIEW, field: 'procedureStoreId', autoHeight: true, cellClass: "cell-wrap-text", width: 85,
            cellRenderer: function (params) {
               var eDiv = document.createElement('div');
               var chkVal
               if (params.data.inReview == 1) {
                  eDiv.innerHTML = '<input type="checkbox" procedureId=' + params.data.id + ' procedureStoreId= ' + params.data.procedureStoreId + ' checked disabled>';
               } else {
                  eDiv.innerHTML = '<input type="checkbox" procedureId=' + params.data.id + ' procedureStoreId= ' + params.data.procedureStoreId + ' disabled>';
               }
               var eButton = eDiv.querySelectorAll("[type='checkbox']")[0];
               eButton.addEventListener('onChange', function () {
                  loadingService.showLoading(true, null, "", null)
                  if (this.checked) {
                     chkVal = "on";
                  } else {
                     chkVal = "off";
                  }
                  procedureStoreService.updateInReviewProcedureStore(params.data.id, chkVal, params.data.procedureStoreId)
                     .subscribe(data => {
                        loadingService.hideLoading()
                        alertService.success(data.statusDescription)
                     },
                        error => {
                           loadingService.hideLoading()
                           alertService.error(error.statusDescription)
                        });

               });
               return eDiv;
            }
         })
         this.columnDefs.push({
            headerName: this.ps_translated_data.LOCATION, field: 'folderPath', autoHeight: true, cellClass: "cell-wrap-text"

         })
         this.columnDefs.push({
            headerName: this.ps_translated_data.KEY_WORDS, field: 'keywords', autoHeight: true, cellClass: "cell-wrap-text"
         })

         this.columnDefs.push({
            headerName: this.ps_translated_data.DELETE, field: '', autoHeight: true, cellClass: "cell-wrap-text", width: 85, suppressFiltersToolPanel: true,
            cellRendererFramework: PSDeleteButtonRender, hide: !this.deleteProcedureStore
         })

      });
   }

   ngOnInit() {
      //this.getProcedureStoreByProjectId()
   }

   onColumnResized(event) {
      // if (event.finished) {
      //    this.gridApi.resetRowHeights();
      // }
   }

   onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      params.api.sizeColumnsToFit();
      this.getProcedureStoreByProjectId();
      setTimeout(function () {
         params.api.sizeColumnsToFit();
         params.api.resetRowHeights();
      }, 500);
      this.procedureStoreService.tableReloadRespond().subscribe(
         (option: any) => {
            console.log("reload table event= "+ option.event)
            if(this.facilityId==null){
               this.getProcedureStoreByProjectId();
            }
            else {
               this.getProceduresByFacility();
            }
         }
      )
   }

   public removedFacility(event) {
      this.selectedFacility = [{ 'id': 'all', 'text': 'ALL' }]
   }

   public selectedFacilityId(event) {
      this.facilityId = event.id
      this.getProceduresByFacility()
   }

   public getProceduresByFacility() {
      this.loadingService.showLoading(true, null, "", null)
      this.procedureStoreService.getProceduresByFacility(this.facilityId)
         .subscribe(data => {
            this.rowData = []
            this.procedureData = []
            if(data.payload != null){
               this.rowData = data.payload;
               this.gridApi.sizeColumnsToFit();
            }else{
               //nothing to do here
            }            
            this.loadingService.hideLoading()
         },
            error => {
               this.loadingService.hideLoading()
               this.alertService.clear()
               this.alertService.error(error.statusDescription)
            });
   }

   public getProcedureStoreByProjectId() {
      if (this.projectId != null && this.projectId != undefined) {
         this.loadingService.showLoading(true, null, "", null)
         this.procedureData = []
         this.procedureStoreService.getProcedureStoreByProjectId(this.projectId)
            .subscribe(data => {
               if (data.payload != null) {
                  data.payload.forEach(element => {
                     if (element.id != null) {
                        this.procedureData.push( element)
                     }
                  });
                  this.rowData = []
                  this.rowData = this.procedureData;
                  // this.gridApi.sizeColumnsToFit();
                  this.gridApi.refreshCells();
                  this.facilityList = []
                  data.payload.forEach(element => {
                     if (element.facilityList != null) {
                        for (let key of Object.keys(element.facilityList)) {
                           this.facilityList.push({ 'id': key, 'text': element.facilityList[key] })
                        }
                     }
                  });
                  this.facilityList.sort(this.compare);
                  this.facilityList.unshift({ 'id': 'all', 'text': 'ALL' }, { 'id': 'none', 'text': 'None' })
               } else {
                  //nothing to do here
               }
               this.loadingService.hideLoading()
            },
               error => {
                  this.rowData = []
                  this.loadingService.hideLoading()
                  this.alertService.clear()
                  this.alertService.error(error.statusDescription)
               });
      } else {
         this.router.navigateByUrl('/login')
      }

   }

   /*public addProcedureStore() {
      this.router.navigateByUrl('addProcedureToStore');
   }

   public deletedProcedureView() {
      this.router.navigateByUrl('deleteProcedureFromStore');
   }

   public viewProcedureInstance() {
      this.router.navigateByUrl('viewProcedureInstanceOutputList')
   }*/


   compare(a, b) {
      const facilityA = a.text.toUpperCase();
      const facilityB = b.text.toUpperCase();

      let comparison = 0;
      if (facilityA > facilityB) {
         comparison = 1;
      } else if (facilityA < facilityB) {
         comparison = -1;
      }
      return comparison;
   }
}