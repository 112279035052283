<div>
	<div class="py-2 px-0 mt-2">
		<ul class="col-sm-12 px-0 py-2 list-group mt-2">
			<li class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
				[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'reusableupdate', 'custom-list-item-dark': selectedMenuItem != 'reusableupdate'}"
				(click)="sideItemClick('reusableupdate')">
				<h6 class="flaot-left ml-2 mb-0 sidebar-list-text">
					<i class="far fa-list-alt mx-2" aria-hidden="true"></i>
					{{ 'REUSABLE_UPDATE.RU_SIDEBAR_REUSABE_UPDATE' | translate }}
				</h6>
			</li>
		</ul>
	</div>
</div>