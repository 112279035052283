import {Component, OnInit, ViewChild, ElementRef, TemplateRef, OnDestroy, HostListener, Renderer2} from '@angular/core';
import { SharedService } from '../util/shared.service';
import * as $ from 'jquery';
import { TranslateService } from '@ngx-translate/core';
import { ProcedureService } from './procedure.service';
import { LoadingService } from '../util/loading/loading.service';
import { AlertService } from '../util/alert/alert.service';
import { NCWRS, Step, Document, SubStep, GroupTitle, Location, DependencyStep, Content, ProcedureSystem, ProcType, ProcDesc, Assumption, Objective, Header, ProcImage, 
		ResponsibleParty, OperatingData } from './procedure.model';
import { OrderArrayByType } from '../util/orderarraybytype.pipe';
import { PreventParentClickEvent } from '../util/preventparentclick.directive';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TagInputModule } from 'ngx-chips';
import { Observable, Subscription, timer } from 'rxjs';
import { LocalStorage } from '../util/localstorage.service';
import { Module, AllModules } from "@ag-grid-enterprise/all-modules";
import { Constants } from '../util/constants';

declare var CKEDITOR: any;
var editor, tag_editor;

TagInputModule.withDefaults({
	tagInput: {
		placeholder: 'Add a new dependency',
		secondaryPlaceholder: 'Add a new dependency'
	}
});

@Component({
	selector: 'app-procedure',
	templateUrl: './procedure.component.html',
	styleUrls: ['./procedure.component.css']
})
export class ProcedureComponent implements OnInit, OnDestroy {
	selectedEleOld="";
	selectedEleNew:"";
	public divHeight: number = (window.innerHeight) * 4 / 5;
	// public mainDivheight: number = (window.innerHeight) * 4 / 5;
	@HostListener('window:resize', ['$event'])
	onresize(event?) {
		// console.log('[divHeight] ' + JSON.stringify(window.innerHeight));
		this.divHeight = (window.innerHeight) * 4 / 5
		// this.mainDivheight = (window.innerHeight) * 4 / 5
		const windowHeight = this.windowHeight;
		if (this.isBrowserFullScreen) {
			if ((event.target.innerHeight / windowHeight) <= 1) {
				this.heightFactor = (event.target.innerHeight) / windowHeight;
			} else {
				this.heightFactor = 1;
			}
		} else {
			this.heightFactor = window.outerHeight / window.screen.availHeight;
		}
		
	}
	@ViewChild('is_dep') is_dep: TemplateRef<any>;
	@ViewChild('delete_step') delete_step: TemplateRef<any>;
	@ViewChild('delete_substep') delete_substep: TemplateRef<any>;
	@ViewChild('conflict_checker') conflict_checker: TemplateRef<any>;
	@ViewChild('undo_checkout_warn') undo_checkout_warn: TemplateRef<any>;
	bsModalRef: BsModalRef;
	modalRef: BsModalRef;
	undocheckoutWarningModalRef: BsModalRef;

	private isFirefox: boolean = false;
	private isMobile: boolean = false;
	private touchTime = 0;
	private isDependency: boolean = false;
	public isCheckOut: boolean = false;
	public isCheckOutByOthers = false;
	private checkoutUserId: String = null;
	public showProHeaderDetail: boolean = true;
	public isExpandAll: boolean = true;
	public showHeaderImages: boolean = false;
	public isShowBadgeItem: number = -1
	public selectedItemStepNum: number = -1;
	public collapseExpandBtnText: string = ''
	private contentStr: string = ''
	private fileId: string;
	public userId: String;
	private fileType: String;
	// private tempSaveKey: string;
	public tabId: string;
	public toolbarId: string;
	private insertSubscription: any;
	private timerSubscription: any;
	pro_translated_data: any = null;
	public systemName: String;
	public proceType: String;
	public proceDesc: String;
	public folderPath: String;
	public ncwrList: Array<NCWRS>;
	public assList: Array<any> = [];
	public objList: Array<any> = [];
	public stepsList: Array<Step> = [];
	public dupSubstep: SubStep;
	public dupStep: Step;
	public rowData: any[] = [];

	private tagEditor: any;
	private index: number = 0;
	private stepIndex: number = 0;
	private gtNcWRIndex: number = 0;
	private ncwrIndex: number = 0;
	private docIndex: number = 0;
	private subStepIndex: number = 0;
	private subStepGtNcWRIndex: number = 0;
	private substepNcwrIndex: number = 0;
	private substepDocIndex: number = 0;

	private delStep: Step;
	private delSubstep: SubStep;
	private delIndex: number;
	private delId: number;
	private delContent: string;
	private html: string;
	private stmHtml: string;
	private tagContent: string = '';
	private selection: any;
	private ranges: any;

	private showImageStep: String = '';
	private showImageSubstep: String = '';
	private stepOfSubstep: String = '';
	public speCharList: Array<any> = [];
	public imageList: Array<ProcImage> = [];
	public imagePanelFlag: boolean = false;
	public editorList: Array<any> = [];
	public editorDivList: Array<any> = [];
	private guidWinT: String;
	public addFractionDIvId: String = "";
	public addSpecialCharDivId: String = "";
	public tabID: String = "";	
	public categoryNameList: Array<any> = [];
	public categoryName: string;
	private escapeAttrMap: Map<string, string> = new Map();
	public operatingDataList: Array<any> = [];

	public modules: Module[] = AllModules;
	private gridApi;
	public getRowNodeId;
	private gridColumnApi;
	private rowSelection;
	private susbscription: Subscription;
	public heightFactor: number;
	private windowHeight: number;
	public isBrowserFullScreen: boolean;
	private hasResuables: boolean;

	public optionList: Array<any> = [
		{ id: 'id_notice', text: 'Notice' },
		{ id: 'id_caution', text: 'Caution' },
		{ id: 'id_warning', text: 'Warning' },
		{ id: 'id_reference', text: 'Reference' },
		{ id: 'id_danger', text: 'Danger' },
		{ id: 'id_interlock', text: 'Interlock' },
		{ id: 'id_warningcell', text: 'WarningCell' },
	]

	public titleOptionList: Array<any> = [
		{ id: 'id_notice', text: 'Notice' },
		{ id: 'id_caution', text: 'Caution' },
		{ id: 'id_warning', text: 'Warning' },
		{ id: 'id_reference', text: 'Reference' },
	]

	public stepOptionList: Array<any> = [
		// { id: 'id_gt', text: 'Group Title' },
		// { id: 'id_substep', text: 'Sub Step' },
		{ id: 'id_notice', text: 'Notice' },
		{ id: 'id_caution', text: 'Caution' },
		{ id: 'id_warning', text: 'Warning' },
		{ id: 'id_reference', text: 'Reference' },
		// { id: 'id_location', text: 'Location' },
		// { id: 'id_document', text: 'Document' },
		// { id: 'id_dependency', text: 'Dependency' },
		// { id: 'id_duplicate', text: 'Duplicate' },
		{ id: 'id_danger', text: 'Danger' },
		{ id: 'id_interlock', text: 'Interlock' },
		{ id: 'id_warningcell', text: 'WarningCell' },
		{ id: 'id_sshecritical', text: 'SSH and E Critical Task' },
		{ id: 'id_safeguardcritical', text: 'Safeguard Critical Task' },
		{ id: 'id_independentverif', text: 'Independent Verification' },
	]

	public subStepOptionList: Array<any> = [
		// { id: 'id_gt', text: 'Group Title' },
		{ id: 'id_notice', text: 'Notice' },
		{ id: 'id_caution', text: 'Caution' },
		{ id: 'id_warning', text: 'Warning' },
		{ id: 'id_reference', text: 'Reference' },
		// { id: 'id_location', text: 'Location' },
		// { id: 'id_document', text: 'Document' },
		// { id: 'id_duplicate', text: 'Duplicate' },
		{ id: 'id_danger', text: 'Danger' },
		{ id: 'id_interlock', text: 'Interlock' },
		{ id: 'id_warningcell', text: 'WarningCell' },
		{ id: 'id_sshecritical', text: 'SSH and E Critical Task' },
		{ id: 'id_safeguardcritical', text: 'Safeguard Critical Task' },
		{ id: 'id_independentverif', text: 'Independent Verification' },
	]

	public actionMenuOptionList: Array<any> = [
		{ id: 'id_nothing', text: 'Nothing' },
		{ id: 'id_checkbox', text: 'Checkbox' },
	]

	public predefinedColors: string[] = Constants.COLOR_PALETTE_COLORS.split(',').map(color => `#${color}`);;

	private content: any;
	private xmlContent: string = '';
	private partialStm: string = '';
	private title: string = '';

	private tagEditorConfig = {
		autoParagraph: false,
		readOnly: false,
		removePlugins: 'toolbar, pastefromword, tableselection, uploadwidget, clipboard, pastetext, widget, uploadimage, emptytopicheader, topicheader, liststyle, magicline, scayt, image2, topicncwrs'
	};

	private inlineConfig = {
		toolbar: [
			{ name: 'clipboard', groups: ['clipboard', 'undo'], items: ['Cut', 'Copy',  '-', 'Undo', 'Redo'] },
			{ name: 'insert', groups: ['insert'], items: ['addsymbol', 'addfraction'] },
			//{ name: 'editing', groups: ['spellchecker'], items: ['Scayt'] },
			{ name: 'links', items: ['Link'] },
			'/',
			{ name: 'basicstyles', groups: ['basicstyles'], items: ['Bold', 'Italic', 'Underline', 'Subscript', 'Superscript'] },
			{ name: 'paragraph', groups: ['list'], items: ['NumberedList', 'BulletedList'] },
		],
		autoParagraph: false,
		readOnly: false,
		removePlugins: 'magicline, maximize, resize, wsc',
		pasteFilter: 'plain-text',
		extraPlugins: 'statementclickhandler,sharedspace, addsymbol, addfraction, disablepartialbui, reusablepanelhandler, deletezws, deletehandler',
		sharedSpaces: {
			top: 'toolbar_' + SharedService.getTabId(),
		},
		title: false,
		tabID: SharedService.getTabId(),
		enterMode: CKEDITOR.ENTER_BR,
		startupFocus: 'end'
	};

	public validators = [this.must_be_valid_depno];
	public errorMessages = {
		'must_be_number': 'Enter a valid number!',
		'must_not_be_itself': 'Cannot depend on itself!',
		'must_be_valid_step_number': 'Invalid step number!'
	};

	public columnDefs = [
		{ headerName: '', field: 'cbox', width: 50, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
		{ headerName: 'Type', field: 'type', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 100, enableRowGroup: true },
		{ headerName: 'Last Value', field: 'lastVal', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 100, enableRowGroup: true, tooltipField: "lastVal" },
		{ headerName: 'New Value', field: 'newVal', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 100, enableRowGroup: true, tooltipField: "newVal" },
		{ headerName: 'Id', field: 'id', hide: true },
		{ headerName: 'Name', field: 'name', hide: true },
		{ headerName: 'LastUpdateTimeStamp', field: 'lastUpdateTimeStamp', hide: true },
		{ headerName: 'isDeleted', field: 'isDeleted', hide: true }
	]

	public defaultColDef = {
		resizable: true,
		sortable: true,
	}

	constructor(private sharedService: SharedService, private translate: TranslateService, private elementRef: ElementRef,
		private procedureService: ProcedureService, private loadingService: LoadingService, private alertService: AlertService,
		private modalService: BsModalService,private renderer: Renderer2) {
		this.isFirefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
		this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
		this.fileId = sharedService.getFileId();
		this.fileType = sharedService.getFileType();
		this.userId = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER)).userId;
		translate.addLangs(["es", "en"]);
		translate.setDefaultLang("en");
		let browserLang = translate.getBrowserLang();
		translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
		translate.getTranslation(browserLang).subscribe((res: string) => {
			this.pro_translated_data = res['PROCEDURE_EDITOR']
			this.collapseExpandBtnText = this.pro_translated_data.COLLAPSE_ALL
		})
		// this.tempSaveKey = this.fileId + '_procTempContent';
		this.tabId = SharedService.getTabId();
		this.toolbarId = 'toolbar_' + SharedService.getTabId();
		this.rowSelection = "multiple";
		this.rowSelection = "multiple";
		this.checkoutUserId = sharedService.getCheckoutUserId();
		this.addFractionDIvId = 'webeditor_fraction_' + SharedService.getTabId();
		this.addSpecialCharDivId = 'webeditor_special_char_' + SharedService.getTabId();
		editor = null;	
	}

	ngOnInit() {
		this.sharedService.setViewOnlyStatus(this.fileId, true);
		this.speCharList = [
			{ data: { symbol: this.getSymbolFromDecimal(169), decVal: 169, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(8482), decVal: 8482, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(174), decVal: 174, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(176), decVal: 176, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(163), decVal: 163, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(8364), decVal: 8364, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(10016), decVal: 10016, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(9746), decVal: 9746, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(9744), decVal: 9744, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(167), decVal: 167, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(937), decVal: 937, type: "speChar" } }
		];

		this.loadingService.showLoading(
			true, false, this.translate.instant('PROCEDURE_EDITOR.LOADING'), 0);

		const loggedUserId = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER));
		if (this.checkoutUserId != null) {
			if (loggedUserId.userId != this.checkoutUserId) {
				this.isCheckOutByOthers = true;
			} else {
				this.isCheckOutByOthers = false;
			}
		} else {
			this.isCheckOutByOthers = false;
		}

		if (this.sharedService.getIsCheckedIn() == true) {
			this.isCheckOut = false;
		} else {
			this.isCheckOut = true;
		}

		CKEDITOR.disableAutoInline = true;
		let el = this.elementRef.nativeElement.querySelector('.tag_editor');
		this.tagEditor = CKEDITOR.inline(el, this.tagEditorConfig);

		// if (localStorage.getItem(this.tempSaveKey) == undefined) {
		this.procedureService.getProcedureContent(this.fileId).subscribe(
			data => {
				// if (this.isCheckOut) {
					// localStorage.setItem(this.tempSaveKey, JSON.stringify(data.payload));
					// this.loadData(JSON.stringify(data.payload));
				// } else {
					this.loadData(JSON.stringify(data.payload));
				// }
			},
			error => {
				this.alertService.clear();
				this.alertService.error(error.statusDescription);
			}
		);
		this.procedureService.getOperatingDataList(this.fileId).subscribe(
			data => {
				if(data != null){
					this.operatingDataList = data.payload;
					for (let i = 0; i < this.operatingDataList.length; i++){
						this.operatingDataList[i].operatingDataName = this.decodeSpecialChars(this.operatingDataList[i].operatingDataName);
					}
				}
			},
			error => {
				this.alertService.clear();
				this.alertService.error(error.statusDescription);
			}
		);
		// } else {
		// 	this.loadData(localStorage.getItem(this.tempSaveKey));
		// }
		
		editor = null;
		this.browserResize();
	}

	ngAfterContentInit() {
		//Called after ngOnInit when the component's or directive's content has been initialized.
		//Add 'implements AfterContentInit' to the class.
		this.susbscription = SharedService.tabIdRespond().subscribe(() => {
			//this.tabId = SharedService.getTabId();

			// Disable all editors if tab is changed.
			for (let i in this.editorList) {
				if (CKEDITOR.instances[this.editorList[i].name] != undefined) {
					CKEDITOR.instances[this.editorList[i].name].destroy();
					this.editorDivList[i].setAttribute("contenteditable", "false");
					if ($(this.editorDivList[i])[0].innerHTML == '') {
						$(this.editorDivList[i])[0].innerHTML = '<br>';
					}
				}
			}

			if(this.tabId == SharedService.getTabId()){
				this.insertSubscription = SharedService.insertProceduresExecute().subscribe(option => this.insertTagEditArea(option));
				SharedService.setProceSubscriptions(this.insertSubscription);
			}
		});
		this.insertSubscription = SharedService.insertProceduresExecute().subscribe(option => this.insertTagEditArea(option));
		SharedService.setProceSubscriptions(this.insertSubscription);
	}

	loadConflictData(payload: any) {
		this.rowData = [];
		for (let i = 0; i < payload.length; i++) {
			this.rowData.push({
				type: payload[i]["type"],
				newVal: payload[i]["isDeleted"] == "false" ? payload[i]["newValue"] : "DELETED",
				lastVal: payload[i]["lastValue"],
				id: payload[i]["id"],
				name: payload[i]["name"],
				lastUpdateTimeStamp: payload[i]["lastUpdateTimeStamp"],
				isDeleted: payload[i]["isDeleted"]

			})
		}
		for (let x = 0; x < this.rowData.length; x++) {
			if (this.rowData[x].type == "Statement") {
				this.rowData.push(this.rowData.splice(x, 1)[0]);
			}
		}
	}

	loadData(data: any) {
		if (data != null) {
			data = data.replace(/(<wintitle)([\s]+([^>]+)\/>)/g,
				"<wintitle contenteditable='true'$2</wintitle>");
			this.content = JSON.parse(data);
			this.folderPath = this.content.folderPath;
			if (this.content.header != null) {
				let header = this.content.header;
				if (header.system != null) {
					this.systemName = header.system.name;
				}
				if (header.procType != null) {
					this.proceType = header.procType.name;
				}
				if (header.procDesc != null) {
					this.proceDesc = header.procDesc.element;
				} else {
					this.proceDesc = this.setElementTag();
				}
				if (header.assumption != null) {
					this.assList.push({ element: header.assumption.element });
				}
				if (header.objective != null) {
					this.objList.push({ element: header.objective.element });
				}
				this.ncwrList = header.ncwrs;
				if (header.images != null) {
					this.imageList = header.images;
				}
				this.imagePanelFlag = header.imagePanelFlag;
			}if (this.content.steps != null && this.content.steps.length != 0) {
				this.stepsList = this.content.steps;
				for (let p = 0; p <this.stepsList.length; p++) {
					if (this.stepsList[p].element.includes("<para contenteditable=\"false\" style=\"background-color:#d9d9d9;display:block;\"> <\/para>")) {
						let count = (this.stepsList[p].element.match(/<para contenteditable=\"false\" style=\"background-color:#d9d9d9;display:block;\"> <\/para>/g) || []).length;
						for (let j = 1; j <= count; j++) {
							this.stepsList[p].element = this.stepsList[p].element.replace("<para contenteditable=\"false\" style=\"background-color:#d9d9d9;display:block;\"> <\/para>", 
							"<para contenteditable=\"false\" style=\"background-color:#d9d9d9;display:block;\">&nbsp;<\/para>");
						}
					}
				}
			}
			setTimeout(() => {
				let titleEl = this.elementRef.nativeElement.querySelector('.ckeditor_title');
				titleEl.innerHTML = this.proceDesc;
				for (let i = 0; i < this.imageList.length; i++) {
					if (this.imageList[i].type == "imageAsFigure") {
						let el = this.elementRef.nativeElement.querySelector('.img_editor' + i);
						el.innerHTML = this.imageList[i].title;
					}
				}
				if (this.assList.length == 1) {
					let el = this.elementRef.nativeElement.querySelector('.ack_editor0');
					el.innerHTML = this.assList[0].element;
				}
				if (this.objList.length == 1) {
					let el = this.elementRef.nativeElement.querySelector('.ock_editor0');
					el.innerHTML = this.objList[0].element;
				}
				for (let i = 0; i < this.ncwrList.length; i++ , this.index++) {
					let el = this.elementRef.nativeElement.querySelector('.ck_editor' + i);
					el.innerHTML = this.ncwrList[i].element;
				}
				for (let i = 0; i < this.stepsList.length; i++ , this.stepIndex++) {
					this.addStepsContent(i);
				}
				if (this.isCheckOut && !this.isCheckOutByOthers) {
					this.setEditor();
				} else {
					if (!this.isMobile) {
						$('.toggle-edit' + SharedService.getTabId() + this.userId).unbind("dblclick");
					} else {
						$('.toggle-edit' + SharedService.getTabId() + this.userId).unbind("click");
					}
				}
				this.loadingService.hideLoading();

				let checkTimer = timer(5000, 60000);
				this.timerSubscription = checkTimer.subscribe(t => this.getValues());
				// this.timerSubscription = timer.subscribe(t => this.saveTemporary());
			}, 500);
		} else {
			// do nothing
		}
		
		this.procedureService.getOperatingDataList(this.fileId).subscribe(
			data => {
				if(data != null){
					this.operatingDataList = data.payload;
					for (let i = 0; i < this.operatingDataList.length; i++){
						this.operatingDataList[i].operatingDataName = this.decodeSpecialChars(this.operatingDataList[i].operatingDataName);
					}
					for (let j = 0; j <this.stepsList.length; j++) {
						for (let n = 0; n < this.stepsList[j].operatingData.length; n++) {
							let id = this.stepsList[j].operatingData[n].id;
							this.stepsList[j].operatingData[n] = this.operatingDataList.find(item => item.id == id);
						}
						for (let m = 0; m < this.stepsList[j].substeps.length; m++) {
							let substep = this.stepsList[j].substeps[m];
							for (let k = 0; k < substep.operatingData.length; k++) {
								let id = substep.operatingData[k].id;
								substep.operatingData[k] = this.operatingDataList.find(item => item.id == id);
							}
						}
					}
				}
			},
			error => {
				this.alertService.clear();
				this.alertService.error(error.statusDescription);
			}
		);
	}

	loadUndoOrCheckoutData(data: any) {
		this.loadingService.hideLoading();
		this.assList = [];
		this.objList = [];
		if (data != null) {
			data = data.replace(/(<wintitle)([\s]+([^>]+)\/>)/g,
				"<wintitle contenteditable='true'$2</wintitle>");
			this.content = JSON.parse(data);
			this.folderPath = this.content.folderPath;
			if (this.content.header != null) {
				let header = this.content.header;
				if (header.system != null) {
					this.systemName = header.system.name;
				}
				if (header.procType != null) {
					this.proceType = header.procType.name;
				}
				if (header.procDesc != null) {
					this.proceDesc = header.procDesc.element;
				} else {
					this.proceDesc = this.setElementTag();
				}
				if (header.assumption != null) {
					this.assList.push({ element: header.assumption.element });
				}
				if (header.objective != null) {
					this.objList.push({ element: header.objective.element });
				}
				this.ncwrList = header.ncwrs;
				if (header.images != null) {
					this.imageList = header.images;
				}
				this.imagePanelFlag = header.imagePanelFlag;
			} if (this.content.steps != null && this.content.steps.length != 0) {
				this.stepsList = this.content.steps;
				for (let p = 0; p <this.stepsList.length; p++) {
					if (this.stepsList[p].element.includes("<para contenteditable=\"false\" style=\"background-color:#d9d9d9;display:block;\"> <\/para>")) {
						let count = (this.stepsList[p].element.match(/<para contenteditable=\"false\" style=\"background-color:#d9d9d9;display:block;\"> <\/para>/g) || []).length;
						for (let j = 1; j <= count; j++) {
							this.stepsList[p].element = this.stepsList[p].element.replace("<para contenteditable=\"false\" style=\"background-color:#d9d9d9;display:block;\"> <\/para>", 
							"<para contenteditable=\"false\" style=\"background-color:#d9d9d9;display:block;\">&nbsp;<\/para>");
						}
					}
				}
			}
			setTimeout(() => {
				let titleEl = this.elementRef.nativeElement.querySelector('.ckeditor_title');
				titleEl.innerHTML = this.proceDesc;
				for (let i = 0; i < this.imageList.length; i++) {
					if (this.imageList[i].type == "imageAsFigure") {
						let el = this.elementRef.nativeElement.querySelector('.img_editor' + i);
						el.innerHTML = this.imageList[i].title;
					}
				}
				if (this.assList.length == 1) {
					let el = this.elementRef.nativeElement.querySelector('.ack_editor0');
					el.innerHTML = this.assList[0].element;
				}
				if (this.objList.length == 1) {
					let el = this.elementRef.nativeElement.querySelector('.ock_editor0');
					el.innerHTML = this.objList[0].element;
				}
				for (let i = 0; i < this.ncwrList.length; i++ , this.index++) {
					let el = this.elementRef.nativeElement.querySelector('.ck_editor' + i);
					el.innerHTML = this.ncwrList[i].element;
				}
				for (let i = 0; i < this.stepsList.length; i++ , this.stepIndex++) {
					this.addStepsContent(i);
				}
				if (this.isCheckOut && !this.isCheckOutByOthers) {
					this.setEditor();
				} else {
					if (!this.isMobile) {
						$('.toggle-edit' + SharedService.getTabId() + this.userId).unbind("dblclick");
					} else {
						$('.toggle-edit' + SharedService.getTabId() + this.userId).unbind("click");
					}
				}
				this.loadingService.hideLoading();
			}, 500);
		} else {
			// do nothing
		}
		this.procedureService.getOperatingDataList(this.fileId).subscribe(
			data => {
				if(data != null){
					this.operatingDataList = data.payload;
					for (let i = 0; i < this.operatingDataList.length; i++){
						this.operatingDataList[i].operatingDataName = this.decodeSpecialChars(this.operatingDataList[i].operatingDataName);
					}
					for (let j = 0; j <this.stepsList.length; j++) {
						for (let n = 0; n < this.stepsList[j].operatingData.length; n++) {
							let id = this.stepsList[j].operatingData[n].id;
							this.stepsList[j].operatingData[n] = this.operatingDataList.find(item => item.id == id);
						}
						for (let m = 0; m < this.stepsList[j].substeps.length; m++) {
							let substep = this.stepsList[j].substeps[m];
							for (let k = 0; k < substep.operatingData.length; k++) {
								let id = substep.operatingData[k].id;
								substep.operatingData[k] = this.operatingDataList.find(item => item.id == id);
							}
						}
					}
				}
			},
			error => {
				this.alertService.clear();
				this.alertService.error(error.statusDescription);
			}
		);
	}

	private htmlEncode(content: string): string {
		if (content != null || content != "") {
			return content.replace(/(?!&amp;|&gt;|&lt;|&quot;|&nbsp;)\&/g, "&amp;").replace(/>/g, "&gt;").replace(/</g, "&lt;").
			replace(/"/g, "&quot;").replace(/'/g, "&#39;");
		}
		else {
			return "";
		}
	}

	private getSymbolFromDecimal(value: number): string {
		if ((33 <= value) && (value < 65536)) {
			return String.fromCharCode(value);
		} else {
			this.alertService.error("Please enter valid Unicode character entity reference. Range is between 33 to 65535", true);
		}
	}

	private insertTagEditArea(option: any) {
		this.html = '';
		this.tagContent = '';
		if (option != null) {
			if (option.data.type == "Facility" || option.data.type == "FacilityName" || option.data.type == "documentFacility" ||
				option.data.type == "SystemFacility" || option.data.type == "System" || option.data.type == "TagNo" ||
				option.data.type == "TagNoDesc" || option.data.type == "TagDocument" || option.data.type == "Description" ||
				option.data.type == "Component" || option.data.type == "Document" || option.data.type == "systemName" ||
				option.data.type == "systemNo" || option.data.type == "TagFacilityName" || option.data.type == "TagAttributes") {
				if (option.data.reusableXML != undefined) {
					this.html = option.data.reusableXML.replace(/insid=.{38}/gi, function insId() {
                        function s4() {
                            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
                        }
                        return 'insId="' + s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4() + '"';
                  	  });
				} else {
					if (option.data.type == "Component") {
                        this.html = "<com style='background-color:#FFFF8D;' compType='com' id='" + option.data.id + "' compName='" + this.htmlEncode(option.data.componentName) + "' compValue='" +
                            this.htmlEncode(option.data.componentValue) + "' lastUpdateTimeStamp='" + option.data.lastUpdateTimeStamp +
                            "' insID='" + this.guid() + "'>" + this.htmlEncode(option.data.componentValue) + "</com>";
                    } else if (option.data.type == "Document") {
                        this.html = "<com style='background-color:#FFFF8D;' compType='doc' id='" + option.data.id + "' compName='document' compValue='" +
                            this.htmlEncode(option.data.documentName) + "' lastUpdateTimeStamp='" + option.data.lastUpdateTimeStamp +
                            "' insID='" + this.guid() + "'>" + this.htmlEncode(option.data.documentName) + "</com>";
                    } else if (option.data.type == "systemName") {
                        this.html = "<com style='background-color:#FFFF8D;' compType='sys' id='" + option.data.systemId + "' compName='SystemName' compValue='" +
                            this.htmlEncode(option.data.systemName) + "' lastUpdateTimeStamp='" + option.data.lastUpdateTimeStamp +
                            "' insID='" + this.guid() + "'>" + this.htmlEncode(option.data.systemName) + "</com>";
                    } else if (option.data.type == "systemNo") {
                        this.html = "<com style='background-color:#FFFF8D;' compType='sys' id='" + option.data.systemId + "' compName='SystemNo' compValue='" +
                            option.data.systemNo + "' lastUpdateTimeStamp='" + option.data.systemNoLastUpdateTimeStamp +
                            "' insID='" + this.guid() + "'>" + option.data.systemNo + "</com>";
                    } else if (option.data.type == "Facility") {
                        this.html = "<com style='background-color:#FFFF8D;' compType='fac' id='" + option.data.id + "' compName='facility' compValue='" +
                            this.htmlEncode(option.data.facilityName) + "' lastUpdateTimeStamp='" + option.data.lastUpdateTimeStamp +
                            "' insID='" + this.guid() + "'>" + this.htmlEncode(option.data.facilityName) + "</com>";
                    } else if (option.data.type == "FacilityName") {
                        this.html = "<com style='background-color:#FFFF8D;' comptype = 'fac' compName='facility' compValue='" + this.htmlEncode(option.data.facilityName) +
                            "' id='" + option.data.facilityId + "' insID='" + this.guid() + "' lastUpdateTimeStamp='" +
                            option.data.tagDescUpdateTimeStamp + "' >" + this.htmlEncode(option.data.facilityName) + "</com>";
                    } else if (option.data.type == "documentFacility") {
                        if (option.data.facilityName != undefined) {
							this.html = "<com style='background-color:#FFFF8D;' comptype = 'fac' compName='facility' compValue='" + this.htmlEncode(option.data.facilityName) +
                            "' id='" + option.data.facilityId + "' insID='" + this.guid() + "' lastUpdateTimeStamp='" +
                            option.data.facilityLastUpdateTimeStamp + "' >" + this.htmlEncode(option.data.facilityName) + "</com>";
						}
                    } else if (option.data.type == "SystemFacility") {
                        if (option.data.facilityName != undefined) {
                            this.html = "<com style='background-color:#FFFF8D;' compType='fac' id='" + option.data.facilityId + "' compName='facility' compValue='" +
                            this.htmlEncode(option.data.facilityName) + "' lastUpdateTimeStamp='" + option.data.facilityLastUpdateTimeStamp +
                            "' insID='" + this.guid() + "'>" + this.htmlEncode(option.data.facilityName) + "</com>";
                        }                        
                    } else if (option.data.type == "System") {
                        this.html = "<tag style='background-color:#FFFF8D;' id='" + option.data.tagId + "' insID='" + this.guid() + "'><fld name='sysName' value='" +
                            this.htmlEncode(option.data.systemName) + "' lastUpdateTimeStamp='" + option.data.tagSysUpdateTimeStamp +
                            "' insID='" + this.guid() + "'>" + this.htmlEncode(option.data.systemName) + "</fld></tag>";
                    } else if (option.data.type == "TagNo") {
                        this.html = "<tag style='background-color:#FFFF8D;' id='" + option.data.tagId + "' insID='" + this.guid() + "'><fld name='tagNo' value='" + this.htmlEncode(option.data.tagNo) +
                            "' lastUpdateTimeStamp='" + option.data.tagNoUpdateTimeStamp + "'>" + this.htmlEncode(option.data.tagNo) + "</fld></tag>";
                    } else if (option.data.type == "Description") {
                        this.html = "<tag style='background-color:#FFFF8D;' id='" + option.data.tagId + "' insID='" + this.guid() + "'><fld name='tagName' value='' lastUpdateTimeStamp='" +
                            option.data.tagDescUpdateTimeStamp + "'><tagdesc><txt>" + this.htmlEncode(option.data.description) + "</txt></tagdesc></fld></tag>";
                    } else if (option.data.type == "TagDocument") {
                        if (option.data.documentName != undefined) {
                            this.html = "<tag style='background-color:#FFFF8D;' compType='doc' id='" + option.data.tagId + "' insID='" + this.guid() + "'><fld name='docName' value='" +
                            this.htmlEncode(option.data.documentName) + "' lastUpdateTimeStamp='" + option.data.tagDocUpdateTimeStamp + "'>" +
                            this.htmlEncode(option.data.documentName) + "</fld></tag>";
                        }                        
                    } else if (option.data.type == "TagNoDesc") {
                        this.html = "<tag style='background-color:#FFFF8D;' id='" + option.data.tagId + "' insID='" + this.guid() + "'><fld name='tagName' value='' lastUpdateTimeStamp='" +
                            option.data.tagDescUpdateTimeStamp + "'><tagdesc><txt>" + this.htmlEncode(option.data.description) + "</txt></tagdesc></fld></tag>" +
                            "kkkk,kkkk" +
                            "<tag style='background-color:#FFFF8D;' id='" + option.data.tagId + "' insID='" + this.guid() + "'><fld name='tagNo' value='" + this.htmlEncode(option.data.tagNo) +
                            "' lastUpdateTimeStamp='" + option.data.tagNoUpdateTimeStamp + "'>" + this.htmlEncode(option.data.tagNo) + "</fld></tag>";
                    } else if (option.data.type == "TagFacilityName") {
                        if (option.data.facilityName != undefined) {
                            this.html = "<com style='background-color:#FFFF8D;' comptype = 'fac' compName='facility' compValue='" + this.htmlEncode(option.data.facilityName) +
                            "' id='" + option.data.facilityId + "' insID='" + this.guid() + "' lastUpdateTimeStamp='" +
                            option.data.tagFacilityLastUpdateTimeStamp + "' >" + this.htmlEncode(option.data.facilityName) + "</com>";
                        }                        
                    } else if (option.data.type == "TagAttributes") {
                        this.html = '<tag style="background-color:#FFFF8D;" contenteditable="false" id="' + option.data.id +'"' + ' insID="' + this.guid().toUpperCase() + '"'+ '>'+ 
                        '<attr specId="' + option.data.specId + '"'+ ' lastUpdateTimeStamp="' + option.data.lastUpdateTimeStamp + 
                        '"'+ ' name="' + this.htmlEncode(option.data.name) + '"'+ ' value="' + this.htmlEncode(option.data.value) + '"'+'>' + this.htmlEncode(option.data.value) +
                        "</attr></tag>";
                    }
				}
				if (editor != null) {
					this.selection = editor.getSelection();
					if (this.selection != null) {
						this.ranges = this.selection.getRanges();
					}
					let ancestor;
					if (this.ranges != null) {
						let range = this.ranges[0];
						if (range != null) {
							range = range.clone();
							let startNode = range.startContainer;
							ancestor = startNode.$.parentNode;
							if (startNode.$.nodeName == 'PH' || startNode.$.nodeName == 'EDITAREA') {
								ancestor = startNode.$;
							}
                            while(ancestor.nodeName == 'B' || ancestor.nodeName == 'U' || ancestor.nodeName == 'I'){
                                ancestor = ancestor.parentNode;
                            }
                            if(ancestor.nodeName == 'ATTR' && ancestor.parentNode.nodeName == 'TAG'){
                                ancestor = ancestor.parentNode;
                            }
						}
					}
					if (ancestor != null) {
						if (this.html == '') {
							if (ancestor.nodeName == 'TXT' && (ancestor.parentNode.nodeName == 'STATEMENT' || ancestor.parentNode.nodeName == 'PARA' || ancestor.parentNode.nodeName == 'LI')) {
								this.alertService.clear();
								this.alertService.info(this.translate.instant('TAG_EDIT_AREA.CANNOT_INSERT_REUSABLE'), true);
							} else {
								this.alertService.clear();
								this.alertService.info(this.translate.instant('TAG_EDIT_AREA.CANNOT_INSERT_EMPTY_VALUE'), true);
							}
						} else {
							if (ancestor.nodeName == 'PH') {
								if (option.data.type == 'TagNoDesc') {
									this.guidWinT = this.guid().toUpperCase();
									this.html = this.processTagNoDesc(this.html);
								}
								let restriction = ancestor.attributes['restriction'].value;
								let tagPHId = ancestor.attributes['tagPHId'].value;
								let catId = ancestor.attributes['catId'] != undefined ? ancestor.attributes['catId'].value : "";
								let text = ancestor.textContent;
								this.tagContent = '<tags contenteditable="false" insID="' + this.guid().toUpperCase() +
									'" tagPHId="' + tagPHId + '" restriction="' + restriction + '" catId="' + catId + '" phText="' + text + '"><editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea>' + this.html + '<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea></tags>';
								this.setOuterHTML(ancestor, this.tagContent);
							} else if (ancestor.nodeName == 'EDITAREA') {
								if (option.data.type == 'TagNoDesc') {
									this.html = this.processTagNoDesc(this.html);
								}
								this.tagContent = '<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea>' + this.html + '<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea>';
								this.setOuterHTML(ancestor, this.tagContent);
							} else if (SharedService.nodeSelected() && SharedService.getselectedNode().nodeName == 'PH') {
								if (option.data.type == 'TagNoDesc') {
									this.guidWinT = this.guid().toUpperCase();
									this.html = this.processTagNoDesc(this.html);
								}
								let selectedNode = SharedService.getselectedNode();
								let restriction = selectedNode.attributes['restriction'].value;
								let tagPHId = selectedNode.attributes['tagPHId'].value;
								let catId = selectedNode.attributes['catId'] != undefined ? selectedNode.attributes['catId'].value : "";
								let text = selectedNode.textContent;
								this.tagContent = '<tags contenteditable="false" insID="' + this.guid().toUpperCase() +
									'" tagPHId="' + tagPHId + '" restriction="' + restriction + '" catId="' + catId + '" phText="' + text + '"><editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea>' + this.html + '<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea></tags>';
								this.setOuterHTML(selectedNode, this.tagContent);
								SharedService.resetselectedNode();
							}  else if (ancestor.nodeName == 'TAG' || ancestor.nodeName == 'COM' || ancestor.nodeName == 'FLD' || ancestor.nodeName == 'TAGDESC' || ancestor.nodeName == 'TXT') {
								if(!this.isStatementParent(ancestor)){
									if (ancestor.nodeName == 'TAG' || ancestor.nodeName == 'COM') {
										if (option.data.type == 'TagNoDesc') {
                                            this.html = this.html.replace(/kkkk,kkkk/g, ('</tags>,&nbsp;<tags contenteditable="false" insID="'+this.guid().toUpperCase()+'">'));
                                            this.html = '<tags contenteditable="false" insID="'+this.guid().toUpperCase()+'">' + this.html + '</tags>';
                                            this.setOuterHTML(ancestor.parentNode, this.html);
                                        }
                                        else{
                                            this.setOuterHTML(ancestor, this.html);
                                        }
									} else if (ancestor.nodeName == 'FLD') {
										if (ancestor.parentNode != undefined) {
											var tagsNode = ancestor.parentNode;
											if (option.data.type == 'TagNoDesc') {
                                                this.html = this.html.replace(/kkkk,kkkk/g, ('</tags>,&nbsp;<tags contenteditable="false" insID="'+this.guid().toUpperCase()+'">'));
                                                this.html = '<tags contenteditable="false" insID="'+this.guid().toUpperCase()+'">' + this.html + '</tags>';
                                                this.setOuterHTML(tagsNode.parentNode, this.html);
											}
                                            else{
                                                this.setOuterHTML(tagsNode, this.html);
                                            }
										}
									} else if (ancestor.nodeName == 'TAGDESC') {
										if (ancestor.parentNode.parentNode != undefined) {
											var tagsNode = ancestor.parentNode.parentNode;
											if (option.data.type == 'TagNoDesc') {
                                                this.html = this.html.replace(/kkkk,kkkk/g, ('</tags>,&nbsp;<tags contenteditable="false" insID="'+this.guid().toUpperCase()+'">'));
                                                this.html = '<tags contenteditable="false" insID="'+this.guid().toUpperCase()+'">' + this.html + '</tags>';
                                                this.setOuterHTML(tagsNode.parentNode, this.html);
											}
                                            else{
                                                this.setOuterHTML(tagsNode, this.html);
                                            }
										}
									} else if (ancestor.nodeName == 'TXT') {
										if (ancestor.parentNode.nodeName == 'STATEMENT' || ancestor.parentNode.nodeName == 'PARA' || ancestor.parentNode.nodeName == 'P' || ancestor.parentNode.nodeName == 'LI') {
											this.alertService.clear();
											this.alertService.info(this.translate.instant('TAG_EDIT_AREA.CANNOT_INSERT_REUSABLE'), true);
										} else if (ancestor.parentNode.parentNode.parentNode != undefined) {
											var tagsNode = ancestor.parentNode.parentNode.parentNode;
											if (option.data.type == 'TagNoDesc') {
                                                this.html = this.html.replace(/kkkk,kkkk/g, ('</tags>,&nbsp;<tags contenteditable="false" insID="'+this.guid().toUpperCase()+'">'));
                                                this.html = '<tags contenteditable="false" insID="'+this.guid().toUpperCase()+'">' + this.html + '</tags>';
                                                this.setOuterHTML(tagsNode.parentNode, this.html);
											}
                                            else{
                                                this.setOuterHTML(tagsNode, this.html);
                                            }
										}
									}
								}else{
									this.alertService.clear();
									this.alertService.info(this.translate.instant('TAG_EDIT_AREA.NOT_VALID_SELECTION'), true);
								}
							} else if (ancestor.nodeName != 'WINTITLE' && editor.getSelection() != null && ancestor.nodeName != 'P') {
								let findstatement  =this.findUpTag(ancestor,'STATEMENT' );
								if(!findstatement){
									if(option.data.type == 'TagNoDesc' && this.html.indexOf('</wintitle>')>-1){
										let strArray = this.html.split(/<editarea(?:(?!<tag.*?>).)*>/g);
										strArray.splice(1, 0, ", ");
										for (let i = 0; i < strArray.length; i++) {
											if (i == 0 || i == 2) {
												this.tagContent = "<tags contenteditable='false' insID='" + this.guid().toUpperCase() + "'>" + strArray[i] + "</tags>";
												let sel = editor.getSelection();
												let range;
												if (sel.getRanges()) {
													range = sel.getRanges()[0];
												}
												if (!range) {
													range = editor.createRange();
													range.selectNodeContents(editor.editable());
													sel.selectRanges([range]);
												}
												let fragment = range.extractContents();
												let container = CKEDITOR.dom.element.createFromHtml(this.tagContent, editor.document);
												fragment.appendTo(container);
												editor.insertElement(container);
											} else {
												this.tagContent = strArray[i];
												editor.insertText(", ");
											}
										}
									}
									else if (option.data.type == 'TagNoDesc') {
										let strArray = this.html.split("kkkk,kkkk");
										strArray.splice(1, 0, ", ");
										for (let i = 0; i < strArray.length; i++) {
											if (i == 0 || i == 2) {
												this.tagContent = "<tags contenteditable='false' insID='" + this.guid().toUpperCase() + "'>" + strArray[i] + "</tags>";
												let sel = editor.getSelection();
												let range;
												if (sel.getRanges()) {
													range = sel.getRanges()[0];
												}
												if (!range) {
													range = editor.createRange();
													range.selectNodeContents(editor.editable());
													sel.selectRanges([range]);
												}
												let fragment = range.extractContents();
												let container = CKEDITOR.dom.element.createFromHtml(this.tagContent, editor.document);
												fragment.appendTo(container);
												editor.insertElement(container);
											} else {
												this.tagContent = strArray[i];
												editor.insertText(", ");
											}
										}
									} else {
										this.tagContent = '<tags contenteditable="false" insID="' + this.guid().toUpperCase() + '">' + this.html + '</tags>';
										let sel = editor.getSelection();
										let range;
										if (sel.getRanges()) {
											range = sel.getRanges()[0];
										}
										if (!range) {
											range = editor.createRange();
											range.selectNodeContents(editor.editable());
											sel.selectRanges([range]);
										}
										let fragment = range.extractContents();
										let container = CKEDITOR.dom.element.createFromHtml(this.tagContent, editor.document);
										fragment.appendTo(container);
										editor.insertElement(container);
									}
								}
							}
						}
					} else {
						this.alertService.clear();
						this.alertService.info(this.translate.instant('TAG_EDIT_AREA.NOT_VALID_SELECTION'), true);
					}
				} else {
					this.alertService.clear();
					this.alertService.info(this.translate.instant('TAG_EDIT_AREA.NOT_VALID_EDITOR'), true);
				}
			} else if (option.data.type == "Statement" || option.data.type == "History_Statement" || option.data.type == "crossReference") {
				if (option.data.type == "Statement") {
					this.convertStatement(option.data);
				} else if (option.data.type == "crossReference") {
					this.html = "<XRef contenteditable='false' style='background-color:#f4b342;' topicId='" + option.data.refId + "' xrefType='"
						+ option.data.refType + "' refInsId='" + option.data.refInsId + "'>" + option.data.refType + ": " + option.data.refText + "</XRref>";
				} else if (option.data.type == "History_Statement") {
					this.html = option.data.reusableXML.replace(/insid=.{38}/gi, function insId() {
                        function s4() {
                            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
                        }
                        return 'insId="' + s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4() + '"';
                    });
				}
				if (editor != null) {
					this.selection = editor.getSelection();
					if (this.selection != null) {
						this.ranges = this.selection.getRanges();
					}
					let ancestor;
					if (this.ranges != null) {
						let range = this.ranges[0];
						if (range != null) {
							range = range.clone();
							let startNode = range.startContainer;
							ancestor = startNode.$.parentNode;
						}
					} if (ancestor != null) {
						if (this.html != '') {
							if (ancestor.nodeName != 'WINTITLE' && editor.getSelection() != null) {
								let sel = editor.getSelection();
								let range;
								if (sel.getRanges()) {
									range = sel.getRanges()[0];
								}
								if (!range) {
									range = editor.createRange();
									range.selectNodeContents(editor.editable());
									sel.selectRanges([range]);
								}
								let fragment = range.extractContents();
								let container = CKEDITOR.dom.element.createFromHtml(this.html, editor.document);
								fragment.appendTo(container);
								editor.insertElement(container);
							} else {
								this.alertService.clear();
								this.alertService.info(this.translate.instant('TAG_EDIT_AREA.NOT_VALID_SELECTION'), true);
							}
						} else {
							this.alertService.clear();
							this.alertService.info(this.translate.instant('TAG_EDIT_AREA.CANNOT_INSERT_EMPTY_VALUE'), true);
						}
					} else {
						this.alertService.clear();
						this.alertService.info(this.translate.instant('TAG_EDIT_AREA.NOT_VALID_SELECTION'), true);
					}
				} else {
					this.alertService.clear();
					this.alertService.info(this.translate.instant('TAG_EDIT_AREA.NOT_VALID_EDITOR'), true);
				}
			} else if (option.data.type == "fraction" || option.data.type == "speChar") {
				if (option.data.type == "speChar") {
					this.html = "<spChar contenteditable='false' style='background-color:#48f442;' value='" + option.data.decVal + "' insID='" +
						this.guid().toUpperCase() + "'>" + option.data.symbol + "</spChar>";
				} else {
					this.html = "<fraction style='background-color:#f4b342;' numerator='" + option.data.numerator + "' denominator=" + option.data.denominator + ">" + option.data.numerator + "/" + option.data.denominator + "</fraction>";
				}
				if (editor != null) {
					this.selection = editor.getSelection();
					if (this.selection != null) {
						this.ranges = this.selection.getRanges();
					}
					let ancestor;
					if (this.ranges != null) {
						let range = this.ranges[0];
						if (range != null) {
							range = range.clone();
							let startNode = range.startContainer;
							ancestor = startNode.$.parentNode;
						}
					}
					if (ancestor != null) {
						if (this.html != '') {
							if (ancestor.nodeName == 'WINTITLE') {
								$(ancestor).removeAttr("contenteditable");
							} else if (ancestor.nodeName == 'PH') {
								let restriction = ancestor.attributes['restriction'].value;
								let tagPHId = ancestor.attributes['tagPHId'].value;
								let catId = ancestor.attributes['catId'] != undefined ? ancestor.attributes['catId'].value : "";
								let text = ancestor.textContent;
								this.tagContent = '<tags contenteditable="false" insID="' + this.guid().toUpperCase() +
									'" tagPHId="' + tagPHId + '" restriction="' + restriction + '" catId="' + catId + '" phText="' + text + '"><editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea>' + this.html + '<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea></tags>';
								this.setOuterHTML(ancestor, this.tagContent);
							} else if (ancestor.nodeName == 'EDITAREA') {
								this.tagContent = '<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea>' + this.html + '<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea>';
								this.setOuterHTML(ancestor, this.tagContent);
							} else if (ancestor.nodeName != 'TAG' || ancestor.nodeName != 'COM' || ancestor.nodeName != 'FLD' || ancestor.nodeName != 'TAGDESC' || ancestor.nodeName != 'TXT' || ancestor.nodeName != 'TAGS' || ancestor.nodeName != 'STATEMENT') {
								if (option.data.type == "speChar") {
									this.tagContent = '<tags contenteditable="false" insID="' + this.guid().toUpperCase() + '">' + this.html + '</tags>';
								} else {
									this.tagContent = "<fraction contenteditable='false' style='background-color:#f4b342;' numerator='" + option.data.numerator + "' denominator=" + option.data.denominator + ">" + option.data.numerator + "/" + option.data.denominator + "</fraction>";
								}
								let sel = editor.getSelection();
								let range;
								if (sel.getRanges()) {
									range = sel.getRanges()[0];
								}
								if (!range) {
									range = editor.createRange();
									range.selectNodeContents(editor.editable());
									sel.selectRanges([range]);
								}
								let fragment = range.extractContents();
								let container = CKEDITOR.dom.element.createFromHtml(this.tagContent, editor.document);
								fragment.appendTo(container);
								editor.insertElement(container);
							} else {
								this.alertService.clear();
								this.alertService.info(this.translate.instant('TAG_EDIT_AREA.NOT_VALID_SELECTION'), true);
							}
						} else {
							this.alertService.clear();
							this.alertService.info(this.translate.instant('TAG_EDIT_AREA.CANNOT_INSERT_EMPTY_VALUE'), true);
						}
					} else {
						this.alertService.clear();
						this.alertService.info(this.translate.instant('TAG_EDIT_AREA.NOT_VALID_SELECTION'), true);
					}
				} else {
					this.alertService.clear();
					this.alertService.info(this.translate.instant('TAG_EDIT_AREA.NOT_VALID_EDITOR'), true);
				}
			} else if (option.data.type == "imageAsImage" || option.data.type == "imageAsFigure") {
				this.sharedService.setViewOnlyStatus(this.fileId, false);
				let image: ProcImage = new ProcImage(option.data.id, option.data.thumbnailImage, option.data.title, option.data.imgThumb, option.data.type);
				if (this.showHeaderImages) {
					this.imageList.push(image);
					this.showHeaderImages = false;
					this.showImageStep = '';
					this.showImageSubstep = '';
					if (option.data.type == "imageAsFigure") {
						setTimeout(() => {
							let el = this.elementRef.nativeElement.querySelector('.img_editor' + (this.imageList.length - 1));
							el.innerHTML = image.title;
							this.setEditor();
						}, 200);
					}
				} else if (this.showImageStep != '') {
					let step = this.stepsList.find(item => item.id === this.showImageStep);
					step.images.push(image);
					this.showHeaderImages = false;
					this.showImageStep = '';
					this.showImageSubstep = '';
					if (option.data.type == "imageAsFigure") {
						setTimeout(() => {
							let el = this.elementRef.nativeElement.querySelector('.step_img_editor' + (step.images.length - 1) + (step.stepNo - 1));
							el.innerHTML = image.title;
							this.setEditor();
						}, 200);
					}
				} else if (this.showImageSubstep != '') {
					let step = this.stepsList.find(item => item.id === this.stepOfSubstep);
					let substep = step.substeps.find(item => item.id === this.showImageSubstep);
					substep.images.push(image);
					this.showHeaderImages = false;
					this.showImageStep = '';
					this.showImageSubstep = '';
					if (option.data.type == "imageAsFigure") {
						setTimeout(() => {
							let el = this.elementRef.nativeElement.querySelector('.substep_img_editor' + (substep.images.length - 1) + (step.stepNo - 1) + (substep.stepNo - 1));
							el.innerHTML = image.title;
							this.setEditor();
						}, 200);
					}
				}
			}
			if(option.data.type != "fraction" && option.data.type != "speChar"){
				SharedService.setHtmlString(this.html);
			}
		} else {
			// not inserting to tag edit area
		}
	}

	private processTagNoDesc(html: string): string {
		let tagCombiStr = "";
		tagCombiStr = html.replace(/<\/tag>,/g, '</tag><editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea><wintitle style="background-color:#cfe2f3;" insID="' + this.guidWinT + '" id="' + this.guidWinT + '">&nbsp;,&nbsp;</wintitle>' +
			'<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea>').
			replace(/kkkk,kkkk/g,'<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea>'
            +'<wintitle contenteditable="true" style="background-color:#cfe2f3;" insID="' + this.guid() + '" id="' + this.guid() + '">,&nbsp;</wintitle>'
            +'<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea>');
		return tagCombiStr;
                            	}

	private isStatementParent(tagNode: any): boolean {
		let inStmNode: boolean = false;
		while(tagNode.parentNode != undefined){
			if(tagNode.parentNode.nodeName == "STATEMENT"){
                inStmNode = true;
                break;
            }
            else{
                tagNode = tagNode.parentNode;
            }
		}
		// if (tagNode.parentNode != undefined) {
		// 	let tagsNode = tagNode.parentNode;
		// 	if (tagsNode.parentNode != undefined && tagsNode.parentNode.nodeName == 'STATEMENT') {
		// 		inStmNode = true;
		// 	} else if (tagsNode.parentNode.nodeName == 'PARA') {
		// 		if (tagsNode.parentNode.parentNode != undefined && tagsNode.parentNode.parentNode.nodeName == 'STATEMENT') {
		// 			inStmNode = true;
		// 		}
		// 	} else if (tagsNode.parentNode.nodeName == 'LI' && tagsNode.parentNode.parentNode != undefined) {
		// 		if (tagsNode.parentNode.parentNode.nodeName == 'OL' || tagsNode.parentNode.parentNode.nodeName == 'UL') {
		// 			if (tagsNode.parentNode.parentNode.parentNode != undefined && tagsNode.parentNode.parentNode.parentNode.nodeName == 'STATEMENT') {
		// 				inStmNode = true;
		// 			}
		// 		}
		// 	} else if(tagsNode.parentNode != undefined && tagsNode.parentNode.parentNode != undefined) {
        //         if (tagsNode.parentNode.parentNode.nodeName == 'LI' && tagsNode.parentNode.parentNode.parentNode != undefined) {
        //             if (tagsNode.parentNode.parentNode.parentNode.nodeName == 'OL' || tagsNode.parentNode.parentNode.parentNode.nodeName == 'UL') {
        //                 if (tagsNode.parentNode.parentNode.parentNode.parentNode != undefined && tagsNode.parentNode.parentNode.parentNode.parentNode.nodeName == 'STATEMENT') {
        //                     inStmNode = true;
        //                 }
        //                 else {
        //                     inStmNode = this.isStatementParent(tagsNode.parentNode.parentNode);
        //                 }
        //             }
        //         }else if (tagsNode.parentNode.nodeName == 'TXT' && tagsNode.parentNode.parentNode.parentNode != undefined){
        //             if(tagsNode.parentNode.parentNode.parentNode.nodeName == 'STATEMENT'){
        //                 inStmNode = true;
        //             }
        //         }
        //     }
		// }
		return inStmNode;
	}

	convertStatement(content: any): string {
		this.html = '';
		let xmlDoc;
		let statementContent = content.content;
		try {
			xmlDoc = $.parseXML(statementContent);
		} catch {
			this.alertService.clear();
			this.alertService.error(this.translate.instant('INVALID_XML_MSG'), true);
		}
		let outNode = $(xmlDoc).find('statement');
		let length = outNode[0].childNodes.length;
		let id = outNode.attr('id');
		let label = this.htmlEncode(outNode.attr('label'));
		this.html = "<statement style='background-color:#d9d9d9;' insID='" + this.guid() + "' lastUpdateTimeStamp='" + content.lastUpdateTimeStamp + "' contenteditable='false' id='" +
			id + "' label='" + label + "'>";
		for (let i = 0; i < length; i++) {
			let innerNode = outNode[0].childNodes[i];
			if ($(innerNode)[0].nodeName == 'txt') {
				this.html += "<txt";
                let bgc = $(innerNode).attr("bgc");
                let fgc = $(innerNode).attr("fgc");
                if (bgc != null && bgc != "") {
                    this.html += " bgc='"+bgc+ "' "
                }
                if (fgc != null && fgc != "") {
                    this.html += " fgc='"+fgc+ "' "
                }
                this.html += ">";
				for (let j = 0; j < $(innerNode)[0].childNodes.length; j++) {
					let childTxtNode = $(innerNode)[0].childNodes[j];
					if ($(childTxtNode)[0].nodeName == '#text') {
						this.html += childTxtNode.textContent;
					} else {
						this.processStatement(childTxtNode);
					}
				}
				this.html += "</txt>";
			} else if ($(innerNode)[0].nodeName == 'ph') {
				this.processStatement($(innerNode)[0]);
			} else if ($(innerNode)[0].nodeName == 'p') {
				if ($(innerNode)[0].childNodes.length != 0) {
					this.html += '<para contenteditable="false" style="background-color:#d9d9d9;display:block;">';
				} else {
					this.html += '<para contenteditable="false" style="background-color:#d9d9d9;display:block;">&nbsp;';
				}
				for (let i = 0; i < $(innerNode)[0].childNodes.length; i++) {
					let childNode = $(innerNode)[0].childNodes[i];
					if ($(childNode)[0].nodeName == 'txt') {
						this.html += "<txt";
                		let bgc = $(innerNode).attr("bgc");
                		let fgc = $(innerNode).attr("fgc");
                		if (bgc != null && bgc != "") {
                    		this.html += " bgc='"+bgc+ "' "
                		}
                		if (fgc != null && fgc != "") {
                    		this.html += " fgc='"+fgc+ "' "
                		}
                		this.html += ">";
						for (let j = 0; j < $(childNode)[0].childNodes.length; j++) {
							let childTxtNode = $(childNode)[0].childNodes[j];
							if ($(childTxtNode)[0].nodeName == '#text') {
								this.html += this.htmlEncode(childTxtNode.textContent);
							} else {
								this.processStatement(childTxtNode);
							}
						}
						this.html += "</txt>";
					} else if ($(childNode)[0].nodeName == 'ph') {
						this.processStatement($(childNode)[0]);
					}
				}
				this.html += '</para> ';
			} else if ($(innerNode)[0].nodeName == 'ol') {
				let listStyle = $(innerNode).attr("liststyle");
                this.html += '<' + $(innerNode)[0].nodeName + ' contenteditable="false" ckeditorlist="true" style="background-color:#d9d9d9; list-style-type:' + listStyle +
                    '">';
				for (let i = 0; i < $(innerNode)[0].childNodes.length; i++) {
					let liNode = $(innerNode)[0].childNodes[i];
					if (liNode.nodeName == 'li') {
						this.html += '<li>';
						for (let i = 0; i < liNode.childNodes.length; i++) {
							let childNode = liNode.childNodes[i];
							if (childNode.nodeName == 'txt') {
								$(childNode).removeAttr('style');
								this.processStatement(childNode);
							} else if (childNode.nodeName == 'ph') {
								this.processStatement(childNode);
							}
						}
						this.html += '</li>';
					}
				}
				this.html += '</' + $(innerNode)[0].nodeName + '>';
			} else if ($(innerNode)[0].nodeName == 'ul') {
				let listStyle = $(innerNode).attr("liststyle");
                this.html += '<' + $(innerNode)[0].nodeName + ' contenteditable="false" ckeditorlist="true" style="background-color:#d9d9d9; list-style-type:' + listStyle +
                    '">'
				for (let i = 0; i < $(innerNode)[0].childNodes.length; i++) {
					let liNode = $(innerNode)[0].childNodes[i];
					if (liNode.nodeName == 'li') {
						this.html += '<li>';
						for (let i = 0; i < liNode.childNodes.length; i++) {
							let childNode = liNode.childNodes[i];
							if (childNode.nodeName == 'txt') {
								$(childNode).removeAttr('style');
								this.processStatement(childNode);
							} else if (childNode.nodeName == 'ph') {
								this.processStatement(childNode);
							}
						}
						this.html += '</li>';
					}
				}
				this.html += '</' + $(innerNode)[0].nodeName + '>';
			}
		}
		this.html += "</statement>";
		return this.html;
	}

	private processStatement(node: any) {
		if (node.nodeName == 'ph') {
			let arrLen = node.attributes.length;
			let attrPh, attrRestriction, value, attrCatId;
			for (let n = 0; n < arrLen; n++) {
				let attrName = node.attributes[n].name;
				let attrValue = node.attributes[n].value;
				if (attrName == 'tagPHId' || attrName == 'tagphid') {
					attrPh = attrValue;
				} else if (attrName == 'restriction') {
					attrRestriction = attrValue;
				} else if (attrName == 'catId') {
					attrCatId = attrValue;
				}
			}
			if (attrPh != undefined && attrRestriction != undefined) {
				value = node.childNodes[0].textContent;
				if (attrCatId != undefined) {
					if (value != undefined) {
						this.html += "<ph insID='" + this.guid() + "' style='background-color:#ffffcc ;' catId='" + attrCatId + "' tagPHId='" + attrPh + "' restriction='" + attrRestriction + "'>" + value + "</ph>";
					} else {
						this.html += "<ph insID='" + this.guid() + "' style='background-color:#ffffcc ;' catId='" + attrCatId + "' tagPHId='" + attrPh + "' restriction='" + attrRestriction + "'></ph>";
					}
				} else {
					if (value != undefined) {
						this.html += "<ph insID='" + this.guid() + "' style='background-color:#ffffcc ;' tagPHId='" + attrPh + "' restriction='" + attrRestriction + "'>" + value + "</ph>";
					} else {
						this.html += "<ph insID='" + this.guid() + "' style='background-color:#ffffcc ;' tagPHId='" + attrPh + "' restriction='" + attrRestriction + "'></ph>";
					}
				}
			} else if (attrPh != undefined && attrRestriction == undefined) {
				this.html += "<ph insID='" + this.guid() + "' style='background-color:#ffffcc ;' tagPHId='" + attrPh + "'></ph>";
			}
	    } else if (node.nodeName == 'txt') {
            this.html += "<txt";
            let bgc = $(node).attr("bgc");
            let fgc = $(node).attr("fgc");
            if (bgc != null && bgc != "") {
                this.html += " bgc='" + bgc + "' "
            }
            if (fgc != null && fgc != "") {
                this.html += " fgc='" + fgc + "' "
            }
            this.html += ">";
            for (let j = 0; j < $(node)[0].childNodes.length; j++) {
                let childTxtNode = $(node)[0].childNodes[j];
                if ($(childTxtNode)[0].nodeName == '#text') {
                    this.html += childTxtNode.textContent;
                } else {
                    this.processStatement(childTxtNode);
                }
            }
            this.html += "</txt>";

        } else if (node.nodeName == 'ol') {
            let listStyle = $(node).attr("liststyle");
            this.html += '<' + $(node)[0].nodeName + ' contenteditable="false" ckeditorlist="true" style="background-color:#d9d9d9; list-style-type:' + listStyle +
                '">';
            for (let i = 0; i < $(node)[0].childNodes.length; i++) {
                let liNode = $(node)[0].childNodes[i];
                if (liNode.nodeName == 'li') {
                    this.html += '<li>';
                    for (let i = 0; i < liNode.childNodes.length; i++) {
                        let childNode = liNode.childNodes[i];
                        if (childNode.nodeName == 'txt') {
                            $(childNode).removeAttr('style');
                            this.processStatement(childNode);
                        } else if (childNode.nodeName == 'ph') {
                            this.processStatement(childNode);
                        }
                    }
                    this.html += '</li>';
                }
            }
            this.html += '</' + $(node)[0].nodeName + '>';

        } else if (node.nodeName == 'ul') {
            let listStyle = $(node).attr("liststyle");
            this.html += '<' + $(node)[0].nodeName + ' contenteditable="false" ckeditorlist="true" style="background-color:#d9d9d9; list-style-type:' + listStyle +
                '">';
            for (let i = 0; i < $(node)[0].childNodes.length; i++) {
                let liNode = $(node)[0].childNodes[i];
                if (liNode.nodeName == 'li') {
                    this.html += '<li>';
                    for (let i = 0; i < liNode.childNodes.length; i++) {
                        let childNode = liNode.childNodes[i];
                        if (childNode.nodeName == 'txt') {
                            $(childNode).removeAttr('style');
                            this.processStatement(childNode);
                        } else if (childNode.nodeName == 'ph') {
                            this.processStatement(childNode);
                        }
                    }
                    this.html += '</li>';
                }
            }
            this.html += '</' + $(node)[0].nodeName + '>';

        } else {
			let str = node.outerHTML;
			str = str.replace(/<font.*?>/gi, '');
			str = str.replace(/<\/font>/gi, '');
			this.html += str;
		}
	}

	private s4(): string {
		return Math.floor((1 + Math.random()) * 0x10000)
			.toString(16)
			.substring(1);
	}

	private getEditorContent(editorName: string): string {
		let el = this.elementRef.nativeElement.querySelector(editorName);
		return el.innerHTML;
	}

	private getEditorName(editorName: string): string {
		let el = this.elementRef.nativeElement.querySelector(editorName);
		return el.title.split(" ")[3];
	}

	/**
     * Creates and returns random GUID.
     */
	private guid(): string {
		return this.s4() + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + this.s4() + this.s4();
	}

	openModal(template: TemplateRef<any>) {
		this.bsModalRef = this.modalService.show(template);
	}

	openModalCC(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template, { class: 'modal-lg', backdrop: 'static', keyboard: false });
	}

	openModalUndoCheckoutConfirm() {
		this.undocheckoutWarningModalRef = this.modalService.show(this.undo_checkout_warn);
	}

	onGridReady(params) {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
	}

	addStepsContent(i: number) {
		if (this.stepsList[i].groupTitle != null) {
			let el = this.elementRef.nativeElement.querySelector('.gtck_editor' + i);
			el.innerHTML = this.stepsList[i].groupTitle.element;
			if (this.stepsList[i].groupTitle.ncwrs != null) {
				for (let j = 0; j < this.stepsList[i].groupTitle.ncwrs.length; j++ , this.gtNcWRIndex++) {
					let el = this.elementRef.nativeElement.querySelector('.gtck_ncwr_editor' + i + j);
					el.innerHTML = this.stepsList[i].groupTitle.ncwrs[j].element
				}
			}
		}
		let stepEl = this.elementRef.nativeElement.querySelector('.step_ck_editor' + i);
		stepEl.innerHTML = this.stepsList[i].element;
		for (let k = 0; k < this.stepsList[i].ncwrs.length; k++ , this.ncwrIndex++) {
			if (this.stepsList[i].ncwrs[k].type != 'StepLine') {
				let el = this.elementRef.nativeElement.querySelector('.ck_ncwr_editor' + i + k);
				el.innerHTML = this.stepsList[i].ncwrs[k].element;
			}
		}
		for (let a = 0; a < this.stepsList[i].images.length; a++) {
			if (this.stepsList[i].images[a].type == "imageAsFigure") {
				let el = this.elementRef.nativeElement.querySelector('.step_img_editor' + a + i);
				el.innerHTML = this.stepsList[i].images[a].title;
			}
		}
		for (let t = 0; t < this.stepsList[i].documents.length; t++ , this.docIndex++) {
			let el = this.elementRef.nativeElement.querySelector('.ck_doc_editor' + i + t);
			el.innerHTML = this.stepsList[i].documents[t].element;
		}
		if (this.stepsList[i].locationDesc != null) {
			let el = this.elementRef.nativeElement.querySelector('.ck_loc_editor' + i);
			el.innerHTML = this.stepsList[i].locationDesc.element;
		}
		if(this.stepsList[i].responsiblePartyDesc != null){
			for (let n = 0; n < this.stepsList[i].responsiblePartyDesc.length; n++) {
				let el = this.elementRef.nativeElement.querySelector('.ck_resp_editor' + i + n);
				el.innerHTML = this.stepsList[i].responsiblePartyDesc[n].element;
			}
		}
		for (let m = 0; m < this.stepsList[i].substeps.length; m++ , this.subStepIndex++) {
			this.addSubstepContent(i, Number(m))
		}
	}

	addSubstepContent(i: number, m: number) {
		if (this.stepsList[i].substeps[m].groupTitle != null) {
			let el = this.elementRef.nativeElement.querySelector('.gtck_substep_editor' + i + m);
			el.innerHTML = this.stepsList[i].substeps[m].groupTitle.element;
			if (this.stepsList[i].substeps[m].groupTitle.ncwrs != null) {
				for (let n = 0; n < this.stepsList[i].substeps[m].groupTitle.ncwrs.length; n++ , this.subStepGtNcWRIndex++) {
					let el = this.elementRef.nativeElement.querySelector('.gtck_substep_ncwr_editor' + i + m + n);
					el.innerHTML = this.stepsList[i].substeps[m].groupTitle.ncwrs[n].element;
				}
			}
		}
		let substepEl = this.elementRef.nativeElement.querySelector('.substep_ck_editor' + i + m);
		substepEl.innerHTML = this.stepsList[i].substeps[m].element;
		for (let x = 0; x < this.stepsList[i].substeps[m].ncwrs.length; x++ , this.substepNcwrIndex++) {
			if (this.stepsList[i].substeps[m].ncwrs[x].type != 'StepLine') {
				let el = this.elementRef.nativeElement.querySelector('.substep_ck_ncwr_editor' + i + m + x);
				el.innerHTML = this.stepsList[i].substeps[m].ncwrs[x].element;
			}
		}
		for (let a = 0; a < this.stepsList[i].substeps[m].images.length; a++) {
			if (this.stepsList[i].substeps[m].images[a].type == "imageAsFigure") {
				let el = this.elementRef.nativeElement.querySelector('.substep_img_editor' + a + i + m);
				el.innerHTML = this.stepsList[i].substeps[m].images[a].title;
			}
		}
		for (let t = 0; t < this.stepsList[i].substeps[m].documents.length; t++ , this.substepDocIndex++) {
			let el = this.elementRef.nativeElement.querySelector('.substep_ck_doc_editor' + i + m + t);
			el.innerHTML = this.stepsList[i].substeps[m].documents[t].element;
		}
		if (this.stepsList[i].substeps[m].locationDesc != null) {
			let el = this.elementRef.nativeElement.querySelector('.substep_ck_loc_editor' + i + m);
			el.innerHTML = this.stepsList[i].substeps[m].locationDesc.element;
		}
	}

	processContentToSave(data: string) {
		this.content = JSON.parse(data);
		let header = this.content.header;
		this.content.name = this.htmlEncode(this.content.name);
		header.system.name = this.htmlEncode(header.system.name);
		header.procType.name = this.htmlEncode(header.procType.name);
		this.xmlContent = '';
		this.xmlContent += '<Procedure folderId=\"' + this.content.folderId + '\" id=\"' + this.content.id + '\" procName=\"' + this.content.name + '\"><Header>';
		this.xmlContent += '<System id=\"' + header.system.id + '\">' + header.system.name + '</System>';
		this.xmlContent += '<ProcType  id=\"' + header.procType.id + '\">' + header.procType.name + '</ProcType>';
		this.xmlContent += '<ProcDesc><div textAlign="left">';
		this.processXML(header.procDesc.element, false);
		//console.log("***************************************:" +header.procDesc.element)
		let titleNode = "<ProcDesc>"+header.procDesc.element+"</ProcDesc>"
		let encodedContent = this.replaceEntities(titleNode)
		this.getTitle(encodedContent);
		this.xmlContent += '</div></ProcDesc><CustomFields>';
		if (this.assList.length == 1) {
			let data = header.assumption.element;
			this.xmlContent += '<CustomField type="Assumption"><div textAlign="left">';
			this.processXML(data, false);
			this.xmlContent += '</div></CustomField>';
		}
		if (this.objList.length == 1) {
			let data = header.objective.element;
			this.xmlContent += '<CustomField type="Objective"><div textAlign="left">';
			this.processXML(data, false);
			this.xmlContent += '</div></CustomField>';
		}
		this.xmlContent += '</CustomFields><NCWRS>';
		let ncwrList = header.ncwrs;
		for (let j in ncwrList) {
			let data = ncwrList[j].element;
			let seq = Number(j) + 1;
			this.xmlContent += '<NCWR aboveStep="1" seq="' + seq + '" type="' + ncwrList[j].type 
				+ '" backgroundColor="'+ ncwrList[j].backgroundColor +'"><div textAlign="left">';
			this.processXML(data, false);
			this.xmlContent += '</div></NCWR>';
		}
		this.xmlContent += '</NCWRS><Images>';
		let imgList = header.images;
		for (let j in imgList) {
			let type = imgList[j].type;
			let path = imgList[j].thumbPath;
			let seq = Number(j) + 1;
			if (type == 'imageAsFigure') {
				let data = imgList[j].title;
				let insId = imgList[j].insId;
				if (insId != null){
					this.xmlContent += '<Figure insID="' + insId + '" seqID="' + seq + '"><Title><div textAlign="center">';
				}
				else {
					this.xmlContent += '<Figure insID="' + this.guid().toUpperCase() + '" seqID="' + seq + '"><Title><div textAlign="center">';
				}
				this.processXML(data, false);
				this.xmlContent += '</div></Title>';
				this.xmlContent += '<Image id="' + imgList[j].id + '" path="' + path + '"/></Figure>';
			} else if (type == 'imageAsImage') {
				this.xmlContent += '<Image id="' + imgList[j].id + '" path="' + path + '" seqID="' + seq + '"/>';
			}
		}
		this.xmlContent += '</Images></Header><Steps>';
		let stepsList = this.content.steps;
		for (let k in stepsList) {
			let i: number = Number(k);
			let stepNo = i + 1;
			let action = stepsList[i].action ? stepsList[i].action : "";  
			let backgroundColor = stepsList[i].backgroundColor ? stepsList[i].backgroundColor : "";  
			this.xmlContent += '<Step id="' + stepsList[i].id + '"><StepNo>' + stepNo + '</StepNo><Action>'+ action +'</Action><BackgroundColor>'+ backgroundColor +'</BackgroundColor><GroupTitle>';
			if (stepsList[i].groupTitle != null) {
				this.xmlContent += '<div textAlign="left">';
				let data = stepsList[i].groupTitle.element;
				this.processXML(data, false);
				this.xmlContent += '</div>';
				if (stepsList[i].groupTitle.ncwrs != null) {
					this.xmlContent += '<NCWRS>';
					for (let j = 0; j < stepsList[i].groupTitle.ncwrs.length; j++) {
						let data = stepsList[i].groupTitle.ncwrs[j].element;
						let seq = j + 1;
						this.xmlContent += '<NCWR aboveStep="1" seq="' + seq + '" type="' + stepsList[i].groupTitle.ncwrs[j].type
							+ '" backgroundColor="'+ stepsList[i].groupTitle.ncwrs[j].backgroundColor +'"><div textAlign="left">';
						this.processXML(data, false);
						this.xmlContent += '</div></NCWR>';
					}
					this.xmlContent += '</NCWRS>';
				}
			}
			this.xmlContent += '</GroupTitle><DependencySteps>';
			for (let k = 0; k < stepsList[i].dependencySteps.length; k++) {
				this.xmlContent += '<DependencyStep id="' + stepsList[i].dependencySteps[k].value + '">' + stepsList[i].dependencySteps[k].display + '</DependencyStep>';
			}
			this.xmlContent += '</DependencySteps><StepDesc><div textAlign="left">';
			let data = stepsList[i].element;
			this.processXML(data, false);
			this.xmlContent += '</div></StepDesc><NCWRS>';
			for (let k = 0; k < stepsList[i].ncwrs.length; k++) {
				let seq = k + 1;
				let aboveStep = stepsList[i].ncwrs[k].aboveStep;
				let type = stepsList[i].ncwrs[k].type;
				this.xmlContent += '<NCWR aboveStep="' + aboveStep + '" seq="' + seq + '" type="' + type
					+ '" backgroundColor="'+ stepsList[i].ncwrs[k].backgroundColor +'"><div textAlign="left">';
				if (type != 'StepLine') {
					let data = stepsList[i].ncwrs[k].element;
					this.processXML(data, false);
				} else {
					this.xmlContent += '<p><font backgroundcolor="" color="#000000"></font></p>';
				}
				this.xmlContent += '</div></NCWR>';
			}
			this.xmlContent += '</NCWRS><Documents>';
			for (let t = 0; t < stepsList[i].documents.length; t++) {
				let seq = t + 1;
				let data = stepsList[i].documents[t].element;
				this.xmlContent += '<Document seq="' + seq + '"><div textAlign="left">';
				this.processXML(data, false);
				this.xmlContent += '</div></Document>';
			}
			this.xmlContent += '</Documents><LocationDesc>';
			if (stepsList[i].locationDesc != null) {
				let data = stepsList[i].locationDesc.element;
				this.xmlContent += '<Location seq="1"><div textAlign="left">';
				this.processXML(data, false);
				this.xmlContent += '</div></Location>';
			}
			this.xmlContent += '</LocationDesc><ResponsiblePartyDesc>';
			for (let n = 0; n < stepsList[i].responsiblePartyDesc.length; n++) {
				let seq = n + 1;
				let data = stepsList[i].responsiblePartyDesc[n].element;
				this.xmlContent += '<ResponsibleParty seq="' + seq + '"><div textAlign="left">';
				this.processXML(data, false);
				this.xmlContent += '</div></ResponsibleParty>';
			}
			this.xmlContent += '</ResponsiblePartyDesc><OperatingDataItem>';
			for (let n = 0; n < stepsList[i].operatingData.length; n++) {
				let type = stepsList[i].operatingData[n].operatingDataType;
				let id = stepsList[i].operatingData[n].id;
				let name = this.encodeSpecialChar(stepsList[i].operatingData[n].operatingDataName);
				this.xmlContent += '<OperatingData operatingDataType="' + type +
									 '" id="'+ id +'" operatingDataName="'+ name +'">';
				if (type == "Value List"){
					this.xmlContent += "<ValueList>"
					let valueListItems = stepsList[i].operatingData[n].valueListItems;
					console.log(valueListItems);
					let valueListItemList = JSON.parse(valueListItems);
					if(valueListItemList != null){
						for (let m = 0; m < valueListItemList.length; m++) {
							let valueListItem = valueListItemList[m].valueListItem;
							let index = valueListItemList[m].index;
							if (valueListItem != null) {
								valueListItem = this.decodeSpecialChars(valueListItem);
								valueListItem = this.encodeSpecialChar(valueListItem);
								this.xmlContent += '<ValueListItem value="'+ valueListItem +'" index="'+ index +'"/>';
							}
						}
					}
					this.xmlContent += "</ValueList>"
				}
				this.xmlContent += '</OperatingData>';
			}
			this.xmlContent += '</OperatingDataItem><Images>';
			for (let j = 0; j < stepsList[i].images.length; j++) {
				let type = stepsList[i].images[j].type;
				let path = stepsList[i].images[j].thumbPath;
				let title = stepsList[i].images[j].title;
				let id = stepsList[i].images[j].id;
				let seq = j + 1;
				if (type == 'imageAsFigure') {
					let data = stepsList[i].images[j].title;
					let insId = stepsList[i].images[j].insId;
					if (insId != null){
						this.xmlContent += '<Figure insID="' + insId + '" seqID="' + seq + '"><Title><div textAlign="center">';
					}
					else {
						this.xmlContent += '<Figure insID="' + this.guid().toUpperCase() + '" seqID="' + seq + '"><Title><div textAlign="center">';
					}
					this.processXML(data, false);
					this.xmlContent += '</div></Title>';
					this.xmlContent += '<Image id="' + id + '" path="' + path + '"/></Figure>';
				} else if (type == 'imageAsImage') {
					this.xmlContent += '<Image id="' + id + '" path="' + path + '" seqID="' + seq + '"/>';
				}
			}
			this.xmlContent += '</Images><SubSteps>';
			for (let m = 0; m < stepsList[i].substeps.length; m++) {
				let substep = stepsList[i].substeps[m];
				let j: number = m + 1;
				this.xmlContent += '<SubStep id="' + substep.id + '" parentStepId="' + substep.parentStepId + '"><StepNo>' + stepNo + '.' + j + '</StepNo><GroupTitle>';
				if (substep.groupTitle != null) {
					this.xmlContent += '<div textAlign="left">';
					let data = substep.groupTitle.element;
					this.processXML(data, false);
					this.xmlContent += '</div>';
					if (substep.groupTitle.ncwrs != null) {
						this.xmlContent += '<NCWRS>';
						for (let n = 0; n < substep.groupTitle.ncwrs.length; n++) {
							let data = substep.groupTitle.ncwrs[n].element;
							let seq = n + 1;
							this.xmlContent += '<NCWR aboveStep="1" seq="' + seq + '" type="' + substep.groupTitle.ncwrs[n].type
								+ '" backgroundColor="'+ substep.groupTitle.ncwrs[n].backgroundColor +'"><div textAlign="left">';
							this.processXML(data, false);
							this.xmlContent += '</div></NCWR>';
						}
						this.xmlContent += '</NCWRS>';
					}
				}
				this.xmlContent += '</GroupTitle><DependencySteps/><StepDesc><div textAlign="left">';
				let data = substep.element;
				this.processXML(data, false);
				this.xmlContent += '</div></StepDesc><NCWRS>';
				for (let x = 0; x < substep.ncwrs.length; x++) {
					let seq = x + 1;
					let aboveStep = substep.ncwrs[x].aboveStep;
					let type = substep.ncwrs[x].type;
					this.xmlContent += '<NCWR aboveStep="' + aboveStep + '" seq="' + seq + '" type="' + type
						+ '" backgroundColor="'+ substep.ncwrs[x].backgroundColor +'"><div textAlign="left">';
					if (type != 'StepLine') {
						let data = substep.ncwrs[x].element;
						this.processXML(data, false);
					} else {
						this.xmlContent += '<p><font backgroundcolor="" color="#000000"></font></p>';
					}
					this.xmlContent += '</div></NCWR>';
				}
				this.xmlContent += '</NCWRS><Documents>';
				for (let t = 0; t < substep.documents.length; t++) {
					let seq = t + 1;
					let data = substep.documents[t].element;
					this.xmlContent += '<Document seq="' + seq + '"><div textAlign="left">';
					this.processXML(data, false);
					this.xmlContent += '</div></Document>';
				}
				this.xmlContent += '</Documents><LocationDesc>';
				if (substep.locationDesc != null) {
					this.xmlContent += '<Location seq="1"><div textAlign="left">';
					let data = substep.locationDesc.element;
					this.processXML(data, false);
					this.xmlContent += '</div></Location>';
				}
				this.xmlContent += '</LocationDesc><OperatingDataItem>';
				for (let n = 0; n < substep.operatingData.length; n++) {
					let type = substep.operatingData[n].operatingDataType;
					let id = substep.operatingData[n].id;
					let name = this.encodeSpecialChar(substep.operatingData[n].operatingDataName);
					this.xmlContent += '<OperatingData operatingDataType="' + type +
										 '" id="'+ id +'" operatingDataName="'+ name +'">';
					if (type == "Value List"){
						this.xmlContent += "<ValueList>"
						let valueListItems = substep.operatingData[n].valueListItems;
						let valueListItemList = JSON.parse(valueListItems);
						if(valueListItemList != null){
							for (let m = 0; m < valueListItemList.length; m++) {
								let valueListItem = valueListItemList[m].valueListItem;
								let index = valueListItemList[m].index;
								if (valueListItem != null) {
									valueListItem = this.decodeSpecialChars(valueListItem);
									valueListItem = this.encodeSpecialChar(valueListItem);
									this.xmlContent += '<ValueListItem value="'+ valueListItem +'" index="'+ index +'"/>';
								}
							}
						}
						this.xmlContent += "</ValueList>"
					}
					this.xmlContent += '</OperatingData>';
				}
				
				this.xmlContent += '</OperatingDataItem><Images>';
				for (let j = 0; j < substep.images.length; j++) {
					let type = substep.images[j].type;
					let path = substep.images[j].thumbPath;
					let title = substep.images[j].title;
					let id = substep.images[j].id;
					let seq = j + 1;
					if (type == 'imageAsFigure') {
						let data = substep.images[j].title;
						let insId = substep.images[j].insId;
						if (insId != null){
							this.xmlContent += '<Figure insID="' + insId + '" seqID="' + seq + '"><Title><div textAlign="center">';
						}
						else {
							this.xmlContent += '<Figure insID="' + this.guid().toUpperCase() + '" seqID="' + seq + '"><Title><div textAlign="center">';
						}
						this.processXML(data, false);
						this.xmlContent += '</div></Title>';
						this.xmlContent += '<Image id="' + id + '" path="' + path + '"/></Figure>';
					} else if (type == 'imageAsImage') {
						this.xmlContent += '<Image id="' + id + '" path="' + path + '" seqID="' + seq + '"/>';
					}
				}
				this.xmlContent += '</Images></SubStep>';
			}
			this.xmlContent += '</SubSteps></Step>';
		}
		this.xmlContent += '</Steps></Procedure>';
		

		this.xmlContent = this.xmlContent.replace(/insid=/g, "insID=");
		this.xmlContent = this.xmlContent.replace(/compvalue=/g, "compValue=");
		this.xmlContent = this.xmlContent.replace(/comptype=/g, "compType=");
		this.xmlContent = this.xmlContent.replace(/compname=/g, "compName=");
		this.xmlContent = this.xmlContent.replace(/tagphid=/g, "tagPHId=");
		this.xmlContent = this.xmlContent.replace(/lastupdatetimestamp=/g, "lastUpdateTimeStamp=");
		this.xmlContent = this.xmlContent.replace(/specid=/g, "specId=");
		this.xmlContent = this.xmlContent.replace(/<spchar/g, "<spChar");
		this.xmlContent = this.xmlContent.replace(/<\/spchar>/g, "</spChar>");
		this.xmlContent = this.xmlContent.replace(/<p><\/p>/g, "");
		this.xmlContent = this.xmlContent.replace(/<p\/>/g, "");
		this.xmlContent = this.xmlContent.replace(/<tags insID=.{38}\/>/g, "");
		this.xmlContent = this.xmlContent.replace(/<b(?=[\s|>])/g, "<B");
		this.xmlContent = this.xmlContent.replace(/<\/b>/g, "</B>");
		this.xmlContent = this.xmlContent.replace(/<u(?=[\s|>])/g, "<U");
		this.xmlContent = this.xmlContent.replace(/<\/u>/g, "</U>");
		this.xmlContent = this.xmlContent.replace(/<i(?=[\s|>])/g, "<I");
		this.xmlContent = this.xmlContent.replace(/<\/i>/g, "</I>");
	}

	public replaceEntities(content: any) {
        if (content != null) {
			var self = this;
			this.escapeAttrMap = new Map();

			$(content).each(function() {
				var oldNode = this.outerHTML;
				var changedNode = false;			
				$.each(this.attributes, function() {
					if (this.value.includes("<")) {
						changedNode = true;
						this.value = this.value.replace(/</g, "&lt;");
					}
				});
				var newNode = this.outerHTML;
				if (changedNode) {
					self.escapeAttrMap.set(oldNode, newNode);
				}
				$.each(this.childNodes, function() {
					self.escapeAttrValues(this);
				});
			});			
			this.escapeAttrMap.forEach((value: string, key: string) => {
				let newVlaue = value.replace(/&amp;lt;/g,'&lt;');
				let regex = new RegExp(key, 'g');
				content = content.replace(regex, newVlaue);
			});

			content = content.replace(/\s/g, " ");
			content = content.replace(/&amp;/g, "&#38;");
			content = content.replace(/&lt;/g, "&#60;");
			content = content.replace(/&gt;/g, "&#62;");
			content = content.replace(/&nbsp;/g, " ");
			content = content.replace(/\u200B/g, '');
			content = content.replace(/<br>/g, "<br></br>");
        } else {
            content = "";
        }
        return content;
    }

	//encodes special characters
	public encodeSpecialChar(content: any) {
        if (content != null) {
			content = content.toString().replace(/&/g, "&amp;");
			content = content.toString().replace(/>/g, "&gt;");
			content = content.toString().replace(/</g, "&lt;");
			content = content.toString().replace(/\"/g, "&#34;");
			content = content.toString().replace(/\'/g, "&apos;");
        } else {
            content = "";
        }
        return content;
	}

	//decode special characters
	private decodeSpecialChars(content: String){
		if(content != null){
			content = content.replace(/&bsol;/g, "\\");
			content = content.replace(/&sol;/g, "\/");
			content = content.replace(/&quot;/g, "\"");
			content = content.replace(/&apos;/g, "\'");
			content = content.replace(/&lt;/g, "<");
			content = content.replace(/&gt;/g, ">");
			content = content.replace(/&amp;/g, "&");
		}
		return content;
	}

	// saveTemporary() {
	// 	let content: Content = null;
	// 	let header: Header = null;
	// 	let assumption: Assumption = null;
	// 	let objective: Objective = null;
	// 	let ncwrs: Array<NCWRS> = [];
	// 	let images: Array<ProcImage> = [];
	// 	let steps: Array<Step> = [];
	// 	let system = new ProcedureSystem(this.content.header.system.id, this.systemName);
	// 	let procType = new ProcType(this.content.header.procType.id, this.proceType);
	// 	let procDesc = new ProcDesc(this.getEditorContent('.ckeditor_title'));
	// 	if (this.assList.length == 1) {
	// 		let content = this.getEditorContent('.ack_editor0');
	// 		assumption = new Assumption(content);
	// 	}
	// 	if (this.objList.length == 1) {
	// 		let content = this.getEditorContent('.ock_editor0');
	// 		objective = new Objective(content);
	// 	}
	// 	for (let j in this.ncwrList) {
	// 		let data = this.getEditorContent('.ck_editor' + j);
	// 		let seq = Number(j) + 1;
	// 		let ncwr = new NCWRS(this.ncwrList[j].type, seq, 1, data);
	// 		ncwrs.push(ncwr);
	// 	}
	// 	images = this.imageList;
	// 	header = new Header(system, procType, procDesc, assumption, objective, ncwrs, images, this.imagePanelFlag);
	// 	for (let k in this.stepsList) {
	// 		let i: number = Number(k);
	// 		let stepNo = i + 1;
	// 		let groupTitle: GroupTitle = null;
	// 		let dependencySteps: Array<DependencyStep> = [];
	// 		let dependencyFlag: boolean = false;
	// 		let ncwrs: Array<NCWRS> = [];
	// 		let documents: Array<Document> = [];
	// 		let locationDesc: Location = null;
	// 		let substeps: Array<SubStep> = [];
	// 		if (this.stepsList[i].groupTitle != null) {
	// 			let data = this.getEditorContent('.gtck_editor' + i);
	// 			let ncwrs: Array<NCWRS> = [];
	// 			if (this.stepsList[i].groupTitle.ncwrs != null) {
	// 				for (let j = 0; j < this.stepsList[i].groupTitle.ncwrs.length; j++) {
	// 					let data = this.getEditorContent('.gtck_ncwr_editor' + i + j);
	// 					let seq = j + 1;
	// 					let ncwr = new NCWRS(this.stepsList[i].groupTitle.ncwrs[j].type, seq, 1, data);
	// 					ncwrs.push(ncwr);
	// 				}
	// 			}
	// 			groupTitle = new GroupTitle(ncwrs, data);
	// 		}
	// 		for (let k = 0; k < this.stepsList[i].dependencySteps.length; k++) {
	// 			let dependency = new DependencyStep(this.stepsList[i].dependencySteps[k].display, this.stepsList[i].dependencySteps[k].value);
	// 			dependencySteps.push(dependency);
	// 			dependencyFlag = true;
	// 		}
	// 		let data = this.getEditorContent('.step_ck_editor' + i);
	// 		for (let k = 0; k < this.stepsList[i].ncwrs.length; k++) {
	// 			let seq = k + 1;
	// 			let aboveStep = this.stepsList[i].ncwrs[k].aboveStep;
	// 			let type = this.stepsList[i].ncwrs[k].type;
	// 			let data;
	// 			if (type != 'StepLine') {
	// 				data = this.getEditorContent('.ck_ncwr_editor' + i + k);
	// 			} else {
	// 				data = '<p>&#8203;</p>';
	// 			}
	// 			let ncwr = new NCWRS(type, seq, aboveStep, data);
	// 			ncwrs.push(ncwr);
	// 		}
	// 		for (let t = 0; t < this.stepsList[i].documents.length; t++) {
	// 			let seq = t + 1;
	// 			let data = this.getEditorContent('.ck_doc_editor' + i + t);
	// 			let doc = new Document(seq, data);
	// 			documents.push(doc);
	// 		}
	// 		if (this.stepsList[i].locationDesc != null) {
	// 			let data = this.getEditorContent('.ck_loc_editor' + i);
	// 			locationDesc = new Location(1, data);
	// 		}
	// 		for (let m = 0; m < this.stepsList[i].substeps.length; m++) {
	// 			let sstep: SubStep = null;
	// 			let groupTitle: GroupTitle = null;
	// 			let ncwrs: Array<NCWRS> = [];
	// 			let documents: Array<Document> = [];
	// 			let locationDesc: Location = null;
	// 			let substep = this.stepsList[i].substeps[m];
	// 			let j: number = m + 1;
	// 			if (substep.groupTitle != null) {
	// 				let ncwrs: Array<NCWRS> = [];
	// 				let data = this.getEditorContent('.gtck_substep_editor' + i + m);
	// 				if (substep.groupTitle.ncwrs != null) {
	// 					for (let n = 0; n < substep.groupTitle.ncwrs.length; n++) {
	// 						let data = this.getEditorContent('.gtck_substep_ncwr_editor' + i + m + n);
	// 						let seq = n + 1;
	// 						let ncwr = new NCWRS(substep.groupTitle.ncwrs[n].type, seq, 1, data);
	// 						ncwrs.push(ncwr);
	// 					}
	// 				}
	// 				groupTitle = new GroupTitle(ncwrs, data);
	// 			}
	// 			let data = this.getEditorContent('.substep_ck_editor' + i + m);
	// 			for (let x = 0; x < substep.ncwrs.length; x++) {
	// 				let seq = x + 1;
	// 				let aboveStep = substep.ncwrs[x].aboveStep;
	// 				let type = substep.ncwrs[x].type;
	// 				let data;
	// 				if (type != 'StepLine') {
	// 					data = this.getEditorContent('.substep_ck_ncwr_editor' + i + m + x);
	// 				} else {
	// 					data = '<p>&#8203;</p>"';
	// 				}
	// 				let ncwr = new NCWRS(type, seq, aboveStep, data);
	// 				ncwrs.push(ncwr);
	// 			}
	// 			for (let t = 0; t < substep.documents.length; t++) {
	// 				let seq = t + 1;
	// 				let data = this.getEditorContent('.substep_ck_doc_editor' + i + m + t);
	// 				let doc = new Document(seq, data);
	// 				documents.push(doc);
	// 			}
	// 			if (substep.locationDesc != null) {
	// 				let data = this.getEditorContent('.substep_ck_loc_editor' + i + m);

	// 				locationDesc = new Location(1, data);
	// 			}
	// 			sstep = new SubStep(substep.id, substep.parentStepId, j, groupTitle, data, ncwrs, documents, locationDesc, substep.images, substep.imagePanelFlag);
	// 			substeps.push(sstep);
	// 		}
	// 		let step = new Step(this.stepsList[i].id, stepNo, groupTitle, dependencySteps, dependencyFlag, data, ncwrs, documents,
	// 			locationDesc, this.stepsList[i].images, this.stepsList[i].imagePanelFlag, substeps);
	// 		steps.push(step);
	// 	}
	// 	content = new Content(this.content.folderId, this.content.folderPath, this.content.id, this.content.name, header, steps);
	// 	if (this.isCheckOut) {
	// 		localStorage.setItem(this.tempSaveKey, JSON.stringify(content));
	// 	}
	// }

	getValues () {
		let content: Content = null;		
		let header: Header = null;
		let assumption: Assumption = null;
		let objective: Objective = null;
		let ncwrs: Array<NCWRS> = [];
		let images: Array<ProcImage> = [];
		let steps: Array<Step> = [];
		let system = new ProcedureSystem(this.content.header.system.id, this.systemName);
		let procType = new ProcType(this.content.header.procType.id, this.proceType);
		let procDesc = new ProcDesc(this.getEditorContent('.ckeditor_title'));
		if (this.assList.length == 1) {
			let content = this.getEditorContent('.ack_editor0');
			assumption = new Assumption(content);
		}
		if (this.objList.length == 1) {
			let content = this.getEditorContent('.ock_editor0');
			objective = new Objective(content);
		}
		for (let j in this.ncwrList) {
			let data = this.getEditorContent('.ck_editor' + j);
			let seq = Number(j) + 1;
			let backgroundColor = this.ncwrList[j].backgroundColor ? this.ncwrList[j].backgroundColor : "";
			let ncwr = new NCWRS(this.ncwrList[j].type, seq, 1, data, backgroundColor);
			ncwrs.push(ncwr);
		}
		for (let a in this.imageList) {
			if (this.imageList[a].type == "imageAsFigure") {
				let data = this.getEditorContent('.img_editor' + a);
				this.imageList[a].title = data;
			}
		}
		images = this.imageList;
		header = new Header(system, procType, procDesc, assumption, objective, ncwrs, images, this.imagePanelFlag);
		for (let k in this.stepsList) {
			let i: number = Number(k);
			let stepNo = i + 1;
			let action = this.stepsList[i].action ? this.stepsList[i].action : "";
			let backgroundColor = this.stepsList[i].backgroundColor ? this.stepsList[i].backgroundColor : ""
			let groupTitle: GroupTitle = null;
			let dependencySteps: Array<DependencyStep> = [];
			let dependencyFlag: boolean = false;
			let ncwrs: Array<NCWRS> = [];
			let documents: Array<Document> = [];
			let locationDesc: Location = null;
			let responsiblePartyDesc: Array<ResponsibleParty> = [];
			let operatingData: Array<OperatingData> = [];
			let operatingDataFlag: boolean = false;
			let substeps: Array<SubStep> = [];
			if (this.stepsList[i].groupTitle != null) {
				let data = this.getEditorContent('.gtck_editor' + i);
				let ncwrs: Array<NCWRS> = [];
				if (this.stepsList[i].groupTitle.ncwrs != null) {
					for (let j = 0; j < this.stepsList[i].groupTitle.ncwrs.length; j++) {
						let data = this.getEditorContent('.gtck_ncwr_editor' + i + j);
						let seq = j + 1;
						let backgroundColor = this.stepsList[i].groupTitle.ncwrs[j].backgroundColor ? this.stepsList[i].groupTitle.ncwrs[j].backgroundColor : "";
						let ncwr = new NCWRS(this.stepsList[i].groupTitle.ncwrs[j].type, seq, 1, data, backgroundColor);
						ncwrs.push(ncwr);
					}
				}
				groupTitle = new GroupTitle(ncwrs, data);
			}
			for (let k = 0; k < this.stepsList[i].dependencySteps.length; k++) {
				let dependency = new DependencyStep(this.stepsList[i].dependencySteps[k].display, this.stepsList[i].dependencySteps[k].value);
				dependencySteps.push(dependency);
				dependencyFlag = true;
			}
			let data = this.getEditorContent('.step_ck_editor' + i);
			for (let k = 0; k < this.stepsList[i].ncwrs.length; k++) {
				let seq = k + 1;
				let aboveStep = this.stepsList[i].ncwrs[k].aboveStep;
				let type = this.stepsList[i].ncwrs[k].type;
				let data;
				if (type != 'StepLine') {
					data = this.getEditorContent('.ck_ncwr_editor' + i + k);
				} else {
					data = '<p>&#8203;</p>';
				}
				let backgroundColor = this.stepsList[i].ncwrs[k].backgroundColor ? this.stepsList[i].ncwrs[k].backgroundColor : "";
				let ncwr = new NCWRS(type, seq, aboveStep, data, backgroundColor);
				ncwrs.push(ncwr);
			}
			for (let t = 0; t < this.stepsList[i].documents.length; t++) {
				let seq = t + 1;
				let data = this.getEditorContent('.ck_doc_editor' + i + t);
				let doc = new Document(seq, data);
				documents.push(doc);
			}
			if (this.stepsList[i].locationDesc != null) {
				let data = this.getEditorContent('.ck_loc_editor' + i);
				locationDesc = new Location(1, data);
			}
			if (this.stepsList[i].responsiblePartyDesc != undefined){
				for (let n = 0; n < this.stepsList[i].responsiblePartyDesc.length; n++) {
					let seq = n + 1;
					let data = this.getEditorContent('.ck_resp_editor' + i + n);
					let resp = new ResponsibleParty(seq, data);
					responsiblePartyDesc.push(resp);
				}
			}
			if (this.stepsList[i].operatingData != undefined){
				for (let n = 0; n < this.stepsList[i].operatingData.length; n++) {
					let data = this.stepsList[i].operatingData[n];
					//let optData = new OperatingData(seq, data);
					operatingData.push(data);
					operatingDataFlag = true;
				}
			}
			for (let a in this.stepsList[i].images) {
				if (this.stepsList[i].images[a].type == "imageAsFigure") {
					let data = this.getEditorContent('.step_img_editor' + a + i);
					this.stepsList[i].images[a].title = data;
				}
			}
			for (let m = 0; m < this.stepsList[i].substeps.length; m++) {
				let sstep: SubStep = null;
				let groupTitle: GroupTitle = null;
				let ncwrs: Array<NCWRS> = [];
				let documents: Array<Document> = [];
				let locationDesc: Location = null;
				let responsiblePartyDesc: Array<ResponsibleParty> = [];
				let operatingData: Array<OperatingData> = [];
				let operatingDataFlag: boolean = false;
				let substep = this.stepsList[i].substeps[m];
				let j: number = m + 1;
				if (substep.groupTitle != null) {
					let ncwrs: Array<NCWRS> = [];
					let data = this.getEditorContent('.gtck_substep_editor' + i + m);
					if (substep.groupTitle.ncwrs != null) {
						for (let n = 0; n < substep.groupTitle.ncwrs.length; n++) {
							let data = this.getEditorContent('.gtck_substep_ncwr_editor' + i + m + n);
							let seq = n + 1;
							let backgroundColor = substep.groupTitle.ncwrs[n].backgroundColor ? substep.groupTitle.ncwrs[n].backgroundColor : "";
							let ncwr = new NCWRS(substep.groupTitle.ncwrs[n].type, seq, 1, data, backgroundColor);
							ncwrs.push(ncwr);
						}
					}
					groupTitle = new GroupTitle(ncwrs, data);
				}
				let data = this.getEditorContent('.substep_ck_editor' + i + m);
				for (let x = 0; x < substep.ncwrs.length; x++) {
					let seq = x + 1;
					let aboveStep = substep.ncwrs[x].aboveStep;
					let type = substep.ncwrs[x].type;
					let data;
					if (type != 'StepLine') {
						data = this.getEditorContent('.substep_ck_ncwr_editor' + i + m + x);
					} else {
						data = '<p>&#8203;</p>"';
					}
					let backgroundColor = substep.ncwrs[x].backgroundColor ? substep.ncwrs[x].backgroundColor : "";
					let ncwr = new NCWRS(type, seq, aboveStep, data, backgroundColor);
					ncwrs.push(ncwr);
				}
				for (let t = 0; t < substep.documents.length; t++) {
					let seq = t + 1;
					let data = this.getEditorContent('.substep_ck_doc_editor' + i + m + t);
					let doc = new Document(seq, data);
					documents.push(doc);
				}
				if (substep.locationDesc != null) {
					let data = this.getEditorContent('.substep_ck_loc_editor' + i + m);

					locationDesc = new Location(1, data);
				}
				if (substep.operatingData != undefined){
					for (let n = 0; n < substep.operatingData.length; n++) {
						let data = substep.operatingData[n];
						//let optData = new OperatingData(seq, data);
						operatingData.push(data);
						operatingDataFlag = true;
					}
				}
				for (let a in substep.images) {
					if (substep.images[a].type == "imageAsFigure") {
						let data = this.getEditorContent('.substep_img_editor' + a + i + m);
						substep.images[a].title = data;
					}
				}
				sstep = new SubStep(substep.id, substep.parentStepId, j, groupTitle, data, ncwrs, documents, locationDesc, operatingData, operatingDataFlag, substep.images, substep.imagePanelFlag);
				substeps.push(sstep);
			}
			let step = new Step(this.stepsList[i].id, stepNo, action, backgroundColor, groupTitle, dependencySteps, dependencyFlag, data, ncwrs, documents,
				locationDesc, responsiblePartyDesc,operatingData, operatingDataFlag, this.stepsList[i].images, this.stepsList[i].imagePanelFlag, substeps);
			steps.push(step);
		}
		content = new Content(this.content.folderId, this.content.folderPath, this.content.id, this.content.name, header, steps);
		if (this.isCheckOut) {
			this.contentStr = JSON.stringify(content);
		}
	}

	save() {
		if(!navigator.onLine){
			this.alertService.clear();
			this.alertService.error(this.translate.instant('PROCEDURE_EDITOR.SAVE_NO_INTERENET_CONNECTION'), true);
			return;
		}
		this.loadingService.showLoading(true, false, "", 0);
		this.getValues();
		this.processContentToSave(this.contentStr);
		console.log("Content to Save : " +this.xmlContent);
		this.procedureService.saveProcedureContent(this.fileId, this.xmlContent, this.title).subscribe(
			data => {
				if (data.statusCode == "200") {
					this.sharedService.setViewOnlyStatus(this.fileId, true);
					this.loadingService.hideLoading();
					this.alertService.clear();
					this.alertService.success(this.translate.instant('PROCEDURE_EDITOR.SAVE_MSG'), true);
				} else {
					this.alertService.clear();
					this.alertService.error(this.translate.instant('PROCEDURE_EDITOR.SAVE_ERROR_MSG'), true);
					this.loadingService.hideLoading();
				}
			},
			error => {
				if (error.statusCode == "543") {
					this.loadConflictData(error.payload)
					this.openModalCC(this.conflict_checker);
				}else if (error.statusCode == "0") {
					this.alertService.clear();
					this.alertService.error(this.translate.instant('PROCEDURE_EDITOR.CONNECTION_LOST'), true);
				} else {
					if(error.statusName == "TimeoutError"){
						if(!navigator.onLine){
							this.alertService.clear();
							this.alertService.error(this.translate.instant('PROCEDURE_EDITOR.CONNECTION_LOST'), true);
							this.loadingService.hideLoading();
							return;
						}
						else{
							this.alertService.clear();
							this.alertService.error(this.translate.instant('PROCEDURE_EDITOR.SAVE_TIMEOUT'), true);
							this.loadingService.hideLoading();
							return;
						}
					}
					if(error.statusDescription == "Procedure already checked in"){
						this.setReadOnlyEditors();
						this.isCheckOut = false;
						this.isCheckOutByOthers = false;
						this.sharedService.setViewOnlyStatus(this.fileId, true);
						this.contentStr = "";
					}
					this.alertService.clear();
					this.alertService.error(error.statusDescription);
				}
				this.loadingService.hideLoading();
			});
	}

	checkIn(comments) {
		if(!navigator.onLine){
			this.alertService.clear();
			this.alertService.error(this.translate.instant('PROCEDURE_EDITOR.CHECKIN_NO_INTERENET_CONNECTION'), true);
			return;
		}
		this.loadingService.showLoading(true, false, "", 0);
		this.getValues();
		this.processContentToSave(this.contentStr);
		this.bsModalRef.hide();
		console.log("Content to Checkin : " +this.xmlContent);
		this.procedureService.checkinProcedureContent(this.fileId, this.xmlContent, this.title, comments).subscribe(
			data => {
				if (data.statusCode == "200") {
					this.setReadOnlyEditors();
					this.isCheckOut = false;
					this.isCheckOutByOthers = false;
					if (!this.isMobile) {
						$('.toggle-edit' + SharedService.getTabId() + this.userId).unbind("dblclick");
					} else {
						$('.toggle-edit' + SharedService.getTabId() + this.userId).unbind("click");
					}
					this.loadingService.hideLoading();
					this.alertService.clear();
					this.alertService.success(this.translate.instant('PROCEDURE_EDITOR.CHECKIN_MSG'), true);
					this.sharedService.setViewOnlyStatus(this.fileId, true);
					this.contentStr = "";
					// localStorage.removeItem(this.tempSaveKey);
				} else {
					this.alertService.clear();
					this.alertService.error(this.translate.instant('PROCEDURE_EDITOR.CHECKIN_ERROR_MSG'), true);
					this.loadingService.hideLoading();
				}
			},
			error => {
				if (error.statusCode == "543") {
					this.loadConflictData(error.payload)
					this.openModalCC(this.conflict_checker);
				} else if (error.statusCode == "0") {
					this.alertService.clear();
					this.alertService.error(this.translate.instant('PROCEDURE_EDITOR.CONNECTION_LOST'), true);
				} else {
					if(error.statusName == "TimeoutError"){
						if(!navigator.onLine){
							this.alertService.clear();
							this.alertService.error(this.translate.instant('PROCEDURE_EDITOR.CONNECTION_LOST'), true);
							this.loadingService.hideLoading();
							return;
						}
						else{
							this.alertService.clear();
							this.alertService.error(this.translate.instant('PROCEDURE_EDITOR.CHECKIN_TIMEOUT'), true);
							this.loadingService.hideLoading();
							return;
						}
					}
					if(error.statusDescription == "Procedure already checked in"){
						this.setReadOnlyEditors();
						this.isCheckOut = false;
						this.isCheckOutByOthers = false;
						this.sharedService.setViewOnlyStatus(this.fileId, true);
						this.contentStr = "";
					}
					this.alertService.clear();
					this.alertService.error(error.statusDescription);
				}
				this.loadingService.hideLoading();
			});
	}

	checkout() {
		this.loadingService.showLoading(true, false, "", 0);
		this.procedureService.checkoutProcedure(this.fileId, this.fileType, this.folderPath).subscribe(
			data => {
				if (data.statusCode == "200") {
					this.loadingService.showLoading(
						true, false, this.translate.instant('PROCEDURE_EDITOR.LOADING'), 0);
					this.isCheckOut = true;
					this.isCheckOutByOthers = false;
					if (data.payload != null) {
						this.loadUndoOrCheckoutData(JSON.stringify(data.payload));
					}
					this.sharedService.setViewOnlyStatus(this.fileId, true);
					this.loadingService.hideLoading();
					this.alertService.clear();
					this.alertService.success(this.translate.instant('PROCEDURE_EDITOR.CHECKOUT_MSG'), true);
				} else {
					this.alertService.clear();
					this.alertService.error(this.translate.instant('PROCEDURE_EDITOR.CHECKOUT_ERROR_MSG'), true);
					this.loadingService.hideLoading();
				}
			},
			error => {
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
				this.loadingService.hideLoading();
			});
	}

	undoCheckout() {
		this.loadingService.showLoading(true, false, "", 0);
		this.procedureService.undoCheckoutProcedure(this.fileId, this.folderPath).subscribe(
			data => {
				if (data.statusCode == "200") {
					this.loadingService.showLoading(
						true, false, this.translate.instant('PROCEDURE_EDITOR.LOADING'), 0);
					this.isCheckOut = false;
					this.isCheckOutByOthers = false;
					if (data.payload != null) {
						this.loadUndoOrCheckoutData(JSON.stringify(data.payload));
					}
					this.setReadOnlyEditors();
					this.sharedService.setViewOnlyStatus(this.fileId, true);
					this.loadingService.hideLoading();
					this.alertService.clear();
					this.alertService.success(this.translate.instant('PROCEDURE_EDITOR.UNDO_CHECKOUT_MSG'), true);
					this.contentStr = "";
					// localStorage.removeItem(this.tempSaveKey);
				} else {
					this.alertService.clear();
					this.alertService.error(this.translate.instant('PROCEDURE_EDITOR.UNDO_CHECKOUT_ERROR_MSG'), true);
					this.loadingService.hideLoading();
				}
			},
			error => {
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
				this.loadingService.hideLoading();
			});
	}

	getTitle(xml:any) {
		xml = xml.replace(/<br type="_moz">/g, "<br></br>");
		let xmlDoc;
		try {
			xmlDoc = $.parseXML(xml);
		} catch {
			this.alertService.clear();
			this.alertService.error(this.translate.instant('INVALID_XML_MSG'), true);
		}
		let titleNode = $(xmlDoc).find('ProcDesc');
		this.title = $(titleNode)[0].textContent;
		console.log("Title ------------------------" +this.title)
	}

	addAO(type: string) {
		if (type == 'Assumption' && this.assList.length == 0) {
			this.sharedService.setViewOnlyStatus(this.fileId, false);
			this.assList.push({ element: '' });
			setTimeout(() => {
				let el = this.elementRef.nativeElement.querySelector('.ack_editor0');
				this.activeEvent(el);
				el.innerHTML = this.setElementTag();
				this.focusNewEditor('.ack_editor0');
				this.setEditor();
			}, 200);
		} else if (type == 'Objective' && this.objList.length == 0) {
			this.sharedService.setViewOnlyStatus(this.fileId, false);
			this.objList.push({ element: '' });
			setTimeout(() => {
				let el = this.elementRef.nativeElement.querySelector('.ock_editor0');
				this.activeEvent(el);
				el.innerHTML = this.setElementTag();
				this.focusNewEditor('.ock_editor0');
				this.setEditor();
			}, 200);
		}
	}

	addNCWR(type: string) {
		this.sharedService.setViewOnlyStatus(this.fileId, false);
		this.ncwrList.push(new NCWRS(type, this.index + 1, 1, "", ""));
		setTimeout(() => {
			let i = this.ncwrList.length - 1;
			let el = this.elementRef.nativeElement.querySelector('.ck_editor' + i);
			this.activeEvent(el);
			el.innerHTML = this.setElementTag();
			this.focusNewEditor('.ck_editor' + i);
			this.setEditor();
			this.index++;
		}, 200);
	}

	addGtNCWR(type: string, step: Step) {
		let j: number;
		let lastIndex: number;
		this.sharedService.setViewOnlyStatus(this.fileId, false);
		for (let i in this.stepsList) {
			if (this.stepsList[i].stepNo === step.stepNo) {
				j = Number(i);
				lastIndex = this.stepsList[i].groupTitle.ncwrs.length;
				this.stepsList[i].groupTitle.ncwrs.push(new NCWRS(type, lastIndex + 1, 1, "", ""));
				break;
			}
		}
		setTimeout(() => {
			let el = this.elementRef.nativeElement.querySelector('.gtck_ncwr_editor' + j + lastIndex);
			this.activeEvent(el);
			el.innerHTML = this.setElementTag();
			this.focusNewEditor('.gtck_ncwr_editor' + j + lastIndex);
			this.setEditor();
			this.gtNcWRIndex++;
		}, 200);
	}

	addSubStepGtNCWR(type: string, step: Step, substep: SubStep) {
		let j, n: number;
		let lastIndex: number;
		this.sharedService.setViewOnlyStatus(this.fileId, false);
		for (let i in this.stepsList) {
			if (this.stepsList[i].stepNo === step.stepNo) {
				j = Number(i);
				for (let k in this.stepsList[j].substeps) {
					if (this.stepsList[j].substeps[k].stepNo === substep.stepNo) {
						n = Number(k);
						lastIndex = substep.groupTitle.ncwrs.length;
						substep.groupTitle.ncwrs.push(new NCWRS(type, lastIndex + 1, 1, "", ""));
						break;
					}
				}
				break;
			}
		}
		setTimeout(() => {
			let el = this.elementRef.nativeElement.querySelector('.gtck_substep_ncwr_editor' + j + n + lastIndex);
			this.activeEvent(el);
			el.innerHTML = this.setElementTag();
			this.focusNewEditor('.gtck_substep_ncwr_editor' + j + n + lastIndex);
			this.setEditor();
			this.subStepGtNcWRIndex++;
		}, 200);
	}

	public deleteNCWR(ncwr: NCWRS) {
		let j: number;
		this.sharedService.setViewOnlyStatus(this.fileId, false);
		for (let i in this.ncwrList) {
			if (this.ncwrList[i].seq === ncwr.seq) {
				j = Number(i);
				let name = this.getEditorName('.ck_editor' + j);
				if (CKEDITOR.instances[name] != undefined) {
					CKEDITOR.instances[name].destroy();
				}
				break;
			}
		}
		this.ncwrList.splice(j, 1);
	}

	public deleteAO(type: String) {
		this.sharedService.setViewOnlyStatus(this.fileId, false);
		if (type == 'Assumption') {
			let name = this.getEditorName('.ack_editor0');
			if (CKEDITOR.instances[name] != undefined) {
				CKEDITOR.instances[name].destroy();
			}
			this.assList.splice(0, 1);
		} else if (type == 'Objective') {
			let name = this.getEditorName('.ock_editor0');
			if (CKEDITOR.instances[name] != undefined) {
				CKEDITOR.instances[name].destroy();
			}
			this.objList.splice(0, 1);
		}
	}

	public deleteGroupTitle(step: Step) {
		this.sharedService.setViewOnlyStatus(this.fileId, false);
		for (let i in this.stepsList) {
			if (this.stepsList[i].stepNo === step.stepNo) {
				this.deleteGroupTitleEditors(Number(i), step);
				break;
			}
		}
		step.groupTitle = null;
	}

	private deleteGroupTitleEditors(i: number, step: Step) {
		let name = this.getEditorName('.gtck_editor' + i);
		if (CKEDITOR.instances[name] != undefined) {
			CKEDITOR.instances[name].destroy();
		}
		for (let k in step.groupTitle.ncwrs) {
			let name = this.getEditorName('.gtck_ncwr_editor' + i + Number(k));
			if (CKEDITOR.instances[name] != undefined) {
				CKEDITOR.instances[name].destroy();
			}
		}
	}

	public deleteSubStepGroupTitle(step: Step, substep: SubStep) {
		this.sharedService.setViewOnlyStatus(this.fileId, false);
		for (let i in this.stepsList) {
			if (this.stepsList[i].stepNo === step.stepNo) {
				for (let k in step.substeps) {
					if (step.substeps[k].stepNo === substep.stepNo) {
						this.deleteSubStepGroupTitleEditors(Number(i), Number(k), substep);
						break;
					}
				}
				break;
			}
		}
		substep.groupTitle = null;
	}

	private deleteSubStepGroupTitleEditors(i: number, j: number, substep: SubStep) {
		let name = this.getEditorName('.gtck_substep_editor' + i + j);
		if (CKEDITOR.instances[name] != undefined) {
			CKEDITOR.instances[name].destroy();
		}
		for (let m in substep.groupTitle.ncwrs) {
			let name = this.getEditorName('.gtck_substep_ncwr_editor' + i + j + Number(m));
			if (CKEDITOR.instances[name] != undefined) {
				CKEDITOR.instances[name].destroy();
			}
		}
	}

	public moveUpNCWR(ncwr: NCWRS, step: Step, substep: SubStep) {
		let prev, ncwrList;
		this.sharedService.setViewOnlyStatus(this.fileId, false);
		if (substep != undefined) {
			ncwrList = substep.groupTitle.ncwrs;
		} else if (step != undefined) {
			ncwrList = step.groupTitle.ncwrs;
		} else {
			ncwrList = this.ncwrList;
		}
		for (let i in ncwrList) {
			if (ncwrList[i].seq === ncwr.seq) {
				prev = ncwrList[Number(i) - 1];
				break;
			}
		}
		let seq = ncwr.seq;
		ncwr.seq = ncwr.seq - 1;
		prev.seq = seq;
	}

	public moveDownNCWR(ncwr: NCWRS, step: Step, substep: SubStep) {
		let next, ncwrList;
		this.sharedService.setViewOnlyStatus(this.fileId, false);
		if (substep != undefined) {
			ncwrList = substep.groupTitle.ncwrs;
		} else if (step != undefined) {
			ncwrList = step.groupTitle.ncwrs;
		} else {
			ncwrList = this.ncwrList;
		}
		for (let i in ncwrList) {
			if (ncwrList[i].seq === ncwr.seq) {
				next = ncwrList[Number(i) + 1];
				break;
			}
		}
		let seq = ncwr.seq;
		ncwr.seq = ncwr.seq + 1;
		next.seq = seq;
	}

	public deleteGtNCWR(ncwr: NCWRS, step: Step) {
		let j: number;
		let ncwrList = step.groupTitle.ncwrs;
		this.sharedService.setViewOnlyStatus(this.fileId, false);
		for (let i in this.stepsList) {
			if (this.stepsList[i].stepNo === step.stepNo) {
				for (let k in ncwrList) {
					if (ncwrList[k].seq === ncwr.seq) {
						j = Number(k);
						let name = this.getEditorName('.gtck_ncwr_editor' + Number(i) + j);
						if (CKEDITOR.instances[name] != undefined) {
							CKEDITOR.instances[name].destroy();
						}
						break;
					}
				}
				break;
			}
		}
		ncwrList.splice(j, 1);
	}

	public deleteSubStepGtNCWR(ncwr: NCWRS, step: Step, substep: SubStep) {
		let j, n, x: number;
		let ncwrList = substep.groupTitle.ncwrs;
		this.sharedService.setViewOnlyStatus(this.fileId, false);
		for (let i in this.stepsList) {
			if (this.stepsList[i].stepNo === step.stepNo) {
				j = Number(i);
				for (let k in this.stepsList[j].substeps) {
					if (this.stepsList[j].substeps[k].stepNo === substep.stepNo) {
						n = Number(k);
						for (let m in ncwrList) {
							if (ncwrList[m].seq === ncwr.seq) {
								x = Number(m);
								let name = this.getEditorName('.gtck_substep_ncwr_editor' + j + n + x);
								if (CKEDITOR.instances[name] != undefined) {
									CKEDITOR.instances[name].destroy();
								}
								break;
							}
						}
						break;
					}
				}
				break;
			}
		}
		ncwrList.splice(x, 1);
	}

	public moveUpStepNCWR(ncwr: NCWRS, step: Step, substep: SubStep) {
		let prev;
		let ncwrList;
		this.sharedService.setViewOnlyStatus(this.fileId, false);
		if (substep != undefined) {
			ncwrList = substep.ncwrs;
		} else {
			ncwrList = step.ncwrs;
		}
		for (let i in ncwrList) {
			if (ncwrList[i].seq === ncwr.seq) {
				prev = ncwrList[Number(i) - 1];
				if (prev.type == 'StepLine') {
					ncwr.aboveStep = 1;
				} else {
					// nothing to do
				}
				break;
			}
		}
		let seq = ncwr.seq;
		ncwr.seq = ncwr.seq - 1;
		prev.seq = seq;
	}

	public moveDownStepNCWR(ncwr: NCWRS, step: Step, substep: SubStep) {
		let next;
		let ncwrList;
		this.sharedService.setViewOnlyStatus(this.fileId, false);
		if (substep != undefined) {
			ncwrList = substep.ncwrs;
		} else {
			ncwrList = step.ncwrs;
		}
		for (let i in ncwrList) {
			if (ncwrList[i].seq === ncwr.seq) {
				next = ncwrList[Number(i) + 1];
				if (next.type == 'StepLine') {
					ncwr.aboveStep = 0;
				} else {
					// nothing to do
				}
				break;
			}
		}
		let seq = ncwr.seq;
		ncwr.seq = ncwr.seq + 1;
		next.seq = seq;
	}

	public deleteStepNCWR(ncwr: NCWRS, step: Step) {
		let j: number;
		let ncwrList = step.ncwrs;
		this.sharedService.setViewOnlyStatus(this.fileId, false);
		for (let i in this.stepsList) {
			if (this.stepsList[i].stepNo === step.stepNo) {
				for (let k in ncwrList) {
					if (ncwrList[k].seq === ncwr.seq) {
						j = Number(k);
						this.deleteStepNCWREditors(Number(i), j);
						break;
					}
				}
				break;
			}
		}
		if (ncwrList.length == 2) {
			ncwrList.splice(0, 2);
		} else {
			ncwrList.splice(j, 1);
		}
	}

	private deleteStepNCWREditors(i: number, j: number) {
		let name = this.getEditorName('.ck_ncwr_editor' + i + j);
		if (CKEDITOR.instances[name] != undefined) {
			CKEDITOR.instances[name].destroy();
		}
	}

	public deleteSubStepNCWR(ncwr: NCWRS, step: Step, substep: SubStep) {
		let x: number;
		let ncwrList = substep.ncwrs;
		this.sharedService.setViewOnlyStatus(this.fileId, false);
		for (let i in this.stepsList) {
			if (this.stepsList[i].stepNo === step.stepNo) {
				for (let k in step.substeps) {
					if (step.substeps[k].stepNo === substep.stepNo) {
						for (let m in ncwrList) {
							if (ncwrList[m].seq === ncwr.seq) {
								x = Number(m);
								this.deleteSubStepNCWREditors(Number(i), Number(k), x)
								break;
							}
						}
						break;
					}
				}
				break;
			}
		}
		if (ncwrList.length == 2) {
			ncwrList.splice(0, 2);
		} else {
			ncwrList.splice(x, 1);
		}
	}

	private deleteSubStepNCWREditors(i: number, j: number, k: number) {
		let name = this.getEditorName('.substep_ck_ncwr_editor' + i + j + k);
		if (CKEDITOR.instances[name] != undefined) {
			CKEDITOR.instances[name].destroy();
		}
	}

	public moveUpDoc(doc: Document, step: Step, substep: SubStep) {
		let prev, docList;
		this.sharedService.setViewOnlyStatus(this.fileId, false);
		if (substep != undefined) {
			docList = substep.documents
		} else {
			docList = step.documents;
		}
		for (let i in docList) {
			if (docList[i].seq === doc.seq) {
				prev = docList[Number(i) - 1];
				break;
			}
		}
		let seq = doc.seq;
		doc.seq = doc.seq - 1;
		prev.seq = seq;
	}

	public moveDownDoc(doc: Document, step: Step, substep: SubStep) {
		let next, docList;
		this.sharedService.setViewOnlyStatus(this.fileId, false);
		if (substep != undefined) {
			docList = substep.documents;
		} else {
			docList = step.documents;
		}
		for (let i in docList) {
			if (docList[i].seq === doc.seq) {
				next = docList[Number(i) + 1];
				break;
			}
		}
		let seq = doc.seq;
		doc.seq = doc.seq + 1;
		next.seq = seq;
	}

	public deleteDoc(doc: Document, step: Step) {
		let j: number;
		let docList = step.documents;
		this.sharedService.setViewOnlyStatus(this.fileId, false);
		for (let i in this.stepsList) {
			if (this.stepsList[i].stepNo === step.stepNo) {
				for (let k in docList) {
					if (docList[k].seq === doc.seq) {
						j = Number(k);
						this.deleteDocEditors(Number(i), j);
						break;
					}
				}
				break;
			}
		}
		docList.splice(j, 1);
	}

	private deleteDocEditors(i: number, j: number) {
		let name = this.getEditorName('.ck_doc_editor' + i + j);
		if (CKEDITOR.instances[name] != undefined) {
			CKEDITOR.instances[name].destroy();
		}
	}

	public deleteSubStepDoc(doc: Document, step: Step, substep: SubStep) {
		let x: number;
		let docList = substep.documents;
		this.sharedService.setViewOnlyStatus(this.fileId, false);
		for (let i in this.stepsList) {
			if (this.stepsList[i].stepNo === step.stepNo) {
				for (let k in step.substeps) {
					if (step.substeps[k].stepNo === substep.stepNo) {
						for (let m in docList) {
							if (docList[m].seq === doc.seq) {
								x = Number(m);
								this.deleteSubStepDocEditors(Number(i), Number(k), x);
								break;
							}
						}
						break;
					}
				}
				break;
			}
		}
		docList.splice(x, 1);
	}

	private deleteSubStepDocEditors(i: number, j: number, k: number) {
		let name = this.getEditorName('.substep_ck_doc_editor' + i + j + k);
		if (CKEDITOR.instances[name] != undefined) {
			CKEDITOR.instances[name].destroy();
		}
	}

	public deleteDepSteps(step: Step) {
		this.sharedService.setViewOnlyStatus(this.fileId, false);
		step.dependencyFlag = false;
		step.dependencySteps.splice(0, step.dependencySteps.length);
	}

	public deleteOperatingData(step: Step) {
		this.sharedService.setViewOnlyStatus(this.fileId, false);
		step.operatingDataFlag = false;
		step.operatingData.splice(0, step.operatingData.length);
	}

	public deleteLoc(step: Step) {
		this.sharedService.setViewOnlyStatus(this.fileId, false);
		for (let i in this.stepsList) {
			if (this.stepsList[i].stepNo === step.stepNo) {
				this.deleteLocEditor(Number(i));
				break;
			}
		}
		step.locationDesc = null;
	}

	private deleteLocEditor(i: number) {
		let name = this.getEditorName('.ck_loc_editor' + i);
		if (CKEDITOR.instances[name] != undefined) {
			CKEDITOR.instances[name].destroy();
		}
	}

	public deleteSubStepLoc(step: Step, substep: SubStep) {
		this.sharedService.setViewOnlyStatus(this.fileId, false);
		for (let i in this.stepsList) {
			if (this.stepsList[i].stepNo === step.stepNo) {
				for (let k in step.substeps) {
					if (step.substeps[k].stepNo === substep.stepNo) {
						this.deleteSubStepLocEditor(Number(i), Number(k));
						break;
					}
				}
				break;
			}
		}
		substep.locationDesc = null;
	}

	private deleteSubStepLocEditor(i: number, j: number) {
		let name = this.getEditorName('.substep_ck_loc_editor' + i + j);
		if (CKEDITOR.instances[name] != undefined) {
			CKEDITOR.instances[name].destroy();
		}
	}

	public moveUpResp(resp: ResponsibleParty, step: Step) {
		let prev;
		this.sharedService.setViewOnlyStatus(this.fileId, false);
		let repList = step.responsiblePartyDesc;
		for (let i in repList) {
			if (repList[i].seq === resp.seq) {
				prev = repList[Number(i) - 1];
				break;
			}
		}
		let seq = resp.seq;
		resp.seq = resp.seq - 1;
		prev.seq = seq;
	}

	public moveDownResp(resp: ResponsibleParty, step: Step) {
		let next;
		this.sharedService.setViewOnlyStatus(this.fileId, false);
		let repList = step.responsiblePartyDesc;
		for (let i in repList) {
			if (repList[i].seq === resp.seq) {
				next = repList[Number(i) + 1];
				break;
			}
		}
		let seq = resp.seq;
		resp.seq = resp.seq + 1;
		next.seq = seq;
	}

	public deleteResponisibleParty(resp: ResponsibleParty, step: Step) {
		let j: number;
		let respList = step.responsiblePartyDesc;
		this.sharedService.setViewOnlyStatus(this.fileId, false);
		for (let i in this.stepsList) {
			if (this.stepsList[i].stepNo === step.stepNo) {
				for (let k in respList) {
					if (respList[k].seq === resp.seq) {
						j = Number(k);
						this.deleteResponisiblePartyEditor(Number(i), j);
						break;
					}
				}
				break;
			}
		}
		respList.splice(j, 1);
	}

	private deleteResponisiblePartyEditor(i: number, j: number) {
		let name = this.getEditorName('.ck_resp_editor' + i + j);
		if (CKEDITOR.instances[name] != undefined) {
			CKEDITOR.instances[name].destroy();
		}
	}


	public moveUpStep(step: Step) {
		let prev;
		this.sharedService.setViewOnlyStatus(this.fileId, false);
		for (let i in this.stepsList) {
			if (this.stepsList[i].stepNo === step.stepNo) {
				prev = this.stepsList[Number(i) - 1];
				break;
			}
		}
		for (let i = 0; i < this.stepsList.length; i++) {
			let item = this.stepsList[i];
			if (item.dependencyFlag) {
				for (let k = 0; k < item.dependencySteps.length; k++) {
					if (item.dependencySteps[k].display == prev.stepNo) {
						item.dependencySteps[k].display = step.stepNo;
					} else if (item.dependencySteps[k].display == step.stepNo) {
						item.dependencySteps[k].display = prev.stepNo;
					}
				}
			}
		}

		let stepNo = step.stepNo;
		step.stepNo = step.stepNo - 1;
		prev.stepNo = stepNo;

	}

	public moveDownStep(step: Step) {
		let next;
		this.sharedService.setViewOnlyStatus(this.fileId, false);
		for (let i in this.stepsList) {
			if (this.stepsList[i].stepNo === step.stepNo) {
				next = this.stepsList[Number(i) + 1];
				break;
			}
		}
		for (let i = 0; i < this.stepsList.length; i++) {
			let item = this.stepsList[i];
			if (item.dependencyFlag) {
				for (let k = 0; k < item.dependencySteps.length; k++) {
					if (item.dependencySteps[k].display == next.stepNo) {
						item.dependencySteps[k].display = step.stepNo;
					} else if (item.dependencySteps[k].display == step.stepNo) {
						item.dependencySteps[k].display = next.stepNo;
					}
				}
			}
		}
		let stepNo = step.stepNo;
		step.stepNo = step.stepNo + 1;
		next.stepNo = stepNo;
	}

	public moveUpSubStep(step: Step, substep: SubStep) {
		let prev;
		this.sharedService.setViewOnlyStatus(this.fileId, false);
		for (let i in step.substeps) {
			if (step.substeps[i].stepNo === substep.stepNo) {
				prev = step.substeps[Number(i) - 1];
				break;
			}
		}
		let stepNo = substep.stepNo;
		substep.stepNo = substep.stepNo - 1;
		prev.stepNo = stepNo;
	}

	public moveDownSubStep(step: Step, substep: SubStep) {
		let next;
		this.sharedService.setViewOnlyStatus(this.fileId, false);
		for (let i in step.substeps) {
			if (step.substeps[i].stepNo === substep.stepNo) {
				next = step.substeps[Number(i) + 1];
				break;
			}
		}
		let stepNo = substep.stepNo;
		substep.stepNo = substep.stepNo + 1;
		next.stepNo = stepNo;
	}

	public deleteSubStep(step: Step, substep: SubStep) {
		for (let i in this.stepsList) {
			if (this.stepsList[i].stepNo === step.stepNo) {
				for (let k in step.substeps) {
					if (step.substeps[k].stepNo === substep.stepNo) {
						this.delStep = step;
						this.delSubstep = substep;
						this.delId = Number(i);
						this.delIndex = Number(k);
						this.delContent = this.translate.instant('PROCEDURE_EDITOR.SUBSTEP_DEL_MSG');
						this.openModal(this.delete_substep);
						break;
					}
				}
				break;
			}
		}
	}

	private deleteSubStepEditors(i: number, j: number, substep: SubStep) {
		if (substep.groupTitle != null) {
			this.deleteSubStepGroupTitleEditors(i, j, substep);
		}
		for (let m in substep.ncwrs) {
			if (substep.ncwrs[m].type != 'StepLine') {
				this.deleteSubStepNCWREditors(i, j, Number(m));
			} else {
				// nothing to do
			}
		}
		for (let y in substep.images) {
			if (substep.images[y].type == "imageAsFigure") {
				let name = this.getEditorName('.substep_img_editor' + Number(y) + i + j);
				if (CKEDITOR.instances[name] != undefined) {
					CKEDITOR.instances[name].destroy();
				}
			}
		}
		for (let x in substep.documents) {
			this.deleteSubStepDocEditors(i, j, Number(x));
		}
		if (substep.locationDesc != null) {
			this.deleteSubStepLocEditor(i, j);
		}
		let name = this.getEditorName('.substep_ck_editor' + i + j);
		if (CKEDITOR.instances[name] != undefined) {
			CKEDITOR.instances[name].destroy();
		}
	}

	public confirmSubstepDel() {
		this.sharedService.setViewOnlyStatus(this.fileId, false);
		this.bsModalRef.hide();
		this.deleteSubStepEditors(this.delId, this.delIndex, this.delSubstep);
		this.delStep.substeps.splice(this.delIndex, 1);
		for (let i in this.delStep.substeps) {
			this.delStep.substeps[i].stepNo = Number(i) + 1;
		}
	}

	public deleteStep(step: Step) {
		let stepId: String;
		for (let i in this.stepsList) {
			if (this.stepsList[i].stepNo === step.stepNo) {
				stepId = step.id;
				this.isDependencyStep(stepId);
				if (this.isDependency) {
					this.openModal(this.is_dep);
				} else {
					this.delStep = step;
					this.delIndex = Number(i);
					this.delContent = this.translate.instant('PROCEDURE_EDITOR.STEP_DEL_MSG');
					this.openModal(this.delete_step);
				}
				break;
			}
		}
	}

	public showSubstepImagesPanel(substep: SubStep, step: Step) {
		substep.imagePanelFlag = !substep.imagePanelFlag;
		this.showHeaderImages = false;
		this.showImageStep = '';
		if (substep.imagePanelFlag) {
			this.showImageSubstep = substep.id;
			this.stepOfSubstep = step.id;
		} else {
			this.stepOfSubstep = '';
			this.showImageSubstep = '';
		}
	}

	public showStepImagesPanel(step: Step) {
		step.imagePanelFlag = !step.imagePanelFlag;
		this.showHeaderImages = false;
		this.showImageSubstep = '';
		if (step.imagePanelFlag) {
			this.showImageStep = step.id;
		} else {
			this.showImageStep = '';
		}
	}

	public showHeaderImagesPanel() {
		this.imagePanelFlag = !this.imagePanelFlag;
		this.showImageStep = '';
		this.showImageSubstep = '';
		if (this.imagePanelFlag) {
			this.showHeaderImages = true;
		} else {
			this.showHeaderImages = false;
		}
	}

	public addToHeaderImage() {
		this.changeToImagePanel();
		this.showImageStep = '';
		this.showImageSubstep = '';
		this.showHeaderImages = true;
	}

	public addToStepImage(step: Step) {
		this.changeToImagePanel();
		this.showHeaderImages = false;
		this.showImageSubstep = '';
		this.showImageStep = step.id;
	}

	public addToSubstepImage(substep: SubStep, step: Step) {
		this.changeToImagePanel();
		this.showHeaderImages = false;
		this.showImageStep = '';
		this.showImageSubstep = substep.id;
		this.stepOfSubstep = step.id;
	}

	public removeHeaderImage(i: number) {
		this.sharedService.setViewOnlyStatus(this.fileId, false);
		if (this.imageList[i].type == "imageAsFigure") {
			let name = this.getEditorName('.img_editor' + i);
			if (CKEDITOR.instances[name] != undefined) {
				CKEDITOR.instances[name].destroy();
			}
		}
		this.imageList.splice(i, 1);
	}

	public removeStepImage(i: number, step: Step) {
		this.sharedService.setViewOnlyStatus(this.fileId, false);
		let imgStep = this.stepsList.find(item => item.id === step.id);
		if (imgStep.images[i].type == "imageAsFigure") {
			let name = this.getEditorName('.step_img_editor' + i + (imgStep.stepNo - 1));
			if (CKEDITOR.instances[name] != undefined) {
				CKEDITOR.instances[name].destroy();
			}
		}
		imgStep.images.splice(i, 1);

	}

	public removeSubstepImage(i: number, step: Step, substep: SubStep) {
		this.sharedService.setViewOnlyStatus(this.fileId, false);
		let imgStep = this.stepsList.find(item => item.id === step.id);
		let imgSubstep = imgStep.substeps.find(item => item.id === substep.id);
		if (imgSubstep.images[i].type == "imageAsFigure") {
			let name = this.getEditorName('.substep_img_editor' + i + (imgStep.stepNo - 1) + (imgSubstep.stepNo - 1));
			if (CKEDITOR.instances[name] != undefined) {
				CKEDITOR.instances[name].destroy();
			}
		}
		imgSubstep.images.splice(i, 1);
	}

	private isDependencyStep(id: String) {
		this.isDependency = false;
		for (let j in this.stepsList) {
			for (let k in this.stepsList[j].dependencySteps) {
				let dep = this.stepsList[j].dependencySteps[k];
				if (dep.value == id) {
					this.isDependency = true;
					break;
				}
			}
			if (this.isDependency) {
				break;
			}
		}
	}

	public confirmStepDel() {
		let n: number;
		this.sharedService.setViewOnlyStatus(this.fileId, false);
		this.bsModalRef.hide();
		if (this.delStep.groupTitle != null) {
			this.deleteGroupTitleEditors(this.delIndex, this.delStep);
		}
		for (let m in this.delStep.ncwrs) {
			if (this.delStep.ncwrs[m].type != 'StepLine') {
				this.deleteStepNCWREditors(this.delIndex, Number(m));
			} else {
				// nothing to do
			}
		}
		for (let y in this.delStep.images) {
			if (this.delStep.images[y].type == "imageAsFigure") {
				let name = this.getEditorName('.step_img_editor' + Number(y) + this.delIndex);
				if (CKEDITOR.instances[name] != undefined) {
					CKEDITOR.instances[name].destroy();
				}
			}
		}
		for (let x in this.delStep.documents) {
			this.deleteDocEditors(this.delIndex, Number(x));
		}
		if (this.delStep.locationDesc != null) {
			this.deleteLocEditor(this.delIndex);
		}
		for (let k in this.delStep.substeps) {
			n = Number(k);
			this.deleteSubStepEditors(this.delIndex, n, this.delStep.substeps[n]);
		}
		let name = this.getEditorName('.step_ck_editor' + this.delIndex);
		if (CKEDITOR.instances[name] != undefined) {
			CKEDITOR.instances[name].destroy();
		}
		this.stepsList.splice(this.delIndex, 1);
		for (let i in this.stepsList) {
			this.stepsList[i].stepNo = Number(i) + 1;
			if (this.stepsList[i].dependencySteps.length != 0) {
				for (let j = 0; j < this.stepsList[i].dependencySteps.length; j++) {
					if (this.delIndex + 1 < this.stepsList[i].dependencySteps[j].display ) {
						this.stepsList[i].dependencySteps[j].display = this.stepsList[i].dependencySteps[j].display - 1;
					}
				}
			}
		}
	}

	public addNewStep() {
		let lastIndex = this.stepsList.length;
		let ncwrList: Array<NCWRS> = [];
		let docList: Array<Document> = [];
		let depList: Array<DependencyStep> = [];
		let imgList: Array<ProcImage> = [];
		let subStepList: Array<SubStep> = [];
		let responsiblePartyDesc: Array<ResponsibleParty> = [];
		let operatingData: Array<OperatingData> = [];
		this.sharedService.setViewOnlyStatus(this.fileId, false);
		this.stepsList.push(new Step(this.guid().toUpperCase(), lastIndex + 1, "", "", null, depList, false, "", ncwrList, docList, null, responsiblePartyDesc,operatingData, false,
		 imgList, false, subStepList));
		setTimeout(() => {
			let el = this.elementRef.nativeElement.querySelector('.step_ck_editor' + lastIndex);
			this.activeEvent(el);
			el.innerHTML = this.setElementTag();
			this.focusNewEditor('.step_ck_editor' + lastIndex);
			this.setEditor();
			this.stepIndex++;
			let objDiv = $("#proc_editor");
    	 	let h = objDiv.get(0).scrollHeight;
    	 	objDiv.animate({scrollTop: h});
		}, 200);
	}

	public addStepItem(type: string, step: Step) {
		let j: number;
		this.sharedService.setViewOnlyStatus(this.fileId, false);
		for (let i in this.stepsList) {
			if (this.stepsList[i].stepNo === step.stepNo) {
				j = Number(i);
				break;
			}
		}
		if (type == 'Group Title') {
			if (this.isExpandAll == false) {
                this.collapseAndExpandAll();
            } 
			if (step.groupTitle == null) {
				let ncwrList: Array<NCWRS> = [];
				let gt: GroupTitle = new GroupTitle(ncwrList, "");
				step.groupTitle = gt;
				setTimeout(() => {
					let el = this.elementRef.nativeElement.querySelector('.gtck_editor' + j);
					this.activeEvent(el);
					el.innerHTML = this.setElementTag();
					this.focusNewEditor('.gtck_editor' + j);
					this.setEditor();
					setTimeout(() => {
						var focusElement = document.querySelector(":focus");
						var position = $(focusElement).parentsUntil('div.row.ng-star-inserted').offset();   
						document.getElementById('proc_editor').scrollTop += (position.top - 400);
					}, 200);
				}, 200);
			} else {
				this.alertService.clear();
				this.alertService.info(this.translate.instant('PROCEDURE_EDITOR.ADD_GT_MSG'));
			}
		} else if (type == 'Sub Step') {
			if (this.isExpandAll == false) {
                this.collapseAndExpandAll();
            }
			let lastIndex = step.substeps.length;
			let ncwrList: Array<NCWRS> = [];
			let docList: Array<Document> = [];
			let imageList: Array<ProcImage> = [];
			let operatingData: Array<OperatingData> = [];
			step.substeps.push(new SubStep(this.guid().toUpperCase(), step.id, lastIndex + 1, null, "", ncwrList, docList, null, operatingData, false, imageList, false));
			setTimeout(() => {
				let el = this.elementRef.nativeElement.querySelector('.substep_ck_editor' + j + lastIndex);
				this.activeEvent(el);
				el.innerHTML = this.setElementTag();
				this.focusNewEditor('.substep_ck_editor' + j + lastIndex);
				this.setEditor();
				setTimeout(() => {
					var focusElement = document.querySelector(":focus");
					var position = $(focusElement).parentsUntil('div.row.ng-star-inserted').offset();   
					document.getElementById('proc_editor').scrollTop += (position.top - 400);
                }, 200);
				this.subStepIndex++;
			}, 200);
		} else if (type == 'Location') {
			if (this.isExpandAll == false) {
                this.collapseAndExpandAll();
            }
			if (step.locationDesc == null) {
				let loc: Location = new Location(1, "");
				step.locationDesc = loc;
				setTimeout(() => {
					let el = this.elementRef.nativeElement.querySelector('.ck_loc_editor' + j);
					this.activeEvent(el);
					el.innerHTML = this.setElementTag();
					this.focusNewEditor('.ck_loc_editor' + j);
					this.setEditor();
					setTimeout(() => {
						var focusElement = document.querySelector(":focus");
						var position = $(focusElement).parentsUntil('div.row.ng-star-inserted').offset();   
						document.getElementById('proc_editor').scrollTop += (position.top - 400);
					}, 200);
				}, 200);
			} else {
				this.alertService.clear();
				this.alertService.info(this.translate.instant('PROCEDURE_EDITOR.ADD_LOC_MSG'));
			}
		} else if (type == 'Document') {
			if (this.isExpandAll == false) {
                this.collapseAndExpandAll();
            }        
			let lastIndex = step.documents.length;
			step.documents.push(new Document(lastIndex + 1, ""));
			setTimeout(() => {
				let el = this.elementRef.nativeElement.querySelector('.ck_doc_editor' + j + lastIndex);
				this.activeEvent(el);
				el.innerHTML = this.setElementTag();
				this.focusNewEditor('.ck_doc_editor' + j + lastIndex);
				this.setEditor();
				setTimeout(() => {
					var focusElement = document.querySelector(":focus");
					var position = $(focusElement).parentsUntil('div.row.ng-star-inserted').offset();   
					document.getElementById('proc_editor').scrollTop += (position.top - 400);
                }, 200);
                this.docIndex++;
            }, 200);
		} else if (type == 'Dependency') {
			if (this.isExpandAll == false) {
                this.collapseAndExpandAll();
            } 
			if (!step.dependencyFlag) {
				step.dependencyFlag = true;
			} else {
				// nothing to do
			}
			setTimeout(() => {
				var focusElement = document.querySelector(":focus");
				var position = $(focusElement).parentsUntil('div.row.ng-star-inserted').offset();   
				document.getElementById('proc_editor').scrollTop += (position.top - 400);
			}, 200);
		} else if (type == 'Duplicate') {
			this.removeAcive();
			this.loadingService.showLoading(true, false, "Loading...", 0);
			setTimeout(() => {
				this.loadingService.hideLoading();
			}, 200);
			this.dupStep = JSON.parse(JSON.stringify(step));
			this.dupStep.id = this.guid().toUpperCase();
			for (let i in this.dupStep.substeps) {
				this.dupStep.substeps[i].id = this.guid().toUpperCase();
				this.dupStep.substeps[i].parentStepId = this.dupStep.id;
			}
			this.setContentDuplicateStep(j);
			this.stepsList.splice(j + 1, 0, this.dupStep);
			for (let a = 0; a <= this.stepsList.length - 1; a++) {
				var index = this.stepsList.findIndex(item => item.id === this.stepsList[a].id);
				this.stepsList[a].stepNo = index + 1;
			}
			setTimeout(() => {
				this.addStepsContent(j + 1);
				this.setEditor();
				this.stepIndex++;
			}, 200);
		} else if (type == 'Responsible Party') {
			if (this.isExpandAll == false) {
                this.collapseAndExpandAll();
			}
			let lastIndex = 0;
			if(step.responsiblePartyDesc != undefined){
				lastIndex = step.responsiblePartyDesc.length;
			}
			else{
				step.responsiblePartyDesc = [];
			}
			step.responsiblePartyDesc.push(new ResponsibleParty(lastIndex + 1, ""));
			setTimeout(() => {
				let el = this.elementRef.nativeElement.querySelector('.ck_resp_editor' + j + lastIndex);
				this.activeEvent(el);
				el.innerHTML = this.setElementTag();
				this.focusNewEditor('.ck_resp_editor' + j + lastIndex);
				this.setEditor();
				setTimeout(() => {
					var focusElement = document.querySelector(":focus");
					var position = $(focusElement).parentsUntil('div.row.ng-star-inserted').offset();   
					document.getElementById('proc_editor').scrollTop += (position.top - 400);
				}, 200);
			}, 200);
		} else if (type == 'Operating Data') {
			if (this.isExpandAll == false) {
                this.collapseAndExpandAll();
            } 
			if (!step.operatingDataFlag) {
				step.operatingDataFlag = true;
			} else {
				// nothing to do
			}
			setTimeout(() => {
				var focusElement = document.querySelector(":focus");
				var position = $(focusElement).parentsUntil('div.row.ng-star-inserted').offset();   
				document.getElementById('proc_editor').scrollTop += (position.top - 400);
			}, 200);
		} else {
			if (this.isExpandAll == false) {
                this.collapseAndExpandAll();
            }
			if (step.ncwrs.length == 0) {
				step.ncwrs.push(new NCWRS('StepLine', 1, 0, "", ""));
			}
			//Set default background colors
			let backgroundColor = "";
			if (type == "SSH and E Critical Task" || type == "Safeguard Critical Task") {
				backgroundColor = "#e5b8b7";
			}
			else if (type == "Independent Verification") {
				backgroundColor = "#ffffcc";
			}
			step.ncwrs.push(new NCWRS(type, step.ncwrs.length + 1, 0, "", backgroundColor));
			setTimeout(() => {
				let lastIndex = step.ncwrs.length - 1;
				let el = this.elementRef.nativeElement.querySelector('.ck_ncwr_editor' + j + lastIndex);
				this.activeEvent(el);
				el.innerHTML = this.setElementTag();
				this.focusNewEditor('.ck_ncwr_editor' + j + lastIndex);
				this.setEditor();
				setTimeout(() => {
					var focusElement = document.querySelector(":focus");
					var position = $(focusElement).parentsUntil('div.row.ng-star-inserted').offset();   
					document.getElementById('proc_editor').scrollTop += (position.top - 400);
                }, 200);
				this.ncwrIndex++;
			}, 200);
		}
	}

	public addSubStepItem(type: string, step: Step, substep: SubStep) {
		let j, n: number;
		this.sharedService.setViewOnlyStatus(this.fileId, false);
		for (let i in this.stepsList) {
			if (this.stepsList[i].stepNo === step.stepNo) {
				j = Number(i);
				break;
			}
		}
		for (let k in step.substeps) {
			if (step.substeps[k].stepNo === substep.stepNo) {
				n = Number(k);
				break;
			}
		}
		if (type == 'Group Title') {
			if (substep.groupTitle == null) {
				let ncwrList: Array<NCWRS> = [];
				let gt: GroupTitle = new GroupTitle(ncwrList, "");
				substep.groupTitle = gt;
				setTimeout(() => {
					let el = this.elementRef.nativeElement.querySelector('.gtck_substep_editor' + j + n);
					this.activeEvent(el);
					el.innerHTML = this.setElementTag();
					this.focusNewEditor('.gtck_substep_editor' + j + n);
					this.setEditor();
				}, 200);
			} else {
				this.alertService.clear();
				this.alertService.info(this.translate.instant('PROCEDURE_EDITOR.ADD_GT_MSG'));
			}
		} else if (type == 'Location') {
			if (substep.locationDesc == null) {
				let loc: Location = new Location(1, "");
				substep.locationDesc = loc;
				setTimeout(() => {
					let el = this.elementRef.nativeElement.querySelector('.substep_ck_loc_editor' + j + n);
					this.activeEvent(el);
					el.innerHTML = this.setElementTag();
					this.focusNewEditor('.substep_ck_loc_editor' + j + n);
					this.setEditor();
				}, 200);
			} else {
				this.alertService.clear();
				this.alertService.info(this.translate.instant('PROCEDURE_EDITOR.ADD_LOC_MSG'));
			}
		} else if (type == 'Document') {
			let lastIndex = substep.documents.length;
			substep.documents.push(new Document(lastIndex + 1, ""));
			setTimeout(() => {
				let el = this.elementRef.nativeElement.querySelector('.substep_ck_doc_editor' + j + n + lastIndex);
				this.activeEvent(el);
				el.innerHTML = this.setElementTag();
				this.focusNewEditor('.substep_ck_doc_editor' + j + n + lastIndex);
				this.setEditor();
				this.substepDocIndex++;
			}, 200);
		} else if (type == 'Duplicate') {
			this.loadingService.showLoading(true, false, "Loading...", 0);
			setTimeout(() => {
				this.loadingService.hideLoading();
			}, 300);
			this.dupSubstep = JSON.parse(JSON.stringify(substep))
			this.dupSubstep.id = this.guid().toUpperCase();
			this.setContentDuplicateSubstep(j, n, this.dupSubstep);
			step.substeps.splice(n + 1, 0, this.dupSubstep);
			for (let a = 0; a <= this.stepsList.length - 1; a++) {
				for (let b = 0; b <= this.stepsList[a].substeps.length - 1; b++) {
					var index = this.stepsList[a].substeps.findIndex(item => item.id === this.stepsList[a].substeps[b].id);
					this.stepsList[a].substeps[b].stepNo = index + 1;
				}
			}
			setTimeout(() => {
				this.addSubstepContent(j, n + 1);
				this.setEditor();
				this.subStepIndex++;
			}, 300);
		} else if (type == 'Operating Data') {
			if (this.isExpandAll == false) {
                this.collapseAndExpandAll();
            } 
			if (!substep.operatingDataFlag) {
				substep.operatingDataFlag = true;
			} else {
				// nothing to do
			}
			setTimeout(() => {
				var focusElement = document.querySelector(":focus");
				var position = $(focusElement).parentsUntil('div.row.ng-star-inserted').offset();   
				document.getElementById('proc_editor').scrollTop += (position.top - 400);
			}, 200);
		} else {
			if (substep.ncwrs.length == 0) {
				substep.ncwrs.push(new NCWRS('StepLine', 1, 0, "", ""));
			}
			//Set default background colors
			let backgroundColor = "";
			if (type == "SSH and E Critical Task" || type == "Safeguard Critical Task") {
				backgroundColor = "#e5b8b7";
			}
			else if (type == "Independent Verification") {
				backgroundColor = "#ffffcc";
			}
			substep.ncwrs.push(new NCWRS(type, substep.ncwrs.length + 1, 0, "", backgroundColor));
			setTimeout(() => {
				let lastIndex = substep.ncwrs.length - 1;
				let el = this.elementRef.nativeElement.querySelector('.substep_ck_ncwr_editor' + j + n + lastIndex);
				this.activeEvent(el);
				el.innerHTML = this.setElementTag();
				this.focusNewEditor('.substep_ck_ncwr_editor' + j + n + lastIndex);
				this.setEditor();
				this.substepNcwrIndex++;
			}, 200);
		}
	}

	public addAction(type: string, step: Step) {
		step.action = type == "Nothing" ? '' : type;
	}

	private setContentDuplicateSubstep(i: number, m: number, substep: SubStep) {
		if (this.stepsList[i].substeps[m].groupTitle != null) {
			substep.groupTitle.element = this.getEditorContent('.gtck_substep_editor' + i + m);
			if (this.stepsList[i].substeps[m].groupTitle.ncwrs != null) {
				for (let n = 0; n < this.stepsList[i].substeps[m].groupTitle.ncwrs.length; n++) {
					substep.groupTitle.ncwrs[n].element = this.getEditorContent('.gtck_substep_ncwr_editor' + i + m + n);
				}
			}
		}
		substep.element = this.getEditorContent('.substep_ck_editor' + i + m);
		for (let x = 0; x < this.stepsList[i].substeps[m].ncwrs.length; x++) {
			if (this.stepsList[i].substeps[m].ncwrs[x].type != 'StepLine') {
				substep.ncwrs[x].element = this.getEditorContent('.substep_ck_ncwr_editor' + i + m + x);
			}
		}
		for (let a = 0; a < this.stepsList[i].substeps[m].images.length; a++) {
			if (this.stepsList[i].substeps[m].images[a].type == 'imageAsFigure') {
				substep.images[a].title = this.getEditorContent('.substep_img_editor' + a + i + m );
			}
		}
		for (let t = 0; t < this.stepsList[i].substeps[m].documents.length; t++) {
			substep.documents[t].element = this.getEditorContent('.substep_ck_doc_editor' + i + m + t);
		}
		if (this.stepsList[i].substeps[m].locationDesc != null) {
			substep.locationDesc.element = this.getEditorContent('.substep_ck_loc_editor' + i + m);
		}
		
		if(this.stepsList[i].substeps[m].operatingData != null){
			substep.operatingDataFlag = this.stepsList[i].substeps[m].operatingDataFlag;
			substep.operatingData = this.stepsList[i].substeps[m].operatingData;
		}
		let convertSubstep = JSON.stringify(substep);
		if (convertSubstep.includes("<xref")) {
			convertSubstep = convertSubstep.replace(/refinsid/gi, "ref_ins");	
		}	
		convertSubstep = convertSubstep.replace(/(insid)=.{38}/gi, function insId() {
			function s4() {
				return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
			}
			return 'insID=\\"' + s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
		});
		
		if (convertSubstep.includes("<xref")) {	
			convertSubstep = convertSubstep.replace(/ref_ins/gi, "refinsid");
		}		
		this.dupSubstep = JSON.parse(convertSubstep);
	}

	private setContentDuplicateStep(i: number) {
		if (this.stepsList[i].groupTitle != null) {
			this.dupStep.groupTitle.element = this.getEditorContent('.gtck_editor' + i);
			if (this.stepsList[i].groupTitle.ncwrs != null) {
				for (let j = 0; j < this.stepsList[i].groupTitle.ncwrs.length; j++) {
					this.dupStep.groupTitle.ncwrs[j].element = this.getEditorContent('.gtck_ncwr_editor' + i + j);
				}
			}
		}
		this.dupStep.element = this.getEditorContent('.step_ck_editor' + i);
		for (let k = 0; k < this.stepsList[i].ncwrs.length; k++) {
			if (this.stepsList[i].ncwrs[k].type != 'StepLine') {
				this.dupStep.ncwrs[k].element = this.getEditorContent('.ck_ncwr_editor' + i + k);
			}
		}
		for (let a = 0; a < this.stepsList[i].images.length; a++) {
			if (this.stepsList[i].images[a].type == "imageAsFigure") {
				this.dupStep.images[a].title = this.getEditorContent('.step_img_editor' + a + i);
			}
		}
		for (let t = 0; t < this.stepsList[i].documents.length; t++) {
			this.dupStep.documents[t].element = this.getEditorContent('.ck_doc_editor' + i + t);
		}
		if (this.stepsList[i].locationDesc != null) {
			this.dupStep.locationDesc.element = this.getEditorContent('.ck_loc_editor' + i);
		}
		if(this.stepsList[i].responsiblePartyDesc != null){
			for (let n = 0; n < this.stepsList[i].responsiblePartyDesc.length; n++) {
				this.dupStep.responsiblePartyDesc[n].element = this.getEditorContent('.ck_resp_editor' + i + n);
			}
		}
		if(this.stepsList[i].operatingData != null){
			this.dupStep.operatingDataFlag = this.stepsList[i].operatingDataFlag;
			this.dupStep.operatingData = this.stepsList[i].operatingData;
		}
		for (let m = 0; m < this.stepsList[i].substeps.length; m++) {
			this.setContentDuplicateSubstep(i, Number(m), this.dupStep.substeps[m])
		}
		let convertStep = JSON.stringify(this.dupStep);
		if (convertStep.includes("<xref")) {
			convertStep = convertStep.replace(/refinsid/gi, "ref_ins");	
		}	
			convertStep = convertStep.replace(/(insid)=.{38}/gi, function insId() {
			function s4() {
				return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
			}
			return 'insID=\\"' + s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
		});
		
		if (convertStep.includes("<xref")) {	
			convertStep = convertStep.replace(/ref_ins/gi, "refinsid");
		}		
		
		this.dupStep = JSON.parse(convertStep);
	}

	setTagEditor() {
		$('.toggle-tagedit').click(function (e) {
			var name = $(this)[0].title.split(" ")[3];
			tag_editor = CKEDITOR.instances[name];
		});
	}

	focusNewEditor(className: string) {
		console.log(className);
		var self = this;
		// $('.toggle-edit' + SharedService.getTabId() + this.userId).bind('copy', function() {
		// 	if (editor == null || editor == undefined) {
		// 		self.alertService.clear();
		// 		self.alertService.error("Please enable the editor before copying");
		// 	}
		// });	
		let node = $(".toggle-edit" + this.tabId + this.userId + className);

		for (let i in this.editorList) {
			if (CKEDITOR.instances[this.editorList[i].name] != undefined) {
				CKEDITOR.instances[this.editorList[i].name].destroy();
				this.editorDivList[i].setAttribute("contenteditable", "false");
				if ($(this.editorDivList[i])[0].innerHTML == '') {
					$(this.editorDivList[i])[0].innerHTML = '<br>';
				}
			}
		}
		$(node)[0].setAttribute("contenteditable", "true");
		editor = CKEDITOR.inline($(node)[0], this.inlineConfig);
		this.editorList.push(editor);
		this.editorDivList.push($(node)[0]);

		//$(document).on('dblclick','ph', function(){
			let item = $(this).attr('restriction');
			let catId = $(this).attr('catId');
			self.getRestrictionValueOndblClick(item,catId);
		//});

		// $(document).bind('copy', function() {
		// 	if (editor == null || editor == undefined) {
		// 		self.alertService.clear();
		// 		self.alertService.info("Please enable the editor before copying");
		// 	}
		// });	

		editor.on('instanceReady', function (ev) {
			var range = editor.createRange();
			if (range != null) {
				range.moveToElementEditEnd(range.root);
				editor.getSelection().selectRanges([range]);
			}
		});
				
		editor.on('focus', function (evt) {
			if(self.isFirefox){
				let wintitles = editor.container.getElementsByTag("wintitle");
				for(let i=0; i<wintitles.$.length; i++){
					wintitles.$[i].removeAttribute("contenteditable");
				}
			}
		});

		editor.on('key', function (e) {
			self.editorKeyDown(e);
		});

		editor.on('paste', function (evt) {
			evt.stop();
			var data: string = evt.data.dataValue;
			let pasteData: string = self.processPasteData(data);
			evt.editor.insertHtml(pasteData);
			if(self.hasResuables) {
				self.alertService.clear();
				self.alertService.toast(self.translate.instant('PROCEDURE_EDITOR.DISABLED_EDITOR_COPY'));
			}
		});

		editor.on('change', () => {
			self.sharedService.setViewOnlyStatus(self.fileId, false);
		});
	}

	setEditor() {
		var self = this;
		$('.toggle-edit' + SharedService.getTabId() + this.userId).on('copy', function() {
			if ($(this)[0].attributes['contenteditable'].nodeValue == "false") {
				localStorage.setItem('disabledEditor', "true"); 
			}
		});		
		if (!this.isMobile) {
			$('.toggle-edit' + SharedService.getTabId() + this.userId).unbind("dblclick");
			$('.toggle-edit' + SharedService.getTabId() + this.userId).dblclick(function (e) {
				//$('.toggle-edit' + SharedService.getTabId() + this.userId).unbind("dblclick");
				
			if (e.target.nodeName != "PH" || (editor == null) || (editor == undefined)) {
				if ($(this)[0].attributes['contenteditable'].nodeValue == "false") {
					for (let i in self.editorList) {
						if (CKEDITOR.instances[self.editorList[i].name] != undefined) {
							CKEDITOR.instances[self.editorList[i].name].destroy();
							self.editorDivList[i].setAttribute("contenteditable", "false");
							if ($(self.editorDivList[i])[0].innerHTML == '') {
								$(self.editorDivList[i])[0].innerHTML = '<br>';
							}
						}
					}
					$(this)[0].setAttribute("contenteditable", "true");
					editor = CKEDITOR.inline($(this)[0], self.inlineConfig);
					self.editorList.push(editor);
					self.editorDivList.push($(this)[0]);
				}
			}  else {	
				console.log("=====e.target.nodeName=" + e.target.nodeName);				
				if ($(this)[0].attributes['contenteditable'].nodeValue == "true" ) {
					let contenteditable :boolean = true;
					//$(document).on('dblclick','ph', function(){
						SharedService.setselectedNode(e.target);
						let item = e.target.attributes['restriction'].nodeValue;
						let catId = e.target.attributes['catId']
						if (e.target.attributes['catId'] != undefined && e.target.attributes['catId'] != null) {
							catId = e.target.attributes['catId'].nodeValue;
						}
						if (contenteditable ) {
							self.getRestrictionValueOndblClick(item,catId);
						}

						contenteditable = false;
				//});
				}			
			}
				// $(document).bind('copy', function() {
				// 	if (editor == null || editor == undefined) {
				// 		self.alertService.clear();
				// 		self.alertService.info("Please enable the editor before copying");
				// 	}
				// });	
				editor.on('instanceReady', function (evt) {
					var range = editor.createRange();
					if (range != null) {
						range.moveToElementEditEnd(range.root);
						editor.getSelection().selectRanges([range]);
					}
				});
				
				editor.on('focus', function (evt) {
					if(self.isFirefox){
						let wintitles = editor.container.getElementsByTag("wintitle");
						for(let i=0; i<wintitles.$.length; i++){
							wintitles.$[i].removeAttribute("contenteditable");
						}
					}
				});

				editor.on('key', function (e) {
					self.editorKeyDown(e);
				});

				editor.on('paste', function (evt) {
					evt.stop();
					var data: string = evt.data.dataValue;
					let pasteData: string = self.processPasteData(data);
					evt.editor.insertHtml(pasteData);
					if(self.hasResuables) {
						self.alertService.clear();
						self.alertService.toast(self.translate.instant('PROCEDURE_EDITOR.DISABLED_EDITOR_COPY'));
					}
				});

				editor.on('change', () => {
					self.sharedService.setViewOnlyStatus(self.fileId, false);
				});
			});
		} else {
			$('.toggle-edit' + SharedService.getTabId() + this.userId).click(function (e) {
				self.touchTime = new Date().getTime();
				if (((new Date().getTime()) - self.touchTime) < 800) {
					for (let i in self.editorList) {
						if (CKEDITOR.instances[self.editorList[i].name] != undefined) {
							CKEDITOR.instances[self.editorList[i].name].destroy();
							self.editorDivList[i].setAttribute("contenteditable", "false");
							if ($(self.editorDivList[i])[0].innerHTML == '') {
								$(self.editorDivList[i])[0].innerHTML = '<br>';
							}
						}
					}
					$(this)[0].setAttribute("contenteditable", "true");
					editor = CKEDITOR.inline($(this)[0], self.inlineConfig);
					self.editorList.push(editor);
					self.editorDivList.push($(this)[0]);

					editor.on('instanceReady', function (evt) {
						var range = editor.createRange();
						if (range != null) {
							range.moveToElementEditEnd(range.root);
							editor.getSelection().selectRanges([range]);
						}
					});
				
					editor.on('focus', function (evt) {
						if(self.isFirefox){
							let wintitles = editor.container.getElementsByTag("wintitle");
							for(let i=0; i<wintitles.$.length; i++){
								wintitles.$[i].removeAttribute("contenteditable");
							}
						}
					});

					editor.on('key', function (e) {
						self.editorKeyDown(e);
					});

					// $(document).bind('copy', function() {
					// 	if (editor == null || editor == undefined) {
					// 		self.alertService.clear();
					// 		self.alertService.info("Please enable the editor before copying");
					// 	}
					// });	

					editor.on('paste', function (evt) {
						evt.stop();
						var data: string = evt.data.dataValue;
						let pasteData: string = self.processPasteData(data);
						evt.editor.insertHtml(pasteData);
						if(self.hasResuables) {
							self.alertService.clear();
							self.alertService.toast(self.translate.instant('PROCEDURE_EDITOR.DISABLED_EDITOR_COPY'));
						}
					});

					editor.on('change', () => {
						self.sharedService.setViewOnlyStatus(self.fileId, false);
					});
				} else {
					if (editor != null) {
						self.selection = editor.getSelection();
						if (self.selection != null) {
							self.ranges = self.selection.getRanges();
						}
						if (self.ranges != null) {
							let range = self.ranges[0];
							if (range != null) {
								range = range.clone();
								var startNode = range.startContainer.$;
								var ancestor = startNode.parentNode;
								if (ancestor != null && ancestor.nodeName == 'PH') {
									let attr = ancestor.attributes;
									if (attr.length != 0) {
										self.getRestrictionValue(ancestor);
									}
								}
							}
						}
					}
				}
			});
		}
	}

	private editorKeyDown(e: any) {
		this.tagContent = '';
		this.selection = editor.getSelection();
		if (this.selection != null) {
			this.ranges = this.selection.getRanges();
		}
		if (this.ranges != null) {
			let range = this.ranges[0];
			if (range != null) {
				range = range.clone();
				var startNode = range.startContainer.$;
				var ancestor = startNode.parentNode;
				if(startNode.nodeName == 'EDITAREA'){
					ancestor=startNode;
				}
				if (this.isFirefox) {
					if (ancestor != null) {
						if (ancestor.nodeName == 'PH') {
							if (this.checkPrintableKeys(e)) {
								let attr = ancestor.attributes;
								if (attr.length != 0) {
									//this.getRestrictionValue(ancestor);
								}
								let restriction = ancestor.attributes['restriction'].value;
								let tagPHId = ancestor.attributes['tagPHId'].value;
								let catId = ancestor.attributes['catId'] != undefined ? ancestor.attributes['catId'].value : "";
								let text = ancestor.textContent;
								this.guidWinT = this.guid().toUpperCase();
								this.tagContent = '<tags insID="' + this.guid().toUpperCase() +
									'" tagPHId="' + tagPHId + '" restriction="' + restriction + '" catId="' + catId + '" phText="' + text + '">' +
									'<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea><wintitle contenteditable="true" style="background-color:#cfe2f3;" insID="' + this.guidWinT + '" id="' + this.guidWinT + '"></wintitle>' +
									'<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea></tags>';
									//setTimeout(() => {
										ancestor.outerHTML = this.tagContent;
										editor.document.getById(this.guidWinT).focus();
									//}, 300);
								
							}
						} else if (ancestor.nodeName == 'EDITAREA') {
							if (this.checkPrintableKeys(e)) {
								let prevNode = $(ancestor).prev()[0];
								let nextNode = $(ancestor).next()[0];
								if (prevNode != undefined && prevNode.nodeName == 'WINTITLE') {
									let text = prevNode.textContent;
									//prevNode.textContent = text.slice(0, text.length - 1) + e.data.domEvent.$.key + ' ';
								} else if (nextNode != undefined && nextNode.nodeName == 'WINTITLE') {
									let text = nextNode.textContent;
									//nextNode.textContent = ' ' + e.data.domEvent.$.key + text.slice(1, text.length);
								} else {
									this.guidWinT = this.guid().toUpperCase();
									this.tagContent = '<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea><wintitle contenteditable="true" style="background-color:#cfe2f3;" insID="' + this.guidWinT + '" id="' + this.guidWinT + '"></wintitle>' +
										'<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea></tags>';
									ancestor.outerHTML = this.tagContent;
									editor.document.getById(this.guidWinT).focus();
								}
							}
						} else if (ancestor.nodeName == 'WINTITLE') {
							ancestor.setAttribute("contenteditable", "true");
						} else if (ancestor.nodeName == 'TAG' || ancestor.nodeName == 'COM' || ancestor.nodeName == 'FLD' || ancestor.nodeName == 'TAGDESC' || ancestor.nodeName == 'TXT' || ancestor.nodeName == 'SPCHAR' || ancestor.nodeName == 'FRACTION'
							|| ancestor.nodeName == 'B' || ancestor.nodeName == 'U' || ancestor.nodeName == 'I' ) {
								if (e.data.keyCode == 8 || e.data.keyCode == 46) {
									while(ancestor.parentNode != undefined){
										if(ancestor.nodeName == 'TAG' || ancestor.nodeName == 'COM' || ancestor.nodeName == 'SPCHAR' || ancestor.nodeName == 'FRACTION'){
											
											if(this.isStatementParent(ancestor)){
												this.setOuterHTML(ancestor, "");
											}
											break;
											
										}else{
											ancestor = ancestor.parentNode;
										}
									}
								// if (ancestor.nodeName == 'TAG' || ancestor.nodeName == 'COM' || ancestor.nodeName == 'SPCHAR' || ancestor.nodeName == 'FRACTION') {
								// 	if (this.isStatementParent(ancestor)) {
								// 		this.setOuterHTML(ancestor, "");
								// 	}
								// } else if (ancestor.nodeName == 'FLD') {
								// 	if (ancestor.parentNode != undefined) {
								// 		var tagsNode = ancestor.parentNode;
								// 		if (this.isStatementParent(tagsNode)) {
								// 			this.setOuterHTML(tagsNode, "");
								// 		}
								// 	}
								// } else if (ancestor.nodeName == 'TAGDESC') {
								// 	if (ancestor.parentNode.parentNode != undefined) {
								// 		var tagsNode = ancestor.parentNode.parentNode;
								// 		if (this.isStatementParent(tagsNode)) {
								// 			this.setOuterHTML(tagsNode, "");
								// 		}
								// 	}
								// } else if (ancestor.nodeName == 'TXT') {
								// 	if (ancestor.parentNode.nodeName != 'STATEMENT' || ancestor.parentNode.nodeName != 'PARA' || ancestor.parentNode.nodeName != 'LI') {
								// 		if (ancestor.parentNode.parentNode.parentNode != undefined) {
								// 			var tagsNode = ancestor.parentNode.parentNode.parentNode;
								// 			if (tagsNode.nodeName == "TAG" && this.isStatementParent(tagsNode)) {
								// 				this.setOuterHTML(tagsNode, "");
								// 			}
								// 		}
								// 	}
								// }
							}
						} else {
							if (startNode != null && (startNode.nodeName == 'STATEMENT' || startNode.nodeName == 'TAGS' || startNode.nodeName == 'PARA' || startNode.nodeName == 'LI')) {
								if (this.checkPrintableKeys(e)) {
									let wintile = editor.document.getById(this.guidWinT).$;
									let text = wintile.textContent;
									wintile.textContent = text.slice(0, text.length - 1) + e.data.domEvent.$.key + ' ';
								}
							}
						}
					} else {
						this.alertService.clear();
						this.alertService.info(this.translate.instant('TAG_EDIT_AREA.NOT_VALID_SELECTION'), true);
					}
				} else {
					if (ancestor != null) {
						if (ancestor.nodeName == 'PH') {
							if (this.checkPrintableKeys(e)) {
								let attr = ancestor.attributes;
								if (attr.length != 0) {
									//this.getRestrictionValue(ancestor);
								}
								let restriction = ancestor.attributes['restriction'].value;
								let tagPHId = ancestor.attributes['tagPHId'].value;
								let catId = ancestor.attributes['catId'] != undefined ? ancestor.attributes['catId'].value : "";
								let text = ancestor.textContent;
								this.guidWinT = this.guid().toUpperCase();
								this.tagContent = '<tags insID="' + this.guid().toUpperCase() +
									'" tagPHId="' + tagPHId + '" restriction="' + restriction + '" catId="' + catId + '" phText="' + text + '">' +
									'<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea><wintitle contenteditable="true" style="background-color:#cfe2f3;" insID="' + this.guidWinT + '" id="' + this.guidWinT + '"></wintitle>' +
									'<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea></tags>';
								this.setOuterHTML(ancestor, this.tagContent);
								editor.document.getById(this.guidWinT).focus();
							}
						} else if (ancestor.nodeName == 'EDITAREA') {
							if (this.checkPrintableKeys(e)) {
								let prevNode = $(ancestor).prev()[0];
								let nextNode = $(ancestor).next()[0];
								if (prevNode != undefined && prevNode.nodeName == 'WINTITLE') {
									let text = prevNode.textContent;
									//prevNode.textContent = text.slice(0, text.length - 1) + e.data.domEvent.$.key + ' ';
								} else if (nextNode != undefined && nextNode.nodeName == 'WINTITLE') {
									let text = nextNode.textContent;
									//nextNode.textContent = ' ' + e.data.domEvent.$.key + text.slice(1, text.length);
								} else {
									this.guidWinT = this.guid().toUpperCase();
									this.tagContent = '<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea><wintitle contenteditable="true" style="background-color:#cfe2f3;" insID="' + this.guidWinT + '" id="' + this.guidWinT + '"></wintitle>' +
										'<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea></tags>';
									this.setOuterHTML(ancestor, this.tagContent);
									editor.document.getById(this.guidWinT).focus();
								}
							}
						} else if (ancestor.nodeName == 'WINTITLE') {
							ancestor.setAttribute("contenteditable", "true");
						} else if (ancestor.nodeName == 'TAG' || ancestor.nodeName == 'COM' || ancestor.nodeName == 'FLD' || ancestor.nodeName == 'TAGDESC' || ancestor.nodeName == 'TXT' || ancestor.nodeName == 'SPCHAR' || ancestor.nodeName == 'FRACTION'
							|| ancestor.nodeName == 'B' || ancestor.nodeName == 'U' || ancestor.nodeName == 'I' ) {
								if (e.data.keyCode == 8 || e.data.keyCode == 46) {
									while(ancestor.parentNode != undefined){
										if(ancestor.nodeName == 'TAG' || ancestor.nodeName == 'COM' || ancestor.nodeName == 'SPCHAR' || ancestor.nodeName == 'FRACTION'){
											
											if(this.isStatementParent(ancestor)){
												this.setOuterHTML(ancestor, "");
											}
											break;
											
										}else{
											ancestor = ancestor.parentNode;
										}
									}
								// if (ancestor.nodeName == 'TAG' || ancestor.nodeName == 'COM' || ancestor.nodeName == 'SPCHAR' || ancestor.nodeName == 'FRACTION') {
								// 	if (this.isStatementParent(ancestor)) {
								// 		this.setOuterHTML(ancestor, "");
								// 	}
								// } else if (ancestor.nodeName == 'FLD') {
								// 	if (ancestor.parentNode != undefined) {
								// 		var tagsNode = ancestor.parentNode;
								// 		if (this.isStatementParent(tagsNode)) {
								// 			this.setOuterHTML(tagsNode, "");
								// 		}
								// 	}
								// } else if (ancestor.nodeName == 'TAGDESC') {
								// 	if (ancestor.parentNode.parentNode != undefined) {
								// 		var tagsNode = ancestor.parentNode.parentNode;
								// 		if (this.isStatementParent(tagsNode)) {
								// 			this.setOuterHTML(tagsNode, "");
								// 		}
								// 	}
								// } else if (ancestor.nodeName == 'TXT') {
								// 	if (ancestor.parentNode.nodeName != 'STATEMENT' || ancestor.parentNode.nodeName != 'PARA' || ancestor.parentNode.nodeName != 'LI') {
								// 		if (ancestor.parentNode.parentNode.parentNode != undefined) {
								// 			var tagsNode = ancestor.parentNode.parentNode.parentNode;
								// 			if (tagsNode.nodeName == "TAG" && this.isStatementParent(tagsNode)) {
								// 				this.setOuterHTML(tagsNode, "");
								// 			}
								// 		}
								// 	}
								// }
							}
						} else {
							if (startNode != null && (startNode.nodeName == 'STATEMENT' || startNode.nodeName == 'TAGS' || startNode.nodeName == 'PARA' || startNode.nodeName == 'LI')) {
								if (this.checkPrintableKeys(e)) {
									let wintile = editor.document.getById(this.guidWinT).$;
									let text = wintile.textContent;
									wintile.textContent = text.slice(0, text.length - 1) + e.data.domEvent.$.key + ' ';
								}
							}
						}
					} else {
						this.alertService.clear();
						this.alertService.info(this.translate.instant('TAG_EDIT_AREA.NOT_VALID_SELECTION'), true);
					}
				}
			}
		}
	}

	private setOuterHTML(node: any, content: string) {
		if (node.parentNode != undefined) {
			node.outerHTML = content;
		} else {
			this.alertService.clear();
			this.alertService.info(this.translate.instant('TAG_EDIT_AREA.NOT_VALID_SELECTION'), true);
		}
	}

	private checkPrintableKeys(e: any): boolean {
		let printable: boolean = false;
		let keycode = e.data.domEvent.$.keyCode;
		printable = keycode == 32 ||
			(keycode > 47 && keycode < 58) ||
			(keycode > 64 && keycode < 91) ||
			(keycode > 95 && keycode < 112) ||
			(keycode > 185 && keycode < 193) ||
			(keycode > 218 && keycode < 223);
		return printable;
	}

	private getRestrictionValue(item: any) {
        var res = item.attributes['restriction']
        if (res != undefined) {
            var restriction = item.attributes['restriction'].value;
            if (restriction == "doc") {
                restriction = "documents";
            } else if (restriction == "com") { 
                restriction = "components";
                let compCategoryId = "";
                if (item.attributes['catid'] != undefined) {
                    compCategoryId = item.attributes['catid'].value;
                }
				if (compCategoryId != null && compCategoryId != "") {   
					this.procedureService.getCategoryList("Component")
				.subscribe(
					data => {
						this.categoryNameList = []
						if (data.payload) {
							console.log("category payload");
							console.log(data.payload);
							data.payload.forEach(element => {
								this.categoryNameList.push({ "categoryId": element.categoryId, 
								"categoryName": element.categoryName,
								"categoryType": element.categoryType
							})
							});
							for (let i = 0; i < this.categoryNameList.length; i++) {                        
								if (this.categoryNameList[i].categoryId == compCategoryId){
									this.categoryName = this.categoryNameList[i].categoryName;
									break;
								}                         
							}
						} else {
						}
						if (compCategoryId != null) {
							SharedService.searchComponentEventEmit(this.categoryName);
						}   						
					},
					error => {
						this.alertService.clear()
						this.alertService.error(error.statusDescription)
					});
				}          
				else {
					SharedService.searchComponentEventEmit("");
				}
            } else if (restriction == "tag") {
                restriction = "tags";
            } else if (restriction == "all") {
                restriction = "tags";
            } else if (restriction == "sys") {
                restriction = "systems";
            }
            SharedService.setStatementHtmlString(restriction);
			SharedService.openReusablepanel(restriction);
			setTimeout(() => {
				$('input[name=symbiosis_search]').focus();
			}, 500);               
        }
	}
	
	private getRestrictionValueOndblClick(item: any, catId:any) {
        if (item != undefined) {
            var restriction = item;
            if (item == "doc") {
                restriction = "documents";
            } else if (item == "com") { 
				//var compCategoryId = item.attributes['catid'].value;
				restriction = "components";
                let compCategoryId = "";
                if (catId != undefined && catId != null) {
                    compCategoryId = catId;
                }   
				if (compCategoryId != null && compCategoryId != "") {   
					this.procedureService.getCategoryList("Component")
				.subscribe(
					data => {
						this.categoryNameList = []
						if (data.payload) {
							console.log("category payload");
							console.log(data.payload);
							data.payload.forEach(element => {
								this.categoryNameList.push({ "categoryId": element.categoryId, 
								"categoryName": element.categoryName,
								"categoryType": element.categoryType
							})
							});
							for (let i = 0; i < this.categoryNameList.length; i++) {                        
								if (this.categoryNameList[i].categoryId == compCategoryId){
									this.categoryName = this.categoryNameList[i].categoryName;
									break;
								}                         
							}
						} else {
						}
						if (compCategoryId != null) {
							SharedService.searchComponentEventEmit(this.categoryName);
						}   						
					},
					error => {
						this.alertService.clear()
						this.alertService.error(error.statusDescription)
					});
				}          
				else {
					SharedService.searchComponentEventEmit("");
				}
            } else if (item == "tag") {
                restriction = "tags";
            } else if (item == "all") {
                restriction = "tags";
            } else if (item == "sys") {
                restriction = "systems";
            }
            SharedService.setStatementHtmlString(restriction);
			SharedService.openReusablepanel(restriction);
			setTimeout(() => {
				$('input[name=symbiosis_search]').focus();
			}, 500);
			
		                
        }
	}
	
	setElementTag(): String {
		return '<br>';
	}

	private escapeAttrValues(xml: any) {
		var self = this;
		$(xml).each(function() {
			var oldNode = this.outerHTML;
			var changedNode = false;
			$.each(this.attributes, function() {
				// this.attributes is not a plain object, but an array
				// of attribute nodes, which contain both the name and value
				if(this.specified) {
					if (this.value.includes("<")) {
						changedNode = true;
						this.value = this.value.replace(/</g, "&lt;");
					}
				}
			});
			var newNode = this.outerHTML;
			if (changedNode) {
				self.escapeAttrMap.set(oldNode, newNode);
			}
			$.each(this.childNodes, function() {
				self.escapeAttrValues(this);
			})
		});		
	}

	private processXML(xml: any, pFlag: boolean) {
		var self = this;
		this.escapeAttrMap = new Map();
		xml = xml.replace(/&nbsp;/g, " ");
		xml = xml.replace(/\u200B/g, "");
		xml = xml.replace(/\u000A/g, "");
		xml = xml.replace(/<span.*?>/g, "");
		xml = xml.replace(/<\/span>/g, "");
		xml = xml.replace(/<font.*?>/g, "");
		xml = xml.replace(/<\/font>/g, "");
		xml = xml.replace(/(?!<ph|<para)\<p.*?>/g, "");
		xml = xml.replace(/<\/p>/g, "");
		xml = xml.replace(/<br><\/wintitle>/g, "</wintitle>");
		xml = xml.replace(/(<br>|(<br> )){2,}<ul>/g, "<br><ul>");
		xml = xml.replace(/(<br>|(<br> )){2,}<ol>/g, "<br><ol>");
		xml = xml.replace(/<editarea.*?>*<\/editarea>/g, "");
		xml = xml.replace(/<p><br><\/p>/g, "");
		xml = xml.replace(/<div.*?>*<\/div>/g, "");
		//xml = xml.replace(/<wintitle.(?:(?!<wintitle.*?>).)*?><\/wintitle>/g, "");
		xml = xml.replace(/<para.*?>/g, "<p>");
		xml = xml.replace(/para>/g, "p>");
		xml = xml.replace(/<br type="_moz">/g, "<br>");
		xml = xml.replace(/<br>/g, "<br></br>");
		xml = xml.replace(/ text=\"([^']*?)\"/g, "");
		
		if (xml == "") {
			xml = "<br></br>";
		}
		let xmlStr = '<out>' + xml + '</out>';
		$(xmlStr).each(function() {
			var oldNode = this.outerHTML;
			var changedNode = false;			
			$.each(this.attributes, function() {
				// this.attributes is not a plain object, but an array
				// of attribute nodes, which contain both the name and value
				if (this.value.includes("<")) {
					changedNode = true;
					this.value = this.value.replace(/</g, "&lt;");
				}
			});
			var newNode = this.outerHTML;
			if (changedNode) {
				self.escapeAttrMap.set(oldNode, newNode);
			}
			$.each(this.childNodes, function() {
				self.escapeAttrValues(this);
			});
		});
		this.escapeAttrMap.forEach((value: string, key: string) => {
			let newVlaue = value.replace(/&amp;lt;/g,'&lt;');
			key = key.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
			let regex = new RegExp(key, 'g');
			xmlStr = xmlStr.replace(regex, newVlaue);
		});
		let xmlDoc;
		try {
			xmlDoc = $.parseXML(xmlStr);
		} catch {
			this.alertService.clear();
			this.alertService.error(this.translate.instant('INVALID_XML_MSG'), true);
		}
		let outNode = $(xmlDoc).find('out');
		for (let i = 0; i < outNode[0].childNodes.length; i++) {
			let length = outNode[0].childNodes.length;
			let innerNode = outNode[0].childNodes[i];
			if ($(innerNode)[0].nodeName == 'br') {
				if (length == 1) {
					this.xmlContent += '<p><font backgroundcolor="" color="#000000"></font></p>'
				} else {
					if (i == 0) {
						this.xmlContent += '<p><font backgroundcolor="" color="#000000"></font></p>'
					} else if (i != length - 1) {
						this.xmlContent += '<p>';
						if ($(innerNode)[0].nextSibling.nodeName == 'br' || $(innerNode)[0].nextSibling.nodeName == 'ul' || $(innerNode)[0].nextSibling.nodeName == 'ol') {
							this.xmlContent += '<font backgroundcolor="" color="#000000"></font></p>'
						}
					}
				}
			} else if ($(innerNode)[0].nodeName == 'ul' || $(innerNode)[0].nodeName == 'ol') {
				this.processOutXML($(innerNode)[0]);
			} else {
				if (i == 0 || $(innerNode)[0].previousSibling.nodeName == 'ol' || $(innerNode)[0].previousSibling.nodeName == 'ul' || (i == 1 && $(innerNode)[0].previousSibling.nodeName == 'br')) {
					this.xmlContent += '<p>';
				}
				this.processOutXML($(innerNode)[0]);
				if (i == length - 1 || $(innerNode)[0].nextSibling.nodeName == 'br' || $(innerNode)[0].nextSibling.nodeName == 'ul' || $(innerNode)[0].nextSibling.nodeName == 'ol') {
					this.xmlContent += '</p>';
				}
			}
		}
	}

	getListStyleType(styles: string): string | null {
		// Split the styles string by semicolons to get individual style declarations
		const styleDeclarations = styles.split(';');
	  
		// Iterate through the style declarations
		for (const declaration of styleDeclarations) {
		  // Split each declaration by colon to get the property and value
		  const [property, value] = declaration.split(':').map(item => item.trim());
	  
		  // Check if the property is 'list-style-type'
		  if (property === 'list-style-type') {
			return value;
		  }
		}
	  
		// If 'list-style-type' is not found, return null
		return null;
	}

	processOutXML(node: any) {
		if (node.nodeName == 'ol' || node.nodeName == 'ul') {
			if (node.nodeName == 'ol') {
				let liststyle = "decimal";
				if (node.attributes.length >= 1) {
					liststyle = this.getListStyleType(node.getAttribute('style')).trim();
				 }
				 if (liststyle == "lower-roman") {
					liststyle = "lowerRoman";
				} else if (liststyle == "lower-alpha") {
					liststyle = "lowerAlpha";
				} else if (liststyle == "upper-roman") {
					liststyle = "upperRoman";
				} else if (liststyle == "upper-alpha") {
					liststyle = "upperAlpha";
				}
				this.xmlContent += '<ol ckeditorlist="true" listStylePosition="outside" listStyleType="' + liststyle + '" paddingLeft="30">' +
					'<listformat fontWeight="normal" paragraphEndIndent="4" textDecoration="none"/>'
			} else if (node.nodeName == 'ul') {
				let liststyle = "disc";
				if (node.attributes.length >= 1) {
					liststyle = this.getListStyleType(node.getAttribute('style')).trim();
				 }
				this.xmlContent += '<ul ckeditorlist="true" listStylePosition="outside" listStyleType="' + liststyle + '" paddingLeft="30">' +
					'<listformat fontWeight="normal" paragraphEndIndent="4" textDecoration="none"/>'
			}
			this.processListItemXML(node, 1);
			if (node.nodeName == 'ol') {
				this.xmlContent += '</ol>';
			} else if (node.nodeName == 'ul') {
				this.xmlContent += '</ul>';
			}
		} else if (node.nodeName == 'statement' || node.nodeName == 'tags' || node.nodeName == 'fraction' || node.nodeName == '#text' || node.nodeName == 'b' || node.nodeName == 'i' || node.nodeName == 'u' || node.nodeName == 'sup' || node.nodeName == 'sub' || node.nodeName == 'a' || node.nodeName == 'xref') {
			this.processReusablesXML(node, false, false, false, false, false);
		}
	}

	processListItemXML(node: any, level: number) {
		for (let i = 0; i < node.childNodes.length; i++) {
			let liNode = node.childNodes[i];
			if (liNode.nodeName == 'li') {
				this.xmlContent += '<li level="' + level + '">';
				for (let i = 0; i < liNode.childNodes.length; i++) {
					let childNode = liNode.childNodes[i];
					if (childNode.nodeName == 'ul') {
						let listStyle = "disc";
						if (childNode.attributes.length >= 1) {
							listStyle = this.getListStyleType(childNode.getAttribute('style')).trim();
						}
						if (level == 1) {
							listStyle = "circle";
							if (childNode.attributes.length >= 1) {
								listStyle = this.getListStyleType(childNode.getAttribute('style')).trim();
							}
						} else {
							listStyle = "square";
							if (childNode.attributes.length >= 1) {
								listStyle = this.getListStyleType(childNode.getAttribute('style')).trim();
							}
						} 
						this.xmlContent += '<' + childNode.nodeName + ' ckeditorlist="true" listStylePosition="outside" listStyleType="' + listStyle + '" paddingLeft="30"' + '>';
						this.processListItemXML($(childNode)[0], level + 1);
						this.xmlContent += '</' + childNode.nodeName + '>';
					} else if (childNode.nodeName == 'ol') {
						let listStyle = "decimal";
						if (childNode.attributes.length >= 1) {
							listStyle = this.getListStyleType(childNode.getAttribute('style')).trim();
						}
						if (listStyle == "lower-roman") {
							listStyle = "lowerRoman";
						} else if (listStyle == "lower-alpha") {
							listStyle = "lowerAlpha";
						} else if (listStyle == "upper-roman") {
							listStyle = "upperRoman";
						} else if (listStyle == "upper-alpha") {
							listStyle = "upperAlpha";
						}
						this.xmlContent += '<' + childNode.nodeName + ' ckeditorlist="true" listStylePosition="outside" listStyleType="' + listStyle + '" paddingLeft="30"' + '>';
						this.processListItemXML($(childNode)[0], level + 1);
						this.xmlContent += '</' + childNode.nodeName + '>';
					} else {
						if (i == 0 || childNode.previousSibling.nodeName == 'ol' || childNode.previousSibling.nodeName == 'ul') {
							this.xmlContent += "<p>";
						}
						if (childNode.nodeName == '#text' || childNode.nodeName == 'statement' || childNode.nodeName == 'tags' || childNode.nodeName == 'fraction' || childNode.nodeName == 'b' || childNode.nodeName == 'i' || childNode.nodeName == 'u' || childNode.nodeName == 'sup' || childNode.nodeName == 'sub' || childNode.nodeName == 'a' || childNode.nodeName == 'xref') {
							this.processReusablesXML(childNode, false, false, false, false, false);
						} else if (childNode.nodeName == 'br') {
							this.xmlContent += '<font backgroundcolor="" color="#000000"></font>';
						}
						if (i == liNode.childNodes.length - 1 || childNode.nextSibling.nodeName == 'ol' || childNode.nextSibling.nodeName == 'ul') {
							this.xmlContent += "</p>";
						}
					}
				}
				this.xmlContent += '</li>';
			}
		}
	}

	processStmNode(node: any) {
		if (node.nodeName == 'tags') {
			let tagsNode = node;
			for (let k = 0; k < tagsNode.childNodes.length; k++) {
				let name = tagsNode.childNodes[k].nodeName;
				if (name == 'tag') {
					let tagNode = tagsNode.childNodes[k];
					if (tagNode.childNodes[0].nodeName == 'fld') {
						let fldChild = tagNode.childNodes[0].childNodes[0];
						if (fldChild.nodeName != 'tagdesc') {
							this.checkBoldItaUnder(fldChild, tagNode.childNodes[0]);
							$(fldChild).remove();
						} else 	{
							let tagdesChild = fldChild.childNodes[0];
							let textNode = tagdesChild.childNodes[0];
							//this.appendTxtContent(textNode, tagdesChild, false, false, false, null, null);
							this.checkTagDescBoldItaUnder(textNode, node, tagdesChild);
							$(textNode).remove();
						}
					}
				} else if (name == 'com') {
					let node = tagsNode.childNodes[k];
					let textNode = node.childNodes[0];
					this.checkBoldItaUnder(node, node);
					$(textNode).remove();
				} else if (name == 'wintitle') {
					let node = tagsNode.childNodes[k];
					let textNode = node.childNodes[0];
					if (textNode != undefined) {
						$(node).removeAttr('contenteditable');
						$(node).removeAttr('id');
						$(node).attr("text", textNode.textContent);
						this.checkBoldItaUnder(node, node);
						$(textNode).remove();
					}
				} else if (name == 'spchar' || name == 'fraction') {
					let spNode = tagsNode.childNodes[k];
					let textNode = spNode.childNodes[0];
					$(textNode).remove();
				}
			}
		} else if (node.nodeName == 'ph' || node.nodeName == 'txt') {
			let bgc = "";
			let fgc = "";
			let stateNode = node;

			if(stateNode.childNodes.length ==1){
				if(stateNode.childNodes[0].nodeName=="txt"){
					let textNode = stateNode.childNodes[0];
					bgc = $(node).attr("bgc");
					fgc = $(node).attr("fgc")
					this.appendTxtContent(textNode, stateNode, false, false, false, bgc, fgc);
					$(textNode).remove();
				}
				else if(stateNode.childNodes[0].nodeName=="b" || stateNode.childNodes[0].nodeName=="u" || stateNode.childNodes[0].nodeName=="i"){
					let buiNode = stateNode.childNodes[0];
					for(let i = 0; i<buiNode.childNodes.length; i++){
						this.processStmNode(buiNode.childNodes[i]);
					}
				}
			}
			for(let i=0; i<stateNode.childNodes.length; i++){
				if (stateNode.childNodes[i].nodeName == 'ph' || stateNode.childNodes[i].nodeName == 'tags' || stateNode.childNodes[i].nodeName == 'txt') {	
					this.processStmNode(stateNode.childNodes[i]);								
				}
			}
		}
	}

	processReusablesXML(node: any, uFlag: boolean, bFlag: boolean, iFlag: boolean, subFlag: boolean, supFlag: boolean) {
		if (node.nodeName == 'statement') {
			$(node).removeAttr('contenteditable');
			for (let j = 0; j < node.childNodes.length; j++) {
				if (node.childNodes[j].nodeName == 'tags' || node.childNodes[j].nodeName == 'ph' || node.childNodes[j].nodeName == 'txt') {
					this.processStmNode(node.childNodes[j]);
				} else if ( node.childNodes[j].nodeName == 'ul') {
					let listNode = node.childNodes[j];
					for (let x = 0; x < listNode.attributes.length; x++) {
					 if (listNode.attributes[x].name == "style") {
						var values = listNode.attributes[x].nodeValue.split(" ");
						var listst = values[1] ? listNode.attributes[x].nodeValue.substr(listNode.attributes[x].nodeValue.indexOf(' ') + 1) : '';
						var values1 = listNode.attributes[x].nodeValue.split(":");
						let listsyle1 = values1[1] ? listst.substr(listst.indexOf(':') + 1) : '';
						let liststyle = listsyle1.trim();
						$(listNode).attr("listStyleType", liststyle);
					 }
					}
					$(listNode).removeAttr('contenteditable');
					for (let j = 0; j < listNode.childNodes.length; j++) {
						let itemNode = listNode.childNodes[j];
						for (let k = 0; k < itemNode.childNodes.length; k++) {
							if (itemNode.childNodes[k].nodeName == 'tags' || itemNode.childNodes[k].nodeName == 'ph') {
								this.processStmNode(itemNode.childNodes[k]);
							}
							else if (itemNode.childNodes[k].nodeName == 'txt') {
								let txtNode = itemNode.childNodes[k];
								for (let l = 0; l < txtNode.childNodes.length; l++) {
									if (txtNode.childNodes[l].nodeName == 'ph' || txtNode.childNodes[l].nodeName == 'tags') {
										this.processStmNode(txtNode.childNodes[l]);
									} else if (txtNode.childNodes[l].nodeName == 'txt') {										
										this.processStmNode(txtNode.childNodes[l]);								
									} else if (txtNode.childNodes[l].nodeName == 'ul' || txtNode.childNodes[l].nodeName == 'ol') {	
										this.processStatementMultiLevel(txtNode.childNodes[l]);								
									}	
								}
							}
						}
					}
				} else if (node.childNodes[j].nodeName == 'ol') {
					let listNode = node.childNodes[j];
					for (let x = 0; x < listNode.attributes.length; x++) {
						if (listNode.attributes[x].name == "style") {
							var values = listNode.attributes[x].nodeValue.split(" ");
							var listst = values[1] ? listNode.attributes[x].nodeValue.substr(listNode.attributes[x].nodeValue.indexOf(' ') + 1) : '';
							var values1 = listNode.attributes[x].nodeValue.split(":");
							let listsyle1 = values1[1] ? listst.substr(listst.indexOf(':') + 1) : '';
							let liststyle = listsyle1.trim();
							if (liststyle == "lower-roman") {
								liststyle = "lowerRoman";
							} else if (liststyle == "lower-alpha") {
								liststyle = "lowerAlpha";
							} else if (liststyle == "upper-roman") {
								liststyle = "upperRoman";
							} else if (liststyle == "upper-alpha") {
								liststyle = "upperAlpha";
							}
						$(listNode).attr("listStyleType", liststyle);
					 }
					}
					$(listNode).removeAttr('contenteditable');
					for (let j = 0; j < listNode.childNodes.length; j++) {
						let itemNode = listNode.childNodes[j];
						for (let k = 0; k < itemNode.childNodes.length; k++) {
							if (itemNode.childNodes[k].nodeName == 'tags' || itemNode.childNodes[k].nodeName == 'ph') {
								this.processStmNode(itemNode.childNodes[k]);
							}
							else if (itemNode.childNodes[k].nodeName == 'txt') {
								let txtNode = itemNode.childNodes[k];
								for (let l = 0; l < txtNode.childNodes.length; l++) {
									if (txtNode.childNodes[l].nodeName == 'ph'|| txtNode.childNodes[l].nodeName == 'tags') {
										this.processStmNode(txtNode.childNodes[l]);
									} else if (txtNode.childNodes[l].nodeName == 'txt') {										
										this.processStmNode(txtNode.childNodes[l]);											
									} else if (txtNode.childNodes[l].nodeName == 'ol' || txtNode.childNodes[l].nodeName == 'ul') {	
										this.processStatementMultiLevel(txtNode.childNodes[l]);								
									}		 	
								}
							}
						}
					}
				} else if (node.childNodes[j].nodeName == 'p') {
					let pNode = node.childNodes[j];
					$(pNode).removeAttr('contenteditable');
					for (let j = 0; j < pNode.childNodes.length; j++) {
						if (pNode.childNodes[j].nodeName == 'tags' || pNode.childNodes[j].nodeName == 'ph') {
							this.processStmNode(pNode.childNodes[j]);
						} else if (pNode.childNodes[j].nodeName == 'txt') {
							let txtNode = pNode.childNodes[j];
							for (let k = 0; k < txtNode.childNodes.length; k++) {
								if (txtNode.childNodes[k].nodeName == 'ph' || txtNode.childNodes[k].nodeName == 'tags') {
									this.processStmNode(txtNode.childNodes[k]);
								} else if (txtNode.childNodes[k].nodeName == 'txt') {
									// let formatNode = txtNode.childNodes[j];
									// for (let j = 0; j < formatNode.childNodes.length; j++) {
									// 	if (formatNode.childNodes[j].nodeName == 'b') {
											this.processStmNode(txtNode.childNodes[k]);
										// }
									// }
								}													
							}
						}
					}
				}
			}
			this.xmlContent += node.outerHTML;
		} else if (node.nodeName == 'tags') {
			$(node).removeAttr('contenteditable');
			if (iFlag) {
				$(node).attr("italic", "true");
			}
			if (uFlag) {
				$(node).attr("underline", "true");
			}
			if (bFlag) {
				$(node).attr("bold", "true");
			}
			for (let k = 0; k < node.childNodes.length; k++) {
				let tagNode = node.childNodes[k];
				if (tagNode.nodeName == 'tag') {
					if (tagNode.childNodes[0].nodeName == 'fld') {
						let fldChild = tagNode.childNodes[0].childNodes[0];
						if (fldChild.nodeName != 'tagdesc') {
							this.checkBoldItaUnder(fldChild, node);
							$(fldChild).remove();
						} else {
							let tagdesChild = fldChild.childNodes[0];
							let textNode = tagdesChild.childNodes[0];
							this.checkTagDescBoldItaUnder(textNode, node, tagdesChild);
							$(textNode).remove();
						}
					}
				} else if (tagNode.nodeName == 'com') {
					let textNode = tagNode.childNodes[0];
					this.checkBoldItaUnder(textNode, node);
					$(textNode).remove();
				} else if (tagNode.nodeName == 'spchar') {
					let textNode = tagNode.childNodes[0];
					$(textNode).remove();
				}
			}
			this.xmlContent += node.outerHTML;
		} else if (node.nodeName == '#text') {
			if (subFlag) {
				this.xmlContent += '<subscript text="' + node.textContent + '"';
				if (bFlag) {
					this.xmlContent += ' bold="true"';
				}
				if (iFlag) {
					this.xmlContent += ' italic="true"';
				}
				this.xmlContent += '/>';
			} else if (supFlag) {
				this.xmlContent += '<superscript text="' + node.textContent + '"';
				if (bFlag) {
					this.xmlContent += ' bold="true"';
				}
				if (iFlag) {
					this.xmlContent += ' italic="true"';
				}
				this.xmlContent += '/>';
			} else {
				let text = this.htmlEncode($(node)[0].textContent);
				if (bFlag) {
					this.xmlContent += '<B>';
				}
				if (iFlag) {
					this.xmlContent += '<I>';
				}
				if (!uFlag) {
					this.xmlContent += '<font backgroundcolor="" color="#000000">' + text + '</font>';
				} else {
					this.xmlContent += '<font color="#000000" backgroundcolor=""><U>' + text + '</U></font>';
				}
				if (iFlag) {
					this.xmlContent += '</I>';
				}
				if (bFlag) {
					this.xmlContent += '</B>';
				}
			}
		} else if (node.nodeName == 'b') {
			for (let k = 0; k < node.childNodes.length; k++) {
				let bNode = node.childNodes[k];
				if (bNode.nodeName == 'tags' || bNode.nodeName == 'fraction' || bNode.nodeName == 'statement' || bNode.nodeName == '#text' || bNode.nodeName == 'font' || bNode.nodeName == 'i' || bNode.nodeName == 'u' || bNode.nodeName == 'sub' || bNode.nodeName == 'sup' || bNode.nodeName == 'a' || bNode.nodeName == 'xref' || bNode.nodeName == 'br') {
					this.processReusablesXML(bNode, uFlag, true, iFlag, subFlag, supFlag);
				}
			}
		} else if (node.nodeName == 'u') {
			for (let k = 0; k < node.childNodes.length; k++) {
				let uNode = node.childNodes[k];
				if (uNode.nodeName == 'tags' || uNode.nodeName == 'fraction' || uNode.nodeName == 'statement' || uNode.nodeName == '#text' || uNode.nodeName == 'font' || uNode.nodeName == 'i' || uNode.nodeName == 'b' || uNode.nodeName == 'sub' || uNode.nodeName == 'sup' || uNode.nodeName == 'a' || uNode.nodeName == 'xref' || uNode.nodeName == 'br') {
					this.processReusablesXML(uNode, true, bFlag, iFlag, subFlag, supFlag);
				}
			}
		} else if (node.nodeName == 'i') {
			for (let k = 0; k < node.childNodes.length; k++) {
				let iNode = node.childNodes[k];
				if (iNode.nodeName == 'tags' || iNode.nodeName == 'fraction' || iNode.nodeName == 'statement' || iNode.nodeName == '#text' || iNode.nodeName == 'font' || iNode.nodeName == 'b' || iNode.nodeName == 'u' || iNode.nodeName == 'sub' || iNode.nodeName == 'sup' || iNode.nodeName == 'a' || iNode.nodeName == 'xref' || iNode.nodeName == 'br') {
					this.processReusablesXML(iNode, uFlag, bFlag, true, subFlag, supFlag);
				}
			}
		} else if (node.nodeName == 'fraction') {
			$(node).removeAttr('contenteditable');
			if (iFlag) {
				$(node).attr("italic", "true");
			}
			if (uFlag) {
				$(node).attr("underline", "true");
			}
			if (bFlag) {
				$(node).attr("bold", "true");
			}
			let textNode = node.childNodes[0];
			$(textNode).remove();
			this.xmlContent += node.outerHTML;
		} else if (node.nodeName == 'sub') {
			for (let k = 0; k < node.childNodes.length; k++) {
				let childNode = node.childNodes[k];
				if (childNode.nodeName == '#text') {
					this.xmlContent += '<subscript text="' + childNode.textContent + '"';
					if (bFlag) {
						this.xmlContent += ' bold="true"';
					}
					if (iFlag) {
						this.xmlContent += ' italic="true"';
					}
					this.xmlContent += '/>';
				} else {
					this.processReusablesXML(childNode, false, false, false, true, false);
				}
			}
		} else if (node.nodeName == 'sup') {
			for (let k = 0; k < node.childNodes.length; k++) {
				let childNode = node.childNodes[k];
				if (childNode.nodeName == '#text') {
					this.xmlContent += '<superscript text="' + childNode.textContent + '"';
					if (bFlag) {
						this.xmlContent += ' bold="true"';
					}
					if (iFlag) {
						this.xmlContent += ' italic="true"';
					}
					this.xmlContent += '/>';
				} else {
					this.processReusablesXML(childNode, false, false, false, false, true);
				}
			}
		} else if (node.nodeName == 'a') {
			let childNode = node.childNodes[0];
			let url = $(node).attr("href");
			this.xmlContent += '<XRef topicId="" xrefType="External" refInsId="" refurl="' + this.htmlEncode(url) + '">';
			if (childNode.nodeName == '#text') {
				this.xmlContent += this.htmlEncode(childNode.textContent);
			}
			this.xmlContent += '</XRef>';
		} else if (node.nodeName == 'xref') {
			let childNode = node.childNodes[0];
			if (childNode != undefined) {
				let topicId = $(node).attr("topicid");
				let xrefType = $(node).attr("xreftype");
				let refInsId = $(node).attr("refinsid");
				this.xmlContent += '<XRef style="background-color:#f4b342;" topicId="' + topicId + '" xrefType="' + xrefType + '" refInsId="' + refInsId + '">';
				if (childNode.nodeName == '#text') {
					this.xmlContent += this.htmlEncode(childNode.textContent);
				}
				this.xmlContent += '</XRef>';
			}
		} else if (node.nodeName == 'br') {
			this.xmlContent += '</p><p>';
		}
	}

	checkBoldItaUnder(node: any, tagsNode: any) {
		if (node.nodeName == 'b') {
			$(tagsNode).attr("bold", "true");
		} else if (node.nodeName == 'i') {
			$(tagsNode).attr("italic", "true");
		} else if (node.nodeName == 'u') {
			$(tagsNode).attr("underline", "true");
		} if (node.nodeName == '#text') {
			return;
		} else {
			this.checkBoldItaUnder(node.childNodes[0], tagsNode);
		}
	}

	checkTagDescBoldItaUnder(node: any, tagsNode: any, tagDesChild: any) {
		if (node.nodeName == 'b') {
			$(tagsNode).attr("bold", "true");
		} else if (node.nodeName == 'i') {
			$(tagsNode).attr("italic", "true");
		} else if (node.nodeName == 'u') {
			$(tagsNode).attr("underline", "true");
		} if (node.nodeName == '#text') {
			$(tagDesChild).append(this.htmlEncode(node.textContent));
			return;
		} else {
			this.checkTagDescBoldItaUnder(node.childNodes[0], tagsNode, tagDesChild);
		}
	}

	appendTxtContent(node: any, parent: any, b: boolean, i: boolean, u: boolean, bgcVal: any, fgcVal: any) {
		let bFlag: boolean = b;
		let iFlag: boolean = i;
		let uFlag: boolean = u;
		let bgc = bgcVal;
		let fgc = fgcVal;
		let nodeText = "";
		if (node != undefined) {
			if (node.nodeName == '#text') {
				let text = this.htmlEncode(node.textContent);
				if (bFlag && !iFlag && !uFlag) {
					if ((fgc != null && fgc != "") || (bgc != null && bgc != "")) {
						$(parent).attr("bgc", bgc);
						$(parent).attr("fgc", fgc);
					}
					nodeText = "<B>" + text + "</B>";
				} else if (!bFlag && iFlag && !uFlag) {
					if ((fgc != null && fgc != "") || (bgc != null && bgc != "")) {
						$(parent).attr("bgc", bgc);
						$(parent).attr("fgc", fgc);
					}
					nodeText = "<I>" + text + "</I>";
				} else if (!bFlag && !iFlag && uFlag) {
					if ((fgc != null && fgc != "") || (bgc != null && bgc != "")) {
						$(parent).attr("bgc", bgc);
						$(parent).attr("fgc", fgc);
					}
					nodeText = "<U>" + text + "</U>";
				} else if (bFlag && iFlag && !uFlag) {
					if ((fgc != null && fgc != "") || (bgc != null && bgc != "")) {
						$(parent).attr("bgc", bgc);
						$(parent).attr("fgc", fgc);
					}
					nodeText = "<B><I>" + text + "</I></B>";
				} else if (bFlag && !iFlag && uFlag) {
					if ((fgc != null && fgc != "") || (bgc != null && bgc != "")) {
						$(parent).attr("bgc", bgc);
						$(parent).attr("fgc", fgc);
					}
					nodeText = "<B><U>" + text + "</U></B>";
				} else if (!bFlag && iFlag && uFlag) {
					if ((fgc != null && fgc != "") || (bgc != null && bgc != "")) {
						$(parent).attr("bgc", bgc);
						$(parent).attr("fgc", fgc);
					}
					nodeText = "<I><U>" + text + "</U></I>";
				} else if (bFlag && iFlag && uFlag) {
					if ((fgc != null && fgc != "") || (bgc != null && bgc != "")) {
						$(parent).attr("bgc", bgc);
						$(parent).attr("fgc", fgc);
					}
					nodeText = "<B><I><U>" + text + "</U></I></B>";
				} else {
					if ((fgc != null && fgc != "") || (bgc != null && bgc != "")) {
						$(parent).attr("bgc", bgc);
						$(parent).attr("fgc", fgc);
					}
					nodeText = text;	
				}
				$(parent).append(nodeText);
				return;
			} else {
				if (node.nodeName == 'b') {
					bFlag = true;
				} else if (node.nodeName == 'i') {
					iFlag = true;
				} else if (node.nodeName == 'u') {
					uFlag = true;
				} else if (node.nodeName == 'txt') {
					bgc = $(node).attr("bgc");
					fgc = $(node).attr("fgc")
				}
				if(node.childNodes.length==1 && node.nodeName !='b' && node.nodeName !='u' && node.nodeName !='i'){
					this.appendTxtContent(node.childNodes[0], parent, bFlag, iFlag, uFlag, bgc, fgc);
				}else{
					let newClone = node.cloneNode([true]);
					$(parent).append(newClone);
					for (let j = 0; j < newClone.childNodes.length; j++) {
						// let newClone = node.childNodes[j].cloneNode([true]);
						if(newClone.childNodes[j].nodeName == 'tags' || newClone.childNodes[j].nodeName == 'txt'){
							this.processStmNode(newClone.childNodes[j]);
						}
						//$(parent).append(newClone);
					}
					if ((fgc != null && fgc != "") || (bgc != null && bgc != "")) {
						$(parent).attr("bgc", bgc);
						$(parent).attr("fgc", fgc);
						$(newClone).removeAttr("bgc");
						$(newClone).removeAttr("fgc");
					}
					return
				}
			}
		}
	}

	setReadOnlyEditors() {
		for (let i in this.editorList) {
			if (CKEDITOR.instances[this.editorList[i].name] != undefined) {
				CKEDITOR.instances[this.editorList[i].name].setReadOnly("true");
			}
		}
	}

	ngOnDestroy() {
		for (let i in this.editorList) {
			if (CKEDITOR.instances[this.editorList[i].name] != undefined) {
				CKEDITOR.instances[this.editorList[i].name].destroy();
			}
		}
		this.timerSubscription.unsubscribe();
		this.contentStr = "";
		this.susbscription.unsubscribe();
		// localStorage.removeItem(this.tempSaveKey);
	}

	onDepStepAdded(event) {
		this.sharedService.setViewOnlyStatus(this.fileId, false);
		let j = Number(event.display) - 1;
		event.value = this.stepsList[j].id;
	}

	onDepStepRemoved(event) {
		this.sharedService.setViewOnlyStatus(this.fileId, false);
	}

	onDepStepFocused(stepNo: number, step: Step) {
		SharedService.setStep(step);
		SharedService.setStepNo(stepNo + 1);
		SharedService.setStepListSize(this.stepsList.length);
	}

	
	onOperatingDataAdded(event) {
	}

	onOperatingDataRemoved(event) {
	}

	onOperatingDataFocused(stepNo: number, step: Step) {
	}

	private must_be_valid_depno(control: any) {
		let displayVal = Number(control.value);
		let stepNo = SharedService.getStepNo();
		let step = SharedService.getStep();
		let stepListSize = SharedService.getStepListSize();
		let DEP_REGEXP = /^[0-9]+$/i;
		if (control.value != "") {
			if (!DEP_REGEXP.test(control.value)) {
				return { "must_be_number": true };
			} else {
				if (displayVal == stepNo) {
					return { "must_not_be_itself": true };
				} else if (displayVal > stepListSize || displayVal == 0) {
					return { "must_be_valid_step_number": true };
				} else {
					for (let i in step.dependencySteps) {
						if (step.dependencySteps[i].display == displayVal) {
							return { "must_be_valid_step_number": true };
						}
					}
				}
			}
		}
		return null;
	}

	public collapseAndExpandAll() {
		//console.log('[collapseAndExpandAll]');
		this.isExpandAll = !this.isExpandAll
		if (this.isExpandAll) {
			this.collapseExpandBtnText = this.pro_translated_data.COLLAPSE_ALL
		} else {
			this.collapseExpandBtnText = this.pro_translated_data.EXPAND_ALL
		}
	}

	public badgeDoubleClick(itemNum: number) {
		//console.log('[badgeDoubleClick]');
		this.isShowBadgeItem = itemNum
		this.selectedItemStepNum = itemNum + 1
	}
	
	/**
	 * Event to fire on double click on step number.
	 * @param event  
	 * @param step Step
	 */
	public badgeDoubleClickToEdit(event, step: Step) {
		// console.log('badgeDoubleClick');
		if (this.isCheckOut && !this.isCheckOutByOthers) {
			$('.edit-input-wrapper.editmode').removeClass('editmode');
			var self = this;
			$(event.target).parents('.edit-input-wrapper').toggleClass('editmode');
			setTimeout(() => {
				$(event.target).parents('.edit-input-wrapper').find('input').focus();
			}, 0);
			$('.edit-input-wrapper.editmode').find('input').on('keyup', function (e) {
				if (e.keyCode === 13) {
					//Enter key press
					self.moveStep(step);
					$('.edit-input-wrapper.editmode').removeClass('editmode');
				} else if (e.keyCode == 27) {
					//Escape key press
					$('.edit-input-wrapper.editmode').removeClass('editmode');
				}
			});
		}

	}

	/**
	 * Move steps function.
	 * @param step Step.
	 */
	public moveStep(step: Step) {
		let input: any = $('.edit-input-wrapper.editmode').find('input').val();
		if (this.isCheckOut && !this.isCheckOutByOthers) {
			if (input != undefined) {
				let newStep: number = Number(input);
				let trueStepNum: number;
				for (let i = 0; i < this.stepsList.length; i++) {
					let item = this.stepsList[i];
					if (item.id == step.id) {
						trueStepNum = item.stepNo;
						break;
					}
				}
				let totalSteps = this.stepsList.length;
				if ((newStep < 1) || (newStep > totalSteps)) {
					//Entered step number is not valid
					this.alertService.clear();
					this.alertService.error(this.translate.instant('PROCEDURE_EDITOR.MOVE_PROC_WARN_NOT_A_VALID_STEP_NO') + totalSteps, true);
				} else if (newStep > trueStepNum) {
					//Entered step number > current step
					this.moveDownSteps(newStep, trueStepNum);
				} else if (newStep < trueStepNum) {
					//Entered step number < current step
					this.moveUpSteps(newStep, trueStepNum);
				} else {
					//Same step number has enterd.
					this.alertService.clear();
					this.alertService.error(this.translate.instant('PROCEDURE_EDITOR.MOVE_PROC_WARN_SAME_STEP_ENTERED'), true);
				}
			}
		} else {
			this.alertService.clear();
			this.alertService.error(this.translate.instant('Please check out the procedure'), true);

		}		
		$('.edit-input-wrapper.editmode').removeClass('editmode');
	}

	/**
	 * Move a step to down.
	 * @param newStep number new step.
	 * @param truStepNum number current step.
	 */
	public moveDownSteps(newStep: number, truStepNum: number) {
		this.sharedService.setViewOnlyStatus(this.fileId, false);
		for (let i = 0; i < this.stepsList.length; i++) {
			let stepListItem = this.stepsList[Number(i)];
			if (stepListItem.stepNo == truStepNum) {
				stepListItem.stepNo = newStep;
			} else if ((stepListItem.stepNo <= newStep) && (stepListItem.stepNo > truStepNum)) {
				stepListItem.stepNo = Number(stepListItem.stepNo) - 1;
			}
		}
		//handle dependancy steps
		setTimeout(() => {
			for (let i = 0; i < this.stepsList.length; i++) {
				let item = this.stepsList[i];
				if (item.dependencyFlag) {
					for (let k = 0; k < item.dependencySteps.length; k++) {
						for (let j = 0; j < this.stepsList.length; j++) {
							let depStep = this.stepsList[j];
							if (item.dependencySteps[k].value == depStep.id) {
								item.dependencySteps[k].display = Number(j) + 1;
							}
						}
					}
				}
			}
		}, 200);
	}

	/**
	 * Move a step to up.
	 * @param newStep number new step.
	 * @param truStepNum number current step.
	 */
	public moveUpSteps(newStep: number, truStepNum: number) {
		this.sharedService.setViewOnlyStatus(this.fileId, false);
		//let currentStepNo = step.stepNo;
		for (let i = 0; i < this.stepsList.length; i++) {
			let stepListItem = this.stepsList[i];
			if (stepListItem.stepNo == truStepNum) {
				stepListItem.stepNo = newStep;
			}
			else if ((stepListItem.stepNo >= newStep) && (stepListItem.stepNo <= truStepNum)) {
				stepListItem.stepNo = Number(stepListItem.stepNo) + 1;
			}

		}
		//handle dependancy steps
		setTimeout(() => {
			for (let i = 0; i < this.stepsList.length; i++) {
				let item = this.stepsList[i];
				if (item.dependencyFlag) {
					for (let k = 0; k < item.dependencySteps.length; k++) {
						for (let j = 0; j < this.stepsList.length; j++) {
							let depStep = this.stepsList[j];
							if (item.dependencySteps[k].value == depStep.id) {
								item.dependencySteps[k].display = Number(j) + 1;
							}
						}
					}
				}
			}
		}, 200);
	}

	public onKeydownStepNum(event: any) {
		//console.log('[onKeydownStepNum]'); 
		switch (event.key) {
			case 'Enter':
				//console.log('ENTER button click event');
				break;
			case 'Escape':
				//console.log('ESC button click event');
				break;
			default:
				break;
		}
		this.isShowBadgeItem = -1
		this.selectedItemStepNum = -1
	}

	addSpeChar(value: string) {
		if (value != "") {
			let decVal = Number(value);
			this.speCharList.push({ data: { symbol: this.getSymbolFromDecimal(decVal), decVal: decVal, type: "speChar" } });
		}
	}

	numberOnly(event): boolean {
		const charCode = (event.which) ? event.which : event.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}

	addFraction(num: string, den: string) {
		if (den != "" && num != "") {
			let denominator = parseInt(den);
			if (denominator != 0) {
				const frac = {
					numerator: num,
					denominator: den,
					type: 'fraction'
				};
				const fracStr = JSON.stringify(frac);
				let event = JSON.parse(fracStr);
				const fracObj = {
					data: event
				};
				const fracObjStr = JSON.stringify(fracObj);
				let data = JSON.parse(fracObjStr);
				this.insertTagEditArea(data);
			} else {
				this.alertService.clear();
				this.alertService.error(this.translate.instant('WEB_EDITOR.DENOMINATOR_ZERO'));
			}
		} else {
			this.alertService.clear();
			this.alertService.error(this.translate.instant('WEB_EDITOR.NUMERATOR_AND_DENOMINATOR_EMPTY'));
		}
	}

	private changeToImagePanel() {
		SharedService.openReusablepanel('images');
	}

	public autoUpdateProc() {
		let selectedNodes = this.gridApi.getSelectedRows();
		let length = this.gridApi.getSelectedRows().length;
		if (length == 0){
			this.alertService.clear();
			this.alertService.error(this.translate.instant('WEB_EDITOR.SELECT_REUSABLES_TO_UPDATE'));
		} else {
			this.modalRef.hide();
			for (let i = 0; i < length; i++) {
				let id = selectedNodes[i].id;
				let type = selectedNodes[i].type;
				let flag = selectedNodes[i].isDeleted;
				if (type == 'TagNo') {
					let nodes = $(".toggle-edit" + this.tabId + this.userId).find("tag#" + id);
					for (let j = 0; j < nodes.length; j++) {
						let fldNode = nodes[j].childNodes[0];
						let fldName = $(fldNode).attr("name");
						if (fldName == 'tagNo') {
							if (flag == 'false') {
								$(fldNode).attr("lastupdatetimestamp", selectedNodes[i].lastUpdateTimeStamp);
								$(fldNode).attr("value", selectedNodes[i].newVal);
								fldNode.childNodes[0].textContent = selectedNodes[i].newVal;
							} else {
								let node = nodes[j];
								$(node).remove();
							}
						}
					}
				} if (type == 'TagSystem') {
					let nodes = $(".toggle-edit" + this.tabId + this.userId).find("tag#" + id);
					for (let j = 0; j < nodes.length; j++) {
						let fldNode = nodes[j].childNodes[0];
						let fldName = $(fldNode).attr("name");
						if (fldName == 'sysName') {
							if (flag == 'false') {
								$(fldNode).attr("lastupdatetimestamp", selectedNodes[i].lastUpdateTimeStamp);
								$(fldNode).attr("value", selectedNodes[i].newVal);
								fldNode.childNodes[0].textContent = selectedNodes[i].newVal;
							} else {
								let node = nodes[j];
								$(node).remove();
							}
						}
					}
				} if (type == 'TagDocument') {
					let nodes = $(".toggle-edit" + this.tabId + this.userId).find("tag#" + id);
					for (let j = 0; j < nodes.length; j++) {
						let fldNode = nodes[j].childNodes[0];
						let fldName = $(fldNode).attr("name");
						if (fldName == 'docName') {
							if (flag == 'false') {
								$(fldNode).attr("lastupdatetimestamp", selectedNodes[i].lastUpdateTimeStamp);
								$(fldNode).attr("value", selectedNodes[i].newVal);
								fldNode.childNodes[0].textContent = selectedNodes[i].newVal;
							} else {
								let node = nodes[j];
								$(node).remove();
							}
						}
					}
				} else if (type == 'TagDesc') {
					let nodes = $(".toggle-edit" + this.tabId + this.userId).find("tag#" + id)
					for (let j = 0; j < nodes.length; j++) {
						let fldNode = nodes[j].childNodes[0];
						let fldName = $(fldNode).attr("name");
						if (fldName == 'tagName') {
							if (flag == 'false') {
								let txtNode = fldNode.childNodes[0].childNodes[0]
								$(fldNode).attr("lastupdatetimestamp", selectedNodes[i].lastUpdateTimeStamp);
								txtNode.textContent = selectedNodes[i].newVal;
							} else {
								let node = nodes[j];
								$(node).remove();
							}
						}
					}
				} else if (type == 'Component' || type == 'Document' || type == 'Facility') {
					let nodes = $(".toggle-edit" + this.tabId + this.userId).find("com#" + id)
					for (let j = 0; j < nodes.length; j++) {
						let node = nodes[j];
						let compType = $(node).attr("compType");
						if (compType == 'doc' || compType == 'com' || compType == 'fac') {
							if (flag == 'false') {
								$(node).attr("lastupdatetimestamp", selectedNodes[i].lastUpdateTimeStamp);
								$(node).attr("compValue", selectedNodes[i].newVal);
								node.textContent = selectedNodes[i].newVal;
							} else {
								$(node).remove();
							}
						}
					}
				} else if (type == 'System Number') {
					let nodes = $(".toggle-edit" + this.tabId + this.userId).find("com#" + id)
					for (let j = 0; j < nodes.length; j++) {
						let node = nodes[j];
						let compType = $(node).attr("compName");
						if (compType == 'SystemNo') {
							if (flag == 'false') {
								$(node).attr("lastupdatetimestamp", selectedNodes[i].lastUpdateTimeStamp);
								$(node).attr("compvalue", selectedNodes[i].newVal);
								node.textContent = selectedNodes[i].newVal;
							} else {
								$(node).remove();
							}
						}
					}
				} else if (type == 'System Name') {
					let nodes = $(".toggle-edit" + this.tabId + this.userId).find("com#" + id)
					for (let j = 0; j < nodes.length; j++) {
						let node = nodes[j];
						let compType = $(node).attr("compName");
						if (compType == 'SystemName') {
							if (flag == 'false') {
								$(node).attr("lastupdatetimestamp", selectedNodes[i].lastUpdateTimeStamp);
								$(node).attr("compvalue", selectedNodes[i].newVal);
								node.textContent = selectedNodes[i].newVal;
							} else {
								$(node).remove();
							}
						}
					}
				} else if (type == 'Statement') {
					let oldStmNode: any;
					let newStmHTML: string = '';
					let nodes = $(".toggle-edit" + this.tabId + this.userId).find("statement#" + id)
					for (let j = 0; j < nodes.length; j++) {
						oldStmNode = nodes[j];
						if (flag == 'false') {
							newStmHTML = this.createEditedStatement(selectedNodes[i].name, selectedNodes[i].lastUpdateTimeStamp, oldStmNode)
							this.setOuterHTML(oldStmNode, newStmHTML);
						} else {
							$(oldStmNode).remove();
						}
					}
				} else if (type == 'TagAttribute') {
					let nodes =  $(".toggle-edit" + this.tabId + this.userId).find("attr");
					for (let j = 0; j < nodes.length; j++) {
						let node = nodes[j];
						let specId = $(node).attr("specid");
						if(specId == id) {
							if (flag == 'false') {
								$(node).attr("lastupdatetimestamp", selectedNodes[i].lastUpdateTimeStamp);
								$(node).attr("value", selectedNodes[i].newVal);
								node.textContent = selectedNodes[i].newVal;
							} else {
								$(node).remove();
							}
						}
					}
				}
			}
			this.alertService.clear();
			this.alertService.warn(this.translate.instant('PROCEDURE_EDITOR.CONTENT_UPDATE_MSG'), true);
		}
		
	}

	createEditedStatement(statementContent: string, lastUpdateTimeStamp: string, node: any): string {
		this.stmHtml = '';
		let xmlDoc;
		try {
			xmlDoc = $.parseXML(statementContent);
		} catch {
			this.alertService.clear();
			this.alertService.error(this.translate.instant('INVALID_XML_MSG'), true);
		}
		let outNode = $(xmlDoc).find('statement');
		let length = outNode[0].childNodes.length;
		let id = outNode.attr('id');
		let label = this.htmlEncode(outNode.attr('label'));
		this.stmHtml = "<statement style='background-color:#d9d9d9;' insID='" + this.guid() + "' lastUpdateTimeStamp='" + lastUpdateTimeStamp + "' contenteditable='false' id='" +
			id + "' label='" + label + "'>";
		for (let i = 0; i < length; i++) {
			let innerNode = outNode[0].childNodes[i];
			if ($(innerNode)[0].nodeName == 'txt') {
				this.stmHtml += "<txt";
				let bgc = $(innerNode).attr("bgc");		
	                let fgc = $(innerNode).attr("fgc");		
	                if (bgc != null && bgc != "") {		
	                    this.stmHtml += " bgc='"+bgc+ "' "		
	                }		
	                if (fgc != null && fgc != "") {		
	                    this.stmHtml += " fgc='"+fgc+ "' "		
	                }		
	                this.stmHtml += ">";
				for (let j = 0; j < $(innerNode)[0].childNodes.length; j++) {
					let childTxtNode = $(innerNode)[0].childNodes[j];
					if ($(childTxtNode)[0].nodeName == '#text') {
						this.stmHtml += childTxtNode.textContent;
					} else {
						this.processNewStatement(childTxtNode, node);
					}
				}
				this.stmHtml += "</txt>";
			} else if ($(innerNode)[0].nodeName == 'ph') {
				this.processNewStatement($(innerNode)[0], node);
			} else if ($(innerNode)[0].nodeName == 'p') {
				if ($(innerNode)[0].childNodes.length != 0) {
					this.stmHtml += '<para contenteditable="false" style="background-color:#d9d9d9;display:block;">';
				} else {
					this.stmHtml += '<para contenteditable="false" style="background-color:#d9d9d9;display:block;">&nbsp;';
				}
				for (let i = 0; i < $(innerNode)[0].childNodes.length; i++) {
					let childNode = $(innerNode)[0].childNodes[i];
					if ($(childNode)[0].nodeName == 'txt') {
						this.stmHtml += "<txt";
						let bgc = $(innerNode).attr("bgc");		
	                		let fgc = $(innerNode).attr("fgc");		
	                		if (bgc != null && bgc != "") {		
	                    		this.stmHtml += " bgc='"+bgc+ "' "		
	                		}		
	                		if (fgc != null && fgc != "") {		
	                    		this.stmHtml += " fgc='"+fgc+ "' "		
	                		}		
	                		this.stmHtml += ">";
						for (let j = 0; j < $(childNode)[0].childNodes.length; j++) {
							let childTxtNode = $(childNode)[0].childNodes[j];
							if ($(childTxtNode)[0].nodeName == '#text') {
								this.stmHtml += this.htmlEncode(childTxtNode.textContent);
							} else {
								this.processNewStatement(childTxtNode, node);
							}
						}
						this.stmHtml += "</txt>";
					} else if ($(childNode)[0].nodeName == 'ph') {
						this.processNewStatement($(childNode)[0], node);
					}
				}
				this.stmHtml += '</para> ';
			} else if ($(innerNode)[0].nodeName == 'ol' || $(innerNode)[0].nodeName == 'ul') {
				this.stmHtml += '<' + $(innerNode)[0].nodeName + ' contenteditable="false" style="background-color:#d9d9d9;">';
				for (let i = 0; i < $(innerNode)[0].childNodes.length; i++) {
					let liNode = $(innerNode)[0].childNodes[i];
					if (liNode.nodeName == 'li') {
						this.stmHtml += '<li>';
						for (let i = 0; i < liNode.childNodes.length; i++) {
							let childNode = liNode.childNodes[i];
							if (childNode.nodeName == 'txt') {
								$(childNode).removeAttr('style');
								this.processNewStatement(childNode, node);
							} else if (childNode.nodeName == 'ph') {
								this.processNewStatement(childNode, node);
							}
						}
						this.stmHtml += '</li>';
					}
				}
				this.stmHtml += '</' + $(innerNode)[0].nodeName + '>';
			}
		}
		this.stmHtml += "</statement>";
		return this.stmHtml;
	}
	private getOldTagsNode(tagPHId:String, node: any){
		if(node.nodeName == "tags" || node.nodeName == "TAGS" || node.nodeName == "ph"){
			if($(node).attr('tagphid') == tagPHId){
				return node;
			}else{
				return null;
			}
		}else{
			for(let i=0; i<node.childNodes.length;i++){
				let tagsNode = this.getOldTagsNode(tagPHId, node.childNodes[i])
				if(tagsNode != null){
					return tagsNode;
				}
			}
		}
		return null;
	}

	private processNewStatement(node: any, oldStmNode: any) {
		if (node.nodeName == 'ph') {
			let isTags: boolean = false;
			let arrLen = node.attributes.length;
			let attrPh, attrRestriction, value, attrCatId;
			for (let n = 0; n < arrLen; n++) {
				let attrName = node.attributes[n].name;
				let attrValue = node.attributes[n].value;
				if (attrName == 'tagPHId' || attrName == 'tagphid') {
					attrPh = attrValue;
				} else if (attrName == 'restriction') {
					attrRestriction = attrValue;
				} else if (attrName == 'catId') {
					attrCatId = attrValue;
				}
			}
			let oldTagsNode = this.getOldTagsNode(attrPh, oldStmNode);
			if (oldTagsNode != null) {
				this.stmHtml += oldTagsNode.outerHTML;
				isTags = true;
			}
			if (!isTags) {
				if (attrPh != undefined && attrRestriction != undefined) {
					value = node.childNodes[0].textContent;
					if (attrCatId != undefined) {
						if (value != undefined) {
							this.stmHtml += "<ph insID='" + this.guid() + "' style='background-color:#ffffcc ;' catId='" + attrCatId + "' tagPHId='" + attrPh + "' restriction='" + attrRestriction + "'>" + value + "</ph>";
						} else {
							this.stmHtml += "<ph insID='" + this.guid() + "' style='background-color:#ffffcc ;' catId='" + attrCatId + "' tagPHId='" + attrPh + "' restriction='" + attrRestriction + "'></ph>";
						}
					} else {
						if (value != undefined) {
							this.stmHtml += "<ph insID='" + this.guid() + "' style='background-color:#ffffcc ;' tagPHId='" + attrPh + "' restriction='" + attrRestriction + "'>" + value + "</ph>";
						} else {
							this.stmHtml += "<ph insID='" + this.guid() + "' style='background-color:#ffffcc ;' tagPHId='" + attrPh + "' restriction='" + attrRestriction + "'></ph>";
						}
					}
				} else if (attrPh != undefined && attrRestriction == undefined) {
					this.stmHtml += "<ph insID='" + this.guid() + "' style='background-color:#ffffcc ;' tagPHId='" + attrPh + "'></ph>";
				}
			}
		} else if (node.nodeName == 'txt') {
            this.stmHtml += "<txt";
            let bgc = $(node).attr("bgc");
            let fgc = $(node).attr("fgc");
            if (bgc != null && bgc != "") {
                this.stmHtml += " bgc='" + bgc + "' "
            }
            if (fgc != null && fgc != "") {
                this.stmHtml += " fgc='" + fgc + "' "
            }
            this.stmHtml += ">";
            for (let j = 0; j < $(node)[0].childNodes.length; j++) {
                let childTxtNode = $(node)[0].childNodes[j];
                if ($(childTxtNode)[0].nodeName == '#text') {
                    this.stmHtml += childTxtNode.textContent;
                } else {
                    this.processNewStatement(childTxtNode, oldStmNode);
                }
            }
			this.stmHtml += "</txt>";
		} else if (node.nodeName == 'ol') {		
			let listStyle = $(node).attr("liststyle");		
			this.stmHtml += '<' + $(node)[0].nodeName + ' contenteditable="false" ckeditorlist="true" style="background-color:#d9d9d9; list-style-type:' + listStyle +		
				'">';		
			for (let i = 0; i < $(node)[0].childNodes.length; i++) {		
				let liNode = $(node)[0].childNodes[i];		
				if (liNode.nodeName == 'li') {		
					this.stmHtml += '<li>';		
					for (let i = 0; i < liNode.childNodes.length; i++) {		
						let childNode = liNode.childNodes[i];		
						if (childNode.nodeName == 'txt') {		
							$(childNode).removeAttr('style');		
							this.processNewStatement(childNode, oldStmNode);		
						} else if (childNode.nodeName == 'ph') {		
							this.processNewStatement(childNode, oldStmNode);		
						}		
					}		
					this.stmHtml += '</li>';		
				}		
			}		
			this.stmHtml += '</' + $(node)[0].nodeName + '>';		
		
		} else if (node.nodeName == 'ul') {		
			let listStyle = $(node).attr("liststyle");		
			this.stmHtml += '<' + $(node)[0].nodeName + ' contenteditable="false" ckeditorlist="true" style="background-color:#d9d9d9; list-style-type:' + listStyle +		
				'">';		
			for (let i = 0; i < $(node)[0].childNodes.length; i++) {		
				let liNode = $(node)[0].childNodes[i];		
				if (liNode.nodeName == 'li') {		
					this.stmHtml += '<li>';		
					for (let i = 0; i < liNode.childNodes.length; i++) {		
						let childNode = liNode.childNodes[i];		
						if (childNode.nodeName == 'txt') {		
							$(childNode).removeAttr('style');		
							this.processNewStatement(childNode, oldStmNode);		
						} else if (childNode.nodeName == 'ph') {		
							this.processNewStatement(childNode, oldStmNode);		
						}		
					}		
					this.stmHtml += '</li>';		
				}		
			}		
			this.stmHtml += '</' + $(node)[0].nodeName + '>';
		}else if (node.nodeName == 'b' || node.nodeName == 'u' || node.nodeName == 'i'){
			this.stmHtml += '<'+node.nodeName+'>';
			for (let i = 0; i < node.childNodes.length; i++) {		
				let childNode = node.childNodes[i];		
				this.processNewStatement(childNode, oldStmNode);		
			}
			this.stmHtml += '</'+node.nodeName+'>';
		}else if (node.nodeName == '#text'){
			this.stmHtml += node.nodeValue;
		}else {
			let str = node.outerHTML;
			if(str != undefined){
				str = str.replace(/<font.*?>/gi, '');
				str = str.replace(/<\/font>/gi, '');
				this.stmHtml += str;
			}
		}
	}

	private processStatementMultiLevel(node: any) {
		if (node.nodeName == 'ol') {
			for (let x = 0; x < node.attributes.length; x++) {
				if (node.attributes[x].name == "style") {
					var values = node.attributes[x].nodeValue.split(" ");
					var listst = values[1] ? node.attributes[x].nodeValue.substr(node.attributes[x].nodeValue.indexOf(' ') + 1) : '';
					var values1 = node.attributes[x].nodeValue.split(":");
					let listsyle1 = values1[1] ? listst.substr(listst.indexOf(':') + 1) : '';
					let liststyle = listsyle1.trim();
					if (liststyle == "lower-roman") {
						liststyle = "lowerRoman";
					} else if (liststyle == "lower-alpha") {
						liststyle = "lowerAlpha";
					} else if (liststyle == "upper-roman") {
						liststyle = "upperRoman";
					} else if (liststyle == "upper-alpha") {
						liststyle = "upperAlpha";
					}
					$(node).attr("listStyleType", liststyle);
				}
			}
			$(node).removeAttr('contenteditable');
			for (let j = 0; j < node.childNodes.length; j++) {
				let itemNode = node.childNodes[j];
				for (let k = 0; k < itemNode.childNodes.length; k++) {
					if (itemNode.childNodes[k].nodeName == 'tags' || itemNode.childNodes[k].nodeName == 'ph') {
						this.processStmNode(itemNode.childNodes[k]);
					}
					else if (itemNode.childNodes[k].nodeName == 'txt') {
						let txtNode = itemNode.childNodes[k];
						for (let l = 0; l < txtNode.childNodes.length; l++) {
							if (txtNode.childNodes[l].nodeName == 'ph' || txtNode.childNodes[l].nodeName == 'tags') {
								this.processStmNode(txtNode.childNodes[l]);
							} else if (txtNode.childNodes[l].nodeName == 'txt') {										
								this.processStmNode(txtNode.childNodes[l]);											
							} else if (txtNode.childNodes[l].nodeName == 'ol' || txtNode.childNodes[l].nodeName == 'ul') {										
								this.processStatementMultiLevel(txtNode.childNodes[l]);											
							}	 	
						}
					}
				}
			}
		} else if (node.nodeName == 'ul') {
			for (let x = 0; x < node.attributes.length; x++) {
				if (node.attributes[x].name == "style") {			
					var values = node.attributes[x].nodeValue.split(" ");
					var listst = values[1] ? node.attributes[x].nodeValue.substr(node.attributes[x].nodeValue.indexOf(' ') + 1) : '';
					var values1 = node.attributes[x].nodeValue.split(":");
					let listsyle1 = values1[1] ? listst.substr(listst.indexOf(':') + 1) : '';
					let liststyle = listsyle1.trim();
					$(node).attr("listStyleType", liststyle);
				}
			}
			$(node).removeAttr('contenteditable');
			for (let j = 0; j < node.childNodes.length; j++) {
				let itemNode = node.childNodes[j];
				for (let k = 0; k < itemNode.childNodes.length; k++) {
					if (itemNode.childNodes[k].nodeName == 'tags' || itemNode.childNodes[k].nodeName == 'ph') {
						this.processStmNode(itemNode.childNodes[k]);
					}
					else if (itemNode.childNodes[k].nodeName == 'txt') {
						let txtNode = itemNode.childNodes[k];
						for (let l = 0; l < txtNode.childNodes.length; l++) {
							if (txtNode.childNodes[l].nodeName == 'ph' || txtNode.childNodes[l].nodeName == 'tags') {
								this.processStmNode(txtNode.childNodes[l]);
							} else if (txtNode.childNodes[l].nodeName == 'txt') {										
								this.processStmNode(txtNode.childNodes[l]);											
							} else if (txtNode.childNodes[l].nodeName == 'ul'|| txtNode.childNodes[l].nodeName == 'ol') {										
								this.processStatementMultiLevel(txtNode.childNodes[l]);											
							}	 	
						}
					}
				}
			}
		}
	}
	activeEvent(event){
		if(event.nodeName=="FONT"){
			parent =this.renderer.parentNode(event);
			this.renderer.addClass(parent, "selected");
			// @ts-ignore
			this.selectedEleNew = parent;
		}else{
			this.selectedEleNew = event;
		}
		this.renderer.addClass(event, "selected");
		if(this.selectedEleOld!==""){
			if(this.selectedEleOld!=this.selectedEleNew ){
				this.renderer.removeClass(this.selectedEleOld, "selected");
				this.selectedEleOld=this.selectedEleNew;
			}
		}else{
			this.selectedEleOld=this.selectedEleNew;
		}
	}
	removeAcive(){
		if(this.selectedEleOld != undefined && this.selectedEleOld!==""){
			this.renderer.removeClass(this.selectedEleOld, "selected");
		}
		if(this.selectedEleNew != undefined && this.selectedEleNew!==""){
			this.renderer.removeClass(this.selectedEleNew, "selected");
		}
	}

	browserResize() {
		this.heightFactor = 1;
		this.windowHeight = window.innerHeight;
		if (window.screen.availHeight > window.outerHeight) {
			if ((window.screen.availHeight - window.outerHeight) !== 0) {
				//Not fullScreen
				this.isBrowserFullScreen = false;
				this.divHeight = 0.8*(window.outerHeight / window.screen.availHeight)*(window.innerHeight);
			} else {
				//fullScreen
				this.isBrowserFullScreen = true;
			}
		}
	}
	private findUpTag(el, tag) {
		while (el.parentNode) {
			el = el.parentNode;
			if (el.tagName === tag)
				return el;
		}
		return null;
	}

	processPartialStmFF(outNode: any, idList: string[]) {
		this.partialStm = "";
		for (let j = 0; j < outNode.childNodes.length; j++) {
			let node = outNode.childNodes[j];
			if (node.nodeName == 'statement') {
				if (idList.includes(node.attributes["insid"].value, 1)) {
					for (let j = 0; j < node.childNodes.length; j++) {
						let childNode = node.childNodes[j];
						if (childNode.nodeName == 'txt') {
							for(let i = 0; i < childNode.childNodes.length; i++){
								if (childNode.childNodes[i].nodeName == 'tags' || childNode.childNodes[i].nodeName == 'txt' || childNode.childNodes[i].nodeName == '#text' || childNode.childNodes[i].nodeName == 'ph') {	
									this.processPartialStmNode(childNode.childNodes[i]);								
								} else if(childNode.childNodes[i].nodeName == "b" || childNode.childNodes[i].nodeName == "u" || childNode.childNodes[i].nodeName == "i"){
									let buiNode = childNode.childNodes[i];
									this.partialStm += '<' + buiNode.nodeName + '>';
									for(let i = 0; i < buiNode.childNodes.length; i++){
										this.processPartialStmNode(buiNode.childNodes[i]);
									}
									this.partialStm += '</' + buiNode.nodeName + '>';
								}					 	
							}										
						} else if (childNode.nodeName == 'ph') {
							this.partialStm  += childNode.textContent;
						} else if (childNode.nodeName == 'tags' || childNode.nodeName == 'p' || childNode.nodeName == 'para') {
							this.processPartialStmNode(childNode);
						} else if (childNode.nodeName == 'ul' || childNode.nodeName == 'ol') {
							this.partialStm  += childNode.textContent;
						} else if (childNode.nodeName == "b" || childNode.nodeName == "u" || childNode.nodeName == "i"){
							this.partialStm += '<' + childNode.nodeName + '>';
							for(let i = 0; i < childNode.childNodes.length; i++){
								this.processPartialBUINode(childNode.childNodes[i]);
							}
							this.partialStm += '</' + childNode.nodeName + '>';
						} 				
					}
				} else {
					this.partialStm += node.outerHTML;
				}
			} else {
				this.processPartialStmNode(node);
			}
		}
	}
	
	processPartialStm(outNode: any) {
		this.partialStm = "";
		for (let j = 0; j < outNode.childNodes.length; j++) {
			let node = outNode.childNodes[j];
			if (node.nodeName == 'statement') {
				for (let j = 0; j < node.childNodes.length; j++) {
					let childNode = node.childNodes[j];
					if (childNode.nodeName == 'txt') {
						for(let i = 0; i < childNode.childNodes.length; i++){
							if (childNode.childNodes[i].nodeName == 'tags' || childNode.childNodes[i].nodeName == 'txt' || childNode.childNodes[i].nodeName == '#text' || childNode.childNodes[i].nodeName == 'ph') {	
								this.processPartialStmNode(childNode.childNodes[i]);								
							} else if(childNode.childNodes[i].nodeName == "b" || childNode.childNodes[i].nodeName == "u" || childNode.childNodes[i].nodeName == "i"){
								let buiNode = childNode.childNodes[i];
								this.partialStm += '<' + buiNode.nodeName + '>';
								for(let i = 0; i < buiNode.childNodes.length; i++){
									this.processPartialStmNode(buiNode.childNodes[i]);
								}
								this.partialStm += '</' + buiNode.nodeName + '>';
							}					 	
						}										
					} else if (childNode.nodeName == 'ph') {
						this.partialStm  += childNode.textContent;
					} else if (childNode.nodeName == 'tags' || childNode.nodeName == 'p' || childNode.nodeName == 'para') {
						this.processPartialStmNode(childNode);
					} else if (childNode.nodeName == 'ul' || childNode.nodeName == 'ol') {
						this.partialStm  += childNode.textContent;
					} else if (childNode.nodeName == "b" || childNode.nodeName == "u" || childNode.nodeName == "i"){
						this.partialStm += '<' + childNode.nodeName + '>';
						for(let i = 0; i < childNode.childNodes.length; i++){
							this.processPartialBUINode(childNode.childNodes[i]);
						}
						this.partialStm += '</' + childNode.nodeName + '>';
					} 				
				}
			} else {
				this.processPartialStmNode(node);
			}
		}
	}

	processPartialStmNode(node: any) {
		if (node.nodeName == 'tags') {
			for (let k = 0; k < node.childNodes.length; k++) {
				let tagNode = node.childNodes[k];
				if (tagNode.nodeName == '#text' || tagNode.nodeName == 'wintitle') {
					this.partialStm += this.htmlEncode(tagNode.textContent);	
				} else if (tagNode.nodeName == 'tag' || tagNode.nodeName == 'com' || tagNode.nodeName == 'spchar') {										
					this.partialStm += '<tags contenteditable="false" insID="' + this.guid().toUpperCase() + '" style="background-color: #FFFF8D;">' + tagNode.outerHTML + '</tags>';
				} else if (tagNode.nodeName == 'fraction') {
					this.partialStm += tagNode.outerHTML;
				}
			}
		} else if (node.nodeName == 'txt') {
			for(let i = 0; i < node.childNodes.length; i++){
				if (node.childNodes[i].nodeName == 'tags' || node.childNodes[i].nodeName == 'txt') {	
					this.processPartialStmNode(node.childNodes[i]);								
				} else if (node.childNodes[i].nodeName == '#text') {
					this.partialStm  += this.htmlEncode(node.childNodes[i].textContent);
				} else if (node.childNodes[i].nodeName == 'ph') {
					this.partialStm  += node.childNodes[i].textContent;
				} else if(node.childNodes[i].nodeName == "b" || node.childNodes[i].nodeName == "u" || node.childNodes[i].nodeName == "i"){
					let buiNode = node.childNodes[i];
					this.partialStm += '<' + buiNode.nodeName + '>';
					for(let i = 0; i < buiNode.childNodes.length; i++){
						this.processPartialStmNode(buiNode.childNodes[i]);
					}
					this.partialStm += '</' + buiNode.nodeName + '>';
				}
			}
		} else if (node.nodeName == 'p' || node.nodeName == 'para') {
			for (let j = 0; j < node.childNodes.length; j++) {
				if (node.childNodes[j].nodeName == 'txt' || node.childNodes[j].nodeName == 'tags' || node.childNodes[j].nodeName == 'ph') {
					this.processPartialStmNode(node.childNodes[j]);
				} else if (node.childNodes[j].nodeName == 'br') {
					this.partialStm += '<br><br>';
				} else if (node.nodeName == '#text') {
					this.partialStm += this.htmlEncode(node.textContent);	
				} 
			}
		} else if (node.nodeName == 'ul' || node.nodeName == 'ol') {
			this.partialStm  += node.outerHTML;
		} else if(node.nodeName == "b" || node.nodeName == "u" || node.nodeName == "i"){
			this.partialStm += '<' + node.nodeName + '>';
			for(let i = 0; i < node.childNodes.length; i++){
				this.processPartialStmNode(node.childNodes[i]);
			}
			this.partialStm += '</' + node.nodeName + '>';
		} else if (node.nodeName == '#text' || node.nodeName == 'span') {
			this.partialStm += this.htmlEncode(node.textContent);	
		} else if (node.nodeName == 'ph') {
			this.partialStm  += node.textContent
		} else if (node.nodeName == 'br') {
			this.partialStm += '<br>';
		}
	}

	processPartialBUINode(node: any) {
		if (node.nodeName == "b" || node.nodeName == "u" || node.nodeName == "i"){
			this.partialStm += '<' + node.nodeName + '>';
			for(let i = 0; i < node.childNodes.length; i++){
				this.processPartialBUINode(node.childNodes[i]);
			}
			this.partialStm += '</' + node.nodeName + '>';
		} else {
			this.partialStm  += node.textContent;
		}
	}

	replaceInsIdOnPaste(data: string) : string {
		if (data.match(/insid=""/g) != null) {
			data = data.replace(/insid=""/gi, function insId() {
					function s4() {
						return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
					}
					return 'insId="' + s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4() + '"';
			});
		}
		data = data.replace(/(insid)=.{38}/gi, function insId() {
			function s4() {
				return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
			}
			return 'insId="' + s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4() + '"';
		});
		return data;
	}

	processPasteData(data: string) : string {
		var self = this;
		this.escapeAttrMap = new Map();
		this.hasResuables = false;
		data = data.replace(/<div.*?>/g, "");
		data = data.replace(/<\/div>/g, "");
		data = data.replace(/<br.*?>/g, "<br/>");
		data = data.replace(/&nbsp;/g, "&#160;");
		if(this.isFirefox) {
			if (localStorage.getItem('partialStmTxt') != null && localStorage.getItem('partialStmTxt').includes("Statement")) {				
				if (localStorage.getItem('disabledEditor') != null && localStorage.getItem('disabledEditor') == "true") {
					//paste only free text
					var xmlStr: string = '<xml>' + data + '</xml>';
					var xmlDoc = $.parseXML(xmlStr);
					let outNode = $(xmlDoc).find('xml');
					this.checkForReusables(outNode[0].outerHTML);			
					if(this.hasResuables) {
						return outNode[0].textContent;
					} else {
						data = data.replace(/<p><br\/><\/p>/g, '<br>');
						data = data.replace(/(?!<ph|<para)\<p.*?>/g, "");
						data = data.replace(/<\/p>/g, "<br>");
						data = data.replace(/<br\/>/g, "<br>");
						data = data.replace(/<span.*?>/g, "");
						data = data.replace(/<\/span>/g, "");
						data = data.replace(/<font.*?>/g, "");
						data = data.replace(/<\/font>/g, "");
						return data;
					}
				}	
				else if (localStorage.getItem('disabledEditor') == null || localStorage.getItem('disabledEditor') == "false") {												
					let copyArray = data.match(/<statement.*?>*<\/statement>/g);
					if(copyArray != null) {
						for(let i in copyArray) {
							let copyData = copyArray[i];
							let originalData = copyData;
							copyData = copyData.replace(/(?!<ph)\<p.*?>/g, '<para contenteditable="false" style="background-color:#d9d9d9;display:block;">').replace(/p>/g, "para>").replace(/<br\/>/g, "&#160;");	
							data = data.replace(originalData, copyData);
						}
					}
					data = data.replace(/(?!<ph|<para)\<p.*?>/g, "");
					data = data.replace(/<\/p>/g, "");
					let idStr = localStorage.getItem('partialStmTxt');
					let idList = idStr.split(':')
					for(let j = 1; j < idList.length; j++) {
						let expression = 'insid="' +idList[j] + '".*?>*<\/statement>';
						let regex = new RegExp(expression, 'g');
						let resultArray = data.match(regex);
						if(resultArray != null) {
							let copyData = resultArray[0];
							let originalData = copyData;
							copyData = copyData.replace(/<editarea.*?>*<\/editarea>/g, " ");
							copyData = copyData.replace(/<wintitle.*?>/g, "");
							copyData = copyData.replace(/<\/wintitle>/g, "");
							data = data.replace(originalData, copyData);
						}
					}
					var xmlStr: string = '<xml>' + data + '</xml>'
					$(xmlStr).each(function() {
						var oldNode = this.outerHTML;
						var changedNode = false;			
						$.each(this.attributes, function() {
							if (this.value.includes("<")) {
								changedNode = true;
								this.value = this.value.replace(/</g, "&lt;");
							}
						});
						var newNode = this.outerHTML;
						if (changedNode) {
							self.escapeAttrMap.set(oldNode, newNode);
						}
						$.each(this.childNodes, function() {
							self.escapeAttrValues(this);
						});
					});
					this.escapeAttrMap.forEach((value: string, key: string) => {
						let newVlaue = value.replace(/&amp;lt;/g,'&lt;');
						key = key.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
						let regex = new RegExp(key, 'g');
						xmlStr = xmlStr.replace(regex, newVlaue);
					});
					var xmlDoc = $.parseXML(xmlStr);
					let outNode = $(xmlDoc).find('xml');
					this.processPartialStmFF(outNode[0], idList);
					this.partialStm = this.replaceInsIdOnPaste(this.partialStm);
					return this.partialStm;
				}	
			} else {
				if (localStorage.getItem('disabledEditor') != null && localStorage.getItem('disabledEditor') == "true") {
					//paste only free text
					var xmlStr: string = '<xml>' + data + '</xml>';
					var xmlDoc = $.parseXML(xmlStr);
					let outNode = $(xmlDoc).find('xml');
					this.checkForReusables(outNode[0].outerHTML);
					if(this.hasResuables) {
						return outNode[0].textContent;
					} else {
						data = data.replace(/<p><br\/><\/p>/g, '<br>');
						data = data.replace(/(?!<ph|<para)\<p.*?>/g, "");
						data = data.replace(/<\/p>/g, "<br>");
						data = data.replace(/<br\/>/g, "<br>");
						data = data.replace(/<span.*?>/g, "");
						data = data.replace(/<\/span>/g, "");
						data = data.replace(/<font.*?>/g, "");
						data = data.replace(/<\/font>/g, "");
						return data;
					}
				}	
				else if (localStorage.getItem('disabledEditor') == null || localStorage.getItem('disabledEditor') == "false") {											
					var xmlStr: string = '<xml>' + data + '</xml>'
					var parser = new DOMParser();
					var xmlDoc = parser.parseFromString(xmlStr, "text/xml");
					let copyArray = data.match(/<statement.*?>*<\/statement>/g);
					if (copyArray != null) {						
						for(let i in copyArray) {
							let copyData = copyArray[i];
							let originalData = copyData;
							copyData = copyData.replace(/(?!<ph)\<p.*?>/g, '<para contenteditable="false" style="background-color:#d9d9d9;display:block;">').replace(/p>/g, "para>").replace(/<br\/>/g, "&nbsp;");	
							data = data.replace(originalData, copyData);
						}
					}
					var xrefEle = xmlDoc.getElementsByTagName("xref")[0];
					if (xrefEle !== undefined) {
						data = data.replace(/refinsid/gi, "ref_ins");
					}
					data = this.replaceInsIdOnPaste(data);
					if (xrefEle !== undefined) {		
						data = data.replace(/ref_ins/gi, "refinsid");		
					}
					data = data.replace(/<p><br\/><\/p>/g, '<br>');
					data = data.replace(/(?!<ph|<para)\<p.*?>/g, "");
					data = data.replace(/<\/p>/g, "<br>");
					return data;
				}
			} 
		} else {
			if (localStorage.getItem('partialStmTxt') != null && localStorage.getItem('partialStmTxt').includes("Statement")) {
				if (localStorage.getItem('disabledEditor') != null && localStorage.getItem('disabledEditor') == "true") {
					//paste only free text
					var xmlStr: string = '<xml>' + data + '</xml>';
					var xmlDoc = $.parseXML(xmlStr);
					let outNode = $(xmlDoc).find('xml');
					this.checkForReusables(outNode[0].outerHTML);
					if(this.hasResuables) {
						return outNode[0].textContent;
					} else {
						data = data.replace(/<p><br\/><\/p>/g, '<br>');
						data = data.replace(/(?!<ph|<para)\<p.*?>/g, "");
						data = data.replace(/<\/p>/g, "<br>");
						data = data.replace(/<br\/>/g, "<br>");
						data = data.replace(/<span.*?>/g, "");
						data = data.replace(/<\/span>/g, "");
						data = data.replace(/<font.*?>/g, "");
						data = data.replace(/<\/font>/g, "");
						return data;
					}
				}	
				else if (localStorage.getItem('disabledEditor') == null || localStorage.getItem('disabledEditor') == "false") {		
					data = data.replace(/(?!<ph|<para)\<p.*?>/g, "");
					data = data.replace(/<\/p>/g, "");
					data = data.replace(/<editarea.*?>*<\/editarea>/g, " ");
					data = data.replace(/<wintitle.*?>/g, "");
					data = data.replace(/<\/wintitle>/g, "");
					var xmlStr: string = '<xml>' + data + '</xml>'
					$(xmlStr).each(function() {
						var oldNode = this.outerHTML;
						var changedNode = false;			
						$.each(this.attributes, function() {
							if (this.value.includes("<")) {
								changedNode = true;
								this.value = this.value.replace(/</g, "&lt;");
							}
						});
						var newNode = this.outerHTML;
						if (changedNode) {
							self.escapeAttrMap.set(oldNode, newNode);
						}
						$.each(this.childNodes, function() {
							self.escapeAttrValues(this);
						});
					});
					this.escapeAttrMap.forEach((value: string, key: string) => {
						let newVlaue = value.replace(/&amp;lt;/g,'&lt;');
						key = key.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
						let regex = new RegExp(key, 'g');
						xmlStr = xmlStr.replace(regex, newVlaue);
					});
					var xmlDoc = $.parseXML(xmlStr);
					let outNode = $(xmlDoc).find('xml');
					this.processPartialStm(outNode[0]);
					this.partialStm = this.replaceInsIdOnPaste(this.partialStm);
					return this.partialStm;				
				}
			} else {
				if (localStorage.getItem('disabledEditor') != null && localStorage.getItem('disabledEditor') == "true") {
					var xmlStr: string = '<xml>' + data + '</xml>';
					var xmlDoc = $.parseXML(xmlStr);
					let outNode = $(xmlDoc).find('xml');
					this.checkForReusables(outNode[0].outerHTML);
					if(this.hasResuables) {
						return outNode[0].textContent;
					} else {
						data = data.replace(/<p><br\/><\/p>/g, '<br>');
						data = data.replace(/(?!<ph|<para)\<p.*?>/g, "");
						data = data.replace(/<\/p>/g, "<br>");
						data = data.replace(/<br\/>/g, "<br>");
						data = data.replace(/<span.*?>/g, "");
						data = data.replace(/<\/span>/g, "");
						data = data.replace(/<font.*?>/g, "");
						data = data.replace(/<\/font>/g, "");
						return data;
					}
				}	
				else if (localStorage.getItem('disabledEditor') == null || localStorage.getItem('disabledEditor') == "false") {		
					var xmlStr: string = '<xml>' + data + '</xml>'
					var parser = new DOMParser();
					var xmlDoc = parser.parseFromString(xmlStr, "text/xml");
					let copyArray = data.match(/<statement.*?>*<\/statement>/g);
					if (copyArray != null) {
						for(let i in copyArray) {
							let copyData = copyArray[i];
							let originalData = copyData;
							copyData = copyData.replace(/(?!<ph)\<p.*?>/g, '<para contenteditable="false" style="background-color:#d9d9d9;display:block;">').replace(/p>/g, "para>").replace(/<br\/>/g, "&nbsp;");	
							data = data.replace(originalData, copyData);
						}
					}
					var xrefEle = xmlDoc.getElementsByTagName("xref")[0];
					if (xrefEle !== undefined) {
						data = data.replace(/refinsid/gi, "ref_ins");
					}
					data = this.replaceInsIdOnPaste(data);
					if (xrefEle !== undefined) {		
						data = data.replace(/ref_ins/gi, "refinsid");		
					}
					data = data.replace(/<p><br\/><\/p>/g, '<br>');
					data = data.replace(/(?!<ph|<para)\<p.*?>/g, "");
					data = data.replace(/<\/p>/g, "<br>");
					return data;
				}
			}
		}
	}

	checkForReusables(data: string) {		 
		if (data.match(/<statement.*?>*<\/statement>/g)) {
			this.hasResuables = true;
		}
		else if(data.match(/<tags.*?>*<\/tags>/g)) {
			this.hasResuables = true;
		}
		else if(data.match(/<xref.*?>*<\/xref>/gi)) {
			this.hasResuables = true;
		}
		else if(data.match(/<fraction.*?>*<\/fraction>/g)) {
			this.hasResuables = true;
		}
		else {
			this.hasResuables = false;
		}
	}
}
