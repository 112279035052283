import { Component, OnInit, Input, ViewChild, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { FilesService } from '../../../files/files.service';
import { AgGridAngular } from '@ag-grid-community/angular';
import { TranslateService } from '@ngx-translate/core';
import { GridOptions, ColDef, Module, AllModules } from "@ag-grid-enterprise/all-modules";
import { RevisionCellRenderComponent } from './revision-cell-render-component.component';
import { CompareRevisionComponent } from './compare-revisions.component';
import { WsResponse } from '../../../../util/ws-response.model';
import { WsType } from '../../../../util/ws-type';
import { LoadingService } from '../../../../util/loading/loading.service';
import { SharedService } from "../../../../util/shared.service";
import { AlertService } from '../../../../util/alert/alert.service';
import { FilesDataService } from '../filesdata.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ViewmodelComponent } from "../../../../util/viewmodel/viewmodel.component";
			

@Component({
	selector: 'app-files-revisions',
	templateUrl: './revisions.component.html',
	styleUrls: ['./revisions.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class RevisionsComponent implements OnInit {

	public fileData: any;
	public columns: Array<any>;
	public selectedItemData: any;
	public showDetailPanel: boolean = false;
	@ViewChild('agGrid') agGrid: AgGridAngular;
	@Output('closeRevision') closeRevision: EventEmitter<any> = new EventEmitter();
	private gridApi;	
	private getRowNodeId;
	private columnDefs = [];
	private itemId: string;
	private revisions = [];
	private revisionData = [];
	private documentType: string;
	private gridColumnApi;
	rowData: any = [];
	private revisionNo: string;
	public revisionContent: string;
	private view: string;
	private show:string="Output View";
	private restoreStr: string;
	private checkedInBy: string;
	private checkedInDate: string;
	private type: string;
	okActionText: string;
	private comment: string;
	private noRevisionsMessage: string;
	needToReloadHomeEntry: boolean = false;
	rowSelection: string;
	public disable: boolean = false;
	public enableProperties: boolean = false
	public selectedNodes: number;
	public multipleSelections = false;
	public triggersType: string = 'hover'
	public enableRevision: boolean = false
	public isNewTab: boolean = false;
	selectedCount: number = 0;
	bsModalRef: BsModalRef;
	gridOptions :any;

	constructor(private filesService: FilesService, private modalService: BsModalService, private translate: TranslateService, private loadingService: LoadingService, private sharedService: SharedService,  private alertService: AlertService, private filesDataService: FilesDataService) {
		translate.addLangs(["es", "en"]);
		translate.setDefaultLang("en");
		let browserLang = translate.getBrowserLang();
		translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
		this.translate.get('REVISION.REVISION_NO').subscribe((res: string) => {
			this.revisionNo = res;
			this.translate.get('REVISION.RESTORE').subscribe((res: string) => {
				this.restoreStr = res;
				this.translate.get('REVISION.VIEW').subscribe((res: string) => {
					this.view = res;
					this.translate.get('REVISION.CHECKED_IN_BY').subscribe((res: string) => {
						this.checkedInBy = res;
						this.translate.get('REVISION.CHECKED_IN_DATE').subscribe((res: string) => {
							this.checkedInDate = res;
							this.translate.get('REVISION.COMMENT').subscribe((res: string) => {
								this.comment = res;
								this.translate.get('REVISION_CELL_RENDERER.OK').subscribe((res: string) => {
									this.okActionText = res;
								});
							});
						});
					});
				});
			});
		});
		this.fileData = this.filesService.getSelectedFileData();
		if(this.fileData.type == "Procedure" || this.fileData.type == "Topic" ){
			this.columnDefs = [
				{ headerName: 'Select', field: 'cbox', width: 50, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
				{ headerName: this.revisionNo, field: "revisionNo", width: 119 },
				{ headerName: this.restoreStr, field: "restore", width: 50, cellRendererFramework: RevisionCellRenderComponent },
				{ headerName: "XML View", field: "view", width: 100, cellRendererFramework: RevisionCellRenderComponent },
				{ headerName: this.show, field: "show", width: 100, cellRendererFramework: RevisionCellRenderComponent },
				{ headerName: this.checkedInBy, field: "checkedInBy" },
				{ headerName: this.checkedInDate, field: "checkedInDate" },
				{ headerName: this.comment, field: "comment" }
			];
		}
		else if (this.fileData.type == "ICECourse") {
			this.columnDefs = [
				{ headerName: 'Select', field: 'cbox', width: 50, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
				{ headerName: this.revisionNo, field: "revisionNo", width: 119 },
				{ headerName: this.restoreStr, field: "restore", width: 50, cellRendererFramework: RevisionCellRenderComponent },
				{ headerName: this.checkedInBy, field: "checkedInBy" },
				{ headerName: this.checkedInDate, field: "checkedInDate" },
				{ headerName: this.comment, field: "comment" }
			];
		}
		else{
			this.columnDefs = [
				{ headerName: 'Select', field: 'cbox', width: 50, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
				{ headerName: this.revisionNo, field: "revisionNo", width: 119 },
				{ headerName: this.restoreStr, field: "restore", width: 50, cellRendererFramework: RevisionCellRenderComponent },
				{ headerName: this.view, field: "view", width: 100, cellRendererFramework: RevisionCellRenderComponent },
				{ headerName: this.checkedInBy, field: "checkedInBy" },
				{ headerName: this.checkedInDate, field: "checkedInDate" },
				{ headerName: this.comment, field: "comment" }
			];
		}
		
		this.rowSelection = "multiple";
		this.noRevisionsMessage = undefined;
		this.fileData = this.filesService.getSelectedFileData();
		if (this.fileData != null) {
			this.loadingService.showLoading(true,false,"Revision Loading",100);
			if (this.fileData.type == "ICECourse") {
				//get ice course revision data
				this.filesService.getICECourseRevisionsData(this.fileData.id, this);
			} else {
				this.filesService.getRevisionDataByFileId(this.fileData, this);
			}
			this.getRowNodeId = function (data) {
				return data.id;
			};
		}

		this.gridOptions = <GridOptions>{
			context: {
				componentParent: this
			}
		};
	}

	

	onGridReady(params) {
		const allColumnIds = [];
		this.columnDefs.forEach((columnDef) => {
			allColumnIds.push(<ColDef>columnDef);
		});
		this.gridColumnApi = params.columnApi;
		this.gridColumnApi.autoSizeColumns(allColumnIds);
		this.gridApi = params.api;
		params.api.sizeColumnsToFit();
		this.filesService.setRevisionGridData(this.gridApi);
	}

	onRowSelected(event) {
		console.log("Type is :"+this.fileData.type)
		this.selectedItemData = undefined;
		let rowSelected: boolean = event.node.selected;
		this.disable = true;
		this.selectedNodes = this.agGrid.api.getSelectedNodes().length;
		console.log("onRowSelected | No. of nodes: " + this.selectedNodes);
		if(this.selectedNodes==2 &&(this.fileData.type=="Topic"||this.fileData.type=="Procedure"))
		{
			this.enableDisableButtons()
			for (let i = 0; i < this.selectedNodes; i++) {
				this.itemId = this.agGrid.api.getSelectedNodes()[i].data.itemId;
				this.revisions[i] = this.agGrid.api.getSelectedNodes()[i].data.revisionNo;
				this.documentType = this.agGrid.api.getSelectedNodes()[i].data.documentType;
			}
		}
		else
		{
			this.resetenableDisableButtons()
		}
	}
	public resetenableDisableButtons() {
		this.enableRevision = false
	}

	ngOnInit() {
		this.sharedService.setRevisionParent(this)
		this.triggersType = 'hover'
		
	}

	public emitClose(){
		this.closeRevision.emit();
	}

	loadData(data: any) {
		this.rowData = [];
		// console.log("Revision Data: " + JSON.stringify(data));
		let checkedOut: boolean = false;
		if(data.checkoutUserId != null){
			checkedOut = true;
		}
		else{
			// nothing to do
		}
		

		if (data.type == "ThreeDModel") {
			this.type = "3D Model"
		} else {
			this.type = data.type;
		}
		if (data != null) {
			let restore = data.revisionsList[0]["status"];
			if (checkedOut) {
				restore = "No";
			}
			if (data.revisionsList[0]["itemType"] == "Procedure" || data.revisionsList[0]["itemType"] == "Topic" ) {
				this.rowData.push({
					id:data.revisionsList[0]["itemId"],
					revisionNo:"Current Version",
					itemId: data.revisionsList[0]["itemId"],
					documentType: data.revisionsList[0]["itemType"], 
					view: "view:" + "Current version" + "," +data.revisionsList[0]["itemId"]+ "," + data.revisionsList[0]["itemType"]+","+"Topic0" ,
					show:"view:" + "Current version" + "," +data.revisionsList[0]["itemId"]+ "," + data.revisionsList[0]["itemType"]+","+"viewTopicContent",
					restore:restore == "No" ? "disableRestore:" + data.revisionsList[0]["itemId"] + "," + data.revisionsList[0]["itemType"] : 'restore:' + data.revisionsList[0]["itemId"] + "," + data.revisionsList[0]["itemType"],
					// checkedInBy:data.revisionsList[0]["userId"],
					// checkedInDate:new Date(data.revisionsList[0]["cmTimeStamp"]).toLocaleString(),
					// comment:data.revisionsList[0]["comment"]
					
				})
			}
			// if (this.fileData.type == 'Topic') {
			for (let i = 0; i < data.revisionsList.length; i++) {
				let restore = data.revisionsList[i]["status"];
				if(checkedOut){
					restore = "No";
				}
				else{
					// nothing to do
				}

				if (data.revisionsList[i]["itemType"] == "Procedure" || data.revisionsList[i]["itemType"] == "Topic") {
					this.rowData.push({
						id: data.revisionsList[i]["cmRevisionId"],					
						revisionNo: data.revisionsList[i]["cmRevisionNo"],
						itemId: data.revisionsList[i]["itemId"],
						documentType: data.revisionsList[i]["itemType"], 
						view: data.revisionsList[i]["itemType"] == 'Image' ?  
						
						 "view:" + data.revisionsList[i]["cmRevisionNo"] + "," + data.revisionsList[i]["itemId"] + "," + data.revisionsList[i]["itemType"] + "," + data.revisionsList[i]["itemName"] + "," + data.revisionsList[i]["versionFile"] 
	
						 :
						 data.revisionsList[i]["itemType"] == 'ContentItem' ? "view:" + data.revisionsList[i]["cmRevisionNo"] + "," + data.revisionsList[i]["itemId"] + "," + data.revisionsList[i]["itemType"] + "," + data.revisionsList[i]["itemName"] :
						 
						 "view:" + data.revisionsList[i]["cmRevisionNo"] + "," + data.revisionsList[i]["itemId"] + "," + data.revisionsList[i]["itemType"],
						 
	
						show: "view:" + data.revisionsList[i]["cmRevisionNo"] + "," + data.revisionsList[i]["itemId"] + "," + data.revisionsList[i]["itemType"]+","+"viewRevisionContent",
						restore: restore == "No" ? "disableRestore:" + data.revisionsList[i]["cmRevisionNo"] + "," + data.revisionsList[i]["itemId"] + "," + data.revisionsList[i]["itemType"] : 'restore:' + data.revisionsList[i]["cmRevisionNo"] + "," + data.revisionsList[i]["itemId"] + "," + data.revisionsList[i]["itemType"],
						checkedInBy: data.revisionsList[i]["userId"],
						checkedInDate: new Date(data.revisionsList[i]["cmTimeStamp"]).toLocaleString(),
						comment: data.revisionsList[i]["comment"]
					})
				}else {
					this.rowData.push({
						id: data.revisionsList[i]["cmRevisionId"],
						itemId: data.revisionsList[i]["itemId"],					
						revisionNo: data.revisionsList[i]["cmRevisionNo"],
						documentType: data.revisionsList[i]["itemType"], 
						view: data.revisionsList[i]["itemType"] == 'Image' ?  
						
						 "view:" + data.revisionsList[i]["cmRevisionNo"] + "," + data.revisionsList[i]["itemId"] + "," + data.revisionsList[i]["itemType"] + "," + data.revisionsList[i]["itemName"] + "," + data.revisionsList[i]["versionFile"] 
	
						 :
						 data.revisionsList[i]["itemType"] == 'ContentItem' ? "view:" + data.revisionsList[i]["cmRevisionNo"] + "," + data.revisionsList[i]["itemId"] + "," + data.revisionsList[i]["itemType"] + "," + data.revisionsList[i]["itemName"] :
						 
						 "view:" + data.revisionsList[i]["cmRevisionNo"] + "," + data.revisionsList[i]["itemId"] + "," + data.revisionsList[i]["itemType"],
	
	
						restore: restore == "No" ? "disableRestore:" + data.revisionsList[i]["cmRevisionNo"] + "," + data.revisionsList[i]["itemId"] + "," + data.revisionsList[i]["itemType"] : 'restore:' + data.revisionsList[i]["cmRevisionNo"] + "," + data.revisionsList[i]["itemId"] + "," + data.revisionsList[i]["itemType"],
						checkedInBy: data.revisionsList[i]["userId"],
						checkedInDate: new Date(data.revisionsList[i]["cmTimeStamp"]).toLocaleString(),
						comment: data.revisionsList[i]["comment"]
					})
				}
				
				//}
			}
		}
		if(this.rowData.length == 0){
			this.noRevisionsMessage = "No Revisions";
		}
		this.loadingService.hideLoading();
		this.sharedService.folderRefreshRequest(data.folderId);
	}

	loadICECourseData(data: any) {
		this.rowData = [];
		this.type = "ICE Course";
		if (data != null) {
			for (let i = 0; i < data.length; i++) {
				this.rowData.push({
					revisionNo: data[i]["courseVersion"],
					view:'view:"0,0,0"',
					restore: i == 0 ? 'disableRestore:'+ data[i]["courseVersion"] + "," + data[i]["id"]:'restore:' + data[i]["courseVersion"] + "," + data[i]["id"] + ",ICECourse",
					checkedInBy: data[i]["checkinUserName"],
					checkedInDate: new Date(data[i]["checkinTimeStamp"]).toLocaleString(),
					comment: data[i]["comment"]
				})
			}
		}
		if(this.rowData.length == 0){
			this.noRevisionsMessage = "No Revisions";
		}
		this.loadingService.hideLoading();
	}

	/**
       * Success function for filesdata service.
       * @param data 
       * @param serviceType 
       */
	onSuccess(data: WsResponse, serviceType: WsType): void {
		if (data != null && serviceType == WsType.REVISION_DATA_VIEW) {
			if(this.needToReloadHomeEntry){
				console.log("==========modify home")
				this.filesService.setCallback(this.sharedService.getParent());
				if(data.payload.checkoutUserId != null){
					this.filesDataService.getBasicFileData(this.fileData.id, this.fileData.type )
				}else{
					this.filesDataService.getBasicFileDataForCheckin(this.fileData.id, this.fileData.type)
				}
				this.needToReloadHomeEntry = false
			}
			this.loadData(data.payload);
		} else if (data != null && serviceType == WsType.GET_ICE_COURSE_REVISIONS_DATA) {
			this.loadICECourseData(data.payload);
		}else if( serviceType == WsType.RESTORE_REVISION ) {
			//  this.sharedService.setRevisionParent(null)
			this.needToReloadHomeEntry = true
			this.filesService.getRevisionDataByFileId(this.fileData, this);
			this.alertService.clear();
			this.alertService.success(this.translate.instant('REVISION.RESTORE_REVISION_SUCCESS'));
		}
		else if (serviceType === WsType.REVISION_COMPARE_VIEW) {
			if (this.isNewTab == false) {
				this.openModalWithComponent(data.payload, "Compare Versions", this.okActionText, null, null, null, null);
			} else {
				if (data.payload != null) {
					this.sharedService.setRevisionContent(data.payload);
				}
				this.sharedService.setRevisionContent(data.payload);
				this.revisionData[0] = this.revisions[0];
				this.revisionData[1] = this.revisions[1];
				this.revisionData[2] = this.documentType;
				this.revisionData[3] = this.fileData.name;
				this.sharedService.setRevisionData(this.revisionData);
				this.sharedService.modalCloseEventEmit();				
				this.sharedService.openTabRequestWithId("compare_revision", "Compare Revision", "CompareRevision", data.payload, "Compare Revision");
				// this.bsModalRef.hide()
			}
		}
	}
	openModalWithComponent(data: any, title: any, closeBtn: any, actionBtn: any, itemId: any, cmRevisionNo: any, itemType: any) {
		const initialState = {
			data: data,
			title: title,
			itemId: itemId,
			cmRevisionNo: cmRevisionNo,
			itemType: itemType
		};
		this.bsModalRef = this.modalService.show(ViewmodelComponent, { initialState , class: 'modal-xl' });
		this.bsModalRef.content.closeBtnName = closeBtn;
		this.bsModalRef.content.actionBtn = actionBtn;
	}
	/**
	 * On fail function for filesdata service.
	 * @param data 
	 * @param serviceType 
	 */
	onFail(data: WsResponse, serviceType: WsType): void {
		this.loadingService.hideLoading();
		console.log("Recieved a failed service type "+ serviceType)
		if( serviceType == WsType.RESTORE_REVISION ) {
			if (data.statusCode == "0") {
				this.alertService.clear();
				this.alertService.error("Could not restore revision. please try again", true);
				this.loadingService.hideLoading();
			} 
			else{
				if(data.statusName == "TimeoutError"){
					this.alertService.clear();
					this.alertService.error("Could not restore revision. please try again", true);
					this.loadingService.hideLoading();
				}
				else{
					this.alertService.clear()
					// this.alertService.error(this.translate.instant('REVISION.RESTORE_REVISION_FAILED'));
					this.alertService.error(data.statusDescription);
					this.loadingService.hideLoading();
				}
			}
		}else{
			this.alertService.clear()
			this.alertService.error(data.statusDescription);
		}
	}


	private viewFile() {
		//console.log("ViewFile")
	}

	private restore() {
		//console.log("ViewFile")
	}


	private enableDisableButtons() {
			this.enableRevision=true
	}

	public compareRevisionBtnEvent() {
		let revision1 = "";
		revision1 = this.revisions[0];
		let revision2 = "";
		revision2 = this.revisions[1];
		this.filesService.compareRevisionContent(this, revision1, revision2, this.documentType, this.itemId)
	}


}


