<div *ngIf="displayModel">
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{headerTitle}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container">

            <!--ICE Course-->
            <div *ngIf="iceCourseDependency != undefined && iceCourseDependency.ICECourse != undefined">
                    <div>
                        <h5 class="text-center">ICE Courses</h5>
                        <div>
                            {{iceCourseDependency.projectName}} ({{iceCourseDependency.ICECourse.length != undefined? iceCourseDependency.ICECourse.length: 1}})
                        </div>
                        <br />
                        <div *ngIf="iceCourseDependency.ICECourse.length != undefined">
                            <div *ngFor="let dependency of iceCourseDependency.ICECourse">
                                    <li *ngIf="dependency.checkedOutUser != ''">                                        
                                        {{dependency.iceCourseName}} ({{dependency.referenceCount}}) [{{dependency.iceCoursePath}}] - {{dependency.checkedOutUser}}
                                    </li>
                                    <li *ngIf="dependency.checkedOutUser == ''">                                        
                                        {{dependency.iceCourseName}} ({{dependency.referenceCount}}) [{{dependency.iceCoursePath}}] 
                                    </li>
                            </div>
                        </div>
                        <div *ngIf="iceCourseDependency.ICECourse.length == undefined">     
                                <li *ngIf="iceCourseDependency.ICECourse.checkedOutUser != ''">                                                   
                                    {{iceCourseDependency.ICECourse.iceCourseName}} ({{iceCourseDependency.ICECourse.referenceCount}}) [{{iceCourseDependency.ICECourse.iceCoursePath}}] - {{iceCourseDependency.ICECourse.checkedOutUser}}                                             
                                </li>
                                <li *ngIf="iceCourseDependency.ICECourse.checkedOutUser == ''">                                                   
                                    {{iceCourseDependency.ICECourse.iceCourseName}} ({{iceCourseDependency.ICECourse.referenceCount}}) [{{iceCourseDependency.ICECourse.iceCoursePath}}]                                             
                                </li>
                         </div>
                    </div>
                </div>

            <!--ICE Topic-->
            <div *ngIf="iceTopicDependency != undefined && iceTopicDependency.ICETopic != undefined">
                <div>
                    <h5 class="text-center">ICE Topic Menus</h5>
                    <div>
                        {{iceTopicDependency.projectName}} ({{iceTopicDependency.ICETopic.length != undefined? iceTopicDependency.ICETopic.length: 1}})
                    </div>
                    <br />
                        <div *ngIf="iceTopicDependency.ICETopic.length != undefined">
                            <div *ngFor="let dependency of iceTopicDependency.ICETopic">
                                <li *ngIf="dependency.checkedOutUser != ''" >
                                    {{dependency.iceTopicName}} ({{dependency.referenceCount}}) [{{dependency.iceTopicPath}}] - {{dependency.checkedOutUser}}
                                </li>
                                <li *ngIf="dependency.checkedOutUser == ''" >
                                    {{dependency.iceTopicName}} ({{dependency.referenceCount}}) [{{dependency.iceTopicPath}}]}
                                </li>
                            </div>
                        </div>
                        <div *ngIf="iceTopicDependency.ICETopic.length == undefined">     
                                <li *ngIf="iceTopicDependency.ICETopic.checkedOutUser != ''">                    
                                    {{iceTopicDependency.ICETopic.iceTopicName}} ({{iceTopicDependency.ICETopic.referenceCount}}) [{{iceTopicDependency.ICETopic.iceTopicPath}}] - {{iceTopicDependency.ICETopic.checkedOutUser}}                                             
                                </li>
                                <li *ngIf="iceTopicDependency.ICETopic.checkedOutUser == ''"> 
                                    {{iceTopicDependency.ICETopic.iceTopicName}} ({{iceTopicDependency.ICETopic.referenceCount}}) [{{iceTopicDependency.ICETopic.iceTopicPath}}]                                              
                                </li>
                         </div>
                </div>
            </div>

            <!--ICE Topic Content-->
            <div *ngIf="iceTopicContentDependency != undefined && iceTopicContentDependency.ICETopicContent != undefined ">
                <div>
                    <h5 class="text-center">Bullets</h5>
                    <div>
                        {{iceTopicContentDependency.projectName}} ({{iceTopicContentDependency.ICETopicContent.length != undefined? iceTopicContentDependency.ICETopicContent.length: 1}})
                    </div>
                    <br/>
                    <div *ngIf="iceTopicContentDependency.ICETopicContent.length != undefined">
                        <div *ngFor="let dependency of iceTopicContentDependency.ICETopicContent">
                            <li  *ngIf="dependency.checkedOutUser != ''">
                                {{dependency.descriptionHTML}} ({{dependency.referenceCount}}) [{{dependency.iceTopicContentPath}}] - {{dependency.checkedOutUser}}
                            </li>
                            <li  *ngIf="dependency.checkedOutUser == ''">
                                {{dependency.descriptionHTML}} ({{dependency.referenceCount}}) [{{dependency.iceTopicContentPath}}] 
                            </li>
                        </div>
                    </div>
                    <div *ngIf="iceTopicContentDependency.ICETopicContent.length == undefined">     
                            <li *ngIf="iceTopicContentDependency.ICETopicContent.checkedOutUser != ''">                    
                               {{iceTopicContentDependency.ICETopicContent.descriptionHTML}} ({{iceTopicContentDependency.ICETopicContent.referenceCount}}) [{{iceTopicContentDependency.ICETopicContent.iceTopicContentPath}}] - {{iceTopicContentDependency.ICETopicContent.checkedOutUser}}                                             
                            </li>
                            <li *ngIf="iceTopicContentDependency.ICETopicContent.checkedOutUser == ''">                    
                                {{iceTopicContentDependency.ICETopicContent.descriptionHTML}} ({{iceTopicContentDependency.ICETopicContent.referenceCount}}) [{{iceTopicContentDependency.ICETopicContent.iceTopicContentPath}}]                                              
                            </li>
                     </div>
                </div>
            </div>

            <!--Topics-->
            <div *ngIf="topicDependency != undefined && topicDependency.Topic != undefined">
                <div>
                    <h5 class="text-center">Topics</h5>
                    <div>
                        {{topicDependency.projectName}} ({{topicDependency.dependencyCount}})
                    </div>
                    <br />
                    <div *ngIf="topicDependency.Topic.length != undefined">
                        <div *ngFor="let dependency of topicDependency.Topic">
                            <li *ngIf="dependency.checkedOutUser != ''">
                                {{dependency.topicName}} ({{dependency.referenceCount}}) [{{dependency.topicPath}}] - {{dependency.checkedOutUser}}
                            </li>
                            <li *ngIf="dependency.checkedOutUser == ''">
                                {{dependency.topicName}} ({{dependency.referenceCount}}) [{{dependency.topicPath}}] 
                            </li>
                        </div>
                    </div>
                    <div *ngIf="topicDependency.Topic.length == undefined">     
                            <li *ngIf="topicDependency.Topic.checkedOutUser!= ''">                    
                                {{topicDependency.Topic.procName}} ({{topicDependency.Topic.referenceCount}}) [{{topicDependency.Topic.procPath}}] - {{topicDependency.Topic.checkedOutUser}}
                            </li>
                            <li *ngIf="topicDependency.Topic.checkedOutUser == ''">                    
                                {{topicDependency.Topic.procName}} ({{topicDependency.Topic.referenceCount}}) [{{topicDependency.Topic.procPath}}] 
                            </li>
                     </div>
                </div>
            </div>

            <!--Procedures-->
            <div *ngIf="procedureDependency != undefined && procedureDependency.Procedure != undefined ">
                <div>
                    <h5 class="text-center">Procedures</h5>
                    <div>
                        {{procedureDependency.projectName}} ({{procedureDependency.dependencyCount}})
                    </div>
                    <br />
                    <div *ngIf="procedureDependency.Procedure.length != undefined">
                        <div *ngFor="let dependency of procedureDependency.Procedure">
                            <li *ngIf="dependency.checkedOutUser != ''">
                                {{dependency.procName}} ({{dependency.referenceCount}}) [{{dependency.procPath}}] - {{dependency.checkedOutUser}}
                            </li>
                            <li *ngIf="dependency.checkedOutUser == ''">
                                {{dependency.procName}} ({{dependency.referenceCount}}) [{{dependency.procPath}}] 
                            </li>
                        </div>
                    </div>
                    <div *ngIf="procedureDependency.Procedure.length == undefined">     
                            <li *ngIf="procedureDependency.Procedure.checkedOutUser != ''">                    
                                {{procedureDependency.Procedure.procName}} ({{procedureDependency.Procedure.referenceCount}}) [{{procedureDependency.Procedure.procPath}}] - {{procedureDependency.Procedure.checkedOutUser}}
                            </li>
                            <li *ngIf="procedureDependency.Procedure.checkedOutUser == ''">                    
                                {{procedureDependency.Procedure.procName}} ({{procedureDependency.Procedure.referenceCount}}) [{{procedureDependency.Procedure.procPath}}] 
                            </li>
                     </div>
                </div>
            </div>

            <!--Tags-->
            <div *ngIf="tagDependency != undefined && tagDependency.Tag != undefined ">
                <div>
                    <h5 class="text-center">Tags</h5>
                    <div>
                        {{tagDependency.projectName}} ({{tagDependency.dependencyCount}})
                    </div>
                    <br />
                    <div *ngIf="tagDependency.Tag.length != undefined">
                        <div *ngFor="let dependency of tagDependency.Tag">
                            <li>
                                {{dependency.tagNo}} - {{dependency.description}}
                            </li>
                        </div>
                    </div>
                    <div *ngIf="tagDependency.Tag.length == undefined">
                        <li>
                            {{tagDependency.Tag.tagNo}} - {{tagDependency.Tag.description}}
                        </li>
                    </div>
                </div>
            </div>

            <!--CheckSheets-->
            <div *ngIf="checkSheetDependency != undefined">
                <div>
                    <h5 class="text-center">Checksheets</h5>
                    <div>
                        {{checkSheetDependency.projectName}} ({{checkSheetDependency.dependencyCount}})
                    </div>
                    <br />
                    <div *ngIf="checkSheetDependency.CheckSheet.length != undefined">
                        <div *ngFor="let dependency of checkSheetDependency.CheckSheet">
                            <li>
                                {{dependency.checkSheetName}}
                            </li>
                        </div>
                    </div>
                    <div *ngIf="checkSheetDependency.CheckSheet.length == undefined">
                        <li>
                            {{checkSheetDependency.CheckSheet.checkSheetName}}
                        </li>
                    </div>
                </div>
            </div>

            <!--IRN-->
            <div *ngIf="irnDependency != undefined">
                <div>
                    <h5 class="text-center">IRN</h5>
                    <div>
                        {{irnDependency.projectName}} ({{irnDependency.dependencyCount}})
                    </div>
                    <br />
                    <div *ngIf="irnDependency.IRN.length != undefined">
                        <div *ngFor="let dependency of irnDependency.IRN">
                            <li>
                                {{dependency.dependencyName}}
                            </li>
                        </div>
                    </div>
                    <div *ngIf="irnDependency.IRN.length == undefined">
                        <li>
                            {{irnDependency.IRN.dependencyName}}
                        </li>
                    </div>
<!--                    <div *ngFor="let dependency of irnDependency.IRN">-->
<!--                        <li>-->
<!--                            {{dependency.dependencyName}}-->
<!--                        </li>-->
<!--                    </div>-->
                </div>
            </div>

            <!--Asset Parents-->
            <div *ngIf="assetParentDependency != undefined">
                <div>
                    <h5 class="text-center">Asset Parents</h5>
                    <div>
                        {{assetParentDependency.projectName}} ({{assetParentDependency.dependencyCount}})
                    </div>
                    <br />
                    <!-- <div *ngFor="let dependency of assetParentDependency.Topic">
                        <li>
                            {{dependency.topicName}} ({{dependency.referenceCount}}) [{{dependency.topicPath}}]
                                - {{dependency.checkedOutUser}}
                        </li>
                    </div> -->
                    <div *ngIf="assetParentDependency.AssetParent.length != undefined">
                        <div *ngFor="let dependency of assetParentDependency.AssetParent">
                            <li>
                                {{dependency.dependencyName}}
                            </li>
                        </div>
                    </div>
                    <div *ngIf="assetParentDependency.AssetParent.length == undefined">
                        <li>
                            {{assetParentDependency.AssetParent.dependencyName}}
                        </li>
                    </div>
                </div>
            </div>

            <!--Asset Attributes-->
            <div *ngIf="assetAttrDependency != undefined">
                <div>
                    <h5 class="text-center">Asset Attributes</h5>
                    <div>
                        {{assetAttrDependency.projectName}} ({{assetAttrDependency.dependencyCount}})
                    </div>
                    <br />
                    <div *ngIf="assetAttrDependency.AssetAttr.length != undefined">
                        <div *ngFor="let dependency of assetAttrDependency.AssetAttr">
                            <li>
                                {{dependency.dependencyName}}
                            </li>
                        </div>
                    </div>
                    <div *ngIf="assetAttrDependency.AssetAttr.length == undefined">
                        <li>
                            {{assetAttrDependency.AssetAttr.dependencyName}}
                        </li>
                    </div>
                </div>
            </div>

            <!--Punch list-->
            <div *ngIf="punchListDependency != undefined">
                <div>
                    <h5 class="text-center">Punchlists</h5>
                    <div>
                        {{punchListDependency.projectName}} ({{punchListDependency.dependencyCount}})
                    </div>
                    <br />
                    <div *ngIf="punchListDependency.Punchlist.length != undefined">
                        <div *ngFor="let dependency of punchListDependency.Punchlist">
                            <li>
                                {{dependency.punchlistDescrip}}
                            </li>
                        </div>
                    </div>
                    <div *ngIf="punchListDependency.Punchlist.length == undefined">
                        <li>
                            {{punchListDependency.Punchlist.punchlistDescrip}}
                        </li>
                    </div>
                </div>
            </div>

            <!--Comm Work-->
            <div *ngIf="commWorkItemDependency != undefined">
                <div>
                    <h5 class="text-center">Work Items</h5>
                    <div>
                        {{commWorkItemDependency.projectName}} ({{commWorkItemDependency.dependencyCount}})
                    </div>
                    <br />
                    <div *ngIf="commWorkItemDependency.WorkItem.length != undefined">
                        <div *ngFor="let dependency of commWorkItemDependency.WorkItem">
                            <li>
                                {{dependency.dependencyName}}
                            </li>
                        </div>
                    </div>
                    <div *ngIf="commWorkItemDependency.WorkItem.length == undefined">
                            <li>
                                {{commWorkItemDependency.WorkItem.dependencyName}}
                            </li>
                    </div>
                </div>
            </div>
              <!--OTN-->
              <div *ngIf="otnDependency != undefined">
                <div>
                    <h5 class="text-center">OTN</h5>
                    <div>
                        {{otnDependency.projectName}} ({{otnDependency.dependencyCount}})
                    </div>
                    <br />
                    <div *ngIf="otnDependency.OTN.length != undefined">
                        <div *ngFor="let dependency of otnDependency.OTN">
                            <li>
                                {{dependency.dependencyName}}
                            </li>
                        </div>
                    </div>
                    <div *ngIf="otnDependency.OTN.length == undefined">
                        <li>
                            {{otnDependency.OTN.dependencyName}}
                        </li>
                    </div>
                </div>
            </div>
        <div>
			<div *ngIf="delFlag && ( topicDependency != undefined || procedureDependency != undefined || tagDependency != undefined ||checkSheetDependency != undefined || irnDependency != undefined ||
			assetParentDependency != undefined || assetAttrDependency != undefined || punchListDependency != undefined || commWorkItemDependency != undefined || iceCourseDependency != undefined
            || iceTopicDependency != undefined || iceTopicContentDependency != undefined || otnDependency != undefined)" class="pl-4">
				<label><input type="checkbox" [(ngModel)]="checkboxVal" (change)="onCheckboxClick($event)">{{
                    'DISABLE' | translate}}</label>
            </div>

            <div *ngIf="noDependecies" >
                <h5 class="text-center">
                    <label>{{ 'TAGS_SEARCH_VIEW.NO_ANY_DEPENDENCIES' | translate }}</label>
                </h5>
            </div>
        </div>

        <div class="modal-footer">
            <button *ngIf="cancelFlag" type="button" class="btn btn-secondary btn-sm" (click)="bsModalRef.hide()">{{ 'CANCEL' |
                translate}}</button>
            <button type="button" class="btn btn-warning" (click)="bsModalRef.hide();confirm(headerTitle,checkboxVal,delFlag);">{{
                'OK' | translate}}</button>
        </div>
    </div>
