import { Component, OnInit } from '@angular/core';
import { PdfToProcedurestroeService } from './pdf-to-procedurestroe.service';
import { AlertService } from 'src/app/util/alert/alert.service';
import { FloatingButtonCallback } from '../../floating-button-callback.interface';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PdfToProcedurestoreModel } from './pdf-to-procedurestore.model';

@Component({
	selector: 'app-pdf-to-procedurestroe',
	templateUrl: './pdf-to-procedurestore.component.html',
	styleUrls: ['./pdf-to-procedurestore.component.css']
})
export class PdfToProcedurestoreComponent implements OnInit {

	public floatingButtonCallback: FloatingButtonCallback;
	public selectedFile: any;
	private treeBsModalRef: BsModalRef;
	DocumentNo: string;
	DocumentTitle: string;
	DocumentSubTitle: string;
	DocumentProject: string;
	DocumentOutputFileName: string;
	DocumentVersionNo: string;
	DocumentNotes: string;
	DocumentKeywords: string;
	facilityList: Array<any> = []
	selectedFacility: string;
	facilityDisable: boolean = true;
	selectedSystem: string;
	systemList: Array<any> = [];
	systemDisable: boolean = true;
	DocumentInReview: boolean;
	DocumentParVal: boolean;
	DocumentDraft: boolean;
	facilityId: string;
	systemId: string;

	constructor(private pdfToProcedurestroeService: PdfToProcedurestroeService, private alertService: AlertService, public bsModalRef: BsModalRef) { }

	ngOnInit() {
		this.loadData();
	}

	public loadData() {
		console.log(this.selectedFile)
		this.DocumentOutputFileName = this.selectedFile.name + '.zip';
		this.pdfToProcedurestroeService.getFacilityList().subscribe(
			data => {
				this.facilityList = [];
				if (data.payload != null) {
					data.payload.forEach(element => {
						if (element.status != "Inactive") {
							this.facilityList.push({ 'id': element.facilityId, 'text': element.facilityName });
						}
						else {
							// do nothing
						}
					});
					this.facilityDisable = false;
				} else {
					// do nothing
				}
			},
			error => {
				this.alertService.error(error.statusDescription)
			});

		this.pdfToProcedurestroeService.getSystemsByProjectId().subscribe(
			data => {
				this.systemList = [];
				if (data.payload != null) {
					data.payload.forEach(system => {
						this.systemList.push({ 'id': system.id, 'text': system.systemName });
					});
					this.systemDisable = false;
				} else {
					// do nothing
				}
			},
			error => {
				this.alertService.error(error.statusDescription)
			});
	}



	public addToProcedureStore() {
		console.log("addToProcedureStore")
		var pdfToProcedurestoreModel: PdfToProcedurestoreModel = new PdfToProcedurestoreModel(this.DocumentNo, this.DocumentTitle, this.DocumentSubTitle, this.DocumentProject, this.DocumentOutputFileName, this.DocumentVersionNo, this.DocumentNotes, this.DocumentKeywords, this.facilityId, this.systemId, this.DocumentInReview, this.DocumentParVal, this.DocumentDraft);
		console.log("model= " + JSON.stringify(pdfToProcedurestoreModel))
		this.pdfToProcedurestroeService.addPdfToProcedureStore(pdfToProcedurestoreModel).subscribe(
			data => {
				this.alertService.success(data.statusDescription)
			},
			error => {
				this.alertService.error(error.statusDescription)
			})
		// this.floatingButtonCallback.hide();
	}


	public removedFacility(value: any): void {
		this.facilityId = null;
		console.log("removeSelectedFacility");
	}

	public selectedFacilityId(value: any): void {
		this.facilityId = value.id;
		console.log("Selected Facility Value: " + JSON.stringify(value));
	}

	public selectedSystemId(value: any): void {
		this.systemId = value.id;
		console.log("select system:" + JSON.stringify(value));
	}

}
