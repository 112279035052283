<div class="mx-0 my-2">
	<div class="mx-auto" [ngClass]="{'d-flex flex-row': isOpen, 'col p-0': !isOpen}" [style.height.px]="divHeight" style="overflow-y: auto;overflow-x: hidden;">
		<div class="p-0" [ngClass]="(isOpen && uiLayout != 'UIL_6')?'':''">
			<ul class="list-group ml-1">
				<span *ngIf="isOpen" id="hide_reusable" type="button" class="reusable-btn-outline-dark reusable-btn-sm my-2 border-0" (click)="hideReusable()" >
					<i class="fas fa-arrow-right reusable-sm-icon" aria-hidden="true"></i>
				</span>
				<span type="button" id="reusable_tags" class="reusable-btn-sm my-3" tooltip="Tags" placement="{{tooltipPlacement}}" [adaptivePosition]="false" container="body" (click)="reusableBtnEventWithDoubleClick('tags')"
				 [ngClass]="{'reusable-btn-light': selectedItem != 'tags', 'reusable-btn-dark': selectedItem == 'tags'}" >
					<i class="fal fa-tags reusable-sm-icon" aria-hidden="true"></i>
				</span>
				<span type="button" id="reusable_statements" class="reusable-btn-sm my-3" tooltip="Statements" placement="{{tooltipPlacement}}" [adaptivePosition]="false" container="body" (click)="reusableBtnEventWithDoubleClick('statements')"
				 [ngClass]="{'reusable-btn-light': selectedItem != 'statements', 'reusable-btn-dark': selectedItem == 'statements'}" >
					<i class="fal fa-edit reusable-sm-icon" aria-hidden="true"></i>
				</span>
				<span type="button" id="reusable_components" class="reusable-btn-sm my-3" tooltip="Components" placement="{{tooltipPlacement}}" [adaptivePosition]="false" container="body" (click)="reusableBtnEventWithDoubleClick('components')"
				 [ngClass]="{'reusable-btn-light': selectedItem != 'components', 'reusable-btn-dark': selectedItem == 'components'}" >
				 <i class="fal fa-chart-network reusable-sm-icon"  aria-hidden="true"></i>
				</span>
				<span type="button" id="reusable_systems" class="reusable-btn-sm my-3" tooltip="Systems" placement="{{tooltipPlacement}}" [adaptivePosition]="false" container="body" (click)="reusableBtnEventWithDoubleClick('systems')"
				 [ngClass]="{'reusable-btn-light': selectedItem != 'systems', 'reusable-btn-dark': selectedItem == 'systems'}" >
					<i class="fal fa-atom reusable-sm-icon" aria-hidden="true"></i>
				</span>
				<span type="button" id="reusable_documents" class="reusable-btn-sm my-3" tooltip="Documents" placement="{{tooltipPlacement}}" [adaptivePosition]="false" container="body" (click)="reusableBtnEventWithDoubleClick('documents')"
				 [ngClass]="{'reusable-btn-light': selectedItem != 'documents', 'reusable-btn-dark': selectedItem == 'documents'}">
					<i class="fal fa-book reusable-sm-icon" aria-hidden="true"></i>
				</span>
				<span type="button" id="reusable_facilities" class="reusable-btn-sm my-3" tooltip="Facilities" placement="{{tooltipPlacement}}" [adaptivePosition]="false" container="body" (click)="reusableBtnEventWithDoubleClick('facilities')"
				 [ngClass]="{'reusable-btn-light': selectedItem != 'facilities', 'reusable-btn-dark': selectedItem == 'facilities'}">
				 <i class="fal fa-industry-alt reusable-sm-icon" aria-hidden="true"></i>
				</span>
				<span type="button" id="reusable_images" class="reusable-btn-sm my-3" tooltip="Images" placement="{{tooltipPlacement}}" [adaptivePosition]="false" container="body" (click)="reusableBtnEventWithDoubleClick('images')"
				 [ngClass]="{'reusable-btn-light': selectedItem != 'images', 'reusable-btn-dark': selectedItem == 'images'}">
					<i class="fal fa-images reusable-sm-icon" aria-hidden="true"></i>
				</span>
				<span type="button" id="reusable_threed_model" class="reusable-btn-sm my-3" tooltip="3D-model" placement="{{tooltipPlacement}}" [adaptivePosition]="false" container="body" (click)="reusableBtnEventWithDoubleClick('threed-model')"
				[ngClass]="{'reusable-btn-light': selectedItem != 'threed-model', 'reusable-btn-dark': selectedItem == 'threed-model'}" > 
				   <i class="fal fa-cube reusable-sm-icon" aria-hidden="true"></i>
			   </span>
			   <span type="button" id="reusable_videos" class="reusable-btn-sm my-3" tooltip="Videos" placement="{{tooltipPlacement}}" [adaptivePosition]="false" container="body" (click)="reusableBtnEventWithDoubleClick('video')"
				[ngClass]="{'reusable-btn-light': selectedItem != 'video', 'reusable-btn-dark': selectedItem == 'video'}" > 
				   <i class="fal fa-desktop reusable-sm-icon" aria-hidden="true"></i>
			   </span>
				<span type="button" id="reusable_history" class="reusable-btn-sm my-3" tooltip="History" placement="{{tooltipPlacement}}" [adaptivePosition]="false" container="body" (click)="reusableBtnEventWithDoubleClick('history')"
				[ngClass]="{'reusable-btn-light': selectedItem != 'history', 'reusable-btn-dark': selectedItem == 'history'}" > 
				   <i class="fal fa-history reusable-sm-icon" aria-hidden="true"></i>
			   </span>
			   <!-- removed double click event : (dblclick)="reusableFS()" -->
			</ul>
		</div>
		<div class="col-sm-11 pl-2 pr-1 ml-1 mt-2 mx-auto" [ngClass]="{'content-show': isOpen, 'content-hide': !isOpen}">
			<div #componentHost></div>
		</div>
	</div>
</div>