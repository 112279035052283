import { Component, OnInit } from '@angular/core';
import { GridOptions, ColDef, Module, AllModules } from '@ag-grid-enterprise/all-modules';
import { Constants } from 'src/app/util/constants';
import { IssueChecksheetService } from './issuechecksheets.service';
import { LoadingService } from 'src/app/util/loading/loading.service';
import { AlertService } from 'src/app/util/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { ChecksheetDeleteCellRendererComponent } from './cell-renderer/delete-cell-renderer.component';
import { FilesDataService } from 'src/app/home/files/filesdata/filesdata.service';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { OutputGenerateModalComponent } from 'src/app/home/files/outputgenerator/outputgeneratemodal.component';
import { SharedService } from 'src/app/util/shared.service';
import { WsResponse } from 'src/app/util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';

@Component({
    selector: 'app-issuechecksheets',
    templateUrl: './issuechecksheets.component.html',
    styleUrls: ['./issuechecksheets.component.css']
})
export class IssueChecksheetsComponent implements OnInit{
    public rowData: any = [];
    public columnDefs: any;
    public gridOptions: GridOptions;
    public overlayLoadingTemplate: any;
    public overlayNoRowsTemplate: any;
    public getRowNodeId: any;
    public gridApi: any;
    private gridColumnApi: any;
    public generateOut: boolean = false;
    public modules: Module[] = AllModules;

    private searchVendor: string = null;
    private searchChecksheet: string = null;
    private searchAsset: string = null;
    private searchDescription: string = null;
    private searchSystem: string = null;
    private searchAssetGroup: string = null;
    private searchPhase: string = null;
    private searchAssetParent: string = null;
    private searchFunctionCode: string = null;
    private hasText: string = null;
    private bsModalRef: BsModalRef;
    selectedOutputType = []
    checkingChecksheetsList = []
    public sendMail: string = "Yes";
    public isSendMail: boolean = true;

    constructor(private translate: TranslateService, private issueChecksheetService: IssueChecksheetService, private loadingService: LoadingService, 
      private alertService: AlertService, private filesDataService: FilesDataService, private modalService: BsModalService, private sharedService: SharedService){
      translate.addLangs(["es", "en"]);
      translate.setDefaultLang("en");
      let browserLang = translate.getBrowserLang();
      translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
        this.columnDefs = [
            {
                headerName:'',
                field: 'id',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
              headerName:'',
              field: 'assetSystemId',
              hide: true,
              suppressColumnsToolPanel: true
            },
            {
              headerName:'',
              field: 'generateStatus',
              hide: true,
              suppressColumnsToolPanel: true
            },
            {
                headerName:this.translate.instant('ISSUE_CHECKS.VENDOR'),
                field: 'vendorName',
                minWidth:100
            },
            {
                headerName:this.translate.instant('ISSUE_CHECKS.CHECKSHEET'),
                field: 'checksheetCode',
                minWidth:100
            },
            {
                headerName:this.translate.instant('ISSUE_CHECKS.ASSET'),
                field: 'assetNo',
                minWidth:100
            },
            {
                headerName:this.translate.instant('ISSUE_CHECKS.DESCRIPTION'),
                field: 'assetDescription',
                minWidth:100
            },
            {
                headerName:this.translate.instant('ISSUE_CHECKS.SYSTEM'),
                field: 'systemName',
                minWidth:100
            },
            {
                headerName:this.translate.instant('ISSUE_CHECKS.ASSET_GROUP'),
                field: 'assetGroup',
                minWidth:100
            },
            {
                headerName:this.translate.instant('ISSUE_CHECKS.PHASE'),
                field: 'phaseName',
                minWidth:100
            },
            {
                headerName:this.translate.instant('ISSUE_CHECKS.ASSET_PARENT'),
                field: 'assetParent',
                minWidth:100
            },
            {
                headerName:this.translate.instant('ISSUE_CHECKS.CS_FUNCTION_CODE'),
                field: 'functionCode',
                minWidth:100
            },
            {
                headerName:this.translate.instant('ISSUE_CHECKS.DELETE'),
                field: 'delete',
                minWidth:100,
                cellRendererFramework: ChecksheetDeleteCellRendererComponent
            }
        ];
    
        this.getRowNodeId = function (data) {
            return data.id;
          };
          
          this.gridOptions = {
            rowData: this.rowData,
            columnDefs: this.columnDefs,
            defaultColDef: {
              resizable: true,
              sortable: true
            },
            accentedSort: true,
            rowSelection: "single",
            rowMultiSelectWithClick: false,
            animateRows: true,
            rowBuffer: 20,
            context: {
              componentParent: this
            },
            paginationPageSize: 10,
            getRowStyle: (params) => {
              if (params.data.generateStatus == "OnQueue") {
                return { background: '#FFDD77' };
              } else if (params.data.generateStatus == "Issued" || params.data.generateStatus == "DataSaved") {
                return { background: '#FCE2BE' };
              } else if (params.data.generateStatus == "Approved" || params.data.generateStatus == "NotIssued") {
                return { background: '#DEDEDE' };
              } else if (params.data.generateStatus == "Error") {
                return { background: '#FCCDC4' };
              } else {
                return { background: '#ffffff' };
              }
            }
          };
      
          this.overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Loading data...</span>';
          this.overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">No rows to show</span>';
    }

    ngOnInit(): void {
      this.issueChecksheetService.setIssueChecksheetComp(this);
      this.issueChecksheetService.updateUIafterChecksheetGenerateResponse().subscribe((option: any) => this.updateUIAfterGenerateOutput());
    }

    onGridReady(params: any){
      const allColumnIds = [];
      this.columnDefs.forEach((columnDef) => {
        allColumnIds.push(<ColDef>columnDef);
      });
      this.gridColumnApi = params.columnApi;
      this.gridApi = params.api;
      this.issueChecksheetService.setApiGridData(this.gridApi);
      params.api.sizeColumnsToFit();
      // window.addEventListener("resize", function () {
      //   setTimeout(function () {
      //     params.api.sizeColumnsToFit();
      //   });
      // });
      window.addEventListener("resize", this.onWindowResize);
    }
    private onWindowResize = () => {
   
      setTimeout(() => {
        if (this.gridApi) {
          this.gridApi.sizeColumnsToFit();
        }
      }); 
    };
    reloadData(){
      this.fireEvent("mainsearchinput", "click");
    }
  
    private fireEvent( ElementId, EventName ) {
      if( document.getElementById(ElementId) != null ) {
        const ke = new KeyboardEvent("keyup", {
          bubbles: true, cancelable: true, key:"Enter"
        });
        document.getElementById( ElementId ).dispatchEvent( ke );
      }
    }

    updateUIAfterGenerateOutput(){
      this.generateOut = false;
      this.isSendMail = true;
      this.sendMail = "Yes";
      if(this.rowData.length > 0 ){
        this.rowData.forEach((row) => {
          if(row.generateStatus == null ){ //the status of checksheet instance is null before generating the checksheet instance certificate
            row.generateStatus = "OnQueue";
            row.approve= "approve:OnQueue"
            row.delete= "delete:OnQueue"
          }
        })
      }
      this.gridApi.setRowData(this.rowData);
    }
    
    searchFunc(data: any){
      this.searchVendor = "";
      this.searchChecksheet = "";
      this.searchAsset = "";
      this.searchDescription = "";
      this.searchSystem = "";
      this.searchAssetGroup = "";
      this.searchPhase = "";
      this.searchAssetParent = "";
      this.searchFunctionCode = "";
      this.hasText = "";

      var vendorSearch = data.searchWordMap[Constants.SEARCH_KEY_WORDS.ISSUE_CS_VENDOR];
      if (vendorSearch != undefined) {
        this.searchVendor = vendorSearch
      } else {
        //do nothing
      }
      
      var checksheetSearch = data.searchWordMap[Constants.SEARCH_KEY_WORDS.ISSUE_CS_CHECKSHEET];
      if(checksheetSearch != undefined){
        this.searchChecksheet = checksheetSearch
      } else{
        //do nothing
      }

      var assetSearch = data.searchWordMap[Constants.SEARCH_KEY_WORDS.ISSUE_CS_ASSET];
      if(assetSearch != undefined){
        this.searchAsset = assetSearch
      } else{
        //do nothing
      }

      var descriptionSearch = data.searchWordMap[Constants.SEARCH_KEY_WORDS.ISSUE_CS_ASSET_DESCRIPTION];
      if(descriptionSearch != undefined){
        this.searchDescription = descriptionSearch
      } else{
        //do nothing
      }

      var systemSearch = data.searchWordMap[Constants.SEARCH_KEY_WORDS.ISSUE_CS_SYSTEM];
      if(systemSearch != undefined){
        this.searchSystem = systemSearch
      } else{
        //do nothing
      }

      var assetGroupSearch = data.searchWordMap[Constants.SEARCH_KEY_WORDS.ISSUE_CS_ASSET_GROUP];
      if(assetGroupSearch != undefined){
        this.searchAssetGroup = assetGroupSearch
      } else{
        //do nothing
      }

      var phaseSearch = data.searchWordMap[Constants.SEARCH_KEY_WORDS.ISSUE_CS_PHASE];
      if(phaseSearch != undefined){
        this.searchPhase = phaseSearch
      } else{
        //do nothing
      }

      var assetParentSearch = data.searchWordMap[Constants.SEARCH_KEY_WORDS.ISSUE_CS_ASSET_PARENT];
      if(assetParentSearch != undefined){
        this.searchAssetParent = assetParentSearch
      } else{
        //do nothing
      }

      var functionCodeSearch = data.searchWordMap[Constants.SEARCH_KEY_WORDS.ISSUE_CS_FUNCTION_CODE];
      if(functionCodeSearch != undefined){
        this.searchFunctionCode = functionCodeSearch
      } else{
        //do nothing
      }

      var hasText = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_HAS_THE_WORDS];
      if(hasText != undefined){
        this.hasText = hasText
      } else{
        //do nothing
      }

      if(this.searchVendor != "" || this.searchChecksheet != "" || this.searchAsset != "" || this.searchDescription != "" || 
      this.searchSystem != "" || this.searchAssetGroup != "" || this.searchPhase != "" || this.searchAssetParent != "" || 
      this.searchFunctionCode != "" || this.hasText != ""){
        this.loadingService.showLoading(true, null, "Searching", null);
        this.issueChecksheetService.getCheckSheetFilterResult(this.searchVendor, this.searchChecksheet, this.searchAsset, this.searchDescription,
          this.searchSystem, this.searchAssetGroup, this.searchPhase, this.searchAssetParent, this.searchFunctionCode, this.hasText).subscribe(data => {
            this.loadingService.hideLoading();
            this.loadData(data.payload);
        },
        error => {
          this.loadingService.hideLoading();
          this.alertService.clear()
          this.alertService.error(error.statusDescription)
        });
      } else{
        this.gridApi.hideOverlay()
      }
    } 

    loadData(data: any){
      var rowData = [];
      this.generateOut = false;
      let parser = new DOMParser();
      if(data != null){
        data.forEach(element => {
          let desXml = parser.parseFromString(element.assetDecription, "text/xml");
          rowData.push({
            id: element.checkSheetId,
            assetSystemId: element.checkSheetAssetSystemId,
            generateStatus: element.status,
            vendorName: element.vendorName,
            checksheetCode: element.checkSheetCode,
            assetNo: element.assetName,
            assetDescription: element.assetDecription != null ? desXml.getElementsByTagName("txt")[0].childNodes[0].nodeValue : "",
            systemName: element.systemName,
            assetGroup: element.assetGroupName,
            phaseName: element.phaseName,
            assetParent: element.assetParentName,
            functionCode: element.functionCode,
            delete: "delete" + ":" + element.status
          })
        });
    }
      this.rowData = rowData;
      this.gridApi.paginationProxy.currentPage = 0;
      this.gridOptions.api.sizeColumnsToFit();
      //show/hide the Generate button
      if(this.rowData.length > 0){
        this.rowData.forEach(element => {
          if(element.generateStatus == null){
            this.generateOut = true;
          }
        });
      }
    }

    checkboxChecked(data: any){
      var checkboxValue = data.currentTarget.checked;
        if (checkboxValue) {
            this.isSendMail = true;
            this.sendMail = "Yes";
        } else{
          this.isSendMail = false;
          this.sendMail = "No";
        }
    }

    generateOutputClick(){
      this.checkingChecksheetsList = [];
      this.rowData.forEach(element => {
        if(element.generateStatus == null){
          this.checkingChecksheetsList.push({assetSystemId: element.assetSystemId})
        }   
      });

      if(this.rowData.length > 0){
        this.generateOutput();
      } else{
        //do nothing
      }
    }

    /**
     * Generate the checksheet document
     */
    public generateOutput() {
      let ids = [];
      this.checkingChecksheetsList.forEach((item) => {
        ids.push(item.assetSystemId);
      })
      let type = "CheckSheet";
      this.loadingService.showLoading(true, null, "", null);
      this.filesDataService.getOutputTypeListByProjectIdDocType(type)
        .subscribe(data => {
          this.loadingService.hideLoading()
          this.selectedOutputType = []
          if (data.payload != null && data.payload.length > 0) {
            data.payload.forEach(element => {
              this.selectedOutputType.push({ 'id': element, 'text': element })
            });
            const initialState: object = {
              selectedOutputType: this.selectedOutputType,
              outputData: "",
              idList: ids,
              type: type,
              outputType: this.selectedOutputType[0].text,
              outputId: this.selectedOutputType[0].id,
              showTable: false,
              sendMail: this.sendMail
            }
            this.bsModalRef = this.modalService.show(OutputGenerateModalComponent, { class: 'modal-lg', initialState });
  
          } else {
            this.alertService.warn("Please add the Checksheet template into this project");
          }
        },
          error => {
            this.loadingService.hideLoading()
            this.alertService.clear()
            this.alertService.error(error.statusDescription)
          });
    }

    /**
     * Clear taginput items
     */
    removeSelectedIssueChecksheetTagInputs(){
      this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.ISSUE_CS_VENDOR, []);
      this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.ISSUE_CS_CHECKSHEET, []);
      this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.ISSUE_CS_ASSET, []);
      this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.ISSUE_CS_ASSET_DESCRIPTION, []);
      this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.ISSUE_CS_SYSTEM, []);
      this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.ISSUE_CS_ASSET_GROUP, []);
      this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.ISSUE_CS_PHASE, []);
      this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.ISSUE_CS_ASSET_PARENT, []);
      this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.ISSUE_CS_FUNCTION_CODE, []);
  }

  public ngOnDestroy() {
    this.removeSelectedIssueChecksheetTagInputs();
    window.removeEventListener("resize", this.onWindowResize);
    this.gridApi.destroy();
    this.gridApi = null;
  }

  onSuccess(data: WsResponse, serviceType: WsType){
    if(serviceType == WsType.REMOVE_CS_INSTANCE){
      if(data.payload){
        this.loadingService.hideLoading();
        this.alertService.success("Successfully deleted the checksheet instance");
        this.reloadData();
      }
    }
  }

  onFail(data: WsResponse, serviceType: WsType){
    if(serviceType == WsType.REMOVE_CS_INSTANCE){
      this.loadingService.hideLoading();
      this.alertService.error(data.statusDescription);
    }
  }
}