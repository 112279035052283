import { AllModules, ColDef, GridOptions, Module } from '@ag-grid-enterprise/all-modules';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AlertService } from 'src/app/util/alert/alert.service';
import { Constants } from 'src/app/util/constants';
import { DependenciesModalService } from 'src/app/util/dependenciesmodal/dependenciesmodal.service';
import { LoadingService } from 'src/app/util/loading/loading.service';
import { SharedService } from 'src/app/util/shared.service';
import { ChecksheetsCellRenderComponentComponent } from '../checksheets-cell-render-component/checksheets-cell-render-component.component';
import { AddeditchecksheetComponent } from './addeditchecksheet/addeditchecksheet.component';
import { ManagechecksheetsService } from './managechecksheets.service';

@Component({
  selector: 'app-managechecksheets',
  templateUrl: './managechecksheets.component.html',
  styleUrls: ['./managechecksheets.component.css']
})
export class ManagechecksheetsComponent implements OnInit {
  public searchData: any;
  private csName: string = "";
  private csCode: string = "";
  private csDiscipline: string = "";
  private csType: string = "";
  private hasText: string = "";

  public columnDefs: any;
  rowData: any = [];
  filteredData: any[];
  public gridApi: any;
  public getRowNodeId: any;
  private gridColumnApi: any;
  public overlayLoadingTemplate: any;
  public overlayNoRowsTemplate: any;
  public modules: Module[] = AllModules;
  public gridOptions: GridOptions;
  public bsModalRef: BsModalRef;

  constructor(
    private alertService: AlertService,
    private loadingService: LoadingService,
    private translate: TranslateService,
    private checksheetService: ManagechecksheetsService,
    private modalService: BsModalService,
  ) {
    translate.addLangs(["es", "en"]);
    translate.setDefaultLang("en");
    let browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');

    this.columnDefs = [
      {
        headerName: this.translate.instant('CS_SEARCH_VIEW.CS_DEPENDENCY'),
        field: "dependencies",
        minWidth: 75,
        cellStyle: { 'text-align': 'center' },
        cellRendererFramework: ChecksheetsCellRenderComponentComponent,
      },
      {
        headerName: this.translate.instant('CS_SEARCH_VIEW.CS_NAME'),
        field: 'name',
        autoHeight: true,
        cellClass: "cell-wrap-text",
        minWidth: 200,
      },
      {
        headerName: this.translate.instant('CS_SEARCH_VIEW.CS_CODE'),
        field: 'code',
        autoHeight: true,
        cellClass: "cell-wrap-text",
        minWidth: 75,
      },
      {
        headerName: this.translate.instant('CS_SEARCH_VIEW.CS_DISCIPLINE'),
        field: 'discipline',
        autoHeight: true,
        cellClass: "cell-wrap-text",
        minWidth: 120,
      },
      {
        headerName: this.translate.instant('CS_SEARCH_VIEW.CS_TYPE'),
        field: 'type',
        autoHeight: true,
        cellClass: "cell-wrap-text",
        minWidth: 75,
      },
      {
        headerName: this.translate.instant('CS_SEARCH_VIEW.CS_ASSIGN_CHKS'),
        field: "assignChks",
        minWidth: 120,
        cellStyle: { 'text-align': 'center' },
        cellRendererFramework: ChecksheetsCellRenderComponentComponent,
      },
      {
        headerName: this.translate.instant('CS_SEARCH_VIEW.CS_ASSIGN_ASSETS'),
        field: "assignAssets",
        minWidth: 120,
        cellStyle: { 'text-align': 'center' },
        cellRendererFramework: ChecksheetsCellRenderComponentComponent,
      },
      {
        headerName: this.translate.instant('CS_SEARCH_VIEW.CS_ASSIGN_SYSTEMS'),
        field: "assignSys",
        minWidth: 130,
        cellStyle: { 'text-align': 'center' },
        cellRendererFramework: ChecksheetsCellRenderComponentComponent,
      },
      {
        headerName: this.translate.instant('CS_SEARCH_VIEW.EDIT'),
        field: "edit",
        minWidth: 75,
        cellStyle: { 'text-align': 'center' },
        cellRendererFramework: ChecksheetsCellRenderComponentComponent,
      },
      {
        headerName: this.translate.instant('CS_SEARCH_VIEW.STATUS'),
        field: "status",
        minWidth: 75,
        cellStyle: { 'text-align': 'center' },
        cellRendererFramework: ChecksheetsCellRenderComponentComponent,
      },
      {
        headerName: this.translate.instant('CS_SEARCH_VIEW.DELETE'),
        field: "delete",
        minWidth: 85,
        cellStyle: { 'text-align': 'center' },
        cellRendererFramework: ChecksheetsCellRenderComponentComponent,
      }
    ];

    this.getRowNodeId = function (data) {
      return data.id;
    };

    this.gridOptions = {
      rowData: this.rowData,
      columnDefs: this.columnDefs,
      defaultColDef: {
        resizable: true,
        sortable: true
      },
      accentedSort: true,
      rowSelection: "single",
      rowMultiSelectWithClick: false,
      doesExternalFilterPass: this.externalFilterPass,
      animateRows: true,
      rowBuffer: 20,
      context: {
        componentParent: this
      },
      paginationPageSize: 10
    };

    //custom layout templates for loading and empty data sets
    this.overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Loading data...</span>';
    this.overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">No rows to show</span>';
  }

  externalFilterPass(node: any) {
    return true;
  }

  onGridReady(params) {
    const allColumnIds = [];
    this.columnDefs.forEach((columnDef) => {
      allColumnIds.push(<ColDef>columnDef);
    });
    this.gridColumnApi = params.columnApi;
    this.gridApi = params.api;
    this.checksheetService.setApiGridData(this.gridApi);
    params.api.sizeColumnsToFit();
    // window.addEventListener("resize", function () {
    //   setTimeout(function () {
    //     params.api.sizeColumnsToFit();
    //   });
    // });
    window.addEventListener("resize", this.onWindowResize);
  }
  private onWindowResize = () => {
   
		setTimeout(() => {
			if (this.gridApi) {
				this.gridApi.sizeColumnsToFit();
			}
		}); 
	};
  ngOnDestroy() {

		window.removeEventListener("resize", this.onWindowResize);
		this.gridApi.destroy();
		this.gridApi = null;
	  }
    
  ngOnInit(): void {
    this.checksheetService.loadTableDataResponse().subscribe((option: any) => this.reloadData());
  }

  newCSButtonClick() {
    this.openAddEditCSModal();
  }

  private openAddEditCSModal() {
    const initialState: object = {
      edit: false
    };
    this.bsModalRef = this.modalService.show(AddeditchecksheetComponent, { initialState, class: 'modal-md', backdrop: 'static', keyboard: false })
  }

  reloadData() {
    this.fireEvent("mainsearchinput", "click");
  }

  private fireEvent(ElementId, EventName) {
    if (document.getElementById(ElementId) != null) {
      const ke = new KeyboardEvent("keyup", {
        bubbles: true, cancelable: true, key: "Enter"
      });
      document.getElementById(ElementId).dispatchEvent(ke);
    }
  }

  searchFunc(data: any) {
    //console.log('[searchFunc] (data) ' + JSON.stringify(data));
    this.searchData = data;
    this.csName = "";
    this.csCode = "";
    this.csDiscipline = "";
    this.csType = "";
    this.hasText = "";
    this.gridApi.showLoadingOverlay();

    //assign value for the fields from searchWordMap
    var plname = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_CS_NAME];
    if (plname != undefined) {
      this.csName = plname;
    } else {
      //no name
    }
    var plcode = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_CS_CODE];
    if (plcode != undefined) {
      this.csCode = plcode;
    } else {
      //no code
    }

    var pldisc = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_CS_DISCIPLINE];
    if (pldisc != undefined) {
      this.csDiscipline = pldisc;
    } else {
      //no discipline
    }

    var pltype = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_CS_TYPE];
    if (pltype != undefined) {
      this.csType = pltype;
    } else {
      //no type
    }

    var hasText = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_HAS_THE_WORDS];
    if (hasText != undefined) {
      this.hasText = hasText;
    } else {
      //no has text
    }

    if (this.csName != "" || this.csCode != "" || this.csDiscipline != "" || this.csType != "" || this.hasText != "") {
      let to: any = false;
      if (to) { clearTimeout(to); }
      this.loadingService.showLoading(true, null, "Searching", null);
      to = setTimeout(() => {
        this.gridOptions.api.onFilterChanged();
        this.checksheetService.searchChecksheets(this.csName, this.csCode, this.csDiscipline, this.csType, this.hasText).subscribe(
          data => {
            this.setDocumentSearchTableData(data.payload)
            console.log(data.payload);
          },
          error => {
            this.loadingService.hideLoading();
            this.alertService.clear()
            this.alertService.error(error.statusDescription)
          });
      }, 200);
    } else {
      //search bar has empty values
      this.gridApi.hideOverlay()
      this.setDocumentSearchTableData(null);
    }

  }

  setDocumentSearchTableData(payload: any) {
    this.loadingService.hideLoading();
    this.filteredData = [];
    this.rowData = [];
    if (payload != null) {
      for (let i = 0; i < payload.length; i++) {
        this.filteredData.push({
          id: payload[i]["checkSheetId"],
          referenceCount: payload[i]["referenceCount"],
          dependencies: "dependencies:",
          name: payload[i]["checkSheetName"],
          code: payload[i]["checkSheetCode"],
          discipline: payload[i]["checkSheetDisciplineName"],
          type: payload[i]["checkSheetType"],
          assignChks: "assignchecks:",
          assignAssets: "assignassets:",
          assignSys: "assignsystems:",
          status: "status:" + payload[i]["checksheetStatus"],
          edit: "edit:",
          delete: "delete:" + payload[i]["checksheetStatus"],
        });
      }
    }
    this.rowData = this.filteredData;
    this.gridOptions.columnApi.getColumn('name').setSort("asc");
    this.gridApi.paginationProxy.currentPage = 0;
    this.gridOptions.api.sizeColumnsToFit();
    this.checksheetService.setRowData(this.rowData);
  }
}
