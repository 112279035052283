<div>
    <div>{{ 'HEADER.TIMEZONE' | translate}}</div>
        <div>
            <ngx-timezones [(timezone)]="timezoneString"></ngx-timezones>
        </div>
    <div hidden="true">{{ 'HEADER.LANGUAGE' | translate}}</div>
        <div hidden="true">
            <label>
                <select #langSelect (change)="changeLanguage(langSelect.value)">
                    <option *ngFor="let lang of translate.getLangs()" [value]="lang" [selected]="lang">{{ lang }}</option>
                </select>
            </label>
        </div>
    <div hidden="true">{{ 'HEADER.FONTSIZE' | translate}}</div>
            <form [formGroup]="fontForm" hidden="true" >
                <select #fontSelect formControlName="fontControl" [style.font-size.px]="font['0']" (change)="changeFontSize(fontSelect.value)">
                    <option [style.font-size.px]="font['0']" [value]="font['0']" *ngFor="let font of fontList">{{font['1']}}</option>
                </select>
            </form>
    <div hidden="true">{{ 'HEADER.WIDGET' | translate}}</div>
        <div *ngFor="let widget of widgetList" hidden="true">
            <div>
                <input type="checkbox" data-md-icheck (change)="toggleVisibility(widget)" [checked]="widget['1'] === 1">  {{widget["0"]}} 
                <!-- <span *ngIf="marked"></span><span *ngIf="!marked"></span> -->
            </div>                
        </div>
</div>
