import { Component, OnInit, ElementRef, ViewChild, NgModule } from '@angular/core';
import { GridOptions, Module, AllModules } from "@ag-grid-enterprise/all-modules";
import { LoadingService } from '../../util/loading/loading.service';
import { SharedService } from '../../util/shared.service';
import { AlertService } from '../../util/alert/alert.service';
import { HistoryService } from '../history/history.service';
import { WsResponse } from '../../util/ws-response.model';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-history',
    templateUrl: './history.component.html',
    styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {
    public rowData: any[];
    public coldata: any[];
    public columnDefs = [];
    public gridOptions: GridOptions;
    private gridApi;
    private getRowNodeId;
    private gridColumnApi;
    public modules: Module[] = AllModules;


    constructor(private historyService: HistoryService, private loadingService: LoadingService) {
        this.columnDefs = [

            {
                headerName: 'Reusable Type', field: 'reusableType', checkboxSelection: false, hide: false,
                suppressColumnsToolPanel: false
            },
            {
                headerName: 'Reusable', field: 'reusableView', checkboxSelection: false, hide: false,
                suppressColumnsToolPanel: false,
                cellRenderer: function (params: any) {
					var eDiv = document.createElement('div');
					eDiv.innerHTML = params.data.reusableView;
					return eDiv;
				}
            }
        ];
        this.gridOptions = {
            rowData: this.rowData,
            columnDefs: this.columnDefs,
            defaultColDef: {
                resizable: true,
                sortable: true
            },
            rowSelection: "single",
            rowMultiSelectWithClick: false,
            onRowClicked: this.rowClicked,
        };
    }
    rowClicked() {
    }

    cellClicked(event) {
        console.log('event.data', event);
        if (event.data.reusableType == "System Name") {
            event.data.type = "systemName";
            event.data.systemName = event.data.reusableType;
            let tabType = SharedService.getTabComponentType();
            if (tabType == 'Editor') {
                SharedService.insertEditorRequest(event.data);
            } else if (tabType == 'Procedures') {
                SharedService.insertProceduresRequest(event.data);
            } else if (tabType == 'IceCourse') {
                SharedService.insertICECourseRequest(event.data);
            }
        }
        else if (event.data.reusableType == "Tag No") {
            event.data.type = "TagNo";
            event.data.tagNo = event.data.reusableView;
            console.log('event.data cell clicked', event.data);
            let tabType = SharedService.getTabComponentType();
            if (tabType == 'Editor') {
                SharedService.insertEditorRequest(event.data);
            } else if (tabType == 'Procedures') {
                SharedService.insertProceduresRequest(event.data);
            } else if (tabType == 'IceCourse') {
                SharedService.insertICECourseRequest(event.data);
            }
        } else if (event.data.reusableType == "System Number") {
            event.data.type = "systemNo";
            event.data.systemNo = event.data.reusableType;
            let tabType = SharedService.getTabComponentType();
            if (tabType == 'Editor') {
                SharedService.insertEditorRequest(event.data);
            } else if (tabType == 'Procedures') {
                SharedService.insertProceduresRequest(event.data);
            } else if (tabType == 'IceCourse') {
                SharedService.insertICECourseRequest(event.data);
            }
        } else if (event.data.reusableType == "Facility_System") {
            event.data.type = "SystemFacility";
            event.data.facilityName = event.data.reusableView;
            let tabType = SharedService.getTabComponentType();
            if (tabType == 'Editor') {
                SharedService.insertEditorRequest(event.data);
            } else if (tabType == 'Procedures') {
                SharedService.insertProceduresRequest(event.data);
            } else if (tabType == 'IceCourse') {
                SharedService.insertICECourseRequest(event.data);
            }
        } else if (event.data.reusableType == "Tag Description") {
            event.data.type = "Description";
            event.data.description = event.data.reusableView;
            let tabType = SharedService.getTabComponentType();
            if (tabType == 'Editor') {
                SharedService.insertEditorRequest(event.data);
            } else if (tabType == 'Procedures') {
                SharedService.insertProceduresRequest(event.data);
            } else if (tabType == 'IceCourse') {
                SharedService.insertICECourseRequest(event.data);
            }
        } else if (event.data.reusableType == "Tag System") {
            event.data.type = "System";
            event.data.systemName = event.data.reusableView;
            let tabType = SharedService.getTabComponentType();
            if (tabType == 'Editor') {
                SharedService.insertEditorRequest(event.data);
            } else if (tabType == 'Procedures') {
                SharedService.insertProceduresRequest(event.data);
            } else if (tabType == 'IceCourse') {
                SharedService.insertICECourseRequest(event.data);
            }
        } else if (event.data.reusableType == "Tag Document") {
            event.data.type = "TagDocument";
            event.data.documentName = event.data.reusableView;
            let tabType = SharedService.getTabComponentType();
            if (tabType == 'Editor') {
                SharedService.insertEditorRequest(event.data);
            } else if (tabType == 'Procedures') {
                SharedService.insertProceduresRequest(event.data);
            } else if (tabType == 'IceCourse') {
                SharedService.insertICECourseRequest(event.data);
            }
        } else if (event.data.reusableType == "Facility_tag") {
            event.data.type = "FacilityName";
            event.data.facilityName = event.data.reusableView;
            let tabType = SharedService.getTabComponentType();
            if (tabType == 'Editor') {
                SharedService.insertEditorRequest(event.data);
            } else if (tabType == 'Procedures') {
                SharedService.insertProceduresRequest(event.data);
            } else if (tabType == 'IceCourse') {
                SharedService.insertICECourseRequest(event.data);
            }
        } else if (event.data.reusableType == "Facility") {
            event.data.type = "Facility";
            event.data.facilityName = event.data.reusableView;
            let tabType = SharedService.getTabComponentType();
            if (tabType == 'Editor') {
                SharedService.insertEditorRequest(event.data);
            } else if (tabType == 'Procedures') {
                SharedService.insertProceduresRequest(event.data);
            } else if (tabType == 'IceCourse') {
                SharedService.insertICECourseRequest(event.data);
            }
        } else if (event.data.reusableType == "Document Name") {
            event.data.type = "Document";
            event.data.documentName = event.data.reusableView;
            let tabType = SharedService.getTabComponentType();
            if (tabType == 'Editor') {
                SharedService.insertEditorRequest(event.data);
            } else if (tabType == 'Procedures') {
                SharedService.insertProceduresRequest(event.data);
            } else if (tabType == 'IceCourse') {
                SharedService.insertICECourseRequest(event.data);
            }
        } else if (event.data.reusableType == "Component Value") {
            event.data.type = "Component";
            event.data.componentValue = event.data.reusableView;
            let tabType = SharedService.getTabComponentType();
            if (tabType == 'Editor') {
                SharedService.insertEditorRequest(event.data);
            } else if (tabType == 'Procedures') {
                SharedService.insertProceduresRequest(event.data);
            } else if (tabType == 'IceCourse') {
                SharedService.insertICECourseRequest(event.data);
            }
        } else if (event.data.reusableType == "Statement") {
            event.data.type = "History_Statement";
            event.data.componentValue = event.data.reusableView;
            let tabType = SharedService.getTabComponentType();
            if (tabType == 'Editor') {
                SharedService.insertEditorRequest(event.data);
            } else if (tabType == 'Procedures') {
                SharedService.insertProceduresRequest(event.data);
            } else if (tabType == 'IceCourse') {
                SharedService.insertICECourseRequest(event.data);
            }
        } else if (event.data.reusableType == "Tag No&Description") {
            event.data.type = "TagNoDesc";
            let tabType = SharedService.getTabComponentType();
            if (tabType == 'Editor') {
                SharedService.insertEditorRequest(event.data);
            } else if (tabType == 'Procedures') {
                SharedService.insertProceduresRequest(event.data);
            } else if (tabType == 'IceCourse') {
                SharedService.insertICECourseRequest(event.data);
            }
        }else if (event.data.reusableType == "Tag Attribute") {
            event.data.type = "TagAttributes";
            let tabType = SharedService.getTabComponentType();
            if (tabType == 'Editor') {
                SharedService.insertEditorRequest(event.data);
            } else if (tabType == 'Procedures') {
                SharedService.insertProceduresRequest(event.data);
            } else if (tabType == 'IceCourse') {
                SharedService.insertICECourseRequest(event.data);
            }
        }
    }
    ngOnInit() {
        console.log('getHistory');
        let to: any = false;
        if (to) { clearTimeout(to); }
        to = setTimeout(() => {
            this.historyService.getHistoryList().subscribe(
                data => {
                    console.log("success", data.payload);
                    this.loadHistoryData(data.payload)
                },
                error => {

                });;
        }, 200);
    }

    loadHistoryData(historyData: any) {
        this.loadingService.hideLoading();
        console.log(historyData);
        //this.filteredData = [];
        this.rowData = [];
        if (historyData != null) {

            for (let i = 0; i < historyData.length; i++) {
                this.rowData.push({
                    id: historyData[i]["id"],
                    reusableType: historyData[i]["reusableType"],
                    reusableView: historyData[i]["reusableView"],
                    type: historyData[i]["type"],
                    reusableXML: historyData[i]["reusableXML"]
                });
            }
            // this.rowData = this.filteredData;
            this.gridOptions.api.sizeColumnsToFit();
        } else {
            // no row data to show
        }

    }



}
