import { Component, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { TagsService } from '../tags.service';
import { TranslateService } from '@ngx-translate/core';
import { UserVariable } from 'src/app/util/common/user-variable';
import { WsCallback } from 'src/app/util/ws-callback.interface';
import { WsResponse } from 'src/app/util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';
import { AlertService } from 'src/app/util/alert/alert.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { DeleteCellRendererComponent } from '../delete-cell-renderer/delete-cell-renderer.component';
import { GridOptions, Module, AllModules } from "@ag-grid-enterprise/all-modules";
import { DependencyCellRenderComponent } from '../dependency-cell-render/dependency-cell-render.component';
import { DependencyModalComponent } from '../tag-classification/dependency-modal/dependency-modal.component';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { ConfirmationCallback } from '../confirmation-modal/confirmation-callback';
import { LoadingService } from 'src/app/util/loading/loading.service';
import { CommonUtil } from 'src/app/util/common/common-util';

@Component({
	selector: 'app-tag-value-list',
	templateUrl: './tag-value-list.component.html',
	styleUrls: ['./tag-value-list.component.css']
})
export class TagValueListComponent implements OnInit, WsCallback, ConfirmationCallback {
	private token: string;
	public tagValueList: any = [];
	public modules: Module[] = AllModules;
	private bsModalRef: BsModalRef;
	public filterText: string = '';
	public valueListDescription = '';
	public selectedValueListItem: any = {
		id: '', valueListId: ''
	};

	public newValueListName: string;
	public newValueListDescription: string;

	//ag-grid
	private gridApi;
	private valueGridApi;
	private gridColumnApi;
	public columnDefs: any;
	public rowData: any[];
	public frameworkComponents: any;
	public gridOptions;

	//ag-grid valuelist
	public valueListColumnDefs: any;
	public rowDataForValueList: any[];

	//attribute list
	public attributeList: any[] = [];

	//default items
	public valueListItems: any[] = [];
	public valueListSelected: boolean = false
	public paddingtop: number = 60;
	public valueListEditItem: any;
    @ViewChild('navPanel', {static: true}) navPanel: ElementRef
	private oldDescription: string;
	public divHeight = window.innerHeight-230;

	constructor(private tagService: TagsService, private translate: TranslateService, private alertService: AlertService,
        private modalService: BsModalService, private router: Router, private loadingService: LoadingService, private commonUtil: CommonUtil) {
		translate.addLangs(["es", "en"]);
		translate.setDefaultLang("en");
		let browserLang = translate.getBrowserLang();
		translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');

		//Initialization of variables
        this.token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(this.token)) {
            return;
        }

		//Initialize grid options
		this.gridOptions = <GridOptions>{
			context: {
				componentParent: this
			},
			defaultColDef: {
                resizable: true,
			},
		};
	}

	ngOnInit() {
		this.loadingService.showLoading(true, false, 'Loading', 0);
		this.tagService.getValueList(this.token, this);
		this.prepareAgGrid();
		this.tagService.getDefaultItemsForValueList(this.token, this);

		if (this.navPanel.nativeElement.offsetHeight > 55) {
			this.paddingtop = 78
		} else {
			this.paddingtop = 50
		}
	}

	onValueSelect(value: any) {
		this.selectedValueListItem = value;
		this.valueListDescription = this.selectedValueListItem.description;
		this.loadAttributes();
	}

	clearSelectedValueData(){
		this.selectedValueListItem.id = ''
		this.selectedValueListItem.valueListId = ''
		this.selectedValueListItem.attribute = ''
		this.selectedValueListItem.sequence = ''
		this.selectedValueListItem.valueListName = ''
		this.selectedValueListItem.description = ''
		this.selectedValueListItem.projectId = ''
		this.selectedValueListItem.status = ''
		this.selectedValueListItem.equipmentField = ''
	}

	/**
	 * Call attribute list loading for a selected value list.
	 */
	public loadAttributes() {
		this.loadingService.showLoading(true, false, 'Loading', 0);
		this.tagService.getValueListAttributes(this.token, this.selectedValueListItem.valueListId, this);
	}

	/**
	 * Clicks on the description save button.
	 */
	saveDescription() {
		if (this.valueListDescription != undefined) {
			this.valueListDescription = this.valueListDescription.trim()
			this.valueListDescription = this.valueListDescription.replace(/\\/g, "\\\\"); 
			if((this.valueListDescription == this.oldDescription) || this.valueListDescription == ''){
				this.valueListDescription = this.oldDescription
				return
			}
			this.loadingService.showLoading(true, false, 'Saving', 0);
			//set the value list name before saving the description
			this.selectedValueListItem.valueListName = this.selectedValueListItem.valueListName.replace(/\\/g, "\\\\"); 
			const data = {
				userToken: this.token,
				id: this.selectedValueListItem.valueListId,
				name: this.selectedValueListItem.valueListName,
				description: this.valueListDescription
			}
			this.tagService.updateValueList(data, this);
		} else {
			let alert = this.translate.instant('TAG_VALUE_LIST.DESCRIPTION_EMPTY');
			this.alertService.error(alert);
			this.valueListDescription = this.oldDescription
		}
	}
	/**
	 * keep existing description to replace if an error occured
	 */
	editDescription(){
		this.oldDescription = this.valueListDescription
	}

	/**
	 * Clicks on the Add button.
	 * @param template format for the modal
	 */
	addValueButtonClick(template: TemplateRef<any>) {
		console.log("Add value list button clicked........");
		this.bsModalRef = this.modalService.show(template, { class: 'modal-lg', backdrop: 'static', keyboard : false });
	}

	/**
	 * Click on the save new value list button.
	 */
	submitButtonClick() {
		if (this.newValueListName != undefined && this.newValueListName != ''
			&& this.newValueListDescription != undefined && this.newValueListDescription != '') {
				this.newValueListName = this.newValueListName.trim()
				//eliminate \ from the value list name
				this.newValueListName = this.newValueListName.replace(/\\/g, "\\\\"); 
				if(this.newValueListName == ''){
					return 
				}
				this.newValueListDescription = this.newValueListDescription.trim() 
				//eliminate \ from the value list description
				this.newValueListDescription = this.newValueListDescription.replace(/\\/g, "\\\\"); 
				if(this.newValueListDescription  == ''){
					return 
				}
			this.loadingService.showLoading(true, false, 'Adding', 0);
			const data = { userToken: this.token, id: 0, name: this.newValueListName, description: this.newValueListDescription }
			this.tagService.addValueList(data, this);
		} else {
			return 
		}
	}

	/**
	 * Clicks on the delete button of the value list.
	 * @param tagValue item list.
	 */
	deleteValueListItem(tagValue: any) {
		//tagValue.valueListId
		this.selectedValueListItem = tagValue;
		this.showDeleteConfirmation(tagValue);
	}

	showDeleteConfirmation(tagValue: any) {
		let title = this.translate.instant('TAG_VALUE_LIST.DELETE_CONFIRM');
		let message = this.translate.instant('TAG_VALUE_LIST.DELETE_CONFIRM_MESSAGE');
		const initialState = {
			token: this.token,
			attributeId: tagValue.valueListId,
			confirmationTitle: title,
			confirmationMessage: message,
			callBack: this
		};
		this.bsModalRef = this.modalService.show(ConfirmationModalComponent, { initialState });
	}

	/**
	 * Click on the confirm button of the delete confirmation popup.
	 * @param itemId item id value
	 */
	onDeleteConfirm(itemId: any) {
		this.loadingService.showLoading(true, false, 'Deleting', 0);
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
		var request: any = {
			"itemId": itemId,
			"itemType": 'CMMSValueList',
			"action": 'delete',
            "userToken": token
		};
		this.tagService.getTagDependenciesByTag(JSON.stringify(request), this);
		this.bsModalRef.hide();
	}

	/**
	 * Click on the cancel button of the delete confirmation popup.
	 */
	onDeleteDecline() {
		this.bsModalRef.hide()
	}

	/**
	 * Shows dependency list popup.
	 * @param tagList dependency list/
	 */
	dependenciesModalWithComponent(tagList: any[]) {
		const initialState : object = {
			tagDependency: tagList,
			title: 'Dependencies List'
		};
		this.bsModalRef = this.modalService.show(DependencyModalComponent, { initialState });
		this.bsModalRef.content.closeBtnName = 'Close';
	}

	/**
	 * Clicks on the edit button of the value list.
	 * @param tagValue selected value list item.
	 * @param template modal template.
	 */
	editValueListItem(tagValue: any, template: TemplateRef<any>) {
		this.valueListEditItem = tagValue;
		this.newValueListName = tagValue.valueListName;
		this.bsModalRef = this.modalService.show(template, { class: 'modal-lg', backdrop: 'static', keyboard: false });
	}

	/**
	 * Clicks on the submit button of the edit value list popup.
	 */
	editSubmitButtonClick() {
		if (this.newValueListName != undefined && this.newValueListName != '') {
			this.newValueListName = this.newValueListName.trim()
			//eliminate \ from the value list name
			this.newValueListName = this.newValueListName.replace(/\\/g, "\\\\"); 
			if(this.newValueListName == ''){
				return
			}
			this.loadingService.showLoading(true, false, 'Saving', 0);
			const data = {
				userToken: this.token, id: this.valueListEditItem.valueListId,
				name: this.newValueListName, description: this.valueListEditItem.description
			}
			this.tagService.updateValueListName(data, this);
		} else {
			return
		}
	}

	/**
	 * Clicks on the cancel button of the edit value list popup.
	 */
	editCancelButtonClick() {
		this.newValueListName = ''
		this.bsModalRef.hide();
	}
	/**
	 * Clicks on the cancel button.
	 */
	cancelButtonClick() {
		this.bsModalRef.hide()
		this.newValueListDescription = '';
		this.newValueListName = '';
	}

	/**
	 * Filter function for the value list search view.
	 * @param itemList value list
	 */
	public filter(itemList: any[]): any[] {
		let result: any[] = [];
		itemList.forEach(item => {
			if (item.valueListName.toLowerCase().search(this.filterText.toLowerCase()) != -1) {
				result[result.length] = item;
			}
		});
		return result;
	}

	/**
	 * Ag_Grid Related Methods
	 */

	/**
	 * Just after finishing the ag-grid view.
	 * @param params parameters which are passed to the ag-grid view
	 */
	onGridReady(params: any) {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		this.gridApi.sizeColumnsToFit();
		// this.gridOptions.api.sizeColumnsToFit();
	}

	onValueListGridReady(params: any){
		this.valueGridApi = params.api;
	}

	onColumnResized(event) {
		if (event.finished) {
			this.valueGridApi.resetRowHeights();
		}
	}

	/**
	 * after load data set columns width to fit
	 */
	onRowDataChanged(){
		this.valueGridApi.sizeColumnsToFit();
	}

	/**
	 * Just after the row dragging.
	 * @param e dragged row related data.
	 */
	public onRowDragEnd(e: any) {
		// e.overIndex = moved position in the new list (move 4th item to 0th position).
		// -1 can be returned for invalid movements.
		if (e.overIndex == -1) {
			// valid movement
			return;
		}
		let moveToIndex = e.overIndex;
		let moveFromIndex = 0;
		let overNode = e.overNode;
		this.attributeList.forEach(attribute => {
			if (attribute != undefined) {
				if (attribute.id == overNode.data.id) {
				moveFromIndex = attribute.sequence - 1;
				};
			}
		});
		if (moveFromIndex === moveToIndex) {
			console.log('There is no change');
			return;
		}
		//move items
		this.moveAttribute(moveFromIndex, moveToIndex);
		//reset sequences
		let orderList = this.updateAttributeSequences();
		//prepare the json data
		var data = { userToken: this.token, orders: orderList };
		this.tagService.setValueListAttrOrder(data, this);
	}

	/**
   * Move attribute along the attribute array list. This is achieved by splitting the array into three parts
   * based on moving position and replace position. Then merge them all together.
   * [first_part][from/to][middle_part][to/from][last_part]
   *
   * @param moveFromIndex index of the item that we want to move
   * @param moveToIndex index of the place where the item should be moved
   */
	private moveAttribute(moveFromIndex: number, moveToIndex: number) {
		// list will be divided into three parts
		if (moveFromIndex > moveToIndex) {
			var firstPart = this.attributeList.slice(0, moveToIndex);
			var middlePart = this.attributeList.slice(moveToIndex + 1, moveFromIndex);
			var lastPart = this.attributeList.slice(moveFromIndex + 1);
			var movingItem = this.attributeList[moveFromIndex];
			var replacedItem = this.attributeList[moveToIndex];
			// concatenate all items to create the final one.
			this.attributeList = [...firstPart, movingItem, replacedItem, ...middlePart, ...lastPart];
		} else {
			var firstPart = this.attributeList.slice(0, moveFromIndex);
			var middlePart = this.attributeList.slice(moveFromIndex + 1, moveToIndex);
			var lastPart = this.attributeList.slice(moveToIndex + 1);
			var movingItem = this.attributeList[moveFromIndex];
			var replacedItem = this.attributeList[moveToIndex];
			// concatenate all items to create the final one.
			this.attributeList = [...firstPart, ...middlePart, replacedItem, movingItem, ...lastPart];
		}
	}

	/**
   * Set the sequence value.
   */
	private updateAttributeSequences(): any[] {
		let index = 1;
		let orderList = [];
		this.attributeList.forEach(attribute => {
			if (attribute != undefined) {
				attribute.sequence = index;
				orderList.push({ id: attribute.id, sequence: index });
				index++;
			}
		});

		return orderList;
	}

	/**
	 * Just after the cell modification.
	 * @param params new values
	 */
	onCellValueChanged(params) {
		if (params.data.id == null) {
			var addData = { "userToken": this.token, "valueListId": params.data.valueListId, "attributeName": params.data.attribute, "sequence": params.data.sequence };
			let available = false;
			for (let i = 0; i < this.attributeList.length; i++) {
				if ((addData.attributeName == this.attributeList[i].attribute) && (this.attributeList[i].id != null)) {
					available =true;
					
				}
			} 
			if (available == false) {			
				this.tagService.addValueListAttribute(addData, this);
			} else {
				this.alertService.error("Valuelist can not have duplicate values");
				this.gridOptions.api.startEditingCell({
					rowIndex:  params.rowIndex,
					colKey: 'attribute',					
				});
				//this.loadAttributes();
			}

		} else {
			var data = { "userToken": this.token, "attributeId": params.data.id, "attributeName": params.data.attribute };
			let available = false;
			for (let i = 0; i < this.attributeList.length; i++) {
				if ((data.attributeName == this.attributeList[i].attribute) && (this.attributeList[i].id != params.data.id)) {
					available =true;
					
				}
			} 
			if (available == false) {
				this.tagService.updateValueListAttribute(data, this);
			} else {
				this.alertService.error("Valuelist can not have duplicate values");
				this.gridOptions.api.startEditingCell({
					rowIndex: params.rowIndex,
					colKey: 'attribute',					
				});
				//this.loadAttributes();
			}
		}
	}

	/**
	 * Clicks on the add attribute button
	 */
	addAttribute() {
		var newItem = {
			attribute: '--Double click to edit--',
			description: null,
			equipmentField: null,
			id: null,
			projectId: null,
			sequence: this.attributeList.length + 1,
			status: null,
			valueListId: this.selectedValueListItem.valueListId,
			valueListName: null
		};
		this.attributeList.push(newItem);
		var res = this.gridApi.applyTransaction({ add: [newItem] });
	}

	/**
	 * Preparing the column definitions for the table.
	 */
	private prepareAgGrid() {
		this.columnDefs = [
			{	//column for drag, change sequence
				minWidth: 50,
				maxWidth: 50,
				autoHeight: true,
				rowDrag: true
				
			},
			{
				headerName: 'Value',
				width: 500,
				autoHeight: true,
				editable: true,
				type: 'Attribute',
				field: "attribute",
				// cellEditor: "agLargeTextCellEditor",
				cellStyle: { 'text-align': 'left' },
			},
			{
				headerName: 'Delete',
				type: 'Delete',
				width: 100,
				autoHeight: true,
				editable: false,
				field: "attributeId",
				cellStyle: { 'text-align': 'center' },
				cellRendererFramework: DeleteCellRendererComponent,
				
			}
		];
	}

	/**
	 * Fires when attribute name edit is finished.
	 * @param param parameter model
	 */
	cellEditCompleted(param: any) {
	}


	////////////////////////////////////
	////////////VALUE LIST AG-GRID//////
	////////////////////////////////////

	/**
	 * Value list ag-grid column definitions.
	 */
	private prepareValueListAgGrid() {
		this.valueListColumnDefs = [
			{
				headerName: 'Dependency',
				autoHeight: true,
				// editable: false,
				field: "name",
				cellStyle: { 'text-align': 'left' },
				resizable: true,
				cellRendererFramework: DependencyCellRenderComponent
			},
			{
				headerName: 'Item',
				autoHeight: true,
				// editable: false,
				field: "name",
				resizable: true,
				cellEditor: "agTextCellEditor",
				cellStyle: { 'text-align': 'left' }
			},
			{
				headerName: 'Value List',
				autoHeight: true,
				editable: true,
				field: "valueListName",
				cellEditor: "agRichSelectCellEditor",
				cellStyle: { 'text-align': 'left' },
				cellRenderer: function (params) {
					var value = '--select value list--'
					params.data.tagValueList.forEach(tag => {
						if (params.data.id == tag.valueListId) {
							value = tag.valueListName;
						}
					});
					return value;
				},
				cellEditorParams: function (params) {
					var attributeValues: any[] = [];
					params.data.tagValueList.forEach(tag => {
						attributeValues[attributeValues.length] = tag.valueListName;
					});

					return { values: attributeValues }
				},
				resizable: true,
				singleClickEdit: true
			}
		];
	}

	/**
	 * Prepare data for the value list grid.
	 */
	private prepareRowDataForValueListGrid() {
		var rowData: any[] = [];
		this.valueListItems.forEach(valueList => {
			rowData.push({
				id: valueList.id,
				name: valueList.name,
				count :valueList.count,
				tagValueList: this.tagValueList
			});
		});
		this.rowDataForValueList = rowData;
	}

	/**
	 * when user select value list.
	 */
	onValueListCellValueChanged(params) {
		if(params.oldValue == params.newValue){
			return false
		}
		var listId;
		params.data.tagValueList.forEach(item => {
			if (item.valueListName === params.newValue) {
				listId = item.valueListId;
			}
		});
		if (listId == undefined) {
			return;
		}
		const data = { userToken: this.token, valueListId: listId, equipmentFieldId: params.data.name };
		this.tagService.addEquipmentField(data, this);
	}

	onSuccess(data: WsResponse, serviceType: WsType) {
		this.loadingService.hideLoading();

		if (serviceType == WsType.GET_CMMS_VALUE_LIST) {
			this.tagValueList = data.payload;
			this.prepareRowDataForValueListGrid();
			this.prepareValueListAgGrid();
		} else if (serviceType == WsType.ADD_VALUE_LIST) {
			let alert = this.translate.instant('TAG_VALUE_LIST.ADD_SUCCESS');
			this.alertService.success(alert);
			//Load new list
			this.tagService.getValueList(this.token, this);
			this.bsModalRef.hide();
			this.newValueListDescription = '';
			this.newValueListName = '';
		} else if (serviceType == WsType.UPDATE_VALUE_LIST) {
			//Load new list
			let alert = this.translate.instant('TAG_VALUE_LIST.UPDATE_VALUE_LIST');
			this.alertService.success(alert);
			this.tagService.getValueList(this.token, this);
			//set the valueListDescription to initial value 
			this.valueListDescription = this.valueListDescription.replace(/\\\\/g, "\\"); 
			//set the valueListName to initial value 
			this.selectedValueListItem.valueListName = this.selectedValueListItem.valueListName.replace(/\\\\/g, "\\"); 
		} else if (serviceType == WsType.GET_DEFAULT_ITEMS_FOR_VALUE_LIST) {
			this.valueListItems = data.payload;
			this.prepareRowDataForValueListGrid();
		} else if (serviceType == WsType.GET_VALUE_LIST_ATTRIBUTES) {
			this.attributeList = data.payload;
			this.gridApi.sizeColumnsToFit();
		} else if (serviceType == WsType.UPDATE_VALUE_LIST_SEQUENCE) {
			let alert = this.translate.instant('TAG_VALUE_LIST.UPDATE_VALUE_LIST_SEQUENCE');
			this.alertService.success(alert);
			// this.alertService.success(data.statusDescription);
		} else if (serviceType == WsType.UPDATE_VALUE_LIST_ATTRIBUTE) {
			let alert = this.translate.instant('TAG_VALUE_LIST.UPDATE_VALUE_LIST_ATTRIBUTE');
			this.alertService.success(alert);
		} else if (serviceType == WsType.ADD_VALUE_LIST_ATTRIBUTE) {
			this.loadAttributes()
			let alert = this.translate.instant('TAG_VALUE_LIST.ADD_VALUE_LIST_ATTRIBUTE');
			this.alertService.success(alert);
		} else if (serviceType == WsType.ADD_EQUIPMENT_FIELD) {
			this.tagService.getDefaultItemsForValueList(this.token, this);
			let alert = this.translate.instant('TAG_VALUE_LIST.ADD_EQUIPMENT_FIELD');
			this.alertService.success(alert);
		} else if (serviceType == WsType.UPDATE_VALUE_LIST_NAME) {
			let alert = this.translate.instant('TAG_VALUE_LIST.UPDATE_VALUE_LIST_NAME');
			this.alertService.success(alert);
			if(this.selectedValueListItem.valueListId == this.valueListEditItem.valueListId){
				this.newValueListName = this.newValueListName.replace(/\\\\/g, "\\"); 
				this.selectedValueListItem.valueListName = this.newValueListName
				this.newValueListName = ''
			}
			this.bsModalRef.hide();
			this.tagService.getValueList(this.token, this);
		} if (serviceType == WsType.GET_TAG_DEPENDENCIES_BY_TAG) {
			var payload = JSON.parse(data.payload);
			if (payload.HasDependencies == 0 || payload.HasDependencies == undefined) {
				const data = {
					userToken: this.token, id: this.selectedValueListItem.valueListId,
					name: this.newValueListName, description: this.selectedValueListItem.description
				}
				this.tagService.deleteValueList(data, this);
			} else {
				var string_ = JSON.stringify(payload.TagDependency);
				var json_ = JSON.parse(string_);
				this.dependenciesModalWithComponent(json_);
			}
		} if (serviceType == WsType.DELETE_VALUE_LIST) {
			this.selectedValueListItem.valueListName = '';
			this.tagService.getValueList(this.token, this);
			let alert = this.translate.instant('TAG_VALUE_LIST.DELETE_VALUE_LIST');
			this.alertService.success(alert);
			this.valueListDescription = '';
			this.attributeList = [];
			this.clearSelectedValueData()
		}
	}

	onFail(data: WsResponse, serviceType: WsType) {
		this.loadingService.hideLoading();

		if (serviceType == WsType.GET_CMMS_VALUE_LIST) {
			this.alertService.error(data.statusDescription);
		} if (serviceType == WsType.ADD_VALUE_LIST) {
			this.alertService.error(data.statusDescription);
		} else if (serviceType == WsType.UPDATE_VALUE_LIST) {
			this.alertService.error(data.statusDescription);
		} else if (serviceType == WsType.GET_DEFAULT_ITEMS_FOR_VALUE_LIST) {
			this.alertService.error(data.statusDescription);
		} else if (serviceType == WsType.GET_VALUE_LIST_ATTRIBUTES) {
			this.alertService.error(data.statusDescription);
		} else if (serviceType == WsType.UPDATE_VALUE_LIST_SEQUENCE) {
			this.alertService.error(data.statusDescription);
		} else if (serviceType == WsType.UPDATE_VALUE_LIST_ATTRIBUTE) {
			this.alertService.error(data.statusDescription);
		} else if (serviceType == WsType.ADD_VALUE_LIST_ATTRIBUTE) {
			this.alertService.error(data.statusDescription);
		} else if (serviceType == WsType.ADD_EQUIPMENT_FIELD) {
			this.alertService.error(data.statusDescription);
		} else if (serviceType == WsType.UPDATE_VALUE_LIST_NAME) {
			this.alertService.error(data.statusDescription);
		} if (serviceType == WsType.GET_TAG_DEPENDENCIES_BY_TAG) {
			this.alertService.error(data.statusDescription);
		} if (serviceType == WsType.DELETE_VALUE_LIST) {
			this.alertService.error(data.statusDescription);
		}
	}
}
