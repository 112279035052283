import { AllModules, ColDef, GridOptions, ICellEditorComp, ICellEditorParams, Module } from '@ag-grid-enterprise/all-modules';
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { Constants } from 'src/app/util/constants';
import { AlertService } from 'src/app/util/alert/alert.service';
import { LoadingService } from 'src/app/util/loading/loading.service';
import { SharedService } from 'src/app/util/shared.service';
import { WsCallback } from 'src/app/util/ws-callback.interface';
import { WsResponse } from 'src/app/util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';
import datepickerFactory from 'jquery-datepicker';
import datepickerJAFactory from 'jquery-datepicker/i18n/jquery.ui.datepicker-en-GB';
import { ChecksheetsCellRenderComponentComponent } from '../../checksheets-cell-render-component/checksheets-cell-render-component.component';
import { ManagechecksheetsService } from '../managechecksheets.service';

declare const $: any; // avoid the error on $(this.eInput).datepicker();
datepickerFactory($);
datepickerJAFactory($);

@Component({
	selector: 'app-assignsystems',
	templateUrl: './assignsystems.component.html',
	styleUrls: ['./assignsystems.component.css']
})
export class AssignsystemsComponent implements OnInit, WsCallback {
	@ViewChild("closeConfirmModal") closeConfirmModal: TemplateRef<any>;
	public closeModalRef: BsModalRef;

	public columnDefs: any;
	rowData: any = [];
	public sysColumnDefs: any;
	sysRowData: any = [];
	filteredData: any[];
	sysFilteredData: any[];
	deletedSystems: any[];
	savedTableData: any[];
	public gridApi: any;
	public sysGridApi: any;
	public getRowNodeId: any;
	private gridColumnApi: any;
	public overlayLoadingTemplate: any;
	public overlayNoRowsTemplate: any;
	public modules: Module[] = AllModules;
	public gridOptions: GridOptions;
	public sysGridOptions: GridOptions;

	public csId: string;
	private loadingHandler: any;
	public treeTitle = "";
	private checksheetNode: any;

	public searchData: any;
	private sysNoSearch: string = "";
	private sysNameSearch: string = "";
	private sysParentSearch: string = "";

	loadingCSSub: Subscription;
	treeheight: number = (window.innerHeight) * 2 / 3;
	private vendorList = [];
	private vendorValueList = [];
	private phaseList = [];
	private phaseValueList = [];

	constructor(
		public bsModalRef: BsModalRef,
		private alertService: AlertService,
		private loadingService: LoadingService,
		private translate: TranslateService,
		private sharedService: SharedService,
		private modalService: BsModalService,
		private checksheetService: ManagechecksheetsService,
	) {
		translate.addLangs(["es", "en"]);
		translate.setDefaultLang("en");
		let browserLang = translate.getBrowserLang();
		translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');

		this.columnDefs = [
			{
				headerName: this.translate.instant('CS_ASSIGN_SYSTEMS.SYSTEM_NO'),
				field: 'sysNo',
				autoHeight: true,
				cellClass: "cell-wrap-text",
				minWidth: 150,
				dndSource: true
			},
			{
				headerName: this.translate.instant('CS_ASSIGN_SYSTEMS.SYSTEM_NAME'),
				field: 'sysName',
				autoHeight: true,
				cellClass: "cell-wrap-text",
				minWidth: 150,
			}
		];

		this.sysColumnDefs = [
			{
				headerName: '',
				field: "deleteSystem",
				minWidth: 85,
				cellStyle: { 'text-align': 'center' },
				cellRendererFramework: ChecksheetsCellRenderComponentComponent,
			},
			{
				headerName: this.translate.instant('CS_ASSIGN_SYSTEMS.SYSTEM_NO'),
				field: 'sysNo',
				autoHeight: true,
				cellClass: "cell-wrap-text",
				minWidth: 120,
			},
			{
				headerName: this.translate.instant('CS_ASSIGN_SYSTEMS.SYSTEM_NAME'),
				field: 'sysName',
				autoHeight: true,
				cellClass: "cell-wrap-text",
				minWidth: 150,
			},
			{
				headerName: this.translate.instant('CS_ASSIGN_SYSTEMS.COMP_DATE'),
				field: 'cdate',
				minWidth: 120,
				editable: true,
				cellEditor: DatePicker,
				singleClickEdit: true,
				cellClass: this.EnableDropdownFunction.bind(this)
			},
			{
				headerName: this.translate.instant('CS_ASSIGN_SYSTEMS.VENDOR'),
				field: 'vendor',
				cellEditor: 'agRichSelectCellEditor',
				editable: this.checkEditFunction.bind(this),
				minWidth: 120,
				cellEditorParams: {
					cellHeight: 30,
					values: this.vendorValueList
				},
				singleClickEdit: true,
				cellClass: this.EnableDropdownFunction.bind(this)
			},
			{
				headerName: this.translate.instant('CS_ASSIGN_SYSTEMS.PHASE'),
				field: 'phase',
				cellEditor: 'agRichSelectCellEditor',
				editable: this.checkEditFunction.bind(this),
				minWidth: 120,
				cellEditorParams: {
					cellHeight: 30,
					values: this.phaseValueList
				},
				singleClickEdit: true,
				cellClass: this.EnableDropdownFunction.bind(this)
			}
		];

		this.getRowNodeId = function (data) {
			return data.id;
		};

		this.gridOptions = {
			rowData: this.rowData,
			columnDefs: this.columnDefs,
			defaultColDef: {
				resizable: true,
				sortable: true,
			},
			rowDragManaged: true,
			rowSelection: "multiple",
			rowMultiSelectWithClick: true,
			doesExternalFilterPass: this.externalFilterPass,
			animateRows: true,
			rowBuffer: 20,
			context: {
				componentParent: this
			},
			paginationPageSize: 12,
		};

		var self = this;
		this.sysGridOptions = {
			rowData: this.sysRowData,
			columnDefs: this.sysColumnDefs,
			defaultColDef: {
				resizable: true,
				sortable: true,
			},
			rowSelection: "single",
			rowMultiSelectWithClick: false,
			doesExternalFilterPass: this.externalFilterPass,
			animateRows: true,
			rowBuffer: 20,
			context: {
				componentParent: this
			},
			paginationPageSize: 12,
			onCellValueChanged: function (event) {
				self.checksheetService.setIsSaved(false);
			}
		};

		//custom layout templates for loading and empty data sets
		this.overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Loading data...</span>';
		this.overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">No rows to show</span>';
	}

	checkEditFunction(params) {
		if (params.node.data.referenceCount == 0) {
			return true;
		} else {
			return false;
		}
	}

	EnableDropdownFunction(params) {
		if (params.colDef.field == "cdate") {
			return 'grid-cell-calendar-style';
		}
		else if (params.node.data.referenceCount == 0) {
			return 'grid-cell-drop-down-style';
		}
	}

	externalFilterPass(node: any) {
		return true;
	}
	private onWindowResize = () => {
   
		setTimeout(() => {
			if (this.gridApi) {
				this.gridApi.sizeColumnsToFit();
			}
		}); 
	};
	onGridReady(params) {
		const allColumnIds = [];
		this.columnDefs.forEach((columnDef) => {
			allColumnIds.push(<ColDef>columnDef);
		});
		this.gridColumnApi = params.columnApi;
		this.gridApi = params.api;
		this.checksheetService.setAllApiGridData(this.gridApi);
		params.api.sizeColumnsToFit();
		// window.addEventListener("resize", function () {
		// 	setTimeout(function () {
		// 		params.api.sizeColumnsToFit();
		// 	});
		// });
		window.addEventListener("resize", this.onWindowResize);
	}

	initializeGrid(params) {
		const allColumnIds = [];
		this.sysColumnDefs.forEach((columnDef) => {
			allColumnIds.push(<ColDef>columnDef);
		});
		this.gridColumnApi = params.columnApi;
		this.sysGridApi = params.api;
		this.checksheetService.setAssignedApiGridData(this.sysGridApi);
		params.api.sizeColumnsToFit();
		// window.addEventListener("resize", function () {
		// 	setTimeout(function () {
		// 		params.api.sizeColumnsToFit();
		// 	});
		// });
		window.addEventListener("resize", this.onWindowResize);
		var self = this;
		document.addEventListener("drop", function (event) {
			event.preventDefault();
			var jsonData = event.dataTransfer.getData('application/json');
			var data = JSON.parse(jsonData);
			self.dropGridData(data);
		});
	}
	ngOnDestroy() {
		window.removeEventListener("resize", this.onWindowResize);
		this.gridApi.destroy();
		this.gridApi = null;
	  }
	ngOnInit(): void {
		this.loadingHandler = new AssignsystemsComponent.LoadingHandler();
		// loading Checksheet systems data after loading all other items
		this.loadingCSSub = this.loadingHandler.getLoadedSub().subscribe(() => {
			this.loadingService.showLoading(true, false, 'Loading', 0);
			this.checksheetService.getAssignedSystems(this, this.csId);
		});
		this.loadingHandler.init(3, this.loadingService);
		this.checksheetService.getSystemsByProjectId(this.csId, this);
		this.checksheetService.getCSDataById(this.csId, this);
		this.checksheetService.getAllVendors(this);
		this.checksheetService.getAllPhases(this);
	}

	ngAfterViewInit(): void {
		//Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
		//Add 'implements AfterViewInit' to the class.
		this.changeModalSize();
		if (typeof window !== 'undefined') {
			(<any>window).gridDragOver = this.gridDragOver;
			(<any>window).gridDrop = this.gridDrop;
		}
	}

	gridDragOver(event: any) {
		var dragSupported = event.dataTransfer.types.length;

		if (dragSupported) {
			event.dataTransfer.dropEffect = 'copy';
			event.preventDefault();
		}
	}

	// This is to avoid console error when calling DOM event
	gridDrop(event: any) {
		// do nothing
	}

	dropGridData(data: any) {
		let returnRowData = [];
		let removeRowData = [];
		let multiRowData = [];
		multiRowData = this.gridApi.getSelectedRows();
		if (multiRowData != undefined) {
			if (multiRowData.length == 0) {
				// if data missing or data has no it, do nothing
				if (!data || data.id == null) { return; }
				else {
					multiRowData.push(data);
				}
			} else {
				this.gridApi.deselectAll();
			}

			//console.log("Drop Data", multiRowData)

			if (this != undefined) {
				var rowAlreadyInGrid;

				//Remove row data from system grid
				//this.rowData = this.rowData.filter(x => x.id != data.id);

				multiRowData.forEach(row => {
					rowAlreadyInGrid = !!this.sysGridApi!.getRowNode(row.id);
					if (!rowAlreadyInGrid) {
						var returnRow = {
							id: row.id,
							csSystemId: this.getRandomId(),
							sysNo: row.sysNo,
							sysName: row.sysName,
							vendor: row.vendor != null ? row.vendor : this.vendorValueList[0],
							phase: this.phaseValueList[0],
							cdate: "",
							referenceCount: 0,
							deleteSystem: "deleteSystem:"
						}
						returnRowData.push(returnRow);
					} else {
						removeRowData.push(row);
					}
				});
			}

			// show warning if row is already in the grid
			if (removeRowData.length > 0) {
				this.translate.get('CS_ASSIGN_SYSTEMS.ASSIGN_SYS_WARN').subscribe((res: string) => {
					this.alertService.warn(res);
				});
			}

			if (returnRowData.length > 0) {
				var transaction = {
					add: returnRowData
				};
				this.checksheetService.setIsSaved(false);
				this.sysGridApi.applyTransaction(transaction);
			}
		}
	}

	moveAll() {
		let returnRowData = [];
		let removeRowData = [];
		if (this != undefined) {
			var rowAlreadyInGrid;

			//Remove row data from system grid
			//this.rowData = this.rowData.filter(x => x.id != data.id);

			this.rowData.forEach(row => {
				rowAlreadyInGrid = !!this.sysGridApi!.getRowNode(row.id);
				if (!rowAlreadyInGrid) {
					var returnRow = {
						id: row.id,
						csSystemId: this.getRandomId(),
						sysNo: row.sysNo,
						sysName: row.sysName,
						vendor: row.vendor != null ? row.vendor : this.vendorValueList[0],
						phase: this.phaseValueList[0],
						cdate: "",
						referenceCount: 0,
						deleteSystem: "deleteSystem:"
					}
					returnRowData.push(returnRow);
				} else {
					removeRowData.push(row);
				}
			});

			// show warning if row is already in the grid
			if (removeRowData.length > 0) {
				this.translate.get('CS_ASSIGN_SYSTEMS.ASSIGN_SYS_WARN').subscribe((res: string) => {
					this.alertService.warn(res);
				});
			}

			if (returnRowData.length > 0) {
				var transaction = {
					add: returnRowData
				};
				this.checksheetService.setIsSaved(false);
				this.sysGridApi.applyTransaction(transaction);
			}
		}
	}

	@HostListener('window:resize', ['$event'])
	public changeModalSize(event?: any) {
		this.treeheight = (window.innerHeight) * 2 / 3;
	}

	onSuccess(data: WsResponse, serviceType: WsType) {
		if (serviceType == WsType.GET_CS_DATA_BY_ID) {
			if (data.payload != null) {
				this.checksheetNode = data.payload;
				this.treeTitle = this.checksheetNode.checkSheetCode + " - " + this.checksheetNode.checkSheetName;
			}
			this.loadingHandler.handle();
		}
		else if (serviceType == WsType.GET_ACTIVE_SYSTEMS) {
			if (data.payload != null) {
				this.setSystemSearchTableData(data.payload);
			}
			this.loadingHandler.handle();
		}
		else if (serviceType == WsType.GET_ALL_VENDORS) {
			if (data.payload != null) {
				data.payload.forEach(element => {
					this.vendorList.push({ 'id': element.vendorId, 'text': element.vendorName });;
					this.vendorValueList.push(element.vendorName);
				});
			}
			this.loadingHandler.handle();
		}
		else if (serviceType == WsType.GET_ALL_PHASES) {
			if (data.payload != null) {
				data.payload.forEach(element => {
					this.phaseList.push({ 'id': element.phaseId, 'text': element.phaseName });;
					this.phaseValueList.push(element.phaseName);
				});
			}
			this.loadingHandler.handle();
		}
		else if (serviceType == WsType.GET_ASSIGNED_SYSTEMS) {
			this.loadingService.hideLoading();
			if (data.payload != null) {
				this.setAssignedSystemData(data.payload)
			}
			else {
				this.loadingService.hideLoading();
				this.alertService.clear();
				this.translate.get('CS_ASSIGN_SYSTEMS.LOAD_SYS_DATA_FAILED').subscribe((res: string) => {
					this.alertService.error(res);
				});
			}
		}
	}

	onFail(data: WsResponse, serviceType: WsType) {
		if (serviceType == WsType.GET_CS_DATA_BY_ID) {
			this.loadingHandler.handle();
			this.alertService.clear();
			this.alertService.error(data.statusDescription)
		}
		else if (serviceType == WsType.GET_ACTIVE_SYSTEMS) {
			this.loadingHandler.handle();
			this.alertService.clear();
			this.alertService.error(data.statusDescription)
		}
		else if (serviceType == WsType.GET_ALL_VENDORS) {
			this.loadingHandler.handle();
			this.alertService.clear();
			this.alertService.error(data.statusDescription)
		}
		else if (serviceType == WsType.GET_ALL_PHASES) {
			this.loadingHandler.handle();
			this.alertService.clear();
			this.alertService.error(data.statusDescription)
		}
		else if (serviceType == WsType.GET_ASSIGNED_SYSTEMS) {
			this.loadingService.hideLoading();
			this.alertService.clear();
			this.alertService.error(data.statusDescription);
		}
	}

	getRandomId() {
		function s4() {
			return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
		}
		return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
	}

	searchFunc(data: any) {
		this.searchData = data;
		this.sysNoSearch = "";
		this.sysNameSearch = "";
		//this.sysParentSearch = "";

		//assign value for the fields from searchWordMap
		var plsysno = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_SYS_NO];
		if (plsysno != undefined) {
			this.sysNoSearch = plsysno;
		} else {
			//do nothing
		}
		var plsysname = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_SYS_NAME];
		if (plsysname != undefined) {
			this.sysNameSearch = plsysname;
		} else {
			//do nothing
		}
		/*var plsysparent = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_SYS_PARENT];
		if (plsysparent != undefined) {
			this.sysParentSearch = plsysparent;
		} else {
			//do nothing
		}*/

		if (this.sysNoSearch != "" || this.sysNameSearch != "") {
			let to: any = false;
			let dataObj = {}
			dataObj = {
				checksheetId: this.csId,
				systemNo: this.sysNoSearch,
				systemName: this.sysNameSearch
			}
			var json = JSON.stringify(dataObj);
			if (to) { clearTimeout(to); }
			this.loadingService.showLoading(true, null, "Searching", null);
			to = setTimeout(() => {
				this.checksheetService.searchSystemsOnCS(json).subscribe(
					data => {
						this.setSystemSearchTableData(data.payload);
					},
					error => {
						this.loadingService.hideLoading();
						this.alertService.clear()
						this.alertService.error(error.statusDescription)
					});
			}, 200);
		} else {
			this.gridApi.hideOverlay()
		}
	}

	setSystemSearchTableData(payload: any) {
		this.loadingService.hideLoading();
		this.filteredData = [];
		this.rowData = [];
		if (payload != null) {
			for (let i = 0; i < payload.length; i++) {
				this.filteredData.push({
					id: payload[i]["id"],
					sysNo: payload[i]["systemNo"],
					sysName: payload[i]["systemName"],
					vendor: payload[i]["vendorName"]
				});
			}
		}
		this.rowData = this.filteredData;
		this.gridApi.paginationProxy.currentPage = 0;
		this.gridOptions.api.sizeColumnsToFit();
	}

	setAssignedSystemData(payload: any) {
		this.sysFilteredData = [];
		this.sysRowData = [];
		if (payload != null) {
			for (let i = 0; i < payload.length; i++) {
				this.sysFilteredData.push({
					id: payload[i]["systemModel"]["id"],
					csSystemId: payload[i]["checkSheetSystemId"],
					sysNo: payload[i]["systemModel"]["systemNo"],
					sysName: payload[i]["systemModel"]["systemName"],
					vendor: payload[i]["vendorName"] != null ? payload[i]["vendorName"] : null,
					phase: payload[i]["phaseName"] != null ? payload[i]["phaseName"] : null,
					cdate: payload[i]["checkSheetTargetCompletionDate"] != null ? new Date(payload[i]["checkSheetTargetCompletionDate"]).toLocaleDateString() : "",
					referenceCount: payload[i]["dependencyCount"],
					deleteSystem: "deleteSystem:"
				});
			}
		}
		this.sysRowData = this.sysFilteredData;
		this.gridApi.paginationProxy.currentPage = 0;
		this.gridOptions.api.sizeColumnsToFit();
	}

	/**
	 * Get the deleted checksheet systems
	 */
	getDeletedCSSystems() {
		this.deletedSystems = [];
		this.sysFilteredData.forEach(elementF => {
			let isDeleted = true;
			this.savedTableData.forEach(elementS => {
				if (elementF.id == elementS.systemId && elementF.csSystemId == elementS.csSystemId) {
					isDeleted = false;
				} else {
					//do nothing
				}
			});
			if (isDeleted) {
				let deletedSystem = {
					systemId: elementF.id,
					csSystemId: elementF.csSystemId,
					vendorId: null,
					phaseId: null,
					completionDate: null,
					isDeleted: true,
					isAdded: false
				}
				this.deletedSystems.push(deletedSystem);
			}
		});
	}

	/**
	 * Get the added checksheet systems
	 */
	getAddedCSSystems() {
		this.savedTableData.forEach(elementS => {
			let isAdded = true;
			this.sysFilteredData.forEach(elementF => {
				if (elementS.systemId == elementF.id && elementS.csSystemId == elementF.csSystemId) {
					isAdded = false;
				} else {
					//do nothing
				}
			});
			if (isAdded) {
				elementS.isAdded = true;
			}
			else {
				elementS.isAdded = false;
			}
		});
	}

	saveAssignSystems() {
		this.savedTableData = [];
		let saveError: boolean = false;
		this.sysGridApi.forEachNode((rowNode) => {
			let data = rowNode.data;
			if (data.cdate == "") {
				saveError = true;
				this.translate.get('CS_ASSIGN_SYSTEMS.SAVE_SYS_ERROR').subscribe((res: string) => {
					this.alertService.error(res);
				});
				return;
			}
			let modifyData = {
				systemId: data.id,
				csSystemId: data.csSystemId,
				vendorId: this.vendorList.filter(x => x.text == data.vendor)[0].id,
				phaseId: this.phaseList.filter(x => x.text == data.phase)[0].id,
				completionDate: new DatePipe("en-US").transform(new Date(data.cdate), 'yyyy-MM-dd'),
				isDeleted: false,
				isAdded: false
			}
			this.savedTableData.push(modifyData);
		});

		//get the added/deleted checksheet systems
		this.getAddedCSSystems();
		this.getDeletedCSSystems();
		//add deleted checksheet systems to the list
		if (this.deletedSystems.length > 0) {
			this.deletedSystems.forEach(element => {
				this.savedTableData.push(element);
			});
		}

		//console.log("Final Table Data : ", JSON.stringify(this.savedTableData));
		if (!saveError) {
			this.loadingService.showLoading(true, false, 'Saving', 0);
			this.checksheetService.saveCSSystems(this.csId, JSON.stringify(this.savedTableData))
				.subscribe(data => {
					this.checksheetService.setIsSaved(true);
					this.loadingService.hideLoading();
					this.translate.get('CS_ASSIGN_SYSTEMS.ASSIGN_SYS_SUCCESS').subscribe((res: string) => {
						this.alertService.success(res);
					});
					this.loadingService.showLoading(true, false, 'Loading', 0);
					this.checksheetService.getAssignedSystems(this, this.csId);
				},
					error => {
						this.checksheetService.setIsSaved(false);
						this.loadingService.hideLoading();
						this.translate.get('CS_ASSIGN_SYSTEMS.ASSIGN_SYS_FAILED').subscribe((res: string) => {
							this.alertService.error(res);
						});
					}
				);
		}
	}

	//hides the modal
	cancel() {
		if (this.checksheetService.getIsSaved()) {
			this.removeSelectedSystemTagInputs();
			this.bsModalRef.hide();
		}
		else {
			//Show close confirm modal
			this.closeModalRef = this.modalService.show(this.closeConfirmModal);
		}
	}

	closeModal() {
		this.closeModalRef.hide();
	}

	confirmModal() {
		this.checksheetService.setIsSaved(true);
		this.removeSelectedSystemTagInputs();
		this.closeModalRef.hide();
		this.bsModalRef.hide();
	}

	removeSelectedSystemTagInputs() {
		this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.SEARCH_SYS_NO, []);
		this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.SEARCH_SYS_NAME, []);
		//this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.SEARCH_SYS_PARENT, []);
	}

	/**
 * This class handles parallel calling of web services. loadingService initiate the loading service which is required to show and hide functionalities.
 * Then, parallelServiceCount indicate the number of parallel services that we want to call. init() method is used to initialize the handler object.
 * handle() method will be called on onSuccess() and onFail() methods of required service calls. This method will hide the loading view when the counter
 * becomes zero which means no more service response to be arrived.
 */
	static LoadingHandler = class {
		parallelServiceCount: number = 0;
		loadingService: LoadingService = null;
		loaded = new EventEmitter<any>()

		init(parallelServiceCount: number, loadingService: LoadingService) {
			this.parallelServiceCount = parallelServiceCount
			this.loadingService = loadingService;
			this.loadingService.showLoading(true, false, 'Loading data', 0);
		}

		handle() {
			--this.parallelServiceCount;
			if (this.parallelServiceCount == 0) {
				this.loadingService.hideLoading();
				this.loaded.emit("");
			}
		}

		getLoadedSub() {
			return this.loaded;
		}
	}
}

class DatePicker implements ICellEditorComp {
	eInput!: HTMLInputElement;

	// gets called once before the renderer is used
	init(params: ICellEditorParams) {
		// create the cell
		this.eInput = document.createElement('input');
		this.eInput.value = params.value;
		this.eInput.classList.add('ag-input');
		this.eInput.style.height = '100%';

		// https://jqueryui.com/datepicker/
		$(this.eInput).datepicker({
			dateFormat: 'mm/dd/yy',
			onSelect: () => {
				//this.eInput.focus();
				params.api.clearFocusedCell();
			}
		});
	}

	// gets called once when grid ready to insert the element
	getGui() {
		return this.eInput;
	}

	// focus and select can be done after the gui is attached
	afterGuiAttached() {
		this.eInput.focus();
		this.eInput.select();
	}

	// returns the new value after editing
	getValue() {
		return this.eInput.value;
	}

	// any cleanup we need to be done here
	destroy() {
		// but this example is simple, no cleanup, we could
		// even leave this method out as it's optional
	}

	// if true, then this editor will appear in a popup
	isPopup() {
		// and we could leave this method out also, false is the default
		return false;
	}
}
