<div class="container-fluid px-0">
    <div class="row px-3">
        <div class="col-sm-8 px-0">
            <p class="mb-2 h5">
                <b>{{ 'VIDEO_SEARCH_VIEW.HEADER' | translate }}</b>
            </p>
        </div>
        <div class="col-sm-4 px-0">
            <!-- <button *ngIf="changeLayout" type="submit" class="btn btn-primary btn-sm float-right reusable_btn my-1" (click)="newTagButtonClick()">
        <i class="fas fa-plus"></i>
      </button> -->
        </div>
    </div>
    <div class="col-sm-12 px-0">
        <symbiosis-search [comp]="'video'" id="video_search" (searchFunc)="searchFunc($event)"
            (showSearchOptionEE)="showSearchOptionEE($event)" (searchBarEnterFunc)="searchBarEnterFunc($event)">
        </symbiosis-search>
    </div>
    <hr class="my-2" />
  </div>
  
  <div class="container-fluid px-0" style="overflow: auto" [style.height.px]="divHeight">
    <div *ngIf="videoSearchState == constants.LOADING_STATES.LOADING"
        class="alert alert-light-dark-border mx-auto p-2 loading-alert ">
        {{ 'LOADING' | translate }}
    </div>
    <div *ngIf="videoSearchState == constants.LOADING_STATES.EMPTY_RESULT"
        class="alert alert-light-dark-border mx-auto p-2 no-images-alert ">
        {{ 'VIDEO_SEARCH_VIEW.NO_VIDEO_TO_SHOW' | translate }}
    </div>
    <div *ngIf="videoSearchState == constants.LOADING_STATES.HAS_RESULT" class="card-columns flip-card-board">
        <div class="card flip-card" *ngFor="let video of videos; let i=index"
            [ngClass]="{'voted': flippedVideo.id == video.id}">
            <div class="front"
                [ngClass]="{'d-none': flippedVideo.id == video.id, 'd-block': flippedVideo.id != video.id}">

                <img class="card-img-top img-fluid pointer-cursor" [src]="video.thumbUrl"
                (click)="showLargeVideo(largeVideo, video, video.id)" [alt]="'No Thumbnail'">


                <div class="card-body">
                    <p class="card-text dotted-text pointer-cursor" (click)="flipVideo(video)">{{video.title}}</p>
                </div>
            </div>
            <div class="back"
                [ngClass]="{'d-none': flippedVideo.id != video.id, 'd-block': flippedVideo.id == video.id}">
                <img class="card-img-top blur-img img-fluid pointer-cursor"
                    [src]="selectedURL" [alt]="video.title">
                <div class="card-img-overlay text-center" (click)="unFlipVideo()">
                    <button type="button" class="btn btn-sm my-1 btn-primary" (click)="$event.stopPropagation();getVideoInfo(flippedVideo)">
                        {{ 'VIDEO_SEARCH_VIEW.BTN_TEXT_INSERT_AS_VIDEO' | translate }}
                    </button>
                </div>
                <div class="card-body z-index-1050">
                    <p class="card-text dotted-text pointer-cursor z-index-1050" (click)="unFlipVideo()">
                        {{video.title}}</p>
                </div>
            </div>
        </div>
    </div>
  </div>
  
  <ng-template #largeVideo>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{ selectedVideo.title }}</h4>
        <button type="button" id="video_close" class="close pull-right" aria-label="Close" (click)="modelRefCloseEvent()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <video class="card p-0" controls width = "760" height="400" [poster]="selectedVideoPosterframeURL"> 
            <source [src]="selectedVideoURL" type="video/mp4">
        </video>
    </div>
    <div class="row mt-2 ml-3">
        <h6>Path: </h6>
        <p class="folder-path ml-1" >{{ selectedVideo.folderPath }}{{ selectedVideo.title }}</p>
    </div>
    <div class="modal-footer" height="100">
        <button type="button" class="btn btn-sm btn-primary mr-auto" (click)="goToVideoLocation()">Go to Path</button>
        <button type="button" class="btn btn-sm btn-secondary" (click)="modelRefCloseEvent()">{{
                'VIDEO_SEARCH_VIEW.BTN_TEXT_CLOSE' | translate }}</button>
        <button type="button" class="btn btn-sm btn-primary" (click)="getVideoInfo(selectedVideo)">{{
                'VIDEO_SEARCH_VIEW.BTN_TEXT_INSERT_AS_VIDEO' |
                translate }}</button>
    </div>
  </ng-template>
  
  <ng-template #popTemplate>
    <div class="card col-sm-8 rounded-0 p-0 border-0 mt-2 mb-1 mx-auto">
        <div class="card-body row p-2">
            <button type="button" class="btn btn-sm btn-outline-dark btn-block m-2"
                (click)="getVideoInfo(selectedVideo)">{{
                    'VIDEO_SEARCH_VIEW.BTN_TEXT_INSERT_AS_VIDEO' | translate }}</button>
        </div>
    </div>
  </ng-template>