import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService } from 'src/app/util/alert/alert.service';
import { OptDataService } from '../opt-data.service';
import { ConfirmCallback } from './confirm-callback';

@Component({
  selector: 'app-confirm-model',
  templateUrl: './confirm-model.component.html',
  styleUrls: []
})
export class ConfirmModelComponent implements OnInit {

  public token;
  public attributeId;
  public confirmationTitle: string;
  public confirmationMessage: string;
  public callBack: ConfirmCallback;

  ngOnInit() {
  }

  constructor(public bsModalRef: BsModalRef, private alertService: AlertService, private optService: OptDataService) { }

  confirm(): void {
    this.callBack.onDeleteConfirm(this.attributeId);
  }

  decline(): void {
    this.callBack.onDeleteDecline();
  }

}
