<div class="main-panel pt-0 pb-4" style="overflow: hidden;">
	<div class="container-fluid">
		<div class="col-sm-12 px-0">
			<h3>
				{{ 'OUTPUT_MANAGEMENT.OM_SIDEBAR_PROJECT_TEMPLATE' | translate }}
			</h3>
			<hr class="mt-0 mb-3" />
		</div>
		<div class="col-sm-12 px-0" [ngClass]="{'content-hide': isMinimized, 'content-show': !isMinimized}">
			<ag-grid-angular #agGrid style="width: 100%;" [style.height.px]="divHeight" id="addProjectTem" [rowData]="rowData" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }"
				class="ag-theme-balham" [columnDefs]="columnDefs" [frameworkComponents]="frameworkComponents"
				  [enableRangeSelection]="true" [paginationPageSize]="10" [defaultColDef]="defaultColDef"
				[pagination]="true" (columnResized)="onColumnResized($event)" (gridReady)="onGridReady($event)" [getRowNodeId]="getRowNodeId"
				(cellValueChanged)="onCellValueChanged($event)" [suppressMenuHide]="true" [gridOptions]="gridOptions">
			</ag-grid-angular>
		</div>
	</div>
</div>
<!-- <div class="form-inline my-2 my-lg-0 float-center">
      <div class="my-2 my-sm-0 mr-1 ml-2">
         <button type="button" (click)="addTemplate()" [disabled]="!manageProjectTemplates" id="OM_addTemplate"
            class="btn btn-outline-primary btn-sm ml-1 mr-1">
            <i class="fa fa-plus-square" aria-hidden="true"></i> Add Template
         </button>
      </div>
   </div>
   <div class="card-block card-body mt-2 pb-1 pt-1 card-primary"
      [ngClass]="{'content-hide': isMinimized, 'content-show': !isMinimized}">
      <ag-grid-angular #agGrid style="width: 100%; height: 400px;" id="addProjectTem" [rowData]="rowData"  [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }"
         class="ag-theme-balham" [columnDefs]="columnDefs" [frameworkComponents]="frameworkComponents"
           [enableRangeSelection]="true" [paginationPageSize]="10"
         [pagination]="true" (columnResized)="onColumnResized($event)" (gridReady)="onGridReady($event)"
         (cellValueChanged)="onCellValueChanged($event)" [suppressMenuHide]="true" [gridOptions]="gridOptions">
      </ag-grid-angular>
   </div>
</div> -->