<header class="slide-panel-header">
  <span class="slide-action" *ngIf="assignRoleCapabilities" (click)="pageToggle()">
    <i class="fal fa-plus"></i>
  </span>
  <div class="panel-details">
    <h5 class="title mb-1 pl-2">Capabilities</h5>
    <ul class="list-group list-group-horizontal link-list action-list text-uppercase">
      <li class="list-group-item"><a (click)="selectAll()" class="cllapse-link">Select all</a></li>
      <li class="list-group-item"><a (click)="deselectAll()" class="cllapse-link">Deselect all</a></li>
      <li class="list-group-item"><a (click)="collapseAll($event)"  class="cllapse-link">Collapse all</a></li>
    </ul>
  </div>
</header>

<div class="container right-slide-panelcontent">
  <div class="accordion" id="accordionExample">
    <div class="card collapse-panel">
      <div class="card-header header" id="headingOne">
        <h2 class="mb-0">
          <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#SystemCapabilitiesCollapse"
            aria-expanded="true" aria-controls="SystemCapabilitiesCollapse">
            System Capabilities
          </button>
        </h2>
      </div>

      <div id="SystemCapabilitiesCollapse" class="collapse  show" aria-labelledby="headingOne" data-parent="#accordionExample">
        <div *ngFor="let cat of this.systemCatList; let i = index" class="card-body inner-collapse border-0">
          <div class="card-header header">
            <h2 class="mb-0">
              <button class="btn btn-link collapse-btn pl-2" type="button" data-toggle="collapse"
              [attr.data-target]="'#SystemCapabilitiesCollapseLevel2-' + cat.id"  aria-expanded="true" aria-controls="SystemCapabilitiesCollapseLevel2">
                {{cat.category}}  
              </button>
            </h2>
          </div>
           
          <div class="collapse show" [attr.id]="'SystemCapabilitiesCollapseLevel2-' + cat.id">
            <div class="card card-body border-0 pl-0 pr-0">
              <div class="row switch-row m-2">
                 
                
                <div *ngFor="let cap of this.userRolesComponent.systemCapbilityList; let j = index">
                  <div *ngIf = "cap.category == cat.category;" class="col-12 col-md-12 mb-2 p-0 switch-item">
                    <div class="switch-wrapper mr-2">
                      {{cap.title}}
                      <ui-switch switchColor="#fcfcfc" uncheckedLabel="off" [checked]="cap.added" (change)="onChange($event,j,cap.id,cap.scope)" size="small"></ui-switch>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>        
      </div>
    </div>

    <div class="card collapse-panel">
      <div class="card-header header" id="headingOne">
        <h2 class="mb-0">
          <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#ClientCapabilitiesCollapse"
            aria-expanded="true" aria-controls="ClientCapabilitiesCollapse">
            Client Capabilities
          </button>
        </h2>
      </div>

      <div id="ClientCapabilitiesCollapse" class="collapse  show" aria-labelledby="headingOne" data-parent="#accordionExample">
        <div *ngFor="let cat of this.clientCatList; let i = index" class="card-body inner-collapse border-0">
          <div class="card-header header">
            <h2 class="mb-0">
              <button class="btn btn-link collapse-btn pl-2" type="button" data-toggle="collapse"
              [attr.data-target]="'#ClientCapabilitiesCollapseLevel2-' + cat.id"  aria-expanded="true" aria-controls="ClientCapabilitiesCollapseLevel2">
                {{cat.category}}
              </button>
            </h2>
          </div>

          <div class="collapse show" [attr.id]="'ClientCapabilitiesCollapseLevel2-' + cat.id">
            <div class="card card-body border-0 pl-0 pr-0">
              <div class="row switch-row m-2">                
                <div *ngFor="let cap of this.userRolesComponent.clientCapbilityList; let j = index">
                  <div *ngIf = "cap.category == cat.category;" class="col-12 col-md-12 mb-2 p-0 switch-item">
                    <div class="switch-wrapper mr-2">
                    {{cap.title}}
                    <ui-switch switchColor="#fcfcfc" uncheckedLabel="off" [checked]="cap.added" (change)="onChange($event,j,cap.id,cap.scope)" size="small"></ui-switch>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>        
      </div>
    </div>

    <div class="card collapse-panel">
      <div class="card-header header" id="headingOne">
        <h2 class="mb-0">
          <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#ProjectCapabilitiesCollapse"
            aria-expanded="true" aria-controls="ProjectCapabilitiesCollapse">
            Project Capabilities
          </button>
        </h2>
      </div>

      <div id="ProjectCapabilitiesCollapse" class="collapse  show" aria-labelledby="headingOne" data-parent="#accordionExample">
        <div *ngFor="let cat of this.projectCatList; let i = index" class="card-body inner-collapse border-0">
          <div class="card-header header">
            <h2 class="mb-0">
              <button class="btn btn-link collapse-btn pl-2" type="button" data-toggle="collapse"
              [attr.data-target]="'#ProjectCapabilitiesCollapseLevel2-' + cat.id"  aria-expanded="true" aria-controls="ProjectCapabilitiesCollapseLevel2">
                {{cat.category}}
              </button>
            </h2>
          </div>

          <div class="collapse show" [attr.id]="'ProjectCapabilitiesCollapseLevel2-' + cat.id" >
            <div class="card card-body border-0 pl-0 pr-0">
              <div class="row switch-row m-2">
                
                <div *ngFor="let cap of this.userRolesComponent.projectCapbilityList; let j = index">
                  <div *ngIf = "cap.category == cat.category;" class="col-12 col-md-12 mb-2 p-0 switch-item">
                    <div class="switch-wrapper mr-2">
                    {{cap.title}}
                    <ui-switch switchColor="#fcfcfc" uncheckedLabel="off" [checked]="cap.added" (change)="onChange($event,j,cap.id,cap.scope)" size="small"></ui-switch>
                    </div>
                  </div>
                </div>
               
              </div>
            </div>
          </div>
        </div>        
      </div>
    </div>

<footer class="slide-panel-footer">
  <div class="row">
    <div class="col">
      <ul class="list-group list-group-horizontal">
        <li class="list-group-item">
          <div class="switch-wrapper-individual mt-1 left">

            <ui-switch switchColor="#fcfcfc" uncheckedLabel="off" [checked]="this.userRolesComponent.applyAll" (change)="onChangeApplyToAll($event)" size="small"></ui-switch>
            Apply to all Clients/Projects
          </div>
        </li>
      </ul>
    </div>
    <div class="col">
      <ul class="list-group list-group-horizontal float-right">
        <li class="list-group-item">
          <button type="submit" id="punchlist_save" class="btn  btn-custom lgRole float-right" (click)="saveCapability()">Save</button>
          <button type="button" id="change_cap_cancel" class="btn  btn-custom lgRole float-right"
          (click)="pageToggle()">{{ 'CANCEL' | translate}}</button>
        </li>
      </ul>
    </div>
  </div>



</footer>