<div class="modal-header py-2">
    <h5 class="modal-title">
        {{ 'MANAGE_ADHOC_REPORTS.ADD_REPORT' | translate }}
    </h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body" id="irnaddedit">
    <form>
        <fieldset [disabled]="disableform">
            <div class="form-group mb-2">
                <div class="mt-4 dropdown-100 ">
                    <div class="form-group">
                        <label>{{ 'MANAGE_ADHOC_REPORTS.REPORT_NAME' | translate }}
                        </label>
                        <div class="badge-required mx-2"></div>
                        <input type="text" class="form-control form-control-sm" id="reportName" name="reportName"
                            [(ngModel)]="reportName">
                    </div>
                </div>
                <div class="mt-4 dropdown-100 ">
                    <div class="form-group">
                        <label>{{ 'MANAGE_ADHOC_REPORTS.CATEGORY' | translate }}
                        </label>
                        <div class="badge-required mx-2"></div>
                        <input type="text" class="form-control form-control-sm" id="category" name="category"
                            [(ngModel)]="category">
                    </div>
                </div>
                <div class="mt-2 dropdown-100 mb-2">
                    <div class="form-group">
                        <label for="addmodule" class="mb-1">{{ 'MANAGE_ADHOC_REPORTS.MODULE' | translate
                            }}</label>
                        <div class="badge-required mx-2"></div>
                        <tag-input name="addmodule" id="addmodule" [(ngModel)]='selectedModuleList'
                            [disable]="disableform" [removable]='true'
                            secondaryPlaceholder="{{'MANAGE_ADHOC_REPORTS.SELECT_REPORT_MODULE' | translate}}"
                            placeholder="{{'MANAGE_ADHOC_REPORTS.SELECT_REPORT_MODULE' | translate}}"
                            [onlyFromAutocomplete]="true" [clearOnBlur]="true">
                            <tag-input-dropdown [showDropdownIfEmpty]="true" [keepOpen]="false"
                                [autocompleteItems]="moduleList" [limitItemsTo]=20 [appendToBody]="false">
                            </tag-input-dropdown>
                        </tag-input>
                    </div>
                </div>

                <!--JRXML File upload-->
                <div class="form-group">
                    <label for="JRXMLFile_FileUpload">{{ 'MANAGE_ADHOC_REPORTS.JRXML_FILE' | translate }}<div
                            class="badge-required mx-2">
                        </div></label>
                    <div class="d-flex">
                        <input #jrxmlInput type="file" class="form-control-file" id="JRXMLFile_FileUpload" accept=".jrxml"
                            (change)="onJRXMLFileChanged($event)" required>
                        <button *ngIf="jrxmlFile != null" type="button" class="close float-right" data-dismiss="modal" aria-label="Close"
                            (click)="clearFileInput('jrxml')">
                            <span aria-hidden="true"><i class="fas fa-backspace"></i></span>
                        </button>
                    </div>
                </div>
                <!--CSV File upload-->
                <div class="form-group">
                    <label for="CSVFile_FileUpload">{{ 'MANAGE_ADHOC_REPORTS.CSV_FILE' | translate }}</label>
                    <div class="d-flex">
                        <input #csvInput type="file" class="form-control-file" id="CSVFile_FileUpload" accept=".csv"
                            (change)="onCSVFileChanged($event)">
                        <button *ngIf="csvFile != null" type="button" class="close float-right" data-dismiss="modal" aria-label="Close"
                            (click)="clearFileInput('csv')">
                            <span aria-hidden="true"><i class="fas fa-backspace"></i></span>
                        </button>
                    </div>
                </div>
                <!--XML File upload-->
                <div class="form-group">
                    <label for="XMLFile_FileUpload">{{ 'MANAGE_ADHOC_REPORTS.XML_FILE' | translate }}
                        <div *ngIf="!disableXMLInput" class="badge-required mx-2">
                        </div>
                    </label>
                    <div class="d-flex">
                        <input #xmlInput type="file" [disabled]="disableXMLInput" class="form-control-file"
                            id="XMLFile_FileUpload" accept=".xml" (change)="onXMLFileChanged($event)" required>
                        <button  *ngIf="xmlFile != null" type="button" class="close float-right" data-dismiss="modal" aria-label="Close"
                            (click)="clearFileInput('xml')">
                            <span aria-hidden="true"><i class="fas fa-backspace"></i></span>
                        </button>
                    </div>
                </div>
            </div>
        </fieldset>
    </form>
</div>
<div class="modal-footer">
    <button type="button" id="addreport_cancel" class="btn btn-secondary btn-sm float-left" (click)="cancel()">{{
        'CANCEL'|
        translate}}</button>
    <button *ngIf="!disableform" type="submit" id="savereport" class="btn btn-primary btn-sm float-left"
        (click)="save()">{{ 'MANAGE_ADHOC_REPORTS.SAVE' | translate}}</button>
</div>