<div class="my-login-page" data-gr-c-s-loaded="true">
  <section class="h-100">
    <div class="container h-100">
      <div class="row justify-content-md-center h-100">
        <div class="card-wrapper">
          <div class="mt-3 mb-3">
            <img src="../../../assets/images/mainimage.png" alt="" width="100%" height="auto">
          </div>
          <div class="card fat">
            <div class="card-body">
              <h4 class="card-title">{{ 'FORGOT_PASSWORD.FORGOT_PASSWORD_TITLE' | translate}}</h4>
              <p>{{ 'FORGOT_PASSWORD.CLICK' | translate}}
                <a data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                  <b>{{ 'FORGOT_PASSWORD.HERE' | translate}}</b>
                </a> {{ 'FORGOT_PASSWORD.TO_SEE_THE_PASSWORD_RESTRICTIONS' | translate}}</p>
              <div class="collapse" id="collapseExample">
                <div class="form-group">
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item pr-1 pl-1">{{ 'FORGOT_PASSWORD.RESTRICTIONS.YOUR_PASSWORD_MUST_CONTAIN_AT_LEAST' | translate}}
                      <strong>{{ 'FORGOT_PASSWORD.RESTRICTIONS.SIX_CHARACTERS' | translate}}</strong>
                    </li>
                    <li class="list-group-item pr-1 pl-1">{{ 'FORGOT_PASSWORD.RESTRICTIONS.YOUR_PASSWORD_MUST_CONTAIN_AT_LEAST' | translate}}
                      <strong>{{ 'FORGOT_PASSWORD.RESTRICTIONS.ONE_NUMBER' | translate}}</strong>
                    </li>
                    <li class="list-group-item pr-1 pl-1">{{ 'FORGOT_PASSWORD.RESTRICTIONS.YOUR_PASSWORD_MUST_CONTAIN_AT_LEAST' | translate}}
                      <strong>{{ 'FORGOT_PASSWORD.RESTRICTIONS.ONE_LOWER_CASE_LETTER' | translate}}</strong>
                    </li>
                    <li class="list-group-item pr-1 pl-1">{{ 'FORGOT_PASSWORD.RESTRICTIONS.YOUR_PASSWORD' | translate}}
                      <strong>{{ 'FORGOT_PASSWORD.RESTRICTIONS.CANNOT_CONTAIN' | translate}}</strong> {{ 'FORGOT_PASSWORD.RESTRICTIONS.TWO_ CONSECUTIVE_LETTERS' | translate}}
                    </li>
                    <li class="list-group-item pr-1 pl-1">{{ 'FORGOT_PASSWORD.RESTRICTIONS.YOUR_PASSWORD_MUST_CONTAIN_AT_LEAST' | translate}}
                      <strong>{{ 'FORGOT_PASSWORD.RESTRICTIONS.ONE_UPPER_CASE_LETTER' | translate}}</strong>
                    </li>
                  </ul>
                </div>
              </div>
              <form method="POST">
                <div class="form-group">
                  <label for="newPassword">{{ 'FORGOT_PASSWORD.NEW_PASSWORD' | translate}}</label>
                  <input id="forgotpassword_newPassword" [(ngModel)]="newPassword" type="password" class="form-control" name="newPassword" value="" required="" autofocus="">
                </div>
                <div class="form-group">
                  <label for="confirmPassword">{{ 'FORGOT_PASSWORD.CONFIRM_PASSWORD' | translate}}</label>
                  <input id="forgotpassword_confirmPassword" [(ngModel)]="confirmPassword" type="password" class="form-control" name="confirmPassword" value="" required="" autofocus="">
                </div>
                <div class="form-group">
                  <alert></alert>
                </div>
                <div class="form-group mt-4 mb-3">
                  <button id="forgotpassword_submit" type="submit" class="btn btn-primary btn-block mb-3" (click)="resetPassword()">
                      {{ 'SUBMIT' | translate}}
                  </button>
                  <button id="forgotpassword_cancel" class="btn btn-secondary btn-block mt-3" (click)="cancelForgotPassword()">
                      {{ 'CANCEL' | translate}}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="footer">
            2018 © AXIS. All Rights Reserved
          </div>
        </div>
      </div>
    </div>
  </section>
</div>