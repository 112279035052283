<div>
  <div class="loading-panel center">
    <div class="center">
      <tr>
        <img src="../../../assets/images/mainimage.png" alt="" width="auto" height="auto">
      </tr>
      <tr>
        <h5 class="center" style="color: red;">Oops!, requested page cannot not find.</h5>
      </tr>
    </div>
  </div>
</div>