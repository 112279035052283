import {Component, TemplateRef, ViewChild} from "@angular/core";
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import {SharedService} from "../../../util/shared.service";
import {Constants} from "../../../util/constants";
import {DependenciesmodalComponent} from "../../../util/dependenciesmodal/dependenciesmodal.component";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {TranslateService} from "@ngx-translate/core";
import {IrnService} from "../irn.service";
import {LoadingService} from "../../../util/loading/loading.service";

@Component({
	selector: 'child-cell',
	templateUrl: './irn-punchlist-cell-render-component.component.html'

	// template: '<div >'+
	// 	'<button id="landing-table-buttons" class="btn btn-sm btn-outline-dark py-0 my-1 px-1 border-0" (click)="delete()">'+
	// 	'<i class="far fa-trash-alt" aria-hidden="true"></i>'+
	// 	'</button>'


})
export class IrnPunchlistsCellRenderer implements ICellRendererAngularComp {
	public params: any;
	public documents: any[] = [];
	punchlistID:any
	bsModalRef: BsModalRef;
	warnModalRef: BsModalRef;
	@ViewChild('confirmation_warn') confirmationWarn: TemplateRef<any>;
	constructor(private sharedService: SharedService,private bsModalService: BsModalService, private translate: TranslateService,
				private irnService: IrnService,private loadingService: LoadingService,) {
}
	agInit(params: any): void {
		this.params = params;
		this.documents = this.params.data.documents;
		let items = params.value.split(':')
		if (items.length >= 2) {
			this.punchlistID = items[1];

		}
	}

	showConfirm(){
		this.warnModalRef = this.bsModalService.show(this.confirmationWarn);
	}
	public delete() {

		this.loadingService.showLoading(true, false, 'deleting punchlist', 0);
		this.irnService.deletePunchlist(this.punchlistID)

	}

	refresh(): boolean {
		return false;
	}

}
