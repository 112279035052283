import { Component, OnInit, EventEmitter } from '@angular/core';
import { CapabilityService } from 'src/app/util/capability.service';

@Component({
	selector: 'app-ps-sidebar',
	templateUrl: './ps-sidebar.component.html',
	styleUrls: ['./ps-sidebar.component.scss'],
	outputs: ['selectSideBarItem']
})
export class PsSidebarComponent implements OnInit {

	public selectedMenuItem: string = 'procedurestore'
	selectSideBarItem = new EventEmitter<any>()

	public addProcedureToStore: boolean = false;
	public viewProcedureInstanceOutputList: boolean = false

	constructor(private capabilityService: CapabilityService) { 
		this.addProcedureToStore = this.capabilityService.isCapabilityAssigned("addProcedureToStore");
		this.viewProcedureInstanceOutputList = this.capabilityService.isCapabilityAssigned("viewProcedureInstanceOutputList")
	}

	ngOnInit() {
	}

	public sideItemClick(selectedItem: string) {
		this.selectedMenuItem = selectedItem
		this.selectSideBarItem.emit(
			{
				selectedItem: selectedItem
			}
		)
	}

}
