import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '../../../util/alert/alert.service';
import { SharedService } from '../../../util/shared.service';
import { GridOptions, Module, AllModules } from "@ag-grid-enterprise/all-modules";
import { CapabilityService } from '../../../util/capability.service'
import { BsModalService } from 'ngx-bootstrap/modal';
import { OutputManagementService } from '../outputmanagement.service'
import { LoadingService } from '../../../util/loading/loading.service';

@Component({
   selector: 'app-outputqueue',
   templateUrl: './outputqueue.component.html',
   styleUrls: ['./outputqueue.component.css']
})

export class OutputQueueComponent implements OnInit {
   public isMinimized: boolean = false
   rowData: any[] = [];
   private gridApi;
   private gridColumnApi;
   columnDefs;
   public frameworkComponents;
   public gridOptions: GridOptions;
   public defaultColDef: any;
   om_translated_data: any = null;
   loadingMessage: string;
   public modules: Module[] = AllModules;

   constructor(private outputManagementService: OutputManagementService, private sharedService: SharedService, private translate: TranslateService, private alertService: AlertService,
      private capabilityService: CapabilityService, private modalService: BsModalService, private router: Router, private loadingService: LoadingService) {

      let browserLang = translate.getBrowserLang();
      this.gridOptions = <GridOptions>{
         context: {
            componentParent: this
         }
      };
      this.defaultColDef = {
			resizable: true,
			sortable: true,
		};
      this.translate.get('OUTPUT_MANAGEMENT.LOADING_MESSAGE').subscribe((res: string) => {
         this.loadingMessage = res;
      });         
      
      translate.getTranslation(browserLang).subscribe((res: string) => {
         this.om_translated_data = res['OUTPUT_MANAGEMENT']
         this.columnDefs = [            
            {
               headerName: this.om_translated_data.DOCUMENT_NAME, field: 'documentName', autoHeight: true, cellClass: "cell-wrap-text"
            },
            {
               headerName: this.om_translated_data.ITEM_TYPE, field: 'itemType', autoHeight: true, cellClass: "cell-wrap-text"
            },
            {
               headerName: this.om_translated_data.ADDED_DATE, field: 'addedTime', autoHeight: true, cellClass: "cell-wrap-text"
            },
            {
               headerName: this.om_translated_data.INITIATED_USER, field: 'initiatedUserFullName', autoHeight: true, cellClass: "cell-wrap-text"
            },
            {
               headerName: this.om_translated_data.PRIORITY, field: 'priority', autoHeight: true, cellClass: "cell-wrap-text",
               editable: true,
					cellEditor: 'agRichSelectCellEditor',
					cellEditorParams: {
						values: [1,2,3,4,5]
					},
					singleClickEdit: true               
            },
            {
               headerName: this.om_translated_data.DRAFT, field: 'draftDocument', autoHeight: true, cellClass: "cell-wrap-text"
            },
            {
               headerName: this.om_translated_data.STATUS, field: 'processStatus', autoHeight: true, cellClass: "cell-wrap-text"
            },           
         ]
      });
     
   }

   ngOnInit() {     
   }

   onCellValueChanged(params){      
      let id = params.data.id
      let processStatus = params.data.processStatus
      let newValue = params.newValue
      this.changePriority(id, processStatus, newValue)
   }

   onColumnResized(event) {
      if (event.finished) {
         this.gridApi.resetRowHeights();
      }
   }

   onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      params.api.sizeColumnsToFit();
      this.getOutputModelsByProcessStatusList();
      setTimeout(function () {
         params.api.sizeColumnsToFit();
         params.api.resetRowHeights();
      }, 500);
   }

   public refresh(){
      this.loadingService.showLoading(true, null, this.loadingMessage, null)
      this.outputManagementService.getOutputModelsByProcessStatusList()
         .subscribe(data => {
            this.loadingService.hideLoading()
            this.rowData = []
            this.rowData = data.payload;
         },
            error => {
               this.loadingService.hideLoading()
               this.alertService.clear()
               this.alertService.error(error.statusDescription)
            });
   }

   public changePriority(id, processStatus, newValue) {
      this.loadingService.showLoading(true, null, this.loadingMessage, null)
      var editedValue = {
         "id": id,
         "processStatus": processStatus ,
         "priority": newValue
      }
      this.outputManagementService.changePriority(editedValue)
         .subscribe(data => {
            this.loadingService.hideLoading()
            this.rowData = []
            this.refresh()
         },
            error => {
               this.loadingService.hideLoading()
               this.alertService.clear()
               this.alertService.error(error.statusDescription)
            });
   }

   public getOutputModelsByProcessStatusList() {
      this.loadingService.showLoading(true, null, this.loadingMessage, null)
      this.outputManagementService.getOutputModelsByProcessStatusList()
         .subscribe(data => {
            this.loadingService.hideLoading()
            this.rowData = []
            this.rowData = data.payload;
         },
            error => {
               this.loadingService.hideLoading()
               this.alertService.clear()
               this.alertService.error(error.statusDescription)
            });
   }

   public refreshTable() {
      this.refresh()
   }
        
}

