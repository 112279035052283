import { Component, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
    selector: 'child-cell',
    templateUrl: './delete-cell.component.html'

})
export class ManageAdhocReportDeleteCellComponent {

    public params: any;
    public deleteModalRef: BsModalRef;
    @ViewChild("deleteConfirmModal") deleteConfirmModal: TemplateRef<any>;

    constructor( private bsModalService: BsModalService) {
        
    }

    agInit(params: any): void {
        this.params = params;
    }


    public delete() {
        this.deleteModalRef = this.bsModalService.show(this.deleteConfirmModal);
    }

    public deleteConfirm() {
        this.bsModalService.hide();
        this.params.context.componentParent.deleteReport(this.params.data.id);
    }

    refresh(): boolean {
        return false;
    }

}
