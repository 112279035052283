<div class="container-fluid px-0">
  <div class="col-sm-12 px-0">
      <p class="mb-2 h6">
          <b>Search Image</b>
      </p>
  </div>
  <div class="col-sm-12 px-0">
      <symbiosis-search [comp]="'image'" (searchFunc)="searchFunc($event)" (showSearchOptionEE)="showSearchOptionEE($event)" 
      (searchBarEnterFunc)="searchBarEnterFunc($event)" ></symbiosis-search>
  </div>
  <hr class="my-2" />
  <div class="row mt-2" style="overflow: auto" [style.height.px]="divHeight">
      <div class="my-1" [ngClass]="{'col-sm-2': changeLayout, 'col-sm-4': !changeLayout}">
          <div class="card p-0">
              <img class="card-img-top pointer-cursor" src="../../../assets/images/symbiosis-images/1.png" alt="{{ imageName }}"
                  (click)="showLargeImage(largeImage)">
              <div class="card-body p-1">
                  <p class="card-text">
                      {{ imageName }}
                  </p>
              </div>
          </div>
      </div>
      <div class="my-1" [ngClass]="{'col-sm-2': changeLayout, 'col-sm-4': !changeLayout}">
          <div class="card p-0">
              <img class="card-img-top pointer-cursor" src="../../../assets/images/symbiosis-images/2.png" alt="{{ imageName }}"
                  style="height: auto">
              <div class="card-body p-1">
                  <p class="card-text">
                      {{ imageName }}
                  </p>
              </div>
          </div>
      </div>
      <div class="my-1" [ngClass]="{'col-sm-2': changeLayout, 'col-sm-4': !changeLayout}">
          <div class="card p-0">
              <img class="card-img-top" src="../../../assets/images/symbiosis-images/3.png" alt="{{ imageName }}">
              <div class="card-body p-1">
                  <p class="card-text">
                      {{ imageName }}
                  </p>
              </div>
          </div>
      </div>
      <div class="my-1" [ngClass]="{'col-sm-2': changeLayout, 'col-sm-4': !changeLayout}">
          <div class="card p-0">
              <img class="card-img-top" src="../../../assets/images/symbiosis-images/4.png" alt="{{ imageName }}">
              <div class="card-body p-1">
                  <p class="card-text">
                      {{ imageName }}
                  </p>
              </div>
          </div>
      </div>
      <div class="my-1" [ngClass]="{'col-sm-2': changeLayout, 'col-sm-4': !changeLayout}">
          <div class="card p-0">
              <img class="card-img-top" src="../../../assets/images/symbiosis-images/5.jpg" alt="{{ imageName }}">
              <div class="card-body p-1">
                  <p class="card-text">
                      {{ imageName }}
                  </p>
              </div>
          </div>
      </div>
      <div class="my-1" [ngClass]="{'col-sm-2': changeLayout, 'col-sm-4': !changeLayout}">
          <div class="card p-0">
              <img class="card-img-top" src="../../../assets/images/symbiosis-images/6.png" alt="{{ imageName }}">
              <div class="card-body p-1">
                  <p class="card-text">
                      {{ imageName }}
                  </p>
              </div>
          </div>
      </div>
      <div class="my-1" [ngClass]="{'col-sm-2': changeLayout, 'col-sm-4': !changeLayout}">
          <div class="card p-0">
              <img class="card-img-top" src="../../../assets/images/symbiosis-images/1.png" alt="{{ imageName }}">
              <div class="card-body p-1">
                  <p class="card-text">
                      {{ imageName }}
                  </p>
              </div>
          </div>
      </div>
      <div class="my-1" [ngClass]="{'col-sm-2': changeLayout, 'col-sm-4': !changeLayout}">
          <div class="card p-0">
              <img class="card-img-top" src="../../../assets/images/symbiosis-images/2.png" alt="{{ imageName }}"
                  style="height: auto">
              <div class="card-body p-1">
                  <p class="card-text">
                      {{ imageName }}
                  </p>
              </div>
          </div>
      </div>
      <div class="my-1" [ngClass]="{'col-sm-2': changeLayout, 'col-sm-4': !changeLayout}">
          <div class="card p-0">
              <img class="card-img-top" src="../../../assets/images/symbiosis-images/3.png" alt="{{ imageName }}">
              <div class="card-body p-1">
                  <p class="card-text">
                      {{ imageName }}
                  </p>
              </div>
          </div>
      </div>
      <div class="my-1" [ngClass]="{'col-sm-2': changeLayout, 'col-sm-4': !changeLayout}">
          <div class="card p-0">
              <img class="card-img-top" src="../../../assets/images/symbiosis-images/4.png" alt="{{ imageName }}">
              <div class="card-body p-1">
                  <p class="card-text">
                      {{ imageName }}
                  </p>
              </div>
          </div>
      </div>
      <div class="my-1" [ngClass]="{'col-sm-2': changeLayout, 'col-sm-4': !changeLayout}">
          <div class="card p-0">
              <img class="card-img-top" src="../../../assets/images/symbiosis-images/5.jpg" alt="{{ imageName }}">
              <div class="card-body p-1">
                  <p class="card-text">
                      {{ imageName }}
                  </p>
              </div>
          </div>
      </div>
      <div class="my-1" [ngClass]="{'col-sm-2': changeLayout, 'col-sm-4': !changeLayout}">
          <div class="card p-0">
              <img class="card-img-top" src="../../../assets/images/symbiosis-images/6.png" alt="{{ imageName }}">
              <div class="card-body p-1">
                  <p class="card-text">
                      {{ imageName }}
                  </p>
              </div>
          </div>
      </div>
      <div class="my-1" [ngClass]="{'col-sm-2': changeLayout, 'col-sm-4': !changeLayout}">
          <div class="card p-0">
              <img class="card-img-top" src="../../../assets/images/symbiosis-images/1.png" alt="{{ imageName }}">
              <div class="card-body p-1">
                  <p class="card-text">
                      {{ imageName }}
                  </p>
              </div>
          </div>
      </div>
      <div class="my-1" [ngClass]="{'col-sm-2': changeLayout, 'col-sm-4': !changeLayout}">
          <div class="card p-0">
              <img class="card-img-top" src="../../../assets/images/symbiosis-images/2.png" alt="{{ imageName }}"
                  style="height: auto">
              <div class="card-body p-1">
                  <p class="card-text">
                      {{ imageName }}
                  </p>
              </div>
          </div>
      </div>
      <div class="my-1" [ngClass]="{'col-sm-2': changeLayout, 'col-sm-4': !changeLayout}">
          <div class="card p-0">
              <img class="card-img-top" src="../../../assets/images/symbiosis-images/3.png" alt="{{ imageName }}">
              <div class="card-body p-1">
                  <p class="card-text">
                      {{ imageName }}
                  </p>
              </div>
          </div>
      </div>
      <div class="my-1" [ngClass]="{'col-sm-2': changeLayout, 'col-sm-4': !changeLayout}">
          <div class="card p-0">
              <img class="card-img-top" src="../../../assets/images/symbiosis-images/4.png" alt="{{ imageName }}">
              <div class="card-body p-1">
                  <p class="card-text">
                      {{ imageName }}
                  </p>
              </div>
          </div>
      </div>
      <div class="my-1" [ngClass]="{'col-sm-2': changeLayout, 'col-sm-4': !changeLayout}">
          <div class="card p-0">
              <img class="card-img-top" src="../../../assets/images/symbiosis-images/5.jpg" alt="{{ imageName }}">
              <div class="card-body p-1">
                  <p class="card-text">
                      {{ imageName }}
                  </p>
              </div>
          </div>
      </div>
      <div class="my-1" [ngClass]="{'col-sm-2': changeLayout, 'col-sm-4': !changeLayout}">
          <div class="card p-0">
              <img class="card-img-top" src="../../../assets/images/symbiosis-images/6.png" alt="{{ imageName }}">
              <div class="card-body p-1">
                  <p class="card-text">
                      {{ imageName }}
                  </p>
              </div>
          </div>
      </div>
      <div class="my-1" [ngClass]="{'col-sm-2': changeLayout, 'col-sm-4': !changeLayout}">
          <div class="card p-0">
              <img class="card-img-top" src="../../../assets/images/symbiosis-images/1.png" alt="{{ imageName }}">
              <div class="card-body p-1">
                  <p class="card-text">
                      {{ imageName }}
                  </p>
              </div>
          </div>
      </div>
      <div class="my-1" [ngClass]="{'col-sm-2': changeLayout, 'col-sm-4': !changeLayout}">
          <div class="card p-0">
              <img class="card-img-top" src="../../../assets/images/symbiosis-images/2.png" alt="{{ imageName }}"
                  style="height: auto">
              <div class="card-body p-1">
                  <p class="card-text">
                      {{ imageName }}
                  </p>
              </div>
          </div>
      </div>
      <div class="my-1" [ngClass]="{'col-sm-2': changeLayout, 'col-sm-4': !changeLayout}">
          <div class="card p-0">
              <img class="card-img-top" src="../../../assets/images/symbiosis-images/3.png" alt="{{ imageName }}">
              <div class="card-body p-1">
                  <p class="card-text">
                      {{ imageName }}
                  </p>
              </div>
          </div>
      </div>
      <div class="my-1" [ngClass]="{'col-sm-2': changeLayout, 'col-sm-4': !changeLayout}">
          <div class="card p-0">
              <img class="card-img-top" src="../../../assets/images/symbiosis-images/4.png" alt="{{ imageName }}">
              <div class="card-body p-1">
                  <p class="card-text">
                      {{ imageName }}
                  </p>
              </div>
          </div>
      </div>
      <div class="my-1" [ngClass]="{'col-sm-2': changeLayout, 'col-sm-4': !changeLayout}">
          <div class="card p-0">
              <img class="card-img-top" src="../../../assets/images/symbiosis-images/5.jpg" alt="{{ imageName }}">
              <div class="card-body p-1">
                  <p class="card-text">
                      {{ imageName }}
                  </p>
              </div>
          </div>
      </div>
      <div class="my-1" [ngClass]="{'col-sm-2': changeLayout, 'col-sm-4': !changeLayout}">
          <div class="card p-0">
              <img class="card-img-top" src="../../../assets/images/symbiosis-images/6.png" alt="{{ imageName }}">
              <div class="card-body p-1">
                  <p class="card-text">
                      {{ imageName }}
                  </p>
              </div>
          </div>
      </div>
      <div class="my-1" [ngClass]="{'col-sm-2': changeLayout, 'col-sm-4': !changeLayout}">
          <div class="card p-0">
              <img class="card-img-top" src="../../../assets/images/symbiosis-images/1.png" alt="{{ imageName }}">
              <div class="card-body p-1">
                  <p class="card-text">
                      {{ imageName }}
                  </p>
              </div>
          </div>
      </div>
      <div class="my-1" [ngClass]="{'col-sm-2': changeLayout, 'col-sm-4': !changeLayout}">
          <div class="card p-0">
              <img class="card-img-top" src="../../../assets/images/symbiosis-images/2.png" alt="{{ imageName }}"
                  style="height: auto">
              <div class="card-body p-1">
                  <p class="card-text">
                      {{ imageName }}
                  </p>
              </div>
          </div>
      </div>
      <div class="my-1" [ngClass]="{'col-sm-2': changeLayout, 'col-sm-4': !changeLayout}">
          <div class="card p-0">
              <img class="card-img-top" src="../../../assets/images/symbiosis-images/3.png" alt="{{ imageName }}">
              <div class="card-body p-1">
                  <p class="card-text">
                      {{ imageName }}
                  </p>
              </div>
          </div>
      </div>
      <div class="my-1" [ngClass]="{'col-sm-2': changeLayout, 'col-sm-4': !changeLayout}">
          <div class="card p-0">
              <img class="card-img-top" src="../../../assets/images/symbiosis-images/4.png" alt="{{ imageName }}">
              <div class="card-body p-1">
                  <p class="card-text">
                      {{ imageName }}
                  </p>
              </div>
          </div>
      </div>
      <div class="my-1" [ngClass]="{'col-sm-2': changeLayout, 'col-sm-4': !changeLayout}">
          <div class="card p-0">
              <img class="card-img-top" src="../../../assets/images/symbiosis-images/5.jpg" alt="{{ imageName }}">
              <div class="card-body p-1">
                  <p class="card-text">
                      {{ imageName }}
                  </p>
              </div>
          </div>
      </div>
      <div class="my-1" [ngClass]="{'col-sm-2': changeLayout, 'col-sm-4': !changeLayout}">
          <div class="card p-0">
              <img class="card-img-top" src="../../../assets/images/symbiosis-images/6.png" alt="{{ imageName }}">
              <div class="card-body p-1">
                  <p class="card-text">
                      {{ imageName }}
                  </p>
              </div>
          </div>
      </div>
  </div>

</div>

<ng-template #largeImage>
  <div class="modal-header">
      <h4 class="modal-title pull-left">{{ imageName }}</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <img class="card-img-top" src="../../../assets/images/symbiosis-images/1.png" alt="{{ imageName }}">
  </div>
</ng-template>