import { SearchParams } from './search-params.modal'

export class SearchWildCards extends SearchParams<string> {
    controlType = 'wildcards'
    options: {key: string, value: string}[] = []

    constructor(options: {} = {}) {
        super(options)
        this.options = options['options'] || ''
    }
}