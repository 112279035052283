import {
	Component,
	OnInit,
	AfterContentInit,
	OnDestroy,
	OnChanges,
	SimpleChanges,
	Input,
	Output,
	ContentChildren,
	ElementRef,
	ChangeDetectorRef
} from '@angular/core';
import { Subject } from 'rxjs';
import { Subscription } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { NgcFloatItemBtnComponent } from '../ngc-float-item-btn/ngc-float-item-btn.component';

@Component({
	selector: 'ngc-float-button',
	templateUrl: './ngc-float-btn.component.html',
	styleUrls: ['./ngc-float-btn.component.scss']
})
export class NgcFloatBtnComponent implements OnInit, AfterContentInit, OnDestroy, OnChanges {

	private elementref: HTMLElement;
	private subs: Subscription[] = [];
	public state: BehaviorSubject<any>;

	@Input() icon: string;
	@Input() direction: string;
	@Input() spaceBetweenButtons: number = 55;
	@Input() open: Subject<boolean>;
	@Input() color: string = '#dd0031';
	@Input() disabled: boolean = false;
	@Output() events: Subject<any> = new Subject();
	@ContentChildren(NgcFloatItemBtnComponent) buttons;

	constructor(private element: ElementRef, private cd: ChangeDetectorRef) {
		this.elementref = element.nativeElement;

		this.state = new BehaviorSubject({
			display: false,
			direction: 'top',
			event: 'start',
			spaceBetweenButtons: this.spaceBetweenButtons
		});
	}

	ngOnInit() {
	}

	ngOnChanges(changes: SimpleChanges): void {
		//Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
		//Add '${implements OnChanges}' to the class.
		if (changes.direction && !changes.direction.firstChange) {
			this.state.next({
				...this.state.getValue(),
				event: 'directionChanged',
				direction: changes.direction.currentValue
			});
			// if changes happens
			this.checkDirectionType();
		}

		if (changes.open && changes.open.currentValue) {
			const sub = this.open.subscribe(v => {
				if (v !== this.state.getValue().display) {
					this.state.next({
						...this.state.getValue(),
						event: v ? 'open' : 'close',
						display: v
					});

					// make angular happy
					this.cd.markForCheck();
				}
			});

			this.subs.push(sub);
		}

		if (changes.spaceBetweenButtons && changes.spaceBetweenButtons.currentValue) {
			this.state.next({
				...this.state.getValue(),
				event: 'spaceBetweenButtonsChanged',
				spaceBetweenButtons: changes.spaceBetweenButtons.currentValue
			});
		}
	}

	ngAfterContentInit(): void {
		//Called after ngOnInit when the component's or directive's content has been initialized.
		//Add 'implements AfterContentInit' to the class.
		if (this.direction) {
			// first time to check
			this.checkDirectionType();
		}

		this.buttons.toArray().map(v => {
			const sub = v.clicked.subscribe(() => {
				this.state.next({
					...this.state.getValue(),
					display: false,
					event: 'close'
				});
			});

			this.subs.push(sub);
		});

		const sub = this.state.subscribe(v => {
			this.animateButtons(v.event);

			this.events.next({
				display: v.display,
				event: v.event,
				direction: v.direction
			});
		});
		this.subs.push(sub);
	}

	ngOnDestroy(): void {
		//Called once, before the instance is destroyed.
		//Add 'implements OnDestroy' to the class.
		this.subs.forEach(v => {
			v.unsubscribe();
		});
	}

	public toggle() {
		if (this.disabled) {
			return this.disabled;
		}
		this.state.next({
			...this.state.getValue(),
			display: !this.state.getValue().display,
			event: !this.state.getValue().display ? 'open' : 'close'
		});
	}

	// only top and bottom support content element
	private checkDirectionType() {
		if (this.buttons.toArray()) {
			let display = 'block';

			if (this.direction === 'right' || this.direction === 'left') {
				display = 'none';
			}

			this.buttons.toArray().forEach(element => {
				element.contentref.nativeElement.style.display = display;
			});
		}
	}

	// transition
	private animateButtons(eventType) {
		this.buttons.toArray().forEach((btn, i) => {
			i += 1;
			let style = btn.elementref.nativeElement.style;

			if (eventType !== 'directionChanged' && this.state.getValue().display) {
				style['transform'] = 'scale(1)';
				style['transition-duration'] = '0s';

				if (btn.timeout) {
					clearTimeout(btn.timeout);
				}
			}

			setTimeout(() => {
				style['transition-duration'] = this.state.getValue().display ? `${90 + (100 * i)}ms` : '';
				style['transform'] = this.state.getValue().display ? this.getTranslate(i) : '';
			}, 50);

			if (eventType !== 'directionChanged' && !this.state.getValue().display) {
				btn.timeout = setTimeout(() => {
					style['transform'] = 'scale(0)';
				}, 90 + (100 * i));
			}
		});
	}

	// get transition direction
	private getTranslate(i) {

		let animation;

		switch (this.direction) {
			case 'right':
				animation = `translate3d(${this.state.getValue().spaceBetweenButtons * i}px,0,0)`;
				break;
			case 'bottom':
				animation = `translate3d(0,${this.state.getValue().spaceBetweenButtons * i}px,0)`;
				break;
			case 'left':
				animation = `translate3d(-${this.state.getValue().spaceBetweenButtons * i}px,0,0)`;
				break;
			default:
				animation = `translate3d(0,-${this.state.getValue().spaceBetweenButtons * i}px,0)`;
				break;
		}

		return animation;
	}

}
