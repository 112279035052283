<div *ngIf="!disable">
    <button id="landing-table-buttons" class="btn btn-sm btn-outline-dark py-0 my-1 px-1 border-0" (click)="invoke(type, data)" [disabled]="approved ? 'disabled': null">
        <i class="{{icon}}"  aria-hidden="true" ></i>
    </button>
</div>
<div *ngIf="disable">
    <a class="btn btn-sm btn-outline-dark py-0 my-1 px-1 border-0" (click)="invoke(type, data)" >
        <i class="text-black-50 {{icon}}" aria-hidden="true"></i>
    </a>
</div>
<!--<ng-template #deleteMCCModal>-->
<!--    <div class="modal-header">-->
<!--        <h6 class="modal-title pull-left">{{mccDeleteMsg}}</h6>-->
<!--        <button type="button" class="close pull-right" aria-label="Close" (click)="deleteModalRef.hide()">-->
<!--            <span aria-hidden="true">&times;</span>-->
<!--        </button>-->
<!--    </div>-->
<!--    <div class="modal-body ">-->
<!--        <p> {{ 'MECHANICAL_COMPLETION.REVOKE_MCC_MSG' | translate }} </p>-->
<!--        <div class="float-right">-->
<!--            <button type="button" class="btn btn-default mx-1" (click)="revokeDeleteMCC()">{{ 'MECHANICAL_COMPLETION.YES' | translate-->
<!--                }}</button>-->
<!--            <button type="button" class="btn btn-primary mx-1" (click)="cancelDelteMCC()">{{ 'MECHANICAL_COMPLETION.NO' | translate }}</button>-->
<!--        </div>-->
<!--    </div>-->
<!--</ng-template>-->

<ng-template #deleteMCCModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Revoke confirm</h4>
    </div>
    <div class="modal-body pb-0">
        <h5>{{mccDeleteMsg}}</h5>
    </div>
    <div class="modal-footer">
        <button type="button" id="warn_confirm" class="btn btn-primary"
                (click)="revokeDeleteMCC()">
            {{ 'OK' | translate}} </button>
        <button type="button" id="warn_cancel" class="btn btn-secondary"
                data-dismiss="modal" (click)="cancelDelteMCC()">
            {{ 'CANCEL' | translate}} </button>
    </div>
</ng-template>
