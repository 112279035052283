<div *ngIf="!disable && !restore && !isText">
	<button id="landing-table-buttons" class="btn btn-sm btn-outline-dark py-0 my-1 px-0 border-0" (click)="invoke(type, data)">
		<i class={{icon}} aria-hidden="true"></i>
	</button>
</div>
<div *ngIf="!disable && restore && !isText">
	<a class="btn btn-sm">
		<i class={{icon}} style="color:#343a40" aria-hidden="true" (click)="invoke(type, data)"></i>
	</a>
</div>
<div *ngIf="disable && IsFile">
	<i class={{icon}} style="color:rgb(110, 110, 108)" aria-hidden="true"></i>
</div>
<div *ngIf="disable && !isText">
	<a class="btn btn-sm disabled">
		<i class={{icon}} style="color:rgb(110, 110, 108)" aria-hidden="true" (click)="invoke(type, data)"></i>
	</a>
</div>
<div *ngIf="isText">
	<span title="{{data}}">{{data}}</span>
</div>

<!-- style="color:rgb(236, 167, 39)" -->