<div>
	<div class="py-2 px-0 mt-2">
		<ul class="col-sm-12 px-0 py-2 list-group mt-2">
			<li *ngIf="enableQeuryWord" class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
				[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'dictionary', 'custom-list-item-dark': selectedMenuItem != 'dictionary'}"
				(click)="sideItemClick('dictionary')">
				<h6 class="flaot-left ml-2 mb-0 sidebar-list-text">
					<i class="far fa-language mx-2" aria-hidden="true"></i>
					{{ 'DICTIONARY.DC_SIDEBAR_DICTIONARY' | translate }}
				</h6>
			</li>
			<!--
				<li *ngIf="!enableQeuryWord" class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor disabled"
				[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'dictionary', 'custom-list-item-dark': selectedMenuItem != 'dictionary'}"
				(click)="sideItemClick('dictionary')">
				<h6 class="flaot-left ml-2 mb-0 sidebar-list-text">
					<i class="far fa-language mx-2" aria-hidden="true"></i>
					{{ 'DICTIONARY.DC_SIDEBAR_DICTIONARY' | translate }}
				</h6>
			</li>
			-->
			
			<ul class="col-sm-12 px-0 py-0 list-group rounded-0">
				<li *ngIf="enableAddWord" class="list-group-item border-0 rounded-0 px-0 py-2 list-item-bg list-cursor"
					(click)="sideItemClick('addword')"
					[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'addword', 'custom-list-item-dark': selectedMenuItem != 'addword'}">
					<h6 class="flaot-left mb-0 ml-4">
						<i class="far fa-plus-square mx-2" aria-hidden="true"></i>
						{{ 'DICTIONARY.DC_SIDEBAR_ADD_NEW_WORD' | translate }}
					</h6>
				</li>
				<!--
					<li *ngIf="!enableAddWord" class="list-group-item border-0 rounded-0 px-0 py-2 list-item-bg list-cursor disabled"
					(click)="sideItemClick('addword')"
					[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'addword', 'custom-list-item-dark': selectedMenuItem != 'addword'}">
					<h6 class="flaot-left mb-0 ml-4">
						<i class="far fa-plus-square mx-2" aria-hidden="true"></i>
						{{ 'DICTIONARY.DC_SIDEBAR_ADD_NEW_WORD' | translate }}
					</h6>
				</li>
				-->
				
			</ul>
		</ul>
	</div>
</div>