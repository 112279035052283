import { WsCallback } from 'src/app/util/ws-callback.interface';
import { Component,TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SharedService } from 'src/app/util/shared.service';
import { TranslateService } from '@ngx-translate/core';
import { WsResponse } from 'src/app/util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';
import { Constants } from 'src/app/util/constants';
import { DependenciesmodalComponent } from 'src/app/util/dependenciesmodal/dependenciesmodal.component';
import { AddEditCheckComponent } from './addeditcheck/addeditcheck.component';
import { ManagecheckitemsService } from './managecheckitems.service';
import { LoadingService } from 'src/app/util/loading/loading.service';
import { AlertService } from 'src/app/util/alert/alert.service';

@Component({
    selector: 'app-checks',
    templateUrl: './checks-cell-renderer.component.html',
  })

  
export class CheckCellRendererComponent implements WsCallback{
    disable: boolean = false;
    icon: string;
    title: String;
    type: any;
    id: string;
    status: string;
    refCount: number = 0;
    dependencyType: string;
    bsModalRef: BsModalRef;



    public displayInsWarn: boolean = false;
    public warningMsg: string = "";
    

    warnModalRef: BsModalRef;
    @ViewChild('confirmation_warn') confirmationWarn: TemplateRef<any>;
    
    constructor(private sharedService: SharedService, private modalService: BsModalService,
        private translate: TranslateService, private checkItemsService: ManagecheckitemsService, 
        private loadingService : LoadingService, private alertService: AlertService){
        translate.addLangs(["es", "en"]);
        translate.setDefaultLang("en");
        let browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
    }

    agInit(params:any){
        this.id = params.data.id;
        this.refCount = params.data.referenceCount;
        let items = params.value.split(':')
        if (items.length == 2) {
            this.status = items[1];
        }
        this.setType(items[0]);
    }

    private setType(type: any): void{
        this.type = type;
        if (type == 'dependencies') {
            this.icon = 'far fa-link';
            if (this.refCount > 0) {
                this.disable = false;
                this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.VIEW;
                this.translate.get('CHECKS_CELL_RENDER.DEPENDENCIES_LIST').subscribe((res: string) => {
                this.title = res;
                });
            }
            else {
                this.disable = true;
                this.icon = 'text-black-50 far fa-link';
            }
        } else if (type == 'status') {
            if (this.status == "Active") {
                this.icon = 'far fa-check-circle';
                this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.DISABLE;
                this.translate.get('CHECKS_CELL_RENDER.DISABLE_CONFIRM').subscribe((res: string) => {
                this.title = res;
                });
            } else {
                this.icon = 'far fa-times-circle';
                this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.ENABLE;
                this.translate.get('CHECKS_CELL_RENDER.ENABLE_CONFIRM').subscribe((res: string) => {
                this.title = res;
                });
            }
        } else if (type == 'delete') {
            this.icon = 'far fa-trash-alt';
            this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.DELETE;
            this.translate.get('CHECKS_CELL_RENDER.DELETE_CONFLICTS').subscribe((res: string) => {
                this.title = res;
            });
        } else if (type == 'edit') {
            this.icon = 'far fa-edit';
        } 
    }

    invoke(type: string): void{
        switch (type) {
            case 'dependencies':
              if (this.refCount > 0) {
                this.viewDependencies(false);
              }
              break;
            case 'status':
              this.viewDependencies(false);
              break;
            case 'edit':
                this.loadingService.showLoading(true, false, 'Checking Checks', 0);
                this.checkItemsService.getCSInstanceCount(this.id, this);
              break;
            case 'delete':
              this.viewDependencies(true);
              break;
            }
    }

    viewDependencies(dflag: boolean) {
        this.dependenciesModalWithComponent(this.title, dflag, true);
    }
    
    dependenciesModalWithComponent(header: String, dflag: boolean, cflag: boolean) {
    const initialState = {
        headerTitle: header,
        delFlag: dflag,
        cancelFlag: cflag,
        checkboxVal: this.status == "Inactive" ? true : false
    };
    this.sharedService.setFileId(this.id);
    this.sharedService.setFileType(Constants.DEPENDENCY_ITEM_TYPE.CHECK);
    this.sharedService.setAction("getDependencies");
    this.sharedService.setDependencyMessageType(this.dependencyType);
    this.sharedService.setParent(this);
   // this.checkItemsService.loadTableDataRequest();
    this.bsModalRef = this.modalService.show(DependenciesmodalComponent, { initialState, class: 'modal-lg' });
    }
    
  onSuccess(data: WsResponse, serviceType: WsType) {
    if (serviceType == WsType.GET_CSINSTANCE_COUNT_BY_CHECK) {
      this.loadingService.hideLoading();
      if (data.payload != null) {
        if (data.payload.relatedIssuedCheckSheetsCount > 0 && data.payload.relatedApprovedCheckSheetsCount > 0) {
          this.displayInsWarn = true;
          this.translate.get('CS_CELL_RENDER.ISSUED_APPROVED_WARN').subscribe((res: string) => {
            this.warningMsg = res;
          });
        }
        else if (data.payload.relatedIssuedCheckSheetsCount > 0) {
          this.displayInsWarn = true;
          this.translate.get('CS_CELL_RENDER.ISSUED_WARN').subscribe((res: string) => {
            this.warningMsg = res;
          });
        }
        else if (data.payload.relatedApprovedCheckSheetsCount > 0) {
          this.displayInsWarn = true;
          this.translate.get('CS_CELL_RENDER.APPROVED_WARN').subscribe((res: string) => {
            this.warningMsg = res;
          });
        }
      }
      this.showWarnMsg();
    }
  }
      
      
  onFail(data: WsResponse, serviceType: WsType) {
    if (serviceType == WsType.GET_CSINSTANCE_COUNT_BY_CHECK) {
      this.loadingService.hideLoading();
      this.alertService.clear();
      this.alertService.error(data.statusDescription)
    }
  }

    showWarnMsg() {
        if (this.type == 'edit') {
          if (this.displayInsWarn) {
            this.warnModalRef = this.modalService.show(this.confirmationWarn);
          } else {
            this.openAddEditCheckModal();
          }
        }
      }

      private openAddEditCheckModal() {
        this.sharedService.setFileId(this.id);
        this.bsModalRef = this.modalService.show(AddEditCheckComponent, { class: 'modal-md', backdrop: 'static', keyboard: false })
      }

      warningConfirm() {
        if (this.type == 'edit') {
          this.openAddEditCheckModal()
        }
      }
}