import { Component, OnInit, TemplateRef, ViewChild, EventEmitter } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { LocalStorage } from '../util/localstorage.service';
import { LoggedUser } from '../user/logged-user.model';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '../util/alert/alert.service';
import { SharedService } from '../util/shared.service';
import { HeaderService } from './header.service';
declare var require: any;
const { version: appVersion } = require('../../../package.json');
import { UserVariable } from '../util/common/user-variable';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CapabilityService } from '../util/capability.service';
import { WsResponse } from '../util/ws-response.model';
import { WsType } from '../util/ws-type';
import { WsCallback } from '../util/ws-callback.interface'
import { ServiceUrls } from '../util/service-urls';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css'],
	inputs: ['showHamburger', 'minimalOption'],
	outputs: ['showHideSideBar']
})
export class HeaderComponent implements OnInit {
	//inputs
	public showHamburger: boolean = false
	public minimalOption: boolean = false

	//outputs
	showHideSideBar = new EventEmitter<any>()

	appVersion = "";
	public userFullName: String = "";
	public loggedInUser: LoggedUser;
	public isToggleMenu: boolean = false
	public notificationCount: number = 0
	isOpen = false;
	public clientId: string = UserVariable.clientId; 
	public projectType: any = sessionStorage.getItem(LocalStorage.PROJECT_TYPE); 
	public projectId: string = UserVariable.projectId; 
	public defaultProjectId: string = localStorage.getItem(LocalStorage.DEFAULT_PROJECT_ID);
	public projectName: string = UserVariable.projectName; 
	public serverVersion: String = ''
	public adminSettingsClicked: string = "clicked"
	bsModalRef: any;
	DashBData: any[];
	ActionsData: any[];
	DashBPos: any[];
	public searchParams: any = []
	user = {};
	placeholderString = 'Select timezone';
	timezone: string
	timezoneString: any;
	lang: any;
	previousePreference: string;
	currentPreference: string;
	public isShowSearch: boolean = false
	private vieProcedureStore: boolean = false;
	private vieOutputManagment: boolean = false;
	public setDefaultBtnText: string = '';
	public defaultBtnIcon: boolean = true;
	public showProjectName: boolean = true;
	public pathName: string = null;

	//capabilities
	public viewReport: boolean = false
	public viewProcedureStore: boolean = false
	public viewDocumentStore: boolean = false
	public addWord: boolean = false
	public queryWord: boolean = false
	public queryUser: boolean = false;
	public createUser: boolean = false;
	public queryClient: boolean = false;
	public createClient: boolean = false;
	public queryProject: boolean = false;
	public createProject: boolean = false;
	public queryTopicType: boolean = false;
	public queryDocTypeAttribute: boolean =false;
	public queryCategory: boolean = false;

	// enable/disable buttons
	public enableViewReport: boolean = false
	public enableViewProcedureStore: boolean = false
	public enableViewDocumentStore: boolean = false
	public enableAddWord: boolean = false
	public enableQeuryWord: boolean = false
	public enableAdminSettings: boolean = true;
	public comProjectAvailable: boolean = false;

	changeTimezone(timezone) {
		console.log(timezone)
		//   this.user.timezone = timezone;
	}

	//View Profile variables
    public fontList: {}[] = [[10, "Small"], [12, "Normal"], [16, "Large"], [24, "Huge"]];
    font: any;
    fontForm: FormGroup;
    widgets: any;
    marked: any;
    widgetList: {}[];
    form: FormGroup;
    widgetName: any;
    status: any;

	constructor(private router: Router, private translate: TranslateService, private headerService: HeaderService, private alertService: AlertService
		, private sharedService: SharedService, private modalService: BsModalService, private capabilityService: CapabilityService, private fb: FormBuilder, private formBuilder: FormBuilder) {
		this.loggedInUser = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER));
		this.userFullName = this.loggedInUser.firstName + " " + this.loggedInUser.lastName;
		this.appVersion = appVersion;
		//this.enableDisableButton()
		//console.log('[HeaderComponent] (defaultProjectId) ' + this.defaultProjectId);
		//console.log('[HeaderComponent] (projectId) ' + this.projectId);
		if (this.defaultProjectId == this.projectId && this.defaultProjectId) {
			this.translate.get('HEADER.MENU_REMOVE_DEFAULT').subscribe((res: string) => {
				this.setDefaultBtnText = res;
				this.defaultBtnIcon = false
			});
		} else {
			this.translate.get('HEADER.MENU_SET_AS_DEFAULT').subscribe((res: string) => {
				this.setDefaultBtnText = res;
				this.defaultBtnIcon = true
			});
		}
		if( (this.projectType == null) || (this.projectType == undefined) && 
		(this.defaultProjectId == this.projectId) ){
			this.projectType = localStorage.getItem(LocalStorage.DEFAULT_PROJECT_TYPE)
			sessionStorage.setItem(LocalStorage.PROJECT_TYPE, this.projectType);
		}


		this.headerService.getServerVersion()
			.subscribe(
				data => {
					this.serverVersion = data.payload;
				},
				error => {
					if (error.statusCode == "0") {				
						this.alertService.clear();
						this.alertService.error(this.translate.instant('HEADER.CONNECTION_LOST'), true);
					}
					else{
						this.alertService.clear()
						this.alertService.error(error.statusDescription)
					}
				}
			)
		this.vieProcedureStore = this.capabilityService.isCapabilityAssigned("setAvailabilityProcedureStore");
		this.vieOutputManagment = this.capabilityService.isCapabilityAssigned("setAvailabilityProcedureStore");
	}

	ngOnInit() {
		var pathName: string = window.location.pathname.replace('/', '');
		if(pathName == "landing" || pathName == "adminSettings"){
			this.showProjectName = false;
		}
		this.getNotificationCount();
		//this.refreshData();
		this.pathName = window.location.pathname.replace('/', '');
		console.log("pathName: "+this.pathName)
		if(!this.minimalOption && (UserVariable.projectId !='')){
			this.headerService.getProject(this);
		}

		let viewReportCapability = "viewReport"
		let viewProcedureStoreCapability = "viewProcedureStore"
		let viewDocumentStoreCapability = "viewProcedureStore"
		let addWordCapability: string = "addWord"

		this.viewReport = this.capabilityService.isCapabilityAssigned(viewReportCapability)
		this.viewProcedureStore = this.capabilityService.isCapabilityAssigned(viewProcedureStoreCapability)
		this.viewDocumentStore = this.capabilityService.isCapabilityAssigned(viewDocumentStoreCapability)
		this.addWord = this.capabilityService.isCapabilityAssigned(addWordCapability)
	}

	ngAfterContentInit() {

		this.sharedService.tnExecute().subscribe(data => this.notificationToggle())

	}

	// refreshData() {
	// 	console.log("reload data")
	// 	this.notificationCount = JSON.parse(localStorage.getItem(LocalStorage.NOTIFICATION_COUNT));
	// 	this.headerService.getNotificationCount()
	// 		.subscribe(data => {
	// 			this.notificationCount = data.payload;

	// 		})
	// }

	public changeProject(): void {
		localStorage.setItem(LocalStorage.OLD_PROJECT_ID, this.projectId)
		localStorage.setItem(LocalStorage.OLD_PROJECT_NAME, this.projectName)
		localStorage.setItem(LocalStorage.OLD_CLIENT_ID, this.clientId)
		localStorage.setItem(LocalStorage.CURRENT_PROJECT_ID, this.projectId)

		localStorage.removeItem(LocalStorage.PROJECT_ID);
		localStorage.removeItem(LocalStorage.PROJECT_NAME);
		localStorage.removeItem(LocalStorage.CLIENT_ID);

		let navigationExtras: NavigationExtras = {
			queryParams: {
				'option': 'change_project'
			}
		}
		this.router.navigate(['landing'], navigationExtras)
		// console.log("changed")
	}

	public notificationToggle() {
		this.isOpen = false;
		this.getNotificationCount();
		//this.refreshData();
	}

	public signOutBtnClickEvent(template: TemplateRef<any>): void {

		this.bsModalRef = this.modalService.show(template);

		//this.router.navigateByUrl('/login')
		//clean the local storage
		//localStorage.removeItem(LocalStorage.LOGGED_USER);
		//localStorage.removeItem(LocalStorage.REQUESTED_PATH);
		//Remove everything from the local storage, now developers can logout and test.
		// localStorage.clear();
		//sessionStorage.clear();
		// this.router.navigateByUrl('/login');
	}
	logout(){
		this.bsModalRef.hide();
		localStorage.clear();
		this.router.navigateByUrl('/login');

	}
	cancelLogOut() {
		this.bsModalRef.hide();

	}

	public showToggleMenu() {
		this.isToggleMenu = !this.isToggleMenu
	}

	public showNotification() {
		this.notificationCount;
	}

	public changeNotificationNumber() {
		this.notificationCount = 8
	}

	public psButtonClick() {
		window.open('/procedurestore');
	}

	public documentStoreButtonClick() {
		window.open('/documentstore');
	}

	public dictionaryClick() {
		window.open('/dictionary')
	}

	public opButtonClick() {
		window.open('/outputhome');
	}

	public reusableButtonClick() {
		window.open('/reusableUpdate');
	}

	public adminSetButtonClick() {
		window.open('/adminSettings');
		if (localStorage.getItem(LocalStorage.PROJECT_NAME) === "" && localStorage.getItem(LocalStorage.CLIENT_ID) === null) {
			localStorage.setItem(LocalStorage.IS_ADMINSETTINGS_CLICKED,this.adminSettingsClicked)
		}	
	}

	public reportsBtnClick() {
		window.open('/reports')
	}

	/* Manage step attribute page */

	public optDataClick(){
		window.open('/OptData');
	}

	public workflowConfigButtonClick() {
		window.open('/workflow');
	}

	public getNotificationCount() {
		this.headerService.getNotificationCount().subscribe(
			data => {
				if (data.payload) {
					this.notificationCount = data.payload;
					//localStorage.setItem(LocalStorage.NOTIFICATION_COUNT, data.payload);
				} else {
					//do nothing
					this.notificationCount = 0;
				}
			},
			error => {
				if (error.statusCode == "0") {				
					this.alertService.clear();
					this.alertService.error(this.translate.instant('HEADER.CONNECTION_LOST'), true);
				}
				else{
					this.alertService.clear()
					this.alertService.error(error.statusDescription)
				}
			});

	}

	openViewProfileModal(template: TemplateRef<any>, size: string) {
		this.previousePreference = localStorage.getItem('preferences');
		this.form = this.formBuilder.group({
			widgetList: []
		});
		this.loadUserProfileData();
		if (size == 'lg') {
			this.bsModalRef = this.modalService.show(template, { class: 'modal-lg' });
		} else {
			this.bsModalRef = this.modalService.show(template);
		}
	}

	openModal(template: TemplateRef<any>, size: string) {
		if (size == 'lg') {
			this.bsModalRef = this.modalService.show(template, { class: 'modal-lg' });
		} else {
			this.bsModalRef = this.modalService.show(template);
		}
	}

	cancel() {
		this.bsModalRef.hide();
		localStorage.setItem(LocalStorage.PREFERENCES, this.previousePreference);
	}

	save() {
		this.saveTimeZone();
		this.bsModalRef.hide();
		this.currentPreference = localStorage.getItem('preferences');
		let out = JSON.parse(localStorage.getItem('preferences'));
		this.headerService.updateUserPreferences(out).subscribe(
			data => {
				// console.log(data);
			}
		)
	}

	/**
	 * search component search button click event
	 * @param event 
	 */
	public searchFunc(event: any) {
		console.log('[searchFunc] (event) ' + JSON.stringify(event));
		//generate search parameters from method parameters
		this.getSearchResult(event)
	}

	/**
	 * search component search bar ENTER button click event
	 * @param event 
	 */
	public searchBarEnterFunc(event: any) {
		console.log('[searchBarEnterFunc] (event) ' + JSON.stringify(event));
		//generate search parameters from method parameters
		this.getSearchResult(event)
	}

	private getSearchResult(event: any) {
		let data = {
			isSearch: true,
			searchWordMap: event.searchWordMap
		}
		this.sharedService.openTabRequestWithId('search_res', 'Search', 'FileSearch', data, "Search")
	}

	public setasDefault(option: boolean): void {
		if (option) {
			//set default project
			let request = { projectId: this.projectId, isDefaultProject: 1, projectType: this.projectType, userId: this.loggedInUser.userId };
			this.headerService.setDefaultRecentProjects(JSON.stringify(request), this);
		} else {
			//remove default project
			let request2 = { projectId: this.projectId, userId: this.loggedInUser.userId, projectType: this.projectType };
			this.headerService.deleteUserDefaultRecentProject(JSON.stringify(request2), this);
		}
	}

	onSuccess(data: WsResponse, serviceType: WsType): void {
		if (serviceType == WsType.SET_DEFAULT_RECENT_PROJECT) {
			if (data.payload) {
				this.alertService.success(data.statusDescription)
				this.translate.get('HEADER.MENU_REMOVE_DEFAULT').subscribe((res: string) => {
					this.setDefaultBtnText = res;
					this.defaultBtnIcon = false
				});
				localStorage.setItem(LocalStorage.DEFAULT_PROJECT_ID, UserVariable.projectId);
				localStorage.setItem(LocalStorage.DEFAULT_PROJECT_NAME, UserVariable.projectName);
				localStorage.setItem(LocalStorage.DEFAULT_CLIENT_ID, UserVariable.clientId);
				let defaultProjectType = sessionStorage.getItem(LocalStorage.PROJECT_TYPE);
				localStorage.setItem(LocalStorage.DEFAULT_PROJECT_TYPE, defaultProjectType);
			}
		} else if (serviceType == WsType.DELETE_USER_DEFAULT_RECENT_PROJECT) {
			if (data.payload) {
				this.alertService.success(data.statusDescription)
				this.translate.get('HEADER.MENU_SET_AS_DEFAULT').subscribe((res: string) => {
					this.setDefaultBtnText = res;
					this.defaultBtnIcon = true
				});
				localStorage.removeItem(LocalStorage.DEFAULT_PROJECT_ID);
				localStorage.removeItem(LocalStorage.DEFAULT_PROJECT_NAME);
				localStorage.removeItem(LocalStorage.DEFAULT_CLIENT_ID);
				localStorage.removeItem(LocalStorage.DEFAULT_PROJECT_TYPE);
			}
		} else if (serviceType == WsType.GET_PROJECT) {
			console.log('project type='+ data.payload.projectType)
			if(data.payload.projectType == 2 || data.payload.projectType == 3 ){
				this.comProjectAvailable = true
			} else {
				this.comProjectAvailable = false
			}
		}
	}
	onFail(data: WsResponse, serviceType: WsType): void {
		if (serviceType == WsType.SET_DEFAULT_RECENT_PROJECT) {
			this.alertService.error(data.statusDescription)
		} else if (serviceType == WsType.DELETE_USER_DEFAULT_RECENT_PROJECT) {
			this.alertService.error(data.statusDescription)
		} else if (serviceType == WsType.GET_PROJECT) {
			this.alertService.error(data.statusDescription)
		}
	}

	/**
	 * hamburg button click event - show side bar
	 */
	public showSlideBar() {
		this.showHideSideBar.emit();
	}

	/**
     * enable/disable buttons or columns
     */
	private enableDisableButton() {
		this.checkCapability()
		this.enableViewReport =  this.viewReport
		this.enableViewProcedureStore = this.viewProcedureStore
		this.enableViewDocumentStore = this.viewDocumentStore
		this.enableAddWord = this.addWord

		if (!localStorage.getItem(LocalStorage.CAPABILITY_LIST) === null) {
			this.enableAdminSettings = false;
		} else if (localStorage.getItem(LocalStorage.USER_ACCESSIBILITY) === 'project_client_level' && localStorage.getItem(LocalStorage.PROJECT_NAME) === '') {
			this.enableAdminSettings = false;
		} else if (!this.createUser && !this.createClient && !this.createProject && !this.queryUser && !this.queryClient && !this.queryProject
				&& !this.queryTopicType && !this.queryDocTypeAttribute && !this.queryCategory) {
			this.enableAdminSettings = false;
		} else {
			this.enableAdminSettings = true;
		}
		
		
	}
	
	 /**
     * check the capabilities for given type
     */
    public checkCapability() {
		// let viewReportCapability = "viewReport"
		// let viewProcedureStoreCapability = "viewProcedureStore"
		// let viewDocumentStoreCapability = "viewProcedureStore"
		// let addWordCapability: string = "addWord"
		let queryWordCapability: string = "queryWord"
		let createUserCapability: string = "createUser"
		let createClientCapability: string ="createClient"
		let createProjectCapability: string = "createProject"
		let queryUserCapability: string ="queryUser"
		let queryClientCapability: string ="queryClients"
		let queryProjectCapability: string ="queryProjects"
		let queryTopicTypeCapability: string ="queryTopicType"
		let queryDocTypeAttributeCapability: string ="queryDocTypeAttribute"
		let queryCategoryCapability: string = "queryCategory"

		// this.viewReport = this.capabilityService.isCapabilityAssigned(viewReportCapability)
		// this.viewProcedureStore = this.capabilityService.isCapabilityAssigned(viewProcedureStoreCapability)
		// this.viewDocumentStore = this.capabilityService.isCapabilityAssigned(viewDocumentStoreCapability)
		// this.addWord = this.capabilityService.isCapabilityAssigned(addWordCapability)
		this.queryWord = this.capabilityService.isCapabilityAssigned(queryWordCapability)
		this.createUser = this.capabilityService.isCapabilityAssigned(createUserCapability)
		this.createClient = this.capabilityService.isCapabilityAssigned(createClientCapability)
		this.createProject = this.capabilityService.isCapabilityAssigned(createProjectCapability)
		this.queryUser = this.capabilityService.isCapabilityAssigned(queryUserCapability)
		this.queryClient = this.capabilityService.isCapabilityAssigned(queryClientCapability)
		this.queryProject = this.capabilityService.isCapabilityAssigned(queryProjectCapability)
		this.queryTopicType = this.capabilityService.isCapabilityAssigned(queryTopicTypeCapability)
		this.queryDocTypeAttribute = this.capabilityService.isCapabilityAssigned(queryDocTypeAttributeCapability)
		this.queryCategory = this.capabilityService.isCapabilityAssigned(queryCategoryCapability)
	}
	
	public onClickMenuContent() {
		console.log("ICONS")
		this.enableDisableButton()
	}

	public viewV2Com(){
		let url = ServiceUrls.getV2ComProject(UserVariable.projectId)
		window.open(url , "_blank"); 
	}
	consoleEvent(){
		console.log("focus on reusable");
	}

	//View profile functionalities
	loadUserProfileData() {
		let preferences = localStorage.getItem('preferences');
        var json = JSON.parse(preferences);
        var innerJson = (JSON.parse(json)).UserPreference.Portal;
        let editorJson = (JSON.parse(json)).UserPreference.Editor;			
        let timeZone = innerJson.General.TimeZone
        let language = innerJson.General.Language
        let fontSize = editorJson.FontSize
        this.widgets = editorJson.Widgets
        this.widgetList = Object.entries(this.widgets).sort();
 
        this.timezoneString = timeZone
        this.lang = language
        this.font = fontSize

        this.fontForm = this.fb.group({
            fontControl: [this.font]
          });
        this.DashBData = [];
        for (let i = 0; i < innerJson.Dashboard.DashBPage.length; i++) {
            this.DashBData.push({
                DashBPos: innerJson.Dashboard.DashBPage[i]["DashBPos"],
                pageNo: innerJson.Dashboard.DashBPage[i]["pageNo"],
            });
        }
        this.ActionsData = [];
        for (let i = 0; i < innerJson.Actions.Action.length; i++) {
        this.ActionsData.push({
            name: innerJson.Actions.Action[i]["name"],
            table: innerJson.Actions.Action[i]["Table"],
        });
        }
        this.DashBPos = []
	}

	changeLanguage(language) {

        let preferences = localStorage.getItem('preferences')
        var json = JSON.parse(preferences);
        var innerJson = (JSON.parse(json)).UserPreference.Portal;
        innerJson.General.Language = language
        const innerJsonObjStr = JSON.stringify(innerJson);
        const userPreferenceObjStr = JSON.stringify((JSON.parse(json)).UserPreference.Editor);
        const changeFontObj = "{\"UserPreference\":{\"Editor\":"+userPreferenceObjStr+",\"Portal\":"+innerJsonObjStr+"}}";
        const jsonObjStr = JSON.stringify(changeFontObj)
        localStorage.setItem(LocalStorage.PREFERENCES, jsonObjStr);
    }

    changeFontSize(fontSize) {

        let preferences = localStorage.getItem('preferences')
        var json = JSON.parse(preferences);
        var innerJson = (JSON.parse(json)).UserPreference.Editor;
        innerJson.FontSize = +fontSize
        const innerJsonObjStr = JSON.stringify(innerJson);
        const userPreferenceObjStr = JSON.stringify((JSON.parse(json)).UserPreference.Portal);
        const changeFontObj = "{\"UserPreference\":{\"Editor\":"+innerJsonObjStr+",\"Portal\":"+userPreferenceObjStr+"}}";
        const jsonObjStr = JSON.stringify(changeFontObj)
        localStorage.setItem(LocalStorage.PREFERENCES, jsonObjStr);

    }
    
    toggleVisibility(e){
        
        this.widgetName = e['0'];
        this.status = e['1'];
        let preferences = localStorage.getItem('preferences')
        var json = JSON.parse(preferences);
        var innerJson = (JSON.parse(json)).UserPreference.Editor;
        if ((e['1'] != null) || (e['1'] != undefined)){
            if (e['1'] == 0){
                innerJson.Widgets[this.widgetName] = 1
            } else {
                innerJson.Widgets[this.widgetName] = 0
            }
        
        const innerJsonObjStr = JSON.stringify(innerJson);
        const userPreferenceObjStr = JSON.stringify((JSON.parse(json)).UserPreference.Portal);
        const changeFontObj = "{\"UserPreference\":{\"Editor\":"+innerJsonObjStr+",\"Portal\":"+userPreferenceObjStr+"}}";
        const jsonObjStr = JSON.stringify(changeFontObj)
        localStorage.setItem(LocalStorage.PREFERENCES, jsonObjStr);
        }
    }

	saveTimeZone() {
		let preferences = localStorage.getItem('preferences')
        var json = JSON.parse(preferences);
        var innerJson = (JSON.parse(json)).UserPreference.Portal;
        innerJson.General.TimeZone = this.timezoneString
        const innerJsonObjStr = JSON.stringify(innerJson);
        const userPreferenceObjStr = JSON.stringify((JSON.parse(json)).UserPreference.Editor);
        const changeFontObj = "{\"UserPreference\":{\"Editor\":"+userPreferenceObjStr+",\"Portal\":"+innerJsonObjStr+"}}";
        const jsonObjStr = JSON.stringify(changeFontObj)
        localStorage.setItem(LocalStorage.PREFERENCES, jsonObjStr);
	}
 }
