import { Component, OnInit, AfterContentInit, ComponentFactoryResolver, ElementRef, ViewChild } from '@angular/core';
import { ViewChildren, ViewContainerRef, QueryList } from '@angular/core'
import { ReportWidget } from './report-widget.model'
import { ReportsService } from './reports-service.service'
import { ComponentRef} from '@angular/core';


@Component({
	selector: 'app-reports',
	templateUrl: './reports.component.html',
	styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {

	isWidgetPage: boolean = true

	public paddingtop: number = 60;
	@ViewChild('navPanel', {static: true}) navPanel: ElementRef

	public widgetList: Array<ReportWidget> = new Array<ReportWidget>()
	selectedSideBarItem: string = 'tag'
	@ViewChildren('widgetBody', { read: ViewContainerRef }) widgetHosts: QueryList<ViewContainerRef>;

	component: any;
	@ViewChild('reportHost', { read: ViewContainerRef, static: true }) componentHost: ViewContainerRef
	componentRef: ComponentRef<Component>

	public isSideBar: boolean = true

	constructor(private reportService: ReportsService, private resolver: ComponentFactoryResolver) { 
		this.widgetList = reportService.loadWidgetListByType(this.selectedSideBarItem)
		
	}

	ngOnInit() {
		console.log(this.navPanel.nativeElement.offsetHeight);
		if (this.navPanel.nativeElement.offsetHeight > 55) {
			this.paddingtop = 78
		} else {
			this.paddingtop = 50
		}
		setTimeout(() => {
			// this.loadComponents(this.widgetList)
		}, 0);
	}

	public changeSideBarItem(event: any) {
		console.log('[changeSideBarItem] (event) ' + JSON.stringify(event));
		if(event.selectedItem == 'image' || event.selectedItem == 'comosExport' || event.selectedItem == 'manageAdhocReports'){
			this.isWidgetPage = false;
			console.log('click ' + JSON.stringify(event));
			this.component = this.reportService.loadComponentByName(event.selectedItem)
			this.updateComponent()

		}
		else{
			this.isWidgetPage = true;
			this.widgetList = this.reportService.loadWidgetListByType(event.selectedItem)
			setTimeout(() => {
				this.loadComponents(this.widgetList)
			}, 0);
		}
		
	}

	public changeReportTable(event: any){
		// console.log("get report= ")
		// console.log("get report= " + localStorage.getItem("widgetId"))
		console.log("event.selectedItem " + event.widgetId )
		this.component = this.reportService.loadComponentByName('viewtable')
		this.updateComponent()
	}

	private loadComponents(widgetList: any) {
		let i: number = 0
		widgetList.forEach(widget => {
			this.loadComponent(widget, i)
			i++
		});
	}

	private loadComponent(widget: ReportWidget, index) {
		const component = this.reportService.loadWidgetByComponent(widget.component);
		if (component) {
			this.componentHost.clear()
			const target = this.widgetHosts.toArray()[index];
			const widgetComponent = this.resolver.resolveComponentFactory(component);
			if (target != undefined) {
				const cmpRef: any = target.createComponent(widgetComponent);
			}
		}
	}

	ngOnDestroy(): void {
		//Called once, before the instance is destroyed.
		//Add 'implements OnDestroy' to the class.
		if (this.componentRef) {
			this.componentRef.destroy()
		}
	}


	updateComponent() {
		this.componentHost.clear()
		const authFormFactory = this.resolver.resolveComponentFactory(this.component)
		const componentRef = this.componentHost.createComponent(authFormFactory);

	}


}
