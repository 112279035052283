import { Component, OnInit } from '@angular/core';
import { GridOptions, ColDef } from '@ag-grid-community/core';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from 'src/app/util/constants';
import { ReceiveChecksheetService } from './receivechecksheets.service';
import { LoadingService } from 'src/app/util/loading/loading.service';
import { AlertService } from 'src/app/util/alert/alert.service';
import { ReceiveChecksheetCellRendererComponent } from './cell-renderer/receivechecksheet-cell-renderer.component';
import { SharedService } from 'src/app/util/shared.service';

@Component({
  selector: 'app-receivechecksheets',
  templateUrl: './receivechecksheets.component.html',
})
export class ReceiveChecksheetsComponent implements OnInit {
  public rowData: any = [];
  public columnDefs: any;
  public gridOptions: GridOptions;
  public overlayLoadingTemplate: any;
  public overlayNoRowsTemplate: any;
  public getRowNodeId: any;
  public gridApi: any;
  private searchVendor: string = null;
  private searchChecksheet: string = null;
  private searchAsset: string = null;
  private searchDescription: string = null;
  private searchSystem: string = null;
  private searchAssetGroup: string = null;
  private searchPhase: string = null;
  private searchFunctionCode: string = null;
  private hasText: string = null;

  constructor(private translate: TranslateService, private receiveChecksheetService: ReceiveChecksheetService, private loadingService: LoadingService,
    private alertService: AlertService, private sharedService: SharedService) {
    this.columnDefs = [
      {
        headerName: '',
        field: "csInstanceId",
        hide: true,
        suppressColumnsToolPanel: true
      },
      {
        headerName: '',
        field: "assetId",
        hide: true,
        suppressColumnsToolPanel: true
      },
      {
        headerName: '',
        field: "systemId",
        hide: true,
        suppressColumnsToolPanel: true
      },
      {
        headerName: this.translate.instant('RECEIVE_CHECKS.VENDOR'),
        field: "vendorName",
        minWidth: 100
      },
      {
        headerName: this.translate.instant('RECEIVE_CHECKS.CHECKSHEET'),
        field: "checksheet",
        minWidth: 100
      },
      {
        headerName: this.translate.instant('RECEIVE_CHECKS.ASSET'),
        field: "assetNo",
        minWidth: 100
      },
      {
        headerName: this.translate.instant('RECEIVE_CHECKS.DESCRIPTION'),
        field: "assetDescription",
        minWidth: 100
      },
      {
        headerName: this.translate.instant('RECEIVE_CHECKS.SYSTEM'),
        field: "systemName",
        minWidth: 100
      },
      {
        headerName: this.translate.instant('RECEIVE_CHECKS.ASSET_GROUP'),
        field: "assetGroup",
        minWidth: 100
      },
      {
        headerName: this.translate.instant('RECEIVE_CHECKS.PHASE'),
        field: "phase",
        minWidth: 100
      },
      {
        headerName: this.translate.instant('RECEIVE_CHECKS.FUNCTION_CODE'),
        field: "functionCode",
        minWidth: 100
      },
      {
        headerName: this.translate.instant('RECEIVE_CHECKS.ENTER_DATA'),
        field: "enterData",
        minWidth: 100,
        cellStyle: { 'text-align': 'center' },
        cellRendererFramework: ReceiveChecksheetCellRendererComponent
      },
      {
        headerName: this.translate.instant('RECEIVE_CHECKS.SENT_PDF'),
        field: "sentPdf",
        minWidth: 100,
        cellStyle: { 'text-align': 'center' },
        cellRendererFramework: ReceiveChecksheetCellRendererComponent
      },
      {
        headerName: this.translate.instant('RECEIVE_CHECKS.RETURNED_PDF'),
        field: "returnedPdf",
        minWidth: 100,
        cellStyle: { 'text-align': 'center' },
        cellRendererFramework: ReceiveChecksheetCellRendererComponent
      }
    ];

    this.getRowNodeId = function (data) {
      return data.id;
    };

    this.gridOptions = {
      rowData: this.rowData,
      columnDefs: this.columnDefs,
      defaultColDef: {
        resizable: true,
        sortable: true
      },
      accentedSort: true,
      rowSelection: "single",
      rowMultiSelectWithClick: false,
      animateRows: true,
      rowBuffer: 20,
      context: {
        componentParent: this
      },
      paginationPageSize: 10
    }

    this.overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Loading data...</span>';
    this.overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">No rows to show</span>';
  }

  ngOnInit(): void {
  }

  onGridReady(params: any) {
    const allColumnIds = [];
    this.columnDefs.forEach((columnDef) => {
      allColumnIds.push(<ColDef>columnDef);
    });
    this.gridApi = params.api;
    params.api.sizeColumnsToFit();
    // window.addEventListener("resize", function () {
    //   setTimeout(function () {
    //     params.api.sizeColumnsToFit();
    //   });
    // });
    window.addEventListener("resize", this.onWindowResize);
  }
  private onWindowResize = () => {
   
		setTimeout(() => {
			if (this.gridApi) {
				this.gridApi.sizeColumnsToFit();
			}
		}); 
	};
  searchFunc(data: any) {
    this.searchVendor = "";
    this.searchChecksheet = "";
    this.searchAsset = "";
    this.searchDescription = "";
    this.searchSystem = "";
    this.searchAssetGroup = "";
    this.searchPhase = "";
    this.searchFunctionCode = "";
    this.hasText = "";

    var vendorSearch = data.searchWordMap[Constants.SEARCH_KEY_WORDS.RECEIVE_CS_VENDOR];
    if (vendorSearch != undefined) {
      this.searchVendor = vendorSearch
    } else {
      //do nothing
    }

    var checksheetSearch = data.searchWordMap[Constants.SEARCH_KEY_WORDS.RECEIVE_CS_CHECKSHEET];
    if (checksheetSearch != undefined) {
      this.searchChecksheet = checksheetSearch
    } else {
      //do nothing
    }

    var assetSearch = data.searchWordMap[Constants.SEARCH_KEY_WORDS.RECEIVE_CS_ASSET];
    if (assetSearch != undefined) {
      this.searchAsset = assetSearch
    } else {
      //do nothing
    }

    var descriptionSearch = data.searchWordMap[Constants.SEARCH_KEY_WORDS.RECEIVE_CS_ASSET_DESCRIPTION];
    if (descriptionSearch != undefined) {
      this.searchDescription = descriptionSearch
    } else {
      //do nothing
    }

    var systemSearch = data.searchWordMap[Constants.SEARCH_KEY_WORDS.RECEIVE_CS_SYSTEM];
    if (systemSearch != undefined) {
      this.searchSystem = systemSearch
    } else {
      //do nothing
    }

    var assetGroupSearch = data.searchWordMap[Constants.SEARCH_KEY_WORDS.RECEIVE_CS_ASSET_GROUP];
    if (assetGroupSearch != undefined) {
      this.searchAssetGroup = assetGroupSearch
    } else {
      //do nothing
    }

    var phaseSearch = data.searchWordMap[Constants.SEARCH_KEY_WORDS.RECEIVE_CS_PHASE];
    if (phaseSearch != undefined) {
      this.searchPhase = phaseSearch
    } else {
      //do nothing
    }

    var functionCodeSearch = data.searchWordMap[Constants.SEARCH_KEY_WORDS.RECEIVE_CS_FUNCTION_CODE];
    if (functionCodeSearch != undefined) {
      this.searchFunctionCode = functionCodeSearch
    } else {
      //do nothing
    }

    var hasText = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_HAS_THE_WORDS];
    if (hasText != undefined) {
      this.hasText = hasText
    } else {
      //do nothing
    }

    if (this.searchVendor != "" || this.searchChecksheet != "" || this.searchAsset != "" || this.searchDescription != "" ||
      this.searchSystem != "" || this.searchAssetGroup != "" || this.searchPhase != "" || this.searchFunctionCode != "" || this.hasText != "") {
      this.loadingService.showLoading(true, null, "Searching", null);
      this.receiveChecksheetService.getReceiveCheckSheetFilterResult(this.searchVendor, this.searchChecksheet, this.searchAsset, this.searchDescription,
        this.searchSystem, this.searchAssetGroup, this.searchPhase, this.searchFunctionCode, this.hasText).subscribe(data => {
          this.loadingService.hideLoading();
          this.loadData(data.payload);
        },
          error => {
            this.loadingService.hideLoading();
            this.alertService.clear()
            this.alertService.error(error.statusDescription)
          });
    } else {
      this.gridApi.hideOverlay()
    }
  }

  loadData(data: any) {
    var rowData = [];
    let parser = new DOMParser();
    if(data != null){
      data.forEach(element => {
        let desXml = parser.parseFromString(element.assetDecription, "text/xml");
        rowData.push({
          csInstanceId: element.checkSheetInstanceId,
          assetSystemId: element.checksheetAssetSystemId,
          status: element.status,
          vendorName: element.vendorName,
          checksheet: element.checkSheetCode,
          assetId: element.assetId,
          assetNo: element.assetName,
          assetDescription: element.assetDecription != null ? desXml.getElementsByTagName("txt")[0].childNodes[0].nodeValue : "",
          systemId: element.systemId,
          systemName: element.systemName,
          assetGroup: element.assetGroupName,
          phase: element.phaseName,
          functionCode: element.functionCode,
          enterData: "enterData",
          sentPdf: "sentPdf" + ":" + element.status,
          returnedPdf: "returnedPdf" + ":" + element.status + ":" + element.isUploaded
        })
      });
    }
    this.rowData = rowData;
    this.gridApi.paginationProxy.currentPage = 0;
    this.gridOptions.api.sizeColumnsToFit();
  }

  /**
   * Clear taginput items
   */
  removeSelectedReceiveChecksheetTagInputs() {
    this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.RECEIVE_CS_VENDOR, []);
    this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.RECEIVE_CS_CHECKSHEET, []);
    this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.RECEIVE_CS_ASSET, []);
    this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.RECEIVE_CS_ASSET_DESCRIPTION, []);
    this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.RECEIVE_CS_SYSTEM, []);
    this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.RECEIVE_CS_ASSET_GROUP, []);
    this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.RECEIVE_CS_PHASE, []);
    this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.RECEIVE_CS_FUNCTION_CODE, []);
  }

  public ngOnDestroy() {
    this.removeSelectedReceiveChecksheetTagInputs();
    window.removeEventListener("resize", this.onWindowResize);
    this.gridApi.destroy();
    this.gridApi = null;
  }
}