import { Component, OnInit, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FilesService } from '../../files/files.service';
import { LoadingService } from '../../../util/loading/loading.service';
import { SharedService } from '../../../util/shared.service';
import { FilesDataService } from './filesdata.service';
import { WsResponse } from '../../../util/ws-response.model';
import { WsType } from '../../../util/ws-type';
import { AlertService } from '../../../util/alert/alert.service';
import { DependenciesmodalComponent } from '../../../util/dependenciesmodal/dependenciesmodal.component';
import { DependenciesModalService } from '../../../util/dependenciesmodal/dependenciesmodal.service';
import { LocalStorage } from '../../../util/localstorage.service';
import { LoggedUser } from '../../../user/logged-user.model';
import { UserVariable } from '../../../util/common/user-variable';
import { FileUploadService } from '../new-file/file-upload.service';
import { FolderService } from '../../../foldertree/foldertree.service';
import { FilesComponent } from '../files.component';
import { AlertType } from '../../../util/alert/alert.model';
import { FileUploader } from 'ng2-file-upload';
import { CommonUtil } from '../../../util/common/common-util';


@Component({
    selector: 'app-files-data',
    templateUrl: './filesdata.component.html',
    styleUrls: ['./filesdata.component.css']
})

export class FilesDataComponent implements OnInit, OnDestroy {
    @ViewChild('alertToShowMultipleActions') alertToShowMultipleActions: TemplateRef<any>;
    @ViewChild('checkinMultipleImagesTemplate') checkinMultipleImagesTemplate: TemplateRef<any>;
    public isShowProperties: boolean = false;
    public isMinimized: boolean = false;
    public show: boolean = false;
    public isCheckOut: boolean = true;
    public isCheckIn: boolean = true;
    public isUndo: boolean = false;
    public statusEnabled: boolean = false;
    public genPDFEnable: boolean = false;
    public duplicateEnable: boolean = false;
    public diagnoseEnable: boolean = false;
    public queryWorflow: boolean = false;
    public isEdit: boolean = false;
    public isActive: boolean = true;
    public isDelete: boolean = true;
    public activeInactive: string;
    private dependencyData: any = null;
    private checkboxValue: boolean = false;

    bsModalRef: BsModalRef;
    public fileData: any = [];
    public activeId: String = "properties";
    public showGerenateOP: boolean = false;
    public showDownload: boolean = false;
    public shwBuildTopicMap: boolean = false;
    public showworkFlow: boolean = false;
    @Input("inputData") inputData: any;
    private loggedInUser: LoggedUser;
    private deleteUpdateSubscription: any;
    private uploadingFile: File;
    public uploadFileName: string;
    public fileComment: string;
    public selectedFolder: any;
    public alreadyenabled: boolean = false;
    responseStatus: any;
    showAlert: boolean = false;
    public items = [];
    public multipleSelections = false;
    selectedImages: string;
    rowData: any[] = [];
    checkingImagesComment: any;
    checkInData = [];
    selectedThreeDModels: string;
    checkingThreeDModelComment: any;



    constructor(public filesService: FilesService, private translate: TranslateService, private loadingService: LoadingService,
        private sharedService: SharedService, private filesDataService: FilesDataService,
        private alertService: AlertService, private modalService: BsModalService, private fileUploadService: FileUploadService,
        private dependenciesModalService: DependenciesModalService, private folderService: FolderService, private fileComponent: FilesComponent, private commonUtil: CommonUtil) {

        translate.addLangs(["es", "en"]);
        translate.setDefaultLang("en");
        let browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
        filesDataService.setCallback(this);
    }

    ngOnInit() {
    }


    refreshButton() {
        //this.isShowProperties = this.filesService.getShowProperties();
        this.loggedInUser = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER));
        if (this.filesService.getSelectedFileData() != null && this.fileData != this.filesService.getSelectedFileData()) {
            this.fileData = this.filesService.getSelectedFileData();
            this.sharedService.setFileId(this.fileData.id);
            this.sharedService.setFileType(this.fileData.type);
            this.sharedService.setStatus(this.fileData.status);
            this.shwBuildTopicMap = false;
            this.isShowProperties = false;
            this.isMinimized = false;
            this.show = false;
            this.isUndo = false;
            this.statusEnabled = false;
            this.genPDFEnable = false;
            this.duplicateEnable = false;
            this.diagnoseEnable = false;
            this.queryWorflow = false;
            this.isEdit = false;
            this.isActive = true;
            this.isDelete = true;
            this.checkboxValue = false;
            this.showGerenateOP = false;
            this.showDownload = false;
            this.shwBuildTopicMap = false;
            this.showworkFlow = false;
            if (this.fileData != null && this.fileData.type !== undefined) {
                console.log('FilesDataComponent T1 ');
                //this.show = true;
                console.log(this.fileData.type)
                this.genPDFEnable = this.fileData.genPDFEnable == 'Yes';
                this.statusEnabled = this.fileData.statusEnable == 'Yes';
                this.duplicateEnable = this.fileData.duplicateEnable == 'Yes';
                this.diagnoseEnable = this.fileData.diagnoseEnable == 'Yes';
                this.queryWorflow = this.fileData.queryWorkflow == 'Yes';
                this.isDelete = this.fileData.deleteEnabled == 'Yes';
                if (this.fileData.type.split(':')[0] == "Topic" || this.fileData.type.split(':')[0] == "Procedure" || this.fileData.type.split(':')[0] == "TopicMap") {
                    if (this.fileData.type == "Topic") {
                        this.showworkFlow = true && this.fileData.queryWorkflow == 'Yes';
                    }
                    this.isEdit = true;
                } else {
                    this.isEdit = false;
                } 
                if (this.fileData.type.split(':')[0] == "Topic" || this.fileData.type.split(':')[0] == "Procedure" || this.fileData.type.split(':')[0] == "TopicMap" || this.fileData.type.split(':')[0] == "ContentItem") {
                    // if (this.fileData.type.split(':')[0] == "TopicMap") {
                    //     this.shwBuildTopicMap = true;
                    // }
                    this.showGerenateOP = true;
                    if (this.fileData.checkoutUserId != null) {
                        if (this.fileData.checkoutUserId != this.loggedInUser.userId) {
                            this.isCheckOut = false;
                            this.isCheckIn = false;
                            this.isUndo = false;
                            this.isDelete = false;
                            this.sharedService.setIsCheckedIn(false);
                        } else {
                            this.isCheckOut = false;
                            this.isCheckIn = true && this.fileData.checkInEnabled == 'Yes';
                            this.isUndo = true && this.fileData.checkInEnabled == 'Yes';
                            this.isDelete = true && this.fileData.deleteEnabled == 'Yes';
                            this.sharedService.setIsCheckedIn(false);
                        }
                    } else {
                        this.isCheckOut = true && this.fileData.checkoutEnabled == 'Yes';
                        this.isCheckIn = false;
                        this.isUndo = false;
                        this.isDelete = true && this.fileData.deleteEnabled == 'Yes';
                        this.sharedService.setIsCheckedIn(true);
                    }
                } else {
                    this.isCheckOut = this.fileData.checkoutEnabled == 'Yes';
                    this.isCheckIn = false;
                    this.isUndo = false;
                    this.showDownload = true;
                    this.sharedService.setIsCheckedIn(true);
                } 
                if (this.fileData.status == "Active") {
                    this.isActive = true;
                    this.activeInactive = this.translate.instant('FILES_DATA.ACTIVE');
                    this.checkboxValue = false;
                } else if (this.fileData.status == "Inactive") {
                    this.isCheckOut = false;
                    this.isCheckIn = false;
                    this.isUndo = false;
                    this.isEdit = false;
                    this.isActive = false;
                    this.activeInactive = this.translate.instant('FILES_DATA.INACTIVE');
                    this.checkboxValue = true;
                    this.sharedService.setIsCheckedIn(false);
                }
            } else {
                this.isEdit = false;
                this.isCheckOut = false;
                this.isCheckIn = false;
                this.isUndo = false;
                this.isActive = false;
                this.isDelete = false;
                this.sharedService.setIsCheckedIn(false);
            }
        }
        this.showworkFlow = false;
        this.deleteUpdateSubscription = this.sharedService.deleteUpdateExecute().subscribe(option => this.deleteUpdate(option));
        return true;
    }

    isShow() {
        return this.filesService.getShowProperties();
    }

    showProperties() {
        this.show = true;
        this.isShowProperties = this.filesService.getShowProperties();
        this.fileData = this.filesService.getSelectedFileData();
        if (!this.isShowProperties && this.fileData != undefined) {
            this.filesService.setShowProperties(true);
            this.isShowProperties = true;
            //	this.filesService.setSelectedFileData(this.fileData);
            //this.filesService.getFileDataByFileId(itemData, this);
        }
    }

    validateForm(): boolean {
        console.log('validate the form');
        if (this.uploadFileName == undefined) {
            return false;
        }
        if (this.uploadingFile == undefined) {
            return false;
        }
        return true;
    }

    onUpload() {
        if (!this.validateForm()) {
            return;
        }
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        const uploadData = new FormData();
        uploadData.append("fileName", this.uploadingFile.name);
        uploadData.append("title", this.uploadFileName);
        uploadData.append("comment", this.fileComment);
        uploadData.append("parentFolderId", this.folderService.getSelectedFoldeData().id);
        uploadData.append("itemId", this.fileData.id);
        uploadData.append("itemType", this.fileData.type);
        uploadData.append("token", token);
        uploadData.append("file", this.uploadingFile, this.uploadFileName);

        this.fileUploadService.checkInFile(uploadData, this);
    }


    public onSubmitButtonClick() {
        console.log("onsubmit)")
        this.bsModalRef.hide();
        this.onUpload();
    }

    change(evt: any) {
        this.activeId = evt.nextId;
    }

    onFileChanged(event) {
        this.uploadingFile = <File>event.target.files[0];
        if (this.uploadingFile != undefined) {
            this.uploadFileName = this.uploadingFile.name;
        } else {
            this.uploadFileName = '';
        }
    }

	/**
       * Success function for filesdata service.
       * @param data 
       * @param serviceType 
       */
    onSuccess(data: WsResponse, serviceType: WsType): void {
        this.loadingService.hideLoading();
        if (data != null && serviceType == WsType.CM_SYSTEM_CHECKOUT) {
			this.loadingService.hideLoading();
            this.isCheckOut = false;
            this.isCheckIn = true;
            this.isUndo = true;
            this.isDelete = false;
            this.duplicateEnable = false;
            this.filesDataService.getBasicFileData(this.fileData.id, this.fileData.type);
            this.sharedService.setIsCheckedIn(false);
        } else if (data != null && serviceType == WsType.BASIC_FILE_DATA) {
            this.filesService.updateFileTableData(data.payload.checkoutBy, data.payload.lastUpdateTimeStamp, this.fileData.id, this.fileData.type);
            this.sharedService.updateTimeStampRequest(data.payload.lastUpdateTimeStamp, this.fileData.type);
            let date = new Date(data.payload["lastUpdateTimeStamp"]).toLocaleString() != "Invalid Date" ? new Date(data.payload["lastUpdateTimeStamp"]).toLocaleString() : data.payload["lastUpdateTimeStamp"]
            this.filesService.loadFiles(date, this.fileData.id, "lastUpdateTimeStamp");
        } else if (data != null && serviceType == WsType.CM_SYSTEM_UNDO_CHECKOUT) {
            this.isCheckOut = true;
            this.isCheckIn = false;
            this.isUndo = false;
            this.isDelete = true;
            this.duplicateEnable = true;
            this.sharedService.setIsCheckedIn(true);
            this.filesDataService.getBasicFileData(this.fileData.id, this.fileData.type);
        } else if (data != null && data.payload != null && serviceType == WsType.ITEM_DEPENDENCY_LIST) {
            this.checkDepedencies(data.payload, this.fileData.type);
        } else if (data != null && serviceType == WsType.CHECKIN_DOCUMENT_FILE_DATA) {
            this.filesDataService.getBasicFileDataForCheckin(this.fileData.id, this.fileData.type);
            this.isCheckOut = true;
            this.isCheckIn = false;
            this.isUndo = false;
            this.isDelete = true;
            this.duplicateEnable = true;
            this.uploadFileName = "";
            this.fileComment = "";
            this.sharedService.setIsCheckedIn(true);
        } else if (data != null && serviceType == WsType.BASIC_FILE_DATA_CHECKIN) {
            this.filesService.updateFileTableDataCheckin(data.payload.checkoutBy, data.payload.lastUpdateTimeStamp, this.fileData.id, this.fileData.type);
            this.sharedService.updateTimeStampRequest(data.payload.lastUpdateTimeStamp, this.fileData.type);
        } else if (data != null && serviceType == WsType.MULTIPLE_ACTIONS) {
            let response = JSON.parse(data.payload)
            console.log(response)
            this.setAlert(response);
            this.items = [];
        } else if (data != null && WsType.FILE_VIEW == serviceType) {
            this.fileComponent.showTableData(data.payload)
        } else if (data != null && WsType.GET_EXISTANCE == serviceType) {
            let response = JSON.parse(data.payload)
            this.setTableData(response.images.image);
            this.bsModalRef = this.modalService.show(this.checkinMultipleImagesTemplate);
            this.bsModalRef.content.closeBtnName = 'Close';

        } else if (data != null && serviceType == WsType.CHECK_OUT_THREED_MODEL) {
			this.loadingService.hideLoading();
            this.isCheckOut = false;
            this.isCheckIn = true;
            this.isUndo = true;
            this.isDelete = false;
            this.duplicateEnable = false;
            this.filesDataService.getBasicFileData(this.fileData.id, this.fileData.type);
            this.sharedService.setIsCheckedIn(false);
        } else if (data != null && serviceType == WsType.CHECK_OUT_VIDEO) {
			this.loadingService.hideLoading();
            this.isCheckOut = false;
            this.isCheckIn = true;
            this.isUndo = true;
            this.isDelete = false;
            this.duplicateEnable = false;
            console.log(this.fileData.type);
            this.filesDataService.getBasicFileData(this.fileData.id, this.fileData.type);
            this.sharedService.setIsCheckedIn(false);
        }     

        if (data != null && WsType.FILE_DATA_VIEW == serviceType) {

        }

    }

    /**
     * On fail function for filesdata service.
     * @param data 
     * @param serviceType 
     */
    onFail(data: WsResponse, serviceType: WsType): void {
        this.loadingService.hideLoading();
        if (data != null) {
            this.alertService.error(data.statusDescription, true);
        }
    }

    setTableData(image: any): any {
        if (image != null) {
            this.rowData = [];
            for (let i = 0; i < image.length; i++) {
                if (image[i]["imageID"] != "") {
                    if (image[i]["isCheckedOut"] == 0) {
                        this.rowData.push({
                            fileName: image[i]["fileName"],
                            fileStatus: "Image not checked out",
                        });
                    } else {
                        if (image[i]["status"] == 1) {
                            this.rowData.push({
                                fileName: image[i]["fileName"],
                                fileStatus: "Image is ready",
                            });
                            this.checkInData.push(image[i]);
                        } else {
                            //nothing
                        }
                    }
                } else {
                    if ((image[i].status == 0) && (image[i].isCheckedOut == 1)) {
                        this.rowData.push({
                            fileName: image[i]["fileName"],
                            fileStatus: "Image not selected",
                        });
                    } else if ((image[i].status == 0) && (image[i].isCheckedOut == 2)) {
                        this.rowData.push({
                            fileName: image[i]["fileName"],
                            fileStatus: "Image doesn't exist",
                        });
                    } else {
                        console.log(image[i])
                    }
                }
            }
        }
    }

    private setAlert(response, status?, type?) {

        if (response.Topics != undefined) {
            if (response.Topics.Topic.length != undefined) {
                this.items.push("Topics")
                response.Topics.Topic.forEach(item => {
                    this.items.push(item.Name + " : " + item.Status);
                });
            } else {
                this.items.push("Topic")
                this.items.push(response.Topics.Topic.Name + " : " + response.Topics.Topic.Status);
            }
        }
        if (response.TopicMaps != undefined) {
            if (response.TopicMaps.TopicMap.length != undefined) {
                this.items.push("TopicMaps")
                response.TopicMaps.TopicMap.forEach(item => {
                    this.items.push(item.Name + " : " + item.Status);
                });
            } else {
                this.items.push("TopicMap")
                this.items.push(response.TopicMaps.TopicMap.Name + " : " + response.TopicMaps.TopicMap.Status);
            }
        }
        if (response.Files != undefined) {
            if (response.Files.File.length != undefined) {
                this.items.push("Files")
                response.Files.File.forEach(item => {
                    this.items.push(item.Name + " : " + item.Status);
                });
            } else {
                this.items.push("File")
                this.items.push(response.Files.File.Name + " : " + response.Files.File.Status);
            }
        }
        if (response.Images != undefined) {
            if (response.Images.ProjectImage.length != undefined) {
                this.items.push("Images")
                response.Images.ProjectImage.forEach(item => {
                    this.items.push(item.Name + " : " + item.Status);
                });
            } else {
                this.items.push("Image")
                this.items.push(response.Images.ProjectImage.Name + " : " + response.Images.ProjectImage.Status);
            }
        }
        if (response.Procedures != undefined) {
            if (response.Procedures.Procedure.length != undefined) {
                this.items.push("Procedures")
                response.Procedures.Procedure.forEach(item => {
                    this.items.push(item.Name + " : " + item.Status);
                });
            } else {
                this.items.push("Procedure")
                this.items.push(response.Procedures.Procedure.Name + " : " + response.Procedures.Procedure.Status);
            }
        }
        if (response.ThreeDModels!= undefined) {
            if (response.ThreeDModels.threedmodel.length != undefined) {
                this.items.push("ThreeDModels")
                response.ThreeDModels.threedmodel.forEach(item => {
                    this.items.push(item.Name + " : " + item.Status);
                });
            } else {
                this.items.push("ThreeDModel")
                this.items.push(response.ThreeDModels.threedmodel.Name + " : " + response.ThreeDModels.threedmodel.Status);
            }
        }
        if (response.images != undefined) {
            if (response.images.image != null && response.images.image != undefined)
                response.images.image.forEach(item => {
                    this.items.push(item.Name + " : " + item.Status);
                });
        }
        this.bsModalRef = this.modalService.show(this.alertToShowMultipleActions);
        this.bsModalRef.content.closeBtnName = 'Close';

    }

    enableAll() {
        let itemListString = localStorage.getItem(LocalStorage.SELECTED_ITEMS);
        this.filesDataService.enableAll(itemListString);
    }

    disableAll() {
        let itemListString = localStorage.getItem(LocalStorage.SELECTED_ITEMS);
        this.filesDataService.disableAll(itemListString);
    }

    checkinAll(comment) {
        this.bsModalRef.hide();
        let checkingComment = comment;
        let itemListString = localStorage.getItem(LocalStorage.SELECTED_ITEMS);
        this.filesDataService.checkinAll(itemListString, checkingComment);
    }

    checkoutAll() {
        let itemListString = localStorage.getItem(LocalStorage.SELECTED_ITEMS);
        this.filesDataService.checkoutAll(itemListString);
    }

    deleteAll() {
        let itemListString = localStorage.getItem(LocalStorage.SELECTED_ITEMS);
        this.filesDataService.deleteAll(itemListString);
    }

    undoCheckoutAll() {
        let itemListString = localStorage.getItem(LocalStorage.SELECTED_ITEMS);
        this.filesDataService.undoCheckoutAll(itemListString);
    }

    duplicateAll() {
        let itemListString = localStorage.getItem(LocalStorage.SELECTED_ITEMS);
        this.filesDataService.duplicateAll(itemListString);
    }

    workFlowActionAll() {

    }

    // getImageExistence(comments) {
    //     this.checkingImagesComment = comments;
    //     this.bsModalRef.hide();
    //     let itemListString = localStorage.getItem(LocalStorage.SELECTED_ITEMS);
    //     let folderId = localStorage.getItem(LocalStorage.FOLDER_ID);
    //     let title = this.selectedImages;
    //     this.filesDataService.getImageExistenceStatus(itemListString, folderId, title);

    // }

    checkinMultipleImages() {
        let nodes = "";
        let folderId = localStorage.getItem(LocalStorage.FOLDER_ID);
        let comment = this.checkingImagesComment;
        let itemListString = localStorage.getItem(LocalStorage.SELECTED_ITEMS);
        // let itemListDom = this.checkInData ;
        // const itemListDom = JSON.stringify("{\"Images\": {"+ "\"Image\": {" +this.checkInData+ "}}}")
        const itemListDom = JSON.stringify(this.checkInData)
        let newString = JSON.stringify(itemListDom);
        for (let entry of this.rowData) {
            nodes = nodes + entry["fileName"] + "^";
        }
        this.filesDataService.checkingMultipleImages(itemListDom, folderId, comment, nodes);

    }

    checkIn(comments) {
        console.log("this.fileData checkIn")
        if (this.fileData != undefined) {
            this.bsModalRef.hide();
            this.filesDataService.ckeckinDocumentFromFileData(this.fileData.id, this.fileData.type, comments);
        }
    }

    checkOut() {
        console.log(this.fileData)
        if (this.fileData != undefined) {
            this.loadingService.showLoading(true, false, this.translate.instant('FILES_DATA.LOAD_CHECKOUT'), 0);
            this.filesDataService.checkoutDocumentById(this.fileData.id, this.fileData.type);
        }
    }

    undo() {
        if (this.fileData != undefined) {
            this.loadingService.showLoading(true, false, this.translate.instant('FILES_DATA.LOAD_UNDO_CHECKOUT'), 0);
            this.filesDataService.undoCheckoutDocumentById(this.fileData.id, this.fileData.type);
        }
    }

    setButtonStatus() {
        if (this.isActive) {
            //this.dependenciesModalWithComponent(this.translate.instant('FILES_DATA.DISABLE_CONFIRM'), false, true);
            if (this.isCheckOut) {
                this.isDelete = true;
            } else {
                this.isDelete = false;
            }
            this.isCheckOut = false;
            this.isCheckIn = false;
            this.isUndo = false;
            this.isEdit = false;
            this.isActive = false;
            this.activeInactive = this.translate.instant('FILES_DATA.INACTIVE');
            this.checkboxValue = true;
        } else {
            //this.dependenciesModalWithComponent(this.translate.instant('FILES_DATA.ENABLE_CONFIRM'), false, true);
            if (this.fileData.checkoutUserId != null) {
                this.isCheckOut = false;
                this.isCheckIn = true;
                this.isUndo = true;
            } else {
                this.isCheckOut = true;
                this.isCheckIn = false;
                this.isUndo = false;
            } if (this.fileData.type == "Topic" || this.fileData.type == "Procedure") {
                this.isEdit = true;
            } else {
                this.isEdit = false;
            }
            this.isActive = true;
            this.activeInactive = this.translate.instant('FILES_DATA.ACTIVE');
            this.checkboxValue = false;
        }
        this.sharedService.setParent(null);
    }

    active() {
        if (this.isActive) {
            this.dependenciesModalWithComponent(this.translate.instant('FILES_DATA.DISABLE_CONFIRM'), false, true);
        } else {
            this.dependenciesModalWithComponent(this.translate.instant('FILES_DATA.ENABLE_CONFIRM'), false, true);
        }
    }

    buildTopicMap() {
        this.sharedService.openTopicMapBuilder(this.fileData.id, this.fileData.name, 'edit',this.fileData.content);
        this.sharedService.openTabRequestWithId(this.fileData.id, this.fileData.name, 'TopicMapBuilder', null, null);
    }

    download() {

    }

    delete() {
        this.dependenciesModalWithComponent(this.translate.instant('FILES_DATA.DELETE_CONFLICTS'), false, true);
        //this.dependenciesModalService.getDependecies(this.fileData.id, this.fileData.type);
        //this.dependenciesModalService.setCallback(this);
    }

    generateOutput() {

    }

    duplicate() {

    }

    workflow() {

    }

    edit() {
        if (this.fileData.type.split(':')[0] == 'Topic') {
            // console.log('[edit] (Topic)');
            this.sharedService.openTabRequestWithId(this.fileData.id, this.fileData.name, "Editor", null, null);
        } else if (this.fileData.type.split(':')[0] == 'Procedure') {
            // console.log('[edit] (Procedure)');
            this.sharedService.openTabRequestWithId(this.fileData.id, this.fileData.name, "Procedures", null, null);
        }
        //this.isEdit = false;
    }

    dependenciesModalWithComponent(header: String, dflag: boolean, cflag: boolean) {
        const initialState : object = {
            headerTitle: header,
            delFlag: dflag,
            cancelFlag: cflag,
            checkboxVal: this.checkboxValue
        };
        this.sharedService.setParent(this);
        this.bsModalRef = this.modalService.show(DependenciesmodalComponent, { initialState, class: 'modal-lg' });
    }

    checkDepedencies(payload: any, type: String) {
        if (type = "Topic") {
            this.dependencyData = payload.TopicMap;
        }
        if (this.dependencyData != null) {
            if (this.dependencyData.length > 0) {
                this.dependenciesModalWithComponent(this.translate.instant('FILES_DATA.DELETE_CONFLICTS'), true, false);
            } else {
                this.dependenciesModalWithComponent(this.translate.instant('FILES_DATA.DELETE_CONFLICTS'), false, false);
            }
        }
    }

    private deleteUpdate(option: any) {
        if (option != null) {
            this.isCheckOut = option.flag;
            this.isActive = option.flag;
            this.checkboxValue = option.checkboxVal;
            this.activeInactive = option.tooltip;
        }
    }

    ngOnDestroy() {
        this.deleteUpdateSubscription.unsubscribe();
    }

    openModal(template: TemplateRef<any>, size: string) {
        if (size == 'lg') {
            this.bsModalRef = this.modalService.show(template, { class: 'modal-lg' });
        } else {
            this.bsModalRef = this.modalService.show(template);
        }
    }

    refresh() {
        this.filesService.getFolderContentByFolderId(this);
    }

    ok() {
        this.items = [];
        this.refresh();
        this.bsModalRef.hide();
    }

    cancel() {
        this.items = [];
        this.refresh();
        this.bsModalRef.hide();
    }

    handleFileInput(images) {
        const selectedNodes = images.length;
        this.selectedImages = "";
        for (let i = 0; i < selectedNodes; i++) {
            this.selectedImages = this.selectedImages + images[i].name + '::';
        }
    }

    // handleThreeDModelFileInput(threedmodels){
    //     const selectedNodes = threedmodels.length;
    //     this.selectedThreeDModels = "";
    //     for (let i = 0; i < selectedNodes; i++) {
    //         this.selectedThreeDModels = this.selectedThreeDModels + threedmodels[i].name + '::';
    //     }
    // }

    // checkinMultipleThreeDModels(comments) {
    //     this.checkingThreeDModelComment = comments;
    //     this.bsModalRef.hide();
    //     let itemListString = localStorage.getItem(LocalStorage.SELECTED_ITEMS);
    //     let folderId = localStorage.getItem(LocalStorage.FOLDER_ID);
    //     let title = this.selectedThreeDModels;
    //     this.filesDataService.getThreeDModelExistenceStatus(itemListString, folderId, title);

        
    // }
}
