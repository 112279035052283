import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ConfigureWorkflowTemplateComponent } from '../../workflowtemplateviewmodal/configure-workflow.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import xml2js from 'xml2js'; 

@Component({
    selector: 'child-cell',
    template: '<input type="radio" name="addtemp1" id="wf_addprojecttemp" [value]="id" (change)="onSelectionChange()">'
 })

export class SelectProjectWorkflowTemplateCellRenderer implements ICellRendererAngularComp{
    bsModalRef: BsModalRef;   
    id: string = ""
    public params: any
    configTasks: any = ""
    public processName: string;
    public taskList: any = [];
    public configParameters: any;
    
    constructor(private modalService: BsModalService){
    }

    refresh(): boolean{
        return true
    }

    agInit(params: any){
        this.params = params;
        this.id = this.params.data.id;
        this.processName = this.params.data.templateName;
        this.configTasks = this.params.data.configTasks;
        this.configParameters = this.params.data.configParameters;
    }

    onSelectionChange(){
        this.parseXML(this.configTasks, this.processName).then(data => {
          this.taskList = data
          const initialState = {
            taskList: this.taskList,
            templateId: this.id,
            configParameters: this.configParameters
          }
          this.bsModalRef = this.modalService.show(ConfigureWorkflowTemplateComponent, { class: 'modal-lg', backdrop: 'static', keyboard: false, initialState });
        });   
    }

      /**
       * Process XML and get values
       * @param data 
       * @param processName 
       */
      parseXML(data: any, processName: string){
        return new Promise(resolve => {
          var k: string | number,
            arr = [],
            parser = new xml2js.Parser(
              {
                trim: true,
                explicitArray: true
              });
          parser.parseString(data, function (err, result) {
             if(result != null && result != undefined){
                var obj = result.WorkflowTaskList;
                for (k in obj.WorkflowTask) {
                var item = obj.WorkflowTask[k];
                arr.push({
                    taskName: item.TaskName[0],
                    orderNo: item.TaskOrder[0],
                    processName: processName,
                    automatic: '0',
                    role: null,
                    userId: null
                });
                }
             } else{
                //do nothing
             }
             resolve(arr);
          });
        });
      }
}