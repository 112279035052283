import { Injectable } from '@angular/core';
import { ServiceUrls } from '../util/service-urls';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { WsResponse } from '../util/ws-response.model';
import { UserVariable } from '../util/common/user-variable';
import { map } from 'rxjs/operators'
import { CommonUtil } from '../util/common/common-util';
import { WsCallback } from '../util/ws-callback.interface';
import { WsType } from '../util/ws-type';


@Injectable({
  providedIn: 'root'
})
export class ReportDataService {
	private callback: WsCallback;
	constructor(private http: HttpClient, private commonUtil: CommonUtil) { }

	public setCallback(callBack: WsCallback) {
        this.callback = callBack;
    }

	getDataToReport(widgetId: string): Observable<WsResponse>{
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
		let url = this.selectURL(widgetId);
		const options = { params: new HttpParams().set("token", token) }			
		return this.http.get(url, options).
			pipe(
				map(response => {
					var modified = JSON.parse(JSON.stringify(response));
					return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				}),
				catchError(error => {
					var modified = JSON.parse(JSON.stringify(error.error));
					var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
					return throwError(res)
				})
			);
	}
	private selectURL(widgetId: string) {
		switch (widgetId) {
			case 'tags_total_tag_reference':
				return ServiceUrls.GET_TOTAL_TAG_REFERENCE;
			case 'tags_total_tag_reference_ref_view':
				return ServiceUrls.GET_TOTAL_TAG_REFERENCE_FOR_REFERENCE_VIEW;
			case 'tags_total_authors':
				return ServiceUrls.GET_TOTAL_AUTHORS;
			case 'statements_word_count':
				break;
			case 'statements_total_reference':
				return ServiceUrls.GET_TOTAL_STATEMENT_REFERENCE;
			case 'topic_total_topics':
				return ServiceUrls.GET_TOTAL_TOPIC_COUNT;
			case 'document_total_reference':
				return ServiceUrls.GET_TOTAL_DOCUMENT_REFERENCE;
			case 'topic_total_revisions':
				return ServiceUrls.GET_REPORT_TOPIC_REVISION_COUNT;
			case 'document_total_types':
				return ServiceUrls.GET_REPORT_DOCUMENT_TYPE_REFERENCE;
			case 'total_course':
				return ServiceUrls.GET_TOTAL_COURSE_REFERENCE;
			case 'topic_count':
				return ServiceUrls.GET_TOPIC_COUNT_REFERENCE;
			case 'topic_content_count':
				return ServiceUrls.GET_TOPIC_CONTENT_COUNT_REFERENCE;
			case 'learning_check_count':
				return ServiceUrls.GET_LEARNING_CHECK_COUNT_REFERENCE;	
			case 'game_count':
				return ServiceUrls.GET_GAME_COUNT_REFERENCE;
			case 'total_3d_model':
				return ServiceUrls.GET_TOTAL_3D_MODEL_REFERENCE;
			case 'total_videos':
				return ServiceUrls.GET_TOTAL_VIDEOS_REFERENCE;		
			case 'component_total_reference':
				return ServiceUrls.GET_TOTAL_COMPONENT_REFERENCE;
			case 'system_total_reference':
				return ServiceUrls.GET_TOTAL_SYSTEM_REFERENCE;
			case 'system_total_reference_sys_view':
				return ServiceUrls.GET_TOTAL_SYSTEM_REFERENCE_FOR_SYSTEM_VIEW;
			case 'facility_total_reference':
				return ServiceUrls.GET_TOTAL_FACILITY_REFERENCE;
			case 'procedure_Graphic_reference':
				return ServiceUrls.GET_TOTAL_PROCEDURE_GRAPHIC_REFERENCE;
			case 'procedure_total_reference':
				return ServiceUrls.GET_TOTAL_PROCEDURE_GRAPHIC_REFERENCE;
			default:
				break;
		}
	}


	getDataListToReport(widgetId: string, used: boolean): Observable<WsResponse>{
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
		let url = this.selectDataListURL(widgetId, used);
		// console.log("report data service " + url);
		const options = { params: new HttpParams().set("token", token) }			
		return this.http.get(url, options).
			pipe(
				map(response => {
					var modified = JSON.parse(JSON.stringify(response));
					return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				}),
				catchError(error => {
					var modified = JSON.parse(JSON.stringify(error.error));
					console.log(JSON.stringify(modified));
					var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
					return throwError(res)
				})
			);
	}

	public getTagDependenciesByTag(request: string, wsCallBack: WsCallback) {		
		this.http.post(ServiceUrls.GET_TAG_DEPENDENCIES_BY_TAG_FOR_REPORTS, request).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_TAG_DEPENDENCIES_BY_TAG);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_TAG_DEPENDENCIES_BY_TAG);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_TAG_DEPENDENCIES_BY_TAG);
				}
			}
		);
	}









	getDependecies(itemId: string, itemType: string, action: string) {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateLogin(token)) {
            return;
        }

        const options = { params: new HttpParams().set("token", token).set("itemId", itemId).set("itemType", itemType).set("action", action) }
        let url = this.getDependenciesURL(itemId, itemType);
        this.http.get(url, options).
            subscribe(
                data => {
                    var modified = JSON.parse(JSON.stringify(data));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                    this.callback.onSuccess(res, WsType.ITEM_DEPENDENCY_LIST);
                },
                error => {
                    if (error.status != '') {
                        var val = (error as HttpErrorResponse).error;
                        var modified = JSON.parse(JSON.stringify(val));
                        var res = new WsResponse(
                            modified.status.description,
                            modified.status.name,
                            modified.status.code,
                            modified.payload);
                        this.callback.onFail(res, WsType.ITEM_DEPENDENCY_LIST);
                    } else {
                        //browser related issues
                        if (navigator.onLine == false) {
                            var res = new WsResponse("We lost the connection with the server");
                        } else {
                            var res = new WsResponse("Unknown error happened");
                        }
                        this.callback.onFail(res, WsType.ITEM_DEPENDENCY_LIST);
                    }
                });
    }

	public getDependenciesURL(itemId: String, itemType: String) {
        let url: any;
        if (itemType == "Document") {
            url = ServiceUrls.DEPENDENCY + itemId + '/getDependenciesForReports.json';
        } else if (itemType == "Facility") {
            url = ServiceUrls.DEPENDENCY + itemId + '/getDependenciesForReports.json';
        } else if (itemType == "Statement") {
            url = ServiceUrls.DEPENDENCY + "/" + itemId + '/getDependenciesForReports.json';
        } else if (itemType == "Component") {
            url = ServiceUrls.DEPENDENCY + itemId + '/getDependenciesForReports.json';
        } else if (itemType == "System") {
            url = ServiceUrls.DEPENDENCY + itemId + '/getDependenciesForReports.json';

        } else if (itemType == "SystemName") {
            url = ServiceUrls.DEPENDENCY + itemId + '/getDependenciesForReports.json';

        } else if (itemType == "SystemNo") {
            url = ServiceUrls.DEPENDENCY + itemId + '/getDependenciesForReports.json';

        } else if (itemType == "SystemView") {
            url = ServiceUrls.DEPENDENCY + itemId + '/getDependenciesForReports.json';

        }


        return url;
    }






	private selectDataListURL(widgetId: string, used: boolean) {
		switch (widgetId) {
			case 'tags_total_tag_reference':
				if(used){
					return ServiceUrls.GET_USED_TAG_LIST;
				} 
					return ServiceUrls.GET_TOTAL_TAG_LIST;
			case 'tags_total_tag_reference_ref_view':
				return ServiceUrls.GET_USED_TAG_LIST_FOR_REF_VIEW;
				
			case 'tags_total_authors':
				return ServiceUrls.GET_TOTAL_AUTHORS_LIST
				
			case 'statements_word_count':
				break;
			case 'statements_total_reference':
				if(used){
					return ServiceUrls.GET_USED_STATEMENT_LIST;
				} 
					return ServiceUrls.GET_TOTAL_STATEMENT_LIST;
				
			case 'topic_total_topics':
				return ServiceUrls.GET_REPORT_TOPIC_LIST;
			case 'document_total_reference':
				if(used){
					return ServiceUrls.GET_REPORT_USED_DOCUMENT_LIST;
				} 
					return ServiceUrls.GET_REPORT_DOCUMENT_LIST;
			case 'topic_total_revisions':
				return ServiceUrls.GET_REPORT_TOPIC_REVISION_LIST;
			case 'document_total_types':
				if(used){
					return ServiceUrls.GET_REPORT_USED_DOCUMENT_TYPE_LIST;
				} 
					return ServiceUrls.GET_REPORT_DOCUMENT_TYPE_LIST;
			
			case 'component_total_reference':
				if(used){
					return ServiceUrls.GET_USED_COMPONENT_LIST;
				} 
					return ServiceUrls.GET_COMPONENT_LIST;
				
			case 'system_total_reference':
				if(used){
					return ServiceUrls.GET_USED_SYSTEM_LIST;
				} 
					return ServiceUrls.GET_TOTAL_SYSTEM_LIST;

			case 'system_total_reference_sys_view':
					return ServiceUrls.GET_USED_SYSTEM_VIEW_LIST;

			case 'facility_total_reference':
				if(used){
					return ServiceUrls.GET_USED_FACILITY_LIST;
				} 
					return ServiceUrls.GET_TOTAL_FACILITY_LIST;
			case 'procedure_Graphic_reference':
					return ServiceUrls.GET_PROCEDURE_GRAPHIC_LIST;
			case 'procedure_total_reference':
					return ServiceUrls.GET_PROCEDURE_LIST;
			case 'total_course':
				  return ServiceUrls.GET_TOTAL_COURSE_LIST;
			case 'topic_count':
				return ServiceUrls.GET_COURSE_TOPIC_LIST;
			case 'topic_content_count':
				return ServiceUrls.GET_COURSE_TOPIC_CONTENT_LIST;
			case 'learning_check_count':
				return ServiceUrls.GET_COURSE_LEARNING_CHECK_LIST;
			case 'game_count':
				return ServiceUrls.GET_COURSE_GAME_LIST;
			case 'total_3d_model':
				return ServiceUrls.GET_3D_MODEL_LIST;
			case 'total_videos':
				return ServiceUrls.GET_VIDEO_LIST;
			default:
				break;
		}
	}


	getImageList(): Observable<WsResponse>{
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
		let url = ServiceUrls.GET_REPORT_IMAGES_LIST;
		// console.log("report data service " + url);
		const options = { params: new HttpParams().set("token", token) }			
		return this.http.get(url, options).
			pipe(
				map(response => {
					var modified = JSON.parse(JSON.stringify(response));
					return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				}),
				catchError(error => {
					var modified = JSON.parse(JSON.stringify(error.error));
					console.log(JSON.stringify(modified));
					var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
					return throwError(res)
				})
			);
	}


	getImages(imagedata: any) {
        let a = JSON.stringify(imagedata);
        // console.log('a', a);
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        let url = this.getImagesUrl(token);
        return this.http.post(url, JSON.stringify(new ImageRequest(a))).
            pipe(
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                }),
                catchError(error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    return throwError(res)
                })
            );

    }

	public getImagesUrl(token: String) {
        let url = ServiceUrls.IMAGE_SEARCH_IMAGE + '?token=' + token;
        return url;
	}



	
	getImage(id: string, imagePath: string) {
        // let a = JSON.stringify(imagedata);
        // console.log('a', a);
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        let url = this.getImageUrl(token);
        return this.http.post(url, JSON.stringify(new ImageByPath(id, imagePath ))).
            pipe(
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                }),
                catchError(error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    return throwError(res)
                })
            );

    }


	public getImageUrl(token: String) {
        let url = ServiceUrls.IMAGE_SEARCH_IMAGE_ON_CLICK
		+ '?token=' + token;
        return url;

	}

	public getTagsSystemsHierarchy(): Observable<WsResponse>{
		let token = UserVariable.getUserToken();
		let url = this.getTagsSystemsHierarchyUrl(token);
		const options = { params: new HttpParams().set("token", token) }			
		return this.http.get(url, options).
			pipe(
				map(response => {
					var modified = JSON.parse(JSON.stringify(response));
					return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				}),
				catchError(error => {
					var modified = JSON.parse(JSON.stringify(error.error));
					console.log(JSON.stringify(modified));
					var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
					return throwError(res)
				})
			);

	}

	public getTagsSystemsHierarchyUrl(token: String) {
		let url = ServiceUrls.GET_TAGS_SYSTEMS_HIERARCHY
		+ '?token=' + token;
        return url;
	}

	/**
     *  download tagSystemHierarchy Export (zip)
     */
    public downloadTagSystemHierarchyZIP() {
		let token = UserVariable.getUserToken();
		let url = this.getDownloadTagSystemHierarchyZIPUrl(token);
        return this.http.get(url, {
			observe: 'response', responseType: "blob", params: new HttpParams().set("token", token)
        });
	}
	
	public getDownloadTagSystemHierarchyZIPUrl(token: String){
		let url = ServiceUrls.GET_TAG_SYSTEM_HIERARCHY_DOWNLOAD_ZIP_FILE
		+ '?token=' + token;
        return url;
	}
	
}

class ImageRequest {
	constructor(private imagedata: any) { }
}

class ImageByPath{
	constructor(private id: string, private imagePath: string) { }
}