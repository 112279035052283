import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UserVariable } from '../util/common/user-variable';
import { ServiceUrls } from '../util/service-urls';
import { WsResponse } from '../util/ws-response.model';
import { WsCallback } from '../util/ws-callback.interface';
import { WsType } from '../util/ws-type';
import { Observable, throwError } from 'rxjs';
import { map, timeout, catchError } from 'rxjs/operators'
import { CommonUtil } from '../util/common/common-util';

@Injectable()
export class WebEditorService {
    private callback: WsCallback;

    constructor(private http: HttpClient, private commonUtil: CommonUtil) {
    }

    public setCallback(callBack: WsCallback) {
        this.callback = callBack;
    }

    /**
     * Checkin documents from editor.
     * @param itemId Document Id. 
     * @param itemType Document Type.
     * @param checkinComments Checkin comments.
     * @param content Editor content xml.
     * @param title Topic Title.
     */
    ckeckinDocumentFromEditor(itemId: string, itemType: string, checkinComments: string, content: string, title: string): Observable<WsResponse> {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        let url = this.getCheckinDocumentEditorURL(token);
        return this.http.post(url, JSON.stringify(new Request(itemId, itemType, checkinComments, content, title))).
        pipe(timeout(120000),
            map(response => {
                var modified = JSON.parse(JSON.stringify(response));
                return new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,                    
                    modified.payload);
            }),
            catchError(error => {
                if (error.message == "TimeoutError") {
                    var res = new WsResponse("", "", error.message, "");
                    return throwError(res);
                }
                else if (error.status == "0") {
                    var res = new WsResponse("", error.status, "", "");
                    return throwError(res)
                }
                else{
                    var modified = JSON.parse(JSON.stringify(error.error));
                    var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,                    
                    modified.payload);
                    return throwError(res)
                }
            })
        );
    }

     /**
     * Undo checkout document by item id and item type.
     * @param itemId Item Id
     * @param itemType Item type UNDO_CHECKOUT_DOCUMENT_BY_EDITOR
     */
    undoCheckoutDocumentById(itemId: string, itemType: string): Observable<WsResponse> {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        let url = this.getundoCheckoutDocumentByIdURL(token);
        return this.http.post(url, JSON.stringify(new UndoCheckoutRequest(itemId, itemType))).pipe(
            map(response => {
                var modified = JSON.parse(JSON.stringify(response));
                return new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,                    
                    modified.payload);
            }),
            catchError(error => {
                var modified = JSON.parse(JSON.stringify(error.error));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,                    
                    modified.payload);
                return throwError(res)
            })
        );
    }

/**
    * Define URL for undo checkout document by id.
    */
   public getundoCheckoutDocumentByIdURL(token: String) {
    let url = ServiceUrls.CM_SYSTEM_DOCUMENTS + 'undoCheckoutDocumentById.json?token=' + token;
    return url;
}




/**
 * Define URL for checkin document from editor.
 */
public getCheckinDocumentEditorURL(token: String) {
    let url = ServiceUrls.CM_SYSTEM_DOCUMENTS + 'checkinTopicByIdEditor.json?token=' + token;
    return url;
}

/**
 * Save documents from editor.
 * @param itemId Document Id. 
 * @param itemType Document Type.
 * @param content Editor content xml.
 */
saveDocumentFromEditor(itemId: string, itemType: string, content: string, title: string) {
    let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
    let url = this.getSaveDocumentEditorURL(token);
    this.http.post(url, JSON.stringify(new Request(itemId, itemType, null, content, title))).pipe(timeout(120000)).
    subscribe(
        data => {
            var modified = JSON.parse(JSON.stringify(data));
            var res = new WsResponse(
                modified.status.description,
                modified.status.code,
                modified.status.name,
                modified.payload);
            this.callback.onSuccess(res, WsType.SAVE_DOCUMENT_EDITOR);
        },
        error => {
            if (error.message == "TimeoutError") {
                var res = new WsResponse("", "", error.message, "");
                this.callback.onFail(res, WsType.SAVE_DOCUMENT_EDITOR);
            }
            else if (error.status == "0") {
                var res = new WsResponse("", error.status, "", "");
                this.callback.onFail(res, WsType.SAVE_DOCUMENT_EDITOR);
            }
            else{
                var modified = JSON.parse(JSON.stringify(error));
                var res = new WsResponse(
                    modified.error.status.description,
                    modified.error.status.code,
                    modified.error.status.name,
                    modified.error.payload);
                this.callback.onFail(res, WsType.SAVE_DOCUMENT_EDITOR);
            }
        });
}

getPunchlists(): Observable<WsResponse> {
    let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
    const options = { params: new HttpParams().set("token", token) }
    let url = this.getPunchlistsByUserURL();
    return this.http.get(ServiceUrls.GET_PUNCHLISTS, options).pipe(
        map(response => {
            var modified = JSON.parse(JSON.stringify(response));
            return new WsResponse(
                modified.status.code,
                modified.status.name,
                modified.status.description,
                modified.payload);
        }),
        catchError(error => {
            var modified = JSON.parse(JSON.stringify(error.error));
            var res = new WsResponse(
                modified.status.code,
                modified.status.name,
                modified.status.description,
                modified.payload);
            return throwError(res)
        })
    );
}
public getPunchlistsByUserURL() {
    let url = ServiceUrls.GET_PUNCHLISTS;
    return url;
}

getMCC(): Observable<WsResponse> {
    let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
    const options = { params: new HttpParams().set("token", token) }
    let url = this.getMCCByUserURL();
    return this.http.get(ServiceUrls.GET_ALL_MC, options).pipe(
        map(response => {
            var modified = JSON.parse(JSON.stringify(response));
            return new WsResponse(
                modified.status.code,
                modified.status.name,
                modified.status.description,
                modified.payload);
        }),
        catchError(error => {
            var modified = JSON.parse(JSON.stringify(error.error));
            var res = new WsResponse(
                modified.status.code,
                modified.status.name,
                modified.status.description,
                modified.payload);
            return throwError(res)
        })
    );
}
public getMCCByUserURL() {
    let url = ServiceUrls.GET_ALL_MC;
    return url;
}

getPrecommissioning(): Observable<WsResponse> {
    let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
    const options = { params: new HttpParams().set("token", token) }
    let url = this.getPrecommissioningByUserURL();
    return this.http.get(ServiceUrls.GET_PRECOMMISIONING, options).pipe(
        map(response => {
            var modified = JSON.parse(JSON.stringify(response));
            return new WsResponse(
                modified.status.code,
                modified.status.name,
                modified.status.description,
                modified.payload);
        }),
        catchError(error => {
            var modified = JSON.parse(JSON.stringify(error.error));
            var res = new WsResponse(
                modified.status.code,
                modified.status.name,
                modified.status.description,
                modified.payload);
            return throwError(res)
        })
    );
}
public getPrecommissioningByUserURL() {
    let url = ServiceUrls.GET_PRECOMMISIONING;
    return url;
}


getAllSystems(): Observable<WsResponse> {
    let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
    const options = { params: new HttpParams().set("token", token) }
    let url = this.getMCCByUserURL();
    return this.http.get(ServiceUrls.GET_SYSTEMLIST, options).pipe(
        map(response => {
            var modified = JSON.parse(JSON.stringify(response));
            return new WsResponse(
                modified.status.code,
                modified.status.name,
                modified.status.description,
                modified.payload);
        }),
        catchError(error => {
            var modified = JSON.parse(JSON.stringify(error.error));
            var res = new WsResponse(
                modified.status.code,
                modified.status.name,
                modified.status.description,
                modified.payload);
            return throwError(res)
        })
    );
}
public getAllSystemsByUserURL() {
    let url = ServiceUrls.GET_SYSTEMLIST;
    return url;
}
/**
 * Get checksheet status data for commissioning widget.
 * @param systemId : String List of selected systems.
 * @param  csType : Selected system Types.
 */
getChecksheetSummary(systemId: string, csType: string): Observable<WsResponse> {
    let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
    const options = { params: new HttpParams().set("token", token).set("systemId", systemId).set("csType", csType) }
    let url = this.getChecksheetSummaryByUserURL();
    return this.http.get(ServiceUrls.GET_CHECKSHEET_SUMMARY, options).pipe(
        map(response => {
            var modified = JSON.parse(JSON.stringify(response));
            return new WsResponse(
                modified.status.code,
                modified.status.name,
                modified.status.description,
                modified.payload);
        }),
        catchError(error => {
            var modified = JSON.parse(JSON.stringify(error.error));
            var res = new WsResponse(
                modified.status.code,
                modified.status.name,
                modified.status.description,
                modified.payload);
            return throwError(res)
        })
    );
}
public getChecksheetSummaryByUserURL() {
    let url = ServiceUrls.GET_CHECKSHEET_SUMMARY;
    return url;
}

/**
 * Get checksheet  data for commissioning widget.
 * @param systemId : String List of selected systems.
 * @param  csType : Selected system Types.
 */
getChecksheetData(systemId: string, csType: string): Observable<WsResponse> {
    let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
    const options = { params: new HttpParams().set("token", token).set("systemId", systemId).set("csType", csType) }
    let url = this.getChecksheetDataByUserURL();
    return this.http.get(ServiceUrls.GET_CHECKSHEET_DATA, options).pipe(
        map(response => {
            var modified = JSON.parse(JSON.stringify(response));
            return new WsResponse(
                modified.status.code,
                modified.status.name,
                modified.status.description,
                modified.payload);
        }),
        catchError(error => {
            var modified = JSON.parse(JSON.stringify(error.error));
            var res = new WsResponse(
                modified.status.code,
                modified.status.name,
                modified.status.description,
                modified.payload);
            return throwError(res)
        })
    );
}
public getChecksheetDataByUserURL() {
    let url = ServiceUrls.GET_CHECKSHEET_DATA;
    return url;
}


/**
 * Get punchlist data for commissioning widget.
 * @param systemId : String List of selected systems. 
 * @param category : String  selected category.
 */
getPunchlistTableData(systemId: string, category: string, punchlistStatus:string): Observable<WsResponse> {
    let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
    const options = {
        params: new HttpParams().set("token", token).set("systemId", systemId).
            set("category", category).set('punchlistStatus', punchlistStatus)
    }

    let url = this.getPunchlistDataByURL();
    return this.http.get(ServiceUrls.GET_PUNCHLIST_TABLE_DATA, options).pipe(
        map(response => {
            var modified = JSON.parse(JSON.stringify(response));
            return new WsResponse(
                modified.status.description,
                modified.status.code,
                modified.status.name,
                modified.payload);
        }),
        catchError(error => {
            var modified = JSON.parse(JSON.stringify(error.error));
            var res = new WsResponse(
                modified.status.description,
                modified.status.code,
                modified.status.name,
                modified.payload);
            return throwError(res)
        })
    );
}

public getPunchlistDataByURL() {
    let url = ServiceUrls.GET_PUNCHLIST_TABLE_DATA;
    return url;
}

/**
 * Get scc data for commissioning widget.
 * @param systemId : String List of selected systems. 
 */
getPrecommissioningData(systemId: string): Observable<WsResponse> {
    let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
    const options = { params: new HttpParams().set("token", token).set("systemId", systemId)}
    let url = this.getPrecommissioningDataByUserURL();
    return this.http.get(ServiceUrls.GET_PRECOMMISSIONING_DATA, options).pipe(
        map(response => {
            var modified = JSON.parse(JSON.stringify(response));
            return new WsResponse(
                modified.status.description,
                modified.status.code,
                modified.status.name,
                modified.payload);
        }),
        catchError(error => {
            var modified = JSON.parse(JSON.stringify(error.error));
            var res = new WsResponse(
                modified.status.description,
                modified.status.code,
                modified.status.name,
                modified.payload);
            return throwError(res)
        })
    );
}
public getPrecommissioningDataByUserURL() {
    let url = ServiceUrls.GET_PRECOMMISSIONING_DATA;
    return url;
}

/**
 * Get mcc data for commissioning widget.
 * @param systemId : String List of selected systems. 
 */
geMCCTableData(systemId: string): Observable<WsResponse> {
    let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
    const options = { params: new HttpParams().set("token", token).set("systemId", systemId)}
    let url = this.getMCCTableDataByUserURL();
    return this.http.get(ServiceUrls.GET_MCC_TABLE_DATA, options).pipe(
        map(response => {
            var modified = JSON.parse(JSON.stringify(response));
            return new WsResponse(
                modified.status.description,
                modified.status.code,
                modified.status.name,
                modified.payload);
        }),
        catchError(error => {
            var modified = JSON.parse(JSON.stringify(error.error));
            var res = new WsResponse(
                modified.status.description,
                modified.status.code,
                modified.status.name,
                modified.payload);
            return throwError(res)
        })
    );
}
public getMCCTableDataByUserURL() {
    let url = ServiceUrls.GET_MCC_TABLE_DATA;
    return url;
}

/**
 * Get checksheet status data for commissioning widget.
 * @param systemId : String List of selected systems.
 * @param  csType : Selected system Types.
 */
getChecksheetStatus(systemId: string, csType: string): Observable<WsResponse> {
    let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
    const options = { params: new HttpParams().set("token", token).set("systemId", systemId).set("csType", csType) }
    let url = this.getChecksheetStatusByUserURL();
    return this.http.get(ServiceUrls.GET_CHECKSHEET_STATUS, options).pipe(
        map(response => {
            var modified = JSON.parse(JSON.stringify(response));
            return new WsResponse(
                modified.status.code,
                modified.status.name,
                modified.status.description,
                modified.payload);
        }),
        catchError(error => {
            var modified = JSON.parse(JSON.stringify(error.error));
            var res = new WsResponse(
                modified.status.code,
                modified.status.name,
                modified.status.description,
                modified.payload);
            return throwError(res)
        })
    );
}
public getChecksheetStatusByUserURL() {
    let url = ServiceUrls.GET_CHECKSHEET_STATUS;
    return url;
}

getVendors(): Observable<WsResponse> {
    let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
    const options = { params: new HttpParams().set("token", token)}
    let url = this.getVendorsByUserURL();
    return this.http.get(ServiceUrls.GET_VENDOR_LIST, options).pipe(
        map(response => {
            var modified = JSON.parse(JSON.stringify(response));
            return new WsResponse(
                modified.status.code,
                modified.status.name,
                modified.status.description,
                modified.payload);
        }),
        catchError(error => {
            var modified = JSON.parse(JSON.stringify(error.error));
            var res = new WsResponse(
                modified.status.code,
                modified.status.name,
                modified.status.description,
                modified.payload);
            return throwError(res)
        })
    );
}
public getVendorsByUserURL() {
    let url = ServiceUrls.GET_VENDOR_LIST;
    return url;
}


/**
 * Get STN/PTN data for commissioning widget.
 * @param systemId : String List of selected systems.
 * @param  vendorIds : List of selected vendors.
 */
getSTNS(systemId: string, vendorIds: string): Observable<WsResponse> {
    let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
    const options = { params: new HttpParams().set("token", token).set("systemId", systemId).set("vendorIds", vendorIds)  }
    let url = this.getSTNSByUserURL();
    return this.http.get(ServiceUrls.GET_STNPTN_TABLE_DATA, options).pipe(
        map(response => {
            var modified = JSON.parse(JSON.stringify(response));
            return new WsResponse(
                modified.status.code,
                modified.status.name,
                modified.status.description,
                modified.payload);
        }),
        catchError(error => {
            var modified = JSON.parse(JSON.stringify(error.error));
            var res = new WsResponse(
                modified.status.code,
                modified.status.name,
                modified.status.description,
                modified.payload);
            return throwError(res)
        })
    );
}
public getSTNSByUserURL() {
    let url = ServiceUrls.GET_STNPTN_TABLE_DATA;
    return url;
}

/**
 * Checkout document from editor.
 * @param documentId : String Document Id.
 * @param  documentType : String Document Type.
 */
checkoutDocumentFromEditor(documentId: string, documentType: string): Observable<WsResponse> {
    let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
    const options = { params: new HttpParams().set("token", token).set("documentId", documentId).set("documentType", documentType)  }
    return this.http.get(ServiceUrls.CHECKOUT_DOCUMENT_EDITOR, options).pipe(
        map(response => {
            var modified = JSON.parse(JSON.stringify(response));
            return new WsResponse(
                modified.status.code,
                modified.status.name,
                modified.status.description,
                modified.payload);
        }),
        catchError(error => {
            var modified = JSON.parse(JSON.stringify(error.error));
            var res = new WsResponse(
                modified.status.code,
                modified.status.name,
                modified.status.description,
                modified.payload);
            return throwError(res)
        })
    );
}

public getcheckoutDocumentFromEditorURL() {
    let url = ServiceUrls.CHECKOUT_DOCUMENT_EDITOR;
    return url;
}

	/**
	 * Get converted xml for ck editor. 
	 * @param topicId doument Id. 
	 */
	getEditorXMLByTopicId(topicId: any, topicType: any) {
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
		const options = { params: new HttpParams().set("token", token) }
		let url = "";
		if (topicType == "Topic") {
			url = this.getTopicForEditorURL(topicId);
		} else if (topicType == "Procedure") {
			url = this.getProcedureForEditorURL(topicId);
		}

		this.http.get(url, options).
			subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.code,
					modified.status.name,
					modified.status.description,
					modified.payload);
				//console.log("response " + JSON.stringify(data))
				this.callback.onSuccess(res, WsType.TOPIC_CHECKOUT_VIEW);
			},
			error => {
				var modified = JSON.parse(JSON.stringify(error));
				var res = new WsResponse(
					modified.status.code,
					modified.status.name,
					modified.status.description,
					modified.payload);
				this.callback.onFail(res, WsType.TOPIC_CHECKOUT_VIEW);
			});
    }
    

    	/**
	 * Define URL for get editor topic xml.
	 * @param fileId 
	 */
	public getTopicForEditorURL(fileId: string) {
		let url = ServiceUrls.TOPIC_CHECKOUT_VIEW + 'topics/' + fileId + '/GetEditorTopicContent.json'
		return url;
	}

	/**
	 * Define URL for get editor procedure xml.
	 * @param fileId 
	 */
	public getProcedureForEditorURL(fileId: string) {
		let url = ServiceUrls.TOPIC_CHECKOUT_VIEW + 'procedure/' + fileId + '/GetEditorProcedureContent.json'
		return url;
	}


/**
 * Define URL for checkin document from editor.
 */
public getSaveDocumentEditorURL(token: String) {
    let url = ServiceUrls.CM_SYSTEM_DOCUMENTS + 'saveTopicByIdEditor.json?token=' + token;
    return url;
}

getCategoryList(catType: string): Observable<WsResponse> {
    let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
    let url = ServiceUrls.getCategoryListByCategoryType(catType)
    const options = { params: new HttpParams().set("token", token).set("categoryType", catType) }	
    return this.http.get(url, options).pipe(
        map(response => {
            var modified = JSON.parse(JSON.stringify(response));
            return new WsResponse(
                modified.status.code,
                modified.status.name,
                modified.status.description,
                modified.payload);
        }),
        catchError(error => {
            var modified = JSON.parse(JSON.stringify(error.error));
            var res = new WsResponse(
                modified.status.code,
                modified.status.name,
                modified.status.description,
                modified.payload);
            return throwError(res)
        })
    );
}

}

class Request {
    constructor(private itemId: string, private itemType: string, 
        private checkinComments:string,private content:string, private title:string) { }
}

class UndoCheckoutRequest{
    constructor(private itemId: string, private itemType: string) { }

}

