import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { TagsService } from '../tags.service';
import { AlertService } from 'src/app/util/alert/alert.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SharedService } from 'src/app/util/shared.service';
import { UserVariable } from 'src/app/util/common/user-variable';
import { ICellRendererParams, IAfterGuiAttachedParams } from "@ag-grid-enterprise/all-modules";
import { WsCallback } from 'src/app/util/ws-callback.interface';
import { WsResponse } from 'src/app/util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';
import { DependencyModalComponent } from '../tag-classification/dependency-modal/dependency-modal.component';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { ConfirmationCallback } from '../confirmation-modal/confirmation-callback';
import { TranslateService } from '@ngx-translate/core';
import { CommonUtil } from 'src/app/util/common/common-util';

@Component({
	selector: 'app-delete-cell-renderer',
	template: `
  <div class="col-sm">
    <button type="button"  [disabled]="false" class="btn btn-sm btn-outline-dark py-0 my-1 px-1 border-0" (click)="deleteButtonClick()">
      <i class="far fa-trash-alt" aria-hidden="true"></i>
    </button>
  </div>`,
	styles: []
})
export class DeleteCellRendererComponent implements OnInit, ICellRendererAngularComp, WsCallback, ConfirmationCallback {
	private token: string;
	public cellData: any;
	private bsModalRef: BsModalRef;
	private componentParent: any;

	constructor(private tagService: TagsService, private alertService: AlertService,
        private sharedService: SharedService, private modalService: BsModalService, private translate: TranslateService, private commonUtil: CommonUtil) {
        this.token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(this.token)) {
            return;
        }
	}

	ngOnInit() { }

	refresh(params: any): boolean {
		return true;
	}

	agInit(params: ICellRendererParams): void {
		this.cellData = params.data;
		this.componentParent = params.context.componentParent;
	}

	afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
	}

	/**
	 * Clicks on the delete button.
	 */
	public deleteButtonClick() {
		let token = UserVariable.getUserToken();
		if(!this.commonUtil.validateToken(token)){
			return;
		}
		
		var request: any = {
		  "itemId": this.cellData.id,
		  "itemType": 'ValueListValue',
		  "action": 'delete',
		  "userToken": token
		};
		this.tagService.getTagDependenciesByTag(JSON.stringify(request), this);
	}

	/**
	 * Callback method to track successful web service calls
	 * @param data response data from the web service call
	 * @param serviceType type of the web service call
	 */
	onSuccess(data: WsResponse, serviceType: WsType) {
		if (serviceType == WsType.TOGGLE_TAG_CLASSIFICATION_ATTRIBUTE_REQUIRE_STATUS) {
			this.alertService.success(data.statusDescription, false);
		} else if (serviceType == WsType.ITEM_DEPENDENCY_LIST) {
			this.alertService.success(data.statusDescription, false);
		} else if (serviceType == WsType.GET_TAG_DEPENDENCIES_BY_TAG) {
			var payload = JSON.parse(data.payload);
			if (payload.HasDependencies == 0) {
				this.showDeleteConfirmation();
			} else {
				this.dependenciesModalWithComponent(payload.TagDependency);
			}
		} else if (serviceType == WsType.DELETE_VALUE_LIST_ATTRIBUTE) {
			let alert = this.translate.instant('TAG_VALUE_LIST.DELETE_VALUE_LIST_ATTRIBUTE');
			this.alertService.success(alert);
			this.componentParent.loadAttributes();
		}
	}

	/**
	 * Callback method to track unsuccessful web service calls
	 * @param data response data from the web service call
	 * @param serviceType type of the web service call
	 */
	onFail(data: WsResponse, serviceType: WsType) {
		if (serviceType == WsType.TOGGLE_TAG_CLASSIFICATION_ATTRIBUTE_REQUIRE_STATUS) {
			this.alertService.error(data.statusDescription, false);
		} else if (serviceType == WsType.ITEM_DEPENDENCY_LIST) {
			this.alertService.error(data.statusDescription, false);
		} else if (serviceType == WsType.GET_TAG_DEPENDENCIES_BY_TAG) {
			this.alertService.error(data.statusDescription, false);
		} else if (serviceType == WsType.DELETE_VALUE_LIST_ATTRIBUTE) {
			this.alertService.error(data.statusDescription);
		}
	}

	/**
	 * Shows delete confirmation popup
	 */
	showDeleteConfirmation() {
		let title = this.translate.instant('TAG_VALUE_LIST.DELETE_CONFIRM');
		let message = this.translate.instant('TAG_VALUE_LIST.DELETE_CONFIRM_MESSAGE_ATTRIBUTE');
		const initialState = {
			token: this.token,
			attributeId: this.cellData.id,
			confirmationTitle: title,
			confirmationMessage: message,
			callBack: this
		};
		this.bsModalRef = this.modalService.show(ConfirmationModalComponent, { initialState });
	}

	/**
	 * Shows dependency list modal
	 * @param tagList dependency list
	 */
	dependenciesModalWithComponent(tagList: any[]) {
		const initialState = {
			tagDependency: tagList,
			title: 'Delete Conflicts'
		};
		this.bsModalRef = this.modalService.show(DependencyModalComponent, { initialState });
		this.bsModalRef.content.closeBtnName = 'Close';
	}

	/**
	 * User clicks on the delete button in the deletion confirmation popup
	 */
	onDeleteConfirm() {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
		var data: any = {
            "attributeId": this.cellData.id,
            "userToken": token
		};
		this.tagService.deleteValueListAttribute(data, this);
		this.bsModalRef.hide();
	}

	/**
	 * User clicks on the decline button in the deletion confirmation popup
	 */
	onDeleteDecline() {
		this.bsModalRef.hide()
	}
}
