<div class="modal-header">
	<h4 class="modal-title pull-left">{{title}}</h4>
	<button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body py-1">
	<div class="container-fluid py-1 modal-body-scroll" [style.height.px]="divHeight">
		<div class="justify-content">
			<div id="documenttypetree" class="mt-2 ml-1">
			</div>


			<!-- remove attributes because attributes not shows in this modal. it shows in other place -->
			<!-- <div class="col-sm-6">
				<div *ngIf="documentTypeSelected">
					<div *ngFor="let attibute of documentTypeAttributes">
						<div *ngIf="attibute.isText">
							<table>
								<th></th>
								<th></th>
								<tr>
									<td width="100">
										<label>{{attibute.attributeName}}
											<sup>
												<i *ngIf="attibute.required=='yes'" class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i>
											</sup>
										</label>
									</td>
									<td width="200">
										<input #texts value="{{attibute.value}}" id="{{attibute.attributeId}}" (change)="change(texts.value, attibute.required, attibute, attibute.attributeId); texts.blur()">
									</td>
								</tr>
							</table>

						</div>
						<div *ngIf="!attibute.isText">
							<table>
								<th></th>
								<th></th>
								<tr>
									<td width="100">
										<label>{{attibute.attributeName}}
											<sup>
												<i *ngIf="attibute.required=='yes'" class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i>
											</sup>
										</label>
									</td>
									<td width="200">
										<select #options [(ngModel)]="attibute.value" id="{{attibute.attributeId}}" (ngModelChange)="select(attibute.attributeId)">
											<option *ngFor="let item of attibute.items" [ngValue]="item.id">
												{{item.componentValue}}
											</option>
										</select>
									</td>
								</tr>
							</table>

						</div>
					</div>
				</div>
			</div> -->

		</div>
	</div>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-default" (click)="clickOnCancelButton()">Cancel</button>
	<button type="button" class="btn btn-warning" (click)="clickOnSaveButton()">Select</button>
</div>