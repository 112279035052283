<div class="container-fluid px-0">
	<div class="row px-3">
		<div class="col-sm-8 px-0">
			<p class="mb-2 h5">
				<b>{{ 'STATEMENT_SEARCH_VIEW.HEADER' | translate }}</b>
			</p>
		</div>
		<div class="col-sm-4 px-0">
			<button *ngIf="changeLayout" type="submit" id="statement_add "
				class="btn btn-primary btn-sm float-right reusable_btn my-1" [disabled]="!enableAddStatement" (click)="add()">
				<i class="fas fa-plus"></i>
			</button>
		</div>
		<div class="col-sm-12 px-0">
			<div class="input-with-icon" *ngIf="!enableSearchStatement">
				<input #symbiosis_search class="form-control" name="statement_search" placeholder="Search" style="padding-right: 60px;" 
					[disabled]="!enableSearchStatement"/>
			</div>
			<div class="input-with-icon" *ngIf="enableSearchStatement">
				<symbiosis-search id="statementsearch" [comp]="'statement'" (searchFunc)="searchFunc($event)"
					(searchBarEnterFunc)="searchBarEnterFunc($event)"></symbiosis-search>
			</div>
		</div>
	</div>
	<hr class="my-2" />
</div>

<!-- *ngIf="expandedView == suv.EXPANDED_VIEW" -->
<div class="container-fluid px-0  pb-4 mb-2">
	<!-- <div>
		<br>{{ 'STATEMENT_SEARCH_VIEW.CATEGORY' | translate }}: {{ 'STATEMENT_SEARCH_VIEW.ALL' | translate }}
		<ng-container *ngFor="let subcategory of subCategoryList; let i = index">
			>>
			<select *ngIf="subCategoryList[i]!=null && categoryName[i]==null" id="statement_select" [(ngModel)]="selectedId"
			 (ngModelChange)="selectCategory($event)">
				<option *ngFor="let item of subCategoryList[i]" [ngValue]="item.id">
					{{item.name}}
				</option>
			</select>
			<button *ngIf="categoryName[i]!=null" id="statement_category_close" onclick="style.display='none';" (click)="closeCategory(i)">
				{{categoryName[i]}} X</button>
		</ng-container>
	</div> -->

	<div *ngIf="expandedView == suv.EXPANDED_VIEW" class="my-2">
		<ag-grid-angular #agGrid style="width: 100%; height: 375px;" class="ag-theme-balham" 
			[gridOptions]="gridOptions" [enableFilter]="true" [rowData]="rowData" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }" [columnDefs]="columnDefs"
			(rowClicked)="onRowClicked($event)" [getRowNodeId]="getRowNodeId" (gridReady)="onGridReady($event)"
			[pagination]="true" [paginationPageSize]="10" [overlayLoadingTemplate]="overlayLoadingTemplate"
			[overlayNoRowsTemplate]="overlayNoRowsTemplate">
		</ag-grid-angular>
	</div>

	<div *ngIf="expandedView != suv.EXPANDED_VIEW" class="my-2">
		<ag-grid-angular #agGrid style="width: 100%; height: 375px;" class="ag-theme-balham" 
			[gridOptions]="gridOptions" [enableFilter]="true" [rowData]="rowData" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }" [columnDefs]="columnDefs"
			(rowDoubleClicked)="onRowDoubleClicked($event)" (rowClicked)="onRowClicked($event)" [getRowNodeId]="getRowNodeId" (gridReady)="onGridReady($event)"
			[pagination]="true" [paginationPageSize]="10" [overlayLoadingTemplate]="overlayLoadingTemplate"
			[overlayNoRowsTemplate]="overlayNoRowsTemplate">
		</ag-grid-angular>
	</div>

	<div class="card my-1 pb-4 mb-2" *ngIf="display">
		<div class="card-header py-1 px-2">
			<p class="float-left statement_header mb-0">{{statementTitle}}</p>
			<button type="button" class="close float-right" aria-label="Close" (click)="close()">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="card-body p-2" >
			<div  [innerHTML] = "statementDescription | safeHtml" >	</div>
		</div>
	</div>
</div>