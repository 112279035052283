import { Component, OnInit, Input, HostListener, TemplateRef } from '@angular/core';
import { SharedService } from '../../util/shared.service';
import { THREED_MODEL_UI_VIEW, SessionStorageConst } from '../../util/constants';
import { Constants, UI_LAYOUT, LocalStorageConst } from '../../util/constants';
import { BsModalService } from 'ngx-bootstrap/modal'
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService } from '../../util/alert/alert.service';
import { GridOptions } from "@ag-grid-enterprise/all-modules";
import { VideosService } from './videos.service';
import { SystemsService } from '../systems/systems.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.css']
})
export class VideosComponent implements OnInit {

	public divHeight: number = (window.innerHeight) * 4 / 5;
	base64Data: any;
	base64DataImage: any;
	@HostListener('window:resize', ['$event'])
	onresize(event?) {
		this.divHeight = (window.innerHeight) * 4 / 5
	}

	public constants: any

	public videoName: string = 'Video Name'
	modalRef: BsModalRef;
	private docType: string = "";
	private description: string = "";
	private id: string = "";
	public title: string = "";
	public width: string = "";
	public height: string = "";
	private count = 0;
	changeLayout: boolean = false
	public imageUIV: any = THREED_MODEL_UI_VIEW;
	private hasText: string = "";
	public gridOptions: GridOptions;
	public videos: Array<any> = [];
	public url: String;
	public urlList: Array<any> = [];
	public selectedURL: any;
	public selectedVideoURL: any;
	public selectedVideoPosterframeURL: any;

	thumbnailVideo: any;
	fullVideo: any;
	videoPath: any;
	videoThumbPath: any;
	videoSrc: any;
	thumbPath: any;
	threed: any;
	public selectedVideo: any = {
		'id': '',
		'largeVideo': '',
		'thumbnailVideo': '',
		'title': '',
		'folderID': '',
		'folderPath': ''
	};
	public flippedVideo: any = {
		'id': '',
		'largeTVideo': '',
		'thumbnailVideo': '',
		'title': '',
		'folderID': '',
		'folderPath': ''
	}
	public modalConfig: any = {
		backdrop: true,
		ignoreBackdropClick: true,
		class: 'modal-lg',
		keyboard: false
	}
	public isOpen: boolean = false
	public videoSearchState: string = Constants.LOADING_STATES.INITIAL

	constructor(private sharedService: SharedService,
		private modalService: BsModalService, private alertService: AlertService, private VideosService: VideosService,
		private systemsService: SystemsService, private translate: TranslateService
	) {
		this.constants = Constants;
		translate.addLangs(["es", "en"]);
		translate.setDefaultLang("en");
		let browserLang = translate.getBrowserLang();
		translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
	}

	ngOnInit() {
		let uiLayout = JSON.parse(sessionStorage.getItem(SessionStorageConst.UI_LAYOUT));
		this.videos = this.VideosService.getVideoList();
		this.videoSearchState = this.VideosService.getVideoSearchState();
		if (uiLayout == UI_LAYOUT.UIL_2) {
			this.changeLayout = false
		} else {
			this.changeLayout = true
		}
		this.urlList = this.VideosService.getURLList();
	}

	ngAfterViewInit(): void {
		//Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
		//Add 'implements AfterViewInit' to the class.
		this.videos = this.VideosService.getVideoList();
		this.videoSearchState = this.VideosService.getVideoSearchState();
		this.sharedService.expandReusablesExcute().subscribe(option => this.changeComponent(option))
	}

	changeComponent(option: any) {
		console.log('[changeComponent] (option) ' + JSON.stringify(option));
	}

	searchFunc(event: any) {
		this.resetSelected()
		this.videos = [];
		console.log('[searchFunc] (event) ' + JSON.stringify(event));
		console.log('[searchFunc] (video.length) ' + JSON.stringify(this.videos.length));
		this.divHeight = (window.innerHeight) * 4 / 5;

		this.docType = "";
		this.description = "";
		this.hasText = "";
		this.count = 0;

		this.videoSearchState = Constants.LOADING_STATES.LOADING

		//assign value for the fields from searchWordMap
		var docType = event.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_VIDEO_DOCTYPE];
		if (docType != undefined) {
			this.docType = docType;
		} else {
			//no docType
		}
		var description = event.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_VIDEO_DESCRIPTION];
		if (description != undefined) {
			this.description = description;
		} else {
			//no description
		}
		var hasText = event.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_HAS_THE_WORDS];
		if (hasText != undefined) {
			this.hasText = hasText;
		} else {
			//no has text
		}
		if (this.docType != "" || this.description != "" || this.hasText != "") {
			let to: any = false;
			if (to) { clearTimeout(to); }
			to = setTimeout(() => {
				this.VideosService.searchVideo(this.docType, this.description, this.hasText).subscribe(
					data => {
						var obj = JSON.parse(data.payload);
						if (obj.videos.video) {
							if (obj.videos.video.length > 0 || obj.videos.video.id) {		
								this.getVideoDetail(obj)
							} else {
								this.videoSearchState = Constants.LOADING_STATES.EMPTY_RESULT
							}
						} else {
							//no videos
							this.videoSearchState = Constants.LOADING_STATES.EMPTY_RESULT
						}
					},
					error => {
						this.alertService.clear()
						this.alertService.error(error.statusDescription)
					});
			}, 200);
		} else {
			this.videoSearchState = Constants.LOADING_STATES.EMPTY_RESULT
		}

	}

	error() {
		this.alertService.error("wrong value inserted");
	}

	getVideoDetail(obj: any) {
		this.videoSearchState = Constants.LOADING_STATES.HAS_RESULT;
		this.VideosService.setVideoSearchState(this.videoSearchState)
		let videoList = obj.videos.video;
		if (obj.videos.video.id) {
			let item = obj.videos.video;
			//only one element

			let video = {
				'id': item.id,
				'largeVideo': item.fullVideo,
				'thumbnailVideo': '',
				'title': item.title,
				'folderId': item.folderId,
				'folderPath': item.folderPath
			}
			this.videos.push(video)
		} else {
			//list of videos
			videoList.forEach((item: { id: string; fullVideo: string; thumbnailVideo: string; title: string; folderId: string; folderPath: string;}) => {
				let video = {
					'id': item.id,
					'largeVideo': item.fullVideo,
					'thumbnailVideo':'',
					'title': item.title,
					'folderId': item.folderId,
					'folderPath': item.folderPath
				}
				this.videos.push(video)
			});
			
		}
		this.VideosService.setVideoList(this.videos)
		this.getThumbnailURL(obj);
	}

	public getThumbnailURL(obj: any){
		this.urlList = [];
		this.videoSearchState = Constants.LOADING_STATES.HAS_RESULT;
		this.VideosService.setVideoSearchState(this.videoSearchState)
		let videoList = obj.videos.video;
		if (obj.videos.video.id) {
			let video = obj.videos.video;
			//only one element
			this.VideosService.getThumbnailURL(video.id).subscribe(
				data => {
					if (data.payload != null) {
						this.url = data.payload;
						let urls = {
							'id': video.id,
							'url': this.url,
						}
						this.updateArrayVideo(video.id, data.payload);
						this.urlList.push(urls);
					} else {
						//do nothing
					}
				},
				error => {
					this.alertService.error(error.statusDescription)
			});

		} else {
			//list of videos
			videoList.forEach((item: { id: string; fullVideo: string; thumbnailVideo: string; title: string; }) => {

				this.VideosService.getThumbnailURL(item.id).subscribe(
					data => {
						if (data.payload != null) {
							this.url = data.payload;
							//this.url = "https://youtu.be/gRqj9l51oY0"
							let urls = {
								'id': item.id,
								'url': this.url,
							}
							this.updateArrayVideo(item.id, data.payload);
							this.urlList.push(urls);
						} else {
							//do nothing
						}
					},
					error => {
						this.alertService.error(error.statusDescription)
				});
			});
		}
			this.VideosService.setURLList(this.urlList)
	}
	
	public updateArrayVideo(id: string, url: String) {
		let video = this.videos.find(item => item.id === id)
		video.thumbUrl = url
	}


	// imageArray(thumb: any) {
	// 	this.fullimagearr.push({
	// 		'image': thumb
	// 	});
	// 	console.log('this.fullimagearr', this.fullimagearr)
	// }

	searchBarEnterFunc(event: any) {
		this.resetSelected()
		console.log('[searchBarEnterFunc] (event) ' + JSON.stringify(event));
		this.videos = [];
		this.divHeight = (window.innerHeight) * 4 / 5;
		this.videoSearchState = Constants.LOADING_STATES.LOADING
		this.docType = "";
		this.description = "";
		this.count = 0;
		//check fro empty values in search bar
		if (this.checkForEmptyValuesInSearchBar(event)) {
			let docArray = event.split(" ");
			for (var l = 0; l <= docArray.length - 1; l++) {
				let letter = docArray[l].split(":");
				if (letter != "") {
					for (var i = 0; i < letter.length - 1; i++) {
						if (letter[i] == "docType") {
							this.docType = letter[i + 1];
						} else if (letter[i] == "description") {
							this.description = letter[i + 1];
						} else {
							if (this.count == 0) {
								this.count = 1;
								this.error();
							} else {
								// do nothing
							}
							this.count = 1;
						}
					}
				}
			}
			if (this.docType != "" || this.description != "") {
				let to: any = false;
				if (to) { clearTimeout(to); }
				to = setTimeout(() => {
					this.VideosService.searchVideo(this.docType, this.description, this.hasText).subscribe(
						data => {
							var obj = JSON.parse(data.payload);
							this.getVideoDetail(obj)
						},
						error => {
							this.alertService.clear()
							this.alertService.error(error.statusDescription)
						});
				}, 200);
			}
		} else {
			this.videoSearchState = Constants.LOADING_STATES.EMPTY_RESULT
		}
	}

	/**
     * check for empty values in search bar
     * @param data 
     */
	public checkForEmptyValuesInSearchBar(data: any): boolean {
		let docArray = data.split(" ");
		if (docArray.length > 1) {
			return true
		} else {
			if (docArray[0] != '') {
				return true
			} else {
				return false
			}
		}
	}

	showSearchOptionEE(event: any) {
		console.log('[showSearchOptionEE] (event) ' + JSON.stringify(event));
		if (event) {
			this.divHeight = (window.innerHeight) * 1.7 / 5
		} else {
			this.divHeight = (window.innerHeight) * 4 / 5
		}

	}

	showLargeVideo(template: TemplateRef<any>, selectedVideo: any, selectedId: any) {
		if(this.selectedVideo.id == null || this.selectedVideo.id == ''){
			this.selectedVideo = selectedVideo
			if (selectedId != null) {

				this.VideosService.getPosterframeURL(selectedId).subscribe(
					data => {
						if (data.payload != null) {
							this.selectedVideoPosterframeURL = data.payload;
							console.log("this.selectedVideoPosterframeURL  " + this.selectedVideoPosterframeURL)
							// this.modalRef = this.modalService.show(template, this.modalConfig);
						} else {
							//do nothing
						}
					},
					error => {
						this.alertService.error(error.statusDescription)
				});

				this.VideosService.getVideoURL(selectedId).subscribe(
					data => {
						if (data.payload != null) {
							this.selectedVideoURL = data.payload;
							console.log("this.selectedVideoURL  " + this.selectedVideoURL)
							this.modalRef = this.modalService.show(template, this.modalConfig);
						} else {
							//do nothing
						}
					},
					error => {
						this.alertService.error(error.statusDescription)
				});

				// this.VideosService.getPosterframeURL(selectedId).subscribe(
				// 	data => {
				// 		if (data.payload != null) {
				// 			this.selectedVideoPosterframeURL = data.payload;
				// 			console.log("this.selectedVideoPosterframeURL  " + this.selectedVideoPosterframeURL)
				// 			// this.modalRef = this.modalService.show(template, this.modalConfig);
				// 		} else {
				// 			//do nothing
				// 		}
				// 	},
				// 	error => {
				// 		this.alertService.error(error.statusDescription)
				// });
				//this.modalRef = this.modalService.show(template, this.modalConfig);

			}
		}
	}

	public modelRefCloseEvent() {
		this.modalRef.hide()
		this.selectedVideo = {
			'id': '',
			'largeVideo': '',
			'thumbnailVideo': '',
			'title': '',
			'folderID': '',
			'folderPath': ''
		}
	}

	/**
	 * Achini Randeni
	 * Check the status of the video and insert
	 * @param video 
	 */
	public getVideoInfo(video: any){
		this.VideosService.getPosterframeURL(video.id).subscribe(
			data => {
				if (data.payload != null) {
					this.selectedVideoPosterframeURL = data.payload;
					console.log("this.selectedVideoPosterframeURL  " + this.selectedVideoPosterframeURL)
					// this.modalRef = this.modalService.show(template, this.modalConfig);
				} else {
					//do nothing
				}
			},
			error => {
				this.alertService.error(error.statusDescription)
		});

		this.VideosService.getVideoInfoById(video.id).subscribe(
			data => {
				if(data.payload != null){
					if(data.payload.videoStatus != null && data.payload.videoStatus != 'Active'){
						this.alertService.error("Cannot insert disabled video");
					}else{
						this.insertAsVideo(video);
					}
				}else{

				}
		},
		error => {
			this.alertService.error(error.statusDescription)
		});
	}

	/**
	 * insert image as figure button click event 
	 * both modal button event and popover button event
	 * @param video 
	 */
	public insertAsVideo(video: any) {
		console.log('[insertAsVideo] (video.title) ' + JSON.stringify(video.title));
		if (video.id != null) {
			this.VideosService.getVideoURL(video.id).subscribe(
				data => {
					if (data.payload != null) {
						this.selectedVideoURL = data.payload;
						console.log("this.selectedVideoURL2  " + this.selectedVideoURL)
						const myObj = {
							id: video.id,
							largeImage: video.largeVideo,
							thumbnailImage: video.thumbnailVideo,
							title: video.title,
							width: video.width,
							height: video.height,
							path: video.path,
							thumbPath: video.thumbPath,
							type: 'videoAsFigure',
							imgsrc: video.videosrc, 
							selectedURL: this.selectedVideoURL,
							posterframeURL:this.selectedVideoPosterframeURL
				
						};
						const myObjStr = JSON.stringify(myObj);
						let event = JSON.parse(myObjStr);
				
						let tabType = SharedService.getTabComponentType();
						if (tabType == 'Editor') {
							SharedService.insertEditorRequest(event);
						} else if (tabType == 'Procedures') {
							SharedService.insertProceduresRequest(event);
						} else if (tabType == 'IceCourse') {
							SharedService.insertICECourseRequest(event);
						}
					} else {
						//do nothing
					}
				},
				error => {
					this.alertService.error(error.statusDescription)
			});

		}
	}

	/**
	 * ngx-bootstrap popover onshown event 
	 * Emits an event when the popover is shown
	 * @param threeDM 
	 */
	public onShown(video: any) {
		console.log('[onShown] (threeDM.title) ' + JSON.stringify(video.title));
		this.selectedVideo = video
	}

	/**
	 * flip image when user click on image title
	 * @param video 
	 */
	public flipVideo(threeDM: any) {
		this.flippedVideo = threeDM
		if(this.urlList.length > 0){
			this.urlList.forEach((url: { id: string; url: string; }) => {
				if(url.id == threeDM.id){
					this.selectedURL = url.url
				}
			});
		}
	}

	/**
	 * unflip image 
	 */
	public unFlipVideo() {
		this.flippedVideo = {
			'id': '',
			'largeVideo': '',
			'thumbnailVideo': '',
			'title': '',
			'folderID': '',
			'folderPath': ''
		}
	}

	public resetSelected() {
		this.flippedVideo = {
			'id': '',
			'largeVideo': '',
			'thumbnailVideo': '',
			'title': '',
			'folderID': '',
			'folderPath': ''
		}

		this.selectedVideo = {
			'id': '',
			'largeVideo': '',
			'thumbnailVideo': '',
			'title': '',
			'folderID': '',
			'folderPath': ''
		}
	}

	public goToVideoLocation(){
		let data = {
			isSearch: false
		}
		let folderPath = this.selectedVideo.folderPath;
		if(this.selectedVideo.folderPath.substring(this.selectedVideo.folderPath.length - 1) == "/" ) {
			folderPath = this.selectedVideo.folderPath.substring(0,this.selectedVideo.folderPath.length - 1);
		}
		let folderName = folderPath.substring(folderPath.lastIndexOf('/')+1);
		let folderId = this.selectedVideo.folderId;
		this.modelRefCloseEvent();
		this.sharedService.openTabRequestWithId(folderId, folderName, "Files", data, folderPath);
        //window.open('/home', '_blank').localStorage.setItem("foldertree",'{"state":{"core":{"selected":["'+this.selectedImage.folderId+'"]}}}');
	}

}


