import { Injectable } from '@angular/core';
import { NumberWidgetComponent } from './number-widget/number-widget.component'
import { ImageGridComponent } from './image-grid/image-grid.component';
import { ViewreportdataComponent}  from './viewreportdata/viewreportdata.component'
import { TagsSystemsHierarchyComponent } from './tags-systems-hierarchy/tags-systems-hierarchy.component'
import { ManageAdhocReportsComponent } from './manage-adhoc-reports/manage-adhoc-reports.component';




@Injectable({
	providedIn: 'root'
})
export class ReportsService {

	viewWidgetId: string
	used: boolean


	private tagsWidgets = [
		{
			widgetId: 'tags_total_tag_reference',
			widgetName: 'Total Tags',
			widgetIcon: 'far fa-tags',
			component: 'number-widget',
			widgetSize: 'col-sm-3',
			isMinimized: false,
			canRefresh: false,
			hasConfig: false,
			hasHeader: false,
			bodyType: 'normal',
			used: false
		}
		,
		{
			widgetId: 'tags_total_tag_reference',
			widgetName: 'Used Tags',
			widgetIcon: 'far fa-tags',
			component: 'number-widget',
			widgetSize: 'col-sm-3',
			isMinimized: false,
			canRefresh: false,
			hasConfig: false,
			hasHeader: false,
			bodyType: 'normal',
			used: true
		}
		,
		{
			widgetId: 'tags_total_tag_reference_ref_view',
			widgetName: 'Used Tags - Reference View',
			widgetIcon: 'far fa-tags',
			component: 'number-widget',
			widgetSize: 'col-sm-4',
			isMinimized: false,
			canRefresh: false,
			hasConfig: false,
			hasHeader: false,
			bodyType: 'normal',
			used: true
		}
		
	]

	private statementsWidgets = [
		// {
		// 	widgetId: 'statements_word_count',
		// 	widgetName: 'Word Count',
		// 	widgetIcon: 'far fa-file-word',
		// 	component: 'number-widget',
		// 	widgetSize: 'col-sm-3',
		// 	isMinimized: false,
		// 	canRefresh: false,
		// 	hasConfig: false,
		// 	hasHeader: false,
		// 	bodyType: 'normal'
		// }
		// ,
		{
			widgetId: 'statements_total_reference',
			widgetName: 'Total Statements',
			component: 'number-widget',
			widgetIcon: 'far fa-edit',
			widgetSize: 'col-sm-3',
			isMinimized: false,
			canRefresh: false,
			hasConfig: false,
			hasHeader: false,
			bodyType: 'normal',
			used: false
		},
		{
			widgetId: 'statements_total_reference',
			widgetName: 'Used Statements',
			component: 'number-widget',
			widgetIcon: 'far fa-edit',
			widgetSize: 'col-sm-3',
			isMinimized: false,
			canRefresh: false,
			hasConfig: false,
			hasHeader: false,
			bodyType: 'normal',
			used: true
		}
	]

	private documentWidgets = [
		
		{
			widgetId: 'document_total_reference',
			widgetName: 'Total Documents',
			widgetIcon: 'far fa-copy',
			component: 'number-widget',
			widgetSize: 'col-sm-3',
			isMinimized: false,
			canRefresh: false,
			hasConfig: false,
			hasHeader: false,
			bodyType: 'normal',
			used: false
		}
		,
		{
			widgetId: 'document_total_reference',
			widgetName: 'Used Documents',
			widgetIcon: 'far fa-copy',
			component: 'number-widget',
			widgetSize: 'col-sm-3',
			isMinimized: false,
			canRefresh: false,
			hasConfig: false,
			hasHeader: false,
			bodyType: 'normal',
			used: true
		}
		
	]

	private componentWidgets = [
		{
			widgetId: 'component_total_reference',
			widgetName: 'Total Components',
			widgetIcon: 'far fa-chart-network',
			component: 'number-widget',
			widgetSize: 'col-sm-3',
			isMinimized: false,
			canRefresh: false,
			hasConfig: false,
			hasHeader: false,
			bodyType: 'normal',
			used: false
		}
		,
		{
			widgetId: 'component_total_reference',
			widgetName: 'Used Components',
			widgetIcon: 'far fa-chart-network',
			component: 'number-widget',
			widgetSize: 'col-sm-3',
			isMinimized: false,
			canRefresh: false,
			hasConfig: false,
			hasHeader: false,
			bodyType: 'normal',
			used: true
		}
	]

	private systemWidgets = [
		{
			widgetId: 'system_total_reference',
			widgetName: 'Total Systems',
			widgetIcon: 'far fa-atom',
			component: 'number-widget',
			widgetSize: 'col-sm-3',
			isMinimized: false,
			canRefresh: false,
			hasConfig: false,
			hasHeader: false,
			bodyType: 'normal',
			used: false
		}
		,
		{
			widgetId: 'system_total_reference',
			widgetName: 'Used Systems',
			widgetIcon: 'far fa-atom',
			component: 'number-widget',
			widgetSize: 'col-sm-3',
			isMinimized: false,
			canRefresh: false,
			hasConfig: false,
			hasHeader: false,
			bodyType: 'normal',
			used: true
		}
		,
		{
			widgetId: 'system_total_reference_sys_view',
			widgetName: 'Used Systems - System View',
			widgetIcon: 'far fa-atom',
			component: 'number-widget',
			widgetSize: 'col-sm-4',
			isMinimized: false,
			canRefresh: false,
			hasConfig: false,
			hasHeader: false,
			bodyType: 'normal',
			used: true
		}
	]

	private facilityWidgets = [
		{
			widgetId: 'facility_total_reference',
			widgetName: 'Total Facilities',
			widgetIcon: 'fal fa-industry-alt',
			component: 'number-widget',
			widgetSize: 'col-sm-3',
			isMinimized: false,
			canRefresh: false,
			hasConfig: false,
			hasHeader: false,
			bodyType: 'normal',
			used: false
		}
		,
		{
			widgetId: 'facility_total_reference',
			widgetName: 'Used Facilities',
			widgetIcon: 'fal fa-industry-alt',
			component: 'number-widget',
			widgetSize: 'col-sm-3',
			isMinimized: false,
			canRefresh: false,
			hasConfig: false,
			hasHeader: false,
			bodyType: 'normal',
			used: true
		}
	]

	private procedureWidgets = [
		{
			widgetId: 'procedure_total_reference',
			widgetName: 'Total Procedures',
			widgetIcon: 'fal fa-layer-group',
			component: 'number-widget',
			widgetSize: 'col-sm-3',
			isMinimized: false,
			canRefresh: false,
			hasConfig: false,
			hasHeader: false,
			bodyType: 'normal',
			used: false
		},
		{
			widgetId: 'procedure_Graphic_reference',
			widgetName: 'Procedure Graphic Reference',
			widgetIcon: 'fal fa-file-image',
			component: 'number-widget',
			widgetSize: 'col-sm-3',
			isMinimized: false,
			canRefresh: false,
			hasConfig: false,
			hasHeader: false,
			bodyType: 'normal',
			used: false
		}
		
	]

	private topicWidgets = [
		{
			widgetId: 'topic_total_topics',
			widgetName: 'Total Topics',
			widgetIcon: 'far fa-heading',
			component: 'number-widget',
			widgetSize: 'col-sm-3',
			isMinimized: false,
			canRefresh: false,
			hasConfig: false,
			hasHeader: false,
			bodyType: 'normal',
			used: false
		}
		,
		{
			widgetId: 'topic_total_revisions',
			widgetName: 'Topic Revisions',
			widgetIcon: 'fab fa-rev',
			component: 'number-widget',
			widgetSize: 'col-sm-3',
			isMinimized: false,
			canRefresh: false,
			hasConfig: false,
			hasHeader: false,
			bodyType: 'normal',
			used: false
		}
		,
		{
			widgetId: 'tags_total_authors',
			widgetName: 'Total Authors',
			widgetIcon: 'far fa-user-edit',
			component: 'number-widget',
			widgetSize: 'col-sm-3',
			isMinimized: false,
			canRefresh: false,
			hasConfig: false,
			hasHeader: false,
			bodyType: 'normal',
			used: false
		}

	]

	private documentTypeWidgets = [
		
		{
			widgetId: 'document_total_types',
			widgetName: 'Total Document Types',
			widgetIcon: 'far fa-file-code',
			component: 'number-widget',
			widgetSize: 'col-sm-3',
			isMinimized: false,
			canRefresh: false,
			hasConfig: false,
			hasHeader: false,
			bodyType: 'normal',
			used: false
		}
		,
		{
			widgetId: 'document_total_types',
			widgetName: 'Used Document Types',
			widgetIcon: 'far fa-file-code',
			component: 'number-widget',
			widgetSize: 'col-sm-3',
			isMinimized: false,
			canRefresh: false,
			hasConfig: false,
			hasHeader: false,
			bodyType: 'normal',
			used: true
		}
		
	]

	private courseWidgets = [
		
		{
			widgetId: 'total_course',
			widgetName: 'Total Courses',
			widgetIcon: 'fas fa-graduation-cap',
			component: 'number-widget',
			widgetSize: 'col-sm-3',
			isMinimized: false,
			canRefresh: false,
			hasConfig: false,
			hasHeader: false,
			bodyType: 'normal',
			used: false
		}
		,
		{
			widgetId: 'topic_count',
			widgetName: 'Topic Menu Count',
			widgetIcon: 'fas fa-tasks',
			component: 'number-widget',
			widgetSize: 'col-sm-3',
			isMinimized: false,
			canRefresh: false,
			hasConfig: false,
			hasHeader: false,
			bodyType: 'normal',
			used: false
		}
		,
		{
			widgetId: 'topic_content_count',
			widgetName: 'Bullet Count',
			widgetIcon: 'fas fa-file-alt',
			component: 'number-widget',
			widgetSize: 'col-sm-3',
			isMinimized: false,
			canRefresh: false,
			hasConfig: false,
			hasHeader: false,
			bodyType: 'normal',
			used: false
		}
		,
		{
			widgetId: 'learning_check_count',
			widgetName: 'Learning Check Count',
			widgetIcon: 'fas fa-user-graduate',
			component: 'number-widget',
			widgetSize: 'col-sm-3',
			isMinimized: false,
			canRefresh: false,
			hasConfig: false,
			hasHeader: false,
			bodyType: 'normal',
			used: false
		}
		,
		{
			widgetId: 'game_count',
			widgetName: 'Game Count',
			widgetIcon: 'fas fa-puzzle-piece',
			component: 'number-widget',
			widgetSize: 'col-sm-3',
			isMinimized: false,
			canRefresh: false,
			hasConfig: false,
			hasHeader: false,
			bodyType: 'normal',
			used: false
		}
	
		
	]

	private threedModelWidgets = [
		
		{
			widgetId: 'total_3d_model',
			widgetName: 'Total 3D Models',
			widgetIcon: 'fas fa-cubes',
			component: 'number-widget',
			widgetSize: 'col-sm-3',
			isMinimized: false,
			canRefresh: false,
			hasConfig: false,
			hasHeader: false,
			bodyType: 'normal',
			used: false
		}
		
		
	]

	private videoWidgets = [
		
		{
			widgetId: 'total_videos',
			widgetName: 'Total Videos',
			widgetIcon: 'fas fa-tv',
			component: 'number-widget',
			widgetSize: 'col-sm-3',
			isMinimized: false,
			canRefresh: false,
			hasConfig: false,
			hasHeader: false,
			bodyType: 'normal',
			used: false
		}
		
		
	]

	constructor() { }

	public loadWidgetListByType(type: string) {
		switch (type) {
			case 'tag':
				return this.tagsWidgets
			case 'statement':
				return this.statementsWidgets
			case 'document':
				return this.documentWidgets
			case 'system':
				return this.systemWidgets
			case 'component':
				return this.componentWidgets
			case 'facility':
				return this.facilityWidgets
			case 'procedure':
				return this.procedureWidgets
			case 'topic':
				return this.topicWidgets
			case 'documentType':
				return this.documentTypeWidgets
			case 'course':
				return this.courseWidgets
			case '3DModel':
				return this.threedModelWidgets
			case 'video':
				return this.videoWidgets
			default:
				return this.tagsWidgets
		}
	}

	public loadWidgetByComponent(componentName: string): any {
		switch (componentName) {
			case 'number-widget':
				return NumberWidgetComponent
			default:
				break;
		}
	}

	public loadComponentByName(compName: string) {
		switch (compName) {
            case 'image':
				return ImageGridComponent
			case 'viewtable':
				return ViewreportdataComponent
			case 'comosExport':
				return TagsSystemsHierarchyComponent
			case 'manageAdhocReports':
				return ManageAdhocReportsComponent
            default:
                break;
        }
	}

}
