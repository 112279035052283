import { Directive, OnInit, Input, ElementRef, Renderer2 } from '@angular/core';

@Directive({
	selector: '[symbiosisFocus]'
})
export class ManualFocusDirective implements OnInit {

	@Input('symbiosisFocus') isFocused: boolean;

	constructor(private hostElement: ElementRef, private renderer: Renderer2) { }

	ngOnInit() {
		if (this.isFocused) {
			this.renderer.selectRootElement(this.hostElement.nativeElement).focus();
		}
	}

}
