import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'test',
  template: `
    <h3>
      This is a sample component to demonstrate views without user login.
    </h3>
  `,
  styleUrls: ['./test.component.css']
})
export class TestComponent implements OnInit {

  constructor() {}

  ngOnInit() {}

}
