<div>
	<div class="py-2 px-0 mt-2">
		<ul class="col-sm-12 px-0 py-2 list-group mt-2">
			<li *ngIf="enableLoadDocumentTypes" class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
				[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'documenttype', 'custom-list-item-dark': selectedMenuItem != 'documenttype'}"
				(click)="sideItemClick('documenttype',true)">
				<h6 class="flaot-left ml-2 mb-0 sidebar-list-text">
					<i class="far fa-paste mx-2" aria-hidden="true"></i>
					{{ 'ADMIN_SETTINGS.AS_SIDEBAR_DOCUMENT_TYPES' | translate }}
				</h6>
			</li>
			<ul class="col-sm-12 px-0 py-0 list-group rounded-0">
				<li *ngIf="enableLoadDocumentTypeAttribute" class="list-group-item border-0 rounded-0 px-0 py-2 list-item-bg list-cursor"
					(click)="sideItemClick('manageattributes',true)"
					[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'manageattributes', 'custom-list-item-dark': selectedMenuItem != 'manageattributes'}">
					<h6 class="flaot-left mb-0 ml-4">
						<i class="far fa-file-edit mx-2" aria-hidden="true"></i>
						{{ 'ADMIN_SETTINGS.AS_SIDEBAR_MANAGE_ATTRIBUTES' | translate }}
					</h6>
				</li>
			</ul>
			<li *ngIf="enableLoadUsers" class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
				[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'users', 'custom-list-item-dark': selectedMenuItem != 'users'}"
				(click)="sideItemClick('users',true)">
				<h6 class="flaot-left ml-2 mb-0 sidebar-list-text">
					<i class="far fa-users mx-2" aria-hidden="true"></i>
					{{ 'ADMIN_SETTINGS.AS_SIDEBAR_USERS' | translate }}
				</h6>
			</li>
			<!--
				<li *ngIf="!enableLoadUsers" class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor disabled"
				[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'users', 'custom-list-item-dark': selectedMenuItem != 'users'}"
				(click)="sideItemClick('users')">
				<h6 class="flaot-left ml-2 mb-0 sidebar-list-text">
					<i class="far fa-users mx-2" aria-hidden="true"></i>
					{{ 'ADMIN_SETTINGS.AS_SIDEBAR_USERS' | translate }}
				</h6>
			</li>
			-->	
			<ul class="col-sm-12 px-0 py-0 list-group rounded-0">
				<li *ngIf = "enableAddUser" class="list-group-item border-0 rounded-0 px-0 py-2 list-item-bg list-cursor"
					(click)="sideItemClick('adduser',true)"
					[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'adduser', 'custom-list-item-dark': selectedMenuItem != 'adduser'}">
					<h6 class="flaot-left mb-0 ml-4">
						<i class="far fa-user-edit mx-2" aria-hidden="true"></i>
						{{ 'ADMIN_SETTINGS.AS_SIDEBAR_ADD_EDIT_USER' | translate }}
					</h6>
				</li>
				<!--
					<li *ngIf = "!enableAddUser" class="disabled"
					(click)="sideItemClick('adduser')"
					[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'adduser', 'custom-list-item-dark': selectedMenuItem != 'adduser'}">
					<h6 class="flaot-left mb-0 ml-4">
						<i class="far fa-user-edit mx-2" aria-hidden="true"></i>
						{{ 'ADMIN_SETTINGS.AS_SIDEBAR_ADD_EDIT_USER' | translate }}
					</h6>
				</li>
				-->
			</ul>
			<li *ngIf="enableLoadClients" class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
				[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'clients', 'custom-list-item-dark': selectedMenuItem != 'clients'}"
				(click)="sideItemClick('clients',true)">
				<h6 class="flaot-left ml-2 mb-0 sidebar-list-text">
					<i class="far fa-user-friends mx-2" aria-hidden="true"></i>
					{{ 'ADMIN_SETTINGS.AS_SIDEBAR_CLIENTS' | translate }}
				</h6>
			</li>
			<!--
				<li *ngIf="!enableLoadClients" class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor disabled"
				[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'clients', 'custom-list-item-dark': selectedMenuItem != 'clients'}"
				(click)="sideItemClick('clients')">
				<h6 class="flaot-left ml-2 mb-0 sidebar-list-text">
					<i class="far fa-user-friends mx-2" aria-hidden="true"></i>
					{{ 'ADMIN_SETTINGS.AS_SIDEBAR_CLIENTS' | translate }}
				</h6>
			</li>
			-->
			<ul class="col-sm-12 px-0 py-0 list-group rounded-0">	
				<li *ngIf = "enableAddClient" class="list-group-item border-0 rounded-0 px-0 py-2 list-item-bg list-cursor"
					(click)="sideItemClick('aeclient',true)"
					[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'aeclient', 'custom-list-item-dark': selectedMenuItem != 'aeclient'}">
					<h6 class="flaot-left mb-0 ml-4">
						<i class="far fa-plus-square mx-2" aria-hidden="true"></i>
						{{ 'ADMIN_SETTINGS.AS_SIDEBAR_ADD_EDIT_CLIENTS' | translate }}
					</h6>
				</li>
				<!--
					<li *ngIf = "!enableAddClient" class="disabled"
					(click)="sideItemClick('aeclient')"
					[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'aeclient', 'custom-list-item-dark': selectedMenuItem != 'aeclient'}">
					<h6 class="flaot-left mb-0 ml-4">
						<i class="far fa-plus-square mx-2" aria-hidden="true"></i>
						{{ 'ADMIN_SETTINGS.AS_SIDEBAR_ADD_EDIT_CLIENTS' | translate }}
					</h6>
				</li>
				-->
					
			</ul>
			<li *ngIf="enableLoadProjects" class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
				[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'projects', 'custom-list-item-dark': selectedMenuItem != 'projects'}"
				(click)="sideItemClick('projects',true)">
				<h6 class="flaot-left ml-2 mb-0 sidebar-list-text">
					<i class="far fa-project-diagram mx-2" aria-hidden="true"></i>
					{{ 'ADMIN_SETTINGS.AS_SIDEBAR_PROJECTS' | translate }}
				</h6>
			</li>
			<!--
				<li *ngIf="!enableLoadProjects" class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor disabled" 
				[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'projects', 'custom-list-item-dark': selectedMenuItem != 'projects'}"
				(click)="sideItemClick('projects')">
				<h6 class="flaot-left ml-2 mb-0 sidebar-list-text">
					<i class="far fa-project-diagram mx-2" aria-hidden="true"></i>
					{{ 'ADMIN_SETTINGS.AS_SIDEBAR_PROJECTS' | translate }}
				</h6>
			</li>
			-->
			
			<ul class="col-sm-12 px-0 py-0 list-group rounded-0">
				<li *ngIf = "enableAddProject" class="list-group-item border-0 rounded-0 px-0 py-2 list-item-bg list-cursor"
					(click)="sideItemClick('addprojects',true)"
					[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'addprojects', 'custom-list-item-dark': selectedMenuItem != 'addprojects'}">
					<h6 class="flaot-left mb-0 ml-4">
						<i class="far fa-tools mx-2" aria-hidden="true"></i>
						{{ 'ADMIN_SETTINGS.AS_SIDEBAR_ADD_EDIT_PROJECTS' | translate }}
					</h6>
				</li>
				<!--
					<li *ngIf = "!enableAddProject" class="disabled"
					(click)="sideItemClick('addprojects')"
					[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'addprojects', 'custom-list-item-dark': selectedMenuItem != 'addprojects'}">
					<h6 class="flaot-left mb-0 ml-4">
						<i class="far fa-tools mx-2" aria-hidden="true"></i>
						{{ 'ADMIN_SETTINGS.AS_SIDEBAR_ADD_EDIT_PROJECTS' | translate }}
					</h6>
				</li>
				-->
				
			</ul>
			<li *ngIf="enableLoadCategory" class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
				[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'categorysetting', 'custom-list-item-dark': selectedMenuItem != 'categorysetting'}"
				(click)="sideItemClick('categorysetting',true)">
				<h6 class="flaot-left ml-2 mb-0 sidebar-list-text">
					<i class="far fa-network-wired mx-2" aria-hidden="true"></i>
					{{ 'ADMIN_SETTINGS.AS_SIDEBAR_CATEGORY' | translate }}
				</h6>
			</li>
			<li *ngIf="enableLoadRoles" class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
				[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'userroles', 'custom-list-item-dark': selectedMenuItem != 'userroles'}"
				(click)="sideItemClick('userroles', true)">
				<h6 class="flaot-left ml-2 mb-0 sidebar-list-text">
					<i class="far fa-user-tag mx-2" aria-hidden="true"></i>
					Manage Roles
				</h6>
			</li>
			<li *ngIf="enableLoadChecksheetPunchListImages" class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
				[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'managePLImages', 'custom-list-item-dark': selectedMenuItem != 'managePLImages'}"
				(click)="sideItemClick('managePLImages', true)">
				<h6 class="flaot-left ml-2 mb-0 sidebar-list-text">
					<i class="far fa-images mx-2" aria-hidden="true"></i>
					{{ 'ADMIN_SETTINGS.AS_SIDEBAR_MANAGE_IMAGE' | translate }}
				</h6>
			</li>
			<li *ngIf="viewWorkflowTemplates" class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
				[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'manageWfTemplates', 'custom-list-item-dark': selectedMenuItem != 'manageWfTemplates'}"
				(click)="sideItemClick('manageWfTemplates', true)">
				<h6 class="flaot-left ml-2 mb-0 sidebar-list-text">
					<i class="far fa-paste mx-2" aria-hidden="true"></i>
					{{ 'ADMIN_SETTINGS.AS_SIDEBAR_MANAGE_WORKFLOW_TEMPLATES' | translate }}
				</h6>
			</li>
			<ul class="col-sm-12 px-0 py-0 list-group rounded-0">	
				<li *ngIf = "createWorkflowTemplates" class="list-group-item border-0 rounded-0 px-0 py-2 list-item-bg list-cursor"
					(click)="sideItemClick('addEditWfTemplate',true)"
					[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'addEditWfTemplate', 'custom-list-item-dark': selectedMenuItem != 'addEditWfTemplate'}">
					<h6 class="flaot-left mb-0 ml-4">
						<i class="far fa-plus-square mx-2" aria-hidden="true"></i>
						{{ 'ADMIN_SETTINGS.AS_SIDEBAR_ADD_WORKFLOW_TEMPLATE' | translate }}
					</h6>
				</li>
			</ul>
		</ul>
	</div>
</div>