import { Component, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { RolesService } from './roles.service';
import { WsCallback } from 'src/app/util/ws-callback.interface';
import { WsResponse } from 'src/app/util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';
import { LoadingService } from 'src/app/util/loading/loading.service';
import { CommonUtil } from 'src/app/util/common/common-util';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserVariable } from 'src/app/util/common/user-variable';
import { AlertService } from 'src/app/util/alert/alert.service';
import { GridOptions, ColDef, Module, AllModules } from "@ag-grid-enterprise/all-modules";
import { RoleCellRenderComponent } from './role-cell-render-component.component';
import { Subscription } from 'rxjs';
import { SharedService } from '../../../util/shared.service';
import { CapabilityService } from 'src/app/util/capability.service';

@Component({
  selector: 'app-user-roles',
  templateUrl: './user-roles.component.html',
  styleUrls: ['./user-roles.component.css']
})
export class UserRolesComponent implements OnInit, WsCallback {

  private bsModalRef: BsModalRef;
  private token: any;
  public newRoleName: string;
  public roleId: string;
  public roleList: Array<any> = [];
  public togglePanel: any = {};
	// public newClassificationNameData: string;
  public newRoleDescription: string;
  public newRolestatus: string;
  public roleName: string;
  public roleDescription: string;
  public applyAll: boolean;
  public applyClients: boolean;
  public rolestatus: string;
  public columnDefs: any;
  public gridOptions: GridOptions;
  private gridApi: any;
  private gridColumnApi: any;
  public getRowNodeId: any;
  public overlayLoadingTemplate: any;
  public overlayNoRowsTemplate: any;
  public rowData: any = [];
  public projectCapbilityList: any = [];
  public systemCapbilityList: any = [];
  public clientCapbilityList: any = [];

  private filteredData: Array<any> = [];

  //table row definition
  private statusTable: string;
  private edit: string;
  private delete: string;
  public enableChangeRoleStatus: boolean = false;
  public enableEditRole: boolean = false;
  public enableDeleteRole: boolean = false;
  public assignRoleCapabilities: boolean = false;
  public createRole: boolean = false;
  public assignUsersToRoles: boolean = false;
  private subsVar: Subscription
  public modules: Module[] = AllModules;


  constructor(private roleService: RolesService, private sharedService: SharedService, private translate: TranslateService, private alertService: AlertService,
		private modalService: BsModalService, private router: Router, private loadingService: LoadingService, private commonUtil: CommonUtil,
    private capabilityService: CapabilityService) {
    this.checkCapability()
		translate.addLangs(["es", "en"]);
		translate.setDefaultLang("en");
		let browserLang = translate.getBrowserLang();
		translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');

		//Initialization of variables
        this.token = UserVariable.getUserToken();
        if (!this.commonUtil.validateLogin(this.token)) {
            return;
        }

        this.columnDefs = [
          
          {
              headerName: 'Role Id', field: 'roleId', checkboxSelection: false, hide: true,
              suppressColumnsToolPanel: true
          },
          {
              headerName: this.translate.instant('ROLES.ROLE_NAME'), field: 'name', checkboxSelection: false, hide: false,
              suppressColumnsToolPanel: false, sort: 'asc'
          },
          {
              headerName: this.translate.instant('ROLES.ROLE_DESCRIPTION'), field: 'description', checkboxSelection: false, hide: false,
              suppressColumnsToolPanel: false
          },            
          {
              headerName: this.statusTable,
              field: "status",
              width: 85,
              cellStyle: { 'text-align': 'center' },
              // hide: !this.enableChangeRoleStatus,
              cellRendererFramework: RoleCellRenderComponent
          },
          {
              headerName: this.edit,
              field: "edit",
              width: 85,
              cellStyle: { 'text-align': 'center' },
              // hide: !this.enableEditRole,
              cellRendererFramework: RoleCellRenderComponent
          },
          {
              headerName: this.delete,
              field: "delete",
              width: 85,
              cellStyle: { 'text-align': 'center' },
              // hide: !this.enableDeleteRole,
              cellRendererFramework: RoleCellRenderComponent
          }
      ];

      this.getRowNodeId = function (data) {
        return data.id;
    };

    this.gridOptions = {
        rowData: this.rowData,
        columnDefs: this.columnDefs,
        defaultColDef: {
          resizable: true,
          sortable: true
        },
        rowSelection: "single",
        rowMultiSelectWithClick: false,
        // isExternalFilterPresent: this.externalFilterPresent,
        // doesExternalFilterPass: this.externalFilterPass,
        // onCellClicked: (params) => {
        //     this.clickedTimes++;
        //     this.clickDebouncer(params);
        // },
    };

     
    //custom layout templates for loading and empty data sets
    this.overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Loading data...</span>';
    this.overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">No rows to show</span>';
    this.applyAll =false;
    this.applyClients =false;

	}

  onGridReady(params) {
    const allColumnIds = [];
    this.columnDefs.forEach((columnDef) => {
        allColumnIds.push(<ColDef>columnDef);
    });
    this.gridColumnApi = params.columnApi;
    this.gridApi = params.api;
    this.roleService.setApiGridData(this.gridApi);
    params.api.sizeColumnsToFit();
    // window.addEventListener("resize", function () {
    //     setTimeout(function () {
    //         params.api.sizeColumnsToFit();
    //     });
    // });
    window.addEventListener("resize", this.onWindowResize);
}
private onWindowResize = () => {
   
  setTimeout(() => {
    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
    }
  }); 
};


ngOnDestroy(){

  window.removeEventListener("resize", this.onWindowResize);
this.gridApi.destroy();
this.gridApi = null;
}

ngAfterViewInit(): void {
  //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
  //Add 'implements AfterViewInit' to the class.
  this.sharedService.setRoleTableEditRespond().subscribe(
    (option: { data: any; }) => this.setRoleTableData(option.data)
  )}

  ngOnInit() {
    // this.roleService.getRolesList(this);
    this.roleService.getRolesList().subscribe(data => {
      if (data.payload != null) {
        this.setRoleTableData(data.payload);
      }
			error => {
        this.loadingService.hideLoading();
				this.alertService.clear()
        this.alertService.error(error.statusDescription)
      }
      });
  }
  status: boolean = false;
  pageToggle(){
    if (!$('.page-container').hasClass("toggled") ) {
      //this.status = false;
      $(".page-container").addClass("toggled");
      $('li.role').removeClass('active-role'); 
    }else{
      $('li.role').removeClass('active-role'); 
    }
           
  }
  activestatus: boolean = false;
  activeRoleset(event){
    let target = event.target || event.srcElement || event.currentTarget;
    console.log(target);
    //this.status = !this.status;
    if (!$(".page-container").hasClass("toggled")) {
      $(".page-container").addClass("toggled");
    }else{
      
    }
      
      this.activestatus = !this.activestatus; 
      $('li.role').removeClass('active-role'); 
      $(target).parents('li.role').toggleClass('active-role');      
  }

  addRoleButtonClick(template: TemplateRef<any>) {
		console.log("Add Tag Classification button clicked...");
		this.newRoleDescription = "";
		this.newRoleName = "";
		this.bsModalRef = this.modalService.show(template, { class: 'modal-lg', backdrop: 'static', keyboard : false });
  }
  onSuccess(data: WsResponse, serviceType: WsType) {		
    if(serviceType == WsType.ADD_NEW_ROLE){
      this.loadingService.hideLoading();
      this.alertService.success("Successfully added the role");
      // this.roleService.getRolesList(this);
      this.roleService.getRolesList().subscribe(data => {
        if (data.payload != null) {
          this.setRoleTableData(data.payload);
        }
        error => {          
          this.loadingService.hideLoading();
          this.alertService.clear()
          this.alertService.error(error.statusDescription)
        }
        });
      setTimeout(() => {
				this.bsModalRef.hide();
			  }, 200);	
    // } else if(serviceType == WsType.GET_ROLES){
    //     this.setRoleTableData(data.payload)
    } else if(serviceType == WsType.GET_ROLE_CAPABILITY){
      this.setRoleCapbility(data.payload)
  }

  }
  onFail(data: WsResponse, serviceType: WsType) {
    this.loadingService.hideLoading();
    if(serviceType == WsType.ADD_NEW_ROLE){
      this.alertService.error(data.statusDescription);
    }
  }
  saveButtonClick(): void {
		this.newRoleDescription = this.newRoleDescription.trim()
		this.newRoleName = this.newRoleName.trim()
		if (this.newRoleDescription.includes("\\")) {
			this.newRoleDescription  = this.newRoleDescription.replace(/\\/g, "\\\\");
		}
		if (this.newRoleName.includes("\\")) {
			this.newRoleName  = this.newRoleName.replace(/\\/g, "\\\\");
    }
    let radioValue = $("input[name='addrb']:checked").val()
    if (radioValue){
       this.newRolestatus = radioValue.toString();;
    } else {
      this.alertService.info("Please select the status");
      return;
    }
		if (this.newRoleName.length === 0 ) {
			return;
    }	
    let data = {
      roleID: null,
      roleName: this.newRoleName,
      description: this.newRoleDescription,
      status: this.newRolestatus
      
    };   
    var json = JSON.stringify(data);
    this.roleService.addNewRole(this.token, json, this);
    this.loadingService.showLoading(true, false, this.translate.instant('SAVING'), 0);
  }

  onRowClick(event) {
    if(this.assignRoleCapabilities == true){
      this.roleId = event.data.id;
      this.sharedService.setRoleId(this.roleId);
      this.applyAll = false;
      this.applyClients = false;
      var data: any = {
        "scope": "PROJECT",
        "roleID": event.data.id
      };
      var json = JSON.stringify(data);
      this.roleService.getCapabilitiesList(this.token, json, this);
      
      var data: any = {
        "scope": "SYSTEM",
        "roleID": event.data.id
      };
      var json = JSON.stringify(data);
      this.roleService.getCapabilitiesList(this.token, json, this);
      
      var data: any = {
        "scope": "CLIENT",
        "roleID": event.data.id
      };
      var json = JSON.stringify(data);
      this.roleService.getCapabilitiesList(this.token, json, this);
      
      let target = event.target || event.srcElement || event.currentTarget;
      console.log(target);
      //this.status = !this.status;
      if (!$(".page-container").hasClass("toggled")) {
        $(".page-container").addClass("toggled");
      } else{
        
      }
        
        this.activestatus = !this.activestatus; 
        $('li.role').removeClass('active-role'); 
        $(target).parents('li.role').toggleClass('active-role');    
    }
}

setRoleTableData(payload: any) {
  this.loadingService.hideLoading();
  //console.log(payload[0]["category"]);
  this.roleList = [];
  this.rowData = [];
  if (payload != null) {
      
          if (payload != null) {
          payload.forEach(element => {
            this.roleList.push({ 'id': element.roleID, 
            'name': element.roleName,
            'addedBy': element.addedBy,
            'addedDate': element.addedDate,
            'description': element.description,
            // 'status': element.status,
            status: "status:" + element.roleID + ":" + element.status,
            edit: "edit:" +  element.roleID + ":" + element.status + ":" + element.roleName + ":" + element.description,
            delete: "delete:" +  element.roleID + ":" + element.status,
            });
          });
      }          
      this.rowData = this.roleList;
      console.log(this.rowData);
      this.gridOptions.api.sizeColumnsToFit();
  } else {
      // no row data to show
      this.rowData = [];
  }
  this.roleService.setRowData(this.rowData);
}

setRoleCapbility(payload: any) {
  this.loadingService.hideLoading();
  //console.log(payload[0]["category"]);
  if (payload[0].scope == "PROJECT") {
    this.projectCapbilityList = [];
  } else if (payload[0].scope == "SYSTEM") {
    this.systemCapbilityList = [];
  } else if (payload[0].scope == "CLIENT") {
    this.clientCapbilityList = [];
  } 
  // this.rowData = [];
  if (payload != null) {
      
          if (payload != null) {
          payload.forEach(element => {
            if(element.scope == "PROJECT") {
              this.projectCapbilityList.push({ 'id': element.id, 
              'name': element.name,
              'title': element.title,
              'added': element.added,
              'category': element.category,
              'scope': element.scope,
              'capabilityOrder': element.capabilityOrder,
              });
            } else if(element.scope == "SYSTEM") {
              this.systemCapbilityList.push({ 'id': element.id, 
              'name': element.name,
              'title': element.title,
              'added': element.added,
              'category': element.category,
              'scope': element.scope,
              'capabilityOrder': element.capabilityOrder,
              });
            } else if(element.scope == "CLIENT") {
              this.clientCapbilityList.push({ 'id': element.id, 
              'name': element.name,
              'title': element.title,
              'added': element.added,
              'category': element.category,
              'scope': element.scope,
              'capabilityOrder': element.capabilityOrder,
              });
            }
          });
      }          
      // this.rowData = this.roleList;
      // console.log(this.rowData);
      // this.gridOptions.api.sizeColumnsToFit();
  } else {
      // no row data to show
      // this.rowData = [];
  }
  // this.roleService.setRowData(this.rowData);
}

	
	 /**
     * check the capabilities for given type
     */
	public checkCapability() {

    let assignCapabilitiesCapability: string = "assignRoleCapabilities"
    let assignRolesCapability: string = "assignUserRoles"
    let createRolesCapability: string = "createUserRoles"
   
    this.assignRoleCapabilities = this.capabilityService.isCapabilityAssigned(assignCapabilitiesCapability)
    this.assignUsersToRoles = this.capabilityService.isCapabilityAssigned(assignRolesCapability)   
    this.createRole = this.capabilityService.isCapabilityAssigned(createRolesCapability)

	}

}
