import { Component } from "@angular/core";
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { CapabilityService } from '../../../../util/capability.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '../../../../util/alert/alert.service';
import { LoadingService } from '../../../../util/loading/loading.service';
import { DocumentStoreService } from "../../documentstore.service";
import { AddFacilitiesModalComponent } from "../../addfacilitiesmodal";

@Component({
   selector: 'child-cell',
   template: `
         <div>
            <div *ngIf="selectedFacilityList.length != undefined">
               <div *ngFor="let facility of selectedFacilityList" style="float:left">
                     <a>{{facility.facilityName}}</a>
               </div>
               <button [disabled]="addFacilitiesDisabled" (click)="addFacilities()" id="DS_addFacilities" class="btn btn-sm btn-outline-dark py-0 my-1 px-0 border-0" style="float:left">
                  <i class="far fa-plus-circle mx-2" aria-hidden="true"></i>
               </button>
            </div>
         </div>`,
   styles: [`
          a {
         display: inline-block;
         padding: .4em .5em;
         margin: 2px 2px 2px 0;
         line-height: 1;
         white-space: nowrap;
         text-decoration: none;
         text-align: center;
         border-width: 1px;
         border-style: solid;
         border-radius: 10px;
         border-color: #efefef;
         background-color: #efefef
      }`]

})

export class FacilitiesCellRender implements ICellRendererAngularComp {
   public params: any;
   bsModalRef: BsModalRef;
   public isAvailable: boolean; 
   public loadingMessage: string;
   public inReview: boolean;
   public addFacilitiesDisabled : boolean = false;
   public selectedFacilityList = [];
   
   constructor(private modalService: BsModalService, private translate: TranslateService, 
      private documentStoreService: DocumentStoreService, private alertService: AlertService, private loadingService: LoadingService) {
     
      translate.addLangs(["es", "en"]);
		translate.setDefaultLang("en");
		let browserLang = translate.getBrowserLang();
      translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
      
      
    this.translate.get('DOCUMENT_STORE.LOADING_MESSAGE').subscribe((res: string) => {
			this.loadingMessage = res;
		});
   }
   agInit(params: any): void {
      this.params = params;
      this.processSelectedFacilityList()
      if (params.data.availability == "1") {
         this.addFacilitiesDisabled = true;
      }
      else {
         this.addFacilitiesDisabled = false;
      }
   }   

   public addFacilities() {
      console.log(this.params.data.id);
      this.loadingService.showLoading(true, null, this.loadingMessage, null)
      this.documentStoreService.getAllFacility()
         .subscribe(data => {
            this.loadingService.hideLoading();
            console.log(data.payload);
            this.bsModalRef = this.modalService.show(AddFacilitiesModalComponent, { class: 'modal-lg' });
            this.bsModalRef.content.facilityList = data.payload;
            this.bsModalRef.content.selectedFacilityList = this.selectedFacilityList;
            this.bsModalRef.content.params = this.params;

         },
         error => {
            this.alertService.clear();
            this.alertService.error(error.statusDescription);
            this.loadingService.hideLoading();
         });
   }
   
   //convert id:facilityName pair to facility object
   processSelectedFacilityList(){
      var facilities = this.params.data.facilityList;
      for (var key in facilities) {
         var facility = {
            'facilityId': key,
            'facilityName': facilities[key]
         }
         this.selectedFacilityList.push(facility);
      }
   }

   refresh(): boolean {
      return false;
   }

}