<div class="row my-2 mx-1 border-bottom" #ftHeader>
    <div class="col-sm-6 pl-2">
        <p class="h6">{{ 'FOLDER_TREE.FOLDER_EXFLORER' | translate }}</p>
    </div>
    <div class="col-sm-6 pr-2">
        <div class="float-right" *ngIf="selectedFolderId != null">
            <button type="button" class="btn btn-outline-dark btn-sm ml-1 mr-1 border-0" (click)="createElement()">
                <i class="fas fa-plus fa-sm" aria-hidden="true"></i>
            </button>
            <button type="button" class="btn btn-outline-dark btn-sm ml-1 mr-1 border-0" (click)="renameElement()" [disabled]='renameDisable'>
                <i class="fas fa-edit fa-sm" aria-hidden="true"></i>
            </button>
            <button type="button" class="btn btn-outline-dark btn-sm ml-1 mr-1 border-0" (click)="deleteElement()" [disabled]='deleteDisable'>
                <i class="fas fa-trash-alt fa-sm" aria-hidden="true"></i>
            </button>
            <button type="button" class="btn btn-outline-dark btn-sm ml-1 mr-1 border-0" (click)="refreshFolderTree()" >
                <i class="fas fa-sync-alt fa-sm" aria-hidden="true"></i>
            </button>
        </div>
    </div>
    <div class="col-sm-12 px-2 py-1">
        <form>
            <div class="form-group mb-1">
                <input class="form-control form-control-sm" placeholder="{{ 'FOLDER_TREE.SEARCH_FOLDER' | translate }}" (keyup)="searchElement($event)">
            </div>
        </form>
    </div>
</div>
<div class="row my-2 mx-1">
    <div id="foldertree" class="my-2 mx-1 ftdiv-overflow" style="width: 100%;" [style.height.px]="treeheight">

    </div>
</div>

<!--move folder items modal-->
<ng-template #moveFolderItemsTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{'FOLDER_TREE.MOVE_FOLDER_ITEMS' | translate }}</h4>
    </div>
    <div class="modal-body py-1">
        <h5 class="text-center">
            <label> {{ 'FILES_DATA.WARNING_MESSAGE_MOVE_FILES' | translate}}</label>
        </h5>
    </div>
    <div class="modal-footer">
        <button type="button" id="moveFolderOK" class="btn btn-primary" (click)="moveFolderItems()">
            {{ 'FILES_DATA.OK' | translate}}</button>
        <button type="button" id="moveFolderCancel" class="btn btn-secondary" data-dismiss="modal"
            (click)="cancelMoveFolder()">
            {{ 'FILES_DATA.CANCEL' | translate}}</button>
    </div>
</ng-template>