import { Injectable } from '@angular/core';
import { CustomModalComponent } from "./custommodal.component";
import { AlertService } from '../alert/alert.service';
import * as $ from 'jquery';


@Injectable()
export class CustomModalService {
	private customModal: CustomModalComponent;
	public foundDependency: boolean = false;

	constructor(private alertService: AlertService) { }

	private setModalData(title: any, folderName: string) {
		this.customModal.modal.title = title;
		this.customModal.modal.folderName = folderName;
	}

	add(customModal: any) {
		this.customModal = customModal;
	}

	private open(modalId: string) {
		console.log("open");
		this.customModal.open(modalId);
	}

	public isFoundDependency() {
		return this.foundDependency;
	}

	public invokeCopy(title: any, folderName: string) {
		this.setModalData(title, folderName);
		this.open("topic");
	}

	public invoke(title: any, folderName: string) {
		this.setModalData(title, folderName);
				this.open("topic");
		// if (modalId == 'showHide' || type == 'Note') {
		// 	this.setModalData(null, type, id, component, status);
		// 	this.open(modalId);
		// }
		// else if (status == 1 || modalId == 'delete') {
		// 	// this.dependencyService.getDependency(id, type).subscribe(
		// 	// 	data => {
		// 	// 		this.setModalData(data.dependencyList, type, id, component, status);
		// 	// 		this.open(modalId);
		// 	// 		if (data.dependencyList.length > 0) {
		// 	// 			this.foundDependency = true;
		// 	// 		}
		// 	// 	},
		// 	// 	error => {
		// 	// 		this.alertService.clear();
		// 	// 		let err = error.json();
		// 	// 		this.alertService.handleError(err);
		// 	// 	}
		// 	// );
		// }
		// else if (modalId == 'edit') {
		// 	if (component.dependencyList.length > 0) {
		// 		;
		// 	}
		// 	else {
		// 		// this.dependencyService.getDependency(id, type).subscribe(
		// 		// 	data => {
		// 		// 		if (data.dependencyList.length > 0) {
		// 		// 			let jobLength = data.dependencyList.length;
		// 		// 			for (var i = 0; i < data.dependencyList.length; i++) {
		// 		// 				if (data.dependencyList[i].type === "Assessment") {
		// 		// 					jobLength = i;
		// 		// 					break;
		// 		// 				}
		// 		// 			}
		// 		// 			component.dependencyList = data.dependencyList.slice(0, jobLength)
		// 		// 			this.foundDependency = true;
		// 		// 		}
		// 		// 	},
		// 		// 	error => {
		// 		// 		this.alertService.clear();
		// 		// 		let err = error.json();
		// 		// 		this.alertService.handleError(err);
		// 		// 	}
		// 		// );
		// 	}

		// }
		// else {
		// 	this.setModalData(null, type, id, component, status);
		// 	this.open(modalId);
		// }
	}
}
