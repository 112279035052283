<div>
	<br>
	<div *ngIf="fileData!=null">
		<!-- <div *ngIf="fileData.type=='Topic' || fileData.type=='Procedure' ||  fileData.type=='TopicMap'  ||  fileData.type=='ContentItem' ||  fileData.type=='Image'"> -->
		<!--add data for each type -->
		<table border="0">
			<thead>
				<th></th>
				<th></th>
				<th></th>
			</thead>
			<tbody>
				<tr *ngIf="fileData.type!='ContentItem' && fileData.type!='Image' && fileData.type!='ThreeDModel' && fileData.type!='Video'">
					<td *ngIf="fileData.type!='ICECourse' ">
						<b>{{fileData.type}} Name:</b>
					</td>
					<td *ngIf="fileData.type=='ICECourse'">
						<b>Course File Name:</b>
					</td>
				
					<td *ngIf="fileData.editable=='Yes' ">
						<input #name type="text" style="width:500px;" value="{{fileData.name}}" (change)="updateName(name.value);name.blur()" />
					</td>
				
					<td *ngIf="fileData.editable!='Yes'">
						{{fileData.name}}
					</td>
					<!-- <td></td> -->
				</tr>
				<tr *ngIf="fileData.type=='Image'">
					<td>
						<b>Image Title:</b>
					</td>
					<td *ngIf="fileData.editable=='Yes'">
						<input #name type="text" style="width:500px;" value="{{fileData.title}}" (change)="updateName(name.value);name.blur()" />
					</td>
					<td *ngIf="fileData.editable!='Yes'">
						{{fileData.title}}
					</td>
					<!-- <td></td> -->
				</tr>
				<tr *ngIf="fileData.type=='ThreeDModel'">
					<td>
						<b>3D Model Title:</b>
					</td>
					<td *ngIf="fileData.editable=='Yes'">
						<input #title type="text" style="width:500px;" value="{{fileData.title}}" (change)="updateName(title.value);title.blur()" />
					</td>
					<td *ngIf="fileData.editable!='Yes'">
						{{fileData.title}}
					</td>
					<!-- <td></td> -->
				</tr>
				<tr *ngIf="fileData.type=='Video'">
					<td>
						<b>Video Title:</b>
					</td>
					<td *ngIf="fileData.editable=='Yes'">
						<input #title type="text" style="width:500px;" value="{{fileData.title}}" (change)="updateName(title.value);title.blur()" />
					</td>
					<td *ngIf="fileData.editable!='Yes'">
						{{fileData.title}}
					</td>
					<!-- <td></td> -->
				</tr>
				<tr *ngIf="fileData.type=='ContentItem'">
					<td>
						<b>Title:</b>
					</td>
					<td *ngIf="fileData.editable=='Yes'">
						<input #title type="text" style="width:500px;" value="{{fileData.name}}" (change)="updateName(title.value);title.blur()"
						/>
					</td>
					<td *ngIf="fileData.editable!='Yes'">
							{{fileData.name}}
						</td>
					<!-- <td></td> -->
				</tr>
				<tr *ngIf="fileData.type=='ContentItem' || fileData.type=='Image'">
					<td>
						<b>File Name:</b>
					</td>
					<td *ngIf="fileData.type!='Image'">
						{{fileData.fileName}}
					</td>
					<td *ngIf="fileData.type=='Image'">
						{{fileData.name}}
					</td>
					<!-- <td></td> -->
				</tr>
				<tr *ngIf="fileData.type=='TopicMap'">
					<td>
						<b>Date Added: </b>
					</td>
					<td>
						{{dateAdded}}
					</td>
				</tr>
				<tr>
					<tr *ngIf="fileData.type=='TopicMap'">
						<td>
							<b>Last Updated By: </b>
						</td>
						<td>
							{{fileData.lastUpdatedUser}}
						</td>
					</tr>
					<tr *ngIf="fileData.type !='TopicMap' && fileData.type !='ContentItem' && fileData.type!='Image' ">
						<td>
							<b>Last Modified:</b>
						</td>
						<td>{{lastUpdatedTimeStamp}}</td>
					</tr>
					<tr *ngIf="fileData.type!='ICECourse'">
						<td>
							<b>Document Type:</b>
						</td>
						<td *ngIf="fileData.editable=='Yes'">
							<input #documentType type="text" style="width:500px;" value="{{fileData.documentTypePath}}" (click)="loadDocumentTypes();onSaveButtonClick()"
							/>
						</td>
						<td *ngIf="fileData.editable!='Yes'">
								{{fileData.documentTypePath}}
							</td>
					</tr>
					<tr *ngIf="fileData.type=='Procedure'">
						<td>
							<b>System Name : </b>
						</td>
						<td *ngIf="fileData.editable=='Yes'" width="400">
							<select [(ngModel)]="fileData.systemId" (ngModelChange)="changeSystemId($event)" style="width:100%">
								<option *ngFor="let item of fileData.systems" [ngValue]="item.systemId">
									{{item.systemNo + ' ' + item.systemName}}
								</option>
							</select>
						</td>
						<td *ngIf="fileData.editable!='Yes'">
								{{systemName}}
							</td>
					</tr>
					<tr *ngIf="fileData.type=='Procedure' && fileData.editable=='Yes' || outputConfig!=''">
						<td>
							<b>Output Config: </b>
						</td>
						<td *ngIf="fileData.editable=='Yes'" width="400">
							<select [(ngModel)]="fileData.outputConfigId" (ngModelChange)="changeOutputConfigId($event)" style="width:100%">
								<option>--SELECT--</option>
								<option *ngFor="let item of fileData.outputList" [ngValue]="item.id">
									{{item.configName}}
								</option>
							</select>
						</td>
						<td *ngIf="fileData.editable!='Yes'">
								{{outputConfig}}
							</td>
					</tr>
					<tr *ngIf="fileData.type=='Image'">
						<td>
							<img class="card-img-top" src="data:image/jpeg;base64,{{fileData.imagedata}}">
						</td>
						<td>
							<!-- <img class="img-fluid img-thumbnail"> -->
						</td>
						<!-- <td></td> -->
					</tr>
			</tbody>

		</table>
		<!-- </div> -->
	</div>
</div>