<div class="modal-header py-2">
	<h5 class="modal-title">
		{{ 'STATEMENT_ADD_EDIT.STATEMENTS' | translate }}
	</h5>
	<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body">
	<div *ngIf="isEdit && statement!=null" class="card p-0 mx-auto col-sm-12">
		<div class="card-body py-2 mx-auto">
			<label class="mb-0">
				{{statement.referenceCount}} {{ 'STATEMENT_ADD_EDIT.PLACES' | translate }},
				{{ 'STATEMENT_ADD_EDIT.IN' | translate }} {{statement.dependencyCount}}
				{{ 'STATEMENT_ADD_EDIT.TOPICS' | translate }}, {{ 'STATEMENT_ADD_EDIT.IN' | translate }}
				{{statement.procDependencyCount}}
				{{ 'STATEMENT_ADD_EDIT.PROCEDURES' | translate }}, {{ 'STATEMENT_ADD_EDIT.IN' | translate }}
				{{statement.iceCourseDependencyCount}}
				{{ 'STATEMENT_ADD_EDIT.ICECOURSES' | translate }}
			</label>
			<label class="mb-0">
				<span class="badge border-badge-danger mx-2 py-2"
					tooltip="{{ 'STATEMENT_ADD_EDIT.CHECKED_OUT' | translate }}">
					<i class="fa fa-upload mx-2 fa-lg" aria-hidden="true"></i>
					<label class="mx-2 mb-0">{{statement.checkedOutTopicCount}}</label>
				</span>
				<span class="badge border-badge-success mx-2 py-2"
					tooltip="{{ 'STATEMENT_ADD_EDIT.CHECKED_IN' | translate }}">
					<i class="fa fa-download mx-2 fa-lg" aria-hidden="true"></i>
					<label class="mx-2 mb-0">{{statement.checkedInTopicCount}}</label>
				</span>
			</label>
		</div>
	</div>
	<!-- <div class="card-block card-body py-1"> -->
	<form class="mt-2">
		<div class="form-group mb-2">
			<label for="system_name" class="mb-0">{{ 'STATEMENT_ADD_EDIT.TITLE' | translate }}<div
					class="badge-required mx-2"></div></label>
			<input *ngIf="statement!=null" type="text" class="form-control form-control-sm" id="statement_tite"
				name="statement_tite" [(ngModel)]="statement.title">
			<input *ngIf="statement==null" type="text" class="form-control form-control-sm" id="statement_tite">
		</div>

		<div class="form-group mb-2">
			<label for="ckeditor_folder" class="mb-0">{{ 'STATEMENT_ADD_EDIT.CATEGORY' | translate }}<div
					class="badge-required mx-2"></div></label>
			<input *ngIf="statement!=null" readonly class="form-control form-control-sm" id="statement_categoryName"
				name="statement_categoryName"  [(ngModel)]="statement.categoryName" (click)="loadCategoryTree()">
			<input *ngIf="statement==null" readonly class="form-control form-control-sm" id="statement_categoryName"
				(click)="loadCategoryTree()">
		</div>

		<div *ngIf=" statement!=null && statement.reusableChangeInfo!=null">
			<div class="form-group mb-2">
				<label for="ckeditor_folder" class="col-sm-12 px-0 mb-0">
					{{ 'STATEMENT_ADD_EDIT.CONTENT' | translate }}
					<div class="badge-required mx-2"></div>
					<div class="float-right">
						<a *ngIf="statement.referenceCount==0"
							class="btn btn-sm btn-outline-dark py-0 my-1 px-0 border-0">
							<i class='fa fa-link fa-lg' style="color:rgb(110, 110, 108)" aria-hidden="true"></i>
						</a>
						<a *ngIf="statement.referenceCount!=0"
							class="btn btn-warning btn-sm float-right my-1 btn-sm-form-dependancy" type="button"
							(click)="viewDependencies()">
							<i class='fa fa-link fa-lg' aria-hidden="true"></i>
						</a>
					</div>
				</label>
				<div class="col-sm-12 px-0">
					<app-ckeditor *ngIf="dataLoad" [configData]="configData"></app-ckeditor>
				</div>
			</div>
			<div class="form-group mb-2">
				<div class="card bg-danger my-2">
					<div class="card-body py-1 px-2">
						<div class="my-1"><b>Old Value: </b></div>
						<div class="float-left" *ngIf="displayStm != null" [innerHtml]="displayHtml">
						</div>
						<div class="row col-sm-12"><b class="mr-2">Modified By:
							</b>{{statement.reusableChangeInfo.changeInitiatedUserName}}</div>
						<div class="pt-1"><b class="mr-2">Modified Time:
							</b>{{statement.reusableChangeInfo.changeInitiatedTimeStampFormatted}}</div>
					</div>
				</div>
			</div>
		</div>

		<div *ngIf="statement==null || statement.reusableChangeInfo==null">
			<div class="form-group mb-2">
				<label for="ckeditor_folder" class="col-sm-12 px-0 mb-0">
					{{ 'STATEMENT_ADD_EDIT.CONTENT' | translate }}
					<div class="badge-required mx-2"></div>
					<div *ngIf="statement!=null" class="float-right">
						<a *ngIf="statement.referenceCount==0"
							class="btn btn-sm btn-outline-dark py-0 my-1 px-0 border-0" type="button">
							<i class='fa fa-link fa-lg' style="color:rgb(110, 110, 108)" aria-hidden="true"></i>
						</a>
						<a *ngIf="statement.referenceCount!=0"
							class="btn btn-warning btn-sm float-right my-1 btn-sm-form-dependancy" type="button"
							(click)="viewDependencies()">
							<i class='fa fa-link fa-lg' aria-hidden="true"></i>
						</a>
					</div>
				</label>
				<div class="col-sm-12 px-0">
					<app-ckeditor *ngIf="dataLoad" [configData]="configData"></app-ckeditor>
				</div>
			</div>
		</div>
		<!--div class="form-group mb-2">
			<label for="system_name" class="mb-0">{{ 'STATEMENT_ADD_EDIT.FOREGROUND_COLOR' | translate }}</label>
			<input *ngIf="statement!=null" type="text" class="form-control form-control-sm" id="statement_fcolour"
				name="statement_fcolour" [(ngModel)]="statement.foregroundColor">
			<input *ngIf="statement==null" type="text" class="form-control form-control-sm" id="statement_fcolour">
		</div>
		<div class="form-group mb-2">
			<label for="ckeditor_folder" class="mb-0">{{ 'STATEMENT_ADD_EDIT.BACKGROUND_COLOR' | translate }}</label>
			<input *ngIf="statement!=null" type="text" class="form-control form-control-sm" id="statement_bcolour"
				name="statement_bcolour" [(ngModel)]="statement.backgroundColor">
			<input *ngIf="statement==null" type="text" class="form-control form-control-sm" id="statement_bcolour">
		</div-->
	</form>
	<!-- </div> -->
</div>
<div class="modal-footer">
	<button type="button" id="facility_cancel" class="btn btn-secondary btn-sm float-left"
		(click)="cancel()">{{ 'CANCEL' | translate}}</button>
	<button type="submit" id="facility_save" class="btn btn-primary btn-sm float-left"
		(click)="save()">{{ 'SAVE' | translate}}</button>

</div>

<!-- Multi level statement save-->
<ng-template #save_warn_for_statement>
    <div class="modal-header">
		<h5 class="modal-title">{{ 'STATEMENT_ADD_EDIT.SAVE_CONFIRM' | translate }}</h5>
        <button type="button" id="webeditor_topic_save" class="close pull-right" aria-label="Close"
            (click)="multiLevelWarnModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <label>{{ 'STATEMENT_ADD_EDIT.MULTI_LEVEL' | translate}}</label>
    </div>
    <div class="modal-footer">
        <button type="button" id="webeditor_modal_footer_topic_save" class="btn btn-primary"
            (click)="multiLevelWarnModalRef.hide(); saveMultiLevelStatement()">
            {{ 'WEB_EDITOR.OK' | translate}}</button>
        <button type="button" id="webeditor_modal_footer_modal" class="btn btn-secondary" data-dismiss="modal"
            (click)="multiLevelWarnModalRef.hide()">
            {{ 'WEB_EDITOR.CANCEL' | translate}}</button>
    </div>
</ng-template>