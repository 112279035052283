import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { UserVariable } from '../../util/common/user-variable';
import { ServiceUrls } from '../../util/service-urls';
import { WsResponse } from '../../util/ws-response.model';
import { WsCallback } from '../../util/ws-callback.interface';
import { WsType } from '../../util/ws-type';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { SharedService } from '../../util/shared.service';
import { CommonUtil } from 'src/app/util/common/common-util';

@Injectable()
export class ComponentsService {
	public token: string;
	private apiGridData: any = null;
	private rowData: any = null;
	private CategoryData: any = [];
	private subCategoryList: any = [];

	private callback: WsCallback;

	constructor(private http: HttpClient, private sharedService: SharedService, private commonUtil: CommonUtil) { }

	public setCallback(callBack: WsCallback) {
		this.callback = callBack;
	}

	//search components(post method to send data from the front-end)
	searchComponents(componentName: String, componentValue: String, categoryId: String, categoryName: String, addCatChild: String, hasText: String) {
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
		let url = this.getComponentSearchByNameURL(token);
		if (categoryId == null || categoryId == undefined || categoryId == '') {
			categoryId = '%';
		}
		return this.http.post(url, JSON.stringify(new Request(componentName, componentValue, categoryId, categoryName, addCatChild, hasText))).
			pipe(
			map(response => {
				console.log('response', response);
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.name,
					modified.status.code,
					modified.payload
				);

			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error));
				var res = new WsResponse(
					modified.error.status.description,
					modified.error.status.name,
					modified.error.status.code,
					modified.error.payload);
				return throwError(res)
			})
			);
	}

	public disableEnableFileTableData(fileId: any, title: String, status: String) {
		console.log("hhh" + status + title + fileId  + this.apiGridData)
		if (this.apiGridData != null && this.apiGridData != undefined && fileId != null) {
			console.log("apiGridData'")
			var rowNode = this.apiGridData.getRowNode(fileId);
			console.log("raw nod'" + rowNode)
			if (rowNode != null && rowNode != undefined) {
				console.log("kkkkk'")
				if (title == 'Do you want to disable?') {
					console.log("Disable Confirm'")
					this.sharedService.disableCellRendererRequest(true, fileId);
					rowNode.setDataValue("status", "status:" + fileId + ":Inactive");
					rowNode.setDataValue("delete", "delete:" + fileId + ":Inactive");
				} else if (title == 'Do you want to enable? ') {
					console.log("enable Confirm'")
					this.sharedService.disableCellRendererRequest(false, fileId);
					rowNode.setDataValue("status", "status:" + fileId + ":Active");
					rowNode.setDataValue("delete", "delete:" + fileId + ":Active");
				}
				else {
					console.log("Inactive'")
					if (status == "Inactive") {
						this.sharedService.disableCellRendererRequest(false, fileId);
						rowNode.setDataValue("status", "status:" + fileId + ":Active");
						rowNode.setDataValue("delete", "delete:" + fileId + ":Active");
					}
					else {
						this.sharedService.disableCellRendererRequest(true, fileId);
						rowNode.setDataValue("status", "status:" + fileId + ":Inactive");
						rowNode.setDataValue("delete", "delete:" + fileId + ":Inactive");
					}
				}
			}
		}
	}

	public deleteFileTableData(fileId: any) {
		if (this.apiGridData != null && this.apiGridData != undefined && fileId != null) {
			var selectedData = this.apiGridData.getSelectedRows();
			if (selectedData != null && selectedData != undefined) {
				this.apiGridData.applyTransaction({ remove: selectedData });
				let newRowData:any = [];
				this.apiGridData.forEachNode( function(rowNode, index) {
					newRowData.push(rowNode.data);
				});
				this.setRowData(newRowData);
			}
		}
	}

    /**
	 * Get component data. 
	 * @param fileId.
     * get gategories
	 */
	getComponentByCategory(id: any, callback: WsCallback) {
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
		const options = {
			params: new HttpParams().set("id", id)
		}
		let url = this.getComponentsByCategoryURL(token);
		this.http.get(url, options).
			subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.name,
					modified.status.code,
					modified.payload);
				callback.onSuccess(res, WsType.STATEMENT_SEARCH_VIEW);
			},
			error => {
				var modified = JSON.parse(JSON.stringify(error));
				var res = new WsResponse(
					modified.error.status.description,
					modified.error.status.name,
					modified.error.status.code,
					modified.error.payload);
				callback.onFail(res, WsType.STATEMENT_SEARCH_VIEW);
			});
	}

	private getComponentsByCategoryURL(token: String) {
		let url = ServiceUrls.COMPONENTS_SS_CATEGORY_VIEW + '?token=' + token;
		return url;
	}
	public setCategoryData(categoryData: any) {
		this.CategoryData = categoryData;
	}

	public getCategoryData() {
		return this.CategoryData;
	}
	public setSubCategoryList(subCategoryList: any) {
		this.subCategoryList = subCategoryList;
	}

	public getSubCategoryList() {
		return this.subCategoryList;
	}

	public getComponentSearchByNameURL(token: String) {
		let url = ServiceUrls.COMPONENTS_SEARCH_WITH_CATEGORY + '?token=' + token;

		return url;
	}

	public setApiGridData(apiGridData: any) {
		this.apiGridData = apiGridData;
	}

	public setRowData(rowData: any) {
		this.rowData = rowData;
	}

	public getRowData() {
		return this.rowData;
	}

	addComponent(componentName: String, componenentValue: String, categoryId: String) {
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
		let url = this.addComponentURL(token);
		// console.log("url...." + url);
		return this.http.post(url, JSON.stringify(new AddRequest(componentName, componenentValue, categoryId))).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.name,
					modified.status.code,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error));
				var res = new WsResponse(
					modified.error.status.description,
					modified.error.status.name,
					modified.error.status.code,
					modified.error.payload);
				return throwError(res)
			})
			);
	}

	public addComponentURL(token: String) {
		let url = ServiceUrls.ADD_COMPONENT + '?token=' + token;
		return url;
	}

	getComponentDataByComponentId(componentId: String) {
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
		let url = ServiceUrls.COMPONENT_EDIT + componentId  + '/getComponentByComponentId.json?token=' + token;
		return this.http.post(url, JSON.stringify(new EditRequest(componentId))).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.name,
					modified.status.code,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error));
				var res = new WsResponse(
					modified.error.status.description,
					modified.error.status.name,
					modified.error.status.code,
					modified.error.payload);
				return throwError(res)
			})
			);
	}

	public setItemData(fileId: any, data: any, componentValue: any, category: any) {
		if (this.apiGridData != null && this.apiGridData != undefined && fileId != null) {
			var rowNode = this.apiGridData.getRowNode(fileId);
			if (rowNode != null && rowNode != undefined) {
				rowNode.setDataValue("componentName", data);
				rowNode.setDataValue("componentValue", componentValue);
				rowNode.setDataValue("categoryName", category);
			}
		}
	}

	updateComponent(componentId: String, componentName: String, componenentValue: String, categoryId: String) {
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
		let url = ServiceUrls.UPDATE_COMPONENT + componentId + '/updateComponent.json?token=' + token;
		return this.http.put(url, JSON.stringify(new UpdateRequest(componentId, componentName, componenentValue, categoryId))).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.name,
					modified.status.code,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error));
				var res = new WsResponse(
					modified.error.status.description,
					modified.error.status.name,
					modified.error.status.code,
					modified.error.payload);
				return throwError(res)
			})
			);
	}

    /**
    * Define URL for delete component.
    */
	deleteComponent(id: any, callBack: WsCallback) {
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
		let url = ServiceUrls.COMPONENTS + id + '.json'
		const options = { params: new HttpParams().set("token", token) }
		this.http.delete(url, options).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				callBack.onSuccess(res, WsType.DELETE_ITEM);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					callBack.onFail(res, WsType.DELETE_ITEM);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					callBack.onFail(res, WsType.DELETE_ITEM);
				}
			}
		);
	}

}
class Request {
	constructor(private componentName: String, private componentValue: String, private categoryId: String, private categoryName: String, private addCatChild: String, private hasText: String) { }
}

class AddRequest {
	constructor(private componentName: String, private componentValue: String, private categoryId: String) { }
}

class EditRequest {
	constructor(private componentId: String) { }
}

class UpdateRequest {
	constructor(private componentId: String, private componentName: String, private componentValue: String, private categoryId: String) { }
}