import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
// import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserVariable } from '../util/common/user-variable';
import { ServiceUrls } from '../util/service-urls';
import { WsResponse } from '../util/ws-response.model';
import { WsCallback } from '../util/ws-callback.interface';
import { WsType } from '../util/ws-type';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonUtil } from '../util/common/common-util';


@Injectable()
export class FolderService {
	public token: string;
	private selectedFolderData: any;

    constructor(private http: HttpClient, private commonUtil: CommonUtil) {
	}

	/**
	 * Define URL for get all folders.
	 */
	getAllFolders(callBack: WsCallback) {
		console.log("FolderService.getAllFolders() == > ServiceURL= " + ServiceUrls.USER_FOLDER_VIEW)
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
		const options = { params: new HttpParams().set("token", token) }

		this.http.get(ServiceUrls.USER_FOLDER_VIEW, options).
			subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.code,
					modified.status.name,
					modified.status.description,
					modified.payload);
					callBack.onSuccess(res, WsType.FOLDER_VIEW);
			},
			error => {
				var val = (error as HttpErrorResponse).error;
				var modified = JSON.parse(JSON.stringify(val));
				var res = new WsResponse(
					modified.status.code,
					modified.status.name,
					modified.status.description,
					modified.payload);
					callBack.onFail(res, WsType.FOLDER_VIEW);
			});
	}

	/**
	 * Define URL for add folder.
	 *  @param folderModel.
	 */
	addFolder(folderModel: String, callBack: WsCallback) {
		console.log("FolderService.addFolder() == > ServiceURL= " + ServiceUrls.ADD_FOLDER)
		let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
		const options = { params: new HttpParams().set("token", token) }
		this.http.post(ServiceUrls.ADD_FOLDER, folderModel, options).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				callBack.onSuccess(res, WsType.FOLDER_ADD);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					callBack.onFail(res, WsType.FOLDER_ADD);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					callBack.onFail(res, WsType.FOLDER_ADD);
				}
			}
		);
	}

	/**
	 * Define URL for update folder.
	 *  @param folderModel.
	 */
	updateFolder(folderModel: String, action: String, callBack: WsCallback) {
		console.log("FolderService.updateFolder() == > ServiceURL= " + ServiceUrls.ADD_FOLDER)
		let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
		let url = this.getUpdateFolderURL();
		const options = { params: new HttpParams().set("token", token) }
		this.http.put(url, folderModel, options).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				if(action=="move"){
					callBack.onSuccess(res, WsType.FOLDER_MOVE);
				}
				else{
					callBack.onSuccess(res, WsType.FOLDER_UPDATE);
				}
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
						if(action=="move"){
							callBack.onFail(res, WsType.FOLDER_MOVE);
						}
						else{
							callBack.onFail(res, WsType.FOLDER_UPDATE);
						}
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					if(action=="move"){
						callBack.onFail(res, WsType.FOLDER_MOVE);
					}
					else{
						callBack.onFail(res, WsType.FOLDER_UPDATE);
					}
				}
			}
		);
	}

	/**
	 * Define URL for delete folder.
	 */
	deleteFolder(callBack: WsCallback) {
		console.log("FolderService.deleteFolder() == > ServiceURL= " + ServiceUrls.DELETE_FOLDER)
		let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
		let url = this.getDeleteFolderURL();
		const options = { params: new HttpParams().set("token", token) }
		this.http.delete(url, options).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				callBack.onSuccess(res, WsType.FOLDER_DELETE);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					callBack.onFail(res, WsType.FOLDER_DELETE);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					callBack.onFail(res, WsType.FOLDER_DELETE);
				}
			}
		);
	}

	
    // getWordList(): Observable<WsResponse> {
    //     let token = UserVariable.getUserToken();
    //     const options = { params: new HttpParams().set("token", token) }
    //     let url = this.getWordListByUserURL();
    //     return this.http.get(ServiceUrls.GET_WORDLIST, options).pipe(
    //         map(response => {
    //             var modified = JSON.parse(JSON.stringify(response));
    //             return new WsResponse(
    //                 modified.status.code,
    //                 modified.status.name,
    //                 modified.status.description,
	// 				modified.payload);
    //         }),
    //         catchError(error => {
    //             var modified = JSON.parse(JSON.stringify(error.error));
    //             var res = new WsResponse(
    //                 modified.status.code,
    //                 modified.status.name,
    //                 modified.status.description,
    //                 modified.payload);
    //             return throwError(res)
    //         })
    //     );
    // }
    // public getWordListByUserURL() {
    //     let url = ServiceUrls.GET_WORDLIST;
    //     return url;
    // }


	getWordList(): Observable<WsResponse>{
		let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
		const options = { params: new HttpParams().set("token", token) }
	 
		return this.http.get(ServiceUrls.GET_WORD_LIST, options).
		   pipe(
			  map(response => {
				 var modified = JSON.parse(JSON.stringify(response));
				 return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			   }),
			  catchError(error => {
				 var modified = JSON.parse(JSON.stringify(error.error));
				 var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				 return throwError(res)
			   })
			);
	   }

	/**
	 * Move Folder Items.
	 */
	public moveFolderItems(selectedFolderId: string, moveItemsList: any, callBack: WsCallback) {
		let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
		let moveitems = JSON.stringify(moveItemsList);
		const options = { params: new HttpParams().set("token", token).set("fileItemsList", moveitems) }
		let url = ServiceUrls.FOLDERS + selectedFolderId + '/moveFilesFromFolder.json?token=' + token;
		this.http.post(url, JSON.stringify(new Request(selectedFolderId)), options).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				callBack.onSuccess(res, WsType.MOVE_FILES_FROM_FOLDER);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					callBack.onFail(res, WsType.MOVE_FILES_FROM_FOLDER);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
				}
			}
		);
	}

	/**
	 * Define URL for delete folder.
	 */
	public getDeleteFolderURL() {
		let url = ServiceUrls.DELETE_FOLDER + this.getSelectedFoldeData().id + '.json'
		return url;
	}

	/**
	 * Define URL for delete folder.
	 */
	public getUpdateFolderURL() {
		let url = ServiceUrls.UPDATE_FOLDER + this.getSelectedFoldeData().id + '.json'
		return url;
	}


	public setSelectedFolderData(selectedFolderData: any) {
		this.selectedFolderData = selectedFolderData;
	}

	public getSelectedFoldeData() {
		return this.selectedFolderData;
	}


}

class Request {
	constructor(private id: String) { }

}
