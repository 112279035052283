import { Component, OnInit, Input, TemplateRef, ElementRef, HostListener, ViewChild, Injectable } from '@angular/core';
import { IceTopic } from './ice-topic/ice-topic-model.model';
import { IceLearningCheck } from './ice-learning-check/ice-learning-check.model';
import { OrderArrayByType } from '../util/orderarraybytype.pipe';
import { IceGame } from './ice-game/ice-game-model.model';
import { IceCourseLoaderService } from './ice-course-loader/ice-course-loader.service';
import { SharedService } from '../util/shared.service';
import { AlertService } from '../util/alert/alert.service';
import { UserVariable } from '../util/common/user-variable';
import { ICECourseService } from './ice-course-service.service';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from '../util/loading/loading.service';
import { FolderService } from './../foldertree/foldertree.service'
import { WsCallback } from '../util/ws-callback.interface';
import { WsType } from '../util/ws-type';
import { WsResponse } from '../util/ws-response.model';
import { ICECourse, ProcImage, ICEGlossary } from './ice-course.model';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal/';
import { ICECKEditor } from './ice-course';
import { FilesService } from '../home/files/files.service';
import { FilesComponent } from '../home/files/files.component';
import { LocalStorage } from './../util/localstorage.service';
import { CK_Editor_Type } from './../util/constants';
import { LearningCheckData_Details } from './../util/constants';
import { CommonUtil } from '../util/common/common-util';
import { DependenciesModalService } from '../util/dependenciesmodal/dependenciesmodal.service';
import { DependenciesmodalComponent } from '../util/dependenciesmodal/dependenciesmodal.component'
import { Subscription } from 'rxjs';
import * as $ from "jquery";
import { Module, AllModules } from "@ag-grid-enterprise/all-modules";

declare var CKEDITOR: any;
var editor;

export interface IHash {
	[key: string]: Array<any>;
}

@Component({
	selector: 'app-ice-course',
	templateUrl: './ice-course.component.html',
	styleUrls: ['./ice-course.component.scss']
})

@Injectable({
	providedIn: 'root'
})

export class IceCourseComponent implements OnInit, WsCallback {

	public divHeight: number = (window.innerHeight) * 4 / 5;
	@HostListener('window:resize', ['$event'])
	onresize(event?) {
		this.divHeight = (window.innerHeight) * 4 / 5
	}
	@ViewChild('conflict_checker') conflict_checker: TemplateRef<any>;
	public isShowCourseDetail: boolean = true
	public isShowIceTopic: boolean = false
	public isShowLearningChecks: boolean = false
	public isShowIceGames: boolean = false
	public isShowIceGlossary: boolean = false
	public isViewOnly: boolean = false;
	public topicsModified: boolean = false;
	public learningChecksModified: boolean = false;
	public glossaryModified: boolean = false;
	public courseModified: boolean = false;
	public gameModified: boolean = false;
	public courseEnabled: boolean = false;
	public topicsEnabled: boolean = false;
	public learningCheckEnabled: boolean = false;
	public glossaryEnabled: boolean = false;
	public gameEnabled: boolean = false;
	public navigateToT: boolean = false;
	public loadtopiclist: boolean = false;
	public loadLearningChecklist: boolean = false;
	public loadGlossary: boolean = false;
	public loadGameList: boolean = false;
	public topicData: any;
	public LearningCheckData: any;
	public glossaryData: any;
	public courseGameData: any;
	public isEnableReordering: boolean = true;
	public isDeleteEnable: boolean = true;
	public topicDependencies: number;
	public topicContentDependencies: number;
	public isLCDeleteEnable: boolean = true;
	public isGlossaryDeleteEnable: boolean = true;
	public isGameDeleteEnable: boolean = true;
	public questionDependencies: string;
	public glossaryCount: number;
	public gamesCount: number;
	modalRef: BsModalRef;
	preViewmodalRef: BsModalRef;
	public selectedGameType: string;
	public isLearningCheckList: boolean = true;
	public isLearningCheckDesc: boolean = true;
	public isGameList: boolean = true;
	public isGameDesc: boolean = true;

	private insertSubscription: any;
	private selectTabSubscription: any;
	private deleteTopicSubscription: Subscription;

	public filteredData: Array<any> = []
	public iceTopicList: Array<any> = []
	public learningCheckList: Array<any> = []
	public iceGameList: Array<any> = []
	public iceGlossaryList: Array<any> = []
	public iceTopicListByCourseId: Array<any> = []
	public iceGlossaryListByCourseId: Array<any> = []
	public iceGameListByCourseId: Array<any> = []
	public iceTopicAllListByCourseId: any
	public iceCMCourseList: Array<any> = []
	public iceCMLearningCheckList: Array<any> = []
	public iceCMGameList: Array<any> = []

	public speCharList: Array<any> = [];
	public editorList: Array<any> = [];
	public editorDivList: Array<any> = [];

	public course: ICECourse;
	public courseNameShowInDetails: any = null;
	public iceTopicModel: IceTopic;
	public iceLearningCheckModel: IceLearningCheck;
	public iceGlossaryModel: ICEGlossary;
	public iceGameModel: IceGame;
	public topicToDelete: IceTopic;
	public learningCheckToDelete: IceLearningCheck;
	public glossaryToDelete: ICEGlossary;
	public gameToDelete: IceGame;
	public indexOfTopicToDelete: number;
	public indexOfLearningCheckToDelete: number;
	public indexOfGlossaryToDelete: number;
	public indexOfGameToDelete: number;
	public newLearningCheck: IceLearningCheck;
	public newGlossaryItem: ICEGlossary;
	public newGame: IceGame;
	public iceCMCoursesAvailable: boolean = false;
	public cmLearningCheckListAvailable: boolean = false;
	public cmGameListAvailable: boolean = false;
	public selectedCMIceCourse: string = null;
	public selectedCMIceCourseVersion: string = null;
	public selectedCMLearningCheck: string = null;
	public selectedCMGame: string = null;
	public selectedConfirmed: boolean = false;
	public getCMLearningCheckData: boolean = false;
	public getCMGameData: boolean = false;
	public copyLearningCheckData: boolean = false;
	public copyGameData: boolean = false;
	public selectedCourseVersion: number = null;
	public addNewGameDropdownOpened:boolean = false;
	course_translated_data: any = null;

	public toolbarId: string;
	public desceditor: any;
	public titleditor: any;
	private html: string;
	private tagContent: String = '';
	private selection: any;
	private ranges: any;
	private topicHash: IHash = {};
	private topicContentHash: IHash = {};
	public userId: String;
	public tabId: string;
	public rowData: any[] = [];
	private isImageReusablePanelOpened: boolean = false;
	private isImageAdded: boolean = false;
	private isIconAdded: boolean = false;
	private newTopic: IceTopic;
	public addFractionDIvId: String = "";
	public addSpecialCharDivId: String = "";
	//private ck_editor_type: CK_EDITOR_TYPE;
	//private constant:Constants;
	public courseId: any;

	private xmlContent: string = '';
	private stmHtml: string;
	private gridApi;
	public getRowNodeId;
	public modules: Module[] = AllModules;
	private gridColumnApi;
	private rowSelection;
	public imageBackground: string = "#ffffff";
	public iconBackground: string = "#ffffff";
	public imageBorder: string = "solid #f0ba0a";
	public iconBorder: string = "solid #f0ba0a";
	public courseIconChecked: any = false;
	public courseImageChecked: any = false;
	public numberOfInitialReceivedPayloads: number;
	public totalNumberOfInitialPayloads: number = 3;
	public activeEditor: string = '';
	public selectedImage: any = {
		'id': '',
		'thumbnailImage': '',
		'largeImage': '',
		'title': ''
	};
	public selectedModalTemplate: TemplateRef<any>;

	public modalConfig: any = {
		backdrop: true,
		// ignoreBackdropClick: true,
		class: 'modal-lg',
		// keyboard: false
	}

	public modalPreviewConfig: any = {
		backdrop: true,
		// ignoreBackdropClick: true,
		class: 'modal-xl',
		// keyboard: false
	}

	private inlineConfig = {
		toolbar: [
			{ name: 'clipboard', groups: ['clipboard', 'undo'], items: ['Cut', 'Copy', '-', 'Undo', 'Redo'] },
			{ name: 'basicstyles', groups: ['basicstyles'], items: ['Bold', 'Italic', 'Underline', 'Subscript', 'Superscript'] },
			{ name: 'paragraph', groups: ['list'], items: ['NumberedList', 'BulletedList'] },
			// { name: 'link', items: ['Link'] },
			{ name: 'insert', groups: ['insert'], items: ['addsymbol', 'addfraction'] }
			//{ name: 'editing', groups: ['spellchecker'], items: ['Scayt'] }
		],
		autoParagraph: false,
		readOnly: false,
		removePlugins: 'magicline, maximize, resize, wsc',
		pasteFilter: 'plain-text',
		extraPlugins: 'statementclickhandler, sharedspace, addsymbol, addfraction, disablepartialbui, reusablepanelhandler, deletezws, deletehandler',
		title: false,
		tabID: SharedService.getTabId(),
		enterMode: CKEDITOR.ENTER_BR,
		startupFocus: 'end'
	};

	private inlineConfig_title = {
		toolbar: [
			{ name: 'clipboard', groups: ['clipboard', 'undo'], items: ['Cut', 'Copy', '-', 'Undo', 'Redo'] },
			{ name: 'basicstyles', groups: ['basicstyles'], items: ['Bold', 'Italic', 'Underline', 'Subscript', 'Superscript'] },
			//{ name: 'paragraph', groups: ['list'], items: ['NumberedList', 'BulletedList'] },
			{ name: 'insert', groups: ['insert'], items: ['addsymbol', 'addfraction'] }
			//{ name: 'editing', groups: ['spellchecker'], items: ['Scayt'] }
		],
		autoParagraph: false,
		readOnly: false,
		removePlugins: 'liststyle, magicline, maximize, resize, wsc',
		pasteFilter: 'plain-text',
		extraPlugins: 'statementclickhandler, sharedspace, addsymbol, addfraction, disablepartialbui, reusablepanelhandler, deletezws, deletehandler',
		title: false,
		tabID: SharedService.getTabId(),
		enterMode: CKEDITOR.ENTER_BR,
		startupFocus: 'end'
	};

	@Input() data: any;

	public numerator: string = ''
	public denominator: string = ''

	public columnDefs = [
		{ headerName: '', field: 'cbox', width: 50, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
		{ headerName: 'Type', field: 'type', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 100, enableRowGroup: true },
		{ headerName: 'Last Value', field: 'lastVal', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 100, enableRowGroup: true, tooltipField: "lastVal" },
		{ headerName: 'New Value', field: 'newVal', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 100, enableRowGroup: true, tooltipField: "newVal" },
		{ headerName: 'Id', field: 'id', hide: true },
		{ headerName: 'Name', field: 'name', hide: true },
		{ headerName: 'LastUpdateTimeStamp', field: 'lastUpdateTimeStamp', hide: true },
		{ headerName: 'isDeleted', field: 'isDeleted', hide: true }
	]
	
	public defaultColDef = {
		resizable: true,
		sortable: true,
	}

    constructor(private sharedService: SharedService, private translate: TranslateService,private loadingService: LoadingService,  private dependenciesService: DependenciesModalService,
        private alertService: AlertService, private courseService: ICECourseService, private folderService: FolderService, private modalService: BsModalService,private iceCKEditor: ICECKEditor
        , private elementRef: ElementRef,private filesService: FilesService, private commonUtil: CommonUtil) {
		this.course = new ICECourse("0");
		this.userId = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER)).userId;
		this.tabId = SharedService.getTabId();
		this.addFractionDIvId = 'webeditor_fraction_' + SharedService.getTabId();
		this.addSpecialCharDivId = 'webeditor_special_char_' + SharedService.getTabId();
		this.rowSelection = "multiple";
		// this.addDummyTopics(this.iceTopicList)
		//this.addDummyLCS(this.learningCheckList);
		//this.addDummyIceGames(this.iceGameList);
	}

	ngOnInit() {
		this.speCharList = [
			{ data: { symbol: this.getSymbolFromDecimal(169), decVal: 169, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(8482), decVal: 8482, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(174), decVal: 174, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(176), decVal: 176, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(163), decVal: 163, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(8364), decVal: 8364, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(10016), decVal: 10016, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(9746), decVal: 9746, type: "speChar" } },
			// There was an issue in LC related to this special character. Therefore it was removed from all the Ck Editors in ICE Course
			// { data: { symbol: this.getSymbolFromDecimal(9744), decVal: 9744, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(167), decVal: 167, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(937), decVal: 937, type: "speChar" } }
		];

		this.glossaryCount = this.iceGlossaryList.length;
		this.gamesCount = this.iceGameList.length;
		//set title from user entered value
		if (this.data) {
			if (this.data.isNewCourse == true) {		
				this.course.courseVersion = '1';
				this.course.fileName = this.data.fileName;
				this.course.folderId = this.data.folderId;
				//this.addDummyTopics(this.iceTopicList);
				this.numberOfInitialReceivedPayloads = 3;
				setTimeout(() => {
					//set course description editor content
					let e1 = this.elementRef.nativeElement.querySelector('.ckeditor_course_title' + this.tabId);
					e1.innerHTML = this.course.fileName;
				}, 200);
				this.sharedService.setOpenedICEModules(SharedService.getTabId(), 'ICECourse', this.course);
				let self = this;
				let commonVariables = this.sharedService.getCommonICEModuleVariableNames('course');
				var commonVariableValues: Array<any> = [];
				commonVariables.forEach(function (varName) {
					commonVariableValues[varName] = self[varName];
				}); 
				this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'course', commonVariableValues)
				//get all latest checked in ice courses 
				this.courseService.getLatestCMCourses( UserVariable.getUserToken(), this);
			} else {
				//show loading message
				var loadingMessage = null;
				this.translate.get('ICE_COURSE.LOADING_MESSAGE').subscribe((res: string) => {
					loadingMessage = res;
				});
				this.loadingService.showLoading(true, null, loadingMessage, null);
				console.log("CourseId : " + JSON.stringify(this.data));
                let token = UserVariable.getUserToken();
                if (!this.commonUtil.validateToken(token)) {
                    return;
				}
				this.numberOfInitialReceivedPayloads = 0;
				//get course details
				this.courseService.getICECourse(this.data.id, UserVariable.getUserToken(), this);
				
				// setTimeout(() => {
					//get topic details 
					this.courseService.getAllICETopicsByCourseId(this.data.id, UserVariable.getUserToken(), this);

					//get Learning Check details 
					this.courseService.getAllICELearningCheckByCourseId(this.data.id, UserVariable.getUserToken(), this);

					//get all latest checked in ice courses 
					this.courseService.getLatestCMCourses( UserVariable.getUserToken(), this);
				// }, 200);
			
			}

			//set view only
			this.isViewOnly = this.data.viewOnly;
			if (this.isViewOnly != null) {
				this.sharedService.setViewOnlyStatus(this.data.id, this.isViewOnly);
			}
		} else {
			//no data
		}

		//set course enabled true
		this.courseEnabled = true;
		this.sharedService.setModifiedStatus(this.tabId, this.courseModified)
	}
	ngAfterViewInit() {
		let self = this;
		let e1 = self.elementRef.nativeElement.querySelector('.ckeditor_course_description' + self.tabId);
		let e2 = self.elementRef.nativeElement.querySelector('.ckeditor_course_title' + self.tabId);
		if (self.isViewOnly == true) {
			e1.style.backgroundColor = "#e9ecef";
			e2.style.backgroundColor = "#e9ecef";
		} else {
			this.setCourseEditors(e1, e2);
		}

		//this is called when topic is deleted from dependancy model
		this.deleteTopicSubscription = this.sharedService.deleteICETopicDoneResponse().subscribe(
			option => {
			if (option.isTopic == false) {
				if (this.tabId == SharedService.getTabId()) {
					this.deleteTopicProcess();
				} else {
					//diferent tab
				}
			} 
      });
	}

	openModalCC(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template, { class: 'modal-lg', backdrop: 'static', keyboard: false });
	}

	onGridReady(params) {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
	}

	setCourseEditors(e1, e2) {
		let self = this;
		if (e1.innerHTML == '') {
			e1.innerHTML = "&nbsp;";
		} else {
			//do nothing
		}
		if (e2.innerHTML == '') {
			e2.innerHTML = '&nbsp;';
		} else {
			//do nothing
		}
		if (self.course.courseDescription != undefined) {
			e1.innerHTML = self.course.courseDescription;
		} else {
			//do nothing
		}
		if (self.course.courseName != undefined) {
			e2.innerHTML = self.course.courseName;
		} else {
			//do nothing
		}

		$('.title_ck_editor' + self.tabId).dblclick(function (e) {
			if (e.target.nodeName != "PH" || (self.titleditor == null) || (self.titleditor == undefined)) {
				if ($(this)[0].attributes['contenteditable'].nodeValue == "false") {
				if (self.courseEnabled == true) {
					self.courseModified = true;
					self.sharedService.setModifiedStatus(self.tabId, self.courseModified)
					if (self.titleditor) {
						self.titleditor.destroy();
					} else {
						//no editor
					}
					self.titleditor = CKEDITOR.inline(e2, self.inlineConfig_title);
					if (self.desceditor) {
						self.desceditor.destroy();
					} else {
						//no editor
					}
					//set description editable false
					e1.setAttribute("contenteditable", "false");
					if (e1.innerHTML == '') {
						e1.innerHTML = "&nbsp;";
					} else {
						//do nothing
					}

					//set title editable true
					e2.setAttribute("contenteditable", "true");
					self.setEditorFunctions(self.titleditor);
				} else {
					//do nothing
				}
			}
			} else {
				if ($(this)[0].attributes['contenteditable'].nodeValue == "true") {
					SharedService.setselectedNode(e.target);
					let item = e.target.attributes['restriction'].nodeValue;
                    let catId = e.target.attributes['catId']
                    if (e.target.attributes['catId'] != undefined && e.target.attributes['catId'] != null) {
                        catId = e.target.attributes['catId'].nodeValue;
                    }                       
                    self.iceCKEditor.getRestrictionValueOndblClick(item,catId);
                }				
				self.setEditorFunctions(self.titleditor);
			}
		});
		$('.desc_ck_editor' + self.tabId).dblclick(function (e) {
			if (e.target.nodeName != "PH" || (self.desceditor == null) || (self.desceditor == undefined)) {
				if ($(this)[0].attributes['contenteditable'].nodeValue == "false") {
				if (self.courseEnabled == true) {
					self.courseModified = true;
					self.sharedService.setModifiedStatus(self.tabId, self.courseModified)
					if (self.desceditor) {
						self.desceditor.destroy();
					} else {
						//no editor
					}
					self.desceditor = CKEDITOR.inline(e1, self.inlineConfig);
					if (self.titleditor) {
						self.titleditor.destroy();
					} else {
						//no editor
					}
					if (e2.innerHTML == '') {
						e2.innerHTML = '&nbsp;';
					} else {
						//do nothing
					}
					//set title editable false
					e2.setAttribute("contenteditable", "false");

					//set description editable true
					e1.setAttribute("contenteditable", "true");

					self.setEditorFunctions(self.desceditor);
				} else {
					//do nothing
				}
			}
			} else {
				if ($(this)[0].attributes['contenteditable'].nodeValue == "true") {
					SharedService.setselectedNode(e.target);
                    let item = e.target.attributes['restriction'].nodeValue;
                    let catId = e.target.attributes['catId']
                    if (e.target.attributes['catId'] != undefined && e.target.attributes['catId'] != null) {
                        catId = e.target.attributes['catId'].nodeValue;
                    }                       
                    self.iceCKEditor.getRestrictionValueOndblClick(item,catId);
                }				
				self.setEditorFunctions(self.desceditor);
			}
		});
	}

	setEditorFunctions(editor) {
		var self = this;
		if (editor) {
			editor.on('instanceReady', function (evt) {
				var range = editor.createRange();
				if (range != null) {
					range.moveToElementEditEnd(range.root);
					editor.getSelection().selectRanges([range]);

				}
			});

			editor.on('key', function (e) {
				self.iceCKEditor.editorKeyDown(e, editor);
			});

			editor.on('paste', function (evt) {
				evt.stop();
				var data: string = evt.data.dataValue;
				let pasteData: string = self.iceCKEditor.processPasteData(data);
				if(self.iceCKEditor.gethasResuables()) {
					self.alertService.clear();
					self.alertService.toast(self.translate.instant('ICE_COURSE.DISABLED_EDITOR_COPY'));
				}
				evt.editor.insertHtml(pasteData);
			});

			editor.on('focus', function (evt) {
				if(navigator.userAgent.toLowerCase().indexOf("firefox") > -1){
					let wintitles = editor.container.getElementsByTag("wintitle");
					for(let i=0; i<wintitles.$.length; i++){
						wintitles.$[i].removeAttribute("contenteditable");
					}
				}
			});
		} else {
			//no editor
		}
		this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'course', editor, 'editor')
	}

	ngAfterContentInit() {

		//unsubscribe
		if (SharedService.getIceCourseSubscriptions() != null) {
			SharedService.getIceCourseSubscriptions().unsubscribe();
		}

		this.selectTabSubscription = this.sharedService.setICEModuleChanges.subscribe(prevTab => {
			//get opened modules
			let openedICEModules = this.sharedService.getOpenedICEModules();
			//check if tab type is ICELearningCheck
			if (openedICEModules[prevTab] != undefined && this.tabId == prevTab) {
				// get tab type
				var type = openedICEModules[prevTab]['type'];
				if (type == "ICECourse") {
					this.sharedService.setOpenedICEModules(prevTab, 'ICECourse', this.course);
				} else {
					//differnt type
				}
			} else {
				//do nothing
			}
		});

		this.selectTabSubscription = this.sharedService.setICEModuleVariables.subscribe(prevTab => {
			if(this.tabId == prevTab) {
				let self = this;
				let commonVariables = this.sharedService.getCommonICEModuleVariableNames('course');
				var commonVariableValues: Array<any> = [];
				commonVariables.forEach(function (varName) {
					commonVariableValues[varName] = self[varName];
				}); 
				this.sharedService.setCommonICEModuleVariableValues(prevTab, 'course', commonVariableValues);
			}
		});

		this.sharedService.setOpenedICEModule.subscribe(newTab => {
			let openedICEModules = this.sharedService.getOpenedICEModules();
			if(openedICEModules[newTab] != undefined) {
				this.course = openedICEModules[newTab]['module'];
			}
		});

		this.sharedService.setOpenedICEVariables.subscribe(newTab => {
			if (this.tabId == newTab) {
				let commonVariables = this.sharedService.getCommonICEModuleVariableNames('course');
				var commonVariableValues: Array<any> = [];
				commonVariableValues = this.sharedService.getCommonICEModuleVariableValues(newTab);
				if(commonVariableValues != undefined) {
					let self = this;
					commonVariables.forEach(function (varName) {
						self[varName] = commonVariableValues[varName];
					});
				}
			}
		});

		//Called after ngOnInit when the component's or directive's content has been initialized.
		//Add 'implements AfterContentInit' to the class.

		this.insertSubscription = SharedService.insertICECourseExecute().subscribe(option => {

			//get opened modules
			let openedICEModules = this.sharedService.getOpenedICEModules();
			//get current tab
			var currentTab = SharedService.getTabId();

			//check if current tab type is ICECourse
			if (openedICEModules[currentTab] != undefined) {
				// get tab type
				var type = openedICEModules[currentTab]['type'];

				if (type == "ICECourse") {
					let commonVariableValues = this.sharedService.getCommonICEModuleVariableValues(SharedService.getTabId());
					//reusables not available for learning checks, check the available editor before adding reusables
					if (commonVariableValues.learningCheckEnabled == false && commonVariableValues.glossaryEnabled == false && commonVariableValues.gameEnabled == false) {
						this.iceCKEditor.insertTagEditArea(option, commonVariableValues.elementRef, commonVariableValues.editor, CKEDITOR, this.course, "ICECourse");
					} else if (commonVariableValues.learningCheckEnabled == true) {
						this.alertService.warn(this.translate.instant('ICE_LEARNING_CHECK.ADD_REUSABLES_WARNING'));
					} else if (commonVariableValues.glossaryEnabled == true) {
						this.alertService.warn(this.translate.instant('ICE_GLOSSARY.ADD_REUSABLES_WARNING'));
					} else if (commonVariableValues.gameEnabled == true) {
						this.alertService.warn(this.translate.instant('ICE_GAME.ADD_REUSABLES_WARNING'));
					}
				} else {
					//differnt type
				}
			} else {
				//do nothing
			}

		});
		this.sharedService.imageStatusRespond().subscribe(imageStatus => this.setImageStatus(imageStatus));
		SharedService.setIceCourseSubscriptions(this.insertSubscription);
	}

	setImageStatus(type) {
		if (type.status == "ICECourse" && type.tabId == this.tabId) {
			this.courseModified = true;
			this.sharedService.setModifiedStatus(this.tabId, this.courseModified)
		}
	}

	showICECourseDetail(isShowCourseDetail) {
		let self = this;
		if (isShowCourseDetail == true) {
			if (self.courseModified == true) {
				self.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_COURSE'));
			} else {
				self.isShowCourseDetail = false;
			}
		} else {
			self.isShowCourseDetail = true;
			setTimeout(() => {
				//set course description editor content
				let e1 = self.elementRef.nativeElement.querySelector('.ckeditor_course_description' + self.tabId);
				e1.innerHTML = self.course.courseDescription;
				//set course title editor content
				let e2 = self.elementRef.nativeElement.querySelector('.ckeditor_course_title' + self.tabId);
				e2.innerHTML = self.course.courseName;
				if (self.courseEnabled == false) {
					self.disableCourseEditors();
				} else {
					self.setCourseEditors(e1, e2);
				}
			}, 200);
		}
	}

	setInputKeyUp() {
		let self = this;
		$('.topiclisttitle').keyup(function () {
			//set modified true
			self.topicsModified = true;
			self.sharedService.setModifiedStatus(self.tabId, self.topicsModified)
		});
	}

	showICETopic(isShowIceTopic) {
		let self = this;
		if (isShowIceTopic == true) {
			if (self.topicsModified == true) {
				self.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_TOPICS'));
			} else {
				self.isShowIceTopic = false;
			}
		} else {
			//remove unsaved topic 
			console.log("self.iceTopicList.length" + self.iceTopicList.length);
			self.iceTopicList = self.iceTopicList.filter(function (a) {
				return a.topicName != undefined;
			});
			self.isShowIceTopic = true;
			setTimeout(() => {
				self.setEditor("", CK_Editor_Type.TOPIC_EDITOR);
			}, 200);

		}
	}

	showAllTopic(iceTopic: IceTopic) {
		this.alertService.info("Just CLick!");
	}


	titleClick() {
		//set editor
		var self = this;
		$('.ckeditor_course_title' + SharedService.getTabId()).dblclick(function (e) {
			editor = self.titleditor;
			self.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'course', editor, 'editor')
		})
	}

	descriptionClick() {
		//set editor
		var self = this;
		$('.ckeditor_course_description' + SharedService.getTabId()).dblclick(function (e) {
			editor = self.desceditor;
			self.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'course', editor, 'editor')
		})
	}

	editCourse() {
		let self = this;
		if (self.topicsModified == true) {
			self.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_TOPICS'));
		} else if (self.learningChecksModified == true) {
			self.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_LEARNINGCHECKS'));
		} else if (self.glossaryModified == true) {
			self.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_GLOSSARY'));
		} else if (self.gameModified == true) {
			self.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_GAMES'));
		} else {
			self.courseEnabled = true;
			self.topicsEnabled = false;
			self.learningCheckEnabled = false;
			self.glossaryEnabled = false;
			self.gameEnabled = false;
			this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'course', true, 'courseEnabled');
			this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'course', false, 'topicsEnabled');
			this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'course', false, 'learningCheckEnabled');
			this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'course', false, 'glossaryEnabled');
			this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'course', false, 'gameEnabled');

			//destroy topic editors
			for (let i in this.editorList) {
				if (CKEDITOR.instances[this.editorList[i].name] != undefined) {
					CKEDITOR.instances[this.editorList[i].name].destroy();
				}
			}

			self.isShowCourseDetail = true
			setTimeout(() => {
				//set background colors
				let e1 = self.elementRef.nativeElement.querySelector('.ckeditor_course_description' + self.tabId);
				let e2 = self.elementRef.nativeElement.querySelector('.ckeditor_course_title' + self.tabId);
				e1.style.backgroundColor = "";
				e2.style.backgroundColor = "";
				self.setCourseEditors(e1, e2);
			}, 200);
			// Handling visbility of divs
			self.isShowIceTopic = false;
			self.isShowLearningChecks = false;
			self.isShowIceGlossary = false;
			self.isShowIceGames = false;
		}
	}

	editTopics() {
		let self = this;
		self.activeEditor = CK_Editor_Type.TOPIC_EDITOR;
		if (self.courseModified == true) {
			self.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_COURSE'));
		} else if (self.learningChecksModified == true) {
			self.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_LEARNINGCHECKS'));
		} else if (self.glossaryModified == true) {
			self.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_GLOSSARY'));
		} else if (self.gameModified == true) {
			self.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_GAMES'));
		} else {
			self.topicsEnabled = true;
			self.courseEnabled = false;
			self.learningCheckEnabled = false;
			self.glossaryEnabled = false;
			self.gameEnabled = false;
			this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'course', false, 'courseEnabled');
			this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'course', true, 'topicsEnabled');
			this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'course', false, 'learningCheckEnabled');
			this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'course', false, 'glossaryEnabled');
			this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'course', false, 'gameEnabled');

			//destroy editors
			if (self.titleditor) {
				self.titleditor.destroy();
			} else {
				//no editor
			}
			if (self.desceditor) {
				self.desceditor.destroy();
			} else {
				//no editor
			}

			self.isShowIceTopic = true;
			setTimeout(() => {
				self.setEditor("", CK_Editor_Type.TOPIC_EDITOR);
			}, 200);

			// Handling visbility of divs
			self.isShowCourseDetail = false;
			self.isShowLearningChecks = false;
			self.isShowIceGlossary = false;
			self.isShowIceGames = false;
		}
	}

	disableCourseEditors() {
		let self = this;
		//set background colors in course editors
		let e1 = self.elementRef.nativeElement.querySelector('.ckeditor_course_description' + self.tabId);
		let e2 = self.elementRef.nativeElement.querySelector('.ckeditor_course_title' + self.tabId);
		if (e1) {
			e1.style.backgroundColor = "#e9ecef";
			e1.setAttribute("contenteditable", "false");
		} else {
			//do nothing
		}

		if (e2) {
			e2.style.backgroundColor = "#e9ecef";
			e2.setAttribute("contenteditable", "false");
		} else {
			//do nothing
		}
	}

	/**
	 * show course image/icon from selected item of image search
	 */
	displayImage(option) {
		if (this.course.selectedType == "Image") {
			//set course image
			this.course.courseImage = new ProcImage(option.data.id, option.data.thumbnailImage, option.data.title);
		} else if (this.course.selectedType == "Icon") {
			//set course icon
			this.course.courseIcon = new ProcImage(option.data.id, option.data.thumbnailImage, option.data.title);
		}
	}

	showLargeImage(template: TemplateRef<any>, selectedImageId: any) {
		console.log('[showLargeImage]');
		// this.selectedImage = selectedImage
		// this.modalRef = this.modalService.show(template, this.modalConfig);

		this.selectedImage = {
			'id': '',
			'thumbnailImage': '',
			'largeImage': '',
			'title': ''
		}
        //show loading message
        var loadingMessage = null;
        this.translate.get('ICE_COURSE.COURSE_PREVIEW_IMAGE_LOADING').subscribe((res: string) => {
            loadingMessage = res;
        });
		this.loadingService.showLoading(true, null, loadingMessage, null);
        this.courseService.getSelectedImage(selectedImageId, UserVariable.getUserToken(), this);
        this.selectedModalTemplate = template;
	}

	public modelRefCloseEvent() {
		this.modalRef.hide()
		this.selectedImage = {
			'id': '',
			'thumbnailImage': '',
			'largeImage': '',
			'title': ''
		}
	}

	private getSymbolFromDecimal(value: number): string {
		if ((33 <= value) && (value < 65536)) {
			return String.fromCharCode(value);
		} else {
			this.alertService.error("Please enter valid Unicode character entity reference. Range is between 33 to 65535", true);
		}
	}


	openModal(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template);
	}

	coursePreview(template: TemplateRef<any>) {
		let self = this;
		if (self.courseModified == true) {
			self.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_COURSE'));
		} else if (self.topicsModified == true) {
			self.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_TOPICS'));
		} else if (self.glossaryModified == true) {
			self.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_GLOSSARY'));
		} else if (self.gameModified == true) {
			self.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_GAMES'));
		} else {
			let courseNameTmp = this.course.courseName.replace(/<[^>]*>/g, '');
			let dataToPreview = {"id" : this.course.courseId, "title" : courseNameTmp};
			this.sharedService.openTabRequestWithId("Preview_" + this.course.courseId, "Preview of " + courseNameTmp, "CoursePreview", dataToPreview, "Preview of " + courseNameTmp);
            let token = UserVariable.getUserToken();
            if (!this.commonUtil.validateToken(token)) {
                return;
            }
			// this.courseService.getICECourse(this.data.id, UserVariable.getUserToken(), this);
			//show loading message
			// var loadingMessage = null;
			// this.translate.get('ICE_COURSE.LOADING_MESSAGE').subscribe((res: string) => {
			// 	loadingMessage = res;
			// });
			// this.loadingService.showLoading(true, null, loadingMessage, null);
			// this.courseService.getAllICETopicListOfICECourse(this.data.id, UserVariable.getUserToken(), this);
			// this.courseService.getAllICETopicContentListOfICECourse(this.data.id, UserVariable.getUserToken(), this);
			// this.preViewmodalRef = this.modalService.show(template, this.modalPreviewConfig);
		}
	}

	/**
	 * course image text box click event
	 */
	addCourseImage() {
		this.course.selectedType = "Image";
		this.changeToImagePanel();
	}

	/**
	 * course icon text box click event
	 */
	addCourseIcon() {
		this.course.selectedType = "Icon";
		this.changeToImagePanel();
	}

	/**
	 * course image remove button click event
	 */
	removeCourseImage() {
		this.course.courseImage = null;
		//set course modified ture
		this.courseModified = true;
		this.sharedService.setModifiedStatus(this.tabId, this.courseModified)
	}

	/**
	 * course icon remove button click event
	 */
	removeCourseIcon() {
		this.course.courseIcon = null;
		//set course modified ture
		this.courseModified = true;
		this.sharedService.setModifiedStatus(this.tabId, this.courseModified)
	}

	ifCheckedImage(){
	    if(this.courseImageChecked == true){
			this.course.selectedType = "";
			this.removeCourseImage();
			this.courseImageChecked = false;
			this.imageBackground = "#ffffff";
			this.imageBorder = "solid #f0ba0a";
		  } else if(this.courseImageChecked == false){
			this.imageBackground = "#f0ba0a";
			this.imageBorder = "none";
			this.removeCourseImage();
			this.addCourseImage();
			this.courseImageChecked = true;
		}
	}

	ifCheckedIcon(){
	    if(this.courseIconChecked == true){
			this.course.selectedType = "";
			this.removeCourseIcon();
			this.courseIconChecked = false;
			this.iconBackground = "#ffffff";
			this.iconBorder = "solid #f0ba0a";
		  } else if(this.courseIconChecked == false){
			this.iconBackground = "#f0ba0a";
			this.iconBorder = "none";
			this.removeCourseIcon();
			this.addCourseIcon();
			this.courseIconChecked = true;
		}
	}

	/**
	 * open image search tab
	 */
	private changeToImagePanel() {
		SharedService.openReusablepanel('images');
		this.isImageReusablePanelOpened = true;
	}

	/**
	 * restric user typing
	 */
	returnTyping() {
		return false;
	}

	private getEditorContent(editorName: string): string {
		let el = this.elementRef.nativeElement.querySelector(editorName);
		return el.innerHTML;
	}

	/**
	 * course detail save button click event
	 */
	public courseDetailSave() {
		this.xmlContent = "";
		console.log('[courseDetailSave]');
		//get course description ck editor content
		let content = this.getEditorContent('.ckeditor_course_description' + this.tabId);
		//process content
		this.iceCKEditor.processXML(content, false, this.xmlContent);
		this.xmlContent = this.iceCKEditor.getXMLContent();
		//set course description
		this.course.courseDescription = this.xmlContent;
		//console.log(this.xmlContent);

		this.xmlContent = "";
		//get course title ck editor content
		let content_title = this.getEditorContent('.ckeditor_course_title' + this.tabId);
		//process content
		this.iceCKEditor.processXML(content_title, false, this.xmlContent);
		this.xmlContent = this.iceCKEditor.getXMLContent();
		//set course description
		this.course.courseName = this.xmlContent;

		//validate form
		if (!this.validateForm()) {
			return;
		} else {
			console.log('Validated course : ' + JSON.stringify(this.course));

			//show loading message
			var loadingMessage = null;
			this.translate.get('ICE_COURSE.SAVING_MESSAGE').subscribe((res: string) => {
				loadingMessage = res;
			});
			this.loadingService.showLoading(true, null, loadingMessage, null);


			//request
			var request = {
				"courseId": (this.data.isNewCourse && this.data.id != undefined) ? this.data.id : this.course.courseId,
				"courseName": "<ice>" + this.course.courseName + "</ice>",
				"fileName": this.course.fileName,
				"courseDescription": "<ice>" + this.course.courseDescription + "</ice>",
				"graphicId": (this.course.courseImage != null &&  this.course.courseImage.id != '') ? this.course.courseImage.id : null,
				"iconId": (this.course.courseIcon != null  &&  this.course.courseIcon.id != '')? this.course.courseIcon.id : null,
				"status": "Active",
				"sequence": 0,
				"courseVersion": this.course.courseVersion,
				"folderId": this.course.folderId
			};
			if (this.data.isNewCourse != true) {
				//update course
                console.log('[Update course() was called]');
                let token = UserVariable.getUserToken();
                if (!this.commonUtil.validateToken(token)) {
                    return;
                }
                this.courseService.updateICECourse(JSON.stringify(request), token, this);
				// if(selectedFolderData.id!=null){
                // 	this.sharedService.folderRefreshRequest(selectedFolderData.id);
                // }
            } else {
                //add new course
                console.log('[Create course() was called]');
                let token = UserVariable.getUserToken();
                if (!this.commonUtil.validateToken(token)) {
                    return;
                }
                this.courseService.createICECourse(JSON.stringify(request), token, this);
				// if(selectedFolderData.id!=null){
				// 	this.sharedService.folderRefreshRequest(selectedFolderData.id);
				// }
			}
			//setcoursemodified false
			this.courseModified = false;
			this.sharedService.setModifiedStatus(this.tabId, this.courseModified)
		}

	}

	/**
	 * validate form
	 */
	validateForm(): boolean {
		let self = this;
		var editortext_title = self.replaceWhiteSpace($(".ckeditor_course_title" + self.tabId).text());
		var length_title = editortext_title.length;
		if (length_title < 1) {
			self.alertService.error(self.translate.instant('ICE_COURSE.COURSE.TITLE_ERROR'));
			return false;
		}
		/*if (this.course.courseName == undefined || this.course.courseName == "") {
			console.log('CourseName is undefined|null|empty!!!');
			return false;
		}*/
		var editortext_desc = self.replaceWhiteSpace($(".ckeditor_course_description" + self.tabId).text());
		var length_desc = editortext_desc.length;
		if (length_desc < 1) {
			self.alertService.error(self.translate.instant('ICE_COURSE.COURSE.DESCRIPTION_ERROR'));
			return false;
		}
		/*if (this.course.courseImage == null) {
			console.log('CourseImage is null!!!');
			return false;
		}
		if (this.course.courseIcon == null) {
			console.log('CourseIcon is null!!!');
			return false;
		}*/
		console.log('[Details are valid]');
		return true;
	}
	isNullOrWhitespace(input) {
		return !/\S/.test(input);
	}

	replaceWhiteSpace(str) {
		return str.replace(/[\s\u200B]/g, '');
	}

	//save topic list under course 
	topicsSave(showValidation) {

		//update topic name
		this.updateTopicName();

		if (this.iceTopicList.length > 0) {
			var request = {
				"courseId": this.course.courseId,
				"courseVersion": this.course.courseVersion,
				"topics": this.iceTopicList
			};

			//show loading message
			console.log(this.iceTopicList);
			var loadingMessage = null;
			this.translate.get('ICE_COURSE.SAVE_TOPICS_MESSAGE').subscribe((res: string) => {
				loadingMessage = res;
			});
			this.loadingService.showLoading(true, null, loadingMessage, null);
            let token = UserVariable.getUserToken();
            if (!this.commonUtil.validateToken(token)) {
                return;
            }
			this.courseService.saveTopicList(JSON.stringify(request), token, this);
			//settopicsmodified false
			this.topicsModified = false;
			this.sharedService.setModifiedStatus(this.tabId, this.topicsModified)
		} else {
			if (showValidation == true) {
				this.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.NO_TOPICS_ERROR'));
			} else {
				//do not show
			}
		}
		if (showValidation == true) {
			this.loadtopiclist = true;
		} else {
			//navigate
		}
	}

	//process topic list before delete a topic 
	processTopicList() {
		this.courseService.getAllICETopicsByCourseId(this.course.courseId, UserVariable.getUserToken(), this); 				
	}

	updateTopicName() {
		for (let i = 1; i <= this.iceTopicList.length; i++) {
			if (this.iceTopicList[i - 1].isTopicModified == true) {
				this.xmlContent = "";
				//check if edior text is empty
				var editortext = $('.t_ck_editor' + i + this.tabId).text();
				if (this.isNullOrWhitespace(editortext) == true) {
					this.iceTopicList[i - 1].topicName = '<ice></ice>';
				} else {
					//process name
					var name = this.getEditorContent('.t_ck_editor' + i + this.tabId);
					this.iceCKEditor.processXML(name, false, this.xmlContent);
					this.xmlContent = this.iceCKEditor.getXMLContent();
					this.iceTopicList[i - 1].topicName = '<ice>' + this.xmlContent + '</ice>';
				}
			} else {
				//topic not modified 
			}
		}
	}

	updateTopicNameAfterDelete() {
		this.iceTopicList = this.sharedService.getTopicList();
		for (let i = 1; i <= this.iceTopicList.length; i++) {
			if (this.iceTopicList[i - 1].isTopicModified == true) {
				this.xmlContent = "";
				var editorcontent = this.getEditorContent('.t_ck_editor' + i + this.tabId);
				this.iceTopicList[i - 1].topicName = editorcontent;
			} else {
				//topic not modified 
			}
		}
	}

	/**
	 * add button click event in ice topic list
	 * add new ice topic 
	 */
	public addNewIceTopic() {
		//show loading
		var loadingMessage = null;
		this.translate.get('ICE_TOPIC.SAVING_MESSAGE').subscribe((res: string) => {
			loadingMessage = res;
		});
		this.loadingService.showLoading(true, null, this.translate.instant('ICE_TOPIC.SAVING_MESSAGE'), null);

		let self = this;
		if (self.isShowIceTopic == true) {
			//do nothing
		} else {
			this.isShowIceTopic = true;
			setTimeout(() => {
				self.setEditor("", CK_Editor_Type.TOPIC_EDITOR);
			}, 200);
		}
		//set topic modified
		this.topicsModified = true;
		this.sharedService.setModifiedStatus(this.tabId, this.topicsModified)
		console.log('[addNewIceTopic]');
		let listLength: number = this.iceTopicList.length

		// Request
		this.newTopic = {
			id: '0',
			topicName: "<ice><p>New Topic Menu</p></ice>",
			courseId: this.course.courseId,
			sequence: (listLength + 1),
			description: "<ice></ice>",
			courseVersion: +this.course.courseVersion,
			isTopicModified: true // isTopicModified true before add to the list, otherwise u can't save it
		}
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
		this.courseService.createICETopic(JSON.stringify(this.newTopic), token, this);

		/*let newTopic: IceTopic = {
			id: '0', 
		//	topicName: 'Sample ICE Topic - ' + (listLength+1),
			sequence: (listLength + 1),
			//copyTopicId: ''
		}
		this.iceTopicList.push(newTopic);
		setTimeout(() => {
			this.focusNewEditor('.t_ck_editor'+ (listLength + 1), true);
		  }, 200);*/

		// Handling visbility of divs
		this.isShowCourseDetail = false;
		this.isShowLearningChecks = false;
		this.isShowIceGames = false;
		this.isShowIceGlossary = false;
	}

	/**
	 * delete ice topic 
	 * @param topic 
	 */
	public deleteIceTopic(topic: IceTopic, index: number, template: TemplateRef<any>) {

		if (this.topicsModified == false || (this.topicsModified == true && this.iceTopicList.length == 1)) {
			console.log('[deleteIceTopic]');
			//process topic list before delete
			this.processTopicList();
			if (topic.id != '0') {
				//show loading message
				var loadingMessage = null;
				this.translate.get('LOADING').subscribe((res: string) => {
					loadingMessage = res;
				});
				let token = UserVariable.getUserToken();
				if (!this.commonUtil.validateToken(token)) {
					return;
				}
				this.loadingService.showLoading(true, null, loadingMessage, null);
				// this.courseService.viewDependenciesForTopic(topic.id, token, this);
				this.sharedService.setFileId(topic.id);
				this.sharedService.setFileType("ICETopic");
				this.sharedService.setAction("getDependencies");
				this.sharedService.setCourseId(topic.courseId);
				this.sharedService.setDependencyMessageType("Delete");
				this.sharedService.setIsICETopic(false);
				localStorage.setItem("isDelete", "true");
				// this.topicDependencies = 0;
				// this.topicContentDependencies = 0;
				// this.isDeleteEnable = true;
				this.topicToDelete = topic;
				this.sharedService.setDeleteTopicIndex(index);
				// this.modalRef = this.modalService.show(template, this.modalConfig);
				this.modalRef = this.modalService.show(DependenciesmodalComponent, { class: 'modal-lg' });
			}
			else {
				this.deleteItemFromTopicList(index);
			}
		} else {
			this.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_TOPICS_BEFORE_DELETE'));
		}

	}

	public confirmDeleteTopic() {
		// Delete Topic
		console.log('[Delete topic() was called]');
		//show loading message
		var loadingMessage = null;
		this.translate.get('ICE_TOPIC.TOPIC_DELETING_MESSAGE').subscribe((res: string) => {
			loadingMessage = res;
		});
		var request = {
			"id": this.topicToDelete.id,
			"courseId": this.topicToDelete.courseId,
			"topicParentId": this.topicToDelete.topicParentId,
			"sequence": this.topicToDelete.sequence
		}
		this.loadingService.showLoading(true, null, loadingMessage, null);
		console.log(JSON.stringify(this.topicToDelete.id));
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
		}
		this.courseService.deleteICETopic(JSON.stringify(request), this.topicToDelete.id, token, this);
	}

	/**
	 * change the position of the ice topic 
	 * move up, move down button click event
	 * @param topic 
	 * @param type 
	 */
	public changePositionIceTopic(topic: IceTopic, index: number, dir: string) {
		this.isEnableReordering = false;
		console.log('[changePositionIceTopic] (iceTopic/dir) ' + JSON.stringify(topic) + '/' + dir);
		this.iceTopicList.splice(index, 1);
		if(dir == "up"){
		  console.log("up");      
		  let iceTopic:IceTopic = this.iceTopicList[index-1];
		  this.iceTopicList.splice(index-1, 0, topic);
		  if(iceTopic.id != "0"){
			// Request
			var request = {
			  "id": iceTopic.id,
			  "courseId": iceTopic.courseId,
			  "topicParentId":iceTopic.topicParentId,
			  "sequence": iceTopic.sequence
			};
			console.log(JSON.stringify(request));
			this.iceTopicList[index-1].sequence -= 1;
			this.iceTopicList[index].sequence += 1;
              let token = UserVariable.getUserToken();
              if (!this.commonUtil.validateToken(token)) {
                  return;
              }
              this.courseService.reorderICETopic(JSON.stringify(request), token, this);
		  }
		  else{
			// nothing to do here
		  }
		  
		}
		else if(dir == "down"){
		  console.log("down");
		  this.iceTopicList.splice(index+1, 0, topic);
		  if(topic.id != "0"){
			// Request
			var request = {
			  "id": topic.id,
			  "courseId": topic.courseId,
			  "topicParentId": topic.topicParentId,
			  "sequence": topic.sequence
			};
			this.iceTopicList[index].sequence -= 1;
			this.iceTopicList[index+1].sequence += 1;
              let token = UserVariable.getUserToken();
              if (!this.commonUtil.validateToken(token)) {
                  return;
              }
              this.courseService.reorderICETopic(JSON.stringify(request), token, this);
		  }
		  else{
			// nothing to do here
		  }
		}
		else{
		  // nothing to do here
		}
	}

	/**
	 * show more info button click 
	 * arrow right button click event
	 * @param topic 
	 */

	public showInfoIceTopic(iceTopic: IceTopic) {
		if (this.courseModified == true) {
			this.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_COURSE'));
		} else if (this.learningChecksModified == true) {
			this.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_LEARNINGCHECKS'));
		} else if (this.glossaryModified == true) {
			this.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_GLOSSARY'));
		} else if (this.gameModified == true) {
			this.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_GAMES'));
		} else {
			this.navigateToT = true;
			this.topicData = iceTopic;
			if (this.isViewOnly == true || this.topicsModified == false) {
				this.topicData.courseId = this.course.courseId;
				this.topicData.parentType = 'course';
				this.topicData.parent = this.course;
				let data = {
					courseId: this.course.courseId,
					iceTopic: this.topicData,
					viewOnly: this.isViewOnly,
					iceType: 'topic'
				}
				var html = this.course.courseNameHTML;
				var div = document.createElement("div");
				div.innerHTML = html;
				var text = div.textContent || div.innerText || div.style || "";
				let data2 = {
					courseId: this.course.courseId,
					course: this.course,
					viewOnly: this.isViewOnly,
					iceType: 'IceCourse',
					title: text,
					id: this.course.courseId,
				}

				this.sharedService.changeIceItemRequest('topic', data);
				this.sharedService.setICEBreadCrumb(this.course.courseId, data2);
				//do not save
			} else {
				//auto save topics
				this.topicsSave(false);
				// this.glossarySave(false);
			}

			console.log('[showInfoIceTopic] (topic) ' + JSON.stringify(iceTopic));
		}

	}

	/**
	 * show more info button click 
	 * arrow right button click event
	 * @param learningCheck 
	 */
	public showInfoIceLearningCheck(IceLearningCheck: IceLearningCheck, questionId: string) {
		if (this.courseModified == true) {
			this.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_COURSE'));
		} else if (this.topicsModified == true) {
			this.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_TOPICS'));
		} else if (this.glossaryModified == true) {
			this.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_GLOSSARY'));
		} else if (this.gameModified == true) {
			this.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_GAMES'));
		} else {
			this.navigateToT = true;
			this.LearningCheckData = IceLearningCheck;
			//if(this.LearningCheckData.descriptionHTML == undefined){
			let el = this.elementRef.nativeElement.querySelector('.l_ck_editor' + IceLearningCheck.sequence + this.tabId);
			this.LearningCheckData.descriptionHTML = el.innerHTML;
			//}
			if (this.isViewOnly == true || this.learningChecksModified == false) {
				this.LearningCheckData.courseId = this.course.courseId;
				this.LearningCheckData.parentType = 'course';
				this.LearningCheckData.parent = this.course;
				if (questionId == 'null') {
					let data = {
						courseId: this.course.courseId,
						iceLearningCheck: this.LearningCheckData,
						viewOnly: this.isViewOnly,
						iceType: 'IceLearningCheck',
						quickSelectQuestionId: null
					}
					var html = this.course.courseNameHTML;
					var div = document.createElement("div");
					div.innerHTML = html;
					var text = div.textContent || div.innerText || div.style || "";
					let data2 = {
						courseId: this.course.courseId,
						course: this.course,
						viewOnly: this.isViewOnly,
						iceType: 'IceCourse',
						title: text,
						id: this.course.courseId,
					}
					this.sharedService.changeIceItemRequest('IceLearningCheck', data);
					this.sharedService.setICEBreadCrumb(this.course.courseId, data2);
				} else {
					let data = {
						courseId: this.course.courseId,
						iceLearningCheck: this.LearningCheckData,
						viewOnly: this.isViewOnly,
						iceType: 'IceLearningCheck',
						quickSelectQuestionId: questionId
					}
					var html = this.course.courseNameHTML;
					var div = document.createElement("div");
					div.innerHTML = html;
					var text = div.textContent || div.innerText || div.style || "";
					let data2 = {
						courseId: this.course.courseId,
						course: this.course,
						viewOnly: this.isViewOnly,
						iceType: 'IceCourse',
						title: text,
						id: this.course.courseId,
					}
					this.sharedService.changeIceItemRequest('IceLearningCheck', data);
					this.sharedService.setICEBreadCrumb(this.course.courseId, data2);
				}
				//do not save
			} else {
				//auto save LC
				this.learningChecksSave(false);
			}
			console.log('[showInfoIceLearningCheck] (learningCheck) ' + JSON.stringify(IceLearningCheck));
		}
	}

	// learning checks
	/**
	 * add button click event in learning check list
	 * add new learning check
	*/
	public addNewLearningCheck() {
		//show loading
		var loadingMessage = null;
		this.translate.get('ICE_LEARNING_CHECK.SAVING_MESSAGE').subscribe((res: string) => {
			loadingMessage = res;
		});
		this.loadingService.showLoading(true, null, loadingMessage, null);

		let self = this;
		if (self.isShowLearningChecks == true) {
			//do nothing
		} else {
			this.isShowLearningChecks = true;
			setTimeout(() => {
				self.setEditor("", CK_Editor_Type.LEARNING_CHECK_EDITOR);
			}, 200);
		}
		this.learningChecksModified = true;
		this.sharedService.setModifiedStatus(this.tabId, this.learningChecksModified)
		console.log('[addNewLearningCheck]');
		let learningChecklistLength: number = this.learningCheckList.length

		// Request
		this.newLearningCheck = {
			id: '0',
			courseId: this.course.courseId,
			description: "<ice><p>New Learning Check</p></ice>",
			sequence: (learningChecklistLength + 1),
			courseVersion: +this.course.courseVersion,
			isLearningCheckModified: true
		}
		this.courseService.createLearningCheck(JSON.stringify(this.newLearningCheck), UserVariable.getUserToken(), this);
		this.isShowLearningChecks = true;
		this.isShowIceTopic = false;
		this.isShowCourseDetail = false;
		this.isShowIceGames = false;
		this.isShowIceGlossary = false;
		//let newlc: IceLearningCheck = {
		//id: JSON.stringify(random), description: '', orderNumber: (listLength + 1)
		//}
		//this.learningCheckList.push(newlc)
	}

	/**
	 * save button click event in learning check list
	 * add save learning checks
	 */
	public learningChecksSave(showValidation) {
		//update learning check list
		this.updateLearningCheckDiscription();

		if (this.learningCheckList.length > 0) {
			var request = {
				"courseId": this.course.courseId,
				"courseVersion": this.course.courseVersion,
				"learningChecks": this.learningCheckList,
			};
			//show loading message
			var loadingMessage = null;
			this.translate.get('ICE_COURSE.SAVE_LEARNING_CHECKS_MESSAGE').subscribe((res: string) => {
				loadingMessage = res;
			});
			this.loadingService.showLoading(true, null, loadingMessage, null);

			this.courseService.saveLearningCheckList(JSON.stringify(request), UserVariable.getUserToken(), this);
			this.learningChecksModified = false;
			this.sharedService.setModifiedStatus(this.tabId, this.learningChecksModified)
		} else {
			if (showValidation == true) {
				this.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.NO_LEARNINGCHECK_ERROR'));
			} else {
				//do not show
			}
		}
		if (showValidation == true) {
			this.loadLearningChecklist = true;
		} else {
			//navigate
		}
	}

	updateLearningCheckDiscription() {
		for (let i = 1; i <= this.learningCheckList.length; i++) {
			if (this.learningCheckList[i - 1].isLearningCheckModified == true) {
				this.xmlContent = "";
				//check if edior text is empty
				var editortext = $('.l_ck_editor' + i + this.tabId).text();
				if (this.isNullOrWhitespace(editortext) == true) {
					this.learningCheckList[i - 1].description = '<ice></ice>';
				} else {
					//process name
					var name = this.getEditorContent('.l_ck_editor' + i + this.tabId); //get innerHTML of html element
					this.iceCKEditor.processXML(name, false, this.xmlContent);
					this.xmlContent = this.iceCKEditor.getXMLContent();
					this.learningCheckList[i - 1].description = '<ice>' + this.xmlContent + '</ice>';
				}
			}
		}
	}


	/**
	* edit learning checks 
	* 
	*/
	editLearningChecks() {
		let self = this;
		self.activeEditor = CK_Editor_Type.LEARNING_CHECK_EDITOR;
		if (self.courseModified == true) {
			self.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_COURSE'));
		} else if (self.topicsModified == true) {
			self.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_TOPICS'));
		} else if (self.glossaryModified == true) {
			self.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_GLOSSARY'));
		} else if (self.gameModified == true) {
			self.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_GAMES'));
		} else {
			self.learningCheckEnabled = true;
			self.courseEnabled = false;
			self.topicsEnabled = false;
			self.glossaryEnabled = false;
			self.gameEnabled = false;
			this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'course', false, 'courseEnabled');
			this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'course', false, 'topicsEnabled');
			this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'course', true, 'learningCheckEnabled');
			this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'course', false, 'glossaryEnabled');
			this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'course', false, 'gameEnabled');
			//destroy editors
			if (self.titleditor) {
				self.titleditor.destroy();
			} else {
				//no editor
			}
			if (self.desceditor) {
				self.desceditor.destroy();
			} else {
				//no editor
			}
			self.isShowLearningChecks = true;
			setTimeout(() => {
				self.setEditor("", CK_Editor_Type.LEARNING_CHECK_EDITOR);
			}, 200);
			self.isShowCourseDetail = false;
			self.isShowIceTopic = false;
			self.isShowIceGlossary = false;
			self.isShowIceGames = false
		}
	}

	showICELearningCheck(isShowLearningChecks) {
		let self = this;
		if (isShowLearningChecks == true) {
			if (self.learningChecksModified == true) {
				self.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_LEARNINGCHECKS'));
			} else {
				self.isShowLearningChecks = false;
			}
		} else {
			//remove unsaved LCs 
			console.log("self.iceLearningCheckList.length" + self.learningCheckList.length);
			self.learningCheckList = self.learningCheckList.filter(function (a) {
				return a.description != undefined;
			});
			self.isShowLearningChecks = true;
			setTimeout(() => {
				self.setEditor("", CK_Editor_Type.LEARNING_CHECK_EDITOR);
			}, 200);

		}
	}

	/**
	 * copy the topic content to particualr topic
	 * @param topic 
	 */
	public copyOfIceTopic(topic: IceTopic) {
		console.log('[copyOfIceTopic] (topic) ' + JSON.stringify(topic));
	}

	/**
	 * change the position of the learning check
	 * move up, move down button click event
	 * @param learningCheck 
	 * @param index 
	 * @param type 
	 */
	public changePositionLearningCheck(learningCheck: IceLearningCheck, index: number, dir: string) {
		this.isEnableReordering = false;
		this.learningCheckList.splice(index, 1); //remove , remove the lc from the list
		if (dir == 'up') {
			let iceLearningCheck: IceLearningCheck = this.learningCheckList[index - 1];
			this.learningCheckList.splice(index - 1, 0, learningCheck); //add lc in to list ,(index-1)
			if (learningCheck.id != "0") {
				var request = {
					"id": iceLearningCheck.id,
					"courseId": iceLearningCheck.courseId,
					"topicId": iceLearningCheck.topicId,
					"topicContentId": iceLearningCheck.topicContentId,
					"sequence": iceLearningCheck.sequence
				};
				this.learningCheckList[index - 1].sequence -= 1; // clicked one
				this.learningCheckList[index].sequence += 1;
				this.courseService.reorderICELearningChecks(JSON.stringify(request), UserVariable.getUserToken(), this);
			} else {
				// do nothing
			}
		} else if (dir == 'down') {
			this.learningCheckList.splice(index + 1, 0, learningCheck);
			if (learningCheck.id != "0") {
				var request = {
					"id": learningCheck.id,
					"courseId": learningCheck.courseId,
					"topicId": learningCheck.topicId,
					"topicContentId": learningCheck.topicContentId,
					"sequence": learningCheck.sequence
				};
				this.learningCheckList[index + 1].sequence += 1; // clicked one
				this.learningCheckList[index].sequence -= 1;
				this.courseService.reorderICELearningChecks(JSON.stringify(request), UserVariable.getUserToken(), this);
			} else {
				// do nothing
			}
		} else {
			// do nothing
		}
	}

	/**
	 * delete ice lc , view depencies
	 * @param IceLearningCheck 
	 * @param index
	 */
	public deleteIceLearningCheck(learningCheck: IceLearningCheck, index: number, template: TemplateRef<any>) {
		console.log('[deleteIceLearningCheck]');
		if (learningCheck.id != '0') {
			//show loading message
			var loadingMessage = null;
			this.translate.get('LOADING').subscribe((res: string) => {
				loadingMessage = res;
			});
			this.loadingService.showLoading(true, null, loadingMessage, null);
			this.courseService.viewDependenciesForLearningCheck(learningCheck.id, UserVariable.getUserToken(), this);
			/*this.sharedService.setFileId(learningCheck.id);
			this.sharedService.setFileType("ICELearningCheckData");
			this.sharedService.setAction("getDependencies");*/
			this.questionDependencies = '';
			this.isLCDeleteEnable = true;
			this.learningCheckToDelete = learningCheck;
			this.indexOfLearningCheckToDelete = index;
			//this.modalRef = this.modalService.show(DependenciesmodalComponent, this.modalConfig);
			this.modalRef = this.modalService.show(template, this.modalConfig);
		} else {
			this.deleteItemFromLearningCheckList(index); //no need 
		}
	}

	// remove learning check from the list
	deleteItemFromLearningCheckList(index: number) {
		this.learningCheckList.splice(index, 1);
		if (this.learningCheckList.length == 0) {
			this.isShowLearningChecks = false;
		}
		else {
			this.learningCheckList.forEach((learningCheckItem: IceLearningCheck) => {
				if (index < learningCheckItem.sequence) {
					learningCheckItem.sequence -= 1;
				}
			});
		}
	}

	//delete LC
	public confirmDeleteLearningChecks() {
		console.log('[Delete learningcheck() was called]');
		//show loading message
		var loadingMessage = null;
		this.translate.get('ICE_LEARNING_CHECK.LEARNING_CHECK_DELETING_MESSAGE').subscribe((res: string) => {
			loadingMessage = res;
		});
		var request = {
			"id": this.learningCheckToDelete.id,
			"courseId": this.learningCheckToDelete.courseId,
			"topicId": this.learningCheckToDelete.topicId,
			"topicContentId": this.learningCheckToDelete.topicContentId,
			"sequence": this.learningCheckToDelete.sequence
		}
		this.loadingService.showLoading(true, null, loadingMessage, null);
		console.log(JSON.stringify(this.learningCheckToDelete.id));
		this.courseService.deleteICELearningCheck(JSON.stringify(request), this.learningCheckToDelete.id, UserVariable.getUserToken(), this);
	}

	// copy learning check from a checked in course
	copyLearningCheck(template: TemplateRef<any>){
		if (this.learningChecksModified) {
			this.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_LEARNINGCHECKS'));
		} else {
			this.goBack();
			this.modalRef = this.modalService.show(template, this.modalConfig);
		}
	}

	// select checkedin course from list
	selectCourse(courseId: string){
		this.selectedConfirmed = false;
		if(this.selectedCMIceCourse == null){
			this.selectedCMIceCourse = courseId;
		} else{
			this.selectedCMIceCourse = courseId;
		}
	}

	// copy lc modal OK button click event
	confirmSelected(){
		if(this.iceCMCoursesAvailable == true){
			this.selectedConfirmed = true;
			this.iceCMCourseList.forEach((cmCourseItem: any) => {
				if(cmCourseItem.id == this.selectedCMIceCourse){
					this.getCMLearningCheckData = true;
					this.selectedCMIceCourseVersion = cmCourseItem.courseVersion;
				}
			});
			if(this.getCMLearningCheckData == true){
				var request = {
					"id": this.selectedCMIceCourse,
					"courseVersion": this.selectedCMIceCourseVersion
				}
				this.courseService.getCMLearningCheckList(JSON.stringify(request), UserVariable.getUserToken(), this);
			}
		} else if(this.cmLearningCheckListAvailable == true){
			this.iceCMLearningCheckList.forEach((cmLearningCheckItem: any)=> {
				if(cmLearningCheckItem.id == this.selectedCMLearningCheck){
					this.copyLearningCheckData = true;
				}
			});
			if(this.copyLearningCheckData == true){
				this.iceCMCourseList.forEach((courseItem: any)=>{
					if(courseItem.id == this.selectedCMIceCourse){
						this.selectedCourseVersion = courseItem.courseVersion
					}
				});

				var request2 = {
					"id": this.selectedCMLearningCheck,
					"courseId": this.selectedCMIceCourse,
					"courseVersion": this.selectedCourseVersion,
					"sequence": this.learningCheckList.length
				}
				this.courseService.copyLearningCheckByLearningcheckId(this.course.courseId, JSON.stringify(request2), UserVariable.getUserToken(), this);
				this.modalRef.hide();
			}
		}
	}

	goBack(){
		this.iceCMCoursesAvailable = true;
		this.cmLearningCheckListAvailable = false;
		this.isLearningCheckDesc = false;
		this.isLearningCheckList = true;
	}

	selectLearningCheck(learningCheckId: string){
		this.selectedCMLearningCheck = learningCheckId;
	}

	// copy learning check from a checked in course
	copyGame(template: TemplateRef<any>){

		if (this.gameModified == true) {
			this.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_GAMES'));
		} else {
			this.back();
			//Achini Randeni
			//Get latest CM courses to load into the copy game modal
			this.courseService.getLatestCMCourses( UserVariable.getUserToken(), this);
			this.modalRef = this.modalService.show(template, this.modalConfig);
		}
	}

	selectGame(gameId: string){
		this.selectedCMGame = gameId;
	}

	confirmSelectedItem(){
		if(this.iceCMCoursesAvailable == true){
			this.selectedConfirmed = true;
			this.iceCMCourseList.forEach((cmCourseItem: any) => {
				if(cmCourseItem.id == this.selectedCMIceCourse){
					this.getCMGameData = true;
					this.selectedCMIceCourseVersion = cmCourseItem.courseVersion;
				}
			});
			if(this.getCMGameData == true){
				var request = {
					"id": this.selectedCMIceCourse,
					"courseVersion": this.selectedCMIceCourseVersion
				}
				this.courseService.getCMGameList(JSON.stringify(request), UserVariable.getUserToken(), this);
			}
		} else if(this.cmGameListAvailable == true){
			this.iceCMGameList.forEach((cmGameItem: any)=> {
				if(cmGameItem.id == this.selectedCMGame){
					this.copyGameData = true;
				}
			});
			if(this.copyGameData == true){
				this.iceCMCourseList.forEach((courseItem: any)=>{
					if(courseItem.id == this.selectedCMIceCourse){
						this.selectedCourseVersion = courseItem.courseVersion
					}
				});
				var request2 = {
					"id": this.selectedCMGame,
					"courseId": this.selectedCMIceCourse,
					"courseVersion": this.selectedCourseVersion,
					//"sequence": this.iceGameList.length
				}
				this.courseService.copyGameByCMGameId(this.course.courseId, JSON.stringify(request2), UserVariable.getUserToken(), this);
				this.modalRef.hide();
			}
		}
	}

	back(){
		this.iceCMCoursesAvailable = true;
		this.cmGameListAvailable = false;
		this.isGameDesc = false;
		this.isGameList = true;
	}

	/**
	 * add button click event in ice game list
	 * add new game
	 * @param gameType 
	 */
	public addNewIceGame(gameType: string) {
		let self = this;
		if (self.isShowIceGames == true) {
			//do nothing
		} else {
			this.isShowIceGames = true;
			setTimeout(() => {
				self.setEditor("", CK_Editor_Type.GAME_EDITOR);
			}, 200);
		}
		//set game modified
		this.selectedGameType = gameType;
		this.gameModified = true;
		this.sharedService.setModifiedStatus(this.tabId, this.gameModified)
		let listLength: number = this.iceGameList.length;
		//show loading
		var loadingMessage = null;
		this.translate.get('ICE_GAME.SAVING_MESSAGE').subscribe((res: string) => {
			loadingMessage = res;
		});
		this.loadingService.showLoading(true, null, loadingMessage, null);
		let gameString = this.getStringFromType(gameType);
		//to get game name
		let gameNameString = this.getGameNameFromType(gameType);
		if (this.selectedGameType == 'MG') {
			gameString = "New Memory Game";
		}
		if (this.selectedGameType == 'SMSG') {
			gameString = "New Speed Match Game";
		}
		if (this.selectedGameType == 'EIG') {
			gameString = "New Einstein Game";
		}
		if (this.selectedGameType == 'WAMG') {
			gameString = "New Whack a Mole Game";
		}
		// Request
		this.newGame = {
			id: '0',
			courseId: this.course.courseId,
			gameName: gameNameString,
			description: "<ice><p>" + gameString + "</p></ice>",
			isGameModified: true,
			gameType: gameType,
			courseVersion: +this.course.courseVersion
		}
		this.courseService.createICEGame(JSON.stringify(this.newGame), UserVariable.getUserToken(), this);
		this.isShowCourseDetail = false;
		this.isShowLearningChecks = false;
		this.isShowIceGames = true;
		this.isShowIceTopic = false;
		this.isShowIceGlossary = false;
	}

	// On expand button click show ICE Game by Course Id
	showICEGames(isShowIceGames) {
		//get glossary details 
		let self = this;
		if (isShowIceGames == true) {
			if (self.gameModified == true) {
				self.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_GAMES'));
			} else {
				self.isShowIceGames = false;
			}
		} else {
			// Loading message
			var loadingMessage = null;
			this.translate.get('LOADING').subscribe((res: string) => {
				loadingMessage = res;
			});
			this.loadingService.showLoading(true, null, loadingMessage, null);
			this.courseService.getICEGameInfoByCourseId(this.data.id, UserVariable.getUserToken(), this);
			//remove unsaved glossary items 
			self.iceGameList = self.iceGameList.filter(function (a) {
				return a.description != undefined;
			});
			self.isShowIceGames = true;
			setTimeout(() => {
				self.setEditor("", CK_Editor_Type.GAME_EDITOR);
			}, 200);
		}
	}

	// Set Edit ICE Game UI
	editGame() {
		let self = this;
		self.activeEditor = CK_Editor_Type.GAME_EDITOR;
		if (self.courseModified == true) {
			self.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_COURSE'));
		} else if (self.learningChecksModified == true) {
			self.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_LEARNINGCHECKS'));
		} else if (self.topicsModified == true) {
			self.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_TOPICS'));
		} else if (self.glossaryModified == true) {
			self.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_GLOSSARY'));
		} else {
			self.topicsEnabled = false;
			self.courseEnabled = false;
			self.learningCheckEnabled = false;
			self.glossaryEnabled = false;
			self.gameEnabled = true
			this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'course', false, 'courseEnabled');
			this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'course', false, 'topicsEnabled');
			this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'course', false, 'learningCheckEnabled');
			this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'course', false, 'glossaryEnabled');
			this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'course', true, 'gameEnabled');

			if (self.isShowIceGames == false && this.iceGameList.length == 0) {
				var loadingMessage = null;
				this.translate.get('LOADING').subscribe((res: string) => {
					loadingMessage = res;
				});
				this.loadingService.showLoading(true, null, loadingMessage, null);
				this.courseService.getICEGameInfoByCourseId(this.data.id, UserVariable.getUserToken(), this);
			}
			//destroy editors
			if (self.titleditor) {
				self.titleditor.destroy();
			} else {
				//no editor
			}
			if (self.desceditor) {
				self.desceditor.destroy();
			} else {
				//no editor
			}
			self.isShowIceGames = true;
			setTimeout(() => {
				self.setEditor("", CK_Editor_Type.GAME_EDITOR);
			}, 200);
			// Handling visbility of divs
			self.isShowCourseDetail = false;
			self.isShowLearningChecks = false;
			self.isShowIceTopic = false;
			self.isShowIceGlossary = false;
		}
	}

	// Save ICE Games
	iceGameListSave(showValidation) {
		//update game model
		this.updateGameDescription();
		if (this.iceGameList.length > 0) {
			var request = {
				"courseId": this.course.courseId,
				"courseVersion": this.course.courseVersion,
				"iceGames": this.iceGameList
			};
			//show loading message
			var loadingMessage = null;
			this.translate.get('ICE_GAME.SAVE_GAME_MESSAGE').subscribe((res: string) => {
				loadingMessage = res;
			});
			this.loadingService.showLoading(true, null, loadingMessage, null);
			this.courseService.saveICEGameList(JSON.stringify(request), UserVariable.getUserToken(), this);
			this.gameModified = false;
			this.sharedService.setModifiedStatus(this.tabId, this.gameModified)
		} else {
			if (showValidation == true) {
				this.alertService.warn(this.translate.instant('ICE_GAME.NO_GAME_ERROR'));
			} else {
				//do not show
			}
		}
		if (showValidation == true) {
			this.loadGameList = true;
		} else {
			//navigate
		}
	}

	// Update ICE Glossary Model with modified values
	updateGameDescription() {
		for (let i = 1; i <= this.iceGameList.length; i++) {
			if (this.iceGameList[i - 1].isGameModified == true) {
				this.xmlContent = "";
				//check if edior text is empty
				var editortext = $('.games_ck_editor' + this.iceGameList[i - 1].id + this.tabId).text();
				if (this.isNullOrWhitespace(editortext) == true) {
					this.iceGameList[i - 1].description = '<ice></ice>';
				} else {
					//process name
					var name = this.getEditorContent('.games_ck_editor' + this.iceGameList[i - 1].id + this.tabId);
					this.iceCKEditor.processXML(name, false, this.xmlContent);
					this.xmlContent = this.iceCKEditor.getXMLContent();
					this.iceGameList[i - 1].description = '<ice>' + this.xmlContent + '</ice>';
				}
			} else {
				//game not modified 
			}
		}
	}

	// Delete ICE Game by Game Id
	public deleteIceGame(game: IceGame, index: number, template: TemplateRef<any>) {
		if (game.id != '0') {
			//show loading message
			var loadingMessage = null;
			this.translate.get('LOADING').subscribe((res: string) => {
				loadingMessage = res;
			});
			this.loadingService.showLoading(true, null, loadingMessage, null);
			this.isDeleteEnable = true;
			this.gameToDelete = game;
			this.indexOfGameToDelete = index;
			this.loadingService.hideLoading();
			this.modalRef = this.modalService.show(template, this.modalConfig);
		}
		else {
			this.deleteItemFromGameList(index);
		}
	}

	// Update iceGameList after delete ICE Game
	deleteItemFromGameList(index: number) {
		this.iceGameList.splice(index, 1);
		if (this.iceGameList.length == 0) {
			this.isShowIceGames = false;
		}
	}

	// Delete ICE Game after confirmation
	public confirmDeleteGame() {
		//show loading message
		var loadingMessage = null;
		this.translate.get('ICE_GAME.GAME_DELETING_MESSAGE').subscribe((res: string) => {
			loadingMessage = res;
		});
		var request = {
			"id": this.gameToDelete.id,
			"courseId": this.gameToDelete.courseId,
		}
		this.loadingService.showLoading(true, null, loadingMessage, null);
		this.courseService.deleteICEGame(JSON.stringify(request), this.gameToDelete.id, UserVariable.getUserToken(), this);
	}

	/**
	 * show more info button click 
	 * arrow right button click event
	//  * @param iceGame 
	 */
	public showInfoIceGame(iceGame: IceGame) {
		if (this.courseModified == true) {
			this.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_COURSE'));
		} else if (this.topicsModified == true) {
			this.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_TOPICS'));
		} else if (this.glossaryModified == true) {
			this.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_GLOSSARY'));
		} else if (this.learningChecksModified == true) {
			this.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_LEARNINGCHECKS'));
		} else {
			this.navigateToT = true;
			this.courseGameData = iceGame;
			let el = this.elementRef.nativeElement.querySelector('.games_ck_editor' + iceGame.id + this.tabId);
			this.courseGameData.descriptionHTML = el.innerHTML;
			if( this.gameModified == false || this.isViewOnly == true ) {
				this.courseGameData.courseId = this.course.courseId;
				let data = {
					courseId: this.course.courseId,
					iceGame: iceGame,
					viewOnly: this.isViewOnly,
					iceType: iceGame.gameType
				}
				var html = this.course.courseNameHTML;
				var div = document.createElement("div");
				div.innerHTML = html;
				var text = div.textContent || div.innerText || div.style || "";
				let data2 = {
					courseId: this.course.courseId,
					course: this.course,
					viewOnly: this.isViewOnly,
					iceType: 'IceCourse',
					title: text,
					id: this.course.courseId,
				}
				this.sharedService.setICEBreadCrumb(this.course.courseId, data2);
				switch (iceGame.gameType) {
					case 'EIG':
						this.sharedService.changeIceItemRequest('EinsteinGame', data)
						break;
					case 'SMSG':
						this.sharedService.changeIceItemRequest('SpeedMatchGame', data)
						break;
					case 'MG':
						this.sharedService.changeIceItemRequest('MemoryGame', data)
						break;
					default:
						break;
				}
				console.log('[showInfoIceGame] (iceGame) ' + JSON.stringify(iceGame));
			}else{
				this.iceGameListSave(false);
			}
		}
	}

	public getStringFromType(gameType: string): string {
		switch (gameType) {
			case 'EIG':
				return "Einstein (Instructor)"
			case 'SMSG':
				return "Speed Match (Student)"
			case 'MG':
				return "Memory (Instructor)"
			case 'WAMG':
				return "Whack a Mole (Instructor)"
			default:
				return ""
		}
	}

	//get game name by game type
	public getGameNameFromType(gameType: string): string {
		switch (gameType) {
			case 'EIG':
				return "Einstein"
			case 'SMSG':
				return "Speed Match"
			case 'MG':
				return "Memory"
			case 'WAMG':
				return "Whack A Mole"
			default:
				return ""
		}
	}


	/*private addDummyTopics(topicList: any) {
		let topic_1: IceTopic = {
			id: '0', 
			topicName: 'Sample ICE topic - 1',
			orderNumber: 0, copyTopicId: '0023'
		}
		topicList.push(topic_1)
		let topic_2: IceTopic = {
			id: '0', 
			topicName: 'Sample ICE topic - 2',
			orderNumber: 1, copyTopicId: ''
		}
		topicList.push(topic_2)
	}*/

	// private addDummyLCS(learningCheckList: any) {
	// 	let lc_1: IceLearningCheck = {
	// 		id: '001', description: 'Sample Learning Check - 1',
	// 		orderNumber: 1, questionLength: 37

	// 	}
	// 	learningCheckList.push(lc_1)
	// 	let lc_2: IceLearningCheck = {
	// 		id: '002', description: 'Sample Learning Check - 2',
	// 		orderNumber: 2, questionLength: 6
	// 	}
	// 	learningCheckList.push(lc_2)
	// }

	// private addDummyIceGames(iceGameList: any) {
	// 	let iceGame_1: IceGame = {
	// 		id: '001', description: 'Sample Game - 1', gameType: 'EIG'
	// 	}
	// 	iceGameList.push(iceGame_1)
	// 	let iceGame_2: IceGame = {
	// 		id: '002', description: 'Sample Game - 2', gameType: 'SMSG'
	// 	}
	// 	iceGameList.push(iceGame_2)
	// }


	onSuccess(data: WsResponse, serviceType: WsType) {
		if (serviceType == WsType.CREATE_ICE_COURSE) {
			this.data.isNewCourse = false;
			this.course.courseId = data.payload;	
            let token = UserVariable.getUserToken();
            if (!this.commonUtil.validateToken(token)) {
                return;
            }
			this.courseService.getICECourse(this.course.courseId, token, this);		
			this.showSuccessAlert('ICE_COURSE.COURSE_SAVED_ALERT');   
			console.log("=====Course is succesfully created=====");			
			// let Coursedata = {
			// 	id: this.course.courseId,
			// 	viewOnly: false
			// }
			//this.sharedService.setNewCourseIdRequest(Coursedata);
			//let selectedFolderData = this.folderService.getSelectedFoldeData();
			//this.sharedService.setNewICETabId(this.course.courseId)
		} else if (serviceType == WsType.UPDATE_ICE_COURSE) {
			this.showSuccessAlert('ICE_COURSE.COURSE_UPDATED_ALERT');
			console.log("=====Course is succesfully updated=====");
			this.courseService.getICECourse(this.course.courseId, UserVariable.getUserToken(), this);
		} else if (serviceType == WsType.GET_ICE_COURSE) {
			this.numberOfInitialReceivedPayloads++;
			let self = this;
			self.course = data.payload;
			if(self.course.courseImage == null) {
				this.courseImageChecked = false;
				this.imageBackground = "#ffffff";
				this.imageBorder = "solid #f0ba0a";
			} else {
				this.courseImageChecked = true;
				this.imageBackground = "#f0ba0a";
				this.imageBorder = "none";
			}
			if(self.course.courseIcon == null) {
				this.courseIconChecked = false;
				this.iconBackground = "#ffffff";
				this.iconBorder = "solid #f0ba0a";
			} else {
				this.iconBackground = "#f0ba0a";
				this.iconBorder = "none";
				this.courseIconChecked = true;
			}
			console.log(self.course);
			this.course.courseNameHTML = this.course.courseNameHTML.replace(/(<wintitle)([\s]+([^>]+)\/>)/g,
					"<wintitle contenteditable='true'$2</wintitle>");
			this.course.courseName = this.course.courseName.replace(/(<wintitle)([\s]+([^>]+)\/>)/g,
					"<wintitle contenteditable='true'$2</wintitle>");
			this.course.courseDescriptionHTML = this.course.courseDescriptionHTML.replace(/(<wintitle)([\s]+([^>]+)\/>)/g,
					"<wintitle contenteditable='true'$2</wintitle>");
			this.course.courseDescription = this.course.courseDescription.replace(/(<wintitle)([\s]+([^>]+)\/>)/g,
					"<wintitle contenteditable='true'$2</wintitle>");
			var html = this.course.courseNameHTML;
			var div = document.createElement("div");
			div.innerHTML = html;
			this.courseNameShowInDetails = div.textContent || div.innerText || div.style || "";
			this.glossaryCount = self.course.noOfGlossaryItems;
			this.gamesCount = self.course.noOfGames;
			
			//destroy active editors
			if (this.desceditor) {
				this.desceditor.destroy();
			} else {
				//no editor
			}
			if (this.titleditor) {
				this.titleditor.destroy();
			} else {
				//no editor
			}
			//console.log(this.course);
			setTimeout(() => {
				//set course description editor content
				let e1 = self.elementRef.nativeElement.querySelector('.ckeditor_course_description' + self.tabId);
				e1.innerHTML = self.course.courseDescription;
				e1.setAttribute("contenteditable", "false");
				//set course title editor content
				let e2 = self.elementRef.nativeElement.querySelector('.ckeditor_course_title' + self.tabId);
				e2.innerHTML = self.course.courseName;
				e2.setAttribute("contenteditable", "false");
				//added to hide the loading message after receiving all the three payloads of initial get methods
				if(this.numberOfInitialReceivedPayloads >= this.totalNumberOfInitialPayloads){
					this.loadingService.hideLoading();
				}
			}, 200);
			this.sharedService.setOpenedICEModules(SharedService.getTabId(), 'ICECourse', self.course);
			let commonVariables = this.sharedService.getCommonICEModuleVariableNames('course');
			var commonVariableValues: Array<any> = [];
			commonVariables.forEach(function (varName) {
				commonVariableValues[varName] = self[varName];
			}); 
			this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'course', commonVariableValues)
		} else if (serviceType == WsType.GET_ALL_ICE_TOPICS) {
			this.numberOfInitialReceivedPayloads++;
			this.loadtopiclist = false;
			this.loadTopicDetails(data.payload, false);
			// setTimeout(() => {
				//added to hide the loading message after receiving all the three payloads of initial get methods
				if(this.numberOfInitialReceivedPayloads >= this.totalNumberOfInitialPayloads){
					this.loadingService.hideLoading();
				}
			// }, 500);
		} else if (serviceType == WsType.DELETE_ICE_TOPIC) {
			this.deleteTopicProcess()
		} else if (serviceType == WsType.REORDER_ICE_TOPIC) {
			this.isEnableReordering = true;
			//	this.showSuccessAlert('ICE_TOPIC.TOPIC_REORDER_ALERT');    
			console.log("=====Topic are successfully reordered=====");
		} else if (serviceType == WsType.CHECK_ICE_TOPIC_DEPENDENCY) {
			this.topicDependencies = 0;
			this.topicContentDependencies = 0;
			this.topicDependencies = data.payload[0];
			this.topicContentDependencies = data.payload[1];
			if (this.topicDependencies != 0 || this.topicContentDependencies != 0) {
				this.isDeleteEnable = false;
			}
			else {
				// nothing to do here
			}
			this.loadingService.hideLoading();
		} else if (serviceType == WsType.GET_ICE_COURSE_TOPICS) {
			this.topicHash = data.payload
			//this.alertService.success(JSON.stringify(this.myhash))
			this.iceTopicListByCourseId = data.payload[0];
			this.iceTopicListByCourseId = this.iceTopicListByCourseId
			this.iceTopicAllListByCourseId = data.payload;
			this.loadingService.hideLoading();
		} else if (serviceType == WsType.GET_ICE_COURSE_TOPIC_CONTENTS) {
			this.topicContentHash = data.payload
		} else if (serviceType == WsType.FILE_VIEW) {
			console.log("=====Folder is succesfully refreshed=====");
		} else if (serviceType == WsType.SAVE_ICE_TOPICS) {
			//this.iceTopicList = [];
			if (this.loadtopiclist == true) {
				//get topic details 
				this.courseService.getAllICETopicsByCourseId(this.course.courseId, UserVariable.getUserToken(), this);
				//this.loadingService.hideLoading();
			} else {
				//do nothing
			}
			if (this.navigateToT == true) {
				this.topicData.courseId = this.course.courseId;
				this.topicData.parentType = 'course';
				this.topicData.parent = this.course;
				let data = {
					courseId: this.course.courseId,
					iceTopic: this.topicData,
					viewOnly: this.isViewOnly,
					iceType: 'topic'
				}

				var html = this.course.courseNameHTML;
				var div = document.createElement("div");
				div.innerHTML = html;
				var text = div.textContent || div.innerText || div.style || "";
				//	this.alertService.success(text)
				let data2 = {
					courseId: this.course.courseId,
					course: this.course,
					viewOnly: this.isViewOnly,
					iceType: 'IceCourse',
					title: text,
					id: this.course.courseId,
				}

				this.sharedService.changeIceItemRequest('topic', data);
				this.sharedService.setICEBreadCrumb(this.course.courseId, data2);
			} else {
				//do nothing
			}
		} else if (serviceType == WsType.SAVE_ICE_LEARNING_CHECKS) {
			this.learningCheckList = [];
			if (this.loadLearningChecklist == true) {
				//get lc details 
				this.courseService.getAllICELearningCheckByCourseId(this.course.courseId, UserVariable.getUserToken(), this); //get after saving
				//console.log("LC list is "+ this.learningCheckList);
			} else {
				//do nothing
			}
			if (this.navigateToT == true) {
				this.LearningCheckData.courseId = this.course.courseId;
				this.LearningCheckData.parentType = 'course';
				this.LearningCheckData.parent = this.course;
				let data = {
					courseId: this.course.courseId,
					iceLearningCheck: this.LearningCheckData,
					viewOnly: this.isViewOnly,
					iceType: 'learningcheck'
				}
				var html = this.course.courseNameHTML;
				var div = document.createElement("div");
				div.innerHTML = html;
				var text = div.textContent || div.innerText || div.style || "";
				//	this.alertService.success(text)
				let data2 = {
					courseId: this.course.courseId,
					course: this.course,
					viewOnly: this.isViewOnly,
					iceType: 'IceCourse',
					title: text,
					id: this.course.courseId,
				}

				this.sharedService.changeIceItemRequest('IceLearningCheck', data);
				this.sharedService.setICEBreadCrumb(this.course.courseId, data2);
				this.loadingService.hideLoading();
			} else {
				//do nothing
			}

		} else if (serviceType == WsType.CREATE_ICE_TOPIC) {
			this.newTopic.id = data.payload;
			this.newTopic.topicName = "New Topic Menu";
			this.iceTopicList.push(this.newTopic);

			setTimeout(() => {
				this.focusNewEditor('.t_ck_editor' + (this.newTopic.sequence) + this.tabId, CK_Editor_Type.TOPIC_EDITOR);
				this.loadingService.hideLoading();
			}, 200);
			this.showSuccessAlert('ICE_TOPIC.TOPIC_SAVED_ALERT');
			console.log("=====Topic is succesfully created=====");
		} else if (serviceType == WsType.CREATE_LEARNING_CHECK) {
			this.newLearningCheck.id = data.payload;
			this.newLearningCheck.description = "New Learning Check";
			this.learningCheckList.push(this.newLearningCheck);

			setTimeout(() => {
				this.focusNewEditor('.l_ck_editor' + (this.newLearningCheck.sequence) + this.tabId, CK_Editor_Type.LEARNING_CHECK_EDITOR);
				this.loadingService.hideLoading();
			}, 200);
			this.showSuccessAlert('ICE_LEARNING_CHECK.LEARNINGCHECK_SAVED_ALERT');
			console.log("=====learning check is succesfully created=====");
		} else if (serviceType == WsType.GET_ALL_ICE_LEARNINGCHECKS) {
			this.numberOfInitialReceivedPayloads++;
			this.loadLearningChecklist = false;
			this.loadLearningCheckDetails(data.payload);
			// setTimeout(() => {
				//added to hide the loading message after receiving all the three payloads of initial get methods
				if(this.numberOfInitialReceivedPayloads >= this.totalNumberOfInitialPayloads){
					this.loadingService.hideLoading();
				}
			// }, 500);
		} else if (serviceType == WsType.REORDER_ICE_LEARNING_CHECK) {
			this.isEnableReordering = true;
			console.log("=====Learning Checks are successfully reordered=====");
		} else if (serviceType == WsType.CHECK_ICE_LEARNINGCHECKS_DEPENDENCY) {
			if (data.payload == true) {
				this.questionDependencies = LearningCheckData_Details.LCDATA_AVAILABLE;
				this.isLCDeleteEnable = false;
			}
			else {
				// nothing to do here
			}
			this.loadingService.hideLoading();
		} else if (serviceType == WsType.DELETE_ICE_LEARNING_CHECK) {
			this.deleteItemFromLearningCheckList(this.indexOfLearningCheckToDelete);
			this.learningCheckToDelete = undefined;
			this.indexOfLearningCheckToDelete = undefined;
			this.showSuccessAlert('ICE_LEARNING_CHECK.LEARNING_CHECK_DELETED_ALERT');
			console.log("=====Learning Check is succesfully deleted=====");
			this.loadingService.hideLoading();
			if (this.learningCheckList.length == 0) {
				this.learningChecksModified = false;
			} else {
				//do nothing
			}
		} else if (serviceType == WsType.GET_ALL_ICE_GLOSSARY_LIST) {
			this.loadGlossary = false;
			this.loadGlossaryDetails(data.payload, false);
			this.loadingService.hideLoading();
		} else if (serviceType == WsType.CREATE_ICE_GLOSSARY) {
			this.newGlossaryItem.id = data.payload;
			this.newGlossaryItem.glossaryName = "New Glossary";
			this.newGlossaryItem.description = "Description of new Glossary";
			this.iceGlossaryList.push(this.newGlossaryItem);
			this.glossaryCount = this.iceGlossaryList.length;
			setTimeout(() => {
				this.focusNewEditor('.g_ck_editor_description' + (this.newGlossaryItem.id) + this.tabId, CK_Editor_Type.GLOSSARY_DESC_EDITOR);
			}, 200);
			setTimeout(() => {
				this.focusNewEditor('.g_ck_editor' + (this.newGlossaryItem.id) + this.tabId, CK_Editor_Type.GLOSSARY_EDITOR);
				this.loadingService.hideLoading();
			}, 200);
			this.showSuccessAlert('ICE_GLOSSARY.GLOSSARY_SAVED_ALERT');
			console.log("=====Glossary is succesfully created=====");
		} else if (serviceType == WsType.DELETE_ICE_GLOSSARY) {
			this.deleteItemFromGlossaryList(this.indexOfGlossaryToDelete);
			this.glossaryCount = this.iceGlossaryList.length;
			this.glossaryToDelete = undefined;
			this.indexOfGlossaryToDelete = undefined;
			this.showSuccessAlert('ICE_GLOSSARY.GLOSSARY_DELETED_ALERT');
			console.log("=====Glossary is succesfully deleted=====");
			this.loadingService.hideLoading();
			if (this.iceGlossaryList.length == 0) {
				this.glossaryModified = false;
			} else {
				//do nothing
			}
		} else if (serviceType == WsType.SAVE_ICE_GLOSSARY) {
			this.iceGlossaryList = [];
			if (this.loadGlossary == true) {
				this.courseService.getAllICEGlossaryListByCourseId(this.course.courseId, UserVariable.getUserToken(), this);
			} else {
				//do nothing
			}
			this.showSuccessAlert('ICE_GLOSSARY.GLOSSARY_UPDATED_ALERT');
			console.log("=====Glossary is successfully updated=====");
		} else if (serviceType == WsType.CREATE_ICE_GAME) {
			let gameString = this.getStringFromType(this.selectedGameType);
			//to get game name
			let gameNameString = this.getGameNameFromType(this.selectedGameType);
			if (this.selectedGameType == 'MG') {
				gameString = "New Memory Game";
			}
			if (this.selectedGameType == 'SMSG') {
				gameString = "New Speed Match Game";
			}
			if (this.selectedGameType == 'EIG') {
				gameString = "New Einstein Game";
			}
			if (this.selectedGameType == 'WAMG') {
				gameString = "New Whack a Mole Game";
			}
			this.newGame.id = data.payload;
			this.newGame.gameName = gameNameString;
			this.newGame.description = gameString;
			this.newGame.gameType = this.selectedGameType;
			this.iceGameList.push(this.newGame);
			this.gamesCount = this.iceGameList.length;
			setTimeout(() => {
				this.focusNewEditor('.games_ck_editor' + (this.newGame.id) + this.tabId, CK_Editor_Type.GAME_EDITOR);
			}, 200);
			this.showSuccessAlert('ICE_GAME.GAME_SAVED_ALERT');
			console.log("=====Game is succesfully created=====");
			this.loadingService.hideLoading();
		} else if (serviceType == WsType.GET_ALL_ICE_GAME_LIST) {
			this.loadGameList = false;
			this.loadGameListDetails(data.payload, false);
			this.loadingService.hideLoading();
			this.gamesCount = this.iceGameList.length;
		} else if (serviceType == WsType.SAVE_ICE_GAMES) {
			this.iceGameList = [];
			if (this.loadGameList == true) {
				this.courseService.getICEGameInfoByCourseId(this.course.courseId, UserVariable.getUserToken(), this);
				//this.loadingService.hideLoading();
			} else {
				//do nothing
			}
			if(this.navigateToT == true){
				this.courseGameData.courseId = this.course.courseId;
				let data = {
					courseId: this.course.courseId,
					iceGame: this.courseGameData,
					viewOnly: this.isViewOnly,
					iceType: this.courseGameData.gameType
				}
				var html = this.course.courseNameHTML;
				var div = document.createElement("div");
				div.innerHTML = html;
				var text = div.textContent || div.innerText || div.style || "";
				let data2 = {
					courseId: this.course.courseId,
					course: this.course,
					viewOnly: this.isViewOnly,
					iceType: 'IceCourse',
					title: text,
					id: this.course.courseId,
				}
				this.sharedService.setICEBreadCrumb(this.course.courseId, data2);
				switch (this.courseGameData.gameType) {
					case 'EIG':
						this.sharedService.changeIceItemRequest('EinsteinGame', data)
						break;
					case 'SMSG':
						this.sharedService.changeIceItemRequest('SpeedMatchGame', data)
						break;
					case 'MG':
						this.sharedService.changeIceItemRequest('MemoryGame', data)
						break;
					default:
						break;
				}
			}else{
				//do nothing
			}
			// this.showSuccessAlert('ICE_GAME.GAME_UPDATED_ALERT');
			// console.log("=====Games List is successfully updated=====");
		} else if (serviceType == WsType.DELETE_ICE_GAME) {
			this.deleteItemFromGameList(this.indexOfGameToDelete);
			this.gamesCount = this.iceGameList.length;
			this.gameToDelete = undefined;
			this.indexOfGameToDelete = undefined;
			this.showSuccessAlert('ICE_GAME.GAME_DELETED_ALERT');
			console.log("=====Game is succesfully deleted=====");
			this.loadingService.hideLoading();
			if (this.iceGameList.length == 0) {
				this.gameModified = false;
			} else {
				//do nothing
			}
		}  else if (serviceType == WsType.GET_ALL_CHECKEDIN_ICE_COURSES){
			this.iceCMCoursesAvailable = true;
			this.iceCMCourseList = data.payload;
			//remove file name empty items
			this.iceCMCourseList = this.iceCMCourseList.filter((item => item.fileName !== ""));
			this.iceCMCourseList.sort((a, b) => (a.fileName.toLowerCase() > b.fileName.toLowerCase()) ? 1 : -1);

		}  else if (serviceType == WsType.GET_CM_LEARNINGCHECK_LIST){
			this.iceCMCoursesAvailable = false;
			this.cmLearningCheckListAvailable = true;
			this.iceCMLearningCheckList = data.payload;
			this.getCMLearningCheckData = false;
			//this.selectedCMIceCourse = null;
			this.isLearningCheckDesc = true;
			this.isLearningCheckList = false;

		} else if(serviceType == WsType.COPY_CM_LEARNINGCHECK){
			this.learningCheckList = [];
			this.courseService.getAllICELearningCheckByCourseId(this.data.id, UserVariable.getUserToken(), this);
			this.selectedCMIceCourse = null;
			this.selectedCMLearningCheck = null;
			this.showSuccessAlert('ICE_LEARNING_CHECK.LEARNING_CHECK_COPY_ALERT');
		} else if( serviceType == WsType.GET_CM_GAME_LIST){
			this.iceCMCoursesAvailable = false;
			this.cmGameListAvailable = true;
			this.iceCMGameList = data.payload;
			this.getCMGameData = false;
			this.isGameDesc = true;
			this.isGameList = false;
			
		} else if(serviceType == WsType.COPY_CM_GAME){
			this.iceGameList = [];
			this.courseService.getICEGameInfoByCourseId(this.course.courseId, UserVariable.getUserToken(), this);
			this.selectedCMIceCourse = null;
			this.selectedCMGame = null;
			this.showSuccessAlert('ICE_GAME.GAME_COPY_ALERT');

		}  else if (serviceType == WsType.GET_SELECTED_IMAGE_DATA) {
            this.selectedImage = JSON.parse(data.payload);
            this.loadingService.hideLoading();
            this.modalRef = this.modalService.show(this.selectedModalTemplate, this.modalConfig);
        } else{
		// nothing to do here
	}
	}

	onFail(data: WsResponse, serviceType: WsType) {
		if (serviceType == WsType.CREATE_ICE_COURSE) {
			console.log("=====Failed to create course=====");
			if (data.statusCode == "543") {
				console.log("=====Failed to create course=====" + JSON.stringify(data.payload));
				this.loadConflictData(data.payload)
				this.openModalCC(this.conflict_checker);
				this.loadingService.hideLoading();
			} else {
				this.alertService.error(data.statusDescription, false);
			}
		} else if (serviceType == WsType.UPDATE_ICE_COURSE) {
			if (data.statusCode == "543") {
				console.log("=====Failed to create course=====" + JSON.stringify(data.payload));
				this.loadConflictData(data.payload)
				this.openModalCC(this.conflict_checker);
				this.loadingService.hideLoading();
			} else {
				this.alertService.error(data.statusDescription, false);
			}
		} else if (serviceType == WsType.GET_ICE_COURSE) {
			this.alertService.error(data.statusDescription, false);
			console.log("=====Failed to get course details=====");
		} else if (serviceType == WsType.GET_ALL_ICE_TOPICS) {
			this.alertService.error(data.statusDescription, false);
			console.log("=====Failed to get topic list details=====");
		} else if (serviceType == WsType.DELETE_ICE_TOPIC) {
			this.alertService.error(data.statusDescription, false);
			console.log("=====Failed to delete topic=====");
		} else if (serviceType == WsType.REORDER_ICE_TOPIC) {
			this.alertService.error(data.statusDescription, false);
			console.log("=====Failed to reorder topics=====");
		} else if (serviceType == WsType.CHECK_ICE_TOPIC_DEPENDENCY) {
			this.alertService.error(data.statusDescription, false);
			console.log("=====Failed to check dependencies for topics=====");
		} else if (serviceType == WsType.SAVE_ICE_TOPICS) {
			console.log("=====Failed to save topics=====");
			if (data.statusCode == "543") {
				console.log("=====Failed to create course=====" + JSON.stringify(data.payload));
				this.loadConflictData(data.payload)
				this.openModalCC(this.conflict_checker);
				this.loadingService.hideLoading();
			} else {
				this.alertService.error(data.statusDescription, false);
			}
		} else if (serviceType == WsType.CREATE_ICE_TOPIC) {
			this.alertService.error(data.statusDescription, false);
			console.log("=====Failed to create topic=====");
		} else if (serviceType == WsType.CREATE_LEARNING_CHECK) {
			this.alertService.error(data.statusDescription, false);
			console.log("=====Failed to create learning check=====");
		} else if (serviceType == WsType.SAVE_ICE_LEARNING_CHECKS) {
			this.alertService.error(data.statusDescription, false);
			console.log("=====Failed to save learning check=====");
		} else if (serviceType == WsType.GET_ALL_ICE_LEARNINGCHECKS) {
			this.alertService.error(data.statusDescription, false);
			console.log("=====Failed to get learning check=====");
		} else if (serviceType == WsType.REORDER_ICE_LEARNING_CHECK) {
			this.alertService.error(data.statusDescription, false);
			console.log("=====Failed to reorder learning checks=====");
		} else if (serviceType == WsType.CHECK_ICE_LEARNINGCHECKS_DEPENDENCY) {
			this.alertService.error(data.statusDescription, false);
			console.log("=====Failed to check dependencies learning checks=====");
		} else if (serviceType == WsType.DELETE_ICE_LEARNING_CHECK) {
			this.alertService.error(data.statusDescription, false);
			console.log("=====Failed to delete learning checks=====");
		} else if (serviceType == WsType.GET_ALL_ICE_GLOSSARY_LIST) {
			this.alertService.error(data.statusDescription, false);
			console.log("=====Failed to get glossary list=====");
		} else if (serviceType == WsType.CREATE_ICE_GLOSSARY) {
			this.alertService.error(data.statusDescription, false);
			console.log("=====Failed to add new glossary=====");
		} else if (serviceType == WsType.DELETE_ICE_GLOSSARY) {
			this.alertService.error(data.statusDescription, false);
			console.log("=====Failed to delete glossary=====");
		} else if (serviceType == WsType.SAVE_ICE_GLOSSARY) {
			this.alertService.error(data.statusDescription, false);
			console.log("=====Failed to save glossary=====");
		} else if (serviceType == WsType.CREATE_ICE_GAME) {
			this.alertService.error(data.statusDescription, false);
			console.log("=====Failed to save game=====");
		} else if (serviceType == WsType.GET_ALL_ICE_GAME_LIST) {
			this.alertService.error(data.statusDescription, false);
			console.log("=====Failed to get games list=====");
		} else if (serviceType == WsType.SAVE_ICE_GAMES) {
			this.alertService.error(data.statusDescription, false);
			console.log("=====Failed to save games=====");
		} else if (serviceType == WsType.DELETE_ICE_GAME) {
			this.alertService.error(data.statusDescription, false);
			console.log("=====Failed to delete game=====");
		} else if (serviceType == WsType.GET_SELECTED_IMAGE_DATA) {
            // do nothing
        }
		this.loadingService.hideLoading();
	}

	// showTableData(payload: any) {
	// 	if (payload != null) {
	// 		this.rowData = [];
	// 		for (let i = 0; i < payload.length; i++) {
	// 			this.rowData.push({
	// 				id: payload[i]["id"],
	// 				type: payload[i]["type"] + ":" + payload[i]["id"] + "," + payload[i]["status"],
	// 				fileName: "tooltip:" + payload[i]["name"],
	// 				checkoutBy: payload[i]["checkoutBy"] != null ? payload[i]["checkoutBy"] : "",
	// 				lastUpdateTimeStamp: new Date(payload[i]["lastUpdateTimeStamp"]).toLocaleString() != "Invalid Date" ? new Date(payload[i]["lastUpdateTimeStamp"]).toLocaleString() : payload[i]["lastUpdateTimeStamp"],
	// 				folderPath:this.getFolderPath(payload[i]["folderPath"])
	// 			});
	// 		}
	// 	}
	// }

	// getFolderPath(folderPath) {
	// 	var firstPart = "";
	// 	if (folderPath) {
	// 		var folderPathContent = folderPath.split('/');
	// 		firstPart = folderPathContent[0] + "/" + folderPathContent[1] + "/";
	// 		folderPath = folderPath.replace(firstPart,"");
	// 	} else {
	// 		//no folder path
	// 	}

	// 	return folderPath;
	// }

	deleteTopicProcess() {
		this.updateTopicNameAfterDelete();
		this.indexOfTopicToDelete = this.sharedService.getDeleteTopicIndex();
		this.deleteItemFromTopicList(this.indexOfTopicToDelete);
		this.showSuccessAlert('ICE_TOPIC.TOPIC_DELETED_ALERT');
		console.log("=====Topic is succesfully deleted=====");
		//remove addedtopics and reload
		var topiclist = this.iceTopicList;
		this.iceTopicList = [];
		this.loadTopicDetails(topiclist, true);

		//this.iceTopicList
		
		// this.courseService.getICECourse(this.courseId, UserVariable.getUserToken(), this);
		// this.courseService.getAllICETopicsByCourseId(this.courseId, UserVariable.getUserToken(), this);
		// this.loadingService.hideLoading();
		if (this.iceTopicList.length == 0) {
			this.topicsModified = false;
			this.sharedService.setModifiedStatus(this.tabId, this.topicsModified )
		} else {
			//do nothing
		}
	}

	updateSequence(topiclist) {
		for (let i = 0; i < topiclist.length; i++) {
			topiclist[i].sequence = i + 1;
		}
	}

	/**
	 * load topic list details
	 * @param payload 
	 */
	loadTopicDetails(payload: WsResponse['payload'], afterdelete) {
		this.iceTopicModel = null;
		this.iceTopicList = [];
		if (payload.length != 0) {
			for (let i = 0; i < payload.length; i++) {
				this.iceTopicModel = payload[i];
				this.iceTopicModel.sequence = i + 1;
				this.iceTopicModel.topicName = this.iceTopicModel.topicName.replace(/(<wintitle)([\s]+([^>]+)\/>)/g,
						"<wintitle contenteditable='true'$2</wintitle>");
				this.iceTopicModel.topicNameHTML = this.iceTopicModel.topicNameHTML.replace(/(<wintitle)([\s]+([^>]+)\/>)/g,
						"<wintitle contenteditable='true'$2</wintitle>");
				if (afterdelete == false) {
					this.iceTopicModel.isTopicModified = false;
				} else {
					//do nothing
				}

				this.iceTopicList.push(this.iceTopicModel);
			}
			this.sharedService.setTopicList(this.iceTopicList);
		} else {
			// no row data to show
		}
		setTimeout(() => {
			this.setEditor("", CK_Editor_Type.TOPIC_EDITOR);
		}, 200);
	}

	loadLearningCheckDetails(payload: WsResponse['payload']) {
		this.iceLearningCheckModel = null;
		if (payload.length != 0) {
			for (let i = 0; i < payload.length; i++) {
				this.iceLearningCheckModel = payload[i];
				this.iceLearningCheckModel.isLearningCheckModified = false;
				this.learningCheckList.push(this.iceLearningCheckModel);
			}
		} else {
			// no row data to show
		}

		setTimeout(() => {
			this.setEditor("", CK_Editor_Type.LEARNING_CHECK_EDITOR);
		}, 200);
	}

	setEditor(className, CK_Editor_Variable) {
		var self = this;
		$('.toggle-edit' + SharedService.getTabId() + this.userId).on('copy', function() {
			if ($(this)[0].attributes['contenteditable'].nodeValue == "false") {
				localStorage.setItem('disabledEditor', "true"); 
			}
		});	
		//set editor content
		self.setEditorContent(className, CK_Editor_Variable);
		if (self.isViewOnly == true) {
			//view only
		} else {
			$('.toggle-edit' + SharedService.getTabId() + self.userId).dblclick(function (e) {
				if (self.topicsEnabled == true || self.learningCheckEnabled == true || self.glossaryEnabled == true || self.gameEnabled == true) {
					if (self.activeEditor == 'topics_ck_editor' && CK_Editor_Variable == 'topics_ck_editor') {
						self.topicsModified = true;
						self.sharedService.setModifiedStatus(self.tabId, self.topicsModified);

						let topicId = $(this)[0].getAttribute("id"); //get the id of topic which is double clicked
						let topic = self.iceTopicList.find(obj => obj.id == topicId); //find that topic from topic list using topic Id
						//set topic modified true
						topic.isTopicModified = true; // set isTopicModified true in that topic

					} else if (self.activeEditor == 'learning_checks_ck_editor' && CK_Editor_Variable == 'learning_checks_ck_editor') {
						self.learningChecksModified = true;
						self.sharedService.setModifiedStatus(self.tabId, self.learningChecksModified)

						let learningCheckId = $(this)[0].getAttribute("id");
						let learningCheck = self.learningCheckList.find(obj => obj.id == learningCheckId);
						//set LC true
						learningCheck.isLearningCheckModified = true;
					} else if (self.activeEditor == 'glossary_ck_editor' && (CK_Editor_Variable == 'glossary_ck_editor' || CK_Editor_Variable == 'glossary_desc_ck_editor')) {
						self.glossaryModified = true;
						self.sharedService.setModifiedStatus(self.tabId, self.glossaryModified)
						let glossaryIdFull = $(this)[0].getAttribute("id");
						let glossaryNameSplitAry = glossaryIdFull.split('gdescription_');
						if (glossaryNameSplitAry.length > 1) {
							var glossaryId = glossaryNameSplitAry[1];
						} else {
							var glossaryId = glossaryNameSplitAry[0];
						}
						let glossary = self.iceGlossaryList.find(obj => obj.id == glossaryId);
						glossary.isGlossaryModified = true;
					} else if (self.activeEditor == 'games_ck_editor' && CK_Editor_Variable == 'games_ck_editor') {
						self.gameModified = true;
						self.sharedService.setModifiedStatus(self.tabId, self.gameModified);
						let gameId = $(this)[0].getAttribute("id");
						let iceGameDetail = self.iceGameList.find(obj => obj.id == gameId);
						iceGameDetail.isGameModified = true;
					}
					if (e.target.nodeName != "PH" || (editor == null) || (editor == undefined)) {
						if ($(this)[0].attributes['contenteditable'].nodeValue == "false") {
							for (let i in self.editorList) {
								if (CKEDITOR.instances[self.editorList[i].name] != undefined) {
									CKEDITOR.instances[self.editorList[i].name].destroy();
								}
							}
							for (let j in self.editorDivList) {
								if (self.editorDivList[j].attributes['contenteditable'].nodeValue == "true") {
									self.editorDivList[j].setAttribute("contenteditable", "false");									
									if ($(self.editorDivList[j])[0].innerHTML == '') {
										$(self.editorDivList[j])[0].innerHTML = '<br>';
									}
								}
							}
							$(this)[0].setAttribute("contenteditable", "true");
							editor = CKEDITOR.inline($(this)[0], self.inlineConfig_title);
							self.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'course', editor, 'editor')
							self.editorList.push(editor);
							self.editorDivList.push($(this)[0]);
						} 
					} else {
						if ($(this)[0].attributes['contenteditable'].nodeValue == "true") {
							SharedService.setselectedNode(e.target);
							let item = e.target.attributes['restriction'].nodeValue;
							let catId = e.target.attributes['catId']
							if (e.target.attributes['catId'] != undefined && e.target.attributes['catId'] != null) {
								catId = e.target.attributes['catId'].nodeValue;
							}                       
							self.iceCKEditor.getRestrictionValueOndblClick(item,catId);	
						}	
						//self.editorDivList.push($(this)[0]);
					}
					editor.on('instanceReady', function (evt) {
						var range = editor.createRange();
						if (range != null) {
							range.moveToElementEditEnd(range.root);
							editor.getSelection().selectRanges([range]);
						}
					});

					editor.on('key', function (e) {
						self.iceCKEditor.editorKeyDown(e, editor);
					});

					editor.on('paste', function (evt) {
						evt.stop();
						var data: string = evt.data.dataValue;
						let pasteData: string = self.iceCKEditor.processPasteData(data);
						if(self.iceCKEditor.gethasResuables()) {
							self.alertService.clear();
							self.alertService.toast(self.translate.instant('ICE_COURSE.DISABLED_EDITOR_COPY'));
						}
						evt.editor.insertHtml(pasteData);
					});

					editor.on('focus', function (evt) {
						if(navigator.userAgent.toLowerCase().indexOf("firefox") > -1){
							let wintitles = editor.container.getElementsByTag("wintitle");
							for(let i=0; i<wintitles.$.length; i++){
								wintitles.$[i].removeAttribute("contenteditable");
							}
						}
					});
					self.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'course', editor, 'editor')
				} else {
					//do nothing
				}
			});
		}
	}

	setEditorContent(className, CK_Editor_Variable) {
		if (CK_Editor_Variable == 'topics_ck_editor') {
			if (className) {
				let el = this.elementRef.nativeElement.querySelector(className); //get ui element from class name
				if (el != null) {
					let name = this.iceTopicList[this.newTopic.sequence - 1].topicName;
					if (name != undefined) {
						el.innerHTML = name;
					} else {
						//el.innerHTML = "<p>&nbsp;</p>";
					}
				} else {
					//no element
				}
			} else {
				for (let i = 1; i <= this.iceTopicList.length; i++) {
					let el = this.elementRef.nativeElement.querySelector('.t_ck_editor' + i + this.tabId);
					if (el != null) {
						let name = this.iceTopicList[i - 1].topicName;
						if (name != undefined) {
							el.innerHTML = name;
						} else {
							//el.innerHTML = "<p>&nbsp;</p>";
						}
					} else {
						//no element
					}

					if (this.isViewOnly == true || this.topicsEnabled == false) {
						if (el != null) {
							el.setAttribute("contenteditable", "false");
							el.style.backgroundColor = "#e9ecef";
						} else {
							//no element
						}
					} else {
						if (el != null) {
							el.style.backgroundColor = "";
						} else {
							//no element
						}
					}
				}
			}
		} else if (CK_Editor_Variable == 'learning_checks_ck_editor') {
			if (className) {
				let el = this.elementRef.nativeElement.querySelector(className);
				if (el != null) {
					let name = this.learningCheckList[this.newLearningCheck.sequence - 1].description;
					if (name != undefined) {
						el.innerHTML = name;
					} else {
						//el.innerHTML = "<p>&nbsp;</p>";
					}
				} else {
					//no element
				}
			} else {
				for (let i = 1; i <= this.learningCheckList.length; i++) {
					let e2 = this.elementRef.nativeElement.querySelector('.l_ck_editor' + i + this.tabId);
					if (e2 != null) {
						let name = this.learningCheckList[i - 1].description;

						if (name != undefined) {
							e2.innerHTML = name;
						} else {
							//el.innerHTML = "<p>&nbsp;</p>";
						}
					} else {
						//no element
					}

					if (this.isViewOnly == true || this.learningCheckEnabled == false) {
						if (e2 != null) {
							e2.setAttribute("contenteditable", "false");
							e2.style.backgroundColor = "#e9ecef";
						} else {
							//no element
						}
					} else {
						if (e2 != null) {
							e2.style.backgroundColor = "";
						} else {
							//no element
						}
					}
				}
			}
		} else if (CK_Editor_Variable == 'glossary_ck_editor') {
			if (className) {
				let el = this.elementRef.nativeElement.querySelector(className);
				if (el != null) {
					let glossary = this.iceGlossaryList.find(obj => obj.id == this.newGlossaryItem.id);
					let name = glossary.glossaryName;
					if (name != undefined) {
						el.innerHTML = name;
					} else {
						// do nothing
					}
				} else {
					//no element
				}
			} else {
				for (let i = 1; i <= this.iceGlossaryList.length; i++) {
					let e2 = this.elementRef.nativeElement.querySelector('.g_ck_editor' + this.iceGlossaryList[i - 1].id + this.tabId);
					if (e2 != null) {
						let name = this.iceGlossaryList[i - 1].glossaryName;
						if (name != undefined) {
							e2.innerHTML = name;
						} else {
							// do nothing
						}
					} else {
						//no element
					}
					if (this.isViewOnly == true || this.glossaryEnabled == false) {
						if (e2 != null) {
							e2.setAttribute("contenteditable", "false");
							e2.style.backgroundColor = "#e9ecef";
						} else {
							//no element
						}
					} else {
						if (e2 != null) {
							e2.style.backgroundColor = "";
						} else {
							//no element
						}
					}
				}
			}
		} else if (CK_Editor_Variable == 'glossary_desc_ck_editor') {
			if (className) {
				let el = this.elementRef.nativeElement.querySelector(className);
				if (el != null) {
					let glossary = this.iceGlossaryList.find(obj => obj.id == this.newGlossaryItem.id);
					let desc = glossary.description;
					if (desc != undefined) {
						el.innerHTML = desc;
					} else {
						// do nothing
					}
				} else {
					//no element
				}
			} else {
				for (let i = 1; i <= this.iceGlossaryList.length; i++) {
					let e2 = this.elementRef.nativeElement.querySelector('.g_ck_editor_description' + this.iceGlossaryList[i - 1].id + this.tabId);
					if (e2 != null) {
						let desc = this.iceGlossaryList[i - 1].description;
						if (desc != undefined) {
							e2.innerHTML = desc;
						} else {
							// do nothing
						}
					} else {
						//no element
					}
					if (this.isViewOnly == true || this.glossaryEnabled == false) {
						if (e2 != null) {
							e2.setAttribute("contenteditable", "false");
							e2.style.backgroundColor = "#e9ecef";
						} else {
							//no element
						}
					} else {
						if (e2 != null) {
							e2.style.backgroundColor = "";
						} else {
							//no element
						}
					}
				}
			}
		} else if (CK_Editor_Variable == 'games_ck_editor') {
			if (className) {
				let el = this.elementRef.nativeElement.querySelector(className);
				if (el != null) {
					let game = this.iceGameList.find(obj => obj.id == this.newGame.id);
					let desc = game.description;
					if (desc != undefined) {
						el.innerHTML = desc;
					} else {
						// do nothing
					}
				} else {
					//no element
				}
			} else {
				for (let i = 1; i <= this.iceGameList.length; i++) {
					let e2 = this.elementRef.nativeElement.querySelector('.games_ck_editor' + this.iceGameList[i - 1].id + this.tabId);
					if (e2 != null) {
						let desc = this.iceGameList[i - 1].description;
						if (desc != undefined) {
							e2.innerHTML = desc;
						} else {
							// do nothing
						}
					} else {
						//no element
					}
					if (this.isViewOnly == true || this.gameEnabled == false) {
						if (e2 != null) {
							e2.setAttribute("contenteditable", "false");
							e2.style.backgroundColor = "#e9ecef";
						} else {
							//no element
						}
					} else {
						if (e2 != null) {
							e2.style.backgroundColor = "";
						} else {
							//no element
						}
					}
				}
			}
		}
	}

	focusNewEditor(className: string, CK_Editor_Variable) {//,type: CK_EDITOR_TYPE
		let self = this;
		//set editor content
		this.setEditor(className, CK_Editor_Variable);
		let node = $(".toggle-edit" + this.tabId + this.userId + className);
		for (let i in this.editorList) {
			if (CKEDITOR.instances[this.editorList[i].name] != undefined) {
				CKEDITOR.instances[this.editorList[i].name].destroy();
			}
		}
		for (let j in self.editorDivList) {
			if (self.editorDivList[j].attributes['contenteditable'].nodeValue == "true") {
				self.editorDivList[j].setAttribute("contenteditable", "false");									
				if ($(self.editorDivList[j])[0].innerHTML == '') {
					$(self.editorDivList[j])[0].innerHTML = '<br>';
				}
			}
		}
		$(node)[0].setAttribute("contenteditable", "true");
		editor = CKEDITOR.inline($(node)[0], this.inlineConfig_title);
		this.editorList.push(editor);
		this.editorDivList.push($(node)[0]);

		editor.on('instanceReady', function (evt) {
			var range = editor.createRange();
			if (range != null) {
				range.moveToElementEditEnd(range.root);
				editor.getSelection().selectRanges([range]);
			}
		});

		editor.on('key', function (e) {
			self.iceCKEditor.editorKeyDown(e, editor);
		});

		if ($(node)[0].attributes['contenteditable'].nodeValue == "true") {
			$(document).on('dblclick','ph', function(){
				let item = $(node).attr('restriction');
				let catId = $(node).attr('catId');	                        
				self.iceCKEditor.getRestrictionValueOndblClick(item,catId);
			});
		}

		editor.on('paste', function (evt) {
			evt.stop();
			var data: string = evt.data.dataValue;
			let pasteData: string = self.iceCKEditor.processPasteData(data);
			if(self.iceCKEditor.gethasResuables()) {
				self.alertService.clear();
				self.alertService.toast(self.translate.instant('ICE_COURSE.DISABLED_EDITOR_COPY'));
			}
			evt.editor.insertHtml(pasteData);
		});

		editor.on('focus', function (evt) {
			if(navigator.userAgent.toLowerCase().indexOf("firefox") > -1){
				let wintitles = editor.container.getElementsByTag("wintitle");
				for(let i=0; i<wintitles.$.length; i++){
					wintitles.$[i].removeAttribute("contenteditable");
				}
			}
		});
		this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'course', editor, 'editor')
	}

	deleteItemFromTopicList(index: number) {
		this.iceTopicList.splice(index, 1);
		if (this.iceTopicList.length == 0) {
			this.isShowIceTopic = false;
		}
		else {
			// nothing to do here
		}
	}


	addSpeChar(value: string) {
		if (value != "") {
			let decVal = Number(value);
			this.speCharList.push({ data: { symbol: this.getSymbolFromDecimal(decVal), decVal: decVal, type: "speChar" } });
		}
	}

	numberOnly(event): boolean {
		const charCode = (event.which) ? event.which : event.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}

	addFraction(num: string, den: string) {
		this.modalRef.hide()
		if (den != "" && num != "") {
			let denominator = parseInt(den);
			if (denominator != 0) {
				const frac = {
					numerator: num,
					denominator: den,
					type: 'fraction'
				};
				const fracStr = JSON.stringify(frac);
				let event = JSON.parse(fracStr);
				const fracObj = {
					data: event
				};
				const fracObjStr = JSON.stringify(fracObj);
				let data = JSON.parse(fracObjStr);
				this.iceCKEditor.insertTagEditArea(data, this.elementRef, editor, CKEDITOR, this.course, "ICECourse")
			} else {
				this.alertService.clear();
				this.alertService.error(this.translate.instant('WEB_EDITOR.DENOMINATOR_ZERO'));
			}
		} else {
			this.alertService.clear();
			this.alertService.error(this.translate.instant('WEB_EDITOR.NUMERATOR_AND_DENOMINATOR_EMPTY'));
		}
	}

	addSpecialCharacter(option: any) {
		this.modalRef.hide()
		if (option != "") {
			this.iceCKEditor.insertTagEditArea(option, this.elementRef, editor, CKEDITOR, this.course, "ICECourse")
		}
	}

	showSuccessAlert(en: string) {
		var alert = null;
		this.translate.get(en).subscribe((res: string) => {
			alert = res;
		});
		this.alertService.success(alert);
	}

	ngOnDestroy() {
		if (this.desceditor) {
			this.desceditor.destroy();
		} else {
			//no editor
		}
		if (this.titleditor) {
			this.titleditor.destroy();
		} else {
			//no editor
		}
		for (let i in this.editorList) {
			if (CKEDITOR.instances[this.editorList[i].name] != undefined) {
				CKEDITOR.instances[this.editorList[i].name].destroy();
			}
		}

		if (this.deleteTopicSubscription) {
			this.deleteTopicSubscription.unsubscribe()
		  }
	}

	// On expand button click show ICE GLossary by Course Id
	showICEGlossary(isShowIceGlossary) {
		//get glossary details 
		let self = this;
		if (isShowIceGlossary == true) {
			if (self.glossaryModified == true) {
				self.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_GLOSSARY'));
			} else {
				self.isShowIceGlossary = false;
			}
		} else {
			// Loading message
			var loadingMessage = null;
			this.translate.get('LOADING').subscribe((res: string) => {
				loadingMessage = res;
			});
			this.loadingService.showLoading(true, null, loadingMessage, null);
			this.courseService.getAllICEGlossaryListByCourseId(this.data.id, UserVariable.getUserToken(), this);
			//remove unsaved glossary items 
			self.iceGlossaryList = self.iceGlossaryList.filter(function (a) {
				return a.glossaryName != undefined;
			});
			self.isShowIceGlossary = true;
			setTimeout(() => {
				self.setEditor("", CK_Editor_Type.GLOSSARY_EDITOR);
			}, 200);
			setTimeout(() => {
				self.setEditor("", CK_Editor_Type.GLOSSARY_DESC_EDITOR);
			}, 200);
		}
	}

	// Load ICE Glossary to iceGlossaryList
	loadGlossaryDetails(payload: WsResponse['payload'], afterdelete) {
		this.iceGlossaryList = [];
		this.iceGlossaryModel = null;
		if (payload.length != 0) {
			for (let i = 0; i < payload.length; i++) {
				this.iceGlossaryModel = payload[i];
				this.iceGlossaryModel.isGlossaryModified = false;
				this.iceGlossaryList.push(this.iceGlossaryModel);
			}
			if (this.iceGlossaryList.length == payload.length) {
				setTimeout(() => {
					this.setEditor("", CK_Editor_Type.GLOSSARY_EDITOR);
				}, 200);
				setTimeout(() => {
					this.setEditor("", CK_Editor_Type.GLOSSARY_DESC_EDITOR);
				}, 200);
			}
		} else {
			// no row data to show
		}
	}

	// Load ICE Game to iceGamesList
	loadGameListDetails(payload: WsResponse['payload'], afterdelete) {
		this.iceGameList = [];
		this.iceGameModel = null;
		if (payload.length != 0) {
			for (let i = 0; i < payload.length; i++) {
				this.iceGameModel = payload[i];
				this.iceGameModel.isGameModified = false;
				this.iceGameList.push(this.iceGameModel);
			}
			if (this.iceGameList.length == payload.length) {
				setTimeout(() => {
					this.setEditor("", CK_Editor_Type.GAME_EDITOR);
				}, 200);
			}
		} else {
			// no row data to show
		}
	}

	// Add new ICE Glossary
	public addNewIceGlossary() {
		let self = this;
		if (self.isShowIceGlossary == true) {
			//do nothing
		} else {
			this.isShowIceGlossary = true;
			setTimeout(() => {
				self.setEditor("", CK_Editor_Type.GLOSSARY_EDITOR);
			}, 200);
			setTimeout(() => {
				self.setEditor("", CK_Editor_Type.GLOSSARY_DESC_EDITOR);
			}, 200);
		}
		//set topic modified
		this.glossaryModified = true;
		this.sharedService.setModifiedStatus(this.tabId, this.glossaryModified)
		let listLength: number = this.iceGlossaryList.length;
		//show loading
		var loadingMessage = null;
		this.translate.get('ICE_GLOSSARY.SAVING_MESSAGE').subscribe((res: string) => {
			loadingMessage = res;
		});
		this.loadingService.showLoading(true, null, loadingMessage, null);
		// Request
		this.newGlossaryItem = {
			id: '0',
			courseId: this.course.courseId,
			glossaryName: "<ice><p>New Glossary</p></ice>",
			description: "<ice><p>Description of new glossary</p></ice>",
			courseVersion: +this.course.courseVersion,
			isGlossaryModified: true,
		}
		this.courseService.createICEGlossary(JSON.stringify(this.newGlossaryItem), UserVariable.getUserToken(), this);
		this.isShowCourseDetail = false;
		this.isShowLearningChecks = false;
		this.isShowIceGames = false;
		this.isShowIceTopic = false;
	}

	// Set Edit ICE Glossary UI
	editGlossary() {
		let self = this;
		self.activeEditor = CK_Editor_Type.GLOSSARY_EDITOR;
		if (self.courseModified == true) {
			self.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_COURSE'));
		} else if (self.learningChecksModified == true) {
			self.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_LEARNINGCHECKS'));
		} else if (self.topicsModified == true) {
			self.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_TOPICS'));
		} else if (self.gameModified == true) {
			self.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_GAMES'));
		} else {
			self.topicsEnabled = false;
			self.courseEnabled = false;
			self.learningCheckEnabled = false;
			self.glossaryEnabled = true;
			self.gameEnabled = false;
			this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'course', false, 'courseEnabled');
			this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'course', false, 'topicsEnabled');
			this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'course', false, 'learningCheckEnabled');
			this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'course', true, 'glossaryEnabled');
			this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'course', false, 'gameEnabled');

			if (self.isShowIceGlossary == false && this.iceGlossaryList.length == 0) {
				var loadingMessage = null;
				this.translate.get('LOADING').subscribe((res: string) => {
					loadingMessage = res;
				});
				this.loadingService.showLoading(true, null, loadingMessage, null);
				this.courseService.getAllICEGlossaryListByCourseId(this.data.id, UserVariable.getUserToken(), this);
			}
			//destroy editors
			if (self.titleditor) {
				self.titleditor.destroy();
			} else {
				//no editor
			}
			if (self.desceditor) {
				self.desceditor.destroy();
			} else {
				//no editor
			}
			self.isShowIceGlossary = true;
			setTimeout(() => {
				self.setEditor("", CK_Editor_Type.GLOSSARY_EDITOR);
			}, 200);
			setTimeout(() => {
				self.setEditor("", CK_Editor_Type.GLOSSARY_DESC_EDITOR);
			}, 200);
			// Handling visbility of divs
			self.isShowCourseDetail = false;
			self.isShowLearningChecks = false;
			self.isShowIceTopic = false;
			self.isShowIceGames = false;
		}
	}

	// Save ICE Glossary
	glossarySave(showValidation) {
		//update glossary model
		this.updateGlossaryModel();
		if (this.iceGlossaryList.length > 0) {
			var request = {
				"courseId": this.course.courseId,
				"courseVersion": this.course.courseVersion,
				"glossaryList": this.iceGlossaryList
			};
			//show loading message
			var loadingMessage = null;
			this.translate.get('ICE_GLOSSARY.SAVE_GLOSSARY_MESSAGE').subscribe((res: string) => {
				loadingMessage = res;
			});
			this.loadingService.showLoading(true, null, loadingMessage, null);
			this.courseService.saveGlossaryList(JSON.stringify(request), UserVariable.getUserToken(), this);
			//setglossarymodified false
			this.glossaryModified = false;
			this.sharedService.setModifiedStatus(this.tabId, this.glossaryModified)
		} else {
			if (showValidation == true) {
				this.alertService.warn(this.translate.instant('ICE_GLOSSARY.NO_GLOSSARY_ERROR'));
			} else {
				//do not show
			}
		}
		if (showValidation == true) {
			this.loadGlossary = true;
		} else {
			//navigate
		}
	}

	// Update ICE Glossary Model with modified values
	updateGlossaryModel() {
		for (let i = 1; i <= this.iceGlossaryList.length; i++) {
			if (this.iceGlossaryList[i - 1].isGlossaryModified == true) {
				this.xmlContent = "";
				//check if edior text is empty
				var editortext = $('.g_ck_editor' + this.iceGlossaryList[i - 1].id + this.tabId).text();
				if (this.isNullOrWhitespace(editortext) == true) {
					this.iceGlossaryList[i - 1].glossaryName = '<ice></ice>';
				} else {
					//process name
					var name = this.getEditorContent('.g_ck_editor' + this.iceGlossaryList[i - 1].id + this.tabId);
					this.iceCKEditor.processXML(name, false, this.xmlContent);
					this.xmlContent = this.iceCKEditor.getXMLContent();
					this.iceGlossaryList[i - 1].glossaryName = '<ice>' + this.xmlContent + '</ice>';
				}

				this.xmlContent = "";
				//check if edior text is empty
				var editortext = $('.g_ck_editor_description' + this.iceGlossaryList[i - 1].id + this.tabId).text();
				if (this.isNullOrWhitespace(editortext) == true) {
					this.iceGlossaryList[i - 1].description = '<ice></ice>';
				} else {
					//process name
					var desc = this.getEditorContent('.g_ck_editor_description' + this.iceGlossaryList[i - 1].id + this.tabId);
					this.iceCKEditor.processXML(desc, false, this.xmlContent);
					this.xmlContent = this.iceCKEditor.getXMLContent();
					this.iceGlossaryList[i - 1].description = '<ice>' + this.xmlContent + '</ice>';
				}
			} else {
				//glossary not modified 
			}
		}
	}

	// Delete ICE Glossary by Glossary Id
	public deleteIceGlossary(glossary: ICEGlossary, index: number, template: TemplateRef<any>) {
		if (glossary.id != '0') {
			//show loading message
			var loadingMessage = null;
			this.translate.get('LOADING').subscribe((res: string) => {
				loadingMessage = res;
			});
			this.loadingService.showLoading(true, null, loadingMessage, null);
			this.isDeleteEnable = true;
			this.glossaryToDelete = glossary;
			this.indexOfGlossaryToDelete = index;
			this.loadingService.hideLoading();
			this.modalRef = this.modalService.show(template, this.modalConfig);
		}
		else {
			this.deleteItemFromGlossaryList(index);
		}
	}

	// Update iceGLossaryList after delete ICE Glossary
	deleteItemFromGlossaryList(index: number) {
		this.iceGlossaryList.splice(index, 1);
		if (this.iceGlossaryList.length == 0) {
			this.isShowIceGlossary = false;
		}
	}

	// Delete ICE Glossary after confirmation
	public confirmDeleteGlossary() {
		//show loading message
		var loadingMessage = null;
		this.translate.get('ICE_GLOSSARY.GLOSSARY_DELETING_MESSAGE').subscribe((res: string) => {
			loadingMessage = res;
		});
		var request = {
			"id": this.glossaryToDelete.id,
			"courseId": this.glossaryToDelete.courseId,
		}
		this.loadingService.showLoading(true, null, loadingMessage, null);
		this.courseService.deleteICEGlossary(JSON.stringify(request), this.glossaryToDelete.id, UserVariable.getUserToken(), this);
	}

	loadConflictData(payload: any) {
		this.rowData = [];
		for (let i = 0; i < payload.length; i++) {
			this.rowData.push({
				type: payload[i]["type"],
				newVal: payload[i]["isDeleted"] == "false" ? payload[i]["newValue"] : "DELETED",
				lastVal: payload[i]["lastValue"],
				id: payload[i]["id"],
				name: payload[i]["name"],
				lastUpdateTimeStamp: payload[i]["lastUpdateTimeStamp"],
				isDeleted: payload[i]["isDeleted"]
			})
		}
		for (let x = 0; x < this.rowData.length; x++) {
			if (this.rowData[x].type == "Statement") {
				this.rowData.push(this.rowData.splice(x, 1)[0]);
			}
		}
	}

	public autoUpdateICE() {
		let selectedNodes = this.gridApi.getSelectedRows();
		let length = this.gridApi.getSelectedRows().length;
		if (length == 0) {
			this.alertService.clear();
			this.alertService.error(this.translate.instant('WEB_EDITOR.SELECT_REUSABLES_TO_UPDATE'));
		} else {
			this.modalRef.hide();
			
			for (let i = 0; i < length; i++) {
				let id = selectedNodes[i].id;
				let type = selectedNodes[i].type;
				let flag = selectedNodes[i].isDeleted;
				if (type == 'TagNo') {
					let nodes = $(".toggle-edit" + this.tabId + this.userId).find("tag#" + id);
					for (let j = 0; j < nodes.length; j++) {
						let fldNode = nodes[j].childNodes[0];
						let fldName = $(fldNode).attr("name");
						if (fldName == 'tagNo') {
							if (flag == 'false') {
								$(fldNode).attr("lastupdatetimestamp", selectedNodes[i].lastUpdateTimeStamp);
								$(fldNode).attr("value", selectedNodes[i].newVal);
								fldNode.childNodes[0].textContent = selectedNodes[i].newVal;
							} else {
								let node = nodes[j];
								$(node).remove();
							}
						}
					}
				} if (type == 'TagSystem') {
					let nodes = $(".toggle-edit" + this.tabId + this.userId).find("tag#" + id);
					for (let j = 0; j < nodes.length; j++) {
						let fldNode = nodes[j].childNodes[0];
						let fldName = $(fldNode).attr("name");
						if (fldName == 'sysName') {
							if (flag == 'false') {
								$(fldNode).attr("lastupdatetimestamp", selectedNodes[i].lastUpdateTimeStamp);
								$(fldNode).attr("value", selectedNodes[i].newVal);
								fldNode.childNodes[0].textContent = selectedNodes[i].newVal;
							} else {
								let node = nodes[j];
								$(node).remove();
							}
						}
					}
				} if (type == 'TagDocument') {
					let nodes = $(".toggle-edit" + this.tabId + this.userId).find("tag#" + id);
					for (let j = 0; j < nodes.length; j++) {
						let fldNode = nodes[j].childNodes[0];
						let fldName = $(fldNode).attr("name");
						if (fldName == 'docName') {
							if (flag == 'false') {
								$(fldNode).attr("lastupdatetimestamp", selectedNodes[i].lastUpdateTimeStamp);
								$(fldNode).attr("value", selectedNodes[i].newVal);
								fldNode.childNodes[0].textContent = selectedNodes[i].newVal;
							} else {
								let node = nodes[j];
								$(node).remove();
							}
						}
					}
				} else if (type == 'TagDesc') {
					let nodes = $(".toggle-edit" + this.tabId + this.userId).find("tag#" + id)
					for (let j = 0; j < nodes.length; j++) {
						let fldNode = nodes[j].childNodes[0];
						let fldName = $(fldNode).attr("name");
						if (fldName == 'tagName') {
							if (flag == 'false') {
								let txtNode = fldNode.childNodes[0].childNodes[0]
								$(fldNode).attr("lastupdatetimestamp", selectedNodes[i].lastUpdateTimeStamp);
								txtNode.textContent = selectedNodes[i].newVal;
							} else {
								let node = nodes[j];
								$(node).remove();
							}
						}
					}
				} else if (type == 'Component' || type == 'Document' || type == 'Facility') {
					let nodes = $(".toggle-edit" + this.tabId + this.userId).find("com#" + id)
					for (let j = 0; j < nodes.length; j++) {
						let node = nodes[j];
						let compType = $(node).attr("compType");
						if (compType == 'doc' || compType == 'com' || compType == 'fac') {
							if (flag == 'false') {
								$(node).attr("lastupdatetimestamp", selectedNodes[i].lastUpdateTimeStamp);
								$(node).attr("compValue", selectedNodes[i].newVal);
								node.textContent = selectedNodes[i].newVal;
							} else {
								$(node).remove();
							}
						}
					}
				} else if (type == 'System Number') {
					let nodes = $(".toggle-edit" + this.tabId + this.userId).find("com#" + id)
					for (let j = 0; j < nodes.length; j++) {
						let node = nodes[j];
						let compType = $(node).attr("compName");
						if (compType == 'SystemNo') {
							if (flag == 'false') {
								$(node).attr("lastupdatetimestamp", selectedNodes[i].lastUpdateTimeStamp);
								$(node).attr("compvalue", selectedNodes[i].newVal);
								node.textContent = selectedNodes[i].newVal;
							} else {
								$(node).remove();
							}
						}
					}
				} else if (type == 'System Name') {
					let nodes = $(".toggle-edit" + this.tabId + this.userId).find("com#" + id)
					for (let j = 0; j < nodes.length; j++) {
						let node = nodes[j];
						let compType = $(node).attr("compName");
						if (compType == 'SystemName') {
							if (flag == 'false') {
								$(node).attr("lastupdatetimestamp", selectedNodes[i].lastUpdateTimeStamp);
								$(node).attr("compvalue", selectedNodes[i].newVal);
								node.textContent = selectedNodes[i].newVal;
							} else {
								$(node).remove();
							}
						}
					}
				} else if (type == 'Statement') {
					let oldStmNode: any;
					let newStmHTML: string = '';
					let nodes = $(".toggle-edit" + this.tabId + this.userId).find("statement#" + id)
					for (let j = 0; j < nodes.length; j++) {
						oldStmNode = nodes[j];
						if (flag == 'false') {
							newStmHTML = this.createEditedStatement(selectedNodes[i].name, selectedNodes[i].lastUpdateTimeStamp, oldStmNode)
							this.setOuterHTML(oldStmNode, newStmHTML);
						} else {
							$(oldStmNode).remove();
						}
					}
				} else if (type == 'TagAttribute') {
					let nodes = $(".toggle-edit" + this.tabId + this.userId).find("attr");
					for (let j = 0; j < nodes.length; j++) {
						let node = nodes[j];
						let specId = $(node).attr("specid");
						if (specId == id) {
							if (flag == 'false') {
								$(node).attr("lastupdatetimestamp", selectedNodes[i].lastUpdateTimeStamp);
								$(node).attr("value", selectedNodes[i].newVal);
								node.textContent = selectedNodes[i].newVal;
							} else {
								$(node).remove();
							}
						}
					}
				}
			}
			this.alertService.clear();
			this.alertService.warn(this.translate.instant('PROCEDURE_EDITOR.CONTENT_UPDATE_MSG'), true);

		}
		
	}

	createEditedStatement(statementContent: string, lastUpdateTimeStamp: string, node: any): string {
		this.stmHtml = '';
		let xmlDoc;
		try {
			xmlDoc = $.parseXML(statementContent);
		} catch {
			this.alertService.clear();
			this.alertService.error(this.translate.instant('INVALID_XML_MSG'), true);
		}
		let outNode = $(xmlDoc).find('statement');
		let length = outNode[0].childNodes.length;
		let id = outNode.attr('id');
		let label = this.htmlEncode(outNode.attr('label'));
		this.stmHtml = "<statement style='background-color:#d9d9d9;' insID='" + this.guid() + "' lastUpdateTimeStamp='" + lastUpdateTimeStamp + "' contenteditable='false' id='" +
			id + "' label='" + label + "'>";
		for (let i = 0; i < length; i++) {
			let innerNode = outNode[0].childNodes[i];
			if ($(innerNode)[0].nodeName == 'txt') {
				this.stmHtml += "<txt";
				let bgc = $(innerNode).attr("bgc");
				let fgc = $(innerNode).attr("fgc");
				if (bgc != null && bgc != "") {
					this.stmHtml += " bgc='"+bgc+ "' "
				}
				if (fgc != null && fgc != "") {
					this.stmHtml += " fgc='"+fgc+ "' "
				}
				this.stmHtml += ">";
				for (let j = 0; j < $(innerNode)[0].childNodes.length; j++) {
					let childTxtNode = $(innerNode)[0].childNodes[j];
					if ($(childTxtNode)[0].nodeName == '#text') {
						this.stmHtml += childTxtNode.textContent;
					} else {
						this.processNewStatement(childTxtNode, node);
					}
				}
				this.stmHtml += "</txt>";
			}
			else if ($(innerNode)[0].nodeName == 'ph') {
				this.processNewStatement($(innerNode)[0], node);
			} else if ($(innerNode)[0].nodeName == 'p') {
				this.stmHtml += '<para contenteditable="false" style="background-color:#d9d9d9;">';
				for (let i = 0; i < $(innerNode)[0].childNodes.length; i++) {
					let childNode = $(innerNode)[0].childNodes[i];
					if ($(childNode)[0].nodeName == 'txt') {
						this.stmHtml += "<txt>";
						for (let j = 0; j < $(childNode)[0].childNodes.length; j++) {
							let childTxtNode = $(childNode)[0].childNodes[j];
							if ($(childTxtNode)[0].nodeName == '#text') {
								this.stmHtml += this.htmlEncode(childTxtNode.textContent);
							} else {
								this.processNewStatement(childTxtNode, node);
							}
						}
						this.stmHtml += "</txt>";
					} else if ($(childNode)[0].nodeName == 'ph') {
						this.processNewStatement($(childNode)[0], node);
					}
				}
				this.stmHtml += '</para> ';
			} else if ($(innerNode)[0].nodeName == 'ol' || $(innerNode)[0].nodeName == 'ul') {
				this.stmHtml += '<' + $(innerNode)[0].nodeName + ' contenteditable="false" style="background-color:#d9d9d9;">';
				for (let i = 0; i < $(innerNode)[0].childNodes.length; i++) {
					let liNode = $(innerNode)[0].childNodes[i];
					if (liNode.nodeName == 'li') {
						this.stmHtml += '<li>';
						for (let i = 0; i < liNode.childNodes.length; i++) {
							let childNode = liNode.childNodes[i];
							if (childNode.nodeName == 'txt') {
								$(childNode).removeAttr('style');
								this.processNewStatement(childNode, node);
							} else if (childNode.nodeName == 'ph') {
								this.processNewStatement(childNode, node);
							}
						}
						this.stmHtml += '</li>';
					}
				}
				this.stmHtml += '</' + $(innerNode)[0].nodeName + '>';
			}
		}
		this.stmHtml += "</statement>";
		return this.stmHtml;
	}

	private getOldTagsNode(tagPHId:String, node: any){
		if(node.nodeName == "tags" || node.nodeName == "TAGS" || node.nodeName == "ph"){
			if($(node).attr('tagphid') == tagPHId){
				return node;
			}else{
				return null;
			}
		}else{
			for(let i=0; i<node.childNodes.length;i++){
				let tagsNode = this.getOldTagsNode(tagPHId, node.childNodes[i])
				if(tagsNode != null){
					return tagsNode;
				}
			}
		}
		return null;
	}

	private processNewStatement(node: any, oldStmNode: any) {
		if (node.nodeName == 'ph') {
			let isTags: boolean = false;
			let arrLen = node.attributes.length;
			let attrPh, attrRestriction, value, attrCatId;
			for (let n = 0; n < arrLen; n++) {
				let attrName = node.attributes[n].name;
				let attrValue = node.attributes[n].value;
				if (attrName == 'tagPHId' || attrName == 'tagphid') {
					attrPh = attrValue;
				} else if (attrName == 'restriction') {
					attrRestriction = attrValue;
				} else if (attrName == 'catId') {
					attrCatId = attrValue;
				}
			}
			let oldTagsNode = this.getOldTagsNode(attrPh, oldStmNode);
			if (oldTagsNode != null) {
				this.stmHtml += oldTagsNode.outerHTML;
				isTags = true;
			}
			if (!isTags) {
				if (attrPh != undefined && attrRestriction != undefined) {
					value = node.childNodes[0].textContent;
					if (attrCatId != undefined) {
						if (value != undefined) {
							this.stmHtml += "<ph insID='" + this.guid() + "' style='background-color:#ffffcc ;' catId='" + attrCatId + "' tagPHId='" + attrPh + "' restriction='" + attrRestriction + "'>" + value + "</ph>";
						} else {
							this.stmHtml += "<ph insID='" + this.guid() + "' style='background-color:#ffffcc ;' catId='" + attrCatId + "' tagPHId='" + attrPh + "' restriction='" + attrRestriction + "'></ph>";
						}
					} else {
						if (value != undefined) {
							this.stmHtml += "<ph insID='" + this.guid() + "' style='background-color:#ffffcc ;' tagPHId='" + attrPh + "' restriction='" + attrRestriction + "'>" + value + "</ph>";
						} else {
							this.stmHtml += "<ph insID='" + this.guid() + "' style='background-color:#ffffcc ;' tagPHId='" + attrPh + "' restriction='" + attrRestriction + "'></ph>";
						}
					}
				} else if (attrPh != undefined && attrRestriction == undefined) {
					this.stmHtml += "<ph insID='" + this.guid() + "' style='background-color:#ffffcc ;' tagPHId='" + attrPh + "'></ph>";
				}
			}
		} else if (node.nodeName == 'txt') {
            this.stmHtml += "<txt";
            let bgc = $(node).attr("bgc");
            let fgc = $(node).attr("fgc");
            if (bgc != null && bgc != "") {
                this.stmHtml += " bgc='" + bgc + "' "
            }
            if (fgc != null && fgc != "") {
                this.stmHtml += " fgc='" + fgc + "' "
            }
            this.stmHtml += ">";
            for (let j = 0; j < $(node)[0].childNodes.length; j++) {
                let childTxtNode = $(node)[0].childNodes[j];
                if ($(childTxtNode)[0].nodeName == '#text') {
                    this.stmHtml += childTxtNode.textContent;
                } else {
                    this.processNewStatement(childTxtNode, oldStmNode);
                }
            }
			this.stmHtml += "</txt>";
		} else if (node.nodeName == 'ol') {		
			let listStyle = $(node).attr("liststyle");		
			this.stmHtml += '<' + $(node)[0].nodeName + ' contenteditable="false" ckeditorlist="true" style="background-color:#d9d9d9; list-style-type:' + listStyle +		
				'">';		
			for (let i = 0; i < $(node)[0].childNodes.length; i++) {		
				let liNode = $(node)[0].childNodes[i];		
				if (liNode.nodeName == 'li') {		
					this.stmHtml += '<li>';		
					for (let i = 0; i < liNode.childNodes.length; i++) {		
						let childNode = liNode.childNodes[i];		
						if (childNode.nodeName == 'txt') {		
							$(childNode).removeAttr('style');		
							this.processNewStatement(childNode, oldStmNode);		
						} else if (childNode.nodeName == 'ph') {		
							this.processNewStatement(childNode, oldStmNode);		
						}		
					}		
					this.stmHtml += '</li>';		
				}		
			}		
			this.stmHtml += '</' + $(node)[0].nodeName + '>';		
		
		} else if (node.nodeName == 'ul') {		
			let listStyle = $(node).attr("liststyle");		
			this.stmHtml += '<' + $(node)[0].nodeName + ' contenteditable="false" ckeditorlist="true" style="background-color:#d9d9d9; list-style-type:' + listStyle +		
				'">';		
			for (let i = 0; i < $(node)[0].childNodes.length; i++) {		
				let liNode = $(node)[0].childNodes[i];		
				if (liNode.nodeName == 'li') {		
					this.stmHtml += '<li>';		
					for (let i = 0; i < liNode.childNodes.length; i++) {		
						let childNode = liNode.childNodes[i];		
						if (childNode.nodeName == 'txt') {		
							$(childNode).removeAttr('style');		
							this.processNewStatement(childNode, oldStmNode);		
						} else if (childNode.nodeName == 'ph') {		
							this.processNewStatement(childNode, oldStmNode);		
						}		
					}		
					this.stmHtml += '</li>';		
				}		
			}		
			this.stmHtml += '</' + $(node)[0].nodeName + '>';
		}else if (node.nodeName == 'b' || node.nodeName == 'u' || node.nodeName == 'i'){
			this.stmHtml += '<'+node.nodeName+'>';
			for (let i = 0; i < node.childNodes.length; i++) {		
				let childNode = node.childNodes[i];	
				this.processNewStatement(childNode, oldStmNode);		
			}
			this.stmHtml += '</'+node.nodeName+'>';
		}else if (node.nodeName == '#text'){
			this.stmHtml += node.nodeValue;
		}else {
			let str = node.outerHTML;
			if(str != undefined){
				str = str.replace(/<font.*?>/gi, '');
				str = str.replace(/<\/font>/gi, '');
				this.stmHtml += str;
			}
		}
	}

	private s4(): string {
		return Math.floor((1 + Math.random()) * 0x10000)
			.toString(16)
			.substring(1);
	}

	private guid(): string {
		return this.s4() + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + this.s4() + this.s4();
	}

	private htmlEncode(content: string): string {
		return content.replace(/(?!&amp;|&gt;|&lt;|&quot;|&nbsp;)\&/g, "&amp;").replace(/>/g, "&gt;").replace(/</g, "&lt;").
			replace(/"/g, "&quot;").replace(/'/g, "&#39;");
	}

	private setOuterHTML(node: any, content: string) {
		if (node.parentNode != undefined) {
			node.outerHTML = content;
		} else {
			this.alertService.clear();
			this.alertService.info(this.translate.instant('TAG_EDIT_AREA.NOT_VALID_SELECTION'), true);
		}
	}
}
