<div class="container-fluid page-container">
    <nav class="page-header">
        <div class="row">
            <div class="col">
                <p class="mb-2 h5">
                    <b>Manage Onshore Turnover Notice (OTN)</b>
                </p>
            </div>
            <div class="col">
                <button type="submit" id="add_otn" class="btn btn-primary btn-sm float-right reusable_btn my-1"
                    (click)="newOTNButtonClick()">
                    <i class="fas fa-plus"></i></button>
            </div>
        </div>
    </nav>
    <div class="row">
        <div class="col-sm-12 mb-2">
            <symbiosis-search [comp]="'manageotn'" id="otn_search" (searchFunc)="searchFunc($event)"></symbiosis-search>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-md-6">
            <ul class="list-group list-group-horizontal-sm">
                <li class="list-group-item d-flex align-items-center"><span class="dot pl-4 colorIssued mr-2 ml-2">
                    </span>Issued</li>
                <li class="list-group-item d-flex align-items-center"><span class="dot pl-4 colorApproved mr-2 ml-2">
                    </span>Approved</li>
                <li class="list-group-item d-flex align-items-center text-nowrap"> <span
                        class="dot pl-4 colorQueue mr-2 ml-2"> </span>On Queue</li>
                <li class="list-group-item d-flex align-items-center"><span class="dot pl-4 colorErr mr-2 ml-2">
                    </span>Error</li>
            </ul>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col">
            <ag-grid-angular #agGrid style="width: 100%; height: 375px;" class="ag-theme-balham" [rowData]="rowData"
                [columnDefs]="columnDefs" [gridOptions]="gridOptions" [modules]="modules"
                [sideBar]="{ toolPanels: ['columns'] }" [pagination]="true" [paginationPageSize]="10"
                [overlayLoadingTemplate]="overlayLoadingTemplate" [overlayNoRowsTemplate]="overlayNoRowsTemplate"
                [getRowNodeId]="getRowNodeId" (gridReady)="onGridReady($event)">
            </ag-grid-angular>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12 col-12">
            <button *ngIf="generateOut" type="submit" id="facility_save" class="btn btn-primary btn-sm float-left"
                (click)="generateOutputClick()">Generate</button>
        </div>
    </div>
</div>

<ng-template #incompleteOTNModal>
    <div class="modal-header">
      <h6 class="modal-title pull-left">Generate Onshore Turnover Notice </h6>
      <button type="button" class="close pull-right" aria-label="Close" (click)="cancelOutGenerete()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body ">
      <p *ngIf="hasAssetWithNoIRN">The following Asset(s) do not have at least one IRN.</p>
      <div>
        <ul>
          <div *ngFor="let tag of assetIrnCheckList">
            <!-- <li *ngIf="!tag.hasIRN"> -->
              <li>{{tag.tagNo}}</li>
            <!-- </li> -->
          </div>
        </ul>
      </div>
      <p *ngIf="hasAssetWitOpenPunchlists"> The following Asset(s) have incomplete type A punchlists.</p>
      <div>
        <ul>
          <div *ngFor="let tag of assetOpenPunchlistCheckList">
            <!-- <li *ngIf="tag.hasOpenPunchlists"> -->
              <li>{{tag.tagNo}}</li>
            <!-- </li> -->
          </div>
        </ul>
      </div>
      <p> Do you want to continue?  </p>
      <div class="float-right">
        <button type="button" class="btn btn-default mx-1" (click)="continue()">Continue</button>
        <button type="button" class="btn btn-primary mx-1" (click)="cancelOutGenerete()">Cancel</button>
      </div>
    </div>
  </ng-template>
