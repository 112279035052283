import { Component } from "@angular/core";
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { CapabilityService } from '../../../../util/capability.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { OutputManagementService } from '../../outputmanagement.service';
import { AlertService } from '../../../../util/alert/alert.service';
import { GridOptions } from "@ag-grid-enterprise/all-modules";
import { LoadingService } from '../../../../util/loading/loading.service';

@Component({
   selector: 'child-cell',
   template: '<input type="checkbox" [(ngModel)]="isChecked" (change)="checkVal($event.target.checked)" [hidden]="isVisible">'
})

export class OutputHomeCheckboxRender implements ICellRendererAngularComp {
   public params: any;
   bsModalRef: BsModalRef;
   isChecked: boolean;
   isVisible: boolean
   id: string;
   itemType: string;
   prcStatus: string;
   processStatus: string;
   public gridOptions: GridOptions;
   
   constructor(private capabilityService: CapabilityService, private modalService: BsModalService, private translate: TranslateService,
      private outputManagementService: OutputManagementService, private alertService: AlertService, private loadingService: LoadingService) {
   }

   
   agInit(params: any): void {
      this.params = params
      this.id = this.params.data.id
      this.prcStatus = this.params.data.prcStatus      
      if (params.data.prcStatus == 7) {
         this.isChecked = true
      } else if (params.data.prcStatus == 3 || params.data.prcStatus == 5 || params.data.prcStatus == 6) {
         this.isChecked = false;
      } else {
         this.isVisible = true;
      }
   }

   public checkVal(isChecked: boolean) {
      this.loadingService.showLoading(true, null, "", null)
      let chkVal
      if (isChecked == true) {
         chkVal = "7";
      } else {
         chkVal = "3";
      }
      this.outputManagementService.updatePrcessStatus(this.id, chkVal)
         .subscribe(data => {
            this.loadingService.hideLoading()
            this.processStatus = data.payload
            this.alertService.success(data.payload)
         },
            error => {
               this.loadingService.hideLoading()
               this.alertService.error(error.statusDescription)
            });

   }

   refresh(params: any): boolean {
      this.params.data.processStatus = this.processStatus
      params.api.refreshCells(params);
      return true;
   }

}
