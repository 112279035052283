import { Injectable } from '@angular/core';
import { UserVariable } from '../util/common/user-variable';
import { map } from 'rxjs/operators'
import { WsResponse } from '../util/ws-response.model'
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { ServiceUrls } from '../util/service-urls';
import { SharedService } from '../util/shared.service';
import { CommonUtil } from '../util/common/common-util';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private apiGridData: any = null;
  constructor(private http: HttpClient, private sharedService: SharedService, private commonUtil: CommonUtil) { }

  getAllUsersByClientId(id: any)
	{
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateLogin(token)){
            return;
        }
		const options = { params: new HttpParams().set("token", token) }
		let url = this.getUsersByUrl(id);
        return this.http.get(url, options).
            pipe(
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                }),
                catchError(error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    return throwError(res)
                })
            )
	}

	getUser(id: any)
	{
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateLogin(token)){
            return;
        }
		const options = { params: new HttpParams().set("token", token) }
		let url = this.getUserURL(id);
        return this.http.get(url, options).
            pipe(
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                }),
                catchError(error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    return throwError(res)
                })
            );

	}

	updateUser(id: string, firstName: string, lastName: string, authType: string, userName: string, password: string, currentUName: string,
		status: string, emailAddress: string, clientStatus: string, clientId: string, passwordExpireDate: string, omitDeviceToken: string) {
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateLogin(token)){
            return;
        }
		let url = ServiceUrls.USERMODALS  + 'update/updateUser.json?token=' + token;
		//  let user = JSON.stringify(new UpdateRequest( id, firstName, lastName, authType, userName, password, currentUName,
		// 	status, emailAddress, clientStatus, clientId, passwordExpireDate, omitDeviceToken));
		// const options = { params: new HttpParams().set("id", id).set("firstName", firstName).set("lastName", lastName).set("authType", authType).set("userName", userName).
		// 								set("password", password).set("currentUName", currentUName).set("status", status).set("emailAddress", emailAddress).
		// 							set("clientStatus", clientStatus).set("clientId", clientId).set("passwordExpireDate", passwordExpireDate).set("omitDeviceToken", omitDeviceToken)}
		// 	console.log("user==========" + id)
		console.log("clientId: "+clientId)
		var data = {id : id, firstName : firstName, lastName: lastName, authType: authType, userName: userName, password: password, currentUName: currentUName,
			status: status,  emailAddress: emailAddress,  clientStatus: clientStatus, clientId: clientId, passwordExpireDate: passwordExpireDate, omitDeviceToken:  omitDeviceToken  }
		return this.http.put(url, data).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.name,
					modified.status.code,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error));
				var res = new WsResponse(
					modified.error.status.description,
					modified.error.status.name,
					modified.error.status.code,
					modified.error.payload);
				return throwError(res)
			})
			);
	}

	addUser( firstName: String, lastName: String, authType: String, userName: String, password: String, 
		status: String, emailAddress: String, clientStatus: String, clientId: String, passwordExpireDate: String, omitDeviceToken: String) {
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateLogin(token)){
            return;
        }
		let url = ServiceUrls.USERMODALS  + 'add/addUser.json?token=' + token;
		if(emailAddress === null)
		{
			emailAddress = "";
		}
		//let user = JSON.stringify(new AddRequest(  firstName, lastName, authType, userName, password,
		//	status, emailAddress, clientStatus, clientId, passwordExpireDate, omitDeviceToken))
		var data = {firstName : firstName, lastName: lastName, authType: authType, userName: userName, password: password,
			status: status,  emailAddress: emailAddress,  clientStatus: clientStatus, clientId: clientId, passwordExpireDate: passwordExpireDate, omitDeviceToken:  omitDeviceToken  }
		return this.http.put(url, data ).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.name,
					modified.status.code,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error));
				var res = new WsResponse(
					modified.error.status.description,
					modified.error.status.name,
					modified.error.status.code,
					modified.error.payload);
				return throwError(res)
			})
			);
	}

	public getUserURL(id:any)
	{
		let url = ServiceUrls.USER_PROJECT_VIEW +  id + ".json";
		return url
	} 


	public getUsersByUrl(id:any)
	{
		let url = ServiceUrls.USER_PROJECT_VIEW +  id + "/getAllUsersByClientId.json";
		return url
	} 

	public deleteFileTableData(fileId: any) {
		if (this.apiGridData != null && this.apiGridData != undefined && fileId != null) {
			var selectedData = this.apiGridData.getSelectedRows();
			if (selectedData != null && selectedData != undefined) {
				this.apiGridData.applyTransaction({ remove: selectedData });
			}
		}
	}

	public disableEnableFileTableData(fileId: any, title: String, status: String) {
		console.log("hhh" + status + title + fileId)
		if (this.apiGridData != null && this.apiGridData != undefined && fileId != null) {
			var rowNode = this.apiGridData.getRowNode(fileId);
			console.log("hhh" + status + "title " + title)
			if (rowNode != null && rowNode != undefined) {
				if (title == 'Disable Confirm User') {
					console.log("bhhh")
					this.sharedService.disableCellRendererRequest(true, fileId);
					rowNode.setDataValue("status", "status:" + fileId + ":inactive");
					rowNode.setDataValue("delete", "delete:" + fileId + ":inactive");
				} else if (title == 'Enable Confirm User') {
					console.log("hhh" + status + title + fileId)
					this.sharedService.disableCellRendererRequest(false, fileId);
					rowNode.setDataValue("status", "status:" + fileId + ":Active");
					rowNode.setDataValue("delete", "delete:" + fileId + ":Active");
				}
				else {
					if (status == "Inactive" || status == "inactive") {
						this.sharedService.disableCellRendererRequest(false, fileId);
						rowNode.setDataValue("status", "status:" + fileId + ":Active");
						rowNode.setDataValue("delete", "delete:" + fileId + ":Active");
					}
					else {
						this.sharedService.disableCellRendererRequest(true, fileId);
						rowNode.setDataValue("status", "status:" + fileId + ":Inactive");
						rowNode.setDataValue("delete", "delete:" + fileId + ":Inactive");
					}
				}
			}
		}
	}

	public setApiGridData(apiGridData: any) {
        this.apiGridData = apiGridData;
    }

    public getApiGridData() {
        return this.apiGridData;
    }
}

class UpdateRequest {
	constructor(id: String, firstName: String, lastName: String, authType: String, userName: String, password: String, currentUName: String,
		status: String, emailAddress: String, clientStatus: String, clientId: String, passwordExpireDate: String, omitDeviceToken: String) { }
}

class AddRequest {
	constructor( firstName: String, lastName: String, authType: String, userName: String, password: String,
		status: String, emailAddress: String, clientStatus: String, clientId: String, passwordExpireDate: String, omitDeviceToken: String) { }
}