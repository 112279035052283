<alert class="floating-alert"></alert>
<nav id="navPanel" class="bg-light border-bottom box-shadow fixed-top navbar-expand-lg" #navPanel>
	<app-header [minimalOption]="true" [showHamburger]="true" (showHideSideBar)="isSideBar = !isSideBar"></app-header>
</nav>
<div class="container-fluid">
	<div class="row">
		<nav class="col-md-2 d-md-block bg-dark box-shadow sidebar animate__animated animate__faster" [ngClass]="{'animate__slideInLeft': isSideBar, 'animate__slideOutLeft': !isSideBar}">
			<div class="sidebar-sticky">
				<app-dictionary-sidebar (selectSideBarItem)="changeSideBarItem($event)"></app-dictionary-sidebar>
			</div>
		</nav>
		<main role="main" class="px-0" [ngClass]="{'col-md-10': isSideBar, 'col-md-12': !isSideBar}" #main [style.padding-top.px]="paddingtop">
			<div class="col-sm-12 col-md-12 mx-auto pl-2 pt-2">
				<div #dictionaryHost></div>
			</div>
		</main>
	</div>
</div>