<div class="container-fluid px-0">
    <div class="row px-3">
        <div class="col-sm-8 px-0">
            <p class="mb-2 h5">
                <b>{{ 'FACILITY_SEARCH_VIEW.HEADER' | translate }}</b>
            </p>
        </div>
        <div class="col-sm-4 px-0">
            <button *ngIf="changeLayout" type="submit" class="btn btn-primary btn-sm float-right reusable_btn my-1"
             [disabled]="!enableAddFacility" (click)="add()">
                <i class="fas fa-plus"></i>
            </button>
        </div>
    </div>
    <div class="col-sm-12 px-0">
        <div class="input-with-icon" *ngIf="!enableSearchFacility">
			<input #symbiosis_search class="form-control" name="facility_search" placeholder="Search" style="padding-right: 60px;" 
				[disabled]="!enableSearchFacility"/>
        </div>
        <div class="input-with-icon" *ngIf="enableSearchFacility">
            <symbiosis-search [comp]="'facility'" id="facility_search" (searchFunc)="searchFunc($event)"
                (searchBarEnterFunc)="searchBarEnterFunc($event)"></symbiosis-search>
        </div>
    </div>
    <hr class="my-2" />
</div>
<!-- *ngIf="expandedView == fuv.EXPANDED_VIEW" -->
<div class="container-fluid px-0">
    <!-- <div class="row">
        <div class="col-sm-12 float-right">
            <button type="submit" id="add_facility" class="btn btn-warning btn-sm float-left" (click)="add()">{{
                'FACILITY_SEARCH_VIEW.ADD' | translate }}</button>
        </div>
    </div> -->

    <!-- *ngIf="rowData.length>0" -->
    <div *ngIf="expandedView == fuv.EXPANDED_VIEW">
        <ag-grid-angular #agGrid style="width: 100%; height: 375px;" class="ag-theme-balham" 
            [enableFilter]="true" [rowData]="rowData" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }" [columnDefs]="columnDefs" rowSelection="multiple"
             [rowMultiSelectWithClick]="false" [defaultColDef]="defaultColDef"
            [getRowNodeId]="getRowNodeId" (gridReady)="onGridReady($event)" [pagination]="true"
            [paginationPageSize]="10" [overlayLoadingTemplate]="overlayLoadingTemplate"
            [overlayNoRowsTemplate]="overlayNoRowsTemplate">
        </ag-grid-angular>
    </div>

    <div *ngIf="expandedView != fuv.EXPANDED_VIEW">
        <ag-grid-angular #agGrid style="width: 100%; height: 375px;" class="ag-theme-balham" 
            [enableFilter]="true" [rowData]="rowData" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }" [columnDefs]="columnDefs" rowSelection="multiple"
             [rowMultiSelectWithClick]="false" (cellClicked)="cellClicked($event)" [defaultColDef]="defaultColDef"
             [getRowNodeId]="getRowNodeId" (gridReady)="onGridReady($event)" [pagination]="true"
            [paginationPageSize]="10" [overlayLoadingTemplate]="overlayLoadingTemplate"
            [overlayNoRowsTemplate]="overlayNoRowsTemplate">
        </ag-grid-angular>
    </div>
</div>