<div *ngIf="!dynamicLoad && !assetLoad && !disableInput">
  <tag-input  name="tagInputs" id="tagInputs" [(ngModel)]='selectedItemList' [disable]="disable"
              [removable]='true'
              (onAdd)="selectItemFromSuggestions($event)"
              [placeholder]="placeholder"
              [secondaryPlaceholder]="placeholder"
              (onRemove)="removeSelectedItem($event)"
              [onlyFromAutocomplete]="true"
              [clearOnBlur]="true">
    <tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="dropdownItemList"
                        [limitItemsTo]=20></tag-input-dropdown>
  </tag-input>
</div>
<div *ngIf="dynamicLoad && !assetLoad && !disableInput" >
  <tag-input name="tagInputs" id="tagInputs" [(ngModel)]='selectedItemList' [disable]="disable"
             [removable]='true'
             [placeholder]="placeholder"
             [secondaryPlaceholder]="placeholder"
             [onlyFromAutocomplete]="true"
             (onAdd)="selectItemFromSuggestions($event)"
             (onRemove)="removeSelectedItem($event)"
             [clearOnBlur]="true">
    <tag-input-dropdown [showDropdownIfEmpty]="true"
                        [autocompleteObservable]="requestAutocompleteItems "
                        [limitItemsTo]=10 [appendToBody]="false"></tag-input-dropdown>
  </tag-input>
</div >
<div *ngIf="!dynamicLoad && assetLoad && !disableInput">
  <tag-input  name="tagInputs" id="tagInputs" [(ngModel)]='selectedItemList' [disable]="disable"
              [removable]='true'
              (onAdd)="selectItemFromSuggestions($event)"
              [placeholder]="placeholder"
              [secondaryPlaceholder]="placeholder"
              (onRemove)="removeSelectedItem($event)"
              [onlyFromAutocomplete]="true"
              [clearOnBlur]="true">
    <tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="dropdownItemList" [appendToBody]="false"
                        [limitItemsTo]=20 [zIndex]=9999 [keepOpen]="false"></tag-input-dropdown>
  </tag-input>
</div>
<div *ngIf="!dynamicLoad && assetLoad && disableInput">
  <tag-input  name="tagInputs" id="tagInputs" [(ngModel)]="disable && selectedItemList.length > 0 ? selectedItemList.splice(0, selectedItemList.length) : selectedItemList" [disable]="disable"
              [removable]='true'
              (onAdd)="selectItemFromSuggestions($event)"
              [placeholder]="placeholder"
              [secondaryPlaceholder]="placeholder"
              (onRemove)="removeSelectedItem($event)"
              [onlyFromAutocomplete]="true"
              [clearOnBlur]="true">
    <tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteObservable]="requestAutocompleteItems" [appendToBody]="false"
                        [limitItemsTo]=20 [zIndex]=9999 [keepOpen]="false"></tag-input-dropdown>
  </tag-input>
</div>

