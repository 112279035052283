<div class="main-panel pt-0 pb-4" style="overflow: hidden;">
	<div class="container-fluid">
		<div class="col-sm-12 px-0">
			<h3>
				{{ 'DICTIONARY.DICTIONARY' | translate }}
			</h3>
			<hr class="mt-0 mb-3" />
		</div>
		<div class="col-sm-12 px-0">
			<form>
				<div class="form-group">
					<label class="mb-0">{{ 'DICTIONARY.LANGUAGE' | translate }} :</label>
					<ng-select [active]="languageList" [allowClear]="false" (selected)="getSelectedLanguage($event)"
						[items]="languageList" [disabled]="!enableQueryWord" name="Language" id="language">
					</ng-select>
				</div>
			</form>
		</div>
		<hr class="mt-0 mb-3" />
		<div class="col-sm-12 px-0">
			<ag-grid-angular #agGrid style="width: 100%; height: 400px;" id="dictionaryTable" [rowData]="rowData" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }"
				class="ag-theme-balham" [columnDefs]="columnDefs" [frameworkComponents]="frameworkComponents"
				  [enableRangeSelection]="true" [paginationPageSize]="10" [defaultColDef]="defaultColDef"
				[pagination]="true" (columnResized)="onColumnResized($event)" (gridReady)="onGridReady($event)"
				[suppressMenuHide]="true" [gridOptions]="gridOptions" [overlayLoadingTemplate]="overlayLoadingTemplate"
				[overlayNoRowsTemplate]="overlayNoRowsTemplate"></ag-grid-angular>
		</div>
	</div>
</div>

<!-- <div class="main-panel" [style.padding-top.px]="60" style="overflow: hidden;">
	<div class="my-2 my-sm-0 mr-1 ml-2">
		<button type="button" [disabled]="!addNewWordToDictionary" id="addtodictionary" (click)="addDictionaryWord()" class="btn btn-outline-primary btn-sm ml-1 mr-1">
			 <i class="fa fa-plus-square" aria-hidden="true"></i> Add New Word
		</button>
 </div>
	 
	<div class="card-block card-body mt-2 pb-1 pt-1 card-primary" [ngClass]="{'content-hide': isMinimized, 'content-show': !isMinimized}">
		<div class="form-group col">
			<label>{{ 'DICTIONARY.DICTIONARY' | translate }} </label>
			<ng-select [active]="languageList" [allowClear]="false"  (selected)="getSelectedLanguage($event)"
			[items]="languageList" name="Language" id="language">
			</ng-select>
		</div>
		<ag-grid-angular #agGrid style="width: 100%; height: 400px;" id="dictionaryTable" [rowData]="rowData"  [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }" class="ag-theme-balham"
				[columnDefs]="columnDefs" [frameworkComponents]="frameworkComponents"  
				[enableRangeSelection]="true" [paginationPageSize]="10" [pagination]="true" (columnResized)="onColumnResized($event)"
				(gridReady)="onGridReady($event)" [suppressMenuHide]="true" [gridOptions] ="gridOptions" [overlayLoadingTemplate]="overlayLoadingTemplate"
				[overlayNoRowsTemplate]="overlayNoRowsTemplate"></ag-grid-angular>
	</div>
</div> -->