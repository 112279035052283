<div class="main-panel pt-0 pb-4" style="overflow: hidden;">
	<div class="container-fluid">
		<div class="col-sm-12 px-0">
			<h3>
				{{ 'OUTPUT_MANAGEMENT.OM_SIDEBAR_ADD_PROJECT_TEMPLATE' | translate }}
			</h3>
			<hr class="mt-0 mb-3" />
		</div>
		<div class="col-sm-12 px-0" [ngClass]="{'content-hide': isMinimized, 'content-show': !isMinimized}">
			<ag-grid-angular #agGrid style="width: 100%; height: 400px;" id="addTemplate" [rowData]="rowData" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }"
				class="ag-theme-balham" [columnDefs]="columnDefs" [frameworkComponents]="frameworkComponents"
				  [enableRangeSelection]="true" [paginationPageSize]="10" [defaultColDef]="defaultColDef"
				[pagination]="true" (columnResized)="onColumnResized($event)" (gridReady)="onGridReady($event)"
				[suppressMenuHide]="true" [gridOptions]="gridOptions" [singleClickEdit]="true"></ag-grid-angular>
		</div>
	</div>
</div>