<div>
  <div class="modal-header">
      <h4 class="modal-title pull-left">{{title}}</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <div class="container">

          <!--Topics-->
          <!--<div *ngIf="topicDependency != undefined">
              <div>
                  <h5 class="text-center">Topics</h5>
                  <div>
                      {{topicDependency.projectName}} ({{topicDependency.Topic != undefined? topicDependency.Topic.length: 0}})
                  </div>
                  <br />
                  <div *ngFor="let dependency of topicDependency.Topic">
                      <li>
                          <label>{{dependency.topicName}} ({{dependency.referenceCount}}) [{{dependency.topicPath}}] - {{dependency.checkedOutUser}}</label>
                      </li>
                  </div>
              </div>
          </div>-->

          <!--Procedures-->
          <!--<div *ngIf="procedureDependency != undefined">
              <div>
                  <h5 class="text-center">Procedures</h5>
                  <div>
                      {{procedureDependency.projectName}} ({{procedureDependency.Procedure != undefined? procedureDependency.Procedure.length: 0}})
                  </div>
                  <br />
                  <div *ngFor="let dependency of procedureDependency.Procedure">
                      <li>
                          <label>{{dependency.procName}} ({{dependency.referenceCount}}) [{{dependency.procPath}}] - {{dependency.checkedOutUser}}</label>
                      </li>
                  </div>
              </div>
          </div>-->

          <!--Tags-->
        <div *ngIf="tagDependency != undefined">
            <div>
                <h5 class="text-center">Tags</h5>
                <div>
                    {{tagDependency.Tags.projectName}}
                    <!-- ({{tagDependency.Tags.Tag != undefined? tagDependency.Tags.Tag.length: 0}}) -->
                    ({{tagDependency.Tags.dependencyCount}}) 
                </div>
                <br />
                <div *ngIf="tagDependency.Tags.Tag.constructor.name === 'Array'">
                    <div *ngFor="let dependency of tagDependency.Tags.Tag">
                        <li>
                            <label>{{dependency.tagNo}} - {{dependency.description}}</label>
                        </li>
                    </div>
                </div>

                <div *ngIf="tagDependency.Tags.Tag.constructor.name === 'Object'">
                    <li>
                        <label>{{tagDependency.Tags.Tag.tagNo}} - {{tagDependency.Tags.Tag.description}}</label>
                    </li>
                </div>
            </div>
        </div>

          <!--Tags-->
          <div *ngIf="tagAttributeDependency != undefined">
            <div>
                <h5 class="text-center">Tag Classifications</h5>
                <div>
                    <!--{{tagDependency.TagClassification.className}}-->
                </div>
                <br />
                <div *ngFor="let tagAttribute of tagAttributeDependency">
                    <li>
                        <label>{{tagAttribute.className}}</label>
                    </li>
                </div>
            </div>
        </div>

          <!--CheckSheets-->
          <!--<div *ngIf="checkSheetDependency != undefined">
              <div>
                  <h5 class="text-center">CheckSheets</h5>
                  <div>
                      {{checkSheetDependency.projectName}} ({{checkSheetDependency.CheckSheets != undefined? checkSheetDependency.CheckSheets.length: 0}})
                  </div>
                  <br />
                  <div *ngFor="let dependency of checkSheetDependency.CheckSheets">
                      <li>
                          <label>{{dependency.checkSheetName}}</label>
                      </li>
                  </div>
              </div>
          </div>-->

          <!--IRN-->
          <!--<div *ngIf="irnDependency != undefined">
              <div>
                  <h5 class="text-center">IRN</h5>
                  <div>
                      {{irnDependency.projectName}} ({{irnDependency.IRNs != undefined? irnDependency.IRNs.length: 0}})
                  </div>
                  <br />
                  <div *ngFor="let dependency of irnDependency.IRNs">
                      <li>
                          <label>{{dependency.dependencyName}}</label>
                      </li>
                  </div>
              </div>
          </div>-->

          <!--Asset Parents-->
          <!--<div *ngIf="assetParentDependency != undefined">
              <div>
                  <h5 class="text-center">Asset Parents</h5>
                  <div>
                      {{assetParentDependency.projectName}} ({{assetParentDependency.Topic != undefined? assetParentDependency.Topic.length: 0}})
                  </div>
                  <br />
                  <div *ngFor="let dependency of assetParentDependency.Topic">
                      <li>
                          <label>{{dependency.topicName}} ({{dependency.referenceCount}}) [{{dependency.topicPath}}]
                              - {{dependency.checkedOutUser}}</label>
                      </li>
                  </div>
              </div>
          </div>-->

          <!--Asset Attributes-->
          <!--<div *ngIf="assetAttrDependency != undefined">
              <div>
                  <h5 class="text-center">Asset Attributes</h5>
                  <div>
                      {{assetAttrDependency.projectName}} ({{assetAttrDependency.AssetParents != undefined? assetAttrDependency.AssetParents.length: 0}})
                  </div>
                  <br />
                  <div *ngFor="let dependency of assetAttrDependency.AssetParents">
                      <li>
                          <label>{{dependency.dependencyName}}</label>
                      </li>
                  </div>
              </div>
          </div>-->

          <!--Punch list-->
          <!--<div *ngIf="punchListDependency != undefined">
              <div>
                  <h5 class="text-center">Punch List</h5>
                  <div>
                      {{punchListDependency.projectName}} ({{punchListDependency.Punchlists != undefined? punchListDependency.Punchlists.length: 0}})
                  </div>
                  <br />
                  <div *ngFor="let dependency of punchListDependency.Punchlists">
                      <li>
                          <label>{{dependency.punchlistDescrip}}</label>
                      </li>
                  </div>
              </div>
          </div>-->

          <!--Comm Work-->
          <!--<div *ngIf="commWorkItemDependency != undefined">
              <div>
                  <h5 class="text-center">Comm Work</h5>
                  <div>
                      {{commWorkItemDependency.projectName}} ({{commWorkItemDependency.WorkItems != undefined? commWorkItemDependency.WorkItems.length: 0}})
                  </div>
                  <br />
                  <div *ngFor="let dependency of commWorkItemDependency.WorkItems">
                      <li>
                          <label>{{dependency.dependencyName}}</label>
                      </li>
                  </div>
              </div>
          </div>-->

      </div>
      <div class="modal-footer">
          <button type="button" class="btn btn-warning" (click)="bsModalRef.hide()">{{'OK' | translate}}</button>
      </div>
  </div>