<div *ngIf="fileData!=null">
	<div *ngFor="let attibute of fileData.attributes">
		<div *ngIf="attibute.attributeType != 'null'"> 
		<br>
		<table>
			<th></th>
			<th></th>
			<tr>
				<td width="100">
					<label>{{attibute.attributeName}}
						<sup>
							<i *ngIf="attibute.required=='yes'" class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i>
						</sup>
					</label>
				</td>
				<td width="400" >
					<input type="text"  *ngIf="(attibute.attributeType=='Number' || attibute.attributeType=='Text') && fileData.editable=='Yes'" name ="attribute" #texts [(ngModel)]="attibute.value" (change)="changeAttribute($event.target.value,attibute.attributeId)" style="width:100%">
					<label *ngIf="(attibute.attributeType=='Number' || attibute.attributeType=='Text') && fileData.editable!='Yes'">{{attibute.value}}</label>
					<input type="date"  *ngIf="attibute.attributeType == 'Date' && fileData.editable=='Yes'" name ="attribute" [(ngModel)]="attibute.value" (change)="changeAttribute($event.target.value,attibute.attributeId)" style="width:100%">
					<label  *ngIf="attibute.attributeType == 'Date' && fileData.editable!='Yes'">{{attibute.value}}</label>
					<div *ngIf= "checkDocument(attibute.attributeType,attibute.attributeName)">
					<select *ngIf="!(attibute.attributeType=='Number' || attibute.attributeType=='Text')&& fileData.editable=='Yes'" #options name ="attribute" [(ngModel)]="attibute.value" (change)="changeAttribute($event.target.value,attibute.attributeId)" style="width:100%">
						
						<!--div>{{log(attibute.attributeType)}}</!--div-->
						<option *ngFor="let item of attibute.documents" [ngValue]="item.id">
							{{item.documentName}}
						</option>
					</select>
					</div>
					<div *ngIf= "checkComponent(attibute.attributeType,attibute.attributeName)">
						<select *ngIf="!(attibute.attributeType=='Number' || attibute.attributeType=='Text')&& fileData.editable=='Yes'" #options name ="attribute" [(ngModel)]="attibute.value" (change)="changeAttribute($event.target.value,attibute.attributeId)" style="width:100%">
							<option *ngFor="let item of attibute.components" [ngValue]="item.id">
								{{item.componentName}}
							</option>
						</select>
						</div>
						<div *ngIf= "checkSystem(attibute.attributeType,attibute.attributeName)">
							<select *ngIf="!(attibute.attributeType=='Number' || attibute.attributeType=='Text')&& fileData.editable=='Yes'" #options name ="attribute" [(ngModel)]="attibute.value" (change)="changeAttribute($event.target.value,attibute.attributeId)" style="width:100%">
								<option *ngFor="let item of attibute.systems" [ngValue]="item.systemId">
									{{item.systemName}}
								</option>
							</select>
							</div>
					<label *ngIf="!(attibute.attributeType=='Number' || attibute.attributeType=='Text') && fileData.editable!='Yes'">
						{{processAttribute(attibute.value, attibute.items)}}
					</label>
				</td>
			</tr>
		</table>
		</div>
	</div>
</div>