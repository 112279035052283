import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingService } from '../loading/loading.service';


@Injectable({
    providedIn: 'root'
})
export class CommonUtil {


	constructor(private router: Router, private loadingService: LoadingService) { }


	/**
	 * check userId and projectId in token
	 * @param token 
	 * @param fwd 
	 */
	public validateToken(token: any, fwd = true): boolean{
		if(!fwd){
			console.log('forward '+ fwd)
		}
		if(token == null){
			//user not logged in
			if(fwd){
				//navigate to login page
				console.log("navigate to login")
				this.router.navigateByUrl('login');
			}
			return false
			
		}else{
			let tokenData = JSON.parse(atob(token));
			if(tokenData.userId == ""){
				//user not logged in
				if(fwd){
					//navigate to login page
					console.log("navigate to login");
					this.router.navigateByUrl('login');
				}
				return false
			}else if(tokenData.projectId == ""){ 
				let pathName: string = window.location.pathname.replace('/', '');
				if(pathName == "adminSettings" || pathName == "landing"){
					return true;
				}
				//no selected project
				if(fwd){
					//navigate to landing page
					console.log("navigate to landing");
					this.router.navigateByUrl('landing');
				}
				return false
			}	
		}
		return true;		
	}


	public validateLogin(token: any, fwd = true): boolean{
		if(token == null){
			if(fwd){
				console.log("navigate to login");
				this.router.navigateByUrl('login');
			}
			return false
		}else{
			let tokenData = JSON.parse(atob(token));
			if(tokenData.userId == ""){
				if(fwd){
					console.log("navigate to login");
					this.router.navigateByUrl('login');
				}
				return false
			}
			return true
		}	
	}

	public encodeSpecialChars(content: String){
		if(content != null){
			content = content.replace(/&/g, "&amp;");
			content = content.replace(/\\/g, "\\\\");
			//content = content.replace(/\//g, "&sol;");
			content = content.replace(/\"/g, "&quot;");
			content = content.replace(/\'/g, "&apos;");
			content = content.replace(/</g, "&lt;");
			content = content.replace(/>/g, "&gt;");
		}
		return content;
	}

	public decodeSpecialChars(content: String){
		if(content != null){
			content = content.replace(/&bsol;/g, "\\");
			content = content.replace(/&sol;/g, "\/");
			content = content.replace(/&quot;/g, "\"");
			content = content.replace(/&apos;/g, "\'");
			content = content.replace(/&lt;/g, "<");
			content = content.replace(/&gt;/g, ">");
			content = content.replace(/&amp;/g, "&");
		}
		return content;
	}

}
