<div [ngClass]="hide ? 'hide' : ''" >
  <div class="modal-header py-2">
    <h5 *ngIf="!edit" class="modal-title">
      {{ 'IRN_ADD_EDIT.ADD_IRN' | translate }}
    </h5>
    <h5 *ngIf="edit" class="modal-title">
      {{ 'IRN_ADD_EDIT.EDIT_IRN' | translate }}
    </h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="container-fluid mt-3 mb-4 modal-container" id ="irnaddedit" (click)="consoleClick()">
    <form >
      <fieldset [disabled]="disableform">
        <div class="row my-2">
          <div class="col-sm-6">
            <div class="form-group mb-2">
              <div class="mb-2">
                <label for="facilityName" class="mb-0">{{ 'IRN_ADD_EDIT.IRN_NAME' | translate }}</label>
                <div class="badge-required mx-2"></div>
                <input *ngIf="edit" id="irn_edit_name" type="text" class="form-control form-control-sm"
                       id="IRNNameedit" name="IRNNameedit" [(ngModel)]="irn.irnNumber" maxlength="50" >
                <input #newNumber *ngIf="!edit" id="facility_add_name" type="text"
                       class="form-control form-control-sm" id="facilityName" maxlength="50" >
              </div>
              <div class="mt-4 dropdown-100 ">
                <div class="form-group mb-2">
                  <label class="">{{ 'IRN_ADD_EDIT.IRN_VENDOR' | translate }}
                  </label>
                  <ng-select class="select-more-height" #vendor (dropdownOpened)="closeOtherElems(vendor)"
                             [active]="selectedVendor" [allowClear]="true" [items]="vendorList"
                             placeholder="{{ 'IRN_ADD_EDIT.SELECT_IRN_VENDOR' | translate }}"
                             [disabled]="disableform"
                             Name="subsystem" id="vendorId" (selected)="selectVendor($event)"
                             (removed)="removedVendor($event)">
                  </ng-select>
                </div>
              </div>
              <div class="mt-4 dropdown-100 mb-1">
                <div class="form-group mb-3">
                  <label for="addirn_assets" class="mb-1">{{ 'IRN_ADD_EDIT.IRN_ASSETS' | translate }}</label>
                  <div class="badge-required mx-2"></div>
                  <tag-input name="addirn_assets" id="addirn_assets" [(ngModel)]='selectedAssetList'
                             [disable]="disableAssets || disableform"
                             [removable]='true'
                             secondaryPlaceholder="{{'IRN_ADD_EDIT.SELECT_IRN_ASSETS' | translate}}"
                             placeholder="{{'IRN_ADD_EDIT.SELECT_IRN_ASSETS' | translate}}"
                             [onlyFromAutocomplete]="true" (onAdd)="selectItemFromSuggestions($event, 'system')"
                             (onRemove)="removeSelectedItem($event, 'system')" [clearOnBlur]="true">
                    <tag-input-dropdown [showDropdownIfEmpty]="true"
                                        [keepOpen]="false" [autocompleteItems]="assetList"
                                        [limitItemsTo]=20 [appendToBody]="false"></tag-input-dropdown>
                  </tag-input>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group mb-1">
              <label for="facilityName" class="mb-0">{{ 'IRN_ADD_EDIT.IRN_DESCRIPTION' | translate }}</label>
              <div class="badge-required mx-2"></div>
              <textarea *ngIf="edit"  type="text"  rows=""
                        class="form-control form-control-sm" id="addirn_descriptionedit" name="addtags_description" [(ngModel)]="irn.description" maxlength="500"
              ></textarea>
              <textarea *ngIf="!edit" type="text" #newDesc rows=""
                        class="form-control form-control-sm" id="addnewirn_description" name="addtags_description" maxlength="500"
              ></textarea>
            </div>
  <!--          new file uploader-->
            <div class="form-group files mb-1">
              <label class="">{{ 'IRN_ADD_EDIT.UPLOAD_DOCUMENTS' | translate }}</label>
              <input type="file" class="form-control  custom-b  form-control-file"
                     Name="profile" id="NewFile_FileUpload" accept=".pdf"
                     (change)="onFileChanged($event)" required>
            </div>
            <div class="row justify-content-between">
              <div class="col-sm-6">
                <label class="px-2 " *ngIf="irnDocumentUpload">
                  <mark id="newfilename"
                        class="form-text text-muted pl-2 pr-2 rounded">{{ irnDocumentUpload.file.name}}
                  </mark>
                </label>
              </div>
              <div class="col-sm-6 rightalignItem">
                <button *ngIf="irn.isUploaded"  type="submit"  class="btn btn-primary btn-sm float-right mt-1"
                        (click)="downloadIrn()" >{{ 'IRN_ADD_EDIT.UPLOADED_DOCUMENT_VIEW' | translate}}</button>
<!--                <label *ngIf="irn.isUploaded" (click)="downloadIrn()" class="cursor" >-->
<!--                  <mark id="filename"-->
<!--                        class="form-text text-muted pl-2 pr-2" >{{ 'IRN_ADD_EDIT.UPLOADED_DOCUMENT_VIEW' | translate}}-->
<!--                  </mark>-->
<!--                </label>-->
              </div>
            </div>

          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-6">
            <div class="form-group mb-2">
              <label for="irnStatus" class="mb-1">{{ 'IRN_ADD_EDIT.IRN_STATUS' | translate}}</label>
              <ng-select class="select-more-height" #irnStatus (dropdownOpened)="closeOtherElems(irnStatus)"
                      [active]="selectedIRNStatusId" [allowClear]="true" [items]="irnStatusList"
                      placeholder="{{ 'IRN_ADD_EDIT.SELECT_IRN_STATUS' | translate }}"
                      [disabled]="disableform" 
                      Name="irnStatus" id="irnStatus" (selected)="selectIRNStatus($event)"
                      (removed)="removedIRNStatus($event)">
              </ng-select>
            </div>
          </div>
          <div class="col-sm-6">
            <!-- <div class="form-group mb-2">
              <label for="targetCompletionDate" class="mb-1">{{ 'IRN_ADD_EDIT.IRN_TARGET_COMPLETION_DATE' | translate}} <div *ngIf="selectedIRNStatusText=='Approved'" class="badge-required mx-2">
              </div></label>
              <input type="text" placeholder="Select target completion date" class="form-control" name="date_raised"
                     [(ngModel)]="targetCompletionDate" [bsConfig]="bsConfig" placement="bottom" bsDatepicker>
            </div> -->
            <div class="form-group mb-2">
              <label for="approvedDate" class="mb-1">{{ 'IRN_ADD_EDIT.IRN_APPROVED_DATE' | translate}} <div *ngIf="selectedIRNStatusText=='Approved'" class="badge-required mx-2">
              </div></label>
              <input type="text" placeholder="Select approved date" class="form-control" name="date_approved" [disabled]="approvedDateDisabled"
                     [(ngModel)]="approvedDate" [bsConfig]="bsConfig" placement="bottom" bsDatepicker>
            </div>
          </div>
        </div>
        <!-- <div class="row mb-2">
          <div class="col-sm-6">
            <div class="form-group mb-2">
              <label for="approvedDate" class="mb-1">{{ 'IRN_ADD_EDIT.IRN_APPROVED_DATE' | translate}} <div *ngIf="selectedIRNStatusText=='Approved'" class="badge-required mx-2">
              </div></label>
              <input type="text" placeholder="Select approved date" class="form-control" name="date_approved" [disabled]="approvedDateDisabled"
                     [(ngModel)]="approvedDate" [bsConfig]="bsConfig" placement="bottom" bsDatepicker>
            </div>
          </div>
        </div> -->
        
        <div class="row my-2">
          <!--Systems-->

  <!--        <div class="col-sm-6 dropdown-100">-->
  <!--          <div class="form-group mb-2">-->
  <!--            <label class="mb-0" for="subsystemId" >{{ 'IRN_ADD_EDIT.IRN_SYSTEM' | translate }}-->
  <!--            </label>-->
  <!--            <ng-select class="select-more-height" #system (dropdownOpened)="closeOtherElems(system)" [active]="selectedSystem"-->
  <!--                       [allowClear]="true" [items]="systemList"-->
  <!--                       placeholder="{{ 'IRN_ADD_EDIT.SELECT_IRN_SYSTEM' | translate }}" [disabled]="disableSystems || disableform"-->
  <!--                       Name="subsystem" id="subsystemId" (selected)="selectSystem($event)"-->
  <!--                       (removed)="removedSystem($event)">-->
  <!--            </ng-select>-->
  <!--          </div>-->
  <!--        </div>-->
        </div>

        <div *ngIf="!edit"  class="row my-2">
          <div class="col-lg-12 col-12">
            <button type="submit" id="facility_save" class="btn btn-primary btn-sm float-left"
                    (click)="save()">{{ 'IRN_ADD_EDIT.SAVE' | translate}}</button>
            <label class="ml-4"><small id="emailHelp"
                          class="form-text text-muted">{{ 'IRN_ADD_EDIT.IRN_SAVE_TIP' | translate}}</small>
              </label>
          </div>
        </div>

  <!--    </fieldset>-->
        <div >
          <div class="row my-2 justify-content-start">
            <div class="col-4">
              <label class="mb-0 mt-1 mr-4 p-0">Punch Lists</label>
              <button *ngIf="(this.selectedSystem || this.selectedAssetList.length>0) && this.irn.irnId!=null" type="button" id="facility_cancel" class="btn btn-primary btn-sm "
                      (click)="addPunchList()">{{ 'IRN_ADD_EDIT.ADD_PUNCHLIST' | translate}}</button>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-12 ">
              <ag-grid-angular #agGrid style="width: 100%; height: 200px;" class="ag-theme-balham" [rowData]="rowData" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }"
                               [columnDefs]="columnDefs" [gridOptions]="gridOptions" [pagination]="true"
                               [paginationPageSize]="10"
                               [enableFilter]="true"
                               (gridReady)="onGridReady($event)" (cellClicked)='onDelete($event)'>
              </ag-grid-angular>
            </div>
          </div>
        </div>
      </fieldset>
    </form>
  </div>
  <div class="modal-footer">
    <button *ngIf="edit && ! disableform" type="submit" id="irn_update" class="btn btn-primary btn-sm float-left"
            (click)="save()">{{ 'IRN_ADD_EDIT.UPDATE' | translate}}</button>
    <button type="button" id="irn_cancel" class="btn btn-secondary btn-sm float-left"
            (click)="cancel()">{{ 'CANCEL' | translate}}</button>
  </div>
</div>
