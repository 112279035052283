import { ChangeDetectorRef, Component, HostListener, Injectable, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ColDef, Module, AllModules } from "@ag-grid-enterprise/all-modules";
import { AgGridAngular } from '@ag-grid-community/angular';
import * as FileSaver from 'file-saver';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { UserVariable } from 'src/app/util/common/user-variable';
import { DependenciesmodalComponent } from 'src/app/util/dependenciesmodal/dependenciesmodal.component';
import { FolderService } from '../../foldertree/foldertree.service';
import { AlertService } from '../../util/alert/alert.service';
import { CapabilityService } from '../../util/capability.service';
import { Constants } from '../../util/constants';
import { LoadingService } from '../../util/loading/loading.service';
import { LocalStorage } from '../../util/localstorage.service';
import { SharedService } from '../../util/shared.service';
import { WsCallback } from '../../util/ws-callback.interface';
import { WsResponse } from '../../util/ws-response.model';
import { WsType } from '../../util/ws-type';
import { FilesService } from '../files/files.service';
import { OutputGenerateModalComponent } from '../files/outputgenerator/outputgeneratemodal.component';
import { FileModel } from './file.model';
import { FilesCellRenderComponent } from './files-cell-render-component.component';
import { FilesDataService } from './filesdata/filesdata.service';
import { FloatingButtonCallback } from './floating-button-callback.interface';
import { NewCourseComponent } from './new-course/new-course.component';
import { FileUploadService } from './new-file/file-upload.service';
import { NewFileComponent } from './new-file/new-file.component';
import { NewImageComponent } from './new-image/new-image.component';
import { NewMultipleImagesComponent } from './new-multiple-images/new-multiple-images.component';
import { NewProcedureComponent } from './new-procedure/new-procedure.component';
import { NewThreedModelComponent } from './new-threed-model/new-threed-model.component';
import { NewTopicMapComponent } from './new-topic-map/new-topic-map.component';
import { NewTopicComponent } from './new-topic/new-topic.component';
import { NewVideoComponent } from './new-video/new-video.component';
import { ThreedModelViewerComponent} from 'src/app/util/threed-model-viewer/threed-model-viewer.component';
import { CommonUtil } from '../../util/common/common-util';
import { PdfToProcedurestoreComponent } from './outputgenerator/pdf-to-procedurestore/pdf-to-procedurestore.component';
import { OPGConfigInputsViewModalComponent } from './outputgenerator/ouputconfiginputsviewmodal.component';
import { DependenciesModalService } from 'src/app/util/dependenciesmodal/dependenciesmodal.service';
declare var $: any;

@Component({
	selector: 'app-files',
	templateUrl: './files.component.html',
	styleUrls: ['./files.component.css']
})

@Injectable({
	providedIn: 'root'
})

export class FilesComponent implements OnInit, FloatingButtonCallback, OnDestroy, WsCallback {

	@ViewChild('agGrid') agGrid: AgGridAngular;
	@ViewChild('checkingTemplate') checkingTemplate: TemplateRef<any>
	@ViewChild('checkinWarningTemplate') checkinWarningTemplate: TemplateRef<any>
	@ViewChild('checkinCouseWarningTemplate') checkinCouseWarningTemplate: TemplateRef<any>
	@ViewChild('checkinAllWarningTemplate') checkinAllWarningTemplate: TemplateRef<any>
	@ViewChild('contentTemplate') contentTemplate: TemplateRef<any>
	@ViewChild('revisionTemplate') revisionTemplate: TemplateRef<any>
	@ViewChild('checkinMultipleImagesTemplate') checkinMultipleImagesTemplate: TemplateRef<any>
	@ViewChild('alertToShowMultipleActions') alertToShowMultipleActions: TemplateRef<any>
	@ViewChild('imageCheckInTemplate') imageCheckInTemplate: TemplateRef<any>
	@ViewChild('fileCheckInTemplate') fileCheckInTemplate: TemplateRef<any>
	@ViewChild('viewImageTemplate') viewImageTemplate: TemplateRef<any>
	@ViewChild('multipleActionConfirmationTemplate') multipleActionConfirmationTemplate: TemplateRef<any>
	@ViewChild('threeDModelCheckInTemplate') threeDModelCheckInTemplate: TemplateRef<any>
	@ViewChild('viewThreeDModelTemplate') viewThreeDModelTemplate: TemplateRef<any>
	@ViewChild('videoCheckInTemplate') videoCheckInTemplate: TemplateRef<any>
	@ViewChild('viewVideoTemplate') viewVideoTemplate: TemplateRef<any>
	@ViewChild('threedmodelDepTemplate') threedmodelDepTemplate: TemplateRef<any>
	@ViewChild('threedmodelViewWarnTemplate') threedmodelViewWarnTemplate: TemplateRef<any>
	@ViewChild('restoreRevision3DModelTemplate') restoreRevision3DModelTemplate: TemplateRef<any>
	@ViewChild('undo_checkout_warn') undo_checkout_warn: TemplateRef<any>;
	undocheckoutWarningModalRef: BsModalRef;
	public filteredData: Array<any> = [];
	public openedTabs: Array<any> = [];
	public showDetailPanel: boolean = false;
	public selectedItemData: any;
	selectedCount: number = 0;
	private gridApi;
	public getRowNodeId;
	private gridColumnApi;
	public inputData: any;
	public path: String;
	private fileModal: BsModalRef;
	private modalTitle: string;
	public columnDefs: any;
	public defaultColDef: any;
	public fileName: string;
	public fileType: string;
	public checkOutBy: string;
	public lastUpdated: string;
	public hasText: string = "";
	public rowData: any = [];
	public bsModalRef: BsModalRef;
	public selectedFile: any = null;
	public prevSelectedNodesCount: number = null;
	public multipleSelections = false;
	public selectedOutputType: Array<any>;
	public enableGenerateOutput: boolean;
	//public selectedTemplateName: Array<any>;
	public outputData: any[] = [];
	public modules: Module[] = AllModules;
	public outputType: string = 'PDF';
	public openSelectedFolder: boolean = false;
	// Capabilities
	public modifyCap: boolean = false;
	public queryCap: boolean = false;
	public deleteCap: boolean = false;
	public disableCap: boolean = false;
	public createCap: boolean = false;

	private selectedNodes: number;

	//enable disable buttons
	public enableCheckIn: boolean = false
	public enableCheckOut: boolean = false
	public enableStatus: boolean = false
	public enableUndo: boolean = false
	public enableDelete: boolean = false
	public enableGenerateOP: boolean = false
	public enableDuplicate: boolean = false
	public enableWorkFlow: boolean = false
	public enableEdit: boolean = false
	public enableRevision: boolean = false
	public enableProperties: boolean = false
	public enableView: boolean = false
	public enableMoveFile: boolean = false
	public enableAddToProcStore: boolean = false
	public enableAddToDocumentStore: boolean = false


	public showTopicMap: boolean = false
	public showDonload: boolean = false
	public showGenerateOP: boolean = false
	public showWorkFlow: boolean = false
	public selectedOpenedItems: Array<any> = [];
	public isFileActive: boolean = true
	public activeBtnToolTip: string = ''
	private selectedFileForInfo: any = null
	public activeId: String = "properties";

	public divHeight: number = (window.innerHeight) * 5 / 7
	rowSelection: string;
	folderId: any;
	title: any;

	// private modalRef: BsModalRef
	public uploadFileName: string;
	public fileComment: string;
	public headings = [];
	public itemStatus: number[][] = [];
	private items: string[][] = [];
	private uploadingFile: File;
	private dependencyData: any = null;
	private checkInData = [];
	private deleteUpdateSubscription: any
	private checkboxValue: boolean = false; //used for dependancies
	public showPropertiesPanel: boolean = false
	public isMinimized: boolean = false;
	public disable: boolean = false;
	public multipleConfirmMessage: string;
	private uploadingVideoFile:File[] = []
	//Image Check-in popup
	public uploadFileExt: string;
	public fileTitle: string;
	public attributeList: any;
	public documentType: string;
	public haveProperties: boolean = true;

	//File check in popup
	public fileCheckInTitle: string;

	//undo checkout model confirm pop up file type
	public fileTypeUndoCheckout: string; 

	private subsVar: Subscription
	private folderSubVar: Subscription
	private enableDisableSubscription: Subscription;
	//image view
	base64FullImage: any = null;
	isLoadedImage: boolean = false;
	viewModalRef: BsModalRef;
	previousFolder: any;
	fileExtension: string;
	fileCheckInTitle2: string;
	public addFileShow: boolean = false;
	public allImages:boolean = false;
	public all3Dmodels:boolean = false;
	public allVideos:boolean = false;

	//threedModelViewerComponent: ThreedModelViewerComponent;
// threed model
  	url:String
//   threeDViewModalRef: BsModalRef;
	isLoadedModel: boolean = false;
	modalRef: BsModalRef;
	modelMissingViews: Array<any> = [];
	modelChangedViews: Array<any> = [];
	modelInfo: Array<any> = [];

  //video 
  	selectedVideoTitle:String
	selectedThreeDModels: string;
	checkingThreeDModelComment: any;
	threeDModels:File[] = [];
	videoFiles:File[] = [];
	selectedVideos:string;
	checkingImagesComment: any;
	selectedImages: string;
	imageFiles: File[] = [];
	public selectedVideoPosterframeURL: any;
    posterframe: File;
    thumbnail: File;

	@HostListener('window:resize', ['$event'])
	onresize(event?) {
		this.divHeight = (window.innerHeight) * 5 / 7
	}

	@Input() data: any;
	public deviceType: string = localStorage.getItem(LocalStorage.DEVICE_TYPE)
	public triggersType: string = 'hover'

	constructor(private filesService: FilesService, private alertService: AlertService, private folderService: FolderService,
		private loadingService: LoadingService, private changeDetectorRef: ChangeDetectorRef, private translate: TranslateService,
		private modalService: BsModalService, private sharedService: SharedService, private filesDataService: FilesDataService,
		private fileUploadService: FileUploadService, private capabilityService: CapabilityService, private commonUtil: CommonUtil,
		private dependenciesmodalService: DependenciesModalService) {
		this.enableGenerateOutput = this.capabilityService.isCapabilityAssigned("generateOutput")
		translate.addLangs(["es", "en"]);
		translate.setDefaultLang("en");
		let browserLang = translate.getBrowserLang();

		translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
		this.activeBtnToolTip = this.translate.instant('FILES_DATA.ACTIVE_AND_INACTIVE');
		this.translate.get('FILES_VIEW.FILE_NAME').subscribe((res: string) => {
			this.fileName = res;
			this.translate.get('FILES_VIEW.CHECKOUT_BY').subscribe((res: string) => {
				this.checkOutBy = res;
				this.translate.get('FILES_VIEW.LAST_UPDATED').subscribe((res: string) => {
					this.lastUpdated = res;

				});
			});
		});
		this.columnDefs = [
			{ headerName: 'Select', field: 'cbox', width: 50, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
			{ headerName: 'Icon', cellClass: 'text-center', field: "type", width: 50, cellRendererFramework: FilesCellRenderComponent },
			{ headerName: 'File Name', field: "fileName", width: 500, cellRendererFramework: FilesCellRenderComponent },
			{ headerName: 'Checkout By', field: "checkoutBy", width: 200 },
			{ headerName: 'Last Update TimeStamp', field: "lastUpdateTimeStamp", comparator: this.dateTimeComparator }
		];
		this.rowSelection = "multiple";
		this.defaultColDef = {
			resizable: true,
			sortable: true,
		}
		filesService.setCallback(this);
		filesDataService.setCallback(this);
		this.getRowNodeId = function (data) {
			return data.id;
		};

		let selectedFolderData = this.folderService.getSelectedFoldeData();
		if (selectedFolderData != null && selectedFolderData != undefined) {
			this.folderId = selectedFolderData.id;
			localStorage.setItem(LocalStorage.FOLDER_ID, this.folderId);
			var re = /(\/)/gi;
			let index = selectedFolderData.original.path.indexOf('/');
			let originalPath = selectedFolderData.original.path.substring(index + 1);
			let originalIndex = originalPath.indexOf('/');
			this.path = originalPath.substring(originalIndex + 1).replace(re, ' / ');
		}

		this.sharedService.showDetailPanelExecute().subscribe(option => this.updateDetailPanel(option));
		this.sharedService.selectFolderItem.subscribe(
			data => {
				// console.log("this.folderId= "+ data.tabTitle )
				if (this.folderId != this.previousFolder) {
					this.previousFolder = this.folderId
					this.resetenableDisableButtons()
				}
			})
	}

	dateTimeComparator(date1:any, date2:any) {
		let parts = date1.match(/(\d{1,2})\/(\d{1,2})\/(\d{4}), (\d{1,2}):(\d{2}):(\d{2})/);
		if(date1.substring(date1.length-2)=="PM" && parts[4] != 12){
			parts[4] = (parseInt(parts[4])+12).toString();
		}
		let date1Number = Date.UTC(+parts[3], parts[1]-1, +parts[2], +parts[4], +parts[5], +parts[6]);
		parts = date2.match(/(\d{1,2})\/(\d{1,2})\/(\d{4}), (\d{1,2}):(\d{2}):(\d{2})/);
		if(date2.substring(date2.length-2)=="PM" && parts[4] != 12){
			parts[4] = (parseInt(parts[4])+12).toString();
		}
		let date2Number = Date.UTC(+parts[3], parts[1]-1, +parts[2], +parts[4], +parts[5], +parts[6]);
	  
		if (date1Number === null && date2Number === null) {
		  return 0;
		}
		if (date1Number === null) {
		  return -1;   
		}
		if (date2Number === null) {
		  return 1;
		}
	  
		return date1Number - date2Number;
	}

	onGridReady(params) {
		const allColumnIds = [];
		this.columnDefs.forEach((columnDef) => {
			allColumnIds.push(<ColDef>columnDef);
		});
		this.gridColumnApi = params.columnApi;
		this.gridColumnApi.autoSizeColumns(allColumnIds);
		this.gridApi = params.api;
		params.api.sizeColumnsToFit();
		this.filesService.setApiGridData(this.gridApi);
		window.addEventListener("resize", this.onWindowResize);
	}

	private onWindowResize = () => {
        // Wrap sizeColumnsToFit in a setTimeout to ensure it's called after the resize event has finished
        setTimeout(() => {
            if (this.gridApi) {
                this.gridApi.sizeColumnsToFit();
            }
        }); // Adjust the delay as needed
    };

	onRowSelected(event) {
		this.selectedItemData = undefined;
		let rowSelected: boolean = event.node.selected;
		this.disable = true;
		//console.log("hhhh")
		this.selectedNodes = this.agGrid.api.getSelectedNodes().length;
		console.log("onRowSelected | No. of nodes: " + this.selectedNodes);
		let nodes = "";
		localStorage.setItem(localStorage.SELECTED_ITEMS, "");
		for (let i = 0; i < this.selectedNodes; i++) {
			nodes = nodes + this.agGrid.api.getSelectedNodes()[i].data.type.split(':')[0] + '|' + this.agGrid.api.getSelectedNodes()[i].data.id + '::';
		}
		localStorage.setItem(localStorage.SELECTED_ITEMS, nodes);
		let type: any;
		if (rowSelected && (this.selectedCount < this.selectedNodes)) {
			//if (rowSelected && (this.selectedCount < selectedNodes || selectedNodes == 1) && (selectedNodes != this.filteredData.length || (selectedNodes == this.selectedCount + 1))) {
			this.showDetailPanel = false;
			this.filesService.setCallback(this);
			this.selectedItemData = event.data;
			this.inputData = event.data;
			//this.loadingService.showLoading(true, false, "File Data Loading", 0);
			type = this.selectedItemData.type.split(':')[0];
			this.selectedVideoTitle = this.selectedItemData.name;
			console.log("title :"+ this.selectedVideoTitle );
			/*if (type == 'Folder') {
				$('#foldertree').jstree('deselect_all')
				$("#foldertree").jstree("select_node", this.selectedItemData.id);
			} else {
				//	this.filesService.setApiGridData(this.gridApi);
				this.filesService.setShowProperties(false);
				this.showDetailPanel = true;
				console.log(type + ' is clicked');
				if (type == 'ICECourse') {
					this.filesService.getICECourse(this.selectedItemData.id, this);
				} else {
					this.filesService.getFileDataByFileId(this.selectedItemData, this);
				}
							
			}*/
		}
		this.onSelectionChanged(rowSelected, type);
	}

	onlastChildChanged(params) {
		//console.log("hhk jjj nnn")
		//not implemented yet
	}

	onCellClicked(event: any){
		if(event.colDef.field != "cbox"){
			this.openSelectedFolder = true;
		}
	}

	onSelectionChanged(rowSelected: boolean, type: any) {
		console.log("[onSelectionChanged] | Row is selected or not: " + rowSelected);
		var selectedRows = this.gridApi.getSelectedRows();
		if (selectedRows.length > 1) {
			this.multipleSelections = true;
			this.allImages = false;
			this.allVideos = false;
			this.all3Dmodels = false;
			for (let i = 0; i < this.selectedNodes; i++) {
				console.log("Type : " +this.agGrid.api.getSelectedNodes()[i].data.type.split(':')[0]);

				let type = this.agGrid.api.getSelectedNodes()[i].data.type.split(':')[0];

				if (type == "Image") {
					this.allImages = true;
				} else {
					this.allImages = false;
				}
				if (type == "Video") {
					this.allVideos = true;
				} else {
					this.allVideos = false;
				}
				if (type == "ThreeDModel") {
					this.all3Dmodels = true;
				} else {
					this.all3Dmodels = false;
				}
			}
		} else if (selectedRows.length == 1) {
			this.resetenableDisableButtons();
			this.multipleSelections = false;
			this.sharedService.setCheckoutUserId(this.agGrid.api.getSelectedNodes()[0].data.checkoutUserId);
			//console.log(this.agGrid.api.getSelectedNodes()[0].data.checkoutUserId); 
			if (!rowSelected && this.prevSelectedNodesCount > 1) {
				let singleNodeData = this.agGrid.api.getSelectedNodes()[0].data;
				let type = singleNodeData.type.split(':')[0];
				if (type == 'ICECourse') {
					this.filesService.getICECourse(singleNodeData.id, this);
				} else if(type == 'Folder'){
					this.folderSelected(singleNodeData)
				} else {
					this.filesService.getFileDataByFileId(singleNodeData, this);
				}
			} else if (rowSelected) {
				if (type == 'Folder') {
					if(this.openSelectedFolder){
						let status = this.selectedItemData.type.split(',')[1];
						if (status == "Active") {
							$('#foldertree').jstree('deselect_all')
							$("#foldertree").jstree("select_node", this.selectedItemData.id);
							this.openSelectedFolder = false
						}
					} else {
						this.folderSelected(this.selectedItemData)
					}
				}
				else {
					this.filesService.setShowProperties(false);
					this.showDetailPanel = true;
					console.log(type + ' is clicked');
					if (type == 'ICECourse') {
						this.filesService.getICECourse(this.selectedItemData.id, this);
					} else {
						this.filesService.getFileDataByFileId(this.selectedItemData, this);
					}
				}
			}
			else {
				this.resetenableDisableButtons();
			}
		} else {
			this.resetenableDisableButtons();
			this.enableProperties = false;
			this.enableProperties = false;
			this.multipleSelections = false;
		}
		const selectedNodes = this.agGrid.api.getSelectedNodes().length;
		this.prevSelectedNodesCount = this.selectedNodes;
		let nodes = "";
		localStorage.setItem(LocalStorage.SELECTED_ITEMS, "");
		for (let i = 0; i < selectedNodes; i++) {
			nodes = nodes + this.agGrid.api.getSelectedNodes()[i].data.type.split(':')[0] + '|' + this.agGrid.api.getSelectedNodes()[i].data.id + '::';
		}
		localStorage.setItem(LocalStorage.SELECTED_ITEMS, nodes);

		//my code
		var selectedRows = this.gridApi.getSelectedRows();
		// console.log(selectedRows)
		var selectedRowsString = "";
		selectedRows.forEach(function (selectedRow, index) {
			if (index > 5) {
				return;
			}
			if (index !== 0) {
				selectedRowsString += ", ";
			}
			selectedRowsString += selectedRow.athlete;
		});
		if (selectedRows.length >= 5) {
			selectedRowsString += " - and " + (selectedRows.length - 5) + " others";
		}
		localStorage.setItem(LocalStorage.SELECTED_LIST, JSON.stringify(selectedRows))


	}

	folderSelected(folderData: any){
		let folderInfo = new FileModel();
		let folderName = folderData.fileName.split(/:(.+)/)[1];
		folderInfo.folderId = folderData.id;
		folderInfo.type = 'Folder';
		folderInfo.folderName = folderName;
		folderInfo.enableMoveFile = 'Yes'
		folderInfo.deleteEnabled = 'Yes'
		this.sharedService.setFileType(folderInfo.type);
		this.sharedService.setFileId(folderInfo.folderId);
		this.enableDisableButtons(folderInfo);
		this.dependenciesmodalService.setCallback(this);
		this.dependenciesmodalService.getDependecies(folderInfo.folderId, folderInfo.type, null);
	}

	ngOnInit() {
		console.log('[filesComponent] (data) ' + JSON.stringify(this.data));
		console.log('[filesComponent] (deviceType) ' + JSON.stringify(this.deviceType));
		if (this.deviceType != null && this.deviceType != Constants.DEVICE_TYPES.DESKTOP) {
			this.triggersType = ''
		} else {
			this.triggersType = 'hover'
		}

		if (this.data.isSearch) {
			this.addFileShow = false;
			this.columnDefs.push({ headerName: 'Path', field: "folderPath" });
			//get search results
			this.getFileSearchResult(this.data);
		} else {
			this.addFileShow = true;
			this.filesService.getFolderContentByFolderId(this);
		}
		this.loadingService.showLoading(true, false, 'File Loading', 0);
	}

	getFileSearchResult(data: any) {
		this.fileName = "";
		this.checkOutBy = "";
		this.lastUpdated = "";
		this.fileType = "";
		this.hasText = "";

		//assign value for the fields from searchWordMap
		var flName = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_HEADER_FILE_NAME];
		if (flName != undefined) {
			this.fileName = flName;
		} else {
			//no file name
		}
		var ckBy = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_HEADER_FILE_CHECKOUT_BY];
		if (ckBy != undefined) {
			this.checkOutBy = ckBy;
		} else {
			//no ckBy
		}

		var lstUpdated = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_HEADER_FILE_LAST_UPDATE_DATE];
		if (lstUpdated != undefined) {
			let startDateTime = lstUpdated + "T00:00:00"
			let startUTCDate = new Date(startDateTime).toISOString();
			let endDateTime = lstUpdated + "T23:59:59"
			let endUTCDate = new Date(endDateTime).toISOString();
			this.lastUpdated = "[" + startUTCDate + " TO " + endUTCDate + "]";
		} else {
			//no lastUpdated time
		}

		var fType = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_HEADER_FILE_ITEM_TYPE];
		if (fType != undefined) {
			this.fileType = fType;
		} else {
			//no file type
		}

		var hasText = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_HAS_THE_WORDS];
		if (hasText != undefined) {
			this.hasText = hasText;
		} else {
			//no has text
		}

		if (this.fileName != "" || this.checkOutBy != "" || this.lastUpdated != "" || this.fileType != "" || this.hasText != "") {
			let to: any = false;
			if (to) { clearTimeout(to); }
			to = setTimeout(() => {

				this.filesService.searchFiles(this.fileName, this.checkOutBy, this.lastUpdated, this.fileType, this.hasText);
			}, 200);
		} else {
			//search bar has empty values
			this.showTableData(null)
		}
	}

	showTableData(payload: any) {
		if (payload != null) {
			this.rowData = [];
			let preferences = localStorage.getItem('preferences');
			var json = JSON.parse(preferences);
			var innerJson = (JSON.parse(json)).UserPreference.Portal;
			let zone = innerJson.General.TimeZone;
			for (let i = 0; i < payload.length; i++) {
				this.rowData.push({
					id: payload[i]["id"],
					type: payload[i]["type"] + ":" + payload[i]["id"] + "," + payload[i]["status"],
					fileName: "tooltip:" + payload[i]["name"],
					checkoutBy: payload[i]["checkoutBy"] != null ? payload[i]["checkoutBy"] : "",
					lastUpdateTimeStamp: new Date(payload[i]["lastUpdateTimeStamp"]).toLocaleString("en-US", {timeZone: zone}) != "Invalid Date" ? new Date(payload[i]["lastUpdateTimeStamp"]).toLocaleString("en-US", {timeZone: zone}) : payload[i]["lastUpdateTimeStamp"],
					folderPath: this.getFolderPath(payload[i]["folderPath"]),
					checkoutUserId: payload[i]["checkoutUserId"]
				});
			}
		}
	}

	getFolderPath(folderPath) {
		var firstPart = "";
		if (folderPath) {
			var folderPathContent = folderPath.split('/');
			firstPart = folderPathContent[0] + "/" + folderPathContent[1] + "/";
			folderPath = folderPath.replace(firstPart, "");
		} else {
			//no folder path
		}

		return folderPath;
	}

	onSuccess(data: WsResponse, serviceType: WsType): void {
		console.log("Recieved a successful a service type " + serviceType);

		// for testing purposes only

		console.log(
			["data != null && serviceType == WsType.CM_SYSTEM_CHECKOUT: " + (data != null && serviceType == WsType.CM_SYSTEM_CHECKOUT),
			"data != null && serviceType == WsType.CM_SYSTEM_UNDO_CHECKOUT: " + (data != null && serviceType == WsType.CM_SYSTEM_UNDO_CHECKOUT),
			"data != null && serviceType == WsType.CHECKIN_DOCUMENT_FILE_DATA: " + (data != null && serviceType == WsType.CHECKIN_DOCUMENT_FILE_DATA),
			"data != null && serviceType == WsType.FILE_CHECKOUT: " + (data != null && serviceType == WsType.FILE_CHECKOUT),
			"data != null && serviceType == WsType.FILE_CHECKOUT_UNDO: " + (data != null && serviceType == WsType.FILE_CHECKOUT_UNDO),
			"data != null && serviceType == WsType.FILE_CHECK_IN: " + (data != null && serviceType == WsType.FILE_CHECK_IN),
			"data != null && serviceType == WsType.CHECK_OUT_IMAGE: " + (data != null && serviceType == WsType.CHECK_OUT_IMAGE),
			"data != null && serviceType == WsType.UNDO_CHECK_OUT_IMAGE: " + (data != null && serviceType == WsType.UNDO_CHECK_OUT_IMAGE),
			"data != null && serviceType == WsType.CHECK_IN_IMAGE: " + (data != null && serviceType == WsType.CHECK_IN_IMAGE),
			"data != null && serviceType == WsType.CHECKIN_ICE_COURSE: " + (data != null && serviceType == WsType.CHECKIN_ICE_COURSE),
			"data != null && serviceType == WsType.CHECKOUT_ICE_COURSE: " + (data != null && serviceType == WsType.CHECKOUT_ICE_COURSE),
			"data != null && serviceType == WsType.UNDO_CHECKOUT_ICE_COURSE: " + (data != null && serviceType == WsType.UNDO_CHECKOUT_ICE_COURSE),
			"data != null && serviceType == WsType.RESTORE_ICE_COURSE_REVISION: " + (data != null && serviceType == WsType.RESTORE_ICE_COURSE_REVISION),
			"data != null && serviceType == WsType.FILE_DATA_VIEW: " + (data != null && serviceType == WsType.FILE_DATA_VIEW)
		]);

		console.log("ws type : " + serviceType);
		console.log("data : " + data.payload);
		//this.loadingService.hideLoading();
		if (data != null && serviceType == WsType.FILE_VIEW) {
			if(data.payload != null && data.payload.length > 0){
				this.sharedService.enableFolderDeleteRequest(false);
			}
			else{
				this.sharedService.enableFolderDeleteRequest(true);
			}
			this.resetenableDisableButtons();
			this.multipleSelections = false;
			this.showTableData(data.payload);
			this.filesService.setApiGridData(this.gridApi);
			this.loadingService.hideLoading();
		} else if (data != null && serviceType == WsType.FILE_DATA_VIEW) {
			this.disable = false;
			this.selectedFile = data.payload
			this.resetenableDisableButtons();
			if (this.selectedFile != null) {
				this.enableDisableButtons(this.selectedFile)
			}
			this.sharedService.setFileId(this.selectedFile.id);
			this.sharedService.setFileType(this.selectedFile.type);
			this.sharedService.setStatus(this.selectedFile.status);
			this.filesService.setSelectedFileData(data.payload);
			this.filesService.getFileDataByFolderId(data.payload, this);
			this.loadingService.hideLoading();

		} else if (data != null && data.payload != null && serviceType == WsType.ITEM_DEPENDENCY_LIST) {
			let payload = JSON.parse(data.payload);
			if(this.sharedService.getFileType() == 'Folder'){
				if(payload != null && payload.HasDependencies){
					this.enableDelete = false;
				}
				else{
					this.enableDelete = true;
				}
			}
			else{
				this.checkDepedencies(data.payload, this.selectedFile.type);
				this.loadingService.hideLoading();
			}
		} else if (data != null && serviceType == WsType.CM_SYSTEM_CHECKOUT) {
			console.log("CM_SYSTEM_CHECKOUT: " + JSON.stringify(data));
			this.alertService.clear();
			this.alertService.success("Successfully Checked Out");
			//this.loadingService.hideLoading();
			if (this.selectedFile == null) {
				console.log("Selected file : null");
				this.selectedFile = this.filesService.getSelectedFileData();
				//this.sharedService.folderRefreshRequest(this.folderId);//this.filesService.getFolderContentByFolderId(this);
			}
			//else{
			this.filesDataService.getBasicFileData(this.selectedFile.id, this.selectedFile.type, this);
			//}
			this.sharedService.setIsCheckedIn(false);

		} else if (data != null && serviceType == WsType.CM_SYSTEM_UNDO_CHECKOUT) {
			console.log("CM_SYSTEM_UNDO_CHECKOUT: " + JSON.stringify(data));
			this.alertService.clear();
			this.alertService.success("Successfully Undo Checked Out");
			//this.loadingService.hideLoading();
			if (this.selectedFile == null) {
				console.log("Selected file : null");
				this.selectedFile = this.filesService.getSelectedFileData();
				//this.sharedService.folderRefreshRequest(this.folderId);//this.filesService.getFolderContentByFolderId(this);
			}
			//else{
			this.filesDataService.getBasicFileDataUndoCheckout(this.selectedFile.id, this.selectedFile.type, this);
			//}
			this.sharedService.setIsCheckedIn(true);

		} else if (data != null && serviceType == WsType.CHECKIN_DOCUMENT_FILE_DATA) {
			console.log("CHECKIN_DOCUMENT_FILE_DATA: " + JSON.stringify(data.payload));
			this.alertService.clear();
			this.alertService.success("Successfully Checked In");
			//this.loadingService.hideLoading();
			if (this.selectedFile == null) {
				console.log("Selected file : null");
				this.selectedFile = this.filesService.getSelectedFileData();
				//this.sharedService.folderRefreshRequest(this.folderId);//this.filesService.getFolderContentByFolderId(this);
			}
			//else{
			this.filesDataService.getBasicFileDataForCheckin(this.selectedFile.id, this.selectedFile.type, this);
			//}
			this.sharedService.setIsCheckedIn(true);

		} else if (data != null && serviceType == WsType.FILE_CHECKOUT) {
			console.log("FILE_CHECKOUT: " + JSON.stringify(data));
			this.alertService.clear();
			this.alertService.success("Successfully Checked Out");
			//this.loadingService.hideLoading();
			if (this.selectedFile == null) {
				console.log("Selected file : null");
				this.selectedFile = this.filesService.getSelectedFileData();
				//this.sharedService.folderRefreshRequest(this.folderId);//this.filesService.getFolderContentByFolderId(this);
			}
			//else{
			this.filesDataService.getBasicFileData(this.selectedFile.id, this.selectedFile.type, this);
			//}
			this.sharedService.setIsCheckedIn(false);

		} else if (data != null && serviceType == WsType.FILE_CHECKOUT_UNDO) {
			console.log("FILE_CHECKOUT_UNDO: " + JSON.stringify(data));
			this.alertService.clear();
			this.alertService.success("Successfully Undo Checked Out");
			//this.loadingService.hideLoading();
			if (this.selectedFile == null) {
				console.log("Selected file : null");
				this.selectedFile = this.filesService.getSelectedFileData();
				//this.sharedService.folderRefreshRequest(this.folderId);//this.filesService.getFolderContentByFolderId(this);
			}
			//else{
			this.filesDataService.getBasicFileDataUndoCheckout(this.selectedFile.id, this.selectedFile.type, this);
			//}
			this.sharedService.setIsCheckedIn(true);

		} else if (data != null && serviceType == WsType.FILE_CHECK_IN) {
			console.log("FILE_CHECK_IN: " + JSON.stringify(data.payload));
			this.uploadingFile = null
			this.fileCheckInTitle = ""
			this.alertService.clear();
			this.alertService.success("Successfully Checked In");
			//this.loadingService.hideLoading();
			this.bsModalRef.hide()
			if (this.selectedFile == null) {
				console.log("Selected file : null");
				this.selectedFile = this.filesService.getSelectedFileData();
				//this.sharedService.folderRefreshRequest(this.folderId);//this.filesService.getFolderContentByFolderId(this);
			}
			//else{
			this.filesDataService.getBasicFileDataForCheckin(this.selectedFile.id, this.selectedFile.type, this);
			//}			
			this.sharedService.setIsCheckedIn(true);

		} else if (data != null && serviceType == WsType.CHECK_OUT_IMAGE) {
			console.log("CHECK_OUT_IMAGE: " + JSON.stringify(data));
			this.alertService.clear();
			this.alertService.success("Successfully Checked Out");
			//this.loadingService.hideLoading();
			if (this.selectedFile == null) {
				console.log("Selected file : null");
				this.selectedFile = this.filesService.getSelectedFileData();
				//this.sharedService.folderRefreshRequest(this.folderId);//this.filesService.getFolderContentByFolderId(this);
			}
			//else{
			this.filesDataService.getBasicFileData(this.selectedFile.id, this.selectedFile.type, this);
			//}
			this.sharedService.setIsCheckedIn(false);

		} else if (data != null && serviceType == WsType.UNDO_CHECK_OUT_IMAGE) {
			console.log("UNDO_CHECK_OUT_IMAGE: " + JSON.stringify(data));
			this.alertService.clear();
			this.alertService.success("Successfully Undo Checked Out");
			//this.loadingService.hideLoading();
			if (this.selectedFile == null) {
				console.log("Selected file : null");
				this.selectedFile = this.filesService.getSelectedFileData();
				//this.sharedService.folderRefreshRequest(this.folderId);//this.filesService.getFolderContentByFolderId(this);
			}
			//else{
			this.filesDataService.getBasicFileDataUndoCheckout(this.selectedFile.id, this.selectedFile.type, this);
			//}
			this.sharedService.setIsCheckedIn(true);

		} else if (data != null && serviceType == WsType.CHECK_IN_IMAGE) {
			console.log("CHECK_IN_IMAGE: " + JSON.stringify(data.payload));
			this.alertService.clear();
			this.alertService.success("Successfully Checked In");
			this.bsModalRef.hide()
			//this.loadingService.hideLoading();
			if (this.selectedFile == null) {
				console.log("Selected file : null");
				this.selectedFile = this.filesService.getSelectedFileData();
				//this.sharedService.folderRefreshRequest(this.folderId);//this.filesService.getFolderContentByFolderId(this);
			}
			//else{
			this.filesDataService.getBasicFileDataForCheckin(this.selectedFile.id, this.selectedFile.type, this);
			//}
			this.sharedService.setIsCheckedIn(true);

			/*this.folderService.getSelectedFoldeData();
			this.alertService.success(data.statusDescription, false);
			this.loadingService.hideLoading();
			this.bsModalRef.hide();*/
		} else if (data != null && serviceType == WsType.CHECK_IN_THREED_MODEL) {
			this.bsModalRef.hide();
			console.log("CHECK_IN_THREED_MODEL: " + JSON.stringify(data.payload));
			let infoList = JSON.parse(JSON.stringify(data.payload));
			if(infoList.length == 0) {
				this.alertService.clear();
				this.alertService.success("Successfully Checked In");											
				this.filesDataService.getBasicFileDataForCheckin(this.selectedFile.id, this.selectedFile.type, this);
				this.sharedService.setIsCheckedIn(true);
			} else {
				//Logic to display 3D model missing dependencies
				this.modelMissingViews = [];	
				this.modelChangedViews = [];			
				for (let i = 0; i <= infoList.length - 1; i++) {
					let item: Array<string>  = infoList[i];
					let div = document.createElement("div");
					let fullPath = item[0];
					let html = item[1].split("</div>");
					for (let j = 0; j <= html.length - 1; j++) {						
						div.innerHTML = html[j];
						fullPath += "/" + div.textContent || div.innerText || "";
					}
					div.innerHTML = item[2];
					fullPath += div.textContent || div.innerText || "";
					if (item[4] != null || item[5] != null) {
						let viewInfo = null;
						let layerInfo = null;
						if (item[4] != null) {
							viewInfo = item[4];
						}
						if (item[5] != null) {
							layerInfo = item[5];
						}
						this.modelMissingViews.push({'fullPath' : fullPath, 'view' : viewInfo, 'layers' : layerInfo });
					} else if (item[3] != null) {
						// if modelViewName is null show the dependency message.
						if(item[6] == null) {
							this.modelChangedViews.push({'fullPath' : fullPath, 'viewDetails' : item[3]})
						}
						else {
							// Nothing to do
						}
					// If no model dependencies and model view name is null	
					} else if (item[6] == null) {
						this.modelChangedViews.push({'fullPath' : fullPath, 'view' : "Empty Model View Name"})
					}
				}
				if (this.modelMissingViews.length > 0) {
					this.modalRef = this.modalService.show(this.threedmodelDepTemplate, { class: 'modal-lg' });
					this.loadingService.hideLoading();
				} else if (this.modelChangedViews.length > 0) {
					this.modalRef = this.modalService.show(this.threedmodelViewWarnTemplate, { class: 'modal-lg' });
					this.loadingService.hideLoading();
				} else {
					this.alertService.clear();
					this.alertService.success("Successfully Checked In");											
					this.filesDataService.getBasicFileDataForCheckin(this.selectedFile.id, this.selectedFile.type, this);
					this.sharedService.setIsCheckedIn(true);
				}
			}
			if (this.selectedFile == null) {
				console.log("Selected file : null");
				this.selectedFile = this.filesService.getSelectedFileData();
			}

			/*this.folderService.getSelectedFoldeData();
			this.alertService.success(data.statusDescription, false);
			this.loadingService.hideLoading();
			this.bsModalRef.hide();*/
		} else if (data != null && serviceType == WsType.CHECK_OUT_THREED_MODEL) {
			console.log("CHECK_OUT_THREED_MODEL: " + JSON.stringify(data));
			this.alertService.clear();
			this.alertService.success("Successfully Checked Out");
			//this.loadingService.hideLoading();
			if (this.selectedFile == null) {
				console.log("Selected file : null");
				this.selectedFile = this.filesService.getSelectedFileData();
				//this.sharedService.folderRefreshRequest(this.folderId);//this.filesService.getFolderContentByFolderId(this);
			}
			//else{
			this.filesDataService.getBasicFileData(this.selectedFile.id, this.selectedFile.type, this);
			//}
			this.sharedService.setIsCheckedIn(false);

		} else if (data != null && serviceType == WsType.UNDO_CHECK_OUT_THREED_MODEL) {
			console.log("UNDO_CHECK_OUT_THREED_MODEL: " + JSON.stringify(data));
			this.alertService.clear();
			this.alertService.success("Successfully Undo Checked Out");
			//this.loadingService.hideLoading();
			if (this.selectedFile == null) {
				console.log("Selected file : null");
				this.selectedFile = this.filesService.getSelectedFileData();
				//this.sharedService.folderRefreshRequest(this.folderId);//this.filesService.getFolderContentByFolderId(this);
			}
			//else{
			this.filesDataService.getBasicFileDataUndoCheckout(this.selectedFile.id, this.selectedFile.type, this);
			//}
			this.sharedService.setIsCheckedIn(true);

		} else if (data != null && serviceType == WsType.BASIC_FILE_DATA) {
			console.log("BASIC_FILE_DATA - " + data.payload);
			this.updateSelectedFile(data.payload, data.payload.action);
			this.enableDisableButtons(this.selectedFile);
			if ((data.payload.type == 'Image') || (data.payload.type == 'ContentItem') || (data.payload.type == 'ThreeDModel') || (data.payload.type == 'Video')) {
				this.filesService.updateFileTableData(data.payload.checkoutBy, data.payload.lastUpdateTimeStamp, this.selectedFile.id, this.selectedFile.type, data.payload.title);
			} else {
				this.filesService.updateFileTableData(data.payload.checkoutBy, data.payload.lastUpdateTimeStamp, this.selectedFile.id, this.selectedFile.type, data.payload.name);
			}
			this.sharedService.updateTimeStampRequest(data.payload.lastUpdateTimeStamp, this.selectedFile.type);
			this.loadingService.hideLoading();

		} else if (data != null && serviceType == WsType.BASIC_FILE_DATA_UNDO_CHECKOUT) {
			console.log("BASIC_FILE_DATA_UNDO_CHECKOUT");
			this.updateSelectedFile(data.payload, "checkin");
			this.enableDisableButtons(this.selectedFile);
			if ((data.payload.type == 'Image') || (data.payload.type == 'ContentItem') || (data.payload.type == 'ThreeDModel') || (data.payload.type == 'Video')) {
				this.filesService.updateFileTableDataUndoCheckout(data.payload.checkoutBy, data.payload.lastUpdateTimeStamp, this.selectedFile.id, this.selectedFile.type, data.payload.title);
			} else {
				this.filesService.updateFileTableDataUndoCheckout(data.payload.checkoutBy, data.payload.lastUpdateTimeStamp, this.selectedFile.id, this.selectedFile.type, data.payload.name);
			}

			this.sharedService.updateTimeStampRequest(data.payload.lastUpdateTimeStamp, this.selectedFile.type);
			this.loadingService.hideLoading();

		} else if (data != null && serviceType == WsType.BASIC_FILE_DATA_CHECKIN) {
			console.log("BASIC_FILE_DATA_CHECKIN");
			this.updateSelectedFile(data.payload, "checkin");
			this.enableDisableButtons(this.selectedFile);
			if ((data.payload.type == 'Image') || (data.payload.type == 'ContentItem') || (data.payload.type == 'ThreeDModel') || (data.payload.type == 'Video')) {
				this.filesService.updateFileTableDataCheckin(data.payload.checkoutBy, data.payload.lastUpdateTimeStamp, this.selectedFile.id, this.selectedFile.type, data.payload.title);
			} else {
				this.filesService.updateFileTableDataCheckin(data.payload.checkoutBy, data.payload.lastUpdateTimeStamp, this.selectedFile.id, this.selectedFile.type, data.payload.name);
			}
			this.sharedService.updateTimeStampRequest(data.payload.lastUpdateTimeStamp, this.selectedFile.type);
			this.loadingService.hideLoading();

		} else if (data != null && serviceType == WsType.MULTIPLE_ACTIONS) {
			this.loadingService.showLoading(true, false, 'File Loading', 0);
			if (this.bsModalRef != undefined) {
				this.bsModalRef.hide();
			}
			this.items = [];
			this.headings = [];
			this.itemStatus = [];
			let response = JSON.parse(data.payload)
			console.log("response: " + JSON.stringify(response));
			this.setAlert(response);
			this.closeDeletedTabs(response);
			this.filesService.getFolderContentByFolderId(this);
		} else if (data != null && serviceType == WsType.GET_EXISTANCE) {
			let response = JSON.parse(data.payload)
			this.setTableData(response.images.image);
			this.bsModalRef = this.modalService.show(this.checkinMultipleImagesTemplate);
			this.bsModalRef.content.closeBtnName = 'Close';
			this.loadingService.hideLoading();
		} else if (data != null && serviceType == WsType.FILE_SEARCH) {
			this.showTableData(data.payload);
			this.loadingService.hideLoading();
		} else if (data != null && serviceType == WsType.DUPLICATE_ITEM) {
			if( data.payload == "Success"){
				this.resetenableDisableButtons();
				this.alertService.clear();
				this.alertService.success("Successfully duplicated the item");
				this.loadingService.hideLoading();
				this.filesService.getFolderContentByFolderId(this);
				this.loadingService.showLoading(true, false, 'File Loading', 0);
			} else {
				this.resetenableDisableButtons();
				this.alertService.clear();
				this.alertService.error("Cannot duplicated the item");
				this.loadingService.hideLoading();
				this.filesService.getFolderContentByFolderId(this);
				this.loadingService.showLoading(true, false, 'File Loading', 0);
			}
		} else if (data != null && serviceType == WsType.GET_ICE_COURSE) {
			console.log("GET_ICE_COURSE");
			let responseData = data.payload;
			//set selected file data
			let fileData = new FileModel();
			fileData.type = "ICECourse";
			fileData.statusEnable = "Yes";
			if(responseData.checkoutUserId != null){
				fileData.editable = "Yes";
			}else{
				fileData.editable = "No";
			}
			fileData.lastUpdateTimeStamp = responseData.lastUpdateTimeStamp;
			fileData.id = responseData.courseId;
			fileData.status = responseData.status;
			fileData.checkoutUserId = responseData.checkoutUserId;
			fileData.name = responseData.fileName;
			fileData.storeVersion = responseData.courseVersion;
			this.sharedService.setFileId(fileData.id);
			this.sharedService.setFileType(fileData.type);
			this.sharedService.setStatus(fileData.status);
			this.filesService.setSelectedFileData(fileData);
			this.selectedFile = fileData;
			this.enableDisableButtons(fileData);
			this.loadingService.hideLoading();
			this.filesService.getFileDataByFolderId(data.payload, this);
		} else if (data != null && serviceType == WsType.CHECKIN_ICE_COURSE) {
			this.alertService.clear();
			this.alertService.success(this.translate.instant('FILES_DATA.COURSE_CHECKEDIN'));
			if (this.selectedFile == null) {
				console.log("Selected file : null");
				this.selectedFile = this.filesService.getSelectedFileData();
				//this.sharedService.folderRefreshRequest(this.folderId);//this.filesService.getFolderContentByFolderId(this);
			}
			//else{
			this.filesDataService.getBasicFileDataForCheckin(this.selectedFile.id, this.selectedFile.type, this);
			//}
			this.sharedService.setIsCheckedIn(true);

			// if (this.selectedFile.id == this.sharedService.getNewICETabId()) {
			// 	this.sharedService.checkedInCheckedOutTabCloseRequest('ice_cource_1');
			// } else {
				this.sharedService.checkedInCheckedOutTabCloseRequest(this.selectedFile.id);
			//}

		} else if (data != null && serviceType == WsType.CHECKOUT_ICE_COURSE) {
			this.alertService.clear();
			this.alertService.success(this.translate.instant('FILES_DATA.COURSE_CHECKEDOUT'));
			if (this.selectedFile == null) {
				console.log("Selected file : null");
				this.selectedFile = this.filesService.getSelectedFileData();
				//this.sharedService.folderRefreshRequest(this.folderId);//this.filesService.getFolderContentByFolderId(this);
			}
			//else{	
			this.filesDataService.getBasicFileData(this.selectedFile.id, this.selectedFile.type, this);
			//}
			this.sharedService.setIsCheckedIn(false);

			// if (this.selectedFile.id == this.sharedService.getNewICETabId()) {
			// 	this.sharedService.checkedInCheckedOutTabCloseRequest('ice_cource_1');
			// } else {
				this.sharedService.checkedInCheckedOutTabCloseRequest(this.selectedFile.id);
			//}

		} else if (data != null && serviceType == WsType.UNDO_CHECKOUT_ICE_COURSE) {
			this.alertService.clear();
			this.alertService.success(this.translate.instant('FILES_DATA.COURSE_UNDO_CHECKEDOUT'));

			if (this.selectedFile == null) {
				console.log("Selected file : null");
				this.selectedFile = this.filesService.getSelectedFileData();
				//this.sharedService.folderRefreshRequest(this.folderId);//this.filesService.getFolderContentByFolderId(this);
			}
			//else{
			this.filesDataService.getBasicFileDataUndoCheckout(this.selectedFile.id, this.selectedFile.type, this);
			//}
			this.sharedService.setIsCheckedIn(true);

			// if (this.selectedFile.id == this.sharedService.getNewICETabId()) {
			// 	this.sharedService.checkedInCheckedOutTabCloseRequest('ice_cource_1');
			// } else {
				this.sharedService.checkedInCheckedOutTabCloseRequest(this.selectedFile.id);
			//}

		} else if (data != null && serviceType == WsType.RESTORE_ICE_COURSE_REVISION) {
			console.log("RESTORE_ICE_COURSE_REVISION: " + JSON.stringify(data.payload));
			let infoList = JSON.parse(JSON.stringify(data.payload));
			if(infoList.length == 0) {
				this.alertService.clear();
				this.alertService.success(this.translate.instant('FILES_DATA.COURSE_RESTORE_REVISION'));
				this.filesDataService.getBasicFileData(this.selectedFile.id, this.selectedFile.type, this, "checkin");
				this.sharedService.checkedInCheckedOutTabCloseRequest(this.selectedFile.id);
			} else {
				this.modelInfo = [];				
				for (let i = 0; i <= infoList.length - 1; i++) {
					let item: Array<string>  = infoList[i];
					let div = document.createElement("div");
					let fullPath = item[0];
					let html = item[1].split("</div>");
					for (let j = 0; j <= html.length - 1; j++) {						
						div.innerHTML = html[j];
						fullPath += "/" + div.textContent || div.innerText || "";
					}
					div.innerHTML = item[2];
					fullPath += div.textContent || div.innerText || "";
					if (item[3] != null || item[4] != null || item[5] != null) {
						let viewNameInfo = null;
						let viewCoordInfo = null;
						let layerInfo = null;
						let delModelInfo = null;				
						if (item[6] != null) {
							if (item[4] != null) {
								viewNameInfo = item[4];
							}
							else {
								// Nothing to do
							}
						} 
						else {
							if ((item[3] != null && item[3] != "DeletedModel") || item[3] == null) {
								viewNameInfo = "Empty Model View Name";
							}
						}						
						if (item[5] != null) {
							layerInfo = item[5];
						}
						if (item[3] != null) {
							if (item[3] == "DeletedModel") {
								delModelInfo = item[3];
							} else {
								viewCoordInfo = item[3];
							}
						}				
						this.modelInfo.push({'fullPath' : fullPath, 'view' : viewNameInfo, 'layers' : layerInfo, 'viewDetails' : viewCoordInfo, 'deletedModel' : delModelInfo });
					} 
				}
				if (this.modelInfo.length > 0) {
					this.modalRef = this.modalService.show(this.restoreRevision3DModelTemplate, { class: 'modal-lg' });
					this.loadingService.hideLoading();
				} else {
					this.alertService.clear();
					this.alertService.success(this.translate.instant('FILES_DATA.COURSE_RESTORE_REVISION'));
					this.filesDataService.getBasicFileData(this.selectedFile.id, this.selectedFile.type, this, "checkin");
					this.sharedService.checkedInCheckedOutTabCloseRequest(this.selectedFile.id);
				}
			}
			if (this.selectedFile == null) {
				console.log("Selected file : null");
				this.selectedFile = this.filesService.getSelectedFileData();
				//this.sharedService.folderRefreshRequest(this.folderId);//this.filesService.getFolderContentByFolderId(this);
			}
		}else if (data != null && serviceType == WsType.CHECK_OUT_VIDEO) {
			console.log("CHECK_OUT_VIDEO: " + JSON.stringify(data));
			this.alertService.clear();
			this.alertService.success("Successfully Checked Out");
			//this.loadingService.hideLoading();
			if (this.selectedFile == null) {
				console.log("Selected file : null");
				this.selectedFile = this.filesService.getSelectedFileData();
				//this.sharedService.folderRefreshRequest(this.folderId);//this.filesService.getFolderContentByFolderId(this);
			}
			//else{
			this.filesDataService.getBasicFileData(this.selectedFile.id, this.selectedFile.type, this);
			//}
			this.sharedService.setIsCheckedIn(false);

		} else if (data != null && serviceType == WsType.CHECK_IN_VIDEO) {
			console.log("CHECK_IN_VIDEO: " + JSON.stringify(data.payload));
			this.alertService.clear();
			this.alertService.success("Successfully Checked In");
			this.bsModalRef.hide()
			//this.loadingService.hideLoading();
			if (this.selectedFile == null) {
				console.log("Selected file : null");
				this.selectedFile = this.filesService.getSelectedFileData();

			}

			this.filesDataService.getBasicFileDataForCheckin(this.selectedFile.id, this.selectedFile.type, this);
			this.sharedService.setIsCheckedIn(true);
			this.posterframe = null;
			this.thumbnail = null;
			this.uploadingVideoFile = [];

			/*this.folderService.getSelectedFoldeData();
			this.alertService.success(data.statusDescription, false);
			this.loadingService.hideLoading();
			this.bsModalRef.hide();*/
		}else if (data != null && serviceType == WsType.UNDO_CHECK_OUT_VIDEO) {
			console.log("UNDO_CHECK_OUT_VIDEO: " + JSON.stringify(data));
			this.alertService.clear();
			this.alertService.success("Successfully Undo Checked Out");
			//this.loadingService.hideLoading();
			if (this.selectedFile == null) {
				console.log("Selected file : null");
				this.selectedFile = this.filesService.getSelectedFileData();
				//this.sharedService.folderRefreshRequest(this.folderId);//this.filesService.getFolderContentByFolderId(this);
			}
			//else{
			this.filesDataService.getBasicFileDataUndoCheckout(this.selectedFile.id, this.selectedFile.type, this);
			//}
			this.sharedService.setIsCheckedIn(true);

		} else if (serviceType == WsType.EXPORT_ICECMCOURSE) {
			this.alertService.clear();
			this.alertService.success("Successfully Exported.");
			this.loadingService.hideLoading();
			// this.bsModalRef.hide()
			// if(this.selectedFile == null){				
			// 	console.log("Selected file : null");
			// 	this.selectedFile = this.filesService.getSelectedFileData();	
			// 	//this.sharedService.folderRefreshRequest(this.folderId);//this.filesService.getFolderContentByFolderId(this);
			// }
		} else if(serviceType == WsType.MULTIPLE_THREEDMODEL_CHECKIN){
			if(data.payload == true){
				this.alertService.success("Successfully checked In");
				this.loadingService.hideLoading();
			}
		} else if(serviceType == WsType.GET_PROCEDURE_PDF_TEMPLATE){
			if (data.payload != null) {
				console.log(JSON.stringify(data.payload))
				console.log(data.payload)
				
				this.getPDFPrcedureConfigs(data.payload)
			} else {
				// do nothing
			}
		} else if(serviceType == WsType.GET_DOCUMENT_STORE_PDF_TEMPLATE){
			if (data.payload != null) {
				console.log(JSON.stringify(data.payload))
				console.log(data.payload)
				
				this.getPDFDocumentStoreConfigs(data.payload)
			} else {
				// do nothing
			}
		} else if (data!= null && serviceType == WsType.FILE_DATA_FOLDER_PATH) {
			this.selectedFile.folderPath = data.payload.path;
		}
		else if (serviceType == WsType.DELETE_ITEM){
			console.log("DELETE_ITEM - Folder " + JSON.stringify(data.payload));
			this.filesService.deleteFileTableData(data.payload);
			this.sharedService.deletedTabCloseRequest(data.payload);
			this.sharedService.deleteFolderFromFolderTreeRequest([data.payload])
			this.loadingService.hideLoading();
			this.alertService.success("Folder deleted successfully");
		}
	}

	onFail(res: WsResponse, serviceType: WsType): void {
		console.log("Recieved a failed service type " + serviceType);
		this.loadingService.hideLoading();
		if (res != null && WsType.FILE_VIEW == serviceType) {
			if (res.statusCode == "600") {
				this.loadingService.showLoading(true, false, 'File Loading', 0)
				this.filesService.getFolderContentByFolderId(this);
			} else {
				//this.alertService.error(res.statusDescription, false);
			}
		} else if (res != null && WsType.FILE_DATA_VIEW == serviceType) {
			if (res.statusCode == "600") {
				this.loadingService.showLoading(true, false, "File Data Loading", 0);
				this.filesService.getFileDataByFileId(this.selectedItemData, this);
			} else {
				//show the error message
				//this.alertService.error(res.statusDescription, false);
			}
		} else if (WsType.CHECK_OUT_IMAGE == serviceType) {
			this.alertService.error(res.statusDescription, false);
		} else if (WsType.UNDO_CHECK_OUT_IMAGE == serviceType) {
			this.alertService.error(res.statusDescription, false);
		} else if (WsType.CHECK_IN_IMAGE == serviceType) {
			this.alertService.error(res.statusDescription, false);
			this.bsModalRef.hide();
		} else if (serviceType == WsType.FILE_CHECKOUT) {
			this.alertService.error(res.statusDescription, false);
		} else if (serviceType == WsType.FILE_CHECKOUT_UNDO) {
			this.alertService.error(res.statusDescription, false);
		} else if (serviceType == WsType.FILE_CHECK_IN) {
			this.alertService.error(res.statusDescription, false);
		} else if (serviceType == WsType.GET_ICE_COURSE) {
			this.alertService.error(res.statusDescription, false);
		} else if (serviceType == WsType.CHECKIN_ICE_COURSE) {
			this.alertService.error(res.statusDescription, false);
		} else if (serviceType == WsType.CHECKOUT_ICE_COURSE) {
			this.alertService.error(res.statusDescription, false);
		} else if (serviceType == WsType.UNDO_CHECKOUT_ICE_COURSE) {
			this.alertService.error(res.statusDescription, false);
		} else if (serviceType == WsType.RESTORE_ICE_COURSE_REVISION) {
			if (res.statusCode == "0") {
				this.alertService.clear();
				this.alertService.error("Could not restore revision. please try again", true);
				this.loadingService.hideLoading();
			} 
			else{
				if(res.statusName == "TimeoutError"){
					this.alertService.clear();
					this.alertService.error("Could not restore revision. please try again", true);
					this.loadingService.hideLoading();
				}
				else{
					this.alertService.clear();
					this.alertService.error(res.statusDescription, false);
					this.loadingService.hideLoading();
				}
			}
		} else if (serviceType == WsType.DUPLICATE_ITEM) {
			this.loadingService.hideLoading();
			this.alertService.error(res.statusDescription, false);
		} else if (serviceType == WsType.EXPORT_ICECMCOURSE) {
			this.alertService.error(res.statusDescription, false);
			this.loadingService.hideLoading();
		}else if(serviceType == WsType.MULTIPLE_THREEDMODEL_CHECKIN){
			this.alertService.error("checkin multiple 3D model failed");
		} else if (serviceType == WsType.FILE_DATA_FOLDER_PATH) {
			this.alertService.error("Getting folder path failed")
		} else if (serviceType == WsType.CHECKIN_DOCUMENT_FILE_DATA) {
			this.alertService.error(res.statusDescription, false);
		} else if (serviceType == WsType.CHECK_IN_THREED_MODEL) {
			this.alertService.error(res.statusDescription, false);
		} else if (serviceType == WsType.CHECK_IN_VIDEO) {
			this.posterframe = null;
			this.thumbnail = null;
			this.uploadingVideoFile = [];
			this.alertService.error(res.statusDescription, false);
		}
		else if (serviceType == WsType.MULTIPLE_ACTIONS) {
			this.alertService.error(res.statusDescription, false);
		}
		this.loadingService.hideLoading();
	}

	/**
	 * User clicks on the topic map floating button.
	 * @param template view template
	 */
	public addNewTopicMap(): void {
		this.translate.get('file.popups.new_topic_map_title').subscribe((res: string) => {
			this.modalTitle = res;
		});
		const initialState = {
			title: this.modalTitle,
			selectedFileData: this.folderService.getSelectedFoldeData(),
			floatingButtonCallback: this
		};
		this.bsModalRef = this.modalService.show(NewTopicMapComponent, { initialState, backdrop: 'static', keyboard: false });
		this.resetenableDisableButtons();
		this.multipleSelections = false;
	}

	/**
	 * User clicks on the topic floating button.
	 * @param template view template
	 */
	public addNewTopic(): void {
		this.translate.get('file.popups.new_topic_title').subscribe((res: string) => {
			this.modalTitle = res;
		});
		const initialState = {
			title: this.modalTitle,
			selectedFileData: this.folderService.getSelectedFoldeData(),
			floatingButtonCallback: this
		};
		this.bsModalRef = this.modalService.show(NewTopicComponent, { initialState, backdrop: 'static', keyboard: false });
		this.resetenableDisableButtons();
		this.multipleSelections = false;
	}

	/**
	 * User clicks on the file floating button.
	 * @param template view template
	 */
	public addNewFile(): void {
		this.translate.get('file.popups.new_file_title').subscribe((res: string) => {
			this.modalTitle = res;
		});
		const initialState = {
			title: this.modalTitle,
			selectedFolder: this.folderService.getSelectedFoldeData(),
			floatingButtonCallback: this
		};
		this.bsModalRef = this.modalService.show(NewFileComponent, { initialState, backdrop: 'static', keyboard: false });
		this.resetenableDisableButtons();
		this.multipleSelections = false;
	}

	/**
	 * User clicks on the image floating button.
	 * @param template view template
	 */
	public addNewImage(): void {
		this.translate.get('file.popups.new_image_title').subscribe((res: string) => {
			this.modalTitle = res;
		});
		const initialState = {
			title: this.modalTitle,
			selectedFolder: this.folderService.getSelectedFoldeData(),
			floatingButtonCallback: this
		};
		this.bsModalRef = this.modalService.show(NewImageComponent, { initialState, backdrop: 'static', keyboard: false });
		this.resetenableDisableButtons();
		this.multipleSelections = false;
	}
	/**
	 * User clicks on the video floating button.
	 * @param template view template
	 */
	// public addNewvideo(): void {
	// 	this.translate.get('file.popups.new_videos').subscribe((res: string) => {
	// 		this.modalTitle = res;
	// 	});
	// 	const initialState = {
	// 		title: this.modalTitle,
	// 		selectedFolder: this.folderService.getSelectedFoldeData(),
	// 		floatingButtonCallback: this
	// 	};
	// 	this.bsModalRef = this.modalService.show(NewVideoComponent, { initialState, backdrop: 'static', keyboard: false });
	// 	this.resetenableDisableButtons();
	// 	this.multipleSelections = false;
	// }
	/**
	 * User clicks on the procedure floating button.
	 * @param template view template
	 */
	public addNewProcedure(): void {
		this.translate.get('file.popups.new_procedure_title').subscribe((res: string) => {
			this.modalTitle = res;
		});
		const initialState = {
			title: this.modalTitle,
			selectedFolder: this.folderService.getSelectedFoldeData(),
			floatingButtonCallback: this
		};
		this.bsModalRef = this.modalService.show(NewProcedureComponent, { initialState, backdrop: 'static', keyboard: false });
		this.resetenableDisableButtons();
		this.multipleSelections = false;
	}

	/**
	 * User clicks on the course floating button
	 * @param template view tamplate
	 */
	public addNewCourse(): void {
		let data = {
			isNewCourse: true
		}

		//open course edit view
		// this.sharedService.openTabRequestWithId('ice_cource_1', 'ICE Cource', 'IceCourse', data);
		// this.resetenableDisableButtons();
		// this.multipleSelections = false;
		this.translate.get('file.popups.new_course_title').subscribe((res: string) => {
			this.modalTitle = res;
		});
		const initialState = {
			title: this.modalTitle,
			selectedFolder: this.folderService.getSelectedFoldeData(),
			floatingButtonCallback: this
		};
		this.bsModalRef = this.modalService.show(NewCourseComponent, { initialState, class: 'modal-lg', backdrop: 'static', keyboard: false });
		this.resetenableDisableButtons();
		this.multipleSelections = false;
	}

	/**
	 * User clicks on the multiple image floating button.
	 * @param template view template
	 */
	public addNewMultipleImages(): void {
		this.translate.get('file.popups.new_multiple_images_title').subscribe((res: string) => {
			this.modalTitle = res;
		});

		const initialState = {
			title: this.modalTitle,
			selectedFolder: this.folderService.getSelectedFoldeData(),
			floatingButtonCallback: this
		};
		this.bsModalRef = this.modalService.show(NewMultipleImagesComponent, { initialState, backdrop: 'static', keyboard: false });
		this.resetenableDisableButtons();
		this.multipleSelections = false;
	}



	hide() {
		this.bsModalRef.hide();
		//Reload the new data
		this.filesService.getFolderContentByFolderId(this);
		this.loadingService.showLoading(true, false, 'File Loading', 0);
	}

	private updateDetailPanel(option: any) {
		if (option != null) {
			this.showDetailPanel = option.showDetailPanel;
		}
	}

	/*private createComponent() {
		this.treeComponentHost.clear();
		const factory = this.resolver.resolveComponentFactory(NewTopicComponent);
		const ref = this.treeComponentHost.createComponent(factory);
	}*/
	ngAfterViewInit() {
		this.enableDisableSubscription = this.sharedService.resetEnableDisableButtonsEventResponse().subscribe(
			() => this.resetenableDisableButtons()
		);
	}
	
	ngAfterContentInit() {
		//Called after ngOnInit when the component's or directive's content has been initialized.
		//Add 'implements AfterContentInit' to the class.	
		this.subsVar = this.sharedService.modalCloseEventResponse().subscribe(() => this.close())
		if (!this.data.isSearch) {
			this.folderSubVar = this.sharedService.folderRefreshRespond().subscribe((option: any) => this.refreshFolder(option))
		}
	}

	ngOnDestroy() {
		// this.deleteUpdateSubscription.unsubscribe();
		if (this.subsVar) {
			this.subsVar.unsubscribe()
		}
		if (this.folderSubVar) {
			this.folderSubVar.unsubscribe()
		}
		window.removeEventListener("resize", this.onWindowResize);
		this.gridApi.destroy();
    	this.gridApi = null;
	}

	/**
	 * refresh folder data - refresh when user click on tab name
	 *
	 * @param option 
	 */
	private refreshFolder(option: any) {
		if (option.folderId == this.folderId) {
			this.loadingService.showLoading(true, false, this.translate.instant("REFRESHING"), 0);
			//console.log('[reload folder data]');
			this.filesService.getTabContentByFolderId(this, this.folderId);
		} else {
			//do nothing
		}
	}

	private enableDisableButtons(fileData: FileModel) {
		console.log('[enableDisableButtons] (fileData) '+ JSON.stringify(fileData))
		if (this.selectedNodes == 0) {
			this.resetenableDisableButtons();
			this.enableProperties = false;
			this.enableProperties = false;
			return;
		}
		
		this.capabilityCheck(fileData.type);
		//console.log('[enableDisableButtons] (getSelectedFileData()) ' + JSON.stringify(fileData));
		const loggedInUser = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER));
		this.enableGenerateOP = (fileData.genPDFEnable == 'Yes')
		if (fileData.type != "ThreeDModel" && fileData.type != "Video") {
			this.enableDuplicate = (fileData.duplicateEnable == 'Yes')
		}
		this.enableWorkFlow = (fileData.queryWorkflow == 'Yes')
		this.enableDelete = (fileData.deleteEnabled == 'Yes')
		this.enableMoveFile = (fileData.enableMoveFile == 'Yes')
		this.enableStatus = this.disableCap;

		console.log("====fileData.type=========" + fileData.type);
		if(fileData.type == "ICECourse")
		{
			this.enableGenerateOP = true;
		}
		console.log("====this.enableGenerateOP=========" + this.enableGenerateOP);

		// if (fileData.type == 'Topic' || fileData.type == 'Procedure' || fileData.type == 'TopicMap') {
		// 	if (fileData.type == 'Topic') {
		// 		this.showWorkFlow = (fileData.queryWorkflow == 'Yes')
		// 	} else {
		// 		//do nothing
		// 	}
		// 	this.enableEdit = true
		// } else {
		// 	this.enableEdit = false
		// }

		console.log("[enableDisableButtons] FileData: " + fileData);

		if (fileData.type == 'Topic' || fileData.type == 'Procedure' || fileData.type == 'TopicMap'
			|| fileData.type == 'ContentItem' || fileData.type == 'Image' || fileData.type == 'ThreeDModel' || fileData.type == "Video") {


			// if (fileData.type == 'TopicMap') {
			// 	this.showTopicMap = true
			// } else {
			// 	//do nothing
			// }
			if (fileData.type == 'Image' || fileData.type == 'ThreeDModel' || fileData.type == 'Video') {
				this.showGenerateOP = false;
			} else {
				this.showGenerateOP = true;
			}
			if (fileData.checkoutUserId != null) {
				this.enableDuplicate = false;
				this.enableMoveFile = true;
				this.enableDelete = true;
				if (fileData.checkoutUserId != loggedInUser.userId) {
					this.enableCheckIn = false;
					this.enableCheckOut = false;
					this.enableUndo = false;

					this.enableDelete = false;

					this.enableEdit = false;
					this.enableView = true;

					this.sharedService.setIsCheckedIn(false);
				} else {
					this.enableCheckIn = this.modifyCap;
					this.enableCheckOut = false;
					this.enableUndo = this.modifyCap;
					console.log("Logged as checked out user:" + fileData.type);

					if (fileData.type == 'ContentItem' || fileData.type == 'Image' || fileData.type == 'ThreeDModel') {
						this.enableCheckIn = true;
						this.enableUndo = true;
					}

					this.enableDelete = false;
					if (fileData.type == 'ThreeDModel' || fileData.type == 'Video' || fileData.type == 'ContentItem' || fileData.type == 'Image') {
						this.enableEdit = false;
					} else {
						this.enableEdit = this.modifyCap;
					}
					if (fileData.type == 'TopicMap') {
						this.enableView = true;
					} else {
						this.enableView = false;
					}
					this.sharedService.setIsCheckedIn(false);
				}
			} else {
				this.enableCheckIn = false;
				this.enableCheckOut = this.modifyCap;
				this.enableUndo = false;

				if (fileData.type == 'ContentItem' || fileData.type == 'Image' || fileData.type == 'ThreeDModel' || fileData.type == 'Video') {
					this.enableCheckOut = true;
				}

				//this.enableDelete = (fileData.deleteEnabled == 'Yes');
				this.enableDelete = this.deleteCap;

				this.enableEdit = false;
				this.enableView = true;
				this.enableMoveFile = true;
				//if (fileData.type != 'ThreeDModel') {

				//	this.enableMoveFile = true;
				//}
				this.sharedService.setIsCheckedIn(true);
			}
		} else {
			this.enableCheckIn = false;
			this.enableCheckOut = this.modifyCap;
			//this.enableCheckOut = (fileData.checkoutEnabled == 'Yes');
			this.enableUndo = false;

			//this.showDonload = true;
			this.enableView = true;
			this.sharedService.setIsCheckedIn(true);
		}

		this.enableRevision = true
		this.enableProperties = true
		this.deleteUpdateSubscription = this.sharedService.deleteUpdateExecute().subscribe(option => this.deleteUpdate(option));

		if (fileData.type == 'ICECourse') {
			this.disable = false;

			this.enableRevision = true;
			// set true to enable properties button 
			this.enableProperties = true;
			this.enableMoveFile = true;

			if (fileData.checkoutUserId == null) {
				console.log("CheckOut UserID is null");
				//no one checkout
				this.enableCheckIn = false;
				this.enableCheckOut = this.modifyCap;
				this.enableUndo = false;
				// uncomment this for enable delete button 
				//this.enableDelete = this.deleteCap;
				// uncomment this for enable duplicate button 
				this.enableDuplicate = true;
				this.enableEdit = false;
				this.enableView = true;
				this.enableDelete = true;

				this.sharedService.setIsCheckedIn(true);

			} else if (fileData.checkoutUserId == loggedInUser.userId) {
				console.log("CheckOut User is the logged in user");
				//checkout by user
				this.enableCheckIn = this.modifyCap;
				this.enableCheckOut = false;
				this.enableUndo = this.modifyCap;

				this.enableDelete = false;

				this.enableDuplicate = false;

				this.enableEdit = this.modifyCap;
				this.enableView = false;

				this.sharedService.setIsCheckedIn(false);

			} else {
				console.log("CheckOut User is not the logged in user");
				//checkout by other
				this.enableCheckIn = false;
				this.enableCheckOut = false;
				this.enableUndo = false;

				this.enableDelete = false;

				this.enableDuplicate = false;
				this.enableMoveFile = true;

				this.enableEdit = false;
				this.enableView = true;

				this.sharedService.setIsCheckedIn(false);
			}
		} else {
			//do nothing
		}

		if (fileData.status == "Active") {
			this.checkboxValue = false;
			this.isFileActive = true;
			this.activeBtnToolTip = this.translate.instant('FILES_DATA.ACTIVE');
		} else if (fileData.status == "Inactive") {
			this.checkboxValue = true;
			this.isFileActive = false;
			this.activeBtnToolTip = this.translate.instant('FILES_DATA.INACTIVE');

			this.enableCheckIn = false;
			this.enableCheckOut = false;
			this.enableUndo = false;

			this.enableEdit = false;

		} else {
			this.disable = true;
		}

		if(fileData.contentType == 'PDF'){
			this.enableAddToProcStore = true;
			this.enableAddToDocumentStore = true;
		}
		if(fileData.type== 'Folder'){
			this.resetenableDisableButtons()
			this.enableMoveFile = true;
			this.disable = false;
		}

	}

	public resetenableDisableButtons() {
		this.enableCheckIn = false
		this.enableCheckOut = false
		this.enableStatus = false
		this.enableUndo = false
		this.enableDelete = false
		this.enableGenerateOP = false
		this.enableDuplicate = false
		this.enableMoveFile = false;
		this.enableWorkFlow = false
		this.enableEdit = false
		this.enableView = false
		this.enableRevision = false
		this.enableProperties = false
		this.showTopicMap = false
		this.showDonload = false
		this.showGenerateOP = false
		this.showWorkFlow = false
		this.isFileActive = false
		this.enableAddToProcStore = false
		this.enableAddToDocumentStore = false
		//this.activeBtnToolTip = this.translate.instant('FILES_DATA.ACTIVE_AND_INACTIVE');
	}

    /**
     * Open checkin model 
     * @param type 
     */
	public openCheckinModal(selectedFileType: any, selectedFileId: any) {
		console.log('[openCheckinModal] (type) ' + selectedFileType);
		console.log('[openCheckinModal] (id) ' + selectedFileId);
		this.filesService.setApiGridData(this.gridApi);
		switch (this.selectedFile.type) {
			case 'ContentItem':
				this.fileComment = ''
				this.fileCheckInTitle = this.selectedFile.name;
				this.bsModalRef = this.modalService.show(this.fileCheckInTemplate, { class: 'modal-lg' })
				break;
			case 'Image':
				this.translate.get('file.popups.new_image_title').subscribe((res: string) => {
					this.modalTitle = res;
				});
				this.selectedFile.description = ''
				this.uploadingFile = undefined
				var initialState = {
					title: this.modalTitle,
					selectedFolder: this.folderService.getSelectedFoldeData(),
					floatingButtonCallback: this
				};
				this.bsModalRef = this.modalService.show(this.imageCheckInTemplate, { initialState });
				break;
			case 'Topic':
				this.openedTabs = this.sharedService.getOpenedTabs();
				console.log("Opened Tabs: " + this.openedTabs);
				if (this.openedTabs.indexOf(selectedFileId) > -1) {
					this.bsModalRef = this.modalService.show(this.checkinWarningTemplate, { class: 'modal-lg' })
				} else {
					this.bsModalRef = this.modalService.show(this.checkingTemplate, { class: 'modal-lg' })
				}
				break;
			case 'TopicMap':
				this.openedTabs = this.sharedService.getOpenedTabs();
				console.log("Opened Tabs: " + this.openedTabs);
				if (this.openedTabs.indexOf(selectedFileId) > -1) {
					this.bsModalRef = this.modalService.show(this.checkinWarningTemplate, { class: 'modal-lg' })
				} else {
					this.bsModalRef = this.modalService.show(this.checkingTemplate, { class: 'modal-lg' })
				}
				break;
			case 'Procedure':
				this.openedTabs = this.sharedService.getOpenedTabs();
				console.log("Opened Tabs: " + this.openedTabs);
				if (this.openedTabs.indexOf(selectedFileId) > -1) {
					this.bsModalRef = this.modalService.show(this.checkinWarningTemplate, { class: 'modal-lg' })
				} else {
					this.bsModalRef = this.modalService.show(this.checkingTemplate, { class: 'modal-lg' })
				}
				break;
			case 'ICECourse':
				this.openedTabs = this.sharedService.getOpenedTabs();
				// if(selectedFileId == this.sharedService.getNewICETabId()){
				// 	selectedFileId = 'ice_cource_1';
				// }
				console.log("Opened Tabs: " + this.openedTabs);
				if ((this.openedTabs.indexOf(selectedFileId) > -1) && this.sharedService.getModifiedStatus(selectedFileId)) {
					this.bsModalRef = this.modalService.show(this.checkinCouseWarningTemplate, { class: 'modal-lg' })
				} else {
					this.bsModalRef = this.modalService.show(this.checkingTemplate, { class: 'modal-lg' })
				}
				break;
			case 'ThreeDModel':
				this.translate.get('file.popups.new_threed_model_title').subscribe((res: string) => {
					this.modalTitle = res;
				});
				this.selectedFile.description = ''
				this.uploadingFile = undefined
				var initialState = {
					title: this.modalTitle,
					selectedFolder: this.folderService.getSelectedFoldeData(),
					floatingButtonCallback: this
				};
				this.bsModalRef = this.modalService.show(this.threeDModelCheckInTemplate, { initialState });
				break;
			case 'Video':
				this.translate.get('file.popups.new_threed_model_title').subscribe((res: string) => {
					this.modalTitle = res;
				});
					this.selectedFile.description = ''
					this.uploadingFile = undefined
					var initialState = {
						title: this.modalTitle,
						selectedFolder: this.folderService.getSelectedFoldeData(),
						floatingButtonCallback: this
					};
					this.bsModalRef = this.modalService.show(this.videoCheckInTemplate, { initialState });
					break;
			default:
				this.bsModalRef = this.modalService.show(this.contentTemplate);
				break;
		}
		//this.enableDisableButtons(this.selectedFile);
	}

    /**
     * checkout button click event 
     */
	public checkoutBtnEvent() {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
		this.filesService.setApiGridData(this.gridApi);
		this.loadingService.showLoading(true, false, this.translate.instant('FILES_DATA.LOAD_CHECKOUT'), 0);
		//console.log('[checkoutBtnEvent] (id/type) ' + JSON.stringify(this.selectedFile.id + '/' + this.selectedFile.type));
		if (this.selectedFile.type === "Image") {
			var data = { imageId: this.selectedFile.id, userToken: UserVariable.getUserToken() };
			this.filesDataService.checkOutImage(data, this);
			return;
		} else if (this.selectedFile.type === "ContentItem") {
			console.log('request to checkout an file');
			var fileData = { fileId: this.selectedFile.id, userToken: UserVariable.getUserToken() };
			this.filesDataService.checkOutFile(fileData, this);
			return;
		} else if (this.selectedFile.type == "ICECourse") {
			// this.openedTabs = this.sharedService.getOpenedTabs();
			// console.log("Opened Tabs: " + this.openedTabs);
			// if (this.openedTabs.indexOf(this.selectedFile.id) > -1) {
			// 	this.loadingService.hideLoading();
			// 	this.bsModalRef = this.modalService.show(this.checkinCouseWarningTemplate)
			// 	return;
			// } else {
				this.filesDataService.checkoutICECourse(this.selectedFile.id, this);
			 	return;
			// }
		}
		else if (this.selectedFile.type == "Topic" || this.selectedFile.type == "Procedure") {
			this.openedTabs = this.sharedService.getOpenedTabs();
			console.log("Opened Tabs: " + this.openedTabs);
			if (this.openedTabs.indexOf(this.selectedFile.id) > -1) {
				this.loadingService.hideLoading();
				this.bsModalRef = this.modalService.show(this.checkinWarningTemplate)
				return;
			} else {
				this.filesDataService.checkoutDocumentById(this.selectedFile.id, this.selectedFile.type, this);
				return;
			}
		} else if (this.selectedFile.type == "ThreeDModel") {
			var modelData = { modelId: this.selectedFile.id, userToken: UserVariable.getUserToken() };
			this.filesDataService.checkOutThreeDModel(modelData, this);
			return;
		} else if (this.selectedFile.type == "Video") {
			var videoData = { videoId: this.selectedFile.id, userToken: UserVariable.getUserToken() };
			this.filesDataService.checkOutVideo(videoData, this);
			return;
		}
		this.filesDataService.checkoutDocumentById(this.selectedFile.id, this.selectedFile.type, this);
	}

    /**
     * build topic map button click event
     * open topic map builder component
     */
	public buildTopicMap() {
		console.log('[buildTopicMap]');
		this.selectedFile.content
		this.sharedService.openTopicMapBuilder(this.selectedFile.id, this.selectedFile.name, 'edit', this.selectedFile.content)
		this.sharedService.openTabRequestWithId(this.selectedFile.id, this.selectedFile.name, 'TopicMapBuilder', null, null)
	}

    /**
     * view topic map button click event
     */
	public viewTopicMap() {
		console.log('[viewTopicMap]');
		let folderPath: string = this.selectedFile.folderPath + this.selectedFile.name;
		if (this.selectedFile.type === "ICECourse") {
			let data = {
				id: this.selectedFile.id,
				viewOnly: true
			}
			this.sharedService.openTabRequestWithId(this.selectedFile.id, this.selectedFile.name, 'IceCourse', data, folderPath);
		} else if (this.selectedFile.type === "TopicMap") {
			this.sharedService.openTopicMapViewer(this.selectedFile.id, this.selectedFile.name, 'view');
		} else if (this.selectedFile.type === "Topic") {
			this.sharedService.openTabRequestWithId(this.selectedFile.id, this.selectedFile.name, "Editor", null, folderPath);
		} else if (this.selectedFile.type === "Procedure") {
			this.sharedService.openTabRequestWithId(this.selectedFile.id, this.selectedFile.name, "Procedures", null, folderPath);
		} else if (this.selectedFile.type === "ContentItem") {
			this.downloadContentItem()
			//Download the File.
		} else if (this.selectedFile.type === "Image") {
			// console.log("selected file type = image this.selectedfile=  "+ JSON.stringify(this.selectedFile))
			this.showImage()
			//Download the File.
		} else if (this.selectedFile.type == "ThreeDModel") {
			this.showThreeDModel(this.selectedFile.id,this.selectedFile.type);
		} else if (this.selectedFile.type == "Video") {
			this.showVideo(this.selectedFile.id,this.selectedFile.type);
		} else {
			//No Obeject Type to View.
		}
		if (this.data.isSearch) {
			this.agGrid.api.deselectAll();
		}
	}

	/**
	 * download pdf, doc, docx, ppt, pptx
	 */
	private downloadContentItem() {
		let parts = this.selectedFile.fileName.split('.')
		if (parts.length < 2) {
			this.translate.get('NEW_IMAGE.INVALID_FILE_NAME').subscribe((res: string) => {
				this.alertService.error(res, false);
			});
			return false
		}
		this.loadingService.showLoading(true, null, "Downloading", null)
		let ext = parts.pop()
		let filename = this.selectedFile.id + "." + ext
		let type = ext.toUpperCase()
		let fileType = null
		switch (type) {
			case ('PDF'):
				fileType = 'application/pdf'
				break
			case ('DOC'):
				fileType = 'application/doc'
				break
			case ('DOCX'):
				fileType = 'application/docx'
				break
			case ('PPT'):
				fileType = 'application/ppt'
				break
			case ('PPTX'):
				fileType = 'application/pptx'
				break
			default:
				this.loadingService.hideLoading()
				return false
		}
		console.log("this.selectedFile==" + JSON.stringify(this.selectedFile));
		this.filesDataService.viewContentItem(filename)
			.subscribe(data => {
				//    let file = new Blob([response], { type: fileType });
				//    FileSaver.saveAs(file, this.selectedFile.name);
				//    this.loadingService.hideLoading()
				let base64 = data.payload;
				const byteString = atob(base64);
				const arrayBuffer = new ArrayBuffer(byteString.length);
				const int8Array = new Uint8Array(arrayBuffer);

				for (let i = 0; i < byteString.length; i++) {
					int8Array[i] = byteString.charCodeAt(i);
				}

				let file = new Blob([arrayBuffer], { type: fileType });

				let fileNameToSave = this.selectedFile.name;
				let nameParts = this.selectedFile.name.split('.');
				if(nameParts.length == 1 || nameParts.pop() != ext){
					fileNameToSave += "."+ext;
				}
				
				FileSaver.saveAs(file, fileNameToSave);
				this.loadingService.hideLoading()

			},
				error => {
					console.log(error.statusDescription)
					this.loadingService.hideLoading()
					let alert = this.translate.instant('FILES_DATA.ERROR_DOWNLOAD_FILE');
					this.alertService.error(alert)
				});
	}
	/**
	 * get full image file and show
	 */
	private showImage() {
		console.log("[showImage] id= " + this.selectedFile.id + " name= " + this.selectedFile.name);
		this.isLoadedImage = false;
		this.base64FullImage = null;
		this.getFullImage();
		this.viewModalRef = this.modalService.show(this.viewImageTemplate, { backdrop: true, class: 'modal-lg' });
	}
	/**
	 * get image 
	 */
	private getFullImage() {
		let to: any = false;
		if (to) { clearTimeout(to); }
		to = setTimeout(() => {
			this.filesDataService.getImage(this.selectedFile.id, this.selectedFile.imagePath).subscribe(
				data => {
					if (data.payload) {
						this.base64FullImage = data.payload;
						this.isLoadedImage = true;
					} else {
						this.modelRefCloseEvent()
					}
				},
				error => {
					this.modelRefCloseEvent()
					this.alertService.error(error.statusDescription)
				});
		}, 200);
	}
	/**
	 * close image view model
	 */
	public modelRefCloseEvent() {
		this.viewModalRef.hide();
		this.url = null;
	}


    /**
     * undo check out button click event
     */
	public undoCheckOut() {
		//console.log('[undoCheckOut]');
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
		this.filesService.setApiGridData(this.gridApi);
		this.loadingService.showLoading(true, false, this.translate.instant('FILES_DATA.LOAD_UNDO_CHECKOUT'), 0);
		if (this.selectedFile.type === "Image") {
			var data = { imageId: this.selectedFile.id, userToken: UserVariable.getUserToken() };
			this.filesDataService.undoCheckOutImage(data, this);
			return;
		} else if (this.selectedFile.type === "ContentItem") {
			var fileData = { fileId: this.selectedFile.id, userToken: UserVariable.getUserToken() };
			this.filesDataService.undoCheckOutFile(fileData, this);
			return;
		} else if (this.selectedFile.type === "ICECourse") {
			this.openedTabs = this.sharedService.getOpenedTabs();
			console.log("Opened Tabs: " + this.openedTabs);
			if (this.openedTabs.indexOf(this.selectedFile.id) > -1  && this.sharedService.getModifiedStatus(this.selectedFile.id)) {
				this.loadingService.hideLoading();
				this.bsModalRef = this.modalService.show(this.checkinCouseWarningTemplate)
				return;
			} else {
				this.filesDataService.undoCheckoutICECourse(this.selectedFile.id, this);
			 	return;
			}
		}
		else if (this.selectedFile.type == "Topic" || this.selectedFile.type == "Procedure" || this.selectedFile.type == 'TopicMap') {
			this.openedTabs = this.sharedService.getOpenedTabs();
			console.log("Opened Tabs: " + this.openedTabs);
			if (this.openedTabs.indexOf(this.selectedFile.id) > -1) {
				this.loadingService.hideLoading();
				this.bsModalRef = this.modalService.show(this.checkinWarningTemplate)
				return;
			} else {
				this.filesDataService.undoCheckoutDocumentById(this.selectedFile.id, this.selectedFile.type, this);
				return;
			}
		} else if (this.selectedFile.type === "ThreeDModel") {
			var modeldata = { modelId: this.selectedFile.id, userToken: UserVariable.getUserToken() };
			this.filesDataService.undoCheckOutThreeDModel(modeldata, this);
			return;
		}else if (this.selectedFile.type === "Video") {
			var modeldata = { modelId: this.selectedFile.id, userToken: UserVariable.getUserToken() };
			this.filesDataService.undoCheckOutVideo(modeldata, this);
			return;
		}
		this.filesDataService.undoCheckoutDocumentById(this.selectedFile.id, this.selectedFile.type, this);
	}

	openModalUndoCheckoutConfirm() {
		if (this.selectedFile.type == "ThreeDModel") {
			this.fileTypeUndoCheckout = "3D Model";
		} else {
			this.fileTypeUndoCheckout = this.selectedFile.type;
		}
		this.undocheckoutWarningModalRef = this.modalService.show(this.undo_checkout_warn);
	}


    /**
     * active inactive button click event
     */
	public activeBtnEvent() {
		//console.log('[activeBtnEvent] (isFileActive) ' + JSON.stringify(this.isFileActive));
		localStorage.setItem("isDelete", "false");
		if (this.isFileActive) {
			this.sharedService.setDependencyMessageType(Constants.DEPENDENCY_MESSAGE_TYPE.DISABLE.toString());
			this.dependenciesModalWithComponent(this.translate.instant('FILES_DATA.DISABLE_CONFIRM'), false, true);
		} else {
			this.sharedService.setDependencyMessageType(Constants.DEPENDENCY_MESSAGE_TYPE.ENABLE.toString());
			this.dependenciesModalWithComponent(this.translate.instant('FILES_DATA.ENABLE_CONFIRM'), false, true);
		}
	}

    /**
     * download file button click event
     */
	public downloadFileBtnEvent() {
		//console.log('[downloadFileBtnEvent]');
	}

    /**
     * delete button click event
     */
	public deleteBtnEvent() {
		localStorage.setItem("isDelete", "true");
		this.filesService.setApiGridData(this.gridApi);
		//console.log('[deleteBtnEvent]');
		this.sharedService.setDependencyMessageType(Constants.DEPENDENCY_MESSAGE_TYPE.DELETE.toString());
		this.dependenciesModalWithComponent(this.translate.instant('FILES_DATA.DELETE_CONFLICTS'), true, true);
	}

    /**
     * generate output button event
     */
	public generateOutputBtnEvent() {
		//console.log('[generateOutputBtnEvent]');
		this.loadingService.showLoading(true, null, "", null);
		this.filesDataService.getFileDetails(this.selectedFile.id, this.selectedFile.type)
			.subscribe(res =>{
				if(this.selectedFile.type=="TopicMap"){
					if(res.payload["topicMapContentCM"]==null){
						this.loadingService.hideLoading();
						this.alertService.clear();
						this.alertService.error(this.translate.instant("FILES_DATA.ERROR_TOPIC_MAP_NOT_CHECKED_IN"), false);
						return;
					}
				}
				
				this.filesDataService.getOutputTypeListByProjectIdDocType(this.selectedFile.type)
					.subscribe(data => {
						this.loadingService.hideLoading()
						this.selectedOutputType = []
						//this.selectedTemplateName = []
						//this.selectedOutputType.push({ 'id': '0', 'text': 'Select Output Type' })
						//this.selectedTemplateName.push({ 'id': '0', 'text': 'Select Template Name' })
						if (data.payload != null) {
							data.payload.forEach(element => {
								this.selectedOutputType.push({ 'id': element, 'text': element })
							});
							const initialState = {
								selectedOutputType: this.selectedOutputType,
								//selectedTemplateName: this.selectedTemplateName,
								outputData: this.outputData,
								idList: [this.selectedFile.id],
								type: this.selectedFile.type,
								outputType: this.selectedOutputType[0].text,
								outputId: this.selectedOutputType[0].id,
								showTable : true,
								selectedFileName : this.selectedFile.name
							}
							this.bsModalRef = this.modalService.show(OutputGenerateModalComponent, { class: 'modal-lg', initialState });
		
						} else {
							// do nothingl
						}
					},
						error => {
							this.loadingService.hideLoading()
							this.alertService.clear()
							this.alertService.error(error.statusDescription)
						});
				
			});
	}

    /**
     * duplicate button event
     */
	public duplicateBtnEvent() {
		this.loadingService.showLoading(true, false, 'Duplicating', 0);
		console.log('[duplicateBtnEvent]' + this.selectedFile.type);
		console.log(this.folderService.getSelectedFoldeData().id)
		console.log(this.selectedFile.id)
		if (this.selectedFile.type == 'Topic') {
			this.filesService.duplicateTopic(this.selectedFile.id, this.selectedFile.name, this.folderService.getSelectedFoldeData().id, this)
		}
		else if (this.selectedFile.type == 'Procedure') {
			this.filesService.duplicateProcedure(this.selectedFile.id, this.selectedFile.name, this.folderService.getSelectedFoldeData().id, this)
		}
		else if (this.selectedFile.type == 'Image') {
			this.filesService.duplicateImage(this.selectedFile.id, this.selectedFile.name, this.folderService.getSelectedFoldeData().id, this)
		}
		else if (this.selectedFile.type == 'ContentItem') {
			this.filesService.duplicateFile(this.selectedFile.id, this.selectedFile.name, this.folderService.getSelectedFoldeData().id, this)
		}
		else if (this.selectedFile.type == 'TopicMap') {
			this.filesService.duplicateTopicMap(this.selectedFile.id, this.selectedFile.name, this.folderService.getSelectedFoldeData().id, this)
		}
		else if (this.selectedFile.type == 'ICECourse') {
			this.loadingService.showLoading(true, false, 'Course Duplicating', 0);
			this.filesService.duplicateICECourse(this.selectedFile.id, this.selectedFile.name, this.folderService.getSelectedFoldeData().id, this)
		} else {
			console.log("This Duplication is not Implemented for now!!!");
			//Unknown Object, No action
		}
		//this.filesService.getFolderContentByFolderId(this);
	}

	/**
     * Move file/Files button event
     */
	public moveFileEvent(){
		if(localStorage.getItem("isMoveFileEvent") === "true"){
			localStorage.setItem("isMoveFileEvent","false")
			console.log("moveFileEvent true ");

			this.translate.get('You have cancelled the file move event.').subscribe((res: string) => {
				this.alertService.warn(res, false);
				
			});
			localStorage.setItem("fileMoveCancelled","true");
		} else {
			console.log("moveFileEvent false ");
			let itemListString = localStorage.getItem(LocalStorage.SELECTED_ITEMS);
			let array = itemListString.split('::');
			let itemCount = array.length - 1;
			this.translate.get('You have selected ('+itemCount+') item(s) to move. Please select a folder to move. Select the Move File button to cancel file move event.').subscribe((res: string) => {
				this.alertService.info(res, false);
				
			});
			this.sharedService.moveFolderItemsEvent(itemListString, true,itemCount)
			localStorage.setItem("isMoveFileEvent","true")
			localStorage.setItem("fileMoveCancelled","false");
		}
	}

	
    /**
     * workflow button event
     */
	public workflowBtnEvent() {
		console.log('[workflowBtnEvent]');
	}

    /**
     * edit button click event
     */
	public editBtnEvent() {
		console.log('[editBtnEvent] (this.selectedFile.type) ' + this.selectedFile.type);
		let data = {
			id: this.selectedFile.id,
			viewOnly: false
		}
		let folderPath: string = this.selectedFile.folderPath + this.selectedFile.name;
		switch (this.selectedFile.type) {
			case 'Topic':
				this.sharedService.openTabRequestWithId(this.selectedFile.id, this.selectedFile.name, "Editor", null, folderPath);
				break;
			case 'Procedure':
				this.sharedService.openTabRequestWithId(this.selectedFile.id, this.selectedFile.name, "Procedures", null, folderPath);
				break;
			case 'TopicMap':
				this.selectedFile.content
				this.sharedService.openTopicMapBuilder(this.selectedFile.id, this.selectedFile.name, 'edit', this.selectedFile.content)
				this.sharedService.openTabRequestWithId(this.selectedFile.id, this.selectedFile.name, 'TopicMapBuilder', null, folderPath)
				break;
			case 'ICECourse':
				this.sharedService.openTabRequestWithId(this.selectedFile.id, this.selectedFile.name, 'IceCourse', data, folderPath);
				break;
			default:
				break;
		}
		if (this.data.isSearch) {
			this.agGrid.api.deselectAll();
		}
	}

	public closeCourseWarning(){
		this.bsModalRef = this.modalService.show(this.checkingTemplate, { class: 'modal-lg' })
	}

    /**
     * show revision button click event
     */
	public showRevisionBtnEvent() {
		//console.log('[showRevisionBtnEvent]');
		if (this.selectedFile.type != 'ICECoure') {
			this.sharedService.setParent(this);
		}
		this.bsModalRef = this.modalService.show(this.revisionTemplate, { class: 'modal-lg' })
	}

	/**
	 * reset shared service parent when close revision model
	 */
	public resetSharedServiceParent() {
		if (this.selectedFile.type != 'ICECoure') {
			this.sharedService.setParent(null);
			this.sharedService.setRevisionParent(null)
		}
	}

    /**
     * show properties and attributes button click event
     */
	public showPropertiesAttributesBtnEvent() {
		//console.log('[showPropertiesAttributesBtnEvent]');
		this.showPropertiesPanel = !this.showPropertiesPanel;
		if(this.showPropertiesPanel && !this.isMinimized){
			this.divHeight = (window.innerHeight) * 2.9 / 7;
		}
		else{
			this.divHeight = (window.innerHeight) * 5 / 7;
		}
	}

	public minimizePropertiesAttributesBtnEvent(){{
		this.isMinimized = !this.isMinimized;
		if(!this.isMinimized){
			this.divHeight = (window.innerHeight) * 2.9 / 7;
		}
		else{
			this.divHeight = (window.innerHeight) * 5 / 7;
		}

	}}

	private dependenciesModalWithComponent(header: String, dflag: boolean, cflag: boolean) {
		const initialState : object = {
			headerTitle: header,
			delFlag: dflag,
			cancelFlag: cflag,
			checkboxVal: this.checkboxValue
		};
		this.sharedService.setParent(this);
        this.sharedService.setAction("getDependencies");
		this.bsModalRef = this.modalService.show(DependenciesmodalComponent, { initialState, class: 'modal-lg' });
	}

	setButtonStatus() {
		console.log("[setButtonStatus]");
		if (this.isFileActive) {
			//this.dependenciesModalWithComponent(this.translate.instant('FILES_DATA.DISABLE_CONFIRM'), false, true);
			if (this.enableCheckOut) {
				this.enableDelete = true;
			} else {
				this.enableDelete = false;
			}
			this.enableCheckOut = false;
			this.enableCheckIn = false;
			this.enableUndo = false;
			this.enableEdit = false;
			this.isFileActive = false;
			this.activeBtnToolTip = this.translate.instant('FILES_DATA.INACTIVE');
			this.checkboxValue = true;
		} else {
			//this.dependenciesModalWithComponent(this.translate.instant('FILES_DATA.ENABLE_CONFIRM'), false, true);
			if (this.selectedFile.checkoutUserId != null) {
				this.enableCheckOut = false;
				this.enableCheckIn = true;
				this.enableUndo = true;
			} else {
				this.enableCheckOut = true;
				this.enableCheckIn = false;
				this.enableUndo = false;
			} if (this.selectedFile.type == "Topic" || this.selectedFile.type == "Procedure") {
				this.enableEdit = true;
			} else {
				this.enableEdit = false;
			}
			this.isFileActive = true;
			this.activeBtnToolTip = this.translate.instant('FILES_DATA.ACTIVE');
			this.checkboxValue = false;
		}
		this.sharedService.setParent(null);
	}

	validateForm(): boolean {
		//console.log('validate the form');
		if (this.uploadFileName == undefined) {
			return false;
		}
		if (this.uploadingFile == undefined) {
			return false;
		}
		return true;
	}

	onUpload() {
		if (!this.validateForm()) {
			return;
		}
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
		const uploadData = new FormData();
		uploadData.append("fileName", this.uploadingFile.name);
		uploadData.append("title", this.uploadFileName);
		uploadData.append("comment", this.fileComment);
		uploadData.append("parentFolderId", this.folderService.getSelectedFoldeData().id);
		uploadData.append("itemId", this.selectedFile.id);
		uploadData.append("itemType", this.selectedFile.type);
		uploadData.append("token", token);
		uploadData.append("file", this.uploadingFile, this.uploadFileName);

		this.fileUploadService.checkInFile(uploadData, this);
	}

	checkIn(comment: any) {
		this.bsModalRef.hide();
		let checkingComment = comment;
		this.loadingService.showLoading(true, false, "Checking In...", 0);
		//let itemListString = localStorage.getItem(LocalStorage.SELECTED_ITEMS);
		//console.log("selected file" + JSON.stringify(this.selectedFile) + "selectedFile" + JSON.stringify(this.selectedItemData))
		if (this.selectedFile.type == "ICECourse") {
			this.filesDataService.checkinICECourse(this.selectedFile.id, this.selectedFile.storeVersion, checkingComment, this);
		} else {
			this.filesDataService.ckeckinDocumentFromFileData(this.selectedFile.id, this.selectedFile.type, checkingComment, this);
		}

	}

	onFileChanged(event) {
		this.uploadingFile = <File>event.target.files[0];
		if (this.uploadingFile != undefined) {
			this.uploadFileName = this.uploadingFile.name;
			// this.fileCheckInTitle = this.uploadingFile.name;
			let parts = this.uploadingFile.name.split('.')
			if (parts.length > 1) {
				this.fileExtension = parts.pop()
				//this.fileCheckInTitle = parts.join('.')
			} else {
				this.translate.get('NEW_FILE.INVALID_FILE_NAME').subscribe((res: string) => {
					this.alertService.clear();
					this.alertService.error(res, false);
				});
			}
		} else {
			this.uploadFileName = '';
		}
	}

	checkDepedencies(payload: any, type: String) {
		if (type = "Topic") {
			this.dependencyData = payload.TopicMap;
		}
		if (this.dependencyData != null) {
			if (this.dependencyData.length > 0) {
				this.dependenciesModalWithComponent(this.translate.instant('FILES_DATA.DELETE_CONFLICTS'), true, false);
			} else {
				this.dependenciesModalWithComponent(this.translate.instant('FILES_DATA.DELETE_CONFLICTS'), false, false);
			}
		}
	}

	private setAlert(response, status?, type?) {
		if (this.bsModalRef != undefined) {
			this.bsModalRef.hide();
		}
		if (response.Topics != undefined) {
			if (response.Topics.Topic.length != undefined) {
				this.headings.push("Topics");
				this.items[this.headings.indexOf("Topics")] = [];
				this.itemStatus[this.headings.indexOf("Topics")] = [];
				response.Topics.Topic.forEach(item => {
					this.items[this.headings.indexOf("Topics")].push(item.Name + " : " + item.Status);
					this.itemStatus[this.headings.indexOf("Topics")].push(item.Success);
				});
			} else {
				this.headings.push("Topic");
				this.items[this.headings.indexOf("Topic")] = [];
				this.itemStatus[this.headings.indexOf("Topic")] = [];
				this.items[this.headings.indexOf("Topic")].push(response.Topics.Topic.Name + " : " + response.Topics.Topic.Status);
				this.itemStatus[this.headings.indexOf("Topic")].push(response.Topics.Topic.Success);
			}
		}
		if (response.TopicMaps != undefined) {
			if (response.TopicMaps.TopicMap.length != undefined) {
				this.headings.push("TopicMaps");
				this.items[this.headings.indexOf("TopicMaps")] = [];
				this.itemStatus[this.headings.indexOf("TopicMaps")] = [];
				response.TopicMaps.TopicMap.forEach(item => {
					this.items[this.headings.indexOf("TopicMaps")].push(item.Name + " : " + item.Status);
					this.itemStatus[this.headings.indexOf("TopicMaps")].push(item.Success);
				});
			} else {
				this.headings.push("TopicMap");
				this.items[this.headings.indexOf("TopicMap")] = [];
				this.itemStatus[this.headings.indexOf("TopicMap")] = [];
				this.items[this.headings.indexOf("TopicMap")].push(response.TopicMaps.TopicMap.Name + " : " + response.TopicMaps.TopicMap.Status);
				this.itemStatus[this.headings.indexOf("TopicMap")].push(response.TopicMaps.TopicMap.Success);
			}
		}
		if (response.Files != undefined) {
			if (response.Files.File.length != undefined) {
				this.headings.push("Files");
				this.items[this.headings.indexOf("Files")] = [];
				this.itemStatus[this.headings.indexOf("Files")] = [];
				response.Files.File.forEach(item => {
					this.items[this.headings.indexOf("Files")].push(item.Name + " : " + item.Status);
					this.itemStatus[this.headings.indexOf("Files")].push(item.Success);
				});
			} else {
				this.headings.push("File");
				this.items[this.headings.indexOf("File")] = [];
				this.itemStatus[this.headings.indexOf("File")] = [];
				this.items[this.headings.indexOf("File")].push(response.Files.File.Name + " : " + response.Files.File.Status);
				this.itemStatus[this.headings.indexOf("File")].push(response.Files.File.Success);
			}
		}
		if (response.Images != undefined) {
			if (response.Images.ProjectImage.length != undefined) {
				this.headings.push("Images");
				this.items[this.headings.indexOf("Images")] = [];
				this.itemStatus[this.headings.indexOf("Images")] = [];
				response.Images.ProjectImage.forEach(item => {
					this.items[this.headings.indexOf("Images")].push(item.Name + " : " + item.Status);
					this.itemStatus[this.headings.indexOf("Images")].push(item.Success);
				});
			} else {
				this.headings.push("Image");
				this.items[this.headings.indexOf("Image")] = [];
				this.itemStatus[this.headings.indexOf("Image")] = [];
				this.items[this.headings.indexOf("Image")].push(response.Images.ProjectImage.Name + " : " + response.Images.ProjectImage.Status);
				this.itemStatus[this.headings.indexOf("Image")].push(response.Images.ProjectImage.Success);
			}
		}
		if (response.Courses != undefined) {
			if (response.Courses.ICECourse.length != undefined) {
				this.headings.push("Courses");
				this.items[this.headings.indexOf("Courses")] = [];
				this.itemStatus[this.headings.indexOf("Courses")] = [];
				response.Courses.ICECourse.forEach(item => {
					this.items[this.headings.indexOf("Courses")].push(item.Name + " : " + item.Status);
					this.itemStatus[this.headings.indexOf("Courses")].push(item.Success);
				});
			} else {
				this.headings.push("ICECourse");
				this.items[this.headings.indexOf("ICECourse")] = [];
				this.itemStatus[this.headings.indexOf("ICECourse")] = [];
				this.items[this.headings.indexOf("ICECourse")].push(response.Courses.ICECourse.Name + " : " + response.Courses.ICECourse.Status);
				this.itemStatus[this.headings.indexOf("ICECourse")].push(response.Courses.ICECourse.Success);
			}
		}
		if (response.Procedures != undefined) {
			if (response.Procedures.Procedure.length != undefined) {
				this.headings.push("Procedures");
				this.items[this.headings.indexOf("Procedures")] = [];
				this.itemStatus[this.headings.indexOf("Procedures")] = [];
				response.Procedures.Procedure.forEach(item => {
					this.items[this.headings.indexOf("Procedures")].push(item.Name + " : " + item.Status);
					this.itemStatus[this.headings.indexOf("Procedures")].push(item.Success);
				});
			} else {
				this.headings.push("Procedure");
				this.items[this.headings.indexOf("Procedure")] = [];
				this.itemStatus[this.headings.indexOf("Procedure")] = [];
				this.items[this.headings.indexOf("Procedure")].push(response.Procedures.Procedure.Name + " : " + response.Procedures.Procedure.Status);
				this.itemStatus[this.headings.indexOf("Procedure")].push(response.Procedures.Procedure.Success);
			}
		}
		if (response.ThreeDModels != undefined) {
			if (response.ThreeDModels.threedmodel.length != undefined) {
				this.headings.push("ThreeDModels");
				this.items[this.headings.indexOf("ThreeDModels")] = [];
				this.itemStatus[this.headings.indexOf("ThreeDModels")] = [];
				response.ThreeDModels.threedmodel.forEach(item => {
					this.items[this.headings.indexOf("ThreeDModels")].push(item.Name + " : " + item.Status);
					this.itemStatus[this.headings.indexOf("ThreeDModels")].push(item.Success);
				});
			} else {
				this.headings.push("ThreeDModel");
				this.items[this.headings.indexOf("ThreeDModel")] = [];
				this.itemStatus[this.headings.indexOf("ThreeDModel")] = [];
				this.items[this.headings.indexOf("ThreeDModel")].push(response.ThreeDModels.threedmodel.Name + " : " + response.ThreeDModels.threedmodel.Status);
				this.itemStatus[this.headings.indexOf("ThreeDModel")].push(response.ThreeDModels.threedmodel.Success);
			}
		}
		if (response.Videos != undefined) {
			if (response.Videos.Video.length != undefined) {
				this.headings.push("Videos");
				this.items[this.headings.indexOf("Videos")] = [];
				this.itemStatus[this.headings.indexOf("Videos")] = [];
				response.Videos.Video.forEach(item => {
					this.items[this.headings.indexOf("Videos")].push(item.Name + " : " + item.Status);
					this.itemStatus[this.headings.indexOf("Videos")].push(item.Success);
				});
			} else {
				this.headings.push("Video");
				this.items[this.headings.indexOf("Video")] = [];
				this.itemStatus[this.headings.indexOf("Video")] = [];
				this.items[this.headings.indexOf("Video")].push(response.Videos.Video.Name + " : " + response.Videos.Video.Status);
				this.itemStatus[this.headings.indexOf("Video")].push(response.Videos.Video.Success);
			}
		}
		if (response.Folders != undefined) {
			if (response.Folders.Folder.length != undefined) {
				this.headings.push("Folders");
				this.items[this.headings.indexOf("Folders")] = [];
				this.itemStatus[this.headings.indexOf("Folders")] = [];
				response.Folders.Folder.forEach(item => {
					this.items[this.headings.indexOf("Folders")].push(item.Name + " : " + item.Status);
					this.itemStatus[this.headings.indexOf("Folders")].push(item.Success);
				});
			} else {
				this.headings.push("Folder");
				this.items[this.headings.indexOf("Folder")] = [];
				this.itemStatus[this.headings.indexOf("Folder")] = [];
				this.items[this.headings.indexOf("Folder")].push(response.Folders.Folder.Name + " : " + response.Folders.Folder.Status);
				this.itemStatus[this.headings.indexOf("Folder")].push(response.Folders.Folder.Success);
			}
		}
		if (this.items != null) {
			this.bsModalRef = this.modalService.show(this.alertToShowMultipleActions);
		}

	
	}

	/* Closes opened tabs of deleted files when deleting multiple items */
	public closeDeletedTabs(response: any){
		if (response.Topics != undefined) {
			if (response.Topics.Topic.length != undefined) {
				response.Topics.Topic.forEach(item => {
					if(item.Success && item.Id != null){
						this.sharedService.deletedTabCloseRequest(item.Id);
					}
				});
			} else {
				if(response.Topics.Topic.Success && response.Topics.Topic.Id != null){
					this.sharedService.deletedTabCloseRequest(response.Topics.Topic.Id);
				}
			}
		}
		if (response.Procedures != undefined) {
			if (response.Procedures.Procedure.length != undefined) {
				response.Procedures.Procedure.forEach(item => {
					if(item.Success && item.Id != null){
						this.sharedService.deletedTabCloseRequest(item.Id);
					}
				});
			} else {
				if(response.Procedures.Procedure.Success && response.Procedures.Procedure.Id != null){
					this.sharedService.deletedTabCloseRequest(response.Procedures.Procedure.Id);
				}
			}
		}
		if (response.Folders != undefined) {
			let deletedFolderIds = [];
			if (response.Folders.Folder.length != undefined) {
				response.Folders.Folder.forEach(item => {
					if(item.Success && item.Id != null){
						this.sharedService.deletedTabCloseRequest(item.Id);

						/* for delete the folder from folder tree */
						deletedFolderIds.push(item.Id);
					}
				});
			} else {
				if(response.Folders.Folder.Success && response.Folders.Folder.Id != null){
					this.sharedService.deletedTabCloseRequest(response.Folders.Folder.Id);

					/* for delete the folder from folder tree */
					deletedFolderIds.push(response.Folders.Folder.Id);
				}
			}

			/* Delete folders from folder tree */
			this.sharedService.deleteFolderFromFolderTreeRequest(deletedFolderIds);
		}
	}

	setTableData(image: any): any {
		if (image != null) {
			this.rowData = [];
			for (let i = 0; i < image.length; i++) {
				if (image[i]["imageID"] != "") {
					if (image[i]["isCheckedOut"] == 0) {
						this.rowData.push({
							fileName: image[i]["fileName"],
							fileStatus: "Image not checked out",
						});
					} else {
						if (image[i]["status"] == 1) {
							this.rowData.push({
								fileName: image[i]["fileName"],
								fileStatus: "Image is ready",
							});
							this.checkInData.push(image[i]);
						} else {
							//nothing
						}
					}
				} else {
					if ((image[i].status == 0) && (image[i].isCheckedOut == 1)) {
						this.rowData.push({
							fileName: image[i]["fileName"],
							fileStatus: "Image not selected",
						});
					} else if ((image[i].status == 0) && (image[i].isCheckedOut == 2)) {
						this.rowData.push({
							fileName: image[i]["fileName"],
							fileStatus: "Image doesn't exist",
						});
					} else {
						console.log(image[i])
					}
				}
			}
		}
	}

	openModal(template: TemplateRef<any>, size: string) {
		let selectedListString = localStorage.getItem(LocalStorage.SELECTED_LIST);
		let selectedItems = JSON.parse(selectedListString);
		this.openedTabs = this.sharedService.getOpenedTabs();
		console.log("Opened Tabs: " + this.openedTabs);
		let j = 0;
		if (this.selectedOpenedItems.length != 0) {
			this.selectedOpenedItems = [];
		}
		for (let i = 0; i <= selectedItems.length - 1; i++) {
			if (this.openedTabs.indexOf(selectedItems[i].id) > -1) {
				this.selectedOpenedItems[j] = selectedItems[i];
				j++;
			}
		}
		if (this.selectedOpenedItems.length != 0) {
			for (let i = 0; i <= this.selectedOpenedItems.length - 1; i++) {
				this.selectedOpenedItems[i].fileName = this.selectedOpenedItems[i].fileName.replace("tooltip:", "");
				this.selectedOpenedItems[i].type = this.selectedOpenedItems[i].type.substring(0, this.selectedOpenedItems[i].type.indexOf(':'));;
			}
			this.loadingService.hideLoading();
			this.bsModalRef = this.modalService.show(this.checkinAllWarningTemplate)
		} else {
			if (size == 'lg') {
				this.bsModalRef = this.modalService.show(template, { class: 'modal-lg' });
			} else {
				this.bsModalRef = this.modalService.show(template);
			}
		}
	}

	warnModal(template: TemplateRef<any>, size: string) {
		if (size == 'lg') {
			this.bsModalRef = this.modalService.show(template, { class: 'modal-lg' });
		} else {
			this.bsModalRef = this.modalService.show(template);
		}
	}

	openMultipleConfirmationModal(action: string, size: string) {
		console.log(action);
		this.multipleConfirmMessage = action;
		console.log(this.multipleConfirmMessage);
		if(action == 'delete'){
			let itemListString = localStorage.getItem(LocalStorage.SELECTED_ITEMS);
			this.sharedService.setDependencyMessageType(Constants.DEPENDENCY_MESSAGE_TYPE.DELETE.toString());
			this.sharedService.setMultipleSelectedItems(itemListString);
			this.dependenciesModalWithComponent(this.translate.instant('FILES_DATA.DELETE_CONFLICTS'), true, true);
		} else{
			if (size == 'lg') {
				this.bsModalRef = this.modalService.show(this.multipleActionConfirmationTemplate, { class: 'modal-lg' });
			} else {
				this.bsModalRef = this.modalService.show(this.multipleActionConfirmationTemplate, {});
			}
 		}
	}

	multipleActionSelection() {
		console.log(this.multipleConfirmMessage);
		switch (this.multipleConfirmMessage) {
			case "delete":
				this.deleteAll();
				break;
			case "enable":
				this.enableAll();
				break;
			case "disable":
				this.disableAll();
				break;
			default: break;
		}
		this.multipleConfirmMessage = undefined;
	}

    /**
     * alert to show multiple action modal
     * ok button click event
     */
	ok() {
		this.items = [];
		this.refresh();
		this.bsModalRef.hide();
	}

	refresh() {
		this.filesService.getFolderContentByFolderId(this);
	}

	cancel() {
		this.items = [];
		this.imageFiles  = [];
		this.videoFiles = [];
		this.threeDModels = [];
		this.refresh();
		this.bsModalRef.hide();
	}

	private deleteUpdate(option: any) {
		if (option != null) {
			this.enableCheckOut = option.flag;
			this.isFileActive = option.flag;
			this.checkboxValue = option.checkboxVal;
			this.enableStatus = option.tooltip;
		}
	}

    /**
     * multi select button function 
     * enable all button
     */
	public enableAll() {
		let tabIdList : Array<string> = [];
		let itemListString = localStorage.getItem(LocalStorage.SELECTED_ITEMS);
		this.filesDataService.enableAll(itemListString, this);
		let selectedListString = localStorage.getItem(LocalStorage.SELECTED_LIST);
		let selectedItems = JSON.parse(selectedListString);
		for (let i = 0; i <= selectedItems.length - 1; i++) {
			let item = selectedItems[i];
			let active = (item.type.split(':')[1]).split(',')[1];
			if (active === "Inactive") {
				tabIdList.push(item.id);
			}
		}
		this.sharedService.deletedTabListCloseRequest(tabIdList);
		//this.loadingService.showLoading(false, false, "Loading...", 0);
	}

    /**
     * multi select button function 
     * disable all button
     */
	public disableAll() {
		let tabIdList : Array<string> = [];
		let itemListString = localStorage.getItem(LocalStorage.SELECTED_ITEMS);
		console.log(itemListString);
		this.filesDataService.disableAll(itemListString, this);
		let selectedListString = localStorage.getItem(LocalStorage.SELECTED_LIST);
		let selectedItems = JSON.parse(selectedListString);
		for (let i = 0; i <= selectedItems.length - 1; i++) {
			let item = selectedItems[i];
			let active = (item.type.split(':')[1]).split(',')[1];
			if (active === "Active") {
				tabIdList.push(item.id);
			}
		}
		this.sharedService.deletedTabListCloseRequest(tabIdList);
		//this.loadingService.showLoading(false, false, "Loading...", 0);
	}

    /**
     * multi select button function 
     * checkin all button
     */
	public checkinAll(comment) {
		this.bsModalRef.hide();
		let checkingComment = comment;
		let itemListString = localStorage.getItem(LocalStorage.SELECTED_ITEMS);

		this.filesDataService.checkinAll(itemListString, checkingComment, this);
		this.loadingService.showLoading(false, false, "Loading...", 0);
	}

    /**
     * multi select button function 
     * check out all button
     */
	public checkoutAll() {
		let itemListString = localStorage.getItem(LocalStorage.SELECTED_ITEMS);
		let selectedListString = localStorage.getItem(LocalStorage.SELECTED_LIST);
		let selectedItems = JSON.parse(selectedListString);
		this.openedTabs = this.sharedService.getOpenedTabs();
		console.log("Opened Tabs: " + this.openedTabs);
		let j = 0;
		if (this.selectedOpenedItems.length != 0) {
			this.selectedOpenedItems = [];
		}
		for (let i = 0; i <= selectedItems.length - 1; i++) {
			if (this.openedTabs.indexOf(selectedItems[i].id) > -1) {
				this.selectedOpenedItems[j] = selectedItems[i];
				j++;
			}
		}
		if (this.selectedOpenedItems.length != 0) {
			for (let i = 0; i <= this.selectedOpenedItems.length - 1; i++) {
				this.selectedOpenedItems[i].fileName = this.selectedOpenedItems[i].fileName.replace("tooltip:", "");
				this.selectedOpenedItems[i].type = this.selectedOpenedItems[i].type.substring(0, this.selectedOpenedItems[i].type.indexOf(':'));;
			}
			this.loadingService.hideLoading();
			this.bsModalRef = this.modalService.show(this.checkinAllWarningTemplate);
		} else {
			this.filesDataService.checkoutAll(itemListString, this);
			this.loadingService.showLoading(false, false, "Loading...", 0);
		}
	}

    /**
     * multi select button function 
     * delete all button
     */
	public deleteAll() {
		let tabIdList : Array<string> = [];
		let itemListString = localStorage.getItem(LocalStorage.SELECTED_ITEMS);
		this.filesDataService.deleteAll(itemListString, this);
		let selectedListString = localStorage.getItem(LocalStorage.SELECTED_LIST);
		let selectedItems = JSON.parse(selectedListString);
		for (let i = 0; i <= selectedItems.length - 1; i++) {
			tabIdList.push(selectedItems[i].id);
		}
		this.sharedService.deletedTabListCloseRequest(tabIdList);
		//this.loadingService.showLoading(false, false, "Loading...", 0);
	}

    /**
     * multi select button function 
     * undo check out all button
     */
	public undoCheckoutAll() {
		let itemListString = localStorage.getItem(LocalStorage.SELECTED_ITEMS);
		let selectedListString = localStorage.getItem(LocalStorage.SELECTED_LIST);
		let selectedItems = JSON.parse(selectedListString);
		this.openedTabs = this.sharedService.getOpenedTabs();
		console.log("Opened Tabs: " + this.openedTabs);
		let j = 0;
		if (this.selectedOpenedItems.length != 0) {
			this.selectedOpenedItems = [];
		}
		for (let i = 0; i <= selectedItems.length - 1; i++) {
			if (this.openedTabs.indexOf(selectedItems[i].id) > -1) {
				this.selectedOpenedItems[j] = selectedItems[i];
				j++;
			}
		}
		if (this.selectedOpenedItems.length != 0) {
			for (let i = 0; i <= this.selectedOpenedItems.length - 1; i++) {
				this.selectedOpenedItems[i].fileName = this.selectedOpenedItems[i].fileName.replace("tooltip:", "");
				this.selectedOpenedItems[i].type = this.selectedOpenedItems[i].type.substring(0, this.selectedOpenedItems[i].type.indexOf(':'));;
			}
			this.loadingService.hideLoading();
			this.bsModalRef = this.modalService.show(this.checkinAllWarningTemplate);
		} else {
			this.filesDataService.undoCheckoutAll(itemListString, this);
			this.loadingService.showLoading(false, false, "Loading...", 0);
		}
	}

    /**
     * multi select button function 
     * duplicate all button
     */
	public duplicateAll() {
		let itemListString = localStorage.getItem(LocalStorage.SELECTED_ITEMS);
		this.filesDataService.duplicateAll(itemListString, this);
		this.loadingService.showLoading(false, false, "Loading...", 0);
	}

    /**
     * multi select button function 
     * work flow all button
     */
	public workFlowActionAll() {

	}

	/**
	* Fires the method when change the file selection.
	* @param event event type
	*/
	onImageFileChanged(event) {
		this.uploadingFile = <File>event.target.files[0];
		if (this.uploadingFile != undefined) {
			let parts = this.uploadingFile.name.split('.')
			if (parts.length > 1) {
				this.uploadFileExt = parts.pop()
				this.uploadFileName = parts.join('.')
				this.fileTitle = parts.join('.')
				//this.selectedFile.title = this.fileTitle
			} else {
				this.translate.get('NEW_IMAGE.INVALID_FILE_NAME').subscribe((res: string) => {
					this.alertService.error(res, false);
				});
			}
			// this.uploadFileName = this.uploadingFile.name.split('.')[0];
			// this.uploadFileExt = this.uploadingFile.name.split('.')[1];
			// this.fileTitle = this.uploadingFile.name.split('.')[0];
			// this.selectedFile.title = this.uploadingFile.name.split('.')[0];
		} else {
			this.uploadFileName = '';
		}
	}

	/**
	 * image checkin button click event.
	 */
	public onImageCheckInSubmitButtonClick() {
		console.log('save');
		if (!this.validateImageForm()) {
			return;
		}

		var loadingMessage;
		this.translate.get('NEW_IMAGE.LOADING_MESSAGE').subscribe((res: string) => {
			loadingMessage = res;
		});
		this.loadingService.showLoading(true, null, loadingMessage, null);
		var attributeValueXml: string = '';
		//creating attribute xml string.
		if (this.selectedFile.attributes.length > 0) {
			attributeValueXml = '<attrs>';
			this.selectedFile.attributes.forEach(item => {
				attributeValueXml += "<attr type=\"text\" name='" + item.attributeName + "' typeattributeid='" + item.attributeId + "'>" + item.value + "</attr>";
			});
			attributeValueXml += '</attrs>';
		}
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
		const uploadData = new FormData();
		uploadData.append("fileName", this.uploadFileName);
		uploadData.append("fileNameExt", this.uploadFileExt);
		uploadData.append("title", this.selectedFile.title);
		uploadData.append("comment", this.selectedFile.description);
		uploadData.append("parentFolderId", this.folderId);
		//uploadData.append("attributes", attributeValueXml);
        uploadData.append("token", token);
		uploadData.append("file", this.uploadingFile, this.uploadFileName);
		uploadData.append("itemId", this.selectedFile.id);
		uploadData.append("itemType", "Image");

		this.fileUploadService.checkInImage(uploadData, this);
	}

	validateImageForm(): boolean {
		this.selectedFile.title = this.selectedFile.title.trim()
		if (this.selectedFile.title) {
		} else {
			return false;
		}
		if (this.uploadingFile == undefined) {
			return false;
		}
		return true;
	}

	validateFileForm(): boolean{	
		var fileextension = this.uploadingFile.name.split('.').pop();
    	console.log("ext: " + fileextension.toLowerCase());
		if(fileextension === 'pdf' || fileextension === 'ppt'||fileextension === 'pptx'|| fileextension === 'doc'||fileextension === 'docx'){
			return true;
		}
		else{
			this.translate.get('CHECKIN_FILE.INVALID_VIDEO_FILE_TYPE').subscribe((res: string) => {
				this.alertService.error(res, false);
			});
			return false;
		}
	
	
	}

	/**
	 * file checkin button click event.
	 */
	public onSubmitButtonClick(templateType: string) {

		console.log("== onSubmitButtonClick= " + templateType)
		if (this.uploadingFile == null || this.uploadingFile == undefined) {
			// this.alertService.error('Empty files cannot be checked in');
			return;
		}
		if (templateType == 'fileCheckInTemplate') {

			if (!this.validateFileForm()) {
				return;
			}
			this.fileCheckInTitle = this.fileCheckInTitle.trim()
			if (this.fileCheckInTitle) {
				this.fileCheckInTitle2 = this.fileCheckInTitle;
			} else {
				this.fileCheckInTitle = ""
				return;
			}
		}
		var loadingMessage;
		this.translate.get('CHECKIN_FILE.LOADING_MESSAGE').subscribe((res: string) => {
			loadingMessage = res;
		});
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
		this.loadingService.showLoading(true, null, loadingMessage, null);
		const uploadData = new FormData();
		uploadData.append("file", this.uploadingFile, this.uploadFileName);
		uploadData.append("title", this.fileCheckInTitle2);
		uploadData.append("comment", this.fileComment);
		uploadData.append("itemId", this.selectedFile.id);
		uploadData.append("folderId", this.folderId);
		uploadData.append("fileName", this.uploadFileName);
        uploadData.append("userToken", token);

		this.filesService.checkInFile(uploadData, this);
	}


	updateSelectedFile(responseData: WsResponse['payload'], action: string) {

		//set selected file data
		let fileData = new FileModel();
		if ((this.selectedFile.type == 'Image') && (responseData.type == 'Image')) {
			this.selectedFile.pathToThumb = responseData.pathToThumb
			this.selectedFile.imagePath = responseData.imagePath
			this.selectedFile.imagedata = responseData.imagedata
			this.selectedFile.name = responseData.name
		}
		if ((this.selectedFile.type == 'ContentItem') && (responseData.type == 'ContentItem')) {
			this.selectedFile.name = responseData.title
		}
		if ((this.selectedFile.type == 'ThreeDModel') && (responseData.type == 'ThreeDModel')) {
			this.selectedFile.name = responseData.title
		}if ((this.selectedFile.type == 'Video') && (responseData.type == 'Video')) {
			this.selectedFile.name = responseData.title
		}
		fileData = this.selectedFile;
		fileData.checkoutBy = responseData.checkoutBy;
		fileData.checkoutUserId = responseData.checkoutUserId;
		fileData.lastUpdateTimeStamp = responseData.lastUpdateTimeStamp;
		const loggedUserId = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER));
		if (action == "checkin") {
			fileData.checkInEnabled = "No";
			fileData.checkoutEnabled = "Yes";
			fileData.undoCheckoutEnable = "No";
			fileData.checkoutUserId = null;
		} else if (action == "enableDisable") {
			this.sharedService.setParent(null);
			//fileData.status = fileData.status != "Active" ? "Inactive": fileData.status != "Inactive"? "Active": fileData.status;
			fileData.status = responseData.status;
			if (fileData.status == "Active") {
				this.sharedService.setStatus("Active");
				this.sharedService.disableCellRendererRequest(false, fileData.id);
			} else if (fileData.status == "Inactive") {
				this.sharedService.setStatus("Inactive");
				this.sharedService.disableCellRendererRequest(true, fileData.id);
			} else {
				// nothing to do
			}
		} else {
			fileData.checkInEnabled = "Yes";
			fileData.checkoutEnabled = "No";
			fileData.undoCheckoutEnable = "Yes";
			fileData.checkoutUserId = loggedUserId.userId;
		}
		//Added to update fileName after doing undo checkout or restore
		if(this.selectedFile.type == 'ICECourse'){
			fileData.type = "ICECourse";
			fileData.statusEnable = "Yes";
			if(responseData.checkoutUserId != null){
				fileData.editable = "Yes";
			}else{
				fileData.editable = "No";
			}
			fileData.id = responseData.courseId;
			fileData.status = responseData.status;
			fileData.name = responseData.fileName;
			fileData.storeVersion = responseData.courseVersion;
			this.filesService.loadFiles("tooltip:" + fileData.name, fileData.id, "fileName")
		}
			this.sharedService.setFileId(fileData.id);
			this.sharedService.setFileType(fileData.type);
			this.sharedService.setAction("getDependencies")
			this.sharedService.setStatus(fileData.status);
			this.filesService.setSelectedFileData(fileData);
			this.selectedFile = fileData;				
	}

	public capabilityCheck(type: String) {

		let modifyCapability: string = "modify";
		let queryCapability: string = "query";
		let deleteCapability: string = "delete";
		let disableCapability: string = "disable";
		let createCapability: string = "create";

		modifyCapability += type;
		queryCapability += type;
		deleteCapability += type;
		disableCapability += type;
		createCapability += type;

		this.modifyCap = this.capabilityService.isCapabilityAssigned(modifyCapability);
		this.queryCap = this.capabilityService.isCapabilityAssigned(queryCapability);
		this.deleteCap = this.capabilityService.isCapabilityAssigned(deleteCapability);
		this.disableCap = this.capabilityService.isCapabilityAssigned(disableCapability);
		this.createCap = this.capabilityService.isCapabilityAssigned(createCapability);
	}
	close() {
        this.bsModalRef.hide();
    }	

	closeVideoCheckinModal() {
		this.posterframe = null;
		this.thumbnail = null;
		this.uploadingVideoFile = [];

        this.bsModalRef.hide();
    }	

	/**
	 * User clicks on the 3D model floating button.
	 * @param template view template
	 */
	public addNewThreeDModel(): void {
		this.translate.get('file.popups.new_threed_model_title').subscribe((res: string) => {
			this.modalTitle = res;
		});
		const initialState = {
			title: this.modalTitle,
			selectedFolder: this.folderService.getSelectedFoldeData(),
			floatingButtonCallback: this
		};
		this.bsModalRef = this.modalService.show(NewThreedModelComponent, { initialState, backdrop: 'static', keyboard: false });
		this.resetenableDisableButtons();
		this.multipleSelections = false;
	}

	onThreeDModelFileChanged(event) {
		this.uploadingFile = <File>event.target.files[0];
		if (this.uploadingFile != undefined) {
			let parts = this.uploadingFile.name.split('.')
			if (parts.length > 1) {
				this.uploadFileExt = parts.pop()
				this.uploadFileName = parts.join('.')
				this.fileTitle = parts.join('.')
				//this.selectedFile.title = this.fileTitle
			} else {
				this.translate.get('NEW_THREED_MODEL.INVALID_FILE_NAME').subscribe((res: string) => {
					this.alertService.error(res, false);
				});
			}

		} else {
			this.uploadFileName = '';
		}
	}

	onThreeDModelCheckInSubmitButtonClick() {
		console.log('save');
		if (!this.validateImageForm()) {
			return;
		}

		var loadingMessage;
		this.translate.get('NEW_THREED_MODEL.LOADING_MESSAGE').subscribe((res: string) => {
			loadingMessage = res;
		});
		this.loadingService.showLoading(true, null, loadingMessage, null);
		var attributeValueXml: string = '';
		//creating attribute xml string.
		if (this.selectedFile.attributes.length > 0) {
			attributeValueXml = '<attrs>';
			this.selectedFile.attributes.forEach(item => {
				attributeValueXml += "<attr type=\"text\" name='" + item.attributeName + "' typeattributeid='" + item.attributeId + "'>" + item.value + "</attr>";
			});
			attributeValueXml += '</attrs>';
		}
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
		const uploadData = new FormData();
		uploadData.append("fileName", this.uploadFileName);
		uploadData.append("fileNameExt", this.uploadFileExt);
		uploadData.append("title", this.selectedFile.title);
		uploadData.append("comment", this.selectedFile.description);
		uploadData.append("parentFolderId", this.folderId);
		//uploadData.append("attributes", attributeValueXml);
        uploadData.append("token", token);
		uploadData.append("file", this.uploadingFile, this.uploadFileName);
		uploadData.append("itemId", this.selectedFile.id);
		uploadData.append("itemType", "ThreeDModel");

		this.fileUploadService.checkInThreeDModel(uploadData, this);
	}


	showThreeDModel(modelId: String, type:String){
		console.log("showThreeDModel =" + modelId)
		console.log("show Model type =" + type)
		const initialState : object = {
			id: modelId,
			selectedFolder: this.folderService.getSelectedFoldeData()
		};
		// this.showThreeDModelViiew(modelId,type);
		// this.viewModalRef= this.modalService.show(this.viewThreeDModelTemplate, { backdrop: true, class: 'modal-lg' });
		this.viewModalRef= this.modalService.show(ThreedModelViewerComponent, {initialState, backdrop: true, class: 'modal-lg' });
		//this.filesService.threeDModelView( this,"ThreeDModel", modelId);
		// ThreedModelViewerComponent.showThreeDModelViiew(modelId, "ThreeDModel");
	}
	showThreeDModelViiew(id: String, itemType:String){
		console.log("showThreeDModelViiew of " + id)
	
	  this.filesService.getThreeDModelURL(id).subscribe(data => {
		if (data.payload) {
			this.url = data.payload;
			console.log("url " + this.url)
		} else {
			//do nothing
		}
		
	 },
		error => {
		   this.alertService.error(error.statusDescription)
		});
	  this.isLoadedModel = true;
	  
	  
		}

		showVideo(videoId: String, type:String){
			// console.log("showThreeDModel =" + videoId)
			// console.log("show Model type =" + type)
			
			this.showVideoView(videoId,type);
			this.viewModalRef= this.modalService.show(this.viewVideoTemplate, { backdrop: true, class: 'modal-lg' });
			//this.filesService.threeDModelView( this,"ThreeDModel", modelId);
			// ThreedModelViewerComponent.showThreeDModelViiew(modelId, "ThreeDModel");
		}
		showVideoView(id: String, itemType:String){
			console.log("showThreeDModelViiew of " + id)
		  this.filesService.getPosterframeURL(id).subscribe(data => {
				if (data.payload) {
					this.selectedVideoPosterframeURL = data.payload;
					console.log("selectedVideoPosterframeURL " + this.selectedVideoPosterframeURL)
				} else {
					//do nothing
				}
				
				},
				error => {
					this.alertService.error(error.statusDescription)
				});

		  this.filesService.getVideoURL(id).subscribe(data => {
			if (data.payload) {
				this.url = data.payload;
				console.log("url " + this.url)
			} else {
				//do nothing
			}
			
		 },
			error => {
			   this.alertService.error(error.statusDescription)
			});

			// this.filesService.getPosterframeURL(id).subscribe(data => {
			// 	if (data.payload) {
			// 		this.selectedVideoPosterframeURL = data.payload;
			// 		console.log("selectedVideoPosterframeURL " + this.selectedVideoPosterframeURL)
			// 	} else {
			// 		//do nothing
			// 	}
				
			//  },
			// 	error => {
			// 	   this.alertService.error(error.statusDescription)
			// 	});

		  this.isLoadedModel = true;
		  
		  
			}

// public ThreeDModelRefCloseEvent() {
// 	this.threeDViewModalRef.hide()
//   }
  
 public addNewVideo(): void{
	this.translate.get('file.popups.new_videos_title').subscribe((res: string) => {
		this.modalTitle = res;
	});
	const initialState = {
		title: this.modalTitle,
		selectedFolder: this.folderService.getSelectedFoldeData(),
		floatingButtonCallback: this
	};
	this.bsModalRef = this.modalService.show(NewVideoComponent , { initialState, backdrop: 'static', keyboard: false });
	this.resetenableDisableButtons();
	this.multipleSelections = false;
 }
 

	posterframeFileAdded(event) {
		this.posterframe = <File>event.target.files[0];
		if (this.posterframe) {
            let parts = this.posterframe.name.split('.')
            if (parts.length > 1) {
                this.fileExtension = parts.pop()
                this.fileName = parts.join('.')
                let posterframeTitle = parts.join('.') + "_posterframe." + this.fileExtension
				this.posterframe = new File([this.posterframe], posterframeTitle, { type: this.posterframe.type });
            }
        }
	}

	thumbfileAdded(event) {
		this.thumbnail = <File>event.target.files[0];	
    }

 onVideoFileChanged(event) {
	this.uploadingFile = <File>event.target.files[0];
	if (this.uploadingFile != undefined) {
		let parts = this.uploadingFile.name.split('.')
		if (parts.length > 1) {
			this.uploadFileExt = parts.pop()
			this.uploadFileName = parts.join('.')
			this.fileTitle = parts.join('.')
			//this.selectedFile.title = this.fileTitle
		} else {
			this.translate.get('NEW_VIDEO.INVALID_FILE_NAME').subscribe((res: string) => {
				this.alertService.error(res, false);
			});
		}

	} else {
		this.uploadFileName = '';
	}
}

onVideoCheckInSubmitButtonClick() {
	console.log('save');
	if (!this.validateVideoForm()) {
		return;
	}

	var loadingMessage;
	this.translate.get('NEW_VIDEO.LOADING_MESSAGE').subscribe((res: string) => {
		loadingMessage = res;
	});
	this.loadingService.showLoading(true, null, loadingMessage, null);
	var attributeValueXml: string = '';
	//creating attribute xml string.
	if (this.selectedFile.attributes.length > 0) {
		attributeValueXml = '<attrs>';
		this.selectedFile.attributes.forEach(item => {
			attributeValueXml += "<attr type=\"text\" name='" + item.attributeName + "' typeattributeid='" + item.attributeId + "'>" + item.value + "</attr>";
		});
		attributeValueXml += '</attrs>';
	}
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
        return;
    }
	this.uploadingVideoFile.push(this.uploadingFile);
	const uploadData = new FormData();
	uploadData.append("fileName", this.uploadFileName);
	uploadData.append("fileNameExt", this.uploadFileExt);
	uploadData.append("title", this.selectedFile.title);
	uploadData.append("comment", this.selectedFile.description);
	uploadData.append("parentFolderId", this.folderId);
	//uploadData.append("attributes", attributeValueXml);
    uploadData.append("token", token);
	// uploadData.append("file", this.uploadingFile, this.uploadFileName);
	if(this.uploadingVideoFile.length){
		for(let i=0 ; i < this.uploadingVideoFile.length ; i++){
		  uploadData.append("file", this.uploadingVideoFile[i],this.uploadingVideoFile[i].name);
		  this.uploadingVideoFile.splice(i-1,i);
		}
	}
	if (this.posterframe) {
		uploadData.append("file", this.posterframe, this.posterframe.name);
	}
	if (this.thumbnail) {
		uploadData.append("file", this.thumbnail, this.thumbnail.name);
	}
	uploadData.append("itemId", this.selectedFile.id);
	uploadData.append("itemType", "Video");

	this.fileUploadService.checkInVideo(uploadData, this);
}

validateVideoForm(): boolean{

	this.selectedFile.title = this.selectedFile.title.trim()
	if (this.selectedFile.title) {
	} else {
		return false;
	}
	if (this.uploadingFile == undefined || this.posterframe == undefined || this.thumbnail == undefined) {
		return false;
	}

	var videoextension = this.uploadingFile.name.split('.').pop();
    console.log("ext: " + videoextension.toLowerCase());
    if (videoextension.toLowerCase() != 'mp4') {
        this.translate.get('CHECK_IN_VIDEO.INVALID_VIDEO_FILE_TYPE').subscribe((res: string) => {
            this.alertService.error(res, false);

            });
            return false;
    }

	var thumbnailextension = this.thumbnail.name.split('.').pop();
	console.log("ext: " + thumbnailextension.toLowerCase());
	if (thumbnailextension.toLowerCase() != 'png') {
		this.translate.get('NEW_VIDEO.THUMBNAIL.INVALID_THUMBNAIL_FILE_TYPE').subscribe((res: string) => {
			this.alertService.error(res, false);

		});
		return false;
	}
	var posterframeextension = this.posterframe.name.split('.').pop();
	console.log("ext: " + posterframeextension.toLowerCase());
	if (posterframeextension.toLowerCase() != 'png') {
		this.translate.get('NEW_VIDEO.POSTERFRAME.INVALID_POSTERFRAME_FILE_TYPE').subscribe((res: string) => {
			this.alertService.error(res, false);

		});
		return false;
	}

	return true;

}


handleThreeDModelFileInput(threedmodels){
	const selectedNodes = threedmodels.length;
	this.selectedThreeDModels = "";
	this.threeDModels = [];
	// for (let i = 0; i < this.threeDModels.length; i++) {
	// 	this.threeDModels.pop();
	// 	console.log("pop 3d model")
	// }
	for (let i = 0; i < selectedNodes; i++) {
		this.selectedThreeDModels = this.selectedThreeDModels + threedmodels[i].name + '::';
		this.threeDModels.push(threedmodels[i]);
		console.log("push 3d model "+threedmodels[i].name)
	}
	
}

checkinMultipleThreeDModels(comments) {
	if (!this.validateThreedModels()) {
		return;
	}
	this.checkingThreeDModelComment = comments;
	this.bsModalRef.hide();
	let itemListString = localStorage.getItem(LocalStorage.SELECTED_ITEMS);
	let folderId = localStorage.getItem(LocalStorage.FOLDER_ID);
	let title = this.selectedThreeDModels;
	let token = UserVariable.getUserToken();
    // if(this.commonUtil.validateToken(token)){
	// 	console.log("token validated! ");
    //     // return;
	// }
	this.loadingService.showLoading(true, false, "Checking In...", 0);
	const uploadData = new FormData();
	uploadData.append("token",token);
	uploadData.append("title",title);
	uploadData.append("folderId",folderId);
	uploadData.append("itemList",itemListString);
	uploadData.append("comment",comments);
	if(this.threeDModels.length ){
		console.log("array length "+this.threeDModels.length);
		for(let i=0 ; i < this.threeDModels.length ; i++){
		  uploadData.append("file", this.threeDModels[i],this.threeDModels[i].name);
		//   this.threeDModels.splice(i-1,i);
		  console.log("splice 3d model "+i+this.threeDModels[i].name)
	}
}
	this.filesDataService.getThreeDModelExistenceStatus(uploadData,this);	
	
	for(var i = this.threeDModels.length; i >=0 ; i--){
		console.log("pop array length "+this.threeDModels.length);
		let file = this.threeDModels.splice(i, 1);
			console.log("pop 3d model "+i+file);
		}
	
}

handleVideoFileInput(vieos){
	const selectedNodes = vieos.length;
	this.selectedVideos = "";
	this.videoFiles = [];
	for (let i = 0; i < selectedNodes; i++) {
		this.selectedVideos = this.selectedVideos + vieos[i].name + '::';
		this.videoFiles.push(vieos[i]);
		console.log("push videos "+vieos[i].name)
	}
}

checkinMultipleVideos(comments) {
	if (!this.validateVideos()) {
		return;
	}
	this.checkingThreeDModelComment = comments;
	this.bsModalRef.hide();
	let itemListString = localStorage.getItem(LocalStorage.SELECTED_ITEMS);
	let folderId = localStorage.getItem(LocalStorage.FOLDER_ID);
	let title = this.selectedVideos;
	let token = UserVariable.getUserToken();
    // if(this.commonUtil.validateToken(token)){
	// 	console.log("token validated! ");
    //     // return;
	// }
	this.loadingService.showLoading(true, false, "Checking In...", 0);
	const uploadData = new FormData();
	uploadData.append("token",token);
	uploadData.append("title",title);
	uploadData.append("folderId",folderId);
	uploadData.append("itemList",itemListString);
	uploadData.append("comment",comments);
	if(this.videoFiles.length ){
		console.log("array length "+this.videoFiles.length);
		for(let i=0 ; i < this.videoFiles.length ; i++){
		  uploadData.append("file", this.videoFiles[i],this.videoFiles[i].name);
		//   this.videoFiles.splice(i-1,i);
		  console.log("splice 3d model "+i+this.videoFiles[i].name)
	}
}
	this.filesDataService.checkinMultipleVideos (uploadData,this);	
	
	for(var i = this.videoFiles.length; i >=0 ; i--){
		console.log("pop array length "+this.videoFiles.length);
		let file = this.videoFiles.splice(i, 1);
			console.log("pop 3d model "+i+file);
		}
}

validateVideos(): boolean {
	if(this.videoFiles == undefined || this.videoFiles.length == 0){
		this.translate.get('CHECK_IN_VIDEO.VIDEOS_NOT_SELECTED').subscribe((res: string) => {
			this.alertService.error(res, false);

		});
		return false;
	}
	for (let i=0 ; i < this.videoFiles.length ; i++) {
		var videoextension = this.videoFiles[i].name.split('.').pop();
        if (videoextension.toLowerCase() != 'mp4') {
            this.translate.get('NEW_VIDEO.INVALID_VIDEO_FILE_TYPE').subscribe((res: string) => {
                this.alertService.error(res, false);

            });
            return false;
        }
	}
	return true;
}

validateImages(): boolean {
	if(this.imageFiles == undefined || this.imageFiles.length == 0){
		this.translate.get('CHECK_IN_IMAGE.IMAGES_NOT_SELECTED').subscribe((res: string) => {
			this.alertService.error(res, false);

		});
		return false;
	}
	return true;
}

validateThreedModels(): boolean {
	if(this.threeDModels == undefined || this.threeDModels.length == 0){
		this.translate.get('CHECK_IN_THREED_MODEL.THREED_MODELS_NOT_SELECTED').subscribe((res: string) => {
			this.alertService.error(res, false);

		});
		return false;
	}
	return true;
}

handleImageFileInput(images){
	const selectedNodes = images.length;
	this.selectedImages = "";
	this.imageFiles = [];
	for (let i = 0; i < selectedNodes; i++) {
		this.selectedImages = this.selectedImages + images[i].name + '::';
		this.imageFiles.push(images[i]);
		console.log("push 3d model "+images[i].name)
	}
	
}

getImageExistence(comments) {
	if (!this.validateImages()) {
		return;
	}
	this.checkingImagesComment = comments;
	this.bsModalRef.hide();
	let itemListString = localStorage.getItem(LocalStorage.SELECTED_ITEMS);
	let folderId = localStorage.getItem(LocalStorage.FOLDER_ID);
	let title = this.selectedImages;
	var attributeValueXml: string = '';
	if (this.selectedFile.attributes.length > 0) {
		attributeValueXml = '<attrs>';
		this.selectedFile.attributes.forEach(item => {
			attributeValueXml += "<attr type=\"text\" name='" + item.attributeName + "' typeattributeid='" + item.attributeId + "'>" + item.value + "</attr>";
		});
		attributeValueXml += '</attrs>';
	}
	let token = UserVariable.getUserToken();
	if(!this.commonUtil.validateToken(token)){
		return;
	}
	this.loadingService.showLoading(true, false, "Checking In...", 0);
	const uploadData = new FormData();
	uploadData.append("token",token);
	uploadData.append("title",title);
	uploadData.append("folderId",folderId);
	uploadData.append("itemList",itemListString);
	uploadData.append("attributes", attributeValueXml);
	uploadData.append("comment",comments);
	if(this.imageFiles.length ){
		console.log("array length "+this.imageFiles.length);
		for(let i=0 ; i < this.imageFiles.length ; i++){
		  uploadData.append("file", this.imageFiles[i],this.imageFiles[i].name);
		//   this.videoFiles.splice(i-1,i);
		  console.log("splice 3d model "+i+this.imageFiles[i].name)
	}
}
	this.filesDataService.getImageExistenceStatus(uploadData,this);
	for(var i = this.imageFiles.length; i >=0 ; i--){
		console.log("pop array length "+this.imageFiles.length);
		let file = this.imageFiles.splice(i, 1);
			console.log("pop 3d model "+i+file);
		}
}

	public async addToProcStoreBtnEvent(){
		this.loadingService.showLoading(true, false, 'Loading', 0);
		let latestCMVersion:string = ""; 
		await this.filesService.getLatestVersionByItemIdAndType(this.selectedFile.id,this.selectedFile.type).toPromise().then(
			data => {
				latestCMVersion = data.payload;
				this.filesService.getSelectedProcedureInfo(this.selectedFile.id).subscribe(
					data1 => {
					//	var modified = JSON.parse(JSON.stringify(data));
						if (data1.payload != null && data1.payload.length != 0) {
							let isLatestCMVersion:boolean = false; 
							  if (data1.payload[0].cmRevsionVersion >= latestCMVersion ){
									//pdf version alredy in procedure store
									let alredyAdded = this.translate.instant('PDF_ADD_TO_PROCEDURE_STORE.ALREADY_ADDED');
									this.loadingService.hideLoading();
									this.alertService.error(alredyAdded)
								}  else {
									this.addToProcedureStore(this.selectedFile)
								}
						} else {
							this.addToProcedureStore(this.selectedFile)
						}
					},
					error => {
						this.alertService.error(error.statusDescription)
					});	
			},
			error => {
				this.alertService.error(error.statusDescription)
			});	
	}

	public addToProcedureStore(selectedFile:any) {
		var procedurePDFTempateId = "";
		var inputData = {
			"item": this.selectedFile.type,
			"itemId": this.selectedFile.id,
			"outputType": "PROCEDURE_ZIP_PDF",
			"documentType" : "ExternalPDF"
		 }
		this.filesService.getProcedureTemplateId(inputData, this);
	}

	public getPDFPrcedureConfigs(procedurePDFTempateId : string){
		this.filesService.getTemplateConfigInputs(this.selectedFile.id, "ExternalPDF", "PROCEDURE_ZIP_PDF",procedurePDFTempateId)
            .subscribe(data => {
               this.loadingService.hideLoading()
				var itemConfigList: any[] = [];
				var invisibleItemConfigList: any[] = [];
				var facilityList: Array<any> = [];
				var documentList: Array<any> = [];
				var systemList: Array<any> = [];
               if (data.payload != null) {
                  if (data.payload.documentConfigList != null) {
                     data.payload.documentConfigList.forEach(element => {
                        if (element.display == '1') {
                           itemConfigList.push(element);
                        } else {
                           invisibleItemConfigList.push(element);
                        }
                     });
                  } else {
                     //nothing happen
                  }
                  if (data.payload.globalConfigList != null) {
                     data.payload.globalConfigList.forEach(element => {
                        if (element.display == '1') {
                           itemConfigList.push(element);
                        } else {
                           invisibleItemConfigList.push(element);
                        }
                     });
                  } else {
                     //nothing happen
                  }
                  if (data.payload.projectConfigList != null) {
                     data.payload.projectConfigList.forEach(element => {
                        if (element.display == '1') {
                           itemConfigList.push(element);
                        } else {
                           invisibleItemConfigList.push(element);
                        }
                     });
                  } else {
                     //nothing happen
                  }
                  if (data.payload.facilityList != null) {
                     for (let key of Object.keys(data.payload.facilityList)) {
                        //if (data.payload.facilityList[key] != 'Select') {
                           facilityList.push({ id: key, text: data.payload.facilityList[key] })
                        //}
                     }
                  } else {
                     //nothing happen
                  }
                  if (data.payload.documentList != null) {
                     for (let key of Object.keys(data.payload.documentList)) {
                        //if (data.payload.documentList[key] != 'Select') {
                           documentList.push({ id: key, text: data.payload.documentList[key] })
                        //}
                     }
                  } else {
                     //nothing happen
				  }
				  if (data.payload.systemList != null) {
					for (let key of Object.keys(data.payload.systemList)) {
					   //if (data.payload.facilityList[key] != 'Select') {
						  systemList.push({ id: key, text: data.payload.systemList[key] })
					   //}
					}
				 } else {
					//nothing happen
				 }
               } else {
                  // response is null
               }
               if (itemConfigList.length > 0) {
                  const initialState = {
                     itemConfigList: itemConfigList,
					 facilityList: facilityList,
					 systemList: systemList,
                     documentList: documentList,
                     itemIdList: [this.selectedFile.id],
                     item: "ExternalPDF",
                     outputType: "PROCEDURE_ZIP_PDF",
                     templateId: procedurePDFTempateId,
                     projectmapId: data.payload.id,
                     isEnabled_CreateDraftDocuments: data.payload.isEnabled_CreateDraftDocuments,
                     isEnabled_SaveOutputTemplateParams: data.payload.isEnabled_SaveOutputTemplateParams,
					 invisibleItemConfigList: invisibleItemConfigList,
					 title: "Add to Procedure Store"
                  };
                  this.bsModalRef = this.modalService.show(OPGConfigInputsViewModalComponent, { class: 'modal-lg', initialState });
                  // this.bsModalRef.content.itemConfigList = this.itemConfigList;
                  // this.bsModalRef.content.facilityList = this.facilityList;
               } else {

               }
            },
               error => {
                  this.loadingService.hideLoading()
                  this.alertService.clear()
                  this.alertService.error(error.statusDescription)
               });
	}

	public async addToDocumentStoreBtnEvent(){
		this.loadingService.showLoading(true, false, 'Loading', 0);
		let latestCMVersion:string = ""; 
		await this.filesService.getLatestVersionByItemIdAndType(this.selectedFile.id,this.selectedFile.type).toPromise().then(
			data => {
				latestCMVersion = data.payload;
				this.filesService.getSelectedStoreDocumentInfo(this.selectedFile.id).subscribe(
					data1 => {
						if (data1.payload != null && data1.payload.length != 0) {
							let isLatestCMVersion:boolean = false; 
							  if (data1.payload[0].cmversion >= latestCMVersion ){
									//pdf version alredy in document store
									let alredyAdded = this.translate.instant('PDF_ADD_TO_DOCUMENT_STORE.ALREADY_ADDED');
									this.loadingService.hideLoading();
									this.alertService.error(alredyAdded)
								}  else {
									this.addToDocumentStore(this.selectedFile)
								}
						} else {
							this.addToDocumentStore(this.selectedFile)
						}
					},
					error => {
						this.alertService.error(error.statusDescription)
					});	
			},
			error => {
				this.alertService.error(error.statusDescription)
			});	
	}

	public addToDocumentStore(selectedFile:any) {
		var inputData = {
			"item": this.selectedFile.type,
			"itemId": this.selectedFile.id,
			"outputType": "STORE_DOC_ZIP_PDF",
			"documentType" : "ExternalPDF"
		}
		this.filesService.getStoreDocumentTemplateId(inputData, this);
	}

	public getPDFDocumentStoreConfigs(documentStorePDFTempateId : string){
		this.filesService.getTemplateConfigInputs(this.selectedFile.id, "ExternalPDF", "STORE_DOC_ZIP_PDF", documentStorePDFTempateId)
            .subscribe(data => {
               this.loadingService.hideLoading()
				var itemConfigList: any[] = [];
				var invisibleItemConfigList: any[] = [];
				var facilityList: Array<any> = [];
				var documentList: Array<any> = [];
				var systemList: Array<any> = [];
               if (data.payload != null) {
                  if (data.payload.documentConfigList != null) {
                     data.payload.documentConfigList.forEach(element => {
                        if (element.display == '1') {
                           itemConfigList.push(element);
                        } else {
                           invisibleItemConfigList.push(element);
                        }
                     });
                  } else {
                     //nothing happen
                  }
                  if (data.payload.globalConfigList != null) {
                     data.payload.globalConfigList.forEach(element => {
                        if (element.display == '1') {
                           itemConfigList.push(element);
                        } else {
                           invisibleItemConfigList.push(element);
                        }
                     });
                  } else {
                     //nothing happen
                  }
                  if (data.payload.projectConfigList != null) {
                     data.payload.projectConfigList.forEach(element => {
                        if (element.display == '1') {
                           itemConfigList.push(element);
                        } else {
                           invisibleItemConfigList.push(element);
                        }
                     });
                  } else {
                     //nothing happen
                  }
                  if (data.payload.facilityList != null) {
                     for (let key of Object.keys(data.payload.facilityList)) {
                        //if (data.payload.facilityList[key] != 'Select') {
                           facilityList.push({ id: key, text: data.payload.facilityList[key] })
                        //}
                     }
                  } else {
                     //nothing happen
                  }
                  if (data.payload.documentList != null) {
                     for (let key of Object.keys(data.payload.documentList)) {
                        //if (data.payload.documentList[key] != 'Select') {
                           documentList.push({ id: key, text: data.payload.documentList[key] })
                        //}
                     }
                  } else {
                     //nothing happen
				  }
				  if (data.payload.systemList != null) {
					for (let key of Object.keys(data.payload.systemList)) {
					   //if (data.payload.facilityList[key] != 'Select') {
						  systemList.push({ id: key, text: data.payload.systemList[key] })
					   //}
					}
				 } else {
					//nothing happen
				 }
               } else {
                  // response is null
               }
               if (itemConfigList.length > 0) {
                  const initialState = {
                     itemConfigList: itemConfigList,
					 facilityList: facilityList,
					 systemList: systemList,
                     documentList: documentList,
                     itemIdList: [this.selectedFile.id],
                     item: "ExternalPDF",
                     outputType: "STORE_DOC_ZIP_PDF",
                     templateId: documentStorePDFTempateId,
                     projectmapId: data.payload.id,
                     isEnabled_CreateDraftDocuments: data.payload.isEnabled_CreateDraftDocuments,
                     isEnabled_SaveOutputTemplateParams: data.payload.isEnabled_SaveOutputTemplateParams,
					 invisibleItemConfigList: invisibleItemConfigList,
					 title: "Add to Document Store",
					 selectedFileName: this.selectedFile.name
                  };
				  this.bsModalRef = this.modalService.show(OPGConfigInputsViewModalComponent, { class: 'modal-lg', initialState });
                  // this.bsModalRef.content.itemConfigList = this.itemConfigList;
                  // this.bsModalRef.content.facilityList = this.facilityList;
               } else {

               }
            },
               error => {
                  this.loadingService.hideLoading()
                  this.alertService.clear()
                  this.alertService.error(error.statusDescription)
               });
	}

	public threedmodelDepClickOK() {
		this.alertService.clear();
		this.alertService.warn("Please resolve model dependencies before checkin");
	}

	public threedmodelViewWarnClickOK() {
		this.alertService.clear();
		this.alertService.success("Successfully Checked In");											
		this.filesDataService.getBasicFileDataForCheckin(this.selectedFile.id, this.selectedFile.type, this);
		this.sharedService.setIsCheckedIn(true);
	}

	public restoreRevisionClickOK() {
		this.alertService.clear();
		this.alertService.success(this.translate.instant('FILES_DATA.COURSE_RESTORE_REVISION'));
		this.filesDataService.getBasicFileData(this.selectedFile.id, this.selectedFile.type, this, "checkin");
		this.sharedService.checkedInCheckedOutTabCloseRequest(this.selectedFile.id);
	}
}
