<div class="modal-header">
    <!--Title-->
    <h4 class="modal-title pull-left">{{title}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  <!--Body-->
  <div class="modal-body">
    <form ngNativeValidate id="new_file_form">
      <!--File upload-->
      <div class="form-group">
        <label for="NewImage_FileUpload">{{ 'NEW_MLTIPLE_IMAGE.IMAGE_FILE.NAME' | translate }}<div class="badge-required mx-2"></div></label>
        <input type="file" class="form-control-file" id="NewImage_FileUpload" accept=".png, .jpg, .jpeg, .gif" (change)="onFileChanged($event);fileAdded($event)" multiple required>
        <small id="emailHelp" class="form-text text-muted">{{ 'NEW_MLTIPLE_IMAGE.IMAGE_FILE.MESSAGE' | translate }}</small>
      </div>
  
      <!--Document type-->
      <div class="form-group">
        <label for="NewImage_DocumentType">{{ 'NEW_MLTIPLE_IMAGE.DOC_TYPE.NAME' | translate }}<div class="badge-required mx-2"></div></label>
        <input type="text" class="form-control" id="NewImage_DocumentType" aria-describedby="docType" name="docType" autocomplete="off" [(ngModel)]="documentType" (click)="loadDocumentTypes()" placeholder="{{ 'NEW_MLTIPLE_IMAGE.DOC_TYPE.PLACE_HOLDER' | translate }}" required onselectstart="return false" onpaste="return false" onCopy="return false" onCut="return false" onkeypress="return false" onCut="return false" onDrag="return false" onDrop="return false">
      </div>
  
      <!--User comments-->
      <div class="form-group">
        <label for="NewImage_Comments">{{ 'NEW_MLTIPLE_IMAGE.COMMENTS.NAME' | translate }}</label>
        <input type="text" class="form-control" id="NewImage_Comments" name="newFile" [(ngModel)]="fileComment" aria-describedby="comments" placeholder="{{ 'NEW_MLTIPLE_IMAGE.COMMENTS.PLACE_HOLDER' | translate }}">
      </div>
  
      <!--Attributes-->
      <div class="form-group">
        <label for="exampleInputPassword1">{{ 'NEW_MLTIPLE_IMAGE.ATTRIBUTE.NAME' | translate }}</label>
        <div class="alert alert-info" role="alert" *ngIf="!haveProperties">
            {{ 'NEW_MLTIPLE_IMAGE.ATTRIBUTE.EMPTY_MESSAGE' | translate }}
        </div>
        <!--Attribute Table-->
			<div *ngIf="attributeList != undefined">
				<div *ngFor="let attibute of attributeList">
          <div *ngIf="attibute.attributeType != 'null'">  
					<br>
					<table>
						<th></th>
						<th></th>
						<tr>
							<td width="100">
								<label>{{attibute.attributeName}}
									<sup>
										<i *ngIf="attibute.required=='yes'" class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i>
									</sup>
								</label>
							</td>
							<td width="300" >
								<input type="text"  *ngIf="(attibute.attributeType=='Number' || attibute.attributeType=='Text') && (attibute.attributeName != 'Document' && attibute.attributeName != 'c1' && attibute.attributeName != 'System')" 
                #texts name ="attribute" autocomplete="off" [(ngModel)]="attibute.value" style="width:100%">
								<input type="date"  *ngIf="attibute.attributeType == 'Date'" name ="attribute" [(ngModel)]="attibute.value" style="width:100%">
								<div *ngIf= "checkDocument(attibute.attributeType,attibute.attributeName)">
								<select #options name ="attribute" [(ngModel)]="attibute.value" style="width:100%" >
									<option *ngFor="let item of attibute.documents" [ngValue]="item.id">
										{{item.documentName}}
									</option>
								</select>
								</div>
								<div *ngIf= "checkComponent(attibute.attributeType,attibute.attributeName)">
									<select  #options name ="attribute" [(ngModel)]="attibute.value" style="width:100%" >
										<option *ngFor="let item of attibute.components" [ngValue]="item.id">
											{{item.componentName}}
										</option>
									</select>
									</div>
									<div *ngIf= "checkSystem(attibute.attributeType,attibute.attributeName)">
										<select  #options name ="attribute" [(ngModel)]="attibute.value" style="width:100%" >
											<option *ngFor="let item of attibute.systems" [ngValue]="item.systemId">
												{{item.systemName}}
											</option>
										</select>
										</div>
							</td>
						</tr>
          </table>
					</div>
				</div>
			</div>

		</div>
  
      <!--Submit Button-->
      <div class="form-group row float-right mr-0">
        <button type="submit" class="btn btn-primary" (click)="onSubmitButtonClick()">{{ 'SUBMIT' | translate }}</button>
      </div>
  
    </form>
  </div>
  
  <!--Close button-->
  <!--<div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">{{closeBtnName}}</button>
  </div>-->