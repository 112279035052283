<div class = "col-lg-12">
    <div class = "col" style= "overflow: hidden">
        <div class = "float-left">
            <div *ngIf = "!isEdit; else editWorkflowTemplate">
                <h4 class = "text-center">
                    {{ 'WORKFLOW_TEMPLATE_ADD_EDIT.ADD_WORKFLOW_TEMPLATE' | translate }}
                </h4>
            </div>
            <ng-template #editWorkflowTemplate>
                <h4 class = "text-center">
                    {{ 'WORKFLOW_TEMPLATE_ADD_EDIT.EDIT_WORKFLOW_TEMPLATE' | translate }}
                </h4>
            </ng-template>
        </div>
        <br />
    </div>
    <hr />
</div>

<div>    
<div class="form-group row">
    <label for="workflowName" class="col-sm-2 col-form-label ml-3">{{ 'WORKFLOW_TEMPLATE_ADD_EDIT.WORKFLOW_NAME' | translate }}<div class="badge-required mx-2"></div></label>
    <div class="col-sm-9">
        <input id="workflowName" type="text" [maxLength]="100" class="form-control form-control-sm"
            name="workflowName" [(ngModel)]="workflowName">
    </div>
</div>

<div class="form-group row">
    <label for="itemType" class="col-sm-2 col-form-label ml-3">{{ 'WORKFLOW_TEMPLATE_ADD_EDIT.ITEM_TYPE' | translate }}<div class="badge-required mx-2"></div></label>
    <div class="col-sm-9">
            <ng-select [items]="itemTypeList" [active]="selectedItemType" placeholder="{{ 'WORKFLOW_TEMPLATE_ADD_EDIT.SELECT_ITEM_TYPE' | translate }}"
            (removed)="removeItemType()" [allowClear]="true" (selected)="selectItemType($event)">
            </ng-select>
    </div>
</div>

<div class="form-group row">
    <label for="configParameters" class="col-sm-2 col-form-label ml-3">{{ 'WORKFLOW_TEMPLATE_ADD_EDIT.CONFIG_PARAMETERS' | translate }}<div class="badge-required mx-2"></div></label>
    <div class="col-sm-9">
        <textarea  id="configParameters" type="text" class="form-control form-control-sm"
            name="configParameters" [(ngModel)]="configParameters"></textarea>
    </div>
</div>

<div class="form-group row">
    <label for="version" class="col-sm-2 col-form-label ml-3">{{ 'WORKFLOW_TEMPLATE_ADD_EDIT.VERSION' | translate }}</label>
    <div class="col-sm-9">
        <input id="version" type="text" [maxLength]="10" class="form-control form-control-sm"
            name="version" [(ngModel)]="version">
    </div>
</div>

<div class="form-group row">
    <label for="status" class="col-form-label col-sm-2 pt-0 ml-3">{{ 'WORKFLOW_TEMPLATE_ADD_EDIT.STATUS' | translate }}<div class="badge-required mx-2"></div></label>
        <div class="form-check">
            <input  type="radio" value="0" name="active" [checked]="(status === '1')"  (click)="radioClick()" >
            <label class="form-check-label  mx-1">
                Active
            </label>
        </div>
        <div class="form-check ">
            <input  type="radio" value="0" name="inactive" [checked]="(status === '0')"  (click)="radioClick()">
            <label class="form-check-label  mx-1">
                Inactive
            </label> 
        </div>
</div>

<div class="card my-3">
    <div class="card-header py-1 stick-to-top">
        <p class="col-sm-5 my-1 float-left h6 px-0">
            <span class="badge badge-warning mr-2">{{taskList.length}}</span>
            {{ 'WORKFLOW_TEMPLATE_ADD_EDIT.TASKS' | translate }}
        </p>
        <button id="showMoreWfTasks" type="button"
            class="my-1 mx-0 close float-right" (click)="showWorkflowTasks()">
            <i class="far" [ngClass]="{'fa-angle-up': isShowWorkflowTasks, 'fa-angle-down': !isShowWorkflowTasks}"></i>
        </button>
        <button id="addNewWfTask" type="button"
            class="btn btn-sm btn-dark mx-2 float-right" (click)="addNewWorkflowTask()"
             tooltip="Add New Task" [placement]="isShowWorkflowTasks ? 'bottom' : 'top'" [disabled]="hasEmptyTaskName">
            <i class="far fa-plus"></i>
        </button>
    </div>
    <div *ngIf="isShowWorkflowTasks" class="card-body p-2"> 
        <div *ngFor="let task of taskList | orderBy : 'order'; let t = index"
            class="card card-secondary my-2">
            <div class="card-body container-fluid p-1 topiclistDiv">
                <div class="">
                    <div class="col-sm-9 float-left pl-2 pr-0">
                        <input type="text" class="form-control form-control-sm topiclisttitle" id="wfTaskName" name="wfTaskName"
                            [value]="task.taskName"
                            [(ngModel)]="task.taskName"
                            (change)="onTaskNameChange()"
                            placeholder="{{ 'WORKFLOW_TEMPLATE_ADD_EDIT.TASK_NAME_PLACEHOLDER' | translate }}" [disabled]="isViewOnly">
                    </div>
                    <div class="col-sm-3 float-right px-0">
                        <button id="btnDeleteTask" type="button" class="my-1 mx-2 close float-right"
                            (click)="deleteWorkflowTask(task.order)">
                            <i class="far fa-trash-alt"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class = "form-group row ml-3">
    <div class="btn-toolbar">
        <button type="submit" id="workflow_save" class="btn btn-warning btn-sm mr-2 " (click)="save()">{{
            'WORKFLOW_TEMPLATE_ADD_EDIT.SAVE' | translate}}</button>
    </div>
    <div class="btn-toolbar ">
        <button type="button" id="workflow_cancel" class="btn btn-warning btn-sm mr-2" (click)="cancel()">{{
            'WORKFLOW_TEMPLATE_ADD_EDIT.CANCEL' | translate}}</button>
    </div>	
    </div>

</div>


