<div [ngClass]="hide ? 'hide' : ''" >
  <div class="modal-header py-2">
    <h5  class="modal-title" *ngIf="mccId">
      Edit Mechanical Completion
    </h5>
    <h5  class="modal-title" *ngIf="!mccId">
      Add Mechanical Completion
    </h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="container-fluid mt-3 mb-4 modal-container" id ="irnaddedit" >
    <form class="mt-2">
      <!-- facility name -->
      <div class="form-group mb-2">
        <label for="system" class="mb-0">System</label>
        <div class="badge-required mx-2"></div>
        <input *ngIf="systemName!=null" id="facility_edit_name" type="text" class="form-control form-control-sm"
               id="system" name="system" [value]="systemName" disabled>
      </div>
      <div class="form-group mb-2">
        <label for="mccStatus" class="mb-0">MCC Status</label>
        <div class="badge-required mx-2"></div>
        <input *ngIf="mccStatus!=null" id="facility_edit_name" type="text" class="form-control form-control-sm"
               id="mccStatus" name="mccStatus" [value] ="mccStatus" disabled>
      </div>
      <div class="form-group mb-2">
        <label for="targetCompletionDate" class="mb-0">Target Completion Date <div class="badge-required mx-2">
        </div></label>
        <input type="text" placeholder="Select target completion date" class="form-control" name="date_raised"
               [(ngModel)]="targetCompletionDate" [bsConfig]="bsConfig" placement="bottom" bsDatepicker>
      </div>
      <div class="form-group form-check mt-4">
        <div class="row my-2">
          <div class="col-sm-6">
            <label class="container_checkbox">
              <input [(ngModel)]="required" type="checkbox" name="isCommissioning" checked="checked" id="required">
              <span class="checkmark"></span>
            </label>
            <!-- <input [(ngModel)]="required" type="checkbox" class="form-check-input" id="required">-->
            <label class="ml-2">Required <div class="badge-required mx-2">
            </div></label>
          </div>
        </div>
      </div>
      <div class="form-group form-check">
        <div class="row my-2">
          <div class="col-sm-6">
            <label class="container_checkbox">
              <input [(ngModel)]="mcVendorManaged" type="checkbox" name="isCommissionning"  id="mcVendorManaged">
              <span class="checkmark"></span>
            </label>
            <!-- <input [(ngModel)]="required" type="checkbox" class="form-check-input" id="required">-->
            <label class="ml-2">Vendor Managed</label>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button  type="submit" id="irn_update" class="btn btn-primary btn-sm float-left"
             (click)="saveMC()">Save</button>
    <button type="button" id="irn_cancel" class="btn btn-secondary btn-sm float-left"
            (click)="cancel()">{{ 'CANCEL' | translate}}</button>
  </div>

</div>

<ng-template #editMCModal>
  <div class="modal-header">
    <h6 class="modal-title pull-left">Delete Confirmation</h6>
    <button type="button" class="close pull-right" aria-label="Close" (click)="hideDeleteConfirm()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body ">
    <p> Are you sure you want to delete this? </p>
    <div class="float-right">
      <button type="button" class="btn btn-default mx-1" (click)="deleteMCC()">Yes</button>
      <button type="button" class="btn btn-primary mx-1" (click)="hideDeleteConfirm()">Cancel</button>
    </div>
  </div>
</ng-template>
