import { Injectable } from '@angular/core';
import { PunchlistComponent } from './punchlist/punchlist.component';
import { AddpunchlistComponent } from './punchlist/addpunchlist/addpunchlist.component'
import { EditpunchlistComponent } from './punchlist/editpunchlist/editpunchlist.component'
import {IrnComponent} from "./irn/irn.component";
import {ManagemccComponent} from "./managemcc/managemcc.component";
import { ManageotnComponent } from './manageotn/manageotn.component';
import { ManagecheckitemsComponent } from './checksheets/managecheckitems/managecheckitems.component';
import { ManagechecksheetsComponent } from './checksheets/managechecksheets/managechecksheets.component';
import { IssueChecksheetsComponent } from './checksheets/issuechecksheets/issuechecksheets.component';
import { ReceiveChecksheetsComponent } from './checksheets/receivechecksheets/receivechecksheets.component';
import { ManagePCCComponent } from './managepcc/managepcc.component';

@Injectable({
  providedIn: 'root'
})
export class ComLoaderService {

  constructor() { }

  public loadComponentByName(compName: string){
    switch(compName){
      case 'punchlist':
        return PunchlistComponent;
      case 'add_punchlist':
        return AddpunchlistComponent;
      case 'edit_punchlist':
        return EditpunchlistComponent;
      case 'irn':
        return IrnComponent;
      case 'mcc':
        return ManagemccComponent;
      case 'otn':
        return ManageotnComponent;
      case 'managecs':
        return ManagechecksheetsComponent;
      case 'managechk':
        return ManagecheckitemsComponent;
      case 'issuecs':
        return IssueChecksheetsComponent;
      case 'receivecs':
        return ReceiveChecksheetsComponent;
      case 'pcc':
        return ManagePCCComponent;
      /*case 'ccc':
        return ManagemccComponent;
        case 'stnptn':
        return ManagemccComponent;
      case 'shc':
        return ManagemccComponent;
      case 'facility_acceptance':
          return ManagemccComponent;
      case 'work_item':
          return ManageotnComponent;
      case 'discipline':
        return ManageotnComponent;
      case 'functionCode':
        return ManageotnComponent;
      case 'assetGroups':
        return ManageotnComponent;
      case 'valueList':
        return ManageotnComponent;
      case 'vendor':
        return ManageotnComponent;
      case 'phase':
        return ManageotnComponent;*/
      default:
        break;
    }
  }
}

