import { Component, OnInit, ViewChildren, QueryList, EventEmitter} from '@angular/core';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { SelectComponent } from 'src/app/util/ng2-select/select';
import { Punchlist, PunchlistDocument } from 'src/app/commissioning/punchlist/punchlist.model'
import { PunchlistService } from 'src/app/commissioning/punchlist/punchlist.service';
import { AlertService } from 'src/app/util/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from 'src/app/util/loading/loading.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { LocalStorage } from 'src/app/util/localstorage.service';
import { GridOptions, Module, AllModules } from "@ag-grid-enterprise/all-modules";
import { FileUploadService } from 'src/app/commissioning/punchlist/file-upload.service';
import { UserVariable } from 'src/app/util/common/user-variable';
import { WsCallback } from 'src/app/util/ws-callback.interface';
import { WsResponse } from 'src/app/util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { CommonUtil } from 'src/app/util/common/common-util';
import {SharedService} from "../../../util/shared.service";

@Component({
  selector: 'app-addpunchlist',
  templateUrl: './addpunchlist.component.html',
  styleUrls: ['./addpunchlist.component.css']
})
export class AddpunchlistComponent implements OnInit, WsCallback {
  public punchlist: Punchlist = new Punchlist();
  public projectId: string = null;
  public userId: string = null;
  public token: string;

  public category: Array<any> = [];
  public item_status: Array<any> = [];
  public selectedCategory: any = "";
  public selectedItem_status: any = "";


  public assetList: Array<any> = [];
  public disciplineList: Array<any> = [];
  public subSystemList: Array<any> = [];
  public selectedAsset: Array<any> = null;
  public vendorList: Array<any> = [];

  public punchlistDisable: boolean = true;
  public assetDisable: boolean = true;
  public disciplineDisable: boolean = true;
  public subSystemDisable: boolean = true;
  public vendorDisable: boolean = true;

  public emptyFieldError: any;
  public statusError: any;
  public emptyAssetOrSystem: any;
  public emptyAsset: any;
  public emptyCloseDate: any;
  public invalidDate: any;
  public maxTotalSizeExceed: any;

  public gridOptions: GridOptions;
  public columnDefs: any;
  public rowData:any = [];
  private gridApi: any;
  private gridColumnApi: any;
  public modules: Module[] = AllModules;

  private uploadingFile: File;
  private fileExtension: string;
  public uploadFileName: string;
  public uploadFileNameWithExt: string;
  public punchlistUploadDocuments: any = [];
  private MAX_FILE_SIZE : any = 26214400; //25MB

  filesToUpload: Array<File> = [];

  public bsConfig: Partial<BsDatepickerConfig>
  public parentComponent:any;
  @ViewChildren(SelectComponent) selectElem: QueryList<SelectComponent>
  onClose = new EventEmitter();
  constructor(
    private punchlistService: PunchlistService,
    private alertService: AlertService,
    private translate: TranslateService,
    private loadingService: LoadingService,
    private modalService: BsModalService,
    private fileUploadService: FileUploadService,
    public bsModalRef: BsModalRef, 
    private commonUtil: CommonUtil,
    private sharedService:SharedService
  ) { 

      translate.addLangs(["es", "en"]);
      translate.setDefaultLang("en");
      let browserLang = translate.getBrowserLang();
      translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
        this.translate.get('PUNCHLIST_ADD_EDIT.FIELD_EMPTY').subscribe((res: string) => {
          this.emptyFieldError = res;
        });
        this.translate.get('PUNCHLIST_ADD_EDIT.STATUS_ERROR').subscribe((res: string) => {
          this.statusError = res;
        });
        this.translate.get('PUNCHLIST_ADD_EDIT.CLOSE_DATE_EMPTY').subscribe((res: string) => {
          this.emptyCloseDate = res;
        });
        this.translate.get('PUNCHLIST_ADD_EDIT.ASSET_OR_SYSTEM_EMPTY').subscribe((res: string) => {
          this.emptyAssetOrSystem = res;
        });
        this.translate.get('PUNCHLIST_ADD_EDIT.ASSET_EMPTY').subscribe((res: string) => {
          this.emptyAsset = res;
        });
        this.translate.get('PUNCHLIST_ADD_EDIT.INVALID_DATE').subscribe((res: string) => {
          this.invalidDate = res;
        });
        this.translate.get('PUNCHLIST_ADD_EDIT.MAX_ATTACHMENT_SIZE').subscribe((res: string) => {
          this.maxTotalSizeExceed = res;
        });
        
    //set token
    this.token = UserVariable.getUserToken();
    if(!this.commonUtil.validateToken(this.token)){
      return;
    }

    this.bsConfig = Object.assign({}, { containerClass: 'theme-default', customTodayClass: 'custom-today-class' });
    
    this.prepareAgGrid();
  }
  
  ngOnInit() {
    this.category = [
      { 'id': '1', 'text': 'A' },
      { 'id': '2', 'text': 'B' },
      { 'id': '3', 'text': 'C' },
      { 'id': '4', 'text': 'D' }
    ];
    
    this.item_status = [
      { 'id': '1', 'text': 'Open' },
      { 'id': '2', 'text': 'Closed' }
    ];

    //set project id and user id
    // this.projectId = localStorage.getItem(LocalStorage.PROJECT_ID);
    this.projectId = UserVariable.projectId;
    this.userId = localStorage.getItem(LocalStorage.USER_ID);

     //set default item status
    this.punchlist.selectedItemStatus = this.item_status.filter(x => x.text == 'Open');
    this.punchlist.itemStatus = 'Open';
    this.selectedItem_status = this.punchlist.selectedItemStatus[0].text;
    //this.item_status[0].Active;

    //get required details for add punchlist

    // this.punchlistService.getAssetList(this);
    // this.punchlistService.getSubSystemList(this);
    this.punchlistService.getDisciplineList(this.projectId, this);
    if(this.sharedService.getParent() && this.sharedService.getParent().componentName=="AddeditirnComponent"){
      this.parentComponent = this.sharedService.getParent();
      this.parentComponent.selectedAssetList.forEach(element => {
        this.assetList.push({ 'id': element.value, 'text': element.display });
      });
      this.punchlist.selectedSubSystem ,this.subSystemList=this.parentComponent.selectedSystem;
      this.punchlist.irnId=this.parentComponent.irn.irnId;
      this.subSystemDisable = true;
    } else if(this.sharedService.getParent() && this.sharedService.getParent().componentName == "ApproveCheckSheetComponent"){
      this.parentComponent = this.sharedService.getParent();
      if(this.parentComponent.assetId != null){
        this.punchlist.assetId = this.parentComponent.assetId;
        this.punchlist.assetName = this.parentComponent.itemTagNo;
        this.assetList.push({ 'id': this.punchlist.assetId, 'text': this.punchlist.assetName });
        this.punchlist.selectedAsset = this.assetList.filter(a => a.id = this.punchlist.assetId);
        this.punchlist.isAssetDisable = false;
        this.subSystemDisable = true;
      } else if(this.parentComponent.systemId){
        this.punchlist.subsystemId = this.parentComponent.systemId
        this.punchlist.subSystemName = this.parentComponent.systemNo;
        this.subSystemList.push({'id': this.punchlist.subsystemId, 'text': this.punchlist.subSystemName});
        this.punchlist.selectedSubSystem = this.subSystemList.filter(s => s.id == this.punchlist.subsystemId);
        this.subSystemDisable = false;
        this.punchlist.isAssetDisable = true;
      } else{
        //do nothing
      }
      //get the checksheet details
      this.punchlist.checksheetId = this.parentComponent.checkSheetId;
      if(this.parentComponent.selectedChecksheetCheck != null){
        this.punchlist.checksheetCheckId = this.parentComponent.selectedChecksheetCheck.checkSheetCheckId;
        this.punchlist.checksheetInstanceId = this.parentComponent.selectedChecksheetCheck.checkSheetInstanceId;
      } else{
        //do nothing
      }
    } else{
      this.punchlistService.getAssetList(this);
      this.punchlistService.getSubSystemList(this);
    }

    //get vendor list
    this.punchlistService.getVendorList(this.projectId, this);
  }

  public selectedAssetId(value: any): void {
    console.log("Selected Asset Value: " + JSON.stringify(value));

    if (this.punchlist.assetId != value.id) {
        this.punchlistDisable = true;
        // Update facility details
        this.punchlist.assetName = value.text;
        this.punchlist.assetId = value.id;
        this.punchlist.selectedAsset = this.assetList.filter(x => x.id == this.punchlist.assetId);
        this.punchlist.isSystemDisable = true;
        this.punchlist.parentId = null;
        this.punchlist.parentName = '';
    }else{
      //nothing to do
    }
  }

  selectedAssetDisplay(assetId) {
    console.log("selectedAssetDisplay() - assetId: " + assetId);

    // Set selected asset
    this.punchlist.selectedAsset = this.getAssetNameById(assetId);
    console.log("assetId " + JSON.stringify(this.punchlist.selectedAsset));

  }
  
  getAssetNameById(id) {
    return this.assetList.filter(x => (x.id === id));
  }


  categorySelected(event: any)
	{
    this.punchlist.category = event.text;
		this.selectedCategory = event["text"]
  }

  public selectedDisciplineId(value: any): void {
    console.log("Selected Discipline Value: " + JSON.stringify(value));

    if (this.punchlist.disciplineId != value.id) {
        this.punchlistDisable = true;
        // Update facility details
        this.punchlist.disciplineName = value.text;
        this.punchlist.disciplineId = value.id;
        this.punchlist.selectedDiscipline = this.disciplineList.filter(x => x.id == this.punchlist.disciplineId);
    }else{
      //nothing to do
    }
  }

  //Achini Randeni
  //Remove the selected category
  public removeCategory(event: any){
    this.punchlist.category = ""
    this.selectedCategory = ""
  }

  public removeDisciplineId(value: any): void {
    console.log("removeDisciplineId");

    this.punchlist.disciplineName = null;
    this.punchlist.disciplineId = null;
    this.punchlist.selectedDiscipline = null;
  }

  selectedtDisciplineDisplay(disciplineId) {
    console.log("selectedDisciplineDisplay() - disciplineId: " + disciplineId);

    // Set selected discipline
    this.punchlist.selectedDiscipline = this.getDisciplineNameById(disciplineId);
    console.log("disciplineId " + JSON.stringify(this.punchlist.selectedDiscipline));

  }
  
  getDisciplineNameById(id) {
    return this.disciplineList.filter(x => (x.id === id));
  } 

  public selectedSubSystemId(value: any): void {
    console.log("Selected SubSystem Value: " + JSON.stringify(value));
    if (this.punchlist.subsystemId != value.id) {
        this.punchlistDisable = true;
        this.punchlist.subSystemName = value.text;
        this.punchlist.subsystemId = value.id;
        this.punchlist.selectedSubSystem = this.subSystemList.filter(x => x.id == this.punchlist.subsystemId);
        if(this.punchlist.selectedSubSystem[0].parentNo != null && this.punchlist.selectedSubSystem[0].parentName != null && this.punchlist.selectedSubSystem[0].parentId != null){
          this.punchlist.parentName = this.punchlist.selectedSubSystem[0].parentNo + ' ' + this.punchlist.selectedSubSystem[0].parentName;
          this.punchlist.parentId = this.punchlist.selectedSubSystem[0].parentId;
        }
        else{
          this.punchlist.parentName = '';
          this.punchlist.parentId = null;
        }
        this.punchlist.isAssetDisable = true;
      }else{
      //nothing to do
    }
  }

  selectedSubSystemDisplay(subsystemId) {
    console.log("selectedSubSystemDisplay() - subsystemId: " + subsystemId);

    // Set selected subsystem
    this.punchlist.selectedSubSystem = this.getSubSystemNameById(subsystemId);
    console.log("subsystemId " + JSON.stringify(this.punchlist.selectedSubSystem));

  }
  
  getSubSystemNameById(id) {
    return this.subSystemList.filter(x => (x.id === id));
  } 
  
  public itemStatusSelected(event: any)
	{
    this.punchlist.itemStatus = event.text;
    this.punchlist.selectedItemStatus = event.text;
    this.selectedItem_status = event["text"]
  }

  public removedAsset(value: any): void {
    console.log("removeSelectedAsset");

    // Update asset details
    this.punchlist.assetName = null;
    this.punchlist.assetId = null;
    this.punchlist.selectedAsset = null;
    this.punchlist.isSystemDisable = false;

    console.log("Removed Asset Value: " + value);
  }

  public removedSubSystem(value: any): void {
    console.log("removeSelectedSubSystem");

    // Update asset details
    this.punchlist.subSystemName = null;
    this.punchlist.subsystemId = null;
    this.punchlist.selectedSubSystem = null;
    this.punchlist.isAssetDisable = false;
    this.punchlist.parentId = null;
    this.punchlist.parentName = '';

    console.log("Removed Asset Value: " + value);
  }

  IsValidDate(value: Date): boolean {
    if(value != undefined){
      var date = new Date(value);
      if(date.toString() == 'Invalid Date'){
        return false;
      }
      else{
        return true;
      }
    }else{
      return true;
    }
  }

  public selectedVendorId(value: any): void {
    console.log("Selected Vendor Value: " + JSON.stringify(value));

    if (this.punchlist.vendorId != value.id) {
      this.punchlistDisable = true;
      this.punchlist.vendorName = value.text;
      this.punchlist.vendorId = value.id;
      this.punchlist.selectedVendor = this.vendorList.filter(x => x.id == this.punchlist.vendorId);
    } else {
      //nothing to do
    }
  }

  public removeVendorId(value: any): void {
    console.log("removeVendorId");

    this.punchlist.vendorName = null;
    this.punchlist.vendorId = null;
    this.punchlist.selectedVendor = null;
  }

  /**
   * add new punchlist
   */
  save(){
    //Achini Randeni
    //validate punchlist category for the undefined, null and empty
    if(this.punchlist.category == undefined || this.punchlist.category == null || this.punchlist.category == ""){
      this.alertService.clear();
      this.alertService.error(this.emptyFieldError);
    }else if(this.punchlist.disciplineId == undefined || this.punchlist.disciplineId == null){
      this.alertService.clear();
      this.alertService.error(this.emptyFieldError);
    }else if(this.punchlist.punchlistDescription == undefined || this.punchlist.punchlistDescription.trim() == ""){
      this.alertService.clear();
      this.alertService.error(this.emptyFieldError);
    }else if(this.punchlist.dateraised == undefined || this.punchlist.dateraised == null){
      this.alertService.clear();
      this.alertService.error(this.emptyFieldError);
    }
    //Achini Randeni
    //Validate raisedby field for the undefined, null and empty
    else if(this.punchlist.raisedby == undefined || this.punchlist.raisedby == null || this.punchlist.raisedby == ""){
      this.alertService.clear();
      this.alertService.error(this.emptyFieldError);
    }else if(this.sharedService.getParent() && (this.sharedService.getParent().componentName == "AddeditirnComponent") && (this.punchlist.assetId == undefined || this.punchlist.assetId == null)){
      this.alertService.clear();
      this.alertService.error(this.emptyAsset);
    }else if((this.punchlist.assetId == undefined || this.punchlist.assetId == null) && (this.punchlist.subsystemId == undefined || this.punchlist.subsystemId == null)){
      this.alertService.clear();
      this.alertService.error(this.emptyAssetOrSystem);
    }else if(!this.IsValidDate(this.punchlist.dateraised) || !this.IsValidDate(this.punchlist.closedDate) || !this.IsValidDate(this.punchlist.targetCompletionDate)){
      this.alertService.clear();
      this.alertService.error(this.invalidDate);
    }else if(this.punchlist.closedDate != null && this.punchlist.closedDate != undefined && this.punchlist.itemStatus != "Closed"){
      this.alertService.clear();
      this.alertService.error(this.statusError);
    }else if(this.punchlist.itemStatus == "Closed" && (this.punchlist.closedDate == null || this.punchlist.closedDate == undefined)){
      this.alertService.clear();
      this.alertService.error(this.emptyCloseDate);
    } else if (this.IsMaxAttachmentSizeExceeded()) {
      this.alertService.clear();
      this.alertService.error(this.maxTotalSizeExceed);
    } 
    else{
      let data = {
        id: null,
        punchlistDescription: this.punchlist.punchlistDescription,
        category: this.punchlist.category,
        dateRaised: this.punchlist.dateraised != undefined ? this.punchlist.dateraised : null,
        dateClosed: this.punchlist.closedDate != undefined ? this.punchlist.closedDate : null,
        estimatedCompletionDate: this.punchlist.targetCompletionDate != undefined ? this.punchlist.targetCompletionDate : null,
        raisedBy: this.punchlist.raisedby,
        estimatedManHours: this.punchlist.estimatedManHours,
        materialsRequired: this.punchlist.materialRequired,
        itemStatus: this.punchlist.itemStatus,
        comments: this.punchlist.comments,
        assetId: this.punchlist.assetId,
        disciplineId: this.punchlist.disciplineId,
        subSystemId: this.punchlist.subsystemId,
        systemId: this.punchlist.parentId != null ? this.punchlist.parentId : null,
        status: 'Active',
        projectId: this.projectId,
        punchlistDocuments: this.punchlist.punchlistDocuments,
        irnId:this.punchlist.irnId,
        vendorId: this.punchlist.vendorId,
        firstOilRequired: this.punchlist.firstOilRequired,
        checksheetId: this.punchlist.checksheetId != undefined ? this.punchlist.checksheetId : null,
        checksheetCheckId: this.punchlist.checksheetCheckId != undefined ? this.punchlist.checksheetCheckId : null,
        checksheetInstanceId: this.punchlist.checksheetInstanceId != undefined ? this.punchlist.checksheetInstanceId : null
      };

      var json = JSON.stringify(data);
      console.log(json);
      this.loadingService.showLoading(true, false, this.translate.instant('SAVING'), 0);
      this.punchlistService.addNewPunchlist(this.token, json, this);
    }
  }

  cancel(){
    this.onClose.emit(true);
    this.bsModalRef.hide()
  }

  public closeOtherElems(element: any) {
    let a = this.selectElem.filter(function (el) {
        return (el != element)
    });

    a.forEach(function (e: SelectComponent) {
        e.hideOptions();
    })
}

/**
 * Get file from local storage
 * @param event 
 */
onFileChanged(event) {
  console.log("onfile change= ")
  let i: number = 0;
  for(i = 0; i < event.target.files.length; i++){
    this.uploadingFile = <File>event.target.files[i];
    if (this.uploadingFile != undefined) {
      let parts = this.uploadingFile.name.split('.')
      if(parts.length>1){
        this.fileExtension = parts.pop()
        this.uploadFileName = parts.join('.')
        var documentId = this.createUUID();
        
        if (this.validateForm()) {
        //set for inserting to the database
        var punchlistDoc = {
          id: documentId,
          punchlistId: null,
          fileName: this.uploadingFile.name,
          contentType: this.fileExtension,
          projectId: this.projectId,
          filePath: '',
          isAdded: true,
          isDeleted: false,
          addedUserId: '',
          fileSize:this.uploadingFile.size
        };
        this.punchlist.punchlistDocuments.push(punchlistDoc);
  
        //set for uploading to the local file system
        var punchlistUploadDoc = 
        {
          docId: documentId,
          docName:this.uploadFileName, 
          docType:this.fileExtension,
          file: this.uploadingFile,
          isAdded: true,
          isDeleted: false
        };
        this.punchlistUploadDocuments.push(punchlistUploadDoc);
      }
      }else{
        this.translate.get('NEW_PUNCHLIST_DOCUMENT.INVALID_FILE_NAME').subscribe((res: string) => {
        this.alertService.error(res, false);
        });
      }
    } else {
      this.uploadFileName = '';
  }
  }
 
  //refresh ag-grid 
  this.createRowData(this.punchlist.punchlistDocuments.filter(x=>!x.isDeleted));
}

/**
 * Upload documents into local storage folder
 * @param punchlistId 
 */
onUpload(punchlistId: string) {
  var loadingMessage;
  this.translate.get('NEW_PUNCHLIST_DOCUMENT.LOADING_MESSAGE').subscribe((res: string) => {
    loadingMessage = res;
  });
  let token = UserVariable.getUserToken(); 
  if(!this.commonUtil.validateToken(token)){
    return;
  }
  this.loadingService.showLoading(true, null, loadingMessage, null);
 
  this.punchlistUploadDocuments.forEach(element => {
    if(element.isAdded && !element.isDeleted){
      this.uploadFileNameWithExt = element.docName + "." + element.docType;
      const uploadData = new FormData();
      console.log("element.docId = ", element.docId);
      console.log("this.punchlist.id = ", punchlistId);
      uploadData.append("docId", element.docId);
      uploadData.append("punchlistId", punchlistId);
      uploadData.append("projectId", this.projectId);
      uploadData.append("filePath", '');
      uploadData.append("fileName", this.uploadFileNameWithExt);
      uploadData.append("contentType", element.docType);
      uploadData.append("token", token);
      uploadData.append("file", element.file, element.docName);
      uploadData.append("addedUserId", '');
      uploadData.append("isAdded", element.isAdded);
      uploadData.append("isDeleted", element.isDeleted);

      this.fileUploadService.uploadFile(uploadData, this);
    }
  });
}

/**
 * ag-grid punchlist documents
 */
private prepareAgGrid(){
  this.columnDefs = [
    {
      headerName: 'Document Id',
      field: 'docId',
      hide: true
    },
    {
      headerName: 'Document Name',
      field: 'docName',
      width: 500,
      cellClass: "cell-wrap-text", 
			cellStyle: {"white-space": "normal"},
    },
    {
      headerName: 'Content Type',
      field: 'docType',
      width: 400,
      cellClass: "cell-wrap-text", 
			cellStyle: {"white-space": "normal"},
    },
    {
      headerName: 'Delete',
      field: 'docDelete',
      width: 200,
      cellStyle: { 'text-align': 'center', "white-space": "normal"},
      cellClass: "cell-wrap-text",
      cellRenderer: function (params) {
        return ('<button type="button" [disabled]="false" class="btn btn-sm btn-outline-dark py-0 my-1 px-1 border-0"(click)="deleteButtonClick($event)"><i class="far fa-trash-alt" aria-hidden="true"></i></button>');
      }
    }
  ];

  this.gridOptions = {
    rowData: this.rowData,
    columnDefs: this.columnDefs,
    defaultColDef: {
      resizable: true,
      sortable: true
    },
    rowSelection: "single",
    rowMultiSelectWithClick: false
};

}

/**
 * Initializing data for the punchlist documents ag-grid
 * @param documents 
 */
private createRowData(documents: Array<PunchlistDocument>){
  var rowData : any[] = [];
  documents.forEach(punchlistDoc => {
    rowData.push({
      docId: punchlistDoc.id,
      docName: punchlistDoc.fileName,
      docType: punchlistDoc.contentType
    });
  })
  
  this.rowData = rowData;
  this.prepareAgGrid();
}

/**
 * Delete punchlist document
 * @param event 
 */
public onDelete(event){
  if(event.colDef.field == "docDelete"){
    var selectedRowData = this.gridApi.getSelectedRows();
    this.gridApi.applyTransaction({ remove: selectedRowData });
    var docId = selectedRowData[0].docId;
    this.punchlist.punchlistDocuments.forEach(doc=>{
      if(doc.id == docId){
        doc.isDeleted = true;
      }
    });
    this.punchlistUploadDocuments.forEach(doc=>{
      if(doc.id == docId){
        doc.isDeleted = true;
      }
    });
    this.createRowData(this.punchlist.punchlistDocuments.filter(x=>!x.isDeleted));
  }
}

onGridReady(params: any) {
  console.log("ongrid ready")
  this.gridApi = params.api
  this.gridColumnApi = params.columnApi;
  this.gridApi.hideOverlay()
}

/**
 * Generate unique identifier
 */
public createUUID(){
  var dt = new Date().getTime();
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = (dt + Math.random()*16)%16 | 0;
    dt = Math.floor(dt/16);
    return (c=='x' ? r :(r&0x3|0x8)).toString(16);
  });
  return uuid;
}	

validateForm(): boolean {
  this.uploadFileName = this.uploadFileName.trim()
  if (this.uploadingFile.size > this.MAX_FILE_SIZE){
    this.translate.get('NEW_PUNCHLIST_DOCUMENT.SIZE_LIMIT_EXCEEDED').subscribe((res: string) => {
      this.alertService.error(res, false);
    });
    return false;
  }
 
  if(this.uploadingFile.type != 'application/pdf'){
    this.translate.get('NEW_PUNCHLIST_DOCUMENT.INVALID_FILE_TYPE').subscribe((res: string) => {
      this.alertService.error(res, false);
    });
    return false;
  }

  if (this.uploadingFile.name.length > 100) {
    this.translate.get('NEW_PUNCHLIST_DOCUMENT.FILE_NAME_SIZE_LIMIT_EXCEEDED').subscribe((res: string) => {
      this.alertService.error(res, false);
    });
    return false;
  }

  return true;
}

//check total attachment size exceed 25MB
IsMaxAttachmentSizeExceeded() {
  var isExceeded = false;
  var totalSize = 0;

  //get document sizes
  this.punchlist.punchlistDocuments.forEach(element => {
    if (!element.isDeleted) {
      totalSize += element.fileSize;
    } else {
      //deleted
    }
  });

  if (totalSize > this.MAX_FILE_SIZE) {
    isExceeded = true;
  } else {
    //false
  }

  return isExceeded;
}

onSuccess(data: WsResponse, serviceType: WsType) {
  if(serviceType == WsType.ADD_NEW_PUNCHLIST){
    this.onUpload(data.payload);
    let pinchlistId = data.payload;
    if(this.parentComponent){
      if(this.parentComponent.componentName == "AddeditirnComponent"){
        let payload = {punchlistId:pinchlistId,punchlistDes:this.punchlist.punchlistDescription,assetName: this.punchlist.assetName,systemName: this.punchlist.subSystemName,assetId: this.punchlist.assetId,systemId: this.punchlist.subsystemId}
        let data= new WsResponse(
            "",
            "",
            "",
            payload);
        this.parentComponent.onSuccess(data,WsType.GET_PUNCHLIST_DATA_TO_IRN);
      } else if(this.parentComponent.componentName == "ApproveCheckSheetComponent"){
        let payload = {punchlistId:pinchlistId, punchlistDes:this.punchlist.punchlistDescription, punchlistCategory: this.punchlist.category, assetName: this.punchlist.assetName, systemName: this.punchlist.subSystemName, 
          assetId: this.punchlist.assetId, systemId: this.punchlist.subsystemId, checkSheetCheckId: this.punchlist.checksheetCheckId}
        let data = new WsResponse(
            "",
            "",
            "",
            payload);
        this.parentComponent.onSuccess(data,WsType.GET_PUNCHLIST_DATA_TO_CHECKSHEET);
      } else{
        //do nothing
      } 
    }
    this.loadingService.hideLoading();
    this.alertService.success("Successfully added the punchlist");
    this.cancel();
    // this.bsModalRef.hide();
  }
  else if(serviceType == WsType.ADD_NEW_PUNCHLIST_DOCUMENT){
    
  }
  else if(serviceType == WsType.GET_ASSETS){
    this.loadingService.hideLoading()
    this.assetList = [];
    if (data.payload != null) {
        data.payload.forEach(element => {
          this.assetList.push({ 'id': element.tagId, 'text': element.tagNo });
        });
        this.assetDisable = false;
    } 
  }
  else if(serviceType == WsType.GET_SYSTEMS_BY_PROJECT_ID){
    this.subSystemList = [];
    if (data.payload != null) {
        data.payload.forEach(element => {
          this.subSystemList.push({ 'id': element.id, 'text': element.systemNo + ' ' + element.systemName, 'parentId': element.parentId, 'parentName': element.parentSystemName, 'parentNo': element.parentSystemNo });
        });
        this.subSystemDisable = false;
    }
  }
  else if(serviceType == WsType.GET_DISCIPLINES){
    this.disciplineList = [];
    if (data.payload != null) {
        data.payload.forEach(element => {
          this.disciplineList.push({ 'id': element.disciplineId, 'text': element.disciplineName });
        });
        this.disciplineDisable = false;
    } 
  }
  else if (serviceType == WsType.GET_VENDOR_LIST) {
    this.vendorList = [];
    if (data.payload != null) {
      data.payload.forEach(element => {
        this.vendorList.push({ 'id': element.vendorId, 'text': element.vendorName });
      });
      this.vendorDisable = false;
    }
  }
}

onFail(data: WsResponse, serviceType: WsType) {
  this.loadingService.hideLoading();
  if(serviceType == WsType.ADD_NEW_PUNCHLIST){
    this.alertService.error(data.statusDescription);
  }
  else if(serviceType == WsType.ADD_NEW_PUNCHLIST_DOCUMENT){
    this.alertService.error(data.statusDescription);
  }
  else if(serviceType == WsType.GET_ASSETS){
    this.alertService.clear()
    this.alertService.error(data.statusDescription)
  }
  else if(serviceType == WsType.GET_SYSTEMS_BY_PROJECT_ID){
    this.alertService.clear()
    this.alertService.error(data.statusDescription)
  }
  else if(serviceType == WsType.GET_DISCIPLINES){
    this.alertService.clear()
    this.alertService.error(data.statusDescription)
  }
  else if (serviceType == WsType.GET_VENDOR_LIST) {
    this.alertService.clear()
    this.alertService.error(data.statusDescription)
  }
}

  checkFirstOilRequired(event: any) {
    if (event == true) {
      this.punchlist.firstOilRequired = true;
    }
    else {
      this.punchlist.firstOilRequired = false;
    }
  }
  
}

