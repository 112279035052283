import { Component, OnInit, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-ru-sidebar',
	templateUrl: './ru-sidebar.component.html',
	styleUrls: ['./ru-sidebar.component.scss'],
	outputs: ['selectSideBarItem']
})
export class RuSidebarComponent implements OnInit {

	//public selectedMenuItem: string = 'procedurestore'
	public selectedMenuItem: string = 'reusableupdate'
	selectSideBarItem = new EventEmitter<any>()

	constructor() { }

	ngOnInit() {
	}

	public sideItemClick(selectedItem: string) {
		this.selectedMenuItem = selectedItem
		this.selectSideBarItem.emit(
			{
				selectedItem: selectedItem
			}
		)
	}

}
