import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorage } from '../../../util/localstorage.service';
import { TranslateService } from '@ngx-translate/core';
import { UserVariable } from '../../../util/common/user-variable';
import { AlertService } from '../../../util/alert/alert.service';
import { SharedService } from '../../../util/shared.service';
import { GridOptions, Module, AllModules } from "@ag-grid-enterprise/all-modules";
import { ProcedureStoreService } from '../procedurestore.service';
import { CapabilityService } from '../../../util/capability.service'
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AddToStoreButtonRender } from '../cell-render/addtostorebutton/addtostorebutton.component'
import { DeleteButtonAddedStoreRender } from '../cell-render/addtostorebutton/deleteaddedstore.component'
import { LoadingService } from '../../../util/loading/loading.service';


@Component({
   selector: 'app-addprocedurestore',
   templateUrl: './addprocedurestore.component.html',
   styleUrls: ['./addprocedurestore.component.css']
})
export class AddProcedureStoreComponent implements OnInit {
   public isMinimized: boolean = false
   public rowData: any[] = [];
   private procedureData: any[] = [];
   private gridApi;
   private gridColumnApi;
   public columnDefs;
   private customColumn;
   private ps_translated_data: any = null;
   public frameworkComponents;
   public gridOptions: GridOptions;
   public defaultColDef: any;
   private projectId: string = UserVariable.projectId
   public facilityList: Array<any> = []
   public selectedFacility: Array<any> = [{ 'id': 'ALL', 'text': 'ALL' }, { 'id': 'None', 'text': 'None' }]  
   public facilityId: string;
   public facilityName: string;
   public modules: Module[] = AllModules;

   constructor(private procedureStoreService: ProcedureStoreService, private sharedService: SharedService, private translate: TranslateService, private alertService: AlertService,
      private capabilityService: CapabilityService, private modalService: BsModalService, private loadingService: LoadingService) {

      let browserLang = translate.getBrowserLang();
      this.gridOptions = <GridOptions>{
         context: {
            componentParent: this
         }
      };
      this.defaultColDef = {
			resizable: true,
			sortable: true,
		};
      translate.getTranslation(browserLang).subscribe((res: string) => {
         this.ps_translated_data = res['PROCEDURE_STORE']

         this.columnDefs = [
            {
               headerName: this.ps_translated_data.PROCEDURE, field: 'name', autoHeight: true, cellClass: "cell-wrap-text"
            },
            {
               headerName: this.ps_translated_data.FOLDER_PATH, field: 'folderPath', autoHeight: true, cellClass: "cell-wrap-text"
            },
            {
               headerName: this.ps_translated_data.SYSTEM, field: 'systemName', autoHeight: true, cellClass: "cell-wrap-text"
            },
            {
               headerName: this.ps_translated_data.FACILITY, field: 'facilityName', autoHeight: true, cellClass: "cell-wrap-text"
            },
            {
               headerName: this.ps_translated_data.VERSION, field: 'storeVersion', autoHeight: true, cellClass: "cell-wrap-text"
            },
            {
               headerName: this.ps_translated_data.ADDED_DATE, field: 'lastUpdateTime', autoHeight: true, cellClass: "cell-wrap-text"
            },
            {
               headerName: this.ps_translated_data.ADD_TO_STORE, field: '', autoHeight: true, cellClass: "cell-wrap-text", width: 85, suppressFiltersToolPanel: true,
               cellRendererFramework: AddToStoreButtonRender
            },
            {
               headerName: this.ps_translated_data.DELETE, field: '', autoHeight: true, cellClass: "cell-wrap-text", width: 85, suppressFiltersToolPanel: true,
               cellRendererFramework: DeleteButtonAddedStoreRender
            }
           

         ]
      });
   }

   ngOnInit() {
      //this.getAddProcedureStoreViewData()
   }

   onColumnResized(event) {
      if (event.finished) {
         this.gridApi.resetRowHeights();
      }
   }

   onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      params.api.sizeColumnsToFit();
      this.getAddProcedureStoreViewData();
      setTimeout(function () {
         params.api.sizeColumnsToFit();
         params.api.resetRowHeights();
      }, 500);
      this.procedureStoreService.tableReloadRespond().subscribe(
         (option: any) => {
            console.log("reload table event= "+ option.event);
            if(this.facilityId==null){
               this.getAddProcedureStoreViewData();
            }
            else {
               this.getProcedureStoreItemsByFacility();
            }
         }
      )
   }
   removedFacility(event) {
      this.selectedFacility = [{ 'id': 'ALL', 'text': 'ALL' }]
   }

   selectedFacilityId(event) {
      this.facilityId = event.id    
      this.facilityName = event.text        
      this.getProcedureStoreItemsByFacility()
   }

   public getProcedureStoreItemsByFacility() {
      this.loadingService.showLoading(true, null, "", null)
      this.rowData = []
      this.procedureStoreService.getProcedureStoreItemsByFacility(this.facilityId)
         .subscribe(data => {
            this.loadingService.hideLoading()
            this.rowData = data.payload;
            this.gridApi.sizeColumnsToFit();
         },
            error => {
               this.loadingService.hideLoading()
               this.alertService.clear()
               this.alertService.error(error.statusDescription)
            });
   }

   public getAddProcedureStoreViewData() {
      this.loadingService.showLoading(true, null, "", null)
      this.rowData = []
      this.procedureStoreService.getAddProcedureStoreViewData(this.projectId)
         .subscribe(data => {
            this.loadingService.hideLoading()
            this.procedureData = []
            this.rowData = []
            if (data.payload != null) {
               data.payload.forEach(element => {
                  if (element.id != null) {
                     this.procedureData.push( element)
                  }
               });
               this.rowData = this.procedureData;
               this.gridApi.sizeColumnsToFit();
               this.facilityList = []
               this.facilityList.push({ 'id': 'ALL', 'text': 'ALL' }, { 'id': 'None', 'text': 'None' })
               data.payload.forEach(element => {
                  if (element.facilityList != null) {                  
                     for (let key of Object.keys(element.facilityList)) {                     
                        this.facilityList.push({ 'id': key, 'text': element.facilityList[key] })
                     }
                  }
               });
            } else {
               //nothing to do here
            }
          
         },
         error => {
            this.loadingService.hideLoading()
            this.alertService.clear()
            this.alertService.error(error.statusDescription)
         });
   }
}