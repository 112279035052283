import { Component, OnInit, QueryList, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '../../../util/alert/alert.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DocumenttypetreeComponent } from '../../../util/documenttypetree/documenttypetree.component';
import { LoadingService } from '../../../util/loading/loading.service';
import { UserVariable } from 'src/app/util/common/user-variable';
import { FileUploadService } from './file-upload.service';
import { WsCallback } from '../../../util/ws-callback.interface';
import { WsResponse } from '../../../util/ws-response.model';
import { WsType } from '../../../util/ws-type';
import { FloatingButtonCallback } from '../floating-button-callback.interface';
import { DocumentTypeService } from '../../../util/documenttypetree/documenttype.service';
import { TreeListener } from '../../../util/documenttypetree/tree-listener.interface';
import { CommonUtil } from '../../../util/common/common-util';

@Component({
  selector: 'app-new-threed-model',
  templateUrl: './new-threed-model.component.html',
  styleUrls: ['./new-threed-model.component.css']
})
export class NewThreedModelComponent implements OnInit, TreeListener, WsCallback {
  public title: string;
  public fileComment: string;
  public modelTitle: string;
  public documentType: string;
  public attributeList: any;
  public uploadFileExt: string;
  public selectedFolder: any;
  public fileTitle: string;
  public floatingButtonCallback: FloatingButtonCallback;
  public haveProperties: boolean = true;
  
  private treeBsModalRef: BsModalRef;
  private documentTypeId: string;
  private uploadingFile: File;
  private fileExtension: string;
  selectedType: string;
 
  constructor(public bsModalRef: BsModalRef, private modalService: BsModalService, private alert: AlertService,
     private documentTypeService: DocumentTypeService, private translate: TranslateService, private loadingService: LoadingService, private fileUploadService: FileUploadService, private commonUtil: CommonUtil) { }

  ngOnInit() {
  }

  onFileChanged(event) {
    console.log("onfile change= ")
    this.uploadingFile = <File>event.target.files[0];
    if (this.uploadingFile != undefined) {
            let parts = this.uploadingFile.name.split('.')
      if(parts.length>1){
            this.fileExtension = parts.pop()
            this.modelTitle = parts.join('.')
            this.fileTitle = parts.join('.')
      }else{
        this.translate.get('NEW_THREED_MODEL.INVALID_FILE_NAME').subscribe((res: string) => {
          this.alert.error(res, false);
        });
            }
    } else {
      this.modelTitle = '';
    }
  }

  public loadDocumentTypes() {
        const initialState = {
            title: 'Select a Document Type',
            treeListener: this,
            initial: true
        };
        this.treeBsModalRef = this.modalService.show(DocumenttypetreeComponent, { initialState, class: 'modal-lg' });
        this.treeBsModalRef.content.closeBtnName = 'Close';
  }

  
  
  /**
     * Clicks on the submit button
     */
    public onSubmitButton() {
        this.onUpload();
  }
  
  /**
     * Call web services to upload file and data.
     */
    onUpload() {
        
        if (!this.validateForm()) {
            return;
        }
     
        var loadingMessage;
        this.translate.get('NEW_THREED_MODEL.LOADING_MESSAGE').subscribe((res: string) => {
            loadingMessage = res;
        });
        this.loadingService.showLoading(true, null, loadingMessage, null);
        var attributeValueXml: string = '';
        //creating attribute xml string.
        attributeValueXml = '<attrs>';
        if (this.attributeList != undefined && this.attributeList.length != undefined && this.attributeList.length > 0) {
            this.attributeList.forEach(item => {
                attributeValueXml += "<attr type=\"text\" name='" + item.attributeName + "' typeattributeid='" + item.attributeId + "'>" + item.value + "</attr>";
            });
        }
        attributeValueXml += '</attrs>';
    
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        if(this.fileComment == undefined){
            this.fileComment = "";
        }
        const uploadData = new FormData();
        uploadData.append("fileName", this.modelTitle);
        uploadData.append("fileNameExt", this.uploadFileExt);
        uploadData.append("title", this.modelTitle);
        uploadData.append("typeId", this.documentTypeId);
        uploadData.append("comment", this.fileComment);
        uploadData.append("parentFolderId", this.selectedFolder.id);
        uploadData.append("attributes", attributeValueXml);
        uploadData.append("token", token);
        uploadData.append("file", this.uploadingFile, this.modelTitle);
    
        this.fileUploadService.uploadFile(uploadData, this);
    
  }
  
  /**
     * Validate the form for compulsory fields.
     */
    validateForm(): boolean {
        if (this.uploadingFile == undefined) {
            return false;
        }
        this.modelTitle = this.modelTitle.trim()
        if (this.modelTitle) {
            var extension = this.uploadingFile.name.split('.').pop();
            console.log("ext: " + extension.toLowerCase());
            if (extension.toLowerCase() != 'zip') {
                this.translate.get('NEW_THREED_MODEL.INVALID_FILE_TYPE').subscribe((res: string) => {
                    this.alert.error(res, false);

                });
                return false;
            }
        } else {
            return false;
        }
        if (this.documentTypeId) {
        } else {
            this.documentType = "";
            return false;
        }
        // if (this.uploadingFile.size > 104857601 ){
        // 	this.translate.get('NEW_IMAGE.SIZE_LIMIT_EXCEEDED').subscribe((res: string) => {
        // 	  this.alert.error(res, false);
        // 	});
        // 	return false;
        // }
        if (this.selectedType != this.documentType) {
            this.documentType = "";
            return false;
        }

        //Check for attribute values
        if (this.attributeList != undefined && this.attributeList.length != undefined && this.attributeList.length > 0) {
            var isEmptyAttribute: boolean = false;
            this.attributeList.forEach(item => {
                if(item.required == 'yes' && (item.value == null || item.value == "")){
					this.alert.error(this.translate.instant('NEW_THREED_MODEL.ATTRIBUTE.EMPTY_ATTRIBUTE_ERROR_MESSAGE'));
                    isEmptyAttribute = true;
                }
                // if (item.attributeName == 'Document') {
                //     if (item.documents != undefined) {
                //         isEmptyAttribute = false;
                //     }
                // } else if (item.attributeName == 'c1') {
                //     if (item.components != undefined) {
                //         isEmptyAttribute = false;
                //     }
                // } else if (item.attributeName == 'System') {
                //     if (item.systems != undefined) {
                //         isEmptyAttribute = false;
                //     }
                // }
            });
            if (isEmptyAttribute) {
                return false;
            }
        }

        return true;
    }

        /**
     * Callback method for the successful web service calls.
     * @param data web service response data
     * @param serviceType service type id
     */
    onSuccess(data: WsResponse, serviceType: WsType) {
        if (serviceType == WsType.DOCUMENT_TYPE_ATTRIBUTES_VIEW) {
            this.attributeList = data.payload;
            if (data.payload.length == 0) {
                this.haveProperties = false;
            } else {
                this.haveProperties = true;
            }
        } else if (serviceType == WsType.ADD_NEW_THREED_MODEL) {
            this.alert.success(data.statusDescription);
            this.floatingButtonCallback.hide();
            this.loadingService.hideLoading();
        }
    }

    /**
     * Callback method for the unsuccessful web service calls.
     * @param data web service response data
     * @param serviceType service type id
     */
    onFail(data: WsResponse, serviceType: WsType) {
        if (serviceType == WsType.DOCUMENT_TYPE_ATTRIBUTES_VIEW) {
            this.alert.error(data.statusDescription, false);
        } else if (serviceType == WsType.ADD_NEW_THREED_MODEL) {
            if(data.statusCode=='504'){
                this.alert.error("Some files are missing. Cannot insert 3D model", false);
            }
            else{
                this.alert.error(data.statusDescription, false);
            }
            this.loadingService.hideLoading();
        }
    }

        /**
     * Clicks on the save button of the tree view popup
     * @param file selected file
     * @param texts text element
     * @param options options element
     * @param documentTypeAttributes selected attributes
     */
    onSaveButtonClick(file: any, texts: QueryList<ElementRef>, options: QueryList<ElementRef>, documentTypeAttributes: any) {
        this.documentTypeService.getDocumentTypeAttributes(file.node.id, this.selectedFolder, this);
        this.documentType = file.node.text;
        this.selectedType = file.node.text;
        this.documentTypeId = file.node.id;
        this.treeBsModalRef.hide();
    }

    checkComponent(type:any, name:any){
		if(type == 'Component' || name == 'c1' ){
		   return true;
	   }
   }

   checkSystem(type:any, name:any){
		 if(type == 'System' || name == 'System' ){
		   return true;
	   }
   }
   checkDocument(type:any, name:any){
		 if(type == 'Document' || name == 'Document'){
		   return true;
	   }
   }
}
