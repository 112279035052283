import { Component, ElementRef, EventEmitter, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SelectComponent } from 'src/app/util/ng2-select/select';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService } from 'src/app/util/alert/alert.service';
import { UserVariable } from 'src/app/util/common/user-variable';
import { LoadingService } from 'src/app/util/loading/loading.service';
import { WsCallback } from 'src/app/util/ws-callback.interface';
import { WsResponse } from 'src/app/util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';
import { ManagechecksheetsService } from '../managechecksheets.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-addeditchecksheet',
  templateUrl: './addeditchecksheet.component.html',
  styleUrls: ['./addeditchecksheet.component.css']
})
export class AddeditchecksheetComponent implements OnInit, WsCallback {
  public edit: boolean;
  public csId: string;
  private loadingHandler: any;
  @ViewChildren(SelectComponent) selectElem: QueryList<SelectComponent>
  @ViewChild('csName') csName: ElementRef;
  @ViewChild('csCode') csCode: ElementRef;

  public functionCodeList = [];
  public selectedFunctionCodeList = [];
  private funtionCodeIds = [];
  private projectId: String;
  public disciplineList = [];
  public typeList = [];

  public selectType;
  public selectDiscipline;
  public name: string = null;
  public code: string = null;
  private checksheetStatus: string = null;
  emptyFieldError: string;
  emptyTypeError: string;
  emptyDiscError: string

  loadingCSSub: Subscription;

  constructor(
    private checksheetService: ManagechecksheetsService,
    private loadingService: LoadingService,
    private alertService: AlertService,
    public translate: TranslateService,
    public bsModalRef: BsModalRef,
  ) {
    translate.addLangs(["es", "en"]);
    translate.setDefaultLang("en");
    let browserLang = translate.getBrowserLang();
    this.translate.get('CS_ADD_EDIT.FIELD_EMPTY').subscribe((res: string) => {
      this.emptyFieldError = res;
    });
    this.translate.get('CS_ADD_EDIT.TYPE_EMPTY').subscribe((res: string) => {
      this.emptyTypeError = res;
    });
    this.translate.get('CS_ADD_EDIT.DISCIPLINE_EMPTY').subscribe((res: string) => {
      this.emptyDiscError = res;
    });
  }

  ngOnInit(): void {
    this.typeList = [
      { 'id': '1', 'text': 'A' },
      { 'id': '2', 'text': 'B' },
      { 'id': '3', 'text': 'C' },
      { 'id': '4', 'text': 'D' }
    ];

    this.projectId = UserVariable.projectId;
    if (this.edit) {
      this.loadingHandler = new AddeditchecksheetComponent.LoadingHandler();
      // loading Checksheet after loading all other items
      this.loadingCSSub = this.loadingHandler.getLoadedSub().subscribe(() => {
        this.loadingService.showLoading(true, false, 'Loading Checksheet data', 0);
        this.checksheetService.getCSDataById(this.csId, this);
      });
      this.loadingHandler.init(2, this.loadingService);
      this.checksheetService.getDisciplineList(this.projectId, this);
      this.checksheetService.getFunctionCodeList(this);
    }
    else {
      this.loadingHandler = new AddeditchecksheetComponent.LoadingHandler();
      this.loadingHandler.init(2, this.loadingService);
      this.checksheetService.getDisciplineList(this.projectId, this);
      this.checksheetService.getFunctionCodeList(this);
    }
  }

  onSuccess(data: WsResponse, serviceType: WsType) {
    if (serviceType == WsType.GET_ALL_FUNCTION_CODES) {
      this.functionCodeList = [];
      if (data.payload != null) {
        data.payload.forEach(element => {
          this.functionCodeList.push({ 'id': element.functionCodeId, 'text': element.functionCode });
        });
      }
      this.loadingHandler.handle();
    }
    else if (serviceType == WsType.GET_DISCIPLINES) {
      this.disciplineList = [];
      if (data.payload != null) {
        data.payload.forEach(element => {
          this.disciplineList.push({ 'id': element.disciplineId, 'text': element.disciplineName });;
        });
      }
      this.loadingHandler.handle();
    }
    else if (serviceType == WsType.ADD_NEW_CHECKSHEET) {
      this.loadingService.hideLoading();
      this.alertService.clear()
      this.translate.get('CS_ADD_EDIT.ADD_SUCCESS').subscribe((res: string) => {
        this.alertService.success(res);
      });
      this.bsModalRef.hide();
    }
    else if (serviceType == WsType.GET_CS_DATA_BY_ID) {
      this.loadingService.hideLoading();
      if (data.payload != null) {
        this.setValues(data.payload)
      }
    }
    else if (serviceType == WsType.UPDATE_CHECKSHEET) {
      this.loadingService.hideLoading();
      this.alertService.clear()
      this.translate.get('CS_ADD_EDIT.UPDATE_SUCCESS').subscribe((res: string) => {
        this.alertService.success(res);
      });
      this.checksheetService.loadTableDataRequest();
      this.bsModalRef.hide();
    }
  }

  onFail(data: WsResponse, serviceType: WsType) {
    if (serviceType == WsType.GET_ALL_FUNCTION_CODES) {
      this.alertService.clear();
      this.alertService.error(data.statusDescription);
      this.loadingHandler.handle();
    }
    else if (serviceType == WsType.GET_DISCIPLINES) {
      this.alertService.clear();
      this.alertService.error(data.statusDescription)
      this.loadingHandler.handle();
    }
    else if (serviceType == WsType.ADD_NEW_CHECKSHEET) {
      this.loadingService.hideLoading();
      if (data.statusDescription != null) {
        this.alertService.clear()
        this.alertService.error(data.statusDescription)
      }
      else {
        this.alertService.clear()
        this.translate.get('CS_ADD_EDIT.UPDATE_FAILED').subscribe((res: string) => {
          this.alertService.error(res);
        });
      }
    }
    else if (serviceType == WsType.GET_CS_DATA_BY_ID) {
      this.loadingService.hideLoading();
      this.alertService.clear();
      this.alertService.error(data.statusDescription)
    }
    else if (serviceType == WsType.UPDATE_CHECKSHEET) {
     this.loadingService.hideLoading();
      if (data.statusDescription != null) {
        this.alertService.clear()
        this.alertService.error(data.statusDescription)
      }
      else {
        this.alertService.clear()
        this.translate.get('CS_ADD_EDIT.ADD_FAILED').subscribe((res: string) => {
          this.alertService.error(res);
        });
      }
    }
  }

  selectedType(value: any) {
    this.selectType = this.typeList.filter(x => x.id == value.id);
  }

  selectedDiscipline(value: any) {
    this.selectDiscipline = this.disciplineList.filter(x => x.id == value.id);
  }

  removedType(event: any): void {
    this.selectType = null;
  }

  removedDiscipline(event: any): void {
    this.selectDiscipline = null;
  }

  //hides the modal
  cancel() {
    this.bsModalRef.hide()
  }

  public closeOtherElems(element: any) {
    let a = this.selectElem.filter(function (el) {
      return (el != element)
    });

    a.forEach(function (e: SelectComponent) {
      e.hideOptions();
    })
  }

  public selectItemFromSuggestions(event: any) {
  }

  removeSelectedFuncCode(event) {

  }

  change(event: any, val: string) {
    switch (val) {
      case 'name':
        this.name = event.target.value;
        break;
      case 'code':
        this.code = event.target.value;
        break;
      default:
        break;
    }
  }

  saveChecksheet() {
    if (!this.edit) {
      if (this.csName.nativeElement.value.trim() == "" || this.csCode.nativeElement.value.trim() == "") {
        this.alertService.clear();
        this.alertService.error(this.emptyFieldError);
        return;
      }
    }
    else {
      if (this.name == undefined || this.name.trim() == "" || this.code == undefined || this.code.trim() == "") {
        this.alertService.clear();
        this.alertService.error(this.emptyFieldError);
        return;
      }
    }
    if (this.selectType == undefined || this.selectType == null) {
      this.alertService.clear();
      this.alertService.error(this.emptyTypeError);
    } else if (this.selectDiscipline == undefined || this.selectDiscipline == null) {
      this.alertService.clear();
      this.alertService.error(this.emptyDiscError);
    } else {
      this.funtionCodeIds = [];
      if (this.selectedFunctionCodeList.length > 0) {
        this.selectedFunctionCodeList.forEach((item) => {
          this.funtionCodeIds.push(item.text)
        })
      }
      this.loadingService.showLoading(true, false, 'Saving checksheet data', 0);
      if (!this.edit) {
        let data = {
          checkSheetName: this.csName.nativeElement.value,
          checkSheetCode: this.csCode.nativeElement.value,
          checkSheetType: this.selectType[0].text,
          checkSheetFunctionCodes: this.funtionCodeIds,
          checkSheetDiscipline: this.selectDiscipline[0].id
        }
        this.checksheetService.addNewChecksheet(JSON.stringify(data), this);
      }
      else {
        let data = {
          checkSheetId: this.csId,
          checkSheetName: this.name,
          checkSheetCode: this.code,
          checkSheetType: this.selectType[0].text,
          checkSheetFunctionCodes: this.funtionCodeIds,
          checkSheetDiscipline: this.selectDiscipline[0].id,
          checksheetStatus: this.checksheetStatus
        }
        this.checksheetService.updateChecksheet(JSON.stringify(data), this);
      }
    }
  }

  setValues(payload: any) {
    if (payload != undefined || payload != null) {
      var selectedFuncCodes = [];

      if (payload.checkSheetName) {
        this.name = payload.checkSheetName;
      }

      if (payload.checkSheetCode) {
        this.code = payload.checkSheetCode;
      }
      
      if (payload.checksheetStatus) {
        this.checksheetStatus = payload.checksheetStatus;
      }

      if (payload.checkSheetType) {
        this.selectType = this.typeList.filter(x => x.text == payload.checkSheetType);
      }

      if (payload.checkSheetFunctionCodes != null && payload.checkSheetFunctionCodes.length > 0) {
        payload.checkSheetFunctionCodes.forEach(code => {
          //var selectedFunc = this.functionCodeList.find(x => x.id == code);
          var selectedFunc = this.functionCodeList.find(x => x.text == code);
          selectedFuncCodes.push(selectedFunc);
        });
      }
      this.selectedFunctionCodeList = selectedFuncCodes;

      if (payload.checkSheetDiscipline) {
        this.selectDiscipline = this.disciplineList.filter(x => x.id == payload.checkSheetDiscipline);
      }
    }
  }

  /**
   * This class handles parallel calling of web services. loadingService initiate the loading service which is required to show and hide functionalities.
   * Then, parallelServiceCount indicate the number of parallel services that we want to call. init() method is used to initialize the handler object.
   * handle() method will be called on onSuccess() and onFail() methods of required service calls. This method will hide the loading view when the counter
   * becomes zero which means no more service response to be arrived.
   */
  static LoadingHandler = class {
    parallelServiceCount: number = 0;
    loadingService: LoadingService = null;
    loaded = new EventEmitter<any>()

    init(parallelServiceCount: number, loadingService: LoadingService) {
      this.parallelServiceCount = parallelServiceCount
      this.loadingService = loadingService;
      this.loadingService.showLoading(true, false, 'Loading data', 0);
    }

    handle() {
      --this.parallelServiceCount;
      if (this.parallelServiceCount == 0) {
        this.loadingService.hideLoading();
        this.loaded.emit("");
      }
    }

    getLoadedSub() {
      return this.loaded;
    }
  }
}


