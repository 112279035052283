import { OnInit, Component, TemplateRef, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ManagecheckitemsService } from '../managecheckitems.service';
import { SelectCategoryTreeComponent } from 'src/app/util/select-category-tree/select-category-tree.component';
import { AlertService } from 'src/app/util/alert/alert.service';
import { SharedService } from 'src/app/util/shared.service';
import { WsResponse } from 'src/app/util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';
import { LoadingService } from 'src/app/util/loading/loading.service';

@Component({
    selector: 'app-managechecks',
    templateUrl: './addeditcheck.component.html'
})
export class AddEditCheckComponent implements OnInit{
    public checkTypes: Array<any> = [{id: 1, text: 'OK-NA-PL'}, {id: 2, text: 'DATA ENTRY'}];
    public selectedCheckType:any = [];
    public categoryName: string;
    public categoryId: string;
    public categoryType: string;
    private modalRef: BsModalRef;
    private modalRefFraction: BsModalRef;
    private validationErrorCategory: string;
    private validationErrorCheckType: string;
    private validationErrorCheckDescription:string;
    selectedCategoryItem: any = { 'catName': '', 'catId': '' }
    public dataLoad: boolean = false;
    public checkDescription: string = "";
    public checkDescriptionHtml: string;
    public comment: string;
    public isEdit: boolean;
    private checkId: string;
    private status: string;

    public configData = {
		height: '20vh',
		extraPlugins: 'symbol,fractionchecks',
		removePlugins: 'font,save,image,link,magicline,pagebreak,showblocks,smiley,copyformatting,abot,flash,find,iframe,print,removeformatting,preview,search,templates,form,checkbox,radiobutton,forms,' +
			'stylescombo,tableselection,tab,table,tabletools,a11yhelp,about,dialogadvtab,colordialog,div,wsc,scayt,pastetext,pastefromword,widget,uploadwidget,' +
			'uploadimage,uploadwidget,widget,stylescombo,wsc,filebrowser,indentblock,justify,format,popup,blockquote,maximize,horizontalrule,selectall,removeformat,' +
			'bidi,newpage,elementspath',
		removeButtons: 'Source,Subscript,Superscript,Strike,Language,Undo,Redo,Cut,Copy,NumberedList,BulletedList,Indent,Outdent,TextColor,BGColor',
		resize_enabled: false,
		readOnly: false,
		basicEntities: true,
		autoParagraph: false,
    }
    
    constructor( public translate: TranslateService, private bsModalRef: BsModalRef, private checkItemsService: ManagecheckitemsService,
            private modalService: BsModalService, private alertservice: AlertService, private alertService: AlertService, private loadingService: LoadingService,
            private sharedService: SharedService){
        translate.addLangs(["es", "en"]);
        translate.setDefaultLang("en");
        let browserLang = translate.getBrowserLang();
        this.translate.get('ADD_EDIT_CHECKS.ERROR_CATEGORY_REQUIRED').subscribe((res: string) => {
            this.validationErrorCategory = res;
        });
        this.translate.get('ADD_EDIT_CHECKS.ERROR_CHECK_TYPE_REQUIRED').subscribe((res: string) => {
            this.validationErrorCheckType = res;
        });
        this.translate.get('ADD_EDIT_CHECKS.ERROR_CHECK_DESCRIPTION_REQUIRED').subscribe((res: string) => {
            this.validationErrorCheckDescription = res;
        });
    }

    ngOnInit() {
        if(this.sharedService.getFileId() != null && this.sharedService.getFileId() != "-1" && this.sharedService.getFileId() != -1 && this.sharedService.getFileId() != ""){
            this.isEdit = true;
            this.checkId = this.sharedService.getFileId();
            this.checkItemsService.getCheckByCheckId(this.checkId).subscribe(res=>{
                this.categoryId =  res.payload.checkCategory;
                this.selectedCategoryItem = { 'catName': res.payload.checkCategoryName, 'catId': res.payload.checkCategory }
                this.checkDescription = res.payload.checkDescription;
                this.selectedCheckType = this.checkTypes.filter(type => type.text == res.payload.checkType);
                this.status = res.payload.status;
            });
        } else{
            this.isEdit = false;
        }
    }

    cancel(){
        this.bsModalRef.hide();
    }
    
    closeButtonClickEvent(){
        this.modalRef.hide();
    }

    /**
     * Add new check
     */
    addCheck(){
        if(this.validateCheck()){
            this.loadingService.showLoading(true, false, 'Saving', 0);
            this.checkItemsService.addCheck(this.categoryId, this.selectedCheckType[0].text, this.checkDescription, this)
        } else{
            //do nothing
        }
    }

    /**
     * Update check
     */
    updateCheck(){
        if(this.validateCheck()){
            this.loadingService.showLoading(true, false, 'Updating', 0);
            var data = {
                checkId: this.checkId,
                checkCategory: this.categoryId,
                checkType: this.selectedCheckType[0].text,
                checkDescription: this.checkDescription,
                status: this.status
            }
            this.checkItemsService.updateCheck(JSON.stringify(data), this);
        } else{
            //do nothing
        }
    }

    /**
     * Validate check before saving
     */
    validateCheck(): boolean{
        let isValid: boolean = false;
        if(this.categoryId == undefined || this.categoryId == ""){
            this.alertservice.clear();
            this.alertservice.error(this.validationErrorCategory);
        } else if(this.selectedCheckType == undefined || this.selectedCheckType == null || this.selectedCheckType.length == 0){
            this.alertservice.clear();
            this.alertservice.error(this.validationErrorCheckType);
        } else if(this.checkDescription == undefined || this.checkDescription == null || this.checkDescription.length == 0){
            this.alertservice.clear();
            this.alertservice.error(this.validationErrorCheckDescription);
        } else{
            isValid = true;
        }
        return isValid;
    }

    removeCheckType(event: any){
        this.selectedCheckType = null;
    }

    selectCheckType(value: any){
        this.selectedCheckType = this.checkTypes.filter(x => x.id == value.id);
    }

    showCategoryList(template: TemplateRef<any>){
        this.categoryType = "chk_category";
        this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
    } 

    selectedCategory(value: any){
        this.selectedCategoryItem = value;
        this.categoryId = this.selectedCategoryItem.catId
        this.modalRef.hide();
    }

    openModal(template: TemplateRef<any>){
        this.modalRefFraction = this.modalService.show(template);
    }

    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    /**
     * Add fraction to the ckeditor
     * @param num 
     * @param den 
     */
    addSpeCharfraction(num: string, den: string) {
        if (den != "" && num != "") {
            var nume = parseInt(num);
            var denominator = parseInt(den);
            if (denominator != 0) {
                const myObj = {
                    numerator: num,
                    denominator: den,
                    type: 'fraction'
                };
                const myObjStr = JSON.stringify(myObj);
                let event = JSON.parse(myObjStr);

                const myObj1 = {
                    data: event
                };
                const myObjStr1 = JSON.stringify(myObj1);
                let data1 = JSON.parse(myObjStr1);
                this.insertFractionData(data1);
            } else {
                this.alertService.error(this.translate.instant('WEB_EDITOR.DENOMINATOR_ZERO'));
            }
        } else {
            this.alertService.error(this.translate.instant('WEB_EDITOR.NUMERATOR_AND_DENOMINATOR_EMPTY'));
        }
    }

    /**
     * Append fraction(customized button) data to the editor
     * @param option 
     */
    insertFractionData(option: any){
        this.checkDescription += option.data.numerator + "/" + option.data.denominator;
    }

    onSuccess(data: WsResponse, serviceType: WsType){
        if(serviceType == WsType.ADD_CHECK){
            this.loadingService.hideLoading();
            this.alertservice.success("Successfully added the check");
            this.bsModalRef.hide();
        } else if(serviceType == WsType.UPDATE_CHECK){
            this.loadingService.hideLoading();
            this.alertservice.success("Successfully updated the check");
            this.checkItemsService.loadTableDataRequest();
            this.bsModalRef.hide();
        }
    }
    
    onFail(data: WsResponse, serviceType: WsType){
        if(serviceType == WsType.ADD_CHECK){
            this.loadingService.hideLoading();
            this.alertService.clear();
            this.alertService.error(data.statusDescription)
        } else if(serviceType == WsType.UPDATE_CHECK){
            this.loadingService.hideLoading();
            this.alertService.clear();
            this.alertService.error(data.statusDescription)
        }
    }
}