import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams, IAfterGuiAttachedParams } from "@ag-grid-enterprise/all-modules";
import { ManageAttributesComponent } from '../manage-attributes.component';
import { TagsService } from '../../tags.service';

@Component({
  selector: 'app-edit-cell-render',
  template: `<button type="button" class="btn btn-sm btn-outline-dark py-0 my-1 px-1 border-0"
				(click)="onEditButtonClick()">
				<i class="far fa-edit" aria-hidden="true"></i>
			</button>`,
  styles: []
})
export class EditCellRenderComponent implements OnInit, ICellRendererAngularComp {
  public attribute: any;
  private params: any;
  public componentParent: ManageAttributesComponent;
  private cellData;

  constructor() { }

  ngOnInit() {
  }

  refresh(params: any): boolean {
    return true;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.cellData = params.data;
    this.componentParent = params.context.componentParent;

    if ( params.data != undefined)  {
      this.attribute = params.data;
    } else {
      this.attribute = params.value;
    }
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
  }

  onEditButtonClick() {
    this.componentParent.editTagAttribute(this.cellData);
  }
}
