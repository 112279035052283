import { Component, TemplateRef,  ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker/ngx-bootstrap-datepicker';
import { LoadingService } from 'src/app/util/loading/loading.service';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { AlertService } from 'src/app/util/alert/alert.service';
import { WsResponse } from 'src/app/util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';
import { ManagePCCService } from '../managepcc.service';
import { UserVariable } from 'src/app/util/common/user-variable';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-add-edit-pcc',
    templateUrl: './addeditpcc.component.html',
    styleUrls: ['./addeditpcc.component.css']
})
export class AddEditPCCComponent{
@ViewChild("editPCCModal") editPCCModal: TemplateRef<any>;
public pccId: string;
public targetCompletionDate: any;
public required: boolean;
public pccStatus: string;
public systemName: string;
public systemId: string;
public hide : boolean = false;
selectedItem: any;
public bsConfig: Partial<BsDatepickerConfig>;

constructor(private translate: TranslateService, private loadingService: LoadingService, 
    private bsModalRef: BsModalRef, private alertService: AlertService, private precommissioningservice: ManagePCCService,
    private editModalRef: BsModalRef, private bsModalService: BsModalService){
    translate.addLangs(["es", "en"]);
    translate.setDefaultLang("en");
    let browserLang = translate.getBrowserLang();
    this.bsConfig = Object.assign({}, { containerClass: 'theme-default' });
}

ngOnInit(){
    if(this.pccId){
        this.loadingService.showLoading(false, false, "loading PC", 0)
        this.precommissioningservice.getPCById(this.pccId, this)
      }else{
        this.selectedItem = {
        }
        this.targetCompletionDate = null
        this.required = false;
        this.pccStatus = "NotIssued"
    }
}

cancel(){
    this.bsModalRef.hide();
}

/**
 * Add or update precommissioning
 */
savePC(): void {
    if (this.selectedItem.pcId) {
        if (this.isInputValid()) {
            if (this.selectedItem.pcRequired && !this.required) {
                this.editModalRef = this.bsModalService.show(this.editPCCModal, { class: 'modal-sm' })
            } else {
                this.loadingService.showLoading(false, false, '', 0)
                if (!this.selectedItem.pcstatus) {
                    this.selectedItem.pcstatus = "NotIssued"
                }
                if (this.selectedItem.pcstatus == "Not Issued") {
                    this.selectedItem.pcstatus = "NotIssued"
                }
                this.selectedItem.pcRequired = this.required
                this.selectedItem.completionDate = new DatePipe("en-US").transform(new Date(this.targetCompletionDate), 'yyyy-MM-dd')
                console.log(JSON.stringify(this.selectedItem))
                this.precommissioningservice.updatePrecommissioningCompletion(this.selectedItem, this)
            }
        } else {
            this.alertService.clear();
            this.translate.get('PCC_ADD_EDIT.REQUIRED').subscribe((res: string) => {
                this.alertService.error(res);
              });
        }
    } else {
        if (this.isInputValid()) {
            this.loadingService.showLoading(false, false, '', 0)
            this.selectedItem.systemId = this.systemId;
            this.selectedItem.pcstatus = "NotIssued"
            this.selectedItem.pcRequired = this.required
            this.selectedItem.completionDate = new DatePipe("en-US").transform(new Date(this.targetCompletionDate), 'yyyy-MM-dd')
            this.selectedItem.projectId = UserVariable.projectId;
            this.precommissioningservice.addPrecommissioningCompletion(this.selectedItem, this)
        } else {
            this.alertService.clear();
            this.translate.get('PCC_ADD_EDIT.REQUIRED').subscribe((res: string) => {
                this.alertService.error(res);
              });
        }
    }
}

/**
 * Validate the add/edit precommissioning UI
 */
isInputValid() {
    if (!this.selectedItem.pcstatus) {
        if (this.targetCompletionDate && this.required) {
            return true
        } else {
            return false
        }
    } else {
        if (this.selectedItem.pcRequired && !this.required) {
            if (this.targetCompletionDate) {
                return true
            } else {
                return false
            }
        } else {
            if (this.targetCompletionDate && this.required) {
                return true
            } else {
                return false
            }
        }
    }
}

deletePCC(){
    this.editModalRef.hide();
    this.loadingService.showLoading(false, false, '', 0)
    this.precommissioningservice.deletePrecommissioningCompletion(this.selectedItem.pcId, this);
}

hideDeleteConfirm(){
    this.editModalRef.hide();
}

onSuccess(data: WsResponse, type: WsType){
    if(type == WsType.ADD_NEW_PCC){
        this.loadingService.hideLoading()
        this.translate.get('PCC_ADD_EDIT.ADD_SUCCESS').subscribe((res: string) => {
            this.alertService.success(res);
          });
        this.bsModalRef.hide();
        this.precommissioningservice.loadTableDataRequest();
    } else if(type == WsType.UPDATE_PCC){
        this.loadingService.hideLoading()
        this.translate.get('PCC_ADD_EDIT.UPDATE_SUCCESS').subscribe((res: string) => {
            this.alertService.success(res);
          });
        this.bsModalRef.hide();
        this.precommissioningservice.loadTableDataRequest();
    } else if(type == WsType.GET_PC_BY_ID){
        this.loadingService.hideLoading();
        if(data.payload){
            this.selectedItem = data.payload;
            this.targetCompletionDate = new Date(this.selectedItem.targetCompletionDate)
            this.required = this.selectedItem.pcRequired;
            this.pccStatus = this.selectedItem.pcstatus;
        }
    } else if(type == WsType.DELETE_PCC){
        this.loadingService.hideLoading()
        this.translate.get('PCC_ADD_EDIT.DELETE_SUCCESS').subscribe((res: string) => {
            this.alertService.success(res);
          });
        this.bsModalRef.hide();
        this.precommissioningservice.loadTableDataRequest();
    }
}

onFail(error: WsResponse, type: WsType){
    if(type == WsType.ADD_NEW_PCC){
        this.loadingService.hideLoading()
        this.alertService.clear()
        this.alertService.error(error.statusDescription);
    } else if(type == WsType.UPDATE_PCC){
        this.loadingService.hideLoading()
        this.alertService.clear()
        this.alertService.error(error.statusDescription);
    } else if(type == WsType.GET_PC_BY_ID){
        this.loadingService.hideLoading()
        this.alertService.clear()
        this.alertService.error(error.statusDescription);
    } else if(type == WsType.DELETE_PCC){
        this.loadingService.hideLoading()
        this.alertService.clear()
        this.alertService.error(error.statusDescription)
    }
}

}