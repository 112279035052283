import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GridOptions, ColDef } from '@ag-grid-enterprise/all-modules';
import { Constants } from 'src/app/util/constants';
import { ManagecheckitemsService } from './managecheckitems.service';
import { AlertService } from 'src/app/util/alert/alert.service';
import { LoadingService } from 'src/app/util/loading/loading.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AddEditCheckComponent } from './addeditcheck/addeditcheck.component';
import { CheckCellRendererComponent } from './checks-cell-renderer.component';
import { SharedService } from 'src/app/util/shared.service';
import { WsResponse } from 'src/app/util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-managecheckitems',
  templateUrl: './managecheckitems.component.html',
  styleUrls: ['./managecheckitems.component.css']
})
export class ManagecheckitemsComponent implements OnInit {
  public columnDefs: any;
  public rowData: any = [];
  public gridOptions: GridOptions;
  public getRowNodeId: any;
  private gridColumnApi: any;
  public gridApi: any;
  public overlayLoadingTemplate: any;
  public overlayNoRowsTemplate: any;

  public searchData: any;
  private checkCategory: string = "";
  private checkDescription: string = "";
  private hasText: string = "";
  public bsModalRef: BsModalRef
  private subsVar: Subscription;

  constructor(private translate: TranslateService, private checkItemsService: ManagecheckitemsService, private sharedService: SharedService,
    private alertService: AlertService, private loadingService: LoadingService, private modalService: BsModalService) {
    translate.addLangs(["es", "en"]);
    translate.setDefaultLang("en");
    let browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');

    this.columnDefs = [
      {
        headerName: '',
        field:'id',
        hide: true,
        suppressColumnsToolPanel: true
      },
      {
        headerName: this.translate.instant('MANAGE_CHECKS_SEARCH_VIEW.DEPENDENCY'),
        field:'dependencies',
        minWidth:70,
        maxWidth:70,
        cellRendererFramework: CheckCellRendererComponent
      },
      {
        headerName: this.translate.instant('MANAGE_CHECKS_SEARCH_VIEW.CHECK_CATEGORY'),
        field:'checkCategory',
        cellClass: "cell-wrap-text",
        minWidth:200
      },
      {
        headerName: this.translate.instant('MANAGE_CHECKS_SEARCH_VIEW.CHECK_DESCRIPTION'),
        field:'checkDescription',
        cellClass: "cell-wrap-text",
        minWidth:300,
        cellRenderer: function (params: any) {
					var eDiv = document.createElement('div');
					eDiv.innerHTML = params.data.checkDescription;
					return eDiv;
				}
      },
      {
        headerName: this.translate.instant('MANAGE_CHECKS_SEARCH_VIEW.TYPE_OF_CHECK'),
        field:'typeOfCheck',
        cellClass: "cell-wrap-text",
        minWidth:200
      },
      {
        headerName: this.translate.instant('MANAGE_CHECKS_SEARCH_VIEW.EDIT'),
        field:'edit',
        cellStyle: { 'text-align': 'center' },
        minWidth:80,
        maxWidth:80,
        cellRendererFramework: CheckCellRendererComponent
      },
      {
        headerName: this.translate.instant('MANAGE_CHECKS_SEARCH_VIEW.STATUS'),
        field:'status',
        cellStyle: { 'text-align': 'center' },
        minWidth:80,
        maxWidth:80,
        cellRendererFramework: CheckCellRendererComponent
      },
      {
        headerName: this.translate.instant('MANAGE_CHECKS_SEARCH_VIEW.DELETE'),
        field:'delete',
        cellStyle: { 'text-align': 'center' },
        minWidth:80,
        maxWidth:80,
        cellRendererFramework: CheckCellRendererComponent
      }
    ];

    this.getRowNodeId = function (data) {
      return data.id;
    };

    this.gridOptions = {
      rowData: this.rowData,
      columnDefs: this.columnDefs,
      defaultColDef: {
        resizable: true,
        sortable: true
      },
      accentedSort: true,
      rowSelection: "single",
      rowMultiSelectWithClick: false,
      animateRows: true,
      rowBuffer: 20,
      context: {
        componentParent: this
      },
      paginationPageSize: 10
    };

    this.overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Loading data...</span>';
    this.overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">No rows to show</span>';
   }

  ngOnInit(): void {
    this.checkItemsService.loadTableDataResponse().subscribe((option: any) => this.reloadData());
  }

  onGridReady(params: any){
    const allColumnIds = [];
    this.columnDefs.forEach((columnDef) => {
      allColumnIds.push(<ColDef>columnDef);
    });
    this.gridColumnApi = params.columnApi;
    this.gridApi = params.api;
    this.checkItemsService.setApiGridData(this.gridApi);
    params.api.sizeColumnsToFit();
    // window.addEventListener("resize", function () {
    //   setTimeout(function () {
    //     params.api.sizeColumnsToFit();
    //   });
    // });
    window.addEventListener("resize", this.onWindowResize);
  }
  private onWindowResize = () => {
   
		setTimeout(() => {
			if (this.gridApi) {
				this.gridApi.sizeColumnsToFit();
			}
		}); 
	};
  newCheckItemButtonClick(){
    this.sharedService.setFileId(-1);
    this.bsModalRef = this.modalService.show(AddEditCheckComponent, { class: 'modal-md', backdrop: 'static', keyboard: false })
  }

  /**
   * Search checks by category, description and hasText
   * @param data 
   */
  searchFunc(data: any) {
    this.searchData = data;
    this.checkCategory = "";
    this.checkDescription = "";
    this.hasText = "";
    this.gridApi.showLoadingOverlay();

    //get check category
    var chkCategory = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_CHK_CATEGORY];
    if(chkCategory != undefined){
      this.checkCategory = chkCategory
    }

    //get check description
    var chkDescription = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_CHK_DESC];
    if(chkDescription != undefined){
      this.checkDescription = chkDescription
    }

    //get has the word
    var hasWord = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_HAS_THE_WORDS];
    if(hasWord != undefined){
      this.hasText = hasWord;
    }

    if(this.checkCategory != "" || this.checkDescription != "" || this.hasText != ""){
      this.loadingService.showLoading(true, null, "Searching", null);
      this.checkItemsService.searchChecks(this.checkCategory, this.checkDescription, "", null, this.hasText).subscribe(
        data => {
          this.loadData(data.payload)
        },
        error => {
          this.loadingService.hideLoading();
          this.alertService.clear()
          this.alertService.error(error.statusDescription)
        });
    } else {
      //search bar has empty values
      this.gridApi.hideOverlay()
      this.loadData(null);
    }
  }

  /**
   * Load data to the checks grid
   * @param payload 
   */
  loadData(payload: any){
    var rowData = [];
    this.loadingService.hideLoading();
    if(payload != null){
      for(let i = 0; i < payload.length; i++){
        rowData.push({
          referenceCount: payload[i]["referenceCount"],
          dependencies: "dependencies:",
          id: payload[i]["checkId"],
          checkCategory: payload[i]["checkCategoryName"],
          checkDescription: payload[i]["checkDescription"],
          typeOfCheck: payload[i]["checkType"],
          status: "status:" + payload[i]["status"],
          edit: "edit:",
          delete: "delete:" + payload[i]["status"],
        });
      }
    }
    this.rowData = rowData;
    this.gridApi.paginationProxy.currentPage = 0;
    this.gridOptions.api.sizeColumnsToFit();
    this.checkItemsService.setRowData(this.rowData);
  }

  ngAfterViewInit(): void {
    this.subsVar = this.sharedService.editCheckResponse().subscribe((option: any) => this.openAddEditCheckModal());
    this.checkItemsService.setCallback(this);
  }

  ngOnDestroy(): void {
    if (this.subsVar) {
      this.subsVar.unsubscribe()
    }
    window.removeEventListener("resize", this.onWindowResize);
		this.gridApi.destroy();
		this.gridApi = null;
  }

  private openAddEditCheckModal() {
    this.bsModalRef = this.modalService.show(AddEditCheckComponent, { class: 'modal-xl', backdrop: 'static', keyboard: false })
  }

  reloadData(){
    this.fireEvent("mainsearchinput", "click");
  }

  private fireEvent( ElementId, EventName ) {
    if( document.getElementById(ElementId) != null ) {
      const ke = new KeyboardEvent("keyup", {
        bubbles: true, cancelable: true, key:"Enter"
      });
      document.getElementById( ElementId ).dispatchEvent( ke );
    }
  }

  onSuccess(data: WsResponse, type: WsType){

  }

  onFail(data: WsResponse, type: WsType){

  }
}
