import { Component, OnInit, AfterContentInit } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { CapabilityService } from 'src/app/util/capability.service';
import { SharedService } from 'src/app/util/shared.service';

@Component({
	selector: 'app-om-sidebar',
	templateUrl: './om-sidebar.component.html',
	styleUrls: ['./om-sidebar.component.scss'],
	outputs: ['selectSideBarItem']
})
export class OmSidebarComponent implements OnInit, AfterContentInit {

	public selectedMenuItem: string = 'opmanagement'
	selectSideBarItem = new EventEmitter<any>()

	//capabilities
	public manageProjectTemplates: boolean = false;
	public enableViewOutputList: boolean = false;
	public enableViewOutputQueue: boolean = false;
	public enableViewOutputConfig: boolean = false;
	public enableViewOutputTemplate: boolean = false;
	public enableCreateOutputTemplate: boolean = false;

	constructor(private capabilityService: CapabilityService, private sharedService: SharedService) { 
		this.manageProjectTemplates = this.capabilityService.isCapabilityAssigned("manageProjectTemplates");
		this.enableViewOutputList = this.capabilityService.isCapabilityAssigned("viewOutputList");
		this.enableViewOutputQueue = this.capabilityService.isCapabilityAssigned("viewOutputQueue");
		this.enableViewOutputConfig = this.capabilityService.isCapabilityAssigned("viewOutputConfig");
		this.enableViewOutputTemplate = this.capabilityService.isCapabilityAssigned("viewOutputTemplates");
		this.enableCreateOutputTemplate = this.capabilityService.isCapabilityAssigned("createOutputTemplates");

	}

	ngAfterContentInit(): void {
		//Called after ngOnInit when the component's or directive's content has been initialized.
		//Add 'implements AfterContentInit' to the class.
		this.sharedService.changeOMComponentsRespond().subscribe(
			(option: any) => this.sideItemClick(option.componentName)
		)
	}

	ngOnInit() {
	}

	public sideItemClick(selectedItem: string) {
		this.selectedMenuItem = selectedItem
		this.selectSideBarItem.emit(
			{
				selectedItem: selectedItem
			}
		) 
	}

}
