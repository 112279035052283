import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CommonUtil } from 'src/app/util/common/common-util';
import { UserVariable } from 'src/app/util/common/user-variable';
import { ServiceUrls } from 'src/app/util/service-urls';
import { WsCallback } from 'src/app/util/ws-callback.interface';
import { WsResponse } from 'src/app/util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';
import { GridApi } from '@ag-grid-community/core';

@Injectable({
  providedIn: 'root'
})
export class ManagecheckitemsService {
  token: string;
  private callback: WsCallback
  private apiGridData: any = null;
  private rowData: any = null;
  loadTableData = new EventEmitter<any>();

  constructor(private http: HttpClient, private commonUtil: CommonUtil) { 
  }

  public setCallback(callBack: WsCallback) {
    this.callback = callBack;
  }

  public loadTableDataRequest(){
    this.loadTableData.emit()
  }
  public loadTableDataResponse(){
    return this.loadTableData;
  }

  public setApiGridData(apiGridData: any) {
    this.apiGridData = apiGridData;
  }

  public getApiGridData() {
    return this.apiGridData;
  }
  

  public disableEnableFileTableData(fileId: any, title: String, status: String) {
    if (this.apiGridData != null && this.apiGridData != undefined && fileId != null) {
      var rowNode = this.apiGridData.getRowNode(fileId);
      if (rowNode != null && rowNode != undefined) {
        if (status == "Inactive") {
          rowNode.setDataValue("status", "status:Active");
          rowNode.setDataValue("delete", "delete:Active");
        }
        else {
          rowNode.setDataValue("status", "status:Inactive");
          rowNode.setDataValue("delete", "delete:Inactive");
        }
      }
    }
  }

  searchChecks(chkCategory: String, chkDesc: String, chkType: String, chkAssign: String, hasText: string) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
        return;
    }

    let url = this.getCHKSearchURL(token);
    return this.http.post(url, JSON.stringify(new Request(chkCategory, chkDesc, chkType, chkAssign, hasText))).
        pipe(
            map(response => {
                var modified = JSON.parse(JSON.stringify(response));
                return new WsResponse(
                    modified.status.description,
                    modified.status.name,
                    modified.status.code,
                    modified.payload);
            }),
            catchError(error => {
                var modified = JSON.parse(JSON.stringify(error));
                var res = new WsResponse(
                    modified.error.status.description,
                    modified.error.status.name,
                    modified.error.status.code,
                    modified.error.payload);
                return throwError(res)
            })
        );
    }

    addCheck(checkCategory: string, checkType: string, checkDescription: string, wsCallBack: WsCallback){
      let token = UserVariable.getUserToken();
      if(!this.commonUtil.validateToken(token)){
        return;
      }

      this.http.post(ServiceUrls.addCheck(token), new HttpParams().set("checkCategory", checkCategory)
      .set("checkType", checkType).set("checkDescription", checkDescription)).subscribe(
        response =>{
          var modified = JSON.parse(JSON.stringify(response));
          var res = new WsResponse(
            modified.status.description,
            modified.status.code,
            modified.status.name,
            modified.payload);
          wsCallBack.onSuccess(res, WsType.ADD_CHECK);
      }, error => {
        if (error.status != '') {
          var val = (error as HttpErrorResponse).error;
          var modified = JSON.parse(JSON.stringify(val));
          var res = new WsResponse(
            modified.status.description,
            modified.status.code,
            modified.status.name,
            modified.payload);
          wsCallBack.onFail(res, WsType.ADD_CHECK);
        } else {
          //browser related issues
          var res = new WsResponse("Unknown error happened");
          wsCallBack.onFail(res, WsType.ADD_CHECK);
        }
      });
    }

    getCheckByCheckId(checkId: string){
      let token = UserVariable.getUserToken();
      if(!this.commonUtil.validateToken(token)){
        return;
      }

      return this.http.get(ServiceUrls.getCheckById(token, checkId)).pipe(
        map(response => {
          var modified = JSON.parse(JSON.stringify(response));
          return new WsResponse(
              modified.status.description,
              modified.status.name,
              modified.status.code,
              modified.payload);
        }), 
        catchError(error => {
          var modified = JSON.parse(JSON.stringify(error));
                var res = new WsResponse(
                    modified.error.status.description,
                    modified.error.status.name,
                    modified.error.status.code,
                    modified.error.payload);
          return throwError(res)
        })
      );
    }

     updateCheck(data: string, wsCallBack: WsCallback){
      let token = UserVariable.getUserToken();
      if(!this.commonUtil.validateToken(token)){
        return;
      }

      this.http.post(ServiceUrls.updateCheck(token), data).subscribe(
        response => {
          var modified = JSON.parse(JSON.stringify(response));
          var res = new WsResponse(
            modified.status.description,
            modified.status.code,
            modified.status.name,
            modified.payload);
          wsCallBack.onSuccess(res, WsType.UPDATE_CHECK);
      }, error => {
        if (error.status != '') {
          var val = (error as HttpErrorResponse).error;
          var modified = JSON.parse(JSON.stringify(val));
          var res = new WsResponse(
            modified.status.description,
            modified.status.code,
            modified.status.name,
            modified.payload);
          wsCallBack.onFail(res, WsType.UPDATE_CHECK);
        } else {
          //browser related issues
          var res = new WsResponse("Unknown error happened");
          wsCallBack.onFail(res, WsType.UPDATE_CHECK);
        }
      });
    }

    public getCHKSearchURL(token: String) {
      let url = ServiceUrls.CHECKS_SEARCH_VIEW + '?token=' + token;
      return url;
    }

    public setRowData(rowData: any) {
      this.rowData = rowData;
    }
  
    public getRowData() {
      return this.rowData;
    }

    public deleteFileTableData(csd: any) {
      if (this.apiGridData != null && this.apiGridData != undefined && csd != null) {
        var selectedData = this.apiGridData.getSelectedRows();
        if (selectedData != null && selectedData != undefined) {
          this.apiGridData.applyTransaction({ remove: selectedData });
          let newRowData: any = [];
          this.apiGridData.forEachNode(function (rowNode, index) {
            newRowData.push(rowNode.data);
          });
          this.setRowData(newRowData);
        }
      }
    }

    getCSInstanceCount(csId: string, wsCallBack: WsCallback) {
      let token = UserVariable.getUserToken();
      if (!this.commonUtil.validateToken(token)) {
        return;
      }
      this.http.get(ServiceUrls.getCSInstanceCountByCheckId(token, csId)).subscribe(
        data => {
          var modified = JSON.parse(JSON.stringify(data));
          var res = new WsResponse(
            modified.status.description,
            modified.status.code,
            modified.status.name,
            modified.payload);
          wsCallBack.onSuccess(res, WsType.GET_CSINSTANCE_COUNT_BY_CHECK);
        },
        error => {
          if (error.status != '') {
            var val = (error as HttpErrorResponse).error;
            var modified = JSON.parse(JSON.stringify(val));
            var res = new WsResponse(
              modified.status.description,
              modified.status.code,
              modified.status.name,
              modified.payload);
            wsCallBack.onFail(res, WsType.GET_CSINSTANCE_COUNT_BY_CHECK);
          } else {
            //browser related issues
            var res = new WsResponse("Unknown error happened");
            wsCallBack.onFail(res, WsType.GET_CSINSTANCE_COUNT_BY_CHECK);
          }
        }
      );
    }
  
}



class Request {
  constructor(private checkCategory: String, private checkDescription: String, private checkType: String, private checkAssign: String, private hasText: String) { }
}
