import { Component, OnInit, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CapabilityService } from 'src/app/util/capability.service';

@Component({
	selector: 'app-reports-sidebar',
	templateUrl: './reports-sidebar.component.html',
	styleUrls: ['./reports-sidebar.component.css'],
	outputs: ['selectSideBarItem']
})
export class ReportsSidebarComponent implements OnInit {

	public selectedMenuItem: string = 'tag'
	selectSideBarItem = new EventEmitter<any>()
	
	showManageAdhocReports: boolean = false;

	constructor(private translate: TranslateService, private capabilityService: CapabilityService) { 
		translate.addLangs(["es", "en"]);
        translate.setDefaultLang("en");
        let browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
	}

	ngOnInit() {
		this.capabilityCheck();
	}

	public sideItemClick(selectedItem: string) {
		this.selectedMenuItem = selectedItem
		this.selectSideBarItem.emit(
			{
				selectedItem: selectedItem
			}
		) 
	}

	/**
	 *
	 * Check capabilities to show/hide sidebar items
	 */
	 capabilityCheck(){
		let viewManageAdhocReports: string = "manageAdhocReports";
		this.showManageAdhocReports = this.capabilityService.isCapabilityAssigned(viewManageAdhocReports);
	 }

}
