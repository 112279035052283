import { ColDef, GridOptions } from '@ag-grid-enterprise/all-modules';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AlertService } from 'src/app/util/alert/alert.service';
import { UserVariable } from 'src/app/util/common/user-variable';
import { LoadingService } from 'src/app/util/loading/loading.service';
import { ManagePunchlistImageService } from './manage-punchlist-image.service';

@Component({
  selector: 'app-manage-punchlist-images',
  templateUrl: './manage-punchlist-images.component.html',
  styleUrls: ['./manage-punchlist-images.component.css']
})
export class ManagePunchlistImagesComponent implements OnInit {

  public projectList: Array<any> = [];
  selectedProjectId: any = "";
  private gridApi;
  public getRowNodeId;
  private gridColumnApi
  public gridOptions: GridOptions;
  public columnDefs: any;
  public defaultColDef: any;
  rowData: any = [];
  public overlayNoRowsTemplate: any;
  public overlayLoadingTemplate: any;
  private checkSheetName: string = "";
  private checkSheetCode: string = "";
  private plDescription: string = "";
  public showGrid = false;
  public showCopyBtn = false;
  
  showResultsText = false;
  copiedItems = 0;
  totalItems = 0;
  public copyModalRef: BsModalRef;
  @ViewChild("copyConfirmModal") copyConfirmModal: TemplateRef<any>;

  constructor(private managepunchlistService: ManagePunchlistImageService, private translate: TranslateService,
      private loadingService: LoadingService, private alertServie: AlertService, private modalService: BsModalService) {
    translate.addLangs(["es", "en"]);
    translate.setDefaultLang("en");
    let browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');

    this.translate.get('PL_IMAGES.CHECKSHEET_NAME').subscribe((res: string) => {
      this.checkSheetName = res;
      this.translate.get('PL_IMAGES.CHECKSHEET_CODE').subscribe((res: string) => {
        this.checkSheetCode = res;
        this.translate.get('PL_IMAGES.PL_DESCRIPTION').subscribe((res: string) => {
          this.plDescription = res;
        });
      });
    });

    this.columnDefs = [
      {
        headerName: this.checkSheetName,
        field: "checkSheetName",
        checkboxSelection: false,
        sortable: true
      },
      {
        headerName: this.checkSheetCode,
        field: "checkSheetCode",
        checkboxSelection: false,
        suppressColumnsToolPanel: true
      },
      {
        headerName: this.plDescription,
        field: "plDescription",
        checkboxSelection: false,
        suppressColumnsToolPanel: true
      },
      {
        headerName: this.translate.instant('PL_IMAGES.STATUS'),
        field: "status",
        checkboxSelection: false,
        suppressColumnsToolPanel: true
      }
    ];

    this.getRowNodeId = function (data) {
      return data.id;
    };

    this.gridOptions = {
      rowData: this.rowData,
      columnDefs: this.columnDefs,
      rowMultiSelectWithClick: false,
      getRowStyle  : (params)=>{
        console.log(params);
        if (params.data.status == "Success") {
          return { background: '#D4EDDA' };
        }else if(params.data.status == "Failed"){
          return { background: '#FCCDC4' };
        }else{
          return { background: '#ffffff' };
        }
      }
    };
    this.defaultColDef = {
      resizable: true,
      sortable: true,
    };
    this.overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Loading data...</span>';
    this.overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">No data to show</span>';

  }

  ngOnInit(): void {

    this.managepunchlistService.getProjectList(UserVariable.userId).
      subscribe(data => {
        this.loadProjects(data.payload);
      },
        error => {

        });
  }

  //load all projects for active clients
  loadProjects(data: any) {
    if (data != null) {
      this.projectList = [];
      data.forEach(element => {
        this.projectList.push({
          'id': element.id,
          'text': element.projectName
        });
      });
    }
  }

  projectSelected(event: any) {
    this.selectedProjectId = event["id"];
    this.showResultsText = false;
    this.getAllPunchlistsWithPhotosToUploadToS3();
  }

  removedProject(event: any) {
    this.showGrid = false;
    this.showCopyBtn = false;
    this.showResultsText = false;
  }


  //get all punchlists having images that are not copied to s3 bucket
  getAllPunchlistsWithPhotosToUploadToS3() {
    this.managepunchlistService.getAllPunchlistsWithPhotosToUploadToS3(this.selectedProjectId)
      .subscribe(data => {

        this.loadData(data.payload);
      },
        error => {

        });
  }

  loadData(data: any) {
    this.rowData = [];
    //show grid
    this.showGrid = true;

    if (data.length > 0) {
      //show btn
      this.showCopyBtn = true;
    } else {
      //hide btn
      this.showCopyBtn = false;
    }

    for (let i = 0; i < data.length; i++) {

      this.rowData.push({
        id: data[i]["id"],
        checkSheetName: data[i]["checkSheetName"],
        checkSheetCode: data[i]["checkSheetCode"],
        plDescription: data[i]["punchlistDescription"],
        status: data[i]["status"]
      })
    }
    console.log("load" + this.rowData.length)
  }

  onGridReady(params) {
    const allColumnIds = [];
    this.columnDefs.forEach((columnDef) => {
      allColumnIds.push(<ColDef>columnDef);
    });
    this.gridColumnApi = params.columnApi;
    this.gridApi = params.api;
    params.api.sizeColumnsToFit();
    // window.addEventListener("resize", function () {
    //   setTimeout(function () {
    //     params.api.sizeColumnsToFit();
    //   });
    // });
    window.addEventListener("resize", this.onWindowResize);
  }

  private onWindowResize = () => {
   
		setTimeout(() => {
			if (this.gridApi) {
				this.gridApi.sizeColumnsToFit();
			}
		}); 
	};

     ngOnDestroy(){
        window.removeEventListener("resize", this.onWindowResize);
        this.gridApi.destroy();
        this.gridApi = null;
     }

  showCopyConfirmation() {
    this.copyModalRef = this.modalService.show(this.copyConfirmModal)
  }

  copyToS3(){
    //hide confirmation popup
    this.copyModalRef.hide()
    this.loadingService.showLoading(true,null,"Copying..",null);
    this.showResultsText = false;
    this.managepunchlistService.copyImagesToS3(this.selectedProjectId)
      .subscribe(data => {
        this.loadData(data.payload);

        this.totalItems = data.payload.length
        this.copiedItems = data.payload.filter(item => item.status == "Success").length
        this.showResultsText = true;
        this.loadingService.hideLoading();

      },
      error => {
        this.loadingService.hideLoading();
        this.alertServie.clear()
        this.alertServie.error(error.statusDescription)
      });
  }
}
