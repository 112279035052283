<div class="flex-row" id="proc_editor" style="overflow-y: auto; overflow-x: hidden" [style.height.px]="divHeight">
  <div class="container-fluid my-2">
    <!-- breadcrumb -->

    <nav aria-label="breadcrumb my-1">
        <ol class="breadcrumb my-0 px-2 py-1">
            <li class="breadcrumb-item active" *ngFor="let breadCrumbListItem of breadCrumbList ; let i = index"   aria-current="page" (click)="clickBreadCrumb(breadCrumbListItem,i)">
               {{ breadCrumbListItem.title}}
               <!-- <div [innerHTML]="breadCrumbListItem.title"></div> -->
            </li>
            <li class="currentItem breadcrumb-item">{{topicContentDescriptionShowInDetails}}</li>
        </ol>
      </nav>

  <!-- topic content details -->
  <div class="card my-2">
    <div class="card-header py-1">
      <p class="col-sm-7 my-1 float-left h6  px-0">
        {{ 'ICE_TOPIC_CONTENT.TOPIC_CONTENT_DETAILS' | translate }}
        <span *ngIf="!isShowIceTopicContentDetails && topicContentDescriptionShowInDetails != null" class="ml-2" STYLE="font-size: 10pt" > | {{topicContentDescriptionShowInDetails}}</span>
      </p>
      <button type="button" class="m-0 close float-right" (click)="showIceTopicContentDetails(isShowIceTopicContentDetails)">
        <i class="far"
          [ngClass]="{'fa-angle-up': isShowIceTopicContentDetails, 'fa-angle-down': !isShowIceTopicContentDetails}"></i>
      </button>
      <button class="btn btn-sm btn-dark float-right mx-2" type="button" 
					(click)="topicContentDetailSave()" [disabled]="isViewOnly || !isShowIceTopicContentDetails" tooltip ="Save Bullet" >
					<i class="fal fa-save"></i>
      </button>
      <button  [disabled]="isViewOnly || topicContentEnabled" class="btn btn-sm btn-dark float-right mx-2" type="button" 
      (click)="editTopicContent()" tooltip ="Edit Bullet" >
        <i class="far fa-edit"></i>
      </button>
      <button  [disabled]="isViewOnly" class="btn btn-sm btn-dark float-right mx-2" type="button"
				(click)="addAssociateCourse(associateCorseModel)" tooltip ="Link Course">
				<i class="fas fa-link"></i> <!--<i class="fas fa-link"></i>-->
      </button>
      <button class="btn btn-sm btn-dark float-right mx-2" type="button" 
      (click)="openCancelConfirmationModel(closeConfirmModel)" tooltip ="Cancel" >
      <i class="fas fa-times"></i>
    </button>
    </div>
    <div *ngIf="isShowIceTopicContentDetails" class="card-body">
      <form ngNativeValidate>
        <!--<div class="form-group">
          <label for="topicContentTitle">
            {{ 'ICE_TOPIC_CONTENT.TOPIC_CONTENT.TITLE_LABEL' | translate }}
          </label>
          <div class="badge-required mx-2"></div>
          <input type="text"  [(ngModel)]="iceTopicContent.topicContentName" name="title"  class="form-control form-control-sm" id="topicContentTitle"
          id="iceTopicContentTitle" placeholder="{{ 'ICE_TOPIC_CONTENT.TOPIC_CONTENT.TITLE_PLACEHOLDER' | translate }}" required required [disabled]="isViewOnly">
        </div>-->
        <div class="form-group">
          <label for="topicContentDescription">
            {{ 'ICE_TOPIC_CONTENT.TOPIC_CONTENT.DESCRIPTION_LABEL' | translate }}
          </label>
          <div class="badge-required mx-2"></div>
          <div class="border rounded" (click)="descriptionClick()">
            <div rows="2" contenteditable="false"
              class="toggle-edit{{tabId}}{{userId}} ckeditor_topic_content_description{{tabId}} topic_content_ck_editor desc_ck_editor{{tabId}} tceditor" required id="topicContentDescription" name="topicContentDescription">
            </div>
          </div>
          <!-- <textarea [(ngModel)]="iceTopicContent.description" name="courseDescription"
          class="form-control form-control-sm" id="topicContentDescription" rows="2"
            placeholder="{{ 'ICE_TOPIC_CONTENT.TOPIC_CONTENT.DESCRIPTION_PLACEHOLDER' | translate }}" required required [disabled]="isViewOnly" > </textarea> -->
        </div>
        <div *ngIf="linkedCourse != null" class="form-group">
          <label for="associateCourse">
            {{ 'ICE_TOPIC_CONTENT.TOPIC_CONTENT.ASSOCIATE_COURSE_ID' | translate }}
          </label>
          <div class="input-group">
              <input class="form-control form-control-sm" id="associateCourse" name="courseDescription" [(ngModel)]="linkedCourse" rows="2" 
              required required [disabled]="true">
              <span class="input-group-btn pt-1">
                  <button  [disabled]="isViewOnly" (click)="addAssociateCourse(associateCorseModel)" tooltip ="Edit" class="btn btn-sm btn-dark float-right mx-2" 
                  type="submit">
                      <i class="far fa-edit"></i>
                  </button>
              </span>
          </div>
        </div>
        <div class="form-row"> 
          <div class="col-sm-12">
            <label for="topicContentUpload">
               {{ 'ICE_TOPIC_CONTENT.TOPIC_CONTENT.UPLOAD' | translate }}
            </label>
            <!-- <div class="badge-required mx-2"></div> -->
            <div class="form-group">
                <div class="radio-item">
                    <input type="radio" id="topicContentImage_{{tabId}}" name="iceTopicContentType_{{tabId}}" value="Image"
                    (click)="removeTopicContent(); addTopicContentImage();" [checked]="iceTopicContent.topicContentImage != null ? true: false">
                    <label for="topicContentImage_{{tabId}}">
                        {{ 'ICE_TOPIC_CONTENT.TOPIC_CONTENT.IMAGE_LABEL' | translate }}
                    </label>
                </div>
                <div class="radio-item">
                    <input type="radio" id="topicContentVideo_{{tabId}}" name="iceTopicContentType_{{tabId}}" value="Video"
                    (click)=" removeTopicContent(); addTopicContentVideo();" [checked]="iceTopicContent.topicContentVideo != null ? true: false">
                    <label for="topicContentVideo_{{tabId}}">
                        {{ 'ICE_TOPIC_CONTENT.TOPIC_CONTENT.VIDEO_LABEL' | translate }}
                    </label>
                </div>  
                <div class="radio-item">
                    <input type="radio" id="topicContentThreeDModel_{{tabId}}" name="iceTopicContentType_{{tabId}}" value="ThreeDModel"
                    (click)=" removeTopicContent(); addTopicContentThreeDModel();" [checked]="iceTopicContent.topicContentThreeDModel != null ? true: false">
                    <label for="topicContentThreeDModel_{{tabId}}">
                        {{ 'ICE_TOPIC_CONTENT.TOPIC_CONTENT.THREED_MODEL_LABEL' | translate }}
                    </label>
                </div>
            </div>
          <div *ngIf="iceTopicContent.topicContentImage != null" class="card" style="width: 8rem;">
              <div class="card-img-overlay py-0 pl-0 pr-1" (click)="showLargeImage(largeImage, iceTopicContent.topicContentImage.id)">
                  <button type="button" class="close float-right" aria-label="Close"
                    (click)="$event.stopPropagation();removeTopicContent()" [disabled]="isViewOnly">
                    <span aria-hidden="true">&times;</span>
                  </button>
              </div>
            <img class="img-fluid"  style="width: 8rem;" src="data:image/jpeg;base64,{{iceTopicContent.topicContentImage.thumbnailImage}}"
              alt="{{iceTopicContent.topicContentImage.title}}">
          </div>         
          <div *ngIf="iceTopicContent.topicContentVideo != null" class="card" style="width: 40rem; height: 30rem">
              <button type="button" class="close float-right" aria-label="Close"
                (click)="$event.stopPropagation();removeTopicContent()" [disabled]="isViewOnly">
                <span aria-hidden="true">&times;</span>
              </button>
              <div class="modal-header">
                <h6 class="modal-title pull-left">{{iceTopicContent.topicContentVideo.title}}</h6>
              </div>
              <video class="img-fluid" style="width: 40rem; height: 26rem" controls [poster]="iceTopicContent.topicContentVideo.posterframeURL"> 
                <source [src]="iceTopicContent.topicContentVideo.selectedURL" [alt]="iceTopicContent.topicContentVideo.title" type="video/mp4">
              </video>
          </div>
          <div *ngIf="iceTopicContent.topicContentThreeDModel != null" class="card" style="width: 8rem;">
              <div class="card-img-overlay py-0 pl-0 pr-1; form-group" (click)="showLargeThreeDModel(largeThreeDModel, iceTopicContent.topicContentThreeDModel)">
                  <button type="button" class="close float-right" aria-label="Close"
                    (click)="$event.stopPropagation();removeTopicContent()" [disabled]="isViewOnly">
                    <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <div class="form-group">
                <img class="img-fluid"  style="width: 8rem;" [src]="iceTopicContent.topicContentThreeDModel.selectedURL"
                alt="{{iceTopicContent.topicContentThreeDModel.title}}">
              </div>
          </div>
        </div>
      </div>
        <div *ngIf="iceTopicContent.topicContentThreeDModel != null" class="form-row">
            <div class="container-fluid px-0">
              <div class="row-px-3">
                <div class="col-sm-6-px-0">
                 <button type="button" [disabled]="!hasSavedModelInfo" class="btn btn-primary btn-sm float-right reusable_btn my-1 mx-1" (click)="importSavedModelData()">
                  {{ 'ICE_TOPIC_CONTENT.TOPIC_CONTENT.IMPORT_SAVED_MODEL_DATA' | translate }}
                 </button>
                 <button type="button" [disabled]="!hasSelectedModelView" class="btn btn-primary btn-sm float-right reusable_btn my-1 mx-1" (click)="copyModelInfo()">
                  {{ 'ICE_TOPIC_CONTENT.TOPIC_CONTENT.COPY_MODEL_INFO' | translate }}
                 </button>
                </div>
              </div>
            </div>
            <div class="form-group" style="width: 35rem; padding-top: 15px">
              <label for="topicContentDescription">
                {{ 'ICE_TOPIC_CONTENT.TOPIC_CONTENT.MODEL_VIEW_LABEL' | translate }}
              </label>
              <div class="badge-required mx-2"></div>
              <ng-select required [active]="selectedViewType" [allowClear]="false" [placeholder]="placeholder" name="modelView" id="modelView" 
               [items]="viewTypes" style="width: auto;padding: 65px;" (selected)="selectedView($event)">
              </ng-select>
            </div>
            <ag-grid-angular #agGrid2 style="width: 100%; height: 375px;" class="ag-theme-balham" [rowData]="rowData2" [columnDefs]="columnDefs2"
            [gridOptions]="gridOptions" [treeData]="true" [animateRows]="true" [modules]="modules" [sideBar]="sideBar"
            [getDataPath]="getDataPath" [autoGroupColumnDef]="autoGroupColumnDef"
            [overlayLoadingTemplate]="overlayLoadingTemplate" [overlayNoRowsTemplate]="overlayNoRowsTemplate" [rowSelection]="rowSelection"
            [getRowNodeId]="getRowNodeId" (gridReady)="onGridReady2($event)" rowSelection="multiple"   [enableFilter]="true">
            </ag-grid-angular>
        
        </div>
         
        <!--<hr class="mt-3 mb-2" />
        <div class="form-group">
          <button class="btn btn-sm btn-warning float-right" (click)="topicContentDetailSave()" [disabled]="isViewOnly" >Save</button>
        </div>-->
      </form>
    </div>
   <!--<div *ngIf="isShowIceTopicContentDetails" class="card-footer py-1">
      <button class="btn btn-sm btn-warning float-right" (click)="topicContentDetailSave()">Save</button>
    </div> -->
  </div>
  </div>
</div>


<!-- ICE Topic Content list -->
<!--     <div class="card my-3">
    <div class="card-header py-1">
      <p class="col-sm-10 my-1 float-left h6 px-0">
        <span class="badge badge-warning mr-2">{{iceTopicContentList.length}}</span>
        {{ 'ICE_TOPIC_CONTENT.ICE_TOPIC_CONTENTS' | translate }}
      </p>
      <button id="showMoreIceTopicContent" type="button" class="my-1 mx-0 close float-right"
        (click)="isShowIceTopicContent = !isShowIceTopicContent">
        <i class="far" [ngClass]="{'fa-angle-up': isShowIceTopicContent, 'fa-angle-down': !isShowIceTopicContent}"></i>
      </button>
      <button id="addNewIceTopicContent" type="button" class="btn btn-sm btn-dark mx-4 float-right"
        (click)="addNewIceTopicContent()">
        <i class="far fa-plus"></i>
      </button>
    </div>
    <div *ngIf="isShowIceTopicContent" class="card-body p-2">
      <div *ngFor="let iceTopicContent of iceTopicContentList | orderBy : 'orderNum'; let t = index"
        class="card card-secondary my-2">
        <div class="card-body container-fluid p-1">
          <div class="">
            <div class="col-sm-9 float-left pl-2 pr-0">
              <input type="text" class="form-control form-control-sm" id="iceTopicContentTitle"
                [value]="iceTopicContent.topicContentName"
                placeholder="{{ 'ICE_TOPIC_CONTENT.TOPIC_CONTENT.TITLE_PLACEHOLDER' | translate }}">
            </div>
            <div class="col-sm-3 float-right px-0">
              <button id="btnGotoTopic" type="button" class="my-1 mx-3 close float-right"
                (click)="showInfoIceTopicContent(iceTopicContent)">
                <i class="far fa-angle-right"></i>
              </button>
              <button id="btnDeleteTopicContent" type="button" class="my-1 mx-2 close float-right"
                (click)="deleteIceTopicContent(iceTopic)">
                <i class="far fa-trash-alt"></i>
              </button>
              <button *ngIf="(iceTopicContentList.length > 1) && (t != 0)" id="btnMoveUpTopicContent" type="button"
                class="my-1 mx-2 close float-right" (click)="changePositionIceTopicContent(iceTopicContent, 'up')">
                <i class="far fa-arrow-up"></i>
              </button>
              <button *ngIf="(iceTopicContentList.length > 1) && (t != (iceTopicContentList.length - 1))"
                id="btnMoveDownTopicContent" type="button" class="my-1 mx-2 close float-right"
                (click)="changePositionIceTopicContent(iceTopicContent, 'down')">
                <i class="far fa-arrow-down"></i>
              </button>
              <button id="btnCopyTopicContent" type="button" class="my-1 mx-2 close float-right"
                (click)="copyOfIceTopicContent(iceTopicContent)"
                [ngClass]="{'clone': iceTopicContent.copyTopicContentId != '', 'not-clone': iceTopicContent.copyTopicContentId == ''}">
                <i class="far fa-clone"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <!-- ICE Topic Content Bullet list -->
<!--  <div class="card my-3">
  <div class="card-header py-1">
    <p class="col-sm-10 my-1 float-left h6 px-0">
      <span class="badge badge-warning mr-2">{{iceTopicContentBulletList.length}}</span>
      {{ 'ICE_TOPIC_CONTENT.ICE_BULLETS' | translate }}
    </p>
    <button id="showMoreIceTopicContentBullets" type="button" class="my-1 mx-0 close float-right"
      (click)="isShowIceTopicContentBullets = !isShowIceTopicContentBullets">
      <i class="far" [ngClass]="{'fa-angle-up': isShowIceTopicContentBullets, 'fa-angle-down': !isShowIceTopicContentBullets}"></i>
    </button>
    <button id="addNewIceTopicContentBullet" type="button" class="btn btn-sm btn-dark mx-4 float-right"
      (click)="addNewIceTopicContentBullet()">
      <i class="far fa-plus"></i>
    </button>
  </div>
  <div *ngIf="isShowIceTopicContentBullets" class="card-body p-2">
    <div *ngFor="let iceTopicContentBullet of iceTopicContentBulletList | orderBy : 'orderNum'; let tb = index"
      class="card card-secondary my-2">
      <div class="card-body container-fluid p-1">
        <div class="">
          <div class="col-sm-9 float-left pl-2 pr-0">
            <input type="text" class="form-control form-control-sm" id="iceTopicContentBulletTitle"
              [value]="iceTopicContentBullet.title"
              placeholder="{{ 'ICE_TOPIC_CONTENT_BULLET.TITLE_PLACEHOLDER' | translate }}">
          </div>
          <div class="col-sm-3 float-right px-0">
            <button id="btnGotoTopicContentBullet" type="button" class="my-1 mx-3 close float-right"
              (click)="showInfoIceTopicContentBullet(iceTopicContentBullet)">
              <i class="far fa-angle-right"></i>
            </button>
            <button id="btnDeleteTopicContentBullet" type="button" class="my-1 mx-2 close float-right"
              (click)="deleteIceTopicContentBullet(iceTopicContentBullet)">
              <i class="far fa-trash-alt"></i>
            </button>
            <button *ngIf="(iceTopicContentBulletList.length > 1) && (t != 0)" id="btnMoveUpTopicContent" type="button"
              class="my-1 mx-2 close float-right" (click)="changePositionIceTopicContentBullet(iceTopicContentBullet, 'up')">
              <i class="far fa-arrow-up"></i>
            </button>
            <button *ngIf="(iceTopicContentBulletList.length > 1) && (t != (iceTopicContentBulletList.length - 1))"
              id="btnMoveDownTopicContentBullet" type="button" class="my-1 mx-2 close float-right"
              (click)="changePositionIceTopicContentBullet(iceTopicContentBullet, 'down')">
              <i class="far fa-arrow-down"></i>
            </button>
            <button id="btnCopyTopicContentBullet" type="button" class="my-1 mx-2 close float-right"
              (click)="copyOfIceTopicContentBullet(iceTopicContentBullet)"
              [ngClass]="{'clone': iceTopicContentBullet.copyTopicContentBulletId != '', 'not-clone': iceTopicContentBullet.copyTopicContentBulletId == ''}">
              <i class="far fa-clone"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<!-- Learning Checks list 

   <div class="card my-3">
  <div class="card-header py-1">
    <p class="col-sm-5 my-1 float-left h6 px-0">
      <span class="badge badge-warning mr-2" >{{iceLearningCheckList.length}}</span>
      {{ 'ICE_TOPIC_CONTENT.LEARNING_CHECKS' | translate }}
    </p>
    <button id="showMoreLearningCheck" type="button" class="my-1 mx-0 close float-right"
      (click)="showICELearningCheck(isShowLearningChecks)" >
      <i class="far"
        [ngClass]="{'fa-angle-up': isShowLearningChecks, 'fa-angle-down': !isShowLearningChecks}"></i>
    </button>
    <button id="addNewLearningCheck" type="button" class="btn btn-sm btn-dark mx-2 float-right"
      (click)="addNewLearningCheck()" [disabled]="isViewOnly || !learningCheckEnabled" tooltip ="Add Learning Checks">
      <i class="far fa-plus"></i>
    </button>
  <button  class="btn btn-sm btn-dark float-right mx-2" type="button" 
    (click)="learningChecksSave(true)" [disabled]="isViewOnly || !learningCheckEnabled || !isShowLearningChecks"  tooltip ="Save Learning Checks">
    <i class="fal fa-save"></i>
   </button>
   <button class="btn btn-sm btn-dark float-right mx-2" type="button" 
     (click)="editLearningChecks()" [disabled]="isViewOnly || learningCheckEnabled"  tooltip ="Edit Learning Checks" >
     <i class="far fa-edit"></i>
   </button>
  </div>
  <div *ngIf="isShowLearningChecks" class="card-body p-2">
    <div *ngFor="let learningCheck of iceLearningCheckList | orderBy : 'orderNum'; let l = index"
      class="card card-secondary my-2">
      <div class="card-body container-fluid p-1">
        <div class="col-sm-12 px-0 float-left">
          <div class="col-sm-9 float-left pl-2 pr-0">
              <div class="border rounded">
                  <div rows="2" contenteditable="false" id="{{learningCheck.id}}"
                    class="toggle-edit{{tabId}}{{userId}} l_ck_editor{{learningCheck.sequence}}{{tabId}} leditor">
                  </div>
              </div> 
          
          </div>
          <div class="col-sm-3 float-right px-0">
            <button id="btnGotoLearningCheck" type="button" class="my-1 mx-3 close float-right"
              (click)="showInfoIceLearningCheck(learningCheck, 'null')">
              <i class="far fa-angle-right"></i>
            </button>
            <button id="btnDeleteLearningCheck" type="button" class="my-1 mx-2 close float-right"
            (click)="deleteIceLearningCheck(learningCheck, l, deleteLearningCheckModel)"
            [disabled]="isViewOnly || !learningCheckEnabled">
              <i class="far fa-trash-alt"></i>
            </button>
            <button *ngIf="(iceLearningCheckList.length > 1) && (l != 0)" id="btnMoveUpLearningCheck"
              type="button" class="my-1 mx-2 close float-right"
              (click)="changePositionLearningCheck(learningCheck, l, 'up')"
              [disabled]="isViewOnly || !isEnableReordering || !learningCheckEnabled">
              <i class="far fa-arrow-up"></i>
            </button>
            <button *ngIf="(iceLearningCheckList.length > 1) && (l != (iceLearningCheckList.length - 1)) && learningCheck.id!='0' && !(l + 1 >= iceLearningCheckList.length) && (iceLearningCheckList[l+1].id!='0')"
              id="btnMoveDownLearningCheck" type="button" class="my-1 mx-2 close float-right"
              (click)="changePositionLearningCheck(learningCheck, l, 'down')"
              [disabled]="isViewOnly || !isEnableReordering || !learningCheckEnabled">
              <i class="far fa-arrow-down"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="container">
				<div class="row">
					<div *ngFor="let datas of learningCheck.data | orderBy : 'orderNum'; let l = index">
						<label class="my-1 mx-2 h5" (click)="showInfoIceLearningCheck(learningCheck, datas.id)">
							<span class="badge badge-secondary">{{l+1}}</span>
						</label>
					</div>
				</div>
			</div>
    </div>
  </div>
</div> 

-->     
           
<!-- Games List -->
<!-- <div class="card my-3">
  <div class="card-header py-1">
    <p class="col-sm-10 my-1 float-left h6 px-0">
      <span class="badge badge-warning mr-2" [hidden] = "true">{{iceGameList.length}}</span>
      {{ 'ICE_TOPIC_CONTENT.ICE_GAMES' | translate }}
    </p>
    <button id="showMoreIceGames" type="button" class="my-1 mx-0 close float-right"
      (click)="isShowIceGames = !isShowIceGames" [disabled]="true">
      <i class="far" [ngClass]="{'fa-angle-up': isShowIceGames, 'fa-angle-down': !isShowIceGames}"></i>
    </button>
    <div class="dropdown">
      <button class="btn btn-sm btn-dark mx-4 float-right dropdown-toggle" type="button" id="addNewIceGames"
        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [disabled]="true">
        <i class="far fa-plus"></i>
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" [disabled]="true">
        <a class="dropdown-item" (click)="addNewIceGame('EIG')">Einstein (Instructor) Game</a>
        <a class="dropdown-item" (click)="addNewIceGame('MG')">Memory Game</a>
        <a class="dropdown-item" (click)="addNewIceGame('SMSG')">Speed Match (Student) Game</a>
      </div>
    </div>
  </div>
  <div *ngIf="isShowIceGames" class="card-body p-2">
    <div *ngFor="let iceGame of iceGameList; let g = index" class="card card-secondary my-2">
      <div class="card-body container-fluid p-1">
        <div class="">
          <div class="col-sm-10 float-left pl-2 pr-0">
            <div class="input-group input-group-sm">
              <div class="input-group-prepend">
                <span class="input-group-text">{{getStringFromType(iceGame.gameType)}}</span>
              </div>
              <input type="text" class="form-control form-control-sm float-right"
                id="iceGameDescription" [value]="iceGame.description"
                placeholder="{{ 'ICE_GAME.TITLE_PLACEHOLDER' | translate }}">
            </div>
          </div><!- -
          <div class="col-sm-2 float-right px-0">
            <button id="btnGotoIceGame" type="button" class="my-1 mx-3 close float-right"
              (click)="showInfoIceGame(iceGame)">
              <i class="far fa-angle-right"></i>
            </button>
            <button id="btnDeleteTopic" type="button" class="my-1 mx-2 close float-right"
              (click)="deleteIceGame(iceGame)">
              <i class="far fa-trash-alt"></i>
            </button>
          </div>- ->
        </div>
      </div>
    </div>
  </div>
</div>
 </div> -->

 <!-- Template for the model-->

<!-- hidden buttons to handle toolbar special char and fraction-->
<button tooltip="{{ 'WEB_EDITOR.ADD_SPE_CHAR' | translate }}" id={{addSpecialCharDivId}} container="body"
type="button" (click)="openModal(spec_char)" hidden="true"
class="btn btn-outline-primary btn-sm ml-1 mr-1">
<i class="fas fa-omega" aria-hidden="true"></i>
</button>
<button tooltip="{{ 'WEB_EDITOR.ADD_FRACTION' | translate }}" id={{addFractionDIvId}} container="body"
type="button" (click)="openModal(fraction)" hidden="true"
class="btn btn-outline-primary btn-sm ml-1 mr-1">
<i class="fas fa-divide" aria-hidden="true"></i>
</button>



<!-- special characters modal -->
<ng-template #spec_char>
<div class="modal-header">
    <h4 class="modal-title pull-left">{{ 'WEB_EDITOR.SPE_CHAR' | translate}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-2" *ngFor="let speChar of speCharList; let n=index">
                <button type="button" class="btn default" (click)="addSpecialCharacter(speChar)"
                    style="background-color: inherit;">
                    {{speChar.data.symbol}}</button>
            </div>
        </div>
    </div>
</div>
<!-- <div class="modal-footer">
    <label>{{ 'WEB_EDITOR.DEC_INPUT' | translate}}</label>
    <input (keypress)="numberOnly($event)" class="form-control input-sm" #dec_value type="text">
    <button type="button" class="btn btn-primary" (click)="addSpeChar(dec_value.value)">
        <i class="fa fa-plus" aria-hidden="true"></i>
    </button>
</div> -->
</ng-template>

<!--fraction modal-->
<ng-template #fraction>
    <div class="modal-header p-2">
      <h4 class="modal-title pull-left">{{ 'WEB_EDITOR.FRACTION' | translate}}</h4>
      <button type="button" class="close pull-right p-0 m-0" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="container-fluid">
        <div class="row">
          <div class="input-group input-group-sm ">
            <div class="input-group-prepend">
              <span class="input-group-text">Numerator / Denominator</span>
            </div>
            <input (keypress)="numberOnly($event)" #dec_value type="text" aria-label="Numerator"
              class="form-control" [(ngModel)]="numerator">
            <input (keypress)="numberOnly($event)" #dec_valueD type="text" aria-label="Denominator"
              class="form-control" [(ngModel)]="denominator">
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer p-2">                     
      <div class="col-sm-12 px-0"> 
        <div class="badge badge-warning float-left" *ngIf="numerator != '' && denominator != '' && numerator != null && denominator != null">
          <span class="h6 m-0">{{numerator}} / {{denominator}}</span>
        </div>
        <button type="button" class="btn btn-secondary btn-sm float-right mx-1" (click)="modalRef.hide()">{{ 'CANCEL' |
                  translate}}</button>
        <button type="button" class="btn btn-primary btn-sm float-right mx-1"
          (click)="modalRef.hide();addFraction(dec_value.value,dec_valueD.value);">{{
                  'ADD' | translate}}</button>
      </div>
    </div>
  </ng-template>

<ng-template #largeImage>
  <div class="modal-header">
      <h4 class="modal-title pull-left">{{ selectedImage.title }}</h4>
      <button type="button" id="image_close" class="close pull-right" aria-label="Close" (click)="modelRefCloseEvent()">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <div class="card p-0">
          <img class="card-img-top" src="data:image/jpeg;base64,{{selectedImage.largeImage}}">
      </div>
  </div>
  <div class="modal-footer">
      <button type="button" class="btn btn-sm btn-secondary" (click)="modelRefCloseEvent()">{{
              'IMAGE_SEARCH_VIEW.BTN_TEXT_CLOSE' | translate }}</button>
  </div>
</ng-template>

<ng-template #largeThreeDModel>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{ selectedThreeDModel.title }}</h4>
        <button type="button" id="threeDModel_close" class="close pull-right" aria-label="Close" (click)="modelRefCloseEvent()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="card p-0">
            <img class="card-img-top" [src]="selectedThreeDModel.selectedURL">
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-secondary" (click)="modelRefCloseEvent()">{{
                'THREED_MODEL_SEARCH_VIEW.BTN_TEXT_CLOSE' | translate }}</button>
    </div>
  </ng-template>

<!--template for delete learning checks-->
<ng-template #deleteLearningCheckModel>
	<div class="modal-header">
		<div *ngIf="isLCDeleteEnable">
			<h4 class="modal-title pull-left">{{ 'ICE_LEARNING_CHECK.DELETE_LEARNINGCHECK' | translate }} </h4>
		</div>
		<div *ngIf="!isLCDeleteEnable">
			<h4 class="modal-title pull-left">{{ 'ICE_LEARNING_CHECK.CANNOT_DELETE_LEARNINGCHECK' | translate }} </h4>
		</div>
		<button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>

	<div *ngIf="!isLCDeleteEnable" class="modal-body">
		<div class="card p-0 alert alert-warning" role="alert">
			<ul>
				<li class="m-1">
					<label>{{questionDependencies}}</label>
				</li>
			</ul>
		</div>
	</div>

	<div class="modal-footer">
		<button type="button" class="btn btn-default" (click)="modalRef.hide()">{{ 'CANCEL' | translate}}</button>

		<button *ngIf="isLCDeleteEnable" type="button" class="btn btn-warning"
			(click)="modalRef.hide();confirmDeleteLearningChecks();">{{'OK' | translate}}</button>
	</div>
</ng-template>

<ng-template #associateCorseModel>

  <div class="modal-header">
      <div >
          <h4 class="modal-title pull-left">{{ 'ICE_TOPIC_CONTENT.ASSOCIATE_ICE_COURSE' | translate }} </h4>
      </div>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>    
<div *ngIf="iceCoursesAvailable" class="link-model">
    <div class="modal-body">
        <div id="link-model-card" class="card p-0">          
            <div *ngFor="let course of iceCourseList; let n=index">
                <div [ngClass]="{'card bg-secondary text-white': course.courseId==selectedIceCourse}">
                    <div *ngIf="course.courseId != iceTopicContent.courseId"
                    (click)="selectCourse(course.courseId)" class="card-header">{{course.fileName}}
                   </div><!--link id and click object id || click id || -->
                </div>
            </div>
        </div> 
    </div>
</div>

  <div class="modal-footer">
    <button type="button" class="btn btn-warning mr-auto" (click)="modalRef.hide();clearSelected();">{{'CLEAR' | translate}}</button>
    <button type="button" class="btn btn-warning" (click)="modalRef.hide();confirmSelected();">{{'OK' | translate}}</button> 
    <button type="button" class="btn btn-default" (click)="modalRef.hide()">{{ 'CANCEL' | translate}}</button>
  </div>
</ng-template>

<!-- conflict checker modal -->
<ng-template #conflict_checker>
  <div class="modal-header">
      <h4 class="modal-title pull-left">Conflicts</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>

  <div class="modal-body py-1">
      <label>{{ 'ICE_COURSE.CONFLICTS_CHECK_MSG' | translate }}</label>
      <ag-grid-angular #agGrid style="width: 100%; height: 400px;" [rowData]="rowData" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }" class="ag-theme-balham"
          [columnDefs]="columnDefs"  [enableFilter]="false" [defaultColDef]="defaultColDef"
          [enableRangeSelection]="true" [pagination]="false"
          (gridReady)="onGridReady($event)" rowSelection="multiple" 
          [rowMultiSelectWithClick]="true" [getRowNodeId]="getRowNodeId" [rowSelection]="rowSelection">
      </ag-grid-angular>
      <button class="btn btn-sm btn-outline-dark float-right my-2" (click)="modalRef.hide();">Cancel</button>
      <button class="btn btn-sm btn-outline-dark float-right my-2 mx-2"
          (click)="autoUpdateICE();">Auto
          Update</button>
  </div>
</ng-template>

<!--template for closing topic content-->
<ng-template #closeConfirmModel>
  <div class="modal-header">
      <h4 class="modal-title pull-left">{{'CLOSE_CONFIRM' | translate }}</h4>
  </div>    	
  <div class="modal-body">
      <div class="container">
        <h5 class='warn-text-editor'>{{ 'WARNING' | translate}}</h5>
        <h5>{{ 'ICE_TOPIC_CONTENT.CLOSE_CONFIRM_MSG' | translate}} </h5>
      </div>
  </div> 
  <div class="modal-footer">
    <button  type="button" class="btn btn-default" (click)="cancel();modalRef.hide();">{{'YES' | translate}}</button>
    <button type="button" class="btn btn-warning" (click)="modalRef.hide()">{{ 'NO' | translate}}</button>      
  </div>
</ng-template>
