<div>
  <div class="row my-2 col-sm-12" style="padding-right: 0;">
    <div class="col-sm-8 px-0">
      <h3>{{ title }} </h3>
      <hr class="mt-0 mb-3" />
    </div>
    <div class="col-sm-3 px-0" >
      <input matInput [(ngModel)]="searchTerm" #category (keyup)='onkeyup(category.value)' placeholder="Search" class="form-control float-right" />
    </div>
    <div class="col-sm-1 px-0">
      <button id="button_comos_export" type="submit" class="btn btn-primary btn-sm float-right my-1 mx-1" (click)="exportClick()">
        {{ 'REPORTS.BTN_TXT_EXPORT' | translate }}
      </button>
    </div>
  </div>
  <div class="virtual-scroll-container" [style.height.px]="divHeight">
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
      <!-- This is the tree node template for leaf nodes -->
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
        <li class="mat-tree-node">
          <!-- use a disabled button to provide padding for tree leaf -->
          <button mat-icon-button disabled></button>
          {{node.name}}
        </li>
      </mat-tree-node>
      <!-- This is the tree node template for expandable nodes -->
      <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
        <li>
          <div class="mat-tree-node">
            <button mat-icon-button matTreeNodeToggle
                    [attr.aria-label]="'toggle ' + node.name">
              <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
              </mat-icon>
            </button>
            {{node.name}}
          </div>
          <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
            <ng-container matTreeNodeOutlet></ng-container>
          </ul>
        </li>
      </mat-nested-tree-node>
    </mat-tree>
  </div>
</div>