import { Component, OnInit } from '@angular/core';
import { WsCallback } from '../../../util/ws-callback.interface';
import { Router } from '../../../../../node_modules/@angular/router';
import { WsResponse } from '../../../util/ws-response.model';
import { WsType } from '../../../util/ws-type';
import { AlertService } from '../../../util/alert/alert.service';
import { ChangePassword } from './change-password.service';
import { Password } from './password.model';
import { LocalStorage } from '../../../util/localstorage.service';
import { TranslateService } from '../../../../../node_modules/@ngx-translate/core';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css']
})
export class ChangepasswordComponent implements OnInit, WsCallback {

  public passwordChangeModel = new Password(2);

  constructor(private router: Router, private alertService: AlertService, public changePasswordService: ChangePassword,
    private translate: TranslateService) {
    changePasswordService.setCallback(this);
    this.passwordChangeModel.userId = localStorage.getItem(LocalStorage.USER_ID);
    localStorage.removeItem(LocalStorage.LOGGED_USER);

    translate.addLangs(["es", "en"]);
		translate.setDefaultLang("en");
		let browserLang = translate.getBrowserLang();
		translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
  }

  ngOnInit() {
  }

  public onSubmitButtonClick(): void {
    console.log(this.passwordChangeModel);
    if (this.passwordChangeModel.newPassword.length < 6) {
      this.alertService.error('Password must contain at least six characters', false);
      return;
    }
    if (!this.passwordChangeModel.newPassword.match('[0-9]+')){
      this.alertService.error('Password must contain at least one number (0-9)', false);
      return;
    }
    if (!this.passwordChangeModel.newPassword.match('[a-z]+')) {
      this.alertService.error('Password must contain at least one lowercase letter (a-z)', false);
      return;
    }
    if (this.passwordChangeModel.newPassword.match('(.)\\1')) {
      this.alertService.error('Password cannot contain, two consecutive characters', false);
      return;
    }
    if (!this.passwordChangeModel.newPassword.match('[A-Z]')) {
      this.alertService.error('Password must contain at least one uppercase letter (A-Z)', false);
      return;
    }
    if (this.passwordChangeModel.newPassword != this.passwordChangeModel.confirmPassword) {
      this.alertService.error('Mismatching passwords', false);
      return;
    }

    //send the request
    this.changePasswordService.changePassword(JSON.stringify(this.passwordChangeModel));
  }

  public onCancelButtonClick(): void {
    localStorage.removeItem(LocalStorage.LOGGED_USER);
    this.router.navigateByUrl('login');
  }

  onSuccess(data: WsResponse, serviceType: WsType): void {
    if (serviceType == WsType.CHANGE_PASSWORD) {
      localStorage.removeItem(LocalStorage.LOGGED_USER);
      this.router.navigateByUrl('changePasswordStatus');
    }
  }

  onFail(res: WsResponse, serviceType: WsType): void {
    if (serviceType == WsType.CHANGE_PASSWORD) {
      this.alertService.error(res.statusDescription, false);
    }
  }

  //Password change model
  //public static PasswordModel = class {
  //  constructor(public oldPassword?: String, public newPassword?: String, public confirmPassword?: String){}
  //}
}
