import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UserVariable } from 'src/app/util/common/user-variable';
import { AlertService } from 'src/app/util/alert/alert.service';
import { LoadingService } from 'src/app/util/loading/loading.service';
import { OutputManagementService } from '../outputmanagement.service';

@Component({
  selector: 'app-alert-model',
  template: `   
   <div class="modal-header">
      <h4 class="modal-title pull-left">{{ title | translate }}</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <div class="container">
         <h6>
            {{ bodyText | translate }}
         </h6>
      </div>
   </div>
   <div *ngIf="showFooter()" class="modal-footer">
      <button type="button" class="btn btn-primary btn-sm" (click)="yesbuttonClick(); bsModalRef.hide()">{{ 'YES' | translate}}</button>
      <button type="button" class="btn btn-secondary btn-sm" (click)="bsModalRef.hide()">{{ 'CANCEL' | translate}}</button>
   </div>
   `
  })
export class OutputHomeAlertModelComponent {

  public title: string = 'More Info';
   private projectId: string = UserVariable.projectId
   public bodyText: string
   public showFooterEle: string
   public id: string
   public prcStatus: string
   public click: string
   public parentComp: any

   constructor(public bsModalRef: BsModalRef, private alertService: AlertService, private router: Router,
      private modalService: BsModalService, private loadingService: LoadingService,
      private outputManagementService: OutputManagementService) { }

   ngOnit() {     
   }

   public showFooter(){
      if (this.showFooterEle == "Yes") {
         return true;
      } else {
         return false;
      }
   }

   public yesbuttonClick() {
      if (this.click == "regOutput") {
         this.loadingService.showLoading(true, null, "", null)
         this.outputManagementService.regenarateOutput(this.id, "0")
            .subscribe(data => {
               this.loadingService.hideLoading();
               this.parentComp.getSearchedOutputData();
            },
               error => {
                  this.loadingService.hideLoading()
                  this.alertService.clear()
                  this.alertService.error(error.statusDescription)
               });
      }
   }
}
