export class Statement {
	constructor(
		public statementId?: String,
		   public title?: String,
		   public categoryId?: String,
		   public projectId?: String,
		   public topicTypeId?: String,
		   public content?: String,
		   public categoryName?: String,
		   public categoryPath?: String,
		   public status?: String,
		   public lastUpdateTimeStamp?: any,
		   public statementContentUpdateTimeStamp?:any,
		   public referenceCount?:any,
		   public dependencyCount?:any,
		   public checkedOutTopicCount?:any,
		   public checkedInTopicCount?:any,
		   public procDependencyCount?:any,
		   public iceCourseDependencyCount?:any,
		   public lastUpdateTimeStampString?:any,
		   public lastUpdatedBy?:any,
		   public lastStatementValue?:any,
		   public projectName?:any,
		   public clientName?:any,
		   public checkoutUserId?:any,
		   public checkoutUserName?:any,
		   public fieldDependencyCount?:any,
		   public fieldReferenceCount?:any,
		   public updateContent?:any,
		   public hasPendingChange?:any,
		   public reusableChangeInfo?:any,
		   public foregroundColor?:any,
		   public backgroundColor?:any
	)
	{
   
	}
   }
   