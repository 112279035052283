import { OnInit, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ManageWorkflowTemplatesService } from '../manage-workflow-templates.service';
import { LoadingService } from 'src/app/util/loading/loading.service';
import { WsResponse } from 'src/app/util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';
import { AlertService } from 'src/app/util/alert/alert.service';
import { SharedService } from 'src/app/util/shared.service';
import xml2js from 'xml2js'; 

@Component({
    selector: 'app-add-edit-workflow-template',
    templateUrl: './add-edit-workflow-template.component.html'
  })
export class AddEditWorkflowTemplateComponent implements OnInit{
    id: string;
    public active: boolean = true;
    public isShowWorkflowTasks: boolean = false
    public taskList: any = []
    public workflowName: string = "";
    public itemType: string = null;
    public configParameters: string = "";
    public version: string = "";
    public status: string = '1';
    public isEdit: boolean = false;
    public hasEmptyTaskName: boolean = false;
    public mandatoryMessage: string = "";
    public itemTypeList: Array<any> = [];
    public selectedItemType: Array<any> = [];
  
    constructor(private sharedService: SharedService, private workflowService: ManageWorkflowTemplatesService, private translate: TranslateService, 
      private loadingService: LoadingService, private alertService: AlertService){
        translate.addLangs(["es", "en"]);
        translate.setDefaultLang("en");
        let browserLang = translate.getBrowserLang();
        this.translate.get('WORKFLOW_TEMPLATE_ADD_EDIT.MANDATORY_FIELDS').subscribe((res: string) => {
          this.mandatoryMessage = res;
        })
    }

    ngOnInit(): void{
      if(this.sharedService.getFileId() != null && this.sharedService.getFileId() != "-1" && this.sharedService.getFileId() != -1)
      {
        this.id = this.sharedService.getFileId()
        this.isEdit = true;
        this.editWorkflowTemplate(this.sharedService.getFileId());
      } else{
        this.getItemTypes()
      }
    }

    /**
     * Add new workflow template or update the existing workflow template
     */
    save(){
      //validate the required fields before saving
      if(this.isFormValid()){
        //Invert the names again not to cause an issue with old templates
        this.ConvertItemTypeName();
        if(this.id != undefined){ //update the existing workflow template
          var wfTemplateObject1 = {
            id: this.id,
            fileName: this.workflowName,
            itemType: this.itemType,
            configParameters: this.configParameters,
            status: this.status,
            version: this.version,
            configTasks: this.getTasksXML(this.taskList)
          }
    
          var jsonObject = JSON.stringify(wfTemplateObject1);
          console.log(jsonObject);
          this.loadingService.showLoading(true, false, this.translate.instant('SAVING'), 0);
          this.workflowService.updateWorkflowTemplate(jsonObject, this);
        } else{ //add new workflow template
          var wfTemplateObject2 = {
            fileName: this.workflowName,
            itemType: this.itemType,
            configParameters: this.configParameters,
            status: this.status,
            version: this.version,
            configTasks: this.getTasksXML(this.taskList)
          }
    
          var jsonObject = JSON.stringify(wfTemplateObject2);
          console.log(jsonObject);
          this.loadingService.showLoading(true, false, this.translate.instant('SAVING'), 0);
          this.workflowService.addWorkflowTemplate(jsonObject, this);
        }
      } else{ //give error message to the user
        this.alertService.clear();
        this.alertService.error(this.mandatoryMessage)
      }
    }

    /**
     * Validate the mandatory fields
     */
    isFormValid(): boolean{
      let isValid = true;
      if(this.workflowName == undefined || this.workflowName.trim() == '' || this.itemType == undefined || this.itemType == '' || 
      this.configParameters == undefined || this.configParameters.trim() == '' || this.hasEmptyTaskNames()){
        isValid = false;
      }
      return isValid;
    }

    /**
     * Create XML from the task list
     * @param taskList 
     */
    getTasksXML(taskList: any): string{
      let taskXML = "";
      if(taskList != null && taskList.length > 0){
        taskXML += "<WorkflowTaskList>";
        taskList.forEach(task => {
          taskXML += "<WorkflowTask>";
          taskXML += "<TaskName>";
          taskXML += task.taskName;
          taskXML += "</TaskName>";
          taskXML += "<TaskOrder>";
          taskXML += task.order;
          taskXML += "</TaskOrder>";
          taskXML += "</WorkflowTask>";
        });
        taskXML += "</WorkflowTaskList>";
      } else{
        //do nothing
      }
      return taskXML;
    }

    /**
     * Get task list by XML
     * @param configTasks 
     */
    getTasksByXML(configTasks: string){
      if(configTasks != null){
        this.parseXML(configTasks).then(data => {
          this.taskList = data
        });
      } else{
        //do nothing
      }
    }

    /**
     * Process XML and get values
     * @param data 
     */
    parseXML(data){
      return new Promise(resolve => {
        var k: string | number,
          arr = [],
          parser = new xml2js.Parser(
            {
              trim: true,
              explicitArray: true
            });
        parser.parseString(data, function (err, result) {
          var obj = result.WorkflowTaskList;
          for (k in obj.WorkflowTask) {
            var item = obj.WorkflowTask[k];
            arr.push({
              taskName: item.TaskName[0],
              order: item.TaskOrder[0]
            });
          }
          resolve(arr);
        });
      });
    }

    /**
     * Cancel the Add/Edit workflow template
     */
    cancel(){
      this.alertService.clear();
      this.sharedService.viewSideBarEventEmit("manageWfTemplates")
    }

    /**
     * Enable/disable the workflow template
     */
    radioClick(){
      this.active = !this.active;
      if(this.active){
        this.status = '1'
      } else{
        this.status = '0'
      }
    }

    /**
     * Show/hide workflow tasks
     */
    showWorkflowTasks(){
      this.isShowWorkflowTasks = !this.isShowWorkflowTasks;
    }

    /**
     * Add new workflow task into the list
     */
    addNewWorkflowTask(){
      this.isShowWorkflowTasks = true
      let i = this.taskList.length
      this.taskList.push({ order: i + 1, taskName: ""})
      this.hasEmptyTaskName = this.hasEmptyTaskNames();
    }

    /**
     * Delete workflow task from the list
     * @param order 
     */
    deleteWorkflowTask(order: number){
      let itemIndex = this.taskList.findIndex(item => item.order == order);
      this.taskList.splice(itemIndex, 1);
      //reduce the order by 1 for all the records in the list after the deleted record
      for(let i = itemIndex; i < this.taskList.length; i++){
        this.taskList[i].order -= 1
      }
      this.hasEmptyTaskName = this.hasEmptyTaskNames();
    }

    /**
     * Load values for the Edit Workflow Template
     * @param wfTemplateId 
     */
    editWorkflowTemplate(wfTemplateId: string){
      this.loadingService.showLoading;
      this.workflowService.getWorkflowTemplateById(wfTemplateId, this);
    }

    /**
     * Disable the plus icon after validating the task name
     */
    onTaskNameChange(){
      this.hasEmptyTaskName = this.hasEmptyTaskNames();
    }

    /**
     * Validate the task names
     */
    hasEmptyTaskNames(): boolean{
      let hasEmpty = false;
      this.taskList.forEach(element => {
        if(element.taskName.trim() == '' || element.taskName == undefined){
          hasEmpty = true;
          return hasEmpty
        }
      });
      return hasEmpty;
    }

    getItemTypes(){
      this.loadingService.showLoading(true, false, 'Loading', 0);
      this.workflowService.getItemTypes(this)
    }

    public selectItemType(event) {
      this.itemType = event.text
   }

   public removeItemType() {
      this.itemType = null;
   }

    onSuccess(data: WsResponse, serviceType: WsType){
      this.loadingService.hideLoading();
      if(serviceType == WsType.ADD_WORKFLOW_TEMPLATE){
        this.alertService.success("Successfully added the workflow template")
        this.sharedService.viewSideBarEventEmit("manageWfTemplates")
      } else if(serviceType == WsType.GET_WORKFLOW_TEMPLATE_BY_ID){
        var workflowTemplate = data.payload;
        this.workflowName = workflowTemplate.fileName;
        this.itemType = workflowTemplate.itemType;
        this.configParameters = workflowTemplate.configParameters;
        this.version = workflowTemplate.version;
        this.status = workflowTemplate.status;
        this.getTasksByXML(workflowTemplate.configTasks);
        this.getItemTypes();
      } else if(serviceType == WsType.UPDATE_WORKFLOW_TEMPLATE){
        var isUpdated = data.payload;
        if(isUpdated){
          this.alertService.success("Successfully updated the workflow template");
		      this.sharedService.viewSideBarEventEmit("manageWfTemplates")
        }
      } else if(serviceType == WsType.GET_ITEM_TYPES){
        this.itemTypeList = data.payload
        if(this.itemType != null && this.itemType != ""){
          //set the item type for existing templates since those were added manually
          if(this.itemType == 'CheckSheetInstance'){
            this.itemType = 'CheckSheet';
          } else if(this.itemType == 'Topic'){
            this.itemType = 'Topic';
          } else if(this.itemType == 'MechanicalCompletion'){
            this.itemType = 'Mechanical Completion';
          } else if(this.itemType == 'PrecommissioningCompletion'){
            this.itemType = 'Static Completion';
          } else if(this.itemType == 'SystemTurnoverNotice'){
            this.itemType = 'System Turnover';
          } else if(this.itemType == 'CommissioningCompletion'){
            this.itemType = 'Comm Completion';
          } else if(this.itemType == 'OnshoreTurnoverNotice'){
            this.itemType = 'Onshore Turnover Notice';
          } else if(this.itemType == 'PunchlistOpen'){
            this.itemType = 'Punchlist Group Open';
          } else if(this.itemType == 'PunchlistClose'){
            this.itemType = 'Punchlist Group Close';
          } else{
            //no need to set the item type manually since the item type is selected from the dropdown for the newely added workflow templates
          }
          this.selectedItemType = this.itemTypeList.filter(x => x == this.itemType);
        }
      }
    }
 
    onFail(data: WsResponse, serviceType: WsType){
      this.loadingService.hideLoading();
      if(serviceType == WsType.ADD_WORKFLOW_TEMPLATE){
        this.alertService.error(data.statusDescription);
      } else if(serviceType == WsType.GET_WORKFLOW_TEMPLATE_BY_ID){
        this.alertService.error(data.statusDescription);
      } else if(serviceType == WsType.UPDATE_WORKFLOW_TEMPLATE){
        this.alertService.error(data.statusDescription);
      } else if(serviceType == WsType.DELETE_WORKFLOW_TEMPLATE){
        this.alertService.error(data.statusDescription);
      } else if(serviceType == WsType.DISABLE_WORKFLOW_TEMPLATE){
        this.alertService.error(data.statusDescription);
      } else if(serviceType == WsType.GET_ITEM_TYPES){
        this.alertService.error(data.statusDescription);
      }
    }

    ngOnDestroy(){
      this.sharedService.setFileId(-1);
    }

    //Invert the names again not to cause an issue with old templates
    ConvertItemTypeName() {
      if (this.itemType == 'CheckSheet') {
        this.itemType = 'CheckSheetInstance';
      } else if (this.itemType == 'Topic') {
          this.itemType = 'Topic';
      } else if (this.itemType == 'Mechanical Completion') {
          this.itemType = 'MechanicalCompletion';
      } else if (this.itemType == 'Static Completion') {
          this.itemType = 'PrecommissioningCompletion';
      } else if (this.itemType == 'System Turnover') {
          this.itemType = 'SystemTurnoverNotice';
      } else if (this.itemType == 'Comm Completion') {
          this.itemType = 'CommissioningCompletion';
      } else if (this.itemType == 'Onshore Turnover Notice') {
          this.itemType = 'OnshoreTurnoverNotice';
      } else if (this.itemType == 'Punchlist Group Open') {
          this.itemType = 'PunchlistOpen';
      } else if (this.itemType == 'Punchlist Group Close') {
          this.itemType = 'PunchlistClose';
      } else {

      }
    }
}