<div class="my-login-page" data-gr-c-s-loaded="true">
    <section class="h-100">
       <div class="container h-100">
          <div class="row justify-content-md-center h-100">
             <div class="card-wrapper">
                <div class="mt-5 mb-3">
                   <img src="../../../assets/images/mainimage.png" alt="" width="100%" height="auto">
                </div>
                <div class="card fat">
                   <div class="card-body">
                      <h4 class="card-title">{{ 'KEY_CODE_VERIFICATION.KEY_CODE_VERIFICATION' | translate}} </h4>
                      <form method="POST" ngNativeValidate>
                         <div class="form-group">
                            <label><strong>{{ 'KEY_CODE_VERIFICATION.KEY_SENT_BY_EMAIL' | translate}}</strong> {{ 'KEY_CODE_VERIFICATION.ENTER_KEY_CODE' | translate}}</label>
                         </div>
                         <div class="form-group">
                            <label for="email">Key Code</label>
                            <input id="keycodeverification_keyCode" type="text" class="form-control" name="keyCode" value="" required autofocus="" [(ngModel)] = "keyCode">
                         </div>
                         <div class="form-group">
                           <alert></alert>
                         </div>
                         <div class="form-group mt-4 mb-3">
                            <button id="keycodeverification_submit" type="submit" class="btn btn-primary btn-block mb-3" (click)="verifyKeyCode()">
                              {{ 'SUBMIT' | translate}}
                            </button>
                            <button id="keycodeverification_cancel"  class="btn btn-secondary btn-block mt-3" (click)="cancelBtnClickEvent()">
                              {{ 'CANCEL' | translate}}
                            </button>
                         </div>
                      </form>
                   </div>
                </div>
                <div class="footer">
                  {{ 'ALL_RIGHTS' | translate}}
                </div>
             </div>
          </div>
       </div>
    </section>
  </div>
