import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CommonUtil } from 'src/app/util/common/common-util';
import { UserVariable } from 'src/app/util/common/user-variable';
import { ServiceUrls } from 'src/app/util/service-urls';
import { SharedService } from 'src/app/util/shared.service';
import { WsCallback } from 'src/app/util/ws-callback.interface';
import { WsResponse } from 'src/app/util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';

@Injectable({
  providedIn: 'root'
})
export class ManagechecksheetsService {
  token: string;
  private rowData: any = null;
  private apiGridData: any = null;
  private allApiGridData: any = null;
  private assignedApiGridData: any = null;
  private isSaved: boolean = true;
  loadTableData = new EventEmitter<any>();

  constructor(private http: HttpClient, private commonUtil: CommonUtil) {
    this.token = UserVariable.getUserToken();
    if (!this.commonUtil.validateLogin(this.token)) {
      return;
    }
  }

  public loadTableDataRequest(){
    this.loadTableData.emit()
  }
  public loadTableDataResponse(){
    return this.loadTableData;
  }

  public setApiGridData(apiGridData: any) {
    this.apiGridData = apiGridData;
  }

  public getApiGridData() {
    return this.apiGridData;
  }

  public setAllApiGridData(apiGridData: any) {
    this.allApiGridData = apiGridData;
  }

  public getAllApiGridData() {
    return this.allApiGridData;
  }

  public setAssignedApiGridData(apiGridData: any) {
    this.assignedApiGridData = apiGridData;
  }

  public getAssignedApiGridData() {
    return this.assignedApiGridData;
  }

  public setRowData(rowData: any) {
    this.rowData = rowData;
  }

  public getRowData() {
    return this.rowData;
  }

  public setIsSaved(isSaved: boolean) {
    this.isSaved = isSaved;
  }

  public getIsSaved() {
    return this.isSaved;
  }

  searchChecksheets(csName: String, csCode: String, csDiscipline: String, csType: String, hasText: string) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    let url = this.getCSSearchURL(token);
    return this.http.post(url, JSON.stringify(new Request(csName, csCode, csDiscipline, csType, hasText))).
      pipe(
        map(response => {
          var modified = JSON.parse(JSON.stringify(response));
          return new WsResponse(
            modified.status.description,
            modified.status.name,
            modified.status.code,
            modified.payload);
        }),
        catchError(error => {
          var modified = JSON.parse(JSON.stringify(error));
          var res = new WsResponse(
            modified.error.status.description,
            modified.error.status.name,
            modified.error.status.code,
            modified.error.payload);
          return throwError(res)
        })
      );
  }

  public getCSSearchURL(token: String) {
    let url = ServiceUrls.CHECKSHEET_SEARCH_VIEW + '?token=' + token;
    return url;
  }

  public getFunctionCodeList(wsCallBack: WsCallback) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    this.http.get(ServiceUrls.getAllFunctionCodes(token)).subscribe(
      data => {
        var modified = JSON.parse(JSON.stringify(data));
        var res = new WsResponse(
          modified.status.description,
          modified.status.code,
          modified.status.name,
          modified.payload);
        wsCallBack.onSuccess(res, WsType.GET_ALL_FUNCTION_CODES);
      },
      error => {
        if (error.status != '') {
          var val = (error as HttpErrorResponse).error;
          var modified = JSON.parse(JSON.stringify(val));
          var res = new WsResponse(
            modified.status.description,
            modified.status.code,
            modified.status.name,
            modified.payload);
          wsCallBack.onFail(res, WsType.GET_ALL_FUNCTION_CODES);
        } else {
          //browser related issues
          var res = new WsResponse("Unknown error happened");
          wsCallBack.onFail(res, WsType.GET_ALL_FUNCTION_CODES);
        }
      }
    );
  }

  getDisciplineList(projectId: String, wsCallBack: WsCallback) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    const options = { params: new HttpParams().set("token", token) }
    this.http.get(ServiceUrls.getDisciplines(projectId), options).subscribe(
      data => {
        var modified = JSON.parse(JSON.stringify(data));
        var res = new WsResponse(
          modified.status.description,
          modified.status.code,
          modified.status.name,
          modified.payload);
        wsCallBack.onSuccess(res, WsType.GET_DISCIPLINES);
      },
      error => {
        if (error.status != '') {
          var val = (error as HttpErrorResponse).error;
          var modified = JSON.parse(JSON.stringify(val));
          var res = new WsResponse(
            modified.status.description,
            modified.status.code,
            modified.status.name,
            modified.payload);
          wsCallBack.onFail(res, WsType.GET_DISCIPLINES);
        } else {
          //browser related issues
          var res = new WsResponse("Unknown error happened");
          wsCallBack.onFail(res, WsType.GET_DISCIPLINES);
        }
      }
    );
  }

  addNewChecksheet(data: string, wsCallBack: WsCallback) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    this.http.post(ServiceUrls.addNewCS(token), data).subscribe(
      data => {
        var modified = JSON.parse(JSON.stringify(data));
        var res = new WsResponse(
          modified.status.description,
          modified.status.code,
          modified.status.name,
          modified.payload);
        wsCallBack.onSuccess(res, WsType.ADD_NEW_CHECKSHEET);
      },
      error => {
        if (error.status != '') {
          var val = (error as HttpErrorResponse).error;
          var modified = JSON.parse(JSON.stringify(val));
          var res = new WsResponse(
            modified.status.description,
            modified.status.code,
            modified.status.name,
            modified.payload);
          wsCallBack.onFail(res, WsType.ADD_NEW_CHECKSHEET);
        } else {
          //browser related issues
          var res = new WsResponse("Unknown error happened");
          wsCallBack.onFail(res, WsType.ADD_NEW_CHECKSHEET);
        }
      }
    );
  }

  getCSInstanceCount(csId: string, wsCallBack: WsCallback) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    this.http.get(ServiceUrls.getCSInstanceCount(token, csId)).subscribe(
      data => {
        var modified = JSON.parse(JSON.stringify(data));
        var res = new WsResponse(
          modified.status.description,
          modified.status.code,
          modified.status.name,
          modified.payload);
        wsCallBack.onSuccess(res, WsType.GET_CSINSTANCE_COUNT);
      },
      error => {
        if (error.status != '') {
          var val = (error as HttpErrorResponse).error;
          var modified = JSON.parse(JSON.stringify(val));
          var res = new WsResponse(
            modified.status.description,
            modified.status.code,
            modified.status.name,
            modified.payload);
          wsCallBack.onFail(res, WsType.GET_CSINSTANCE_COUNT);
        } else {
          //browser related issues
          var res = new WsResponse("Unknown error happened");
          wsCallBack.onFail(res, WsType.GET_CSINSTANCE_COUNT);
        }
      }
    );
  }

  getCSDataById(csId: string, wsCallBack: WsCallback) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    this.http.get(ServiceUrls.getCSDataById(token, csId)).subscribe(
      data => {
        var modified = JSON.parse(JSON.stringify(data));
        var res = new WsResponse(
          modified.status.description,
          modified.status.code,
          modified.status.name,
          modified.payload);
        wsCallBack.onSuccess(res, WsType.GET_CS_DATA_BY_ID);
      },
      error => {
        if (error.status != '') {
          var val = (error as HttpErrorResponse).error;
          var modified = JSON.parse(JSON.stringify(val));
          var res = new WsResponse(
            modified.status.description,
            modified.status.code,
            modified.status.name,
            modified.payload);
          wsCallBack.onFail(res, WsType.GET_CS_DATA_BY_ID);
        } else {
          //browser related issues
          var res = new WsResponse("Unknown error happened");
          wsCallBack.onFail(res, WsType.GET_CS_DATA_BY_ID);
        }
      }
    );
  }

  updateChecksheet(data: string, wsCallBack: WsCallback) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    this.http.post(ServiceUrls.updateCS(token), data).subscribe(
      data => {
        var modified = JSON.parse(JSON.stringify(data));
        var res = new WsResponse(
          modified.status.description,
          modified.status.code,
          modified.status.name,
          modified.payload);
        wsCallBack.onSuccess(res, WsType.UPDATE_CHECKSHEET);
      },
      error => {
        if (error.status != '') {
          var val = (error as HttpErrorResponse).error;
          var modified = JSON.parse(JSON.stringify(val));
          var res = new WsResponse(
            modified.status.description,
            modified.status.code,
            modified.status.name,
            modified.payload);
          wsCallBack.onFail(res, WsType.UPDATE_CHECKSHEET);
        } else {
          //browser related issues
          var res = new WsResponse("Unknown error happened");
          wsCallBack.onFail(res, WsType.UPDATE_CHECKSHEET);
        }
      }
    );
  }

  public getChecksheetCheckData(csId: string, wsCallBack: WsCallback) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    this.http.get(ServiceUrls.getCSCheckDataById(token, csId)).subscribe(
      data => {
        var modified = JSON.parse(JSON.stringify(data));
        var res = new WsResponse(
          modified.status.description,
          modified.status.code,
          modified.status.name,
          modified.payload);
        wsCallBack.onSuccess(res, WsType.GET_CS_CHECK_DATA_BY_ID);
      },
      error => {
        if (error.status != '') {
          var val = (error as HttpErrorResponse).error;
          var modified = JSON.parse(JSON.stringify(val));
          var res = new WsResponse(
            modified.status.description,
            modified.status.code,
            modified.status.name,
            modified.payload);
          wsCallBack.onFail(res, WsType.GET_CS_CHECK_DATA_BY_ID);
        } else {
          //browser related issues
          var res = new WsResponse("Unknown error happened");
          wsCallBack.onFail(res, WsType.GET_CS_CHECK_DATA_BY_ID);
        }
      }
    );
  }

  public disableEnableFileTableData(fileId: any, title: String, status: String) {
    if (this.apiGridData != null && this.apiGridData != undefined && fileId != null) {
      var rowNode = this.apiGridData.getRowNode(fileId);
      if (rowNode != null && rowNode != undefined) {
        if (status == "Inactive") {
          rowNode.setDataValue("status", "status:Active");
          rowNode.setDataValue("delete", "delete:Active");
        }
        else {
          rowNode.setDataValue("status", "status:Inactive");
          rowNode.setDataValue("delete", "delete:Inactive");
        }
      }
    }
  }

  public deleteFileTableData(csd: any) {
    if (this.apiGridData != null && this.apiGridData != undefined && csd != null) {
      var selectedData = this.apiGridData.getSelectedRows();
      if (selectedData != null && selectedData != undefined) {
        this.apiGridData.applyTransaction({ remove: selectedData });
        let newRowData: any = [];
        this.apiGridData.forEachNode(function (rowNode, index) {
          newRowData.push(rowNode.data);
        });
        this.setRowData(newRowData);
      }
    }
  }

  public removeAssignedAssetSystem(rowId: any) {
    if (this.assignedApiGridData != null && this.assignedApiGridData != undefined && rowId != null) {
      var selectedData = this.assignedApiGridData.getRowNode(rowId).data;
      if (selectedData != null && selectedData != undefined) {
        this.assignedApiGridData.applyTransaction({ remove: [{ id: rowId }] });
        this.setIsSaved(false);
        //this.assetApiGridData.applyTransaction({add: [selectedData]});
        //this.assetApiGridData.gridOptions.columnApi.getColumn('assetNo').setSort("asc");
      }
    }
  }

  saveCSChecks(csId: String, checksJson: any) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    let url = ServiceUrls.saveCSChecks(token, csId);
    return this.http.post(url, JSON.stringify(new AssignChecksRequest(csId, checksJson, null, null))).
      pipe(
        map(response => {
          var modified = JSON.parse(JSON.stringify(response));
          return new WsResponse(
            modified.status.description,
            modified.status.name,
            modified.status.code,
            modified.payload);
        }),
        catchError(error => {
          var modified = JSON.parse(JSON.stringify(error));
          var res = new WsResponse(
            modified.error.status.description,
            modified.error.status.name,
            modified.error.status.code,
            modified.error.payload);
          return throwError(res)
        })
      );
  }

  saveCSAssets(csId: String, assetsJson: any) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    let url = ServiceUrls.saveCSAssets(token, csId);
    return this.http.post(url, JSON.stringify(new AssignChecksRequest(csId, null, assetsJson, null))).
      pipe(
        map(response => {
          var modified = JSON.parse(JSON.stringify(response));
          return new WsResponse(
            modified.status.description,
            modified.status.name,
            modified.status.code,
            modified.payload);
        }),
        catchError(error => {
          var modified = JSON.parse(JSON.stringify(error));
          var res = new WsResponse(
            modified.error.status.description,
            modified.error.status.name,
            modified.error.status.code,
            modified.error.payload);
          return throwError(res)
        })
      );
  }

  searchAssetsOnCS(json) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    return this.http.post(ServiceUrls.getAssetOnChecksheets(token), json).
      pipe(
        map(response => {
          var modified = JSON.parse(JSON.stringify(response));
          return new WsResponse(
            modified.status.description,
            modified.status.name,
            modified.status.code,
            modified.payload);
        }),
        catchError(error => {
          var modified = JSON.parse(JSON.stringify(error));
          var res = new WsResponse(
            modified.error.status.description,
            modified.error.status.name,
            modified.error.status.code,
            modified.error.payload);
          return throwError(res)
        })
      );
  }

  getAssetsByProjectId(csId: string, wsCallBack: WsCallback) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    this.http.get(ServiceUrls.getActiveAssets(token, csId)).subscribe(
      data => {
        var modified = JSON.parse(JSON.stringify(data));
        var res = new WsResponse(
          modified.status.description,
          modified.status.code,
          modified.status.name,
          modified.payload);
        wsCallBack.onSuccess(res, WsType.GET_ACTIVE_ASSETS);
      },
      error => {
        if (error.status != '') {
          var val = (error as HttpErrorResponse).error;
          var modified = JSON.parse(JSON.stringify(val));
          var res = new WsResponse(
            modified.status.description,
            modified.status.code,
            modified.status.name,
            modified.payload);
          wsCallBack.onFail(res, WsType.GET_ACTIVE_ASSETS);
        } else {
          //browser related issues
          var res = new WsResponse("Unknown error happened");
          wsCallBack.onFail(res, WsType.GET_ACTIVE_ASSETS);
        }
      }
    );
  }

  public getAllVendors(wsCallBack: WsCallback) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    this.http.get(ServiceUrls.getAllVendors(token)).subscribe(
      data => {
        var modified = JSON.parse(JSON.stringify(data));
        var res = new WsResponse(
          modified.status.description,
          modified.status.code,
          modified.status.name,
          modified.payload);
        wsCallBack.onSuccess(res, WsType.GET_ALL_VENDORS);
      },
      error => {
        if (error.status != '') {
          var val = (error as HttpErrorResponse).error;
          var modified = JSON.parse(JSON.stringify(val));
          var res = new WsResponse(
            modified.status.description,
            modified.status.code,
            modified.status.name,
            modified.payload);
          wsCallBack.onFail(res, WsType.GET_ALL_VENDORS);
        } else {
          //browser related issues
          var res = new WsResponse("Unknown error happened");
          wsCallBack.onFail(res, WsType.GET_ALL_VENDORS);
        }
      }
    );
  }

  public getAllPhases(wsCallBack: WsCallback) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    this.http.get(ServiceUrls.getActivePhases(token)).subscribe(
      data => {
        var modified = JSON.parse(JSON.stringify(data));
        var res = new WsResponse(
          modified.status.description,
          modified.status.code,
          modified.status.name,
          modified.payload);
        wsCallBack.onSuccess(res, WsType.GET_ALL_PHASES);
      },
      error => {
        if (error.status != '') {
          var val = (error as HttpErrorResponse).error;
          var modified = JSON.parse(JSON.stringify(val));
          var res = new WsResponse(
            modified.status.description,
            modified.status.code,
            modified.status.name,
            modified.payload);
          wsCallBack.onFail(res, WsType.GET_ALL_PHASES);
        } else {
          //browser related issues
          var res = new WsResponse("Unknown error happened");
          wsCallBack.onFail(res, WsType.GET_ALL_PHASES);
        }
      }
    );
  }

  public getAssignedAssets(wsCallBack: WsCallback, csId: String) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    this.http.get(ServiceUrls.getAssetsForCS(token, csId)).subscribe(
      data => {
        var modified = JSON.parse(JSON.stringify(data));
        var res = new WsResponse(
          modified.status.description,
          modified.status.code,
          modified.status.name,
          modified.payload);
        wsCallBack.onSuccess(res, WsType.GET_ASSIGNED_ASSETS);
      },
      error => {
        if (error.status != '') {
          var val = (error as HttpErrorResponse).error;
          var modified = JSON.parse(JSON.stringify(val));
          var res = new WsResponse(
            modified.status.description,
            modified.status.code,
            modified.status.name,
            modified.payload);
          wsCallBack.onFail(res, WsType.GET_ASSIGNED_ASSETS);
        } else {
          //browser related issues
          var res = new WsResponse("Unknown error happened");
          wsCallBack.onFail(res, WsType.GET_ASSIGNED_ASSETS);
        }
      }
    );
  }


  searchSystemsOnCS(json) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    return this.http.post(ServiceUrls.getSystemsOnChecksheets(token), json).
      pipe(
        map(response => {
          var modified = JSON.parse(JSON.stringify(response));
          return new WsResponse(
            modified.status.description,
            modified.status.name,
            modified.status.code,
            modified.payload);
        }),
        catchError(error => {
          var modified = JSON.parse(JSON.stringify(error));
          var res = new WsResponse(
            modified.error.status.description,
            modified.error.status.name,
            modified.error.status.code,
            modified.error.payload);
          return throwError(res)
        })
      );
  }

  getSystemsByProjectId(csId: string, wsCallBack: WsCallback) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    this.http.get(ServiceUrls.getActiveSystems(token, csId)).subscribe(
      data => {
        var modified = JSON.parse(JSON.stringify(data));
        var res = new WsResponse(
          modified.status.description,
          modified.status.code,
          modified.status.name,
          modified.payload);
        wsCallBack.onSuccess(res, WsType.GET_ACTIVE_SYSTEMS);
      },
      error => {
        if (error.status != '') {
          var val = (error as HttpErrorResponse).error;
          var modified = JSON.parse(JSON.stringify(val));
          var res = new WsResponse(
            modified.status.description,
            modified.status.code,
            modified.status.name,
            modified.payload);
          wsCallBack.onFail(res, WsType.GET_ACTIVE_SYSTEMS);
        } else {
          //browser related issues
          var res = new WsResponse("Unknown error happened");
          wsCallBack.onFail(res, WsType.GET_ACTIVE_SYSTEMS);
        }
      }
    );
  }

  public getAssignedSystems(wsCallBack: WsCallback, csId: String) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    this.http.get(ServiceUrls.getSystemsForCS(token, csId)).subscribe(
      data => {
        var modified = JSON.parse(JSON.stringify(data));
        var res = new WsResponse(
          modified.status.description,
          modified.status.code,
          modified.status.name,
          modified.payload);
        wsCallBack.onSuccess(res, WsType.GET_ASSIGNED_SYSTEMS);
      },
      error => {
        if (error.status != '') {
          var val = (error as HttpErrorResponse).error;
          var modified = JSON.parse(JSON.stringify(val));
          var res = new WsResponse(
            modified.status.description,
            modified.status.code,
            modified.status.name,
            modified.payload);
          wsCallBack.onFail(res, WsType.GET_ASSIGNED_SYSTEMS);
        } else {
          //browser related issues
          var res = new WsResponse("Unknown error happened");
          wsCallBack.onFail(res, WsType.GET_ASSIGNED_SYSTEMS);
        }
      }
    );
  }

  saveCSSystems(csId: String, systemsJson: any) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    let url = ServiceUrls.saveCSSystems(token, csId);
    return this.http.post(url, JSON.stringify(new AssignChecksRequest(csId, null, null, systemsJson))).
      pipe(
        map(response => {
          var modified = JSON.parse(JSON.stringify(response));
          return new WsResponse(
            modified.status.description,
            modified.status.name,
            modified.status.code,
            modified.payload);
        }),
        catchError(error => {
          var modified = JSON.parse(JSON.stringify(error));
          var res = new WsResponse(
            modified.error.status.description,
            modified.error.status.name,
            modified.error.status.code,
            modified.error.payload);
          return throwError(res)
        })
      );
  }

  public getAllChecksheets(wsCallBack: WsCallback) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    this.http.get(ServiceUrls.getAllChecksheets(token)).subscribe(
      data => {
        var modified = JSON.parse(JSON.stringify(data));
        var res = new WsResponse(
          modified.status.description,
          modified.status.code,
          modified.status.name,
          modified.payload);
        wsCallBack.onSuccess(res, WsType.GET_ALL_CHECKSHEETS);
      },
      error => {
        if (error.status != '') {
          var val = (error as HttpErrorResponse).error;
          var modified = JSON.parse(JSON.stringify(val));
          var res = new WsResponse(
            modified.status.description,
            modified.status.code,
            modified.status.name,
            modified.payload);
          wsCallBack.onFail(res, WsType.GET_ALL_CHECKSHEETS);
        } else {
          //browser related issues
          var res = new WsResponse("Unknown error happened");
          wsCallBack.onFail(res, WsType.GET_ALL_CHECKSHEETS);
        }
      }
    );
  }
}

class Request {
  constructor(private checkSheetName: String, private checkSheetCode: String, private checkSheetDiscipline: String, private checkSheetType: String, private hasText: String) { }
}

class AssignChecksRequest {
  constructor(private checksheetId: String, private checksJson: any, private assetsJson: any, private systemsJson: any) { }
}
