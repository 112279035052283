import { Component, OnInit, EventEmitter } from '@angular/core';
import { WsResponse } from '../../util/ws-response.model';
import { WsType } from '../../util/ws-type';
import { AlertService } from '../../util/alert/alert.service';
import { DocumentTypeService } from '../documenttypetree/documenttype.service';
import { SharedService } from '../shared.service';
import { FilesService } from '../../home/files/files.service';
declare var $: any;

@Component({
	selector: 'app-select-doctype-tree',
	templateUrl: './select-doc-type-tree.component.html',
	styleUrls: ['./select-doc-type-tree.component.css'],
	inputs: ['documentType', 'selectedDocType'],
	outputs: ['documentSelected', 'closeModal']
})
export class SelectDocTypeTreeComponent implements OnInit {

   public documentTypeAttributes: any = [];
   public initial: boolean = true;
   public documentTypeSelected: boolean = true;
   public selectedDocumentTypeId: String = null;
   public file: File = null;

	documentType: string;
	selectedDocType: any
	rowData: any = [];	
	docName: any = [];
	public data_n: any = {
		'data': [
		]
	}
	documentSelected = new EventEmitter<any>()
	closeModal = new EventEmitter<any>()
	sc: any = null

	constructor(private documentTypeService: DocumentTypeService, private alertService: AlertService, private filesService: FilesService ) { }

	ngOnInit() {      
      this.documentTypeService.getDocumentTypes(this);
	}
	

   loadDocumentTypeTree(payload: any) {
		if (payload != null) {
			let selectedFileData = this.filesService.getSelectedFileData();
			let selectedTypeCount = 0;
			let deselect_node = 0;
			this.data_n['data'] = [
			];
			this.data_n["data"].push({ "id": "0", "parent": "#", "text": "Document Types", icon: "fa fa-clipboard", type: "documentType", state: {
            disabled: true}})
			for (let i = 0; i < payload.length; i++) {
				if (selectedFileData != null && selectedFileData != undefined && selectedFileData.documentTypeId == payload[i]["typeId"]) {
					selectedTypeCount = i + 1;
				}
				else if (localStorage.getItem("DocumentType") != null && localStorage.getItem("DocumentType") != undefined && localStorage.getItem("DocumentType") == payload[i]["typeId"]) {
					selectedTypeCount = i + 1;
				}
				if (localStorage.getItem("selectedDocType") != null && localStorage.getItem("selectedDocType") != undefined && localStorage.getItem("selectedDocType") == payload[i]["typeId"]) {
					deselect_node = i + 1;
				}
				this.data_n["data"].push({ "id": payload[i]["typeId"], "parent": payload[i]["parentId"], "text": payload[i]["topicType"], icon: "fa fa-clipboard", type: "documentType" })
			}
			//console.log("this.data_n " + JSON.stringify(this.data_n["data"]))
			$('#documenttypetree').jstree({
				core: {
					themes: {
						variant: 'large'
					},
					data: this.data_n["data"],
					check_callback: true,
					force_text: true
				},
				types: {
					default: {
						// "icon" : "fa fa-file-o "
					}
				},
				plugins: ["contextmenu", "dnd", "search", "state", "types", "wholerow"],
				search: {
					show_only_matches: true,
					show_only_matches_children: true
				}
			});

			if (selectedTypeCount > 0) {
				localStorage.setItem("selectedDocType", this.data_n["data"][selectedTypeCount]["typeId"])
				setTimeout(() => $("#documenttypetree").jstree("select_node", this.data_n["data"][selectedTypeCount]["id"]), 200);
			}

			if (deselect_node > 0 && deselect_node != selectedTypeCount) {
				setTimeout(() => $("#documenttypetree").jstree("deselect_node", this.data_n["data"][deselect_node]["id"]), 200);
			}

			$('#documenttypetree').bind("changed.jstree", (node: any, action: any, selected: any, event: any) => 
			this.doctypeChanged(node, action, selected, event))
			$('#documenttypetree').bind("loaded.jstree",(event: any, data: any) => this.jsTreeLoaded(event, data))
		}
	}


	private jsTreeLoaded(event: any, data: any) {
		$('#documenttypetree').jstree("open_all");
		let jsT = $('#documenttypetree').jstree(true).get_json('#', {flat:true})
		if(this.filesService.getSelectedFileData() != null) {
			let node = jsT.find((i: { id: any; }) => i.id == this.filesService.getSelectedFileData().documentTypeId)
			$('#documenttypetree').jstree(true).select_node([node], true, true)
		} else {
			//do nothing
		}
   }
   
   onSuccess(data: WsResponse, serviceType: WsType): void {
		if (data != null && WsType.DOCUMENT_TYPES_VIEW == serviceType) {
			this.loadDocumentTypeTree(data.payload);
		}
		else if (data != null && WsType.DOCUMENT_TYPE_ATTRIBUTES_VIEW == serviceType) {
			let selectedFileData = this.filesService.getSelectedFileData();
			this.initial = false;
			if (selectedFileData.documentTypeId == localStorage.getItem("selectedDocType")) {
				this.initial = true;
			}
			else if (data.payload != "") {
				for (let i = 0; i < data.payload.length; i++) {
					if (data.payload[i].required == 'yes') {
						this.initial = true;
						break;
					}
				}
			}
			else {

			}
			this.documentTypeSelected = true;
			this.processDocuemntTypeAttributs(data.payload)
		}
	}

	onFail(res: WsResponse, serviceType: WsType): void {
		if (res != null && WsType.DOCUMENT_TYPES_VIEW == serviceType) {
			if (res.statusCode == "600") {
				this.documentTypeService.getDocumentTypes(this);
			} else {
				this.alertService.error(res.statusDescription, false);
			}
		}
		if (res != null && WsType.DOCUMENT_TYPE_ATTRIBUTES_VIEW == serviceType) {
			this.documentTypeSelected = false;
			if (res.statusCode == "600") {
				let selectedFileData = this.filesService.getSelectedFileData();
				this.documentTypeService.getDocumentTypeAttributes(selectedFileData, this.selectedDocumentTypeId, this);
			} else {
				this.alertService.error(res.statusDescription, false);
			}
		}
		if (res != null && WsType.FILE_UPDATE == serviceType) {
			if (res.statusCode == "532") {
				//this.router.navigateByUrl('login');
			}
			else if (res.statusCode == "600") {
				let selectedFileData = this.filesService.getSelectedFileData();
				this.filesService.updateFile(JSON.stringify(this.file), selectedFileData.id, selectedFileData.type, this);
			} else {
				this.alertService.success(res.statusDescription, false);
			}
		}
   }
   
   isInitial() {
		return this.initial;
	}

	
	processDocuemntTypeAttributs(payload: any) {
		if (payload != null) {
			this.documentTypeAttributes = [];
			for (let i = 0; i < payload.length; i++) {
				this.documentTypeAttributes.push({
					'attributeId': payload[i]["attributeId"],
					'attributeName': payload[i]["attributeName"],
					'attributeType': payload[i]["attributeType"],
					'docAttributeValueId': payload[i]["docAttrValueId"],
					'required': payload[i]["required"],
					'value': payload[i]["value"] != null ? payload[i]["value"] : "",
					'initialvalue': payload[i]["value"] != null ? payload[i]["value"] : "",
					'categoryId': payload[i]["categoryId"],
					'items': payload[i]["attributeType"] == 'Component' ? payload[i]["components"] : payload[i]["attributeType"] == 'System' ? payload[i]["systems"] :
						payload[i]["attributeType"] == 'Document' ? payload[i]["documents"] : null,
					'isText': (payload[i]["attributeType"] == 'Number' || payload[i]["attributeType"] == 'Text') ? true : false,
					'systems': payload[i]["systems"],
					'docuents': payload[i]["documents"],
					'components': payload[i]["components"]
				});
			}
		}
   }
   
   private doctypeChanged(node: any, action: any, selected: any, event: any) {		
		// console.log(action)
		if(action.action == 'select_node') {
			this.sc = {
				docName: action.node.text,
				docId: action.node.id
			}
		}
   }
   
	//select category button click event
	public selectDoctype() {
		//$('#documenttypetree').jstree().destroy(true)
		this.documentSelected.emit({
			docName: this.sc.docName,
			docId: this.sc.docId
		})
	}

	public closeButtonClickEvent() {
		//$('#documenttypetree').jstree().destroy(true)
		this.sc = null
		this.selectedDocType = null
		this.closeModal.emit()
	}

}
