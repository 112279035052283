<div class="main-panel pt-0 pb-4" style="overflow: hidden;">
	<div class="container-fluid">
		<div class="col-sm-12 px-0">
			<h3>
				{{ 'DICTIONARY.DC_SIDEBAR_ADD_NEW_WORD' | translate }}
			</h3>
			<hr class="mt-0 mb-3" />
		</div>
		<div class="col-sm-12 px-0">
			<form>
				<div class="form-group">
					<label class="mb-0">{{ 'DICTIONARY_ADD.LANGUAGE' | translate }} :</label>
					<ng-select [active]="languageList" [allowClear]="false" [items]="languageList"
						(selected)="languageSeleted($event)"
						placehoder="{{ 'DICTIONARY_ADD.SELECT_LANGUAGE' | translate }}" name="Language" id="language">
					</ng-select>
				</div>
				<div class="form-group">
					<label class="mb-0">{{ 'DICTIONARY_ADD.WORD' | translate }} :</label>
					<input #newWord id="new_word" type="text" class="form-control form-control-sm" id="word"
						[value]="placeholder">
				</div>
				<div class="form-group">
					<button type="submit" id="word_save" class="btn btn-warning float-right col-sm-2"
						(click)="save()">{{ 'SAVE' | translate}}</button>
				</div>
			</form>
		</div>
	</div>
</div>


<!-- <div class="container-fluid my-2">
	<div class="card-block card-body py-1">
		<form>
			<div class="row">
				<div class="col">
					<div class="form-group mb-2">
						<label for="language" class="mb-0">{{ 'DICTIONARY_ADD.LANGUAGE' | translate }}</label>
						<ng-select [active]="languageList" [allowClear]="false" [items]="languageList"
							(selected)="languageSeleted($event)"
							placehoder="{{ 'DICTIONARY_ADD.SELECT_LANGUAGE' | translate }}" name="Language"
							id="language">
						</ng-select>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col">
					<div class="form-group mb-2">
						<label for="word" class="mb-0">{{ 'DICTIONARY_ADD.WORD' | translate }}</label>
						<input #newWord id="new_word" type="text" class="form-control form-control-sm" id="word"
							[value]="placeholder">
					</div>
				</div>
			</div>
			<div class="row">
				<button type="submit" id="word_save" class="btn btn-warning btn-sm float-left"
					(click)="save()">{{ 'SAVE' | translate}}</button>
				<div class="divider"></div>
				<button type="button" id="word_cancel" class="btn btn-warning btn-sm float-left"
					(click)="cancel()">{{ 'CANCEL' | translate}}</button>
			</div>
		</form>
	</div>
</div> -->