<div class="modal-header py-2">
	<h5 class="modal-title">
		<span class="badge badge-warning mr-2">{{projectName}}</span>
		Tag
	</h5>
	<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="tagAddCancelButtonClick()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<!-- <alert class="floating-alert"></alert> -->
<div class="container-fluid mt-3 mb-4 modal-container">
	<form>
		<div class="row my-2">
			<div class="col-sm-6">
				<div class="form-group mb-2">
					<div class="mb-1">
						<label for="tag_no" class="mb-0 mt-1 col-sm-6 p-0">{{ 'TAG_ADD.TAG_NO' | translate }}
							<div class="badge-required mx-2"></div>
						</label>
						<!--<input type="text" class="form-control form-control-sm" id="addtags_tag_number">-->
						<input type="text" class="form-control form-control-sm" id="addTags_tagNumber"
							name="addTags_tagNumber" [(ngModel)]="tagNumber" (ngModelChange)="altTagchange()">
					</div>
					<div class="mt-3 dropdown-100">
						<label for="facility" class="mb-0 mt-1 col-sm-6 p-0">{{ 'TAG_ADD.FACILITY' | translate }}
							<!--<div class="badge-required mx-2"></div>-->
						</label>
						<!--<input type="text" class="form-control form-control-sm" id="addtags_facility">-->
						<tag-input name="addtags_facility" id="addtags_facility" [(ngModel)]='selectedFacilityList'
							[displayBy]="'facilityName'" [removable]='true'
							secondaryPlaceholder="{{'TAG_ADD.FACILITY_SELECT_PLACEHOLDER' | translate}}" maxItems="1"
							[onlyFromAutocomplete]="true" (onAdd)="selectItemFromSuggestions($event, 'facility')"
							(onRemove)="removeSelectedItem($event, 'facility')" [clearOnBlur]="true">
							<tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="facilityTagInputList"
								[displayBy]="'facilityName'" [limitItemsTo]=10 [appendToBody]="false"></tag-input-dropdown>
						</tag-input>
					</div>
				</div>
			</div>
			<div class="col-sm-6">
				<div class="form-group mb-1">
					<label for="description" class="mb-0 mt-1 col-sm-6 p-0">{{ 'TAG_ADD.DESCRIPTION' | translate }}
						<div class="badge-required mx-2"></div>
					</label>
					<button type="button" class="btn btn-sm float-right my-1 btn-sm-form-dependancy mx-1"
						[ngClass]="{'btn-outline-dark': !showSC, 'btn-dark': showSC}"
						(click)="showSpecialCharacters()">Ω</button>
					<button type="button" class="btn btn-sm float-right my-1 btn-sm-form-dependancy mx-1"
						[ngClass]="{'btn-outline-dark': !showFC, 'btn-dark': showFC}"
						(click)="showFractions()">X/Y</button>
				</div>
				<div *ngIf="showSC">
					<button type="button" class="btn btn-light btn-sm my-1 btn-sm-form-dependancy mx-1"
						id="special_char_1" name="special_char_1" (click)="setSpecialCharacter($event)">@</button>
					<button type="button" class="btn btn-light btn-sm my-1 btn-sm-form-dependancy mx-1"
						id="special_char_2" name="special_char_2" (click)="setSpecialCharacter($event)">©</button>
					<button type="button" class="btn btn-light btn-sm my-1 btn-sm-form-dependancy mx-1"
						id="special_char_3" name="special_char_3" (click)="setSpecialCharacter($event)">Ã</button>
					<button type="button" class="btn btn-light btn-sm my-1 btn-sm-form-dependancy mx-1"
						id="special_char_4" name="special_char_4" (click)="setSpecialCharacter($event)">Ø</button>
					<button type="button" class="btn btn-light btn-sm my-1 btn-sm-form-dependancy mx-1"
						id="special_char_5" name="special_char_5" (click)="setSpecialCharacter($event)">è</button>
					<button type="button" class="btn btn-light btn-sm my-1 btn-sm-form-dependancy mx-1"
						id="special_char_6" name="special_char_6" (click)="setSpecialCharacter($event)">µ</button>
					<button type="button" class="btn btn-light btn-sm my-1 btn-sm-form-dependancy mx-1"
						id="special_char_7" name="special_char_7" (click)="setSpecialCharacter($event)">¶</button>
					<button type="button" class="btn btn-light btn-sm my-1 btn-sm-form-dependancy mx-1"
						id="special_char_8" name="special_char_8" (click)="setSpecialCharacter($event)">æ</button>
					<button type="button" class="btn btn-light btn-sm my-1 btn-sm-form-dependancy mx-1"
						id="special_char_9" name="special_char_9" (click)="setSpecialCharacter($event)">£</button>
					<button type="button" class="btn btn-light btn-sm my-1 btn-sm-form-dependancy mx-1"
						id="special_char_0" name="special_char_0" (click)="setSpecialCharacter($event)">#</button>

					<button type="button" class="close float-right mt-1" aria-label="Close" (click)="hidePanels()">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div *ngIf="showFC">
					<form class="form-inline float-left col-sm-10 py-1">
						<div class="form-group">
							<label for="add_tag_numerator" class="sr-only">Numerator</label>
							<input type="number" class="form-control form-control-sm" id="add_tag_numerator"
								name="add_tag_numerator" [(ngModel)]="numerator" placeholder="Numerator">
						</div>
						<div class="form-group mx-sm-3">
							<label for="add_tag_denominator" class="sr-only">Denominator</label>
							<input type="number" class="form-control form-control-sm" id="add_tag_denominator"
								name="add_tag_denominator" [(ngModel)]="denominator" placeholder="Denominator">
						</div>
						<button type="submit" class="btn btn-primary btn-sm"
							(click)="setNumeratorDenominator()">Ok</button>
					</form>
					<button type="button" class="close float-right mt-1" aria-label="Close" (click)="hidePanels()">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="form-group mb-2">
					<!--<textarea type="text" rows="{{rowCount}}" class="form-control form-control-sm" id="addtags_description"></textarea>-->
					<textarea type="text" #tagDesc (click)="getCaretPos(tagDesc)" rows="{{rowCount}}" 
					class="form-control form-control-sm" id="addtags_description" name="addtags_description" 
					[(ngModel)]="tagDescription" (keyup)="getCaretPos(tagDesc)" (keydown)="getCaretPos(tagDesc)"></textarea>
				</div>
			</div>
		</div>
		<div class="row my-2">
			<div class="col-sm-6 dropdown-100">
				<label for="document" class="mb-0 mt-1">{{ 'TAG_ADD.DOCUMENT' | translate }}</label>
				<!--<input type="text" class="form-control form-control-sm" id="addtags_document">-->
				<tag-input name="addtags_document" id="addtags_document" [(ngModel)]='selectedDocumentList'
					[displayBy]="'documentName'" [removable]='true'
					secondaryPlaceholder="{{'TAG_ADD.DOCUMENT_SELECT_PLACEHOLDER' | translate}}" maxItems="1"
					[onlyFromAutocomplete]="true" (onAdd)="selectItemFromSuggestions($event, 'document')"
					(onRemove)="removeSelectedItem($event, 'document')" [clearOnBlur]="true">
					<tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="documentTagInputList"
						[displayBy]="'documentName'" [limitItemsTo]=10 [appendToBody]="false"></tag-input-dropdown>
				</tag-input>
			</div>
			<!--Systems-->
			<div class="col-sm-6 dropdown-100">
				<label for="system_name" class="mb-0 mt-1 col-sm-6 p-0">{{ 'TAG_ADD.SYSTEM' | translate }}
					<div class="badge-required mx-2"></div>
				</label>
				<!--<input type="text" class="form-control form-control-sm" id="addtags_system">-->
				<tag-input name="addtags_system" id="addtags_system" [(ngModel)]='selectedSystemList'
					[displayBy]="'systemNoandsystemName'" [removable]='true'
					secondaryPlaceholder="{{'TAG_ADD.SYSTEM_SELECT_PLACEHOLDER' | translate}}" maxItems="1"
					[onlyFromAutocomplete]="true" (onAdd)="selectItemFromSuggestions($event, 'system')"
					(onRemove)="removeSelectedItem($event, 'system')" [clearOnBlur]="true">
					<tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="systemTagInputList"
						[displayBy]="'systemNoandsystemName'" [limitItemsTo]=10 [appendToBody]="false"></tag-input-dropdown>
				</tag-input>
			</div>
		</div>
		<div class="row my-2">
			<!--Data Sheet-->
			<div class="col-sm-6 dropdown-100">
				<label for="datasheet" class="mb-0 mt-1">{{ 'TAG_ADD.DATA_SHEET' | translate }}</label>
				<!--<input type="text" class="form-control form-control-sm" id="addtags_datasheet">-->
				<tag-input name="addtags_datasheet" id="addtags_datasheet" [(ngModel)]='selectedDataSheetList'
					[displayBy]="'documentName'" [removable]='true'
					secondaryPlaceholder="{{'TAG_ADD.DATA_SHEET_SELECT_PLACEHOLDER' | translate}}" maxItems="1"
					[onlyFromAutocomplete]="true" (onAdd)="selectItemFromSuggestions($event, 'data_sheet')"
					(onRemove)="removeSelectedItem($event, 'data_sheet')" [clearOnBlur]="true">
					<tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="dataSheetTagInputList"
						[displayBy]="'documentName'" [limitItemsTo]=10 [appendToBody]="false"></tag-input-dropdown>
				</tag-input>
			</div>
			<!--Classification-->
			<div class="col-sm-6 dropdown-100">
				<label for="classification" class="mb-0 mt-1">{{ 'TAG_ADD.CLASSIFICATION' | translate }}</label>
				<!--<input type="text" class="form-control form-control-sm" id="addtags_clasification">-->
				<tag-input name="selected_class" [(ngModel)]='selectedTagClassList' [displayBy]="'className'"
					[removable]='true'
					secondaryPlaceholder="{{'TAG_ADD.CLASSIFICATION_SELECT_PLACEHOLDER' | translate}}" maxItems="1"
					[onlyFromAutocomplete]="true" (onAdd)="selectItemFromSuggestions($event, 'classification')"
					(onRemove)="removeSelectedItem($event, 'classification')" [clearOnBlur]="true">
					<tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="tagClassList"
						[displayBy]="'className'" [limitItemsTo]=10 [appendToBody]="false"></tag-input-dropdown>
				</tag-input>
			</div>
		</div>
		<div class="row my-2">
			<div class="col-sm-6">
				<label for="equipment_description" class="mb-0 mt-1">{{ 'TAG_ADD.EQUIPMENT_DESCRIPTION' | translate }}</label>
				<textarea type="text" class="form-control form-control-sm" id="addtags_equipment_description"
					name="addtags_equipment_description" [(ngModel)]="equipmentDescription"></textarea>
			</div>
			<div class="col-sm-6">
				<label for="system_name" class="mb-0 mt-1">{{ 'TAG_ADD.LOCATION_DESCRIPTION' | translate }}</label>
				<textarea type="text" class="form-control form-control-sm" id="addtags_location_description"
					name="addtags_location_description" [(ngModel)]="locationDescription"></textarea>
			</div>
		</div>
		<!--Equipment Description-->
		<div class="row my-2">
			<div class="col-sm-6" *ngIf="tagAddPreConditions.autoGenerateEquipNo" name="equipment_number">
				<label for="equipment_no" class="mb-0 mt-1">{{ 'TAG_ADD.EQUIPMENT_NUMBER' | translate }}</label>
				<div class="input-group mb-3">
					<div class="input-group-prepend">
						<div class="input-group-text">
							<input type="checkbox" aria-label="Checkbox for following text input" name="add_equipment_number"
							[(checked)]="isEquipmentNoCheckboxSelected" (click)="onCheckBoxCheck($event)" [(ngModel)]="isEquipmentNoCheckboxSelected">
						</div>
					</div>
					<input name="equipment_number_input" type="text" class="form-control" [disabled]="isEquipmentNoCheckboxSelected"
						aria-label="Text input with checkbox" [(ngModel)]="nextAvailableEquipmentNo">
				</div>
			</div>
			<!-- Location Parent -->
			<div class="col-sm-6 dropdown-100">
				<label for="location_parent" class="mb-0 mt-1">{{ 'TAG_ADD.LOCATION_PARENT' | translate }}</label>
				<!--<input type="text" class="form-control form-control-sm" id="addtags_location_parent">-->
				<tag-input name="addtags_location_parent" id="addtags_location_parent"
					[(ngModel)]='selectedLocationParent' [displayBy]="'tagNo'" [removable]='true'
					secondaryPlaceholder="{{'TAG_ADD.LOCATION_PARENT_PLACEHOLDER' | translate}}" maxItems="1"
					[onlyFromAutocomplete]="true" (onAdd)="selectItemFromSuggestions($event, 'location_parent')"
					(onRemove)="removeSelectedItem($event, 'location_parent')"
					(keyup)="filterItems($event.target.value)" [clearOnBlur]="true">
					<tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="filteredLocationParent"
						[displayBy]="'tagNo'" [limitItemsTo]=10 [appendToBody]="false"></tag-input-dropdown>
				</tag-input>
				<button  *ngIf="this.enableMore" type="button" class="btn btn-primary mx-1 float-right" (click)="setMoreLocationParent(locationParentTemplate)">{{
					'TAG_ADD.ALL' | translate }}</button>
			</div>
			<!--CAD No-->
			<div class="col-sm-6">
				<label for="CADNo" class="mb-0 mt-1 col-sm-6 p-0">{{ 'TAG_ADD.CAD_No' | translate }}
					<div class="mx-2"></div>
				</label>
				<input type="text" class="form-control form-control-sm" id="addTags_CADNo"
					name="addTags_CADNo" [(ngModel)]="cadNo">
			</div>
		</div>
		<!--Functional Location-->
		<hr *ngIf="isUsedCmmsTracking"/>
		<div class="row my-2" *ngIf="isUsedCmmsTracking" name="functional_location">
			<div class="col-sm-6">
				<label for="build_function_location" class="mb-0 mt-1">{{ 'TAG_ADD.BUILD_FUNCTIONAL_LOCATION' | translate }}</label>
				<div class="form-row">
					<div class="col-xl-4 col-lg-6"
						*ngFor="let item of tagAddPreConditions.cmmsMaskSpecificationInfo; let i = index">
						<tag-input [ngModelOptions]="{standalone: true}" [ngModel]="addedSuggestionList[i]"
							secondaryPlaceholder="{{'TAG_ADD.TAG_SELECT_PLACEHOLDER' | translate}}" 
							[onlyFromAutocomplete]="true" [maxItems]='1' [(disable)]="enableDisableItems[i]" 
							(keyup)="onTextChange($event.target.value, i)" (onAdd)="onSuggestionSelected($event, i)" [onRemoving]="onRemoving"
							(onRemove)="onSuggestionRemoved($event, i)"	[clearOnBlur]="true"> 
							<tag-input-dropdown [showDropdownIfEmpty]="false" [autocompleteItems]="suggestionList"
								[displayBy]="'altNoAndTagNo'" [limitItemsTo]=10 [appendToBody]="false">
							</tag-input-dropdown>
						</tag-input>			
						<button *ngIf="(this.enableMoreFunctional && !enable[i])" type="button" class="btn btn-primary button-size float-right" (click)="setMoreFunctionalLocation(functionalLocationTemplate)">{{
							'TAG_ADD.ALL' | translate }}</button>			
					</div>					
				</div>
			</div>
			<div class="col-sm-6">
				<label for="functional_locaiton" class="mb-0 mt-1">{{ 'TAG_ADD.FUNCTIONAL_LOCATION' | translate }}</label>
				<textarea type="text" class="form-control form-control-sm" id="addtags_functional_location"
					name="addtags_functional_location" [(ngModel)]="calculatedFunctionalLocationValue"
					readonly="true"></textarea>
			</div>
		</div>
		<div class="row my-2" *ngIf="isUsedCmmsTracking" name="alternate_tag_no">
			<div class="col-sm-6">
				<label for="alternate_tag_no" class="mb-0 mt-1">{{ 'TAG_ADD.ALTERNATE_TAG_NO' | translate }}</label>
				<input type="text" class="form-control form-control-sm" id="addtags_alternate_tag_no"
					name="addtags_alternate_tag_no" [(ngModel)]="alternateTagNoValue" (ngModelChange)="altTagNoChange()">
			</div>	
		</div>
		<hr *ngIf="isUsedCmmsTracking"/>
		<div class="row my-2">
			<div class="col-sm-6 dropdown-100">
				<label for="failure_class" class="mb-0 mt-1">{{ 'TAG_ADD.FAILURE_CLASS' | translate }}</label>
				<!--<input type="text" class="form-control form-control-sm" id="addtags_failure_class">-->
				<tag-input id="addtags_failure_class" name="addtags_failure_class" [(ngModel)]='selectedFailureClasses'
					[displayBy]="'attribute'" [removable]='true'
					secondaryPlaceholder="{{'TAG_ADD.FAILURE_CLASS_PLACEHOLDER' | translate}}" maxItems="1"
					[onlyFromAutocomplete]="true" (onAdd)="selectItemFromSuggestions($event, 'failure_class')"
					(onRemove)="removeSelectedItem($event, 'failure_class')" [clearOnBlur]="true">
					<tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="failureClassList"
						[displayBy]="'attribute'" [limitItemsTo]=10 [appendToBody]="false"></tag-input-dropdown>
				</tag-input>
			</div>
			<div class="col-sm-6 dropdown-100">
					<label for="criticality" class="mb-0 mt-1">{{ 'TAG_ADD.CRITICALITY' | translate }}</label>
					<!--<input type="text" class="form-control form-control-sm" id="addtags_criticality">-->
					<tag-input id="addtags_criticality" name="addtags_criticality" [(ngModel)]='selectedCriticalityList'
						[displayBy]="'attribute'" [removable]='true'
						secondaryPlaceholder="{{'TAG_ADD.CRITICALITY_PLACEHOLDER' | translate}}" maxItems="1"
						[onlyFromAutocomplete]="true" (onAdd)="selectItemFromSuggestions($event, 'criticality')"
						(onRemove)="removeSelectedItem($event, 'criticality')" [clearOnBlur]="true">
						<tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="criticalityList"
							[displayBy]="'attribute'" [limitItemsTo]=10 [appendToBody]="false"></tag-input-dropdown>
					</tag-input>
				</div>
		</div>
		<div class="row my-2">
			<div class="col-sm-6 dropdown-100">
				<label for="safety_critical" class="mb-0 mt-1">{{ 'TAG_ADD.SAFETY_CRITICAL' | translate }}</label>
				<!--<input type="text" class="form-control form-control-sm" id="addtags_safety_critical">-->
				<tag-input id="addtags_safety_critical" name="addtags_safety_critical"
					[(ngModel)]='selectedSafetyCriticalityList' [displayBy]="'attribute'" [removable]='true'
					secondaryPlaceholder="{{'TAG_ADD.SAFETY_CRITICAL_PLACEHOLDER' | translate}}" maxItems="1"
					[onlyFromAutocomplete]="true" (onAdd)="selectItemFromSuggestions($event, 'criticality')"
					(onRemove)="removeSelectedItem($event, 'criticality')" [clearOnBlur]="true">
					<tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="safetyCriticalityList"
						[displayBy]="'attribute'" [limitItemsTo]=10 [appendToBody]="false"></tag-input-dropdown>
				</tag-input>
			</div>
			<div class="col-sm-6 dropdown-100">
					<label for="discipline" class="mb-0 mt-1">{{ 'TAG_ADD.DISCIPLINE' | translate }}</label>
					<!--<input type="text" class="form-control form-control-sm" id="addtags_discipline">-->
					<tag-input id="addtags_discipline" name="addtags_discipline" [(ngModel)]='selectedDisciplineList'
						[displayBy]="'attribute'" [removable]='true'
						secondaryPlaceholder="{{'TAG_ADD.DISCIPLINE_PLACEHOLDER' | translate}}" maxItems="1"
						[onlyFromAutocomplete]="true" (onAdd)="selectItemFromSuggestions($event, 'discipline')"
						(onRemove)="removeSelectedItem($event, 'discipline')" [clearOnBlur]="true">
						<tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="disciplineList"
							[displayBy]="'attribute'" [limitItemsTo]=10 [appendToBody]="false"></tag-input-dropdown>
					</tag-input>
				</div>
		</div>
		<div class="row my-2">
			<div class="col-sm-6 dropdown-100">
				<label for="equipment_type" class="mb-0 mt-1">{{ 'TAG_ADD.EQUIPMENT_TYPE' | translate }}</label>
				<!--<input type="text" class="form-control form-control-sm" id="addtags_equipment_type">-->
				<tag-input id="addtags_equipment_type" name="addtags_equipment_type"
					[(ngModel)]='selectedEquipmentTypeList' [displayBy]="'attribute'" [removable]='true'
					secondaryPlaceholder="{{'TAG_ADD.EQUIPMENT_TYPE_PLACEHOLDER' | translate}}" maxItems="1"
					[onlyFromAutocomplete]="true" (onAdd)="selectItemFromSuggestions($event, 'equipment_type')"
					(onRemove)="removeSelectedItem($event, 'equipment_type')" [clearOnBlur]="true">
					<tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="equipmentTypeList"
						[displayBy]="'attribute'" [limitItemsTo]=10 [appendToBody]="false"></tag-input-dropdown>
				</tag-input>
			</div>
		</div>
		<hr />
		<div class="row my-2">
			<div class="col-sm-6">
				<div class="checkbox float-left">
					<label class="customcheck">
						<p class="h6 mb-0">{{ 'TAG_ADD.USED_IN_COMMISSIONING' | translate }}</p>
						<input id="commissioning_checkbox" type="checkbox" name="checkbox" [(checked)]="usedInCMMSTrac"
							[(ngModel)]="usedInCMMSTrac">
						<span class="checkmark"></span>
					</label>
				</div>
			</div>
		</div>
		<div class="row my-2">
			<div class="col-sm-6 dropdown-100">
				<label for="asset_groups" class="mb-0 mt-1 col-sm-6 p-0">{{ 'TAG_ADD.ASSET_GROUPS' | translate }}
					<div class="badge-required mx-2"></div>
				</label>
				<!--<input type="text" class="form-control form-control-sm" id="addtags_asset_groups" name="addtags_asset_groups" [(disabled)]="!usedInCMMSTrac">-->
				<tag-input id="addtags_asset_groups" name="addtags_asset_groups" [(ngModel)]='selectedAssetGroupsList' [displayBy]="'assetGroupName'" [removable]='true'
					secondaryPlaceholder="{{'TAG_ADD.ASSET_GROUP_PLACEHOLDER' | translate}}" maxItems="1" [ngClass]="{'disableTagInput': !usedInCMMSTrac}"
					[onlyFromAutocomplete]="true" (onAdd)="selectItemFromSuggestions($event, 'asset_group')"
					(onRemove)="removeSelectedItem($event, 'asset_group')" [clearOnBlur]="true">
					<tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="assetGroupsList"
						[displayBy]="'assetGroupName'" [limitItemsTo]=10  [appendToBody]="false"></tag-input-dropdown>
				</tag-input>
			</div>
			<div class="col-sm-6 dropdown-100">
				<label for="asset_parent" class="mb-0 mt-1">{{ 'TAG_ADD.ASSET_PARENT' | translate }}</label>
				<!--<input type="text" class="form-control form-control-sm" id="addtags_asset_parent" name="addtags_asset_parent" [(disabled)]="!usedInCMMSTrac">-->
				<tag-input id="addtags_asset_parent" name="addtags_asset_parent" [(ngModel)]='selectedParentList' [displayBy]="'tagNoandtagDescription'" [removable]='true'
					secondaryPlaceholder="{{'TAG_ADD.ASSET_PARENT_PLACEHOLDER' | translate}}" maxItems="1" [ngClass]="{'disableTagInput': !usedInCMMSTrac}"
					[onlyFromAutocomplete]="true" (onAdd)="selectItemFromSuggestions($event, 'asset_parent')"
					(onRemove)="removeSelectedItem($event, 'asset_parent')" [clearOnBlur]="true">
					<tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="assetParentList"
						[displayBy]="'tagNoandtagDescription'" [limitItemsTo]=10 [appendToBody]="false"></tag-input-dropdown>
				</tag-input>
			</div>
		</div>
		<div class="row my-2">
			<div class="col-sm-6 dropdown-100">
				<label for="function_code" class="mb-0 mt-1">{{ 'TAG_ADD.FUNCTION_CODE' | translate }}</label>
				<!--<input type="text" class="form-control form-control-sm" id="addtags_function_code" name="addtags_function_code" [(disabled)]="!usedInCMMSTrac">-->
				<tag-input id="addtags_function_code" name="addtags_function_code" [ngClass]="{'disableTagInput': !usedInCMMSTrac}"
					[(ngModel)]='selectedFunctionCodeList' [displayBy]="'functionCode'"
					[removable]='true' secondaryPlaceholder="{{'TAG_ADD.FUNCTION_CODE_PLACEHOLDER' | translate}}"
					maxItems="1" [onlyFromAutocomplete]="true"
					(onAdd)="selectItemFromSuggestions($event, 'function_code')"
					(onRemove)="removeSelectedItem($event, 'function_code')" [clearOnBlur]="true">
					<tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="functionCodeList"
						[displayBy]="'functionCode'" [limitItemsTo]=10 [appendToBody]="false"></tag-input-dropdown>
				</tag-input>
			</div>
			<div class="col-sm-6 dropdown-100">
				<label for="vendor" class="mb-0 mt-1">{{ 'TAG_ADD.VENDOR' | translate }}</label>
				<!--<input type="text" class="form-control form-control-sm" id="addtags_vendor" name="addtags_vendor" [(disabled)]="!usedInCMMSTrac">-->
				<tag-input id="addtags_vendor" name="addtags_vendor" [(ngModel)]='selectedVendorList' [ngClass]="{'disableTagInput': !usedInCMMSTrac}"
					[displayBy]="'vendorName'" [removable]='true'
					secondaryPlaceholder="{{'TAG_ADD.VENDOR_PLACEHOLDER' | translate}}" maxItems="1"
					[onlyFromAutocomplete]="true" (onAdd)="selectItemFromSuggestions($event, 'vendor')"
					(onRemove)="removeSelectedItem($event, 'vendor')" [clearOnBlur]="true">
					<tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="vendorList"
						[displayBy]="'vendorName'" [limitItemsTo]=10 [appendToBody]="false"></tag-input-dropdown>
				</tag-input>
			</div>
		</div>
		<div class="row my-2">
			<div class="col-sm-6 ">
				<label for="irn_target_completion_date" class="mb-0 mt-1">{{ 'TAG_ADD.IRN_TARGET_COMPLETION_DATE' | translate }}</label>
				<!--<input type="text" class="form-control form-control-sm" id="addtags_function_code" name="addtags_function_code" [(disabled)]="!usedInCMMSTrac">-->
				
				<input type="text"  [ngClass]="{'disabled': !usedInCMMSTrac}" placeholder="Select IRN target completion date" class="form-control" name="irn_target_completion_date"
                     [(ngModel)]="irnTargetCompletionDate" [bsConfig]="bsConfig" placement="bottom" bsDatepicker>
			</div>
			<div class="col-sm-6 ">
				<label for="otn_target_completion_date" class="mb-0 mt-1">{{ 'TAG_ADD.OTN_TARGET_COMPLETION_DATE' | translate }}</label>
				<!--<input type="text" class="form-control form-control-sm" id="addtags_vendor" name="addtags_vendor" [(disabled)]="!usedInCMMSTrac">-->
				<input type="text" [ngClass]="{'disabled': !usedInCMMSTrac}"  placeholder="Select OTN target completion date" class="form-control" name="otn_target_completion_date"
                     [(ngModel)]="otnTargetCompletionDate" [bsConfig]="bsConfig" placement="bottom" bsDatepicker>
			</div>
		</div>
		<hr />

		<!--Assets attributes-->
		<div class="row my-2">
			<div class="col-sm-12">
				<label class="mb-1 h6">{{ 'TAG_ADD.ASSET_ATTRIBUTES' | translate }}</label>
			</div>
			<div class="col-sm-12">
                <div class="card">
                    <div class="class-body p-2">
                        <p class="text-muted"
                            *ngIf="assetAttributeList == undefined || assetAttributeList.length == 0">No asset
                            attributes</p>
                        <div *ngFor="let attribute of assetAttributeList; let i = index">
                            <div *ngIf="attribute.attributeType == 'Text'">
                                <div class="col-sm-6 float-left pb-1">
                                    <label class="mb-0 mt-1 col-sm-6 p-0">{{attribute.attributeName}}
                                        <div *ngIf="attribute.required.toLowerCase()=='yes'" class="badge-required mx-2"></div>
                                    </label>
                                </div>								
                                <div class="col-sm-6 float-left pb-1">
                                    <input value="{{attribute.attributeValue}}"
                                        id="{{attribute.assetAttributeId}}"
                                        name="{{attribute.assetAttributeId}}" 
                                        [disabled]="!usedInCMMSTrac" type="text" class="form-control form-control-sm"
                                        (change)="getValueChanges($event, 'asset_attribute_text', attribute.assetAttributeId, i)">
                                </div>
                            </div>
                            <!-- Non text asset attributes -->
                            <div *ngIf="attribute.attributeType != 'Text'">
                                <div class="col-sm-6 float-left pb-1">
                                    <label class="mb-0 mt-1 col-sm-6 p-0">{{attribute.attributeName}}
                                        <div *ngIf="attribute.required.toLowerCase()=='yes'" class="badge-required mx-2"></div>
                                    </label>
                                </div>
                                <div class="col-sm-6 float-left pb-1">
                                    <!--asset list-->
                                    <app-attribute-select *ngIf="attribute.attributeType == 'Asset'"
                                        [viewItems]="assetAttributeSelectionList[i].assetListNgSelect"
                                        id="asset_{{attribute.assetAttributeId}}" [disabled]="!usedInCMMSTrac"
                                        (emitSelected)="getValueChanges($event, 'asset_attribute_asset', attribute.assetAttributeId, i)">
                                    </app-attribute-select>
                                    <!--value list-->
                                    <app-attribute-select *ngIf="attribute.attributeType == 'ValueList'"
                                        [viewItems]="assetAttributeSelectionList[i].valueListNgSelect"
                                        id="valuelist_{{attribute.assetAttributeId}}"
                                        [disabled]="!usedInCMMSTrac"
                                        (emitSelected)="getValueChanges($event, 'asset_attribute_value', attribute.assetAttributeId, i)">
                                    </app-attribute-select>
                                </div>
                            </div>
                        </div>
                    </div>
				</div>
			</div>
		</div>
		<hr />

		<!--Tag Attributes-->
		<div class="row my-2">
			<div class="col-sm-12">
				<label class="mb-1 h6">{{ 'TAG_ADD.TAG_ATTRIBUTES' | translate }}</label>
			</div>
			<div class="col-sm-12">
				<div class="card">
                    <div class="class-body p-2">
                        <!--No Tag Attributes Message-->
                        <p class="text-muted" *ngIf="tagAttributes == undefined || tagAttributes.length == 0"
                            name="no_tag_attribute_label">No tag attributes</p>
                        <!--Tag Attribute List-->
                        <div *ngFor="let attribute of tagAttributes; let t = index">

							<table><tr>
								<th></th>
								<th></th>
								<td  style="width:50%;vertical-align: middle">
									<label>{{attribute.attributeName}}
										<div *ngIf="attribute.required=='yes'" class="badge-required mx-2"></div>
                                    </label>

								</td>
								<td style="width:50%;vertical-align: middle">
									<input *ngIf="attribute.attributeType == 'Text'" value="{{attribute.value}}"
                                        id="{{attribute.attributeId}}" type="text" class="form-control"
                                        (change)="getValueChanges($event, 'text', attribute.attributeId, attribute.tagClsSpecId)">

									<input *ngIf="attribute.attributeType == 'Number'" value="{{attribute.value}}"
                                        id="{{attribute.attributeId}}" type="text" (keypress)="checkIfNumber($event)" class="form-control"
                                        (change)="getValueChanges($event, 'text', attribute.attributeId, attribute.tagClsSpecId)">

									<input *ngIf="attribute.attributeType == 'Date'" value="{{attribute.value}}"
										id="{{attribute.attributeId}}" type="date" class="form-control"
										(change)="getValueChanges($event, 'date', attribute.attributeId, attribute.tagClsSpecId)">

									<div *ngIf="attribute.attributeType != 'Text' && attribute.attributeType != 'Number' && attribute.attributeType != 'Date'">

									 <!--Documents-->
									<app-attribute-select
                                        *ngIf="attribute.attributeType == 'Document'"
                                        [viewItems]="documentListForNgSelect"
                                        id="sysdoc_{{attribute.attributeId}}"
                                        (emitSelected)="getValueChanges($event, 'document', attribute.attributeId, attribute.tagClsSpecId)">
                                    </app-attribute-select>

                                    <!--Systems-->
                                    <app-attribute-select
                                        *ngIf="attribute.attributeType == 'System'"
                                        [viewItems]="systemListForNgSelect"
                                        id="sysatt_{{attribute.attributeId}}"
                                        (emitSelected)="getValueChanges($event, 'system', attribute.attributeId, attribute.tagClsSpecId)">
                                    </app-attribute-select>

                                    <!--Components-->
                                    <app-attribute-select
                                        *ngIf="attribute.attributeType == 'Component'"
                                        [viewItems]="getComponentListByCategory(attribute.categoryId)"
                                        id="syscom_{{attribute.attributeId}}"
                                        (emitSelected)="getValueChanges($event, 'component', attribute.attributeId, attribute.tagClsSpecId)">
                                    </app-attribute-select>

									</div>

								</td>
								</tr></table>
                            <!-- <div *ngIf="attribute.attributeType == 'Text'">
                                <div class="col-sm-4 float-left pb-1">
                                    <label class="mb-0 mt-1 col-sm-6 p-0 pl-4">{{attribute.attributeName}}
                                        <div *ngIf="attribute.required=='yes'" class="badge-required mx-2"></div>
                                    </label>
                                </div>
                                <div class="col-sm-6 float-left pb-1">
                                    <input value="{{attribute.value}}"
                                        id="{{attribute.attributeId}}" type="text" class="form-control form-control-sm"
                                        (change)="getValueChanges($event, 'text', attribute.attributeId, attribute.tagClsSpecId)">
                                </div>
                            </div>
                            <div *ngIf="attribute.attributeType == 'Number'">
                                <div class="col-sm-4 float-left pb-1">
                                    <label class="mb-0 mt-1 col-sm-6 p-0 pl-4">{{attribute.attributeName}}
                                        <div *ngIf="attribute.required=='yes'" class="badge-required mx-2"></div>
                                    </label>
                                </div>
                                <div class="col-sm-6 float-left pb-1">
                                    <input value="{{attribute.value}}"
                                        id="{{attribute.attributeId}}" type="text" (keypress)="checkIfNumber($event)" class="form-control form-control-sm"
                                        (change)="getValueChanges($event, 'text', attribute.attributeId, attribute.tagClsSpecId)">
                                </div>
                            </div>
                            <div *ngIf="attribute.attributeType != 'Text' && attribute.attributeType != 'Number'">
                                <div class="col-sm-4 float-left pb-1">
                                            <label class="mb-0 mt-1 col-sm-6 p-0 pl-4">{{attribute.attributeName}}
                                                <div *ngIf="attribute.required=='yes'" class="badge-required mx-2"></div>
                                            </label>
                                </div>
                                <div class="col-sm-6 float-left pb-1">
                                    <!--Documents-->
                                    <!-- <app-attribute-select
                                        *ngIf="attribute.attributeType == 'Document'"
                                        [viewItems]="documentListForNgSelect"
                                        id="sysdoc_{{attribute.attributeId}}"
                                        (emitSelected)="getValueChanges($event, 'document', attribute.attributeId, attribute.tagClsSpecId)">
                                    </app-attribute-select> -->

                                    <!--Systems-->
                                    <!-- <app-attribute-select
                                        *ngIf="attribute.attributeType == 'System'"
                                        [viewItems]="systemListForNgSelect"
                                        id="sysatt_{{attribute.attributeId}}"
                                        (emitSelected)="getValueChanges($event, 'system', attribute.attributeId, attribute.tagClsSpecId)">
                                    </app-attribute-select> -->

                                    <!--Components-->
                                    <!-- <app-attribute-select
                                        *ngIf="attribute.attributeType == 'Component'"
                                        [viewItems]="getComponentListByCategory(attribute.categoryId)"
                                        id="syscom_{{attribute.attributeId}}"
                                        (emitSelected)="getValueChanges($event, 'component', attribute.attributeId, attribute.tagClsSpecId)">
                                    </app-attribute-select> -->

                                    <!--Date-->
                                    <!-- <input *ngIf="attribute.attributeType == 'Date'" id="{{attribute.attributeId}}" value="{{attribute.value}}" 
										type="date" (change)="getValueChanges($event, 'date', attribute.attributeId, attribute.tagClsSpecId)"
										class="form-control form-control-sm"/>
                                </div>
                            </div> -->
                        </div>
                    </div>
				</div>
			</div>
		</div>
		<!-- <div class="row my-4">
			<div class="col-sm-12 float-right">
				<button type="button" class="btn btn-primary mx-1 float-right" (click)="tagAddSaveButtonClick()">{{
					'TAG_ADD.SAVE_BUTTON_TEXT' | translate }}</button>
				<button type="button" class="btn btn-secondary mx-1 float-right" (click)="tagAddCancelButtonClick()">{{
					'TAG_ADD.CANCEL_BUTTON_TEXT' | translate }}</button>
			</div>
		</div> -->
	</form>
</div>
<div class="modal-footer">
	<label class="form-check-label">
		<input class="form-check-input" id="remember_data_checkbox" type="checkbox" name="checkbox" [(checked)]="isRememberData" [(ngModel)]="isRememberData">
		{{ 'TAG_ADD.REMEMBER_DATA_TEXT' | translate}}
	</label>
	<button type="button" id="addtag_cancel" class="btn btn-secondary btn-sm" (click)="tagAddCancelButtonClick()">
		{{ 'TAG_ADD.CANCEL_BUTTON_TEXT' | translate}}
	</button>
	<button type="submit" id="addtag_save" class="btn btn-primary btn-sm" (click)="tagAddSaveButtonClick()">
		{{ 'TAG_ADD.SAVE_BUTTON_TEXT' | translate}}
	</button>

</div>

<ng-template #specialCharacter>
	<div class="container-fluid">
		<div class="col-sm-12">
			<button type="button" class="btn btn-outline-dark">Dark</button>
		</div>
		<div class="col-sm-12">
			<button type="button" class="btn btn-primary float-right">OK</button>
		</div>
	</div>
</ng-template>

<!--Location Parent Template-->
<ng-template #locationParentTemplate>
	<div class="modal-header">
		<h5 class="modal-title">{{ 'TAG_ADD.TAG_LIST' | translate }}</h5>
		<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="locationParentCancel()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<form ngNativeValidate>
		<div class="modal-body">			
				<ag-grid-angular #agGrid style="height: 550px;" class="ag-theme-balham"
					 [enableFilter]="true" [rowData]="rowData" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }" [columnDefs]="columnDefs"
					[rowSelection]="'single'" [suppressRowDeselection]="true" [rowMultiSelectWithClick]="false" (rowClicked)="onRowClick($event)"
					[components]="components"  [pagination]="true" [paginationPageSize]="20" [getRowNodeId]="getRowNodeId" [rowSelection]="rowSelection"
					(rowSelected)="onRowSelected($event)" [defaultColDef]="defaultColDef">
				</ag-grid-angular>						
		</div>
		<div class="modal-footer">
			<button type="submit" class="btn btn-primary btn-sm"
				(click)="okMoreLocationParent()">{{ 'TAG_ADD.OK' | translate }}</button>
		</div>
	</form>
</ng-template>

<!--Functional Location Template-->
<ng-template #functionalLocationTemplate>
	<div class="modal-header">
		<h5 class="modal-title">{{ 'TAG_ADD.TAG_LIST' | translate }}</h5>
		<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="fucnctionalLocationCancel()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<form ngNativeValidate>
		<div class="modal-body">			
				<ag-grid-angular #agGrid style="height: 550px;" class="ag-theme-balham" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }"
					 [enableFilter]="true" [rowData]="rowDataFunctionalLocation" [columnDefs]="columnDefsFunctionalLocation"
					[rowSelection]="'single'" [suppressRowDeselection]="true" [rowMultiSelectWithClick]="false" (rowClicked)="onRowClickFunctional($event)"
					[components]="components"  [pagination]="true" [paginationPageSize]="15" [getRowNodeId]="getRowNodeId" [rowSelection]="rowSelection"
					(rowSelected)="onRowSelected($event)" [defaultColDef]="defaultColDef">
				</ag-grid-angular>						
		</div>
		<div class="modal-footer">
			<button type="submit" class="btn btn-primary btn-sm"
				(click)="okMoreFunctionLocation()">{{ 'TAG_ADD.OK' | translate }}</button>
		</div>
	</form>
</ng-template>
