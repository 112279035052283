<div>
  <div class="modal-header py-2">
    <h5 class="modal-title">
      {{ 'CS_ASSIGN_CHECKS.TITLE' | translate }}
    </h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="container-fluid modal-container">
    <div class="row my-2">
      <div class="col-sm-6 border-right">
        <div class="pb-4">
          <div class='float-left' style="font-size: large;">{{treeTitle}}</div>
          <div class="float-right">
            <button type="button" class="btn btn-outline-dark btn-sm ml-1 mr-1 border-0"
              tooltip="{{ 'CS_ASSIGN_CHECKS.ADD_GROUP' | translate }}" (click)="addNewGroup()">
              <i class="fas fa-plus" aria-hidden="true"></i>
            </button>
            <button type="button" class="btn btn-outline-dark btn-sm ml-1 mr-1 border-0" [disabled]='renameDisable'
              (click)="renameNode()" tooltip="{{ 'CS_ASSIGN_CHECKS.RENAME' | translate }}">
              <i class="fas fa-edit" aria-hidden="true"></i>
            </button>
            <button type="button" class="btn btn-outline-dark btn-sm ml-1 mr-1 border-0" [disabled]='deleteDisable'
              (click)="removeNode()" tooltip="{{ 'CS_ASSIGN_CHECKS.DELETE' | translate }}">
              <i class="fas fa-trash-alt" aria-hidden="true"></i>
            </button>
          </div>
        </div>
        <div id="checkstree" class="mt-2 mb-3 mx-1 pt-3 ftdiv-overflow" style="width: 100%;"
          [style.height.px]="treeheight">
        </div>
      </div>
      <div class="col-sm-6">
        <div class="row">
          <div class="col-sm-12 mb-2">
            <symbiosis-search [comp]="'searchchk'" id="chk_search" (searchFunc)="searchFunc($event)"></symbiosis-search>
          </div>
        </div>
        <div class="row">
          <div id="filteredtree" class="my-2 mx-1 ftdiv-overflow" style="width: 100%;" [style.height.px]="treeheight">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-primary btn-sm float-left" (click)="saveAssignChecks()">{{
      'CS_ASSIGN_CHECKS.SAVE' |
      translate}}</button>
    <button type="button" class="btn btn-secondary btn-sm float-left" (click)="cancel()">{{ 'CANCEL' |
      translate}}</button>
  </div>
</div>

<ng-template #closeConfirmModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ 'CLOSE' | translate}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">If you close without saving you will loose data. Do you want to close?</div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="confirmModal()">{{ 'OK' | translate}}</button>
    <button type="button" class="btn btn-secondary" (click)="closeModal()">{{ 'CANCEL' | translate}}</button>
  </div>
</ng-template>