import { UserVariable } from 'src/app/util/common/user-variable';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { CommonUtil } from 'src/app/util/common/common-util';
import { catchError, map } from 'rxjs/operators';
import { WsResponse } from 'src/app/util/ws-response.model';
import { throwError } from 'rxjs';
import { ServiceUrls } from 'src/app/util/service-urls';
import { WsCallback } from 'src/app/util/ws-callback.interface';
import { WsType } from 'src/app/util/ws-type';

@Injectable({
    providedIn: 'root'
})

export class ManageWorkflowTemplatesService {

    constructor(private http: HttpClient, private commonUtil: CommonUtil) {

    }

    getAllWorkflowTemplates(wsCallBack: WsCallback) {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateLogin(token)) {
            return;
        }
        const options = { params: new HttpParams().set("token", token) }
        this.http.get(ServiceUrls.GET_ALL_WORKFLOW_TEMPLATES, options).subscribe(response => {
            var modified = JSON.parse(JSON.stringify(response));
            var res = new WsResponse(
                modified.status.code,
                modified.status.name,
                modified.status.description,
                modified.payload);
                wsCallBack.onSuccess(res, WsType.GET_ALL_WORKFLOW_TEMPLATES);
        }, error => {
            var modified = JSON.parse(JSON.stringify(error.error));
            var res = new WsResponse(
                modified.status.code,
                modified.status.name,
                modified.status.description,
                modified.payload);
                wsCallBack.onFail(res, WsType.GET_ALL_WORKFLOW_TEMPLATES);
        });
    }

    addWorkflowTemplate(wfTemplate: any, wsCallBack: WsCallback) {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateLogin(token)) {
            return;
        }

        this.http.post(ServiceUrls.addWorkflowTemplate(token), wfTemplate).subscribe(data => {
            var modified = JSON.parse(JSON.stringify(data));
            var res = new WsResponse(
                modified.status.description,
                modified.status.code,
                modified.status.name,
                modified.payload);
            wsCallBack.onSuccess(res, WsType.ADD_WORKFLOW_TEMPLATE);
        },
            error => {
                var modified = JSON.parse(JSON.stringify(error.error));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                wsCallBack.onFail(res, WsType.ADD_WORKFLOW_TEMPLATE);
            })
    }

    getWorkflowTemplateById(wfTemplateId: string, wsCallBack: WsCallback) {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateLogin(token)) {
            return;
        }

        this.http.get(ServiceUrls.getWorkflowTemplateById(token, wfTemplateId)).subscribe(data => {
            var modified = JSON.parse(JSON.stringify(data));
            var res = new WsResponse(
                modified.status.description,
                modified.status.code,
                modified.status.name,
                modified.payload);
            wsCallBack.onSuccess(res, WsType.GET_WORKFLOW_TEMPLATE_BY_ID);
        },
            error => {
                var modified = JSON.parse(JSON.stringify(error.error));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                wsCallBack.onFail(res, WsType.GET_WORKFLOW_TEMPLATE_BY_ID);
            })
    }

    updateWorkflowTemplate(wfTemplate: any, wsCallBack: WsCallback) {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateLogin(token)) {
            return;
        }

        this.http.post(ServiceUrls.updateWorkflowTemplate(token), wfTemplate).subscribe(data => {
            var modified = JSON.parse(JSON.stringify(data));
            var res = new WsResponse(
                modified.status.description,
                modified.status.code,
                modified.status.name,
                modified.payload);
            wsCallBack.onSuccess(res, WsType.UPDATE_WORKFLOW_TEMPLATE);
        },
            error => {
                var modified = JSON.parse(JSON.stringify(error.error));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                wsCallBack.onFail(res, WsType.UPDATE_WORKFLOW_TEMPLATE);
            })
    }

    getItemTypes(wsCallBack: WsCallback) {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateLogin(token)) {
            return;
        }

        this.http.get(ServiceUrls.getItemTypes(token)).subscribe(data => {
            var modified = JSON.parse(JSON.stringify(data));
            var res = new WsResponse(
                modified.status.description,
                modified.status.code,
                modified.status.name,
                modified.payload);
            wsCallBack.onSuccess(res, WsType.GET_ITEM_TYPES);
        },
            error => {
                var modified = JSON.parse(JSON.stringify(error.error));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                wsCallBack.onFail(res, WsType.GET_ITEM_TYPES);
            })
    }
}