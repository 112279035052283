import { Component, OnInit, ViewChild, ElementRef, QueryList, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DocumentsService } from './../documents.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SharedService } from '../../../util/shared.service';
import { HistoryService } from '../../history/history.service';
import { Constants, UI_LAYOUT, LocalStorageConst } from '../../../util/constants';
import { AlertService } from '../../../util/alert/alert.service';
import { DependenciesmodalComponent } from '../../../util/dependenciesmodal/dependenciesmodal.component';
import { LocalStorage } from '../../../util/localstorage.service';
import { WsCallback } from '../../../util/ws-callback.interface';
import { WsType } from '../../../util/ws-type';
import { WsResponse } from '../../../util/ws-response.model';
import { DocumenttypetreeComponent } from '../../../util/documenttypetree/documenttypetree.component';
import { DocumentTypeService } from '../../../util/documenttypetree/documenttype.service';
import { TreeListener } from '../../../util/documenttypetree/tree-listener.interface';
import { FilesService } from '../../../home/files/files.service';
import { LoadingService } from '../../../util/loading/loading.service';
import { ActivatedRoute } from '@angular/router';
import { PDFViewModalComponent } from '../pdfviewmodal.component'
import { GridOptions } from "@ag-grid-enterprise/all-modules";

@Component({
   selector: 'app-addeditdocuments',
   templateUrl: './addeditdocuments.component.html',
   styleUrls: ['./addeditdocuments.component.css']
})
export class AddeditdocumentsComponent implements OnInit, TreeListener {

   public itemId: string;

   private modalRef: BsModalRef;
   public dataLoad: boolean = false;
   public isEdit: boolean = false;
	private dependencyType: string;
   public document: any = null;
   public documentId: any = null;

   public idForSelectedFacility: String;
   public facilityList: Array<any> = []
   public selectedFacility: Array<any> = [{ 'id': '', 'text': 'Select' }]
   public selectedFacilityIdValue: any;
   public selectedFacilityName: any;

   public editFacilityList: Array<any> = [];
   public selectedFacilityEdit: Array<any>;
   public selectedFacilityIdValueEdit: any;
   public selectedFacilityNameEdit: any;

   public documentType: string;
   private documentTypeId: string;
   private attachFile: string;
   private treeBsModalRef: BsModalRef;
   public selectedFolder: any;
   public reusable: boolean = false;
   public reusablesName: any;
   public reusablesModifiedBy: any;
   public rusableModifiedTime: any;
   public documentName: any;
   public altDocumentNo: string;
   public revision: string;
   public docType: string;

   public folderName: any;
   public contentItemId: string;
   public contentItemName: string;
   public addDoc: boolean = false;
   public editDoc: boolean = true;
   public editDocumentName: any;
   public editAltDocumentNo: string;
   public editDocumentType: string;
   public editRevision: string;
   public editDocumentTypeId: string;
   public editContentItemId: string;
   public editContentItemName: string;
   public editFacilityId: string;
   public editFacilityName: string;

   public checkinTopicCount: string;
   public checkoutTopicCount: string;
   public referenceCount: string;
   public topicCount: string;
   public procedureCount: string;
   public iceCourseCount: string;
   public expandedView: string; //common variable 

   constructor(private translate: TranslateService,
      private router: Router,
      private documentsService: DocumentsService,
      private documentTypeService: DocumentTypeService,
      private sharedService: SharedService,
      private modalService: BsModalService,
      private alertService: AlertService,
      private filesService: FilesService,
      private route: ActivatedRoute,
      private loadingService: LoadingService,
      public bsModalRef: BsModalRef) {
   }

   getType() {
      return localStorage.getItem("value");
   }

   ngOnInit() {

      // this.route.queryParams.subscribe(params => {
      //    // Defaults to null if no query param provided.
      //    this.itemId = params['itemId'] || null;

      // });

      console.log('[AddeditdocumentsComponent] (itemId) ' + JSON.stringify(this.itemId));


      if (this.itemId == null) {
         this.addDoc = true
         this.editDoc = false
         localStorage.setItem("DocumentType", null);
         this.loadingService.showLoading(true, null, "Initial data is loading", null)
         this.documentsService.getFacilityList().subscribe(
            data => {
               this.loadingService.hideLoading()
               this.facilityList = []
               this.facilityList.push({ 'id': '', 'text': 'Select' })
               if (data.payload != null) {
                  data.payload.forEach(element => {
                     this.facilityList.push({ 'id': element.facilityId, 'text': element.facilityName })
                  });
               } else {
                  // do nothing
               }
            },
            error => {
               //console.log('[getParentSystemList] (error) ' + JSON.stringify(error.statusDescription));
               this.loadingService.hideLoading()
               this.alertService.error(error.statusDescription)
            });
      } else {
         this.editDoc = true
         this.addDoc = false;
         this.loadingService.showLoading(true, null, "Data is loading", null)
         this.documentsService.getDocumentDataByDocumentId(this.itemId).subscribe(
            data => {
               this.loadingService.hideLoading()
               if (data.payload != null) {
                  this.checkinTopicCount = data.payload.checkedInTopicCount;
                  this.checkoutTopicCount = data.payload.checkedOutTopicCount;
                  this.referenceCount = data.payload.referenceCount;
                  this.topicCount = data.payload.dependencyCount;
                  this.procedureCount = data.payload.procDependencyCount;
                  this.iceCourseCount = data.payload.iceCourseDependencyCount;
                  this.editFacilityList = []
                  this.editFacilityList.push({ 'id': '', 'text': 'Select' })
                  if (data.payload.facilityList != null) {
                     for (let key of Object.keys(data.payload.facilityList)) {
                        this.editFacilityList.push({ id: key, text: data.payload.facilityList[key] })
                     }
                  } else {
                     //nothing happen
                  }
                  if (data.payload.facilityName != null) {
                     this.selectedFacilityEdit = [{ 'id': data.payload.facilityId, 'text': data.payload.facilityName }]
                     this.editFacilityId = data.payload.facilityId
                     this.editFacilityName = data.payload.facilityName
                  } else {
                     this.selectedFacilityEdit = [{ 'id': '', 'text': 'Select' }]
                     this.editFacilityId = ""
                     this.editFacilityName = ""
                  }
                  this.editDocumentType = data.payload.documentType
                  this.editAltDocumentNo = data.payload.altDocNo;
                  this.editRevision = data.payload.revision;
                  this.editContentItemName = data.payload.attachedFileName;
                  localStorage.setItem("DocumentType", data.payload.documentTypeId);
                  this.editDocumentTypeId = data.payload.documentTypeId;
                  this.editContentItemId = data.payload.contentItemId;
                  if (data.payload.reusableChangeInfo != null) {
                     this.reusable = true;
                     this.reusablesName = "Old Value : " + data.payload.reusableChangeInfo.oldValue;
                     this.reusablesModifiedBy = "Modified By : " + data.payload.reusableChangeInfo.changeInitiatedUserName;
                     if (data.payload.reusableChangeInfo.changeInitiatedTimeStampFormatted != null) {
                        this.rusableModifiedTime = "Modified Time : " + data.payload.reusableChangeInfo.changeInitiatedTimeStampFormatted;
                     }
                     this.editDocumentName = data.payload.reusableChangeInfo.newValue;
                  } else {
                     this.reusable = false;
                     this.editDocumentName = data.payload.documentName;
                  }
                  this.filesService.setSelectedFileData(null);
               } else {

               }
            },
            error => {
               this.loadingService.hideLoading()
               this.alertService.clear()
               this.alertService.error(error.statusDescription)
            });
      }

   }

   public selectedFacilityId(value: any): void {
      this.selectedFacilityName = value.text;
      this.selectedFacilityIdValue = value.id;
   }

   public removedFacility(event) {
      this.selectedFacility = [{ 'id': '', 'text': 'Select' }]
      this.selectedFacilityName = '';
      this.selectedFacilityIdValue = '';
   }

   public selectedFacilityEditId(value: any): void {
      this.selectedFacilityNameEdit = value.text;
      this.selectedFacilityIdValueEdit = value.id;
   }

   public removedFacilityEdit(event) {
      this.selectedFacilityEdit = [{ 'id': '', 'text': 'Select' }]
      this.selectedFacilityNameEdit = '';
      this.selectedFacilityIdValueEdit = '';
   }

   viewDependencies(dflag: boolean) {
      this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.VIEW;
      this.dependenciesModalWithComponent('Dependencies List', false, true);
   }

   public dependenciesModalWithComponent(header: String, dflag: boolean, cflag: boolean) {
      const initialState : object = {
         headerTitle: header,
         delFlag: dflag,
         cancelFlag: cflag,
         checkboxVal: false
      };
      this.sharedService.setFileId(this.itemId);
      this.sharedService.setFileType(Constants.DEPENDENCY_ITEM_TYPE.DOCUMENT);
      this.sharedService.setAction("getDependencies");
		this.sharedService.setDependencyMessageType(this.dependencyType);
      this.sharedService.setParent(this);
      this.sharedService.setDependencyTypeList(["ProcedureDependency"]);
      this.modalRef = this.modalService.show(DependenciesmodalComponent, { initialState, class: 'modal-lg' });
   }

   public loadDocumentTypes() {
      const initialState = {
         title: 'Select a Document Type',
         treeListener: this,
         initial: true
      };
      this.treeBsModalRef = this.modalService.show(DocumenttypetreeComponent, { initialState, class: 'modal-lg' });
      this.treeBsModalRef.content.closeBtnName = 'Close';
   }

	/**
   * Clicks on the save button of the tree view popup
   * @param file selected file
   */
   public onSaveButtonClick(file: any, texts: QueryList<ElementRef>, options: QueryList<ElementRef>, documentTypeAttributes: any) {
      //console.log("onsave buttopn")
      //this.documentTypeService.getDocumentTypeAttributes(file.node.id, this.selectedFolder, this);
      if (this.editDoc == true) {
         this.editDocumentType = file.node.text
         this.editDocumentTypeId = file.node.id
      } else {
         this.documentType = file.node.text;
         this.documentTypeId = file.node.id;
      }
      this.treeBsModalRef.hide();
   }

   public addButtonClickFunction() {
      if (this.documentName == undefined || this.documentName == "") {
         this.alertService.error("Please enter a document name.")
      } else if (this.documentTypeId == undefined) {
         this.alertService.error("Please select a document type.")
      } else {
         if (this.documentName.trimLeft() != this.documentName) {
            this.alertService.error("Please remove leading space beginning of a document name.")
         } else {
            this.alertService.clear()
            this.loadingService.showLoading(true, null, "", null)
            if (this.selectedFacilityIdValue == undefined) {
               this.selectedFacilityIdValue = null
            }
            this.documentsService.addDocument(this.documentName, this.altDocumentNo, this.selectedFacilityIdValue, this.documentTypeId, this.revision, this.contentItemId).subscribe(
               data => {
                  this.loadingService.hideLoading()
                  //Comment this to remove updating UI and add user message
                  /*this.documentsService.createItemData(data.payload, this.documentName, this.altDocumentNo, this.selectedFacilityName, 
                  this.documentType, this.revision, this.contentItemId, this.contentItemName, this.selectedFacilityIdValue)*/

                  this.alertService.clear()
                  this.alertService.info(data.statusDescription +". " +this.translate.instant("REUSABLES.REFRESH_REUSABLE_TABLE"));				
                  // this.router.navigateByUrl("home")
                  this.bsModalRef.hide()
               },
               error => {
                  this.loadingService.hideLoading()
                  this.alertService.clear()
                  this.alertService.error(error.statusDescription)
               });
         }

      }
   }

   public editButtonClickFunction() {
      console.log('[editButtonClickFunction]');
      if (this.editDocumentName == undefined || this.editDocumentName == "") {         
         this.alertService.error("Please enter a document name.")         
      } else if (this.editDocumentTypeId == undefined) {
         this.alertService.error("Please select a document type.")
      } else {
         if(this.editDocumentName.trimLeft() != this.editDocumentName){
            this.alertService.error("Please remove leading space beginning of a document name.")
         }else{
            this.alertService.clear()
            this.loadingService.showLoading(true, null, "", null)
            if (this.selectedFacilityIdValueEdit == undefined) {
               if (this.editFacilityId == "") {
                  this.selectedFacilityIdValueEdit = ""
               } else {
                  this.selectedFacilityIdValueEdit = this.editFacilityId
               }
            } else {
                // nothing to do here
            }
            if(this.selectedFacilityNameEdit == undefined){
               if(this.editFacilityName == ""){
                  this.selectedFacilityNameEdit = ""
               }else {
                  this.selectedFacilityNameEdit = this.editFacilityName
               }
            }else{
               // nothing to do here
            }
            this.documentsService.updateDocument(this.itemId, this.editDocumentName, this.editAltDocumentNo, this.selectedFacilityIdValueEdit,
               this.editDocumentTypeId, this.editRevision, this.editContentItemId).subscribe(
                  data => {
                     this.loadingService.hideLoading()
                     //Comment this to remove updating UI and add user message
                     /*this.documentsService.setItemData(this.itemId, this.editDocumentName, this.editAltDocumentNo, this.selectedFacilityNameEdit,
                        this.editDocumentType, this.editRevision, this.editContentItemId, this.editContentItemName);*/
                     this.alertService.clear()
                     this.alertService.info(data.statusDescription +". " +this.translate.instant("REUSABLES.REFRESH_REUSABLE_TABLE"));				
                     //this.sharedService.docuSaveEventEmit()
                     // this.router.navigateByUrl("home")
                     this.bsModalRef.hide()
                  },
                  error => {
                     this.loadingService.hideLoading()
                     this.alertService.clear()
                     this.alertService.error(error.statusDescription)
                  });

         }         
      }
   }

	/**
	* Clicks on the save button of the pdf filetree view popup
	* @param file selected file
	*/
   public pdfFileSelectClickFunction(fileId: any, fileName: any) {
      if (this.editDoc == true) {
         this.editContentItemId = fileId;
         this.editContentItemName = fileName;
      } else {
         this.contentItemId = fileId;
         this.contentItemName = fileName;
      }
   }

   public loadPDFFile() {
      this.loadingService.showLoading(true, null, "", null)
      this.documentsService.getPDFFileAndFolders()
         .subscribe(data => {
            data.payload.forEach(element => {
               if (element.parent == true) {
                  this.folderName = element.name
               }
            });
            this.loadingService.hideLoading()
            const initialState = {
               payload: data.payload,
               treeListener: this,
               contentItemId: this.contentItemId,
               editContentItemId: this.editContentItemId,
               folderName: this.folderName
            };
            this.modalRef = this.modalService.show(PDFViewModalComponent, { class: 'modal-lg', initialState });
         },
            error => {
               this.loadingService.hideLoading()
               this.alertService.clear()
               this.alertService.error(error.statusDescription)
            });

   }

   public cancel() {
      // this.router.navigateByUrl("home")
      this.alertService.clear()
      this.bsModalRef.hide()
   }

   public removeSelectedPDF() {
      this.contentItemName = "";
      this.editContentItemName = "";
      this.editContentItemId = "";
      this.contentItemId = "";
   }

   onSuccess(data: WsResponse, serviceType: WsType): void {
      if (data.payload != null && serviceType == WsType.DOCUMENT_DATA_VIEW) {
         this.document = data.payload;
         this.dataLoad = true;
      } else if (serviceType == WsType.DOCUMENT_UPDATE) {
         // this.router.navigateByUrl("home")
         this.bsModalRef.hide()
      }
   }

   onFail(res: WsResponse, serviceType: WsType): void {
   }



}
