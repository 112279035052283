<div class="page-container with-right-panel ">
    <nav class="page-header">
        <div class="row">
            <div class="col mobile-hide">
                <p class="mb-2 h5">
                    <b>{{ 'PL_IMAGES.MANAGE_PL_IMAGES' | translate }}</b>
                </p>
            </div>
        </div>
    </nav>
    <div class="container pl-0">
        <div class="row pb-4">
            <label class="pt-2">{{ 'PL_IMAGES.PROJECT' | translate }}
            </label>
            <div class="col-sm-5">
                <ng-select [allowClear]="true" [items]="projectList" name="project" (selected)="projectSelected($event)"
                    id="project" placeholder="{{'PL_IMAGES.SELECT_PROJECT' | translate}}" (removed)="removedProject($event)">
                </ng-select>
            </div>
        </div>
        <div>
            <div class="row pb-4"  *ngIf="showGrid">
                <ag-grid-angular #agGrid style="width: 100%; height: 300px;" class="ag-theme-balham" [enableFilter]="true"
                    [rowData]="rowData" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }"
                    [columnDefs]="columnDefs" rowSelection="multiple" [rowMultiSelectWithClick]="false"
                    (gridReady)="onGridReady($event)" [pagination]="true" [gridOptions]="gridOptions"
                    [paginationPageSize]="12" [overlayLoadingTemplate]="overlayLoadingTemplate"
                    [defaultColDef]="defaultColDef" [overlayNoRowsTemplate]="overlayNoRowsTemplate">
                </ag-grid-angular>
            </div>
            <div class="row" *ngIf="showCopyBtn">
                <button type="button" class="btn btn-primary" (click)="showCopyConfirmation()" >{{ 'PL_IMAGES.COPY_TO_S3' | translate }}</button>
            </div>
            <div class="row pt-4" *ngIf="showResultsText">
                <h6>{{ copiedItems }} out of {{ totalItems }} successfully copied.</h6>
            </div>
        </div>
    </div>
</div>
<ng-template #copyConfirmModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{ 'PL_IMAGES.COPY_CONFIRMATION' | translate }}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="copyModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body ">
        <h5>{{ 'PL_IMAGES.COPY_CONFIRMATION_MESSAGE' | translate }}</h5>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="copyToS3()">
            Yes </button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal"
            (click)="copyModalRef.hide()">
            {{ 'CANCEL' | translate}} </button>
    </div>
</ng-template>