<div>
    <button id="landing-table-buttons" class="btn btn-sm btn-outline-dark py-0 my-1 px-1 border-0" (click)="invoke(type, data)" [disabled]="approved ? 'disabled': null">
        <i class="{{icon}}"  aria-hidden="true" ></i>
    </button>
</div>

<ng-template #deletePCCModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Revoke confirm</h4>
    </div>
    <div class="modal-body pb-0">
        <h5>{{pccDeleteMsg}}</h5>
    </div>
    <div class="modal-footer">
        <button type="button" id="warn_confirm" class="btn btn-primary"
                (click)="revokeDeletePCC()">Ok</button>
        <button type="button" id="warn_cancel" class="btn btn-secondary"
                data-dismiss="modal" (click)="cancelDeltePCC()">Cancel</button>
    </div>
</ng-template>
