<div class="row my-2 mx-1 border-bottom" #ftHeader>
	<div class="col-sm-6 pl-2">
		<p class="h6">{{ 'TOPIC_MAP.HEADER_TOPIC_MAP' | translate }}</p>
	</div>
	<div class="col-sm-6 pr-2">
		<div class="float-right">
			<!-- <button type="button" class="btn btn-outline-dark btn-sm ml-1 mr-1 border-0" (click)="createElement()">
                <i class="fa fa-plus fa-lg" aria-hidden="true"></i>
            </button> -->
		</div>
	</div>
	<!-- menu button panel -->
	<div *ngIf="topicMapType == 'edit'" class="col-sm-12 px-2 py-1  border-bottom " >
		<button type="button" class="btn btn-outline-dark btn-sm mx-1 border-0" (click)="openModal(checkin_comments)" tooltip="{{ 'TOPIC_MAP.TOOLTIP_CHECK_IN_TOPICMAP' | translate }}" [disabled] ="!buttonEnabled">
			<i class="fas fa-download" aria-hidden="true"></i>
		</button>
		<button type="button" class="btn btn-outline-dark btn-sm mx-1 border-0" (click)="saveTopicmap()" tooltip="{{ 'TOPIC_MAP.TOOLTIP_SAVE_TOPICMAP' | translate }}" [disabled] ="!buttonEnabled">
			<i class="fas fa-save" aria-hidden="true"></i>
		</button>
		<!-- <button type="button" class="btn btn-outline-dark btn-sm mx-1 border-0" tooltip="{{ 'TOPIC_MAP.TOOLTIP_OBJECT_CONFIGURE' | translate }}">
			<i class="fa fa-share-alt" aria-hidden="true"></i>
		</button> -->
		<button *ngIf="!isTopicMapEdited" type="button" class="btn btn-outline-dark btn-sm mx-1 border-0" (click)="undoCheckoutTopicmap()" tooltip="{{ 'TOPIC_MAP.UNDO_CHECKOUT' | translate }}" [disabled] ="!buttonEnabled">
			<i class="fa fa-undo" aria-hidden="true"></i>
		</button>
		<button *ngIf="isTopicMapEdited" type="button" class="btn btn-outline-dark btn-sm mx-1 border-0" (click)="undoCheckoutConfirm()" tooltip="{{ 'TOPIC_MAP.UNDO_CHECKOUT' | translate }}" [disabled] ="!buttonEnabled">
			<i class="fa fa-undo" aria-hidden="true"></i>
		</button>
		<button type="button" class="btn btn-outline-dark btn-sm mx-1 border-0" (click)="removeTopicmapItem()" tooltip="{{ 'TOPIC_MAP.TOOLTIP_REMOVE_ITEM' | translate }}" [disabled] ="!buttonEnabled">
			<i class="fas fa-trash-alt" aria-hidden="true"></i>
		</button>
		<button type="button" class="btn btn-outline-dark btn-sm mx-1 border-0" (click)="renameTopicmapItem()" tooltip="{{ 'TOPIC_MAP.TOOLTIP_RENAME_ITEM' | translate }}" [disabled] ="!buttonEnabled">
			<i class="fa fa-exchange" aria-hidden="true"></i>
		</button>
		<button type="button" class="btn btn-outline-dark btn-sm mx-1 border-0" (click)="addTopicmapHeader()" tooltip="{{ 'TOPIC_MAP.TOOLTIP_HEADER' | translate }}" [disabled] ="!buttonEnabled">
			<i class="fa fa-file" aria-hidden="true"></i>
		</button>
	</div>
	<div *ngIf="topicMapType == 'view'" class="col-sm-12 px-2 py-1 border-bottom ">
		<button type="button" class="btn btn-outline-dark btn-sm mx-1 px-1 py-0" (click)="insertReferenceBtnEvent()">
			{{ 'TOPIC_MAP.BTN_INSERT_REFERENCE' | translate }}
		</button>
		<button type="button" class="btn btn-outline-dark btn-sm mx-1 px-1 py-0" (click)="insertOtherReferenceBtnEvent()" [disabled]="!enableInsertOtherRef">
			{{ 'TOPIC_MAP.BTN_INSERT_OTHER_REFERENCE' | translate }}
		</button>
	</div>
	<!-- add options to topic map -->
	<div class="col-sm-12 px-2 py-1 border-bottom" *ngIf="topicMapType == 'edit'">
		<button type="button" class="btn btn-outline-dark btn-sm mx-1 my-1" (click)="toggleTOC()" [disabled] ="!buttonEnabled">TOC</button>
		<button type="button" class="btn btn-outline-dark btn-sm mx-1 my-1" (click)="toggleTOF()" [disabled] ="!buttonEnabled">TOF</button>
		<button type="button" class="btn btn-outline-dark btn-sm mx-1 my-1" (click)="toggleTOT()" [disabled] ="!buttonEnabled">TOT</button>
		<button type="button" class="btn btn-outline-dark btn-sm mx-1 my-1" (click)="toggleTOD()" [disabled] ="!buttonEnabled">TOD</button>
		<button type="button" class="btn btn-outline-dark btn-sm mx-1 my-1" (click)="toggleIndex()" [disabled] ="!buttonEnabled">Index</button>
		<!-- <button type="button" class="btn btn-outline-dark btn-sm mx-1 my-1" (click)="addObject()">Object</button> -->
		<button type="button" class="{{flTOCClass}}" aria-pressed="{{flTOCAria}}" (click)="toggleFLTOC()" [disabled] ="!buttonEnabled">FLTOC</button>
		<button type="button" class="{{flcoverClass}}" aria-pressed="{{flcoverAria}}" (click)="toggleFLCover()" [disabled] ="!buttonEnabled">FLCOVER</button>
	</div>
	<div class="col-sm-12 px-2 py-1 border-bottom" *ngIf="topicMapType == 'edit'">
		<div class="px-2" (click)="otherSettingsClick();resizeEditorDiv()"
			[ngClass]="{'other-settings-disable': !isOtherSettings, 'other-settings-enable': isOtherSettings}">
			<label class="mb-0"><b>Other Settings</b></label>
			<span class="float-right">
				<i class="fa " aria-hidden="true"
					[ngClass]="{'fa-chevron-up': collapseOS, 'fa-chevron-down': !collapseOS}"></i>
			</span>
		</div>

		<div *ngIf="collapseOS" class="px-2 py-2">
			<form>
				<div class="form-group">
					<label for="topicmap_builder_orientation">Orientation :</label>
					<!-- <input type="text" class="form-control form-control-sm" id="topicmap_builder_orientation" placeholder="Orientation" value="{{orientation}}"> -->
					<select id="topicmap_builder_orientation" value="{{orientation}}" class="form-control form-control-sm" (change)="orientationOnSelect($event.target.value)" [disabled] ="!buttonEnabled">
						<option value="None">None</option>
						<option value="Landscape">Landscape</option>
						<option value="Portrait">Portrait</option>
					</select>

				</div>
				<div class="form-group">
					<label for="topicmap_builder_special_paper_size">Special Paper Size</label>
					<!-- <input type="text" class="form-control form-control-sm" id="topicmap_builder_special_paper_size" placeholder="Special Paper Size" value="{{specialPaperSize}}"> -->
					<select id="topicmap_builder_special_paper_size" value="{{specialPaperSize}}" class="form-control form-control-sm" (change)="specialPaperOnSelect($event.target.value)" [disabled] ="!buttonEnabled">
						<option value="None">None</option>
						<option value="Letter">Letter</option>
						<option value="A4">A4</option>
						<option value="A3">A3</option>
						<option value="B4">B4</option>
						<option value="Legal">Legal</option>
						<option value="Tabloid">Tabloid</option>
					</select>
				</div>
				<div class="form-group form-check">
					<input type="checkbox" class="form-check-input" data-md-icheck id="topicmap_builder_otc" [checked]=omitInTOC (change)="omitInTOCChange($event)" [disabled] ="!buttonEnabled">
					<label class="form-check-label" for="topicmap_builder_otc">Omit in Table of Content</label>
				</div>
				<div class="form-group form-check">
					<input type="checkbox" class="form-check-input" data-md-icheck id="topicmap_builder_page_break" [checked]=pageBreak (change)="pageBreakChange($event)" [disabled] ="!buttonEnabled">
					<label class="form-check-label" for="topicmap_builder_page_break">Page Break</label>
				</div>
			</form>
		</div>

	</div>
	
	
</div>
<div class="row my-2 mx-1" [ngClass]="{'content-show': topicMapType == 'edit', 'content-hide': topicMapType == 'view'}">
	<div id="topicMapTreeEdit" class="my-2 mx-1 ftdiv-overflow" [style.height.px]="treeheight_edit"></div>
</div>

<div class="row my-2 mx-1" [ngClass]="{'content-show': topicMapType == 'view', 'content-hide': topicMapType == 'edit'}">
	<div id="topicMapTreeView" class="my-2 mx-1 ftdiv-overflow" [style.height.px]="treeheight_view"></div>
</div>
<ng-template #omitTableContent_unchecked>
	<div class="form-group form-check">
		<input type="checkbox" class="form-check-input" id="topicmap_builder_otc">
		<label class="form-check-label" for="topicmap_builder_otc">Omit in Table of Content</label>
	</div>
</ng-template>
<ng-template #pageBreak_unchecked>
	<div class="form-group form-check">
		<input type="checkbox" class="form-check-input" id="topicmap_builder_page_break">
		<label class="form-check-label" for="topicmap_builder_page_break">Page Break</label>
	</div>
</ng-template>

<!--Insert other reference modal-->
<ng-template #insert_other_reference>
	<div class="modal-header">
		<h4 class="modal-title pull-left">{{'TOPIC_MAP.INSERT_OTHER_REFERENCE_MODAL_HEADER' | translate }}</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body py-1">
		<div class="row mb-2">
			<div class="btn-group btn-group-sm my-1 mx-auto" role="group" aria-label="Basic example">
				<button type="button" class="btn btn-outline-dark" (click)="showFigureRef()"
					[ngClass]="{'btn-dark': selectedOtherReference == 'figureRef', 'btn-outline-dark': selectedOtherReference != 'figureRef'}">
					{{ 'TOPIC_MAP.INSERT_OTHER_REFERENCE_FIGURE_TAB' | translate }}</button>
				<button type="button" class="btn " (click)="showTableRef() "
					[ngClass]="{'btn-dark': selectedOtherReference == 'tableRef', 'btn-outline-dark': selectedOtherReference != 'tableRef'}">
					{{ 'TOPIC_MAP.INSERT_OTHER_REFERENCE_TABLES_TAB' | translate }}</button>		
			</div>
		</div>
		<hr class="mt-0 mb-2" />		
		<ag-grid-angular #agGrid style="width: 100%; height: 400px;" [rowData]="rowData" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }" class="ag-theme-balham"
			[columnDefs]="imageColumnDefs"  [enableFilter]="false" 
			[enableRangeSelection]="false" [pagination]="true"
			(gridReady)="onGridReady($event)" [defaultColDef]="defaultColDef"
			(rowClicked)="onRowClick($event)" [suppressMenuHide]="true" rowSelection="single" [suppressRowDeselection]="true"
			[rowMultiSelectWithClick]="false" (rowSelected)="onRowSelected($event)" [getRowNodeId]="getRowNodeId"
			(lastChildChanged)="onlastChildChanged($event)"
			[rowSelection]="rowSelection"></ag-grid-angular>
		<button *ngIf="imageRef && isImageSelected" class="btn btn-sm btn-outline-dark float-right my-2" (click)="viewSelectedImage($event)">
				{{ 'TOPIC_MAP.BTN_INSERT_OTHER_REFERENCE_IMAGE_VIEW' | translate }}</button>
		<button *ngIf="enableInsertRef && enableInsertRefCount" class="btn btn-sm btn-outline-dark float-left my-2" (click)="insertOtherReference($event)">
				{{ 'TOPIC_MAP.BTN_INSERT_REFERENCE' | translate }}</button>
	</div>
</ng-template>

<!--Insert other reference image view modal-->
<ng-template #largeImage>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{ viewImage.title }}</h4>
        <button type="button" id="image_close" class="close pull-right" aria-label="Close" (click)="bsModalRefLargerImage.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="card p-0">
            <img class="card-img-top" src="data:image/jpeg;base64,{{viewImage.largeImage}}">
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-secondary" (click)="bsModalRefLargerImage.hide()">
			{{ 'TOPIC_MAP.BTN_INSERT_OTHER_REFERENCE_MODAL_CLOSE' | translate }}</button>
    </div>
</ng-template>

<!--Checkin model-->
<ng-template #checkin_comments>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{ 'TOPIC_MAP.CHECK_IN' | translate}}</h4>
        <button type="button" id="topicmap_builder_bs_model_ref_close" class="close pull-right" aria-label="Close"
            (click)="bsModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <label>{{ 'TOPIC_MAP.COMMENTS' | translate}}</label>
        <textarea class="form-control" #comments></textarea>
    </div>
    <div class="modal-footer">
        <button type="button" id="topicmap_builder_checkin" class="btn btn-primary" (click)="checkinTopicmap(comments.value)">
            {{ 'TOPIC_MAP.OK' | translate}}</button>
        <button type="button" id="topicmap_builder_bs_model_ref_modal" class="btn btn-secondary" data-dismiss="modal"
            (click)="bsModalRef.hide()">
            {{ 'TOPIC_MAP.CANCEL' | translate}}</button>
    </div>
</ng-template>

<ng-template #undoCheckOutTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{ 'UNDO_CHECKOUT_CONFIRM' | translate}}</h4>
    </div>    	
	<div class="modal-body">
        <div class="container">
			<h5 class="text-center">{{ 'UNDO_CHECKOUT_CONFIRM_MSG' | translate}} </h5>
        </div>
    </div> 
    <div class="modal-footer">
	  <button  type="button" class="btn btn-default" (click)="onConfirm(); bsModalRef.hide();">{{'YES' | translate}}</button>
      <button type="button" class="btn btn-warning" (click)="bsModalRef.hide()">{{ 'NO' | translate}}</button>      
    </div>
</ng-template>