import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FacilitiesService } from 'src/app/reusables/facilities/facilities.service';
import { AlertService } from 'src/app/util/alert/alert.service';
import { Constants } from 'src/app/util/constants';
import { DependenciesmodalComponent } from 'src/app/util/dependenciesmodal/dependenciesmodal.component';
import { LoadingService } from 'src/app/util/loading/loading.service';
import { SharedService } from 'src/app/util/shared.service';
import { WsCallback } from 'src/app/util/ws-callback.interface';
import { WsResponse } from 'src/app/util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';
import { AssignchecksComponent } from '../managechecksheets/assignchecks/assignchecks.component';
import { AddeditchecksheetComponent } from '../managechecksheets/addeditchecksheet/addeditchecksheet.component';
import { ManagechecksheetsService } from '../managechecksheets/managechecksheets.service';
import { AssignassetsComponent } from '../managechecksheets/assignassets/assignassets.component';
import { AssignsystemsComponent } from '../managechecksheets/assignsystems/assignsystems.component';


@Component({
  selector: 'app-checksheets-cell-render-component',
  templateUrl: './checksheets-cell-render-component.component.html',
})
export class ChecksheetsCellRenderComponentComponent implements WsCallback {

  disable: boolean = false;
  icon: string;
  title: String;
  type: any;
  id: string;
  status: string;
  refCount: number = 0;
  dependencyType: string;
  bsModalRef: BsModalRef;

  public displayInsWarn: boolean = false;
  public warningMsg: string = "";

  warnModalRef: BsModalRef;
  @ViewChild('confirmation_warn') confirmationWarn: TemplateRef<any>;
  @ViewChild('assetDelete_warn') assetDeleteWarn: TemplateRef<any>;

  constructor(private alertService: AlertService, private sharedService: SharedService,
    private modalService: BsModalService, private translate: TranslateService,
    private loadingService: LoadingService, private checksheetService: ManagechecksheetsService,) {
    translate.addLangs(["es", "en"]);
    translate.setDefaultLang("en");
    let browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
  }

  agInit(params: any): void {
    this.id = params.data.id;
    this.refCount = params.data.referenceCount;
    let items = params.value.split(':')
    if (items.length == 2) {
      this.status = items[1];
    }
    this.setType(items[0]);

  }

  private setType(type) {
    this.type = type;
    if (type == 'dependencies') {
      this.icon = 'far fa-link';
      if (this.refCount > 0) {
        this.disable = false;
        this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.VIEW;
        this.translate.get('CS_CELL_RENDER.DEPENDENCIES_LIST').subscribe((res: string) => {
          this.title = res;
        });
      }
      else {
        this.disable = true;
      }
    } else if (type == 'assignchecks' || type == 'assignassets' || type == 'assignsystems') {
      this.icon = 'fal fa-equals';
    } else if (type == 'status') {
      if (this.status == "Active") {
        this.icon = 'far fa-check-circle';
        this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.DISABLE;
        this.translate.get('CS_CELL_RENDER.DISABLE_CONFIRM').subscribe((res: string) => {
          this.title = res;
        });
      } else {
        this.icon = 'far fa-times-circle';
        this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.ENABLE;
        this.translate.get('CS_CELL_RENDER.ENABLE_CONFIRM').subscribe((res: string) => {
          this.title = res;
        });
      }
    } else if (type == 'delete') {
      this.icon = 'far fa-trash-alt';
      this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.DELETE;
      this.translate.get('CS_CELL_RENDER.DELETE_CONFLICTS').subscribe((res: string) => {
        this.title = res;
      });
    } else if (type == 'edit') {
      this.icon = 'far fa-edit';
    } else if (type == 'deleteAsset') {
      this.icon = 'far fa-trash-alt';
      this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.VIEW;
      this.translate.get('CS_CELL_RENDER.DEPENDENCIES_LIST').subscribe((res: string) => {
        this.title = res;
      });
    } else if (type == 'deleteSystem') {
      this.icon = 'far fa-trash-alt';
      this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.VIEW;
      this.translate.get('CS_CELL_RENDER.DEPENDENCIES_LIST').subscribe((res: string) => {
        this.title = res;
      });
    }
  }

  viewDependencies(dflag: boolean) {
    this.dependenciesModalWithComponent(this.title, dflag, true);
  }

  dependenciesModalWithComponent(header: String, dflag: boolean, cflag: boolean) {
    const initialState = {
      headerTitle: header,
      delFlag: dflag,
      cancelFlag: cflag,
      checkboxVal: this.status == "Inactive" ? true : false
    };
    this.sharedService.setFileId(this.id);
    this.sharedService.setFileType(Constants.DEPENDENCY_ITEM_TYPE.CHECKSHEET);
    this.sharedService.setAction("getDependencies");
    this.sharedService.setDependencyMessageType(this.dependencyType);
    this.sharedService.setParent(this);
    this.bsModalRef = this.modalService.show(DependenciesmodalComponent, { initialState, class: 'modal-lg' });
  }

  public invoke(type: String, data: any) {
    switch (type) {
      case 'dependencies':
        if (this.refCount > 0) {
          this.viewDependencies(false);
        }
        break;
      case 'status':
        this.viewDependencies(false);
        break;
      case 'edit':
        this.loadingService.showLoading(true, false, 'Checking Checksheet Instances', 0);
        this.checksheetService.getCSInstanceCount(this.id, this);
        break;
      case 'delete':
        this.viewDependencies(true);
        break;
      case 'assignchecks':
        this.openAssignChecksModal();
        break;
      case 'assignassets':
        this.openAssignAssetsModal();
        break;
      case 'deleteAsset':
        if (this.refCount == 0) {
          // delete
          this.checksheetService.removeAssignedAssetSystem(this.id);
        }
        else {
          // show warning
          this.warnModalRef = this.modalService.show(this.assetDeleteWarn);
        }
        break;
      case 'assignsystems':
        this.openAssignSystemsModal();
        break;
      case 'deleteSystem':
        if (this.refCount == 0) {
          // delete
          this.checksheetService.removeAssignedAssetSystem(this.id);
        }
        else {
          // show warning
          this.warnModalRef = this.modalService.show(this.assetDeleteWarn);
        }
        break;
    }
  }

  onSuccess(data: WsResponse, serviceType: WsType) {
    if (serviceType == WsType.GET_CSINSTANCE_COUNT) {
      this.loadingService.hideLoading();
      if (data.payload != null) {
        if (data.payload.relatedIssuedCheckSheetsCount > 0 && data.payload.relatedApprovedCheckSheetsCount > 0) {
          this.displayInsWarn = true;
          this.translate.get('CS_CELL_RENDER.ISSUED_APPROVED_WARN').subscribe((res: string) => {
            this.warningMsg = res;
          });
        }
        else if (data.payload.relatedIssuedCheckSheetsCount > 0) {
          this.displayInsWarn = true;
          this.translate.get('CS_CELL_RENDER.ISSUED_WARN').subscribe((res: string) => {
            this.warningMsg = res;
          });
        }
        else if (data.payload.relatedApprovedCheckSheetsCount > 0) {
          this.displayInsWarn = true;
          this.translate.get('CS_CELL_RENDER.APPROVED_WARN').subscribe((res: string) => {
            this.warningMsg = res;
          });
        }
      }
      this.showWarnMsg();
    }
  }

  onFail(data: WsResponse, serviceType: WsType) {
    if (serviceType == WsType.GET_CSINSTANCE_COUNT) {
      this.loadingService.hideLoading();
      this.alertService.clear();
      this.alertService.error(data.statusDescription)
    }
  }

  showWarnMsg() {
    if (this.type == 'edit') {
      if (this.displayInsWarn) {
        this.warnModalRef = this.modalService.show(this.confirmationWarn);
      } else {
        this.openAddEditCSModal();
      }
    }
  }

  private openAddEditCSModal() {
    const initialState: object = {
      edit: true,
      csId: this.id
    };
    this.bsModalRef = this.modalService.show(AddeditchecksheetComponent, { initialState, class: 'modal-md', backdrop: 'static', keyboard: false })
  }

  private openAssignChecksModal() {
    const initialState: object = {
      csId: this.id
    };
    this.bsModalRef = this.modalService.show(AssignchecksComponent, { initialState, backdrop: 'static', keyboard: false });
    this.bsModalRef.setClass('modal-xl-custom-style');
  }

  private openAssignAssetsModal() {
    const initialState: object = {
      csId: this.id
    };
    this.bsModalRef = this.modalService.show(AssignassetsComponent, { initialState, backdrop: 'static', keyboard: false });
    this.bsModalRef.setClass('modal-xl-custom-style');
  }

  private openAssignSystemsModal() {
    const initialState: object = {
      csId: this.id
    };
    this.bsModalRef = this.modalService.show(AssignsystemsComponent, { initialState, backdrop: 'static', keyboard: false });
    this.bsModalRef.setClass('modal-xl-custom-style');
  }

  warningConfirm() {
    if (this.type == 'edit') {
      this.openAddEditCSModal()
    }
  }
}
