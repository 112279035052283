export class IceLearningCheck {
    constructor(
        public id?: string,
        public sequence?: number,
        public courseId?: string,
        public topicId?: string ,
        public topicContentId?: string,
        public description?: string,
        public descriptionHTML?: string,
        public learningCheckData?: any,
        public learningCheckVersion?: number,
        public courseVersion?: number,
        public orderNumber?: number,
        public questionLength?: number,
        public data?: any,
        public parentType?: string,
        public parent?: any,
        public isLearningCheckModified?: boolean,
        public selectedImageType?: string,
        public category?: string
    ){}
}