import { Injectable } from '@angular/core';
import { ProcedureStoreComponent } from '../procedurestore.component';
import { AddProcedureStoreComponent } from '../addprocedurestore/addprocedurestore.component';
import { DeletedProcedureStoreComponent } from '../deletedprocedurestore/deletedprocedurestore.component';
import { ProcedureInstancesComponent } from '../procedureinstances/procedureinstances.component';

@Injectable({
	providedIn: 'root'
})
export class PsLoaderService {

	constructor() { }

	public loadComponentByName(compName: string) {
		switch (compName) {
            case 'procedurestore':
                return ProcedureStoreComponent
            case 'addprocedure':
                return AddProcedureStoreComponent
            case 'deletedprocedure':
                return DeletedProcedureStoreComponent
            case 'procedureInstance':
                return ProcedureInstancesComponent
            default:
                break;
        }
	}
}
