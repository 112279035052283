import { AllModules, GridOptions, Module } from '@ag-grid-enterprise/all-modules';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FilesDataService } from 'src/app/home/files/filesdata/filesdata.service';
import { OutputGenerateModalComponent } from 'src/app/home/files/outputgenerator/outputgeneratemodal.component';
import { AlertService } from 'src/app/util/alert/alert.service';
import { Constants } from 'src/app/util/constants';
import { LoadingService } from 'src/app/util/loading/loading.service';
import { WsResponse } from 'src/app/util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';
import { AddeditotnComponent } from './addeditotn/addeditotn.component';
import { ManageOTNCellRenderComponent } from './manageotn-cell-render-component.component';
import { ManageotnService } from './manageotn.service';
import { OTNDocuSignCellRendererComponent } from './otndocu-sign-cell-renderer/otndocu-sign-cell-renderer.component';
import * as moment from 'moment';

@Component({
  selector: 'app-manageotn',
  templateUrl: './manageotn.component.html',
  styleUrls: ['./manageotn.component.css']
})
export class ManageotnComponent implements OnInit {
  @ViewChild("incompleteOTNModal") incompleteOTNModal: TemplateRef<any>;
  public incompleteModalRef: BsModalRef;

  generateOut: boolean = false;
  checkingOTNList = [];
  rowData: any = [];
  assetIrnCheckList: any
  assetOpenPunchlistCheckList: any
  hasAssetWithNoIRN: boolean = false;
  hasAssetWitOpenPunchlists: boolean = false;
  selectedOutputType = []

  public overlayLoadingTemplate: any;
  public overlayNoRowsTemplate: any;
  public getRowNodeId: any;

  public bsModalRef: BsModalRef;

  private filteredData: Array<any> = [];
  public columnDefs: any;
  public gridOptions: GridOptions;
  public gridApi: any;
  public modules: Module[] = AllModules;
  public expandComponents: boolean = false;

  public searchData: any;
  private assetSearch: string = "";
  private vendorSearch: string = "";
  private otnStatusSearch: string = "";
  private completionDateSearch: string = "";
  private hasText: string = "";
  private assetsHasIrn: true;

  constructor(
    private modalService: BsModalService,
    private translate: TranslateService,
    private loadingService: LoadingService,
    private alertService: AlertService,
    private manageotnService: ManageotnService,
    private filesDataService: FilesDataService
  ) {

    translate.addLangs(["es", "en"])
    translate.setDefaultLang("en");
    let browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
    this.columnDefs = [
      {
        headerName: this.translate.instant('OTN_SEARCH_VIEW.ASSETS'),
        field: 'otnAssets',
        autoHeight: true,
        cellClass: "cell-wrap-text",
        minWidth: 250,
        sortable: true,
        cellStyle: { 'white-space': 'normal' },
        cellRenderer: function (params) {
          var eDiv = document.createElement('div');
          if (params.data.otnAssets != null) {
            var listSize = params.data.otnAssets.length
            for (let i = 0; i < listSize; i++) {
              eDiv.innerHTML += params.data.otnAssets[i].name;
              if (i < listSize - 1) {
                eDiv.innerHTML += ", ";
              }
            }
          }
          return eDiv;
        },
        comparator: function (assetList1, assetList2) {
          var assets1 = "";
          var assets2 = "";
          if (assetList1 != null) {
            var listSize = assetList1.length
            for (let i = 0; i < listSize; i++) {
              assets1 += assetList1[i].name;
              if (i < listSize - 1) {
                assets1 += ", ";
              }
            }
          }
          if (assetList2 != null) {
            var listSize = assetList2.length
            for (let i = 0; i < listSize; i++) {
              assets2 += assetList2[i].name;
              if (i < listSize - 1) {
                assets2 += ", ";
              }
            }
          }
          return ('' + assets1).localeCompare(assets2);
        }
      },
      {
        headerName: this.translate.instant('OTN_SEARCH_VIEW.OTN_STATUS'),
        field: 'otnStatus',
        autoHeight: true,
        cellClass: "cell-wrap-text",
        minWidth: 150,
        sortable: true
      },
     /*  {
        headerName: this.translate.instant('OTN_SEARCH_VIEW.TERGETCOMPLETIONDATE'),
        field: 'targetCompletionDate',
        autoHeight: true,
        cellClass: "cell-wrap-text",
        minWidth: 150,
        sortable: true,
        comparator: this.dateTimeComparator
      }, */
      {
        headerName: this.translate.instant('OTN_SEARCH_VIEW.VENDOR'),
        field: 'vendor',
        autoHeight: true,
        cellClass: "cell-wrap-text",
        minWidth: 150,
        sortable: true,
        cellStyle: { 'white-space': 'normal' }
      },
      {
        headerName: this.translate.instant('PUNCHLIST_SEARCH_VIEW.WORKFLOW_STATUS'),
        field: 'docusignStatus',
        autoHeight: true, 
        cellClass: "cell-wrap-text",   
        minWidth: 125,
        cellRendererFramework: OTNDocuSignCellRendererComponent
      },
      {
        headerName: this.translate.instant('OTN_SEARCH_VIEW.EDIT'),
        field: "edit",
        minWidth: 75,
        cellStyle: { 'text-align': 'center' },
        cellRendererFramework: ManageOTNCellRenderComponent,
      },
      {
        headerName: this.translate.instant('OTN_SEARCH_VIEW.DELETE'),
        field: "delete",
        minWidth: 120,
        cellStyle: { 'text-align': 'center' },
        cellRendererFramework: ManageOTNCellRenderComponent,
      },
      {
        headerName: this.translate.instant('OTN_SEARCH_VIEW.APPROVE'),
        field: "approve",
        minWidth: 75,
        cellStyle: { 'text-align': 'center' },
        cellRendererFramework: ManageOTNCellRenderComponent,
      },
      {
        headerName: this.translate.instant('OTN_SEARCH_VIEW.SENT_PDF'),
        field: "sentpdf",
        minWidth: 75,
        cellStyle: { 'text-align': 'center' },
        cellRendererFramework: ManageOTNCellRenderComponent,
      },
      {
        headerName: this.translate.instant('OTN_SEARCH_VIEW.RETURNED_PDF'),
        field: "returnedpdf",
        minWidth: 75,
        cellStyle: { 'text-align': 'center' },
        cellRendererFramework: ManageOTNCellRenderComponent,
      }
    ];
    this.getRowNodeId = function (data) {
      return data.id;
    };
    this.gridOptions = {
      rowData: this.rowData,
      columnDefs: this.columnDefs,
      defaultColDef: {
        resizable: true,
        sortable: true
      },
      rowSelection: "single",
      rowMultiSelectWithClick: false,
      doesExternalFilterPass: this.externalFilterPass,
      animateRows: true,
      rowBuffer: 20,
      context: {
        componentParent: this
      },
      paginationPageSize: 10,
      getRowStyle: (params) => {
        if (params.data.otnStatus == "OnQueue") {
          return { background: '#FFDD77' };
        } else if (params.data.otnStatus == "Issued") {
          return { background: '#FCE2BE' };
        } else if (params.data.otnStatus == "Approved") {
          return { background: '#DEDEDE' };
        } else if (params.data.otnStatus == "Error") {
          return { background: '#FCCDC4' };
        } else {
          return { background: '#ffffff' };
        }
      }
    };

    //custom layout templates for loading and empty data sets
    this.overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Loading data...</span>';
    this.overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">No rows to show</span>';
  }

  dateTimeComparator(date1:any, date2:any) {
     let date1Number = new Date(date1).getTime();
     let date2Number = new Date(date2).getTime();

     if (date1Number === null && date2Number === null) {
        return 0;
     }
     if (date1Number === null) {
        return -1;   
     }
     if (date2Number === null) {
        return 1;
     }

     return date1Number - date2Number;
  }

  ngOnInit(): void {
    this.manageotnService.setOTNcomp(this);
    this.manageotnService.loadTableDataResponse().subscribe((option: any) => this.reloadData());
    this.manageotnService.updateUIafterOTNGenerateResponse().subscribe((option: any) => this.updateUIAfterGenerateOutPut());
    this.manageotnService.updateUIafterApprovalResponse().subscribe((option: any) => this.updateUIAfterApprove(option.otnId, option.uploaded))
  }
  onGridReady(params) {
    this.gridApi = params.api;
    params.api.sizeColumnsToFit();
  }

  externalFilterPass(node: any) {
    return true;
  }

  searchFunc(data: any) {
    this.searchData = data;
    this.assetSearch = "";
    this.vendorSearch = "";
    this.otnStatusSearch = "";
    this.completionDateSearch = "";
    this.hasText = "";

    var assetSearch = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_OTN_ASSET];
    if (assetSearch != undefined) {
      this.assetSearch = assetSearch
    } else {

    }
    var vendorSearch = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_OTN_VENDOR];
    if (vendorSearch != undefined) {
      this.vendorSearch = vendorSearch
    } else {

    }
    var otnStatusSearch = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_OTN_STATUS];
    if (otnStatusSearch != undefined) {
      this.otnStatusSearch = otnStatusSearch
    } else {

    }

    var completionDateSearch = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_OTN_COMPLETION_DATE];
    if (completionDateSearch != undefined) {
      this.completionDateSearch = completionDateSearch;
    } else {
      //no has text
    }
    var hasText = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_HAS_THE_WORDS];
    if (hasText != undefined) {
      this.hasText = hasText;
    } else {
      //no has text
    }
    if (this.assetSearch != "" || this.vendorSearch != "" || this.otnStatusSearch != "" || this.completionDateSearch != "" || this.hasText != "") {
      let to: any = false;
      let data = {}
      data = {
        otnAssets: this.assetSearch,
        otnVendors: this.vendorSearch,
        otnStatusSearch: this.otnStatusSearch,
        otnCompletionDateSearch: this.completionDateSearch,
        hasText: this.hasText,
      }
      var json = JSON.stringify(data);
      if (to) { clearTimeout(to); }
      this.loadingService.showLoading(true, null, "Searching", null);
      to = setTimeout(() => {
        this.manageotnService.searchOTNList(json).subscribe(
          data => {
            this.setDocumentSearchTableData(data.payload)
          },
          error => {
            this.loadingService.hideLoading();
            this.alertService.clear()
            this.alertService.error(error.statusDescription)
          });
      }, 200);
    } else {

      this.gridApi.hideOverlay()
    }
  }

  setDocumentSearchTableData(payload: any) {
    this.filteredData = [];
    this.rowData = [];
    let id: number = 0
    if (payload != null) {
      if (this.expandComponents == false) {
        for (let i = 0; i < payload.length; i++) {
          id += 1;
          let targetCompletionDate = payload[i]["targetCompletionDate"] != null ? moment.utc(payload[i]["targetCompletionDate"]).format("M/D/YYYY") : null;

          this.filteredData.push({
            id: id,
            otnId: payload[i]["otnid"],
            otnAssets: payload[i]["assetList"],
            otnStatus: payload[i]["otnstatus"],
            targetCompletionDate: targetCompletionDate,
            vendor: payload[i]["vendorName"],
            docusignStatus: payload[i]["docusignStatus"],
            docusignId: payload[i]["docusignId"],            
						assetHasIrnList: payload[i]["assetIdHasIrnList"],
						assetHasOpenPlList: payload[i]["assetIdHasOpenPlList"],
            approve: "approve:" + payload[i]["otnstatus"],
            edit: "edit:" + payload[i]["otnstatus"],
            delete: "delete:" + payload[i]["otnstatus"],
            sentpdf: "sentpdf:" + payload[i]["otnstatus"],
            returnedpdf: "returnedpdf:" + payload[i]["otnstatus"] + ":" + payload[i]["isUploaded"]
          });
        }
      }
      this.rowData = this.filteredData;
      this.gridOptions.columnApi.getColumn('otnAssets').setSort("asc");
      this.gridApi.paginationProxy.currentPage = 0;
      this.gridOptions.api.sizeColumnsToFit();
      this.generateOut = false;
      if (this.rowData.length > 0) {
        this.rowData.forEach((row) => {
          if (row.otnStatus == "NotIssued") {
            this.generateOut = true;
          }
        })
      } else {
        this.generateOut = false;
      }
    } else {
      // no row data to show
      this.rowData = [];
      this.generateOut = false;
    }
    this.manageotnService.setRowData(this.rowData);
    this.loadingService.hideLoading();
  }

  newOTNButtonClick() {
    this.openAddEditOTNModal();
  }

  private openAddEditOTNModal() {
    const initialState: object = {
      edit: false
    };
    this.bsModalRef = this.modalService.show(AddeditotnComponent, { initialState, class: 'modal-md', backdrop: 'static', keyboard: false })
  }

  generateOutputClick() {
    this.checkingOTNList = [];
    this.assetIrnCheckList = []
    this.assetOpenPunchlistCheckList = []
    this.hasAssetWithNoIRN = false;
    this.hasAssetWitOpenPunchlists = false;
    var assetsStr = "";
    for (let i = 0; i < this.rowData.length; i++) {
      if (this.rowData[i].mccStatus == "NotIssued") {
        if (i != this.rowData.length - 1) {
          assetsStr += this.rowData[i].systemId + ",";
        } else {
          assetsStr += this.rowData[i].systemId;
        }
      }
    }
    let i = 0;
    this.rowData.forEach((row) => {
      if (row.otnStatus == "NotIssued") {
        var assets = [];
        row.otnAssets.forEach((asset) => {
          assets.push({ assetName: asset.name, assetId: asset.id })
          assetsStr += asset.id + ",";
        })
        this.checkingOTNList.push({ assets: assets, otnId: row.otnId })
      }
    });

    assetsStr = assetsStr.slice(0, -1);

    this.rowData.forEach((row) => {
      if (row.otnStatus == "NotIssued") {
        //var assets = [];
        if(row.assetHasIrnList!=undefined && row.assetHasIrnList!=null && row.assetHasIrnList.length!=0){
          for(var i = 0; i < row.assetHasIrnList.length; i++){
            this.assetIrnCheckList.push(row.assetHasIrnList[i]);
          }
        }

        if(row.assetHasOpenPlList!=undefined && row.assetHasOpenPlList!=null && row.assetHasOpenPlList.length!=0){
          for(var i = 0; i < row.assetHasOpenPlList.length; i++){
            this.assetOpenPunchlistCheckList.push(row.assetHasOpenPlList[i]);
          }
        }
        //this.assetIrnCheckList.push(row.assetHasIrnList);
      
      }
      console.log(this.assetIrnCheckList)
    });
    		if(this.assetIrnCheckList != undefined && this.assetIrnCheckList != null && this.assetIrnCheckList.length != 0){
					this.hasAssetWithNoIRN = true;
				}
				else{
					this.hasAssetWithNoIRN = false;
				}

        if(this.assetOpenPunchlistCheckList != undefined && this.assetOpenPunchlistCheckList != null && this.assetOpenPunchlistCheckList.length != 0){
					this.hasAssetWitOpenPunchlists = true;
				}
				else{
					this.hasAssetWitOpenPunchlists = false;
				}

        if (this.hasAssetWithNoIRN || this.hasAssetWitOpenPunchlists) {
            this.incompleteModalRef = this.modalService.show(this.incompleteOTNModal, { class: 'modal-lg' });
        }
        else {
            this.generateOutput();
        }

    // if (this.checkingOTNList.length > 0) {
    //   this.loadingService.showLoading(true, false, 'loading', 0);
    //   this.assetsHasIrn = true;
    //   this.manageotnService.isAssetsHasIRN(assetsStr)
    //     .subscribe(data => {
    //       if (data != undefined || data != null) {
    //         this.assetIrnCheckList = data.payload;
    //         for (let i = 0; i < this.assetIrnCheckList.length; i++) {
    //           if (!this.hasAssetWithNoIRN) {
    //             // if there is a asset which has no IRN set hasAssetWithNoIRN to true
    //             this.hasAssetWithNoIRN = !this.assetIrnCheckList[i].hasIRN;
    //           }
    //         }
    //       }
    //       this.manageotnService.isAllPunchlistItemsClosedByCategoryAndAsset("A", assetsStr)
    //         .subscribe(data2 => {
    //           this.loadingService.hideLoading()
    //           if (data2 != undefined || data2 != null) {
    //             this.assetOpenPunchlistCheckList = data2.payload;
    //             for (let i = 0; i < this.assetOpenPunchlistCheckList.length; i++) {
    //               if (!this.hasAssetWitOpenPunchlists) {
    //                 // if there is a asset which has not closed punchlists set hasAssetWithNotClosedPunchlists to true
    //                 this.hasAssetWitOpenPunchlists = this.assetOpenPunchlistCheckList[i].hasOpenPunchlists;
    //               }
    //             }
    //           }

    //           if (this.hasAssetWithNoIRN || this.hasAssetWitOpenPunchlists) {
    //             this.incompleteModalRef = this.modalService.show(this.incompleteOTNModal, { class: 'modal-lg' });
    //           }
    //           else {
    //             this.generateOutput();
    //           }
    //         },
    //           error => {
    //             this.loadingService.hideLoading()
    //           });
    //     },
    //       error => {
    //         this.loadingService.hideLoading()
    //       });
    // } else {

    // }
  }

  public generateOutput() {
    let ids = [];
    this.checkingOTNList.forEach((item) => {
      ids.push(item.otnId);
    })
    let type = "Onshore Turnover Notice";
    this.loadingService.showLoading(true, null, "", null);
    this.filesDataService.getOutputTypeListByProjectIdDocType(type)
      .subscribe(data => {
        this.loadingService.hideLoading()
        this.selectedOutputType = []
        if (data.payload != null && data.payload.length > 0) {
          data.payload.forEach(element => {
            this.selectedOutputType.push({ 'id': element, 'text': element })
          });
          const initialState: object = {
            selectedOutputType: this.selectedOutputType,
            outputData: "",
            idList: ids,
            type: type,
            outputType: this.selectedOutputType[0].text,
            outputId: this.selectedOutputType[0].id,
            showTable: false
          }
          this.bsModalRef = this.modalService.show(OutputGenerateModalComponent, { class: 'modal-lg', initialState });

        } else {
          this.alertService.warn("Please add the Onshore Turnover Notice template into this project");
        }
      },
        error => {
          this.loadingService.hideLoading()
          this.alertService.clear()
          this.alertService.error(error.statusDescription)
        });
  }

  continue() {
    if (this.incompleteModalRef) {
      this.assetIrnCheckList = []
      this.assetOpenPunchlistCheckList = []
      this.hasAssetWithNoIRN = false;
      this.hasAssetWitOpenPunchlists = false;
      this.incompleteModalRef.hide();
    }
    this.generateOutput()
  }
  
  cancelOutGenerete(){
    if(this.bsModalRef){
      this.bsModalRef.hide()
    }
    this.assetIrnCheckList = []
    this.assetOpenPunchlistCheckList = []
    this.hasAssetWithNoIRN = false;
    this.hasAssetWitOpenPunchlists = false;
    this.incompleteModalRef.hide()
  }

  onSuccess(data: WsResponse, serviceType: WsType) {
      if(serviceType == WsType.DELETE_REVOKE_OTN){
        this.loadingService.hideLoading();
        if (data.payload.action == "delete") {
          this.alertService.success("Successfully deleted the OTN")
        }
        else {
          this.alertService.success("Successfully revoked the OTN")
        }
        this.reloadData();
        // if(data.payload){
        //   let itemIndex = this.rowData.findIndex(item => item.otnId == data.payload.id);
        //   if (this.rowData[itemIndex]) {
        //     if(this.rowData[itemIndex].otnStatus == "Issued" || this.rowData[itemIndex].otnStatus == "Error"
        //         || this.rowData[itemIndex].otnStatus == "OnQueue" ){

        //       this.rowData[itemIndex].otnStatus = "NotIssued";
        //       this.rowData[itemIndex].approve= "approve:NotIssued"
        //       this.rowData[itemIndex].edit= "edit:NotIssued"
        //       this.rowData[itemIndex].delete= "delete:NotIssued"
        //       this.rowData[itemIndex].sentpdf= "sentpdf:NotIssued"
        //       this.rowData[itemIndex].returnedpdf= "returnedpdf:NotIssued:false"

        //     }else if(this.rowData[itemIndex].otnStatus == "Approved"){
              
        //       this.rowData[itemIndex].otnStatus = "Issued";
        //       this.rowData[itemIndex].approve= "approve:Issued"
        //       this.rowData[itemIndex].edit= "edit:Issued"
        //       this.rowData[itemIndex].delete= "delete:Issued"
        //       this.rowData[itemIndex].sentpdf= "sentpdf:Issued"
        //       this.rowData[itemIndex].returnedpdf= "returnedpdf:Issued:false"
        //     }
        //     else if(this.rowData[itemIndex].otnStatus == "NotIssued") {
        //       this.rowData.splice(itemIndex, 1);
        //     }
        //     this.gridApi.setRowData(this.rowData);
        //     this.generateOut = false;
        //     if(this.rowData.length > 0 ){
        //       this.rowData.forEach((row)=>{
        //         if(row.otnStatus == "NotIssued"){
        //           this.generateOut = true;
        //         }
        //       })
        //     }else{
        //       this.generateOut = false;
        //     }
        //   }
        // }
    }

  }

  onFail(data: WsResponse, serviceType: WsType) {
    if(serviceType == WsType.DELETE_REVOKE_OTN){
      this.loadingService.hideLoading();
      if (data.payload.action == "delete") {
        this.alertService.error("Could not delete OTN");
      }
      else {
        this.alertService.error("Could not revoke OTN");
      }
    }
  }

  reloadData(){
    this.fireEvent("mainsearchinput", "click");
  }

  private fireEvent( ElementId, EventName ) {
    if( document.getElementById(ElementId) != null ) {
      const ke = new KeyboardEvent("keyup", {
        bubbles: true, cancelable: true, key:"Enter"
      });
      document.getElementById( ElementId ).dispatchEvent( ke );
    }
  }

  updateUIAfterGenerateOutPut(){
    this.generateOut = false;
    if(this.rowData.length > 0 ){
      this.rowData.forEach((row)=>{
        if(row.otnStatus == "NotIssued" ){
          row.otnStatus = "OnQueue";
          row.approve= "approve:OnQueue"
          row.edit= "edit:OnQueue"
          row.delete= "delete:OnQueue"
          row.sentpdf= "sentpdf:OnQueue"
          row.docuSignStatus= null
          row.docusignId= null
          row.returnedpdf= "returnedpdf:OnQueue:false"
        }
      })
    }
    this.gridApi.setRowData(this.rowData);
  }

  updateUIAfterApprove(otnId, uploaded){
    let itemIndex = this.rowData.findIndex(item => item.otnId == otnId);
    if (this.rowData[itemIndex]) {
      this.rowData[itemIndex].otnStatus = "Approved";
      this.rowData[itemIndex].approve= "approve:Approved"
      this.rowData[itemIndex].edit= "edit:Approved"
      this.rowData[itemIndex].delete= "delete:Approved"
      this.rowData[itemIndex].sentpdf= "sentpdf:Approved"
      this.rowData[itemIndex].returnedpdf= "returnedpdf:Approved:"+uploaded
      this.gridApi.setRowData(this.rowData);
      this.generateOut = false;
      if(this.rowData.length > 0 ){
        this.rowData.forEach((row)=>{
          if(row.otnStatus == "NotIssued"){
            this.generateOut = true;
          }
        })
      }
    }
  }

}
