import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { OutputManagementService } from '../outputmanagement.service';
import { UserVariable } from '../../../util/common/user-variable';
import { AlertService } from '../../../util/alert/alert.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from '../../../util/loading/loading.service';

@Component({
   selector: 'addToStore-modal',
   template: `
   <div class="modal-header">
   <h4 class="modal-title pull-left">{{title}}</h4>
   <button type="button" class="close pull-right" aria-label="Close" (click)="cancellClick()">
      <span aria-hidden="true">&times;</span>
   </button>
</div>
<div class="modal-body">
   <form>
      <div class="form-group row">
         <label for="name" class="col-sm-2 col-form-label">{{ 'OUTPUT_MANAGEMENT.NAME' | translate }}
         <div class="badge-required mx-2"></div>
         </label>         
            <div class="col-sm-10">
            <ng-select [active]="selectedItemType" [allowClear]="true" (removed)="removedTempId($event)"
            (selected)="selectedTemplateId($event)" [items]="tempList" name="temp" id="temp">
         </ng-select>
            </div>
      </div>
      <div class="form-group row">
         <label for="tempValue" class="col-sm-2 col-form-label">{{ 'OUTPUT_MANAGEMENT.TITLE' | translate }}
         <div class="badge-required mx-2"></div>
         </label>         
         <div class="col-sm-10">
            <input type="text" class="form-control" id="tempValue"
            [(ngModel)]="selectedTempValue" name="tempValue" required disabled ="true">
         </div>
      </div>
      <div class="form-group row">
         <label for="value" class="col-sm-2 col-form-label">{{ 'OUTPUT_MANAGEMENT.VALUE' | translate }}</label>         
         <div class="col-sm-10">
            <input type="text" class="form-control" id="value"
            [(ngModel)]="value" name="value" required >
         </div>
      </div>
      <div class="form-group row">
         <label for="editable" class="col-sm-2 col-form-label">{{ 'OUTPUT_MANAGEMENT.EDITABLE' | translate }}</label>         
         <div class="col-sm-10">
            <div class="form-check">
               <input type="checkbox" class="form-check-input" id="editable" name="editable" [(ngModel)]="isEditable">
            </div>
         </div>
      </div>
      <div class="form-group row">
         <label for="type" class="col-sm-2 col-form-label">{{ 'OUTPUT_MANAGEMENT.TYPE' | translate }}
         <div class="badge-required mx-2"></div>
         </label>         
            <div class="col-sm-10">
            <ng-select [active]="selectedType" [allowClear]="true" (removed)="removedTypeId($event)"
            (selected)="selectedTypeId($event)" [items]="typeList" name="type" id="type">
         </ng-select>
            </div>
      </div>
      <div class="form-group row">
         <label for="display" class="col-sm-2 col-form-label">{{ 'OUTPUT_MANAGEMENT.DISPLAY' | translate }}</label>         
         <div class="col-sm-10">
            <div class="form-check">
               <input type="checkbox" class="form-check-input" id="display" name="display" [(ngModel)]="isDisplay">
            </div>
         </div>
      </div>
   </form>
</div>
<div class="modal-footer">  
   <button type="button" class="btn btn-primary" (click)="save()">{{ 'Save' | translate}}</button>
   <button type="button" class="btn btn-secondary" (click)="cancellClick()">{{ 'CANCEL' | translate}}</button>
</div>
   `
})
export class AddProjectTempModalComponent {
   title: string = "Import Document Configuration"
   public tempList: Array<any> = [];
   public selectedItemType: Array<any> = [{ 'id': '0', 'text': 'Select Config Name' }];
   private templateId: string = "0";
   public selectedTempValue: string;  
   public value: string;
   public typeList: Array<any> = [];
   public selectedType: Array<any> = [{ 'id': '0', 'text': 'Select Type' }];
   private typeId: string = "0";
   om_translated_data: any = null;
   public isEditable: boolean;
   public isDisplay: boolean;
   public projectTemplateMapId: string;
   public templateValue: string;
   public typeValue: string;
   public configEditable: string;
   public configDisplay: string;
   public loadingMessage: string;
   public id: string;

   constructor(public bsModalRef: BsModalRef, private outputManagementService: OutputManagementService, private alertService: AlertService, private router: Router,
       private translate: TranslateService, private loadingService: LoadingService) { }

   ngOnInit() {
      let browserLang = this.translate.getBrowserLang();
      this.translate.getTranslation(browserLang).subscribe((res: string) => {
         this.om_translated_data = res['OUTPUT_MANAGEMENT']
         this.typeList = [{ 'id': '0', 'text': 'Select Type'},
         { 'id': '1', 'text': this.om_translated_data.TEXT },
         { 'id': '2', 'text': this.om_translated_data.CHECK },
         { 'id': '3', 'text': this.om_translated_data.FACILITY }
         ]
      });
   }

   public selectedTemplateId(event) {
      this.templateId = event.id
      this.templateValue = event.text
      if (this.templateId != "0") {
         this.selectedTempValue = event.text
         this.outputManagementService.getDocumentConfig(this.projectTemplateMapId, this.selectedTempValue)
            .subscribe(data => {
               if (data.payload.configEditable == "yes") {
                  this.isEditable = true
               } else {
                  this.isEditable = false
               }

               if (data.payload.configDisplay == "1") {
                  this.isDisplay = true;
               } else {
                  this.isDisplay = false;
               }

               if (data.payload.configType != "") {
                  if (data.payload.configType == "text") {
                     this.selectedType = [{ 'id': '1', 'text': data.payload.configType }]
                     this.typeId = "1"
                  } else if (data.payload.configType == "check") {
                     this.selectedType = [{ 'id': '2', 'text': data.payload.configType }]
                     this.typeId = "2"
                  } else {
                     this.selectedType = [{ 'id': '3', 'text': data.payload.configType }]
                     this.typeId = "3"
                  }

               } else {
                  this.selectedType = [{ 'id': '0', 'text': 'Select Type' }]
               }
            },
               error => {
                  this.alertService.clear()
                  this.alertService.error(error.statusDescription)
               });
      } else {
         this.selectedTempValue = ""
         this.isEditable = false
         this.isDisplay = false
         this.selectedType = [{ 'id': '0', 'text': 'Select Type' }]
      }
   }

   public removedTempId(event) {
      this.selectedItemType = [{ 'id': '0', 'text': 'Select Config Name' }]
      this.selectedTempValue = ""
      this.isEditable = false
      this.isDisplay = false
   }

   public selectedTypeId(event) {
      this.typeId = event.id
   }

   public removedTypeId(event) {
      this.selectedType = [{ 'id': '0', 'text': 'Select Type' }]
   }

   public save() {
      if (this.templateId == "0") {
         this.alertService.error("Please select a configuration name.")

      } else if (this.typeId == "0") {
         this.alertService.error("Please select a configuration type.")
      } else {
         this.alertService.clear()
         this.loadingService.showLoading(true, null, this.loadingMessage, null)
         if (this.isDisplay == true) {
            this.configDisplay = "1"
         } else {
            this.configDisplay = "0"
         }
         if (this.isEditable == true) {
            this.configEditable = 'yes'
         } else {
            this.configEditable = 'no'
         }
         var importDoc = {
            "templateId": this.projectTemplateMapId,
            "configName": this.templateValue,
            "configTitle": this.selectedTempValue,
            "configEditable": this.configEditable,
            "configType": this.typeValue,
            "configDisplay": this.configDisplay,
            "configValue": this.value
         }
         this.outputManagementService.saveImportedDocumentConfig(importDoc)
            .subscribe(data => {
               if(data.payload != null){
                  let opData = data.payload
                  this.outputManagementService.setItemData(this.projectTemplateMapId, opData.trim(), "opData")
               }
               this.loadingService.hideLoading()
               this.bsModalRef.hide()
            },
               error => {
                  this.bsModalRef.hide()
                  this.loadingService.hideLoading()
                  this.alertService.clear()
                  this.alertService.error(error.statusDescription)
               });
      }

   }

   public cancellClick(){
      this.alertService.clear()
      this.bsModalRef.hide()
   }

}