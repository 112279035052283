<button id="WF_View_Tasks" (click)="viewTasks()" class="btn btn-sm btn-outline-dark py-0 my-1 px-0 border-0" >
    <i class="fas fa-list mx-2" aria-hidden="true"></i></button>

<ng-template #docusignTemplate>
        <div class="modal-header">
                <h5 class="modal-title pull-left">{{ 'VIEW_INSTANCES.VIEW_TASKS' | translate }}</h5>
                <button type="button" id="table_close" class="close pull-right" aria-label="Close" (click)="modelRefCloseEvent()">
                    <span aria-hidden="true">&times;</span>
                </button>
        </div>
    
        <div  class="modal-body">
            <app-docu-sign-person-status  [docusignId] ="docuSignId" [system] ="system" [type]="type" [showTaskName]=true></app-docu-sign-person-status>
    
        </div>
    
        <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-secondary" (click)="modelRefCloseEvent()"> Close</button>
        </div>
    </ng-template>