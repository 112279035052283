import { Component, OnInit, Input, TemplateRef, ElementRef, HostListener, ViewChild} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ColDef, Module, AllModules } from "@ag-grid-enterprise/all-modules";
import { AgGridAngular } from '@ag-grid-community/angular';
import * as FileSaver from 'file-saver';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Subscription } from 'rxjs';
import { UserVariable } from 'src/app/util/common/user-variable';
import { DependenciesmodalComponent } from 'src/app/util/dependenciesmodal/dependenciesmodal.component';
import { FolderService } from '../../foldertree/foldertree.service';
import { AlertService } from '../../util/alert/alert.service';
import { FilesService } from '../../home/files/files.service';
import { CapabilityService } from '../../util/capability.service';
import { Constants } from '../../util/constants';
import { LoadingService } from '../../util/loading/loading.service';
import { LocalStorage } from '../../util/localstorage.service';
import { SharedService } from '../../util/shared.service';
import { WsCallback } from '../../util/ws-callback.interface';
import { WsResponse } from '../../util/ws-response.model';
import { WsType } from '../../util/ws-type';
import { ThreedModelViewerComponent} from 'src/app/util/threed-model-viewer/threed-model-viewer.component';
import { CommonUtil } from '../../util/common/common-util';
import { ICECourse } from '../ice-course.model';
import { ICECourseService } from '../ice-course-service.service';
import { FilesCellRenderComponent } from '../../home/files/files-cell-render-component.component';
import { group } from '@angular/animations';
import { CoursePreviewButtonRenderer } from './course-preview-button-renderer';
declare var $: any;

export interface IHash {
    [key: string]: Array<any>;
}

@Component({
selector: 'app-ice-course-preview',
templateUrl: './ice-course-preview.component.html',
styleUrls: ['./ice-course-preview.component.css']
})

export class IceCoursePreviewComponent implements OnInit, WsCallback {

    public divHeight: number = (window.innerHeight) * 4 / 5;
    @HostListener('window:resize', ['$event'])
    onresize(event?) {
        this.divHeight = (window.innerHeight) * 4 / 5
    }
    @ViewChild('agGrid') agGrid: AgGridAngular;
    @Input() data: any;

    public course: ICECourse;
    public courseNameShowInDetails: any = null;
    public topicHash: IHash = {};
    public iceTopicListByCourseId: Array<any> = [];
    public iceTopicAllListByCourseId: any;
    public topicContentHash: IHash = {};
    public columnDefs: any;
    public fileName: string;
    public fileType: string;
    public checkOutBy: string;
    public lastUpdated: string;
    public hasText: string = "";
    public rowData: any = [];
    public modules: Module[] = AllModules;
    public getRowNodeId;
    public rowSelection: string;
    public selectedItemData: any;
    public disable: boolean = false;
    public selectedCount: number = 0;
    public showDetailPanel: boolean = false;
    public inputData: any;
    public selectedVideoTitle:String;
    public prevSelectedNodesCount: number = null;
    public enableProperties: boolean = false;
    public multipleSelections = false;
    public getDataPath;
    public groupDefaultExpanded;
    public autoGroupColumnDef;
    public detailedPreviewType: string; 
    public displayCourseData: any;
    public displayTopicData: any;
    public displayTopicContentData: any;
    public displayEIGData: any;
    public displaySMIGData: any;
    public displayMGData: any;
    public displayLCData: any;
    public frameworkComponents: any;
    public numOfRowsMG: number = 2;
    public numOfSequencesMG: number = 5;
    public overlayLoadingTemplate: any;
    public overlayNoRowsTemplate: any;
    private gridApi;
    private gridColumnApi;
    public defaultColDef: any;
    private selectedNodes: number;
    public selectedImage: any = {
		'id': '',
		'thumbnailImage': '',
		'largeImage': '',
		'title': ''
    };
    public selectedModalTemplate: TemplateRef<any>;
    modalRef: BsModalRef;
    public modalConfig: any = {
		backdrop: true,
		// ignoreBackdropClick: true,
		class: 'modal-lg',
		// keyboard: false
	}
    private susbscription: Subscription;
    public tabId: String = "";
    public detailLoadSuccess: boolean = false;
    
    constructor(private filesService: FilesService,private sharedService: SharedService, private translate: TranslateService,
    private loadingService: LoadingService, private courseService: ICECourseService, private elementRef: ElementRef, private commonUtil: CommonUtil, private modalService: BsModalService) {
        this.frameworkComponents = {
            buttonRenderer: CoursePreviewButtonRenderer,
        }
        this.columnDefs = [
            { headerName: 'Type', cellClass: 'text-center', field: "itemType", filter: "agTextColumnFilter", autoHeight: true},
            { headerName: 'Description', field: "description", width: 500, filter: "agTextColumnFilter", autoHeight: true, cellStyle: {"white-space": "normal", "display": "flex","justify-content": "flex-start"},
                cellRenderer: function (params: any) {
                    return params.value;
                } 
            },
            { headerName: 'Content', field: "actions", width: 200, cellRenderer: 'buttonRenderer', filter: false, autoHeight: true,
                suppressMenu: true,
                suppressSorting: true,
                suppressFiltersToolPanel: true,  
                floatingFilterComponentParams: {suppressFiltersToolPanelButton:true},
                cellRendererParams: {
                    onClick: this.showItemDetails.bind(this),
                label: ''
                }
            },
        ];
        this.rowSelection = "multiple";
        this.defaultColDef = {
			resizable: true,
            sortable: true,
            floatingFilter: true
		}
        this.groupDefaultExpanded = 1;
        this.getDataPath = function(data) {
            return data.name;
        };
        this.autoGroupColumnDef = {
            headerName: "Course Hierarchy",
            rowGroup: true,
            filter: "agTextColumnFilter",
            autoHeight: true,
            cellStyle: {"white-space": "normal", "display": "flex","justify-content": "flex-start"},
            cellRendererParams: { 
                suppressCount: true,
                innerRenderer: function (params: any) {
                    if(params.value.indexOf("######@Removable") !== -1){
                        return "";
                    } else {
                        let itemType = params.value.split("_");
                        switch (itemType[0]) {
                            case 'EIG':
                                return "Einstein (Instructor) Game"
                            case 'SMSG':
                                return "Speed Match (Student) Game"
                            case 'MG':
                                return "Memory (Instructor) Game"
                            case 'WAMG':
                                return "Whack a Mole (Instructor)"
                            case 'LC':
                                return "Learning Check"
                            default:
                                return params.value
                        }
                    }
                    
                }
            },
        };
        this.getRowNodeId = function (data) {
            return data.id;
        };
        this.overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Loading data...</span>';
        this.overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">Loading data...</span>';
    }

    onGridReady(params) {
        const allColumnIds = [];
        this.columnDefs.forEach((columnDef) => {
            allColumnIds.push(<ColDef>columnDef);
        });
        this.gridColumnApi = params.columnApi;
        this.gridColumnApi.autoSizeColumns(allColumnIds);
        this.gridApi = params.api;
        params.api.sizeColumnsToFit();
        // window.addEventListener("resize", function () {
        //     setTimeout(function () {
        //         params.api.sizeColumnsToFit();
        //     });
        // });
        window.addEventListener("resize", this.onWindowResize);
    }

    private onWindowResize = () => {
   
		setTimeout(() => {
			if (this.gridApi) {
				this.gridApi.sizeColumnsToFit();
			}
		}); 
	};

    ngOnDestroy(){
        window.removeEventListener("resize", this.onWindowResize);
        this.gridApi.destroy();
        this.gridApi = null;
     }
    onRowSelected(event) {
        this.selectedItemData = undefined;
        let rowSelected: boolean = event.node.selected;
        this.disable = true;
        //console.log("hhhh")
        this.selectedNodes = this.agGrid.api.getSelectedNodes().length;

        let nodes = "";
        for (let i = 0; i < this.selectedNodes; i++) {
            nodes = nodes + this.agGrid.api.getSelectedNodes()[i].data.type.split(':')[0] + '|' + this.agGrid.api.getSelectedNodes()[i].data.id + '::';
        }
        let type: any;
        if (rowSelected && (this.selectedCount < this.selectedNodes)) {
            this.showDetailPanel = false;
            this.selectedItemData = event.data;
            this.inputData = event.data;
            type = this.selectedItemData.type.split(':')[0];
            this.selectedVideoTitle = this.selectedItemData.name;
            console.log("title :"+ this.selectedVideoTitle );
        }
        this.onSelectionChanged(rowSelected, type);
    }

    onlastChildChanged(params) {
        //not implemented yet
    }

    onSelectionChanged(rowSelected: boolean, type: any) {
        console.log("[onSelectionChanged] | Row is selected or not: " + rowSelected);
        var selectedRows = this.gridApi.getSelectedRows();
        const selectedNodes = this.agGrid.api.getSelectedNodes().length;
        this.prevSelectedNodesCount = this.selectedNodes;
        let nodes = "";
        for (let i = 0; i < selectedNodes; i++) {
            nodes = nodes + this.agGrid.api.getSelectedNodes()[i].data.type.split(':')[0] + '|' + this.agGrid.api.getSelectedNodes()[i].data.id + '::';
        }
        var selectedRows = this.gridApi.getSelectedRows();
        var selectedRowsString = "";
        selectedRows.forEach(function (selectedRow, index) {
            if (index > 5) {
                return;
            }
            if (index !== 0) {
                selectedRowsString += ", ";
            }
            selectedRowsString += selectedRow.athlete;
        });
        if (selectedRows.length >= 5) {
            selectedRowsString += " - and " + (selectedRows.length - 5) + " others";
        }
    }

    ngOnInit() {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
        this.courseService.getICECourseDataPreview(this.data.id, UserVariable.getUserToken(), this);
    }

    onSuccess(data: WsResponse, serviceType: WsType) {
        if (serviceType == WsType.GET_ICE_COURSE_PREVIEW_DATA) {
            let self = this;
            self.rowData = JSON.parse(data.payload);
        } else if (serviceType == WsType.GET_ICE_COURSE_PREVIEW_CONTENT) {
            this.detailLoadSuccess = true;
            this.showItemDetails(null,data.payload);
        } else if (serviceType == WsType.GET_ICE_COURSE_TOPIC_CONTENTS) {
            this.topicContentHash = data.payload
            this.loadingService.hideLoading();
        } else if (serviceType == WsType.GET_SELECTED_IMAGE_DATA) {
            this.selectedImage = JSON.parse(data.payload);
            this.loadingService.hideLoading();
            this.modalRef = this.modalService.show(this.selectedModalTemplate, this.modalConfig);
        }
    }

    onFail(data: WsResponse, serviceType: WsType) {
        if (serviceType == WsType.GET_ICE_COURSE_PREVIEW_DATA) {
            this.overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">No rows to show</span>';
        } else if (serviceType == WsType.GET_ICE_COURSE_PREVIEW_CONTENT) {
            this.detailLoadSuccess = false;
            this.loadingService.hideLoading();
        } else if (serviceType == WsType.GET_SELECTED_IMAGE_DATA) {
            this.loadingService.hideLoading();
        }
    }

    ngAfterContentInit() {
		this.susbscription = SharedService.tabIdRespond().subscribe(() => this.tabId = SharedService.getTabId())
	}

    showItemDetails(e,courseContent) {
        // show loading message
        var loadingMessage = null;
        this.translate.get('ICE_COURSE.COURSE_PREVIEW_CONTENT_LOADING').subscribe((res: string) => {
            loadingMessage = res;
        });
        this.loadingService.showLoading(true, null, loadingMessage, null);
        if(!this.detailLoadSuccess) {
            this.detailedPreviewType = e.rowData.actions.Type;
            this.courseService.getICECourseDataPreviewDetails(this.data.id, this.detailedPreviewType, e.rowData.actions.RefID, UserVariable.getUserToken(), this);
        } else {
            if(this.detailedPreviewType == 'GameEIG' ) {
                this.displayEIGData = JSON.parse(courseContent);
            } else if(this.detailedPreviewType == 'GameSMSG' ) {
                this.displaySMIGData = JSON.parse(courseContent);
            } else if(this.detailedPreviewType == 'GameMG' ) {
                this.displayMGData = {};
                if(courseContent != "") {
                    let gameDataObject = JSON.parse(courseContent);	
                    this.numOfRowsMG = 2;
                    this.numOfSequencesMG = 5;
                    var qaPanels = {};
                    this.displayMGData.target = gameDataObject.matchGame.target;
                    for(let i = 0; i < this.numOfRowsMG; i++) {
                        qaPanels[i] = {};
                        qaPanels[i].qaPanelData = {};
                        for(let x = 0; x < this.numOfSequencesMG; x++) {
                            var qIndex = i * 10 + x;
                            var aIndex = qIndex + 5;
                            var tagIndex = x + 1 + i * 5;
                            qaPanels[i].qaPanelData[x] = {};
                            if(gameDataObject.matchGame.matchContent[qIndex] != undefined){
                                if(gameDataObject.matchGame.matchContent[qIndex].type == "text"){
                                    qaPanels[i].qaPanelData[x].questionIsText = true;
                                    qaPanels[i].qaPanelData[x].questionIsImage = false;
                                    qaPanels[i].qaPanelData[x].questionTextData = gameDataObject.matchGame.matchContent[qIndex].text ;
                                    qaPanels[i].qaPanelData[x].questionImageData = {};
                                }else if(gameDataObject.matchGame.matchContent[qIndex].type == "image"){
                                    qaPanels[i].qaPanelData[x].questionIsText = false;
                                    qaPanels[i].qaPanelData[x].questionIsImage = true;
                                    qaPanels[i].qaPanelData[x].questionTextData = "";
                                    qaPanels[i].qaPanelData[x].questionImageData = {};
                                    qaPanels[i].qaPanelData[x].questionImageData = gameDataObject.matchGame.matchContent[qIndex].text ;
                                }else{
                                    qaPanels[i].qaPanelData[x].questionIsText = false;
                                    qaPanels[i].qaPanelData[x].questionIsImage = false;
                                    qaPanels[i].qaPanelData[x].questionTextData = "";
                                    qaPanels[i].qaPanelData[x].questionImageData = {};
                                    qaPanels[i].qaPanelData[x].questionImageData = "";
                                }
                            }
                            if(gameDataObject.matchGame.matchContent[aIndex] != undefined){
                                if(gameDataObject.matchGame.matchContent[aIndex].type == "text"){
                                    qaPanels[i].qaPanelData[x].answerIsText = true;
                                    qaPanels[i].qaPanelData[x].answerIsImage = false;
                                    qaPanels[i].qaPanelData[x].answerTextData = gameDataObject.matchGame.matchContent[aIndex].text ;
                                    qaPanels[i].qaPanelData[x].answerImageData = {};
                                }else if(gameDataObject.matchGame.matchContent[aIndex].type == "image"){
                                    qaPanels[i].qaPanelData[x].answerIsText = false;
                                    qaPanels[i].qaPanelData[x].answerIsImage = true;
                                    qaPanels[i].qaPanelData[x].answerTextData = "";
                                    qaPanels[i].qaPanelData[x].answerImageData = {};
                                    qaPanels[i].qaPanelData[x].answerImageData = gameDataObject.matchGame.matchContent[aIndex].text ;
                                }else{
                                    qaPanels[i].qaPanelData[x].answerIsText = false;
                                    qaPanels[i].qaPanelData[x].answerIsImage = false;
                                    qaPanels[i].qaPanelData[x].answerTextData = "";
                                    qaPanels[i].qaPanelData[x].answerImageData = {};
                                    qaPanels[i].qaPanelData[x].answerImageData = "";
                                }
                            }
                        }
                    }
                    this.displayMGData.qaPanels = qaPanels
                }
            } else if(this.detailedPreviewType == 'LearningCheck' ) {
                this.displayLCData = JSON.parse(courseContent);
            } else {
                this.displayCourseData = JSON.parse(courseContent);
            }
            this.detailLoadSuccess = false;
            this.loadingService.hideLoading();
            window.dispatchEvent(new Event('resize'));
        }
    }

    public getStringFromType(questionType: string): string {
        switch (questionType) {
            case 'singleanswer':
                return "Single Choice"
            case 'multipleanswer':
                return "Multiple Choice"
            case 'truefalse':
                return "True or False"
            case 'dragdrop':
                return "Drag and Drop"
            case 'fillintheblank':
                return "Fill in the Blank Question"
            default:
                //return nothing
        }
    }

    showLargeImage(template: TemplateRef<any>, selectedImageId: any) {
        this.selectedImage = {
			'id': '',
			'thumbnailImage': '',
			'largeImage': '',
			'title': ''
		}
        //show loading message
        var loadingMessage = null;
        this.translate.get('ICE_COURSE.COURSE_PREVIEW_IMAGE_LOADING').subscribe((res: string) => {
            loadingMessage = res;
        });
		this.loadingService.showLoading(true, null, loadingMessage, null);
        this.courseService.getSelectedImage(selectedImageId, UserVariable.getUserToken(), this);
        this.selectedModalTemplate = template;
    }
    
    public modelRefCloseEvent() {
		this.modalRef.hide()
		this.selectedImage = {
			'id': '',
			'thumbnailImage': '',
			'largeImage': '',
			'title': ''
		}
	}
}
