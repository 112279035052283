import { Component, OnInit, Input, HostListener, TemplateRef } from '@angular/core';
import { SharedService } from '../../util/shared.service';
import { IMAGE_UI_VIEW, SessionStorageConst } from '../../util/constants';
import { Constants, UI_LAYOUT, LocalStorageConst } from '../../util/constants';
import { BsModalService } from 'ngx-bootstrap/modal'
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService } from '../../util/alert/alert.service';
import { GridOptions } from "@ag-grid-enterprise/all-modules";
import { ImagesService } from './images.service';
import { SystemsService } from '../systems/systems.service';
import { TranslateService } from '@ngx-translate/core';
import { Image } from './image.model';
import { LoadingService } from '../../util/loading/loading.service';

@Component({
	selector: 'app-images',
	templateUrl: './images.component.html',
	styleUrls: ['./images.component.css']
})
export class ImagesComponent implements OnInit {

	public divHeight: number = (window.innerHeight) * 4 / 5;
	base64Data: any;
	base64DataImage: any;
	@HostListener('window:resize', ['$event'])
	onresize(event?) {
		this.divHeight = (window.innerHeight) * 4 / 5
	}

	public constants: any

	public imageName: string = 'Image Name'
	modalRef: BsModalRef;
	private docType: string = "";
	private description: string = "";
	private imagesystem: string = "";
	private imagedoc: string = "";
	private imagePath: string = "";
	private imageThumbPath: string = "";
	private imgSrc: String = "";
	private thumbPath: String = "";
	private id: string = "";
	public title: string = "";
	public width: string = "";
	public height: string = "";
	private thumbnailImage: string = "";
	private fullImage: string = "";
	private count = 0;
	changeLayout: boolean = false
	public imageUIV: any = IMAGE_UI_VIEW;
	private hasText: string = "";
	public gridOptions: GridOptions;
	public imageData: Array<any> = [];
	public images: Array<any> = [];
	public fullimages: Array<any> = [];
	public fullimagearr: Array<any> = [];
	public imageList: Array<any> = [];
	private timeoutId: any = null;
	public selectedImage: any = {
		'id': '',
		'largeImage': '',
		'thumbnailImage': '',
		'title': '',
		'folderID': '',
		'folderPath': ''
	};
	public flippedImage: any = {
		'id': '',
		'largeImage': '',
		'thumbnailImage': '',
		'title': '',
		'folderID': '',
		'folderPath': ''
	}
	public modalConfig: any = {
		backdrop: true,
		ignoreBackdropClick: true,
		class: 'modal-lg',
		keyboard: false
	}
	public isOpen: boolean = false
	public imageSearchState: string = Constants.LOADING_STATES.INITIAL

	constructor(private sharedService: SharedService, private loadingService: LoadingService,
		private modalService: BsModalService, private alertService: AlertService, private imagesService: ImagesService,
		private systemsService: SystemsService, private translate: TranslateService
	) {
		this.constants = Constants;
		translate.addLangs(["es", "en"]);
		translate.setDefaultLang("en");
		let browserLang = translate.getBrowserLang();
		translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
	}

	ngOnInit() {
		let uiLayout = JSON.parse(sessionStorage.getItem(SessionStorageConst.UI_LAYOUT));
		this.images = this.imagesService.getImageList();
		this.imageSearchState = this.imagesService.getImageSearchState();
		if (uiLayout == UI_LAYOUT.UIL_2) {
			this.changeLayout = false
		} else {
			this.changeLayout = true
		}
	}

	ngAfterViewInit(): void {
		//Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
		//Add 'implements AfterViewInit' to the class.
		this.images = this.imagesService.getImageList();
		this.imageSearchState = this.imagesService.getImageSearchState();
		this.sharedService.expandReusablesExcute().subscribe(option => this.changeComponent(option))
	}

	changeComponent(option: any) {
		console.log('[changeComponent] (option) ' + JSON.stringify(option));
	}

	searchFunc(event: any) {
		this.resetSelected()
		this.images = [];
		// this.imageList = [];
		console.log('[searchFunc] (event) ' + JSON.stringify(event));
		console.log('[searchFunc] (images.length) ' + JSON.stringify(this.images.length));
		this.divHeight = (window.innerHeight) * 4 / 5;

		this.docType = "";
		this.description = "";
		this.imagesystem = "";
		this.imagedoc = "";
		this.hasText = "";
		this.count = 0;

		this.imageSearchState = Constants.LOADING_STATES.LOADING

		//assign value for the fields from searchWordMap
		var docType = event.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_IMAGE_IMG_DOCTYPE];
		if (docType != undefined) {
			this.docType = docType;
		} else {
			//no docType
		}
		var description = event.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_IMAGE_IMG_DESCRIPTION];
		if (description != undefined) {
			this.description = description;
		} else {
			//no description
		}

		var imagesystem = event.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_IMAGE_IMG_SYSTEM];
		if (imagesystem != undefined) {
			this.imagesystem = imagesystem;
		} else {
			//no imagesystem
		}

		var imagedoc = event.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_IMAGE_IMG_DOCUMENT];
		if (imagedoc != undefined) {
			this.imagedoc = imagedoc;
		} else {
			//no imagedoc
		}

		var hasText = event.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_HAS_THE_WORDS];
		if (hasText != undefined) {
			this.hasText = hasText;
		} else {
			//no has text
		}

		/*event.jsonData.forEach(item => {
			switch (item.key) {
				case Constants.SEARCH_KEY_WORDS.SEARCH_IMAGE_IMG_DOCTYPE:
					this.docType = item.value
					break;
				case Constants.SEARCH_KEY_WORDS.SEARCH_IMAGE_IMG_DESCRIPTION:
					this.description = item.value
					break;
				case Constants.SEARCH_KEY_WORDS.SEARCH_IMAGE_IMG_SYSTEM:
					this.imagesystem = item.value
					break;
				case Constants.SEARCH_KEY_WORDS.SEARCH_IMAGE_IMG_DOCUMENT:
					this.imagedoc = item.value
					break;
				default:
					break;
			}
		});*/

		// let docArray = event.searchTxt.split(" ");
		// for (var l = 0; l <= docArray.length - 1; l++) {
		// 	let letter = docArray[l].split(":");
		// 	if (letter != "") {
		// 		for (var i = 0; i < letter.length - 1; i++) {
		// 			if (letter[i] == "docType") {
		// 				this.docType = letter[i + 1];
		// 			}
		// 			else if (letter[i] == "isys") {
		// 				this.imagesystem = letter[i + 1];
		// 			} else if (letter[i] == "idoc") {
		// 				this.imagedoc = letter[i + 1];
		// 			} else if (letter[i] == "description") {
		// 				this.description = letter[i + 1];
		// 			} else {
		// 				if (this.count == 0) {
		// 					this.count = 1;
		// 					this.error();
		// 				} else {
		// 					// do nothing
		// 				}
		// 				this.count = 1;

		// 			}
		// 		}
		// 	}
		// }

		if (this.docType != "" || this.imagesystem != "" || this.description != "" || this.imagedoc != ""|| this.hasText != "") {
			let timeoutId: any = false;
			if (timeoutId) { clearTimeout(timeoutId); }
			timeoutId = setTimeout(() => {
				this.imagesService.searchImages(this.docType, this.imagesystem, this.description, this.imagedoc, this.hasText).subscribe(
					data => {
						// console.log('[data.payload]' + JSON.stringify(JSON.parse(data.payload)));
						var obj = JSON.parse(data.payload);
						// this.getImageThumbPathandId(obj);
						if (obj.images.image) {
							if (obj.images.image.length > 0 || obj.images.image.id) {
								this.getImageDetail(obj)
							} else {
								this.imageSearchState = Constants.LOADING_STATES.EMPTY_RESULT
							}
						} else {
							//no images
							this.imageSearchState = Constants.LOADING_STATES.EMPTY_RESULT
						}
					},
					error => {
						this.alertService.clear()
						this.alertService.error(error.statusDescription)
					});
			}, 200);
		} else {
			this.imageSearchState = Constants.LOADING_STATES.EMPTY_RESULT
		}

	}

	error() {
		this.alertService.error("wrong value inserted");
	}

	getImageDetail(obj: any) {
		this.imageSearchState = Constants.LOADING_STATES.HAS_RESULT;
		this.imagesService.setImageSearchState(this.imageSearchState)
		this.imageList = [];
		let imagesList = obj.images.image;
		this.imageList = obj.images.image;
		// let imageThumbLoc = obj.images.image.imageLocation + obj.images.image.thumbnailImage;
		if (obj.images.image.id) {
			let img = obj.images.image;
			let imageThumbLoc = img.imageLocation + img.thumbnailImage;
			//only one element
			let image = {
				'id': img.id,
				'largeImage': img.fullImage,
				'thumbnailImage': img.thumbnailImage,
				'title': img.title,
				'folderId': img.folderId,
				'folderPath': img.folderPath,
				'thumbnailImagePath': imageThumbLoc
			}
			this.images.push(image)
		} else {
			//list of images
			imagesList.forEach((item: { id: string; fullImage: string; thumbnailImage: string; title: string; folderId: string; folderPath: string; imageLocation: string; }) => {
				let imageThumbLoc = item.imageLocation + item.thumbnailImage;
				let image = {
					'id': item.id,
					'largeImage': item.fullImage,
					'thumbnailImage': item.thumbnailImage,
					'title': item.title,
					'folderId': item.folderId,
					'folderPath': item.folderPath,
					'thumbnailImagePath': imageThumbLoc
				}
				this.images.push(image)
			});
		}
		this.imagesService.setImageList(this.images)
		this.getImageThumbPathandId(obj)
	}

	getImageThumbPathandId(obj: any) {
		// this.imageSearchState = Constants.LOADING_STATES.HAS_RESULT
		if (obj.images.image.length >= 0) {
			for (let i = 0; i < obj.images.image.length; i++) {
				this.id = obj.images.image[i]["id"];
				this.thumbnailImage = obj.images.image[i]["thumbnailImage"];
				this.fullImage = obj.images.image[i]["fullImage"];
				this.title = obj.images.image[i]["title"];
				let timeoutId: any = false;
				if (timeoutId) { clearTimeout(timeoutId); }
				timeoutId = setTimeout(() => {

					this.imagesService.getThumbnailImages(obj.images.image[i]).subscribe(
						data => {
							if (data.payload) {
								this.base64Data = data.payload[0];
								this.title = data.payload[1];
								this.id = data.payload[2];
								this.imageThumbPath = data.payload[3];
								this.thumbPath = data.payload[4];

								this.updateArrayImage(this.images, this.id)
								this.imageArray(this.images);

							} else {
								//do nothing
								console.log('for else')
							}

						},
						error => {
							this.alertService.error(error.statusDescription)
						});

				// 	//this.gridOptions.api.onFilterChanged();
				// 	this.imagesService.getImages(obj.images.image[i]).subscribe(
				// 		data => {
				// 			if (data.payload) {
				// 				this.base64Data = data.payload[0];
				// 				this.base64DataImage = data.payload[1];
				// 				this.title = data.payload[2];
				// 				this.id = data.payload[3];
				// 				this.width = data.payload[4];
				// 				this.height = data.payload[5];
				// 				this.imagePath = data.payload[6];
				// 				this.imageThumbPath = data.payload[7];
				// 				this.imgSrc = data.payload[8];
				// 				this.thumbPath = data.payload[9];
				// 				// this.images.push({
				// 				// 	'id': this.id,
				// 				// 	'largeImage': this.base64DataImage,
				// 				// 	'thumbnailImage': this.base64Data,
				// 				// 	'title': this.title,
				// 				// 	'width': this.width,
				// 				// 	'height': this.height,
				// 				// 	'path': this.imagePath,
				// 				// 	'thumbPath': this.imageThumbPath,
				// 				// 	'imgsrc': this.imgSrc
				// 				// });
				// 				this.updateArrayImage(this.images, this.id)
				// 				this.imageArray(this.images);
				// 				// console.log('this.images', this.images);
				// 			} else {
				// 				//do nothing
				// 				console.log('for else')
				// 			}

				// 		},
				// 		error => {
				// 			this.alertService.error(error.statusDescription)
				// 		});
				}, 200);
			}
		} else {
			this.id = obj.images.image.id;
			this.thumbnailImage = obj.images.image.thumbnailImage;
			this.fullImage = obj.images.image.fullImage;
			this.title = obj.images.image.title;
			this.width = obj.images.image.width;
			this.height = obj.images.image.height;
			let timeoutId: any = false;
			if (timeoutId) { clearTimeout(timeoutId); }
			timeoutId = setTimeout(() => {

				this.imagesService.getThumbnailImages(obj.images.image).subscribe(
					data => {
						if (data.payload) {
							this.base64Data = data.payload[0];
							this.title = data.payload[1];
							this.id = data.payload[2];
							this.imageThumbPath = data.payload[3];
							this.thumbPath = data.payload[4];

							this.updateArrayImage(this.images, this.id)
							

						} else {
							//do nothing
							console.log('for else')
						}

					},
					error => {
						this.alertService.error(error.statusDescription)
					});


			// 	this.imagesService.getImages(obj.images.image).subscribe(
			// 		data => {
			// 			if (data.payload) {
			// 				this.base64Data = data.payload[0];
			// 				this.base64DataImage = data.payload[1];
			// 				this.title = data.payload[2];
			// 				this.width = data.payload[4];
			// 				this.height = data.payload[5];
			// 				this.imagePath = data.payload[6];
			// 				this.imageThumbPath = data.payload[7];
			// 				this.imgSrc = data.payload[8];
			// 				this.thumbPath = data.payload[9];
			// 				this.updateArrayImage(this.images, this.id)
			// 				// this.images.push({
			// 				// 	'id': this.id,
			// 				// 	'largeImage': this.base64DataImage,
			// 				// 	'thumbnailImage': this.base64Data,
			// 				// 	'title': this.title,
			// 				// 	'width': this.width,
			// 				// 	'height': this.height,
			// 				// 	'path': this.imagePath,
			// 				// 	'thumbPath': this.imageThumbPath,
			// 				// 	'imgsrc': this.imgSrc

			// 				// });
			// 			} else {
			// 				//do nothing
			// 			}
			// 		},
			// 		error => {
			// 			this.alertService.clear()
			// 			this.alertService.error(error.statusDescription)
			// 		});
			}, 200);
		}
	}

	imageArray(thumb: any) {
		this.fullimagearr.push({
			'image': thumb
		});
		console.log('this.fullimagearr', this.fullimagearr)
	}

	public updateArrayImage(imageArray: Array<any>, imageId: string) {
		let image: Image = imageArray.find(item => item.id === imageId)
		image.largeImage = this.base64DataImage
		image.thumbnailImage = this.base64Data
		image.title = this.title
		image.width = this.width
		image.height = this.height
		image.path = this.imagePath
		image.thumbPath = this.imageThumbPath
		image.imgsrc = this.imgSrc
		image.imgThumb = this.thumbPath
	}

	searchBarEnterFunc(event: any) {
		this.resetSelected()
		console.log('[searchBarEnterFunc] (event) ' + JSON.stringify(event));
		this.images = [];
		this.divHeight = (window.innerHeight) * 4 / 5;
		this.imageSearchState = Constants.LOADING_STATES.LOADING
		this.docType = "";
		this.description = "";
		this.imagesystem = "";
		this.imagedoc = "";
		this.count = 0;
		//check fro empty values in search bar
		if (this.checkForEmptyValuesInSearchBar(event)) {
			let docArray = event.split(" ");
			for (var l = 0; l <= docArray.length - 1; l++) {
				let letter = docArray[l].split(":");
				if (letter != "") {
					for (var i = 0; i < letter.length - 1; i++) {
						if (letter[i] == "docType") {
							this.docType = letter[i + 1];
						} else if (letter[i] == "isys") {
							this.imagesystem = letter[i + 1];
						} else if (letter[i] == "idoc") {
							this.imagedoc = letter[i + 1];
						} else if (letter[i] == "description") {
							this.description = letter[i + 1];
						} else {
							if (this.count == 0) {
								this.count = 1;
								this.error();
							} else {
								// do nothing
							}
							this.count = 1;
						}
					}
				}
			}
			if (this.docType != "" || this.imagesystem != "" || this.description != "" || this.imagedoc != "") {
				let timeoutId: any = false;
				if (timeoutId) { clearTimeout(timeoutId); }
				timeoutId = setTimeout(() => {
					this.imagesService.searchImages(this.docType, this.imagesystem, this.description, this.imagedoc, this.hasText).subscribe(
						data => {
							console.log(data.payload);
							var obj = JSON.parse(data.payload);
							console.log('obj', obj)
							this.getImageThumbPathandId(obj);
						},
						error => {
							this.alertService.clear()
							this.alertService.error(error.statusDescription)
						});
				}, 200);
			}
		} else {
			this.imageSearchState = Constants.LOADING_STATES.EMPTY_RESULT
		}
	}

	/**
     * check for empty values in search bar
     * @param data 
     */
	public checkForEmptyValuesInSearchBar(data: any): boolean {
		let docArray = data.split(" ");
		if (docArray.length > 1) {
			return true
		} else {
			if (docArray[0] != '') {
				return true
			} else {
				return false
			}
		}
	}

	showSearchOptionEE(event: any) {
		console.log('[showSearchOptionEE] (event) ' + JSON.stringify(event));
		if (event) {
			this.divHeight = (window.innerHeight) * 1.7 / 5
		} else {
			this.divHeight = (window.innerHeight) * 4 / 5
		}

	}

	showLargeImage(template: TemplateRef<any>, selectedImage: any) {
		console.log('[showLargeImage]');
		this.selectedImage = selectedImage;
		this.loadingService.showLoading(true,null,"Image Loading..",null);

			if (this.imageList.length > 1){
				for (let i = 0; i < this.imageList.length; i++) {
				if (this.imageList[i].id == selectedImage.id){
					this.imageList[i].thumbnailImage = this.thumbPath;
					let timeoutId: any = false;
					if (timeoutId) { clearTimeout(timeoutId); }
					timeoutId = setTimeout(() => {
					this.imagesService.getImages(this.imageList[i]).subscribe(
						data => {
							if (data.payload) {
								this.base64Data = data.payload[0];
								this.base64DataImage = data.payload[1];
								this.title = data.payload[2];
								this.width = data.payload[4];
								this.height = data.payload[5];
								this.imagePath = data.payload[6];
								this.imageThumbPath = data.payload[7];
								this.imgSrc = data.payload[8];
								this.thumbPath = data.payload[9];
								let imageToUpdate = [];
								imageToUpdate[0] = this.imageList[i];
								this.updateArrayImage(imageToUpdate, selectedImage.id)
								this.imageArray(imageToUpdate);
								this.modalRef = this.modalService.show(template, this.modalConfig);
							} else {
								//do nothing
							}
							this.loadingService.hideLoading();
						},
						error => {
							this.alertService.clear()
							this.alertService.error(error.statusDescription)
							this.loadingService.hideLoading();
						});
						// this.modalRef = this.modalService.show(template, this.modalConfig);
					}, 200);
					
				}

			}
		} else { 
			if (this.imageList.length != 0){
				let singleResult = [];
				singleResult[0] = this.imageList;
				singleResult[0].thumbnailImage = this.thumbPath;
				// singleResult[0].folderId = 
				let timeoutId: any = false;
				if (timeoutId) { clearTimeout(timeoutId); }
				timeoutId = setTimeout(() => {
				this.imagesService.getImages(singleResult[0]).subscribe(
					data => {
						if (data.payload) {
							this.base64Data = data.payload[0];
							this.base64DataImage = data.payload[1];
							this.title = data.payload[2];
							this.width = data.payload[4];
							this.height = data.payload[5];
							this.imagePath = data.payload[6];
							this.imageThumbPath = data.payload[7];
							this.imgSrc = data.payload[8];
							this.thumbPath = data.payload[9];
							let imageToUpdate = [];
							imageToUpdate[0] = singleResult[0];
							this.updateArrayImage(imageToUpdate, selectedImage.id)
							this.modalRef = this.modalService.show(template, this.modalConfig);

						} else {
							//do nothing
						}
						this.loadingService.hideLoading();
					},
					error => {
						this.alertService.clear()
						this.alertService.error(error.statusDescription)
						this.loadingService.hideLoading();
					});
					// this.modalRef = this.modalService.show(template, this.modalConfig);
				}, 200);

			}	else {
				let noResult = [];
				const NoImageIndex = this.images.findIndex((figure) => figure.id === selectedImage.id);
				noResult[0] = this.images[NoImageIndex];
				noResult[0].thumbnailImage = noResult[0].imgThumb;
				const replacedString = noResult[0].imgThumb.replace(/\/Thumbs\//, "/Images/");
				noResult[0].fullImage = replacedString;
				let timeoutId: any = false;
				if (timeoutId) { clearTimeout(timeoutId); }
				timeoutId = setTimeout(() => {
				this.imagesService.getImages(noResult[0]).subscribe(
					data => {
						if (data.payload) {
							this.base64Data = data.payload[0];
							this.base64DataImage = data.payload[1];
							this.title = data.payload[2];
							this.width = data.payload[4];
							this.height = data.payload[5];
							this.imagePath = data.payload[6];
							this.imageThumbPath = data.payload[7];
							this.imgSrc = data.payload[8];
							this.thumbPath = data.payload[9];
							let imageToUpdate = [];
							imageToUpdate[0] = noResult[0];
							this.updateArrayImage(imageToUpdate, selectedImage.id)
							this.modalRef = this.modalService.show(template, this.modalConfig);

						} else {
							//do nothing
						}
						this.loadingService.hideLoading();
					},
					error => {
						this.alertService.clear()
						this.alertService.error(error.statusDescription)
						this.loadingService.hideLoading();
					});
					// this.modalRef = this.modalService.show(template, this.modalConfig);
				}, 200);
			}	  
		}
		// this.modalRef = this.modalService.show(template, this.modalConfig);
	}

	public modelRefCloseEvent() {
		if (this.modalRef != undefined) {
			this.modalRef.hide()
		}
		this.selectedImage = {
			'id': '',
			'largeImage': '',
			'thumbnailImage': '',
			'title': '',
			'folderID': '',
			'folderPath': ''
		}
	}

	/**
	 * Achini Randeni
	 * Check the status of the image before inserting
	 * @param image 
	 */
	public getFigureInfo(image: any){
		this.imagesService.getImageInfoById(image.id).subscribe(
			data => {
				if(data.payload != null){
					if(data.payload.imageStatus != null && data.payload.imageStatus != 'Active'){
						this.alertService.error("Cannot insert disabled image");
					}else{
						this.insertAsFigure(image);
					}
				}else{

				}
		},
		error => {
			this.alertService.error(error.statusDescription)
		});
	}

	/**
	 * insert image as figure button click event 
	 * both modal button event and popover button event
	 * @param image 
	 */
	public insertAsFigure(image: any) {

		let indexOfArray;
		if(this.imageList.length>1){
			const imageIndex = this.imageList.findIndex((figure) => figure.id === image.id);						
			indexOfArray = this.imageList[imageIndex];
			this.imageList[imageIndex].thumbnailImage = this.thumbPath;
		} else {
			if(this.imageList.length != 0){
				let singleImg = [];
				singleImg[0] = this.imageList;								
				indexOfArray = singleImg[0];
				singleImg[0].thumbnailImage = this.thumbPath;

			} else {
				const imageIndex = this.images.findIndex((figure) => figure.id === image.id);
				let noImg = [];
				noImg[0] = { ...this.images[imageIndex] }; 
				indexOfArray = noImg[0];
				noImg[0].thumbnailImage = noImg[0].imgThumb;
				const replacedString = noImg[0].imgThumb.replace(/\/Thumbs\//, "/Images/");
				noImg[0].fullImage = replacedString;

				
			}
			
		} 
		
		let timeoutId: any = false;
		if (timeoutId) { clearTimeout(timeoutId); }
		timeoutId = setTimeout(() => {
		this.loadingService.showLoading(true,null,"Loading the figure..",null);
		this.imagesService.getImages(indexOfArray).subscribe(
			data => {
				if (data.payload) {

					console.log('[insertAsFigure] (image.title) ' + JSON.stringify(image.title));
					const myObj = {
						id: image.id,
						largeImage: data.payload[1],
						thumbnailImage: image.thumbnailImage,
						title: image.title,
						width: data.payload[4],
						height: data.payload[5],
						path: data.payload[6],
						thumbPath: image.thumbPath,
						type: 'imageAsFigure',
						imgsrc: data.payload[8], 
						imgThumb: image.imgThumb
			
					};
					const myObjStr = JSON.stringify(myObj);
					let event = JSON.parse(myObjStr);
			
					let tabType = SharedService.getTabComponentType();
					if (tabType == 'Editor') {
						SharedService.insertEditorRequest(event);
					} else if (tabType == 'Procedures') {
						SharedService.insertProceduresRequest(event);
					} else if (tabType == 'IceCourse') {
						SharedService.insertICECourseRequest(event);
					}
					this.loadingService.hideLoading();
					this.modelRefCloseEvent();


				} else {
					this.loadingService.hideLoading();
					//do nothing
				}
				
			},
			error => {
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
				this.loadingService.hideLoading();
				
			});	
			
		}, 200);
		

	}

	/**
	 * Achini Randeni
	 * Check the status of the image before inserting
	 * @param image 
	 */
	public getImageInfo(image: any){
		this.imagesService.getImageInfoById(image.id).subscribe(
			data => {
				if(data.payload != null){
					if(data.payload.imageStatus != null && data.payload.imageStatus != 'Active'){
						this.alertService.error("Cannot insert disabled image");
					}else{
						this.insertAsImage(image);
					}
				}else{

				}
		},
		error => {
			this.alertService.error(error.statusDescription)
		});
	}

	/**
	 * insert image as image button click event 
	 * both modal button event and popover button event
	 * @param image 
	 */
	public insertAsImage(image: any) {
		
		let indexOfArray;
		if(this.imageList.length>1){
			const imageIndex = this.imageList.findIndex((figure) => figure.id === image.id);
			indexOfArray = this.imageList[imageIndex];
			this.imageList[imageIndex].thumbnailImage = this.thumbPath;
		} else {

			if(this.imageList.length != 0){
				let singleImg = [];
				singleImg[0] = this.imageList;
				indexOfArray = singleImg[0];
				singleImg[0].thumbnailImage = this.thumbPath;

			} else {
				const imageIndex = this.images.findIndex((figure) => figure.id === image.id);
				let noImg = [];
				noImg[0] = { ...this.images[imageIndex] }; 
				indexOfArray = noImg[0];
				noImg[0].thumbnailImage = noImg[0].imgThumb;
				const replacedString = noImg[0].imgThumb.replace(/\/Thumbs\//, "/Images/");
				noImg[0].fullImage = replacedString;

			}

		} 
		
		let timeoutId: any = false;
		if (timeoutId) { clearTimeout(timeoutId); }
		timeoutId = setTimeout(() => {
		this.loadingService.showLoading(true,null,"Loading the image..",null);
		this.imagesService.getImages(indexOfArray).subscribe(
			data => {
				if (data.payload) {				

				console.log('[insertAsImage] (image.title) ' + JSON.stringify(image.title));
					const myObj = {
						id: image.id,
						largeImage: data.payload[1],
						thumbnailImage: image.thumbnailImage,
						title: image.title,
						width: data.payload[4],
						height: data.payload[5],
						path: data.payload[6],
						thumbPath: image.thumbPath,
						type: 'imageAsImage',
						imgsrc: data.payload[8],
						imgThumb: image.imgThumb

					};
					const myObjStr = JSON.stringify(myObj);
					let event = JSON.parse(myObjStr);

					let tabType = SharedService.getTabComponentType();
					if (tabType == 'Editor') {
						SharedService.insertEditorRequest(event);
					} else if (tabType == 'Procedures') {
						SharedService.insertProceduresRequest(event);
					} else if (tabType == 'IceCourse') {
						SharedService.insertICECourseRequest(event);
					}
					this.loadingService.hideLoading();
					this.modelRefCloseEvent();
				} else {

					this.loadingService.hideLoading();
				}

				},
				error => {
					this.alertService.clear()
					this.alertService.error(error.statusDescription)
					this.loadingService.hideLoading();
					
				});	

		}, 200);
		
	}

	/**
	 * ngx-bootstrap popover onshown event 
	 * Emits an event when the popover is shown
	 * @param image 
	 */
	public onShown(image: any) {
		console.log('[onShown] (image.title) ' + JSON.stringify(image.title));
		this.selectedImage = image
	}

	/**
	 * flip image when user click on image title
	 * @param image 
	 */
	public flipImage(image: any) {
		// console.log('[flipImage] (image) ' + JSON.stringify(image.id));
		this.flippedImage = image
	}

	/**
	 * unflip image 
	 */
	public unFlipImage() {
		this.flippedImage = {
			'id': '',
			'largeImage': '',
			'thumbnailImage': '',
			'title': '',
			'folderID': '',
			'folderPath': ''
		}
	}

	public resetSelected() {
		this.flippedImage = {
			'id': '',
			'largeImage': '',
			'thumbnailImage': '',
			'title': '',
			'folderID': '',
			'folderPath': ''
		}

		this.selectedImage = {
			'id': '',
			'largeImage': '',
			'thumbnailImage': '',
			'title': '',
			'folderID': '',
			'folderPath': ''
		}
	}

	public goToImageLocation(){
		let data = {
			isSearch: false
		}
		let folderPath = this.selectedImage.folderPath;
		if(this.selectedImage.folderPath.substring(this.selectedImage.folderPath.length - 1) == "/" ) {
			folderPath = this.selectedImage.folderPath.substring(0,this.selectedImage.folderPath.length - 1);
		}
		let folderName = folderPath.substring(folderPath.lastIndexOf('/')+1);
		let folderId = this.selectedImage.folderId;
		this.modelRefCloseEvent();
		this.sharedService.openTabRequestWithId(folderId, folderName, "Files", data, folderPath);
        //window.open('/home', '_blank').localStorage.setItem("foldertree",'{"state":{"core":{"selected":["'+this.selectedImage.folderId+'"]}}}');
	}

}
