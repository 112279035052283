<div *ngIf="rowData.length!=0">
	<br>
	<h6>
		<b>Restore {{type}} Versions</b>
	</h6>
		<ag-grid-angular #agGrid style="height: 300px;" class="ag-theme-balham" [gridOptions]="gridOptions"
			 [enableFilter]="true" [rowData]="rowData" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }" [columnDefs]="columnDefs"
			[rowSelection]="'multiple'"  [rowMultiSelectWithClick]="false"
			[components]="components" (onSelectionChanged)=onSelectionChanged()
			 [getRowNodeId]="getRowNodeId" [rowSelection]="rowSelection"(gridReady)="onGridReady($event)" 
			 (lastChildChanged)="onlastChildChanged($event)"(rowSelected)="onRowSelected($event)" >
		</ag-grid-angular>
</div>
<div *ngIf="rowData.length==0">
<b>{{noRevisionsMessage}}</b>
</div>
<div class="row my-2">
	<div class="col-sm-6">
		<div class="checkbox float-left">
			<label class="customcheck my-2">
				<p class="h6 mb-0">{{ 'FILES_DATA.OPEN_NEW_TAB' | translate }}</p>
				<input id="newtab_checkbox" type="checkbox" name="isNewTab" [(checked)]="isNewTab"
					[(ngModel)]="isNewTab">
				<span class="checkmark"></span>
			</label>
		</div>
	</div>
</div>
<div class="modal-footer pb-0 pt-3">
		<button type="button"  id="revisions"
			[disabled]="!enableRevision" class="btn btn-outline-info" container="body"
			(click)="compareRevisionBtnEvent()" [triggers]="triggersType" >
		{{ 'FILES_DATA.COMPARE' | translate }}
		</button>
</div>
