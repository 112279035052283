<div *ngIf="!disable && !disableDelete">
    <button id="landing-table-buttons" class="btn btn-sm btn-outline-dark py-0 my-1 px-1 border-0" (click)="invoke(type, data)">
        <i class={{icon}} aria-hidden="true"></i>
    </button>
</div>
<div *ngIf="disable && !disableDelete">
    <a class="btn btn-sm btn-outline-dark py-0 my-1 px-1 border-0" (click)="invoke(type, data)">
        <i class="text-black-50 {{icon}}" aria-hidden="true"></i>
    </a>
</div>
<div *ngIf="disableDelete">
    <a class="btn btn-sm btn-outline-dark py-0 my-1 px-1 border-0" style="pointer-events: none;cursor: default;">
        <i class="text-black-50 {{icon}}" aria-hidden="true"></i>
    </a>
</div>