import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UserVariable } from 'src/app/util/common/user-variable';
import { CommonUtil } from 'src/app/util/common/common-util';
import { ServiceUrls } from 'src/app/util/service-urls';
import { map, catchError } from 'rxjs/operators';
import { WsResponse } from 'src/app/util/ws-response.model';
import { throwError } from 'rxjs/internal/observable/throwError';

@Injectable({
	providedIn: 'root'
})
export class PdfToProcedurestroeService {

	constructor(private http: HttpClient, private commonUtil: CommonUtil) { }


	/**
	 * Get all systems by the project id
	 */
	public getSystemsByProjectId() {
		let token=UserVariable.getUserToken();
		if (!this.commonUtil.validateToken(token)) {
			return;
		}
		return this.http.get(ServiceUrls.getSystemsByProjectId(token)).pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);					
				return throwError(res);
				} )
		);
	}

	/**
	 * get facility list by project id
	 */
	public getFacilityList() {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        const options = { params: new HttpParams().set("token", token) }
        return this.http.get(ServiceUrls.GET_FACILITY_LIST, options).
            pipe(
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.code,
                        modified.status.name,
                        modified.status.description,
                        modified.payload);
                }),
                catchError(error => {
                    var modified = JSON.parse(JSON.stringify(error.error));
                    var res = new WsResponse(
                        modified.status.code,
                        modified.status.name,
                        modified.status.description,
                        modified.payload);
                    return throwError(res)
                })
            );
    }


	public addPdfToProcedureStore(pdfToProcStore: any ){
		//need to implement
		console.log("need to implement")
		return null
		/*let token = UserVariable.getUserToken();
		if (!this.commonUtil.validateToken(token)) {
			return;
		}
		let url = this.addToProcStoreUrl(token)
		return this.http.post(url, JSON.stringify(pdfToProcStore)).
            pipe(
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                }),
                catchError(error => {
                    var modified = JSON.parse(JSON.stringify(error.error));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    return throwError(res)
                })
            );*/
	}


	
	public addToProcStoreUrl(token: String) {
		let url //= ServiceUrls.PDF_ADD_TO_PROCEDURE_STORE + '?token=' + token;
		return url;
	}



}
