import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StatementsService } from './statements.service'
import { WsCallback } from '../../util/ws-callback.interface';
import { WsType } from '../../util/ws-type';
import { WsResponse } from '../../util/ws-response.model';
import { AlertService } from '../../util/alert/alert.service';
import { AgGridAngular } from '@ag-grid-community/angular';
import { ColDef, GridOptions, Module, AllModules } from "@ag-grid-enterprise/all-modules";
import { SharedService } from '../../util/shared.service';
import { Router } from '@angular/router';
import { STATEMENT_UI_VIEW, SessionStorageConst } from '../../util/constants';
import { StatementsCellRenderComponent } from './statements-cell-render-component.component';
import { Constants, UI_LAYOUT } from '../../util/constants';
//import { NgxXml2jsonService } from 'ngx-xml2json';
import { HistoryService } from '../history/history.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AddeditstatementComponent } from './addeditstatement/addeditstatement.component';
import { DependenciesModalService } from './../../util/dependenciesmodal/dependenciesmodal.service';
import { Subscription } from 'rxjs';
import { CapabilityService } from '../../util/capability.service';
import { Pipe } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";


@Pipe({name: 'safeHtml'})
export class Safe {
    constructor(private sanitizer:DomSanitizer){}
  
    transform(value: any): any {
      return this.sanitizer.bypassSecurityTrustHtml(value);
      // return this.sanitizer.bypassSecurityTrustStyle(style);
      // return this.sanitizer.bypassSecurityTrustXxx(style); - see docs
    }
  }

@Component({
	selector: 'app-statements',
	templateUrl: './statements.component.html',
	styleUrls: ['./statements.component.css']
})
export class StatementsComponent implements OnInit, WsCallback, AfterViewInit, OnDestroy {
	@ViewChild('agGrid') agGrid: AgGridAngular;
	public gridOptions: GridOptions;
	categoryId: any = [];
	categoryName: any = [];
	public overlayLoadingTemplate: any;
	public overlayNoRowsTemplate: any;
	//	category: Category = null;
	changeLayout: boolean = false
	level: any = 0;
	subCategoryList: any = [];
	public expandedView: string; //common variable 
	public suv: any = STATEMENT_UI_VIEW;
	private gridApi;
	public getRowNodeId;
	public columnDefs = [];
	rowData: any = [];
	public modules: Module[] = AllModules;
	private title: string;
	private hasText: string = "";
	private count = 0;
	private statement: string;
	private addCatChild: string;
	private status: string;
	private edit: string;
	private delete: string;
	private selectedCategoryId: any = null;
	resultData: any[];
	private statmentContent: String;
	private reusableType: string = "";
	private resuableText: string = "";
	private reusableXML: string = null;
	private reusableView: string = "";
	public statementDescription: string = "";
	public statementTitle: string = "";
	public display: boolean = false;
	public content: any;
	xml = `<ph tagPHId="a2ebc74b-d294-4663-9c38-2ae8a8789941" restriction="all" >Valve</ph><txt> is/are </txt><ph tagPHId="c7b9b1e4-528a-4cd1-a8f9-c047166f961a" restriction="all" >Closed or Open</ph>`;
	private subsVar: Subscription

	 // Capabilities
	 public modifyCap: boolean = false;
	 public queryCap: boolean = false;
	 public deleteCap: boolean = false;
	 public disableCap: boolean = false;
	 public createCap: boolean = false;
 
	 //enable disable button/icon
	 public enableAddStatement: boolean = false;
	 public enableSearchStatement: boolean = false;
	 public enableChangeStatementStatus: boolean = false;
	 public enableEditStatement: boolean = false;
	 public enableDeleteStatement: boolean = false;

	constructor(private statementsService: StatementsService, private sharedService: SharedService,
		private alertService: AlertService, private modalService: BsModalService,
		private translate: TranslateService, private historyService: HistoryService, private dependenciesmodalService: DependenciesModalService,
		private capabilityService: CapabilityService) {
		translate.addLangs(["es", "en"]);
		translate.setDefaultLang("en");
		let browserLang = translate.getBrowserLang();
		translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');

		this.translate.get('STATEMENT_SEARCH_VIEW.CATEGORY').subscribe((res: string) => {
			this.translate.get('STATEMENT_SEARCH_VIEW.TITLE').subscribe((res: string) => {
				this.title = res;
				this.translate.get('STATEMENT_SEARCH_VIEW.STATEMENT').subscribe((res: string) => {
					this.statement = res;
					this.translate.get('STATEMENT_SEARCH_VIEW.STATUS').subscribe((res: string) => {
						this.status = res;
						this.translate.get('STATEMENT_SEARCH_VIEW.EDIT').subscribe((res: string) => {
							this.edit = res;
							this.translate.get('STATEMENT_SEARCH_VIEW.DELETE').subscribe((res: string) => {
								this.delete = res;
							});
						});
					});
				});
			});
		});

		//check the user capability and enable/disable buttons
		this.enableDisableButtons();

		this.columnDefs = [
			{
				headerName: 'Dependencies',
				field: "dependencies",
				width: 100,
				cellStyle: { 'text-align': 'center' },
				cellRendererFramework: StatementsCellRenderComponent
			},

			{
				headerName: this.title,
				width: 800,
				field: "title",
				sort: 'asc'
			},
			{
				headerName: this.statement,
				field: "statement",
				width: 800,
				cellRenderer: function (params: any) {
					var eDiv = document.createElement('div');
					eDiv.innerHTML = params.data.statement;
					return eDiv;
				}
			},
			{
				headerName: "Category",
				width: 800,
				field: "categoryName",
			},
			{
				headerName: this.status,
				field: "status",
				width: 250,
				hide:!this.enableChangeStatementStatus,
				cellStyle: { 'text-align': 'center' },
				cellRendererFramework: StatementsCellRenderComponent
			},
			{
				headerName: this.edit,
				field: "edit",
				width: 250,
				hide:!this.enableEditStatement,
				cellStyle: { 'text-align': 'center' },
				cellRendererFramework: StatementsCellRenderComponent
			},
			{
				headerName: this.delete,
				field: "delete",
				width: 250,
				hide:!this.enableDeleteStatement,
				cellStyle: { 'text-align': 'center' },
				cellRendererFramework: StatementsCellRenderComponent
			}
		];

		this.gridOptions = {
			rowData: this.rowData,
			columnDefs: this.columnDefs,
			defaultColDef: {
                resizable: true,
                sortable: true
			},
			rowSelection: "multiple",
			rowMultiSelectWithClick: false,
		}

		//custom layout templates for loading and empty data sets
		this.overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Loading data...</span>';
		this.overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">No rows to show</span>';

		this.getRowNodeId = function (data) {
			return data.id;
		};
	}

	close() {
		this.display = false;
	}

	onRowDoubleClicked(event) {
		console.log('event data', event.data);
		SharedService.setContent(event.data.content);
		this.display = true;
		console.log('row', event.data.id);
        let status = event.data.status.split(":")[2];
		if (status != null) {
			if (status == "Active" || status == "active") {
				let tabType = SharedService.getTabComponentType();
				if (tabType == 'Editor') {
					SharedService.insertEditorRequest(event.data);
				} else if (tabType == 'Procedures') {
					SharedService.insertProceduresRequest(event.data);
				} else if (tabType == 'IceCourse') {
					SharedService.insertICECourseRequest(event.data);
				}
				this.reusableView = event.data.statement;
				this.reusableXML = SharedService.getHtmlString();
				this.save();
			} else {
				this.translate.get('REUSABLES.COULD_NOT_ENTER_DISABLED_REUSABLES').subscribe((res: string) => {
                    this.alertService.error(res, false);
                });
			}
		}
	}

	onRowClicked(event) {
		this.statementDescription = event.data.statement;
		this.statementTitle = event.data.title;
		console.log('event', event);
		this.display = true;
	}

	save() {
		if (this.reusableXML != null) {
			let xml = this.reusableXML.replace(/insid=.{38}/gi, function insId() {
                function s4() {
                    return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
                }
                return 'insId="' + s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4() + '"';
            });
			let to: any = false;
			if (to) { clearTimeout(to); }
			to = setTimeout(() => {
				this.reusableType = "Statement";
				this.resuableText = "Statement";
				this.historyService.saveToHistory(this.reusableType, this.resuableText, xml, this.reusableView).subscribe(
					() => {
						console.log("success");
					},
					error => {
						this.alertService.clear()
						this.alertService.error(error.statusDescription)
					});;
			}, 200);
		}
	}


	onGridReady(params) {
		const allColumnIds = [];
		this.columnDefs.forEach((columnDef) => {
			allColumnIds.push(<ColDef>columnDef);
		});
		this.gridApi = params.api;
		this.statementsService.setApiGridData(this.gridApi);
		params.api.sizeColumnsToFit();
		// window.addEventListener("resize", function () {
		// 	setTimeout(function () {
		// 		params.api.sizeColumnsToFit();
		// 	});
		// });
		window.addEventListener("resize", this.onWindowResize);
	}
	private onWindowResize = () => {
   
		setTimeout(() => {
			if (this.gridApi) {
				this.gridApi.sizeColumnsToFit();
			}
		}); 
	};
	ngOnInit() {
		this.level = this.statementsService.getLevel();
		this.expandedView = STATEMENT_UI_VIEW.EXPANDED_VIEW;
		if (this.statementsService.getRowData() != null) {
			this.rowData = this.statementsService.getRowData();
			this.subCategoryList = this.statementsService.getSubCategoryList();
			this.categoryName = this.statementsService.getCategoryData();
		}
		else {
			this.statementsService.getStatementByCategory('0', this);
		}

		let ui = JSON.parse(sessionStorage.getItem(SessionStorageConst.UI_LAYOUT));
		if (ui !== UI_LAYOUT.UIL_6) {
			this.columnDefs = [
				{
					headerName: this.title,
					field: "title",
					width: 800, 
					sort: 'asc'
				},
				{
					headerName: this.statement,
					field: "statement",
					width: 800,
					cellRenderer: function (params: any) {
						var eDiv = document.createElement('div');
						eDiv.innerHTML = params.data.statement;
						return eDiv;
					}                                                                                                                           
				},
				{
					headerName: "Category",
					field: "categoryName",
					width: 800
				}

			];
		}
		else {
			//nothing to chnange
		}
		if (ui == UI_LAYOUT.UIL_2 || ui == UI_LAYOUT.UIL_4) {
			this.expandedView = STATEMENT_UI_VIEW.SMALL_VIEW;
			this.changeLayout = false
		} else {
			this.changeLayout = true
		}
	}

	ngAfterViewInit(): void {
		//Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
		//Add 'implements AfterViewInit' to the class.
		this.subsVar = this.sharedService.expandReusablesExcute().subscribe((option: any) => this.changeComponent(option))
		this.subsVar = this.sharedService.openStatementsEditRespond().subscribe(() => this.openAddEditStatementModal())
	}

	ngOnDestroy(): void {
		//Called once, before the instance is destroyed.
		//Add 'implements OnDestroy' to the class.
		if(this.subsVar) {
			this.subsVar.unsubscribe()
		}

		window.removeEventListener("resize", this.onWindowResize);
		this.gridApi.destroy();
    	this.gridApi = null;
	}

	changeComponent(option: any) {
		console.log('[changeComponent] (option) ' + JSON.stringify(option));
	}

	closeCategory(index) {
		this.categoryName.length = index;
		this.subCategoryList.length = index + 1;
		this.level = index;
		this.statementsService.setLevel(this.level);
	}

	selectCategory(id: any) {
		this.statementsService.getStatementByCategory(id, this);
		this.selectedCategoryId = id;
		this.statementsService.setSelectedCategoryId(this.selectedCategoryId);
		this.categoryName[this.level] = this.subCategoryList[this.level].filter(item => item["id"] === id)[0]["name"];
		this.level = this.level + 1;
		this.statementsService.setLevel(this.level);
		this.statementsService.setCategoryData(this.categoryName);
	}

	loadCategory(categories: any) {
		this.subCategoryList[this.level] = [];
		for (var i = 0; i < categories.length; i++) {
			this.subCategoryList[this.level].push({ id: categories[i]["categoryId"], name: categories[i]["categoryName"] })
		}
		this.statementsService.setSubCategoryList(this.subCategoryList);
	}

	add() {
		this.sharedService.setFileId("-1");
		//this.router.navigateByUrl('addeditstatemet');
		this.openAddEditStatementModal()
	}
	/**
	* open add/edit statement modal 
	* to solve data mission issue when save or cancel
	*/
	private openAddEditStatementModal() {
		console.log('[openAddEditStatementModal]');
		this.modalService.show(AddeditstatementComponent, { class: 'modal-lg', backdrop: 'static', keyboard : false })
	}

	convertString(replaceStr: any, option: any) {
		let replace = this.content.replace(replaceStr, option);
		//console.log("replace" + replace)
		this.content = replace;
	}

	loadStatements(statements: any) {
		if (statements != null) {
			this.rowData = [];
			for (let i = 0; i < statements.length; i++) {
				if (statements[i]["content"] != null) {
					this.statmentContent = statements[i]["content"];
					console.log(" statements[i]" + this.statmentContent)
					// 	let contents = statements[i]["content"].split("txt>")
					// 	if(contents.length > 0)
					// 	{
					// 	for (var j = 1; j < contents.length - 1; j++) {
					// 		var re = />/gi;
					// 		if (contents[j] != "</" && contents[j].search(re) == -1) {
					// 			content = content + contents[j].split("<")[0];
					// 		}
					// 		else if (contents[j] != "</") {
					// 			let innerContents = contents[j].split(">")[1].split("<")[0]
					// 			content = content + innerContents;
					// 		}
					// 	}

					// }else{

					this.content = statements[i]["content"];
					this.convertString(/<statement id="(\w+)-(\w+)-(\w+)-(\w+)-(\w+)" label="((?!=|\,|\.).)+(.)\b">/gi, "");
					this.convertString(/<\/statement>/gi, "");
					this.convertString(/<txt>/gi, "");

					this.convertString(/<\/txt>/gi, "");
					this.convertString(/<B>/gi, "");
					this.convertString(/<\/B>/gi, "");
					this.convertString(/<u>/gi, "");
					this.convertString(/<\/u>/gi, "");
					this.convertString(/<i>/gi, "");
					this.convertString(/<\/i>/gi, "");
					this.convertString(/<br>/gi, "");
					this.convertString(/<ph/gi, "");
					this.convertString(/restriction="(\w+)"/gi, "");
					this.convertString(/tagPHId="(\w+)-(\w+)-(\w+)-(\w+)-(\w+)">/gi, "");
					this.convertString(/catId="(\w+)-(\w+)-(\w+)-(\w+)-(\w+)"/gi, "");
					this.convertString(/<\/ph>/gi, "");
					this.convertString(/<ol/gi, "");
					this.convertString(/ckeditorlist="true"/gi, "");
					this.convertString(/style="list-style-type:decimal">/gi, "");
					this.convertString(/<li>/gi, "");
					this.convertString(/<\/li>/gi, "");
					this.convertString(/<\/ol>/gi, "");
					this.convertString(/<ul/gi, "");
					this.convertString(/<\/ul>/gi, "");
					this.convertString(/style="list-style-type:disc">/gi, "");

					// let contents = statements[i]["content"].split("li>")
					// console.log(" contents" +  this.statmentContent)
					// for (var j = 1; j < contents.length - 1; j++) {
					// 	var re = />/gi;
					// 	if (contents[j] != "</" && contents[j].search(re) == -1) {
					// 		content = content + contents[j].split("<")[0];
					// 	}
					// 	else if (contents[j] != "</") {   
					// 		let innerContents = contents[j].split(">")[1].split("<")[0]
					// 		content = content + innerContents;
					// 	}
					// }

					//	}


				}

				this.dependenciesmodalService.setCallback(this);
				this.rowData.push({
					id: statements[i]["statementId"],
					dependencies:  "disable_dependencies:" + statements[i]["statementId"],
					category: this.categoryName[this.categoryName.length - 1],
					title: statements[i]["title"],
					statement: statements[i]["contentHTML"],
					content: statements[i]["content"],
					type: statements[i]["type"],
					categoryName: statements[i]["categoryName"],
					lastUpdateTimeStamp: statements[i]["lastUpdateTimeStamp"],
					status: "status:" + statements[i]["statementId"] + ":" + statements[i]["status"],
					edit: "edit:" + statements[i]["statementId"] + ":" + statements[i]["status"],
					delete: "delete:" + statements[i]["statementId"] + ":" + statements[i]["status"],
					statementStatus: statements[i]["status"],
				});

				this.dependenciesmodalService.getDependeciesWithId( statements[i]["statementId"], "Statement");
				//console.log('statement',statements);
				//console.log('content',statements[i]["content"]);
				//console.log('projectId',statements[i]["projectId"]);
				this.gridApi.paginationProxy.currentPage = 0;
				this.statementsService.setRowData(this.rowData);
			}
		} else {
			this.rowData = []
			this.statementsService.setRowData(this.rowData);
		}
	}

	onSuccess(data: WsResponse, serviceType: WsType): void {
		if (data.payload != null && serviceType == WsType.STATEMENT_SEARCH_VIEW) {
			if (data.payload.subCategoryList != null) {
				this.loadCategory(data.payload.subCategoryList);
			}
			if (data.payload.statementList != null) {
				this.loadStatements(data.payload.statementList);
				//console.log('statementList',data.payload.statementList);
				this.statementsService.setApiGridData(this.gridApi);
			}
		}
		if (data.payload != null && serviceType == WsType.ITEM_DEPENDENCY_LIST) {
			let items = data.payload.split("_")
			if(items[1]=="Yes")
			{
				 for(let i=0; i<this.rowData.length; i++)
				 {
					 if(this.rowData[i].id == items[0])
					 {
						 //rowNode.setDataValue("dependencies", "dependencies:" + items[0])
						 this.rowData[i].dependencies = "dependencies:" + items[0];
						 if(!this.gridApi.destroyCalled) {
						 	this.gridApi.setRowData(this.rowData);
						 }
					 }
				 }
			}
		 }
	}

	onFail(res: WsResponse, serviceType: WsType): void {
		if (serviceType == WsType.STATEMENT_SEARCH_VIEW) {
			if (res.statusCode == "532") {
				//this.router.navigateByUrl('login');
			}
			else if (res.statusCode == "600") {
				this.statementsService.getStatementByCategory(this.categoryId[this.level], this);
				this.selectedCategoryId = this.categoryId[this.level];
				this.statementsService.setSelectedCategoryId(this.selectedCategoryId);
			} else {
				//show the error message
				this.alertService.error(res.statusDescription.toString(), false);
			}
		}
	}

	searchFunc(data: any) {
		console.log('[searchFunc] (data) ' + JSON.stringify(data.jsonData));
		this.alertService.clear();
		this.statement = "";
		this.categoryId = "";
		this.categoryName = "";
		this.count = 0;
		this.title = "";
		this.hasText = "";
		// console.log('data jason', data.jsonData);
		this.gridApi.showLoadingOverlay();

		//assign value for the fields from searchWordMap
		var statement = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_STATEMENT_STM];
		if (statement != undefined) {
			this.statement = statement;
		} else {
			//no statement
		}
		var title = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_STATEMENT_STM_TITLE];
		if (title != undefined) {
			this.title = title;
		} else {
			//no title
		}

		var category = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_STATEMENT_STM_CATEGORY];
		if (category != undefined) {
			this.categoryName = category;
		} else {
			//no category id
		}

		var hasText = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_HAS_THE_WORDS];
		if (hasText != undefined) {
			this.hasText = hasText;
		} else {
			//no has text
		}

		// let dataj = data.jsonData;
		// // console.log('dataj', dataj[1].key);
		// if (data.jsonData[0].value) {
		// 	this.title = data.jsonData[0].value;
		// } else {
		// 	this.title = "";
		// }
		// if (data.jsonData[1].value) {
		// 	this.statement = data.jsonData[1].value;
		// } else {
		// 	this.statement = ""
		// }
		// if (data.jsonData[2].value != null) {
		// 	this.categoryId = data.jsonData[2].value.catId;
		// } else if (data.jsonData[2].value == null) {
		// 	this.categoryId = "";
		// }

		/*if (this.addCatChild == undefined) {
			//set as false
			this.addCatChild = "false";
		}*/

		this.addCatChild = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_WITH_CHILDREN];
		console.log("this.addCatChild "+this.addCatChild)
		//this.addCatChild = SharedService.getStateCheck();
		if (this.statement != "" || this.categoryName != "" || this.title != "" || this.hasText != "") {
			let to: any = false;
			if (to) { clearTimeout(to); }
			to = setTimeout(() => {
				this.statementsService.searchStatements(this.statement, this.categoryName, this.title, this.addCatChild, this.hasText).subscribe(
					data => {
						this.loadStatements(data.payload)
					},
					error => {
						this.alertService.clear()
						this.alertService.error(error.statusDescription)
					});
			}, 200);
		} else {
			// this.addCatChild = SharedService.getStateCheck();
			// let to: any = false;
			// if (to) { clearTimeout(to); }
			// to = setTimeout(() => {
			// 	//this.componentName = event.target.value;
			// 	this.statementsService.searchStatements(this.statement, this.categoryId, this.title, this.addCatChild).subscribe(
			// 		data => {
			// 			this.loadStatements(data.payload)
			// 		},
			// 		error => {
			// 			this.alertService.clear()
			// 			this.alertService.error(error.statusDescription)
			// 		});
			// }, 200);

			//search panel has empty values
			this.gridApi.hideOverlay()
			this.loadStatements(null)
		}
	}

	searchBarEnterFunc(data: any) {
		console.log('[searchBarEnterFunc] (data) ' + JSON.stringify(data));
		this.alertService.clear();
		this.categoryId = "";
		this.count = 0;
		this.title = "";
		this.addCatChild = SharedService.getStateCheck();
		this.gridApi.showLoadingOverlay();
		//check fro empty values in search bar
		if (this.checkForEmptyValuesInSearchBar(data)) {
			let compArray = data.split(" ");
			for (var l = 0; l <= compArray.length - 1; l++) {
				let letter = compArray[l].split(":");
				if (letter != "") {
					for (var i = 0; i < letter.length - 1; i++) {
						if (letter[i] == "title") {
							this.title = letter[i + 1];
						} else if (letter[i] == "statement") {
							this.statement = letter[i + 1];
						} else {
							if (this.count == 0) {
								this.count = 1;
								this.error();
							} else {
								// do nothing
							}
							this.count = 1;
						}
					}
				}
			}
			if (this.statement != "" || this.categoryId != "" || this.title) {
				let to: any = false;
				if (to) { clearTimeout(to); }
				to = setTimeout(() => {
					this.statementsService.searchStatements(this.statement, this.categoryId, this.title, this.addCatChild, this.hasText).subscribe(
						data => {
							this.loadStatements(data.payload)
						},
						error => {
							this.alertService.clear()
							this.alertService.error(error.statusDescription)
						});
				}, 200);
			} else {
				let to: any = false;
				if (to) { clearTimeout(to); }
				to = setTimeout(() => {
					//this.componentName = event.target.value;
					this.statementsService.searchStatements(this.statement, this.categoryId, this.title, this.addCatChild, this.hasText).subscribe(
						data => {
							this.loadStatements(data.payload)
						},
						error => {
							this.alertService.clear()
							this.alertService.error(error.statusDescription)
						});
				}, 200);
			}
		} else {
			//search bar has empty values
			this.gridApi.hideOverlay()
			this.loadStatements(null)

		}
	}

	error() {
		this.alertService.error("wrong value inserted");
	}

	/**
     * check for empty values in search bar
     * @param data 
     */
	public checkForEmptyValuesInSearchBar(data: any): boolean {
		let docArray = data.split(" ");
		if (docArray.length > 1) {
			return true
		} else {
			if (docArray[0] != '') {
				return true
			} else {
				return false
			}
		}
	}

	/**
     * enable/disable buttons or columns
     */
    private enableDisableButtons(){
        this.capabilityCheck();
        this.enableAddStatement = this.createCap;
        this.enableSearchStatement = this.queryCap;
        this.enableEditStatement = this.modifyCap;
        this.enableDeleteStatement = this.deleteCap;
        this.enableChangeStatementStatus = this.disableCap;
    }

    /**
     * check the capabilities
     */
    public capabilityCheck(){
        let modifyCapability: string = "modifyStatement";
        let queryCapability: string = "queryStatements";
        let deleteCapability: string = "deleteStatement";
        let disableCapability: string = "disableStatement";
        let createCapability: string = "createStatement";

        this.modifyCap = this.capabilityService.isCapabilityAssigned(modifyCapability);
        this.queryCap = this.capabilityService.isCapabilityAssigned(queryCapability);
        this.deleteCap = this.capabilityService.isCapabilityAssigned(deleteCapability);
        this.disableCap = this.capabilityService.isCapabilityAssigned(disableCapability);
        this.createCap = this.capabilityService.isCapabilityAssigned(createCapability);
    }
}
