<nav id="navPanel" class="bg-light border-bottom box-shadow fixed-top navbar-expand-lg" #navPanel>
  <app-header [minimalOption]="true" [showHamburger]="true" (showHideSideBar)="isSideBar = !isSideBar"></app-header>
</nav>
<div *ngIf="isParamsEmpty">
  <div class="loading-panel center">
    <div class="center">
      <tr>
        <img src="../../../assets/images/mainimage.png" alt="" width="auto" height="auto">
      </tr>
      <tr>
        <h5 class="center" style="color: red;">Requested URL cannot be found.</h5>
      </tr>
    </div>
  </div>
</div>