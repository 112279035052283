import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingService } from '../../../util/loading/loading.service';
import { ResetPasswordValidation } from './reset-password-validation.service';
import { WsCallback } from '../../../util/ws-callback.interface';
import { WsResponse } from '../../../util/ws-response.model';
import { WsType } from '../../../util/ws-type';
import { ResetPassword } from './reset-password.service';
import { AlertService } from '../../../util/alert/alert.service';
import { LocalStorage } from '../../../util/localstorage.service';
import { TranslateService } from '../../../../../node_modules/@ngx-translate/core';

@Component({
	selector: 'app-forgotpassword',
	templateUrl: './forgotpassword.component.html',
	styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit, WsCallback {
	newPassword: String;
	confirmPassword: String;

	private action: any;
	private key: any;
	private id: any;

	constructor(private router: Router, public activatedRoute: ActivatedRoute,
		private loadingService: LoadingService, private resetPasswordValidation: ResetPasswordValidation,
		private resetPasswordService: ResetPassword, private alertService: AlertService,
		private translate: TranslateService) {
		translate.addLangs(["es", "en"]);
		translate.setDefaultLang("en");
		let browserLang = translate.getBrowserLang();
		translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
	}

	ngOnInit() {
		//Reading url parameters
		this.activatedRoute.paramMap.subscribe(params => {
			this.action = this.activatedRoute.snapshot.queryParams.action;
			this.key = this.activatedRoute.snapshot.queryParams.key;
			this.id = this.activatedRoute.snapshot.queryParams.id;
		});

		//reading parameters
		console.log('this is the key - ' + this.key);
		this.resetPasswordValidation.setCallback(this);
	}

	ngAfterViewInit() {
		//Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
		//Add 'implements AfterViewInit' to the class.

		console.log(this.action + '---' + this.key + '--' + this.id);

		if (this.action == 'validate' && this.key != null && this.id != null) {
			//show the loading
			this.loadingService.showLoading(true, false, 'Password reset request is being validated', 0);
			//send the data to the server
			this.resetPasswordValidation.getPasswordResetUrl(this.key, this.id);
		} else {
			console.log('Some parameter are not available');
		}
	}

	public cancelForgotPassword(): void {
		this.router.navigateByUrl('/login')
	}

	public resetPassword(): void {
		if (this.confirmPassword == null && this.newPassword == null) {
			this.newPassword ="";
			this.confirmPassword="";
		}

		this.resetPasswordService.setCallback(this);
		this.resetPasswordService.getResetPassword(this.newPassword,
			this.confirmPassword, this.key);
		
	}

	onSuccess(data: WsResponse, serviceType: WsType): void {
		if (serviceType == WsType.PASSWORD_RESET_VALIDATE) {
			this.loadingService.hideLoading();
		} else if (serviceType == WsType.PASSWORD_RESET) {
			//this.router.navigateByUrl('login');
			localStorage.removeItem(LocalStorage.LOGGED_USER);
			this.router.navigateByUrl('changePasswordStatus');
		}
	}

	onFail(res: WsResponse, serviceType: WsType): void {
		if (serviceType == WsType.PASSWORD_RESET_VALIDATE) {
			this.loadingService.hideLoading();
			this.router.navigateByUrl('login');
		} else if (serviceType == WsType.PASSWORD_RESET) {
			this.alertService.error(res.statusDescription);
		}
	}

}
