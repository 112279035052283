import { Component, OnInit, ViewChild, TemplateRef, AfterViewInit ,ɵConsole } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../util/shared.service';
import { AlertService } from '../util/alert/alert.service';
import { ElementRef,HostListener } from '@angular/core'
import { Folder } from '../foldertree/folder.model'
import { LandingService } from 'src/app/landing/landing.service';
import { LoadingService } from 'src/app/util/loading/loading.service';
import { RecentProject } from 'src/app/landing/recent-project.service';
import { LoggedUser } from '../user/logged-user.model';
import { Router } from '@angular/router';
import { FilesService } from 'src/app/home/files/files.service';
import { WsResponse } from 'src/app/util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';
import { TopicmapBuilderService } from './topic-map-builder.service';
import { LocalStorage } from '../util/localstorage.service';
import { UserVariable } from '../util/common/user-variable';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { CommonUtil } from '../util/common/common-util';
import { Module, AllModules } from "@ag-grid-enterprise/all-modules";


declare var $: any;

@Component({
	selector: 'app-topic-map-builder',
	templateUrl: './topic-map-builder.component.html',
	styleUrls: ['./topic-map-builder.component.css']
})
export class TopicMapBuilderComponent implements OnInit,AfterViewInit {

	@ViewChild('insert_other_reference') insert_other_reference: TemplateRef<any>;
	@ViewChild('largeImage') largeImage: TemplateRef<any>;
	@ViewChild('ftHeader') ftHeader: ElementRef;
	@ViewChild("undoCheckOutTemplate") undoCheckOut: TemplateRef<any>;

	
	public data: any = {
		'data': [
			{ "id": "ajson1", "parent": "#", "text": "ROOT NODE", icon: "fa fa-folder-o", type: "folder", isOtherSettings: false },
			{ "id": "ajson1", "parent": "#", "text": "ROOT NODE", icon: "fa fa-folder-o", type: "folder", isOtherSettings: false },
			{ "id": "ajson2", "parent": "#", "text": "Root node 2", icon: "fa fa-folder-o", type: "folder", isOtherSettings: true },
			{ "id": "ajson3", "parent": "ajson2", "text": "Child 1", icon: "fa fa-file-o", type: "file", isOtherSettings: true },
			{ "id": "ajson4", "parent": "ajson2", "text": "Child 2", icon: "fa fa-file-image-o", type: "image", isOtherSettings: false }
		]
	}

	public data_n: any = {
		'data': [
		]
	}
	public treeRefEdit: any = null;
	public treeRefView: any = null
	public selectedItem: any = null
	public isOtherSettings: boolean = false
	public newItem: boolean = false
	public collapseOS: boolean = false
	private loggedInUser: LoggedUser;
	public topicmapContentPayload: any = null;
	//config data variables
	public headerName: string = '';
	public orientation: string = '';
	public specialPaperSize: string = '';
	public omitInTOC: boolean = false;
	public pageBreak: boolean = false;
	public objType: string = '';
	public objSearchSystems: string = '';
	public objSearchVendors: string = '';
	public objSearchCheckSheets: string = '';
	public topicMapType: string = ''
	public oldTopicMapId: string = null
	public topicMapName: string = ''
	//topic map buttons bar variables
	public TOCadded: boolean = false;
	public TOFadded: boolean = false;
	public TOTadded: boolean = false;
	public TODadded: boolean = false;
	public indexAdded: boolean = false;
	public FLTOCadded: boolean = false;
	public FLCOVERadded: boolean = false;
	private dndNode: any = null
	private uniqueIdentifierTOC: string;
	private uniqueIdentifierTOD: string;
	private uniqueIdentifierTOF: string;
	private uniqueIdentifierTOT: string;
	private uniqueIdentifierIndex: string;
	public parentId_seqId_hashmap = new Map();
	private token: any;

	//cross reference variables.
	public refType = "";
	public refText = "";
	public refId = "";
	public refInsId = "";
	public imageId = "";
	public bsModalRef: BsModalRef;
	public bsModalRefLargerImage: BsModalRef;
	public hasTableRef: boolean = false;
	public hasImageRef: boolean = false;
	public imageRowData: any[] = [];
	public tableRowData: any[] = [];
	public rowData: any[] = [];
	public modules: Module[] = AllModules;
	public topicContent: any = null;
	public procedureContent: any = null;
	public columnDefs: any[] = [];
	public selectedOtherReference: string = 'figureRef';
	public imageRef: boolean = true;
	public gridColumnApi: any;
	public imageData = "";
	public imageTitle = "";
	public viewImage: any = {
		'largeImage': '',
		'title': ''
	};
	public isImageSelected: boolean = false;
	public enableInsertRef: boolean = false;
	public enableInsertRefCount: boolean = false;
	public enableInsertOtherRef: boolean = false;
	
	public treeheight_view: number = 600
	public treeheight_edit: number = 400
	
	public flcoverClass: string;
	public flcoverAria:boolean;
	public flTOCClass: string;
	public flTOCAria:boolean;
	public TableColumnDefs = [
		//{ headerName: '', field: 'cbox', width:25, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
		{ headerName: 'Title', field: 'refText', autoHeight: true, cellClass: "cell-wrap-text", width: 250, enableRowGroup: true },
		{ headerName: 'refType', field: 'refType', hide: true },
		{ headerName: 'refInsId', field: 'refInsId', hide: true },
		{ headerName: 'refId', field: 'refId', hide: true }
	];

	public imageColumnDefs = [
		//{ headerName: '', field: 'cbox', width: 25, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
		{ headerName: 'Title', field: 'refText', autoHeight: true, cellClass: "cell-wrap-text", width: 250, enableRowGroup: true },
		{ headerName: 'imageId', field: 'imageId', hide: true },
		{ headerName: 'refType', field: 'refType', hide: true },
		{ headerName: 'refInsId', field: 'refInsId', hide: true },
		{ headerName: 'refId', field: 'refId', hide: true }
	];

	public defaultColDef = {
		resizable: true,
		sortable: true,
	};

	public buttonEnabled: boolean = false;
	public isSaveClicked: boolean = false;
	public isTopicMapEdited: boolean = false;
	public tabId: string =null;
	public isUndoCheckedOut: boolean =false;
	public tree_startLenght: number;
	public tree_stopLenght: number;

	constructor(private translate: TranslateService, private sharedService: SharedService, private alertService: AlertService,
		private topicmapBuilderService: TopicmapBuilderService, private loadingService: LoadingService,
        private modalService: BsModalService, private commonUtil: CommonUtil) {
            translate.addLangs(["es", "en"]);
            translate.setDefaultLang("en");
            let browserLang = translate.getBrowserLang();
            translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
            this.loggedInUser = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER));
            this.token = UserVariable.getUserToken();
            if (!this.commonUtil.validateToken(this.token)) {
                return;
			}
        }
	ngOnInit() {
		//Get Topicmap content
		// 	this.topicmapBuilderService.getTopicMapContent(this.sharedService.getTopicMapId(), this);
		// 	this.topicMapType = this.sharedService.getTopicMapType()
	}

	ngAfterViewInit(): void {
		//Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
		//Add 'implements AfterViewInit' to the class.
		//this.changeFolderTreeHeight()
	}

	@HostListener('window:resize', ['$event'])
	public changeFolderTreeHeight(event?: any) {
		this.treeheight_view = window.innerHeight - (this.ftHeader.nativeElement.offsetHeight * 2.5)
		//this.treeheight_edit = window.innerHeight - (this.ftHeader.nativeElement.offsetHeight * 1.2)
		this.treeheight_edit = window.innerHeight - (this.ftHeader.nativeElement.offsetHeight * 1.5)
	}

	public resizeEditorDiv(){
		setTimeout(()=> this.changeFolderTreeHeight(), 250)
	}

	ngAfterContentInit(): void {
		//Called after ngOnInit when the component's or directive's content has been initialized.
		//Add 'implements AfterContentInit' to the class.
		this.sharedService.openTopicMapBuilderRespond().subscribe(option => this.loadTopicMapDataEdit(option))
		this.sharedService.openTopicMapViewerRespond().subscribe(option => this.loadTopicMapDataView(option))
		this.sharedService.tabCloseBtnClickRespond().subscribe(option => this.tabCloseBtnClickEvent())
		this.sharedService.selectTopicMapTabRespond().subscribe(option => this.loadTopicMapDataWhenTabSelect(option))
		
	}

	/**
	 * load topic map data to edit 
	 * @param option 
	 */
	public loadTopicMapDataEdit(option: any): void {
		this.collapseOS = false;
		this.sharedService.getUniqueidentifierList('5',this)
		let topicMapId: string = option.topicMapId
		this.topicMapType = option.topicMapType
		let xmlDoc = $.parseXML(option.mapContent);
		let mapNode = $(xmlDoc).find('map');
		this.buttonEnabled = true;

		let firstlevelCover: string = mapNode.attr("firstlevelCover");
		let firstlevelTOC: string = mapNode.attr("firstlevelTOC");
		if (firstlevelCover == "1") {
			this.FLCOVERadded = true;
			this.flcoverClass = "btn btn-outline-primary btn-sm mx-1 my-1";
			this.flcoverAria = true;
		} else {
			this.FLCOVERadded = false;
			this.flcoverClass = "btn btn-outline-dark btn-sm mx-1 my-1";
			this.flcoverAria = false;
		}

		if (firstlevelTOC == "1") {
			this.FLTOCadded = true;
			this.flTOCClass = "btn btn-outline-primary btn-sm mx-1 my-1";
			this.flTOCAria = true;
		} else {
			this.FLTOCadded = false;
			this.flTOCClass = "btn btn-outline-dark btn-sm mx-1 my-1";
			this.flTOCAria = false;
		}

		if (this.oldTopicMapId != null) {
			// console.log('[oldTopicMapId/topicMapId] ' + this.oldTopicMapId + '/' + topicMapId);
		} else {
			this.oldTopicMapId = topicMapId
			this.topicMapName = option.topicMapName
			this.topicmapBuilderService.getTopicMapContent(topicMapId, this);

		}
		this.sharedService.setViewOnlyStatus(topicMapId, true);
		this.sharedService.enableTopicMapPaneRequest(true);
	}

	/**
	 * load topic map data to read only
	 * @param option 
	 */
	public loadTopicMapDataView(option: any): void {
		// console.log('[loadTopicMapView] (topicMapType) ' + option.topicMapType);
		this.topicMapName = option.topicMapName
		this.topicMapType = option.topicMapType
		this.topicmapBuilderService.getTopicMapContent(option.topicMapId, this);
		this.sharedService.enableTopicMapPaneRequest(true);
	}

	/**
	 * load topic map data when user select a tab in edit mode
	 * @param option 
	 */
	private loadTopicMapDataWhenTabSelect(option: any): void {
		//this.sharedService.getUniqueidentifierList('5',this)
		let topicMapId = option.tab.id
		let topicMapName = option.tab.title

		this.topicMapName = option.tab.title
		this.oldTopicMapId = topicMapId
		this.topicMapType = 'edit'
		this.tabId = topicMapId
		//this.topicmapBuilderService.getTopicMapContent(topicMapId, this);
	}

	/**
	 * remove tree reference and destroy jstree
	 * reset all variables 
	 */
	private tabCloseBtnClickEvent() {
		console.log('[tabCloseBtnClickEvent]');
		let tabComponentType = SharedService.getClosingTabType();
		if(tabComponentType == "TopicMapBuilder") {
			if (this.treeRefEdit != null) {
				console.log('[this.treeRefEdit != null]');
				//do nothing, to prevent duplicating dnd events
				//$('#topicMapTreeEdit').jstree().destroy()
			} else {
				//do nothing
			}
	
			if (this.treeRefView != null) {
				console.log('[this.treeRefView != null]');
				$('#topicMapTreeView').jstree().destroy()
			} else {
				//do nothing
			}
	
			this.treeRefView = null
			this.oldTopicMapId = null
			this.buttonEnabled = false;
		} 
		
	}

	private itemSelectEvent(evt: any, data: any) {
		console.log('[itemSelectEvent] (data) ' + JSON.stringify(data.node));
		console.log(data);
		this.selectedItem = data.node
		this.isOtherSettings = data.node.original.isOtherSettings
		this.newItem = false
		if (data.node.original.isOtherSettings == undefined) {
			if(JSON.stringify(data.node.data.isOtherSettings) == 'true') {
				this.isOtherSettings = true
			}else {
			}
			if (data.node.data.type === 'TOC' || data.node.data.type === 'TOF' || data.node.data.type === 'TOT' || data.node.data.type === 'TOD' || data.node.data.type === 'Index') {
				this.isOtherSettings = true
			}
			this.newItem = true;
		}
		if (!this.isOtherSettings) {
			this.collapseOS = false;
		} else {
			if (this.newItem) {
				//set config data
				var xmlDoc = $.parseXML(data.node.data.config);
				var TMBConfigNode = $(xmlDoc).find('tmbconfig');
				
				var TMBConfigNodeLength = TMBConfigNode[0].childNodes.length;
				for (let i = 0; i < TMBConfigNodeLength; i++) {
					
					let childNode = TMBConfigNode[0].childNodes[i];
					switch ($(childNode)[0].nodeName) {
						case 'headername':
							this.headerName = $(childNode)[0].textContent;
							break;
						case 'orientation':
							this.orientation = $(childNode)[0].textContent;
							break;
						case 'specialpapersize':
							this.specialPaperSize = $(childNode)[0].textContent;
							break;
						case 'omitintoc':
							if ($(childNode)[0].textContent == '1') {
								this.omitInTOC = true;
							} else {
								this.omitInTOC = false;
							}
							break;
						case 'pagebreak':
							if ($(childNode)[0].textContent == '1') {
								this.pageBreak = true;
							} else {
								this.pageBreak = false;
							}
							break;
						case 'object':
							var objNodeLength = $(childNode)[0].childNodes.length;
							for (let j = 0; j < objNodeLength; j++) {
								let objChildNode = $(childNode)[0].childNodes[j];
								switch ($(objChildNode)[0].nodeName) {
									case 'objtype':
										this.objType = $(objChildNode)[0].textContent;
										break;
									case 'search':
										var searchNodeLength = $(objChildNode)[0].childNodes.length;
										for (let k = 0; k < searchNodeLength; k++) {
											let searchChildNode = $(objChildNode)[0].childNodes[k];
											switch ($(searchChildNode)[0].nodeName) {
												case 'yystems':
													break;
												case 'vendors':
													break;
												case 'checksheets':
													break;
												default:
													break;
											}
										}
										break;
									default:
										break;
								}
							}
							break;
						default:
							break;
					}
				}

			} else {
				//set config data
				var xmlDoc = $.parseXML(data.node.data.config);
				var TMBConfigNode = $(xmlDoc).find('TMBConfig');
	
				var TMBConfigNodeLength = TMBConfigNode[0].childNodes.length;
				for (let i = 0; i < TMBConfigNodeLength; i++) {
					let childNode = TMBConfigNode[0].childNodes[i];
					switch ($(childNode)[0].nodeName) {
						case 'headerName':
							this.headerName = $(childNode)[0].textContent;
							break;
						case 'Orientation':
							this.orientation = $(childNode)[0].textContent;
							break;
						case 'SpecialPaperSize':
							this.specialPaperSize = $(childNode)[0].textContent;
							break;
						case 'OmitInTOC':
							if ($(childNode)[0].textContent == '1') {
								this.omitInTOC = true;
							} else {
								this.omitInTOC = false;
							}
							break;
						case 'PageBreak':
							if ($(childNode)[0].textContent == '1') {
								this.pageBreak = true;
							} else {
								this.pageBreak = false;
							}
							break;
						case 'Object':
							var objNodeLength = $(childNode)[0].childNodes.length;
							for (let j = 0; j < objNodeLength; j++) {
								let objChildNode = $(childNode)[0].childNodes[j];
								switch ($(objChildNode)[0].nodeName) {
									case 'ObjType':
										this.objType = $(objChildNode)[0].textContent;
										break;
									case 'Search':
										var searchNodeLength = $(objChildNode)[0].childNodes.length;
										for (let k = 0; k < searchNodeLength; k++) {
											let searchChildNode = $(objChildNode)[0].childNodes[k];
											switch ($(searchChildNode)[0].nodeName) {
												case 'Systems':
													break;
												case 'Vendors':
													break;
												case 'CheckSheets':
													break;
												default:
													break;
											}
										}
										break;
									default:
										break;
								}
							}
							break;
						default:
							break;
					}
				}
			}
		}
	}

	/**
	 * tree item select event in read only view
	 * @param evt 
	 * @param data 
	 */
	private viewTreeItemSelectEvent(evt: any, data: any) {
		this.enableInsertOtherRef = false;
		this.tableRowData = [];
		this.imageRowData = [];
		//console.log('[viewTreeItemSelectEvent] (data) ' + JSON.stringify(data.node));
		this.refId = data.node.data.topicId;
		this.refText = data.node.text;
		this.refType = data.node.data.type;
		//console.log('this.refId ' + this.refId);

		if (this.refType == "ContentItem") {
			this.refType = "File";
			this.enableInsertOtherRef = false;
		}

		if (this.refType == "Topic") {
			this.enableInsertOtherRef = true;
			this.topicmapBuilderService.getEditorXMLforOtherRef(this.refId).subscribe(
				data => {
					if (data != null) {
						this.topicContent = data.payload.htmlContent;
						//console.log("TopicContent ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ : " + this.topicContent);
					}
				},
				error => {
					console.log('[getTopicXMLContent] (error) ' + JSON.stringify(error.statusDescription));
					this.alertService.error(error.statusDescription);
				}
			);

		} else if (this.refType == "Procedure") {
			this.enableInsertOtherRef = true;
			this.topicmapBuilderService.getBasicFileDataforOtherRef(this.refId, this.refType).subscribe(
				data => {
					if (data != null) {
						this.procedureContent = data.payload.content;
						//console.log("Procedure Cntent ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ : " + this.procedureContent);
					}
				},
				error => {
					console.log('[getProcedureContent] (error) ' + JSON.stringify(error.statusDescription));
					this.alertService.error(error.statusDescription);
				}
			);

		}

	}

	public otherSettingsClick() {
		// console.log('[otherSettingsClick] (collapseOS) ' + JSON.stringify(this.collapseOS) );
		if (this.isOtherSettings) {
			this.collapseOS = !this.collapseOS
		}
	}
	
	/**
	 * find node by its text and id 
	 * use when jsTree has different node Id than parentNode.children_d in folderMoveStopEvent
	 * @param text 
	 */
	private findNodeByText(text: string) : any {
		let returnNode = null
		let jsT = $('#topicMapTreeEdit').jstree(true).get_json('#', {flat:true})
		let nodes = jsT.filter((i: { text: string; }) => i.text == text)
		if(nodes.length > 1) {
			nodes.forEach((element: any) => {
				if(element.id.includes("_")) {
					returnNode = element
				}
			});
		} else {
			returnNode = nodes[0]
		}
		return returnNode
	}

	private folderMoveStopEvent(evt: any, data: any, element: any, dndNode: any, parentNode: any) {
		console.log('[topic-map-builder: folderMoveStopEvent] (dnd_stop.vakata) ');
		if (dndNode != null && parentNode != null) {
			console.log('dndNode.text= '+dndNode.text)
			console.log('dndNode.id= '+dndNode.id)
			let arrayLength = parentNode.children_d.length
			let newItemId = parentNode.children_d[arrayLength - 1]
			if(newItemId != undefined){
				//console.log("Ssssssssssssssssssssssssssss")
				$('#topicMapTreeEdit').jstree('open_node', parentNode.id); // expand parent node on drag and drop event.

				this.isTopicMapEdited = true;
				let node = $('#topicMapTreeEdit').jstree(true).get_node(newItemId)
				var data_temp: any = {
					'data': [
					]
				}
				var type = dndNode.data.type;
					var itemId = dndNode.id;
					var tmbConfig = this.generateTMBConfigXML("");
					data_temp.data.push({ 
							name : dndNode.data.name,
							type : dndNode.data.type,
							config : tmbConfig,
							topicId : itemId,
							isOtherSettings: true
						})
				if(node) {
					if(node.data == null){
						node.data = data_temp.data[0]
					}
				} else {
					//can not find node
					if(this.findNodeByText(dndNode.text) != null) {
						node = this.findNodeByText(dndNode.text)
						if(node.data == null){
							node.data = JSON.parse(JSON.stringify(data_temp.data))
						}
					}
				}

				this.sharedService.getUniqueidentifier(this).subscribe(
					data => {
						var modifiedData = JSON.parse(JSON.stringify(data));
						let id = modifiedData.payload;
						$('#topicMapTreeEdit').jstree(true).set_id(node,id)
					},
					error => {

					}
				)

				var treeData = JSON.parse(JSON.stringify($('#topicMapTreeEdit').jstree(true).get_json('#', { flat: true, no_data: false }))); 
				this.tree_stopLenght = treeData.length;
				console.log('[topic-map-builder: folderMoveStopEvent]============itemId========'+itemId);
				if (this.tree_startLenght !== this.tree_stopLenght ) {
					for (let i = 0; i < treeData.length; i++) {
						console.log('[topic-map-builder: folderMoveStopEvent]============topicId========'+treeData[i].data.topicId);
						if (itemId == treeData[i].data.topicId && treeData[i].id != node.id) {
						//if (treeData[i].data.topicId != undefined && itemId == treeData[i].data.topicId) {	
							this.alertService.warn("Topic is already assigned to the selected map");
						} else {
							console.log('[topic-map-builder: folderMoveStopEvent]============data.data=======2========');
						}
					} 
				}	
				this.sharedService.setViewOnlyStatus(this.tabId, false);
			}
		} else {
			//do nothing
			//nodes order changed
			console.log('[topic-map-builder: folderMoveStopEvent] Null');
			
		}
	}

	private folderMoveStartEvent(evt: any, data: any) {
		console.log('[topic-map-builder: folderMoveStartEvent] (dnd_start.vakata)');
		console.log('dndNode.text= '+ data.event.target.textContent)
		console.log('dndNode.id= '+data.data.nodes[0])
		const treeData = JSON.parse(JSON.stringify($('#topicMapTreeEdit').jstree(true)
		.get_json('#', { flat: true, no_data: false }))); 
		this.tree_startLenght = treeData.length;			
	}

	private copyNodeEvent(evt: any, data: any) {
		console.log('[topic-map-builder: copyNodeEvent] (dnd_copy.vakata)');
	}


	onSuccess(data: WsResponse, serviceType: WsType): void {
		if (serviceType == WsType.GET_TOPIC_MAP_CONTENT) {
			this.topicmapContentPayload = data.payload;
			this.loadData(data.payload, this.topicMapType);
			this.changeFolderTreeHeight()
			this.isTopicMapEdited =  false;
		} else if (serviceType == WsType.GENERATE_UNIQUE_IDENTIFIER) {
			console.log("[onSuccess]==WsType.GENERATE_UNIQUE_IDENTIFIER==");
		} else if (serviceType == WsType.CHECKIN_TOPIC_MAP) {
			this.alertService.success("Successfully checked In");
			this.sharedService.activeTabCloseRequest();
			this.buttonEnabled = false;
			this.sharedService.setViewOnlyStatus(this.tabId, true);
			this.loadingService.hideLoading();
		} else if (serviceType == WsType.SAVE_TOPIC_MAP) {
			this.alertService.success("Successfully saved");
			this.sharedService.setViewOnlyStatus(this.tabId, true);
		} else if (serviceType == WsType.CM_SYSTEM_UNDO_CHECKOUT) {
			this.alertService.success("Successfully Undo Checked Out");
			this.sharedService.activeTabCloseRequest();
			this.buttonEnabled = false;
			this.loadingService.hideLoading();
		} else if (serviceType == WsType.GET_UNIQUE_IDENTIFIER_LIST){
			if (data.payload != null) {
				this.uniqueIdentifierTOC = data.payload[0];
				this.uniqueIdentifierTOD = data.payload[1];
				this.uniqueIdentifierTOF = data.payload[2];
				this.uniqueIdentifierTOT = data.payload[3];
				this.uniqueIdentifierIndex = data.payload[4];
			}
		} else if (serviceType == WsType.RENAME_TOPIC) {
			this.alertService.success("Successfully Renamed the Topic");
		} else if (serviceType == WsType.RENAME_PROCEDURE) {
			this.alertService.success("Successfully Renamed the Procedure");
		} else if (serviceType == WsType.RENAME_CONTENT_ITEM) {
			this.alertService.success("Successfully Renamed the Content Item");
		} else if (serviceType == WsType.RENAME_IMAGE) {
			this.alertService.success("Successfully Renamed the Image");
		}
	}

	onFail(res: WsResponse, serviceType: WsType): void {
		this.loadingService.hideLoading();
		if (serviceType == WsType.GET_TOPIC_MAP_CONTENT) {
			console.log('fail - ' + res.statusDescription);
		} else if (serviceType == WsType.GENERATE_UNIQUE_IDENTIFIER) {
			console.log('fail - ' + res.statusDescription);
		} else if (serviceType == WsType.CHECKIN_TOPIC_MAP) {
			console.log('fail - ' + res.statusDescription);
		} else if (serviceType == WsType.SAVE_TOPIC_MAP) {
			console.log('fail - ' + res.statusDescription);
		} else if (serviceType == WsType.RENAME_TOPIC) {
			console.log('fail - ' + res.statusDescription);
		} else if (serviceType == WsType.RENAME_IMAGE) {
			console.log('fail - ' + res.statusDescription);
		}
	}

	loadData(payload: any, topicMapType: string) {
		
		console.log("[topic-map-builder: loadData] (topicMapType) " + topicMapType)

		console.log("[topic-map-builder: loadData] (payload) " + JSON.stringify(payload))
		var duplicated = false;
		var dndNode = null
		var parentNode = null
		//Add topicmap as root of the topic map builder
		var topicMapName = this.topicMapName;
		this.data_n['data'] = [];
		this.data_n["data"].push({
			id: "0", topicId: "0", parent: "#", text: this.topicMapName, icon: "far fa-map", type: "TopicMap",
			sequenceId: "0", state: {
				opened: true,
				disabled: true,
				selected: false
			}
		});
		if (payload != null) {
			var item = payload.filter(item => item["topicMapTopicId"] !== null);

			if (item != null && item.length > 0) {
				for (let i = 0; i < payload.length; i++) {
					var topicMapTopicId = payload[i]["topicMapTopicId"];
					var topicId = payload[i]["topicId"];

					var topicItemType = payload[i]["topicItemType"];
					var sequenceId = payload[i]["sequenceId"];
					var config = payload[i]["config"];
					var itemName = payload[i]["itemName"];
					//set config values
					if (config != null || config != "") {
						this.isOtherSettings = true;
					} else {
						this.isOtherSettings = false;
					}
					this.orientation = payload[i]["topicId"];
					//set parent id values
					var parentId = null;
					if (payload[i]["parentId"] != null && payload[i]["parentId"] !== "") {
						parentId = payload[i]["parentId"];
					} else {
						//set # for items where that don't have parent id
						parentId = "0";
					}

					//set icon value for topic map items
					var icon = "";
					var type = "";
					var itemId =  "";
					if (payload[i]["topicItemType"] != null && payload[i]["topicItemType"] !== "") {
						switch (payload[i]["topicItemType"]) {
							case 'Topic':
								icon = "far fa-lightbulb";
								type = "Topic";
								break;
							case 'Procedure':
								icon = "far fa-layer-group";
								type = "Procedure";
								break;
							case 'Image':
								icon = "far fa-image";
								type = "Image";
								break;
							case 'ContentItem':
								icon = "far fa-file-archive";
								type = "ContentItem";
								break;
							case 'Header':
								icon = "far fa-heading";
								type = "Header";
								//set item name for anchors
								let xmlDocHeader = $.parseXML(config);
								let outNodeHeader = $(xmlDocHeader).find('headerName' );
								itemName = outNodeHeader[0].textContent.trim();
								break;
							case 'Anchor':
								icon = "far fa-anchor";

								//set item name for anchors
								let xmlDoc = $.parseXML(config);
								let outNode = $(xmlDoc).find('headerName');
								itemName = outNode[0].textContent.trim();
								console.log("================itemName==========================" + itemName);
								//Set toggle status for topic map buttons bar
								switch (itemName) {
									case 'Table of Contents':
										this.TOCadded = true;
										type = "TOC";
										itemId = "1";
										break;
									case 'Table of Figures':
										this.TOFadded = true;
										type = "TOF";
										itemId = "2";
										break;
									case 'Table of Tables':
										this.TOTadded = true;
										type = "TOT";
										itemId = "3";
										break;
									case 'P&ID Cross Reference':
										this.TODadded = true;
										type = "TOD";
										itemId = "5";
										break;
									case 'Index':
										this.indexAdded = true;
										type = "Index";
										itemId = "4";
										break;
									default:
										break;
								}
								break;
							case 'Object':
								icon = "far fa-cube";
								type = "Object";
								break;
							case 'ContentItem':
								icon = "far a-file-archive";
								type = "ContentItem";
								break;
							default:
								break;
						}
					} else {
						//Do nothing
					}

					this.data_n["data"].push({
						id: topicMapTopicId, data: { topicId: topicId, config: config, sequenceId: sequenceId, type: type ,itemId :itemId}, parent: parentId, text: itemName, icon: icon, type: topicItemType
						, isOtherSettings: this.isOtherSettings
					});
				}
			}
		}

		if (topicMapType == 'edit') {
			console.log('[start load jstree EDIT]');
			if (this.treeRefEdit != null) {
				// $('#topicMapTreeEdit').jstree().destroy()
				// this.treeRefEdit = null
				console.log("jstree exists, update nodes")
				$('#topicMapTreeEdit').jstree(true).settings.core.data = this.data_n.data;
				$('#topicMapTreeEdit').jstree(true).refresh();
			} else {

				this.treeRefEdit = $('#topicMapTreeEdit').jstree({
					core: {
						themes: {
							variant: 'normal'
						},
						data: this.data_n.data,
						check_callback: function (operation, node, node_parent, node_position, new_instance, more) {
							switch (operation) {
								case 'move_node':
									var returnValue = false;
									//Switch case to select item type
									//console.log("----move_node------node--------------" + JSON.stringify(node));
									//console.log("----move_node-------node_parent--------------" + JSON.stringify(node_parent));
									if (node_parent.id == '#') {
										returnValue = false;
									}
									else if (node_parent.original.type == "Anchor") {
										returnValue = false;
									}
									else if (node_parent.original.original != undefined && node_parent.original.original.type == "Anchor") {
										returnValue = false;
									}
									else {
										if ((node.data.type == "TOD" || node.data.type == "TOF" || node.data.type == "TOT" ||
											node.data.type == "Index" || node.data.type == "TOC") && (node_parent.id != '0')) {
											returnValue = false;
										} else {
											returnValue = true;
										}

									}
									dndNode = null
									parentNode = null
									return returnValue;
								case 'copy_node':
									//console.log("-----copy_node------node--------------" + JSON.stringify(node));
									// console.log("-----copy_node------node_parent--------------" + JSON.stringify(node_parent));
									dndNode = node
									parentNode = node_parent

									var returnValue = false;
									if (node_parent.id == '#') {
										returnValue = false
									} else {
										if (node_parent.original.type == "Anchor") {
											returnValue = false;
										} 
										else if (node_parent.original.original != undefined && node_parent.original.original.type == "Anchor") {
											returnValue = false;
										} 
										else {
											//Switch case to select item type
											switch (node.original.type) {
												case 'Folder':
													returnValue = false
													break;
												case 'TopicMap':
													returnValue = false
													break;
												case 'ICECourse':
													returnValue = false
													break;
												case 'Video':
													returnValue = false
													break;
												case 'ThreeDModel':
													returnValue = false
													break;
												case 'Image':
													returnValue = false
													break;
												case 'ContentItem':
													let extension = node.original.extension
													if(extension != null){
														if(extension != "PDF"){
															returnValue = false;
														}
														else{
															returnValue = true;
														}
													}
													else{
														returnValue = true;
													}
													break;
													
												default:
													returnValue = true

													break;
											}

										}

									}

									//node.data.set_id(node.data, node.data.original.id);
									return returnValue;
								case 'delete_node':
									return true;
								default:
									break;
							}
						},
						force_text: true
					},
					types: {
						default: {
							// "icon" : "fa fa-file-o "
						}
					},
					plugins: ["dnd", "search", "state", "types", "wholerow"],
					search: {
						show_only_matches: true,
						show_only_matches_children: true
					},
					dnd: {
						copy: true,
						//always_copy: true,
						large_drag_target: '#topicMapTreeTM',
						// drop_target: '#topicMapTreeEdit'
					}
				});
				setTimeout(() =>
					//select node event
					$('#topicMapTreeEdit').bind("select_node.jstree", (evt, data) => this.itemSelectEvent(evt, data)),
					//drag and drop stop functionality
					$(document).bind("dnd_stop.vakata", (evt, data, element) => this.folderMoveStopEvent(evt, data, element, dndNode, parentNode)),
					//drag and drop startfunctionality
					$(document).bind("dnd_start.vakata", (evt, data) => this.folderMoveStartEvent(evt, data)),
					$('#topicMapTreeEdit').bind("rename_node.jstree", (evt, data) => this.renameNode(evt, data)),
					$('#topicMapTreeEdit').on('create_node.jstree', function (evt, data) {
						console.log("============create_node==========create_node======================");
					})

				)
			}
		} else {
			console.log('[start load jstree VIEW]');
			if (this.treeRefView != null) {
				$('#topicMapTreeView').jstree().destroy()
				this.treeRefView = null
			}
			this.treeRefView = $('#topicMapTreeView').jstree({
				core: {
					themes: {
						variant: 'normal'
					},
					data: this.data_n.data,
					check_callback: function (operation, node, node_parent, node_position, more) {
						switch (operation) {
							case 'move_node':
								return false;
							case 'copy_node':
								return false;
							case 'delete_node':
								return false;
							default:
								break;
						}
					},
					force_text: true
				},
				types: {
					default: {
						// "icon" : "fa fa-file-o "
					}
				},
				plugins: ["search", "state", "types", "wholerow"],
				search: {
					show_only_matches: true,
					show_only_matches_children: true
				}
			});
			setTimeout(() =>
				//select node event
				$('#topicMapTreeView').bind("select_node.jstree", (evt, data) => this.viewTreeItemSelectEvent(evt, data)),
				//drag and drop stop functionality
			)
		}

		this.isOtherSettings = false;
	}

	public renameNode(evt: any, data: any): any {
		let itemData = data;
		let type = itemData.node.data.type;
		if (type == 'Anchor' || type == 'Header' ){
			var tmbConfigText = '';
			var headerNameText = '';
			if (itemData.node.data.status == 'new') {
				tmbConfigText = 'tmbconfig';
				headerNameText = 'headername';
			} else {
				tmbConfigText = 'TMBConfig';
				headerNameText = 'headerName';
			}
			var tempTMBConfig = itemData.node.data.config
			var xmlConfigDoc = $.parseXML(tempTMBConfig);
			var TMBConfigNode = $(xmlConfigDoc).find(tmbConfigText);
			var TMBConfigNodeLength = TMBConfigNode[0].childNodes.length;
						
			for (let i = 0; i < TMBConfigNodeLength; i++) {
				let childNode = TMBConfigNode[0].childNodes[i];
				switch ($(childNode)[0].nodeName) {
					case headerNameText:
						$(childNode)[0].textContent = itemData.node.text;
						break;
					default:
						break;
					}
				}
				var oSerializer = new XMLSerializer();
				var text = oSerializer.serializeToString(xmlConfigDoc);
				data.node.data.config = text; 
				itemData = '';
			} else if ( type == 'Topic' || type == 'Procedure' || type == 'ContentItem' || type == 'Image'){
				let topicPrevName: String = itemData.node.original.text;
				let topicNewName: String = itemData.node.text;
				let topicId: String = itemData.node.data.topicId;
				if (topicPrevName != topicNewName) {
					this.topicmapBuilderService.updateTopicMapItemName(topicId,topicNewName,type,this);
				}
			} 			
	}


	  
	// Topic map builder button bar events
	/*  
		Add/Remove table of Content
	*/
	public toggleTOC() {
		console.log('[topic-map-builder: (toggleTOC)]');
		if (!this.TOCadded) {
			var tmbConfig = this.generateTMBConfigXML("Table of Contents");
			let id = this.uniqueIdentifierTOC;
			$('#topicMapTreeEdit').jstree().create_node('0', {
				id: id,
				text: "Table of Contents", 
				parent: "0", 
				parents: "[0, #]", 
				icon: "far fa-thumbtack", 
				data: {
					config: tmbConfig,
					type: "TOC", 
					itemId : "1"
				}, 
				original: { 
					id: id,
					text: "Table of Contents",
					 parent: "0", 
					 icon: "far fa-anchor", 
					 type: "Anchor", 
					 isOtherSettings: true 
					}
			},
			"first", function () {
			});
			this.TOCadded = true;
		} else {
			this.TOCadded = false;
			this.removeContentListItem("TOC");
		}
		this.sharedService.setViewOnlyStatus(this.tabId, false);
		this.isTopicMapEdited = true;
	}

	/*  
		Add/Remove table of Figures
	*/
	public toggleTOF() {
		console.log('[topic-map-builder: (toggleTOF)]');
		if (!this.TOFadded) {
			var tmbConfig = this.generateTMBConfigXML("Table of Figures");
			let id = this.uniqueIdentifierTOF;
			$('#topicMapTreeEdit').jstree().create_node('0', {
				id: id, 
				text: "Table of Figures", 
				parent: "0", 
				parents: "[0, #]", 
				icon: "far fa-thumbtack", 
				data: {
					config: tmbConfig,
					 type: "TOF", 
					 itemId : "2"
					}, 
				original: { 
					id: id, 
					text: "Table of Figures", 
					parent: "0", 
					icon: "far fa-anchor", 
					type: "Anchor", 
					isOtherSettings: true 
				}
					
			},
			"first", function () {

			});
			this.TOFadded = true;
		} else {
			this.TOFadded = false;
			this.removeContentListItem("TOF");
		}
		this.sharedService.setViewOnlyStatus(this.tabId, false);
		this.isTopicMapEdited = true;
	}

	/*  
		 Add/Remove table of Tables
	 */
	public toggleTOT() {
		console.log('[topic-map-builder: (toggleTOT)]');
		if (!this.TOTadded) {
			var tmbConfig = this.generateTMBConfigXML("Table of Tables");
			let id = this.uniqueIdentifierTOT;
			$('#topicMapTreeEdit').jstree().create_node('0', {
				id: id, 
				text: "Table of Tables", 
				parent: "0", 
				parents: "[0, #]", 
				icon: "far fa-thumbtack", 
				data: {
					config: tmbConfig,
					type: "TOT" , 
					itemId : "3"
				},
				original: { 
					id: id, 
					text: "Table of Tables",
					parent: "0", 
					icon: "far fa-anchor", 
					type: "Anchor", 
					isOtherSettings: true 
				}
			},
			"first", function () {

			});
			this.TOTadded = true;
		} else {
			this.TOTadded = false;
			this.removeContentListItem("TOT");
		}
		this.sharedService.setViewOnlyStatus(this.tabId, false);
		this.isTopicMapEdited = true;
	}

	
	/*  
		 Add/Remove table of Documents
	 */
	public toggleTOD() {
		console.log('[topic-map-builder: (toggleTOD)]');
		if (!this.TODadded) {
			var tmbConfig = this.generateTMBConfigXML("P&ID Cross Reference");
			let id = this.uniqueIdentifierTOD;
			$('#topicMapTreeEdit').jstree().create_node('0', {
				id: id, 
				text: "P&ID Cross Reference", 
				parent: "0", 
				parents: "[0, #]", 
				icon: "far fa-thumbtack", 
				data: {
					config: tmbConfig, 
					type: "TOD" , 
					itemId : "5"
				}, 
				original: { 
					id: id, 
					text: "P&ID Cross Reference", 
					parent: "0", 
					icon: "far fa-anchor", 
					type: "Anchor", 
					isOtherSettings: true 
				}
			},
			"first", function () {

			});
			this.TODadded = true;
		} else {
			this.TODadded = false;
			this.removeContentListItem("TOD");
		}
		this.sharedService.setViewOnlyStatus(this.tabId, false);
		this.isTopicMapEdited = true;
	}

	/*  
		 Add/Remove Index
	 */
	public toggleIndex() {
		if (!this.indexAdded) {
			var tmbConfig = this.generateTMBConfigXML("Index");
			var treeData = JSON.parse(JSON.stringify($('#topicMapTreeEdit').jstree(true).get_json('#', { flat: true, no_data: false })));
			let id = this.uniqueIdentifierIndex;
			$('#topicMapTreeEdit').jstree().create_node('0', {
				id: id, 
				text: "Index", 
				parent: "0", 
				parents: "[0, #]", 
				icon: "far fa-thumbtack", 
				data: {
					config: tmbConfig, 
					type: "Index" , 
					itemId : "4"
				}, 
				original: { 
					id: id, 
					text: "Index", 
					parent: "0", 
					icon: "far fa-anchor", 
					type: "Anchor", 
					isOtherSettings: true 
				}
			},
			"first", function () {

			});
			this.indexAdded = true;
		} else {
			this.indexAdded = false;
			this.removeContentListItem("Index");
		}
		this.sharedService.setViewOnlyStatus(this.tabId, false);
		this.isTopicMapEdited = true;
	}

	/*  
		Remove Content List Item
		Remove TOC,TOF,TOT,TOD,Index items
	 */
	public removeContentListItem(contentListItemType: string){
		var treeData = JSON.parse(JSON.stringify($('#topicMapTreeEdit').jstree(true).get_json('#', { flat: true, no_data: false })));
			
		for (let treeDataItem of treeData) {
			var type = treeDataItem.data.type;
			if (type == contentListItemType) {
				$('#topicMapTreeEdit').jstree().delete_node([treeDataItem]);
				break;
			}
		}
	}

	public generateTMBConfigXML(headerNameValue: string) {
		console.log("=========generateTMBConfigXML==========")

		if (navigator.userAgent.toLowerCase().indexOf("edge") > -1) {
			var tmbConfig = document.createElement('tmbconfig');
			tmbConfig.setAttribute("TopicMapTopicId", this.sharedService.getTopicMapId());
			if (headerNameValue != ''){
				var headerName = document.createElement('headername');
			headerName.textContent = headerNameValue;
			tmbConfig.appendChild(headerName);
			}
			var orientation = document.createElement('orientation');
			orientation.textContent = "None";
			tmbConfig.appendChild(orientation);

			var specialPaperSize = document.createElement('specialpapersize');
			specialPaperSize.textContent = "None";
			tmbConfig.appendChild(specialPaperSize);

			var omitInTOC = document.createElement('omitintoc');
			omitInTOC.textContent = "0";
			tmbConfig.appendChild(omitInTOC);

			var pageBreak = document.createElement('pagebreak');
			pageBreak.textContent = "0";
			tmbConfig.appendChild(pageBreak);
		} else {
			var tmbConfig = document.createElement('TMBConfig');
			tmbConfig.setAttribute("TopicMapTopicId", this.sharedService.getTopicMapId());

			if (headerNameValue != ''){
				var headerName = document.createElement('headerName');
			headerName.textContent = headerNameValue;
			tmbConfig.appendChild(headerName);
			}
			

			var orientation = document.createElement('Orientation');
			orientation.textContent = "None";
			tmbConfig.appendChild(orientation);

			var specialPaperSize = document.createElement('SpecialPaperSize');
			specialPaperSize.textContent = "None";
			tmbConfig.appendChild(specialPaperSize);

			var omitInTOC = document.createElement('OmitInTOC');
			omitInTOC.textContent = "0";
			tmbConfig.appendChild(omitInTOC);

			var pageBreak = document.createElement('PageBreak');
			pageBreak.textContent = "0";
			tmbConfig.appendChild(pageBreak);
		}
		

		if (headerNameValue == "Object") {
			var object = document.createElement('Object');
			tmbConfig.appendChild(object);

			var objType = document.createElement('ObjType');
			objType.textContent = "Checksheets";
			object.appendChild(objType);

			var search = document.createElement('Search');
			object.appendChild(search);

			var systems = document.createElement('Systems');
			object.appendChild(systems);

			var vendors = document.createElement('Vendors');
			object.appendChild(vendors);

			var checkSheets = document.createElement('CheckSheets');
			object.appendChild(checkSheets);

		}

		
		var oSerializer = new XMLSerializer();
		var tmbConfigString = oSerializer.serializeToString(tmbConfig);
		console.log("=======tmbConfigString==========="+tmbConfigString);
		return tmbConfigString;
	}

	/*  
		 Add/Remove object
	 */
	public addObject() {
		console.log('[topic-map-builder: (addObject)]');
		


		this.sharedService.getUniqueidentifier(this).subscribe(
			data => {
				var modifiedData = JSON.parse(JSON.stringify(data));
				var tmbConfig = this.generateTMBConfigXML("Object");
				let id = modifiedData.payload;
				$('#topicMapTreeEdit').jstree().create_node('0', {
					id: id, text: "Object", parent: "0", parents: "[0, #]", icon: "far fa-cube", data: {
						config: tmbConfig, type: "Object"
					}, original: { id: id, text: "Object", parent: "0", icon: "far fa-anchor", type: "Anchor", isOtherSettings: true }
				},
					"last", function () {
					});
			},
			error => {

			}
		);


	}

	/*  
		 Add/Remove FLTOC
	 */
	public toggleFLTOC() {
		this.FLTOCadded = !this.FLTOCadded;
		if (this.FLTOCadded){
			this.alertService.success("Successfully added FLTOC");
			this.flTOCClass = "btn btn-outline-primary btn-sm mx-1 my-1";
			this.flTOCAria = true;
		} else {
			this.alertService.success("Successfully removed FLTOC");
			this.flTOCClass = "btn btn-outline-dark btn-sm mx-1 my-1";
			this.flTOCAria = false;
		}
		this.sharedService.setViewOnlyStatus(this.tabId, false);
		this.isTopicMapEdited = true;
	}

	/*  
		 Add/Remove FLCOVER
	 */
	public toggleFLCover() {
		this.FLCOVERadded = !this.FLCOVERadded;
		if (this.FLCOVERadded){
			this.alertService.success("Successfully added FLCover");
			this.flcoverClass = "btn btn-outline-primary btn-sm mx-1 my-1";
			this.flcoverAria = true;
		} else {
			this.alertService.success("Successfully removed FLCover");
			this.flcoverClass = "btn btn-outline-dark btn-sm mx-1 my-1";
			this.flcoverAria = false;
		}
		this.sharedService.setViewOnlyStatus(this.tabId, false);
		this.isTopicMapEdited = true;
	}


	/*  
	Insert topic map header
	 */
	public addTopicmapHeader(event :any){
		console.log('[topic-map-builder: (addTopicmapHeader)]');
		
		this.sharedService.getUniqueidentifier(this).subscribe(
			data => {
				var modifiedData = JSON.parse(JSON.stringify(data));
				var tmbConfig = this.generateTMBConfigXML("Header");
				let id = modifiedData.payload;
				$('#topicMapTreeEdit').jstree().create_node('0', { id: id,text: "Header", parent:"0", parents:"[0, #]", icon: "far fa-heading", data : { config: tmbConfig ,
					 type : "Header", status : "new", topicId: id}, original : { id: id,text: "Header", parent:"0", icon: "far fa-heading", type: "Header", isOtherSettings: true} }, 
				"last", function () {
				});
			},
		 	error => {
				  
			}
		);
		this.sharedService.setViewOnlyStatus(this.tabId, false);
		this.isTopicMapEdited = true;
	}

	/*  
		 Remove item from the topic map
	 */
	public removeTopicmapItem() {
		console.log('[topic-map-builder: (removeTopicmapItem)]');
		
		console.log('[topic-map-builder: (removeTopicmapItem)]-content--' + JSON.stringify(this.selectedItem.text));
		switch (this.selectedItem.text) {
			case 'Table of Contents':
				this.TOCadded = false;
				break;
			case 'Table of Figures':
				this.TOFadded = false;
				break;
			case 'Table of Tables':
				this.TOTadded = false;
				break;
			case 'P&ID Cross Reference':
				this.TODadded = false;
				break;
			case 'Index':
				this.indexAdded = false;
				break;
			default:
				break;
		}

		$('#topicMapTreeEdit').jstree().delete_node([this.selectedItem]);
		this.selectedItem = null;
		this.sharedService.setViewOnlyStatus(this.tabId, false);
		this.isTopicMapEdited = true;
	}


	/*  
		 Rename item from the topic map
	 */
	public renameTopicmapItem(evt: any, data: any) {
		console.log('[topic-map-builder: (renameTopicmapItem)] '+JSON.stringify(data));
		let ref = $('#topicMapTreeEdit').jstree(true)
		let sel = ref.get_selected()
		if (!sel.length) { 
			return false
		 }
		sel = sel[0]
		ref.edit(sel)
		this.sharedService.setViewOnlyStatus(this.tabId, false);
		this.isTopicMapEdited = true;
	}

	/*  
	 Save topic map
 */

	public saveTopicmap() {
		console.log('[topic-map-builder: (saveTopicmap)]');
		var treeData = JSON.parse(JSON.stringify($('#topicMapTreeEdit').jstree(true).get_json('#', { flat: true, no_data: false })));
		console.log('[topic-map-builder: (saveTopicmap)]' + JSON.stringify(treeData));
		var topicmapXML = document.createElement('topicmapitems');
		this.isSaveClicked = true;
		var configElement = document.createElement('config');
		//topicmapXML.appendChild(configElement);
		let topicItems: string[] = [];
		this.parentId_seqId_hashmap.clear;
		this.parentId_seqId_hashmap.set("0", 0);
		for (let treeDataItem of treeData) {
			if (treeDataItem.id == "0") {
				topicmapXML.setAttribute("id", this.sharedService.getTopicMapId());
				topicmapXML.setAttribute("title", treeDataItem.text);

				if (this.FLCOVERadded) {
					topicmapXML.setAttribute("firstlevelCover", "1");
				} else {
					//Do nothing
				}

				if (this.FLTOCadded) {
					topicmapXML.setAttribute("firstlevelTOC", "1");
				} else {
					//Do nothing
				}

			} else {

				if (topicItems.indexOf(treeDataItem.id) > -1) {

				} else {
					let parentId: string = treeDataItem.parent;


					if (this.parentId_seqId_hashmap.has(parentId)){
						var currentSeqId = this.parentId_seqId_hashmap.get(parentId);
						currentSeqId = currentSeqId + 1 ;
						this.parentId_seqId_hashmap.set(parentId, currentSeqId);
					} else {
						var seqId: number = 0;
						seqId = seqId + 1;
						this.parentId_seqId_hashmap.set(parentId, seqId);
					}
					
					this.createTopicmapBuilderItem(treeDataItem, topicItems, treeData, topicmapXML);
				}

			}
		 }
		 var oSerializer = new XMLSerializer();
		 console.log('[topic-map-builder: (saveTopicmap)]'+oSerializer.serializeToString(topicmapXML));
		 this.topicmapBuilderService.saveTopicMap(this.sharedService.getTopicMapId(),oSerializer.serializeToString(topicmapXML),this.topicMapName,this);
	}

	private createTopicmapBuilderItem(treeDataItem: any, topicItems: any, treeData: any, topicmapXML: any) {
		if (treeDataItem.id == "0") {
			//mapElement.setAttribute("id",treeDataItem.id);
			//mapElement.setAttribute("title",treeDataItem.text);

		} else {
			if (topicItems.indexOf(treeDataItem.id) > -1) {
			} else {
				var itemId: string = JSON.stringify(treeDataItem.id);
				topicItems.push(treeDataItem.id);
				var treeItemElement = document.createElement('treeitem');
				treeItemElement.setAttribute("type", treeDataItem.data.type);
				treeItemElement.setAttribute("topicmaptopicid", treeDataItem.id);
				if (treeDataItem.parent == 0) {
					treeItemElement.setAttribute("parentid", "null");
				} else {
					treeItemElement.setAttribute("parentid", treeDataItem.parent);

					
				}
				var currentSeqId = this.parentId_seqId_hashmap.get(treeDataItem.parent);
				console.log("=====treeDataItem.parent====2============================="+treeDataItem.parent);
				console.log("=====currentSeqId===========2======================"+currentSeqId);
				treeItemElement.setAttribute("seqid", currentSeqId);
				
				treeItemElement.setAttribute("treeparentid", treeDataItem.parent);
				treeItemElement.setAttribute("treeid", treeDataItem.parent);
				

				switch (treeDataItem.data.type) {

					case 'Topic':
						treeItemElement.setAttribute("topicid", treeDataItem.data.topicId);
						treeItemElement.setAttribute("itemid", treeDataItem.data.topicId);
						break;
					case 'Procedure':
						treeItemElement.setAttribute("procedureid", treeDataItem.data.topicId);
						treeItemElement.setAttribute("itemid", treeDataItem.data.topicId);
						break;
					case 'Image':
						treeItemElement.setAttribute("imageid", treeDataItem.data.topicId);
						treeItemElement.setAttribute("itemid", treeDataItem.data.topicId);
						break;
					case 'ContentItem':
						treeItemElement.setAttribute("contentitemid", treeDataItem.data.topicId);
						treeItemElement.setAttribute("itemid", treeDataItem.data.topicId);
						break;
					case 'Header':
						treeItemElement.setAttribute("headerid", treeDataItem.data.topicId);
						treeItemElement.setAttribute("itemid", treeDataItem.data.topicId);
						break;
					case 'TOC':
						treeItemElement.setAttribute("itemid", treeDataItem.data.itemId);
						break;
					case 'TOF':
						treeItemElement.setAttribute("itemid", treeDataItem.data.itemId);
						break;
					case 'TOT':
						treeItemElement.setAttribute("itemid", treeDataItem.data.itemId);
						break;
					case 'TOD':
						treeItemElement.setAttribute("itemid", treeDataItem.data.itemId);
						break;
					case 'Index':
						treeItemElement.setAttribute("itemid", treeDataItem.data.itemId);
						break;
					default:
						break;
				}
				console.log("====treeDataItem.data.config=================="+JSON.stringify(treeDataItem.data.config));
				var xmlConfigDoc = $.parseXML(treeDataItem.data.config);
				//xmlConfigDoc.getElementsByTagName("tmbConfig")[0].removeAttribute("xmlns");
				treeItemElement.innerHTML = new XMLSerializer().serializeToString(xmlConfigDoc);
				//var topicItemConfigElement = document.appendChild(TMBConfigNode);
				topicmapXML.appendChild(treeItemElement);
				//check for child nodes
				for (var key in treeData) {
					if (treeData[key]["parent"] == treeDataItem.id || treeDataItem.id == '') {
						let parentId_sub: string = treeDataItem.id;
						console.log("=====treeDataItem.parent====3============================="+treeDataItem.parent);
						//console.log("=====currentSeqId===========3======================"+currentSeqId);
						if (this.parentId_seqId_hashmap.has(parentId_sub)){
							var currentSeqId_sub = this.parentId_seqId_hashmap.get(parentId_sub);
							currentSeqId_sub = currentSeqId_sub + 1 ;
							this.parentId_seqId_hashmap.set(parentId_sub, currentSeqId_sub);
							console.log("=====currentSeqId===========3=====currentSeqId_sub================="+currentSeqId_sub);
						} else {
							var seqId_sub: number = 0;
							seqId_sub = seqId_sub + 1;
							this.parentId_seqId_hashmap.set(parentId_sub, seqId_sub);
							console.log("=====currentSeqId===========3======seqId_sub================"+seqId_sub);
						}
						this.createTopicmapBuilderItem(treeData[key], topicItems, treeData, topicmapXML);
					}
				}
			}

		}
	}
	/*  
	 Checkin topic map
 */
	public checkinTopicmap(checkinComments: any) {
		this.loadingService.showLoading(true, false, "Checking In...", 0);
		console.log('[topic-map-builder: (checkinTopicmap)]');
		this.bsModalRef.hide();
		var treeData = JSON.parse(JSON.stringify($('#topicMapTreeEdit').jstree(true).get_json('#', { flat: true, no_data: false })));
		console.log('[topic-map-builder: (checkinTopicmap)----]'+JSON.stringify($('#topicMapTreeEdit').jstree(true).get_json('#', {flat:true,no_data:false})));
		var topicmapXML = document.createElement('topicmapitems');
		var configElement = document.createElement('config');
		let topicItems: string[] = [];
		
		this.parentId_seqId_hashmap.clear;

		this.parentId_seqId_hashmap.set("0", 0);
		for (let treeDataItem of treeData) {
			if (treeDataItem.id == "0") {
				topicmapXML.setAttribute("id", this.sharedService.getTopicMapId());
				topicmapXML.setAttribute("title", treeDataItem.text);

				if (this.FLCOVERadded) {
					topicmapXML.setAttribute("firstlevelCover", "1");
				} else {
					//Do nothing
				}

				if (this.FLTOCadded) {
					topicmapXML.setAttribute("firstlevelTOC", "1");
				} else {
					//Do nothing
				}
			} else {

				if (topicItems.indexOf(treeDataItem.id) > -1) {

				} else {
					let parentId: string = treeDataItem.parent;


					if (this.parentId_seqId_hashmap.has(parentId)){
						var currentSeqId = this.parentId_seqId_hashmap.get(parentId);
						currentSeqId = currentSeqId + 1 ;
						this.parentId_seqId_hashmap.set(parentId, currentSeqId);
					} else {
						var seqId: number = 0;
						seqId = seqId + 1;
						this.parentId_seqId_hashmap.set(parentId, seqId);
					}
					this.createTopicmapBuilderItem(treeDataItem, topicItems, treeData, topicmapXML);
				}

			}
		}
		var oSerializer = new XMLSerializer();
		// this.topicmapBuilderService.saveTopicMap(this.sharedService.getTopicMapId(),oSerializer.serializeToString(topicmapXML),this.topicMapName);
		console.log('[topic-map-builder: (CHECKIN TM)]'+oSerializer.serializeToString(topicmapXML));
		console.log('==========checkinComments======================='+checkinComments);
		this.topicmapBuilderService.checkinTopicmap(this.sharedService.getTopicMapId(), oSerializer.serializeToString(topicmapXML), checkinComments,this)
	}

	public orientationOnSelect(value: any) {
		console.log('[topic-map-builder: (orientationOnSelect)]---' + value);
		var node = this.selectedItem;

		console.log('[topic-map-builder: (orientationOnSelect)]-this.selectedItem--' + JSON.stringify(this.selectedItem));
		var tmbConfigVal = '';
		var orientationVal = '';
		if (this.newItem) {
			 tmbConfigVal = 'tmbconfig';
			 orientationVal = 'orientation';
		} else {
			 tmbConfigVal = 'TMBConfig';
			 orientationVal = 'Orientation';
		}
		var xmlDoc = $.parseXML(this.selectedItem.data.config);
		var TMBConfigNode = $(xmlDoc).find(tmbConfigVal);
		var TMBConfigNodeLength = TMBConfigNode[0].childNodes.length;
		var selectedOrientation: string = '';
		for (let i = 0; i < TMBConfigNodeLength; i++) {
			let childNode = TMBConfigNode[0].childNodes[i];
			switch ($(childNode)[0].nodeName) {

				case orientationVal:
					$(childNode)[0].textContent = value;
					this.orientation = value
					console.log('[topic-map-builder: (orientationOnSelect)]--textContent-' + $(childNode)[0].textContent);
					break;
				default:
					break;
			}
		}


		//	console.log('[topic-map-builder: (orientationOnSelect)]--xmlDoc-'+ $.parse(xmlDoc)  );
		var xmlText = new XMLSerializer().serializeToString(xmlDoc);

		console.log('[topic-map-builder: (orientationOnSelect)]--xmlDoc-' + xmlText);
		this.selectedItem.data.config = xmlText;


	}

	public specialPaperOnSelect(value: any) {
		console.log('[topic-map-builder: (specialPaperOnSelect)]---' + value);

		var node = this.selectedItem;
		var tmbConfigVal = '';
		var specialPaperSizeVal = '';
		if (this.newItem) {
			 tmbConfigVal = 'tmbconfig';
			 specialPaperSizeVal = 'specialpapersize';
		} else {
			 tmbConfigVal = 'TMBConfig';
			 specialPaperSizeVal = 'SpecialPaperSize';
		}

		console.log('[topic-map-builder: (specialPaperOnSelect)]-this.selectedItem--' + JSON.stringify(this.selectedItem));
		var xmlDoc = $.parseXML(this.selectedItem.data.config);
		var TMBConfigNode = $(xmlDoc).find(tmbConfigVal);
		var TMBConfigNodeLength = TMBConfigNode[0].childNodes.length;
		var selectedOrientation: string = '';
		for (let i = 0; i < TMBConfigNodeLength; i++) {
			let childNode = TMBConfigNode[0].childNodes[i];
			switch ($(childNode)[0].nodeName) {

				case specialPaperSizeVal:
					$(childNode)[0].textContent = value;
					this.specialPaperSize = value
					console.log('[topic-map-builder: (specialPaperOnSelect)]--textContent-' + $(childNode)[0].textContent);
					break;
				default:
					break;
			}
		}


		//	console.log('[topic-map-builder: (orientationOnSelect)]--xmlDoc-'+ $.parse(xmlDoc)  );
		var xmlText = new XMLSerializer().serializeToString(xmlDoc);

		console.log('[topic-map-builder: (orientationOnSelect)]--xmlDoc-' + xmlText);
		this.selectedItem.data.config = xmlText;
	}

	public omitInTOCChange(event: any) {
		var xmlDoc = $.parseXML(this.selectedItem.data.config);
		var tmbConfigVal = '';
		var omitInTOCVal = '';
		if (this.newItem) {
			tmbConfigVal = 'tmbconfig';
			omitInTOCVal = 'omitintoc';
	   } else {
			tmbConfigVal = 'TMBConfig';
			omitInTOCVal = 'OmitInTOC';
	   }
		var TMBConfigNode = $(xmlDoc).find(tmbConfigVal);
		var TMBConfigNodeLength = TMBConfigNode[0].childNodes.length;

		for (let i = 0; i < TMBConfigNodeLength; i++) {
			let childNode = TMBConfigNode[0].childNodes[i];
			switch ($(childNode)[0].nodeName) {

				case omitInTOCVal:
					if (event.target.checked) {
						$(childNode)[0].textContent = "1";
						this.omitInTOC = true
					} else {
						$(childNode)[0].textContent = "0";
						this.omitInTOC = false
					}
					break;
				default:
					break;
			}
		}

		var xmlText = new XMLSerializer().serializeToString(xmlDoc);
		this.selectedItem.data.config = xmlText;
	}

	public pageBreakChange(event: any) {
		console.log("---------pageBreakChange-------")
		var xmlDoc = $.parseXML(this.selectedItem.data.config);
		var tmbConfigVal = '';
		var pageBreakVal = '';
		if (this.newItem) {
			tmbConfigVal = 'tmbconfig';
			pageBreakVal = 'pagebreak';
	   } else {
			tmbConfigVal = 'TMBConfig';
			pageBreakVal = 'PageBreak';
	   }
		var TMBConfigNode = $(xmlDoc).find(tmbConfigVal);
		var TMBConfigNodeLength = TMBConfigNode[0].childNodes.length;
		for (let i = 0; i < TMBConfigNodeLength; i++) {
			let childNode = TMBConfigNode[0].childNodes[i];
			switch ($(childNode)[0].nodeName) {

				case pageBreakVal:
					if (event.target.checked) {
						$(childNode)[0].textContent = "1";
						this.pageBreak = true
					} else {
						$(childNode)[0].textContent = "0";
						this.pageBreak = false
					}
					break;
				default:
					break;
			}
		}
		var xmlText = new XMLSerializer().serializeToString(xmlDoc);
		this.selectedItem.data.config = xmlText;
	}

	public undoCheckoutTopicmap(){
		this.loadingService.showLoading(true, false, "Undo Checking Out...", 0);
		console.log('[topic-map-builder: (undoCheckoutTopicmap)]-Start');
		this.topicmapBuilderService.undoCheckoutTopicmap(this.sharedService.getTopicMapId(),"TopicMap",this);
		//this.sharedService.setViewOnlyStatus(this.tabId, true);
	}

	/**
	 * insert cross reference btn click event
	 */
	public insertReferenceBtnEvent() {
		let refData = { refId: this.refId, refInsId: this.refInsId, refText: this.refText, refType: this.refType, type: "crossReference" };
		if (this.refType == "Topic" || this.refType == "Procedure" || this.refType == "File") {
			let refStr = JSON.parse(JSON.stringify(refData));
			let tabType = SharedService.getTabComponentType();
			if (tabType == 'Editor') {
				SharedService.insertEditorRequest(refStr);
			} else if (tabType == 'Procedures') {
				SharedService.insertProceduresRequest(refStr);
			}
		}
	}

	openModal(template: TemplateRef<any>) {
		this.bsModalRef = this.modalService.show(template);
	}

	openLargerImageModal(template: TemplateRef<any>, viewImage: any) {
		this.viewImage = viewImage;
		this.bsModalRefLargerImage = this.modalService.show(template);
	}

	onGridReady(params) {
		//this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		params.api.sizeColumnsToFit();

	}

	/**
	 * insert other reference btn click event
	 */
	public insertOtherReferenceBtnEvent() {
		console.log('[insertOtherReferenceBtnEvent]' +this.refType);
		this.isImageSelected = false;
		let tabType = SharedService.getTabComponentType();
			if (tabType == 'Editor' || tabType == 'Procedures' ) {
				this.enableInsertRef = true;
			} else {
				this.enableInsertRef = false;
			}		
		if (this.refType == "Topic") {
			if (this.topicContent != null) {
				this.tableRowData = [];
				this.imageRowData = [];
				var xmlParser = new DOMParser();
				var xmlDoc = xmlParser.parseFromString(this.topicContent, "text/xml");
				var tableList = xmlDoc.querySelectorAll('table');
				for (let i = 0; i < tableList.length; i++) {
					let tableInsId = tableList[i].getAttribute("insid");
					let tabletype = tableList[i].getAttribute("type");
					let tableTitleNodeList = tableList[i].querySelector("caption");
					let tableTitle = "";
					if (tableTitleNodeList != null && tableTitleNodeList != undefined) {
						tableTitle = tableTitleNodeList.textContent;
					}
					let tableRefType = "";
					if (tabletype == "commissioning") {
						tableRefType = tableList[i].getAttribute("comtype");
					} else {
						tableRefType = "Table";
					}
					this.tableRowData.push({
						refType: tableRefType,
						refInsId: tableInsId,
						refText: tableTitle,
						refId: this.refId,
					})
				}
				//var
				var imageList = xmlDoc.querySelectorAll('figure');
				let imgInsId = "";
				let imageTitle = "";
				let imageId = "";
				for (let i = 0; i < imageList.length; i++) {
					let imgNodeList = imageList[i].querySelector("img");
					imageId = imgNodeList.getAttribute("id");
					imgInsId = imgNodeList.getAttribute("insid");
					let titleNodeList = imageList[i].querySelector("figcaption");
					if (titleNodeList != null && titleNodeList != undefined) {
						imageTitle = titleNodeList.textContent;
					} else {
						imageTitle = "";
					}
					this.imageRowData.push({
						refType: "Figure",
						refInsId: imgInsId,
						refText: imageTitle,
						imageId: imageId,
						refId: this.refId,
					})
				}

			}
		} else if (this.refType == "Procedure") {
			if (this.procedureContent != null) {
				this.tableRowData = [];
				this.imageRowData = [];
				var xmlParser = new DOMParser();
				var xmlDoc = xmlParser.parseFromString(this.procedureContent, "text/xml");
				var proImageList = xmlDoc.querySelectorAll('Figure');
				let imgInsId = "";
				let imageTitle = "";
				let imageId = "";
				for (let i = 0; i < proImageList.length; i++) {
					let imgNodeList = proImageList[i].querySelector("Image");
					imageId = imgNodeList.getAttribute("id");
					imgInsId = proImageList[i].getAttribute("insID");
					let titleNodeList = proImageList[i].querySelector("Title");
					imageTitle = titleNodeList.textContent;
					this.imageRowData.push({
						refType: "Figure",
						refInsId: imgInsId,
						refText: imageTitle,
						imageId: imageId,
						refId: this.refId,
					})
				}

			}

		}
		this.showFigureRef();
		//this.gridOptions.api.sizeColumnsToFit();
		this.openModal(this.insert_other_reference);
	}

	/**
	 * Figure reference tab click event
	 */
	showFigureRef() {
		this.imageRef = true;
		this.selectedOtherReference = "figureRef";
		this.rowData = this.imageRowData;
		if (this.rowData.length > 0) {
			this.enableInsertRefCount = true;
		} else {
			this.enableInsertRefCount = false;
		}
	}

	/**
	 * Table reference tab click event
	 */
	showTableRef() {
		this.selectedOtherReference = "tableRef";
		this.imageRef = false;
		this.rowData = this.tableRowData;
		if (this.rowData.length > 0) {
			this.enableInsertRefCount = true;
		} else {
			this.enableInsertRefCount = false;
		}
	}

	private toggleColumnDefs() {
		if (this.selectedOtherReference == "figureRef") {
			this.imageRef = true;
			this.columnDefs = this.imageColumnDefs;
		} else if (this.selectedOtherReference == "tableRef") {
			this.columnDefs = this.TableColumnDefs;
		}
		SharedService.setTable(this.rowData);
	}

	onRowSelected(event) {
		//console.log(event.data);
		// this.refId = event.data.refId,
		// this.refInsId = event.data.refInsId;
		// this.refText = event.data.refText;
		// this.refType = event.data.refType;
		// this.imageId = event.data.imageId;		
	}

	onRowClick(event) {
		if (event.node.isSelected()) {
			console.log("deselected");
			//this.enableInsertRef = false;
		} else {
			//console.log("selected" +event.data);
			event.node.setSelected(true);

			this.refId = event.data.refId,
			this.refInsId = event.data.refInsId;
			this.refText = event.data.refText;
			this.refType = event.data.refType;
			this.imageId = event.data.imageId;	

			if (this.imageId != null) {
	      	this.isImageSelected = true;
				this.topicmapBuilderService.getImageView(this.imageId).subscribe(
					data => {
						if (data != null) {
							this.imageData = data.payload.imageData;
							this.imageTitle = data.payload.title;

							this.viewImage = {
								'largeImage': data.payload.imagedata,
								'title': data.payload.title
							}
						}
					},
					error => {
						console.log('[getImageView] (error) ' + JSON.stringify(error.statusDescription));
						this.alertService.error(error.statusDescription);
					}
				);
			}
		}
	}

	insertOtherReference(event) {
		let refData = { refId: this.refId, refInsId: this.refInsId, refText: this.refText, refType: this.refType, type: "crossReference" };
		//if (this.refType == "Figure" || this.refType == "Table") {
		let refStr = JSON.parse(JSON.stringify(refData));
		let tabType = SharedService.getTabComponentType();
		if (tabType == 'Editor') {
			SharedService.insertEditorRequest(refStr);
		} else if (tabType == 'Procedures') {
			SharedService.insertProceduresRequest(refStr);
		}
			refData = {refId: "",refInsId: "",refText:"", refType: "", type: ""};
		//}

	}

	viewSelectedImage($event) {
		this.openLargerImageModal(this.largeImage, this.viewImage);
	}

	undoCheckoutConfirm() {
		console.log("undoCheckoutConfirm")
		this.bsModalRef = this.modalService.show(this.undoCheckOut);
	}
	onConfirm() {
		this.undoCheckoutTopicmap();
		//this.isTopicMapEdited = false;
	}
} 
