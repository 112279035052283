import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { UserVariable } from '../../util/common/user-variable';
import { ServiceUrls } from '../../util/service-urls';
import { WsResponse } from '../../util/ws-response.model';
import { WsCallback } from '../../util/ws-callback.interface';
import { WsType } from '../../util/ws-type';
import { SharedService } from '../../util/shared.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { CommonUtil } from 'src/app/util/common/common-util';

@Injectable()
export class StatementsService {

	public token: string;
	private apiGridData: any = null;
	private rowData: any = null;
	private selectedEditorButton: any = null;
	private subCategoryList: any = [];
	private CategoryData: any = [];
	private addEditPanel: any;
	private selectedCategoryId: any;
	private level: any = 0;
	private callback: WsCallback;
	constructor(private http: HttpClient, private sharedService: SharedService, private commonUtil: CommonUtil) { }

	/**
	  * Call service method for statement serach using get request.
	  * @param categoryId category id
	  */
	getStatementByCategory(id: any, callback: WsCallback) {
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
		const options = { params: new HttpParams().set("token", token) }
		let url = this.getStatementsByCategoryURL(id);

		this.http.get(url, options).
			subscribe(
				data => {
					var modified = JSON.parse(JSON.stringify(data));
					var res = new WsResponse(
						modified.status.description,
						modified.status.name,
						modified.status.code,
						modified.payload);
					callback.onSuccess(res, WsType.STATEMENT_SEARCH_VIEW);
				},
				error => {
					var modified = JSON.parse(JSON.stringify(error));
					var res = new WsResponse(
						modified.error.status.description,
						modified.error.status.name,
						modified.error.status.code,
						modified.error.payload);
					callback.onFail(res, WsType.STATEMENT_SEARCH_VIEW);
				});
	}

	public disableEnableFileTableData(fileId: any, title: String, status: String) {
		if (this.apiGridData != null && this.apiGridData != undefined && fileId != null) {
			var rowNode = this.apiGridData.getRowNode(fileId);
			if (rowNode != null && rowNode != undefined) {
				if (title == 'Disable Confirm') {
					this.sharedService.disableCellRendererRequest(true, fileId);
					rowNode.setDataValue("status", "status:" + fileId + ":Inactive");
					rowNode.setDataValue("delete", "delete:" + fileId + ":Inactive");
				} else if (title == 'Enable Confirm') {
					this.sharedService.disableCellRendererRequest(false, fileId);
					rowNode.setDataValue("status", "status:" + fileId + ":Active");
					rowNode.setDataValue("delete", "delete:" + fileId + ":Active");
				}
				else {
					if (status == "Inactive") {
						this.sharedService.disableCellRendererRequest(false, fileId);
						rowNode.setDataValue("status", "status:" + fileId + ":Active");
						rowNode.setDataValue("delete", "delete:" + fileId + ":Active");
					}
					else {
						this.sharedService.disableCellRendererRequest(true, fileId);
						rowNode.setDataValue("status", "status:" + fileId + ":Inactive");
						rowNode.setDataValue("delete", "delete:" + fileId + ":Inactive");
					}
				}
			}
		}
	}

	/**
	 * Update statement. 
	 * @param statementModel.
	 * @param statementId. 
	 */
	updateStatement(statementModel: String, id: String, callBack: WsCallback) {
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
		const options = { params: new HttpParams().set("token", token) }
		let url = ServiceUrls.STATEMENTS + "/" + id + '.json'
		this.http.put(url, statementModel, options).subscribe(
			data => {
				// this may be incorrect
				//var val = (data as HttpErrorResponse).message;
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				callBack.onSuccess(res, WsType.STATEMENT_UPDATE);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					callBack.onFail(res, WsType.STATEMENT_UPDATE);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					callBack.onFail(res, WsType.STATEMENT_UPDATE);
				}
			}
		);

	}


	public deleteFileTableData(fileId: any) {
		if (this.apiGridData != null && this.apiGridData != undefined && fileId != null) {
			var selectedData = this.apiGridData.getSelectedRows();
			if (selectedData != null && selectedData != undefined) {
				this.apiGridData.applyTransaction({ remove: selectedData });
				let newRowData:any = [];
				this.apiGridData.forEachNode( function(rowNode, index) {
					newRowData.push(rowNode.data);
				});
				this.setRowData(newRowData);
			}
		}
	}

	/**
	 * Define URL for add statement.
	 *  @param statementModel.
	 */
	addStatement(statementModel: String, callBack: WsCallback) {
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
		const options = { params: new HttpParams().set("token", token) }
		this.http.post(ServiceUrls.STATEMENTS + '.json', statementModel, options).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				callBack.onSuccess(res, WsType.STATEMENT_ADD);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					callBack.onFail(res, WsType.STATEMENT_ADD);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					callBack.onFail(res, WsType.STATEMENT_ADD);
				}
			}
		);
	}


	/**
	 * Get statement data. 
	 * @param fileId.
	 */
	getStatementDataByStatementId(callBack: WsCallback) {
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
		const options = { params: new HttpParams().set("token", token) }
		let url = ServiceUrls.STATEMENTS + "/" + this.sharedService.getFileId() + '.json'
		this.http.get(url, options).
			subscribe(
				data => {
					var modified = JSON.parse(JSON.stringify(data));
					var res = new WsResponse(
						modified.status.code,
						modified.status.name,
						modified.status.description,
						modified.payload);
					//console.log("response " + JSON.stringify(data));
					callBack.onSuccess(res, WsType.STATEMENT_DATA_VIEW);
				},
				error => {
					var modified = JSON.parse(JSON.stringify(error));
					var res = new WsResponse(
						modified.status.code,
						modified.status.name,
						modified.status.description,
						modified.payload);
					callBack.onSuccess(res, WsType.STATEMENT_DATA_VIEW);
				});
	}

	/**
 * Define URL for delete statement.
 */
	deleteStatement(id: any, callBack: WsCallback) {
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
		let url = ServiceUrls.STATEMENTS + "/" + id + '.json'
		const options = { params: new HttpParams().set("token", token) }
		this.http.delete(url, options).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				callBack.onSuccess(res, WsType.DELETE_ITEM);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					callBack.onFail(res, WsType.DELETE_ITEM);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					callBack.onFail(res, WsType.DELETE_ITEM);
				}
			}
		);
	}

	public setApiGridData(apiGridData: any) {
		this.apiGridData = apiGridData;
	}

	public getApiGridData() {
		return this.apiGridData;
	}

	public setRowData(rowData: any) {
		this.rowData = rowData;
	}

	public setSubCategoryList(subCategoryList: any) {
		this.subCategoryList = subCategoryList;
	}

	public getSubCategoryList() {
		return this.subCategoryList;
	}

	public getRowData() {
		return this.rowData;
	}

	public setCategoryData(categoryData: any) {
		this.CategoryData = categoryData;
	}

	public getCategoryData() {
		return this.CategoryData;
	}

	public setSelectedCategoryId(selectedCategoryId: any) {
		this.selectedCategoryId = selectedCategoryId;
	}

	public getSelectedCategoryId() {
		return this.selectedCategoryId;
	}

	public setLevel(level: any) {
		this.level = level;
	}

	public getLevel() {
		return this.level;
	}

    /**
    * Define URL for get facility list by facility search criteria.
    */
	private getStatementsByCategoryURL(categoryId: String) {
		let url = ServiceUrls.STATMENT_SEARCH_VIEW + categoryId + '/getStatementsByCategory.json';
		return url;
	}

	public setSelectedEditorButton(selectedEditorButton: any) {
		//console.log("seee" + selectedEditorButton)
		this.addEditPanel.data = selectedEditorButton;
	}

	public getSelectedEditorButton() {
		return this.selectedEditorButton;
	}

	// public setAddEditPanel(addEditPanel: any) {
	// 	this.addEditPanel = addEditPanel;
	// }

	// public getAddEditPanel() {
	// 	return this.addEditPanel;
	// }


	searchStatements(statement: String, categoryName: String, title: String, addCatChild: String, hasText: String) {
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
		let url = this.getStatementSearchByNameURL(token);
		return this.http.post(url, JSON.stringify(new Request(statement, categoryName, title, addCatChild, hasText))).
			pipe(
				map(response => {
					console.log('response', response);
					var modified = JSON.parse(JSON.stringify(response));
					return new WsResponse(
						modified.status.description,
						modified.status.name,
						modified.status.code,
						modified.payload
					);

				}),
				catchError(error => {
					var modified = JSON.parse(JSON.stringify(error));
					var res = new WsResponse(
						modified.error.status.description,
						modified.error.status.name,
						modified.error.status.code,
						modified.error.payload);
					return throwError(res)
				})
			);
	}
	public getStatementSearchByNameURL(token: String) {
		let url = ServiceUrls.STATEMENT_SEARCH_WITH_CATEGORY + '?token=' + token;
		return url;
	}

}

class Request {
	constructor(private statement: String, private categoryName: String, private title: String, private addCatChild: String, private hasText: String) { }
}