import { Component, Input, OnInit, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { SearchParams } from './search-params.modal';
import { SearchControlService } from './search-control.service';
import { SearchReturn } from './search-return.modal';
import { Constants } from '../../constants';
import { SharedService } from '../../shared.service';

@Component({
    selector: 'symbiosis-search-form',
    templateUrl: './search-form.component.html',
    providers: [SearchControlService],
    outputs: ['searchClick']
})
export class SearchFormComponent implements OnInit {

    @Input() searchParams: SearchParams<any>[] = [];
    form: FormGroup;
    payLoad = [];
    searchClick = new EventEmitter<any>()
    selectedCategoryItem: any = null;
    selectedDocType: any = null;
    tagInputItems = [];
    constructor(private scs: SearchControlService, private sharedService: SharedService) { }

    ngOnInit() {
        this.form = this.scs.toFormGroup(this.searchParams);
    }

    //search button click event
    onSubmit() {
        // console.log('[onSubmit] (SearchParams)' + JSON.stringify(this.searchParams));
        this.payLoad = []
        this.generatePayLoad()
        // console.log('[onSubmit] (this.payLoad) ' + JSON.stringify(this.payLoad) );
        this.searchClick.emit(this.payLoad)
    }

    /**
     * generate payload 
     */
    generatePayLoad() {
        this.searchParams.forEach(item => {
            // console.log('[generatePayLoad] ' + item.key + '-' + this.form.get(item.key).value);
           //alert( JSON.stringify(item));
           
            let srItem: SearchReturn = new SearchReturn()
            if(this.form.get(item.key).value == '') {
                if((item.key == Constants.SEARCH_KEY_WORDS.SEARCH_STATEMENT_STM_CATEGORY) || item.key == (Constants.SEARCH_KEY_WORDS.SEARCH_COMPONENT_COMP_CATEGORY) || item.key == (Constants.SEARCH_KEY_WORDS.SEARCH_CHK_CATEGORY)) {
                    srItem.key = item.key
                    if(this.selectedCategoryItem != null) {
                        srItem.value = this.selectedCategoryItem.catName;
                        this.payLoad.push(srItem)
                    } else {

                    }
                } else if((item.key == (Constants.SEARCH_KEY_WORDS.SEARCH_IMAGE_IMG_DOCTYPE)) || (item.key == (Constants.SEARCH_KEY_WORDS.SEARCH_DOCUMENT_DOC_DOCTYPE)) || 
                        (item.key == (Constants.SEARCH_KEY_WORDS.SEARCH_THREED_MODEL_DOCTYPE)) || (item.key == (Constants.SEARCH_KEY_WORDS.SEARCH_VIDEO_DOCTYPE))) {
                    srItem.key = item.key
                    if(this.selectedDocType != null) {
                        srItem.value = this.selectedDocType.docName;
                        this.payLoad.push(srItem)
                    } else {

                    }
                }  else if((item.key == (Constants.SEARCH_KEY_WORDS.SEARCH_IRN_VENDOR)) || (item.key == (Constants.SEARCH_KEY_WORDS.SEARCH_IRN_SYSTEM))  || (item.key == (Constants.SEARCH_KEY_WORDS.SEARCH_IRN_ASSET)) || (item.key == (Constants.SEARCH_KEY_WORDS.SEARCH_IRN_IRN_DESCRIPTION)) || (item.key == (Constants.SEARCH_KEY_WORDS.SEARCH_IRN_IRN_NUMBER)) 
                || (item.key == (Constants.SEARCH_KEY_WORDS.SEARCH_IRN_STATUS)) || (item.key == (Constants.SEARCH_KEY_WORDS.SEARCH_IRN_COMPLETION_DATE))  ){
                    srItem.key = item.key
                    srItem.value = []
                    this.tagInputItems.forEach((elment) => {
                        if(elment.key ==item.key){
                            srItem.value = elment.value
                        }
                    });
                    this.payLoad.push(srItem);
                } else if((item.key == (Constants.SEARCH_KEY_WORDS.SEARCH_MCC_SYSTEM)) || (item.key == (Constants.SEARCH_KEY_WORDS.SEARCH_MCC_STATUS)) || (item.key == (Constants.SEARCH_KEY_WORDS.SEARCH_MCC_COMPLETION_DATE)) ){
                    srItem.key = item.key
                    srItem.value = []
                    this.tagInputItems.forEach((elment) => {
                        if(elment.key ==item.key){
                            srItem.value = elment.value
                        }
                    });
                    this.payLoad.push(srItem);
                } else if((item.key == (Constants.SEARCH_KEY_WORDS.SEARCH_OTN_ASSET)) || (item.key == (Constants.SEARCH_KEY_WORDS.SEARCH_OTN_VENDOR)) || (item.key == (Constants.SEARCH_KEY_WORDS.SEARCH_OTN_STATUS)) || (item.key == (Constants.SEARCH_KEY_WORDS.SEARCH_OTN_COMPLETION_DATE)) ){
                    srItem.key = item.key
                    srItem.value = []
                    this.tagInputItems.forEach((elment) => {
                        if(elment.key ==item.key){
                            srItem.value = elment.value
                        }
                    });
                    this.payLoad.push(srItem);
                }
                else if( item.key === Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_NO || item.key === Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_DESC || item.key === Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_SYS ||
                    item.key === Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_GROUP || item.key === Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_PARENT || item.key === Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_FUNCCODE || item.key === Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_ATTRIBUTE){
                    srItem.key = item.key
                    srItem.value = []
                    this.tagInputItems.forEach((elment) => {
                        if(elment.key ==item.key){
                            srItem.value = elment.value
                        }
                    });
                    this.payLoad.push(srItem);
                }
                else if(item.key === Constants.SEARCH_KEY_WORDS.SEARCH_SYS_NO ||  item.key === Constants.SEARCH_KEY_WORDS.SEARCH_SYS_NAME){
                    srItem.key = item.key
                    srItem.value = []
                    this.tagInputItems.forEach((elment) => {
                        if(elment.key ==item.key){
                            srItem.value = elment.value
                        }
                    });
                    this.payLoad.push(srItem);
                }
                else if(item.key === Constants.SEARCH_KEY_WORDS.ISSUE_CS_VENDOR || item.key === Constants.SEARCH_KEY_WORDS.ISSUE_CS_CHECKSHEET ||
                    item.key === Constants.SEARCH_KEY_WORDS.ISSUE_CS_ASSET || item.key === Constants.SEARCH_KEY_WORDS.ISSUE_CS_ASSET_DESCRIPTION ||
                    item.key === Constants.SEARCH_KEY_WORDS.ISSUE_CS_SYSTEM || item.key === Constants.SEARCH_KEY_WORDS.ISSUE_CS_ASSET_GROUP ||
                    item.key === Constants.SEARCH_KEY_WORDS.ISSUE_CS_PHASE || item.key === Constants.SEARCH_KEY_WORDS.ISSUE_CS_ASSET_PARENT ||
                    item.key === Constants.SEARCH_KEY_WORDS.ISSUE_CS_FUNCTION_CODE){
                    srItem.key = item.key
                    srItem.value = []
                    this.tagInputItems.forEach((elment) => {
                        if(elment.key ==item.key){
                            srItem.value = elment.value
                        }
                    });
                    this.payLoad.push(srItem); 
                }
                else if(item.key === Constants.SEARCH_KEY_WORDS.RECEIVE_CS_VENDOR || item.key === Constants.SEARCH_KEY_WORDS.RECEIVE_CS_CHECKSHEET ||
                    item.key === Constants.SEARCH_KEY_WORDS.RECEIVE_CS_ASSET || item.key === Constants.SEARCH_KEY_WORDS.RECEIVE_CS_ASSET_DESCRIPTION ||
                    item.key === Constants.SEARCH_KEY_WORDS.RECEIVE_CS_SYSTEM || item.key === Constants.SEARCH_KEY_WORDS.RECEIVE_CS_ASSET_GROUP ||
                    item.key === Constants.SEARCH_KEY_WORDS.RECEIVE_CS_PHASE || item.key === Constants.SEARCH_KEY_WORDS.RECEIVE_CS_FUNCTION_CODE){
                        srItem.key = item.key
                        srItem.value = []
                        this.tagInputItems.forEach((elment) => {
                            if(elment.key ==item.key){
                                srItem.value = elment.value
                            }
                        });
                        this.payLoad.push(srItem); 
                }
                else if((item.key == (Constants.SEARCH_KEY_WORDS.SEARCH_PCC_SYSTEM)) || (item.key == (Constants.SEARCH_KEY_WORDS.SEARCH_PCC_STATUS)) || (item.key == (Constants.SEARCH_KEY_WORDS.SEARCH_PCC_COMPLETION_DATE)) ){
                    srItem.key = item.key
                    srItem.value = []
                    this.tagInputItems.forEach((elment) => {
                        if(elment.key ==item.key){
                            srItem.value = elment.value
                        }
                    });
                    this.payLoad.push(srItem);
                } 
            } 
            else {
                if((item.key == Constants.SEARCH_KEY_WORDS.SEARCH_STATEMENT_STM_CATEGORY) || item.key == (Constants.SEARCH_KEY_WORDS.SEARCH_COMPONENT_COMP_CATEGORY) || item.key == (Constants.SEARCH_KEY_WORDS.SEARCH_CHK_CATEGORY)) {
                    srItem.key = item.key
                    if(this.selectedCategoryItem != null) {
                        srItem.value = this.selectedCategoryItem.catName;
                        this.payLoad.push(srItem)
                    } else {
                        srItem.value = this.form.get(item.key).value;
                        this.payLoad.push(srItem)
                    }
                } else if( item.key == (Constants.SEARCH_KEY_WORDS.SEARCH_IMAGE_IMG_DOCTYPE) || (item.key == (Constants.SEARCH_KEY_WORDS.SEARCH_DOCUMENT_DOC_DOCTYPE))
                            || (item.key == (Constants.SEARCH_KEY_WORDS.SEARCH_THREED_MODEL_DOCTYPE)) || (item.key == (Constants.SEARCH_KEY_WORDS.SEARCH_VIDEO_DOCTYPE))) {
                    srItem.key = item.key
                    if(this.selectedDocType != null) {
                        srItem.value = this.selectedDocType.docName;
                        this.payLoad.push(srItem)
                    } else {
                        srItem.value = this.form.get(item.key).value;
                        this.payLoad.push(srItem)
                    }
                } else if((item.key == (Constants.SEARCH_KEY_WORDS.SEARCH_IRN_VENDOR)) || (item.key == (Constants.SEARCH_KEY_WORDS.SEARCH_IRN_ASSET)) || (item.key == (Constants.SEARCH_KEY_WORDS.SEARCH_IRN_STATUS))
                || (item.key == (Constants.SEARCH_KEY_WORDS.SEARCH_IRN_COMPLETION_DATE)) ){
                    srItem.key = item.key
                    srItem.value = []
                    this.tagInputItems.forEach((elment) => {
                        if(elment.key ==item.key){
                            srItem.value = elment.value
                        }
                    });
                    this.payLoad.push(srItem);
                } else if((item.key == (Constants.SEARCH_KEY_WORDS.SEARCH_MCC_SYSTEM)) || (item.key == (Constants.SEARCH_KEY_WORDS.SEARCH_MCC_STATUS)) || (item.key == (Constants.SEARCH_KEY_WORDS.SEARCH_MCC_COMPLETION_DATE)) ){
                    srItem.key = item.key
                    srItem.value = []
                    this.tagInputItems.forEach((elment) => {
                        if(elment.key ==item.key){
                            srItem.value = elment.value
                        }
                    });
                    this.payLoad.push(srItem);
                } else if((item.key == (Constants.SEARCH_KEY_WORDS.SEARCH_OTN_ASSET)) || (item.key == (Constants.SEARCH_KEY_WORDS.SEARCH_OTN_VENDOR)) || (item.key == (Constants.SEARCH_KEY_WORDS.SEARCH_OTN_STATUS)) || (item.key == (Constants.SEARCH_KEY_WORDS.SEARCH_OTN_COMPLETION_DATE)) ){
                    srItem.key = item.key
                    srItem.value = []
                    this.tagInputItems.forEach((elment) => {
                        if(elment.key ==item.key){
                            srItem.value = elment.value
                        }
                    });
                    this.payLoad.push(srItem);
                }
                else if( item.key === Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_NO || item.key === Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_DESC || item.key === Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_SYS ||
                    item.key === Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_GROUP || item.key === Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_PARENT || item.key === Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_FUNCCODE || item.key === Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_ATTRIBUTE){
                    srItem.key = item.key
                    srItem.value = []
                    this.tagInputItems.forEach((elment) => {
                        if(elment.key ==item.key){
                            srItem.value = elment.value
                        }
                    });
                    this.payLoad.push(srItem);
                }
                else if(item.key === Constants.SEARCH_KEY_WORDS.SEARCH_SYS_NO ||  item.key === Constants.SEARCH_KEY_WORDS.SEARCH_SYS_NAME){
                    srItem.key = item.key
                    srItem.value = []
                    this.tagInputItems.forEach((elment) => {
                        if(elment.key ==item.key){
                            srItem.value = elment.value
                        }
                    });
                    this.payLoad.push(srItem);
                }
                else if(item.key === Constants.SEARCH_KEY_WORDS.ISSUE_CS_VENDOR || item.key === Constants.SEARCH_KEY_WORDS.ISSUE_CS_CHECKSHEET ||
                    item.key === Constants.SEARCH_KEY_WORDS.ISSUE_CS_ASSET || item.key === Constants.SEARCH_KEY_WORDS.ISSUE_CS_ASSET_DESCRIPTION ||
                    item.key === Constants.SEARCH_KEY_WORDS.ISSUE_CS_SYSTEM || item.key === Constants.SEARCH_KEY_WORDS.ISSUE_CS_ASSET_GROUP ||
                    item.key === Constants.SEARCH_KEY_WORDS.ISSUE_CS_PHASE || item.key === Constants.SEARCH_KEY_WORDS.ISSUE_CS_ASSET_PARENT ||
                    item.key === Constants.SEARCH_KEY_WORDS.ISSUE_CS_FUNCTION_CODE){
                    srItem.key = item.key
                    srItem.value = []
                    this.tagInputItems.forEach((elment) => {
                        if(elment.key ==item.key){
                            srItem.value = elment.value
                        }
                    });
                    this.payLoad.push(srItem); 
                } else if(item.key === Constants.SEARCH_KEY_WORDS.RECEIVE_CS_VENDOR || item.key === Constants.SEARCH_KEY_WORDS.RECEIVE_CS_CHECKSHEET ||
                    item.key === Constants.SEARCH_KEY_WORDS.RECEIVE_CS_ASSET || item.key === Constants.SEARCH_KEY_WORDS.RECEIVE_CS_ASSET_DESCRIPTION ||
                    item.key === Constants.SEARCH_KEY_WORDS.RECEIVE_CS_SYSTEM || item.key === Constants.SEARCH_KEY_WORDS.RECEIVE_CS_ASSET_GROUP ||
                    item.key === Constants.SEARCH_KEY_WORDS.RECEIVE_CS_PHASE || item.key === Constants.SEARCH_KEY_WORDS.RECEIVE_CS_FUNCTION_CODE){
                        srItem.key = item.key
                        srItem.value = []
                        this.tagInputItems.forEach((elment) => {
                            if(elment.key ==item.key){
                                srItem.value = elment.value
                            }
                        });
                        this.payLoad.push(srItem); 
                    }
                    else if((item.key == (Constants.SEARCH_KEY_WORDS.SEARCH_PCC_SYSTEM)) || (item.key == (Constants.SEARCH_KEY_WORDS.SEARCH_PCC_STATUS)) || (item.key == (Constants.SEARCH_KEY_WORDS.SEARCH_PCC_COMPLETION_DATE)) ){
                        srItem.key = item.key
                        srItem.value = []
                        this.tagInputItems.forEach((elment) => {
                            if(elment.key ==item.key){
                                srItem.value = elment.value
                            }
                        });
                        this.payLoad.push(srItem);
                    } 
                else {
                    srItem.key = item.key
                    srItem.value = this.form.get(item.key).value
                    this.payLoad.push(srItem)
                }
            }
         });
    }

    /**
     * replace empty value
     * used in form 
     * @param value 
     */
    replaceEmptyValue(value) {
        if(value === '') {
            return undefined
        } 
        return value
    }

    categorySelected(event: any) {
        this.selectedCategoryItem = event
    }

    documentSelected(event: any) {
        this.selectedDocType = event
    }

    taginputSeleted(event: any){
        let key = event.key;
        this.tagInputItems = this.tagInputItems.filter( item => item.key != key);
        this.tagInputItems.push(event);
        console.log(this.tagInputItems);

        if(key == Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_GROUP) {
            this.tagInputItems.forEach(tagInput => {
                if(tagInput.key == Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_GROUP) {
                    if(tagInput.value.length == 1) {
                        this.searchParams.forEach(item => {
                            if(item.key == Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_ATTRIBUTE) {                     
                                item.disable = false;
                            }
                        });
                    }
                    else {
                        this.searchParams.forEach(item => {
                            if(item.key == Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_ATTRIBUTE) {  
                                this.tagInputItems = this.tagInputItems.filter(x => x.key != item.key);  
                                //this.sharedService.setTagInputList(item.key, []);          
                                item.disable = true;
                            }
                        });
                    }
                }
            });
        }
    }

    setDropdownValues() {
      for (let i = 0; i < this.searchParams.length; i++) {
        if(this.searchParams[i].key === 'filetype') {
            for (let j = 0; j < this.searchParams[i].options.length; j++) {
                if (this.searchParams[i].options[j].key === this.searchParams[i].value) {
                    this.form.controls['filetype'].setValue([{
                        id: this.searchParams[i].options[j].key , 
                        text: this.searchParams[i].options[j].value
                    }]);
                    break;
                }
            }
            break;
        } 
      }  
    }   
}
