import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams, IAfterGuiAttachedParams } from "@ag-grid-enterprise/all-modules";
import { TagsService } from '../tags.service';
import { WsCallback } from 'src/app/util/ws-callback.interface';
import { WsType } from 'src/app/util/ws-type';
import { WsResponse } from 'src/app/util/ws-response.model';
import { AlertService } from 'src/app/util/alert/alert.service';
import { UserVariable } from 'src/app/util/common/user-variable';
import { DependenciesModalService } from 'src/app/util/dependenciesmodal/dependenciesmodal.service';
import { SharedService } from 'src/app/util/shared.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TagDependencyComponent } from '../tag-dependency/tag-dependency.component';
import { DependenciesmodalComponent } from 'src/app/util/dependenciesmodal/dependenciesmodal.component';
import { DependencyModalComponent } from '../tag-classification/dependency-modal/dependency-modal.component';
import { ConfirmModalComponent } from '../tag-classification/confirm-modal/confirm-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { CommonUtil } from 'src/app/util/common/common-util';

@Component({
	selector: 'app-classification-cell-render',
	templateUrl: './classification-cell-render.component.html',
	styleUrls: ['./classification-cell-render.component.css']
})
export class ClassificationCellRenderComponent implements OnInit, ICellRendererAngularComp, WsCallback {
	private token: string;
	public isDelete: boolean = false;
	public isRequired: boolean = false;
	public isUpDownButton: boolean = false;
	public isAttributeName: boolean = false;
	public isAttributeType: boolean = false;
	public isCategory: boolean = false;
	private isNewItem: boolean = false;
	public isR: boolean = false

	private params: any
	public value: any;
	public selectedAttributeType: any;
	public attributeId: string;
	public tagClasSpecId: string;
	private bsModalRef: BsModalRef;
	public console: any;

	private parentComponent: any;

	constructor(private tagService: TagsService, private alertService: AlertService,
		private sharedService: SharedService, private modalService: BsModalService, private translate: TranslateService, private commonUtil: CommonUtil) {
		//console.log('constructor');
		this.console = console;
		//Initialization of variables
        this.token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(this.token)) {
            return;
        }
	}

	ngOnInit() {
		//console.log('init');
	}

	refresh(params: any): boolean {
		return true;
	}

	agInit(params: ICellRendererParams): void {
		this.params = params
		this.value = params.value;
		this.isNewItem = params.data.newItem;
		this.attributeId = params.data.attributeId;
		this.tagClasSpecId = params.data.tagClasSpecId;
		this.parentComponent = params.context.parent;
		this.isR = this.assignIsRequired(params.data.required)
		switch (params.colDef.headerName) {
			case 'Delete':
				this.changeCellVisibility([true, , , , ,]);
				break;
			case 'Required':
				this.changeCellVisibility([, true, , , ,]);
				break;
			case '#':
				this.changeCellVisibility([, , true, , ,]);
				break;
			case 'Attribute':
				this.changeCellVisibility([, , , true, ,]);
				break;
			case 'Attribute Type':
				this.changeCellVisibility([, , , , true,]);
				break;
			case 'Dot Notation':
				this.changeCellVisibility([, , , , , true]);
				break;
			default:
				break;
		}
	}

	private assignIsRequired(required: string): boolean {
		if(required == 'yes') {
			return true
		} else {
			return false
		}
	}


	changeRequiredIcon() {

	}

	afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
	}

	changeCellVisibility(visibilityMap: boolean[]) {
		this.isDelete = visibilityMap[0];
		this.isRequired = visibilityMap[1];
		this.isUpDownButton = visibilityMap[2];
		this.isAttributeName = visibilityMap[3];
		this.isAttributeType = visibilityMap[4];
		this.isCategory = visibilityMap[5];
	}

	public toggleButtonClick() {
		console.log(this.params.node.data.required);

		if (this.isNewItem) {
			this.parentComponent.setRequiredField();
			return;
		}
		var data = { userToken: this.token, tagClasSpecId: this.tagClasSpecId };
		this.tagService.toggleTagClassificationAttributeRequireStatus(data, this);
	}

	public deleteButtonClick() {

        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
		var request: any = {
			"itemId": this.tagClasSpecId,
			"itemType": 'TagClassificationAttributeMap',
			"action": 'delete',
            "userToken": token
		};
		this.tagService.getTagDependenciesByTag(JSON.stringify(request), this);
	}

	onSuccess(data: WsResponse, serviceType: WsType) {
		if (serviceType == WsType.TOGGLE_TAG_CLASSIFICATION_ATTRIBUTE_REQUIRE_STATUS) {
			let alert = this.translate.instant('TAG_CLASSIFICATION.TOGGLE_TAG_CLASSIFICATION_ATTRIBUTE_REQUIRE_STATUS');
			this.alertService.success(alert);
			this.isR = !this.isR
		} else if (serviceType == WsType.ITEM_DEPENDENCY_LIST) {
			this.alertService.success(data.statusDescription, false);
		} else if (serviceType == WsType.GET_TAG_DEPENDENCIES_BY_TAG) {
			var payload = JSON.parse(data.payload);
			if (payload.HasDependencies == 0) {
				this.showDeleteConfirmation();
			} else {
				this.dependenciesModalWithComponent(payload.TagDependency);
			}
		}
	}
	onFail(data: WsResponse, serviceType: WsType) {
		if (serviceType == WsType.TOGGLE_TAG_CLASSIFICATION_ATTRIBUTE_REQUIRE_STATUS) {
			this.alertService.error(data.statusDescription, false);
		} else if (serviceType == WsType.ITEM_DEPENDENCY_LIST) {
			this.alertService.error(data.statusDescription, false);
		} else if (serviceType == WsType.GET_TAG_DEPENDENCIES_BY_TAG) {
			this.alertService.error(data.statusDescription, false);
		}
	}

	showDeleteConfirmation() {
		const initialState : object = {
			token: this.token,
			attributeId: this.attributeId,
			tagClasSpecId: this.tagClasSpecId,
			callBack: this.parentComponent
		};
		this.bsModalRef = this.modalService.show(ConfirmModalComponent, { initialState });
	}

	dependenciesModalWithComponent(tagList: any[]) {
		const initialState : object = {
			tagDependency: tagList,
			title: 'Delete Conflicts'
		};
		this.bsModalRef = this.modalService.show(DependencyModalComponent, { initialState });
		this.bsModalRef.content.closeBtnName = 'Close';
	}
}
