import { Component, ViewChild, TemplateRef } from "@angular/core";
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { CapabilityService } from '../../../../util/capability.service'
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService } from '../../../../util/alert/alert.service';
import { LoadingService } from '../../../../util/loading/loading.service';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from "src/app/util/constants";
import { OutputManagementService } from "../../outputmanagement.service";

@Component({
    selector: 'child-cell',
    templateUrl: './delete-button.component.html',
})
export class PTDeleteButtonRenderer implements ICellRendererAngularComp {
    public params: any;
    public modifyTopicType: boolean = false
    public bsModalRef: BsModalRef;
    
    public title: string;
    public dependencyType: string;
    public status: string = "";
    @ViewChild('docAttributeDeleteModal') docAttributeDeleteModal: TemplateRef<any>;

    constructor(private outputManagementService: OutputManagementService, private capabilityService: CapabilityService, private modalService: BsModalService,
        private alertService: AlertService, private loadingService: LoadingService, private translate: TranslateService) {
        this.modifyTopicType = this.capabilityService.isCapabilityAssigned("modifyTopicType");
        translate.addLangs(["es", "en"]);
        translate.setDefaultLang("en");
        let browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
    }

    agInit(params: any): void {
        this.params = params;
        this.translate.get('OUTPUT_MANAGEMENT.DELETE_CONFLICTS').subscribe((res: string) => {
            this.title = res;
        });
    }

    public delete() {
        this.bsModalRef = this.modalService.show(this.docAttributeDeleteModal);
    }

    refresh(): boolean {
        return false;
    }

    deleteItemClick(){
        console.log("delete confirm")
        console.log(this.params);
        this.outputManagementService.deleteProjectTemplate(this.params.data.projectTemplateMapId);
    }
}