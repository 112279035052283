import { Component } from "@angular/core";
import { LandingService } from '../../landing/landing.service';
import { WsResponse } from '../../util/ws-response.model';
import { WsType } from '../../util/ws-type';
import { AlertService } from '../../util/alert/alert.service';
import { UserVariable } from '../../util/common/user-variable';
import { Router } from '@angular/router';
import { LocalStorage } from '../../util/localstorage.service';
import { RecentProjectModel } from '../../landing/recent-project.model';
import { LoggedUser } from '../../user/logged-user.model';
import { RecentProject } from '../../landing/recent-project.service';
import { SharedService } from "../../util/shared.service";
import { WsCallback } from "../../util/ws-callback.interface";


@Component({
	selector: 'app-cell-render',
	templateUrl: './files-cell-render-component.component.html'
})
export class FilesCellRenderComponent implements WsCallback {
	icon: string;
	data: string;
	type: string;
	disable: boolean = false;
	restore: boolean = false;
	IsFile: boolean = false;
	isText: boolean = false;
	private loggedInUser: LoggedUser;
	public recentProjectList: Array<RecentProjectModel> = new Array<RecentProjectModel>();

	constructor(private landingService: LandingService, private alertService: AlertService,
		private recentProjectService: RecentProject, private router: Router, private sharedService: SharedService) {
		//recentProjectService.setCallback(this);
	}

	agInit(params: any): void {
		this.loggedInUser = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER)) as LoggedUser;
		let items = params.value.split(":");
		if(params.value.indexOf(":")>0){
			let type = params.value.substring(0,params.value.indexOf(":"));
			let data = null;
			if(params.value.indexOf(":")<params.value.length){
				data = params.value.substring(params.value.indexOf(":")+1, params.value.length);
			}
			this.setType(type);
			this.type = type;
			if(data != null){
				this.data = data;
				this.loadDisabledFiles(this.data);
			}
		} else {
			//nothing to process
		}
		this.sharedService.disableCellRendererExecute().subscribe(option => this.setDisable(option));
	}


	public invoke(type: String, data: any) {
		let items = data.split(",")
		if (items != null && items.length == 2) {
			switch (type) {
				case 'oa':
					this.addToRecentlyVisitedProject(items[0], 1);
					this.landingService.setSelectedProjectName(items[1])
					this.router.navigateByUrl('/home')
					break;
				case 'com':
				case 'acom':
					//Marking the project as recently visited
					this.addToRecentlyVisitedProject(items[0], 2);
					console.log('COM project');
					break;
				case 'dashBoard':
					//Marking the project as recently visited
					this.addToRecentlyVisitedProject(items[0], 3);
					console.log('Dashboard project');
					break;
				case "procedureStore":
					//Marking the project as recently visited
					this.addToRecentlyVisitedProject(items[0], 4);
					console.log('P.Store project');
					break;
				default:
					break;
			}
		}
	}

	/**
	 * Will call the addUpdateRecentProject() in the RecentProjectService to update the recently visited project.
	 */
	private addToRecentlyVisitedProject(id: any, type: number): void {
		console.log(id);
		var recentProjectModel: RecentProjectModel = new RecentProjectModel(id, this.loggedInUser.userId.toString(), 0, type);
		this.recentProjectService.addUpdateRecentProject(JSON.stringify(recentProjectModel), this);
	}

	onSuccess(data: WsResponse, serviceType: WsType): void {
		if (serviceType == WsType.ADD_UPDATE_RECENT_PROJECT) {
			//refresh the recent list again
			this.recentProjectService.getAllRecentProjects(this.loggedInUser.userId, this);
		}
		else if (serviceType == WsType.GET_ALL_RECENT_PROJECTS) {
			this.recentProjectList = data.payload;
			this.recentProjectService.setDefaultRecentProjects(data.payload, this);
		}
		else {

		}
	}

	onFail(res: WsResponse, serviceType: WsType): void {
		if (serviceType == WsType.GET_ALL_RECENT_PROJECTS) {
			this.alertService.error(res.statusDescription);
		} else if (serviceType == WsType.ADD_UPDATE_RECENT_PROJECT) {
			//We don't show the error message here since it is out of user control.
			console.log("=======error======" + res.statusDescription);
		}
		else {

		}
	}

	private setType(params) {
		if (params == 'disableRestore') {
			this.disable = true;
			this.restore = true;
		}
		else if (params == 'restore') {
			this.restore = true;
		}
		else if (params == 'tooltip') {
			this.isText = true;
		}
		this.icon = params == 'Topic' ? "far fa-lightbulb" : params == 'Image' ? 'far fa-image ' : params == 'TopicMap' ? 'far fa-map' :
			params == 'ContentItem' ? 'far fa-file-archive' : params == 'ICECourse' ? 'far fa-diploma' :
				params == 'Procedure' ? 'far fa-layer-group ' : params == 'Folder' ? 'far fa-folder' : params == 'oa' ? 'far fa-folder mx-2' :
					params == 'com' ? 'far fa-folder mx-2' : params == 'acom' ? 'far fa-folder mx-2' : params == 'dashboard' ? 'far fa-tachometer mx-2' :
						params == 'procedure' ? 'far fa-layer-group  mx-2' : params == 'view' ? 'far fa-eye ' : params == 'restore' ? 'far fa-retweet ' :
							params == 'disableRestore' ? 'far fa-file-excel ' : params == 'ThreeDModel' ? 'far fa-cube ' : params == 'Video' ? 'fa fa-desktop '  : '' ;
	}

	private setDisable(option: any) {
		let items = this.data.split(",")
		if (option != null && option.id == items[0]) {
			this.disable = option.data;
		}
	}

	private loadDisabledFiles(data: any) {
		let items = data.split(",")
		if (items != null && items.length == 2) {
			if (items[1] == "Inactive" || items[1] == "Importing" || items[1] == "OnImportQueue") {
				this.disable = true;
				this.IsFile = true;
			} else if (items[1] == "Active") {
				this.disable = false;
				this.IsFile = true;
			} else {
				this.disable = false;
				this.IsFile = false;
			}
		} else {
			this.disable = false;
			this.IsFile = false;
		}
	}
}  