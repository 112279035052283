import { Component, ViewChild, TemplateRef } from "@angular/core";
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { CapabilityService } from '../../../../../util/capability.service'
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DeleteDocAttributeTypeModalComponent } from '../../confirmation-modal/deleteconfirmationmodal';
import { DocumentTypesService } from '../../document-types.service';
import { AlertService } from '../../../../../util/alert/alert.service';
import { LoadingService } from '../../../../../util/loading/loading.service';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from "src/app/util/constants";
import { SharedService } from "../../../../../util/shared.service";
import { DependenciesmodalComponent } from '../../../../../util/dependenciesmodal/dependenciesmodal.component';

@Component({
    selector: 'child-cell',
    templateUrl: './delete-button.component.html',
})
export class DTDeleteButtonRenderer implements ICellRendererAngularComp {
    public params: any;
    public modifyTopicType: boolean = false
    public bsModalRef: BsModalRef;
    
    public title: string;
    public dependencyType: string;
    public status: string = "";
    @ViewChild('docAttributeDeleteModal') docAttributeDeleteModal: TemplateRef<any>;

    constructor(private capabilityService: CapabilityService, private modalService: BsModalService, private documentTypesService: DocumentTypesService,
        private alertService: AlertService, private loadingService: LoadingService, private translate: TranslateService, private sharedService: SharedService) {
        this.modifyTopicType = this.capabilityService.isCapabilityAssigned("modifyTopicType");
        translate.addLangs(["es", "en"]);
        translate.setDefaultLang("en");
        let browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
    }

    agInit(params: any): void {
        this.params = params;
        this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.DELETE;
        this.translate.get('DOCUMENT_TYPE.DELETE_CONFLICTS').subscribe((res: string) => {
            this.title = res;
        });
    }

    public disabledDeleteBtn() {
        if (this.modifyTopicType) {
            return false
        } else {
            return true
        }
    }

    public delete() {
        //this.bsModalRef = this.modalService.show(this.docAttributeDeleteModal);
        this.dependenciesModalWithComponent(this.title, false, true, this.params.data.docTypeAttrId);
        
    }

    refresh(): boolean {
        return false;
    }

    deleteItemClick(){ //Not using
        console.log("delete confirm")
        this.documentTypesService.removeDocTypeAttribute(this.params.data.docTypeAttrId)
    }

    public dependenciesModalWithComponent(header: String, dflag: boolean, cflag: boolean, id: string) {
		const initialState : object = {
			headerTitle: header,
			delFlag: dflag,
			cancelFlag: cflag,
			checkboxVal: this.status == "inactive" ? true : false
		};
		this.sharedService.setFileId(id);
		this.sharedService.setFileType(Constants.DEPENDENCY_ITEM_TYPE.DOCUMENTTYPEATTRIBUTE);
		this.sharedService.setDependencyMessageType(this.dependencyType);
		this.sharedService.setParent(this);
		this.bsModalRef = this.modalService.show(DependenciesmodalComponent, { initialState, class: 'modal-lg' });
	}
}