import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CommonUtil } from 'src/app/util/common/common-util';
import { UserVariable } from 'src/app/util/common/user-variable';
import { ServiceUrls } from 'src/app/util/service-urls';
import { WsResponse } from 'src/app/util/ws-response.model';

@Injectable({
  providedIn: 'root'
})
export class DocumentStoreService {

  constructor(private http: HttpClient, private commonUtil: CommonUtil) {
  }
  @Output() updateDocumentStoreTable: EventEmitter<any> = new EventEmitter()
  /**
  * Call service method to getDocumentStoreByProjectId using GET request.
  * @param projectId project Id   
  */

  getDocumentStoreByProjectId(projectId: string): Observable<WsResponse> {
     let token = UserVariable.getUserToken();
       if(!this.commonUtil.validateToken(token)){
           return;
       }
     const options = { params: new HttpParams().set("token", token) }
     return this.http.get(ServiceUrls.getDocumentStoreByProjectId(projectId), options).
        pipe(
           map(response => {
              var modified = JSON.parse(JSON.stringify(response));
              return new WsResponse(
                 modified.status.description,
                 modified.status.code,
                 modified.status.name,
                 modified.payload);
           }),
           catchError(error => {
              var modified = JSON.parse(JSON.stringify(error.error));
              var res = new WsResponse(
                 modified.status.description,
                 modified.status.code,
                 modified.status.name,
                 modified.payload);
              return throwError(res)
           })
        );
  }

  /**
   * Call service method to updateDocumentStore using POST request.
   * @param documentId Document Id
   * @param storeAvailability Store Availability
   * @param documentStoreId Document Store Id
   */
  updateDocumentStore(documentId: string, storeAvailability: string, documentStoreId: string,
     facilityList:any, facilityUpdated:boolean): Observable<WsResponse> {
     let token = UserVariable.getUserToken();
       if(!this.commonUtil.validateToken(token)){
           return;
       }
     const headers = new Headers({ 'Content-Type': 'application/json' });
     let url = this.updateDocumentStoreURL(token, documentId);
     return this.http.put(url, JSON.stringify(new UpdateDocumentStoreRequest(documentId, storeAvailability, documentStoreId, facilityList, facilityUpdated))).
        pipe(
           map(response => {
              var modified = JSON.parse(JSON.stringify(response));
              return new WsResponse(
                 modified.status.description,
                 modified.status.code,
                 modified.status.name,
                 modified.payload);
           }),
           catchError(error => {
              var modified = JSON.parse(JSON.stringify(error.error));
              var res = new WsResponse(
                 modified.status.description,
                 modified.status.code,
                 modified.status.name,
                 modified.payload);
              return throwError(res)
           })
        );
  }

  /**
   * document store table refresh event emitter request
   * @param documentId 
   * @param documentStoreId 
   * @param event 
   */
  tableReloadRequest(documentId: string, documentStoreId: string, event: string){
     this.updateDocumentStoreTable.emit(
        {
           documentId: documentId,
           documentStoreId: documentStoreId,
           event: event
        }
     )
  }

  /**
   * document store table refresh event emitter respond
   */
  tableReloadRespond(){
     return this.updateDocumentStoreTable
  }

  /**
  * Call service method to deleteDocumentStore using GET request.    
  * @param documentStoreId Document Store Id
  */
  deleteDocumentStore(documentStoreId: string): Observable<WsResponse> {
     let token = UserVariable.getUserToken();
       if(!this.commonUtil.validateToken(token)){
           return;
       }
     const options = { params: new HttpParams().set("token", token) }
     return this.http.delete(ServiceUrls.deleteDocumentStoreByDocumentStoreId(documentStoreId), options).
        pipe(
           map(response => {
              var modified = JSON.parse(JSON.stringify(response));
              return new WsResponse(
                 modified.status.description,
                 modified.status.code,
                 modified.status.name,
                 modified.payload);
           }),
           catchError(error => {
              var modified = JSON.parse(JSON.stringify(error.error));
              var res = new WsResponse(
                 modified.status.description,
                 modified.status.code,
                 modified.status.name,
                 modified.payload);
              return throwError(res)
           })
        );
  }

  /**
  * Call service method to getAddDocumentStoreByProjectId using GET request.
  * @param projectId project Id   
  */
  getAddDocumentStoreViewData(projectId: string): Observable<WsResponse> {
     let token = UserVariable.getUserToken();
       if(!this.commonUtil.validateToken(token)){
           return;
       }
     const options = { params: new HttpParams().set("token", token) }
     return this.http.get(ServiceUrls.getAddDocumentStoreViewData(projectId), options).
        pipe(
           map(response => {
              var modified = JSON.parse(JSON.stringify(response));
              return new WsResponse(
                 modified.status.description,
                 modified.status.code,
                 modified.status.name,
                 modified.payload);
           }),
           catchError(error => {
              var modified = JSON.parse(JSON.stringify(error.error));
              var res = new WsResponse(
                 modified.status.description,
                 modified.status.code,
                 modified.status.name,
                 modified.payload);
              return throwError(res)
           })
        );
  }

  /**
   * Call service method to addDocumentToDocumentStore using POST request.
   * @param documentstoremodel Document Id
  
   */
  addDocumentToDocumentStore(documentStore: any): Observable<WsResponse> {
     let token = UserVariable.getUserToken();
       if(!this.commonUtil.validateToken(token)){
           return;
       }
     const headers = new Headers({ 'Content-Type': 'application/json' });
     documentStore['userToken'] = token
     return this.http.post(ServiceUrls.ADD_TO_DOCUMENT_STORE, JSON.stringify(documentStore)).
        pipe(
           map(response => {
              var modified = JSON.parse(JSON.stringify(response));
              return new WsResponse(
                 modified.status.description,
                 modified.status.code,
                 modified.status.name,
                 modified.payload);
           }),
           catchError(error => {
              var modified = JSON.parse(JSON.stringify(error.error));
              var res = new WsResponse(
                 modified.status.description,
                 modified.status.code,
                 modified.status.name,
                 modified.payload);
              return throwError(res)
           })
        );
  }

  /**
  * Call service method to  getDocumentForAddToStore using GET request.
  * @param projectId project Id   
  */
  getDocumentForAddToStore(documentId: string, outputId: string): Observable<WsResponse> {
     let token = UserVariable.getUserToken();
       if(!this.commonUtil.validateToken(token)){
           return;
       }
     const options = { params: new HttpParams().set("token", token) }
     return this.http.get(ServiceUrls.getDocumentForAddToStore(documentId, outputId), options).
        pipe(
           map(response => {
              var modified = JSON.parse(JSON.stringify(response));
              return new WsResponse(
                 modified.status.description,
                 modified.status.code,
                 modified.status.name,
                 modified.payload);
           }),
           catchError(error => {
              var modified = JSON.parse(JSON.stringify(error.error));
              var res = new WsResponse(
                 modified.status.description,
                 modified.status.code,
                 modified.status.name,
                 modified.payload);
              return throwError(res)
           })
        );
  }

  /**
 * Call service method to deleteDocumentZIPItem using GET request.    
 * @param outputconfigId outputconfig Id
 */
  deleteDocumentZIPItem(outputId: string): Observable<WsResponse> {
     let token = UserVariable.getUserToken();
       if(!this.commonUtil.validateToken(token)){
           return;
       }
     const options = { params: new HttpParams().set("token", token) }
     return this.http.delete(ServiceUrls.deleteDocumentZIPItem(outputId), options).
        pipe(
           map(response => {
              var modified = JSON.parse(JSON.stringify(response));
              return new WsResponse(
                 modified.status.description,
                 modified.status.code,
                 modified.status.name,
                 modified.payload);
           }),
           catchError(error => {
              var modified = JSON.parse(JSON.stringify(error.error));
              var res = new WsResponse(
                 modified.status.description,
                 modified.status.code,
                 modified.status.name,
                 modified.payload);
              return throwError(res)
           })
        );
  }

  /**
  * Call service method to  getDocumentStoreDeletedItems using GET request.    
  * @param deleItem deletedItem
  */
  getDocumentStoreDeletedItems(): Observable<WsResponse> {
     let token = UserVariable.getUserToken();
       if(!this.commonUtil.validateToken(token)){
           return;
       }
     const options = { params: new HttpParams().set("token", token) }
     return this.http.get(ServiceUrls.GET_DELETED_DOCUMENT_STORE, options).
        pipe(
           map(response => {
              var modified = JSON.parse(JSON.stringify(response));
              return new WsResponse(
                 modified.status.description,
                 modified.status.code,
                 modified.status.name,
                 modified.payload);
           }),
           catchError(error => {
              var modified = JSON.parse(JSON.stringify(error.error));
              var res = new WsResponse(
                 modified.status.description,
                 modified.status.code,
                 modified.status.name,
                 modified.payload);
              return throwError(res)
           })
        );
  }

  /**
  * Call service method to  getProcedureInstancesSearchResults using GET request.    
  * @param status selected status
  * @param document name
  */
   getProcedureInstancesSearchResults(selectedSystem: string): Observable<WsResponse> {
     let token = UserVariable.getUserToken();
       if(!this.commonUtil.validateToken(token)){
           return;
       }
     const options = { params: new HttpParams().set("token", token) }
     return this.http.get(ServiceUrls.getDocumentStoreProcedureInstanceSearchResults(selectedSystem), options).
        pipe(
           map(response => {
              var modified = JSON.parse(JSON.stringify(response));
              return new WsResponse(
                 modified.status.description,
                 modified.status.code,
                 modified.status.name,
                 modified.payload);
           }),
           catchError(error => {
              var modified = JSON.parse(JSON.stringify(error.error));
              var res = new WsResponse(
                 modified.status.description,
                 modified.status.code,
                 modified.status.name,
                 modified.payload);
              return throwError(res)
           })
        );
  }

  /**
 * Call service method to   getOutputErrorMessage using GET request.    
 * @param outputId  output id
 * @param itemType item type
 */
  getOutputErrorMessage(id: string, itemType: string): Observable<WsResponse> {
     let token = UserVariable.getUserToken();
       if(!this.commonUtil.validateToken(token)){
           return;
       }
     const options = { params: new HttpParams().set("token", token) }
     return this.http.get(ServiceUrls.getOutputErrorMessage(id, itemType), options).
        pipe(
           map(response => {
              var modified = JSON.parse(JSON.stringify(response));
              return new WsResponse(
                 modified.status.description,
                 modified.status.code,
                 modified.status.name,
                 modified.payload);
           }),
           catchError(error => {
              var modified = JSON.parse(JSON.stringify(error.error));
              var res = new WsResponse(
                 modified.status.description,
                 modified.status.code,
                 modified.status.name,
                 modified.payload);
              return throwError(res)
           })
        );
  }

  /**
 * Call service method to   getOutputErrorMessage using GET request.    
 * @param Id  output id
 * @param prcStatus process status value
 */
  regenarateOutput(id: string, prcStatus: string): Observable<WsResponse> {
     let token = UserVariable.getUserToken();
       if(!this.commonUtil.validateToken(token)){
           return;
       }
     const options = { params: new HttpParams().set("token", token) }
     return this.http.get(ServiceUrls.regenarateOutput(id, prcStatus), options).
        pipe(
           map(response => {
              var modified = JSON.parse(JSON.stringify(response));
              return new WsResponse(
                 modified.status.description,
                 modified.status.code,
                 modified.status.name,
                 modified.payload);
           }),
           catchError(error => {
              var modified = JSON.parse(JSON.stringify(error.error));
              var res = new WsResponse(
                 modified.status.description,
                 modified.status.code,
                 modified.status.name,
                 modified.payload);
              return throwError(res)
           })
        );
  }

  /**
  * Call service method to   getOutputErrorMessage using GET request.    
  * @param Id  output id
  * @param prcStatus process status value
  */
  terminateOutput(id: string, prcStatus: string): Observable<WsResponse> {
     let token = UserVariable.getUserToken();
       if(!this.commonUtil.validateToken(token)){
           return;
       }
     const options = { params: new HttpParams().set("token", token) }
     return this.http.get(ServiceUrls.terminateOutput(id, prcStatus), options).
        pipe(
           map(response => {
              var modified = JSON.parse(JSON.stringify(response));
              return new WsResponse(
                 modified.status.description,
                 modified.status.code,
                 modified.status.name,
                 modified.payload);
           }),
           catchError(error => {
              var modified = JSON.parse(JSON.stringify(error.error));
              var res = new WsResponse(
                 modified.status.description,
                 modified.status.code,
                 modified.status.name,
                 modified.payload);
              return throwError(res)
           })
        );
  }


  /**
  * Call service method to   getAllFacilityList using GET request.    
  * @param outputId  output id
  * @param itemType item type
  */
  getAllFacility(): Observable<WsResponse> {
     let token = UserVariable.getUserToken();
       if(!this.commonUtil.validateToken(token)){
           return;
       }
     const options = { params: new HttpParams().set("token", token) }
     return this.http.get(ServiceUrls.GET_ALL_FACILITY, options).
        pipe(
           map(response => {
              var modified = JSON.parse(JSON.stringify(response));
              return new WsResponse(
                 modified.status.description,
                 modified.status.code,
                 modified.status.name,
                 modified.payload);
           }),
           catchError(error => {
              var modified = JSON.parse(JSON.stringify(error.error));
              var res = new WsResponse(
                 modified.status.description,
                 modified.status.code,
                 modified.status.name,
                 modified.payload);
              return throwError(res)
           })
        );
  }


  /**
  * Call service method to getDocumentsByFacility using GET request.    
  * @param facilityId  facility id
  */
  getStoreDocumentsByFacility(selectedFacilityId: string): Observable<WsResponse> {
     let token = UserVariable.getUserToken();
       if(!this.commonUtil.validateToken(token)){
           return;
       }
     const options = { params: new HttpParams().set("token", token) }
     return this.http.get(ServiceUrls.getStoreDocumentsByFacility(selectedFacilityId), options).
        pipe(
           map(response => {
              var modified = JSON.parse(JSON.stringify(response));
              return new WsResponse(
                 modified.status.description,
                 modified.status.code,
                 modified.status.name,
                 modified.payload);
           }),
           catchError(error => {
              var modified = JSON.parse(JSON.stringify(error.error));
              var res = new WsResponse(
                 modified.status.description,
                 modified.status.code,
                 modified.status.name,
                 modified.payload);
              return throwError(res)
           })
        );
  }

  /**
  * Call service method to getDocumentStoreItemsByFacility   using GET request.    
  * @param facilityId  facility id
  */
  getDocumentStoreItemsByFacility(selectedFacilityId: string): Observable<WsResponse> {
     let token = UserVariable.getUserToken();
       if(!this.commonUtil.validateToken(token)){
           return;
       }
     const options = { params: new HttpParams().set("token", token) }
     return this.http.get(ServiceUrls.getDocumentStoreItemsByFacility(selectedFacilityId), options).
        pipe(
           map(response => {
              var modified = JSON.parse(JSON.stringify(response));
              return new WsResponse(
                 modified.status.description,
                 modified.status.code,
                 modified.status.name,
                 modified.payload);
           }),
           catchError(error => {
              var modified = JSON.parse(JSON.stringify(error.error));
              var res = new WsResponse(
                 modified.status.description,
                 modified.status.code,
                 modified.status.name,
                 modified.payload);
              return throwError(res)
           })
        );
  }

  /**
	 * Gets system list by facility id.
	 * @param token token of the user
	 * @param facilityId  id of the facility
	 * @param wsCallBack callback class
	 */
	public getSystemsByFacilityId(token: string, facilityId: string): Observable<WsResponse>  {
		return this.http.get(ServiceUrls.getSystemsByFacilityId(token, facilityId)).
      pipe(
         map(response => {
            var modified = JSON.parse(JSON.stringify(response));
            return new WsResponse(
               modified.status.description,
               modified.status.code,
               modified.status.name,
               modified.payload);
         }),
         catchError(error => {
            var modified = JSON.parse(JSON.stringify(error.error));
            var res = new WsResponse(
               modified.status.description,
               modified.status.code,
               modified.status.name,
               modified.payload);
            return throwError(res)
         })
      );
	}

  /**
	 * Get all systems for the project
	 * @param token user token
	 * @param wsCallBack  call back class
	 */
	getSystemsByProjectId(token: string): Observable<WsResponse> {
    return this.http.get(ServiceUrls.getSystemsByProjectId(token))
    .pipe(
      map(response => {
         var modified = JSON.parse(JSON.stringify(response));
         return new WsResponse(
            modified.status.description,
            modified.status.code,
            modified.status.name,
            modified.payload);
      }),
      catchError(error => {
         var modified = JSON.parse(JSON.stringify(error.error));
         var res = new WsResponse(
            modified.status.description,
            modified.status.code,
            modified.status.name,
            modified.payload);
         return throwError(res)
      })
		);
	}

  downloadFile(outputId: string) {
     return this.http.get(ServiceUrls.DOWNLOAD_FILE, {
        responseType: "blob", params: new HttpParams().
           set("documentId", outputId).set("mode", "ViewOutput")
     });
  }

  public updateDocumentStoreURL(token: String, id: any) {
     let url = ServiceUrls.UPDATE_DOCUMENT_STORE + '/' + id + '/updateDocumentStore.json?token=' + token;
     return url;
  }

}

class Request {
  constructor(private documentId: string, private availability: string, private documentStoreId: string) { }
}

class UpdateDocumentStoreRequest{
  constructor(private documentId: string, private availability: string, private documentStoreId: string,
    private facilityIdList: any, private facilityUpdated :boolean) { }
}