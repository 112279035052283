import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { UserVariable } from '../../util/common/user-variable';
import { ServiceUrls } from '../../util/service-urls';
import { WsResponse } from '../../util/ws-response.model';
import { WsCallback } from '../../util/ws-callback.interface';
import { WsType } from '../../util/ws-type';
import { CommonUtil } from '../common/common-util';

@Injectable()
export class CategoryTreeService {

	constructor(private http: HttpClient, private commonUtil: CommonUtil) {
	}

	/**
	 * Get document types. 
	 */
	getCategories(callBack: WsCallback) {
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
		const options = { params: new HttpParams().set("token", token) .set("categoryType", "Statement")}
		this.http.get(ServiceUrls.CATEGORY_VIEW, options).
			subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.code,
					modified.status.name,
					modified.status.description,
					modified.payload);
				callBack.onSuccess(res, WsType.CATEGORY_VIEW);
			},
			error => {
				var modified = JSON.parse(JSON.stringify(error));
				var res = new WsResponse(
					modified.status.code,
					modified.status.name,
					modified.status.description,
					modified.payload);
				callBack.onSuccess(res, WsType.CATEGORY_VIEW);
			});
	}


	getCategoriesComponent( callBack: WsCallback) {
		console.log('service of cat');
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
		const options = { params: new HttpParams().set("categoryType", "Component")}
		let url = this.getComponentsByCategoryURL(token);
		this.http.get(url, options).
			subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.code,
					modified.status.name,
					modified.status.description,
					modified.payload);
				callBack.onSuccess(res, WsType.CATEGORY_VIEW);
			},
			error => {
				var modified = JSON.parse(JSON.stringify(error));
				var res = new WsResponse(
					modified.status.code,
					modified.status.name,
					modified.status.description,
					modified.payload);
				callBack.onSuccess(res, WsType.CATEGORY_VIEW);
			});
	}

	getChecksCategories( callBack: WsCallback) {
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
		const options = { params: new HttpParams().set("categoryType", "Check")}
		let url = this.getComponentsByCategoryURL(token);
		this.http.get(url, options).
			subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.code,
					modified.status.name,
					modified.status.description,
					modified.payload);
				callBack.onSuccess(res, WsType.CATEGORY_VIEW);
			},
			error => {
				var modified = JSON.parse(JSON.stringify(error));
				var res = new WsResponse(
					modified.status.code,
					modified.status.name,
					modified.status.description,
					modified.payload);
				callBack.onSuccess(res, WsType.CATEGORY_VIEW);
			});
	}

	private getComponentsByCategoryURL(token: String) {
        let url = ServiceUrls.CATEGORY_VIEW + '?token=' + token;
        return url;
    }
}