import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '../../../util/alert/alert.service';
import { SharedService } from '../../../util/shared.service';
import { GridOptions, Module, AllModules } from "@ag-grid-enterprise/all-modules";
import { CapabilityService } from '../../../util/capability.service'
import { BsModalService } from 'ngx-bootstrap/modal';
import { OutputManagementService } from '../outputmanagement.service'
import { LoadingService } from '../../../util/loading/loading.service';
import { ProjectConfigRender } from '../cell-render/addprojecttemplaterender/projectconfigrender.component'
import { EditableDocConfigRender } from '../cell-render/addprojecttemplaterender/editabledocconfigrender.component'
import { VisibleDocConfigRender } from '../cell-render/addprojecttemplaterender/visibledocconfigrender.component'
import { AddDocConfigRender } from '../cell-render/addprojecttemplaterender/adddocconfigrender.component'
import { PTDeleteButtonRenderer } from './delete-button/delete-button.component'
import { WsResponse } from 'src/app/util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';
//import { OptionalDataCellEditor } from '../cell-render/addprojecttemplaterender/optionaldatacelleditor.component'

@Component({
   selector: 'app-outputaddprojecttemp',
   templateUrl: './outputaddprojecttemp.component.html',
   styleUrls: ['./outputaddprojecttemp.component.css']
})

export class OutputAddProjectTempComponent implements OnInit {
   public isMinimized: boolean = false
   rowData: any[] = [];
   private gridApi;
   private gridColumnApi;
   columnDefs;
   public frameworkComponents;
   public gridOptions: GridOptions;
   public defaultColDef: any;
   om_translated_data: any = null;
   loadingMessage: string;
   manageProjectTemplates: boolean = false;
   public divHeight: number = (window.innerHeight) * 5 / 7
   public getRowNodeId;
   public modules: Module[] = AllModules;

   constructor(private outputManagementService: OutputManagementService, private sharedService: SharedService, private translate: TranslateService, private alertService: AlertService,
      private capabilityService: CapabilityService, private modalService: BsModalService, private router: Router, private loadingService: LoadingService) {

      this.manageProjectTemplates = this.capabilityService.isCapabilityAssigned("manageProjectTemplates");
      let browserLang = translate.getBrowserLang();
      //this.gridOptions = <GridOptions>{
         //context: {
            ///componentParent: this
         //}
      //};
      this.defaultColDef = {
			resizable: true,
			sortable: true,
		};
      this.translate.get('OUTPUT_MANAGEMENT.LOADING_MESSAGE').subscribe((res: string) => {
         this.loadingMessage = res;
      });
      translate.getTranslation(browserLang).subscribe((res: string) => {
         this.om_translated_data = res['OUTPUT_MANAGEMENT']
         this.columnDefs = [
            {
               headerName: this.om_translated_data.TEMPLATE_NAME, field: 'templateName', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 150, suppressFiltersToolPanel: true,
               cellEditor: 'agLargeTextCellEditor', editable: this.manageProjectTemplates
            },
            {
               headerName: this.om_translated_data.FILENAME, field: 'fileName', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 150, suppressFiltersToolPanel: true,
            },
            {
               headerName: this.om_translated_data.OUTPUT_TYPE, field: 'outputType', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 50, suppressFiltersToolPanel: true,
            },
            {
               headerName: this.om_translated_data.OUTPUT_DOCUMENT, field: 'documentType', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 200, suppressFiltersToolPanel: true,
            },
            {
               headerName: this.om_translated_data.OPTIONAL_DATA, field: 'optionalData', cellClass: "cell-wrap-text", minWidth: 400, suppressFiltersToolPanel: true,
               cellStyle: {'white-space': 'normal'}, cellEditor: 'agLargeTextCellEditor', editable: this.manageProjectTemplates,
               cellEditorParams: {
                  maxLength: '5000',
                 },
              hide: true
            },
            {
               headerName: this.om_translated_data.ADD_DOCUMENT_CONFIGURATION, field: 'hasToImportDocConfigs', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 50, suppressFiltersToolPanel: true,
               cellRendererFramework: AddDocConfigRender
            },
            {
               headerName: this.om_translated_data.VISIBLE_DOCUMENT_CONFIGURATION, field: 'documentConfigList', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 150, suppressFiltersToolPanel: true,
               cellRendererFramework: VisibleDocConfigRender
            },
            {
               headerName: this.om_translated_data.EDITABLE_DOCUMENT_CONFIGURATION, field: 'editableDocumentConfigList', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 200, suppressFiltersToolPanel: true,
               cellRendererFramework: EditableDocConfigRender
            },
            {
               headerName: this.om_translated_data.PROJECT_CONFIGURATION, field: 'projectLogoInfoList', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 150, suppressFiltersToolPanel: true,
               cellRendererFramework: ProjectConfigRender
            },
            {
               headerName: 'Delete', maxWidth: 100, autoHeight: true, cellClass: "cell-wrap-text", editable: true, field: "delete",
               cellRendererFramework: PTDeleteButtonRenderer
            }
         ]
      });
      this.getRowNodeId = function (data) {         
         return data.projectTemplateMapId;
     };
   }

   ngOnInit() {
      this.getProjectTemplates();
      if (this.outputManagementService.getRowData() != null) {
         this.rowData = this.outputManagementService.getRowData();
     } else {
     }
     this.outputManagementService.setCallBack(this);   
   }

   onCellValueChanged(params) {
      let colId = params.column.getId()      
      let projectTemplateMapId = params.data.projectTemplateMapId
      let newValue = params.newValue
      switch (colId) {
			case 'optionalData':
				this.editOptionalData(projectTemplateMapId, newValue, params)
				break;
			case 'templateName':
				this.editTemplate(projectTemplateMapId, newValue, params)
				break;
		}     
   }

   onColumnResized(event) {
      if (event.finished) {
         this.gridApi.resetRowHeights();
      }
   }

   onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.outputManagementService.setApiGridData(this.gridApi);
      params.api.sizeColumnsToFit();
      setTimeout(function () {
         params.api.sizeColumnsToFit();
         params.api.resetRowHeights();
      }, 500);
   }

   public getProjectTemplates() {
      this.loadingService.showLoading(true, null, this.loadingMessage, null)
      this.outputManagementService.getProjectTemplates()
         .subscribe(data => {
            this.loadingService.hideLoading()
            this.rowData = data.payload;
            this.gridApi.sizeColumnsToFit();
         },
            error => {
               this.loadingService.hideLoading()
               this.alertService.clear()
               this.alertService.error(error.statusDescription)
            });

   }

   //public addTemplate() {
     //this.router.navigateByUrl('addtemplate')
  // }

   public editOptionalData(id, newValue, params){
      if (newValue != "") {
         this.alertService.clear()
         this.loadingService.showLoading(true, null, this.loadingMessage, null)
         var editedOpData = {
            "id": id,
            "optionalData": newValue
         }
         this.outputManagementService.editOptionalData(editedOpData)
            .subscribe(data => {
               //this.outputManagementService.setItemData(id, newValue, "opData")
               this.gridApi.sizeColumnsToFit();
               this.loadingService.hideLoading()
               //this.router.navigateByUrl('outputaddprojecttemp')
            },
               error => {
                  this.loadingService.hideLoading()
                  this.alertService.clear()
                  this.alertService.error(error.statusDescription)
               });
      }else{
         this.alertService.clear()
         this.alertService.error("Optional data can not be empty.")
      }
   }

   public editTemplate(projectTemplateMapId, newValue, params){
      if (newValue != "") {
         this.alertService.clear()
         this.loadingService.showLoading(true, null, this.loadingMessage, null)
         var editedTempName = {
            "id": projectTemplateMapId,
            "templateName": newValue
         }
         this.outputManagementService.editTemplateName(editedTempName)
            .subscribe(data => {
               this.outputManagementService.setItemData(projectTemplateMapId, newValue, "tempName")
               this.gridApi.sizeColumnsToFit();
               this.loadingService.hideLoading()
               //this.router.navigateByUrl('outputaddprojecttemp')
            },
               error => {
                  this.loadingService.hideLoading()
                  this.alertService.clear()
                  this.alertService.error(error.statusDescription)
               });
      }else{
         this.alertService.clear()
         this.alertService.error("Optional data can not be empty.")
      }
   }

   onSuccess(data: WsResponse, serviceType: WsType): void {
      if(serviceType == WsType.DELETE_ITEM){
         this.getProjectTemplates();
         this.alertService.clear()
         this.alertService.success("Project template successfully deleted");
     }
   }
   onFail(res: WsResponse, serviceType: WsType): void {
      if(serviceType == WsType.DELETE_ITEM){
         this.alertService.clear()
         this.alertService.error("Cannot delete project template");
      }
   }
}