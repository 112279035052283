import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams, IAfterGuiAttachedParams } from "@ag-grid-enterprise/all-modules";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SharedService } from 'src/app/util/shared.service';
import { DependencyModComponent } from '../dependency-mod/dependency-mod.component';
import { UserVariable } from 'src/app/util/common/user-variable';
import { OptDataService } from '../opt-data.service';
import { WsCallback } from 'src/app/util/ws-callback.interface';
import { AlertService } from 'src/app/util/alert/alert.service';
import { WsResponse } from 'src/app/util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';
import { CommonUtil } from 'src/app/util/common/common-util';
import { DependenciesmodalComponent } from 'src/app/util/dependenciesmodal/dependenciesmodal.component';

@Component({
  selector: 'app-dependency-cell',
  template: `
  <div class="col-sm">
  <button type="button" [disabled]="disable" class="btn btn-sm btn-outline-dark py-0 my-1 px-1 border-0 " (click)="showDependency()">
    <i class="far fa-link" aria-hidden="true"></i>
  </button>
</div>
`,
  styleUrls: []
})
export class DependencyCellComponent implements OnInit, ICellRendererAngularComp {

  public cellData: any;
  public bsModalRef: BsModalRef;
  public disable: boolean;

  refresh(params: any): boolean {
    return true;
  }
  agInit(params: ICellRendererParams): void {
    this.cellData = params.data;
    if(params.value == 'disable: false'){
        this.disable = false;
    }
    else{
        this.disable = true;
    }
  }
  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {

  }

  constructor(private bsModalService: BsModalService, private sharedService: SharedService,
    private modalService: BsModalService, private optService: OptDataService, private alertService: AlertService, private commonUtil: CommonUtil) { }

  ngOnInit() {
  }

  /**
   * Enable/disable dependency button.
   */
  public disableDependencyButton() {
    if (this.cellData.count > 0) {
      return false
    } else {
      return true
    }
  }

  /**
   * Request dependencies.
   */
  showDependency() {
		this.dependenciesModalWithComponent('Dependencies List', false, false);
  }


  dependenciesModalWithComponent(header: String, dflag: boolean, cflag: boolean) {
    const initialState = {
      headerTitle: header,
      delFlag: dflag,
      cancelFlag: cflag,
      checkboxVal: false
    };
    // console.log("this.data " + this.data)
    this.sharedService.setFileId(this.cellData.id);
    this.sharedService.setFileType("OperatingData");
          this.sharedService.setAction("getDependencies");
    this.sharedService.setDependencyMessageType("OperatingData");
    this.sharedService.setParent(this);
    this.bsModalRef = this.modalService.show(DependenciesmodalComponent, { initialState, class: 'modal-lg' });
    console.log("dflag= " + dflag);
    console.log("cflag= " + cflag);
  }
}
