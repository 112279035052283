import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ServiceUrls } from '../../../util/service-urls';
import { WsCallback } from '../../../util/ws-callback.interface';
import { WsResponse } from '../../../util/ws-response.model';
import { WsType } from '../../../util/ws-type';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NewProcedureService {

  constructor(private http:HttpClient) { }

  public getSystemList(getSystemListRequest: string, callBack: WsCallback) {
    this.http.post(ServiceUrls.GET_SYSTEM_LIST, getSystemListRequest).subscribe(
        data => {
            var modified = JSON.parse(JSON.stringify(data));
            var res = new WsResponse(
                modified.status.description,
                modified.status.code,
                modified.status.name,
                modified.payload);
            callBack.onSuccess(res, WsType.GET_SYSTEM_LIST);
        },
        error => {
            if (error.status != '') {
                var val = (error as HttpErrorResponse).error;
                var modified = JSON.parse(JSON.stringify(val));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onFail(res, WsType.GET_SYSTEM_LIST);
            } else {
                //browser related issues
                var res = new WsResponse("Unknown error happened");
                callBack.onFail(res, WsType.GET_SYSTEM_LIST);
            }
        }
    );
}

public getOutputConfigList(outputConfigListRequest: string, callBack: WsCallback) {
  this.http.post(ServiceUrls.GET_OUTPUT_CONFIG_LIST, outputConfigListRequest).subscribe(
      data => {
          var modified = JSON.parse(JSON.stringify(data));
          var res = new WsResponse(
              modified.status.description,
              modified.status.code,
              modified.status.name,
              modified.payload);
          callBack.onSuccess(res, WsType.GET_OUTPUT_CONFIG_LIST);
      },
      error => {
          if (error.status != '') {
              var val = (error as HttpErrorResponse).error;
              var modified = JSON.parse(JSON.stringify(val));
              var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
              callBack.onFail(res, WsType.GET_OUTPUT_CONFIG_LIST);
          } else {
              //browser related issues
              var res = new WsResponse("Unknown error happened");
              callBack.onFail(res, WsType.GET_OUTPUT_CONFIG_LIST);
          }
      }
  );
}

public addProcedure(addProcedureRequest: string, callBack: WsCallback) {
    this.http.post(ServiceUrls.ADD_PROCEDURE, addProcedureRequest).subscribe(
        data => {
            var modified = JSON.parse(JSON.stringify(data));
            var res = new WsResponse(
                modified.status.description,
                modified.status.code,
                modified.status.name,
                modified.payload);
            callBack.onSuccess(res, WsType.ADD_PROCEDURE);
        },
        error => {
            if (error.status != '') {
                var val = (error as HttpErrorResponse).error;
                var modified = JSON.parse(JSON.stringify(val));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onFail(res, WsType.ADD_PROCEDURE);
            } else {
                //browser related issues
                var res = new WsResponse("Unknown error happened");
                callBack.onFail(res, WsType.ADD_PROCEDURE);
            }
        }
    );
  }
}
