import { Component, OnInit, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { DocumentTypeService } from './documenttype.service'
import { WsResponse } from '../../util/ws-response.model';
import { WsCallback } from '../../util/ws-callback.interface';
import { WsType } from '../../util/ws-type';
import { AlertService } from '../../util/alert/alert.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FilesService } from '../../home/files/files.service';
import { File } from '../../common-components/items/file.model';
import { FileAttribute } from '../../common-components/items/fileattribute.model';
import { TreeListener } from './tree-listener.interface';
declare var $: any;

@Component({
	selector: 'app-documenttypetree',
	templateUrl: './documenttypetree.component.html',
	styleUrls: ['./documenttypetree.component.css']
})
export class DocumenttypetreeComponent implements OnInit {

	@ViewChildren('texts', { read: ElementRef }) texts: QueryList<ElementRef>;
	@ViewChildren('options', { read: ElementRef }) options: QueryList<ElementRef>;
	public title: string = '';
	public doucmentTypeGrid: any;
	public selectedDocumentTypeId: String = null;
	public documentTypeSelected: boolean = true;
	public initial: boolean = true;
	public data_n: any = {
		'data': [
		]
	}
	public documentTypeAttributes: any = [];
	public treeRef: any = null;
	public file: File = null;
	public treeListener: TreeListener;
	private selectedItem: any = null;

	public divHeight: number = (window.innerHeight) / 2;

	constructor(public bsModalRef: BsModalRef, private filesService: FilesService, private documentTypeService: DocumentTypeService, private alertService: AlertService) {
	}

	ngOnInit() {
		console.log('initialize the document type tree');
		this.documentTypeService.getDocumentTypes(this);
	}

	/*save() {
		let selectedFileData = this.filesService.getSelectedFileData();
		if (selectedFileData != null && selectedFileData != undefined) {
			this.file = new File(selectedFileData.id, selectedFileData.name, this.selectedDocumentTypeId)
			if (selectedFileData.type == 'Procedure') {
				this.file.systemId = selectedFileData.systemId;
				this.file.outputConfigId = selectedFileData.outputConfigId;
			}
			let attributes = [];
			if (this.selectedDocumentTypeId != selectedFileData.documentTypeId) //insert
			{
				if (this.texts != null) {
					for (var i = 0; i < this.texts.toArray().length; i++) {
						let invalidText = (this.texts.toArray()[i].nativeElement.value == null || this.texts.toArray()[i].nativeElement.value == undefined || this.texts.toArray()[i].nativeElement.value == '');
						if (!invalidText) {
							let fileAttribute = new FileAttribute(this.texts.toArray()[i].nativeElement.id, this.texts.toArray()[i].nativeElement.value);
							attributes.push(fileAttribute);
						}
					}
				}
				if (this.options != null) {
					for (var i = 0; i < this.options.toArray().length; i++) {
						let invalidText = (this.options.toArray()[i].nativeElement.value == null || this.options.toArray()[i].nativeElement.value == undefined || this.options.toArray()[i].nativeElement.value == '');
						if (!invalidText) {
							let fileAttribute = new FileAttribute(this.options.toArray()[i].nativeElement.id, this.options.toArray()[i].nativeElement.value.split(':')[1].trim());
							attributes.push(fileAttribute);
						}
					}
				}
			}
			else  //update
			{
				if (this.texts != null) {
					for (var i = 0; i < this.texts.toArray().length; i++) {
						let text = (this.texts.toArray()[i].nativeElement.value == null || this.texts.toArray()[i].nativeElement.value == undefined || this.texts.toArray()[i].nativeElement.value == '') ? "" : this.texts.toArray()[i].nativeElement.value;
						let fileAttribute = new FileAttribute(this.texts.toArray()[i].nativeElement.id, text);
						attributes.push(fileAttribute);
					}
				}
				if (this.options != null) {
					for (var i = 0; i < this.options.toArray().length; i++) {
						let fileAttribute = new FileAttribute(this.options.toArray()[i].nativeElement.id, this.options.toArray()[i].nativeElement.value.split(':')[1].trim());
						attributes.push(fileAttribute);
					}
				}

				if (attributes.length == 0) {
					this.alertService.warn("Nothing to update")
					return;
				}
			}

			this.file.attributes = attributes;
			//console.log("JSON.stringify(this.file) " + JSON.stringify(this.file))
			this.filesService.updateFile(JSON.stringify(this.file), selectedFileData.id, selectedFileData.type, this);
		}
	}*/

	change(value: any, required: string, attribute: any, id: any) {
		attribute.value = value;
		let item = this.documentTypeAttributes.filter(item => item.attributeId == id)[0];
		if (item.initialvalue != item.value && (value != '' || required == "no")) {
			this.initial = false;
			for (var i = 0; i < this.documentTypeAttributes.length; i++) {
				if (this.documentTypeAttributes[i].isText) {
					if (this.documentTypeAttributes[i].required == 'yes' && (this.documentTypeAttributes[i].value == null || this.documentTypeAttributes[i].value == '')) {
						this.initial = true;
						return;
					}
				}
			}
		}
		else {
			this.initial = true;
		}
	}

	select(id: any) {
		let item = this.documentTypeAttributes.filter(item => item.attributeId == id)[0];
		if (item.initialvalue != item.value) {
			this.initial = false;
			for (var i = 0; i < this.documentTypeAttributes.length; i++) {
				if (this.documentTypeAttributes[i].isText) {
					if (this.documentTypeAttributes[i].required == 'yes' && (this.documentTypeAttributes[i].value == null || this.documentTypeAttributes[i].value == '')) {
						this.initial = true;
						return;
					}
				}
			}
		}
		else {
			this.initial = true;
		}
	}

	loadDocumentTypeTree(payload: any) {
		if (payload != null) {
			let selectedFileData = this.filesService.getSelectedFileData();
			let selectedTypeCount = 0;
			let deselect_node = 0;
			let enabled;
			let status = '';
			let icon = '';
			this.data_n['data'] = [
			];
			this.data_n["data"].push({ "id": "0", "parent": "#", "text": "Document Types", icon: "fa fa-clipboard", type: "documentType", state: {
            disabled: true}})
			for (let i = 0; i < payload.length; i++) {
				if (selectedFileData != null && selectedFileData != undefined && selectedFileData.documentTypeId == payload[i]["typeId"]) {
					selectedTypeCount = i + 1;
				}
				else if (localStorage.getItem("DocumentType") != null && localStorage.getItem("DocumentType") != undefined && localStorage.getItem("DocumentType") == payload[i]["typeId"]) {
					selectedTypeCount = i + 1;
				}
				if (localStorage.getItem("selectedDocType") != null && localStorage.getItem("selectedDocType") != undefined && localStorage.getItem("selectedDocType") == payload[i]["typeId"]) {
					deselect_node = i + 1;
				}
				status = payload[i]["status"].toUpperCase();
				if( status == "INACTIVE"){
					enabled =  { "disabled" : true };
					icon = "fa fa-ban";
				} else {
					enabled = { "disabled" : false };
					icon = "fa fa-clipboard";
				}
				this.data_n["data"].push({ "id": payload[i]["typeId"], "parent": payload[i]["parentId"], "text": payload[i]["topicType"], icon: icon, type: "documentType", state : enabled})
			}
			//console.log("this.data_n " + JSON.stringify(this.data_n["data"]))
			$('#documenttypetree').jstree({
				core: {
					themes: {
						variant: 'large'
					},
					data: this.data_n["data"],
					check_callback: true,
					force_text: true
				},
				types: {
					default: {
						// "icon" : "fa fa-file-o "
					}
				},
				plugins: ["contextmenu", "dnd", "search", "state", "types", "wholerow"],
				search: {
					show_only_matches: true,
					show_only_matches_children: true
				}
			});

			if (selectedTypeCount > 0) {
				localStorage.setItem("selectedDocType", this.data_n["data"][selectedTypeCount]["typeId"])
				setTimeout(() => $("#documenttypetree").jstree("select_node", this.data_n["data"][selectedTypeCount]["id"]), 200);
			}

			if (deselect_node > 0 && deselect_node != selectedTypeCount) {
				setTimeout(() => $("#documenttypetree").jstree("deselect_node", this.data_n["data"][deselect_node]["id"]), 200);
			}

			$('#documenttypetree').bind("select_node.jstree", (evt, data) => this.documentTypeSelectEvent(evt, data))
			$('#documenttypetree').bind("loaded.jstree",(event: any, data: any) => this.jsTreeLoaded(event, data))
			$('#documenttypetree').bind("ready.jstree", (event: any, data: any) => this.jsTreeReady())
		}
	}


	private jsTreeLoaded(event: any, data: any) {
		$('#documenttypetree').jstree("open_all");
		let jsT = $('#documenttypetree').jstree(true).get_json('#', {flat:true})
		if(this.filesService.getSelectedFileData() != null) {
			let node = jsT.find((i: { id: any; }) => i.id == this.filesService.getSelectedFileData().documentTypeId)
			$('#documenttypetree').jstree(true).select_node([node], true, true)
		} else {
			//do nothing
		}
	}

	isInitial() {
		return this.initial;
	}

	private jsTreeReady() {
		$('#documenttypetree').jstree(true).deselect_all(true);
		console.log('[jsTreeReady]');	
	}


	processDocuemntTypeAttributs(payload: any) {
		if (payload != null) {
			this.documentTypeAttributes = [];
			for (let i = 0; i < payload.length; i++) {
				this.documentTypeAttributes.push({
					'attributeId': payload[i]["attributeId"],
					'attributeName': payload[i]["attributeName"],
					'attributeType': payload[i]["attributeType"],
					'docAttributeValueId': payload[i]["docAttrValueId"],
					'required': payload[i]["required"],
					'value': payload[i]["value"] != null ? payload[i]["value"] : "",
					'initialvalue': payload[i]["value"] != null ? payload[i]["value"] : "",
					'categoryId': payload[i]["categoryId"],
					'items': payload[i]["attributeType"] == 'Component' ? payload[i]["components"] : payload[i]["attributeType"] == 'System' ? payload[i]["systems"] :
						payload[i]["attributeType"] == 'Document' ? payload[i]["documents"] : null,
					'isText': (payload[i]["attributeType"] == 'Number' || payload[i]["attributeType"] == 'Text') ? true : false,
					'systems': payload[i]["systems"],
					'docuents': payload[i]["documents"],
					'components': payload[i]["components"]
				});
			}
		}
	}

	/**
	 * Callback method for select a node in the tree.
	 */
	public documentTypeSelectEvent(evt: any, data: any) {
		console.log('[select_node.jstree] (data.node) ' + JSON.stringify(data.node));
		this.selectedItem = data;
		this.documentTypeAttributes = [];
		let selectedFileData = this.filesService.getSelectedFileData();
		if (selectedFileData != null && selectedFileData != undefined && selectedFileData.id != null) {
			localStorage.setItem("selectedDocType", data.node.id)
			this.documentTypeService.getDocumentTypeAttributes(data.node.id, selectedFileData, this);
			this.selectedDocumentTypeId = data.node.id;
			this.filesService.setSelectedDocumentType(data.node.text);
		}
	}

	onSuccess(data: WsResponse, serviceType: WsType): void {
		if (data != null && WsType.DOCUMENT_TYPES_VIEW == serviceType) {
			this.loadDocumentTypeTree(data.payload);
		}
		else if (data != null && WsType.DOCUMENT_TYPE_ATTRIBUTES_VIEW == serviceType) {
			let selectedFileData = this.filesService.getSelectedFileData();
			this.initial = false;
			if (selectedFileData.documentTypeId == localStorage.getItem("selectedDocType")) {
				this.initial = true;
			}
			else if (data.payload != "") {
				for (let i = 0; i < data.payload.length; i++) {
					if (data.payload[i].required == 'yes') {
						this.initial = true;
						break;
					}
				}
			}
			else {

			}
			this.documentTypeSelected = true;
			this.processDocuemntTypeAttributs(data.payload)
		}
	}

	onFail(res: WsResponse, serviceType: WsType): void {
		if (res != null && WsType.DOCUMENT_TYPES_VIEW == serviceType) {
			if (res.statusCode == "600") {
				this.documentTypeService.getDocumentTypes(this);
			} else {
				this.alertService.error(res.statusDescription, false);
			}
		}
		if (res != null && WsType.DOCUMENT_TYPE_ATTRIBUTES_VIEW == serviceType) {
			this.documentTypeSelected = false;
			if (res.statusCode == "600") {
				let selectedFileData = this.filesService.getSelectedFileData();
				this.documentTypeService.getDocumentTypeAttributes(selectedFileData, this.selectedDocumentTypeId, this);
			} else {
				this.alertService.error(res.statusDescription, false);
			}
		}
		if (res != null && WsType.FILE_UPDATE == serviceType) {
			if (res.statusCode == "532") {
				//this.router.navigateByUrl('login');
			}
			else if (res.statusCode == "600") {
				let selectedFileData = this.filesService.getSelectedFileData();
				this.filesService.updateFile(JSON.stringify(this.file), selectedFileData.id, selectedFileData.type, this);
			} else {
				this.alertService.success(res.statusDescription, false);
			}
		}
	}

	public setTreeListener(treeListener: TreeListener) {
		this.treeListener = treeListener;
	}
	public clickOnCancelButton() {
		this.bsModalRef.hide();
	}

	public clickOnSaveButton() {
		this.bsModalRef.hide();
		this.treeListener.onSaveButtonClick(this.selectedItem, this.texts, this.options, this.documentTypeAttributes);
	}

}
