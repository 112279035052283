export class Stack<T> {
	_store: T[] = [];

	push(val: T) {
		this._store.push(val);
	}

	pop(): T | undefined {
		return this._store.pop();
	}

	isEmpty() {
		return this._store.length === 0;
	}

	delete(val: T) {
		this._store = this._store.filter(item => item === val)
	}

}
