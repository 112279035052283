<alert class="floating-alert"></alert>
<nav id="navPanel" class="bg-light border-bottom box-shadow fixed-top navbar-expand-lg">
	<app-header [minimalOption]="true"></app-header>
</nav>
<div class="main-panel pb-2" role="main">
	<div class="container-fluid mt-3">
		<h4 class="ml-4 mr-2 mt-3">{{ 'RECENT_PROJECTS.RECENT_PROJECTS' | translate }}</h4>
		<div class="row ml-2 mr-2 mt-3">
			<!--Start of the view-->
			<div class="col-sm-3" *ngFor="let item of recentProjectList; let i=index">
				<div class="panel" (dblclick)="changeDefaultProjectStatus(item.projectId, item.isDefaultProject, item.projectType)" 
									[ngClass]="{'panel-success': item.isDefaultProject === 1, 'panel-default': item.isDefaultProject !== 1}"
									(click)="onItemSelected(item)">
					<div class="panel-heading pl-3 pb-1">
						<div class="row">
							<div class="col-sm-10">
								<p class="h6 mb-0 wrap-text" [tooltip]="item.projectName" placement="top" container="body">
									<span class="badge badge-warning mr-2">{{getProjectTypeName(item.projectType)}}</span>
									{{item.projectName}}</p>
							</div>
							<div class="col-sm-2">
								<button type="button" class="close close-custom float-right" aria-label="Close" (click)="$event.stopPropagation(); onRecentProjectCloseButtonClick(item.projectId, item.projectType); "> 
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
						</div>
					</div>
					<div class="card-body py-1 pl-3">
						<label class="text-muted"> {{item.clientName}}
						</label>
					</div>
				</div>
			</div>
			<!-- End of the view -->
		</div>
	</div>
	<div class="container-fluid mt-3">
		<div class="col-sm-12">
			<div class="panel panel-default">
				<div class="panel-heading pl-3">
					<div class="row">
						<div class="col-sm-9">
							<h4>
								<b>{{ 'LANDING.ALL_PROJECTS' | translate }}</b>
							</h4>
						</div>
						<div class="col-sm-3">
							<button id="landing_collapsebutton" type="button" class="btn btn-outline-dark btn-sm float-right" (click)="isCollapse = !isCollapse">
								<i class="fa " [ngClass]="{'fa-chevron-down': !isCollapse, 'fa-chevron-up': isCollapse}"></i>
							</button>
						</div>
					</div>
				</div>
				<div class="px-2 pt-0 pb-2" *ngIf="isCollapse">
					<div class="card-body px-1">
						<ag-grid-angular #agGrid style="width: 100%; height: 408px;" class="ag-theme-balham" [enableFilter]="true" [rowData]="rowData"  [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }" [gridOptions]="gridOptions" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }"
						 [columnDefs]="columnDefs" [components]="components" rowSelection="multiple" (rowSelected)="onRowSelected($event)"  [rowMultiSelectWithClick]="false"
						 (cell-clicked)="onCellClicked($event)" [getRowNodeId]="getRowNodeId" (gridReady)="onGridReady($event)"
						 (lastChildChanged)="onlastChildChanged($event)" [pagination]="true" [paginationPageSize]="12" [frameworkComponents]="frameworkComponents"
						 [suppressRowClickSelection]="true" (cellClicked)='onCellClicked($event)'>
						</ag-grid-angular>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>