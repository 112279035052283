import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ProcedureStoreService } from './procedurestore.service';
import { UserVariable } from '../../util/common/user-variable';
import { AlertService } from '../../util/alert/alert.service';
import { ProcedureStoreModel } from '../procedurestore/addprocedurestore/procedurestore.model'
import { Router } from '@angular/router';
import { LoadingService } from '../../util/loading/loading.service';

@Component({
   selector: 'addToStore-modal',
   template: `
   <div class="modal-header">
   <h4 class="modal-title pull-left">{{title}}</h4>
   <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
   </button>
</div>
<div class="modal-body">
   <form>
      <div class="form-group row">
         <label for="procedure_Name" class="col-sm-2 col-form-label">{{ 'PROCEDURE_STORE.PROCEDURE' | translate }}</label>         
            <div class="col-sm-10">
               <input type="text" class="form-control" id="procedureName"
               [(ngModel)]="name" name="procedure" required disabled ="true">
            </div>
      </div>
      <div class="form-group row">
         <label for="system_Name" class="col-sm-2 col-form-label">{{ 'PROCEDURE_STORE.SYSTEM' | translate }}</label>         
            <div class="col-sm-10">
               <input type="text" class="form-control" id="systemName"
               [(ngModel)]="systemName" name="systemName" required disabled ="true">
            </div>
      </div>
      <div class="form-group row">
         <label for="version" class="col-sm-2 col-form-label">{{ 'PROCEDURE_STORE.VERSION' | translate }}</label>         
            <div class="col-sm-10">
               <input type="text" class="form-control" id="version"
               [(ngModel)]="storeVersion" name="version" maxlength="200">
            </div>
      </div>
      <div class="form-group row">
         <label for="notes" class="col-sm-2 col-form-label">{{ 'PROCEDURE_STORE.NOTES' | translate }}</label>         
            <div class="col-sm-10">
               <textarea type="text" class="form-control" id="notes" name="notes" [(ngModel)]="notes" maxlength="200"></textarea>
            </div>
      </div>
      <div class="form-group row">
         <label for="key_word" class="col-sm-2 col-form-label">{{ 'PROCEDURE_STORE.KEY_WORDS' | translate }}</label>         
            <div class="col-sm-10">
               <textarea type="text" class="form-control" id="keyWord" name="keyWord" [(ngModel)]="keyWord" maxlength="400"></textarea>
            </div>
      </div>
      <div class="form-group row">
         <label for="location" class="col-sm-2 col-form-label">{{ 'PROCEDURE_STORE.LOCATION' | translate }}</label>         
         <div class="col-sm-10">
            <textarea type="text" class="form-control" id="location"
            [(ngModel)]="folderPath" name="location" required disabled ="true"></textarea>
         </div>
      </div>
      <div class="form-group row">
         <label for="availability" class="col-sm-2 col-form-label">{{ 'PROCEDURE_STORE.AVAILABILITY' | translate }}</label>         
         <div class="col-sm-10">
            <div class="form-check">
               <input type="checkbox" class="form-check-input" id="availability" name="availability" [(ngModel)]="availability">
            </div>
         </div>
      </div>
      <div class="form-group row">
         <label for="inReview" class="col-sm-2 col-form-label">{{ 'PROCEDURE_STORE.IN_REVIEW' | translate }}</label>         
         <div class="col-sm-10">
            <div class="form-check">
               <input type="checkbox"  class="form-check-input" id="inReview" name="inReview" [(ngModel)]="inReview" disabled>
            </div>
         </div>
      </div>
   </form>
</div>
<div class="modal-footer">  
   <button type="button" class="btn btn-primary" (click)="save(); bsModalRef.hide()">{{ 'Save' | translate}}</button>
   <button type="button" class="btn btn-secondary" (click)="bsModalRef.hide()">{{ 'CANCEL' | translate}}</button>
</div>
   `
})
export class AddProceduresModalComponent {
   public title: string = 'Add Procedures';
   public procedureStoreId: string
   private projectId: string = UserVariable.projectId
   public name: string
   public systemName: string
   public storeVersion: string
   public folderPath: string
   public inReview: boolean
   public keyWord: string
   public notes: string
   public availability: boolean = false
   public procedureId: string
   public systemId: string
   public outputId: string
   public inReviewReturn: string
   public availabilityReturn: string
   public description: string
  
   constructor(public bsModalRef: BsModalRef, private procedureStoreService: ProcedureStoreService, private alertService: AlertService, private router: Router,
      private loadingService: LoadingService) { }

   ngOnInit() {

   }

   save() {
      this.loadingService.showLoading(true, null, "", null)
      if (this.notes == undefined){
         this.notes = ''
      }else{
      }
      if (this.keyWord == undefined){
         this.keyWord = ''
      }else{
      }

      if (this.inReview != null){
         if(this.inReview == true){
            this.inReviewReturn = '1'
         }else{
            this.inReviewReturn = '0'
         }         
      }else{
         
      }

      if (this.availability == true){
         this.availabilityReturn = '1'
      }else{
         this.availabilityReturn = '0'
      }

      var procedurModel:  ProcedureStoreModel = new  ProcedureStoreModel(this.procedureId, this.systemId, this.storeVersion, this.folderPath, this.notes,
         this.keyWord, this.outputId, this.availabilityReturn, this.inReviewReturn, this.description)

      this.procedureStoreService.addProcedureToProcedureStore(procedurModel)
      .subscribe(data => {
         this.loadingService.hideLoading()
         this.alertService.success(data.statusDescription)
         location.reload();
      },
      error => {
         this.loadingService.hideLoading()
         this.alertService.error(error.statusDescription)
      });
      
   }

   deleteProcedureStoreItemClick(procedureStoreId) {
      this.loadingService.showLoading(true, null, "", null)
      this.procedureStoreService.deleteProcedureStore(procedureStoreId)
      .subscribe(data => {
         this.loadingService.hideLoading()
         this.alertService.success(data.statusDescription)
         location.reload();
      },
      error => {
         this.loadingService.hideLoading()
         this.alertService.error(error.statusDescription)
      });
   }
}