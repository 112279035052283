import { Component, OnInit, ViewChild, ElementRef, Input, ViewContainerRef, AfterContentInit, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { ComponentRef, ComponentFactoryResolver } from '@angular/core';
import { OMLoaderService } from './output-loader.service';

@Component({
    selector: 'app-output-management',
    templateUrl: './outputmanagement.component.html',
    styleUrls: ['./outputmanagement.component.scss']
})
export class OutputManagementComponent implements OnInit, AfterContentInit, OnChanges, OnDestroy {

    public paddingtop: number = 60;
    @ViewChild('navPanel', {static: true}) navPanel: ElementRef

    component: any;
    @ViewChild('omHost', { read: ViewContainerRef, static: true }) componentHost: ViewContainerRef
    componentRef: ComponentRef<Component>
    
    public isSideBar: boolean = true

    constructor(private resolver: ComponentFactoryResolver, private omlService: OMLoaderService) {
        this.component = this.omlService.loadComponentByName('opmanagement')
     }

    ngOnInit() { 
        console.log(this.navPanel.nativeElement.offsetHeight);
		if (this.navPanel.nativeElement.offsetHeight > 55) {
			this.paddingtop = 78
		} else {
			this.paddingtop = 50
		}
    }

    public changeSideBarItem(event: any) {
        console.log('[changeSideBarItem] (event) ' + JSON.stringify(event));
        this.component = this.omlService.loadComponentByName(event.selectedItem)
        this.updateComponent()
    }
    
    ngAfterContentInit(): void {
        //Called after ngOnInit when the component's or directive's content has been initialized.
        //Add 'implements AfterContentInit' to the class.
        this.updateComponent()
    }

    ngOnChanges(changes: SimpleChanges): void {
        //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
        //Add '${implements OnChanges}' to the class.
    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        if (this.componentRef) {
			this.componentRef.destroy()
		}
    }

    updateComponent() {
        this.componentHost.clear()
		const authFormFactory = this.resolver.resolveComponentFactory(this.component)
		const componentRef = this.componentHost.createComponent(authFormFactory);
    }

}