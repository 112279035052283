import { Injectable } from '@angular/core';

import { SearchDropdown } from './search-form/search-dropdown.modal';
import { SearchParams } from './search-form/search-params.modal';
import { SearchTextBox } from './search-form/search-textfield.modal';
import { SearchCategory } from './search-form/search-category.modal';
import { SearchDocType } from './search-form/search-doc-type.modal';
import { SharedService } from '../shared.service';
import { SearchDatePicker } from './search-form/search-datepicker.modal';
import { Constants } from '../constants';
import { SearchWildCards } from './search-form/search-wildcards.modal';
import { SearchTagInput } from "./search-form/search-tag-input.modal";


@Injectable()
export class SymbiosisSearchService {

    // TODO: get from a remote source of question metadata
    // TODO: make asynchronous

    /**
     * generate search options by component name
     * @param compName 
     */
    generateSearchOptionByName(compName: string) {
        // console.log('[generateSearchOptionByName] ' + JSON.stringify(compName));
        let sp: SearchParams<any>[]
        switch (compName) {
            case 'tag':
                sp = this.getTagSearchOptions()
                break;
            case 'component':
                sp = this.getComponentSearchOptions()
                break;
            case 'document':
                sp = this.getDocumentSearchOptions()
                break;
            case 'system':
                sp = this.getSystemSearchOptions()
                break;
            case 'facility':
                sp = this.getFacilitySearchOptions()
                break;
            case 'image':
                sp = this.getImageSearchOption()
                break;
            case 'threeDModel':
                sp = this.getThreeDModelSearchOption()
                break;
            case 'video':
                sp = this.getVideoSearchOption()
                break;
            case 'statement':
                sp = this.getStatementSearchOptions()
                break;
            case 'header':
                sp = this.getHeaderSearchOptions()
                break;
            case 'punchlist':
                sp = this.getPunchlistSearchOptions()
                break;
            case 'irn':
                sp = this.getIrnSearchOptions()
                break;
            case 'managemcc':
                sp = this.getMCCSearchOptions();
                break;
            case 'manageotn':
                sp = this.getOTNSearchOptions();
                break;
            case 'searchcs':
                sp = this.getCSSearchOptions();
                break;
            case 'searchchk':
                sp = this.getCSChecksSearchOptions();
                break;
            case 'searchasset':
                sp = this.getCSAssetsSearchOptions();
                break;
            case 'searchsys':
                sp = this.getCSSystemsSearchOptions();
                break;
            case 'managechecks':
                sp = this.getCheckItemsSearchOptions();
                break;
            case 'issuechecksheets':
                sp = this.getCheckSheetsForIssueSearchOptions();
                break;
            case 'receivechecksheets':
                sp = this.getCheckSheetsForReceiveSearchOptions();
                break;
            case 'managepcc':
                sp = this.getPCCSearchOptions();
                break;
            default:
                sp = this.getQuestions()
                break;
        }
        return sp
    }

    getQuestions() {
        let sp: SearchParams<any>[] = [
            new SearchDropdown({
                key: 'facility',
                label: 'Facility',
                options: [
                    { key: 'facility_1', value: 'Facility 1' },
                    { key: 'facility_2', value: 'Facility 2' },
                    { key: 'facility_3', value: 'Facility 3' },
                    { key: 'facility_4', value: 'Facility 4' }
                ],
                order: 3
            }),
            new SearchTextBox({
                key: 'tag_number',
                label: 'Tag Number',
                value: '00alk123',
                type: 'text',
                order: 1,
                id: 'searchtext'
            }),
            new SearchTextBox({
                key: 'description',
                label: 'Tag Description',
                type: 'text',
                order: 2
            })
        ];

        return sp.sort((a, b) => a.order - b.order);
    }

    private getTagSearchOptions() {
        let sp: SearchParams<any>[] = [
            new SearchWildCards({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_TAG_TAG_NUM,
                label: 'Tag Number : ',
                placeholder: 'Tag Number',
                //type: 'text',
                type: 'wildcards',
                order: 1,
                id: 'tagsearch_tagnumber'
            }),
            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_TAG_TAG_DESCRIPTION,
                label: 'Description : ',
                placeholder: 'Description',
                type: 'text',
                order: 2,
                id: 'tagsearch_discription'
            }),
            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_TAG_TAG_SYSTEM,
                label: 'System : ',
                placeholder: 'System',
                type: 'text',
                order: 3,
                id: 'tagsearch_system'
            }),
            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_TAG_TAG_DOCUMENT,
                label: 'Document : ',
                placeholder: 'Document',
                type: 'text',
                order: 4,
                id: 'tagsearch_document'
            }),
            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_TAG_TAG_FACILITY,
                label: 'Facility : ',
                placeholder: 'Facility',
                type: 'text',
                order: 5,
                id: 'tagsearch_facility'
            }),
            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_TAG_TAG_CAD_NUMBER,
                label: 'CAD Number : ',
                placeholder: 'CAD Number',
                type: 'text',
                order: 6,
                id: 'tagsearch_cadno'
            }),
            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_HAS_THE_WORDS,
                label: 'Has the words : ',
                placeholder: 'Has the words',
                type: 'text',
                order: 7,
                id: 'tagsearch_hasthewords'
            })
        ];
        return sp.sort((a, b) => a.order - b.order);
    }
    private getComponentSearchOptions() {
        let sp: SearchParams<any>[] = [
            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_COMPONENT_COMP_NAME,
                label: 'Component Name : ',
                placeholder: 'Component Name',
                type: 'text',
                order: 1,
                id: 'componentsearch_name'
            }),
            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_COMPONENT_COMP_VALUE,
                label: 'Component Value : ',
                placeholder: 'Component Value',
                type: 'text',
                order: 2,
                id: 'componentsearch_value'
            }),
            new SearchCategory({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_COMPONENT_COMP_CATEGORY,
                label: 'Category : ',
                placeholder: 'Category',
                type: 'comp_category',
                order: 5,
                id: 'componentsearch_category'
            }),
            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_HAS_THE_WORDS,
                label: 'Has the words : ',
                placeholder: 'Has the words',
                type: 'text',
                order: 6,
                id: 'componentsearch_hasthewords'
            })
        ];
        return sp.sort((a, b) => a.order - b.order);
    }

    private getDocumentSearchOptions() {
        let sp: SearchParams<any>[] = [
            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_DOCUMENT_DOC_NAME,
                label: 'Document Name : ',
                placeholder: 'Document Name',
                type: 'text',
                order: 1,
                id: 'documentsearch_name'
            }),
            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_DOCUMENT_DOC_ALT_DOC_NO,
                label: 'ALT Doc No : ',
                placeholder: 'ALT Doc No',
                type: 'text',
                order: 2,
                id: 'documentsearch_altdocno'
            }),
            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_DOCUMENT_DOC_FACILITY,
                label: 'Facility Name : ',
                placeholder: 'Facility Name',
                type: 'text',
                order: 2,
                id: 'documentsearch_facilityname'
            }),
            new SearchDocType({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_DOCUMENT_DOC_DOCTYPE,
                label: 'Document Type : ',
                placeholder: 'Document Type',
                type: 'document-type',
                order: 2,
                id: 'documentsearch_documenttype'
            }),
            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_DOCUMENT_DOC_REVISION,
                label: 'Revision : ',
                placeholder: 'Revision',
                type: 'text',
                order: 2,
                id: 'documentsearch_revision'
            }),
            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_DOCUMENT_DOC_FILENAME,
                label: 'File Name : ',
                placeholder: 'File Name',
                type: 'text',
                order: 2,
                id: 'documentsearch_filename'
            }),
            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_HAS_THE_WORDS,
                label: 'Has the words : ',
                placeholder: 'Has the words',
                type: 'text',
                order: 3,
                id: 'documentsearch_hasthewords'
            })
        ];
        return sp.sort((a, b) => a.order - b.order);
    }
    private getSystemSearchOptions() {
        let sp: SearchParams<any>[] = [
            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_SYSTEM_SYS_NUMBER,
                label: 'System Number : ',
                placeholder: 'System Number',
                type: 'text',
                order: 1,
                id: 'systemsearch_number'
            }),
            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_SYSTEM_SYS_NAME,
                label: 'System Name : ',
                placeholder: 'System Name',
                type: 'text',
                order: 2,
                id: 'systemsearch_name'
            }),
            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_SYSTEM_SYS_FACILITY,
                label: 'Facility Name : ',
                placeholder: 'Facility Name',
                type: 'text',
                order: 3,
                id: 'systemsearch_facilityname'
            }),
            new SearchDropdown({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_SYSTEM_SYS_COMMISSIONING,
                label: 'Used in Commissioning : ',
                placeholder: 'Used in Commissioning',
                type: 'dropdown',
                order: 4,
                options: [{ key: 'Yes', value: 'Yes' }, { key: 'No', value: 'No' }],
                id: 'systemsearch_commissioning'
            }),
            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_HAS_THE_WORDS,
                label: 'Has the words : ',
                placeholder: 'Has the words',
                type: 'text',
                order: 5,
                id: 'systemsearch_hasthewords'
            })
        ];
        return sp.sort((a, b) => a.order - b.order);
    }
    private getFacilitySearchOptions() {
        let sp: SearchParams<any>[] = [
            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_FACILITY_FAC_NAME,
                label: 'Facility Name : ',
                placeholder: 'Facility Name',
                type: 'text',
                order: 1,
                id: 'facilitysearch_name'
            }),
            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_FACILITY_EMAIL,
                label: 'Email : ',
                placeholder: 'Email',
                type: 'text',
                order: 2,
                id: 'facilitysearch_email'
            }),
            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_FACILITY_SEC_EMAIL,
                label: 'Secondary Email : ',
                placeholder: 'Secondary Email',
                type: 'text',
                order: 3,
                id: 'facilitysearch_semail'
            }),
            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_HAS_THE_WORDS,
                label: 'Has the words : ',
                placeholder: 'Has the words',
                type: 'text',
                order: 4,
                id: 'facilitysearch_hasthewords'
            })
        ];
        return sp.sort((a, b) => a.order - b.order);
    }
    private getImageSearchOption() {
        let sp: SearchParams<any>[] = [
            new SearchDocType({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_IMAGE_IMG_DOCTYPE,
                label: 'Document Type : ',
                placeholder: 'Document Type',
                type: 'img-document-type',
                order: 1,
                id: 'imagesearch_docType'

            }),
            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_IMAGE_IMG_DESCRIPTION,
                label: 'Description : ',
                placeholder: 'Description',
                type: 'text',
                order: 2,
                id: 'imagesearch_description'
            }),
            /* new SearchTextBox({
                 key: Constants.SEARCH_KEY_WORDS.SEARCH_IMAGE_IMG_SYSTEM,
                 label: 'System : ',
                 placeholder: 'System',
                 type: 'text',
                 order: 3,
                 id: 'imagesearch_isystem'
             }),
             new SearchTextBox({
                 key: Constants.SEARCH_KEY_WORDS.SEARCH_IMAGE_IMG_DOCUMENT,
                 label: 'Document : ',
                 placeholder: 'Document',
                 type: 'text',
                 order: 4,
                 id: 'imagesearch_idoc'
             }),*/
            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_HAS_THE_WORDS,
                label: 'Has the words : ',
                placeholder: 'Has the words',
                type: 'text',
                order: 5,
                id: 'imagesearch_hasthewords'
            })
        ];
        return sp.sort((a, b) => a.order - b.order);
    }
    private getThreeDModelSearchOption() {
        let sp: SearchParams<any>[] = [
            new SearchDocType({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_THREED_MODEL_DOCTYPE,
                label: 'Document Type : ',
                placeholder: 'Document Type',
                type: 'threed-model-document-type',
                order: 1,
                id: 'threedmodelsearch_docType'

            }),
            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_THREED_MODEL_DESCRIPTION,
                label: 'Description : ',
                placeholder: 'Description',
                type: 'text',
                order: 2,
                id: 'threedmodelsearch_description'
            }),
            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_HAS_THE_WORDS,
                label: 'Has the words : ',
                placeholder: 'Has the words',
                type: 'text',
                order: 3,
                id: 'threedmodelsearch_hasthewords'
            })
        ];
        return sp.sort((a, b) => a.order - b.order);
    }

    private getVideoSearchOption() {
        let sp: SearchParams<any>[] = [
            new SearchDocType({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_VIDEO_DOCTYPE,
                label: 'Document Type : ',
                placeholder: 'Document Type',
                type: 'video-document-type',
                order: 1,
                id: 'videosearch_docType'

            }),
            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_VIDEO_DESCRIPTION,
                label: 'Description : ',
                placeholder: 'Description',
                type: 'text',
                order: 2,
                id: 'videosearch_description'
            }),
            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_HAS_THE_WORDS,
                label: 'Has the words : ',
                placeholder: 'Has the words',
                type: 'text',
                order: 3,
                id: 'videosearch_hasthewords'
            })
        ];
        return sp.sort((a, b) => a.order - b.order);
    }

    private getStatementSearchOptions() {
        let sp: SearchParams<any>[] = [
            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_STATEMENT_STM_TITLE,
                label: 'Title : ',
                placeholder: 'Title',
                type: 'text',
                order: 1,
                id: 'statemensearch_title'
            }),
            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_STATEMENT_STM,
                label: 'Statement : ',
                placeholder: 'Statement',
                type: 'text',
                order: 2,
                id: 'statemensearch_title'
            }),
            new SearchCategory({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_STATEMENT_STM_CATEGORY,
                label: 'Category : ',
                placeholder: 'Category',
                type: 'state_category',
                order: 3,
                id: 'statemensearch_category'
            }),
            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_HAS_THE_WORDS,
                label: 'Has the words : ',
                placeholder: 'Has the words',
                type: 'text',
                order: 4,
                id: 'statemensearch_hasthewords'
            })
        ];
        return sp.sort((a, b) => a.order - b.order);
    }

    private getHeaderSearchOptions() {
        let sp: SearchParams<any>[] = [
            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_HEADER_FILE_NAME,
                label: 'File Name : ',
                placeholder: 'File Name',
                type: 'text',
                order: 1,
                id: 'header_file_name'
            }),
            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_HEADER_FILE_CHECKOUT_BY,
                label: 'Checked out by : ',
                placeholder: 'Checked out by',
                type: 'text',
                order: 2,
                id: 'header_checked_out_by'
            }),
            new SearchDatePicker({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_HEADER_FILE_LAST_UPDATE_DATE,
                label: 'Last update date : ',
                placeholder: 'Last update date',
                type: 'text',
                order: 3,
                id: 'header_last_update_date'
            }),
            new SearchDropdown({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_HEADER_FILE_ITEM_TYPE,
                type: 'dropdown',
                label: 'Type : ',
                placeholder: 'Type',
                options: [
                    { key: 'topic', value: 'Topic' },
                    { key: 'procedure', value: 'Procedure' },
                    { key: 'topic_map', value: 'Topic Map' },
                    { key: 'course', value: 'Course' },
                    { key: 'image', value: 'Image' },
                    { key: 'video', value: 'Video' },
                    { key: 'threedmodel', value: '3D Model' },
                    { key: 'content_item', value: 'Content Item' },
                    { key: 'all', value: 'ALL' }
                ],
                order: 4,
                id: 'header_item_type'
            }),
            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_HAS_THE_WORDS,
                label: 'Has the words : ',
                placeholder: 'Has the words',
                type: 'text',
                order: 5,
                id: 'header_hasthewords'
            })
        ];
        return sp.sort((a, b) => a.order - b.order);
    }

    private getPunchlistSearchOptions() {
        let sp: SearchParams<any>[] = [
            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_PUNCHLIST_CATEGORY,
                label: 'Punchlist Category : ',
                placeholder: 'Punchlist Category',
                type: 'text',
                order: 1,
                id: 'punchlist_category'
            }),
            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_PUNCHLIST_ASSET,
                label: 'Asset : ',
                placeholder: 'Asset',
                type: 'text',
                order: 2,
                id: 'punchlist_asset'
            }),
            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_PUNCHLIST_DISCIPLINE,
                label: 'Discipline : ',
                placeholder: 'Discipline',
                type: 'text',
                order: 3,
                id: 'punchlist_discipline'
            }),
            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_PUNCHLIST_DESCRIPTION,
                label: 'Punchlist Description : ',
                placeholder: 'Punchlist Description',
                type: 'text',
                order: 4,
                id: 'punchlist_description'
            }),
            new SearchDatePicker({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_PUNCHLIST_DATE_RAISED,
                label: 'Date Raised : ',
                placeholder: 'Date Raised',
                type: 'text',
                order: 5,
                id: 'punchlist_date_raised'
            }),
            new SearchDatePicker({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_PUNCHLIST_DATE_CLOSED,
                label: 'Date Closed : ',
                placeholder: 'Date Closed',
                type: 'text',
                order: 6,
                id: 'punchlist_date_closed'
            }),
            new SearchDatePicker({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_PUNCHLIST_COMPLETION_DATE,
                label: 'Completion Date : ',
                placeholder: 'Completion Date',
                type: 'text',
                order: 7,
                id: 'punchlist_completion_date'
            }),
            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_PUNCHLIST_RAISED_BY,
                label: 'Raised By : ',
                placeholder: 'Raised By',
                type: 'text',
                order: 8,
                id: 'punchlist_raised_by'
            }),
            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_PUNCHLIST_MATERIAL_REQUIRED,
                label: 'Material Required : ',
                placeholder: 'Material Required',
                type: 'text',
                order: 9,
                id: 'punchlist_material_required'
            }),
            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_PUNCHLIST_SUB_SYSTEMS,
                label: 'Sub Systems : ',
                placeholder: 'Sub Systems',
                type: 'text',
                order: 10,
                id: 'punchlist_sub_systems'
            }),
            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_PUNCHLIST_EST_MAN_HOURS,
                label: 'Estimated Man Hours : ',
                placeholder: 'Estimated Man Hours',
                type: 'text',
                order: 11,
                id: 'punchlist_est_man_hours'
            }),
            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_PUNCHLIST_ITEM_STATUS,
                label: 'Item Status : ',
                placeholder: 'Item Status',
                type: 'text',
                order: 12,
                id: 'punchlist_item_status'
            }),
            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_HAS_THE_WORDS,
                label: 'Has the words : ',
                placeholder: 'Has the words',
                type: 'text',
                order: 13,
                id: 'header_hasthewords'
            })
        ];
        return sp.sort((a, b) => a.order - b.order);
    }

    /**
     * Achini Randeni
     * Get IRN search fields according to the input type
     */
    private getIrnSearchOptions() {
        let sp: SearchParams<any>[] = [
            new SearchTextBox(
                {
                    key: Constants.SEARCH_KEY_WORDS.SEARCH_IRN_IRN_NUMBER,
                    placeholder: 'Select IRN Number',
                    type: 'text',
                    order: 1,
                    id: 'irn_IRN_number'
                }
            ),
            new SearchTextBox(
                {
                    key: Constants.SEARCH_KEY_WORDS.SEARCH_IRN_IRN_DESCRIPTION,
                    placeholder: 'Select IRN Description',
                    type: 'text',
                    order: 2,
                    id: 'irn_description'
                }
            ),
            new SearchTagInput(
                {
                    key: Constants.SEARCH_KEY_WORDS.SEARCH_IRN_VENDOR,
                    placeholder: 'Select Vendor',
                    type: 'taginput',
                    order: 3,
                    id: 'irn_vendor',
                    disable: false
                }
            ),
            new SearchTagInput(
                {
                    key: Constants.SEARCH_KEY_WORDS.SEARCH_IRN_ASSET,
                    placeholder: 'Select Asset',
                    type: 'taginput',
                    order: 4,
                    id: 'irn_asset',
                    disable: false
                }
            ),
            new SearchTagInput(
                {
                    key: Constants.SEARCH_KEY_WORDS.SEARCH_IRN_STATUS,
                    label: 'select status : ',
                    placeholder: 'Select IRN Status',
                    type: 'irnstatus',
                    order: 5,
                    id: 'IRN Status'
                }
            ),
            /* new SearchTagInput(
                {
                    key: Constants.SEARCH_KEY_WORDS.SEARCH_IRN_COMPLETION_DATE,
                    label: 'select completion date : ',
                    placeholder: 'Select Completion Date',
                    type: 'completiondate',
                    order: 6,
                    id: 'Completion Date'
                }
            ), */

            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_HAS_THE_WORDS,
                label: 'Has the words : ',
                placeholder: 'Has the words',
                type: 'text',
                order: 7,
                id: 'header_hasthewords'
            })
        ];
        return sp.sort((a, b) => a.order - b.order);
    }
    private getMCCSearchOptions() {
        let sp: SearchParams<any>[] = [
            new SearchTagInput(
                {
                    key: Constants.SEARCH_KEY_WORDS.SEARCH_MCC_SYSTEM,
                    label: 'select system : ',
                    placeholder: 'Select System Name',
                    type: 'irnnumber',
                    order: 1,
                    id: 'Discipline Name',
                    disable: false
                }
            ),
            new SearchTagInput(
                {
                    key: Constants.SEARCH_KEY_WORDS.SEARCH_MCC_STATUS,
                    label: 'select status : ',
                    placeholder: 'Select MCC Status',
                    type: 'irndesc',
                    order: 2,
                    id: 'Discipline Name',
                    disable: false
                }
            ),
            new SearchTagInput(
                {
                    key: Constants.SEARCH_KEY_WORDS.SEARCH_MCC_COMPLETION_DATE,
                    label: 'select completion date : ',
                    placeholder: 'Select Completion Date',
                    type: 'assets',
                    order: 3,
                    id: 'Discipline Name',
                    disable: false
                }
            ),
            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_HAS_THE_WORDS,
                label: 'Has the words : ',
                placeholder: 'Has the words',
                type: 'text',
                order: 4,
                id: 'header_hasthewords'
            })
        ];
        return sp.sort((a, b) => a.order - b.order);
    }
    private getOTNSearchOptions() {
        let sp: SearchParams<any>[] = [
            new SearchTagInput(
                {
                    key: Constants.SEARCH_KEY_WORDS.SEARCH_OTN_ASSET,
                    label: 'select asset : ',
                    placeholder: 'Select Asset',
                    type: 'otnasset',
                    order: 1,
                    id: 'Asset Name',
                    disable: false
                }
            ),
            new SearchTagInput(
                {
                    key: Constants.SEARCH_KEY_WORDS.SEARCH_OTN_VENDOR,
                    label: 'select vendor : ',
                    placeholder: 'Select Vendor',
                    type: 'vendor',
                    order: 2,
                    id: 'Vendor Name',
                    disable: false
                }
            ),
            new SearchTagInput(
                {
                    key: Constants.SEARCH_KEY_WORDS.SEARCH_OTN_STATUS,
                    label: 'select status : ',
                    placeholder: 'Select OTN Status',
                    type: 'otnstatus',
                    order: 3,
                    id: 'OTN Status',
                    disable: false
                }
            ),
            /* new SearchTagInput(
                {
                    key: Constants.SEARCH_KEY_WORDS.SEARCH_OTN_COMPLETION_DATE,
                    label: 'select completion date : ',
                    placeholder: 'Select Completion Date',
                    type: 'completiondate',
                    order: 4,
                    id: 'Completion Date',
                    disable: false
                }
            ), */
            new SearchTextBox(
                {
                    key: Constants.SEARCH_KEY_WORDS.SEARCH_HAS_THE_WORDS,
                    label: 'Has the words : ',
                    placeholder: 'Has the words',
                    type: 'text',
                    order: 5,
                    id: 'header_hasthewords'
                }
            )
        ];
        return sp.sort((a, b) => a.order - b.order);
    }

    private getCSSearchOptions() {
        let sp: SearchParams<any>[] = [
            new SearchTextBox(
                {
                    key: Constants.SEARCH_KEY_WORDS.SEARCH_CS_NAME,
                    label: 'Checksheet Name : ',
                    placeholder: 'Checksheet Name',
                    type: 'text',
                    order: 1,
                    id: 'cs_name'
                }
            ),
            new SearchTextBox(
                {
                    key: Constants.SEARCH_KEY_WORDS.SEARCH_CS_CODE,
                    label: 'Checksheet Code : ',
                    placeholder: 'Checksheet Code',
                    type: 'text',
                    order: 2,
                    id: 'cs_code'
                }
            ),
            new SearchTextBox(
                {
                    key: Constants.SEARCH_KEY_WORDS.SEARCH_CS_DISCIPLINE,
                    label: 'Checksheet Discipline : ',
                    placeholder: 'Checksheet Discipline',
                    type: 'text',
                    order: 3,
                    id: 'cs_disc'
                }
            ),
            new SearchDropdown({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_CS_TYPE,
                label: 'Checksheet Type : ',
                placeholder: 'Checksheet Type',
                type: 'dropdown',
                order: 4,
                options: [{ key: 'A', value: 'A' }, { key: 'B', value: 'B' }, { key: 'C', value: 'C' }, { key: 'D', value: 'D' }],
                id: 'cs_type'
            }),
            new SearchTextBox(
                {
                    key: Constants.SEARCH_KEY_WORDS.SEARCH_HAS_THE_WORDS,
                    label: 'Has the words : ',
                    placeholder: 'Has the words',
                    type: 'text',
                    order: 5,
                    id: 'header_hasthewords'
                }
            )
        ];
        return sp.sort((a, b) => a.order - b.order);
    }

    private getCSChecksSearchOptions() {
        let sp: SearchParams<any>[] = [
            new SearchCategory({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_CHK_CATEGORY,
                label: 'Check Category : ',
                placeholder: 'Check Category',
                type: 'chk_category',
                order: 1,
                id: 'chk_category'
            }
            ),
            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_CHK_DESC,
                label: 'Check Description : ',
                placeholder: 'Check Description',
                type: 'text',
                order: 2,
                id: 'chk_desc'
            }
            ),
            new SearchDropdown({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_CHK_TYPE,
                label: 'Type of Check : ',
                placeholder: 'Type of Check',
                type: 'dropdown',
                order: 3,
                options: [{ key: 'OK-NA-PL', value: 'OK-NA-PL' }, { key: 'DATA ENTRY', value: 'DATA ENTRY' }],
                id: 'chk_type'
            }
            )
        ];
        return sp.sort((a, b) => a.order - b.order);
    }

    private getCSAssetsSearchOptions() {
        let sp: SearchParams<any>[] = [
            new SearchTagInput(
                {
                    key: Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_NO,
                    label: 'Select Asset : ',
                    placeholder: 'Select Asset',
                    type: 'taginput',
                    order: 1,
                    id: 'asset_no',
                    disable: false
                }
            ),
            new SearchTagInput(
                {
                    key: Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_DESC,
                    label: 'Select Description : ',
                    placeholder: 'Select Description',
                    type: 'taginput',
                    order: 2,
                    id: 'asset_desc',
                    disable: false
                }
            ),
            new SearchTagInput(
                {
                    key: Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_SYS,
                    label: 'Select System : ',
                    placeholder: 'Select System',
                    type: 'taginput',
                    order: 3,
                    id: 'asset_sys',
                    disable: false
                }
            ),
            new SearchTagInput(
                {
                    key: Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_GROUP,
                    label: 'Select Asset Group : ',
                    placeholder: 'Select Asset Group',
                    type: 'taginput',
                    order: 4,
                    id: 'asset_group',
                    disable: false
                }
            ),
            new SearchTagInput(
                {
                    key: Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_ATTRIBUTE,
                    label: 'Select Attribute Value : ',
                    placeholder: 'Select Attribute Value',
                    type: 'taginput',
                    order: 5,
                    id: 'attribute_value',
                    disable: true
                }
            ),
            new SearchTagInput(
                {
                    key: Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_PARENT,
                    label: 'Select Asset Parent : ',
                    placeholder: 'Select Asset Parent',
                    type: 'taginput',
                    order: 6,
                    id: 'asset_parent',
                    disable: false
                }
            ),
            new SearchTagInput(
                {
                    key: Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_FUNCCODE,
                    label: 'Select Function Code : ',
                    placeholder: 'Select Function Code',
                    type: 'taginput',
                    order: 7,
                    id: 'func_code',
                    disable: false
                }
            ),
        ];
        return sp.sort((a, b) => a.order - b.order);
    }

    private getCSSystemsSearchOptions() {
        let sp: SearchParams<any>[] = [
            new SearchTagInput(
                {
                    key: Constants.SEARCH_KEY_WORDS.SEARCH_SYS_NO,
                    label: 'Select System No : ',
                    placeholder: 'Select System No',
                    type: 'taginput',
                    order: 1,
                    id: 'system_no',
                    disable: false
                }
            ),
            new SearchTagInput(
                {
                    key: Constants.SEARCH_KEY_WORDS.SEARCH_SYS_NAME,
                    label: 'Select System Name : ',
                    placeholder: 'Select System Name',
                    type: 'taginput',
                    order: 2,
                    id: 'system_name',
                    disable: false
                }
            )
            /*new SearchTagInput(
                {
                    key: Constants.SEARCH_KEY_WORDS.SEARCH_SYS_PARENT,
                    label: 'Select Parent: ',
                    placeholder: 'Select Parent',
                    type: 'taginput',
                    order: 3,
                    id: 'system_parent',
                    disable: false
                }
            ),*/
        ];
        return sp.sort((a, b) => a.order - b.order);
    }

    private getCheckItemsSearchOptions(){
        let sp: SearchParams<any>[] = [
            new SearchCategory({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_CHK_CATEGORY,
                label: 'Check Category : ',
                placeholder: 'Check Category',
                type: 'chk_category',
                order: 1,
                id: 'chk_category'
            }
            ),
            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_CHK_DESC,
                label: 'Check Description : ',
                placeholder: 'Check Description',
                type: 'text',
                order: 2,
                id: 'chk_desc'
            }
            ),
            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_HAS_THE_WORDS,
                label: 'Has the words : ',
                placeholder: 'Has the words',
                type: 'text',
                order: 3,
                id: 'chk_hasthewords'
            }
            )
        ];
        return sp.sort((a, b) => a.order - b.order);
    }

    private getCheckSheetsForIssueSearchOptions(){
        let sp: SearchParams<any>[] = [
            new SearchTagInput({
                key: Constants.SEARCH_KEY_WORDS.ISSUE_CS_VENDOR,
                label: 'Select Vendor : ',
                placeholder: 'Select Vendor',
                type: 'taginput',
                order: 1,
                id: 'issue_cs_vendor'
            }
            ),
            new SearchTagInput({
                key: Constants.SEARCH_KEY_WORDS.ISSUE_CS_CHECKSHEET,
                label: 'Select Checksheet : ',
                placeholder: 'Select Checksheet',
                type: 'taginput',
                order: 2,
                id: 'issue_cs_checksheet'
            }
            ),
            new SearchTagInput({
                key: Constants.SEARCH_KEY_WORDS.ISSUE_CS_ASSET,
                label: 'Select Asset : ',
                placeholder: 'Select Asset',
                type: 'taginput',
                order: 3,
                id: 'issue_cs_asset'
            }
            ),
            new SearchTagInput({
                key: Constants.SEARCH_KEY_WORDS.ISSUE_CS_ASSET_DESCRIPTION,
                label: 'Asset Description : ',
                placeholder: 'Asset Description',
                type: 'text',
                order: 4,
                id: 'issue_cs_asset_desc'
            }
            ),
            new SearchTagInput({
                key: Constants.SEARCH_KEY_WORDS.ISSUE_CS_SYSTEM,
                label: 'Select System : ',
                placeholder: 'Select System',
                type: 'taginput',
                order: 5,
                id: 'issue_cs_system'
            }
            ),
            new SearchTagInput({
                key: Constants.SEARCH_KEY_WORDS.ISSUE_CS_ASSET_GROUP,
                label: 'Select Asset Group : ',
                placeholder: 'Select Asset Group',
                type: 'taginput',
                order: 6,
                id: 'issue_cs_asset_group'
            }
            ),
            new SearchTagInput({
                key: Constants.SEARCH_KEY_WORDS.ISSUE_CS_PHASE,
                label: 'Select Phase : ',
                placeholder: 'Select Phase',
                type: 'taginput',
                order: 7,
                id: 'issue_cs_phase'
            }
            ),
            new SearchTagInput({
                key: Constants.SEARCH_KEY_WORDS.ISSUE_CS_ASSET_PARENT,
                label: 'Select Asset Parent : ',
                placeholder: 'Select Asset Parent',
                type: 'text',
                order: 8,
                id: 'issue_cs_asset_parent'
            }
            ),
            new SearchTagInput({
                key: Constants.SEARCH_KEY_WORDS.ISSUE_CS_FUNCTION_CODE,
                label: 'Select CS Function Code : ',
                placeholder: 'Select CS Function Code',
                type: 'taginput',
                order: 9,
                id: 'issue_cs_function_code'
            }
            ),
            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_HAS_THE_WORDS,
                label: 'Has the words : ',
                placeholder: 'Has the words',
                type: 'text',
                order: 10,
                id: 'issue_cs_hasthewords'
            }
            )
        ];
        return sp.sort((a, b) => a.order - b.order);
    }

    private getCheckSheetsForReceiveSearchOptions(){
        let sp: SearchParams<any>[] = [
            new SearchTagInput({
                key: Constants.SEARCH_KEY_WORDS.RECEIVE_CS_VENDOR,
                label: 'Select Vendor : ',
                placeholder: 'Select Vendor',
                type: 'taginput',
                order: 1,
                id: 'receive_cs_vendor'
            }
            ),
            new SearchTagInput({
                key: Constants.SEARCH_KEY_WORDS.RECEIVE_CS_CHECKSHEET,
                label: 'Select Checksheet : ',
                placeholder: 'Select Checksheet',
                type: 'taginput',
                order: 2,
                id: 'receive_cs_checksheet'
            }
            ),
            new SearchTagInput({
                key: Constants.SEARCH_KEY_WORDS.RECEIVE_CS_ASSET,
                label: 'Select Asset : ',
                placeholder: 'Select Asset',
                type: 'taginput',
                order: 3,
                id: 'receive_cs_asset'
            }
            ),
            new SearchTagInput({
                key: Constants.SEARCH_KEY_WORDS.RECEIVE_CS_ASSET_DESCRIPTION,
                label: 'Asset Description : ',
                placeholder: 'Asset Description',
                type: 'text',
                order: 4,
                id: 'receive_cs_asset_desc'
            }
            ),
            new SearchTagInput({
                key: Constants.SEARCH_KEY_WORDS.RECEIVE_CS_SYSTEM,
                label: 'Select System : ',
                placeholder: 'Select System',
                type: 'taginput',
                order: 5,
                id: 'receive_cs_system'
            }
            ),
            new SearchTagInput({
                key: Constants.SEARCH_KEY_WORDS.RECEIVE_CS_ASSET_GROUP,
                label: 'Select Asset Group : ',
                placeholder: 'Select Asset Group',
                type: 'taginput',
                order: 6,
                id: 'receive_cs_asset_group'
            }
            ),
            new SearchTagInput({
                key: Constants.SEARCH_KEY_WORDS.RECEIVE_CS_PHASE,
                label: 'Select Phase : ',
                placeholder: 'Select Phase',
                type: 'taginput',
                order: 7,
                id: 'receive_cs_phase'
            }
            ),
            new SearchTagInput({
                key: Constants.SEARCH_KEY_WORDS.RECEIVE_CS_FUNCTION_CODE,
                label: 'Select CS Function Code : ',
                placeholder: 'Select CS Function Code',
                type: 'taginput',
                order: 9,
                id: 'receive_cs_function_code'
            }
            ),
            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_HAS_THE_WORDS,
                label: 'Has the words : ',
                placeholder: 'Has the words',
                type: 'text',
                order: 10,
                id: 'receive_cs_hasthewords'
            }
            )
        ];
        return sp.sort((a, b) => a.order - b.order);
    }

    private getPCCSearchOptions(){
        let sp: SearchParams<any>[] = [
            new SearchTagInput(
                {
                    key: Constants.SEARCH_KEY_WORDS.SEARCH_PCC_SYSTEM,
                    label: 'select system : ',
                    placeholder: 'Select System Name',
                    type: 'taginput',
                    order: 1,
                    id: 'PCC_system_name',
                    disable: false
                }
            ),
            new SearchTagInput(
                {
                    key: Constants.SEARCH_KEY_WORDS.SEARCH_PCC_STATUS,
                    label: 'select status : ',
                    placeholder: 'Select PCC Status',
                    type: 'taginput',
                    order: 2,
                    id: 'PCC_status',
                    disable: false
                }
            ),
            new SearchTagInput(
                {
                    key: Constants.SEARCH_KEY_WORDS.SEARCH_PCC_COMPLETION_DATE,
                    label: 'select completion date : ',
                    placeholder: 'Select Completion Date',
                    type: 'taginput',
                    order: 3,
                    id: 'PCC_target_completion_date',
                    disable: false
                }
            ),
            new SearchTextBox({
                key: Constants.SEARCH_KEY_WORDS.SEARCH_HAS_THE_WORDS,
                label: 'Has the words : ',
                placeholder: 'Has the words',
                type: 'text',
                order: 4,
                id: 'PCC_has_the_words'
            })
        ];
        return sp.sort((a, b) => a.order - b.order);
    }
}
