import { Component, OnInit, HostListener, TemplateRef, Input } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal'
import { BsModalRef } from 'ngx-bootstrap/modal'

@Component({
	selector: 'app-image-search',
	templateUrl: './image-search.component.html',
	styleUrls: ['./image-search.component.css']
})
export class ImageSearchComponent implements OnInit {

	public divHeight: number = (window.innerHeight) * 4 / 5;
	@HostListener('window:resize', ['$event'])
	onresize(event?) {
		this.divHeight = (window.innerHeight) * 4 / 5
	}

	public imageName: string = 'Image Name'
	modalRef: BsModalRef

	@Input() changeLayout: boolean = false

	constructor(private modalService: BsModalService) { }

	ngOnInit() {
	}

	searchFunc(event: any) {
		console.log('[searchFunc] (event) ' + JSON.stringify(event));
		this.divHeight = (window.innerHeight) * 4 / 5
	}

	searchBarEnterFunc(event: any) {
		console.log('[searchBarEnterFunc] (event) ' + JSON.stringify(event));
	}

	showSearchOptionEE(event: any) {
		console.log('[showSearchOptionEE] (event) ' + JSON.stringify(event));
		if (event) {
			this.divHeight = (window.innerHeight) * 1.7 / 5
		} else {
			this.divHeight = (window.innerHeight) * 4 / 5
		}

	}

	showLargeImage(template: TemplateRef<any>) {
		console.log('[showLargeImage]');
		this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
	}

}
