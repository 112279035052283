<div *ngIf="!disable && icon">
    <button id="landing-table-buttons" class="btn btn-sm btn-outline-dark py-0 my-1 px-1 border-0"
            (click)="invoke(type,irnId )">
        <i class={{icon}} aria-hidden="true"></i>
    </button>
</div>
<div *ngIf="disable && icon">
    <a class="btn btn-sm btn-outline-dark py-0 my-1 px-1 border-0 disabled" (click)="invoke(type,irnId )">
        <i class="text-black-50 {{icon}}" aria-hidden="true"></i>
    </a>
</div>

<ng-template #confirmation_warn>
    <div class="modal-header">
        <h4 *ngIf="type == 'delete'" class="modal-title pull-left">{{'IRN_CELL_RENDER.DELETE_CONFIRM' | translate}}</h4>
        <h4 *ngIf="type == 'edit'"
            class="modal-title pull-left">{{'IRN_CELL_RENDER.EDIT_WARN_CONFIRM' | translate}}</h4>
    </div>
    <div *ngIf="displayMCCWarn || displayOTNWarn" class="modal-body pb-0">
        <h5 class='warn-text-editor'>{{ 'WARNING' | translate}}</h5>
        <h5>This IRN is referenced by</h5>
        <div *ngIf="mccList.length != undefined">
            <ul>
                <div *ngFor="let mcc of mccList">
                    <li>
                        {{mcc.status}} {{mcc.systemName}}
                    </li>
                </div>
            </ul>
        </div>
        <div *ngIf="otnList.length != undefined">
            <ul>
                <div *ngFor="let otn of otnList">
                    <li>
                        {{otn.status}} {{otn.otnName}}
                    </li>
                </div>
            </ul>
        </div>
    </div>
    <div *ngIf="displayPunchlistWarn && type == 'delete'" class="modal-body">
        <h5 class='warn-text-editor'>{{ 'WARNING' | translate}}</h5>
        <h5>{{'IRN_CELL_RENDER.PUNCHLIST_WARN' | translate}}</h5>
    </div>
    <div class="modal-footer">
        <button type="button" id="warn_confirm" class="btn btn-primary"
                (click)="warnModalRef.hide(); warningConfirm();">
            {{ 'OK' | translate}} </button>
        <button type="button" id="warn_cancel" class="btn btn-secondary"
                data-dismiss="modal" (click)="warnModalRef.hide()">
            {{ 'CANCEL' | translate}} </button>
    </div>
</ng-template>

