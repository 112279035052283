<div class="main-panel pt-0 pb-4" style="overflow: hidden;">
	<div class="container-fluid">
		<div class="col-sm-12 px-0">
			<h3>
				{{ 'DOCUMENT_STORE.DS_SIDEBAR_PROCEDURE_INSTANCES' | translate }}
			</h3>
			<hr class="mt-0 mb-3" />
		</div>
		<div class="col-sm-12 px-0">
			<form>
				<div class="form-group">
					<label class="mb-0">{{ 'DOCUMENT_STORE.STATUS' | translate }} :</label>
					<ng-select [active]="selectedSystem" [allowClear]="true" (removed)="removedSystem($event)"
						(selected)="selectedSystemId($event)" [items]="systemList" name="system" id="system">
					</ng-select>
				</div>
			</form>
		</div>
		<hr class="mt-0 mb-3" />
		<div class="col-sm-12 px-0">
			<ag-grid-angular #agGrid style="width: 100%; height: 400px;" id="documentStoreInstance" [rowData]="rowData"  [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }" 
				class="ag-theme-balham" [columnDefs]="columnDefs" [frameworkComponents]="frameworkComponents" [defaultColDef]="defaultColDef"
				[enableRangeSelection]="true" [paginationPageSize]="10" accentedSort = true
				[pagination]="true" (columnResized)="onColumnResized($event)" (gridReady)="onGridReady($event)"
				[suppressMenuHide]="true" [gridOptions]="gridOptions" [enableFilter]="true" [floatingFilter]="true"></ag-grid-angular>
		</div>
	</div>
</div>
