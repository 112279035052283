
<div class="container-fluid page-container punchlist-container">
        <nav class="page-header">
                <div class="row">
                    <div class="col">
                        <p class="mb-2 h5">
                            <b>{{ 'PUNCHLIST_SEARCH_VIEW.HEADER' | translate }}</b>
                        </p>
                    </div>
                    <div class="col">
                            <button type="submit" id="punchlist_save" class="btn btn-primary btn-sm float-right reusable_btn my-1"
                                (click)="newPunchlistButtonClick()"><i class="fas fa-plus"></i></button>
                        </div>
                </div>
        </nav>
    
    <div class="row">
        <div class="col-sm-12 mb-2">
            <symbiosis-search [comp]="'punchlist'" id="punchlist_search" (searchFunc)="searchFunc($event)" (searchBarEnterFunc)="searchBarEnterFunc($event)"></symbiosis-search>
        </div>
    </div>
    <div class="row">
            <div class="col">
                    <ag-grid-angular #agGrid style="width: 100%; height: 375px;" class="ag-theme-balham" [rowData]="rowData" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }" [columnDefs]="columnDefs" [gridOptions]="gridOptions" 
                    [pagination]="true" [paginationPageSize]="10"
                    [overlayLoadingTemplate]="overlayLoadingTemplate" [overlayNoRowsTemplate]="overlayNoRowsTemplate"
                    [getRowNodeId]="getRowNodeId" (gridReady)="onGridReady($event)" (cellClicked)="onCellClick($event)">
                   </ag-grid-angular>
            </div>
    </div>
</div>




