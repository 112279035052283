<div>
    <button [disabled]="disabledDeleteBtn()" (click)="delete()"
        class="btn btn-sm btn-outline-dark py-0 my-1 px-0 border-0">
        <i class="far fa-trash-alt mx-2" aria-hidden="true"></i>
    </button>
</div>


<ng-template #docAttributeDeleteModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{ title }}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container">
            <h6 class="text-center">
                {{ bodyText | translate }}
            </h6>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-sm"
            (click)="deleteItemClick(); bsModalRef.hide()">{{ 'OK' | translate}}</button>
        <button type="button" class="btn btn-secondary btn-sm"
            (click)="bsModalRef.hide()">{{ 'CANCEL' | translate}}</button>
    </div>
</ng-template>