<div class="container-fluid px-0">
	<div class="row px-3">
		<div class="col-sm-8 px-0">
			<p class="mb-2 h5">
				<b>{{ 'HISTORY_SEARCH_VIEW.HEADER' | translate }}</b>
			</p>
		</div>
		<div class="col-sm-4 px-0">
			<!-- <button type="submit" class="btn btn-primary btn-sm float-right reusable_btn my-1">
				<i class="fas fa-plus"></i>
			</button> -->
		</div>
	</div>
	<div class="col-sm-12 px-0">
		<!-- <symbiosis-search [comp]="'tag'" id="tag_search" (searchFunc)="searchFunc($event)" (searchBarEnterFunc)="searchBarEnterFunc($event)"></symbiosis-search> -->
	</div>
	<hr class="my-2" />
</div>

<!-- <div>
  <h5 class="text-center">
    {{ 'HISTORY_SEARCH_VIEW.HEADER' | translate }}
  </h5>
  <br />
</div>
<div class="form-group mb-1">
  <input class="form-control" id="history_search" placeholder="{{ 'HISTORY_SEARCH_VIEW.SEARCH_HISTORY' | translate }}" (keyup.enter)="search($event)">
</div>
<ag-grid-angular style="width: 100%; height: 100%;" class="ag-theme-balham" [rowData]="rowData"  [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }" [columnDefs]="columnDefs"
  [gridOptions]="gridOptions">
</ag-grid-angular>
</div> -->

<ag-grid-angular style="width: 100%; height: 100%;" class="ag-theme-balham" [rowData]="rowData" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }" [columnDefs]="columnDefs"
  (cellClicked)="cellClicked($event)" [gridOptions]="gridOptions">
</ag-grid-angular>
