import { Component, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { TagsService } from '../tags.service';
import { UserVariable } from 'src/app/util/common/user-variable';
import { WsCallback } from 'src/app/util/ws-callback.interface';
import { WsType } from 'src/app/util/ws-type';
import { WsResponse } from 'src/app/util/ws-response.model';
import { AlertService } from 'src/app/util/alert/alert.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { EditCellRenderComponent } from './edit-cell-render/edit-cell-render.component';
import { DeleteCellRenderComponent } from './delete-cell-render/delete-cell-render.component';
import { DependencyCellRendererComponent } from './dependency-cell-render/dependency-cell-render.component';
import { GridOptions, Module, AllModules } from "@ag-grid-enterprise/all-modules";
import { DependencyModalComponent } from '../tag-classification/dependency-modal/dependency-modal.component';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { LoadingService } from 'src/app/util/loading/loading.service';
import { Router } from '@angular/router';
import { CommonUtil } from 'src/app/util/common/common-util';

@Component({
	selector: 'app-manage-attributes',
	templateUrl: './manage-attributes.component.html',
	styleUrls: ['./manage-attributes.component.css']
})
export class ManageAttributesComponent implements OnInit, WsCallback {
	@ViewChild('template') template: TemplateRef<any>;
	@ViewChild('update_template') updateTemplate: TemplateRef<any>;

	token: string;
	tagAttributeList: any = [];

	// Ag-Grid related variables
	public columnDefs;
	public rowData: any[] = [];
	public modules: Module[] = AllModules;
	public frameworkComponents: any;
	public gridOptions: any;
	//Add tag attribute modal
	private bsModalRef: BsModalRef;
	public tagAttributeTypeList: any[] = [];
	public selectedTagAttribute: any[] = [];
	public newTagAttributeName: any;
	private editTagAttributeModel: any;
	private deleteTagAttributeModel: any;
	private gridApi: any;
	private gridColumnApi: any;
	public overlayLoadingTemplate: any;
	public overlayNoRowsTemplate: any;
	public action: string = '';
	public isAttributeTypeModified: boolean = false;

	public paddingtop: number = 60;	
	@ViewChild('navPanel', {static: true}) navPanel: ElementRef

	constructor(public tagService: TagsService, private alertService: AlertService, private modalService: BsModalService,
        private loadingService: LoadingService, private router: Router, private commonUtil: CommonUtil) {
        this.prepareFrameworkComponents();
        this.prepareAgGrid()
		
		this.overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Loading data...</span>';
        this.overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">No rows to show</span>';
	}

	ngOnInit() {
        this.token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(this.token)) {
            return;
        }
        this.tagService.getTagClassAttributeList(this.token, this);
        this.tagService.getTagAttributeTypes(this);

        if (this.navPanel.nativeElement.offsetHeight > 55) {
            this.paddingtop = 78
        } else {
            this.paddingtop = 50
        }
	}

	private prepareAgGrid() {
		this.columnDefs = [
			{
				headerName: 'Dependency',
				field: "dependencies",
				width: 100,
				autoHeight: true,
				resizable: true,
				suppressSizeToFit: true,
				editable: false,
				cellClass: 'vAlign',
				cellRenderer: 'dependencyCellRenderer',
				cellStyle: { 'text-align': 'center' },
				singleClickEdit: true
			},
			{
				headerName: 'Attribute Name',
				autoHeight: true,
				resizable: true,
				suppressSizeToFit: false,
				editable: false,
				field: "tagAttributeName",
				sort: "asc"
				//sortable: true 
			},
			{
				headerName: 'Default Type',
				autoHeight: true,
				resizable: true,
				suppressSizeToFit: false,
				editable: false,
				field: "dfType"
			},
			{
				headerName: 'Edit',
				autoHeight: true,
				resizable: true,
				suppressSizeToFit: true,
				editable: false,
				cellRenderer: 'editCellRenderer',
				cellStyle: { 'text-align': 'center' },
				singleClickEdit: true
			},
			{
				headerName: 'Delete',
				autoHeight: true,
				resizable: true,
				suppressSizeToFit: true,
				editable: false,
				cellClass: 'vAlign',
				cellRenderer: 'deleteCellRenderer',
				cellStyle: { 'text-align': 'center' },
				singleClickEdit: true
			}
		];
	}

	/**
	 * Preparing data for showing in the Ag-Grid view.
	 */
	private createRowData() {
		var rowData: any[] = [];
		this.tagAttributeList.forEach(attribute => {
			rowData.push({
				attributeId: attribute.id,
				dependencies:'disable: true',
				tagAttributeName: attribute.tagAttributeName,
				attributeInstanceId: attribute.attributeInstanceId,
				attributeType: attribute.attributeType,
				tagId: attribute.tagId,
				attributeValue: attribute.attributeValue,
				dfType: attribute.dfType,
				projectId: attribute.projectId,
			});

			var request: any = {
				"itemId": attribute.id,
				"itemType": 'TagClassification',
				"action": 'dependency',
				"userToken": UserVariable.getUserToken()
			};
			this.tagService.getClassificationAttributeDependeciesWithId(request,this);
		});
		this.rowData = rowData;
	}

	/**
	 * Preparing custom cell renderers for the Ag-Grid
	 */
	private prepareFrameworkComponents() {
		this.frameworkComponents = {
			editCellRenderer: EditCellRenderComponent,
			deleteCellRenderer: DeleteCellRenderComponent,
			dependencyCellRenderer: DependencyCellRendererComponent
		};
		//grid options
		this.gridOptions = <GridOptions>{
			context: {
				componentParent: this
			},
			defaultColDef: {
                resizable: true,
				sortable: true
            },
		};
	}
	

	onGridReady(params: any) {
		this.gridApi = params.api
		this.gridColumnApi = params.columnApi;
		this.gridApi.hideOverlay()
		this.gridApi.resetRowHeights();
		this.gridApi.sizeColumnsToFit()
	}

	/**
	 * User clicks on the edit button on the Ag-Grid.
	 * @param data attribute model
	 */
	public editTagAttribute(data: any) {
		this.editTagAttributeModel = data;
		this.tagAttributeTypeList.forEach(type => {
			if (type.id == data.dfType) {
				// clean the previous selection
				this.selectedTagAttribute = [];
				// add the new one
				this.selectedTagAttribute.push(type);
			}
		});		
		// set the name of the attribute
		this.newTagAttributeName = data.tagAttributeName;
		this.action = 'edit'; //set the current action
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
		var request: any = {
			"itemId": this.editTagAttributeModel.attributeId,
			"itemType": 'TagClassification',
			"action": 'edit',
            "userToken": token
		};
		this.tagService.getTagDependenciesByTag(JSON.stringify(request), this);
	}

	/**
	 * User clicks on the delete button on the Ag-Grid. If there are dependencies
	 * then it will be shown otherwise it will ask to confirm the action.
	 * @param data tag attribute model.
	 */
	public deleteTagAttribute(data: any) {
		this.deleteTagAttributeModel = data;
		this.action = 'delete'; //set the current action
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
		var request: any = {
			"itemId": this.deleteTagAttributeModel.attributeId,
			"itemType": 'TagClassificationAttribute',
			"action": 'delete',
            "userToken": token
		};
		this.tagService.getTagDependenciesByTag(JSON.stringify(request), this);
	}

		/**
	 * User clicks on the dependency button on the Ag-Grid. If there are dependencies
	 * then it will be shown otherwise it will show no dependencies
	 * @param data tag attribute model.
	 */
	public showDependencies(data: any) {
		this.action = 'dependency'; //set the current action
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
		var request: any = {
			"itemId": data.attributeId,
			"itemType": 'TagClassificationAttribute',
			"action": 'dependency',
            "userToken": token
		};
		this.tagService.getTagDependenciesByTag(JSON.stringify(request), this);
	}

	/**
	 * Shows the dependency list popup view.
	 * @param tagList dependency list
	 */
	dependenciesModalWithComponentDelete(tagList: any[]) {
		const initialState = {
			tagAttributeDependency: tagList,
			title: 'Tag Classification Attributes Dependency List'
		};
		this.bsModalRef = this.modalService.show(DependencyModalComponent, { initialState });
		this.bsModalRef.content.closeBtnName = 'Close';
	}

	/**
	 * Shows the dependency list popup view.
	 * @param tagList dependency list
	 */
	showDependencyModel(tagList: any[]) {
		const initialState = {
			tagAttributeDependency: tagList,
			title: 'Delete Conflicts'
		};
		this.bsModalRef = this.modalService.show(DependencyModalComponent, { initialState });
		this.bsModalRef.content.closeBtnName = 'Close';
	}

	/**
	 * Shows the dependency list popup view.
	 * @param tagList dependency list
	 */
	dependenciesModalWithComponentEdit(tagList: any[]) {
		const initialState = {
			tagDependency: tagList,
			title: 'Edit Conflicts'
		};
		this.bsModalRef = this.modalService.show(DependencyModalComponent, { initialState });
		this.bsModalRef.content.closeBtnName = 'Close';
	}

	/**
	 * User clicks on the update button on the update popup view.
	 */
	updateButtonClick() {
		if (this.newTagAttributeName != undefined && this.newTagAttributeName != '' && this.selectedTagAttribute.length != 0) {
			if (this.newTagAttributeName.includes("\\")){
				this.newTagAttributeName  = this.newTagAttributeName.replace(/\\/g, "\\\\");
			}
			const data = {
				userToken: this.token, id: this.editTagAttributeModel.attributeId,
				name: this.newTagAttributeName,
				type: this.selectedTagAttribute[0].text,
				isAttributeTypeModified: this.isAttributeTypeModified
			};
			this.tagService.updateTagAttributeType(data, this);
			this.loadingService.showLoading(true, false, 'Saving data', 0);
		} else {
			this.alertService.error('Attribute name and type are required fields');
		}
	}

	/**
	 * User clicks on the Add Attribute button
	 * @param template UI template
	 */
	addTagAttribute(template: TemplateRef<any>) {
		this.newTagAttributeName = "";
		this.selectedTagAttribute = [];
		this.bsModalRef = this.modalService.show(template, { class: 'modal-lg' });
	}

	/**
	 * User clicks on the add button on the add attribute popup view.
	 */
	addButtonClick() {
		if (this.newTagAttributeName != undefined && this.newTagAttributeName != '' && this.selectedTagAttribute.length != 0) {
			if (this.newTagAttributeName.includes("\\")){
				this.newTagAttributeName  = this.newTagAttributeName.replace(/\\/g, "\\\\");
			}
			var data = {attributeName: this.newTagAttributeName, userToken: this.token};
			this.tagService.tagAttTypeNameAvailability(data, this);
			this.loadingService.showLoading(true, false, 'Saving data', 0);
		} else {
			this.alertService.error('Attribute name and type are required fields');
		}
	}

	/**
	 * Tracks successful web service calls.
	 * @param data payload
	 * @param serviceType type of the service
	 */
	onSuccess(data: WsResponse, serviceType: WsType) {
		this.loadingService.hideLoading();
		if (serviceType == WsType.GET_TAG_CLASSIFICATION_ATTRIBUTE_LIST) {
			this.tagAttributeList = data.payload;
			// this.prepareAgGrid();
			this.createRowData();
		} else if (serviceType == WsType.GET_TAG_ATTRIBUTE_TYPES) {
			const valList = data.payload;
			valList.forEach(val => {
				this.tagAttributeTypeList.push({ 'id': val, 'text': val });
			});
		} else if (serviceType == WsType.TAG_ATTRIBUTE_TYPE_NAME_AVAILABILITY) {
			if (data.payload) {
				this.alertService.error('Given name is already exist');
			} else {
				this.loadingService.showLoading(true, false, 'Saving data', 0);
				const data = { userToken: this.token, attributeName: this.newTagAttributeName, attributeType: this.selectedTagAttribute[0].text };
				this.tagService.addNewTagAttributeType(data, this);
			}
		} else if (serviceType == WsType.ADD_TAG_ATTRIBUTE) {
			this.alertService.success(data.statusDescription);
			this.tagService.getTagClassAttributeList(this.token, this);
			this.bsModalRef.hide();
		} else if (serviceType == WsType.UPDATE_TAG_ATTRIBUTE) {
			this.alertService.success(data.statusDescription);
			this.tagService.getTagClassAttributeList(this.token, this);
			this.bsModalRef.hide();
			this.editTagAttributeModel = null;
		} else if (serviceType == WsType.DELETE_TAG_ATTRIBUTE) {
			this.alertService.success(data.statusDescription);
			this.tagService.getTagClassAttributeList(this.token, this);
			this.deleteTagAttributeModel = null;
		} else if (serviceType == WsType.GET_TAG_DEPENDENCIES_BY_TAG) {
			var payload = JSON.parse(data.payload);
			if(this.action == 'edit'){
				if(payload.HasDependencies == 0){
					// send edit request if there are no dependencies.
					this.bsModalRef = this.modalService.show(this.updateTemplate, { class: 'modal-lg' });
				}
				else{
					//There are dependencies
					this.dependenciesModalWithComponentEdit(payload.TagDependency);
				}
			}
			else if(this.action == 'delete'){
				if(payload.HasDependencies == 0){
					// send delete request if there are no dependencies.
					this.showDeleteConfirmation();
				}
				else{
					//Prepare dependencies list
					var items: any[] = [];
					if (payload.TagClassDependency.TagClassifications.TagClassification.constructor.name == Object.name) {
						items.push(payload.TagClassDependency.TagClassifications.TagClassification);
					} else {
						items = payload.TagClassDependency.TagClassifications.TagClassification;
					}
					// show dependencies
					this.dependenciesModalWithComponentDelete(items);
				}
			}
			else if(this.action == 'dependency'){
				var items: any[] = [];
				if (payload.TagClassDependency.TagClassifications.TagClassification.constructor.name == Object.name) {
					items.push(payload.TagClassDependency.TagClassifications.TagClassification);
				} else {
					items = payload.TagClassDependency.TagClassifications.TagClassification;
				}
				// show dependencies
				this.dependenciesModalWithComponentDelete(items);
			}
			else{
				console.log(this.action);
			}
		} else if (serviceType == WsType.GET_CLASSIFICATION_ATTRIBUTE_DEPENDENCIES_WITH_ID) {
			let items = data.payload.split("_")
			if(items[1]=="Yes")
			{
				for(let i=0; i<this.rowData.length; i++)
				{
					if(this.rowData[i].attributeId == items[0])
					{
						this.rowData[i]["dependencies"] = "disable: false"
						this.gridApi.setRowData(this.rowData);
					}
				}
			}
		}
	}

	/**
	 * Tracks unsuccessful web service calls.
	 * @param data payload
	 * @param serviceType type of the service
	 */
	onFail(data: WsResponse, serviceType: WsType) {
		if (serviceType == WsType.GET_TAG_CLASSIFICATION_ATTRIBUTE_LIST) {
			this.alertService.error(data.statusDescription);
		} else if (serviceType == WsType.GET_TAG_ATTRIBUTE_TYPES) {
			this.alertService.error(data.statusDescription);
		} else if (serviceType == WsType.TAG_ATTRIBUTE_TYPE_NAME_AVAILABILITY) {
			this.alertService.error(data.statusDescription);
		} else if (serviceType == WsType.ADD_TAG_ATTRIBUTE) {
			this.alertService.error(data.statusDescription);
		} else if (serviceType == WsType.UPDATE_TAG_ATTRIBUTE) {
			this.alertService.error(data.statusDescription);
		} else if (serviceType == WsType.DELETE_TAG_ATTRIBUTE) {
			this.alertService.error(data.statusDescription);
		} else if (serviceType == WsType.GET_TAG_DEPENDENCIES_BY_TAG) {
			this.alertService.error(data.statusDescription, false);
		}
		this.loadingService.hideLoading();
	}

	/**
	* Shows delete confirmation popup
	*/
	showDeleteConfirmation() {
		const initialState = {
			token: this.token,
			attributeId: '',
			confirmationTitle: 'Delete Confirm',
			confirmationMessage: 'Are you sure you want to delete this tag attribute?',
			callBack: this
		};
		this.bsModalRef = this.modalService.show(ConfirmationModalComponent, { initialState });
	}

	/**
	* User clicks on the delete button in the deletion confirmation popup
	*/
	onDeleteConfirm() {
		this.loadingService.showLoading(true, false, 'Deleting data', 0);
		const data_ = { userToken: this.token, id: this.deleteTagAttributeModel.attributeId, name: '', type: '' };
		this.tagService.deleteTagAttributeType(data_, this);
		this.bsModalRef.hide();
	}

	/**
	 * User clicks on the decline button in the deletion confirmation popup
	 */
	onDeleteDecline() {
		this.bsModalRef.hide()
	}

	/**
	 * navigate to tag classification screen
	 */
	public tagClassificationClick() {
		this.router.navigateByUrl('tagclassification');
	}

	/**
	 * update isAttributeTypeModified
	 * @param $event 
	 */
	onChange($event){
		this.isAttributeTypeModified = true;
	}
}
