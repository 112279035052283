
<div class="modal-header py-2">
	<h5 class="modal-title">
		{{ 'SYSTEM_ADD_EDIT.SYSTEMS' | translate }}
	</h5>
	<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>

<div class="modal-body">
	<!-- Dependency Details -->
	<div *ngIf="savedSystem!=null" class="card p-0 mx-auto col-sm-12">
		<div class="card-body py-2 mx-auto">							
			<label class="mb-1 col-sm-12">
				{{system.referenceCount}} {{ 'STATEMENT_ADD_EDIT.PLACES' | translate }},
				{{ 'STATEMENT_ADD_EDIT.IN' | translate }} {{system.dependencyCount}}
				{{ 'STATEMENT_ADD_EDIT.TOPICS' | translate }}, {{ 'STATEMENT_ADD_EDIT.IN' | translate }}
				{{system.procDependencyCount}}
				{{ 'STATEMENT_ADD_EDIT.PROCEDURES' | translate }}, {{ 'STATEMENT_ADD_EDIT.IN' | translate }}
				{{system.iceCourseDependencyCount}}
				{{ 'STATEMENT_ADD_EDIT.ICECOURSES' | translate }}
			</label>

			<label class="mb-0 col-sm-12">
				<span class="badge border-badge-danger mx-2 py-2"
					tooltip="{{ 'STATEMENT_ADD_EDIT.CHECKED_OUT' | translate }}">
					<i class="fa fa-upload mx-2 fa-lg" aria-hidden="true"></i>
					<label class="mx-2 mb-0">{{system.checkedOutTopicCount}}</label>
				</span>
				<span class="badge border-badge-success mx-2 py-2"
					tooltip="{{ 'STATEMENT_ADD_EDIT.CHECKED_IN' | translate }}">
					<i class="fa fa-download mx-2 fa-lg" aria-hidden="true"></i>
					<label class="mx-2 mb-0">{{system.checkedInTopicCount}}</label>
				</span>
			</label>
		</div>
	</div>
	<form class="mt-2">
		
		<!-- system number -->
		<div *ngIf="!reusableNo" class="form-group mb-2">
			<label for="systemNo" class="mb-0">{{ 'SYSTEM_ADD_EDIT.SYSTEM_NUMBER' | translate }}<div class="badge-required mx-2"></div></label>
			<!-- *ngIf="savedSystem!=null"-->
			<input type="text" class="form-control form-control-sm" id="systemNo" name="systemNo"
				[(ngModel)]="system.systemNo" maxlength="20">
			<!--<input #systemNo *ngIf="savedSystem==null" type="text" class="form-control form-control-sm" maxlength="20" id="systemNo" name="systemNo" [(ngModel)]="system.systemNo">-->
		</div>
		<div *ngIf="reusableNo" class="form-group mb-2">
			<label for="systemNo" class="mb-0">{{ 'SYSTEM_ADD_EDIT.SYSTEM_NUMBER' | translate }}<div class="badge-required mx-2"></div></label>
			<!-- *ngIf="savedSystem!=null"-->
			<input type="text" class="form-control form-control-sm" id="systemNo" name="systemNo" [(ngModel)]="system.systemNo">
			<!--<input #systemNo *ngIf="savedSystem==null" type="text" class="form-control form-control-sm" id="systemNo" name="systemNo" [(ngModel)]="system.systemNo">-->
			<!-- modified values & time -->
			<div class="card bg-danger my-2">
				<div class="card-body py-1 px-2">
					<p class="card-text my-1">{{reusablesNumber}}</p>
					<p class="card-text my-1">{{reusablesNoModifiedBy}}</p>
					<p class="card-text my-1">{{reusableNoModifiedTime}}</p>
				</div>
			</div>
		</div>

		<!-- system name -->
		<div *ngIf="!reusable" class="form-group mb-2">
			<label for="systemName" class="mb-0">{{ 'SYSTEM_ADD_EDIT.SYSTEM_NAME' | translate }}<div class="badge-required mx-2"></div></label>
			<!-- *ngIf="savedSystem!=null"-->
			<input type="text" class="form-control form-control-sm" id="systemName"
				name="systemName" [(ngModel)]="system.systemName">
			<!--<input #systemName *ngIf="savedSystem==null" type="text" class="form-control form-control-sm" id="systemName"
			name="systemName" [(ngModel)]="system.systemName">-->
		</div>
		<div *ngIf="reusable" class="form-group mb-2">
			<label for="systemName" class="mb-0">{{ 'SYSTEM_ADD_EDIT.SYSTEM_NAME' | translate }}<div class="badge-required mx-2"></div></label>
			<!-- *ngIf="savedSystem!=null"-->
			<input type="text" class="form-control form-control-sm" id="systemName"
				name="systemName" [(ngModel)]="system.systemName">
			<!--<input #systemName *ngIf="savedSystem==null" type="text" class="form-control form-control-sm" id="systemName"
			name="systemName" [(ngModel)]="system.systemName">-->
			<!-- modified values & time -->
			<div class="card bg-danger my-2">
				<div class="card-body py-1 px-2">
					<p class="card-text my-1">{{reusablesName}}</p>
					<p class="card-text my-1">{{reusablesModifiedBy}}</p>
					<p class="card-text my-1">{{reusableModifiedTime}}</p>
				</div>
			</div>
		</div>
		
		<!-- facility -->
		<div class="form-group mb-2">
			<label for="facilityId" class="mb-0">{{ 'SYSTEM_ADD_EDIT.FACILITY' | translate }} :</label>
			<!-- system not null -->
			<!--*ngIf="savedSystem!=null && system.facilityId!=null" -->
			<ng-select #fac (dropdownOpened)="closeOtherElems(fac)" [active]="system.selectedFacility" [allowClear]="true"
				(removed)="removedFacility($event)" (selected)="selectedFacilityId($event)" [items]="facilityList"
				placeholder="{{ 'SYSTEM_ADD_EDIT.SELECT_FACILITY' | translate }}"
				name="facilityId" id="facilityId" [disabled]="facilityDisable">
			</ng-select>
			<!-- system null -->
			<!--<ng-select *ngIf="savedSystem==null || (savedSystem!=null && system.facilityId==null)" [allowClear]="true" (removed)="removedFacility($event)"
				(selected)="selectedFacilityId($event)" [items]="facilityList"
				placeholder="{{ 'SYSTEM_ADD_EDIT.SELECT_FACILITY' | translate }}" name="facilityId" id="facilityId">
			</ng-select>-->
		</div>
		
		<!-- parent system -->
		<div class="form-group mb-2">
			<label for="systemId" class="mb-0">{{ 'SYSTEM_ADD_EDIT.PARENT_SYSTEM' | translate }} :</label>
			<!-- system not null -->
			<!-- *ngIf="savedSystem!=null && system.parentId!=null" -->
			<ng-select #parSys (dropdownOpened)="closeOtherElems(parSys)" [active]="system.selectedParentSystem" [allowClear]="true"
				(removed)="removedSystem($event)" (selected)="selectedSystemId($event)"  [items]="systemList"
				placeholder="{{ 'SYSTEM_ADD_EDIT.SELECT_SYSTEM' | translate }}" name="systemId" id="systemId" [disabled]="systemDisable">
			</ng-select>
			<div *ngIf="reusableParent" class="card bg-danger my-2">
				<div class="card-body py-1 px-2">
					<p class="card-text my-1">{{reusablesParent}}</p>
					<p class="card-text my-1">{{reusablesParentModifiedBy}}</p>
					<p class="card-text my-1">{{reusableParentModifiedTime}}</p>
				</div>
			</div>
			<!-- system null -->
			<!--<ng-select *ngIf="savedSystem==null || (savedSystem!=null && system.parentId==null)" [allowClear]="true" (removed)="removedSystem($event)" 
				(selected)="selectedSystemId($event)" [items]="systemList"
				placeholder="{{ 'SYSTEM_ADD_EDIT.SELECT_SYSTEM' | translate }}" name="systemId" id="systemId">
			</ng-select>-->
		</div>
		<div *ngIf="siblingDisplay" class="form-group mb-2">
			<label for="systemId" class="mb-0">{{ 'SYSTEM_ADD_EDIT.SIBLINGS' | translate }} :</label>
			<!-- system not null -->
			<div *ngFor="let sibling of system.siblings">
				{{sibling.systemNo}} {{sibling.systemName}}
			</div>
		</div>
		<div *ngIf="childDisplay" class="form-group mb-2">
			<label for="systemId" class="mb-0">{{ 'SYSTEM_ADD_EDIT.CHILDREN' | translate }} :</label>
			<!-- system not null -->
			<div *ngFor="let child of system.children">
				{{child.systemName}}
			</div>
		</div>
		<hr />
		<div class="form-group mb-2">
			<div class="row my-2">
				<div class="col-sm-6">
					<div class="checkbox float-left">
						<label class="customcheck">
							<p class="h6 mb-0">{{ 'SYSTEM_ADD_EDIT.USED_IN_COMMISSIONING' | translate }}</p>
							<input id="commissioning_checkbox" type="checkbox" name="isCommissioning" [(checked)]="isCommissioning"
								[(ngModel)]="isCommissioning">
							<span class="checkmark"></span>
						</label>
					</div>
				</div>
			</div>
		</div>
	</form>
</div>
<div class="modal-footer">
	<button type="button" id="facility_cancel" class="btn btn-secondary btn-sm float-left"
		(click)="cancel()">{{ 'CANCEL' | translate}}</button>
	<button type="submit" id="facility_save" class="btn btn-primary btn-sm float-left"
		(click)="save()">{{ 'SAVE' | translate}}</button>

</div>