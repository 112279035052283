<div class="modal-header py-2">
<h5 class="modal-title">{{'WORKFLOW_CONFIGURATION.WORKFLOW_CONFIGURATION' | translate}}</h5>
<button type="button" class="close pull-right" aria-label="Close" (click)="cancellClick()">
    <span aria-hidden="true">&times;</span>
 </button>
</div>

<div class="modal-body" id="configureWorkflowTemplate">
  <form>
    <fieldset>
      <div class="form-group mb-2">
        <div class="mt-2 dropdown-100 mb-2">
          <div class="form-group">
            <div class="row">
              <div class="col-md-6">
                  <label for="PM" class="mb-2">{{'WORKFLOW_CONFIGURATION.PROJECT_MANAGER' | translate}}</label>
              </div>
              <div class="col-md-6">
                  <ng-select [items]="userList" [active]="selectedProjectManager" placeholder="{{ 'WORKFLOW_CONFIGURATION.SELECT_USER' | translate }}"
                  (removed)="removePM()" [allowClear]="true" (selected)="selectPM($event)">
                  </ng-select>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div *ngIf="taskList?.length > 0" class="form-group">
            <div class="row">
              <div class="col-md-6">
                  <label for="taskName" class="mb-2">{{'WORKFLOW_CONFIGURATION.TASK_NAME' | translate}}</label>
              </div>
              <div class="col-md-6">
                  <label for="user" class="mb-2">{{'WORKFLOW_CONFIGURATION.USER' | translate}}</label>
              </div>
            </div>
          </div>
        </div>
        <div *ngFor="let item of taskList; let i as index">
          <div class="form-group">
              <div class="row">
                <div class="col-md-6">
                    <label for="taskName" class="mb-2">{{item.taskName}}</label>
                </div>
                <div class="col-md-6">
                    <ng-select [items]="userList" [active]="item.selected" placeholder="{{ 'WORKFLOW_CONFIGURATION.SELECT_USER' | translate }}"
                    (removed)="removeUser(i)" [allowClear]="true" (selected)="selectUser($event, i)">
                  </ng-select>
                </div>
              </div>
            </div>
        </div>
      </div>
    </fieldset>
  </form>
</div>
<div class="modal-footer">   
    <!--<button type="button" class="btn btn-primary" (click)="save(); bsModalRef.hide()">{{ 'Save' | translate}}</button>-->
    <button type="button" class="btn btn-primary" (click)="save()">{{ 'Save' | translate}}</button>
    <button type="button" class="btn btn-secondary" (click)="cancellClick()">{{ 'CANCEL' | translate}}</button>
 </div>
    