import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { OutputManagementService } from '../outputmanagement.service';
import { UserVariable } from '../../../util/common/user-variable';
import { AlertService } from '../../../util/alert/alert.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from '../../../util/loading/loading.service';

@Component({
   selector: 'editoutputcnfig-modal',
   template: `
   <div class="modal-header">
   <h4 class="modal-title pull-left">{{title}}</h4>
   <button type="button" class="close pull-right" aria-label="Close" (click)="cancellClick()">
      <span aria-hidden="true">&times;</span>
   </button>
</div>
<div class="modal-body">
   <form>     
      <div class="form-group row">
         <label for="configName" class="col-sm-2 col-form-label">{{ 'OUTPUT_MANAGEMENT.CONFIG_NAME' | translate }}
         <div class="badge-required mx-2"></div>
         </label>         
         <div class="col-sm-10">
            <input type="text" class="form-control" id="OM_configName"
            [(ngModel)]="configName" name="configName">
         </div>
      </div>
      <div class="form-group row">
      <label for="outputconfigxml" class="col-sm-2 col-form-label">{{ 'OUTPUT_MANAGEMENT.CONFIG_XML' | translate }}
      <div class="badge-required mx-2"></div>
      </label>         
         <div class="col-sm-10">
            <textarea type="text" class="form-control" id="OM_outputconfigxml" name="configxml" [(ngModel)]="configXML"></textarea>
         </div>
   </div>
   </form>
</div>
<div class="modal-footer">   
   <button type="button" class="btn btn-primary" (click)="save()">{{ 'Save' | translate}}</button>
   <button type="button" class="btn btn-secondary" (click)="cancellClick()">{{ 'CANCEL' | translate}}</button>
</div>
   `
})
export class EditOutputConfigModalComponent {
   public title: string;
   om_translated_data: any = null;
   public loadingMessage: string;
   public configName: string;
   public id: String;
   public configXML: string;

   constructor(public bsModalRef: BsModalRef, private outputManagementService: OutputManagementService, private alertService: AlertService, private router: Router,
      private translate: TranslateService, private loadingService: LoadingService) { }

   ngOnInit() {

   }

   public save() {
      if (this.configName == "") {
         this.alertService.error("Config name can not be empty.")
      }else if(this.configXML == ""){
         this.alertService.error("Config XML can not be empty.")
      }else{
         this.alertService.clear()
         var editedVal = {
            "id": this.id,
            "configName": this.configName,
            "configXML": this.configXML
         }
         if(this.title == "Edit Configuration"){
            this.loadingService.showLoading(true, null, this.loadingMessage, null)
            this.outputManagementService.updateOutputConfig(editedVal)
            .subscribe(data => {
               this.bsModalRef.hide()
               this.loadingService.hideLoading()
               location.reload()
            },
               error => {
                  this.loadingService.hideLoading()
                  this.alertService.clear()
                  this.alertService.error(error.statusDescription)
               });

         }else{
            this.loadingService.showLoading(true, null, this.loadingMessage, null)
            this.outputManagementService.addOutputConfig(editedVal)
            .subscribe(data => {
               this.bsModalRef.hide()
               this.loadingService.hideLoading()
               location.reload()
            },
               error => {
                  this.loadingService.hideLoading()
                  this.alertService.clear()
                  this.alertService.error(error.statusDescription)
               });
         }         
      }
   }

   public cancellClick() {
      this.alertService.clear()
      this.bsModalRef.hide()
   }

}