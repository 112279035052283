import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FilesService } from "../../home/files/files.service";
import { WsCallback } from "../../util/ws-callback.interface";
import { WsResponse } from '../../util/ws-response.model';
import { WsType } from '../../util/ws-type';
import { AlertService } from '../../util/alert/alert.service';
import { SharedService } from '../shared.service';
import { LoadingService } from '../loading/loading.service';

@Component({
	selector: 'app-xmlmodel',
	templateUrl: './xmlmodel.component.html',
	styleUrls: ['./xmlmodel.component.css']
})
export class XmlmodelComponent implements OnInit {

	title: string;
	closeBtnName: string;
	actionBtn: string;
	data: any;
	itemId: any;
	cmRevisionNo: any;
	itemType: any;

	constructor(public bsModalRef: BsModalRef, private filesService: FilesService, private alertService: AlertService, private sharedService: SharedService, private loadingService: LoadingService) { }

	ngOnInit() {
	}

	confirm() {

		if (this.itemType == 'ICECourse') {
			this.loadingService.showLoading(true, false, "Revision restoring", 100);
			this.filesService.restoreICECourseRevision(this.itemId, this.cmRevisionNo, this);
		} else {
			this.loadingService.showLoading(true, false, "Revision restoring", 100);
			this.filesService.setCallback(this.sharedService.getRevisionParent());
			this.filesService.restoreRevision(this.itemId, this.itemType, this.cmRevisionNo)
		}
	}

	onSuccess(data: WsResponse, serviceType: WsType): void {
		console.log("fail serviceType= " + serviceType)
		console.log("^^^^^^^^^^^^^^^^^^^^^^^", JSON.stringify(data))
		// if( this.itemType != 'ContentItem' && this.itemType != 'Image')
		// {
		// 	if( WsType.RESTORE_REVISION === serviceType )
		// 	{
		// 		let fildData = { id : this.itemId, type: this.itemType }
		// 		this.filesService.getFileDataByFileId(fildData, this)
		// 		this.alertService.clear();
		// 		this.alertService.success("Successfully restored");
		// 		if(this.itemType == 'TopicMap'){
		// 			console.log("add new entry to model")
		// 			console.log("filesService.getFileDataByFileId= ")
		// 		}
		// 	}
		// 	else if( WsType.FILE_DATA_VIEW == serviceType )
		// 	{
		// 		console.log("+++++++else if( WsType.FILE_DATA_VIEW == serviceType ) ")
		// 		let date = new Date(data.payload["lastUpdateTimeStamp"]).toLocaleString() != "Invalid Date" ? new Date(data.payload["lastUpdateTimeStamp"]).toLocaleString() : data.payload["lastUpdateTimeStamp"]
		// 		this.filesService.loadFiles( date, this.itemId , "lastUpdateTimeStamp");
		// 	}
		// }
		// else{
		// 	 let item = data.payload["1"][0]["revisionsList"][0];
		// 	 this.filesService.addNewRow(item);
		// }

	}

	onFail(res: WsResponse, serviceType: WsType): void {
		console.log("fail serviceType= " + serviceType)
		this.alertService.clear()
		this.alertService.error(res.statusDescription);
	}
}
