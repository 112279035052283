<div class="modal-body py-2">	
	<div id="documenttypetree" class="mt-2 ml-1"></div>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-primary btn-sm" (click)="selectDoctype()">
		Select
	</button>
	<button type="button" class="btn btn-secondary btn-sm" (click)="closeButtonClickEvent()">
		Close
	</button>
</div>