import { Component, OnInit, EventEmitter } from '@angular/core';
import { WsResponse } from '../../util/ws-response.model';
import { WsType } from '../../util/ws-type';
import { AlertService } from '../../util/alert/alert.service';
import { CategoryTreeService } from '../categorytree/categorytree.service';
import { SharedService } from '../shared.service';
declare var $: any;

@Component({
	selector: 'app-select-category-tree',
	templateUrl: './select-category-tree.component.html',
	styleUrls: ['./select-category-tree.component.css'],
	inputs: ['categoryType', 'selectedCategoryItem', 'showIncludeChildren'],
	outputs: ['categorySelected', 'closeModal']
})
export class SelectCategoryTreeComponent implements OnInit {

	categoryType: string;
	selectedCategoryItem: any;
	showIncludeChildren:boolean;
	rowData: any = [];
	subCategoryList: any = [];
	categoryName: any = [];
	public data_n: any = {
		'data': [
		]
	}
	categorySelected = new EventEmitter<any>()
	closeModal = new EventEmitter<any>()
	sc: any = null

	constructor(private categoryTreeService: CategoryTreeService, private alertService: AlertService, ) { }

	ngOnInit() {
		// console.log('[ngOnInit] (SelectCategoryTreeComponent)');
		if (this.categoryType != null) {
			// console.log('[ngOnInit] (this.categoryType) ' + JSON.stringify(this.categoryType));
			if (this.categoryType == "comp_category") {
				this.categoryTreeService.getCategoriesComponent(this);
			} else if (this.categoryType == "state_category") {
				this.categoryTreeService.getCategories(this);
			} else if (this.categoryType == "chk_category") {
				this.categoryTreeService.getChecksCategories(this);
			}
		} else {
			// console.log('[ngOnInit] (this.categoryType) ' + 'null');
		}

		if (this.selectedCategoryItem != null) {
			console.log('[ngOnInit] (this.selectedCategoryItem) ' + JSON.stringify(this.selectedCategoryItem));
		} else {
			console.log('[ngOnInit] (this.selectedCategoryItem) ' + JSON.stringify(this.selectedCategoryItem));
		}

		SharedService.setStateCheck("false");
	}

	checkBoxEvent(event: any) {
		console.log('event', event.target.checked);
		let check = event.target.checked.toString();
		console.log('check', check);
		SharedService.setStateCheck(check);
	}

	loadCategoryTree(payload: any) {	
		if (payload[0].categoryType === 'Component' || payload[0].categoryType === 'Check') {
			this.showIncludeChildren=false;
		} else if (payload[0].categoryType === 'Statement') {
			this.showIncludeChildren=true;
		}
		if (payload != null) {
			 console.log('payload', payload);
			this.data_n['data'] = [
			];

			this.data_n["data"].push({ "id": "0", "parent": "#", "text": payload[0].categoryType, icon: "fal fa-clipboard", type: "Statement", state: {disabled: true}});
			for (let i = 0; i < payload.length; i++) {
				this.data_n["data"].push({ 
				"id": payload[i]["categoryId"], 
				"parent": payload[i]["parentId"], 
				"text": payload[i]["categoryName"], 
				icon: payload[i]["status"] == "Inactive" ? "fas fa-clipboard" : "fal fa-clipboard", 
				type:  payload[i]["categoryType"] ,
				state : payload[i]["status"] == "Inactive" ? { "disabled" : true } : { "disabled" : false }
			})
			}
			// console.log('[loadCategoryTree] (this.data_n)' + JSON.stringify(this.data_n["data"]))
			$('#categorytree').jstree({
				core: {
					themes: {
						variant: 'normal'
					},
					data: this.data_n["data"],
					check_callback: true,
					force_text: true
				},
				types: {
					default: {
						draggable : false
						// "icon" : "fa fa-file-o "
					}
				},
				plugins: ["contextmenu", "dnd", "search", "state", "types", "wholerow"],
				search: {
					show_only_matches: true,
					show_only_matches_children: true
				},
				ui: {
					select_limit: 1,
					select_prev_on_delete: false,
				}
			});

			// $('#categorytree').bind("select_node.jstree", (evt: any, data: any) => this.categorySelectEvent(evt, data))
			$('#categorytree').bind("changed.jstree", (node: any, action: any, selected: any, event: any) => 
			this.categoryChanged(node, action, selected, event))
			// $('#categorytree').bind("loaded.jstree", function (event, data) {
			// 	$(this).jstree("open_all");
			// })
			$('#categorytree').bind("loaded.jstree", (event: any, data: any) => this.jsTreeLoaded(event, data))
			$('#categorytree').bind("ready.jstree", (event: any, data: any) => this.jsTreeReady())
			$('#categorytree').bind("init.jstree", (event: any, data: any) => this.jsTreeInit())
			
		} else {
			//do nothing
			//payload == null
		}
		
	}

	public categorySelectEvent(evt: any, data: any) {
		// console.log('[categoryName/categoryId] ' + JSON.stringify(data.node.text) + '/' + JSON.stringify(data.node.id));
	}

	private categoryChanged(node: any, action: any, selected: any, event: any) {
		console.log('[categoryChanged] (action) ' + JSON.stringify(action.action));
		// console.log(action)
		if(action.action == 'select_node') {
			this.sc = {
				catName: action.node.text,
				catId: action.node.id
			}
		}
	}

	onSuccess(data: WsResponse, serviceType: WsType): void {
		if (data != null && WsType.CATEGORY_VIEW == serviceType) {
			this.loadCategoryTree(data.payload);
			// console.log('dataaaa', data);
		}
	}

	onFail(res: WsResponse, serviceType: WsType): void {
		if (res != null && WsType.CATEGORY_VIEW == serviceType) {
			if (res.statusCode == "600") {
				this.categoryTreeService.getCategories(this);
			} else {
				this.alertService.error(res.statusDescription, false);
			}
		}
	}

	private jsTreeInit() {
		console.log('[jsTreeInit]');
	}

	private jsTreeReady() {
		$('#categorytree').jstree(true).deselect_all(true);
		console.log('[jsTreeReady]');		
		this.highlightSelectedNode();
	}

	private jsTreeLoaded(event: any, data: any) {
		console.log('[jsTreeLoaded]');
		// console.log(data);
		$('#categorytree').jstree("open_all");
	}

	public highlightSelectedNode() {
		console.log("this.selectedCategoryItem====" + this.selectedCategoryItem);
		let jsT = $('#categorytree').jstree(true).get_json('#', {flat:true})
		let selectedNode = null
		jsT.forEach((node: any) => {
			$('#categorytree').jstree(true).deselect_node([node], true)
		});
		if (this.selectedCategoryItem != null) {
			selectedNode = jsT.find((i: { id: any; }) => i.id == this.selectedCategoryItem.catId)
			if(selectedNode != undefined) {
				$('#categorytree').jstree('select_node', selectedNode.id);
			}
		} else {
			console.log("No Selected Category.");
		}
		
	}

	//select category button click event
	public selectCategory() {
		if (this.sc != null) {
			$('#categorytree').jstree().destroy(true);
			this.categorySelected.emit({
				catName: this.sc.catName,
				catId: this.sc.catId
			});
			this.selectedCategoryItem = this.sc;
			this.sc = null;
		} else {
			this.alertService.error('Please select a category.');
		}
		
	}

	public closeButtonClickEvent() {
		$('#categorytree').jstree().destroy(true);
		this.sc = null;
		this.selectedCategoryItem = null;
		this.closeModal.emit();
	}

}
