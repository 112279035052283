import { Component, OnInit, ElementRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FloatingButtonCallback } from '../floating-button-callback.interface';
import { TreeListener } from '../../../util/documenttypetree/tree-listener.interface';
import { QueryList } from '@angular/core';
import { DocumenttypetreeComponent } from '../../../util/documenttypetree/documenttypetree.component';
import { WsCallback } from '../../../util/ws-callback.interface';
import { WsType } from '../../../util/ws-type';
import { WsResponse } from '../../../util/ws-response.model';
import { NewProcedureService } from './new-procedure.service';
import { AlertService } from '../../../util/alert/alert.service';
import { LocalStorage } from '../../../util/localstorage.service';
import { CustomTypeaheadComponent } from '../../../util/custom-typeahead/custom-typeahead.component';
import { LoadingService } from '../../../util/loading/loading.service';
import { TranslateService } from '@ngx-translate/core';
import { UserVariable } from 'src/app/util/common/user-variable';
import { DocumentTypeService } from 'src/app/util/documenttypetree/documenttype.service';
import { CommonUtil } from 'src/app/util/common/common-util';

@Component({
  selector: 'app-new-procedure',
  templateUrl: './new-procedure.component.html',
  styleUrls: ['./new-procedure.component.css']
})
export class NewProcedureComponent implements OnInit, TreeListener, WsCallback {
  public title: string;
  private selectedFolder: any;
  private floatingButtonCallback: FloatingButtonCallback;
  public systemList: any;
  public outputConfigList: any;
  public selectedSystem: any;
  public selectedOutputConfig: any;
  public systemsLength:Number = 0;

  private selectedSystemItemId: string;
  private selectedConfigId: string;
  public procedureName: string;
  public procedureId: string = '';
  public procedureType: string;
  public haveProperties:boolean = true;
  public attributeList: any;

  public folderName: string;
  //Local variables
  private treeBsModalRef: BsModalRef;

  constructor(public bsModalRef: BsModalRef, private modalService: BsModalService, public procedureService: NewProcedureService,
    private alert: AlertService, private loadingService: LoadingService, private translate: TranslateService, private documentTypeService: DocumentTypeService, private commonUtil: CommonUtil) {
    //Loading system list
    let token = UserVariable.getUserToken();
    if(!this.commonUtil.validateToken(token)){
            return;
        }
    var request = { "userToken": token };
    procedureService.getSystemList(JSON.stringify(request), this);
  }

  ngOnInit() {
    if (this.selectedFolder != undefined) {
      this.folderName = this.selectedFolder.text;
    }
   }

  onSaveButtonClick(file: any, texts: QueryList<ElementRef>, options: QueryList<ElementRef>, documentTypeAttributes: any) {
    this.documentTypeService.getDocumentTypeAttributes(file.node.id, this.selectedFolder, this);
    this.procedureType = file.node.text;
    this.procedureId = file.node.id;
    this.treeBsModalRef.hide();
  }

  public onProcedureTypeFocus() {
    const initialState = {
      title: 'Select a Document Type',
      treeListener: this,
      initial: true
    };
    this.treeBsModalRef = this.modalService.show(DocumenttypetreeComponent, { initialState, class: 'modal-lg' });
    this.treeBsModalRef.content.closeBtnName = 'Close';
  }

  public onSelectSystemItem(event) {
    if (event.value.item != undefined) {
      this.selectedSystemItemId = event.value.item.id;
    }
  }

  public onSelectConfigItem(event) {
    if (event.value.item != undefined) {
      this.selectedConfigId = event.value.item.id;
    }
  }

  onSubmitButtonClick() {
	 // this.validateForm()
     if (!this.validateForm()) {
		  // this.alert.error('Invalid form data');
       return;
    }

    var loadingMessage;
    this.translate.get('NEW_PROCEDURE.LOADING_MESSAGE').subscribe((res: string) => {
			loadingMessage = res;
		});
    this.loadingService.showLoading(true, null, loadingMessage, null);

    var attributeValueXml: string = '';
    //creating attribute xml string.
    if (this.attributeList != undefined && this.attributeList.length != undefined && this.attributeList.length > 0) {
      attributeValueXml = '<attrbutes>';
      this.attributeList.forEach(item => {
        attributeValueXml += "<attrVal typeAttrId='" + item.attributeId + "' value='" + item.value + "'></attrVal>";
      });
      attributeValueXml += '</attrbutes>';
    }
    let token = UserVariable.getUserToken();
    if(!this.commonUtil.validateToken(token)){
        return;
    }
    var request = {
      "userToken": token, "procedureName": this.procedureName,
      "procedureTypeId": this.procedureId,
      "systemId": (this.selectedSystemItemId != undefined ? this.selectedSystemItemId[0] : ''),
      "outputConfigId": (this.selectedConfigId != undefined ? this.selectedConfigId[0] : ''),
      "description": (this.procedureName != undefined ? this.procedureName : ''),
      "folderId": this.selectedFolder.id,
      "attributeValueXml": attributeValueXml
    };

    this.procedureService.addProcedure(JSON.stringify(request), this);
  }

  validateForm() {
    if (this.procedureName == undefined || this.procedureName.trim() == "") {
      this.alert.error('Procedure Name cannot be empty');
      return false;
    }
    else if (this.procedureType == undefined) {
      this.alert.error('Procedure Type cannot be empty');
      return false;
    }
    //Below are optional fields
    // if (this.selectedConfigId == undefined) {
    //   return false;
    // }
    else if (this.selectedSystemItemId == undefined || this.selectedSystemItemId == "") {
      this.alert.error('Procedure System cannot be empty');
      return false;
    }

    //Check for attribute values
    if (this.attributeList != undefined && this.attributeList.length != undefined && this.attributeList.length > 0) {
      var isEmptyAttribute: boolean = false;
      this.attributeList.forEach(item => {
        if (item.required == 'yes' && (item.value == null || item.value == "")) {
					this.alert.error(this.translate.instant('NEW_PROCEDURE.ATTRIBUTE.EMPTY_ATTRIBUTE_ERROR_MESSAGE'));
          isEmptyAttribute = true;
        }
        // if(item.attributeName == 'Document'){
        //   if(item.documents != undefined){
        // isEmptyAttribute = false;
        //   }
        // } else if(item.attributeName == 'c1'){
        //   if(item.components != undefined){
        //   isEmptyAttribute = false;
        //   }
        // } else if(item.attributeName == 'System'){
        //   if(item.systems != undefined){
        //   isEmptyAttribute = false;
        //   }
        // }
      });
      if (isEmptyAttribute) {
        return false;
      }
    }
    return true;
  }

  onSuccess(data: WsResponse, serviceType: WsType) {
    if (serviceType == WsType.DOCUMENT_TYPE_ATTRIBUTES_VIEW) {
      this.attributeList = data.payload;
      if (data.payload.length == 0) {
        this.haveProperties = false;
      } else {
        this.haveProperties = true;
      }
    } else if (serviceType == WsType.GET_SYSTEM_LIST) {
      this.systemList = JSON.parse(data.payload);
      this.systemsLength = this.systemList.length;
      //Loading system list
      let token = UserVariable.getUserToken();
      if(!this.commonUtil.validateToken(token)){
          return;
      }
      var request = { "userToken": token };
      this.procedureService.getOutputConfigList(JSON.stringify(request), this);
    } else if (serviceType == WsType.GET_OUTPUT_CONFIG_LIST) {
      this.outputConfigList = JSON.parse(data.payload);
    } else if (serviceType == WsType.ADD_PROCEDURE) {
		this.alert.clear();
      this.alert.success('New procedure added successfully');
      this.floatingButtonCallback.hide();
      this.loadingService.hideLoading();
    }
  }
  onFail(data: WsResponse, serviceType: WsType) {
    if (serviceType == WsType.GET_SYSTEM_LIST) {
      this.alert.error(data.statusDescription);
    } else if (serviceType == WsType.GET_OUTPUT_CONFIG_LIST) {
      this.alert.error(data.statusDescription);
    } else if (serviceType == WsType.ADD_PROCEDURE) {
      this.alert.error(data.statusDescription);
      this.loadingService.hideLoading();
    }
  }

  checkComponent(type:any, name:any){
		if(type == 'Component' || name == 'c1' ){
		   return true;
	   }
   }

   checkSystem(type:any, name:any){
		 if(type == 'System' || name == 'System' ){
		   return true;
	   }
   }
   checkDocument(type:any, name:any){
		 if(type == 'Document' || name == 'Document'){
		   return true;
	   }
   }
}
