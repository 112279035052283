import { Component, OnInit, EventEmitter } from '@angular/core';
import { SharedService } from 'src/app/util/shared.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
	outputs: ['selectSideBarItem']
})
export class SidebarComponent implements OnInit {
  
	public selectedMenuItem: string = 'punchlists'
	selectSideBarItem = new EventEmitter<any>()
	private subsVar: Subscription
	isCSCollapsed: boolean = false

	constructor(private sharedService: SharedService) { }

	ngOnInit() {
	}

	ngAfterContentInit(){
		this.subsVar = this.sharedService.viewSideBarResponse().subscribe(
			(options) => {				
				this.sideItemClick(options.item)		
			}
		)
	}

	// public sideItemClick(selectedItem: string) {
	// 	console.log('[sideItemClick] (selectedItem) ' + selectedItem);
	// 	console.log('==========='+selectedItem+'=============')
	// 	if (selectedItem == "punchlists") {
	// 		selectedItem = "punchlist";
	// 		console.log(selectedItem)
	// 	} else {
	// 		this.sharedService.setFileId("-1");
	// 	}
	// 	this.selectedMenuItem = selectedItem
	// 	this.sharedService.viewSideBarEventEmit("punchlists")
	// }
	public sideItemClick(selectedItem: string) {
		if(selectedItem == 'checksheets') {
			this.isCSCollapsed = !this.isCSCollapsed;
		}
		this.selectedMenuItem = selectedItem
		this.selectSideBarItem.emit(
			{
				selectedItem: selectedItem
			}
		)
	}

 }
