import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {BsDatepickerConfig} from "ngx-bootstrap/datepicker";
import {AlertService} from "../../../util/alert/alert.service";
import {TranslateService} from "@ngx-translate/core";
import {LoadingService} from "../../../util/loading/loading.service";
import {SharedService} from "../../../util/shared.service";
import {ManagemccService} from "../managemcc.service";
import {WsCallback} from "../../../util/ws-callback.interface";
import {WsResponse} from "../../../util/ws-response.model";
import {WsType} from "../../../util/ws-type";
import {DatePipe} from "@angular/common";
import {UserVariable} from "../../../util/common/user-variable";

@Component({
  selector: 'app-editmcc',
  templateUrl: './editmcc.component.html',
  styleUrls: ['./editmcc.component.css']
})
export class EditmccComponent implements OnInit {
  @ViewChild("editMCModal") editMCModal: TemplateRef<any>;
  public editModalRef: BsModalRef;
  public mccId : string;
  public systemName: string;
  public systemId : string;
  mccStatus : String;
  targetCompletionDate : any;
  required :boolean;
  mcVendorManaged :boolean;
  mcstatus:any;
  showRequiredErr : boolean
  selectedItem :any;
  public bsConfig: Partial<BsDatepickerConfig>;
  hide : boolean = false;
  constructor(public bsModalRef: BsModalRef,
              private alertService: AlertService,
              private translate: TranslateService,
              private loadingService: LoadingService,
              private sharedService:SharedService,
              private bsModalService: BsModalService,
              private managemccService:ManagemccService) {
    translate.addLangs(["es", "en"]);
    translate.setDefaultLang("en");
    let browserLang = translate.getBrowserLang();
    this.bsConfig = Object.assign({}, { containerClass: 'theme-default' });
  }

  ngOnInit() {
    if(this.mccId){
      this.loadingService.showLoading(false, false, "loading MC", 0)
      this.managemccService.getMcByMcId(this.mccId,this)
    }else{
      this.selectedItem = {

      }
      this.targetCompletionDate = null
      this.required = false;
      this.mcVendorManaged = false;

      this.mccStatus = "NotIssued"
    }
    // let selectedMCC = this.managemccService.getMCCById(this.mccId);
    //     // console.log(selectedMCC);
    //     // this.targetCompletionDate = new Date(selectedMCC.targetCompletionDate)
    //     // this.system = selectedMCC.system;
    //     // this.required = selectedMCC.required;
    //     // this.mccStatus = selectedMCC.mccStatus;
    //     // this.mcstatus = selectedMCC.mccStatus;
  }
  cancel(){
    this.bsModalRef.hide()
  }
  save(){

    let data ={
      mcId : this.mccId,
      strCompletionDate : new DatePipe("en-US").transform(new Date(this.targetCompletionDate), 'yyyy-MM-dd'),
      mcstatus : "Issued",
      mcRequired : this.required,
      mcVendorManaged : this.mcVendorManaged


    }
    console.log(data);
    this.managemccService.updateMCData(data,this)
  }

  onFail(data: WsResponse, serviceType: WsType) {
    if(serviceType == WsType.UPDATE_MC_DATA){
      this.alertService.error(data.statusDescription)
    }
  }

  onSuccess(data: WsResponse, serviceType: WsType) {
    if(serviceType == WsType.UPDATE_MC_DATA){
      if(data.payload){
        console.log(data.payload)
      }
      this.alertService.success("Sucessfully updated MCC")
      this.bsModalRef.hide()
    }else if(serviceType == WsType.GET_MC_BY_ID){
      this.loadingService.hideLoading();
      if(data.payload){
        this.selectedItem = data.payload;
        this.targetCompletionDate = new Date(this.selectedItem.targetCompletionDate)
        this.required = this.selectedItem.mcRequired;
        this.mccStatus = this.selectedItem.mcstatus;
        this.mcVendorManaged = this.selectedItem.mcVendorManaged;
      }
    }
  }

  saveMC(): void {
    if (this.mccId) {
      if (this.isInputValid()) {
        this.showRequiredErr = false
        if (this.selectedItem.mcRequired && !this.required) {
          this.editModalRef = this.bsModalService.show(this.editMCModal, {  backdrop: 'static', keyboard: false });
          this.hide =true;
        } else {
          this.loadingService.showLoading(false, false, '', 0)
          if (!this.selectedItem.mcstatus) {
            this.selectedItem.mcstatus = "NotIssued"
          }
          if (this.selectedItem.mcstatus == "Not Issued") {
            this.selectedItem.mcstatus = "NotIssued"
          }
          this.selectedItem.mcRequired = this.required
          delete this.selectedItem['parentSystemId']
          let data  = {
            mcId : this.selectedItem.mcId,
            mcstatus :this.selectedItem.mcstatus,
            strCompletionDate : new DatePipe("en-US").transform(new Date(this.targetCompletionDate), 'yyyy-MM-dd'),
            mcRequired : this.selectedItem.mcRequired,
            mcVendorManaged : this.mcVendorManaged
          }
          // this.selectedItem.strCompletionDate = new DatePipe("en-US").transform(new Date(this.targetCompletionDate), 'yyyy-MM-dd')
          this.managemccService.updateMechanicalCompletion(data)
              .subscribe(data => {
                this.loadingService.hideLoading()
                this.alertService.success("Sucessfully updated MCC")
                this.bsModalRef.hide()
                this.managemccService.loadTableDataRequest()
              }, error => {
                this.loadingService.hideLoading()
                this.alertService.error(error.statusDescription);
              })
        }
      } else {
        this.showRequiredErr = true
        if(this.showRequiredErr){
          this.alertService.error("Fill all required fields");
        }
      }

    } else {
      if (this.isInputValid()) {
        this.loadingService.showLoading(false, false, '', 0)
        this.showRequiredErr = false
        this.selectedItem = {
          mcstatus:"NotIssued",
          mcRequired :this.required,
          strCompletionDate : new DatePipe("en-US").transform(new Date(this.targetCompletionDate), 'yyyy-MM-dd'),
          projectId : UserVariable.projectId,
          systemId : this.systemId,
          mcVendorManaged : this.mcVendorManaged
        }
        // this.selectedItem.mcstatus = "NotIssued"
        // this.selectedItem.mcRequired = this.required
        // this.selectedItem.strCompletionDate = new DatePipe("en-US").transform(new Date(this.targetCompletionDate), 'yyyy-MM-dd')
        // this.selectedItem.projectId = UserVariable.projectId;
        this.managemccService.addMechanicalCompletion(this.selectedItem)
            .subscribe(data => {
              this.loadingService.hideLoading()
              this.alertService.success("Successfully Added the MCC")
              this.bsModalRef.hide()
              this.managemccService.loadTableDataRequest()
            }, error => {
              this.loadingService.hideLoading()
              this.alertService.error(error.statusDescription);
            })
      } else {
        this.showRequiredErr = true
        if(this.showRequiredErr){
          this.alertService.error("Fill all required fields");
        }
      }
    }
  }
  deleteMCC() {
    this.editModalRef.hide();
    // this.eventType = WIDGET_EVENT.SHOW_TABLE
    this.loadingService.showLoading(false, false, '', 0)
    this.managemccService.deleteMechanicalCompletion(this.selectedItem.mcId)
        .subscribe(
            data => {
              this.loadingService.hideLoading()
              this.bsModalRef.hide()
              this.alertService.success("Successfully Deleted the MCC")
              this.managemccService.loadTableDataRequest()
              // this.refresh()
            },
            error => {
              this.loadingService.hideLoading()
              this.alertService.clear()
              this.alertService.error(error.statusDescription)
            });
  }
  isInputValid() {
    if (!this.selectedItem.mcstatus) {
      if (this.targetCompletionDate && this.required) {
        if(this.targetCompletionDate == 'Invalid Date'){
          return false
        }else{
          return true
        }
      } else {
        return false
      }
    } else {
      if (this.selectedItem.mcRequired && !this.required) {
        if (this.targetCompletionDate) {
          return true
        } else {
          return false
        }
      } else {
        if (this.targetCompletionDate && this.required) {
          if(this.targetCompletionDate == 'Invalid Date'){
            return false
          }else{
            return true
          }
        } else {
          return false
        }
      }
    }
  }

  hideDeleteConfirm(){
    this.editModalRef.hide();
    this.hide = false;
  }

}
