import { Component, OnInit, TemplateRef, ViewChild, EventEmitter } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { LocalStorage } from '../../util/localstorage.service';
import { LoggedUser } from '../../user/logged-user.model';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '../../util/alert/alert.service';
import { SharedService } from '../../util/shared.service';
import { ComHeaderService } from './com-header.service';
declare var require: any;
const { version: appVersion } = require('../../../../package.json');
import { UserVariable } from '../../util/common/user-variable';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CapabilityService } from '../../util/capability.service';
import { WsResponse } from '../../util/ws-response.model';
import { WsType } from '../../util/ws-type';
import { WsCallback } from '../../util/ws-callback.interface'

@Component({
	selector: 'app-com-header',
	templateUrl: './com-header.component.html',
	styleUrls: ['./com-header.component.css'],
	inputs: ['showHamburger', 'minimalOption'],
	outputs: ['showHideSideBar']
})
export class ComHeaderComponent implements OnInit {
	//inputs
	public showHamburger: boolean = false
	public minimalOption: boolean = false

	//outputs
	showHideSideBar = new EventEmitter<any>()

	appVersion = "";
	public userFullName: String = "";
	public loggedInUser: LoggedUser;
	public isToggleMenu: boolean = false
	public notificationCount: number = 0
	isOpen = false;
	public clientId: string = localStorage.getItem(LocalStorage.CLIENT_ID)
	public projectType: any = sessionStorage.getItem(LocalStorage.PROJECT_TYPE); //localStorage.getItem(LocalStorage.PROJECT_TYPE);
	public projectId: string = localStorage.getItem(LocalStorage.PROJECT_ID)
	public defaultProjectId: string = localStorage.getItem(LocalStorage.DEFAULT_PROJECT_ID);
	public projectName: string = localStorage.getItem(LocalStorage.PROJECT_NAME)
	public serverVersion: String = ''
	bsModalRef: any;
	DashBData: any[];
	ActionsData: any[];
	DashBPos: any[];
	public searchParams: any = []
	user = {};
	placeholderString = 'Select timezone';
	timezone: string
	timezoneString: any;
	lang: any;
	previousePreference: string;
	currentPreference: string;
	public isShowSearch: boolean = false
	private vieProcedureStore: boolean = false;
	private vieOutputManagment: boolean = false;
	public setDefaultBtnText: string = '';
	public defaultBtnIcon: boolean = true;

	changeTimezone(timezone) {
		console.log(timezone)
		//   this.user.timezone = timezone;
	}

	constructor(private router: Router, private translate: TranslateService, private comheaderService: ComHeaderService, private alertService: AlertService
		, private sharedService: SharedService, private modalService: BsModalService, private capabilityService: CapabilityService) {
		this.loggedInUser = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER));
		this.userFullName = this.loggedInUser.firstName + " " + this.loggedInUser.lastName;
		this.appVersion = appVersion;
		//console.log('[HeaderComponent] (defaultProjectId) ' + this.defaultProjectId);
		//console.log('[HeaderComponent] (projectId) ' + this.projectId);
		if (this.defaultProjectId == this.projectId && this.defaultProjectId) {
			this.translate.get('HEADER.MENU_REMOVE_DEFAULT').subscribe((res: string) => {
				this.setDefaultBtnText = res;
				this.defaultBtnIcon = false
			});
		} else {
			this.translate.get('HEADER.MENU_SET_AS_DEFAULT').subscribe((res: string) => {
				this.setDefaultBtnText = res;
				this.defaultBtnIcon = true
			});
		}


		this.comheaderService.getServerVersion()
			.subscribe(data => {
				this.serverVersion = data.payload;

			})
		this.vieProcedureStore = this.capabilityService.isCapabilityAssigned("setAvailabilityProcedureStore");
		this.vieOutputManagment = this.capabilityService.isCapabilityAssigned("setAvailabilityProcedureStore");
	}

	ngOnInit() {
		this.getNotificationCount();
		//this.refreshData();
	}

	ngAfterContentInit() {

		this.sharedService.tnExecute().subscribe(data => this.notificationToggle())

	}

	// refreshData() {
	// 	this.notificationCount = JSON.parse(localStorage.getItem(LocalStorage.NOTIFICATION_COUNT));
	// 	this.comheaderService.getNotificationCount()
	// 		.subscribe(data => {
	// 			this.notificationCount = data.payload;

	// 		})
	// }

	public changeProject(): void {
		localStorage.setItem(LocalStorage.OLD_PROJECT_ID, this.projectId)
		localStorage.setItem(LocalStorage.OLD_PROJECT_NAME, this.projectName)
		localStorage.setItem(LocalStorage.OLD_CLIENT_ID, this.clientId)
		localStorage.setItem(LocalStorage.CURRENT_PROJECT_ID, this.projectId)

		localStorage.removeItem(LocalStorage.PROJECT_ID);
		localStorage.removeItem(LocalStorage.PROJECT_NAME);
		localStorage.removeItem(LocalStorage.CLIENT_ID);

		let navigationExtras: NavigationExtras = {
			queryParams: {
				'option': 'change_project'
			}
		}
		this.router.navigate(['landing'], navigationExtras)
		// console.log("changed")
	}

	public notificationToggle() {
		this.isOpen = false;
		this.getNotificationCount();
		//this.refreshData();
	}

	public signOutBtnClickEvent(): void {
		//this.router.navigateByUrl('/login')
		//clean the local storage
		//localStorage.removeItem(LocalStorage.LOGGED_USER);
		//localStorage.removeItem(LocalStorage.REQUESTED_PATH);
		//Remove everything from the local storage, now developers can logout and test.
		localStorage.clear();
		//sessionStorage.clear();
		this.router.navigateByUrl('/login');
	}

	public showToggleMenu() {
		this.isToggleMenu = !this.isToggleMenu
	}

	public showNotification() {
		this.notificationCount;
	}

	public changeNotificationNumber() {
		this.notificationCount = 8
	}

	public psButtonClick() {
		window.open('/procedurestore');
	}

	public dictionaryClick() {
		window.open('/dictionary')
	}

	public opButtonClick() {
		window.open('/outputhome');
	}

	public reusableButtonClick() {
		window.open('/reusableUpdate');
	}

	public adminSetButtonClick() {
		window.open('/adminSettings');
	}

	public reportsBtnClick() {
		window.open('/reports')
	}

	public workflowConfigButtonClick() {
		window.open('/workflow');
	}
	
	public getNotificationCount() {
		this.comheaderService.getNotificationCount().subscribe(
			data => {
				if (data.payload) {
					this.notificationCount = data.payload;
					//localStorage.setItem(LocalStorage.NOTIFICATION_COUNT, data.payload);
				} else {
					//do nothing
					this.notificationCount = 0;
				}
			},
			error => {
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			});

	}

	openModal(template: TemplateRef<any>, size: string) {
		this.previousePreference = localStorage.getItem('preferences');
		if (size == 'lg') {
			this.bsModalRef = this.modalService.show(template, { class: 'modal-lg' });
		} else {
			this.bsModalRef = this.modalService.show(template);
		}
	}

	cancel() {
		this.bsModalRef.hide();
		localStorage.setItem(LocalStorage.PREFERENCES, this.previousePreference);
	}

	save() {
		this.bsModalRef.hide();
		this.currentPreference = localStorage.getItem('preferences');
		let out = JSON.parse(localStorage.getItem('preferences'));
		this.comheaderService.updateUserPreferences(out).subscribe(
			data => {
				// console.log(data);
			}
		)

	}

	/**
	 * search component search button click event
	 * @param event 
	 */
	public searchFunc(event: any) {
		console.log('[searchFunc] (event) ' + JSON.stringify(event));
		//generate search parameters from method parameters
		this.getSearchResult(event)
	}

	/**
	 * search component search bar ENTER button click event
	 * @param event 
	 */
	public searchBarEnterFunc(event: any) {
		console.log('[searchBarEnterFunc] (event) ' + JSON.stringify(event));
		//generate search parameters from method parameters
		this.getSearchResult(event)
	}

	private getSearchResult(event: any) {
		let data = {
			isSearch: true,
			searchWordMap: event.searchWordMap
		}
		this.sharedService.openTabRequestWithId('search_res', 'Search', 'FileSearch', data, "Search")
	}

	public setasDefault(option: boolean): void {
		if (option) {
			//set default project
			let request = { projectId: this.projectId, isDefaultProject: 1, projectType: this.projectType, userId: this.loggedInUser.userId };
			this.comheaderService.setDefaultRecentProjects(JSON.stringify(request), this);
		} else {
			//remove default project
			let request2 = { projectId: this.projectId, userId: this.loggedInUser.userId, projectType: this.projectType };
			this.comheaderService.deleteUserDefaultRecentProject(JSON.stringify(request2), this);
		}
	}

	onSuccess(data: WsResponse, serviceType: WsType): void {
		if (serviceType == WsType.SET_DEFAULT_RECENT_PROJECT) {
			if (data.payload) {
				this.alertService.success(data.statusDescription)
				this.translate.get('HEADER.MENU_REMOVE_DEFAULT').subscribe((res: string) => {
					this.setDefaultBtnText = res;
					this.defaultBtnIcon = false
				});
				localStorage.setItem(LocalStorage.DEFAULT_PROJECT_ID, UserVariable.projectId);
				localStorage.setItem(LocalStorage.DEFAULT_PROJECT_NAME, UserVariable.projectName);
				localStorage.setItem(LocalStorage.DEFAULT_CLIENT_ID, UserVariable.clientId);
			}
		} else if (serviceType == WsType.DELETE_USER_DEFAULT_RECENT_PROJECT) {
			if (data.payload) {
				this.alertService.success(data.statusDescription)
				this.translate.get('HEADER.MENU_SET_AS_DEFAULT').subscribe((res: string) => {
					this.setDefaultBtnText = res;
					this.defaultBtnIcon = true
				});
				localStorage.removeItem(LocalStorage.DEFAULT_PROJECT_ID)
				localStorage.removeItem(LocalStorage.DEFAULT_PROJECT_NAME);
				localStorage.removeItem(LocalStorage.DEFAULT_CLIENT_ID);
			}
		}
	}
	onFail(data: WsResponse, serviceType: WsType): void {
		if (serviceType == WsType.SET_DEFAULT_RECENT_PROJECT) {
			this.alertService.error(data.statusDescription)
		} else if (serviceType == WsType.DELETE_USER_DEFAULT_RECENT_PROJECT) {
			this.alertService.error(data.statusDescription)
		}
	}

	/**
	 * hamburg button click event - show side bar
	 */
	public showSlideBar() {
		console.log('[showSlideBar]');
		this.showHideSideBar.emit()
	}
}



