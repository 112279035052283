
<!--
<div>
	<alert></alert>
</div>
-->
<div class="col-md-12">
	<div class="col" style="overflow:hidden">
		<div class="float-left">
			<h4>{{ 'EDIT_PROJECT.EDIT_PROJECT' | translate }}</h4>
		</div>
		<div class="float-right">

		</div>
	</div>
	<hr />
</div>


<div class="overflow-auto col-md-12 mx-auto">
	<form [formGroup]="editProjectForm">
		<!--first name-->
		<div *ngIf="hasDependencies">
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">{{ 'EDIT_PROJECT.PROJECT_NAME' | translate }}
				<div class="badge-required mx-2"></div>
			</label>
			<div class="col-sm-10">
				<input *ngIf="project!=null && project!=undefined" id="projectName" formControlName="projectName" type="text" class="form-control form-control-sm" name="projectName"  [(ngModel)]="project.projectName" >
			</div>
		</div>
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">{{ 'EDIT_PROJECT.CLIENTS' | translate }}
				<div class="badge-required mx-2"></div>
			</label>
			<div class="col-sm-10">
				 <ng-select [allowClear]="true" [items]="" [active]="clientList"  [disabled]="true" name="clientList" formControlName="clientList" id="clientList"> </ng-select>
			</div>
		</div>
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">{{ 'EDIT_PROJECT.PROJECT_TYPE' | translate }}
				<div class="badge-required mx-2"></div>
			</label>
			<div class="col-sm-10">
				 <ng-select [allowClear]="true" [items]="projectTypes" [active]="selctedProjectType"  name="projectType" formControlName="projectType" id="projectType" (selected)="projectTypeSelected($event)"> </ng-select>
			</div>
		</div>
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">{{ 'EDIT_PROJECT.ADDITIONAL_NOTIFIED_PARTY' | translate }}
			</label>
			<div class="col-sm-10">
				<input id="additionalNotifiedParty" formControlName="additionalNotifiedParty" type="email"
					class="form-control form-control-sm" name="additionalNotifiedParty"  [(ngModel)]="project.additionalNotifiedParty"
					 email>
			</div>
		</div>
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">{{ 'EDIT_PROJECT.AUTO_GENERATED' | translate }}
			</label>
			<div class="col-sm-10">
				<div class="row">
					<div class="col-sm-1">
				<input #checkAutoGenerated id="checkAutoGenerated" formControlName="checkAutoGenerated" type="checkbox" class="form-control form-control-sm disable"
				name="checkAutoGenerated" [checked]="project != undefined && project.autoGenerateEquipNo"  (change)="CheckAutoGenerated(checkAutoGenerated.checked)" disabled>
			</div>
			<div class="col-sm-11"></div>
			</div>
			</div>
		</div>

		<div class="form-group row">
			<label *ngIf="checkAutoGenerate" class="col-sm-2 col-form-label">{{ 'EDIT_PROJECT.STARTING_EQUIPMENT' | translate }}
			</label>
			<div class="col-sm-10">
					<input *ngIf="checkAutoGenerate" id="equipmentNumber" formControlName="equipmentNumber" type="text" class="form-control form-control-sm" name="equipmentNumber"  [(ngModel)]="project.cmmsProjectInfo.startingEquipmentNo"
					disabled >
			</div>
		</div>

		<div class="form-group row">
			<label class="col-sm-2 col-form-label">{{ 'EDIT_PROJECT.CMMS_TRACKING' | translate }}
			</label>
			<div class="col-sm-10">
				<div class="row">
					<div class="col-sm-1">
				<input #cmmsTracking [(ngModel)]="isCmmsTracking" id="cmmsTracking" formControlName="cmmsTracking" type="checkbox" class="form-control form-control-sm"
				name="cmmsTracking" (change)="CheckCmmsTracking(cmmsTracking.checked)">
			</div>
			<div class="col-sm-11"></div>
			</div>
			</div>
		</div>
		<div *ngIf="isCmmsTracking"><h6>{{ 'EDIT_PROJECT.FUNCTIONAL' | translate }}</h6></div>
		<div>
			<a class="btn btn-sm btn-outline-dark py-0 my-1 px-1 border-0" (click)="invoke()">
				<i class="text-black-50 far fa-link" aria-hidden="true"></i>
			</a>
		</div>
		<div *ngIf="isCmmsTracking" class="form-group row">
			<label class="col-sm-2 col-form-label">{{ 'EDIT_PROJECT.NUMBER_OF_ELEMENTS' | translate }}
			</label>
			<div class="col-sm-10 dropup-select">
				 <ng-select [allowClear]="true" [items]="elements" [disabled]="true" [active]="selctedElement" name="numOfElements" (selected)="elementSelected($event)" formControlName="numOfElements"
							 id="numOfElements"> </ng-select>
			</div>
		</div>
		<div *ngIf="isCmmsTracking" class="form-group row">
			<label *ngIf="numberofMasks>0"class="col-sm-2 col-form-label">{{ 'ADD_PROJECT.CHARACTERS_PER_ELEMENT' | translate }}
			</label>
			<div *ngIf="numberofMasks>0" class="col-sm-1 dropup-select">
				 <ng-select [allowClear]="true" [disabled]="true" [items]="characterPerElement" [active]="mask1" name="characters1" (selected)="characterSelected1($event)" formControlName="characters1"
							 id="characters1"> </ng-select>
			</div>
			<div *ngIf="numberofMasks>1" class="col-sm-1 dropup-select">
					<ng-select [allowClear]="true" [disabled]="true"  [items]="characterPerElement" [active]="mask2" name="characters2" (selected)="characterSelected2($event)" formControlName="characters2"
								id="characters2"> </ng-select>
			   </div>
			   <div *ngIf="numberofMasks>2" class="col-sm-1 dropup-select">
					<ng-select [allowClear]="true" [disabled]="true"  [items]="characterPerElement" [active]="mask3" name="characters3" (selected)="characterSelected3($event)" formControlName="characters3"
								id="characters3"> </ng-select>
			   </div>
			   <div *ngIf="numberofMasks>3" class="col-sm-1 dropup-select">
					<ng-select [allowClear]="true" [disabled]="true"  [items]="characterPerElement" [active]="mask4" name="characters4" (selected)="characterSelected4($event)" formControlName="characters4"
								id="characters4"> </ng-select>
			   </div>
			   <div *ngIf="numberofMasks>4" class="col-sm-1 dropup-select">
					<ng-select [allowClear]="true" [disabled]="true"  [items]="characterPerElement" [active]="mask5" name="characters5" (selected)="characterSelected5($event)" formControlName="characters5"
								id="characters5"> </ng-select>
			   </div>
			   <div *ngIf="numberofMasks>5" class="col-sm-1 dropup-select">
					<ng-select [allowClear]="true" [disabled]="true"  [items]="characterPerElement" [active]="mask6" name="characters6" (selected)="characterSelected6($event)" formControlName="characters6"
								id="characters6"> </ng-select>
			   </div>
			   <div *ngIf="numberofMasks>6" class="col-sm-1 dropup-select">
					<ng-select [allowClear]="true" [disabled]="true"  [items]="characterPerElement" [active]="mask7" name="characters7" (selected)="characterSelected7($event)" formControlName="characters7"
								id="characters7"> </ng-select>
			   </div>
			   <div *ngIf="numberofMasks>7" class="col-sm-1 dropup-select">
					<ng-select [allowClear]="true" [disabled]="true"  [items]="characterPerElement" [active]="mask8" name="characters8" (selected)="characterSelected8($event)" formControlName="characters8"
								id="characters8"> </ng-select>
			   </div>
			   <div *ngIf="numberofMasks>8" class="col-sm-1 dropup-select">
					<ng-select [allowClear]="true" [disabled]="true"  [items]="characterPerElement" [active]="mask9" name="characters9" (selected)="characterSelected9($event)" formControlName="characters9"
								id="characters9"> </ng-select>
			   </div>
			   <div *ngIf="numberofMasks>9" class="col-sm-1 dropup-select">
					<ng-select [allowClear]="true" [disabled]="true"  [items]="characterPerElement" [active]="mask10" name="characters10" (selected)="characterSelected10($event)" formControlName="characters10"
								id="characters10"> </ng-select>
			   </div>
		</div>
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">{{ 'EDIT_PROJECT.ALLOW_DIFFERNT_PROCEDURE_INITIATED_USERS' | translate }}
			</label>
			<div class="col-sm-10">
				<div class="row">
					<div class="col-sm-1">
				<input #allowDifferentProcInitiatedUsers id="allowDifferentProcInitiatedUsers" formControlName="allowDifferentProcInitiatedUsers"
					type="checkbox" class="form-control form-control-sm" name="allowDifferentProcInitiatedUsers" [(ngModel)]="project != undefined && project.allowDifferentProcInitiatedUsers"
					 (change)="checkAllowDifferentProcInitiatedUsers(allowDifferentProcInitiatedUsers.checked)">
			</div>
			</div>
			</div>
		</div>
		<div class="row">
			<div class="col-sm-2">
				<button type="submit" class="btn btn-warning btn-block" (click)="save()">{{ 'EDIT_PROJECT.SAVE' | translate }}</button>
			</div>
			<div class="col-sm-2">
				<button type="button" class="btn btn-default btn-block" (click)="cancel()">{{ 'EDIT_PROJECT.CANCEL' | translate }}</button>
			</div>
		</div>
		</div>

		<div *ngIf="!hasDependencies">
			<div class="form-group row">
				<label class="col-sm-2 col-form-label">{{ 'EDIT_PROJECT.PROJECT_NAME' | translate }}
						<div class="badge-required mx-2"></div>
				</label>
				<div class="col-sm-10">
					<input *ngIf="project!=null && project!=undefined" id="projectName" formControlName="projectName" type="text" class="form-control form-control-sm" name="projectName"  [(ngModel)]="project.projectName" >
				</div>
			</div>
			<div class="form-group row">
				<label class="col-sm-2 col-form-label">{{ 'EDIT_PROJECT.CLIENTS' | translate }}
						<div class="badge-required mx-2"></div>
				</label>
				<div class="col-sm-10">
					 <ng-select [allowClear]="true" [items]="" [active]="clientList"  [disabled]="true" name="clientList" formControlName="clientList" 
					 id="clientList" (removed)="clientRemoved($event)"> </ng-select>
				</div>
			</div>
			<div class="form-group row">
				<label class="col-sm-2 col-form-label">{{ 'EDIT_PROJECT.PROJECT_TYPE' | translate }}
					<div class="badge-required mx-2"></div>
				</label>
				<div class="col-sm-10">
					 <ng-select [allowClear]="true" [items]="projectTypes" [active]="selctedProjectType"  name="projectType" formControlName="projectType" 
					 id="projectType" (selected)="projectTypeSelected($event)" (removed)="projectTypeRemoved($event)"> </ng-select>
				</div>
			</div>
			<div class="form-group row">
				<label class="col-sm-2 col-form-label">{{ 'EDIT_PROJECT.ADDITIONAL_NOTIFIED_PARTY' | translate }}
				</label>
				<div class="col-sm-10">
					<input id="additionalNotifiedParty" formControlName="additionalNotifiedParty" type="email"
						class="form-control form-control-sm" name="additionalNotifiedParty"  [(ngModel)]="project.additionalNotifiedParty"
						 email>
				</div>
			</div>
			<div class="form-group row">
				<label class="col-sm-2 col-form-label">{{ 'EDIT_PROJECT.AUTO_GENERATED' | translate }}
				</label>
				<div class="col-sm-10">
					<div class="row">
						<div class="col-sm-1">
					<input #checkAutoGenerated id="checkAutoGenerated" formControlName="checkAutoGenerated" type="checkbox" class="form-control form-control-sm disable"
					name="checkAutoGenerated" [checked]="project != undefined && project.autoGenerateEquipNo"  (change)="CheckAutoGenerated(checkAutoGenerated.checked)">
				</div>
				<div class="col-sm-11"></div>
				</div>
				</div>
			</div>
	
			<div class="form-group row">
				<label *ngIf="checkAutoGenerate" class="col-sm-2 col-form-label">{{ 'EDIT_PROJECT.STARTING_EQUIPMENT' | translate }}
				</label>
				<div class="col-sm-10">
						<input *ngIf="checkAutoGenerate" id="equipmentNumber" formControlName="equipmentNumber" type="text" class="form-control form-control-sm" name="equipmentNumber"  [(ngModel)]="project.cmmsProjectInfo.startingEquipmentNo"
						 >
				</div>
			</div>
	
			<div class="form-group row">
				<label class="col-sm-2 col-form-label">{{ 'EDIT_PROJECT.CMMS_TRACKING' | translate }}
				</label>
				<div class="col-sm-10">
					<div class="row">
						<div class="col-sm-1">
					<input #cmmsTracking id="cmmsTracking" formControlName="cmmsTracking" type="checkbox" class="form-control form-control-sm"
					name="cmmsTracking" [checked]="project != undefined && project.usedInCMMSTrac" (change)="CheckCmmsTracking(cmmsTracking.checked)">
				</div>
				<div class="col-sm-11"></div>
				</div>
				</div>
			</div>
			<div *ngIf="isCmmsTracking"><h6>{{ 'EDIT_PROJECT.FUNCTIONAL' | translate }}</h6></div>
			<div *ngIf="isCmmsTracking" class="form-group row">
				<label class="col-sm-2 col-form-label">{{ 'EDIT_PROJECT.NUMBER_OF_ELEMENTS' | translate }}
				</label>
				<div class="col-sm-10 dropup-select">
					 <ng-select [allowClear]="true" [items]="elements" [disabled]="false" [active]="selctedElement" name="numOfElements" (selected)="elementSelected($event)" formControlName="numOfElements"
								 id="numOfElements"> </ng-select>
				</div>
			</div>
			<div *ngIf="isCmmsTracking" class="form-group row">
				<label *ngIf="numberofMasks>0"class="col-sm-2 col-form-label">{{ 'ADD_PROJECT.CHARACTERS_PER_ELEMENT' | translate }}
				</label>
				<div *ngIf="numberofMasks>0" class="col-sm-1 dropup-select">
					 <ng-select [allowClear]="true" [disabled]="false" [items]="characterPerElement" [active]="mask1" name="characters1" (selected)="characterSelected1($event)" formControlName="characters1"
								 id="characters1"> </ng-select>
				</div>
				<div *ngIf="numberofMasks>1" class="col-sm-1 dropup-select">
						<ng-select [allowClear]="true" [disabled]="false"  [items]="characterPerElement" [active]="mask2" name="characters2" (selected)="characterSelected2($event)" formControlName="characters2"
									id="characters2"> </ng-select>
				   </div>
				   <div *ngIf="numberofMasks>2" class="col-sm-1 dropup-select">
						<ng-select [allowClear]="true" [disabled]="false"  [items]="characterPerElement" [active]="mask3" name="characters3" (selected)="characterSelected3($event)" formControlName="characters3"
									id="characters3"> </ng-select>
				   </div>
				   <div *ngIf="numberofMasks>3" class="col-sm-1 dropup-select">
						<ng-select [allowClear]="true" [disabled]="false"  [items]="characterPerElement" [active]="mask4" name="characters4" (selected)="characterSelected4($event)" formControlName="characters4"
									id="characters4"> </ng-select>
				   </div>
				   <div *ngIf="numberofMasks>4" class="col-sm-1 dropup-select">
						<ng-select [allowClear]="true" [disabled]="false"  [items]="characterPerElement" [active]="mask5" name="characters5" (selected)="characterSelected5($event)" formControlName="characters5"
									id="characters5"> </ng-select>
				   </div>
				   <div *ngIf="numberofMasks>5" class="col-sm-1 dropup-select">
						<ng-select [allowClear]="true" [disabled]="false"  [items]="characterPerElement" [active]="mask6" name="characters6" (selected)="characterSelected6($event)" formControlName="characters6"
									id="characters6"> </ng-select>
				   </div>
				   <div *ngIf="numberofMasks>6" class="col-sm-1 dropup-select">
						<ng-select [allowClear]="true" [disabled]="false"  [items]="characterPerElement" [active]="mask7" name="characters7" (selected)="characterSelected7($event)" formControlName="characters7"
									id="characters7"> </ng-select>
				   </div>
				   <div *ngIf="numberofMasks>7" class="col-sm-1 dropup-select">
						<ng-select [allowClear]="true" [disabled]="false"  [items]="characterPerElement" [active]="mask8" name="characters8" (selected)="characterSelected8($event)" formControlName="characters8"
									id="characters8"> </ng-select>
				   </div>
				   <div *ngIf="numberofMasks>8" class="col-sm-1 dropup-select">
						<ng-select [allowClear]="true" [disabled]="false"  [items]="characterPerElement" [active]="mask9" name="characters9" (selected)="characterSelected9($event)" formControlName="characters9"
									id="characters9"> </ng-select>
				   </div>
				   <div *ngIf="numberofMasks>9" class="col-sm-1 dropup-select">
						<ng-select [allowClear]="true" [disabled]="false"  [items]="characterPerElement" [active]="mask10" name="characters10" (selected)="characterSelected10($event)" formControlName="characters10"
									id="characters10"> </ng-select>
				   </div>
			</div>
			<div class="form-group row">
				<label class="col-sm-2 col-form-label">{{ 'EDIT_PROJECT.ALLOW_DIFFERNT_PROCEDURE_INITIATED_USERS' | translate }}
				</label>
				<div class="col-sm-10">
					<div class="row">
						<div class="col-sm-1">
					<input #allowDifferentProcInitiatedUsers id="allowDifferentProcInitiatedUsers" formControlName="allowDifferentProcInitiatedUsers"
						type="checkbox" class="form-control form-control-sm" name="allowDifferentProcInitiatedUsers" [(ngModel)]="project != undefined && project.allowDifferentProcInitiatedUsers"
					 	(change)="checkAllowDifferentProcInitiatedUsers(allowDifferentProcInitiatedUsers.checked)">
				</div>
				</div>
				</div>
			</div>
			<div class="form-group row ml-0">
				<div class="btn-toolbar">
					<button type="submit" class="btn btn-warning btn-sm mr-2" (click)="save()">{{ 'EDIT_PROJECT.SAVE' | translate }}</button>
				</div>
				<div class="btn-toolbar">
					<button type="button" class="btn btn-warning btn-sm mr-2" (click)="cancel()">{{ 'EDIT_PROJECT.CANCEL' | translate }}</button>
				</div>
			</div>
			</div>
	</form>
</div>

<!-- checkin comments modal -->
<ng-template #checkingTemplate>
	<div class="modal-header">
		<h4 class="modal-title pull-left">Alert</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<label>The project has CMMS functional location dependencies. Are you sure you want to remove CMMS Tracking from this project?</label>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="uncheck();bsModalRef.hide()">
			{{ 'FILES_DATA.OK' | translate}}</button>
		<button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="check();bsModalRef.hide()">
			{{ 'FILES_DATA.CANCEL' | translate}}
		</button>
	</div>
</ng-template>