
<!--
<div>
	<alert></alert>
</div>
-->

<div class="col-md-12">
	<div class="col" style="overflow:hidden">
		<div class="float-left">
			<h4>{{ 'ADD_PROJECT.ADD_PROJECT' | translate }}</h4>
		</div>
		<div class="float-right">

		</div>
	</div>
	<hr />
</div>


<div class="overflow-auto col-md-12 mx-auto">
	<form [formGroup]="addProjectForm">
		<!--first name-->
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">{{ 'ADD_PROJECT.PROJECT_NAME' | translate }}
				<div class="badge-required mx-2"></div>
			</label>
			<div class="col-sm-10">
				<input id="projectName" formControlName="projectName" type="text" class="form-control form-control-sm" name="projectName">
			</div>
		</div>
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">{{ 'ADD_PROJECT.CLIENTS' | translate }}
				<div class="badge-required mx-2"></div>
			</label>
			<div class="col-sm-10">
				 <ng-select [allowClear]="true" [items]="clientList" name="clientList" (selected)="clientSelected($event)" formControlName="clientList" 
				 id="clientList" (removed)="clientRemoved($event)"> </ng-select>
			</div>
		</div>
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">{{ 'ADD_PROJECT.PROJECT_TYPE' | translate }}
				<div class="badge-required mx-2"></div>
			</label>
			<div class="col-sm-10">
				 <ng-select [allowClear]="true" [items]="projectTypes" name="projectTypes" formControlName="projectTypes"
							 id="projectTypes"  (selected)="projectTypeSelected($event)" (removed)="projectTypeRemoved($event)"> </ng-select>
			</div>
		</div>
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">{{ 'ADD_PROJECT.ADDITIONAL_NOTIFIED_PARTY' | translate }}
			</label>
			<div class="col-sm-10">
				 <!-- <ng-select [allowClear]="true" [items]="userList" name="additionalNotifiedParty" (selected)="clientSelected($event)" formControlName="clientList" 
				 id="additionalNotifiedParty" (removed)="	additionalNotifiedPartyRemoved($event)"> </ng-select> -->
				 <input  id="additionalNotifiedParty" formControlName="additionalNotifiedParty" type="email" class="form-control form-control-sm" name="email" email>
			</div>
		</div>
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">{{ 'ADD_PROJECT.AUTO_GENERATED' | translate }}
			</label>
			<div class="col-sm-10">
				<div class="row">
					<div class="col-sm-1">
				<input #checkAutoGenerated id="checkAutoGenerated" formControlName="checkAutoGenerated" type="checkbox" class="form-control form-control-sm"
				name="checkAutoGenerated" (change)="CheckAutoGenerated(checkAutoGenerated.checked)">
			</div>
			<div class="col-sm-11"></div>
			</div>
			</div>
		</div>
		<div class="form-group row">
				<label *ngIf="checkAutoGenerate" class="col-sm-2 col-form-label">{{ 'ADD_PROJECT.STARTING_EQUIPMENT' | translate }}
				</label>
				<div class="col-sm-10">
						<input *ngIf="checkAutoGenerate" id="equipmentNumber" formControlName="equipmentNumber" type="text" class="form-control form-control-sm" name="equipmentNumber"
						>
				</div>
			</div>
		
			<div class="form-group row">
					<label class="col-sm-2 col-form-label">{{ 'ADD_PROJECT.CMMS_TRACKING' | translate }}
					</label>
					<div class="col-sm-10">
						<div class="row">
							<div class="col-sm-1">
						<input #cmmsTracking id="cmmsTracking" formControlName="cmmsTracking" type="checkbox" class="form-control form-control-sm"
						name="cmmsTracking" (change)="CheckCmmsTracking(cmmsTracking.checked)">
					</div>
					<div class="col-sm-11"></div>
					</div>
					</div>
				</div>
				<div *ngIf="isCmmsTracking"><h6>{{ 'ADD_PROJECT.FUNCTIONAL' | translate }}</h6></div>

				<div *ngIf="isCmmsTracking" class="form-group row">
						<label class="col-sm-2 col-form-label">{{ 'ADD_PROJECT.NUMBER_OF_ELEMENTS' | translate }}
						</label>
						<div class="col-sm-10 dropup-select">
							 <ng-select [allowClear]="true" [items]="elements" name="numOfElements" (selected)="elementSelected($event)" 
							 formControlName="numOfElements"
										 id="numOfElements"> </ng-select>
						</div>
					</div>
					<div *ngIf="isCmmsTracking" class="form-group row">
							<label *ngIf="selectedElement>0"class="col-sm-2 col-form-label">{{ 'ADD_PROJECT.CHARACTERS_PER_ELEMENT' | translate }}
							</label>
							<div *ngIf="selectedElement>0" class="col-sm-1 dropup-select">
								 <ng-select [allowClear]="true" [items]="characterPerElement" name="characters1" (selected)="characterSelected1($event)" formControlName="characters1"
											 id="characters1"> </ng-select>
							</div>
							<div *ngIf="selectedElement>1" class="col-sm-1 dropup-select">
									<ng-select [allowClear]="true" [items]="characterPerElement" name="characters2" (selected)="characterSelected2($event)" formControlName="characters2"
												id="characters2"> </ng-select>
							   </div>
							   <div *ngIf="selectedElement>2" class="col-sm-1 dropup-select">
									<ng-select [allowClear]="true" [items]="characterPerElement" name="characters3" (selected)="characterSelected3($event)" formControlName="characters3"
												id="characters3"> </ng-select>
							   </div>
							   <div *ngIf="selectedElement>3" class="col-sm-1 dropup-select">
									<ng-select [allowClear]="true" [items]="characterPerElement" name="characters4" (selected)="characterSelected4($event)" formControlName="characters4"
												id="characters4"> </ng-select>
							   </div>
							   <div *ngIf="selectedElement>4" class="col-sm-1 dropup-select">
									<ng-select [allowClear]="true" [items]="characterPerElement" name="characters5" (selected)="characterSelected5($event)" formControlName="characters5"
												id="characters5"> </ng-select>
							   </div>
							   <div *ngIf="selectedElement>5" class="col-sm-1 dropup-select">
									<ng-select [allowClear]="true" [items]="characterPerElement" name="characters6" (selected)="characterSelected6($event)" formControlName="characters6"
												id="characters6"> </ng-select>
							   </div>
							   <div *ngIf="selectedElement>6" class="col-sm-1 dropup-select">
									<ng-select [allowClear]="true" [items]="characterPerElement" name="characters7" (selected)="characterSelected7($event)" formControlName="characters7"
												id="characters7"> </ng-select>
							   </div>
							   <div *ngIf="selectedElement>7" class="col-sm-1 dropup-select">
									<ng-select [allowClear]="true" [items]="characterPerElement" name="characters8" (selected)="characterSelected8($event)" formControlName="characters8"
												id="characters8"> </ng-select>
							   </div>
							   <div *ngIf="selectedElement>8" class="col-sm-1 dropup-select">
									<ng-select [allowClear]="true" [items]="characterPerElement" name="characters9" (selected)="characterSelected9($event)" formControlName="characters9"
												id="characters9"> </ng-select>
							   </div>
							   <div *ngIf="selectedElement>9" class="col-sm-1 dropup-select">
									<ng-select [allowClear]="true" [items]="characterPerElement" name="characters10" (selected)="characterSelected10($event)" formControlName="characters10"
												id="characters10"> </ng-select>
							   </div>
						</div>
						
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">{{ 'ADD_PROJECT.ALLOW_DIFFERNT_PROCEDURE_INITIATED_USERS' | translate }}
			</label>
			<div class="col-sm-10">
				<div class="row">
					<div class="col-sm-1">
				<input #allowDifferentProcInitiatedUsers id="allowDifferentProcInitiatedUsers" formControlName="allowDifferentProcInitiatedUsers" type="checkbox" class="form-control form-control-sm"
				name="allowDifferentProcInitiatedUsers" (change)="checkAllowDifferentProcInitiatedUsers(allowDifferentProcInitiatedUsers.checked)">
			</div>
			</div>
			</div>
		</div>

		<div class="form-group row ml-0">
			<div class="btn-toolbar">
				<button type="submit" class="btn btn-warning btn-sm mr-2" (click)="save()">{{ 'ADD_USER.SAVE' | translate }}</button>
			</div>
			<div class="btn-toolbar">
				<button type="button" class="btn btn-warning btn-sm mr-2" (click)="cancel()">{{ 'ADD_USER.CANCEL' | translate }}</button>
			</div>
		</div>
	</form>
</div>
