import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SharedService } from 'src/app/util/shared.service';
import { FolderService } from '../../../foldertree/foldertree.service';
import { ICECourseService } from '../../../ice-course/ice-course-service.service';
import { UserVariable } from 'src/app/util/common/user-variable';
import { WsResponse } from 'src/app/util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';
import { AlertService } from 'src/app/util/alert/alert.service';
import { CommonUtil } from 'src/app/util/common/common-util';
import { LocalStorage } from 'src/app/util/localstorage.service';
import { LoggedUser } from 'src/app/user/logged-user.model';
import { LandingService } from 'src/app/landing/landing.service';
import { ICECourse } from 'src/app/ice-course/ice-course.model';
import { AllModules, GridOptions, Module } from '@ag-grid-enterprise/all-modules';
import { Folder } from 'src/app/foldertree/folder.model';
import { LoadingService } from 'src/app/util/loading/loading.service';


@Component({
	selector: 'app-new-course',
	templateUrl: './new-course.component.html',
	styleUrls: ['./new-course.component.css']
})
export class NewCourseComponent implements OnInit {

	public title: string;
	public fileName: string; //used to store file name user input value
	public folderId: string;
	public folderPath: string;
	public import: string = "Yes";
    public isImport: boolean = false;
	public mediaFolderPath: string;
	public projectList = [];
	public selectedProject;
	public selectedProjectName;
	private loggedInUser: LoggedUser;
	public iceCourseModel: ICECourse;
	public iceCourseList: Array<any> = [];
	public gridOptions: GridOptions;
	public columnDefs: any;
	public rowData:any = [];
	private gridApi: any;
	private gridColumnApi: any;
	public modules: Module[] = AllModules;
	public projectId: string;
	public isImportClicked: boolean = false;
	public selectedItemData: any;
	public folderData: Folder;
	public overlayLoadingTemplate: any;
	public overlayNoRowsTemplate: any;

	constructor(public bsModalRef: BsModalRef, public sharedService: SharedService, private folderService: FolderService,
		private alertService: AlertService, private courseService: ICECourseService, private commonUtil: CommonUtil, private landingService: LandingService,private loadingService: LoadingService) { 
			
			this.columnDefs = [
				{ 
					headerName: 'Select', 
					field: 'cbox', 
					resizable: true,
					width: 50, 
					headerCheckboxSelection: false, 
					checkboxSelection: true 
				},
				{
				  headerName: 'Course Id',
				  resizable: true,
				  field: 'courseId',
				  hide: true
				},
				{
				  headerName: 'ICE Course Name',
				  field: 'fileName',
				  filter: "agTextColumnFilter",
				  resizable: true,
				  sortable: true,
				  minWidth: 225,
				  cellClass: "cell-wrap-text", 
				  cellStyle: {"white-space": "normal"},
				},
				{
				  headerName: 'Path',
				  field: 'path',
				  resizable: true,
				  minWidth: 450,
				  cellClass: "cell-wrap-text", 
				  cellStyle: {"white-space": "normal"},
				}
			  ];
			
			  this.gridOptions = {
				rowData: this.rowData,
				columnDefs: this.columnDefs,
				defaultColDef: {
				  resizable: true,
				  sortable: true
				},
				rowSelection: "single",
				rowMultiSelectWithClick: false
			};
			
			this.overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Loading data...</span>';
            this.overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">No rows to show</span>';
		}

	ngOnInit() {
	}
	/**
	 * submit button click event
	 */
	public onSubmitButtonClick() {
		console.log('[onSubmitButtonClick]');
		//validate form
		if (!this.validateForm()) {
			return;
		}

		let selectedFolderData = this.folderService.getSelectedFoldeData();
		this.folderId = selectedFolderData != null ? selectedFolderData.id : 0
		this.folderPath = selectedFolderData != null ? selectedFolderData.original.path + this.fileName : null;
		//request
		var request = {
			"fileName": this.fileName,
			"folderId" : this.folderId
		};
        let token = UserVariable.getUserToken();
            if (!this.commonUtil.validateToken(token)) {
                return;
            }
            this.courseService.checkSimilarFileName(JSON.stringify(request), token, this);
        }

	/**
	 * validate form
	 */
	validateForm(): boolean {
		if(this.fileName != undefined) {
			this.fileName = this.fileName.trim()
		}
		if(this.fileName){
		}else{
			return false;
		}
		if (this.fileName == undefined || this.fileName == null) {
			return false;
		}
		return true;
	}

	onSuccess(data: WsResponse, serviceType: WsType) {
		console.log(data.payload);
		console.log("serviceType"+serviceType);
		if (serviceType == WsType.CHECK_ICE_COURSE_FILENAME) {
			let unsavedTabsWithSimilarName = Object.values(this.sharedService.getOpenedICEModules())
				.filter(course => course.module.fileName == this.fileName && course.module.folderId == this.folderId);
				
			if(unsavedTabsWithSimilarName.length > 0){
				this.loadingService.hideLoading();
				this.alertService.error("File name already exists in an unsaved course tab", false);
			}
			else{
				if(this.isImportClicked != true ){
				let data = {
					fileName: this.fileName,
					isNewCourse: true,
					folderId : this.folderId,
					id: this.getRandomId()
				}
				//open new tab with hardcoded values tab type should be 'IceCourse'
				//this should remove after insert cource to files table
				this.sharedService.openTabRequestWithId(data.id, this.fileName, 'IceCourse', data, this.folderPath) 
				this.bsModalRef.hide()
				} else {
                  if(data != null){
					this.createMediaFolder(this.folderId,(this.fileName+" - Media Files"));
					

				  }

				}
			}
		} else if (data != null && WsType.LANDING == serviceType) {

			this.projectList = [];
			if (data.payload != null) {
			data.payload.forEach(element => {
				if(element.id != this.projectId){
					this.projectList.push({ 'id': element.id, 'text': element.projectName});
				}
				
			});
			this.projectList.sort((a, b) => b.text < a.text ? 1: -1);
			this.loadingService.hideLoading();
			} 
		
		} else if (serviceType == WsType.GET_ALL_ACTIVE_ICE_COURSES){
			this.loadICECourseList(data.payload);

		} else if (serviceType == WsType.FOLDER_ADD){
          if(data != null){
			this.saveImportCourseDetails();
			this.alertService.info("Started importing the course");
			this.alertService.timerFunc(8000);
			this.bsModalRef.hide();
          }

		} else if(data != null && serviceType == WsType.CREATE_ICE_COURSE_IMPORT){
			this.folderService.getAllFolders(this);
		} else if(data != null && serviceType == WsType.FOLDER_VIEW){
			this.loadingService.hideLoading();
			this.sharedService.setFolderTree(data);
			this.sharedService.folderRefreshRequest(this.folderId);

		} else {
			//do nothing
		}
	}

	onFail(data: WsResponse, serviceType: WsType) {
		if (serviceType == WsType.CHECK_ICE_COURSE_FILENAME) {
			this.loadingService.hideLoading();
			this.alertService.error(data.statusDescription, false);
		} else if (WsType.LANDING == serviceType) {
			this.loadingService.hideLoading();
			this.alertService.error(data.statusDescription, false);
		} else if (serviceType == WsType.GET_ALL_ACTIVE_ICE_COURSES){
			this.loadingService.hideLoading();
			this.alertService.error(data.statusDescription, false);
		} else if (serviceType == WsType.FOLDER_ADD){
			this.loadingService.hideLoading();
			if(data.statusDescription == "Folder name already exists" && data.statusCode == "532"){
				this.alertService.error("Media files folder name is already taken. Please change the file name or rename the existing folder name");
			} else {
			this.alertService.error(data.statusDescription, false);
			}
		}  else if(serviceType == WsType.CREATE_ICE_COURSE_IMPORT){
			this.loadingService.hideLoading();
			this.alertService.error(data.statusDescription, false);
		}
	}

	getRandomId(){
		function s4() {
			return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
		}
		return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
	}

	checkboxChecked(data: any){
		
		this.loggedInUser = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER)) as LoggedUser;
		this.landingService.getAllProjects(this.loggedInUser.userId, this);
		let projectId = UserVariable.projectId;
		this.projectId = projectId;
		let mediaFilesFolder = this.folderService.getSelectedFoldeData();
		this.mediaFolderPath = mediaFilesFolder != null ? mediaFilesFolder.original.path : null;
		var checkboxValue = data.currentTarget.checked;
		  if (checkboxValue) {
			  this.isImport = true;
			  this.import = "Yes";
			  this.loadingService.showLoading(true, null, "Loading Projects", null);
		  } else{
			this.isImport = false;
			this.import = "No";
			this.isImportClicked = false;
			this.selectedProject = null;
			this.selectedItemData = null;
			this.rowData = [];
		  }
	  }

	  public selectedProjectId(value: any): void {
		
		console.log("Selected Project name: " + JSON.stringify(value));
		this.selectedProject = value.id;
		this.selectedProjectName = value.text;
		this.courseService.getAllActiveICECoursesByProjectId(this.selectedProject,UserVariable.getUserToken(),this);
		
	  }
	  public removedProject(value: any): void {
		console.log("removeprojectId");
	
		this.selectedProject = null;
		this.selectedProjectName = null;

	  }
	  loadICECourseList(payload:WsResponse['payload']){
		this.selectedItemData = null;
		var rowData : any[] = [];
		payload.forEach(iceCourse => {
		  rowData.push({
			courseId: iceCourse.courseId,
			fileName: iceCourse.fileName,
			path: iceCourse.folderPath
		  });
		})
		
		this.rowData = rowData;
		this.rowData.sort((a, b) => b.fileName < a.fileName ? 1: -1);
		// this.prepareAgGrid();

	  }
	//   private prepareAgGrid(){

	// 	this.columnDefs = [
	// 		{ 
	// 			headerName: 'Select', 
	// 			field: 'cbox', 
	// 			resizable: true,
	// 			width: 50, 
	// 			headerCheckboxSelection: false, 
	// 			checkboxSelection: true 
	// 		},
	// 		{
	// 		  headerName: 'Course Id',
	// 		  resizable: true,
	// 		  field: 'courseId',
	// 		  hide: true
	// 		},
	// 		{
	// 		  headerName: 'ICE Course Name',
	// 		  field: 'fileName',
	// 		  resizable: true,
	// 		  sortable: true,
	// 		//   width: 500,
	// 		  cellClass: "cell-wrap-text", 
	// 		  cellStyle: {"white-space": "normal"},
	// 		},
	// 		{
	// 		  headerName: 'Path',
	// 		  field: 'path',
	// 		  resizable: true,
	// 		//   width: 400,
	// 		  cellClass: "cell-wrap-text", 
	// 		  cellStyle: {"white-space": "normal"},
	// 		}
	// 	  ];
		
	// 	  this.gridOptions = {
	// 		rowData: this.rowData,
	// 		columnDefs: this.columnDefs,
	// 		defaultColDef: {
	// 		  resizable: true,
	// 		  sortable: true
	// 		},
	// 		rowSelection: "single",
	// 		rowMultiSelectWithClick: false
	// 	};
	// 	// this.overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Loading data...</span>';
    //     // this.overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">No rows to show</span>';
	//   }
	  onGridReady(params: any) {

		this.gridApi = params.api
		this.gridColumnApi = params.columnApi;
		// this.gridApi.hideOverlay()
	  }
	  
	  onRowSelected(event) {


		this.selectedItemData = undefined;
		let rowSelected: boolean = event.node.selected;
		if(rowSelected){
			this.selectedItemData = event.data;
		} 
		// this.onSelectionChange();
	  }

	  onSelectionChanged(event){

		var selectedRows = this.gridApi.getSelectedRows();
		this.selectedItemData = undefined;
		// let rowSelected: boolean = event.node.selected;
		// if(rowSelected){
		this.selectedItemData = selectedRows["0"];
		// } 
	  }
	  createMediaFolder(folderId: any, fileName: any){
		this.folderData = new Folder(fileName, folderId)
		this.folderData.status = "Inactive";
		this.folderService.addFolder(JSON.stringify(this.folderData), this);

	  }

	  validateImportForm(): boolean {
		if(this.fileName != undefined) {
			this.fileName = this.fileName.trim()
		}
		if(this.fileName){
		}else{
			return false;
		}
		if (this.fileName == undefined || this.fileName == null) {			
			return false;
		}
		if (this.selectedProject == null || this.selectedProject == undefined){
			this.alertService.error("Please Select a Project");
			return false;
		}
		if (this.selectedItemData == null || this.selectedItemData == undefined){
			this.alertService.error("Please Select a Course");
			return false;
		}
		return true;
	}
	  public onImportButtonClick(){
		console.log('[onImportButtonClick]');
		//validate import form
		
		if (!this.validateImportForm()) {
			return;
		}
		this.loadingService.showLoading(true, null, "Starting course import...", null);

		let selectedFolderData = this.folderService.getSelectedFoldeData();
		this.folderId = selectedFolderData != null ? selectedFolderData.id : 0
		this.folderPath = selectedFolderData != null ? selectedFolderData.original.path + this.fileName : null;
		//request
		var request = {
			"fileName": this.fileName,
			"folderId" : this.folderId
		};
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
                return;
        }
		this.isImportClicked = true;
        this.courseService.checkSimilarFileName(JSON.stringify(request), token, this);

	  }

	  public saveImportCourseDetails(){

		var request = {
			"courseId": 0,
			"fileName": this.fileName,			
			"status": "OnImportQueue",
			"folderId": this.folderId,
			"originalCourseIdForImport": this.selectedItemData.courseId,
			"mediaFolderForImport": this.fileName+" - Media Files",
			"courseName": "<ice>" + this.fileName + "- On Import Queue" + "</ice>"
			
		};

		console.log('[Create course() was called]');
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
             return;
        }
        this.courseService.createICECourseForImport(JSON.stringify(request), token, this);
	  }

	  public saveCourseDuplicateDetails(){

		var request = {
			"originalCourseId": this.selectedItemData.courseId,
			"fileName": this.selectedItemData.fileName,			
			"mediaFolderNameForImport": this.mediaFolderPath+this.fileName+" - Media Files"
		};

		console.log('[Duplicate course() was called]');
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
             return;
        }
        this.courseService.saveCourseDuplicateDetails(JSON.stringify(request), token, this);
	  }
	  

}
