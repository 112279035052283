import { Component, Input, TemplateRef, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { SearchParams } from './search-params.modal';
import { BsModalService } from 'ngx-bootstrap/modal'
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Constants } from '../../constants';
import { CategorytreeComponent } from '../../categorytree/categorytree.component';

declare var $: any;

@Component({
    selector: 'symbiosis-search-form-item',
    templateUrl: './search-form-item.component.html',
    outputs: ['categorySelected', 'documentSelected','taginputSeleted']
})
export class SearchFormItemComponent {
    @Input() searchParams: SearchParams<any>;
    @Input() form: FormGroup;
    get isValid() { return this.form.controls[this.searchParams.key].valid; }
    categorySelected = new EventEmitter<any>()
    documentSelected = new EventEmitter<any>()
    taginputSeleted = new EventEmitter<any>()
    public categoryType: string = null;
    public documentType: string = null;
    public wildcardText: string = '';
    public isToggleDropdownBtnDisabled: boolean = true;
    public searchText: string = '';
    //public wildcardText: string = '';
    public wildcardType: string = 'contains';
    public selectedWildcardType: any = null;
    public disabled: boolean = true;

    modalRef: BsModalRef
    selectedCategoryItem: any = {
        'catName': '',
        'catId': ''
    }
    tempCategoryItem: any = null
    selectedDocType: any = {
        'docName': '',
        'docId': ''
    }
    selectedDropDown: any = [];
    tempDocumentType: any = null
    types: any = [];

    public wildcardTypes = [{ 'id': '1', 'text': 'startsWith' },
                { 'id': '2', 'text': 'contains' },
                { 'id': '3', 'text': 'endsWith' },
                { 'id': '4', 'text': 'exactWord' }]

    constructor(private modalService: BsModalService) { }
    ngOnInit(): void {
        if (this.searchParams.options !== undefined && this.searchParams.options !== "") {
            let list: Array<any> = []
            this.searchParams.options.forEach(element => {
                list.push({
                    id: element.key,
                    text: element.value
                })
            });
            this.types = list
        }
    
        //set category type
        if (this.searchParams.type == 'state_category') {
            if (this.searchParams.key == Constants.SEARCH_KEY_WORDS.SEARCH_STATEMENT_STM_CATEGORY) {
                if (this.searchParams.value != undefined) {
                this.selectedCategoryItem.catName = this.searchParams.value;
                } else {
                    //no value
                }
            } else {
                //do nothing
            }
        } else if (this.searchParams.type == 'comp_category') {
            if (this.searchParams.key == Constants.SEARCH_KEY_WORDS.SEARCH_COMPONENT_COMP_CATEGORY) {
                if (this.searchParams.value != undefined) {
                    this.selectedCategoryItem.catName = this.searchParams.value;
                } else {
                    //no value
                }
            } else {
                //do nothing
            }
        } else if (this.searchParams.type == 'document-type') {
            if (this.searchParams.key == Constants.SEARCH_KEY_WORDS.SEARCH_DOCUMENT_DOC_DOCTYPE) {
                if (this.searchParams.value != undefined) {
                    this.selectedDocType.docName = this.searchParams.value;
                } else {
                    //no value
                }
            } else {
            //do nothing
            }
        } else if (this.searchParams.type == 'chk_category') {
            if (this.searchParams.key == Constants.SEARCH_KEY_WORDS.SEARCH_CHK_CATEGORY) {
                if (this.searchParams.value != undefined) {
                    this.selectedCategoryItem.catName = this.searchParams.value;
                } else {
                    //no value
                }
            } else {
                //do nothing
            }
        } else if (this.searchParams.type == 'dropdown') {
            if (this.searchParams.key == Constants.SEARCH_KEY_WORDS.SEARCH_SYSTEM_SYS_COMMISSIONING || this.searchParams.key == Constants.SEARCH_KEY_WORDS.SEARCH_HEADER_FILE_ITEM_TYPE || this.searchParams.key == Constants.SEARCH_KEY_WORDS.SEARCH_CS_TYPE || Constants.SEARCH_KEY_WORDS.SEARCH_CHK_TYPE) {
                if (this.searchParams.value != undefined) {
                    if (this.searchParams.value != "") {
                        this.types.forEach(element => {
                            if(element.id == this.searchParams.value[0].id){
                                this.selectedDropDown = [{id:this.searchParams.value[0].id, text:element.text}];
                            } else{
                                //do nothing
                            }
                        });
                    } else{
                       this.selectedDropDown = [{
                           'id': '',
                          'text': ''
                      }];
                    }
                } else {
                    //no value
                }
            } else {
            //do nothing
            }
        } else if (this.searchParams.type == 'img-document-type') {
            if (this.searchParams.key == Constants.SEARCH_KEY_WORDS.SEARCH_IMAGE_IMG_DOCTYPE) {
                if (this.searchParams.value != undefined) {
                    this.selectedDocType.docName = this.searchParams.value;
                } else {
                    //no value
                }
            } else {
            //do nothing
            }
        } else if (this.searchParams.type == 'threed-model-document-type') {
            if (this.searchParams.key == Constants.SEARCH_KEY_WORDS.SEARCH_THREED_MODEL_DOCTYPE) {
                if (this.searchParams.value != undefined) {
                    this.selectedDocType.docName = this.searchParams.value;
                    console.log("doc name" + this.selectedDocType.docName)
                } else {
                    //no value
                }
            } else {
            //do nothing
            }
        } else if (this.searchParams.type == 'video-document-type') {
            if (this.searchParams.key == Constants.SEARCH_KEY_WORDS.SEARCH_VIDEO_DOCTYPE) {
                if (this.searchParams.value != undefined) {
                    this.selectedDocType.docName = this.searchParams.value;
                    console.log("doc name" + this.selectedDocType.docName)
                } else {
                    //no value
                }
            } else {
            //do nothing
            }
        } else if(this.searchParams.type == 'wildcards'){
            if(this.searchParams.key == Constants.SEARCH_KEY_WORDS.SEARCH_TAG_TAG_NUM){
                if (this.searchParams.value != undefined) {
                    this.setWildCardTypeAndSearchText(this.searchParams.value);
                } else{
                    this.selectedWildcardType = this.wildcardTypes[1];
                }
            }
        } 
        //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        //Add 'implements OnInit' to the class.
    }

    public showCategoryList(template: TemplateRef<any>, categoryType: string) {
        console.log('[showCategoryList] (categoryType) ' + JSON.stringify(categoryType));
        this.categoryType = categoryType;
        if (this.selectedCategoryItem.catName != '') {
            this.tempCategoryItem = this.selectedCategoryItem
            this.selectedCategoryItem = {
                'catName': '',
                'catId': ''
            }
        } 
        this.modalRef = this.modalService.show(template, { class: 'modal-lg', backdrop: 'static', keyboard : false })
    }

    public showDocumentTypes(template: TemplateRef<any>, documentType: string) {
      this.documentType = documentType;
      if (this.selectedDocType.docName != '') {
          this.tempDocumentType= this.selectedDocType
          this.selectedDocType = {
              'docName': '',
              'docId': ''
          }
      } 
      this.modalRef = this.modalService.show(template, { class: 'modal-lg', backdrop: 'static', keyboard : false })
  }

    /**
     * select category output event
     * @param event 
     */
    selectedCategory(event: any) {
        console.log('[selectedCategory] (event) ' + JSON.stringify(event));
		localStorage.setItem("selectedCategory", event.catId);
        this.selectedCategoryItem = event
        this.tempCategoryItem = event
        this.categorySelected.emit(event)
        this.modalRef.hide()
    }

    selectedDocument(event: any) {
        console.log('[selectedCategory] (event) ' + JSON.stringify(event));
		localStorage.setItem("selectedCategory", event.catId);
        this.selectedDocType = event
        this.tempDocumentType = event
        this.documentSelected.emit(event)
        this.modalRef.hide()
    }

    /**
     * close button click event on modal
     * hide model
     */
    closeButtonClickEvent() {
        if (this.tempCategoryItem != null) {
            this.selectedCategoryItem = this.tempCategoryItem
        } else {

        }
        this.modalRef.hide()
    }

    itemRemoved(event: any) {

    }

    itemSelected(item : any) {
        this.selectedDropDown = [{id:item.id, text:item.text}];
        if (item.id === 'topic' || item.id === 'procedure' || item.id == 'topic_map' || item.id === 'image' || item.id === 'content_item') {
            this.form.controls['filetype'].setValue([{id: item.id, text: item.text}]);
        }
    }

    /**text value change event */
    onKey(event:any){
        if(event.srcElement.value.length == 0){
            this.wildcardText = '';
            this.searchText = '';
        } else{
            this.searchText = event.srcElement.value;
            this.setWildCardText();
        }
    }

    /**set wildcard type */
    wildcardSelected(wildcardType: any){
        this.selectedWildcardType = wildcardType;
        this.wildcardType = this.selectedWildcardType.text;
        if(this.searchText != ''){
            this.setWildCardText();
        }
    }

    /**set wildcard text */
    setWildCardText(){
        switch(this.wildcardType){
            case 'startsWith':
                    this.wildcardText = this.searchText + '*';
                break;
            case 'contains':
                    this.wildcardText = '*' + this.searchText + '*';
                break;
            case 'endsWith':
                    this.wildcardText = '*' + this.searchText;
                break;
            case 'exactWord':
                    this.wildcardText = this.searchText;
                break;
            default:
                    this.wildcardText = '*' + this.searchText + '*';
                break;
        }
    }

    /**set wildcard type and text */
    setWildCardTypeAndSearchText(wildcardText: string){
        var firstChar = wildcardText.charAt(0);
        var lastChar = wildcardText.charAt(wildcardText.length - 1);
        if(firstChar === '*' && lastChar === '*'){
            this.wildcardType = 'contains';
            this.selectedWildcardType = this.wildcardTypes[1];
            this.searchText = wildcardText.substring(1, wildcardText.length - 1);
        } else if(firstChar != '*' && lastChar === '*'){
            this.wildcardType = 'startsWith';
            this.selectedWildcardType = this.wildcardTypes[0];
            this.searchText = wildcardText.substring(0, wildcardText.length - 1);
        } else if(firstChar === '*' && lastChar != '*'){
            this.wildcardType = 'endsWith';
            this.selectedWildcardType = this.wildcardTypes[2];
            this.searchText = wildcardText.substring(1, wildcardText.length);
        } else{
            this.selectedWildcardType = this.wildcardTypes[3];
            this.wildcardType = 'exactWord';
            this.searchText = wildcardText;
        }
        this.setWildCardText();
    }

    tagInputItemSelected(event){
        this.taginputSeleted.emit(event);
    }
}
