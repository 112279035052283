import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy, } from '@angular/core';
import { AlertService } from '../../util/alert/alert.service';
import { SharedService } from '../../util/shared.service';
import { LoadingService } from '../../util/loading/loading.service';
import { WsResponse } from '../../util/ws-response.model';
import { WsType } from '../../util/ws-type';
import { GridOptions, Module, AllModules } from "@ag-grid-enterprise/all-modules";
import { TranslateService } from '@ngx-translate/core';
import { TagsService } from './tags.service';
import { HistoryService } from '../history/history.service';
import { TagCellRendererComponent } from './tag-cell-renderer/tag-cell-renderer.component';
import { ActivatedRoute, Router } from '@angular/router';
import { UI_LAYOUT, SessionStorageConst } from 'src/app/util/constants';
import { Constants } from '../../util/constants';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AddTagComponent } from './add-tag/add-tag.component';
import { EditTagsComponent } from './edit-tags/edit-tags.component';
import { UserVariable } from '../../util/common/user-variable';
import { DependenciesModalService } from './../../util/dependenciesmodal/dependenciesmodal.service';
import { CapabilityService } from '../../util/capability.service';
import { CommonUtil } from 'src/app/util/common/common-util';
import { TagDependencyComponent } from './tag-dependency/tag-dependency.component';
import { DEPENDENCY_ACTION } from './tag-util';

@Component({
    selector: 'app-tags',
    templateUrl: './tags.component.html',
    styleUrls: ['./tags.component.css']
})
export class TagsComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('tagNo') tagNoElem: ElementRef;
    @ViewChild('description') descriptionElem: ElementRef;
    @ViewChild('systemName') systemNameElem: ElementRef;
    @ViewChild('documentName') documentNameElem: ElementRef;
    @ViewChild('facilityName') facilityNameElem: ElementRef;

    private token;
    public expandTags: boolean = false;
    changeLayout: boolean = false
    private filteredData: Array<any> = [];
    public gridOptions: GridOptions;
    private gridApi: any;
    private gridColumnApi: any;
    public getRowNodeId: any;
    public modules: Module[] = AllModules;
    public defaultColDef: any;

    private tagId: string = "";
    private tagIdAttr: string = "";
    private tagNo: string = "";
    private cadNo: string = "";
    private classificationId: string = "";
    private description: string = "";
    private systemName: string = "";
    private documentName: string = "";
    private facilityName: string = "";
    private hasText: string = "";

    public columnDefs: any;
    public columnDefsAttr = [];
    public smallView: boolean = false;
    public classificationClicked: boolean = false;
    rowDataTagAttr: any = [];
    rowData: any = [];
    public hasAttr:boolean = false;
    public loading:boolean = false;
    public overlayLoadingTemplate: any;
    public overlayNoRowsTemplate: any;
    private count = 0;
    private reusableType: string = "";
    private reusableView: string = "";
    private resuableText: string = "";
    private reusableXML: string = null;

    //table row definition
    private status: string;
    private edit: string;
    private delete: string;

    public bsModalRef: BsModalRef;
    private subsVar: Subscription;

    private clickDebouncer: any;
    private clickedTimes = 0;

    // Attributes of the selected classification
    public tagAttributes = [];
    public tagAttributesNew = [];
    public isShowTagAttributes:boolean = false;
    public selectedAttributes = [];
    public selectedChangedAttributes = []; 
    
	public systemListForNgSelect: Array<any> = [];
	//public selectedSystemOptions: Array<any> = [];
	public componentListForNgSelect: Array<any> = [];
	//public selectedComponentOptions: Array<any> = [];
    public documentListForNgSelect: Array<any> = [];
    public selectedTagAttributeValue: Array<any> = [];
    //public selectedDocumentOptions = [];
    private systems = [];
	public documents = [];
    public documentListAll = [];
    public documentListAllNew = [];
    private components = [];
    
    // Capabilities
	public modifyCap: boolean = false;
	public queryCap: boolean = false;
	public deleteCap: boolean = false;
	public disableCap: boolean = false;
    public createCap: boolean = false;
    public queryTagClassificationCap: boolean = false;
    public queryValueListCap: boolean = false;

    //enable disable button/icon
	public enableAddTag: boolean = false;
	public enableSearchTag: boolean = false;
    public enableChangeTagStatus: boolean = false;
    public enableEditTag: boolean = false;
    public enableDeleteTag: boolean = false;
    public enableSearchTagClassification: boolean = false;
    public enableSearchValueList: boolean = false;
    
    constructor(private alertService: AlertService, private route:ActivatedRoute, private sharedService: SharedService,
        private loadingService: LoadingService, private translate: TranslateService,
        private tagsService: TagsService, private historyService: HistoryService, private router: Router,
        private dependenciesmodalService: DependenciesModalService,
        private modalService: BsModalService, private capabilityService: CapabilityService, private commonUtil: CommonUtil) {

        translate.addLangs(["es", "en"]);
        translate.setDefaultLang("en");
        let browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');

        //check the user capability and enable/disable buttons
        this.enableDisableButtons();

        // Big View
        this.columnDefs = [
            {
                headerName: 'Dependencies',
                field: "dependencies",
                width: 140,
                cellStyle: { 'text-align': 'center' },
                cellRendererFramework: TagCellRendererComponent
            },
            {
                headerName: 'Type', field: 'type', checkboxSelection: false, hide: true,
                suppressColumnsToolPanel: true
            },
            {
                headerName: 'Tag Id', field: 'tagId', checkboxSelection: false, hide: true,
                suppressColumnsToolPanel: true
            },
            {
                headerName: this.translate.instant('TAGS_SEARCH_VIEW.TAG_NO'), field: 'tagNo', checkboxSelection: false, hide: false,
                suppressColumnsToolPanel: false, sort: 'asc'
            },
            {
                headerName: this.translate.instant('TAGS_SEARCH_VIEW.DESCRIPTION'), field: 'description', checkboxSelection: false, hide: false,
                suppressColumnsToolPanel: false
            },
            {
                headerName: this.translate.instant('TAGS_SEARCH_VIEW.SYSTEM'), field: 'systemName', checkboxSelection: false, hide: false,
                suppressColumnsToolPanel: false
            },
            {
                headerName: this.translate.instant('TAGS_SEARCH_VIEW.DOCUMENT'), field: 'documentName', checkboxSelection: false, hide: false,
                suppressColumnsToolPanel: false
            },
            {
                headerName: this.translate.instant('TAGS_SEARCH_VIEW.FACILITY'), field: 'facilityName', checkboxSelection: false, hide: false,
                suppressColumnsToolPanel: false
            },
            {
                headerName: this.translate.instant('TAGS_SEARCH_VIEW.CAD_NO'), field: 'cadNo', checkboxSelection: false, hide: false,
                suppressColumnsToolPanel: false
            },
            {
                headerName: this.translate.instant('TAGS_SEARCH_VIEW.BTN_TXT_CLASSIFICATION'), field: 'classification', checkboxSelection: false, hide: false,
                suppressColumnsToolPanel: false
            },
            {
                headerName: this.status,
                field: "status",
                width: 85,
                cellStyle: { 'text-align': 'center' },
                hide: !this.enableChangeTagStatus,
                cellRendererFramework: TagCellRendererComponent
            },
            {
                headerName: this.edit,
                field: "edit",
                width: 85,
                cellStyle: { 'text-align': 'center' },
                hide: !this.enableEditTag,
                cellRendererFramework: TagCellRendererComponent
            },
            {
                headerName: this.delete,
                field: "delete",
                width: 85,
                cellStyle: { 'text-align': 'center' },
                hide: !this.enableDeleteTag,
                cellRendererFramework: TagCellRendererComponent
            }
        ];

        this.getRowNodeId = function (data) {
            return data.id;
        };

        this.gridOptions = {
            rowData: this.rowData,
            columnDefs: this.columnDefs,
            rowSelection: "single",
            rowMultiSelectWithClick: false,
            isExternalFilterPresent: this.externalFilterPresent,
            doesExternalFilterPass: this.externalFilterPass,
            onCellClicked: (params) => {
                this.clickedTimes++;
                this.clickDebouncer(params);
            },
        };

        this.defaultColDef = {
			resizable: true,
			sortable: true,
		};
      
        //custom layout templates for loading and empty data sets
        this.overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Loading data...</span>';
        this.overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">No rows to show</span>';
        tagsService.setCallback(this);
    }

    ngOnInit() {
        this.clickDebouncer = this.debounce(this.actualOnCellClicked, 500, false);
        this.clickedTimes = 0;
        if (this.tagsService.getRowData() != null) {
            this.rowData = this.tagsService.getRowData();

        } else {
            // do nothing
        }

        // Small View
        let uiLayout = JSON.parse(sessionStorage.getItem(SessionStorageConst.UI_LAYOUT));
        if (uiLayout !== UI_LAYOUT.UIL_6) {
            this.smallView = true;
            this.columnDefs = [
                {
                    headerName: 'Type', field: 'type', checkboxSelection: false, hide: true,
                    suppressColumnsToolPanel: true
                },
                {
                    headerName: 'Tag Id', field: 'tagId', checkboxSelection: false, hide: true,
                    suppressColumnsToolPanel: true
                },
                {
                    headerName: this.translate.instant('TAGS_SEARCH_VIEW.TAG_NO'), field: 'tagNo', checkboxSelection: false, hide: false, width: 350,
                    suppressColumnsToolPanel: false, sort: 'asc'
                },
                {
                    headerName: this.translate.instant('TAGS_SEARCH_VIEW.DESCRIPTION'), field: 'description', checkboxSelection: false, hide: false, width: 300,
                    suppressColumnsToolPanel: false
                },
                {
                    headerName: this.translate.instant('TAGS_SEARCH_VIEW.SYSTEM'), field: 'systemName', checkboxSelection: false, hide: false, width: 200,
                    suppressColumnsToolPanel: false
                },
                {
                    headerName: this.translate.instant('TAGS_SEARCH_VIEW.DOCUMENT'), field: 'documentName', checkboxSelection: false, hide: false, width: 200,
                    suppressColumnsToolPanel: false
                },
                // {
                //     headerName: this.translate.instant('TAGS_SEARCH_VIEW.FACILITY'), field: 'facilityName', checkboxSelection: false, hide: false, width: 200,
                //     suppressColumnsToolPanel: false
                // },
                {
                    headerName: this.translate.instant('TAGS_SEARCH_VIEW.BTN_TXT_CLASSIFICATION'), field: 'classification', checkboxSelection: false, hide: false,
                    suppressColumnsToolPanel: false
                }
            ];

             // Attribute List
          this.columnDefsAttr = [	
			{ headerName: "Attribute Name", field: "name", width: 170 },
			{ headerName: "Value", field: "value", width: 150 },		
        ];

        } else {
            //nothing to chnange
        }
        if (uiLayout == UI_LAYOUT.UIL_2 || uiLayout == UI_LAYOUT.UIL_4) {
            this.changeLayout = false;

            this.gridOptions = {
            rowData: this.rowData,
            columnDefs: this.columnDefs,
            defaultColDef: {
                resizable: true,
                sortable: true
            },
            rowSelection: "single",
            rowMultiSelectWithClick: false,
            isExternalFilterPresent: this.externalFilterPresent,
            doesExternalFilterPass: this.externalFilterPass,
            onCellClicked: (params) => {
                this.clickedTimes++;
                this.clickDebouncer(params);
            },
        };
        } else {
            this.changeLayout = true;

            this.gridOptions = {
            rowData: this.rowData,
            columnDefs: this.columnDefs,
            defaultColDef: {
                resizable: true,
                sortable: true
            },
            rowSelection: "single",
            rowMultiSelectWithClick: false,
            isExternalFilterPresent: this.externalFilterPresent,
            doesExternalFilterPass: this.externalFilterPass,
        };
        }
    }

    onGridReady(params) {
        this.gridColumnApi = params.columnApi;
        this.gridApi = params.api;
        params.api.sizeColumnsToFit();
        this.tagsService.setApiGridData(this.gridApi);
        // window.addEventListener("resize", function () {
        //     setTimeout(function () {
        //         params.api.sizeColumnsToFit();
        //     });
        // });
        window.addEventListener("resize", this.onWindowResize);
    }

    private onWindowResize = () => {
   
		setTimeout(() => {
			if (this.gridApi) {
				this.gridApi.sizeColumnsToFit();
			}
		}); 
	};

    ngAfterViewInit(): void {
        //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
        //Add 'implements AfterViewInit' to the class.
        this.subsVar = this.sharedService.expandReusablesExcute().subscribe((option: any) => this.changeComponent(option))
        this.subsVar = this.sharedService.openTagsEditRespond().subscribe(
            (option: any) => this.openEditTagModal(option.tagId)
        )
    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        if (this.subsVar) {
            this.subsVar.unsubscribe()
        }

        window.removeEventListener("resize", this.onWindowResize);
		this.gridApi.destroy();
    	this.gridApi = null;
    }

    changeComponent(option: any) {
        console.log('[changeComponent] (option) ' + JSON.stringify(option));
    }

    private actualOnCellClicked(params: any) {
        if (this.clickedTimes === 1) {
            this.cellClicked(params);
        }
        else if (this.clickedTimes === 2) {
            this.cellDoubleClicked(params);
        }
        this.clickedTimes = 0;
    }

    // Returns a function, that, as long as it continues to be invoked, will not be triggered. The function will be called after it stops being called for
    // N milliseconds. If `immediate` is passed, trigger the function on the leading edge, instead of the trailing.
    private debounce(func: any, wait: any, immediate: any) {
        var timeout;
        return function () {
            var context = this, args = arguments;
            var later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    };

    private cellDoubleClicked(event) {
        let status = event.data.tagStatus;
        if (status != null) {
            if (status == "Active" || status == "active") {
                if (event.colDef.field == "tagNo" || event.colDef.field == "description") {
                    event.data.type = "TagNoDesc";
                    let tabType = SharedService.getTabComponentType();
                    if (tabType == 'Editor') {
                        SharedService.insertEditorRequest(event.data);
                    } else if (tabType == 'Procedures') {
                        SharedService.insertProceduresRequest(event.data);
                    } else if (tabType == 'IceCourse') {
                        SharedService.insertICECourseRequest(event.data);
                    }
                    this.reusableType = "Tag No&Description";
                    this.resuableText = "tnodesc";
                    this.reusableView = event.data.tagNo + "," + event.data.description;
                    this.reusableXML = SharedService.getHtmlString();
                    this.save(this.reusableXML, this.resuableText, this.reusableType, this.reusableView);
                }
            } else {
                this.translate.get('REUSABLES.COULD_NOT_ENTER_DISABLED_REUSABLES').subscribe((res: string) => {
                    this.alertService.error(res, false);
                });
            }
        }
    }

    private cellClicked(event) { 
        let status = event.data.tagStatus;
        //console.log("TagStatus 0000000000000000 " +JSON.stringify(event.data));
		if (status != null) {
			if (status == "Active" || status == "active") {
                if (event.colDef.field == "tagNo") {
                    this.classificationClicked = false;
                    event.data.type = "TagNo";
                    let tabType = SharedService.getTabComponentType();
                    if (tabType == 'Editor') {
                        SharedService.insertEditorRequest(event.data);
                    } else if (tabType == 'Procedures') {
                        SharedService.insertProceduresRequest(event.data);
                    } else if (tabType == 'IceCourse') {
                        SharedService.insertICECourseRequest(event.data);
                    }
                    this.reusableType = "Tag No";
                    this.resuableText = "tno";
                    this.reusableView = event.data.tagNo;
                    this.reusableXML = SharedService.getHtmlString();
                    this.save(this.reusableXML, this.resuableText, this.reusableType, this.reusableView);
        
                } else if (event.colDef.field == "description") {
                    this.classificationClicked = false;
                    event.data.type = "Description";
                    let tabType = SharedService.getTabComponentType();
                    if (tabType == 'Editor') {
                        SharedService.insertEditorRequest(event.data);
                    } else if (tabType == 'Procedures') {
                        SharedService.insertProceduresRequest(event.data);
                    } else if (tabType == 'IceCourse') {
                        SharedService.insertICECourseRequest(event.data);
                    }
                    this.reusableType = "Tag Description";
                    this.resuableText = "tag";
                    this.reusableView = event.data.description;
                    this.reusableXML = SharedService.getHtmlString();
                    this.save(this.reusableXML, this.resuableText, this.reusableType, this.reusableView);
        
                } else if (event.colDef.field == "systemName") {
                    this.classificationClicked = false;
                    event.data.type = "System";
                    let tabType = SharedService.getTabComponentType();
                    if (tabType == 'Editor') {
                        SharedService.insertEditorRequest(event.data);
                    } else if (tabType == 'Procedures') {
                        SharedService.insertProceduresRequest(event.data);
                    } else if (tabType == 'IceCourse') {
                        SharedService.insertICECourseRequest(event.data);
                    }
                    this.reusableType = "Tag System";
                    this.resuableText = "tsys";
                    this.reusableView = event.data.systemName;
                    this.reusableXML = SharedService.getHtmlString();
                    this.save(this.reusableXML, this.resuableText, this.reusableType, this.reusableView);
        
                } else if (event.colDef.field == "documentName") {
                    this.classificationClicked = false;
                    event.data.type = "TagDocument";
                    let tabType = SharedService.getTabComponentType();
                    if (tabType == 'Editor') {
                        SharedService.insertEditorRequest(event.data);
                    } else if (tabType == 'Procedures') {
                        SharedService.insertProceduresRequest(event.data);
                    } else if (tabType == 'IceCourse') {
                        SharedService.insertICECourseRequest(event.data);
                    }
                    this.reusableType = "Tag Document";
                    this.resuableText = "tdoc";
                    this.reusableView = event.data.documentName;
                    this.reusableXML = SharedService.getHtmlString();
                    this.save(this.reusableXML, this.resuableText, this.reusableType, this.reusableView);
        
                } else if (event.colDef.field == "facilityName") {
                    this.classificationClicked = false;
                    event.data.type = "TagFacilityName";
                    let tabType = SharedService.getTabComponentType();
                    if (tabType == 'Editor') {
                        SharedService.insertEditorRequest(event.data);
                    } else if (tabType == 'Procedures') {
                        SharedService.insertProceduresRequest(event.data);
                    } else if (tabType == 'IceCourse') {
                        SharedService.insertICECourseRequest(event.data);
                    }
                    this.reusableType = "Facility_tag";
                    this.resuableText = "fac";
                    this.reusableView = event.data.facilityName;
                    this.reusableXML = SharedService.getHtmlString();
                    this.save(this.reusableXML, this.resuableText, this.reusableType, this.reusableView);
                }
                else if (event.colDef.field == "classification") {             
                    this.classificationClicked = true;
                    this.tagAttributesNew = [];
                    this.tagId = event.data.tagId;
                    this.tagIdAttr = event.data.tagId;
                    this.classificationId = event.data.classificationId;
                    if(this.classificationId != null && this.classificationId != undefined && this.classificationId != "") {
                        this.loading =true;
                        if(this.tagId != null) {
                            this.token = UserVariable.getUserToken();
                            if(!this.commonUtil.validateToken(this.token)){
                                return;
                            }
                            let request: any = { 'tagId': this.tagId, 'userToken': this.token };
                            this.tagsService.getTagByTagIdNew(request, this);
                        } else {
                            this.alertService.error('tag id is not available');
                        }
                    } else {
                        this.loading =false;
                    }
                }
			} else {
				this.translate.get('REUSABLES.COULD_NOT_ENTER_DISABLED_REUSABLES').subscribe((res: string) => {
                    this.alertService.error(res, false);
                });
			}
		}
       
    }

    save(reusableXML: any, reusableText: any, reusableType: any, reusableView: any) {
        if (this.reusableXML != null) {
            let xml = this.reusableXML.replace(/insid=.{38}/gi, function insId() {
                function s4() {
                    return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
                }
                return 'insId="' + s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4() + '"';
            });
            let to: any = false;
            if (to) { clearTimeout(to); }
            to = setTimeout(() => {
                this.historyService.saveToHistory(this.reusableType, this.resuableText, xml, this.reusableView).subscribe(
                    data => {
                        console.log("success");
                    },
                    error => {
                        this.alertService.clear()
                        this.alertService.error(error.statusDescription)
                    });;
            }, 200);
        }
    }

    search(event: any) {
        this.documentName = "";
        this.facilityName = "";
        this.description = "";
        this.systemName = "";
        this.tagNo = "";
        this.cadNo = "";
        this.count = 0;
        let docArray = event.target.value.split(",");
        for (var l = 0; l <= docArray.length - 1; l++) {
            let letter = docArray[l].split(":");
            if (letter != "") {
                for (var i = 0; i < letter.length - 1; i++) {
                    if (letter[i] == "tdoc") {
                        this.documentName = letter[i + 1];
                    }
                    else if (letter[i] == "fac") {
                        this.facilityName = letter[i + 1];
                    } else if (letter[i] == "tno") {
                        this.tagNo = letter[i + 1];
                    } else if (letter[i] == "tsys") {
                        this.systemName = letter[i + 1];
                    } else if (letter[i] == "tag") {
                        this.description = letter[i + 1];
                    } else {
                        if (this.count == 0) {
                            this.count = 1;
                            this.error();
                        } else {
                            // do nothing dname fac tno sName desc
                        }
                        this.count = 1;
                    }
                }
            }
        }
        if (this.documentName != "" || this.facilityName != "" || this.description != "" || this.systemName != "" || this.tagNo != "") {
            let to: any = false;
            if (to) { clearTimeout(to); }
            to = setTimeout(() => {
                this.gridOptions.api.onFilterChanged();
                this.tagsService.searchTags(this.tagNo, this.cadNo, this.description, this.systemName, this.documentName, this.facilityName, this.hasText);
            }, 200);
        }

    }
    error() {
        this.alertService.error("wrong value inserted");
    }

    /**
     * Set row data of tag search to the table.
     * @param payload  
     */
    setTagSearchTableData(payload: any) {
        this.loadingService.hideLoading();
        let parser = new DOMParser();
        this.filteredData = [];
        this.rowData = [];
        if (payload != null) {
            console.log('payloaddd', payload);
            this.dependenciesmodalService.setCallback(this);
            for (let i = 0; i < payload.length; i++) {
                let desXml = parser.parseFromString(payload[i]["description"], "text/xml");
                let curSysName: String = payload[i]["systemName"];
                if ((curSysName != null) && (curSysName.startsWith("-"))) {
                    curSysName = curSysName.substring(1);
                }
                this.filteredData.push({
                    'id': payload[i]["tagId"],
                    'tagId': payload[i]["tagId"],
                    'type': payload[i]["type"],
                    'tagNo': payload[i]["tagNo"],
                    'cadNo': payload[i]["cadNo"],
                    'description': desXml.getElementsByTagName("txt")[0] != undefined ? desXml.getElementsByTagName("txt")[0].childNodes[0].nodeValue : "",
                    'systemName': curSysName,
                    'systemId': payload[i]["systemId"],
                    'documentName': payload[i]["documentName"],
                    'documentId': payload[i]["documentId"],
                    'facilityName': payload[i]["facilityName"],
                    'facilityId': payload[i]["facilityId"],
                    'classification': payload[i]["className"],
                    'classificationId':payload[i]["classId"],
                    'tagNoUpdateTimeStamp': payload[i]["tagNoUpdateTimeStamp"],
                    'tagDescUpdateTimeStamp': payload[i]["tagDescUpdateTimeStamp"],
                    'tagSysUpdateTimeStamp': payload[i]["tagSysUpdateTimeStamp"],
                    'tagDocUpdateTimeStamp': payload[i]["tagDocUpdateTimeStamp"],
                    'tagFacilityLastUpdateTimeStamp': payload[i]["tagFacilityLastUpdateTimeStamp"],
                    'status': "status:" + payload[i]["tagId"] + ":" + payload[i]["status"],
                    'edit': "edit:" + payload[i]["tagId"] + ":" + payload[i]["status"],
                    'delete': "delete:" + payload[i]["tagId"] + ":" + payload[i]["status"],
//                    'dependencies': payload[i]["referenceCount"] == "0" ? "disable_dependencies:" + payload[i]["tagId"] : "dependencies:" + payload[i]["tagId"],
                    'dependencies': "disable_dependencies:" + payload[i]["tagId"],
                    'tagStatus' : payload[i]["status"]
                });
                this.dependenciesmodalService.getDependeciesWithId( payload[i]["tagId"], "Tag");
            }
            this.rowData = this.filteredData;
            this.gridApi.paginationProxy.currentPage = 0;
            this.gridOptions.api.sizeColumnsToFit();
        } else {
            // no row data to show 
            this.rowData = [];
        }
        this.tagsService.setRowData(this.rowData);
    }

    /**
     * change tag status in search table
     * @private
     * @memberof TagsComponent
     */
    private updateStatus(){
        if(this.sharedService.getFileType() !="Tag"){
            return
        }
        let tagId = this.sharedService.getFileId()
        let newStatus = null
        if(this.sharedService.getAction() == "disable"){
            newStatus = "Inactive"
            this.alertService.success(this.translate.instant('TAGS_SEARCH_VIEW.DISABLE_SUCCESS'))
        }else{
            newStatus = "Active"
            this.alertService.success(this.translate.instant('TAGS_SEARCH_VIEW.ENABLE_SUCCESS'))
        }
        this.rowData.map((tag, i) => {
            if (tag.tagId == tagId){
                let node = this.rowData[i] 
                node.status =  "status:" + tag.tagId + ":" + newStatus
                node.edit = "edit:" + tag.tagId + ":" + newStatus
                node.delete = "delete:" + tag.tagId + ":" + newStatus
                node.tagStatus = newStatus
                this.rowData[i] = node
                this.tagsService.setRowData(this.rowData);
                this.gridApi.redrawRows()
                console.log("node= "+ JSON.stringify(node))
            }
           }); 
    }

    /**
     *remove deleted tag from search table
     * @private
     * @memberof TagsComponent
     */
    private removeTag(){
        if(this.sharedService.getFileType() !="Tag" && this.sharedService.getAction() != "delete"){
            return
        }
        let tagId = this.sharedService.getFileId()
        for (let i = 0; i < this.rowData.length; i++) {
            if(this.rowData[i].tagId == tagId){
                this.rowData.splice(i, 1);  
                break;
            }
        }
        let selectedData = this.gridApi.getSelectedRows();
        this.tagsService.setRowData(this.rowData);
        this.gridApi.applyTransaction({ remove: selectedData });
    }

    /**
       * Success function for tags service.
       * @param data 
       * @param serviceType 
       */
    onSuccess(data: WsResponse, serviceType: WsType): void {
        //this.loadingService.hideLoading();
        if (data != null && serviceType == WsType.TAG_SEARCH_VIEW) {
            this.setTagSearchTableData(data.payload)
        }else if(serviceType == WsType.CHANGE_TAG_STATUS){
            this.updateStatus();
        }else if(serviceType == WsType.DELETE_TAG){
            this.alertService.success(this.translate.instant('TAGS_SEARCH_VIEW.DELETE_TAG'))
            this.removeTag();
            //re initialize variables
            this.reinitializeVariables();
        } else if (data.payload != null && serviceType == WsType.ITEM_DEPENDENCY_LIST) {
            let items = data.payload.split("_");
            if (items[1] == "Yes")
            {
                for(let i=0; i<this.rowData.length; i++)
                {
                    if (this.rowData[i].id == items[0])
                    {
                       //var rowNode = this.gridApi.getRowNode(items[0]);
                       //rowNode.setDataValue("dependencies", "dependencies:" + items[0]);
                       this.rowData[i].dependencies = "dependencies:" + items[0];
                       if(!this.gridApi.destroyCalled) {
                            this.gridApi.setRowData(this.rowData);
                       }
                    }
                }
                if(!this.gridApi.destroyCalled) {
                    this.gridApi.redrawRows();
                }
            }
        } else if(serviceType == WsType.GET_TAG_BY_TAG_ID) {
                this.tagAttributes = data.payload.tagAttributes;
                this.tagAttributesNew = data.payload.tagAttributes;
                this.systems = data.payload.systems;
                this.documents = data.payload.documents;
                this.components = data.payload.components;
                this.documentListAllNew = data.payload.documentListAll;
                // this.prepareNgSelectSystems();
			    // this.prepareNgSelectDocuments();
                // this.prepareNgSelectComponents();
                this.prepareTagAttributes();
                this.isShowTagAttributes = true;
        } else if(serviceType == WsType.GET_TAG_BY_TAG_ID_NEW) {
            this.tagAttributesNew = data.payload.tagAttributes;
            //Achini Randeni
            //set values for the systems, documents, documentListAllNew and components lists
            this.systems = data.payload.systems;
            this.documents = data.payload.documents;
            this.components = data.payload.components;
            this.documentListAllNew = data.payload.documentListAll;
            if (this.tagAttributesNew.length != 0) {
                this.hasAttr = true;
                this.loading = false;
            } else {
                this.hasAttr = false;
                this.loading = false;
            }            
            this.isShowTagAttributes = true;
            this.setTagAttributes();
        } else if(serviceType == WsType.UPDATE_TAG_CLASS_ATTRIBUTE_VALUE){
            setTimeout(() => {
				this.loadingService.hideLoading();
                this.alertService.success(this.translate.instant("TAGS_SEARCH_VIEW.EDIT_TAG_ATTRIBUTE_VALUE"));
                //re initialize variables
                this.reinitializeVariables();
			}, 6000);	
        }else {
            this.alertService.error(this.translate.instant('TAGS_SEARCH_VIEW.LOAD_ERROR'), true);
        }
    }
    
    /**
     * On fail function for tags service.
     * @param data 
     * @param serviceType 
     */
    onFail(data: WsResponse, serviceType: WsType): void {
        this.loadingService.hideLoading();
        if (data != null && serviceType == WsType.TAG_SEARCH_VIEW) {
            this.alertService.error(data.statusDescription, true);
        }else if(serviceType == WsType.CHANGE_TAG_STATUS){
            this.alertService.error(data.statusDescription, true);
        } else if(serviceType == WsType.DELETE_TAG){
            this.alertService.error(data.statusDescription, true);
        }else if(serviceType == WsType.UPDATE_TAG_CLASS_ATTRIBUTE_VALUE){
            this.alertService.error(data.statusDescription, true);
        }else {
            this.alertService.error(this.translate.instant('TAGS_SEARCH_VIEW.LOAD_ERROR'), true);
        }
    }

    externalFilterPresent() {
        return this.tagNo != "";
    }

    externalFilterPass(node: any) {
        return true;
    }


    /** Previous tag search methods
     * commentted
     */

    // searchByTagNo(event: any) {
    //     let to: any = false;
    //     if (to) { clearTimeout(to); }
    //     to = setTimeout(() => {
    //         this.tagNo = event.target.value;
    //         this.description = this.descriptionElem.nativeElement.value;
    //         this.systemName = this.systemNameElem.nativeElement.value;
    //         this.documentName = this.documentNameElem.nativeElement.value;
    //         this.facilityName = this.facilityNameElem.nativeElement.value;
    //         this.gridOptions.api.onFilterChanged();
    //         this.tagsService.searchTags(this.tagNo, this.description, this.systemName, this.documentName, this.facilityName);
    //     }, 200);
    // }

    // searchByDescription(event: any) {
    //     let to: any = false;
    //     if (to) { clearTimeout(to); }
    //     to = setTimeout(() => {
    //         this.tagNo = this.tagNoElem.nativeElement.value;
    //         this.description = event.target.value;
    //         this.systemName = this.systemNameElem.nativeElement.value;
    //         this.documentName = this.documentNameElem.nativeElement.value;
    //         this.facilityName = this.facilityNameElem.nativeElement.value;
    //         this.gridOptions.api.onFilterChanged();
    //         this.tagsService.searchTags(this.tagNo, this.description, this.systemName, this.documentName, this.facilityName);
    //     }, 200);
    // }

    // searchBySystemName(event: any) {
    //     let to: any = false;
    //     if (to) { clearTimeout(to); }
    //     to = setTimeout(() => {
    //         this.tagNo = this.tagNoElem.nativeElement.value;
    //         this.description = this.descriptionElem.nativeElement.value;
    //         this.systemName = event.target.value;
    //         this.documentName = this.documentNameElem.nativeElement.value;
    //         this.facilityName = this.facilityNameElem.nativeElement.value;
    //         this.gridOptions.api.onFilterChanged();
    //         this.tagsService.searchTags(this.tagNo, this.description, this.systemName, this.documentName, this.facilityName);
    //     }, 200);
    // }

    // searchByDocumentName(event: any) {
    //     let to: any = false;
    //     if (to) { clearTimeout(to); }
    //     to = setTimeout(() => {
    //         this.tagNo = this.tagNoElem.nativeElement.value;
    //         this.description = this.descriptionElem.nativeElement.value;
    //         this.systemName = this.systemNameElem.nativeElement.value;
    //         this.documentName = event.target.value;
    //         this.facilityName = this.facilityNameElem.nativeElement.value;
    //         this.gridOptions.api.onFilterChanged();
    //         this.tagsService.searchTags(this.tagNo, this.description, this.systemName, this.documentName, this.facilityName);
    //     }, 200);
    // }

    // searchByFacilityName(event: any) {
    //     let to: any = false;
    //     if (to) { clearTimeout(to); }
    //     to = setTimeout(() => {
    //         this.tagNo = this.tagNoElem.nativeElement.value;
    //         this.description = this.descriptionElem.nativeElement.value;
    //         this.systemName = this.systemNameElem.nativeElement.value;
    //         this.documentName = this.documentNameElem.nativeElement.value;
    //         this.facilityName = event.target.value;
    //         this.gridOptions.api.onFilterChanged();
    //         this.tagsService.searchTags(this.tagNo, this.description, this.systemName, this.documentName, this.facilityName);
    //     }, 200);
    // }

    searchFunc(data: any) {
        console.log('[searchFunc] ' + JSON.stringify(data));
        this.alertService.clear();
        this.gridApi.showLoadingOverlay();
        this.documentName = "";
        this.facilityName = "";
        this.description = "";
        this.systemName = "";
        this.tagNo = "";
        this.cadNo = "";
        this.hasText = "";
        this.count = 0;

        //check empty values in search 
        if (this.checkForEmptyValuesInSearchBar(data.searchTxt)) {

            //assign value for the fields from searchWordMap
            var docName = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_TAG_TAG_DOCUMENT];
            if (docName != undefined) {
                this.documentName = docName;
            } else {
                //no doc name
            }
            var facilityName = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_TAG_TAG_FACILITY];
            if (facilityName != undefined) {
                this.facilityName = facilityName;
            } else {
                //no facility name
            }
            var description = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_TAG_TAG_DESCRIPTION];
            if (description != undefined) {
                this.description = description;
            } else {
                //no description
            }
            var systemName = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_TAG_TAG_SYSTEM];
            if (systemName != undefined) {
                this.systemName = systemName;
            } else {
                //no system name
            }
            var tagNo = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_TAG_TAG_NUM];
            if (tagNo != undefined) {
                this.tagNo = tagNo;
            } else {
                //no tag no
            }
            var cadNo = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_TAG_TAG_CAD_NUMBER];
            if (cadNo != undefined) {
                this.cadNo = cadNo;
            } else {
                //no cad no
            }
            var hasText = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_HAS_THE_WORDS];
            if (hasText != undefined) {
                this.hasText = hasText;
            } else {
                //no has text
            }

            /*let docArray = data.searchTxt.split(" ");
            for (var l = 0; l <= docArray.length - 1; l++) {
                let letter = docArray[l].split(":");
                if (letter != "") {
                    for (var i = 0; i < letter.length - 1; i++) {
                        if (letter[i] == "tdoc") {
                            this.documentName = letter[i + 1];
                        }
                        else if (letter[i] == "fac") {
                            this.facilityName = letter[i + 1];
                        } else if (letter[i] == "tno") {
                            this.tagNo = letter[i + 1];
                        } else if (letter[i] == "tsys") {
                            this.systemName = letter[i + 1];
                        } else if (letter[i] == "tag") {
                            this.description = letter[i + 1];
                        } else {
                            if (this.count == 0) {
                                this.count = 1;
                                this.error();
                            } else {
                                // do nothing dname fac tno sName desc
                            }
                            this.count = 1;
                        }
                    }
                }
            }*/
            if (this.documentName != "" || this.facilityName != "" || this.description != "" || this.systemName != "" || this.tagNo != "" || this.cadNo != "" || this.hasText != "") {
                let to: any = false;
                if (to) { clearTimeout(to); }
                to = setTimeout(() => {

                    this.gridOptions.api.onFilterChanged();
                    this.tagsService.searchTags(this.tagNo, this.cadNo, this.description, this.systemName, this.documentName, this.facilityName, this.hasText);
                }, 200);
            }
        } else {
            //search bar has empty values
            this.gridOptions.api.hideOverlay()
            this.setTagSearchTableData(null);
        }
    }

    searchBarEnterFunc(data: any) {
        console.log('[searchBarEnterFunc] ' + JSON.stringify(data));
        this.alertService.clear();
        this.gridApi.showLoadingOverlay();
        this.documentName = "";
        this.facilityName = "";
        this.description = "";
        this.systemName = "";
        this.tagNo = "";
        this.cadNo = "";
        this.count = 0;

        if (this.checkForEmptyValuesInSearchBar(data)) {
            let docArray = data.split(" ");
            for (var l = 0; l <= docArray.length - 1; l++) {
                let letter = docArray[l].split(":");
                if (letter != "") {
                    for (var i = 0; i < letter.length - 1; i++) {
                        if (letter[i] == "tdoc") {
                            this.documentName = letter[i + 1];
                        }
                        else if (letter[i] == "fac") {
                            this.facilityName = letter[i + 1];
                        } else if (letter[i] == "tno") {
                            this.tagNo = letter[i + 1];
                        } else if (letter[i] == "tsys") {
                            this.systemName = letter[i + 1];
                        } else if (letter[i] == "tag") {
                            this.description = letter[i + 1];
                        } else {
                            if (this.count == 0) {
                                this.count = 1;
                                this.error();
                            } else {
                                // do nothing dname fac tno sName desc
                            }
                            this.count = 1;
                        }
                    }
                }
            }
            if (this.documentName != "" || this.facilityName != "" || this.description != "" || this.systemName != "" || this.tagNo != "" || this.cadNo != "") {
                let to: any = false;
                if (to) { clearTimeout(to); }
                to = setTimeout(() => {
                    this.gridOptions.api.onFilterChanged();
                    this.tagsService.searchTags(this.tagNo, this.cadNo, this.description, this.systemName, this.documentName, this.facilityName, this.hasText);
                }, 200);
            }
        } else {
            this.gridOptions.api.hideOverlay()
            this.setTagSearchTableData(null);
        }
    }

    /**
     * check for empty values in search bar
     * @param data 
     */
    public checkForEmptyValuesInSearchBar(data: any): boolean {
        let docArray = data.split(" ");
        if (docArray.length > 1) {
            return true
        } else {
            if (docArray[0] != '') {
                return true
            } else {
                return false
            }
        }
    }

    public newTagButtonClick() {
        // this.router.navigateByUrl('addtags');
        this.bsModalRef = this.modalService.show(AddTagComponent, { class: 'modal-xl', backdrop: 'static', keyboard: false })
    }

    private openEditTagModal(tagId: string) {
        console.log('[openEditTagModal] (tagId) ' + JSON.stringify(tagId));
        const initialState: any = {
            tagId: tagId
        }
        this.bsModalRef = this.modalService.show(EditTagsComponent, { initialState, class: 'modal-xl', backdrop: 'static', keyboard: false })
    }

    public bulkImportClick() {
        console.log('[bulkImportClick]');
    }

    public classificationClick() {
        console.log('[classificationClick]');
        window.open('/tagclassification', '_blank');
    }

    public valueListClick() {
        console.log('[valueListClick]');
        window.open('/tagvaluelist', '_blank');
    }

    //TAG ATTRIBUTES
	/**
	 * Prepare list items for the system drop-down list
	 */
	// private prepareNgSelectSystems() {
	// 	this.systems.forEach(system => {
	// 		this.systemListForNgSelect.push({ "id": system.id, "text": system.systemName });
	// 		this.tagAttributes.forEach(sys => {
	// 			if (sys.value == system.id) {
	// 				this.selectedSystemOptions.push({ "id": sys.attributeId + '_' + sys.value, "text": system.systemName });
	// 				//console.log('Systems - ' + this.selectedSystemOptions.length);
	// 			}
	// 		});
	// 	});
	// }

	// /**
	//  * Prepare the list items for the document drop-down list.
	//  */
	// private prepareNgSelectDocuments() {
	// 	this.documents.forEach(doc => {
	// 		this.documentListForNgSelect.push({ "id": doc.id, "text": doc.documentName });
	// 		this.tagAttributes.forEach(selectedDoc => {
	// 			if (selectedDoc.value == doc.id) {
	// 				this.selectedDocumentOptions.push({ "id": selectedDoc.attributeId + '_' + selectedDoc.value, "text": doc.documentName });
	// 			}
	// 		});
	// 	});
	// }

	// private prepareNgSelectComponents() {
	// 	this.components.forEach(comp => {
	// 		this.componentListForNgSelect.push({ "id": comp.id, "text": comp.componentValue });
	// 		this.tagAttributes.forEach(selectedCmp => {
	// 			if (selectedCmp.value == comp.id) {
	// 				this.selectedComponentOptions.push({ "id": selectedCmp.attributeId + '_' + selectedCmp.value, "text": comp.componentValue });
	// 			}
	// 		});
	// 	});
    // }
    
	private prepareTagAttributes() {
		this.tagAttributes.forEach((attrib, i) => {
            console.log("[" + i + "]===attrib.id=" + attrib.attributeId + "===attrib.type=" + attrib.attributeType + "========= attrib.value=" +  attrib.value + "=");
            this.selectedTagAttributeValue[i] = { "id": attrib.attributeId + '_' + '', "text": ''};
            var selectedTagAttrib = [];
            if (attrib.attributeType === "Component") {
                //Add the component List as componentListForNgSelect
                this.components.forEach(comp => {
                    if (this.componentListForNgSelect.length <= this.components.length) {
                        this.componentListForNgSelect.push({ "id": comp.id, "text": comp.componentValue });
                    }
                    //Set the Selected Value to selectedItemList
                    if (attrib.value == comp.id) {
                        selectedTagAttrib.push({ "id": attrib.attributeId + '_' + attrib.value, "text": comp.componentValue });
                    }
                });
			} else if (attrib.attributeType === "System") {
                this.systems.forEach(system => {
                    if (this.systemListForNgSelect.length <= this.systems.length) {
                        this.systemListForNgSelect.push({ "id": system.id, "text": system.systemNo + ' ' + system.systemName });
                    }
                    //Set the Selected Value to selectedItemList
                    if (attrib.value == system.id) {
                        selectedTagAttrib.push({ "id": attrib.attributeId + '_' + attrib.value, "text": system.systemNo + ' ' + system.systemName });
                    }
                });
			} else if (attrib.attributeType === "Document") {
                this.documentListAllNew.forEach(doc => {
                    if (this.documentListForNgSelect.length <= this.documentListAllNew.length) {
                        this.documentListForNgSelect.push({ "id": doc.id, "text": doc.documentName });
                    }
                    //Set the Selected Value to selectedItemList
                    if (attrib.value == doc.id) {
                        selectedTagAttrib.push({ "id": attrib.attributeId + '_' + attrib.value, "text": doc.documentName });
                    }
                });
            }
            this.selectedTagAttributeValue[i] = {selectedDataId: selectedTagAttrib};
		});
	}


    public getComponentListByCategory(categoryId: string){
		let compListBycategory = []
		if(categoryId != null && categoryId != ''){
			this.components.forEach(component => {
				if(component.categoryId == categoryId){
					let node = { "id": component.id, "text": component.componentValue };
					compListBycategory.push(node)
				}
			})
		}
		else{
			//set all the components when the tag attribute type category is not selected
			compListBycategory = this.componentListForNgSelect
		}
		return compListBycategory
    }
    
    /**
     * Identifying the changes of tag attribute values
     * @param val 
     * @param type 
     * @param attributeId 
     * @param tagSpecId 
     */
    public getValueChanges(val, t_index: number, type: string, attributeId: string, tagSpecId: string = "", required: string) {
        console.log(JSON.stringify(val));
        //checking existing attributes from the selectedChangedAttributes list
        let index = -1;
        for (let i = 0; i < this.selectedChangedAttributes.length; i++) {
            if (this.selectedChangedAttributes[i].attributeId == attributeId) {
                index = i;
            }
        }                
        console.log("Old Value: " + this.tagAttributes[t_index].value);
		switch (type) {
			case 'tag_attribute_component':
                    if (index == -1) {
                        //if the item is not existed.
                        this.selectedChangedAttributes[this.selectedChangedAttributes.length] = { 
                            attributeId: attributeId, 
                            value: val.id, 
                            classId: this.classificationId,
                            tagClassSpecId: tagSpecId,
                            tagId: this.tagId,
                            oldValue: this.tagAttributes[t_index].value,
                            required: required
                         };
                    }
                    else{
                         //if the item is existed.
                        this.selectedChangedAttributes[index] = { 
                            attributeId: attributeId, 
                            value: val.id, 
                            classId: this.classificationId,
                            tagClassSpecId: tagSpecId,
                            tagId: this.tagId,
                            oldValue: this.tagAttributes[t_index].value,
                            required: required
                         };
                    }
				break;
			case 'tag_attribute_system':
                if (index == -1) {
                    //if the item is not existed.
                    this.selectedChangedAttributes[this.selectedChangedAttributes.length] = { 
                        attributeId: attributeId, 
                        value: val.id, 
                        classId: this.classificationId,
                        tagClassSpecId: tagSpecId,
                        tagId: this.tagId,
                        oldValue: this.tagAttributes[t_index].value,
                        required: required
                        };
                }
                else{
                        //if the item is existed.
                    this.selectedChangedAttributes[index] = { 
                        attributeId: attributeId, 
                        value: val.id, 
                        classId: this.classificationId,
                        tagClassSpecId: tagSpecId,
                        tagId: this.tagId,
                        oldValue: this.tagAttributes[t_index].value,
                        required: required
                        };
                }
				break;
			case 'tag_attribute_document':
                if (index == -1) {
                    //if the item is not existed.
                    this.selectedChangedAttributes[this.selectedChangedAttributes.length] = { 
                        attributeId: attributeId, 
                        value: val.id, 
                        classId: this.classificationId,
                        tagClassSpecId: tagSpecId,
                        tagId: this.tagId,
                        oldValue: this.tagAttributes[t_index].value,
                        required: required
                        };
                }
                else{
                        //if the item is existed.
                    this.selectedChangedAttributes[index] = { 
                        attributeId: attributeId, 
                        value: val.id, 
                        classId: this.classificationId,
                        tagClassSpecId: tagSpecId,
                        tagId: this.tagId,
                        oldValue: this.tagAttributes[t_index].value,
                        required: required
                        };
                }
                break;
            case 'tag_attribute_date':
                    if (index == -1) {
                        //if the item is not existed.
                        this.selectedChangedAttributes[this.selectedChangedAttributes.length] = {
                            attributeId: attributeId, 
                            value: val.currentTarget.value, 
                            classId: this.classificationId,
                            tagClassSpecId: tagSpecId,
                            tagId: this.tagId,
                            oldValue: this.tagAttributes[t_index].value,
                            required: required
                        };
                    } else {
                        //if the item is existed.
                        this.selectedChangedAttributes[index] = {
                            attributeId: attributeId, 
                            value: val.currentTarget.value, 
                            classId: this.classificationId,
                            tagClassSpecId: tagSpecId,
                            tagId: this.tagId,
                            oldValue: this.tagAttributes[t_index].value,
                            required: required
                        };
                    }
                break;
			case 'tag_attribute_text':
                if (index == -1) {
                    //if the item is not existed.
                    this.selectedChangedAttributes[this.selectedChangedAttributes.length] = {
                        attributeId: attributeId, 
                        value: val.currentTarget.value, 
                        classId: this.classificationId,
                        tagClassSpecId: tagSpecId,
                        tagId: this.tagId,
                        oldValue: this.tagAttributes[t_index].value,
                        required: required
                    };
                } else {
                    //if the item is existed.
                    this.selectedChangedAttributes[index] = {
                        attributeId: attributeId, 
                        value: val.currentTarget.value, 
                        classId: this.classificationId,
                        tagClassSpecId: tagSpecId,
                        tagId: this.tagId,
                        oldValue: this.tagAttributes[t_index].value,
                        required: required
                    };
                }
                break;
            default:
                break;
            }
        }

    /**
     * Display tag attribute values on row click event if the selected tag has classification
     */
    onRowClick(event:any) {
        //re initialize variables
        this.reinitializeVariables();
        this.tagId = event.data.tagId;
        this.classificationId = event.data.classificationId;
        if(this.classificationId != null && this.classificationId != undefined && this.classificationId != "") {
            if(this.tagId != null) {
                this.token = UserVariable.getUserToken();
                if(!this.commonUtil.validateToken(this.token)){
                    return;
                }
                let request: any = { 'tagId': this.tagId, 'userToken': this.token };
                this.tagsService.getTagByTagId(request, this);
            } else {
                this.alertService.error('tag id is not available');
            }
        } 
    }

    /**
     * Update tag attribute values
     */
    public tagAttributeSaveButtonClick(){
        if(this.selectedChangedAttributes.length > 0){
            //Check required fields of tag atributes
            for (let i = 0; i < this.selectedChangedAttributes.length; i++){
				let requiredField = this.selectedChangedAttributes[i].required;
				if (requiredField == "yes") {
					let selectedAttributeValue = this.selectedChangedAttributes[i].value;
					if(selectedAttributeValue == null || selectedAttributeValue == ""){
						this.alertService.error("Attribute cannot be empty");
						return;
					}
				}
            }
            
            let data = {
                tagId: this.tagId,
                tagAttributes: this.selectedChangedAttributes != undefined ? this.selectedChangedAttributes : []
            };
           
            var json = JSON.stringify(data);
            this.loadingService.showLoading(true, false, this.translate.instant('SAVING'), 0);
            this.tagsService.updateTagClassAttributeValue(this.token, json, this);
        }
        else{
            this.alertService.info(this.translate.instant('TAGS_SEARCH_VIEW.SAVE_TAG_ATTRIBUTE_WITH_NO_CHANGES'));
        }  
    }

    /**
     * re initialize variables when the cancel button is clicked
     */
    public tagAttributeCancelButtonClick(){
        this.reinitializeVariables();
    }

    /**
     * re initialize variables
     */
    public reinitializeVariables(){
        this.selectedChangedAttributes = [];
        this.documentListForNgSelect = [];
        //this.selectedDocumentOptions = [];
        this.componentListForNgSelect = [];
        //this.selectedComponentOptions = [];
        this.systemListForNgSelect = [];
        //this.selectedSystemOptions = [];
        this.isShowTagAttributes = false;
    }

    /**
     * enable/disable buttons or columns
     */
    private enableDisableButtons(){
        this.capabilityCheck();
        this.enableAddTag = this.createCap;
        this.enableSearchTag = this.queryCap;
        this.enableEditTag = this.modifyCap;
        this.enableDeleteTag = this.deleteCap;
        this.enableChangeTagStatus = this.disableCap;
        this.enableSearchTagClassification = this.queryTagClassificationCap;
        this.enableSearchValueList = this.queryValueListCap;
    }

    /**
     * check the capabilities for given type
     */
    public capabilityCheck(){
        let modifyCapability: string = "modifyTag";
        let queryCapability: string = "queryTags";
        let deleteCapability: string = "deleteTag";
        let disableCapability: string = "disableTag";
        let createCapability: string = "createTag";
        let queryCapabilityTagClassification: string = "queryTagClassification";
        let queryCapabilityValueList: string = "queryValueList";

        this.modifyCap = this.capabilityService.isCapabilityAssigned(modifyCapability);
        this.queryCap = this.capabilityService.isCapabilityAssigned(queryCapability);
        this.deleteCap = this.capabilityService.isCapabilityAssigned(deleteCapability);
        this.disableCap = this.capabilityService.isCapabilityAssigned(disableCapability);
        this.createCap = this.capabilityService.isCapabilityAssigned(createCapability);
        this.queryTagClassificationCap = this.capabilityService.isCapabilityAssigned(queryCapabilityTagClassification);
        this.queryValueListCap = this.capabilityService.isCapabilityAssigned(queryCapabilityValueList);
    }

    /**
	 * Setvalues for tag attributes small view table
	 * @param val
	 */
	public setTagAttributes() {	
        this.rowDataTagAttr = [];       
		var tagAttributeValue = ""; //initialize the tagAttributeValue
		for (let i = 0; i < this.tagAttributesNew.length; i++) {
            if (this.tagAttributesNew[i]["attributeType"] == "Component") {
                for (let j = 0; j < this.components.length; j++) {                        
                    if (this.components[j].id == this.tagAttributesNew[i]["value"]){
                        tagAttributeValue = this.components[j].componentValue;
                        break;
                    }                         
                }
            } else if (this.tagAttributesNew[i]["attributeType"] == "Document") {
                for (let j = 0; j < this.documentListAllNew.length; j++) {                        
                    if (this.documentListAllNew[j].id == this.tagAttributesNew[i]["value"]){
                        tagAttributeValue = this.documentListAllNew[j].documentName;
                        break;
                    }
                }
            } else if (this.tagAttributesNew[i]["attributeType"] == "System") {
                for (let j = 0; j < this.systems.length; j++) {                        
                    if (this.systems[j].id == this.tagAttributesNew[i]["value"]){                        
                        // only checking for parentSystemNo since systemNo and systemName can not be null
                        // if(this.systems[j].parentSystemNo != null){
                        //     this.tagAttributesNew[i]["value"] = this.systems[j].parentSystemNo + "-" + this.systems[j].systemNo + " " + this.systems[j].systemName;
                        // }
                        // else{
                        //     this.tagAttributesNew[i]["value"] = this.systems[j].systemNo + " " + this.systems[j].systemName;
                        // }
                        tagAttributeValue = this.systems[j].systemNo + " " + this.systems[j].systemName;
                        break;
                    }
                }
            }else{
                tagAttributeValue = this.tagAttributesNew[i]["value"]
            }  

            //add tag attribute values into the rowDataTagAttr list       
            this.rowDataTagAttr.push({
                id: this.tagIdAttr,
                type : "TagAttributes",                
                attributeId: this.tagAttributesNew[i]["attributeId"],                
                name: this.tagAttributesNew[i]["attributeName"],	
                attributeType: this.tagAttributesNew[i]["attributeType"],					
                value: tagAttributeValue,			
                specId: this.tagAttributesNew[i]["tagSpecId"],							
                lastUpdateTimeStamp: this.tagAttributesNew[i]["lastUpdateTimeStamp"]				
            })
            tagAttributeValue = ""; //reinitialize the tagAttributeValue
        }
    }
    
      /**
     * Insert tag attribute values on row click event if the selected tag has attributes
     */
    onRowClickTag(event:any) {
        let tabType = SharedService.getTabComponentType();
        this.reusableType = "Tag Attribute";
        this.resuableText = "tagAttr";
        this.reusableView = event.data.value;

        if (tabType == 'Editor') {
            SharedService.insertEditorRequest(event.data);
        } else if (tabType == 'Procedures') {
            SharedService.insertProceduresRequest(event.data);
        } else if (tabType == 'IceCourse') {
            SharedService.insertICECourseRequest(event.data);
        }
          this.reusableXML = SharedService.getHtmlString();
          this.save(this.reusableXML, this.resuableText, this.reusableType, this.reusableView);
    }

    	/**
	 * Click event for the dependency button
	 */
	public dependencyButtonClickEvent(type) {
		console.log('clicked on a dependency button');
		const initialState : object = {
            headerTitle: 'Dependencies List',
            delFlag: false,
            cancelFlag: true,
            checkboxVal: false
		};
		//Set data
		console.log('showing Dependencies List');

		this.sharedService.setFileId(this.tagId);
        this.sharedService.setFileType(type);
        this.sharedService.setParent(this);

		this.sharedService.setAction(DEPENDENCY_ACTION.GET_DEPENDENCIES);
		this.bsModalRef = this.modalService.show(TagDependencyComponent, { initialState, class: 'modal-lg' });
    }
    
    checkIfNumber(param:any) {
		if ((param.keyCode >= 48 && param.keyCode <= 57)) { 
			// 0-9 only
		} else {	
            this.alertService.error("The value should be numeric");
            return false;
		}
	}
}
