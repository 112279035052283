<div class="row my-2 mx-1 border-bottom" #ftHeader>
  <div class="col-sm-6 pl-2">
      <p class="h6">{{ 'REFERENCE.HEADER_REFERENCE' | translate }}</p>
  </div>
  <div class="col-sm-12 px-2 py-1 border-bottom ">
    <button type="button" class="btn btn-outline-dark btn-sm mx-1 px-1 py-0" (click)="insertReferenceBtnEvent()">
      {{ 'REFERENCE.BTN_INSERT_REFERENCE' | translate }}
    </button>
    <button type="button" class="btn btn-outline-dark btn-sm mx-1 px-1 py-0" (click)="insertOtherReferenceBtnEvent()" [disabled]="!enableInsertOtherRef">
      {{ 'REFERENCE.BTN_INSERT_OTHER_REFERENCE' | translate }}
    </button>
  </div>
</div>
<div class="row my-2 mx-1">
  <div id="referencefoldertree" class="my-2 mx-1 ftdiv-overflow" style="width: 100%;" [style.height.px]="treeheight">
  </div>
</div>


<ng-template #insert_other_reference>
	<div class="modal-header">
		<h4 class="modal-title pull-left">{{'TOPIC_MAP.INSERT_OTHER_REFERENCE_MODAL_HEADER' | translate }}</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body py-1">
		<div class="row mb-2">
			<div class="btn-group btn-group-sm my-1 mx-auto" role="group" aria-label="Basic example">
				<button type="button" class="btn btn-outline-dark" (click)="showFigureRef()"
					[ngClass]="{'btn-dark': selectedOtherReference == 'figureRef', 'btn-outline-dark': selectedOtherReference != 'figureRef'}">
					{{ 'TOPIC_MAP.INSERT_OTHER_REFERENCE_FIGURE_TAB' | translate }}</button>
				<button type="button" class="btn " (click)="showTableRef() "
					[ngClass]="{'btn-dark': selectedOtherReference == 'tableRef', 'btn-outline-dark': selectedOtherReference != 'tableRef'}">
					{{ 'TOPIC_MAP.INSERT_OTHER_REFERENCE_TABLES_TAB' | translate }}</button>		
			</div>
		</div>
		<hr class="mt-0 mb-2" />		
		<ag-grid-angular #agGrid style="width: 100%; height: 400px;" [rowData]="rowData" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }" class="ag-theme-balham"
			[columnDefs]="imageColumnDefs"  [enableFilter]="false" 
			[enableRangeSelection]="false" [pagination]="true"
			(gridReady)="onGridReady($event)" [defaultColDef]="defaultColDef"
			(rowClicked)="onRowClick($event)" [suppressMenuHide]="true" rowSelection="single" [suppressRowDeselection]="true"
			[rowMultiSelectWithClick]="false" [getRowNodeId]="getRowNodeId"
			(lastChildChanged)="onlastChildChanged($event)"
			[rowSelection]="rowSelection"></ag-grid-angular>
		<button *ngIf="imageRef && isImageSelected" class="btn btn-sm btn-outline-dark float-right my-2" (click)="viewSelectedImage($event)">
				{{ 'TOPIC_MAP.BTN_INSERT_OTHER_REFERENCE_IMAGE_VIEW' | translate }}</button>
		<button *ngIf="enableInsertRef && enableInsertRefCount" class="btn btn-sm btn-outline-dark float-left my-2" (click)="insertOtherReference($event)">
				{{ 'TOPIC_MAP.BTN_INSERT_REFERENCE' | translate }}</button>
	</div>
</ng-template>

<!--Insert other reference image view modal-->
<ng-template #largeImage>
  <div class="modal-header">
      <h4 class="modal-title pull-left">{{ viewImage.title }}</h4>
      <button type="button" id="image_close" class="close pull-right" aria-label="Close" (click)="bsModalRefLargerImage.hide()">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <div class="card p-0">
          <img class="card-img-top" src="data:image/jpeg;base64,{{viewImage.largeImage}}">
      </div>
  </div>
  <div class="modal-footer">
      <button type="button" class="btn btn-sm btn-secondary" (click)="bsModalRefLargerImage.hide()">
    {{ 'TOPIC_MAP.BTN_INSERT_OTHER_REFERENCE_MODAL_CLOSE' | translate }}</button>
  </div>
</ng-template>