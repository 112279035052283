<div class="container-fluid"  [style.padding-bottom.px]="40">
    <p class="my-1 ">
        <sttrong>Topic Map Builder</sttrong>
    </p>
    <div class="col-sm-12 my-2 mx-5">
            <div class="row my-2 mx-1">
                <div id="topicMapTreeTM" class="my-2 mx-1  ftdiv-overflow" [style.height.px]="divHeight" style="width: 100%">

                </div>
            </div>
    </div>
</div>

