import { Component, OnInit, ViewChild, ElementRef, Input, ViewContainerRef, AfterContentInit, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { ComponentRef, ComponentFactoryResolver } from '@angular/core';
import { ComLoaderService  } from './com-loader.service';


@Component({
  selector: 'app-commissioning',
  templateUrl: './commissioning.component.html',
  styleUrls: ['./commissioning.component.css']
})
export class CommissioningComponent{
  public paddingtop: number = 60;
  @ViewChild('navPanel', {static: true}) navPanel: ElementRef

  component: any;
  @ViewChild('asHost', { read: ViewContainerRef, static: true }) componentHost: ViewContainerRef
  componentRef: ComponentRef<Component> 
    public isSideBar: boolean = true

    constructor(private resolver: ComponentFactoryResolver, private aslService: ComLoaderService) {
      this.component = this.aslService.loadComponentByName('irn')
   }


     public changeSideBarItem(event: any) {
      console.log('[changeSideBarItem] (event) ' + JSON.stringify(event));
      if(event.selectedItem == 'checksheets') {
        // do nothing
      }
      else {
        this.component = this.aslService.loadComponentByName(event.selectedItem)
        this.updateComponent()
      }
    }

    ngAfterContentInit(): void {
      //Called after ngOnInit when the component's or directive's content has been initialized.
      //Add 'implements AfterContentInit' to the class.
      this.updateComponent()
  }

    ngOnChanges(changes: SimpleChanges): void {
        //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
        //Add '${implements OnChanges}' to the class.
    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        if (this.componentRef) {
      this.componentRef.destroy()
      }
    }

    updateComponent() {
      this.componentHost.clear()
      const authFormFactory = this.resolver.resolveComponentFactory(this.component)
      const componentRef = this.componentHost.createComponent(authFormFactory);
    }
  ngOnInit() {
  }

}
