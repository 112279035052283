import { Component, ViewChild, TemplateRef } from "@angular/core";
import { WsResponse } from '../../../util/ws-response.model';
import { WsType } from '../../../util/ws-type';
import { AlertService } from '../../../util/alert/alert.service';
import { Router } from '@angular/router';
import { SharedService } from "../../../util/shared.service";
import { WsCallback } from "../../../util/ws-callback.interface";
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from 'src/app/util/loading/loading.service';
import { RolesService } from './roles.service';
import { GridOptions } from "@ag-grid-enterprise/all-modules";
import { Constants } from "src/app/util/constants";
import { UserVariable } from 'src/app/util/common/user-variable';
import { CommonUtil } from 'src/app/util/common/common-util';
import { RoleConfirmationModalComponent } from './role-confirmation-modal/role-confirmation-modal.component';
import { CapabilityService } from "src/app/util/capability.service";
@Component({
  selector: 'app-role-cell-render-component',
  templateUrl: './role-cell-render-component.component.html',
})
export class RoleCellRenderComponent implements WsCallback {
	icon: String;
	type: String;
	disable: boolean = false;
	bsModalRef: BsModalRef;
	title: string;
	data: String = "";
	status: string = "";
	public roleId: string = "";
	public roleName: string = "";
	public roleDescription: string = "";
	public action: string = "";
	public active: boolean = false;
	public gridOptions: GridOptions;	
	private token: any;
	public enableChangeRoleStatus: boolean = false;
	public enableEditRole: boolean = false;
	public enableDeleteRole: boolean = false;
	public deleteModalRef: BsModalRef;
	@ViewChild("deleteRoleModal") deleteRoleModal: TemplateRef<any>;


	constructor(private roleService: RolesService, private alertService: AlertService, private router: Router, private sharedService: SharedService,
		private bsModalService: BsModalService, private translate: TranslateService, private commonUtil: CommonUtil, private loadingService: LoadingService,
		private capabilityService: CapabilityService) {
		this.checkCapability()
		translate.addLangs(["es", "en"]);
		translate.setDefaultLang("en");
		let browserLang = translate.getBrowserLang();
		translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');

		//Initialization of variables
		this.token = UserVariable.getUserToken();
		if (!this.commonUtil.validateLogin(this.token)) {
			return;
		}
	}

	agInit(params: any): void {
		let items = params.value.split(':')
		if (items.length >= 2) {
			this.data = items[1];
			if (items.length == 3) {
				this.status = items[2];
				if(items[0]=='status'){
					this.checkStatusCapability()
				}
				else if(items[0]=='delete'){
					this.checkDelCapability()
				}
				else if(items[0]=='edit'){
					this.checkEditCapability()
				}
			}
			if (items.length == 4) {
				this.status = items[2];
				this.roleName = items[3];
				if(items[0]=='status'){
					this.checkStatusCapability()
				}
				else if(items[0]=='delete'){
					this.checkDelCapability()
				}
				else if(items[0]=='edit'){
					this.checkEditCapability()
				}
			}
			if (items.length == 5) {
				this.status = items[2];
				this.roleName = items[3];
				this.roleDescription = items[4];
				if(items[0]=='status'){
					this.checkStatusCapability()
				}
				else if(items[0]=='delete'){
					this.checkDelCapability()
				}
				else if(items[0]=='edit'){
					this.checkEditCapability()
				}
			}
		}
		this.setType(items[0], items[1])
		console.log("parameters" + items[0] + " " + items[1])

		this.sharedService.disableCellRendererExecute().subscribe(
			option => this.setDisable(option));

		}


	public invoke(type: String, roleId: any, roleName: any, roleDescription:any, status:any, template: TemplateRef<any>) {
		this.roleId = roleId;
		this.roleName = roleName;
		this.roleDescription = roleDescription;
		this.status = status;
		let title;
		let message;
		if (this.status == "Active" || this.status == "active") {
			this.active = true;
		}
		switch (type) {
			
			case 'status':
				localStorage.setItem("isDelete", "false");
				// let title;
				// let message;
				this.action = "Update";
				if (this.status == "Active" || this.status == "active") {
					this.status = "Inactive";				
					title = this.translate.instant('ROLES.DISABLE_TITLE');
					message = this.translate.instant('ROLES.DISABLE_CONFIRM');
				} else {
					this.status = "Active";
					title = this.translate.instant('ROLES.ENABLE_TITLE');
					message = this.translate.instant('ROLES.ENABLE_CONFIRM');
				}
				this.showStatusConfirmation(title, message);
				break;
			case 'edit':
				localStorage.setItem("isDelete", "false");
				this.editRole(false, template);
				break;
			case 'delete':
				this.action = "Delete";
				localStorage.setItem("isDelete", "true");
				title = this.translate.instant('ROLES.DELETE_CONFIRM');
				message = this.translate.instant('ROLES.DELETE_CONFIRM_MESSAGE');
				this.showStatusConfirmation(title, message);
				break;
			default:
				break;
		}
	}
	

	onSuccess(data: WsResponse, serviceType: WsType) {
		if(serviceType == WsType.UPDATE_ROLE){
			// this.loadingService.hideLoading();
			this.alertService.success("Successfully edited the role");
			this.roleService.getRolesList().subscribe(data => {
				if (data.payload != null) {
					this.sharedService.setRoleTableEditRequest(data.payload);
					setTimeout(() => {
						this.bsModalRef.hide();
					}, 200);	
				}
				error => {          
				  this.loadingService.hideLoading();
				  this.alertService.clear()
				  this.alertService.error(error.statusDescription)
				}
				});				
		} else if(serviceType == WsType.UPDATE_STATUS){
			// this.loadingService.hideLoading();
			this.alertService.success("Successfully update the status");
			this.roleService.getRolesList().subscribe(data => {
				if (data.payload != null) {
					this.sharedService.setRoleTableEditRequest(data.payload);
					setTimeout(() => {
						this.bsModalRef.hide();
					}, 200);	
				}
				error => {          
				  this.loadingService.hideLoading();
				  this.alertService.clear()
				  this.alertService.error(error.statusDescription)
				}
				});			
		} else if(serviceType == WsType.GET_ROLE_ASSIGN_USERS){			
			if (data.payload.length != 0) {
				this.loadingService.hideLoading();
				this.bsModalRef.hide()
				this.alertService.info("Could not delete the role as users are assigned to the role");
			} else {
				var newdata: any = {
					"roleID": this.roleId
				};
				var json = JSON.stringify(newdata);
				this.roleService.deleteRole(this.token, json, this);
			}
		} else if(serviceType == WsType.DELETE_ROLE){
			// this.loadingService.hideLoading();
			this.alertService.success("Successfully deleted the role");
			this.bsModalRef.hide()
			this.roleService.getRolesList().subscribe(data => {
				if (data.payload != null) {
					this.sharedService.setRoleTableEditRequest(data.payload);
					setTimeout(() => {
						this.bsModalRef.hide();
					}, 200);	
				}
				error => {          
				  this.loadingService.hideLoading();
				  this.alertService.clear()
				  this.alertService.error(error.statusDescription)
				}
				});			
		} 

	}

	onFail(data: WsResponse, serviceType: WsType) {
		this.loadingService.hideLoading();
		if (serviceType == WsType.UPDATE_ROLE){
		this.alertService.error(data.statusDescription);
		} else if (serviceType == WsType.GET_ROLES){
			this.alertService.error(data.statusDescription);
		} else if (serviceType == WsType.UPDATE_STATUS){
			this.alertService.error(data.statusDescription);
		} else if (serviceType == WsType.GET_ROLE_ASSIGN_USERS){
			this.alertService.error(data.statusDescription);
		}
	}

	private setType(params, roleId) {
		this.type = params;
		this.roleId = roleId;
		if (params == 'status') {
			if (this.status == "Inactive") {
				this.icon = 'far fa-times-circle';
				this.translate.get('PUNCHLIST_CELL_RENDER.ENABLE_CONFIRM').subscribe((res: string) => {
					this.title = res;
				});
			} else {
				this.translate.get('PUNCHLIST_CELL_RENDER.DISABLE_CONFIRM').subscribe((res: string) => {
					this.title = res;
				});
				this.icon = 'far fa-check-circle';
			}
		}else if(params == 'disable_dependencies'){
			this.disable = true;
			this.icon = 'far fa-link';
		} else if (this.type == 'delete') {
			// this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.DELETE;
			this.translate.get('PUNCHLIST_CELL_RENDER.DELETE_CONFLICTS').subscribe((res: string) => {
				this.title = res;
			});
			this.icon = 'far fa-trash-alt';
		} else if (this.type == 'edit') {
			// this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.EDIT;
			this.translate.get('PUNCHLIST_CELL_RENDER.EDIT_CONFIRM').subscribe((res: string) => {
				this.title = res;
			});
			this.icon = 'far fa-edit';
		}
	}

	editRole(dflag: boolean, template: TemplateRef<any>) {
		this.bsModalRef = this.bsModalService.show(template, { class: 'modal-lg', backdrop: 'static', keyboard : false });
		if (this.active == true) {
			$("#rb3").prop("checked", true);
			this.active = false;
		} else {
			$("#rb4").prop("checked", true);
			this.active = false;
		}
	}

	editSaveButtonClick(): void {
		this.roleDescription = this.roleDescription.trim()
		this.roleName = this.roleName.trim()
		if (this.roleDescription.includes("\\")) {
			this.roleDescription  = this.roleDescription.replace(/\\/g, "\\\\");
		}
		if (this.roleName.includes("\\")) {
			this.roleName  = this.roleName.replace(/\\/g, "\\\\");
    }
    let radioValue = $("input[name='addrb']:checked").val()
    if (radioValue){
       this.status = radioValue.toString();;
    } else {
      this.alertService.info("Please select the status");
      return;
    }
		if (this.roleName.length === 0 ) {
			return;
	}
    let data = {
      roleID: this.roleId,
      roleName: this.roleName,
      description: this.roleDescription,
      status: this.status
      
    };   
    var json = JSON.stringify(data);
    this.roleService.updateRole(this.token, json, this);
    this.loadingService.showLoading(true, false, this.translate.instant('SAVING'), 0);
  }

	private setDisable(option: any) {
		let items = this.data.split(",")
		if (option != null && option.id == items[0] && this.type == 'status') {
			this.disable = option.data;
			this.icon = this.disable ? 'far fa-times-circle' : 'far fa-check-circle';
			this.status = this.disable ? 'Inactive' : 'Active';
			if (this.status == "Inactive") {
				// this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.ENABLE;
				this.translate.get('SYSTEM_CELL_RENDER.ENABLE_CONFIRM').subscribe((res: string) => {
					this.title = res;
				});
			} else {
				// this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.DISABLE;
				this.translate.get('SYSTEM_CELL_RENDER.DISABLE_CONFIRM').subscribe((res: string) => {
					this.title = res;
				});
			}
		}
	}

	/**
   * Shows status confirmation popup
   */
  showStatusConfirmation(title: string, message: string) {	
	const initialState = {
		token: this.token,
		attributeId: '',
		confirmationTitle: title,
		confirmationMessage: message,
		callBack: this
	};
	this.bsModalRef = this.bsModalService.show(RoleConfirmationModalComponent, { initialState });
}

/**
* User clicks on the delete button in the deletion confirmation popup
*/
onConfirm() {
	if (this.action == "Update") {
	var data: any = {
		"roleID": this.roleId,
		"status": this.status
	};
	var json = JSON.stringify(data);
    this.roleService.updateStatus(this.token, json, this);
	this.loadingService.showLoading(true, false, this.translate.instant('LOADING'), 0);
	} else if (this.action == "Delete"){
		var data: any = {
			"roleID": this.roleId
		};
		var json = JSON.stringify(data);
		this.roleService.getRoleAssignUsers(this.token, json, this);
		this.loadingService.showLoading(true, false, this.translate.instant('LOADING'), 0);
	}
}

/**
 * User clicks on the decline button in the deletion confirmation popup
 */
onDecline() {
	this.bsModalRef.hide()
}

public checkCapability() {
	let deleteCapability: string = "deleteUserRoles"
	let disableCapability: string = "disableUserRoles"
	let modifyCapability: string = "modifyUserRoles"


	this.enableDeleteRole = this.capabilityService.isCapabilityAssigned(deleteCapability)
	this.enableChangeRoleStatus = this.capabilityService.isCapabilityAssigned(disableCapability)
	this.enableEditRole = this.capabilityService.isCapabilityAssigned(modifyCapability)  


}

public checkStatusCapability(){

	
	if(this.enableChangeRoleStatus == false ){
		// this.status = 'Inactive'
		this.disable = true
	}
	
}

public checkDelCapability(){


	if(this.enableDeleteRole == false ){
		 //this.status = "Inactive"
		this.disable = true
	}
}

public checkEditCapability(){
	if(this.enableEditRole == false ){
		 //this.status = "Inactive"
		this.disable = true
	}
	

}
}  