import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { UserVariable } from '../../util/common/user-variable';
import { ServiceUrls } from '../../util/service-urls';
import { WsResponse } from '../../util/ws-response.model';
import { WsCallback } from '../../util/ws-callback.interface';
import { WsType } from '../../util/ws-type';
//import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { SharedService } from '../../util/shared.service';
import { CommonUtil } from 'src/app/util/common/common-util';
 
@Injectable({
    providedIn: 'root'
})

export class SystemsService {
    public token: string;
    private rowData: any = null;
    private apiGridData: any = null;
    private callback: WsCallback;
    private selectedEditorButton: any = null;
    private addEditPanel: any;

    constructor(private http: HttpClient, private sharedService: SharedService, private commonUtil: CommonUtil) { }

    public setCallback(callBack: WsCallback) {
        this.callback = callBack;
    }

    searchSystems(systemNo: String, systemName: String, facilityName: String, isCommissioning: boolean, hasText: String) {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        let url = this.getsystemsBySearch(token);
        return this.http.post(url, JSON.stringify(new Request(systemNo, systemName, facilityName, isCommissioning, hasText))).
            pipe(
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                }),
                catchError(error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    return throwError(res)
                })
            );
	}
	
	searchChildandSibling(systemId: String, facilityId: String, parentId: String, callback: any ) {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
		let url =ServiceUrls.SYSTEMS_MODAL_VIEW  + 'ChildSibling/getSystemSiblingsChildren.json?token=' + token;
		var data = {systemId: systemId, facilityId: facilityId, parentId: parentId  }
		this.http.post(url, data).
			subscribe(
				data => {
					var modified:any =  data;
					var res = new WsResponse(
						modified.status.description,
						modified.status.name,
						modified.status.code,
						modified.payload);
					callback.onSuccess(res, WsType.GET_SIBLING);
				},
				error => {
					var modified = JSON.parse(JSON.stringify(error));
					var res = new WsResponse(
						modified.error.status.description,
						modified.error.status.name,
						modified.error.status.code,
						modified.error.payload);
					callback.onFail(res, WsType.GET_SIBLING);
				});
    }

    public getsystemsBySearch(token: String) {
        let url = ServiceUrls.SYSTEMS_SEARCH_VIEW + '?token=' + token;
        return url;
	}

	public setItemData(fileId: any, systemNo: any, systemName: any,  selectedFacility: any, isCommissioning: boolean) {
		if (this.apiGridData != null && this.apiGridData != undefined && fileId != null) {
			var rowNode = this.apiGridData.getRowNode(fileId);
			if (rowNode != null && rowNode != undefined) {
				rowNode.setDataValue("systemNo", systemNo);
                rowNode.setDataValue("systemName", systemName);
                rowNode.setDataValue("isCommissioning", isCommissioning);
				if (selectedFacility != null && selectedFacility != undefined) {
					rowNode.setDataValue("facilityName", selectedFacility);
				}
			}
		}
	}

    addSystem(systemNo: String, systemName: String, facilityId: String, parentId: String, isCommissioning: boolean) {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        let url = this.addSystemURL(token);
        return this.http.post(url, JSON.stringify(new AddRequest(systemNo, systemName, facilityId, parentId, isCommissioning))).
            pipe(
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                }),
                catchError(error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    return throwError(res)
                })
            );
    }

    public addSystemURL(token: String) {
        let url = ServiceUrls.ADD_SYSTEM + '?token=' + token;
        return url;
    }

    updateSystem(systemId: String, systemNo: String, systemName: String, facilityId: String, parentId: String, isCommissioning: boolean) {
        console.log("this is update" + JSON.stringify(new UpdateRequest(systemId, systemNo, systemName, facilityId, parentId, isCommissioning)));
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        // let url = ServiceUrls.UPDATE_SYSTEM + '?token=' + token;
        let url = ServiceUrls.UPDATE_SYSTEM + '?token=' + token;
        return this.http.put(url, JSON.stringify(new UpdateRequest(systemId, systemNo, systemName, facilityId, parentId, isCommissioning))).
            pipe(
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                }),
                catchError(error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    return throwError(res)
                })
            );
    }

    getParentSystemList() {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        const options = { params: new HttpParams().set("token", token) }
        //let url = this.parentSystemURL();
        return this.http.get(ServiceUrls.GET_PARENT_SYSTEM_LIST, options).
            pipe(
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.code,
                        modified.status.name,
                        modified.status.description,
                        modified.payload);
                }),
                catchError(error => {
                    var modified = JSON.parse(JSON.stringify(error.error));
                    var res = new WsResponse(
                        modified.status.code,
                        modified.status.name,
                        modified.status.description,
                        modified.payload);
                    return throwError(res)
                })
            );
	}
	

	getSystemsByFacilityId(facilityId: any) {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        const options = { params: new HttpParams().set("token", token) }
        //let url = this.parentSystemURL();
        return this.http.get(ServiceUrls.getSystemsByFacilityId(token, facilityId)).
            pipe(
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.code,
                        modified.status.name,
                        modified.status.description,
                        modified.payload);
                }),
                catchError(error => {
                    var modified = JSON.parse(JSON.stringify(error.error));
                    var res = new WsResponse(
                        modified.status.code,
                        modified.status.name,
                        modified.status.description,
                        modified.payload);
                    return throwError(res)
                })
            );
    }

    getAllActiveParentSystemsByFacility(facilityId: any) {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        const options = { params: new HttpParams().set("token", token) }
        //let url = this.parentSystemURL();
        return this.http.get(ServiceUrls.getAllActiveParentSystemsByFacility(token, facilityId)).
            pipe(
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.code,
                        modified.status.name,
                        modified.status.description,
                        modified.payload);
                }),
                catchError(error => {
                    var modified = JSON.parse(JSON.stringify(error.error));
                    var res = new WsResponse(
                        modified.status.code,
                        modified.status.name,
                        modified.status.description,
                        modified.payload);
                    return throwError(res)
                })
            );
    }


    // public parentSystemURL() {
    //     let url = ServiceUrls.GET_PARENT_SYSTEM_LIST;
    //     return url;
    // }

    getFacilityList() {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        const options = { params: new HttpParams().set("token", token) }
        return this.http.get(ServiceUrls.GET_FACILITY_LIST, options).
            pipe(
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.code,
                        modified.status.name,
                        modified.status.description,
                        modified.payload);
                }),
                catchError(error => {
                    var modified = JSON.parse(JSON.stringify(error.error));
                    var res = new WsResponse(
                        modified.status.code,
                        modified.status.name,
                        modified.status.description,
                        modified.payload);
                    return throwError(res)
                })
            );
    }

    getSystemDataBySystemId(systemId: String, facilityId: String, parentId: String) {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
		let url = ServiceUrls.SYSTEM_EDIT + '?token=' + token;
		console.log("facilityId  " + facilityId)
		console.log("parentId  " + parentId)
        return this.http.post(url, JSON.stringify(new EditRequest(systemId, facilityId, parentId))).
            pipe(
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                }),
                catchError(error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    return throwError(res)
                })
            );
    }

    public disableEnableFileTableData(fileId: any, title: String, status: String) {
        if (this.apiGridData != null && this.apiGridData != undefined && fileId != null) {
            var rowNode = this.apiGridData.getRowNode(fileId);
            if (rowNode != null && rowNode != undefined) {
                if (title == 'Disable Confirm') {
                    this.sharedService.disableCellRendererRequest(true, fileId);
                    rowNode.setDataValue("status", "status:" + fileId + ":Inactive");
                    rowNode.setDataValue("delete", "delete:" + fileId + ":Inactive");
                } else if (title == 'Enable Confirm') {
                    this.sharedService.disableCellRendererRequest(false, fileId);
                    rowNode.setDataValue("status", "status:" + fileId + ":Active");
                    rowNode.setDataValue("delete", "delete:" + fileId + ":Active");
                }
                else {
                    if (status == "Inactive") {
                        this.sharedService.disableCellRendererRequest(false, fileId);
                        rowNode.setDataValue("status", "status:" + fileId + ":Active");
                        rowNode.setDataValue("delete", "delete:" + fileId + ":Active");
                    }
                    else {
                        this.sharedService.disableCellRendererRequest(true, fileId);
                        rowNode.setDataValue("status", "status:" + fileId + ":Inactive");
                        rowNode.setDataValue("delete", "delete:" + fileId + ":Inactive");
                    }
                }
            }
        }
    }

    public deleteFileTableData(fileId: any) {
        if (this.apiGridData != null && this.apiGridData != undefined && fileId != null) {
            var selectedData = this.apiGridData.getSelectedRows();
            if (selectedData != null && selectedData != undefined) {
                this.apiGridData.applyTransaction({ remove: selectedData });
                let newRowData:any = [];
				this.apiGridData.forEachNode( function(rowNode, index) {
					newRowData.push(rowNode.data);
				});
				this.setRowData(newRowData);
            }
        }
    }
    
    /**
    * Define URL for delete statement.
    */
   deleteSystem(id: any, callBack: WsCallback) {
    let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
    let url = ServiceUrls.SYSTEMS + id + '.json'
    const options = { params: new HttpParams().set("token", token) }
    this.http.delete(url, options).subscribe(
        data => {
            var modified = JSON.parse(JSON.stringify(data));
            var res = new WsResponse(
                modified.status.description,
                modified.status.code,
                modified.status.name,
                modified.payload);
            callBack.onSuccess(res, WsType.DELETE_ITEM);
        },
        error => {
            if (error.status != '') {
                var val = (error as HttpErrorResponse).error;
                var modified = JSON.parse(JSON.stringify(val));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onFail(res, WsType.DELETE_ITEM);
            } else {
                //browser related issues
                var res = new WsResponse("Unknown error happened");
                callBack.onFail(res, WsType.DELETE_ITEM);
            }
        }
    );
}
    public setApiGridData(apiGridData: any) {
        this.apiGridData = apiGridData;
    }

    public getApiGridData() {
        return this.apiGridData;
    }

    public setRowData(rowData: any) {
        this.rowData = rowData;
    }

    public getRowData() {
        return this.rowData;
    }
}

class Request {
    constructor(private systemNo: String, private systemName: String, private facilityName: String, private isCommissioning: boolean, private hasText: String) { }
}

class AddRequest {
    constructor(private systemNo: String, private systemName: String, private facilityId: String, private parentId: String, private isCommissioning: boolean) { }
}

class EditRequest {
    constructor(private systemId: String, facilityId: String, prentId: String) { }
}

class UpdateRequest {
    constructor(private systemId: String, private systemNo: String, private systemName: String, private facilityId: String, private parentId: String, private isCommissioning: boolean) { }
}