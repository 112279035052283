import { Injectable } from '@angular/core';
import { OutputHomeComponent } from './outputhome/outputhome.component';
import { OutputQueueComponent } from './outputqueue/outputqueue.component';
import { OutputConfigComponent } from './outputconfig/outputconfig.component';
import { OutputAddProjectTempComponent } from './outputaddprojecttemp/outputaddprojecttemp.component';
import { OMAddTemplateComponent } from './outputaddprojecttemp/addtemplate/addtemplate.component';

@Injectable({
    providedIn: 'root'
})
export class OMLoaderService {

    constructor() { }

    public loadComponentByName(compName: string) {
        switch (compName) {
            case 'opmanagement':
                return OutputHomeComponent
            case 'projecttemplate':
                return OutputAddProjectTempComponent
            case 'addprotemplate':
                return OMAddTemplateComponent
            case 'outputqueue':
                return OutputQueueComponent
            case 'outputconfig':
                return OutputConfigComponent
            default:
                break;
        }
    }
}