import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import * as auth0 from 'auth0-js';
import { Observable } from 'rxjs';
import { LocalStorage } from '../util/localstorage.service';

@Injectable({
  providedIn: 'root'
})
export class Auth0Service {

  private auth0: auth0.WebAuth;
  private authOptions: auth0.AuthOptions;
  private _idToken: string;

  constructor(public router: Router) {
    var auth0Configs = JSON.parse(localStorage.getItem(LocalStorage.AUTH0_CONFIGURATIONS));

    if (auth0Configs != null && auth0Configs != ''){
        this.authOptions = {
          domain: auth0Configs.symbiosisweb.authOptions.domain,
          clientID: auth0Configs.symbiosisweb.authOptions.clientID
        };
    
        this.auth0 = new auth0.WebAuth(this.authOptions);
      }
    
  }

  public authorise(configs:any,usernameData:any) {
    var auth0Configs = JSON.parse(configs);

    var usernameJson = JSON.parse(usernameData);
    if (this.auth0 == undefined){
      if (auth0Configs != null && auth0Configs != ''){
        this.authOptions = {
          domain: auth0Configs.symbiosisweb.authOptions.domain,
          clientID: auth0Configs.symbiosisweb.authOptions.clientID
        };
    
        this.auth0 = new auth0.WebAuth(this.authOptions);
      }
    }
    if (auth0Configs != null && auth0Configs != ''){
      this.auth0.authorize({
        redirectUri: auth0Configs.symbiosisweb.configs.redirectUri,
        responseType: auth0Configs.symbiosisweb.configs.responseType,
        audience: auth0Configs.symbiosisweb.configs.audience,
        scope: auth0Configs.symbiosisweb.configs.scope,
        login_hint : usernameJson.username,
        prompt : 'login'
        
      }); 
    }
    
  }

  public parseAccessToken() {
    let result: any;
    let userData: any;

    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (authResult) {
          window.location.hash = '';
          localStorage.setItem('token', authResult.accessToken);

          resolve(authResult);
        } else if (err) {
          reject(err);
        }
      });
    });

  }
}

