import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AddpunchlistComponent } from './addpunchlist/addpunchlist.component';
import { EditpunchlistComponent } from './editpunchlist/editpunchlist.component';
import { GridOptions, ColDef, Module, AllModules } from "@ag-grid-enterprise/all-modules";
import { PunchlistService } from './punchlist.service';
import { Constants } from '../../util/constants';
import { AlertService } from '../../util/alert/alert.service';
import { SharedService } from '../../util/shared.service';
import { LoadingService } from '../../util/loading/loading.service';
import { DecodeHtmlString } from '../../util/common/decode-html-string.pipe';
import { PunchlistsCellRenderComponent } from './punchlists-cell-render-component.component';
import { WsResponse } from '../../util/ws-response.model';
import { WsType } from '../../util/ws-type';
import { Subscription } from 'rxjs';
import { PunchListDocumentIconRenderer } from './punchlist-documents-cell-renderer.component';
import { FileUploadService } from "./file-upload.service";
import * as FileSaver from 'file-saver';
import { DependenciesModalService } from './../../util/dependenciesmodal/dependenciesmodal.service';
import { PunchListImageIconRenderer } from './image-icon/image-icon.component';
import { PunchlistDocuSignCellRendererComponent } from './punchlist-docu-sign-cell-renderer/punchlist-docu-sign-cell-renderer.component';

@Component({
  selector: 'app-punchlist',
  templateUrl: './punchlist.component.html',
  styleUrls: ['./punchlist.component.css']
})
export class PunchlistComponent implements OnInit, AfterViewInit, OnDestroy {
  component: any;
  public bsModalRef: BsModalRef;
  public expandedView: string;
  public columnDefs: any;
  rowData: any = [];
  public gridApi: any;
  public getRowNodeId: any;
  private gridColumnApi: any;
  public overlayLoadingTemplate: any;
  public overlayNoRowsTemplate: any;
  public modules: Module[] = AllModules;

  public gridOptions: GridOptions;
  changeLayout: boolean = false

  private count: number = 0;
  private category: string = "";
  private assetName: string = "";
  private disciplineName: string = "";
  private description: string = "";
  private dateRaisedString: string;
  private dateClosedString: string;
  private dateEstimatedCompletionString: string;
  private dateR = "";
  private dateC = "";
  private compDate = "";
  private raisedBy: string = "";
  private materialRequired: string = "";
  private subSystemName: string = "";
  private estManHours: string = "";
  private itemStatus: string = "";
  private hasText: string = "";
  public searchData: any;

  private filteredData: Array<any> = [];
  public expandComponents: boolean = false;
  private subsVar: Subscription;
  private deletePlId: Subscription;
  private disablePlId: Subscription;
  private editPlId: Subscription;
  public punchlistId: string;
  public status: string;

  constructor(private router: Router,
    private modalService: BsModalService, 
    private translate: TranslateService, 
    private punchlistService: PunchlistService,
    private alertService: AlertService,
    private loadingService: LoadingService,
    private decodeHTMLString: DecodeHtmlString,
    private sharedService: SharedService,
    private fileUploadService: FileUploadService,
    private dependenciesmodalService: DependenciesModalService) {

        translate.addLangs(["es", "en"]);
        translate.setDefaultLang("en");
        let browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');

        this.columnDefs = [
            {
              headerName: this.translate.instant('PUNCHLIST_SEARCH_VIEW.PUNCHLIST_DEPENDENCY'),
              field: "dependencies",
              minWidth: 75,
              //hide:!this.disableCap,
              cellStyle: { 'text-align': 'center' },
              cellRendererFramework: PunchlistsCellRenderComponent,
            },
            {
              headerName: this.translate.instant('PUNCHLIST_SEARCH_VIEW.PUNCHLIST_CATEGORY'),
              field: 'category',
              autoHeight: true, 
              cellClass: "cell-wrap-text",  
              minWidth: 100,
            },
            {
              headerName: this.translate.instant('PUNCHLIST_SEARCH_VIEW.PUNCHLIST_ASSET'),
              field: 'assetName',
              autoHeight: true, 
              cellClass: "cell-wrap-text",  
              minWidth: 100,
            },
            {
              headerName: this.translate.instant('PUNCHLIST_SEARCH_VIEW.PUNCHLIST_DISCIPLINE'),
              field: 'disciplineName',
              autoHeight: true, 
              cellClass: "cell-wrap-text",  
              minWidth: 100,
            },
            {
              headerName: this.translate.instant('PUNCHLIST_SEARCH_VIEW.PUNCHLIST_DESCRIPTION'),
              field: 'plDescription',
              autoHeight: true, 
              cellClass: "cell-wrap-text",  
              minWidth: 300,
            },
            {
              headerName: this.translate.instant('PUNCHLIST_SEARCH_VIEW.PUNCHLIST_ITEM_STATUS'),
              field: 'itemStatus', 
              autoHeight: true, 
              cellClass: "cell-wrap-text", 
              cellRenderer: this.statusCellRenderer, 
              minWidth: 100, 
              enableRowGroup: true
            },
            {
              headerName: this.translate.instant('PUNCHLIST_SEARCH_VIEW.PUNCHLIST_DATE_RAISED'),
              field: 'dateRaised',
              autoHeight: true, 
              cellClass: "cell-wrap-text",  
              minWidth: 100,
            },
            {
              headerName: this.translate.instant('PUNCHLIST_SEARCH_VIEW.PUNCHLIST_DATE_CLOSED'),
              field: 'dateClosed',
              autoHeight: true, 
              cellClass: "cell-wrap-text",  
              minWidth: 100,
            },
            {
              headerName: this.translate.instant('PUNCHLIST_SEARCH_VIEW.PUNCHLIST_COMPLETION_DATE'),
              field: 'completionDate',
              autoHeight: true, 
              cellClass: "cell-wrap-text",  
              minWidth: 100,
            },
            {
              headerName: this.translate.instant('PUNCHLIST_SEARCH_VIEW.PUNCHLIST_RAISED_BY'),
              field: 'raisedBy',
              autoHeight: true, 
              cellClass: "cell-wrap-text",  
              minWidth: 100,
            },
            {
              headerName: this.translate.instant('PUNCHLIST_SEARCH_VIEW.PUNCHLIST_MATERIAL_REQUIRED'),
              field: 'materialsReq',
              autoHeight: true, 
              cellClass: "cell-wrap-text",  
              minWidth: 100,
            },
            {
              headerName: this.translate.instant('PUNCHLIST_SEARCH_VIEW.PUNCHLIST_SUB_SYSTEMS'),
              field: 'subSystemName',
              autoHeight: true, 
              cellClass: "cell-wrap-text",  
              minWidth: 300,
            },
            {
              headerName: this.translate.instant('PUNCHLIST_SEARCH_VIEW.VENDOR_NAME'),
              field: 'vendor',
              autoHeight: true, 
              cellClass: "cell-wrap-text",  
              minWidth: 150,
            },
            {
              headerName: this.translate.instant('PUNCHLIST_SEARCH_VIEW.PUNCHLIST_ESTIMATED_MAN_HOURS'),
              field: 'estiManHours',
              autoHeight: true, 
              cellClass: "cell-wrap-text",
              cellStyle: {'white-space': 'normal' },
              minWidth: 100,
            },
            {
              headerName: this.translate.instant('PUNCHLIST_SEARCH_VIEW.PUNCHLIST_DOCUMENTS'),
              field: 'documents',
              autoHeight: true,
              cellClass: "cell-wrap-text",
              cellStyle: {'white-space': 'normal' },
              minWidth: 300,
              cellRendererFramework: PunchListDocumentIconRenderer
            },
            {
              headerName: this.translate.instant('PUNCHLIST_SEARCH_VIEW.WORKFLOW_STATUS'),
              field: 'docuSignStatus',
              autoHeight: true, 
              cellClass: "cell-wrap-text",   
              minWidth: 125,
              cellRendererFramework: PunchlistDocuSignCellRendererComponent
            },
            {
              headerName: this.translate.instant('PUNCHLIST_SEARCH_VIEW.PUNCHLIST_OPEN_SENT_PDF'),
              field: 'opensentpdf',
              autoHeight: true,
              cellClass: "cell-wrap-text",
              cellStyle: {'white-space': 'normal' },
              minWidth: 100,
              cellRendererFramework: PunchlistsCellRenderComponent
            },
            {
              headerName: this.translate.instant('PUNCHLIST_SEARCH_VIEW.PUNCHLIST_OPEN_RETURN_PDF'),
              field: 'openreturnedpdf',
              autoHeight: true,
              cellClass: "cell-wrap-text",
              cellStyle: {'white-space': 'normal' },
              minWidth: 100,
              cellRendererFramework: PunchlistsCellRenderComponent
            },
            {
              headerName: this.translate.instant('PUNCHLIST_SEARCH_VIEW.PUNCHLIST_CLOSE_SENT_PDF'),
              field: 'closesentpdf',
              autoHeight: true,
              cellClass: "cell-wrap-text",
              cellStyle: {'white-space': 'normal' },
              minWidth: 100,
              cellRendererFramework: PunchlistsCellRenderComponent
            },
            {
              headerName: this.translate.instant('PUNCHLIST_SEARCH_VIEW.PUNCHLIST_CLOSE_RETURN_PDF'),
              field: 'closereturnedpdf',
              autoHeight: true,
              cellClass: "cell-wrap-text",
              cellStyle: {'white-space': 'normal' },
              minWidth: 100,
              cellRendererFramework: PunchlistsCellRenderComponent
            },
            {
              headerName: this.translate.instant('PUNCHLIST_SEARCH_VIEW.PUNCHLIST_IMAGES'),
              field: 'images',
              autoHeight: true,
              cellClass: "cell-wrap-text",
              minWidth: 300,
              cellRendererFramework: PunchListImageIconRenderer
            },
            {
              headerName: this.translate.instant('PUNCHLIST_SEARCH_VIEW.FIRST_OIL_REQUIRED'),
              field: "firstOilRequired",
              minWidth: 140,
              cellStyle: { 'text-align': 'center' },
            },
            {
              headerName: this.translate.instant('PUNCHLIST_SEARCH_VIEW.STATUS'),
              field: "status",
              minWidth: 75,
              //hide:!this.disableCap,
              cellStyle: { 'text-align': 'center' },
              cellRendererFramework: PunchlistsCellRenderComponent,
            },
            {
              headerName: this.translate.instant('PUNCHLIST_SEARCH_VIEW.EDIT'),
              field: "edit",
              minWidth: 75,
              //hide:!this.enableEditPunchlist,
              cellStyle: { 'text-align': 'center' },
              cellRendererFramework: PunchlistsCellRenderComponent,
            },
            {
              headerName: this.translate.instant('PUNCHLIST_SEARCH_VIEW.DELETE'),
              field: "delete",
              minWidth: 120,
              //hide:!this.enableDeletePunchlist,
              cellStyle: { 'text-align': 'center' },
              cellRendererFramework: PunchlistsCellRenderComponent,
            }
          
            
        ];

        this.getRowNodeId = function (data) {
            return data.id;
        };

        this.gridOptions = {
            rowData: this.rowData,
            columnDefs: this.columnDefs,
            defaultColDef: {
              resizable: true,
              sortable: true
            },
            rowSelection: "single",
            rowMultiSelectWithClick: false,
            doesExternalFilterPass: this.externalFilterPass,
            onRowClicked: this.rowClicked,
            animateRows: true,
            rowBuffer: 20,
            context: {
              componentParent: this
            },
            paginationPageSize: 10
        };
  
        //custom layout templates for loading and empty data sets
        this.overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Loading data...</span>';
        this.overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">No rows to show</span>';
    }

    externalFilterPass(node: any) {
        return true;
    }

    rowClicked() {
    }

    onGridReady(params) {
        const allColumnIds = [];
        this.columnDefs.forEach((columnDef) => {
            allColumnIds.push(<ColDef>columnDef);
        });
        this.gridColumnApi = params.columnApi;
        this.gridApi = params.api;
        this.punchlistService.setApiGridData(this.gridApi);
        params.api.sizeColumnsToFit();
        // window.addEventListener("resize", function () {
        //     setTimeout(function () {
        //         params.api.sizeColumnsToFit();
        //     });
        // });
        window.addEventListener("resize", this.onWindowResize);
    }
    private onWindowResize = () => {
   
      setTimeout(() => {
        if (this.gridApi) {
          this.gridApi.sizeColumnsToFit();
        }
      }); 
    };

  ngOnInit() {
  }

  public newPunchlistButtonClick() {
    var loadingMessage;  
		this.translate.get('LOADING').subscribe((res: string) => {
			loadingMessage = res;
		});
		//show loading
		this.loadingService.showLoading(true, false, loadingMessage, 0); 
    // this.router.navigateByUrl('addtags');
    this.bsModalRef = this.modalService.show(AddpunchlistComponent, { class: 'modal-xl', backdrop: 'static', keyboard: false })
}

edit(option): void {
  this.punchlistId = this.sharedService.getFileId();
  this.punchlistService.getPunchlistID(this.punchlistId);
  this.bsModalRef = this.modalService.show(EditpunchlistComponent, { class: 'modal-xl', backdrop: 'static', keyboard: false })
}

error() {
    this.alertService.error("wrong value inserted");
}

searchBarEnterFunc(data: any) {
  console.log('[searchBarEnterFunc] (data) ' + JSON.stringify(data));
  this.category = "";
  this.assetName = "";
  this.disciplineName = "";
  this.description = "";
  this.dateR = "";
  this.dateC = "";
  this.compDate = "";
  this.dateRaisedString = "";
  this.dateClosedString = "";
  this.dateEstimatedCompletionString = "";
  this.raisedBy = "";
  this.materialRequired = "";
  this.subSystemName = "";
  this.estManHours = "";
  this.itemStatus = "";
  this.count = 0;
  this.gridApi.showLoadingOverlay();
  //check fro empty values in search bar
  if (this.checkForEmptyValuesInSearchBar(data)) {
      let docArray = data.split(" ");
      for (var l = 0; l <= docArray.length - 1; l++) {
          let letter = docArray[l].split(":");
          if (letter != "") {
              for (var i = 0; i < letter.length - 1; i++) {
                  if (letter[i] == "cat") {
                      this.category = letter[i + 1];
                  } else if (letter[i] == "ass") {
                      this.assetName = letter[i + 1];
                  } else if (letter[i] == "dis") {
                      this.disciplineName = letter[i + 1];
                  } else if (letter[i] == "des") {
                    this.description = letter[i + 1];
                  }  else if (letter[i] == "dateR") {
                    this.dateRaisedString = letter[i + 1];
                  } else if (letter[i] == "dateC") {
                    this.dateClosedString = letter[i + 1];
                  } else if (letter[i] == "com") {
                    this.dateEstimatedCompletionString = letter[i + 1];
                  } else if (letter[i] == "rai") {
                    this.raisedBy = letter[i + 1];
                  } else if (letter[i] == "mat") {
                    this.materialRequired = letter[i + 1];
                  } else if (letter[i] == "subS") {
                    this.subSystemName = letter[i + 1];
                  } else if (letter[i] == "est") {
                    this.estManHours = letter[i + 1];
                  } else if (letter[i] == "item") {
                    this.itemStatus = letter[i + 1];
                  } else {
                      if (this.count == 0) {
                          this.count = 1;
                          this.error();
                      } else {
                          // do nothing dname fac tno sName desc
                      }
                      this.count = 1;
                  }
              }
          }
      }
      /*if(this.dateR != ""){
        this.dateRaised = new Date(this.dateR); 
        console.log(this.dateRaised);
      } else if(this.dateC != ""){
        this.dateClosed = new Date(this.dateC); 
        console.log(this.dateClosed);
      } else if(this.compDate != ""){
        this.completionDate = new Date(this.compDate); 
        console.log(this.completionDate);
      }*/

      if (this.category != "" || this.assetName != "" || this.disciplineName != "" || this.description != "" || this.dateRaisedString != "" || this.dateClosedString != "" || this.dateEstimatedCompletionString != "" || this.raisedBy != "" || this.materialRequired != "" || this.subSystemName != "" || this.estManHours != "" || this.itemStatus != "") {
        let to: any = false;
        if (to) { clearTimeout(to); }
        to = setTimeout(() => {
            this.gridOptions.api.onFilterChanged();
            this.punchlistService.searchPunchlists(this.category, this.assetName, this.disciplineName, this.description, this.dateRaisedString, this.dateClosedString, this.dateEstimatedCompletionString, this.raisedBy, this.materialRequired, this.subSystemName, this.estManHours, this.itemStatus, this.hasText).subscribe(
                data => {
                    this.setDocumentSearchTableData(data.payload)
                },
                error => {
                    this.alertService.clear()
                    this.alertService.error(error.statusDescription)
                });
        }, 200);
    }

  } else {
      //search bar has empty values
      this.gridApi.hideOverlay()
      this.setDocumentSearchTableData(null)
  }
}

/**
* check for empty values in search bar
* @param data 
*/
public checkForEmptyValuesInSearchBar(data: any): boolean {
  let docArray = data.split(" ");
  if (docArray.length > 1) {
      return true
  } else {
      if (docArray[0] != '') {
          return true
      } else {
          return false
      }
  }

}

searchFunc(data: any) {
  this.searchData = data;
  console.log('[searchFunc] (data) ' + JSON.stringify(data));
  this.category = "";
  this.assetName = "";
  this.disciplineName = "";
  this.description = "";
  this.dateR = "";
  this.dateC = "";
  this.compDate = "";
  this.dateRaisedString = "";
  this.dateClosedString = "";
  this.dateEstimatedCompletionString = "";
  this.raisedBy = "";
  this.materialRequired = "";
  this.subSystemName = "";
  this.estManHours = "";
  this.itemStatus = "";
  this.hasText = "";
  this.count = 0;
  this.gridApi.showLoadingOverlay();

  //assign value for the fields from searchWordMap
  var plcategory= data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_PUNCHLIST_CATEGORY];
  if (plcategory != undefined) {
      this.category = plcategory;
  } else {
      //no category
  } 
  var plassetName = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_PUNCHLIST_ASSET];
  if (plassetName != undefined) {
      this.assetName = plassetName;
  } else {
      //no asset
  } 

  var pldisciplineName = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_PUNCHLIST_DISCIPLINE];
  if (pldisciplineName != undefined) {
      this.disciplineName = pldisciplineName;
  } else {
      //no discipline
  }

  var pldescription = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_PUNCHLIST_DESCRIPTION];
  if (pldescription != undefined) {
      this.description = pldescription;
  } else {
      //no description
  }

  var date_raised = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_PUNCHLIST_DATE_RAISED];
  if (date_raised != undefined) {
      this.dateRaisedString = date_raised;
  } else {
      //no date_raised
  }

  var date_closed = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_PUNCHLIST_DATE_CLOSED];
  if (date_closed != undefined) {
      this.dateClosedString = date_closed;
  } else {
      //no date_closed
  }

  var completion_date = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_PUNCHLIST_COMPLETION_DATE];
  if (completion_date != undefined) {
      this.dateEstimatedCompletionString = completion_date;
  } else {
      //no completion_date
  }

  var raised_by = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_PUNCHLIST_RAISED_BY];
  if (raised_by != undefined) {
      this.raisedBy = raised_by;
  } else {
      //no raised_by
  }

  var material_required = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_PUNCHLIST_MATERIAL_REQUIRED];
  if (material_required != undefined) {
      this.materialRequired = material_required;
  } else {
      //no material_required
  }

  var sub_systems = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_PUNCHLIST_SUB_SYSTEMS];
  if (sub_systems != undefined) {
      this.subSystemName = sub_systems;
  } else {
      //no sub_systems
  }

  var est_man_hours = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_PUNCHLIST_EST_MAN_HOURS];
  if (est_man_hours != undefined) {
      this.estManHours = est_man_hours;
  } else {
      //no est_man_hours
  }

  var item_status = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_PUNCHLIST_ITEM_STATUS];
  if (item_status != undefined) {
      this.itemStatus = item_status;
  } else {
      //no item_status
  }

  var hasText = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_HAS_THE_WORDS];
  if (hasText != undefined) {
      this.hasText = hasText;
  } else {
      //no has text
  }

  /*if(this.dateR != ""){
    this.dateRaised = new Date(this.dateR); 
    console.log(this.dateRaised);
  } else if(this.dateC != ""){
    this.dateClosed = new Date(this.dateC); 
    console.log(this.dateClosed);
  } else if(this.compDate != ""){
    this.completionDate = new Date(this.compDate); 
    console.log(this.completionDate);
  }*/

  if (this.category != "" || this.assetName != "" || this.disciplineName != "" || this.description != "" || this.dateRaisedString != "" || this.dateClosedString != "" || this.dateEstimatedCompletionString != "" || this.raisedBy != "" || this.materialRequired != "" || this.subSystemName != "" || this.estManHours != "" || this.itemStatus != "" ||  this.hasText != "") {
      let to: any = false;
      if (to) { clearTimeout(to); }
      to = setTimeout(() => {
          // this.gridOptions.api.onFilterChanged();
          this.punchlistService.searchPunchlists(this.category, this.assetName, this.disciplineName, this.description, this.dateRaisedString, this.dateClosedString, this.dateEstimatedCompletionString, this.raisedBy, this.materialRequired, this.subSystemName, this.estManHours, this.itemStatus, this.hasText).subscribe(
              data => {
                  this.setDocumentSearchTableData(data.payload)
                  console.log(data.payload);
              },
              error => {
                  this.alertService.clear()
                  this.alertService.error(error.statusDescription)
              });
      }, 200);
  } else {
      //search bar has empty values
      this.gridApi.hideOverlay()
      this.setDocumentSearchTableData(null)
  }

}

setDocumentSearchTableData(payload: any) {
  this.loadingService.hideLoading();
  //console.log(payload[0]["category"]);
  this.filteredData = [];
  this.rowData = [];
  if (payload != null) {
    //Achini Randeni
    //set call back to this component
    this.dependenciesmodalService.setCallback(this);
       if (this.expandComponents == false) {
          for (let i = 0; i < payload.length; i++) {
            let completionDate = ''
            let dateClosed = ''
            let dateRaised = ''
            //let location = ''
            if (payload[i]["estimatedCompletionDate"]) {
              completionDate = new Date(payload[i]["estimatedCompletionDate"]).toLocaleDateString()
            }
            if (payload[i]["dateClosed"]) {
              dateClosed = new Date(payload[i]["dateClosed"]).toLocaleDateString()
            }
            if (payload[i]["dateRaised"]) {
              dateRaised = new Date(payload[i]["dateRaised"]).toLocaleDateString()
            }
           
              this.filteredData.push({
                  'id': payload[i]["id"],
                  'category': payload[i]["category"] != null ? payload[i]["category"] : '',
                  'assetName': payload[i]["assetId"] != null ? this.decodeHTMLString.transform(payload[i]["assetId"]) : '',
                  'disciplineName': payload[i]["disciplineId"] != null ? payload[i]["disciplineId"] : payload[i]["disciplineId"],
                  'plDescription': payload[i]["punchlistDescription"] != null ? this.decodeHTMLString.transform(payload[i]["punchlistDescription"]) : '',
                  'dateRaised': dateRaised,
                  'dateClosed': dateClosed,
                  'completionDate': completionDate,
                  'raisedBy': payload[i]["raisedBy"] != null ? this.decodeHTMLString.transform(payload[i]["raisedBy"]) : '',
                  'materialsReq': payload[i]["materialsRequired"] != null ? this.decodeHTMLString.transform(payload[i]["materialsRequired"]) : '',
                  'subSystemName': payload[i]["subSystemId"] != null ? this.decodeHTMLString.transform(payload[i]["subSystemId"]) : '',
                  status: "status:" + payload[i]["id"] + ":" + payload[i]["status"],
                  edit: "edit:" + payload[i]["id"] + ":" + payload[i]["status"],
                  delete: "delete:" + payload[i]["id"] + ":" + payload[i]["status"],
                  'estiManHours': payload[i]["estimatedManHours"] != null ? payload[i]["estimatedManHours"] : '',
                  'itemStatus': payload[i]["itemStatus"],
                  'documents': payload[i]["punchlistDocuments"],
                  'dependencies': payload[i]["hasDependencies"] ? "dependencies:" + payload[i]["id"] : "disable_dependencies:" + payload[i]["id"],
                  'vendor': payload[i]["vendorName"],
                  opensentpdf: "opensentpdf:" + payload[i]["id"] + ":" + payload[i]["itemStatus"],
                  openreturnedpdf: "openreturnedpdf:" + payload[i]["id"] + ":" + payload[i]["itemStatus"],
                  closesentpdf: "closesentpdf:" + payload[i]["id"] + ":" + payload[i]["itemStatus"],
                  closereturnedpdf: "closereturnedpdf:" + payload[i]["id"] + ":" + payload[i]["itemStatus"],
                  docuSignStatus: payload[i]["docuSignStatus"],
                  docuSignId: payload[i]["docuSignId"],
                  openPunchlistGroupId: payload[i]["openPunchlistGroupId"],
                  closePunchlistGroupId: payload[i]["closePunchlistGroupId"],
                  isOpenReturnPDFUploaded: payload[i]["isOpenReturnPDFUploaded"],
                  isCloseReturnPDFUploaded: payload[i]["isCloseReturnPDFUploaded"],
                  images: payload[i]["punchlistImages"],
                  firstOilRequired: payload[i]["firstOilRequired"] ? "Yes" : "No"
              });
              //Achini Randeni
              //get punchlist dependencies
              //this.dependenciesmodalService.getDependeciesWithId( payload[i]["id"], Constants.DEPENDENCY_ITEM_TYPE.PUNCHLIST);
          }
     }
      this.rowData = this.filteredData;
      console.log(this.rowData);
      this.gridApi.paginationProxy.currentPage = 0;
      this.gridOptions.api.sizeColumnsToFit();
  } else {
      // no row data to show
      this.rowData = [];
  }
  this.punchlistService.setRowData(this.rowData);
}

public statusCellRenderer(param: any) {
  switch (param.value) {
    case 'Initial':
			return '<span class="badge badge-warning px-2 py-1">Initial</span>'
    case 'Open':
      return '<span class="badge badge-warning px-2 py-1">Open</span>'
    case 'Closed':
      return '<span class="badge badge-success px-2 py-1">Closed</span>'
    default:
      return ''
  }
}

ngAfterViewInit(): void {
  //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
  //Add 'implements AfterViewInit' to the class.
  this.subsVar = this.sharedService.expandReusablesExcute().subscribe((option: any) => this.changeComponent(option))
  //this.disablePlId = this.sharedService.disablePunchlistResponse().subscribe((option: any) => this.disable(option));
  //this.deletePlId = this.sharedService.deletePunchlistResponse().subscribe((option: any) => this.delete(option));
  this.editPlId = this.sharedService.editPunchlistResponse().subscribe((option: any) => this.edit(option));
}

ngOnDestroy(): void {
  //Called once, before the instance is destroyed.
  //Add 'implements OnDestroy' to the class.
  if(this.subsVar) {
      this.subsVar.unsubscribe()
  }
  if (this.disablePlId) {
    this.disablePlId.unsubscribe()
  }
  else if (this.disablePlId) {
    this.disablePlId.unsubscribe()
  }
  else if (this.deletePlId) {
    this.deletePlId.unsubscribe()
  }
  else if (this.editPlId) {
    this.editPlId.unsubscribe()
  }
  else{

  }
  window.removeEventListener("resize", this.onWindowResize);
  this.gridApi.destroy();
  this.gridApi = null;
}

onCellClick(event) {
}

changeComponent(option: any) {
  // console.log('[changeComponent] (option) ' + JSON.stringify(option));
}

public downloadDocument(doc:any) {
  let fileExtention = doc.contentType;
  let outputId = doc.id;
  let outputFile = null;
  let punchlistId = doc.punchlistId;
  let projectId = doc.projectId;
  let fileName = doc.fileName;

  this.loadingService.showLoading(false, false, '', 0);
  this.fileUploadService.downloadFile(outputId, punchlistId, projectId, fileExtention, fileName)
  .subscribe(response => {
    console
        this.loadingService.hideLoading()
        let file = new Blob([response], { type: 'application/pdf' });
        //let file = new Blob([response], { type: response.type });
        FileSaver.saveAs(file, fileName);
      },
      error => {
        this.loadingService.hideLoading()
        this.alertService.clear()
        this.alertService.error("Could not download the file.");
      });
}

onSuccess(data: WsResponse, serviceType: WsType): void {
  // console.log(data.payload)
  console.log("data.payload "  + serviceType)	
  if (data.payload != null && serviceType == WsType.ITEM_DEPENDENCY_LIST) {
    let items = data.payload.split("_")
		   if(items[1]=="Yes")
		   {
				for(let i = 0; i < this.rowData.length; i++)
				{
					if(this.rowData[i].id == items[0])
					{
						//Achini Randeni
            //if dependencies are available, then enable the dependency link
            var rowNode = this.gridApi.getRowNode(items[0]);
            rowNode.setDataValue("dependencies", "dependencies:" + items[0])
					}
				}
		   }
  }
  if (serviceType == WsType.DISABLE_PUNCHLIST) {
    if(this.status == 'true'){
      this.alertService.success("Successfully disabled the punchlist");
    }else{
      this.alertService.success("Successfully enabled the punchlist");
    }
    this.loadingService.hideLoading()
    this.gridApi.showLoadingOverlay()
    this.searchFunc(this.searchData);
  }
  else if (serviceType == WsType.DELETE_PUNCHLIST) {
    this.alertService.success("Successfully deleted the punchlist");
    this.loadingService.hideLoading()
    this.gridApi.showLoadingOverlay()
    this.searchFunc(this.searchData);
  }
  else if (serviceType == WsType.REVOKE_PUNCHLIST) {
      this.alertService.success("Successfully revoked the punchlist(s)");
      if(data.payload){
          let itemIndex = this.rowData.findIndex(item => item.id == data.payload.id);
          if (this.rowData[itemIndex]) {
              this.rowData[itemIndex].docuSignStatus= null;
              this.gridApi.setRowData(this.rowData);
          }
      }
  }
  else {
  
  }
}

    onFail(res: WsResponse, serviceType: WsType): void {        
		if (serviceType == WsType.REVOKE_PUNCHLIST) {
			this.alertService.error(res.statusDescription);
		}
    }

    
	
	public downloadImage(image:any) {
        let imageId = image.id;
        let punchlistId = image.itemId;
        let projectId = image.projectId;
        let fileName = image.fileName;
        let filePath = image.filePath;
        let fileExtention = fileName.substring(fileName.lastIndexOf("."));
        
        this.loadingService.showLoading(false, false, '', 0);
        this.punchlistService.downloadImage(projectId, punchlistId, filePath)
        .subscribe(
            response => {
                this.loadingService.hideLoading()
                let file = new Blob([response], { type: 'image/' + fileExtention });
                FileSaver.saveAs(file, "PL_" + fileName);
            },
            error => {
                this.loadingService.hideLoading()
                this.alertService.clear()
                this.alertService.error("Could not download the file.");
            });
    }

}
