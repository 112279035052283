import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams, IAfterGuiAttachedParams } from "@ag-grid-enterprise/all-modules";
import { OptDataComponent } from '../opt-data.component';
import { SharedService } from 'src/app/util/shared.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Constants } from 'src/app/util/constants';
import { DependenciesmodalComponent } from 'src/app/util/dependenciesmodal/dependenciesmodal.component';

@Component({
  selector: 'app-edit-cell',
  template: `<button type="button" class="btn btn-sm btn-outline-dark py-0 my-1 px-1 border-0"
  (click)="onEditButtonClick()">
  <i class="far fa-edit" aria-hidden="true"></i>
</button>`,
  styleUrls: []
})
export class EditCellComponent implements OnInit, ICellRendererAngularComp {

  public attribute: any;
  private params: any;
  public componentParent: OptDataComponent;
  private cellData;
  public bsModalRef: BsModalRef;

  constructor(private sharedService: SharedService,private modalService: BsModalService,) { }

  ngOnInit() {
  }

  refresh(params: any): boolean {
    return true;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.cellData = params.data;
    this.componentParent = params.context.componentParent;

    if (params.data != undefined) {
      this.attribute = params.data;
    } else {
      this.attribute = params.value;
    }
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
  }

  onEditButtonClick() {
    this.dependenciesModalWithComponent('Do you want to edit?', false, true);
  }

  public editOperatingData(){
    this.componentParent.editOptData(this.cellData);
  }

  
  dependenciesModalWithComponent(header: String, dflag: boolean, cflag: boolean) {
    const initialState = {
      headerTitle: header,
      delFlag: dflag, 
      cancelFlag: cflag,
      checkboxVal: false
    };
    this.sharedService.setFileId(this.cellData.id);
    this.sharedService.setFileType("OperatingData");
          this.sharedService.setAction("getDependencies");
    this.sharedService.setDependencyMessageType(Constants.DEPENDENCY_MESSAGE_TYPE.EDIT);
		localStorage.setItem("isDelete", "false");
    this.sharedService.setParent(this);
    this.bsModalRef = this.modalService.show(DependenciesmodalComponent, { initialState, class: 'modal-lg' });
    console.log("dflag= " + dflag);
    console.log("cflag= " + cflag);
  }

}
