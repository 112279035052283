<div class="container-fluid px-0">
	<div class="row px-3">
	  <div class="col-sm-8 px-0">
		<p class="mb-2 h5">
		  <b>{{ 'PROJECT.HEADER' | translate }}</b>
		</p>
	  </div>
	  <!--
		<div class="col-sm-4 px-0">
			  <button type="submit" class="btn btn-primary btn-sm reusable_btn my-1"
				  (click)="add()" id="client_add">
				  <i class="fas fa-plus"></i>
			  </button>
	  </div>
	  -->
	  
	</div>
	<div>
		  
	  <ag-grid-angular #agGrid style="width: 100%; height: 570px;" class="ag-theme-balham" 
		[enableFilter]="true" [rowData]="rowData" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }" [columnDefs]="columnDefs" rowSelection="multiple" 
		[rowMultiSelectWithClick]="false"   [getRowNodeId]="getRowNodeId" (gridReady)="onGridReady($event)" [pagination]="true"
		[paginationPageSize]="12" [overlayLoadingTemplate]="overlayLoadingTemplate" [defaultColDef]="defaultColDef"
		[overlayNoRowsTemplate]="overlayNoRowsTemplate">
	  </ag-grid-angular>
	</div>
  </div>