import { Injectable } from '@angular/core';

import { ComponentsComponent } from './components/components.component';
import { DocumentsComponent } from './documents/documents.component';
import { FacilitiesComponent } from './facilities/facilities.component';
import { ImagesComponent } from './images/images.component';
import { StatementsComponent } from './statements/statements.component';
import { SystemsComponent } from './systems/systems.component';
import { TagsComponent } from './tags/tags.component';
import { HistoryComponent } from './history/history.component';
import { ThreedModelComponent } from './threed-model/threed-model.component';
import { VideosComponent } from './videos/videos.component';

@Injectable({
	providedIn: 'root'
})
export class ReusablesService {

	constructor() { }

	public loadComponentByName(componentName: string) {
		switch (componentName) {
			case 'components':
				return ComponentsComponent
			case 'documents':
				return DocumentsComponent
			case 'facilities':
				return FacilitiesComponent
			case 'images':
				return ImagesComponent
			case 'statements':
				return StatementsComponent
			case 'systems':
				return SystemsComponent
			case 'tags':
				return TagsComponent
			case 'threed-model':
				return ThreedModelComponent
			case 'video':
				return VideosComponent
			case 'history':
				return HistoryComponent
			default:
				return
		}
	}
}
