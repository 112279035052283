<div class="modal-header py-2">
    <h5 class="modal-title">
        {{'APPROVE_CHECKSHEET.TITLE' | translate}}
    </h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="container-fluid mt-3 mb-4 modal-container" id="approveChecksheet">
    <form class="mt-2">
        <div class="row">
            <div class="col-sm-3"></div>
            <div class="col-sm-6">
                <p class="text-heading"><b>{{formType}}</b></p>
            </div>
            <div class="col-sm-3"></div>
        </div>
        <div class="row">
            <div class="col-sm-3"></div>
            <div class="col-sm-6">
                <p class="text-heading">{{checkSheetCode}} - {{checkSheetDiscipline}}</p>
            </div>
            <div class="col-sm-3"></div>
        </div>
        <div class="row">
            <div class="col-sm-3"></div>
            <div class="col-sm-6">
                <p class="text-heading">{{checkSheetName}}</p>
            </div>
            <div class="col-sm-3"></div>
        </div>
        <div class="row">
            <div class="col-sm-3">
                <label><b>{{'APPROVE_CHECKSHEET.PHASE' | translate}}</b></label>
            </div>
            <div class="col-sm-3">
                <p>{{phase}}</p>
            </div>
            <div class="col-sm-3">
                <label><b>{{'APPROVE_CHECKSHEET.TAG_NO' | translate}}</b></label>
            </div>
            <div class="col-sm-3">
                <p>{{itemTagNo}}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-3">
                <label><b>{{'APPROVE_CHECKSHEET.PROJECT_NAME' | translate}}</b></label>
            </div>
            <div class="col-sm-3">
                <p>{{projectName}}</p>
            </div>
            <div class="col-sm-3">
                <label><b>{{'APPROVE_CHECKSHEET.SUB_SYSTEM_NO' | translate}}</b></label>
            </div>
            <div class="col-sm-3">
                <p>{{subSystemNo}}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-3">
                <label><b>{{'APPROVE_CHECKSHEET.DATA_SHEET' | translate}}</b></label>
            </div>
            <div class="col-sm-3">
                <p>{{dataSheet}}</p>
            </div>
            <div class="col-sm-3">
                <label><b>{{'APPROVE_CHECKSHEET.SYSTEM_NO' | translate}}</b></label>
            </div>
            <div class="col-sm-3">
                <p>{{systemNo}}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-3">
                <label><b>{{'APPROVE_CHECKSHEET.EQUIPMENT_DESCRIPTION' | translate}}</b></label>
            </div>
            <div class="col-sm-9">
                <p>{{equipmentDescription}}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-3">
                <label><b>{{'APPROVE_CHECKSHEET.SUB_SYSTEM_DESCRIPTION' | translate}}</b></label>
            </div>
            <div class="col-sm-9">
                <p>{{subSystemDescription}}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-3">
                <label><b>{{'APPROVE_CHECKSHEET.LOCATION' | translate}}</b></label>
            </div>
            <div class="col-sm-3">
                <p>{{location}}</p>
            </div>
            <div class="col-sm-3">
                <label><b>{{'APPROVE_CHECKSHEET.MODULE_DETAIL' | translate}}</b></label>
            </div>
            <div class="col-sm-3">
                <p>{{moduleDetail}}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-3">
                <label><b>{{'APPROVE_CHECKSHEET.DRAWING_REFERENCE' | translate}}</b></label>
            </div>
            <div class="col-sm-3">
                <p>{{drawingReference}}</p>
            </div>
            <div class="col-sm-3">
                <label><b>{{'APPROVE_CHECKSHEET.DATE_PRINTED' | translate}}</b></label>
            </div>
            <div class="col-sm-3">
                <p>{{datePrinted}}</p>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-sm-3">
                <label><b>{{'APPROVE_CHECKSHEET.CONFIRM_VERIFY' | translate}}</b></label>
            </div>
        </div>
        <diV *ngFor="let csInstanceData of checkSheetInstanceData; let i = index">
            <div class="row">
                <div class="col-sm-9 wordwrap">
                    <p>{{csInstanceData.checkDescription}}</p>
                </div>
                <div *ngIf="csInstanceData.checkType == 'OK-NA-PL'" class="col-sm-3">
                    <div class="checkboxes">
                        <input type="checkbox" name="chkOk" [disabled]="csInstanceData.isDisabled || isApproved"
                            [checked]="csInstanceData.checkedOK"
                            (change)="checkboxChecked($event, csInstanceData, 'OK')">
                        <label>{{'APPROVE_CHECKSHEET.OK' | translate}}</label>
                        <input type="checkbox" name="chkNA" [disabled]="csInstanceData.isDisabled || isApproved"
                            [checked]="csInstanceData.checkedNA"
                            (change)="checkboxChecked($event, csInstanceData, 'NA')">
                        <label>{{'APPROVE_CHECKSHEET.NA' | translate}}</label>
                        <input type="checkbox" name="chkPL" [disabled]="csInstanceData.isDisabled || isApproved"
                            [checked]="csInstanceData.checkedPL"
                            (change)="checkboxChecked($event, csInstanceData, 'PL')">
                        <label>{{'APPROVE_CHECKSHEET.PL' | translate}}</label>
                    </div>
                </div>
                <div *ngIf="csInstanceData.checkType == 'DATA ENTRY'" class="col-sm-3">
                    <input type="text" name="chkInput{{i}}" [disabled]="isApproved"
                        [(ngModel)]="csInstanceData.checkInput" />
                </div>
            </div>
        </diV>
        <br>
        <div class="row">
            <div class="col-sm-9">
                <label><b>{{'APPROVE_CHECKSHEET.PUNCHLIST' | translate}}</b></label>
            </div>
            <div class="col-sm-3">
                <label><b>{{'APPROVE_CHECKSHEET.CODE' | translate}}</b></label>
            </div>
        </div>
        <br>
        <div *ngFor="let punchlist of punchlists; let p = index">
            <div class="row">
                <div class="col-sm-9 wordwrap">
                    <p>{{punchlist.punchlistDescription}}</p>
                </div>
                <div class="col-sm-3">
                    <p>{{punchlist.category}}</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-3">
                <label><b>{{'APPROVE_CHECKSHEET.COMMENTS' | translate}}</b></label>
            </div>
            <diV *ngIf="!isApproved" class="col-sm-6">
                <textarea id="comment" [(ngModel)]="comments" class="form-control"></textarea>
            </diV>
            <diV *ngIf="isApproved" class="col-sm-6">
                <textarea id="comment" [(ngModel)]="comments" class="form-control" [disabled]="true"></textarea>
            </diV>
            <div class="col-sm-3"></div>
        </div>
        <br>
        <div class="container grid-striped">
            <div class="row font-weight-bold pt-2 pb-2">
                <div class="col-sm-3">
                    <label class="m-0"><b>{{'APPROVE_CHECKSHEET.VERIFIED_BY' | translate}}</b></label>
                </div>
                <div class="col-sm-3">
                    <label class="m-0"><b>{{'APPROVE_CHECKSHEET.CONTRACTOR_REPRESENTATIVE' | translate}}</b></label>
                </div>
                <div class="col-sm-3">
                    <label class="m-0"><b>{{'APPROVE_CHECKSHEET.COMPANY_REPRESENTATIVE' | translate}}</b></label>
                </div>
                <div class="col-sm-3">
                    <label class="m-0"><b>{{'APPROVE_CHECKSHEET.MCCR_INPUT_TO_SCDB' | translate}}</b></label>
                </div>
            </div>
            <br>
            <div class="row pt-2 pb-2">
                <div class="col-sm-3 my-auto">
                    <label class="m-0">{{'APPROVE_CHECKSHEET.PRINT_NAME' | translate}}</label>
                </div>
                <div *ngIf="!isApproved" class="col-sm-3">
                    <input type="text" id="conRepName" name="conRepName" [(ngModel)]="contracterRepName"
                        class="form-control" />
                </div>
                <div *ngIf="isApproved" class="col-sm-3 my-auto">
                    <label class="m-0">{{contracterRepName}}</label>
                </div>
                <div *ngIf="!isApproved" class="col-sm-3">
                    <input type="text" id="comRepName" name="comRepName" [(ngModel)]="companyRepName"
                        class="form-control" />
                </div>
                <div *ngIf="isApproved" class="col-sm-3 my-auto">
                    <label class="m-0">{{companyRepName}}</label>
                </div>
                <div class="col-sm-3 my-auto">
                    <label class="m-0">{{MCCRToSCDBName}}</label>
                </div>
            </div>
            <br>
            <div class="row pt-2 pb-2">
                <div class="col-sm-3 my-auto">
                    <label class="m-0">{{'APPROVE_CHECKSHEET.DATE' | translate}}</label>
                </div>
                <div *ngIf="!isApproved" class="col-sm-3">
                    <input type="text" placeholder="Select date" class="form-control newheight" name="date_con"
                        autocomplete="off" id="conRepDate" [(ngModel)]="contracterRepDate" [bsConfig]="bsConfig"
                        placement="top" bsDatepicker>
                </div>
                <div *ngIf="isApproved" class="col-sm-3 my-auto">
                    <label class="m-0">{{contracterRepDate}}</label>
                </div>
                <div *ngIf="!isApproved" class="col-sm-3">
                    <input type="text" placeholder="Select date" class="form-control newheight" name="date_com"
                        autocomplete="off" id="comRepDate" [(ngModel)]="companyRepDate" [bsConfig]="bsConfig"
                        placement="top" bsDatepicker>
                </div>
                <div *ngIf="isApproved" class="col-sm-3 my-auto">
                    <label class="m-0">{{companyRepDate}}</label>
                </div>
                <div class="col-sm-3 my-auto">
                    <label class="m-0">{{MCCRToSCDBDate}}</label>
                </div>
            </div>
            <br>
            <div class="row pt-2 pb-2">
                <div class="col-sm-3 my-auto">
                    <label class="m-0">{{'APPROVE_CHECKSHEET.SIGNATURE' | translate}}</label>
                </div>
                <div class="col-sm-3">
                        <img *ngIf="comRepSignature != null"src="data:image/png;base64,{{comRepSignature}}" alt="" height="auto" class="img-fluid plimage">
                </div>
                <div class="col-sm-3">
                        <img *ngIf="conRepSignature != null" src="data:image/png;base64,{{conRepSignature}}" alt="" height="auto" class="img-fluid plimage">
                </div>
                <div class="col-sm-3"></div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <div class="row justify-content-between w-100">
        <div class="col-4">
            <div class="row">
                <div class="col-lg-7">
                    <label>{{'APPROVE_CHECKSHEET.ATTACH_SCANNED_CHECKSHEET' | translate}}</label>
                </div>
                <div class="col-lg-5">
                    <div class="form-group files singlebtn mb-1">
                        <input type="file" class="form-control  custom-b  form-control-file" Name="profile"
                            id="NewFile_FileUpload" accept=".pdf" (change)="onFileChanged($event)">
                    </div>
                </div>
            </div>
            <div class="row justify-content-between">
                <div class="col-sm-6">
                    <label class="px-2 " *ngIf="csDocumentUpload">
                        <mark id="newfilename"
                            class="form-text text-muted pl-2 pr-2 rounded">{{csDocumentUpload.file.name}}
                        </mark>
                    </label>
                </div>
            </div>
        </div>
        <div class="col-6">
            <button type="submit" id="save_for_approval" class="btn btn-primary btn-sm float-right mr-2"
               [disabled]="isApproved" (click)="saveApproval()">{{'APPROVE_CHECKSHEET.SAVE_FOR_APPROVAL' | translate}}</button>
            <button type="submit" id="save_for_later" class="btn btn-primary btn-sm float-right mr-2"
               [disabled]="isApproved" (click)="saveLater()">{{'APPROVE_CHECKSHEET.SAVE_FOR_LATER' | translate}}</button>
               <button type="submit" id="upload_file" class="btn btn-primary btn-sm float-right mr-2"
               (click)="clickUpload()">{{'APPROVE_CHECKSHEET.UPLOAD' | translate}}</button>
            <button type="button" id="cs_cancel" class="btn btn-secondary btn-sm float-right mr-2"
                (click)="cancel()">{{'APPROVE_CHECKSHEET.CANCEL' | translate}}</button>
        </div>
    </div>
</div>

<ng-template #replaceFileConfirmModal>
        <div class="modal-header">
            <h4 class="modal-title pull-left">CheckSheet File Upload</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="canceledFileUpload()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body ">
            <h5>Do you want to replace the existing file?</h5>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="confirmedReplaceFile()">
                Yes </button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal"
                (click)="canceledFileUpload()">
                {{ 'CANCEL' | translate}} </button>
        </div>
    </ng-template>

    <ng-template #removePunchlistConfirmModal>
            <div class="modal-header">
                <h4 class="modal-title pull-left warn-text-editor">WARNING</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="canceledRemovePunchlist()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body ">
                <h5>The Punchlists tied to this checksheet will no longer be tied to that checksheet. Do you want to continue?</h5>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="confirmedRemovePunchlist()">
                    Yes </button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal"
                    (click)="canceledRemovePunchlist()">
                    {{ 'CANCEL' | translate}} </button>
            </div>
        </ng-template>