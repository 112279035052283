import { Component, OnInit, ViewChild, ElementRef, OnDestroy, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FacilitiesService } from '../facilities/facilities.service'
import { WsCallback } from '../../util/ws-callback.interface';
import { WsType } from '../../util/ws-type';
import { WsResponse } from '../../util/ws-response.model';
import { AlertService } from '../../util/alert/alert.service';
import { AgGridAngular } from '@ag-grid-community/angular';
import { ColDef, GridOptions, Module, AllModules } from "@ag-grid-enterprise/all-modules";
import { SharedService } from '../../util/shared.service';
import { Router } from '@angular/router';
import { FacilitiesCellRenderComponent } from './facilities-cell-render-component.component';
import { Constants, UI_LAYOUT, LocalStorageConst, SessionStorageConst } from '../../util/constants';
import { LoadingService } from '../../util/loading/loading.service';
import { FACILITY_UI_VIEW } from '../../util/constants';
import { HistoryService } from '../history/history.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AddedfacilitiesComponent } from './addedfacilities/addedfacilities.component';
import { Subscription } from 'rxjs';
import { DependenciesModalService } from './../../util/dependenciesmodal/dependenciesmodal.service';
import { CapabilityService } from '../../util/capability.service';

@Component({
	selector: 'app-facilities',
	templateUrl: './facilities.component.html',
	styleUrls: ['./facilities.component.css']
})

export class FacilitiesComponent implements OnInit, WsCallback, AfterViewInit, OnDestroy {

	@ViewChild('agGrid') agGrid: AgGridAngular;
	@ViewChild('expandName') expandName: ElementRef;
	@ViewChild('expandEmail') expandEmail: ElementRef;
	@ViewChild('expandsecondaryEmail') expandsecondaryEmail: ElementRef;
	//to add a new facility
	@ViewChild('newName') newName: ElementRef;
	@ViewChild('newEmail') newEmail: ElementRef;
	@ViewChild('newsecondaryEmail') newsecondaryEmail: ElementRef;

	public gridOptions: GridOptions;

	public fuv: any = FACILITY_UI_VIEW;
	private gridApi: any;
	public getRowNodeId: any;
	private gridColumnApi: any;
	private expandSubscription: any;
	public columnDefs: any;
	public modules: Module[] = AllModules;
	public defaultColDef: any;
	rowData: any = [];
	public overlayLoadingTemplate: any;
	public overlayNoRowsTemplate: any;
	private filteredData: Array<any> = [];

	public expandedView: string;
	private facilityName: string = "";
	private email: string = "";
	private secondaryEmail: string = "";
	private status: string = "";
	private edit: string = "";
	private delete: string = "";
	private reusableType: string = "";
	private resuableText: string = "";
	private reusableXML: string = "";
	private reusableView: string = "";
	private hasText: string = "";

	private count: number = 0;

	// Capabilities
	public modifyCap: boolean = false;
	public queryCap: boolean = false;
	public deleteCap: boolean = false;
	public disableCap: boolean = false;
	public createCap: boolean = false;
 
	//enable disable button/icon
	public enableAddFacility: boolean = false;
	public enableSearchFacility: boolean = false;
	public enableChangeFacilityStatus: boolean = false;
	public enableEditFacility: boolean = false;
	public enableDeleteFacility: boolean = false;

	changeLayout: boolean = false

	private bsModalRef: BsModalRef
	private subsVar: Subscription

	constructor(private facilitiesService: FacilitiesService, private alertService: AlertService,
		private loadingService: LoadingService, private sharedService: SharedService, private modalService: BsModalService,
		private translate: TranslateService, private historyService: HistoryService, private router: Router, 
		private dependenciesmodalService: DependenciesModalService, private capabilityService: CapabilityService
	) {
		translate.addLangs(["es", "en"]);
		translate.setDefaultLang("en");
		let browserLang = translate.getBrowserLang();
		translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');

		this.translate.get('FACILITY_SEARCH_VIEW.FACILITY_NAME').subscribe((res: string) => {
			this.facilityName = res;
			this.translate.get('FACILITY_SEARCH_VIEW.EMAIL').subscribe((res: string) => {
				this.email = res;
				this.translate.get('FACILITY_SEARCH_VIEW.SECONDARY_EMAIL').subscribe((res: string) => {
					this.secondaryEmail = res;
					this.translate.get('FACILITY_SEARCH_VIEW.STATUS').subscribe((res: string) => {
						this.status = res;
						this.translate.get('FACILITY_SEARCH_VIEW.EDIT').subscribe((res: string) => {
							this.edit = res;
							this.translate.get('FACILITY_SEARCH_VIEW.DELETE').subscribe((res: string) => {
								this.delete = res;
							});
						});
					});
				});
			});
		});

		//check the user capability and enable/disable buttons
		this.enableDisableButtons();
		
		this.columnDefs = [
			{
				headerName: 'Dependencies',
				field: "dependencies",
				width: 100,
				cellStyle: { 'text-align': 'center' },
				cellRendererFramework: FacilitiesCellRenderComponent
			},
			{
				headerName: this.facilityName,
				field: 'facilityName',
				checkboxSelection: false,
				hide: false,
				suppressColumnsToolPanel: false,
				sort: 'asc'
			},
			{
				headerName: this.email,
				field: 'email',
				checkboxSelection: false,
				hide: false,
				suppressColumnsToolPanel: false
			},
			{
				headerName: this.secondaryEmail,
				field: 'secondaryEmail',
				checkboxSelection: false,
				hide: false,
				suppressColumnsToolPanel: false
			},
			{
				headerName: this.status,
				field: "status",
				width: 85,
				hide:!this.enableChangeFacilityStatus,
				cellStyle: { 'text-align': 'center' },
				cellRendererFramework: FacilitiesCellRenderComponent
			},
			{
				headerName: this.edit,
				field: "edit",
				width: 85,
				hide:!this.enableEditFacility,
				cellStyle: { 'text-align': 'center' },
				cellRendererFramework: FacilitiesCellRenderComponent
			},
			{
				headerName: this.delete,
				field: "delete",
				width: 85,
				hide:!this.enableDeleteFacility,
				cellStyle: { 'text-align': 'center' },
				cellRendererFramework: FacilitiesCellRenderComponent
			}
		];

		this.getRowNodeId = function (data) {
			return data.id;
		};

		this.gridOptions = {
			rowData: this.rowData,
			columnDefs: this.columnDefs,
			rowSelection: "single",
			rowMultiSelectWithClick: false,
			isExternalFilterPresent: this.externalFilterPresent,
			doesExternalFilterPass: this.externalFilterPass,
		};
		this.defaultColDef = {
			resizable: true,
			sortable: true,
		}
		//custom layout templates for loading and empty data sets
		this.overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Loading data...</span>';
		this.overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">No rows to show</span>';
		facilitiesService.setCallback(this);
	}

	onGridReady(params) {
		const allColumnIds = [];
		this.columnDefs.forEach((columnDef) => {
			allColumnIds.push(<ColDef>columnDef);
		});
		this.gridColumnApi = params.columnApi;
		this.gridApi = params.api;
		this.facilitiesService.setApiGridData(this.gridApi);
		params.api.sizeColumnsToFit();
		// window.addEventListener("resize", function () {
		// 	setTimeout(function () {
		// 		params.api.sizeColumnsToFit();
		// 	});
		// });
		window.addEventListener("resize", this.onWindowResize);
	}

	private onWindowResize = () => {
   
		setTimeout(() => {
			if (this.gridApi) {
				this.gridApi.sizeColumnsToFit();
			}
		}); 
	};

	ngOnInit() {
		this.expandedView = FACILITY_UI_VIEW.EXPANDED_VIEW;
		if (this.facilitiesService.getRowData() != null) {
			this.rowData = this.facilitiesService.getRowData();
		}
		else {
			// do nothing
		}

		let ui = JSON.parse(sessionStorage.getItem(SessionStorageConst.UI_LAYOUT));
		if (ui !== UI_LAYOUT.UIL_6) {
			this.columnDefs = [
				{
					headerName: this.facilityName,
					cellStyle: { 'text-align': 'center' },
					field: "facilityName",
					checkboxSelection: false,
					hide: false,
					suppressColumnsToolPanel: false, sort: 'asc'
				}
			];
		} else {
			//nothing to chnange
		}
		if (ui == UI_LAYOUT.UIL_2 || ui == UI_LAYOUT.UIL_4) {
			this.expandedView = FACILITY_UI_VIEW.SMALL_VIEW;
			this.changeLayout = false
		} else {
			this.changeLayout = true
		}

	}

	ngAfterViewInit(): void {
		//Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
		//Add 'implements AfterViewInit' to the class.
		this.subsVar = this.sharedService.expandReusablesExcute().subscribe((option: any) => this.changeComponent(option))
		this.subsVar = this.sharedService.openFacilitiesEditRespond().subscribe((option: any) => this.openAddEditFacilityModal())
	}

	ngOnDestroy(): void {
		//Called once, before the instance is destroyed.
		//Add 'implements OnDestroy' to the class.
		if (this.subsVar) {
			this.subsVar.unsubscribe()
		}

		window.removeEventListener("resize", this.onWindowResize);
		this.gridApi.destroy();
    	this.gridApi = null;
	}

	changeComponent(option: any) {
		// console.log('[changeComponent] (option) ' + JSON.stringify(option));
	}

	ngAfterContentInit() {
		//Called after ngOnInit when the component's or directive's content has been initialized.
		//Add 'implements AfterContentInit' to the class.
		this.expandSubscription = this.sharedService.expandReusablesExcute().subscribe(option => this.expandReusables(option))
	}

	add() {
		this.sharedService.setFileId("-1");
		// this.router.navigateByUrl('addedfacilities');
		this.openAddEditFacilityModal()
	}

    /**
     * open add/edit facility modal 
     * to solve data mission issue when save or cancel
     */
	private openAddEditFacilityModal() {
		console.log('[openAddEditFacilityModal]');
		this.bsModalRef = this.modalService.show(AddedfacilitiesComponent, { backdrop: 'static', keyboard : false })
	}

    /**
     * Set row data of facility search to the table.
     * @param payload  
     */
	setFacilitySearchTableData(payload: any) {
		this.loadingService.hideLoading();
		this.filteredData = [];
		this.rowData = [];
		console.log("payload");
		console.log(payload);

		if (payload != null) {
			// this.expandedView = FACILITY_UI_VIEW.RESULT_VIEW;
			this.dependenciesmodalService.setCallback(this);
			this.rowData = [];
			for (let i = 0; i < payload.length; i++) {
				this.rowData.push({
					id: payload[i]["facilityId"],
					dependencies: "disable_dependencies:" + payload[i]["facilityId"],
					facilityName: payload[i]["facilityName"],
					email: payload[i]["emailAddress"],
					type: payload[i]["type"],
					lastUpdateTimeStamp: payload[i]["lastUpdateTimeStamp"],
					secondaryEmail: payload[i]["secondaryEmailAddress"],
					status: "status:" + payload[i]["facilityId"] + ":" + payload[i]["status"],
					edit: "edit:" + payload[i]["facilityId"] + ":" + payload[i]["status"],
					delete: "delete:" + payload[i]["facilityId"] + ":" + payload[i]["status"],
					facilityStatus: payload[i]["status"]
				});

				this.dependenciesmodalService.getDependeciesWithId(payload[i]["facilityId"], "Facility");
			}
			// this.rowData = this.filteredData;
			// this.gridOptions.api.sizeColumnsToFit();
			if (this.gridOptions.api) {
				this.gridOptions.api.sizeColumnsToFit();
			}
			this.gridApi.paginationProxy.currentPage = 0;
			this.facilitiesService.setRowData(this.rowData);
		} else {
			this.rowData = []
			this.facilitiesService.setRowData(this.rowData)
		}
	}

	onSuccess(data: WsResponse, serviceType: WsType): void {
		if (data.payload != null && serviceType == WsType.FACILITY_SEARCH_VIEW) {
			this.setFacilitySearchTableData(data.payload)
			this.facilitiesService.setApiGridData(this.gridApi);
		}
		if (data.payload != null && serviceType == WsType.ITEM_DEPENDENCY_LIST) {
			let items = data.payload.split("_")
			if (items[1] == "Yes") {
				for (let i = 0; i < this.rowData.length; i++) {
					if (this.rowData[i].id == items[0]) {
						//var rowNode = this.gridApi.getRowNode(items[0]);
						//rowNode.setDataValue("dependencies", "dependencies:" + items[0])
						this.rowData[i].dependencies = "dependencies:" + items[0];
						if(!this.gridApi.destroyCalled) {
							this.gridApi.setRowData(this.rowData);
						}
					}
				}
			}
		}
	}

	onFail(data: WsResponse, serviceType: WsType): void {
		if (serviceType == WsType.FACILITY_SEARCH_VIEW) {
			if (data.statusCode == "532") {
				//this.router.navigateByUrl('login');
			}
			else if (data.statusCode == "600") {
				this.alertService.error(this.translate.instant('FACILITY_SEARCH_VIEW.LOAD_ERROR'), true);
			} else {
				//show the error message
				this.alertService.error(data.statusDescription.toString(), false);
			}
		}
	}

	externalFilterPresent() {
		return this.facilityName != "";
	}

	externalFilterPass(node: any) {
		return true;
	}

	cellClicked(event) {
		console.log('row', event.data.facilityStatus);
		console.log('kkkkk', event.data.type);
        let status = event.data.status.split(":")[2];
		if (status != null) {
			if (status == "Active" || status == "active") {
				let tabType = SharedService.getTabComponentType();
				if (tabType == 'Editor') {
					SharedService.insertEditorRequest(event.data);
				} else if (tabType == 'Procedures') {
					SharedService.insertProceduresRequest(event.data);
				} else if (tabType == 'IceCourse') {
					SharedService.insertICECourseRequest(event.data);
				}
				this.reusableView = event.data.facilityName;
				this.reusableXML = SharedService.getHtmlString();
				this.save(this.reusableXML, this.reusableView);
				console.log("to save");
			} else {
				this.translate.get('REUSABLES.COULD_NOT_ENTER_DISABLED_REUSABLES').subscribe((res: string) => {
                    this.alertService.error(res, false);
                });
			}
		}

	}

	save(reusableXML: any, reusableView: any) {
		console.log("save");
		if (this.reusableXML != null) {
			let xml = this.reusableXML.replace(/insid=.{38}/gi, function insId() {
                function s4() {
                    return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
                }
                return 'insId="' + s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4() + '"';
            });
			let to: any = false;
			if (to) { clearTimeout(to); }
			to = setTimeout(() => {
				this.reusableType = "Facility";
				this.resuableText = "fac";
				this.historyService.saveToHistory(this.reusableType, this.resuableText, xml, this.reusableView).subscribe(
					data => {
						console.log("success");
					},
					error => {
						this.alertService.clear()
						this.alertService.error(error.statusDescription)
					});;
			}, 200);
		}
	}

	expandSearchByFacilityName(event: any) {
		let to: any = false;
		if (to) { clearTimeout(to); }
		to = setTimeout(() => {
			this.facilityName = event.target.value;
			this.email = this.expandEmail.nativeElement.value;
			this.secondaryEmail = this.expandsecondaryEmail.nativeElement.value;
			// this.gridOptions.api.onFilterChanged();
			this.facilitiesService.searchFacilities(this.facilityName, this.email, this.secondaryEmail, this.hasText);
		}, 200);
	}

	expandSearchByEmail(event: any) {
		let to: any = false;
		if (to) { clearTimeout(to); }
		to = setTimeout(() => {
			this.email = event.target.value;
			this.facilityName = this.expandName.nativeElement.value;
			this.secondaryEmail = this.expandsecondaryEmail.nativeElement.value;
			//this.gridOptions.api.onFilterChanged();
			if (this.gridOptions.api) {
				this.gridOptions.api.onFilterChanged();
			}
			this.facilitiesService.searchFacilities(this.facilityName, this.email, this.secondaryEmail, this.hasText);
		}, 200);
	}

	expandSearchBySecondaryEmail(event: any) {
		let to: any = false;
		if (to) { clearTimeout(to); }
		to = setTimeout(() => {
			this.secondaryEmail = event.target.value;
			this.facilityName = this.expandName.nativeElement.value;
			this.email = this.expandEmail.nativeElement.value;
			// this.gridOptions.api.onFilterChanged();
			this.facilitiesService.searchFacilities(this.facilityName, this.email, this.secondaryEmail, this.hasText);
		}, 200);
	}
    /**
     * previous search
     */
	//facilities view not expanded
	// searchByFacilityName(event: any) {
	//     let to: any = false;
	//     if (to) { clearTimeout(to); }
	//     to = setTimeout(() => {
	//         this.facilityName = event.target.value;
	//         // this.gridOptions.api.onFilterChanged();
	//         this.facilitiesService.searchFacilities(this.facilityName, this.email, this.secondaryEmail);
	//     }, 200);
	// }

	//facilities view expanded
	private expandReusables(option: any) {

	}

    /**
     * seach component search button click event
     * @param data 
     */
	public searchFunc(data: any) {
		console.log('[searchFunc] (data) ' + JSON.stringify(data));
		this.alertService.clear();
		this.email = "";
		this.facilityName = "";
		this.secondaryEmail = "";
		this.hasText = "";
		this.count = 0;
		this.gridApi.showLoadingOverlay();


		//assign value for the fields from searchWordMap
		var facName = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_FACILITY_FAC_NAME];
		if (facName != undefined) {
			this.facilityName = facName;
		} else {
			//no facilityName
		}
		var eml = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_FACILITY_EMAIL];
		if (eml != undefined) {
			this.email = eml;
		} else {
			//no email
		}

		var secEmail = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_FACILITY_SEC_EMAIL];
		if (secEmail != undefined) {
			this.secondaryEmail = secEmail;
		} else {
			//no secondaryEmail
		}

		var hasText = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_HAS_THE_WORDS];
		if (hasText != undefined) {
			this.hasText = hasText;
		} else {
			//no has text
		}


        /*data.jsonData.forEach(item => {
            switch (item.key) {
                case Constants.SEARCH_KEY_WORDS.SEARCH_FACILITY_FAC_NAME:
                    this.facilityName = item.value
                    break;
                case Constants.SEARCH_KEY_WORDS.SEARCH_FACILITY_EMAIL:
                    this.email = item.value
                    break;
                case Constants.SEARCH_KEY_WORDS.SEARCH_FACILITY_SEC_EMAIL:
                    this.secondaryEmail = item.value
                    break;
                default:
                    break;
            }
        });*/

		// let docArray = data.searchTxt.split(" ");
		// for (var l = 0; l <= docArray.length - 1; l++) {
		//     let letter = docArray[l].split(":");
		//     if (letter != "") {
		//         for (var i = 0; i < letter.length - 1; i++) {
		//             if (letter[i] == "email") {
		//                 this.email = letter[i + 1];
		//             } else if (letter[i] == "fac") {
		//                 this.facilityName = letter[i + 1];
		//             } else if (letter[i] == "semail") {
		//                 this.secondaryEmail = letter[i + 1];
		//             } else {
		//                 if (this.count == 0) {
		//                     this.count = 1;
		//                     this.error();
		//                 } else {
		//                     // do nothing dname fac tno sName desc
		//                 }
		//                 this.count = 1;
		//             }
		//         }
		//     }
		// }

		if (this.email != "" || this.facilityName != "" || this.secondaryEmail != "" || this.hasText != "") {
			let to: any = false;
			if (to) { clearTimeout(to); }
			to = setTimeout(() => {
				//this.gridOptions.api.onFilterChanged();
				this.facilitiesService.searchFacilities(this.facilityName, this.email, this.secondaryEmail, this.hasText);

			}, 200);
		} else {
			//search bar has empty values
			this.gridApi.hideOverlay()
			this.setFacilitySearchTableData(null)
		}

	}

    /**
     * search component input field enter button click event
     * @param data 
     */
	searchBarEnterFunc(data: any) {
		console.log('[searchBarEnterFunc] (data) ' + JSON.stringify(data));
		this.alertService.clear();
		this.email = "";
		this.facilityName = "";
		this.secondaryEmail = "";
		this.count = 0;
		this.gridApi.showLoadingOverlay();

		//check fro empty values in search bar
		if (this.checkForEmptyValuesInSearchBar(data)) {
			let docArray = data.split(" ");
			for (var l = 0; l <= docArray.length - 1; l++) {
				let letter = docArray[l].split(":");
				if (letter != "") {
					for (var i = 0; i < letter.length - 1; i++) {
						if (letter[i] == "email") {
							this.email = letter[i + 1];
						} else if (letter[i] == "fac") {
							this.facilityName = letter[i + 1];
						} else if (letter[i] == "semail") {
							this.secondaryEmail = letter[i + 1];
						} else {
							if (this.count == 0) {
								this.count = 1;
								this.error();
							} else {
								// do nothing dname fac tno sName desc
							}
							this.count = 1;
						}
					}
				}
			}
			if (this.email != "" || this.facilityName != "" || this.secondaryEmail != "") {
				let to: any = false;
				if (to) { clearTimeout(to); }
				to = setTimeout(() => {
					//this.gridOptions.api.onFilterChanged();
					this.facilitiesService.searchFacilities(this.facilityName, this.email, this.secondaryEmail, this.hasText)

				}, 200);
			}
		} else {
			//search bar has empty values
			this.gridApi.hideOverlay()
			this.setFacilitySearchTableData(null)
		}
	}

    /**
     * check for empty values in search bar
     * @param data 
     */
	public checkForEmptyValuesInSearchBar(data: any): boolean {
		let docArray = data.split(" ");
		if (docArray.length > 1) {
			return true
		} else {
			if (docArray[0] != '') {
				return true
			} else {
				return false
			}
		}
	}

	error() {
		this.alertService.error("wrong value inserted");
	}


	/**
     * enable/disable buttons or columns
     */
    private enableDisableButtons(){
        this.capabilityCheck();
        this.enableAddFacility = this.createCap;
        this.enableSearchFacility = this.queryCap;
        this.enableEditFacility = this.modifyCap;
        this.enableDeleteFacility = this.deleteCap;
        this.enableChangeFacilityStatus = this.disableCap;
    }

    /**
     * check the capabilities
     */
    public capabilityCheck(){
        let modifyCapability: string = "modifyFacility";
        let queryCapability: string = "queryFacility";
        let deleteCapability: string = "deleteFacility";
        let disableCapability: string = "disableFacility";
        let createCapability: string = "createFacility";

        this.modifyCap = this.capabilityService.isCapabilityAssigned(modifyCapability);
        this.queryCap = this.capabilityService.isCapabilityAssigned(queryCapability);
        this.deleteCap = this.capabilityService.isCapabilityAssigned(deleteCapability);
        this.disableCap = this.capabilityService.isCapabilityAssigned(disableCapability);
        this.createCap = this.capabilityService.isCapabilityAssigned(createCapability);
    }
}