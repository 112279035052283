import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { UserVariable } from '../../util/common/user-variable';
import { ServiceUrls } from '../../util/service-urls';
import { WsResponse } from '../../util/ws-response.model';
import { WsCallback } from '../../util/ws-callback.interface';
import { WsType } from '../../util/ws-type';
import { FilesService } from '../../home/files/files.service'
import { CommonUtil } from '../common/common-util';

@Injectable()
export class DocumentTypeService {

	constructor(private http: HttpClient, private filesService: FilesService, private commonUtil: CommonUtil) {
	}

	/**
	 * Get document types. 
	 */
	getDocumentTypes(callBack: WsCallback) {
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
		const options = { params: new HttpParams().set("token", token) }
		this.http.get(ServiceUrls.DOCUMENT_TYPES_VIEW, options).
			subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.code,
					modified.status.name,
					modified.status.description,
					modified.payload);
				callBack.onSuccess(res, WsType.DOCUMENT_TYPES_VIEW);
			},
			error => {
				var modified = JSON.parse(JSON.stringify(error));
				var res = new WsResponse(
					modified.status.code,
					modified.status.name,
					modified.status.description,
					modified.payload);
				callBack.onSuccess(res, WsType.DOCUMENT_TYPES_VIEW);
			});
	}

	/**
	 * Get document type Attributes. 
	 */
	getDocumentTypeAttributes(docTypeId: String,selectedFileData: any, callBack: WsCallback) {
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
		const options = { params: new HttpParams().set("token", token) }
		let url = this.getDocumentTypeAttributesURL(docTypeId, selectedFileData);
		console.log('<><><><><><><>' + url);

		this.http.get(url, options).
			subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.code,
					modified.status.name,
					modified.status.description,
					modified.payload);
				callBack.onSuccess(res, WsType.DOCUMENT_TYPE_ATTRIBUTES_VIEW);
			},
			error => {
				var modified = JSON.parse(JSON.stringify(error));
				var res = new WsResponse(
					modified.status.code,
					modified.status.name,
					modified.status.description,
					modified.payload);
				callBack.onSuccess(res, WsType.DOCUMENT_TYPE_ATTRIBUTES_VIEW);
			});
	}


	public getDocumentTypeAttributesURL(docTypeId: String, selectedFileData: any) {
		let url = "";
		//let selectedFileData = this.filesService.getSelectedFileData();
		if (selectedFileData != null && selectedFileData != undefined) {
			let docId = docTypeId == selectedFileData.documentTypeId ? selectedFileData.id : null;
			let itemType = selectedFileData.type;
			url = ServiceUrls.DOCUMENT_TYPES_ATTRIBUTES_VIEW + docId + ':' + itemType + ':' + docTypeId + '/getDocumentTypeAttributes.json'
		}
		return url;

	}

}

