import { Component, OnInit } from '@angular/core';
import { GridOptions, ColDef, Module, AllModules } from "@ag-grid-enterprise/all-modules";
import { LoadingService } from '../util/loading/loading.service';
import { SharedService } from '../util/shared.service';
import { AlertService } from '../util/alert/alert.service';
import { Constants, UI_LAYOUT, LocalStorageConst } from '../util/constants';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { WsCallback } from '../util/ws-callback.interface';
import { WsType } from '../util/ws-type';
import { WsResponse } from '../util/ws-response.model';
import { ClientService } from './client.service';
import { ClientCellRenderComponent } from './client-cell-render-component.component';
import { CapabilityService } from '../util/capability.service';

@Component({
    selector: 'app-client',
    templateUrl: './client.component.html',
    styleUrls: ['./client.component.css']
})
export class ClientComponent implements OnInit {

    private gridApi;
    public getRowNodeId;
    private gridColumnApi
    public gridOptions: GridOptions;
    public defaultColDef: any;
    public columnDefs: any;
    rowData: any = [];
    private filteredData: Array<any> = [];
    public overlayLoadingTemplate: any;
    public overlayNoRowsTemplate: any;

    private clientName: string = "";
    private clientShortName: string = "";
    private status: string = "";
    private edit: string = "";
    private delete: string = "";
    public modules: Module[] = AllModules;

    // capabilities
    public deleteClient: boolean = false
	public disableClient: boolean = false
	public modifyClient: boolean = false
    
    // enable/disable buttons
    public enableDeleteClient: boolean = false
	public enableChangeClientStatus: boolean = false
	public enableEditClient: boolean = false

    constructor(
        private clientService: ClientService,
        private alertService: AlertService,
        private loadingService: LoadingService,
        private sharedService: SharedService,
        private translate: TranslateService,
        private router: Router, private capabilityService: CapabilityService) {
            this.enableDisableButton()
        translate.addLangs(["es", "en"]);
        translate.setDefaultLang("en");
        let browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');

        this.translate.get('CLIENT.CLIENT_NAME').subscribe((res: string) => {
            this.clientName = res;
            this.translate.get('CLIENT.SHORT_NAME').subscribe((res: string) => {
                this.clientShortName = res;
                this.translate.get('CLIENT.STATUS').subscribe((res: string) => {
                    this.status = res;
                    this.translate.get('CLIENT.EDIT').subscribe((res: string) => {
                        this.edit = res;
                        this.translate.get('CLIENT.DELETE').subscribe((res: string) => {
                            this.delete = res;
                        });
                    });
                });
            });
        });
        this.columnDefs = [
            {
                headerName: '',
                field: "dependencies",
                width: 40,
                cellStyle: { 'text-align': 'center' },
                cellRendererFramework: ClientCellRenderComponent
            },
            {
                headerName: this.clientName,
                field: "clientName",
                checkboxSelection: false,
            },
            {
                headerName: this.clientShortName,
                field: "clientShortName",
                checkboxSelection: false,
                suppressColumnsToolPanel: true
            },
            {
                headerName: this.status,
                field: "status",
                width: 85,
                cellStyle: { 'text-align': 'center' },
                hide: !this.enableChangeClientStatus,
                cellRendererFramework: ClientCellRenderComponent
            },
            {
                headerName: this.edit,
                field: "edit",
                width: 85,
                cellStyle: { 'text-align': 'center' },
                hide: !this.enableEditClient,
                cellRendererFramework: ClientCellRenderComponent
            },
            {
                headerName: this.delete,
                field: "delete",
                width: 85,
                cellStyle: { 'text-align': 'center' },
                hide: !this.enableDeleteClient,
                cellRendererFramework: ClientCellRenderComponent
            }
		];
		
		this.getRowNodeId = function (data) {
            return data.id;
		};
		
        this.gridOptions = {
            rowData: this.rowData,
            columnDefs: this.columnDefs,
            rowSelection: "single",           
            rowMultiSelectWithClick: false,
            isExternalFilterPresent: this.externalFilterPresent,
            doesExternalFilterPass: this.externalFilterPass,

        };
        this.defaultColDef = {
			resizable: true,
			sortable: true,
		};
        //custom layout templates for loading and empty data sets
        this.overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Loading data...</span>';
        this.overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">No rows to show</span>';
    }

    ngOnInit() {
        let to: any = false;
        if (to) { clearTimeout(to); }
        to = setTimeout(() => {
            // this.gridOptions.api.onFilterChanged();
            this.clientService.getClientList().subscribe(
                data => {
                    this.setClientsTable(data.payload)
                },
                error => {
                    this.alertService.clear()
                    this.alertService.error(error.statusDescription)
                });;
        }, 200);
    }


	add()
	{
		this.sharedService.setFileId("-1");
       // this.router.navigateByUrl('addeditclient');
	}

    setClientsTable(payload: any) {
        // console.log(payload);
        this.loadingService.hideLoading();
        this.filteredData = [];
        this.rowData = [];
        if (payload != null) {
            for (let i = 0; i < payload.length; i++) {
                this.filteredData.push({
					id: payload[i]["clientId"],
                    dependencies: payload[i]["hasDependencies"] == false ? "disable_dependencies:" + payload[i]["clientId"] : "dependencies:" + payload[i]["clientId"],
                    clientName: payload[i]["clientName"],
                    clientShortName: payload[i]["clientShortName"],
                    status: "status:" + payload[i]["clientId"] + ":" + payload[i]["status"],
                    edit: "edit:" + payload[i]["clientId"] + ":" + payload[i]["status"],
                    delete: "delete:" + payload[i]["clientId"] + ":" + payload[i]["status"]
                });
            }
			this.rowData = this.filteredData;
			//this.clientService.setApiGridData(this.gridApi);
            //this.gridOptions.api.sizeColumnsToFit();
            this.clientService.setRowData(this.rowData);
        } else {
            // no row data to show
        }
    }

    onGridReady(params) {
        const allColumnIds = [];
        this.columnDefs.forEach((columnDef) => {
            allColumnIds.push(<ColDef>columnDef);
        });
        this.gridColumnApi = params.columnApi;
        this.gridApi = params.api;
		//gridOptions.api.hideOverlay();
        this.clientService.setApiGridData(this.gridApi);
        params.api.sizeColumnsToFit();
        // window.addEventListener("resize", function () {
        //     setTimeout(function () {
        //         params.api.sizeColumnsToFit();
        //     });
        // });
        window.addEventListener("resize", this.onWindowResize);
    }

    private onWindowResize = () => {
   
		setTimeout(() => {
			if (this.gridApi) {
				this.gridApi.sizeColumnsToFit();
			}
		}); 
	};

    ngOnDestroy(){
               window.removeEventListener("resize", this.onWindowResize);
		this.gridApi.destroy();
    	this.gridApi = null;
    }

    externalFilterPresent() {
        return this.clientName != "";
    }

    externalFilterPass(node: any) {
        return true;
    }

    /**
     * enable/disable buttons or columns
     */
    private enableDisableButton() {
        this.checkCapability()
        this.enableDeleteClient = this.deleteClient
        this.enableChangeClientStatus = this.disableClient
        this.enableEditClient = this.modifyClient
    }

    /**
     * check the capabilities for given type
     */
    public checkCapability() {
        let deleteCapability: string = "deleteClient"
		let disableCapability: string = "disableClient"
		let modifyCapability: string = "modifyClient"

        this.deleteClient = this.capabilityService.isCapabilityAssigned(deleteCapability)
        this.disableClient = this.capabilityService.isCapabilityAssigned(disableCapability)
        this.modifyClient = this.capabilityService.isCapabilityAssigned(modifyCapability)
        
    }

}
