import { Component, Input, EventEmitter, ViewChild, ElementRef, Output } from '@angular/core';

import { SymbiosisSearchService } from './symbiosis-search.service'
import { Constants } from '../constants';
import { SharedService } from '../../util/shared.service';
import { DatePipe } from '@angular/common'
import { AlertService } from "../../util/alert/alert.service";

@Component({
    selector: 'symbiosis-search',
    templateUrl: './symbiosis-search.component.html',
    styleUrls: ['./symbiosis-search.component.css'],
    inputs: ['comp'],
    outputs: ['searchFunc', 'searchBarEnterFunc', 'showSearchOptionEE']
})
export class SymbiosisSearch {

    comp: string
    searchFunc = new EventEmitter<any>()
    searchBarEnterFunc = new EventEmitter<any>()
    showSearchOptionEE = new EventEmitter<any>()
    @ViewChild('symbiosis_search') symbiosisSearchInput: ElementRef;
    public showSearchOptions: boolean = false
    public withoutHTWSearch: boolean = false
    public searchParams: any = [];
    public searchStringArray: any = [];
    public searchWordMap: { [key: string]: string } = {};
    public keyWords: any = [];
    //Achini Randeni
    //use these variables to keep values related to the symbiosis search with tag input items
    public previousDisplayText: string = ''
    public newSearchText: string = ''
    public currentSearchStringArrayWithTagInputs: any = []

    constructor(private symbiosisSearchService: SymbiosisSearchService, private alertService: AlertService, public datepipe: DatePipe, private sharedService: SharedService,) {


    }

    ngOnInit(): void {
        //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        //Add 'implements OnInit' to the class.

        /*this.sharedService.changeModalSizeExecute().subscribe(() => {
            this.showSearchOptions = true;
        });*/

        this.searchParams = this.symbiosisSearchService.generateSearchOptionByName(this.comp);
        this.keyWords = [];
        //add keywords form Constants.SEARCH_KEY_WORDS to keyWords array
        Object.keys(Constants.SEARCH_KEY_WORDS).forEach(key => {
            let value = Constants.SEARCH_KEY_WORDS[key];
            if (this.comp == 'tag') {
                if (key.indexOf("SEARCH_TAG_TAG") !== -1) {
                    let value = Constants.SEARCH_KEY_WORDS[key];
                    this.keyWords.push(value);
                } else {
                    //do not put
                }
            } else if (this.comp == 'statement') {
                if (key.indexOf("SEARCH_STATEMENT_STM") !== -1) {
                    let value = Constants.SEARCH_KEY_WORDS[key];
                    this.keyWords.push(value);
                } else {
                    //do not put
                }
            } else if (this.comp == 'component') {
                if (key.indexOf("SEARCH_COMPONENT_COMP") !== -1) {
                    let value = Constants.SEARCH_KEY_WORDS[key];
                    this.keyWords.push(value);
                } else {
                    //do not put
                }
            } else if (this.comp == 'system') {
                if (key.indexOf("SEARCH_SYSTEM_SYS") !== -1) {
                    let value = Constants.SEARCH_KEY_WORDS[key];
                    this.keyWords.push(value);
                } else {
                    //do not put
                }
            } else if (this.comp == 'document') {
                if (key.indexOf("SEARCH_DOCUMENT_DOC") !== -1) {
                    let value = Constants.SEARCH_KEY_WORDS[key];
                    this.keyWords.push(value);
                } else {
                    //do not put
                }
            } else if (this.comp == 'facility') {
                if (key.indexOf("SEARCH_FACILITY") !== -1) {
                    let value = Constants.SEARCH_KEY_WORDS[key];
                    this.keyWords.push(value);
                } else {
                    //do not put
                }
            } else if (this.comp == 'image') {
                if (key.indexOf("SEARCH_IMAGE") !== -1) {
                    let value = Constants.SEARCH_KEY_WORDS[key];
                    this.keyWords.push(value);
                } else {
                    //do not put
                }
            } else if (this.comp == 'threeDModel') {
                if (key.indexOf("SEARCH_THREED_MODEL") !== -1) {
                    let value = Constants.SEARCH_KEY_WORDS[key];
                    this.keyWords.push(value);
                } else {
                    //do not put
                }
            } else if (this.comp == 'video') {
                if (key.indexOf("SEARCH_VIDEO") !== -1) {
                    let value = Constants.SEARCH_KEY_WORDS[key];
                    this.keyWords.push(value);
                } else {
                    //do not put
                }
            } else if (this.comp == 'header') {
                if (key.indexOf("SEARCH_HEADER_FILE") !== -1) {
                    let value = Constants.SEARCH_KEY_WORDS[key];
                    this.keyWords.push(value);
                } else {
                    //do not put
                }
            } else if (this.comp == 'punchlist') {
                if (key.indexOf("SEARCH_PUNCHLIST") !== -1) {
                    let value = Constants.SEARCH_KEY_WORDS[key];
                    this.keyWords.push(value);
                } else {
                    //do not put
                }
            }
            //Get IRN related keywords
            else if (this.comp == 'irn') {
                if (key.indexOf("SEARCH_IRN") !== -1) {
                    let value = Constants.SEARCH_KEY_WORDS[key];
                    this.keyWords.push(value);
                } else {
                    //do not put
                }
            } else if (this.comp == 'managemcc') {
                if (key.indexOf("SEARCH_MCC") !== -1) {
                    let value = Constants.SEARCH_KEY_WORDS[key];
                    this.keyWords.push(value);
                } else {
                    //do not put
                }
            } else if (this.comp == 'manageotn') {
                if (key.indexOf("SEARCH_OTN") !== -1) {
                    let value = Constants.SEARCH_KEY_WORDS[key];
                    this.keyWords.push(value);
                } else {
                    //do not put
                }
            }
            else if (this.comp == 'searchcs') {
                if (key.indexOf("SEARCH_CS") !== -1) {
                    let value = Constants.SEARCH_KEY_WORDS[key];
                    this.keyWords.push(value);
                } else {
                    //do not put
                }
            }
            else if (this.comp == 'searchchk') {
                this.withoutHTWSearch = true;
                if (key.indexOf("SEARCH_CHK") !== -1) {
                    let value = Constants.SEARCH_KEY_WORDS[key];
                    this.keyWords.push(value);
                } else {
                    //do not put
                }
            }
            else if (this.comp == 'searchasset') {
                this.withoutHTWSearch = true;
                if (key.indexOf("SEARCH_ASSET") !== -1) {
                    let value = Constants.SEARCH_KEY_WORDS[key];
                    this.keyWords.push(value);
                } else {
                    //do not put
                }
            }
            else if (this.comp == 'searchsys') {
                this.withoutHTWSearch = true;
                if (key.indexOf("SEARCH_SYS") !== -1) {
                    let value = Constants.SEARCH_KEY_WORDS[key];
                    this.keyWords.push(value);
                } else {
                    //do not put
                }
            }
            else if (this.comp == 'issuechecksheets') {
                if (key.indexOf("ISSUE_CS") !== -1) {
                    let value = Constants.SEARCH_KEY_WORDS[key];
                    this.keyWords.push(value);
                } else {
                    //do not put
                }
            }
            else if (this.comp == 'receivechecksheets') {
                if (key.indexOf("RECEIVE_CS") !== -1) {
                    let value = Constants.SEARCH_KEY_WORDS[key];
                    this.keyWords.push(value);
                } else {
                    //do not put
                }
            }
            else if (this.comp == 'managechecks') {
                if (key.indexOf("SEARCH_CHK") !== -1) {
                    let value = Constants.SEARCH_KEY_WORDS[key];
                    this.keyWords.push(value);
                } else {
                    //do not put
                }
            }
            else if (this.comp == 'managepcc') {
                if (key.indexOf("SEARCH_PCC") !== -1) {
                    let value = Constants.SEARCH_KEY_WORDS[key];
                    this.keyWords.push(value);
                } else {
                    //do not put
                }
            }
            else {
                this.keyWords.push(value);
            }
        });

        //add SEARCH_HAS_THE_WORDS
        this.keyWords.push(Constants.SEARCH_KEY_WORDS.SEARCH_HAS_THE_WORDS);
        //add include children
        this.keyWords.push(Constants.SEARCH_KEY_WORDS.SEARCH_WITH_CHILDREN);
    }

    //show more option
    moreSearch() {
        var self = this;
        console.log('[moreSearch] (symbiosisSearchInput) ' + JSON.stringify(this.symbiosisSearchInput.nativeElement.value));
        //get user typed key value pairs
        this.getKeyWordValue(this.symbiosisSearchInput.nativeElement.value);
        this.showSearchOptions = true
        //set user typed values to related search box
        this.searchParams.forEach(item => {
            if (item.key == Constants.SEARCH_KEY_WORDS.SEARCH_HEADER_FILE_LAST_UPDATE_DATE) {
                var lastupdatedate = self.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_HEADER_FILE_LAST_UPDATE_DATE];
                let converted_date = "";
                if (lastupdatedate) {
                    try {
                        //convert date
                        converted_date = this.datepipe.transform(lastupdatedate, 'MM-dd-yyyy')
                    } catch (e) {
                        converted_date = null;
                    }
                } else {
                    //no date
                }
                item.value = converted_date;
            } else if (item.key == Constants.SEARCH_KEY_WORDS.SEARCH_PUNCHLIST_DATE_RAISED) {
                var dateRaised = self.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_PUNCHLIST_DATE_RAISED];
                let converted_date = "";
                if (dateRaised) {
                    try {
                        //convert date
                        converted_date = this.datepipe.transform(dateRaised, 'MM-dd-yyyy')
                    } catch (e) {
                        converted_date = null;
                    }
                } else {
                    //no date
                }
                item.value = converted_date;
            } else if (item.key == Constants.SEARCH_KEY_WORDS.SEARCH_PUNCHLIST_DATE_CLOSED) {
                var dateClosed = self.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_PUNCHLIST_DATE_CLOSED];
                let converted_date = "";
                if (dateClosed) {
                    try {
                        //convert date
                        converted_date = this.datepipe.transform(dateClosed, 'MM-dd-yyyy')
                    } catch (e) {
                        converted_date = null;
                    }
                } else {
                    //no date
                }
                item.value = converted_date;
            } else if (item.key == Constants.SEARCH_KEY_WORDS.SEARCH_PUNCHLIST_COMPLETION_DATE) {
                var dateCompletion = self.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_PUNCHLIST_COMPLETION_DATE];
                let converted_date = "";
                if (dateCompletion) {
                    try {
                        //convert date
                        converted_date = this.datepipe.transform(dateCompletion, 'MM-dd-yyyy')
                    } catch (e) {
                        converted_date = null;
                    }
                } else {
                    //no date
                }
                item.value = converted_date;
            } else if (item.key == Constants.SEARCH_KEY_WORDS.SEARCH_SYSTEM_SYS_COMMISSIONING || item.key == Constants.SEARCH_KEY_WORDS.SEARCH_HEADER_FILE_ITEM_TYPE
                || item.key == Constants.SEARCH_KEY_WORDS.SEARCH_CS_TYPE || item.key == Constants.SEARCH_KEY_WORDS.SEARCH_CHK_TYPE) {
                if (self.searchWordMap[item.key] != undefined) {
                    item.value = [{ id: self.searchWordMap[item.key] }];
                    //                    item.value = [{id: 'yes', text: 'Used in Commissioning'}];
                } else {
                    item.value = null;//[{id: '', text: ''}];
                }
            } else {
                item.value = self.searchWordMap[item.key];
            }
        });
    }

    //get keyword value pairs
    getKeyWordValue(userInput) {
        var self = this;
        var isLast = false;
        this.searchStringArray = [];
        self.searchWordMap = {};

        if (userInput) {
            //split by :
            var rawArray = userInput.split(':');
            rawArray.forEach(function (value, index) {
                if (value) {
                    if (index == rawArray.length - 1) {
                        isLast = true;
                    } else {
                        //not last element
                    }

                    //check has keyword
                    var hasKeyWord = self.hasKeyWord(value, isLast);
                    if (hasKeyWord == false) {
                        //no keyword so add value
                        self.searchStringArray.push(value);
                    } else {
                        //already added to array
                    }
                } else {
                    //no value
                }
            });

            this.searchStringArray.forEach(function (value, index) {
                //add key value pair
                var iskeyword = self.isKeyWord(value);
                var key, valueStr;
                if (iskeyword == true) {
                    //key is the current value
                    key = value;
                    //value is the next item in the array
                    valueStr = self.searchStringArray[index + 1];
                    if (valueStr) {
                        //remove last empty string
                        var lastChar = valueStr[valueStr.length - 1];
                        if (lastChar == " ") {
                            valueStr = valueStr.substring(0, valueStr.length - 1);
                        } else {
                            //last string is not empty
                        }
                        //add to map
                        if (self.searchWordMap[key] != undefined) {
                            self.searchWordMap[key] = self.searchWordMap[key] + " " + valueStr;
                        } else {
                            self.searchWordMap[key] = valueStr;
                        }
                    } else {
                        //do nothing
                    }
                } else {
                    if (index == 0) {
                        var nextVal = self.searchStringArray[1];
                        if (nextVal) {
                            //next element should be keyword
                            if (self.isKeyWord(nextVal)) {
                                self.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_HAS_THE_WORDS] = value;
                            } else {
                                //invalid
                            }
                        } else {
                            //only element
                            self.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_HAS_THE_WORDS] = value;
                        }
                    }
                }

            });
        } else {
            //nothing entered
        }
    }

    //check has keyword
    hasKeyWord(wordString, isLast): boolean {
        var self = this;
        var hasKeyWords = false;
        var extra = "";
        self.keyWords.forEach(function (value) {
            if (wordString.indexOf(value) !== -1) {
                //check if last part is a keyword
                var lst = wordString.substr(wordString.length - value.length);
                if (lst == value) {
                    //has keyword
                    if (isLast == true) {
                        extra = wordString;
                    } else {
                        extra = wordString.replace(value, "");
                    }
                    //add value without keyword if not empty
                    if (extra) {
                        self.searchStringArray.push(extra);
                    } else {
                        //empty value (only keyword)
                    }
                    //add keyword
                    self.searchStringArray.push(value);
                    hasKeyWords = true;
                } else {
                    //incorrect keyword
                }
            } else {
                //no keyword
            }
        });
        return hasKeyWords;
    }

    //check is keyword
    isKeyWord(val): boolean {
        var self = this;
        var isKeyWord = false;
        self.keyWords.forEach(function (value) {
            if (val == value) {
                isKeyWord = true;
            }
        });
        return isKeyWord;
    }

    //close button click event
    closeSearchOptions() {
        // console.log('[closeSearchOptions]');
        this.showSearchOptions = false
        this.symbiosisSearchInput.nativeElement.value = null
        this.showSearchOptionEE.emit(this.showSearchOptions)
        if (this.comp == 'searchasset') {
            this.removeSelectedTagInputs();
        }
        else if (this.comp == 'searchsys') {
            this.removeSelectedSystemTagInputs();
        } else if(this.comp == 'issuechecksheets'){
            this.removeSelectedIssueChecksheetTagInputs();
        } else if(this.comp == 'receivechecksheets'){
            this.removeSelectedReceiveChecksheetTagInputs();
        } else if(this.comp == 'managepcc'){
            this.removeSelectedSearchPCCTagInputs();
        }
    }

    removeSelectedTagInputs() {
        this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_NO, []);
        this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_DESC, []);
        this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_SYS, []);
        this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_GROUP, []);
        this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_PARENT, []);
        this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_ATTRIBUTE, []);
        this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_FUNCCODE, []);
    }

    removeSelectedSystemTagInputs() {
        this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.SEARCH_SYS_NO, []);
        this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.SEARCH_SYS_NAME, []);
        //this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.SEARCH_SYS_PARENT, []);
    }

    removeSelectedIssueChecksheetTagInputs(){
        this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.ISSUE_CS_VENDOR, []);
        this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.ISSUE_CS_CHECKSHEET, []);
        this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.ISSUE_CS_ASSET, []);
        this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.ISSUE_CS_ASSET_DESCRIPTION, []);
        this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.ISSUE_CS_SYSTEM, []);
        this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.ISSUE_CS_ASSET_GROUP, []);
        this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.ISSUE_CS_PHASE, []);
        this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.ISSUE_CS_ASSET_PARENT, []);
        this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.ISSUE_CS_FUNCTION_CODE, []);
    }

    removeSelectedReceiveChecksheetTagInputs(){
        this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.RECEIVE_CS_VENDOR, []);
        this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.RECEIVE_CS_CHECKSHEET, []);
        this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.RECEIVE_CS_ASSET, []);
        this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.RECEIVE_CS_ASSET_DESCRIPTION, []);
        this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.RECEIVE_CS_SYSTEM, []);
        this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.RECEIVE_CS_ASSET_GROUP, []);
        this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.RECEIVE_CS_PHASE, []);
        this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.RECEIVE_CS_FUNCTION_CODE, []);
    }   
    
    removeSelectedSearchPCCTagInputs(){
        this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.SEARCH_PCC_SYSTEM, []);
        this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.SEARCH_PCC_STATUS, []);
        this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.SEARCH_PCC_COMPLETION_DATE, []);
    }

    /**
     * search button click event
     * @param data 
     */
    searchBtnClickEvent(data: any) {
        var self = this;
        var addCatChild = SharedService.getStateCheck();

        let searchTxt = ''
        let displayTxt = ''
        self.searchWordMap = {};
        if (data.length > 0) {
            searchTxt = this.generateStringFromArray(data)
            //generate display search text. This will be different from the searchTxt when having multiple inputs
            displayTxt = this.generateDisplayTextFromArray(data)
        } else {

        }

        if ((this.comp == 'statement' || this.comp == 'component') && addCatChild != undefined) {
            this.symbiosisSearchInput.nativeElement.value = searchTxt + Constants.SEARCH_KEY_WORDS.SEARCH_WITH_CHILDREN + ":" + addCatChild;
            displayTxt = searchTxt + Constants.SEARCH_KEY_WORDS.SEARCH_WITH_CHILDREN + ":" + addCatChild;
        } else {
            this.symbiosisSearchInput.nativeElement.value = searchTxt
        }

        this.getKeyWordValue(this.symbiosisSearchInput.nativeElement.value);

        //set display value to the search bar
        this.symbiosisSearchInput.nativeElement.value = displayTxt;

        //keep current displayTxt
        this.previousDisplayText = displayTxt;

        this.searchFunc.emit(
            {
                searchTxt: searchTxt,
                jsonData: data,
                searchWordMap: this.searchWordMap
            }
        )
        this.showSearchOptions = false
    }

    /**
     * search bar enter button click event
     * @param searchVal 
     */
    mainSearch(searchVal: string) {
        // console.log('[search] (searchVal) ' + JSON.stringify(searchVal));
        var self = this;
        var searchStr = "";

        //check whether the searchVal contains taginput items
        if (this.hasTagInputItems(searchVal)) {
            //set previous search text and display text
            var prevDisplayText = this.previousDisplayText;
            //get modified tag input items
            let modifiedItems = this.compareWithPreviousSearchText(searchVal, prevDisplayText)
            if (modifiedItems != '') {
                this.alertService.clear()
                this.alertService.warn("Please select the correct " + modifiedItems + " from the dropdown");
                this.showSearchOptions = true
            } else {
                //get user typed key value pairs
                this.getKeyWordValue(this.newSearchText);
                this.searchFunc.emit(
                    {
                        searchTxt: this.newSearchText,
                        searchWordMap: this.searchWordMap
                    }
                )
                this.symbiosisSearchInput.nativeElement.value = searchVal;
            }
        } else {
            //get user typed key value pairs
            this.getKeyWordValue(searchVal);
            this.searchFunc.emit(
                {
                    searchTxt: searchVal,
                    searchWordMap: this.searchWordMap
                }
            )

            //get user typed key value pairs
            for (let key in self.searchWordMap) {
                let value = self.searchWordMap[key];
                if (value) {
                    searchStr += key + ":" + value + " ";
                } else {
                    //no value
                }
            }
            //update search string
            this.symbiosisSearchInput.nativeElement.value = searchStr;
        }
    }

    /**
     * generate string from JSON
     * @param jsonData 
     */
    generateStringFromJSON(jsonData: any): string {
        let searchInputText = ''
        let JData = JSON.parse(jsonData)
        for (let key in JData) {
            searchInputText += key + ':' + JData[key] + ' '
        }
        return searchInputText
    }
    /**
     * generate string from array
     * @param data 
     */
    generateStringFromArray(data: any): string {
        let searchInputText = '';
        data.forEach(dItem => {
            if (dItem.value != undefined && dItem.value != '') {
                if (dItem.key == Constants.SEARCH_KEY_WORDS.SEARCH_HEADER_FILE_LAST_UPDATE_DATE) {
                    let converted_date = this.datepipe.transform(dItem.value, 'yyyy-MM-dd');
                    searchInputText += Constants.SEARCH_KEY_WORDS.SEARCH_HEADER_FILE_LAST_UPDATE_DATE + ':' + converted_date + ' '
                } else if (dItem.key == Constants.SEARCH_KEY_WORDS.SEARCH_PUNCHLIST_DATE_RAISED) {
                    let converted_date = this.datepipe.transform(dItem.value, 'yyyy-MM-dd');
                    searchInputText += Constants.SEARCH_KEY_WORDS.SEARCH_PUNCHLIST_DATE_RAISED + ':' + converted_date + ' '
                } else if (dItem.key == Constants.SEARCH_KEY_WORDS.SEARCH_PUNCHLIST_DATE_CLOSED) {
                    let converted_date = this.datepipe.transform(dItem.value, 'yyyy-MM-dd');
                    searchInputText += Constants.SEARCH_KEY_WORDS.SEARCH_PUNCHLIST_DATE_CLOSED + ':' + converted_date + ' '
                } else if (dItem.key == Constants.SEARCH_KEY_WORDS.SEARCH_PUNCHLIST_COMPLETION_DATE) {
                    let converted_date = this.datepipe.transform(dItem.value, 'yyyy-MM-dd');
                    searchInputText += Constants.SEARCH_KEY_WORDS.SEARCH_PUNCHLIST_COMPLETION_DATE + ':' + converted_date + ' '
                } else if (dItem.key == Constants.SEARCH_KEY_WORDS.SEARCH_SYSTEM_SYS_COMMISSIONING || dItem.key == Constants.SEARCH_KEY_WORDS.SEARCH_CS_TYPE || dItem.key == Constants.SEARCH_KEY_WORDS.SEARCH_CHK_TYPE) {
                    let selectedValue = dItem.value[0].id;
                    searchInputText += dItem.key + ':' + selectedValue + ' '
                } else if (dItem.key === Constants.SEARCH_KEY_WORDS.SEARCH_HEADER_FILE_ITEM_TYPE) {
                    if (Array.isArray(dItem.value)) {
                        searchInputText += dItem.key + ':' + dItem.value[0].id + ' '
                    } else {
                        searchInputText += dItem.key + ':' + dItem.value + ' '
                    }
                    console.log(searchInputText)
                } else if (dItem.key === Constants.SEARCH_KEY_WORDS.SEARCH_IRN_ASSET || dItem.key === Constants.SEARCH_KEY_WORDS.SEARCH_IRN_VENDOR || dItem.key === Constants.SEARCH_KEY_WORDS.SEARCH_MCC_COMPLETION_DATE || dItem.key === Constants.SEARCH_KEY_WORDS.SEARCH_MCC_STATUS || dItem.key === Constants.SEARCH_KEY_WORDS.SEARCH_MCC_SYSTEM
                    || dItem.key === Constants.SEARCH_KEY_WORDS.SEARCH_OTN_ASSET || dItem.key === Constants.SEARCH_KEY_WORDS.SEARCH_OTN_VENDOR || dItem.key === Constants.SEARCH_KEY_WORDS.SEARCH_OTN_STATUS || dItem.key === Constants.SEARCH_KEY_WORDS.SEARCH_OTN_COMPLETION_DATE ||
                    dItem.key === Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_NO || dItem.key === Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_DESC || dItem.key === Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_SYS ||
                    dItem.key === Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_GROUP || dItem.key === Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_PARENT || dItem.key === Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_FUNCCODE || dItem.key === Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_ATTRIBUTE ||
                    dItem.key === Constants.SEARCH_KEY_WORDS.SEARCH_SYS_NO ||  dItem.key === Constants.SEARCH_KEY_WORDS.SEARCH_SYS_NAME || dItem.key === Constants.SEARCH_KEY_WORDS.ISSUE_CS_VENDOR || dItem.key === Constants.SEARCH_KEY_WORDS.ISSUE_CS_CHECKSHEET ||
                    dItem.key === Constants.SEARCH_KEY_WORDS.ISSUE_CS_ASSET || dItem.key === Constants.SEARCH_KEY_WORDS.ISSUE_CS_ASSET_DESCRIPTION || dItem.key === Constants.SEARCH_KEY_WORDS.ISSUE_CS_SYSTEM || dItem.key === Constants.SEARCH_KEY_WORDS.ISSUE_CS_ASSET_GROUP ||
                    dItem.key === Constants.SEARCH_KEY_WORDS.ISSUE_CS_PHASE || dItem.key === Constants.SEARCH_KEY_WORDS.ISSUE_CS_ASSET_PARENT || dItem.key === Constants.SEARCH_KEY_WORDS.RECEIVE_CS_ASSET ||
                    dItem.key === Constants.SEARCH_KEY_WORDS.RECEIVE_CS_ASSET_DESCRIPTION || dItem.key === Constants.SEARCH_KEY_WORDS.RECEIVE_CS_ASSET_GROUP || dItem.key === Constants.SEARCH_KEY_WORDS.RECEIVE_CS_CHECKSHEET ||
                    dItem.key === Constants.SEARCH_KEY_WORDS.RECEIVE_CS_PHASE || dItem.key === Constants.SEARCH_KEY_WORDS.RECEIVE_CS_SYSTEM || dItem.key === Constants.SEARCH_KEY_WORDS.RECEIVE_CS_VENDOR || dItem.key === Constants.SEARCH_KEY_WORDS.SEARCH_IRN_STATUS ||
                    dItem.key === Constants.SEARCH_KEY_WORDS.SEARCH_IRN_COMPLETION_DATE || dItem.key === Constants.SEARCH_KEY_WORDS.SEARCH_PCC_SYSTEM || dItem.key === Constants.SEARCH_KEY_WORDS.SEARCH_PCC_STATUS || dItem.key === Constants.SEARCH_KEY_WORDS.SEARCH_PCC_COMPLETION_DATE) {
                    if (Array.isArray(dItem.value)) {
                        let txt = "";
                        var itemList = [];
                        itemList = dItem.value;
                        if (dItem.value.length > 0) {
                            let len = dItem.value.length;
                            itemList.forEach((item, id) => {
                                if (item.value != '') {
                                    if (id == len - 1) {
                                        txt = txt + item.value
                                    } else {
                                        txt = txt + item.value + ","
                                    }
                                } else if (item.display != '') {
                                    if (id == len - 1) {
                                        txt = txt + item.display
                                    } else {
                                        txt = txt + item.display + ","
                                    }
                                } else {

                                }
                            })
                        }
                        searchInputText += dItem.key + ':' + txt + ' '
                    } else {
                        searchInputText += dItem.key + ':' + dItem.value + ' '
                    }
                } else if(dItem.key === Constants.SEARCH_KEY_WORDS.ISSUE_CS_FUNCTION_CODE || dItem.key === Constants.SEARCH_KEY_WORDS.RECEIVE_CS_FUNCTION_CODE){
                    if (Array.isArray(dItem.value)) {
                        let displaytxt = "";
                        var itemList = [];
                        itemList = dItem.value;
                        if (dItem.value.length > 0) {
                            let len = dItem.value.length;
                            itemList.forEach((item, id) => {
                                if (item.display != '') {
                                    if (id == len - 1) {
                                        displaytxt = displaytxt + item.display
                                    } else {
                                        displaytxt = displaytxt + item.display + ","
                                    }
                                } else {

                                }
                            })
                        }
                        searchInputText += dItem.key + ':' + displaytxt + ' '
                    } else {
                        searchInputText += dItem.key + ':' + dItem.value + ' '
                    }
                } else {
                    searchInputText += dItem.key + ':' + dItem.value + ' '
                }
            } else {

            }
        });
        return searchInputText
    }

    /**
     * Achini Randeni
     * Generate search text to display in the search bar
     * This method is introduced to avoid display primary key in the search bar for taginput fields
     * @param data 
     */
    generateDisplayTextFromArray(data: any): string {
        let searchDisplayText = ''
        data.forEach(dItem => {
            if (dItem.value != undefined && dItem.value != '') {
                if (dItem.key == Constants.SEARCH_KEY_WORDS.SEARCH_HEADER_FILE_LAST_UPDATE_DATE) {
                    let converted_date = this.datepipe.transform(dItem.value, 'yyyy-MM-dd');
                    searchDisplayText += Constants.SEARCH_KEY_WORDS.SEARCH_HEADER_FILE_LAST_UPDATE_DATE + ':' + converted_date + ' '
                } else if (dItem.key == Constants.SEARCH_KEY_WORDS.SEARCH_PUNCHLIST_DATE_RAISED) {
                    let converted_date = this.datepipe.transform(dItem.value, 'yyyy-MM-dd');
                    searchDisplayText += Constants.SEARCH_KEY_WORDS.SEARCH_PUNCHLIST_DATE_RAISED + ':' + converted_date + ' '
                } else if (dItem.key == Constants.SEARCH_KEY_WORDS.SEARCH_PUNCHLIST_DATE_CLOSED) {
                    let converted_date = this.datepipe.transform(dItem.value, 'yyyy-MM-dd');
                    searchDisplayText += Constants.SEARCH_KEY_WORDS.SEARCH_PUNCHLIST_DATE_CLOSED + ':' + converted_date + ' '
                } else if (dItem.key == Constants.SEARCH_KEY_WORDS.SEARCH_PUNCHLIST_COMPLETION_DATE) {
                    let converted_date = this.datepipe.transform(dItem.value, 'yyyy-MM-dd');
                    searchDisplayText += Constants.SEARCH_KEY_WORDS.SEARCH_PUNCHLIST_COMPLETION_DATE + ':' + converted_date + ' '
                } else if (dItem.key == Constants.SEARCH_KEY_WORDS.SEARCH_SYSTEM_SYS_COMMISSIONING || dItem.key == Constants.SEARCH_KEY_WORDS.SEARCH_CS_TYPE || dItem.key == Constants.SEARCH_KEY_WORDS.SEARCH_CHK_TYPE) {
                    let selectedValue = dItem.value[0].id;
                    searchDisplayText += dItem.key + ':' + selectedValue + ' '
                } else if (dItem.key === Constants.SEARCH_KEY_WORDS.SEARCH_HEADER_FILE_ITEM_TYPE) {
                    if (Array.isArray(dItem.value)) {
                        searchDisplayText += dItem.key + ':' + dItem.value[0].id + ' '
                    } else {
                        searchDisplayText += dItem.key + ':' + dItem.value + ' '
                    }
                    console.log(searchDisplayText)
                } else if (dItem.key === Constants.SEARCH_KEY_WORDS.SEARCH_IRN_ASSET || dItem.key === Constants.SEARCH_KEY_WORDS.SEARCH_IRN_VENDOR || dItem.key === Constants.SEARCH_KEY_WORDS.SEARCH_MCC_COMPLETION_DATE || dItem.key === Constants.SEARCH_KEY_WORDS.SEARCH_MCC_STATUS || dItem.key === Constants.SEARCH_KEY_WORDS.SEARCH_MCC_SYSTEM
                    || dItem.key === Constants.SEARCH_KEY_WORDS.SEARCH_OTN_ASSET || dItem.key === Constants.SEARCH_KEY_WORDS.SEARCH_OTN_VENDOR || dItem.key === Constants.SEARCH_KEY_WORDS.SEARCH_OTN_STATUS || dItem.key === Constants.SEARCH_KEY_WORDS.SEARCH_OTN_COMPLETION_DATE
                    || dItem.key === Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_NO || dItem.key === Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_DESC || dItem.key === Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_SYS ||
                    dItem.key === Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_GROUP || dItem.key === Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_PARENT || dItem.key === Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_FUNCCODE || dItem.key === Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_ATTRIBUTE ||
                    dItem.key === Constants.SEARCH_KEY_WORDS.SEARCH_SYS_NO ||  dItem.key === Constants.SEARCH_KEY_WORDS.SEARCH_SYS_NAME || dItem.key === Constants.SEARCH_KEY_WORDS.ISSUE_CS_VENDOR || dItem.key === Constants.SEARCH_KEY_WORDS.ISSUE_CS_CHECKSHEET ||
                    dItem.key === Constants.SEARCH_KEY_WORDS.ISSUE_CS_ASSET || dItem.key === Constants.SEARCH_KEY_WORDS.ISSUE_CS_ASSET_DESCRIPTION || dItem.key === Constants.SEARCH_KEY_WORDS.ISSUE_CS_SYSTEM || dItem.key === Constants.SEARCH_KEY_WORDS.ISSUE_CS_ASSET_GROUP ||
                    dItem.key === Constants.SEARCH_KEY_WORDS.ISSUE_CS_PHASE || dItem.key === Constants.SEARCH_KEY_WORDS.ISSUE_CS_ASSET_PARENT || dItem.key === Constants.SEARCH_KEY_WORDS.ISSUE_CS_FUNCTION_CODE || dItem.key === Constants.SEARCH_KEY_WORDS.RECEIVE_CS_ASSET ||
                    dItem.key === Constants.SEARCH_KEY_WORDS.RECEIVE_CS_ASSET_DESCRIPTION || dItem.key === Constants.SEARCH_KEY_WORDS.RECEIVE_CS_ASSET_GROUP || dItem.key === Constants.SEARCH_KEY_WORDS.RECEIVE_CS_CHECKSHEET || dItem.key === Constants.SEARCH_KEY_WORDS.RECEIVE_CS_FUNCTION_CODE || 
                    dItem.key === Constants.SEARCH_KEY_WORDS.RECEIVE_CS_PHASE || dItem.key === Constants.SEARCH_KEY_WORDS.RECEIVE_CS_SYSTEM || dItem.key === Constants.SEARCH_KEY_WORDS.RECEIVE_CS_VENDOR || dItem.key === Constants.SEARCH_KEY_WORDS.SEARCH_IRN_STATUS ||
                    dItem.key === Constants.SEARCH_KEY_WORDS.SEARCH_IRN_COMPLETION_DATE || dItem.key === Constants.SEARCH_KEY_WORDS.SEARCH_PCC_SYSTEM || dItem.key === Constants.SEARCH_KEY_WORDS.SEARCH_PCC_STATUS || dItem.key === Constants.SEARCH_KEY_WORDS.SEARCH_PCC_COMPLETION_DATE) {
                    if (Array.isArray(dItem.value)) {
                        //this.previousTagInputItems.push(dItem)
                        let displaytxt = "";
                        var itemList = [];
                        itemList = dItem.value;
                        if (dItem.value.length > 0) {
                            let len = dItem.value.length;
                            itemList.forEach((item, id) => {
                                if (item.display != '') {
                                    if (id == len - 1) {
                                        displaytxt = displaytxt + item.display
                                    } else {
                                        displaytxt = displaytxt + item.display + ","
                                    }
                                } else {

                                }
                            })
                        }
                        searchDisplayText += dItem.key + ':' + displaytxt + ' '
                    } else {
                        searchDisplayText += dItem.key + ':' + dItem.value + ' '
                    }
                } else {
                    searchDisplayText += dItem.key + ':' + dItem.value + ' '
                }
            } else {

            }
        })
        return searchDisplayText;
    }

    /**
     * Achini Randeni
     * Check the currentSearchText has tag input items
     * @param currentSearchText 
     */
    private hasTagInputItems(currentSearchText: string) {
        var self = this;
        var key: string = ''
        var values: string = ''
        var hasTagInputs: boolean = false
        this.currentSearchStringArrayWithTagInputs = []

        //get key value pairs from the currentSearchText
        this.getKeyWordValue(currentSearchText);
        this.searchStringArray.forEach(function (value, index) {
            if (self.isKeyWord(value)) {
                key = value
            } else {
                values = value
            }
            if (key != '' && values != '') {
                //check whether the key is a tag input item or not
                if (key === Constants.SEARCH_KEY_WORDS.SEARCH_IRN_ASSET || key === Constants.SEARCH_KEY_WORDS.SEARCH_IRN_VENDOR || key === Constants.SEARCH_KEY_WORDS.SEARCH_MCC_COMPLETION_DATE || key === Constants.SEARCH_KEY_WORDS.SEARCH_MCC_STATUS || key === Constants.SEARCH_KEY_WORDS.SEARCH_MCC_SYSTEM
                    || key === Constants.SEARCH_KEY_WORDS.SEARCH_OTN_ASSET || key === Constants.SEARCH_KEY_WORDS.SEARCH_OTN_VENDOR || key === Constants.SEARCH_KEY_WORDS.SEARCH_OTN_STATUS || key === Constants.SEARCH_KEY_WORDS.SEARCH_OTN_COMPLETION_DATE
                    || key === Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_NO || key === Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_DESC || key === Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_SYS ||
                    key === Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_GROUP || key === Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_PARENT || key === Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_FUNCCODE || key === Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_ATTRIBUTE ||
                    key === Constants.SEARCH_KEY_WORDS.SEARCH_SYS_NO ||  key === Constants.SEARCH_KEY_WORDS.SEARCH_SYS_NAME || key === Constants.SEARCH_KEY_WORDS.ISSUE_CS_VENDOR || key === Constants.SEARCH_KEY_WORDS.ISSUE_CS_CHECKSHEET ||
                    key === Constants.SEARCH_KEY_WORDS.ISSUE_CS_ASSET || key === Constants.SEARCH_KEY_WORDS.ISSUE_CS_ASSET_DESCRIPTION || key === Constants.SEARCH_KEY_WORDS.ISSUE_CS_SYSTEM || key === Constants.SEARCH_KEY_WORDS.ISSUE_CS_ASSET_GROUP ||
                    key === Constants.SEARCH_KEY_WORDS.ISSUE_CS_PHASE || key === Constants.SEARCH_KEY_WORDS.ISSUE_CS_ASSET_PARENT || key === Constants.SEARCH_KEY_WORDS.ISSUE_CS_FUNCTION_CODE || key === Constants.SEARCH_KEY_WORDS.RECEIVE_CS_ASSET ||
                    key === Constants.SEARCH_KEY_WORDS.RECEIVE_CS_ASSET_DESCRIPTION || key === Constants.SEARCH_KEY_WORDS.RECEIVE_CS_ASSET_GROUP || key === Constants.SEARCH_KEY_WORDS.RECEIVE_CS_CHECKSHEET || key === Constants.SEARCH_KEY_WORDS.RECEIVE_CS_FUNCTION_CODE || 
                    key === Constants.SEARCH_KEY_WORDS.RECEIVE_CS_PHASE || key === Constants.SEARCH_KEY_WORDS.RECEIVE_CS_SYSTEM || key === Constants.SEARCH_KEY_WORDS.RECEIVE_CS_VENDOR || key === Constants.SEARCH_KEY_WORDS.SEARCH_IRN_STATUS || 
                    key === Constants.SEARCH_KEY_WORDS.SEARCH_IRN_COMPLETION_DATE || key === Constants.SEARCH_KEY_WORDS.SEARCH_PCC_SYSTEM || key === Constants.SEARCH_KEY_WORDS.SEARCH_PCC_STATUS || key === Constants.SEARCH_KEY_WORDS.SEARCH_PCC_COMPLETION_DATE) {
                    self.currentSearchStringArrayWithTagInputs.push({ 'key': key, 'value': values })
                    hasTagInputs = true
                }
                key = ''
                values = ''
            } else {

            }
        })

        //if the tag input item has been removed from the new serach text 
        //then it should be removed from the tagInputList in the shared service as well
        var tagInputList = self.sharedService.getTagInputList()
        tagInputList.forEach(function (item, index) {
            var filteredTagInput = self.currentSearchStringArrayWithTagInputs.filter(i => i.key == item.key);
            if (filteredTagInput.length == 0) {
                self.sharedService.removeTagInput(index)
            }
        })

        return hasTagInputs;
    }

    /**
      * Achini Randeni
      * Find the modified taginput items
      * @param currentSearchText 
      * @param previousSearchText 
      */
    private compareWithPreviousSearchText(currentSearchText: string, previousSearchText: string): string {
        var self = this;
        var modifiedItems: string = '';
        var key: string = ''
        var values: string = ''
        var currentSearchTextStringArray = [];
        var previousSearchTextStringArray = [];
        var newSearchStr: string = ''

        //get key value pairs from the currentSearchText
        this.getKeyWordValue(currentSearchText);
        this.searchStringArray.forEach(function (value, index) {
            if (self.isKeyWord(value)) {
                key = value
            } else {
                values = value
            }
            if (key != '' && values != '') {
                currentSearchTextStringArray.push({ 'key': key, 'value': values })
                key = ''
                values = ''
            } else {

            }
        })

        //get key value pairs from the previousSearchText
        this.getKeyWordValue(previousSearchText);
        key = ''
        values = ''
        this.searchStringArray.forEach(function (value, index) {
            if (self.isKeyWord(value)) {
                key = value
            } else {
                values = value
            }
            if (key != '' && values != '') {
                previousSearchTextStringArray.push({ 'key': key, 'value': values })
                key = ''
                values = ''
            } else {

            }
        })

        //identifying the modified fields
        let len = currentSearchTextStringArray.length;
        currentSearchTextStringArray.forEach(function (item1, index1) {
            previousSearchTextStringArray.forEach(function (item2, index2) {
                if (item1.key == item2.key) {
                    //get key value pair from the selected taginput items
                    var value = self.sharedService.getTagInputList().filter(item => item.key == item1.key);
                    //check whether the value is an array or not
                    if (value.length > 0 && Array.isArray(value[0].value)) {
                        if (item1.value != item2.value) {
                            if (index1 != len - 1) {
                                modifiedItems = modifiedItems + item1.key + ','
                            } else {
                                modifiedItems = modifiedItems + item1.key
                            }
                        } else {
                            newSearchStr += self.generateStringFromArray(value)
                        }
                    } else {
                        newSearchStr += item1.key + ":" + item1.value + " "
                    }
                } else {

                }
            })
        });
        this.newSearchText = newSearchStr
        return modifiedItems;
    }
}
