import { Component, OnInit, Input, ViewEncapsulation, ViewChild, Output, EventEmitter } from '@angular/core';
import { SelectComponent, SelectItem } from 'ng2-select';

@Component({
  selector: 'app-attribute-select',
  templateUrl: './attribute-select.component.html',
  styleUrls: ['./attribute-select.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AttributeSelectComponent implements OnInit {
  //Outputs
  @Input() public viewItems: Array<any> = [];
  //This is to show already selected items (edit)
  @Input() public selectedItem: any = [];
  @Input() public id: any = null;
  @Input() public disabled: boolean;
  //This is to pass selected items (add)
  @Output() emitSelected = new EventEmitter();

  //Elements
  //@ViewChild('attribute_component_select') mySelectComponent: SelectComponent;

  constructor() { }

  ngOnInit() {
    //Showing currently selected items
    var valueId;
    const attributeId = this.id.split("_")[1];
    //console.log(attributeId);
    this.selectedItem.forEach(item => {
      if (item.id.split("_")[0] == attributeId) {
        valueId = item.id.split("_")[1];
      }
    });
    //Find the selected item  and set
    this.viewItems.forEach(item => {
      if (valueId == item.id) {
        this.selectedItem = [];
        this.selectedItem[0] = item;
      }
    });
  }

  public selected(item) {
    this.selectedItem[0] = item;
    this.emitSelected.emit(item);
  }
}
