import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ServiceUrls } from '../util/service-urls';
import { WsCallback } from '../util/ws-callback.interface';
import { WsResponse } from '../util/ws-response.model';
import { WsType } from '../util/ws-type';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import {  HttpParams } from '@angular/common/http';
import { UserVariable } from '../util/common/user-variable';
import { CommonUtil } from '../util/common/common-util';

@Injectable({ providedIn: "root" })
export class ICECourseService {
    constructor(private http:HttpClient, private commonUtil: CommonUtil){}

    public createICECourse(iceCourseCreateRequest: string, token:string, callBack: WsCallback) {
        this.http.post(ServiceUrls.createICECourse(token), iceCourseCreateRequest).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.CREATE_ICE_COURSE);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.CREATE_ICE_COURSE);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.CREATE_ICE_COURSE);
                }
            }
        );
    }

    public updateICECourse(iceCourseCreateRequest: string, token:string, callBack: WsCallback) {
        this.http.post(ServiceUrls.updateICECourse(token), iceCourseCreateRequest).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.UPDATE_ICE_COURSE);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.UPDATE_ICE_COURSE);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.UPDATE_ICE_COURSE);
                }
            }
        );
    }

    public deleteICECourse(iceCourse: string, iceCourseId: string, token:string, callBack: WsCallback) {
        this.http.post(ServiceUrls.deleteICECourse(token, iceCourseId), iceCourse).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.DELETE_ICE_COURSE);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.DELETE_ICE_COURSE);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.DELETE_ICE_COURSE);
                }
            }
        );
    }

    public getICECourse(courseId: string, token:string, callBack: WsCallback) {
        this.http.get(ServiceUrls.getICECourse(token, courseId)).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.GET_ICE_COURSE);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.GET_ICE_COURSE);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.GET_ICE_COURSE);
                }
            }
        );
    }

    public getICECourseDataPreview(courseId: string, token:string, callBack: WsCallback) {
        this.http.get(ServiceUrls.getICECourseDataPreview(token, courseId)).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.GET_ICE_COURSE_PREVIEW_DATA);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.GET_ICE_COURSE_PREVIEW_DATA);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.GET_ICE_COURSE_PREVIEW_DATA);
                }
            }
        );
    }

    public getICECourseDataPreviewDetails(courseId: string, itemType:string, refID:string, token:string, callBack: WsCallback) {
        this.http.get(ServiceUrls.getICECourseDataPreviewDetails(token, courseId, itemType, refID)).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.GET_ICE_COURSE_PREVIEW_CONTENT);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.GET_ICE_COURSE_PREVIEW_CONTENT);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.GET_ICE_COURSE_PREVIEW_CONTENT);
                }
            }
        );
    }

    public getSelectedImage(imageId: string, token:string, callBack: WsCallback) {
        this.http.get(ServiceUrls.getSelectedImage(token, imageId)).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.GET_SELECTED_IMAGE_DATA);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.GET_SELECTED_IMAGE_DATA);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.GET_SELECTED_IMAGE_DATA);
                }
            }
        );
    }

    public getAllICETopicListOfICECourse(courseId: string, token:string, callBack: WsCallback) {
        this.http.get(ServiceUrls.getAllICETopicListOfICECourse(token, courseId)).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.GET_ICE_COURSE_TOPICS);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.GET_ICE_COURSE_TOPICS);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.GET_ICE_COURSE_TOPICS);
                }
            }
        );
    }

    public getAllICETopicContentListOfICECourse(courseId: string, token:string, callBack: WsCallback) {
        this.http.get(ServiceUrls.getAllICETopicContentListOfICECourse(token, courseId)).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.GET_ICE_COURSE_TOPIC_CONTENTS);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.GET_ICE_COURSE_TOPIC_CONTENTS);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.GET_ICE_COURSE_TOPIC_CONTENTS);
                }
            }
        );
    }

    public createICETopic(iceTopicCreateRequest: string, token:string, callBack: WsCallback) {
        this.http.post(ServiceUrls.createICETopic(token), iceTopicCreateRequest).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.CREATE_ICE_TOPIC);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.CREATE_ICE_TOPIC);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.CREATE_ICE_TOPIC);
                }
            }
        );
    }

    public updateICETopic(iceTopicCreateRequest: string, token:string, callBack: WsCallback) {
        this.http.post(ServiceUrls.updateICETopic(token), iceTopicCreateRequest).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.UPDATE_ICE_TOPIC);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.UPDATE_ICE_TOPIC);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.UPDATE_ICE_TOPIC);
                }
            }
        );
    }

    public reorderICETopic(iceTopicChangeRequest: string, token:string, callBack: WsCallback) {
        this.http.post(ServiceUrls.reorderICETopic(token), iceTopicChangeRequest).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.REORDER_ICE_TOPIC);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.REORDER_ICE_TOPIC);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.REORDER_ICE_TOPIC);
                }
            }
        );
    }

    public getICETopic(topicId: string, token:string, callBack: WsCallback) {
        this.http.get(ServiceUrls.getICETopic(token, topicId)).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.GET_ICE_TOPIC);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.GET_ICE_TOPIC);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.GET_ICE_TOPIC);
                }
            }
        );
    }

    public viewDependenciesForTopic(topicId: string, token:string, callBack: WsCallback) {
        this.http.get(ServiceUrls.viewDependenciesForTopic(token, topicId)).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.CHECK_ICE_TOPIC_DEPENDENCY);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.CHECK_ICE_TOPIC_DEPENDENCY);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.CHECK_ICE_TOPIC_DEPENDENCY);
                }
            }
        );
    }

    public deleteICETopic(iceTopic: string, iceTopicId: string, token:string, callBack: WsCallback) {
        this.http.post(ServiceUrls.deleteICETopic(token, iceTopicId), iceTopic).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.DELETE_ICE_TOPIC);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.DELETE_ICE_TOPIC);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.DELETE_ICE_TOPIC);
                }
            }
        );
    }

    public createICETopicContent(iceTopicContentCreateRequest: string, token:string, callBack: WsCallback) {
        this.http.post(ServiceUrls.createICETopicContent(token), iceTopicContentCreateRequest).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.CREATE_ICE_TOPIC_CONTENT);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.CREATE_ICE_TOPIC_CONTENT);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.CREATE_ICE_TOPIC_CONTENT);
                }
            }
        );
    }

    public updateICETopicContent(iceTopicContentUpdateRequest: string, token:string, callBack: WsCallback) {
        this.http.post(ServiceUrls.updateICETopicContent(token), iceTopicContentUpdateRequest).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.UPDATE_ICE_TOPIC_CONTENT);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.UPDATE_ICE_TOPIC_CONTENT);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.UPDATE_ICE_TOPIC_CONTENT);
                }
            }
        );
    }

    public getICETopicContent(topicContentId: string, token:string, callBack: WsCallback) {
        this.http.get(ServiceUrls.getICETopicContent(token, topicContentId)).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                console.log("model====  " + JSON.stringify(data))
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.GET_ICE_TOPIC_CONTENT);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.GET_ICE_TOPIC_CONTENT);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.GET_ICE_TOPIC_CONTENT);
                }
            }
        );
    }

    public deleteICETopicContent(iceTopicContentUpdateRequest: string, topicContentId: string,token:string, callBack: WsCallback) {
        this.http.post(ServiceUrls.deleteICETopicContent(token,topicContentId),iceTopicContentUpdateRequest).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.DELETE_ICE_TOPIC_CONTENT);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.DELETE_ICE_TOPIC_CONTENT);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.DELETE_ICE_TOPIC_CONTENT);
                }
            }
        );
    }

    public getAllICETopicsByCourseId(courseId: string, token:string, callBack: WsCallback) {
        this.http.get(ServiceUrls.getAllICETopicsByCourseId(token, courseId)).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.GET_ALL_ICE_TOPICS);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.GET_ALL_ICE_TOPICS);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.GET_ALL_ICE_TOPICS);
                }
            }
        );
    }

    public getAllICELearningCheckByCourseId(courseId: string, token:string, callBack: WsCallback) {
        this.http.get(ServiceUrls.getAllICELearningCheckByCourseId(token, courseId)).subscribe(  
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.GET_ALL_ICE_LEARNINGCHECKS);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.GET_ALL_ICE_LEARNINGCHECKS);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.GET_ALL_ICE_LEARNINGCHECKS);
                }
            }
        );
    }

    public getAllICELearningCheckByTopicId(topicId: string, token:string, callBack: WsCallback) {
        this.http.get(ServiceUrls.getAllICELearningCheckByTopicId(token, topicId)).subscribe(  
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.GET_ICE_LEARNINGCHECKS);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.GET_ICE_LEARNINGCHECKS);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.GET_ICE_LEARNINGCHECKS);
                }
            }
        );
    }

    public getAllICELearningCheckByTopicContentId(topicContentId: string, token:string, callBack: WsCallback) {
        this.http.get(ServiceUrls.getAllICELearningCheckByTopicContentId(token, topicContentId)).subscribe(  
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.GET_ICE_LEARNINGCHECKS);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.GET_ICE_LEARNINGCHECKS);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.GET_ICE_LEARNINGCHECKS);
                }
            }
        );
    }

    public getAllICETopicsByTopicId(topicParentId: string, token:string, callBack: WsCallback) {
        this.http.get(ServiceUrls.getAllICETopicsByTopicId(token, topicParentId)).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.GET_ALL_ICE_TOPICS);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.GET_ALL_ICE_TOPICS);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.GET_ALL_ICE_TOPICS);
                }
            }
        );
    }

    public getICETopicContentListByICETopicId(topicId: string, token:string, callBack: WsCallback) {
        this.http.get(ServiceUrls.getICETopicContentListByICETopicId(token, topicId)).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.GET_ICE_TOPIC_CONTENT);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.GET_ICE_TOPIC_CONTENT);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.GET_ICE_TOPIC_CONTENT);
                }
            }
        );
    }

    public reorderICETopicContent(iceTopicContentUpdateRequest: string, token:string, callBack: WsCallback) {
        this.http.post(ServiceUrls.reorderICETopicContent(token), iceTopicContentUpdateRequest).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.REORDER_ICE_TOPIC_CONTENT);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.REORDER_ICE_TOPIC_CONTENT);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.REORDER_ICE_TOPIC_CONTENT);
                }
            }
        );
    }

    public saveTopicList(iceTopicsSaveRequest: string, token:string, callBack: WsCallback) {
        this.http.post(ServiceUrls.saveICETopicList(token), iceTopicsSaveRequest).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.SAVE_ICE_TOPICS);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.SAVE_ICE_TOPICS);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.SAVE_ICE_TOPICS);
                }
            }
        );
    }

    public saveTopicContentsList(iceTopicContentssSaveRequest: string, token:string, callBack: WsCallback) {
        this.http.post(ServiceUrls.saveICETopicContentList(token), iceTopicContentssSaveRequest).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.SAVE_ICE_TOPICCONTENTS);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.SAVE_ICE_TOPICCONTENTS);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.SAVE_ICE_TOPICCONTENTS);
                }
            }
        );
    }

    public checkSimilarFileName(iceCourseFileNameRequest: string, token:string, callBack: WsCallback) {
        this.http.post(ServiceUrls.checkFileName(token), iceCourseFileNameRequest).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.CHECK_ICE_COURSE_FILENAME);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.CHECK_ICE_COURSE_FILENAME);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.CHECK_ICE_COURSE_FILENAME);
                }
            }
        );
    }

    
    public createLearningCheck(iceLearningCheckCreateRequest: string, token:string, callBack: WsCallback) {
        console.log("call url");
        this.http.post(ServiceUrls.createICELearningCheck(token), iceLearningCheckCreateRequest).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.CREATE_LEARNING_CHECK); 
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.CREATE_LEARNING_CHECK);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.CREATE_LEARNING_CHECK);
                }
            }
        );
    }
    public saveLearningCheckList(iceLearningCheckSaveRequest: string, token:string, callBack: WsCallback){
        console.log("call url");
        this.http.post(ServiceUrls.saveLearningCheckList(token), iceLearningCheckSaveRequest).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.SAVE_ICE_LEARNING_CHECKS); 
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.SAVE_ICE_LEARNING_CHECKS);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.SAVE_ICE_LEARNING_CHECKS);
                }
            }
        );
    }

    public reorderICELearningChecks(iceLCChangeRequest: string, token:string, callBack: WsCallback) {
        this.http.post(ServiceUrls.reorderICELearningChecks(token), iceLCChangeRequest).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.REORDER_ICE_LEARNING_CHECK);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.REORDER_ICE_LEARNING_CHECK);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.REORDER_ICE_LEARNING_CHECK);
                }
            }
        );
    }

    public viewDependenciesForLearningCheck(learningCheckId: string, token:string, callBack: WsCallback) {
        this.http.get(ServiceUrls.viewDependenciesForLearningCheck(token, learningCheckId)).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.CHECK_ICE_LEARNINGCHECKS_DEPENDENCY);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.CHECK_ICE_LEARNINGCHECKS_DEPENDENCY);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.CHECK_ICE_LEARNINGCHECKS_DEPENDENCY);
                }
            }
        );
    }

    public deleteICELearningCheck(IceLearningCheck: string, learningCheckId: string, token:string, callBack: WsCallback) {
        this.http.post(ServiceUrls.deleteICELearningChecks(token, learningCheckId), IceLearningCheck).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.DELETE_ICE_LEARNING_CHECK);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.DELETE_ICE_LEARNING_CHECK);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.DELETE_ICE_LEARNING_CHECK);
                }
            }
        );
    }

    public saveLearningCheckQuestionList(LearningCheckQuestionSaveRequest: string,  learningCheckId: string, token:string, callBack: WsCallback){
        this.http.post(ServiceUrls.saveLearningCheckQuestionList(token, learningCheckId), LearningCheckQuestionSaveRequest).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.SAVE_ICE_LEARNING_CHECKDATA); 
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.SAVE_ICE_LEARNING_CHECKDATA);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.SAVE_ICE_LEARNING_CHECKDATA);
                }
            }
        );
    }

    public getLearningCheckDataByLCId(learningCheckId: string, token:string, callBack: WsCallback) {
        this.http.get(ServiceUrls.getLearningCheckDataByLCId(token, learningCheckId)).subscribe(  
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.GET_ICE_LEARNING_CHECKDATA);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.GET_ICE_LEARNING_CHECKDATA);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.GET_ICE_LEARNING_CHECKDATA);
                }
            }
        );
    }


    public getCategoryList(catType: string): Observable<WsResponse> {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        let url = ServiceUrls.getCategoryListByCategoryType(catType)
        const options = { params: new HttpParams().set("token", token).set("categoryType", catType) }	
        return this.http.get(url, options).pipe(
            map(response => {
                var modified = JSON.parse(JSON.stringify(response));
                return new WsResponse(
                    modified.status.code,
                    modified.status.name,
                    modified.status.description,
                    modified.payload);
            }),
            catchError(error => {
                var modified = JSON.parse(JSON.stringify(error.error));
                var res = new WsResponse(
                    modified.status.code,
                    modified.status.name,
                    modified.status.description,
                    modified.payload);
                return throwError(res)
            })
        );
    }

    public getAllActiveICECourses(id: string, token:string, callBack: WsCallback){
        this.http.get(ServiceUrls.getAllActiveICECourses(token, id)).subscribe(  
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.GET_ALL_ACTIVE_ICE_COURSES);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.GET_ALL_ACTIVE_ICE_COURSES);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.GET_ALL_ACTIVE_ICE_COURSES);
                }
            }
        );
    }

    // Get ICE Glossary by Course Id
    public getAllICEGlossaryListByCourseId(courseId: string, token:string, callBack: WsCallback) {
        this.http.get(ServiceUrls.getAllICEGlossaryListByCourseId(token, courseId)).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.GET_ALL_ICE_GLOSSARY_LIST);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.GET_ALL_ICE_GLOSSARY_LIST);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.GET_ALL_ICE_GLOSSARY_LIST);
                }
            }
        );
    }

    // Add new ICE Game
    public createICEGame(iceGameCreateRequest: string, token:string, callBack: WsCallback) {
        this.http.post(ServiceUrls.createICEGame(token), iceGameCreateRequest).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.CREATE_ICE_GAME);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.CREATE_ICE_GAME);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.CREATE_ICE_GAME);
                }
            }
        );
    }

    // Get ICE Games list by Course Id
    public getICEGameInfoByCourseId(courseId: string, token:string, callBack: WsCallback) {
        this.http.get(ServiceUrls.getICEGameInfoByCourseId(token, courseId)).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.GET_ALL_ICE_GAME_LIST);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.GET_ALL_ICE_GAME_LIST);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.GET_ALL_ICE_GAME_LIST);
                }
            }
        );
    }

    // Get ICE Games list by Course Id
    public getICEGameInfoByTopicId(topicId: string, token:string, callBack: WsCallback) {
        this.http.get(ServiceUrls.getICEGameInfoByTopicId(token, topicId)).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.GET_ALL_ICE_GAME_LIST);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.GET_ALL_ICE_GAME_LIST);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.GET_ALL_ICE_GAME_LIST);
                }
            }
        );
    }

    public getAllICEGameInfoById(iceGameId: string, token:string, callBack: WsCallback) {
        this.http.get(ServiceUrls.getAllICEGameInfoById(token, iceGameId)).subscribe(  
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.GET_ALL_ICE_GAME_INFO_BY_ID);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.GET_ALL_ICE_GAME_INFO_BY_ID);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.GET_ALL_ICE_GAME_INFO_BY_ID);
                }
            }
        );
    }

    // Save ICE Games list
    public saveICEGameList(iceGamSaveRequest: string, token:string, callBack: WsCallback) {
        this.http.post(ServiceUrls.saveICEGameList(token), iceGamSaveRequest).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.SAVE_ICE_GAMES);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.SAVE_ICE_GAMES);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.SAVE_ICE_GAMES);
                }
            }
        );
    }

    // Delete ICE Glossary by Glossary Id
    public deleteICEGame(iceGame: string, iceGameId: string, token:string, callBack: WsCallback) {
        this.http.post(ServiceUrls.deleteICEGame(token, iceGameId), iceGame).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.DELETE_ICE_GAME);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.DELETE_ICE_GAME);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.DELETE_ICE_GAME);
                }
            }
        );
    }

    public saveICEGameData(iceGameDataSaveRequest: string,  iceGameId: string, token:string, callBack: WsCallback){
        this.http.post(ServiceUrls.saveICEGameData(token, iceGameId), iceGameDataSaveRequest).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.SAVE_ICE_GAME_DATA); 
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.SAVE_ICE_GAME_DATA);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.SAVE_ICE_GAME_DATA);
                }
            }
        );
    }

    // Add new ICE Glossary item
    public createICEGlossary(iceGlossaryCreateRequest: string, token:string, callBack: WsCallback) {
        this.http.post(ServiceUrls.createICEGlossary(token), iceGlossaryCreateRequest).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.CREATE_ICE_GLOSSARY);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.CREATE_ICE_GLOSSARY);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.CREATE_ICE_GLOSSARY);
                }
            }
        );
    }

    // Save ICE Glossary list
    public saveGlossaryList(iceGlossarySaveRequest: string, token:string, callBack: WsCallback) {
        this.http.post(ServiceUrls.saveICEGlossaryList(token), iceGlossarySaveRequest).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.SAVE_ICE_GLOSSARY);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.SAVE_ICE_GLOSSARY);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.SAVE_ICE_GLOSSARY);
                }
            }
        );
    }

    // Delete ICE Glossary by Glossary Id
    public deleteICEGlossary(iceGlossary: string, iceGlossaryId: string, token:string, callBack: WsCallback) {
        this.http.post(ServiceUrls.deleteICEGlossary(token, iceGlossaryId), iceGlossary).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.DELETE_ICE_GLOSSARY);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.DELETE_ICE_GLOSSARY);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.DELETE_ICE_GLOSSARY);
                }
            }
        );
    }

    // get latest checked in ice course list
    public getLatestCMCourses( token:string, callBack: WsCallback){
        this.http.get(ServiceUrls.getLatestCMCourses( token )).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.GET_ALL_CHECKEDIN_ICE_COURSES);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.GET_ALL_CHECKEDIN_ICE_COURSES);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.GET_ALL_CHECKEDIN_ICE_COURSES);
                }
            }
        );
    }

    // get cm learningcheck list by selected course id
    public getCMLearningCheckList( iceCMLearningCheckGetRequest: string, token:string, callBack: WsCallback){
        this.http.post(ServiceUrls.getCMLearningCheckList(token), iceCMLearningCheckGetRequest).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.GET_CM_LEARNINGCHECK_LIST);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.GET_CM_LEARNINGCHECK_LIST);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.GET_CM_LEARNINGCHECK_LIST);
                }
            }
        );
    }

    // COPY cm learningcheck by learningcheck id
    public copyLearningCheckByLearningcheckId( thisCourseId: string, iceCMLearningCheckCopyRequest: string, token:string, callBack: WsCallback){
        this.http.post(ServiceUrls.copyLearningCheckByLearningcheckId(token, thisCourseId), iceCMLearningCheckCopyRequest).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.COPY_CM_LEARNINGCHECK);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.COPY_CM_LEARNINGCHECK);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.COPY_CM_LEARNINGCHECK);
                }
            }
        );
    }

     // get cm_GAME list by selected course id
     public getCMGameList( iceCMGameListGetRequest: string, token:string, callBack: WsCallback){
        this.http.post(ServiceUrls.getCMGameList(token), iceCMGameListGetRequest).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.GET_CM_GAME_LIST);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.GET_CM_GAME_LIST);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.GET_CM_GAME_LIST);
                }
            }
        );
    }

    // COPY cm learningcheck by learningcheck id
    public copyGameByCMGameId( thisCourseId: string, iceCMLearningCheckCopyRequest: string, token:string, callBack: WsCallback){
        this.http.post(ServiceUrls.copyGameByCMGameId(token, thisCourseId), iceCMLearningCheckCopyRequest).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.COPY_CM_GAME);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.COPY_CM_GAME);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.COPY_CM_GAME);
                }
            }
        );
    }

    /**
     * Achini Randeni
     * Get all the topics by parent topic
     * @param topicParentId 
     * @param token 
     */
    public getAllICETopicsByParentTopicId(topicParentId: string, token:string): Observable<WsResponse> {
        let url = ServiceUrls.getAllICETopicsByTopicId(token, topicParentId)
        return this.http.get(url).pipe(
            map(response => {
                var modified = JSON.parse(JSON.stringify(response));
                return new WsResponse(
                    modified.status.code,
                    modified.status.name,
                    modified.status.description,
                    modified.payload);
            }),
            catchError(error => {
                var modified = JSON.parse(JSON.stringify(error.error));
                var res = new WsResponse(
                    modified.status.code,
                    modified.status.name,
                    modified.status.description,
                    modified.payload);
                return throwError(res)
            })
        );
    }

    /**
     * Achini Randeni
     * Get all the topic content by parent topic
     * @param topicParentId 
     * @param token 
     */
    public getAllICETopicContentsByParentTopicId(topicParentId: string, token:string): Observable<WsResponse> {
        let url = ServiceUrls.getICETopicContentListByICETopicId(token, topicParentId)
        return this.http.get(url).pipe(
            map(response => {
                var modified = JSON.parse(JSON.stringify(response));
                return new WsResponse(
                    modified.status.code,
                    modified.status.name,
                    modified.status.description,
                    modified.payload);
            }),
            catchError(error => {
                var modified = JSON.parse(JSON.stringify(error.error));
                var res = new WsResponse(
                    modified.status.code,
                    modified.status.name,
                    modified.status.description,
                    modified.payload);
                return throwError(res)
            })
        );
    }

    public getAllActiveICECoursesByProjectId(projId: string, token:string, callBack: WsCallback){
        this.http.get(ServiceUrls.getAllActiveICECoursesByProjectId(token, projId)).subscribe(  
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.GET_ALL_ACTIVE_ICE_COURSES);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.GET_ALL_ACTIVE_ICE_COURSES);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.GET_ALL_ACTIVE_ICE_COURSES);
                }
            }
        );
    }

    public createICECourseForImport(iceCourseCreateRequest: string, token:string, callBack: WsCallback) {
        this.http.post(ServiceUrls.createICECourseForImport(token), iceCourseCreateRequest).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.CREATE_ICE_COURSE_IMPORT);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.CREATE_ICE_COURSE_IMPORT);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.CREATE_ICE_COURSE_IMPORT);
                }
            }
        );
    }

    public saveCourseDuplicateDetails(iceCourseCreateRequest: string, token:string, callBack: WsCallback) {
        this.http.post(ServiceUrls.createICECourseForImport(token), iceCourseCreateRequest).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.SAVE_DUPLICATE_ICE_COURSE_IMPORT);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.SAVE_DUPLICATE_ICE_COURSE_IMPORT);
                } else {
                    //browser related issues
                    if (navigator.onLine == false) {
                        var res = new WsResponse("We lost the connection with the server");
                    } else {
                        var res = new WsResponse("Unknown error happened");
                    }
                    callBack.onFail(res, WsType.SAVE_DUPLICATE_ICE_COURSE_IMPORT);
                }
            }
        );
    }

}
