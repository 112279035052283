

<div>
    <!--object id="objData" data="../assets/threedmodel/index.htm?fileUrl=https://samples3bucketrasi.s3.amazonaws.com/models/iv3d/6fa62a3b-e8d9-4796-85ae-3994b3781857.iv3d?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20200206T041038Z&X-Amz-SignedHeaders=host&X-Amz-Expires=604799&X-Amz-Credential=AKIA2J6I27N3TQUPZO7F%2F20200206%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=fb5adef618f5c3886069678fd9d3ec2cf6d269a2e2ddfb3fb56c2cfd8b5d24a9" width="800" height="600">
        <embed id="embedSource" src="../assets/threedmodel/index.htm?fileUrl=https://samples3bucketrasi.s3.amazonaws.com/models/iv3d/6fa62a3b-e8d9-4796-85ae-3994b3781857.iv3d?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20200206T041038Z&X-Amz-SignedHeaders=host&X-Amz-Expires=604799&X-Amz-Credential=AKIA2J6I27N3TQUPZO7F%2F20200206%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=fb5adef618f5c3886069678fd9d3ec2cf6d269a2e2ddfb3fb56c2cfd8b5d24a9" width="800" height="600"/-->
            <object [data]='sanitizer.bypassSecurityTrustResourceUrl(threedmodel())' width="800" height="600">
                <embed [src]='sanitizer.bypassSecurityTrustResourceUrl(threedmodel())' width="800" height="600"/>  
    </object>
</div>

