<div class="container-fluid page-container">
  <nav class="page-header">
    <div class="row">
      <div class="col">
        <p class="mb-2 h5">
          <b>Manage MCC</b>
        </p>
      </div>
      <div class="col">
        <!--        <button type="submit" id="punchlist_save" class="btn btn-primary btn-sm float-right reusable_btn my-1"-->
        <!--                ><i class="fas fa-plus" (click)="openApproveModal()"></i></button>-->
      </div>
    </div>
  </nav>
  <div class="row">
    <div class="col-sm-12 mb-2">
      <symbiosis-search [comp]="'managemcc'"  id="irn_search" (searchFunc)="searchFunc($event)"
      ></symbiosis-search>
    </div>
  </div>
  <div class="row mb-2">
    <div class="col-md-6">
      <ul class="list-group list-group-horizontal-sm">
        <li class="list-group-item d-flex align-items-center"><span class="dot pl-4 colorIssued mr-2 ml-2"> </span>Issued</li>
        <li class="list-group-item d-flex align-items-center"><span class="dot pl-4 colorApproved mr-2 ml-2"> </span>Approved</li>
        <li class="list-group-item d-flex align-items-center text-nowrap"> <span class="dot pl-4 colorQueue mr-2 ml-2"> </span>On Queue</li>
        <li class="list-group-item d-flex align-items-center"><span class="dot pl-4 colorErr mr-2 ml-2"> </span>Error</li>
      </ul>
    </div>
  </div>
  <div class="row mb-2">
    <div class="col">
      <ag-grid-angular #agGrid style="width: 100%; height: 355px;" class="ag-theme-balham" [rowData]="rowData"
                       [columnDefs]="columnDefs" [gridOptions]="gridOptions"
                       [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }"
                       [pagination]="true" [paginationPageSize]="10"
                       [overlayLoadingTemplate]="overlayLoadingTemplate"
                       [overlayNoRowsTemplate]="overlayNoRowsTemplate"
                       [getRowNodeId]="getRowNodeId" (gridReady)="onGridReady($event)">
      </ag-grid-angular>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12 col-12">
      <button *ngIf="generateOut" type="submit" id="facility_save" class="btn btn-primary btn-sm float-left"
              (click)="checkIncompleteItemsForSystemList()">Generate</button>
    </div>
  </div>
</div>

<ng-template #incompleteMCCModal>
  <div class="modal-header">
    <h6 class="modal-title pull-left">Generate Mechanical Completion Certificate </h6>
    <button type="button" class="close pull-right" aria-label="Close" (click)="incompleteModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body ">
    <p *ngIf="!systemsHasIrn">The Following System(s) do not have at least one IRN.</p>
    <div *ngIf="systemListWithIncompleteItems.length != undefined">
      <ul>
        <div *ngFor="let system of systemListWithIncompleteItems">
          <li *ngIf="!system.systemHasIRN">
            {{system.systemName}}
          </li>
        </div>
      </ul>
    </div> 
    <p *ngIf="assetsListWithoutApprovedOTNBySystemId.length != undefined && assetsListWithoutApprovedOTNBySystemId.length > 0 ">The Following Asset(s) do not have any approved OTN.</p>
    <div *ngIf="assetsListWithoutApprovedOTNBySystemId.length != undefined && assetsListWithoutApprovedOTNBySystemId.length > 0 ">
      <ul>
        <div *ngFor="let system of systemListWithIncompleteItems">
          <div *ngIf="!system.noAssetsListWithoutApprovedOTN">
          
          <li>
          {{system.systemName}}  
          <ul>
            <li class="wordwrap">{{system.assetsListWithoutApprovedOTN}}</li>
          </ul>
          </li>
        </div>
        </div>
      </ul>
    </div>
    <p *ngIf="systemListWithIncompleteItems.length>0"> Following System(s) have Incomplete Checksheets/Punchlists. Do you want to continue?  </p>
    <div *ngIf="systemListWithIncompleteItems.length != undefined">
      <ul>
        <div *ngFor="let system of systemListWithIncompleteItems">
          <li *ngIf="system.incompletePLC">
            {{system.systemName}}
          </li>
        </div>
      </ul>
    </div>
    <div class="float-right">
      <button type="button" class="btn btn-default mx-1" (click)="continue()">Continue</button>
      <button type="button" class="btn btn-primary mx-1" (click)="cancelOutGenerete()">Cancel</button>
    </div>
  </div>
</ng-template>

