import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DocumentStoreService } from './documentstore.service';
import { AlertService } from '../../util/alert/alert.service';
import { Router } from '@angular/router';
import { LoadingService } from '../../util/loading/loading.service';

@Component({
   selector: 'addFacilities-modal',
   template: `
   <div class="modal-header">
        <h4 class="modal-title pull-left">{{title}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form>
            <tag-input name="addfacilities" id="addfacilities" [(ngModel)]='selectedFacilityList'
                [displayBy]="'facilityName'" [identifyBy]="'facilityId'" [removable]='true'
                placeholder="{{'DOCUMENT_STORE.FACILITY_SELECT_PLACEHOLDER' | translate}}" 
                secondaryPlaceholder="{{'DOCUMENT_STORE.FACILITY_SELECT_PLACEHOLDER' | translate}}" maxItems="100"
                [onlyFromAutocomplete]="true" (onAdd)="selectItemFromSuggestions($event, 'system')"
                (onRemove)="removeSelectedItem($event, 'system')" [clearOnBlur]="true">
                <tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="facilityList"
                    [displayBy]="'facilityName'" [identifyBy]="'facilityId'" [limitItemsTo]=10 [appendToBody]="false"></tag-input-dropdown>
            </tag-input>
        </form>
    </div>
    <div class="modal-footer">  
        <button type="button" class="btn btn-primary" (click)="add(); bsModalRef.hide()">{{ 'Add' | translate}}</button>
        <button type="button" class="btn btn-secondary" (click)="bsModalRef.hide()">{{ 'CANCEL' | translate}}</button>
    </div>
   `
})
export class AddFacilitiesModalComponent {
    public title: string = 'Add Facilities';
    public params: any;
    public facilityList: any
    public selectedFacilityList: any

    constructor(public bsModalRef: BsModalRef, private documentStoreService: DocumentStoreService,
    private alertService: AlertService, private router: Router, private loadingService: LoadingService) { }

    ngOnInit() {

   }

   add() {
        this.loadingService.showLoading(true, null, "", null)
        
        var facilityIdList = [];
        if(this.selectedFacilityList.length > 0) {
            this.selectedFacilityList.forEach(element => {
                facilityIdList.push(element.facilityId)
            });
        }
        else {
            facilityIdList.push("0");
        }

        this.documentStoreService.updateDocumentStore(this.params.data.documentId, this.params.data.availability,
            this.params.data.id, facilityIdList, true)
            .subscribe(data => {
                this.loadingService.hideLoading()
                this.alertService.success(data.statusDescription)
                this.documentStoreService.tableReloadRequest("", "", "");
            },
            error => {
                this.loadingService.hideLoading()
                this.alertService.error(error.statusDescription)
            });
        
    }

    selectItemFromSuggestions(val: any, type: string){
        console.log("selected");
    }

    removeSelectedItem(val: any, type: string){
        console.log("removedSelectedItem");
    }
}