import { Injectable, EventEmitter } from '@angular/core';
import { UserVariable } from 'src/app/util/common/user-variable';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { CommonUtil } from 'src/app/util/common/common-util';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { WsResponse } from 'src/app/util/ws-response.model';
import { ServiceUrls } from 'src/app/util/service-urls';
import { WsCallback } from 'src/app/util/ws-callback.interface';
import { WsType } from 'src/app/util/ws-type';

@Injectable({
    providedIn: 'root'
  })
export class IssueChecksheetService{
  issueChecksheetComp: WsCallback;
  private rowData: any = null;
  private apiGridData: any = null;
  private allApiGridData: any = null;
  updateUIafterChecksheetGenerate  = new EventEmitter<any>();

  constructor(private http: HttpClient, private commonUtil: CommonUtil){

  }

  public setIssueChecksheetComp(checksheetComp: WsCallback){
    this.issueChecksheetComp = checksheetComp;
  }

  public  updateUIafterChecksheetGenerateRequest(){
    this.updateUIafterChecksheetGenerate.emit()
  }

  public  updateUIafterChecksheetGenerateResponse(){
      return this.updateUIafterChecksheetGenerate;
  }

  public setApiGridData(apiGridData: any) {
    this.apiGridData = apiGridData;
  }

  public getApiGridData() {
    return this.apiGridData;
  }

  getCheckSheetFilterResult(csVendors: string, checksheets: string, csAssets: string, csDescriptions: string, csSystems: string,
    csAssetGroups: string, csPhases: string, csAssetParents: string, csFunctionCodes: string, hasText: string){
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
        return;
    }

    let url = ServiceUrls.getCheckSheetFilterResult(token);
    return this.http.post(url, JSON.stringify(new ChecksheetFilterRequest(csVendors, checksheets, csAssets, csDescriptions, csSystems,
      csAssetGroups, csPhases, csAssetParents, csFunctionCodes, hasText))).
        pipe(
            map(response => {
                var modified = JSON.parse(JSON.stringify(response));
                return new WsResponse(
                    modified.status.description,
                    modified.status.name,
                    modified.status.code,
                    modified.payload);
            }),
            catchError(error => {
                var modified = JSON.parse(JSON.stringify(error));
                var res = new WsResponse(
                    modified.error.status.description,
                    modified.error.status.name,
                    modified.error.status.code,
                    modified.error.payload);
                return throwError(res)
            })
        );
  }

  removeCheckSheetInstance(assetSystemId: string){
    
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
        return;
    }

    let url = ServiceUrls.removeCheckSheetInstance(token);
    this.http.post(url, new HttpParams().set("csAssetSystemId", assetSystemId)).subscribe(response => {
      var modified = JSON.parse(JSON.stringify(response));
      var res = new WsResponse(
          modified.status.description,
          modified.status.name,
          modified.status.code,
          modified.payload);
          this.issueChecksheetComp.onSuccess(res, WsType.REMOVE_CS_INSTANCE);
    }, error => {
      if (error.status != '') {
        var val = (error as HttpErrorResponse).error;
        var modified = JSON.parse(JSON.stringify(val));
        var res = new WsResponse(
            modified.status.description,
            modified.status.code,
            modified.status.name,
            modified.payload);
          this.issueChecksheetComp.onFail(res, WsType.REMOVE_CS_INSTANCE);
    } else {
        //browser related issues
        var res = new WsResponse("Unknown error happened");
        this.issueChecksheetComp.onFail(res, WsType.REMOVE_CS_INSTANCE);
    }
    })
  }
}

class ChecksheetFilterRequest{
  constructor(private csVendors: string, private checksheets: string, private csAssets: string, private csDescriptions: string, 
    private csSystems: string, private csAssetGroups: string, private csPhases: string, private csAssetParents: string, 
    private csFunctionCodes: string, private hasText: string){}
}