
<!--
<div>
	<alert></alert>
</div>
-->
<div class="col-lg-12">
	<div class="col" style="overflow:hidden">
		<div class="float-left">
			<h4>{{ 'EDIT_USER.EDIT_USER' | translate }}</h4>
		</div>
		<div class="float-right">

		</div>
	</div>
	<hr />
</div>


<div class="col-lg-12 mx-auto">
	<form [formGroup]="editUserForm">
		<!--first name-->
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">{{ 'EDIT_USER.FIRST_NAME' | translate }}<div class="badge-required mx-2"></div>
			</label>
			<div class="col-sm-10">
				<input *ngIf="user!=null" id="firstName" formControlName="firstName" type="text" class="form-control form-control-sm" name="firstName"
				 [(ngModel)]="user.firstName">
			</div>
		</div>
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">{{ 'EDIT_USER.LAST_NAME' | translate }}<div class="badge-required mx-2"></div>
			</label>
			<div class="col-sm-10">
				<input *ngIf="user!=null" id="lastName" formControlName="lastName" type="text" class="form-control form-control-sm" name="firstName"
				 [(ngModel)]="user.lastName">
			</div>
		</div>
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">{{ 'EDIT_USER.USER_NAME' | translate }}<div class="badge-required mx-2"></div>
			</label>
			<div class="col-sm-10">
				<input *ngIf="user!=null" id="userName" formControlName="userName" type="text" class="form-control form-control-sm" name="firstName"
				 [(ngModel)]="user.userName">
			</div>
		</div>
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">{{ 'EDIT_USER.CLIENT' | translate }}
			</label>
			<div class="col-sm-10"> 
				<div class="row">
					<div class="col-sm-1">
						<input #inputEl *ngIf="user!=null" id="checkClient" [(checked)]="clientIdStatus" [(ngModel)]="clientIdStatus" formControlName="checkClient" type="checkbox" class="form-control form-control-sm"
						 name="checkClient" (change)="Changed(inputEl.checked)">
					</div>
					
					<div class="col-sm-11">
						<div *ngIf = "clientId != null && clientId != 'null'">
							<ng-select *ngIf="!disableClientList" [allowClear]="true" [items]="clientList" [active]="selectedClient" name="itemType" formControlName="clientList"
								id="itemType" (selected)="clientSelected($event)" (removed)="removed($event)">
							</ng-select>
						</div>
						<!--
							<select formControlName="clientList" [ngModel] = "selectedClient" (ngModelChange) ="clientSelected($event)">
								<option [ngValue]="client" *ngFor="let client of clientList">{{client.text}}</option>
						 </select>
						-->
					</div>
				</div>
			</div>
		</div>
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">{{ 'EDIT_USER.OPEN_ID_AUTHENTICATION' | translate }}
			</label>
			<div class="col-sm-10">
				<div class="row">
					<div class="col-sm-1">
						<input #inputE2 *ngIf="user!=null" id="checkOpenId" formControlName="checkOpenId" type="checkbox" class="form-control form-control-sm"
						 name="checkOpenId" (change)="CheckOpenId(inputE2.checked)" [(checked)] = "openIdStatus" [(ngModel)]= "openIdStatus" >
					</div>
				</div>
			</div>
		</div>
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">{{ 'EDIT_USER.STATUS' | translate }}<div class="badge-required mx-2"></div>
			</label>
			<div class="col-sm-10">
				<ng-select [allowClear]="true" [items]="statusList" [active]="statusItems" name="status" (selected)="statusSelected($event)" formControlName="status" id="status">
				</ng-select>
			</div>
		</div>
		<div *ngIf = "openIdStatus == false"  class="form-group row">
			<label *ngIf="!checkOpenId" class="col-sm-2 col-form-label">{{ 'EDIT_USER.EMAIL' | translate }}
				<div class="badge-required mx-2"></div>
			</label>
			<div class="col-sm-10">
				<!--
					<input *ngIf="!checkOpenId && user!=null" id="email" formControlName="email" type="text" class="form-control form-control-sm" name="email"
				 [(ngModel)]="user.emailAddress">
				-->
				 <input *ngIf="!checkOpenId && user!=null" id="email" formControlName="email" type="email" class="form-control form-control-sm" name="email"
				 [(ngModel)]="user.emailAddress" email>
			</div>
		</div>
		<div *ngIf="openIdStatus == false" class="form-group row">
			<label *ngIf="!checkOpenId" class="col-sm-2 col-form-label">{{ 'EDIT_USER.NEW_PASSWORD' | translate }}
			</label>
			<div class="col-sm-10">
				<input *ngIf="!checkOpenId" id="newPassword" formControlName="newPassword" type="password" class="form-control form-control-sm"
				 name="newPassword">
			</div>
		</div>
		<div *ngIf="openIdStatus == false" class="form-group row">
			<label *ngIf="!checkOpenId" class="col-sm-2 col-form-label">{{ 'EDIT_USER.CONFIRM_PASSWORD' | translate }}
			</label>
			<div class="col-sm-10">
				<input *ngIf="!checkOpenId" id="confirmPassword" formControlName="confirmPassword" type="password" class="form-control form-control-sm"
				 name="confirmPassword">
			</div>
		</div>
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">{{ 'EDIT_USER.SET_PASSWORD_EXPIRE' | translate }}
			</label>
			<div class="col-sm-10">
				<div class="row">
					<div class="col-sm-1">
						<input *ngIf="user!=null" #inputE3 id="passwordExpire" formControlName="passwordExpire" type="checkbox" class="form-control form-control-sm"
						 name="passwordExpire" (change)="setPasswordExpire(inputE3.checked)">
					</div>
					<div class="col-sm-11">
						<!--
							<input *ngIf="passwordExpire || checkedExpiredDate" type="text" class="form-control" formControlName="expiryDate" bsDatepicker (bsValueChange)="onStartValueChange($event)"
						 [bsValue]="bsStartValue" [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY', containerClass: 'theme-default' }" id="startDate" [(ngModel)]="passwordExpiredDate">
						-->
						 <input *ngIf="passwordExpire" type="text" class="form-control" formControlName="expiryDate" bsDatepicker (bsValueChange)="onStartValueChange($event)"
						  [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY', containerClass: 'theme-default' }" id="startDate" [(ngModel)]="passwordExpiredDate">
						  <input *ngIf="!passwordExpire" type="text" class="form-control" formControlName="expiryDate" bsDatepicker (bsValueChange)="onStartValueChange($event)"
						  [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY', containerClass: 'theme-default' }" id="startDate" [(ngModel)]="passwordExpiredDate" disabled>
					</div>
				</div>
			</div>
		</div>
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">{{ 'EDIT_USER.OMIT_AUTHENTICATION' | translate }}
			</label>
			<div class="col-sm-10">
				<div class="row">
					<div class="col-sm-1">
						<input *ngIf="user!=null" #inputE4 id="authCode" formControlName="authCode" type="checkbox" class="form-control form-control-sm"
						 (change)="setOmitDeviceId(inputE4.checked)" name="authCode" [(checked)]="omitDeviceIdStatus" [(ngModel)]="omitDeviceIdStatus">
					</div>
				</div>
			</div>
		</div>

		<div class="form-group row ml-0">
			<div class="btn-toolbar">
				<button type="submit" class="btn btn-warning btn-sm mr-2" (click)="save()">{{ 'EDIT_USER.SAVE' | translate }}</button>
			</div>
			<div class="btn-toolbar">
				<button type="button" class="btn btn-warning btn-sm mr-2" (click)="cancel()">{{ 'EDIT_USER.CANCEL' | translate }}</button>
			</div>
		</div>
	</form>
</div>