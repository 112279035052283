import { Component, OnInit, Input, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { FilesService } from '../../../files/files.service';
import { DocumentTypeService } from './../../../../util/documenttypetree/documenttype.service';
import { File } from '../../../../common-components/items/file.model';
import { FileAttribute } from '../../../../common-components/items/fileattribute.model';
import { WsResponse } from '../../../../util/ws-response.model';
import { WsType } from '../../../../util/ws-type';
import { AlertService } from '../../../../util/alert/alert.service';
import { SharedService } from 'src/app/util/shared.service';


@Component({
	selector: 'app-files-attributes',
	templateUrl: './attributes.component.html',
	styleUrls: ['./attributes.component.css'],
	inputs: ['fileData']
})
export class AttributesComponent implements OnInit {

	@ViewChildren('texts', { read: ElementRef }) texts: QueryList<ElementRef>;
	@ViewChildren('options', { read: ElementRef }) options: QueryList<ElementRef>;
	public fileData: any = null;
	public documentTypeAttributes: any = [];
	public file: File = null;
	public updatedAttribute: any;
	public document: boolean = false;
	public component: boolean = false;
	public system: boolean = false;
    initialValue: any;
	constructor(private documentTypeService: DocumentTypeService, private filesService: FilesService, 
		private alertService: AlertService, private sharedService: SharedService) {

	}

	ngOnInit() {
		if (this.fileData != null && this.fileData != undefined) {
			this.processDocuemntTypeAttributs(this.fileData.attributes);
			this.initialValue = this.fileData.attributes.value;
		}
		this.sharedService.selectedFileDataChangedResponse().subscribe(() => {
			this.fileData = this.filesService.getSelectedFileData();
		});
	}

	ngAfterContentInit(): void {
		//Called after ngOnInit when the component's or directive's content has been initialized.
		//Add 'implements AfterContentInit' to the class.
	}

	

	processAttribute(attId: any, items: any) {
		console.log("attId: "+attId+"  items: "+items.length);
		for (var i = 0; i < items.length; i++) {
			if (items[i].id == attId) {
				return items[i].componentValue;
			}
		}
	}

	changeAttribute(value:any, id: any) {
		let item = this.documentTypeAttributes.filter(item => item.attributeId == id)[0];
		let valuesplit = value.split(":");
		if(valuesplit.length>1){
			value = (valuesplit[1])
			if(value!=undefined){
				value=value.trim();
			}
		}
		 if (item == undefined || value != item.value) {
			let selectedFileData = this.filesService.getSelectedFileData();
			if (selectedFileData != null && selectedFileData != undefined) {
				this.file = new File(selectedFileData.id, selectedFileData.name, selectedFileData.documentTypeId)
				if (selectedFileData.type == "Procedure") {
					this.file.systemId = selectedFileData.systemId;
					this.file.outputConfigId = selectedFileData.outputConfigId;
				}
				let attributes = [];
				let fileAttribute = new FileAttribute(id,value);
				attributes.push(fileAttribute);
				this.file.attributes = attributes;
				this.updatedAttribute = fileAttribute;
				this.filesService.updateFile(JSON.stringify(this.file), selectedFileData.id, selectedFileData.type, this);
			}
		}
	}

	processDocuemntTypeAttributs(payload: any) {
		if (payload != null) {
			
			for (let i = 0; i < payload.length; i++) {
				this.documentTypeAttributes.push({
					'attributeId': payload[i]["attributeId"],
					'attributeName': payload[i]["attributeName"],
					'attributeType': payload[i]["attributeType"],
					'docAttributeValueId': payload[i]["docAttrValueId"],
					'required': payload[i]["required"],
					'value': payload[i]["value"] != null ? payload[i]["value"] : "",
					'initialvalue': payload[i]["value"] != null ? payload[i]["value"] : "",
					'categoryId': payload[i]["categoryId"],
					'items': payload[i]["attributeType"] == 'Component' ? payload[i]["components"] : payload[i]["attributeType"] == 'System' ? payload[i]["systems"] :
						payload[i]["attributeType"] == 'Document' ? payload[i]["documents"] : null,
					'isText': (payload[i]["attributeType"] == 'Number' || payload[i]["attributeType"] == 'Text') ? true : false
				});

				

			}
		}
	}

	checkComponent(type:any, name:any){
		 if(type == 'Component' || name == 'c1' ){
			return true;
		}
	}

	checkSystem(type:any, name:any){
		  if(type == 'System' || name == 'System' ){
			return true;
		}
	}
	checkDocument(type:any, name:any){
		  if(type == 'Document' || name == 'Document'){
			return true;
		}
	}
	onSuccess(data: WsResponse, serviceType: WsType): void {
		if (WsType.FILE_UPDATE == serviceType && this.updatedAttribute != null) {
			var item = this.fileData.attributes.filter(item => item.attributeId === this.updatedAttribute.attributeId)[0];
			item.value = this.updatedAttribute.value
			this.alertService.clear();
			this.alertService.success('Attribute updated successfully');
		}
	}

	onFail(res: WsResponse, serviceType: WsType): void {
		if (res != null && WsType.FILE_UPDATE == serviceType) {
			if (res.statusCode == "532") {
				//this.router.navigateByUrl('login');
			}
			else if (res.statusCode == "600") {
				let selectedFileData = this.filesService.getSelectedFileData();
				this.filesService.updateFile(JSON.stringify(this.file), selectedFileData.id, selectedFileData.type, this);
			} else {
				this.alertService.success(res.statusDescription, false);
			}
		}
	}

}
