import { ImagesService } from './../reusables/images/images.service';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { LandingService } from './landing.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { UserVariable } from '../util/common/user-variable';
import { WsCallback } from '../util/ws-callback.interface';
import { WsType } from '../util/ws-type';
import { WsResponse } from '../util/ws-response.model';
import { AlertService } from '../util/alert/alert.service';
import { RecentProject } from './recent-project.service';
import { LoggedUser } from '../user/logged-user.model';
import { LocalStorage } from '../util/localstorage.service';
import { RecentProjectModel } from './recent-project.model';
import { CapabilityService } from '../util/capability.service';
import { GridOptions, ColDef, Module, AllModules } from '@ag-grid-enterprise/all-modules';
import { TagsService } from '../reusables/tags/tags.service';
import { StatementsService } from '../reusables/statements/statements.service';
import { ComponentsService } from '../reusables/components/components.service';
import { SystemsService } from '../reusables/systems/systems.service';
import { DocumentsService } from '../reusables/documents/documents.service';
import { FacilitiesService } from '../reusables/facilities/facilities.service';
import { ButtonRendererComponent } from './button-renderer.component';
import { LoadingService } from '../util/loading/loading.service';
import { CommonUtil } from '../util/common/common-util';
import { ThreedModelService } from '../reusables/threed-model/threed-model.service';
import { VideosService } from '../reusables/videos/videos.service';


@Component({
	selector: 'app-landing',
	templateUrl: './landing.component.html',
	styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit, WsCallback {

	private loggedInUser: LoggedUser;
	public recentProjectList: Array<RecentProjectModel> = new Array<RecentProjectModel>();
	public isCollapse: boolean = true;
	frameworkComponents: any;
	private gridApi;
	private getRowNodeId;
	private client: string;
	private project: string;
	private com: string;
	private gridColumnApi;
	private columnDefs = [];
	rowData: any = [];
	public gridOptions: GridOptions;
	public isVisible: boolean = false;  
	public modules: Module[] = AllModules;
	public viewOAProject: boolean = false
	public viewCOMProject: boolean = false

	constructor(private landingService: LandingService, private translate: TranslateService, private router: Router,
		private alertService: AlertService, public recentProjectService: RecentProject, private capabilityService: CapabilityService,
		private tagsService: TagsService, private statementService: StatementsService,
		private componentsService: ComponentsService, private systemService: SystemsService, private documentService: DocumentsService,
		private facilitiesService: FacilitiesService, private loadingService: LoadingService, private commonUtil: CommonUtil, private imageService: ImagesService,
		private threeDModelService: ThreedModelService, private videosService: VideosService) {
		translate.addLangs(["es", "en"]);
		translate.setDefaultLang("en");
		let browserLang = translate.getBrowserLang();
		translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
		this.frameworkComponents = {
			buttonRenderer: ButtonRendererComponent,
		}
		this.translate.get('LANDING.CLIENT').subscribe((res: string) => {
			this.client = res;
			this.translate.get('LANDING.PROJECT').subscribe((res: string) => {
				this.project = res;
			});
		});
		localStorage.removeItem(LocalStorage.PROJECT_ID);
		localStorage.removeItem(LocalStorage.PROJECT_NAME);
		localStorage.removeItem(LocalStorage.CLIENT_ID);

		this.gridOptions = {
			defaultColDef: {
			  resizable: true,
			  sortable: true,
			}	
		}
		this.gridOptions.getRowStyle = function (params) {
			if (params.data != undefined && params.data.id === localStorage.getItem(LocalStorage.DEFAULT_PROJECT_ID)) {
				return { background: '#ecf0f1' }
			}

		}
		this.columnDefs = [
			{
				headerName: this.client,
				field: "client",
			},
			{
				headerName: this.project,
				field: "projectName",
			},
			{
				headerName: "Commissioning",
				field: "projectType",
				width: 40,
				cellRenderer: 
				function (params) {
					if (params.data.projectType === 3 ||  params.data.projectType === 2 ) {
						console.log("=====BUTTON RENDERER1======");
						return('<button class="btn btn-custom" (click)="onClick()"> view </button>');
					}
				}
				
			},

		
			//{
			//headerName: '',
			//field: "oa",
			//width: 20,
			//cellStyle: { 'text-align': 'center' },
			//cellRendererFramework: LandingCellRenderComponent,
			//hide: "true"
			//},
			//{
			//headerName: '',
			//field: "com",
			//width: 20,
			//cellStyle: { 'text-align': 'center' },
			//cellRendererFramework: LandingCellRenderComponent,
			//hide: "true"
			//},
			//{
			//headerName: '',
			//field: "dashboard",
			//width: 20,
			//cellStyle: { 'text-align': 'center' },
			//cellRendererFramework: LandingCellRenderComponent,
			//hide: "true"
			//},
			//{
			//headerName: '',
			//field: "procedure",
			//width: 20,
			//cellStyle: { 'text-align': 'center' },
			//cellRendererFramework: LandingCellRenderComponent,
			//hide: "true"
			//}
		];

		
		//landingService.setCallback(this);
		//recentProjectService.setCallback(this);
		this.getRowNodeId = function (data) {
			return data.id;
		};
		this.loadingService.hideLoading();
	}

	onCellClicked(event){
		let token = UserVariable.getUserToken();
		if (!this.commonUtil.validateLogin(token)) {
			return;
		}
		let data = event.data.oa.split(":")[1];
		let items = data.split(",")
		if (items != null && items.length == 4) {
			UserVariable.projectId = items[0];
			this.landingService.setSelectedProjectName(items[1])
			UserVariable.projectName = items[1]
			UserVariable.clientId = items[3]
		}
		//for the symbiosis-web "recent" projct type is 1
		this.addToRecentlyVisitedProject(event.data.id, 1);
		this.getCapabilityList();
		
		localStorage.setItem(LocalStorage.CLIENT_NAME, event.data.client);
		localStorage.setItem(LocalStorage.PROJECT_NAME, event.data.projectName);
		localStorage.setItem(LocalStorage.PROJECT_TYPE, '1');
		sessionStorage.setItem(LocalStorage.PROJECT_TYPE, '1');
		localStorage.setItem(LocalStorage.PROJECT_ID, event.data.id);
		sessionStorage.setItem(LocalStorage.PROJECT_ID, event.data.id);
		//localStorage.setItem(LocalStorage.CLIENT_ID, event.data.clientId);
		localStorage.setItem(LocalStorage.CLIENT_ID, items[3])
		setTimeout(() => {
			this.checkCapability();
			if(event.colDef.field == "projectType" && (event.data.projectType == 2 || event.data.projectType == 3)){
				if(this.viewCOMProject == false){
					this.alertService.clear();
					this.alertService.error(this.translate.instant('LANDING.UNAUTHORIZED'), true);
				}
				else{
					this.router.navigateByUrl('/commissioning')
				}
			}
			else{
				if(this.viewOAProject == false){
					this.alertService.clear();
					this.alertService.error(this.translate.instant('LANDING.UNAUTHORIZED'), true);
				}
				else{
					this.clearReusableSearchResults();
					this.router.navigateByUrl('/home')
				}
			}
		}, 2000);
		this.imageService.setImageList([]);
		this.threeDModelService.setThreeDModelList([]);
		this.videosService.setVideoList([]);

	}
	ngAfterViewInit() {
		if (this.gridApi) {
		  this.gridApi.sizeColumnsToFit();
		}
	}
	onGridReady(params) {
		const allColumnIds = [];
		this.columnDefs.forEach((columnDef) => {
			allColumnIds.push(<ColDef>columnDef);
		});
		// this.gridColumnApi = params.columnApi;
		// this.gridColumnApi.autoSizeColumns(allColumnIds);
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		params.api.sizeColumnsToFit();
		window.addEventListener("resize", this.onWindowResize);
	}

	private onWindowResize = () => {
        
        setTimeout(() => {
            if (this.gridApi) {
                this.gridApi.sizeColumnsToFit();
            }
        }); 
    };

	ngOnDestroy() {
        // Remove the window resize event listener
        window.removeEventListener("resize", this.onWindowResize);
		this.gridApi.destroy();
    	this.gridApi = null;
    }

	ngOnInit() {
		//initialize variables
		localStorage.setItem(LocalStorage.PROJECT_NAME, "");
		this.loggedInUser = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER)) as LoggedUser;
		this.landingService.getAllProjects(this.loggedInUser.userId, this);
		this.recentProjectService.getAllRecentProjects(this.loggedInUser.userId, this);
		UserVariable.projectId = "";
		UserVariable.clientId = "";
		UserVariable.projectName = "";

	}

	loadData(payload: any) {
		if (payload != null) {
			this.rowData = [];
			for (let i = 0; i < payload.length; i++) {
				if (payload[i]["status"] === "Active") {
					this.rowData.push({
						id: payload[i]["id"],
						projectName: payload[i]["projectName"],
						client: payload[i]["clientName"] != null ? payload[i]["clientName"] : "",
						projectType: payload[i]["projectType"],
						commissioning: '',
						status:payload[i]["status"] === "Active" ? true : false,
						oa: "oa:" + payload[i]["id"] + "," + payload[i]["projectName"] + "," + payload[i]["clientName"] + "," + payload[i]["clientId"],
						com: payload[i]["projectType"] > 1 ? "acom:" + payload[i]["id"] + "," + payload[i]["projectName"] + "," + payload[i]["clientName"] + "," + payload[i]["clientId"] : "com:" + payload[i]["id"] + "," + payload[i]["projectName"] + "," + payload[i]["clientName"] + "," + payload[i]["clientId"],
						dashboard: "dashboard:" + payload[i]["id"] + "," + payload[i]["projectName"] + "," + payload[i]["clientName"] + "," + payload[i]["clientId"],
						procedure: "procedure:" + payload[i]["id"] + "," + payload[i]["projectName"] + "," + payload[i]["clientName"] + "," + payload[i]["clientId"]
					});
				}
			}
		}
	}

	/**
	 * set default project type to use in new home tabs
	 * @param recentProjects 
	 */
	setDefaultProjectType(recentProjects: any){
		if( (recentProjects != null) && (recentProjects.length != 0)){
			for (let i = 0; i < recentProjects.length; i++) {
				if(recentProjects[i].isDefaultProject == '1'){
					// localStorage.setItem(LocalStorage.DEFAULT_PROJECT_TYPE, recentProjects[i]["projectType"]);
					localStorage.setItem(LocalStorage.DEFAULT_PROJECT_TYPE, '1');
				}
			}
		}
	}

	/**
	 * Get the project type name by value.
	 * @param type project type in int (possible values are 1, 2, 3 and 4)
	 */
	private getProjectTypeName(type: number): string {
		var name: string;
		switch (type) {
			case 1:
				name = 'OA';
				break;
			case 2:
				name = 'COM';
				break;
			case 3:
				name = 'Dashboard';
				break;
			case 4:
				name = 'Procedure Store';
				break;
			default:
				name = 'Not Available';
				break;
		}
		return name;
	}

	onSuccess(data: WsResponse, serviceType: WsType): void {
		if (data != null && WsType.LANDING == serviceType) {
			//this.alertService.success(data.statusDescription.toString(), false);
			this.loadData(data.payload)
		} else if (serviceType == WsType.GET_ALL_RECENT_PROJECTS) {
			this.setDefaultProjectType(data.payload);
			this.recentProjectList = data.payload;
			this.loadingService.hideLoading();
		} else if (serviceType == WsType.DELETE_USER_RECENT_PROJECT) {
			//refresh the recent list again
			this.recentProjectService.getAllRecentProjects(this.loggedInUser.userId, this);
			
		} else if (serviceType == WsType.SET_DEFAULT_RECENT_PROJECT) {
			//show the latest status of projects
			this.recentProjectService.getAllRecentProjects(this.loggedInUser.userId, this);
		} else if (serviceType == WsType.DELETE_USER_DEFAULT_RECENT_PROJECT) {
			//show the latest status of projects
			this.recentProjectService.getAllRecentProjects(this.loggedInUser.userId, this);
		}
	}

	onFail(res: WsResponse, serviceType: WsType): void {
		if (res != null && WsType.LANDING == serviceType) {
			if (res.statusCode == "532") {
				this.router.navigateByUrl('login');
			}
			else if (res.statusCode == "600") {
				this.landingService.getAllProjects(this.loggedInUser.userId, this);
			} else if (res.statusCode == "0") {				
				this.alertService.clear();
				this.alertService.error(this.translate.instant('LANDING.CONNECTION_LOST'), true);
			} else {
				this.alertService.error(res.statusDescription, false);
			}
		} else if (serviceType == WsType.GET_ALL_RECENT_PROJECTS) {
			this.loadingService.hideLoading();
			if (res.statusCode == "0") {				
				this.alertService.clear();
				this.alertService.error(this.translate.instant('LANDING.CONNECTION_LOST'), true);
			}
			else{
				this.alertService.error(res.statusDescription);
			}
		} else if (serviceType == WsType.ADD_UPDATE_RECENT_PROJECT) {
			//We don't show the error message here since it is out of user control.
			console.log("=======error======" + res.statusDescription);
		} else if (serviceType == WsType.DELETE_USER_RECENT_PROJECT) {
			this.alertService.error(res.statusDescription);
			this.loadingService.hideLoading();
		} else if (serviceType == WsType.SET_DEFAULT_RECENT_PROJECT) {
			this.alertService.error(res.statusDescription);
		} else if (serviceType == WsType.DELETE_USER_DEFAULT_RECENT_PROJECT) {
			this.alertService.error(res.statusDescription);
		}
	}

	/**
	 * Will call the addUpdateRecentProject() in the RecentProjectService to update the recently visited project.
	 */
	private addToRecentlyVisitedProject(id: any, type: number): void {
		console.log(id);
		var recentProjectModel: RecentProjectModel = new RecentProjectModel(id, this.loggedInUser.userId.toString(), 0, type);
		this.recentProjectService.addUpdateRecentProject(JSON.stringify(recentProjectModel), this);
	}

	onRowSelected(event) {
		let token = UserVariable.getUserToken();
		if (!this.commonUtil.validateLogin(token)) {
			return;
		}
		let data = event.data.oa.split(":")[1];
		let items = data.split(",")
		if (items != null && items.length == 4) {
			UserVariable.projectId = items[0];
			this.landingService.setSelectedProjectName(items[1])
			UserVariable.clientId = items[3]
			UserVariable.projectName = items[1]
		}
		//for the symbiosis-web "recent" projct type is 1
		this.addToRecentlyVisitedProject(event.data.id, 1);
		this.getCapabilityList();
		setTimeout(() => {
	   
			this.checkCapability();
			if(this.viewOAProject == false){
				this.alertService.clear();
				this.alertService.error(this.translate.instant('LANDING.UNAUTHORIZED'), true);
			}else{
				localStorage.setItem(LocalStorage.CLIENT_NAME, event.data.client);
				localStorage.setItem(LocalStorage.PROJECT_NAME, event.data.projectName);
				localStorage.setItem(LocalStorage.PROJECT_TYPE, '1');
				sessionStorage.setItem(LocalStorage.PROJECT_TYPE, '1');
				localStorage.setItem(LocalStorage.PROJECT_ID, event.data.id);
				sessionStorage.setItem(LocalStorage.PROJECT_ID, event.data.id);
				// localStorage.setItem(LocalStorage.CLIENT_ID, event.data.clientId);
				localStorage.setItem(LocalStorage.CLIENT_ID, items[3]);
				//clear reusable search results
				this.clearReusableSearchResults()
				this.imageService.setImageList([]);
				this.threeDModelService.setThreeDModelList([]);
				this.videosService.setVideoList([]);
				this.router.navigateByUrl('/home')
			}
		}, 2000); 
	}
	// /**
	//  * Will call the addUpdateRecentProject() in the RecentProjectService to update the recently visited project.
	//  */
	// private addToRecentlyVisitedProject(data: any, type: number): void {
	// 	console.log(data);
	// 	var recentProjectModel: RecentProjectModel = new RecentProjectModel(data.row.id, this.loggedInUser.userId.toString(), 0, type);
	// 	this.recentProjectService.addUpdateRecentProject(JSON.stringify(recentProjectModel));
	// }

	/**
	 * clear reusable search results
	 */
	private clearReusableSearchResults() {
		let emptyRowData = []
		this.tagsService.setRowData(emptyRowData)
		this.statementService.setRowData(emptyRowData)
		this.componentsService.setRowData(emptyRowData)
		this.systemService.setRowData(emptyRowData)
		this.documentService.setRowData(emptyRowData)
		this.facilitiesService.setRowData(emptyRowData)
	}

	/**
	 * Remove the selected project from the recently visited list.
	 */
	private onRecentProjectCloseButtonClick(name: string, projectType: number): void {
		this.loadingService.showLoading(true,null,"Closing..",null);
		console.log("++++++++++++++++++++++++++++++++++++++++++")
		var request = { projectId: name, userId: this.loggedInUser.userId, projectType: projectType };
		this.recentProjectService.deleteUserRecentProject(JSON.stringify(request), this);	
	}

	/**
	 * Mark the default project as non default and non default project as the default project
	 * @param projectId id of the selected project
	 * @param isDefaultProject  status of the selected project
	 */
	private changeDefaultProjectStatus(projectId: string, isDefaultProject: number, projectType: number): void {
		if (isDefaultProject == 0) {
			//Not a default project
			var request = { projectId: projectId, isDefaultProject: 1, projectType: projectType, userId: this.loggedInUser.userId };
			this.recentProjectService.setDefaultRecentProjects(JSON.stringify(request), this);
		} else if (isDefaultProject == 1) {
			//A default project
			var request2 = { projectId: projectId, userId: this.loggedInUser.userId, projectType: projectType };
			this.recentProjectService.deleteUserDefaultRecentProject(JSON.stringify(request2), this);
		} else {
			this.alertService.error('Invalid project project status');
		}
	}

	public signOutBtnClickEvent(): void {
		this.router.navigateByUrl('/login')
	}

	public OABtnClickEvent(): void {
		UserVariable.projectId = "ABCD";
		this.router.navigateByUrl('/home')
	}

	public dashboadBtnClickEvent(): void {
		console.log("PrevProjId =" + UserVariable.projectId);
		UserVariable.projectId = "11111111";
		console.log("NewProjId =" + UserVariable.projectId);
		//this.router.navigateByUrl('/home')
	}

	public procBtnClickEvent(): void {
		console.log("CurProjId =" + UserVariable.projectId);
	}
	onItemSelected(event) {
		let token = UserVariable.getUserToken();
		if (!this.commonUtil.validateLogin(token)) {
			return;
		}
		if ((event != null) && (event.projectType == 1)) {
			UserVariable.projectId = event.projectId;
			this.landingService.setSelectedProjectName(event.projectName)
			UserVariable.clientId = event.clientId;
			UserVariable.projectName = event.projectName;
			this.addToRecentlyVisitedProject(event.projectId, 1);
			this.getCapabilityList();
			setTimeout(() => {
				this.checkCapability();
				if(this.viewOAProject == false){
					this.alertService.clear();
					this.alertService.error(this.translate.instant('LANDING.UNAUTHORIZED'), true);
				}else{
					localStorage.setItem(LocalStorage.CLIENT_NAME, event.clientName);
					localStorage.setItem(LocalStorage.PROJECT_NAME, event.projectName);
					localStorage.setItem(LocalStorage.PROJECT_TYPE, '1');
					sessionStorage.setItem(LocalStorage.PROJECT_TYPE, '1');
					localStorage.setItem(LocalStorage.PROJECT_ID, event.projectId);
					sessionStorage.setItem(LocalStorage.PROJECT_ID, event.projectId);
					localStorage.setItem(LocalStorage.CLIENT_ID, event.clientId);

					//clear reusable search results
					this.clearReusableSearchResults()
					this.imageService.setImageList([]);
					this.threeDModelService.setThreeDModelList([]);
					this.videosService.setVideoList([]);
					this.router.navigateByUrl('/home')
				}
			}, 2000);
		}
	}
	private getCapabilityList() {
		this.capabilityService.getAllCapabilityList()
			.subscribe(data => {
				localStorage.setItem(LocalStorage.CAPABILITY_LIST, JSON.stringify(data.payload))
			},
			error => {

			})

	}

	public checkCapability() {
		let viewOAProjectCapability = "viewOAProject"
		let viewOACOMProjectCapability = "viewCOMProject"

		this.viewOAProject = this.capabilityService.isCapabilityAssigned(viewOAProjectCapability)
		this.viewCOMProject = this.capabilityService.isCapabilityAssigned(viewOACOMProjectCapability)

	
	}
}
