import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserVariable } from '../../util/common/user-variable';
import { ServiceUrls } from '../../util/service-urls';
import { WsResponse } from '../../util/ws-response.model';
import { WsCallback } from '../../util/ws-callback.interface';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators'
import { CommonUtil } from 'src/app/util/common/common-util';

@Injectable({
    providedIn: 'root'
})
export class ThreedModelService {
    public token: string;
    private rowData: any = null;
    private threeDModels: any = null;
    private URLList: Array<any> = [];;
    private threeDModelSearchState:any = null;
    private apiGridData: any = null;
    private callback: WsCallback;
    constructor(private http: HttpClient, private commonUtil: CommonUtil) { }
    public setCallback(callBack: WsCallback) {
        this.callback = callBack;
    }



    searchThreeDModel(documentTypeId: String, description: String, hasText: string) {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        let url = this.getThreeDModelsBySearch(token);
        return this.http.post(url, JSON.stringify(new Request(documentTypeId, description, hasText))).
            pipe(
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                }),
                catchError(error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    return throwError(res)
                })
            );

    }

    getThreeDModels(threedmodeldata: any) {
        let a = JSON.stringify(threedmodeldata);
        console.log('a', a);
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        let url = this.getThreeDModel(token);
        return this.http.post(url, JSON.stringify(new ThreeDModelRequest(a))).
            pipe(
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                }),
                catchError(error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    return throwError(res)
                })
            );

    }

    getThumbnailURL(data: any) {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        let url = ServiceUrls.THREED_MODEL_SEARCH_THREED_MODEL + '?token=' + token;
        return this.http.post(url, new ThreedModelInfoRequest(data)).
            pipe(
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                }),
                catchError(error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    return throwError(res)
                })
            );
    }

    getThreeDModelInfoById(modelId: any) {
        let token = UserVariable.getUserToken();
        let url = ServiceUrls.GET_THREED_MODEL_INFO + '?token=' + token;
        return this.http.post(url, new ThreedModelInfoRequest(modelId)).
            pipe(
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.code,
                        modified.status.name,
                        modified.status.description,
                        modified.payload);
                }),
                catchError(error => {
                    var modified = JSON.parse(JSON.stringify(error.error));
                    var res = new WsResponse(
                        modified.status.code,
                        modified.status.name,
                        modified.status.description,
                        modified.payload);
                    return throwError(res)
                })
            );
    }

    public getThreeDModelsBySearch(token: String) {
        let url = ServiceUrls.THREED_MODEL_SEARCH + '?token=' + token;
        return url;

    }
    public getThreeDModel(token: String) {
        let url = ServiceUrls.THREED_MODEL_SEARCH_THREED_MODEL + '?token=' + token;
        return url;
    }

    public setApiGridData(apiGridData: any) {
        this.apiGridData = apiGridData;
    }

    public getApiGridData() {
        return this.apiGridData;
    }

    public setRowData(rowData: any) {
        this.rowData = rowData;
    }

    public getRowData() {
        return this.rowData;
    }

    public setThreeDModelList(threeDModels: any) {
        this.threeDModels = threeDModels;
    }

    public getThreeDModelList() {
        return this.threeDModels;
    }

    public setURLList(urlList: any) {
        this.URLList = urlList;
    }

    public getURLList() {
        return this.URLList;
    }

    public setThreeDModelSearchState(threeDModelSearchState: any) {
        this.threeDModelSearchState = threeDModelSearchState;
    }

    public getThreeDModelSearchState() {
        return this.threeDModelSearchState;
    }
}
class Request {
    constructor(private documentTypeId: String, private description: String, private hasText: String,) { }
}

class ThreeDModelRequest {
    constructor(private threedmodeldata: any) { }
}

class ThreedModelInfoRequest {
    constructor(private id: any) { }
}

