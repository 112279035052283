<div class="container-fluid p-0 nav-tabs nav-div-custom ">
	<ul class="nav nav-tabs-custom rounded-0 float-left" id="tab_nav_bar" [style.height.px]="ulHeight">
		<!--li class="nav-item" *ngFor="let tab of tabs" [ngClass]="{'active': tab.active}" (dblclick)="fullScreen(tab)">
			<div class="nav-link nav-link-custom" [ngClass]="{'active': tab.active}">
				<p class="nav-link-p h6 float-left mb-0 noselect" (click)="selectTab(tab, true);">
					{{tab.title}}
				</p>
				<button id="main_tab_close_btn_{{tab.id}}" type="button" class="tab-close-text close ml-3 float-right"
					aria-label="Close" (click)="setModel(iceCourseCheckModel,tab);closeTab(tab);">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
		</li-->
		<!-- dynamic tabs -->
		<li class="float-left tab_header{{n + 1}}" *ngFor="let tab of dynamicTabs; let n=index" [ngClass]="{'active tab-a': tab.active}"
			(dblclick)="fullScreen(tab)">
			<div class="nav-link nav-link-custom px-2" [ngClass]="{'active tab-a': tab.active}">
				<p class="nav-link-p h6 float-left mb-0 noselect" (click)="selectTab(tab, true)" 
					[ngSwitch]="tab.componentType" tooltip="{{tab.path}}" [placement]="placement" [adaptivePosition]="false" container="body" (mouseover)="onMouseoverTab(tab)">
					<i *ngSwitchCase="'Files'" class="far fa-folder mr-1"></i>
					<i *ngSwitchCase="'Procedures'" class="far fa-layer-group mr-1"></i>
					<i *ngSwitchCase="'TopicMapBuilder'" class="far fa-map mr-1"></i>
					<i *ngSwitchCase="'Editor'" class="far fa-lightbulb mr-1"></i>
					<i *ngSwitchCase="'Revisions'" class="far fa-scroll-old mr-1"></i>
					<i *ngSwitchCase="'Attributes'" class="far fa-info mr-1"></i>
					<i *ngSwitchCase="'IceCourse'" class="far fa-graduation-cap mr-1"></i>
					<i *ngSwitchCase="'CoursePreview'" class="far fa-eye"></i>
					{{tab.title}}
				</p>
				<button id="tab_close_btn_{{tab.id}}" *ngIf="tab.isCloseable" type="button"
					class="tab-close-text close ml-3 float-right" aria-label="Close" (click)="setModel(iceCourseCheckModel,tab);closeTab(tab);">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
		</li>
	</ul>
	<button id="tabhost_more_tabs_btn" type="button" *ngIf="dynamicTabs.length>0" class="btn btn-light  m-0 p-1 rounded-0 float-right"
		(click)="moreTabsPO.toggle()">
		<i class="far fa-ellipsis-v" [popover]="moreTabs" containerClass="menu-tabs" placement="bottom"
			#moreTabsPO="bs-popover" container="body" [outsideClick]="true" triggers=""></i>
	</button>
</div>
<ng-content></ng-content>
<ng-template dynamic-tabs #container></ng-template>
<ng-template #moreTabs>
	<div class="card col-sm-12 rounded-0 p-0 border-0 mt-2 mb-1">
		<ul class="nav">
			<!--li class="nav-item" *ngFor="let tab of dynamicTabs" [ngClass]="{'active tab-a': tab.active}">
				<div class="nav-link nav-link-custom px-2" [ngClass]="{'active tab-a': tab.active}">
					<p class="nav-link-moretab-p h6 float-left mb-0 noselect"
						(click)="selectTabFromMore(tab); moreTabsPO.toggle()" [ngSwitch]="tab.componentType">
						<i *ngSwitchCase="'Files'" class="far fa-folder mr-1"></i>
						<i *ngSwitchCase="'Procedures'" class="far fa-layer-group mr-1"></i>
						<i *ngSwitchCase="'TopicMapBuilder'" class="far fa-map mr-1"></i>
						<i *ngSwitchCase="'Editor'" class="far fa-lightbulb mr-1"></i>
						<i *ngSwitchCase="'Revisions'" class="far fa-scroll-old mr-1"></i>
						<i *ngSwitchCase="'Attributes'" class="far fa-info mr-1"></i>
						<i *ngSwitchCase="'IceCourse'" class="far fa-graduation-cap mr-1"></i>
						<i *ngSwitchCase="'CoursePreview'" class="far fa-eye"></i>
						{{tab.title}}
					</p>
				</div>
			</li-->

			<li class="nav-item ">
				<div class="nav-link nav-link-custom nav-link-custom-button px-2 active tab-a">
					<button type="button" class="btn btn-outline-danger btn-sm btn-block" (click)="openCloseAllModal()">
						{{ 'TAB_HOST.BTN_TXT_CLOSE_ALL' | translate }}
					</button>
				</div>
			</li>
		</ul>
	</div>
</ng-template>

<!-- Template model to check the ice course tab closing event-->
<ng-template #iceCourseCheckModel>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{'CLOSE_CONFIRM' | translate }}</h4>
    </div>    	
	<div class="modal-body">
        <div class="container">
			<h5 class='warn-text-editor'>{{ 'WARNING' | translate}}</h5>
			<h5>{{ 'CLOSE_CONFIRM_MSG' | translate}} </h5>
        </div>
    </div> 
    <div class="modal-footer">
	  <button  type="button" class="btn btn-default" (click)="closeICECourse();modalRef.hide();">{{'YES' | translate}}</button>
      <button type="button" class="btn btn-warning" (click)="modalRef.hide()">{{ 'NO' | translate}}</button>      
    </div>
</ng-template>

<ng-template #closeAllConfirmTemplate>
	<div class="modal-header">
        <h4 class="modal-title pull-left">{{'CLOSE_CONFIRM' | translate }}</h4>
	</div>
	<div class="modal-body">
        <div class="container">
			<div>
				<h6>{{ 'CLOSE_ALL_UNSAVED_CHANGES_MESSAGE' | translate }}</h6>
			</div>
			<div *ngFor="let file of unsavedFiles">
				{{file}}
			</div>
			<br>
			<div>
				<h6>{{ 'CLOSE_ALL_CONFIRM_MESSAGE' | translate }}</h6>
			</div>
        </div>
    </div> 
	<div class="modal-footer">
		<button  type="button" class="btn btn-default" (click)="onConfirmClose(); modalRef.hide();">{{'YES' | translate}}</button>
		<button type="button" class="btn btn-warning" (click)="modalRef.hide()">{{ 'NO' | translate}}</button>      
	  </div>  
</ng-template>
