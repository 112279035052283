import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { OutputManagementService } from '../outputmanagement.service';
import { UserVariable } from '../../../util/common/user-variable';
import { AlertService } from '../../../util/alert/alert.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from '../../../util/loading/loading.service';
import { SharedService } from 'src/app/util/shared.service';

@Component({
	selector: 'addToProject-modal',
	template: `
   <div class="modal-header">
   <h4 class="modal-title pull-left">{{title}}</h4>
   <button type="button" class="close pull-right" aria-label="Close" (click)="cancellClick()">
      <span aria-hidden="true">&times;</span>
   </button>
</div>
<div class="modal-body">
   <form>     
      <div class="form-group row">
         <label for="tempValue" class="col-sm-2 col-form-label">{{ 'OUTPUT_MANAGEMENT.TEMPLATE_NAME' | translate }}</label>         
         <div class="col-sm-10">
            <input type="text" class="form-control" id="OM_tempValue"
            [(ngModel)]="templatename" name="tempValue">
         </div>
      </div>      
   </form>
</div>
<div class="modal-footer">   
   <button type="button" class="btn btn-primary" (click)="save(); bsModalRef.hide()">{{ 'Save' | translate}}</button>
   <button type="button" class="btn btn-secondary" (click)="cancellClick()">{{ 'CANCEL' | translate}}</button>
</div>
   `
})
export class AddTemplateModalComponent {
	title: string = "Edit Template Name"
	om_translated_data: any = null;
	public loadingMessage: string;
	public templatename: string;
	public id: String;

	constructor(public bsModalRef: BsModalRef, private outputManagementService: OutputManagementService, 
		private alertService: AlertService, private router: Router, private sharedService: SharedService,
		private translate: TranslateService, private loadingService: LoadingService) { }

	ngOnInit() {

	}

	public save() {
		if (this.templatename != "") {
         this.alertService.clear()
			this.loadingService.showLoading(true, null, this.loadingMessage, null)
			var editedTemp = {
				"id": this.id,
				"templateName": this.templatename
			}
			this.outputManagementService.projectTemplateSave(editedTemp)
				.subscribe(data => {
					console.log('projectTemplateSave');
					this.loadingService.hideLoading()
					// this.router.navigateByUrl('outputaddprojecttemp')
					//this.sharedService.changeOMComponentsRequest('projecttemplate')
				},
					error => {
						this.loadingService.hideLoading()
						this.alertService.clear()
						this.alertService.error(error.statusDescription)
					});
		} else {
			this.alertService.error("Template name can not be empty.")
		}

	}

   public cancellClick() {
      this.alertService.clear()
      this.bsModalRef.hide()	
   }

}
