export class IceGame {
    constructor(
        public id?: string,
        public courseId?: string,
        public gameName?: string,
        public description?: string,
        public gameType?: string,
        public gameData?: string,
        public SMgameData?: any,
        public gameConfig?: string,
        public topicId?: string,
        public topicContentId?: string,
        public gameVersion?: number, 
        public courseVersion?: number,
        public descriptionHTML?: string,
        public gameDataHTML?: string,
        public isGameModified?: boolean,
        public gameImage?: ICEGameImage 
    ){}
}

export class ICEGameImage {
    constructor(
        public id?: string,
        public thumbnailImage?: string,
        public largeImage?: string,
        public title?: string,
    ){}      
}
