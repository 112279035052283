import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ServiceUrls } from '../../../util/service-urls';
import { WsCallback } from '../../../util/ws-callback.interface';
import { WsResponse } from '../../../util/ws-response.model';
import { WsType } from '../../../util/ws-type';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NewTopicMapService {

  constructor(private http:HttpClient) { }

  public createNewTopicMap(newTopicMapCreateRequest: string, callBack: WsCallback) {
    this.http.post(ServiceUrls.CREATE_TOPIC_MAP, newTopicMapCreateRequest).subscribe(
        data => {
            var modified = JSON.parse(JSON.stringify(data));
            var res = new WsResponse(
                modified.status.description,
                modified.status.code,
                modified.status.name,
                modified.payload);
            callBack.onSuccess(res, WsType.CREATE_TOPIC_MAP);
        },
        error => {
            if (error.status != '') {
                var val = (error as HttpErrorResponse).error;
                var modified = JSON.parse(JSON.stringify(val));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onFail(res, WsType.CREATE_TOPIC_MAP);
            } else {
                //browser related issues
                var res = new WsResponse("Unknown error happened");
                callBack.onFail(res, WsType.CREATE_TOPIC_MAP);
            }
        }
    );
  }
}
