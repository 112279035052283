<div class="main-panel pt-0 pb-4" style="overflow: hidden;">
	<div class="container-fluid">
		<div class="col-sm-12 px-0">
			<h3>
				{{ 'PROCEDURE_STORE.PS_SIDEBAR_DELETED_PROCEDURE' | translate }}
			</h3>
			<hr class="mt-0 mb-3" />
			<div class="col-sm-12 px-0">
				<ag-grid-angular #agGrid style="width: 100%; height: 400px;" id="deletedProcedureStore"
					[rowData]="rowData" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }" class="ag-theme-balham" [columnDefs]="columnDefs" 
					 [enableRangeSelection]="true" [paginationPageSize]="10" [pagination]="true" [defaultColDef]="defaultColDef"
					(columnResized)="onColumnResized($event)" (gridReady)="onGridReady($event)"
					[suppressMenuHide]="true" [gridOptions]="gridOptions"></ag-grid-angular>
			</div>
		</div>
	</div>
</div>
<!-- <div class="main-panel" [style.padding-top.px]="60" style="overflow: hidden;">   
   <div class="card-block card-body mt-2 pb-1 pt-1 card-primary" [ngClass]="{'content-hide': isMinimized, 'content-show': !isMinimized}">
      <ag-grid-angular #agGrid style="width: 100%; height: 400px;" id="deletedProcedureStore" [rowData]="rowData"  [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }" class="ag-theme-balham"
         [columnDefs]="columnDefs"  
         [enableRangeSelection]="true" [paginationPageSize]="50" [pagination]="true" (columnResized)="onColumnResized($event)"
         (gridReady)="onGridReady($event)" [suppressMenuHide]="true" [gridOptions]="gridOptions"></ag-grid-angular>
   </div>
</div> -->