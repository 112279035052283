import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { UserVariable } from '../../util/common/user-variable';
import { ServiceUrls } from '../../util/service-urls';
import { WsResponse } from '../../util/ws-response.model';
import { WsCallback } from '../../util/ws-callback.interface';
import { WsType } from '../../util/ws-type';
import { SharedService } from '../../util/shared.service';
import { CommonUtil } from 'src/app/util/common/common-util';

@Injectable()
export class FacilitiesService {

    public token: string;
    private callback: WsCallback;
    private rowData: any = null;
    private apiGridData: any = null;
    private selectedEditorButton: any = null;
	private addEditPanel: any;
	private facility : any;

    constructor(private http: HttpClient, private sharedService: SharedService, private commonUtil: CommonUtil) { }

    public setCallback(callBack: WsCallback) {
        this.callback = callBack;
    }

    /**
      * Call service method for facility serach using POST request.
      * @param facilityName Facility name
      * @param email Email address
      * @param secondaryEmail Secondary email address
      */
    searchFacilities(facilityName: String, email: String, secondaryEmail: String, hasText: String) {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        let url = this.getFacilitySearchURL(token);

        this.http.post(url, JSON.stringify(new Request(facilityName, email, secondaryEmail, hasText))).
            subscribe(
                data => {
                    var modified = JSON.parse(JSON.stringify(data));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                    this.callback.onSuccess(res, WsType.FACILITY_SEARCH_VIEW);
                },
                error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    this.callback.onFail(res, WsType.FACILITY_SEARCH_VIEW);
                });
    }

    /**
    * Define URL for get facility list by facility search criteria.
    */
    public getFacilitySearchURL(token: String) {
        let url = ServiceUrls.FACILITIES_SEARCH_VIEW + '?token=' + token;
        return url;
    }

    /**
     * Call service method to add facility using POST request.
     * @param facilityName Facility name
     * @param email Email address
     * @param secondaryEmail Secondary email address
     */
    addFacility(facilityName: String, email: String, secondaryEmail: String, callback: WsCallback) {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        let url = this.addFacilityURL(token);

        this.http.post(url, JSON.stringify(new Request(facilityName, email, secondaryEmail, ''))).
            subscribe(
                data => {
                    var modified = JSON.parse(JSON.stringify(data));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
						modified.payload);
						console.log("[addFacility] request was successful.")
                    callback.onSuccess(res, WsType.FACILITY_ADD);
                },
                error => {
					var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
						modified.error.payload);
						console.log("[addFacility] request was failed.")
                    callback.onFail(res, WsType.FACILITY_ADD);
                }
            );
    }

    public addFacilityURL(token: String) {
        let url = ServiceUrls.ADD_FACILITY + '?token=' + token;
        return url;
	}


	public setItemData() {
		if (this.apiGridData != null && this.apiGridData != undefined && this.facility.facilityId != null) {
			var rowNode = this.apiGridData.getRowNode(this.facility.facilityId);
			console.log("row node " + rowNode);
			if (rowNode != null && rowNode != undefined) {
				rowNode.setDataValue("facilityName", this.facility.facilityName);
				rowNode.setDataValue("email", this.facility.emailAddress);
				rowNode.setDataValue("secondaryEmail", this.facility.secondaryEmailAddress);
			}
		}
	}
	

    public disableEnableFileTableData(fileId: any, title: String, status: String) {
        if (this.apiGridData != null && this.apiGridData != undefined && fileId != null) {
            var rowNode = this.apiGridData.getRowNode(fileId);
            if (rowNode != null && rowNode != undefined) {
				console.log("title " + title)
                if (title == 'Do you want to disable') {
                    this.sharedService.disableCellRendererRequest(true, fileId);
                    rowNode.setDataValue("status", "status:" + fileId + ":Inactive");
                    rowNode.setDataValue("delete", "delete:" + fileId + ":Inactive");
                } else if (title == 'Do you want to enable?') {
                    this.sharedService.disableCellRendererRequest(false, fileId);
                    rowNode.setDataValue("status", "status:" + fileId + ":Active");
                    rowNode.setDataValue("delete", "delete:" + fileId + ":Active");
				}
				else if (title == 'Enable Confirm Facility') {
					console.log("Enable Confirm")
                    this.sharedService.disableCellRendererRequest(false, fileId);
                    rowNode.setDataValue("status", "status:" + fileId + ":Active");
                    rowNode.setDataValue("delete", "delete:" + fileId + ":Active");
				}
				else if (title == 'Disable Confirm Facility') {
					console.log("Disable Confirm")
                    this.sharedService.disableCellRendererRequest(true, fileId);
                    rowNode.setDataValue("status", "status:" + fileId + ":Inactive");
                    rowNode.setDataValue("delete", "delete:" + fileId + ":Inactive");
                }
                else {
                    if (status == "Inactive") {
                        this.sharedService.disableCellRendererRequest(false, fileId);
                        rowNode.setDataValue("status", "status:" + fileId + ":Active");
                        rowNode.setDataValue("delete", "delete:" + fileId + ":Active");
                    }
                    else {
                        this.sharedService.disableCellRendererRequest(true, fileId);
                        rowNode.setDataValue("status", "status:" + fileId + ":Inactive");
                        rowNode.setDataValue("delete", "delete:" + fileId + ":Inactive");
                    }
                }
            }
        }
	}
	
	setFacility(facilityModel :any){
		this.facility = facilityModel;
	}

    /**
	 * Update facility. 
	 * @param facilityModel.
	 * @param facilityId. 
	 */
    updateFacility(facilityModel: String, id: String, callBack: WsCallback) {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        const options = { params: new HttpParams().set("token", token) }
        let url = ServiceUrls.FACILITIES + id + '.json'
        this.http.put(url, facilityModel, options).subscribe(
            data => {
                // this may be incorrect
                //var val = (data as HttpErrorResponse).message;
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.FACILITY_UPDATE);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.FACILITY_UPDATE);
                } else {
                    //browser related issues
                    var res = new WsResponse("Unknown error happened");
                    callBack.onFail(res, WsType.FACILITY_UPDATE);
                }
            }
        );

    }

    public deleteFileTableData(fileId: any) {
        if (this.apiGridData != null && this.apiGridData != undefined && fileId != null) {
            var selectedData = this.apiGridData.getSelectedRows();
            if (selectedData != null && selectedData != undefined) {
                this.apiGridData.applyTransaction({ remove: selectedData });
                let newRowData:any = [];
				this.apiGridData.forEachNode( function(rowNode, index) {
					newRowData.push(rowNode.data);
				});
				this.setRowData(newRowData);
            }
        }
    }

    /**
    * Get statement data. 
    * @param fileId.
    */
    getFacilityDataByFacilityId(callBack: WsCallback) {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        const options = { params: new HttpParams().set("token", token) }
        let url = ServiceUrls.FACILITIES + this.sharedService.getFileId() + '/show.json'
        this.http.get(url, options).
            subscribe(
                data => {
                    var modified = JSON.parse(JSON.stringify(data));
                    var res = new WsResponse(
                        modified.status.code,
                        modified.status.name,
                        modified.status.description,
                        modified.payload);
                    //console.log("response " + JSON.stringify(data));
                    callBack.onSuccess(res, WsType.FACILITY_DATA_VIEW);
                },
                error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.status.code,
                        modified.status.name,
                        modified.status.description,
                        modified.payload);
                    callBack.onSuccess(res, WsType.FACILITY_DATA_VIEW);
                });
    }

    /**
    * Define URL for delete statement.
    */
    deleteFacility(id: any, callBack: WsCallback) {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        let url = ServiceUrls.FACILITIES + id + '.json'
        const options = { params: new HttpParams().set("token", token) }
        this.http.delete(url, options).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.DELETE_ITEM);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.DELETE_ITEM);
                } else {
                    //browser related issues
                    var res = new WsResponse("Unknown error happened");
                    callBack.onFail(res, WsType.DELETE_ITEM);
                }
            }
        );
    }

    public setSelectedEditorButton(selectedEditorButton: any) {
        //console.log("seee" + selectedEditorButton)
        this.addEditPanel.data = selectedEditorButton;
    }

    public getSelectedEditorButton() {
        return this.selectedEditorButton;
    }

    public setApiGridData(apiGridData: any) {
        this.apiGridData = apiGridData;
    }

    public getApiGridData() {
        return this.apiGridData;
    }

    public setRowData(rowData: any) {
        this.rowData = rowData;
    }

    public getRowData() {
        return this.rowData;
    }
}

class Request {

    constructor(private facilityName: String, private emailAddress: String, private secondaryEmailAddress: String, private hasText: String) { }

}
