<div class="container-fluid page-container">
    <nav class="page-header">
        <div class="row">
            <div class="col">
                <p class="mb-2 h5">
                    <b>Receive Checksheets</b>
                </p>
            </div>
        </div>
    </nav>
    <div class="row">
        <div class="col-sm-12 mb-2">
            <symbiosis-search [comp]="'receivechecksheets'" id="receive_cs_search" (searchFunc)="searchFunc($event)"></symbiosis-search>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col">
            <ag-grid-angular #agGrid style="width: 100%; height: 375px;" class="ag-theme-balham" [rowData]="rowData"
                [columnDefs]="columnDefs" [gridOptions]="gridOptions"
                [sideBar]="{ toolPanels: ['columns'] }" [pagination]="true" [paginationPageSize]="10"
                [overlayLoadingTemplate]="overlayLoadingTemplate" [overlayNoRowsTemplate]="overlayNoRowsTemplate"
                [getRowNodeId]="getRowNodeId" (gridReady)="onGridReady($event)">
            </ag-grid-angular>
        </div>
    </div>
</div>