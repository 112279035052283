import { Component, EventEmitter, OnInit } from '@angular/core';
import { CapabilityService } from 'src/app/util/capability.service';

@Component({
	selector: 'app-ds-sidebar',
	templateUrl: './ds-sidebar.component.html',
	styleUrls: ['./ds-sidebar.component.scss'],
	outputs: ['selectSideBarItem']
})
export class DsSidebarComponent implements OnInit {

	public selectedMenuItem: string = 'documentstore'
	selectSideBarItem = new EventEmitter<any>()

	public addDocumentToStore: boolean = false;
	public viewProcedureInstanceOutputList: boolean = false

	constructor(private capabilityService: CapabilityService) { 
		this.addDocumentToStore = this.capabilityService.isCapabilityAssigned("addDocumentToStore");
		this.viewProcedureInstanceOutputList = this.capabilityService.isCapabilityAssigned("viewProcedureInstanceOutputList");
	}

	ngOnInit() {
	}

	public sideItemClick(selectedItem: string) {
		this.selectedMenuItem = selectedItem
		this.selectSideBarItem.emit(
			{
				selectedItem: selectedItem
			}
		)
	}

}
