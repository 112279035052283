import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Auth0Service } from './../../auth0.service';
import { SharedService } from 'src/app/util/shared.service'
@Component({
  selector: 'app-auth0',
  templateUrl: './auth0.component.html',
  styleUrls: ['./auth0.component.css']
})
export class Auth0Component implements OnInit {
  private _idToken: string;

  constructor(private auth0: Auth0Service, private router: Router, private sharedService: SharedService) {

    this._idToken = localStorage.getItem("Auth0IdToken");
  }

  async ngOnInit() {
    this.authLogin();
    this.router.navigateByUrl('/login');
  }

  public authLogin() {
    this.auth0.parseAccessToken().then(
      (data) => {
        let userEmail = JSON.parse(JSON.stringify(data)).idTokenPayload.email;
        let email_verified = JSON.parse(JSON.stringify(data)).idTokenPayload.email_verified;
        this.sharedService.auth0LoginRequest(userEmail, email_verified);

      }, (err) => {
      });
  }
}
