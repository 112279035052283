import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GridOptions, Module, AllModules } from "@ag-grid-enterprise/all-modules";
import { LoadingService } from '../../../../util/loading/loading.service';
import { DocumentTypesService } from '../document-types.service';
import { AlertService } from '../../../../util/alert/alert.service';
import { DTADeleteButtonRenderer } from '../document-attributes/cell-renderer/delete-button/delete-button.component'
import { DTAEditButtonRenderer } from '../document-attributes/cell-renderer/edit-button/edit-button.component'
import { DTADependencyButtonRenderer } from '../document-attributes/cell-renderer/dependency-button/dependency-button.component'
import { CapabilityService } from '../../../../util/capability.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AddDocAttributeTypeModalComponent } from './add-attribute-modal.component'
import { SharedService } from 'src/app/util/shared.service';
import { EditDocAttributeTypeModalComponent } from './cell-renderer/edit-button/edit-modal-view.components';
import { Subscription } from 'rxjs';
import { DependenciesModalService } from './../../../../util/dependenciesmodal/dependenciesmodal.service';
import { Constants } from 'src/app/util/constants';
import { WsResponse } from 'src/app/util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';

@Component({
    selector: 'app-document-attributes',
    templateUrl: './document-attributes.component.html',
    styleUrls: ['./document-attributes.component.css']
})
export class DocumentAttributesComponent implements OnInit {

    public gridApi;
    public gridColumnApi;
    public gridOptions: GridOptions;
    public columnDefs;
    public rowSelection;
    public rowData: any = [];
   public modules: Module[] = AllModules;
    public getRowNodeId: any;
    public frameworkComponents;
    doctype_translated_data: any = null;
    private subsVar: Subscription

    public bsModalRef: BsModalRef;

    public createDocTypeAttribute: boolean = false

    constructor(private translate: TranslateService, private loadingService: LoadingService, private documentTypesService: DocumentTypesService, 
      private alertService: AlertService, private capabilityService: CapabilityService, private modalService: BsModalService, private sharedService: SharedService,
      private dependenciesmodalService: DependenciesModalService) {
        let browserLang = translate.getBrowserLang();
        translate.getTranslation(browserLang).subscribe((res: string) => {
            this.doctype_translated_data = res['DOCUMENT_TYPE']
            this.columnDefs = [
               { headerName: this.doctype_translated_data.DEPENDENCIES, field: 'dependencies', cellClass: "cell-wrap-text", autoHeight: true, cellRendererFramework: DTADependencyButtonRenderer},
                { headerName: this.doctype_translated_data.ATTRIBUTE_NAME, field: 'attributeName', filter: "agTextColumnFilter", cellClass: "cell-wrap-text", autoHeight: true, },
                { headerName: this.doctype_translated_data.DEFAULTY_TYPE, filter: "agTextColumnFilter", field: 'defaultType', cellClass: "cell-wrap-text", autoHeight: true, },
                { headerName: this.doctype_translated_data.EDIT, field: 'type', cellClass: "cell-wrap-text", autoHeight: true, cellRendererFramework: DTAEditButtonRenderer},
                { headerName: this.doctype_translated_data.DELETE, field: 'type', cellClass: "cell-wrap-text", autoHeight: true, cellRendererFramework: DTADeleteButtonRenderer},
            ];
        });

        this.createDocTypeAttribute = this.capabilityService.isCapabilityAssigned("createDocTypeAttribute");
        this.gridOptions = {
            rowData: this.rowData,
            columnDefs: this.columnDefs,
            defaultColDef: {
               resizable: true,
               sortable: true,
            },
            rowSelection: "single",
            rowMultiSelectWithClick: false,
        };
        
        this.getRowNodeId = function (data) {
			  return data.id;
		  };
    }

    ngOnInit() {
      this.subsVar = this.sharedService.openDocumentAtributeEditRespond().subscribe(() => this.editConfirm())

    }     
    
    ngAfterViewInit() {
       this.sharedService.loadAttributeListEventResponse().subscribe(() => {
          this.doViewAttributeList();
       })
    }
    ngOnDestroy(){
       this.subsVar.unsubscribe();
       window.removeEventListener("resize", this.onWindowResize);
       this.gridApi.destroy();
       this.gridApi = null;
    }

    onGridReady(params) {
		this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.doViewAttributeList();
      this.documentTypesService.setApiGridData(this.gridApi);
		setTimeout(() => {
			params.api.sizeColumnsToFit();
		}, 500);
		// window.addEventListener("resize", function () {
		// 	setTimeout(function () {
		// 		params.api.sizeColumnsToFit();
		// 	});
		// });
      window.addEventListener("resize", this.onWindowResize);
   }

   private onWindowResize = () => {
   
		setTimeout(() => {
			if (this.gridApi) {
				this.gridApi.sizeColumnsToFit();
			}
		}); 
	};
   
   onColumnResized(event) {
      if (event.finished) {
         this.gridApi.resetRowHeights();
      }
   }

   public doViewAttributeList(){
      this.loadingService.showLoading(true, null, "", null)
      this.documentTypesService.doViewAttributeList()
         .subscribe(data => {
            this.loadingService.hideLoading()
            data.payload.forEach(element => {
               element["id"]=element.attributeId;
               element["dependencies"]="disable: true";
               this.dependenciesmodalService.setCallback(this);
               this.dependenciesmodalService.getDependeciesWithId(element.attributeId, Constants.DEPENDENCY_ITEM_TYPE.DOCUMENTTYPE);
            });
            this.rowData = []          
            if(data.payload != null){
               this.rowData = data.payload;
               this.gridApi.sizeColumnsToFit();
            }else{
               //nothing to do here
            }            
         },
            error => {
               this.loadingService.hideLoading()
               this.alertService.clear()
               this.alertService.error(error.statusDescription)
            });
   }

   onSuccess(data: WsResponse, serviceType: WsType): void {      
      if (data.payload != null && serviceType == WsType.ITEM_DEPENDENCY_LIST) {
         let items = data.payload.split("_")
		   if(items[1]=="Yes")
		   {
				for(let i=0; i<this.rowData.length; i++)
				{
					if(this.rowData[i].id == items[0])
					{
						this.rowData[i]["dependencies"] = "disable: false"
                  this.gridApi.setRowData(this.rowData);
					}
				}
		   }
      }
   }

   onFail(res: WsResponse, serviceType: WsType): void {
   }

   public disabledAddBtn() {
		if (this.createDocTypeAttribute) {
         return false
      }else{
         return true
      }
   }
   
   public addAttribute(){
      this.bsModalRef = this.modalService.show(AddDocAttributeTypeModalComponent, { class: 'modal-lg' });
   }

   public editConfirm(){
      this.loadingService.showLoading(true, null, "", null)
        this.documentTypesService.doViewAttribute(this.sharedService.getFileId())
        .subscribe(data => {
           this.loadingService.hideLoading()
           const initialState : object = { 
              attributeType: data.payload.attributeType,
              id: data.payload.id,
              name: data.payload.name
            }          
           this.bsModalRef = this.modalService.show(EditDocAttributeTypeModalComponent, { class: 'modal-lg', initialState });
        },
        error => {
           this.loadingService.hideLoading()
           this.alertService.error(error.statusDescription)
        });
    }

    
}
