import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FilesService } from '../files.service';
import { AlertService } from '../../../util/alert/alert.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from '../../../util/loading/loading.service';
import { GridOptions, Module, AllModules } from "@ag-grid-enterprise/all-modules";
import * as FileSaver from 'file-saver';
import { BsModalService } from 'ngx-bootstrap/modal';
import { OPGConfigInputsViewModalComponent } from './ouputconfiginputsviewmodal.component'

@Component({
   selector: 'outputgenerator-modal',
   template: `<div class="modal-header">
               <h4 class="modal-title pull-left">{{title}}</h4>
               <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
                     <span aria-hidden="true">&times;</span>
               </button>
               </div>
               <div class="modal-body">
                  <form>
                  <div class="form-group row">
                  <label for="name" class="col-sm-2 col-form-label">{{ 'Output Type' | translate }}</label>         
                     <div class="col-sm-10">
                        <ng-select [active]="selectedOutputType" [allowClear]="true" (removed)="removedOutputId($event)"
                        (selected)="selectedOutputId($event)" [items]="selectedOutputType" name="outputType" id="outputType">
                        </ng-select>
                     </div>
                  </div>
                  <div class="form-group row" style="margin-left: 0px;margin-right: 0px;" *ngIf="showTable">
                     <ag-grid-angular #agGrid style="width: 100%; height: 400px;" id="outputList" [rowData]="outputData" class="ag-theme-balham"
                     [columnDefs]="columnDefs" [frameworkComponents]="frameworkComponents" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }" 
                     [paginationPageSize]="10" [pagination]="false" (columnResized)="onColumnResized($event)" [defaultColDef]="defaultColDef"
                     (gridReady)="onGridReady($event)" [suppressMenuHide]="true" [gridOptions]="gridOptions"></ag-grid-angular>
                  </div>
                  <div class="form-group row">
                  <label for="name" class="col-sm-2 col-form-label">{{ 'Template' | translate }}</label>         
                     <div class="col-sm-10">
                        <ng-select [active]="selectedTemplateName" [allowClear]="true" (removed)="removedTemplateId($event)"
                        (selected)="selectedTemplateId($event)" [items]="selectedTemplateName" name="tempName" id="tempName">
                        </ng-select>
                     </div>
                  </div>
                  </form>
               </div>
            <div class="modal-footer">  
                  <button type="button" class="btn btn-primary" (click)="next()">{{ 'Next' | translate}}</button>
                  <button type="button" class="btn btn-secondary" (click)="closeButtonClickEvent()">{{ 'CANCEL' | translate}}</button>
            </div>`
})

export class OutputGenerateModalComponent {
   om_translated_data: any = null;
   public loadingMessage: string;
   public title: string = "Output Generator"
   public selectedOutputType: Array<any>;
   public selectedTemplateName: Array<any>;
   public outputData: any[] = [];
   public outputSelectedData: any[] = [];
   public outputId: string;
   public outputType: string;
   public templateId: string;
   public idList: string[] = [];
   public type: string;
   public itemConfigList: any[] = [];
   public invisibleItemConfigList: any[] = [];
   public facilityList: Array<any> = [];
   public systemList: Array<any> = [];
   public documentList: Array<any> = [];
   public itemList: Array<any> = [];
   public multipleLogoList: Array<any> = [];
   public selectedTempName: string;
   public showTable : boolean;
   columnDefs;
   public defaultColDef: any;
   public modules: Module[] = AllModules;
   private gridApi;
   public gridOptions: GridOptions;
   private gridColumnApi;
   public frameworkComponents;
   public selectedFileName: string;
   public sendMail: string = null;

   constructor(public bsModalRef: BsModalRef, private filesService: FilesService, private alertService: AlertService, private router: Router,
      private translate: TranslateService, private loadingService: LoadingService, private modalService: BsModalService) {

      let browserLang = this.translate.getBrowserLang();
      this.translate.getTranslation(browserLang).subscribe((res: string) => {

      });

      this.defaultColDef = {
			resizable: true,
			sortable: true,
		}

      this.columnDefs = [
         {
            headerName: "User", field: 'initiatedUserFullName', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 200, suppressFiltersToolPanel: true,
         },
         {
            headerName: "Template", field: 'templateName', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 130, suppressFiltersToolPanel: true,
         },
         {
            headerName: "Time", field: 'completedTime', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 140,
         },
         {
            headerName: "View", field: '', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 75, suppressFiltersToolPanel: true,
            cellRenderer: function (params) {
               var eDiv = document.createElement('div');
               eDiv.innerHTML = '<button class="btn btn-sm btn-outline-dark py-0 my-1 px-0 border-0">' +
                  '<i class="fa fa-eye mx-2" aria-hidden="true"></i>' +
                  '</button>';

               var eImg = document.createElement('i');    
               eImg.innerHTML= '<i class="fa fa-arrow-right" aria-hidden="true">'+'</i>';

               var eButton = eDiv.querySelectorAll('.btn-sm')[0];
               eButton.addEventListener('click', function () {
                  loadingService.showLoading(true, null, "Downloading", null)
                  filesService.downloadFile(params.data.outputId, params.data.outputFile)
                     .subscribe(response => {
                        loadingService.hideLoading()
                        let file = new Blob([response]);
                        if (params.data.outputFriendlyName != null) {
                           FileSaver.saveAs(file, params.data.outputFriendlyName);
                        } else {
                           FileSaver.saveAs(file, params.data.outputFile);
                        }
                     },
                        error => {
                          // alertService.error(this.fa_translated_data.ERR_DOWNLOAD_FILE)
                          alertService.error("Error downloading file!");
                        });
               });
               if(params.data.processStatus == 'File Issued'){
                  eDiv.appendChild(eImg);
                  return eDiv;
               }else{
                  return eDiv;
               }
            }
         },
      ]
   }

   ngOnInit() {
      this.getOutputdataBySelectedValue()
   }

   public next() {
      if (this.outputId == undefined) {
         this.alertService.error("Pleas Select Output Type")
      } else if (this.templateId == undefined) {
         this.alertService.error("Pleas Select Template")
      } else {
         this.loadingService.showLoading(true, null, "", null)
         this.filesService.getTemplateConfigInputs(this.idList[0], this.type, this.outputType, this.templateId)
            .subscribe(data => {
               this.loadingService.hideLoading()
               this.closeButtonClickEvent()
               this.itemConfigList = []
               if (data.payload != null) {
                  if (data.payload.documentConfigList != null) {
                     data.payload.documentConfigList.forEach(element => {
                        if (element.display == '1') {
                           element.parameterValue = this.decodeHTMLEntities(element.parameterValue);
                           this.itemConfigList.push(element);
                        } else {
                           this.invisibleItemConfigList.push(element);
                        }
                     });
                  } else {
                     //nothing happen
                  }
                  if (data.payload.globalConfigList != null) {
                     data.payload.globalConfigList.forEach(element => {
                        if (element.display == '1') {
                           this.itemConfigList.push(element);
                        } else {
                           this.invisibleItemConfigList.push(element);
                        }
                     });
                  } else {
                     //nothing happen
                  }
                  if (data.payload.projectConfigList != null) {
                     data.payload.projectConfigList.forEach(element => {
                        if (element.display == '1') {
                           if(element.hasOwnProperty('itemList')){
                              if(element.itemList!='' || element.itemList!=null||element.itemList!=undefined){
                              let valuesArray = element.itemList.split('\n').filter(value => value.trim().length > 0);

                              valuesArray.forEach(line => {
                                 // Split each line by comma to separate ID and value
                                 let parts = line.split(', ');
                                 // Extract the ID and value
                                 let id = parts[0].split(': ')[1].trim();
                                 let value = parts[1].split(': ')[1].trim();
                             
                                 // Create an object and push it to the itemList array
                                 if (id && value) {
                                    this.itemList.push({ id: id, text: value });
                                 }
                             });




                           }
                           } 
                           if(element.hasOwnProperty('multipleLogoList')){
                              if(element.multipleLogoList!='' || element.multipleLogoList!=null||element.multipleLogoList!=undefined){
                                 let valuesArray = element.multipleLogoList.split('\n').filter(value => value.trim().length > 0);
   
                                 valuesArray.forEach(line => {
                                    // Split each line by comma to separate ID and value
                                    let parts = line.split(', ');
                                    // Extract the ID and value
                                    let id = parts[0].split(': ')[1].trim();
                                    let value = parts[1].split(': ')[1].trim();
                                    let path = parts[2].split(': ')[1].trim();
                                    // Create an object and push it to the itemList array
                                    if (id && value) {
                                       this.multipleLogoList.push({ id: id, text: value, path: path });
                                    }
                                });
   
   
   
   
                              }

                           }
                           
                           this.itemConfigList.push(element);
                        } else {
                           this.invisibleItemConfigList.push(element);
                        }
                     });
                  } else {
                     //nothing happen
                  }
                  if (data.payload.facilityList != null) {
                     for (let key of Object.keys(data.payload.facilityList)) {
                        //if (data.payload.facilityList[key] != 'Select') {
                           this.facilityList.push({ id: key, text: data.payload.facilityList[key] })
                        //}
                     }
                  } else {
                     //nothing happen
                  }
                  if (data.payload.systemList != null) {
                     for (let key of Object.keys(data.payload.systemList)) {
                           this.systemList.push({ id: key, text: data.payload.systemList[key] })
                     }
                  } else {
                     //nothing happen
                  }
                  if (data.payload.documentList != null) {
                     for (let key of Object.keys(data.payload.documentList)) {
                        //if (data.payload.documentList[key] != 'Select') {
                           this.documentList.push({ id: key, text: data.payload.documentList[key] })
                        //}
                     }
                  } else {
                     //nothing happen
                  }
               } else {
                  // response is null
               }
               //if (this.itemConfigList.length > 0) {
                  const initialState = {
                     itemConfigList: this.itemConfigList,
                     facilityList: this.facilityList,
                     itemList: this.itemList,
                     multipleLogoList: this.multipleLogoList,
                     systemList: this.systemList,
                     documentList: this.documentList,
                     itemIdList: this.idList,
                     item: this.type,
                     outputType: this.outputType,
                     templateId: this.templateId,
                     projectmapId: data.payload.id,
                     isEnabled_CreateDraftDocuments: data.payload.isEnabled_CreateDraftDocuments,
                     isEnabled_SaveOutputTemplateParams: data.payload.isEnabled_SaveOutputTemplateParams,
                     invisibleItemConfigList: this.invisibleItemConfigList,
                     selectedFileName: this.selectedFileName,
                     sendMail: this.sendMail
                  };
                  this.bsModalRef = this.modalService.show(OPGConfigInputsViewModalComponent, { class: 'modal-lg', initialState });
                  // this.bsModalRef.content.itemConfigList = this.itemConfigList;
                  // this.bsModalRef.content.facilityList = this.facilityList;
             
            },
               error => {
                  this.loadingService.hideLoading()
                  this.alertService.clear()
                  this.alertService.error(error.statusDescription)
               });

      }

   }

   closeButtonClickEvent() {
      this.bsModalRef.hide()
   }

   selectedOutputId(event) {
      this.outputId = event.id
      this.outputType = event.text
      this.getOutputdataBySelect()
   }

   removedOutputId(event) {

   }

   selectedTemplateId(event) {
      this.templateId = event.id
   }

   removedTemplateId(event) {

   }

   onColumnResized(event) {
      if (event.finished) {
         this.gridApi.resetRowHeights();
      }
   }

   onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      params.api.sizeColumnsToFit();
      setTimeout(function () {
         params.api.sizeColumnsToFit();
         params.api.resetRowHeights();
      }, 500);
   }

   getOutputdataBySelect() {
      this.loadingService.showLoading(true, null, "", null)
      this.filesService.getTemplateForDoc(this.idList[0], this.type, this.outputType)
         .subscribe(data => {
            this.loadingService.hideLoading()
            this.outputSelectedData = []
            this.selectedTemplateName = []
            if (data.payload != null) {
               data.payload.forEach(element => {
                  if (element.outputId == null) {
                     if(element.templateName != null && element.templateName != ""){
                        this.selectedTemplateName.push({ 'id': element.projectTemplateMapId, 'text': element.templateName })
                     }
                  } else {
                     this.outputSelectedData.push(element)
                  }
               });
               this.selectedTempName = this.selectedTemplateName[0].text
               this.templateId = this.selectedTemplateName[0].id
               if (this.outputSelectedData.length > 0) {
                  this.outputData = this.outputSelectedData
               } else {
                  this.outputData = []
               }
            } else {
            }
         },
            error => {
               this.loadingService.hideLoading()
               this.alertService.clear()
               this.alertService.error(error.statusDescription)
            });
   }

   getOutputdataBySelectedValue() {
      this.loadingService.showLoading(true, null, "", null)
      this.filesService.getTemplateForDoc(this.idList[0], this.type, this.outputType)
         .subscribe(data => {
            this.loadingService.hideLoading()
            this.outputSelectedData = []
            this.selectedTemplateName = []
            if (data.payload != null) {
               data.payload.forEach(element => {
                  if (element.outputId == null) {
                     if(element.templateName != null && element.templateName != ""){
                        this.selectedTemplateName.push({ 'id': element.projectTemplateMapId, 'text': element.templateName })
                     }
                  } else {
                     this.outputSelectedData.push(element)
                  }
               });
               this.selectedTempName = this.selectedTemplateName[0].text
               this.templateId = this.selectedTemplateName[0].id
               if (this.outputSelectedData.length > 0) {
                  this.outputData = this.outputSelectedData
               } else {
                  this.outputData = []
               }
            } else {

            }
         },
            error => {
               this.loadingService.hideLoading()
               this.alertService.clear()
               this.alertService.error(error.statusDescription)
            });
   }

   //decode html entities
   private decodeHTMLEntities(str: string) {
      if(str != null && str != undefined) {
         str = str.toString().replace( /&apos;/g,"'");
         str = str.toString().replace( /&gt;/g,">");
         str = str.toString().replace( /&lt;/g,"<");
         str = str.toString().replace(/&bsol;/g, "\\");
         str = str.toString().replace( /&quot;/g,"\"");
         str = str.toString().replace( /&amp;/g,"&");
      }
      return str;
   }
}
