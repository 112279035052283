import { Injectable } from "@angular/core";
import { Constants} from './constants';

@Injectable({providedIn: 'root'})
export class LocalStorage {
    constructor(){}
    public static LOGGED_USER: string = "logged_in_user";
    public static DEVICE_ID: string = "device_id";
    public static USER_ID: string = "user_id";
    public static PROJECT_NAME:string = "selectedProjectName";
    public static DEFAULT_PROJECT_NAME = "default_project_name";
    public static CLIENT_NAME:string = "selectedClientName";
    //public static REQUESTED_PATH:string = "requested_path";
    public static GOOGLE_API_KEY:string = "g_api_key";
    public static GOOGLE_CLIENT_ID:string = "g_client_id";
    //public static CLIENT_ID:string = "clientId";//dont use this, get client id from the token
    //public static USER_TOKEN:string = "userToken";//never use this
    public static PREFERENCES:string = "preferences";
    public static IS_CLIENT:string = "isClient";
    public static NOTIFICATION_COUNT:string = "notification_count";
    public static CAPABILITY_LIST: string = "capability_list"
    public static CLIENT_ID: string = "client_id";
    public static DEFAULT_CLIENT_ID = "default_client_id";
	public static OLD_PROJECT_ID: string = "old_project_id"
	public static OLD_PROJECT_NAME: string = "old_project_name"
	public static OLD_CLIENT_ID: string = "old_client_id"
	public static CURRENT_PROJECT_ID:string = "current_project_id"
    public static PROJECT_ID: string = "project_id";
    public static DEFAULT_PROJECT_ID: string = "default_project_id";
    public static DEFAULT_PROJECT_TYPE: string ="default_project_type";
	public static PROJECT_TYPE: string = "project_type";
	public static SELECTED_ITEMS: string = "selected_items";
    public static DICTIONARY: string = "wsc_user_dictionary";
    public static SELECTED_LIST: string = "selected_list";
    public static SELECTED_ROWS: string = "selected_rows";
    public static FOLDER_ID: string = "folder_id";
    public static DEVICE_TYPE: string = Constants.DEVICE_TYPES.DESKTOP
    public static IS_ADMINSETTINGS_CLICKED: string = "isAdminSettingsClicked";
    public static USER_ACCESSIBILITY: string = "user_accessibility";
    public static AUTH0_CONFIGURATIONS:string = "auth0_configs";
    
}
