<div class="flex-row" id="proc_editor" style="overflow-y: auto; overflow-x: hidden" [style.height.px]="divHeight">
	<div class="container-fluid my-2">
<!-- <div class="container-fluid px-0 pb-5" style="overflow-y: auto; overflow-x: hidden" [style.height.px]="divHeight"> -->
		<!-- breadcrumb -->
		<!-- <nav aria-label="breadcrumb my-1">
			<ol class="breadcrumb my-0 px-2 py-1">
				<li class="breadcrumb-item active" aria-current="page" (click)="backtoCourse()">Sample Course</li>
			</ol>
		</nav> -->
		<nav aria-label="breadcrumb my-1">
			<ol class="breadcrumb my-0 px-2 py-1">
				<li class="breadcrumb-item active" *ngFor="let breadCrumbListItem of breadCrumbList ; let i = index"   aria-current="page" (click)="clickBreadCrumb(breadCrumbListItem,i)">
				{{ breadCrumbListItem.title}}
				</li>
				<li class="currentItem breadcrumb-item">{{gameDescriptionShowInDetails}}</li>
			</ol>
		</nav>
		<div class="card my-2">
			<div class="card-header py-1">
				<p class="col-sm-7 my-1 float-left h6  px-0">
					{{ 'ICE_GAME.MEMORY_GAME.GAME_DETAILS' | translate }}
				</p>
				<button class="btn btn-sm btn-dark float-right mx-2" type="button" 
							[disabled]="isViewOnly || !gameEnabled " (click) = "gameDataSave()" tooltip ="Save Game" >
							<i class="fal fa-save"></i>
				</button>
				<button class="btn btn-sm btn-dark float-right mx-2" type="button" 
							(click)="openCancelConfirmationModel(closeConfirmModel)" tooltip ="Cancel" >
							<i class="fas fa-times"></i>
				</button>
			</div>
			<div class="card-body">
				<!-- game description -->
					<form>
						<div class="form-group">
							<label for="gameDescription">
								{{ 'ICE_GAME.MEMORY_GAME.TITLE_LABEL' | translate }}
							</label>
							<div class="badge-required mx-2" style="position: relative"></div>
							<div class="border rounded">
								<div rows="2" contenteditable="false"
									class="toggle-edit{{tabId}}{{userId}} games_ck_editor{{tabId}} desc_ck_editor{{tabId}} gaeditor"
									required id="gameDescription">
								</div>
							</div>
						</div>
						<div class="form-row my-1 align-items-end">
							<div class="col-sm-6">
								<div class="form-group mb-1">
									<label class="col-sm-12 px-0 mb-1">
										{{ 'ICE_GAME.MEMORY_GAME.GAME_IMAGE.TITLE_LABEL' | translate }}
									</label>

									<input (keydown)="returnTyping()" *ngIf="iceGame.gameImage == null" type="text"
									class="form-control form-control-sm" [(ngModel)]="iceGame.gameImage" name="gameImage"
									id="gameImage" placeholder="{{ 'ICE_GAME.MEMORY_GAME.GAME_IMAGE.TITLE_PLACEHOLDER' | translate }}"
									(click)="addGameImage()" [disabled]="isViewOnly || !gameEnabled">
										<div *ngIf="iceGame.gameImage != null" class="card" style="width: 16rem; ">
											<div class="card-img-overlay py-0 pl-0 pr-1"
												(click)="showLargeImage(largeImage, iceGame.gameImage.id)">
												<button type="button" class="close float-right" aria-label="Close"
													(click)="$event.stopPropagation();removeGameImage()"
													[disabled]="isViewOnly || !gameEnabled">
													<span aria-hidden="true">&times;</span>
												</button>
											</div>
											<img class="img-fluid" style="width: 16rem;"
												src="data:image/jpeg;base64,{{iceGame.gameImage.largeImage}}"
												alt="{{iceGame.gameImage.title}}">
										</div>
								</div>
							</div>
						</div>
					</form>
				<hr />
				<!-- game data panel -->
				<div class="my-2" *ngIf="gameGenarated">
					<div class="form-group form-row mb-2" *ngFor="let row of [].constructor(numOfRows); let i = index">
						<div class="col-sm-2 col-sm-25 p-1" *ngFor="let seq of [].constructor(numOfSequences); let x = index">
							<div class="card card-secondary p-2" style="background-color: #f1f1f1;" *ngIf="gameData.qaPanels != undefined">
								<div class="card eing-card-body mb-1" style="background-color: #fafafa;border: 0px">
									<div class="card-header p-1" style="background-color: #fafafa;border: 0px">
										<button id="btnClearQ_{{i}}_{{x}}" type="button" class="my-1 mx-2 close float-right" (click)="clearContent(i,x,'q')" 
										tooltip ="Clear" [disabled]="isViewOnly || !gameEnabled ">
											<i class="far fa-eraser"></i>
										</button>
										<button id="btnAddQText_{{i}}_{{x}}" type="button" class="my-1 mx-2 close float-right" (click)="addText(i,x,'q')" 
										tooltip ="Add Text" [disabled]="isViewOnly || !gameEnabled ">
											<i class="far fa-font"></i>
										</button>
										<button id="btnAddQImage_{{i}}_{{x}}" type="button" class="my-1 mx-2 close float-right" (click)="addPicture(i,x,'q')" 
										tooltip ="Add Image" [disabled]="isViewOnly || !gameEnabled ">
											<i class="far fa-image"></i>
										</button>
									</div>
									<div class="card-body" id="questionDiv_{{i}}_{{x}}" *ngIf= "gameData.qaPanels[i+'_'+tabId] != undefined && !gameData.qaPanels[i+'_'+tabId].qaPanelData[x+'_'+tabId].questionIsText && !gameData.qaPanels[i+'_'+tabId].qaPanelData[x+'_'+tabId].questionIsImage">
									</div>
									<div *ngIf= "gameData.qaPanels[i+'_'+tabId] != undefined && gameData.qaPanels[i+'_'+tabId].qaPanelData[x+'_'+tabId].questionIsText" id="questionDiv_{{i}}_{{x}}">
										<div class="card-body">
											<div class="border rounded">
												<div rows="2" contenteditable="false" id="question_{{i}}_{{x}}"
													class="toggle-edit{{tabId}}{{userId}} qpanel_ck_editor{{i}}{{x}}{{tabId}} qeditor"> 
												</div>
											</div> 
										</div>
									</div>
									<div *ngIf= "gameData.qaPanels[i+'_'+tabId] != undefined && gameData.qaPanels[i+'_'+tabId].qaPanelData[x+'_'+tabId].questionIsImage" id="questionDiv_{{i}}_{{x}}">
										<div class="card-body pl-0 pr-1 pb-0" (click)="showLargeImage(largeImage,gameData.qaPanels[i+'_'+tabId].qaPanelData[x+'_'+tabId].questionImageData.id)">
											<img class="img-fluid" style="width: 8rem;display: block;margin-left: auto;margin-right: auto;"
											src="data:image/jpeg;base64,{{gameData.qaPanels[i+'_'+tabId].qaPanelData[x+'_'+tabId].questionImageData.thumbnailImage}}"
											alt="{{gameData.qaPanels[i+'_'+tabId].qaPanelData[x+'_'+tabId].questionImageData.title}}">
										</div>
									</div>
								</div>
								<div class="card eing-card-body mt-1" style="background-color: #fafafa;border: 0px">
									<div class="card-header p-1" style="background-color: #fafafa;border: 0px">		
										<button id="btnClearA_{{i}}_{{x}}" type="button" class="my-1 mx-2 close float-right" (click)="clearContent(i,x,'a')" 
										tooltip ="Clear" [disabled]="isViewOnly || !gameEnabled ">
											<i class="far fa-eraser"></i>
										</button>			
										<button id="btnAddAText_{{i}}_{{x}}" type="button" class="my-1 mx-2 close float-right" (click)="addText(i,x,'a')" 
										tooltip ="Add Text" [disabled]="isViewOnly || !gameEnabled ">
											<i class="far fa-font"></i>
										</button>
										<button id="btnAddAImage_{{i}}_{{x}}" type="button" class="my-1 mx-2 close float-right" (click)="addPicture(i,x,'a')" 
										tooltip ="Add Image" [disabled]="isViewOnly || !gameEnabled ">
											<i class="far fa-image"></i>
										</button>
									</div>
									<div class="card-body" id="answerDiv_{{i}}_{{x}}" *ngIf= "gameData.qaPanels[i+'_'+tabId] != undefined && !gameData.qaPanels[i+'_'+tabId].qaPanelData[x+'_'+tabId].answerIsText && !gameData.qaPanels[i+'_'+tabId].qaPanelData[x+'_'+tabId].answerIsImage">
									</div>
									<div *ngIf= "gameData.qaPanels[i+'_'+tabId] != undefined && gameData.qaPanels[i+'_'+tabId].qaPanelData[x+'_'+tabId].answerIsText" id="answerDiv_{{i}}_{{x}}">
										<div class="card-body">
											<div class="border rounded">
												<div rows="2" contenteditable="false" id="answer_{{i}}_{{x}}"
													class="toggle-edit{{tabId}}{{userId}} apanel_ck_editor{{i}}{{x}}{{tabId}} qeditor"> 
												</div>
											</div> 
										</div>
									</div>
									<div *ngIf= "gameData.qaPanels[i+'_'+tabId] != undefined && gameData.qaPanels[i+'_'+tabId].qaPanelData[x+'_'+tabId].answerIsImage" id="answerDiv_{{i}}_{{x}}">
										<div class="card-body pl-0 pr-1 pb-0" (click)="showLargeImage(largeImage,gameData.qaPanels[i+'_'+tabId].qaPanelData[x+'_'+tabId].answerImageData.id)">
											<img class="img-fluid" style="width: 8rem;display: block;margin-left: auto;margin-right: auto;"
											src="data:image/jpeg;base64,{{gameData.qaPanels[i+'_'+tabId].qaPanelData[x+'_'+tabId].answerImageData.thumbnailImage}}"
											alt="{{gameData.qaPanels[i+'_'+tabId].qaPanelData[x+'_'+tabId].answerImageData.title}}">
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- <hr class="my-1" /> -->
					<!-- button section -->
					<!-- <div class="d-flex flex-row-reverse my-3">
						<button type="button" class="btn btn-sm btn-primary ml-1" [disabled]="isViewOnly || !gameEnabled " (click) = "gameDataSave()">
							{{ "ICE_GAME.MEMORY_GAME.BTN_SAVE" | translate }}
						</button>
						<button type="button" class="btn btn-sm btn-secondary mx-1" (click) = "cancel()">
							{{ "ICE_GAME.MEMORY_GAME.BTN_CANCEL" | translate }}
						</button> -->
						<!-- <button type="button" class="btn btn-sm btn-primary mx-1" (click) = "showPreview()">
							{{ "ICE_GAME.MEMORY_GAME.BTN_SHOW_PREVIEW" | translate }}
						</button> -->
					<!-- </div> -->
				</div>				
			</div>
		</div>
	</div>
</div>

<!-- hidden buttons to handle toolbar special char and fraction-->
<button tooltip="{{ 'WEB_EDITOR.ADD_SPE_CHAR' | translate }}" id={{addSpecialCharDivId}} container="body"
	type="button" (click)="openModal(spec_char)" hidden="true"
	class="btn btn-outline-primary btn-sm ml-1 mr-1">
	<i class="fas fa-omega" aria-hidden="true"></i>
</button>
<button tooltip="{{ 'WEB_EDITOR.ADD_FRACTION' | translate }}" id={{addFractionDIvId}} container="body"
	type="button" (click)="openModal(fraction)" hidden="true"
	class="btn btn-outline-primary btn-sm ml-1 mr-1">
	<i class="fas fa-divide" aria-hidden="true"></i>
</button>

<!-- special characters modal -->
<ng-template #spec_char>
	<div class="modal-header">
		<h4 class="modal-title pull-left">{{ 'WEB_EDITOR.SPE_CHAR' | translate}}</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="container-fluid">
			<div class="row">
				<div class="col-md-2" *ngFor="let speChar of speCharList; let n=index">
					<button type="button" class="btn default" (click)="addSpecialCharacter(speChar)"
						style="background-color: inherit;">
						{{speChar.data.symbol}}</button>
				</div>
			</div>
		</div>
	</div>
	<!-- <div class="modal-footer">
		<label>{{ 'WEB_EDITOR.DEC_INPUT' | translate}}</label>
		<input (keypress)="numberOnly($event)" class="form-control input-sm" #dec_value type="text">
		<button type="button" class="btn btn-primary" (click)="addSpeChar(dec_value.value)">
			<i class="fa fa-plus" aria-hidden="true"></i>
		</button>
	</div> -->
  </ng-template>
  
  <!--fraction modal-->
  <ng-template #fraction>
	  <div class="modal-header p-2">
		<h4 class="modal-title pull-left">{{ 'WEB_EDITOR.FRACTION' | translate}}</h4>
		<button type="button" class="close pull-right p-0 m-0" aria-label="Close" (click)="modalRef.hide()">
		  <span aria-hidden="true">&times;</span>
		</button>
	  </div>
	  <div class="modal-body">
		<div class="container-fluid">
		  <div class="row">
			<div class="input-group input-group-sm ">
			  <div class="input-group-prepend">
				<span class="input-group-text">Numerator / Denominator</span>
			  </div>
			  <input (keypress)="numberOnly($event)" #dec_value type="text" aria-label="Numerator"
				class="form-control" [(ngModel)]="numerator">
			  <input (keypress)="numberOnly($event)" #dec_valueD type="text" aria-label="Denominator"
				class="form-control" [(ngModel)]="denominator">
			</div>
		  </div>
		</div>
	  </div>
	  <div class="modal-footer p-2">                     
		<div class="col-sm-12 px-0"> 
		  <div class="badge badge-warning float-left" *ngIf="numerator != '' && denominator != '' && numerator != null && denominator != null">
			<span class="h6 m-0">{{numerator}} / {{denominator}}</span>
		  </div>
		  <button type="button" class="btn btn-secondary btn-sm float-right mx-1" (click)="modalRef.hide()">{{ 'CANCEL' |
					translate}}</button>
		  <button type="button" class="btn btn-primary btn-sm float-right mx-1"
			(click)="modalRef.hide();addFraction(dec_value.value,dec_valueD.value);">{{
					'ADD' | translate}}</button>
		</div>
	  </div>
	</ng-template>
  
	<ng-template #largeImage>
		<div class="modal-header">
			<h4 class="modal-title pull-left">{{ selectedImage.title }}</h4>
			<button type="button" id="image_close" class="close pull-right" aria-label="Close"
				(click)="modelRefCloseEvent()">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body">
			<div class="card p-0">
				<img class="card-img-top" src="data:image/jpeg;base64,{{selectedImage.largeImage}}">
			</div>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-sm btn-secondary" (click)="modelRefCloseEvent()">{{
					'IMAGE_SEARCH_VIEW.BTN_TEXT_CLOSE' | translate }}</button>
		</div>
	</ng-template>

  <!--template for closing game-->
	<ng-template #closeConfirmModel>
		<div class="modal-header">
			<h4 class="modal-title pull-left">{{'CLOSE_CONFIRM' | translate }}</h4>
		</div>    	
		<div class="modal-body">
			<div class="container">
			<h5 class='warn-text-editor'>{{ 'WARNING' | translate}}</h5>
			<h5>{{ 'ICE_GAME.MEMORY_GAME.CLOSE_CONFIRM_MSG' | translate}} </h5>
			</div>
		</div> 
		<div class="modal-footer">
		<button  type="button" class="btn btn-default" (click)="cancel();modalRef.hide();">{{'YES' | translate}}</button>
		<button type="button" class="btn btn-warning" (click)="modalRef.hide()">{{ 'NO' | translate}}</button>      
		</div>
	</ng-template>