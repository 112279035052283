import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService } from 'src/app/util/alert/alert.service';
import { CommonUtil } from 'src/app/util/common/common-util';
import { UserVariable } from 'src/app/util/common/user-variable';
import { LoadingService } from 'src/app/util/loading/loading.service';
import { WsResponse } from 'src/app/util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';
import { ManageotnService } from '../manageotn.service';

@Component({
  selector: 'app-approveotn',
  templateUrl: './approveotn.component.html',
  styleUrls: ['./approveotn.component.css']
})
export class ApproveotnComponent implements OnInit {

  otnId :string;
  clickedRow:string

  appConDate : any;
  appComSMDate : any;
  appComPMDate : any;
  documentUpload : any;
  public bsConfig: Partial<BsDatepickerConfig>
  @ViewChild('appContractorName') appContractorName: ElementRef;
  @ViewChild('appCompanySMName') appCompanySMName: ElementRef;
  @ViewChild('appCompanyPMName') appCompanyPMName: ElementRef;
  public otnDocumentUpload: any;
  public projectId: string = null;

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private loadingService: LoadingService,
    private translate: TranslateService,
    private manageotnService:ManageotnService,
    private commonUtil: CommonUtil
    ) {
      this.bsConfig = Object.assign({}, { containerClass: 'theme-default'  });
      this.projectId = UserVariable.projectId;
    }

  ngOnInit(): void {
  }

  cancel(){
    this.bsModalRef.hide()
  }
  
  save(){
    if (this.appContractorName.nativeElement.value == "" || this.appCompanySMName.nativeElement.value == "" || this.appCompanyPMName.nativeElement.value == "" ) {
      this.alertService.clear();
      this.alertService.error("Please fill all the required fields")
    } else if (!this.appContractorName.nativeElement.value || !this.appCompanySMName.nativeElement.value || !this.appCompanyPMName.nativeElement.value ) {
      this.alertService.clear();
      this.alertService.error("Please fill all the required fields")
    }
    else if (!this.appConDate || !this.appComSMDate || !this.appComPMDate ) {
      this.alertService.clear();
      this.alertService.error("Please fill all the required fields")
    }else if(this.appConDate == 'Invalid Date' || this.appComSMDate == 'Invalid Date'  || this.appComPMDate== 'Invalid Date' ){
      this.alertService.clear();
      this.alertService.error("Invalid date selected")
    }else if(this.otnDocumentUpload == null){
      this.alertService.clear();
      this.alertService.error("Please upload returned PDF file.");
    }
    else{
      this.loadingService.showLoading(true, false, 'Saving approval data', 0);
      let data = {
        otnId:  this.otnId,
        contractorPMName: this.appContractorName.nativeElement.value,
        contractorPMDate: new DatePipe("en-US").transform(new Date(this.appConDate), 'yyyy-MM-dd'),
        companyStartupManagerName: this.appCompanySMName.nativeElement.value,
        companyStartupManagerDate: new DatePipe("en-US").transform(new Date(this.appComSMDate), 'yyyy-MM-dd'),
        companyPMName: this.appCompanyPMName.nativeElement.value ,
        companyPMDate: new DatePipe("en-US").transform(new Date(this.appComPMDate), 'yyyy-MM-dd'),
        type: "Approve"
      }
      this.manageotnService.approveOTN(data,this);
    }
  }
  changeColor(val){
    // this.clickedRow = val;
  }

  onFail(data: WsResponse, serviceType: WsType) {
    if(serviceType == WsType.APPROVE_OTN){
      this.alertService.error(data.statusDescription);
      this.loadingService.hideLoading();
    }else if(serviceType == WsType.ADD_NEW_MC_DOCUMENT){
      this.alertService.error(data.statusDescription);
      this.loadingService.hideLoading()
    }
  }

  onSuccess(data: WsResponse, serviceType: WsType) {
    if(serviceType == WsType.APPROVE_OTN){
      if(data.payload){
        console.log(data.payload)
      }
      if(this.otnDocumentUpload!=null){
        this.onUpload(this.otnId)
      }else {
        this.translate.get('NEW_OTN_DOCUMENT.APPROVE_SUCCESS').subscribe((res: string) => {
          this.alertService.success(res, false);
        });
        this.loadingService.hideLoading();
        this.bsModalRef.hide()
        this.manageotnService.updateUIafterApprovalRequest(this.otnId,false);
      }

    }else if(serviceType == WsType.ADD_NEW_OTN_DOCUMENT){
      this.translate.get('NEW_OTN_DOCUMENT.APPROVE_SUCCESS').subscribe((res: string) => {
        this.alertService.success(res, false);
      });
      this.loadingService.hideLoading()
      this.otnDocumentUpload = null;
      this.bsModalRef.hide()
      this.manageotnService.updateUIafterApprovalRequest(this.otnId,true);
    }
  }

  onFileChanged(event) {
    let i: number = 0;
    for (i = 0; i < event.target.files.length; i++) {
      let newFile = <File>event.target.files[i];
      if (newFile != undefined) {
        let parts = newFile.name.split('.')
        if (parts.length > 1) {
          let fileExtension = parts.pop();
          if (fileExtension == 'pdf') {
            let fileName = parts.join('.');
            fileName = fileName.trim();
            if (this.validateFile(newFile)) {
              //set for uploading to the local file system
              var otnDocumentUpload =
                  {
                    docName: fileName,
                    docType: fileExtension,
                    file: newFile,
                    isAdded: true,
                    isDeleted: false
                  };
              this.otnDocumentUpload = otnDocumentUpload;
            } else {
              //file size is too large
            }
          } else {
            //file is not pdf
            this.alertService.clear();
            this.translate.get('NEW_OTN_DOCUMENT.INVALID_FILE_TYPE').subscribe((res: string) => {
              this.alertService.error(res, false);
            });

          }
        } else {
          this.translate.get('NEW_OTN_DOCUMENT.INVALID_FILE_NAME').subscribe((res: string) => {
            this.alertService.error(res, false);
          });
        }
      } else {
        //file is not valid
        this.alertService.clear();
        this.translate.get('NEW_OTN_DOCUMENT.INVALID_FILE').subscribe((res: string) => {
          this.alertService.error(res, false);
        });
        this.alertService.error("invalid file");
      }
    }
  }

  validateFile(file: File): boolean {
    if (file.size > 104857601) {
      this.translate.get('NEW_OTN_DOCUMENT.SIZE_LIMIT_EXCEEDED').subscribe((res: string) => {
        this.alertService.error(res, false);
      });
      return false;
    } else {
      return true;
    }
  }

  onUpload(otnId: string) {
    var loadingMessage;

    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }

    this.translate.get('NEW_OTN_DOCUMENT.LOADING_MESSAGE').subscribe((res: string) => {
      loadingMessage = res;
      this.loadingService.showLoading(true, null, loadingMessage, null);
    });

    if (this.otnDocumentUpload && this.otnDocumentUpload.isAdded && !this.otnDocumentUpload.isDeleted) {
      let element = this.otnDocumentUpload;
      let uploadFileNameWithExt = element.docName + "." + element.docType;
      const uploadData = new FormData();

      uploadData.append("otnId", otnId);
      uploadData.append("projectId", this.projectId);
      uploadData.append("filePath", '');
      uploadData.append("fileName", element.docName);
      uploadData.append("contentType", element.docType);
      uploadData.append("token", token);
      uploadData.append("file", element.file, element.docName);
      uploadData.append("addedUserId", '');
      uploadData.append("isAdded", element.isAdded);
      uploadData.append("isDeleted", element.isDeleted);
      this.manageotnService.uploadFile(uploadData, this);
    }
  }
}
