import { Component, OnInit, ElementRef, ViewChild, NgModule, AfterViewInit, OnDestroy } from '@angular/core';
import { WsResponse } from '../../util/ws-response.model';
import { WsCallback } from '../../util/ws-callback.interface';
import { WsType } from '../../util/ws-type';
import { GridOptions, ColDef, Module, AllModules } from "@ag-grid-enterprise/all-modules";
import { LoadingService } from '../../util/loading/loading.service';
import { SharedService } from '../../util/shared.service';
import { ComponentsService } from './components.service';
import { AlertService } from '../../util/alert/alert.service';
import { ComponentsCellRenderComponent } from './components-cell-render-component.component';
import { AgGridAngular } from '@ag-grid-community/angular';
import { TranslateService } from '@ngx-translate/core';
import { COMPONENT_UI_VIEW, LocalStorageConst, UI_LAYOUT, SessionStorageConst } from '../../util/constants';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { HistoryService } from '../history/history.service';
import { Router } from '@angular/router';
import { Constants } from '../../util/constants';
import { AddeditcomponentsComponent } from './addeditcomponents/addeditcomponents.component';
import { Subscription } from 'rxjs';
import { DependenciesModalService } from './../../util/dependenciesmodal/dependenciesmodal.service';
import { CapabilityService } from '../../util/capability.service';

@Component({
    selector: 'app-components',
    templateUrl: './components.component.html',
    styleUrls: ['./components.component.css']
})

export class ComponentsComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('agGrid') agGrid: AgGridAngular;

    categoryId: any = [];
    categoryName: any = [];
    level: any = 0;
    subCategoryList: any = [];
    public suv: any = COMPONENT_UI_VIEW;
    private gridApi: any;
    public getRowNodeId: any;
    private gridColumnApi: any;
    public columnDefs: any = [];
    public modules: Module[] = AllModules;
    public defaultColDef: any;
    public overlayLoadingTemplate: any;
    public overlayNoRowsTemplate: any;
    rowData: any = [];
    private filteredData: Array<any> = [];
    letter: any = [];
    items: any = [];

    public expandedView: string; //common variable 
    private categoryTitle: string;
    private title: string;
    private statement: string;
    private status: string;
    private edit: string;
    private delete: string;
    private selectedId: string;
    private componentName: string = "";
    private hasText: string = "";
    private componentValue: string = "";
    private reusableType: string = "";
    private resuableText: string = "";
    private reusableXML: string = null;
    private reusableView: string = "";
    private addCatChild: string;

     // Capabilities
	public modifyCap: boolean = false;
	public queryCap: boolean = false;
	public deleteCap: boolean = false;
	public disableCap: boolean = false;
	public createCap: boolean = false;

    //enable disable button/icon
	public enableAddComponent: boolean = false;
	public enableSearchComponent: boolean = false;
    public enableChangeComponentStatus: boolean = false;
    public enableEditComponent: boolean = false;
    public enableDeleteComponent: boolean = false;
    
    public gridOptions: GridOptions;
    public expandComponents: boolean = false;
    changeLayout: boolean = false
    bsModalRef: BsModalRef;
    private count: number = 0;

    private subsVar: Subscription

    constructor(private componentsService: ComponentsService,
        private alertService: AlertService,
        private sharedService: SharedService,
        private loadingService: LoadingService,
        private translate: TranslateService,
        private modalService: BsModalService,
		private historyService: HistoryService,
        private dependenciesmodalService: DependenciesModalService,
        private capabilityService: CapabilityService,
        private router: Router) {

        translate.addLangs(["es", "en"]);
        translate.setDefaultLang("en");
        let browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');

        this.translate.get('COMPONENT_SEARCH_VIEW.COMPONENT_NAME').subscribe((res: string) => {
            this.categoryTitle = res;
            this.translate.get('COMPONENT_SEARCH_VIEW.COMPONENT_VALUE').subscribe((res: string) => {
                this.title = res;
            });
        });

        //check the user capability and enable/disable buttons
        this.enableDisableButtons();

        this.columnDefs = [
            {
                headerName: 'Dependencies',
                field: "dependencies",
                width: 100,
                cellStyle: { 'text-align': 'center' },
                cellRendererFramework: ComponentsCellRenderComponent
            },
            {
                headerName: 'Type', field: 'type', checkboxSelection: false, hide: true,
                suppressColumnsToolPanel: true
            },
            {
                headerName: 'Component Id', field: 'componentId', checkboxSelection: false, hide: true,
                suppressColumnsToolPanel: true
            },
            {
                headerName: 'Component Name', field: 'componentName', checkboxSelection: false, hide: false,
                suppressColumnsToolPanel: false, sort: 'asc' 
            },
            {
                headerName: 'Component Value', field: 'componentValue', checkboxSelection: false, hide: false,
                suppressColumnsToolPanel: false
            },
            {
                headerName: 'Component Category', field: 'categoryName', checkboxSelection: false, hide: false,
                suppressColumnsToolPanel: false
            },
            // {
            //     headerName: 'Last Update Timestamp', field: 'lastUpdateTimeStamp', checkboxSelection: false, hide: true,
            //     suppressColumnsToolPanel: true
            // }
            {
                headerName: this.status,
                field: "status",
                width: 85,
                hide:!this.enableChangeComponentStatus,
                cellStyle: { 'text-align': 'center' },
                cellRendererFramework: ComponentsCellRenderComponent
            },
            {
                headerName: this.edit,
                field: "edit",
                width: 85,
                hide:!this.enableEditComponent,
                cellStyle: { 'text-align': 'center' },
                cellRendererFramework: ComponentsCellRenderComponent
            },
            {
                headerName: this.delete,
                field: "delete",
                width: 85,
                hide:!this.enableDeleteComponent,
                cellStyle: { 'text-align': 'center' },
                cellRendererFramework: ComponentsCellRenderComponent
            }
        ];

        this.getRowNodeId = function (data) {
            return data.id;
        };

        this.gridOptions = {
            rowData: this.rowData,
            columnDefs: this.columnDefs,
            rowSelection: "single",
            rowMultiSelectWithClick: false,
            isExternalFilterPresent: this.externalFilterPresent,
            doesExternalFilterPass: this.externalFilterPass,

        };
        this.defaultColDef = {
			resizable: true,
			sortable: true,
		}
        this.overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Loading data...</span>';
        this.overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">No rows to show</span>';

    }

    // get categories with no parents
    ngOnInit() {
        this.expandedView = COMPONENT_UI_VIEW.EXPANDED_VIEW;
        if (this.componentsService.getRowData() != null) {
            this.rowData = this.componentsService.getRowData();
            this.subCategoryList = this.componentsService.getSubCategoryList();
            this.categoryName = this.componentsService.getCategoryData();
        }
        else {
            this.componentsService.getComponentByCategory('0', this);
        }

        let uiLayout = JSON.parse(sessionStorage.getItem(SessionStorageConst.UI_LAYOUT));
        if (uiLayout !== UI_LAYOUT.UIL_6) {
            this.columnDefs = [
                {
                    headerName: 'Type', field: 'type', checkboxSelection: false, hide: true,
                    suppressColumnsToolPanel: true
                },
                {
                    headerName: 'Component Id', field: 'componentId', checkboxSelection: false, hide: true,
                    suppressColumnsToolPanel: true
                },
                {
                    headerName: 'Component Name', field: 'componentName', checkboxSelection: false, hide: false, width: 150,
                    suppressColumnsToolPanel: false, sort: 'asc' 
                },
                {
                    headerName: 'Component Value', field: 'componentValue', checkboxSelection: false, hide: false, width: 300,
                    suppressColumnsToolPanel: false
                },
                {
                    headerName: 'Component Category', field: 'categoryName', checkboxSelection: false, hide: false, width: 300,
                    suppressColumnsToolPanel: false
                }
            ];
        } else {
            // do nothing
        }
        if (uiLayout == UI_LAYOUT.UIL_2 || uiLayout == UI_LAYOUT.UIL_4) {
            this.expandedView = COMPONENT_UI_VIEW.SMALL_VIEW; //********//
            this.changeLayout = false
        } else {
            this.changeLayout = true
        }
    }

    ngAfterViewInit(): void {
        //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
        //Add 'implements AfterViewInit' to the class.
        this.subsVar = this.sharedService.expandReusablesExcute().subscribe((option: any) => this.changeComponent(option))
        this.subsVar = this.sharedService.opencomponentEditRespond().subscribe(() => this.openAddEditComponentModal())
       // 
       // this.subsVar = SharedService.searchComponentResponse().subscribe((option: any) => this.searchFuncStatement(option))
    }

    ngAfterContentInit() {
        this.subsVar = SharedService.searchComponentResponse().subscribe((option: any) => this.searchFuncStatement(option))
    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        if(this.subsVar) {
            this.subsVar.unsubscribe()
        }
        window.removeEventListener("resize", this.onWindowResize);
		this.gridApi.destroy();
    	this.gridApi = null;
    }

    changeComponent(option: any) {
        // console.log('[changeComponent] (option) ' + JSON.stringify(option));
    }

    /**
 * previous search
 */
    // component search
    // search(event: any) {
    //     this.componentValue = "";
    //     this.componentName = "";
    //     this.count = 0;
    //     let compArray = event.target.value.split(",");
    //     for (var l = 0; l <= compArray.length - 1; l++) {
    //         let letter = compArray[l].split(":");
    //         if (letter != "") {
    //             for (var i = 0; i < letter.length - 1; i++) {
    //                 if (letter[i] == "cn") {
    //                     this.componentName = letter[i + 1];
    //                 }
    //                 else if (letter[i] == "cv") {
    //                     this.componentValue = letter[i + 1];
    //                 } else {
    //                     if (this.count == 0) {
    //                         this.count = 1;
    //                         this.error();
    //                     } else {
    //                         // do nothing
    //                     }
    //                     this.count = 1;
    //                 }
    //             }
    //         }
    //     }
    //     if (this.componentName != "" || this.componentValue != "") {
    //         let to: any = false;
    //         if (to) { clearTimeout(to); }
    //         to = setTimeout(() => {

    //             this.componentsService.searchComponents(this.componentName, this.componentValue, "02fad998-3d98-49a7-9244-13b97a8f6f6d").subscribe(
    //                 data => {
    //                     this.loadComponents(data.payload)
    //                 },
    //                 error => {
    //                     this.alertService.clear()
    //                     this.alertService.error(error.statusDescription)
    //                 });
    //         }, 200);
    //     }
    //     else {
    //         let to: any = false;
    //         if (to) { clearTimeout(to); }
    //         to = setTimeout(() => {
    //             this.componentName = event.target.value;
    //             this.componentsService.searchComponents(this.componentName, this.componentValue, "0").subscribe(
    //                 data => {
    //                     this.loadComponents(data.payload)

    //                 },
    //                 error => {
    //                     this.alertService.clear()
    //                     this.alertService.error(error.statusDescription)
    //                 });
    //         }, 200);

    //     }
    // }

    error() {
        this.alertService.error("wrong value inserted");
    }

    onGridReady(params) {
        const allColumnIds = [];
        this.columnDefs.forEach((columnDef) => {
            allColumnIds.push(<ColDef>columnDef);
        });
        this.gridColumnApi = params.columnApi;
        this.gridApi = params.api;
        this.gridOptions = { onCellClicked: this.onCellClicked };
        this.componentsService.setApiGridData(this.gridApi);
        params.api.sizeColumnsToFit();
        // window.addEventListener("resize", function () {
        //     setTimeout(function () {
        //         params.api.sizeColumnsToFit();
        //     });
        // });
        window.addEventListener("resize", this.onWindowResize);
    }
    private onWindowResize = () => {
   
		setTimeout(() => {
			if (this.gridApi) {
				this.gridApi.sizeColumnsToFit();
			}
		}); 
	};
    //add component value to the editor
    onCellClicked(event) {
        let status = event.data.status.split(":")[2];
        if (status != null) {
            if (status == "Active" || status == "active") {
                let tabType = SharedService.getTabComponentType();
                if (tabType == 'Editor') {
                    SharedService.insertEditorRequest(event.data);
                } else if (tabType == 'Procedures') {
                    SharedService.insertProceduresRequest(event.data);
                } else if (tabType == 'IceCourse') {
                    SharedService.insertICECourseRequest(event.data);
                }
                this.reusableView = event.data.componentValue;
                this.reusableXML = SharedService.getHtmlString();
                this.save(this.reusableXML, this.reusableView);

            } else {
                this.translate.get('REUSABLES.COULD_NOT_ENTER_DISABLED_REUSABLES').subscribe((res: string) => {
                    this.alertService.error(res, false);
                });
            }
        } else {
            //Status is null
        }

    }

    save(reusableXML: any, reusableView: any) {
        if (this.reusableXML != null) {
            let xml = this.reusableXML.replace(/insid=.{38}/gi, function insId() {
                function s4() {
                    return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
                }
                return 'insId="' + s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4() + '"';
            });
            let to: any = false;
            if (to) { clearTimeout(to); }
            to = setTimeout(() => {
                this.reusableType = "Component Value";
                this.resuableText = "cv";
                this.historyService.saveToHistory(this.reusableType, this.resuableText, xml, this.reusableView).subscribe(
                    data => {
                        // console.log("success");
                    },
                    error => {
                        this.alertService.clear()
                        this.alertService.error(error.statusDescription)
                    });;
            }, 200);
        }
    }

    closeCategory(index) {
        this.categoryName.length = index;
        this.subCategoryList.length = index + 1;
        this.level = index;
        this.selectedId = "";
    }


    selectCategory(id: any) {
        this.categoryId = id;
        this.componentsService.getComponentByCategory(id, this);
        this.categoryName[this.level] = this.subCategoryList[this.level].filter(item => item["id"] === id)[0]["name"];
        this.level = this.level + 1;
        this.componentsService.setCategoryData(this.categoryName);
    }

    onSuccess(data: WsResponse, serviceType: WsType): void {
        // console.log(data.payload);
        if (data.payload != null && serviceType == WsType.STATEMENT_SEARCH_VIEW) {
            if (data.payload.subCategoryList != null) {
                this.loadCategory(data.payload.subCategoryList);
            }
            if (data.payload.componentList != null) {
                this.loadComponents(data.payload.componentList);
                this.componentsService.setApiGridData(this.gridApi);
            }
		}
		
		if (data.payload != null && serviceType == WsType.ITEM_DEPENDENCY_LIST) {
		   let items = data.payload.split("_")
		   if(items[1]=="Yes")
		   {
				for(let i=0; i<this.rowData.length; i++)
				{
					if(this.rowData[i].id == items[0])
					{
						//var rowNode = this.gridApi.getRowNode(items[0]);
						//rowNode.setDataValue("dependencies", "dependencies:" + items[0])
                        this.rowData[i].dependencies = "dependencies:" + items[0];
                        if(!this.gridApi.destroyCalled) {
                            this.gridApi.setRowData(this.rowData);
                        }
					}
				}
		   }
        }
    }

    onFail(res: WsResponse, serviceType: WsType): void {
        if (serviceType == WsType.STATEMENT_SEARCH_VIEW) {
            if (res.statusCode == "532") {
                //this.router.navigateByUrl('login');
            }
            else if (res.statusCode == "600") {
                this.componentsService.getComponentByCategory(this.categoryId[this.level], this);
            } else {
                //show the error message
                this.alertService.error(res.statusDescription.toString(), false);
            }
        }
    }

    loadCategory(categories: any) {
        this.subCategoryList[this.level] = [];
        for (var i = 0; i < categories.length; i++) {
            this.subCategoryList[this.level].push({ id: categories[i]["categoryId"], name: categories[i]["categoryName"] })
        }
        this.componentsService.setSubCategoryList(this.subCategoryList);
    }

    loadComponents(components: any) {
        if (components != null) {
			this.rowData = [];
			this.dependenciesmodalService.setCallback(this);
            for (let i = 0; i < components.length; i++) {
                let content = ""
                if (components[i]["content"] != null) {
                    let contents = components[i]["content"].split("txt>")
                    for (var j = 1; j < contents.length - 1; j++) {
                        var re = />/gi;
                        if (contents[j] != "</" && contents[j].search(re) == -1) {
                            content = content + contents[j].split("<")[0];
                        }
                        else if (contents[j] != "</") {
                            let innerContents = contents[j].split(">")[1].split("<")[0]
                            content = content + innerContents;
                        }
                    }
                }
                this.rowData.push({
                    id: components[i]["id"],
                    dependencies: "disable_dependencies:" + components[i]["id"],
                    componentName: components[i]["componentName"],
                    componentValue: components[i]["componentValue"],
                    categoryName: components[i]["categoryName"],
                    lastUpdateTimeStamp: components[i]["lastUpdateTimeStamp"],
                    type: components[i]["type"],
                    status: "status:" + components[i]["id"] + ":" + components[i]["status"],
                    edit: "edit:" + components[i]["id"] + ":" + components[i]["status"],
                    delete: "delete:" + components[i]["id"] + ":" + components[i]["status"],
                    compStatus: components[i]["status"],

				});
				//this.items.push({id: components[i]["id"]})
                this.dependenciesmodalService.getDependeciesWithId( components[i]["id"], "Component");
                this.gridApi.paginationProxy.currentPage = 0;
                this.componentsService.setRowData(this.rowData);
			}

			
			// console.log("this.items.length " + this.items.length)
			// for(let i=0; i<this.items.length; i++)
			// {
			// 	this.dependenciesmodalService.getDependeciesWithId( this.items[i].id, "Component");
			// }


        } else {
            this.rowData = [];
            this.componentsService.setRowData(this.rowData);
        }
    }

    /**
     * 
     * previoues component search
     */
    // // search components by component Name
    // searchByComponentName(event: any) {
    //     if (event.target.value != "") {
    //         let to: any = false;
    //         if (to) { clearTimeout(to); }
    //         to = setTimeout(() => {
    //             this.componentName = event.target.value;
    //             this.componentsService.searchComponents(this.componentName, this.componentValue, this.categoryId).subscribe(
    //                 data => {
    //                     this.loadComponents(data.payload)

    //                 },
    //                 error => {
    //                     this.alertService.clear()
    //                     this.alertService.error(error.statusDescription)
    //                 });
    //         }, 200);
    //     } else {
    //         let to: any = false;
    //         if (to) { clearTimeout(to); }
    //         to = setTimeout(() => {
    //             this.componentName = event.target.value;
    //             this.componentsService.searchComponents(this.componentName, this.componentValue, "0").subscribe(
    //                 data => {
    //                     this.loadComponents(data.payload)

    //                 },
    //                 error => {
    //                     this.alertService.clear()
    //                     this.alertService.error(error.statusDescription)
    //                 });
    //         }, 200);

    //     }
    // }
    // // search component by component value
    // searchByComponentValue(event: any) {
    //     console.log("n+++n")
    //     if (event.target.value != "") {
    //         let to: any = false;
    //         if (to) { clearTimeout(to); }
    //         to = setTimeout(() => {
    //             this.componentValue = event.target.value;
    //             this.componentsService.searchComponents(this.componentName, this.componentValue, this.categoryId).subscribe(
    //                 data => {
    //                     this.loadComponents(data.payload)
    //                 },
    //                 error => {
    //                     this.alertService.clear()
    //                     this.alertService.error(error.statusDescription)
    //                 });
    //         }, 200);
    //     } else {
    //         let to: any = false;
    //         if (to) { clearTimeout(to); }
    //         to = setTimeout(() => {
    //             this.componentValue = event.target.value;
    //             this.componentsService.searchComponents(this.componentName, this.componentValue, "0").subscribe(
    //                 data => {
    //                     this.loadComponents(data.payload)

    //                 },
    //                 error => {
    //                     this.alertService.clear()
    //                     this.alertService.error(error.statusDescription)
    //                 });
    //         }, 200);
    //     }

    // }


    searchFunc(data: any) {
        console.log('[searchFunc] (data) ' + JSON.stringify(data));
        this.alertService.clear();
        // console.log('data jason', data.jsonData);
        this.gridApi.showLoadingOverlay();
        this.componentValue = "";
        this.componentName = "";
        this.count = 0;
        this.categoryId = 0;
        this.categoryName = "";
        this.hasText = "";

        //assign value for the fields from searchWordMap
        var compname = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_COMPONENT_COMP_NAME];
        if (compname != undefined) {
            this.componentName = compname;
        } else {
            //no compname
        } 
        var compval = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_COMPONENT_COMP_VALUE];
        if (compval != undefined) {
            this.componentValue = compval;
        } else {
            //no compval
        } 

        var category = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_COMPONENT_COMP_CATEGORY];
        if (category != undefined) {
            this.categoryName = category;
        } else {
            //no category id
        }

        var hasText = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_HAS_THE_WORDS];
        if (hasText != undefined) {
            this.hasText = hasText;
        } else {
            //no has text
        }

        this.addCatChild = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_WITH_CHILDREN];
		if (this.addCatChild == undefined) {
			//set as false
			this.addCatChild = "false";
		}

        console.log(this.componentName + '/' + this.componentValue + '/' + this.categoryId + '/' + this.categoryName);

        if (this.componentValue != "" || this.categoryName != "" || this.componentName != "" || this.hasText != "") {
            let to: any = false;
            if (to) { clearTimeout(to); }
            to = setTimeout(() => {

                this.componentsService.searchComponents(this.componentName, this.componentValue, this.categoryId, this.categoryName, this.addCatChild, this.hasText).subscribe(
                    data => {
                        this.loadComponents(data.payload)
                    },
                    error => {
                        this.alertService.clear()
                        this.alertService.error(error.statusDescription)
                    });
            }, 200);
        } else {
            //search bar has empty values
            this.gridApi.hideOverlay()
            this.loadComponents(null) 
        }

        // let compArray = data.searchTxt.split(" ");
        // for (var l = 0; l <= compArray.length - 1; l++) {
        //     let letter = compArray[l].split(":");
        //     if (letter != "") {
        //         for (var i = 0; i < letter.length - 1; i++) {
        //             if (letter[i] == "cn") {
        //                 this.componentName = letter[i + 1];
        //             }
        //             else if (letter[i] == "cv") {
        //                 this.componentValue = letter[i + 1];
        //             } else if (letter[i] == "catName") {
        //                 if (letter[i + 1] = "Project ") {
        //                     this.categoryId = "02fad998-3d98-49a7-9244-13b97a8f6f6d";
        //                 } else if (letter[i + 1] = "Units ") {
        //                     this.categoryId = "985f7615-4ff3-4c06-beee-498d3afeba87";
        //                 }

        //             } else {
        //                 if (this.count == 0) {
        //                     this.count = 1;
        //                     this.error();
        //                 } else {
        //                     // do nothing
        //                 }
        //                 this.count = 1;
        //             }
        //         }
        //         this.categoryId = localStorage.getItem("selectedCategory"); //category id got from local storage
        //         localStorage.setItem("selectedCategory", "%");
        //     }
        // }
        // if (this.componentName != "" || this.componentValue != "") {
        //     let to: any = false;
        //     if (to) { clearTimeout(to); }
        //     to = setTimeout(() => {

        //         this.componentsService.searchComponents(this.componentName, this.componentValue, this.categoryId).subscribe(
        //             data => {
        //                 this.loadComponents(data.payload)
        //             },
        //             error => {
        //                 this.alertService.clear()
        //                 this.alertService.error(error.statusDescription)
        //             });
        //     }, 200);
        // } else {
        //     let to: any = false;
        //     if (to) { clearTimeout(to); }
        //     to = setTimeout(() => {
        //         //this.componentName = event.target.value;
        //         this.componentsService.searchComponents(this.componentName, this.componentValue, this.categoryId).subscribe(
        //             data => {
        //                 this.loadComponents(data.payload)
        //             },
        //             error => {
        //                 this.alertService.clear()
        //                 this.alertService.error(error.statusDescription)
        //             });
        //     }, 200);
        // }
    }

    searchBarEnterFunc(data: any) {
        console.log('[searchBarEnterFunc] (data) ' + JSON.stringify(data));
        this.alertService.clear();
        this.gridApi.showLoadingOverlay();
        this.componentValue = "";
        this.componentName = "";
        this.count = 0;
        this.categoryId = "";
        this.categoryName = "";
        //check fro empty values in search bar
        if (this.checkForEmptyValuesInSearchBar(data)) {
            let compArray = data.split(" ");
            for (var l = 0; l <= compArray.length - 1; l++) {
                let letter = compArray[l].split(":");
                if (letter != "") {
                    for (var i = 0; i < letter.length - 1; i++) {
                        if (letter[i] == "cn") {
                            this.componentName = letter[i + 1];
                        }
                        else if (letter[i] == "cv") {
                            this.componentValue = letter[i + 1];
                        } else if (letter[i] == "catName") {
                            if (letter[i + 1] = "Project ") {
                                this.categoryId = "02fad998-3d98-49a7-9244-13b97a8f6f6d";
                            } else if (letter[i + 1] = "Units ") {
                                this.categoryId = "985f7615-4ff3-4c06-beee-498d3afeba87";
                            }

                        } else {
                            if (this.count == 0) {
                                this.count = 1;
                                this.error();
                            } else {
                                // do nothing
                            }
                            this.count = 1;
                        }

                    }
                }
            }
            if (this.componentName != "" || this.componentValue != "") {
                let to: any = false;
                if (to) { clearTimeout(to); }
                to = setTimeout(() => {

                    this.componentsService.searchComponents(this.componentName, this.componentValue, this.categoryId, this.categoryName, this.addCatChild,  this.hasText).subscribe(
                        data => {
                            this.loadComponents(data.payload)
                        },
                        error => {
                            this.alertService.clear()
                            this.alertService.error(error.statusDescription)
                        });
                }, 200);
            } else {
                let to: any = false;
                if (to) { clearTimeout(to); }
                to = setTimeout(() => {
                    // this.componentName = event.target.value;
                    this.componentsService.searchComponents(this.componentName, this.componentValue, this.categoryId, this.categoryName, this.addCatChild, this.hasText).subscribe(
                        data => {
                            this.loadComponents(data.payload)
                        },
                        error => {
                            this.alertService.clear()
                            this.alertService.error(error.statusDescription)
                        });
                }, 200);

            }
        } else {
            //search bar has empty values
            this.gridApi.hideOverlay()
            this.loadComponents(null)
        }
    }

    /**
     * check for empty values in search bar
     * @param data 
     */
    public checkForEmptyValuesInSearchBar(data: any): boolean {
        let docArray = data.split(" ");
        if (docArray.length > 1) {
            return true
        } else {
            if (docArray[0] != '') {
                return true
            } else {
                return false
            }
        }
    }

    externalFilterPresent() {
        return this.componentName != "";
    }

    externalFilterPass(node: any) {
        return true;
    }

    add() {
        this.sharedService.setFileId("-1");
        // this.router.navigateByUrl('addeditcomponents');
        this.openAddEditComponentModal()
    }

    /**
     * open add/ edit component modal 
     * to solve data mission issue when save or cancel
     */
    private openAddEditComponentModal() {
        console.log('[openAddEditComponentModal]');
        this.bsModalRef = this.modalService.show(AddeditcomponentsComponent, { backdrop: 'static', keyboard : false })
    }

     /**
     * enable/disable buttons or columns
     */
    private enableDisableButtons(){
        this.capabilityCheck();
        this.enableAddComponent = this.createCap;
        this.enableSearchComponent = this.queryCap;
        this.enableEditComponent = this.modifyCap;
        this.enableDeleteComponent = this.deleteCap;
        this.enableChangeComponentStatus = this.disableCap;
    }

    /**
     * check the capabilities
     */
    public capabilityCheck(){
        let modifyCapability: string = "modifyComp";
        let queryCapability: string = "queryComp";
        let deleteCapability: string = "deleteComp";
        let disableCapability: string = "disableComp";
        let createCapability: string = "createComp";

        this.modifyCap = this.capabilityService.isCapabilityAssigned(modifyCapability);
        this.queryCap = this.capabilityService.isCapabilityAssigned(queryCapability);
        this.deleteCap = this.capabilityService.isCapabilityAssigned(deleteCapability);
        this.disableCap = this.capabilityService.isCapabilityAssigned(disableCapability);
        this.createCap = this.capabilityService.isCapabilityAssigned(createCapability);
    }

    searchFuncStatement(categoryName: any) { 
        console.log("Coming to component category search");
        this.count = 0;
        this.categoryId = 0;
        this.categoryName = "";        
       
        if (categoryName != undefined) {
            this.categoryName = categoryName["item"];
        } else {
            //no category id
        }
        console.log(this.categoryId + '/' + this.categoryName);

        if (this.categoryName != "") {
            let to: any = false;
            if (to) { clearTimeout(to); }
            to = setTimeout(() => {

                this.componentsService.searchComponents("", "", this.categoryId, this.categoryName, "", "").subscribe(
                    data => {
                        this.loadComponents(data.payload)
                    },
                    error => {
                        this.alertService.clear()
                        this.alertService.error(error.statusDescription)
                    });
            }, 200);
        } else {
            //search bar has empty values
            this.gridApi.hideOverlay()
            this.loadComponents(null) 
        }
    
    }
}

