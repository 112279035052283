export class FileModel {
    constructor(
        public action?: any,
        public addedDate?: any,
        public attributes?: any,
        public checkInEnabled?: any,
        public checkoutBy?: string,
        public checkoutEnabled?: any,
        public checkoutUserId?: string,
        public clientName?: string,
        public cmRevsionVersion?: string,
        public comments?: any,
        public completed?: any,
        public completedTime?: any,
        public content?: any,
        public contentType?: any,
        public deleteEnabled?: any,
        public dependencyCount?: number,
        public description?: any,
        public diagnoseEnable?: any,
        public documentType?: any,
        public documentTypeId?: any,
        public documentTypePath?: any,
        public documents?: any,
        public downloadEnable?: any,
        public duplicateEnable?: any,
        public enableMoveFile?: any,
        public editable?: any,
        public facilityId?: any,
        public facilityName?: any,
        public fileId?: any,
        public folderId?: any,
        public folderName?: any,
        public folderPath?: any,
        public genPDFEnable?: any,
        public htmlContent?: any,
        public id?: any,
        public images?: any,
        public inReview?: any,
        public initiatedTime?: any,
        public initiatedUser?: any,
        public itemStatus?: any,
        public keywords?: any,
        public lastUpdateBy?: any,
        public lastUpdateTime?: any,
        public lastUpdateTimeStamp?: any,
        public lastUpdatedBy?: any,
        public name?: any,
        public ncrws?: any,
        public outputConfigId?: any,
        public outputList?: any,
        public procContentCM?: any,
        public procName?: any,
        public procedureStoreId?: any,
        public projectId?: any,
        public projectName?: any,
        public publishDate?: any,
        public queryWorkflow?: any,
        public referenceCount?: any,
        public reusableUpdateDataId?: any,
        public revisionsList?: any,
        public status?: any,
        public statusEnable?: any,
        public storeAvailability?: any,
        public storeVersion?: any,
        public systemId?: any,
        public systemName?: any,
        public systems?: any,
        public targetType?: any,
        public targetValue?: any,
        public templateConfigXML?: any,
        public title?: any,
        public type?: any,
        public undoCheckoutEnable?: any,
        public lastUpdatedUser?: any
    ) {}
}