<div class="container-fluid px-0">
    <div class="row px-3">
        <div class="col-sm-8 px-0">
            <p class="mb-2 h5">
                <b>{{ 'COMPONENT_SEARCH_VIEW.HEADER' | translate }}</b>
            </p>
        </div>
        <div class="col-sm-4 px-0">
            <button *ngIf="changeLayout" type="submit" class="btn btn-primary btn-sm float-right reusable_btn my-1"
            [disabled]="!enableAddComponent" (click)="add()" id="component_add">
                <i class="fas fa-plus"></i>
            </button>
        </div>
    </div>
    <div class="col-sm-12 px-0">
        <div class="input-with-icon" *ngIf="!enableSearchComponent">
			<input #symbiosis_search class="form-control" name="tag_search" placeholder="Search" style="padding-right: 60px;" 
				[disabled]="!enableSearchComponent"/>
        </div>
        <div class="input-with-icon" *ngIf="enableSearchComponent">
            <symbiosis-search id="componentsearch" [comp]="'component'" (searchFunc)="searchFunc($event)"
                (searchBarEnterFunc)="searchBarEnterFunc($event)">
            </symbiosis-search>
        </div>
    </div>
    <hr class="my-2" />
</div>

<div *ngIf="expandedView == suv.EXPANDED_VIEW">
    <!--ag-grid-angular style="width: 100%; height: 100%;" class="ag-theme-balham" [rowData]="rowData"  [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }" [columnDefs]="columnDefs"
        [gridOptions]="gridOptions">
    </ag-grid-angular-->
    <ag-grid-angular #agGrid style="width: 100%; height: 375px;" class="ag-theme-balham" 
        [enableFilter]="true" [rowData]="rowData" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }" [columnDefs]="columnDefs" rowSelection="multiple" 
        [rowMultiSelectWithClick]="false"  [getRowNodeId]="getRowNodeId" [defaultColDef]="defaultColDef"
        (gridReady)="onGridReady($event)" [pagination]="true" [paginationPageSize]="10" [overlayLoadingTemplate]="overlayLoadingTemplate"
        [overlayNoRowsTemplate]="overlayNoRowsTemplate">
    </ag-grid-angular>
</div>

<div *ngIf="expandedView != suv.EXPANDED_VIEW">
    <ag-grid-angular #agGrid style="width: 100%; height: 375px;" class="ag-theme-balham" 
        [enableFilter]="true" [rowData]="rowData" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }" [columnDefs]="columnDefs" rowSelection="multiple" 
        [rowMultiSelectWithClick]="false" (cellClicked)="onCellClicked($event)"  [getRowNodeId]="getRowNodeId" [defaultColDef]="defaultColDef"
        (gridReady)="onGridReady($event)" [pagination]="true" [paginationPageSize]="10" [overlayLoadingTemplate]="overlayLoadingTemplate"
        [overlayNoRowsTemplate]="overlayNoRowsTemplate">
    </ag-grid-angular>
</div>