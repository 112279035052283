<!-- <alert class="floating-alert"></alert>
<div>
   <h5 class="text-center">
      {{ 'DOCUMENT_ADD_EDIT.DOCUMENTS' | translate }}
   </h5>
   <br />
</div> -->

<div class="modal-header py-2">
   <h5 class="modal-title">
      {{ 'DOCUMENT_ADD_EDIT.DOCUMENTS' | translate }}
   </h5>
   <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel()">
      <span aria-hidden="true">&times;</span>
   </button>
</div>

<div class="modal-body">
   <!-- <div class="card-block card-body py-1"> -->
   <div *ngIf="addDoc">
      <form>
         <!--Document name-->
         <div class="form-group mb-2">
            <label for="document_Name" class="col-sm-12 px-0 mb-0">
               {{ 'DOCUMENT_ADD_EDIT.DOCUMENT_NAME' | translate }}
               <div class="badge-required mx-2"></div>
            </label>
            <input [(ngModel)]="documentName" type="text" id="docName" name="documentName" class="form-control form-control-sm">
         </div>

         <!--Alternate doc no-->
         <div class="form-group mb-2">
            <label for="alt_Doc_No" class="col-sm-12 px-0 mb-0">
               {{ 'DOCUMENT_ADD_EDIT.ALTERNATE_DOCUMENT_NUMBER' | translate }}
            </label>
            <input [(ngModel)]="altDocumentNo" type="text" id="altDocNumber" name="altDocumentNo" class="form-control form-control-sm">
         </div>

         <!--Facility-->
         <div class="form-group mb-2">
            <label for="facility_Id" class="col-sm-12 px-0 mb-0">
               {{ 'DOCUMENT_ADD_EDIT.FACILITY' | translate }}
            </label>
            <ng-select [allowClear]="true" (removed)="removedFacility($event)" (selected)="selectedFacilityId($event)"
               [items]="facilityList" [active]="selectedFacility" name="facilityId" id="facilityId">
            </ng-select>
         </div>

         <!--Document type-->
         <div class="form-group mb-2">
            <label class="col-sm-12 px-0 mb-0" for="document_Type_Id">
               {{ 'DOCUMENT_ADD_EDIT.DOCUMENT_TYPE' | translate }}
               <div class="badge-required mx-2"></div>
            </label>
            <input type="text" class="form-control form-control-sm" [(ngModel)]="documentType" id="document_Type" name="documentType" readonly
               placeholder="Select document type" (click)="loadDocumentTypes()">
         </div>

         <!--Revision-->
         <div class="form-group mb-2">
            <label class="col-sm-12 px-0 mb-0" for="revision_">
               {{ 'DOCUMENT_ADD_EDIT.REVISION' | translate }}
            </label>
            <input type="text" class="form-control form-control-sm" id="docRevision" [(ngModel)]="revision" name="revision">
         </div>

         <div class="form-group mb-2">
            <label class="col-sm-12 px-0 mb-0" for="document_pdf_view">
               {{ 'DOCUMENT_ADD_EDIT.PDF_FILE' | translate }}
            </label>
            <div class="input-group">
               <input type="text" class="form-control form-control-sm" [(ngModel)]="contentItemName" id="attach_file" name="attach_file"
                  placeholder="Select pdf file" (click)="loadPDFFile()">
               <div class="input-group-append">
                  <span class="input-group-text" (click)="removeSelectedPDF()"><i class="far fa-times-circle mx-2" aria-hidden="true"></i></span>
               </div>
            </div>
         </div>

         <!--buttons-->
         <!-- <div class="row my-4">
            <div class="col-sm-12 float-right">
               <button type="button" id="document_cancel" class="btn btn-secondary mx-1 float-right" (click)="cancel()">{{
                     'CANCEL' | translate}}</button>
               <button type="submit" id="document_save" class="btn btn-primary mx-1 float-right"
                  (click)="addButtonClickFunction()">{{
                     'SAVE' | translate}}</button>
            </div>
         </div> -->
      </form>
   </div>

   <div *ngIf="editDoc">
      <form>
		 <!--Document name-->
		 <div class="form-group mb-2">
				<label class="mb-1 col-sm-12">
						{{referenceCount}} {{ 'STATEMENT_ADD_EDIT.PLACES' | translate }},
						{{ 'STATEMENT_ADD_EDIT.IN' | translate }} {{topicCount}}
						{{ 'STATEMENT_ADD_EDIT.TOPICS' | translate }}, {{ 'STATEMENT_ADD_EDIT.IN' | translate }}
						{{procedureCount}}
						{{ 'STATEMENT_ADD_EDIT.PROCEDURES' | translate }}, {{ 'STATEMENT_ADD_EDIT.IN' | translate }}
						{{iceCourseCount}}
                  {{ 'STATEMENT_ADD_EDIT.ICECOURSES' | translate }}
               </label>
               <label class="mb-0 col-sm-12">
						<span class="badge border-badge-danger mx-2 py-2"
							tooltip="{{ 'STATEMENT_ADD_EDIT.CHECKED_OUT' | translate }}">
							<i class="fa fa-upload mx-2 fa-lg" aria-hidden="true"></i>
							<label class="mx-2 mb-0">{{checkoutTopicCount}}</label>
						</span>
						<span class="badge border-badge-success mx-2 py-2"
							tooltip="{{ 'STATEMENT_ADD_EDIT.CHECKED_IN' | translate }}">
							<i class="fa fa-download mx-2 fa-lg" aria-hidden="true"></i>
							<label class="mx-2 mb-0">{{checkinTopicCount}}</label>
						</span>
					</label>
	</div>
         <div class="form-group mb-2" *ngIf="!reusable">
            <label for="document_Name" class="col-sm-12 px-0 mb-0">
               {{ 'DOCUMENT_ADD_EDIT.DOCUMENT_NAME' | translate }}
               <div class="badge-required mx-2"></div>
            </label>
            <input [(ngModel)]="editDocumentName" type="text" id="edit_docName" name="documentName" class="form-control form-control-sm">
         </div>

         <div class="form-group mb-2" *ngIf="reusable">
            <label for="document_Name" class="col-sm-12 px-0 mb-0">
               {{ 'DOCUMENT_ADD_EDIT.DOCUMENT_NAME' | translate }}
               <div class="badge-required mx-2"></div>
            </label>
            <input [(ngModel)]="editDocumentName" type="text" id="edit_docName" name="documentName" class="form-control form-control-sm">
            <!-- modified values & time -->
            <div class="card bg-danger mb-3">
               <div class="card-body py-1 px-2">
                  <p class="card-text my-1">{{reusablesName}}</p>
                  <p class="card-text my-1">{{reusablesModifiedBy}}</p>
                  <p class="card-text my-1">{{rusableModifiedTime}}</p>
               </div>
            </div>
         </div>

         <!--Alternate doc no-->
         <div class="form-group mb-2">
            <label for="alt_Doc_No" class="col-sm-12 px-0 mb-0">
               {{ 'DOCUMENT_ADD_EDIT.ALTERNATE_DOCUMENT_NUMBER' | translate }}
            </label>
            <input [(ngModel)]="editAltDocumentNo" type="text" id="edit_altDocNumber" name="edit_altDocumentNo" class="form-control form-control-sm">
         </div>

         <!--Facility-->
         <div class="form-group mb-2">
            <label for="facility_Id" class="col-sm-12 px-0 mb-0">
               {{ 'DOCUMENT_ADD_EDIT.FACILITY' | translate }}
            </label>
            <ng-select [allowClear]="true" (removed)="removedFacilityEdit($event)" (selected)="selectedFacilityEditId($event)"
               [items]="editFacilityList" [active]="selectedFacilityEdit" name="facility_Id" id="facility_Id">
            </ng-select>
         </div>

         <!--Document type-->
         <div class="form-group mb-2">
            <label class="col-sm-12 px-0 mb-0" for="document_Type_Id">
               {{ 'DOCUMENT_ADD_EDIT.DOCUMENT_TYPE' | translate }}
               <div class="badge-required mx-2"></div>
            </label>
            <input type="text" class="form-control form-control-sm" [(ngModel)]="editDocumentType" id="edit_document_Type" readonly
               name="edit_documentType" (click)="loadDocumentTypes()">
         </div>

         <!--Revision-->
         <div class="foform-group mb-2">
            <label class="col-sm-12 px-0 mb-0" for="revision_">
               {{ 'DOCUMENT_ADD_EDIT.REVISION' | translate }}
            </label>
            <input type="text" class="form-control form-control-sm" id="edit_docRevision" [(ngModel)]="editRevision"
               name="edit_revision">
         </div>

         <div class="form-group mb-2">
            <label class="col-sm-6 px-0 mb-0" for="document_pdf_view">
               {{ 'DOCUMENT_ADD_EDIT.PDF_FILE' | translate }}
            </label>
            <button type="button" class="btn btn-warning btn-sm float-right my-1 btn-sm-form-dependancy"
               (click)="viewDependencies(false)" [disabled]="procedureCount == 0">
               <i class="fa fa-link" aria-hidden="true"></i>
            </button>
            <div class="input-group">
               <input type="text" class="form-control form-control-sm" [(ngModel)]="editContentItemName" id="attach_file" name="attach_file"
                  placeholder="Select pdf file" (click)="loadPDFFile()">
               <div class="input-group-append">
                  <span class="input-group-text" (click)="removeSelectedPDF()"><i class="far fa-times-circle mx-2" aria-hidden="true"></i></span>
               </div>
            </div>
         </div>

      </form>
   </div>
   <!-- </div> -->
</div>

<div class="modal-footer">
   <button type="button" id="document_cancel" class="btn btn-secondary btn-sm" (click)="cancel()">
      {{ 'CANCEL' | translate}}
   </button>
   
   <button *ngIf="addDoc" type="submit" id="document_save" class="btn btn-primary btn-sm" (click)="addButtonClickFunction()">
      {{ 'SAVE' | translate}}
   </button>
   <button *ngIf="editDoc" type="submit" id="document_save" class="btn btn-primary btn-sm" (click)="editButtonClickFunction()">
      {{ 'SAVE' | translate}}
   </button>

</div>