export class ICECourse {
    constructor(
        public courseId: string,
        public courseName?: string,
        public courseNameHTML?: string,
        public fileName?: string,
        public folderId?: string,
        public courseDescription?: string,
        public courseDescriptionHTML?: string,
	    public courseImage?: ProcImage,
        public courseIcon?: ProcImage,
        public selectedType?: string,
        public courseVersion?: string,
        public noOfGlossaryItems?: number,
        public noOfGames?: number
    ){}
}

export class ProcImage {
    constructor(
        public id: string,
        public thumbnailImage?: string,
        public largeImage?: string,
        public title?: string
    ){}
}

export class ICEGlossary {
    public get isGlossaryModified(): boolean {
        return this._isGlossaryModified;
    }
    public set isGlossaryModified(value: boolean) {
        this._isGlossaryModified = value;
    }
    constructor(
        public id: string,
        public courseId?: string,
        public glossaryName?: string,
        public description?: string,
        public courseVersion?: number,
        private _isGlossaryModified?: boolean,
    ){}
}