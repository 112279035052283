import { Component, OnInit, HostListener } from '@angular/core';
import { FolderService } from '../../foldertree/foldertree.service';
import { LoggedUser } from '../../user/logged-user.model';
import { AlertService } from 'src/app/util/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { LandingService } from 'src/app/landing/landing.service';
import { Router } from '@angular/router';
import { FilesService } from 'src/app/home/files/files.service';
import { SharedService } from 'src/app/util/shared.service';
import { LoadingService } from 'src/app/util/loading/loading.service';
import { RecentProject } from 'src/app/landing/recent-project.service';
import { WsResponse } from 'src/app/util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';
import { Folder } from '../../foldertree/folder.model'
import { LocalStorage } from 'src/app/util/localstorage.service';

declare var $: any;

@Component({
	selector: 'app-topic-map-folder-list',
	templateUrl: './topic-map-folder-list.component.html',
	styleUrls: ['./topic-map-folder-list.component.css']
})
export class TopicMapFolderList implements OnInit {

	public data: any = {
		'data': [
			{ "id": "ajson1", "parent": "#", "text": "TM ROOT NODE", icon: "fa fa-folder-o", type: "folder", isOtherSettings: false },
			{ "id": "ajson2", "parent": "#", "text": "TM Root node 2", icon: "fa fa-folder-o", type: "folder", isOtherSettings: true },
			{ "id": "ajson3", "parent": "ajson2", "text": "TM Child 1", icon: "fa fa-file-o", type: "file", isOtherSettings: true },
			{ "id": "ajson4", "parent": "ajson2", "text": "TM Child 2", icon: "fa fa-file-image-o", type: "image", isOtherSettings: false }
		]
	}

	public data_n: any = {
		'data': [
		]
	}

	public treeRefTM: any = null;

	public selectedFolderId: string = null;
	public treeRef: any = null;
	public folder: Folder;
	public selectedTreeItem: any = null;
	public folderListPayload: any = null;
	private parentProjectName: string;
	private loggedInUser: LoggedUser;
	public selectedFolderId_ex: string[] = [];
	public divHeight: number = (window.innerHeight) * 5 / 6.5
	public tabChanges: any;

	@HostListener('window:resize', ['$event'])
	onresize(event?) {
		this.divHeight = (window.innerHeight) * 5 / 6.5
	}
	constructor(private translate: TranslateService, private filesService: FilesService, private folderService: FolderService,
		private sharedService: SharedService, private loadingService: LoadingService) {
		translate.addLangs(["es", "en"]);
		translate.setDefaultLang("en");
		let browserLang = translate.getBrowserLang();
		translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
		this.loggedInUser = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER));
	}

	ngOnInit(): void {
		//Called after the constructor, initializing input properties, and the first call to ngOnChanges.
		//Add 'implements OnInit' to the class.
		//this.initTree();
		this.selectedTreeItem = null;
		this.folderService.getAllFolders(this);
		localStorage.removeItem('topicMapTreeEdit')
	}

	ngAfterContentInit(): void {
		//Called after ngOnInit when the component's or directive's content has been initialized.
		//Add 'implements AfterContentInit' to the class.
		this.sharedService.selectTabWhenTMBClickRespond().subscribe(option => this.deselectAllItems())
		this.tabChanges = this.sharedService.selectTopicMapFoldeTabRespond().subscribe(option => this.getFolderFileData())
	}

	public deselectAllItems() {
		if (this.treeRefTM != null) {
			// $("#topicMapTreeTM").jstree().deselect_all(true);
		} else {
			// do nothing
		}
	}

	/* 
		Reload the folder tree when tab refreshed 
	*/
	public getFolderFileData() {
		this.selectedFolderId_ex = [];
		this.folderService.getAllFolders(this);
	}

	/* 
		Load folder data to JStree 
	*/
	loadData(payload: any) {
		if (payload != null) {
			this.data_n['data'] = [];

			var item = payload.filter(item => item["parent"] === true);
			if (item != null && item.length > 0) {
				item[0]["parentId"] = "#"
				for (let i = 0; i < payload.length; i++) {
					//set icon value for topic map items
					var icon = "";
					var type = "";
					var tmbConfig = "";
					var isOtherSettings = "";
					if (payload[i]["type"] != null && payload[i]["type"] !== "") {
						switch (payload[i]["type"]) {
							case 'Topic':
								icon = "far fa-lightbulb";
								type = "Topic";
								tmbConfig = this.generateTMBConfigXML();
								isOtherSettings = "true";
								break;
							case 'Procedure':
								icon = "far fa-layer-group";
								type = "Procedure";
								tmbConfig = this.generateTMBConfigXML();
								isOtherSettings = "true";
								break;
							case 'Image':
								icon = "far fa-image";
								type = "Image";
								tmbConfig = this.generateTMBConfigXML();
								isOtherSettings = "true";
								break;
							case 'ContentItem':
								icon = "far fa-file-archive";
								type = "ContentItem";
								tmbConfig = this.generateTMBConfigXML();
								isOtherSettings = "true";
								break;
							case 'Folder':
								icon = "far fa-folder";
								type = "Folder";
								break;
							case 'TopicMap':
								icon = "far fa-map";
								type = "TopicMap";
								break;
							case 'ICECourse':
								icon = "far fa-diploma";
								type = "ICECourse";
								break;
							case 'ThreeDModel':
								type = "ThreeDModel";
								break;
							case 'Video':
								type = "Video";
								break;
							default:
								break;
						}
					} else {
						//Do nothing
					}
					this.data_n["data"].push({
						id: payload[i]["id"], parent: payload[i]["parentId"], text: payload[i]["name"], path: payload[i]["path"],
						icon: icon, type: type, data: { name: payload[i]["name"], type: type, path: payload[i]["path"], config: tmbConfig}
					})

					
				}
				if (this.treeRefTM != null) {
					// $('#topicMapTreeTM').jstree().destroy()
					$('#topicMapTreeTM').jstree('destroy');
					this.treeRefTM = null
				}
				$("#topicMapTreeTM").jstree("deselect_all");
				this.treeRefTM = $('#topicMapTreeTM').jstree({
					core: {
						multiple: false,
						themes: {
							variant: 'normal'
						},
						data: this.data_n["data"],
						check_callback: function (operation, node, node_parent, node_position, more) {
							switch (operation) {
								case 'move_node':
									return true
								case 'copy_node':
									return false
								default:
									break;
							}
						},
						force_text: true
					},
					types: {
						default: {
							// "icon" : "fa fa-file-o "
						}
					},
					plugins: ["dnd", "search", "state", "types", "wholerow"],
					search: {
						show_only_matches: true,
						show_only_matches_children: true
					},
					dnd: {
						copy: true,
						always_copy: true,
						large_drop_target: '#topicMapTreeEdit'
					},
					state: {
						key: 'topicMapTreeEdit'
					}
				});
				this.selectedTreeItem = null;
				$('#topicMapTreeTM').bind("ready.jstree", (event: any, data: any) => this.jsTreeReady(event, data))
				setTimeout(() => {
					//select tree item functionality
                    $('#topicMapTreeTM').bind("select_node.jstree", (evt, data) => this.folderSelectEvent(evt, data))
                    //drag and drop functionality
                    $(document).bind("dnd_start.vakata", (evt, data) => this.folderMoveStartEvent(evt, data))
                    //drag and drop startfunctionality
					$(document).bind("dnd_stop.vakata", (evt, data) => this.folderMoveStopEvent(evt, data))
					
                }, 250);
			}
		}
	}
	/*
	 	openNodeCallbackFunction 
	 */
	openNodeCallbackFunction(): any {
		//do nothing
	}
	
	jsTreeReady(event: any, data: any){
		console.log('jstreeReady')
		$("#topicMapTreeTM").jstree("deselect_all");
		//open node functionality
		if (this.selectedFolderId != null) {
			$('#topicMapTreeTM').jstree('select_node',this.selectedFolderId);
			$('#topicMapTreeTM').jstree().open_node(this.selectedFolderId, this.openNodeCallbackFunction(), false);
		} else {
			//do nothing
		}
	}

	initTreeReady(event: any, data: any){
		$("#topicMapTreeTM").jstree("deselect_all");
		//open node functionality
		if (this.selectedFolderId != null) {
			$('#topicMapTreeTM').jstree("select_node", this.selectedFolderId, true);
			$('#topicMapTreeTM').jstree().open_node(this.selectedFolderId, this.openNodeCallbackFunction(), false);
		} else {
			//do nothing
		}
	}
	/* 
		OnSuccess actions for service calls
	*/
	onSuccess(data: WsResponse, serviceType: WsType): void {
		this.loadingService.hideLoading();
		if (serviceType == WsType.FOLDER_VIEW) {
			//$('#topicMapTreeTM').jstree('destroy');
			this.folderListPayload = data.payload;
			this.loadData(data.payload);
		} else if (this.selectedFolderId_ex.indexOf(this.selectedFolderId) == -1 && WsType.FILE_VIEW == serviceType) {
			$('#topicMapTreeTM').jstree('destroy');
			this.loadingService.hideLoading();
			this.showFolderData(data.payload)
			
			this.initTree();
			this.selectedFolderId_ex.push(this.selectedFolderId);
		} 
	}

	/* 
		onFail actions for service calls
	*/
	onFail(res: WsResponse, serviceType: WsType): void {
		this.loadingService.hideLoading();
		if (serviceType == WsType.FOLDER_VIEW) {
			console.log('fail - ' + res.statusDescription);
		} else if (serviceType == WsType.FILE_VIEW) {
			console.log('fail - ' + res.statusDescription);
		}
	}

	/* 
		initTree will call when folder item select event triggered
	*/
	public initTree() {
		this.treeRefTM = $('#topicMapTreeTM').jstree({
			core: {
				multiple: false,
				themes: {
					variant: 'normal'
				},
				data: this.data_n.data,

				check_callback: function (operation, node, node_parent, node_position, more) {
					switch (operation) {
						case 'move_node':
							return false
						case 'copy_node':
							return false
						default:
							break;
					}
				},
				force_text: true
			},
			types: {
				default: {
					// "icon" : "fa fa-file-o "
				}
			},
			plugins: ["dnd", "search", "state", "types", "wholerow"],
			search: {
				show_only_matches: true,
				show_only_matches_children: true
			},
			dnd: {
				always_copy: true,
				drop_target: '#topicMapTreeEdit'
			}
		});
		$('#topicMapTreeTM').bind("ready.jstree", (event: any, data: any) => this.initTreeReady(event, data))
		setTimeout(() => {
			//select tree item functionality
			$('#topicMapTreeTM').bind("select_node.jstree", (evt, data) => this.folderSelectEvent(evt, data))
			//drag and drop functionality
			$(document).bind("dnd_start.vakata", (evt, data) => this.folderMoveStartEvent(evt, data))
			//drag and drop startfunctionality
			$(document).bind("dnd_stop.vakata", (evt, data) => this.folderMoveStopEvent(evt, data))
			//open node functionality
			
		}, 250);
	}

	private itemSelectEvent(evt: any, data: any) {
		console.log('[topic-map-folder-list: itemSelectEvent] (data) ' + JSON.stringify(data.node));

	}

	private folderMoveStartEvent(evt: any, data: any) {
		// console.log('[topic-map-folder-list: folderMoveStartEvent] (dnd_start.vakata)');
	}

	private folderMoveStopEvent(evt: any, data: any) {
		// console.log('[topic-map-folder-list: folderMoveStopEvent] (dnd_stop.vakata)');
	}

	/* 
		Jstree item select event
	 */
	public folderSelectEvent(evt: any, data: any) {
		console.log(data)
		console.log(event)
		this.selectedTreeItem = data
		let folderType: string = this.selectedTreeItem.node.original.type
		switch (folderType) {
			case 'Folder':
				this.selectedFolderId = data.node.id
				this.folderService.setSelectedFolderData(data.node);
				if (this.selectedTreeItem.node.children.length > 0) {
					if (this.selectedFolderId != null) {
						if (this.selectedFolderId_ex.indexOf(this.selectedFolderId) == -1 ){
							this.filesService.getFolderContentByFolderId(this);
							this.loadingService.showLoading(true, false, 'File Loading', 0)
						} else {
							//do nothing
							$('#topicMapTreeTM').jstree().open_node(this.selectedFolderId, this.openNodeCallbackFunction(), false);
						}
						//$('#topicMapTreeTM').jstree().open_node(this.selectedFolderId, this.openNodeCallbackFunction(), false);
					} else {
						//do nothing
					}
				} else {
					this.filesService.getFolderContentByFolderId(this);
					this.loadingService.showLoading(true, false, 'File Loading', 0)
				}
				// $('#topicMapTreeTM').jstree().open_node(this.selectedFolderId, this.openNodeCallbackFunction(), false);
				break;
			default:
				break;
		}
	}

	/* 
		show Folder Data when folder select
	*/
	showFolderData(payload: any) {
		if (payload != null) {
			for (let i = 0; i < payload.length; i++) {
				//set icon value for topic map items
				var icon = "";
				var type = "";
				var tmbConfig = "";
				if (payload[i]["type"] != null && payload[i]["type"] !== "") {
					switch (payload[i]["type"]) {
						case 'Topic':
							icon = "far fa-lightbulb";
							type = "Topic";
							tmbConfig = this.generateTMBConfigXML();
							break;
						case 'Procedure':
							icon = "far fa-layer-group";
							type = "Procedure";
							tmbConfig = this.generateTMBConfigXML();
							break;
						case 'Image':
							icon = "far fa-image";
							type = "Image";
							tmbConfig = this.generateTMBConfigXML();
							break;
						case 'ContentItem':
							icon = "far fa-file-archive";
							type = "ContentItem";
							tmbConfig = this.generateTMBConfigXML();
							break;
						case 'Folder':
							icon = "far fa-folder";
							type = "Folder";
							break;
						case 'TopicMap':
							icon = "far fa-map";
							type = "TopicMap";
							break;
						case 'ICECourse':
							icon = "far fa-diploma";
							type = "ICECourse";
							break;
						case 'ThreeDModel':
							type = "ThreeDModel";
							break;
						case 'Video':
							type = "Video";
							break;
						default:
							break;
					}

					if (this.selectedTreeItem.node.children.indexOf(payload[i]["id"]) > -1) {
						//do not add folder to jsTree
					} else {
						//check for the status of items
						if ( payload[i]["status"] == 'Active') {
							this.data_n["data"].push({
								id: payload[i]["id"], 
								parent: payload[i]["folderId"], 
								text: payload[i]["name"], 
								icon: icon, 
								type: type,
								extension: payload[i]["extension"],
								data: { 
									name: payload[i]["name"], 
									type: type, 
									config: tmbConfig,
									status : payload[i]["status"] 
								}, 
								isOtherSettings: true
							})
						} else {
							//Since folder doesn't has status, show them in folder tree
							if (payload[i]["type"] == 'Folder') {
								this.data_n["data"].push({
									id: payload[i]["id"], 
									parent: payload[i]["folderId"], 
									text: payload[i]["name"], 
									icon: icon, 
									type: type,
									data: { 
										name: payload[i]["name"], 
										type: type, 
										config: tmbConfig,
										status : payload[i]["status"] 
									}, 
									isOtherSettings: true
								})
							} else {
								//do nothing
								//Remove the Inactive content
							}
						}
					} 
				}
			}
		}
	}

	/* 
		generate TMB ConfigXML before moving items
	*/
	public generateTMBConfigXML() {
		var tmbConfig = document.createElement('TMBConfig');
		tmbConfig.setAttribute("TopicMapTopicId", this.sharedService.getTopicMapId());

		var orientation = document.createElement('Orientation');
		orientation.textContent = "None";
		tmbConfig.appendChild(orientation);

		var specialPaperSize = document.createElement('SpecialPaperSize');
		specialPaperSize.textContent = "None";
		tmbConfig.appendChild(specialPaperSize);

		var omitInTOC = document.createElement('OmitInTOC');
		omitInTOC.textContent = "0";
		tmbConfig.appendChild(omitInTOC);

		var pageBreak = document.createElement('PageBreak');
		pageBreak.textContent = "0";
		tmbConfig.appendChild(pageBreak);

		var oSerializer = new XMLSerializer();
		var tmbConfigString = oSerializer.serializeToString(tmbConfig);
		return tmbConfigString;
	}


	ngOnDestroy() {
		$('#topicMapTreeTM').jstree(true).clear_state();
		this.tabChanges.unsubscribe();
	}


} 