<div class="modal-header">
	<!--Title-->
	<h4 class="modal-title pull-left">{{title}}</h4>
	<button type="button" class="close pull-right" aria-label="Close" (click)="newTopicBsModalRef.hide()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>

<!--Body-->
<div class="modal-body">
	<form class="px-3" ngNativeValidate>
		<div class="form-group row">
			<label for="exampleInputEmail1">{{ 'NEW_TOPIC.FOLDER_NAME.NAME' | translate }}</label>
			<input type="text" class="form-control" id="folderNameId"
				placeholder="{{ 'NEW_TOPIC.FOLDER_NAME.PLACEHOLDER' | translate }}" [(ngModel)]="folderName"
				name="folder name" disabled required>
		</div>
		<div class="form-group row">
			<label for="exampleInputPassword1">{{ 'NEW_TOPIC.TOPIC_NAME.NAME' | translate }}<div
					class="badge-required mx-2"></div></label>
			<input type="text" class="form-control" id="topicNameId"
				placeholder="{{ 'NEW_TOPIC.TOPIC_NAME.PLACEHOLDER' | translate }}" (keypress)="loadDocumentTree($event)"
				[(ngModel)]="topicName" name="topic name" required>
		</div>
		<div class="form-group row">
			<label for="exampleInputPassword1">{{ 'NEW_TOPIC.DOCUMENT_TYPE.NAME' | translate }}<div
					class="badge-required mx-2"></div></label>
			<input type="text" class="form-control" id="documentTypeId"
				placeholder="{{ 'NEW_TOPIC.DOCUMENT_TYPE.PLACEHOLDER' | translate }}" [(ngModel)]="documentType"
				(click)="loadDocumentTypes()" name="type name" autocomplete="off" required>
		</div>
		<div class="form-group">
			<label for="exampleInputPassword1">{{ 'NEW_TOPIC.ATTRIBUTE.NAME' | translate }}</label>
			<div class="alert alert-info" role="alert" *ngIf="!haveProperties">
				No attributes for the selected file
			</div>
			<!--Attribute Table-->
			<div *ngIf="attributeList != undefined">
				<div *ngFor="let attibute of attributeList">
					<div *ngIf="attibute.attributeType != 'null'"> 
					<br>
					<table>
						<th></th>
						<th></th>
						<tr>
							<td width="100">
								<label>{{attibute.attributeName}}
									<sup>
										<i *ngIf="attibute.required=='yes'" class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i>
									</sup>
								</label>
							</td>
							<td width="300" >
								<input type="text"  *ngIf="(attibute.attributeType=='Number' || attibute.attributeType=='Text') && (attibute.attributeName != 'Document' && attibute.attributeName != 'c1' && attibute.attributeName != 'System')" 
                #texts name ="attribute" autocomplete="off" [(ngModel)]="attibute.value" style="width:100%">
								<input type="date"  *ngIf="attibute.attributeType == 'Date'" name ="attribute" [(ngModel)]="attibute.value" style="width:100%">
								<div *ngIf= "checkDocument(attibute.attributeType,attibute.attributeName)">
								<select #options name ="attribute" [(ngModel)]="attibute.value" style="width:100%">
									<option *ngFor="let item of attibute.documents" [ngValue]="item.id">
										{{item.documentName}}
									</option>
								</select>
								</div>
								<div *ngIf= "checkComponent(attibute.attributeType,attibute.attributeName)">
									<select  #options name ="attribute" [(ngModel)]="attibute.value" style="width:100%">
										<option *ngFor="let item of attibute.components" [ngValue]="item.id">
											{{item.componentName}}
										</option>
									</select>
									</div>
									<div *ngIf= "checkSystem(attibute.attributeType,attibute.attributeName)">
										<select  #options name ="attribute" [(ngModel)]="attibute.value" style="width:100%">
											<option *ngFor="let item of attibute.systems" [ngValue]="item.systemId">
												{{item.systemName}}
											</option>
										</select>
										</div>
							</td>
						</tr>
					</table>
					</div>
				</div>
			</div>
		</div>
		<div class="form-group row float-right">
			<button id="submit" type="submit" class="btn btn-primary btn-sm"
				(click)="onSubmitButtonClick()">Submit</button>
		</div>

	</form>
</div>