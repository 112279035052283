<div class="container-fluid page-container">
    <nav class="page-header">
        <div class="row">
            <div class="col">
                <p class="mb-2 h5">
                    <b>Issue Checksheets</b>
                </p>
            </div>
        </div>
    </nav>
    <div class="row">
        <div class="col-sm-12 mb-2">
            <symbiosis-search [comp]="'issuechecksheets'" id="issue_cs_search" (searchFunc)="searchFunc($event)"></symbiosis-search>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-md-6">
            <ul class="list-group list-group-horizontal-sm">
                <li class="list-group-item d-flex align-items-center"><span class="dot pl-4 colorIssued mr-2 ml-2">
                    </span>Issued</li>
                <li class="list-group-item d-flex align-items-center"><span class="dot pl-4 colorApproved mr-2 ml-2">
                    </span>Approved</li>
                <li class="list-group-item d-flex align-items-center text-nowrap"> <span
                        class="dot pl-4 colorQueue mr-2 ml-2"> </span>On Queue</li>
                <li class="list-group-item d-flex align-items-center"><span class="dot pl-4 colorErr mr-2 ml-2">
                    </span>Error</li>
            </ul>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col">
            <ag-grid-angular #agGrid style="width: 100%; height: 375px;" class="ag-theme-balham" [rowData]="rowData"
                [columnDefs]="columnDefs" [gridOptions]="gridOptions" [modules]="modules"
                [sideBar]="{ toolPanels: ['columns'] }" [pagination]="true" [paginationPageSize]="10"
                [overlayLoadingTemplate]="overlayLoadingTemplate" [overlayNoRowsTemplate]="overlayNoRowsTemplate"
                [getRowNodeId]="getRowNodeId" (gridReady)="onGridReady($event)">
            </ag-grid-angular>
        </div>
    </div>
    <div class="row" *ngIf="generateOut">
        <div class="col-lg-6 checkboxes">
            <label>Send notification mail</label>
            <input type="checkbox" id="sendMailCheckBox" [(ngModel)]=isSendMail (change)="checkboxChecked($event)">
        </div>
        <div class="col-lg-6"></div>
    </div>
    <div class="row" *ngIf="generateOut">
        <div class="col-lg-12 col-12">
            <button type="submit" id="facility_save" class="btn btn-primary btn-sm float-left"
                (click)="generateOutputClick()">Generate</button>
        </div>
    </div>
</div>
