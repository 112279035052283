import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthRequest } from './auth-request.model';
import { AlertService } from '../../util/alert/alert.service';
import Fingerprint2 from 'fingerprintjs2';
import { LoadingService } from '../../util/loading/loading.service';
import { Auth } from "../../user/login/auth.service";
import { WsCallback } from '../../util/ws-callback.interface';
import { WsType } from '../../util/ws-type';
import { WsResponse } from '../../util/ws-response.model';
import { LocalStorage } from '../../util/localstorage.service';
import { LoggedUser } from '../logged-user.model';
import { SocialAuthService, GoogleLoginProvider } from 'angularx-social-login';
import { Openid } from './openid.service';
import { SessionValidationService } from './session-validation-request.service';
import { ResetUrlService } from './forgotpassword/reset-url.service';
import { RecentProject } from '../../landing/recent-project.service';
import { UserVariable } from '../../util/common/user-variable';
import { CapabilityService } from '../../util/capability.service';
import { CommonUtil } from 'src/app/util/common/common-util';
import { Auth0Service } from './../auth0.service';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/util/shared.service';
@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, WsCallback {
	@ViewChild('login_username') userNameInput: ElementRef;
  	@ViewChild('login_password') passwordInput: ElementRef;

	public authRequest: AuthRequest;
	public tarns: string;
	private loggedInUser: LoggedUser;
	public showPassword: boolean = false
	private forwardPath: string;
	private projectId: string;
	private clientId: string;

    constructor(private router: Router, private translate: TranslateService, private auth: Auth,
        private alertService: AlertService, private loadingService: LoadingService, private socialAuthService: SocialAuthService,
        private openIdLogin: Openid, public sessionValidationRequest: SessionValidationService,
		public passwordResetURL: ResetUrlService, public recentProjectService: RecentProject, private route: ActivatedRoute, private openIdService: Openid, private capabilityService: CapabilityService, private commonUtil: CommonUtil,
		private auth0 : Auth0Service, private sharedService : SharedService ) {
		translate.addLangs(["es", "en"]);
		translate.setDefaultLang("en");
		//recentProjectService.setCallback(this);

		let browserLang = translate.getBrowserLang();
		translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');

		//localStorage.setItem(LocalStorageConst.UI_LAYOUT, JSON.stringify(UI_LAYOUT.UIL_1))
		this.loadingService.hideLoading();
	}

	ngOnInit() {
		// Initializing the auth request.
		this.authRequest = new AuthRequest();
		this.authRequest.authtype = "2";

		this.route.paramMap.subscribe(params => {
			if (params.get('fwd') != null) {
				this.forwardPath = params.get('fwd');
			}
		});

		// Generating a unique ID for the browser
		var options = { excludeAdBlock: true, excludeJsFonts: true, excludeScreenResolution: true, excludeAvailableScreenResolution: true, excludeUserAgent: true};
		new Fingerprint2(options).get((data) => this.setDeviceId(data));

		//check user session
		if ((JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER)) != null) &&
			(JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER)).authtoken != null) &&
			(UserVariable.getUserToken() != null) ) {
			this.loggedInUser = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER));
			this.sessionValidationRequest.validateSession(this);
		}
	}
	private susbscription: Subscription;

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.authRequest.username = this.userNameInput.nativeElement.value;
			this.authRequest.password = this.passwordInput.nativeElement.value;
		  }, 1000);
	}

	ngAfterContentInit() {
		console.log('========ngAfterContentInit=======start=================');
		this.susbscription = this.sharedService.auth0LoginResponse().subscribe((option: any) => this.auth0Login(option))
		console.log('========ngAfterContentInit=======end=================');
    }
	auth0Login(option: any) {
		console.log('========auth0Login=======start=================');

		console.log('========auth0Login=======start=======option=========='+JSON.stringify(option));
		console.log('========auth0Login=======start=======option.userEmail=========='+option.userEmail);
		var authRequest: AuthRequest = new AuthRequest();
						authRequest.email = btoa(option.userEmail);
						authRequest.authtype = btoa("1");
						let deviceId = localStorage.getItem(LocalStorage.DEVICE_ID);
						authRequest.deviceid = btoa(deviceId);
						this.openIdLogin.openIDLogin(JSON.stringify(authRequest), this);
						console.log('========auth0Login=======end=================');
	}

	public setDeviceId(deviceId) {
		this.authRequest.deviceid = deviceId;
		localStorage.setItem(LocalStorage.DEVICE_ID, deviceId);
	}

	public loginBtnClickEvent(): void {
		//this.router.navigateByUrl('/landing');
		// this.alertService.success('Success')
		if (this.authRequest.username != null && this.authRequest.password != null) {
			//encoding data
			let logRequest = new AuthRequest();
			logRequest.authtype = btoa(this.authRequest.authtype);
			logRequest.deviceid = btoa(this.authRequest.deviceid);
			logRequest.email = btoa(this.authRequest.email);
			logRequest.password = btoa(this.authRequest.password);
			logRequest.username = btoa(this.authRequest.username);

			this.auth.login(JSON.stringify(logRequest), this);
		}
	}

	public forgotPasswordEvent() {
		//this.router.navigateByUrl('/forgotpassword');
		if (this.authRequest.username == undefined || this.authRequest.username == '') {
			this.alertService.error('Please enter username');
			return;
		}
		this.passwordResetURL.setCallback(this);
		this.passwordResetURL.getPasswordResetUrl(this.authRequest.username);
	}

	onSuccess(data: WsResponse, serviceType: WsType): void {
		console.log("login component-----" + serviceType);
		if (serviceType == WsType.LOGIN) {
			var userJason = JSON.parse(JSON.stringify(data.payload));
			var user = new LoggedUser(userJason.currentusername,
			userJason.currentuserid, userJason.authtoken, userJason.firstName, userJason.lastName);
			let deviceId = localStorage.getItem(LocalStorage.DEVICE_ID);
			localStorage.clear();
			localStorage.setItem(LocalStorage.DEVICE_ID, deviceId);
			localStorage.setItem(LocalStorage.LOGGED_USER, JSON.stringify(user));
			//this will available only for client users. Null for admins
			UserVariable.clientId = data.payload.clientid != null ? JSON.stringify(data.payload.clientid) : "";
			UserVariable.isClient = data.payload.isclient;
			UserVariable.userId = data.payload.currentuserid;
			UserVariable.preferences = data.payload.preferences != null ? JSON.stringify(data.payload.preferences) : "";
            UserVariable.tokenTimeout();
			//Check default project data
			if ((userJason.recentproject != null) && (userJason.recentproject != undefined)
					&& (userJason.recentproject.isDefaultProject == 1) && (userJason.recentproject.projectType == 1) ) {
				UserVariable.projectId = userJason.recentproject.projectId;
				UserVariable.clientId = userJason.recentproject.clientId;
				UserVariable.projectName = userJason.recentproject.projectName;

				localStorage.setItem(LocalStorage.PROJECT_NAME, userJason.recentproject.projectName);
				localStorage.setItem(LocalStorage.DEFAULT_PROJECT_ID, userJason.recentproject.projectId);
				localStorage.setItem(LocalStorage.PROJECT_TYPE, '1');
				sessionStorage.setItem(LocalStorage.PROJECT_TYPE, '1');
				localStorage.setItem(LocalStorage.DEFAULT_PROJECT_TYPE, '1');
				localStorage.setItem(LocalStorage.PROJECT_ID, userJason.recentproject.projectId);
				localStorage.setItem(LocalStorage.CLIENT_ID, userJason.recentproject.clientId);
				localStorage.setItem(LocalStorage.DEFAULT_PROJECT_NAME, userJason.recentproject.projectName);
				localStorage.setItem(LocalStorage.DEFAULT_CLIENT_ID, userJason.recentproject.clientId);

			} else {
				//No Default Project 
			}

			//add capabilities
			this.getCapabilityList();
         
			//Forward to requested page if it is available
			if (this.forwardPath != undefined) {
				console.log('forwardPath= '+ this.forwardPath)
				if(this.checkFwdParameters()){
					this.getProject();
				} else {
					this.proceedForward();
				}
			} else {
				console.log(userJason);
				if ((userJason.recentproject != null) && (userJason.recentproject != undefined)
						&& (userJason.recentproject.isDefaultProject == 1) && (userJason.recentproject.projectType == 1)) {
					this.router.navigateByUrl('home');
				} else {
					this.router.navigateByUrl('landing');
				}
			}
		} else if (serviceType == WsType.OPEN_ID_LOGIN) {
			var userJason = JSON.parse(JSON.stringify(data.payload));
			var user = new LoggedUser(userJason.currentusername,
				userJason.currentuserid, userJason.authtoken, userJason.firstName, userJason.lastName);
			let deviceId = localStorage.getItem(LocalStorage.DEVICE_ID);
			localStorage.clear();
			localStorage.setItem(LocalStorage.DEVICE_ID, deviceId);
			localStorage.setItem(LocalStorage.LOGGED_USER, JSON.stringify(user));
			//this.router.navigateByUrl('landing');
			//Get the default project of the user
			//this.recentProjectService.getDefaultRecentProject(userJason.currentuserid, this);
			//this will available only for client users. Null for admins
			UserVariable.clientId = data.payload.clientid != null ? JSON.stringify(data.payload.clientid) : "";
			UserVariable.isClient = data.payload.isclient;
			UserVariable.userId = data.payload.currentuserid;
            UserVariable.preferences = data.payload.preferences != null ? JSON.stringify(data.payload.preferences) : "";
            UserVariable.tokenTimeout();
			//Check default project data
			if ((userJason.recentproject != null) && (userJason.recentproject != undefined)
					&& (userJason.recentproject.isDefaultProject == 1) && (userJason.recentproject.projectType == 1)) {
				UserVariable.projectId = userJason.recentproject.projectId;
				UserVariable.clientId = userJason.recentproject.clientId;
				UserVariable.projectName = userJason.recentproject.projectName;
				localStorage.setItem(LocalStorage.PROJECT_NAME, userJason.recentproject.projectName);
				localStorage.setItem(LocalStorage.DEFAULT_PROJECT_ID, userJason.recentproject.projectId);
				localStorage.setItem(LocalStorage.PROJECT_TYPE, '1');
				sessionStorage.setItem(LocalStorage.PROJECT_TYPE, '1');
				localStorage.setItem(LocalStorage.DEFAULT_PROJECT_TYPE, '1');
				localStorage.setItem(LocalStorage.PROJECT_ID, userJason.recentproject.projectId);
				localStorage.setItem(LocalStorage.CLIENT_ID, userJason.recentproject.clientId);
				localStorage.setItem(LocalStorage.DEFAULT_PROJECT_NAME, userJason.recentproject.projectName);
				localStorage.setItem(LocalStorage.DEFAULT_CLIENT_ID, userJason.recentproject.clientId);
				
			} else {
				//No Default Project 
			}

			//add capabilities
			this.getCapabilityList();

			//Forward to requested page if it is available
			if (this.forwardPath != undefined) {
				console.log('forwardPath= '+ this.forwardPath)
				if(this.checkFwdParameters()){
					this.getProject();
				} else {
					this.proceedForward();
				}
			} else {
				//Otherwise forward to the normal page
				if ((userJason.recentproject != null) && (userJason.recentproject != undefined)
						&& (userJason.recentproject.isDefaultProject == 1) && (userJason.recentproject.projectType == 1)) {
					this.router.navigateByUrl('home');
				} else {
					this.router.navigateByUrl('landing');
				}
			}
		} else if (serviceType == WsType.SESSION_VALIDATION) {
			//this.router.navigateByUrl('landing');
			//Get the default project of the user
			this.recentProjectService.getDefaultRecentProject(this.loggedInUser.userId.toString(), this);
		} else if (serviceType == WsType.PASSWORD_RESET_URL) {
			this.alertService.info(data.statusDescription, false);
		} else if (serviceType == WsType.GET_DEFAULT_RECENT_PROJECT) {
			console.log("login --- Get Default Recent Projet");
			if (data.payload != null) {
				//There is a default project
				if(data.payload.isDefaultProject == '1'){
					localStorage.setItem(LocalStorage.DEFAULT_PROJECT_ID, data.payload.projectId);
					localStorage.setItem(LocalStorage.DEFAULT_PROJECT_TYPE, '1');
					localStorage.setItem(LocalStorage.DEFAULT_PROJECT_NAME, data.payload.projectName);
					localStorage.setItem(LocalStorage.DEFAULT_CLIENT_ID, data.payload.clientId);
				}
				sessionStorage.setItem(LocalStorage.PROJECT_TYPE, '1');
				localStorage.removeItem(LocalStorage.PROJECT_NAME);
				UserVariable.clientId = data.payload.clientId;
				UserVariable.projectId = data.payload.projectId;
				UserVariable.projectName = data.payload.projectName;
				this.router.navigateByUrl('home');
			} else {
				//There is no default project
				this.router.navigateByUrl('landing');
			}
		} else if (serviceType == WsType.GET_GOOGLE_KEYS) {
			//save data in the local storage
			var payload = JSON.parse(data.payload);
			localStorage.setItem(LocalStorage.GOOGLE_API_KEY, payload.key);
			localStorage.setItem(LocalStorage.GOOGLE_CLIENT_ID, payload.clientid);

			//this.doGoogleAuthentication();
		}  else if(serviceType == WsType.GET_PROJECT){
			if (data.payload != null){
				this.changeProject(data.payload)
			} else {
				console.log("Project not found. Navigate to landing")
				this.alertService.error('Project not found');
				this.router.navigateByUrl('landing');
			}
		} else if (serviceType == WsType.GET_AUTH0_CONFIGURATIONS) {
			var payload = JSON.parse(JSON.stringify(data.payload));
			localStorage.setItem(LocalStorage.AUTH0_CONFIGURATIONS, payload);
			var username = this.authRequest.username;
	  		var usernameData = "{ \"username\": \""+username+"\"}";
			this.auth0.authorise(localStorage.getItem(LocalStorage.AUTH0_CONFIGURATIONS),usernameData);
		}
	}

	onFail(res: WsResponse, serviceType: WsType): void {
		this.alertService.clear()
		if (serviceType == WsType.LOGIN) {
			if (res.statusCode == "542") {
				var userJason = JSON.parse(JSON.stringify(res.payload));
				//var user = new LoggedUser(userJason.currentusername,userJason.currentuserid, userJason.authtoken, userJason.firstName, userJason.lastName);
				localStorage.setItem(LocalStorage.USER_ID, userJason.currentuserid);
				this.router.navigateByUrl('kvc');
			} else if (res.statusCode == "541") {
				//expired password
				var userJason = JSON.parse(JSON.stringify(res.payload));
				var user = new LoggedUser(userJason.currentusername,
					userJason.currentuserid, userJason.authtoken, userJason.firstName, userJason.lastName);
				let deviceId = localStorage.getItem(LocalStorage.DEVICE_ID);
				localStorage.clear();
				localStorage.setItem(LocalStorage.DEVICE_ID, deviceId);
				localStorage.setItem(LocalStorage.USER_ID, userJason.currentuserid);
				localStorage.setItem(LocalStorage.LOGGED_USER, JSON.stringify(user));
				this.alertService.error(res.statusDescription, false);
				setTimeout(() => this.router.navigateByUrl('changepassword'), 1000);
			} else {
				//show the error message
				this.alertService.error(res.statusDescription, false);
			}
		} else if (serviceType == WsType.OPEN_ID_LOGIN) {
			this.alertService.error(res.statusDescription, false);
		} else if (serviceType == WsType.SESSION_VALIDATION) {
			this.alertService.error(res.statusDescription, true);
			setTimeout(() => {
				console.log(this.forwardPath)
				if (this.forwardPath != undefined) {
					this.router.navigate(['login', { 'fwd': this.forwardPath }])
				} else {
					this.router.navigateByUrl('login')
				}
			}, 1000);
		} else if (serviceType == WsType.PASSWORD_RESET_URL) {
			this.alertService.error(res.statusDescription, false);
		} else if (serviceType == WsType.GET_DEFAULT_RECENT_PROJECT) {
			localStorage.removeItem(LocalStorage.PROJECT_NAME);
			this.alertService.error(res.statusDescription, false);
		} else if (serviceType == WsType.GET_GOOGLE_KEYS) {
			this.router.navigateByUrl('login');
		} else if(serviceType == WsType.GET_PROJECT){
			this.alertService.error(res.statusDescription, false);
			this.router.navigateByUrl('landing');
		} else if (serviceType == WsType.GET_AUTH0_CONFIGURATIONS) {
			this.router.navigateByUrl('login');
		}
	}

	/* doGoogleAuthentication(): void {
		if (localStorage.getItem(LocalStorage.GOOGLE_API_KEY) != null || localStorage.getItem(LocalStorage.GOOGLE_CLIENT_ID) != null) {
			this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then(
				(userData) => {
					var authRequest: AuthRequest = new AuthRequest();
					authRequest.email = btoa(userData.email);
					authRequest.authtype = btoa("1");
					authRequest.deviceid = btoa(this.authRequest.deviceid);
					this.openIdLogin.openIDLogin(JSON.stringify(authRequest), this);
				},
				(reason) => {
					console.log("reason "+reason);
					this.alertService.error('Authorization Error Occurred', false);
				}
			);
		} else {
			this.openIdService.getGoogleAPIKeys(this);
		}
   } */
	/**
	 * Call Auth0 for openId authentication
	 */ 
   async auth0Authentication(): Promise<void>{
	   await this.openIdService.getAuth0Configurations(this);

	   var username = this.authRequest.username;
	   var usernameData = "{ \"username\": \""+username+"\"}";
	   this.auth0.authorise(localStorage.getItem(LocalStorage.AUTH0_CONFIGURATIONS),usernameData);
   }


   private getCapabilityList() {
		this.capabilityService.getAllCapabilityList()
			.subscribe(data => {
				localStorage.setItem(LocalStorage.CAPABILITY_LIST, JSON.stringify(data.payload))
				if (data.payload.length !== 0) {
					localStorage.setItem(LocalStorage.USER_ACCESSIBILITY, "system_level");
				} else {
					localStorage.setItem(LocalStorage.USER_ACCESSIBILITY, "project_client_level");
				}
			},
				error => {

				})

	}

	/**
	 * check projectId and userId in requested url
	 */
	private checkFwdParameters(): boolean{
		let needToChangeProject: boolean = false
		var urlParams = new URLSearchParams(this.forwardPath );
		this.projectId = urlParams.get("projectId");
		if (this.projectId == null) {
			this.projectId = urlParams.get("projectid");
		}
		this.clientId = urlParams.get("clientId");
		if (this.clientId == null) {
			this.clientId = urlParams.get("clientid");
		}
		
		if ((this.clientId != null) && (this.projectId != null)) {
			needToChangeProject = true;
		} else {
			needToChangeProject = false;
		}
		return needToChangeProject;
	}

	getProject(){
		UserVariable.projectId = this.projectId;
		UserVariable.clientId = this.clientId;
		let token = UserVariable.getUserToken()
		this.recentProjectService.getProject(token, this)
	}

	/**
	 * change project and forward to requested page 
	 * @param projectData 
	 */
	changeProject(projectData: any){
		UserVariable.projectId = projectData.id;
		UserVariable.clientId = projectData.clientId;
		UserVariable.projectName = projectData.projectName;
		var recentProjectModel = {
			projectId: projectData.id,
			userId: UserVariable.userId,
			isDefaultProject: 0,
			projectType: 1
		}
		localStorage.setItem(LocalStorage.CLIENT_NAME, projectData.clientName);
		localStorage.setItem(LocalStorage.PROJECT_NAME, projectData.projectName);
		localStorage.setItem(LocalStorage.PROJECT_TYPE, '1');
		sessionStorage.setItem(LocalStorage.PROJECT_TYPE, '1');
		localStorage.setItem(LocalStorage.PROJECT_ID, UserVariable.projectId);
		sessionStorage.setItem(LocalStorage.PROJECT_ID, UserVariable.projectId);
		localStorage.setItem(LocalStorage.CLIENT_ID, projectData.clientId);
		this.recentProjectService.addUpdateRecentProject(JSON.stringify(recentProjectModel), this);

		this.router.navigateByUrl(this.forwardPath);
	}

	/**
	 * without selecting a project user can visit only adminSettings and landing pages
	 */
	proceedForward(){
		if(this.forwardPath != undefined){
			let pathName = this.forwardPath.split( '?' )[0]
			if (pathName == 'adminSettings' || pathName == 'landing') {
				this.router.navigateByUrl(this.forwardPath);
			} else if (!(UserVariable.projectId == '' || UserVariable.projectId == null)) {
				this.router.navigateByUrl(this.forwardPath);
			} else {
				console.log('no selected project forward to landing page')
				this.router.navigateByUrl('landing');
			}
		} else {
			this.router.navigateByUrl('landing');
		}
	}
}
