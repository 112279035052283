import { UserVariable } from 'src/app/util/common/user-variable';
import { CommonUtil } from 'src/app/util/common/common-util';
import { HttpClient, HttpParams } from '@angular/common/http';
import { WsResponse } from 'src/app/util/ws-response.model';
import { map, catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { ServiceUrls } from 'src/app/util/service-urls';
import { Injectable } from '@angular/core';
import { WsCallback } from 'src/app/util/ws-callback.interface';
import { WsType } from 'src/app/util/ws-type';

@Injectable({
    providedIn: 'root'
  })
export class WorkflowService{
    private rowData: any = null;

    constructor(private http: HttpClient, private commonUtil: CommonUtil) {
       
    }
    
    public setRowData(rowData: any) {
        this.rowData = rowData;
    }

    getActiveWorkFlowsPerProjectByItemType(itemType: string){
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateLogin(token)){
            return
        }
        const options = {params: new HttpParams().set("token", token).set("itemType", itemType)}
        return this.http.get(ServiceUrls.GET_ACTIVE_WORKFLOW_TEMPLATES_BY_ITEM_TYPE, options).
        pipe(
            map(response => {
                var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.code,
                        modified.status.name,
                        modified.status.description,
                        modified.payload);
            }),
            catchError(error => {
                var modified = JSON.parse(JSON.stringify(error.error));
                    var res = new WsResponse(
                        modified.status.code,
                        modified.status.name,
                        modified.status.description,
                        modified.payload);
                    return throwError(res)
            })
        );
    }

    getWorkflowTemplateListsWithoutMappingByProjectId(){
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateLogin(token)){
            return
        }
        const options = {params: new HttpParams().set("token", token)}
        return this.http.get(ServiceUrls.GET_WORKFLOW_TEMPLATES_WITHOUT_MAPPING_BY_PROJECT, options).
        pipe(
            map(response => {
                var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.code,
                        modified.status.name,
                        modified.status.description,
                        modified.payload);
            }),
            catchError(error => {
                var modified = JSON.parse(JSON.stringify(error.error));
                    var res = new WsResponse(
                        modified.status.code,
                        modified.status.name,
                        modified.status.description,
                        modified.payload);
                    return throwError(res)
            })
        );
    }

    getAllActiveUsersForProject(projectId: string): Observable<WsResponse> {
		console.log("WorkflowService.getAllActiveUsersForProject() == > ServiceURL= " + ServiceUrls.getAllActiveUsers(projectId))
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("token", token) }

		return this.http.get(ServiceUrls.getAllActiveUsers(projectId), options).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
    }
    
    getTasksByTemplateId(templateId: string){
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return
        }
        
       // const options = {params: new HttpParams().set("token", token).set("templateId", templateId)}
        
        return this.http.get(ServiceUrls.getTasksByTemplateId(token, templateId)).pipe(
        map(response =>{
            var modified = JSON.parse(JSON.stringify(response));
            return new WsResponse(
                modified.status.code,
                modified.status.name,
                modified.status.description,
                modified.payload);
        }),
        catchError(error =>{
            var modified = JSON.parse(JSON.stringify(error.error));
            var res = new WsResponse(
                modified.status.code,
                modified.status.name,
                modified.status.description,
                modified.payload);
            return throwError(res)
        })
        );
    }

    assignWorkflowTemplateToProject(data: string, wsCallBack: WsCallback){
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return
        }

        return this.http.post(ServiceUrls.assignWorkflowTemplate(token), data).subscribe(data => {
            var modified = JSON.parse(JSON.stringify(data));
            var res = new WsResponse(
                    modified.status.code,
                    modified.status.name,
                    modified.status.description,
                    modified.payload);
            wsCallBack.onSuccess(res, WsType.ASSIGN_WORKFLOW_TEMPLATE);
        },
        error => {
            var modified = JSON.parse(JSON.stringify(error.error));
            var res = new WsResponse(
                modified.status.code,
                modified.status.name,
                modified.status.description,
                modified.payload);
            wsCallBack.onFail(res, WsType.ASSIGN_WORKFLOW_TEMPLATE);
        });
    }

    updateWorkflowTemplateTasks(data: string, wsCallBack: WsCallback){
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return
        }

        return this.http.post(ServiceUrls.updateWorkflowTemplateTasks(token), data).subscribe(data => {
            var modified = JSON.parse(JSON.stringify(data));
            var res = new WsResponse(
                    modified.status.code,
                    modified.status.name,
                    modified.status.description,
                    modified.payload);
            wsCallBack.onSuccess(res, WsType.UPDATE_WORKFLOW_TEMPLATE_TASKS);
        },
        error => {
            var modified = JSON.parse(JSON.stringify(error.error));
            var res = new WsResponse(
                modified.status.code,
                modified.status.name,
                modified.status.description,
                modified.payload);
            wsCallBack.onFail(res, WsType.UPDATE_WORKFLOW_TEMPLATE_TASKS);
        });
    }

    getDocusignWorkFlowEntriesByProjectWorkflowTemplateId(projWorkflowTemplateId: string, wsCallBack: WsCallback){
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return
        }
        
        return this.http.get(ServiceUrls.getDocusignWorkFlowEntriesByProjectWorkflowTemplateId(token, projWorkflowTemplateId)).subscribe(data => {
            var modified = JSON.parse(JSON.stringify(data));
            var res = new WsResponse(
                    modified.status.code,
                    modified.status.name,
                    modified.status.description,
                    modified.payload);
            wsCallBack.onSuccess(res, WsType.GET_WORKFLOW_DOCUSIGN_ENTRIES);
        },
        error => {
            var modified = JSON.parse(JSON.stringify(error.error));
            var res = new WsResponse(
                modified.status.code,
                modified.status.name,
                modified.status.description,
                modified.payload);
            wsCallBack.onFail(res, WsType.GET_WORKFLOW_DOCUSIGN_ENTRIES);
        }); 
    }

    getPCCById(pccId: any, wsCallBack: WsCallback) {
		console.log("SystemTurnoverNoticeService.getPCCById() == > ServiceURL= " + ServiceUrls.getPCCById(pccId))
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("token", token) }

		this.http.get(ServiceUrls.getPCCById(pccId), options).
			subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
                    wsCallBack.onSuccess(res, WsType.GET_PCC_BY_ID);
			},
			error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
                    wsCallBack.onFail(res, WsType.GET_PCC_BY_ID);
			});
    }
    
    getStnById(stnId: any, wsCallBack: WsCallback) {
		console.log("SystemTurnoverNoticeService.getStnById() == > ServiceURL= " + ServiceUrls.getStnById(stnId))
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("token", token) }

		this.http.get(ServiceUrls.getStnById(stnId), options).
			subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
                    wsCallBack.onSuccess(res, WsType.GET_STN_BY_ID);
			},
			error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
                    wsCallBack.onFail(res, WsType.GET_STN_BY_ID);
			});
    }
    
    getCommCompletionById(ccId: any, wsCallBack: WsCallback) {
		console.log("CommCompletionService.getCommCompletionById() == > ServiceURL= " + ServiceUrls.getCommCompletionById(ccId))
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("token", token) }

        this.http.get(ServiceUrls.getCommCompletionById(ccId), options).
			subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
                    wsCallBack.onSuccess(res, WsType.GET_CCC_BY_ID);
			},
			error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
                    wsCallBack.onFail(res, WsType.GET_CCC_BY_ID);
            });
    }
    
    getPersonDocuSignStatus(docuSignId: any): Observable<WsResponse> {
		
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("token", token) }
		let url = ServiceUrls.getPersonDocuSignStatusUrl(docuSignId);
		console.log("getPersonDocuSignStatus == > ServiceURL= " + url)

		return this.http.get(url, options).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
    }
    
    downloadFile(projectId: string, documentId: string, mode: string, type: string, fileName: string) {
        return this.http.get(ServiceUrls.DOWNLOAD_FILE,
            {
              responseType: "blob", params: new HttpParams().set("projectId", projectId).set("documentId", documentId)
                  .set("mode", mode).set("type", type).set("fileName", fileName)
            });
      }
}