<button (click)="delete()" class="btn btn-sm btn-outline-dark py-0 my-1 px-0 border-0">
    <i class="fas fa-trash-alt mx-2" aria-hidden="true"></i>
</button>

<ng-template #deleteConfirmModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{ 'MANAGE_ADHOC_REPORTS.DELETE_CONFIRM' | translate}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="deleteModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body ">
        <h5>{{ 'MANAGE_ADHOC_REPORTS.DELETE_CONFIRM_MESSAGE' | translate}}</h5>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="deleteConfirm()">
            {{ 'MANAGE_ADHOC_REPORTS.YES' | translate}} </button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal"
            (click)="deleteModalRef.hide()">
            {{ 'CANCEL' | translate}} </button>
    </div>
</ng-template>