<div>
	<div class="py-2 px-0 mt-2">
		<ul class="col-sm-12 px-0 py-2 list-group mt-2">
			<li class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
				[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'tag', 'custom-list-item-dark': selectedMenuItem != 'tag'}"
				(click)="sideItemClick('tag')">
				<h6 class="flaot-left ml-2 mb-0 sidebar-list-text">
					<i class="far fa-tags mx-2" aria-hidden="true"></i> {{ 'REPORTS.REPORTS_SIDEBAR_TAG' | translate }}
				</h6>
			</li>
			<li class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
				[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'statement', 'custom-list-item-dark': selectedMenuItem != 'statement'}"
				(click)="sideItemClick('statement')">
				<h6 class="flaot-left ml-2 mb-0 sidebar-list-text">
					<i class="far fa-edit mx-2" aria-hidden="true"></i> {{ 'REPORTS.REPORTS_SIDEBAR_STATEMENT' | translate }}
				</h6>
			</li>
			<li class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
				[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'document', 'custom-list-item-dark': selectedMenuItem != 'document'}"
				(click)="sideItemClick('document')">
				<h6 class="flaot-left ml-2 mb-0 sidebar-list-text">
					<i class="far fa-book mx-2" aria-hidden="true"></i> {{ 'REPORTS.REPORTS_SIDEBAR_DOCUMENT' | translate }}
				</h6>
			</li>
			<li class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
				[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'system', 'custom-list-item-dark': selectedMenuItem != 'system'}"
				(click)="sideItemClick('system')">
				<h6 class="flaot-left ml-2 mb-0 sidebar-list-text">
					<i class="far fa-atom mx-2" aria-hidden="true"></i> {{ 'REPORTS.REPORTS_SIDEBAR_SYSTEM' | translate }}
				</h6>        
			</li>
			<li class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
				[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'component', 'custom-list-item-dark': selectedMenuItem != 'component'}"
				(click)="sideItemClick('component')">
				<h6 class="flaot-left ml-2 mb-0 sidebar-list-text">
					<i class="far fa-chart-network mx-2" aria-hidden="true"></i> {{ 'REPORTS.REPORTS_SIDEBAR_COMPONENT' | translate }}
				</h6>  		
			</li>
			<li class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
				[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'facility', 'custom-list-item-dark': selectedMenuItem != 'facility'}"
				(click)="sideItemClick('facility')">
				<h6 class="flaot-left ml-2 mb-0 sidebar-list-text">
					<i class="fal fa-industry-alt mx-2" aria-hidden="true"></i> {{ 'REPORTS.REPORTS_SIDEBAR_FACILITY' | translate }}
				</h6>
			</li>
			<li class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
				[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'topic', 'custom-list-item-dark': selectedMenuItem != 'topic'}"
				(click)="sideItemClick('topic')">
				<h6 class="flaot-left ml-2 mb-0 sidebar-list-text">
					<i class="fal fa-inbox mx-2" aria-hidden="true"></i> {{ 'REPORTS.REPORTS_SIDEBAR_TOPIC' | translate }}
				</h6>
			</li>
			<li class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
				[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'procedure', 'custom-list-item-dark': selectedMenuItem != 'procedure'}"
				(click)="sideItemClick('procedure')">
				<h6 class="flaot-left ml-2 mb-0 sidebar-list-text">
					<i class="fal fa-layer-group mx-2" aria-hidden="true"></i> {{ 'REPORTS.REPORTS_SIDEBAR_PROCEDURE_GRAPHIC' | translate }}
				</h6>
			</li>			
			<li class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
				[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'image', 'custom-list-item-dark': selectedMenuItem != 'image'}"
				(click)="sideItemClick('image')">
				<h6 class="flaot-left ml-2 mb-0 sidebar-list-text">
					<i class="fal fa-images mx-2" aria-hidden="true"></i> {{ 'REPORTS.REPORTS_SIDEBAR_IMAGE' | translate }}
				</h6>
			</li>
			<li class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
				[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'documentType', 'custom-list-item-dark': selectedMenuItem != 'documentType'}"
				(click)="sideItemClick('documentType')">
				<h6 class="flaot-left ml-2 mb-0 sidebar-list-text">
					<i class="fal fa-file-code mx-2" aria-hidden="true"></i> {{ 'REPORTS.REPORTS_SIDEBAR_DOCUMENT_TYPE' | translate }}
				</h6>
			</li>
			<li class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
				[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'course', 'custom-list-item-dark': selectedMenuItem != 'course'}"
				(click)="sideItemClick('course')">
				<h6 class="flaot-left ml-2 mb-0 sidebar-list-text">
					<i class="fas fa-graduation-cap mx-2" aria-hidden="true"></i> {{ 'REPORTS.REPORTS_SIDEBAR_COURSE' | translate }}
				</h6>
			</li>
			<li class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
				[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == '3DModel', 'custom-list-item-dark': selectedMenuItem != '3DModel'}"
				(click)="sideItemClick('3DModel')">
				<h6 class="flaot-left ml-2 mb-0 sidebar-list-text">
					<i class="fas fa-cubes mx-2" aria-hidden="true"></i> {{ 'REPORTS.REPORTS_SIDEBAR_3D_MODEL' | translate }}
				</h6>
			</li>
			<li class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
				[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'video', 'custom-list-item-dark': selectedMenuItem != 'video'}"
				(click)="sideItemClick('video')">
				<h6 class="flaot-left ml-2 mb-0 sidebar-list-text">
					<i class="fas fa-tv mx-2" aria-hidden="true"></i> {{ 'REPORTS.REPORTS_SIDEBAR_VIDEO' | translate }}
				</h6>
			</li>
			<li class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
				[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'comosExport', 'custom-list-item-dark': selectedMenuItem != 'comosExport'}"
				(click)="sideItemClick('comosExport')">
				<h6 class="flaot-left ml-2 mb-0 sidebar-list-text">
				<i class="fas fa-file-export mx-2" aria-hidden="true"></i> {{ 'REPORTS.REPORTS_SIDEBAR_COMOS_Export' | translate }}
				</h6>
			</li>
			<li *ngIf="showManageAdhocReports" class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
				[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'manageAdhocReports', 'custom-list-item-dark': selectedMenuItem != 'manageAdhocReports'}"
				(click)="sideItemClick('manageAdhocReports')">
				<h6 class="flaot-left ml-2 mb-0 sidebar-list-text">
				<i class="fas fa-file-chart-line mx-2" aria-hidden="true"></i> {{ 'REPORTS.REPORTS_SIDEBAR_MANAGE_ADHOC_REPORTS' | translate }}
				</h6>
			</li>
		</ul>
	</div>
</div>