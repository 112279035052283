import { LocalStorage } from "./localstorage.service";

export class SessionUtil {
    public static PRIMARY_ID: string = "main";
    public static SECONDARY_ID: string = new Date().getTime().toString();
    public static KEY_ID = "id";

    public static setSessionId() {
        let sessionId = sessionStorage.getItem(this.KEY_ID);
        console.log('getsessionid= ' +sessionId);
        if(sessionId == null){
            sessionStorage.setItem(this.KEY_ID, this.SECONDARY_ID);
        }
    }

    public static getSessionId(): string | null {
        return sessionStorage.getItem(this.KEY_ID);
    }

    public static updateSessionId(): string{
        let sessionId =  this.SECONDARY_ID;
        sessionStorage.setItem(this.KEY_ID, sessionId)
        return sessionId;
    }

}
