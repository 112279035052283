import { HttpClient, HttpErrorResponse, HttpEventType, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CommonUtil } from 'src/app/util/common/common-util';
import { UserVariable } from 'src/app/util/common/user-variable';
import { ServiceUrls } from 'src/app/util/service-urls';
import { WsCallback } from 'src/app/util/ws-callback.interface';
import { WsResponse } from 'src/app/util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';

@Injectable({
    providedIn: 'root'
})
export class ManageAdhocReportsService {

    loadTableData = new EventEmitter<any>();

    constructor(
        private http: HttpClient,
        private commonUtil: CommonUtil
    ) { }

    public loadTableDataRequest() {
        this.loadTableData.emit()
    }
    public loadTableDataResponse() {
        return this.loadTableData;
    }

    getAllReportDesignsByModuleAndProjectId(module: string): Observable<WsResponse> {
        let token = UserVariable.getUserToken();
        const options = { params: new HttpParams().set("module", module) }

        return this.http.get(ServiceUrls.getAllReportDesignsByModuleAndProjectId(token), options).
            pipe(
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                }),
                catchError(error => {
                    var modified = JSON.parse(JSON.stringify(error.error));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    return throwError(res)
                })
            );
    }


    public addNewAdhocReport(formData: FormData, callBack: WsCallback) {

        let token = UserVariable.getUserToken();

        this.http.post(ServiceUrls.addNewReportDesign(token), formData, {
            reportProgress: true,
            observe: 'events'
        }).subscribe(
            event => {
                if (event.type == HttpEventType.UploadProgress) {
                    //progress
                } else if (event.type == HttpEventType.Response) {
                    var modified = JSON.parse(JSON.stringify(event.body));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onSuccess(res, WsType.ADD_NEW_ADHOC_REPORT);
                }
            },
            error => {
                if (error.error.payload != undefined) {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.ADD_NEW_ADHOC_REPORT);
                } else {
                    var res: WsResponse;
                    if (error.error.actionErrors != undefined) {
                        res = new WsResponse(error.statusText + ' - ' + error.error.actionErrors);
                    } else {
                        res = new WsResponse(error.message);
                    }
                    callBack.onFail(res, WsType.ADD_NEW_ADHOC_REPORT);
                }
            }
        );
    }

    checkReportNameAvailability(reportName: string): Observable<WsResponse> {
        let token = UserVariable.getUserToken();
        const options = { params: new HttpParams().set("reportName", reportName) }

        return this.http.get(ServiceUrls.checkReportNameAvailability(token), options).
            pipe(
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                }),
                catchError(error => {
                    var modified = JSON.parse(JSON.stringify(error.error));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    return throwError(res)
                })
            );
    }

    /**
     * Call service method to delete report design using DELETE request.
     */
     deleteReport(itemId: string): Observable<WsResponse> {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateLogin(token)) {
            return;
        }
        const options = { params: new HttpParams().set("token", token) }
        return this.http.delete(ServiceUrls.deleteAdhocReport(itemId), options).
            pipe(
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                }),
                catchError(error => {
                    var modified = JSON.parse(JSON.stringify(error.error));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    return throwError(res)
                })
            );
    }

}
