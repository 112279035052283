<div>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{title}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container">


            <!--Operatingdata-->
            <div *ngIf="operatingDependency != undefined">
                <div>
                    <h5 class="text-center">Data</h5>
                    <div>
                        {{operatingDependency.Data.projectName}}
                        ({{operatingDependency.Data.dependencyCount}})
                    </div>
                    <br />
                    <div *ngIf="operatingDependency.Data.Data.constructor.name === 'Array'">
                        <div *ngFor="let dependency of operatingDependency.Data.Data">
                            <li>
                                <label>{{dependency.DataNo}} - {{dependency.description}}</label>
                            </li>
                        </div>
                    </div>

                    <div *ngIf="operatingDependency.Data.Data.constructor.name === 'Object'">
                        <li>
                            <label>{{operatingDependency.Data.Data.DataNo}} -
                                {{operatingDependency.Data.Data.description}}</label>
                        </li>
                    </div>
                </div>
            </div>

            <!--Operatingdata-->
            <div *ngIf="operatingDataDependency != undefined">
                <div>
                    <h5 class="text-center">Operating Data Classifications</h5>
                    <div>
                    </div>
                    <br />
                    <div *ngFor="let operatingData of operatingDataDependency">
                        <li>
                            <label>{{operatingData.className}}</label>
                        </li>
                    </div>
                </div>
            </div>


        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-warning" (click)="bsModalRef.hide()">{{'OK' | translate}}</button>
        </div>
    </div>