import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams, IAfterGuiAttachedParams } from '@ag-grid-enterprise/all-modules';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-otndocu-sign-cell-renderer',
  templateUrl: './otndocu-sign-cell-renderer.component.html',
  styleUrls: ['./otndocu-sign-cell-renderer.component.css']
})
export class OTNDocuSignCellRendererComponent implements ICellRendererAngularComp {

  @ViewChild('docusignTemplate') docusignTemplate: TemplateRef<any>
	type: string;
  params: any;
	docuSignId: any;
    //system: any;
    otnId: any;
	status: any;
	docuSignStatus: any = "";
	modalRef: BsModalRef;
	isPresent: boolean = false;
	stn_translated_data: any = null;
	gridParams: any;
	workflow: boolean = false;
	assetsString: string = "";
	assets: any = [];

	public modalConfig: any = {
		backdrop: true,
		// ignoreBackdropClick: true,
		class: 'modal-lg',
		// keyboard: false
	}
  refresh(): boolean {
    return false;
  }
  constructor(private modalService: BsModalService) { }

  ngOnInit() {
    
	}
  
  agInit(params: any): void {
    this.gridParams = params;
		this.assets = params.data.otnAssets;
		if(params.data.otnStatus == "NotIssued"){
			this.workflow = true
		}
		else{
      if(params.data.docusignStatus != null){
        this.docuSignStatus = this.statusRender(params.data.docusignStatus.toLowerCase())		
        this.isPresent = true;
        this.docuSignId = params.data.docusignId
      }
      else{
        this.workflow = true

      }
		}
    
  }

  public statusRender(status: any) {
		switch (status) {
			case 'waiting':
				return '<span class="badge badge-warning px-2 py-1">Waiting</span>'
			case 'completed':
				return '<span class="badge badge-success px-2 py-1">Completed</span>'
			case 'sent':
				return '<span class="badge badge-warning px-2 py-1">Sent</span>'
			case 'declined':
				return '<span class="badge badge-danger px-2 py-1">Declined</span>'
			case 'delegated':
				return '<span class="badge badge-danger px-2 py-1">Delegated</span>'
			case 'error':
				return '<span class="badge badge-danger px-2 py-1">Error</span>'
			case 'failed':
				return '<span class="badge badge-danger px-2 py-1">Failed</span>'
			default:
				return ''
		}
	}

	viewPersonStatus(){
		this.getAssetsString();
		this.type = 'OTN'
		event.stopPropagation();
		this.modalRef = this.modalService.show(this.docusignTemplate, this.modalConfig);	
	}

	public modelRefCloseEvent() {
		this.modalRef.hide()
	}

  getAssetsString(){
		this.assetsString = "";
		for(let i = 0; i < this.assets.length; i++){
			if(i == this.assets.length - 1){
				this.assetsString += this.assets[i].name;
			} else{
				this.assetsString += this.assets[i].name + ', ';
			}
		}
	}
  

}
