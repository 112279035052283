import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {BsModalRef,BsModalService} from "ngx-bootstrap/modal";
import {SharedService} from "../../util/shared.service";
import {IrnService} from "./irn.service";
import {TranslateService} from "@ngx-translate/core";
import {LoadingService} from "../../util/loading/loading.service";
import {GridOptions, ColDef, Module, AllModules} from "@ag-grid-enterprise/all-modules";
import {Subscription} from "rxjs";
import {IrnCellRenderComponent} from "./irn-cell-render-component.component";
import {AddeditirnComponent} from "./addeditirn/addeditirn.component";
import {Constants} from "../../util/constants";
import {AlertService} from "../../util/alert/alert.service";
import {WsCallback} from "../../util/ws-callback.interface";
import {WsResponse} from "../../util/ws-response.model";
import {WsType} from "../../util/ws-type";
import {IrnAssetsCellRenderer} from "./irn-assets-cell-renderer.component";
import { ThrowStmt } from '@angular/compiler';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import * as moment from 'moment';
@Component({
  selector: 'app-irn',
  templateUrl: './irn.component.html',
  styleUrls: ['./irn.component.css']
})
export class IrnComponent implements OnInit , AfterViewInit, OnDestroy ,WsCallback{
  rowData: any = [];
  private filteredData: Array<any> = [];
  public columnDefs: any;
  public gridOptions: GridOptions;
  public overlayLoadingTemplate: any;
  public overlayNoRowsTemplate: any;
  public getRowNodeId: any;
  private gridColumnApi: any;
  public expandComponents: boolean = false;

  public bsModalRef: BsModalRef;
  public gridApi: any;
  selectedAssetList=[]

  public searchData: any;
  private irnNumber: string = "";
  private irnDescription: string = "";
  private irnAssets: string = "";
  private irnVendors: string = "";
  private irnSystems: string = "";
  private irnStatus: string = "";
  private targetCompletionDate: string = "";
  private hasText: string = "";
  public modules: Module[] = AllModules;

  private subsVar: Subscription;
  constructor(
      private modalService: BsModalService,
      private sharedService: SharedService,
      private irnService:IrnService,
      private translate: TranslateService,
      private loadingService: LoadingService,
      private alertService: AlertService
  ) {
    translate.addLangs(["es", "en"])
    translate.setDefaultLang("en");
    let browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
    this.columnDefs = [
      {
        headerName: this.translate.instant('IRN_SEARCH_VIEW.IRN_DEPENDENCY'),
        field: "dependencies",
        minWidth: 50,
        cellRendererFramework: IrnCellRenderComponent,
      },
      {
        headerName: this.translate.instant('IRN_SEARCH_VIEW.IRN_NUMBER'),
        field: 'irnNumber',
        autoHeight: true,
        cellClass: "cell-wrap-text",
        minWidth: 150,
        sortable: true
      },
      {
        headerName: this.translate.instant('IRN_SEARCH_VIEW.IRN_DESCRIPTION'),
        field: 'description',
        autoHeight: true,
        cellClass: "cell-wrap-text",
        minWidth: 300,
      },
      {
        headerName: this.translate.instant('IRN_SEARCH_VIEW.STATUS'),
        field: 'irnStatus',
        autoHeight: true,
        headerClass : "cell-centered-text",
        cellClass: "cell-centered-text",
        cellStyle: {'white-space': 'normal' },
        minWidth: 150
      },
     /*  {
        headerName: this.translate.instant('IRN_SEARCH_VIEW.TARGET_COMPLETION_DATE'),
        field: 'targetCompletionDate',
        autoHeight: true,
        headerClass : "cell-centered-text",
        cellClass: "cell-centered-text",
        cellStyle: {'white-space': 'normal' },
        minWidth: 150
      }, */
      {
        headerName: this.translate.instant('IRN_SEARCH_VIEW.IRN_ASSETS'),
        field: 'assets',
        autoHeight: true,
        headerClass : "cell-centered-text",
        cellClass: "cell-centered-text",
        cellStyle: {'white-space': 'normal' },
        minWidth: 150,
        cellRendererFramework: IrnAssetsCellRenderer
      },
      {
        headerName: this.translate.instant('IRN_SEARCH_VIEW.IRN_VENDOR'),
        field: 'vendorName',
        autoHeight: true,
        cellClass: "cell-wrap-text",
        minWidth: 150,
      },
      {
        headerName: this.translate.instant('IRN_SEARCH_VIEW.IRN_PUNCHLIST_COUNT'),
        field: 'punchlistCount',
        autoHeight: true,
        cellClass: "cell-wrap-text",
        minWidth: 75,
      },
      {
        headerName: this.translate.instant('IRN_SEARCH_VIEW.IRN_DOC'),
        field: "doc",
        minWidth: 75,
        //hide:!this.disableCap,
        cellStyle: { 'text-align': 'center' },
        cellRendererFramework: IrnCellRenderComponent,
      },
      {
        headerName: this.translate.instant('IRN_SEARCH_VIEW.EDIT'),
        field: "edit",
        minWidth: 75,
        //hide:!this.enableEditPunchlist,
        cellStyle: { 'text-align': 'center' },
        cellRendererFramework: IrnCellRenderComponent,
      },
      {
        headerName: this.translate.instant('IRN_SEARCH_VIEW.DELETE'),
        field: "delete",
        minWidth: 85,
        //hide:!this.enableDeletePunchlist,
        cellStyle: { 'text-align': 'center' },
        cellRendererFramework: IrnCellRenderComponent,
      }
    ]
    this.getRowNodeId = function (data) {
      return data.id;
    };
    this.gridOptions = {
      rowData: this.rowData,
      columnDefs: this.columnDefs,
      defaultColDef: {
        resizable: true,
        sortable: true
      },
      rowSelection: "single",
      rowMultiSelectWithClick: false,
      doesExternalFilterPass: this.externalFilterPass,
      onRowClicked: this.rowClicked,
      animateRows: true,
      rowBuffer: 20,
      context: {
        componentParent: this
      },
      paginationPageSize: 10
    };

    //custom layout templates for loading and empty data sets
    this.overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Loading data...</span>';
    this.overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">No rows to show</span>';
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.subsVar = this.sharedService.editIrnResponse().subscribe((option: any) => this.openAddEditIrnModal());
    this.irnService.setCallback(this);
  }

  ngOnDestroy(): void {
    if (this.subsVar) {
      this.subsVar.unsubscribe()
    }
    window.removeEventListener("resize", this.onWindowResize);
    this.gridApi.destroy();
    this.gridApi = null;
  }
  externalFilterPass(node: any) {
    return true;
  }
  rowClicked() {
  }
  onGridReady(params) {
    const allColumnIds = [];
    this.columnDefs.forEach((columnDef) => {
      allColumnIds.push(<ColDef>columnDef);
    });
    this.gridColumnApi = params.columnApi;
    this.gridApi = params.api;
    this.irnService.setApiGridData(this.gridApi);
    params.api.sizeColumnsToFit();
    // window.addEventListener("resize", function () {
    //   setTimeout(function () {
    //     params.api.sizeColumnsToFit();
    //   });
    // });
    window.addEventListener("resize", this.onWindowResize);
  }
  private onWindowResize = () => {
   
		setTimeout(() => {
			if (this.gridApi) {
				this.gridApi.sizeColumnsToFit();
			}
		}); 
	};
  newIrnButtonClick(){
    this.sharedService.setFileId("-1");
    this.openAddEditIrnModal();
  }
  private openAddEditIrnModal() {
    console.log('[openAddEditFacilityModal]');
    this.bsModalRef = this.modalService.show(AddeditirnComponent, { class: 'modal-xl', backdrop: 'static', keyboard: false })
  }

  searchFunc(data: any){
    console.log(data)
    this.searchData = data;
    this.irnNumber = "";
    this.irnDescription  = "";
    this.irnAssets = "";
    this.irnVendors = "";
    this.irnSystems = "";
    this.irnStatus = "";
    this.targetCompletionDate = "";
    this.hasText = "";

    // this.gridApi.showLoadingOverlay();
    //assign irnNumbers for the fields from searchWordMap
    var irnNumber= data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_IRN_IRN_NUMBER];
    if (irnNumber != undefined) {
      this.irnNumber = irnNumber
    } else {
      //no irnNumbers
    }
    //assign irnDescriptions for the fields from searchWordMap
    var irnDescription= data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_IRN_IRN_DESCRIPTION];
    if (irnDescription != undefined) {
      this.irnDescription = irnDescription
    } else {
      //no irnDescriptions
    }
    //assign irnAssets for the fields from searchWordMap
    var irnAssets= data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_IRN_ASSET];
    if (irnAssets != undefined) {
      this.irnAssets = irnAssets
    } else {
      //no irnAssets
    }
    //assign irnVendors for the fields from searchWordMap
    var irnVendors= data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_IRN_VENDOR];
    if (irnVendors != undefined) {
      this.irnVendors = irnVendors
    } else {
      //no irnVendors
    }
    //assign irnStatus for the fields from searchWordMap
    var irnStatusSearch = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_IRN_STATUS];
    if (irnStatusSearch != undefined) {
      this.irnStatus = irnStatusSearch
    } else {
     //no IRN Status
    }
    //assign completionDate for the fields from searchWordMap
    /* var completionDateSearch = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_IRN_COMPLETION_DATE];
    if (completionDateSearch != undefined) {
      this.targetCompletionDate = completionDateSearch;
    } else {
      //no completionDate
    } */
    //assign hasText for the fields from searchWordMap
    var hasText = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_HAS_THE_WORDS];
    if (hasText != undefined) {
      this.hasText = hasText;
    } else {
      //no has text
    }
    if (this.irnNumber != "" || this.irnDescription != "" || this.irnAssets != ""|| this.irnVendors != "" || this.hasText != "" || this.irnSystems !=""
        || this.irnStatus != "" || this.targetCompletionDate != "") {
      let data ={}
      if(this.irnNumber != ""){
        data = {irnNumber:this.irnNumber,...data}
      }
      if(this.irnDescription != ""){
        data = {irnDescription:this.irnDescription,...data}
      }
      data = {
        irnAssets : this.irnAssets,
        irnVendors : this.irnVendors,
        irnSystems : this.irnSystems,
        irnStatus: this.irnStatus,
        targetCompletionDate: this.targetCompletionDate,
        hasText: this.hasText,
        ...data
      }
      var json = JSON.stringify(data);
      let to: any = false;
      if (to) { clearTimeout(to); }
      this.loadingService.showLoading(true, null, "Searching", null);
      to = setTimeout(() => {
        this.irnService.searchIrns(json).subscribe(
            data => {
              this.setDocumentSearchTableData(data.payload)
              console.log(data.payload);
            },
            error => {
              this.alertService.clear()
              this.alertService.error(error.statusDescription)
            },
            () =>{
              this.loadingService.hideLoading()
            });
      }, 200);
    } else {
      //search bar has empty values
      this.gridApi.hideOverlay()
    }
  }
  searchBarEnterFunc(event){
    console.log(event)
  }
  setDocumentSearchTableData(payload: any) {
    this.loadingService.hideLoading();
    this.filteredData = [];
    this.rowData = [];
    if (payload != null) {
      if (this.expandComponents == false) {
        for (let i = 0; i < payload.length; i++) {
          let targetCompletionDate = payload[i]["targetCompletionDate"] != null ? moment.utc(payload[i]["targetCompletionDate"]).format("M/D/YYYY") : null;
          let refCount = payload[i]["referenceCount"];
          let assests = [];
          let assetsIdList = []
          if(payload[i]["assets"].length>0){
            let length = payload[i]["assets"].length;
            payload[i]["assets"].forEach((item,id)=>{
              if(length -1 == id){
                if(item.assetName){
                  assests.push(item.assetName)
                }
              }else{
                assests.push(item.assetName+",")
              }
              if(item.assetId){
                assetsIdList.push(item.assetId)
              }
            })
          }
          let assetsList = "" + assetsIdList.toString()
          this.filteredData.push({
            id:payload[i]["irnId"],
            dependencies: refCount > 0 ? "dependencies:" + payload[i]["irnId"] : "disable_dependencies:" + payload[i]["irnId"],
            irnNumber: payload[i]["irnNumber"],
            description: payload[i]["description"],
            vendorName: payload[i]["vendorName"],
            systemName: payload[i]["systemName"],
            punchlistCount: payload[i]["punchlistCount"],
            assets: assests,
            doc: "doc:" + payload[i]["irnId"] + ":" + payload[i]["isUploaded"],
            edit: "edit:" + payload[i]["irnId"] ,
            delete: "delete:" + payload[i]["irnId"],
            assetIds : assetsList,
            irnStatus : payload[i]["irnstatus"],
            targetCompletionDate : targetCompletionDate
          });
        }
      }
      this.rowData = this.filteredData;
      console.log(this.rowData);
      this.gridOptions.columnApi.getColumn('irnNumber').setSort("asc")
      this.gridApi.paginationProxy.currentPage = 0;
      this.gridOptions.api.sizeColumnsToFit();
    } else {
      // no row data to show
      this.rowData = [];
    }
    this.irnService.setRowData(this.rowData);
  }

  onFail(data: WsResponse, serviceType: WsType) {
    if(serviceType == WsType.DELETE_IRN_DOCUMENT){
      this.alertService.error(data.statusDescription);
    }
  }

  onSuccess(data: WsResponse, serviceType: WsType) {
    if(serviceType == WsType.DELETE_IRN_DOCUMENT){
      // this.alertService.success(data.statusDescription);
    }
  }
}
