<div *ngIf="!disable">
  <button id="landing-table-buttons" class="btn btn-sm btn-outline-dark py-0 my-1 px-1 border-0" (click)="invoke(type, data, roleName, roleDescription, status, edittemplate)">
      <i class={{icon}} aria-hidden="true"></i>
  </button>
</div>
<div *ngIf="disable">
  <a class="btn btn-sm btn-outline-dark py-0 my-1 px-1 border-0 disabled" (click)="invoke(type, data, roleId )" >
      <i class="text-black-50 {{icon}}"  aria-hidden="true"></i>
  </a>
</div>

<ng-template #edittemplate>
    <div class="modal-header">
      <h5 class="modal-title">
        {{ 'ROLES.EDIT_ROLE' | translate }}
      </h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <form ngNativeValidate>
      <div class="modal-body">
        
          <div class="form-group">
            <label for="roleName">
              {{ 'ROLES.ROLE_NAME' | translate }}
            </label>
            <div class="badge-required mx-2"></div>
            <input type="text" class="form-control" id="roleName" name="roleName" placeholder=""
              [(ngModel)]="roleName" required/>
          </div>
          <div class="form-group">
            <label for="roleDescription">
              {{ 'ROLES.ROLE_DESCRIPTION' | translate }}
            </label>
            <input type="text" class="form-control" id="roleDescription" name="roleDescription"
              placeholder="" [(ngModel)]="roleDescription"/>
          </div>
          <div class="col-sm-6">
            <label for="status"> {{ 'ROLES.ROLE_STATUS' | translate }}</label>
            <div class="badge-required mx-2"></div>
            <div class="clearfix">
              <div class="radio-group">
                <input type="radio" name="addrb" id="rb3" value="Active"/>
                <label for="rb3">Active</label>
                <input type="radio" name="addrb" id="rb4" value="Inactive"/>
                <label for="rb4">Inactive</label>
              </div>
            </div>
          </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary btn-sm" (click)="editSaveButtonClick()">
          {{ 'ROLES.BTN_TXT_SAVE' | translate }}
        </button>
        <button type="button" formnovalidate class="btn btn-secondary btn-sm" data-dismiss="modal"
          (click)="bsModalRef.hide()">
          {{ 'ROLES.BTN_TXT_CANCEL' | translate }}
        </button>
      </div>
    </form>	
  </ng-template>


  