<div class="modal-header py-2">
	<h5 class="modal-title">
		<span class="badge badge-warning mr-2">{{projectName}}</span>
		Tag
	</h5>
	<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="tagEditCancelButtonClick()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="container-fluid my-2">
	<!--<div class="form-group">
		<alert></alert>
	</div>-->
	<div class="card bg-light p-1 col-sm-8 mx-auto" role="alert">
		<div class="card-body py-1 px-2 mx-auto">
			{{(tagModel.referenceCount != undefined ? tagModel.referenceCount: 0) + ' Place(s), in '+
			(tagModel.dependencyCount != undefined ? tagModel.dependencyCount : 0) + ' Topic(s), in ' +
			(tagModel.procDependencyCount != undefined ? tagModel.procDependencyCount : 0) + ' Procedure(s), in ' +
			(tagModel.iceCourseDependencyCount != undefined ? tagModel.iceCourseDependencyCount : 0) + ' ICE Course(s)'}}
			<label #occurrenceLabel class="mb-0">
				<span class="badge border-badge-danger mx-2 py-2">
					<i class="fa fa-upload mx-2 fa-lg" aria-hidden="true" tooltip="Checked-Out Items"></i>
					<label class="mx-2 mb-0">
						{{(tagModel.checkedOutCount != undefined? tagModel.checkedOutCount : 0)}}
					</label>
				</span>
				<span class="badge border-badge-success mx-2 py-2">
					<i class="fa fa-download mx-2 fa-lg" aria-hidden="true" tooltip="Checked-In Items"></i>
					<label class="mx-2 mb-0">
						{{(tagModel.checkedInCount != undefined ? tagModel.checkedInCount : 0)}}
					</label>
				</span>
			</label>
		</div>
	</div>
</div>
<!-- <alert class="floating-alert"></alert> -->
<div class="container-fluid mt-3 mb-4 modal-container">
	<form>
		<!--Tag no & Facility & Description-->
		<div class="row my-2">
			<!--Tag no & Facility-->
			<div class="col-sm-6">
				<div class="form-group mb-2">
					<div class="mb-1">
						<label for="tag_no" class="mb-0 mt-1 col-sm-6 p-0">{{ 'TAG_EDIT.TAG_NO' | translate }}
							<div class="badge-required mx-2"></div>
						</label>
						<button type="button" class="btn btn-warning btn-sm float-right my-1 btn-sm-form-dependancy"
							(click)="dependencyButtonClickEvent('TagNumber')" [disabled]="fieldDependencyCountTagNumber == '0'"><i
								class="fa fa-link" aria-hidden="true"></i></button>
						<input type="text" class="form-control form-control-sm" id="edittags_tag_number"
							name="edittags_tag_number" [(ngModel)]="tagModel.tagNo" (ngModelChange)="altTagchange()">
						<div *ngIf="tagModel.hasPendingChange" class="card bg-danger my-2">
							<div class="card-body py-1 px-2">
								<p class="card-text my-1">{{reusablesNo}}</p>
								<p class="card-text my-1">{{reusablesNoModifiedBy}}</p>
								<p class="card-text my-1">{{reusablesNoModifiedTime}}</p>
							</div>
						</div>
					</div>
					<!--Facility-->
					<div class="mt-3 dropdown-100">
						<label for="facility" class="mb-0 mt-1 col-sm-6 p-0">{{ 'TAG_EDIT.FACILITY' | translate }}</label>
						<button type="button" class="btn btn-warning btn-sm float-right my-1 btn-sm-form-dependancy"
							(click)="dependencyButtonClickEvent('TagFacility')" [disabled]="fieldDependencyCountTagFacility == '0'">
							<i class="fa fa-link" aria-hidden="true"></i>
						</button>
						<!--<input type="text" class="form-control form-control-sm" id="edittags_facility">-->
						<tag-input name="selected_facility" [(ngModel)]='selectedFacilityList'
							[displayBy]="'facilityName'" [removable]='true'
							secondaryPlaceholder="{{'TAG_EDIT.FACILITY_SELECT_PLACEHOLDER' | translate}}" maxItems="1"
							[onlyFromAutocomplete]="true" (onAdd)="selectItemFromSuggestions($event, 'facility')"
							(onRemove)="removeSelectedItem($event, 'facility')" [clearOnBlur]="true">
							<tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="facilityList"
								[displayBy]="'facilityName'" [limitItemsTo]=10 [appendToBody]="false"></tag-input-dropdown>
						</tag-input>
					</div>
				</div>
			</div>
			<!--Description-->
			<div class="col-sm-6">
				<div class="form-group mb-1">
					<label for="description" class="mb-0 mt-1 col-sm-6 p-0">{{ 'TAG_EDIT.DESCRIPTION' | translate }}
						<div class="badge-required mx-2"></div>
					</label>
					<button type="button" class="btn btn-sm float-right my-1 btn-sm-form-dependancy mx-1"
						[ngClass]="{'btn-outline-dark': !showSC, 'btn-dark': showSC}"
						(click)="showSpecialCharacters()">Ω</button>
					<button type="button" class="btn btn-sm float-right my-1 btn-sm-form-dependancy mx-1"
						[ngClass]="{'btn-outline-dark': !showFC, 'btn-dark': showFC}"
						(click)="showFractions()">X/Y</button>
					<button type="button" class="btn btn-warning btn-sm float-right my-1 btn-sm-form-dependancy"
						(click)="dependencyButtonClickEvent('TagDescription')" [disabled]="fieldDependencyCountTagDescription == '0'">
						<i class="fa fa-link" aria-hidden="true"></i>
					</button>
				</div>
				<div *ngIf="showSC">
					<button type="button" class="btn btn-light btn-sm my-1 btn-sm-form-dependancy mx-1"
						id="special_char_1" name="special_char_1" (click)="setSpecialCharacter($event)">@</button>
					<button type="button" class="btn btn-light btn-sm my-1 btn-sm-form-dependancy mx-1"
						id="special_char_2" name="special_char_2" (click)="setSpecialCharacter($event)">©</button>
					<button type="button" class="btn btn-light btn-sm my-1 btn-sm-form-dependancy mx-1"
						id="special_char_3" name="special_char_3" (click)="setSpecialCharacter($event)">Ã</button>
					<button type="button" class="btn btn-light btn-sm my-1 btn-sm-form-dependancy mx-1"
						id="special_char_4" name="special_char_4" (click)="setSpecialCharacter($event)">Ø</button>
					<button type="button" class="btn btn-light btn-sm my-1 btn-sm-form-dependancy mx-1"
						id="special_char_5" name="special_char_5" (click)="setSpecialCharacter($event)">è</button>
					<button type="button" class="btn btn-light btn-sm my-1 btn-sm-form-dependancy mx-1"
						id="special_char_6" name="special_char_6" (click)="setSpecialCharacter($event)">µ</button>
					<button type="button" class="btn btn-light btn-sm my-1 btn-sm-form-dependancy mx-1"
						id="special_char_7" name="special_char_7" (click)="setSpecialCharacter($event)">¶</button>
					<button type="button" class="btn btn-light btn-sm my-1 btn-sm-form-dependancy mx-1"
						id="special_char_8" name="special_char_8" (click)="setSpecialCharacter($event)">æ</button>
					<button type="button" class="btn btn-light btn-sm my-1 btn-sm-form-dependancy mx-1"
						id="special_char_9" name="special_char_9" (click)="setSpecialCharacter($event)">£</button>
					<button type="button" class="btn btn-light btn-sm my-1 btn-sm-form-dependancy mx-1"
						id="special_char_0" name="special_char_0" (click)="setSpecialCharacter($event)">#</button>

					<button type="button" class="close float-right mt-1" aria-label="Close" (click)="hidePanles()">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div *ngIf="showFC">
					<form class="form-inline float-left col-sm-10 py-1">
						<div class="form-group">
							<label for="edit_tag_numerator" class="sr-only">Numerator</label>
							<input type="number" class="form-control form-control-sm" id="edit_tag_numerator"
							name="edit_tag_numerator" [(ngModel)]="numerator" placeholder="Numerator" >
						</div>
						<div class="form-group mx-sm-3">
							<label for="edit_tag_denominator" class="sr-only">Denominator</label>
							<input type="number" class="form-control form-control-sm" id="edit_tag_denominator"
							name="edit_tag_denominator" [(ngModel)]="denominator" placeholder="Denominator">
						</div>
						<button type="submit" class="btn btn-primary btn-sm" (click)="setNumeratorDenominator()">Ok</button>
					</form>
					<button type="button" class="close float-right mt-1" aria-label="Close" (click)="hidePanles()">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="form-group mb-2">
					<textarea #tagDesc type="text" rows="{{rowCount}}" class="form-control form-control-sm"
						id="edittags_description" name="edittags_description" [(ngModel)]="tagDescription"
						(click)="getCaretPos(tagDesc)" (keyup)="getCaretPos(tagDesc)" (keydown)="getCaretPos(tagDesc)"></textarea>
					<div *ngIf="tagModel.hasPendingChangeDescription" class="card bg-danger my-2">
						<div class="card-body py-1 px-2">
							<p class="card-text my-1">{{reusablesDescription}}</p>
							<p class="card-text my-1">{{reusablesDescModifiedBy}}</p>
							<p class="card-text my-1">{{reusablesDescModifiedTime}}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!--Document & Systems-->
		<div class="row my-2">
			<div class="col-sm-6 dropdown-100">
				<label for="document" class="mb-0 mt-1">{{ 'TAG_EDIT.DOCUMENT' | translate }}</label>
				<button type="button" class="btn btn-warning btn-sm float-right my-1 btn-sm-form-dependancy ml-1"
					(click)="dependencyButtonClickEvent('TagDocument')" [disabled]="fieldDependencyCountTagDocument == '0'">
					<i class="fa fa-link" aria-hidden="true"></i>
				</button>
				<!--<input type="text" class="form-control form-control-sm" id="edittags_document">-->
				<tag-input name="selected_document" [(ngModel)]='selectedDocumentList' [displayBy]="'documentName'"
					[removable]='true' secondaryPlaceholder="{{'TAG_EDIT.DOCUMENT_SELECT_PLACEHOLDER' | translate}}"
					maxItems="1" [onlyFromAutocomplete]="true" (onAdd)="selectItemFromSuggestions($event, 'document')"
					(onRemove)="removeSelectedItem($event, 'document')" [clearOnBlur]="true">
					<tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="documents"
						[displayBy]="'documentName'" [limitItemsTo]=10 [appendToBody]="false"></tag-input-dropdown>
				</tag-input>
				<div *ngIf="tagModel.hasPendingChangeDocument" class="card bg-danger my-2">
					<div class="card-body py-1 px-2">
						<p class="card-text my-1">{{reusablesDocument}}</p>
						<p class="card-text my-1">{{reusablesDocModifiedBy}}</p>
						<p class="card-text my-1">{{reusablesDocModifiedTime}}</p>
					</div>
				</div>
			</div>
			<div class="col-sm-6 dropdown-100">
				<label for="system_name" class="mb-0 mt-1 col-sm-6 p-0">{{ 'TAG_EDIT.SYSTEM' | translate }}
					<div class="badge-required mx-2"></div>
				</label>
				<button type="button" class="btn btn-warning btn-sm float-right my-1 btn-sm-form-dependancy ml-1"
					(click)="dependencyButtonClickEvent('TagSystem')" [disabled]="fieldDependencyCountTagSystem == '0'">
					<i class="fa fa-link" aria-hidden="true"></i>
				</button>
				<!--<input type="text" class="form-control form-control-sm" id="edittags_system" value="{{(tagModel.systemName != undefined ? tagModel.systemName : '')}}">-->
				<tag-input name="selected_system" [(ngModel)]='selectedSystemList' [displayBy]="'systemNoandsystemName'"
					[removable]='true' secondaryPlaceholder="{{'TAG_EDIT.SYSTEM_SELECT_PLACEHOLDER' | translate}}"
					maxItems="1" [onlyFromAutocomplete]="true" (onAdd)="selectItemFromSuggestions($event, 'system')"
					(onRemove)="removeSelectedItem($event, 'system')" [clearOnBlur]="true">
					<tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="systemList"
						[displayBy]="'systemNoandsystemName'" [limitItemsTo]=10 [appendToBody]="false"></tag-input-dropdown>
				</tag-input>
				<div *ngIf="tagModel.hasPendingChangeSystem" class="card bg-danger my-2">
					<div class="card-body py-1 px-2">
						<p class="card-text my-1">{{reusablesSystem}}</p>
						<p class="card-text my-1">{{reusablesSysModifiedBy}}</p>
						<p class="card-text my-1">{{reusablesSysModifiedTime}}</p>
					</div>
				</div>
			</div>
		</div>
		<!--Data sheet & Classification-->
		<div class="row my-2">
			<!-- Data sheets -->
			<div class="col-sm-6 dropdown-100">
				<label for="datasheet" class="mb-0 mt-1">{{ 'TAG_EDIT.DATA_SHEET' | translate }}</label>
				<!--<input type="text" class="form-control form-control-sm" id="edittags_datasheet">-->
				<tag-input id="edittags_datasheet" name="edittags_datasheet" [(ngModel)]='selectedDataSheetList'
					[displayBy]="'documentName'" [removable]='true'
					secondaryPlaceholder="{{'TAG_EDIT.DATA_SHEET_SELECT_PLACEHOLDER' | translate}}" maxItems="1"
					[onlyFromAutocomplete]="true" (onAdd)="selectItemFromSuggestions($event, 'data_sheet')"
					(onRemove)="removeSelectedItem($event, 'data_sheet')" [clearOnBlur]="true">
					<tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="dataSheetList"
						[displayBy]="'documentName'" [limitItemsTo]=10 [appendToBody]="false"></tag-input-dropdown>
				</tag-input>
			</div>
			<!-- Classification -->
			<div class="col-sm-6 dropdown-100">
				<label for="classification" class="mb-0 mt-1">{{ 'TAG_EDIT.CLASSIFICATION' | translate }}</label>
				<!--<input type="text" class="form-control form-control-sm" id="edittags_clasification">-->
				<tag-input name="selected_class" [(ngModel)]='selectedTagClassList' [displayBy]="'className'"
					[removable]='!classIsAlreadyAdded'
					secondaryPlaceholder="{{'TAG_EDIT.CLASSIFICATION_SELECT_PLACEHOLDER' | translate}}" maxItems="1"
					[onlyFromAutocomplete]="true" (onAdd)="selectItemFromSuggestions($event, 'classification')"
					(onRemove)="removeSelectedItem($event, 'classification')" [clearOnBlur]="true">
					<tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="tagClassList"
						[displayBy]="'className'" [limitItemsTo]=10 [appendToBody]="false"></tag-input-dropdown>
				</tag-input>
			</div>
		</div>
		<!--Equipment Description & Location Description-->
		<div class="row my-2">
			<div class="col-sm-6">
				<label for="equipment_description" class="mb-0 mt-1">{{ 'TAG_EDIT.EQUIPMENT_DESCRIPTION' | translate }}</label>
				<textarea type="text" class="form-control form-control-sm" id="edittags_equipment_description"
					name="edittags_equipment_description" [(ngModel)]="tagModel.equipmentDescription"></textarea>
			</div>
			<div class="col-sm-6">
				<label for="location_description" class="mb-0 mt-1">{{ 'TAG_EDIT.LOCATION_DESCRIPTION' | translate }}</label>
				<textarea type="text" class="form-control form-control-sm" id="edittags_location_description"
					name="edittags_location_description" [(ngModel)]="tagModel.locationDescription"></textarea>
			</div>
		</div>

		<!--NEW CHANGES(START)-->
		<!--Equipment Description-->
		<div class="row my-2">
			<div class="col-sm-6" *ngIf="autoGenerateEquipNo" name="equipment_number">
				<label for="equipment_no" class="mb-0 mt-1">{{ 'TAG_ADD.EQUIPMENT_NUMBER' | translate }}</label>
				<div class="input-group mb-3">
					<div class="input-group-prepend">
						<div class="input-group-text">
							<input type="checkbox" name="edit-equipment-number"
								aria-label="Checkbox for following text input" 
								[disabled]="hasEquipmentNumber" [(checked)]="isEquipmentNoCheckboxSelected" (click)="onCheckBoxCheck($event)" [(ngModel)]="isEquipmentNoCheckboxSelected">
						</div>
					</div>
					<input name="equipment_number_input" type="text" class="form-control"
						aria-label="Text input with checkbox" [(ngModel)]="nextAvailableEquipmentNo"
						 [disabled]="isEquipmentNoCheckboxSelected">
				</div>
			</div>
			<div class="col-sm-6 dropdown-100">
				<label for="location_parent" class="mb-0 mt-1">{{ 'TAG_ADD.LOCATION_PARENT' | translate }}</label>
				<!--<input type="text" class="form-control form-control-sm" id="addtags_location_parent">-->
				<tag-input name="addtags_location_parent" id="addtags_location_parent"
					[(ngModel)]='selectedLocationParent' [displayBy]="'tagNo'" [removable]='true'
					secondaryPlaceholder="{{'TAG_ADD.LOCATION_PARENT_PLACEHOLDER' | translate}}" maxItems="1"
					[onlyFromAutocomplete]="true" (onAdd)="selectItemFromSuggestions($event, 'location_parent')"
					(onRemove)="removeSelectedItem($event, 'location_parent')"
					(keyup)="filterItems($event.target.value)" [clearOnBlur]="true">
					<tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="filteredLocationParent"
						[displayBy]="'tagNo'" [limitItemsTo]=10 [appendToBody]="false"></tag-input-dropdown>
				</tag-input>
				<button  *ngIf="this.enableMore" type="button" class="btn btn-primary mx-1 float-right" (click)="setMoreLocationParent(locationParentTemplate)">{{
					'TAG_ADD.ALL' | translate }}</button>
			</div>
			<!--CAD No-->
			<div class="col-sm-6">
				<label for="CADNo" class="mb-0 mt-1 col-sm-6 p-0">{{ 'TAG_EDIT.CAD_No' | translate }}
					<div class="mx-2"></div>
				</label>
				<input type="text" class="form-control form-control-sm" id="edittags_CADNo"
					name="edittags_CADNo" [(ngModel)]="tagModel.cadNo">
			</div>
		</div>
		<!--Functional Location-->
		<hr *ngIf="isUsedCmmsTracking"/>
		<div class="row my-2" *ngIf="isUsedCmmsTracking" name="functional_location">
			<!-- Location Parent -->
			<div class="col-sm-6">
				<label for="build_function_location" class="mb-0 mt-1">{{ 'TAG_ADD.BUILD_FUNCTIONAL_LOCATION' | translate }}</label>
				<div class="form-row">
					<div class="col-xl-4 col-lg-6" *ngFor="let item of tagAddPreConditions.cmmsFunctionalLocationInfo; let i = index"
						name="functional_location_tag">
						<tag-input [ngModelOptions]="{standalone: true}" [ngModel]="addedSuggestionList[i]"
							secondaryPlaceholder="{{'TAG_ADD.TAG_SELECT_PLACEHOLDER' | translate}}"
							[onlyFromAutocomplete]="true" [maxItems]='1' [(disable)]="enableDisableItems[i]"
							[displayBy]="'functionalLocationTagNo'" (keyup)="onTextChange($event.target.value, i)"
							(onAdd)="onSuggestionSelected($event, i)" [onRemoving]="onRemoving" 
							(onRemove)="onSuggestionRemoved($event, i)"	[clearOnBlur]="true">
							<tag-input-dropdown [showDropdownIfEmpty]="false" [autocompleteItems]="suggestionList"
								[displayBy]="'altNoAndTagNo'" [limitItemsTo]=10 [appendToBody]="false">
							</tag-input-dropdown>
						</tag-input>
						<button *ngIf="(this.enableMoreFunctional && !enable[i])" type="button" class="btn btn-primary button-size float-right" (click)="setMoreFunctionalLocation(functionalLocationTemplate)">{{
							'TAG_ADD.ALL' | translate }}</button>
					</div>
				</div>
			</div>
			<div class="col-sm-6">
				<label for="functional_locaiton" class="mb-0 mt-1">{{ 'TAG_ADD.FUNCTIONAL_LOCATION' | translate }}</label>
				<textarea type="text" class="form-control form-control-sm" id="addtags_functional_location"
					name="addtags_functional_location" [(ngModel)]="calculatedFunctionalLocationValue"
					readonly="true"></textarea>
			</div>
		</div>
		<div class="row my-2" *ngIf="isUsedCmmsTracking" name="alternate_tag_no">
			<div class="col-sm-6">
				<label for="alternate_tag_no" class="mb-0 mt-1">{{ 'TAG_ADD.ALTERNATE_TAG_NO' | translate }}</label>
				<button type="button" class="btn btn-warning btn-sm float-right my-1 btn-sm-form-dependancy"
					(click)="dependencyButtonClickEvent('AlternateTagNo')" [disabled]="fieldDependencyCountAlternateTagNo == '0'">
					<i class="fa fa-link" aria-hidden="true"></i>
				</button>
				<input type="text" class="form-control form-control-sm" id="edittag_alternate_tag_no"
					name="edittag_alternate_tag_no" [(ngModel)]="alternateTagNo" (ngModelChange)="altTagNoChange()">
			</div>
		</div>
		<hr *ngIf="isUsedCmmsTracking"/>
		<!--NEW CHANGES(END)-->

		<!--Failure Class-->
		<div class="row my-2">
			<!-- Failure Class -->
			<div class="col-sm-6 dropdown-100">
				<label for="failure_class" class="mb-0 mt-1">{{ 'TAG_EDIT.FAILURE_CLASS' | translate }}</label>
				<!--<input type="text" class="form-control form-control-sm" id="edittags_failure_class">-->
				<tag-input id="edittags_failure_class" name="edittags_failure_class"
					[(ngModel)]='selectedFailureClasses' [displayBy]="'attribute'" [removable]='true'
					secondaryPlaceholder="{{'TAG_EDIT.FAILURE_CLASS_PLACEHOLDER' | translate}}" maxItems="1"
					[onlyFromAutocomplete]="true" (onAdd)="selectItemFromSuggestions($event, 'failure_class')"
					(onRemove)="removeSelectedItem($event, 'failure_class')" [clearOnBlur]="true">
					<tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="failureClassList"
						[displayBy]="'attribute'" [limitItemsTo]=10 [appendToBody]="false"></tag-input-dropdown>
				</tag-input>
			</div>
			<!-- Criticality -->
			<div class="col-sm-6 dropdown-100">
					<label for="criticality" class="mb-0 mt-1">{{ 'TAG_EDIT.CRITICALITY' | translate }}</label>
					<!--<input type="text" class="form-control form-control-sm" id="edittags_criticality">-->
					<tag-input id="edittags_criticality" name="edittags_criticality" [(ngModel)]='selectedCriticalityList'
						[displayBy]="'attribute'" [removable]='true'
						secondaryPlaceholder="{{'TAG_EDIT.CRITICALITY_PLACEHOLDER' | translate}}" maxItems="1"
						[onlyFromAutocomplete]="true" (onAdd)="selectItemFromSuggestions($event, 'criticality')"
						(onRemove)="removeSelectedItem($event, 'criticality')" [clearOnBlur]="true">
						<tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="criticalityList"
							[displayBy]="'attribute'" [limitItemsTo]=10 [appendToBody]="false"></tag-input-dropdown>
					</tag-input>
				</div>
		</div>
		<!--Criticality & Safety Critical-->
		<div class="row my-2">
			<!-- Safety Criticality -->
			<div class="col-sm-6 dropdown-100">
				<label for="safety_critical" class="mb-0 mt-1">{{ 'TAG_EDIT.SAFETY_CRITICAL' | translate }}</label>
				<!--<input type="text" class="form-control form-control-sm" id="edittags_safety_critical">-->
				<tag-input id="edittags_safety_critical" name="edittags_safety_critical"
					[(ngModel)]='selectedSafetyCriticalityList' [displayBy]="'attribute'" [removable]='true'
					secondaryPlaceholder="{{'TAG_EDIT.SAFETY_CRITICAL_PLACEHOLDER' | translate}}" maxItems="1"
					[onlyFromAutocomplete]="true" (onAdd)="selectItemFromSuggestions($event, 'criticality')"
					(onRemove)="removeSelectedItem($event, 'criticality')" [clearOnBlur]="true">
					<tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="safetyCriticalityList"
						[displayBy]="'attribute'" [limitItemsTo]=10 [appendToBody]="false"></tag-input-dropdown>
				</tag-input>
			</div>
			<!-- Discipline -->
			<div class="col-sm-6 dropdown-100">
					<label for="discipline" class="mb-0 mt-1">{{ 'TAG_EDIT.DISCIPLINE' | translate }}</label>
					<!--<input type="text" class="form-control form-control-sm" id="edittags_discipline">-->
					<tag-input id="edittags_discipline" name="edittags_discipline" [(ngModel)]='selectedDisciplineList'
						[displayBy]="'attribute'" [removable]='true'
						secondaryPlaceholder="{{'TAG_EDIT.DISCIPLINE_PLACEHOLDER' | translate}}" maxItems="1"
						[onlyFromAutocomplete]="true" (onAdd)="selectItemFromSuggestions($event, 'discipline')"
						(onRemove)="removeSelectedItem($event, 'discipline')" [clearOnBlur]="true">
						<tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="disciplineList"
							[displayBy]="'attribute'" [limitItemsTo]=10 [appendToBody]="false"></tag-input-dropdown>
					</tag-input>
				</div>
		</div>
		<!--Discipline & Equipment Type-->
		<div class="row my-2">
			<!-- Equipment Type -->
			<div class="col-sm-6">
				<label for="equipment_type" class="mb-0 mt-1">{{ 'TAG_EDIT.EQUIPMENT_TYPE' | translate }}</label>
				<!--<input type="text" class="form-control form-control-sm" id="edittags_equipment_type">-->
				<tag-input id="edittags_equipment_type" name="edittags_equipment_type"
					[(ngModel)]='selectedEquipmentTypeList' [displayBy]="'attribute'" [removable]='true'
					secondaryPlaceholder="{{'TAG_EDIT.EQUIPMENT_TYPE_PLACEHOLDER' | translate}}" maxItems="1"
					[onlyFromAutocomplete]="true" (onAdd)="selectItemFromSuggestions($event, 'equipment_type')"
					(onRemove)="removeSelectedItem($event, 'equipment_type')" [clearOnBlur]="true">
					<tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="equipmentTypeList"
						[displayBy]="'attribute'" [limitItemsTo]=10 [appendToBody]="false"></tag-input-dropdown>
				</tag-input>
			</div>
		</div>
		<hr />
		<!--Commissioning-->
		<div class="row my-2">
			<div class="col-sm-6">
				<div class="checkbox float-left">
					<label class="customcheck">
						<p class="h6 mb-0">{{ 'TAG_EDIT.USED_IN_COMMISSIONING' | translate }}</p>
						<input type="checkbox" type="checkbox" name="checkbox" [(checked)]="usedInCMMSTrac"
							[(ngModel)]="usedInCMMSTrac"/>
						<span class="checkmark"></span>
					</label>
				</div>
			</div>
		</div>
		<!--Asset Groups & Asset Parent-->
		<div class="row my-2">
			<!-- Assets Groups List -->
			<div class="col-sm-6 dropdown-100">
				<label for="asset_groups" class="mb-0 mt-1 col-sm-6 p-0">{{ 'TAG_EDIT.ASSET_GROUPS' | translate }}
					<div class="badge-required mx-2"></div>
				</label>
				<!--<input type="text" class="form-control form-control-sm" id="edittags_asset_groups">-->
				<tag-input id="edittags_asset_groups" name="edittags_asset_groups" [(ngModel)]='selectedAssetGroupsList'
					[displayBy]="'assetGroupName'" [removable]='true' [ngClass]="{'disableTagInput': !usedInCMMSTrac}"
					secondaryPlaceholder="{{'TAG_EDIT.ASSET_GROUP_PLACEHOLDER' | translate}}" maxItems="1"
					[onlyFromAutocomplete]="true" (onAdd)="selectItemFromSuggestions($event, 'asset_group')"
					(onRemove)="removeSelectedItem($event, 'asset_group')" [clearOnBlur]="true">
					<tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="assetGroupsList"
						[displayBy]="'assetGroupName'" [limitItemsTo]=10 [appendToBody]="false"></tag-input-dropdown>
				</tag-input>
			</div>
			<div class="col-sm-6 dropdown-100">
				<label for="asset_parent" class="mb-0 mt-1">{{ 'TAG_EDIT.ASSET_PARENT' | translate }}</label>
				<!--<input type="text" class="form-control form-control-sm" id="edittags_asset_parent">-->
				<tag-input id="edittags_asset_parent" name="edittags_asset_parent" [(ngModel)]='selectedParentList'
					[displayBy]="'tagNoandtagDescription'" [removable]='true' [ngClass]="{'disableTagInput': !usedInCMMSTrac}"
					secondaryPlaceholder="{{'TAG_EDIT.ASSET_PARENT_PLACEHOLDER' | translate}}" maxItems="1"
					[onlyFromAutocomplete]="true" (onAdd)="selectItemFromSuggestions($event, 'asset_parent')"
					(onRemove)="removeSelectedItem($event, 'asset_parent')" [clearOnBlur]="true">
					<tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="assetParentList"
						[displayBy]="'tagNoandtagDescription'" [limitItemsTo]=10 [appendToBody]="false"></tag-input-dropdown>
				</tag-input>
			</div>
		</div>
		<!--Function Code & Vendor-->
		<div class="row my-2">
			<div class="col-sm-6 dropdown-100">
				<label for="function_code" class="mb-0 mt-1">{{ 'TAG_EDIT.FUNCTION_CODE' | translate }}</label>
				<!--<input type="text" class="form-control form-control-sm" id="edittags_function_code">-->
				<tag-input id="edittags_function_code" name="edittags_function_code"
					[(ngModel)]='selectedCsFunctionCodeList'[displayBy]="'functionCode'"
					[removable]='true' secondaryPlaceholder="{{'TAG_EDIT.FUNCTION_CODE_PLACEHOLDER' | translate}}"
					maxItems="1" [onlyFromAutocomplete]="true" [ngClass]="{'disableTagInput': !usedInCMMSTrac}"
					(onAdd)="selectItemFromSuggestions($event, 'function_code')"
					(onRemove)="removeSelectedItem($event, 'function_code')" [clearOnBlur]="true">
					<tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="csFunctionCodeList"
						[displayBy]="'functionCode'" [limitItemsTo]=10 [appendToBody]="false"></tag-input-dropdown>
				</tag-input>
			</div>
			<div class="col-sm-6 dropdown-100">
				<label for="vendor" class="mb-0 mt-1">{{ 'TAG_EDIT.VENDOR' | translate }}</label>
				<!--<input type="text" class="form-control form-control-sm" id="edittags_vendor">-->
				<tag-input id="edittags_vendor" name="edittags_vendor" [(ngModel)]='selectedVendorList'
					[displayBy]="'vendorName'" [removable]='true' [ngClass]="{'disableTagInput': !usedInCMMSTrac}"
					secondaryPlaceholder="{{'TAG_EDIT.VENDOR_PLACEHOLDER' | translate}}" maxItems="1"
					[onlyFromAutocomplete]="true" (onAdd)="selectItemFromSuggestions($event, 'vendor')"
					(onRemove)="removeSelectedItem($event, 'vendor')" [clearOnBlur]="true">
					<tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="vendorList"
						[displayBy]="'vendorName'" [limitItemsTo]=10 [appendToBody]="false"></tag-input-dropdown>
				</tag-input>
			</div>
		</div>
		<div class="row my-2">
			<div class="col-sm-6">
				<label for="irn_target_completion_date" class="mb-0 mt-1">{{ 'TAG_EDIT.IRN_TARGET_COMPLETION_DATE' | translate }}</label>
				<!--<input type="text" class="form-control form-control-sm" id="edittags_function_code">-->
				<input type="text" id="irn_target_completion_date" name="irn_target_completion" [ngClass]="{'disabled': !usedInCMMSTrac}" placeholder="Select IRN target completion date" class="form-control"
                     [(ngModel)]="irnTargetCompletionDate" [bsConfig]="bsConfig" placement="bottom" bsDatepicker/>
			</div>
			<div class="col-sm-6">
				<label for="otn_target_completion_date" class="mb-0 mt-1">{{ 'TAG_EDIT.OTN_TARGET_COMPLETION_DATE' | translate }}</label>
				<!--<input type="text" class="form-control form-control-sm" id="edittags_vendor">-->
				<input type="text" id="otn_target_completion_date" name="otn_target_completion" [ngClass]="{'disabled': !usedInCMMSTrac}"  placeholder="Select OTN target completion date" class="form-control" 
                     [(ngModel)]="otnTargetCompletionDate" [bsConfig]="bsConfig" placement="bottom" bsDatepicker/>
				
			</div>
		</div>
		<hr />
		<!-- ASSET ATTRIBUTES -->
		<div class="row my-2">
			<div class="col-sm-12">
				<label class="mb-1 h6">{{ 'TAG_EDIT.ASSET_ATTRIBUTES' | translate }}</label>
			</div>
			<!-- Container -->
			<div class="col-sm-12">
				<div class="card">
                    <div class="class-body p-2">
                        <p class="text-muted"
                            *ngIf="assetAttributeList == undefined || assetAttributeList.length == 0"
                            name="no_asset_attribute_label">No
                            asset attributes</p>
                        <!--Asset Attribute-->
                        <div *ngFor="let attribute of assetAttributeList let i = index">
                            <div *ngIf="attribute.attributeType == 'Text'">
                                <div class="col-sm-6 float-left pb-1">
                                    <label class="mb-0 mt-1 col-sm-6 p-0">{{attribute.attributeName}}
                                        <div *ngIf="attribute.required.toLowerCase()=='yes'" class="badge-required mx-2"></div>
                                    </label>
                                </div>
                                <div class="col-sm-6 float-left pb-1">
                                    <input value="{{attribute.attributeValue}}"
                                        id="{{attribute.assetAttributeId}}"
                                        name="{{attribute.assetAttributeId}}"
                                        [disabled]="!usedInCMMSTrac" type="text" class="form-control form-control-sm"
                                        (change)="getValueChanges($event, 'asset_attribute_text', attribute.assetAttributeId)">
                                </div>
                            </div>
                            <!-- Non text asset attributes -->
                            <div *ngIf="attribute.attributeType != 'Text'">
                                <div class="col-sm-6 float-left pb-1">
                                    <label class="mb-0 mt-1 col-sm-6 p-0">{{attribute.attributeName}}
                                        <div *ngIf="attribute.required.toLowerCase()=='yes'" class="badge-required mx-2"></div>
                                    </label>
                                </div>
                                <div class="col-sm-6 float-left pb-1">
                                    <!--asset list-->
                                    <app-attribute-select *ngIf="attribute.attributeType == 'Asset'"
                                        [selectedItem]="assetAttributeSelectionList[i].selectedDataId"
                                        [viewItems]="assetAttributeSelectionList[i].assetListNgSelect"
                                        id="asset_{{attribute.assetId}}" [disabled]="!usedInCMMSTrac"
                                        (emitSelected)="getValueChanges($event, 'asset_attribute_asset', attribute.assetAttributeId)">
                                    </app-attribute-select>
                                    <!--value list-->
                                    <app-attribute-select *ngIf="attribute.attributeType == 'ValueList'"
                                        [selectedItem]="assetAttributeSelectionList[i].selectedDataId"
                                        [viewItems]="assetAttributeSelectionList[i].valueListNgSelect" id="valuelist_{{attribute.valueListId}}"
                                        [disabled]="!usedInCMMSTrac"
                                        (emitSelected)="getValueChanges($event, 'asset_attribute_value', attribute.assetAttributeId)">
                                    </app-attribute-select>
                                </div>
                            </div>
                        </div>
                    </div>
				</div>
			</div>
		</div>
		<hr />
		<!--Tag Attributes-->
		<div class="row my-2">
			<div class="col-sm-12">
				<label class="mb-1 h6">{{ 'TAG_EDIT.TAG_ATTRIBUTES' | translate }}</label>
			</div>
			<div class="col-sm-12">
				<div class="card">
					<div class="class-body p-2">
						<p class="text-muted" *ngIf="tagAttributes == undefined || tagAttributes.length == 0"
							name="no_tag_attribute_label">No
							tag attributes</p>
						<div *ngFor="let attribute of tagAttributes let i = index">
                            <div *ngIf="attribute.attributeType == 'Text'">
                                <div class="row my-1">
									<div class="col-sm-4 float-left pb-1">
										<label class="mb-0 mt-1 col-sm-6 p-0 pl-4">{{attribute.attributeName}}
											<div *ngIf="attribute.required=='yes'" class="badge-required mx-2"></div>
										</label>
									</div>
									<div class="col-sm-6 float-left pb-1">
										<div class="row">
											<div class="col-sm-12">
												<input value="{{attribute.value}}" id="{{attribute.attributeId}}" type="text" maxlength="300" class="form-control form-control-sm"
													(change)="getValueChanges($event, 'tag_attribute_text', attribute.attributeId, attribute.tagClsSpecId, attribute.required)">
												<!--<input #texts value="{{attribute.tagAttributeModel.attributeValue}}" id="{{attribute.attributeId}}"
												(change)="getValueChanges($event, 'text', attribute.attributeId)">-->
													</div>
										</div>
										<div class="row mx-0">
											<div class="col-sm-12 float-left pb-1 card bg-danger my-2" *ngIf="attribute.hasPendingChange">
												<div class="card-body py-1 px-2">
													<p class="card-text my-1">{{attribute.reusableOldValue}}</p>
													<p class="card-text my-1">{{attribute.reusableModifiedBy}}</p>
													<p class="card-text my-1">{{attribute.reusableModifiedTime}}</p>
												</div>
											</div>
										</div>
									</div>
									<div class="col-sm-1 float-left pb-1">
										<button type="button" class="btn btn-warning btn-sm float-right my-1 btn-sm-form-dependancy"
											(click)="dependencyButtonClickEvent('TagAttribute')" [disabled]="attribute.dependencyCount == '0'"><i
											class="fa fa-link" aria-hidden="true"></i></button>
									</div>
								</div>
							</div>
                            <div *ngIf="attribute.attributeType == 'Number'">
                                <div class="row my-1">
									<div class="col-sm-4 float-left pb-1">
										<label class="mb-0 mt-1 col-sm-6 p-0 pl-4">{{attribute.attributeName}}
											<div *ngIf="attribute.required=='yes'" class="badge-required mx-2"></div>
										</label>
									</div>
									<div class="col-sm-6 float-left pb-1">
										<div class="row">
											<div class="col-sm-12">
												<input value="{{attribute.value}}" id="{{attribute.attributeId}}" type="text" maxlength="300" (keypress)="checkIfNumber($event)" class="form-control form-control-sm"
													(change)="getValueChanges($event, 'tag_attribute_text', attribute.attributeId, attribute.tagClsSpecId, attribute.required)">
												<!--<input #texts value="{{attribute.tagAttributeModel.attributeValue}}" id="{{attribute.attributeId}}"
												(change)="getValueChanges($event, 'text', attribute.attributeId)">-->
											</div>
										</div>
										<div class="row mx-0">
											<div class="col-sm-12 float-left pb-1 card bg-danger my-2" *ngIf="attribute.hasPendingChange">
												<div class="card-body py-1 px-2">
													<p class="card-text my-1">{{attribute.reusableOldValue}}</p>
													<p class="card-text my-1">{{attribute.reusableModifiedBy}}</p>
													<p class="card-text my-1">{{attribute.reusableModifiedTime}}</p>
												</div>
											</div>
										</div>
									</div>
									<div class="col-sm-1 float-left pb-1">
										<button type="button" class="btn btn-warning btn-sm float-right my-1 btn-sm-form-dependancy"
											(click)="dependencyButtonClickEvent('TagAttribute')" [disabled]="attribute.dependencyCount == '0'"><i
											class="fa fa-link" aria-hidden="true"></i></button>
									</div>
								</div>
                            </div>
                            <div *ngIf="attribute.attributeType != 'Text' && attribute.attributeType != 'Number'">
                                <div class="row my-1">
									<div class="col-sm-4 float-left pb-1">
										<label class="mb-0 mt-1 col-sm-6 p-0 pl-4">{{attribute.attributeName}}
											<div *ngIf="attribute.required=='yes'" class="badge-required mx-2"></div>
										</label>
									</div>
									<div class="col-sm-6 float-left pb-1">
										<div class="row">
											<div class="col-sm-12">
										<!--Documents-->
												<app-attribute-select *ngIf="attribute.attributeType == 'Document'"
													[selectedItem]="selectedTagAttributeValue[i].selectedDataId"
													[viewItems]="documentListForNgSelect"
													id="sysdoc_{{attribute.attributeId}}"
													name="sysdoc_{{attribute.attributeId}}"
													(emitSelected)="getValueChanges($event, 'tag_attribute_document', attribute.attributeId, attribute.tagClsSpecId, attribute.required)">
												</app-attribute-select>
			
												<!--Systems-->
												<app-attribute-select *ngIf="attribute.attributeType == 'System'"
													[selectedItem]="selectedTagAttributeValue[i].selectedDataId"
													[viewItems]="systemListForNgSelect"
													id="sysatt_{{attribute.attributeId}}"
													name="sysatt_{{attribute.attributeId}}"
													(emitSelected)="getValueChanges($event, 'tag_attribute_system', attribute.attributeId, attribute.tagClsSpecId, attribute.required)">
												</app-attribute-select>
			
												<!--Components-->
												<app-attribute-select *ngIf="attribute.attributeType == 'Component'"
													[selectedItem]="selectedTagAttributeValue[i].selectedDataId"
													[viewItems]="getComponentListByCategory(attribute.categoryId)"
													id="syscom_{{attribute.attributeId}}"
													name="syscom_{{attribute.attributeId}}"
													(emitSelected)="getValueChanges($event, 'tag_attribute_component', attribute.attributeId, attribute.tagClsSpecId, attribute.required)">
												</app-attribute-select>
			
												<!--Date-->
												<input *ngIf="attribute.attributeType == 'Date'" id="{{attribute.attributeId}}" value="{{attribute.value}}" 
													type="date" (change)="getValueChanges($event, 'tag_attribute_date', attribute.attributeId, attribute.tagClsSpecId, attribute.required)"
													class="form-control form-control-sm"/>
											</div>
										</div>
										<div class="row mx-0">
											<div class="col-sm-12 float-left pb-1 card bg-danger my-2" *ngIf="attribute.hasPendingChange">
												<div class="card-body py-1 px-2">
													<p class="card-text my-1">{{attribute.reusableOldValue}}</p>
													<p class="card-text my-1">{{attribute.reusableModifiedBy}}</p>
													<p class="card-text my-1">{{attribute.reusableModifiedTime}}</p>
												</div>
											</div>
										</div>
									</div>
									<div class="col-sm-1 float-left pb-1">
										<button type="button" class="btn btn-warning btn-sm float-right my-1 btn-sm-form-dependancy"
											(click)="dependencyButtonClickEvent('TagAttribute')" [disabled]="attribute.dependencyCount == '0'"><i
											class="fa fa-link" aria-hidden="true"></i></button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- <div class="row my-4">
			<div class="col-sm-12 float-right">
				<button type="button" class="btn btn-primary mx-1 float-right"
					(click)="tagEditSaveButtonClick()">{{ 'TAG_EDIT.SAVE_BUTTON_TEXT' | translate }}</button>
				<button type="button" class="btn btn-secondary mx-1 float-right"
					(click)="tagEditCancelButtonClick()">{{ 'TAG_EDIT.CANCEL_BUTTON_TEXT' | translate }}</button>
			</div>
		</div> -->
	</form>
</div>
<div class="modal-footer">
	<button type="submit" id="edittag_save" class="btn btn-primary btn-sm" (click)="tagEditSaveButtonClick()">
		{{ 'TAG_ADD.SAVE_BUTTON_TEXT' | translate}}
	</button>
	<button type="button" id="edittag_cancel" class="btn btn-secondary btn-sm" (click)="tagEditCancelButtonClick()">
		{{ 'TAG_ADD.CANCEL_BUTTON_TEXT' | translate}}
	</button>
</div>

<ng-template #specialCharacter>
	<div class="container-fluid">
		<div class="col-sm-12">
			<button type="button" class="btn btn-outline-dark">Dark</button>
		</div>
		<div class="col-sm-12">
			<button type="button" class="btn btn-primary float-right">OK</button>
		</div>
	</div>
</ng-template>
<!--Location Parent Template-->
<ng-template #locationParentTemplate>
	<div class="modal-header">
		<h5 class="modal-title">{{ 'TAG_EDIT.TAG_LIST' | translate }}</h5>
		<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="locationParentCancel()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<form ngNativeValidate>
		<div class="modal-body">			
				<ag-grid-angular #agGrid style="height: 550px;" class="ag-theme-balham"
					 [enableFilter]="true" [rowData]="rowData" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }" [columnDefs]="columnDefs"
					[rowSelection]="'single'" [suppressRowDeselection]="true" [rowMultiSelectWithClick]="false" (rowClicked)="onRowClick($event)"
					[components]="components"  [pagination]="true" [paginationPageSize]="20" [getRowNodeId]="getRowNodeId" [rowSelection]="rowSelection"
					(rowSelected)="onRowSelected($event)" [defaultColDef]="defaultColDef">
				</ag-grid-angular>						
		</div>
		<div class="modal-footer">
			<button type="submit" class="btn btn-primary btn-sm"
				(click)="okMoreLocationParent()">{{ 'TAG_EDIT.OK' | translate }}</button>
		</div>
	</form>
</ng-template>
<!--Functional Location Template-->
<ng-template #functionalLocationTemplate>
	<div class="modal-header">
		<h5 class="modal-title">{{ 'TAG_ADD.TAG_LIST' | translate }}</h5>
		<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="fucnctionalLocationCancel()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<form ngNativeValidate>
		<div class="modal-body">			
				<ag-grid-angular #agGrid style="height: 550px;" class="ag-theme-balham" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }"
					 [enableFilter]="true" [rowData]="rowDataFunctionalLocation" [columnDefs]="columnDefsFunctionalLocation"
					[rowSelection]="'single'" [suppressRowDeselection]="true" [rowMultiSelectWithClick]="false" (rowClicked)="onRowClickFunctional($event)"
					[components]="components"  [pagination]="true" [paginationPageSize]="15" [getRowNodeId]="getRowNodeId" [rowSelection]="rowSelection"
					(rowSelected)="onRowSelected($event)" [defaultColDef]="defaultColDef">
				</ag-grid-angular>						
		</div>
		<div class="modal-footer">
			<button type="submit" class="btn btn-primary btn-sm"
				(click)="okMoreFunctionLocation()">{{ 'TAG_ADD.OK' | translate }}</button>
		</div>
	</form>
</ng-template>

<!--Changing Tag Classification-->
<ng-template #changingTagClassificationTemplate>
	<div class="modal-header">
		<h5 class="modal-title">Attributes</h5>
		<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="tagAttributesCancel()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<form ngNativeValidate>
		<div class="modal-body">
			<div class="row">
				<div class="col-sm-6">
					<h5 class="modal-title">Old Classification:</h5>
					<div *ngFor="let attribute of oldTagAttributes let i = index">
                            <div *ngIf="attribute.attributeType == 'Text'">
                                <div class="row">
									<div class="col-sm-3 float-left pb-1">
										<label class="mb-0 mt-1 col-sm-6 p-0">{{attribute.attributeName}}
											<div *ngIf="attribute.required=='yes'" class="badge-required mx-2"></div>
										</label>
									</div>
									<div class="col-sm-9 float-left pb-1">
										<input value="{{attribute.value}}" id="{{attribute.attributeId}}" type="text" [disabled]="true" class="form-control form-control-sm"
											(change)="getValueChanges($event, 'tag_attribute_text', attribute.attributeId, attribute.tagClsSpecId, attribute.required)">
										<!--<input #texts value="{{attribute.tagAttributeModel.attributeValue}}" id="{{attribute.attributeId}}"
										(change)="getValueChanges($event, 'text', attribute.attributeId)">-->
									</div>
									<!--<div class="col-sm-1 float-left pb-1">
										<button type="button" class="btn btn-warning btn-sm float-right my-1 btn-sm-form-dependancy"
											(click)="dependencyButtonClickEvent('TagAttribute')" [disabled]="attribute.dependencyCount == '0'"><i
											class="fa fa-link" aria-hidden="true"></i></button>
									</div>-->
								</div>
							</div>
                            <div *ngIf="attribute.attributeType == 'Number'">
                                <div class="row">
									<div class="col-sm-3 float-left pb-1">
										<label class="mb-0 mt-1 col-sm-6 p-0">{{attribute.attributeName}}
											<div *ngIf="attribute.required=='yes'" class="badge-required mx-2"></div>
										</label>
									</div>
									<div class="col-sm-9 float-left pb-1">
										<input value="{{attribute.value}}" id="{{attribute.attributeId}}" type="text" [disabled]="true" (keypress)="checkIfNumber($event)" class="form-control form-control-sm"
											(change)="getValueChanges($event, 'tag_attribute_text', attribute.attributeId, attribute.tagClsSpecId, attribute.required)">
										<!--<input #texts value="{{attribute.tagAttributeModel.attributeValue}}" id="{{attribute.attributeId}}"
										(change)="getValueChanges($event, 'text', attribute.attributeId)">-->
									</div>
									<!--<div class="col-sm-1 float-left pb-1">
										<button type="button" class="btn btn-warning btn-sm float-right my-1 btn-sm-form-dependancy"
											(click)="dependencyButtonClickEvent('TagAttribute')" [disabled]="attribute.dependencyCount == '0'"><i
											class="fa fa-link" aria-hidden="true"></i></button>
									</div>-->
								</div>
                            </div>
                            <div *ngIf="attribute.attributeType != 'Text' && attribute.attributeType != 'Number'">
                                <div class="row">
									<div class="col-sm-3 float-left pb-1">
										<label class="mb-0 mt-1 col-sm-6 p-0">{{attribute.attributeName}}
											<div *ngIf="attribute.required=='yes'" class="badge-required mx-2"></div>
										</label>
									</div>
									<div class="col-sm-9 float-left pb-1">
										<!--Documents-->
										<app-attribute-select *ngIf="attribute.attributeType == 'Document'"
											[selectedItem]="selectedOldTagAttributeValue[i].selectedDataId"
											[viewItems]="documentListForNgSelect"
											id="sysdoc_{{attribute.attributeId}}"
											name="sysdoc_{{attribute.attributeId}}"
											[disabled]="true"
											(emitSelected)="getValueChanges($event, 'tag_attribute_document', attribute.attributeId, attribute.tagClsSpecId, attribute.required)">
										</app-attribute-select>
	
										<!--Systems-->
										<app-attribute-select *ngIf="attribute.attributeType == 'System'"
											[selectedItem]="selectedOldTagAttributeValue[i].selectedDataId"
											[viewItems]="systemListForNgSelect"
											id="sysatt_{{attribute.attributeId}}"
											name="sysatt_{{attribute.attributeId}}"
											[disabled]="true"
											(emitSelected)="getValueChanges($event, 'tag_attribute_system', attribute.attributeId, attribute.tagClsSpecId, attribute.required)">
										</app-attribute-select>
	
										<!--Components-->
										<app-attribute-select *ngIf="attribute.attributeType == 'Component'"
											[selectedItem]="selectedOldTagAttributeValue[i].selectedDataId"
											[viewItems]="getComponentListByCategory(attribute.categoryId)"
											id="syscom_{{attribute.attributeId}}"
											name="syscom_{{attribute.attributeId}}"
											[disabled]="true"
											(emitSelected)="getValueChanges($event, 'tag_attribute_component', attribute.attributeId, attribute.tagClsSpecId, attribute.required)">
										</app-attribute-select>
	
										<!--Date-->
										<input *ngIf="attribute.attributeType == 'Date'" id="{{attribute.attributeId}}" value="{{attribute.value}}" [disabled]="true"
											type="date" (change)="getValueChanges($event, 'tag_attribute_date', attribute.attributeId, attribute.tagClsSpecId, attribute.required)"
											class="form-control form-control-sm"/>
									</div>
									<!--<div class="col-sm-1 float-left pb-1">
										<button type="button" class="btn btn-warning btn-sm float-right my-1 btn-sm-form-dependancy"
											(click)="dependencyButtonClickEvent('TagAttribute')" [disabled]="attribute.dependencyCount == '0'"><i
											class="fa fa-link" aria-hidden="true"></i></button>
									</div>-->
								</div>
							</div>
						</div>
				</div>
				<div class="col-sm-6">
					<h5 class="modal-title">New Classification:</h5>
					<div *ngFor="let attribute of newTagAttributes let i = index">
                            <div *ngIf="attribute.attributeType == 'Text'">
                                <div class="row">
									<div class="col-sm-3 float-left pb-1">
										<label class="mb-0 mt-1 col-sm-6 p-0">{{attribute.attributeName}}
											<div *ngIf="attribute.required=='yes'" class="badge-required mx-2"></div>
										</label>
									</div>
									<div class="col-sm-9 float-left pb-1">
										<input value="{{attribute.value}}" id="{{attribute.attributeId}}" type="text" maxlength="300" class="form-control form-control-sm"
											(change)="getValueChanges($event, 'tag_attribute_text', attribute.attributeId, attribute.tagClsSpecId, attribute.required)">
										<!--<input #texts value="{{attribute.tagAttributeModel.attributeValue}}" id="{{attribute.attributeId}}"
										(change)="getValueChanges($event, 'text', attribute.attributeId)">-->
									</div>
									<!--<div class="col-sm-1 float-left pb-1">
										<button type="button" class="btn btn-warning btn-sm float-right my-1 btn-sm-form-dependancy"
											(click)="dependencyButtonClickEvent('TagAttribute')" [disabled]="attribute.dependencyCount == '0'"><i
											class="fa fa-link" aria-hidden="true"></i></button>
									</div>-->
								</div>
							</div>
                            <div *ngIf="attribute.attributeType == 'Number'">
                                <div class="row">
									<div class="col-sm-3 float-left pb-1">
										<label class="mb-0 mt-1 col-sm-6 p-0">{{attribute.attributeName}}
											<div *ngIf="attribute.required=='yes'" class="badge-required mx-2"></div>
										</label>
									</div>
									<div class="col-sm-9 float-left pb-1">
										<input value="{{attribute.value}}" id="{{attribute.attributeId}}" type="text" maxlength="300" (keypress)="checkIfNumber($event)" class="form-control form-control-sm"
											(change)="getValueChanges($event, 'tag_attribute_text', attribute.attributeId, attribute.tagClsSpecId, attribute.required)">
										<!--<input #texts value="{{attribute.tagAttributeModel.attributeValue}}" id="{{attribute.attributeId}}"
										(change)="getValueChanges($event, 'text', attribute.attributeId)">-->
									</div>
									<!--<div class="col-sm-1 float-left pb-1">
										<button type="button" class="btn btn-warning btn-sm float-right my-1 btn-sm-form-dependancy"
											(click)="dependencyButtonClickEvent('TagAttribute')" [disabled]="attribute.dependencyCount == '0'"><i
											class="fa fa-link" aria-hidden="true"></i></button>
									</div>-->
								</div>
                            </div>
                            <div *ngIf="attribute.attributeType != 'Text' && attribute.attributeType != 'Number'">
                                <div class="row">
									<div class="col-sm-3 float-left pb-1">
										<label class="mb-0 mt-1 col-sm-6 p-0">{{attribute.attributeName}}
											<div *ngIf="attribute.required=='yes'" class="badge-required mx-2"></div>
										</label>
									</div>
									<div class="col-sm-9 float-left pb-1">
										<!--Documents-->
										<app-attribute-select *ngIf="attribute.attributeType == 'Document'"
											[selectedItem]="selectedTagAttributeValue[i].selectedDataId"
											[viewItems]="documentListForNgSelect"
											id="sysdoc_{{attribute.attributeId}}"
											name="sysdoc_{{attribute.attributeId}}"
											(emitSelected)="getValueChanges($event, 'tag_attribute_document', attribute.attributeId, attribute.tagClsSpecId, attribute.required)">
										</app-attribute-select>
	
										<!--Systems-->
										<app-attribute-select *ngIf="attribute.attributeType == 'System'"
											[selectedItem]="selectedTagAttributeValue[i].selectedDataId"
											[viewItems]="systemListForNgSelect"
											id="sysatt_{{attribute.attributeId}}"
											name="sysatt_{{attribute.attributeId}}"
											(emitSelected)="getValueChanges($event, 'tag_attribute_system', attribute.attributeId, attribute.tagClsSpecId, attribute.required)">
										</app-attribute-select>
	
										<!--Components-->
										<app-attribute-select *ngIf="attribute.attributeType == 'Component'"
											[selectedItem]="selectedTagAttributeValue[i].selectedDataId"
											[viewItems]="getComponentListByCategory(attribute.categoryId)"
											id="syscom_{{attribute.attributeId}}"
											name="syscom_{{attribute.attributeId}}"
											(emitSelected)="getValueChanges($event, 'tag_attribute_component', attribute.attributeId, attribute.tagClsSpecId, attribute.required)">
										</app-attribute-select>
	
										<!--Date-->
										<input *ngIf="attribute.attributeType == 'Date'" id="{{attribute.attributeId}}" value="{{attribute.value}}" 
											type="date" (change)="getValueChanges($event, 'tag_attribute_date', attribute.attributeId, attribute.tagClsSpecId, attribute.required)"
											class="form-control form-control-sm"/>
									</div>
									<!--<div class="col-sm-1 float-left pb-1">
										<button type="button" class="btn btn-warning btn-sm float-right my-1 btn-sm-form-dependancy"
											(click)="dependencyButtonClickEvent('TagAttribute')" [disabled]="attribute.dependencyCount == '0'"><i
											class="fa fa-link" aria-hidden="true"></i></button>
									</div>-->
								</div>
							</div>
						</div>
				</div>
			</div>
		</div>
		<div class="modal-footer">
			<button type="submit" class="btn btn-primary btn-sm" (click)="submitButtonClick()">
				{{ 'TAG_CLASSIFICATION.BTN_TXT_SUBMIT' | translate }}
			</button>
			<button type="button" formnovalidate class="btn btn-secondary btn-sm" data-dismiss="modal"
				(click)="tagAttributesCancel()">
				{{ 'TAG_CLASSIFICATION.BTN_TXT_CANCEL' | translate }}
			</button>
		</div>
	</form>
</ng-template>