import { OnInit, Component, ViewChild } from '@angular/core';
import { WorkflowService } from '../workflow.service';
import { ColDef, GridOptions, Module, AllModules } from '@ag-grid-enterprise/all-modules';
import { WfConfigureCellRendererComponent } from '../cell-renderer/configure/wf-configure-cell-renderer.component';
import { WfViewInstancesCellRendererComponent } from '../cell-renderer/viewinstances/wf-view-instances-cell-renderer.component';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/util/alert/alert.service';
import { WsResponse } from 'src/app/util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';
import { WfDocumentCellRendererComponent } from '../cell-renderer/documents/wf-document-cell-renderer.component';
import { WfViewTasksCellRendererComponent } from '../cell-renderer/viewtasks/wf-view-tasks-cell-renderer.component';


@Component({
    selector: 'app-workflow-templates',
    templateUrl: './workflow-templates.component.html'
  })
  
export class WorkflowTemplatesComponent implements OnInit{
    public columnDefs: any
    
    public defaultColDef: any;
    private gridApi:any
    private gridColumnApi:any
    public rowData: any = [];
    public gridOptions: GridOptions
    public overlayLoadingTemplate: any
    public overlayNoRowsTemplate:any
    public getRowNodeId: any
    modules: Module[] = AllModules;
    private workflowName: string;
    private configure: string;
    private viewInstances: string;
    private processName: string;
    private initiatedUser: string;
    private addedTime: string;
    private status: string;
    private viewTasks: string;
    private document: string;
    private projectWorkflowTemplateId: string = '';
    public showViewInstances: boolean = false;
    public viewInstancesColumnDefs: any
    public viewInstancesRowData: any = [];
    public viewInstancesGridOptions: GridOptions
    private viewInstancesgridApi:any
    private viewInstancesgridColumnApi:any

    constructor(private wfService: WorkflowService, private translate: TranslateService, private alertService: AlertService){
        translate.addLangs(["es", "en"]);
        translate.setDefaultLang("en");
        let browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
        this.translate.get('WORKFLOW_CONFIGURATION.WORKFLOW_NAME').subscribe((res: string) => {
            this.workflowName = res;
            this.translate.get('WORKFLOW_CONFIGURATION.CONFIGURE').subscribe((res: string) => {
                this.configure = res;
                this.translate.get('WORKFLOW_CONFIGURATION.VIEW_INSTANCES').subscribe((res: string) => {
                    this.viewInstances = res;
                    this.translate.get('VIEW_INSTANCES.PROCESS_NAME').subscribe((res: string) => {
                        this.processName = res;
                        this.translate.get('VIEW_INSTANCES.INITIATED_USER').subscribe((res: string) => {
                            this.initiatedUser = res;
                            this.translate.get('VIEW_INSTANCES.ADDED_TIME').subscribe((res: string) => {
                                this.addedTime = res;
                                this.translate.get('VIEW_INSTANCES.STATUS').subscribe((res: string) => {
                                    this.status = res;
                                    this.translate.get('VIEW_INSTANCES.VIEW_TASKS').subscribe((res: string) => {
                                        this.viewTasks = res;
                                        this.translate.get('VIEW_INSTANCES.DOCUMENT').subscribe((res: string) => {
                                            this.document = res;
                                        });
                                    });
                                });
                            });
                        });
                    });
                });
            });
        });

        this.columnDefs = [
            {
                headerName: 'Id',
                field: 'projWfTempMapId',
                hide: true
            },
            {
                headerName: this.workflowName,
                field: 'processName',
                width: 40
            },
            {
                headerName: 'ItemType',
                field: 'itemType',
                hide: true
            },
            {
                headerName: this.configure,
                field: 'configure',
                width: 40,
                cellRendererFramework: WfConfigureCellRendererComponent
            },
            {
                headerName: this.viewInstances,
                field: 'viewInstances',
                width: 40,
                cellRendererFramework: WfViewInstancesCellRendererComponent
            }
        ]

        this.viewInstancesColumnDefs = [
            {
                headerName: 'Id',
                field:'docusignEntryId',
                hide:true
            },
            {
                headerName: 'templateId',
                field:'templateId',
                hide:true
            },
            {
                headerName: this.processName,
                field: 'workflowName',
                width: 40
            },
            {
                headerName: this.initiatedUser,
                field: 'initiatedUser',
                width: 40
            },
            {
                headerName: this.addedTime,
                field: 'sendDate',
                width: 40
            },
            {
                headerName: this.status,
                field: 'status',
                filter: "agTextColumnFilter",
                width: 40
            },
            {
                headerName: this.document,
                field: 'document',
                width: 40,
                cellRendererFramework: WfDocumentCellRendererComponent
            },
            {
                headerName: this.viewTasks,
                field: 'tasks',
                width: 40,
                cellRendererFramework: WfViewTasksCellRendererComponent
            }
        ]

        this.getRowNodeId = function (data) {
            return data.id;
        };
        
        this.gridOptions = {
            rowData: this.rowData,
            columnDefs: this.columnDefs,
            rowSelection: "single",
            rowMultiSelectWithClick: false
        };

        this.viewInstancesGridOptions = {
            rowData: this.viewInstancesRowData,
            columnDefs: this.viewInstancesColumnDefs,
            rowSelection: "single",
            rowMultiSelectWithClick: false
        };

        this.defaultColDef = {
			resizable: true,
			sortable: true,
        };
        
        //custom layout templates for loading and empty data sets
        this.overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Loading data...</span>';
        this.overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">No rows to show</span>';
    }

    onGridReady(params) {
        this.gridColumnApi = params.columnApi;
        this.gridApi = params.api;
        this.gridOptions.api.setRowData(this.rowData);
        params.api.sizeColumnsToFit();
        // window.addEventListener("resize", function () {
        //     setTimeout(function () {
        //         params.api.sizeColumnsToFit();
        //     });
        // });
        window.addEventListener("resize", this.onWindowResize);
     }

     onViewInstancesGridReady(params) {
        this.viewInstancesgridColumnApi = params.columnApi;
        this.viewInstancesgridApi = params.api;
        this.viewInstancesGridOptions.api.setRowData(this.viewInstancesRowData);
        params.api.sizeColumnsToFit();
        // window.addEventListener("resize", function () {
        //     setTimeout(function () {
        //         params.api.sizeColumnsToFit();
        //     });
        // });
        window.addEventListener("resize", this.onWindowResize);
     }
     
     private onWindowResize = () => {
   
		setTimeout(() => {
			if (this.gridApi) {
				this.gridApi.sizeColumnsToFit();
			}
		}); 
	};

     ngOnDestroy(){
        window.removeEventListener("resize", this.onWindowResize);
        this.gridApi.destroy();
        this.gridApi = null;
     }

     onCellClicked(event: any){
        if(event.colDef.field == 'viewInstances'){
            if(event.data.projWfTempMapId != null && event.data.projWfTempMapId != ''){
                this.projectWorkflowTemplateId = event.data.projWfTempMapId
            }
            this.wfService.getDocusignWorkFlowEntriesByProjectWorkflowTemplateId(this.projectWorkflowTemplateId, this);
        }
    }

    ngOnInit(){
        //get workflow templates which are assigned to the selected project
        this.wfService.getActiveWorkFlowsPerProjectByItemType('ALL').subscribe(data => {
            this.loadData(data.payload);
        }, error => {
            this.alertService.clear()
            this.alertService.error(error.statusDescription)
        });
    }

    loadData(data: any){
        this.rowData = []
       for(let i = 0; i < data.length; i++){
            this.rowData.push({
                projWfTempMapId: data[i]["id"],
                processName: data[i]["processName"],
                itemType: data[i]["itemType"],
                configParameters: data[i]["configParameters"]
            })
       }
    }

    onSuccess(data: WsResponse, serviceType: WsType){
        if(serviceType == WsType.GET_WORKFLOW_DOCUSIGN_ENTRIES){
            if(data.payload){
                this.showViewInstances = true;
                this.viewInstancesRowData = data.payload;
            }
        }
    }
    onFail(data: WsResponse, serviceType: WsType){
        if(serviceType == WsType.GET_WORKFLOW_DOCUSIGN_ENTRIES){
            if(data.payload){
            this.alertService.clear()
            this.alertService.error(data.statusDescription)
            }
        }
    }
}