import { Component, OnInit } from '@angular/core';
import { ClientService } from '../client/client.service';
import { UserService } from '../user/user.service';
import { TranslateService } from '@ngx-translate/core';
import { GridOptions, ColDef, Module, AllModules } from "@ag-grid-enterprise/all-modules";
import { UserCellRenderComponent } from './user-cell-render-component.component';
import { SharedService } from '../util/shared.service';
import { Router } from '@angular/router';
import { CapabilityService } from '../util/capability.service';

@Component({
	selector: 'app-user',
	templateUrl: './user.component.html',
	styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

	clientList: Array<any> = [

	];
	selecedClient: any;
	private gridApi;
	public getRowNodeId;
	private gridColumnApi
	public gridOptions: GridOptions;
	public columnDefs: any;
	public defaultColDef: any;
	rowData: any = [];
	public modules: Module[] = AllModules;

	private userName: string = "";
	private firstName: string = "";
	private lastName: string = "";
	private client: string = "";
	private lastAccessDate: string = "";
	private emailAddress: string = "";
	private status: string = "";
	private edit: string = "";
	private delete: string = "";
	public overlayNoRowsTemplate: any;
	public overlayLoadingTemplate: any;
	public clientItems: Array<any>;

	// capabilities
    public deleteUser: boolean = false
	public disableUser: boolean = false
	public modifyUser: boolean = false
    
    // enable/disable buttons
    public enableDeleteUser: boolean = false
	public enableChangeUserStatus: boolean = false
	public enableEditUser: boolean = false

	constructor(private clientService: ClientService, private sharedService: SharedService, private router: Router, private translate: TranslateService, private userService: UserService,
		private capabilityService: CapabilityService) {
		this.enableDisableButton()
		translate.addLangs(["es", "en"]);
		translate.setDefaultLang("en");
		let browserLang = translate.getBrowserLang();
		translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');

		this.translate.get('USER.USERNAME').subscribe((res: string) => {
			this.userName = res;
			this.translate.get('USER.FIRSTNAME').subscribe((res: string) => {
				this.firstName = res;
				this.translate.get('USER.LASTNAME').subscribe((res: string) => {
					this.lastName = res;
					this.translate.get('USER.CLIENT').subscribe((res: string) => {
						this.client = res;
						this.translate.get('USER.LASTACCESSDATE').subscribe((res: string) => {
							this.lastAccessDate = res;
							this.translate.get('USER.EMAILADDRESS').subscribe((res: string) => {
								this.emailAddress = res;
								this.translate.get('USER.STATUS').subscribe((res: string) => {
									this.status = res;
									this.translate.get('USER.EDIT').subscribe((res: string) => {
										this.edit = res;
										this.translate.get('USER.DELETE').subscribe((res: string) => {
											this.delete = res;
										});
									});
								});
							});
						});
					});
				});
			});
		});
		this.columnDefs = [
			{
				headerName: this.userName,
				field: "userName",
				checkboxSelection: false,
				sortable: true
			},
			{
				headerName: this.firstName,
				field: "firstName",
				checkboxSelection: false,
				suppressColumnsToolPanel: true
			},
			{
				headerName: this.lastName,
				field: "lastName",
				checkboxSelection: false,
				suppressColumnsToolPanel: true
			},
			{
				headerName: this.client,
				field: "client",
				checkboxSelection: false,
				suppressColumnsToolPanel: true
			},
			{
				headerName: this.lastAccessDate,
				field: "lastAccessDate",
				checkboxSelection: false,
				suppressColumnsToolPanel: true
			},
			{
				headerName: this.emailAddress,
				field: "emailAddress",
				checkboxSelection: false,
				suppressColumnsToolPanel: true
			},
			{
				headerName: this.status,
				field: "status",
				width: 85,
				cellStyle: { 'text-align': 'center' },
				hide: !this.enableChangeUserStatus,
				cellRendererFramework: UserCellRenderComponent
			},
			{
				headerName: this.edit,
				field: "edit",
				width: 85,
				cellStyle: { 'text-align': 'center' },
				hide: !this.enableEditUser,
				cellRendererFramework: UserCellRenderComponent
			},
			{
				headerName: this.delete,
				field: "delete",
				width: 85,
				cellStyle: { 'text-align': 'center' },
				hide:!this.enableDeleteUser,
				cellRendererFramework: UserCellRenderComponent
			}
		];

		this.getRowNodeId = function (data) {
			return data.id;
		};

		this.gridOptions = {
			rowData: this.rowData,
			columnDefs: this.columnDefs,
			rowSelection: "single",
			rowMultiSelectWithClick: false,
			//isExternalFilterPresent: this.externalFilterPresent,
			//doesExternalFilterPass: this.externalFilterPass,

		};
		this.defaultColDef = {
			resizable: true,
			sortable: true,
		};
		this.overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Loading data...</span>';
		this.overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">No rows to show</span>';

	}

	ngOnInit() {
		console.log("data loading=================")

		this.clientService.getClientList().subscribe(
			data => {

				this.loadClients(data.payload);
				if (localStorage.getItem("clientId") != null && localStorage.getItem("clientId") != "") {
					this.selecedClient = localStorage.getItem("clientId")
					let list: Array<any> = [];
					list.push(
						{ id: this.selecedClient, text: localStorage.getItem("clientText") }
					);

					this.clientItems = list;
					this.getAllUsersByClientId();
				}
				//this.setClientsTable(data.payload)
			},
			error => {
				//this.alertService.clear()
				//this.alertService.error(error.statusDescription)
			});;

	}
	add() {
		this.sharedService.setFileId("-1");
		this.router.navigateByUrl('adduser');
	}

	clientSelected(event: any) {
		console.log("localStorage" + event.text)
		localStorage.setItem("clientId", event.id);
		localStorage.setItem("clientText", event.text);
		this.selecedClient = event.id
		this.getAllUsersByClientId();

	}


	getAllUsersByClientId() {
		this.userService.getAllUsersByClientId(this.selecedClient)
			.subscribe(data => {

				this.loadData(data.payload);
			},
			error => {

			});
	}

	loadData(data: any) {
		this.rowData = [];
		let preferences = localStorage.getItem('preferences');
		var json = JSON.parse(preferences);
		var innerJson = (JSON.parse(json)).UserPreference.Portal;
		let zone = innerJson.General.TimeZone;
		for (let i = 0; i < data.length; i++) {

			this.rowData.push({
				id: data[i]["id"],
				userName: data[i]["userName"],
				firstName: data[i]["firstName"],
				lastName: data[i]["lastName"],
				client: data[i]["clientName"],
				//lastAccessDate: new Date(data[i]["lastAccessDate"]).toLocaleString() != "Invalid Date" ? new Date(data[i]["lastAccessDate"]).toLocaleString() : data[i]["lastAccessDate"],
				lastAccessDate: data[i]["lastAccessDate"] === null ? null: (new Date(data[i]["lastAccessDate"]).toLocaleString("en-US", {timeZone: zone}) != "Invalid Date" ? new Date(data[i]["lastAccessDate"]).toLocaleString("en-US", {timeZone: zone}) : data[i]["lastAccessDate"]),
				//data[i]["lastAccessDate"],
				emailAddress: data[i]["emailAddress"],
				status: "status:" + data[i]["id"] + ":" + data[i]["status"],
				edit: "edit:" + data[i]["id"] + ":" + data[i]["status"],
				delete: "delete:" + data[i]["id"] + ":" + data[i]["status"]
			})
		}
		console.log("load" + this.rowData.length)
	}

	onGridReady(params) {
		const allColumnIds = [];
		this.columnDefs.forEach((columnDef) => {
			allColumnIds.push(<ColDef>columnDef);
		});
		this.gridColumnApi = params.columnApi;
		this.gridApi = params.api;
		//gridOptions.api.hideOverlay();
		this.userService.setApiGridData(this.gridApi);
		params.api.sizeColumnsToFit();
		// window.addEventListener("resize", function () {
		// 	setTimeout(function () {
		// 		params.api.sizeColumnsToFit();
		// 	});
		// });
		window.addEventListener("resize", this.onWindowResize);
	}

	private onWindowResize = () => {
   
		setTimeout(() => {
			if (this.gridApi) {
				this.gridApi.sizeColumnsToFit();
			}
		}); 
	};
	ngOnDestroy() {

        window.removeEventListener("resize", this.onWindowResize);
		this.gridApi.destroy();
    	this.gridApi = null;
    }
	sort(n1, n2)
	{
		if (n1.clientName > n2.clientName) {
			return 1;
		 }
		 if (n1.clientName < n2.clientName) {
			 return -1;
		 }
		 return 0;
	}

	loadClients(data: any) {
		//   console.log(data);
		let list: Array<any> = []
		list.push({ id: "-1", text: "--Select Client--" })
		list.push({ id: "null", text: "Internal Users" })

		var sortedArray: any[] = data.sort((n1,n2) => {
			if (n1.clientName > n2.clientName) {
				return 1;
			}
		
			if (n1.clientName < n2.clientName) {
				return -1;
			}
		
			return 0;
		});

		sortedArray.forEach(item => {
			list.push(
				{ id: item.clientId, text: item.clientName }
			)
		});
		this.clientList = list


		let list1: Array<any> = [];
		list1.push(
			{ id: "-1", text: "--Select Client--" }
		);

		this.clientItems = list1;

		// for(let i=0; i<data.length; i++)
		// {
		// 	// console.log("loading " + data[i].clientId + '/'+ data[i].clientName)
		// 	this.clientList.push({ id:data[i].clientId, text:data[i].clientName});
		// }
		// console.log(JSON.stringify(this.clientList.length));

	}

	/**
     * enable/disable buttons or columns
     */
	private enableDisableButton() {
		this.checkCapability()
		this.enableDeleteUser = this.deleteUser
		this.enableChangeUserStatus = this.disableUser
		this.enableEditUser = this.modifyUser
	}
	
	 /**
     * check the capabilities for given type
     */
	public checkCapability() {
		let deleteCapability: string = "deleteUser"
		let disableCapability: string = "disableUser"
		let modifyCapability: string = "modifyUser"
	
		this.deleteUser = this.capabilityService.isCapabilityAssigned(deleteCapability)
		this.disableUser = this.capabilityService.isCapabilityAssigned(disableCapability)
		this.modifyUser = this.capabilityService.isCapabilityAssigned(modifyCapability)   
	}

}
