<div *ngIf="!disable && !restore && !isText">
	<button id="landing-table-buttons" class="btn btn-sm btn-outline-dark py-0 my-1 px-0 border-0" (click)="invoke(type, data)">
		<i class={{icon}} aria-hidden="true"></i>
	</button>
</div>
<div *ngIf="disable && restore">
	<button class="btn btn-sm" disabled="true" matTooltip="Disabled">
		<i class={{icon}} style="color:rgb(110, 110, 108)" aria-hidden="true" (click)="invoke(type, data)"></i>
	</button>
</div>
<div *ngIf="!disable && restore && !isText">
	<a class="btn btn-sm">
		<i class={{icon}} style="color:#343a40" aria-hidden="true" (click)="invoke(type, data)"></i>
	</a>
</div>
<div *ngIf="disable && IsFile">
	<i class={{icon}} style="color:rgb(110, 110, 108)" aria-hidden="true"></i>
</div>
<div *ngIf="disable && !isText">
	<a class="btn btn-sm disabled">
		<i class={{icon}} style="color:rgb(110, 110, 108)" aria-hidden="true" (click)="invoke(type, data)"></i>
	</a>
</div>
<div *ngIf="isText">
	<span title="{{data}}">{{data}}</span>
</div>

<ng-template #viewVideoTemplate>
		<div class="modal-header">
			<h4 class="modal-title pull-left">{{ selectedVideoTitle }}</h4>
			<button type="button" id="video_close" class="close pull-right" aria-label="Close" (click)="close()">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body"  >
			<div *ngIf="url != null">  
				<video class="card p-0" controls width = "760" height="400"  src="{{url}}" type="video/mp4">
				</video>
			</div>
		</div>
		<div class="modal-footer" height="100">
			<button type="button" class="btn btn-sm btn-secondary" (click)="close()">{{
					'VIDEO_SEARCH_VIEW.BTN_TEXT_CLOSE' | translate }}</button>
			<button type="button" class="btn btn-sm btn-primary" (click)="insertAsVideo(selectedVideo)">{{
					'VIDEO_SEARCH_VIEW.BTN_TEXT_INSERT_AS_VIDEO' |
					translate }}</button>
		</div>
	  </ng-template>

	  <ng-template #noViewTemplate>
        <div >
				<div class="modal-header">
			<button type="button" id="video_close" class="close pull-right" aria-label="Close" (click)="close()">
				<span aria-hidden="true">&times;</span>
			</button>
				</div>
          <div  class="pl-4">
              <h5 class="text-center">
            <label>{{ 'NEW_VIDEO.NO_VIEW.MESSAGE' | translate }}</label>
            </h5>
            </div>
           
        </div>
		</ng-template>
		
		<ng-template #viewThreeDModelTemplate>
			<div class="modal-header">
				<h4 class="modal-title pull-left">{{ title }}</h4>
				<button type="button" id="image_close" class="close pull-right" aria-label="Close"
					(click)="close()">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="card p-0">
					<!--img *ngIf="isLoadedModel" class="card-img-top" src="{{url}}"
						alt="{{title}}"-->
						<img  class="card-img-top" src="{{url}}"
						alt="{{title}}">
					<!--div *ngIf="!isLoadedModel" class="alert alert-warning" role="alert">
						{{ 'IMAGE_SEARCH_VIEW.IMAGE_LOADING' | translate }}
					</div-->
				</div>
			
			
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-sm btn-secondary" (click)="close()">{{
						'IMAGE_SEARCH_VIEW.BTN_TEXT_CLOSE' | translate }}</button>
			</div>
			
			</ng-template>

<!-- Opened tab warning modal -->
<ng-template #tabOpenedWarningTemplate>
	<div class="modal-header">
		<h4 class="modal-title pull-left">{{ 'FILES_DATA.WARNING' | translate}}</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="tabOpenedWarnModalRef.hide();">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<label>{{ 'FILES_DATA.WARNING_MESSAGE_TAB_OPENED' | translate}}</label>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="openTabBtnEvent();tabOpenedWarnModalRef.hide();closeRevision()">
			{{ 'FILES_DATA.YES' | translate}}</button>
		<button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="tabOpenedWarnModalRef.hide();">
			{{ 'FILES_DATA.NO' | translate}}
		</button>
	</div>
</ng-template>
<!-- style="color:rgb(236, 167, 39)" -->