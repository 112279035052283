export class Folder {
    constructor(
		public name?: String,
		public parentId?: String,
		public id?: String,
		public createdBy?: String,
		public status?: String,
		public lastUpdatedBy?: String,
		public lastUpdatedTime?: any,
		public path?: String,
		public type?: String  ) {}
}
