
	<!--
			<div>
		<alert></alert>
	</div>
	-->
	<div class="col-lg-12">
		<div class="col" style="overflow:hidden">
			<div class="float-left">
				<h4>{{ 'ADD_USER.ADD_USER' | translate }}</h4>
			</div>
			<div class="float-right">
	
			</div>
		</div>
		<hr />
	</div>
	
	
	<div class="col-lg-12 mx-auto">
		<form [formGroup]="addUserForm">
			<!--first name-->
			<div class="form-group row">
				<label class="col-sm-2 col-form-label">{{ 'ADD_USER.FIRST_NAME' | translate }}<div class="badge-required mx-2"></div>
				</label>
				<div class="col-sm-10">
					<input id="firstName" formControlName="firstName" type="text" class="form-control form-control-sm" name="firstName"
					 >
				</div>
			</div>
			<div class="form-group row">
				<label class="col-sm-2 col-form-label">{{ 'ADD_USER.LAST_NAME' | translate }}<div class="badge-required mx-2"></div>
				</label>
				<div class="col-sm-10">
					<input id="lastName" formControlName="lastName" type="text" class="form-control form-control-sm" name="lastName"
					 >
				</div>
			</div>
			<div class="form-group row">
				<label class="col-sm-2 col-form-label">{{ 'ADD_USER.USER_NAME' | translate }}<div class="badge-required mx-2"></div><div *ngIf="checkOpenId">Username should be an email address</div>
				</label>
				<div class="col-sm-10">
					<input  id="userName" formControlName="userName" type="text" class="form-control form-control-sm" name="userName"
					 >
				</div>
			</div>
			<div class="form-group row">
				<label class="col-sm-2 col-form-label">{{ 'ADD_USER.CLIENT' | translate }}
				</label>
				<div class="col-sm-10">
					<div class="row">
						<div class="col-sm-1">
							<input #inputEl id="checkClient" [checked]="false" formControlName="checkClient" type="checkbox" class="form-control form-control-sm"
							 name="checkClient" (change)="Changed(inputEl.checked)">
						</div>
						<div class="col-sm-11">
							<ng-select *ngIf="!disableClientList" [allowClear]="true" [items]="clientList" name="itemType" formControlName="clientList"
							 id="itemType" (selected)= "clientSelected($event)" (removed) = "removed($event)">
							</ng-select>
						</div>
					</div>
				</div>
			</div>
			<div class="form-group row">
				<label class="col-sm-2 col-form-label">{{ 'ADD_USER.OPEN_ID_AUTHENTICATION' | translate }}
				</label>
				<div class="col-sm-10">
					<div class="row">
						<div class="col-sm-1">
							<input #inputE2 id="checkOpenId" formControlName="checkOpenId" type="checkbox" class="form-control form-control-sm"
							 name="checkOpenId" (change)="CheckOpenId(inputE2.checked)">
						</div>
					</div>
				</div>
			</div>
			<div class="form-group row">
				<label class="col-sm-2 col-form-label">{{ 'ADD_USER.STATUS' | translate }}<div class="badge-required mx-2"></div>
				</label>
				<div class="col-sm-10">
					<ng-select [allowClear]="true" [items]="statusList" name="status" (selected)="statusSelected($event)" formControlName="status" id="status">
					</ng-select>
				</div>
			</div>
			<div class="form-group row">
				<label *ngIf="!checkOpenId" class="col-sm-2 col-form-label">{{ 'ADD_USER.EMAIL' | translate }}
					<div class="badge-required mx-2"></div>
				</label>
				<div class="col-sm-10">
					<input *ngIf="!checkOpenId" id="email" formControlName="email" type="email" class="form-control form-control-sm" name="email" email>
				</div>
			</div>
			<div class="form-group row">
				<label *ngIf="!checkOpenId" class="col-sm-2 col-form-label">{{ 'ADD_USER.NEW_PASSWORD' | translate }}<div class="badge-required mx-2"></div>
				</label>
				<div class="col-sm-10">
					<input *ngIf="!checkOpenId" id="newPassword" formControlName="newPassword" type="password" class="form-control form-control-sm"
					 name="newPassword">
				</div>
			</div>
			<div class="form-group row">
				<label *ngIf="!checkOpenId" class="col-sm-2 col-form-label">{{ 'ADD_USER.CONFIRM_PASSWORD' | translate }}<div class="badge-required mx-2"></div>
				</label>
				<div class="col-sm-10">
					<input *ngIf="!checkOpenId" id="confirmPassword" formControlName="confirmPassword" type="password" class="form-control form-control-sm"
					 name="confirmPassword">
				</div>
			</div>
			<div class="form-group row">
				<label class="col-sm-2 col-form-label">{{ 'ADD_USER.SET_PASSWORD_EXPIRE' | translate }}
				</label>
				<div class="col-sm-10">
					<div class="row">
						<div class="col-sm-1">
							<input #inputE3 id="passwordExpire" formControlName="passwordExpire" type="checkbox" class="form-control form-control-sm"
							 name="passwordExpire" (change)="setPasswordExpire(inputE3.checked)">
						</div>
						<div class="col-sm-11">
							<!--
								<input *ngIf="passwordExpire" type="text" class="form-control" formControlName="expiryDate" bsDatepicker (bsValueChange)="onStartValueChange($event)"
							 [bsValue]="bsStartValue" [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY', containerClass: 'theme-default' }" id="startDate">
							-->
							 <input *ngIf="passwordExpire" type="text" class="form-control" formControlName="expiryDate" bsDatepicker (bsValueChange)="onStartValueChange($event)"
							 [value]="startDate" [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY', containerClass: 'theme-default' }" id="startDate">
							 <input *ngIf="!passwordExpire" type="text" class="form-control" formControlName="expiryDate" bsDatepicker (bsValueChange)="onStartValueChange($event)"
							 [value]="startDate" [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY', containerClass: 'theme-default' }" id="startDate" disabled>
						</div>
					</div>
				</div>
			</div>
			<div class="form-group row">
				<label class="col-sm-2 col-form-label">{{ 'ADD_USER.OMIT_AUTHENTICATION' | translate }}
				</label>
				<div class="col-sm-10">
					<div class="row">
						<div class="col-sm-1">
							<input #inputE4 id="authCode" formControlName="authCode" type="checkbox" class="form-control form-control-sm"
							 (change)="setOmitDeviceId(inputE4.checked)" name="authCode">
						</div>
					</div>
				</div>
			</div>
	
			<div class="form-group row ml-0">
				<div class="btn-toolbar">
					<button type="submit" class="btn btn-warning btn-sm mr-2" (click)="save()">{{ 'ADD_USER.SAVE' | translate }}</button>
				</div>
				<div class="btn-toolbar">
					<button type="button" class="btn btn-warning btn-sm mr-2" (click)="cancel()">{{ 'ADD_USER.CANCEL' | translate }}</button>
				</div>
			</div>
		</form>
	</div>