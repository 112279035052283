import { Injectable } from '@angular/core';
import { AddDocumentStoreComponent } from '../adddocumentstore/adddocumentstore.component';
import { DeletedDocumentStoreComponent } from '../deleteddocumentstore/deleteddocumentstore.component';
import { DocumentStoreComponent } from '../documentstore.component';
import { ProcedureInstancesDSComponent } from '../procedureinstances/procedureinstances.component';

@Injectable({
  providedIn: 'root'
})
export class DsLoaderService {

	constructor() { }

	public loadComponentByName(compName: string) {
		switch (compName) {
            case 'documentstore':
                return DocumentStoreComponent
            case 'adddocument':
                return AddDocumentStoreComponent
            case 'deleteddocument':
                return DeletedDocumentStoreComponent
            case 'procedureInstance':
                return ProcedureInstancesDSComponent
            default:
                break;
        }
	}
}
