import { Component, OnInit, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { TranslateService } from '@ngx-translate/core';
import { StatementsService } from './../statements.service';
import { WsCallback } from '../../../util/ws-callback.interface';
import { WsType } from '../../../util/ws-type';
import { WsResponse } from '../../../util/ws-response.model';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CategorytreeComponent } from '../../../util/categorytree/categorytree.component';
import { SharedService } from '../../../util/shared.service';
import { DependenciesmodalComponent } from '../../../util/dependenciesmodal/dependenciesmodal.component';
import { LocalStorage } from '../../../util/localstorage.service';
import { Statement } from '../../../reusables/statements/statement.model';
import { AlertService } from '../../../util/alert/alert.service';
import { UserVariable } from 'src/app/util/common/user-variable';
import { ServiceUrls } from 'src/app/util/service-urls';
import { LoadingService } from 'src/app/util/loading/loading.service';
import { Constants } from "src/app/util/constants";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CommonUtil } from 'src/app/util/common/common-util';

declare var CKEDITOR: any;

@Component({
	selector: 'app-addeditstatement',
	templateUrl: './addeditstatement.component.html',
	styleUrls: ['./addeditstatement.component.css']
})

export class AddeditstatementComponent implements OnInit {

	private stmStr: string;
	public displayStm: string = null;
	public displayHtml: SafeHtml;
	public statement: any = null;
	public data: any = null;
	public content: string = null;
	public reusableValue: string = null;
	public dataLoad: boolean = false;
	public isEdit: boolean = false;
	@ViewChild('statement_content') statement_content: ElementRef;
	@ViewChild('statementtite') statementtite: ElementRef;
	@ViewChild('statementcategoryName') statementcategoryName: ElementRef;
	@ViewChild('save_warn_for_statement') save_warn_for_statement: TemplateRef<any>;
	private modalRef: BsModalRef;
	multiLevelWarnModalRef: BsModalRef;
	dependencyType: string;
	public alert;
	public configData = {
		contentsCss: CKEDITOR.basePath + 'statementeditor.css',
		height: '20vh',
		extraPlugins: 'contextmenu, anyvalue, component, document, part, system, menu, floatpanel, panel, list, symbol, deletehandler',
		removePlugins: 'font,save,image,link,magicline,pagebreak,showblocks,smiley,copyformatting,abot,flash,find,iframe,print,removeformatting,preview,search,templates,form,checkbox,radiobutton,forms,' +
			'stylescombo,tableselection,tab,table,tabletools,a11yhelp,about,dialogadvtab,colordialog,div,wsc,scayt,pastetext,pastefromword,widget,uploadwidget,' +
			'uploadimage,uploadwidget,widget,stylescombo,wsc,filebrowser,indentblock,justify,format,popup,blockquote,maximize,horizontalrule,selectall,removeformat,' +
			'bidi,newpage,elementspath',
		removeButtons: 'Source,Subscript,Superscript,Strike,Language,Undo,Redo',
		resize_enabled: false,
		readOnly: false,
		deviceId: btoa(localStorage.getItem(LocalStorage.DEVICE_ID)),
		auth: JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER)).authtoken,
		url: ServiceUrls.COMPONENTS_SS_CATEGORY_VIEW + '?token=' + UserVariable.getUserToken(),
		basicEntities: true,
		autoParagraph: false,
		colorButton_colors: Constants.COLOR_PALETTE_COLORS

	}

	constructor(private translate: TranslateService, private router: Router, private StatementsService: StatementsService,
		private sharedService: SharedService, private modalService: BsModalService, private alertService: AlertService,
        public bsModalRef: BsModalRef, private loadingService: LoadingService, private sanitizer: DomSanitizer, private commonUtil: CommonUtil) {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
        // this.configData.url = ServiceUrls.COMPONENTS_SS_CATEGORY_VIEW + '?token=' + token
    }

	getType() {
		return localStorage.getItem("value");
	}

	ngOnInit() {
		this.loadingService.showLoading(
			true, false, this.translate.instant('STATEMENT_ADD_EDIT.LOADING'), 0);
		if (this.sharedService.getFileId() != "-1" && this.sharedService.getFileId() != -1) {
			this.isEdit = true;
			this.StatementsService.getStatementDataByStatementId(this);
			localStorage.setItem("value", "0");
		} else {
			this.dataLoad = true;
			localStorage.setItem("selectedContent", "");
			this.configData.contentsCss = CKEDITOR.basePath + 'contents.css';
			this.statement = new Statement();
			this.statement.categoryId = null
			let categories = this.StatementsService.getCategoryData()[this.StatementsService.getCategoryData().length - 1]
			this.statement.categoryName = categories;
			this.statement.referenceCount = 0;
			this.loadingService.hideLoading();
		}
	}

	viewDependencies(dflag: boolean) {
		this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.VIEW;
		this.dependenciesModalWithComponent('Dependencies List');
	}

	dependenciesModalWithComponent(header: String) {
		const initialState : object = {
			headerTitle: header,
			delFlag: false,
			cancelFlag: true,
			checkboxVal: false
		};
		this.sharedService.setFileId(this.statement.statementId);
		this.sharedService.setFileType("Statement");
        this.sharedService.setAction("getDependencies")
		this.sharedService.setParent(this);
		this.sharedService.setDependencyMessageType(null);
		this.modalService.show(DependenciesmodalComponent, { initialState, class: 'modal-lg' });
	}

	processContent(content: any, reusable: boolean) {
		let xmlDoc;
		this.content = content.replace(/(?!&amp;|&gt;|&lt;|&quot;|&nbsp;)\&/g, "&amp;");	
		this.content = this.content.replace(/fgc/gi, "color");
		this.content = this.content.replace(/background-fgc/gi, "background-color");
		if (!reusable) {
			localStorage.setItem("selectedContent", this.content);
		}
	}	

	private isInlineStm(node: any): boolean {
		let inlineStm: boolean = true;
		let length = node.childNodes.length;
		for (let i = 0; i < length; i++) {
			let innerNode = node.childNodes[i];
			if ($(innerNode)[0].nodeName == 'txt' || $(innerNode)[0].nodeName == 'ph' || $(innerNode)[0].nodeName == '#text') {
				inlineStm = true;
			} else {
				if (length == 1 && $(innerNode)[0].nodeName == 'p') {
					inlineStm = true;
				} else {
					inlineStm = false;
				}
				break;
			}
		}
		return inlineStm;
	}

	onSuccess(data: WsResponse, serviceType: WsType): void {
		if (data.payload != null && serviceType == WsType.STATEMENT_DATA_VIEW) {
			this.statement = data.payload;
			if (this.statement.reusableChangeInfo == null) {
				this.configData.contentsCss = CKEDITOR.basePath + 'contents.css';
				this.processContent(data.payload.content, false)
			}
			else {
				this.processContent(this.statement.reusableChangeInfo.newValue, false);
				this.content = this.statement.reusableChangeInfo.oldValue;
				this.displayStm = "";
				this.displayHtml = this.sanitizer.bypassSecurityTrustHtml(this.content);
				// this.content = this.statement.reusableChangeInfo.oldValue;
				// let xmlDoc;
				// this.displayStm = '';
				// let xmlStr = this.content;
				// xmlStr = xmlStr.replace(/(?!&amp;|&gt;|&lt;|&quot;|&nbsp;)\&/g, "&amp;");
				// try {
				// 	xmlDoc = $.parseXML(xmlStr);
				// } catch {
				// 	this.alertService.clear();
				// 	this.alertService.error(this.translate.instant('INVALID_XML_MSG'), true);
				// }
				// let outNode = $(xmlDoc).find('statement');
				// let length = outNode[0].childNodes.length;
				// for (let i = 0; i < length; i++) {
				// 	let innerNode = outNode[0].childNodes[i];
				// 	if ($(innerNode)[0].nodeName == 'txt' || $(innerNode)[0].nodeName == 'ph' || $(innerNode)[0].nodeName == 'p' || $(innerNode)[0].nodeName == 'ol' || $(innerNode)[0].nodeName == 'ul') {
				// 		this.processForReusableUpdate($(innerNode)[0]);
				// 	}
				// }
				// if (this.displayStm != '') {
				// 	this.displayHtml = this.sanitizer.bypassSecurityTrustHtml(this.displayStm);
				// }
				// this.statement.reusableChangeInfo.oldValue = this.content;
			}
			this.dataLoad = true;
			this.loadingService.hideLoading();
			
		} else if (serviceType == WsType.STATEMENT_UPDATE) {
			this.bsModalRef.hide();
			this.alertService.clear();
			this.alertService.info(this.translate.instant('STATEMENT_ADD_EDIT.UPDATE_MSG') +". " +this.translate.instant("REUSABLES.REFRESH_REUSABLE_TABLE"), true);				
		}
		else if (serviceType == WsType.STATEMENT_ADD) {
			this.bsModalRef.hide();
			this.alertService.clear();
			this.alertService.info(this.translate.instant('STATEMENT_ADD_EDIT.ADD_MSG') +". " +this.translate.instant("REUSABLES.REFRESH_REUSABLE_TABLE"), true);
		}
	}

	onFail(res: WsResponse, serviceType: WsType): void {
		if (serviceType == WsType.STATEMENT_SEARCH_VIEW) {
			if (res.statusCode == "532") {
				this.alertService.clear();
				this.alertService.error(res.statusDescription);
			}
			else if (res.statusCode == "600") {
				this.StatementsService.getStatementDataByStatementId(this)
			} else {
				this.alertService.clear();
				this.alertService.error(res.statusDescription);
			}
		} else if (serviceType == WsType.STATEMENT_UPDATE) {
			if (res.statusCode == "532") {
				this.alertService.clear();
				this.alertService.error(res.statusDescription);
			}
			else if (res.statusCode == "600") {
				this.StatementsService.updateStatement(JSON.stringify(this.statement), this.statement.id, this)
			} else {
				this.alertService.clear();
				this.alertService.error(res.statusDescription);
			}
		}
		else if (serviceType == WsType.STATEMENT_ADD) {
			if (this.statement.categoryId == undefined ||this.statement.categoryId == "" ||this.statement.categoryId == null || this.statement.categoryId == "null"  ){

				this.alertService.clear();
				this.alertService.error(this.translate.instant('STATEMENT_ADD_EDIT.CATEGORY_ERROR'));
			}
			else{
			this.alertService.clear();
			this.alertService.error(res.statusDescription);
			}
		}
	}

	private processForReusableUpdate(node: any) {
		let str = '';
		str = node.outerHTML;
		str = str.replace(/\u00A0/g, " ");
		str = str.replace(/<ol ckeditorlist="true" style="list-style-type:decimal">/gi, '<ol>');
		str = str.replace(/<ul ckeditorlist="true" style="list-style-type:disc">/gi, '<ul>');
		str = str.replace(/<ph.*?>/gi, '<txt style="background-color:#ffff00;">');
		str = str.replace(/<\/ph>/gi, '</txt>');
		str = str.replace(/<font/gi, '<txt');
		str = str.replace(/<\/font>/gi, '</txt>');
		str = str.replace(/<p>/gi, '<p style="margin-bottom:0;">');
		this.displayStm += str;
	}

	openModalWithComponent() {
		const initialState = {
			title: 'Select a Category',
			treeListener: this,
			initial: true,
			selectedItem: {
				text: this.statement.categoryName,
				id: this.statement.categoryId
			}
		};
		this.modalRef = this.modalService.show(CategorytreeComponent, { initialState, class: 'modal-lg' });
		this.modalRef.content.closeBtnName = 'Close';
	}

	loadCategoryTree() {
		console.log('[loadCategoryTree] ' + JSON.stringify(this.statement.categoryId));
		this.openModalWithComponent();
	}

	private htmlEncode(content: string): string {
		return content.replace(/(?!&amp;|&gt;|&lt;|&quot;|&nbsp;)\&/g, "&amp;").replace(/>/g, "&gt;").replace(/</g, "&lt;").
			replace(/"/g, "&quot;").replace(/'/g, "&#39;");
	}

	save() {
		let statementTitle = "";
		this.content = this.sharedService.getEditor().getData();
		if (this.statement.title == undefined || this.statement.title == "")  {
			this.alertService.error(this.translate.instant('STATEMENT_ADD_EDIT.TITLE_ERROR'), true);

			return;
		} else if (this.content == "") {
			this.alertService.error(this.translate.instant('STATEMENT_ADD_EDIT.CONTENT_ERROR'), true);
			return;
		}

		this.content = this.content.replace(/&nbsp;/g, " ");
		this.content = this.content.replace(/<br>/g, "");
		this.content = this.content.replace(/\u200B/g, "");
		this.content = this.content.replace(/\u000A/g, "");
		this.content = this.content.replace(/\u0009/g, "");
		this.content = this.content.replace(/\\/g, "\\\\");
		this.content = this.content.replace(/contenteditable="false"/gi, "");
		this.content = this.content.replace(/contenteditable="true"/gi, "");
		this.content = this.content.replace(/tagphid=""/gi, function insId() {
			function s4() {
				return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
			}
			return 'tagphid="' + s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4() + '"';
		});
		this.content = this.content.replace(/<txt>/gi, "");
		this.content = this.content.replace(/<\/txt>/gi, "");
		this.content = this.content.replace(/<!--\?xml version="1.0" encoding="UTF-8"\?-->/gi, "");
		this.content = this.content.replace(/<\?xml version="1.0" encoding="UTF-8"\?>/gi, "");		
		
		if(((this.content || '').match(/<\/p>/g) || []).length == 1 &&
			 !((this.content.includes("<ol")) || (this.content.includes("<ul")))){
			this.content = this.content.replace(/<p>/g, '');
			this.content = this.content.replace(/<\/p>/g, '');
		}

		statementTitle = this.statement.title.replace(/\\/g, "\\\\");
		//this.statement.title = statementTitle;
		this.stmStr = '';
		this.stmStr = '<statement id="" label="' + this.htmlEncode(statementTitle) + '">';	
		this.stmStr += this.content;	
		this.statement.content = this.stmStr + "</statement>";
		if ((this.statement.content.includes("<p>")) || (this.statement.content.includes("<ol")) || 
            (this.statement.content.includes("<ul"))) {
				this.openMultiLevelWarnModal(this.save_warn_for_statement);
		} else {
			if (this.sharedService.getFileId() != "-1" && this.sharedService.getFileId() != -1) {
				this.StatementsService.updateStatement(JSON.stringify(this.statement), this.statement.statementId, this);
				console.log("Edited Statement Content :" +this.statement.content);
			} else {
				this.StatementsService.addStatement(JSON.stringify(this.statement), this);
				console.log("Added Statement Content :" +this.statement.content);
			}
		}
	}

	saveMultiLevelStatement () {
		if (this.sharedService.getFileId() != "-1" && this.sharedService.getFileId() != -1) {
			this.StatementsService.updateStatement(JSON.stringify(this.statement), this.statement.statementId, this);
			console.log("Edited Statement Content :" +this.statement.content);
		} else {
			this.StatementsService.addStatement(JSON.stringify(this.statement), this);
			console.log("Added Statement Content :" +this.statement.content);
		}
	}
	
	cancel() {
		this.bsModalRef.hide()
		this.alertService.clear()
	}

	openMultiLevelWarnModal(template: TemplateRef<any>) {
        this.multiLevelWarnModalRef = this.modalService.show(template);
    }

}
