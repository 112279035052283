import { Component, OnInit } from '@angular/core';
import { WsResponse } from '../../../util/ws-response.model';
import { WsCallback } from "../../../util/ws-callback.interface";
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService } from '../../../util/alert/alert.service';
import { DeletewordComponent } from '../deleteword/deleteword.component';
import { DictionarysharedService } from '../dictionaryshared.service';
import { DictionaryService} from '../dictionary.service';


@Component({
	selector: 'app-dictionary-cell-render',
	templateUrl: './dictionary-cell-render.component.html',
	//styleUrls: ['./dictionary-cell-render.component.css']
})
export class DictionaryCellRenderComponent implements WsCallback {
	
	icon: string;
	type: string;
	params :any;
	id: any;
	data: any;
	bsModalRef: BsModalRef;
	

	constructor(private modalService: BsModalService, private dictionarysharedService: DictionarysharedService, private dictionaryService: DictionaryService ) { }

	

	agInit(params: any): void{
		this.params = params;
		let items = params.value.split(':')
		this.setType(items[0]);
		this.id = items[1];
	}


	onSuccess(data: WsResponse,): void {
	}


	onFail(res: WsResponse): void {
	}

	private setType(params) {
		this.type = params;
		if(params == 'delete'){
			this.icon = 'far fa-trash-alt';
		}
	}

	public invoke(type: String, data: any) {
        if(type == 'delete'){
			this.dictionarysharedService.setWordId(this.id);
			this.bsModalRef = this.modalService.show(DeletewordComponent, { class: 'modal-lg' });
		}
	  }
	  

}
