<!--
<div><alert></alert></div>
-->

	<div class = "col-lg-12">
		<div class = "col" style= "overflow: hidden">
			<div class = "float-left">
				<div *ngIf = "client == null ; else editClient">
					<h4 class = "text-center">
						{{ 'CLIENT_ADD_EDIT.ADD_CLIENT' | translate }}
					</h4>
				</div>
				<ng-template #editClient>
					<h4 class = "text-center">
						{{ 'CLIENT_ADD_EDIT.EDIT_CLIENT' | translate }}
					</h4>
				</ng-template>
			</div>
					<!--
						<h5 class="text-center">
						{{ 'CLIENT_ADD_EDIT.CLIENT' | translate }}
					</h5>
					-->
			<br />
		</div>
		<hr />
	</div>
	
<div>
	<div class="form-group row">
		<label for="clientName" class="col-sm-2 col-form-label ml-3">{{ 'CLIENT_ADD_EDIT.CLIENT_NAME' | translate }}<div class="badge-required mx-2"></div></label>
		<div class="col-sm-9">
			<input *ngIf="client!=null" id="clientName" type="text" class="form-control form-control-sm"
				name="clientName" [(ngModel)]="client.clientName">
			<input #clientName *ngIf="client==null" id="clientName" type="text" class="form-control form-control-sm">
		</div>
	</div>		
		
	<div class="form-group row">
		<label for="shortName" class="col-sm-2 col-form-label ml-3">{{ 'CLIENT_ADD_EDIT.SHORT_NAME' | translate }}<div class="badge-required mx-2"></div></label>
		<div class="col-sm-9">
			<input *ngIf="client!=null" id="shortName" type="text" class="form-control form-control-sm"
				name="shortName" [(ngModel)]="client.clientShortName">
			<input #shortName *ngIf="client==null" id="shortName" type="text" class="form-control form-control-sm">
		</div>
	</div>
	
	<div class="form-group row">
		<label for="status" class="col-form-label col-sm-2 pt-0 ml-3">{{ 'CLIENT_ADD_EDIT.STATUS' | translate }}<div class="badge-required mx-2"></div></label>
			<div class="form-check">
				<input  type="radio" value="0" [checked]="(editable === true && active === true)"  (click)="radioClick()" >
				<label class="form-check-label  mx-1">
					Active
				</label>
			</div>
			<div class="form-check ">
				<input  type="radio" value="0" [checked]="(editable === true && active === false)"  (click)="radioClick()">
				<label class="form-check-label  mx-1">
					Inactive
				</label> 
			</div>
	</div>
	<div class = "form-group row">
		<div class="col-sm-2">
			<input type="file" class="form-control-file " id="NewImage_FileUpload" accept=".png" 
			(change)="onFileChanged($event)" style="display: none;">
			<input type="button" class ="ml-3" value="Select File for Client Logo" onclick="document.getElementById('NewImage_FileUpload').click();" />	
		</div>
		<div class="col-sm-2">
			<div *ngIf = "client!=null">
				<div *ngIf = "fileChanged == true && uploadFileExt == 'png'">
					<img class="card-img-top ml-2" [src]="url">
				</div>
				<div *ngIf = "fileChanged == false && client.imageFile != null">
					<img class="card-img-top ml-2" src="data:image/png;base64,{{client.imageFile}}">
				</div>		
			</div>
			<div *ngIf = "client==null">
				<div *ngIf = "fileChanged != false && uploadFileExt == 'png'">
					<img class="card-img-top ml-2" [src]="url">
				</div>						
			</div>
		</div>
	</div>
	<div class = "form-group row ml-3">
		<div class="btn-toolbar">
			<button type="submit" id="client_save" class="btn btn-warning btn-sm mr-2 " (click)="save()">{{
				'CLIENT_ADD_EDIT.SAVE' | translate}}</button>
		</div>
		<div class="btn-toolbar ">
			<button type="button" id="client_cancel" class="btn btn-warning btn-sm mr-2" (click)="cancel()">{{
				'CLIENT_ADD_EDIT.CANCEL' | translate}}</button>
		</div>	
		</div>
	
</div>


