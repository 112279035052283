import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService } from 'src/app/util/alert/alert.service';
import { WsResponse } from 'src/app/util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';
import { RolesService } from '../roles.service';
import { RoleConfirmationCallback } from './role-confirmation-callback';

@Component({
  selector: 'app-role-confirmation-modal',
  templateUrl: './role-confirmation-modal.component.html',
  styles: []
})
export class RoleConfirmationModalComponent implements OnInit {
  public token;
  public attributeId;
  public confirmationTitle: string;
  public confirmationMessage: string;
  public callBack: RoleConfirmationCallback;

  ngOnInit(): void {
  }

  constructor(public bsModalRef: BsModalRef, private alertService: AlertService, private rolesService: RolesService) { }

  confirm(): void {
    this.callBack.onConfirm(this.attributeId);
  }

  decline(): void {
    this.callBack.onDecline();
  }
}
