<div class="main-panel pt-0 pb-4">
    <div class="container-fluid">
        <div class="col-sm-12 px-0">
          <h3> {{ 'WF_TEMPLATES.HEADER' | translate }} </h3>
          <hr class="mt-0 mb-3">
        </div>
        <div class="col-sm-12 px-0">
            <ag-grid-angular #agGrid style="width: 100%; height: 375px;" class="ag-theme-balham" [rowData]="rowData" [sideBar]="{ toolPanels: ['columns'] }"
            [columnDefs]="columnDefs" [gridOptions]="gridOptions" [pagination]="true" [paginationPageSize]="10"
            [overlayLoadingTemplate]="overlayLoadingTemplate" [overlayNoRowsTemplate]="overlayNoRowsTemplate" [modules]="modules"
            [getRowNodeId]="getRowNodeId" (gridReady)="onGridReady($event)" [suppressCellSelection]="true"  (cellClicked)="onCellClicked($event)" >
            </ag-grid-angular>
        </div>
    
    <div class="col-sm-12 px-0">
      <div *ngIf="showViewInstances" class="row">
        <div class="col-sm-12 my-2">
          <div class="row px-2">
            <div class="col">
              <label>
                {{ 'WF_TEMPLATES.VIEW_INSTANCES' | translate}}
              </label>
              <button type="button" class="close" aria-label="Close" (click)="showViewInstances = !showViewInstances">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
          <hr class="my-0" />
        </div>
        <div class="col-sm-12 my-2">
          <ag-grid-angular #agGrid style="width: 100%; height: 350px;" id="viewInstances" [rowData]="viewInstancesRowData"
           class="ag-theme-balham" [columnDefs]="viewInstancesColumnDefs" [enableSorting]="true" [enableFilter]="true"
           [enableColResize]="true" [enableColResize]="true" [sideBar]="{ toolPanels: ['columns'] }"
           [pagination]="true" [paginationPageSize]="10" (gridReady)="onViewInstancesGridReady($event)"
           [gridOptions]="viewInstancesGridOptions">
          </ag-grid-angular>
        </div>
      </div>
    </div>
</div>
</div>
