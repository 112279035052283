<div >
    <button id="landing-table-buttons" class="btn btn-sm btn-outline-dark py-0 my-1 px-1 border-0" (click)="showConfirm()">
        <i class="far fa-trash-alt" aria-hidden="true"></i>
    </button>
</div>
<ng-template #confirmation_warn>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{'IRN_CELL_RENDER.DELETE_CONFIRM' | translate}}</h4>
    </div>
    <div  class="modal-body pb-0">
        <h5>{{'IRN_PUNCHLIST_VIEW.DELETE_CONFIRM' | translate}}</h5>
    </div>

    <div class="modal-footer">
        <button type="button" id="warn_confirm" class="btn btn-primary"
                (click)="warnModalRef.hide(); delete();">
            {{ 'OK' | translate}} </button>
        <button type="button" id="warn_cancel" class="btn btn-secondary"
                data-dismiss="modal" (click)="warnModalRef.hide()">
            {{ 'CANCEL' | translate}} </button>
    </div>
</ng-template>

