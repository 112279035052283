import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService } from 'src/app/util/alert/alert.service';
import { LoadingService } from 'src/app/util/loading/loading.service';
import { WsCallback } from 'src/app/util/ws-callback.interface';
import { WsResponse } from 'src/app/util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';
import { ManageAdhocReportsService } from '../manage-adhoc-reports.service';

@Component({
    selector: 'app-add-adhoc-report',
    templateUrl: './add-adhoc-report.component.html',
    styleUrls: ['./add-adhoc-report.component.css']
})
export class AddAdhocReportComponent implements OnInit, WsCallback {

    @ViewChild('jrxmlInput') jrxmlInput: ElementRef;
    @ViewChild('csvInput') csvInput: ElementRef;
    @ViewChild('xmlInput') xmlInput: ElementRef;

    public reportName: string;
    public category: string;
    public selectedModuleList = [];
    public moduleList = ["SymbiosisWeb", "Dashboard/Commissioning"];
    public disableform: boolean = false;
    public disableXMLInput: boolean = true;

    public jrxmlFile: File;
    public csvFile: File;
    public xmlFile: File;

    constructor(
        public bsModalRef: BsModalRef,
        private manageAdhocReportsService: ManageAdhocReportsService,
        private alertService: AlertService,
        private loadingService: LoadingService,
        private translate: TranslateService
    ) { }

    onSuccess(data: WsResponse, serviceType: WsType) {
        if (serviceType == WsType.ADD_NEW_ADHOC_REPORT) {
            this.loadingService.hideLoading();
            this.alertService.success(this.translate.instant('MANAGE_ADHOC_REPORTS.ADD_SUCCESS'));
            this.bsModalRef.hide();
            this.manageAdhocReportsService.loadTableDataRequest();
        }
    }

    onFail(data: WsResponse, serviceType: WsType) {
        if (serviceType == WsType.ADD_NEW_ADHOC_REPORT) {
            this.loadingService.hideLoading();
            this.alertService.error(this.translate.instant('MANAGE_ADHOC_REPORTS.ERROR_ADD_REPORT'));
        }
    }

    ngOnInit(): void {
    }

    onJRXMLFileChanged(event) {
        this.jrxmlFile = <File>event.target.files[0];
        if (this.jrxmlFile != null) {
            var ext = this.jrxmlFile.name.split('.').pop().toLowerCase();
            if(ext != 'jrxml') {
                this.jrxmlInput.nativeElement.value = "";
                this.jrxmlFile = null;
                this.alertService.error(this.translate.instant('MANAGE_ADHOC_REPORTS.INVALID_JRXML'));
            }
        }
    }
    onCSVFileChanged(event) {
        this.csvFile = <File>event.target.files[0];
        if (this.csvFile != null) {
            this.disableXMLInput = false;
            
            var ext = this.csvFile.name.split('.').pop().toLowerCase();
            if(ext != 'csv') {
                //remove selected file
                this.csvInput.nativeElement.value = "";
                this.csvFile = null;

                //remove xml if there any
                this.xmlInput.nativeElement.value = "";
                this.xmlFile = null;
                this.disableXMLInput = true;

                this.alertService.error(this.translate.instant('MANAGE_ADHOC_REPORTS.INVALID_CSV'));
            }
        }
        else {
            this.xmlInput.nativeElement.value = "";
            this.xmlFile = null;
            this.disableXMLInput = true;
        }
    }
    onXMLFileChanged(event) {
        this.xmlFile = <File>event.target.files[0];
        if (this.xmlFile != null) {
            var ext = this.xmlFile.name.split('.').pop().toLowerCase();
            if(ext != 'xml') {
                this.xmlInput.nativeElement.value = "";
                this.xmlFile = null;
                this.alertService.error(this.translate.instant('MANAGE_ADHOC_REPORTS.INVALID_XML'));
            }
        }
    }

    save() {
        if (this.reportName == undefined || this.reportName == "") {
            this.alertService.clear();
            this.alertService.error(this.translate.instant('MANAGE_ADHOC_REPORTS.FIELD_EMPTY'));
        }
        else if (this.category == undefined || this.category == "") {
            this.alertService.clear();
            this.alertService.error(this.translate.instant('MANAGE_ADHOC_REPORTS.FIELD_EMPTY'));
        }
        else if (this.selectedModuleList == undefined || this.selectedModuleList.length == 0) {
            this.alertService.clear();
            this.alertService.error(this.translate.instant('MANAGE_ADHOC_REPORTS.FIELD_EMPTY'));
        }
        else if (this.jrxmlFile == undefined) {
            this.alertService.clear();
            this.alertService.error(this.translate.instant('MANAGE_ADHOC_REPORTS.FIELD_EMPTY'));
        }
        else if (this.csvFile != undefined && this.xmlFile == undefined) {
            this.alertService.clear();
            this.alertService.error(this.translate.instant('MANAGE_ADHOC_REPORTS.XML_FIELD_EMPTY'));
        }
        else {

            this.loadingService.showLoading(true, null, "Checking report name availability", null);

            this.manageAdhocReportsService.checkReportNameAvailability(this.reportName)
                .subscribe(data => {
                    if (data.payload) {
                        this.loadingService.showLoading(true, null, "Saving Report data", null);
                        let modules = [];
                        this.selectedModuleList.forEach(element => {
                            modules.push(element.value);
                        });

                        const uploadData = new FormData();
                        uploadData.append("reportName", this.reportName);
                        uploadData.append("category", this.category);
                        uploadData.append("modules", modules.toString());
                        uploadData.append("jrxmlFile", this.jrxmlFile, this.jrxmlFile.name);
                        uploadData.append("jrxmlFileName", this.jrxmlFile.name);
                        if (this.csvFile != undefined && this.xmlFile != undefined) {
                            uploadData.append("csvFile", this.csvFile, this.csvFile.name);
                            uploadData.append("xmlFile", this.xmlFile, this.xmlFile.name);
                            uploadData.append("csvFileName", this.csvFile.name);
                            uploadData.append("xmlFileName", this.xmlFile.name);
                        }

                        this.manageAdhocReportsService.addNewAdhocReport(uploadData, this);
                    }
                    else {
                        this.loadingService.hideLoading();
                        this.alertService.error(this.translate.instant('MANAGE_ADHOC_REPORTS.REPORT_NAME_EXISTS'));
                    }
                },
                    error => {
                        console.log('[checkReportNameAvailability] (error) ' + JSON.stringify(error));
                        this.loadingService.hideLoading();
                        this.alertService.error(error.statusDescription);
                    });
        }

    }

    clearFileInput(fileType: string) {
        switch (fileType) {
            case "jrxml":
                this.jrxmlInput.nativeElement.value = "";
                this.jrxmlFile = null;
                break;
            case "csv":
                this.csvInput.nativeElement.value = "";
                this.csvFile = null;

                //clear xml file and disable xml file input
                this.xmlInput.nativeElement.value = "";
                this.xmlFile = null;
                this.disableXMLInput = true;
                break;
            case "xml":
                this.xmlInput.nativeElement.value = "";
                this.xmlFile = null;
                break;

            default:
                break;
        }
    }

    //hides the this modal
    cancel() {
        this.bsModalRef.hide()
        this.alertService.clear()
    }

}
