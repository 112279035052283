import { Component, OnInit, EventEmitter } from '@angular/core';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';

@Component({
	selector: 'app-custom-typeahead',
	templateUrl: './custom-typeahead.component.html',
	styleUrls: ['./custom-typeahead.component.css'],
	inputs: ['typeahead_list', 'option_field', 'place_holder', 'val', 'initial_value', 'requiredField', 'scrollable', 'scrollable_options_view', 'options_limit'],
	outputs: ['text_changed']
})
export class CustomTypeaheadComponent implements OnInit {

	typeahead_list: any
	option_field: string = 'name'
	val: any = ''
	place_holder: string = 'Custom Typeahead Placeholder';
	initial_value: any
	requiredField: boolean;
	scrollable:boolean;
	scrollable_options_view:Number;
	options_limit:Number;

	selectedOption: any = null

	constructor() {

	}

	ngOnInit() {
		// console.log('[CustomTypeaheadComponent] (initial_value) ' + JSON.stringify(this.initial_value));
		this.setInitialValue(this.initial_value)
	}

	onSelect(event: TypeaheadMatch): void {
		this.selectedOption = event.item;
		this.val = event.item.name
		this.text_changed.emit({
			value: event
		})
	}

	text_changed = new EventEmitter<any>()

	textChange(value: any) {
		this.val = value
		this.text_changed.emit(
			{
				value: value
			}
		)
	}

	clearInput() {
		this.val = ''
		this.selectedOption = null
		//this.text_changed.emit(null)
	}

	setInitialValue(initialValue: any) {
		if (initialValue != null) {
			this.selectedOption = initialValue
			this.val = initialValue.name
			this.text_changed.emit({
				value: initialValue.value
			})
		} else {
			//do nothing
		}
	}

}
