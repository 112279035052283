import { LoadingService } from 'src/app/util/loading/loading.service';

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from "@angular/forms";
import { ClientService } from '../../client/client.service';
import { Router } from '@angular/router';
import { AlertService } from '../../util/alert/alert.service';
import { ProjectService } from '../project.service';
import { WsCallback } from 'src/app/util/ws-callback.interface';
import { WsResponse } from 'src/app/util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';
import { SharedService } from './../../util/shared.service';

@Component({
	selector: 'app-add-project',
	templateUrl: './add-project.component.html',
	styleUrls: ['./add-project.component.css']
})
export class AddProjectComponent implements OnInit, WsCallback {
	addProjectForm: FormGroup;
	clientList: any;
	userList : any;
	public projectTypes: Array<any> = [];
	public elements: Array<any> = [];
	public characterPerElement: Array<any> = [];
	public selectedClient: any = "";
	public selectedProjectType: any = "";
	public selectedElement: any = 0;
	public selectedCharacter1: any = "";
	public selectedCharacter2: any = "";
	public selectedCharacter3: any = "";
	public selectedCharacter4: any = "";
	public selectedCharacter5: any = "";
	public selectedCharacter6: any = "";
	public selectedCharacter7: any = "";
	public selectedCharacter8: any = "";
	public selectedCharacter9: any = "";
	public selectedCharacter10: any = "";
	checkAutoGenerate: boolean = false;
	allowDifferentProcInitiatedUsers: boolean = false;
	isCmmsTracking: boolean = false;
	public nameEmpty: any;
	public msgStartingEquipmentNumber: any;
	public msgNoOfElements: any;
	public msgNoOfCharacters: any;
	public msgClient: any;
	public msgProjectType: any
	constructor(private formBuilder: FormBuilder, private alertService: AlertService, private translate: TranslateService,
		 private clientService: ClientService, private router: Router,
		  private projectService: ProjectService, private sharedService: SharedService, 
		  private loadingService: LoadingService) {

		translate.addLangs(["es", "en"]);
		translate.setDefaultLang("en");
		let browserLang = translate.getBrowserLang();
		translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');

		this.translate.get('ADD_PROJECT.NAME_EMPTY').subscribe((res: string) => {
			this.nameEmpty = res;
			this.translate.get('ADD_PROJECT.MSG_STARTING_EQUIPMENT_NUMBER').subscribe((res: string) => {
				this.msgStartingEquipmentNumber = res;
				this.translate.get('ADD_PROJECT.MSG_NO_OF_ELEMENTS').subscribe((res: string) => {
					this.msgNoOfElements = res;
					this.translate.get('ADD_PROJECT.MSG_NO_OF_CHARACTERS').subscribe((res: string) => {
						this.msgNoOfCharacters = res;
						this.translate.get('ADD_PROJECT.MSG_SELECT_CLIENT').subscribe((res: string) => {
							this.msgClient = res;
							this.translate.get('ADD_PROJECT.MSG_PROJECT_TYPE').subscribe((res: string) => {
								this.msgProjectType = res;
							});
						});
					});
				});
			});
		});

		this.projectTypes = [
			{ 'id': '1', 'text': 'OA' },
			{ 'id': '2', 'text': 'COM' },
			{ 'id': '3', 'text': 'Both' }
		];

		this.elements = [
			{ 'id': '1', 'text': '1' },
			{ 'id': '2', 'text': '2' },
			{ 'id': '3', 'text': '3' },
			{ 'id': '4', 'text': '4' },
			{ 'id': '5', 'text': '5' },
			{ 'id': '6', 'text': '6' },
			{ 'id': '7', 'text': '7' },
			{ 'id': '8', 'text': '8' },
			{ 'id': '9', 'text': '9' },
			{ 'id': '10', 'text': '10' }
		];

		this.characterPerElement = [
			{ 'id': '1', 'text': '1' },
			{ 'id': '2', 'text': '2' },
			{ 'id': '3', 'text': '3' },
			{ 'id': '4', 'text': '4' },
			{ 'id': '5', 'text': '5' },
			{ 'id': '6', 'text': '6' },
			{ 'id': '7', 'text': '7' },
			{ 'id': '8', 'text': '8' },
			{ 'id': '9', 'text': '9' },
			{ 'id': '10', 'text': '10' },
			{ 'id': '11', 'text': '11' },
			{ 'id': '12', 'text': '12' },
			{ 'id': '13', 'text': '13' },
			{ 'id': '14', 'text': '14' },
			{ 'id': '15', 'text': '15' },
			{ 'id': '16', 'text': '16' },
			{ 'id': '17', 'text': '17' },
			{ 'id': '18', 'text': '18' },
			{ 'id': '19', 'text': '19' },
			{ 'id': '20', 'text': '20' }
		];
	}

	ngOnInit() {
		this.addProjectForm = this.formBuilder.group({
			projectName: new FormControl(null),
			clientList: new FormControl(null),
			projectTypes: new FormControl(null),
			additionalNotifiedParty: new FormControl(null),
			checkAutoGenerated: new FormControl(null),
			allowDifferentProcInitiatedUsers: new FormControl(null),
			equipmentNumber: new FormControl(null),
			cmmsTracking: new FormControl(null),
			numOfElements: new FormControl(null),
			characters1: new FormControl(null),
			characters2: new FormControl(null),
			characters3: new FormControl(null),
			characters4: new FormControl(null),
			characters5: new FormControl(null),
			characters6: new FormControl(null),
			characters7: new FormControl(null),
			characters8: new FormControl(null),
			characters9: new FormControl(null),
			characters10: new FormControl(null)
		});

		this.clientService.getClientList().subscribe(
			data => {

				this.loadClients(data.payload);

				//this.setClientsTable(data.payload)
			},
			error => {
				//this.alertService.clear()
				//this.alertService.error(error.statusDescription)
			});;

	}

	
	save() {
		console.log('save click');
		if(this.addProjectForm.controls.projectName.value == "" || this.addProjectForm.controls.projectName.value == null)
		{
			this.alertService.clear();
			this.alertService.error(this.nameEmpty);
		} 
		else if(this.selectedClient == null || this.selectedClient == "")
		{
			this.alertService.clear();
			this.alertService.error(this.msgClient);
		}
		else if(this.selectedProjectType == null || this.selectedProjectType == "")
		{
			this.alertService.clear();
			this.alertService.error(this.msgProjectType);
		}
		else if( this.checkAutoGenerate && (this.addProjectForm.controls.equipmentNumber.value == "" || this.addProjectForm.controls.equipmentNumber.value == null) )
		{
			this.alertService.clear();
			this.alertService.error(this.msgStartingEquipmentNumber);
		}
		else if(this.isCmmsTracking && (this.addProjectForm.controls.numOfElements.value == null || this.addProjectForm.controls.numOfElements.value == ""))
		{
			this.alertService.clear();
			this.alertService.error(this.msgNoOfElements);
		}
		else if (this.addProjectForm.controls.additionalNotifiedParty.invalid)
		{
			this.alertService.clear();
			this.alertService.error("Please enter a valid e-mail address");
		}
		else
		{
			if(this.selectedElement > 0 && this.selectedCharacter1 == "")
			{
				this.alertService.clear();
			this.alertService.error(this.msgNoOfCharacters);
			}else if(this.selectedElement > 1 && this.selectedCharacter2 == "")
			{
				this.alertService.clear();
			this.alertService.error(this.msgNoOfCharacters);
			}else if(this.selectedElement > 2 && this.selectedCharacter3 == "")
			{
				this.alertService.clear();
			this.alertService.error(this.msgNoOfCharacters);
			}else if(this.selectedElement > 3 && this.selectedCharacter4 == "")
			{
				this.alertService.clear();
			this.alertService.error(this.msgNoOfCharacters);
			}else if(this.selectedElement > 4 && this.selectedCharacter5 == "")
			{
				this.alertService.clear();
			this.alertService.error(this.msgNoOfCharacters);
			}else if(this.selectedElement > 5 && this.selectedCharacter6 == "")
			{
				this.alertService.clear();
			this.alertService.error(this.msgNoOfCharacters);
			}else if(this.selectedElement > 6 && this.selectedCharacter7 == "")
			{
				this.alertService.clear();
			this.alertService.error(this.msgNoOfCharacters);
			}else if(this.selectedElement > 7 && this.selectedCharacter8 == "")
			{
				this.alertService.clear();
			this.alertService.error(this.msgNoOfCharacters);
			}else if(this.selectedElement > 8 && this.selectedCharacter9 == "")
			{
				this.alertService.clear();
			this.alertService.error(this.msgNoOfCharacters);
			}else if(this.selectedElement > 9 && this.selectedCharacter10 == "")
			{
				this.alertService.clear();
			this.alertService.error(this.msgNoOfCharacters);
			}
			else
			{
				let projectName = this.addProjectForm.controls.projectName.value;
				let additionalNotifiedPartyEmail = this.addProjectForm.controls.additionalNotifiedParty.value;
				if (additionalNotifiedPartyEmail == "") {
					additionalNotifiedPartyEmail = null;
				}
				let projectType;
				if(this.selectedProjectType == "OA")
				{
					projectType = "1";
				} 
				else if(this.selectedProjectType == "COM")
				{
					projectType = "2";
				} 
				else
				{
					projectType = "3";
				}
				let cmmsNumbers = ""
				console.log("isCmmsTracking " + this.isCmmsTracking)
				if(this.isCmmsTracking)
				{
					for(let i=0; i<this.selectedElement; i++)
					{
						switch(i)
						{
							case 0:
							cmmsNumbers = this.selectedCharacter1;
							break;
							case 1:
							cmmsNumbers = cmmsNumbers + "," + this.selectedCharacter2;
							break;
							case 2:
							cmmsNumbers = cmmsNumbers + "," + this.selectedCharacter3;
							break;
							case 3:
							cmmsNumbers = cmmsNumbers + "," + this.selectedCharacter4;
							break;
							case 4:
							cmmsNumbers = cmmsNumbers + "," + this.selectedCharacter5;
							break;
							case 5:
							cmmsNumbers = cmmsNumbers + "," + this.selectedCharacter6;
							break;
							case 6:
							cmmsNumbers = cmmsNumbers + "," + this.selectedCharacter7;
							break;
							case 7:
							cmmsNumbers = cmmsNumbers + "," + this.selectedCharacter8;
							break;
							case 8:
							cmmsNumbers = cmmsNumbers + "," + this.selectedCharacter9;
							break;
							case 9:
							cmmsNumbers = cmmsNumbers + "," + this.selectedCharacter10;
							break;
							default:
							break;

						}
						
					}
				}
				this.alertService.clear();
				this.projectService.addProject(projectName, this.selectedClient, projectType,
					additionalNotifiedPartyEmail,
					 this.isCmmsTracking, this.selectedElement,
					 cmmsNumbers, this.checkAutoGenerate, this.addProjectForm.controls.equipmentNumber.value, this.allowDifferentProcInitiatedUsers, this );
				this.loadingService.showLoading(true,null,"Saving..",null);
			}
		}
	}

	onSuccess(data: WsResponse, serviceType: WsType) {
		if (serviceType == WsType.ADD_PROJECT) {
			//this.router.navigateByUrl("project");
			this.alertService.clear();
			this.alertService.success("Project added successfully");
			this.loadingService.hideLoading();
			this.sharedService.viewSideBarEventEmit("projects")
		}
	}
	onFail(data: WsResponse, serviceType: WsType) {
		if (serviceType == WsType.ADD_PROJECT) {
			this.alertService.clear();
			this.alertService.error(data.statusDescription);
			this.loadingService.hideLoading();
		}
	}

	projectTypeSelected(event: any)
	{
		this.selectedProjectType = event["text"]
	}

	CheckAutoGenerated(event: any) {
		if (event == true) {
			this.checkAutoGenerate = true;
		}
		else {
			this.checkAutoGenerate = false;
		}
	}

	checkAllowDifferentProcInitiatedUsers(event: any) {
		if (event == true) {
			this.allowDifferentProcInitiatedUsers = true;
		}
		else {
			this.allowDifferentProcInitiatedUsers = false;
		}
	}

	CheckCmmsTracking(event: any) {
		if (event == true) {
			this.isCmmsTracking = true;
		}
		else {
			this.isCmmsTracking = false;
		}
	}

	clientSelected(event: any) {
		this.selectedClient = event.id;

	}

	clientRemoved(event: any) {
		this.selectedClient = null;;
	}

	

	projectTypeRemoved(event: any) {
		this.selectedProjectType = null;
	}

	characterSelected1(event: any)
	{
		this.selectedCharacter1 = event["text"]
	}
	
	characterSelected2(event: any)
	{
		this.selectedCharacter2 = event["text"]
	}

	characterSelected3(event: any)
	{
		this.selectedCharacter3 = event["text"]
	}

	characterSelected4(event: any)
	{
		this.selectedCharacter4 = event["text"]
	}

	characterSelected5(event: any)
	{
		this.selectedCharacter5 = event["text"]
	}

	characterSelected6(event: any)
	{
		this.selectedCharacter6 = event["text"]
	}

	characterSelected7(event: any)
	{
		this.selectedCharacter7 = event["text"]
	}

	characterSelected8(event: any)
	{
		this.selectedCharacter8 = event["text"]
	}

	characterSelected9(event: any)
	{
		this.selectedCharacter9 = event["text"]
	}

	characterSelected10(event: any)
	{
		this.selectedCharacter10 = event["text"]
	}

	elementSelected(event: any) {
		this.selectedElement = event["text"];

	}

	loadClients(data: any) {
		//   console.log(data);
		let list: Array<any> = []
		//list.push({id:"-1", text:"Internal Users"})
		data.forEach(item => {
			list.push(
				{ id: item.clientId, text: item.clientName }
			)
		});
		this.clientList = list;

	}

	cancel() {
		//this.router.navigateByUrl("project");
		this.alertService.clear();
		this.sharedService.viewSideBarEventEmit("projects")
	}


	

}
