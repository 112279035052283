import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserVariable } from '../../util/common/user-variable';
import { ServiceUrls } from '../../util/service-urls';
import { WsCallback } from '../../util/ws-callback.interface';
import { WsType } from '../../util/ws-type';
import { WsResponse } from '../../util/ws-response.model';
import { LocalStorage } from '../../util/localstorage.service';
import { map } from 'rxjs/operators'
import { moment } from 'ngx-bootstrap/chronos/test/chain';
import { CommonUtil } from 'src/app/util/common/common-util';

@Injectable()
export class OutputManagementService {

   private apiGridData: any = null;
   private rowData: any = null;
   private callBack: any = null;

   constructor(private http: HttpClient, private commonUtil: CommonUtil) {
   }

   /**
   * Call service method to get all outputs using GET request.
   **  
   */

   getAllOutputs(): Observable<WsResponse> {
      let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
      const options = { params: new HttpParams().set("token", token) }
      return this.http.get(ServiceUrls.GET_ALL_OUTPUTS, options).
         pipe(
            map(response => {
               var modified = JSON.parse(JSON.stringify(response));
               return new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
            }),
            catchError(error => {
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
               return throwError(res)
            })
         );
   }

   getOutputSearchResults(startValue: any, endValue: any, statusId: any, itemTypeId: any, 
      outputType: any ): Observable<WsResponse> {      
      let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
      const options = { params: new HttpParams().set("token", token).set("startValue", startValue)
      .set("endValue", endValue).set("statusId", statusId).set("itemTypeId", itemTypeId)
      .set("outputType" , outputType) }
      return this.http.get(ServiceUrls.GET_OUTPUT_SEARCH_RESULT, options).
         pipe(
            map(response => {
               var modified = JSON.parse(JSON.stringify(response));
               return new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
            }),
            catchError(error => {
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
               return throwError(res)
            })
         );
   }

   updatePrcessStatus(fileId: any, status: any): Observable<WsResponse> {      
      let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
      const options = { params: new HttpParams().set("token", token).set("fileId", fileId).set("status", status) }
      return this.http.get(ServiceUrls.UPDATE_PROCESS_STATUS, options).
         pipe(
            map(response => {
               var modified = JSON.parse(JSON.stringify(response));
               return new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
            }),
            catchError(error => {
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
               return throwError(res)
            })
         );
   }

   getProjectTemplates() {
      let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
      const options = { params: new HttpParams().set("token", token) }
      return this.http.get(ServiceUrls.GET_PROJECT_TEMPLATE, options).
         pipe(
            map(response => {
               var modified = JSON.parse(JSON.stringify(response));
               return new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
            }),
            catchError(error => {
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
               return throwError(res)
            })
         );
   }

   getImportedDocumentConfigInfo(tempId: any) {
      let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
      const options = { params: new HttpParams().set("token", token).set("tempId", tempId) }
      return this.http.get(ServiceUrls.GET_IMPORTED_DOCUMENT_CONFIG, options).
         pipe(
            map(response => {
               var modified = JSON.parse(JSON.stringify(response));
               return new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
            }),
            catchError(error => {
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
               return throwError(res)
            })
         );
   }

   getDocumentConfig(templateId: any, selectConfigName: any) {
      let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
      const options = { params: new HttpParams().set("token", token).set("templateId", templateId).set("selectConfigName", selectConfigName)}
      return this.http.get(ServiceUrls.GET_DOCUMENT_CONFIG, options).
         pipe(
            map(response => {
               var modified = JSON.parse(JSON.stringify(response));
               return new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
            }),
            catchError(error => {
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
               return throwError(res)
            })
         );
   }

   saveImportedDocumentConfig(importDoc: any) {
      let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
      importDoc['userToken'] = token     
      return this.http.post(ServiceUrls.SAVE_IMPORTED_DOCUMENT_CONFIG, JSON.stringify(importDoc)).
         pipe(
            map(response => {
               var modified = JSON.parse(JSON.stringify(response));
               return new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
            }),
            catchError(error => {
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
               return throwError(res)
            })
         );
   }

   getTemplates() {      
      let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
      const options = { params: new HttpParams().set("token", token) }
      return this.http.get(ServiceUrls.GET_TEMPLATE, options).
         pipe(
            map(response => {
               var modified = JSON.parse(JSON.stringify(response));
               return new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
            }),
            catchError(error => {
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
               return throwError(res)
            })
         );
   }

   projectTemplateSave(editedTemp: any) {
      let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
      editedTemp['userToken'] = token     
      return this.http.post(ServiceUrls.PROJECT_TEMPLATE_SAVE, JSON.stringify(editedTemp)).
         pipe(
            map(response => {
               var modified = JSON.parse(JSON.stringify(response));
               return new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
            }),
            catchError(error => {
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
               return throwError(res)
            })
         );
   }

   editOptionalData(editedOPdata: any) {
      let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
      editedOPdata['userToken'] = token     
      return this.http.post(ServiceUrls.EDITED_OP_DATA, JSON.stringify(editedOPdata)).
         pipe(
            map(response => {
               var modified = JSON.parse(JSON.stringify(response));
               return new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
            }),
            catchError(error => {
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
               return throwError(res)
            })
         );
   }

   editTemplateName(editedTempName: any) {
      let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
      editedTempName['userToken'] = token
      return this.http.post(ServiceUrls.EDITED_TEMPLATE_NAME, JSON.stringify(editedTempName)).
         pipe(
            map(response => {
               var modified = JSON.parse(JSON.stringify(response));
               return new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
            }),
            catchError(error => {
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
               return throwError(res)
            })
         );
   }

   getOutputModelsByProcessStatusList() {
      let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
      const options = { params: new HttpParams().set("token", token) }
      return this.http.get(ServiceUrls.GET_OUTPUT_MODEL_BY_PROCESS_STATUS, options).
         pipe(
            map(response => {
               var modified = JSON.parse(JSON.stringify(response));
               return new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
            }),
            catchError(error => {
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
               return throwError(res)
            })
         );
   }

   changePriority(editedValue: any) {
      let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }      
      return this.http.post(ServiceUrls.CHANGE_PRIORITY, JSON.stringify(editedValue)).
         pipe(
            map(response => {
               var modified = JSON.parse(JSON.stringify(response));
               return new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
            }),
            catchError(error => {
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
               return throwError(res)
            })
         );
   }

   getOutputConfigList(){
      let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
      const options = { params: new HttpParams().set("token", token) }
      return this.http.get(ServiceUrls.GET_OUTPUT_CONFIG_LIST_BY_PROJECT, options).
         pipe(
            map(response => {
               var modified = JSON.parse(JSON.stringify(response));
               return new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
            }),
            catchError(error => {
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
               return throwError(res)
            })
         );
   }

   updateOutputConfig(editedValue: any) {
      let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
      editedValue['userToken'] = token
      return this.http.post(ServiceUrls.UPDATE_OUTPUT_CONFIG, JSON.stringify(editedValue)).
         pipe(
            map(response => {
               var modified = JSON.parse(JSON.stringify(response));
               return new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
            }),
            catchError(error => {
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
               return throwError(res)
            })
         );
   }

   addOutputConfig(editedValue: any) {
      let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
      editedValue['userToken'] = token
      return this.http.post(ServiceUrls.ADD_OUTPUT_CONFIG, JSON.stringify(editedValue)).
         pipe(
            map(response => {
               var modified = JSON.parse(JSON.stringify(response));
               return new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
            }),
            catchError(error => {
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
               return throwError(res)
            })
         );
   }

   getMoreOutputInfo(id: any, itemType: any) {
      let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
      const options = { params: new HttpParams().set("token", token).set("id", id).set("itemType", itemType) }
      return this.http.get(ServiceUrls.GET_MORE_OUTPUT_INFO, options).
         pipe(
            map(response => {
               var modified = JSON.parse(JSON.stringify(response));
               return new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
            }),
            catchError(error => {
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
               return throwError(res)
            })
         );

   }

   setCallBack(callBack: WsCallback){
      this.callBack = callBack;
   }
   
   deleteProjectTemplate(id: any) {
      let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
      const options = { params: new HttpParams().set("token", token).set("projectTemplateId", id) }
      this.http.get(ServiceUrls.DELETE_PROJECT_TEMPLATE, options).
         subscribe(
            data => {
					var modified = JSON.parse(JSON.stringify(data));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					this.callBack.onSuccess(res, WsType.DELETE_ITEM);
				},
				error => {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					this.callBack.onFail(res, WsType.DELETE_ITEM);
				});

   }

   downloadFile(outputId: string, outputFile: string) {
      return this.http.get(ServiceUrls.DOWNLOAD_FILE, {
         responseType: "blob", params: new HttpParams().
            set("documentId", outputId).set("mode", "ViewOutput").set("fileName", outputFile)
      });
   }

   public setItemData(id: String, optionalData: string, itemType: string) {
		if (this.apiGridData != null && this.apiGridData != undefined && id != null) {
            var rowNode = this.apiGridData.getRowNode(id);
			if (rowNode != null && rowNode != undefined) {
            if(itemType == "opData"){
               rowNode.setDataValue("optionalData", optionalData);
            }else{
               rowNode.setDataValue("templateName", optionalData);
            }
			}
		}
   }

   /**
  * Call service method to   getOutputErrorMessage using GET request.    
  * @param Id  output id
  * @param prcStatus process status value
  */
   regenarateOutput(id: string, prcStatus: string): Observable<WsResponse> {
      let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
      const options = { params: new HttpParams().set("token", token) }
      return this.http.get(ServiceUrls.regenarateOutput(id, prcStatus), options).
         pipe(
            map(response => {
               var modified = JSON.parse(JSON.stringify(response));
               return new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
            }),
            catchError(error => {
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
               return throwError(res)
            })
         );
   }

   public setApiGridData(apiGridData: any) {
		this.apiGridData = apiGridData;
	}

	public getApiGridData() {
		return this.apiGridData;
	}

	public setRowData(rowData: any) {
		this.rowData = rowData;
	}

	public getRowData() {
		return this.rowData;
   }
}