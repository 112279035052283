import { Component, OnInit, Input,TemplateRef, ElementRef, HostListener, ViewChild} from '@angular/core';
import { IceTopicContent,ProcImage, ProcVideo, ProcThreeDModel } from './ice-topic-content-model.model';
import { IceLearningCheck } from '../ice-learning-check/ice-learning-check.model';
import { IceGame } from '../ice-game/ice-game-model.model';
import { TranslateService } from '@ngx-translate/core';
import { ICECourseService } from '../ice-course-service.service';
import { AlertService } from '../../util/alert/alert.service';
import { LoadingService } from '../../util/loading/loading.service';
import { FolderService } from '../../foldertree/foldertree.service';
import { SharedService } from '../../util/shared.service';
import { UserVariable } from '../../util/common/user-variable';
import { WsResponse } from '../../util/ws-response.model';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { WsType } from '../../util/ws-type';
import { IceTopic } from '../ice-topic/ice-topic-model.model';
import { BsModalService } from 'ngx-bootstrap/modal'
import { ICECKEditor } from '../ice-course';
import { LocalStorage } from '../../util/localstorage.service';
import { CK_Editor_Type  } from '../../util/constants';
import { LearningCheckData_Details  } from '../../util/constants';
import { ICECourse } from '../ice-course.model';
import { GridOptions, RowNode, Module, AllModules } from "@ag-grid-enterprise/all-modules";
import { CommonUtil } from 'src/app/util/common/common-util';

declare var CKEDITOR: any;
var editor;

@Component({
  selector: 'app-ice-topic-content',
  templateUrl: './ice-topic-content.component.html',
  styleUrls: ['./ice-topic-content.component.scss']
})
export class IceTopicContentComponent implements OnInit {

	public divHeight: number = (window.innerHeight) * 4 / 5;
	@HostListener('window:resize', ['$event'])
	onresize(event?) {
		this.divHeight = (window.innerHeight) * 4 / 5
	}
	@ViewChild('conflict_checker') conflict_checker: TemplateRef<any>;
	@ViewChild('agGrid') agGrid;
    @ViewChild('agGrid2') agGrid2;
	public isShowIceTopicContentDetails: boolean = true;
	public isShowIceTopicContent: boolean = false;
	public isShowIceTopicContentBullets: boolean = false;
	public isShowLearningChecks: boolean = false;
	public isShowIceGames: boolean = false;
	public isViewOnly: boolean = false;
	public topicContentModified: boolean = false;
	modalRef: BsModalRef;

	public iceTopicContentList: Array<any> = [];
	public iceLearningCheckList: Array<any> = [];
	public iceGameList: Array<any> = [];

	private insertSubscription: any;
	private insertThree3DModelSubscription: any;

	public speCharList: Array<any> = [];

	public toolbarId: string;
	public desceditor: any;
	private html: string;
	private tagContent: String = '';
	private selection: any;
	private ranges: any;
	public breadCrumbList:Array<any> = [];
	public topicContentDescriptionShowInDetails:any = null;
	public addFractionDIvId: String = "";
	public addSpecialCharDivId: String = "";

	private xmlContent: string = '';
	public userId: String;
	public rowData: any[] = [];
	private stmHtml: string;
	private gridApi;
	public getRowNodeId;
	public modules: Module[] = AllModules;
	private gridColumnApi;
	private rowSelection
	public gridOptions: GridOptions;
	public sideBar: any;
	public resetArrays: any = false;

	private columnDefs2;
	public rowData2;
	private groupDefaultExpanded;
	private getDataPath;
	private autoGroupColumnDef;

	topic_translated_data: any = null;
	public tabId: string;
	public iceCourseModel: ICECourse;
	public iceCoursesAvailable: boolean = false;
	public selectedIceCourse: string = null;
	public selectedConfirmed: boolean = false;
	public linkedCourse: string = null;
	public iceCourseList: Array<any> = [];
	public iceTopicContentType: string;
	public viewContent: string;
	public viewTypes: Array<any> = [];
	public selectedViewInfo: any = null;
	public selectedViewName: any = null;
	public selectedNodes: RowNode[]; 
	public hiddenLayers: Array<any> = []; 
	public blinkLayers: Array<any> = []; 
	public selectedHiddenlayers: Array<any> = [];
	public selectedBlinklayers: Array<any> = [];
	public selectedViewContent: string;
	public isImageChecked = false;
	public isVideoChecked = false;
	public isThreeDModelChecked = false;
	public selectedViewType: Array<any> = []; 
	public topicContentEnabled: boolean = false;
	public placeholder;
	public hasSavedModelInfo: boolean = false;
	public hasSelectedModelView: boolean = false;
	copyModelMaxCount: number = 10;

  	public modalConfig: any = {
		backdrop: true,
		// ignoreBackdropClick: true,
		class: 'modal-lg',
		// keyboard: false
	}

	public selectedImage: any = {
		'id': '',
		'thumbnailImage': '',
		'largeImage': '',
		'title': ''
	};
	public selectedVideo: any = {
		'id': '',
		'thumbnailVideo': '',
		'largeVideo': '',
		'title': '',
		'selectedURL': '',
		'posterframeURL':''
	};
	public selectedThreeDModel: any = {
		'id': '',
		'thumbnailThreeDModel': '',
		'largeThreeDModel': '',
		'title': '',
		'selectedURL': '',
		'layerContent': '',
		'viewContent': ''
	};
	public selectedModalTemplate: TemplateRef<any>;

	private inlineConfig = {
		toolbar: [
			{ name: 'clipboard', groups: ['clipboard', 'undo'], items: ['Cut', 'Copy',  '-', 'Undo', 'Redo'] },
			{ name: 'basicstyles', groups: ['basicstyles'], items: ['Bold', 'Italic', 'Underline', 'Subscript', 'Superscript'] },
			{ name: 'paragraph', groups: ['list'], items: ['NumberedList', 'BulletedList'] },
			{ name: 'insert', groups: ['insert'], items: ['addsymbol', 'addfraction'] }
			//{ name: 'editing', groups: ['spellchecker'], items: ['Scayt'] }
		],
		autoParagraph: false,
		readOnly: false,
		removePlugins: 'liststyle, magicline, maximize, resize, wsc',
		pasteFilter: 'plain-text',
		extraPlugins: 'statementclickhandler, sharedspace, addsymbol, addfraction, disablepartialbui, reusablepanelhandler, deletezws, deletehandler',
		title: false,
		tabID: SharedService.getTabId(),
		enterMode: CKEDITOR.ENTER_BR,
		startupFocus: 'end'
	};

	public columnDefs = [
		{ headerName: '', field: 'cbox', width: 50, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
		{ headerName: 'Type', field: 'type', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 100, enableRowGroup: true },
		{ headerName: 'Last Value', field: 'lastVal', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 100, enableRowGroup: true, tooltipField: "lastVal" },
		{ headerName: 'New Value', field: 'newVal', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 100, enableRowGroup: true, tooltipField: "newVal" },
		{ headerName: 'Id', field: 'id', hide: true },
		{ headerName: 'Name', field: 'name', hide: true },
		{ headerName: 'LastUpdateTimeStamp', field: 'lastUpdateTimeStamp', hide: true },
		{ headerName: 'isDeleted', field: 'isDeleted', hide: true }
	]

	public defaultColDef = {
		resizable: true,
		sortable: true,
	}

	@Input() data: any;

	public iceTopicContent: IceTopicContent;
	public iceTopic: IceTopic;

	constructor(private sharedService: SharedService, private translate: TranslateService,private loadingService: LoadingService,
 		private alert: AlertService, private courseService: ICECourseService,private modalService: BsModalService, private folderService: FolderService, private alertService: AlertService,private iceCKEditor: ICECKEditor, private elementRef: ElementRef, private commonUtil: CommonUtil) {
		this.iceTopicContent = new IceTopicContent("0");
		this.tabId = SharedService.getTabId();
		this.addFractionDIvId = 'webeditor_fraction_' +SharedService.getTabId();
		this.addSpecialCharDivId = 'webeditor_special_char_' +SharedService.getTabId();
		
		this.addDummyLCS(this.iceLearningCheckList);
		this.addDummyIceGames(this.iceGameList);
		this.rowSelection = "multiple";
		this.userId = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER)).userId;

	 }
	 
	 ngOnInit() {
		this.speCharList = [
			{ data: { symbol: this.getSymbolFromDecimal(169), decVal: 169, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(8482), decVal: 8482, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(174), decVal: 174, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(176), decVal: 176, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(163), decVal: 163, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(8364), decVal: 8364, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(10016), decVal: 10016, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(9746), decVal: 9746, type: "speChar" } },
			// There was an issue in LC related to this special character. Therefore it was removed from all the Ck Editors in ICE Course
			// { data: { symbol: this.getSymbolFromDecimal(9744), decVal: 9744, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(167), decVal: 167, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(937), decVal: 937, type: "speChar" } }
		];
		//set title from user entered value
		if (this.data) {
					if(this.data.iceTopicContent != null && this.data.iceTopicContent != undefined){
						this.iceTopicContent = this.data.iceTopicContent;
						this.iceTopic = this.data.iceTopic;
						this.iceTopicContent.topicId = this.data.iceTopic.id;
						this.iceTopicContent.courseId = this.data.iceTopic.courseId;
						this.sharedService.setOpenedICEModules(SharedService.getTabId(), 'ICETopicContent', this.iceTopicContent);
						if (this.iceTopicContent.id == '0'){			
								//this.addDummyTopicContents(this.iceTopicContentList);
						}
						else{
							//show loading message
							var loadingMessage;
							this.translate.get('ICE_TOPIC_CONTENT.LOADING_MESSAGE').subscribe((res: string) => {
								loadingMessage = res;
							});
							this.loadingService.showLoading(true, null, loadingMessage, null);
							//get this topic content details
                            let token = UserVariable.getUserToken();
                            if (!this.commonUtil.validateToken(token)) {
                                return;
                            }
							this.courseService.getICETopicContent(this.iceTopicContent.id, token, this);
							this.courseService.getAllActiveICECourses(this.iceTopicContent.id, UserVariable.getUserToken(), this);
						}	
			}
			else {
			// data.iceTopic is null or undefined
			}
			this.isViewOnly = this.data.viewOnly;
			this.breadCrumbList = this.sharedService.getICEBreadCrumb(this.data.iceTopic.courseId);
		} else {
			//no data
		}
		//set topic content enabled true
		this.topicContentEnabled = true;
		this.sharedService.setModifiedStatus(this.tabId,  this.topicContentModified)
		let self = this;
		let commonVariables = this.sharedService.getCommonICEModuleVariableNames('topicContent');
		var commonVariableValues: Array<any> = [];
		commonVariables.forEach(function (varName) {
			commonVariableValues[varName] = self[varName];
		}); 
		this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'topicContent', commonVariableValues);
	}

	ngAfterViewInit() {
		let e1 = this.elementRef.nativeElement.querySelector('.ckeditor_topic_content_description' + this.tabId);
		
		if (this.isViewOnly == true) {
			e1 .style.backgroundColor = "#e9ecef";
		} else {
			this.setTopicContentEditor(e1);
		}
	}

	ngAfterContentInit() {

		//unsubscribe
		if (SharedService.getIceTopicContentSubscriptions() != null) {
			SharedService.getIceTopicContentSubscriptions().unsubscribe();
		}
		if (SharedService.getICEThreeDModelSubscriptions() != null) {
			SharedService.getICEThreeDModelSubscriptions().unsubscribe();
		}

		this.sharedService.setICEModuleChanges.subscribe(prevTab => {
			//get opened modules
			let openedICEModules = this.sharedService.getOpenedICEModules();
			//check if tab type is ICELearningCheck
			if (openedICEModules[prevTab] != undefined) {
				// get tab type
				var type = openedICEModules[prevTab]['type'];
				if (type == "ICETopicContent") {
					this.sharedService.setOpenedICEModules(prevTab, 'ICETopicContent', this.iceTopicContent);
				} else {
					//differnt type
				}
			} else {
				//do nothing
			}
		});

		this.sharedService.setICEModuleVariables.subscribe(prevTab => {
			if(this.tabId == prevTab) {
				let self = this;
				let commonVariables = this.sharedService.getCommonICEModuleVariableNames('topicContent');
				var commonVariableValues: Array<any> = [];
				commonVariables.forEach(function (varName) {
					commonVariableValues[varName] = self[varName];
				}); 
				this.sharedService.setCommonICEModuleVariableValues(prevTab, 'topicContent', commonVariableValues);
			}
		});

		this.sharedService.setOpenedICEModule.subscribe(newTab => {
			let openedICEModules = this.sharedService.getOpenedICEModules();
			if(openedICEModules[newTab] != undefined) {
				this.iceTopicContent = openedICEModules[newTab]['module'];
			}
		});

		this.sharedService.setOpenedICEVariables.subscribe(newTab => {
			if (this.tabId == newTab) {
				let commonVariables = this.sharedService.getCommonICEModuleVariableNames('topicContent');
				var commonVariableValues: Array<any> = [];
				commonVariableValues = this.sharedService.getCommonICEModuleVariableValues(newTab);
				console.log(commonVariableValues);
				if(commonVariableValues != undefined) {
					let self = this;
					commonVariables.forEach(function (varName) {
						self[varName] = commonVariableValues[varName];
					}); 
				}
			}
		});

		//Called after ngOnInit when the component's or directive's content has been initialized.`
		//Add 'implements AfterContentInit' to the class.
		this.insertSubscription = SharedService.insertICECourseExecute().subscribe(option => {
			//get opened modules
			let openedICEModules = this.sharedService.getOpenedICEModules();
			//get current tab
			var currentTab = SharedService.getTabId();

			//check if current tab type is ICEGame-Memory
			if (openedICEModules[currentTab] != undefined) {
				// get tab type
				var type = openedICEModules[currentTab]['type'];
				if (type == "ICETopicContent") {
					let commonVariableValues = this.sharedService.getCommonICEModuleVariableValues(SharedService.getTabId());
					this.iceCKEditor.insertTagEditArea(option, commonVariableValues.elementRef, commonVariableValues.editor, CKEDITOR, this.iceTopicContent, "ICETopicContent");
				} else {
					//differnt type
				}
			} else {
				//do nothing
			}
		});

		// this.insertSubscription = SharedService.insertICECourseExecute().subscribe(option => this.iceCKEditor.insertTagEditArea(option, this.elementRef,editor,CKEDITOR, this.iceTopicContent, "ICETopicContent"));
		this.insertThree3DModelSubscription = SharedService.insertICECourseExecute().subscribe(option => this.setThreeDModelInfo(option));
		this.sharedService.imageStatusRespond().subscribe(imageStatus => this.setImageStatus(imageStatus));
		SharedService.setIceTopicContentSubscriptions(this.insertSubscription);
		SharedService.setICEThreeDModelSubscriptions(this.insertThree3DModelSubscription);

		var self = this;
		this.columnDefs2 = [  
			{ 
				headerName: "Hidden Layers",
				field: "hiddenLayer", 
				width: 50, 
				filter: true,
				editable:true,
				cellStyle: this.isViewOnly ?  {'pointer-events': 'none'}: '',
				cellRenderer: function(params) {
					var input1 = document.createElement('input');
					input1.type="checkbox";
					if(self.selectedHiddenlayers != null){
						for (var i = 0; i <= self.selectedHiddenlayers.length - 1; i++) {
							if(params.node.data.layerHierarchy == self.selectedHiddenlayers[i]){
								input1.checked = true;
								params.node.data.hiddenLayer = true;
								params.value = true;
							}
						}
							
					}
					else{
						input1.checked = false;
						params.value = false;
					}
					input1.addEventListener('click', function (event) {
						params.value=!params.value;
						params.node.data.hiddenLayer = params.value;
						if(params.node.data.hiddenLayer == true){
							self.getSelectedHiddenLayers(params.node.data.layerHierarchy, true, false);							
						}	
						else if(params.node.data.hiddenLayer == false){
							self.getSelectedHiddenLayers(params.node.data.layerHierarchy, false, false);
						}

						//set topic content modified true
						self.topicContentModified	= true;
						self.sharedService.setModifiedStatus(self.tabId,  self.topicContentModified);					
					});
					return input1;						
				}
			}, 				
			{ 
				headerName: "Blink Layers",
				field: "blinkLayer",
				width: 50, 
				filter: true,
				editable:true,
				cellStyle: this.isViewOnly ?  {'pointer-events': 'none'}: '',
				cellRenderer: function(params) { 
					var input2 = document.createElement('input');
					input2.type="checkbox";
					if(self.selectedBlinklayers != null){
						for (var i = 0; i <= self.selectedBlinklayers.length - 1; i++) {
							if(params.node.data.layerHierarchy == self.selectedBlinklayers[i]){
								input2.checked = true;
								params.node.data.blinkLayer = true;
								params.value = true;
							}
						}
						
					}
					else{
						input2.checked=false;
						params.value = false
					}
					input2.addEventListener('click', function (event) {
						params.value=!params.value;
						params.node.data.blinkLayer = params.value;
						if(params.node.data.blinkLayer == true){
							self.getSelectedBlinkLayers(params.node.data.layerHierarchy, true, false);
						}	
						else if(params.node.data.blinkLayer == false){
							self.getSelectedBlinkLayers(params.node.data.layerHierarchy, false, false);
						}
						
						//set topic content modified true
						self.topicContentModified	= true;
						self.sharedService.setModifiedStatus(self.tabId,  self.topicContentModified);
					});
					return input2;					
				}
			}
			];
			//this.groupDefaultExpanded = -1;
			this.getDataPath = function(data) {
			return data.layerHierarchy;
			};
			this.autoGroupColumnDef = {
			headerName: "Layer Hierarchy",
			sort: "asc",
			filter: "agTextColumnFilter",
			cellRendererParams: { suppressCount: true },
			};


		this.gridOptions = {
            rowData: this.rowData2,
            columnDefs: this.columnDefs2,
			defaultColDef: {
                resizable: true,
				sortable: true,
				floatingFilter: true,
			},
            rowSelection: "multiple",
			rowMultiSelectWithClick: false,
			rowBuffer: 20,
			suppressHorizontalScroll: true,
		};

		this.sideBar = {
			toolPanels: [
				{
				  id: 'columns',
				  labelDefault: 'Columns',
				  labelKey: 'columns',
				  iconKey: 'columns',
				  toolPanel: 'agColumnsToolPanel',
				  toolPanelParams: {
					suppressRowGroups: true,
					suppressPivotMode: true,
				  },
				},
			]
		}
		
		let hiddenLayers = [];
		let blinkLayers = [];
		
		this.blinkLayers = blinkLayers;
		this.hiddenLayers = hiddenLayers;
		
	}

	public getSelectedHiddenLayers(data, value, init){
		let formattedData
		if(init == false){
			formattedData = this.encodeSpecialChars(data[0]);
			for (var i = 1; i <= data.length - 1; i++) {
				let subLayer = this.encodeSpecialChars(data[i]);
				formattedData = formattedData + "\\\\" + subLayer;
			}
		} else{
			formattedData = data.split(/\\/)[0];
			for (var i = 1; i <= data.split(/\\/).length - 1; i++) {
				formattedData = formattedData + "\\\\" + data.split(/\\/)[i];
			}
		}
		if(value == true){			
			this.hiddenLayers.push(formattedData);
			if(init == true){
				data = data.replace(/\\/g, ",")
				data = this.decodeSpecialChars(data);
				this.selectedHiddenlayers.push(data.toString());
			} else{
				this.selectedHiddenlayers.push(data);
			}			
		}	
		else if(value == false){
			for (var i = 0; i <= this.hiddenLayers.length - 1; i++) {
				if(this.hiddenLayers[i] == formattedData){
					this.hiddenLayers.splice(i, 1);
				}				
			}
			for (var i = 0; i <= this.selectedHiddenlayers.length - 1; i++) {
				if(this.selectedHiddenlayers[i] == data){				
					this.selectedHiddenlayers.splice(i, 1);
				}				
			}			
		}	
		return this.hiddenLayers, this.selectedHiddenlayers;
	}
	
	
	public getSelectedBlinkLayers(data, value, init){
		let formattedData
		if(init == false){
			formattedData = this.encodeSpecialChars(data[0]);
			for (var i = 1; i <= data.length - 1; i++) {
				let subLayer = this.encodeSpecialChars(data[i]);
				formattedData = formattedData + "\\\\" + subLayer;
			}
		} else{
			formattedData = data.split(/\\/)[0];
			for (var i = 1; i <= data.split(/\\/).length - 1; i++) {
				formattedData = formattedData + "\\\\" + data.split(/\\/)[i];
			}
		}
		if(value == true){			
			this.blinkLayers.push(formattedData);
			if(init == true){
				data = data.replace(/\\/g, ",")
				data = this.decodeSpecialChars(data);
				this.selectedBlinklayers.push(data.toString());
			} else{
				this.selectedBlinklayers.push(data);
			}

		}	
		else if(value == false){
			for (var i = 0; i <= this.blinkLayers.length - 1; i++) {
				if(this.blinkLayers[i] == formattedData){
					this.blinkLayers.splice(i, 1);
				}				
			}
			for (var i = 0; i <= this.selectedBlinklayers.length - 1; i++) {
				if(this.selectedBlinklayers[i] == data){
					this.selectedBlinklayers.splice(i, 1);
				}				
			}
		}	
		return this.blinkLayers, this.selectedBlinklayers;
	}

	private encodeSpecialChars(content: String){
		if(content != null){
			content = content.replace(/&/g, "&amp;");
			content = content.replace(/\\/g, "&bsol;");
			content = content.replace(/\//g, "&sol;");
			content = content.replace(/\"/g, "&quot;");
			content = content.replace(/\'/g, "&apos;");
			content = content.replace(/</g, "&lt;");
			content = content.replace(/>/g, "&gt;");
		}
		return content;
	}

	private decodeSpecialChars(content: String){
		if(content != null){
			content = content.replace(/&bsol;/g, "\\");
			content = content.replace(/&sol;/g, "\/");
			content = content.replace(/&quot;/g, "\"");
			content = content.replace(/&apos;/g, "\'");
			content = content.replace(/&lt;/g, "<");
			content = content.replace(/&gt;/g, ">");
			content = content.replace(/&amp;/g, "&");
		}
		return content;
	}
	private onWindowResize = () => {
   
		setTimeout(() => {
			if (this.gridApi) {
				this.gridApi.sizeColumnsToFit();
			}
		}); 
	};

	public onGridReady2(params) {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		params.api.sizeColumnsToFit();
        // window.addEventListener("resize", function () {
        //     setTimeout(function () {
		// 		params.api.sizeColumnsToFit();
        //     });
		// });	
		window.addEventListener("resize", this.onWindowResize);	
	}

	setThreeDModelInfo(option: any) {
		if (option != null && option.data.type == "modelAsFigure") {
			if(this.iceTopicContent.topicContentThreeDModel != null){
				this.viewTypes = [];
				if(option.data.layerContent != null){
					this.rowData2 = JSON.parse(option.data.layerContent);
				} else{
					this.rowData2 = [];
				}
				if(this.iceTopicContent.topicContentThreeDModel.viewContent != null){
					this.viewContent = JSON.parse(this.iceTopicContent.topicContentThreeDModel.viewContent)			
					for (var i = 0; i <= this.viewContent.length - 1; i++) {
						this.viewTypes.push({ 'id': i+1, 'text': this.viewContent[i]['Name'] });
					}
					this.translate.get('ICE_TOPIC_CONTENT.VIEW_INFO').subscribe((res: string) => {
						this.placeholder = res;
					});
				} else {
					this.translate.get('ICE_TOPIC_CONTENT.NO_VIEW_INFO').subscribe((res: string) => {
						this.placeholder = res;
					});
				}	
				
				//enable/disable the "Import Saved Model Data" button
				this.enableImportSaveModelDataButton(this.iceTopicContent.topicContentThreeDModel.id);
			
				this.hiddenLayers = [];
				this.blinkLayers = [];
				this.selectedHiddenlayers = [];
				this.selectedBlinklayers = [];
				this.selectedViewType = [];
				this.selectedViewInfo = null;
				this.selectedViewName = null;
			}
		}
	} 
	
	openModalCC(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template, { class: 'modal-lg', backdrop: 'static', keyboard: false });
	}
	
	onGridReady(params) {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		params.api.sizeColumnsToFit();
        // window.addEventListener("resize", function () {
        //     setTimeout(function () {
        //         params.api.sizeColumnsToFit();
        //     });
		// });
		window.addEventListener("resize", this.onWindowResize);
	}	

	setImageStatus(type){
		if(type.status =="ICETopicContent" && type.tabId == this.tabId){
			this.topicContentModified	= true;
			this.sharedService.setModifiedStatus(this.tabId,  this.topicContentModified)
		}
	}

    public backtoTopic() {
		let data = {
			courseId        : this.iceTopic.courseId, 
      		iceTopicContent : this.iceTopicContent	,
			iceTopic        :	this.iceTopic,
			viewOnly        : this.isViewOnly
		}
		this.sharedService.changeIceItemRequest('topic', data)
	}

	public clickBreadCrumb(data:any,index:number){
		let self = this;
		if (self.topicContentModified == true) {
			self.alertService.warn(self.translate.instant('ICE_TOPIC_CONTENT.TOPIC_CONTENT_SAVE'));
		} else {
			if(data != null){
				self.sharedService.changeIceItemRequest(data.iceType, data);
				self.breadCrumbList.length = index;
			} else{
			//nothing to do
			}
		}
	  }

	showIceTopicContentDetails(isShowIceTopicContentDetails) {
		let self = this;
		if (isShowIceTopicContentDetails == true) {
			if(self.topicContentModified == true) {
				self.alertService.warn(self.translate.instant('ICE_TOPIC_CONTENT.TOPIC_CONTENT_SAVE'));
			} else {
				self.isShowIceTopicContentDetails = false;
			}
		} else {
			self.isShowIceTopicContentDetails = true;
			setTimeout(() => {
				let e1  = this.elementRef.nativeElement.querySelector('.ckeditor_topic_content_description' + self.tabId);
				e1.innerHTML = this.iceTopicContent.description;
				this.setTopicContentEditor(e1);
			}, 200);
		}
	}

	setTopicContentEditor(e1) {
		let self = this;
		$('.toggle-edit' + SharedService.getTabId() + this.userId).on('copy', function() {
			if ($(this)[0].attributes['contenteditable'].nodeValue == "false") {
				localStorage.setItem('disabledEditor', "true"); 
			}
		});	
		$('.desc_ck_editor' + this.tabId).dblclick(function (e) {
			if (e.target.nodeName != "PH" || (self.desceditor == null) || (self.desceditor == undefined)) {
				if ($(this)[0].attributes['contenteditable'].nodeValue == "false") {
				self.topicContentModified = true;
				self.sharedService.setModifiedStatus(self.tabId,  self.topicContentModified)
				if (self.desceditor) {
					self.desceditor.destroy();
				} else {
					//no editor
				}
				self.desceditor = CKEDITOR.inline(e1 , self.inlineConfig);
				//set title editable true
				e1 .setAttribute("contenteditable", "true");
				self.setEditorFunctions(self.desceditor);
			}
			} else {
				if ($(this)[0].attributes['contenteditable'].nodeValue == "true") {
					SharedService.setselectedNode(e.target);
                    let item = e.target.attributes['restriction'].nodeValue;
                    let catId = e.target.attributes['catId']
                    if (e.target.attributes['catId'] != undefined && e.target.attributes['catId'] != null) {
                        catId = e.target.attributes['catId'].nodeValue;
                    }                       
                    self.iceCKEditor.getRestrictionValueOndblClick(item,catId);
                }
				self.setEditorFunctions(self.desceditor);
			}
		});
	}

	setEditorFunctions(editor) {
		let self = this;
		if (editor) {
			editor.on('instanceReady', function (evt) {
				var range = editor.createRange();
				if (range != null) {
				range.moveToElementEditEnd(range.root);
				editor.getSelection().selectRanges([range]);
				}
			});

			editor.on('key', function (e) {
				self.iceCKEditor.editorKeyDown(e,editor);
			});
      
			editor.on('focus', function (evt) {
				if(navigator.userAgent.toLowerCase().indexOf("firefox") > -1){
					let wintitles = editor.container.getElementsByTag("wintitle");
					for(let i=0; i<wintitles.$.length; i++){
						wintitles.$[i].removeAttribute("contenteditable");
					}
				}
			});

			editor.on('paste', function (evt) {
				evt.stop();
				var data: string = evt.data.dataValue;
				let pasteData: string = self.iceCKEditor.processPasteData(data);
				if(self.iceCKEditor.gethasResuables()) {
					self.alertService.clear();
					self.alertService.toast(self.translate.instant('ICE_COURSE.DISABLED_EDITOR_COPY'));
				}
				evt.editor.insertHtml(pasteData);
			});
			this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'topicContent', editor, 'editor');
		} else {
			//no editor
		}
	}
	
	private getSymbolFromDecimal(value: number): string {
		if ((33 <= value) &&  (value < 65536)) {
            return String.fromCharCode(value);
        } else {
            this.alertService.error("Please enter valid Unicode character entity reference. Range is between 33 to 65535", true);
        }
	}

	descriptionClick() {
		//set editor
		var self = this;
		$('.ckeditor_topic_content_description' + SharedService.getTabId()).dblclick(function (e) {
		  editor = self.desceditor;
		  self.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'topicContent', editor, 'editor');
		  })
	}

	editTopicContent() {
		this.isShowIceTopicContentDetails = true;
		this.topicContentEnabled = true;
		setTimeout(() => {
			let e1  = this.elementRef.nativeElement.querySelector('.ckeditor_topic_content_description' + this.tabId);
			e1.innerHTML = this.iceTopicContent.description;
			this.setTopicContentEditor(e1);
		}, 200);
	}

	private getEditorContent(editorName: string): string {
		let el = this.elementRef.nativeElement.querySelector(editorName);
		return el.innerHTML;
	}

	public selectedView(value: any): void {
		if(value.text != null){
			for (var i = 0; i <= this.viewContent.length - 1; i++) {
				if(this.viewContent[i]['Name'] == value.text){
					this.selectedViewInfo = this.viewContent[i]["ViewDetails"];
					this.selectedViewName = this.viewContent[i]["Name"];
					this.enableCopyModelInfoButton(this.selectedViewName); //enable the button when model view is selected

					//set topic content modified true
					this.topicContentModified	= true;
					this.sharedService.setModifiedStatus(this.tabId,  this.topicContentModified);	
				}
			}
		}
	}
	
  // Main Topic
  public topicContentDetailSave() {
		let self = this;
		this.xmlContent ="";
		let blinkLayers = ""
		let hiddenLayers = ""
		if(this.blinkLayers != null){
			blinkLayers = this.blinkLayers[0]
			for (var i = 1; i <= this.blinkLayers.length - 1; i++) {
				blinkLayers = blinkLayers + "##ICELAYERSPLIT##" + this.blinkLayers[i]
			}
		}
		if(this.hiddenLayers != null){
			hiddenLayers = this.hiddenLayers[0]
			for (var i = 1; i <= this.hiddenLayers.length - 1; i++) {
				hiddenLayers = hiddenLayers + "##ICELAYERSPLIT##" + this.hiddenLayers[i]
			}
		}
		if(this.selectedViewName != null){
			this.selectedViewName = this.encodeSpecialChars(this.selectedViewName);
		}
		
		console.log('[topicContentDetailSave]');
    	//get ice topic description ck editor content
    	let content = this.getEditorContent('.ckeditor_topic_content_description' + self.tabId);
    	//process content
		//this.processXML(content, false);
		this.iceCKEditor.processXML(content,false,this.xmlContent);
		this.xmlContent = this.iceCKEditor.getXMLContent();
		//set topic description
		this.iceTopicContent.description = this.xmlContent;
    		//validate form
		if (!this.validateForm()) {
			return;
		} else {

			//show loading message
			var loadingMessage;
			this.translate.get('ICE_TOPIC_CONTENT.SAVING_MESSAGE').subscribe((res: string) => {
				loadingMessage = res;
			});
			this.loadingService.showLoading(true, null, loadingMessage, null);

			let selectedFolderData = this.folderService.getSelectedFoldeData();
			let videoId = this.iceTopicContent.topicContentVideo != null ? this.iceTopicContent.topicContentVideo.id : null;
			let modelId = this.iceTopicContent.topicContentThreeDModel != null ? this.iceTopicContent.topicContentThreeDModel.id : null;

			//if empty set as null
			if (!videoId) {
				videoId = null;
			} else {
				//do nothing
			}

			if (!modelId) {
				modelId = null;
			} else {
				//do nothing
			}
			
			//this.data.iceTopic.id
			//request
			var request = {
				"id":this.iceTopicContent.id,
				"topicContentName":this.iceTopicContent.topicContentName,	
				"topicId": this.iceTopicContent.topicId,
				"courseId": this.iceTopicContent.courseId,	
				"description":"<ice>" + this.iceTopicContent.description + "</ice>",
				"graphicId":this.iceTopicContent.topicContentImage != null ? this.iceTopicContent.topicContentImage.id : null,
				"videoId": videoId,
				"modelId": modelId,		
				"sequence": this.iceTopicContent.sequence,
				"modelView": this.selectedViewInfo,
				"blinkLayerState": blinkLayers,
				"layerState": hiddenLayers,
				"courseVersion": this.iceTopicContent.courseVersion,
				"linkedCourseId": this.iceTopicContent.linkedCourseId,
				"modelViewName": this.selectedViewName
			};
			if (request.id != "0") {
				//update topic content
                let token = UserVariable.getUserToken();
                if (!this.commonUtil.validateToken(token)) {
                    return;
                }
				this.courseService.updateICETopicContent(JSON.stringify(request), token, this);
			} else {
				//add new topic content
                let token = UserVariable.getUserToken();
                if (!this.commonUtil.validateToken(token)) {
                    return;
                }
				this.courseService.createICETopicContent(JSON.stringify(request), token, this);
			}
			this.topicContentModified = false;
			this.sharedService.setModifiedStatus(this.tabId,  this.topicContentModified)
		} 
  }

  	/**
	 * validate form
	 */
	validateForm(): boolean {
		let self = this;
		var editortext_desc = this.replaceWhiteSpace($(".ckeditor_topic_content_description" + self.tabId).text());
		var length_desc = editortext_desc.length;
		/*if (this.iceTopicContent.topicContentName == undefined || this.iceTopicContent.topicContentName == "") {
			return false;
		}*/ 
		if (length_desc < 1) {
			this.alertService.error(this.translate.instant('ICE_TOPIC_CONTENT.TOPIC_CONTENT.DESCRIPTION_ERROR'));
			return false;		
		}
		if(this.iceTopicContent.topicContentThreeDModel != null){
			if (this.selectedViewInfo == null || this.selectedViewName == null) {
				this.alertService.error(this.translate.instant('ICE_TOPIC_CONTENT.TOPIC_CONTENT.MODEL_VIEW_ERROR'));
				return false;		
			}
		}
		
		// else if (this.iceTopicContent.topicContentImage == null) {
		// 	return false;
		// }
		return true; 
	}

	isNullOrWhitespace(input) {
		return !/\S/.test(input);
	  }
	replaceWhiteSpace(str) {
		return str.replace(/[\s\u200B]/g,'');
	}
	
	/**
	 * course image radio button click events
	 */
	addTopicContentImage() {
		this.iceTopicContent.selectedType = "Image";
		this.changeToImagePanel();
	}

	addTopicContentVideo() {
		this.iceTopicContent.selectedType = "Video";
		this.changeToVideoPanel();
	}

	addTopicContentThreeDModel() {
		this.iceTopicContent.selectedType = "ThreeDModel";
		this.changeToThreeDModelPanel();
	}

  	openModal(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template);
		}

	/**
	 * Topic image remove button click event
	 */
	removeTopicContent() {
		if(this.iceTopicContent.topicContentImage != null){
			this.iceTopicContent.topicContentImage = null;
		} else if (this.iceTopicContent.topicContentVideo != null){
			this.iceTopicContent.topicContentVideo = null;
		} else if (this.iceTopicContent.topicContentThreeDModel != null){
			this.iceTopicContent.topicContentThreeDModel = null;
		} else{
			//do nothing
		}

		//reset seleted type
		this.iceTopicContent.selectedType = "";

		this.topicContentModified = true;
		this.sharedService.setModifiedStatus(this.tabId, this.topicContentModified)
	}
	  
	checkedCondition(){
		if(this.iceTopicContent.topicContentImage != null){
			this.addTopicContentImage();
			this.iceTopicContent.topicContentImage = null;
			this.isImageChecked = true;
		} else if (this.iceTopicContent.topicContentVideo != null){
			this.addTopicContentVideo();
			this.iceTopicContent.topicContentVideo = null;
			this.isVideoChecked = true;
		} else if (this.iceTopicContent.topicContentThreeDModel != null){
			this.addTopicContentThreeDModel();
			this.iceTopicContent.topicContentThreeDModel = null;
			this.isThreeDModelChecked = true;
		} else{
			
		}	
	}

		/**
	 * show icetopiccontent image/icon from selected item of image search
	 */
	displayImage(option) {
		if (this.iceTopicContent.selectedType == "Image") {
			//set course image
			this.iceTopicContent.topicContentImage = new ProcImage(option.data.id, option.data.thumbnailImage, option.data.title);
		} else if (this.iceTopicContent.selectedType == "Video"){
			//set course Video
			this.iceTopicContent.topicContentVideo = new ProcVideo(option.data.id, option.data.title, option.data.selectedURL, option.data.thumbnailVideo, option.data.posterframeURL);;
		} else if (this.iceTopicContent.selectedType == "ThreeDModel"){
			//set course ThreeDModel
			this.iceTopicContent.topicContentThreeDModel = new ProcThreeDModel(option.data.id, option.data.title, option.data.selectedURL,
				option.data.layerContent, option.data.viewContent, option.data.thumbnailThreeDModel);		
		} else{
			//nothing to do

		}
	}

	showLargeImage(template: TemplateRef<any>, selectedImageId: any) {
        this.selectedImage = {
			'id': '',
			'thumbnailImage': '',
			'largeImage': '',
			'title': ''
		}
        //show loading message
        var loadingMessage = null;
        this.translate.get('ICE_COURSE.COURSE_PREVIEW_IMAGE_LOADING').subscribe((res: string) => {
            loadingMessage = res;
        });
		this.loadingService.showLoading(true, null, loadingMessage, null);
        this.courseService.getSelectedImage(selectedImageId, UserVariable.getUserToken(), this);
        this.selectedModalTemplate = template;
    }

	showLargeThreeDModel(template: TemplateRef<any>, selectedThreeDModel: any) {
		this.selectedThreeDModel.title = selectedThreeDModel.title
		this.selectedThreeDModel.selectedURL = selectedThreeDModel.selectedURL
		this.modalRef = this.modalService.show(template, this.modalConfig);
	}
	

	public modelRefCloseEvent() {
		this.modalRef.hide()
		this.selectedImage = {
			'id': '',
			'thumbnailImage': '',
			'largeImage': '',
			'title': ''
		}
		this.selectedVideo = {
			'id': '',
			'thumbnailVideo': '',
			'largeVideo': '',
			'title': '',
			'selectedURL': '',
			'posterframeURL':''
		};
		this.selectedThreeDModel = {
			'id': '',
			'thumbnailThreeDModel': '',
			'largeThreeDModel': '',
			'title': '',
			'selectedURL': '',
			'layerContent': '',
			'viewContent': ''
		};
	}

	/**
	 * open image search tab
	 */
	private changeToImagePanel() {
		SharedService.openImageReusablepanel('images');
	}
	/**
	 * open Video search tab
	 */
	private changeToVideoPanel() {
		SharedService.openVideoReusablepanel('video');
	}
	/**
	 * open ThreeDModel search tab
	 */
	private changeToThreeDModelPanel() {
		SharedService.openThreeDModelReusablepanel('threed-model');
	}

  // Topic Content
  public addNewIceTopicContent() {
    console.log('[addNewIceTopicContent]');
  }

  public addNewIceTopicContentBullet() {
    console.log('[addNewIceTopicContentBulletBullet]');
  }
/*
  public showInfoIceTopic(iceTopic: IceTopic){
    console.log('[showInfoIceTopic]');
  }

  public deleteIceTopic(iceTopic: IceTopic){
    console.log('[deleteIceTopic]');
  }

  public changePositionIceTopic(iceTopic: IceTopic, dir: string){
    console.log('[changePositionIceTopic] (iceTopic/dir) ' + JSON.stringify(iceTopic) + '/' + dir);
  }
  
  public copyOfIceTopic(iceTopic){
    console.log('[copyOfIceTopic] ' + JSON.stringify(iceTopic));
  }

  // Topic Bullet
  public addNewIceTopicBullet() {
    console.log('[addNewIceTopicBulletBullet]');
  }

  public showInfoIceTopicBullet(iceTopicBullet: IceTopicBullet){
    console.log('[showInfoIceTopicBullet]');
  }

  public deleteIceTopicBullet(iceTopicBullet: IceTopicBullet){
    console.log('[deleteIceTopicBullet]');
  }

  public changePositionIceTopicBullet(iceTopicBullet: IceTopicBullet, dir: string){
    console.log('[changePositionIceTopicBullet] (iceTopicBullet/dir) ' + JSON.stringify(iceTopicBullet) + '/' + dir);
  }
  
  public copyOfIceTopicBullet(iceTopicBullet: IceTopicBullet){
    console.log('[copyOfIceTopicBullet] ' + JSON.stringify(iceTopicBullet));
  }
*/
  // Learning Check
  public addNewLearningCheck() {
    console.log('[addNewLearningCheck]');
		let random = Math.floor(Math.random() * (999999 - 100000)) + 100000;
		let listLength: number = this.iceLearningCheckList.length
		let newlc: IceLearningCheck = {
			id: JSON.stringify(random), description: '', orderNumber: (listLength + 1)
		}
		this.iceLearningCheckList.push(newlc)
  }

  // ICE Game
  public addNewIceGame(gameType: string){
    console.log('[addNewIceGame] (gameType) ' + gameType);
		let random = Math.floor(Math.random() * (999999 - 100000)) + 100000;
		let newGame: IceGame = {
			id: JSON.stringify(random), description: '', gameType: gameType
		};
		this.iceGameList.push(newGame);
  }

  public getStringFromType(gameType: string): string {
		switch (gameType) {
			case 'EIG':
				return "Einstein (Instructor)"
			case 'SMSG':
				return "Speed Match (Student)"
			case 'MG':
				return "Memory (Instructor)"
			case 'WAMG':
				return "Whack a Mole (Instructor)"
			default:
				return ""
		}
	}

  // Methods for adding dummy details
  /*private addDummyTopicContents(iceTopicContentist: any) {
		let topic_1: IceTopicContent = {
			id: '001', topicContentName: 'Sample ICE Topic Content - 1',
			orderNumber: 1, copyTopicContentId: '0023'
		}
		iceTopicContentist.push(topic_1)
		let topic_2: IceTopicContent = {
			id: '002', topicContentName: 'Sample ICE Topic Content- 2',
			orderNumber: 2, copyTopicContentId: ''
		}
		iceTopicContentist.push(topic_2)
  }*/
  
  private addDummyLCS(learningCheckList: any) {
		let lc_1: IceLearningCheck = {
			id: '001', description: 'Sample Learning Check - 1',
			orderNumber: 1, questionLength: 37
		}
		learningCheckList.push(lc_1)
		let lc_2: IceLearningCheck = {
			id: '002', description: 'Sample Learning Check - 2',
			orderNumber: 2, questionLength: 6
		}
		learningCheckList.push(lc_2)
	}

	private addDummyIceGames(iceGameList: any) {
		let iceGame_1: IceGame = {
			id: '001', description: 'Sample Game - 1', gameType: 'EIG'
		}
		iceGameList.push(iceGame_1)
		let iceGame_2: IceGame = {
			id: '002', description: 'Sample Game - 2', gameType: 'SMSG'
		}
		iceGameList.push(iceGame_2)
	}

	onSuccess(data: WsResponse, serviceType: WsType) {
		console.log(data.payload);
		console.log("serviceType"+serviceType);
		if (serviceType == WsType.CREATE_ICE_TOPIC_CONTENT) {
			this.iceTopicContent.id = data.payload;
      var alert = null;
			this.translate.get('ICE_TOPIC_CONTENT.TOPIC_CONTENT_SAVED_ALERT').subscribe((res: string) => {
				alert = res;
			});
			this.alert.success(alert);
			this.loadingService.hideLoading();
      console.log("=====Topic content is succesfully created=====");
		} else if (serviceType == WsType.UPDATE_ICE_TOPIC_CONTENT){
      var alert = null;
			this.translate.get('ICE_TOPIC_CONTENT.TOPIC_CONTENT_UPDATED_ALERT').subscribe((res: string) => {
				alert = res;
			});
			this.alert.success(alert);
			this.courseService.getICETopicContent(this.iceTopicContent.id, UserVariable.getUserToken(), this);
			this.loadingService.hideLoading();
      console.log("=====Topic content is succesfully updated=====");
    } else if (serviceType == WsType.GET_ICE_TOPIC_CONTENT) {
			let self = this;
			this.iceTopicContent.description = data.payload.description;
			this.iceTopicContent.descriptionHTML = data.payload.descriptionHTML;
			this.iceTopicContent.description = this.iceTopicContent.description.replace(/(<wintitle)([\s]+([^>]+)\/>)/g,
					"<wintitle contenteditable='true'$2</wintitle>");
			this.iceTopicContent.descriptionHTML = this.iceTopicContent.descriptionHTML.replace(/(<wintitle)([\s]+([^>]+)\/>)/g,
					"<wintitle contenteditable='true'$2</wintitle>");
			if(data.payload.topicContentImage != null){
				this.iceTopicContent.topicContentImage = data.payload.topicContentImage;
			} else if (data.payload.topicContentVideo != null){
				this.iceTopicContent.topicContentVideo = data.payload.topicContentVideo;
				this.iceTopicContent.topicContentVideo.selectedURL = data.payload.topicContentVideo.url;
			} else if(data.payload.topicContentThreeDModel != null){
				//this.addTopicContentThreeDModel();
				this.selectedViewType = [];
				this.selectedHiddenlayers = [];
				this.selectedBlinklayers = [];
				this.hiddenLayers = [];
				this.blinkLayers = [];
				this.iceTopicContent.topicContentThreeDModel = data.payload.topicContentThreeDModel;
				this.viewContent = JSON.parse(this.iceTopicContent.topicContentThreeDModel.viewContent);
				for (var i = 0; i <= this.viewContent.length - 1; i++) {
					if(data.payload.modelViewName == null) {
						if(this.viewContent[i]['ViewDetails'] == data.payload.modelView){
							this.selectedViewType.push({ 'id': i+1, 'text': this.viewContent[i]['Name'] });
							this.selectedViewInfo = this.viewContent[i]["ViewDetails"]
							this.selectedViewName = this.decodeSpecialChars(this.viewContent[i]["Name"]);
						}
					} else {
						if(this.viewContent[i]['Name'] == this.decodeSpecialChars(data.payload.modelViewName)){
							this.selectedViewType.push({ 'id': i+1, 'text': this.viewContent[i]['Name'] });
							this.selectedViewInfo = this.viewContent[i]["ViewDetails"]
							this.selectedViewName =this.decodeSpecialChars(this.viewContent[i]["Name"]);
						}
					}
					this.viewTypes.push({ 'id': i+1, 'text': this.viewContent[i]['Name'] });
				}
				if(data.payload.layerState != null){
					let hidden = data.payload.layerState.split("##ICELAYERSPLIT##");
					for (var i = 0; i <= hidden.length - 1; i++) {
						//this.selectedHiddenlayers.push(hidden[i].replace(/\\/g, ",").toString());
						this.getSelectedHiddenLayers(hidden[i], true, true);
					}
				}
				if(data.payload.blinkLayerState != null){
					let blink = data.payload.blinkLayerState.split("##ICELAYERSPLIT##");
					for (var i = 0; i <= blink.length - 1; i++) {
						//this.selectedBlinklayers.push(blink[i].replace(/\\/g, ",").toString());
						this.getSelectedBlinkLayers(blink[i], true, true);
					}
				} 
				this.iceTopicContent.topicContentThreeDModel.selectedURL = data.payload.topicContentThreeDModel.url;
				this.rowData2 = JSON.parse(this.iceTopicContent.topicContentThreeDModel.layerContent);
				//enable/disable the "Import Saved Model Data" button
				this.enableImportSaveModelDataButton(this.iceTopicContent.topicContentThreeDModel.id);
				this.enableCopyModelInfoButton(this.selectedViewName); //enable the button if the model view is selected
			}
			this.iceTopicContent.linkedCourseId = data.payload.linkedCourseId;
			var html = this.iceTopicContent.descriptionHTML;
			var div = document.createElement("div");
			div.innerHTML = html;
			this.topicContentDescriptionShowInDetails = div.textContent || div.innerText || "";
			if (this.desceditor) {
				this.desceditor.destroy();
			} else {
				//no editor
			}
			let e1  = this.elementRef.nativeElement.querySelector('.ckeditor_topic_content_description' + self.tabId);
			e1.innerHTML = this.iceTopicContent.description;
			e1.setAttribute("contenteditable", "false");
			// if(this.iceCourseList.length > 0){
			// 	this.iceCoursesAvailable = true;
			// 	if(this.iceTopicContent.linkedCourseId != undefined || this.iceTopicContent.linkedCourseId != null){
			// 	  this.selectedIceCourse = this.iceTopicContent.linkedCourseId;
			// 	  this.iceCourseList.forEach((courseItem : ICECourse) => {
			// 		if(courseItem.courseId == this.iceTopicContent.linkedCourseId){
			// 		  this.linkedCourse = courseItem;
			// 		}
			// 	  });
			// 	}
			//   }
			this.sharedService.setOpenedICEModules(SharedService.getTabId(), 'ICETopicContent', this.iceTopicContent);
			if(this.iceTopicContent.linkedCourseId != undefined || this.iceTopicContent.linkedCourseId != null){
				this.iceCoursesAvailable = true;
				this.selectedIceCourse = this.iceTopicContent.linkedCourseId;
				this.linkedCourse = data.payload.linkedCourseFileName;
			}
			let commonVariables = this.sharedService.getCommonICEModuleVariableNames('topicContent');
			var commonVariableValues: Array<any> = [];
			commonVariables.forEach(function (varName) {
				commonVariableValues[varName] = self[varName];
			}); 
			this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'topicContent', commonVariableValues);
			this.loadingService.hideLoading();			  
		} else if(serviceType == WsType.GET_ALL_ACTIVE_ICE_COURSES){
			this.loadICECourseList(data.payload);
		} else if (serviceType == WsType.GET_SELECTED_IMAGE_DATA) {
            this.selectedImage = JSON.parse(data.payload);
            this.loadingService.hideLoading();
            this.modalRef = this.modalService.show(this.selectedModalTemplate, this.modalConfig);
        }
	}

	onFail(data: WsResponse, serviceType: WsType) {
		if (serviceType == WsType.CREATE_ICE_TOPIC_CONTENT) {
			this.alert.error(data.statusDescription, false);
		} else if (serviceType == WsType.UPDATE_ICE_TOPIC_CONTENT) {
			console.log("=====Failed to update topic details=====");
			if (data.statusCode == "543") {
				this.loadConflictData(data.payload)
				this.openModalCC(this.conflict_checker);
				this.loadingService.hideLoading();
			} else {
				this.alertService.error(data.statusDescription, false);
			}
    	} else if (serviceType == WsType.GET_ICE_TOPIC_CONTENT) {
			this.alert.error(data.statusDescription, false);
      		console.log("=====Failed to get topic details=====");
		} else if (serviceType == WsType.GET_SELECTED_IMAGE_DATA) {
            // do nothing
        }
		this.loadingService.hideLoading();
	}

	addSpeChar(value: string) {
		if (value != "") {
			let decVal = Number(value);
			this.speCharList.push({ data: { symbol: this.getSymbolFromDecimal(decVal), decVal: decVal, type: "speChar" } });
		}
	}

	numberOnly(event): boolean {
		const charCode = (event.which) ? event.which : event.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}

	addFraction(num: string, den: string) {
		this.modalRef.hide()
		if (den != "" && num != "") {
			let denominator = parseInt(den);
			if (denominator != 0) {
				const frac = {
					numerator: num,
					denominator: den,
					type: 'fraction'
				};
				const fracStr = JSON.stringify(frac);
				let event = JSON.parse(fracStr);
				const fracObj = {
					data: event
				};
				const fracObjStr = JSON.stringify(fracObj);
				let data = JSON.parse(fracObjStr);
				this.iceCKEditor.insertTagEditArea(data, this.elementRef,editor,CKEDITOR, this.iceTopicContent, "ICETopicContent")
			} else {
				this.alertService.clear();
				this.alertService.error(this.translate.instant('WEB_EDITOR.DENOMINATOR_ZERO'));
			}
		} else {
			this.alertService.clear();
			this.alertService.error(this.translate.instant('WEB_EDITOR.NUMERATOR_AND_DENOMINATOR_EMPTY'));
		}
	}

	addSpecialCharacter(option: any){
		this.modalRef.hide()
		if(option != ""){
			this.iceCKEditor.insertTagEditArea(option, this.elementRef,editor,CKEDITOR, this.iceTopicContent, "ICETopicContent" )
		}
	}

  showSuccessAlert(en:string){
    var alert = null;
    this.translate.get(en).subscribe((res: string) => {
      alert = res;
    });
    this.alert.success(alert);
	}
	
	/**
	 * click event of link button
	 * @param AssociateCoursetemplate 
	*/
	addAssociateCourse(template: TemplateRef<any>){
		if(this.selectedConfirmed == false){
		  this.selectedIceCourse = this.iceTopicContent.linkedCourseId;
		}
		this.modalRef = this.modalService.show(template, this.modalConfig);
	
	  }

	/**
	 * select a course from course list
	 * @param selectedCourseId 
	*/
	selectCourse(courseId: string){
		this.selectedConfirmed = false;
		if(this.selectedIceCourse == null){
		  this.selectedIceCourse = courseId;
		} else{
		  if( this.selectedIceCourse == courseId){
			this.selectedIceCourse == null;
		  } else{
			this.selectedIceCourse = courseId;
		  }
		}
	}

	/**
	 * confirm course association
	 * OK button click event
	*/
	confirmSelected(){
		this.selectedConfirmed = true;
		this.topicContentModified = true;
		this.sharedService.setModifiedStatus(this.tabId, this.topicContentModified)
		this.iceTopicContent.linkedCourseId = this.selectedIceCourse;
		this.iceCourseList.forEach((courseItem : ICECourse) => {
		  if(courseItem.courseId == this.iceTopicContent.linkedCourseId){
			this.linkedCourse = courseItem.fileName;
		  }
		});
	}

	/**
	 * clear selected course
	*/
	clearSelected(){
		this.linkedCourse = null;
		this.selectedIceCourse = null;
		this.iceTopicContent.linkedCourseId = null;
		this.selectedConfirmed = false;
		this.topicContentModified = true;
		this.sharedService.setModifiedStatus(this.tabId, this.topicContentModified)
	}

	/**
	 * load basic details of courses
	 * @param payload 
	*/
	loadICECourseList(payload:WsResponse['payload']){
		this.iceCourseModel = null;
			if(payload.length != 0){
				for(let i = 0; i < payload.length; i++){
			this.iceCourseModel = payload[i];
			this.iceCourseList.push(this.iceCourseModel);
				}
			this.iceCourseList.sort((a, b) => (a.fileName.toLowerCase() > b.fileName.toLowerCase()) ? 1 : -1);	
			}else{
				// no row data to show
		}
		if(this.iceCourseList.length > 0){
		  this.iceCoursesAvailable = true;
		  if(this.iceTopicContent.linkedCourseId != undefined || this.iceTopicContent.linkedCourseId != null){
			this.selectedIceCourse = this.iceTopicContent.linkedCourseId;
			// this.iceCourseList.forEach((courseItem : ICECourse) => {
			//   if(courseItem.courseId == this.iceTopicContent.linkedCourseId){
			// 	this.linkedCourse = courseItem;
			//   }
			// });
		  }
		}
	}
	
	ngOnDestroy() {
		if (this.desceditor) {
			this.desceditor.destroy();
		} else {
			//no editor
		}
		window.removeEventListener("resize", this.onWindowResize);
        // this.gridApi.destroy();
        this.gridApi = null;
	}

	loadConflictData(payload: any) {
		this.rowData = [];
		for (let i = 0; i < payload.length; i++) {
			this.rowData.push({
				type: payload[i]["type"],
				newVal: payload[i]["isDeleted"] == "false" ? payload[i]["newValue"] : "DELETED",
				lastVal: payload[i]["lastValue"],
				id: payload[i]["id"],
				name: payload[i]["name"],
				lastUpdateTimeStamp: payload[i]["lastUpdateTimeStamp"],
				isDeleted: payload[i]["isDeleted"]
			})
		}
		for (let x = 0; x < this.rowData.length; x++) {
			if (this.rowData[x].type == "Statement") {
				this.rowData.push(this.rowData.splice(x, 1)[0]);
			}
		}
	}

	public autoUpdateICE() {
		let selectedNodes = this.gridApi.getSelectedRows();
		let length = this.gridApi.getSelectedRows().length;
		if (length == 0) {
			this.alertService.clear();
			this.alertService.error(this.translate.instant('WEB_EDITOR.SELECT_REUSABLES_TO_UPDATE'));
		} else {
			this.modalRef.hide();
			for (let i = 0; i < length; i++) {
				let id = selectedNodes[i].id;
				let type = selectedNodes[i].type;
				let flag = selectedNodes[i].isDeleted;
				if (type == 'TagNo') {
					let nodes = $(".toggle-edit" + this.tabId + this.userId).find("tag#" + id);
					for (let j = 0; j < nodes.length; j++) {
						let fldNode = nodes[j].childNodes[0];
						let fldName = $(fldNode).attr("name");
						if (fldName == 'tagNo') {
							if (flag == 'false') {
								$(fldNode).attr("lastupdatetimestamp", selectedNodes[i].lastUpdateTimeStamp);
								$(fldNode).attr("value", selectedNodes[i].newVal);
								fldNode.childNodes[0].textContent = selectedNodes[i].newVal;
							} else {
								let node = nodes[j];
								$(node).remove();
							}
						}
					}
				} if (type == 'TagSystem') {
					let nodes = $(".toggle-edit" + this.tabId + this.userId).find("tag#" + id);
					for (let j = 0; j < nodes.length; j++) {
						let fldNode = nodes[j].childNodes[0];
						let fldName = $(fldNode).attr("name");
						if (fldName == 'sysName') {
							if (flag == 'false') {
								$(fldNode).attr("lastupdatetimestamp", selectedNodes[i].lastUpdateTimeStamp);
								$(fldNode).attr("value", selectedNodes[i].newVal);
								fldNode.childNodes[0].textContent = selectedNodes[i].newVal;
							} else {
								let node = nodes[j];
								$(node).remove();
							}
						}
					}
				} if (type == 'TagDocument') {
					let nodes = $(".toggle-edit" + this.tabId + this.userId).find("tag#" + id);
					for (let j = 0; j < nodes.length; j++) {
						let fldNode = nodes[j].childNodes[0];
						let fldName = $(fldNode).attr("name");
						if (fldName == 'docName') {
							if (flag == 'false') {
								$(fldNode).attr("lastupdatetimestamp", selectedNodes[i].lastUpdateTimeStamp);
								$(fldNode).attr("value", selectedNodes[i].newVal);
								fldNode.childNodes[0].textContent = selectedNodes[i].newVal;
							} else {
								let node = nodes[j];
								$(node).remove();
							}
						}
					}
				} else if (type == 'TagDesc') {
					let nodes = $(".toggle-edit" + this.tabId + this.userId).find("tag#" + id)
					for (let j = 0; j < nodes.length; j++) {
						let fldNode = nodes[j].childNodes[0];
						let fldName = $(fldNode).attr("name");
						if (fldName == 'tagName') {
							if (flag == 'false') {
								let txtNode = fldNode.childNodes[0].childNodes[0]
								$(fldNode).attr("lastupdatetimestamp", selectedNodes[i].lastUpdateTimeStamp);
								txtNode.textContent = selectedNodes[i].newVal;
							} else {
								let node = nodes[j];
								$(node).remove();
							}
						}
					}
				} else if (type == 'Component' || type == 'Document' || type == 'Facility') {
					let nodes = $(".toggle-edit" + this.tabId + this.userId).find("com#" + id)
					for (let j = 0; j < nodes.length; j++) {
						let node = nodes[j];
						let compType = $(node).attr("compType");
						if (compType == 'doc' || compType == 'com' || compType == 'fac') {
							if (flag == 'false') {
								$(node).attr("lastupdatetimestamp", selectedNodes[i].lastUpdateTimeStamp);
								$(node).attr("compValue", selectedNodes[i].newVal);
								node.textContent = selectedNodes[i].newVal;
							} else {
								$(node).remove();
							}
						}
					}
				} else if (type == 'System Number') {
					let nodes = $(".toggle-edit" + this.tabId + this.userId).find("com#" + id)
					for (let j = 0; j < nodes.length; j++) {
						let node = nodes[j];
						let compType = $(node).attr("compName");
						if (compType == 'SystemNo') {
							if (flag == 'false') {
								$(node).attr("lastupdatetimestamp", selectedNodes[i].lastUpdateTimeStamp);
								$(node).attr("compvalue", selectedNodes[i].newVal);
								node.textContent = selectedNodes[i].newVal;
							} else {
								$(node).remove();
							}
						}
					}
				} else if (type == 'System Name') {
					let nodes = $(".toggle-edit" + this.tabId + this.userId).find("com#" + id)
					for (let j = 0; j < nodes.length; j++) {
						let node = nodes[j];
						let compType = $(node).attr("compName");
						if (compType == 'SystemName') {
							if (flag == 'false') {
								$(node).attr("lastupdatetimestamp", selectedNodes[i].lastUpdateTimeStamp);
								$(node).attr("compvalue", selectedNodes[i].newVal);
								node.textContent = selectedNodes[i].newVal;
							} else {
								$(node).remove();
							}
						}
					}
				} else if (type == 'Statement') {
					let oldStmNode: any;
					let newStmHTML: string = '';
					let nodes = $(".toggle-edit" + this.tabId + this.userId).find("statement#" + id)
					for (let j = 0; j < nodes.length; j++) {
						oldStmNode = nodes[j];
						if (flag == 'false') {
							newStmHTML = this.iceCKEditor.createEditedStatement(selectedNodes[i].name, selectedNodes[i].lastUpdateTimeStamp, oldStmNode)
							this.iceCKEditor.setOuterHTML(oldStmNode, newStmHTML);
						} else {
							$(oldStmNode).remove();
						}
					}
				} else if (type == 'TagAttribute') {
					let nodes =  $(".toggle-edit" + this.tabId + this.userId).find("attr");
					for (let j = 0; j < nodes.length; j++) {
						let node = nodes[j];
						let specId = $(node).attr("specid");
						if(specId == id) {
							if (flag == 'false') {
								$(node).attr("lastupdatetimestamp", selectedNodes[i].lastUpdateTimeStamp);
								$(node).attr("value", selectedNodes[i].newVal);
								node.textContent = selectedNodes[i].newVal;
							} else {
								$(node).remove();
							}
						}
					}
				}
			}
			this.alertService.clear();
			this.alertService.warn(this.translate.instant('PROCEDURE_EDITOR.CONTENT_UPDATE_MSG'), true);

		}
		
	}

	cancel() {
		let index = this.breadCrumbList.length-1;
		this.sharedService.changeIceItemRequest(this.breadCrumbList[index].iceType, this.breadCrumbList[index]);
		this.breadCrumbList.length = index;
	}

	public openCancelConfirmationModel(template: TemplateRef<any>) {
		if(this.sharedService.getModifiedStatus(this.tabId)){
		  this.modalRef = this.modalService.show(template, this.modalConfig);
		}else{
		  this.cancel();
		}
	}

	/**
	 * Save model hierarchy details into the local storage of the browser
	 */
	public copyModelInfo(){
		let blinkLayers;
		let hiddenLayers;
		let self = this;
		if(this.blinkLayers != null && this.blinkLayers.length > 0){
			blinkLayers = this.blinkLayers[0]
			for (var i = 1; i <= this.blinkLayers.length - 1; i++) {
				blinkLayers = blinkLayers + "##ICELAYERSPLIT##" + this.blinkLayers[i]
			}
			blinkLayers = blinkLayers.replace(/\\\\/g, "\\");
		}
		if(this.hiddenLayers != null && this.hiddenLayers.length > 0){
			hiddenLayers = this.hiddenLayers[0]
			for (var i = 1; i <= this.hiddenLayers.length - 1; i++) {
				hiddenLayers = hiddenLayers + "##ICELAYERSPLIT##" + this.hiddenLayers[i]
			}
			hiddenLayers = hiddenLayers.replace(/\\\\/g, "\\");
		}
		if(this.selectedViewInfo != null && this.selectedViewName != null){
			let modelId = this.iceTopicContent.topicContentThreeDModel != null ? this.iceTopicContent.topicContentThreeDModel.id : null;
			const now = new Date();
			var utcTime = new Date(now.getTime() + (now.getTimezoneOffset() * 60000));
			var jsonData = {
				"modelId": modelId,
				"blinkLayerState": blinkLayers,
				"layerState": hiddenLayers,
				"modelView": this.selectedViewInfo,
				"modelViewName": this.selectedViewName,
				"dateTime": utcTime
			}

			let modelInfoList: any[] = [];
			modelInfoList = JSON.parse(localStorage.getItem("model_info_list"));
			if(modelInfoList != null){
				if(this.hasSavedModelInfo){
					const index: number = modelInfoList.findIndex(item => item.modelId == modelId);
					if (index !== -1) {
						modelInfoList.splice(index, 1);
					}   
					modelInfoList.push(jsonData)
				}else if(modelInfoList.length == this.copyModelMaxCount){
					modelInfoList = modelInfoList.sort((a, b) => (a.dateTime > b.dateTime) ? 1 : -1);
					modelInfoList.splice(0, 1);
					modelInfoList.push(jsonData);
				}else{
					modelInfoList.push(jsonData)
				}
			}else{
				modelInfoList = [];
				modelInfoList.push(jsonData)
			}
			localStorage.setItem("model_info_list", JSON.stringify(modelInfoList)); //add list to the local storage
			self.alertService.success(self.translate.instant('ICE_TOPIC_CONTENT.TOPIC_CONTENT.MODEL_INFO_SAVE_SUCCESS'));
			this.enableImportSaveModelDataButton(modelId); //enable/disable the Import Saved Model Data button
		}else{
			//do nothing
		}
	}

	/**
	 * To enable/disable the Import Saved Model Data button 
	 * @param modelId 
	 */
	enableImportSaveModelDataButton(modelId){
		this.hasSavedModelInfo = false;
		//get the saved model info from local storage
		let modelInfoList: any[] = JSON.parse(localStorage.getItem("model_info_list"));
		if(modelInfoList != null){
			for(let modelInfoItem of modelInfoList){
				if(modelInfoItem.modelId == modelId){
					this.hasSavedModelInfo = true;
				}
			}
		}
	}

	/**
	 * To enable/disable the Copy Model Info button 
	 * @param selectedModelView 
	 */
	enableCopyModelInfoButton(selectedModelView){
		if(selectedModelView != null && selectedModelView != ""){
			this.hasSelectedModelView = true;
		}else{
			this.hasSelectedModelView = false;
		}
	}

	/**
	 * Import model hierarchy details from the local storage
	 */
	public importSavedModelData(){
		let self = this;
		let layerState;
		let blinkLayerState;
		//clear existing view and the hierarchy details
		this.hiddenLayers = [];
		this.blinkLayers = [];
		this.selectedHiddenlayers = [];
		this.selectedBlinklayers = [];
		this.selectedViewInfo = null;
		this.selectedViewName = null;
		this.selectedViewType = [];
		let modelId = this.iceTopicContent.topicContentThreeDModel != null ? this.iceTopicContent.topicContentThreeDModel.id : null;
		//get the saved model info from local storage
		let modelInfoList: any[] = JSON.parse(localStorage.getItem("model_info_list"));
		for(let modelInfoItem of modelInfoList){
			if(modelInfoItem.modelId == modelId){
				//get hidden and blink layer values for the selected model
				layerState = modelInfoItem.layerState != "" ? modelInfoItem.layerState : null;
				blinkLayerState = modelInfoItem.blinkLayerState != "" ? modelInfoItem.blinkLayerState : null;
				this.selectedViewInfo = modelInfoItem.modelView;
				this.selectedViewName = modelInfoItem.modelViewName;
				//set the selected model view
				this.selectedViewType.push({ 'id': 0, 'text': this.selectedViewName });
			}
		}
		if(layerState != null){
			let hidden = layerState.split("##ICELAYERSPLIT##");
			for (var i = 0; i <= hidden.length - 1; i++) {
				this.getSelectedHiddenLayers(hidden[i], true, true);
			}
		}
		if(blinkLayerState != null){
			let blink = blinkLayerState.split("##ICELAYERSPLIT##");
			for (var i = 0; i <= blink.length - 1; i++) {
				this.getSelectedBlinkLayers(blink[i], true, true);
			}
		} 
		if(this.selectedViewInfo != null){
			self.alertService.success(self.translate.instant('ICE_TOPIC_CONTENT.TOPIC_CONTENT.MODEL_INFO_IMPORT_SUCCESS'));
		}
		//populate layer hierarchy tables with the imported saved model data
		this.rowData2 = JSON.parse(this.iceTopicContent.topicContentThreeDModel.layerContent);
	}
}
