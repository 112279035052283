import { Component, ViewChild, TemplateRef, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AlertService } from 'src/app/util/alert/alert.service';
import { WorkflowService } from '../../workflow.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'wf-view-tasks',
	templateUrl: './wf-view-tasks-cell-renderer.component.html'
 })
export class WfViewTasksCellRendererComponent implements ICellRendererAngularComp{
    bsModalRef: BsModalRef;   
    public params: any;
	docuSignId: string;
	type: string;
	system: any = "";
    modalRef: BsModalRef;
    @ViewChild('docusignTemplate') docusignTemplate: TemplateRef<any>
    public modalConfig: any = {
		backdrop: true,
		// ignoreBackdropClick: true,
		class: 'modal-lg',
		// keyboard: false
	}
    
    constructor(private modalService: BsModalService){

    }
    
    agInit(params: any){
        this.params = params;
        this.docuSignId = params.data.docusignEntryId;
        console.log("docuSignId:", params.data.docusignEntryId)
    }
    
    refresh(): boolean{
        return false;
    }

    viewTasks(){
        this.type = 'VIEW_TASKS';
		event.stopPropagation();
		this.modalRef = this.modalService.show(this.docusignTemplate, this.modalConfig);
    }
    
    public modelRefCloseEvent() {
		this.modalRef.hide()
	}
}