<!--Alert-->
<alert class="floating-alert"></alert>

<!-- header -->
<nav id="navPanel" class="bg-light border-bottom box-shadow fixed-top navbar-expand-lg" #navPanel>
	<app-header [minimalOption]="true"></app-header>
</nav>

<div class="container-fluid">
	<main role="main" class="col-md-12 px-0" #main [style.padding-top.px]="paddingtop">
		<!-- title and  manage attribute button-->
		<div class="col-sm-12 px-0 align-self-end">
			<div class="row align-items-center">
				<div class="col-sm-8">
					<h4 class="" id="classification_title">
						{{ 'TAG_CLASSIFICATION.TITLE' | translate }}
					</h4>
				</div>
				<div class="col-sm-4">
					<!-- manage attribute button -->
					<button type="button" class="btn btn-sm btn-primary float-right"
						(click)="manageAttributeClick()">{{ 'TAG_CLASSIFICATION.MANAGE_ATTRIBUTES' | translate }}
					</button>
				</div>
			</div>
			<hr class="mt-0 mb-3" />
		</div>
		<div class="row">
			<!-- left side panel (search box and value list) -->
			<div class="col-sm-4">
				<!-- search box and add new button -->
				<div class="form-group row mb-1">
					<div class="col-sm-6 align-self-end">
						<label class="h5" for="exampleInputEmail1">
							{{ 'TAG_CLASSIFICATION.LABEL_SEARCH' | translate }}
						</label>
					</div>
					<div class="col-sm-6 align-self-end">
						<button id="btn_add_classification" type="button" class="btn btn-sm btn-primary my-1 float-right"
							(click)="addClassificationButtonClick(template)">
							{{ 'TAG_CLASSIFICATION.BTN_TXT_ADD_TAG_CLASSIFICATION' | translate }}
						</button>
					</div>
					<div class="col-sm-12">
						<input type="text" class="form-control form-control-sm" aria-label="Sizing example input"
							placeholder="Search Tag Classification" aria-describedby="inputGroup-sizing-default"
							[(ngModel)]="filterText">
					</div>
				</div>
				<hr class="my-2" />
				<!-- tag classification list with 2 buttons -->
				<div [style.max-height.px]="divHeight" style="overflow: auto;">
				<div class="mt-2" *ngFor="let tagClassification of filter(tagClassificationList); let i = index;">
					<div class="card my-1 cursor-pointer card-primary"
						[ngClass]="{'card-primary': tagClassification.classId != selectedClassification.classId, 'card-secondary': tagClassification.classId == selectedClassification.classId }">
						<div class="card-body px-2 py-1" (click)="onClassificationSelect(tagClassification)">
							<!-- label -->
							<div *ngIf="!editableMode[i]" class="col-sm-12 float-left px-0">
								<p class="pb-0 h6">{{tagClassification.className}}</p>
							</div>
							<!-- edit text -->
							<div *ngIf="editableMode[i]" class="col-sm-12 float-left px-0">
								<input class="form-control form-control-sm" type="text" id="tagClassificationClassName"
									[(ngModel)]="tagClassification.className" (keydown.enter)="onKeydown($event, i)"
									[symbiosisFocus]="editableMode[i]" (click)="$event.stopPropagation();" />
							</div>
							<!-- label button set -->
							<div *ngIf="!editableMode[i]" class="col-sm-12 float-left px-0">
								<div class="col-sm-3 float-right px-0">
									<button id="btnDeleteTagClassification" type="button"
										class="my-1 mx-2 close close-list float-right"
										(click)="$event.stopPropagation(); deleteButtonClick(tagClassification, i)">
										<i class="far fa-trash-alt"></i>
									</button>
									<button id="btnEditTagClassification" type="button"
										class="my-1 mx-2 close close-list float-right"						
										(click)="$event.stopPropagation();renameButtonClick(tagClassification, i, editTemplate)">
										<i class="far fa-edit"></i>
									</button>
								</div>
							</div>
							<!-- edit button set -->
							<div *ngIf="editableMode[i]" class="col-sm-12 float-left px-0">
								<div class="col-sm-3 float-right px-0">
									<button id="btnCancelEditTagClassification" type="button"
										class="my-1 mx-2 close close-list float-right"
										(click)="$event.stopPropagation(); editCloseButtonClick(i); editableMode[i] = !editableMode[i]">
										<i class="far fa-times"></i>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				</div>
			</div>
			<!-- right side panel (description, attributes ) -->
			<div class="col-sm-8">
				<!-- description -->
				<div class="col-sm-12 px-0" id="classification_description">
					<div class="form-group row" id="value_description">
						<div class="col-sm-12 align-self-end">
							<label class="h5 mb-0" for="exampleInputEmail1">
								{{ 'TAG_CLASSIFICATION.TITLE' | translate }}
								<small class="mx-2"> {{selectedClassification.className}} </small>
							</label>
						</div>
						<div class="col-sm-9 align-self-end">
							<label class="h6" for="exampleInputEmail1">
								{{ 'TAG_CLASSIFICATION.LABEL_DESCRIPTION' | translate }}
							</label>
						</div>
						<div class="col-sm-3 align-self-end">
							<button id="btnSaveTagDescription" type="button" (click)="saveDescription()"
								[disabled]="selectedClassification.classId == ''"
								class="btn btn-sm btn-primary my-1 float-right">
								{{ 'TAG_CLASSIFICATION.BTN_TXT_SAVE' | translate }}
							</button>
						</div>
						<div class="col-sm-12">
							<textarea class="form-control" id="classification_description" rows="3" maxlength="500" [disabled]="selectedClassification.classId == ''" [(ngModel)]="tagDescription" (click)="editTagDescription();">
							</textarea>
						</div>
					</div>
				</div>
				<hr class="my-2" />
				<!-- tag attributes -->
				<div class="col-sm-12 px-0" id="classification_description">
					<div class="form-group row mt-3" id="value_description">
						<div class="col-sm-9 align-self-end">
							<label class="h5 " for="exampleInputEmail1">
								{{ 'TAG_CLASSIFICATION.LABEL_TAG_ATTRIBUTES' | translate }}
								<small class="mx-2"> {{selectedClassification.className}} </small>
							</label>
						</div>
						<div class="col-sm-3 align-self-end">
							<div class="dropdown float-right" *ngIf="this.classAttributeAvailableList.length > 0">
								<button class="btn btn-primary btn-sm dropdown-toggle" type="button"
									id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
									aria-expanded="false">
									{{ 'TAG_CLASSIFICATION.BTN_TXT_ADD_ATTRIBUTE' | translate }}
								</button>
								<div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton" style="max-height: 255px; overflow: auto;">
									<a *ngFor="let item of classAttributeAvailableList" name="attribute-drop-down"
										class="dropdown-item" (click)="addAttribute(item)">{{item.tagAttributeName}}</a>
								</div>
							</div>
						</div>
						<div class="col-sm-12 my-2">
							<ag-grid-angular style="width: 100%; height: 250px;" class="ag-theme-balham"
								[rowData]="rowData" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }" [gridOptions]="gridOptions" [columnDefs]="columnDefs"
								[enableRangeSelection]="true" (gridReady)="onGridReady($event)"
								[frameworkComponents]="frameworkComponents" [rowDragManaged]="true" [animateRows]="true"
								(rowDragEnd)="onRowDragEnd($event)" (cellValueChanged)="onCellValueChanged($event)" (cellClicked)="onCellClick($event, allCategoryModel)"
								[overlayNoRowsTemplate]="overlayNoRowsTemplate" (columnResized)="onColumnResized($event)" (rowDataChanged)="onRowDataChanged($event)">
							</ag-grid-angular>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
</div>


<!-- <div class="container mt-5 pt-5">
	<div class="row">
		<div class="col-sm" id="classification_list">
			<div class="input-group mb-3">
				<div class="input-group-prepend">
					<span class="input-group-text" id="inputGroup-sizing-default">Filter</span>
				</div>
				<input type="text" class="form-control" aria-label="Sizing example input"
					aria-describedby="inputGroup-sizing-default" [(ngModel)]="filterText">
			</div>
			<div class="list-group" id="list-tab" role="tablist">
				<div *ngFor="let tagClassification of filter(tagClassificationList); let i = index;">
					<a *ngIf="!editableMode[i]" class="list-group-item list-group-item-action" id="list-home-list"
						data-toggle="list"
						(click)="onClassificationSelect(tagClassification)">{{tagClassification.className}}</a>
					<input type="text" *ngIf="editableMode[i]" [(ngModel)]="tagClassification.className"
						(keydown.enter)="onKeydown($event, i)" />
					<div class="col-sm-3 float-right px-0">
						<button id="btnGotoLearningCheck" type="button" class="my-1 mx-3 close float-right"
							(click)="renameButtonClick(tagClassification, i)">
							<i class="far fa-angle-right"></i>
						</button>
						<button id="btnGotoLearningCheck" type="button" class="my-1 mx-3 close float-right"
							(click)="deleteButtonClick(tagClassification, i)">
							<i class="far fa-angle-right"></i>
						</button>
					</div>
				</div>
				<button type="button" class="btn btn-warning"
					(click)="addClassificationButtonClick(template)">Add</button>
			</div>
		</div>
		<div class="col-sm">
			<div class="row" id="classification_description">
				Tag Classification:components only
				<div class="form-group">
					<label for="exampleFormControlTextarea1">Description</label>
					<textarea class="form-control" id="classification_description" rows="3"
						[(ngModel)]="tagDescription"></textarea>
					<button type="button" class="btn btn-warning" (click)="saveDescription()">Save</button>
				</div>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="row container-fluid" id="tag_attributes">
			Tag Attributes:components only
			<ag-grid-angular style="width: 100%; height: 250px;" class="ag-theme-balham" [rowData]="rowData"  [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }"
				[gridOptions]="gridOptions" [columnDefs]="columnDefs" [enableRangeSelection]="true"
				(gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents" [rowDragManaged]="true"
				[animateRows]="true" (rowDragEnd)="onRowDragEnd($event)" (cellValueChanged)="onCellValueChanged($event)"
				[overlayNoRowsTemplate]="overlayNoRowsTemplate">
			</ag-grid-angular>
		</div>
		<div class="dropdown" *ngIf="this.classAttributeAvailableList.length > 0">
			<button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
				data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
				Add Attribute
			</button>
			<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
				<a *ngFor="let item of classAttributeAvailableList" name="attribute-drop-down" class="dropdown-item"
					(click)="addAttribute(item)">{{item.tagAttributeName}}</a>
			</div>
		</div>
	</div>
</div> -->

<!-- add new tag classification modal -->
<ng-template #template>
	<div class="modal-header">
		<h5 class="modal-title">
			{{ 'TAG_CLASSIFICATION.MODAL_HEADER_NEW_CLASSIFICATION' | translate }}
		</h5>
		<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<form ngNativeValidate>
		<div class="modal-body">
			
				<div class="form-group">
					<label for="classificationName">
						{{ 'TAG_CLASSIFICATION.FORM_NAME' | translate }}
					</label>
					<div class="badge-required mx-2"></div>
					<input type="text" class="form-control" id="classificationName" name="classificationName" placeholder=""
						[(ngModel)]="newClassificationName" required/>
				</div>
				<div class="form-group">
					<label for="classificationDescription">
						{{ 'TAG_CLASSIFICATION.FORM_DESCRIPTION' | translate }}
					</label>
					<div class="badge-required mx-2"></div>
					<input type="text" class="form-control" id="classificationDescription" name="classificationDescription"
						placeholder="" [(ngModel)]="newClassificationDescription" required/>
				</div>
		</div>
		<div class="modal-footer">
			<button type="submit" class="btn btn-primary btn-sm" (click)="submitButtonClick()">
				{{ 'TAG_CLASSIFICATION.BTN_TXT_SUBMIT' | translate }}
			</button>
			<button type="button" formnovalidate class="btn btn-secondary btn-sm" data-dismiss="modal"
				(click)="bsModalRef.hide()">
				{{ 'TAG_CLASSIFICATION.BTN_TXT_CANCEL' | translate }}
			</button>
		</div>
	</form>	
</ng-template>
<!--Edit Classification Template-->
<ng-template #editTemplate>
	<div class="modal-header">
		<h5 class="modal-title">{{ 'TAG_CLASSIFICATION.EDIT_CLASSIFICATION' | translate }}</h5>
		<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="editCancelButtonClick()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<form ngNativeValidate>
		<div class="modal-body">			
				<div class="form-group">
					<label for="newClassificationName">{{ 'TAG_CLASSIFICATION.FORM_NAME' | translate }} </label>
					<div class="badge-required mx-2"></div>
					<input type="text" class="form-control" id="newClassificationName" name="newClassificationName" placeholder=""
						[(ngModel)]="newClassificationName" required>
				</div>
			
		</div>
		<div class="modal-footer">
			<button type="submit" class="btn btn-primary btn-sm"
				(click)="editSubmitButtonClick()">{{ 'TAG_CLASSIFICATION.BTN_TXT_SUBMIT' | translate }}</button>
			<button type="button" formnovalidate class="btn btn-secondary btn-sm"
				(click)="editCancelButtonClick()">{{ 'TAG_CLASSIFICATION.BTN_TXT_CANCEL' | translate }}</button>
		</div>
	</form>
</ng-template>

<!--Model to be passed-->
<ng-template #allCategoryModel>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Select a Category</h4>
        <button type="button" id="image_close" class="close pull-right" aria-label="Close"
            (click)="closeButtonClickEvent()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <app-select-category-tree (categorySelected)="selectedCategory($event)" [categoryType]="categoryType"
    [selectedCategoryItem]="tempCategoryItem" [showIncludeChildren]=false (closeModal)="closeButtonClickEvent()">
    </app-select-category-tree>
</ng-template>