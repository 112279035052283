import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DocumentsService } from './documents.service';
import { AlertService } from '../../util/alert/alert.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from '../../util/loading/loading.service';
import { TreeListener } from './pdfview-listener.interface';

declare var $: any;
@Component({
   selector: 'pdfviewmodal-modal',
   template: `
   <div class="modal-header">
   <h4 class="modal-title pull-left">{{"Select PDF file"}}</h4>
   <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
   </button>
</div>
<div class="modal-body">
<div class="container">
<p>
   <label id="lblName">{{folderName}}</label>
</p>
    <div id="pdfTree" class="my-2 mx-1" style="width: 100%;">
</div>
<div class="modal-footer">
   <button type="button" class="btn btn-primary" (click)="selectBtnFunction()">{{ 'Select' | translate}}</button>
   <button type="button" class="btn btn-secondary" (click)="cancelButtonClickFunction();bsModalRef.hide()">{{ 'CANCEL' | translate}}</button>
</div>
`
})
export class PDFViewModalComponent {
   public data: any = {
      'data': [
         { "id": "ajson1", "parent": "#", "text": "ROOT NODE", icon: "fa fa-folder-o", type: "folder" },
         { "id": "ajson2", "parent": "#", "text": "Root node 2", icon: "fa fa-folder-o", type: "folder" },
         { "id": "ajson4", "parent": "ajson2", "text": "Child 2", icon: "fa fa-file-pdf", type: "pdf" }
      ]
   }
   public data_n: any = {
      'data': [
      ]
   }
   public payload: any;
   public selectedFolderId: string = null
   public selectedFileName: string = null
   public treeRef: any = null;
   public selectedTreeItem: any = null   
   public selectedFileId: string;
   public treeListener: TreeListener;
   public contentItemId: string;
   public editContentItemId: string;
   public type: string;
   public folderName: any;

   constructor(public bsModalRef: BsModalRef, private documentService: DocumentsService, private alertService: AlertService, private router: Router,
      private translate: TranslateService, private loadingService: LoadingService) { }

   ngOnInit() {
      if (this.payload != null) {
         this.data_n['data'] = [];
         var item = this.payload.filter(item => item["parent"] === true);
         if (item != null && item.length > 0) {
            item[0]["parentId"] = "#"
            for (let i = 0; i < this.payload.length; i++) {
               this.data_n["data"].push({ id: this.payload[i]["id"], parent: this.payload[i]["parentId"], text: this.payload[i]["name"], path: this.payload[i]["path"], icon: "far fa-folder", type: "folder" })
            }
            //console.log("this.data_n " + this.data_n["data"])
            this.treeRef = $('#pdfTree').jstree({
               core: {
                  themes: {
                     variant: 'normal'
                  },
                  strings: {
                     'New node': 'New Folder'
                  },
                  data: this.data_n["data"],
                  check_callback: function (operation, node, node_parent, node_position, more) {
                  },
                  force_text: true
               },
               types: {
                  default: {
                     // "icon" : "fa fa-file-o "
                  }
               },
               plugins: ["types"],
            
            });

            //setTimeout(() => $("#pdfTree").jstree("open_node", item[0]["id"]), 250);
            //$("#foldertree").jstree("open_node", item[0]["id"])
            $('#pdfTree').bind("select_node.jstree", (evt, data) => this.folderSelectEvent(evt, data))
            $('#pdfTree').bind("loaded.jstree", function (event, data) {
               $(this).jstree("open_all");
            })
            $('#pdfTree').bind('changed.jstree', (evt, data) => this.folderSelectPath(evt, data))            
         }
      }
   }

   public folderSelectPath(evt: any, data: any) {
      this.folderName = data.instance.get_path(data.node,'/');
   }

   public folderSelectEvent(evt: any, data: any) {
      //console.log('[select_node.jstree] (data.node) ' + JSON.stringify(data.node.id));
      this.selectedTreeItem = data
      let folderType: string = data.node.original.type
      this.selectedFolderId = data.node.id
      this.selectedFileName = data.node.text
      this.type = data.node.original.type
      if (folderType == 'folder') {
         if (data.node.parent != '#') {
            this.loadingService.showLoading(true, null, "", null)
            this.documentService.getSelectedFolderPDFAndFolders(data.node.id)
               .subscribe(data => {
                  this.loadingService.hideLoading()
                  data.payload.forEach(element => {
                     var createdNodde = $('#pdfTree').jstree(true).get_node(element.id);           
                     if (createdNodde == false) {
                        if (element.type == "Folder" || element.type == "ContentItem") {
                           if (element.type == "ContentItem") {
                              if(element.status == "Active"){
                                 if ( element.id == this.contentItemId || element.id == this.editContentItemId) {
                                    $('#pdfTree').jstree().create_node(this.selectedFolderId, {
                                       "id": element.id,
                                       "text": element.name,
                                       "icon": "fa fa-file-pdf",
                                       "type": "pdf",
                                       "state": { "opened" : true, "selected" : true}
                                    });
                                 } else {
                                    $('#pdfTree').jstree().create_node(this.selectedFolderId, {
                                       "id": element.id,
                                       "text": element.name,
                                       "icon": "fa fa-file-pdf",
                                       "type": "pdf"
                                    });
                                 }
                              }else{
                                 //nothimg to do
                              }
                           } else {
                              $('#pdfTree').jstree().create_node(this.selectedFolderId, {
                                 "id": element.id,
                                 "text": element.name,
                                 "icon": "far fa-folder",
                                 "type": "folder"
                              });
                           }
                        } else {
                        }

                     } else {
                        //node is created
                     }
                  });
                  $("#pdfTree").jstree("open_all", this.selectedFolderId); 
               },
                  error => {
                     this.loadingService.hideLoading()
                     this.alertService.clear()
                     this.alertService.error(error.statusDescription)
                  });
         }

      } else {

      }
   }

   public setTreeListener(treeListener: TreeListener) {
		this.treeListener = treeListener;
	}

   public cancelButtonClickFunction() {

   }

   public selectBtnFunction() {
      if(this.type == "pdf"){
         this.bsModalRef.hide();
         this.treeListener.pdfFileSelectClickFunction(this.selectedFolderId, this.selectedFileName);
      }
   }

}


