<div *ngIf="isDeleteVisible" class="col-sm">
    <button type="button" [disabled]="false" class="btn btn-sm btn-outline-dark py-0 my-1 px-1 border-0"
        (click)="deleteConfirm()">
        <i class="far fa-trash-alt" aria-hidden="true"></i>
    </button>
</div>

<ng-template #deleteCSInstanceModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Delete confirm</h4>
    </div>
    <div class="modal-body pb-0">
        <h5>{{deleteMsg}}</h5>
    </div>
    <div class="modal-footer">
        <!--<button type="button" id="warn_confirm" class="btn btn-primary"
                (click)="deleteCSInstance()">
            {{ 'OK' | translate}} </button>
        <button type="button" id="warn_cancel" class="btn btn-secondary"
                data-dismiss="modal" (click)="cancelDeleteCSInstance()">
            {{ 'CANCEL' | translate}} </button>-->
        <button type="button" id="warn_confirm" class="btn btn-primary"
            (click)="deleteCSInstance()">
        Ok</button>
        <button type="button" id="warn_cancel" class="btn btn-secondary"
            data-dismiss="modal" (click)="cancelDeleteCSInstance()">
        Cancel</button>
    </div>
</ng-template>