<div class="modal-header py-2">
    <h5 class="modal-title">
        Precommissioning Approval Data
    </h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="container-fluid mt-3 mb-4 modal-container" id="irnaddedit">
    <form class="mt-2">

        <div class="row">
            <div class="col-sm-6">
                <div class="checkbox float-left">
                    <label class="">
                        <p class="h6 mb-0">Approved:</p>
                    </label>
                </div>
            </div>
        </div>
        <div class="row " (click)="changeColor('1')" [ngClass]="clickedRow=='1' ? 'bgcolor' : ''">
            <div class="col-sm-8 dropdown-100">
                <label for="companyManagerName" class="mb-0   p-0">COMPANY - Commissioning & Start-up Manager

                </label>
                <div class="badge-required mx-2"></div>
                <input #companyManagerName type="text" class="form-control form-control-sm mb-2 newheight"
                    id="companyManagerName" name="companyManagerName" [disabled]="pccStatus == 'Approved'">
            </div>
            <div class="col-sm-4 dropdown-100">
                <label for="companyManagerDate" class="mb-0 ">Date</label>
                <div class="badge-required mx-2"></div>
                <input type="text" placeholder="Select date" class="form-control newheight" name="companyManagerDate"
                    id="companyManagerDate" [(ngModel)]="companyManagerDate" [bsConfig]="bsConfig" placement="bottom"
                    [disabled]="pccStatus == 'Approved'" bsDatepicker>
            </div>
        </div>
        <div class="row" (click)="changeColor('2')" [ngClass]="clickedRow=='2' ? 'bgcolor' : ''">
            <div class="col-sm-8 dropdown-100">
                <label for="companyLeadName" class="mb-0  p-0">COMPANY - Commissioning Lead

                </label>
                <div class="badge-required mx-2"></div>
                <input #companyLeadName type="text" class="form-control form-control-sm mb-2 newheight"
                    id="companyLeadName" name="companyLeadName" [disabled]="pccStatus == 'Approved'">
            </div>
            <div class="col-sm-4 dropdown-100">
                <label for="companyLeadDate" class="mb-0 ">Date</label>
                <div class="badge-required mx-2"></div>
                <input type="text" placeholder="Select date" class="form-control newheight" name="leadDate"
                    id="companyLeadDate" [(ngModel)]="companyLeadDate" [bsConfig]="bsConfig" placement="bottom"
                    [disabled]="pccStatus == 'Approved'" bsDatepicker>
            </div>
        </div>
        <hr />
        <div class="row my-2">
            <div class="col-sm-6">

                <div class="form-group files mb-1">
                    <label class="">{{ 'PCC_ADD_EDIT.UPLOAD_DOCUMENTS' | translate }}</label>
                    <input type="file" class="form-control  custom-b  form-control-file" Name="profile"
                        id="NewFile_FileUpload" accept=".pdf" (change)="onFileChanged($event)" [(ngModel)]="file"
                        required>
                </div>
                <div class="row justify-content-between">
                    <div class="col-sm-6">
                        <label class="px-2 " *ngIf="pccDocumentUpload">
                            <mark id="newfilename" class="form-text text-muted pl-2 pr-2 rounded">{{
                                pccDocumentUpload.file.name}}
                            </mark>
                        </label>
                    </div>
                </div>

            </div>
        </div>
    </form>
</div>

<div class="modal-footer">
    <button type="button" id="irn_update" class="btn btn-primary btn-sm float-left" (click)="onUpload()">Upload</button>
    <button [disabled]="pccStatus == 'Approved'" type="submit" id="irn_update" class="btn btn-primary btn-sm float-left"
        (click)="save()">Save and Approve</button>
    <button type="button" id="irn_cancel" class="btn btn-secondary btn-sm float-left" (click)="cancel()">{{ 'CANCEL' |
        translate}}</button>
</div>

<ng-template #replacePCCDocumentModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Precommissioning Completion Certificate File Upload</h4>
    </div>
    <div class="modal-body pb-0">
        <h5>Do you want to replace the existing file?</h5>
    </div>
    <div class="modal-footer">
        <button type="button" id="confirm" class="btn btn-primary" (click)="confirmReplaceDocument()">
            {{ 'YES' | translate}} </button>
        <button type="button" id="cancel" class="btn btn-secondary" data-dismiss="modal"
            (click)="cancelReplaceDocument()">
            {{ 'CANCEL' | translate}} </button>
    </div>
</ng-template>