<!--alert class="floating-alert"></alert-->
<nav id="navPanel" class="bg-light border-bottom box-shadow fixed-top navbar-expand-lg">
    <app-header [minimalOption]="true"></app-header>
</nav>

<div class="main-panel pt-0 pb-4"  style="overflow: hidden;">
    <div class="container-fluid">
       <div class="col-sm-12 px-0">
			<h3>
               {{ 'DOCUMENT_TYPE.TITLE' | translate }}
			</h3>
			<hr class="mt-0 mb-3" />
		</div>		
        <div class="col-sm-12 px-0" [style.z-index]="0">
              <div class="col-sm-12 pr-4">
                <button *ngIf="selectedDocType" type="button" class="btn btn-primary btn-sm float-sm-right" (click)="addAttribute()" [disabled] = "!isDocTypeActive || rootSelected">
                            {{ 'DOCUMENT_TYPE.BTN_ADD_ATTRIBUTE' | translate }}
                            </button>
                <button type="button" class="btn btn-outline-dark btn-sm ml-1 mr-1 border-0" (click)="createElement()" [disabled] = "!isDocTypeActive" tooltip=" {{ 'DOCUMENT_TYPE.ADD_TOOLTIP' | translate }}"> 
                    <i class="fas fa-plus fa-sm" aria-hidden="true"></i>
                </button>
                <button type="button" class="btn btn-outline-dark btn-sm ml-1 mr-1 border-0" (click)="renameElement()" [disabled] = "!isDocTypeActive || rootSelected" tooltip="{{ 'DOCUMENT_TYPE.RENAME_TOOLTIP' | translate }}">
                    <i class="fas fa-edit fa-sm" aria-hidden="true"></i>
                </button>
                <button type="button" class="btn btn-outline-dark btn-sm ml-1 mr-1 border-0" (click)="deleteElement()"  [disabled] = "!isDocTypeActive || rootSelected" tooltip="{{ 'DOCUMENT_TYPE.DELETE_TOOLTIP' | translate }}">
                    <i class="fas fa-trash-alt fa-sm" aria-hidden="true"></i>
                </button>
                <button type="button" class="btn btn-outline-dark btn-sm ml-1 mr-1 border-0" (click)="disableDocTypeStatus()" [disabled] = "rootSelected" >
                    <i  *ngIf="isDocTypeActive" class="fas fa-times fa-sm" aria-hidden="true" tooltip="{{ 'DOCUMENT_TYPE.DISABLE_TOOLTIP' | translate }}"></i> 
                    <i  *ngIf="!isDocTypeActive" class="fas fa-check fa-sm" aria-hidden="true" tooltip="{{ 'DOCUMENT_TYPE.ENABLE_TOOLTIP' | translate }}"></i> 
                </button>
            
            </div>
            <as-split [direction]="" [gutterSize]="2" [gutterColor]="" [gutterImageV]="">
                <as-split-area [size]="30">
                    <!--div class="row my-2 mx-1 border-bottom"-->

                        <div class="col-sm-12 px-2 py-1" >
                            <form>
                                <div class="form-group mb-1">
                                    <input class="form-control form-control-sm"
                                        placeholder="{{ 'DOCUMENT_TYPE.SEARCH_DOCUMENT' | translate }}"
                                        (keyup)="searchElement($event)">
                                </div>
                            </form>
                        </div>
                    <!--/div-->
                    <!--Document Type Tree-->
                    <div id="foldertree" class="my-2 mx-1" style="overflow-x: auto;"></div>
                </as-split-area>
                <as-split-area [size]="70">
                    <!--div class="row my-2 mx-1 border-bottom">
                        <div class="col-sm-6 pl-2">
                            <button type="button" class="btn btn-primary" (click)="manageAttributes($event)">
                                {{ 'DOCUMENT_TYPE.MANAGE_ATTRIBUTES' | translate}}</button>
                        </div>
                        <div class="col-sm-6 pr-2">
                        </div>
                    </div-->
                    <div class="card-block card-body mt-2 pb-1 pt-1 card-primary" [class.disabled-contenct]="!isDocTypeActive">
                        <!-- body content -->
                        <!--<ag-grid-angular #agGrid style="height: 300px;width: 100%" class="ag-theme-balham" 
                         [enableFilter]="true" [rowData]="rowData"  [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }" [columnDefs]="columnDefs" rowSelection="multiple" 
                         [rowMultiSelectWithClick]="false" (cellClicked)="onCellClicked($event)"  [floatingFilter]="true"
                         (gridReady)="onGridReady($event)" [pagination]="true" [paginationPageSize]="5" [suppressMenuHide]="true"
                         [suppressRowClickSelection]="true">
                        </ag-grid-angular>-->
                        <ag-grid-angular style="width: 100%; height: 400px;" class="ag-theme-balham" [rowData]="rowData" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }"
                            [gridOptions]="gridOptions" [columnDefs]="columnDefs" [enableRangeSelection]="true" [defaultColDef]="defaultColDef"
                            (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents" [getRowNodeId]="getRowNodeId"
                            [rowDragManaged]="false" [animateRows]="true" (cellValueChanged)="onCellValueChanged($event)" (cellClicked)="onCellClicked($event)">
                        </ag-grid-angular>
                    </div>
                </as-split-area>
            </as-split>
        </div>
    </div>
</div>

<ng-template #docAttributeModal>
      <div class="modal-header">
            <h4 class="modal-title pull-left">{{ 'DOCUMENT_TYPE.BTN_ADD_ATTRIBUTE' | translate }}</h4>
            <button type="button" id="doc_attribut_add_close" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
               <span aria-hidden="true">&times;</span>
            </button>
         </div>
         <div class="modal-body">
            <div class="container">
                  <label class="mb-0">{{ 'DOCUMENT_TYPE.SELECT_ATTRIBUTE' | translate }} :</label>
                  <ng-select  [active]="selectedAttribute" [allowClear]="true"
                     (removed)="removedAttribute($event)" (selected)="selectedAttributeId($event)"
                     [items]="attributeSelectList" name="attribute" id="attribute">
                  </ng-select>
            </div>
         </div>
         <div class="modal-footer">
            <button type="button" id="doc_attribut_add_modal" class="btn btn-secondary btn-sm" 
            (click)="bsModalRef.hide()">{{ 'DOCUMENT_TYPE.CANCEL' | translate}}</button>
         </div>      
  </ng-template>
  
  <!-- dot notation -->
  <ng-template #allCategoryModel>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Select a Category</h4>
        <button type="button" id="image_close" class="close pull-right" aria-label="Close"
            (click)="closeButtonClickEvent()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <app-select-category-tree (categorySelected)="selectedCategory($event)" [categoryType]="categoryType"
    [selectedCategoryItem]="tempCategoryItem" [showIncludeChildren]=false (closeModal)="closeButtonClickEvent()">
    </app-select-category-tree>
</ng-template>