import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DocumentStoreService } from './documentstore.service';
import { UserVariable } from '../../util/common/user-variable';
import { AlertService } from '../../util/alert/alert.service';
import { Router } from '@angular/router';
import { LoadingService } from '../../util/loading/loading.service';

@Component({
   selector: 'delete-documentstore-modal',
   template: `   
   <div class="modal-header">
      <h4 class="modal-title pull-left">{{ title | translate }}</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <div class="container">
         <h6 class="text-center">
            {{ bodyText | translate }}
         </h6>
      </div>
   </div>
   <div class="modal-footer">     
      <button type="button" class="btn btn-primary btn-sm" (click)="deleteDocumentStoreItemClick(documentStoreId); bsModalRef.hide()">{{ 'OK' | translate}}</button>
      <button type="button" class="btn btn-secondary btn-sm" (click)="bsModalRef.hide()">{{ 'CANCEL' | translate}}</button>
   </div>  
   `
})
export class DeleteDocumentStoreModalComponent {
   public title: string = 'Delete Confirm';
   public documentStoreId: string
   private projectId: string = UserVariable.projectId
   public bodyText: string
   public click: string

   constructor(public bsModalRef: BsModalRef, private documentStoreService: DocumentStoreService, private alertService: AlertService, private router: Router, private loadingService: LoadingService) { }

   ngOnit(){      
   }

   deleteDocumentStoreItemClick(documentStoreId) {
      if(this.click == "deleteItem"){
         console.log("deleteDocumentStore");
         this.loadingService.showLoading(true, null, "", null)
         this.documentStoreService.deleteDocumentStore(documentStoreId)
         .subscribe(data => {
            this.loadingService.hideLoading()
            this.alertService.success(data.statusDescription)
            //location.reload()
            this.documentStoreService.tableReloadRequest("", "", "");
         },
         error => {
            this.loadingService.hideLoading()
            this.alertService.error(error.statusDescription)
         });
      }else{
         console.log("deleteDocumentZIPItem");
         this.loadingService.showLoading(true, null, "", null)
         this.documentStoreService.deleteDocumentZIPItem(documentStoreId)
         .subscribe(data => {
            this.loadingService.hideLoading()
            this.alertService.success(data.statusDescription)
            //location.reload();
            this.documentStoreService.tableReloadRequest("", "", "");
         },
         error => {
            this.loadingService.hideLoading()
            this.alertService.error(error.statusDescription)
         });
      }      
   }
}