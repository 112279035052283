import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UserVariable } from '../util/common/user-variable';
import { ServiceUrls } from '../util/service-urls';
import { WsResponse } from '../util/ws-response.model';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map, timeout} from 'rxjs/operators';
import { CommonUtil } from '../util/common/common-util';

@Injectable()
export class ProcedureService {
    constructor(private http: HttpClient, private commonUtil: CommonUtil) {
    }

    getProcedureContent(itemId: String): Observable<WsResponse> {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        const options = { params: new HttpParams().set("token", token) }
        let url = ServiceUrls.PROCEDURES + itemId + '/getProcedureContent.json';
        return this.http.get(url, options).
            pipe(
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                }),
                catchError(error => {
                    var modified = JSON.parse(JSON.stringify(error.error));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    return throwError(res)
                })
            );
    }

    /**
     * Save edited procedure content.
     * @param itemId Procedure Id.
     * @param content Procedure content.
     * @param title Procedure title.
     */
    saveProcedureContent(itemId: String, content: String, title: String): Observable<WsResponse> {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        let url = this.getSaveProcEditorContentURL(itemId, token);
        return this.http.post(url, JSON.stringify(new Request(content, title, null, "save"))).
            pipe(timeout(120000),
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                }),
                catchError(error => {
                    if (error.message == "TimeoutError") {
                        var res = new WsResponse("", "", error.message, "");
                        return throwError(res)
                    }
                    else if (error.status == "0") {
                        var res = new WsResponse("", error.status, "", "");
                        return throwError(res)
                    }
                    else{
                        var modified = JSON.parse(JSON.stringify(error.error));
                        var res = new WsResponse(
                            modified.status.description,
                            modified.status.code,
                            modified.status.name,
                            modified.payload);
                        return throwError(res)
                    }
                })
            );
    }

    /**
     * Checkin edited procedure content.
     * @param itemId Procedure Id.
     * @param content Procedure content.
     * @param title Procedure title.
     * @param comments Checkin comments.
     */
    checkinProcedureContent(itemId: String, content: String, title: String, comments: String): Observable<WsResponse> {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        let url = this.getSaveProcEditorContentURL(itemId, token);
        return this.http.post(url, JSON.stringify(new Request(content, title, comments, "checkin"))).
            pipe(timeout(120000),
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                }),
                catchError(error => {
                    if (error.message == "TimeoutError") {
                        var res = new WsResponse("", "", error.message, "");
                        return throwError(res)
                    }
                    else if (error.status == "0") {
                        var res = new WsResponse("", error.status, "", "");
                        return throwError(res)
                    }
                    else{
                        var modified = JSON.parse(JSON.stringify(error.error));
                        var res = new WsResponse(
                            modified.status.description,
                            modified.status.code,
                            modified.status.name,
                            modified.payload);
                        return throwError(res)
                    }
                })
            );
    }

    /**
     * Checkout procedure by item id and item type.
     * @param itemId Item Id
     * @param itemType Item type
     */
    checkoutProcedure(itemId: String, itemType: String, folderPath: String) {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        let url = this.getcheckoutDocumentByIdURL(token);
        return this.http.post(url, JSON.stringify(new CRequest(itemId, itemType, folderPath))).
            pipe(
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                }),
                catchError(error => {
                    var modified = JSON.parse(JSON.stringify(error.error));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    return throwError(res)
                })
            );
    }

    /**
     * Undo checkout procedure by id.
     * @param procId procedure Id
     */
    undoCheckoutProcedure(procId: String, path: String) {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        let url = this.getundoCheckoutProcedure(procId, token);
        return this.http.post(url, {folderPath : path}).
            pipe(
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                }),
                catchError(error => {
                    var modified = JSON.parse(JSON.stringify(error.error));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    return throwError(res)
                })
            );
    }

    public getSaveProcEditorContentURL(itemId: String, token: String) {
        let url = ServiceUrls.PROCEDURES + itemId + '/saveProcedureContent.json' + '?token=' + token;
        return url;
    }

    /**
    * Define URL for checkout document by id.
    */
    private getcheckoutDocumentByIdURL(token: String) {
        let url = ServiceUrls.CM_SYSTEM_DOCUMENTS + 'checkoutDocumentById.json?token=' + token;
        return url;
    }

    /**
    * Define URL for undo checkout document by id.
    */
    private getundoCheckoutProcedure(procId: String, token: String) {
        let url = ServiceUrls.PROCEDURES + procId + '/undocheckoutProcedure.json?token=' + token;
        return url;
    }

    getCategoryList(catType: string): Observable<WsResponse> {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        let url = ServiceUrls.getCategoryListByCategoryType(catType)
        const options = { params: new HttpParams().set("token", token).set("categoryType", catType) }	
        return this.http.get(url, options).pipe(
            map(response => {
                var modified = JSON.parse(JSON.stringify(response));
                return new WsResponse(
                    modified.status.code,
                    modified.status.name,
                    modified.status.description,
                    modified.payload);
            }),
            catchError(error => {
                var modified = JSON.parse(JSON.stringify(error.error));
                var res = new WsResponse(
                    modified.status.code,
                    modified.status.name,
                    modified.status.description,
                    modified.payload);
                return throwError(res)
            })
        );
    }

    getOperatingDataList(itemId: String): Observable<WsResponse> {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        const options = { params: new HttpParams().set("token", token) }
        let url = ServiceUrls.getOperatingDataList(token);
        return this.http.get(url, options).
            pipe(
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                }),
                catchError(error => {
                    var modified = JSON.parse(JSON.stringify(error.error));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    return throwError(res)
                })
            );
    }
}

class Request {
    constructor(private content: String, private title: String, private comments: String, private action: String) { }
}

class CRequest {
    constructor(private itemId: String, private itemType: String, private content: String) { }
}
