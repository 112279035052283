<!-- <div class="container-fluid px-0 pb-5" style="overflow-y: auto; overflow-x: hidden" [style.height.px]="divHeight"> -->
<div class="flex-row" id="proc_editor" style="overflow-y: auto; overflow-x: hidden" [style.height.px]="divHeight">
    <div class="container-fluid my-2">
    <!-- breadcrumb -->

          <nav aria-label="breadcrumb my-1">
            <ol class="breadcrumb my-0 px-2 py-1">
                <li class="breadcrumb-item active" *ngFor="let breadCrumbListItem of breadCrumbList ; let i = index"   aria-current="page" (click)="clickBreadCrumb(breadCrumbListItem,i)">
                   {{ breadCrumbListItem.title}}
                   <!-- <div [innerHTML]="breadCrumbListItem.title"></div> -->
                </li>
                <li class="currentItem breadcrumb-item">{{topicNameShowInDetails}}</li>
            </ol>
          </nav>

    <!-- topic details -->
    <div class="card my-2">
      <div class="card-header py-1">
        <p class="col-sm-7 my-1 float-left h6  px-0">
          {{ 'ICE_TOPIC.TOPIC_DETAILS' | translate }}
          <span *ngIf="!isShowIceTopicDetails && topicNameShowInDetails != null" class="ml-2" STYLE="font-size: 10pt" > | {{topicNameShowInDetails}}</span>
        </p>
        <button type="button" class="m-0 close float-right" (click)="showIceTopicDetails(isShowIceTopicDetails)">
          <i class="far"
            [ngClass]="{'fa-angle-up': isShowIceTopicDetails, 'fa-angle-down': !isShowIceTopicDetails}"></i>
        </button>
        <button class="btn btn-sm btn-dark float-right mx-2" type="button" 
					(click)="topicDetailSave()" [disabled]="isViewOnly || !topicEnabled || !isShowIceTopicDetails" tooltip ="Save Topic Menu" >
					<i class="fal fa-save"></i>
				</button>
				<button class="btn btn-sm btn-dark float-right mx-2" type="button" 
					(click)="editTopic()" [disabled]="isViewOnly || topicEnabled" tooltip ="Edit Topic Menu" >
					<i class="far fa-edit"></i>
        </button>
        <button  [disabled]="isViewOnly || haveChildItems" class="btn btn-sm btn-dark float-right mx-2" type="button"
				(click)="addAssociateCourse(associateCorseModel)" tooltip ="Link Course">
				<i class="fas fa-link"></i> <!--<i class="fas fa-link"></i>-->
      </button>
      <button class="btn btn-sm btn-dark float-right mx-2" type="button" 
      (click)="openCancelConfirmationModel(closeConfirmModel)" tooltip ="Cancel" >
      <i class="fas fa-times"></i>
    </button>
      </div>
      <div *ngIf="isShowIceTopicDetails" class="card-body">
        <form ngNativeValidate>
          <div class="form-group">
            <label for="topicTitle">
              {{ 'ICE_TOPIC.TOPIC.TITLE_LABEL' | translate }}
            </label>
            <div class="badge-required mx-2"></div>
            <div class="border rounded" (click)="titleClick()">
                <div rows="2" contenteditable="false"
                  class="toggle-edit{{tabId}}{{userId}} ckeditor_topic_title{{tabId}} topic_ck_editor title_ck_editor{{tabId}} teditor" required id="topicTitle" name="topicTitle">
                </div>
            </div>
            <!--<input type="text" class="form-control form-control-sm" id="topicTitle" name="topicTitle" [(ngModel)]="topic.topicName"
              placeholder="{{ 'ICE_TOPIC.TOPIC.TITLE_PLACEHOLDER' | translate }}" required required [disabled]="isViewOnly">-->
          </div>
          <div class="form-group">
            <label for="topicDescription">
              {{ 'ICE_TOPIC.TOPIC.DESCRIPTION_LABEL' | translate }}
            </label>
            <!--<div class="badge-required mx-2"></div>-->
            <div class="border rounded" (click)="descriptionClick()">
              <div rows="2" contenteditable="false" 
                class="toggle-edit{{tabId}}{{userId}} ckeditor_topic_description{{tabId}} topic_ck_editor desc_ck_editor{{tabId}} teditor" required id="topicDescription" name="topicDescription">
              </div>
            </div>
            <!-- <textarea class="form-control form-control-sm" id="topicDescription" name="topicDescription" [(ngModel)]="topic.description" rows="2"
              placeholder="{{ 'ICE_TOPIC.TOPIC.DESCRIPTION_PLACEHOLDER' | translate }}" required required [disabled]="isViewOnly"> </textarea> -->
          </div>
          <div *ngIf="linkedCourse != null" class="form-horizontal">
              <label for="associateCourse">
                {{ 'ICE_TOPIC.TOPIC.ASSOCIATE_COURSE_ID' | translate }}
              </label>  
              <div class="input-group pb-3">
                  <input class="form-control form-control-sm" id="associateCourse" name="courseDescription" [(ngModel)]="linkedCourse" rows="2" 
                  required required [disabled]="true">
                  <span class="input-group-btn pt-1">
                      <button  [disabled]="isViewOnly || haveChildItems" (click)="addAssociateCourse(associateCorseModel)" tooltip ="Edit" class="btn btn-sm btn-dark float-right mx-2" 
                      type="submit">
                          <i class="far fa-edit"></i>
                      </button>
                  </span>
              </div>
          </div>
          <div class="form-group">
            <div class="col-sm-11">
              <div class="row">
                <div class="checkbox float-center">
                  <label class="customcheck">
                  <input [checked]="topic.topicImage != null ? true: false" type="checkbox"
                  class="form-control form-control-sm form-check-input" name="topicImage"
                  id="topicImage" (click) = "ifChecked()">
                  <span class="checkmark" style="padding-top: 3px;" [style.background] = "imageBackground"
                  [style.border] = "imageBorder"></span>
                  </label>	
                </div>
                <label for="topicImage"> 
                {{ 'ICE_COURSE.COURSE.IMAGE_LABEL' | translate }}
                <small>{{ 'ICE_COURSE.COURSE.LBL_IMAGE_SIZE' | translate }}</small>
                </label>
              </div>
              <div class="row">
                <div *ngIf="topic.topicImage != null" class="card" style="width: 8rem;">
                    <div class="card-img-overlay py-0 pl-0 pr-1" (click)="showLargeImage(largeImage, topic.topicImage.id)">
                        <button type="button" class="close float-right" aria-label="Close"
                          (click)="$event.stopPropagation();removeTopicImage(); ifChecked()" [disabled]="isViewOnly || !topicEnabled">
                          <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                  <img class="img-fluid"  style="width: 8rem;"  src="data:image/jpeg;base64,{{topic.topicImage.thumbnailImage}}"
                  alt="{{topic.topicImage.title}}">
                </div>
              </div>
            </div>
          </div>
          <!--<hr class="mt-3 mb-2" />
          <div class="form-group">
            <button class="btn btn-sm btn-warning float-right" (click)="topicDetailSave()" [disabled]="isViewOnly">Save</button>
          </div>-->
        </form>
      </div>
      
    </div>


  <!-- ICE Topic list -->
	<div class="card my-3">
      <div class="card-header py-1 stick-to-top">
        <p class="col-sm-5 my-1 float-left h6 px-0">
          <span class="badge badge-warning mr-2">{{iceTopicList.length}}</span>
          {{ 'ICE_TOPIC.ICE_TOPICS' | translate }}
        </p>
        <button *ngIf="(topic.id != '0')" id="showMoreIceTopic" type="button" class="my-1 mx-0 close float-right"
          (click)="showICETopic(isShowIceTopic)">
          <i class="far" [ngClass]="{'fa-angle-up': isShowIceTopic, 'fa-angle-down': !isShowIceTopic}"></i>
        </button>
        <button *ngIf="(topic.id != '0')" id="addNewIceTopic" type="button" class="btn btn-sm btn-dark mx-2 float-right"
          (click)="addNewIceTopic()" [disabled]="isViewOnly || !topicsEnabled ||  topic.linkedCourseId != null" tooltip ="Add Topic Menu"
          [placement]="isShowIceTopic ? 'bottom' : 'top'">
          <i class="far fa-plus"></i>
        </button>
        <button *ngIf="(topic.id != '0')" class="btn btn-sm btn-dark float-right mx-2" type="button" 
         (click)="topicsSave(true)" [disabled]="isViewOnly || !topicsEnabled || !isShowIceTopic" tooltip ="Save Topic Menus"
         [placement]="isShowIceTopic ? 'bottom' : 'top'">
         <i class="fal fa-save"></i>
        </button>
        <button *ngIf="(topic.id != '0')" class="btn btn-sm btn-dark float-right mx-2" type="button" 
          (click)="editTopics()" [disabled]="isViewOnly || topicsEnabled || iceTopicContentList.length > 0 || topic.linkedCourseId != null" 
          tooltip ="Edit Topic Menus" [placement]="isShowIceTopic ? 'bottom' : 'top'" >
          <i class="far fa-edit"></i>
        </button>
      </div>
      <div *ngIf="isShowIceTopic" class="card-body p-2">
        <div *ngFor="let iceTopic of iceTopicList | orderBy : 'orderNum'; let t = index"
          class="card card-secondary my-2">
          <div class="card-body container-fluid p-1">
            <div class="">
              <div class="col-sm-9 float-left pl-2 pr-0">
                  <div class="border rounded">
                      <div rows="2" contenteditable="false" id="{{iceTopic.id}}"
                        class="toggle-edit{{tabId}}{{userId}} t_ck_editor{{iceTopic.sequence}}{{tabId}} teditor" >
                      </div>
                  </div>
                <!--<input type="text" class="form-control form-control-sm topiclisttitle" id="iceTopicTitle"
                  [value]="iceTopic.topicName"
                  [(ngModel)]="iceTopic.topicName"
                  name = "iceTopicTitle"
                  placeholder="{{ 'ICE_TOPIC.TOPIC.TITLE_PLACEHOLDER' | translate }}" [disabled]="isViewOnly">-->
              </div>
              <div class="col-sm-3 float-right px-0">
                <button id="btnGotoTopic" *ngIf="iceTopic.id!='0'" type="button" class="my-1 mx-3 close float-right"
                  (click)="showInfoIceTopic(iceTopic)">
                  <i class="far fa-angle-right"></i>
                </button>
                <button id="btnDeleteTopic" type="button" class="my-1 mx-2 close float-right"
                  (click)="deleteIceTopic(iceTopic, t, deleteTopicModel)" [disabled]="isViewOnly || !topicsEnabled">
                  <i class="far fa-trash-alt"></i>
                </button>
                <button *ngIf="(iceTopicList.length > 1) && (t != 0) && iceTopic.id!='0'" id="btnMoveUpTopic" type="button"
                  class="my-1 mx-2 close float-right" (click)="changePositionIceTopic(iceTopic, t, 'up')" [disabled]="isViewOnly || !isEnableReordering || !topicsEnabled">
                  <i class="far fa-arrow-up"></i>
                </button>
                <button *ngIf="(iceTopicList.length > 1) && (t != (iceTopicList.length - 1)) && iceTopic.id!='0' && !(t + 1 >= iceTopicList.length) && (iceTopicList[t+1].id!='0')"
                  id="btnMoveDownTopic" type="button" class="my-1 mx-2 close float-right"
                  (click)="changePositionIceTopic(iceTopic, t, 'down')" [disabled]="isViewOnly || !isEnableReordering || !topicsEnabled">
                  <i class="far fa-arrow-down"></i>
                </button>
                <!--<button id="btnCopyTopic" type="button" class="my-1 mx-2 close float-right"
                  (click)="copyOfIceTopic(iceTopic)"
                  [ngClass]="{'clone': iceTopic.copyTopicId != '', 'not-clone': iceTopic.copyTopicId == ''}" [disabled]="isViewOnly">
                  <i class="far fa-clone"></i>
                </button>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- ICE Topic Content list -->
    <div class="card my-3">
      <div class="card-header py-1 stick-to-top">
        <p class="col-sm-5 my-1 float-left h6 px-0">
          <span class="badge badge-warning mr-2">{{iceTopicContentList.length}}</span>
          {{ 'ICE_TOPIC.ICE_CONTENTS' | translate }}
        </p>
        <button *ngIf="(topic.id != '0')" id="showMoreIceTopicContents" type="button" class="my-1 mx-0 close float-right"
          (click)="showIceTopicContents(isShowIceTopicContents)">
          <i class="far" [ngClass]="{'fa-angle-up': isShowIceTopicContents, 'fa-angle-down': !isShowIceTopicContents}"></i>
        </button>
        <button *ngIf="(topic.id != '0')" id="addNewIceTopicContent" type="button" class="btn btn-sm btn-dark mx-2 float-right"
          (click)="addNewIceTopicContent()" [disabled]="isViewOnly || !topicContentsEnabled ||  topic.linkedCourseId != null" 
          tooltip ="Add Bullet" [placement]="isShowIceTopicContents ? 'bottom' : 'top'">
          <i class="far fa-plus"></i>
        </button>
        <button *ngIf="(topic.id != '0')" class="btn btn-sm btn-dark float-right mx-2" type="button" 
         (click)="topicContentsSave(true)" [disabled]="isViewOnly || !topicContentsEnabled || !isShowIceTopicContents"
         tooltip ="Save Bullets" [placement]="isShowIceTopicContents ? 'bottom' : 'top'">
         <i class="fal fa-save"></i>
        </button>
        <button *ngIf="(topic.id != '0')" class="btn btn-sm btn-dark float-right mx-2" type="button" 
          (click)="editTopicContents()" [disabled]="isViewOnly|| topicContentsEnabled || iceTopicList.length > 0 || topic.linkedCourseId != null" 
          tooltip ="Edit Bullets" [placement]="isShowIceTopicContents ? 'bottom' : 'top'" >
          <i class="far fa-edit"></i>
			  </button>
      </div>
      <div *ngIf="isShowIceTopicContents" class="card-body p-2">
        <div *ngFor="let iceTopicContent of iceTopicContentList | orderBy : 'orderNum'; let tb = index"
          class="card card-secondary my-2">
          <div class="card-body container-fluid p-1">
            <div class="">
              <div class="col-sm-9 float-left pl-2 pr-0">
                  <div class="border rounded">
                      <div rows="2" contenteditable="false" id="{{iceTopicContent.id}}"
                        class="toggle-edit{{tabId}}{{userId}} tc_ck_editor{{iceTopicContent.sequence}}{{tabId}} tceditor" >
                      </div>
                  </div>
                <!--<input type="text" class="form-control form-control-sm" id="iceTopicContentTitle"
                  [value]="iceTopicContent.topicContentName"
                  [(ngModel)]="iceTopicContent.topicContentName"
                  name = "iceTopicContentTitle"
                  placeholder="{{ 'ICE_TOPIC_CONTENT.TITLE_PLACEHOLDER' | translate }}" [disabled]="isViewOnly">-->
              </div>
              <div class="col-sm-3 float-right px-0">
                <button *ngIf="iceTopicContent.id!='0'" id="btnGotoTopicContent" type="button" class="my-1 mx-3 close float-right"
                  (click)="showInfoIceTopicContent(iceTopicContent)">
                  <i class="far fa-angle-right"></i>
                </button>
                <button id="btnDeleteTopicContent" type="button" class="my-1 mx-2 close float-right"
                  (click)="deleteIceTopicContent(iceTopicContent, tb, deleteTopicContentModel)" [disabled]="isViewOnly|| !topicContentsEnabled">
                  <i class="far fa-trash-alt"></i>
                </button>
                <button *ngIf="(iceTopicContentList.length > 1) && (tb != 0) && iceTopicContent.id!='0'" id="btnMoveUpTopic" type="button"
                  class="my-1 mx-2 close float-right" (click)="changePositionIceTopicContent(iceTopicContent, 'up',tb)" [disabled]="isViewOnly || isShowIceTopicContentReorderButton|| !topicContentsEnabled" >
                  <i class="far fa-arrow-up"></i>
                </button>
                <button *ngIf="(iceTopicContentList.length > 1) && (tb != (iceTopicContentList.length - 1)) && iceTopicContent.id!='0' && !(tb + 1 >= iceTopicContentList.length) && (iceTopicContentList[tb+1].id!='0')"
                  id="btnMoveDownTopicContent" type="button" class="my-1 mx-2 close float-right"
                  (click)="changePositionIceTopicContent(iceTopicContent, 'down',tb)" [disabled]="isViewOnly || isShowIceTopicContentReorderButton || !topicContentsEnabled">
                  <i class="far fa-arrow-down"></i>
                </button> 
                <!--<button id="btnCopyTopicContent" type="button" class="my-1 mx-2 close float-right"
                  (click)="copyOfIceTopicContent(iceTopicContent)"
                  [ngClass]="{'clone': iceTopicContent.copyTopicContentId != '', 'not-clone': iceTopicContent.copyTopicContentId == ''}" [disabled]="isViewOnly">
                  <i class="far fa-clone"></i>
                </button>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  <!-- ICE Learning Check list -->
  <div class="card my-3"> 
    <div class="card-header py-1 stick-to-top">
			<p class="col-sm-5 my-1 float-left h6 px-0">
				<span class="badge badge-warning mr-2"  >{{iceLearningCheckList.length}}</span>
				{{ 'ICE_TOPIC.LEARNING_CHECKS' | translate }}
      </p>
			<button *ngIf="(topic.id != '0')" id="showMoreLearningCheck" type="button" class="my-1 mx-0 close float-right"
				(click)="showICELearningCheck(isShowLearningChecks)"> 
				<i class="far"
					[ngClass]="{'fa-angle-up': isShowLearningChecks, 'fa-angle-down': !isShowLearningChecks}"></i>
      </button>
			<button *ngIf="(topic.id != '0')" id="addNewLearningCheck" type="button" class="btn btn-sm btn-dark mx-2 float-right"
        (click)="addNewLearningCheck()" [disabled]="isViewOnly || !learningCheckEnabled ||  topic.linkedCourseId != null" 
        tooltip ="Add Learning Checks" [placement]="isShowLearningChecks ? 'bottom' : 'top'"> 
				<i class="far fa-plus"></i>
      </button>
			<button *ngIf="(topic.id != '0')" class="btn btn-sm btn-dark float-right mx-2" type="button" 
        (click)="learningChecksSave(true)" [disabled]="isViewOnly || !learningCheckEnabled || !isShowLearningChecks"
        tooltip ="Save Learning Checks" [placement]="isShowLearningChecks ? 'bottom' : 'top'" > 
				<i class="fal fa-save"></i>
      </button>
			<button *ngIf="(topic.id != '0')" class="btn btn-sm btn-dark float-right mx-2" type="button" 
        (click)="editLearningChecks()" [disabled]="isViewOnly || learningCheckEnabled || topic.linkedCourseId != null"
        tooltip ="Edit Learning Checks" [placement]="isShowLearningChecks ? 'bottom' : 'top'" >
				<i class="far fa-edit"></i>
      </button>
      <button *ngIf="(topic.id != '0')" class="btn btn-sm btn-dark float-right mx-2" type="button" 
        (click)="copyLearningCheck(copyLearningCheckModal)" [disabled]="isViewOnly || topic.linkedCourseId != null"
        tooltip ="Copy Learning Checks" [placement]="isShowLearningChecks ? 'bottom' : 'top'" >
				<i class="fas fa-copy" aria-hidden="true"></i>
			</button>
    </div>
		<div *ngIf="isShowLearningChecks" class="card-body p-2">
			<div *ngFor="let learningCheck of iceLearningCheckList | orderBy : 'orderNum'; let l = index"
				class="card card-secondary my-2">
				<div class="card-body container-fluid p-1">
					<div class="col-sm-12 px-0 float-left">
						<div class="col-sm-9 float-left pl-2 pr-0">
                <div class="border rounded">
                    <div rows="2" contenteditable="false" id="{{learningCheck.id}}"
                      class="toggle-edit{{tabId}}{{userId}} l_ck_editor{{learningCheck.sequence}}{{tabId}} leditor">
                    </div>
                </div> 
						</div>
						<div class="col-sm-3 float-right px-0">
							<button id="btnGotoLearningCheck" type="button" class="my-1 mx-3 close float-right"
								(click)="showInfoIceLearningCheck(learningCheck, 'null')">
								<i class="far fa-angle-right"></i>
							</button>
							<button id="btnDeleteLearningCheck" type="button" class="my-1 mx-2 close float-right"
              (click)="deleteIceLearningCheck(learningCheck, l, deleteLearningCheckModel)"
              [disabled]="isViewOnly || !learningCheckEnabled">
								<i class="far fa-trash-alt"></i>
							</button>
							<button *ngIf="(iceLearningCheckList.length > 1) && (l != 0)" id="btnMoveUpLearningCheck"
								type="button" class="my-1 mx-2 close float-right"
                (click)="changePositionLearningCheck(learningCheck, l, 'up')"
                [disabled]="isViewOnly || !isEnableReordering || !learningCheckEnabled">
								<i class="far fa-arrow-up"></i>
							</button>
							<button *ngIf="(iceLearningCheckList.length > 1) && (l != (iceLearningCheckList.length - 1)) && learningCheck.id!='0' && !(l + 1 >= iceLearningCheckList.length) && (iceLearningCheckList[l+1].id!='0')"
								id="btnMoveDownLearningCheck" type="button" class="my-1 mx-2 close float-right"
                (click)="changePositionLearningCheck(learningCheck, l, 'down')"
                [disabled]="isViewOnly || !isEnableReordering || !learningCheckEnabled">
								<i class="far fa-arrow-down"></i>
							</button>
						</div>
					</div>
        </div>
        <!-- <div class="row pl-4">
          <div *ngFor="let datas of learningCheck.data | orderBy : 'orderNum'; let l = index">
            <label style="cursor: pointer" class="my-1 mx-2 h5" (click)="showInfoIceLearningCheck(learningCheck, datas.id)">
              <span class="badge badge-secondary">{{l+1}}</span>
            </label>
          </div>
        </div> -->
			</div>
		</div>
	</div>
  
  <!-- Games List -->
	<div class="card my-3">
      <div class="card-header py-1 stick-to-top">
        <p class="col-sm-5 my-1 float-left h6 px-0">
          <span class="badge badge-warning mr-2">{{gamesCount}}</span>
          {{ 'ICE_COURSE.ICE_GAMES' | translate }}
        </p>
        <button *ngIf="(topic.id != '0')" id="showMoreIceGames" type="button"
          class="my-1 mx-0 close float-right" (click)="showICEGames(isShowIceGames)">
          <i class="far" [ngClass]="{'fa-angle-up': isShowIceGames, 'fa-angle-down': !isShowIceGames}"></i>
        </button>
        <div *ngIf="(topic.courseId != '0')" class="dropdown">
          <button class="btn btn-sm btn-dark mx-4 float-right dropdown-toggle" type="button" id="addNewIceGames"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [disabled]="isViewOnly || !gameEnabled" style="margin-left: 0.0rem!important;margin-right: 0.5rem!important;">
            <i class="far fa-plus"></i>
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" (click)="addNewIceGame('EIG')">Einstein (Instructor) Game</a>
            <a class="dropdown-item" (click)="addNewIceGame('MG')">Memory (Instructor) Game</a>
            <a class="dropdown-item" (click)="addNewIceGame('SMSG')">Speed Match (Student) Game</a>
            <a class="dropdown-item" (click)="addNewIceGame('WAMG')">Whack a Mole (Instructor) Game</a>
          </div>
        </div>
        <button *ngIf="(topic.id != '0')" class="btn btn-sm btn-dark float-right mx-2" type="button" 
          (click)="iceGameListSave(true)" [disabled]="isViewOnly || !gameEnabled || !isShowIceGames"
          tooltip ="Save Games" [placement]="isShowIceGames ? 'bottom' : 'top'" style="margin-right: 0.3rem!important;">
          <i class="fal fa-save"></i>
        </button>
        <button *ngIf="(topic.id != '0')" class="btn btn-sm btn-dark float-right mx-2" type="button"
          (click)="editGame()" [disabled]="isViewOnly || gameEnabled ||  topic.linkedCourseId != null"
          tooltip="Edit Games" [placement]="isShowIceGames ? 'bottom' : 'top'">
          <i class="far fa-edit"></i>
        </button>
        <button *ngIf="(topic.id != '0')" class="btn btn-sm btn-dark float-right mx-2" type="button" 
        (click)="copyGame(copyGamesModal)" [disabled]="isViewOnly || topic.linkedCourseId != null"
        tooltip ="Copy Games" [placement]="isShowIceGames ? 'bottom' : 'top'" >
				<i class="fas fa-copy" aria-hidden="true"></i>
			</button>
      </div>
      <div *ngIf="isShowIceGames" class="card-body p-2">
        <div *ngFor="let iceGameInfo of iceGameList; let ga = index" class="card card-secondary my-2">
          <div class="card-body container-fluid p-1 gamelistdiv">
            <div class="">
                <div class="col-sm-10 float-left px-0">
                  <div class="input-group input-group-sm float-left">
                    <div class="input-group-prepend input-game-aligned">
                      <span class="input-group-text" id="basic-addon1">
                        {{getStringFromType(iceGameInfo.gameType)}}
                      </span>
                    </div>
                    <div class="col-sm-9 float-left pl-0 pr-0">
                      <div class="border rounded">
                        <div rows="2"  contenteditable="false" id="{{iceGameInfo.id}}"
                          class="toggle-edit{{tabId}}{{userId}} games_ck_editor{{iceGameInfo.id}}{{tabId}} gaeditor">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              <div class="col-sm-2 float-right px-0">
                <button [disabled]="(iceGameInfo.gameType == 'WAMG')" [style.color]="iceGameInfo.gameType == 'WAMG'? 'transparent':''" id="btnGotoIceGame+{{iceGameInfo.id}}" type="button" class="my-1 mx-3 close float-right"
                  (click)="showInfoIceGame(iceGameInfo)">
                  <i class="far fa-angle-right"></i>
                </button>
                <button id="btnDeleteGame+{{iceGameInfo.id}}" type="button" class="my-1 mx-2 close float-right"
                  (click)="deleteIceGame(iceGameInfo, ga, deleteGameModel)" [disabled]="isViewOnly || !gameEnabled">
                  <i class="far fa-trash-alt"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
</div>
<!-- Template for the model-->
 <ng-template #deleteTopicModel>

    <div class="modal-header">
        <div *ngIf="isDeleteEnable" >
            <h4 class="modal-title pull-left">{{ 'ICE_TOPIC.DELETE_TOPIC' | translate }} </h4>
        </div>
        <div *ngIf="!isDeleteEnable" >
            <h4 class="modal-title pull-left">{{ 'ICE_TOPIC.CANNOT_DELETE_TOPIC' | translate }} </h4>
        </div>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>    

  <div *ngIf="!isDeleteEnable" class="modal-body">
      <div class="card p-0">          
          <div class="alert alert-warning" role="alert">
              <ul>
                  <li class="m-1">
                      <label>Topic Menu Dependencies : {{topicDependencies}}</label> 
                  </li>
                  <li class="m-1">
                      <label>Bullet Dependencies : {{topicContentDependencies}}</label>
                  </li>
              </ul>
          </div>
      </div> 
  </div>
  
    <div class="modal-footer">
      <button type="button" class="btn btn-default" (click)="modalRef.hide()">{{ 'CANCEL' | translate}}</button>
      
        <button *ngIf="isDeleteEnable" type="button" class="btn btn-warning" (click)="modalRef.hide();confirmDeleteTopic();">{{'OK' | translate}}</button>
    </div>
</ng-template>

<!-- Template for delete ice learning checks-->
<ng-template #deleteLearningCheckModel>

	<div class="modal-header">
		<div *ngIf="isLCDeleteEnable">
			<h4 class="modal-title pull-left">{{ 'ICE_LEARNING_CHECK.DELETE_LEARNINGCHECK' | translate }} </h4>
		</div>
		<div *ngIf="!isLCDeleteEnable">
			<h4 class="modal-title pull-left">{{ 'ICE_LEARNING_CHECK.CANNOT_DELETE_LEARNINGCHECK' | translate }} </h4>
		</div>
		<button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>

	<div *ngIf="!isLCDeleteEnable" class="modal-body">
		<div class="card p-0 alert alert-warning" role="alert">
			<ul>
				<li class="m-1">
					<label>{{questionDependencies}}</label>
				</li>
			</ul>
		</div>
	</div>

	<div class="modal-footer">
		<button type="button" class="btn btn-default" (click)="modalRef.hide()">{{ 'CANCEL' | translate}}</button>

		<button *ngIf="isLCDeleteEnable" type="button" class="btn btn-warning"
			(click)="modalRef.hide();confirmDeleteLearningChecks();">{{'OK' | translate}}</button>
	</div>
</ng-template>

<!-- Template for the delete ICE Game item model-->
<ng-template #deleteGameModel>
    <div class="modal-header">
      <div *ngIf="isDeleteEnable">
        <h4 class="modal-title pull-left">{{ 'ICE_GAME.DELETE_GAME' | translate }} </h4>
      </div>
      <div *ngIf="!isDeleteEnable">
        <h4 class="modal-title pull-left">{{ 'ICE_GAME.CANNOT_DELETE_GAME' | translate }} </h4>
      </div>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-default" (click)="modalRef.hide()">{{ 'CANCEL' | translate}}</button>
      <button *ngIf="isDeleteEnable" type="button" class="btn btn-warning"
        (click)="modalRef.hide();confirmDeleteGame();">{{'OK' | translate}}</button>
    </div>
  </ng-template>

<!-- hidden buttons to handle toolbar special char and fraction-->
<button tooltip="{{ 'WEB_EDITOR.ADD_SPE_CHAR' | translate }}" id={{addSpecialCharDivId}} container="body"
	type="button" (click)="openModal(spec_char)" hidden="true"
	class="btn btn-outline-primary btn-sm ml-1 mr-1">
	<i class="fas fa-omega" aria-hidden="true"></i>
</button>
<button tooltip="{{ 'WEB_EDITOR.ADD_FRACTION' | translate }}" id={{addFractionDIvId}} container="body"
	type="button" (click)="openModal(fraction)" hidden="true"
	class="btn btn-outline-primary btn-sm ml-1 mr-1">
	<i class="fas fa-divide" aria-hidden="true"></i>
</button>

<!-- Template for topic content model-->
<ng-template #deleteTopicContentModel>

  <div class="modal-header">
      <h4 class="modal-title pull-left">{{ 'ICE_TOPIC_CONTENT.DELETE_TOPIC_CONTENT' | translate }} </h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="modalRef.hide()">{{ 'CANCEL' | translate}}</button>
    
      <button type="button" class="btn btn-warning" (click)="modalRef.hide();confirmDeleteTopicContent();">{{'OK' | translate}}</button>
  </div>
</ng-template>

<!-- special characters modal -->
<ng-template #spec_char>
  <div class="modal-header">
      <h4 class="modal-title pull-left">{{ 'WEB_EDITOR.SPE_CHAR' | translate}}</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <div class="container-fluid">
          <div class="row">
              <div class="col-md-2" *ngFor="let speChar of speCharList; let n=index">
                  <button type="button" class="btn default" (click)="addSpecialCharacter(speChar)"
                      style="background-color: inherit;">
                      {{speChar.data.symbol}}</button>
              </div>
          </div>
      </div>
  </div>
  <!-- <div class="modal-footer">
      <label>{{ 'WEB_EDITOR.DEC_INPUT' | translate}}</label>
      <input (keypress)="numberOnly($event)" class="form-control input-sm" #dec_value type="text">
      <button type="button" class="btn btn-primary" (click)="addSpeChar(dec_value.value)">
          <i class="fa fa-plus" aria-hidden="true"></i>
      </button>
  </div> -->
</ng-template>

<!--fraction modal-->
<ng-template #fraction>
    <div class="modal-header p-2">
      <h4 class="modal-title pull-left">{{ 'WEB_EDITOR.FRACTION' | translate}}</h4>
      <button type="button" class="close pull-right p-0 m-0" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="container-fluid">
        <div class="row">
          <div class="input-group input-group-sm ">
            <div class="input-group-prepend">
              <span class="input-group-text">Numerator / Denominator</span>
            </div>
            <input (keypress)="numberOnly($event)" #dec_value type="text" aria-label="Numerator"
              class="form-control" [(ngModel)]="numerator">
            <input (keypress)="numberOnly($event)" #dec_valueD type="text" aria-label="Denominator"
              class="form-control" [(ngModel)]="denominator">
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer p-2">                     
      <div class="col-sm-12 px-0"> 
        <div class="badge badge-warning float-left" *ngIf="numerator != '' && denominator != '' && numerator != null && denominator != null">
          <span class="h6 m-0">{{numerator}} / {{denominator}}</span>
        </div>
        <button type="button" class="btn btn-secondary btn-sm float-right mx-1" (click)="modalRef.hide()">{{ 'CANCEL' |
                  translate}}</button>
        <button type="button" class="btn btn-primary btn-sm float-right mx-1"
          (click)="modalRef.hide();addFraction(dec_value.value,dec_valueD.value);">{{
                  'ADD' | translate}}</button>
      </div>
    </div>
  </ng-template>

<ng-template #largeImage>
  <div class="modal-header">
      <h4 class="modal-title pull-left">{{ selectedImage.title }}</h4>
      <button type="button" id="image_close" class="close pull-right" aria-label="Close" (click)="modelRefCloseEvent()">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <div class="card p-0">
          <img class="card-img-top" src="data:image/jpeg;base64,{{selectedImage.largeImage}}">
      </div>
  </div>
  <div class="modal-footer">
      <button type="button" class="btn btn-sm btn-secondary" (click)="modelRefCloseEvent()">{{
              'IMAGE_SEARCH_VIEW.BTN_TEXT_CLOSE' | translate }}</button>
  </div>
</ng-template>

<ng-template #associateCorseModel>

  <div class="modal-header">
      <div >
          <h4 class="modal-title pull-left">{{ 'ICE_TOPIC.ASSOCIATE_ICE_COURSE' | translate }} </h4> 
      </div>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()"> 
          <span aria-hidden="true">&times;</span>
      </button>
  </div>    
<div *ngIf="iceCoursesAvailable" class="link-model">
    <div class="modal-body">
        <div id="link-model-card" class="card p-0">          
            <div *ngFor="let course of iceCourseList; let n=index">
                <div [ngClass]="{'card bg-secondary text-white': course.courseId == selectedIceCourse}">
                    <div *ngIf="course.courseId != topic.courseId"
                    (click)="selectCourse(course.courseId)" class="card-header">{{course.fileName}}
                   </div><!--link id and click object id || click id || -->
                </div>
            </div>
        </div> 
    </div>
</div>

  <div class="modal-footer">
    <button type="button" class="btn btn-warning mr-auto" (click)="modalRef.hide();clearSelected();">{{'CLEAR' | translate}}</button>
    <button type="button" class="btn btn-warning" (click)="modalRef.hide();confirmSelected();">{{'OK' | translate}}</button> 
    <button type="button" class="btn btn-default" (click)="modalRef.hide()">{{ 'CANCEL' | translate}}</button>
  </div>
</ng-template>

<!-- conflict checker modal -->
<ng-template #conflict_checker>
  <div class="modal-header">
      <h4 class="modal-title pull-left">Conflicts</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>

  <div class="modal-body py-1">
      <label>{{ 'ICE_COURSE.CONFLICTS_CHECK_MSG' | translate }}</label>
      <ag-grid-angular #agGrid style="width: 100%; height: 400px;" [rowData]="rowData" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }" class="ag-theme-balham"
          [columnDefs]="columnDefs"  [enableFilter]="false" [defaultColDef]="defaultColDef"
          [enableRangeSelection]="true" [pagination]="false"
          (gridReady)="onGridReady($event)" rowSelection="multiple" 
          [rowMultiSelectWithClick]="true" [getRowNodeId]="getRowNodeId" [rowSelection]="rowSelection">
      </ag-grid-angular>
      <button class="btn btn-sm btn-outline-dark float-right my-2" (click)="modalRef.hide();">Cancel</button>
      <button class="btn btn-sm btn-outline-dark float-right my-2 mx-2"
          (click)="autoUpdateICE();">Auto
          Update</button>
  </div>
</ng-template>

<!-- copy learningcheck modal -->
<ng-template #copyLearningCheckModal>
	<div class="modal-header">
		<div >
			<h4 class="modal-title pull-left">{{ 'ICE_LEARNING_CHECK.COPY_LEARNINGCHECK' | translate }} </h4>
		</div>
		<button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>

  	<div *ngIf="iceCMCoursesAvailable" class="copy-model">
	  	<div class="modal-body">
		  	<div id="copy-model-card" class="card p-0">
			  	<div *ngFor="let course of iceCMCourseList; let n=index">
				  	<div [ngClass]="{'card bg-secondary text-white': course.id == selectedCMIceCourse}">
					  	<!-- <div (click)="selectCMCourse(course.id)" class="card-header">{{course.courseName}}
            </div> -->
            <div (click)="selectCMCourse(course.id)" class="card-header">{{course.fileName}}
						</div>
					</div>
				</div>
			</div>
	  	</div>
	</div>

	<div *ngIf="cmLearningCheckListAvailable" class="copy-model">
		<div class="modal-body">
			<div id="copy-model-card" class="card p-0">
				<div *ngFor="let learningCheck of iceCMLearningCheckList; let n=index">
					<div [ngClass]="{'card bg-secondary text-white': learningCheck.id == selectedCMLearningCheck}">
						<div (click)="selectLearningCheck(learningCheck.id)" class="card-header" [innerHTML] = "learningCheck.description">
					  	</div>
				  	</div>
			  </div>
		  </div>
		</div>
  </div>

  <div class="modal-footer">
	  <button type="button" *ngIf="isLearningCheckDesc && !isLearningCheckList" class="btn btn-warning mr-auto" (click)="goBack()">{{'Back' | translate}}</button>
	  <button type="button" class="btn btn-warning" (click)="confirmSelectedCM();">{{'Ok' | translate}}</button> 
	  <button type="button" class="btn btn-default" (click)="modalRef.hide()">{{ 'Cancel' | translate}}</button>
	</div>
</ng-template>

<!-- copy Games modal -->
<ng-template #copyGamesModal>
	<div class="modal-header">
		<div >
			<h4 class="modal-title pull-left">{{ 'ICE_GAME.COPY_GAMES' | translate }} </h4>
		</div>
		<button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>

  	<div *ngIf="iceCMCoursesAvailable" class="copy-model">
	  	<div class="modal-body">
		  	<div id="copy-model-card" class="card p-0">
			  	<div *ngFor="let course of iceCMCourseList; let n=index">
				  	<div [ngClass]="{'card bg-secondary text-white': course.id == selectedCMIceCourse}">
              <!-- <div (click)="selectCMCourse(course.id)" class="card-header">{{course.courseName}}
              </div> -->
					  	<div (click)="selectCMCourse(course.id)" class="card-header">{{course.fileName}}
						</div>
					</div>
				</div>
			</div>
	  	</div>
	</div>

	<div *ngIf="cmGameListAvailable" class="copy-model">
		<div class="modal-body">
			<div id="copy-model-card" class="card p-0">
				<div *ngFor="let game of iceCMGameList; let n=index">
					<div [ngClass]="{'card bg-secondary text-white': game.id == selectedCMGame}">
						<div (click)="selectGame(game.id)" class="card-header" [innerHTML] = "game.description">
					  	</div>
				  	</div>
			  </div>
		  </div>
		</div>
  </div>

	<div class="modal-footer">
	  <button type="button" *ngIf="isGameDesc && !isGameList" class="btn btn-warning mr-auto" (click)="back()">{{'Back' | translate}}</button>
	  <button type="button" class="btn btn-warning" (click)="confirmSelectedItem();">{{'Ok' | translate}}</button> 
	  <button type="button" class="btn btn-default" (click)="modalRef.hide()">{{ 'Cancel' | translate}}</button>
	</div>
</ng-template>

<!--template for closing topic-->
<ng-template #closeConfirmModel>
  <div class="modal-header">
      <h4 class="modal-title pull-left">{{'CLOSE_CONFIRM' | translate }}</h4>
  </div>    	
  <div class="modal-body">
      <div class="container">
        <h5 class='warn-text-editor'>{{ 'WARNING' | translate}}</h5>
        <h5>{{ 'ICE_TOPIC.CLOSE_CONFIRM_MSG' | translate}} </h5>
      </div>
  </div> 
  <div class="modal-footer">
    <button  type="button" class="btn btn-default" (click)="cancel();modalRef.hide();">{{'YES' | translate}}</button>
    <button type="button" class="btn btn-warning" (click)="modalRef.hide()">{{ 'NO' | translate}}</button>      
  </div>
</ng-template>