import { Component, OnInit, EventEmitter, Input, Output, } from '@angular/core';
import { UserVariable } from "../common/user-variable";
import { HttpClient, HttpErrorResponse, HttpParams } from "@angular/common/http";
import { ServiceUrls } from "../service-urls";
import { WsResponse } from "../ws-response.model";
import { SharedService } from "../shared.service";
import { CommonUtil } from "../common/common-util";
import { AlertService } from "../alert/alert.service";
import { WsType } from "../ws-type";
import { Observable } from "rxjs";
import { TaginputitemService } from "./taginputitem.service";
import { WsCallback } from "../ws-callback.interface";
import { Constants } from '../constants';
import { TagsService } from 'src/app/reusables/tags/tags.service';
import { ManagechecksheetsService } from 'src/app/commissioning/checksheets/managechecksheets/managechecksheets.service';
import { TranslateService } from '@ngx-translate/core';

function getDateFromTimestampDisplay(targetCompletionDate: string) {
  // return  new Date(targetCompletionDate).toISOString().slice(0, 19).split('T')[0] +"";
  return new Date(targetCompletionDate).toLocaleDateString();
}

function getDateFromTimestampValue(targetCompletionDate: string) {
  return new Date(targetCompletionDate).toISOString().slice(0, 19).split('T')[0] + "";
  // return new Date(targetCompletionDate).toLocaleDateString();
}

@Component({
  selector: 'app-taginputitem',
  templateUrl: './taginputitem.component.html',
  styleUrls: ['./taginputitem.component.css'],
  outputs: ['taginputSeleted']
})
export class TaginputitemComponent implements OnInit, WsCallback {
  @Input() key: string;
  @Input() placeholder: string;
  @Input() disable: boolean;
  taginputSeleted = new EventEmitter<any>()
  dropdownItemList = [];
  selectedItemList = [];
  disableInput: boolean = false;
  dynamicLoad: boolean = false;
  assetLoad: boolean = false;
  private token: string;

  constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private commonUtil: CommonUtil,
    private alertService: AlertService,
    private taginputitemService:TaginputitemService,
    private tagService: TagsService,
    private checksheetService: ManagechecksheetsService,
  ) {
    this.token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(this.token)) {
      return;
    }
  }

  ngOnInit() {
    if (this.key == Constants.SEARCH_KEY_WORDS.SEARCH_IRN_ASSET) {
      this.dynamicLoad = true;
       this.setTagInputSelectedItems(this.key)
    } else if (this.key == Constants.SEARCH_KEY_WORDS.SEARCH_IRN_VENDOR) {
      this.setTagInputSelectedItems(this.key)
      this.taginputitemService.getAllVendors(this);
    } else if (this.key == Constants.SEARCH_KEY_WORDS.SEARCH_MCC_SYSTEM) {
      this.setTagInputSelectedItems(this.key)
      this.taginputitemService.getAllActiveUsedInCommissioningSystemsWithParentForFilter(this);
    }
    else if (this.key == Constants.SEARCH_KEY_WORDS.SEARCH_MCC_COMPLETION_DATE) {
      this.setTagInputSelectedItems(this.key)
      this.taginputitemService.getAllTargetCompletionDatesForMCCFilter(this);
    }
    else if (this.key == Constants.SEARCH_KEY_WORDS.SEARCH_MCC_STATUS) {
      this.setTagInputSelectedItems(this.key)
      this.statusSearch();
    }
    // OTN related items
    else if (this.key == Constants.SEARCH_KEY_WORDS.SEARCH_OTN_ASSET) {
      this.setTagInputSelectedItems(this.key);
      this.taginputitemService.getAssetsForFilters(this);
    }
    else if (this.key == Constants.SEARCH_KEY_WORDS.SEARCH_OTN_VENDOR) {
      this.setTagInputSelectedItems(this.key)
      this.taginputitemService.getAllVendors(this);
    }
    else if (this.key == Constants.SEARCH_KEY_WORDS.SEARCH_OTN_STATUS) {
      this.setTagInputSelectedItems(this.key)
      this.statusSearch();
    }
    else if (this.key == Constants.SEARCH_KEY_WORDS.SEARCH_OTN_COMPLETION_DATE) {
      this.setTagInputSelectedItems(this.key)
      this.taginputitemService.getAllTargetCompletionDatesForOTNFilter(this);
    } 
    // IRN related items
    else if(this.key == Constants.SEARCH_KEY_WORDS.SEARCH_IRN_STATUS){
      this.setTagInputSelectedItems(this.key)
      this.statusSearchIRN();
    } 
    else if(this.key == Constants.SEARCH_KEY_WORDS.SEARCH_IRN_COMPLETION_DATE){
      this.setTagInputSelectedItems(this.key)
      this.taginputitemService.getAllTargetCompletionDatesForIRNFilter(this);
    }
    else if (this.key == Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_NO || this.key == Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_PARENT ||
      this.key == Constants.SEARCH_KEY_WORDS.ISSUE_CS_ASSET || this.key == Constants.SEARCH_KEY_WORDS.ISSUE_CS_ASSET_PARENT || this.key == Constants.SEARCH_KEY_WORDS.RECEIVE_CS_ASSET) {
      this.assetLoad = true;
      this.setTagInputSelectedItems(this.key);
      this.taginputitemService.getAssetsForFilters(this);
    }
    else if (this.key == Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_DESC || this.key == Constants.SEARCH_KEY_WORDS.ISSUE_CS_ASSET_DESCRIPTION || this.key == Constants.SEARCH_KEY_WORDS.RECEIVE_CS_ASSET_DESCRIPTION) {
      this.assetLoad = true;
      this.setTagInputSelectedItems(this.key);
      this.taginputitemService.getAssetsForFilters(this);
    }
    else if (this.key == Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_SYS) {
      this.assetLoad = true;
      this.setTagInputSelectedItems(this.key);
      this.taginputitemService.getAllActiveSystemsWithParentForCSHFilter(this);
    }
    else if(this.key == Constants.SEARCH_KEY_WORDS.ISSUE_CS_SYSTEM || this.key == Constants.SEARCH_KEY_WORDS.RECEIVE_CS_SYSTEM){
      this.setTagInputSelectedItems(this.key);
      this.taginputitemService.getAllActiveUsedInCommissioningSystemsWithParentForFilter(this);
    }
    else if (this.key == Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_GROUP || this.key == Constants.SEARCH_KEY_WORDS.ISSUE_CS_ASSET_GROUP || this.key == Constants.SEARCH_KEY_WORDS.RECEIVE_CS_ASSET_GROUP) {
      this.assetLoad = true;
      this.setTagInputSelectedItems(this.key);
      this.tagService.getAllActiveAssetGroups(this.token, this);
    }
    else if (this.key == Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_ATTRIBUTE) {
      this.disableInput = true;
      this.assetLoad = true;
      this.setTagInputSelectedItems(this.key);
    }
    else if (this.key == Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_FUNCCODE || this.key == Constants.SEARCH_KEY_WORDS.ISSUE_CS_FUNCTION_CODE || this.key == Constants.SEARCH_KEY_WORDS.RECEIVE_CS_FUNCTION_CODE) {
      this.assetLoad = true;
      this.setTagInputSelectedItems(this.key);
      this.checksheetService.getFunctionCodeList(this);
    }
    else if (this.key == Constants.SEARCH_KEY_WORDS.SEARCH_SYS_NO || this.key == Constants.SEARCH_KEY_WORDS.SEARCH_SYS_NAME) {
      this.assetLoad = true;
      this.setTagInputSelectedItems(this.key);
      this.taginputitemService.getSystemsForFilters(this);
    }
    else if(this.key == Constants.SEARCH_KEY_WORDS.ISSUE_CS_VENDOR || this.key == Constants.SEARCH_KEY_WORDS.RECEIVE_CS_VENDOR){
      this.setTagInputSelectedItems(this.key);
      this.checksheetService.getAllVendors(this);
    }
    else if(this.key == Constants.SEARCH_KEY_WORDS.ISSUE_CS_CHECKSHEET || this.key == Constants.SEARCH_KEY_WORDS.RECEIVE_CS_CHECKSHEET){
      this.setTagInputSelectedItems(this.key);
      this.checksheetService.getAllChecksheets(this);
    }
    else if(this.key == Constants.SEARCH_KEY_WORDS.ISSUE_CS_PHASE || this.key == Constants.SEARCH_KEY_WORDS.RECEIVE_CS_PHASE){
      this.setTagInputSelectedItems(this.key);
      this.checksheetService.getAllPhases(this);
    }
    //PCC related items
    else if (this.key == Constants.SEARCH_KEY_WORDS.SEARCH_PCC_SYSTEM) {
      this.setTagInputSelectedItems(this.key)
      this.taginputitemService.getAllActiveUsedInCommissioningSystemsWithParentForFilter(this);
    }
    else if (this.key == Constants.SEARCH_KEY_WORDS.SEARCH_PCC_COMPLETION_DATE) {
      this.setTagInputSelectedItems(this.key)
      this.taginputitemService.getAllTargetCompletionDatesForPCCFilter(this);
    }
    else if (this.key == Constants.SEARCH_KEY_WORDS.SEARCH_PCC_STATUS) {
      this.setTagInputSelectedItems(this.key)
      this.statusSearch();
    }
  }


  /**
   * Achini Randeni
   * Get selected tag inputs from the shared service and 
   * set those items into each taginput fields
   * @param key 
   */
  setTagInputSelectedItems(key: string) {
    var tempTagInputList = []
    var tempvalue = []
    //get selected tag inputs from shared service
    tempTagInputList = this.sharedService.getTagInputList();
    if (tempTagInputList.length > 0) {
      //extract the list by key
      tempTagInputList = tempTagInputList.filter(i => i.key == key)
      if (tempTagInputList.length > 0) {
        //get the values array
        tempvalue = tempTagInputList[0].value
        tempvalue.forEach(i => {
          this.selectedItemList.push({ value: i.value, display: i.display });
        });

        //set taginputSeleted 
        let itemIds = []
        this.selectedItemList.forEach((item) => {
          itemIds.push(item)
        });
        this.taginputSeleted.emit({ key: this.key, value: itemIds })
      } else {
        //do nothing
      }
    } else {
      //do nothing
    }
  }

  setSystemDropdown() {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    const options = { params: new HttpParams().set("token", token) }
    this.http.get(ServiceUrls.GET_ALL_SYSTEM_LIST, options).subscribe(
      data => {
        var modified = JSON.parse(JSON.stringify(data));
        if (modified.payload != null) {
          console.log(modified.payload);
          modified.payload.forEach(element => {
            this.dropdownItemList.push({ value: element.id, display: element.systemNo });
          });
        }
      },
      error => {
        if (error.status != '') {
          var val = (error as HttpErrorResponse).error;
          var modified = JSON.parse(JSON.stringify(val));
          var res = new WsResponse(
            modified.status.description,
            modified.status.code,
            modified.status.name,
            modified.payload);
          // wsCallBack.onFail(res, WsType.GET_SYSTEMS_BY_PROJECT_ID);
        } else {
          //browser related issues
          var res = new WsResponse("Unknown error happened");
          // wsCallBack.onFail(res, WsType.GET_SYSTEMS_BY_PROJECT_ID);
        }
      }
    );
  }

  selectItemFromSuggestions(event) {
    let itemIds = []

    this.selectedItemList.forEach((item) => {
      itemIds.push(item);
    });

    //Achini Randeni
    //add selected tag input to the shared service
    this.sharedService.setTagInputList(this.key, itemIds);
    this.taginputSeleted.emit({ key: this.key, value: itemIds })

    // this.dropdownItemList = this.dropdownItemList.filter((el) => {
    //   return this.selectedItemList.some((f) => {
    //     return f.value === el.value
    //   });
    // });
  }

  removeSelectedItem(event) {
    let itemIds = []

    this.selectedItemList.forEach((item) => {
      itemIds.push(item);
    });

    //Achini Randeni
    //update selected tag input list in shared service after removing item
    this.sharedService.setTagInputList(this.key, itemIds);
    this.taginputSeleted.emit({ key: this.key, value: itemIds })
  }

  public requestAutocompleteItems = (text: string): Observable<any> => {
    if (this.key == "assets") {
      return this.taginputitemService.getAssetList(text);
    }
    else if (this.key == Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_ATTRIBUTE) {
      return this.taginputitemService.getAttributeList(text);
    }
  }

  onFail(data: WsResponse, serviceType: WsType) {
    if (serviceType == WsType.GET_ALL_VENDORS) {

    } else if (serviceType == WsType.GET_SYSTEMS_BY_PROJECT_ID) {

    }  else if(serviceType == WsType.GET_IRN_TARGETCOMPLETION_DATES){

    } else if(serviceType == WsType.GET_PCC_TARGETCOMPLETION_DATES){
      this.alertService.clear();
      this.alertService.error(data.statusDescription);
    } else if(serviceType == WsType.GET_ACTIVE_USED_IN_COMMISSIONING_SYSTEMS){
      this.alertService.clear();
      this.alertService.error(data.statusDescription);
    }
  }

  onSuccess(data: WsResponse, serviceType: WsType) {
    if (serviceType == WsType.GET_ALL_VENDORS) {
      if (data.payload) {
        data.payload.forEach(element => {
          this.dropdownItemList.push({ value: element.vendorId, display: element.vendorName });
        });
      }
    } else if (serviceType == WsType.GET_SYSTEMS_BY_PROJECT_ID) {
      if (data.payload) {
        data.payload.forEach(element => {
          this.dropdownItemList.push({ value: element.systemId, display: element.systemNo });
        });
      }
    } else if (serviceType == WsType.GET_MCC_TARGETCOMPLETION_DATES) {
      if (data.payload) {
        data.payload.forEach(element => {
          this.dropdownItemList.push({ value: getDateFromTimestampValue(element.targetCompletionDate), display: getDateFromTimestampDisplay(element.targetCompletionDate) });
        });
      }
    }
    else if (serviceType == WsType.GET_ALL_SYSTEM_LIST_FOR_CSHFILTER) {
      if (data.payload) {
        data.payload.forEach(element => {
          this.dropdownItemList.push({ value: element.id, display: element.systemNo + ' ' + element.systemName });
        });
      }
    }
    else if (serviceType == WsType.GET_ASSETS) {
      if (data.payload != null) {
        if (this.key == Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_DESC || this.key == Constants.SEARCH_KEY_WORDS.ISSUE_CS_ASSET_DESCRIPTION || this.key == Constants.SEARCH_KEY_WORDS.RECEIVE_CS_ASSET_DESCRIPTION) {
          data.payload.forEach(element => {
            this.dropdownItemList.push({ value: element.tagId, display: element.plainTextDesc });
          });
        }
        else {
          data.payload.forEach(element => {
            this.dropdownItemList.push({ value: element.tagId, display: element.tagNo });
          });
        }
      }
    }
    else if (serviceType == WsType.GET_OTN_TARGETCOMPLETION_DATES) {
      if (data.payload) {
        data.payload.forEach(element => {
          this.dropdownItemList.push({ value: getDateFromTimestampValue(element.targetCompletionDate), display: getDateFromTimestampDisplay(element.targetCompletionDate) });
        });
      }
    }
    else if (serviceType == WsType.GET_ALL_ACTIVE_ASSET_GROUPS) {
      if (data.payload) {
        data.payload.forEach(element => {
          this.dropdownItemList.push({ value: element.assetGroupId, display: element.assetGroupName });
        });
      }
    } else if (serviceType == WsType.GET_IRN_TARGETCOMPLETION_DATES) {
      if(data.payload){
        data.payload.forEach(element => {
          this.dropdownItemList.push({ value: getDateFromTimestampValue(element.targetCompletionDate), display:  getDateFromTimestampDisplay(element.targetCompletionDate)});
        });
      }
    }
    else if (serviceType == WsType.GET_ALL_FUNCTION_CODES) {
      if (data.payload) {
        data.payload.forEach(element => {
          this.dropdownItemList.push({ value: element.functionCodeId, display: element.functionCode });
        });
      }
    }
    else if (serviceType == WsType.GET_SYSTEM_LIST) {
      if (data.payload) {
        let jsonData = JSON.parse(data.payload);
        if (this.key == Constants.SEARCH_KEY_WORDS.SEARCH_SYS_NAME) {
          jsonData.forEach(element => {
            this.dropdownItemList.push({ value: element.id[0], display: element.name[0] });
          });
        }
        else {
          jsonData.forEach(element => {
            this.dropdownItemList.push({ value: element.id[0], display: element.number[0] });
          });
        }
      }
    }
    else if(serviceType == WsType.GET_ALL_CHECKSHEETS){
      if (data.payload) {
        data.payload.forEach(element => {
          this.dropdownItemList.push({ value: element.checkSheetId, display: element.checkSheetCode + " - " + element.checkSheetName });
        });
      }
    }
    else if(serviceType == WsType.GET_ALL_PHASES){
      if (data.payload) {
        data.payload.forEach(element => {
          this.dropdownItemList.push({ value: element.phaseId, display: element.phaseName });
        });
      }
    } 
    else if(serviceType == WsType.GET_PCC_TARGETCOMPLETION_DATES){
      if(data.payload){
        data.payload.forEach(element => {
          this.dropdownItemList.push({ value: getDateFromTimestampValue(element), display: getDateFromTimestampDisplay(element) });
        });
      }
    }
    else if(serviceType == WsType.GET_ACTIVE_USED_IN_COMMISSIONING_SYSTEMS){
      if (data.payload) {
        data.payload.forEach(element => {
          this.dropdownItemList.push({ value: element.systemId, display: element.systemNo + ' ' + element.systemName });
        });
      }
    }
  }

    statusSearch() {
      let statusSearch = [];
      statusSearch.push({ value: "NotIssued", display: "NotIssued" });
      statusSearch.push({ value: "Issued", display: "Issued" });
      statusSearch.push({ value: "Approved", display: "Approved" });
      this.dropdownItemList = statusSearch;
    }

  statusSearchIRN(){
    let statusSearchIRN = [];
    statusSearchIRN.push({value : "Initial", display :"Initial"});
    statusSearchIRN.push({value : "Approved", display :"Approved"});
    this.dropdownItemList = statusSearchIRN;
  }

}
