export class AuthRequest {
    private _1?: string;
    private _2?: string;
    private _3?: string;
    private _4?: string;
    private _5?: string;

    constructor() { }

    public set username(value: string) {
        this._1 = value;
    }

    public get username(): string {
        return this._1;
    }

    public set password(value: string) {
        this._2 = value;
    }
    public get password(): string {
        return this._2;
    }

    public set deviceid(value: string) {
        this._3 = value;
    }
    public get deviceid(): string {
        return this._3;
    }

    public set authtype(value: string) {
        this._4 = value;
    }
    public get authtype(): string {
        return this._4;
    }

    public set email(value: string) {
        this._5 = value;
    }
    public get email(): string {
        return this._5;
    }

    toString() {
        return 'username-' + this.username + ' password-' + this.password + ' deviceid-' + this.deviceid + ' authtype-' + this.authtype  + ' email-' + this.email;
    }
}
