import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker/bs-datepicker.config';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs/internal/Subject';
import { AlertService } from 'src/app/util/alert/alert.service';
import { CommonUtil } from 'src/app/util/common/common-util';
import { UserVariable } from 'src/app/util/common/user-variable';
import { LoadingService } from 'src/app/util/loading/loading.service';
import { WsCallback } from 'src/app/util/ws-callback.interface';
import { WsResponse } from 'src/app/util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';
import { ManagePCCService } from '../managepcc.service';

@Component({
    selector: 'app-approvepcc',
    templateUrl: './approvepcc.component.html',
    styleUrls: ['./approvepcc.component.css']
})
export class ApprovepccComponent implements OnInit, WsCallback {

    clickedRow: string
    documentUpload: any;
    companyManagerDate: any;
    companyLeadDate: any;
    replacePCCDocumentModalRef: BsModalRef;
    file: any;
    @ViewChild('companyManagerName') companyManagerName: ElementRef;
    @ViewChild('companyLeadName') companyLeadName: ElementRef;
    @ViewChild('replacePCCDocumentModal') replacePCCDocumentModal: TemplateRef<any>;
    public onReplacePCCDocumentModalClose: Subject<boolean>;
    public bsConfig: Partial<BsDatepickerConfig>
    public pccDocumentUpload: any;
    public projectId: string = null;

    public pccId: string;
    public pccStatus: string;
    public systemName: string;
    public systemId: string;
    public isUploaded: boolean;

    private approvedNow: boolean = false; //To identify approved now or before

    constructor(
        public bsModalRef: BsModalRef,
        private modalService: BsModalService,
        private alertService: AlertService,
        private loadingService: LoadingService,
        private translate: TranslateService,
        private managepccService: ManagePCCService,
        private commonUtil: CommonUtil,
    ) {
        this.bsConfig = Object.assign({}, { containerClass: 'theme-default' });
        this.projectId = UserVariable.projectId;
    }

    ngOnInit(): void {
        this.onReplacePCCDocumentModalClose = new Subject();
        if (this.pccStatus == "Approved") {
            this.loadingService.showLoading(true, false, 'Loading approval data', 0);
            this.managepccService.getPCCApprovalData(this.pccId, this);
        }
    }

    cancel() {
        this.bsModalRef.hide()
    }

    changeColor(val) {
        // this.clickedRow = val;
    }

    save() {
        if (this.companyManagerName.nativeElement.value == "" || this.companyLeadName.nativeElement.value == "") {
            this.alertService.clear();
            this.alertService.error(this.translate.instant('PCC_ADD_EDIT.REQUIRED'));
        } else if (!this.companyManagerName.nativeElement.value || !this.companyLeadName.nativeElement.value) {
            this.alertService.clear();
            this.alertService.error(this.translate.instant('PCC_ADD_EDIT.REQUIRED'));
        }
        else if (!this.companyManagerDate || !this.companyLeadDate) {
            this.alertService.clear();
            this.alertService.error(this.translate.instant('PCC_ADD_EDIT.REQUIRED'));
        } else if (this.companyManagerDate == 'Invalid Date' || this.companyLeadDate == 'Invalid Date') {
            this.alertService.clear();
            this.alertService.error(this.translate.instant('PCC_ADD_EDIT.INVALID_DATE'));
        }
        else {
            this.loadingService.showLoading(true, false, 'Saving approval data', 0);
            let data = {
                pccId: this.pccId,
                companyManagerName: this.companyManagerName.nativeElement.value,
                companyManagerDate: new DatePipe("en-US").transform(new Date(this.companyManagerDate), 'yyyy-MM-dd'),
                companyLeadName: this.companyLeadName.nativeElement.value,
                companyLeadDate: new DatePipe("en-US").transform(new Date(this.companyLeadDate), 'yyyy-MM-dd'),
                type: "Approve"
            }
            this.managepccService.approvePCC(data, this)
        }
    }


    onUpload() {
        if (this.pccDocumentUpload == null) {
            this.alertService.clear();
            this.alertService.error(this.translate.instant('NEW_PCC_DOCUMENT.SELECT_A_FILE'));
            return;
        }
        var loadingMessage;

        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
        this.translate.get('NEW_PCC_DOCUMENT.LOADING_MESSAGE').subscribe((res: string) => {
            loadingMessage = res;
            this.loadingService.showLoading(true, null, loadingMessage, null);
        });

        if (this.pccDocumentUpload && this.pccDocumentUpload.isAdded && !this.pccDocumentUpload.isDeleted) {
            let element = this.pccDocumentUpload;
            let uploadFileNameWithExt = element.docName + "." + element.docType;
            const uploadData = new FormData();

            uploadData.append("pccId", this.pccId);
            uploadData.append("projectId", this.projectId);
            uploadData.append("filePath", '');
            uploadData.append("fileName", element.docName);
            uploadData.append("contentType", element.docType);
            uploadData.append("token", token);
            uploadData.append("file", element.file, element.docName);
            uploadData.append("addedUserId", '');
            uploadData.append("isAdded", element.isAdded);
            uploadData.append("isDeleted", element.isDeleted);
            this.managepccService.uploadFile(uploadData, this);
        }
    }


    /**
     * Replace PCC document is confirmed.
     */
    public confirmReplaceDocument(): void {
        this.onReplacePCCDocumentModalClose.next(true);
        this.replacePCCDocumentModalRef.hide();
    }

    /**
     * Replace PCC document is canceled.
     */
    public cancelReplaceDocument(): void {
        this.onReplacePCCDocumentModalClose.next(false);
        this.replacePCCDocumentModalRef.hide();
    }

    onFileChanged(event) {
        if (this.isUploaded) {
            this.replacePCCDocumentModalRef = this.modalService.show(this.replacePCCDocumentModal)
            this.onReplacePCCDocumentModalClose.subscribe(confirm => {
                if (confirm) {
                    this.changeFile(event);
                }
                else {
                    this.pccDocumentUpload = null;
                    this.file = null;
                }
            })
        }
        else {
            this.changeFile(event);
        }
    }

    changeFile(event) {
        let i: number = 0;
        for (i = 0; i < event.target.files.length; i++) {
            let newFile = <File>event.target.files[i];
            if (newFile != undefined) {
                let parts = newFile.name.split('.')
                if (parts.length > 1) {
                    let fileExtension = parts.pop();
                    if (fileExtension == 'pdf') {
                        let fileName = parts.join('.');
                        fileName = fileName.trim();
                        if (this.validateFile(newFile)) {
                            //set for uploading to the local file system
                            var pccDocumentUpload =
                            {
                                docName: fileName,
                                docType: fileExtension,
                                file: newFile,
                                isAdded: true,
                                isDeleted: false
                            };
                            this.pccDocumentUpload = pccDocumentUpload;
                        } else {
                            //file size is too large
                        }
                    } else {
                        //file is not pdf
                        this.alertService.clear();
                        this.translate.get('NEW_PCC_DOCUMENT.INVALID_FILE_TYPE').subscribe((res: string) => {
                            this.alertService.error(res, false);
                        });

                    }
                } else {
                    this.translate.get('NEW_PCC_DOCUMENT.INVALID_FILE_NAME').subscribe((res: string) => {
                        this.alertService.error(res, false);
                    });
                }
            } else {
                //file is not valid
                this.alertService.clear();
                this.translate.get('NEW_PCC_DOCUMENT.INVALID_FILE').subscribe((res: string) => {
                    this.alertService.error(res, false);
                });
                this.alertService.error("invalid file");
            }
        }
    }

    validateFile(file: File): boolean {
        if (file.size > 104857601) {
            this.translate.get('NEW_PCC_DOCUMENT.SIZE_LIMIT_EXCEEDED').subscribe((res: string) => {
                this.alertService.error(res, false);
            });
            return false;
        } else {
            return true;
        }
    }

    onSuccess(data: WsResponse, serviceType: WsType) {
        if (serviceType == WsType.APPROVE_PCC) {
            this.approvedNow = true;
            if (this.pccDocumentUpload != null) {
                this.onUpload()
            } else {
                this.alertService.success(this.translate.instant('PCC_ADD_EDIT.APPROVE_SUCCESS'));
                this.loadingService.hideLoading();
                this.bsModalRef.hide()
                this.managepccService.updateUIafterApprovalRequest(this.pccId, false);
            }
        } else if (serviceType == WsType.ADD_NEW_PCC_DOCUMENT) {
            this.loadingService.hideLoading()
            this.pccDocumentUpload = null;
            if (this.approvedNow) {
                this.alertService.success(this.translate.instant('PCC_ADD_EDIT.APPROVE_SUCCESS'));
                this.bsModalRef.hide()
                this.managepccService.updateUIafterApprovalRequest(this.pccId, true);
            }
            else {
                if (!this.isUploaded) {
                    this.alertService.success(this.translate.instant('NEW_PCC_DOCUMENT.ADD_SUCCESS'));
                    this.managepccService.updateUIafterDocumentUploadRequest(this.pccId, true);
                }
                else {
                    this.alertService.success(this.translate.instant('NEW_PCC_DOCUMENT.UPDATE_SUCCESS'));
                }
            }
            this.isUploaded = true;

        } else if (serviceType == WsType.GET_PCC_APPROVAL_DATA) {
            let payload = data.payload
            if (payload != null) {
                this.companyManagerName.nativeElement.value = payload.ContractorManagerName;
                this.companyLeadName.nativeElement.value = payload.CompanyLeadName;
                if (payload.ContractorManagerDate) {
                    this.companyManagerDate = new Date(payload.ContractorManagerDate);;
                }
                if (payload.CompanyLeadDate) {
                    this.companyLeadDate = new Date(payload.CompanyLeadDate);;
                }
            }
            this.loadingService.hideLoading();
        }
    }

    onFail(data: WsResponse, serviceType: WsType) {
        if (serviceType == WsType.APPROVE_PCC) {
            this.alertService.error(data.statusDescription);
            this.loadingService.hideLoading();

        } else if (serviceType == WsType.ADD_NEW_PCC_DOCUMENT) {
            this.alertService.error(data.statusDescription);
            this.loadingService.hideLoading()

        } else if (serviceType == WsType.GET_PCC_APPROVAL_DATA) {
            this.alertService.error(data.statusDescription);
            this.loadingService.hideLoading()
        }
    }

}
