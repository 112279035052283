import { Component, OnInit, QueryList, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FloatingButtonCallback } from '../floating-button-callback.interface';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DocumenttypetreeComponent } from '../../../util/documenttypetree/documenttypetree.component';
import { DocumentTypeService } from '../../../util/documenttypetree/documenttype.service';
import { WsCallback } from '../../../util/ws-callback.interface';
import { WsResponse } from '../../../util/ws-response.model';
import { WsType } from '../../../util/ws-type';
import { AlertService } from '../../../util/alert/alert.service';
import { HttpClient } from '@angular/common/http';
import { FileUploadService } from './file-upload.service';
import { LoadingService } from '../../../util/loading/loading.service';
import { TranslateService } from '@ngx-translate/core';
import { UserVariable } from 'src/app/util/common/user-variable';
import { FormGroup, FormControl } from '@angular/forms';
import { CommonUtil } from '../../../util/common/common-util';


@Component({
    selector: 'app-new-video',
    templateUrl: './new-video.component.html',
    styleUrls: ['./new-video.component.css']
})
export class NewVideoComponent implements OnInit, WsCallback {
    @ViewChild('confirmTemplate') confirmTemplate: TemplateRef<any>
    //@ViewChild('thumbnailFile') thumbnailFile: ElementRef;
    public title: string;
    public selectedFolder: any;
    public floatingButtonCallback: FloatingButtonCallback;
    public attributeList: any;
    public haveProperties: boolean = true;
    public documentType: string;
    public uploadFileName: string;
    public uploadFileExt: string;
    public fileComment: string;
    public fileTitle: string;

    private treeBsModalRef: BsModalRef;
    private confirmbsModalRef : BsModalRef;
    private documentTypeId: string;
    // private uploadingFile: File;
    private uploadingFile: File[] = [];
   
   
    private uploadingVideoFile: File[] = [undefined];
    private uploadingThumbnnailFile: File[] = [undefined];
    private uploadingPosterframeFile: File[] = [undefined];
   
    selectedType: string;
    formGroup: FormGroup
    form: FormControl
    posterframe: File;
    thumbnail:File;
    fileExtension: string;
    fileName: string;
    posterframeTitle: string;
    constructor(public bsModalRef: BsModalRef, private modalService: BsModalService, private documentTypeService: DocumentTypeService,
        private alert: AlertService, private http: HttpClient, private fileUploadService: FileUploadService, private loadingService: LoadingService,
        private translate: TranslateService, private commonUtil: CommonUtil) { }



    /**
     * Web service call to load document types.
     */
    public loadDocumentTypes() {
        const initialState = {
            title: 'Select a Document Type',
            treeListener: this,
            initial: true
        };
        this.treeBsModalRef = this.modalService.show(DocumenttypetreeComponent, { initialState, class: 'modal-lg' });
        this.treeBsModalRef.content.closeBtnName = 'Close';
    }

    /**
     * Clicks on the save button of the tree view popup
     * @param file selected file
     * @param texts text element
     * @param options options element
     * @param documentTypeAttributes selected attributes
     */
    onSaveButtonClick(file: any, texts: QueryList<ElementRef>, options: QueryList<ElementRef>, documentTypeAttributes: any) {
        this.documentTypeService.getDocumentTypeAttributes(file.node.id, this.selectedFolder, this);
        this.documentType = file.node.text;
        this.selectedType = file.node.text;
        this.documentTypeId = file.node.id;
        this.treeBsModalRef.hide();
    }

    // when files are selected, save them in array selectedFiles
    thumbfileAdded(event) {
       
        this.thumbnail = <File>event.target.files[0];

        if (this.thumbnail != undefined) {
            this.uploadingThumbnnailFile.splice(0,1,<File>event.target.files[0]) ;
        }
        else if (this.thumbnail == undefined){
            this.uploadingThumbnnailFile.splice(0,1);
            this.uploadingThumbnnailFile[0]=undefined;
        }
       
       
       
        // this.thumbnail = <File>event.target.files[0];
        // // if (event.target.files.length) {
        // //     for (let i = 0; i < event.target.files.length; i++) {
        // //         this.uploadingFile.push(<File>event.target.files[i]);
        // //     }
        // // }
        // // this.uploadingFile.splice(1,1);
        // if (this.thumbnail != undefined) {
        //     this.uploadingFile.splice(1,1,<File>event.target.files[0]) ;
        // }
        // else if (this.thumbnail == undefined){
        //     this.uploadingFile.splice(1,1);
        // }
    }

    /**
     * Fires the method when change the file selection.
     * @param event event type
     */
    onFileChanged(event) {
        
        this.uploadingVideoFile[0] = <File>event.target.files[0];
        if (this.uploadingVideoFile[0] != undefined) {
            let parts = this.uploadingVideoFile[0].name.split('.')
            if (parts.length > 1) {
                this.uploadFileExt = parts.pop()
                this.uploadFileName = parts.join('.')
                this.fileTitle = parts.join('.')
            } else {
                this.translate.get('NEW_VIDEO.INVALID_FILE_NAME').subscribe((res: string) => {
                    this.alert.error(res, false);
                });
            }
            this.uploadingVideoFile.splice(0,1,<File>event.target.files[0]);
        } else if(this.uploadingVideoFile[0] == undefined) {
            this.uploadingVideoFile.splice(0,1);
            this.uploadingVideoFile[0] = undefined;
            this.uploadFileName = '';

        }
        
        
        // this.uploadingFile.pop[0] = <File>event.target.files[0];
        // console.log(<File>event.target.files[0])
        // console.log(this.uploadingFile.pop[0])
        // if (this.uploadingFile.pop[0] != undefined) {
        //     // let parts = this.uploadingFile.name.split('.')
        //     let parts = this.uploadingFile.pop[0].name.split('.')
        //     if (parts.length > 1) {
        //         this.uploadFileExt = parts.pop()
        //         this.uploadFileName = parts.join('.')
        //         this.fileTitle = parts.join('.')
        //     } else {
        //         this.translate.get('NEW_VIDEO.INVALID_FILE_NAME').subscribe((res: string) => {
        //             this.alert.error(res, false);
        //         });
        //     }
        //     // console.log("video event: "+<File>event.target.files[0].name)
        //     // this.uploadingFile.splice(0,1);
        //     this.uploadingFile.splice(0,1,<File>event.target.files[0]);
        // } else if(this.uploadingFile.pop[0] == undefined) {
        //     this.uploadingFile.splice(0,1);
        //     this.uploadFileName = '';

        // }
    }

    /**
     * Validate the form for compulsory fields.
     */
    validateForm(): boolean {

        if(this.uploadingVideoFile[0] == undefined ||this.uploadingThumbnnailFile[0] == undefined || this.uploadingPosterframeFile[0] == undefined){
            return false;
        }

        var videoextension = this.uploadingVideoFile[0].name.split('.').pop();
        console.log("ext: " + videoextension.toLowerCase());
        if (videoextension.toLowerCase() != 'mp4') {
            this.translate.get('NEW_VIDEO.INVALID_VIDEO_FILE_TYPE').subscribe((res: string) => {
                this.alert.error(res, false);

            });
            return false;
        }

        var thumbnailextension = this.uploadingThumbnnailFile[0].name.split('.').pop();
        console.log("ext: " + thumbnailextension.toLowerCase());
        if (thumbnailextension.toLowerCase() != 'png') {
            this.translate.get('NEW_VIDEO.THUMBNAIL.INVALID_THUMBNAIL_FILE_TYPE').subscribe((res: string) => {
                this.alert.error(res, false);

            });
            return false;
        }
        var posterframeextension = this.uploadingPosterframeFile[0].name.split('.').pop();
        console.log("ext: " + posterframeextension.toLowerCase());
        if (posterframeextension.toLowerCase() != 'png') {
            this.translate.get('NEW_VIDEO.POSTERFRAME.INVALID_POSTERFRAME_FILE_TYPE').subscribe((res: string) => {
                this.alert.error(res, false);

            });
            return false;
        }

        this.fileTitle = this.fileTitle
        if (this.fileTitle) {
        } else {
            return false;
        }
        if (this.documentTypeId) {
        } else {
            this.documentType = "";
            return false;
        }
        // if (this.uploadingFile.size > 104857601 ){
        // 	this.translate.get('NEW_IMAGE.SIZE_LIMIT_EXCEEDED').subscribe((res: string) => {
        // 	  this.alert.error(res, false);
        // 	});
        // 	return false;
        // }
        if (this.selectedType != this.documentType) {
            this.documentType = "";
            return false;
        }

        //Check for attribute values
        if (this.attributeList != undefined && this.attributeList.length != undefined && this.attributeList.length > 0) {
            var isEmptyAttribute: boolean = false;
            this.attributeList.forEach(item => {
                if (item.required == 'yes' && (item.value == null || item.value == "")) {
					this.alert.error(this.translate.instant('NEW_VIDEO.ATTRIBUTE.EMPTY_ATTRIBUTE_ERROR_MESSAGE'));
                    isEmptyAttribute = true;
                }
                // if(item.attributeName == 'Document'){
                //     if(item.documents != undefined){
                //     isEmptyAttribute = false;
                //     }
                // } else if(item.attributeName == 'c1'){
                //     if(item.components != undefined){
                //     isEmptyAttribute = false;
                //     }
                // } else if(item.attributeName == 'System'){
                //     if(item.systems != undefined){
                //     isEmptyAttribute = false;
                //     }
                // }
            });
            if (isEmptyAttribute) {
                return false;
            }
        }

        return true;
    }

    /**
     * Call web services to upload file and data.
     */
    onUpload() {
        if (!this.validateForm()) {
            return;
        }

        var loadingMessage;
        this.translate.get('NEW_VIDEO.LOADING_MESSAGE').subscribe((res: string) => {
            loadingMessage = res;
        });
        this.loadingService.showLoading(true, null, loadingMessage, null);
        var attributeValueXml: string = '';
        //creating attribute xml string.
        attributeValueXml = '<attrs>';
        if (this.attributeList != undefined && this.attributeList.length != undefined && this.attributeList.length > 0) {
            this.attributeList.forEach(item => {
                attributeValueXml += "<attr type=\"text\" name='" + item.attributeName + "' typeattributeid='" + item.attributeId + "'>" + item.value + "</attr>";
            });
        }
        attributeValueXml += '</attrs>';
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
        const uploadData = new FormData();
        uploadData.append("fileName", this.uploadFileName);
        uploadData.append("fileNameExt", this.uploadFileExt);
        uploadData.append("title", this.fileTitle);
        uploadData.append("typeId", this.documentTypeId);
        uploadData.append("comment", this.fileComment);
        uploadData.append("parentFolderId", this.selectedFolder.id);
        uploadData.append("attributes", attributeValueXml);
        uploadData.append("token", token);
        // uploadData.append("file", this.uploadingFile, this.uploadFileName);



        // if (this.uploadingFile.length) {
        //     for (let i = 0; i < this.uploadingFile.length; i++) {
        //         uploadData.append("file", this.uploadingFile[i], this.uploadingFile[i].name);
        //         console.log("file name: " + this.uploadingFile[i].name);
        //     }
        // }
        uploadData.append("file", this.uploadingVideoFile[0], this.uploadingVideoFile[0].name);
        console.log("file name: " + this.uploadingVideoFile[0].name);
        uploadData.append("file", this.uploadingThumbnnailFile[0], this.uploadingThumbnnailFile[0].name);
        console.log("file name: " + this.uploadingThumbnnailFile[0].name);
        uploadData.append("file", this.uploadingPosterframeFile[0], this.uploadingPosterframeFile[0].name);
        console.log("file name: " + this.uploadingPosterframeFile[0].name);



        // uploadData.append('files', this.uploadingFile[0],this.uploadingFile[0].name);
        this.fileUploadService.uploadFile(uploadData, this);
    }

    /**
     * Clicks on the submit button
     */
    public onSubmitButtonClick() {

        this.onUpload();
    }

    /**
     * Callback method for the successful web service calls.
     * @param data web service response data
     * @param serviceType service type id
     */
    onSuccess(data: WsResponse, serviceType: WsType) {
        if (serviceType == WsType.DOCUMENT_TYPE_ATTRIBUTES_VIEW) {
            this.attributeList = data.payload;
            if (data.payload.length == 0) {
                this.haveProperties = false;
            } else {
                this.haveProperties = true;
            }
        } else if (serviceType == WsType.ADD_NEW_VIDEO) {
            this.alert.success(data.statusDescription);
            this.floatingButtonCallback.hide();
            this.loadingService.hideLoading();
        }
    }

    /**
     * Callback method for the unsuccessful web service calls.
     * @param data web service response data
     * @param serviceType service type id
     */
    onFail(data: WsResponse, serviceType: WsType) {
        if (serviceType == WsType.DOCUMENT_TYPE_ATTRIBUTES_VIEW) {
            this.alert.error(data.statusDescription, false);
        } else if (serviceType == WsType.ADD_NEW_VIDEO) {
            this.alert.error(data.statusDescription, false);
            this.loadingService.hideLoading();
        }
    }

    ngOnInit() {
    }


    confirmSubmissoin() {
       // console.log("thumbnail feild: " + this.thumbnailFile.nativeElement.value)
        // if (this.thumbnailFile.nativeElement.value == "") {
        //     this.confirmbsModalRef= this.modalService.show(this.confirmTemplate, { backdrop: true, class: 'modal-sm' });
        // } else {
        this.onSubmitButtonClick();
        // }
       
    }

    confirm() {
        this.onSubmitButtonClick();

    }
    close(){
        this.confirmbsModalRef.hide();
    }

    posterframeFileAdded(event) {
        // this.uploadingFile.pop[2];


        this.posterframe = <File>event.target.files[0];
        if (this.posterframe != undefined) {
            let parts = this.posterframe.name.split('.')
            if (parts.length > 1) {
                this.fileExtension = parts.pop()
                this.fileName = parts.join('.')
                this.posterframeTitle = parts.join('.') + "_posterframe." + this.fileExtension
                //   this.posterframe.name = this.posterframeTitle;
                var posterframeFile = new File([this.posterframe], this.posterframeTitle, { type: this.posterframe.type });
                console.log("posterframe name: " + posterframeFile.name)
            //    this.uploadingFile.splice(2,1);
                this.uploadingPosterframeFile.splice(0,1,posterframeFile);
               
            }
          
            // this.posterframe = null;
        }else if (this.posterframe == undefined) {
            this.uploadingPosterframeFile.splice(0,1);
            this.uploadingPosterframeFile[0]=undefined;

        }



       
        // this.posterframe = <File>event.target.files[0];
        // if (this.posterframe != undefined) {
        //     let parts = this.posterframe.name.split('.')
        //     if (parts.length > 1) {
        //         this.fileExtension = parts.pop()
        //         this.fileName = parts.join('.')
        //         this.posterframeTitle = parts.join('.') + "_posterframe." + this.fileExtension
        //         //   this.posterframe.name = this.posterframeTitle;
        //         var posterframeFile = new File([this.posterframe], this.posterframeTitle, { type: this.posterframe.type });
        //         console.log("posterframe name: " + posterframeFile.name)
        //     //    this.uploadingFile.splice(2,1);
        //         this.uploadingFile.splice(2,1,posterframeFile);
               
        //     }
          
        //     // this.posterframe = null;
        // }else if (this.posterframe == undefined) {
        //     this.uploadingFile.splice(2,1);

        // }
    }

    checkComponent(type:any, name:any){
		if(type == 'Component' || name == 'c1' ){
		   return true;
	   }
   }

   checkSystem(type:any, name:any){
		 if(type == 'System' || name == 'System' ){
		   return true;
	   }
   }
   checkDocument(type:any, name:any){
		 if(type == 'Document' || name == 'Document'){
		   return true;
	   }
   }
}
