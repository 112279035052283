import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
	providedIn: 'root'
})

export class DictionarysharedService{
	
    $isUpdated = new EventEmitter();
    updated: boolean = false;
    
    wordId: String;
    constructor(){}

    setWordId(wordId){
        this.wordId = wordId;
    }

    getWordId() : String {
        return this.wordId;
    }

    dataUpdated(updated: boolean) {
        this.updated = updated;
        this.$isUpdated.emit(this.updated);
    }
    
    clearFlag(){
        this.updated = false;
    }
}