import { Injectable, Output, EventEmitter } from '@angular/core';
import { IceCourseItem } from './ice-course-item';
import { IceLearningCheckComponent } from '../ice-learning-check/ice-learning-check.component';
import { EinsteinGameComponent } from '../ice-game/einstein-game/einstein-game.component';
import { MemoryGameComponent } from '../ice-game/memory-game/memory-game.component';
import { SpeedMatchGameComponent } from '../ice-game/speed-match-game/speed-match-game.component';
import { IceTopicComponent } from '../ice-topic/ice-topic.component';
import { IceCourseComponent } from '../ice-course.component';
import { IceTopicContentComponent } from '../ice-topic-content/ice-topic-content.component';

@Injectable({
    providedIn: 'root'
})
export class IceCourseLoaderService {

    constructor() { }

    public loadComponentWithData(componentType: string, data: any): IceCourseItem {
		switch (componentType) {
			case 'IceLearningCheck':
				return new IceCourseItem(IceLearningCheckComponent, data)
			case 'EinsteinGame':
				return new IceCourseItem(EinsteinGameComponent, data)
			case 'MemoryGame':
				return new IceCourseItem(MemoryGameComponent, data)
			case 'SpeedMatchGame':
				return new IceCourseItem(SpeedMatchGameComponent, data)
			case 'topic':
				return new IceCourseItem(IceTopicComponent, data)
			case 'IceCourse':
				return new IceCourseItem(IceCourseComponent, data)
			case 'topicContent':
				return new IceCourseItem(IceTopicContentComponent, data)
			default:
				break;
		}
	}
}