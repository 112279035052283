import { Injectable } from '@angular/core';

import { WebeditorComponent } from '../webeditor/webeditor.component'
import { FilesComponent } from '../home/files/files.component'
import { AttributesComponent } from '../home/files/filesdata/attributes/attributes.component'
import { PropertiesComponent } from '../home/files/filesdata/properties/properties.component'
import { RevisionsComponent } from '../home/files/filesdata/revisions/revisions.component'
import { ProcedureComponent } from '../procedure/procedure.component';
import { TopicMapFolderList } from '../topic-map-builder/topic-map-folder-list/topic-map-folder-list.component';
import { IceCourseComponent } from '../ice-course/ice-course.component';
import { TabItem } from './tab-item';
import { IceLearningCheckComponent } from '../ice-course/ice-learning-check/ice-learning-check.component';
import { EinsteinGameComponent } from '../ice-course/ice-game/einstein-game/einstein-game.component';
import { MemoryGameComponent } from '../ice-course/ice-game/memory-game/memory-game.component';
import { SpeedMatchGameComponent } from '../ice-course/ice-game/speed-match-game/speed-match-game.component';
import { IceCourseLoaderComponent } from '../ice-course/ice-course-loader/ice-course-loader.component';
import { CompareRevisionComponent } from '../home/files/filesdata/revisions/compare-revisions.component';
import { IceCoursePreviewComponent } from '../ice-course/ice-course-preview/ice-course-preview.component';

@Injectable({
	providedIn: 'root'
})
export class ComponentService {

	constructor() { }

	public loadComponent(componentType: string) {
		switch (componentType) {
			case 'Editor':
				return WebeditorComponent
			case 'Files':
				return FilesComponent
			case 'Properties':
				return PropertiesComponent
			case 'Attributes':
				return AttributesComponent
			case 'Revisions':
				return RevisionsComponent
			case 'Procedures':
				return ProcedureComponent
			case 'TopicMapBuilder':
				return TopicMapFolderList
			case 'IceCourse':
				return IceCourseComponent
			case 'FileSearch':
				return FilesComponent
			case 'CompareRevision':
				return CompareRevisionComponent
			case 'CoursePreview':
				return IceCoursePreviewComponent
			default:
				break;
		}
	}

	public loadComponentWithData(componentType: string, data: any): TabItem {
		switch (componentType) {
			case 'Editor':
				return new TabItem(WebeditorComponent, data)
			case 'Files':
				return new TabItem(FilesComponent, data)
			case 'Properties':
				return new TabItem(PropertiesComponent, data)
			case 'Attributes':
				return new TabItem(AttributesComponent, data)
			case 'Revisions':
				return new TabItem(RevisionsComponent, data)
			case 'Procedures':
				return new TabItem(ProcedureComponent, data)
			case 'TopicMapBuilder':
				return new TabItem(TopicMapFolderList, data)
			case 'IceCourse':
				return new TabItem(IceCourseLoaderComponent, data)
			case 'FileSearch':
				return new TabItem(FilesComponent, data)
			case 'CompareRevision':
				return new TabItem(CompareRevisionComponent, data)
			case 'CoursePreview':
				return new TabItem(IceCoursePreviewComponent, data)
			default:
				break;
		}
	}
}
