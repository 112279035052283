import { Component, OnInit, TemplateRef, ViewChild, ElementRef} from '@angular/core';
import { OptDataService } from './opt-data.service';
import { UserVariable } from 'src/app/util/common/user-variable';
import { WsCallback } from 'src/app/util/ws-callback.interface';
import { WsType } from 'src/app/util/ws-type';
import { WsResponse } from 'src/app/util/ws-response.model';
import { AlertService } from 'src/app/util/alert/alert.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { EditCellComponent } from './edit-cell/edit-cell.component';
import { DeleteCellComponent } from './delete-cell/delete-cell.component';
import { DependencyCellComponent } from './dependency-cell/dependency-cell.component';
import { AllModules, GridOptions, Module, SetLeftFeature } from "@ag-grid-enterprise/all-modules";
import { DependencyModComponent } from './dependency-mod/dependency-mod.component';
import { ConfirmModelComponent } from './confirm-model/confirm-model.component';
import { LoadingService } from 'src/app/util/loading/loading.service';
import { Router } from '@angular/router';
import { CommonUtil } from 'src/app/util/common/common-util';
import { ConditionalExpr, ThrowStmt } from '@angular/compiler';
import { SharedService } from '../util/shared.service';

@Component({
	selector: 'app-opt-data',
	templateUrl: './opt-data.component.html',
	styleUrls: ['./opt-data.component.css'],
	providers: [BsModalRef]
})
export class OptDataComponent implements OnInit, WsCallback {
	@ViewChild('template') template: TemplateRef<any>;
	@ViewChild('update_template') updateTemplate: TemplateRef<any>;

	token: string;
	operatingDataList: any = [];

	// Ag-Grid related variables
	public columnDefs;
	public rowData: any[] = [];
	public frameworkComponents: any;
	public gridOptions: any;
	public defaultColDef: any;

	private bsModalRef: BsModalRef;
	public operatingDataTypeList: any[] = [];
	public selectedOperatingData: any[] = [];
	public newOperatingDataName: any;
	private editOperatingDataModal: any;
	private deleteOperatingDataModal: any;
	private gridApi: any;
	private gridColumnApi: any;
	public overlayLoadingTemplate: any;
	public overlayNoRowsTemplate: any;
	public action: string = '';
	public isOperateDataTypeModified: boolean = false;
	public valueListDataType: boolean = false;
	public editOrignalvalueListDataType: boolean = false;
	public text: string;

	public fieldArray:any ;
	public ValueListItems: Array<any> = [];
	public newAttribute: any = {};

	public valuesListEdit : any;
	public isEditItems: boolean = false;
	public isUpdateOperatingData: boolean = false;
	public modules: Module[] = AllModules;

	public paddingtop: number = 60;
	@ViewChild('navPanel') navPanel: ElementRef
	@ViewChild('textboxes') divtextboxes: ElementRef;

	constructor(public optService: OptDataService,private alertService: AlertService, private modalService: BsModalService,
		private loadingService: LoadingService, private router: Router, private commonUtil: CommonUtil, private sharedService: SharedService) {
		this.prepareFrameworkComponents();
		this.prepareAgGrid()

		this.overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Loading data...</span>';
		this.overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">No rows to show</span>';

		this.defaultColDef = {
			resizable: true,
			sortable: true,
		};

	}

	ngOnInit() {
		this.token = UserVariable.getUserToken();
		if (!this.commonUtil.validateToken(this.token)) {
			return;
		}
		this.optService.getOperatingDataList(this.token, this);
		this.optService.getOperatingDataTypes(this);

		if (this.navPanel.nativeElement.offsetHeight > 55) {
			this.paddingtop = 78
		} else {
			this.paddingtop = 50
		}
	}

	private prepareAgGrid() {
		this.columnDefs = [
			{
				headerName: 'Dependency',
				field: "dependencies",
				autoHeight: true,
				resizable: false,
				suppressSizeToFit: true,
				editable: false,
				cellClass: 'vAlign',
				cellRenderer: 'dependencyCellRenderer',
				cellStyle: { 'text-align': 'left' },
				singleClickEdit: true
			},
			{
				headerName: 'Operating Data Name',
				autoHeight: true,
				//resizable: true,
				suppressSizeToFit: false,
				editable: false,
				field: "operatingDataName",
				sort: "asc"
				//sortable: true 
			},
			{
				headerName: 'Operating Data Type',
				autoHeight: true,
				//resizable: false,
				suppressSizeToFit: false,
				editable: false,
				field: "operatingDataType"
			},
			{
				headerName: 'Edit',
				autoHeight: true,
				//resizable: true,
				suppressSizeToFit: true,
				editable: false,
				field: "valueListItems",
				cellRenderer: 'editCellRenderer',
				cellStyle: { 'text-align': 'center' },
				singleClickEdit: true
			},
			{
				headerName: 'Delete',
				autoHeight: true,
				//resizable: true,
				suppressSizeToFit: true,
				editable: false,
				cellClass: 'vAlign',
				cellRenderer: 'deleteCellRenderer',
				cellStyle: { 'text-align': 'center' },
				singleClickEdit: true
			}
		];
	}

	/**
	 * Preparing data for showing in the Ag-Grid view.
	 */
	private createRowData() {
		var rowData: any[] = [];
		this.operatingDataList.forEach(attribute => {
			rowData.push({
				id: attribute.id,
				dependencies: 'disable: true',
				operatingDataName: this.commonUtil.decodeSpecialChars(attribute.operatingDataName),
				operatingDataType: attribute.operatingDataType,
				valueListItems : attribute.valueListItems
			});

			var request: any = {
				"itemId": attribute.id,
				"itemType": 'OperatingData',
				"action": 'dependency',
				"userToken": UserVariable.getUserToken()
			};
			this.optService.getOperatingDataDependeciesWithId(request, this);
		});
		this.rowData = rowData;
	}

	/**
	 * Preparing custom cell renderers for the Ag-Grid
	 */
	private prepareFrameworkComponents() {
		this.frameworkComponents = {
			editCellRenderer: EditCellComponent,
			deleteCellRenderer: DeleteCellComponent,
			dependencyCellRenderer: DependencyCellComponent
		};
		//grid options
		this.gridOptions = <GridOptions>{
			context: {
				componentParent: this
			},
			enableColResize: true,
			enableSorting: true
		};
	}


	onGridReady(params: any) {
		this.gridApi = params.api
		this.gridColumnApi = params.columnApi;
		this.gridApi.hideOverlay()
		this.gridApi.resetRowHeights();
		this.gridApi.sizeColumnsToFit()
	}

	/**
	 * User clicks on the edit button on the Ag-Grid.
	 */
	public editOptData(data: any) {
		this.editOperatingDataModal = data;
		this.operatingDataTypeList.forEach(type => {
			if (type.id == data.operatingDataType) {
				// clean the previous selection
				this.selectedOperatingData = [];
				// add the new one
				this.selectedOperatingData.push(type);
			}

			if (data.operatingDataType == "Value List") {
				this.valueListDataType = true;
				this.editOrignalvalueListDataType = true;
				this.fieldArray = this.decodeSpecialCharsJsonArrayItems(JSON.parse(data.valueListItems));
				this.ValueListItems = this.decodeSpecialCharsJsonArrayItems(JSON.parse(data.valueListItems));
			} else {
				this.valueListDataType = false;
				this.editOrignalvalueListDataType = false;
			}
		});
		// set the name of the attribute
		this.newOperatingDataName =  this.commonUtil.decodeSpecialChars(data.operatingDataName);
		this.action = 'edit'; //set the current action
		let token = UserVariable.getUserToken();
		if (!this.commonUtil.validateToken(token)) {
			return;
		}
		var request: any = {
			"itemId": this.editOperatingDataModal.id,
			"itemType": 'OperatingData',
			"action": 'edit',
			"userToken": token
		};
			//this.optService.getOperatingdataDependancies(JSON.stringify(request), this);

			this.bsModalRef = this.modalService.show(this.updateTemplate, { class: 'modal-lg' });
	}

	decodeSpecialCharsJsonArrayItems(jsonArray :any){
		if (jsonArray.length > 0) {
			jsonArray.forEach(element => {              
				console.log(element.valueListItem);
				element.valueListItem = this.commonUtil.decodeSpecialChars(element.valueListItem);
			});
		}
		return jsonArray;
	}

	encodeSpecialCharsJsonArrayItems(jsonArray :any){
		if (jsonArray.length > 0) {
			jsonArray.forEach(element => {              
				console.log(element.valueListItem);
				element.valueListItem = this.commonUtil.encodeSpecialChars(element.valueListItem);
			});
		}
		return jsonArray;
	}

	/**
	 * User clicks on the delete button on the Ag-Grid. If there are dependencies
	 * then it will be shown otherwise it will ask to confirm the action.
	 */
	public deleteOperatingData(data: any) {
		this.deleteOperatingDataModal = data;
		this.action = 'delete'; //set the current action
		let token = UserVariable.getUserToken();
		if (!this.commonUtil.validateToken(token)) {
			return;
		}
		var request: any = {
			"itemId": this.deleteOperatingDataModal.id,
			"itemType": 'OperatingData',
			"action": 'delete',
			"userToken": token
		};
		//this.optService.getOperatingdataDependancies(JSON.stringify(request), this);
		const data_ = { userToken: this.token, id: this.deleteOperatingDataModal.id, name: '', type: '' };
		this.optService.deleteOperatingDataType(data_, this);
	}

	/**
 * User clicks on the dependency button on the Ag-Grid. If there are dependencies
 * then it will be shown otherwise it will show no dependencies
 */
	public showDependencies(data: any) {
		this.action = 'dependency'; //set the current action
		let token = UserVariable.getUserToken();
		if (!this.commonUtil.validateToken(token)) {
			return;
		}
		var request: any = {
			"itemId": data.id,
			"itemType": 'OperatingData',
			"action": 'dependency',
			"userToken": token
		};
		this.optService.getOperatingdataDependancies(JSON.stringify(request), this);
	}

	/**
	 * Shows the dependency list popup view.
	 * @param optList dependency list
	 */
	dependenciesModalWithComponentDelete(optList: any[]) {
		const initialState = {
			operatingDataDependency: optList,
			title: 'Operating Data Dependency List'
		};
		this.bsModalRef = this.modalService.show(DependencyModComponent, { initialState, backdrop: 'static', keyboard: false  });
		this.bsModalRef.content.closeBtnName = 'Close';
	}

	/**
	 * Shows the dependency list popup view.
	 * @param optList dependency list
	 */
	showDependencyModel(optList: any[]) {
		const initialState = {
			operatingDataDependency: optList,
			title: 'Delete Conflicts'
		};
		this.bsModalRef = this.modalService.show(DependencyModComponent, { initialState, backdrop: 'static', keyboard: false  });
		this.bsModalRef.content.closeBtnName = 'Close';
	}

	/**
	 * Shows the dependency list popup view.
	 * @param optList dependency list
	 */
	dependenciesModalWithComponentEdit(optList: any[]) {
		const initialState = {
			operatingDependency: optList,
			title: 'Edit Conflicts'
		};
		this.bsModalRef = this.modalService.show(DependencyModComponent, { initialState , backdrop: 'static', keyboard: false  });
		this.bsModalRef.content.closeBtnName = 'Close';
	}

	/**
	 * User clicks on the update button on the update popup view.
	 */
	updateButtonClick() {
		if (this.newOperatingDataName != undefined && this.newOperatingDataName != '' && this.selectedOperatingData.length != 0) {
			/* if (this.newOperatingDataName.includes("\\")) {
				this.newOperatingDataName = this.newOperatingDataName.replace(/\\/g, "\\\\");
			} */
			//sort array items alphabetically by valueListItem name
			this.ValueListItems = this.sortArrayAlphabetically(this.ValueListItems,'valueListItem');
			var optdataTemp = this.newOperatingDataName;
			 const data = {
				userToken: this.token, id: this.editOperatingDataModal.id,
				name:  this.commonUtil.encodeSpecialChars(optdataTemp),
				type: this.selectedOperatingData[0].text,
				isOperateDataTypeModified: this.isOperateDataTypeModified,
				valueListItems: JSON.stringify(this.encodeSpecialCharsJsonArrayItems(this.ValueListItems))
			};
			this.optService.updateOperatingDataType(data, this);
			this.loadingService.showLoading(true, false, 'Saving data', 0); 
		} else {
			this.alertService.error('Operating data name and type are required fields',false);
		}
	}

	/**
	 * Sort Array Alphabetically.
	 * @param objectArray
	 * @param sortingItem
	 */
	sortArrayAlphabetically (objectArray : any, sortingItem : any){
		function sortOn(property){
			return function(a, b){
				if(a[property] < b[property]){
					return -1;
				}else if(a[property] > b[property]){
					return 1;
				}else{
					return 0;   
				}
			}
		}
		if (objectArray != null && (sortingItem != null) && (sortingItem != undefined)) {
			objectArray.sort(sortOn(sortingItem));
		}
		
		return objectArray;
	}

	/**
	 * On change event of the operating data type change.
	 * @param value
	 */
	selectedOperatingDataTypeChange(event: any) {
		if (event.text == "Value List") {
			this.valueListDataType = true;
			this.fieldArray = [];
			this.ValueListItems = [];
			this.selectedOperatingData = [];
		} else {
			this.valueListDataType = false;
			this.clearOperatingDataAfterAdding();
		}
	}

	addValueListValue(index) {
		this.fieldArray.push(this.newAttribute);
		this.newAttribute = {};
	}

	deleteValueListItem(index) {
		this.fieldArray.splice(index, 1);
		this.ValueListItems.splice(index, 1);
	}

	/**
	 * On change event for value list item.
	 * @param event
	 * @param index
	 */
	onValueListItemChange(event: any, index: any) {
		if (this.ValueListItems.length > 0) {
			if (JSON.stringify(this.ValueListItems[index]) != undefined) {
				var object = {};
				object['valueListItem'] = event;
				object['index'] = index;

				this.ValueListItems[index] = object;
			} else {
				var object = {};
				object['valueListItem'] = event;
				object['index'] = index;

				this.ValueListItems.push(object);
			}
		} else {
			var object = {};
			object['valueListItem'] = event;
			object['index'] = index;

			this.ValueListItems.push(object);
		}
	}

	/**
	 * User clicks on the Add Operating button
	 * @param template UI template
	 */
	addOperatingDataButtonClick(template: TemplateRef<any>) {
		this.newOperatingDataName = "";
		this.selectedOperatingData = [];
		this.clearOperatingDataAfterAdding();
		this.bsModalRef = this.modalService.show(template, { class: 'modal-lg', backdrop: 'static', keyboard: false  });
	}

	/**
	 * User clicks on the add button on the add operating data popup view.
	 */
	addButtonClick() {
		if (this.newOperatingDataName != undefined && this.newOperatingDataName != '' && this.selectedOperatingData.length != 0) {
			/* if (this.newOperatingDataName.includes("\\")) {
				this.newOperatingDataName = this.newOperatingDataName.replace(/\\/g, "\\\\");
			} */
			var optDataName = this.newOperatingDataName;
			optDataName = this.commonUtil.encodeSpecialChars(optDataName);
			this.isUpdateOperatingData = false;
			var data = { operatingDataName: optDataName, userToken: this.token };
			this.optService.operatingDataNameAvailability(data, this);
			this.loadingService.showLoading(true, false, 'Saving data', 0);
		} else {
			this.alertService.error('Operating data name and type are required fields',false);
			
		}
	}

	/**
	 * Tracks successful web service calls.
	 * @param data payload
	 * @param serviceType type of the service
	 */
	onSuccess(data: WsResponse, serviceType: WsType) {
		this.loadingService.hideLoading();
		if (serviceType == WsType.GET_OPERATING_DATA_LIST) {
			this.operatingDataList = data.payload;
			//this.prepareAgGrid();
			this.createRowData();
		} else if (serviceType == WsType.GET_OPERATING_DATA_TYPES) {
			const valList = data.payload;
			valList.forEach(val => {
				this.operatingDataTypeList.push({ 'id': val, 'text': val });
			});
		} else if (serviceType == WsType.OPERATING_DATA_NAME_AVAILABILITY) {
			if (data.payload) {
				this.alertService.error('Operating data name is already exist',false);
			} else {
				this.loadingService.showLoading(true, false, 'Saving data', 0);
				var addData: any;
				if (this.selectedOperatingData[0].text == "Value List") {
					//sort array items alphabetically by valueListItem name
					this.ValueListItems = this.sortArrayAlphabetically(this.ValueListItems,'valueListItem');
					this.ValueListItems = this.encodeSpecialCharsJsonArrayItems(this.ValueListItems);
					addData = {
						userToken: this.token, operatingDataName: this.commonUtil.encodeSpecialChars(this.newOperatingDataName), operatingDataType: this.selectedOperatingData[0].text,
						valueListItems: JSON.stringify(this.ValueListItems)
					};
				} else {
					addData = { userToken: this.token, operatingDataName: this.commonUtil.encodeSpecialChars(this.newOperatingDataName), operatingDataType: this.selectedOperatingData[0].text };
				}
				this.optService.addNewOperatingDataType(addData, this);
				this.clearOperatingDataAfterAdding();
			}
		} else if (serviceType == WsType.ADD_OPERATING_DATA) {
			this.alertService.success(data.statusDescription);
			this.optService.getOperatingDataList(this.token, this);
			this.bsModalRef.hide();
			this.valueListDataType = false;
		} else if (serviceType == WsType.UPDATE_OPERATING_DATA) {
			this.alertService.success(data.statusDescription);
			this.optService.getOperatingDataList(this.token, this);
			this.bsModalRef.hide();
			this.valueListDataType = false;
			this.editOperatingDataModal = null;
		} else if (serviceType == WsType.DELETE_OPERATING_DATA) {
			this.alertService.success(data.statusDescription);
			this.optService.getOperatingDataList(this.token, this);
			this.deleteOperatingDataModal = null;
		} else if (serviceType == WsType.GET_OPERATING_DATA_DEPENDENCIES) {
			var payload = data.payload;
			console.log(this.action);
			if (this.action == 'edit') {
				if (payload.HasDependencies == 0) {
					// send edit request if there are no dependencies.
					this.bsModalRef = this.modalService.show(this.updateTemplate, { class: 'modal-lg', backdrop: 'static', keyboard: false });
				}
				else {
					//There are dependencies
					this.dependenciesModalWithComponentEdit(payload.operatingDependency);
				}
			}
			else if (this.action == 'delete') {
				if (payload.HasDependencies == 0) {
					// send delete request if there are no dependencies.
					this.showDeleteConfirmation();
				}
				else {
					//Prepare dependencies list
					var items: any[] = [];
					if (payload.TagClassDependency.TagClassifications.TagClassification.constructor.name == Object.name) {
						items.push(payload.TagClassDependency.TagClassifications.TagClassification);
					} else {
						items = payload.TagClassDependency.TagClassifications.TagClassification;
					}
					// show dependencies
					this.dependenciesModalWithComponentDelete(items);
				}
			}
			else if (this.action == 'dependency') {
				var items: any[] = [];
				if (payload.TagClassDependency.TagClassifications.TagClassification.constructor.name == Object.name) {
					items.push(payload.TagClassDependency.TagClassifications.TagClassification);
				} else {
					items = payload.TagClassDependency.TagClassifications.TagClassification;
				}
				// show dependencies
				this.dependenciesModalWithComponentDelete(items);
			}
			else {
				console.log(this.action);
			}
		} else if (serviceType == WsType.GET_OPERATING_DATA_DEPENDENCIES_WITH_ID) {
			let items = data.payload.split("_")
			if (items[1] == "Yes") {
				for (let i = 0; i < this.rowData.length; i++) {
					if (this.rowData[i].id == items[0]) {
						this.rowData[i]["dependencies"] = "disable: false"
						this.gridApi.setRowData(this.rowData);
					}
				}
			}
		}
	}

	/**
	 * Clear used models, variable values after add operating data
	 * 
	 */
	clearOperatingDataAfterAdding() {
		this.fieldArray = [];
		this.ValueListItems = [];
		this.valueListDataType = false;
		this.selectedOperatingData = [];
	}

	/**
	 * Tracks unsuccessful web service calls.
	 * @param data payload
	 * @param serviceType type of the service
	 */
	onFail(data: WsResponse, serviceType: WsType) {
		if (serviceType == WsType.GET_OPERATING_DATA_LIST) {
			this.alertService.error(data.statusDescription,false);
		} else if (serviceType == WsType.GET_OPERATING_DATA_TYPES) {
			this.alertService.error(data.statusDescription,false);
		} else if (serviceType == WsType.OPERATING_DATA_NAME_AVAILABILITY) {
			this.alertService.error(data.statusDescription,false);
		} else if (serviceType == WsType.ADD_OPERATING_DATA) {
			this.alertService.error(data.statusDescription,false);
		} else if (serviceType == WsType.UPDATE_OPERATING_DATA) {
			this.alertService.error(data.statusDescription,false);
		} else if (serviceType == WsType.DELETE_OPERATING_DATA) {
			this.alertService.error(data.statusDescription,false);
		} else if (serviceType == WsType.GET_TAG_DEPENDENCIES_BY_TAG) {
			this.alertService.error(data.statusDescription, false);
		}
		this.loadingService.hideLoading();
	}

	/**
	* Shows delete confirmation popup
	*/
	showDeleteConfirmation() {
		const initialState = {
			token: this.token,
			Id: '',
			confirmationTitle: 'Delete Confirm',
			confirmationMessage: 'Are you sure you want to delete this Operating Data?',
			callBack: this
		};
		this.bsModalRef = this.modalService.show(ConfirmModelComponent, { initialState ,backdrop: 'static', keyboard: false });
	}

	/**
	* User clicks on the delete button in the deletion confirmation popup
	*/
	onDeleteConfirm() {
		this.loadingService.showLoading(true, false, 'Deleting data', 0);
		const data_ = { userToken: this.token, id: this.deleteOperatingDataModal.id, name: '', type: '' };
		this.optService.deleteOperatingDataType(data_, this);
		this.bsModalRef.hide();
		this.valueListDataType = false;
	}

	/**
	 * User clicks on the decline button in the deletion confirmation popup
	 */
	onDeleteDecline() {
		this.clearOperatingDataAfterAdding();
		this.bsModalRef.hide()
	}

	closeOperatingDataModal() {
		this.clearOperatingDataAfterAdding();
		this.bsModalRef.hide();
		this.alertService.clear();
	}
	/**
	 * update isOperateDataTypeModified
	 * @param $event 
	 */
	onChange($event) {
		this.isOperateDataTypeModified = true;
	}

}
