
  <div class="modal-header">
      <h4 class="modal-title pull-left">{{ 'DICTIONARY_DELETE.DELETE_WORD' | translate }} </h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <div class="container">        
      </div>
  </div>

  <div class="modal-footer">
		<button type="button" class="btn btn-default" (click)="bsModalRef.hide()">{{ 'CANCEL' | translate}}</button>
		
      <button type="button" class="btn btn-warning" (click)="bsModalRef.hide();confirm();">{{'OK' | translate}}</button>
  </div>