<div>
	<div class="py-2 px-0 mt-2">
		<ul class="col-sm-12 px-0 py-2 list-group mt-2">
			<li *ngIf="enableViewOutputList" class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
				[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'opmanagement', 'custom-list-item-dark': selectedMenuItem != 'opmanagement'}"
				(click)="sideItemClick('opmanagement')">
				<h6 class="flaot-left ml-2 mb-0 sidebar-list-text">
					<i class="far fa-clipboard-list mx-2" aria-hidden="true"></i>
					{{ 'OUTPUT_MANAGEMENT.OM_SIDEBAR_OUTPUT_MANAGEMENT' | translate }}
				</h6>
			</li>
			<li *ngIf="enableViewOutputTemplate && manageProjectTemplates" class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
				[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'projecttemplate', 'custom-list-item-dark': selectedMenuItem != 'projecttemplate'}"
				(click)="sideItemClick('projecttemplate')">
				<h6 class="flaot-left ml-2 mb-0 sidebar-list-text">
					<i class="far fa-file-invoice mx-2" aria-hidden="true"></i>
					{{ 'OUTPUT_MANAGEMENT.OM_SIDEBAR_PROJECT_TEMPLATE' | translate }}
				</h6>
			</li>
			<ul class="col-sm-12 px-0 py-0 list-group rounded-0" >
				<li *ngIf="enableCreateOutputTemplate" class="list-group-item border-0 rounded-0 px-0 py-2 list-item-bg list-cursor" 
					(click)="sideItemClick('addprotemplate')" 
					[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'addprotemplate', 'custom-list-item-dark': selectedMenuItem != 'addprotemplate'}">
					<h6 class="flaot-left mb-0 ml-4">
						<i class="far fa-plus-square mx-2" aria-hidden="true"></i>
						{{ 'OUTPUT_MANAGEMENT.OM_SIDEBAR_ADD_PROJECT_TEMPLATE' | translate }}
					</h6>
				</li>
			</ul>
			<li *ngIf="enableViewOutputQueue && enableViewOutputList" class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
				[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'outputqueue', 'custom-list-item-dark': selectedMenuItem != 'outputqueue'}"
				(click)="sideItemClick('outputqueue')">
				<h6 class="flaot-left ml-2 mb-0 sidebar-list-text">
					<i class="far fa-sign-out mx-2" aria-hidden="true"></i>
					{{ 'OUTPUT_MANAGEMENT.OM_SIDEBAR_OUTPUT_QUEUE' | translate }}
				</h6>
			</li>
			<li *ngIf="enableViewOutputConfig" class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
				[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'outputconfig', 'custom-list-item-dark': selectedMenuItem != 'outputconfig'}"
				(click)="sideItemClick('outputconfig')">
				<h6 class="flaot-left ml-2 mb-0 sidebar-list-text">
					<i class="far fa-conveyor-belt-alt mx-2" aria-hidden="true"></i>
					{{ 'OUTPUT_MANAGEMENT.OM_SIDEBAR_OUTPUT_CONFIG' | translate }}
				</h6>
			</li>
		</ul>
	</div>
</div>