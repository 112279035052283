<!-- <alert class="floating-alert"></alert>

<nav id="navPanel" class="bg-light border-bottom box-shadow fixed-top navbar-expand-lg">
   <app-header></app-header>
</nav> -->

<!-- <div class="main-panel" [style.padding-top.px]="60" style="overflow: hidden;">   
  <div class="card-block card-body mt-2 pb-1 pt-1 card-primary" [ngClass]="{'content-hide': isMinimized, 'content-show': !isMinimized}">
      <div class="row">
         <div class="col-sm-12 my-2">
            <div class="row px-2">
               <div class="form-group col">
                     <label>{{ 'PROCEDURE_STORE.STATUS' | translate }} :</label>
                     <ng-select [active]="selectedSystem" [allowClear]="true" (removed)="removedSystem($event)" (selected)="selectedSystemId($event)"
                        [items]="systemList"  name="system"
                        id="system">
                     </ng-select>
               </div>
            </div>
         </div>
       </div>
      <ag-grid-angular #agGrid style="width: 100%; height: 400px;" id="procedureStoreInstance" [rowData]="rowData"  [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }" class="ag-theme-balham"
         [columnDefs]="columnDefs" [frameworkComponents]="frameworkComponents"  
         [enableRangeSelection]="true" [paginationPageSize]="10" [pagination]="true" (columnResized)="onColumnResized($event)"
         (gridReady)="onGridReady($event)" [suppressMenuHide]="true" [gridOptions]="gridOptions"></ag-grid-angular>
   </div>
</div> -->

<div class="main-panel pt-0 pb-4" style="overflow: hidden;">
	<div class="container-fluid">
		<div class="col-sm-12 px-0">
			<h3>
				{{ 'PROCEDURE_STORE.PS_SIDEBAR_PROCEDURE_INSTANCES' | translate }}
			</h3>
			<hr class="mt-0 mb-3" />
		</div>
		<div class="col-sm-12 px-0">
			<form>
				<div class="form-group">
					<label class="mb-0">{{ 'PROCEDURE_STORE.STATUS' | translate }} :</label>
					<ng-select [active]="selectedSystem" [allowClear]="true" (removed)="removedSystem($event)"
						(selected)="selectedSystemId($event)" [items]="systemList" name="system" id="system">
					</ng-select>
				</div>
			</form>
		</div>
		<hr class="mt-0 mb-3" />
		<div class="col-sm-12 px-0">
			<ag-grid-angular #agGrid style="width: 100%; height: 400px;" id="procedureStoreInstance" [rowData]="rowData" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }"
				class="ag-theme-balham" [columnDefs]="columnDefs" [frameworkComponents]="frameworkComponents"
				  [enableRangeSelection]="true" [paginationPageSize]="10" [defaultColDef]="defaultColDef"
				[pagination]="true" (columnResized)="onColumnResized($event)" (gridReady)="onGridReady($event)"
				[suppressMenuHide]="true" [gridOptions]="gridOptions"></ag-grid-angular>
		</div>
	</div>
</div>