import { Component } from "@angular/core";
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { TranslateService } from '@ngx-translate/core';
import { CapabilityService } from "src/app/util/capability.service";
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DocumentTypesService } from "../../document-types.service";
import { AlertService } from "src/app/util/alert/alert.service";
import { LoadingService } from "src/app/util/loading/loading.service";
import { SharedService } from "src/app/util/shared.service";
import { Constants } from "src/app/util/constants";
import { DependenciesmodalComponent } from "src/app/util/dependenciesmodal/dependenciesmodal.component";

@Component({
    selector: 'child-cell',
    template: '<button id="edit" [disabled]="disable" (click)="showDependencies()" class="btn btn-sm btn-outline-dark py-0 my-1 px-0 border-0"> <i class="far fa-link mx-2" aria-hidden="true"></i> </button>'

})
export class DTDependencyButtonRenderer implements ICellRendererAngularComp {
    public params: any;
    public title: string;
    public disable: boolean;
    public status: string = "";
    public dependencyType: string;
    public bsModalRef: BsModalRef;

    constructor(private capabilityService: CapabilityService, private modalService: BsModalService, private documentTypesService: DocumentTypesService, 
        private alertService: AlertService, private loadingService: LoadingService, private sharedService: SharedService, private translate: TranslateService){
    }

    agInit(params: any): void {
        this.params = params;
        this.translate.get('DOCUMENT_TYPE.DEPENDENCIES_LIST').subscribe((res: string) => {
            this.title = res;
        });
        if(params.value == 'disable: false'){
            this.disable = false;
        }
        else{
            this.disable = true;
        }
    }

    public showDependencies() {
        
      localStorage.setItem("isDelete", "false");
      this.dependenciesModalWithComponent(this.title, false, true);

    }

    public dependenciesModalWithComponent(header: String, dflag: boolean, cflag: boolean) {
        const initialState : object = {
            headerTitle: header,
            delFlag: dflag,
            cancelFlag: cflag,
            checkboxVal: this.status == "Inactive" ? true : false
        };
        this.sharedService.setFileId(this.params.data.attributeId);
        this.sharedService.setFileType(Constants.DEPENDENCY_ITEM_TYPE.DOCUMENTTYPEATTRIBUTE);
        this.sharedService.setDependencyMessageType(this.dependencyType);
        this.sharedService.setParent(this);
        this.bsModalRef = this.modalService.show(DependenciesmodalComponent, { initialState, class: 'modal-lg' });
    }

    refresh(): boolean {
        return false;
    }
}