import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorage } from '../../../util/localstorage.service';
import { TranslateService } from '@ngx-translate/core';
import { UserVariable } from '../../../util/common/user-variable';
import { AlertService } from '../../../util/alert/alert.service';
import { SharedService } from '../../../util/shared.service';
import { GridOptions, Module, AllModules } from "@ag-grid-enterprise/all-modules";
import { CapabilityService } from '../../../util/capability.service'
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { OutputManagementService } from '../outputmanagement.service'
import * as FileSaver from 'file-saver';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import * as moment from 'moment';
import { LoadingService } from '../../../util/loading/loading.service';
import { OutputHomeCheckboxRender } from '../cell-render/outputhomerender/outputhomecheckboxrender.component'
import { OutputHomeStatusButtonRender } from '../cell-render/outputhomerender/outputhomestatusbuttonrender.component'

@Component({
   selector: 'app-outputhome',
   templateUrl: './outputhome.component.html',
   styleUrls: ['./outputhome.component.css']
})
export class OutputHomeComponent implements OnInit {
   public isMinimized: boolean = false
   rowData: any[] = [];
   private gridApi;
   private gridColumnApi;
   public modules: Module[] = AllModules;
   columnDefs;
   public frameworkComponents;
   public gridOptions: GridOptions;
   public defaultColDef: any;
   om_translated_data: any = null;
   public statusList: Array<any> = [];
   public selectedStatus: Array<any> = [{ 'id': 'selectstatus', 'text': 'Select status' }, { 'id': '0', 'text': 'All' }];
   public itemTypeList: Array<any> = [];
   public selectedItemType: Array<any> = [{ 'id': 'selectedItemType', 'text': 'Select item type' }, { 'id': 'All', 'text': 'All' }];
   public outputTypeList: Array<any> = [];
   public selectedOutputType: Array<any> = [{ 'id': '0', 'text': 'All' }];
   bsEndValue: Date = new Date();
   bsStartValue: Date = new Date();
   startValue: any;
   endValue: any;
   onChangedStatusId: String = "selectstatus";
   onChangeItemTypeId: String = "selectedItemType";
   public onchangedOutputType: String = "All"
   loadingMessage: string;

   //capabilities
   public modifyOutput: boolean = false;

   // enable/disable buttons
   public enableModifyOutput: boolean = false;

   constructor(private outputManagementService: OutputManagementService, private sharedService: SharedService, private translate: TranslateService, private alertService: AlertService,
      private capabilityService: CapabilityService, private modalService: BsModalService, private router: Router, private loadingService: LoadingService) {
      this.enableDisableSideBarItem()
      let browserLang = translate.getBrowserLang();
      this.gridOptions = <GridOptions>{
         context: {
            componentParent: this
         }
      };
      this.defaultColDef = {
			resizable: true,
			sortable: true,
		};
      
      this.translate.get('OUTPUT_MANAGEMENT.LOADING_MESSAGE').subscribe((res: string) => {
         this.loadingMessage = res;
      });

      translate.getTranslation(browserLang).subscribe((res: string) => {
         this.om_translated_data = res['OUTPUT_MANAGEMENT']
         this.statusList = [{ 'id': 'selectstatus', 'text': 'Select status' }, { 'id': '0', 'text': 'All' },
         { 'id': '3', 'text': this.om_translated_data.SUCCESSFULLY_COMPLETED },
         { 'id': '4', 'text': this.om_translated_data.PROCESSING_FAILED },
         { 'id': '5', 'text': this.om_translated_data.OUTPUT_EMAILED },
         { 'id': '6', 'text': this.om_translated_data.EMAIL_SENDING_FAILED },
         { 'id': '7', 'text': this.om_translated_data.FILE_ISSUED },
         ]

         this.itemTypeList = [{ 'id': 'selectedItemType', 'text': 'Select item type' }, { 'id': 'All', 'text': 'All' },
         { 'id': 'TopicMap', 'text': this.om_translated_data.TOPICMAP },
         { 'id': 'Topic', 'text': this.om_translated_data.TOPIC },
         { 'id': 'CheckSheet', 'text': this.om_translated_data.CHECK_SHEET },
         { 'id': 'Mechanical Completion', 'text': this.om_translated_data.MECHANICAL_COMPLETION },
         { 'id': 'Static Completion', 'text': this.om_translated_data.STATIC_COMPLETION },
         { 'id': 'Comm Completion', 'text': this.om_translated_data.COMM_COMPLETION },
         { 'id': 'System Turnover', 'text': this.om_translated_data.SYSTEM_TURNOVER },
         { 'id': 'Procedure', 'text': this.om_translated_data.PROCEDURE },
         { 'id': 'Procedure Instance', 'text': this.om_translated_data.PROCEDURE_INSTANCE },
         { 'id': 'ICECourse', 'text': this.om_translated_data.ICE_Course },
         ]

         this.outputTypeList = [{ 'id': 'All', 'text': 'All' },
         { 'id': 'PDF', 'text': 'PDF' },
         { 'id': 'DOCX', 'text': 'DOCX' },
         { 'id': 'PROCEDURE_ZIP_PDF', 'text': 'PROCEDURE_ZIP_PDF' },
         { 'id': 'ICE_COURSE_ZIP', 'text': 'ICE_COURSE_ZIP' },         
         { 'id': 'STORE_DOC_ZIP_PDF', 'text': 'STORE_DOC_ZIP_PDF' },
         { 'id': 'XML', 'text': 'XML' },
         { 'id': 'XML_PROCEDURE_ZIP', 'text': 'XML_PROCEDURE_ZIP' },
         { 'id': 'XML_TOPICMAP_ZIP', 'text': 'XML_TOPICMAP_ZIP' },
         { 'id': 'XML_ZIP', 'text': 'XML_ZIP' },
         ]

         this.columnDefs = [
            {
               headerName: this.om_translated_data.DOCUMENT_NAME, 
               field: 'documentName', 
               autoHeight: true, 
               cellClass: "cell-wrap-text"
            },
            {
               headerName: this.om_translated_data.ITEM_TYPE, 
               field: 'itemType', 
               autoHeight: true, 
               cellClass: "cell-wrap-text"
            },
            {
               headerName: this.om_translated_data.ADDED_DATE, 
               field: 'addedTime', 
               autoHeight: true, 
               cellClass: "cell-wrap-text",
            },
            {
               headerName: this.om_translated_data.COMPLETED_DATE, 
               field: 'completedTime', 
               autoHeight: true, 
               cellClass: "cell-wrap-text"
            },
            {
               headerName: this.om_translated_data.INITIATED_USER, 
               field: 'initiatedUserFullName', 
               autoHeight: true, 
               cellClass: "cell-wrap-text"
            },
            {
               headerName: this.om_translated_data.OUTPUT_FILE, 
               field: 'prcStatus', 
               autoHeight: true, 
               cellClass: "cell-wrap-text", width: 50,
               cellRenderer: function (params) {
                  var eDiv = document.createElement('div');
                  if (params.data.prcStatus == 3 || params.data.prcStatus == 5 || params.data.prcStatus == 6 || params.data.prcStatus == 7) {
                     eDiv.innerHTML = '<button class="btn btn-sm btn-outline-dark py-0 my-1 px-0 border-0">' +
                        '<i class="far fa-file-alt mx-2" aria-hidden="true"></i>' +
                        '</button>';
                     var eButton = eDiv.querySelectorAll('.btn-sm')[0];
                     eButton.addEventListener('click', function () {
                        loadingService.showLoading(true, null, "Downloading", null)
                        outputManagementService.downloadFile(params.data.id, params.data.documentName)
                           .subscribe(response => {
                              loadingService.hideLoading()
                              let file = new Blob([response]);
                              FileSaver.saveAs(file, params.data.documentName);
                           },
                              error => {
                                 loadingService.hideLoading()
                                 alertService.error(this.fa_translated_data.ERR_DOWNLOAD_FILE)
                              });
                     });
                  } else {
                     eDiv.innerHTML = '';
                  }
                  return eDiv;
               }
            },
            {
               headerName: this.om_translated_data.DRAFT, 
               field: 'draftDocument', 
               autoHeight: true, 
               cellClass: "cell-wrap-text",
               width:50
            },
            {
               headerName: this.om_translated_data.OUTPUT_TYPE, 
               field: 'outputType', 
               autoHeight: true, 
               cellClass: "cell-wrap-text"
            },
            {
               headerName: this.om_translated_data.FILE_ISSUED, 
               field: 'prcStatus', 
               autoHeight: true, 
               cellClass: "cell-wrap-text", 
               width: 50,
               cellRendererFramework: OutputHomeCheckboxRender, hide: !this.enableModifyOutput
            },
            {
               headerName: this.om_translated_data.STATUS, 
               field: 'processStatus', 
               autoHeight: true, 
               cellClass: "cell-wrap-text", 
               minWidth: 195,
               cellRendererFramework: OutputHomeStatusButtonRender
            },
         ]
      });
   }

   ngOnInit() {
      this.bsStartValue.setDate(this.bsStartValue.getDate() - 7);
      //this.startdate.subtract(7, "days").toDate();
     
   }

   onColumnResized(event) {
      if (event.finished) {
         this.gridApi.resetRowHeights();
      }
   }

   onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      params.api.sizeColumnsToFit();
      this.getAllOutputs();
      setTimeout(function () {
         params.api.sizeColumnsToFit();
         params.api.resetRowHeights();
      }, 500);
   }

   public getAllOutputs() {
      this.rowData = []
   }

   public selectedStatusId(event) {
      this.onChangedStatusId = event.id
      if (this.onChangeItemTypeId == "selectedItemType" && this.onChangedStatusId == "selectstatus") {
         this.getAllOutputs();
      } else {
         this.getSearchedOutputData()
      }
   }

   public selectedOutputTypeId(event) {
      this.onchangedOutputType = event.id
      if (this.onchangedOutputType == "All" ) {
         this.onchangedOutputType = "";
         this.getSearchedOutputData()
      } else {
         this.getSearchedOutputData()
      }
   }

   public removedStatus(event) {
      this.selectedStatus = [{ 'id': 'selectstatus', 'text': 'Select status' }]
   }

   public removedOutputType(event) {
      this.selectedOutputType = [{ 'id': 'selectstatus', 'text': 'Select status' }]
   }

   public selectedItemTypeId(event) {
      this.onChangeItemTypeId = event.id
      if (this.onChangeItemTypeId == "selectedItemType" && this.onChangedStatusId == "selectstatus") {
         this.getAllOutputs();
      } else {
         this.getSearchedOutputData()
      }
   }

   public removedItemType(event) {
      this.selectedItemType = [{ 'id': 'selectedItemType', 'text': 'Select item type' }]
   }

   public onStartValueChange(value: Date): void {
      this.startValue = value
      if (this.onChangeItemTypeId == "selectedItemType" && this.onChangedStatusId == "selectstatus") {
         this.getAllOutputs();
      } else {
         this.getSearchedOutputData()
      }
   }

   public onEndValueChange(value: Date): void {
      this.endValue = value
      if (this.onChangeItemTypeId == "selectedItemType" && this.onChangedStatusId == "selectstatus") {
         this.getAllOutputs();
      } else {
         this.getSearchedOutputData()
      }
   }

   public getSearchedOutputData() {
      let now = moment(this.endValue);
      let then = moment(this.startValue);
      if (now > then) {
         if (this.onChangeItemTypeId != "selectedItemType" && this.onChangedStatusId != "selectstatus") {
            this.alertService.clear()
            this.rowData = []
            this.loadingService.showLoading(true, null, this.loadingMessage, null)
            if (this.onchangedOutputType == "All") {
               this.onchangedOutputType = "";
            }
            //console.log("AAAAAAAAAAAAAAAAA : " +this.onchangedOutputType)
            this.outputManagementService.getOutputSearchResults(then.format("MM/DD/YYYY"), now.format("MM/DD/YYYY"), 
                  this.onChangedStatusId, this.onChangeItemTypeId, this.onchangedOutputType)
               .subscribe(data => {
                  this.loadingService.hideLoading()
                  this.rowData = data.payload;
                  this.gridApi.sizeColumnsToFit();
               },
                  error => {
                     this.loadingService.hideLoading()
                     this.alertService.clear()
                     this.alertService.error(error.statusDescription)
                  });
         } else {
            this.alertService.clear()
            if(this.onChangeItemTypeId != "selectedItemType"){
               this.alertService.error("Please select a status.")
            }else{
               this.alertService.error("Please select an item type.")
            }
         }
      } else {
         this.alertService.clear()
         this.alertService.error("End Date should be greater than Start date.")
      }
   }

   public viewProjectTemplate(){
      this.router.navigateByUrl('outputaddprojecttemp');
   }

   public viewOutputQueue(){
      this.router.navigateByUrl('outputqueue');
   }

   public viewOutputConfig(){
      this.router.navigateByUrl('outputconfig');
   }

   private enableDisableSideBarItem() {
      this.checkCapabilities()
      this.enableModifyOutput = this.modifyOutput
   }

   public checkCapabilities() {
      let modifyOutputCapability: string = "modifyOutput"
      this.modifyOutput = this.capabilityService.isCapabilityAssigned(modifyOutputCapability)
   }

}
