import { AlertService } from 'src/app/util/alert/alert.service';
import { CapabilityService } from 'src/app/util/capability.service';
import { Component, OnInit, EventEmitter } from '@angular/core';
import { SharedService } from 'src/app/util/shared.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-as-sidebar',
	templateUrl: './as-sidebar.component.html',
	styleUrls: ['./as-sidebar.component.scss'],
	outputs: ['selectSideBarItem']
})
export class AsSidebarComponent implements OnInit {

	public selectedMenuItem: string = 'documenttype'
	selectSideBarItem = new EventEmitter<any>()
	private subsVar: Subscription

	//capabilities
	public createClient: boolean = false
	public createproject: boolean = false
	public createUser: boolean = false
	public queryClient: boolean = false
	public queryProject: boolean = false
	public queryUser: boolean = false
	public queryTopicType: boolean = false
	public queryDocumentTypeAttribute: boolean = false
	public queryCategory: boolean = false 
	public queryRoles: boolean = false
	public queryChecksheetPunchListImages: boolean = false
	public viewWorkflowTemplates: boolean = false
	public createWorkflowTemplates: boolean = false

	// enable/disable buttons
	public enableAddClient: boolean = false
	public enableAddProject: boolean = false
	public enableAddUser: boolean = false
	public enableLoadClients: boolean = false
	public enableLoadProjects: boolean = false
	public enableLoadUsers: boolean = false
	public enableLoadDocumentTypes: boolean = false
	public enableLoadDocumentTypeAttribute: boolean = false 
	public enableLoadCategory: boolean = false
	public enableLoadRoles: boolean = false 
	public enableLoadChecksheetPunchListImages: boolean = false 

	constructor(private sharedService: SharedService, private capabilityService: CapabilityService,private alertService: AlertService) { 
		this.enableDisableSideBarItem()
	}

	ngOnInit() {
	}

	ngAfterContentInit(){
		this.subsVar = this.sharedService.viewSideBarResponse().subscribe(
			(options) => {
				this.sideItemClick(options.item, false);		
			}
		)
	}

	public sideItemClick(selectedItem: string, isItemClicked: boolean) {
		console.log('[sideItemClick] (selectedItem) ' + selectedItem);
		if (isItemClicked) {
			this.alertService.clear();
		} 
		if (selectedItem == "aeclients") {
			selectedItem = selectedItem.substring(0,8)
			console.log(selectedItem)
		} else if(selectedItem == "editprojects") {
			console.log(selectedItem)
		} else if(selectedItem == "edituser"){
			console.log(selectedItem)
		} else if(selectedItem == "addEditWfTemplate"){
			console.log(selectedItem)
		}else {
			this.sharedService.setFileId("-1");
		}
		this.sharedService.setLoadedComponent(selectedItem)
		this.selectedMenuItem = selectedItem
		this.selectSideBarItem.emit(
			{
				selectedItem: selectedItem
			}
		) 
	}

	 /**
     * enable/disable buttons or columns
     */
	private enableDisableSideBarItem() {
		this.checkCapabilities()
		this.enableAddClient = this.createClient
		this.enableAddProject = this.createproject
		this.enableAddUser = this.createUser
		this.enableLoadClients =  this.queryClient
		this.enableLoadProjects = this.queryProject
		this.enableLoadUsers =  this.queryUser
		this.enableLoadDocumentTypes = this.queryTopicType
		this.enableLoadDocumentTypeAttribute = this.queryDocumentTypeAttribute
		this.enableLoadCategory = this.queryCategory
		this.enableLoadRoles = this.queryRoles
		this.enableLoadChecksheetPunchListImages = this.queryChecksheetPunchListImages
	}

	/**
     * check the capabilities for given type
     */
	public checkCapabilities() {

		let createClientCapability: string = "createClient"
		let createProjectCapability: string = "createProject"
		let createUserCapability: string = "createUser"

		let queryClientCapability: string = "queryClients"
		let queryProjectCapability: string = "queryProjects"
		let queryUserCapability: string = "queryUser"
		let queryDocumentTypeCapability: string = "queryTopicType"
		let queryDocumentTypeAttributeCapability: string ="queryDocTypeAttribute"
		let queryCategoryCapability: string = "queryCategory"
		let queryUserRoles: string = "queryUserRoles"
		let queryChecksheetPunchListImages: string = "queryChecksheetPunchListImages"
		let viewWorkflowTemplates: string = "viewWorkflowTemplates"
		let addWorkflowTemplates: string = "createWorkflowTemplates"

		this.createClient = this.capabilityService.isCapabilityAssigned(createClientCapability)
		this.createproject = this.capabilityService.isCapabilityAssigned(createProjectCapability)
		this.createUser = this.capabilityService.isCapabilityAssigned(createUserCapability)

		this.queryClient = this.capabilityService.isCapabilityAssigned(queryClientCapability)
		this.queryProject = this.capabilityService.isCapabilityAssigned(queryProjectCapability)
		this.queryUser = this.capabilityService.isCapabilityAssigned(queryUserCapability)
		this.queryTopicType = this.capabilityService.isCapabilityAssigned(queryDocumentTypeCapability)
		this.queryDocumentTypeAttribute = this.capabilityService.isCapabilityAssigned(queryDocumentTypeAttributeCapability)
		this.queryCategory = this.capabilityService.isCapabilityAssigned(queryCategoryCapability)
		this.queryRoles = this.capabilityService.isCapabilityAssigned(queryUserRoles)
		this.queryChecksheetPunchListImages = this.capabilityService.isCapabilityAssigned(queryChecksheetPunchListImages)
		this.viewWorkflowTemplates = this.capabilityService.isCapabilityAssigned(viewWorkflowTemplates)
		this.createWorkflowTemplates = this.capabilityService.isCapabilityAssigned(addWorkflowTemplates)
	}

}
