<div class="row">
	<div *ngIf="refreshButton()" class="col-sm-12 my-3 px-0">
		<button *ngIf="fileData.type!='ContentItem'" id="checkin" tooltip="{{ 'FILES_DATA.CHECK_IN' | translate }}" type="button" [disabled]="!isCheckIn"
		 (click)="openModal(template, 'sm')" class="btn btn-outline-primary btn-sm ml-1 mr-1 mb-1">
			<i class="fas fa-download" aria-hidden="true"></i>
		</button>
		<button *ngIf="fileData.type=='ContentItem'" id="checkInContent" tooltip="{{ 'FILES_DATA.CHECK_IN' | translate }}" type="button" [disabled]="!isCheckIn"
		 (click)="openModal(contentTemplate, 'sm')" class="btn btn-outline-primary btn-sm ml-1 mr-1 mb-1">
			<i class="fas fa-download" aria-hidden="true"></i>
		</button>
		<button id="checkout" tooltip="{{ 'FILES_DATA.CHECK_OUT' | translate }}" type="button" [disabled]="!isCheckOut" (click)="checkOut()" class="btn btn-outline-primary btn-sm ml-1 mr-1 mb-1">
			<i class="fas fa-upload" aria-hidden="true"></i>
		</button>
		<button *ngIf="shwBuildTopicMap" id="buildTopicMap" tooltip="{{ 'FILES_DATA.BUILD_TOPIC_Map' | translate }}" type="button" (click)="buildTopicMap()"
		 class="btn btn-outline-primary btn-sm ml-1 mr-1 mb-1">
			<i class="fas fa-code-merge" aria-hidden="true"></i>
		</button>
		<button *ngIf="shwBuildTopicMap" id="viewTopicMap" tooltip="{{ 'FILES_DATA.VIEW_TOPICMAP' | translate }}" type="button" (click)="viewTopicMap()"
		 class="btn btn-outline-primary btn-sm ml-1 mr-1 mb-1">
			<i class="fas fa-eye" aria-hidden="true"></i>
		</button>
		<button type="button" id="undoCheckout" tooltip="{{ 'FILES_DATA.UNDO_CHECK_OUT' | translate }}" [disabled]="!isUndo" (click)="undo()"
		 class="btn btn-outline-primary btn-sm ml-1 mr-1 mb-1">
			<i class="far fa-undo-alt" aria-hidden="true"></i>
		</button>
		<button *ngIf="statusEnabled && isActive" id="inactive" type="button" tooltip="{{activeInactive}}" (click)="active()" class="btn btn-outline-danger btn-sm ml-1 mr-1 mb-1">
			<i class="fas fa-times" aria-hidden="true"></i>
		</button>
		<button *ngIf="statusEnabled && !isActive" id="active" type="button" tooltip="{{activeInactive}}" (click)="active()" class="btn btn-outline-primary btn-sm ml-1 mr-1 mb-1">
			<i class="fas fa-check" aria-hidden="true"></i>
		</button>
		<button *ngIf="!statusEnabled" id="activeInactive" type="button" tooltip="{{activeInactive}}" [disabled]="true" (click)="active()" class="btn btn-outline-primary btn-sm ml-1 mr-1 mb-1">
			<i *ngIf="isActive" class="fas fa-circle" aria-hidden="true"></i>
			<i *ngIf="!isActive" class="fas fa-circle" aria-hidden="true"></i>
		</button>
		<button *ngIf="showDownload" id="download" tooltip="{{ 'FILES_DATA.DOWNLOAD' | translate }}" type="button" (click)="download()" class="btn btn-outline-primary btn-sm ml-1 mr-1 mb-1">
			<i class="fas fa-file-download" aria-hidden="true"></i>
		</button>
		<button type="button" id="delete" tooltip="{{ 'FILES_DATA.DELETE' | translate }}" [disabled]="!isDelete" (click)="delete()" class="btn btn-outline-primary btn-sm ml-1 mr-1 mb-1">
			<i class="fas fa-trash-alt" aria-hidden="true"></i>
		</button>
		<button *ngIf="showGerenateOP" id="generateOP" tooltip="{{ 'FILES_DATA.GENERATE_OUTPUT' | translate }}" [disabled]="!genPDFEnable" type="button"
		 (click)="generateOutput()" class="btn btn-outline-primary btn-sm ml-1 mr-1 mb-1">
			<i class="fas fa-forward" aria-hidden="true"></i>
		</button>
		<button *ngIf="fileData!=null" id="fileDataDuplicate" type="button" tooltip="{{ 'FILES_DATA.DUPLICATE' | translate }} {{fileData.type}}" [disabled]="!duplicateEnable"
		 (click)="duplicate()" class="btn btn-outline-primary btn-sm ml-1 mr-1 mb-1">
			<i class="fas fa-copy" aria-hidden="true"></i>
		</button>
		<button *ngIf="fileData==null" id="duplicate" type="button" tooltip="{{ 'FILES_DATA.DUPLICATE' | translate }}" [disabled]="!duplicateEnable"
		 (click)="duplicate()" class="btn btn-outline-primary btn-sm ml-1 mr-1 mb-1">
			<i class="fas fa-copy" aria-hidden="true"></i>
		</button>
		<button *ngIf="showworkFlow" id="workflowAction" tooltip="{{ 'FILES_DATA.WORKFLOW_ACTION' | translate }}" [disabled]="!queryWorflow" type="button"
		 (click)="workflow()" class="btn btn-outline-primary btn-sm ml-1 mr-1 mb-1">
			<i class="fas fa-project-diagram" aria-hidden="true"></i>
		</button>
		<button type="button" id="edit" tooltip="{{ 'FILES_DATA.EDIT' | translate }}" [disabled]="!isEdit" (click)="edit()" class="btn btn-outline-primary btn-sm ml-1 mr-1 mb-1">
			<i class="fas fa-file-edit" aria-hidden="true"></i>
		</button>
		<button type="button" id="revisions" tooltip="{{ 'FILES_DATA.REVISIONS' | translate }}" (click)="openModal(revisionTemplate, 'lg')" class="btn btn-info btn-sm ml-1 mr-1 mb-1">
			<i class="fas fa-scroll-old" aria-hidden="true"></i>
		</button>
		<button type="button" id="propertiesAndAttributes" tooltip="{{ 'FILES_DATA.PROPERTIES_AND_ATTRIBUTES' | translate }}" (click)="showProperties()" class="btn btn-info btn-sm ml-1 mr-1 mb-1">
			<i class="fas fa-info mx-1" aria-hidden="true"></i>
		</button>
	</div>

	<div class="col-sm-6 my-3 px-0" *ngIf="true">
		<button type="button" id="enableAll" tooltip="{{ 'FILES_DATA.ENABLE_ALL' | translate }}" (click)="enableAll()" class="btn btn-outline-primary btn-sm ml-1 mr-1">
			<i class="fa fa-check-circle" aria-hidden="true"></i>
		</button>
		<button type="button" id="disableAll" tooltip="{{ 'FILES_DATA.DISABLE_ALL' | translate }}" (click)="disableAll()" class="btn btn-outline-primary btn-sm ml-1 mr-1">
			<i class="fa fa-times-circle" aria-hidden="true"></i>
		</button>
		<button type="button" id="checkinAll" tooltip="{{ 'FILES_DATA.CHECKIN_ALL' | translate }}" (click)="openModal(checkinAllTemplate, 'lg')"
		 class="btn btn-outline-primary btn-sm ml-1 mr-1">
			<i class="fa fa-download fa-lg" aria-hidden="true"></i>
		</button>
		<button type="button" id="checkoutAll" tooltip="{{ 'FILES_DATA.CHECKOUT_ALL' | translate }}" (click)="checkoutAll()" class="btn btn-outline-primary btn-sm ml-1 mr-1">
			<i class="fa fa-upload" aria-hidden="true"></i>
		</button>
		<button type="button" id="deleteAll" tooltip="{{ 'FILES_DATA.DELETE_ALL' | translate }}" (click)="deleteAll()" class="btn btn-outline-primary btn-sm ml-1 mr-1">
			<i class="fa fa-trash-o" aria-hidden="true"></i>
		</button>
		<button type="button" id="undoCheckoutAll" tooltip="{{ 'FILES_DATA.UNDO_CHECKOUT_ALL' | translate }}" (click)="undoCheckoutAll()" class="btn btn-outline-primary btn-sm ml-1 mr-1">
			<i class="fa fa-undo" aria-hidden="true"></i>
		</button>
		<button type="button" id="duplicateAll" tooltip="{{ 'FILES_DATA.DUPLICATE_ALL' | translate }}" (click)="duplicateAll()" class="btn btn-outline-primary btn-sm ml-1 mr-1">
			<i class="fa fa-files-o" aria-hidden="true"></i>
		</button>
		<button type="button" id="workflowActionAll" tooltip="{{ 'FILES_DATA.WORKFLOW_ACTION_ALL' | translate }}" (click)="workFlowActionAll()" class="btn btn-outline-primary btn-sm ml-1 mr-1">
			<i class="fa fa-link" aria-hidden="true"></i>
		</button>
		<button type="button" id="checkinMultipleImages" tooltip="{{ 'FILES_DATA.CHECKIN_MULTIPLE_IMAGES' | translate }}" (click)="openModal(getMultipleImagesExistanceTemplate, 'lg')"
		 class="btn btn-outline-primary btn-sm ml-1 mr-1">
			<i class="far fa-file" aria-hidden="true"></i>
		</button>
		<!--button type="button"  *ngIf="fileData.type=='ThreeDModel'" id="checkInmultipleThreeDModel" tooltip="{{ 'FILES_DATA.CHECK_IN_MULTIPLE_THREEDMODELS' | translate }}" 
		(click)="openModal(checkinMultipleThreeDModelTemplate, 'lg')" class="btn btn-outline-primary btn-sm ml-1 mr-1">
		   <i class="fa fa-cubes" aria-hidden="true"></i>
	   </button-->
	   <button type="button" id="checkInmultipleThreeDModel" tooltip="{{ 'FILES_DATA.CHECK_IN_MULTIPLE_THREEDMODELS' | translate }}" 
		(click)="openModal(checkinMultipleThreeDModelTemplate, 'lg')" class="btn btn-outline-primary btn-sm ml-1 mr-1">
		   <i class="fa fa-cubes" aria-hidden="true"></i>
	   </button>
	</div>

	<div class="col-sm-12 my-2 px-0" *ngIf="isShow()">
		<div class="panel panel-default">
			<div class="panel-heading panel-header-border-bottom py-1">
				<div class="row px-2">
					<div class="col-sm-12 px-0" (click)="isMinimized = !isMinimized">
						<div class="float-left pt-1 px-1">
							<p class="m-0 pl-1"><b>{{ 'FILES_DATA.PROPERTIES_AND_ATTRIBUTES' | translate }}</b>
							</p>
						</div>
						<div class="float-right pt-1 px-0">
							<button type="button" class="btn btn-sm btn-outline-dark float-right pl-1 pr-1 pt-0 pb-0 ml-1 mr-1 mb-1 pointer-cursor border-0"
							 (click)="$event.stopPropagation(); show = false">
								<i class="fa fa-times" aria-hidden="true"></i>
							</button>
							<button type="button" class="btn btn-sm btn-outline-dark float-right pl-1 pr-1 pt-0 pb-0 ml-1 mr-1 mb-1 pointer-cursor border-0"
							 (click)="$event.stopPropagation(); isMinimized = !isMinimized">
								<i class="fa " [ngClass]="{'fa-chevron-down': isMinimized, 'fa-chevron-up': !isMinimized}" aria-hidden="true"></i>
							</button>
						</div>
					</div>
				</div>
			</div>
			<div *ngIf="isShow() && show" class="card-block card-body mt-1 mb-4 py-1 card-primary" [ngClass]="{'content-hide': isMinimized, 'content-show': !isMinimized}">
				<ngb-tabset [justify]="'justified'" (tabChange)="change($event)">
					<ngb-tab id="properties">
						<ng-template *ngIf="activeId=='properties'" ngbTabTitle>
							<span class="select-tab-title">{{ 'FILES_DATA.PROPERTIES' | translate }}</span>
						</ng-template>
						<ng-template *ngIf="activeId!='properties'" ngbTabTitle>
							<span class="tab-title">{{ 'FILES_DATA.PROPERTIES' | translate }}</span>
						</ng-template>
						<ng-template ngbTabContent>
							<app-files-properties></app-files-properties>
						</ng-template>
					</ngb-tab>
					<ngb-tab id="attributes">
						<ng-template *ngIf="activeId=='attributes'" ngbTabTitle>
							<span class="select-tab-title">{{ 'FILES_DATA.ATTRIBUTES' | translate }}</span>
						</ng-template>
						<ng-template *ngIf="activeId!='attributes'" ngbTabTitle>
							<span class="tab-title">{{ 'FILES_DATA.ATTRIBUTES' | translate }}</span>
						</ng-template>
						<ng-template ngbTabContent>
							<app-files-attributes></app-files-attributes>
						</ng-template>
					</ngb-tab>
				</ngb-tabset>
			</div>
		</div>
	</div>
</div>

<!-- checkin comments modal -->
<ng-template #template>
	<div class="modal-header">
		<h4 class="modal-title pull-left">{{ 'FILES_DATA.CHECK_IN' | translate}}</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<label>{{ 'FILES_DATA.COMMENTS' | translate}}</label>
		<textarea class="form-control" #comments></textarea>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="checkIn(comments.value)">
			{{ 'FILES_DATA.OK' | translate}}</button>
		<button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="bsModalRef.hide()">{{ 'FILES_DATA.CANCEL'
			| translate}}</button>
	</div>
</ng-template>

<ng-template #contentTemplate>
	<div class="modal-header">
		<!--Title-->
		<h4 class="modal-title pull-left">{{ 'NEW_FILE.CREATE_NEW_FILE' | translate }}</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>

	<!--Body-->
	<div class="modal-body">
		<form ngNativeValidate id="new_file_form">
			<!--File upload-->
			<div class="form-group">
				<label for="NewFile_FileUpload">{{ 'NEW_FILE.USER_FILE.NAME' | translate }}</label>
				<input type="file" class="form-control-file" id="NewFile_FileUpload" accept=".doc, .docx, .ppt, .pptx, .pdf" (change)="onFileChanged($event)"
				 required>
				<small id="emailHelp" class="form-text text-muted">{{ 'NEW_FILE.USER_FILE.MESSAGE' | translate }}</small>
			</div>

			<!--Title-->
			<div class="form-group">
				<label for="NewFile_Title">{{ 'NEW_FILE.TITLE.NAME' | translate }}</label>
				<input type="text" class="form-control" id="NewFile_Title" [(ngModel)]="uploadFileName" name="docTitle" aria-describedby="title"
				 placeholder="{{ 'NEW_FILE.TITLE.PLACE_HOLDER' | translate }}" required>
			</div>

			<!--User comments-->
			<div class="form-group">
				<label for="NewFile_Comments">{{ 'NEW_FILE.COMMENTS.NAME' | translate }}</label>
				<textarea type="text" class="form-control" id="NewFile_Comments" name="newFile" [(ngModel)]="fileComment" aria-describedby="comments"
				 placeholder="{{ 'NEW_FILE.COMMENTS.PLACE_HOLDER' | translate }}"></textarea>
			</div>

			<!--Submit Button-->
			<div class="form-group row float-right mr-0">
				<button type="submit" class="btn btn-primary" (click)="onSubmitButtonClick()">{{ 'SUBMIT' | translate }}</button>
			</div>

		</form>
	</div>

</ng-template>

<!-- revisions modal -->
<ng-template #revisionTemplate>
	<div class="modal-header">
		<h4 class="modal-title pull-left">{{ 'FILES_DATA.REVISIONS' | translate}}</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<app-files-revisions></app-files-revisions>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="bsModalRef.hide()">{{ 'FILES_DATA.CANCEL'
			| translate}}</button>
	</div>
</ng-template>

<!-- checkin All comments modal -->
<ng-template #checkinAllTemplate>
	<div class="modal-header">
		<h4 class="modal-title pull-left">{{ 'FILES_DATA.CHECK_IN' | translate}}</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<label>{{ 'FILES_DATA.COMMENTS' | translate}}</label>
		<textarea class="form-control" #comments></textarea>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="checkinAll(comments.value)">
			{{ 'FILES_DATA.OK' | translate}}</button>
		<button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cancel()">{{ 'FILES_DATA.CANCEL'
			| translate}}</button>
	</div>
</ng-template>

<!-- get Images Existance modal -->
<ng-template #getMultipleImagesExistanceTemplate>
	<div class="modal-header">
		<h4 class="modal-title pull-left">{{ 'FILES_DATA.CREAT_IMAGES' | translate}}</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div>
			<label>{{ 'FILES_DATA.CREAT_IMAGES' | translate}}</label>
			<input #image type="file" ng2FileSelect [uploader]="uploader" multiple accept="image/png,image/gif,video/mp4,image/jpeg"
			 (change)="handleFileInput($event.target.files)" /><br/>
		</div>
		<label>{{ 'FILES_DATA.COMMENTS' | translate}}</label>
		<textarea class="form-control" #comments></textarea>
		<label>{{ 'FILES_DATA.ATTRIBUTES' | translate}}</label>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="getImageExistence(comments.value)">
			{{ 'FILES_DATA.CONTINUE' | translate}}</button>
		<button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cancel()">{{ 'FILES_DATA.CANCEL'
			| translate}}</button>
	</div>
</ng-template>

<!-- checkin Multiple Images modal -->
<ng-template #checkinMultipleImagesTemplate>
	<div class="modal-header">
		<h4 class="modal-title pull-left">{{ 'FILES_DATA.MULTIPLE_IMAGES' | translate}}</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div>
			<label>You can only checkin the images that exist in the system.</label>
		</div>
		<table id="classTable" class="table table-bordered">
			<thead>
				<th>{{ 'FILES_DATA.FILE_NAME' | translate}}</th>
				<th>{{ 'FILES_DATA.FILE_STATUS' | translate}}</th>
			</thead>
			<tbody *ngFor="let data of rowData">
				<tr>
					<td>{{data.fileName}}</td>
					<td>{{data.fileStatus}}</td>
				</tr>
			</tbody>
		</table>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="checkinMultipleImages()">
			{{ 'FILES_DATA.CHECK_IN' | translate}}</button>
		<button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cancel()">{{ 'FILES_DATA.CANCEL'
			| translate}}</button>
	</div>
</ng-template>
<!-- Multiple Action Alert modal -->
<ng-template #alertToShowMultipleActions>
	<div class="modal-header">
		<h4 class="modal-title pull-left">{{ 'FILES_DATA.ALERT' | translate}}</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<p *ngFor="let item of items">{{item}}
		</p>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="ok()">
			{{ 'FILES_DATA.OK' | translate}}</button>
	</div>
</ng-template>

<!-- checkin Multiple ThreeDModels modal -->
<!--ng-template #checkinMultipleThreeDModelTemplate>
	<div class="modal-header">
		<h4 class="modal-title pull-left">{{ 'FILES_DATA.MULTIPLE_THREED_MODELS' | translate}}</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div>
			<label>You can only checkin the 3D Models that exist in the system.</label>
		</div>
		<table id="classTable" class="table table-bordered">
			<thead>
				<th>{{ 'FILES_DATA.FILE_NAME' | translate}}</th>
				<th>{{ 'FILES_DATA.FILE_STATUS' | translate}}</th>
			</thead>
			<tbody *ngFor="let data of rowData">
				<tr>
					<td>{{data.fileName}}</td>
					<td>{{data.fileStatus}}</td>
				</tr>
			</tbody>
		</table>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="checkinMultipleThreeDModels()">
			{{ 'FILES_DATA.CHECK_IN' | translate}}</button>
		<button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cancel()">{{ 'FILES_DATA.CANCEL'
			| translate}}</button>
	</div>
</ng-template-->

  <!-- checkin Multiple 3D model  -->
  <!--ng-template #checkinMultipleThreeDModelTemplate>
	<div class="modal-header">
		<h4 class="modal-title pull-left">{{ 'FILES_DATA.MULTIPLE_THREED_MODELS' | translate}}</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div>
			<label>{{ 'FILES_DATA.CREAT_THREED_MODELS' | translate}}</label>
			<input #threedmodel type="file" ng2FileSelect [uploader]="uploader" multiple
				accept="zip"
				(change)="handleThreeDModelFileInput($event.target.files)" /><br />
		</div>
		<label>{{ 'FILES_DATA.COMMENTS' | translate}}</label>
		<textarea class="form-control" #comments></textarea>
		<label>{{ 'FILES_DATA.ATTRIBUTES' | translate}}</label>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="checkinMultipleThreeDModels(comments.value)">
			{{ 'FILES_DATA.CONTINUE' | translate}}</button>
		<button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cancel()">{{ 'FILES_DATA.CANCEL'
			| translate}}</button>
	</div>
</ng-template-->