import { SearchParams } from './search-params.modal'

export class SearchTextBox extends SearchParams<string> {
    controlType = 'textbox'
    type: string

    constructor(options: {} = {}) {
        super(options)
        this.type = options['type'] || ''
    }
}