import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { UserVariable } from '../../../util/common/user-variable';
import { ServiceUrls } from '../../../util/service-urls';
import { WsResponse } from '../../../util/ws-response.model';
import { WsCallback } from '../../../util/ws-callback.interface';
import { WsType } from '../../../util/ws-type';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators'
import { Observable, throwError } from 'rxjs';
import { CommonUtil } from '../../../util/common/common-util';

@Injectable()
export class FilesDataService {
	
   
	
    private callback: WsCallback;

    constructor(private http: HttpClient, private commonUtil: CommonUtil) {
    }

    public setCallback(callBack: WsCallback) {
        this.callback = callBack;
    }

	/**
     * enable documents.
     * @param itemList Item List
     */
    enableAll(itemList: any, callback: WsCallback = this.callback) {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        let url = ServiceUrls.ENABLE_ALL;
        const options = { params: new HttpParams().set("token", token).set("itemList", itemList) }

        this.http.get(url, options).
            subscribe(
                data => {
                    var modified = JSON.parse(JSON.stringify(data));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                    callback.onSuccess(res, WsType.MULTIPLE_ACTIONS);
                },
                error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    callback.onFail(res, WsType.MULTIPLE_ACTIONS);
                });
    }

    /**
     * Checkout document by item id and item type.
     * @param itemId Item Id
     * @param itemType Item type
     */
    checkoutDocumentById(itemId: String, itemType: String, callback: WsCallback = this.callback) {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        let url = this.getcheckoutDocumentByIdURL(token);

        this.http.post(url, JSON.stringify(new Request(itemId, itemType))).
            subscribe(
                data => {
                    var modified = JSON.parse(JSON.stringify(data));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                    callback.onSuccess(res, WsType.CM_SYSTEM_CHECKOUT);
                },
                error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    callback.onFail(res, WsType.CM_SYSTEM_CHECKOUT);
                });
    }

    /**
     * Undo checkout document by item id and item type.
     * @param itemId Item Id
     * @param itemType Item type
     */
    undoCheckoutDocumentById(itemId: String, itemType: String, callback: WsCallback = this.callback) {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        let url = this.getundoCheckoutDocumentByIdURL(token);

        this.http.post(url, JSON.stringify(new Request(itemId, itemType))).
            subscribe(
                data => {
                    var modified = JSON.parse(JSON.stringify(data));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                    callback.onSuccess(res, WsType.CM_SYSTEM_UNDO_CHECKOUT);
                },
                error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    callback.onFail(res, WsType.CM_SYSTEM_UNDO_CHECKOUT);
                });
    }


	/**
     * Get basic file data using file id.
     * * @param fileId File Id
     */
    getBasicFileDataUndoCheckout(fileId: String, fileType: String, callback: WsCallback = this.callback) {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        let url = this.getBasicFileDataByIdURL(fileId, fileType);
        const options = { params: new HttpParams().set("token", token) }

        this.http.get(url, options).
            subscribe(
                data => {
                    var modified = JSON.parse(JSON.stringify(data));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                    callback.onSuccess(res, WsType.BASIC_FILE_DATA_UNDO_CHECKOUT);
                },
                error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    callback.onFail(res, WsType.BASIC_FILE_DATA_UNDO_CHECKOUT);
                });
    }


    /**
     * Get basic file data using file id.
     * * @param fileId File Id
     */
    getBasicFileData(fileId: String, fileType: String, callback: WsCallback = this.callback, action:string = "checkout") {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        let url = this.getBasicFileDataByIdURL(fileId, fileType);
        const options = { params: new HttpParams().set("token", token) }

        this.http.get(url, options).
            subscribe(
                data => {
                    var modified = JSON.parse(JSON.stringify(data));
                    modified.payload.action = action;
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                    callback.onSuccess(res, WsType.BASIC_FILE_DATA);
                },
                error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    callback.onFail(res, WsType.BASIC_FILE_DATA);
                });
    }

    /**
     * Get basic file details using file id and file type to validate details of the file before generate output.
     * @param fileId File Id
     * @param fileType File Type
     */
    getFileDetails(fileId: String, fileType: String){
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        let url = this.getBasicFileDataByIdURL(fileId, fileType);
        const options = { params: new HttpParams().set("token", token) }

        return this.http.get(url, options).
			pipe(
				map(response => {
					var modified = JSON.parse(JSON.stringify(response));
					return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				}),
				catchError(error => {
					var modified = JSON.parse(JSON.stringify(error.error));
					var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
					return throwError(res);
				})
			);
    }

    /**
    * Get basic file data using file id for check-in operation.
    * @param fileId File Id
    */
    getBasicFileDataForCheckin(fileId: String, fileType: String, callback: WsCallback = this.callback) {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        let url = this.getBasicFileDataByIdURL(fileId, fileType);
        const options = { params: new HttpParams().set("token", token) }

        this.http.get(url, options).
            subscribe(
                data => {
                    var modified = JSON.parse(JSON.stringify(data));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                    callback.onSuccess(res, WsType.BASIC_FILE_DATA_CHECKIN);
                },
                error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    callback.onFail(res, WsType.BASIC_FILE_DATA_CHECKIN);
                });
    }

    /**
    * Define URL for checkout document by id.
    */
    public getcheckoutDocumentByIdURL(token: String) {
        let url = ServiceUrls.CM_SYSTEM_DOCUMENTS + 'checkoutDocumentById.json?token=' + token;
        return url;
    }

    /**
    * Define URL for undo checkout document by id.
    */
    public getundoCheckoutDocumentByIdURL(token: String) {
        let url = ServiceUrls.CM_SYSTEM_DOCUMENTS + 'undoCheckoutDocumentById.json?token=' + token;
        return url;
    }


    /**
    * Define URL for get basic topic by topic id.
    */
    public getBasicFileDataByIdURL(fileId: String, fileType: String) {
        switch (fileType) {
            case "Topic":
                return ServiceUrls.TOPICS + fileId + '/getBasicTopic.json';
            case "Procedure":
                return ServiceUrls.PROCEDURES + fileId + '/getBasicProcedure.json';
            case "TopicMap":
                return ServiceUrls.TOPIC_MAPS + fileId + '/getBasicTopicMap.json';
            case "ContentItem":
                return ServiceUrls.CONTENTS + fileId + '/getBasicContent.json';
            case "Image":
                return ServiceUrls.IMAGES + fileId + '/getBasicImage.json';
            case "ICECourse":
                return ServiceUrls.ICECOURSE + fileId + '/getBasicICECourse.json';  
            case "ThreeDModel":
                return ServiceUrls.THREED_MODELS + fileId + '/getBasic3DModel.json';
            case "Video":
                return ServiceUrls.VIDEOS + fileId + '/getBasicVideo.json';
        }
    }

    /**
     * Checkin documents from file data view.
     * @param itemId Document Id. 
     * @param itemType Document Type.
     * @param checkinComments Checkin comments.
     */
    ckeckinDocumentFromFileData(itemId: String, itemType: String, checkinComments: String, callback: WsCallback = this.callback) {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        let url = this.getCheckinDocumentFileDataURL(token);
        this.http.post(url, JSON.stringify(new checkinRequest(itemId, itemType, checkinComments))).
            subscribe(
                data => {
                    var modified = JSON.parse(JSON.stringify(data));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callback.onSuccess(res, WsType.CHECKIN_DOCUMENT_FILE_DATA);
                },
                error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    callback.onFail(res, WsType.CHECKIN_DOCUMENT_FILE_DATA);
                });
    }


    /**
     * Define URL for checkin document from files data
     */
    public getCheckinDocumentFileDataURL(token: String) {
        let url = ServiceUrls.CM_SYSTEM_DOCUMENTS + 'checkinDocumentById.json?token=' + token;
        return url;
    }

    /**
     * Checkin icecourse.
     * @param id Course Id. 
     * @param courseVersion Course Version. 
     */
    checkinICECourse(id: String, courseVersion: String, checkingComment: String, callback: WsCallback = this.callback) {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        let url = ServiceUrls.checkinICECourse(token);
        this.http.post(url, JSON.stringify(new iceCoursecheckinRequest(id, courseVersion, checkingComment))).
            subscribe(
                data => {
                    var modified = JSON.parse(JSON.stringify(data));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callback.onSuccess(res, WsType.CHECKIN_ICE_COURSE);
                },
                error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    callback.onFail(res, WsType.CHECKIN_ICE_COURSE);
                });
    }

    /**
     * Checkout icecourse.
     * @param id Course Id.  
     */
    checkoutICECourse(id: String, callback: WsCallback = this.callback) {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        let url = ServiceUrls.checkoutICECourse(token);
        this.http.post(url, JSON.stringify(new iceCoursecheckoutRequest(id))).
            subscribe(
                data => {
                    var modified = JSON.parse(JSON.stringify(data));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callback.onSuccess(res, WsType.CHECKOUT_ICE_COURSE);
                },
                error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    callback.onFail(res, WsType.CHECKOUT_ICE_COURSE);
                });
    }

    /**
     * Undo Checkout icecourse.
     * @param id Course Id. 
     */
    undoCheckoutICECourse(id: String, callback: WsCallback = this.callback) {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        let url = ServiceUrls.undoCheckoutICECourse(token);
        this.http.post(url, JSON.stringify(new iceCourseUndocheckoutRequest(id))).
            subscribe(
                data => {
                    var modified = JSON.parse(JSON.stringify(data));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callback.onSuccess(res, WsType.UNDO_CHECKOUT_ICE_COURSE);
                },
                error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    callback.onFail(res, WsType.UNDO_CHECKOUT_ICE_COURSE);
                });
    }


    /**
     * disable documents.
     * @param itemList Item List
     */

    disableAll(itemList: any, callback: WsCallback = this.callback) {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        let url = ServiceUrls.DISABLE_ALL;
        const options = { params: new HttpParams().set("token", token).set("itemList", itemList) }

        this.http.get(url, options).
            subscribe(
                data => {
                    var modified = JSON.parse(JSON.stringify(data));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                    callback.onSuccess(res, WsType.MULTIPLE_ACTIONS);
                },
                error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    callback.onFail(res, WsType.MULTIPLE_ACTIONS);
                });
    }

    checkinAll(itemList: any, checkinComment: string, callback: WsCallback = this.callback) {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        let url = ServiceUrls.CHECKIN_ALL;
        const options = { params: new HttpParams().set("token", token).set("itemList", itemList).set("checkinComment", checkinComment) }

        this.http.get(url, options).
            subscribe(
                data => {
                    var modified = JSON.parse(JSON.stringify(data));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                    callback.onSuccess(res, WsType.MULTIPLE_ACTIONS);
                },
                error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    callback.onFail(res, WsType.MULTIPLE_ACTIONS);
                });
    }

    checkoutAll(itemList: any, callback: WsCallback = this.callback) {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        let url = ServiceUrls.CHECKOUT_ALL;
        const options = { params: new HttpParams().set("token", token).set("itemList", itemList) }

        this.http.get(url, options).
            subscribe(
                data => {
                    var modified = JSON.parse(JSON.stringify(data));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                    callback.onSuccess(res, WsType.MULTIPLE_ACTIONS);
                },
                error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    callback.onFail(res, WsType.MULTIPLE_ACTIONS);
                });
    }

    deleteAll(itemList: any, callback: WsCallback = this.callback) {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        let url = ServiceUrls.DELETE_ALL;
        const options = { params: new HttpParams().set("token", token).set("itemList", itemList) }

        this.http.get(url, options).
            subscribe(
                data => {
                    var modified = JSON.parse(JSON.stringify(data));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                    callback.onSuccess(res, WsType.MULTIPLE_ACTIONS);
                },
                error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    callback.onFail(res, WsType.MULTIPLE_ACTIONS);
                });
    }

    undoCheckoutAll(itemList: any, callback: WsCallback = this.callback) {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        let url = ServiceUrls.UNDO_CHECKOUT_ALL;
        const options = { params: new HttpParams().set("token", token).set("itemList", itemList) }

        this.http.get(url, options).
            subscribe(
                data => {
                    var modified = JSON.parse(JSON.stringify(data));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                    callback.onSuccess(res, WsType.MULTIPLE_ACTIONS);
                },
                error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    callback.onFail(res, WsType.MULTIPLE_ACTIONS);
                });
    }

    duplicateAll(itemList: any, callback: WsCallback = this.callback) {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        let url = ServiceUrls.DUPLICATE_ALL;
        const options = { params: new HttpParams().set("token", token).set("itemList", itemList) }

        this.http.get(url, options).
            subscribe(
                data => {
                    var modified = JSON.parse(JSON.stringify(data));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                    callback.onSuccess(res, WsType.MULTIPLE_ACTIONS);
                },
                error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    callback.onFail(res, WsType.MULTIPLE_ACTIONS);
                });
    }

    getImageExistenceStatus(files: FormData, callBack: WsCallback) {
        let url = ServiceUrls.CHECKIN_MULTIPLE_IMAGES;
       
        this.http.post(url,files,{
            reportProgress: true,
            observe: 'events'
          }).
          subscribe(
            event => {
                if (event.type == HttpEventType.UploadProgress) {
                    //progress
                  } else if (event.type == HttpEventType.Response) {
                    var modified = JSON.parse(JSON.stringify(event.body));
                    var res = new WsResponse(
                      modified.status.description,
                      modified.status.code,
                      modified.status.name,
                      modified.payload);
                    this.callback.onSuccess(res, WsType.MULTIPLE_ACTIONS);
                  }
                },
                error => {
                    if (error.error.payload != undefined) {
                      var val = (error as HttpErrorResponse).error;
                      var modified = JSON.parse(JSON.stringify(val));
                      var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    this.callback.onFail(res, WsType.MULTIPLE_ACTIONS);
                } else {
                    var res: WsResponse;
                    if (error.error.actionErrors != undefined) {
                      res = new WsResponse(error.statusText + ' - ' + error.error.actionErrors);
                    } else {
                      res = new WsResponse(error.message);
                    }
                    callBack.onFail(res, WsType.MULTIPLE_ACTIONS);
                  }
                });
    }

    checkingMultipleImages(itemListDom: any, folderId: string, comment: string, nodes: string) {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        let url = ServiceUrls.CHECKIN_MULTIPLE_IMAGES;
        const options = { params: new HttpParams().set("token", token).set("itemListDom", itemListDom).set("folderId", folderId).set("comment", comment).set("nodes", nodes) }

        this.http.get(url, options).
            subscribe(
                data => {
                    var modified = JSON.parse(JSON.stringify(data));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                    this.callback.onSuccess(res, WsType.MULTIPLE_ACTIONS);
                },
                error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    this.callback.onFail(res, WsType.MULTIPLE_ACTIONS);
                });
    }

    /**
     * Do check out an image
     * @param request  {imageId, userToken}
     * @param callBack callback class
     */
    public checkOutImage(request: any, callBack: WsCallback) {
        this.http.post(ServiceUrls.CHECK_OUT_IMAGE, request).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.CHECK_OUT_IMAGE);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.CHECK_OUT_IMAGE);
                } else {
                    //browser related issues
                    var res = new WsResponse("Unknown error happened");
                    callBack.onFail(res, WsType.CHECK_OUT_IMAGE);
                }
            }
        );
    }


    /**
     * Undo check out an image
     * @param request  {imageId, userToken}
     * @param callBack callback class
     */
    public undoCheckOutImage(request: any, callBack: WsCallback) {
        this.http.post(ServiceUrls.UNDO_CHECK_OUT_IMAGE, request).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.UNDO_CHECK_OUT_IMAGE);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.UNDO_CHECK_OUT_IMAGE);
                } else {
                    //browser related issues
                    var res = new WsResponse("Unknown error happened");
                    callBack.onFail(res, WsType.UNDO_CHECK_OUT_IMAGE);
                }
            }
        );
    }

    //checkout a 3D model
    checkOutThreeDModel( request: any, callBack: WsCallback) {
		this.http.post(ServiceUrls.CHECK_OUT_THREED_MODEL, request).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.CHECK_OUT_THREED_MODEL);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.CHECK_OUT_THREED_MODEL);
                } else {
                    //browser related issues
                    var res = new WsResponse("Unknown error happened");
                    callBack.onFail(res, WsType.CHECK_OUT_THREED_MODEL);
                }
            }
        );
	}

    //undo checkout a 3D model
    undoCheckOutThreeDModel(request: any, callBack: WsCallback) {
        this.http.post(ServiceUrls.UNDO_CHECK_OUT_THREED_MODEL, request).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.UNDO_CHECK_OUT_THREED_MODEL);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.UNDO_CHECK_OUT_THREED_MODEL);
                } else {
                    //browser related issues
                    var res = new WsResponse("Unknown error happened");
                    callBack.onFail(res, WsType.UNDO_CHECK_OUT_THREED_MODEL);
                }
            }
        );
	}

    /**
	 * Get otput type  by doc type. 
	 */
   public getOutputTypeListByProjectIdDocType(docType: string,): Observable<WsResponse> {
      let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
      const options = { params: new HttpParams().set("token", token).set("docType", docType) }
      return this.http.get(ServiceUrls.GET_OUTPUT_LIST_FOR_DOC_TYPE, options).
         pipe(
            map(response => {
               var modified = JSON.parse(JSON.stringify(response));
               return new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
            }),
            catchError(error => {
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
               return throwError(res)
            })
         );
   }

   /**
    * Check out a file.
    * @param request {fileId, userToken}
    * @param callBack callback class
    */
   public checkOutFile(request: any, callBack: WsCallback) {
    this.http.post(ServiceUrls.FILE_CHECKOUT, request).subscribe(
        data => {
            var modified = JSON.parse(JSON.stringify(data));
            var res = new WsResponse(
                modified.status.description,
                modified.status.code,
                modified.status.name,
                modified.payload);
            callBack.onSuccess(res, WsType.FILE_CHECKOUT);
        },
        error => {
            if (error.status != '') {
                var val = (error as HttpErrorResponse).error;
                var modified = JSON.parse(JSON.stringify(val));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onFail(res, WsType.FILE_CHECKOUT);
            } else {
                //browser related issues
                var res = new WsResponse("Unknown error happened");
                callBack.onFail(res, WsType.FILE_CHECKOUT);
            }
        }
    );
}

/**
 * Undo file checkout.
 * @param request {imageId, userToken}
 * @param callBack callback class
 */
public undoCheckOutFile(request: any, callBack: WsCallback) {
    this.http.post(ServiceUrls.FILE_CHECKOUT_UNDO, request).subscribe(
        data => {
            var modified = JSON.parse(JSON.stringify(data));
            var res = new WsResponse(
                modified.status.description,
                modified.status.code,
                modified.status.name,
                modified.payload);
            callBack.onSuccess(res, WsType.FILE_CHECKOUT_UNDO);
        },
        error => {
            if (error.status != '') {
                var val = (error as HttpErrorResponse).error;
                var modified = JSON.parse(JSON.stringify(val));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onFail(res, WsType.FILE_CHECKOUT_UNDO);
            } else {
                //browser related issues
                var res = new WsResponse("Unknown error happened");
                callBack.onFail(res, WsType.FILE_CHECKOUT_UNDO);
            }
        }
    );
}
    /**
     * get image base64data
     * @param id 
     * @param imagePath 
     */
    getImage(id: string, imagePath: string) {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        let url = ServiceUrls.IMAGE_SEARCH_IMAGE_ON_CLICK + '?token=' + token;
        return this.http.post(url, JSON.stringify(new ImageByPath(id, imagePath ))).
            pipe(
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                }),
                catchError(error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    return throwError(res)
                })
            );

    }

   
    /**
     *  view/ download content items (pdf, doc, docx, ppt, pptx)
     * @param projectId 
     * @param fileId 
     * @param mode 
     */
    downloadFile(projectId: string, fileId: string, mode: string) {
        return this.http.get(ServiceUrls.DOWNLOAD_FILE, {
           responseType: "blob", params: new HttpParams().
              set("fileName", fileId).set("mode", mode).set("projectId", projectId)
        });
    }


    /**
     * view/ download content items (pdf, doc, docx, ppt, pptx)
     * @param fileName 
     */
    viewContentItem(fileName: string){
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        let url = ServiceUrls.VIEW_CONTENT_ITEM
        const options = { params: new HttpParams().set("token", token).set("fileName", fileName)}
        return this.http.get(url, options).pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
		);
    }
     //checkout a 3D model
     checkOutVideo( request: any, callBack: WsCallback) {
		this.http.post(ServiceUrls.CHECK_OUT_VIDEO, request).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.CHECK_OUT_VIDEO);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.CHECK_OUT_VIDEO);
                } else {
                    //browser related issues
                    var res = new WsResponse("Unknown error happened");
                    callBack.onFail(res, WsType.CHECK_OUT_VIDEO);
                }
            }
        );
	}
    
    private saveToFileSystem(response) {
        const contentDispositionHeader: string = response.headers.get('Content-Disposition');
        const parts: string[] = contentDispositionHeader.split(';');
        const filename = parts[1].split('=')[1];
        const blob = new Blob([response._body], { type: 'application/zip' });
        //saveAs(blob, filename);
    }
//undo checkout a video
undoCheckOutVideo(request: any, callBack: WsCallback) {
    this.http.post(ServiceUrls.UNDO_CHECK_OUT_VIDEO, request).subscribe(
        data => {
            var modified = JSON.parse(JSON.stringify(data));
            var res = new WsResponse(
                modified.status.description,
                modified.status.code,
                modified.status.name,
                modified.payload);
            callBack.onSuccess(res, WsType.UNDO_CHECK_OUT_VIDEO);
        },
        error => {
            if (error.status != '') {
                var val = (error as HttpErrorResponse).error;
                var modified = JSON.parse(JSON.stringify(val));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onFail(res, WsType.UNDO_CHECK_OUT_VIDEO);
            } else {
                //browser related issues
                var res = new WsResponse("Unknown error happened");
                callBack.onFail(res, WsType.UNDO_CHECK_OUT_VIDEO);
            }
        }
    );
}

getThreeDModelExistenceStatus(files: FormData, callBack: WsCallback) {
   
    let url = ServiceUrls.GET_THREED_MODELS_EXISTENCE;
  
    this.http.post(url,files,{
        reportProgress: true,
        observe: 'events'
      }).
      subscribe(
        event => {
            if (event.type == HttpEventType.UploadProgress) {
              //progress
            } else if (event.type == HttpEventType.Response) {
              var modified = JSON.parse(JSON.stringify(event.body));
              var res = new WsResponse(
                modified.status.description,
                modified.status.code,
                modified.status.name,
                modified.payload);
              callBack.onSuccess(res, WsType.MULTIPLE_ACTIONS);
            }
          },
          error => {
            if (error.error.payload != undefined) {
              var val = (error as HttpErrorResponse).error;
              var modified = JSON.parse(JSON.stringify(val));
              var res = new WsResponse(
                modified.status.description,
                modified.status.code,
                modified.status.name,
                modified.payload);
              callBack.onFail(res, WsType.MULTIPLE_ACTIONS);
            } else {
              var res: WsResponse;
              if (error.error.actionErrors != undefined) {
                res = new WsResponse(error.statusText + ' - ' + error.error.actionErrors);
              } else {
                res = new WsResponse(error.message);
              }
              callBack.onFail(res, WsType.MULTIPLE_ACTIONS);
            }
          }
        );
}

checkinMultipleVideos(files: FormData, callBack: WsCallback) {
    let url = ServiceUrls.CHECKIN_MULTIPLE_VIDEOS;
  
    this.http.post(url,files,{
        reportProgress: true,
        observe: 'events'
      }).
      subscribe(
        event => {
            if (event.type == HttpEventType.UploadProgress) {
              //progress
            } else if (event.type == HttpEventType.Response) {
              var modified = JSON.parse(JSON.stringify(event.body));
              var res = new WsResponse(
                modified.status.description,
                modified.status.code,
                modified.status.name,
                modified.payload);
              callBack.onSuccess(res, WsType.MULTIPLE_ACTIONS);
            }
          },
          error => {
            if (error.error.payload != undefined) {
              var val = (error as HttpErrorResponse).error;
              var modified = JSON.parse(JSON.stringify(val));
              var res = new WsResponse(
                modified.status.description,
                modified.status.code,
                modified.status.name,
                modified.payload);
              callBack.onFail(res, WsType.MULTIPLE_ACTIONS);
            } else {
              var res: WsResponse;
              if (error.error.actionErrors != undefined) {
                res = new WsResponse(error.statusText + ' - ' + error.error.actionErrors);
              } else {
                res = new WsResponse(error.message);
              }
              callBack.onFail(res, WsType.MULTIPLE_ACTIONS);
            }
          }
        );
}
     
	onSuccess(data: WsResponse, serviceType: WsType) {

	}

	onFail(data: WsResponse, serviceType: WsType) {
		// if (serviceType == WsType.CHECK_ICE_COURSE_FILENAME) {
		// 	this.alertService.error(data.statusDescription, false);
		// }
	}


}
class Request {
    constructor(private itemId: String, private itemType: String) { }

}

class AllRequest {
    constructor(private itemList: any) { }
}

class checkinRequest {
    constructor(private itemId: String, private itemType: String,
        private checkinComments: String) { }
}

class iceCoursecheckinRequest {
    constructor(private id: String, private courseVersion: String, private comment:String) { }
}

class iceCoursecheckoutRequest {
    constructor(private id: String) { }
}

class iceCourseUndocheckoutRequest {
    constructor(private id: String) { }
}

class checkinImagesRequest {
    constructor(private folderId: string, private folderTitle: String) { }
}

class ImageByPath{
	constructor(private id: string, private imagePath: string) { }
}

class checkinMultipleThreeDModelRequest {
    constructor(private token: string ,private itemList: any, private folderId: string, private title: any,private files: any) { }
}
