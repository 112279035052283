import { Injectable } from "@angular/core";
import { WsCallback } from "../../util/ws-callback.interface";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { ServiceUrls } from "../../util/service-urls";
import { WsResponse } from "../../util/ws-response.model";
import { WsType } from "../../util/ws-type";

@Injectable()
export class SessionValidationService {
    constructor(private http:HttpClient){}

    public validateSession(callBack: WsCallback) {
        this.http.post(ServiceUrls.SESSION_VALIDATION, null).subscribe(
            data => {
                // this may be incorrect
                //var val = (data as HttpErrorResponse).message;
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.SESSION_VALIDATION);
            },
            error => {
                if (error.status != null) {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.SESSION_VALIDATION);
                } else {
                    //browser related issues
                    var res = new WsResponse("Unknown error happened");
                    callBack.onFail(res, WsType.SESSION_VALIDATION);
                }
            }
        );
    }
}
