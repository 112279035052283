import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { ServiceUrls } from '../util/service-urls';
import { WsResponse } from '../util/ws-response.model';
import { WsCallback } from '../util/ws-callback.interface';
import { WsType } from '../util/ws-type'; import { catchError } from 'rxjs/operators';
import { CommonUtil } from 'src/app/util/common/common-util';
import { UserVariable } from '../util/common/user-variable';

@Injectable({
	providedIn: 'root'
})
export class OptDataService {

	public token: string;
	//private callback: WsCallback;
	private rowData: any = null;
	private apiGridData: any = null;

	constructor(private http: HttpClient, private commonUtil: CommonUtil) {
	}



	public setApiGridData(apiGridData: any) {
		this.apiGridData = apiGridData;
	}

	public getApiGridData() {
		return this.apiGridData;
	}

	public setRowData(rowData: any) {
		this.rowData = rowData;
	}

	public getRowData() {
		return this.rowData;
	}

	/**
 * Get Operatingdata dependencies.
 * @param request string such as {"itemId":"", "itemType":"", "action":"", "userToken":""}
 * @param wsCallBack callback component.
 */
	public getOperatingdataDependancies(request: string, wsCallBack: WsCallback) {
		console.log(request);
		this.http.post(ServiceUrls.GET_OPERATING_DATA_DEPENDENCIES, request).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_OPERATING_DATA_DEPENDENCIES);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_OPERATING_DATA_DEPENDENCIES);
				} else {
					//browser related issues
					wsCallBack.onFail(res, WsType.GET_OPERATING_DATA_DEPENDENCIES);
				}
			}
		);
	}

	/**
 * Get operating data list
 * @param wsCallBack callback class
 */

	public getOperatingDataTypes(wsCallBack: WsCallback) {
		this.http.get(ServiceUrls.GET_OPERATING_DATA_TYPES).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				console.log(modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_OPERATING_DATA_TYPES);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_OPERATING_DATA_TYPES);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_OPERATING_DATA_TYPES);
				}
			}
		);
	}

	/**
 * Get operatingdata list for a project
 * @param token user token
 * @param wsCallBack callback class
 */
	public getOperatingDataList(token: string, wsCallBack: WsCallback) {
		this.http.get(ServiceUrls.getOperatingDataList(token)).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_OPERATING_DATA_LIST);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_OPERATING_DATA_LIST);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_OPERATING_DATA_LIST);
				}
			}
		);
	}

	/**
 * Update an existing operatingdata.
 * @param data {"userToken":"","id":"","name":"","type":""}
 * @param wsCallBack callback class
 */
	public updateOperatingDataType(data: any, wsCallBack: WsCallback) {
		this.http.post(ServiceUrls.UPDATE_OPERATING_DATA, data).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.UPDATE_OPERATING_DATA);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.UPDATE_OPERATING_DATA);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.UPDATE_OPERATING_DATA);
				}
			}
		);
	}

	/**
 * Check whether the given operatingdata name is already available.
 * @param token use token
 * @param attributeName name of the operatingdata
 * @param wsCallBack callback class
 */
	public operatingDataNameAvailability(data: any, wsCallBack: WsCallback) {
		this.http.post(ServiceUrls.operatingDataNameAvailability(), data).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.OPERATING_DATA_NAME_AVAILABILITY);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.OPERATING_DATA_NAME_AVAILABILITY);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.OPERATING_DATA_NAME_AVAILABILITY);
				}
			}
		);
	}

	/**
 * Add a new operatingdata.
 * @param data {"userToken":"","operatingDataName": "","operatingDataType": ""}
 * @param wsCallBack callback class
 */
	public addNewOperatingDataType(data: any, wsCallBack: WsCallback) {
		this.http.post(ServiceUrls.ADD_OPERATING_DATA, data).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.ADD_OPERATING_DATA);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.ADD_OPERATING_DATA);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.ADD_OPERATING_DATA);
				}
			}
		);
	}


	/**
	   * Delete an existing operatingdata.
	   * @param data {"userToken":"","id":"<Required>","name":"","type":""}
	   * @param wsCallBack callback class
	   */
	public deleteOperatingDataType(data: any, wsCallBack: WsCallback) {
		this.http.post(ServiceUrls.DELETE_OPERATING_DATA, data).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.DELETE_OPERATING_DATA);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.DELETE_OPERATING_DATA);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.DELETE_OPERATING_DATA);
				}
			}
		);
	}

	/**
     * check tag classification dependencies with id.
     * @param request string such as {"itemId":"", "itemType":"", "action":"", "userToken":""}
     * @param wsCallBack callback component.
     */
	public getOperatingDataDependeciesWithId(request: string, wsCallBack: WsCallback) {
		console.log(request);
		this.http.post(ServiceUrls.GET_OPERATING_DATA_DEPENDENCIES_WITH_ID, request).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_OPERATING_DATA_DEPENDENCIES_WITH_ID);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_OPERATING_DATA_DEPENDENCIES_WITH_ID);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_OPERATING_DATA_DEPENDENCIES_WITH_ID);
				}
			}
		);
	}

}
