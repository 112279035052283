<div class="modal-header  px-3 py-2">
  <h5 class="modal-title ">{{confirmationTitle}}</h5>
  <button type="button" class="close " aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>{{confirmationMessage}}</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary btn-sm" (click)="confirm()">Yes</button>
  <button type="button" class="btn btn-secondary btn-sm" (click)="decline()">No</button>
</div>