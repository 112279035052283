import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WsCallback } from '../../../util/ws-callback.interface';
import { ServiceUrls } from '../../../util/service-urls';
import { WsResponse } from '../../../util/ws-response.model';
import { WsType } from '../../../util/ws-type';

@Injectable({
    providedIn: 'root'
})
export class ResetPasswordValidation {
    private callback: WsCallback;
    constructor(private http:HttpClient){}

    public setCallback(callBack: WsCallback) {
        this.callback = callBack;
    }

    public getPasswordResetUrl(key: String, id: String) {
        this.http.post(ServiceUrls.getValidatePasswordReset(key, id, 2), null).subscribe(
            data => {
                // this may be incorrect
                //var val = (data as HttpErrorResponse).message;
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                this.callback.onSuccess(res, WsType.PASSWORD_RESET_VALIDATE);
            },
            error => {
                if (error.status == 532 || error.status == 542 || error.status == 401) {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    this.callback.onFail(res, WsType.PASSWORD_RESET_VALIDATE);
                } else {
                    //browser related issues
                    var res = new WsResponse("Unknown error happened");
                    this.callback.onFail(res, WsType.PASSWORD_RESET_URL);
                }
            }
        );
    }
}
