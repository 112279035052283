import { Router, ActivatedRoute } from '@angular/router';
import { AppRoutingModule } from '../app-routing.module';

export class RouteUtil {
    private static DEFAULT_DELAY = 1000;

    /**
     * Check whether the requested path can access without login
     * return type {allowed, path}
     */
    public static canProceedWithoutSession(): any {
        var pathName: string = window.location.pathname.replace('/', '');
        var canProceed: boolean = false;
        var data = AppRoutingModule.getRoutingPaths() as Array<any>;
        data.forEach(item => {
            if (item.path == pathName) {
                if (item.data.allowed == true) {
                    canProceed = true;
                }
            }
        });

        return {allowed: canProceed, path: pathName};
    }

    /**
     * Forward to a different component.
     *
     * @param router Router instance for actual forward
     * @param route  ActivatedRoute instance to deal with parameters
     * @param path path where it should be forwarded which are described in the app routing module
     * @param enableDelay enable delay or not just to improve the UX
     */
    public static forward(router: Router, path:string, enableDelay: boolean, route?: ActivatedRoute) {
        console.log('within the forward generic method');

        //No need a delay
        if (!enableDelay) {
            this.DEFAULT_DELAY = 0;
        }
        //need to worry about parameters
        if (route != null && route != undefined) {
            route.paramMap.subscribe(params => {
                if (params.get('fwd') != null) {
                    //When the browser is reload fwd can be set multiple times.
                    //Below code is to block the resetting the url again.
                    //if (path != '' && path != 'splash' && path != 'login') {
                        if (!params.get('fwd').includes('fwd')) {
                            setTimeout(() => router.navigate([path, { 'fwd': params.get('fwd') }]), this.DEFAULT_DELAY);
                        } else {
                            //fwd is already contain.
                            var val = params.get('fwd');
                            var modified = val.substring(val.indexOf('fwd=') + 4)
                            setTimeout(() => router.navigate([path, { 'fwd': modified }]), this.DEFAULT_DELAY);
                        }
                    //} else {router.navigateByUrl(path);}
                } else {
                    setTimeout(() => router.navigateByUrl(path), this.DEFAULT_DELAY);
                }
            });
        } else {
            //no need to care about the parameters, just forward
            router.navigateByUrl(path);
        }
	}
}
