<div class="my-login-page" data-gr-c-s-loaded="true">
    <section class="h-100">
       <div class="container h-100">
          <div class="row justify-content-md-center h-100">
             <div class="card-wrapper">
                <div class="mt-3 mb-3">
                   <img src="../../../assets/images/mainimage.png" alt="" width="100%" height="auto">
                </div>
                <div class="card fat">
                   <div class="card-body">
                      <h4 class="card-title">Change Password</h4>
                      <form method="POST" ngNativeValidate>
                         <div class="form-group">
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item pr-1 pl-1">{{ 'FORGOT_PASSWORD.RESTRICTIONS.YOUR_PASSWORD_MUST_CONTAIN_AT_LEAST' | translate}}
                                    <strong>{{ 'FORGOT_PASSWORD.RESTRICTIONS.SIX_CHARACTERS' | translate}}</strong>
                                  </li>
                                  <li class="list-group-item pr-1 pl-1">{{ 'FORGOT_PASSWORD.RESTRICTIONS.YOUR_PASSWORD_MUST_CONTAIN_AT_LEAST' | translate}}
                                    <strong>{{ 'FORGOT_PASSWORD.RESTRICTIONS.ONE_NUMBER' | translate}}</strong>
                                  </li>
                                  <li class="list-group-item pr-1 pl-1">{{ 'FORGOT_PASSWORD.RESTRICTIONS.YOUR_PASSWORD_MUST_CONTAIN_AT_LEAST' | translate}}
                                    <strong>{{ 'FORGOT_PASSWORD.RESTRICTIONS.ONE_LOWER_CASE_LETTER' | translate}}</strong>
                                  </li>
                                  <li class="list-group-item pr-1 pl-1">{{ 'FORGOT_PASSWORD.RESTRICTIONS.YOUR_PASSWORD' | translate}}
                                    <strong>{{ 'FORGOT_PASSWORD.RESTRICTIONS.CANNOT_CONTAIN' | translate}}</strong> {{ 'FORGOT_PASSWORD.RESTRICTIONS.TWO_ CONSECUTIVE_LETTERS' | translate}}
                                  </li>
                                  <li class="list-group-item pr-1 pl-1">{{ 'FORGOT_PASSWORD.RESTRICTIONS.YOUR_PASSWORD_MUST_CONTAIN_AT_LEAST' | translate}}
                                    <strong>{{ 'FORGOT_PASSWORD.RESTRICTIONS.ONE_UPPER_CASE_LETTER' | translate}}</strong>
                                  </li>
                            </ul>
                         </div>
                         <div class="form-group">
                            <label for="oldPassword">{{ 'CHANGE_PASSWORD.OLD_PASSWORD' | translate}}</label>
                            <input id="changepassword_oldPassword" type="password" class="form-control" required name="oldPassword" value="" required="" autofocus="" [(ngModel)] = "passwordChangeModel.oldPassword">
                         </div>
                         <div class="form-group">
                            <label for="newPassword">{{ 'CHANGE_PASSWORD.NEW_PASSWORD' | translate}}</label>
                            <input id="changepassword_newPassword" type="password" class="form-control" required name="newPassword" value="" required="" autofocus="" [(ngModel)] = "passwordChangeModel.newPassword">
                         </div>
                         <div class="form-group">
                            <label for="confirmPassword">{{ 'CHANGE_PASSWORD.CONFIRM_PASSWORD' | translate}}</label>
                            <input id="changepassword_confirmPassword" type="password" class="form-control" required name="confirmPassword" value="" required="" autofocus="" [(ngModel)] = "passwordChangeModel.confirmPassword">
                         </div>
                         <div class="form-group">
                            <alert></alert>
                         </div>
                         <div class="form-group mt-4 mb-3">
                            <button id="changepassword_submit" type="submit" class="btn btn-primary btn-block mb-3" (click) = "onSubmitButtonClick()">
                              {{ 'SUBMIT' | translate}}
                            </button>
                         </div>
                      </form>
                      <button id="changepassword_cancel" class="btn btn-secondary btn-block mt-3" (click)="onCancelButtonClick()">
                        {{ 'CANCEL' | translate}}
                     </button>
                   </div>
                </div>
                <div class="footer">
                  {{ 'ALL_RIGHTS' | translate}}
                </div>
             </div>
          </div>
       </div>
    </section>
  </div>