<div class="front">
    <p  class="text-left" (click)="viewPersonStatus()" [innerHTML] = "docuSignStatus"></p>
</div>

<ng-template #docusignTemplate>
    <div class="modal-header">
            <h5 class="modal-title pull-left">{{ system }}</h5>
            <button type="button" id="table_close" class="close pull-right" aria-label="Close" (click)="modelRefCloseEvent()">
                <span aria-hidden="true">&times;</span>
            </button>
    </div>

    <div  class="modal-body">
        <app-docu-sign-person-status  [docusignId] ="docuSignId" [system] ="system" [type]="type" [showTaskName]=false></app-docu-sign-person-status>

    </div>

    <div class="modal-footer">
            <button type="button" class="btn btn-sm btn-secondary" (click)="modelRefCloseEvent()"> Close</button>
    </div>
</ng-template>