import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { ServiceUrls } from '../../util/service-urls';
import { UserVariable } from '../../util/common/user-variable';
import { map } from 'rxjs/operators';
import { WsCallback } from '../../util/ws-callback.interface';
import { WsResponse } from '../../util/ws-response.model';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { SharedService } from '../../util/shared.service';
import { WsType } from '../../util/ws-type';
import { CommonUtil } from 'src/app/util/common/common-util';

@Injectable({
  providedIn: 'root'
})
export class PunchlistService {
    public token: string;
    private rowData: any = null;
    private apiGridData: any = null;
    public punchlistId: String = "";
    private callback: WsCallback;

  constructor(private http: HttpClient, private sharedService: SharedService, private commonUtil: CommonUtil) { 
    this.token = UserVariable.getUserToken();
    if(!this.commonUtil.validateLogin(this.token)){
        return;
    }
  }

  public setCallback(callBack: WsCallback) {
    this.callback = callBack;
}

  getAssetList(wsCallBack: WsCallback) {
    console.log("============ASSET LIST==========");
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
        return;
    }
    const options = { params: new HttpParams().set("token", token) }  
    this.http.get(ServiceUrls.GET_ASSET_LIST, options).subscribe(
        data => {
            var modified = JSON.parse(JSON.stringify(data));
            var res = new WsResponse(
                modified.status.description,
                modified.status.code,
                modified.status.name,
                modified.payload);
            wsCallBack.onSuccess(res, WsType.GET_ASSETS);
        },
        error => {
            if (error.status != '') {
                var val = (error as HttpErrorResponse).error;
                var modified = JSON.parse(JSON.stringify(val));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                wsCallBack.onFail(res, WsType.GET_ASSETS);
            } else {
                //browser related issues
                var res = new WsResponse("Unknown error happened");
                wsCallBack.onFail(res, WsType.GET_ASSETS);
            }
        }
    );
}

getDisciplineList(projectId: string, wsCallBack: WsCallback) {
    console.log("============Discipline LIST==========");
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
        return;
    }
    const options = { params: new HttpParams().set("token", token) }  
    this.http.get(ServiceUrls.getDisciplines(projectId), options).subscribe(
        data => {
            var modified = JSON.parse(JSON.stringify(data));
            var res = new WsResponse(
                modified.status.description,
                modified.status.code,
                modified.status.name,
                modified.payload);
            wsCallBack.onSuccess(res, WsType.GET_DISCIPLINES);
        },
        error => {
            if (error.status != '') {
                var val = (error as HttpErrorResponse).error;
                var modified = JSON.parse(JSON.stringify(val));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                wsCallBack.onFail(res, WsType.GET_DISCIPLINES);
            } else {
                //browser related issues
                var res = new WsResponse("Unknown error happened");
                wsCallBack.onFail(res, WsType.GET_DISCIPLINES);
            }
        }
    );
}

getPunchCodeList(projectId: string, wsCallBack: WsCallback) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
        return;
    }
    const options = { params: new HttpParams().set("token", token) }  
    this.http.get(ServiceUrls.getPunchCodes(projectId), options).subscribe(
        data => {
            var modified = JSON.parse(JSON.stringify(data));
            var res = new WsResponse(
                modified.status.description,
                modified.status.code,
                modified.status.name,
                modified.payload);
            wsCallBack.onSuccess(res, WsType.GET_PUNCH_CODES);
        },
        error => {
            if (error.status != '') {
                var val = (error as HttpErrorResponse).error;
                var modified = JSON.parse(JSON.stringify(val));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                wsCallBack.onFail(res, WsType.GET_PUNCH_CODES);
            } else {
                //browser related issues
                var res = new WsResponse("Unknown error happened");
                wsCallBack.onFail(res, WsType.GET_PUNCH_CODES);
            }
        }
    );
}

/**
 * Get all the systems in the project
 * @param wsCallBack 
 */
getSubSystemList(wsCallBack: WsCallback){
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
        return;
    }
    const options = { params: new HttpParams().set("token", token) }
    this.http.get(ServiceUrls.GET_ALL_SYSTEM_LIST, options).subscribe(
        data => {
            var modified = JSON.parse(JSON.stringify(data));
            var res = new WsResponse(
                modified.status.description,
                modified.status.code,
                modified.status.name,
                modified.payload);
            wsCallBack.onSuccess(res, WsType.GET_SYSTEMS_BY_PROJECT_ID);
        },
        error => {
            if (error.status != '') {
                var val = (error as HttpErrorResponse).error;
                var modified = JSON.parse(JSON.stringify(val));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                wsCallBack.onFail(res, WsType.GET_SYSTEMS_BY_PROJECT_ID);
            } else {
                //browser related issues
                var res = new WsResponse("Unknown error happened");
                wsCallBack.onFail(res, WsType.GET_SYSTEMS_BY_PROJECT_ID);
            }
        }
    );
}

searchPunchlists(category: String, assetName: String, disciplineName: String, punchlistDescription: String, dateRaisedString: String, dateClosedString: String, dateEstimatedCompletionString: String, raisedBy: String, materialsRequired: String, subSystemName: String, estimatedManHours: String, itemStatus: String,  hasText: string) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
        return;
    }
    let url = this.getPunchlistBySearch(token);
    console.log(punchlistDescription);
    console.log(JSON.stringify(new Request(category, assetName, disciplineName, punchlistDescription, dateRaisedString, dateClosedString, dateEstimatedCompletionString, raisedBy, materialsRequired, subSystemName, estimatedManHours, itemStatus, hasText)));
    return this.http.post(url, JSON.stringify(new Request(category, assetName, disciplineName, punchlistDescription, dateRaisedString, dateClosedString, dateEstimatedCompletionString, raisedBy, materialsRequired, subSystemName, estimatedManHours, itemStatus, hasText))).
        pipe(
            map(response => {
                var modified = JSON.parse(JSON.stringify(response));
                return new WsResponse(
                    modified.status.description,
                    modified.status.name,
                    modified.status.code,
                    modified.payload);
            }),
            catchError(error => {
                var modified = JSON.parse(JSON.stringify(error));
                var res = new WsResponse(
                    modified.error.status.description,
                    modified.error.status.name,
                    modified.error.status.code,
                    modified.error.payload);
                return throwError(res)
            })
        );
}

public getPunchlistBySearch(token: String) {
    let url = ServiceUrls.PUNCHLIST_SEARCH_VIEW + '?token=' + token;
    return url;
}

public addNewPunchlist(token: string, data: string, wsCallBack: WsCallback){
    this.http.post(ServiceUrls.addNewPunchlist(token), data).subscribe(
        data => {
            var modified = JSON.parse(JSON.stringify(data));
            var res = new WsResponse(
                modified.status.description,
                modified.status.code,
                modified.status.name,
                modified.payload);
            wsCallBack.onSuccess(res, WsType.ADD_NEW_PUNCHLIST);
        },
        error => {
            if (error.status != '') {
                var val = (error as HttpErrorResponse).error;
                var modified = JSON.parse(JSON.stringify(val));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                wsCallBack.onFail(res, WsType.ADD_NEW_PUNCHLIST);
            } else {
                //browser related issues
                var res = new WsResponse("Unknown error happened");
                wsCallBack.onFail(res, WsType.ADD_NEW_PUNCHLIST);
            }
        }
    );
}

getPunchlistID(id: String){
    this.punchlistId = id;
    return this.punchlistId;
}

public getPunchlistDetails(wsCallBack: WsCallback){
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
        return;
    }
    this.http.get(ServiceUrls.getPunchlistByPunchlistId(token,this.punchlistId)).subscribe(
        data => {
            var modified = JSON.parse(JSON.stringify(data));
            var res = new WsResponse(
                modified.status.description,
                modified.status.code,
                modified.status.name,
                modified.payload);
            wsCallBack.onSuccess(res, WsType.GET_PUNCHLIST_BY_PUNCHLIST_ID);
        },
        error => {
            if (error.status != '') {
                var val = (error as HttpErrorResponse).error;
                var modified = JSON.parse(JSON.stringify(val));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                wsCallBack.onFail(res, WsType.GET_PUNCHLIST_BY_PUNCHLIST_ID);
            } else {
                //browser related issues
                var res = new WsResponse("Unknown error happened");
                wsCallBack.onFail(res, WsType.GET_PUNCHLIST_BY_PUNCHLIST_ID);
            }
        }
    );
}


public getDocuments(token: string, facilityId: string, wsCallBack: WsCallback) {
    this.http.get(ServiceUrls.getDocuments(token, facilityId)).subscribe(
        data => {
            var modified = JSON.parse(JSON.stringify(data));
            var res = new WsResponse(
                modified.status.description,
                modified.status.code,
                modified.status.name,
                modified.payload);
            wsCallBack.onSuccess(res, WsType.GET_DOCUMENTS);
        },
        error => {
            if (error.status != '') {
                var val = (error as HttpErrorResponse).error;
                var modified = JSON.parse(JSON.stringify(val));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                wsCallBack.onFail(res, WsType.GET_DOCUMENTS);
            } else {
                //browser related issues
                var res = new WsResponse("Unknown error happened");
                wsCallBack.onFail(res, WsType.GET_DOCUMENTS);
            }
        }
    );
}


public updatePunchlist(token: string, data: string, wsCallBack: WsCallback){
    this.http.post(ServiceUrls.updatePunchlist(token), data).subscribe(
        data => {
            var modified = JSON.parse(JSON.stringify(data));
            var res = new WsResponse(
                modified.status.description,
                modified.status.code,
                modified.status.name,
                modified.payload);
            wsCallBack.onSuccess(res, WsType.UPDATE_PUNCHLIST);
        },
        error => {
            if (error.status != '') {
                var val = (error as HttpErrorResponse).error;
                var modified = JSON.parse(JSON.stringify(val));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                wsCallBack.onFail(res, WsType.UPDATE_PUNCHLIST);
            } else {
                //browser related issues
                var res = new WsResponse("Unknown error happened");
                wsCallBack.onFail(res, WsType.UPDATE_PUNCHLIST);
            }
        }
    );
}

// getSystemDataBySystemId(systemId: String, facilityId: String, parentId: String) {
//     let token = UserVariable.getUserToken();
//     let url = ServiceUrls.SYSTEM_EDIT + '?token=' + token;
//     console.log("facilityId  " + facilityId)
//     console.log("parentId  " + parentId)
//     return this.http.post(url, JSON.stringify(new EditRequest(systemId, facilityId, parentId))).
//         pipe(
//             map(response => {
//                 var modified = JSON.parse(JSON.stringify(response));
//                 return new WsResponse(
//                     modified.status.description,
//                     modified.status.name,
//                     modified.status.code,
//                     modified.payload);
//             }),
//             catchError(error => {
//                 var modified = JSON.parse(JSON.stringify(error));
//                 var res = new WsResponse(
//                     modified.error.status.description,
//                     modified.error.status.name,
//                     modified.error.status.code,
//                     modified.error.payload);
//                 return throwError(res)
//             })
//         );
// }

public disableEnableFileTableData(fileId: any, title: String, status: String) {
    if (this.apiGridData != null && this.apiGridData != undefined && fileId != null) {
        var rowNode = this.apiGridData.getRowNode(fileId);
        if (rowNode != null && rowNode != undefined) {
            if (title == 'Disable Confirm') {
                this.sharedService.disableCellRendererRequest(true, fileId);
                rowNode.setDataValue("status", "status:" + fileId + ":Inactive");
                rowNode.setDataValue("delete", "delete:" + fileId + ":Inactive");
            } else if (title == 'Enable Confirm') {
                this.sharedService.disableCellRendererRequest(false, fileId);
                rowNode.setDataValue("status", "status:" + fileId + ":Active");
                rowNode.setDataValue("delete", "delete:" + fileId + ":Active");
            }
            else {
                if (status == "Inactive") {
                    this.sharedService.disableCellRendererRequest(false, fileId);
                    rowNode.setDataValue("status", "status:" + fileId + ":Active");
                    rowNode.setDataValue("delete", "delete:" + fileId + ":Active");
                }
                else {
                    this.sharedService.disableCellRendererRequest(true, fileId);
                    rowNode.setDataValue("status", "status:" + fileId + ":Inactive");
                    rowNode.setDataValue("delete", "delete:" + fileId + ":Inactive");
                }
            }
        }
    }
}

public deleteFileTableData(punchlistId: any) {
    if (this.apiGridData != null && this.apiGridData != undefined && punchlistId != null) {
        var selectedData = this.apiGridData.getSelectedRows();
        if (selectedData != null && selectedData != undefined) {
            this.apiGridData.applyTransaction({ remove: selectedData });
            let newRowData:any = [];
            this.apiGridData.forEachNode( function(rowNode, index) {
                newRowData.push(rowNode.data);
            });
            this.setRowData(newRowData);
        }
    }
}

deletePunchlist(punchlistId: String, wsCallBack: WsCallback) {
let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
        return;
    }
let url = ServiceUrls.PUNCHLISTS + punchlistId + '/destroy.json';
const options = { params: new HttpParams().set("token", token) }
this.http.delete(url, options).subscribe(
    data => {
        var modified = JSON.parse(JSON.stringify(data));
        var res = new WsResponse(
            modified.status.description,
            modified.status.code,
            modified.status.name,
            modified.payload);
        wsCallBack.onSuccess(res, WsType.DELETE_PUNCHLIST);
    },
    error => {
        if (error.status != '') {
            var val = (error as HttpErrorResponse).error;
            var modified = JSON.parse(JSON.stringify(val));
            var res = new WsResponse(
                modified.status.description,
                modified.status.code,
                modified.status.name,
                modified.payload);
            wsCallBack.onFail(res, WsType.DELETE_PUNCHLIST);
        } else {
            //browser related issues
            var res = new WsResponse("Unknown error happened");
            wsCallBack.onFail(res, WsType.DELETE_PUNCHLIST);
        }
    }
);
}

disablePunchlist(punchlistId: string, status: string, wsCallBack: WsCallback) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
        return;
    }
    console.log("id==" + punchlistId);
    const options = { params: new HttpParams().set("token", token).set("punchlistId", punchlistId).set("status", status)}	
    let url = ServiceUrls.PUNCHLISTS + punchlistId + '/disablePunchlist.json';
    this.http.get(url, options).
        subscribe(
        data => {
            var modified = JSON.parse(JSON.stringify(data));
            var res = new WsResponse(
                modified.status.description,
                modified.status.name,
                modified.status.code,
                modified.payload);
            wsCallBack.onSuccess(res, WsType.DISABLE_PUNCHLIST);
        },
        error => {
            var modified = JSON.parse(JSON.stringify(error));
            var res = new WsResponse(
                modified.status.description,
                modified.status.name,
                modified.status.code,
                modified.payload);
            wsCallBack.onFail(res, WsType.DISABLE_PUNCHLIST);
        });
}

	revokePunchlist(punchlistId: string, wsCallBack: WsCallback) {
		let token = UserVariable.getUserToken();
		let data = {
		  id : punchlistId
		}
		let url  = ServiceUrls.revokePunchlist(token, punchlistId)
		return this.http.post(url, JSON.stringify(data)).
		subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.name,
					modified.status.code,
					{
						id : punchlistId
					});
				wsCallBack.onSuccess(res, WsType.REVOKE_PUNCHLIST);
			},
			error => {
				var modified = JSON.parse(JSON.stringify(error));
				var res = new WsResponse(
					modified.error.status.description,
					modified.error.status.name,
					modified.error.status.code,
					{
						id : punchlistId
					});
			  	wsCallBack.onFail(res, WsType.REVOKE_PUNCHLIST);
			}
		);
	}

    public setApiGridData(apiGridData: any) {
        this.apiGridData = apiGridData;
    }

    public getApiGridData() {
        return this.apiGridData;
    }

    public setRowData(rowData: any) {
        this.rowData = rowData;
    }

    public getRowData() {
        return this.rowData;
    }
  
    downloadOpenSentFile(projectId: string, id: string) {
        return this.http.get(ServiceUrls.DOWNLOAD_FILE,
            {
            responseType: "blob", params: new HttpParams().set("projectId", projectId)
                .set("documentId", id).set("mode", "ViewCommOutput").set("type", "PunchlistOpen").set("fileName", "PL")
            });
    }

    downloadOpenReturnFile(projectId: string, id: string) {
        return this.http.get(ServiceUrls.DOWNLOAD_FILE,
            {
            responseType: "blob", params: new HttpParams().set("projectId", projectId).set("documentId", id)
                .set("mode", "ViewApprovedOutput").set("type", "PunchlistOpen").set("fileName", "PL")
            });
    }
  
    downloadCloseSentFile(projectId: string, id: string) {
        return this.http.get(ServiceUrls.DOWNLOAD_FILE,
            {
            responseType: "blob", params: new HttpParams().set("projectId", projectId)
                .set("documentId", id).set("mode", "ViewCommOutput").set("type", "PunchlistClose").set("fileName", "PL")
            });
    }

    downloadCloseReturnFile(projectId: string, id: string) {
        return this.http.get(ServiceUrls.DOWNLOAD_FILE,
            {
            responseType: "blob", params: new HttpParams().set("projectId", projectId).set("documentId", id)
                .set("mode", "ViewApprovedOutput").set("type", "PunchlistClose").set("fileName", "PL")
            });
    }

    downloadImage(projectId: string, id: string, fileName: string) {
		return this.http.get(ServiceUrls.DOWNLOAD_FILE,
			{
				responseType: "blob", params: new HttpParams().set("projectId", projectId)
					.set("documentId", id).set("fileName", fileName).set("mode", "GetPunchlistPhoto")
			});
	}

    /**
	 * get all vendors of the project
	 * @param projectId 
	 * @param wsCallBack 
	 * @returns 
	 */
	getVendorList(projectId: string, wsCallBack: WsCallback) {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
		console.log("============Vendor LIST==========");
		const options = { params: new HttpParams().set("token", token) }
		this.http.get(ServiceUrls.GET_VENDOR_LIST, options).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_VENDOR_LIST);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_VENDOR_LIST);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_VENDOR_LIST);
				}
			}
		);
	}

    /**
	 * Get punchlists in same group by punchlist id.
	 * @param punchlistId 
	 * @returns 
	 */
	getPunchlistsInSameGroup(punchlistId: string): Observable<WsResponse> {
		let token = UserVariable.getUserToken();

		let url  = ServiceUrls.getPunchlistsInSameGroup(token, punchlistId)
		return this.http.get(url).pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
		);
	}

    getPersonDocuSignStatus(docuSignId: any): Observable<WsResponse> {
		
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("token", token) }
		let url = ServiceUrls.getPersonDocuSignStatusUrl(docuSignId);
		console.log("getPersonDocuSignStatus == > ServiceURL= " + url)

		return this.http.get(url, options).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
    }
    
    public removeChecksheetPunchlistRelation(punchlistIds: string, wsCallBack: WsCallback){
        let token = UserVariable.getUserToken();
        this.http.post(ServiceUrls.removeChecksheetPunchlistRelation(token), new HttpParams().set("punchlistIds", punchlistIds)).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                wsCallBack.onSuccess(res, WsType.REMOVE_CS_RELATION_FROM_PUNCHLIST);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    wsCallBack.onFail(res, WsType.REMOVE_CS_RELATION_FROM_PUNCHLIST);
                } else {
                    //browser related issues
                    var res = new WsResponse("Unknown error happened");
                    wsCallBack.onFail(res, WsType.REMOVE_CS_RELATION_FROM_PUNCHLIST);
                }
            }
        );
    }
    
}

class Request {
    constructor(private category: String, private assetName: String, private disciplineName: String, private punchlistDescription: String, private dateRaisedString: String, private dateClosedString: String, private dateEstimatedCompletionString: String, private raisedBy: String, private materialsRequired: String, private subSystemName: String, private estimatedManHours: String, private itemStatus: String, private hasText: String) { }
}