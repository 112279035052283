<header class="slide-panel-header">
  <span class="slide-action" (click)="pageToggle()">
    <i class="fal fa-plus"></i>
  </span>
  <div class="panel-details">
    <h5 class="title mb-1 pl-2">Assign users</h5>
    <!-- <ul class="list-group list-group-horizontal link-list text-uppercase action-list">
      <li class="list-group-item"><a href="" class="isDisabled">Select all</a></li>
      <li class="list-group-item"><a href="" class="isDisabled">Deselect all</a></li>
      <li class="list-group-item"><a (click)="collapseAll($event)" class="isDisabled cllapse-link">collapse all</a></li>
    </ul> -->
  </div>
</header>
<div class="container right-slide-panelcontent">

  <form>
    <div class="form-group editor-panel-row row">
      <div class="col-sm-6">
        <label for="Client">Client</label>
          <ngx-select placeholder="Select client" [allowClear]="true" (remove)="removedClient($event)" (selectionChanges)="selectedClientId($event)"
          [items]="clientList" name="clientId" id="clientId">
        </ngx-select>

      </div>
      <div class="col-sm-6">
        <label for="inputFirstname">User</label>
        <ngx-select placeholder="Select user" [allowClear]="true" (selectionChanges)="selectedUserId($event)"
        [items]="userList" name="userId" id="userId">
      </ngx-select>
      </div>
    </div>

  </form>
  <div *ngIf = "showDiv;" class="accordion" id="accordionExample">
    <div *ngFor="let role of allList; let j = index" class="card collapse-panel">
      <div class="card-header header" id="headingOne">
        <h2 class="mb-0">
          <!--<button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne"
            aria-expanded="true" aria-controls="collapseOne">
            {{role.roleName}}
          </button>-->
          <button class="btn btn-link collapse-btn" type="button" data-toggle="collapse"
              [attr.data-target]="'#RoleCollapseLevel1-' + role.roleId"  aria-expanded="true" aria-controls="RoleCollapseLevel1">
              {{role.roleName}}
              </button>
          <ul class="list-group list-group-horizontal link-list text-uppercase">            
            <li><ui-switch switchColor="#fcfcfc" uncheckedLabel="off" [checked]="role.added" (change)="onChange($event,j,role.roleId)" size="small"></ui-switch></li>
            <!-- <li class="list-group-item"><a href="">Apply to all</a></li>
            <li class="list-group-item"><a href="">Remove from all </a></li> -->
          </ul>
        </h2>
      </div>
      <div class="collapse show" [attr.id]="'RoleCollapseLevel1-' + role.roleId">
        <div *ngIf = "isInternalUser; else otherUsers">
        <div *ngFor="let client of role.clientlist; let k = index" class="card-body inner-collapse border-0">
          <div class="card-header header">
            <h2 class="mb-0">
              <button class="btn btn-link collapse-btn" type="button" data-toggle="collapse" [attr.data-target]="'#RoleCollapseLevel2-' + client.clientId"  
              aria-expanded="true" aria-controls="RoleCollapseLevel2">
                {{client.clientName}}
              </button>
              <ul class="list-group list-group-horizontal link-list text-uppercase">
                <li><ui-switch switchColor="#fcfcfc" uncheckedLabel="off"  [checked]="client.added" (change)="onChange($event,j,role.roleId,k,client.clientId)" size="small"></ui-switch></li>
              </ul>
            </h2>
          </div>

          <div class="collapse show" [attr.id]="'RoleCollapseLevel2-' + client.clientId">
            <div class="card card-body border-0 pl-0 pr-0">
              <div class="row switch-row">
                <!-- <table> -->
                <div *ngFor="let project of client.projectList; let l = index" class="col-6 col-md-4 mb-2">
                  <div class="switch-wrapper">
                    <!-- <tr> -->
                      {{project.projectName}}
                      <ui-switch switchColor="#fcfcfc" uncheckedLabel="off" [checked]="project.added" (change)="onChange($event,j,role.roleId,k,project.clientId,l,project.projectId)"size="small"></ui-switch>
                    <!-- </tr> -->
                  </div>
                </div>
                <!-- </table> -->
            </div>

            
          </div>
        </div>  
      <!-- </div>         -->
      </div>
    </div>
    <ng-template #otherUsers>
      <div *ngFor="let client of role.clientlist; let k = index" >
        <div *ngIf = "client.clientName == selectedClientName" class="card-body inner-collapse border-0">
        <div class="card-header header">
          <h2 class="mb-0">
            <button class="btn btn-link collapse-btn" type="button" data-toggle="collapse" [attr.data-target]="'#RoleCollapseLevel2-' + client.clientId"  
            aria-expanded="true" aria-controls="RoleCollapseLevel2">
              {{client.clientName}}
            </button>
            <ul class="list-group list-group-horizontal link-list text-uppercase">
              <li><ui-switch switchColor="#fcfcfc" uncheckedLabel="off"  [checked]="client.added" (change)="onChange($event,j,role.roleId,k,client.clientId)" size="small"></ui-switch></li>
            </ul>
          </h2>
        </div>

        <div class="collapse show" [attr.id]="'RoleCollapseLevel2-' + client.clientId">
          <div class="card card-body border-0 pl-0 pr-0">
            <div class="row switch-row">
              <!-- <table> -->
              <div *ngFor="let project of client.projectList; let l = index" class="col-6 col-md-4 mb-2">
                <div class="switch-wrapper">
                  <!-- <tr> -->
                    {{project.projectName}}
                    <ui-switch switchColor="#fcfcfc" uncheckedLabel="off" [checked]="project.added" (change)="onChange($event,j,role.roleId,k,project.clientId,l,project.projectId)"size="small"></ui-switch>
                  <!-- </tr> -->
                </div>
              </div>
              <!-- </table> -->
          </div>

          
        </div>
      </div>  
    </div>        
    </div>
    </ng-template>
    </div> 
  </div>
</div>

<footer class="slide-panel-footer">
  <div class="row">
    <div class="col">

    </div>
    <div class="col">
      <ul class="list-group list-group-horizontal float-right">
        <li class="list-group-item">
          <button *ngIf = "showDiv;" type="submit" id="user_role_save" class="btn  btn-custom lgRole float-right" (click)="save()">{{ 'SAVE' | translate}}</button>
          <button type="button" id="user_role_cancel" class="btn  btn-custom lgRole float-right"
          (click)="pageToggle()">{{ 'CANCEL' | translate}}</button>
        </li>
      </ul>
    </div>
  </div>



</footer>