import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { WsCallback } from '../../../util/ws-callback.interface';
import { ServiceUrls } from '../../../util/service-urls';
import { WsType } from '../../../util/ws-type';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { WsResponse } from '../../../util/ws-response.model';
import { CommonUtil } from 'src/app/util/common/common-util';
import { UserVariable } from '../../../util/common/user-variable';
import { map } from 'rxjs/operators'
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({ 
    providedIn: 'root'
})
export class RolesService {
	public token: string;
	private callback: WsCallback;
	private rowData: any = null;
	private apiGridData: any = null;

	constructor(private http: HttpClient, private commonUtil: CommonUtil) {
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateLogin(token)){
            return;
        }
	}

	public setCallback(callBack: WsCallback) {
		this.callback = callBack;
	}
  /**
	 * Add a new role
	 * @param data {userToken: '', name: '', description: ''}
	 * @param wsCallBack callback class
	 */
	public addNewRole(token: string, data: string, wsCallBack: WsCallback){
		// this.http.post(ServiceUrls.ADD_NEW_ROLE, data).subscribe(
	    this.http.post(ServiceUrls.addNewRole(token), data).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.ADD_NEW_ROLE);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						// modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.ADD_NEW_ROLE);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.ADD_NEW_ROLE);
				}
			}
		);
	}

	getRolesList() {
		console.log("============ROLE LIST==========");
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateLogin(token)){
            return;
        }
		let url = this.getRolesListByUrl(token);
        return this.http.post(url, JSON.stringify(new Request())).
            pipe(
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                }),
                catchError(error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    return throwError(res)
                })
            );
		
	}

	public updateRole(token: string, data: string, wsCallBack: WsCallback){
		this.http.post(ServiceUrls.updateRole(token), data).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.UPDATE_ROLE);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.UPDATE_ROLE);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.UPDATE_ROLE);
				}
			}
		);
	}

	public updateStatus(token: string, data: string, wsCallBack: WsCallback){
		this.http.post(ServiceUrls.updateStatus(token), data).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.UPDATE_STATUS);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.UPDATE_STATUS);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.UPDATE_STATUS);
				}
			}
		);
	}

	/**
	 * Delete a role.
	 * @param data {"userToken":"","roleId":""}
	 * @param wsCallBack callback class
	 */
	public deleteRole(token: string, data: any, wsCallBack: WsCallback) {
		this.http.post(ServiceUrls.deleteRole(token), data).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.DELETE_ROLE);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.DELETE_ROLE);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.DELETE_ROLE);
				}
			}
		);
	}

	/**
	 * get users for a role.
	 * @param data {"userToken":"","roleId":""}
	 * @param wsCallBack callback class
	 */
	public getRoleAssignUsers(token: string, data: any, wsCallBack: WsCallback) {
		this.http.post(ServiceUrls.getRoleAssignUsers(token), data).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_ROLE_ASSIGN_USERS);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_ROLE_ASSIGN_USERS);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_ROLE_ASSIGN_USERS);
				}
			}
		);
	}

	public getCapabilitiesList(token: string, data: any, wsCallBack: WsCallback) {
		this.http.post(ServiceUrls.getRoleCapabilities(token), data).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_ROLE_CAPABILITY);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_ROLE_CAPABILITY);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_ROLE_CAPABILITY);
				}
			}
		);
	}
	
	public getCapCategoryList(token: string, data: any, wsCallBack: WsCallback) {
		this.http.post(ServiceUrls.getRoleCapCategories(token), data).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_ROLE_CATEGORY);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_ROLE_CATEGORY);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_ROLE_CATEGORY);
				}
			}
		);
	}

	saveDefaultRoleCapabilities(scope: String, roleID: string,  capList: String) {
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateLogin(token)){
            return;
        }
		  let url = this.saveDefaultCapabilityURL(token);
		  // console.log("url...." + url);
		return this.http.post(url, JSON.stringify(new AddRequest(scope, roleID, capList))).
			  pipe(
			  map(response => {
				  var modified = JSON.parse(JSON.stringify(response));
				  return new WsResponse(
					  modified.status.description,
					  modified.status.name,
					  modified.status.code,
					  modified.payload);
			  }),
			  catchError(error => {
				  var modified = JSON.parse(JSON.stringify(error));
				  var res = new WsResponse(
					  modified.error.status.description,
					  modified.error.status.name,
					  modified.error.status.code,
					  modified.error.payload);
				  return throwError(res)
			  })
			  );
	}

	saveCustomRoleCapabilities(roleID: string,  capList: String) {
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateLogin(token)){
            return;
        }
		let url = this.saveCustomCapabilityURL(token);
		// console.log("url...." + url);
	  return this.http.post(url, JSON.stringify(new CustomAddRequest(roleID, capList))).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.name,
					modified.status.code,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error));
				var res = new WsResponse(
					modified.error.status.description,
					modified.error.status.name,
					modified.error.status.code,
					modified.error.payload);
				return throwError(res)
			})
			);
  }

	getAllUsersByClientId(id: any)
	{
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateLogin(token)){
            return;
        }
		const options = { params: new HttpParams().set("token", token) }
		let url = this.getUsersByUrl(id);
        return this.http.get(url, options).
            pipe(
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                }),
                catchError(error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    return throwError(res)
                })
            )
	}

	getUserRoleAssignmentsByUserId(userId: String, scope: string) {
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateLogin(token)){
            return;
        }
		let url = this.getUserRoleAssignmentByUrl(token);
		// console.log("url...." + url);
	  return this.http.post(url, JSON.stringify(new UserAssignRequest(userId, scope))).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.name,
					modified.status.code,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error));
				var res = new WsResponse(
					modified.error.status.description,
					modified.error.status.name,
					modified.error.status.code,
					modified.error.payload);
				return throwError(res)
			})
			);
  }

	getRolesByUserId(id: any)
	{
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateLogin(token)){
            return;
        }
		const options = { params: new HttpParams().set("token", token) }
		let url = this.getRolesByUSerIdByUrl(id);
        return this.http.get(url, options).
            pipe(
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                }),
                catchError(error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    return throwError(res)
                })
            )
	}

	getProjectList(id: any) {
		console.log("============PROJECT LIST==========");
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateLogin(token)){
            return;
        }
		let url = this.getProjectListByUrl(id);
        return this.http.post(url, JSON.stringify(new Request())).
            pipe(
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                }),
                catchError(error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    return throwError(res)
                })
            );
		
	}
	
	getClientsByUserId(id: any)
	{
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateLogin(token)){
            return;
        }
		const options = { params: new HttpParams().set("token", token) }
		let url = this.getClientsByUSerIdByUrl(id);
        return this.http.get(url, options).
            pipe(
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                }),
                catchError(error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    return throwError(res)
                })
            )
	}

	saveUserRoleAssignments(userId: String, roles: string,  clientList: String, projectList:string) {
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateLogin(token)){
            return;
        }
		let url = this.saveUserRoleAssignmentURL(token);
		// console.log("url...." + url);
	  return this.http.post(url, JSON.stringify(new SaveUserRoleAssignmentRequest(userId, roles, clientList, projectList))).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.name,
					modified.status.code,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error));
				var res = new WsResponse(
					modified.error.status.description,
					modified.error.status.name,
					modified.error.status.code,
					modified.error.payload);
				return throwError(res)
			})
			);
  }
	

	public saveDefaultCapabilityURL(token: String) {
		let url = ServiceUrls.SAVE_DEFAULT_CAPABILITY + '?token=' + token;
		return url;
	}

	public saveUserRoleAssignmentURL(token: String) {
		let url = ServiceUrls.SAVE_USER_ROLE_ASSIGNMENT + '?token=' + token;
		return url;
	}

	public saveCustomCapabilityURL(token: String) {
		let url = ServiceUrls.SAVE_CUSTOM_CAPABILITY + '?token=' + token;
		return url;
	}

	public setApiGridData(apiGridData: any) {
        this.apiGridData = apiGridData;
    }

    public getApiGridData() {
        return this.apiGridData;
    }

    public setRowData(rowData: any) {
        this.rowData = rowData;
    }

    public getRowData() {
        return this.rowData;
	}

	public getUsersByUrl(id:any)
	{
		let url = ServiceUrls.USER_PROJECT_VIEW +  id + "/getAllUsersByClientId.json";
		return url
	} 

	public getUserRoleAssignmentByUrl(token: String) {
		let url = ServiceUrls.USER_ASSIGNMENT + '?token=' + token;
		return url;
	}

	public getClientsByUSerIdByUrl(id:any)
	{
		let url = ServiceUrls.CLIENT_LIST +  id + "/getClientsByUserId.json";
		return url
	} 

	public getRolesByUSerIdByUrl(id:any)
	{
		let url = ServiceUrls.ROLES_BY_USER_ID +  id + "/getRolesByUserId.json";
		return url
	} 

	public getRolesListByUrl(token: String) {
		let url = ServiceUrls.GET_ROLE_LIST + '?token=' + token;
        return url;
	}
	
	public getProjectListByUrl(id: any) {
		let url = ServiceUrls.PROJECTS_LIST +  id + "/getProjectListRoles.json";
        return url;
    }

}

class Request {
    constructor() { }
}

class AddRequest {
	constructor(private scope: String, private roleID, private capList: String) { }
}

class UserAssignRequest {
	constructor(private userId: String, private scope) { }
}

class CustomAddRequest {
	constructor(private roleID:string, private capList: String) { }
}
class SaveUserRoleAssignmentRequest {
	constructor(private userId: String, private roles, private clientList: String, private projectList: String) { }
}
