export class Content {
    constructor(
        public folderId: String,
        public folderPath: String,
        public id: String,
        public name: String,
        public header?: Header,
        public steps?: Array<Step>
    ) { }
}

export class Header {
    constructor(
        public system: ProcedureSystem,
        public procType: ProcType,
        public procDesc: ProcDesc,
        public assumption?: Assumption,
        public objective?: Objective,
        public ncwrs?: Array<NCWRS>,
        public images?: Array<ProcImage>,
        public imagePanelFlag?: Boolean,
    ) { }
}

export class ProcedureSystem {
    constructor(
        public id: String,
        public name: String
    ) { }
}

export class ProcType {
    constructor(
        public id: String,
        public name: String
    ) { }
}

export class ProcDesc {
    constructor(
        public element: String
    ) { }
}

export class Assumption {
    constructor(
        public element: String
    ) { }
}

export class Objective {
    constructor(
        public element: String
    ) { }
}

export class NCWRS {
    constructor(
        public type: String,
        public seq: number,
        public aboveStep: number,
        public element?: String,
        public backgroundColor?: String
    ) { }
}

export class GroupTitle {
    constructor(
        public ncwrs?: Array<NCWRS>,
        public element?: String
    ) { }
}

export class Location {
    constructor(
        public seq: number,
        public element?: String
    ) { }
}

export class DependencyStep {
    constructor(
        public display?: number,
        public value?: String
    ) { }
}

export class Document {
    constructor(
        public seq: number,
        public element?: String
    ) { }
}

export class ResponsibleParty {
    constructor(
        public seq: number,
        public element?: String
    ) { }
}

export class OperatingData {
    constructor(
        public id?: string,
        public operatingDataName?: string,
        public operatingDataType?: string,       
        public valueListItems?: string,
    ) { }
}

export class ProcImage {
    constructor(
        public id: string,
        public thumbnailImage?: string,
        public title?: string,       
        public thumbPath?: string,
        public type?: String,
        public insId?: String,
    ){}
}

export class SubStep {
    constructor(
        public id: String,
        public parentStepId: String,
        public stepNo: number,
        public groupTitle?: GroupTitle,
        public element?: String,
        public ncwrs?: Array<NCWRS>,
        public documents?: Array<Document>,
        public locationDesc?: Location,
        public operatingData?: Array<OperatingData>,
        public operatingDataFlag?: Boolean,
        public images?: Array<ProcImage>,
        public imagePanelFlag?: Boolean,
    ) { }
}

export class Step {
    constructor(
        public id: String,
        public stepNo: number,
        public action?: String,
        public backgroundColor?: String,
        public groupTitle?: GroupTitle,
        public dependencySteps?: Array<DependencyStep>,
        public dependencyFlag?: Boolean,
        public element?: String,
        public ncwrs?: Array<NCWRS>,
        public documents?: Array<Document>,
        public locationDesc?: Location,
        public responsiblePartyDesc?: Array<ResponsibleParty>,
        public operatingData?: Array<OperatingData>,
        public operatingDataFlag?: Boolean,
        public images?: Array<ProcImage>,
        public imagePanelFlag?: Boolean,
        public substeps?: Array<SubStep>
    ) { }
}