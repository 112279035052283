import { WsCallback } from "../../../util/ws-callback.interface";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Keycode } from "./keycode.model";
import { ServiceUrls } from "../../../util/service-urls";
import { WsResponse } from "../../../util/ws-response.model";
import { WsType } from "../../../util/ws-type";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class Keycodeverify {
    private callback: WsCallback;

    constructor(private http:HttpClient){}

    public setCallback(callBack: WsCallback) {
        this.callback = callBack;
    }

    public verifyKeyCode(request: String) {
        this.http.post(ServiceUrls.KEY_CODE_VERIFY_URL, request).subscribe(
            data => {
                //var val = (data as HttpErrorResponse).message;
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                this.callback.onSuccess(res, WsType.KEY_CODE_VERIFICATION);
            },
            error => {
                if (error.status == 532) {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                this.callback.onFail(res, WsType.KEY_CODE_VERIFICATION);
                } else {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse("Unknown error happened");
                    this.callback.onFail(res, WsType.KEY_CODE_VERIFICATION);
                }
            }
        );
    }
}
