import { Component, OnInit } from '@angular/core';
import { TagsService } from '../tags.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SharedService } from 'src/app/util/shared.service';
import { AlertService } from 'src/app/util/alert/alert.service';
import { FilesService } from 'src/app/home/files/files.service';
import { Router } from '@angular/router';
import { FilesDataService } from 'src/app/home/files/filesdata/filesdata.service';
import { TranslateService } from '@ngx-translate/core';
import { WsCallback } from 'src/app/util/ws-callback.interface';
import { WsResponse } from 'src/app/util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';
import { UserVariable } from 'src/app/util/common/user-variable';
import { LoadingService } from 'src/app/util/loading/loading.service';
import { DEPENDENCY_ACTION } from '../tag-util';
import { CommonUtil } from 'src/app/util/common/common-util';

@Component({
  selector: 'app-tag-dependency',
  templateUrl: './tag-dependency.component.html',
  styleUrls: ['./tag-dependency.component.css']
})
export class TagDependencyComponent implements OnInit, WsCallback {
  private itemType: String;
  private itemId: string;
  public displayModel: boolean = false;
  //dependency list
  private topicDependency: any;
  private iceCourseDependency: any;
  private iceTopicDependency: any;
  private iceTopicContentDependency: any;
  private procedureDependency: any;
  private tagDependency: any[];
  private checkSheetDependency: any;
  private irnDependency: any;
  private assetParentDependency: any;
  private assetAttrDependency: any;
  private punchListDependency: any;
  private commWorkItemDependency: any;
  private otnDependency: any;
  public allDependencies: any = [];
 
  //confirm popup
  public headerTitle: String;

  public delFlag: boolean = true;
  public cancelFlag: boolean = true;
  public noDependecies: boolean = false;
  public userClickCheckbox: boolean = false;

  constructor(public bsModalRef: BsModalRef, private sharedService: SharedService, private tagService: TagsService, private alertService: AlertService,
    private filesDataService: FilesDataService, private filesService: FilesService, private translate: TranslateService, private router: Router,  private loadingService: LoadingService, private commonUtil: CommonUtil) { }

  ngOnInit() {
    this.translate.addLangs(["es", "en"]);
    this.translate.setDefaultLang("en");
    let browserLang = this.translate.getBrowserLang();
    this.translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
    this.loadingService.showLoading(true,false,"Dependency Loading",100);
    this.itemType = this.sharedService.getFileType();
    this.itemId = this.sharedService.getFileId();
    //{"itemId":"", "itemType":"", "action":"", "userToken":""}
    let token = UserVariable.getUserToken();
    if(!this.commonUtil.validateToken(token)){
            return;
    }
    var request: any = { "itemId": this.itemId, "itemType": this.itemType, "action": this.sharedService.getAction(), "userToken": token };
    this.tagService.getTagDependenciesByTag(JSON.stringify(request), this);
  }

  onSuccess(data: WsResponse, serviceType: WsType) {
    if (serviceType == WsType.GET_TAG_DEPENDENCIES_BY_TAG) {
      this.loadingService.hideLoading();
      let response = JSON.parse(data.payload);
      
      if (response.HasDependencies == 1){
        this.noDependecies = false
      } else {
        this.noDependecies = true          
      }
      
      console.log('=====================add================');
      console.log(response);

      if (response.ICECourseDependency != undefined && response.ICECourseDependency.ICECourses != undefined) {
        this.iceCourseDependency = response.ICECourseDependency.ICECourses;
          if(this.iceCourseDependency.ICECourse.length != undefined){
              for (var dependency of this.iceCourseDependency.ICECourse ) {
                var html = dependency.iceCourseName ;
                var div = document.createElement("div");
                div.innerHTML = html;
                dependency.iceCourseName = div.textContent || div.innerText || "";
              }
          }else{
            var html = this.iceCourseDependency.ICECourse.iceCourseName ;
            var div = document.createElement("div");
            div.innerHTML = html;
            this.iceCourseDependency.ICECourse.iceCourseName = div.textContent || div.innerText || "";
          }
      }
      if (response.TopicDependency != undefined && response.TopicDependency.Topics != undefined) {
        if(response.TopicDependency.Topics.Topic.length == undefined){
          let Topic = [];
          Topic.push(response.TopicDependency.Topics.Topic)
          response.TopicDependency.Topics.Topic = Topic
        }
        this.topicDependency = response.TopicDependency.Topics;
      }
      if (response.ICETopicDependency != undefined && response.ICETopicDependency.ICETopics != undefined) {
        this.iceTopicDependency = response.ICETopicDependency.ICETopics;
          if(this.iceTopicDependency.ICETopic.length != undefined){
            for (var dependency of this.iceTopicDependency.ICETopic ) {
              var html = dependency.iceTopicName ;
              var div = document.createElement("div");
              div.innerHTML = html;
              dependency.iceTopicName = div.textContent || div.innerText || "";
            }
        }else{
          var html =  this.iceTopicDependency.ICETopic.iceTopicName ;
          var div = document.createElement("div");
          div.innerHTML = html;
          this.iceTopicDependency.ICETopic.iceTopicName = div.textContent || div.innerText || "";
        }
      }
      if (response.ICETopicContentDependency != undefined && response.ICETopicContentDependency.ICETopicContents != undefined) {
        this.iceTopicContentDependency = response.ICETopicContentDependency.ICETopicContents;
        if(this.iceTopicContentDependency.ICETopicContent.length != undefined){
          for (var dependency of this.iceTopicContentDependency.ICETopicContent ) {
            var html = dependency.descriptionHTML ;
            var div = document.createElement("div");
            div.innerHTML = html;
            dependency.descriptionHTML = div.textContent || div.innerText || "";
          }
        }else{
          var html =  this.iceTopicContentDependency.ICETopicContent.descriptionHTML ;
          var div = document.createElement("div");
          div.innerHTML = html;
          this.iceTopicContentDependency.ICETopicContent.descriptionHTML = div.textContent || div.innerText || "";
        }
      }
      if (response.ProcedureDependency != undefined && response.ProcedureDependency.Procedures != undefined) {
        this.procedureDependency = response.ProcedureDependency.Procedures;
      }
        if (response.TagDependency != undefined && response.TagDependency.Tags != undefined) {
            this.tagDependency = response.TagDependency.Tags;
        // if (typeof response.TagDependency.Tags == "object") {
        //   this.tagDependency = [];
        //   this.tagDependency.push(response.TagDependency.Tags.Tag);
        // } else {
        //   this.tagDependency = response.TagDependency.Tags.Tag;
        // }
        }
      if (response.CheckSheetDependency != undefined && response.CheckSheetDependency.CheckSheets != undefined) {
        this.checkSheetDependency = response.CheckSheetDependency.CheckSheets;
      }
      if (response.IRNDependency != undefined && response.IRNDependency.IRNs != undefined) {
        this.irnDependency = response.IRNDependency.IRNs;
      }
      if (response.AssetParentDependency != undefined && response.AssetParentDependency.AssetParents != undefined) {
        this.assetParentDependency = response.AssetParentDependency.AssetParents;
      }
      if (response.AssetAttrDependency != undefined && response.AssetAttrDependency.AssetAttributes != undefined) {
        this.assetAttrDependency = response.AssetAttrDependency.AssetAttributes;
      }
      if (response.PunchlistDependency != undefined && response.PunchlistDependency.Punchlists != undefined) {
        this.punchListDependency = response.PunchlistDependency.Punchlists;
      }
      if (response.CommWorkItemDependency != undefined && response.CommWorkItemDependency.WorkItems != undefined) {
        this.commWorkItemDependency = response.CommWorkItemDependency.WorkItems;
      }
      //Achini Randeni
      //get OTN dependency for the tag
      if (response.OTNDependency != undefined && response.OTNDependency.OTNs != undefined) { 
        this.otnDependency = response.OTNDependency.OTNs; 
      } 
      
      this.displayModel = true;
      if (response.HasDependencies != 1 && localStorage.getItem("isDelete") === "true") {
        this.headerTitle = this.translate.instant('TAGS_SEARCH_VIEW.DELETE_ITEM')    
      }
      // this.showDependencies();
    }
    if (serviceType == WsType.CHANGE_TAG_STATUS) {
      console.log("change tag status")
    }
    
  }

  onFail(data: WsResponse, serviceType: WsType) {
    this.loadingService.hideLoading();
    if (serviceType == WsType.GET_TAG_DEPENDENCIES_BY_TAG) {
      this.alertService.error(data.statusDescription);
    }
  }

  confirm(title: String, checkboxValue: boolean, delFlag: boolean) {
    let token = UserVariable.getUserToken();
    if(!this.commonUtil.validateToken(token)){
            return;
        }
    switch (title) {
      case this.translate.instant('TAGS_SEARCH_VIEW.CONFIRM_POPUP.DEPENDENCY_TITLE'):
        //to do
        break;
      case this.translate.instant('TAGS_SEARCH_VIEW.CONFIRM_POPUP.ENABLE_CONFIRM'):
        console.log("Enable Confirmed");
        this.tagService.changeTagStatus(token, this.itemId, "false");
        break;
      case this.translate.instant('TAGS_SEARCH_VIEW.CONFIRM_POPUP.DISABLE_CONFIRM'):
        console.log("Disable Confirmed");
        this.tagService.changeTagStatus(token, this.itemId, "true");
        break;
      case this.translate.instant('TAGS_SEARCH_VIEW.CONFIRM_POPUP.EDIT_CONFIRM'):
        // this.router.navigate(['edittags', { 'tagId': this.itemId }]);
        this.sharedService.openTagsEditRequest(this.itemId)
        break;
      case this.translate.instant('TAGS_SEARCH_VIEW.DELETE_ITEM'):
            console.log("Delete Confirmed");
            ///to do delete
            this.tagService.deleteTag(token, this.itemId);
            break;
      case this.translate.instant('TAGS_SEARCH_VIEW.CONFIRM_POPUP.DELETE_CONFIRM'):
        console.log("Delete Confirmed");       
          if(checkboxValue && this.userClickCheckbox){
            this.sharedService.setAction(DEPENDENCY_ACTION.DISABLE)
            this.tagService.changeTagStatus(token, this.itemId, "true");
          }else if(this.userClickCheckbox){
            this.sharedService.setAction(DEPENDENCY_ACTION.ENABLE)
            this.tagService.changeTagStatus(token, this.itemId, "false");          
          }        
        break;
    }
  }

  onCheckboxClick(event: any){
    console.log("user clicks")
    this.userClickCheckbox = true;
  }
  showDependencies(): void {
    console.log('data = ' + JSON.stringify(this.topicDependency));
    console.log('data = ' + JSON.stringify(this.procedureDependency));
    console.log('data = ' + JSON.stringify(this.tagDependency));
    console.log('data = ' + JSON.stringify(this.checkSheetDependency));
    console.log('data = ' + JSON.stringify(this.irnDependency));
    console.log('data = ' + JSON.stringify(this.assetParentDependency));
    console.log('data = ' + JSON.stringify(this.assetAttrDependency));
    console.log('data = ' + JSON.stringify(this.punchListDependency));
    console.log('data = ' + JSON.stringify(this.commWorkItemDependency));
    console.log('data = ' + JSON.stringify(this.otnDependency))
  }
}
