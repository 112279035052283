<div [formGroup]="form">
    <!-- <label class="mt-1" [attr.for]="searchParams.key">{{searchParams.label}}</label> -->

    <div class="mb-1" [ngSwitch]="searchParams.controlType">

        <input class="form-control form-control-sm search-input-padding" *ngSwitchCase="'textbox'" [formControlName]="searchParams.key"
            [id]="searchParams.key" [type]="searchParams.type" [placeholder]="searchParams.placeholder">

        <input class="form-control form-control-sm search-input-padding" *ngSwitchCase="'category'" [type]="searchParams.type"
            [formControlName]="searchParams.key" [id]="searchParams.key" readonly [placeholder]="searchParams.placeholder"
            (click)="showCategoryList(allCategoryModel, searchParams.type)" [value]="selectedCategoryItem.catName">
        <!--
            <ng-select [id]="searchParams.key" *ngSwitchCase="'dropdown'" [formControlName]="searchParams.key"
            [(ngModel)]="selectedDropDown" class="form-control form-control-sm" [placeholder]="searchParams.placeholder" [items]="searchParams.options">
        </ng-select>
        -->       
        <ng-select class="select-sm" *ngSwitchCase="'dropdown'" [allowClear]="true" [id]="searchParams.key" [formControlName]="searchParams.key" 
            [placeholder]="searchParams.placeholder" [items]="types" [active]="selectedDropDown" (selected)="itemSelected($event)" [(ngModel)]="selectedDropDown">
        </ng-select>

        <div class="input-group" *ngSwitchCase="'wildcards'">
            <input type="text" class="form-control form-control-sm search-input-padding" (keyup)="onKey($event)" [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}" aria-label="Text input with dropdown button" [placeholder]="searchParams.placeholder">
            <input type="text" class="form-control form-control-sm search-input-padding" [id]="searchParams.key" [formControlName]="searchParams.key" [(ngModel)]="wildcardText" hidden="true">
            <div class="input-group-append">
              <!--button type="button" class="form-control form-control-sm py-3 dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span>{{wildcardType}}</span> 
              </button>
              <div class="dropdown-menu">
                <a class="dropdown-item" (click)="wildcardSelected('startsWith')"><span>startsWith</span></a>
                <a class="dropdown-item" (click)="wildcardSelected('contains')"><span>contains</span></a>
                <a class="dropdown-item" (click)="wildcardSelected('endsWith')"><span>endsWith</span></a>
                <a class="dropdown-item" (click)="wildcardSelected('exactword')"><span>exactWord</span></a>
              </div-->
              <ng-select class="select-sm" [items]="wildcardTypes" (selected)="wildcardSelected($event)" [active]="[selectedWildcardType]" (ngModel)="selectedWildcardType">
              </ng-select>
            </div>
        </div>
   
        <input class="form-control form-control-sm search-input-padding" *ngSwitchCase="'doctype'" [type]="searchParams.type"
            [formControlName]="searchParams.key" [id]="searchParams.key" readonly [placeholder]="searchParams.placeholder"
            (click)="showDocumentTypes(docTypesModel, searchParams.type)" [value]="selectedDocType.docName">
        <!-- <ng-select *ngSwitchCase="'dropdown'" [allowClear]="true" (removed)="itemRemoved($event)"
            (selected)="itemSelected($event)" [items]="searchParams.options" [name]="searchParams.key"
            [id]="searchParams.key">
        </ng-select> -->

        <input *ngSwitchCase="'datepicker'" [type]="searchParams.type" class="form-control form-control-sm search-input-padding"
            [formControlName]="searchParams.key" [id]="searchParams.key" [bsConfig]="{ containerClass: 'theme-default' }"
            bsDatepicker [placeholder]="searchParams.placeholder">
        <div *ngSwitchCase="'taginput'">
            <app-taginputitem [key]="searchParams.key" [placeholder]="searchParams.placeholder" [disable]="searchParams.disable"
            (taginputSeleted)="tagInputItemSelected($event)"></app-taginputitem>
        </div>
    </div>

    <!-- <div class="errorMessage" *ngIf="!isValid">{{searchParams.label}} is required</div> -->
</div>

<ng-template #allCategoryModel>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Select a Category</h4>
        <button type="button" id="image_close" class="close pull-right" aria-label="Close"
            (click)="closeButtonClickEvent()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <app-select-category-tree (categorySelected)="selectedCategory($event)" [categoryType]="categoryType"
    [selectedCategoryItem]="tempCategoryItem" (closeModal)="closeButtonClickEvent()">
    </app-select-category-tree>
</ng-template>

<ng-template #docTypesModel>
   <div class="modal-header">
       <h4 class="modal-title pull-left">Select a Document Type</h4>
       <button type="button" id="image_close" class="close pull-right" aria-label="Close"
           (click)="closeButtonClickEvent()">
           <span aria-hidden="true">&times;</span>
       </button>
   </div>
   <app-select-doctype-tree (documentSelected)="selectedDocument($event)" [documentType]="documentType"
   [selectedDocType]="tempDocumentType" (closeModal)="closeButtonClickEvent()">
   </app-select-doctype-tree>
</ng-template>
