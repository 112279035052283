import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DocumentStoreService } from './documentstore.service';
import { UserVariable } from '../../util/common/user-variable';
import { AlertService } from '../../util/alert/alert.service';
import { DocumentStoreModel } from './adddocumentstore/documentstore.model';
import { Router } from '@angular/router';
import { LoadingService } from '../../util/loading/loading.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
   selector: 'addToStore-modal',
   template: `
   <div class="modal-header">
   <h4 class="modal-title pull-left">{{title}}</h4>
   <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
   </button>
</div>
<div class="modal-body">
   <form>
      <div class="form-group row">
         <label for="document_Name" class="col-sm-2 col-form-label">{{ 'DOCUMENT_STORE.DOCUMENT' | translate }}</label>         
            <div class="col-sm-10">
               <input type="text" class="form-control" id="documentName"
               [(ngModel)]="name" name="document" required disabled ="true">
            </div>
      </div>
      <div class="form-group row">
         <label for="key_word" class="col-sm-2 col-form-label">{{ 'DOCUMENT_STORE.FACILITY' | translate }}</label>
         <div class="col-sm-10">
            <ng-select  [active]="selectedFacility" [allowClear]="true"
               (removed)="removedFacility($event)" (selected)="selectedFacilityId($event)"
               [items]="facilityList" name="Facility" id="facility">
            </ng-select>
         </div>
      </div>
      <div class="form-group row">
         <label for="key_word" class="col-sm-2 col-form-label">{{ 'DOCUMENT_STORE.SYSTEM' | translate }}</label>
         <div class="col-sm-10">
            <ng-select  [active]="selectedSystem" [allowClear]="true"
               (removed)="removedSystem($event)" (selected)="selectedSystemId($event)"
               [items]="systemList" name="System" id="system">
            </ng-select>
         </div>
      </div>
      <div class="form-group row">
         <label for="version" class="col-sm-2 col-form-label">{{ 'DOCUMENT_STORE.VERSION' | translate }}</label>         
            <div class="col-sm-10">
               <input type="text" class="form-control" id="version"
               [(ngModel)]="storeVersion" name="version" maxlength="20">
            </div>
      </div>
      <div class="form-group row">
         <label for="notes" class="col-sm-2 col-form-label">{{ 'DOCUMENT_STORE.NOTES' | translate }}</label>         
            <div class="col-sm-10">
               <textarea type="text" class="form-control" id="notes" name="notes" [(ngModel)]="notes" maxlength="200"></textarea>
            </div>
      </div>
      <div class="form-group row">
         <label for="location" class="col-sm-2 col-form-label">{{ 'DOCUMENT_STORE.LOCATION' | translate }}</label>         
         <div class="col-sm-10">
            <textarea type="text" class="form-control" id="location"
            [(ngModel)]="folderPath" name="location" required disabled ="true"></textarea>
         </div>
      </div>
      <div class="form-group row">
         <label for="availability" class="col-sm-2 col-form-label">{{ 'DOCUMENT_STORE.AVAILABILITY' | translate }}</label>         
         <div class="col-sm-10">
            <div class="form-check">
               <input type="checkbox" class="form-check-input" id="availability" name="availability" [(ngModel)]="availability">
            </div>
         </div>
      </div>
      <div class="form-group row">
         <label for="inReview" class="col-sm-2 col-form-label">{{ 'DOCUMENT_STORE.IN_REVIEW' | translate }}</label>         
         <div class="col-sm-10">
            <div class="form-check">
               <input type="checkbox"  class="form-check-input" id="inReview" name="inReview" [(ngModel)]="inReview" disabled>
            </div>
         </div>
      </div>
   </form>
</div>
<div class="modal-footer">  
   <button type="button" class="btn btn-primary" (click)="save();">{{ 'Save' | translate}}</button>
   <button type="button" class="btn btn-secondary" (click)="bsModalRef.hide()">{{ 'CANCEL' | translate}}</button>
</div>
   `
})
export class AddStoreDocumentModalComponent {
   public title: string = 'Add Store Documents';
   public documentStoreId: string
   public name: string
   public storeVersion: string;
   public folderPath: string
   public inReview: boolean
   public notes: string
   public availability: boolean = false
   public documentId: string
   public documentTitle: string
   public documentNo: string
   public outputId: string
   public inReviewReturn: string
   public availabilityReturn: string
   public documentTypeId: string
   public facilityList: any
   public selectedFacility: Array<any> = [{ 'id': '', 'text': 'Select' }]
   public selectedFacilityIdValue: any;
   public selectedFacilityName: any;
   public systemList: any
   public selectedSystem: Array<any> = [{ 'id': '', 'text': 'Select' }]
   public selectedSystemIdValue: any;
   public selectedSystemName: any;
   private token: any;
   public loadingMessage: string;
  
   constructor(public bsModalRef: BsModalRef, private documentStoreService: DocumentStoreService,
      private alertService: AlertService, private router: Router, private loadingService: LoadingService,
      private translate: TranslateService) {
         translate.addLangs(["es", "en"]);
         translate.setDefaultLang("en");
         let browserLang = translate.getBrowserLang();
         translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
         this.token = UserVariable.getUserToken();

         this.translate.get('DOCUMENT_STORE.LOADING_MESSAGE').subscribe((res: string) => {
            this.loadingMessage = res;
         });
      }

   ngOnInit() {
      console.log(this.selectedFacility);

   }

   ngAfterContentInit(){
      console.log(this.selectedFacility);
   }

   save() {
      this.loadingService.showLoading(true, null, "", null)
      if (this.notes == undefined){
         this.notes = '';
      }else{
      }

      if (this.storeVersion == null){
         this.storeVersion ='';
      }
      else if (this.storeVersion.length > 20){
         this.alertService.error(this.translate.instant('DOCUMENT_STORE.VERSION_NO_TOO_LONG'));
         this.loadingService.hideLoading();
         return;
      }

      if (this.inReview != null){
         if(this.inReview == true){
            this.inReviewReturn = '1';
         }else{
            this.inReviewReturn = '0';
         }         
      }else{
         this.inReviewReturn = '0';
      }

      if (this.availability == true){
         this.availabilityReturn = '1';
      }else{
         this.availabilityReturn = '0';
      }

      //send facility as a list in the request model
      var facilityIdList = null;
      if (this.selectedFacilityIdValue != undefined && this.selectedFacilityIdValue != null && this.selectedFacilityIdValue != ""){
         facilityIdList = [this.selectedFacilityIdValue];
      }
      if(facilityIdList == null) {
         facilityIdList = ["0"];
      }

      var documentStoreModel:  DocumentStoreModel = new  DocumentStoreModel(this.documentId, this.documentTitle, this.documentNo, 
          this.selectedSystemIdValue, facilityIdList, this.storeVersion,
          this.folderPath, this.notes, this.outputId, this.availabilityReturn, this.inReviewReturn, this.documentTypeId)
          
      this.bsModalRef.hide();

      this.documentStoreService.addDocumentToDocumentStore(documentStoreModel)
      .subscribe(data => {
         this.loadingService.hideLoading()
         this.alertService.success(data.statusDescription)
         location.reload();
      },
      error => {
         this.loadingService.hideLoading()
         this.alertService.error(error.statusDescription)
      });
      
   }

   deleteDocumentStoreItemClick(documentStoreId) {
      this.loadingService.showLoading(true, null, "", null)
      this.documentStoreService.deleteDocumentStore(documentStoreId)
      .subscribe(data => {
         this.loadingService.hideLoading()
         this.alertService.success(data.statusDescription)
         location.reload();
      },
      error => {
         this.loadingService.hideLoading()
         this.alertService.error(error.statusDescription)
      });
   }

   
   public selectedFacilityId(value: any): void {
      this.selectedFacilityName = value.text;
      this.selectedFacilityIdValue = value.id;

      //reset selected system and get the system list for the selected facility
      this.updateSystemList();

   }

   public removedFacility(event) {
      this.selectedFacility = [{ 'id': '', 'text': 'Select' }]
      this.selectedFacilityName = '';
      this.selectedFacilityIdValue = '';

      //reset selected system and get the system list with no facility
      this.updateSystemList();
   }

   public updateSystemList(){
      this.loadingService.showLoading(true, null, this.loadingMessage, null);
      this.selectedSystem = [{ 'id': '', 'text': 'Select' }]
      this.selectedSystemName = '';
      this.selectedSystemIdValue = '';

      var facilityId = this.selectedFacilityIdValue == '' ? "0" : this.selectedFacilityIdValue;

      this.documentStoreService.getSystemsByFacilityId(this.token, facilityId).subscribe(
         systemData => {
            this.systemList = []
            this.systemList.push({ 'id': '', 'text': 'Select' })
            if (systemData.payload != null) {
               systemData.payload.forEach(element => {
                  this.systemList.push({ 'id': element.id, 'text': element.systemNo + ' ' + element.systemName })
               });
            } else {
               // do nothing
            }
            this.loadingService.hideLoading();
         },
         error => {
            this.alertService.clear()
            this.alertService.error(error.statusDescription)
         }
      );
   }
      
   public selectedSystemId(value: any): void {
      this.selectedSystemName = value.text;
      this.selectedSystemIdValue = value.id;
   }

   public removedSystem(event) {
      this.selectedSystem = [{ 'id': '', 'text': 'Select' }]
      this.selectedSystemName = '';
      this.selectedSystemIdValue = '';
   }
}