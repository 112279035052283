<div class="main-panel pt-0 pb-4" style="overflow: hidden;">
	<div class="container-fluid">
		<div class="col-sm-12 px-0 align-self-end">
			<div class="row align-items-center">
				<div class="col-sm-10">
					<h3 class="">
						{{ 'DOCUMENT_TYPE.MANAGE_ATTRIBUTES' | translate }}
					</h3>
				</div>
				<div class="col-sm-2">
					<button type="button" [disabled]="disabledAddBtn()" (click)="addAttribute()"
						class="btn btn-primary btn-sm float-right">
						{{ 'DOCUMENT_TYPE.BTN_ADD_ATTRIBUTE' | translate }}
					</button>
				</div>
			</div>
			<hr class="mt-0 mb-3" />
		</div>
		<div class="col-sm-12 px-0 my-2">
			<ag-grid-angular #agGrid style="width: 100%; height: 400px;" id="docAttribute" [rowData]="rowData" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }"
				class="ag-theme-balham" [columnDefs]="columnDefs" [frameworkComponents]="frameworkComponents"
				[enableRangeSelection]="true" [paginationPageSize]="10"
				[pagination]="true" (columnResized)="onColumnResized($event)" (gridReady)="onGridReady($event)"
				[suppressMenuHide]="true" [gridOptions]="gridOptions"></ag-grid-angular>
		</div>
	</div>
</div>