import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { UserVariable } from '../util/common/user-variable';
import { ServiceUrls } from '../util/service-urls';
import { WsResponse } from '../util/ws-response.model';
import { WsCallback } from '../util/ws-callback.interface';
import { WsType } from '../util/ws-type';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators'
import { CommonUtil } from '../util/common/common-util';

@Injectable()
export class TopicmapBuilderService {
	public token: string;
	private selectedTopicMapData: any;
	private callback: WsCallback;

    constructor(private http: HttpClient, private commonUtil: CommonUtil) {
    }

	/**
	 * Get topic map content.
	 */
	getTopicMapContent(topicmapId: String,callBack: WsCallback) {
		console.log("[TopicmapBuilderService: getTopicMapContent]")
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
		const options = { params: new HttpParams().set("token", token) }
        let url = this.getTopicMapContentURL(topicmapId);
		this.http.get(url, options).
			subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.code,
					modified.status.name,
					modified.status.description,
					modified.payload);
					callBack.onSuccess(res, WsType.GET_TOPIC_MAP_CONTENT);
			},
			error => {
				var val = (error as HttpErrorResponse).error;
				var modified = JSON.parse(JSON.stringify(val));
				var res = new WsResponse(
					modified.status.code,
					modified.status.name,
					modified.status.description,
					modified.payload);
					callBack.onFail(res, WsType.GET_TOPIC_MAP_CONTENT);
			});
	}

    /**
	 * Define URL to get topic map content.
	 */
	public getTopicMapContentURL(topicmapId : String) {
        let url = ServiceUrls.TOPIC_MAPS + topicmapId + '/getTopicMapContent.json'
		return url;
	}

	/**
	 * Save topic map content.
	 */
	public saveTopicMap( itemId: String, content: String, title: String, callBack: WsCallback) {
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
		let url = this.getSaveTopicmapURL(itemId, token);
		this.http.post(url, JSON.stringify(new Request(itemId,content,title))).subscribe(
			
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
					callBack.onSuccess(res, WsType.SAVE_TOPIC_MAP);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
						callBack.onFail(res, WsType.SAVE_TOPIC_MAP);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					callBack.onFail(res, WsType.SAVE_TOPIC_MAP);
				}
			}
		);
	  }

	  /**
     * Checkin ckeckinTopicmap.
     * @param itemId Document Id. 
     * @param itemType Document Type.
     * @param checkinComments Checkin comments.
     */
    public checkinTopicmap(itemId: String, content: String,checkinComments:String,callBack: WsCallback) {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
		let url = this.getCheckinTopicmapURL(itemId,token);
        this.http.post(url, JSON.stringify(new checkinRequest(itemId,content,checkinComments))).
            subscribe(
                data => {
					var modified = JSON.parse(JSON.stringify(data));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onSuccess(res, WsType.CHECKIN_TOPIC_MAP);
                },
                error => {
					if (error.status != '') {
						var val = (error as HttpErrorResponse).error;
						var modified = JSON.parse(JSON.stringify(val));
						var res = new WsResponse(
							modified.status.description,
							modified.status.code,
							modified.status.name,
							modified.payload);
							callBack.onFail(res, WsType.CHECKIN_TOPIC_MAP);
					} else {
						//browser related issues
						var res = new WsResponse("Unknown error happened");
						callBack.onFail(res, WsType.CHECKIN_TOPIC_MAP);
					}
                });
	}

	/**
	 * Rename topic/proceudre/content item map item.
	 */
	public updateTopicMapItemName(id : String,topicmapItemName : String,itemType : String,callback: WsCallback){
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
		let url = '';
		let wsTypeToCall :any;
		switch (itemType) {
			case 'Topic':
				url = this.getUpdateTopicNameURL(id,token); 
				wsTypeToCall = WsType.RENAME_TOPIC;
				break;
			case 'Procedure':
				url = this.getUpdateProcedureNameURL(id,token); 
				wsTypeToCall = WsType.RENAME_PROCEDURE;
				break;
			case 'ContentItem':
				url = this.getUpdateContentItemNameURL(id,token); 
				wsTypeToCall = WsType.RENAME_CONTENT_ITEM;
				break;
			case 'Image':
				url = this.getUpdateImageNameURL(id,token); 
				wsTypeToCall = WsType.RENAME_IMAGE;
				break;
			default:
				break;
		}


        this.http.post(url, JSON.stringify(new renameRequest(id,topicmapItemName))).
            subscribe(
                data => {
					var modified = JSON.parse(JSON.stringify(data));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    	callback.onSuccess(res,wsTypeToCall);
                },
                error => {
					if (error.status != '') {
						var val = (error as HttpErrorResponse).error;
						var modified = JSON.parse(JSON.stringify(val));
						var res = new WsResponse(
							modified.status.description,
							modified.status.code,
							modified.status.name,
							modified.payload);
							callback.onFail(res, wsTypeToCall);
					} else {
						//browser related issues
						var res = new WsResponse("Unknown error happened");
						callback.onFail(res, wsTypeToCall);
					}
                });
	}
	
	/**
     * Define URL for checkin document from files data
     */
    public getCheckinDocumentFileDataURL(token: String) {
        let url = ServiceUrls.CM_SYSTEM_DOCUMENTS + 'checkinDocumentById.json?token=' + token;
        return url;
    }
	 
	  public getSaveTopicmapURL(itemId: String, token: String) {
        let url = ServiceUrls.TOPIC_MAPS + itemId + '/saveTopicmap.json' + '?token=' + token;
        return url;
	}
	
	public getCheckinTopicmapURL(itemId: String, token: String) {
        let url = ServiceUrls.TOPIC_MAPS + itemId + '/checkinTopicmap.json' + '?token=' + token;
        return url;
	}
	
	public getUpdateTopicNameURL(itemId: String, token: String) {
        let url = ServiceUrls.TOPICS + itemId + '/updateTopicName.json' + '?token=' + token;
        return url;
	}

	public getUpdateProcedureNameURL(itemId: String, token: string): string {
		let url = ServiceUrls.PROCEDURES + itemId + '/updateProcedureName.json' + '?token=' + token;
        return url;
	}

	public getUpdateContentItemNameURL(itemId: String, token: string): string {
		let url = ServiceUrls.CONTENTS + itemId + '/updateContentItemName.json' + '?token=' + token;
        return url;
	}

	public getUpdateImageNameURL(itemId: String, token: string): string {
		let url = ServiceUrls.IMAGES + itemId + '/updateImageName.json' + '?token=' + token;
        return url;
	}

	  /**
     * Get basic file data using file id.
     * * @param fileId File Id
     */
    getBasicFileDataforOtherRef(fileId: String, fileType: String): Observable<WsResponse> {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        let url = this.getBasicFileDataByIdURL(fileId, fileType);
		const options = { params: new HttpParams().set("token", token) }		
		return this.http.get(url, options).pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
		);
	}

	  /**
     * Get basic topic data using file id.
     * @param fileId File Id
	 * @param fileType File Type
     */
    getEditorXMLforOtherRef(fileId: string): Observable<WsResponse> {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        let url = this.getTopicForEditorURL(fileId);
		const options = { params: new HttpParams().set("token", token) }		
		return this.http.get(url, options).pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
		);
	}

	/**
  * Define URL for get basic topic by topic id.
  */
	public getBasicFileDataByIdURL(fileId: String, fileType: String) {
		switch (fileType) {
			case "Topic":
				return ServiceUrls.TOPICS + fileId + '/getBasicTopic.json';
			case "Procedure":
				return ServiceUrls.PROCEDURES + fileId + '/getBasicProcedure.json';
		}
	}

	/**
	 * Define URL for get editor topic xml.
	 * @param fileId 
	*/
	public getTopicForEditorURL(fileId: string) {
		let url = ServiceUrls.FILE_EDITOR_VIEW + 'topics/' + fileId + '/GetEditorTopicContent.json'
		return url;
	}


	/**
	 * Get image data for view in modal.
	*/
	getImageView(imageId: string,): Observable<WsResponse> {
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
		const options = { params: new HttpParams().set("token", token).set("imageId", imageId)}
		return this.http.get(ServiceUrls.GET_IMAGE_VIEW, options).pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
		);
	}

	/**
     * Undo checkout topicmap by topicmap id and item type.
     * @param itemId Item Id
     * @param itemType Item type
     */
    undoCheckoutTopicmap(itemId: String, itemType: String, callBack: WsCallback) {
		console.log('[topic-map-builder-service: (undoCheckoutTopicmap)]-Start');
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        let url = this.getundoCheckoutTopicmapByIdURL(token);

        this.http.post(url, JSON.stringify(new UndoCheckoutRequest(itemId, itemType))).
            subscribe(
                data => {
                    var modified = JSON.parse(JSON.stringify(data));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                   		callBack.onSuccess(res, WsType.CM_SYSTEM_UNDO_CHECKOUT);
                },
                error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
						callBack.onFail(res, WsType.CM_SYSTEM_UNDO_CHECKOUT);
                });
	}
	
	/**
    * Define URL for undo checkout topicmap by id.
    */
   public getundoCheckoutTopicmapByIdURL(token: String) {
		let url = ServiceUrls.CM_SYSTEM_DOCUMENTS + 'undoCheckoutDocumentById.json?token=' + token;
		return url;
	}
	
}

class Request {
    constructor(private id: String, private topicMapContent: String,  private topicmapTitle: String){}
}

class checkinRequest {
    constructor(private id: String, private topicMapContent: String,
        private comment:String) { }
}

class renameRequest {
    constructor(private id: String, private title: String) { }
}

class UndoCheckoutRequest {
    constructor(private itemId: String, private itemType: String) { }
}
