import { Component, OnInit, Directive, ViewContainerRef,ElementRef, QueryList } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DocumenttypetreeComponent } from '../../../util/documenttypetree/documenttypetree.component';
import { FilesService } from '../files.service';
import { TreeListener } from '../../../util/documenttypetree/tree-listener.interface';
import { File } from '../../../common-components/items/file.model';
import { DocumentTypeService } from '../../../util/documenttypetree/documenttype.service';
import { WsCallback } from '../../../util/ws-callback.interface';
import { WsType } from '../../../util/ws-type';
import { WsResponse } from '../../../util/ws-response.model';
import { AlertService } from '../../../util/alert/alert.service';
import { NewTopicMapService } from './new-topic-map-service.service';
import { LocalStorage } from '../../../util/localstorage.service';
import { FloatingButtonCallback } from '../floating-button-callback.interface';
import { LoadingService } from '../../../util/loading/loading.service';
import { TranslateService } from '@ngx-translate/core';
import { UserVariable } from '../../../util/common/user-variable';
import { SharedService } from '../../../util/shared.service';
import { CommonUtil } from '../../../util/common/common-util';
import { FolderService } from 'src/app/foldertree/foldertree.service';

@Component({
  selector: 'app-new-topic-map',
  templateUrl: './new-topic-map.component.html',
  styleUrls: ['./new-topic-map.component.css']
})
export class NewTopicMapComponent implements OnInit, TreeListener, WsCallback {
  public title: string;
  //folder name field of the form
  public folderName: string;
  //topic map name field of the form
  public topicMapName: string;
  //document type field of the form
  public documentType: string;
  private documentTypeId: string;
  private floatingButtonCallback: FloatingButtonCallback;

  public fileData: any;
  private treeBsModalRef: BsModalRef;
  private selectedFileData: any;
  public haveProperties:boolean = true;
  public attributeList: any;
  public folderPath: string;
  
  constructor(public newTopicMapbsModalRef: BsModalRef, private modalService: BsModalService, private filesService: FilesService,
    private documentTypeService: DocumentTypeService, private alert: AlertService, private topicMapService: NewTopicMapService,
    private loadingService: LoadingService, private translate: TranslateService , private sharedService: SharedService, private commonUtil: CommonUtil, private folderService: FolderService) {
    this.fileData = this.filesService.getSelectedFileData();
  }

  ngOnInit() {
    if (this.selectedFileData != undefined) {
      this.folderName = this.selectedFileData.text;
    }
  }

  public loadDocumentTypes() {
		const initialState = {
      title: 'Select a Document Type',
      treeListener: this,
      initial: true
		};
		this.treeBsModalRef = this.modalService.show(DocumenttypetreeComponent, { initialState, class: 'modal-lg' });
    this.treeBsModalRef.content.closeBtnName = 'Close';
  }

  /**
   * Clicks on the save button of the tree view popup
   * @param file selected file
   */
  onSaveButtonClick(file: any, texts: QueryList<ElementRef>, options: QueryList<ElementRef>, documentTypeAttributes: any) {
    this.documentTypeService.getDocumentTypeAttributes(file.node.id, this.selectedFileData, this);
    this.documentType = file.node.text;
    this.documentTypeId = file.node.id;
    this.treeBsModalRef.hide();
  }

  /**
   * Clicks on the submit button of the form.
   */
  onSubmitButtonClick() {
    if (!this.validateForm()) {
      return;
    }
    let selectedFolderData = this.folderService.getSelectedFoldeData();
    this.folderPath = selectedFolderData != null ? selectedFolderData.original.path : null;
    var loadingMessage;
    this.translate.get('NEW_TOPIC_MAP.LOADING_MESSAGE').subscribe((res: string) => {
			loadingMessage = res;
		});
    this.loadingService.showLoading(true, null, loadingMessage, null);

    var attributeValueXml: string = '';
    //creating attribute xml string.
    if (this.attributeList != undefined && this.attributeList.length != undefined && this.attributeList.length > 0) {
      attributeValueXml = '<attrbutes>';
      this.attributeList.forEach(item => {
        attributeValueXml += "<attrVal typeAttrId='" + item.attributeId + "' value='" + item.value + "'></attrVal>";
      });
      attributeValueXml += '</attrbutes>';
    }

    let token = UserVariable.getUserToken();
    if(!this.commonUtil.validateToken(token)){
        return;
    }
    var request = { "userToken": token,
                    "itemType": "topicmap",
                    "folderId": this.selectedFileData.id,
                    "docTypeId": this.documentTypeId,
                    "attributeValueXml": attributeValueXml,
                    "itemName": this.topicMapName};

    this.topicMapService.createNewTopicMap(JSON.stringify(request), this);
  }

  validateForm(): boolean {
    if (this.folderName == undefined) {
      return false;
    }
    if (this.topicMapName == undefined || this.topicMapName.trim() == "") {
      this.alert.error("Topic map name cannot be empty.", false);
      return false;
    }
    if (this.documentType == undefined) {
      return false;
    }


    //Check for attribute values
    if (this.attributeList != undefined && this.attributeList.length != undefined && this.attributeList.length > 0) {
      var isEmptyAttribute: boolean = false;
      this.attributeList.forEach(item => {
        if (item.required == 'yes' && (item.value == null || item.value == "")) {
					this.alert.error(this.translate.instant('NEW_TOPIC_MAP.ATTRIBUTE.EMPTY_ATTRIBUTE_ERROR_MESSAGE'));
          isEmptyAttribute = true;
        }
        // if(item.attributeName == 'Document'){
        //     if(item.documents != undefined){
        //     isEmptyAttribute = false;
        //     }
        // } else if(item.attributeName == 'c1'){
        //     if(item.components != undefined){
        //     isEmptyAttribute = false;
        //     }
        // } else if(item.attributeName == 'System'){
        //     if(item.systems != undefined){
        //     isEmptyAttribute = false;
        //     }
        // }
      });
      if (isEmptyAttribute) {
        return false;
      }
    }

    return true;
  }

  onSuccess(data: WsResponse, serviceType: WsType) {
    if (serviceType == WsType.DOCUMENT_TYPE_ATTRIBUTES_VIEW) {
      this.attributeList = data.payload;
      if (data.payload.length == 0) {
        this.haveProperties = false;
      } else {
        this.haveProperties = true;
      }
    } else if (serviceType == WsType.CREATE_TOPIC_MAP) {
      this.floatingButtonCallback.hide();
      this.loadingService.hideLoading();
      this.alert.success(data.statusDescription);
      let folderPath: string = this.folderPath + data.payload.topicMapName;
      //open topic map builder
      this.sharedService.openTopicMapBuilder(data.payload.id, data.payload.topicMapName, 'edit', data.payload.topicMapContent);
      this.sharedService.openTabRequestWithId(data.payload.id, data.payload.topicMapName, 'TopicMapBuilder', null, folderPath);
    }
  }

  onFail(data: WsResponse, serviceType: WsType) {
    if (serviceType == WsType.DOCUMENT_TYPE_ATTRIBUTES_VIEW) {
      this.alert.error(data.statusDescription, false);
    } else if (serviceType == WsType.CREATE_TOPIC_MAP) {
      this.alert.error(data.statusDescription, false);
      this.loadingService.hideLoading();
    }
  }

	checkComponent(type:any, name:any){
		if(type == 'Component' || name == 'c1' ){
		   return true;
	   }
   }

   checkSystem(type:any, name:any){
		 if(type == 'System' || name == 'System' ){
		   return true;
	   }
   }
   checkDocument(type:any, name:any){
		 if(type == 'Document' || name == 'Document'){
		   return true;
	   }
   }

}

