<div class="row">
    <div class="col-sm-12">
        <div class="input-with-icon">
            <input #symbiosis_search class="form-control" id ="mainsearchinput"  name="symbiosis_search" placeholder="Search" style="padding-right: 60px;"
            [disabled]="showSearchOptions || withoutHTWSearch" (keyup.enter)="mainSearch(symbiosis_search.value)"/>
            <div *ngIf="!showSearchOptions" class="btn btn-default icon" (click)="moreSearch()">
                <i class="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
        </div>
    </div>
    <div class="col-sm-12" *ngIf="showSearchOptions">
        <div class="card panel shadow-sm">
            <div class="panel-header px-3 py-1">
                <button type="button" class="close float-right" aria-label="Close" (click)="closeSearchOptions()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="card-body py-2">
                <symbiosis-search-form [searchParams]="searchParams" (searchClick)="searchBtnClickEvent($event)"></symbiosis-search-form>
            </div>
        </div>
    </div>
</div>

