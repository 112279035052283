import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ServiceUrls } from '../util/service-urls';
import { WsCallback } from '../util/ws-callback.interface';
import { WsType } from '../util/ws-type';
import { WsResponse } from '../util/ws-response.model';

@Injectable({
    providedIn: 'root'
})
export class RecentProject {
	private recentProjectList: any;
	constructor(private http: HttpClient) { }

	public setRecentProjectList(recentProjectList: any) {
		this.recentProjectList = recentProjectList;
	}

	public getRecentProjectList() {
		return this.recentProjectList;
	}

    /**
     * Add or update recent project.
     * @param request json request with a format as  {"projectid":"","userid":"","isdefault":"","projecttype":""}
     */
	public addUpdateRecentProject(request: string, wsCallBack: WsCallback) {
		this.http.post(ServiceUrls.ADD_UPDATE_RECENT_PROJECT, request).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.ADD_UPDATE_RECENT_PROJECT);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.ADD_UPDATE_RECENT_PROJECT);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.ADD_UPDATE_RECENT_PROJECT);
				}
			}
		);
	}

    /**
     * Get all recent project list
     * @param userId id value of the user
     */
	public getAllRecentProjects(userId: String, wsCallBack: WsCallback) {
		this.http.get(ServiceUrls.getAllRecentProjects(userId)).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_ALL_RECENT_PROJECTS);
			},
			error => {
				if (error.status != '') {
					if (error.status == "0") {
						var res = new WsResponse("", error.status, "", "");
						wsCallBack.onFail(res, WsType.GET_ALL_RECENT_PROJECTS);
						return;
					}
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_ALL_RECENT_PROJECTS);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_ALL_RECENT_PROJECTS);
				}
			}
		);
	}
    /**
     * Get default project of the user
     * @param userId id of the user
     */
	public getDefaultRecentProject(userId: string, wsCallBack: WsCallback) {
		this.http.get(ServiceUrls.getDefaultRecentProject(userId)).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_DEFAULT_RECENT_PROJECT);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_DEFAULT_RECENT_PROJECT);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_DEFAULT_RECENT_PROJECT);
				}
			}
		);
	}

    /**
     * Set the default project of the user
     * @param request json request with a format as  {"projectId":"","userId":"","isDefaultProject":"","projectType":"", "userId":""}
     */
	public setDefaultRecentProjects(request: string, wsCallBack: WsCallback) {
		this.http.post(ServiceUrls.SET_DEFAULT_RECENT_PROJECT, request).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.SET_DEFAULT_RECENT_PROJECT);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.SET_DEFAULT_RECENT_PROJECT);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.SET_DEFAULT_RECENT_PROJECT);
				}
			}
		);
	}

    /**
     * Delete a recently visited project
     * @param request json request with a format as  {"projectId":"","userId":"", "projectType":""}
     */
	public deleteUserRecentProject(request: string, wsCallBack: WsCallback) {
		this.http.post(ServiceUrls.DELETE_USER_RECENT_PROJECT, request).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.DELETE_USER_RECENT_PROJECT);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.DELETE_USER_RECENT_PROJECT);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.DELETE_USER_RECENT_PROJECT);
				}
			}
		);
	}

    /**
     * Update default project as non default
     * @param request json request with a format as  {"projectId":"","userId":"", "projectType":""}
     */
	public deleteUserDefaultRecentProject(request: String, wsCallBack: WsCallback) {
		this.http.post(ServiceUrls.DELETE_USER_DEFAULT_RECENT_PROJECT, request).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.DELETE_USER_DEFAULT_RECENT_PROJECT);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.DELETE_USER_DEFAULT_RECENT_PROJECT);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.DELETE_USER_DEFAULT_RECENT_PROJECT);
				}
			}
		);
	}

	/**
	 * Get project by project ID (token)
	 * @param token user token
	 * @param wsCallBack callback class
	 */
	public getProject(token: any, wsCallBack: WsCallback) {
		this.http.get(ServiceUrls.getProject(token)).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_PROJECT);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_PROJECT);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_PROJECT);
				}
			}
		);
	}
}
