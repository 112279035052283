<div class="my-login-page" data-gr-c-s-loaded="true">
	<section class="h-100">
		<div class="container h-100">
			<div class="row justify-content-md-center h-100">
				<div class="card-wrapper">
					<div class="mt-3 mb-3">
						<img src="../../../assets/images/mainimage.png" alt="" width="100%" height="auto">
					</div>
					<div class="card fat">
						<div class="card-body">
							<h4 class="card-title">{{ 'CHANGE_PASSWORD.PASSWORD_CHANGE_STATUS.CHANGE_PASSWORD' | translate}}</h4>
							<div class="form-group">
								<span for="oldPassword">{{ 'CHANGE_PASSWORD.PASSWORD_CHANGE_STATUS.PASSWORD_CHANGE_SUCCESS' | translate}}
									<!--<a id="passwordchangestatus_passwordChangeStatusLink" class="link-primary" (click)="loginLinkClicked()">-->
									{{ 'CHANGE_PASSWORD.PASSWORD_CHANGE_STATUS.LOGIN' | translate}}
									<!--</a> -->{{ 'CHANGE_PASSWORD.PASSWORD_CHANGE_STATUS.TO_CONTINUE' | translate}}
								</span>
								<div class="text-center">
									<button type="button" class="btn btn-primary my-2" (click)="loginLinkClicked()">OK</button>
								</div>
							</div>
						</div>
					</div>
					<div class="footer">
						{{ 'ALL_RIGHTS' | translate}}
					</div>
				</div>
			</div>
		</div>
	</section>
</div>