<div>
    <h5><b>Compare Versions</b></h5>
    <table width = 100%>
        <tr>
            <td><b>{{itemType}} : </b>{{itemName}} ({{revisionNo1}} and {{revisionNo2}} versions)</td>
        </tr>           
    </table>
    <p></p>
</div>
<hr>
<div class="ftdiv-overflow" [style.height.px]="divHeight" style="width: 100%">
    <div  [innerHTML] = "revisionContent | safeHtml" >	</div>
</div>