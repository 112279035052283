import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { IAfterGuiAttachedParams, ICellRendererParams } from "@ag-grid-enterprise/all-modules";
@Component({
  selector: 'app-attribute-type-render',
  template: `
    {{name}}
  `,
  styles: []
})
export class AttributeTypeRenderComponent implements ICellRendererAngularComp {
  public name: any;

  constructor() { }

  refresh(params: any): boolean {
    return false;
  }

  agInit(params: ICellRendererParams): void {
    if ( params.data != undefined)  {
      this.name = params.data.selectedAttributeType;
    } else {
      this.name = params.value;
    }
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
    console.log('afterGuiAttached');
  }
}
