export class WsType {

	// class variables
	public static LOGIN = 1;
	public static KEY_CODE_VERIFICATION = 2;
	public static LANDING = 2;
	public static FOLDER_VIEW = 3;
	public static FILE_VIEW = 4;
	public static FILE_DATA_VIEW = 5;
	public static OPEN_ID_LOGIN = 6;
	public static FILE_EDITOR_VIEW = 7;
	public static SESSION_VALIDATION = 8;
	public static PASSWORD_RESET_URL = 9;
	public static PASSWORD_RESET_VALIDATE = 10;
	public static PASSWORD_RESET = 11;
	public static CHANGE_PASSWORD = 12;
	public static FACILITY_SEARCH_VIEW = 13;
	public static FILE_UPDATE = 14;
	public static ADD_UPDATE_RECENT_PROJECT = 15;
	public static GET_ALL_RECENT_PROJECTS = 16;
	public static GET_DEFAULT_RECENT_PROJECT = 17;
	public static SET_DEFAULT_RECENT_PROJECT = 18;
	public static DELETE_USER_RECENT_PROJECT = 19;
	public static DELETE_USER_DEFAULT_RECENT_PROJECT = 20;
	public static FOLDER_ADD = 21;
	public static FOLDER_UPDATE = 22;
	public static FOLDER_DELETE = 23;
	public static DOCUMENT_TYPES_VIEW = 24;
	public static DOCUMENT_TYPE_ATTRIBUTES_VIEW = 25;
	public static CM_SYSTEM_CHECKOUT = 26;
	public static BASIC_FILE_DATA = 27;
	public static CM_SYSTEM_UNDO_CHECKOUT = 28;
	public static ITEM_DEPENDENCY_LIST = 29;
	public static ITEM_DISABLE_ENABLE = 30;
	public static DELETE_ITEM = 31;
	public static TAG_SEARCH_VIEW = 32;
	public static FACILITY_ADD = 33;
	public static GET_GOOGLE_KEYS = 34;
	public static COMPONENTSS_SEARCH_VIEW = 35;
	public static STATEMENT_SEARCH_VIEW = 36;
	public static COMPONENTSS_CATEGORY_VIEW = 37;
	public static CREATE_NEW_TOPIC = 38;
	public static DOCUMENTS_SEARCH_VIEW = 39;
	public static GET_SYSTEM_LIST = 40;
	public static GET_OUTPUT_CONFIG_LIST = 41;
	public static CONVERT_PROC_CONTENT = 42;
	public static ADD_PROCEDURE = 43;
	public static FILE_UPLOAD = 44;
	public static STATEMENT_DATA_VIEW = 45;
	public static CATEGORY_VIEW = 46;
	public static CHECKIN_DOCUMENT_FILE_DATA = 47;
	public static CHECKIN_DOCUMENT_EDITOR = 48;
	public static SAVE_DOCUMENT_EDITOR = 49;
	public static SYSTEM_SEARCH_VIEW = 50;
	public static STATEMENT_UPDATE = 51;
	public static STATEMENT_ADD = 52;
	public static REVISION_CONTENT_VIEW = 53;
	public static RESTORE_REVISION = 54;
	public static BASIC_FILE_DATA_CHECKIN = 55;
	public static DOWNLOAD_IMAGE = 56;
	public static CREATE_TOPIC_MAP = 57;
	public static GET_TAG_DEPENDENCIES_BY_TAG = 58;
	public static GET_TAG_BY_TAG_ID = 59;
	public static REVISION_DATA_VIEW = 60;
	public static FACILITY_DATA_VIEW = 61;
	public static FACILITY_UPDATE = 62;
	public static GET_FACILITY_WITH_DEPENDENCIES = 63;
	public static GET_SYSTEMS_BY_FACILITY_ID = 64;
	public static GET_DOCUMENTS_BY_FACILITY_ID = 65;
	public static GET_DATA_SHEETS_BY_FACILITY_ID = 66;
	public static GET_ALL_FACILITY = 67;
	public static GET_TAG_CLASSIFICATION_LIST_WITH_ATTRIBUTES = 68;
	public static GET_TOPIC_MAP_CONTENT = 69;
	public static GET_SYSTEMS_BY_PROJECT_ID = 70;
	public static GET_COMPONENTS_BY_PROJECT_ID = 71;
	public static GET_DOCUMENTS = 72;
	public static GET_ALL_TAG_SUGGESTION_MODEL_LIST = 73;
	public static GET_FAILURE_CLASS_LIST = 74;
	public static GET_SAFETY_CRITICAL_LIST = 75;
	public static GET_CRITICALITY_LIST = 76;
	public static GET_EQUIPMENT_TYPE_LIST = 77;
	public static GET_DISCIPLINE_LIST = 78;
	public static GET_ALL_ACTIVE_ASSET_GROUPS = 79;
	public static GET_ASSET_GROUP_ATTRIBUTES = 80;
	public static GET_ASSET_GROUP_PARENTS = 81;
	public static GET_ALL_FUNCTION_CODES = 82;
	public static GET_ALL_VENDORS = 83;
	public static GET_ASSET_ATTRIBUTES = 84;
	public static GET_VALUE_LIST_VALUES = 85;
	public static ADD_NEW_TAG = 86;
	public static SYSTEM_DATA_VIEW = 87;
	public static SYSTEM_UPDATE = 88;
	public static DOCUMENT_DATA_VIEW = 89;
	public static DOCUMENT_UPDATE = 90;
	public static UPDATE_TAG = 91;
	public static GET_PROJECT = 92;
	public static COMPONENT_DATA_VIEW = 93;
	public static COMPONENT_UPDATE = 94;
	public static GET_TAG_CLASSIFICATION = 95;
	public static UPDATE_CLASSIFICATION_DESCRIPTION = 96;
	public static GET_ATTRIBUTES_BY_CLASSIFICATION = 97;
	public static GET_CATEGORIES_BY_CLASSIFICATION = 98;
	public static MULTIPLE_ACTIONS = 99;
	public static GET_EXISTANCE = 100;
	public static GET_TAG_ATTRIBUTE_TYPES = 101;
	public static UPDATE_TAG_ATTRIBUTE_SEQUENCE = 102;
	public static TOGGLE_TAG_CLASSIFICATION_ATTRIBUTE_REQUIRE_STATUS = 103;
	public static REMOVE_TAG_CLASSIFICATION_ATTRIBUTE_MAP = 104;
	public static ADD_NEW_CLASSIFICATION = 105;
	public static GET_TAG_CLASSIFICATION_ATTRIBUTE_LIST = 106;
	public static TAG_ATTRIBUTE_TYPE_NAME_AVAILABILITY = 107;
	public static ADD_TAG_ATTRIBUTE = 108;
	public static UPDATE_TAG_ATTRIBUTE = 109;
	public static DELETE_TAG_ATTRIBUTE = 110;
	public static UPDATE_TAG_ATTRIBUTE_NOTATION = 111;
	public static GET_CMMS_VALUE_LIST = 112;
	public static ADD_VALUE_LIST = 113;
	public static UPDATE_VALUE_LIST = 114;
	public static GET_DEFAULT_ITEMS_FOR_VALUE_LIST = 115;
	public static GET_VALUE_LIST_ATTRIBUTES = 116;
	public static UPDATE_VALUE_LIST_SEQUENCE = 117;
	public static UPDATE_VALUE_LIST_ATTRIBUTE = 118;
	public static ADD_VALUE_LIST_ATTRIBUTE = 119;
	public static DELETE_VALUE_LIST_ATTRIBUTE = 120;
	public static ADD_EQUIPMENT_FIELD = 121;
	public static UPDATE_VALUE_LIST_NAME = 122;
	public static DELETE_VALUE_LIST = 123;
	public static ADD_NEW_IMAGE = 124;
	public static CHECK_OUT_IMAGE = 125;
	public static UNDO_CHECK_OUT_IMAGE = 126;
	public static CHECK_IN_IMAGE = 127;
	public static CHECK_OUT_TOPIC_EDITOR = 128;
	public static TOPIC_CHECKOUT_VIEW = 129;
	public static FILE_CHECKOUT = 130;
	public static FILE_CHECKOUT_UNDO = 131;
	public static FILE_CHECK_IN = 132;
	public static ADD_WORD_TO_DICTIONARY = 133;
	public static DELETE_WORD_DICTIONARY = 134;
	public static ASSIGN_TAG_ATTRIBUTE = 135;
	public static EDIT_PROJECT = 136;
	public static GET_TAG_ADD_PRE_CONDITIONS = 137;
	public static SEARCH_ALTERNATE_TAG = 138;
	public static GET_NEXT_AVAILABLE_EQUIPMENT_NUMBER = 139;
	public static SAVE_TOPIC_MAP = 140;
	public static CHECKIN_TOPIC_MAP = 141;
	public static GENERATE_UNIQUE_IDENTIFIER = 142;
	public static DUPLICATE_ITEM = 143;
	public static FILE_SEARCH = 144;
	public static ADD_PROJECT = 145;
	public static ADD_CLIENT= 146;
	public static EDIT_CLIENT= 147;
	public static BASIC_FILE_DATA_UNDO_CHECKOUT = 148;
	public static GET_DOCUMENT_TYPES = 149;
	public static GET_SIBLING = 150;
	public static GET_DOCUMENT_TYPE_ATTRIBUTE_LIST = 151;
	public static GET_CATEGORY_VALUES = 152;
	public static UPDATE_TAG_CLASSIFICATION_NAME = 153;
	public static CREATE_ICE_COURSE = 154;
	public static DELETE_TAG_CLASSIFICATION = 155;
	public static UPDATE_ICE_COURSE = 156;
	public static GET_ICE_COURSE = 157;
	public static CREATE_ICE_TOPIC_CONTENT = 158;
	public static GET_ICE_TOPIC_CONTENT = 159;
	public static UPDATE_ICE_TOPIC_CONTENT = 160;
	public static CREATE_ICE_TOPIC = 161;
	public static UPDATE_ICE_TOPIC = 162;
	public static GET_ICE_TOPIC = 163;
	public static CHECKIN_ICE_COURSE = 164;
	public static CHECKOUT_ICE_COURSE = 165;
	public static GET_ALL_ICE_TOPICS = 166;
	public static UNDO_CHECKOUT_ICE_COURSE = 167;
	public static GET_ICE_COURSE_REVISIONS_DATA = 168;
	public static RESTORE_ICE_COURSE_REVISION = 169;
	public static DELETE_ICE_TOPIC_CONTENT = 170;
	public static DELETE_ICE_TOPIC = 171;
	public static REORDER_ICE_TOPIC = 172;
	public static REORDER_ICE_TOPIC_CONTENT = 173;
	public static CHECK_ICE_TOPIC_DEPENDENCY = 174;
	public static GET_ICE_COURSE_TOPICS = 175;
	public static SAVE_ICE_TOPICS = 176;
	public static GET_ICE_COURSE_TOPIC_CONTENTS = 177;
	public static SAVE_ICE_TOPICCONTENTS = 179;
	public static DELETE_ICE_COURSE = 180;
	public static GET_UNIQUE_IDENTIFIER_LIST = 181;
	public static ADD_NEW_VIDEO = 182;
	public static REVISION_COMPARE_VIEW = 183;
	public static UPDATE_TAG_CLASS_ATTRIBUTE_TYPE = 184;
	public static RENAME_TOPIC=185;
	public static RENAME_PROCEDURE=186;
	public static RENAME_CONTENT_ITEM=187;
	public static RENAME_IMAGE = 188;
	public static VIEW_TOPIC_CONTENT=189;
	public static VIEW_TOPIC_CONTENT_XML=190;
	public static VIEW_REVISION_CONTENT_XML=191;
	public static CHECK_ICE_COURSE_FILENAME = 192;
	public static CHANGE_TAG_STATUS = 193;
	public static DELETE_TAG = 194;
	public static VIEW_PROCEDURE_CONTENT=195;
	public static VIEW_PROCEDURE_CONTENT_OUTPUT=196;
	public static VIEW_PROCEDURE_REVISION_CONTENT_OUTPUT=197;
	public static GET_TAG_BY_TAG_ID_USED_IN_COMMISSIONING=198;
	public static UPDATE_TAG_CLASS_ATTRIBUTE_VALUE=199;
	public static MOVE_FILES_FROM_FOLDER=200;
	public static GET_TAG_BY_TAG_ID_NEW = 201;
	public static ADD_NEW_THREED_MODEL=202;
	public static CHECK_IN_THREED_MODEL=203;
	public static CHECK_OUT_THREED_MODEL=204;
	public static UNDO_CHECK_OUT_THREED_MODEL=205;
	public static ADD_NEW_PUNCHLIST = 206;
	public static DISABLE_PUNCHLIST = 207;
	public static DELETE_PUNCHLIST = 208;
	public static ADD_NEW_PUNCHLIST_DOCUMENT = 209;
	public static UPDATE_PUNCHLIST = 210;
	public static GET_PUNCH_CODES = 211;
	public static GET_PUNCHLIST_BY_PUNCHLIST_ID = 212;
	public static GET_DISCIPLINES = 213;
	public static GET_ASSETS = 214;
	public static THREED_MODEL_VIEW=215;
	public static DELETE_ICE_GLOSSARY=216;
	public static CHECK_IN_VIDEO=217;
	public static CHECK_OUT_VIDEO=218;
	public static UNDO_CHECK_OUT_VIDEO=219;
	public static UPDATE_DOCUMENT_TYPE = 220;
	public static DELETE_DOCUMENT_TYPE = 221;
	public static GET_SUB_SYSTEMS = 222;
	public static ADD_DOCUMENT_TYPE = 223;
	public static GET_DOC_TYPE_AVAILABILITY = 224;
	public static GET_CM_LEARNINGCHECK_LIST = 225;
	public static COPY_CM_LEARNINGCHECK = 226;
	public static GET_ALL_CHECKEDIN_ICE_COURSES = 227;
	public static GET_ALL_ACTIVE_ICE_COURSES = 228;
	public static GET_ALL_ICE_LEARNINGCHECKS = 229;
	public static CREATE_ICE_GAME=230
	public static SAVE_ICE_GAMES=250
	public static DELETE_ICE_GAME=232
	public static SAVE_ICE_GAME_DATA=233
	public static GET_CM_GAME_LIST = 234;
	public static COPY_CM_GAME = 235;
	public static GET_ICE_LEARNINGCHECKS = 236;
	public static REORDER_ICE_LEARNING_CHECK = 237;
	public static CHECK_ICE_LEARNINGCHECKS_DEPENDENCY = 238;
	public static DELETE_ICE_LEARNING_CHECK = 239;
	public static SAVE_ICE_LEARNING_CHECKDATA = 240;
	public static GET_ICE_LEARNING_CHECKDATA = 241;
	public static SAVE_ICE_LEARNING_CHECKS = 242;
	public static CREATE_LEARNING_CHECK = 243;
	public static GET_ALL_ICE_GLOSSARY_LIST=244;
	public static CREATE_ICE_GLOSSARY=245;
	public static SAVE_ICE_GLOSSARY=246;
	public static GET_ALL_ICE_GAME_LIST=247;
	public static GET_ALL_ICE_GAME_INFO_BY_ID=248;
	public static EXPORT_ICECMCOURSE=249;
	public static VIEW_VIDEO_CONTENT = 250;
	public static RESTORE_VIDEO_REVISION = 251;
	public static DISABLE_DOCUMENT_TYPE = 252;
	public static ADD_NEW_MULTIPLE_IMAGE = 253;
	public static MULTIPLE_THREEDMODEL_CHECKIN = 254;
	public static ADD_NEW_ROLE = 255;
	public static GET_ROLES = 256;
	public static UPDATE_ROLE = 257;
	public static UPDATE_STATUS = 258;
	public static DELETE_ROLE = 259;
	public static GET_ROLE_ASSIGN_USERS = 260;
	public static REMOVE_DOCUMENT_TYPE_ATTRIBUTE = 261;
	public static TOGGLE_DOCUMENT_TYPE_ATTRIBUTE =262;
	public static GET_ROLE_CAPABILITY = 263;
	public static GET_ROLE_CATEGORY = 264;
	public static GET_ICE_COURSE_PREVIEW_DATA = 265;
	public static GET_CLIENTS = 266;
	public static GET_PROCEDURE_PDF_TEMPLATE = 267;
	public static GET_ICE_COURSE_PREVIEW_CONTENT = 268;
	public static FILE_DATA_FOLDER_PATH = 269;
	public static GET_SELECTED_IMAGE_DATA = 270;
	public static REFRESH_TOKEN = 271;
	public static ADD_NEW_IRN = 272;
	public static ADD_NEW_IRN_DOCUMENT = 273;
	public static GET_IRN_BY_IRN_ID = 274;
	public static GET_PUNCHLIST_DATA_TO_IRN = 275;
	public static UPDATE_IRN = 276;
	public static GET_IRN_BY_ASSET_ID = 277;
	public static DELETE_IRN_DOCUMENT = 278;
	public static GET_MCC_DATA_BY_IRN_ID = 279;
	public static DELETE_PL_FROM_IRN = 280;
	public static GET_ALL_VENDORS_FOR_IRN = 281;
	public static FOLDER_MOVE = 282;
	public static GET_CLASSIFICATION_ATTRIBUTE_DEPENDENCIES_WITH_ID = 283;
	public static GET_MCC_TARGETCOMPLETION_DATES = 284;
	public static GET_ALL_SYSTEM_LIST_FOR_CSHFILTER = 285;
	public static UPDATE_MC_DATA = 286;
	public static  GET_CS_PL_COMPLETED = 287;
	public static  MCC_GENERATE = 288;
	public static  REVOKE_MCC = 289;
	public static APPROVE_MC = 290;
	public static ADD_NEW_MC_DOCUMENT = 291;
	public static DELETE_MC_DOCUMENT = 292;
	public static  GET_MC_BY_ID = 293;
	public static GET_AUTH0_CONFIGURATIONS = 294;
	public static GET_DOCUMENT_STORE_PDF_TEMPLATE = 295;
	public static GET_OPERATING_DATA_TYPES = 296;
	public static GET_OPERATING_DATA_LIST = 297;
	public static OPERATING_DATA_NAME_AVAILABILITY = 298;
	public static ADD_OPERATING_DATA = 299;
	public static UPDATE_OPERATING_DATA = 300;
	public static DELETE_OPERATING_DATA = 301;
	public static GET_OPERATING_DATA_DEPENDENCIES = 302;
	public static GET_OPERATING_DATA_DEPENDENCIES_WITH_ID = 303;
	public static GET_DELETED_DOCUMENT_STORE = 304;
	public static GET_OTN_BY_ASSET_ID = 305;
	public static ADD_NEW_OTN = 306;
	public static GET_OTN_TARGETCOMPLETION_DATES = 307;
	public static GET_MCC_DATA_BY_OTN_ID = 308;
	public static GET_OTN_BY_OTN_ID = 309;
	public static UPDATE_OTN = 310;
	public static DELETE_REVOKE_OTN = 311;
	public static APPROVE_OTN = 312;
	public static ADD_NEW_OTN_DOCUMENT = 313;
	public static GET_VENDOR_LIST = 314;
	public static REVOKE_PUNCHLIST = 315;
	public static GET_OTN_DATA_BY_IRN_ID = 316;
	public static ADD_NEW_ADHOC_REPORT = 317;
	public static ADD_WORKFLOW_TEMPLATE = 318;
	public static GET_WORKFLOW_TEMPLATE_BY_ID = 319;
	public static UPDATE_WORKFLOW_TEMPLATE = 320;
	public static DELETE_WORKFLOW_TEMPLATE = 321;
	public static DISABLE_WORKFLOW_TEMPLATE = 322;
	public static ASSIGN_WORKFLOW_TEMPLATE = 323;
	public static ADD_NEW_CHECKSHEET = 324;
	public static GET_CSINSTANCE_COUNT = 325;
	public static GET_CS_DATA_BY_ID = 326;
	public static UPDATE_CHECKSHEET = 327;
	public static GET_CS_CHECK_DATA_BY_ID = 328;
	public static UPDATE_WORKFLOW_TEMPLATE_TASKS = 329;
	public static GET_ACTIVE_ASSETS = 330;
	public static GET_ALL_PHASES = 331;
	public static GET_ASSIGNED_ASSETS = 332;
	public static GET_WORKFLOW_DOCUSIGN_ENTRIES = 333;
	public static GET_PCC_BY_ID = 334;
	public static GET_STN_BY_ID = 335;
	public static GET_CCC_BY_ID = 336;
	public static GET_ACTIVE_SYSTEMS = 337;
	public static GET_ASSIGNED_SYSTEMS = 338;
	public static GET_ITEM_TYPES = 339;
	public static GET_ALL_CHECKSHEETS = 340;
	public static GET_CHECKSHEET_INSTANCE_DATA = 341; 
	public static GET_PUNCHLIST_DATA_TO_CHECKSHEET = 342;
	public static SAVE_CHECKSHEET_INSTANCE_DATA = 343;
	public static ADD_NEW_CS_DOCUMENT = 344;
	public static REMOVE_CS_INSTANCE = 345;
	public static REMOVE_CS_RELATION_FROM_PUNCHLIST = 346;
	public static GET_CSINSTANCE_COUNT_BY_CHECK = 347;
	public static GET_ALL_WORKFLOW_TEMPLATES = 348;
	public static ADD_CHECK = 349;
	public static UPDATE_CHECK = 350;
	public static GET_IRN_TARGETCOMPLETION_DATES = 351;
	public static GET_PCC_TARGETCOMPLETION_DATES = 352;
	public static GET_ACTIVE_USED_IN_COMMISSIONING_SYSTEMS = 353;
	public static ADD_NEW_PCC = 354;
	public static UPDATE_PCC = 355;
	public static GET_PC_BY_ID = 356;
	public static DELETE_PCC = 357;
	public static APPROVE_PCC = 358;
	public static ADD_NEW_PCC_DOCUMENT = 359;
	public static GET_PCC_APPROVAL_DATA = 360;
	public static REVOKE_PCC = 361;
	public static CREATE_ICE_COURSE_IMPORT = 362;
	public static SAVE_DUPLICATE_ICE_COURSE_IMPORT = 363;
}
