<!-- inactive remediation with dependency confirmation model-->
<bs-modal #disableWithDependencyModal style="overflow-y: scroll;" [keyboard]="false">
	<bs-modal-header  [showDismiss]="false" >
		<span class="Rounded" width="500px">{{ modal.title }}</span>
	</bs-modal-header>
	<bs-modal-body>
		<br>
		<div class="col-sm-6">
			
			<table>
				<thead>
					<th>
					</th>
					<th>
					</th>
					<th>
					</th>
				</thead>
				<tbody>
					<tr>
						<td>Folder Name: </td>
						<td width="30px"></td>
						<td>
							<input type="text" name="fname" value={{folderName}} readonly>
						</td>
					</tr>
					<tr>
						<td>Topic Name: </td>
						<td width="30px"></td>
						<td>
							<input type="text" name="tname">
						</td>
					</tr>
					<tr>
						<td>Document Type: </td>
						<td width="30px"></td>
						<td>
							<input type="text" name="dType">
							<button>...</button>
						</td>
					</tr>
					<tr>
						<td>Attributes:</td>
						<td width="30px"></td>
						<td>
							<button>...</button>
						</td>
					</tr>
				</tbody>
			</table>
			<br>
          <table border="1">
			<thead>
				<th>
					Attribute Name
				</th>
				<th>
					Attribute Value
				</th>
			</thead>
		  </table>


		</div>
		<div class="col-sm-6">
			<b>Document Type</b>
		</div>
	</bs-modal-body>
	<bs-modal-footer>
		<button type="button" class="btn btn-default"  (click)="save()">Save</button>
		<button type="button" class="btn btn-primary" (click)="requestModalClose()">Cancel</button>
	</bs-modal-footer>

</bs-modal>

<!-- <modal #deleteCancellationModal style="overflow-y: scroll;" data-backdrop="static " data-keyboard=false>
	<modal-header [show-close]="false ">
		<h4 class="modal-title ">Remove {{modal.title}}</h4>
	</modal-header>
	<modal-body>
		<p> You are not allowed to remove this {{modal.bodyText}} due to following dependencies. </p>
		<ul class="list-group ">
			<li class="list-group-item " [ngClass]="{ 'list-group-item-warning': item.type !='' && item.type !='more' , 'list-group-item-primary' : item.value !='' } "
			 *ngFor="let item of modal.dependency ">
				<div *ngIf="item.type !='' && item.type !='more' ">
					<b>{{item.type}}</b>
				</div>
				<div *ngIf="item.value !=''">
					<p class="break-out">
						<i>{{ item.value }}</i>
					</p>
				</div>
			</li>
		</ul>
	</modal-body>
	<modal-footer [show-default-buttons]="false ">
		<button type="button " class="btn " data-dismiss="modal " (click)="deleteCancellationModal.dismiss() ">OK</button>
	</modal-footer>
</modal>

<modal #copytModal style="overflow-y: scroll;" data-backdrop="static " data-keyboard=false>
	<modal-header [show-close]="false ">
		<h4 class="modal-title ">copy {{modal.title}}</h4>
	</modal-header>
	<modal-body>
		<p> You are not allowed to copy following items due to org code mismatch. </p>
		<ul class="list-group ">
			<li class="list-group-item " [ngClass]="{ 'list-group-item-warning': item.type !='' && item.type !='more' , 'list-group-item-primary' : item.value !='' } "
			 *ngFor="let item of modal.dependency ">
				<div *ngIf="item.type !='' && item.type !='more' ">
					<b>{{item.type}}</b>
				</div>
				<div *ngIf="item.value !=''">
					<p class="break-out">
						<i>{{ item.value }}</i>
					</p>
				</div>
			</li>
		</ul>
	</modal-body>
	<modal-footer [show-default-buttons]="false ">
		<button type="button " class="btn " data-dismiss="modal " (click)="copytModal.dismiss() ">OK</button>
	</modal-footer>
</modal>


<modal #deleteModal data-backdrop="static " data-keyboard=false>
	<modal-header [show-close]="false ">
		<h4 class="modal-title ">Remove {{modal.title}}</h4>
	</modal-header>
	<modal-body>
		<p> Are you sure you want to remove this {{modal.bodyText}}? </p>
	</modal-body>
	<modal-footer [show-default-buttons]="false ">
		<button type="button " class="btn btn-danger " data-dismiss="modal " (click)="deleteModal.dismiss();delete() ">Remove</button>
		<button type="button " class="btn btn-secondary " data-dismiss="modal " (click)="deleteModal.dismiss() ">Cancel</button>
	</modal-footer>
</modal>

<modal #statusChangeModal data-backdrop="static " data-keyboard=false>
	<modal-header [show-close]="false ">
		<h4 class="modal-title ">{{statusTitle}} {{modal.title}}</h4>
	</modal-header>
	<modal-body>
		<p>Are you sure you want to {{statusText}} this {{modal.bodyText}}?</p>
	</modal-body>
	<modal-footer [show-default-buttons]="false ">
		<button *ngIf="modal.status==0 " type="button " class="btn btn-success " data-dismiss="modal " (click)="statusChangeModal.dismiss();statusChanege() ">Enable</button>
		<button *ngIf="modal.status==1 " type="button " class="btn btn-danger " data-dismiss="modal " (click)="statusChangeModal.dismiss();statusChanege() ">Disable</button>
		<button type="button " class="btn btn-secondary " data-dismiss="modal " (click)="statusChangeModal.dismiss() ">Cancel</button>
	</modal-footer>
</modal>


<modal #dependencyModal data-backdrop="static " data-keyboard=false>
	<modal-header [show-close]="false ">
		<h4 class="modal-title ">{{modal.title}} Dependencies</h4>
	</modal-header>
	<modal-body>
		<p>Org code change is restricted due to following dependencies of this {{modal.bodyText}} </p>
		<ul class="list-group ">
			<li class="list-group-item " [ngClass]="{ 'list-group-item-warning': item.type !='' && item.type !='more' , 'list-group-item-primary' : item.value !='' } "
			 *ngFor="let item of modal.dependency ">
				<div *ngIf="item.type !='' && item.type !='more' ">
					<b>{{item.type}}</b>
				</div>
				<div *ngIf="item.value !=''">
					<p class="break-out">
						<i>{{ item.value }}</i>
					</p>
				</div>
			</li>
		</ul>
	</modal-body>
	<modal-footer [show-default-buttons]="false ">
		<button type="button " class="btn " data-dismiss="modal " (click)="dependencyModal.dismiss() ">OK</button>
	</modal-footer>
</modal>

<modal #showHideChangeModal data-backdrop="static " data-keyboard=false>
	<modal-header [show-close]="false ">
		<h4 class="modal-title ">{{statusTitle}} {{modal.title}}</h4>
	</modal-header>
	<modal-body>
		<p>Are you sure you want to {{statusText}} this {{modal.bodyText}}?</p>
	</modal-body>
	<modal-footer [show-default-buttons]="false ">
		<button *ngIf="modal.status==0 " type="button " class="btn btn-success " data-dismiss="modal " (click)="showHideChangeModal.dismiss();showHideChanege() ">Show</button>
		<button *ngIf="modal.status==1 " type="button " class="btn btn-danger " data-dismiss="modal " (click)="showHideChangeModal.dismiss();showHideChanege() ">Hide</button>
		<button type="button " class="btn btn-secondary " data-dismiss="modal " (click)="showHideChangeModal.dismiss() ">Cancel</button>
	</modal-footer>
</modal> -->