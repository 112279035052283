import { Component, OnInit } from '@angular/core';
import { Router } from '../../../../../../node_modules/@angular/router';
import { TranslateService } from '../../../../../../node_modules/@ngx-translate/core';

@Component({
  selector: 'app-password-change-status',
  templateUrl: './password-change-status.component.html',
  styleUrls: ['./password-change-status.component.css']
})
export class PasswordChangeStatusComponent implements OnInit {

  constructor(private router: Router, private translate: TranslateService) { 
    translate.addLangs(["es", "en"]);
		translate.setDefaultLang("en");
		let browserLang = translate.getBrowserLang();
		translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
  }

  ngOnInit() {
  }

  public loginLinkClicked(): void {
    this.router.navigateByUrl('login');
  }

}
