import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-dependency-mod',
  templateUrl: './dependency-mod.component.html',
  styleUrls: ['./dependency-mod.component.css']
})
export class DependencyModComponent implements OnInit {

  public operatingDependency: any;
  public operatingDataDependency: any[];
  public title;

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
    console.log(this.operatingDependency);
    console.log(this.operatingDataDependency);
  }

}
