import { Component, OnInit, ElementRef } from '@angular/core';
import { WsResponse } from '../../util/ws-response.model';
import { WsType } from '../../util/ws-type';
import {AlertService} from 'src/app/util/alert/alert.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { LoadingService } from 'src/app/util/loading/loading.service';
import { WsCallback } from 'src/app/util/ws-callback.interface';
import { DocumentTypeService } from 'src/app/util/documenttypetree/documenttype.service';
import { HttpClient, HttpClientModule, HttpRequest, HttpResponse, HttpEvent} from '@angular/common/http';
import { TemplateRef, ViewChild } from '@angular/core';
import { UserVariable } from '../common/user-variable';
import { FilesService } from 'src/app/home/files/files.service';
import { environment } from 'src/environments/environment';
declare var get3dmodel: any;
import { DomSanitizer } from '@angular/platform-browser';


declare const View:any;
@Component({
  selector: 'app-threed-model-viewer',
  templateUrl: './threed-model-viewer.component.html',
  styleUrls: ['./threed-model-viewer.component.css']
})
export class ThreedModelViewerComponent implements OnInit {
 
  id:string
  itemType:String
  url:string
  isLoadedModel: boolean = false;
  serviceName = "symbiosisservice/";
  serviceVersion = "v1/";
  // public weburl="https://www.google.com";
  fileURL:string

 servicePrefix = environment.server_URL + this.serviceName + this.serviceVersion;
  
  constructor(private http: HttpClient, public bsModalRef: BsModalRef, private modalService: BsModalService, private alert: AlertService,
    private documentTypeService: DocumentTypeService, private loadingService: LoadingService,private filesService: FilesService, private alertService: AlertService,public sanitizer: DomSanitizer) { }

  ngOnInit() {

  this.showThreeDModelView(this.id,UserVariable.projectId);
  }
  onSuccess(data: WsResponse, serviceType: WsType): void {
    
  }

  onFail(data: WsResponse, serviceType: WsType) {
    console.log("view on fail")

    if (serviceType == WsType.DOCUMENT_TYPE_ATTRIBUTES_VIEW) {
      this.alert.error(data.statusDescription, false);
    } else if (serviceType == WsType.ADD_NEW_THREED_MODEL) {
      this.alert.error(data.statusDescription, false);
        this.loadingService.hideLoading();
    }
}


showThreeDModelView(id:string, projectId: string){ 
  console.log("showThreeDModelViiew of " + this.id)
  this.filesService.getThreeDModelURL(id+'.iv3d').subscribe(data => {
    if (data.payload) {
      this.url = data.payload;
      console.log("url " + this.url)
    
    } else {
      //do nothing
    }
      },
    error => {
       this.alertService.error(error.statusDescription)
    });
    this.isLoadedModel = true;
   
     

    }
    
threedmodel(){
  this.fileURL= "../assets/threedmodel/index.htm?fileUrl="+this.url;
  //this.fileURL= "../assets/threedmodel/index.htm?fileUrl=https://samples3bucketrasi.s3.amazonaws.com/models/iv3d/6fa62a3b-e8d9-4796-85ae-3994b3781857.iv3d?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20200206T041038Z&X-Amz-SignedHeaders=host&X-Amz-Expires=604799&X-Amz-Credential=AKIA2J6I27N3TQUPZO7F%2F20200206%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=fb5adef618f5c3886069678fd9d3ec2cf6d269a2e2ddfb3fb56c2cfd8b5d24a9";
  return  this.fileURL;
 }
  
  }

