import { Component, ViewChild, TemplateRef, } from "@angular/core";
import { WsResponse } from '../../util/ws-response.model';
import { WsType } from '../../util/ws-type';
import { AlertService } from '../../util/alert/alert.service';
import { Router } from '@angular/router';
import { LocalStorage } from '../../util/localstorage.service';
import { LoggedUser } from '../../user/logged-user.model';
import { SharedService } from "../../util/shared.service";
import { WsCallback } from "../../util/ws-callback.interface";
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { DependenciesmodalComponent } from '../../util/dependenciesmodal/dependenciesmodal.component';
import { ReusableUpdateService } from './reusableupdateservice';
import { LoadingService } from '../../util/loading/loading.service';
import { Module, AllModules } from "@ag-grid-enterprise/all-modules";

@Component({
	selector: 'app-cell-render',
	templateUrl: './reusableupdate-cell-render-component.component.html'
})
export class ReusableUpdateCellRenderComponent implements WsCallback {
	icon: string;
	type: string;
	documnetType: string = "";
	disable: boolean = false;
	bsModalRef: BsModalRef;
	title: string;
	data: string = "";
	failedReusableList: string = "";
	status: string = "";
	facilityId: string = "";
	moreInfo: string = "";
	details: String = "";
	loadingMessage: string;
	isAction: boolean = false;
	rowData: any = [];
	public modules: Module[] = AllModules;
	public defaultColDef: any;
	columnDefs;
	reusableUpdateId: any = "";
	@ViewChild('revisionTemplate') revisionTemplate: TemplateRef<any>
	@ViewChild('detailTemplate') detailTemplate: TemplateRef<any>

	constructor(private alertService: AlertService, private router: Router, private sharedService: SharedService,
		private modalService: BsModalService, private loadingService: LoadingService, private translate: TranslateService, private reusableUpdateService: ReusableUpdateService) {
		translate.addLangs(["es", "en"]);
		translate.setDefaultLang("en");
		let browserLang = translate.getBrowserLang();
		translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');

		this.translate.get('REUSABLE_UPDATE.LOADING_DETAIL_MESSAGE').subscribe((res: string) => {
			this.loadingMessage = res;
		});

		this.columnDefs = [
			{
				headerName: "Item Type", field: "itemType", autoHeight: true, cellClass: "cell-wrap-text", width: 200, suppressFiltersToolPanel: true,
			},
			{
				headerName: "Topic/Procedure/Ice Course Name", field: 'name', autoHeight: true, cellClass: "cell-wrap-text", width: 230, suppressFiltersToolPanel: true,
			},
			{
				headerName: "Reference Type", field: 'referenceType', autoHeight: true, cellClass: "cell-wrap-text", width: 200,
			},
			{
				headerName: "Referred Reusable Name", field: 'reusableName', autoHeight: true, cellClass: "cell-wrap-text", width: 200, suppressFiltersToolPanel: true,
			},
			{
				headerName: "Status", field: 'statusWord', autoHeight: true, cellClass: "cell-wrap-text", width: 200, suppressFiltersToolPanel: true,
			},
			{
				headerName: "Error Message", field: 'errorMessage', autoHeight: true, cellClass: "cell-wrap-text", width: 200,
			}]
			this.defaultColDef = {
				resizable: true,
				sortable: true,
			}	
	}

	agInit(params: any): void {
		//console.log(params)
		let items = params.value.split(':')

		//  if (items[0] == 'edit') {

		//  }
		if (items.length >= 2) {
			this.data = items[1];

			if (items.length == 3) {
				this.status = items[2];
				this.documnetType = items[2];
				this.reusableUpdateId = items[2];
			}
		}

		this.setType(items[0])
		if(this.type == 'action') {
			this.status = items[2];
			this.documnetType = items[2];
			this.reusableUpdateId = items[2];
			this.failedReusableList = "";
			if (items[3] != "") {
				this.failedReusableList = items[3];
			}
		}
		if (this.type == 'dependencies') {
			this.translate.get('REUSABLE_UPDATE.DEPENDENCIES_LIST').subscribe((res: string) => {
				this.title = res +" " +this.documnetType;
			});
		}
		else if (this.type == 'status') {
			if (this.status == "Inactive") {
				this.translate.get('DOCUMENT_CELL_RENDER.ENABLE_CONFIRM').subscribe((res: string) => {
					this.title = res;
				});
			} else {
				this.translate.get('DOCUMENT_CELL_RENDER.DISABLE_CONFIRM').subscribe((res: string) => {
					this.title = res;
				});
			}
		}
		else if (this.type == 'delete') {
			this.translate.get('DOCUMENT_CELL_RENDER.DELETE_CONFLICTS').subscribe((res: string) => {
				this.title = res;
			});
		}
		else if (this.type == 'edit') {
			//console.log("edit  items[3]" +  items[3])
			// 	localStorage.setItem("selectedFacilityId", items[3])
			// 	console.log(" vbbm " + localStorage.getItem("selectedFacilityId"))
			this.facilityId = items[3];
			localStorage.setItem("selectedFacilityId", null)
			this.translate.get('DOCUMENT_CELL_RENDER.EDIT_CONFIRM').subscribe((res: string) => {
				this.title = res;
			});
		}
		this.sharedService.disableCellRendererExecute().subscribe(option => this.setDisable(option));
	}

	viewDependencies(dflag: boolean) {
		this.dependenciesModalWithComponent(this.title, dflag, true);
	}

	dependenciesModalWithComponent(header: String, dflag: boolean, cflag: boolean) {
		const initialState : object = {
			headerTitle: header,
			delFlag: dflag,
			cancelFlag: cflag,
			checkboxVal: this.status == "Inactive" ? true : false
		};
	
		this.sharedService.setFileId(this.data);
		if (this.documnetType == 'SystemNo') {
			this.documnetType = "System";
		}
		this.sharedService.setFileType(this.documnetType);
		this.sharedService.setParent(this);
		this.bsModalRef = this.modalService.show(DependenciesmodalComponent, { initialState, class: 'modal-lg' });
		//console.log("dflag= " + this.documnetType);
		//console.log("cflag= " + cflag);
	}

	public invoke(type: String, data: any, facilityId: any) {
		switch (type) {
			case 'dependencies':
			case 'status':
			case 'edit':
				localStorage.setItem("selectedFacilityId", facilityId)
				this.viewDependencies(false);
				break;
			case 'delete':
				this.viewDependencies(true);
				break;
			case 'moreInfo':
				///this.loadingService.showLoading(true, null, this.loadingMessage, null)
				this.reusableUpdateService.getReusableErrorMessage(this.documnetType, this.data)
					.subscribe(data => {
						//this.loadingService.hideLoading()
						this.moreInfo = data.payload
						//console.log("this.rowData")
						this.bsModalRef = this.modalService.show(this.revisionTemplate, { class: 'modal-sm' })
						//this.loadData(data.payload);
					},
					error => {
						//this.loadingService.hideLoading()
						this.alertService.clear()
						this.alertService.error(error.statusDescription)
					});
				break;
			case 'details':
				console.log("details");
				this.loadingService.showLoading(true, null, this.loadingMessage, null)
				this.reusableUpdateService.getReusableUpdateData(this.documnetType, this.data)
					.subscribe(data => {
						this.loadingService.hideLoading()
						this.rowData = data.payload
						//console.log("this.rowData")
						this.bsModalRef = this.modalService.show(this.detailTemplate, { class: 'modal-lg' })
						//this.loadData(data.payload);
					},
					error => {
						this.loadingService.hideLoading()
						this.alertService.clear()
						this.alertService.error(error.statusDescription)
					});
			default:
				break;
		}
	}

	reQueue() {
		this.reusableUpdateService.doRequeueReusableUpdate(this.failedReusableList, this.reusableUpdateId, this.data)
			.subscribe(data => {
				//this.this.isAction = false;
				//this.alertService.success(data.payload)
			},
			error => {
				//this.loadingService.hideLoading()
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			});

	}

	onSuccess(data: WsResponse, serviceType: WsType): void {
		
	}

	onFail(res: WsResponse, serviceType: WsType): void {
	
	}

	private setType(params) {
		this.type = params;
		if (params == 'status') {
			if (this.status == "Inactive") {
				this.disable = true;
				this.icon = 'far fa-times-circle';
			}
			else {
				this.icon = 'far fa-check-circle';
			}
		}
		else if (params == "action") {
			this.isAction = true;
		}
		else if (params == "moreInfo") {
			console.log("anti")
			this.icon = 'fas fa-question';
		}
		else if (params == "details") {
			console.log("anti")
			this.icon = 'fa fa-file';
		}
		else {
			this.icon = params == 'dependencies' ? 'far fa-link' :
				params == 'edit' ? "far fa-edit" :
					params == 'delete' ? 'far fa-trash-alt' : "";
		}
	}

	private setDisable(option: any) {
		let items = this.data.split(",")
		if (option != null && option.id == items[0] && this.type == 'status') {
			this.disable = option.data;
			this.icon = this.disable ? 'far fa-times-circle' : 'far fa-check-circle';
			this.status = this.disable ? 'Inactive' : 'Active';
			if (this.status == "Inactive") {
				this.translate.get('DOCUMENT_CELL_RENDER.ENABLE_CONFIRM').subscribe((res: string) => {
					this.title = res;
				});
			} else {
				this.translate.get('DOCUMENT_CELL_RENDER.DISABLE_CONFIRM').subscribe((res: string) => {
					this.title = res;
				});
			}
		}
	}

}  