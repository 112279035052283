import { Component, ViewChild, TemplateRef } from "@angular/core";
import { WsResponse } from '../../util/ws-response.model';
import { WsType } from '../../util/ws-type';
import { AlertService } from '../../util/alert/alert.service';
import { Router } from '@angular/router';
import { SharedService } from "../../util/shared.service";
import { WsCallback } from "../../util/ws-callback.interface";
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from 'src/app/util/loading/loading.service';
import { Constants } from "src/app/util/constants";
import { DependenciesmodalComponent } from '../../util/dependenciesmodal/dependenciesmodal.component';
import {UserVariable} from "../../util/common/user-variable";
import {IrnService} from "./irn.service";
import * as FileSaver from 'file-saver';
@Component({
  selector: 'app-punchlists-cell-render-component',
  templateUrl: './irn-cell-render-component.component.html',
})
export class IrnCellRenderComponent implements WsCallback {
	icon: string;
	type: string;
	disable: boolean = false;
	bsModalRef: BsModalRef;
	title: string;
	dependencyType: string;
	data: string = "";
	status: string = "";
	action: string;
	displayMCCWarn: boolean = false;
	displayPunchlistWarn: boolean = false;
	displayOTNWarn: boolean = false;
	assetsIdStr: string = "";
	public irnId: string = "";
	irnNumber: string = "";
	projectId = UserVariable.projectId;
	@ViewChild('confirmation_warn') confirmationWarn: TemplateRef<any>;
	mccList = []
	otnList = []
	warnModalRef: BsModalRef;

	constructor(private alertService: AlertService, private router: Router, private sharedService: SharedService,
				private bsModalService: BsModalService, private translate: TranslateService, private irnService: IrnService,
				private loadingService: LoadingService) {

		translate.addLangs(["es", "en"]);
		translate.setDefaultLang("en");
		let browserLang = translate.getBrowserLang();
		translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
	}

	agInit(params: any): void {
		let items = params.value.split(':')
		if (items.length >= 2) {
			this.data = items[1];
			if (items.length == 3) {
				this.status = items[2];
			}
		}
		this.setType(items[0], items[1])
		this.assetsIdStr = params.data.assetIds;
		this.irnNumber = params.data.irnNumber;
	}

	public invoke(type: String, irnId: any) {
		this.irnId = irnId
		switch (type) {
			case 'dependencies':
				this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.VIEW;
				localStorage.setItem("isDelete", "false");
				this.viewDependencies(false);
				break;
			case 'doc':
				this.downloadIrn(this.data);
				break;
			case 'edit':
				localStorage.setItem("isDelete", "false");
				this.loadingService.showLoading(true, false, 'Checking MCC Status', 0);
				this.irnService.getMCCListByIRNId(this.data, this);
				break;
			case 'delete':
				localStorage.setItem("isDelete", "true");
				this.loadingService.showLoading(true, false, 'Checking MCC Status', 0);
				this.irnService.getMCCListByIRNId(this.data, this);
				break;
			default:
				break;
		}
	}

	warningConfirm() {
		if (this.type == 'delete') {
			this.viewDependencies(true);
		} else if (this.type == 'edit') {
			this.viewDependencies(false);
		}
	}

	showWarnMsg() {
		if(this.type == 'edit'){
			if (this.displayMCCWarn || this.displayOTNWarn ) {
				this.warnModalRef = this.bsModalService.show(this.confirmationWarn);
			} else {
				this.viewDependencies(false);
			}
		}else if(this.type == 'delete'){
			if (this.displayMCCWarn || this.displayPunchlistWarn || this.displayOTNWarn) {
				this.warnModalRef = this.bsModalService.show(this.confirmationWarn);
			} else {
				this.viewDependencies(true);
			}
		}
	}

	onSuccess(data: WsResponse, serviceType: WsType) {
		if (serviceType == WsType.GET_MCC_DATA_BY_IRN_ID) {
			this.loadingService.hideLoading();
			this.mccList = [];
			if (data.payload != null) {
				if (data.payload.length > 0) {
					this.displayMCCWarn = true;
					data.payload.forEach((mcc)=>{
						this.mccList.push({systemName:" MCC " + mcc.systemNo + " " + mcc.systemName,status: mcc.mcstatus})
					})
				} else {
					this.displayMCCWarn = false;
				}
			}
			let punchlistCount = this.irnService.getPunchlistCount(this.data);
			if (punchlistCount > 0) {
				this.displayPunchlistWarn = true
			} else {
				this.displayPunchlistWarn = false
			}
			this.irnService.getOTNListByIRNId(this.data, this)
			//this.showWarnMsg();
		} else if (serviceType == WsType.GET_OTN_DATA_BY_IRN_ID) {
			this.loadingService.hideLoading();
			this.otnList = [];
			if (data.payload != null) {
				if (data.payload.length > 0) {
					this.displayOTNWarn = true;
					data.payload.forEach((otn)=>{
						this.otnList.push({otnName:" OTN " + otn.tagNo + " " + otn.vendorName, status: otn.otnstatus})
					})
				} else {
					this.displayOTNWarn = false;
				}
			}
			this.showWarnMsg();
		}
	}

	onFail(data: WsResponse, serviceType: WsType) {
		if (serviceType == WsType.GET_MCC_DATA_BY_IRN_ID) {
			this.loadingService.hideLoading();
			this.displayMCCWarn = false;
			this.mccList = [];
			let punchlistCount = this.irnService.getPunchlistCount(this.data);
			if (punchlistCount > 0) {
				this.displayPunchlistWarn = true
			} else {
				this.displayPunchlistWarn = false
			}
			//this.showWarnMsg();
		} else if (serviceType == WsType.GET_OTN_DATA_BY_IRN_ID) {
			this.loadingService.hideLoading();
			this.displayOTNWarn = false;
			this.otnList = [];
			this.showWarnMsg();
		}
	}

	private setType(params, irnId) {
		this.type = params;
		this.irnId = irnId;
		if (this.type == 'dependencies') {
			this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.VIEW;
			this.translate.get('IRN_CELL_RENDER.DEPENDENCIES_LIST').subscribe((res: string) => {
				this.title = res;
			});
			this.icon = 'far fa-link';
		} else if (params == 'doc') {
			if (this.status == "false") {
				this.icon = null;
			} else {
				this.icon = 'fas fa-file-pdf';
			}
		} else if (params == 'disable_dependencies') {
			this.disable = true;
			this.icon = 'far fa-link';
		} else if (this.type == 'delete') {
			this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.DELETE;
			this.translate.get('IRN_CELL_RENDER.DELETE_CONFLICTS').subscribe((res: string) => {
				this.title = res;
			});
			this.icon = 'far fa-trash-alt';
		} else if (this.type == 'edit') {
			this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.EDIT;
			this.translate.get('IRN_CELL_RENDER.EDIT_CONFIRM').subscribe((res: string) => {
				this.title = res;
			});
			this.icon = 'far fa-edit';
		}
	}

	viewDependencies(dflag: boolean) {
		this.dependenciesModalWithComponent(this.title, dflag, true);
	}

	dependenciesModalWithComponent(header: String, dflag: boolean, cflag: boolean) {
		const initialState : object = {
			headerTitle: header,
			delFlag: dflag,
			cancelFlag: cflag,
			checkboxVal: this.status == "Inactive" ? true : false
		};
		this.sharedService.setFileId(this.data);
		this.sharedService.setFileType(Constants.DEPENDENCY_ITEM_TYPE.IRN);
        this.sharedService.setAction("getDependencies");
		this.sharedService.setDependencyMessageType(this.dependencyType);
		this.sharedService.setParent(this);
		this.bsModalRef = this.bsModalService.show(DependenciesmodalComponent, { initialState, class: 'modal-lg' });
	}

	downloadIrn(irnId) {
		this.irnService.downloadFile(irnId, this.projectId, irnId + "irnDoc").subscribe(
			response => {
				this.loadingService.hideLoading()
				let file = new Blob([response], {type: 'application/pdf'});
				FileSaver.saveAs(file, "IRN_" + this.irnNumber);
			},
			error => {
				this.loadingService.hideLoading()
				this.alertService.clear()
				this.alertService.error("Could not download the file.");
			}
		);
	}
}  
