<div class="card-block card-body mt-2 pb-1 pt-1 card-primary">
	<div class="row">
		<div class="col-sm-12 my-2">
				<p class="mb-2 h5">
					<b>{{ 'USER.HEADER' | translate }}</b>
				</p>
			<div class="row px-2">
				<div class="form-group col">
				</div>
				<!--
					<div class="col-sm-4 px-0">
						<button type="submit" class="btn btn-primary btn-sm reusable_btn my-1"
							(click)="add()" id="client_add">
							<i class="fas fa-plus"></i>
						</button>
					</div>
				-->
				
				<!-- <div class="form-group col">
		<label>{{ 'REUSABLE_UPDATE.END_DATE' | translate }} :</label>
		<input type="text" class="form-control" bsDatepicker (bsValueChange)="onEndValueChange($event)" [bsValue]="bsEndValue" [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY', containerClass: 'theme-default' }"
		 id="endDate">
	</div> -->
			</div>
			<div class="row px-2">
				<div class="form-group col">
					<ng-select [allowClear]="true" [items]="clientList" [active]="clientItems" name="itemType" id="itemType" (selected)=clientSelected($event)>
					</ng-select>
				</div>
			</div>
		</div>
	</div>

	<ag-grid-angular #agGrid style="width: 100%; height: 570px;" class="ag-theme-balham" 
	[enableFilter]="true" [rowData]="rowData" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }" [columnDefs]="columnDefs" rowSelection="multiple" 
	[rowMultiSelectWithClick]="false"   [getRowNodeId]="getRowNodeId" (gridReady)="onGridReady($event)" [pagination]="true"
	[paginationPageSize]="12" [overlayLoadingTemplate]="overlayLoadingTemplate" [defaultColDef]="defaultColDef"
	[overlayNoRowsTemplate]="overlayNoRowsTemplate">
  </ag-grid-angular>

	<!-- <ag-grid-angular #agGrid style="width: 100%; height: 400px;" id="procedureStore" [rowData]="rowData"  [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }" class="ag-theme-balham"
	 [columnDefs]="columnDefs" [frameworkComponents]="frameworkComponents"   [enableRangeSelection]="true"
	 [paginationPageSize]="100" [pagination]="true" (columnResized)="onColumnResized($event)" (gridReady)="onGridReady($event)"
	 [suppressMenuHide]="true" [gridOptions]="gridOptions">
	</ag-grid-angular> -->
</div>