export class ProjectWorflowTemplateMap{
    constructor(
        public id: string = null,
        public projectId: string = null,
        public templateId: string = null,
        public configParameters: string = null,
        public tasks: Array<WorkflowTemplateTskRoleMap> = []
    ){}
}

export class WorkflowTemplateTskRoleMap{
    constructor(
        public id: string,
        public templateId: string,
        public projectId: string,
        public processName: string,
        public taskName: string,
        public automatic: string,
        public role: string,
        public userId: string,
        public orderNo: number
    ){}
}