<!-- <div class="container">
	<div *ngIf="categoryType == 'state_category' || categoryType == 'comp_category'">
		<input type="checkbox" [(ngModel)]="theCheckbox" data-md-icheck (change)=checkBoxEvent($event)>Include Children
	</div>
	<div id="categorytree" class="mt-2 ml-1"></div>
</div> -->

<div class="modal-body py-2">
	<div *ngIf="((categoryType == 'state_category' || categoryType == 'comp_category' || categoryType == 'chk_category') && (showIncludeChildren == true))">
		<input type="checkbox" [(ngModel)]="theCheckbox" data-md-icheck (change)=checkBoxEvent($event)>Include Children
	</div>
	<div id="categorytree" class="mt-2 ml-1"></div>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-primary btn-sm" (click)="selectCategory()">
		Select
	</button>
	<button type="button" class="btn btn-secondary btn-sm" (click)="closeButtonClickEvent()">
		Close
	</button>
</div>