import { Injectable } from '@angular/core';
import { ReusableupdateComponent } from '../reusableupdate.component';

@Injectable({
	providedIn: 'root'
})
export class RuLoaderService {

	constructor() { }

	public loadComponentByName(compName: string) {
		switch (compName) {
			case 'reusableupdate':
				return ReusableupdateComponent
			default:
				break;
		}
	}
}
