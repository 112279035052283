import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { UserVariable } from '../../util/common/user-variable';
import { ServiceUrls } from '../../util/service-urls';
import { WsResponse } from '../../util/ws-response.model';
import { WsCallback } from '../../util/ws-callback.interface';
import { WsType } from '../../util/ws-type'
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators'
import { SharedService } from '../../util/shared.service';
import { LocalStorage } from '../../util/localstorage.service';
import { CommonUtil } from 'src/app/util/common/common-util';

@Injectable({
	providedIn: 'root'
})
export class DocumentsService {
	public token: string;
	private rowData: any = null;
	private apiGridData: any = null;
	private eventValue: any = null;
    private callback: WsCallback;
	public projectId: string = UserVariable.projectId; //localStorage.getItem(LocalStorage.PROJECT_ID)

	constructor(private http: HttpClient, private sharedService: SharedService, private commonUtil: CommonUtil) { }

	public setCallback(callBack: WsCallback) {
		this.callback = callBack;
	}

	searchDocuments(documentName: String, documentType: string, facilityName: String, revision: string, altDocNo: string, fileName: string, hasText: string) {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
		let url = this.getdocumentsByFacilityNameSearch(token);
		return this.http.post(url, JSON.stringify(new Request(documentName, facilityName, documentType, revision,altDocNo, fileName, hasText))).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.name,
					modified.status.code,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error));
				var res = new WsResponse(
					modified.error.status.description,
					modified.error.status.name,
					modified.error.status.code,
					modified.error.payload);
				return throwError(res)
			})
			);
	}

	public getdocumentsByFacilityNameSearch(token: String) {
		let url = ServiceUrls.DOCUMENTSS_CATEGORY_VIEW + '?token=' + token;
		return url;
	}

	getFacilityList() {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
		const options = { params: new HttpParams().set("token", token) }
		return this.http.get(ServiceUrls.GET_FACILITY_LIST, options).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.code,
					modified.status.name,
					modified.status.description,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.code,
					modified.status.name,
					modified.status.description,
					modified.payload);
				return throwError(res)
			})
			);
	}

   addDocument(documentName: String, altDocNo: string,  facilityId: String, 
      documentTypeId: String, revision: String, contentItemId: String) {
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
		let url = this.addDocumentURL(token);
		// console.log("url...." + url);
      return this.http.post(url, JSON.stringify(new AddRequest(documentName, altDocNo, facilityId, 
         documentTypeId, revision, contentItemId))).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.name,
					modified.status.code,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error));
				var res = new WsResponse(
					modified.error.status.description,
					modified.error.status.name,
					modified.error.status.code,
					modified.error.payload);
				return throwError(res)
			})
			);
	}

	public addDocumentURL(token: String) {
		let url = ServiceUrls.ADD_DOCUMENT + '?token=' + token;
		return url;
	}

	getDocumentDataByDocumentId(id: String) {
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
		let url = ServiceUrls.DOCUMENT_EDIT + id + '/getDocumentByDocumentId?token=' + token;
		return this.http.post(url, JSON.stringify(new EditRequest(id))).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.name,
					modified.status.code,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error));
				var res = new WsResponse(
					modified.error.status.description,
					modified.error.status.name,
					modified.error.status.code,
					modified.error.payload);
				return throwError(res)
			})
			);
	}

	updateDocument(id: String, documentName: String, altDocNo: string, facilityId: String, documentTypeId: String, revision: String, contentItemId: String) {
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
		// let url = ServiceUrls.UPDATE_SYSTEM + '?token=' + token;
		let url = ServiceUrls.UPDATE_DOCUMENT + id + '/updateDocument.json?token=' + token;
		return this.http.put(url, JSON.stringify(new UpdateRequest(id, documentName, altDocNo, facilityId, documentTypeId, revision, contentItemId))).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.name,
					modified.status.code,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error));
				var res = new WsResponse(
					modified.error.status.description,
					modified.error.status.name,
					modified.error.status.code,
					modified.error.payload);
				return throwError(res)
			})
			);
	}

    /**
    * Define URL for delete document.
    */
	deleteDocument(id: any, callBack: WsCallback) {
		console.log("delete1");
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
		let url = ServiceUrls.DOCUMENTS + "/" + id + '.json'
		const options = { params: new HttpParams().set("token", token) }
		this.http.delete(url, options).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				callBack.onSuccess(res, WsType.DELETE_ITEM);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					callBack.onFail(res, WsType.DELETE_ITEM);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					callBack.onFail(res, WsType.DELETE_ITEM);
				}
			}
		);
	}

   public setItemData(id: string,  documentName: string, altDocNo: string, facilityId: string, 
      documentTypeId: string, revision: string, contentItemId: string, editContentItemName: string) {         
		if (this.apiGridData != null && this.apiGridData != undefined && id != null) {
            var rowNode = this.apiGridData.getRowNode(id);
			if (rowNode != null && rowNode != undefined) {
            if(contentItemId == undefined){
               contentItemId = ""
            }
            rowNode.setDataValue("documentName", documentName);
            rowNode.setDataValue("altDocNo", altDocNo);
            rowNode.setDataValue("facilityName", facilityId);
            rowNode.setDataValue("documentType", documentTypeId);
            rowNode.setDataValue("revision", revision);
            rowNode.setDataValue("contentItemId",contentItemId);
            rowNode.setDataValue("pdfFile",editContentItemName);
            rowNode.setDataValue("projectId",this.projectId);
			}
		}
   }
   
   public createItemData(id: string, documentName: string, altDocNo: string, facilityId: string, 
      documentTypeId: string, revision: string, contentItemId: string,addContentItemName: string,
      selectedFacilityIdValue: string){

         if(contentItemId == undefined){
            contentItemId = ""
         }
        
         var newData = {
            id: id,
            documentName: documentName,
            altDocNo: altDocNo,
            facilityName: facilityId,
            documentType: documentTypeId,
            revision: revision,
            contentItemId: contentItemId,
            pdfFile: addContentItemName,
            projectId: this.projectId,
            dependencies:  "disable_dependencies:" + id,
            status: "status:" + id + ":" + "Active",
            edit: "edit:" + id + ":" + "Active" + ":" + selectedFacilityIdValue,
            delete: "delete:" + id + ":" + "Active"
      }
      var res = this.apiGridData.applyTransaction({ add: [newData] });
      
   }

	public disableEnableFileTableData(fileId: any, title: String, status: String) {
		// console.log("hhh" + status + title + fileId)
		if (this.apiGridData != null && this.apiGridData != undefined && fileId != null) {
			var rowNode = this.apiGridData.getRowNode(fileId);
			if (rowNode != null && rowNode != undefined) {
				if (title == 'Disable Confirm') {
					this.sharedService.disableCellRendererRequest(true, fileId);
					rowNode.setDataValue("status", "status:" + fileId + ":Inactive");
					rowNode.setDataValue("delete", "delete:" + fileId + ":Inactive");
				} else if (title == 'Enable Confirm') {
					this.sharedService.disableCellRendererRequest(false, fileId);
					rowNode.setDataValue("status", "status:" + fileId + ":Active");
					rowNode.setDataValue("delete", "delete:" + fileId + ":Active");
				}
				else {
					if (status == "Inactive") {
						this.sharedService.disableCellRendererRequest(false, fileId);
						rowNode.setDataValue("status", "status:" + fileId + ":Active");
						rowNode.setDataValue("delete", "delete:" + fileId + ":Active");
					}
					else {
						this.sharedService.disableCellRendererRequest(true, fileId);
						rowNode.setDataValue("status", "status:" + fileId + ":Inactive");
						rowNode.setDataValue("delete", "delete:" + fileId + ":Inactive");
					}
				}
			}
		}
	}

	public deleteFileTableData(fileId: any) {
		if (this.apiGridData != null && this.apiGridData != undefined && fileId != null) {
			var selectedData = this.apiGridData.getSelectedRows();
			if (selectedData != null && selectedData != undefined) {
				this.apiGridData.applyTransaction({ remove: selectedData });
				let newRowData:any = [];
				this.apiGridData.forEachNode( function(rowNode, index) {
					newRowData.push(rowNode.data);
				});
				this.setRowData(newRowData);
			}
		}
   }
   
   getPDFFileAndFolders() {
      let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
		const options = { params: new HttpParams().set("token", token) }      
      return this.http.get(ServiceUrls.GET_PDF_FILE_AND_FOLDERS, options).
         pipe(
            map(response => {
               var modified = JSON.parse(JSON.stringify(response));
               return new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
            }),
            catchError(error => {
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
               return throwError(res)
            })
         );
   }

   getSelectedFolderData(folderId: string) {
      let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
      const options = { params: new HttpParams().set("token", token) } 
      let url = this.getFolderContentByFolderIdURL(folderId)    
      return this.http.get(url, options).
         pipe(
            map(response => {
               var modified = JSON.parse(JSON.stringify(response));
               return new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
            }),
            catchError(error => {
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
               return throwError(res)
            })
         );
   }

   getSelectedFolderPDFAndFolders(folderId: string) {
		let token = UserVariable.getUserToken();
		if(!this.commonUtil.validateToken(token)){
			return;
		}
		const options = { params: new HttpParams().set("token", token) }
		return this.http.get(this.getSelectedFolderPDFAndFoldersURL(folderId), options).
		pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
		);
	}
	 
	public getSelectedFolderPDFAndFoldersURL(folderId: string) {
		let url = ServiceUrls.FILE_BY_FOLDER_ID_VIEW_URL + folderId + '/getPDFFilesAndFoldersByFolderId.json'
		return url;
	}

   public getFolderContentByFolderIdURL(folderId: string) {
		let url = ServiceUrls.FILE_BY_FOLDER_ID_VIEW_URL + folderId + '/files.json'
		return url;
	}

	public setApiGridData(apiGridData: any) {
		this.apiGridData = apiGridData;
	}

	public getApiGridData() {
		return this.apiGridData;
	}

	public setRowData(rowData: any) {
		this.rowData = rowData;
	}

	public getRowData() {
		return this.rowData;
   }
   
   downloadFile(projectId: string, attachedFileName: string) {
      return this.http.get(ServiceUrls.DOWNLOAD_FILE, {
         responseType: "blob", params: new HttpParams().
            set("fileName", attachedFileName).set("mode", "ViewPDF").set("projectId", projectId)
      });
   }

}
class Request {
	constructor(private documentName: String, private facilityName: String, private documentType: String, private revision: String, private altDocNo: String, private attachedFileName: String, private hasText: String) { }
}

class AddRequest {
	constructor(private documentName: String, private altDocNo, private facilityId: String, private documentTypeId: String, private revision: String, private contentItemId: String) { }
}

class EditRequest {
	constructor(private id: String) { }
}

class UpdateRequest {
	constructor(private id: String, private documentName: String, private altDocNo: string, private facilityId: String, private documentTypeId: String, private revision: String, private contentItemId: String) { }
}