import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams, IAfterGuiAttachedParams } from "@ag-grid-enterprise/all-modules";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SharedService } from 'src/app/util/shared.service';
import { DependenciesmodalComponent } from 'src/app/util/dependenciesmodal/dependenciesmodal.component';
import { DependencyModalComponent } from '../tag-classification/dependency-modal/dependency-modal.component';
import { UserVariable } from 'src/app/util/common/user-variable';
import { TagsService } from '../tags.service';
import { WsCallback } from 'src/app/util/ws-callback.interface';
import { AlertService } from 'src/app/util/alert/alert.service';
import { WsResponse } from 'src/app/util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';
import { CommonUtil } from 'src/app/util/common/common-util';

@Component({
  selector: 'app-dependency-cell-render',
  template: `
    <div class="col-sm">
    <button type="button" [disabled]="disableDependencyButton()" class="btn btn-sm btn-outline-dark py-0 my-1 px-1 border-0 " (click)="showDependency()">
      <i class="far fa-link" aria-hidden="true"></i>
    </button>
  </div>
  `,
  styles: []
})
export class DependencyCellRenderComponent implements OnInit, ICellRendererAngularComp, WsCallback {
  public cellData: any;
  public bsModalRef: BsModalRef;

  refresh(params: any): boolean {
    return true;
  }
  agInit(params: ICellRendererParams): void {
    this.cellData = params.data;
  }
  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {

  }

  constructor(private bsModalService: BsModalService, private sharedService: SharedService,
    private modalService: BsModalService, private tagService: TagsService, private alertService: AlertService, private commonUtil: CommonUtil) { }

  ngOnInit() {
  }

  /**
   * Enable/disable dependency button.
   */
  public disableDependencyButton() {
		if (this.cellData.count > 0) {
      return false
    } else {
      return true
    }
  }
   
  /**
   * Request dependencies.
   */
  showDependency() {
    let token = UserVariable.getUserToken();
    if(!this.commonUtil.validateToken(token)){
        return;
    }
    var request: any = {
      "itemId": this.cellData.name,
      "itemType": 'EquipmentField',
      "action": 'delete',
      "userToken": token
    };
    this.tagService.getTagDependenciesByTag(JSON.stringify(request), this);
  }

  /**
   * Shows list of dependencies in a popup
   * @param tagList dependency list
   */
  dependenciesModalWithComponent(tagList: any[]) {
    const initialState = {
      tagDependency: tagList,
      title: 'Dependencies List'
    };
    this.bsModalRef = this.modalService.show(DependencyModalComponent, {initialState});
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  onSuccess(data: WsResponse, serviceType: WsType) {
    if (serviceType == WsType.GET_TAG_DEPENDENCIES_BY_TAG) {
      var payload = JSON.parse(data.payload);
      if (payload.HasDependencies == 0) {
        this.alertService.error('There are no dependencies');
      } else {
        var string_ = JSON.stringify(payload.TagDependency);
        var json_ = JSON.parse(string_);
        this.dependenciesModalWithComponent(json_);
      }
    }
  }

  onFail(data: WsResponse, serviceType: WsType) {
    if (serviceType == WsType.GET_TAG_DEPENDENCIES_BY_TAG) {
      this.alertService.error(data.statusDescription, false);
    }
  }
}
