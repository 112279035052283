import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorage } from '../../../util/localstorage.service';
import { TranslateService } from '@ngx-translate/core';
import { UserVariable } from '../../../util/common/user-variable';
import { AlertService } from '../../../util/alert/alert.service';
import { SharedService } from '../../../util/shared.service';
import { GridOptions, Module, AllModules } from "@ag-grid-enterprise/all-modules";
import { ProcedureStoreService } from '../procedurestore.service';
import { CapabilityService } from '../../../util/capability.service'
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PSDeleteButtonRender} from '../../procedurestore/cell-render/delete-button/delete-button.component'
import { LoadingService } from '../../../util/loading/loading.service';

@Component({
   selector: 'app-procedurestoredeleted',
   templateUrl: './deletedprocedurestore.component.html',
   styleUrls: ['./deletedprocedurestore.component.css']
})

export class DeletedProcedureStoreComponent implements OnInit {
   public isMinimized: boolean = false
   rowData: any[] = [];
   private gridApi;
   private gridColumnApi;
   columnDefs;  
   ps_translated_data: any = null;
   public frameworkComponents;
   public gridOptions: GridOptions;
   public defaultColDef: any;
   private projectId: string = UserVariable.projectId
   public modules: Module[] = AllModules;

   constructor(private procedureStoreService: ProcedureStoreService, private sharedService: SharedService, private translate: TranslateService, private alertService: AlertService,
      private capabilityService: CapabilityService, private modalService: BsModalService, private loadingService: LoadingService) {

      let browserLang = translate.getBrowserLang();
      this.gridOptions = <GridOptions>{
         context: {
            componentParent: this
         }
      };
      this.defaultColDef = {
			resizable: true,
			sortable: true,
		};
      translate.getTranslation(browserLang).subscribe((res: string) => {
         this.ps_translated_data = res['PROCEDURE_STORE']
         this.columnDefs = [
           
            {
               headerName: this.ps_translated_data.PROCEDURE, field: 'name', autoHeight: true, cellClass: "cell-wrap-text"
            },
            {
               headerName: this.ps_translated_data.SYSTEM, field: 'systemName', autoHeight: true, cellClass: "cell-wrap-text"
            },
            {
               headerName: this.ps_translated_data.VERSION, field: 'storeVersion', autoHeight: true, cellClass: "cell-wrap-text"
            },
            {
               headerName: this.ps_translated_data.NOTES, field: 'content', autoHeight: true, cellClass: "cell-wrap-text"
            },
            {
               headerName: this.ps_translated_data.PUBLISH_TIME, field: 'lastUpdatedTime', autoHeight: true, cellClass: "cell-wrap-text", comparator: this.dateTimeComparator
            },
            {
               headerName: this.ps_translated_data.FOLDER_PATH, field: 'folderPath', autoHeight: true, cellClass: "cell-wrap-text"
            },
            {
               headerName: this.ps_translated_data.KEY_WORDS, field: 'keywords', autoHeight: true, cellClass: "cell-wrap-text"
            },
         ]
      });
   }

   dateTimeComparator(date1:any, date2:any) {
      let parts = date1.match(/(\d{2})-(\d{2})-(\d{4}) (\d{2}):(\d{2}):(\d{2})/);
      let date1Number = Date.UTC(+parts[3], parts[1]-1, +parts[2], +parts[4], +parts[5], +parts[6]);
      parts = date2.match(/(\d{2})-(\d{2})-(\d{4}) (\d{2}):(\d{2}):(\d{2})/);
      let date2Number = Date.UTC(+parts[3], parts[1]-1, +parts[2], +parts[4], +parts[5], +parts[6]);
    
      if (date1Number === null && date2Number === null) {
        return 0;
      }
      if (date1Number === null) {
        return -1;   
      }
      if (date2Number === null) {
        return 1;
      }
    
      return date1Number - date2Number;
    }

   ngOnInit() {
   }

   onColumnResized(event) {
      if (event.finished) {
         this.gridApi.resetRowHeights();
      }
   }

   onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      params.api.sizeColumnsToFit();
      this.getDeletedProcedureStoreViewData();
      setTimeout(function () {
         params.api.sizeColumnsToFit();
         params.api.resetRowHeights();
      }, 500);
   }

   getDeletedProcedureStoreViewData(){
      this.loadingService.showLoading(true, null, "", null)
      this.rowData = [];
      this.procedureStoreService.getProcedureStoreDeletedItems()
         .subscribe(data => {
            this.loadingService.hideLoading()
            this.rowData = data.payload;
         },
         error => {
            this.loadingService.hideLoading()
            this.alertService.clear()
            this.alertService.error(error.statusDescription)
         });
   }

}