import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService } from 'src/app/util/alert/alert.service';
import { WsResponse } from 'src/app/util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';
import { TagsService } from '../tags.service';
import { ConfirmationCallback } from './confirmation-callback';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styles: []
})
export class ConfirmationModalComponent implements OnInit {
  public token;
  public attributeId;
  public confirmationTitle: string;
  public confirmationMessage: string;
  public callBack: ConfirmationCallback;

  ngOnInit(): void {
  }

  constructor(public bsModalRef: BsModalRef, private alertService: AlertService, private tagService: TagsService) { }

  confirm(): void {
    this.callBack.onDeleteConfirm(this.attributeId);
  }

  decline(): void {
    this.callBack.onDeleteDecline();
  }
}
