<div class="modal-header py-2">
    <h5 *ngIf="!isEdit" class="modal-title">{{'ADD_EDIT_CHECKS.ADD_TITLE' | translate}}</h5>
    <h5 *ngIf="isEdit" class="modal-title">{{'ADD_EDIT_CHECKS.EDIT_TITLE' | translate}}</h5>
    <button id="chk_cancel" type="button" class="close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <form>
        <fieldset>
            <div class="form-group mb-2">
                <div class="mb-1">
                    <label>{{'ADD_EDIT_CHECKS.CHECK_CATEGORY' | translate}}</label>
                    <div class="badge-required mx-2"></div>
                     <input class="form-control form-control-sm" [id]="categoryId" readonly
                     (click)="showCategoryList(allCategoryModel)" [value]="selectedCategoryItem.catName">
                </div>
                <div class="mt-4 dropdown-100">
                    <label>{{'ADD_EDIT_CHECKS.TYPE_OF_CHECK' | translate}}</label>
                    <div class="badge-required mx-2"></div>
                    <ng-select placeholder="{{'ADD_EDIT_CHECKS.SELECT_CHECK_TYPE' | translate}}" [items]="checkTypes" (selected)="selectCheckType($event)" 
                    (removed)="removeCheckType($event)" [active]="selectedCheckType" [allowClear]="true"></ng-select>
                </div>
                <button tooltip="{{ 'WEB_EDITOR.ADD_FRACTION' | translate }}" id="fractionchecks_clicked" container="body"
                type="button" hidden="true" (click)="openModal(fraction)" class="btn btn-outline-primary btn-sm ml-1 mr-1">
                <i class="fas fa-divide" aria-hidden="true"></i>
            </button>
                <div class="mt-4 dropdown-100">
                    <label>{{'ADD_EDIT_CHECKS.DESCRIPTION' | translate}}</label>
                    <div class="badge-required mx-2"></div>
                    <div class="col-sm-12 px-0">
                            <ckeditor [config]="configData"
                            #editor
                            [(ngModel)]="checkDescription"
                            id="description"
                            name="description"
                            type="divarea">
                            </ckeditor>
                        <!--<app-ckeditor [configData]="configData"
                        #editor
                        [(ngModel)]="checkDescription"
                        id="description1"
                        name="description"
                        type="divarea"
                        (change)="textChange($event)">
                        </app-ckeditor>-->
                    </div>
                </div>
            </div>
        </fieldset>
    </form>
</div>

<div class="modal-footer">
    <button id="chk_cancel" type="button" class="btn btn-secondary btn-sm float-left" (click)="cancel()">{{'ADD_EDIT_CHECKS.CANCEL' | translate}}</button>
    <button *ngIf="!isEdit" id="chk_save" type="submit" class="btn btn-primary btn-sm float-left" (click)="addCheck()">{{'ADD_EDIT_CHECKS.ADD' | translate}}</button>
    <button *ngIf="isEdit" id="chk_update" type="submit" class="btn btn-primary btn-sm float-left" (click)="updateCheck()">{{'ADD_EDIT_CHECKS.SAVE' | translate}}</button>
</div>

<!--Model to be passed-->
<ng-template #allCategoryModel>
        <div class="modal-header">
            <h4 class="modal-title pull-left">Select a Category</h4>
            <button type="button" id="image_close" class="close pull-right" aria-label="Close"
                (click)="closeButtonClickEvent()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <app-select-category-tree (categorySelected)="selectedCategory($event)" [categoryType]="categoryType"
        [selectedCategoryItem]="tempCategoryItem" [showIncludeChildren]=false (closeModal)="closeButtonClickEvent()">
        </app-select-category-tree>
    </ng-template>

<ng-template #fraction>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{ 'WEB_EDITOR.FRACTION' | translate}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRefFraction.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid">
            <div class="row">
                <div class="input-group input-group-sm ">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Numerator / Denominator</span>
                    </div>
                    <input (keypress)="numberOnly($event)" #dec_value type="text" aria-label="Numerator"
                        class="form-control" [(ngModel)]="numerator">
                    <input (keypress)="numberOnly($event)" #dec_valueD type="text" aria-label="Denominator"
                        class="form-control" [(ngModel)]="denominator">
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer p-2">
        <div class="col-sm-12 px-0">
            <div class="badge float-left"
                *ngIf="numerator != '' && denominator != '' && numerator != null && denominator != null">
                <span class="h6 m-0">{{numerator}} / {{denominator}}</span>
            </div>
            <button type="button" class="btn btn-secondary btn-sm float-right mx-1" (click)="modalRefFraction.hide()">{{ 'CANCEL' |
                                translate}}</button>
            <button type="button" class="btn btn-primary btn-sm float-right mx-1"
                (click)="modalRefFraction.hide();addSpeCharfraction(dec_value.value,dec_valueD.value);">{{
                                'ADD' | translate}}</button>
        </div>
    </div>
</ng-template>