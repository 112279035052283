<div class="navbar navbar-light">
	<div class="navbar-brand">
		<span *ngIf="showHamburger" class="slide-bar-span d-inline-block align-top mr-2" (click)="showSlideBar()">
			<i class="fas fa-bars fa-fw slide-bar-i" aria-hidden="true"></i>
		</span>
		<img src="../../assets/images/mainimage.png" width="auto" height="30" class="d-inline-block align-top ml-1 mr-1"
			alt="">
		<div class="d-inline-block align-top vertical-line ml-1 mr-1"></div>
		<h5 *ngIf="showProjectName" class="d-inline-block align-top ml-1 mr-1">{{projectName}}</h5>
	</div>
	<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
		aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
		(click)="showToggleMenu()">
		<i class="fa fa-ellipsis-v"></i>
	</button>
	<div class="collapse navbar-collapse" id="navbarSupportedContent" [ngClass]="{'toggle-border-top': isToggleMenu}" (click)="consoleEvent()">
		<ul class="navbar-nav mr-auto">
		</ul>
		<div class="form-inline my-2 my-lg-0 float-right">
			<button *ngIf="!minimalOption" class="btn notification-btn btn-sm my-2 my-sm-0 mr-1 ml-2 border-0"
				(click)="isShowSearch = !isShowSearch">
				<i class="fal fa-search fa-2x"></i>
			</button>
			<div class="my-2 my-sm-0 mr-1 ml-2">
				<button *ngIf="!minimalOption" [disabled]='!comProjectAvailable' class="btn notification-btn btn-sm my-2 my-sm-0 mr-1 ml-2 border-0" 
				(click)="viewV2Com()" tooltip='V2 Commissioning' data-placement="bottom">
					<i class="fal fa-file-certificate fa-2x"></i>
				</button>
			</div>
			<div class="my-2 my-sm-0 mr-1 ml-2">
				<span class="btn notification-btn btn-sm my-2 my-sm-0 mr-1 ml-2 border-0"
					(click)="menuContentPO.toggle()">
					<!-- animated swing [ngClass]="{'infinite': notificationCount > 0}" -->
					<img class="mx-auto" height="30" width="auto" src="../../assets/icons/menu_icon.svg" alt=""
						[popover]="menuContent" containerClass="menu-custom" placement="bottom"
						#menuContentPO="bs-popover" container="body" [outsideClick]="true" triggers="" (click)="onClickMenuContent()">
				</span>
			</div>
			<div class="my-2 my-sm-0 mr-1 ml-2">
				<span class="btn notification-btn btn-sm my-2 my-sm-0 mr-1 ml-2 border-0"
					(click)="showNotification(); isOpen = !isOpen" [popover]="notificationContent" [isOpen]="isOpen"
					containerClass="notification-custom" placement="bottom" #notificationPO="bs-popover"
					container="body" [outsideClick]="true" triggers="">
					<!-- animated swing [ngClass]="{'infinite': notificationCount > 0}" -->
					<i class="fal fa-bell fa-lg" aria-hidden="true"></i>
					<span class="badge badge-danger badge-pill badge-notify animate__animated animate__faster" [ngClass]="{'animate__fadeIn': notificationCount>0, 'hidden': notificationCount==0 }">{{notificationCount}}</span>
				</span>
			</div>
			<button class="btn btn-outline-dark btn-sm btn-circle my-2 my-sm-0 mr-1 ml-2"
				(click)="userSettingsPO.toggle()">
				<span [popover]="userSettingsContent" containerClass="user-setting-custom" placement="bottom"
					#userSettingsPO="bs-popover" triggers="" container="body" [outsideClick]="true">{{loggedInUser.firstName | slice:0:1}}{{loggedInUser.lastName |
					slice:0:1}}</span>
			</button>
		</div>
	</div>
</div>

<div *ngIf="isShowSearch" class="floating-search">
	<symbiosis-search [comp]="'header'" id="header_search" (searchFunc)="searchFunc($event)"
		(searchBarEnterFunc)="searchBarEnterFunc($event)"></symbiosis-search>
</div>

<ng-template #notificationContent>
	<app-notification (messageEvent)="getNotificationCount()"></app-notification>
</ng-template>

<ng-template #userSettingsContent>
	<div class="card col-sm-12 rounded-0 p-0 border-top-0 border-left-0 border-right-0 mt-2 mb-1">
		<div class="card-body row p-0">
			<div class="float-left mx-3">
				<span class="btn btn-outline-dark btn-circle btn-sm float-left mt-1">{{loggedInUser.firstName |
					slice:0:1}}{{loggedInUser.lastName | slice:0:1}}</span>
			</div>
			<div class="float-left col">
				<div class="row">
					<div class="col-sm-12 pl-0">
						<p class="float-left mb-0"> {{userFullName}}</p>
					</div>
					<div (click)="userSettingsPO.toggle()">
						<div class="btn col-sm-12 pl-0" (click)="openViewProfileModal(userProfilerTemplate, 'lg')">
							<small class="float-left mb-2 text-muted view-profile">View Profile</small>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="!minimalOption" class="row my-1 mx-0 py-1 px-3 user-settings-item" (click)="changeProject()">
		<div class="float-left mx-2 text-muted">
			<i class="fa fa-tasks" aria-hidden="true"></i>
		</div>
		<div class="float-left mx-2">
			<p class="m-0 ">{{ 'HEADER.MENU_CHANGE_PROJECT' | translate }}</p>
		</div>
	</div>
	<div *ngIf="!minimalOption" class="row my-1 mx-0 py-1 px-3 user-settings-item"
		(click)="setasDefault(defaultBtnIcon)">
		<div class="float-left mx-2 text-muted">
			<i class="fas " aria-hidden="true"
				[ngClass]="{'fa-check-circle': defaultBtnIcon, 'fa-times-circle': !defaultBtnIcon}"></i>
		</div>
		<div class="float-left mx-2">
			<p class="m-0 ">{{ setDefaultBtnText }}</p>
		</div>
	</div>
	<hr *ngIf="!minimalOption" class="m-1" />
	<div class="row my-1 mx-0 py-1 px-3 user-settings-item" (click)="signOutBtnClickEvent(logoutConfirmationTemplate)">
		<div class="float-left mx-2 text-muted">
			<i class="fa fa-sign-out" aria-hidden="true"></i>
		</div>
		<div class="float-left mx-2">
			<p class="m-0 ">{{ 'HEADER.MENU_LOGOUT' | translate }}</p>
		</div>
	</div>
	<hr class="m-1" />
	<div class="row my-1 mx-0 py-1 px-3 user-settings-item">
		<div class="float-left mx-2 text-muted">
			<i class="fa fa-info-circle" aria-hidden="true"></i>
		</div>
		<div class="float-left mx-2">
			<p class="m-0 ">{{ 'HEADER.MENU_VERSION' | translate }} - {{appVersion}}</p>
		</div>
	</div>
	<div class="row my-1 mx-0 py-1 px-3 user-settings-item">
		<div class="float-left mx-2 text-muted small">
			<i class="fa fa-info-circle" aria-hidden="true"></i>
		</div>
		<div class="float-left mx-2">
			<p class="m-0 small">{{ 'HEADER.MENU_SERVERVERSION' | translate }} - {{serverVersion}}</p>
		</div>
	</div>
</ng-template>

<ng-template #menuContent>
	<div class="card col-sm-12 rounded-0 p-0 border-0 mt-2 mb-1">
		<div class="card-body row py-0 px-1 mx-auto">
			<div *ngIf="!minimalOption" class="col-sm-4 my-1 px-1 menu-content-item rounded" (click)="opButtonClick()">
				<span class="menu-content-span">
					<img class="my-2" src="../../assets/icons/output_generate_icon.svg" height="auto" width="50%">
					<p class="my-1">{{ 'HEADER.MENU_OUTPUT_MANAGEMENT' | translate }}</p>
				</span>
			</div>
			<div *ngIf="!minimalOption" class="col-sm-4 my-1 px-1 menu-content-item rounded"
				(click)="reusableButtonClick()">
				<span class="menu-content-span">
					<img class="my-2" src="../../assets/icons/reusable_update_icon.svg" height="auto" width="50%">
					<p class="my-1">{{ 'HEADER.MENU_REUSABLE_UPDATES' | translate }}</p>
				</span>
			</div>
			<div *ngIf="!minimalOption && (enableAddWord || enableQeuryWord)" class="col-sm-4 my-1 px-1 menu-content-item rounded"
				(click)="dictionaryClick()">
				<span class="menu-content-span">
					<img class="my-2" src="../../assets/icons/dictionary_icon.svg" height="auto" width="50%">
					<p class="my-1">{{ 'HEADER.MENU_DICTIONARY' | translate }}</p>
				</span>
			</div>
			<!-- <div *ngIf="!minimalOption" class="col-sm-4 my-1 px-1 menu-content-item rounded">
				<span class="menu-content-span">
					<img class="my-2" src="../../assets/icons/work_flow_icon.svg" height="auto" width="100%">
					<p class="my-1">{{ 'HEADER.MENU_WORKFLOW_CONFIG' | translate }}</p>
				</span>
			</div> -->
			<!-- <div *ngIf="!minimalOption && enableViewProcedureStore" class="col-sm-4 my-1 px-1 menu-content-item rounded" (click)="psButtonClick()">
				<span class="menu-content-span">
					<img class="my-2" src="../../assets/icons/procedure_store_icon.svg" height="auto" width="50%">
					<p class="my-1">{{ 'HEADER.MENU_PROCEDURE_STORE' | translate }}</p>
				</span>
			</div> -->
			<div *ngIf="!minimalOption && enableViewReport" class="col-sm-4 my-1 px-1 menu-content-item rounded"
				(click)="reportsBtnClick()">
				<span class="menu-content-span">
					<img class="my-2" src="../../assets/icons/reports_icon.svg" height="auto" width="50%">
					<p class="my-1">{{ 'HEADER.MENU_REPORTS' | translate }}</p>
				</span>
			</div>

			<!--Add the button click event-->
			<div *ngIf="!minimalOption && enableViewProcedureStore" class="col-sm-4 my-1 px-1 menu-content-item rounded" (click)="optDataClick()">
				<span class="menu-content-span">
					<img class="my-2" src="../../assets/icons/file-spreadsheet.svg" height="auto" width="50%">
					<p class="my-1">{{ 'HEADER.MENU_OPERATING_DATA' | translate }}</p>
				</span>
			</div>
		
			<div *ngIf="enableAdminSettings" class="my-1 px-1 menu-content-item rounded"
				[ngClass]="{'col-sm-12': minimalOption, 'col-sm-4': !minimalOption}" (click)="adminSetButtonClick()">
				<span class="menu-content-span">
					<img class="my-2" src="../../assets/icons/settings_icon.svg" height="auto" width="50%">
					<p class="my-1">{{ 'HEADER.MENU_ADMIN_SETTINGS' | translate }}</p>
				</span>
			</div>
			<div *ngIf="!enableAdminSettings" class="my-1 px-1 menu-content-item rounded" style="pointer-events: none;cursor: default;"
				[ngClass]="{'col-sm-12': minimalOption, 'col-sm-4': !minimalOption}">
				<span class="menu-content-span">
					<img class="my-2" src="../../assets/icons/settings_icon.svg" height="auto" width="50%" style="opacity: 0.4;filter: alpha(opacity=40);">
					<p class="my-1">{{ 'HEADER.MENU_ADMIN_SETTINGS' | translate }}</p>
				</span>
			</div>
			<div *ngIf="!minimalOption && enableViewDocumentStore" class="col-sm-4 my-1 px-1 menu-content-item rounded" (click)="documentStoreButtonClick()">
				<span class="menu-content-span">
					<img class="my-2" src="../../assets/icons/procedure_store_icon.svg" height="auto" width="50%">
					<p class="my-1">{{ 'HEADER.MENU_DOCUMENT_STORE' | translate }}</p>
				</span>
			</div>
			<div *ngIf="!minimalOption" class="col-sm-4 my-1 px-1 menu-content-item rounded" (click)="workflowConfigButtonClick()">
				<span class="menu-content-span">
					<img class="my-2" src="../../assets/icons/work_flow_icon.svg" height="auto" width="50%">
					<p class="my-1">{{ 'HEADER.WORK_FLOW_CONFIGURATIONS' | translate }}</p>
				</span>
			</div>
		</div>
	</div>
</ng-template>
<ng-template #userProfilerTemplate>
	<div class="modal-header">
		<h4 class="modal-title pull-left">{{ 'HEADER.VIEW_PROFILE' | translate}}</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div>
			<div style="font-size: medium;">{{ 'HEADER.TIMEZONE' | translate}}</div>	
			<ngx-timezones [(timezone)]="timezoneString" [placeholder]="testword"></ngx-timezones>
			<div hidden="true">{{ 'HEADER.LANGUAGE' | translate}}</div>
				<div hidden="true">
					<label>
						<select #langSelect (change)="changeLanguage(langSelect.value)">
							<option *ngFor="let lang of translate.getLangs()" [value]="lang" [selected]="lang">{{ lang }}</option>
						</select>
					</label>
				</div>
			<div hidden="true">{{ 'HEADER.FONTSIZE' | translate}}</div>
					<form [formGroup]="fontForm" hidden="true" >
						<select #fontSelect formControlName="fontControl" [style.font-size.px]="font['0']" (change)="changeFontSize(fontSelect.value)">
							<option [style.font-size.px]="font['0']" [value]="font['0']" *ngFor="let font of fontList">{{font['1']}}</option>
						</select>
					</form>
			<div hidden="true">{{ 'HEADER.WIDGET' | translate}}</div>
				<div *ngFor="let widget of widgetList" hidden="true">
					<div>
						<input type="checkbox" data-md-icheck (change)="toggleVisibility(widget)" [checked]="widget['1'] === 1">  {{widget["0"]}} 
						<!-- <span *ngIf="marked"></span><span *ngIf="!marked"></span> -->
					</div>                
				</div>
		</div>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" data-dismiss="modal" (click)="save()">{{
					'HEADER.SAVE' | translate}}</button>
		<button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cancel()">{{
					'HEADER.CANCEL' | translate}}</button>
	</div>
</ng-template>
<ng-template #logoutConfirmationTemplate>
	<div class="modal-header">
		<h4 class="modal-title pull-left">{{ 'HEADER.USER_LOG_OUT_TITLE' | translate}}</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		{{ 'HEADER.USER_LOG_OUT_MESSAGE' | translate}}
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" data-dismiss="modal" (click)="logout()">{{
					'HEADER.LOG_OUT_CONFIRM' | translate}}</button>
		<button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cancelLogOut()">{{
					'HEADER.CANCEL' | translate}}</button>
	</div>
</ng-template>
