import { Component, OnInit, Renderer2, Pipe} from '@angular/core';
import { AlertService } from 'src/app/util/alert/alert.service';
import { WsResponse } from 'src/app/util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';
import { RevisionsComponent } from './revisions.component';
import { SharedService } from "../../../../util/shared.service";
import { DomSanitizer } from "@angular/platform-browser";


@Pipe({name: 'safeHtml'})
export class Safe1 {
    constructor(private sanitizer:DomSanitizer){}
  
    transform(value: any, args?: any): any {
      return this.sanitizer.bypassSecurityTrustHtml(value);
    }
  }

@Component({
  templateUrl: './compare-revsions.component.html',
  styleUrls: ['./compare-revisions.component.css']  
})
export class CompareRevisionComponent implements OnInit {

public revisionContent: string = "";
public revisionNo1: string = "";
public revisionNo2: string = "";
public itemType: string = "";
public itemName: string = "";
private revisionData = [];
public divHeight: number = (window.innerHeight) * 5 / 6.5

  ngOnInit(): void {
    this.revisionContent = this.sharedService.getRevisionContent();
    this.revisionData = this.sharedService.getRevisionData();
    this.revisionNo1 = this.revisionData[0];
    this.revisionNo2 = this.revisionData[1];
    this.itemType = this.revisionData[2];
    this.itemName = this.revisionData[3];
    if(!this.revisionNo1.includes("Current Version") && !this.revisionNo2.includes("Current Version")) {
      let rev1 = parseInt(this.revisionNo1.substring(0, this.revisionNo1.length - 2).substring(2));
      let rev2 = parseInt(this.revisionNo2.substring(0, this.revisionNo2.length - 2).substring(2));
      if (rev1 > rev2) {	                	
      } else {
        let temp = this.revisionNo1;
        this.revisionNo1 = this.revisionNo2;
        this.revisionNo2 = temp;
      }
    } else if (this.revisionNo2.includes("Current Version")) {
      let temp = this.revisionNo1;
      this.revisionNo1 = this.revisionNo2;
      this.revisionNo2 = temp;
    } 
  }

  constructor(private alertService: AlertService, private sharedService: SharedService,) {        
   }  
}
