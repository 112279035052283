import { Component, EventEmitter, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AlertService } from 'src/app/util/alert/alert.service';
import { DecodeHtmlString } from 'src/app/util/common/decode-html-string.pipe';
import { Constants } from 'src/app/util/constants';
import { LoadingService } from 'src/app/util/loading/loading.service';
import { WsCallback } from 'src/app/util/ws-callback.interface';
import { WsResponse } from 'src/app/util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';
import { ManagecheckitemsService } from '../../managecheckitems/managecheckitems.service';
import { ManagechecksheetsService } from '../managechecksheets.service';

declare var $: any;

@Component({
	selector: 'app-assignchecks',
	templateUrl: './assignchecks.component.html',
	styleUrls: ['./assignchecks.component.css']
})
export class AssignchecksComponent implements OnInit, WsCallback {
	@ViewChild("closeConfirmModal") closeConfirmModal: TemplateRef<any>;
	public closeModalRef: BsModalRef;

	public csId: string;
	private loadingHandler: any;
	public searchData: any;
	private chkDesc: string = "";
	private chkCategory: string = "";
	private chkType: string = "";
	public treeRef: any = null;
	public treeRefCS: any = null;
	private categoryData: any = [];
	private checksheetNode: any;
	public treeTitle = "";
	private isSaved: boolean = true;

	loadingCSSub: Subscription;

	treeheight: number = (window.innerHeight) * 2 / 3;

	public data_n: any = {
		'data': [
		]
	}
	tree_startLenght: number;
	tree_stopLenght: number;

	public selectedTreeItem: any = null;
	public renameDisable: boolean = true;
	public deleteDisable: boolean = true;

	constructor(
		public bsModalRef: BsModalRef,
		private alertService: AlertService,
		private loadingService: LoadingService,
		private translate: TranslateService,
		private checksService: ManagecheckitemsService,
		private checksheetService: ManagechecksheetsService,
		private modalService: BsModalService,
		private decodeHTMLString: DecodeHtmlString
	) { }

	ngOnInit(): void {
		this.loadingHandler = new AssignchecksComponent.LoadingHandler();
		// loading Checksheet check data after loading all other items
		this.loadingCSSub = this.loadingHandler.getLoadedSub().subscribe(() => {
			this.loadingService.showLoading(true, false, 'Loading', 0);
			this.checksheetService.getChecksheetCheckData(this.csId, this);
		});
		this.loadingHandler.init(1, this.loadingService);
		this.checksheetService.getCSDataById(this.csId, this);
	}

	ngAfterViewInit(): void {
		//Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
		//Add 'implements AfterViewInit' to the class.
		this.changeModalSize()
	}

	@HostListener('window:resize', ['$event'])
	public changeModalSize(event?: any) {
		//To change assign checks UI height
		//this.sharedService.changeModalSizeRequest();
		this.treeheight = (window.innerHeight) * 2 / 3;
	}

	onSuccess(data: WsResponse, serviceType: WsType) {
		if (serviceType == WsType.GET_CS_CHECK_DATA_BY_ID) {
			if (data.payload != null) {
				this.loadCheckSheetTree(data.payload);
			} else {
				this.loadingService.hideLoading();
				this.alertService.clear();
				this.translate.get('CS_ASSIGN_CHECKS.LOAD_CHECK_DATA_FAILED').subscribe((res: string) => {
					this.alertService.error(res);
				});
			}
		}
		else if (serviceType == WsType.GET_CS_DATA_BY_ID) {
			if (data.payload != null) {
				this.checksheetNode = data.payload;
				this.treeTitle = this.checksheetNode.checkSheetCode + " - " + this.checksheetNode.checkSheetName;
			}
			this.loadingHandler.handle();
			//console.log("CHECKSHEET DATA------------", this.checksheetNode);
		}
	}

	onFail(data: WsResponse, serviceType: WsType) {
		if (serviceType == WsType.GET_CS_DATA_BY_ID) {
			this.loadingHandler.handle();
			this.alertService.clear();
			this.alertService.error(data.statusDescription)
		}
		else if (serviceType == WsType.GET_CS_CHECK_DATA_BY_ID) {
			this.loadingService.hideLoading();
			this.alertService.clear();
			this.alertService.error(data.statusDescription);
		}
	}


	searchFunc(data: any) {
		this.searchData = data;
		this.chkCategory = "";
		this.chkDesc = "";
		this.chkType = "";

		//assign value for the fields from searchWordMap
		var plcategory = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_CHK_CATEGORY];
		if (plcategory != undefined) {
			this.chkCategory = plcategory;
		} else {
			//no name
		}
		var pldesc = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_CHK_DESC];
		if (pldesc != undefined) {
			this.chkDesc = pldesc;
		} else {
			//no code
		}

		var pltype = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_CHK_TYPE];
		if (pltype != undefined) {
			this.chkType = pltype;
		} else {
			//no type
		}

		if (this.chkCategory != "" || this.chkDesc != "" || this.chkType != "") {
			let to: any = false;
			if (to) { clearTimeout(to); }
			this.loadingService.showLoading(true, null, "Searching", null);
			to = setTimeout(() => {
				this.checksService.searchChecks(this.chkCategory, this.chkDesc, this.chkType, "assignCheck", "").subscribe(
					data => {
						this.loadFiltertedChecksTree(data.payload);
					},
					error => {
						this.loadingService.hideLoading();
						this.alertService.clear()
						this.alertService.error(error.statusDescription)
					});
			}, 200);
		}
		else {
			this.alertService.clear()
			this.translate.get('CS_ASSIGN_CHECKS.SEARCH_FIELDS_EMPTY').subscribe((res: string) => {
				this.alertService.warn(res);
			});
		}
	}

	loadCheckSheetTree(payload: any) {
		//("CHECK DATA------------", payload);
		if (this.checksheetNode != null) {
			this.data_n['data'] = [];

			this.data_n["data"].push(
				{
					id: this.checksheetNode.checkSheetId,
					parent: "#",
					text: this.checksheetNode.checkSheetName,
					li_attr: { style: "font-weight: bold;" },
					state: { disabled: 'true' },
					type: 'CheckSheet',
					icon: 'far fa-file-alt'
				},
			);
			if (payload != null) {
				for (let i = 0; i < payload.length; i++) {
					if (payload[i]["parentId"] == null) {
						// Check for Item type : Check and header
						if (payload[i]["itemType"] == "Check") {
							this.data_n["data"].push(
								{
									id: payload[i]["checkSheetCheckId"],
									parent: payload[i]["checkSheetId"],
									text: this.decodeHTMLString.transform(payload[i]["checkDesc"]),
									li_attr: { style: "font-weight: normal;" },
									a_attr: { style: "white-space:normal; height: auto" },
									type: 'Check',
									icon: 'far fa-file-check',
									data: { checkId: payload[i]["checkId"], itemType: payload[i]["itemType"], groupName: payload[i]["groupName"] }
								}
							);
						}
						else if (payload[i]["itemType"] == "Header") {
							this.data_n["data"].push(
								{
									id: payload[i]["checkSheetCheckId"],
									parent: payload[i]["checkSheetId"],
									text: payload[i]["groupName"],
									li_attr: { style: "font-weight: bold;" },
									a_attr: { style: "white-space:normal; height: auto" },
									type: 'Header',
									icon: 'far fa-layer-group',
									data: { itemType: payload[i]["itemType"] }
								}
							);
						}
					} else {
						this.data_n["data"].push(
							{
								id: payload[i]["checkSheetCheckId"],
								parent: payload[i]["parentId"],
								text: this.decodeHTMLString.transform(payload[i]["checkDesc"]),
								li_attr: { style: "font-weight: normal;" },
								a_attr: { style: "white-space:normal; height: auto" },
								type: 'Check',
								icon: 'far fa-file-check',
								data: { checkId: payload[i]["checkId"], itemType: payload[i]["itemType"], groupName: payload[i]["groupName"] }
							}
						);
					}
				}
				this.initChecksheetTree();
				//console.log("this.data_n " + this.data_n["data"])
			}
		}
		this.loadingService.hideLoading();
	}

	loadFiltertedChecksTree(payload: any) {
		if (payload != null) {
			this.createCheckCatergoryNodes(payload);
			this.data_n['data'] = [];

			if (this.categoryData.length > 0) {
				for (let i = 0; i < this.categoryData.length; i++) {
					var category = this.categoryData[i];
					if (!this.data_n["data"].find(x => x.id === category["catId"])) {
						this.data_n["data"].push(
							{
								id: category["catId"],
								parent: "#",
								text: category["catName"],
								li_attr: { style: "font-weight: bold;" },
								state: { disabled: 'true' },
								type: 'CheckCategory',
								icon: 'far fa-cube'
							}
						);
					}
					this.data_n["data"].push(
						{
							id: category["id"],
							parent: category["catId"],
							text: category["catType"],
							li_attr: { style: "font-style: italic;" },
							state: { disabled: 'true' },
							type: 'CheckType',
							icon: 'far fa-angle-right'
						}
					);
				}
			}

			for (let i = 0; i < payload.length; i++) {
				if (payload[i]["status"] != undefined && payload[i]["status"] == "Active") {
					var typeId = "";
					if (payload[i]["checkType"] === 'OK-NA-PL') {
						typeId = payload[i]["checkCategory"] + "-1";
					}
					else if (payload[i]["checkType"] === 'DATA ENTRY') {
						typeId = payload[i]["checkCategory"] + "-2";
					}
					this.data_n["data"].push(
						{
							id: payload[i]["checkId"],
							parent: typeId,
							text: this.decodeHTMLString.transform(payload[i]["checkDescription"]),
							li_attr: { style: "font-weight: normal; font-style: normal;" },
							a_attr: { style: "white-space:normal; height: auto" },
							type: 'Check',
							icon: 'far fa-file-check',
						}
					);
				}
			}
			this.initTree();
			console.log("this.data_n ", this.data_n["data"])
		}
		this.loadingService.hideLoading();
	}

	createCheckCatergoryNodes(payload: any) {
		this.categoryData = [];
		for (let i = 0; i < payload.length; i++) {
			var typeId = "";
			if (payload[i]["checkType"] === 'OK-NA-PL') {
				typeId = payload[i]["checkCategory"] + "-1";
			}
			else if (payload[i]["checkType"] === 'DATA ENTRY') {
				typeId = payload[i]["checkCategory"] + "-2";
			}
			if (payload[i]["checkId"] != null && payload[i]["checkCategory"] !== "") {
				if (this.categoryData.find(x => (x.catId === payload[i]["checkCategory"] && x.catType === payload[i]["checkType"])) == undefined) {
					let data = {
						id: typeId,
						catId: payload[i]["checkCategory"],
						catName: payload[i]["checkCategoryName"],
						catType: payload[i]["checkType"]
					}
					this.categoryData.push(data);
				}
			}
		}
	}

	private initChecksheetTree() {
		var dndNode = null
		var parentNode = null
		if (this.treeRefCS != null) {
			$('#checkstree').jstree('destroy');
			this.treeRefCS = null;
		}
		this.treeRefCS = $('#checkstree').jstree({
			core: {
				multiple: false,
				themes: {
					variant: 'large'
				},
				data: this.data_n.data,

				check_callback: function (operation, node, node_parent, node_position, more) {
					switch (operation) {
						case 'move_node':
							var returnValue = true;
							if (node_parent.id == '#') {
								returnValue = false;
							}
							else if (node.original.type === 'Header') {
								if (node_parent.data != undefined && (node_parent.data.itemType === 'Header' || node_parent.data.itemType === 'Check')) {
									return false;
								}
								else {
									return true;
								}
							}
							else {
								returnValue = true;
							}
							dndNode = null;
							parentNode = null;
							return returnValue;
						case 'copy_node':
							var returnValue = true;
							dndNode = node;
							parentNode = node_parent;
							if (node_parent.id == '#') {
								returnValue = false;
							}
							else {
								returnValue = true;
							}
							return returnValue;
						default:
							break;
					}
				},
				force_text: true
			},
			dnd: {
				large_drop_target: '#checkstree',
			},
			plugins: ["dnd", "types", "state", "wholerow"],
		});
		$('#checkstree').bind("ready.jstree", (event: any, data: any) => this.checksheetTreeReady(event, data));
		setTimeout(() => {
			//select tree item functionality
			$('#checkstree').bind("select_node.jstree", (evt, data) => this.itemSelectEvent(evt, data));
			//drag and drop functionality
			$(document).bind("dnd_start.vakata", (evt, data) => this.nodeMoveStartEvent(evt, data));
			//drag and drop start functionality
			$(document).bind("dnd_stop.vakata", (evt, data) => this.nodeMoveStopEvent(evt, data, dndNode, parentNode));
		}, 250);
	}

	private initTree() {
		var self = this;
		if (this.treeRef != null) {
			$('#filteredtree').jstree('destroy');
			this.treeRef = null;
		}
		this.treeRef = $('#filteredtree').jstree({
			core: {
				multiple: false,
				themes: {
					variant: 'large'
				},
				data: this.data_n.data,

				check_callback: function (operation, node, node_parent, node_position, more) {
					switch (operation) {
						case 'move_node':
							return false
						case 'copy_node':
							return false
						default:
							break;
					}
				},
				force_text: true
			},
			dnd: {
				is_draggable: function (node) {
					if (node[0].original.type === 'CheckCategory' || node[0].original.type === 'CheckType') {
						self.translate.get('CS_ASSIGN_CHECKS.COULDNT_DRAG_NODE').subscribe((res: string) => {
							self.alertService.warn(res);
						});
						return false;
					}
					else {
						self.alertService.clear();
					}
					return true;
				},
				always_copy: true,
			},
			plugins: ["dnd", "types", "wholerow"],
		});
		$('#filteredtree').bind("ready.jstree", (event: any, data: any) => this.jsTreeReady(event, data))
	}

	jsTreeReady(event: any, data: any) {
		$("#filteredtree").jstree("deselect_all");
		$("#filteredtree").jstree("open_all");
	}

	checksheetTreeReady(event: any, data: any) {
		$("#checkstree").jstree("deselect_all");
		$("#checkstree").jstree("open_all");
		$("#checkstree").scrollTop(0);
	}

	private nodeMoveStopEvent(evt: any, data: any, dndNode: any, parentNode: any) {
		this.isSaved = false;
		this.alertService.clear();
		if (dndNode != null && parentNode != null) {
			let arrayLength = parentNode.children_d.length
			let newItemId = parentNode.children_d[arrayLength - 1]

			if (newItemId != undefined) {
				var uniqueId = this.getRandomId();
				$('#checkstree').jstree('open_node', parentNode.id); // expand parent node on drag and drop event.
				let node = $('#checkstree').jstree(true).get_node(newItemId);
				var data_temp: any = {
					'data': [
					]
				}
				var itemId = dndNode.id;
				data_temp.data.push({
					checkId: itemId,
					itemType: 'Check',
					groupName: 'undefined'
				})
				if (node) {
					if (node.data == null) {
						node.data = data_temp.data[0]
						$('#checkstree').jstree(true).set_id(node, uniqueId)
					}
				} else {
					//can not find node
					if (this.findNodeByText(dndNode.text) != null) {
						node = this.findNodeByText(dndNode.text)
						if (node.data == null) {
							node.data = JSON.parse(JSON.stringify(data_temp.data))
							$('#checkstree').jstree(true).set_id(node, uniqueId)
						}
					}
				}

				var treeData = JSON.parse(JSON.stringify($('#checkstree').jstree(true).get_json('#', { flat: true, no_data: false })));
				this.tree_stopLenght = treeData.length;
				if (this.tree_startLenght !== this.tree_stopLenght) {
					for (let i = 1; i < treeData.length; i++) {
						if (itemId == treeData[i].data.checkId && treeData[i].id != node.id) {
							this.translate.get('CS_ASSIGN_CHECKS.ASSIGN_CHECK_WARN').subscribe((res: string) => {
								this.alertService.warn(res);
							});
							break;
						}
					}
				}
			}
		} else {
			//nodes order changed
		}
	}

	private nodeMoveStartEvent(evt: any, data: any) {
		const treeData = JSON.parse(JSON.stringify($('#checkstree').jstree(true)
			.get_json('#', { flat: true, no_data: false })));
		this.tree_startLenght = treeData.length;
	}

	/**
	 * find node by its text and id 
	 * use when jsTree has different node Id than parentNode.children_d in nodeMoveStopEvent
	 * @param text 
	 */
	private findNodeByText(text: string): any {
		let returnNode = null
		let jsT = $('#checkstree').jstree(true).get_json('#', { flat: true })
		let nodes = jsT.filter((i: { text: string; }) => i.text == text)
		if (nodes.length > 1) {
			nodes.forEach((element: any) => {
				if (element.id.includes("_")) {
					returnNode = element
				}
			});
		} else {
			returnNode = nodes[0]
		}
		return returnNode
	}

	getRandomId() {
		function s4() {
			return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
		}
		return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
	}

	private itemSelectEvent(evt: any, data: any) {
		this.selectedTreeItem = data.node;
		this.renameDisable = (data.node.data.itemType == "Check") ? true : false;
		this.deleteDisable = (this.selectedTreeItem == null) ? true : false;
	}

	addNewGroup() {
		this.isSaved = false;
		$("#checkstree").jstree().create_node(this.csId,
			{
				id: this.getRandomId(),
				li_attr: { style: "font-weight: bold;" },
				a_attr: { style: "white-space:normal; height: auto" },
				type: 'Header',
				icon: 'far fa-layer-group',
				text: 'Group',
				data: { itemType: "Header" }
			},
			"last");
		$("#checkstree").scrollTop($("#checkstree")[0].scrollHeight - $("#checkstree")[0].clientHeight);
	}

	renameNode() {
		this.isSaved = false;
		let ref = $('#checkstree').jstree(true)
		let sel = ref.get_selected()
		if (!sel.length) {
			return false
		}
		sel = sel[0]
		ref.edit(sel)
	}

	removeNode() {
		this.isSaved = false;
		$('#checkstree').jstree().delete_node([this.selectedTreeItem]);
		this.selectedTreeItem = null;
	}

	saveAssignChecks() {
		this.loadingService.showLoading(true, false, 'Saving', 0);
		var treeData = JSON.parse(JSON.stringify($('#checkstree').jstree(true).get_json('#', { flat: true, no_data: false })));
		var savedTreeData = [];
		for (let i = 1; i < treeData.length; i++) {
			let item = treeData[i];
			let type = item.data.itemType;
			let parent = item.parent;

			let checksheetCheckId = item.id;
			let checkId = "";
			let parentId = "";
			let checkNumber: number = 0;
			let seqId: number = 0;
			let groupName = "";

			if (parent == this.csId) {
				parentId = null;
			}
			else {
				parentId = parent;
			}
			if (type == 'Header') {
				checkId = i.toString();
				checkNumber = 0;
				seqId = i;
				groupName = item.text;
			}
			else {
				checkId = item.data.checkId;
				groupName = item.data.groupName;
				if (parentId == null) {
					checkNumber = i;
					seqId = i;
				}
				else {
					let node = $('#checkstree').jstree(true).get_node(parentId);
					if (node.children) {
						for (let j = 0; j < node.children.length; j++) {
							let childNode = node.children[j];
							if (childNode == item.id) {
								checkNumber = j + 1;
								seqId = j + 1;
								break;
							}
						}
					}
				}
			}
			let data = {
				checksheetId: this.csId,
				type: type,
				checkId: checkId,
				checksheetCheckId: checksheetCheckId,
				parentId: parentId,
				checkNumber: checkNumber,
				seqId: seqId,
				groupName: groupName,
			}
			savedTreeData.push(data);
		}
		//console.log("Final Tree : ", JSON.stringify(savedTreeData));
		this.checksheetService.saveCSChecks(this.csId, JSON.stringify(savedTreeData))
			.subscribe(data => {
				this.isSaved = true;
				this.loadingService.hideLoading();
				this.translate.get('CS_ASSIGN_CHECKS.ASSIGN_CHECKS_SUCCESS').subscribe((res: string) => {
					this.alertService.success(res);
				});
			},
				error => {
					this.isSaved = false;
					this.loadingService.hideLoading();
					this.translate.get('CS_ASSIGN_CHECKS.ASSIGN_CHECKS_FAILED').subscribe((res: string) => {
						this.alertService.error(res);
					});
				}
			);
	}

	//hides the modal
	cancel() {
		if (this.isSaved) {
			this.bsModalRef.hide();
		}
		else {
			//Show close confirm modal
			this.closeModalRef = this.modalService.show(this.closeConfirmModal);
		}
	}

	closeModal() {
		this.closeModalRef.hide();
	}

	confirmModal() {
		this.isSaved = true;
		this.closeModalRef.hide();
		this.bsModalRef.hide();
	}

	ngOnDestroy() {
		this.treeRef = null;
		this.treeRefCS = null;
		$('#filteredtree').jstree('destroy');
		$('#checkstree').jstree('destroy');
	}

	/**
	 * This class handles parallel calling of web services. loadingService initiate the loading service which is required to show and hide functionalities.
	 * Then, parallelServiceCount indicate the number of parallel services that we want to call. init() method is used to initialize the handler object.
	 * handle() method will be called on onSuccess() and onFail() methods of required service calls. This method will hide the loading view when the counter
	 * becomes zero which means no more service response to be arrived.
	 */
	static LoadingHandler = class {
		parallelServiceCount: number = 0;
		loadingService: LoadingService = null;
		loaded = new EventEmitter<any>()

		init(parallelServiceCount: number, loadingService: LoadingService) {
			this.parallelServiceCount = parallelServiceCount
			this.loadingService = loadingService;
			this.loadingService.showLoading(true, false, 'Loading data', 0);
		}

		handle() {
			--this.parallelServiceCount;
			if (this.parallelServiceCount == 0) {
				this.loadingService.hideLoading();
				this.loaded.emit("");
			}
		}

		getLoadedSub() {
			return this.loaded;
		}
	}
}
