import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { UserVariable } from '../../../util/common/user-variable';
import { ServiceUrls } from '../../../util/service-urls';
import { WsResponse } from '../../../util/ws-response.model';
import { WsCallback } from '../../../util/ws-callback.interface';
import { WsType } from '../../../util/ws-type'
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators'
import { CommonUtil } from 'src/app/util/common/common-util';

@Injectable({
   providedIn: 'root'
})
export class DocumentTypesService {
   private callback: WsCallback;
   private docTypeParent: WsCallback;
   private rowData: any = null;
   private apiGridData: any = null;

   constructor(private http: HttpClient, private commonUtil: CommonUtil) { }

   public setCallback(callBack: WsCallback) {
      this.callback = callBack;
   }
   public setParent(callBack: WsCallback){
      this.docTypeParent = callBack;
   }

   getTopicTypes() {
      let token = UserVariable.getUserToken();
      if (!this.commonUtil.validateLogin(token)) {
         return;
      }
      const options = { params: new HttpParams().set("token", token) }
      return this.http.get(ServiceUrls.GET_TOPIC_TYPES_LIST, options).
         pipe(
            map(response => {
               var modified = JSON.parse(JSON.stringify(response));
               return new WsResponse(
                  modified.status.code,
                  modified.status.name,
                  modified.status.description,
                  modified.payload);
            }),
            catchError(error => {
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.code,
                  modified.status.name,
                  modified.status.description,
                  modified.payload);
               return throwError(res)
            })
         );
   }

   getDocumentTypesAttributes(typeId: string) {
      let token = UserVariable.getUserToken();
      if (!this.commonUtil.validateLogin(token)) {
         return;
      }
      const options = { params: new HttpParams().set("token", token).set("typeId", typeId) }
      return this.http.get(ServiceUrls.GET_DOCUMENT_TYPE_ATTRIBUTES, options).
         pipe(
            map(response => {
               var modified = JSON.parse(JSON.stringify(response));
               return new WsResponse(
                  modified.status.code,
                  modified.status.name,
                  modified.status.description,
                  modified.payload);
            }),
            catchError(error => {
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.code,
                  modified.status.name,
                  modified.status.description,
                  modified.payload);
               return throwError(res)
            })
         );
   }

   getDocAvailability(docTypeName: string, typeId: string, parentId: string, userToken: string, callBack: WsCallback) {
      let token = UserVariable.getUserToken();
      if (!this.commonUtil.validateLogin(token)) {
         return;
      }
      const options = { params: new HttpParams().set("token", token).set("docTypeName", docTypeName).set("typeId", typeId).set("parentId", parentId) }
      return this.http.get(ServiceUrls.GET_DOCUMENT_TYPE_AVAILABILITY, options).
         pipe(
            map(response => {
               var modified = JSON.parse(JSON.stringify(response));
               return new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);

            }),
            catchError(error => {
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
               return throwError(res)
            })
         );
   }

   addDocumentTypeAttribute(typeId: string) {
      let token = UserVariable.getUserToken();
      if (!this.commonUtil.validateLogin(token)) {
         return;
      }
      //const options = { params: new HttpParams().set("token", token).set("docTypeId", token) }
      return this.http.post(ServiceUrls.addDocumentTypeAttribute(typeId), JSON.stringify(new Request(typeId))).
         pipe(
            map(response => {
               var modified = JSON.parse(JSON.stringify(response));
               return new WsResponse(
                  modified.status.code,
                  modified.status.name,
                  modified.status.description,
                  modified.payload);
            }),
            catchError(error => {
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.code,
                  modified.status.name,
                  modified.status.description,
                  modified.payload);
               return throwError(res)
            })
         );
   }

   addDocument(docTypeName: string, parentId: string, callBack: WsCallback) {
      let token = UserVariable.getUserToken();
      if (!this.commonUtil.validateLogin(token)) {
         return;
      }
      const options = { params: new HttpParams().set("token", token).set("docTypeName", docTypeName).set("parentId", parentId) }
      this.http.get(ServiceUrls.ADD_DOCUMENT_TYPE, options).
         subscribe(
            response => {
               var modified = JSON.parse(JSON.stringify(response));
               var res = new WsResponse(
                  modified.status.code,
                  modified.status.name,
                  modified.status.description,
                  modified.payload);
               callBack.onSuccess(res, WsType.ADD_DOCUMENT_TYPE);

            },
            error => {
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.code,
                  modified.status.name,
                  modified.status.description,
                  modified.payload);
               callBack.onFail(res, WsType.ADD_DOCUMENT_TYPE);
               return throwError(res)
            }
         );

   }


   updateDocumentType(id: string, docType: string, callBack: WsCallback) {
      let token = UserVariable.getUserToken();
      if (!this.commonUtil.validateLogin(token)) {
         return;
      }

      this.http.post(ServiceUrls.getUpdateDocumentType(id, token), JSON.stringify(new updateRequest(id, docType))).subscribe(

         data => {
            var modified = JSON.parse(JSON.stringify(data));
            var res = new WsResponse(
               modified.status.description,
               modified.status.code,
               modified.status.name,
               modified.payload);
            callBack.onSuccess(res, WsType.UPDATE_DOCUMENT_TYPE);
         },
         error => {
            if (error.status != '') {
               var val = (error as HttpErrorResponse).error;
               var modified = JSON.parse(JSON.stringify(val));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
               callBack.onFail(res, WsType.UPDATE_DOCUMENT_TYPE);
            } else {
               //browser related issues
               var res = new WsResponse("Unknown error happened");
               callBack.onFail(res, WsType.UPDATE_DOCUMENT_TYPE);
            }
         }
      );
   }

   /**
   * Get categories of a classification
   * @param token user token
   * @param classId classification id
   * @param wsCallBack callback class
   */
   /*public getCategoriesByClassification(token: string, classId: string, wsCallBack: WsCallback) {
     this.http.get(ServiceUrls.getCategoriesByClassification(token, classId)).subscribe(
        data => {
           var modified = JSON.parse(JSON.stringify(data));
           var res = new WsResponse(
              modified.status.description,
              modified.status.code,
              modified.status.name,
              modified.payload);
           wsCallBack.onSuccess(res, WsType.GET_CATEGORIES_BY_CLASSIFICATION);
        },
        error => {
           if (error.status != '') {
              var val = (error as HttpErrorResponse).error;
              var modified = JSON.parse(JSON.stringify(val));
              var res = new WsResponse(
                 modified.status.description,
                 modified.status.code,
                 modified.status.name,
                 modified.payload);
              wsCallBack.onFail(res, WsType.GET_CATEGORIES_BY_CLASSIFICATION);
           } else {
              //browser related issues
              var res = new WsResponse("Unknown error happened");
              wsCallBack.onFail(res, WsType.GET_CATEGORIES_BY_CLASSIFICATION);
           }
        }
     );
   }*/

   /**
   * Get classification attributes for a project
   * @param token user token
   * @param wsCallBack callback class
   */
   public getTagClassAttributeList(token: string, wsCallBack: WsCallback) {
      this.http.get(ServiceUrls.getTagClassAttributeList(token)).subscribe(
         data => {
            var modified = JSON.parse(JSON.stringify(data));
            var res = new WsResponse(
               modified.status.description,
               modified.status.code,
               modified.status.name,
               modified.payload);
            wsCallBack.onSuccess(res, WsType.GET_TAG_CLASSIFICATION_ATTRIBUTE_LIST);
         },
         error => {
            if (error.status != '') {
               var val = (error as HttpErrorResponse).error;
               var modified = JSON.parse(JSON.stringify(val));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
               wsCallBack.onFail(res, WsType.GET_TAG_CLASSIFICATION_ATTRIBUTE_LIST);
            } else {
               //browser related issues
               var res = new WsResponse("Unknown error happened");
               wsCallBack.onFail(res, WsType.GET_TAG_CLASSIFICATION_ATTRIBUTE_LIST);
            }
         }
      );
   }

   /**
    * Get already assigned document type attributes.
    * @param token user token
    * @param docTypeID  doc type id
    * @param wsCallBack  callback class
    */
   public getDocumentTypeAttributeList(token: string, docTypeID: string, wsCallBack: WsCallback) {
      this.http.get(ServiceUrls.getDocumentTypeAttributeList(token, docTypeID)).subscribe(
         data => {
            var modified = JSON.parse(JSON.stringify(data));
            var res = new WsResponse(
               modified.status.description,
               modified.status.code,
               modified.status.name,
               modified.payload);
            wsCallBack.onSuccess(res, WsType.GET_DOCUMENT_TYPE_ATTRIBUTE_LIST);
         },
         error => {
            if (error.status != '') {
               var val = (error as HttpErrorResponse).error;
               var modified = JSON.parse(JSON.stringify(val));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
               wsCallBack.onFail(res, WsType.GET_DOCUMENT_TYPE_ATTRIBUTE_LIST);
            } else {
               //browser related issues
               var res = new WsResponse("Unknown error happened");
               wsCallBack.onFail(res, WsType.GET_DOCUMENT_TYPE_ATTRIBUTE_LIST);
            }
         }
      );
   }

   /**
    * Get category values.
    * @param token  user token
    * @param parentId  parent id
    * @param dtaId  dta ID
    * @param wsCallBack  callback class
    */
   public getCategoryValues(token: string, parentId: string, dtaId: string, wsCallBack: WsCallback) {
      this.http.get(ServiceUrls.getCategoryValues(token, parentId, dtaId)).subscribe(
         data => {
            var modified = JSON.parse(JSON.stringify(data));
            var res = new WsResponse(
               modified.status.description,
               modified.status.code,
               modified.status.name,
               modified.payload);
            wsCallBack.onSuccess(res, WsType.GET_CATEGORY_VALUES);
         },
         error => {
            if (error.status != '') {
               var val = (error as HttpErrorResponse).error;
               var modified = JSON.parse(JSON.stringify(val));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
               wsCallBack.onFail(res, WsType.GET_CATEGORY_VALUES);
            } else {
               //browser related issues
               var res = new WsResponse("Unknown error happened");
               wsCallBack.onFail(res, WsType.GET_CATEGORY_VALUES);
            }
         }
      );
   }

   doAddAttribute(docTypeAttrId: String, docTypeId: string, attributeId: String, defaultType: String) {
      let token = UserVariable.getUserToken();
      if (!this.commonUtil.validateLogin(token)) {
         return;
      }
      let url = this.doAddAttributeURL(token);
      // console.log("url...." + url);
      return this.http.post(url, JSON.stringify(new AddRequest(docTypeAttrId, docTypeId, attributeId, defaultType))).
         pipe(
            map(response => {
               var modified = JSON.parse(JSON.stringify(response));
               return new WsResponse(
                  modified.status.description,
                  modified.status.name,
                  modified.status.code,
                  modified.payload);
            }),
            catchError(error => {
               var modified = JSON.parse(JSON.stringify(error));
               var res = new WsResponse(
                  modified.error.status.description,
                  modified.error.status.name,
                  modified.error.status.code,
                  modified.error.payload);
               return throwError(res)
            })
         );
   }

   /**
  * Call service method to delete document type attribute using DELETE request.
  * @param temId Item  Id
  */
   deleteDocTypeAttribute(itemId: string): Observable<WsResponse> {
      let token = UserVariable.getUserToken();
      if (!this.commonUtil.validateLogin(token)) {
         return;
      }
      const options = { params: new HttpParams().set("token", token) }
      return this.http.delete(ServiceUrls.deleteDocTypeAttribute(itemId), options).
         pipe(
            map(response => {
               var modified = JSON.parse(JSON.stringify(response));
               return new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
            }),
            catchError(error => {
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
               return throwError(res)
            })
         );
   }


   /**
  * Call service method to remove document type attribute using DELETE request.
  * @param temId Item  Id
  */
   removeDocTypeAttribute(docTypeAttrId: string) {
      let token = UserVariable.getUserToken();
      if (!this.commonUtil.validateLogin(token)) {
         return;
      }
      const options = { params: new HttpParams().set("token", token) }
      this.http.delete(ServiceUrls.deleteDocTypeAttribute(docTypeAttrId), options).
      subscribe(
         response => {
               var modified = JSON.parse(JSON.stringify(response));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
                  this.docTypeParent.onSuccess(res, WsType.REMOVE_DOCUMENT_TYPE_ATTRIBUTE);
            },
         error => {
            if (error.status != '') {
               var val = (error as HttpErrorResponse).error;
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
                  this.docTypeParent.onFail(res, WsType.REMOVE_DOCUMENT_TYPE_ATTRIBUTE);
            } else {
               //browser related issues
               var res = new WsResponse("Unknown error happened");
               this.docTypeParent.onFail(res, WsType.REMOVE_DOCUMENT_TYPE_ATTRIBUTE);
            }
         }
      );
   }



   public toggleDocTypeStatus(itemId: string) {
      let token = UserVariable.getUserToken();
      if (!this.commonUtil.validateLogin(token)) {
         return;
      }
      const options = { params: new HttpParams().set("token", token) }
      this.http.get(ServiceUrls.toggleDocTypeStatus(itemId), options).
      subscribe(
            response => {
               var modified = JSON.parse(JSON.stringify(response));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
                  this.docTypeParent.onSuccess(res, WsType.TOGGLE_DOCUMENT_TYPE_ATTRIBUTE);
            },
            error => {
               if (error.status != '') {
                  var val = (error as HttpErrorResponse).error;
                  var modified = JSON.parse(JSON.stringify(error.error));
                  var res = new WsResponse(
                     modified.status.description,
                     modified.status.code,
                     modified.status.name,
                     modified.payload);
                     this.docTypeParent.onFail(res, WsType.TOGGLE_DOCUMENT_TYPE_ATTRIBUTE);
               } else {
                  //browser related issues
                  var res = new WsResponse("Unknown error happened");
                  this.docTypeParent.onFail(res, WsType.TOGGLE_DOCUMENT_TYPE_ATTRIBUTE);
               }
            }
      );
   }

   updateDotNotationCategoryId(docTypeAttrId: string, catId: string): Observable<WsResponse> {
      let token = UserVariable.getUserToken();
      if (!this.commonUtil.validateLogin(token)) {
         return;
      }
      const options = { params: new HttpParams().set("token", token).set("DTAId", docTypeAttrId).set("catId", catId) }
      return this.http.get(ServiceUrls.UPDATE_DOCUMENT_TYPE_ATTRIBUTE_DOT_NOTATION, options).
         pipe(
            map(response => {
               var modified = JSON.parse(JSON.stringify(response));
               return new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
            }),
            catchError(error => {
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
               return throwError(res)
            })
         );
   }

   public disableDocTypeStatus(docTypeId: string, status: string, callBack: WsCallback) {

      let token = UserVariable.getUserToken();
      const options = { params: new HttpParams().set("token", token).set("docTypeId", docTypeId).set("status", status) }

      this.http.get(ServiceUrls.DISABLE_DOC_TYPE, options).
         subscribe(
            response => {
               var modified = JSON.parse(JSON.stringify(response));
               var res = new WsResponse(
                  modified.status.code,
                  modified.status.name,
                  modified.status.description,
                  modified.payload);
               callBack.onSuccess(res, WsType.DISABLE_DOCUMENT_TYPE);

            },
            error => {
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.code,
                  modified.status.name,
                  modified.status.description,
                  modified.payload);
               callBack.onFail(res, WsType.DISABLE_DOCUMENT_TYPE);
               return throwError(res)
            }
         );
   }

   public doViewAttributeList(): Observable<WsResponse> {
      let token = UserVariable.getUserToken();
      if (!this.commonUtil.validateLogin(token)) {
         return;
      }
      const options = { params: new HttpParams().set("token", token) }
      return this.http.get(ServiceUrls.VIEW_MANAGE_ATTRIBUTES, options).
         pipe(
            map(response => {
               var modified = JSON.parse(JSON.stringify(response));
               return new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
            }),
            catchError(error => {
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
               return throwError(res)
            })
         );
   }

   public getDependencyList(itemId: string): Observable<WsResponse> {
      let token = UserVariable.getUserToken();
      if (!this.commonUtil.validateLogin(token)) {
         return;
      }
      const options = { params: new HttpParams().set("token", token) }
      return this.http.get(ServiceUrls.getDependencyList(itemId), options).
         pipe(
            map(response => {
               var modified = JSON.parse(JSON.stringify(response));
               return new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
            }),
            catchError(error => {
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
               return throwError(res)
            })
         );
   }

   public doViewAttribute(itemId: string): Observable<WsResponse> {
      let token = UserVariable.getUserToken();
      if (!this.commonUtil.validateLogin(token)) {
         return;
      }
      const options = { params: new HttpParams().set("token", token) }
      return this.http.get(ServiceUrls.doViewAttribute(itemId), options).
         pipe(
            map(response => {
               var modified = JSON.parse(JSON.stringify(response));
               return new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
            }),
            catchError(error => {
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
               return throwError(res)
            })
         );
   }

   public attTypeNameAvailability(attrId: string, attrName: string): Observable<WsResponse> {
      let token = UserVariable.getUserToken();
      if (!this.commonUtil.validateLogin(token)) {
         return;
      }
      const options = { params: new HttpParams().set("token", token).set("attrId", attrId).set("attrName", attrName) }
      return this.http.get(ServiceUrls.ATTRIBUTE_TYPE_NAME_AVAILABILITY, options).
         pipe(
            map(response => {
               var modified = JSON.parse(JSON.stringify(response));
               return new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
            }),
            catchError(error => {
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
               return throwError(res)
            })
         );
   }

   public doEditAttribute(attrId: string, attrName: string, dftype: string): Observable<WsResponse> {
      let token = UserVariable.getUserToken();
      if (!this.commonUtil.validateLogin(token)) {
         return;
      }
      const options = { params: new HttpParams().set("token", token).set("attrId", attrId).set("attrName", attrName).set("dftype", dftype) }
      return this.http.get(ServiceUrls.DO_EDIT_ATTRIBUTE, options).
         pipe(
            map(response => {
               var modified = JSON.parse(JSON.stringify(response));
               return new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
            }),
            catchError(error => {
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
               return throwError(res)
            })
         );
   }

   public doSaveAttribute(attrName: string, attrType: string): Observable<WsResponse> {
      let token = UserVariable.getUserToken();
      if (!this.commonUtil.validateLogin(token)) {
         return;
      }
      const options = { params: new HttpParams().set("token", token).set("attrName", attrName).set("attrType", attrType) }
      return this.http.get(ServiceUrls.DO_SAVE_ATTRIBUTE, options).
         pipe(
            map(response => {
               var modified = JSON.parse(JSON.stringify(response));
               return new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
            }),
            catchError(error => {
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
               return throwError(res)
            })
         );
   }


   public doUpdateAttribute(docTypeAttrId: string, selectedAttributeType: string): Observable<WsResponse> {
      let token = UserVariable.getUserToken();
      if (!this.commonUtil.validateLogin(token)) {
         return;
      }
      const options = { params: new HttpParams().set("token", token).set("dTAId", docTypeAttrId).set("attrType", selectedAttributeType) }
      return this.http.get(ServiceUrls.UPDATE_DOCUMENT_TYPE_ATTRIBUTE_TYPE, options).
         pipe(
            map(response => {
               var modified = JSON.parse(JSON.stringify(response));
               return new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
            }),
            catchError(error => {
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
               return throwError(res)
            })
         );
   }

   public doAddAttributeURL(token: String) {
      let url = ServiceUrls.DO_ADD_ATTRIBUTE + '?token=' + token;
      return url;
   }

   public deleteRowData(id: string) {
      if (this.apiGridData != null && this.apiGridData != undefined && id != null) {
         var rowNode = this.apiGridData.getRowNode(id);
         if (rowNode != null && rowNode != undefined) {
            var res = this.apiGridData.applyTransaction({ remove: id });
         }
      }
   }

   public setRowData(rowData: any) {
      this.rowData = rowData;
   }

   public getRowData() {
      return this.rowData;
   }

   /**
	 * Delete document type.
	 */
   deleteDocType(itemId: string) {
      console.log("document-type.service.deleteDocType() == ")
      let token = UserVariable.getUserToken();
      if (!this.commonUtil.validateLogin(token)) {
         return;
      }
      let url = ServiceUrls.deleteDocumentType(itemId);
      const options = { params: new HttpParams().set("token", token) }
      this.http.delete(url, options).subscribe(
         data => {
            var modified = JSON.parse(JSON.stringify(data));
            var res = new WsResponse(
               modified.status.description,
               modified.status.code,
               modified.status.name,
               modified.payload);
            //callBack.onSuccess(res, WsType.DELETE_DOCUMENT_TYPE);
         },
         error => {
            if (error.status != '') {
               var val = (error as HttpErrorResponse).error;
               var modified = JSON.parse(JSON.stringify(val));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
               //callBack.onFail(res, WsType.DELETE_DOCUMENT_TYPE);
            } else {
               //browser related issues
               var res = new WsResponse("Unknown error happened");
               //callBack.onFail(res, WsType.DELETE_DOCUMENT_TYPE);
            }
         }
      );
   }

   public setApiGridData(apiGridData: any) {
      this.apiGridData = apiGridData;
   }

   public getApiGridData() {
      return this.apiGridData;
   }

   public setItemData(data: any) {
		if (this.apiGridData != null && this.apiGridData != undefined && data.id != null ) {
         let selectedData = this.apiGridData.getSelectedRows();
          if (selectedData != null && selectedData != undefined) {
              let newRowData = [];
              this.apiGridData.forEachNodeAfterFilterAndSort(rowNode => {
                  let items = rowNode.data;
                     if (selectedData[0].attributeId ==  items.attributeId) {
                        items.attributeName = data.name;
                        items.defaultType = data.defAttributeType;
                     }
                  newRowData.push(items)
              });
              console.log(newRowData)
              //this.apiGridData.setRowData(newRowData)
              this.apiGridData.applyTransaction({ update: newRowData });
              this.setRowData(newRowData);
          }
		}
	}

   public deleteFileTableData(fileId: any) {
      if (this.apiGridData != null && this.apiGridData != undefined && fileId != null) {
          let selectedData = this.apiGridData.getSelectedRows();
          if (selectedData != null && selectedData != undefined) {
              this.apiGridData.applyTransaction({ remove: selectedData });
              let newRowData:any = [];
              this.apiGridData.forEachNode(rowNode => newRowData.push(rowNode.data));
              this.setRowData(newRowData);
          }
      }
  }
}

class Request {
   constructor(private docTypeId: String) { }
}

class AddRequest {
   constructor(private docTypeAttrId: String, private docTypeId, private attributeId: String, private defaultType: String) { }
}
class updateRequest {
   constructor(private docTypeId: String, private value: String) { }
}