import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserVariable } from '../../../util/common/user-variable';
import { AlertService } from '../../../util/alert/alert.service';
import { SharedService } from '../../../util/shared.service';
import { AllModules, GridOptions, Module } from "@ag-grid-enterprise/all-modules";
import { DocumentStoreService } from '../documentstore.service';
import { CapabilityService } from '../../../util/capability.service'
//import { BsModalRef } from 'ngx-bootstrap';
import { BsModalService } from 'ngx-bootstrap/modal';
//import { DSDeleteButtonRender} from '../../documentstore/cell-render/delete-button/delete-button.component'
import { LoadingService } from '../../../util/loading/loading.service';

@Component({
  selector: 'app-deleteddocumentstore',
  templateUrl: './deleteddocumentstore.component.html',
  styleUrls: ['./deleteddocumentstore.component.css']
})
export class DeletedDocumentStoreComponent implements OnInit {

  public isMinimized: boolean = false
   rowData: any[] = [];
   private gridApi;
   private gridColumnApi;
   columnDefs;  
   ps_translated_data: any = null;
   public frameworkComponents;
   public gridOptions: GridOptions;
   public defaultColDef: any;
   private projectId: string = UserVariable.projectId
   public modules: Module[] = AllModules;

   constructor(private documentStoreService: DocumentStoreService, private sharedService: SharedService, private translate: TranslateService, private alertService: AlertService,
      private capabilityService: CapabilityService, private modalService: BsModalService, private loadingService: LoadingService) {

      let browserLang = translate.getBrowserLang();
      this.gridOptions = <GridOptions>{
         context: {
            componentParent: this
         }
      };
      this.defaultColDef = {
			resizable: true,
			sortable: true,
		};
      translate.getTranslation(browserLang).subscribe((res: string) => {
         this.ps_translated_data = res['DOCUMENT_STORE']
         this.columnDefs = [
           
            {
               headerName: this.ps_translated_data.DOCUMENT, field: 'documentTitle', autoHeight: true, cellClass: "cell-wrap-text", filter: "agTextColumnFilter"
            },
            {
               headerName: this.ps_translated_data.SYSTEM, field: 'systemName', autoHeight: true, cellClass: "cell-wrap-text", filter: "agTextColumnFilter"
            },
            {
               headerName: this.ps_translated_data.VERSION, field: 'versionNo', autoHeight: true, cellClass: "cell-wrap-text", filter: "agTextColumnFilter"
            },
            {
               headerName: this.ps_translated_data.NOTES, field: 'notes', autoHeight: true, cellClass: "cell-wrap-text", filter: "agTextColumnFilter"
            },
            {
               headerName: this.ps_translated_data.PUBLISH_TIME, field: 'publishedTime', autoHeight: true, cellClass: "cell-wrap-text", comparator: this.dateTimeComparator,
               filter: "agDateColumnFilter",
               filterParams: {
                  comparator: function (filterLocalDateAtMidnight, cellValue) {
                     var dateAsString = cellValue.substring(0,10);
                     var cellDateTime = new Date(dateAsString);
                     var cellDate = new Date(cellDateTime.getFullYear(), cellDateTime.getMonth(), cellDateTime.getDate())
                     if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                        return 0;
                     }
                     if (cellDate < filterLocalDateAtMidnight) {
                        return -1;
                     }
                     if (cellDate > filterLocalDateAtMidnight) {
                        return 1;
                     }
                  }
               }
            },
            {
               headerName: this.ps_translated_data.FOLDER_PATH, field: 'location', autoHeight: true, cellClass: "cell-wrap-text"
            }
         ]
      });
   }

   dateTimeComparator(date1:any, date2:any) {
    let parts = date1.match(/(\d{2})-(\d{2})-(\d{4}) (\d{2}):(\d{2}):(\d{2})/);
    let date1Number = Date.UTC(+parts[3], parts[1]-1, +parts[2], +parts[4], +parts[5], +parts[6]);
    parts = date2.match(/(\d{2})-(\d{2})-(\d{4}) (\d{2}):(\d{2}):(\d{2})/);
    let date2Number = Date.UTC(+parts[3], parts[1]-1, +parts[2], +parts[4], +parts[5], +parts[6]);
  
    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;   
    }
    if (date2Number === null) {
      return 1;
    }
  
    return date1Number - date2Number;
  }

 ngOnInit() {
 }

 onColumnResized(event) {
    if (event.finished) {
       this.gridApi.resetRowHeights();
    }
 }

 onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
    this.getDeletedDocumentStoreViewData();
    setTimeout(function () {
       params.api.sizeColumnsToFit();
       params.api.resetRowHeights();
    }, 500);
 }

 getDeletedDocumentStoreViewData(){
    console.log("Line 110");
    this.loadingService.showLoading(true, null, "", null)
    this.rowData = [];
    this.documentStoreService.getDocumentStoreDeletedItems()
       .subscribe(data => {
          this.loadingService.hideLoading()
          this.rowData = data.payload;
       },
       error => {
          this.loadingService.hideLoading()
          this.alertService.clear()
          this.alertService.error(error.statusDescription)
       });
 }

}
