import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms'
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { AppComponent } from './app.component';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularSplitModule } from 'angular-split'
import { LoginComponent } from './user/login/login.component';
import { AppRoutingModule } from './/app-routing.module';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { FoldertreeComponent } from './foldertree/foldertree.component';
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { KeycodeverificationComponent } from './user/login/keycodeverification/keycodeverification.component';
import { ChangepasswordComponent } from './user/login/changepassword/changepassword.component';
import { LandingComponent } from './landing/landing.component';
import { BsModalModule } from 'ng2-bs3-modal';
import { AgGridModule } from '@ag-grid-community/angular';
import { LicenseManager } from "@ag-grid-enterprise/core";
import { ModalModule } from 'ngx-bootstrap/modal';
import { SelectModule } from 'ng2-select';
import { NgxSelectModule } from 'ngx-select-ex';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { OrderArrayByType } from './util/orderarraybytype.pipe';
import { PreventParentClickEvent } from './util/preventparentclick.directive';
import { TagInputModule } from 'ngx-chips';
//import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import { DeviceDetectorService } from 'ngx-device-detector';
import { ComHeaderComponent } from './commissioning/com-header/com-header.component'

import { TabhostComponent } from './tabhost/tabhost.component';
import { DynamictabsDirective } from './tabhost/dynamictabs.directive';
import { TabComponent } from './tabhost/tab/tab.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { FilesComponent } from './home/files/files.component';
import { FilesDataComponent } from './home/files/filesdata/filesdata.component'
import { PropertiesComponent } from './home/files/filesdata/properties/properties.component'
import { AttributesComponent } from './home/files/filesdata/attributes/attributes.component'
import { RevisionsComponent } from './home/files/filesdata/revisions/revisions.component'
import { FilesService } from './home/files/files.service';
import { FormsModule } from '@angular/forms';
import { WebeditorComponent } from './webeditor/webeditor.component';
import { CompareRevisionComponent } from './home/files/filesdata/revisions/compare-revisions.component';
import { WsCallback } from './util/ws-callback.interface';
import { WsType } from './util/ws-type';
import { DatePipe } from '@angular/common'

import { AlertComponent } from './util/alert/alert.component';
import { LoadingComponent } from './util/loading/loading.component';
import { CommonUtil } from './util/common/common-util';

//sorting
import { SortService } from './util/sortable-table/sort.service';
import { SortableTableDirective } from './util/sortable-table/sortable-table.directive';
import { SortableColumnComponent } from './util/sortable-table/sortable-column.component';

//services
import { LandingService } from './landing/landing.service';
import { AlertService } from './util/alert/alert.service';
import { LoadingService } from './util/loading/loading.service';
import { FolderService } from './foldertree/foldertree.service';
import { ComponentService } from './tabhost/component.service';
import { LocalStorage } from './util/localstorage.service';
import { Keycodeverify } from './user/login/keycodeverification/keycodeverify.service';
import { SocialLoginModule, SocialAuthServiceConfig, GoogleLoginProvider} from 'angularx-social-login';
import { Constants } from './util/constants';
import { BasicRequest } from './util/basic-request';
import { RefreshTokenInterceptor  } from './util/token-interceptor';
import { ForgotpasswordComponent } from './user/login/forgotpassword/forgotpassword.component';
import { SessionValidationService } from './user/login/session-validation-request.service';
import { ResetUrlService } from './user/login/forgotpassword/reset-url.service';
import { ReusablesService } from './reusables/reusables.service';
import { ResetPasswordValidation } from './user/login/forgotpassword/reset-password-validation.service';
import { FacilitiesService } from './reusables/facilities/facilities.service';
import { DocumentTypeService } from './util/documenttypetree/documenttype.service';
import { FilesDataService } from './home/files/filesdata/filesdata.service';
import { DependenciesModalService } from './util/dependenciesmodal/dependenciesmodal.service';
import { TagsService } from './reusables/tags/tags.service';
import { RolesService } from './systemsetting/admin-settings/user-roles/roles.service';
import { ComponentsService } from './reusables/components/components.service';
import { ProcedureService } from './procedure/procedure.service';
import { CategoryTreeService } from './util/categorytree/categorytree.service';
import { WebEditorService } from './webeditor/webeditor.service';
import { CapabilityService } from './util/capability.service';
import { TopicmapBuilderService } from './topic-map-builder/topic-map-builder.service';

//reusable components
import { ReusablesComponent } from './reusables/reusables.component';
import { TagsComponent } from './reusables/tags/tags.component';
import { StatementsComponent } from './reusables/statements/statements.component';
import { Safe } from './reusables/statements/statements.component';
import { Safe1 } from './home/files/filesdata/revisions/compare-revisions.component';
import { ComponentsComponent } from './reusables/components/components.component';
import { SystemsComponent } from './reusables/systems/systems.component';
import { HistoryComponent } from './reusables/history/history.component';
import { DocumentsComponent } from './reusables/documents/documents.component';
import { FacilitiesComponent } from './reusables/facilities/facilities.component';
import { ImagesComponent } from './reusables/images/images.component';
import { DocumenttypetreeComponent } from './util/documenttypetree/documenttypetree.component';
import { DependenciesmodalComponent } from './util/dependenciesmodal/dependenciesmodal.component';
import { PDFViewModalComponent } from './reusables/documents/pdfviewmodal.component';

//ngx-bootstrap
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ResetPassword } from './user/login/forgotpassword/reset-password.service';
import { ChangePassword } from './user/login/changepassword/change-password.service';
import { PasswordChangeStatusComponent } from './user/login/changepassword/password-change-status/password-change-status.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { UiSwitchModule } from 'ngx-ui-switch';

// import { NgcFloatButtonModule } from 'ngc-float-button';
import { RecentProject } from './landing/recent-project.service';
import { NewTopicComponent } from './home/files/new-topic/new-topic.component';
import { TestComponent } from './open-access/test/test.component';

//editor related components
import { ProcedureComponent } from './procedure/procedure.component';

import { LandingCellRenderComponent } from './landing/landing-cell-render-component.component';
import { FilesCellRenderComponent } from './home/files/files-cell-render-component.component';
import { RevisionCellRenderComponent } from './home/files/filesdata/revisions/revision-cell-render-component.component';
import { NewTopicMapComponent } from './home/files/new-topic-map/new-topic-map.component';
import { NewFileComponent } from './home/files/new-file/new-file.component';
import { NewImageComponent } from './home/files/new-image/new-image.component';
import { NewProcedureComponent } from './home/files/new-procedure/new-procedure.component';
import { NewMultipleImagesComponent } from './home/files/new-multiple-images/new-multiple-images.component';
import { EditTagsComponent } from './reusables/tags/edit-tags/edit-tags.component';
import { AddTagComponent } from './reusables/tags/add-tag/add-tag.component';
import { CustomTypeaheadComponent } from './util/custom-typeahead/custom-typeahead.component'
import { StatementsCellRenderComponent } from './reusables/statements/statements-cell-render-component.component'
import { FacilitiesCellRenderComponent } from './reusables/facilities/facilities-cell-render-component.component'
import { NewTopicService } from './home/files/new-topic/new-topic-service.service';
import { OutputGenerateModalComponent } from './home/files/outputgenerator/outputgeneratemodal.component';
import { OPGConfigInputsViewModalComponent } from './home/files/outputgenerator/ouputconfiginputsviewmodal.component'
import { ButtonRendererComponent } from './landing/button-renderer.component'

import { StatementsService } from './reusables/statements/statements.service';
import { CustomModalComponent } from './util/custommodal/custommodal.component';
import { NotificationComponent } from './header/notification/notification.component';
import { TopicMapBuilderComponent } from './topic-map-builder/topic-map-builder.component';
import { CategorytreeComponent } from './util/categorytree/categorytree.component';
import { AddeditstatementComponent } from './reusables/statements/addeditstatement/addeditstatement.component';
import { SplashComponent } from './splash/splash.component';
import { ComponentsCellRenderComponent } from './reusables/components/components-cell-render-component.component';
import { PageNotFoundComponent } from './util/page-not-found/page-not-found.component';
import { XmlmodelComponent } from './util/xmlmodel/xmlmodel.component';
import { TagCellRendererComponent } from './reusables/tags/tag-cell-renderer/tag-cell-renderer.component';
import { RoleCellRenderComponent } from './systemsetting/admin-settings/user-roles/role-cell-render-component.component';
import { AddedfacilitiesComponent } from './reusables/facilities/addedfacilities/addedfacilities.component';
import { TagDependencyComponent } from './reusables/tags/tag-dependency/tag-dependency.component';
import { SystemsCellRenderComponent } from './reusables/systems/systems-cell-render-component.component';
import { DocumentsCellRenderComponent } from './reusables/documents/documents-cell-render-component.component';
import { ClientCellRenderComponent } from './client/client-cell-render-component.component';
import { UserCellRenderComponent } from './user/user-cell-render-component.component';
import { ProjectCellRenderComponent } from './project/project-cell-render-component.component';

//system setting related componenet
import { ProcedureStoreComponent } from './systemsetting/procedurestore/procedurestore.component';
import { ProcedureStoreService } from './systemsetting/procedurestore/procedurestore.service';
import { PSDeleteButtonRender } from './systemsetting/procedurestore/cell-render/delete-button/delete-button.component'
import { DeleteModalComponent } from './systemsetting/procedurestore/deleteconfirmationmodal'
import { AddProcedureStoreComponent } from './systemsetting/procedurestore/addprocedurestore/addprocedurestore.component'
import { AddToStoreButtonRender } from './systemsetting/procedurestore/cell-render/addtostorebutton/addtostorebutton.component'
import { DeleteButtonAddedStoreRender } from './systemsetting/procedurestore/cell-render/addtostorebutton/deleteaddedstore.component'
import { AddProceduresModalComponent } from './systemsetting/procedurestore/addproceduresmodal'
import { DeletedProcedureStoreComponent } from './systemsetting/procedurestore/deletedprocedurestore/deletedprocedurestore.component'
import { ProcedureInstancesComponent } from './systemsetting/procedurestore/procedureinstances/procedureinstances.component'
import { ProcessStatusRender } from './systemsetting/procedurestore/cell-render/processstatusRender/process-statusbutton.component'
import { OutputAlertModalComponent } from './systemsetting/procedurestore/outputalertmodel.component'
import { OutputHomeComponent } from './systemsetting/outputmanagement/outputhome/outputhome.component'
import { OutputManagementService } from './systemsetting/outputmanagement/outputmanagement.service'
import { OutputHomeStatusButtonRender } from './systemsetting/outputmanagement/cell-render/outputhomerender/outputhomestatusbuttonrender.component'
import { OutputHomeCheckboxRender } from './systemsetting/outputmanagement/cell-render/outputhomerender/outputhomecheckboxrender.component'
import { OutputAddProjectTempComponent } from './systemsetting/outputmanagement/outputaddprojecttemp/outputaddprojecttemp.component'
import { ProjectConfigRender } from './systemsetting/outputmanagement/cell-render/addprojecttemplaterender/projectconfigrender.component'
import { EditableDocConfigRender } from './systemsetting/outputmanagement/cell-render/addprojecttemplaterender/editabledocconfigrender.component'
import { VisibleDocConfigRender } from './systemsetting/outputmanagement/cell-render/addprojecttemplaterender/visibledocconfigrender.component'
import { AddDocConfigRender } from './systemsetting/outputmanagement/cell-render/addprojecttemplaterender/adddocconfigrender.component'
import { AddProjectTempModalComponent } from './systemsetting/outputmanagement/outputviewmodal/addprojecttempmodal'
import { DeleteDocAttributeTypeModalComponent } from './systemsetting/admin-settings/document-types/confirmation-modal/deleteconfirmationmodal'
import { DTStatusButtonRenderer } from './systemsetting/admin-settings/document-types/cell-renderer/status-button/status-button-components'
import { DTDotnotaionRenderer } from './systemsetting/admin-settings/document-types/cell-renderer/dotnotation-button/dotnotation-button.component'
import { DTADeleteButtonRenderer } from './systemsetting/admin-settings/document-types/document-attributes/cell-renderer/delete-button/delete-button.component'
import { DTAEditButtonRenderer } from './systemsetting/admin-settings/document-types/document-attributes/cell-renderer/edit-button/edit-button.component'
import { DTADependencyButtonRenderer } from './systemsetting/admin-settings/document-types/document-attributes/cell-renderer/dependency-button/dependency-button.component'
import { EditDocAttributeTypeModalComponent } from './systemsetting/admin-settings/document-types/document-attributes/cell-renderer/edit-button/edit-modal-view.components'
import { AddDocAttributeTypeModalComponent } from './systemsetting/admin-settings/document-types/document-attributes/add-attribute-modal.component'

import { DictionaryComponent } from './systemsetting/dictionary/dictionary.component';
import { AddwordtodictionaryComponent } from './systemsetting/dictionary/addwordtodictionary/addwordtodictionary.component';
import { DictionaryService } from './systemsetting/dictionary/dictionary.service';
import { DictionaryCellRenderComponent } from './systemsetting/dictionary/dictionary-cell-render/dictionary-cell-render.component';
import { DeletewordComponent } from './systemsetting/dictionary/deleteword/deleteword.component';

//import { OptionalDataCellEditor } from './systemsetting/outputmanagement/cell-render/addprojecttemplaterender/optionaldatacelleditor.component'
import { OMAddTemplateComponent } from './systemsetting/outputmanagement/outputaddprojecttemp/addtemplate/addtemplate.component'
import { AddTemplateRender } from './systemsetting/outputmanagement/cell-render/addprojecttemplaterender/addtemplate/selecttemprender.component'
import { AddTemplateModalComponent } from './systemsetting/outputmanagement/outputviewmodal/addtemplatemodal'
import { OutputQueueComponent } from './systemsetting/outputmanagement/outputqueue/outputqueue.component'
import { OutputConfigComponent } from './systemsetting/outputmanagement/outputconfig/outputconfig.component'
import { OMEditOutputConfigRender } from './systemsetting/outputmanagement/cell-render/outputconfigrender/editbuttoncellrender.component'
import { EditOutputConfigModalComponent } from './systemsetting/outputmanagement/outputviewmodal/editoutputconfigmodal'
import { GetMoreOutputInfoModalComponent } from './systemsetting/outputmanagement/outputviewmodal/getmoreoutputinfomodal'

//symbiosis search
import { SymbiosisSearch } from './util/symbiosis-search/symbiosis-search.component'
import { SearchFormItemComponent } from './util/symbiosis-search/search-form/search-form-item.component'
import { SearchFormComponent } from './util/symbiosis-search/search-form/search-form.component'
import { SymbiosisSearchService } from './util/symbiosis-search/symbiosis-search.service'

import { TopicMapFolderList } from './topic-map-builder/topic-map-folder-list/topic-map-folder-list.component';
import { ImageAddComponent } from './reusables/images/image-add/image-add.component';
import { ImageSearchComponent } from './reusables/images/image-search/image-search.component';
import { AttributeSelectComponent } from './reusables/tags/attribute-select/attribute-select.component';
import { SelectCategoryTreeComponent } from './util/select-category-tree/select-category-tree.component';
import { SelectDocTypeTreeComponent } from './util/select-doc-type-tree/select-doc-type-tree.component';
import { AddeditdocumentsComponent } from './reusables/documents/addeditdocuments/addeditdocuments.component';
import { AddeditsystemsComponent } from './reusables/systems/addeditsystems/addeditsystems.component';
import { NgxTimezonesModule } from 'ngx-timezones';
import { ProfileComponent } from './header/profile/profile.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AgGridCheckboxComponent } from './home/files/ag-grid-checkbox/ag-grid-checkbox.component';
import { AddeditcomponentsComponent } from './reusables/components/addeditcomponents/addeditcomponents.component';
import { TagValueListComponent } from './reusables/tags/tag-value-list/tag-value-list.component';
import { TagClassificationComponent } from './reusables/tags/tag-classification/tag-classification.component';
import { ClassificationCellRenderComponent } from './reusables/tags/classification-cell-render/classification-cell-render.component';
import { AttributeTypeRenderComponent } from './reusables/tags/tag-classification/attribute-type-render/attribute-type-render.component';
import { AdminSettingsComponent } from './systemsetting/admin-settings/admin-settings.component';
import { ClientComponent } from './client/client.component';
import { UserComponent } from './user/user.component';
import { DotNotationRenderComponent } from './reusables/tags/tag-classification/dot-notation-render/dot-notation-render.component';
import { DependencyModalComponent } from './reusables/tags/tag-classification/dependency-modal/dependency-modal.component';
import { ConfirmModalComponent } from './reusables/tags/tag-classification/confirm-modal/confirm-modal.component';
import { DocumentTypesComponent } from './systemsetting/admin-settings/document-types/document-types.component';
import { DocumentAttributesComponent } from './systemsetting/admin-settings/document-types/document-attributes/document-attributes.component';
import { DTDeleteButtonRenderer } from './systemsetting/admin-settings/document-types/cell-renderer/delete-button/delete-button.component';
import { DTAddButtonRenderer } from './systemsetting/admin-settings/document-types/cell-renderer/add-button/add-button.component';
import { DTEditButtonRenderer } from './systemsetting/admin-settings/document-types/cell-renderer/edit-button/edit-button.component';
import { DTDependencyButtonRenderer } from './systemsetting/admin-settings/document-types/cell-renderer/dependency-button/dependency-button.component';
import { PTDeleteButtonRenderer } from './systemsetting/outputmanagement/outputaddprojecttemp/delete-button/delete-button.component';
import { ManageAttributesComponent } from './reusables/tags/manage-attributes/manage-attributes.component';
import { DeleteCellRenderComponent } from './reusables/tags/manage-attributes/delete-cell-render/delete-cell-render.component';
import { DependencyCellRendererComponent } from './reusables/tags/manage-attributes/dependency-cell-render/dependency-cell-render.component';
import { EditCellRenderComponent } from './reusables/tags/manage-attributes/edit-cell-render/edit-cell-render.component';
import { DeleteCellRendererComponent } from './reusables/tags/delete-cell-renderer/delete-cell-renderer.component';
import { ConfirmationModalComponent } from './reusables/tags/confirmation-modal/confirmation-modal.component';
import { DependencyCellRenderComponent } from './reusables/tags/dependency-cell-render/dependency-cell-render.component';
import { ReportsComponent } from './reports/reports.component';
import { ReportsSidebarComponent } from './reports/reports-sidebar/reports-sidebar.component';
import { NumberWidgetComponent } from './reports/number-widget/number-widget.component';
import { ReusableupdateComponent } from './reusables/reusableupdate/reusableupdate.component';
import { ViewreportdataComponent } from './reports/viewreportdata/viewreportdata.component';
//import { ReusabletypedropdownComponent } from './reusables/reusabletypedropdown/reusabletypedropdown.component';
import { ReusableUpdateService } from './reusables/reusableupdate/reusableupdateservice';
import { ReusableUpdateCellRenderComponent } from './reusables/reusableupdate/reusableupdate-cell-render-component.component';
import { AddeditclientComponent } from './client/addeditclient/addeditclient.component';
//import { AddedituserComponent } from './user/addedituser/addedituser.component';
import { AdduserComponent } from './user/adduser/adduser.component';
import { EdituserComponent } from './user/edituser/edituser.component';
import { NewCourseComponent } from './home/files/new-course/new-course.component';
import { IceCourseComponent } from './ice-course/ice-course.component';
import { IceTopicComponent } from './ice-course/ice-topic/ice-topic.component';
import { ProjectComponent } from './project/project.component';
import { AddProjectComponent } from './project/add-project/add-project.component';
import { EditProjectComponent } from './project/edit-project/edit-project.component';
import { IceLearningCheckComponent } from './ice-course/ice-learning-check/ice-learning-check.component';
import { IceGameComponent } from './ice-course/ice-game/ice-game.component';
import { ManualFocusDirective } from './util/manual-focus.directive';
import { EinsteinGameComponent } from './ice-course/ice-game/einstein-game/einstein-game.component';
import { MemoryGameComponent } from './ice-course/ice-game/memory-game/memory-game.component';
import { SpeedMatchGameComponent } from './ice-course/ice-game/speed-match-game/speed-match-game.component';
import { LimitToDirective } from './util/limit-to.directive';
import { IceCourseLoaderComponent } from './ice-course/ice-course-loader/ice-course-loader.component';
import { DynamicIceCourseItemDirective } from './ice-course/ice-course-loader/dynamic-ice-course-item.directive';
import { IceCourseLoaderService } from './ice-course/ice-course-loader/ice-course-loader.service';
import { OmSidebarComponent } from './systemsetting/outputmanagement/om-sidebar/om-sidebar.component';
//import { AddeditclientComponent } from './client/addeditclient/addeditclient/addeditclient.component';
import { OutputManagementComponent } from './systemsetting/outputmanagement/outputmanagement.component';
import { OMLoaderService } from './systemsetting/outputmanagement/output-loader.service';
import { DynamicOmItemDirective } from './systemsetting/outputmanagement/dynamic-om-item.directive';
import { PsSidebarComponent } from './systemsetting/procedurestore/ps-sidebar/ps-sidebar.component';
import { PsLoaderComponent } from './systemsetting/procedurestore/ps-loader/ps-loader.component';
import { DictionarySidebarComponent } from './systemsetting/dictionary/dictionary-sidebar/dictionary-sidebar.component';
import { DictionaryLoaderComponent } from './systemsetting/dictionary/dictionary-loader/dictionary-loader.component';
import { AsSidebarComponent } from './systemsetting/admin-settings/as-sidebar/as-sidebar.component';
import { ImageGridComponent } from './reports/image-grid/image-grid.component';
import { IceTopicContentComponent } from './ice-course/ice-topic-content/ice-topic-content.component';
import { CategorySettingComponent } from './systemsetting/admin-settings/category-setting/category-setting.component';
import { ReportImageCellRenderComponent } from './reports/image-grid/report-image-cell-render/report-image-cell-render.component';
import { RuLoaderComponent } from './reusables/reusableupdate/ru-loader/ru-loader.component';
import { RuSidebarComponent } from './reusables/reusableupdate/ru-sidebar/ru-sidebar.component';
import { NgcFloatBtnModule } from './util/ngc-float-btn/ngc-float-btn.module';
import { Ng2SelectModule } from './util/ng2-select/ng2-select.module';
import { NewVideoComponent } from './home/files/new-video/new-video.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ViewmodelComponent } from './util/viewmodel/viewmodel.component';
import { SafeViewModal } from './util/viewmodel/viewmodel.component';
import { NewThreedModelComponent } from './home/files/new-threed-model/new-threed-model.component';
import { ThreedModelViewerComponent } from './util/threed-model-viewer/threed-model-viewer.component';
import { CommissioningComponent } from './commissioning/commissioning.component';
import { SidebarComponent } from './commissioning/sidebar/sidebar.component';
import { PunchlistComponent } from './commissioning/punchlist/punchlist.component';
import { AddpunchlistComponent } from './commissioning/punchlist/addpunchlist/addpunchlist.component';
import { EditpunchlistComponent } from './commissioning/punchlist/editpunchlist/editpunchlist.component';
import { DecodeHtmlString } from './util/common/decode-html-string.pipe';
import { PunchlistsCellRenderComponent } from './commissioning/punchlist/punchlists-cell-render-component.component';
import { PunchListDocumentIconRenderer } from './commissioning/punchlist/punchlist-documents-cell-renderer.component';
import { ThreedModelComponent } from './reusables/threed-model/threed-model.component';
import { VideosComponent } from './reusables/videos/videos.component';
import { UserRolesComponent } from './systemsetting/admin-settings/user-roles/user-roles.component';
import { AssignUserComponent } from './systemsetting/admin-settings/user-roles/assign-user/assign-user.component';
import { ChangeCapabilitiesComponent } from './systemsetting/admin-settings/user-roles/change-capabilities/change-capabilities.component';
import { RoleConfirmationModalComponent } from './systemsetting/admin-settings/user-roles/role-confirmation-modal/role-confirmation-modal.component';
import { MomentModule } from 'ngx-moment';
import { IceCoursePreviewComponent } from './ice-course/ice-course-preview/ice-course-preview.component';
import { CoursePreviewButtonRenderer } from './ice-course/ice-course-preview/course-preview-button-renderer';
import { PdfToProcedurestoreComponent } from './home/files/outputgenerator/pdf-to-procedurestore/pdf-to-procedurestore.component';
import { FileDownloadComponent } from './util/file-download/file-download.component';
import { TagsSystemsHierarchyComponent } from './reports/tags-systems-hierarchy/tags-systems-hierarchy.component';

import {CdkTreeModule} from '@angular/cdk/tree';
import {MatTreeModule} from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import { IrnComponent } from './commissioning/irn/irn.component';
import { AddeditirnComponent } from './commissioning/irn/addeditirn/addeditirn.component';
import {IrnCellRenderComponent} from "./commissioning/irn/irn-cell-render-component.component";
import {IrnPunchlistsCellRenderer} from "./commissioning/irn/addeditirn/irn-punchlists-cell-renderer.component";
import { TaginputitemComponent } from './util/taginputitem/taginputitem.component';
import {IrnAssetsCellRenderer} from "./commissioning/irn/irn-assets-cell-renderer.component";
import { InsertReferenceComponent } from './insert-reference/insert-reference.component';
import {ManagemccComponent} from "./commissioning/managemcc/managemcc.component";
import {EditmccComponent} from "./commissioning/managemcc/editmcc/editmcc.component";
import {ApprovemccComponent} from "./commissioning/managemcc/approvemcc/approvemcc.component";
import {ManageMCCCellRenderComponent} from "./commissioning/managemcc/managemcc-cell-render-component.component";
import { Auth0Component } from './user/login/auth0/auth0.component';
import { DocumentStoreComponent } from './systemsetting/documentstore/documentstore.component';
import { DsLoaderComponent } from './systemsetting/documentstore/ds-loader/ds-loader.component';
import { DsSidebarComponent } from './systemsetting/documentstore/ds-sidebar/ds-sidebar.component';
import { AddDocumentStoreComponent } from './systemsetting/documentstore/adddocumentstore/adddocumentstore.component';
import { DeletedDocumentStoreComponent } from './systemsetting/documentstore/deleteddocumentstore/deleteddocumentstore.component';
import { ProcedureInstancesDSComponent } from './systemsetting/documentstore/procedureinstances/procedureinstances.component';
import { DSDeleteButtonRender } from './systemsetting/documentstore/cell-render/delete-button/delete-button.component';
import { FacilitiesCellRender } from './systemsetting/documentstore/cell-render/add-facilities-button/facilitiescellrender.component';
import { AddFacilitiesModalComponent } from './systemsetting/documentstore/addfacilitiesmodal';
import { DeleteDocumentStoreModalComponent } from './systemsetting/documentstore/deleteconfirmationmodal';
import { AddStoreDocumentModalComponent } from './systemsetting/documentstore/addstoredocumentmodal';
import { AddToDocumentStoreButtonRender } from './systemsetting/documentstore/cell-render/addtostorebutton/addtostorebutton.component';
import { DeleteButtonAddedDocumentStoreRender } from './systemsetting/documentstore/cell-render/addtostorebutton/deleteaddedstore.component';

import { OptDataComponent } from './opt-data/opt-data.component';
//import { ConfirmationModComponent } from './opt-data/confirmation-mod/confirmation-mod.component';
import { EditCellComponent } from './opt-data/edit-cell/edit-cell.component';
import { DeleteCellComponent } from './opt-data/delete-cell/delete-cell.component';
import { ConfirmModelComponent } from './opt-data/confirm-model/confirm-model.component';
import { DependencyCellComponent } from './opt-data/dependency-cell/dependency-cell.component';
import { DependencyModComponent } from './opt-data/dependency-mod/dependency-mod.component';
import { StatusRenderComponent } from './systemsetting/documentstore/cell-render/status-render/status-render.component';
import { AlertModelComponent } from './systemsetting/documentstore/alert-model.component';
import { ManageotnComponent } from './commissioning/manageotn/manageotn.component';
import { AddeditotnComponent } from './commissioning/manageotn/addeditotn/addeditotn.component';
import {ManageOTNCellRenderComponent} from "./commissioning/manageotn/manageotn-cell-render-component.component";
import { ApproveotnComponent } from './commissioning/manageotn/approveotn/approveotn.component';
import { PunchListImageIconRenderer } from './commissioning/punchlist/image-icon/image-icon.component';
import { ManagePunchlistImagesComponent } from './systemsetting/admin-settings/manage-punchlist-images/manage-punchlist-images.component';
import { ManageWorkflowTemplatesComponent } from './systemsetting/admin-settings/manage-workflow-templates/manage-workflow-templates.component';
import { AddEditWorkflowTemplateComponent } from './systemsetting/admin-settings/manage-workflow-templates/add-edit-workflow-template/add-edit-workflow-template.component';
import { WorkflowTemplatesComponent } from './systemsetting/workflow/workfowtemplates/workflow-templates.component';
import { WorkflowComponent } from './systemsetting/workflow/workflow.component';
import { WfSidebarComponent } from './systemsetting/workflow/wf-sidebar/wf-sidebar.component';
import { WorkflowService } from './systemsetting/workflow/workflow.service';
import { AddProjectWorkflowTemplateComponent } from './systemsetting/workflow/addprojectworkflowtemplate/add-project-workflow-template.component';
import { SelectProjectWorkflowTemplateCellRenderer } from './systemsetting/workflow/cell-renderer/addworkflowtemplate/select-workflow-template-renderer.component';
import { ConfigureWorkflowTemplateComponent } from './systemsetting/workflow/workflowtemplateviewmodal/configure-workflow.component';

import { ManageAdhocReportsComponent } from './reports/manage-adhoc-reports/manage-adhoc-reports.component';
import { AddAdhocReportComponent } from './reports/manage-adhoc-reports/add-adhoc-report/add-adhoc-report.component';
import { ManageAdhocReportDeleteCellComponent } from './reports/manage-adhoc-reports/cell-render/delete-cell.component';
import { OutputHomeAlertModelComponent } from './systemsetting/outputmanagement/outputhome/outputhome-alert-model.component';
import { ManagechecksheetsComponent } from './commissioning/checksheets/managechecksheets/managechecksheets.component';
import { ManagecheckitemsComponent } from './commissioning/checksheets/managecheckitems/managecheckitems.component';
import { ChecksheetsCellRenderComponentComponent } from './commissioning/checksheets/checksheets-cell-render-component/checksheets-cell-render-component.component';
import { AddeditchecksheetComponent } from './commissioning/checksheets/managechecksheets/addeditchecksheet/addeditchecksheet.component';
import { AssignchecksComponent } from './commissioning/checksheets/managechecksheets/assignchecks/assignchecks.component';
import { AssignassetsComponent } from './commissioning/checksheets/managechecksheets/assignassets/assignassets.component';
import { AssignsystemsComponent } from './commissioning/checksheets/managechecksheets/assignsystems/assignsystems.component';
import { PunchlistDocuSignCellRendererComponent } from './commissioning/punchlist/punchlist-docu-sign-cell-renderer/punchlist-docu-sign-cell-renderer.component';
import { DocuSignPersonStatusComponent } from './commissioning/punchlist/docu-sign-person-status/docu-sign-person-status.component';
import { OTNDocuSignCellRendererComponent } from './commissioning/manageotn/otndocu-sign-cell-renderer/otndocu-sign-cell-renderer.component';
import { MCCDocuSignCellRendererComponent } from './commissioning/managemcc/mccdocu-sign-cell-renderer/mccdocu-sign-cell-renderer.component';
import { WfViewTasksCellRendererComponent } from './systemsetting/workflow/cell-renderer/viewtasks/wf-view-tasks-cell-renderer.component';
import { AddEditCheckComponent } from './commissioning/checksheets/managecheckitems/addeditcheck/addeditcheck.component';
import { CKEditorModule } from 'ckeditor4-angular';
import { IssueChecksheetsComponent } from './commissioning/checksheets/issuechecksheets/issuechecksheets.component';
import { ReceiveChecksheetsComponent } from './commissioning/checksheets/receivechecksheets/receivechecksheets.component';
import { ApproveCheckSheetComponent } from './commissioning/checksheets/receivechecksheets/approvechecksheet/approvechecksheet.component';
import { ChecksheetDeleteCellRendererComponent } from './commissioning/checksheets/issuechecksheets/cell-renderer/delete-cell-renderer.component';
LicenseManager.setLicenseKey("CompanyName=Axis, Limited Liability Company,LicensedApplication=Symbiosis,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-035875,SupportServicesEnd=3_February_2024_[v2]_MTcwNjkxODQwMDAwMA==3c85180400952f3918b7472308cb04a7");
import { ManagePCCComponent } from './commissioning/managepcc/managepcc.component';
import { AddEditPCCComponent } from './commissioning/managepcc/addeditpcc/addeditpcc.component';
import { ApprovepccComponent } from './commissioning/managepcc/approvepcc/approvepcc.component';
import { PCCDocusignCellRendererComponent } from './commissioning/managepcc/pcc-docusign-cell-renderer/pcc-docusign-cell-renderer.component';

export function HttpLoaderFactory(HttpClient: HttpClient) {
	return new TranslateHttpLoader(HttpClient, "i18n/", ".json");
}

/*export function getAuthServiceConfigs() {
	let config = new SocialAuthServiceConfig(
		[
			{
				id: GoogleLoginProvider.PROVIDER_ID,
				provider: new GoogleLoginProvider(localStorage.getItem(LocalStorage.GOOGLE_CLIENT_ID))
			}
		]
	);
	return config;
}*/

@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
		HomeComponent,
		HeaderComponent,
		ComHeaderComponent,
		FoldertreeComponent,
		KeycodeverificationComponent,
		ChangepasswordComponent,
		LandingComponent,
		TabhostComponent,
		DynamictabsDirective,
		TabComponent,
		FilesComponent,
		FilesDataComponent,
		PropertiesComponent,
		AttributesComponent,
		RevisionsComponent,
		WebeditorComponent,
		AlertComponent,
		LoadingComponent,
		SortableTableDirective,
		SortableColumnComponent,
		ForgotpasswordComponent,
		ReusablesComponent,
		TagsComponent,
		StatementsComponent,
		ComponentsComponent,
		SystemsComponent,
		DocumentsComponent,
		FacilitiesComponent,
		ImagesComponent,
		LandingCellRenderComponent,
		FilesCellRenderComponent,
		RevisionCellRenderComponent,
		PasswordChangeStatusComponent,
		DocumenttypetreeComponent,
		DependenciesmodalComponent,
		NewTopicComponent,
		ProcedureComponent,
		TestComponent,
		NewTopicMapComponent,
		NewFileComponent,
		NewImageComponent,
		NewProcedureComponent,
		NewMultipleImagesComponent,
		EditTagsComponent,
		AddTagComponent,
		CustomTypeaheadComponent,
		SymbiosisSearch,
		SearchFormItemComponent,
		SearchFormComponent,
		StatementsCellRenderComponent,
		CustomModalComponent,
		NotificationComponent,
		TopicMapBuilderComponent,
		CategorytreeComponent,
		AddeditstatementComponent,
		OrderArrayByType,
		PreventParentClickEvent,
		SplashComponent,
		ComponentsCellRenderComponent,
		PageNotFoundComponent,
		HistoryComponent,
		XmlmodelComponent,
		RoleCellRenderComponent,
		TagCellRendererComponent,
		TagDependencyComponent,
		AddedfacilitiesComponent,
		SystemsCellRenderComponent,
		DocumentsCellRenderComponent,
		FacilitiesCellRenderComponent,
		ProcedureStoreComponent,
		TopicMapFolderList,
		ImageAddComponent,
		ProcessStatusRender,
		ImageSearchComponent,
		PSDeleteButtonRender,
		DeleteModalComponent,
		AddProcedureStoreComponent,
		AddToStoreButtonRender,
		DeleteButtonAddedStoreRender,
		AddProceduresModalComponent,
		DeletedProcedureStoreComponent,
		ProcedureInstancesComponent,
		ProcessStatusRender,
		OutputAlertModalComponent,
		AddeditdocumentsComponent,
		AddeditsystemsComponent,
		AttributeSelectComponent,
		TopicMapBuilderComponent,
		OutputHomeComponent,
		ProfileComponent,
		OutputHomeStatusButtonRender,
		SelectCategoryTreeComponent,
		SelectDocTypeTreeComponent,
		AgGridCheckboxComponent,
		OutputHomeCheckboxRender,
		OutputAddProjectTempComponent,
		ProjectConfigRender,
		EditableDocConfigRender,
		VisibleDocConfigRender,
		AddDocConfigRender,
		AddProjectTempModalComponent,
		AddeditcomponentsComponent,
		TagValueListComponent,
		TagClassificationComponent,
		ClassificationCellRenderComponent,
		OMAddTemplateComponent,
		AddTemplateRender,
		AddTemplateModalComponent,
		AdminSettingsComponent,
		DeleteDocAttributeTypeModalComponent,
		DTStatusButtonRenderer,
		ClientComponent,
		UserComponent,
		ClientCellRenderComponent,
		UserCellRenderComponent,
		ProjectCellRenderComponent,
		OutputQueueComponent,
		OutputConfigComponent,
		OMEditOutputConfigRender,
		EditOutputConfigModalComponent,
		DotNotationRenderComponent,
		GetMoreOutputInfoModalComponent,
		AttributeTypeRenderComponent,
		OMAddTemplateComponent,
		AddTemplateRender,
		AddTemplateModalComponent,
		AdminSettingsComponent,
		DocumentTypesComponent,
		DocumentAttributesComponent,
		DTDeleteButtonRenderer,
		DTAddButtonRenderer,
		DTEditButtonRenderer,
		DTDependencyButtonRenderer,
       	DTADeleteButtonRenderer,
		DTAEditButtonRenderer,
		DTADependencyButtonRenderer,
		PTDeleteButtonRenderer,
		EditDocAttributeTypeModalComponent,
		AddDocAttributeTypeModalComponent,
      	DTDotnotaionRenderer,
		DictionaryComponent,
		AddwordtodictionaryComponent,
		DictionaryCellRenderComponent,
		DeletewordComponent,
		DependencyModalComponent,
		ConfirmModalComponent,
		ManageAttributesComponent,
		DeleteCellRenderComponent,
		DependencyCellRendererComponent,
		EditCellRenderComponent,
		DeleteCellRendererComponent,
		ConfirmationModalComponent,
		RoleConfirmationModalComponent,
		DependencyCellRenderComponent,
		OutputGenerateModalComponent,
		OPGConfigInputsViewModalComponent,
		ReportsComponent,
		ViewreportdataComponent,
		ReportsSidebarComponent,
		NumberWidgetComponent,
		ReusableupdateComponent,
		ReusableUpdateCellRenderComponent,
		AddeditclientComponent,
		//AddedituserComponent,
		AdduserComponent,
		EdituserComponent,
		NewCourseComponent,
		IceCourseComponent,
		IceTopicComponent,
		IceTopicContentComponent,
		EdituserComponent,
		ProjectComponent,
		AddProjectComponent,
		EditProjectComponent,
		PDFViewModalComponent,
		IceLearningCheckComponent,
		IceGameComponent,
		ManualFocusDirective,
		EinsteinGameComponent,
		MemoryGameComponent,
		SpeedMatchGameComponent,
		LimitToDirective,
		IceCourseLoaderComponent,
		DynamicIceCourseItemDirective,
		OmSidebarComponent,
		OutputManagementComponent,
		DynamicOmItemDirective,
		PsSidebarComponent,
		PsLoaderComponent,
		DictionarySidebarComponent,
		AsSidebarComponent,
		DictionaryLoaderComponent,
		ImageGridComponent,
		CategorySettingComponent,
		ReportImageCellRenderComponent,
		RuLoaderComponent,
		RuSidebarComponent,
		NewVideoComponent,
		ViewmodelComponent,
		CompareRevisionComponent,
		Safe,
		Safe1,
		SafeViewModal,
		NewThreedModelComponent,
		ThreedModelViewerComponent,
		ButtonRendererComponent,
		CommissioningComponent,
		SidebarComponent,
		PunchlistComponent,
		AddpunchlistComponent,
		DecodeHtmlString,
		PunchlistsCellRenderComponent,
		EditpunchlistComponent,
		PunchListDocumentIconRenderer,
		ThreedModelComponent,
		VideosComponent,
		UserRolesComponent,
		AssignUserComponent,
		ChangeCapabilitiesComponent,
		IceCoursePreviewComponent,
		CoursePreviewButtonRenderer,
		PdfToProcedurestoreComponent,
		FileDownloadComponent,
		TagsSystemsHierarchyComponent,
		IrnComponent,
		AddeditirnComponent,
		IrnCellRenderComponent,
		IrnPunchlistsCellRenderer,
		TaginputitemComponent,
		IrnAssetsCellRenderer,
		InsertReferenceComponent,
		ManagemccComponent,
		EditmccComponent,
		ApprovemccComponent,
		ManageMCCCellRenderComponent,
		Auth0Component,
		DocumentStoreComponent,
		DsLoaderComponent,
		DsSidebarComponent,
		AddDocumentStoreComponent,
		DeletedDocumentStoreComponent,
		ProcedureInstancesDSComponent,
		DSDeleteButtonRender,
		FacilitiesCellRender,
		AddFacilitiesModalComponent,
		DeleteDocumentStoreModalComponent,
		AddStoreDocumentModalComponent,
		AddToDocumentStoreButtonRender,
		DeleteButtonAddedDocumentStoreRender,
		OptDataComponent,
		EditCellComponent,
		DeleteCellComponent,
		ConfirmModelComponent,
		DependencyCellComponent,
		DependencyModComponent,
		StatusRenderComponent,
		AlertModelComponent,
  		ManageotnComponent,
    	AddeditotnComponent,
		ManageOTNCellRenderComponent,
  		ApproveotnComponent,
		PunchListImageIconRenderer,
		ManagePunchlistImagesComponent,
		ManageWorkflowTemplatesComponent,
		AddEditWorkflowTemplateComponent,
		WorkflowTemplatesComponent,
		WorkflowComponent,
		WfSidebarComponent,
		AddProjectWorkflowTemplateComponent,
		SelectProjectWorkflowTemplateCellRenderer,
		ConfigureWorkflowTemplateComponent,
		ManageAdhocReportsComponent,
		AddAdhocReportComponent,
		ManageAdhocReportDeleteCellComponent,
		OutputHomeAlertModelComponent,
		ManagechecksheetsComponent,
		ManagecheckitemsComponent,
		ChecksheetsCellRenderComponentComponent,
		AddeditchecksheetComponent,
		AssignchecksComponent,
  		AssignassetsComponent,
    AssignsystemsComponent,
  		PunchlistDocuSignCellRendererComponent,
  		DocuSignPersonStatusComponent,
    OTNDocuSignCellRendererComponent,
	MCCDocuSignCellRendererComponent,
	WfViewTasksCellRendererComponent,
	AddEditCheckComponent,
	IssueChecksheetsComponent,
	ReceiveChecksheetsComponent,
	ApproveCheckSheetComponent,
	ChecksheetDeleteCellRendererComponent,
	ManagePCCComponent,
	AddEditPCCComponent,
	ApprovepccComponent,
	PCCDocusignCellRendererComponent
	],
	imports: [
		BrowserModule,
		ReactiveFormsModule,
		BsModalModule,
		// NgcFloatButtonModule,
		NgcFloatBtnModule,
		BrowserAnimationsModule,
		PopoverModule.forRoot(),
		ModalModule.forRoot(),
		// NgbModule.forRoot(),
		TooltipModule.forRoot(),
		AgGridModule.withComponents([LandingCellRenderComponent, FilesCellRenderComponent, RevisionCellRenderComponent, StatementsCellRenderComponent,
			ComponentsCellRenderComponent, TagCellRendererComponent, SystemsCellRenderComponent, DocumentsCellRenderComponent, FacilitiesCellRenderComponent,
			ClassificationCellRenderComponent, AttributeTypeRenderComponent, DotNotationRenderComponent, EditCellRenderComponent, DeleteCellRenderComponent,
			DeleteCellRendererComponent, DependencyCellRenderComponent, PunchlistsCellRenderComponent, RoleCellRenderComponent,IrnCellRenderComponent,IrnPunchlistsCellRenderer,
			IrnAssetsCellRenderer, DependencyCellRendererComponent,ManageMCCCellRenderComponent, EditCellComponent, DeleteCellComponent, DependencyCellComponent,
			ManageOTNCellRenderComponent, PunchListImageIconRenderer, ManageAdhocReportDeleteCellComponent
		]),
		AgGridModule.withComponents([ButtonRendererComponent]),
		AppRoutingModule,
		PaginationModule.forRoot(),
		HttpClientModule,
		AngularSplitModule,
		BsDropdownModule.forRoot(),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
		FormsModule,
		SocialLoginModule,
		AgGridModule.withComponents([]),
		TypeaheadModule.forRoot(),
		// SelectModule,
		Ng2SelectModule,
		SelectDropDownModule,
		TagInputModule,
		BrowserAnimationsModule,
		NgxTimezonesModule,
		BsDatepickerModule.forRoot(),
		TabsModule.forRoot(),
		//DeviceDetectorService.forRoot(),
		UiSwitchModule.forRoot({
			size: 'small',
			color: 'rgb(77, 161, 255)',
			switchColor: '#4DA1FF',
			defaultBgColor: '#F5F5F5',
			defaultBoColor : '#F5F5F5',
			checkedLabel: '',
			uncheckedLabel: ''
		  }),
		MomentModule,
		NgxSelectModule,
		CdkTreeModule,
		MatTreeModule,
		MatIconModule,
		MatButtonModule,
		CKEditorModule
	],
	providers: [
		LandingService,
		FilesService,
		AlertService,
		//LoadingService,
		FolderService,
		ComponentService,
		WsType,
		LocalStorage,
		//Keycodeverify,
		SessionValidationService,
		SortService,
		//ResetUrlService,
		//ResetPasswordValidation,
		//ResetPassword,
		DocumentTypeService,
		ChangePassword,
		{
			provide: 'SocialAuthServiceConfig',
			//useFactory: getAuthServiceConfigs
			useValue: {
				autoLogin: false,
				providers: [
				  {
					id: GoogleLoginProvider.PROVIDER_ID,
					provider: new GoogleLoginProvider(localStorage.getItem(LocalStorage.GOOGLE_CLIENT_ID))
				  }
				]
			  } as SocialAuthServiceConfig,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: BasicRequest,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: RefreshTokenInterceptor,
			multi: true
		  },
		ReusablesService,
		FacilitiesService,
		//RecentProject,
		FilesDataService,
		DependenciesModalService,
		TagsService,
		RolesService,
		ComponentsService,
		StatementsService,
		DictionaryService,
		NewTopicService,
		ProcedureService,
		CategoryTreeService,
		OrderArrayByType,
		WebEditorService,
		ProcedureStoreService,
		CapabilityService,
		SymbiosisSearchService,
		TopicmapBuilderService,
		OutputManagementService,
		ReusableUpdateService,
		DatePipe,
		IceCourseLoaderService,
		OMLoaderService,
		DecodeHtmlString,
		CommonUtil,
		AsSidebarComponent,
		WorkflowService
	],
	bootstrap: [AppComponent],
	/*entryComponents: [
		TabComponent,
		WebeditorComponent,
		CompareRevisionComponent,
		FilesComponent,
		PropertiesComponent,
		AttributesComponent,
		RevisionsComponent,
		TagsComponent,
		StatementsComponent,
		ComponentsComponent,
		SystemsComponent,
		DocumentsComponent,
		FacilitiesComponent,
		ImagesComponent,
		NewVideoComponent,
		DocumenttypetreeComponent,
		DependenciesmodalComponent,
		ProcedureComponent,
		NewTopicComponent,
		NewTopicMapComponent,
		NewProcedureComponent,
		NewMultipleImagesComponent,
		NewImageComponent,
		NewFileComponent,
		FoldertreeComponent,
		CategorytreeComponent,
		TopicMapBuilderComponent,
		HistoryComponent,
		XmlmodelComponent,
		TagCellRendererComponent,
		RoleCellRenderComponent,
		TagDependencyComponent,
		SystemsCellRenderComponent,
		DocumentsCellRenderComponent,
		FacilitiesCellRenderComponent,
		SearchFormComponent,
		SearchFormItemComponent,
		TopicMapFolderList,
		PSDeleteButtonRender,
		DeleteModalComponent,
		AddToStoreButtonRender,
		DeleteButtonAddedStoreRender,
		AddProceduresModalComponent,
		ProcessStatusRender,
		OutputAlertModalComponent,
		AgGridCheckboxComponent,
		OutputHomeCheckboxRender,
		ProjectConfigRender,
		EditableDocConfigRender,
		VisibleDocConfigRender,
		AddDocConfigRender,
		AddProjectTempModalComponent,
		AddTemplateRender,
		AddTemplateModalComponent,
		DeleteDocAttributeTypeModalComponent,
		EditDocAttributeTypeModalComponent,
		AddDocAttributeTypeModalComponent,
		DependencyModalComponent,
		DictionaryComponent,
		DictionaryCellRenderComponent,
		DeletewordComponent,
		ConfirmModalComponent,
		ClientCellRenderComponent,
		UserCellRenderComponent,
		ProjectCellRenderComponent,
		OMEditOutputConfigRender,
		EditOutputConfigModalComponent,
		OutputHomeStatusButtonRender,
		GetMoreOutputInfoModalComponent,
		DTDeleteButtonRenderer,
		DTAddButtonRenderer,
		DTEditButtonRenderer,
		DTDependencyButtonRenderer,
		DTStatusButtonRenderer,
      	DTDotnotaionRenderer,
      	DTADeleteButtonRenderer,
		DTAEditButtonRenderer,
		DTADependencyButtonRenderer,
		PTDeleteButtonRenderer,
      	EditDocAttributeTypeModalComponent,
		ConfirmationModalComponent,
		RoleConfirmationModalComponent,
      	DeleteCellRendererComponent,
      	DTDotnotaionRenderer,
		OutputGenerateModalComponent,
		ViewreportdataComponent,
		OPGConfigInputsViewModalComponent,
		NumberWidgetComponent,
		ReusableUpdateCellRenderComponent,
		PDFViewModalComponent,
		NewCourseComponent,
		IceCourseComponent,
		IceTopicComponent,
		IceTopicContentComponent,
		IceLearningCheckComponent,
		EinsteinGameComponent,
		MemoryGameComponent,
		SpeedMatchGameComponent,
		IceCourseLoaderComponent,
		OmSidebarComponent,
		OutputManagementComponent,
		OutputHomeComponent,
		PsLoaderComponent,
		PsSidebarComponent,
		ProcedureStoreComponent,
		AddProcedureStoreComponent,
		DeletedProcedureStoreComponent,
		ProcedureInstancesComponent,
		DictionaryLoaderComponent,
		DictionarySidebarComponent,
		ImageGridComponent,
		AsSidebarComponent,
		ReportImageCellRenderComponent,
		CategorySettingComponent,
		ReusableupdateComponent,
		ViewmodelComponent,
		NewThreedModelComponent,
		ThreedModelViewerComponent,
		ViewmodelComponent,
		PunchlistComponent,
		AddpunchlistComponent,
		EditpunchlistComponent,
		PunchlistsCellRenderComponent,
		PunchListDocumentIconRenderer,
		ThreedModelComponent,
		VideosComponent,
		UserRolesComponent,
		AssignUserComponent,
		ChangeCapabilitiesComponent,
		IceCoursePreviewComponent,
		CoursePreviewButtonRenderer,
		PdfToProcedurestoreComponent,
		TagsSystemsHierarchyComponent,
		IrnComponent,
		AddeditirnComponent,
		IrnCellRenderComponent,
		IrnPunchlistsCellRenderer,
		IrnAssetsCellRenderer,
		ManagemccComponent,
		EditmccComponent,
		ApprovemccComponent,
		ManageMCCCellRenderComponent,
		DsLoaderComponent,
		DsSidebarComponent,
		DocumentStoreComponent,
		AddDocumentStoreComponent,
		DeletedDocumentStoreComponent,
		ProcedureInstancesDSComponent,
		DSDeleteButtonRender,
		FacilitiesCellRender,
		AddFacilitiesModalComponent,
		DeleteDocumentStoreModalComponent,
		AddStoreDocumentModalComponent,
		AddToDocumentStoreButtonRender,
		DeleteButtonAddedDocumentStoreRender,
		ConfirmModelComponent,
		DependencyModalComponent,
		StatusRenderComponent,
		AlertModelComponent
	],*/
	schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule { }
