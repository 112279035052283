import { Component, OnInit, Input, TemplateRef, ElementRef, HostListener } from '@angular/core';
import { IceLCQuestion } from './ice-lc-question.model';
import { TranslateService } from '@ngx-translate/core';
import { IceLCQAnswer } from './ice-lc-answer.model';
import { dragAndDropSlot } from './ice-lc-answer.model';
import { dragAndDropCell } from './ice-lc-answer.model';
import { SharedService } from 'src/app/util/shared.service';
import { IceLearningCheck } from '../ice-learning-check/ice-learning-check.model';
import { AlertService } from '../../util/alert/alert.service';
import { WsResponse } from '../../util/ws-response.model';
import { WsType } from '../../util/ws-type';
import { ICECKEditor } from '../ice-course';
import { ICECourseService } from '../ice-course-service.service';
import { LocalStorage } from '../../util/localstorage.service';
import { LoadingService } from '../../util/loading/loading.service';
import { UserVariable } from '../../util/common/user-variable';
import { CK_Editor_Type  } from '../../util/constants';
import { LearningCheckData_Details  } from '../../util/constants';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal/';
//import { WsCallback } from '../util/ws-callback.interface';

declare var CKEDITOR: any;
var editor;

@Component({
	selector: 'app-ice-learning-check',
	templateUrl: './ice-learning-check.component.html',
	styleUrls: ['./ice-learning-check.component.scss']
})
export class IceLearningCheckComponent implements OnInit {

	public divHeight: number = (window.innerHeight) * 4 / 5;
	@HostListener('window:resize', ['$event'])
	onresize() {
		this.divHeight = (window.innerHeight) * 4 / 5
	}

	tdQuestionType: any = null
	public selectedQuestionId: string = null
	public questionList: Array<any> = []
	public questionModel: IceLCQuestion;
	private newQuestion: IceLCQuestion;
	private newAnswer: IceLCQAnswer;
	public learningCheck: IceLearningCheck;
	public temporyLearningCheck: IceLearningCheck;
	public temporyQuestionList: Array<IceLCQuestion>;
	public userId: String;
	public tabId: string;
	public breadCrumbList:Array<any> = [];
	public newLearningCheck: IceLearningCheck;
	public tfqStatus: boolean = true;
	public speCharList: Array<any> = [];
	public isViewOnly: boolean = false;
	private insertSubscription: any;
	public titleditor: any;
	public editorList: Array<any> = [];
	public editorDivList: Array<any> = [];
	private xmlContent: string = '';
	public temporyQuestion: IceLCQuestion;
	public iceLCQustionIdChecker: boolean = false;
	public questionModified: boolean;
	public LCDescriptionShowInDetails:any = null;
	public questionModifiedChecker: boolean = false;
	public answerModifiedChecker: boolean = false;
	public selectedQuestion: IceLCQuestion;
	public collapseOpen: boolean = false;
    public slotNumberList: Array<number> = [4];
	public slotNumberLengthList: Array<number> = [];
    public learningCheckDescription: string ;
    public cellList: Array<number> = [0,1];
	public imageData: any;
	public isImageDataAvailable: boolean = false;
	public currentIndex: number;
	public currenCell: number;
	public dragAndDropQuestion: IceLCQuestion;
	public currentDDQuestionIndex: number;
	public dragDropQuestion: IceLCQuestion;
	public learningCheckModified: boolean = false;
	public isTest: boolean = false;
	public isQuiz: boolean = true;
	public category: string;
	modalRef: BsModalRef;
	public addSpecialCharDivId: String = "";
	public addFractionDIvId: String = "";
	public validAnswer: boolean = true;
	public validMCAnswer: boolean = true;
	public validSCAnswer: boolean = true;
	public validQuestion: boolean = true;
	public minimumMCCorrectAnswerCount: number = 2;
	public minimumSCCorrectAnswerCount: number = 1;
	public minimumSCAnswerCount: number = 2;
	public correctAnswerCount: number = 0;
	public emptyAnswer: boolean = false;
	public testing: boolean = false;
	public haveto: boolean = false;
	public deletingQuestion: IceLCQuestion = null;
	public selectedImage: any = {
		'id': '',
		'thumbnailImage': '',
		'largeImage': '',
		'title': ''
    };
    public selectedModalTemplate: TemplateRef<any>;

	public modalConfig: any = {
		backdrop: true,
		// ignoreBackdropClick: true,
		class: 'modal-lg',
		// keyboard: false
	}

	private inlineConfig = {
		toolbar: [
			{ name: 'clipboard', groups: ['clipboard', 'undo'], items: ['Cut', 'Copy',  '-', 'Undo', 'Redo'] },
			{ name: 'basicstyles', groups: ['basicstyles'], items: ['Bold', 'Italic', 'Underline', 'Subscript', 'Superscript'] },
			//{ name: 'paragraph', groups: ['list'], items: ['NumberedList', 'BulletedList'] },
			{ name: 'insert', groups: ['insert'], items: ['addsymbol', 'addfraction'] }
			//{ name: 'editing', groups: ['spellchecker'], items: ['Scayt'] }
		],
		autoParagraph: false,
		readOnly: false,
		removePlugins: 'liststyle, magicline, maximize, resize',
		pasteFilter: 'plain-text',
		extraPlugins: 'statementclickhandler, sharedspace, addsymbol, addfraction, disablepartialbui, reusablepanelhandler, deletezws',
    	title: false,
    	tabID: SharedService.getTabId(),
		enterMode: CKEDITOR.ENTER_BR,
		startupFocus: 'end'
  };

	constructor(private translate: TranslateService, private sharedService: SharedService,  private alert: AlertService,
		private iceCKEditor: ICECKEditor,  private courseService: ICECourseService, private alertService: AlertService,
		private loadingService: LoadingService, private elementRef: ElementRef, private modalService: BsModalService) {
		let browserLang = translate.getBrowserLang();
		translate.getTranslation(browserLang).subscribe((res: string) => {
			this.tdQuestionType = res["ICE_COURSE"]
		});
		this.learningCheck = new IceLearningCheck("0");
		this.userId = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER)).userId;
		this.tabId = SharedService.getTabId();
		this.addFractionDIvId = 'webeditor_fraction_' +SharedService.getTabId();
		this.addSpecialCharDivId = 'webeditor_special_char_' +SharedService.getTabId();
	}

	@Input() data: any;

	ngOnInit() {
		this.speCharList = [
			{ data: { symbol: this.getSymbolFromDecimal(169), decVal: 169, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(8482), decVal: 8482, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(174), decVal: 174, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(176), decVal: 176, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(163), decVal: 163, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(8364), decVal: 8364, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(10016), decVal: 10016, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(9746), decVal: 9746, type: "speChar" } },
			// There was an issue in LC due to this special character. Therefore it was removed from all the Ck Editors in ICE Course
			// { data: { symbol: this.getSymbolFromDecimal(9744), decVal: 9744, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(167), decVal: 167, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(937), decVal: 937, type: "speChar" } }
		];
		
		if (this.data) {
			if(this.data.iceLearningCheck != null && this.data.iceLearningCheck != undefined ){
			  //set lc details from parent page
			  this.learningCheck = this.data.iceLearningCheck;
			  var html = this.learningCheck.descriptionHTML;
			  var div = document.createElement("div");
			  div.innerHTML = html;
			  div.innerText;
			  this.learningCheckDescription = div.innerText;
			
			  setTimeout(() => {
				//set lc description editor content
				let e1  = this.elementRef.nativeElement.querySelector('.l_ck_editor' + this.learningCheck.id + this.tabId);
				e1.innerHTML = this.learningCheck.description ;
				}, 200);

			  //set child details
			  if (this.learningCheck.id == '0'){
				  
			  }
			  else{
				//show loading message
				// var loadingMessage = null;
				// this.translate.get('ICE_LEARNING_CHECK.LOADING_MESSAGE').subscribe((res: string) => {
				// 	loadingMessage = res;
				// });
				this.loadingService.showLoading(true, null, this.translate.instant('ICE_LEARNING_CHECK.LOADING_MESSAGE'), null);
				this.courseService.getLearningCheckDataByLCId(this.learningCheck.id, UserVariable.getUserToken(), this);
			  }
			} else {
			  // data.lc is null or undefined
			}
			this.breadCrumbList = this.sharedService.getICEBreadCrumb(this.learningCheck.courseId);
			var html = this.learningCheck.descriptionHTML;
			var div = document.createElement("div");
			div.innerHTML = html;
			this.LCDescriptionShowInDetails = div.textContent || div.innerText || "";
			this.isViewOnly = this.data.viewOnly;
		} else {
			//no data
		}
		let self = this;
		let commonVariables = this.sharedService.getCommonICEModuleVariableNames('learningCheck');
		var commonVariableValues: Array<any> = [];
		commonVariables.forEach(function (varName) {
			commonVariableValues[varName] = self[varName];
		}); 
		this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'learningCheck', commonVariableValues);
		this.sharedService.setModifiedStatus(this.tabId,  this.learningCheckModified);
		this.sharedService.setOpenedICEModules(SharedService.getTabId(), 'ICELearningCheck', this.learningCheck);
	}

	ngAfterViewInit() {
		for(let i = 1; i <= this.questionList.length; i++){
			let el = this.elementRef.nativeElement.querySelector('.q_ck_editor' + this.questionList[i-1].id + this.tabId);
			if (this.isViewOnly == true) {
				el .style.backgroundColor = "#e9ecef";
			}
		}
	}
	
	ngAfterContentInit() {
		//unsubscribe
		if (SharedService.getIceLCSubscriptions() != null) {
				SharedService.getIceLCSubscriptions().unsubscribe();
			}

		this.sharedService.setICEModuleChanges.subscribe(prevTab => {
			if (prevTab == this.tabId) {
				//get opened modules
				let openedICEModules = this.sharedService.getOpenedICEModules();
				//check if tab type is ICELearningCheck
				if (openedICEModules[prevTab] != undefined) {
					// get tab type
					var type = openedICEModules[prevTab]['type'];
					if (type == "ICELearningCheck") {
						this.sharedService.setOpenedICEModules(prevTab, 'ICELearningCheck', this.learningCheck);
					} else {
						//differnt type
					}
				} else {
					//do nothing
				}
			}
		});

		this.sharedService.setICEModuleVariables.subscribe(prevTab => {
			if(this.tabId == prevTab) {
				let self = this;
				let commonVariables = this.sharedService.getCommonICEModuleVariableNames('learningCheck');
				var commonVariableValues: Array<any> = [];
				commonVariables.forEach(function (varName) {
					commonVariableValues[varName] = self[varName];
				}); 
				this.sharedService.setCommonICEModuleVariableValues(prevTab, 'learningCheck', commonVariableValues);
			}
		});
	
		//Called after ngOnInit when the component's or directive's content has been initialized.
		//Add 'implements AfterContentInit' to the class.
		this.insertSubscription = SharedService.insertICECourseExecute().subscribe(option => {
			//get opened modules
			let openedICEModules = this.sharedService.getOpenedICEModules();
			//get current tab
			var currentTab = SharedService.getTabId();

			//check if current tab type is ICEGame-Memory
			if (openedICEModules[currentTab] != undefined) {
				// get tab type
				var type = openedICEModules[currentTab]['type'];
				if (type == "ICELearningCheck") {
					if (option.data.type != "imageAsImage" && option.data.type != "imageAsFigure") { //all reusable will reject  
						this.alertService.warn(this.translate.instant('ICE_LEARNING_CHECK.ADD_REUSABLES_WARNING'));
						//this.iceCKEditor.insertTagEditArea(option, this.elementRef,editor,CKEDITOR, this.learningCheck, "ICELearningCheck" );
					} else {
						this.sharedService.setSelectedImageRequest(option);
					}
				} else {
					//differnt type
				}
			} else {
				//do nothing
			}
		}
		);

		this.sharedService.setOpenedICEVariables.subscribe(newTab => {
			if (this.tabId == newTab) {
				let commonVariables = this.sharedService.getCommonICEModuleVariableNames('learningCheck');
				var commonVariableValues: Array<any> = [];
				commonVariableValues = this.sharedService.getCommonICEModuleVariableValues(newTab);
				console.log(commonVariableValues);
				if(commonVariableValues != undefined) {
					let self = this;
					commonVariables.forEach(function (varName) {
						self[varName] = commonVariableValues[varName];
					}); 
				}
			}
		});

		this.sharedService.setSelectImagetoMainObject.subscribe(option => {
			this.imageData = option;
			this.isImageDataAvailable= true;
			//get the row index, sequence index and selected image type from shared service to update gameData object with inserted image details
			let pictureReferencesLC = this.sharedService.getPictureReferences();
			// get tab Id of the current tab from shared service
			let tabIDforPictures = this.tabId;
			if(tabIDforPictures == pictureReferencesLC['LCTabId']) {
				if(pictureReferencesLC['LCDdqId'] != undefined){ // images will accept
					this.imageData = option;
					this.isImageDataAvailable= true;
					// let ddqId = this.currentDDQuestionIndex;
					let ddqId = pictureReferencesLC['LCDdqId'];
					
					if(pictureReferencesLC['LCSide'] == 'Left') {
						this.currenCell = 0;
					} else {
						this.currenCell = 1;
					}
					if(this.questionList[ddqId] != undefined && this.questionList[ddqId].answer[0].answerSlots[pictureReferencesLC['LCIndex']] != undefined) {
						if(!this.questionList[ddqId].answer[0].answerSlots[pictureReferencesLC['LCIndex']].answerCells[this.currenCell].isTextEnabled) {
							this.questionList[ddqId].answer[0].answerSlots[pictureReferencesLC['LCIndex']].answerCells[this.currenCell].graphicId = this.imageData.data.id;
							this.questionList[ddqId].answer[0].answerSlots[pictureReferencesLC['LCIndex']].answerCells[this.currenCell].largeImage = this.imageData.data.largeImage;
							this.questionList[ddqId].answer[0].answerSlots[pictureReferencesLC['LCIndex']].answerCells[this.currenCell].thumbnailImage = this.imageData.data.thumbnailImage;
							this.questionList[ddqId].answer[0].answerSlots[pictureReferencesLC['LCIndex']].answerCells[this.currenCell].title = this.imageData.data.title;
							this.questionList[ddqId].answer[0].answerSlots[pictureReferencesLC['LCIndex']].answerCells[this.currenCell].width = this.imageData.data.width;
							this.questionList[ddqId].answer[0].answerSlots[pictureReferencesLC['LCIndex']].answerCells[this.currenCell].height = this.imageData.data.height;
							this.questionList[ddqId].answer[0].answerSlots[pictureReferencesLC['LCIndex']].answerCells[this.currenCell].path = this.imageData.data.path;
							this.questionList[ddqId].answer[0].answerSlots[pictureReferencesLC['LCIndex']].answerCells[this.currenCell].type = this.imageData.data.type;
							this.questionList[ddqId].answer[0].answerSlots[pictureReferencesLC['LCIndex']].answerCells[this.currenCell].isPictureEnabled = true; 
						}
					}
				}
			}
		});
        this.sharedService.imageStatusRespond().subscribe(imageStatus => this.setImageStatus(imageStatus));
		SharedService.setIceLCSubscriptions(this.insertSubscription);     
    }
    
    setImageStatus(type){
		if(type.status =="ICETopicContent" && type.tabId == this.tabId){
			//this.courseModified	= true;
			this.learningCheckModified = true;
			this.sharedService.setModifiedStatus(this.tabId, this.learningCheckModified)
		}
	}

	public clickBreadCrumb(data:any,index:number){
		let self = this;
		self.questionList.forEach((questionItem: IceLCQuestion) => {
			if(questionItem.isQuestionModified == true){
				this.questionModifiedChecker= true;
			} else{
				questionItem.answer.forEach((answeItem: IceLCQAnswer) =>{
					if(answeItem.isAnswerModified == true){
						this.answerModifiedChecker= true;
					} else{
						// if ddq , got to slots is cell modifies set this to true
					}				
				});
			}
		});
		if(this.learningCheckModified == false){
			if(data != null){
				this.sharedService.changeIceItemRequest(data.iceType, data);
				this.breadCrumbList.length = index;
			} else{
			  //nothing to do
			}
		} else{
			self.alertService.warn(self.translate.instant('ICE_LEARNING_CHECK.SAVE_LEARNINGCHECKDATA'));
		}
	}

	openModal(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template);
		}

	/**
	 * add new question
	 * @param questionType 
	 */
	public addNewQuestion(questionType: string) {
		let idGenerate = Math.floor(Math.random() * 99999 + 1);
		this.questionModified = true;
		this.learningCheckModified = true;
		this.sharedService.setModifiedStatus(this.tabId, this.learningCheckModified)
		console.log('[addNewQuestion] (questionType) ' + JSON.stringify(questionType));
		let length = this.questionList.length + 1;
		var idAsString = idGenerate.toString();
		this.questionList.forEach((questionItem:IceLCQuestion) => {
			if( questionItem.id == idAsString){
				this.iceLCQustionIdChecker = true;
			} 
		});
		if(this.iceLCQustionIdChecker == false){
			if(this.temporyQuestion != null){
				if (this.temporyQuestion.questionType !=  'truefalse' && this.collapseOpen == true && this.temporyQuestion.questionType !=  'dragdrop') { 
					for(let i = 1; i <= this.temporyQuestion.answer.length; i++){
						var editortext = $('.a_ck_editor' + this.temporyQuestion.answer[i-1].id + this.tabId).text();
						if(this.isNullOrWhitespace(editortext) == true) {
							this.temporyQuestion.answer[i-1].isValid = false;
						}
					}
					for(let i = 1; i <= this.temporyQuestion.answer.length; i++){
						let questionId = this.temporyQuestion.id;
						let answerId = this.temporyQuestion.answer[i-1].id;
						let el = this.elementRef.nativeElement.querySelector('.a_ck_editor' + this.temporyQuestion.answer[i-1].id + this.tabId);
						this.questionList.forEach((questionItem: IceLCQuestion) => {
							if(questionItem.id == questionId){
								questionItem.answer.forEach((answeItem: IceLCQAnswer) => {
									if(answeItem.id == answerId){
										answeItem.answerTextXML = el.innerHTML;
										answeItem.answerText = el.innerHTML;
										this.answerModifiedChecker== false
									} else{
										// do nothing
									}				
								});	
							}
						});
					}
				} else if(this.temporyQuestion.questionType == 'dragdrop'  && this.collapseOpen == true){
					if(this.temporyQuestion.answer != undefined && this.temporyQuestion.answer[0] != undefined){
						for(let i = 0; i < this.temporyQuestion.answer[0].slotSize; i++){
							for( let j = 0; j < 2; j++){
								if(this.temporyQuestion.answer[0].answerSlots[i].answerCells[j].isTextEnabled == true){
									var editortext = $('.cell_ck_editor' + this.temporyQuestion.answer[0].answerSlots[i].answerCells[j].id + this.tabId).text();
									if(this.isNullOrWhitespace(editortext) == true) {
										this.temporyQuestion.answer[0].isValid = false;
									} else{
										this.temporyQuestion.answer[0].isValid = true;
									}
								}			
							}
						}
					}
					if(this.questionList.length > 0){
						this.selectedQuestionId = this.temporyQuestion.id;
						let questionId = this.temporyQuestion.id;
						this.questionList.forEach((questionItem: IceLCQuestion)=> {
							if(questionItem.id == questionId){
								if(this.temporyQuestion.answer != undefined && this.temporyQuestion.answer[0] != undefined){
									for(let i = 0; i < this.temporyQuestion.answer[0].slotSize; i++){
										for( let j = 0; j < 2; j++){
											if( this.temporyQuestion.answer[0].answerSlots[i].answerCells[j].isTextEnabled == true){
												let el = this.elementRef.nativeElement.querySelector('.cell_ck_editor' + 
										 this.temporyQuestion.answer[0].answerSlots[i].answerCells[j].id + this.tabId);
										 this.temporyQuestion.answer[0].answerSlots[i].answerCells[j].cellText = el.innerHTML;
			
											}
										}
									}
								}
							}
						} );
						//this.showInfoQuestion(this.temporyQuestion)
						// for(let i = 0; i < this.temporyQuestion.answer[0].slotSize; i++){
						// 	for( let j = 0; j < 2; j++){
						// 		if( this.temporyQuestion.answer[0].answerSlots[i].answerCells[j].isTextEnabled == true){
						// 			let el = this.elementRef.nativeElement.querySelector('.cell_ck_editor' + 
						// 			this.temporyQuestion.answer[0].answerSlots[i].answerCells[j].id + this.tabId);
						// 			this.temporyQuestion.answer[0].answerSlots[i].answerCells[j].cellText = el.innerHTML;
						// 		}
						// 	}
						// }
					}		
				}
			}
			if(questionType=='singleanswer'){
				this.newQuestion = {
					id: idAsString, description: '', descriptionXML: '',
					orderNumber: length, questionType: 'singleanswer',
					answer: []
				}
				this.newQuestion.isQuestionModified = true;
				this.questionList.push(this.newQuestion);
				this.temporyQuestion = this.newQuestion;
				setTimeout(() => {
					this.focusNewEditor('.q_ck_editor'+ (this.newQuestion.id) + this.tabId , CK_Editor_Type.ICE_QUESTION_EDITOR);
					}, 200);
			} else if(questionType=='multipleanswer'){
				this.newQuestion = {
					id: idAsString, description: '',  descriptionXML: '',
					orderNumber: length, questionType: 'multipleanswer',
					answer: []
				}
				this.newQuestion.isQuestionModified = true;
				this.questionList.push(this.newQuestion);
				this.temporyQuestion = this.newQuestion;
				setTimeout(() => {
					this.focusNewEditor('.q_ck_editor'+ (this.newQuestion.id) + this.tabId , CK_Editor_Type.ICE_QUESTION_EDITOR);
					}, 200);
			} else if(questionType=='truefalse'){
				let tfqAnswer: IceLCQAnswer = { id: 'tfq_' + (length), answerText: 'True', answerTextXML: "true/false", isCorrect: "y", status: true}
				this.newQuestion = {
					id: idAsString, description: '',  descriptionXML: '',
					orderNumber: length, questionType: 'truefalse',
					answer: [tfqAnswer]
				}
				this.newQuestion.isQuestionModified = true;
				this.questionList.push(this.newQuestion);
				this.temporyQuestion = this.newQuestion;
				setTimeout(() => {
					this.focusNewEditor('.q_ck_editor'+ (this.newQuestion.id) + this.tabId , CK_Editor_Type.ICE_QUESTION_EDITOR);
					}, 200);
			} else if(questionType=='fillintheblank'){
				let ftbAnswer: IceLCQAnswer = { id: 'ftbq_' + (length), answerText: '', isCorrect: "y", status: true}
				this.newQuestion = {
					id: idAsString, description: '',  descriptionXML: '',
					orderNumber: length, questionType: 'fillintheblank',
					answer: [ftbAnswer]
				}
				this.newQuestion.isQuestionModified = true;
				this.questionList.push(this.newQuestion);
				setTimeout(() => {
					this.focusNewEditor('.q_ck_editor'+ (this.newQuestion.id) + this.tabId , CK_Editor_Type.ICE_QUESTION_EDITOR);
					}, 200);
			} else if(questionType=='dragdrop'){
				this.slotNumberLengthList =[];
				let ddqAnswer: IceLCQAnswer = { id: 'ddq_' + (length), isCorrect: "y", answerSlots: [] }
				this.newQuestion = {
					id: idAsString, description: '',  descriptionXML: '',
					orderNumber: length, questionType: 'dragdrop',
					answer: [ddqAnswer], slotSize: 0, slotNumberLengthList: []
				}
				this.newQuestion.isQuestionModified = true;
				this.questionList.push(this.newQuestion);
				this.temporyQuestion = this.newQuestion;
				setTimeout(() => {
					this.focusNewEditor('.q_ck_editor'+ (this.newQuestion.id) + this.tabId , CK_Editor_Type.ICE_QUESTION_EDITOR);
					}, 200);
			}
		} else{
			var loadingMessage = null;
    		this.translate.get('ICE_LEARNING_CHECK.QUESTION.TRY_AGAIN_MESSAGE').subscribe((res: string) => {
      			loadingMessage = res;
			});
			this.loadingService.showLoading(true, null, loadingMessage, null);
			setTimeout(() => {
				this.loadingService.hideLoading();
			  }, 2000);
		}
	}

	/**
	 * show more info in question
	 * @param question 
	 */
	public showInfoQuestion(question: IceLCQuestion) {
		console.log('[showInfoQuestion] (question) ' + JSON.stringify(question));
		if (this.selectedQuestionId == question.id) { // logic to validate answers
			if(question.questionType != 'dragdrop' && question.questionType != 'truefalse'){
				for(let i = 1; i <= question.answer.length; i++){
					var editortext = $('.a_ck_editor' + question.answer[i-1].id + this.tabId).text();
					if(this.isNullOrWhitespace(editortext) == true) {
						question.answer[i-1].isValid = false;
					}
				}
			} else if(question.questionType == 'dragdrop'){
				if(this.collapseOpen== true){ 
					if(question.answer != undefined && question.answer[0] != undefined){
						for(let i = 0; i < question.answer[0].slotSize; i++){
							for( let j = 0; j < 2; j++){
								if(question.answer[0].answerSlots[i].answerCells[j].isTextEnabled == true){
									var editortext = $('.cell_ck_editor' + question.answer[0].answerSlots[i].answerCells[j].id + this.tabId).text();
									if(this.isNullOrWhitespace(editortext) == true) {
										question.answer[0].isValid = false;
									} else {
										question.answer[0].isValid = true;
									}
								}			
							}
						}
					}
				}
			}
			if (this.temporyQuestion.questionType !=  'truefalse') {
				if(this.temporyQuestion.questionType !=  'dragdrop'){
					for(let i = 1; i <= this.temporyQuestion.answer.length; i++){
						let questionId = this.temporyQuestion.id;
						let answerId = this.temporyQuestion.answer[i-1].id;
						let el = this.elementRef.nativeElement.querySelector('.a_ck_editor' + this.temporyQuestion.answer[i-1].id + this.tabId);
						this.questionList.forEach((questionItem: IceLCQuestion) => {
							if(questionItem.id == questionId){
								questionItem.answer.forEach((answeItem: IceLCQAnswer) =>{
									if(answeItem.id == answerId){
										answeItem.answerTextXML = el.innerHTML;
										answeItem.answerText = el.innerHTML;
										this.answerModifiedChecker== false
									} else{
										// do nothing
									}				
								});	
							}
						});
					}
				} else if(this.temporyQuestion.questionType ==  'dragdrop'){
					if(this.collapseOpen== true){ //question.id != this.temporyQuestion.id && 
						if(this.temporyQuestion.answer != undefined && this.temporyQuestion.answer[0] != undefined){
							for(let i = 0; i < this.temporyQuestion.answer[0].slotSize; i++){
								for( let j = 0; j < 2; j++){
									if( this.temporyQuestion.answer[0].answerSlots[i].answerCells[j].isTextEnabled == true){
										let el = this.elementRef.nativeElement.querySelector('.cell_ck_editor' + 
										this.temporyQuestion.answer[0].answerSlots[i].answerCells[j].id + this.tabId);
										this.temporyQuestion.answer[0].answerSlots[i].answerCells[j].cellText = el.innerHTML;
									}
								}
							}
						}
					}
				} 
			} 
			this.selectedQuestionId = null;
			this.collapseOpen = false;
		} else {
			question.isCollapseOpened = true;
			if(this.temporyQuestion == undefined){
				this.temporyQuestion = question;
			}
			if(this.temporyQuestion != undefined){
				if (this.temporyQuestion.questionType != 'truefalse') {
					if(this.temporyQuestion.questionType !=  'dragdrop'){
						this.questionList.forEach((questionItem: IceLCQuestion) => {
							if(questionItem.id == question.id){
								questionItem.isCollapseOpened = true;
							}
						});
						if(question.id != this.temporyQuestion.id && this.collapseOpen== true){
							for(let i = 1; i <= this.temporyQuestion.answer.length; i++){
								let questionId = this.temporyQuestion.id;
								let answerId = this.temporyQuestion.answer[i-1].id;
								let el = this.elementRef.nativeElement.querySelector('.a_ck_editor' + this.temporyQuestion.answer[i-1].id + this.tabId);
								this.questionList.forEach((questionItem: IceLCQuestion) => {
									if(questionItem.id == questionId){
										questionItem.isCollapseOpened = true;
										questionItem.answer.forEach((answeItem: IceLCQAnswer) =>{
											if(answeItem.id == answerId){
												answeItem.answerTextXML = el.innerHTML;
												answeItem.answerText = el.innerHTML;
												this.answerModifiedChecker== false;
											} else{
												// do nothing
											}				
										});	
									}
								});
							}
						}
					} else if(this.temporyQuestion.questionType ==  'dragdrop'){
						this.questionList.forEach((questionItem: IceLCQuestion) => {
							if(questionItem.id == question.id){
								questionItem.isCollapseOpened = true;
							}
						});
						if(question.id != this.temporyQuestion.id && this.collapseOpen== true){ // one answer , 4 slots , 2 cells
							if(this.temporyQuestion.answer != undefined && this.temporyQuestion.answer[0] != undefined){
								for(let i = 0; i < this.temporyQuestion.answer[0].slotSize; i++){
									for( let j = 0; j < 2; j++){
										if( this.temporyQuestion.answer[0].answerSlots[i].answerCells[j].isTextEnabled == true){
											let el = this.elementRef.nativeElement.querySelector('.cell_ck_editor' + this.temporyQuestion.answer[0].answerSlots[i].answerCells[j].id + this.tabId);
											this.temporyQuestion.answer[0].answerSlots[i].answerCells[j].cellText = el.innerHTML;
										}
									}
								}
							}
						}
					} 
				} 
				this.selectedQuestionId = question.id;
				setTimeout(() => {
					this.setEditor(CK_Editor_Type.ICE_ANSWER_EDITOR, "");
					this.setEditor(CK_Editor_Type.DDQ_CELL_EDITOR, "");
				}, 200);
				this.collapseOpen = true;

			}
		}
		this.temporyQuestion = question;
	}

	/**
	 * quick question select under learning check view
	 * @param question
	 */
	public quickSelectByQuestion(question: IceLCQuestion) {
		this.questionList.forEach((questionItem: IceLCQuestion) => {
			questionItem.answer.forEach((answeItem: IceLCQAnswer) =>{
				if(answeItem.isAnswerModified == true){
					this.answerModifiedChecker= true;
				} else{
					// do nothing
				}				
			});	
		});
		if(this.answerModifiedChecker == false){
			if(question.questionType=='truefalse'){
				this.temporyQuestion= null;
			} else{
				this.temporyQuestion = question;
			}
			if (this.selectedQuestionId == question.id) {
				this.selectedQuestionId = null
				setTimeout(() => {
					this.setEditor(CK_Editor_Type.ICE_ANSWER_EDITOR, "");
				}, 200);
			} else {
				this.selectedQuestionId = question.id
				setTimeout(() => {
					this.setEditor(CK_Editor_Type.ICE_ANSWER_EDITOR, "");
				}, 200);
			}
		} else{
			this.alertService.warn(this.translate.instant('ICE_LEARNING_CHECK.SAVE_LEARNINGCHECKANSWER'));
		}
	}

	/**
	 * quick question select under course view,topic view
	 * @param questionId 
	 */
	public quickSelectById(questionId: string){
		this.questionList.forEach((questionItem: IceLCQuestion) => {
			if(questionItem.id == questionId){
				this.temporyQuestion = questionItem;
			}
		});
		this.selectedQuestionId = questionId;
	}

	/**
	 * delete question
	 * @param question 
	 */
	public deleteIceQuestion(question: IceLCQuestion) {
		console.log(question)
		this.learningCheckModified = true;
		this.sharedService.setModifiedStatus(this.tabId, this.learningCheckModified)
		let index = question.orderNumber - 1;
		this.questionList.splice( index, 1 );
		this.questionList.forEach((questionItem:IceLCQuestion) => {
			if(question.orderNumber < questionItem.orderNumber){
				questionItem.orderNumber -= 1;
			}
		});
		if(this.questionList.length > 0 && this.temporyQuestion != null){
			if(question.questionType == "dragdrop"){
				if(question.id == this.temporyQuestion.id){
					this.temporyQuestion = null;
				}
	
			}
		}
	}

		/**
	 * delete question
	 * @param IceLearningCheck 
	 * @param index
	 */
	public openDeleteQuestionModel(question: IceLCQuestion, template: TemplateRef<any>) {
		this.deletingQuestion = question;
			this.modalRef = this.modalService.show(template, this.modalConfig);
	}

	/**
	 * change position of the question 
	 * @param question 
	 * @param direction 
	 */
	public changePositionQuestion(question: IceLCQuestion, direction: string) {
		//console.log('[changePositionQuestion] (question/direction) ' + JSON.stringify(question) + '/' + direction);
		let index = question.orderNumber - 1;
		this.questionList.splice(index, 1);
		this.learningCheckModified = true;
		this.sharedService.setModifiedStatus(this.tabId, this.learningCheckModified)
		if(direction == 'up'){
			this.questionList.splice(index-1, 0, question);
			this.questionList[index- 1].orderNumber -= 1;
			this.questionList[index].orderNumber += 1;
		} else if(direction == 'down'){
			this.questionList.splice(index+ 1, 0, question);
			this.questionList[index+ 1].orderNumber += 1;
			this.questionList[index].orderNumber -= 1;
		}
	}

	/**
	 * add answer to a question
	 * @param question 
	 */
	public addAnswer(question: IceLCQuestion) {
		let idGenerate = Math.floor(Math.random() * 99999 + 1);
		var idAsString = idGenerate.toString();
		console.log('[addAnswer] (question) ' + JSON.stringify(question));
		let length: number = question.answer.length;
		this.learningCheckModified = true;
		this.sharedService.setModifiedStatus(this.tabId, this.learningCheckModified)
		switch (question.questionType) {
			case 'singleanswer':
				if(question.answer.length < LearningCheckData_Details.MAXIMUM_ANSWER_COUNT){
					this.newAnswer = { id: 'saq_' + idAsString +(length + 1), answerText: '', answerTextXML: '', isCorrect: "n", status: false, orderNumber: length + 1}
					this.newAnswer.isAnswerModified = true;
					question.answer.push(this.newAnswer);
					this.temporyQuestion = question;
					setTimeout(() => {
						this.focusNewEditor('.a_ck_editor'+ (this.newAnswer.id) + this.tabId , CK_Editor_Type.ICE_ANSWER_EDITOR);
						}, 200);
				} else{
					this.alertService.warn(this.translate.instant('ICE_LEARNING_CHECK.ADD_ANSWER_WARNING'));
				}
				break;
			case 'multipleanswer':
					if(question.answer.length < LearningCheckData_Details.MAXIMUM_ANSWER_COUNT){
						this.newAnswer = { id: 'maq_' +  idAsString + (length + 1), answerText: '', answerTextXML: '', isCorrect: "n",  status: false, orderNumber: length + 1}
					this.newAnswer.isAnswerModified = true;
					question.answer.push(this.newAnswer);
					this.temporyQuestion = question;
					setTimeout(() => {
						this.focusNewEditor('.a_ck_editor'+ (this.newAnswer.id) + this.tabId , CK_Editor_Type.ICE_ANSWER_EDITOR);
						}, 200);
					} else {
						this.alertService.warn(this.translate.instant('ICE_LEARNING_CHECK.ADD_ANSWER_WARNING'));
					}
				break;
			default:
				break;
		}	
	}
	
	/**
	 * update question descriptions before save
	*/
	updateQuestionDescription() {
		//this.validQuestion = true;
		for(let i = 1; i <= this.questionList.length; i++){
			this.xmlContent ="";
			//check if edior text is empty
			var editortext = $('.q_ck_editor' + this.questionList[i-1].id + this.tabId).text();
			if(this.isNullOrWhitespace(editortext) == true) {
				//this.validQuestion = false;
				this.questionList[i - 1].descriptionXML = '';  // warning msggg
			} else{
				this.questionList[i - 1].isQuestionModified = false;
				var name = this.getEditorContent('.q_ck_editor' + this.questionList[i-1].id + this.tabId);
				this.iceCKEditor.processXML(name,true,this.xmlContent);
				this.xmlContent = this.iceCKEditor.getXMLContent();
				this.xmlContent = this.xmlContent.substring(3, this.xmlContent.length - 4); // to remove <p></p> which are not required
				this.questionList[i - 1].descriptionXML =  this.xmlContent ;
			} 
		}
	}

	/**
	 * update answer text before save
	*/
	// updateAnsweranswerText() {  
	// 	if(this.temporyQuestion != null){
	// 		this.xmlContent ="";
	// 		if(this.temporyQuestion.answer.length > 0 && this.temporyQuestion.questionType != 'truefalse' 
	// 		&& this.temporyQuestion.questionType != 'dragdrop'){				
	// 			for(let i = 1; i <= this.temporyQuestion.answer.length; i++){ // have to process all answers
	// 				//check if edior text is empty
	// 				var editortext = $('.a_ck_editor' + this.temporyQuestion.answer[i-1].id + this.tabId).text();
	// 				if(this.isNullOrWhitespace(editortext) == true) {
	// 					this.temporyQuestion.answer[i - 1].answerTextXML = '';
	// 				} else{
	// 					this.temporyQuestion.answer[i - 1].isAnswerModified = false;
	// 					var name = this.getEditorContent('.a_ck_editor' + this.temporyQuestion.answer[i-1].id + this.tabId); 
	// 					this.xmlContent ="";
	// 					if( name == undefined){
	// 						var name2 =this.temporyQuestion.answer[i-1].answerText;
	// 						this.iceCKEditor.processXML(name2, true, this.xmlContent);
	// 						this.xmlContent = this.iceCKEditor.getXMLContent();
	// 						this.xmlContent = this.xmlContent.substring(3, this.xmlContent.length - 4);
	// 						this.temporyQuestion.answer[i - 1].answerTextXML = this.xmlContent;
	// 					} else{
	// 						this.iceCKEditor.processXML(name, true, this.xmlContent);
	// 						this.xmlContent = this.iceCKEditor.getXMLContent();
	// 						this.xmlContent = this.xmlContent.substring(3, this.xmlContent.length - 4);
	// 						this.temporyQuestion.answer[i - 1].answerTextXML = this.xmlContent;
	// 					}	
	// 				}			   
	// 			}
	// 		} else if(this.temporyQuestion.questionType == 'dragdrop'){
	// 			if(this.questionList.length > 0 && this.collapseOpen == true){
	// 				for(let i = 0; i < this.temporyQuestion.answer[0].slotSize; i++){
	// 					for( let j = 0; j < 2; j++){
	// 						this.xmlContent ="";
	// 						if( this.temporyQuestion.answer[0].answerSlots[i].answerCells[j].isTextEnabled == true){
	// 							let el = this.elementRef.nativeElement.querySelector('.cell_ck_editor' + 
	// 							this.temporyQuestion.answer[0].answerSlots[i].answerCells[j].id + this.tabId);
	// 							this.temporyQuestion.answer[0].answerSlots[i].answerCells[j].cellText = el.innerHTML;
	// 							var name3 = this.temporyQuestion.answer[0].answerSlots[i].answerCells[j].cellText;
	// 							this.iceCKEditor.processXML(name3, true, this.xmlContent);
	// 							this.xmlContent = this.iceCKEditor.getXMLContent();
	// 							this.xmlContent = this.xmlContent.substring(3, this.xmlContent.length - 4);
	// 							this.temporyQuestion.answer[0].answerSlots[i].answerCells[j].cellText = this.xmlContent;
	// 						}
	// 					}
	// 				}
	// 			}
	// 		}
	// 	} else {
	// 		// Do nothing
	// 	}
	// }


	updateAnswers(){
		if(this.temporyQuestion != null){
			this.xmlContent ="";
			// if(this.temporyQuestion.answer.length > 0 && this.temporyQuestion.questionType != 'truefalse' 
			// && this.temporyQuestion.questionType != 'dragdrop'){	
				this.questionList.forEach((questionItem: IceLCQuestion) => { 
					if(questionItem.questionType == "multipleanswer" || questionItem.questionType == "singleanswer"){
						for(let i = 1; i <= questionItem.answer.length; i++){
							questionItem.answer[i - 1].isAnswerModified = false;
							var name = this.getEditorContent('.a_ck_editor' + questionItem.answer[i-1].id + this.tabId); 
							this.xmlContent ="";
							//console.log(JSON.stringify(questionItem))
							if( name == undefined){
								if(questionItem.isCollapseOpened == true){
									var name2 = questionItem.answer[i-1].answerText;
									//console.log(questionItem.answer[i-1]);
									console.log( JSON.stringify(questionItem.answer[i-1]) );
									this.iceCKEditor.processXML(name2, false, this.xmlContent);
									this.xmlContent = this.iceCKEditor.getXMLContent();
									this.xmlContent = this.xmlContent.substring(3, this.xmlContent.length - 4);
									questionItem.answer[i - 1].answerTextXML = this.xmlContent;
									
								} else if(questionItem.isCollapseOpened == false){
									//do nothing
									var name3 = questionItem.answer[i-1].answerTextXML;
								}
								
							} else{
								questionItem.answer[i-1].answerText = name;
								this.iceCKEditor.processXML(name, false, this.xmlContent);
								this.xmlContent = this.iceCKEditor.getXMLContent();
								this.xmlContent = this.xmlContent.substring(3, this.xmlContent.length - 4);
								questionItem.answer[i - 1].answerTextXML = this.xmlContent;

							}			   
						}
					} else if(questionItem.questionType == 'dragdrop'){
						if(questionItem.isCollapseOpened == true){
							if(questionItem.id != this.temporyQuestion.id){
								if(questionItem.answer != undefined && questionItem.answer[0] != undefined){
									for(let i = 0; i < questionItem.answer[0].slotSize; i++){
										for( let j = 0; j < 2; j++){
											this.xmlContent ="";
											if( questionItem.answer[0].answerSlots[i].answerCells[j].isTextEnabled == true){
												//questionItem.answer[0].answerSlots[i].answerCells[j].cellText = el.innerHTML; // can't read inner html because collapse closed
												var name3 = questionItem.answer[0].answerSlots[i].answerCells[j].cellText;
												this.iceCKEditor.processXML(name3, false, this.xmlContent);
												this.xmlContent = this.iceCKEditor.getXMLContent();
												this.xmlContent = this.xmlContent.substring(3, this.xmlContent.length - 4);
												questionItem.answer[0].answerSlots[i].answerCells[j].cellText = this.xmlContent;
											}
										}
									}
								}
							} else if(questionItem.id == this.temporyQuestion.id){
									if(this.questionList.length > 0 && this.collapseOpen == true){ 
										if(this.temporyQuestion.answer != undefined && this.temporyQuestion.answer[0] != undefined){
											for(let i = 0; i < this.temporyQuestion.answer[0].slotSize; i++){
												for( let j = 0; j < 2; j++){
													this.xmlContent ="";
													if( this.temporyQuestion.answer[0].answerSlots[i].answerCells[j].isTextEnabled == true){
														let el = this.elementRef.nativeElement.querySelector('.cell_ck_editor' +
														this.temporyQuestion.answer[0].answerSlots[i].answerCells[j].id + this.tabId);
														this.temporyQuestion.answer[0].answerSlots[i].answerCells[j].cellText = el.innerHTML;
														var name3 = this.temporyQuestion.answer[0].answerSlots[i].answerCells[j].cellText;
														this.iceCKEditor.processXML(name3, false, this.xmlContent);
														this.xmlContent = this.iceCKEditor.getXMLContent();
														this.xmlContent = this.xmlContent.substring(3, this.xmlContent.length - 4);
														this.temporyQuestion.answer[0].answerSlots[i].answerCells[j].cellText = this.xmlContent;
													}
												}
											}
										}
									} else if(this.questionList.length > 0 && this.collapseOpen == false){
										if(this.temporyQuestion.answer != undefined && this.temporyQuestion.answer[0] != undefined){
											for(let i = 0; i < this.temporyQuestion.answer[0].slotSize; i++){
												for( let j = 0; j < 2; j++){
													this.xmlContent ="";
													if( this.temporyQuestion.answer[0].answerSlots[i].answerCells[j].isTextEnabled == true){
														
														// let el = this.elementRef.nativeElement.querySelector('.cell_ck_editor' +
														// this.temporyQuestion.answer[0].answerSlots[i].answerCells[j].id + this.tabId);
														//this.temporyQuestion.answer[0].answerSlots[i].answerCells[j].cellText = el.innerHTML;
														var name3 = this.temporyQuestion.answer[0].answerSlots[i].answerCells[j].cellText;
														this.iceCKEditor.processXML(name3, false, this.xmlContent);
														this.xmlContent = this.iceCKEditor.getXMLContent();
														this.xmlContent = this.xmlContent.substring(3, this.xmlContent.length - 4);
														this.temporyQuestion.answer[0].answerSlots[i].answerCells[j].cellText = this.xmlContent;
													}
												}
											}
										}
									}
								}
						} else {
							console.log("do nothing");
						}
					} else if(questionItem.questionType == 'truefalse'){
						if(questionItem.answer != undefined && questionItem.answer[0] != undefined){
							this.xmlContent ="";
							var name4 = questionItem.answer[0].answerTextXML;
							this.iceCKEditor.processXML(name4, false, this.xmlContent);
							this.xmlContent = this.iceCKEditor.getXMLContent();
							this.xmlContent = this.xmlContent.substring(3, this.xmlContent.length - 4);
							questionItem.answer[0].answerTextXML = this.xmlContent;
						}
					}
				});
		} else {
			// Do nothing
		}
	}

	/**
	 * update learning check descriptions before save
	*/
	updateLearningCheckDescription() {
		this.xmlContent ="";
		//check if edior text is empty
		var editortext = $('.l_ck_editor' + this.learningCheck.id + this.tabId).text();
		if(this.isNullOrWhitespace(editortext) == true) {
			this.learningCheck.description = '<ice></ice>';
		} else {
			//process name
			var name = this.getEditorContent('.l_ck_editor' + this.learningCheck.id + this.tabId); 
			this.iceCKEditor.processXML(name,false,this.xmlContent);
			this.xmlContent = this.iceCKEditor.getXMLContent();
			this.learningCheck.description = '<ice>'+ this.xmlContent + '</ice>';
			this.xmlContent = this.xmlContent.substring(3, this.xmlContent.length - 4);
			this.learningCheckDescription = this.xmlContent;
		}
	}

	isNullOrWhitespace(input) {
		return !/\S/.test(input);
	}
	
	private getEditorContent(editorName: string): string {
		let el = this.elementRef.nativeElement.querySelector(editorName);
		if(el != null){
			return el.innerHTML;
		}
	}

	/**
	 * remove answer from single answer and multiple answer
	 * @param answer 
	 */
	public removeAnswer(question: IceLCQuestion, answer: IceLCQAnswer, index: number) {
		if(!this.isViewOnly) {
			console.log('[removeAnswer] (question/answer) ' + JSON.stringify(question) + '/' + JSON.stringify(answer));
			this.learningCheckModified = true;		
			this.sharedService.setModifiedStatus(this.tabId, this.learningCheckModified)
			question.answer.splice(index,1);
			question.answer.forEach((answerItem:IceLCQAnswer) => {
				if(answer.orderNumber < answerItem.orderNumber){
					answerItem.orderNumber -= 1;
				}
			});
		}
	}

	/**
	 * change answer of the single answer question
	 * @param answer 
	 * @param event 
	 */
	public changeAnswerStatus(question: IceLCQuestion, answer: IceLCQAnswer) {
		//console.log('[removeAnswer] (question/answer) ' + JSON.stringify(question) + '/' + JSON.stringify(answer));
		this.learningCheckModified = true;
		this.sharedService.setModifiedStatus(this.tabId, this.learningCheckModified)
		question.answer.forEach((answerItem: IceLCQAnswer) => {
			answerItem.isCorrect = "n"
			answerItem.status = false;
		});
		answer.isCorrect = "y"
		answer.status = true;
	}

	/**
	 * change status of answer ,multiple answer question
	 * @param answer 
	 * @param event 
	 */
	public changeMultipleAnswerStatus(question: IceLCQuestion, answer: IceLCQAnswer, index: number, qindex: number) {
		this.learningCheckModified = true;
		this.sharedService.setModifiedStatus(this.tabId, this.learningCheckModified)
		if(this.questionList[qindex].answer[index].isCorrect == "y"){
			this.questionList[qindex].answer[index].isCorrect = "n";
			this.questionList[qindex].answer[index].status = false;
			this.validAnswer = true;	
		} else if(this.questionList[qindex].answer[index].isCorrect == "n"){
			this.questionList[qindex].answer[index].isCorrect = "y"
			this.questionList[qindex].answer[index].status = true;
			this.validAnswer = true;
		}
	}

	/**
	 * back to main course detail page
	 */
	public backtoCourse() {
		this.sharedService.changeIceItemRequest('IceCourse', null)
	}

	/**
	 * update breadcrumb after updating learning check description under learning check view.
	*/
	updateBreadCrumb(){
		this.breadCrumbList = this.sharedService.getICEBreadCrumb(this.learningCheck.courseId);
		var html = this.learningCheck.descriptionHTML;
		var div = document.createElement("div");
		div.innerHTML = html;
		this.LCDescriptionShowInDetails = div.textContent || div.innerText || "";
	}

	/**
	 * update learning check description 
	*/
	updateDescription(){
		let el = this.elementRef.nativeElement.querySelector('.l_ck_editor' + this.learningCheck.id + this.tabId);
		if (el != null) {
			let name = this.learningCheck.description; 
			if (name != undefined) {
				el.innerHTML = name;
			}
		}
	}

	checkAnswers(){
		this.emptyAnswer = false;
		this.validAnswer = true;
		this.validMCAnswer = true;
		this.validSCAnswer = true;
		if(this.temporyQuestion != null){
			if (this.temporyQuestion.questionType !=  'truefalse' && this.collapseOpen == true && this.temporyQuestion.questionType !=  'dragdrop') {
				for(let i = 1; i <= this.temporyQuestion.answer.length; i++){
					var editortext = $('.a_ck_editor' + this.temporyQuestion.answer[i-1].id + this.tabId).text();
					if(this.isNullOrWhitespace(editortext) == true) {
						this.temporyQuestion.answer[i-1].isValid = false;
					} else{
						this.temporyQuestion.answer[i-1].isValid = true;
					}
				}
			} else if(this.temporyQuestion.questionType == 'dragdrop'  && this.collapseOpen == true){
				if(this.temporyQuestion.answer != undefined && this.temporyQuestion.answer[0] != undefined ){
					for(let i = 0; i < this.temporyQuestion.answer[0].slotSize; i++){
						for( let j = 0; j < 2; j++){
							if(this.temporyQuestion.answer[0].answerSlots[i].answerCells[j].isTextEnabled == true){
								var editortext = $('.cell_ck_editor' + this.temporyQuestion.answer[0].answerSlots[i].answerCells[j].id + this.tabId).text();
								if(this.isNullOrWhitespace(editortext) == true) {
									this.temporyQuestion.answer[0].isValid = false;
								} else{
									this.temporyQuestion.answer[0].isValid = true;
								}
							}
							// else if(this.temporyQuestion.answer[0].answerSlots[i].answerCells[j].isPictureEnabled == true){
							// 	if(this.temporyQuestion.answer[0].answerSlots[i].answerCells[j].anstype =='image' && (this.temporyQuestion.answer[0].answerSlots[i].answerCells[j].graphicId == 'null'|| this.temporyQuestion.answer[0].answerSlots[i].answerCells[j].graphicId == '')){
							// 			this.emptyAnswer = true;			 
							// 	}

							// }

						}
					}
				}	
			}
		}
		this.questionList.forEach((questionItem: IceLCQuestion) => {
			if(questionItem.questionType == 'multipleanswer'){
				this.correctAnswerCount = 0;
				if(questionItem.answer.length > 0){
					questionItem.answer.forEach(( answerItem: IceLCQAnswer) => {
						if( answerItem.isCorrect == "y"){
							this.correctAnswerCount = this.correctAnswerCount + 1;
						}
						if(answerItem.isValid == false){	
							this.emptyAnswer = true;
						}
					});
					if(this.correctAnswerCount >= this.minimumMCCorrectAnswerCount){
						this.correctAnswerCount = 0;
					} else{
						this.validAnswer = false;
						this.validMCAnswer = false;
					}
				}
			}else if(questionItem.questionType == 'singleanswer'){
				this.correctAnswerCount = 0;
				if(questionItem.answer.length > 0){
					if(questionItem.answer.length >= this.minimumSCAnswerCount){
						questionItem.answer.forEach(( answerItem: IceLCQAnswer) => {
							if( answerItem.isCorrect == "y"){
								this.correctAnswerCount = this.correctAnswerCount + 1;
							}
							if(answerItem.isValid == false){
								this.emptyAnswer = true;								
							}
						});
						if(this.correctAnswerCount == this.minimumSCCorrectAnswerCount){
							this.correctAnswerCount = 0;
						} else{
							this.validAnswer = false;
							this.validSCAnswer = false;
						}
					}else{
						this.validAnswer = false;
					}
				}
			} else{
				//this.validAnswer = true;
			}
		});
		// this.questionList.forEach((questionItem: IceLCQuestion) => {
		// 	if(questionItem.questionType == 'dragdrop'){
		// 		if(questionItem.answer != undefined && questionItem.answer[0] != undefined ){ 
		// 			if(questionItem.answer[0].isValid == false){
		// 				this.emptyAnswer = true;
		// 			}
		// 		}
		// 	}
		// });
	}

	checkQuestions(){
		this.validQuestion = true;
		for(let i = 1; i <= this.questionList.length; i++){
			var editortext = $('.q_ck_editor' + this.questionList[i-1].id + this.tabId).text();
			if(this.isNullOrWhitespace(editortext) == true) {
				this.validQuestion = false;
			}
		}
			
	}

	/**
	* save learningCheckData
	*/
	public save(){

		if (!this.validateForm()) {
			return;
		}else{
		
		this.checkAnswers();
		this.checkQuestions();
		if(this.validAnswer == true && this.validQuestion == true && this.emptyAnswer == false){
			console.log('[save] ');
			this.updateQuestionDescription();
			this.updateAnswers()
			this.updateLearningCheckDescription();
			this.learningCheckModified = false;
			this.questionModifiedChecker = false;
			this.answerModifiedChecker = false;
			let temporyQuestionList: Array<any> = [];
			this.questionList.forEach((questionItem: IceLCQuestion) => {
				let qItem:IceLCQuestion;
				qItem = {id: questionItem.id, description: '', descriptionXML: '',
					orderNumber: questionItem.orderNumber, questionType: questionItem.questionType,
					answer: []
				};
				qItem.isCollapseOpened = questionItem.isCollapseOpened;
				qItem.descriptionXML =  "<ice><p>" + questionItem.descriptionXML + "</p></ice>";  
				questionItem.answer.forEach(( answerItem: IceLCQAnswer) => {
					if(answerItem.answerTextXML != ""){
						let aItem:IceLCQAnswer;
						aItem = {id: answerItem.id, answerText: '', answerTextXML: '', isCorrect: answerItem.isCorrect, status: answerItem.status, orderNumber: answerItem.orderNumber};
						aItem.answerTextXML =  "<ice><p>" + answerItem.answerTextXML + "</p></ice>";
						aItem.answerText = answerItem.answerText;
						if(answerItem.answerSlots != undefined){
							aItem.answerSlots = [];
							if(answerItem.answerSlots.length > 0){
								answerItem.answerSlots.forEach((slotItem: dragAndDropSlot) =>{
									let slot: dragAndDropSlot;
									slot = {id: slotItem.id, slotIndex:slotItem.slotIndex, isCorrect: slotItem.isCorrect, answerCells: []}; 
									slotItem.answerCells.forEach((cellItem: dragAndDropCell) =>{	
										let cell: dragAndDropCell;
										cell = {id: cellItem.id, cellSide: cellItem.cellSide };	
										cell.matchId = 	cellItem.matchId;
										cell.cellIndex = cellItem.cellIndex; 				
										if(cellItem.isTextEnabled == true){
											cell.isTextEnabled = true;
											cell.isPictureEnabled = false;
											cell.graphicId = null;
											cell.type = 'text';
											cell.cellText = "<ice><p>" + cellItem.cellText + "</p></ice>";
										} else if( cellItem.isPictureEnabled == true){
											cell.isTextEnabled = false;
											cell.isPictureEnabled = true;
											cell.type = 'image';
											cell.largeImage = cellItem.largeImage;
											cellItem.graphicId != null ? cell.graphicId = cellItem.graphicId : cell.graphicId = null;
										} else { // for empty cells 
											cell.isTextEnabled = false;
											cell.isPictureEnabled = false;
											cell.graphicId = null;
											cell.type = 'empty';
										}
										slot.answerCells.push(cell);
									});
									aItem.answerSlots.push(slot);
								});
							}
						} 	 
						qItem.answer.push(aItem);
					} else{
						// nothing to do
					}
				});
				temporyQuestionList.push(qItem);
			});

			if(this.isQuiz == true){
				this.category = "Quiz";
			} else if(this.isTest == true){
				this.category = "Test";
			}
			// Retrieve topicId & set it to null if it's not defined
			let topicId: string;
			(this.learningCheck.topicId != null && this.learningCheck.topicId != undefined) ? topicId = this.learningCheck.topicId : topicId = null;
			var request = {     
				"id": this.learningCheck.id,
				"description": this.learningCheck.description,
				"category": this.category,
				"courseId": this.learningCheck.courseId,
				"topicId": topicId,
				"topicContentId": null,
				"data": temporyQuestionList,
				"courseVersion": this.learningCheck.courseVersion
			}
			var loadingMessage = null;
			this.translate.get('ICE_COURSE.SAVE_LEARNING_CHECKS_MESSAGE').subscribe((res: string) => {
				loadingMessage = res;
			});
			this.loadingService.showLoading(true, null, loadingMessage, null);
			this.courseService.saveLearningCheckQuestionList(JSON.stringify(request), this.learningCheck.id, UserVariable.getUserToken(), this);
			this.selectedQuestionId = ""; // new changers to get q list after saving.
			this.collapseOpen = false;
			this.sharedService.setModifiedStatus(this.tabId, this.learningCheckModified)
		} else {
			if(this.validAnswer == false){
				if(this.validMCAnswer == false){
					this.alertService.warn(this.translate.instant('ICE_LEARNING_CHECK.INVALID_NUMBER_OF_CORRECT_MC_ANSWERS'));
				}else if(this.validSCAnswer == false){
					this.alertService.warn(this.translate.instant('ICE_LEARNING_CHECK.INVALID_NUMBER_OF_CORRECT_SC_ANSWERS'));
				}else{
					this.alertService.warn(this.translate.instant('ICE_LEARNING_CHECK.INVALID_NUMBER_OF_SC_ANSWERS'));
				}
			} else if(this.validQuestion == false){
				this.alertService.warn(this.translate.instant('ICE_LEARNING_CHECK.INVALID_QUESTION'));
			} else if(this.emptyAnswer == true){
				this.alertService.warn(this.translate.instant('ICE_LEARNING_CHECK.INVALID_ANSWER'));
			}
		}
	}
	}

	/**
	 * save question ( removed from the UI)
	 * @param question 
	 */
	// public saveQuestion(question: IceLCQuestion) {
	// 	console.log('[saveQuestion] (question) ' + JSON.stringify(question));
	// 	this.updateQuestionDescription();
	// 	this.updateAnsweranswerText();
	// 	this.questionModifiedChecker = false;
	// 	this.answerModifiedChecker = false;
	// 	console.log(this.questionList);
	// }

	public setradio(question: IceLCQuestion, answerStatus: string){
		this.learningCheckModified = true;
		this.sharedService.setModifiedStatus(this.tabId, this.learningCheckModified)
		if(answerStatus == 'true'){
			this.tfqStatus = true;
			question.answer = [];
			let tfqAnswer: IceLCQAnswer = { id: 'tfq_' + (question.id) , answerText: 'Ture', answerTextXML: "true/false", isCorrect: "y", status: true }
			question.answer.push(tfqAnswer)
		} else if(answerStatus == 'false'){
			this.tfqStatus = false;
			question.answer = [];
			let tfqAnswer: IceLCQAnswer = { id: 'tfq_' + (question.id), answerText: 'False', answerTextXML: "true/false", isCorrect: "n", status: false }
			question.answer.push(tfqAnswer)
		}
	}

	public getStringFromType(questionType: string): string {
		switch (questionType) {
			case 'singleanswer':
				return "Single Choice"
			case 'multipleanswer':
				return "Multiple Choice"
			case 'truefalse':
				return "True or False"
			case 'dragdrop':
				return "Drag and Drop"
			case 'fillintheblank':
				return "Fill in the Blank Question"
			default:
				return this.tdQuestionType.ICE_LEARNING_CHECK.QUESTION_TYPE.SINGLE_ANSWER
		}
	}

	onSuccess(data: WsResponse, serviceType: WsType) {
		if(serviceType == WsType.SAVE_ICE_LEARNING_CHECKDATA){
			this.questionList = [];
			this.temporyQuestion = null;
			this.temporyQuestionList = [];
			this.selectedQuestion = null;
			var loadingMessage = null;
			this.loadingService.hideLoading();
			this.translate.get('ICE_LEARNING_CHECK.WAIT').subscribe((res: string) => {
				loadingMessage = res;
			});
			this.loadingService.showLoading(true, null, loadingMessage, null);
			this.courseService.getLearningCheckDataByLCId(this.learningCheck.id, UserVariable.getUserToken(), this); // new changers to get q list after saving.
			this.learningCheck.descriptionHTML = data.payload;
			this.updateBreadCrumb();
			this.showSuccessAlert('ICE_LEARNING_CHECK.LEARNINGCHECKDATA_SAVED_ALERT');
		} else if(serviceType == WsType.GET_ICE_LEARNING_CHECKDATA){ // set iStextEnable,isPictureEnable. by cell type,
			this.loadICEQuestionList(data.payload);
			this.learningCheck.description = data.payload.description;
			this.learningCheck.descriptionHTML = data.payload.descriptionHTML;
			this.updateBreadCrumb();
			this.updateDescription();
			this.loadingService.hideLoading();
		} else if (serviceType == WsType.GET_SELECTED_IMAGE_DATA) {
            this.selectedImage = JSON.parse(data.payload);
            this.loadingService.hideLoading();
            this.modalRef = this.modalService.show(this.selectedModalTemplate, this.modalConfig);
        }
	}

	onFail(data: WsResponse, serviceType:WsType){
		if (serviceType == WsType.SAVE_ICE_LEARNING_CHECKDATA) {
			this.alertService.error(data.statusDescription, false);
			console.log("=====Failed to save ice learning check data=====");
			this.loadingService.hideLoading();
		} else if (serviceType == WsType.GET_ICE_LEARNING_CHECKDATA) {
			this.alertService.error(data.statusDescription, false);
      		console.log("=====Failed to get ice learning check data=====");
   		} else if (serviceType == WsType.GET_SELECTED_IMAGE_DATA) {
            this.loadingService.hideLoading();
        }
	}

	showLargeImage(template: TemplateRef<any>, selectedImageId: any) {
        this.selectedImage = {
			'id': '',
			'thumbnailImage': '',
			'largeImage': '',
			'title': ''
		}
        //show loading message
        var loadingMessage = null;
        this.translate.get('ICE_COURSE.COURSE_PREVIEW_IMAGE_LOADING').subscribe((res: string) => {
            loadingMessage = res;
        });
		this.loadingService.showLoading(true, null, loadingMessage, null);
        this.courseService.getSelectedImage(selectedImageId, UserVariable.getUserToken(), this);
        this.selectedModalTemplate = template;
    }

	showSuccessAlert(en:string){
		var alert = null;
		this.translate.get(en).subscribe((res: string) => {
		  alert = res;
		});
		this.alert.success(alert);
	}

	/**
	 * Set payload data to question list
	 * @param payload 
	*/
	loadICEQuestionList(payload:WsResponse['payload']){
		this.temporyQuestion = null;
			if(payload != null){
				if(payload.length != 0){
					this.temporyLearningCheck = payload;
					this.temporyQuestionList = this.temporyLearningCheck.data;
					if(this.temporyLearningCheck.category == "Quiz"){
						this.isQuiz = true;
						this.isTest = false;
					} else if(this.temporyLearningCheck.category == "Test"){
						this.isQuiz = false;
						this.isTest = true;
					}
					for(let i = 0; i < this.temporyQuestionList.length; i++){
						//this.temporyLearningCheck = payload[i];
						this.temporyQuestion = this.temporyQuestionList[i];
						this.temporyQuestion.isCollapseOpened = false;
						if(this.temporyQuestion.questionType != 'dragdrop'){
							this.temporyQuestion.answer.forEach((answerItem : IceLCQAnswer) => {
								answerItem.isAnswerModified = false;
								answerItem.answerSlots = [];
							});
							this.temporyQuestion.isQuestionModified = false;
							this.questionList.push(this.temporyQuestion);

							// console.log(JSON.stringify(this.questionList)); //test
							// let iceQuestion = this.questionList.find(obj => obj.id == this.temporyQuestion.id);
							// console.log(JSON.stringify(iceQuestion));
							// this.haveto = true;
							// this.showInfoQuestion(iceQuestion);



						} else if(this.temporyQuestion.questionType == 'dragdrop'){
							this.questionList.push(this.temporyQuestion); // to show question description
							if(this.temporyQuestion.answer != undefined && this.temporyQuestion.answer[0] != undefined ){ 
								if(this.temporyQuestion.answer[0].answerSlots.length > 0){
									this.temporyQuestion.answer[0].slotSize = this.temporyQuestion.answer[0].answerSlots.length
									//this.questionList.push(this.temporyQuestion);
									this.generateSlots(this.temporyQuestion, 4, true); 
									setTimeout(() => {
										this.setEditor(CK_Editor_Type.DDQ_CELL_EDITOR, "");
									}, 400);
								}
							}
						}
					}
				}
			} else{
				// no row data to show
			}
		if(this.data.quickSelectQuestionId != null){
			let iceQuestion = this.questionList.find(obj => obj.id == this.data.quickSelectQuestionId);//quick select the question by payload id
			this.showInfoQuestion(iceQuestion);
		} else{
			//nothing to do
		}
		setTimeout(() => {
		  this.setEditor(CK_Editor_Type.ICE_QUESTION_EDITOR, "");
		  this.setEditor(CK_Editor_Type.ICE_ANSWER_EDITOR, "");
		  //this.setEditor(CK_Editor_Type.DDQ_CELL_EDITOR, "");
		}, 400);
		console.log(this.questionList);
	}

	private getSymbolFromDecimal(value: number): string {
		if ((33 <= value) &&  (value < 65536)) {
            return String.fromCharCode(value);
        } else {
            this.alertService.error("Please enter valid Unicode character entity reference. Range is between 33 to 65535", true);
        }
  	}

  focusNewEditor(className: string, CK_Editor_Variable) {   
    let self = this;
    //set editor content
    this.setEditor(CK_Editor_Variable, className);
	let node = $(".toggle-edit" + this.tabId + this.userId + className);
	for (let i in this.editorList) {
		if (CKEDITOR.instances[this.editorList[i].name] != undefined) {
			CKEDITOR.instances[this.editorList[i].name].destroy();
		}
	}
	for (let j in self.editorDivList) {
		if (self.editorDivList[j].attributes['contenteditable'].nodeValue == "true") {
			self.editorDivList[j].setAttribute("contenteditable", "false");									
			if ($(self.editorDivList[j])[0].innerHTML == '') {
				$(self.editorDivList[j])[0].innerHTML = '<br>';
			}
		}
	}
    $(node)[0].setAttribute("contenteditable", "true");
    editor = CKEDITOR.inline($(node)[0], this.inlineConfig);
    this.editorList.push(editor);
	this.editorDivList.push($(node)[0]);
	editor.on('instanceReady', function () {
		var range = editor.createRange();
		if (range != null) {
			range.moveToElementEditEnd(range.root);
			editor.getSelection().selectRanges([range]);
		}
    });
    
    editor.on('key', function (e) {
      self.iceCKEditor.editorKeyDown(e,editor);
    });

	editor.on('paste', function (evt) {
		evt.stop();
		var data: string = evt.data.dataValue;
		let pasteData: string = self.iceCKEditor.processPasteData(data);
		if(self.iceCKEditor.gethasResuables()) {
			self.alertService.clear();
			self.alertService.toast(self.translate.instant('ICE_COURSE.DISABLED_EDITOR_COPY'));
		}
		evt.editor.insertHtml(pasteData);
	}); 
	}

	setEditor( CK_Editor_Variable:string, className) {
		var self = this;
		$('.toggle-edit' + SharedService.getTabId() + this.userId).on('copy', function() {
			if ($(this)[0].attributes['contenteditable'].nodeValue == "false") {
				localStorage.setItem('disabledEditor', "true"); 
			}
		});	
		//set editor content
		this.setEditorContent(CK_Editor_Variable, className);
	
		if(this.isViewOnly == true) {
			//view only
		} else if( CK_Editor_Variable == 'ice_question_ck_editor' ||  CK_Editor_Variable == 'ice_answer_ck_editor' 
		||  CK_Editor_Variable == 'ice_ddq_cell_ck_editor') {
			$('.toggle-edit' + this.tabId  + this.userId).dblclick(function () {
				self.learningCheckModified = true;
				self.sharedService.setModifiedStatus(self.tabId, self.learningCheckModified)
				for (let i in self.editorList) {
				  if (CKEDITOR.instances[self.editorList[i].name] != undefined) {
					CKEDITOR.instances[self.editorList[i].name].destroy();
				  }
				}
				for (let j in self.editorDivList) {
					if (self.editorDivList[j].attributes['contenteditable'].nodeValue == "true") {
						self.editorDivList[j].setAttribute("contenteditable", "false");									
						if ($(self.editorDivList[j])[0].innerHTML == '') {
							$(self.editorDivList[j])[0].innerHTML = '<br>';
						}
					}
				}
				$(this)[0].setAttribute("contenteditable", "true");
				if(CK_Editor_Variable == 'ice_question_ck_editor'){
					let questionId = $(this)[0].getAttribute("id");
				 	let iceLCquestion = self.questionList.find(obj => obj.id == questionId);
					  //set LC modified true
					if(iceLCquestion != null){
						iceLCquestion.isQuestionModified = true;
						// iceLCquestion.answer.forEach((answerItem:IceLCQAnswer) => {
						// });											
					}
				  	editor = CKEDITOR.inline($(this)[0], self.inlineConfig);
				} else if(CK_Editor_Variable == 'ice_answer_ck_editor'){
					let answerId = $(this)[0].getAttribute("id");
					self.questionList.forEach((questionItem:IceLCQuestion) => {
						let iceLCAnswer = questionItem.answer.find(obj => obj.id == answerId);
						if(iceLCAnswer != null){
							iceLCAnswer.isAnswerModified = true;						
						}	
					});					
				  	editor = CKEDITOR.inline($(this)[0], self.inlineConfig);
				} else if(CK_Editor_Variable == 'ice_ddq_cell_ck_editor'){		
					// let cellId = $(this)[0].getAttribute("id");	
					// self.questionList.forEach((questionItem:IceLCQuestion) => {
					// 	questionItem.answer[0].answerSlots.forEach((slotItem: dragAndDropSlot) => {
					// 		let modifiedCell = slotItem.answerCells.find(obj => obj.id == cellId);
					// 		if(modifiedCell != null){
					// 			modifiedCell.isCellModified = true;
					// 		}
					// 	});	
					// });			
				  	editor = CKEDITOR.inline($(this)[0], self.inlineConfig);
				} 
				self.editorList.push(editor);
				self.editorDivList.push($(this)[0]);	
				editor.on('instanceReady', function () {
				  var range = editor.createRange();
				  if (range != null) {
					range.moveToElementEditEnd(range.root);
					editor.getSelection().selectRanges([range]);
				  }
				});
	
				editor.on('key', function (e) {
				  self.iceCKEditor.editorKeyDown(e,editor);
				});
	
				editor.on('paste', function (evt) {
					evt.stop();
					var data: string = evt.data.dataValue;
					let pasteData: string = self.iceCKEditor.processPasteData(data);
					if(self.iceCKEditor.gethasResuables()) {
						self.alertService.clear();
						self.alertService.toast(self.translate.instant('ICE_COURSE.DISABLED_EDITOR_COPY'));
					}
					evt.editor.insertHtml(pasteData);
				});
			 
			});
		}
	}

	setEditorContent(CK_Editor_Variable, className) {
		if(CK_Editor_Variable == 'ice_question_ck_editor'){
			if(className) {
				let el = this.elementRef.nativeElement.querySelector(className);
				if (el != null) {
				let name = this.questionList[this.newQuestion.orderNumber - 1].descriptionXML; 
				if (name != undefined) {
					el.innerHTML = name;
				} else {
					//el.innerHTML = "<p>&nbsp;</p>";
				}
				} else {
				//no element
				}
			} else {
				for(let i = 1; i <= this.questionList.length; i++){
					let el = this.elementRef.nativeElement.querySelector('.q_ck_editor' + this.questionList[i-1].id + this.tabId);
					if (el != null) {
						let name = this.questionList[i - 1].descriptionXML;
						if (name != undefined) {
						el.innerHTML = name;
						} else {
						//el.innerHTML = "<p>&nbsp;</p>";
						}
					} else {
						//no element
					}
					if(this.isViewOnly == true) {
						if (el != null) {
						el.setAttribute("contenteditable", "false");
						el.style.backgroundColor = "#e9ecef";
						} else {
						//no element
						}
					} else {
						if (el != null) {
						el.style.backgroundColor = "";
						} else {
						//no element
						}
					}
				}
			}
		} else if(CK_Editor_Variable == 'ice_answer_ck_editor'){
			if(className) {
				let el = this.elementRef.nativeElement.querySelector(className);
				if (el != null) {
				let name = this.temporyQuestion.answer[this.newAnswer.orderNumber - 1].answerTextXML; 
				if (name != undefined) {
					el.innerHTML = name;
				} else {
					//el.innerHTML = "<p>&nbsp;</p>";
				}
				} else {
				//no element
				}
			} else {
				if(this.temporyQuestion != null){
					for(let i = 1; i <= this.temporyQuestion.answer.length; i++){
						let el = this.elementRef.nativeElement.querySelector('.a_ck_editor'  + this.temporyQuestion.answer[i-1].id + this.tabId);
						if (el != null) {
							let name = this.temporyQuestion.answer[i - 1].answerTextXML;
							if (name != undefined) {
							el.innerHTML = name;
							} else {
							//el.innerHTML = "<p>&nbsp;</p>";
							}
						} else {
							//no element
						}
						if(this.isViewOnly == true) {
							if (el != null) {
							el.setAttribute("contenteditable", "false");
							el.style.backgroundColor = "#e9ecef";
							} else {
							//no element
							}
						} else {
							if (el != null) {
							el.style.backgroundColor = "";
							} else {
							//no element
							}
						}
					}
				}
			}
		} else if(CK_Editor_Variable == 'ice_ddq_cell_ck_editor'){
			if(className) {
				let el = this.elementRef.nativeElement.querySelector(className);
				if (el != null) {
					let name = this.temporyQuestion.answer[0].answerSlots[this.currentIndex].answerCells[this.currenCell].cellText;  
					if (name != undefined) {
					el.innerHTML = name;
					} else {
					//el.innerHTML = "<p>&nbsp;</p>";
					}
				} else {
				//no element
				}
			} else {
				if(this.temporyQuestion != null){
					for(let i = 1; i <= this.temporyQuestion.answer.length; i++){
						//if( this.currenCell != undefined && this.currentIndex != undefined){
							for(let i = 0; i < this.temporyQuestion.answer[0].slotSize; i++){
								for( let j = 0; j < 2; j++){
									if( this.temporyQuestion.answer[0].answerSlots[i].answerCells[j].isTextEnabled == true){
										let el = this.elementRef.nativeElement.querySelector('.cell_ck_editor' + this.temporyQuestion.answer[0].answerSlots[i].answerCells[j].id + this.tabId);
										if (el != null) {
											let name = this.temporyQuestion.answer[0].answerSlots[i].answerCells[j].cellText; 
											if (name != undefined) {
												el.innerHTML = name;
											} else {
												//el.innerHTML = "<p>&nbsp;</p>";
											}
										} else {
											//no element
										}
										if(this.isViewOnly == true) {
											if (el != null) {
												el.setAttribute("contenteditable", "false");
												el.style.backgroundColor = "#e9ecef";
											} else {
											//no element
											}
										} else {
											if (el != null) {
												el.style.backgroundColor = "";
											} else {
											//no element
											}
										}
									} else if( this.temporyQuestion.answer[0].answerSlots[i].answerCells[j].isPictureEnabled == true){
										this.isImageDataAvailable = true;
									} 
								}
							}
					}
				}
			}
		} 
	}

	/**
	 * generateSlots for DD question
	 * @param question 
	 * @param slotcount
	 *  @param isGet
	*/
	public generateSlots(question: IceLCQuestion, slotCount: any, isGet: boolean) {
		if(isGet == false){ // generate slots when creating new ddq
			question.slotsGenerated = true;
			console.log('[generateSlots] (question) ' + JSON.stringify(question));
			if(question.answer[0] == undefined){
				question.answer = [];	
				question.answer[0] = {};	
				question.answer[0].slotSize = 0;	
				question.answer[0].answerSlots = []; 	
			}
				question.answer[0].slotSize = slotCount;   
				question.slotNumberLengthList = [];
				for(let i = 1; i <= slotCount; i++){
					let idGen = Math.floor(Math.random() * 99999 + 1);
					let id = idGen.toString();
					question.slotNumberLengthList.push(i);
					let cell1 : dragAndDropCell = {id: id+ "cell1", cellIndex: 1, matchId: i}
					let cell2 : dragAndDropCell = {id: id+ "cell2", cellIndex: 2, matchId: i}
					let newSlot: dragAndDropSlot = { id: id+ "slot"+ i, slotIndex: i , isCorrect: true, answerCells: [cell1, cell2]}
					question.answer[0].answerSlots.push(newSlot);
				}
		} else if(isGet == true){ // generate slots after geting data from db
			if(question.answer != undefined && question.answer[0] != undefined){
				question.answer[0].slotSize = slotCount;
				if(question.answer[0].slotSize > 0 ){
					question.slotsGenerated = true;
				}
				question.slotNumberLengthList = [];
				for(let i = 1; i <= slotCount; i++){
					question.slotNumberLengthList.push(i);
				}
			}
		}
	}

    /**
	 * DDQ text icon click event
	 * @param question 
	 * @param cellside
	 * @param slotIndex
	*/
	addText(question: IceLCQuestion, side: string, index: number){
		console.log(question);
		this.currentIndex = index;
		this.temporyQuestion = question;
		if(side == 'Left'){
			this.currenCell = 0;
            question.answer[0].answerSlots[index].answerCells[0].isTextEnabled = true;
            question.answer[0].answerSlots[index].answerCells[0].isPictureEnabled = false;
			question.answer[0].answerSlots[index].answerCells[0].cellSide = side;
			// Empty image when text type is selected - RMS
			question.answer[0].answerSlots[index].answerCells[0].graphicId = "";
			question.answer[0].answerSlots[index].answerCells[0].largeImage = "";
			question.answer[0].answerSlots[index].answerCells[0].thumbnailImage = "";
			question.answer[0].answerSlots[index].answerCells[0].title = "";
			//question.answer[0].answerSlots[index].answerCells[0].anstype = "text";
			setTimeout(() => {
				this.focusNewEditor('.cell_ck_editor'+ (question.answer[0].answerSlots[index].answerCells[0].id) + this.tabId , CK_Editor_Type.DDQ_CELL_EDITOR);
				}, 200);
		} else{
			this.currenCell = 1;
            question.answer[0].answerSlots[index].answerCells[1].isTextEnabled = true;
            question.answer[0].answerSlots[index].answerCells[1].isPictureEnabled = false;
			question.answer[0].answerSlots[index].answerCells[1].cellSide = side;
			// Empty image when text type is selected - RMS
			question.answer[0].answerSlots[index].answerCells[1].graphicId = "";
			question.answer[0].answerSlots[index].answerCells[1].largeImage = "";
			question.answer[0].answerSlots[index].answerCells[1].thumbnailImage = "";
			question.answer[0].answerSlots[index].answerCells[1].title = "";
			//question.answer[0].answerSlots[index].answerCells[1].anstype = "text";
			setTimeout(() => {
				this.focusNewEditor('.cell_ck_editor'+ (question.answer[0].answerSlots[index].answerCells[1].id) + this.tabId , CK_Editor_Type.DDQ_CELL_EDITOR);
				}, 200);
		}
		this.learningCheckModified = true;
		this.sharedService.setModifiedStatus(this.tabId, this.learningCheckModified)
		this.sharedService.setPictureReferences(null, null, null, null, null, null, null, null);
		console.log("answer 1 left: "+ question.answer[0].answerSlots[0].answerCells[0].cellContent);
		console.log("answer 1 right: "+ question.answer[0].answerSlots[0].answerCells[1].cellContent);
		console.log("answer 2 left: "+ question.answer[0].answerSlots[1].answerCells[0].cellContent);
		console.log("answer 2 right: "+ question.answer[0].answerSlots[1].answerCells[1].cellContent);
		console.log("answer 3 left: "+ question.answer[0].answerSlots[2].answerCells[0].cellContent);
		console.log("answer 3 right: "+ question.answer[0].answerSlots[2].answerCells[1].cellContent);
		console.log("answer 4 left: "+ question.answer[0].answerSlots[3].answerCells[0].cellContent);
		console.log("answer 4 right: "+ question.answer[0].answerSlots[3].answerCells[1].cellContent);
    }
    
    /**
	 * DDQ image icon click event
	 * @param question 
	 * @param cellside
	 * @param slotIndex
	*/
	addPicture(question: IceLCQuestion, side: string, index: number){
		this.temporyQuestion = question;
		this.currentIndex = index;
		this.learningCheck.selectedImageType = "Image";
		this.currentDDQuestionIndex = question.orderNumber - 1;
        this.changeToImagePanel();
        if(side == 'Left'){
			this.currenCell = 0;
            question.answer[0].answerSlots[index].answerCells[0].isTextEnabled = false;
			question.answer[0].answerSlots[index].answerCells[0].cellText = "";
           // question.answer[0].answerSlots[index].answerCells[0].isPictureEnabled = true;
			question.answer[0].answerSlots[index].answerCells[0].cellSide = side;
			//question.answer[0].answerSlots[index].answerCells[0].anstype = "image";
		} else{
			this.currenCell = 1;
            question.answer[0].answerSlots[index].answerCells[1].isTextEnabled = false;
			question.answer[0].answerSlots[index].answerCells[1].cellText = "";
           //question.answer[0].answerSlots[index].answerCells[1].isPictureEnabled = true;
			question.answer[0].answerSlots[index].answerCells[1].cellSide = side;
			//question.answer[0].answerSlots[index].answerCells[1].anstype = "image";
		}
		this.learningCheckModified = true;
		this.sharedService.setModifiedStatus(this.tabId, this.learningCheckModified)
		this.sharedService.setPictureReferences(null, null, null, question, side, index, this.tabId, this.currentDDQuestionIndex);
	}
	
	/**
	 * DDQ image clear click event
	 * @param questioncell
	*/
	removeContent(dragdropCell: dragAndDropCell){
		dragdropCell.isPictureEnabled = false;
		dragdropCell.isTextEnabled = false;
		dragdropCell.cellText="";
		dragdropCell.largeImage = null;
		dragdropCell.thumbnailImage = null;
		dragdropCell.title = null;
		dragdropCell.type = null;
		dragdropCell.graphicId = null;
		this.learningCheckModified = true;
		this.sharedService.setModifiedStatus(this.tabId, this.learningCheckModified)
	}
    
    /**
	 * open image search tab
	*/
	private changeToImagePanel() {
		SharedService.openReusablepanel('images');
	}

	/**
	 * test radio button click event
	*/
	checkTest(){
		this.isTest = true;
		this.isQuiz = false;
		this.learningCheckModified = true;
		this.sharedService.setModifiedStatus(this.tabId, this.learningCheckModified)
	}

	/**
	 * quiz radio button click event
	*/
	checkQuiz(){
		this.isTest = false;
		this.isQuiz = true;
		this.learningCheckModified = true;
		this.sharedService.setModifiedStatus(this.tabId, this.learningCheckModified)
	}

	numberOnly(event): boolean {
		const charCode = (event.which) ? event.which : event.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}

	addFraction(num: string, den: string) {
		this.modalRef.hide()
		   if (den != "" && num != "") {
			   let denominator = parseInt(den);
			   if (denominator != 0) {
				   const frac = {
					   numerator: num,
					   denominator: den,
					   type: 'fraction'
				   };
				   const fracStr = JSON.stringify(frac);
				   let event = JSON.parse(fracStr);
				   const fracObj = {
					   data: event
				   };
				   const fracObjStr = JSON.stringify(fracObj);
				   let data = JSON.parse(fracObjStr);
				   this.iceCKEditor.insertTagEditArea(data, this.elementRef,editor,CKEDITOR, this.learningCheck, "ICECourse" )
			   } else {
				   this.alertService.clear();
				   this.alertService.error(this.translate.instant('WEB_EDITOR.DENOMINATOR_ZERO'));
			   }
		   } else {
			   this.alertService.clear();
			   this.alertService.error(this.translate.instant('WEB_EDITOR.NUMERATOR_AND_DENOMINATOR_EMPTY'));
		   }
	   }
   
	   addSpecialCharacter(option: any){
		   this.modalRef.hide()
		   if(option != ""){
			   this.iceCKEditor.insertTagEditArea(option, this.elementRef,editor,CKEDITOR, this.learningCheck, "ICECourse")
		   }
	   }

		cancel() {
			let index = this.breadCrumbList.length-1;
			this.sharedService.changeIceItemRequest(this.breadCrumbList[index].iceType, this.breadCrumbList[index]);
			this.breadCrumbList.length = index;
		}

		public openCancelConfirmationModel(template: TemplateRef<any>) {
			if(this.sharedService.getModifiedStatus(this.tabId)){
			  this.modalRef = this.modalService.show(template, this.modalConfig);
			}else{
			  this.cancel();
			}
		  }

		  public modelRefCloseEvent() {
			this.modalRef.hide()
			this.selectedImage = {
				'id': '',
				'thumbnailImage': '',
				'largeImage': '',
				'title': ''
			}
		}

		replaceWhiteSpace(str) {
			return str.replace(/[\s\u200B]/g,'');
		}

		/**
		 * validate form
		 */
		validateForm(): boolean {
			let self = this;
			var editortext_desc = this.replaceWhiteSpace($(".l_ck_editor"+ self.learningCheck.id + self.tabId).text());
			var length_desc = editortext_desc.length;

			if (length_desc < 1) {
				this.alertService.error(this.translate.instant('ICE_LEARNING_CHECK.DESCRIPTION_ERROR'));
				return false;		
			}

			return true; 
		}
	/*
	Learning check : task to be done for adding reusables
	- Reusable adding angular changes - call insertTagEditArea ice-course.component.ts
	- Add records to linkmanagement tables - modify save, update, delete methods
	- Show dependacy in dependancy popup (Changes in tag, statement, system, document, facility)
	- Show checkin, checkout counts in edit reusable popup (Changes in tag, statement, system, document, facility)
	- Changes in reusable update
	*/

}
