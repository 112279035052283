<div>
	<div class="py-2 px-0 mt-2">
		<ul class="col-sm-12 px-0 py-2 list-group mt-2">
			<li class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
				[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'procedurestore', 'custom-list-item-dark': selectedMenuItem != 'procedurestore'}"
				(click)="sideItemClick('procedurestore')">
				<h6 class="flaot-left ml-2 mb-0 sidebar-list-text">
					<i class="far fa-list-alt mx-2" aria-hidden="true"></i>
					{{ 'PROCEDURE_STORE.PS_SIDEBAR_PROCEDURE_STORE' | translate }}
				</h6>
			</li>
			<ul class="col-sm-12 px-0 py-0 list-group rounded-0">
				<li class="list-group-item border-0 rounded-0 px-0 py-2 list-item-bg list-cursor" *ngIf="addProcedureToStore"
					(click)="sideItemClick('addprocedure')"
					[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'addprocedure', 'custom-list-item-dark': selectedMenuItem != 'addprocedure'}">
					<h6 class="flaot-left mb-0 ml-4">
						<i class="far fa-plus-square mx-2" aria-hidden="true"></i>
						{{ 'PROCEDURE_STORE.PS_SIDEBAR_ADD_PROCEDURE' | translate }}
					</h6>
				</li>
			</ul>
			<li class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
				[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'deletedprocedure', 'custom-list-item-dark': selectedMenuItem != 'deletedprocedure'}"
				(click)="sideItemClick('deletedprocedure')">
				<h6 class="flaot-left ml-2 mb-0 sidebar-list-text">
					<i class="far fa-dumpster mx-2" aria-hidden="true"></i>
					{{ 'PROCEDURE_STORE.PS_SIDEBAR_DELETED_PROCEDURE' | translate }}
				</h6>
			</li>
			<li class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor" *ngIf="viewProcedureInstanceOutputList"
				[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'procedureInstance', 'custom-list-item-dark': selectedMenuItem != 'procedureInstance'}"
				(click)="sideItemClick('procedureInstance')">
				<h6 class="flaot-left ml-2 mb-0 sidebar-list-text">
					<i class="far fa-clipboard-list mx-2" aria-hidden="true"></i>
					{{ 'PROCEDURE_STORE.PS_SIDEBAR_PROCEDURE_INSTANCES' | translate }}
				</h6>
			</li>
		</ul>
	</div>
</div>