import { Component, OnInit, ViewChild, TemplateRef, ElementRef, HostListener } from '@angular/core';
import { FolderService } from '../foldertree/foldertree.service';
import { WsResponse } from '../util/ws-response.model'
import { WsType } from '../util/ws-type';
import { DocumentsService } from '../reusables/documents/documents.service';
import { LoadingService } from '../util/loading/loading.service';
import { AlertService } from '../util/alert/alert.service';
import { SharedService } from '../util/shared.service';
import { TopicmapBuilderService } from '../topic-map-builder/topic-map-builder.service';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Module, AllModules } from "@ag-grid-enterprise/all-modules";

declare var $: any;

@Component({
  selector: 'app-insert-reference',
  templateUrl: './insert-reference.component.html',
  styleUrls: ['./insert-reference.component.css']
})
export class InsertReferenceComponent implements OnInit {

   @ViewChild('insert_other_reference') insert_other_reference: TemplateRef<any>;
   @ViewChild('largeImage') largeImage: TemplateRef<any>;
   @ViewChild('ftHeader') ftHeader: ElementRef

	public bsModalRef: BsModalRef;
	public bsModalRefLargerImage: BsModalRef;
	public treeRef: any = null;
	public treeheight: number = 600;
	public selectedTreeItem: any = null   
	public selectedFileId: string;
	public selectedItemId: string = null
	public contentItemId: string;
	public editContentItemId: string;
	public topicContent: any = null;
	public procedureContent: any = null;

	public isImageSelected: boolean = false;
	public enableInsertRef: boolean = false;
	public enableInsertRefCount: boolean = false;
	public imageRef: boolean = true;
	public enableInsertOtherRef: boolean = false;

	public imageRowData: any[] = [];
	public tableRowData: any[] = [];
	public rowData: any[] = [];

	public selectedOtherReference: string = 'figureRef';
	public gridColumnApi: any;
	public modules: Module[] = AllModules;

	public refType = "";
	public refText = "";
	public refId = "";
	public refInsId = "";
	public imageId = "";
	public imageData = "";
	public imageTitle = "";
	public viewImage: any = {
		'largeImage': '',
		'title': ''
		};
   
   
	public TableColumnDefs = [
		//{ headerName: '', field: 'cbox', width:25, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
		{ headerName: 'Title', field: 'refText', autoHeight: true, cellClass: "cell-wrap-text", width: 250, enableRowGroup: true },
		{ headerName: 'refType', field: 'refType', hide: true },
		{ headerName: 'refInsId', field: 'refInsId', hide: true },
		{ headerName: 'refId', field: 'refId', hide: true }
	];

	public imageColumnDefs = [
		//{ headerName: '', field: 'cbox', width: 25, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
		{ headerName: 'Title', field: 'refText', autoHeight: true, cellClass: "cell-wrap-text", width: 250, enableRowGroup: true },
		{ headerName: 'imageId', field: 'imageId', hide: true },
		{ headerName: 'refType', field: 'refType', hide: true },
		{ headerName: 'refInsId', field: 'refInsId', hide: true },
		{ headerName: 'refId', field: 'refId', hide: true }
	];

	public defaultColDef = {
		resizable: true,
		sortable: true,
	}
  
  public data_n: any = {
		'data': [
		]
	}
  constructor(private folderService: FolderService, private documentService: DocumentsService, private loadingService: LoadingService,
     private alertService: AlertService, private topicmapBuilderService: TopicmapBuilderService, private modalService: BsModalService) { }

  ngOnInit() {
    this.folderService.getAllFolders(this);
  }

  
@HostListener('window:resize', ['$event'])
public changeFolderTreeHeight(event?: any) {
	this.treeheight = window.innerHeight - (this.ftHeader.nativeElement.offsetHeight * 3.5);
}

  onSuccess(data: WsResponse, serviceType: WsType): void {
    if (serviceType == WsType.FOLDER_VIEW) {
			//this.folderListPayload = data.payload;
			this.loadData(data.payload);
			//this.filePath = data.payload.path;
		}
  }

  onFail(res: WsResponse, serviceType: WsType): void {
    
  }

  loadData(payload: any) {
		if (payload != null) {
			this.data_n['data'] = [];
         var item = payload.filter(item => item["parent"] === true);
			if (item != null && item.length > 0) {
				item[0]["parentId"] = "#"
				for (let i = 0; i < item.length; i++) {
					this.data_n["data"].push({ id: item[i]["id"], parent: item[i]["parentId"], text: item[i]["name"], path: item[i]["path"], icon: "far fa-folder", type: "Folder" })
				}
				console.log("this.data_n " + this.data_n["data"])
				this.treeRef = $('#referencefoldertree').jstree({
					core: {
						themes: {
							variant: 'normal'
						},
						strings: {
							'New node': 'New Folder'
						},
						data: this.data_n["data"],
						check_callback: function (operation, node, node_parent, node_position, more) {
						},
						force_text: true
						
					},
					types: {
                  Topic: {

                  },
                  Procedure: {

                  },
                  File: {

                  },
                  Folder: {

                  },
						default: {
							// "icon" : "fa fa-file-o "
						}
					},
					plugins: [ "types", "wholerow", "contextmenu", "sort"],
					search: {
						show_only_matches: true,
						show_only_matches_children: true
					},
					contextmenu: {
						items: ''
					},
					state: {
						key:'referencefoldertree'
					},
					'sort' : function(a, b) {
						 let a1 = this.get_node(a).text;
						 let b1 = this.get_node(b).text;
						 return a1.localeCompare(b1, undefined, {numeric: true, sensitivity: 'base'})
				}
					
				});
				$('#referencefoldertree').bind("select_node.jstree", (evt, data) => this.itemSelectEvent(evt, data))
            $('#referencefoldertree').bind("loaded.jstree", function (event, data) {
               $(this).jstree("open_all");
            })
            $('#referencefoldertree').on('loaded.jstree', function() {
               $('#referencefoldertree').jstree('select_node', item[0].id);
             });
			}
			
		}
  }
  
   	public itemSelectEvent(evt: any, data: any) {
		this.enableInsertOtherRef = false;
		this.tableRowData = [];
		this.imageRowData = [];
		this.selectedTreeItem = data
		this.selectedItemId = data.node.id

		this.refType =  data.node.original.type;
		this.refText = data.node.text;
		this.refId = this.selectedItemId;

		if (this.refType == 'Folder') {
			this.enableInsertOtherRef = false;
			this.loadingService.showLoading(true, null, "", null)
			this.documentService.getSelectedFolderData(data.node.id)
				.subscribe(data => {
				this.loadingService.hideLoading()
				data.payload.forEach(element => {
					var createdNodde = $('#referencefoldertree').jstree(true).get_node(element.id);           
					if (createdNodde == false) {
						if (element.type == "Folder"|| element.type == "Topic"|| element.type == "Procedure" || element.type == "ContentItem") {
							if (element.type == "ContentItem") {
							if(element.status == "Active"){
								if ( element.id == this.contentItemId || element.id == this.editContentItemId) {
									$('#referencefoldertree').jstree().create_node(this.selectedItemId, {
										"id": element.id,
										"text": element.name,
										"icon": "fa fa-file-pdf",
										"type": "File",
										"state": { "opened" : true, "selected" : true}
									});
								} else {
									$('#referencefoldertree').jstree().create_node(this.selectedItemId, {
										"id": element.id,
										"text": element.name,
										"icon": "fa fa-file-pdf",
										"type": "File"
									});
								}
							}else{
								//nothimg to do
							}
							}else if (element.type == "Topic") {
							if(element.status == "Active"){
								if ( element.id == this.contentItemId || element.id == this.editContentItemId) {
									$('#referencefoldertree').jstree().create_node(this.selectedItemId, {
										"id": element.id,
										"text": element.name,
										"icon": "far fa-lightbulb",
										"type": "Topic",
										"state": { "opened" : true, "selected" : true}
									});
								} else {
									$('#referencefoldertree').jstree().create_node(this.selectedItemId, {
										"id": element.id,
										"text": element.name,
										"icon": "far fa-lightbulb",
										"type": "Topic"
									});
								}
							}else{
								//nothimg to do
							}
							}else if (element.type == "Procedure") {
							if(element.status == "Active"){
								if ( element.id == this.contentItemId || element.id == this.editContentItemId) {
									$('#referencefoldertree').jstree().create_node(this.selectedItemId, {
										"id": element.id,
										"text": element.name,
										"icon": "far fa-layer-group ",
										"type": "Procedure",
										"state": { "opened" : true, "selected" : true}
									});
								} else {
									$('#referencefoldertree').jstree().create_node(this.selectedItemId, {
										"id": element.id,
										"text": element.name,
										"icon": "far fa-layer-group ",
										"type": "Procedure"
									});
								}
							}else{
								//nothimg to do
							}
							} else {
									$('#referencefoldertree').jstree().create_node(this.selectedItemId, {
										"id": element.id,
										"text": element.name,
										"icon": "far fa-folder",
										"type": "Folder"
									});
							}
						} else {
						}

					} else {
						//node is created
					}
				});
				$("#referencefoldertree").jstree("open_all", this.selectedItemId); 
				},
				error => {
					this.loadingService.hideLoading()
					this.alertService.clear()
					this.alertService.error(error.statusDescription)
				});


		}

		else if (this.refType == "Topic") {
			this.enableInsertOtherRef = true;
			this.topicmapBuilderService.getEditorXMLforOtherRef(this.refId).subscribe(
			data => {
				if (data != null) {
					this.topicContent = data.payload.htmlContent;
				}
			},
			error => {
				console.log('[getTopicXMLContent] (error) ' + JSON.stringify(error.statusDescription));
				this.alertService.error(error.statusDescription);
			}
			);

		} else if (this.refType == "Procedure") {
			this.enableInsertOtherRef = true;
			this.topicmapBuilderService.getBasicFileDataforOtherRef(this.refId, this.refType).subscribe(
				data => {
				if (data != null) {
					this.procedureContent = data.payload.content;
				}
				},
				error => {
				console.log('[getProcedureContent] (error) ' + JSON.stringify(error.statusDescription));
				this.alertService.error(error.statusDescription);
				}
			);

		} else if (this.refType == "File") {
			this.enableInsertOtherRef = false;
		}
    }

   public insertReferenceBtnEvent() {
      console.log("insertReferenceBtnEvent");
      let refData = { refId: this.refId, refInsId: this.refInsId, refText: this.refText, refType: this.refType, type: "crossReference" };
      if (this.refType == "Topic" || this.refType == "Procedure" || this.refType == "File") {
         let refStr = JSON.parse(JSON.stringify(refData));
         let tabType = SharedService.getTabComponentType();
         console.log(tabType);
         console.log(refStr);
         if (tabType == 'Editor') {
            SharedService.insertEditorRequest(refStr);
         } else if (tabType == 'Procedures') {
            SharedService.insertProceduresRequest(refStr);
         }
      }
   }

   public insertOtherReferenceBtnEvent() {
		console.log('[insertOtherReferenceBtnEvent]' +this.refType);
		this.isImageSelected = false;
		let tabType = SharedService.getTabComponentType();
			if (tabType == 'Editor' || tabType == 'Procedures' ) {
				this.enableInsertRef = true;
			} else {
				this.enableInsertRef = false;
			}		
		if (this.refType == "Topic") {
			if (this.topicContent != null) {
				this.tableRowData = [];
				this.imageRowData = [];
				var xmlParser = new DOMParser();
				var xmlDoc = xmlParser.parseFromString(this.topicContent, "text/xml");
				var tableList = xmlDoc.querySelectorAll('table');
				for (let i = 0; i < tableList.length; i++) {
					let tableInsId = tableList[i].getAttribute("insid");
					let tabletype = tableList[i].getAttribute("type");
					let tableTitleNodeList = tableList[i].querySelector("caption");
					let tableTitle = "";
					if (tableTitleNodeList != null && tableTitleNodeList != undefined) {
						tableTitle = tableTitleNodeList.textContent;
					}
					let tableRefType = "";
					if (tabletype == "commissioning") {
						tableRefType = tableList[i].getAttribute("comtype");
					} else {
						tableRefType = "Table";
					}
					this.tableRowData.push({
						refType: tableRefType,
						refInsId: tableInsId,
						refText: tableTitle,
						refId: this.refId,
					})
				}
				//var
				var imageList = xmlDoc.querySelectorAll('figure');
				let imgInsId = "";
				let imageTitle = "";
				let imageId = "";
				for (let i = 0; i < imageList.length; i++) {
					let imgNodeList = imageList[i].querySelector("img");
					imageId = imgNodeList.getAttribute("id");
					imgInsId = imgNodeList.getAttribute("insid");
					let titleNodeList = imageList[i].querySelector("figcaption");
					if (titleNodeList != null && titleNodeList != undefined) {
						imageTitle = titleNodeList.textContent;
					} else {
						imageTitle = "";
					}
					this.imageRowData.push({
						refType: "Figure",
						refInsId: imgInsId,
						refText: imageTitle,
						imageId: imageId,
						refId: this.refId,
					})
            }
            console.log(this.imageRowData);

			}
		} else if (this.refType == "Procedure") {
			if (this.procedureContent != null) {
				this.tableRowData = [];
				this.imageRowData = [];
				var xmlParser = new DOMParser();
				var xmlDoc = xmlParser.parseFromString(this.procedureContent, "text/xml");
				var proImageList = xmlDoc.querySelectorAll('Figure');
				let imgInsId = "";
				let imageTitle = "";
				let imageId = "";
				for (let i = 0; i < proImageList.length; i++) {
					let imgNodeList = proImageList[i].querySelector("Image");
					imageId = imgNodeList.getAttribute("id");
					imgInsId = proImageList[i].getAttribute("insID");
					let titleNodeList = proImageList[i].querySelector("Title");
					imageTitle = titleNodeList.textContent;
					this.imageRowData.push({
						refType: "Figure",
						refInsId: imgInsId,
						refText: imageTitle,
						imageId: imageId,
						refId: this.refId,
					})
				}

			}

		}
		this.showFigureRef();
		//this.gridOptions.api.sizeColumnsToFit();
		this.openModal(this.insert_other_reference);
   }
   
   /**
	 * Figure reference tab click event
	 */
	showFigureRef() {
		this.imageRef = true;
		this.selectedOtherReference = "figureRef";
		this.rowData = this.imageRowData;
		if (this.rowData.length > 0) {
			this.enableInsertRefCount = true;
		} else {
			this.enableInsertRefCount = false;
      }
      console.log(this.rowData);
   }

   /**
	 * Table reference tab click event
	 */
	showTableRef() {
		this.selectedOtherReference = "tableRef";
		this.imageRef = false;
		this.rowData = this.tableRowData;
		if (this.rowData.length > 0) {
			this.enableInsertRefCount = true;
		} else {
			this.enableInsertRefCount = false;
		}
	}
   
   openModal(template: TemplateRef<any>) {
		this.bsModalRef = this.modalService.show(template);
   }
   openLargerImageModal(template: TemplateRef<any>, viewImage: any) {
		this.viewImage = viewImage;
		this.bsModalRefLargerImage = this.modalService.show(template);
	}
   
   onGridReady(params) {
		//this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		params.api.sizeColumnsToFit();

   }
   
   onRowClick(event) {
		console.log(event);
		if (event.node.isSelected()) {
			console.log("deselected");
			//this.enableInsertRef = false;
		} else {
			//console.log("selected" +event.data);
			event.node.setSelected(true);

			this.refId = event.data.refId,
			this.refInsId = event.data.refInsId;
			this.refText = event.data.refText;
			this.refType = event.data.refType;
			this.imageId = event.data.imageId;	

			if (this.imageId != null) {
	      	this.isImageSelected = true;
				this.topicmapBuilderService.getImageView(this.imageId).subscribe(
					data => {
						if (data != null) {
							this.imageData = data.payload.imageData;
							this.imageTitle = data.payload.title;

							this.viewImage = {
								'largeImage': data.payload.imagedata,
								'title': data.payload.title
							}
						}
					},
					error => {
						console.log('[getImageView] (error) ' + JSON.stringify(error.statusDescription));
						this.alertService.error(error.statusDescription);
					}
				);
			}
		}
   }
   
   viewSelectedImage($event) {
		this.openLargerImageModal(this.largeImage, this.viewImage);
   }
   
   insertOtherReference(event) {
		let refData = { refId: this.refId, refInsId: this.refInsId, refText: this.refText, refType: this.refType, type: "crossReference" };
		//if (this.refType == "Figure" || this.refType == "Table") {
		let refStr = JSON.parse(JSON.stringify(refData));
		let tabType = SharedService.getTabComponentType();
		if (tabType == 'Editor') {
			SharedService.insertEditorRequest(refStr);
		} else if (tabType == 'Procedures') {
			SharedService.insertProceduresRequest(refStr);
		}

	}

}
