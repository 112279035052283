<div class="flex-row" id="proc_editor" style="overflow-y: auto; overflow-x: hidden" [style.height.px]="divHeight">
	<div class="container-fluid my-2">
<!-- <div class="container-fluid px-0 pb-5" style="overflow-y: auto; overflow-x: hidden" [style.height.px]="divHeight">
	<div class="container-fluid px-0 pb-5"> -->
		<!-- breadcrumb 
			<nav aria-label="breadcrumb my-1">
			<ol class="breadcrumb my-0 px-2 py-1">
				<li class="breadcrumb-item active" aria-current="page" (click)="backtoCourse()">Sample Course</li>
			</ol>
		</nav>
		-->
		<nav aria-label="breadcrumb my-1">
			<ol class="breadcrumb my-0 px-2 py-1">
				<li class="breadcrumb-item active" *ngFor="let breadCrumbListItem of breadCrumbList ; let i = index"   aria-current="page" (click)="clickBreadCrumb(breadCrumbListItem,i)">
				{{ breadCrumbListItem.title}}
				<!-- <div [innerHTML]="breadCrumbListItem.title"></div> -->
				</li>
				<li class="currentItem breadcrumb-item">{{LCDescriptionShowInDetails}}</li>
			</ol>
		</nav>

		<div class="card my-2">
			<div class="card-header py-1">
				<p class="col-sm-7 my-1 float-left h6  px-0">
					{{ 'ICE_LEARNING_CHECK.LEARNING_CHECK_DETAILS' | translate }}
				</p>
				<button class="btn btn-sm btn-dark float-right mx-2" type="button" 
							[disabled]="isViewOnly" (click) = "save()" tooltip ="Save Learning Check" >
							<i class="fal fa-save"></i>
				</button>
				<button class="btn btn-sm btn-dark float-right mx-2" type="button" 
							(click)="openCancelConfirmationModel(closeConfirmModel)" tooltip ="Cancel" >
							<i class="fas fa-times"></i>
				</button>
			</div>
			<div class="card-body">
				<!-- description -->
				<div class="form-group">
					<label for="lcDescription">
						{{ 'ICE_LEARNING_CHECK.DESCRIPTION_LABEL' | translate }}
					</label>
					<div class="badge-required mx-2"></div>
						<div class="border rounded">
							<div rows="2" contenteditable="false" id="{{learningCheck.id}}"
								class="toggle-edit{{tabId}}{{userId}} l_ck_editor{{learningCheck.id}}{{tabId}} leditor">
							</div>
						</div> 	
				</div>
				

				<!--
				<div class="my-3">
					<input type="text" class="form-control form-control-sm" id="learningCheckTitle"
						placeholder="{{ learningCheckDescription }}" disabled>
				</div>


				<div class="checkboxes" >
						<label class="checkbox-inline">
						<input  class="form-check-input" type="radio" name="Test" value="True" (click)="checkTest()" [(ngModel)]="isTest">
						<label class="form-check-label" for="tfqTrue">
							{{ 'ICE_LEARNING_CHECK.TEST' | translate }}
						</label>
						</label>
						<label class="checkbox-inline">
						<input  class="form-check-input" type="radio" name="Quiz" value="False" (click)="checkQuiz()" [(ngModel)]="isQuiz">
						<label class="form-check-label" for="tfqTrue">
							{{ 'ICE_LEARNING_CHECK.QUIZ' | translate }}
						</label>
						</label>
				</div>
				
				-->
				
				<form>
					<input  class="radio-inline mr-1" type="radio" name="optradio1" (click)="checkTest()" [disabled]="isViewOnly" 
					[checked]="isTest">
					<label class="form-check-label mr-3" >
						{{ 'ICE_LEARNING_CHECK.TEST' | translate }}
					</label>
					<input  class="radio-inline mr-1" type="radio" name="optradio2" (click)="checkQuiz()" [disabled]="isViewOnly" 
					[checked]="isQuiz">
					<label class="form-check-label " >
						{{ 'ICE_LEARNING_CHECK.QUIZ' | translate }}
					</label>
				</form>

				<!-- question list quick selector -->
				<!-- <div *ngIf = "questionList.length > 0" class="card card-quick-selection my-3">
					<div class="card-body p-1">
						<span *ngFor="let question of questionList" class="badge m-1 quick-select-text cursor-pointer"
							[ngClass]="{'badge-secondary': selectedQuestionId != question.id, 'badge-warning': selectedQuestionId == question.id}"
							(click)="showInfoQuestion(question)">
							{{question.orderNumber}}
						</span>
					</div>
				</div> -->
				<!-- question list -->
				<!-- header -->
				<div class="nav my-1">
					<div class="col-sm-12 border-bottom px-0">
						<p class="float-left m-0 question-header-text">
							<span class="badge badge-warning mr-2 mb-2">{{questionList.length}}</span>
							{{ "ICE_LEARNING_CHECK.BTN_ADD_NEW_QUESTION" | translate }}  
						</p> 
						<!-- <button [disabled]="isViewOnly" class="btn btn-sm btn-dark float-right mx-2 mb-2" type="button"
						(click)="save()"  tooltip="Save ">
							<i class="fal fa-save"></i>
						</button> -->
						<div class="dropdown">
							<button [disabled]="isViewOnly" type="button" class="btn btn-sm btn-dark mx-2 mb-2 float-right"
								data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="addNewQuestionBtn" tooltip="Add new question">
								<i class="far fa-plus" aria-hidden="true"></i>
							</button>
							<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
								<a class="dropdown-item" (click)="addNewQuestion('singleanswer')">
									{{ "ICE_LEARNING_CHECK.QUESTION_TYPE.SINGLE_ANSWER" | translate }}
								</a>
								<a class="dropdown-item" (click)="addNewQuestion('multipleanswer')">
									{{ "ICE_LEARNING_CHECK.QUESTION_TYPE.MULTIPLE_ANSWER" | translate }}
								</a>
								<a class="dropdown-item" (click)="addNewQuestion('truefalse')">
									{{ "ICE_LEARNING_CHECK.QUESTION_TYPE.TRUE_FALSE_ANSWER" | translate }}
								</a>
								<a class="dropdown-item" (click)="addNewQuestion('dragdrop')">
									{{ "ICE_LEARNING_CHECK.QUESTION_TYPE.DRAG_DROP_ANSWER" | translate }}
								</a>
								<!--
								<a class="dropdown-item" (click)="addNewQuestion('fillintheblank')">
									{{ "ICE_LEARNING_CHECK.QUESTION_TYPE.FILL_THE_BLANK" | translate }}
								</a>
								-->
							</div>
						</div>
					</div>
				</div>
				<!-- content -->
				<div class="my-2">
					<div class="card-body p-0">
						<div *ngFor="let question of questionList | orderBy : 'orderNum'; let q = index" class="card my-2">
							<div class="card-body container-fluid p-2">
								<div class="col-sm-12 float-left px-0">
									<div class="input-group input-group-sm float-left">
										<div class="input-group-prepend input-group-qestion-aligned">
											<span class="input-group-text" id="basic-addon1">
												<span class="badge badge-warning mr-2">
													{{question.orderNumber}} 
												</span>
												{{getStringFromType(question.questionType)}} 
											</span>
										</div>
										<div class="col-sm-9 float-left pl-0 pr-0">
											<div class="border rounded">
												<div rows="2" contenteditable="false" id="{{question.id}}"
													class="toggle-edit{{tabId}}{{userId}} q_ck_editor{{question.id}}{{tabId}} qeditor"> <!-- *ngIf="selectedQuestionId != question.id"  -->
												</div>
											</div>
										<!--
											<div *ngIf="selectedQuestionId == question.id"  class="border rounded">
												<div rows="2" contenteditable="false" id="{{question.id}}"
													class="toggle-edit{{tabId}}{{userId}} q_ck_editor{{question.id}}{{tabId}} qeditor">
												</div>
											</div> 
										-->	
										</div>
										<!--<input *ngIf="selectedQuestionId != question.id" type="text" class="form-control"
											id="iceLCQuestionDescription"  [(ngModel)]="question.description"
											placeholder="{{ 'ICE_LEARNING_CHECK.QUESTION.TITLE_PLACEHOLDER' | translate }}"
											aria-describedby="basic-addon1"> question description-->
										<!--<textarea *ngIf="selectedQuestionId == question.id" class="form-control"
											rows="2" [value]="question.description" [symbiosisFocus]="selectedQuestionId == question.id"
											placeholder="{{ 'ICE_LEARNING_CHECK.QUESTION.TITLE_PLACEHOLDER' | translate }}"></textarea> textarea for question expand with qestion description   -->
									</div>
								</div>
								<!-- question section -->
								<div *ngIf="selectedQuestionId == question.id" class="col-sm-12 float-left px-0 my-2">
									<div [ngSwitch]="question.questionType">
										<div *ngSwitchCase="'singleanswer'"> 
											<form *ngFor="let answer of question.answer | orderBy : 'orderNum'; let sa = index">
												<div class="input-group input-group-sm float-left input-padding">
													<div class="input-group-prepend input-group-answer-aligned">
														<div class="input-group-text">
															<input type="radio" id="saqRadioAnswer" name="saqRadioAnswer" [disabled]="isViewOnly"
																[checked]="answer.status"
																(change)="changeAnswerStatus(question, answer)"> 
														</div>
													</div>
													<div class="col-sm-10 float-left pl-0 pr-0">
														<div class="border rounded">
															<div rows="2" contenteditable="false" id="{{answer.id}}" 
																class="toggle-edit{{tabId}}{{userId}} a_ck_editor{{answer.id}}{{tabId}} aeditor">
															</div>
														</div> 
													</div> 
													<!--<input type="text" class="form-control" id="saqAnswer" name="saqAnswer"
													[(ngModel)]="answer.answerText"
														placeholder="{{ 'ICE_LEARNING_CHECK.ANSWER.TITLE_PLACEHOLDER' | translate }}"> answer input  -->
													<div class="input-group-append" (click)="removeAnswer(question, answer, sa)"> <!--answer delete button  -->
														<span class="input-group-text close"><i class="far fa-times"></i></span>
													</div>
												</div>
											</form>
										</div>
										<div *ngSwitchCase="'multipleanswer'">
											<form *ngFor="let answer of question.answer  | orderBy : 'orderNum'; let ma = index" >
												<div class="input-group input-group-sm float-left input-padding">
													<div class="input-group-prepend input-group-answer-aligned">
														<div class="input-group-text">
															<input type="checkbox" id="maqCheckBoxAnswer" name="maqCheckBoxAnswer"
																[checked]="answer.status" [disabled]="isViewOnly" 
																(change)="changeMultipleAnswerStatus(question, answer, ma, q)">
														</div>
													</div>
													<div class="col-sm-10 float-left pl-0 pr-0">
														<div class="border rounded">
															<div rows="2" contenteditable="false" id="{{answer.id}}"
																class="toggle-edit{{tabId}}{{userId}} a_ck_editor{{answer.id}}{{tabId}} aeditor">
															</div>
														</div> 
													</div> 

													<!--<input type="text" class="form-control" id="maqAnswer" name="maqAnswer"
														[(ngModel)]="answer.answerText"
														placeholder="{{ 'ICE_LEARNING_CHECK.ANSWER.TITLE_PLACEHOLDER' | translate }}">-->
													<div class="input-group-append" (click)="removeAnswer(question, answer, ma)">
														<span class="input-group-text close"><i class="far fa-times"></i></span>
													</div>
												</div>
											</form>
										</div>
										<div *ngSwitchCase="'truefalse'">
											<form class="ml-4">
												<div class="form-check">
													<input class="form-check-input" type="radio" name="tfqAnswer" id="tfqTrue" [disabled]="isViewOnly"
														value="True" (click)="setradio(question,'true')"  [checked]="question.answer[0].status ? true: false" >
													<label class="form-check-label" for="tfqTrue">
														{{ 'ICE_LEARNING_CHECK.TRUE' | translate }}
													</label>
												</div>
												<div class="form-check">
													<input class="form-check-input" type="radio" name="tfqAnswer" id="tfqFalse" [disabled]="isViewOnly"
														value="False" (click)="setradio(question,'false')" [checked]="question.answer[0].status ? false: true">
													<label class="form-check-label" for="tfqFalse">
														{{ 'ICE_LEARNING_CHECK.FALSE' | translate }}
													</label>
												</div>
											</form>
										</div>
										<div *ngSwitchCase="'dragdrop'">
											<form class="">
												<div class="form-group form-row mb-2">
													<label class="col-sm-12" for="fbqAnswerlabel mb-1">
														{{ 'ICE_LEARNING_CHECK.ANSWER.LABEL_NUMBER_OF_SLOTS' | translate }}
													</label>
													<div class="col-sm-8">
														<select class="form-control form-control-sm" id="ddqAnswer" [disabled]="isViewOnly" #selectedValue>
															<option (click)="selectSlotNumber(slotNumberList[i])"
															*ngFor="let number of slotNumberList | orderBy : 'orderNum'; let i = index"
															[value]="slotNumberList[i]">{{slotNumberList[i]}}</option>
														</select>
													</div>
													<div class="col-sm-4">
														<button class="btn btn-primary btn-sm mb-2 float-right"
														(click)="generateSlots(question, selectedValue.value, false)"
														[disabled]="question.slotsGenerated || isViewOnly"> 
															{{ 'ICE_LEARNING_CHECK.ANSWER.BTN_TEXT_GENERATE_SLOTS' | translate }}
														</button>
													</div>
												</div>
												<div class="form-group form-row mb-2">
													<div *ngFor="let number of question.slotNumberLengthList | orderBy : 'orderNum'; let j = index" class="col-sm-3 p-1">
														<div class="card card-secondary p-2">
															<div class="card ddq-card-body mb-1">
																<div class="card-header p-1">
																	<span class="badge badge-secondary">Left</span>
																	<button id="btnRemoveDDQAContent" type="button"
																		class="my-1 mx-2 close float-right" [disabled]="isViewOnly" 
																		(click)="removeContent(question.answer[0].answerSlots[j].answerCells[0])" tooltip ="Clear">
																		<i class="far fa-eraser"></i>
																	</button>
																	<button id="btnAddDDQAText" type="button"
																		class="my-1 mx-2 close float-right" [disabled]="isViewOnly" 
																		(click)="addText(question, 'Left', j)" tooltip ="Add Text">
																		<i class="far fa-font"></i>
																	</button>
																	<button id="btnAddDDQAImage" type="button"
																		class="my-1 mx-2 close float-right" [disabled]="isViewOnly"
																		(click)="addPicture(question, 'Left', j)" tooltip ="Add Image">
																		<i class="far fa-image"></i>
																	</button>
																</div>
																<div *ngIf= " 
																question.answer[0].answerSlots[j].answerCells[0].isTextEnabled ">
																	<div class="card-body "> 
																		<div class="border rounded">
																			<div rows="2" contenteditable="false" id="{{question.id}}"
																				class="toggle-edit{{tabId}}{{userId}} cell_ck_editor{{question.answer[0].answerSlots[j].answerCells[0].id}}{{tabId}} qeditor"> 
																			</div>
																		</div> 
																	</div>
																</div>
																<div *ngIf= "question.answer[0].answerSlots[j].answerCells[0].isPictureEnabled &&
																	isImageDataAvailable ">
																	<div class="card-body pl-0 pr-1 pb-0"> 
																		<img class="img-fluid" style="width: 8rem;display: block;margin-left: auto;margin-right: auto;" 
																		src="data:image/jpeg;base64,{{question.answer[0].answerSlots[j].answerCells[0].thumbnailImage}}"
																		alt="{{question.answer[0].answerSlots[j].answerCells[0].title}}" (click)="showLargeImage(largeImage,question.answer[0].answerSlots[j].answerCells[0].graphicId)">
																	</div>
																</div>
															</div>
															<div class="card ddq-card-body mt-1">
																<div class="card-header p-1">
																	<span class="badge badge-secondary">Right</span>
																	<button id="btnRemoveDDQAContent" type="button"
																		class="my-1 mx-2 close float-right" [disabled]="isViewOnly" 
																		(click)="removeContent(question.answer[0].answerSlots[j].answerCells[1])" tooltip ="Clear">
																		<i class="far fa-eraser"></i>
																	</button>
																	<button id="btnAddDDQAText" type="button"
																		class="my-1 mx-2 close float-right" [disabled]="isViewOnly" 
																		(click)="addText(question, 'Right', j)" tooltip ="Add Text">
																		<i class="far fa-font"></i>
																	</button>
																	<button id="btnAddDDQAImage" type="button"
																		class="my-1 mx-2 close float-right" [disabled]="isViewOnly" 
																		(click)="addPicture(question, 'Right', j)" tooltip ="Add Image">
																		<i class="far fa-image"></i>
																	</button>
																</div>
																<div *ngIf= " 
																	question.answer[0].answerSlots[j].answerCells[1].isTextEnabled ">
																	<div class="card-body">
																		<div class="border rounded">
																			<div rows="2" contenteditable="false" id="{{question.id}}"
																				class="toggle-edit{{tabId}}{{userId}} cell_ck_editor{{question.answer[0].answerSlots[j].answerCells[1].id}}{{tabId}} qeditor"> 
																			</div>
																		</div> 
																		</div>
																	</div>
																	<div *ngIf= " 
																	question.answer[0].answerSlots[j].answerCells[1].isPictureEnabled && isImageDataAvailable">
																		<div class="card-body pl-0 pr-1 pb-0">
																			<img class="img-fluid" style="width: 8rem;display: block;margin-left: auto;margin-right: auto;"
																			src="data:image/jpeg;base64,{{question.answer[0].answerSlots[j].answerCells[1].thumbnailImage}}"
																			alt="{{question.answer[0].answerSlots[j].answerCells[1].title}}" (click)="showLargeImage(largeImage,question.answer[0].answerSlots[j].answerCells[1].graphicId)">
																		</div>
																	</div>
															</div>
														</div>
													</div>
												</div>
											</form>
										</div>
										<div *ngSwitchCase="'fillintheblank'">
											<form>
												<div class="form-group">
													<label for="fbqAnswerlabel mb-1">
														{{ 'ICE_LEARNING_CHECK.ANSWER.TITLE_LABEL' | translate }}
													</label>
													<input type="text" class="form-control form-control-sm" id="fbqAnswer"
														[(ngModel)]="question.answer[0].answerText" name="ftbqAnswer"
														placeholder="{{ 'ICE_LEARNING_CHECK.ANSWER.TITLE_PLACEHOLDER' | translate }}">
												</div>
											</form>
										</div>
									</div>
								</div>
								<div class="col-sm-12 float-left px-0"><!-- add new ,delete,change position buttons -->
									<div class="col-sm-3 float-right px-0">
										<button id="btnGotoQuestion" type="button" tooltip = "{{( selectedQuestionId == question.id) ? 'Hide' : 'Show'}}" class="my-1 ml-3 close float-right"
											(click)="showInfoQuestion(question)" >
											<i class="far"
												[ngClass]="{'fa-angle-up': selectedQuestionId == question.id, 'fa-angle-down': selectedQuestionId != question.id}"></i>
										</button>
										<button id="btnDeleteQuestion" type="button" tooltip ="Delete" class="my-1 mx-2 close float-right"
											[disabled]="isViewOnly" (click)="openDeleteQuestionModel(question, deleteQuestionModel)">
											<i class="far fa-trash-alt"></i>
										</button>
										<button *ngIf="(questionList.length > 1) && (q != 0)" id="btnMoveUpQuestion" type="button"
											[disabled]="isViewOnly" class="my-1 mx-2 close float-right" tooltip ="Reorder" (click)="changePositionQuestion(question, 'up')">
											<i class="far fa-arrow-up"></i>
										</button>
										<button *ngIf="(questionList.length > 1) && (q != (questionList.length - 1))"
											id="btnMoveDownQuestion" type="button" tooltip ="Reorder" class="my-1 mx-2 close float-right"
											[disabled]="isViewOnly" (click)="changePositionQuestion(question, 'down')">
											<i class="far fa-arrow-down"></i>
										</button>
										<!--
											<button *ngIf="selectedQuestionId == question.id" id="btnSaveQuestion" type="button"
											class="my-1 mx-2 close float-right" (click)="saveQuestion(question)">
											<i class="far fa-save"></i>
											</button>
										-->
										<button
											*ngIf="(selectedQuestionId == question.id) && (question.questionType == 'singleanswer' || question.questionType == 'multipleanswer')"
											id="btnAddAnswer" type="button" tooltip ="Add new" class="my-1 mx-2 close float-right"
											[disabled]="isViewOnly" (click)="addAnswer(question)">
											<i class="far fa-layer-plus"></i>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- hidden buttons to handle toolbar special char and fraction-->
<button tooltip="{{ 'WEB_EDITOR.ADD_SPE_CHAR' | translate }}" id={{addSpecialCharDivId}} container="body" type="button"
	(click)="openModal(spec_char)" hidden="true" class="btn btn-outline-primary btn-sm ml-1 mr-1">
	<i class="fas fa-omega" aria-hidden="true"></i>
</button>
<button tooltip="{{ 'WEB_EDITOR.ADD_FRACTION' | translate }}" id={{addFractionDIvId}} container="body" type="button"
	(click)="openModal(fraction)" hidden="true" class="btn btn-outline-primary btn-sm ml-1 mr-1">
	<i class="fas fa-divide" aria-hidden="true"></i>
</button>

<!-- special characters modal -->
<ng-template #spec_char>
	<div class="modal-header">
		<h4 class="modal-title pull-left">{{ 'WEB_EDITOR.SPE_CHAR' | translate}}</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="container-fluid">
			<div class="row">
				<div class="col-md-2" *ngFor="let speChar of speCharList; let n=index">
					<button type="button" class="btn default" (click)="addSpecialCharacter(speChar)"
						style="background-color: inherit;">
						{{speChar.data.symbol}}</button>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<!--fraction modal-->
<ng-template #fraction>
	<div class="modal-header p-2">
		<h4 class="modal-title pull-left">{{ 'WEB_EDITOR.FRACTION' | translate}}</h4>
		<button type="button" class="close pull-right p-0 m-0" aria-label="Close" (click)="modalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="container-fluid">
			<div class="row">
				<div class="input-group input-group-sm ">
					<div class="input-group-prepend">
						<span class="input-group-text">Numerator / Denominator</span>
					</div>
					<input (keypress)="numberOnly($event)" #dec_value type="text" aria-label="Numerator"
						class="form-control" [(ngModel)]="numerator">
					<input (keypress)="numberOnly($event)" #dec_valueD type="text" aria-label="Denominator"
						class="form-control" [(ngModel)]="denominator">
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer p-2">                     
		<div class="col-sm-12 px-0"> 
			<div class="badge badge-warning float-left" *ngIf="numerator != '' && denominator != '' && numerator != null && denominator != null">
				<span class="h6 m-0">{{numerator}} / {{denominator}}</span>
			</div>
			<button type="button" class="btn btn-secondary btn-sm float-right mx-1" (click)="modalRef.hide()">{{ 'CANCEL' | 
				translate}}</button>
			<button type="button" class="btn btn-primary btn-sm float-right mx-1"
				(click)="modalRef.hide();addFraction(dec_value.value,dec_valueD.value);">{{
					'ADD' | translate}}</button>
		</div>
	</div>
</ng-template>

<!--template for deleting learning check question-->
<ng-template #deleteQuestionModel>
	<div class="modal-header">
		<!-- <div *ngIf="isLCDeleteEnable"> -->
			<h4 class="modal-title pull-left">{{ 'ICE_LEARNING_CHECK.DELETE_LEARNINGCHECK' | translate }} </h4>
		<!-- </div>
		<div *ngIf="!isLCDeleteEnable">
			<h4 class="modal-title pull-left">{{ 'ICE_LEARNING_CHECK.CANNOT_DELETE_LEARNINGCHECK' | translate }} </h4>
		</div> -->
		<button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-default" (click)="modalRef.hide()">{{ 'CANCEL' | translate}}</button>

		<button *ngIf="deletingQuestion!= null" type="button" class="btn btn-warning"
			(click)="modalRef.hide();deleteIceQuestion(deletingQuestion);">{{'OK' | translate}}</button>
	</div>
</ng-template>

<!--template for closing Learning Check-->
<ng-template #closeConfirmModel>
	<div class="modal-header">
		<h4 class="modal-title pull-left">{{'CLOSE_CONFIRM' | translate }}</h4>
	</div>    	
	<div class="modal-body">
		<div class="container">
		  <h5 class='warn-text-editor'>{{ 'WARNING' | translate}}</h5>
		  <h5>{{ 'ICE_LEARNING_CHECK.CLOSE_CONFIRM_MSG' | translate}} </h5>
		</div>
	</div> 
	<div class="modal-footer">
	  <button  type="button" class="btn btn-default" (click)="cancel();modalRef.hide();">{{'YES' | translate}}</button>
	  <button type="button" class="btn btn-warning" (click)="modalRef.hide()">{{ 'NO' | translate}}</button>      
	</div>
</ng-template>

<ng-template #largeImage>
	<div class="modal-header">
		<h4 class="modal-title pull-left">{{ selectedImage.title }}</h4>
		<button type="button" id="image_close" class="close pull-right" aria-label="Close"
			(click)="modelRefCloseEvent()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="card p-0">
			<img class="card-img-top" src="data:image/jpeg;base64,{{selectedImage.largeImage}}">
		</div>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-sm btn-secondary" (click)="modelRefCloseEvent()">{{
                'IMAGE_SEARCH_VIEW.BTN_TEXT_CLOSE' | translate }}</button>
	</div>
</ng-template>