import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { WsCallback } from 'src/app/util/ws-callback.interface';
import { ServiceUrls } from 'src/app/util/service-urls';
import { UserVariable } from 'src/app/util/common/user-variable';
import { map, catchError } from 'rxjs/operators'
import { WsResponse } from 'src/app/util/ws-response.model';
import { throwError, Observable } from 'rxjs';
import { CommonUtil } from 'src/app/util/common/common-util';

@Injectable({
  providedIn: 'root'
})
export class CategorySettingService {
	private callback: WsCallback;

    constructor(private http: HttpClient, private commonUtil: CommonUtil) { }

	public setCallback(callBack: WsCallback) {
		this.callback = callBack;
	}

	getCategoryTypes(){
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateLogin(token)) {
            return;
        }
        const options = { params: new HttpParams().set("token", token) }
        return this.http.get(ServiceUrls.GET_TOPIC_TYPES_LIST, options).
            pipe(
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.code,
                        modified.status.name,
                        modified.status.description,
                        modified.payload);
                }),
                catchError(error => {
                    var modified = JSON.parse(JSON.stringify(error.error));
                    var res = new WsResponse(
                        modified.status.code,
                        modified.status.name,
                        modified.status.description,
                        modified.payload);
                    return throwError(res)
				})
			);
	}

    getCategoryList(catType: string):  Observable<WsResponse>{
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateLogin(token)){
            return;
        }
        let url = ServiceUrls.getCategoryListByCategoryType(catType)
        console.log(url)
        const options = { params: new HttpParams().set("token", token).set("categoryType", catType) }	
        return this.http.get(url, options).
			pipe(
				map(response => {
					var modified = JSON.parse(JSON.stringify(response));
					return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				}),
				catchError(error => {
					var modified = JSON.parse(JSON.stringify(error.error));
					console.log(JSON.stringify(modified));
					var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
					return throwError(res)
				})
			);
	}
	
	addNewCategory(catName:string, id:string, parentId:string, categoryType: string, path: string){
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateLogin(token)){
            return;
        }
        let url = ServiceUrls.ADD_NEW_CATEGORY;
        console.log(url)
        const options = { params: new HttpParams().set("token", token).set("categoryName", catName).set("id", id).set("parentId", parentId).set("categoryType", categoryType).set("path", path) }	
        return this.http.get(url, options).
			pipe(
				map(response => {
					var modified = JSON.parse(JSON.stringify(response));
					return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				}),
				catchError(error => {
					var modified = JSON.parse(JSON.stringify(error.error));
					console.log(JSON.stringify(modified));
					var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
					return throwError(res)
				})
			);
	}

	deleteCategory(categoryId: string): any {
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateLogin(token)){
            return;
        }
        let url = ServiceUrls.DELETE_CATEGORY;
        console.log(url)
        const options = { params: new HttpParams().set("token", token).set("categoryId", categoryId)}	
        return this.http.get(url, options).
			pipe(
				map(response => {
					var modified = JSON.parse(JSON.stringify(response));
					return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				}),
				catchError(error => {
					var modified = JSON.parse(JSON.stringify(error.error));
					console.log(JSON.stringify(modified));
					var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
					return throwError(res)
				})
			);
	}
  
	getCategoryDependencies(categoryType: string, id: string, action: string){
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateLogin(token)){
            return;
        }
        let url = ServiceUrls.GET_CATEGORY_DEPNDENCIES;
        console.log(url)
        const options = { params: new HttpParams().set("token", token).set("categoryType", categoryType).set("id", id).set("action", action)}	
        return this.http.get(url, options).
			pipe(
				map(response => {
					var modified = JSON.parse(JSON.stringify(response));
					return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				}),
				catchError(error => {
					var modified = JSON.parse(JSON.stringify(error.error));
					console.log(JSON.stringify(modified));
					var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
					return throwError(res)
				})
			);
	}

	disableCategory(categoryId: string, newStatus:string): any {
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateLogin(token)){
            return;
        }
        let url = ServiceUrls.DISABLE_CATEGORY;
        console.log(url)
        const options = { params: new HttpParams().set("token", token).set("categoryId", categoryId).set("newStatus", newStatus)}	
        return this.http.get(url, options).
			pipe(
				map(response => {
					var modified = JSON.parse(JSON.stringify(response));
					return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				}),
				catchError(error => {
					var modified = JSON.parse(JSON.stringify(error.error));
					console.log(JSON.stringify(modified));
					var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
					return throwError(res)
				})
			);
	}


	renameCategory(newName: string, categoryId: string, parentId: string, categoryType: string){
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateLogin(token)){
            return;
        }
        let url = ServiceUrls.UPDATE_CATEGORY;
        console.log(url)
        const options = { params: new HttpParams().set("token", token).set("categoryId", categoryId).set("newName", newName).set("parentId", parentId).set("categoryType", categoryType)}	
        return this.http.get(url, options).
			pipe(
				map(response => {
					var modified = JSON.parse(JSON.stringify(response));
					return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				}),
				catchError(error => {
					var modified = JSON.parse(JSON.stringify(error.error));
					console.log(JSON.stringify(modified));
					var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
					return throwError(res)
				})
			);
	}
}