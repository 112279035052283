import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '../../../util/alert/alert.service';
import { SharedService } from '../../../util/shared.service';
import { GridOptions, Module, AllModules } from "@ag-grid-enterprise/all-modules";
import { CapabilityService } from '../../../util/capability.service'
import { BsModalService } from 'ngx-bootstrap/modal';
import { OutputManagementService } from '../outputmanagement.service'
import { LoadingService } from '../../../util/loading/loading.service';
import { OMEditOutputConfigRender } from '../cell-render/outputconfigrender/editbuttoncellrender.component'
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EditOutputConfigModalComponent } from '../outputviewmodal/editoutputconfigmodal'

@Component({
   selector: 'app-outputconfig',
   templateUrl: './outputconfig.component.html',
   styleUrls: ['./outputconfig.component.css']
})

export class OutputConfigComponent implements OnInit {
   public isMinimized: boolean = false
   rowData: any[] = [];
   private gridApi;
   private gridColumnApi;
   columnDefs;
   public modules: Module[] = AllModules;
   public frameworkComponents;
   public gridOptions: GridOptions;
   public defaultColDef: any;
   om_translated_data: any = null;
   loadingMessage: string;
   bsModalRef: BsModalRef;
    public divHeight: number = (window.innerHeight) * 5 / 7

   constructor(private outputManagementService: OutputManagementService, private sharedService: SharedService, private translate: TranslateService, private alertService: AlertService,
      private capabilityService: CapabilityService, private modalService: BsModalService, private router: Router, private loadingService: LoadingService) {

      let browserLang = translate.getBrowserLang();
      this.gridOptions = <GridOptions>{
         context: {
            componentParent: this
         }
      };
      this.defaultColDef = {
			resizable: true,
			sortable: true,
		};

      this.translate.get('OUTPUT_MANAGEMENT.LOADING_MESSAGE').subscribe((res: string) => {
         this.loadingMessage = res;
      });

      translate.getTranslation(browserLang).subscribe((res: string) => {
         this.om_translated_data = res['OUTPUT_MANAGEMENT']
         this.columnDefs = [            
            {
               headerName: this.om_translated_data.CONFIG_NAME, field: 'configName', autoHeight: true, cellClass: "cell-wrap-text",  width: 85
            },
            {
               headerName: this.om_translated_data.CONFIG_XML, field: 'configXML', autoHeight: true, cellClass: "cell-wrap-text",
               cellStyle: {'white-space': 'normal'}
            },
            {
               headerName: this.om_translated_data.EDIT, field: 'id', autoHeight: true, cellClass: "cell-wrap-text", width: 85, 
               cellRendererFramework: OMEditOutputConfigRender
            },                   
         ]
      });
   }

   ngOnInit() {
   }

   onColumnResized(event) {
      if (event.finished) {
         this.gridApi.resetRowHeights();
      }
   }

   onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      params.api.sizeColumnsToFit();
      this.getOutputConfigList();
      setTimeout(function () {
         params.api.sizeColumnsToFit();
         params.api.resetRowHeights();
      }, 500);
   }

   public getOutputConfigList() {
      this.loadingService.showLoading(true, null, this.loadingMessage, null)
      this.outputManagementService.getOutputConfigList()
         .subscribe(data => {
            this.loadingService.hideLoading()
            this.rowData = data.payload;
         },
         error => {
            this.loadingService.hideLoading()
            this.alertService.clear()
            this.alertService.error(error.statusDescription)
         });
   }

   public addOutputConfig(){
      this.bsModalRef = this.modalService.show(EditOutputConfigModalComponent, { class: 'modal-lg' });
      this.bsModalRef.content.title = "Add Configuration";
      this.bsModalRef.content.configName = "";
      this.bsModalRef.content.configXML = "";
   }
}