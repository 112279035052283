/**
 * The main component that renders single TabComponent
 * instances.
 */
import { Component, HostListener, OnInit, TemplateRef} from '@angular/core';
import { ContentChildren, QueryList, AfterContentInit, ViewChild, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import { Stack } from '../util/stack'

import { TabComponent } from './tab/tab.component';
import { DynamictabsDirective } from './dynamictabs.directive';
import { SharedService } from '../util/shared.service';
import { ComponentService } from './component.service';

import { Constants, UI_LAYOUT, LocalStorageConst, SessionStorageConst } from '../util/constants';
import { AlertService } from '../util/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal/';

@Component({
	selector: 'app-tabhost',
	templateUrl: './tabhost.component.html',
	styleUrls: ['./tabhost.component.css']
})
export class TabhostComponent implements OnInit, AfterContentInit {

	public dynamicTabs: TabComponent[] = []
	@ContentChildren(TabComponent) tabs: QueryList<TabComponent>
	@ViewChild(DynamictabsDirective) dynamicTabPlaceholder: DynamictabsDirective
	@ViewChild("closeAllConfirmTemplate") closeAllTemplate: TemplateRef<any>;
	public tabList: Stack<TabComponent> = new Stack()
	translated_data: any = null;
	modalRef: BsModalRef;
	template: TemplateRef<any>;
	tab: TabComponent;
	check: boolean;
	isViewOnly: boolean;
	selectedTabId: string = null;
	unsavedFiles: string[] = [];
	closeAll: boolean = false;
	placement: string;
	public ulHeight = 47;
	private isFirefox : boolean;
	public modalConfig: any = {
		backdrop: true,
		// ignoreBackdropClick: true,
		class: 'modal-md',
		// keyboard: false
	}
	private isMobile: boolean = false;

	@HostListener('window:resize', ['$event'])
	onresize(event?) {
		this.setULHeight();	
	}

	setULHeight(){
		if(this.isFirefox){
			this.ulHeight = (((window.screen.availHeight - window.outerHeight) + window.innerHeight) / 36) + 26;
		}
		else{
			this.ulHeight = (((window.screen.availHeight - window.outerHeight) + window.innerHeight) / 35) + 26;
		}
	}
	
	constructor(private _componentFactoryResolver: ComponentFactoryResolver, private sharedService: SharedService,
		private componentService: ComponentService, private modalService: BsModalService, private alertService: AlertService, private translate: TranslateService) {
		let browserLang = translate.getBrowserLang();
		translate.getTranslation(browserLang).subscribe((res: string) => {
			this.translated_data = res['TAB_HOST']
		})
		this.isMobile = /iPhone|iPad|iPod|Mac|Android/i.test(navigator.userAgent);	
		this.isFirefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
		this.setULHeight();	
	}

	ngOnInit() {
		let objDiv = $("#tab_nav_bar");
		if (this.isMobile) {
			objDiv.addClass('force-show-scrollbars');
		}
	}

	ngAfterContentInit() {
		//Called after ngOnInit when the component's or directive's content has been initialized.
		//Add 'implements AfterContentInit' to the class.
		//get all active tabs
		const activeTabs = this.tabs.filter(tab => tab.active)
		//if there is no active tab set, activate the first
		
		if (activeTabs.length == 0) {
			// this.selectTab(this.tabs.first)
		} else {
			//do nothing
		}
		this.sharedService.selectTabWhenTMBClickRespond().subscribe(option => this.activateTMBtab())
		this.sharedService.activeTabCloseRespond().subscribe(option => this.closeActiveTab())
		this.sharedService.deletedTabCloseRespond().subscribe(
			(option: { folderId: string; }) => this.closeTabWithTabId(option.folderId)
		)
		this.sharedService.checkedInCheckedOutTabCloseRespond().subscribe(
			(option: { tabId: string; }) => this.closeTabWithTabId(option.tabId)
		)
		this.sharedService.renameTabRespond().subscribe(
			(option: { folderId: string, folderName: string }) => this.renameTabWithTabId(option.folderId, option.folderName)
		)
		this.sharedService.deletedTabListCloseRespond().subscribe(
			(option: { tabIdList: Array<string>; }) => this.closeTabList(option.tabIdList)
		)
	}

	/**
	 * open tab with id
	 * @param id 
	 * @param title 
	 * @param template 
	 * @param componentType 
	 * @param data 
	 * @param isCloseable 
	 */
	public openTabWithId(id: string, title: string, template, componentType: string, data: any, isCloseable = false, path) {
		// console.log('[openTabWithId] (id/componentType) ' + id + '/' + componentType);
		if (componentType == 'TopicMapBuilder') {
			// check for already opened topic map builder tab
			let tmbTabId = this.getAlreadyOpenTMBTab()
			if (tmbTabId != null) {
				//hsa a tab
				if (tmbTabId == this.sharedService.getTopicMapId()) {
					
					this.activateSelectedTabById(tmbTabId)
				} else {
					//show alert message and activate that tab
					this.alertService.warn(this.translated_data.DUPLICATE_TMB_TAB_ERROR)
					this.sharedService.setTopicMapId(tmbTabId)
					this.activateSelectedTabById(tmbTabId)
				}
			} else {
				this.createTab(id, title, template, componentType, data, true, path)
			}
		} else {
			// check for already opened tabs
			if (this.checkAlreadyOpenTabById(id, this.dynamicTabs)) {
				this.activateSelectedTabById(id);
				if (id == "search_res" || id == "ice_cource_1" || id == "compare_revision") {
					if (id == "compare_revision") {
						this.closeTabWithTabId(id);
					} else {
						this.closeActiveTab();
					}					
					this.createTab(id, title, template, componentType, data, true, path);
				}
			} else {
				this.createTab(id, title, template, componentType, data, true, path)
			}
		}
	}

	/**
	 * create a tab
	 * @param id 
	 * @param title 
	 * @param template 
	 * @param componentType 
	 * @param data 
	 * @param isCloseable 
	 */
	private createTab(id: string, title: string, template: any, componentType: string, data: any, isCloseable: boolean, path: string) {
		this.sharedService.setCommonICEModuleVariableNames();
		// get a component factory for our TabComponent
		const componentFactory = this._componentFactoryResolver.resolveComponentFactory(TabComponent);

		// fetch the view container reference from our anchor directive
		const viewContainerRef = this.dynamicTabPlaceholder.viewContainer;
		// create a component instance
		const componentRef = viewContainerRef.createComponent(componentFactory);

		//get component
		// const dyeComponent = this.componentService.loadComponent(componentType)
		// console.log('[createTab] (data) ' + JSON.stringify(data));
		let dyeComponent = this.componentService.loadComponentWithData(componentType, data)
		// // set the according properties on our component instance
		let folderPath: string
		if (path !== null) {
			if (path.includes('/')) {
				folderPath = path.substring(path.indexOf('/') + 1);
				folderPath = (componentType === 'Files') ? folderPath.substring(folderPath.indexOf('/') + 1).slice(0, -1) : folderPath.substring(folderPath.indexOf('/') + 1);
			} else {
				folderPath = path;
			}
		} 	
		const instance: TabComponent = componentRef.instance as TabComponent;
		instance.id = id
		instance.toolTip = title;
		instance.title = title
		instance.componentType = componentType;
		instance.template = template;
		instance.dataContext = data;
		instance.isCloseable = isCloseable;
		instance.component = dyeComponent;
		instance.path = folderPath;
		instance.startIndex = this.dynamicTabs.length;
		this.dynamicTabs.push(componentRef.instance as TabComponent)

		switch (componentType) {
			case 'Procedures':
				if (SharedService.getProceSubscriptions() != null) {
					SharedService.getProceSubscriptions().unsubscribe();
				}
				break;
			case 'Editor':
				if (SharedService.getTopicSubscriptions() != null) {
					SharedService.getTopicSubscriptions().unsubscribe();
				}
				break;
			case 'CompareRevision':
				// if (SharedService.getRevisionSubscriptions() != null) {
				// 	SharedService.getRevisionSubscriptions().unsubscribe();
				// }
				break;
			case 'IceCourse':
				if (SharedService.getIceCourseSubscriptions() != null) {
					SharedService.getIceCourseSubscriptions().unsubscribe();
				}
				break;
			case 'CoursePreview':
				if (SharedService.getIceCourseSubscriptions() != null) {
					//SharedService.getIceCourseSubscriptions().unsubscribe();
				}
				break;
			default:
				break;
		}
		//move the tab header
		this.moveTabheader(this.dynamicTabs.length);

		// set it active
		this.selectTab(this.dynamicTabs[this.dynamicTabs.length - 1], false);
	}

	private openTopicMapTab() {

	}


	public selectTab(tab: TabComponent, isNeedRefresh: boolean) {
		console.log('[selectTab] (id/title) ' + JSON.stringify(tab.id) + '/' + JSON.stringify(tab.title));
		// // deactivate all tabs
		// this.dynamicTabs.forEach(tab => (tab.active = false));
		var self = this;
		this.dynamicTabs.forEach(function(selTab){
			if(selTab.active && selTab.componentType == 'IceCourse') {
				self.sharedService.updatePreviousICEModuleChangesRequest(selTab.id);
				self.sharedService.updatePreviousICEModuleVariablesRequest(selTab.id);
			}
			selTab.active = false
		})

		// activate the tab the user has clicked on.
		tab.active = true;

		SharedService.setTabComponentType(tab.componentType);
		SharedService.setTabId(tab.id);
		this.selectedTabId = tab.id;
		switch (tab.componentType) {
			case 'TopicMapBuilder':
				this.sharedService.changeLeftSideItemRequest('TOPIC_MAP', true)
				this.sharedService.selectTopicMapTabRequest(tab)
				this.sharedService.selectTopicMapFoldeTabRequest(tab)
				this.sharedService.enableReferencePaneRequest(false);
				break;
			case 'Editor':
				console.log("this is a topic")
				this.sharedService.changeLeftSideItemRequest('FOLDER_EXPLORER', false)
				this.sharedService.enableReferencePaneRequest(true);
				break;
			case 'Procedures':
				console.log("this is a procedure")
				this.sharedService.changeLeftSideItemRequest('FOLDER_EXPLORER', false);
				if (SharedService.getProceSubscriptions() != null) {
					SharedService.getProceSubscriptions().unsubscribe();
				}
				this.sharedService.enableReferencePaneRequest(true);
				break;
			case 'Files':
				this.sharedService.changeLeftSideItemRequest('FOLDER_EXPLORER', true)
				this.sharedService.setNeedRefresh(false);
				this.sharedService.selectTreeItemRequest(tab.id)
				this.sharedService.setNeedRefresh(true);
				if (isNeedRefresh) {
					this.sharedService.folderRefreshRequest(tab.id)
				} else {
					//do nothing
				}
				this.sharedService.enableReferencePaneRequest(false);
				break;
			case 'IceCourse':
				this.sharedService.changeLeftSideItemRequest('FOLDER_EXPLORER', true)
				this.sharedService.setOpenedICEModuleRequest(tab.id);
				this.sharedService.setOpenedICEVariablesRequest(tab.id);
				this.sharedService.enableReferencePaneRequest(false);
				break;
			case 'CompareRevision':
				console.log("this is a compare revision")
				this.sharedService.changeLeftSideItemRequest('FOLDER_EXPLORER', false)
				this.sharedService.enableReferencePaneRequest(false);
				break;
			case 'CoursePreview':
				this.sharedService.changeLeftSideItemRequest('FOLDER_EXPLORER', true)
				this.sharedService.enableReferencePaneRequest(false);
				break;
			case 'FileSearch':
				this.sharedService.changeLeftSideItemRequest('FOLDER_EXPLORER', false);
				this.sharedService.enableReferencePaneRequest(false);	
				this.sharedService.selectTreeItemRequest(null);
			default:
				break;
		}
		SharedService.tabIdRequest();
	}

	public selectTabFromMore(tab: TabComponent) {
	 console.log('[selectTabFromMore]');

		//deactivate all tabs
		// this.dynamicTabs.forEach(tab => (tab.active = false));
		var self = this;
		this.dynamicTabs.forEach(function(selTab){
			if(selTab.active && selTab.componentType == 'IceCourse') {
				self.sharedService.updatePreviousICEModuleChangesRequest(selTab.id);
				self.sharedService.updatePreviousICEModuleVariablesRequest(selTab.id);
			}
			selTab.active = false
		})
		//remove tab from list
		this.dynamicTabs = this.dynamicTabs.filter(obj => obj.id !== tab.id)
		tab.active = true;
		this.dynamicTabs.push(tab)

		SharedService.setTabComponentType(tab.componentType);
		SharedService.setTabId(tab.id);
		switch (tab.componentType) {
			case 'TopicMapBuilder':
				this.sharedService.changeLeftSideItemRequest('TOPIC_MAP', true)
				this.sharedService.selectTopicMapTabRequest(tab)
				break;
			case 'Editor':
				this.sharedService.changeLeftSideItemRequest('FOLDER_EXPLORER', false)
				break;
			case 'Procedures':
				this.sharedService.changeLeftSideItemRequest('FOLDER_EXPLORER', false)
				break;
			case 'Files':
				this.sharedService.changeLeftSideItemRequest('FOLDER_EXPLORER', true)
				this.sharedService.folderRefreshRequest(tab.id)
				this.sharedService.selectTreeItemRequest(tab.id)
				break;
			case 'IceCourse':
				this.sharedService.changeLeftSideItemRequest('FOLDER_EXPLORER', true)
				this.sharedService.setOpenedICEModuleRequest(tab.id);
				this.sharedService.setOpenedICEVariablesRequest(tab.id);
				break;
			case 'CoursePreview':
				this.sharedService.changeLeftSideItemRequest('FOLDER_EXPLORER', true)
				break;
			default:
				break;
		}
	}

	public setModel(template: TemplateRef<any>, tab: TabComponent) {
		this.template = template;
		this.tab = tab;
		if(tab.componentType == 'IceCourse'){
			if(this.sharedService.getModifiedStatus(this.tab.id) == true){
				this.check = true;
			}else{
				this.check = false;
			}
		}else{
				this.check = true;
		}
		this.isViewOnly = this.sharedService.getViewOnlyStatus(this.tab.id);
		//this.modalRef = this.modalService.show(template, this.modalConfig);
	}

	public closeICECourse() {
		this.check = false;
		this.closeTab(this.tab);
	}

	public closeTab(tab: TabComponent) {
		 console.log('[closeTab]');	
		 this.alertService.clear();
		if ( tab.componentType == 'Procedures' || tab.componentType == 'Editor' || tab.componentType == 'TopicMapBuilder' || tab.componentType == 'IceCourse') {
			SharedService.setClosingTabIdClosingTabId(tab.id);	
		}
		SharedService.setClosingTabType(tab.componentType);
		if (tab != null && tab != undefined && tab.componentType == 'Files') {
			this.sharedService.selectTreeItemRequest(null);
		}
		if ((tab.componentType == 'IceCourse' || tab.componentType == 'Procedures' || tab.componentType == 'Editor' || tab.componentType === 'TopicMapBuilder') && this.check && !this.isViewOnly) {				
			this.modalRef = this.modalService.show(this.template, this.modalConfig);
		} else {
			let nextOpeningTab;
			if (tab.id !== "search_res") {
				if(this.tab.id =='ice_cource_1'){
					this.sharedService.setNewICETabId("Null")
				}
				this.sharedService.removeListFromICEBreadCrumb(tab.id);
				this.sharedService.tabCloseBtnClickRequest()
			}
			for (let i = 0; i < this.dynamicTabs.length; i++) {
				if (this.dynamicTabs[i].id === tab.id) {
					// remove the tab from our array
					this.dynamicTabs.splice(i, 1);

					// destroy our dynamically created component again
					let viewContainerRef = this.dynamicTabPlaceholder.viewContainer;
					viewContainerRef.remove(tab.startIndex);
					this.shiftTabStartIndex(tab.startIndex);
					// set tab index to 1st one
					if (this.dynamicTabs.length != 0) {
						let tempTab: TabComponent;
						if (tab.id != this.selectedTabId) {
							this.activateSelectedTabByTab(this.dynamicTabs[0])
							tempTab = this.dynamicTabs[0];
							nextOpeningTab = this.dynamicTabs[0];
						}
						else {
							this.activateSelectedTabByTab(this.dynamicTabs[this.dynamicTabs.length - 1])
							tempTab = this.dynamicTabs[this.dynamicTabs.length - 1]
							nextOpeningTab = this.dynamicTabs[this.dynamicTabs.length - 1];
						}
						if ((nextOpeningTab != undefined && nextOpeningTab != null) && nextOpeningTab.componentType == 'IceCourse') {
							// if next opening tab's component type is 'IceCourse', loads relevant data 
							this.sharedService.setOpenedICEModuleRequest(nextOpeningTab.id);
							this.sharedService.setOpenedICEVariablesRequest(tab.id);
							SharedService.setTabComponentType("IceCourse");
							SharedService.setTabId(nextOpeningTab.id);
						}
						if ((nextOpeningTab != undefined && nextOpeningTab != null) && nextOpeningTab.componentType == 'Editor') {
							SharedService.setTabComponentType("Editor");
							SharedService.setTabId(nextOpeningTab.id);
						}
						if ((nextOpeningTab != undefined && nextOpeningTab != null) && nextOpeningTab.componentType == 'Procedures') {
							SharedService.setTabComponentType("Procedures");
							SharedService.setTabId(nextOpeningTab.id);
						}
						if (tab.componentType == 'IceCourse') {
							// remove closed tab from opened courses list in shared service
							this.sharedService.updateOpenedTabsListAfterCloseTab(tab.id);
						}
						switch (tempTab.componentType) {
							case 'TopicMapBuilder':
								this.sharedService.changeLeftSideItemRequest('TOPIC_MAP', false)
								this.sharedService.enableReferencePaneRequest(false);
								break;
							case 'Editor':
								this.sharedService.changeLeftSideItemRequest('FOLDER_EXPLORER', false)
								this.sharedService.enableReferencePaneRequest(true);
								break;
							case 'Procedures':
								this.sharedService.changeLeftSideItemRequest('FOLDER_EXPLORER', false)
								this.sharedService.enableReferencePaneRequest(true);
								break;
							case 'Files':
								console.log("tempTab.componentType : " + tempTab.componentType)
								this.sharedService.changeLeftSideItemRequest('FOLDER_EXPLORER', false)
								this.sharedService.selectTreeItemRequest(tempTab.id)
								this.sharedService.enableReferencePaneRequest(false);
								break;
							case 'IceCourse':
								this.sharedService.changeLeftSideItemRequest('FOLDER_EXPLORER', true)
								this.sharedService.enableReferencePaneRequest(false);
								break;
							case 'CoursePreview':
								this.sharedService.changeLeftSideItemRequest('FOLDER_EXPLORER', true)
								this.sharedService.enableReferencePaneRequest(false);
								break;
							case 'FileSearch':
								this.sharedService.changeLeftSideItemRequest('FOLDER_EXPLORER', false);	
								this.sharedService.enableReferencePaneRequest(false);
								this.sharedService.selectTreeItemRequest(null);
							default:
								break;
						}
					} else {
						this.sharedService.selectTreeItemRequest(null)
					}
					break;
				}
			}
		}
		this.check = false;

	}

	/**
	 * close tab by tab id 
	 * when user delete folder in folder tree need to remove the opened tab
	 * @param tabId 
	 */
	private closeTabWithTabId(tabId: string) {
		console.log('[closeTabWithTabId] (tabId) ' + JSON.stringify(tabId));
		let tab = this.dynamicTabs.find(item => item.id === tabId)
		if (tabId == 'ice_cource_1') {
			this.sharedService.setNewICETabId("Null")
		}
		if (tab != null && tab != undefined && tab.componentType == 'Files') {
			this.sharedService.selectTreeItemRequest(null);
		}
		this.sharedService.removeListFromICEBreadCrumb(tabId);
		this.sharedService.tabCloseBtnClickRequest()
		for (let i = 0; i < this.dynamicTabs.length; i++) {
			if (this.dynamicTabs[i].id === tabId) {
				this.dynamicTabs.splice(i, 1);
				// destroy our dynamically created component again
				let viewContainerRef = this.dynamicTabPlaceholder.viewContainer;
				viewContainerRef.remove(tab.startIndex);
				this.shiftTabStartIndex(tab.startIndex);
				if (this.dynamicTabs.length != 0) {
					if (tab.componentType == 'IceCourse') {
						// remove closed tab from opened courses list in shared service
						this.sharedService.updateOpenedTabsListAfterCloseTab(tabId);
					}
					this.activateSelectedTabByTab(this.dynamicTabs[this.dynamicTabs.length - 1])
					let tempTab: TabComponent = this.dynamicTabs[this.dynamicTabs.length - 1]
					switch (tempTab.componentType) {
						case 'TopicMapBuilder':
							this.sharedService.changeLeftSideItemRequest('TOPIC_MAP', false)
							break;
						case 'Editor':
							this.sharedService.changeLeftSideItemRequest('FOLDER_EXPLORER', false)
							break;
						case 'Procedures':
							this.sharedService.changeLeftSideItemRequest('FOLDER_EXPLORER', false)
							break;
						case 'Files':
							this.sharedService.changeLeftSideItemRequest('FOLDER_EXPLORER', false)
							this.sharedService.selectTreeItemRequest(tempTab.id)
							break;
						case 'IceCourse':
							this.sharedService.changeLeftSideItemRequest('FOLDER_EXPLORER', true)
							break;
						case 'CoursePreview':
							this.sharedService.changeLeftSideItemRequest('FOLDER_EXPLORER', true)
							break;
						case 'FileSearch':
							this.sharedService.changeLeftSideItemRequest('FOLDER_EXPLORER', false);	
							this.sharedService.selectTreeItemRequest(null);
						default:
							break;
					}

				} else {
					this.sharedService.selectTreeItemRequest(null)
				}
			} else {
				// do nothing
			}
		}
	}

	/**
	 * close tab list by tab ids
	 * when user delete items in folder tree need to remove the opened tab
	 * @param tabIdList 
	 */
	private closeTabList(tabIdList: Array<string>) {
		console.log('[closeTabList]' + JSON.stringify(tabIdList));
		let deletedFlag: boolean = false;
		for (let j in tabIdList) {
			let tabId = tabIdList[j];
			let tab = this.dynamicTabs.find(item => item.id === tabId)
			if (tabId == 'ice_cource_1') {
				this.sharedService.setNewICETabId("Null")
			}
			this.sharedService.removeListFromICEBreadCrumb(tabId);
			this.sharedService.tabCloseBtnClickRequest()
			for (let i = 0; i < this.dynamicTabs.length; i++) {
				if (this.dynamicTabs[i].id === tabId) {
					deletedFlag = true;
					this.dynamicTabs.splice(i, 1);
					// destroy our dynamically created component again
					let viewContainerRef = this.dynamicTabPlaceholder.viewContainer;
					viewContainerRef.remove(tab.startIndex);
					this.shiftTabStartIndex(tab.startIndex);
					if (tab.componentType == 'IceCourse') {
						// remove closed tab from opened courses list in shared service
						this.sharedService.updateOpenedTabsListAfterCloseTab(tabId);
					}
					break;
				}				
			}
		} 
		if (this.dynamicTabs.length != 0 && deletedFlag) {
			this.activateSelectedTabByTab(this.dynamicTabs[this.dynamicTabs.length - 1])
			let tempTab: TabComponent = this.dynamicTabs[this.dynamicTabs.length - 1]
			switch (tempTab.componentType) {
				case 'TopicMapBuilder':
					this.sharedService.changeLeftSideItemRequest('TOPIC_MAP', false)
					break;
				case 'Editor':
					this.sharedService.changeLeftSideItemRequest('FOLDER_EXPLORER', false)
					break;
				case 'Procedures':
					this.sharedService.changeLeftSideItemRequest('FOLDER_EXPLORER', false)
					break;
				case 'Files':
					this.sharedService.changeLeftSideItemRequest('FOLDER_EXPLORER', false)
					this.sharedService.selectTreeItemRequest(tempTab.id)
					break;
				case 'IceCourse':
					this.sharedService.changeLeftSideItemRequest('FOLDER_EXPLORER', true)
					break;
				case 'CoursePreview':
					this.sharedService.changeLeftSideItemRequest('FOLDER_EXPLORER', true)
					break;
				case 'FileSearch':
					this.sharedService.changeLeftSideItemRequest('FOLDER_EXPLORER', false);
					this.sharedService.selectTreeItemRequest(null);	
				default:
					break;
			}	
		} else {
			this.sharedService.selectTreeItemRequest(null)
		} 
	} 			

	private shiftTabStartIndex(removedIndex : number) {
		for (let i = 0; i < this.dynamicTabs.length; i++) {
			if (this.dynamicTabs[i].startIndex > removedIndex) {
				this.dynamicTabs[i].startIndex -= 1;
			}
		}
	}

	/**
	 * rename tab 
	 * @param tabId 
	 */
	private renameTabWithTabId(tabId: string, tabName: string) {
		console.log('[renameTabWithTabId] (tabId/tabName) ' + JSON.stringify(tabId + '/' + tabName));
		let tab: TabComponent = this.dynamicTabs.find(item => item.id === tabId)
		tab.title = tabName
		tab.toolTip = tabName
	}

	public activateTMBtab() {
		let tmbTab: TabComponent = null
		tmbTab = this.dynamicTabs.find(item => item.componentType === 'TopicMapBuilder')
		if (tmbTab != null) {
			this.dynamicTabs.forEach(tab => (tab.active = false));
			this.dynamicTabs = this.dynamicTabs.filter(obj => obj.id !== tmbTab.id)
			tmbTab.active = true
			this.dynamicTabs.push(tmbTab)
		} else {
			//do nothing
		}

	}

	public closeMainTabs(tab: TabComponent) {

	}

	/**
	 * clise all tabs
	 */
	public closeAllTabs() {
		console.log('[closeAllTabs]');
		let viewContainerRef = this.dynamicTabPlaceholder.viewContainer;
		const noOfTabs = this.dynamicTabs.length;
        for (let i = 0; i < this.dynamicTabs.length; i++) {
		   // this.dynamicTabs.splice(i, 1);
		   this.dynamicTabs.splice(i,this.dynamicTabs.length);
            // destroy our dynamically created component again
            //let viewContainerRef = this.dynamicTabPlaceholder.viewContainer;
            // let viewContainerRef = this.dynamicTabPlaceholder;
		   // viewContainerRef.remove(i);
		}
		viewContainerRef.clear();
		this.sharedService.closAlltabs()
		//viewContainerRef.remove(noOfTabs + 1);
		//viewContainerRef.remove();
        //activate folder explorer
        this.sharedService.changeLeftSideItemRequest('FOLDER_EXPLORER', false)
        //remove highlighted tree item in the js tree
        this.sharedService.selectTreeItemRequest(null)
    }

	public closeActiveTab() {
		const activeTabs = this.dynamicTabs.filter(tab => tab.active);
		this.tab = activeTabs[0];
		if (activeTabs.length > 0) {
			// close the 1st active tab (should only be one at a time)
			console.log(activeTabs[0]);
			this.closeTab(activeTabs[0]);
		}
		this.isViewOnly = this.sharedService.getViewOnlyStatus(this.tab.id);
	}

	public fullScreen(tab: TabComponent) {
		// console.log('[fullScreen]');
		this.selectTab(tab, true)


		let uiLayout = JSON.parse(sessionStorage.getItem(SessionStorageConst.UI_LAYOUT))
		// console.log('[fullScreen] (uiLayout)' +  JSON.stringify(uiLayout));
		let newLayout: any = null
		switch (uiLayout) {
			case UI_LAYOUT.UIL_1:
				newLayout = Constants.UI_LAYOUT.UIL_3;
				sessionStorage.setItem(SessionStorageConst.UI_LAYOUT, JSON.stringify(UI_LAYOUT.UIL_3))
				break;
			case UI_LAYOUT.UIL_2:
				newLayout = Constants.UI_LAYOUT.UIL_4;
				sessionStorage.setItem(SessionStorageConst.UI_LAYOUT, JSON.stringify(UI_LAYOUT.UIL_4))
				break;
			case UI_LAYOUT.UIL_3:
				newLayout = Constants.UI_LAYOUT.UIL_1;
				sessionStorage.setItem(SessionStorageConst.UI_LAYOUT, JSON.stringify(UI_LAYOUT.UIL_1))
				break;
			case UI_LAYOUT.UIL_4:
				newLayout = Constants.UI_LAYOUT.UIL_2;
				sessionStorage.setItem(SessionStorageConst.UI_LAYOUT, JSON.stringify(UI_LAYOUT.UIL_2))
				break;
			case UI_LAYOUT.UIL_6:
				break;
		}
		// console.log('[fullScreen] (newLayout)' +  JSON.stringify(newLayout));

		if (tab.componentType == 'TopicMapBuilder') {
			//do nothing
		} else {
			this.sharedService.fullScreenRequest(newLayout)
		}
	}

	public checkAlreadyOpenTab(title: string, tabList: any): boolean {
		let isTabExist: boolean = false;
		tabList.forEach(element => {
			if (element.title == title) {
				isTabExist = true
			}
		});
		return isTabExist
	}

	public checkAlreadyOpenTabById(id: string, tabList: any): boolean {
		let isTabExist: boolean = false;
		tabList.forEach(element => {
			if (element.id == id ) {
				isTabExist = true
			}
			if (element.componentType == 'IceCourse' && id == this.sharedService.getNewICETabId() ) {
				isTabExist = true
			}
		});
		return isTabExist
	}

	public getAlreadyOpenTMBTab(): string {
		let tabId: string = null;
		this.dynamicTabs.forEach(element => {
			if (element.componentType == 'TopicMapBuilder') {
				tabId = element.id
			}
		});
		return tabId
	}


	public activateSelectedTabByTab(tab: TabComponent) {
		let resultTab: TabComponent = null
		this.dynamicTabs.forEach(tab => (tab.active = false));
		resultTab = this.dynamicTabs.find(item => item.id === tab.id)
		let index = this.dynamicTabs.findIndex(item => item.id === tab.id);
		resultTab.active = true
		//move the tab header
		this.moveTabheader(index + 1);
	}

	public activateSelectedTabByString(title: string) {
		let resultTab: TabComponent = null
		this.dynamicTabs.forEach(tab => (tab.active = false));
		resultTab = this.dynamicTabs.find(item => item.title === title)
		resultTab.active = true
	}

	public activateSelectedTabById(id: string) {	
	// if (id == this.sharedService.getNewICETabId() ) {
	// //	this.alertService.success(id + "and" + this.sharedService.getNewICETabId() );
	// 	id = 'ice_cource_1'
	// }
		let resultTab: TabComponent = null
		this.dynamicTabs.forEach(tab => (tab.active = false));
		resultTab = this.dynamicTabs.find(item => item.id === id)
		let index = this.dynamicTabs.findIndex(item => item.id === id);
		resultTab.active = true;
		//move the tab header
		this.moveTabheader(index + 1);

		SharedService.setTabComponentType(resultTab.componentType);
		SharedService.setTabId(resultTab.id);
		let openedICEModules = this.sharedService.getOpenedICEModules();
		if (openedICEModules[id] != undefined) {
			this.sharedService.setOpenedICEModuleRequest(id);
			this.sharedService.setOpenedICEVariablesRequest(id);
		}
	}

	openCloseAllModal() {
		let isFileSaved: boolean = true;
		this.unsavedFiles = [];
		for (let i = 0; i < this.dynamicTabs.length; i++) {
			if (this.sharedService.getViewOnlyStatus(this.dynamicTabs[i].id) === false) {
				console.log(this.dynamicTabs[i].title)
				this.unsavedFiles.push(this.dynamicTabs[i].title);
				isFileSaved = false;
			}
		}
		if (!isFileSaved) {
			this.modalRef = this.modalService.show(this.closeAllTemplate);
		} else {
			this.closeAllTabs();
		}
	}

	onConfirmClose() {
		this.closeAllTabs()
	}

	onMouseoverTab(tab) {
		if (tab.path.length >= 38) {
			this.placement = "bottom";
		} else {
			this.placement = "top";
		}
	}

	private moveTabheader(index: number) {
		let totalWidth : number = 0;
		let objDiv = $("#tab_nav_bar");
		for (let i = 1; i < index; i++) {
			let headerDiv = $(".tab_header" + i);
			totalWidth += $(headerDiv)[0].scrollWidth;
		}		
		setTimeout(() => {
			objDiv.animate({scrollLeft: totalWidth});
		}, 200);
	}
}
