import {Component, TemplateRef, ViewChild} from "@angular/core";
import { WsResponse } from '../../util/ws-response.model';
import { WsType } from '../../util/ws-type';
import { AlertService } from '../../util/alert/alert.service';
import { Router } from '@angular/router';
import { LocalStorage } from '../../util/localstorage.service';
import { LoggedUser } from '../../user/logged-user.model';
import { SharedService } from "../../util/shared.service";
import { WsCallback } from "../../util/ws-callback.interface";
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { DependenciesmodalComponent } from '../../util/dependenciesmodal/dependenciesmodal.component';
import { FacilitiesService } from '../../reusables/facilities/facilities.service';
import { Constants } from "src/app/util/constants";
import {ManagemccService} from "./managemcc.service";
import {ApprovemccComponent} from "./approvemcc/approvemcc.component";
import {UserVariable} from "../../util/common/user-variable";
import {LoadingService} from "../../util/loading/loading.service";

import * as FileSaver from 'file-saver';
import {EditmccComponent} from "./editmcc/editmcc.component";
@Component({
    selector: 'app-cell-render',
    templateUrl: './managemcc-cell-render-component.component.html'
})

export class ManageMCCCellRenderComponent implements WsCallback {
    icon: string;
    type: string;
    disable: boolean = false;
    approved: boolean = false
    bsModalRef: BsModalRef;
    title: string;
    dependencyType: string;
    data: string = "";
    status: string = "";
    isUploaded : string;
    mccDeleteMsg :string;
    systemName :string;
    mccId : string;
    systemId : string;
    docuSignStatus: string = "";
    public deleteModalRef: BsModalRef;
    @ViewChild("deleteMCCModal") deleteMCCModal: TemplateRef<any>;
    constructor(private alertService: AlertService, private router: Router, private sharedService: SharedService,
        private modalService: BsModalService, private translate: TranslateService, private facilitiesService: FacilitiesService,
                private loadingService: LoadingService,
                private managemccService:ManagemccService) {
        translate.addLangs(["es", "en"]);
        translate.setDefaultLang("en");
        let browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
    }

    agInit(params: any): void {
        this.systemName = params.data.systemName;
        this.mccId = params.data.mccId;
        this.systemId = params.data.systemId;
        this.docuSignStatus = params.data.docusignStatus;
        let items = params.value.split(':')
        if (items.length >= 2) {
            this.status = items[1];
            if (items.length == 3) {
                this.isUploaded = items[2];
            }
        }
        // if(items.length == 4){
        //     this.status = items[2];
        //     this.isUploaded =items[3];
        //     console.log( "is uploaded --------------------")
        // }

        this.setType(items[0])
        // if (this.type == 'dependencies') {
        //     this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.VIEW;
        //     this.translate.get('FACILITY_CELL_RENDER.DEPENDENCIES_LIST').subscribe((res: string) => {
        //         this.title = res;
        //     });
        // } else if (this.type == 'approve') {
        //     if (this.status == "Issued") {
        //         this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.ENABLE;
        //         this.translate.get('FACILITY_CELL_RENDER.ENABLE_CONFIRM').subscribe((res: string) => {
        //             this.title = res;
        //         });
        //     } else if (this.status == "Approved"){
        //         this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.DISABLE;
        //         this.translate.get('FACILITY_CELL_RENDER.DISABLE_CONFIRM').subscribe((res: string) => {
        //             this.title = res;
        //         });
        //     }
        // } else if (this.type == 'delete' && (this.status == 'Issued' || this.status == 'Approved' || this.status == "Error") ) {
        //     this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.DELETE;
        //     this.translate.get('FACILITY_CELL_RENDER.DELETE_CONFLICTS').subscribe((res: string) => {
        //         this.title = res;
        //     });
        // } else if (this.type == 'edit') {
        //     this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.EDIT;
        //     this.translate.get('FACILITY_CELL_RENDER.EDIT_CONFIRM').subscribe((res: string) => {
        //         this.title = res;
        //     });
        // }
        // this.sharedService.disableCellRendererExecute().subscribe(option => this.setDisable(option));
    }

    viewDependencies(dflag: boolean) {
        this.dependenciesModalWithComponent(this.title, dflag, true);
    }

    dependenciesModalWithComponent(header: String, dflag: boolean, cflag: boolean) {
        const initialState = {
            headerTitle: header,
            delFlag: dflag,
            cancelFlag: cflag,
            checkboxVal: this.status == "Inactive" ? true : false
        };
        // this.sharedService.setFileId(this.data);
        // this.sharedService.setFileType(Constants.DEPENDENCY_ITEM_TYPE.VALUE_LIST);
        // this.sharedService.setAction("getDependencies");
		// this.sharedService.setDependencyMessageType(this.dependencyType);
        // this.sharedService.setParent(this);
        // this.bsModalRef = this.modalService.show(DependenciesmodalComponent, { initialState, class: 'modal-lg' });
    }

    public invoke(type: String, data: any) {
        const initialState = {
            mccId : this.mccId,
            systemName : this.systemName,
            systemId : this.systemId
        }
        switch (type) {
            case 'sentpdf':
                this.downloadSentFile(this.mccId,this.systemName);
                break;
            case 'returnedpdf':
                this.downloadApprovalFile(this.mccId,this.systemName);
                break;
            case 'approve':

                this.bsModalRef = this.modalService.show(ApprovemccComponent, { class: 'modal-xl', backdrop: 'static', keyboard: false, initialState })
                break;
			case 'edit':
                // this.sharedService.setFileId(this.mccId);
                // this.sharedService.editMCCRequest();
                this.bsModalRef = this.modalService.show(EditmccComponent, {  backdrop: 'static', keyboard: false, initialState })

                break;
			case 'delete':
			    this.deleteConfirm(this.mccId);
                // this.viewDependencies(true);
                break;
            default:
                break;
        }
    }

    onSuccess(data: WsResponse, serviceType: WsType): void {
        if (serviceType == WsType.ADD_UPDATE_RECENT_PROJECT) {
            //refresh the recent list again
            //this.recentProjectService.getAllRecentProjects(this.loggedInUser.userId, this);
        }
        else if (serviceType == WsType.GET_ALL_RECENT_PROJECTS) {
            //this.recentProjectList = data.payload;
            //this.recentProjectService.setDefaultRecentProjects(data.payload, this);
        }
        else {

        }
    }

    onFail(res: WsResponse, serviceType: WsType): void {
        if (serviceType == WsType.GET_ALL_RECENT_PROJECTS) {
            this.alertService.error(res.statusDescription);
        } else if (serviceType == WsType.ADD_UPDATE_RECENT_PROJECT) {
            //We don't show the error message here since it is out of user control.
            console.log("=======error======" + res.statusDescription);
        } else {

        }
    }

    private setType(params) {
        this.type = params;
        if (params == "approve") {
            if (this.status == "Issued") {
                this.icon = 'far fa-check-circle';
                if(this.docuSignStatus != null){

                    if(this.docuSignStatus.toLowerCase() == 'declined'){
                        this.approved = true

                    }
                }
            } else if  (this.status == "Approved")  {
                this.icon = 'far fa-check-circle';
                this.approved = true
            }
        }  else if (params == "edit") {
            this.icon = "far fa-edit";
        }
        else if(params == 'delete'){
            if(this.status  == 'Issued' || this.status  == 'Approved' || this.status  == "Error"){
                this.icon = 'far fa-undo'
            } else{
                //do nothing
            }
        } else if (params == "sentpdf" && (this.status == 'Issued' || this.status == 'Approved')) {
            this.icon = 'fas fa-file-pdf';
        }
        else if (params == "returnedpdf" && (this.status == 'Approved' || this.status == 'Issued') && this.isUploaded == "true") {
            this.icon = 'fas fa-file-pdf';
        }

    }

    // private setDisable(option: any) {
    //     let items = this.data.split(",")
    //     if (option != null && option.id == items[0] && this.type == 'status') {
    //         this.disable = option.data;
    //         this.icon = this.disable ? 'far fa-times-circle' : 'far fa-check-circle';
    //         this.status = this.disable ? 'Inactive' : 'Active';
    //         if (this.status == "Inactive") {
    //             this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.ENABLE;
    //             this.translate.get('FACILITY_CELL_RENDER.ENABLE_CONFIRM').subscribe((res: string) => {
    //                 this.title = res;
    //             });
    //         } else {
    //             this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.DISABLE;
    //             this.translate.get('FACILITY_CELL_RENDER.DISABLE_CONFIRM').subscribe((res: string) => {
    //                 this.title = res;
    //             });
    //         }
    //     }
    // }
    public deleteConfirm(params: any) {
        console.log("delete called")
        this.mccDeleteMsg = "Are you sure you want to Revoke the Mechanical Completion Certificate?"
        this.deleteModalRef = this.modalService.show(this.deleteMCCModal)
    }

    public  revokeDeleteMCC(){
        console.log("revoked")
        this.loadingService.showLoading(true, null, "", null);
        this.managemccService.revokeMechanicalCompletion(this.mccId);
        this.deleteModalRef.hide()
    }
    public cancelDelteMCC(){
        this.mccDeleteMsg = null;
        if(this.deleteModalRef){
            this.deleteModalRef.hide()
        }
    }

    downloadSentFile(mcId, systemName) {
        // this.loadingService.showLoading(false, false, '', 0)
        this.managemccService.downloadSentFile(UserVariable.projectId, mcId)
            .subscribe(response => {
                    // this.loadingService.hideLoading()
                    let file = new Blob([response], { type: 'application/pdf' });
                    FileSaver.saveAs(file, "MCC_" + systemName+ "_" + ".pdf");
                },
                error => {
                    // this.loadingService.hideLoading()
                    this.alertService.clear()
                    this.alertService.error("could not download sent file")
                });
    }

    downloadApprovalFile(mcId, systemName) {
        this.loadingService.showLoading(false, false, '', 0)
        this.managemccService.downloadApprovalFile(UserVariable.projectId, mcId)
            .subscribe(response => {
                    this.loadingService.hideLoading()
                    let file = new Blob([response], { type: 'application/pdf' });
                    FileSaver.saveAs(file, "MCC_" + systemName + ".pdf");
                },
                error => {
                    this.loadingService.hideLoading()
                    this.alertService.clear()
                    this.alertService.error("Could not download approval file")
                });
    }
}
