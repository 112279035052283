import { Component, Input, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AlertService } from 'src/app/util/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { PunchlistService } from '../punchlist.service';

@Component({
  selector: 'app-docu-sign-person-status',
  templateUrl: './docu-sign-person-status.component.html',
  styleUrls: ['./docu-sign-person-status.component.css']
})
export class DocuSignPersonStatusComponent implements OnInit {

  @Input('docusignId') docusignId: string;
	@Input('system') system: string;
	@Input('type') type: string;
	@Input('showTaskName') showTaskName: boolean;
	pcd_translated_data: any;
	columnDefs: any;
	public overlayLoadingTemplate: any;
	public overlayNoRowsTemplate: any;
	public rowData = [];
	private gridApi;

	constructor(private translate: TranslateService, 
		private alertService: AlertService,  
		private modalService: BsModalService,
    private punchlistService: PunchlistService,
		) { 
		}

	ngOnInit() {
		let browserLang = this.translate.getBrowserLang();
			this.translate.getTranslation(browserLang).subscribe((res: string) => {
				this.pcd_translated_data = res[this.type]
				this.columnDefs = [
					{ headerName: this.pcd_translated_data.TASK_NAME, field: 'taskName', hide: !this.showTaskName, autoHeight: true, filter: "agTextColumnFilter", cellClass: "cell-wrap-text", minWidth: 90, resizable: true },
					{ headerName: this.pcd_translated_data.USER_EMAIL, field: 'currentUserId', autoHeight: true, filter: "agTextColumnFilter", cellClass: "cell-wrap-text", minWidth: 90, resizable: true },
					{ headerName: this.pcd_translated_data.SIGN_STATUS, field: 'status', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 90, resizable: true },
					{ headerName: this.pcd_translated_data.REASON, field: 'reason', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 90, resizable: true }								
				]
				
				this.overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Loading data...</span>';
				this.overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">No rows to show</span>';
			});	
	}
	
	public getPersonStatus(){
		// console.log("getPersonStatus= " +this.docusignId)
		this.gridApi.showLoadingOverlay()
		this.punchlistService.getPersonDocuSignStatus(this.docusignId)
			.subscribe(data => {
				// this.isLoading = false;
				if (data.payload) {
					console.log(data.payload)
					this.rowData = data.payload;
				} else {
					this.rowData = []
				}
			},
			error => {
				this.rowData = []
				this.alertService.error(error.statusDescription);
			})
	}

	onGridReady(params) {
		this.gridApi = params.api;
		this.gridApi.showLoadingOverlay()
		window.addEventListener("resize", function () {
            setTimeout(function () {
                params.api.sizeColumnsToFit();
				params.api.resetRowHeights();
            });
        });
		this.getPersonStatus();
		setTimeout(function () {
			params.api.sizeColumnsToFit();
			params.api.resetRowHeights();
		}, 500);

		params.api.sizeColumnsToFit();
		params.api.resetRowHeights();
	}

	onColumnResized(event) {
		if (event.finished) {
		this.gridApi.resetRowHeights();
		}
	}

}
