import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams, IAfterGuiAttachedParams } from "@ag-grid-enterprise/all-modules";
import { ManageAttributesComponent } from '../manage-attributes.component';

@Component({
  selector: 'app-dependency-cell-render',
  template: `<button type="button" [disabled]="disable" class="btn btn-sm btn-outline-dark py-0 my-1 px-1 border-0"
              (click)="onDependencyButtonClick()">
              <i class="far fa-link" aria-hidden="true"></i>
              </button>`,
  styles: []
})
export class DependencyCellRendererComponent implements OnInit, ICellRendererAngularComp {
  public attribute: any;
  public componentParent: ManageAttributesComponent;
  private cellData;
  public disable: boolean;

  constructor() { }

  ngOnInit() {
  }

  refresh(params: any): boolean {
    return true;
  }

  agInit(params: ICellRendererParams): void {
    this.cellData = params.data;
    this.componentParent = params.context.componentParent;
    if (params.data != undefined) {
      this.attribute = params.data;
    } else {
      this.attribute = params.value;
    }
    if(params.value == 'disable: false'){
        this.disable = false;
    }
    else{
        this.disable = true;
    }
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
  }

  onDependencyButtonClick() {
    this.componentParent.showDependencies(this.cellData);
  }
}
