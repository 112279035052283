import { Component, OnInit, OnDestroy, ElementRef, QueryList } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FilesService } from '../../../files/files.service';
import { WsResponse } from '../../../../util/ws-response.model';
import { WsType } from '../../../../util/ws-type';
import { AlertService } from '../../../../util/alert/alert.service';
import { DocumenttypetreeComponent } from '../../../../util/documenttypetree/documenttypetree.component';
import { SharedService } from '../../../../util/shared.service';
import { File } from '../../../../common-components/items/file.model';
import { TreeListener } from '../../../../util/documenttypetree/tree-listener.interface';
import { FileAttribute } from '../../../../common-components/items/fileattribute.model';
import { FileModel } from '../../file.model';
import { ICECourse } from 'src/app/ice-course/ice-course.model';
import { FolderService } from '../../../../foldertree/foldertree.service';

@Component({
	selector: 'app-files-properties',
	templateUrl: './properties.component.html',
	styleUrls: ['./properties.component.css'],
	inputs: ['fileData']
})
export class PropertiesComponent implements OnInit, OnDestroy, TreeListener {
	public fileData: any = null;//selected file from the table
	public file: File;
	bsModalRef: BsModalRef;
	public lastUpdatedTimeStamp: String = "";
	public dateAdded: String = "";
	private updateTimesubscription: any;
	private systemName: String = "";
	private outputConfig: String = "";
	public selectedDocumentTypeId: String = null;
	documentTypeAttributes: any = null


	constructor(private filesService: FilesService, private translate: TranslateService, private alertService: AlertService,
		private modalService: BsModalService, private sharedService: SharedService, private folderService: FolderService) {
		translate.addLangs(["es", "en"]);
		translate.setDefaultLang("en");
		let browserLang = translate.getBrowserLang();
		translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
	}

	ngOnInit() {
		// this.loadPropertiesInfo()
		this.selectedDocumentTypeId = this.fileData.documentTypeId;
		console.log('selected file = ' + this.fileData);

		if (this.fileData != null && this.fileData != undefined) {
			if (this.fileData.type == 'Procedure') {
				this.processProcedureData();
			}
			this.lastUpdatedTimeStamp = new Date(this.fileData.lastUpdateTimeStamp).toLocaleString() != "Invalid Date" ? new Date(this.fileData.lastUpdateTimeStamp).toLocaleString() : this.fileData.lastUpdateTimeStamp;
			this.dateAdded = new Date(this.fileData.dateAdded).toLocaleString() != "Invalid Date" ? new Date(this.fileData.dateAdded).toLocaleString() : this.fileData.dateAdded;
		}
		this.updateTimesubscription = this.sharedService.updateTimeStampExecute().subscribe(option => this.updateLastUpdatedTimeStamp(option));
	}

	ngAfterContentInit(): void {
		//Called after ngOnInit when the component's or directive's content has been initialized.
		//Add 'implements AfterContentInit' to the class.
	}


	processProcedureData() {
		for (var i = 0; i < this.fileData.systems.length; i++) {
			if (this.fileData.systems[i].systemId == this.fileData.systemId) {
				this.systemName = this.fileData.systems[i].systemNo + " " + this.fileData.systems[i].systemName;
				break;
			}
		}
		for (var i = 0; i < this.fileData.outputList.length; i++) {
			if (this.fileData.outputList[i].id == this.fileData.outputConfigId) {
				this.outputConfig = this.fileData.outputList[i].configName;
				break;
			}
		}
	}

	updateName(name: String) {
		//check the required values
		if(name != ""){
			console.log('updateName');
			let self = this;
			this.file = new File(this.fileData.id, name, this.fileData.documentTypeId)
			if (this.fileData.type == 'Procedure') {
				this.file.systemId = this.fileData.systemId;
				this.file.outputConfigId = this.fileData.outputConfigId;
			}

			if (this.fileData.type == 'ICECourse') {

				let selectedFolderData = self.folderService.getSelectedFoldeData();
				let folderId = selectedFolderData != null ? selectedFolderData.id : 0

				var request = {
					"courseId": this.fileData.id,
					"fileName": name,
					"folderId" : folderId,
					"isFileNameUpdated": "Yes"
				};
				this.filesService.updateFile(JSON.stringify(request), this.fileData.id, this.fileData.type, this);
				
			}
			else if (this.fileData.type == 'ThreeDModel') {


				var modelrequest = {
					"id": this.fileData.id,
					"name": name,
					"documentTypeId":this.fileData.documentTypeId

				};
				this.filesService.updateFile(JSON.stringify(modelrequest), this.fileData.id, this.fileData.type, this);
				console.log("model req :"+ JSON.stringify(modelrequest));
			}
			else if (this.fileData.type == 'Video') {


				var videorequest = {
					"id": this.fileData.id,
					"name": name,
					"documentTypeId":this.fileData.documentTypeId

				};
				this.filesService.updateFile(JSON.stringify(videorequest), this.fileData.id, this.fileData.type, this);
				console.log("video req :"+ JSON.stringify(videorequest));
			}
			else{
				console.log("====this.file===== :"+ JSON.stringify(this.file));
				this.filesService.updateFile(JSON.stringify(this.file), this.fileData.id, this.fileData.type, this);
				console.log("file"+ this.file.id);
			}
		}else{
			switch(this.fileData.type){
				case 'TopicMap':
					this.alertService.error("Name is required");
					break;
				case 'Topic':
					this.alertService.error("Name is required");
					break;
				case 'ContentItem':
					this.alertService.error("Title is required");
					break;
				case 'Image':
					this.alertService.error("Title is required");
					break;
				case 'Procedure':
					this.alertService.error("Name is required");
					break;
				case 'ICECourse':
					this.alertService.error("Name is required");
					break;
				case 'ThreeDModel':
					this.alertService.error("Title is required");
					break;
				case 'Video':
					this.alertService.error("Title is required");
					break;
			}
		}
	}

	updateLastUpdatedTimeStamp(option: any) {
		if (option != null) {
			let preferences = localStorage.getItem('preferences');
			var json = JSON.parse(preferences);
			var innerJson = (JSON.parse(json)).UserPreference.Portal;
			let zone = innerJson.General.TimeZone;

			switch (option.fileType) {
				case "Topic": {
					this.lastUpdatedTimeStamp = new Date(option.data).toLocaleString("en-US", {timeZone: zone});
					break;
				}
				case "Procedure": {
					this.lastUpdatedTimeStamp =new Date(option.data).toLocaleString("en-US", {timeZone: zone});;
					break;
				}
				case "TopicMap": {
					this.lastUpdatedTimeStamp = new Date(option.data).toLocaleString("en-US", {timeZone: zone});
					break;
				}
			}
		}
	}

	changeSystemId(value) {
		this.file = new File(this.fileData.id, this.fileData.name, this.fileData.documentTypeId)
		this.file.systemId = value;
		this.file.outputConfigId = this.fileData.outputConfigId;
		this.filesService.updateFile(JSON.stringify(this.file), this.fileData.id, this.fileData.type, this);
	}

	changeOutputConfigId(value) {
		console.log('changeOutputConfigId');
		this.file = new File(this.fileData.id, this.fileData.name, this.fileData.documentTypeId)
		this.file.systemId = this.fileData.systemId;
		this.file.outputConfigId = value;
		this.filesService.updateFile(JSON.stringify(this.file), this.fileData.id, this.fileData.type, this);
	}

	openModalWithComponent() {
		console.log('openModalWithComponent');
		const initialState = {
			title: 'Select a Document Type',
			treeListener: this,
			initial: true
		};
		this.bsModalRef = this.modalService.show(DocumenttypetreeComponent, { initialState, class: 'modal-lg' });
		this.bsModalRef.content.closeBtnName = 'Close';
	}

	loadDocumentTypes() {
		this.filesService.setSelectedFileData(this.fileData);
		this.openModalWithComponent();
	}

	/**
	 * Clicks on the save button of the tree view popup
	 * @param selectedData selected file
	 */
	onSaveButtonClick(selectedDocument: any, texts: QueryList<ElementRef>, options: QueryList<ElementRef>, documentTypeAttributes: any) {
		this.documentTypeAttributes = documentTypeAttributes;
		//var selectedDocumentTypeId = selectedData.node.id;
		//let selectedFileData = selectedData;
		let selectedData = this.filesService.getSelectedFileData();
		if (selectedData != null && selectedData != undefined) {
			this.file = new File(selectedData.id, selectedData.name, selectedDocument.node.id)
			if (selectedData.type == 'Procedure') {
				this.file.systemId = selectedData.systemId;
				this.file.outputConfigId = selectedData.outputConfigId;
			}
			let attributes = [];
			if (selectedDocument.node.id != selectedData.documentTypeId) //insert
			{
				if (texts != null) {
					for (var i = 0; i < texts.toArray().length; i++) {
						let invalidText = (texts.toArray()[i].nativeElement.value == null || texts.toArray()[i].nativeElement.value == undefined || texts.toArray()[i].nativeElement.value == '');
						if (!invalidText) {
							let fileAttribute = new FileAttribute(texts.toArray()[i].nativeElement.id, texts.toArray()[i].nativeElement.value);
							attributes.push(fileAttribute);
						}
					}
				}
				if (options != null) {
					for (var i = 0; i < options.toArray().length; i++) {
						let invalidText = (options.toArray()[i].nativeElement.value == null || options.toArray()[i].nativeElement.value == undefined || options.toArray()[i].nativeElement.value == '');
						if (!invalidText) {
							let fileAttribute = new FileAttribute(options.toArray()[i].nativeElement.id, options.toArray()[i].nativeElement.value.split(':')[1].trim());
							attributes.push(fileAttribute);
						}
					}
				}
			}
			else  //update
			{
				if (texts != null) {
					for (var i = 0; i < texts.toArray().length; i++) {
						let text = (texts.toArray()[i].nativeElement.value == null || texts.toArray()[i].nativeElement.value == undefined || texts.toArray()[i].nativeElement.value == '') ? "" : texts.toArray()[i].nativeElement.value;
						let fileAttribute = new FileAttribute(texts.toArray()[i].nativeElement.id, text);
						attributes.push(fileAttribute);
					}
				}
				if (options != null) {
					for (var i = 0; i < options.toArray().length; i++) {
						let invalidText = (options.toArray()[i].nativeElement.value == null || options.toArray()[i].nativeElement.value == undefined || options.toArray()[i].nativeElement.value == '');
						if (!invalidText) {
							let fileAttribute = new FileAttribute(options.toArray()[i].nativeElement.id, options.toArray()[i].nativeElement.value.split(':')[1].trim());
							attributes.push(fileAttribute);
						}
					}
				}

				if (attributes.length == 0) {
					this.alertService.warn("Nothing to update")
					return;
				}
			}

			this.file.attributes = attributes;
			console.log("JSON.stringify(this.file) " + JSON.stringify(this.file))
			console.log('parameters===> id=' + selectedData.id + ' type=' + selectedData.type);

			this.filesService.updateFile(JSON.stringify(this.file), selectedData.id, selectedData.type, this);
		}
	}

	/**
	 * Achini Randeni
	 * Check whether the attributes contains required values
	 */
	hasRequiredAttributes():boolean{
		var isRequired: boolean = false;
		this.documentTypeAttributes.forEach(element => {
			if(element.required != null && element.required.toLowerCase() === 'yes'){
				isRequired = true;
			}
		});
		return isRequired;
	}

	onSuccess(data: WsResponse, serviceType: WsType): void {
		if (data != null && WsType.FILE_UPDATE == serviceType && this.file != undefined) {
			if(data.payload != null){
				this.fileData.documentTypeId = data.payload.substring(data.payload.lastIndexOf(":")+1);
				this.fileData.documentTypePath = data.payload.substring(0,data.payload.lastIndexOf(":"));
				this.filesService.getFileDataByFileId(this.fileData, this);
			}
			if (this.fileData.type != "Image") {
				this.fileData.name = this.file.name;
				//Give warning to the user if the selected document type has required attributes
				if(this.hasRequiredAttributes()){
					this.alertService.warn("Please fill the required attributes");
				} else{
					if (this.fileData.type === "ContentItem") {
						this.alertService.clear();
						this.alertService.success('File updated successfully');
					} else if (this.fileData.type === "ThreeDModel") {
						this.alertService.clear();
						this.alertService.success('3D Model updated successfully');
					} else {
						this.alertService.clear();
						this.alertService.success(`${this.fileData.type} updated successfully`);
					}		
				}
			}
			else {
				this.fileData.title = this.file.name;
				this.alertService.clear();
				this.alertService.success(`${this.fileData.type} updated successfully`);
			}
			if (this.fileData.type == "Procedure") {
				this.fileData.systemId = this.file.systemId;
				this.fileData.outputConfigId = this.file.outputConfigId;
			}

			this.filesService.loadFiles("tooltip:" + this.file.name, this.file.id, "fileName")

			// let item = this.filesService.getSelectedFileData();
			// if (data.payload != null && (item != null || item != undefined)) {
			// 	item.documentTypePath = data.payload.split(':')[0];
			// 	item.documentTypeId = data.payload.split(':')[1];
			// }
			// if (this.file.attributes == undefined || this.file.attributes.length == 0) {
			// 	item.attributes = [];
			// }
			// else {
			// 	for (let i = 0; i < this.file.attributes.length; i++) {
			// 		if (item.attributes.length > i) {
			// 			var changedItem = item.attributes.filter(attribute => attribute.attributeId === this.file.attributes[i].attributeId)[0];
			// 			if (changedItem != null && changedItem != undefined) {
			// 				changedItem.value = this.file.attributes[i].value;
			// 			}
			// 			else {
			// 				item.attributes.length = 0;
			// 				item.attributes.push(this.documentTypeAttributes[i])
			// 			}
			// 		} else {
			// 			item.attributes.push(this.documentTypeAttributes[i])

			// 		}
			// 	}
			// }
		}else if (data != null && serviceType == WsType.FILE_DATA_VIEW) {
			this.fileData = data.payload;
			this.filesService.setSelectedFileData(data.payload);
			this.sharedService.selectedFileDataChangedEmit();
		}
	}

	onFail(res: WsResponse, serviceType: WsType): void {
		console.log('onFail');
		if (res != null && WsType.FILE_UPDATE == serviceType) {
			/*if (res.statusCode == "532") {
				//this.router.navigateByUrl('login');
			}
			else*/ if (res.statusCode == "600") {
				this.filesService.updateFile(JSON.stringify(this.file), this.fileData.id, this.fileData.type, this);
			} else {
				this.alertService.clear();
				this.alertService.error(res.statusDescription, false);
			}
		}
	}

	ngOnDestroy() {
		console.log('ngOnDestroy');
		this.updateTimesubscription.unsubscribe();
	}
}

/*export class ModalContentComponent implements OnInit {
	title: string;
	closeBtnName: string;

	constructor(public bsModalRef: BsModalRef) { }

	ngOnInit() {
	}
}*/
