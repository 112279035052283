import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AllModules, GridOptions, Module } from "@ag-grid-enterprise/all-modules";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AlertService } from 'src/app/util/alert/alert.service';
import { CapabilityService } from 'src/app/util/capability.service';
import { UserVariable } from 'src/app/util/common/user-variable';
import { LoadingService } from 'src/app/util/loading/loading.service';
import { SharedService } from 'src/app/util/shared.service';
import { FacilitiesCellRender } from './cell-render/add-facilities-button/facilitiescellrender.component';
import { DSDeleteButtonRender } from './cell-render/delete-button/delete-button.component';
import { DocumentStoreService } from './documentstore.service';

@Component({
   selector: 'app-documentstore',
   templateUrl: './documentstore.component.html',
   styleUrls: ['./documentstore.component.css']
})
export class DocumentStoreComponent implements OnInit {
   public isMinimized: boolean = false
   public rowData: any[] = [];
   private documentData: any[] = [];
   private gridApi;
   private gridColumnApi;
   public columnDefs;
   public customColumn;
   public ds_translated_data: any = null;
   public frameworkComponents;
   public gridOptions: GridOptions;
   public defaultColDef: any;
   public projectId: string = UserVariable.projectId
   public modifyStoreAvailability: boolean = false;
   public deleteDocumentStore: boolean = false;
   public addDocumentToStore: boolean = false;
   public deleteDocumentFromStore: boolean = false;
   public viewProcedureInstanceOutputList
   public title: string = '';
   public displayModel: boolean = false;
   public bsModalRef: BsModalRef;
   public facilityList: Array<any> = []
   public selectedFacility: Array<any> = [{ 'id': 'all', 'text': 'ALL' }, { 'id': 'none', 'text': 'None' }]
   public facilityId: string;
   public modules: Module[] = AllModules;

   constructor(private documentStoreService: DocumentStoreService, private sharedService: SharedService, private translate: TranslateService, private alertService: AlertService,
      private capabilityService: CapabilityService, private modalService: BsModalService, private loadingService: LoadingService, private router: Router) {

      this.modifyStoreAvailability = this.capabilityService.isCapabilityAssigned("updateDocumentStore");
      this.deleteDocumentStore = this.capabilityService.isCapabilityAssigned("deleteDocumentFromStore");
      this.addDocumentToStore = this.capabilityService.isCapabilityAssigned("addDocumentToStore");
      this.deleteDocumentFromStore = this.capabilityService.isCapabilityAssigned("deleteDocumentFromStore");
      this.viewProcedureInstanceOutputList = this.capabilityService.isCapabilityAssigned("viewProcedureInstanceOutputList");

      let browserLang = translate.getBrowserLang();
      this.gridOptions = <GridOptions>{
         context: {
            componentParent: this
         }
      };
      this.defaultColDef = {
			resizable: true,
			sortable: true,
		};
      translate.getTranslation(browserLang).subscribe((res: string) => {
         this.ds_translated_data = res['DOCUMENT_STORE']

         this.columnDefs = [
            {
               headerName: this.ds_translated_data.DOCUMENT, field: 'documentTitle', autoHeight: true, cellClass: "cell-wrap-text", filter: "agTextColumnFilter"
            },
            {
               headerName: this.ds_translated_data.SYSTEM, field: 'systemName', autoHeight: true, cellClass: "cell-wrap-text", filter: "agTextColumnFilter"
            },
            {
               headerName: this.ds_translated_data.FACILITIES, field: 'facilityName', autoHeight: true, cellClass: "cell-wrap-text",
               cellRendererFramework: FacilitiesCellRender
            },
            {
               headerName: this.ds_translated_data.VERSION, field: 'versionNo', autoHeight: true, cellClass: "cell-wrap-text", filter: "agTextColumnFilter"
            },
            {
               headerName: this.ds_translated_data.NOTES, field: 'notes', autoHeight: true, cellClass: "cell-wrap-text", filter: "agTextColumnFilter"
            },

         ]
         if (this.modifyStoreAvailability) {
            this.columnDefs.push({
               headerName: this.ds_translated_data.AVAILABLE, field: 'availability', autoHeight: true, cellClass: "cell-wrap-text", width: 85,
               cellRenderer: function (params) {
                  var eDiv = document.createElement('div');
                  var chkVal;
                  if (params.data.availability == 1) {
                     eDiv.innerHTML = '<input type="checkbox" id="chkAvailability" documentId=' + params.data.id + ' documentStoreId= ' + params.data.documentStoreId + ' checked>';
                  } else {
                     eDiv.innerHTML = '<input type="checkbox" id="chkAvailability" documentId=' + params.data.id + ' documentStoreId= ' + params.data.documentStoreId + '>';
                  }
                  var eButton = eDiv.querySelectorAll("[type='checkbox']")[0];
                  eButton.addEventListener('click', function () {
                     loadingService.showLoading(true, null, "", null)
                     if (this.checked) {
                        chkVal = "1";
                     } else {
                        chkVal = "0";
                     }
                     documentStoreService.updateDocumentStore(params.data.documentId, chkVal, params.data.id, null, false)
                        .subscribe(data => {
                           documentStoreService.tableReloadRequest(params.data.documentId, params.data.id, "updateDocumentStore")
                           // loadingService.hideLoading()
                           alertService.success(data.statusDescription)
                        },
                           error => {
                              documentStoreService.tableReloadRequest(params.data.documentId, params.data.id, "updateDocumentStore")
                              // loadingService.hideLoading()
                              alertService.error(error.statusDescription)
                           });
                  });
                  return eDiv;
               }

            })
         } else {
            this.columnDefs.push({
               headerName: this.ds_translated_data.AVAILABLE, field: 'availability', autoHeight: true, cellClass: "cell-wrap-text", width: 85,
               cellRenderer: function (params) {
                  var eDiv = document.createElement('div');
                  var chkVal
                  if (params.data.availability == 1) {
                     eDiv.innerHTML = '<input type="checkbox" documentId=' + params.data.id + ' documentStoreId= ' + params.data.documentStoreId + ' checked disabled>';
                  } else {
                     eDiv.innerHTML = '<input type="checkbox" documentId=' + params.data.id + ' documentStoreId= ' + params.data.documentStoreId + ' disabled>';
                  }
                  var eButton = eDiv.querySelectorAll("[type='checkbox']")[0];
                  eButton.addEventListener('click', function () {
                     loadingService.showLoading(true, null, "", null)
                     if (this.checked) {
                        chkVal = "1";
                     } else {
                        chkVal = "0";
                     }
                     documentStoreService.updateDocumentStore(params.data.id, chkVal, params.data.documentStoreId, null, false)
                        .subscribe(data => {
                           documentStoreService.tableReloadRequest(params.data.id, params.data.documentStoreId, "updateDocumentStore")
                           // loadingService.hideLoading()
                           alertService.success(data.statusDescription)
                        },
                           error => {
                              documentStoreService.tableReloadRequest(params.data.id, params.data.documentStoreId, "updateDocumentStore")
                              // loadingService.hideLoading()
                              alertService.error(error.statusDescription)
                           });

                  });
                  return eDiv;
               },
               hide:!this.modifyStoreAvailability

            })
         }
         this.columnDefs.push({
            headerName: this.ds_translated_data.PUBLISH_TIME, field: 'publishedTime', autoHeight: true, cellClass: "cell-wrap-text", comparator: this.dateTimeComparator,
            filter: "agDateColumnFilter", minWidth:180,
            filterParams: {
               comparator: function (filterLocalDateAtMidnight, cellValue) {
                  var dateAsString = cellValue.substring(0,10);
                  var cellDateTime = new Date(dateAsString);
                  var cellDate = new Date(cellDateTime.getFullYear(), cellDateTime.getMonth(), cellDateTime.getDate())
                  if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                     return 0;
                  }
                  if (cellDate < filterLocalDateAtMidnight) {
                     return -1;
                  }
                  if (cellDate > filterLocalDateAtMidnight) {
                     return 1;
                  }
               }
            }
         })
         this.columnDefs.push({
            headerName: this.ds_translated_data.IN_REVIEW, field: 'documentStoreId', autoHeight: true, cellClass: "cell-wrap-text", width: 85,
            cellRenderer: function (params) {
               var eDiv = document.createElement('div');
               var chkVal
               if (params.data.inReview == 1) {
                  eDiv.innerHTML = '<input type="checkbox" documentId=' + params.data.id + ' documentStoreId= ' + params.data.documentStoreId + ' checked disabled>';
               } else {
                  eDiv.innerHTML = '<input type="checkbox" documentId=' + params.data.id + ' documentStoreId= ' + params.data.documentStoreId + ' disabled>';
               }
               return eDiv;
            }
         })
         this.columnDefs.push({
            headerName: this.ds_translated_data.LOCATION, field: 'location', autoHeight: true, cellClass: "cell-wrap-text"

         })

         this.columnDefs.push({
            headerName: this.ds_translated_data.DELETE, field: '', autoHeight: true, cellClass: "cell-wrap-text", width: 85, suppressFiltersToolPanel: true,
            cellRendererFramework: DSDeleteButtonRender, hide: !this.deleteDocumentStore
         })

      });
   }


   dateTimeComparator(date1:any, date2:any) {
      let parts = date1.match(/(\d{1,2})-(\d{1,2})-(\d{4}) (\d{1,2}):(\d{2}):(\d{2})/);
      let date1Number = Date.UTC(+parts[3], parts[1]-1, +parts[2], +parts[4], +parts[5], +parts[6]);

      parts = date2.match(/(\d{1,2})-(\d{1,2})-(\d{4}) (\d{1,2}):(\d{2}):(\d{2})/);
      let date2Number = Date.UTC(+parts[3], parts[1]-1, +parts[2], +parts[4], +parts[5], +parts[6]);

      if (date1Number === null && date2Number === null) {
         return 0;
      }
      if (date1Number === null) {
         return -1;   
      }
      if (date2Number === null) {
         return 1;
      }

      return date1Number - date2Number;
   }

   ngOnInit() {
   }

   onColumnResized(event) {
   }

   onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      params.api.sizeColumnsToFit();
      this.getDocumentStoreByProjectId();
      setTimeout(function () {
         params.api.sizeColumnsToFit();
         params.api.resetRowHeights();
      }, 500);
      this.documentStoreService.tableReloadRespond().subscribe(
         (option: any) => {
            console.log("reload table event= "+ option.event)
            if(this.facilityId==null){
               this.getDocumentStoreByProjectId();
            }
            else {
               this.getDocumentsByFacility();
            }
         }
      )
   }

   ngOnDestroy(){
      this.gridApi.destroy();
      this.gridApi = null;
   }

   public removedFacility(event) {
      this.selectedFacility = [{ 'id': 'all', 'text': 'ALL' }]
   }

   public selectedFacilityId(event) {
      this.facilityId = event.id
      this.getDocumentsByFacility()
   }

   public getDocumentsByFacility() {
      this.loadingService.showLoading(true, null, "", null)
      this.documentStoreService.getStoreDocumentsByFacility(this.facilityId)
         .subscribe(data => {
            this.rowData = []
            this.documentData = []
            if(data.payload != null){
               this.rowData = data.payload;
               this.gridApi.sizeColumnsToFit();
            }else{
               //nothing to do here
            }            
            this.loadingService.hideLoading()
         },
            error => {
               this.loadingService.hideLoading()
               this.alertService.clear()
               this.alertService.error(error.statusDescription)
            });
   }

   public getDocumentStoreByProjectId() {
      if (this.projectId != null && this.projectId != undefined) {
         this.loadingService.showLoading(true, null, "", null)
         this.documentData = []
         this.documentStoreService.getDocumentStoreByProjectId(this.projectId)
            .subscribe(data => {
               if (data.payload != null) {
                  data.payload.forEach(element => {
                     if (element.id != null) {
                        this.documentData.push( element)
                     }
                  });
                  this.rowData = []
                  this.rowData = this.documentData;
                  // this.gridApi.sizeColumnsToFit();
                  this.gridApi.refreshCells();
                  this.facilityList = []
                  data.payload.forEach(element => {
                     if (element.id == null && element.facilityList != null) {
                        for (let key of Object.keys(element.facilityList)) {
                           this.facilityList.push({ 'id': key, 'text': element.facilityList[key] })
                        }
                     }
                  });
                  this.facilityList.sort(this.compare);
                  this.facilityList.unshift({ 'id': 'all', 'text': 'ALL' }, { 'id': 'none', 'text': 'None' })
               } else {
                  //nothing to do here
               }
               this.loadingService.hideLoading()
            },
               error => {
                  this.rowData = []
                  this.loadingService.hideLoading()
                  this.alertService.clear()
                  this.alertService.error(error.statusDescription)
               });
      } else {
         this.router.navigateByUrl('/login')
      }

   }

   compare(a, b) {
      const facilityA = a.text.toUpperCase();
      const facilityB = b.text.toUpperCase();

      let comparison = 0;
      if (facilityA > facilityB) {
         comparison = 1;
      } else if (facilityA < facilityB) {
         comparison = -1;
      }
      return comparison;
   }
}
