import { Component, OnInit } from '@angular/core';
import { ReportDataService } from '../report-data-service.service';
import {NestedTreeControl} from '@angular/cdk/tree';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import { LoadingService } from 'src/app/util/loading/loading.service';
import * as FileSaver from 'file-saver';

/**
 * Food data with nested structure.
 * Each node has a name and an optional list of children.
 */

interface System{

}
interface Tag {
  name: string;
  //children?: Tag[];
  Children?: Tag[];
}

const TREE_DATA: Tag[] = [];
/**
 * @title Tree with nested nodes
 */

@Component({
  selector: 'app-tags-systems-hierarchy',
  templateUrl: './tags-systems-hierarchy.component.html',
  styleUrls: ['./tags-systems-hierarchy.component.css']
})
export class TagsSystemsHierarchyComponent implements OnInit {
  public title: string = "Tags And Systems Hierarchy";
  public divHeight: number = (window.innerHeight) * 5 / 7;
  public searchTerm: string = '';

  treeControl = new NestedTreeControl<Tag>(node => node.Children);
  dataSource = new MatTreeNestedDataSource<Tag>();
  hasChild = (_: number, node: Tag) => !!node.Children && node.Children.length > 0;

  constructor(private reportDataService: ReportDataService, private loadingService: LoadingService) {
    this.dataSource.data = TREE_DATA;
  }

  treeHierarchy: any;

  ngOnInit() {
    this.loadData();
  }
  public loadData(){
    this.loadingService.showLoading(true, false, "Creating Tag System Hierarchy", 0);
    this.reportDataService.getTagsSystemsHierarchy()
					.subscribe(data => {
						if(data.payload.length != 0){
              this.treeHierarchy = JSON.parse(data.payload)["tagSystemHierarchy"];
              this.dataSource.data = this.treeHierarchy;
              this.loadingService.hideLoading();
						}
						},
						error => {
              this.loadingService.hideLoading();
              
          });
  }

  public exportClick(){
    this.reportDataService.downloadTagSystemHierarchyZIP()
          .subscribe(res => {
              let file = new Blob([res.body], { type: 'application/zip' });
              let fileName = res.headers.get("file_name");
              FileSaver.saveAs(file, fileName);
            },
            error => {
              
          });
  }

  public onkeyup(value: string): void {
    this.dataSource.data = this.search(this.treeHierarchy, value);
    this.treeControl.dataNodes = this.dataSource.data;
    if(value){
      this.treeControl.expandAll();
    }else{
      this.treeControl.collapseAll();
    }
  }

  public search(items: Tag[], term: string): any[] {
    return items.reduce((acc, item) => {
        if (this.contains(item.name, term)) {
            acc.push(item);
        } else if (item.Children && item.Children.length > 0) {
            let newItems = this.search(item.Children, term);

            if (newItems.length > 0) {
              acc.push({ name: item.name, Children: newItems });
            }
        }
        return acc;
    }, []);
  }

  public contains(text: string, term: string): boolean {
    return text.toLowerCase().indexOf(term.toLowerCase()) >= 0;
  }
}
