<div class="row notification-header py-2">
    <div class="col-sm-8">
        <b>Notification</b>
    </div>
    <div class="col-sm-4" *ngIf="!isHideClearAll">
        <a class="notification-show-all-btn" (click)="clearAll()">
            {{ 'NOTIFICATION_LIST.CLEAR_ALL' | translate}}
        </a>
    </div>
</div>
<div *ngFor="let notification of notificationList">
    <!--
        <div class="card col-sm-12 rounded-0 p-0 border-top-0 border-left-0 border-right-0">
        <div class="card-body row p-0">
           
            <div class="col-sm-12 pointer-cursor py-2" [ngClass]="{'notification_read': notification.viewed, 'notification_unread': !notification.viewed}"
                (click)="noticifationClickEvent(notification)">
                <p class="float-left mb-1">
                    <span [innerHTML]="notification.notificationContent"> </span>
                </p>
                <p class="float-left mb-1">
                    <span class="small" [innerHTML]="notification.firingTimestamp"></span>
                </p>
            </div>
        </div>
    </div>
    -->
    <div class="card col-sm-12 rounded-0 p-0  border-0"
        [ngClass]="{'output-notification': notification.notificationType=='output', 'system-notification':notification.notificationType=='system', 'other-notification':notification.notificationType!=='system' && 'output' }">
        <div class="card-body row p-0">
            <div class="media  p-2 w-100 pointer-cursor notification-item"
                [ngClass]="{'notification_read': notification.viewed, 'notification_unread': !notification.viewed}"
                (click)="noticifationClickEvent(notification)">
                <span *ngIf="notification.notificationType == 'output'" class="notify-type-icon  d-block"
                    [ngClass]="{'pdf': notification.fileType=='pdf', 'doc':notification.fileType=='doc', 'zip':notification.fileType=='zip', 'ppt':notification.fileType=='ppt' }"></span>
                <span *ngIf="notification.notificationType == 'system'"
                    class="notify-type-icon  d-block system-notify"></span>
                <div class="media-body">
                    <h4><span class="pr-2" [innerHTML]="notification.notificationContent"> </span>
                        <small><i>{{notification.notificationType}}</i></small>
                    </h4>
                    <p class="text-muted"><span class="small font-weight-bold pr-2">({{ notification.firingTimestamp |
                            amTimeAgo}})</span><span class="small" [innerHTML]="notification.firingTimestamp"></span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!isHideSeeMore" class="seemore_back">
    <a  class="btn btn-link btn-sm btn-seemore" (click)="loadData(false)">
        {{ 'NOTIFICATION_LIST.SEE_MORE' | translate}}
    </a>
</div>