export class IceTopicContent {
    constructor(
        public id: string,
        public topicContentName?: string,
        public topicId?: string,
        public courseId?: string,
        public description?: string,
        public descriptionHTML?: string,
        public courseVersion?: number,
        public sequence?: number,
        public copyTopicContentId?: string,
        public topicContentImage?: ProcImage,
        public topicContentVideo?: ProcVideo,
        public topicContentThreeDModel?: ProcThreeDModel,
        public selectedType?: string,
        public isTopicContentModified?: boolean,
        public linkedCourseId?: string
    ){}  
}

export class ProcImage {
    constructor(
        public id: string,
        public thumbnailImage?: string,
        public largeImage?: string,
        public title?: string
    ){}
}

export class ProcVideo {
    constructor(
        public id: string,
        public title?: string,
        public selectedURL?: string,
        public thumbnailVideo?: string,
        public largeVideo?: string
    ){}
}

export class ProcThreeDModel {
    constructor(
        public id: string,
        public title?: string,
        public selectedURL?: string,
        public layerContent?: any,
        public viewContent?: any,
        public thumbnailThreeDModel?: string,
        public largeThreeDModel?: string
    ){}
}
