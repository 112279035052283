import { OnInit, Component, ViewChild, ViewContainerRef, ElementRef, ComponentRef, ComponentFactoryResolver, SimpleChanges, AfterContentInit, OnChanges, OnDestroy } from '@angular/core';
import { WorkflowLoaderService } from './workflow-loader.service';

@Component({
    selector: 'app-workflow-configurations',
    templateUrl: './workflow.component.html',
    styleUrls: ['./workflow.component.scss']
  })

export class WorkflowComponent implements OnInit, AfterContentInit, OnChanges, OnDestroy{
    public paddingtop: number = 60;
    @ViewChild('navPanel', {static: true}) navPanel: ElementRef

    component: any;
    @ViewChild('wfHost', { read: ViewContainerRef, static: true }) componentHost: ViewContainerRef
    componentRef: ComponentRef<Component>
    
    public isSideBar: boolean = true
    public componentToLoad: string = null
    
    constructor(private wfLoaderService: WorkflowLoaderService, private resolver: ComponentFactoryResolver){
        this.component = this.wfLoaderService.loadComponentByName('workflowTemplates');
    }

    ngOnInit(){
        console.log(this.navPanel.nativeElement.offsetHeight);
        if (this.navPanel.nativeElement.offsetHeight > 55) {
            this.paddingtop = 78
        } else {
            this.paddingtop = 50
        }
    }

    changeSideBarItem(event: any){
        this.component = this.wfLoaderService.loadComponentByName(event.selectedItem);
        this.updateComponent()
    }

    ngAfterContentInit(): void {
        //Called after ngOnInit when the component's or directive's content has been initialized.
        //Add 'implements AfterContentInit' to the class.
        this.updateComponent()
    }

    ngOnChanges(changes: SimpleChanges): void {
        //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
        //Add '${implements OnChanges}' to the class.
    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        if (this.componentRef) {
			this.componentRef.destroy()
		}
    }

    updateComponent() {
        this.componentHost.clear()
		const authFormFactory = this.resolver.resolveComponentFactory(this.component)
		const componentRef = this.componentHost.createComponent(authFormFactory);
    }
}