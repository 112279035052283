<div class="modal-header py-2">
  <h5  class="modal-title">
    Mechanical Completion Approval Data
  </h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="container-fluid mt-3 mb-4 modal-container" id ="irnaddedit" >
  <form class="mt-2">

    <div class="row">
      <div class="col-sm-6">
        <div class="checkbox float-left">
          <label class="">
            <p class="h6 mb-0">Endorsed:</p>
          </label>
        </div>
      </div>
    </div>
    <div class="row " (click)="changeColor('1')" [ngClass]="clickedRow=='1' ? 'bgcolor' : ''">
      <div class="col-sm-8 dropdown-100">
        <label for="endcontractorName" class="mb-0   p-0">CONTRACTOR-Lead/ Assigned System Owner

        </label>
        <div class="badge-required mx-2"></div>
        <input #endContractorName type="text" class="form-control form-control-sm mb-2 newheight" id="endcontractorName"
               name="addTags_CADNo" >
      </div>
      <div class="col-sm-4 dropdown-100">
        <label for="endconDate" class="mb-0 ">Date</label>
        <div class="badge-required mx-2"></div>
        <input type="text" placeholder="Select date" class="form-control newheight" name="date_raised"  id="endconDate"
               [(ngModel)]="endConDate" [bsConfig]="bsConfig" placement="bottom" bsDatepicker>
      </div>
    </div>
    <div class="row" (click)="changeColor('2')" [ngClass]="clickedRow=='2' ? 'bgcolor' : ''">
      <div class="col-sm-8 dropdown-100">
        <label for="endcompanyName" class="mb-0  p-0">COMPANY-Subsea Engineer/ Assigned System Owner

        </label>
        <div class="badge-required mx-2"></div>
        <input #endCompanyName type="text" class="form-control form-control-sm mb-2 newheight" id="endcompanyName"
               name="addTags_CADNo" >
      </div>
      <div class="col-sm-4 dropdown-100">
        <label for="endcomDate" class="mb-0 ">Date</label>
        <div class="badge-required mx-2"></div>
        <input type="text" placeholder="Select date" class="form-control newheight" name="date_raised"  id="endcomDate"
               [(ngModel)]="endComDate" [bsConfig]="bsConfig" placement="bottom" bsDatepicker>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-sm-6">
        <div class="checkbox float-left">
          <label class="">
            <p class="h6 mb-0">Approved:</p>
          </label>
        </div>
      </div>
    </div>
    <div class="row " (click)="changeColor('3')" [ngClass]="clickedRow=='3' ? 'bgcolor' : ''">
      <div class="col-sm-8 dropdown-100">
        <label for="appcontractorName" class="mb-0   p-0">CONTRACTOR-Project Manager(or Site Manager)

        </label>
        <div class="badge-required mx-2"></div>
        <input #appContractorName type="text" class="form-control form-control-sm mb-2 newheight" id="appcontractorName"
               name="addTags_CADNo" >
      </div>
      <div class="col-sm-4 dropdown-100">
        <label for="appconDate" class="mb-0 ">Date</label>
        <div class="badge-required mx-2"></div>
        <input type="text" placeholder="Select date" class="form-control newheight" name="date_raised" id="appconDate"
               [(ngModel)]="appConDate" [bsConfig]="bsConfig" placement="bottom" bsDatepicker>
      </div>
    </div>
    <div class="row " (click)="changeColor('4')" [ngClass]="clickedRow=='4' ? 'bgcolor' : ''">
      <div class="col-sm-8 dropdown-100">
        <label for="appcompanyName" class="mb-0   p-0">COMPANY-Comm & Start-Up Lead(or Designate)

        </label>
        <div class="badge-required mx-2"></div>
          <input #appCompanyName type="text" class="form-control form-control-sm mb-2 newheight" id="appcompanyName"
               name="addTags_CADNo" >
      </div>
      <div class="col-sm-4 dropdown-100">
        <label for="appcomDate" class="mb-0 ">Date</label>
        <div class="badge-required mx-2"></div>
        <input type="text" placeholder="Select date" class="form-control newheight" name="date_raised"  id="appcomDate"
               [(ngModel)]="appComDate" [bsConfig]="bsConfig" placement="bottom" bsDatepicker>
      </div>
    </div>
    <hr />
    <div class="row my-2">
      <div class="col-sm-6">

        <div class="form-group files mb-1">
          <label class="">{{ 'IRN_ADD_EDIT.UPLOAD_DOCUMENTS' | translate }}</label>
          <div class="badge-required mx-2"></div>
          <input type="file" class="form-control  custom-b  form-control-file"
                 Name="profile" id="NewFile_FileUpload" accept=".pdf"
                 (change)="onFileChanged($event)" required>
        </div>
        <div class="row justify-content-between">
          <div class="col-sm-6">
            <label class="px-2 " *ngIf="mcDocumentUpload">
              <mark id="newfilename"
                    class="form-text text-muted pl-2 pr-2 rounded">{{ mcDocumentUpload.file.name}}
              </mark>
            </label>
          </div>
        </div>

      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button  type="submit" id="irn_update" class="btn btn-primary btn-sm float-left"
           (click)="save()">Save</button>
  <button type="button" id="irn_cancel" class="btn btn-secondary btn-sm float-left"
          (click)="cancel()">{{ 'CANCEL' | translate}}</button>
</div>
