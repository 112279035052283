import { Component } from "@angular/core";
import { WsResponse } from '../../util/ws-response.model';
import { WsType } from '../../util/ws-type';
import { AlertService } from '../../util/alert/alert.service';
import { Router } from '@angular/router';
import { LocalStorage } from '../../util/localstorage.service';
import { LoggedUser } from '../../user/logged-user.model';
import { SharedService } from "../../util/shared.service";
import { WsCallback } from "../../util/ws-callback.interface";
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { DependenciesmodalComponent } from '../../util/dependenciesmodal/dependenciesmodal.component';
import { ComponentsService } from './components.service';
import { LoadingService } from '../../util/loading/loading.service';
import { Constants } from "src/app/util/constants";

@Component({
	selector: 'app-cell-render',
	templateUrl: './components-cell-render-component.component.html'
})
export class ComponentsCellRenderComponent implements WsCallback {
	icon: string;
	type: string;
	disable: boolean = false;
	bsModalRef: BsModalRef;
	title: string;
	dependencyType: string;
	data: string = "";
	status: string = "";

	constructor(private alertService: AlertService, private router: Router, private sharedService: SharedService,
		private modalService: BsModalService, private translate: TranslateService, private loadingService: LoadingService, private componentsService: ComponentsService) {
		translate.addLangs(["es", "en"]);
		translate.setDefaultLang("en");
		let browserLang = translate.getBrowserLang();
		translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
	}

	agInit(params: any): void {
		let items = params.value.split(':')
		if (items.length >= 2) {
			this.data = items[1];
			if (items.length == 3) {
				this.status = items[2];
			}
		}
		this.setType(items[0])
		if (this.type == 'dependencies') {
			this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.VIEW;
			this.translate.get('COMPONENT_CELL_RENDER.DEPENDENCIES_LIST').subscribe((res: string) => {
				this.title = res;
			});
		} else if (this.type == 'status') {
			if (this.status == "Inactive") {
				this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.ENABLE;
				this.translate.get('COMPONENT_CELL_RENDER.ENABLE_CONFIRM').subscribe((res: string) => {
					this.title = res;
				});
			} else {
				this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.DISABLE;
				this.translate.get('COMPONENT_CELL_RENDER.DISABLE_CONFIRM').subscribe((res: string) => {
					this.title = res;
				});
			}
		} else if (this.type == 'delete') {
			this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.DELETE;
			this.translate.get('COMPONENT_CELL_RENDER.DELETE_CONFLICTS').subscribe((res: string) => {
				this.title = res;
			});
		} else if (this.type == 'edit') {
			this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.EDIT;
			this.translate.get('COMPONENT_CELL_RENDER.EDIT_CONFIRM').subscribe((res: string) => {
				this.title = res;
			});
		}
		this.sharedService.disableCellRendererExecute().subscribe(option => this.setDisable(option));
	}

	viewDependencies(dflag: boolean) {
		this.dependenciesModalWithComponent(this.title, dflag, true);
	}

	dependenciesModalWithComponent(header: String, dflag: boolean, cflag: boolean) {
		const initialState : object = {
			headerTitle: header,
			delFlag: dflag,
			cancelFlag: cflag,
			checkboxVal: this.status == "Inactive" ? true : false
		};
		this.sharedService.setFileId(this.data);
		this.sharedService.setFileType(Constants.DEPENDENCY_ITEM_TYPE.COMPONENT);
        this.sharedService.setAction("getDependencies");
		this.sharedService.setDependencyMessageType(this.dependencyType);
		this.sharedService.setParent(this);
		this.bsModalRef = this.modalService.show(DependenciesmodalComponent, { initialState, class: 'modal-lg' });
		setTimeout(() => {
			this.loadingService.hideLoading();
		}, 2000);
	
	}


	public invoke(type: String, data: any) {
		this.loadingService.showLoading(true, false, "View dependencies", 0)
		console.log("loading")
		switch (type) {
			case 'dependencies':
			case 'status':
			case 'edit':
			localStorage.setItem("isDelete", "false");
				this.viewDependencies(false);
				break;
			case 'delete':
			localStorage.setItem("isDelete", "true");
				this.viewDependencies(true);
				break;
			default:
				break;
		}
	}

	onSuccess(data: WsResponse, serviceType: WsType): void {
		if (serviceType == WsType.ADD_UPDATE_RECENT_PROJECT) {
			//refresh the recent list again
			//this.recentProjectService.getAllRecentProjects(this.loggedInUser.userId, this);
		}
		else if (serviceType == WsType.GET_ALL_RECENT_PROJECTS) {
			//this.recentProjectList = data.payload;
			//this.recentProjectService.setDefaultRecentProjects(data.payload, this);
		}
		else {

		}
	}

	onFail(res: WsResponse, serviceType: WsType): void {
		if (serviceType == WsType.GET_ALL_RECENT_PROJECTS) {
			this.alertService.error(res.statusDescription);
		} else if (serviceType == WsType.ADD_UPDATE_RECENT_PROJECT) {
			//We don't show the error message here since it is out of user control.
			console.log("=======error======" + res.statusDescription);
		}
		else {

		}
	}

	private setType(params) {
		this.type = params;
		if (params == 'status') {
			if (this.status == "Inactive") {
				this.disable = true;
				this.icon = 'far fa-times-circle';
			} else {
				this.icon = 'far fa-check-circle';
			}
		} else if (params == "disable_dependencies") {
			this.disable = true;
			this.icon = 'far fa-link';
		} else {
			this.icon = params == 'dependencies' ? 'far fa-link' :
				params == 'edit' ? "far fa-edit" :
					params == 'delete' ? 'far fa-trash-alt' : "";
		}
	}

	private setDisable(option: any) {
		let items = this.data.split(",")
		if (option != null && option.id == items[0] && this.type == 'status') {
			this.disable = option.data;
			this.icon = this.disable ? 'far fa-times-circle' : 'far fa-check-circle';
			this.status = this.disable ? 'Inactive' : 'Active';
			if (this.status == "Inactive") {
				this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.ENABLE;
				this.translate.get('COMPONENT_CELL_RENDER.ENABLE_CONFIRM').subscribe((res: string) => {
					this.title = res;
				});
			} else {
				this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.DISABLE;
				this.translate.get('COMPONENT_CELL_RENDER.DISABLE_CONFIRM').subscribe((res: string) => {
					this.title = res;
				});
			}
		}
	}
}  