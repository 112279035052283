import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { UserVariable } from '../../util/common/user-variable';
import { ServiceUrls } from '../../util/service-urls';
import { WsCallback } from '../../util/ws-callback.interface';
import { WsType } from '../../util/ws-type';
import { WsResponse } from '../../util/ws-response.model';
import { LocalStorage } from '../../util/localstorage.service';
import { map, catchError } from 'rxjs/operators'
import { CommonUtil } from 'src/app/util/common/common-util';

@Injectable()
export class ProcedureStoreService {

   constructor(private http: HttpClient, private commonUtil: CommonUtil) {
   }
   @Output() updateProcedureStoreTable: EventEmitter<any> = new EventEmitter()
   /**
   * Call service method to getProcedureStoreByProjectId using GET request.
   * @param projectId project Id   
   */

   getProcedureStoreByProjectId(projectId: string): Observable<WsResponse> {
      let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
      const options = { params: new HttpParams().set("token", token) }
      return this.http.get(ServiceUrls.getProcedureStoreByProjectId(projectId), options).
         pipe(
            map(response => {
               var modified = JSON.parse(JSON.stringify(response));
               return new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
            }),
            catchError(error => {
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
               return throwError(res)
            })
         );
   }

   /**
    * Call service method to updateProcedureStore using POST request.
    * @param procedureId Procedure Id
    * @param storeAvailability Store Availability
    * @param procedureStoreId Procedure Store Id
    */
   updateProcedureStore(procedureId: string, storeAvailability: string, procedureStoreId: string): Observable<WsResponse> {
      let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
      const headers = new Headers({ 'Content-Type': 'application/json' });
      let url = this.updateProcedureStoreURL(token, procedureId);
      return this.http.put(url, JSON.stringify(new Request(procedureId, storeAvailability, procedureStoreId))).
         pipe(
            map(response => {
               var modified = JSON.parse(JSON.stringify(response));
               return new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
            }),
            catchError(error => {
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
               return throwError(res)
            })
         );
   }

   /**
    * procedure store table refresh event emitter request
    * @param procedureId 
    * @param procedureStoreId 
    * @param event 
    */
   tableReloadRequest(procedureId: string, procedureStoreId: string, event: string){
      this.updateProcedureStoreTable.emit(
         {
            procedureId: procedureId,
            procedureStoreId: procedureStoreId,
            event: event
         }
      )
   }

   /**
    * procedure store table refresh event emitter respond
    */
   tableReloadRespond(){
      return this.updateProcedureStoreTable
   }



   /**
    * Call service method to updateProcedureStore using POST request.
    * @param procedureId Procedure Id
    * @param inReview In Review
    * @param procedureStoreId Procedure Store Id
    */
   updateInReviewProcedureStore(procedureId: string, inReview: string, procedureStoreId: string): Observable<WsResponse> {
      let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
      const headers = new Headers({ 'Content-Type': 'application/json' });
      let url = this.updateInReviewProcedureStoreURL(token, procedureId);
      return this.http.put(url, JSON.stringify(new Request(procedureId, inReview, procedureStoreId))).
         pipe(
            map(response => {
               var modified = JSON.parse(JSON.stringify(response));
               return new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
            }),
            catchError(error => {
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
               return throwError(res)
            })
         );
   }

   /**
   * Call service method to deleteProcedureStore using GET request.    
   * @param procedureStoreId Procedure Store Id
   */
   deleteProcedureStore(procedureStoreId: string): Observable<WsResponse> {
      let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
      const options = { params: new HttpParams().set("token", token) }
      return this.http.delete(ServiceUrls.deleteProcedureStoreByProcedureStoreId(procedureStoreId), options).
         pipe(
            map(response => {
               var modified = JSON.parse(JSON.stringify(response));
               return new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
            }),
            catchError(error => {
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
               return throwError(res)
            })
         );
   }

   /**
   * Call service method to getAddProcedureStoreByProjectId using GET request.
   * @param projectId project Id   
   */
   getAddProcedureStoreViewData(projectId: string): Observable<WsResponse> {
      let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
      const options = { params: new HttpParams().set("token", token) }
      return this.http.get(ServiceUrls.getAddProcedureStoreViewData(projectId), options).
         pipe(
            map(response => {
               var modified = JSON.parse(JSON.stringify(response));
               return new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
            }),
            catchError(error => {
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
               return throwError(res)
            })
         );
   }

   /**
    * Call service method to addProcedureToProcedureStore using POST request.
    * @param procedurestoremodel Procedure Id
   
    */
   addProcedureToProcedureStore(procedureStore: any): Observable<WsResponse> {
      let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
      const headers = new Headers({ 'Content-Type': 'application/json' });
      procedureStore['userToken'] = token
      return this.http.post(ServiceUrls.ADD_TO_PROCEDURE_STORE, JSON.stringify(procedureStore)).
         pipe(
            map(response => {
               var modified = JSON.parse(JSON.stringify(response));
               return new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
            }),
            catchError(error => {
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
               return throwError(res)
            })
         );
   }

   /**
   * Call service method to  getProcedureForAddToStore using GET request.
   * @param projectId project Id   
   */
   getProcedureForAddToStore(procedureId: string, outputId: string): Observable<WsResponse> {
      let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
      const options = { params: new HttpParams().set("token", token) }
      return this.http.get(ServiceUrls.getProcedureForAddToStore(procedureId, outputId), options).
         pipe(
            map(response => {
               var modified = JSON.parse(JSON.stringify(response));
               return new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
            }),
            catchError(error => {
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
               return throwError(res)
            })
         );
   }

   /**
  * Call service method to deleteProcedureZIPItem using GET request.    
  * @param outputconfigId outputconfig Id
  */
   deleteProcedureZIPItem(outputId: string): Observable<WsResponse> {
      let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
      const options = { params: new HttpParams().set("token", token) }
      return this.http.delete(ServiceUrls.deleteProcedureZIPItem(outputId), options).
         pipe(
            map(response => {
               var modified = JSON.parse(JSON.stringify(response));
               return new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
            }),
            catchError(error => {
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
               return throwError(res)
            })
         );
   }

   /**
   * Call service method to  getProcedureStoreDeletedItems using GET request.    
   * @param deleItem deletedItem
   */
   getProcedureStoreDeletedItems(): Observable<WsResponse> {
      let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
      const options = { params: new HttpParams().set("token", token) }
      return this.http.get(ServiceUrls.GET_DELETED_PROCEDURE_STORE, options).
         pipe(
            map(response => {
               var modified = JSON.parse(JSON.stringify(response));
               return new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
            }),
            catchError(error => {
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
               return throwError(res)
            })
         );
   }

   /**
   * Call service method to  getProcedureInstancesSearchResults using GET request.    
   * @param status selected status
   * @param document name
   */
   getProcedureInstancesSearchResults(selectedSystem: string): Observable<WsResponse> {
      let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
      const options = { params: new HttpParams().set("token", token) }
      return this.http.get(ServiceUrls.getProcedureInstancesSearchResults(selectedSystem), options).
         pipe(
            map(response => {
               var modified = JSON.parse(JSON.stringify(response));
               return new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
            }),
            catchError(error => {
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
               return throwError(res)
            })
         );
   }

   /**
  * Call service method to   getOutputErrorMessage using GET request.    
  * @param outputId  output id
  * @param itemType item type
  */
   getOutputErrorMessage(id: string, itemType: string): Observable<WsResponse> {
      let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
      const options = { params: new HttpParams().set("token", token) }
      return this.http.get(ServiceUrls.getOutputErrorMessage(id, itemType), options).
         pipe(
            map(response => {
               var modified = JSON.parse(JSON.stringify(response));
               return new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
            }),
            catchError(error => {
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
               return throwError(res)
            })
         );
   }

   /**
  * Call service method to   getOutputErrorMessage using GET request.    
  * @param Id  output id
  * @param prcStatus process status value
  */
   regenarateOutput(id: string, prcStatus: string): Observable<WsResponse> {
      let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
      const options = { params: new HttpParams().set("token", token) }
      return this.http.get(ServiceUrls.regenarateOutput(id, prcStatus), options).
         pipe(
            map(response => {
               var modified = JSON.parse(JSON.stringify(response));
               return new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
            }),
            catchError(error => {
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
               return throwError(res)
            })
         );
   }

   /**
   * Call service method to   getOutputErrorMessage using GET request.    
   * @param Id  output id
   * @param prcStatus process status value
   */
   terminateOutput(id: string, prcStatus: string): Observable<WsResponse> {
      let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
      const options = { params: new HttpParams().set("token", token) }
      return this.http.get(ServiceUrls.terminateOutput(id, prcStatus), options).
         pipe(
            map(response => {
               var modified = JSON.parse(JSON.stringify(response));
               return new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
            }),
            catchError(error => {
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
               return throwError(res)
            })
         );
   }


   /**
   * Call service method to   getAllFacilityList using GET request.    
   * @param outputId  output id
   * @param itemType item type
   */
   getAllFacility(): Observable<WsResponse> {
      let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
      const options = { params: new HttpParams().set("token", token) }
      return this.http.get(ServiceUrls.GET_ALL_FACILITY, options).
         pipe(
            map(response => {
               var modified = JSON.parse(JSON.stringify(response));
               return new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
            }),
            catchError(error => {
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
               return throwError(res)
            })
         );
   }


   /**
   * Call service method to getProceduresByFacility using GET request.    
   * @param facilityId  facility id
   */
   getProceduresByFacility(selectedFacilityId: string): Observable<WsResponse> {
      let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
      const options = { params: new HttpParams().set("token", token) }
      return this.http.get(ServiceUrls.getProceduresByFacility(selectedFacilityId), options).
         pipe(
            map(response => {
               var modified = JSON.parse(JSON.stringify(response));
               return new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
            }),
            catchError(error => {
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
               return throwError(res)
            })
         );
   }

   /**
   * Call service method to getProcedureStoreItemsByFacility   using GET request.    
   * @param facilityId  facility id
   */
   getProcedureStoreItemsByFacility(selectedFacilityId: string): Observable<WsResponse> {
      let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
      const options = { params: new HttpParams().set("token", token) }
      return this.http.get(ServiceUrls.getProcedureStoreItemsByFacility(selectedFacilityId), options).
         pipe(
            map(response => {
               var modified = JSON.parse(JSON.stringify(response));
               return new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
            }),
            catchError(error => {
               var modified = JSON.parse(JSON.stringify(error.error));
               var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
               return throwError(res)
            })
         );
   }

   downloadFile(outputId: string) {
      return this.http.get(ServiceUrls.DOWNLOAD_FILE, {
         responseType: "blob", params: new HttpParams().
            set("documentId", outputId).set("mode", "ViewOutput")
      });
   }

   public updateProcedureStoreURL(token: String, id: any) {
      let url = ServiceUrls.UPDATE_PROCEDURE_STORE + '/' + id + '/updateProcedureStore.json?token=' + token;
      return url;
   }
   public updateInReviewProcedureStoreURL(token: String, id: any) {
      let url = ServiceUrls.UPDATE_INREVIEW_PROCEDURE_STORE + '/' + id + '/updateInReviewProcedureStore.json?token=' + token;
      return url;
   }

}

class Request {
   constructor(private procedureId: string, private availability: string, private procedureStoreId: string) { }
}
