import {AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ColDef, GridOptions, Module, AllModules} from "@ag-grid-enterprise/all-modules";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {Subscription} from "rxjs";
import {SharedService} from "../../util/shared.service";
import {IrnService} from "../irn/irn.service";
import {TranslateService} from "@ngx-translate/core";
import {LoadingService} from "../../util/loading/loading.service";
import {AlertService} from "../../util/alert/alert.service";
import {Constants} from "../../util/constants";
import {WsResponse} from "../../util/ws-response.model";
import {WsType} from "../../util/ws-type";
import {ManageMCCCellRenderComponent} from "./managemcc-cell-render-component.component";
import {ManagemccService} from "./managemcc.service";
import {EditmccComponent} from "./editmcc/editmcc.component";
import {OutputGenerateModalComponent} from "../../home/files/outputgenerator/outputgeneratemodal.component";
import {FilesDataService} from "../../home/files/filesdata/filesdata.service";
import {UserVariable} from "../../util/common/user-variable";
import { MCCDocuSignCellRendererComponent } from './mccdocu-sign-cell-renderer/mccdocu-sign-cell-renderer.component';
@Component({
  selector: 'app-managemcc',
  templateUrl: './managemcc.component.html',
  styleUrls: ['./managemcc.component.css']
})
export class ManagemccComponent implements OnInit, AfterViewInit, OnDestroy   {
  @ViewChild("incompleteMCCModal") incompleteMCCModal: TemplateRef<any>;
  public incompleteModalRef: BsModalRef;

  generateOut : boolean = false;
  mccGeneratedCount:number=0;
  ChecksheetCompletedAndPunchListClosedForMC = []
  ChecksheetCompletedAndPunchListClosedForMCresults = []
  CheckIrnList = []
  systemListWithIncompleteItems = [];
  checkingSystemList = [];
  isAllChecksheetsPunchlistsCompleted :boolean =true
  nonCompleteSystems =[]
  selectedFile : {type:"Mechanical Completion",id:""}
  selectedOutputType = []
  searchValueListName :string;
  searchValueListValue :string;
  rowData: any = [];
  public modules: Module[] = AllModules;

  private filteredData: Array<any> = [];
  public columnDefs: any;
  public gridOptions: GridOptions;
  public overlayLoadingTemplate: any;
  public overlayNoRowsTemplate: any;
  public getRowNodeId: any;
  private gridColumnApi: any;
  public expandComponents: boolean = false;

  public bsModalRef: BsModalRef;
  public gridApi: any;
  selectedAssetList=[]

  public searchData: any;
  private hasText: string = "";
  private subsVar: Subscription;
  completionDateSearch : any;
  mccStatusSearch : any;
  systemSearch: any;
  private systemsHasIrn: true;
  assetsListWithoutApprovedOTNBySystemId = [];

  constructor(
      private modalService: BsModalService,
      private sharedService: SharedService,
      private irnService:IrnService,
      private translate: TranslateService,
      private loadingService: LoadingService,
      private alertService: AlertService,
      private managemccService : ManagemccService,
      private filesDataService: FilesDataService,
  ) {
    translate.addLangs(["es", "en"])
    translate.setDefaultLang("en");
    let browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
    this.columnDefs = [
      {
        headerName: this.translate.instant('MCC_SEARCH_VIEW.SYSTEM'),
        field: 'system',
        autoHeight: true,
        cellClass: "cell-wrap-text",
        minWidth: 250,
        sortable: true
      },
      {
        headerName: this.translate.instant('MCC_SEARCH_VIEW.MCC_STATUS'),
        field: 'mccStatus',
        autoHeight: true,
        cellClass: "cell-wrap-text",
        minWidth: 150,
        sortable: true
      },
      {
        headerName: this.translate.instant('MCC_SEARCH_VIEW.TERGETCOMPLETIONDATE'),
        field: 'targetCompletionDate',
        autoHeight: true,
        cellClass: "cell-wrap-text",
        minWidth: 150,
        sortable: true
      },
      {
        headerName: this.translate.instant('MCC_SEARCH_VIEW.EDIT'),
        field: "edit",
        minWidth: 75,
        //hide:!this.enableEditPunchlist,
        cellStyle: { 'text-align': 'center' },
        cellRendererFramework: ManageMCCCellRenderComponent,
      },
      {
        headerName: this.translate.instant('PUNCHLIST_SEARCH_VIEW.WORKFLOW_STATUS'),
        field: 'docusignStatus',
        autoHeight: true, 
        cellClass: "cell-wrap-text",   
        minWidth: 125,
        cellRendererFramework: MCCDocuSignCellRendererComponent
      },
      {
        headerName: this.translate.instant('MCC_SEARCH_VIEW.DELETE'),
        field: "delete",
        minWidth: 75,
        //hide:!this.enableDeletePunchlist,
        cellStyle: { 'text-align': 'center' },
        cellRendererFramework: ManageMCCCellRenderComponent,
      },
      {
        headerName: this.translate.instant('MCC_SEARCH_VIEW.APPROVE'),
        field: "approve",
        minWidth: 75,
        //hide:!this.disableCap,
        cellStyle: { 'text-align': 'center' },
        cellRendererFramework: ManageMCCCellRenderComponent,
      },
      {
        headerName: this.translate.instant('MCC_SEARCH_VIEW.SENT_PDF'),
        field: "sentpdf",
        minWidth: 75,
        //hide:!this.disableCap,
        cellStyle: { 'text-align': 'center' },
        cellRendererFramework: ManageMCCCellRenderComponent,
      },
      {
        headerName: this.translate.instant('MCC_SEARCH_VIEW.RETURNED_PDF'),
        field: "returnedpdf",
        minWidth: 75,
        //hide:!this.disableCap,
        cellStyle: { 'text-align': 'center' },
        cellRendererFramework: ManageMCCCellRenderComponent,
      }
    ];
    this.getRowNodeId = function (data) {
      return data.id;
    };
    this.gridOptions = {
      rowData: this.rowData,
      columnDefs: this.columnDefs,
      defaultColDef: {
        resizable: true,
        sortable: true
      },
      rowSelection: "single",
      rowMultiSelectWithClick: false,
      doesExternalFilterPass: this.externalFilterPass,
      animateRows: true,
      rowBuffer: 20,
      context: {
        componentParent: this
      },
      paginationPageSize: 10,
      getRowStyle  : (params)=>{
        if (params.data.mccStatus == "OnQueue") {
          return { background: '#FFDD77' };
        }else if(params.data.mccStatus == "Issued"){
          return { background: '#FCE2BE' };
        }else if(params.data.mccStatus == "Approved"){
          return { background: '#DEDEDE' };
        }else if(params.data.mccStatus == "Error"){
          return { background: '#FCCDC4' };
        }else{
          return { background: '#ffffff' };
        }
      }
    };

    //custom layout templates for loading and empty data sets
    this.overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Loading data...</span>';
    this.overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">No rows to show</span>';
  }

  ngOnInit() {
    this.managemccService.loadTableDataResponse().subscribe((option: any) => this.reloadData())
    this.managemccService.updateUIafterApprovalResponse().subscribe((option: any) => this.updateUIAfterApprove(option.mccId,option.uploaded))
    this.managemccService.updateUIafterMccGenerateResponse().subscribe((option: any) => this.updateUIAfterGenerateOutPut())
    this.subsVar = this.sharedService.editMCCResponse().subscribe((option: any) => this.openEditMCCModal());
    this.managemccService.setMCCcomp(this);
  }

  ngAfterViewInit(): void {
    // this.bsModalRef = this.modalService.show(ApprovemccComponent, { class: 'modal-xl', backdrop: 'static', keyboard: false })

  }

  ngOnDestroy(): void {
    if (this.subsVar) {
      this.subsVar.unsubscribe()
    }
    window.removeEventListener("resize", this.onWindowResize);
    this.gridApi.destroy();
    this.gridApi = null;
  }
  externalFilterPass(node: any) {
    return true;
  }
  rowClicked(param) {
    console.log(param)

  }
  onGridReady(params) {
    const allColumnIds = [];
    this.columnDefs.forEach((columnDef) => {
      allColumnIds.push(<ColDef>columnDef);
    });
    this.gridColumnApi = params.columnApi;
    this.gridApi = params.api;
    this.managemccService.setApiGridData(this.gridApi);
    params.api.sizeColumnsToFit();
    // window.addEventListener("resize", function () {
    //   setTimeout(function () {
    //     params.api.sizeColumnsToFit();
    //   });
    // });
    window.addEventListener("resize", this.onWindowResize);
  }
  private onWindowResize = () => {
   
		setTimeout(() => {
			if (this.gridApi) {
				this.gridApi.sizeColumnsToFit();
			}
		}); 
	};
  private openEditMCCModal() {
    console.log('[openAddEditValueListModal]');
    this.bsModalRef = this.modalService.show(EditmccComponent, { backdrop: 'static', keyboard : false, class : 'class-mccedit' })
  }

  searchFunc(data: any){
    console.log(data)
    this.searchData = data;
    this.systemSearch = "";
    this.mccStatusSearch  = "";
    this.completionDateSearch = "";
    this.hasText = "";

    var systemSearch= data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_MCC_SYSTEM];
    if (systemSearch != undefined) {
      this.systemSearch = systemSearch
    } else {

    }
    var mccStatusSearch= data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_MCC_STATUS];
    if (mccStatusSearch != undefined) {
      this.mccStatusSearch = mccStatusSearch
    } else {

    }

    var completionDateSearch = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_MCC_COMPLETION_DATE];
    if (completionDateSearch != undefined) {
      this.completionDateSearch = completionDateSearch;
    } else {
      //no has text
    }
    var hasText = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_HAS_THE_WORDS];
    if (hasText != undefined) {
      this.hasText = hasText;
    } else {
      //no has text
    }
    if (this.systemSearch != "" || this.mccStatusSearch != "" || this.completionDateSearch != "" || this.hasText != "") {
      let to: any = false;
      let data ={}
      data = {
        systemSearch : this.systemSearch,
        mccStatusSearch : this.mccStatusSearch,
        completionDateSearch :  this.completionDateSearch,
        hasText : this.hasText,
      }
      var json = JSON.stringify(data);
      if (to) { clearTimeout(to); }
      this.loadingService.showLoading(true, null, "Searching", null);
      to = setTimeout(() => {
        this.managemccService.searchMCCList(json).subscribe(
            data => {
              this.setDocumentSearchTableData(data.payload)
              console.log(data.payload);
            },
            error => {
              this.alertService.clear()
              this.alertService.error(error.statusDescription)
            },
            () =>{
              this.loadingService.hideLoading()
            });
      }, 200);
    } else {

      this.gridApi.hideOverlay()
    }
  }

  setDocumentSearchTableData(payload: any) {
    this.loadingService.hideLoading();
    this.filteredData = [];
    this.rowData = [];
    let id : number = 0
    if (payload != null) {
      if (this.expandComponents == false) {
        for (let i = 0; i < payload.length; i++) {
          id +=1;
          let targetCompletionDate = payload[i]["targetCompletionDate"] != null ? new Date(payload[i]["targetCompletionDate"]).toLocaleDateString() : null;
          // let targetCompletionDate = payload[i]["targetCompletionDate"] != null ? new Date(payload[i]["targetCompletionDate"]).toISOString().slice(0, 19).split('T')[0] +"" : null;

          this.filteredData.push({
            id : id,
            mccId:payload[i]["mcId"],
            system : payload[i]["systemNo"] + " " + payload[i]["systemName"],
            mccStatus : payload[i]["mcstatus"],
            targetCompletionDate : targetCompletionDate,
            approve: "approve:" + payload[i]["mcstatus"],
            edit: "edit:" + payload[i]["mcstatus"],
            docusignStatus: payload[i]["docuSignStatus"],
            docuSignId: payload[i]["docuSignId"],
            delete: "delete:"  + payload[i]["mcstatus"],
            sentpdf: "sentpdf:" + payload[i]["mcstatus"],
            returnedpdf: "returnedpdf:" + payload[i]["mcstatus"]+ ":" +  payload[i]["isUploaded"],
            required : payload[i]["mcRequired"],
            systemId : payload[i]["systemId"],
            systemName : payload[i]["systemNo"] + " " + payload[i]["systemName"]
          });
        }
      }
      this.rowData = this.filteredData;
      this.gridOptions.columnApi.getColumn('system').setSort("asc");
      this.gridApi.paginationProxy.currentPage = 0;
      this.gridOptions.api.sizeColumnsToFit();
      this.generateOut = false;
      if(this.rowData.length > 0 ){
        // this.gridOptions.api.forEachNode(node=> node.rowIndex ? 0 : node.setSelected(true));
        this.rowData.forEach((row)=>{
          if(row.mccStatus == "NotIssued"){
            this.generateOut = true;
          }
        })
      }else{
        this.generateOut = false;
      }
    } else {
      // no row data to show
      this.rowData = [];
      this.generateOut = false;
    }
    this.managemccService.setRowData(this.rowData);
  }

  checkIncompleteItemsForSystemList(){
    this.checkingSystemList = [];
    this.CheckIrnList=[];
    this.systemListWithIncompleteItems=[];
    var systemStr = "";
    for (let i = 0; i < this.rowData.length; i++) {
      if(this.rowData[i].mccStatus == "NotIssued"){
        if(i!= this.rowData.length-1){
          systemStr  += this.rowData[i].systemId + ",";
        }else {
          systemStr  += this.rowData[i].systemId;
        }
      }
    }
    this.rowData.forEach((row)=>{
      if(row.mccStatus == "NotIssued"){
        this.checkingSystemList.push({systemName :row.system, systemId : row.systemId, mcId : row.mccId})

      }
    });
    if(this.checkingSystemList.length>0){
      this.loadingService.showLoading(true, false, 'loading', 0);
      this.systemsHasIrn = true;
      this.managemccService.checkIncompleteItemsForSystemList( systemStr, UserVariable.projectId, UserVariable.userId, UserVariable.clientId)
          .subscribe(data => {
            this.loadingService.hideLoading()
            if(data.payload){
              for (let i = 0; i < data.payload.length; i++) {
                var incompletePLC = false;
                var noAssetsListWithoutApprovedOTN = false;
                if(this.systemsHasIrn){
                  if(!data.payload[i].systemHasIRN){
                    this.systemsHasIrn = data.payload[i].systemHasIRN;
                  }
                }
                if(data.payload[i].assetsListWithoutApprovedOTNBySystemId.length > 0){
                   var assetsListWithoutApprovedOTNBySystemIdItem  = {
                    "systemName" :  data.payload[i].systemNo + " " + data.payload[i].systemName,
                    "assetsListWithoutApprovedOTN" : data.payload[i].assetsListWithoutApprovedOTNBySystemId
                  }
                  this.assetsListWithoutApprovedOTNBySystemId.push(assetsListWithoutApprovedOTNBySystemIdItem)  
                  noAssetsListWithoutApprovedOTN = false;
                } else {
                  noAssetsListWithoutApprovedOTN = true;
                }
                if(!data.payload[i].allPunchlistItemsClosedByCategory || !data.payload[i].allChecksheetsComplete){
                  incompletePLC = true;
                }
                  var item  = {
                    "systemName" :  data.payload[i].systemNo + " " + data.payload[i].systemName,
                    "systemHasIRN" :   data.payload[i].systemHasIRN,
                    "incompletePLC" : incompletePLC,
                    "noAssetsListWithoutApprovedOTN" :noAssetsListWithoutApprovedOTN,
                    "assetsListWithoutApprovedOTN" : data.payload[i].assetsListWithoutApprovedOTNBySystemId
                  }
                this.systemListWithIncompleteItems.push(item);
              }
            }
            if(this.systemListWithIncompleteItems.length>0){
              this.incompleteModalRef = this.modalService.show(this.incompleteMCCModal, { class: 'modal-lg' })
            }else{
              this.continue()
            }
          },
              error => {
                this.loadingService.hideLoading()
              });
    }else {

    }
  }

  reloadData(){
    this.fireEvent("mainsearchinput", "click");
  }

  private fireEvent( ElementId, EventName ) {
    if( document.getElementById(ElementId) != null ) {
      const ke = new KeyboardEvent("keyup", {
        bubbles: true, cancelable: true, key:"Enter"
      });
      document.getElementById( ElementId ).dispatchEvent( ke );
    }
  }

  updateUIAfterApprove(mccId, uploaded){
    let itemIndex = this.rowData.findIndex(item => item.mccId == mccId);
    if (this.rowData[itemIndex]) {
      this.rowData[itemIndex].mccStatus = "Approved";
      this.rowData[itemIndex].approve= "approve:Approved"
      this.rowData[itemIndex].edit= "edit:Approved"
      this.rowData[itemIndex].delete= "delete:Approved"
      this.rowData[itemIndex].sentpdf= "sentpdf:Approved"
      this.rowData[itemIndex].returnedpdf= "returnedpdf:Approved:"+uploaded
      this.gridApi.setRowData(this.rowData);
      this.generateOut = false;
      if(this.rowData.length > 0 ){
        this.rowData.forEach((row)=>{
          if(row.mccStatus == "NotIssued"){
            this.generateOut = true;
          }
        })
      }
    }
  }
  updateUIAfterGenerateOutPut(){
    this.generateOut = false;
    if(this.rowData.length > 0 ){
      this.rowData.forEach((row)=>{
        if(row.mccStatus == "NotIssued" ){
          row.mccStatus = "OnQueue";
          row.approve= "approve:OnQueue"
          row.edit= "edit:OnQueue"
          row.delete= "delete:OnQueue"
          row.sentpdf= "sentpdf:OnQueue"
          row.returnedpdf= "returnedpdf:OnQueue:false"
        }
      })
    }
    this.gridApi.setRowData(this.rowData);
  }

  onFail(data: WsResponse, serviceType: WsType) {
    if(serviceType == WsType.REVOKE_MCC){
      this.loadingService.hideLoading();
      this.alertService.error("Could not revoke mcc");
    }else if(serviceType == WsType.DELETE_MC_DOCUMENT){
      this.alertService.success("Could not delete the pdf file")
    }
  }

  onSuccess(data: WsResponse, serviceType: WsType) {
      if(serviceType == WsType.REVOKE_MCC){
        this.loadingService.hideLoading();
        this.alertService.success("Successfully revoked the MCC")
        this.reloadData();
        // if(data.payload){
        //   let itemIndex = this.rowData.findIndex(item => item.mccId == data.payload);
        //   if (this.rowData[itemIndex]) {
        //     if(this.rowData[itemIndex].mccStatus == "Issued" || this.rowData[itemIndex].mccStatus == "Error" ){
        //       this.rowData[itemIndex].mccStatus = "NotIssued";
        //       this.rowData[itemIndex].approve= "approve:NotIssued"
        //       this.rowData[itemIndex].edit= "edit:NotIssued"
        //       this.rowData[itemIndex].delete= "delete:NotIssued"
        //       this.rowData[itemIndex].sentpdf= "sentpdf:NotIssued"
        //       this.rowData[itemIndex].returnedpdf= "returnedpdf:NotIssued:false"
        //     }else if(this.rowData[itemIndex].mccStatus == "Approved"){
        //       this.rowData[itemIndex].mccStatus = "Issued";
        //       this.rowData[itemIndex].approve= "approve:Issued"
        //       this.rowData[itemIndex].edit= "edit:Issued"
        //       this.rowData[itemIndex].delete= "delete:Issued"
        //       this.rowData[itemIndex].sentpdf= "sentpdf:Issued"
        //       this.rowData[itemIndex].returnedpdf= "returnedpdf:Issued:false"
        //     }
        //     this.gridApi.setRowData(this.rowData);
        //     this.generateOut = false;
        //     if(this.rowData.length > 0 ){
        //       this.rowData.forEach((row)=>{
        //         if(row.mccStatus == "NotIssued"){
        //           this.generateOut = true;
        //         }
        //       })
        //     }else{
        //       this.generateOut = false;
        //     }
        //   }
        // }
    }

  }

  public generateOutputBtnEvent() {
    let ids= [];
    this.checkingSystemList.forEach((item)=>{
      ids.push(item.mcId);
    })
    let type = "Mechanical Completion";
    this.loadingService.showLoading(true, null, "", null);
    this.filesDataService.getOutputTypeListByProjectIdDocType(type)
        .subscribe(data => {
              this.loadingService.hideLoading()
              this.selectedOutputType = []
              if (data.payload != null && data.payload.length > 0) {
                data.payload.forEach(element => {
                  this.selectedOutputType.push({ 'id': element, 'text': element })
                });
                const initialState: object = {
                  selectedOutputType: this.selectedOutputType,
                  outputData: "",
                  idList: ids,
                  type: type,
                  outputType: this.selectedOutputType[0].text,
                  outputId: this.selectedOutputType[0].id,
                  showTable : false
                }
                this.bsModalRef = this.modalService.show(OutputGenerateModalComponent, { class: 'modal-lg', initialState });

              } else {
                this.alertService.warn("Please add the Mechanical Completion template into this project");
              }
            },
            error => {
              this.loadingService.hideLoading()
              this.alertService.clear()
              this.alertService.error(error.statusDescription)
            });
  }

  continue (){
    if(this.incompleteModalRef){
      this.incompleteModalRef.hide();
    }
    this.generateOutputBtnEvent()
    // this.mccGeneratedCount =0
    // if(this.ChecksheetCompletedAndPunchListClosedForMC.length > 0){
    //   this.loadingService.showLoading(true, false, 'loading', 0);
    //   this.ChecksheetCompletedAndPunchListClosedForMC.forEach((item)=>{
    //     this.managemccService.generateOutput(item.mccId,this)
    //   })
    // }
    // if(this.bsModalRef){
    //   this.bsModalRef.hide()
    // }
  }


  cancelOutGenerete(){
    if(this.bsModalRef){
      this.bsModalRef.hide()
    }
    this.ChecksheetCompletedAndPunchListClosedForMC = []
    this.ChecksheetCompletedAndPunchListClosedForMCresults = []
    this.isAllChecksheetsPunchlistsCompleted = true;
    this.nonCompleteSystems = [];
    this.incompleteModalRef.hide()
  }
}
