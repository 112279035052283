import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { IAfterGuiAttachedParams, ICellRendererParams } from "@ag-grid-enterprise/all-modules";

@Component({
  selector: 'app-dot-notation-render',
  template: `
    {{name}}
  `,
  styles: []
})
export class DotNotationRenderComponent implements ICellRendererAngularComp {

  public name: any;

  constructor() { }

  refresh(params: any): boolean {
    return false;
  }

  agInit(params: ICellRendererParams): void {
    if (params.data.categoryList != undefined) {
      if (params.data.selectedAttributeType === 'Component') {
        params.data.categoryList.forEach(category => {
          if (params.data.categoryName != undefined) {
            this.name = params.data.categoryName;
          } else if (category.categoryId == params.data.categoryId) {
            this.name = category.categoryName;
          }
        });
      }
    } else {
      // when edit
      this.name = params.value;
    }
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
    console.log('afterGuiAttached');
  }

}
