import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserVariable } from '../../util/common/user-variable';
import { ServiceUrls } from '../../util/service-urls';
import { WsResponse } from '../../util/ws-response.model';
import { WsCallback } from '../../util/ws-callback.interface';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators'
import { CommonUtil } from 'src/app/util/common/common-util';

@Injectable({
    providedIn: 'root'
})
export class VideosService {
    public token: string;
    private rowData: any = null;
    private videos: any = null;
    private URLList: Array<any> = [];;
    private videoSearchState:any = null;
    private apiGridData: any = null;
    private callback: WsCallback;
    constructor(private http: HttpClient, private commonUtil: CommonUtil) { }
    public setCallback(callBack: WsCallback) {
        this.callback = callBack;
    }



    searchVideo(documentTypeId: String, description: String, hasText: string) {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        let url = this.getVideosBySearch(token);
        return this.http.post(url, JSON.stringify(new Request(documentTypeId, description, hasText))).
            pipe(
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    console.log(JSON.stringify(response))
                    return new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                }),
                catchError(error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    return throwError(res)
                })
            );

    }

    getVideos(videodata: any) {
        let a = JSON.stringify(videodata);
        console.log('a', a);
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        let url = this.getVideo(token);
        return this.http.post(url, JSON.stringify(new VideoRequest(a))).
            pipe(
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                }),
                catchError(error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    return throwError(res)
                })
            );

    }

    getThumbnailURL(data: any) {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        let url = ServiceUrls.VIDEO_SEARCH_VIDEO_THUMBNAIL + '?token=' + token;
        return this.http.post(url, new URLRequest(data)).
            pipe(
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                }),
                catchError(error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    return throwError(res)
                })
            );
    }

    getPosterframeURL(data: any) {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        let url = ServiceUrls.VIDEO_SEARCH_VIDEO_POSTERFRAME + '?token=' + token;
        return this.http.post(url, new URLRequest(data)).
            pipe(
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                }),
                catchError(error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    return throwError(res)
                })
            );
    }

    getVideoURL(data: any) {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        let url = ServiceUrls.VIDEO_SEARCH_VIDEO_URL + '?token=' + token;
        return this.http.post(url, new URLRequest(data)).
            pipe(
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                }),
                catchError(error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    return throwError(res)
                })
            );
    }

    /**
     * Achini Randeni
     * Get video detail by video id
     * @param videoId 
     */
    getVideoInfoById(videoId: any) {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        return this.http.get(ServiceUrls.getVideoInfoById(token, videoId)).pipe(
            map(response => {
                var modified = JSON.parse(JSON.stringify(response));
                return new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload
                )
            }),
            catchError(error => {
                var modified = JSON.parse(JSON.stringify(error.error));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload
                )
                return throwError(res)
            })
        );
    }

    public getVideosBySearch(token: String) {
        let url = ServiceUrls.VIDEO_SEARCH + '?token=' + token;
        return url;

    }
    public getVideo(token: String) {
        let url = ServiceUrls.VIDEO_SEARCH_VIDEO_URL + '?token=' + token;
        return url;
    }

    public setApiGridData(apiGridData: any) {
        this.apiGridData = apiGridData;
    }

    public getApiGridData() {
        return this.apiGridData;
    }

    public setRowData(rowData: any) {
        this.rowData = rowData;
    }

    public getRowData() {
        return this.rowData;
    }

    public setVideoList(videos: any) {
        this.videos = videos;
    }

    public getVideoList() {
        return this.videos;
    }

    public setURLList(urlList: any) {
        this.URLList = urlList;
    }

    public getURLList() {
        return this.URLList;
    }

    public setVideoSearchState(videoSearchState: any) {
        this.videoSearchState = videoSearchState;
    }

    public getVideoSearchState() {
        return this.videoSearchState;
    }
}
class Request {
    constructor(private documentTypeId: String, private description: String, private hasText: String,) { }
}

class VideoRequest {
    constructor(private videodata: any) { }
}

class URLRequest {
    constructor(private id: any) { }
}
