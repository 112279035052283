import { environment } from '../../environments/environment';
import { Constants } from './constants';

export class ServiceUrls {
    private static serviceName = "symbiosisservice/api/";
    private static serviceVersion = "v1/";

    private static servicePrefix = environment.server_URL + ServiceUrls.serviceName + ServiceUrls.serviceVersion;
    private static v2Prefix = environment.v2_url;
    //Project URLs
    public static USER_PROJECT_VIEW = ServiceUrls.servicePrefix + "users/";    
    public static FOLDERS = ServiceUrls.servicePrefix + "folders/";
    public static USER_FOLDER_VIEW = ServiceUrls.servicePrefix + "folders.json";
    public static ADD_FOLDER = ServiceUrls.servicePrefix + "folders.json";
    public static DELETE_FOLDER = ServiceUrls.servicePrefix + "folders/";
    public static UPDATE_FOLDER = ServiceUrls.servicePrefix + "folders/";
    public static FILE_BY_FOLDER_ID_VIEW_URL = ServiceUrls.servicePrefix + "folders/";
    public static GET_PDF_FILE_AND_FOLDERS = ServiceUrls.servicePrefix + "folders/getpdfFile/getPDFFileAndFolders.json";
    public static MOVE_FILES_FROM_FOLDER = ServiceUrls.servicePrefix + "folders/moveFilesFromFolder.json";
    public static LOGIN_URL = ServiceUrls.servicePrefix + "auth.json";
    public static TOPICS = ServiceUrls.servicePrefix + "topics/";
    public static STATEMENTS = ServiceUrls.servicePrefix + "statements/";
    public static FACILITIES = ServiceUrls.servicePrefix + "facilities/";
    public static COMPONENTS = ServiceUrls.servicePrefix + "components/";
    public static PROCEDURES = ServiceUrls.servicePrefix + "procedures/";
    public static TOPIC_MAPS = ServiceUrls.servicePrefix + "topicmaps/";
    public static CONTENTS = ServiceUrls.servicePrefix + "contents/";
    public static IMAGES = ServiceUrls.servicePrefix + "images/";
    public static ICECOURSE = ServiceUrls.servicePrefix + "icecourse/";
    public static ICETOPIC = ServiceUrls.servicePrefix + "icetopic/";
    public static SYSTEMS = ServiceUrls.servicePrefix + "systems/";
    public static TAGS = ServiceUrls.servicePrefix + "tags/";
    public static DOCUMENTS = ServiceUrls.servicePrefix + "documents/";
    public static DOCUMENT_TYPES_VIEW = ServiceUrls.servicePrefix + "documenttypes.json";
	public static DOCUMENT_TYPES_ATTRIBUTES_VIEW = ServiceUrls.servicePrefix + "documenttypes/";
	public static GET_REUSABLE_SEARCH_RESULTS = ServiceUrls.servicePrefix + "reusableupdates/getReusableSearchResults.json";
	public static REUSABLE_UPDATES = ServiceUrls.servicePrefix + "reusableupdates/";
	public static GET_CLIENT_LOGO = ServiceUrls.servicePrefix + "clients.json"; 
    // public static TOPIC_UPDATE = ServiceUrls.servicePrefix + "topics/";
    // public static TOPIC_UPDATE = ServiceUrls.servicePrefix + "topics/";
    // public static TOPIC_UPDATE = ServiceUrls.servicePrefix + "topics/";
    public static KEY_CODE_VERIFY_URL = ServiceUrls.servicePrefix + "kcv.json";
    public static GET_AUTH0_CONFIGURATIONS_URL = ServiceUrls.servicePrefix + "openid/1/getAuth0Configurations.json";
    public static OPEN_ID_LOGIN_URL = ServiceUrls.servicePrefix + "openid.json";
    public static FILE_EDITOR_VIEW = ServiceUrls.servicePrefix;
    public static TOPIC_CHECKOUT_VIEW = ServiceUrls.servicePrefix;
    public static SESSION_VALIDATION = ServiceUrls.servicePrefix + "auth/checks/isAlreadySignIn.json";
    public static REFRESH_TOKEN = ServiceUrls.servicePrefix + "auth/refreshtokenurl/refreshToken.json";
    public static GET_FILE_SEARCH_RESULTS = ServiceUrls.servicePrefix + "files/fileSearch/getFileSearchResults.json";
    public static DEPENDENCY = ServiceUrls.servicePrefix + "dependency/";
    //Facilities URLs
    public static FACILITIES_SEARCH_VIEW = ServiceUrls.servicePrefix + "facilities/facilitySearch/getFacilityListBySearch.json";
    public static ADD_FACILITY = ServiceUrls.servicePrefix + "facilities.json"; //for create method in the backend
    public static FACILITY_SEARCH_VIEW = ServiceUrls.servicePrefix + "facilities/";

    //Statement URLS
    public static STATMENT_SEARCH_VIEW = ServiceUrls.servicePrefix + "statements/";
    public static STATEMENT_SEARCH = ServiceUrls.servicePrefix + "statements/statementSearch/getStatementListBySearch.json";
    public static STATEMENT_SEARCH_WITH_CATEGORY = ServiceUrls.servicePrefix + "statements/statementSearch/getStatementListBySearchWithCategory.json";

    //category Search 
    public static CATEGORY_VIEW = ServiceUrls.servicePrefix + "categories/";

    public static CM_SYSTEM_DOCUMENTS = ServiceUrls.servicePrefix + "cmsystem/documents/";
    //checkout Document from editor
    public static CHECKOUT_DOCUMENT_EDITOR = ServiceUrls.servicePrefix + "cmsystem/documents/checkoutDocumentFromEditor.json";

    //Checkin document from editor
    public static CHECKIN_DOCUMENT_BY_EDITOR= ServiceUrls.servicePrefix + "cmsystem/documents/checkinTopicByIdEditor.json";

    //undo checkout document by editor
    public static UNDO_CHECKOUT_DOCUMENT_BY_EDITOR= ServiceUrls.servicePrefix + "cmsystem/documents/undoCheckoutDocumentById.json";



    public static TAGS_SEARCH_VIEW = ServiceUrls.servicePrefix + "tags/tagSearch/getTagListBySearch.json";
    public static COMPONENTS_S_SEARCH_VIEW = ServiceUrls.servicePrefix + "components/componentSearch/getComponentListBySearch.json";
    public static COMPONENTS_SS_CATEGORY_VIEW = ServiceUrls.servicePrefix + "components/getcomponentS/getComponentsByCategory.json";
    public static DOCUMENTSS_CATEGORY_VIEW = ServiceUrls.servicePrefix + "documents/getDocuments/getDocumentsListBySearchFields.json";
    public static COMPONENTS_SEARCH_WITH_CATEGORY = ServiceUrls.servicePrefix + "components/componentSearch/getComponentListBySearchWithCategory.json";

    //system URLs
	public static SYSTEMS_SEARCH_VIEW = ServiceUrls.servicePrefix + "systems/systemsSearch/getSystemsListBySearch.json";
	public static SYSTEMS_MODAL_VIEW = ServiceUrls.servicePrefix + "systemmodals/";
    public static ADD_SYSTEM = ServiceUrls.servicePrefix + "systems.json";
    public static UPDATE_SYSTEM = ServiceUrls.servicePrefix + "systems/systemsUpdate/updateSystem.json";
    public static SYSTEM_EDIT = ServiceUrls.servicePrefix + "systems/systemsSearch/getSystemBySystemId.json";

    public static GET_ALL_FACILITY = ServiceUrls.servicePrefix + "facilities";

    public static GET_PARENT_SYSTEM_LIST = ServiceUrls.servicePrefix + "systems/getsys/getALLParentSystem.json";

    public static GET_FACILITY_LIST = ServiceUrls.servicePrefix + "systems/getfac/getFacilityListByProjectId.json";

    public static GET_ASSET_LIST = ServiceUrls.servicePrefix + "tags/getTagListByProjectIdUsedInCommissioning/getTagListByProjectIdUsedInCommissioning.json";

    public static GET_ASSET_LIST_WITH_OTN_TARGET_COMPLETION_DATE = ServiceUrls.servicePrefix + "tags/getTagListByProjectIdUsedInCommissioning/getTagsWithOTNTargetCompletionDate.json";

    public static GET_ASSET_LIST_WITH_IRN_TARGET_COMPLETION_DATE = ServiceUrls.servicePrefix + "tags/getTagListByProjectIdUsedInCommissioning/getTagsWithIRNTargetCompletionDate.json";

    public static GET_ALL_SYSTEM_LIST = ServiceUrls.servicePrefix + "systems/geysys/getSystemsByProjectId";


    //document URLs
    public static ADD_DOCUMENT = ServiceUrls.servicePrefix + "documents.json";
    public static DOCUMENT_EDIT = ServiceUrls.servicePrefix + "documents/";
    public static UPDATE_DOCUMENT = ServiceUrls.servicePrefix + "documents/";

    //component URLs
    public static ADD_COMPONENT = ServiceUrls.servicePrefix + "components.json";
    public static COMPONENT_EDIT = ServiceUrls.servicePrefix + "components/";
    public static UPDATE_COMPONENT = ServiceUrls.servicePrefix + "components/";

    // history URLs
    public static HISTORY_SAVE_TO = ServiceUrls.servicePrefix + "history/historySave/saveToHisory.json";
    public static GET_HISTORY_TO = ServiceUrls.servicePrefix + "history/historySave/getHisory.json";

    //image URLs
    public static IMAGE_SEARCH = ServiceUrls.servicePrefix + "images/imagesSearch/getImageListOnSearch.json";
    public static IMAGE_SEARCH_IMAGE = ServiceUrls.servicePrefix + "images/imagesSearch/getImageAfterSearch.json";
    public static IMAGE_SEARCH_IMAGE_ON_CLICK = ServiceUrls.servicePrefix + "images/imagesSearch/getImageOnClick.json";
    public static GET_IMAGE_VIEW = ServiceUrls.servicePrefix + "images/imagesSearch/getImageView.json";
    public static IMAGE_SEARCH_THUMBNAIL_IMAGE = ServiceUrls.servicePrefix + "images/imagesSearch/getThumbnailImageDetailsAfterSearch.json";
    /**
     * Achini Randeni
     * @param token 
     * @param imageId 
     */
    public static getImageInfoById(token: string, imageId: string) {
        return ServiceUrls.servicePrefix + "images/" + imageId + "/getImageInfoById.json?token=" + token + "&imageId=" + imageId;
    }

    public static getPasswordResetUrl(userName: String, authType: number): string {
        return ServiceUrls.servicePrefix + "auth/reseturl/sendPasswordResetURL.json?uname=" + userName + "&authType=" + authType;
    }

    public static getValidatePasswordReset(key: String, id: String, authType: number) {
        return ServiceUrls.servicePrefix + "auth/resetpage/loadPasswordResetPage.json?key=" + key + "&id=" + id + "&authType=" + authType;
    }

    public static RESET_PASSWORD = ServiceUrls.servicePrefix + "auth/resetpw/resetPassword.json";

    public static CHANGE_PASSWORD = ServiceUrls.servicePrefix + "auth/changepw/changePassword.json";

    public static ADD_UPDATE_RECENT_PROJECT = ServiceUrls.servicePrefix + "recentprojects/addrecent/addUpdateRecentProject.json";

    public static getAllRecentProjects(userId: String): string {
        return ServiceUrls.servicePrefix + "recentprojects/getallp/getAllRecentProjects.json?userId=" + userId + "&projectType=" + Constants.APP_TYPE;
    }

    public static getDefaultRecentProject(userId: string): string {
        return ServiceUrls.servicePrefix + "recentprojects/getdefault/getDefaultRecentProject.json?userId=" + userId;
    }


    //punchlist
    public static GET_PUNCHLISTS = ServiceUrls.servicePrefix + "punchlist.json";
    public static PUNCHLISTS = ServiceUrls.servicePrefix + "punchlist/";
    public static PUNCHLIST_SEARCH_VIEW = ServiceUrls.servicePrefix + "punchlist/getPunchlists/getPunchlistsBySearchFields.json";
    
	public static revokePunchlist(token: String, punchlistId: String) {
        return ServiceUrls.servicePrefix + "punchlist/" + punchlistId + "/revokePunchlist.json?token=" + token;
    }
    
    //public static PUNCHLIST_EDIT = ServiceUrls.servicePrefix + "punchlist/getPunchlistByPunchlistId.json";
    public static GET_PUNCHLIST_IMAGE_DATA_S3 = ServiceUrls.servicePrefix + "punchlist/getDetails/getAllPunchlistsWithPhotosToUploadToS3.json";
    
    public static GET_PRECOMMISIONING = ServiceUrls.servicePrefix + "precommissioning.json";

    public static GET_OUTPUT_CONFIG_LIST = ServiceUrls.servicePrefix + "folderprop/getoutcfg/getOutputConfigList.json";

    public static SET_DEFAULT_RECENT_PROJECT = ServiceUrls.servicePrefix + "recentprojects/setdefault/setDefaultRecentProject.json";

    public static DELETE_USER_RECENT_PROJECT = ServiceUrls.servicePrefix + "recentprojects/delrecent/deleteUserRecentProject.json";

    public static DELETE_USER_DEFAULT_RECENT_PROJECT = ServiceUrls.servicePrefix + "recentprojects/deldefault/deleteUserDefaultRecentProject.json";

    public static CREATE_NEW_TOPIC = ServiceUrls.servicePrefix + "folderprop/addtopic/createFormItemInProjectPage.json";

    public static GET_SYSTEMLIST = ServiceUrls.servicePrefix + "systems/stnwidget/getAllSystemsList.json";

    //Manage Checksheets URLs
    public static CHECKSHEET = ServiceUrls.servicePrefix + "checksheets/";
    public static CHECKSHEET_SEARCH_VIEW = ServiceUrls.servicePrefix + "checksheets/checksheetSearch/getCheckSheetSearchResults.json";

    public static getAllDisciplines(token: string) {
        return ServiceUrls.servicePrefix + "checksheets/get/getAllDisciplines.json?token=" + token;
    }

    public static addNewCS(token: string): string {
        return ServiceUrls.servicePrefix + "checksheets.json?token=" + token;
    }

    public static updateCS(token: string): string {
        return ServiceUrls.servicePrefix + "checksheets/update/updateChecksheet.json?token=" + token;
    }

    public static getCSInstanceCount(token: String, csId: String) {
        return ServiceUrls.servicePrefix + "checksheets/" + csId + "/getCheckSheetInstanceCount.json?token=" + token;
    }

    public static getCSDataById(token: String, csId: String) {
        return ServiceUrls.servicePrefix + "checksheets/" + csId + "/getCheckSheetDataById.json?token=" + token;
    }

    public static getCSCheckDataById(token: String, csId: String) {
        return ServiceUrls.servicePrefix + "checksheets/" + csId + "/getCheckSheetChecksDataById.json?token=" + token;
    }

    public static saveCSChecks(token: String, csId: String) {
        return ServiceUrls.servicePrefix + "checksheets/assign/saveCheckSheetChecks.json?token=" + token;
    }

    public static saveCSAssets(token: String, csId: String) {
        return ServiceUrls.servicePrefix + "checksheets/assign/saveCheckSheetAssets.json?token=" + token;
    }

    public static getActivePhases(token: String) {
        return ServiceUrls.servicePrefix + "checksheets/getPhases/getAllActivePhases.json?token=" + token;
    }

    public static getAssetsForCS(token: String, csId: String) {
        return ServiceUrls.servicePrefix + "checksheets/" + csId + "/getCheckSheetAssets.json?token=" + token;
    }

    public static getSystemsOnChecksheets(token: string) {
        return ServiceUrls.servicePrefix + "checksheets/systemSearch/getSystemsOnCheckSheetSearch.json?token=" + token;
    }

    public static getActiveSystems(token: string, csId: string) {
        return ServiceUrls.servicePrefix + "checksheets/"+ csId +"/getAllActiveSystemsByProjectId.json?token=" + token;
    }

    public static getSystemsForCS(token: String, csId: String) {
        return ServiceUrls.servicePrefix + "checksheets/" + csId + "/getCheckSheetSystems.json?token=" + token;
    }

    public static saveCSSystems(token: String, csId: String) {
        return ServiceUrls.servicePrefix + "checksheets/assign/saveCheckSheetSystems.json?token=" + token;
    }

    //Commissioning widget URLS
    public static GET_PRECOMMISSIONING_DATA = ServiceUrls.servicePrefix + "precommissioning/precommissioningData/getPrecommissioningData.json";

    public static GET_MCC_TABLE_DATA = ServiceUrls.servicePrefix + "mechanicalcompletion/mccData/getMCCComTableData.json";

    public static GET_PUNCHLIST_TABLE_DATA = ServiceUrls.servicePrefix + "punchlist/punchlistData/getPunchlistDetails.json";

    public static getPersonDocuSignStatusUrl(docuSignId: string) {
		return ServiceUrls.servicePrefix + "docusign/" + docuSignId + "/viewPersonDocuSignStatus.json";
	}
    public static GET_STNPTN_TABLE_DATA = ServiceUrls.servicePrefix + "stns/stnData/getCommSTNTableData.json";

    public static GET_VENDOR_LIST = ServiceUrls.servicePrefix + "vendors.json";

    public static GET_CHECKSHEET_DATA = ServiceUrls.servicePrefix + "checksheets/checksheetsData/getChecksheetsData.json";

    public static GET_CHECKSHEET_SUMMARY = ServiceUrls.servicePrefix + "checksheets/checksheetsDetails/getChecksheetSummary.json";

    public static GET_CHECKSHEET_STATUS = ServiceUrls.servicePrefix + "checksheets/checksheetsDetails/getChecksheetStatus.json";

    public static CHECKIN_FILE = ServiceUrls.servicePrefix + "folderprop/checkinfile/checkFormItemName.json";

    public static GET_SYSTEM_LIST = ServiceUrls.servicePrefix + "folderprop/getsys/getSystemList.json";

    public static ADD_PROCEDURE = ServiceUrls.servicePrefix + "folderprop/addpro/addProcedure.json";

    public static ADD_NEW_FILE = ServiceUrls.servicePrefix + "folderprop/addfile/addNewFile.json";

    public static GET_DICTIONARY_BY_LANGUAGE_AND_PROJECT = ServiceUrls.servicePrefix + "wordlist.json";
    public static ADD_WORD = ServiceUrls.servicePrefix + "wordlist.json";
    public static DELETE_WORD = ServiceUrls.servicePrefix + "wordlist/";
    public static GET_WORD_LIST = ServiceUrls.servicePrefix + "wordlist/wordlist/getWordList.json"; 
    public static GET_WORDLIST = ServiceUrls.servicePrefix + "wordList/dictionary/getWordList.json";

    // public static CHECKIN_FILE = ServiceUrls.servicePrefix + "folderprop/checkinfile/checkFormItemName.json";

    public static CREATE_TOPIC_MAP = ServiceUrls.servicePrefix + "folderprop/addtopic/createFormItemInProjectPage.json";

    public static GET_TAG_DEPENDENCIES_BY_TAG = ServiceUrls.servicePrefix + "tags/getDependency/getTagDependenciesByTag.json";

    public static GET_TAG_DEPENDENCIES_BY_TAG_FOR_REPORTS = ServiceUrls.servicePrefix + "tags/getDependency/getTagDependenciesByTagForReports.json";
    public static GET_OPERATING_DATA_DEPENDENCIES = ServiceUrls.servicePrefix + +'operatingData/getDependency/getOperatingDataDependencies.json'

    public static GET_OPERATING_DATA_DEPENDENCIES_WITH_ID = ServiceUrls.servicePrefix + "operatingData/getDependency/getOperatingDataDependeciesWithId.json";

    public static GET_CLASSIFICATION_ATTRIBUTE_DEPENDENCIES_WITH_ID = ServiceUrls.servicePrefix + "tags/getDependency/getClassificationAttributeDependeciesWithId.json";

    public static GET_NOTIFICATIONS = ServiceUrls.servicePrefix + "notification/notificationList/getNotificationsByUser.json";

    public static GET_NOTIFICATION_COUNT = ServiceUrls.servicePrefix + "notification/notificationCount/getNotificationCountByUser.json";

    public static GET_NOTIFICATION_COUNT_IF_MODIFIED = ServiceUrls.servicePrefix + "notification/notificationCountIfModified/getNotificationsIfModified.json";

    public static GET_VIEWED_NOTIFICATION = ServiceUrls.servicePrefix + "notification/viewdNotification/updateViewedNotification.json";

    public static UPDATE_VIEWED_AS_ALL_NOTIFICATIONS = ServiceUrls.servicePrefix + "notification/viewdNotification/updateViewedAsAllNotifications.json";


    public static getProcedureStoreByProjectId(projectId: String): string {
        return ServiceUrls.servicePrefix + "projects/" + projectId + "/getProcedureStoreByProjectId.json";
    }

    public static getDocumentStoreByProjectId(projectId: String): string {
        return ServiceUrls.servicePrefix + "projects/" + projectId + "/getDocumentStoreByProjectId.json";
    }

    public static PROJECTS = ServiceUrls.servicePrefix + "projects/";
    public static USERS = ServiceUrls.servicePrefix + "users/";
	public static PROJECT_MODALS = ServiceUrls.servicePrefix + "projectmodals/";
	public static DUPLICATES = ServiceUrls.servicePrefix + "duplicates/";

    public static GET_CAPABILITY_LIST = ServiceUrls.servicePrefix + "capabilities.json";

    public static UPDATE_PROCEDURE_STORE = ServiceUrls.servicePrefix + "procedureStore";

    public static UPDATE_DOCUMENT_STORE = ServiceUrls.servicePrefix + "documentStore";

    public static UPDATE_INREVIEW_PROCEDURE_STORE = ServiceUrls.servicePrefix + "procedureStore";

    public static deleteProcedureStoreByProcedureStoreId(procedureStoreId: String): string {
        return ServiceUrls.servicePrefix + "procedureStore/" + procedureStoreId + ".json";
    }

    public static deleteDocumentStoreByDocumentStoreId(documentStoreId: String): string {
        return ServiceUrls.servicePrefix + "documentStore/" + documentStoreId + ".json";
    }

    public static getDisciplines(projectId: String): string {
        return ServiceUrls.servicePrefix + "projects/" + projectId + "/getAllActiveDisciplineForProject.json";
    }
    public static getAddProcedureStoreViewData(projectId: String): string {
        return ServiceUrls.servicePrefix + "projects/" + projectId + "/getAddProcedureStoreViewDataByProjectId.json";
    }

    public static getAddDocumentStoreViewData(projectId: String): string {
        return ServiceUrls.servicePrefix + "projects/" + projectId + "/getAddDocumentStoreViewDataByProjectId.json";
    }

    public static GET_TAG_BY_TAG_ID = ServiceUrls.servicePrefix + "tags/getTag/getTagByTagId.json";

    public static ADD_TO_PROCEDURE_STORE = ServiceUrls.servicePrefix + "procedureStore.json";
    
    public static ADD_TO_DOCUMENT_STORE = ServiceUrls.servicePrefix + "documentStore.json";

    public static getProcedureForAddToStore(procedureId: String, outputId: string): string {
        return ServiceUrls.servicePrefix + "procedureStore/" + procedureId + "/getProcedureForAddToStore.json?outputId=" + outputId;
    }

    public static getDocumentForAddToStore(procedureId: String, outputId: string): string {
        return ServiceUrls.servicePrefix + "documentStore/" + procedureId + "/getDocumentForAddToStore.json?outputId=" + outputId;
    }

    public static deleteProcedureZIPItem(outputId: String): string {
        return ServiceUrls.servicePrefix + "procedureStore/" + outputId + "/deleteProcedureZIPItem.json";
    }

    public static deleteDocumentZIPItem(outputId: String): string {
        return ServiceUrls.servicePrefix + "documentStore/" + outputId + "/deleteDocumentZIPItem.json";
    }

    public static GET_DELETED_PROCEDURE_STORE = ServiceUrls.servicePrefix + "procedureStore/delItem/getProcedureStoreDeletedItems.json";
    
    public static GET_DELETED_DOCUMENT_STORE = ServiceUrls.servicePrefix + "documentStore/delItem/getDocumentStoreDeletedItems.json";

    public static getProcedureInstancesSearchResults(status: string) {
        return ServiceUrls.servicePrefix + "procedureStore/proIns/getProcedureInstancesSearchResults.json?status=" + status + "&documentName=";
    }

    public static getDocumentStoreProcedureInstanceSearchResults(status: string) {
        return ServiceUrls.servicePrefix + "documentStore/docIns/getDocumentStoreProcedureInstanceSearchResults.json?status=" + status + "&documentName=";
    }

    public static getOutputErrorMessage(id: string, itemType: string) {
        return ServiceUrls.servicePrefix + "procedureStore/outputError/getOutputErrorMessage.json?id=" + id + "&itemType=" + itemType;
    }

    // public static GET_ALL_FACILITY = ServiceUrls.servicePrefix + "facilities";

    public static ENABLE_ALL = ServiceUrls.servicePrefix + "multiItems/multiItems/enableAll.json";

    public static DISABLE_ALL = ServiceUrls.servicePrefix + "multiItems/multiItems/disableAll.json";

    public static CHECKIN_ALL = ServiceUrls.servicePrefix + "multiItems/multiItems/checkinAllItems.json";

    public static CHECKOUT_ALL = ServiceUrls.servicePrefix + "multiItems/multiItems/checkoutAllItems.json";

    public static DELETE_ALL = ServiceUrls.servicePrefix + "multiItems/multiItems/deleteAllItems.json";

    public static UNDO_CHECKOUT_ALL = ServiceUrls.servicePrefix + "multiItems/multiItems/undoCheckoutAllItems.json";

    public static DUPLICATE_ALL = ServiceUrls.servicePrefix + "multiItems/multiItems/duplicateAllItems.json";

    public static GET_IMAGES_EXISTENCE = ServiceUrls.servicePrefix + "multiItems/multiItems/getImageExistenceStatus.json";

    public static CHECKIN_MULTIPLE_IMAGES = ServiceUrls.servicePrefix + "multiItems/multiItems/CheckinMultipleImage/checkingMultipleImages.json";

    public static GET_THREED_MODELS_EXISTENCE = ServiceUrls.servicePrefix + "multiItems/CheckinMultiple3Dmodel/getThreeDModelExistenceStatus.json";

    public static CHECKIN_MULTIPLE_VIDEOS = ServiceUrls.servicePrefix + "multiItems/CheckinMultipleVideo/checkinMultipleVideos.json"

    public static regenarateOutput(id: string, proStatus: string) {
        return ServiceUrls.servicePrefix + "procedureStore/regOutput/regenarateOutput.json?id=" + id + "&proStatus=" + proStatus;
    }

    public static terminateOutput(id: string, proStatus: string) {
        return ServiceUrls.servicePrefix + "procedureStore/terOutput/terminateOutput.json?id=" + id + "&proStatus=" + proStatus;
    }

    public static getProceduresByFacility(selectedFacilityId: string) {
        return ServiceUrls.servicePrefix + "facilities/" + selectedFacilityId + "/getProceduresByFacility.json";
    }

    public static getStoreDocumentsByFacility(selectedFacilityId: string) {
        return ServiceUrls.servicePrefix + "facilities/" + selectedFacilityId + "/getStoreDocumentsByFacility.json";
    }

    public static getProcedureStoreItemsByFacility(selectedFacilityId: string) {
        return ServiceUrls.servicePrefix + "facilities/" + selectedFacilityId + "/getProcedureStoreItemsByFacility.json";
    }

    public static getDocumentStoreItemsByFacility(selectedFacilityId: string) {
        return ServiceUrls.servicePrefix + "facilities/" + selectedFacilityId + "/getDocumentStoreItemsByFacility.json";
    }

    public static GET_SERVER_VERSION = ServiceUrls.servicePrefix + "serverversion.json";

    public static DOWNLOAD_FILE = environment.server_URL + "symbiosisservice/" + ServiceUrls.serviceVersion + "download";

    //public static GET_ALL_FACILITY = ServiceUrls.servicePrefix + "facilities.json";

    public static GET_FACILITY_WITH_DEPENDENCIES = ServiceUrls.servicePrefix + "tags/addTag/getFacilityWithDependencies.json";

    public static getSystemsByFacilityId(token: string, facilityId: string) {
        return ServiceUrls.servicePrefix + "systems/" + facilityId + "/getSystemsByFacilityId.json?token=" + token + "&facilityId=" + facilityId;
    }

    public static getAllActiveParentSystemsByFacility(token: string, facilityId: string) {
        return ServiceUrls.servicePrefix + "systems/" + facilityId + "/getAllActiveParentSystemsByFacility.json?token=" + token + "&facilityId=" + facilityId;
    }

    public static getDataSheetsByFacilityId(token: string, facilityId: string) {
        return ServiceUrls.servicePrefix + "documents/" + facilityId + "/getDocumentsByProjectIdAndActiveStatus.json?token=" + token + "&facilityId=" + facilityId;
    }

    public static getDocumentsByFacilityId(token: string, facilityId: string) {
        return ServiceUrls.servicePrefix + "documents/" + facilityId + "/getDocumentsByProjectIdAndActiveStatus.json?token=" + token + "&facilityId=" + facilityId;
    }
    
    public static getTagClassificationListWithAttributes(token: string) {
        return ServiceUrls.servicePrefix + "tagattribute/" + token + ".json";
    }

    public static GET_ALL_OUTPUTS = ServiceUrls.servicePrefix + "output.json";
    public static UPDATE_USER_PREFERENCES = ServiceUrls.servicePrefix + "userpreferences/updateUserPreferences/updateUserPreferences.json";

    public static getSystemsByProjectId(token: string) {
        return ServiceUrls.servicePrefix + "systems/getsystems/getSystemsByProjectId.json?token=" + token;
    }

    public static getComponentsByProjectId(token: string) {
        return ServiceUrls.servicePrefix + "components/getcomponents/getComponentsByProjectId.json?token=" + token;
    }

    public static getFailureClassList(token: string) {
        return ServiceUrls.servicePrefix + "cmms/getcmms/getFailureClassList.json?token=" + token;
    }

    public static getSafetyCriticalList(token: string) {
        return ServiceUrls.servicePrefix + "cmms/getcmms/getSafetycriticalList.json?token=" + token;
    }

    public static getCriticalityList(token: string) {
        return ServiceUrls.servicePrefix + "cmms/getcmms/getCriticalityList.json?token=" + token;
    }

    public static getEquipmentTypeList(token: string) {
        return ServiceUrls.servicePrefix + "cmms/getcmms/getEquipmentTypeList.json?token=" + token;
    }

    public static getDisciplineList(token: string) {
        return ServiceUrls.servicePrefix + "cmms/getcmms/getDisciplineList.json?token=" + token;
    }

    public static getAllTagSuggestionModelList(token: string, tagId: string, searchText: string) {
        return ServiceUrls.servicePrefix + "tags.json?token=" + token + "&tagId=" + tagId + "&searchText=" + searchText;
    }

    public static getDocuments(token: string, facilityId: string) {
        return ServiceUrls.servicePrefix + "documents.json?token=" + token + "&facilityId=" + facilityId;
    }

    public static GET_OUTPUT_SEARCH_RESULT = ServiceUrls.servicePrefix + "output/getsearchedoutput/getOutputSearchResults.json";

    public static UPDATE_PROCESS_STATUS = ServiceUrls.servicePrefix + "output/updateSatus/updatePrcessStatus.json"

    public static GET_OUTPUT_LIST_FOR_DOC_TYPE = ServiceUrls.servicePrefix + "output/getOutputlist/getOutputTypeListByProjectIdDocType.json"

    public static getAllActiveAssetGroups(token: string) {
        return ServiceUrls.servicePrefix + "assetgroups.json?token=" + token;
    }

    public static getAssetGroupAttributes(token: string, groupId: string) {
        return ServiceUrls.servicePrefix + "assetgroups/get/getAssetGroupAttributes.json?token=" + token + "&groupId=" + groupId;
    }

    public static getAssetGroupParents(token: string, groupId: string) {
        return ServiceUrls.servicePrefix + "assetgroups/get/getAssetGroupParents.json?token=" + token + "&groupId=" + groupId;
    }

    /**
     * Get tags which are used in commissioning as asset parent
	 * Issue - Two different queries running in V2 and V3 after selecting Asset Group to Populate Asset Parent Drop Down
	 *  Trello Card - https://trello.com/c/eUyEMcCb
	 * */
    public static getTagListByProjectIdUsedInCommissioning(token: string) {
        return ServiceUrls.servicePrefix + "tags/get/getTagListByProjectIdUsedInCommissioning.json?token=" + token;
    }

    public static getAllFunctionCodes(token: string) {
        return ServiceUrls.servicePrefix + "checksheets/get/getAllFunctionCodes.json?token=" + token;
    }

    public static getAllVendors(token: string) {
        return ServiceUrls.servicePrefix + "vendors.json?token=" + token;
    }
    public static getAllVendorswithDisables(token: string) {
        return ServiceUrls.servicePrefix + "vendors/vendorSearchResult/vendorSearchResult.json?token=" + token;
    }

    public static getAssetAttributes(token: string, groupId: string, reqAttributeId: string) {
        return ServiceUrls.servicePrefix + "assets/get/getAssetAttributes.json?token=" + token + "&groupId=" + groupId + "&reqAttributeId=" + reqAttributeId;
    }

    public static getAssetAttributeValues(token: string, groupId: string) {
        return ServiceUrls.servicePrefix + "assets/get/getActiveAssetAttributeValues.json?token=" + token + "&groupId=" + groupId;
    }

    public static getAssetOnChecksheets(token: string) {
        return ServiceUrls.servicePrefix + "assets/assetCSSearch/getAssetsOnCheckSheetSearch.json?token=" + token;
    }

    public static getActiveAssets(token: String, csId: String) {
        return ServiceUrls.servicePrefix + "assets/" + csId + "/getAllActiveAssetsByProjectId.json?token=" + token;
    }

    public static getValueListValues(token: string, listId: string, reqAttributeId: string) {
        return ServiceUrls.servicePrefix + "valuelist/get/getValueListValues.json?token=" + token + "&listId=" + listId + "&reqAttributeId=" + reqAttributeId;
    }

    public static addNewTag(token: string): string {
        return ServiceUrls.servicePrefix + "tags/addNewTag/addNewTag.json?token=" + token;
    }

    public static deleteTag(tagId:string, token: string): string {
        return ServiceUrls.servicePrefix + "tags/" + tagId + "/destroy.json?token=" + token;
    }

    public static GET_IMPORTED_DOCUMENT_CONFIG = ServiceUrls.servicePrefix + "templates/getImportedDocConfig/getImportedDocumentConfigInfo.json"

    public static GET_DOCUMENT_CONFIG = ServiceUrls.servicePrefix + "templates/getDocConfig/getDocumentConfig.json"

    public static GET_PROJECT_TEMPLATE = ServiceUrls.servicePrefix + "templates/getProjectTemp/getProjectTemplates.json"

    public static DELETE_PROJECT_TEMPLATE = ServiceUrls.servicePrefix + "templates/deleteProjectTemp/deleteProjectTemplate.json"

    public static updateTag(token: string): string {
        return ServiceUrls.servicePrefix + "tags/updateTag/updateTag.json?token=" + token;
    }

    public static getProject(token: string): string {
        return ServiceUrls.servicePrefix + "projects/getp/getProject.json?token=" + token;
    }

    public static SAVE_IMPORTED_DOCUMENT_CONFIG = ServiceUrls.servicePrefix + "templates/addImportDocConfig/saveImportedDocumentConfig.json"

    public static GET_TEMPLATE = ServiceUrls.servicePrefix + "templates/getTemp/getTemplatesWithoutMappingByProjectId.json"

    public static GET_TEMPLATE_FOR_DOC = ServiceUrls.servicePrefix + "templates/getTempForDoc/getTemplatesForDoc.json"

    public static PROJECT_TEMPLATE_SAVE = ServiceUrls.servicePrefix + "templates/projecttempSave/projectTemplateSave.json"

    public static EDITED_OP_DATA = ServiceUrls.servicePrefix + "templates/editedOp/editOptionalData.json"

    public static EDITED_TEMPLATE_NAME = ServiceUrls.servicePrefix + "templates/editedTem/editTemplateName.json"

    public static GET_TEMPLATE_CONFIG_INPUTS =  ServiceUrls.servicePrefix + "templates/getTemplateConfigInputs/getTemplateConfigInputs.json"

    public static GENERATE_OUTPUT = ServiceUrls.servicePrefix + "templates/generateOutput/generateOutput.json"

    public static GENERATE_MULTIPLE_OUTPUT = ServiceUrls.servicePrefix + "templates/generateMultipleOutput/generateMultipleOutput.json"

    public static GET_TEMPLATES_FOR_PROCEDURE_CONTENT_ITEMS =  ServiceUrls.servicePrefix + "templates/generateOutput/getTemplatesForProcedureContentItem.json"

    public static GET_TEMPLATES_FOR_DOCUMENT_STORE_CONTENT_ITEMS =  ServiceUrls.servicePrefix + "templates/generateOutput/getTemplatesForDocumentStoreContentItem.json"
    
    public static GET_OUTPUT_MODEL_BY_PROCESS_STATUS = ServiceUrls.servicePrefix + "output/outputQueue/getOutputModelsByProcessStatusList.json"

    public static CHANGE_PRIORITY = ServiceUrls.servicePrefix + "output/changePriority/updatePrority.json"

    public static GET_OUTPUT_CONFIG_LIST_BY_PROJECT = ServiceUrls.servicePrefix + "output/getouyputconfig/getOutputConfigList.json"

    public static UPDATE_OUTPUT_CONFIG = ServiceUrls.servicePrefix + "output/editOutputConfigList/updateOutputConfig.json"

    public static ADD_OUTPUT_CONFIG = ServiceUrls.servicePrefix + "output/addOutputConfigList/addOutputConfig.json"

    public static GET_MORE_OUTPUT_INFO = ServiceUrls.servicePrefix + "output/getMoreOutputInfo/getMoreOutputInfo.json"

    public static FILE_DOWNLOAD_INFO = ServiceUrls.servicePrefix + "output/getFileDownloadData/getFileDownloadData.json"

    public static getTagClassifications(token: string): string {
        return ServiceUrls.servicePrefix + "tags/tagclass/getTagClassifications.json?token=" + token;
    }

    public static UPDATE_CLASSIFICATION_DESCRIPTION = ServiceUrls.servicePrefix + "tags/desc/updateClassificationDescription.json";

    public static getCategoriesByClassification(token: string, classId: string) {
        return ServiceUrls.servicePrefix + "categories/getc/getCategoriesByClassification.json?token=" + token + "&classId=" + classId;
    }

    public static getAttributesByClassification(token: string, classId: string) {
        return ServiceUrls.servicePrefix + "tags/getc/getAttributesByClassification.json?token=" + token + "&classId=" + classId;
    }
    public static GET_TAG_ATTRIBUTE_TYPES = ServiceUrls.servicePrefix + "tagattribute/getAttrib/getTagAttributeTypes.json";

    public static GET_OPERATING_DATA_TYPES = ServiceUrls.servicePrefix + "operatingData/getOptData/getOperatingDataTypes.json";

	public static GET_CLIENT_LIST = ServiceUrls.servicePrefix + "clients/getList/viewClientList.json"

	public static CLIENTS = ServiceUrls.servicePrefix + "clients/";

	public static USERMODALS = ServiceUrls.servicePrefix + "usermodals/";

    public static UPDATE_TAG_ATTRIBUTE_SEQUENCE = ServiceUrls.servicePrefix + "tags/updateOrder/updateTagAttributeSequence.json";

    public static TOGGLE_TAG_CLASSIFICATION_ATTRIBUTE_REQUIRE_STATUS = ServiceUrls.servicePrefix + "tags/toggle/toggleTagClassificationAttributeRequireStatus.json";

    public static GET_TOPIC_TYPES_LIST = ServiceUrls.servicePrefix + "documenttypes/1/documenttype/getTopicTypesList.json";

    public static GET_DOCUMENT_TYPE_ATTRIBUTES = ServiceUrls.servicePrefix + "documenttypes/documenttype/getDocumentTypeAttributesList.json";

    public static GET_DOCUMENT_TYPE_AVAILABILITY = ServiceUrls.servicePrefix + "doctype/getDocTypeNameAvailability/getDocTypeNameAvailability.json";

    public static ADD_DOCUMENT_TYPE = ServiceUrls.servicePrefix + "doctype/addDocType/doAddSubDocType.json";

    public static DO_ADD_ATTRIBUTE = ServiceUrls.servicePrefix + "documenttypes/documenttype/doAddAttribute.json";

    public static VIEW_MANAGE_ATTRIBUTES = ServiceUrls.servicePrefix + "documenttypes/documenttypeview/doViewAttributeList.json";

    public static ATTRIBUTE_TYPE_NAME_AVAILABILITY = ServiceUrls.servicePrefix+ "documenttypes/attTypeAvailability/attTypeNameAvailability.json";

    public static DO_EDIT_ATTRIBUTE = ServiceUrls.servicePrefix+ "documenttypes/doEditAttribute/doEditAttribute.json";

    public static DO_SAVE_ATTRIBUTE = ServiceUrls.servicePrefix+ "documenttypes/doSaveAttribute/doSaveAttribute.json";

    public static DELETE_DOC_TYPE = ServiceUrls.servicePrefix+ "documenttypes/doSaveAttribute/doSaveAttribute.json";
    
    public static GET_SUB_SYSTEM = ServiceUrls.servicePrefix+ "systems/getsys/getSubSystemList.json";
    
    public static DISABLE_DOC_TYPE = ServiceUrls.servicePrefix+ "doctype/disableDocumentType/disableDocumentType.json";

    public static UPDATE_DOCUMENT_TYPE_ATTRIBUTE_TYPE = ServiceUrls.servicePrefix+ 'documenttypes/update/doUpdateAttribute.json'

    public static UPDATE_DOCUMENT_TYPE_ATTRIBUTE_DOT_NOTATION = ServiceUrls.servicePrefix+ 'documenttypes/update/updateCategoryId.json'

    public static getDependencyList(itemId: String): string {
       return ServiceUrls.servicePrefix + "documenttypes/" + itemId +"/getDependencies.json";
    }

    public static addDocumentType(token: String): string {
        return ServiceUrls.servicePrefix + "doctype/addDocType/doAddSubDocType.json?token="+token;
     }

    public static deleteDocumentType(itemId: String): string {
        return ServiceUrls.servicePrefix + "documenttypes/" + itemId +"/deleteDocumentType.json?docTypeId="+itemId;
     }

     public static getUpdateDocumentType(itemId: String,token: string): string {
        return ServiceUrls.servicePrefix + "documenttypes/" + itemId +"/updateDocumentType.json?token=" + token;
     }
    public static deleteDocTypeAttribute(itemId: String): string {
      return ServiceUrls.servicePrefix + "documenttypes/" + itemId + ".json";
    }

    public static deleteDocumentTypeAttribute(itemId: String) {
        return ServiceUrls.servicePrefix + "documenttypes/" + itemId +"/deleteAttribute.json";
    }

    public static toggleDocTypeStatus(itemId: String): string {
      return ServiceUrls.servicePrefix + "documenttypes/" + itemId + "/toggleDocTypeStatus.json";
    }

    public static doViewAttribute (itemId: String): string {
      return ServiceUrls.servicePrefix + "documenttypes/" + itemId +"/doViewAttribute.json";
   }

    public static REMOVE_TAG_CLASSIFICATION_ATTRIBUTE_MAP = ServiceUrls.servicePrefix + "tags/remove/removeTagClassificationAttributeMap.json";

    public static ADD_NEW_CLASSIFICATION = ServiceUrls.servicePrefix + "tags/addClass/addNewClassification.json";

    public static getTagClassAttributeList(token: string) {
        return ServiceUrls.servicePrefix + "tags/viewc/getTagClassAttributeList.json?token=" + token;
    }

    public static getOperatingDataList(token: string) {
        return ServiceUrls.servicePrefix + "operatingData/viewL/getOperatingDataList.json?token=" + token;
    }

    public static tagAttTypeNameAvailability() {
        return ServiceUrls.servicePrefix + "tagattribute/getava/tagAttTypeNameAvailability.json";
    }

    public static operatingDataNameAvailability() {
        return ServiceUrls.servicePrefix + "operatingData/getopt/operatingDataNameAvailability.json";
    }

    public static ADD_TAG_ATTRIBUTE = ServiceUrls.servicePrefix + "tagattribute/addattrib/addNewTagAttributeType.json";

    public static ADD_OPERATING_DATA = ServiceUrls.servicePrefix + "operatingData/addOptData/addNewOperatingDataType.json";

    public static UPDATE_TAG_ATTRIBUTE = ServiceUrls.servicePrefix + "tagattribute/update/updateTagAttribute.json";

    public static UPDATE_OPERATING_DATA = ServiceUrls.servicePrefix + "operatingData/update/updateOperatingData.json";

    public static DELETE_TAG_ATTRIBUTE = ServiceUrls.servicePrefix + "tagattribute/delete/deleteTagAttribute.json";

    public static DELETE_OPERATING_DATA = ServiceUrls.servicePrefix + "operatingData/delete/deleteOperatingData.json";

    public static UPDATE_TAG_ATTRIBUTE_NOTATION = ServiceUrls.servicePrefix + "tags/updateDotNotation/updateTagAttributeDotNotation.json";

    public static UPDATE_TAG_CLASS_ATTRIBUTE_TYPE = ServiceUrls.servicePrefix + "tags/updateTagAttributeType/updateTagAttributeType.json";

    public static updateTagAttributeValue(token: string) : string {
        return ServiceUrls.servicePrefix + "tags/updateTagAttributeValue/updateTagAttributeValue.json?token=" + token;
    }

    public static getValueList(token: string) {
        return ServiceUrls.servicePrefix + "cmms/getcmms/getValueList.json?token=" + token;
    }

    public static ADD_VALUE_LIST = ServiceUrls.servicePrefix + "cmms/addValueList/addValueList.json";

    public static UPDATE_VALUE_LIST = ServiceUrls.servicePrefix + "cmms/updateValueList/updateValueList.json";

    public static getDefaultItemsForValueList(token: string) {
        return ServiceUrls.servicePrefix + "cmms/getcmms/getDefaultItemsForValueList.json?token=" + token;
    }

    public static getValueListAttributes(token: string, valueListId: string) {
        return ServiceUrls.servicePrefix + "cmms/getcmms/getValueListAttributes.json?token=" + token + "&valueListId=" + valueListId;
    }

    public static SAVE_TOPIC_MAP = ServiceUrls.servicePrefix + "topicmaps/saveTopicmap.json";

    public static CHECKIN_TOPIC_MAP = ServiceUrls.servicePrefix + "topicmaps/checkinTopicmap.json";

    public static UPDATE_VALUE_LIST_SEQUENCE = ServiceUrls.servicePrefix + "cmms/updateSequence/setValueListAttrOrder.json";

    public static UPDATE_VALUE_LIST_ATTRIBUTE = ServiceUrls.servicePrefix + "cmms/updateName/updateValueListAttribute.json";

    public static ADD_VALUE_LIST_ATTRIBUTE = ServiceUrls.servicePrefix + "cmms/add/addValueListAttribute.json";

    public static DELETE_VALUE_LIST_ATTRIBUTE = ServiceUrls.servicePrefix + "cmms/delete/deleteValueListAttribute.json";

    public static ADD_EQUIPMENT_FIELD = ServiceUrls.servicePrefix + "cmms/addField/addEquipmentField.json";

    public static UPDATE_VALUE_LIST_NAME = ServiceUrls.servicePrefix + "cmms/updateListName/updateValueListName.json";

    public static DELETE_VALUE_LIST = ServiceUrls.servicePrefix + "cmms/deleteValueList/deleteValueList.json";

    public static ADD_NEW_IMAGE = ServiceUrls.servicePrefix + "folderprop/addimage/addNewImage.json";

    public static ADD_NEW_VIDEO = ServiceUrls.servicePrefix + "folderprop/addvideo/addNewVideo.json";

    public static CHECK_OUT_IMAGE = ServiceUrls.servicePrefix + "cmsystem/image/checkOutImage.json";

    public static UNDO_CHECK_OUT_IMAGE = ServiceUrls.servicePrefix + "cmsystem/image/undoCheckOutImage.json";

    //Utility urls
    public static UTILITY = ServiceUrls.servicePrefix + "utility/";

    public static CM_SYSTEM = ServiceUrls.servicePrefix + "cmsystem/cmsystemReq/";

    public static CHECK_IN_IMAGE = ServiceUrls.servicePrefix + "cmsystem/imageCheckIn/checkInImage.json";

    public static FILE_CHECKOUT = ServiceUrls.servicePrefix + "cmsystem/fileCheckOut/checkOutFile.json";

    public static FILE_CHECKOUT_UNDO = ServiceUrls.servicePrefix + "cmsystem/undoFileCheckOut/undoCheckOutFile.json";

    public static FILE_CHECK_IN = ServiceUrls.servicePrefix + "cmsystem/fileCheckIn/checkInFile.json";

    public static ASSIGN_TAG_ATTRIBUTE = ServiceUrls.servicePrefix + "tags/assign/assignTagAttribute.json";

	// public static GET_TOTAL_COMPONENT_REFERENCE = ServiceUrls.servicePrefix + "reports/component/viewTotalComponentReference.json";
    public static GET_TOTAL_COMPONENT_REFERENCE = ServiceUrls.servicePrefix + "reports/component/viewTotalComponentReferenceForReports.json";

    // public static GET_TOTAL_SYSTEM_REFERENCE = ServiceUrls.servicePrefix + "reports/component/viewTotalSystemReference.json";
    public static GET_TOTAL_SYSTEM_REFERENCE = ServiceUrls.servicePrefix + "reports/component/viewTotalSystemReferenceForReports.json";

    public static GET_TOTAL_SYSTEM_REFERENCE_FOR_SYSTEM_VIEW = ServiceUrls.servicePrefix + "reports/component/viewTotalSystemReferenceForSystemView.json";

    public static GET_TOTAL_TAG_REFERENCE = ServiceUrls.servicePrefix + "reports/tag/viewTotalTagReference.json";
    
    public static GET_TOTAL_TAG_REFERENCE_FOR_REFERENCE_VIEW = ServiceUrls.servicePrefix + "reports/tag/viewTotalTagReferenceForReferenceView.json";

    public static GET_TOTAL_STATEMENT_REFERENCE = ServiceUrls.servicePrefix + "reports/statement/viewTotalStatementReference.json";
    //public static GET_TOTAL_STATEMENT_REFERENCE = ServiceUrls.servicePrefix + "reports/statement/viewTotalStatementReferenceForReports.json";

    // public static GET_TOTAL_DOCUMENT_REFERENCE = ServiceUrls.servicePrefix + "reports/document/viewTotalDocumentReference.json";
    public static GET_TOTAL_DOCUMENT_REFERENCE = ServiceUrls.servicePrefix + "reports/document/viewTotalDocumentReferenceForReports.json";

    // public static GET_TOTAL_FACILITY_REFERENCE = ServiceUrls.servicePrefix + "reports/facility/viewTotalFacilityReference.json";
    public static GET_TOTAL_FACILITY_REFERENCE = ServiceUrls.servicePrefix + "reports/facility/viewTotalFacilityReferenceForReports.json";

    // public static GET_COMPONENT_LIST = ServiceUrls.servicePrefix + "reports/total/viewComponentList.json";

    // public static GET_USED_COMPONENT_LIST = ServiceUrls.servicePrefix + "reports/used/viewComponentList.json";
    public static GET_COMPONENT_LIST = ServiceUrls.servicePrefix + "reports/total/viewComponentListForReports.json";

    public static GET_USED_COMPONENT_LIST = ServiceUrls.servicePrefix + "reports/used/viewComponentListForReports.json";

    // public static GET_TOTAL_SYSTEM_LIST = ServiceUrls.servicePrefix + "reports/total/viewSystemList.json";

    // public static GET_USED_SYSTEM_LIST = ServiceUrls.servicePrefix + "reports/used/viewSystemList.json";
    public static GET_TOTAL_SYSTEM_LIST = ServiceUrls.servicePrefix + "reports/total/viewSystemListForReports.json";

    public static GET_USED_SYSTEM_LIST = ServiceUrls.servicePrefix + "reports/used/viewSystemListForReports.json";
    
    public static GET_USED_SYSTEM_VIEW_LIST = ServiceUrls.servicePrefix + "reports/used/viewSystemViewList.json";

    // public static GET_TOTAL_FACILITY_LIST = ServiceUrls.servicePrefix + "reports/total/viewFacilityList.json";

    // public static GET_USED_FACILITY_LIST = ServiceUrls.servicePrefix + "reports/used/viewFacilityList.json";
    public static GET_TOTAL_FACILITY_LIST = ServiceUrls.servicePrefix + "reports/total/viewFacilityListForReports.json";

    public static GET_USED_FACILITY_LIST = ServiceUrls.servicePrefix + "reports/used/viewFacilityListForReports.json";

    // public static GET_USED_STATEMENT_LIST = ServiceUrls.servicePrefix + "reports/used/viewStatementList.json";

    // public static GET_TOTAL_STATEMENT_LIST = ServiceUrls.servicePrefix + "reports/total/viewStatementList.json";
    public static GET_USED_STATEMENT_LIST = ServiceUrls.servicePrefix + "reports/used/viewStatementListForReports.json";

    public static GET_TOTAL_STATEMENT_LIST = ServiceUrls.servicePrefix + "reports/total/viewStatementListForReports.json";

    public static GET_USED_TAG_LIST = ServiceUrls.servicePrefix + "reports/used/viewTagList.json";

    public static GET_USED_TAG_LIST_FOR_REF_VIEW = ServiceUrls.servicePrefix + "reports/used/viewTagListForReferenceView.json";

    public static GET_TOTAL_COURSE_LIST = ServiceUrls.servicePrefix + "reports/used/viewCourseList.json";

    public static GET_COURSE_TOPIC_CONTENT_LIST = ServiceUrls.servicePrefix + "reports/used/viewCourseTopicContentList.json";

    public static GET_COURSE_LEARNING_CHECK_LIST = ServiceUrls.servicePrefix + "reports/used/viewCourseLearningCheckList.json";

    public static GET_COURSE_TOPIC_LIST = ServiceUrls.servicePrefix + "reports/used/viewCourseTopicList.json";

    public static GET_COURSE_GAME_LIST = ServiceUrls.servicePrefix + "reports/used/viewCourseGameList.json";

    public static GET_3D_MODEL_LIST = ServiceUrls.servicePrefix + "reports/used/view3dModelList.json";

    public static GET_VIDEO_LIST = ServiceUrls.servicePrefix + "reports/used/viewVideoList.json";

    public static GET_TOTAL_TAG_LIST = ServiceUrls.servicePrefix + "reports/total/viewTagList.json";

    public static GET_TOTAL_PROCEDURE_GRAPHIC_REFERENCE = ServiceUrls.servicePrefix + "reports/procedure/viewTotalProcedureGraphic.json";

    public static GET_PROCEDURE_GRAPHIC_LIST = ServiceUrls.servicePrefix + "reports/procedure/viewProcedureGraphicList.json";

    public static GET_TOTAL_AUTHORS = ServiceUrls.servicePrefix + "reports/authors/viewTotalAuthors.json";

    public static GET_TOTAL_AUTHORS_LIST = ServiceUrls.servicePrefix + "reports/authors/viewAuthorsList.json";

    public static GET_TAGS_SYSTEMS_HIERARCHY = ServiceUrls.servicePrefix + "reports/getTagsSystems/createTagSystemHierarchy.json";

    public static GET_TAG_SYSTEM_HIERARCHY_DOWNLOAD_ZIP_FILE = ServiceUrls.servicePrefix + "reports/getTagsSystems/downloadTagSystemHierarchy.json";

    public static getTagAddPreConditions(token: string) {
        return ServiceUrls.servicePrefix + "tags/precon/getTagAddPreConditions.json?token=" + token;
    }

    public static searchAlternateTag(token: string, tagNumber: string, facilityId: string, startIndex: number, size: number) {
        return ServiceUrls.servicePrefix + "tags/search/searchAlternateTag.json?token=" + token + "&tagNumber=" +
        tagNumber+ "&startIndex=" + startIndex + "&size=" + size + "&facilityId=" + facilityId;
    }

    public static getNextAvailableEquipmentNo(token: string) {
        return ServiceUrls.servicePrefix + "tags/equipno/getNextAvailableEquipmentNo.json?token=" + token;
    }

    public static getDocumentTypes(token: string) {
        return ServiceUrls.servicePrefix + "doctype/getDocTypes/getAllTopicTypes.json?token=" + token;
    }

    public static getDocumentTypeAttributeList(token: string, docTypeId: string) {
        return ServiceUrls.servicePrefix + "doctype/getDocProperty/getDocumentTypeAttributeList.json?token=" + token + "&docTypeId=" + docTypeId;
    }

    public static addDocumentTypeAttribute(typeId: String): string {
      return ServiceUrls.servicePrefix + "documenttypes/" + typeId + "/addDocumentTypeAttribute.json";
  }

    //File Course Urls
    public static checkinICECourse(token:string) {
        return ServiceUrls.servicePrefix + "icecourse_cm/course/checkin.json?token=" + token;
    }

    public static checkoutICECourse(token:string) {
        return ServiceUrls.servicePrefix + "icecourse_cm/course/checkout.json?token=" + token;
    }

    public static undoCheckoutICECourse(token:string) {
        return ServiceUrls.servicePrefix + "icecourse_cm/course/undocheckout.json?token=" + token;
    }

    public static getLatestCMCourses(token:string) {
        return ServiceUrls.servicePrefix + "icecourse_cm/course/getLatestCMCourses.json?token=" + token;
    }

    public static getCMLearningCheckList(token:string) {
        return ServiceUrls.servicePrefix + "icecourse_cm/course/getCMLearningCheckList.json?token=" + token;
    }

    public static getCMGameList(token:string) {
        return ServiceUrls.servicePrefix + "icecourse_cm/course/getCMGameList.json?token=" + token;
    }

    //Course Urls
    public static createICECourse(token:string) {
        return ServiceUrls.servicePrefix + "icecourse/course/create.json?token=" + token;
    }
    public static createICECourseForImport(token:string) {
        return ServiceUrls.servicePrefix + "icecourse/course/createForImport.json?token=" + token;
    }
    public static saveCourseDuplicateDetails(token:string) {
        return ServiceUrls.servicePrefix + "icecourse/course/saveDuplicateCourseDetails.json?token=" + token;
    }
    public static updateICECourse(token:string) {
        return ServiceUrls.servicePrefix + "icecourse/course/update.json?token=" + token;
    }
    public static checkFileName(token:string) {
        return ServiceUrls.servicePrefix + "icecourse/course/checkFileName.json?token=" + token;
    }    
    public static getICECourse(token:string, courseId:string) {
        return ServiceUrls.servicePrefix + "icecourse/" + courseId + "/show.json?token=" + token;
    }

    public static getICECourseDataPreview(token:string, courseId:string) {
        return ServiceUrls.servicePrefix + "icecourse/" + courseId + "/getICECourseDataPreview.json?token=" + token;
    }

    public static getICECourseDataPreviewDetails(token:string, courseId:string, itemType:string, refID:string) {
        return ServiceUrls.servicePrefix + "icecourse/" + courseId + "/getICECourseDataPreviewDetails.json?token=" + token+ "&itemType=" + itemType + "&refID=" + refID;
    }

    public static getSelectedImage(token:string, imageID:string) {
        return ServiceUrls.servicePrefix + "icecourse/course/getSelectedImage.json?token=" + token+ "&imageId=" + imageID;
    }

    public static getBasicICECourse(token:string, courseId:string) {
        return ServiceUrls.servicePrefix + "icecourse/" + courseId + "/getBasicICECourse.json?token=" + token;
    }
    public static deleteICECourse(token:string, courseId:string) {
        return ServiceUrls.servicePrefix + "icecourse/" + courseId + "/destroy.json?token=" + token;
    }
    public static getICECourseRevisonData(token:string, courseId:string) {
        return ServiceUrls.servicePrefix + "icecourse_cm/" + courseId + "/getRevisiondata.json?token=" + token;
    }
    public static restoreICECourseRevision(token:string) {
        return ServiceUrls.servicePrefix + "icecourse_cm/course/restoreRevision.json?token=" + token;
    }
    public static saveICETopicList(token:string) {
        return ServiceUrls.servicePrefix + "icecourse/course/saveTopics.json?token=" + token;
    }
    public static getAllICETopicListOfICECourse(token:string, courseId:string) {
        return ServiceUrls.servicePrefix + "icecourse/" + courseId + "/getAllICETopicListOfICECourse.json?token=" + token;
    }
    public static getAllICETopicContentListOfICECourse(token:string, courseId:string) {
        return ServiceUrls.servicePrefix + "icecourse/" + courseId + "/getAllICETopicContentListOfICECourse.json?token=" + token;
    }
    public static saveLearningCheckList(token:string) {
        return ServiceUrls.servicePrefix + "icecourse/course/saveLearningChecks.json?token=" + token;
    }
    public static getAllActiveICECourses(token:string, id: string) {
        return ServiceUrls.servicePrefix +  "icecourse/" + id + "/getAllICECoursesByStatus.json?token=" + token;
    }
    public static getAllActiveICECoursesByProjectId(token:string, projId: string) {
        return ServiceUrls.servicePrefix +  "icecourse/" + projId + "/getAllActiveICECoursesByProjectId.json?token=" + token;
    }

    //Topic Urls
    public static createICETopic(token:string) {
        return ServiceUrls.servicePrefix + "icetopic/topic/create.json?token=" + token;
    }
    public static updateICETopic(token:string) {
        return ServiceUrls.servicePrefix + "icetopic/topic/update.json?token=" + token;
    }
    public static reorderICETopic(token:string) {
        return ServiceUrls.servicePrefix + "icetopic/topic/reorderICETopic.json?token=" + token;
    }
    public static getICETopic(token:string, topicId:string) {
        return ServiceUrls.servicePrefix + "icetopic/" + topicId + "/show.json?token=" + token;
    }
    public static viewDependenciesForTopic(token:string, topicId:string) {
        return ServiceUrls.servicePrefix + "icetopic/" + topicId + "/viewDependenciesForTopic.json?token=" + token;
    }
    public static deleteICETopic(token:string, topicId:string) {
        return ServiceUrls.servicePrefix + "icetopic/" + topicId + "/destroy.json?token=" + token;
    }
    public static getAllICETopicsByCourseId(token:string, courseId:string) {
        return ServiceUrls.servicePrefix + "icetopic/" + courseId + "/viewICETopicListByCourseId.json?token=" + token;
    }
    public static getAllICETopicsByTopicId(token:string, topicId:string) {
        return ServiceUrls.servicePrefix + "icetopic/" + topicId + "/viewICETopicListByTopicId.json?token=" + token;
    }
    public static saveICETopicContentList(token:string) {
        return ServiceUrls.servicePrefix + "icetopic/topic/saveTopicContents.json?token=" + token;
    } 

    //Topic Content Urls
    public static createICETopicContent(token:string) {
        return ServiceUrls.servicePrefix + "icetopiccontent/topiccontent/create.json?token=" + token;
    }
    public static updateICETopicContent(token:string) {
        return ServiceUrls.servicePrefix + "icetopiccontent/topiccontent/update.json?token=" + token;
    }
    public static getICETopicContent(token:string, topicContentId:string) {
        return ServiceUrls.servicePrefix + "icetopiccontent/" + topicContentId + "/show.json?token=" + token;
    }
    public static getICETopicContentListByICETopicId(token:string, topicId:string) {
        return ServiceUrls.servicePrefix + "icetopiccontent/" + topicId + "/viewICETopicContentList.json?token=" + token;
    }
    public static deleteICETopicContent(token:string, topicContentId:string) {
        return ServiceUrls.servicePrefix + "icetopiccontent/" + topicContentId + "/destroy.json?token=" + token;
    }   
    public static reorderICETopicContent(token:string) {
        return ServiceUrls.servicePrefix + "icetopiccontent/topiccontent/reorderICETopicContent.json?token=" + token;
    }

    // Learning Check Urls
    public static createICELearningCheck(token:string) {
        return ServiceUrls.servicePrefix + "icelearningcheck/learningcheck/create.json?token=" + token;
    }
    public static getCategoryValues(token: string, parentId: string,dtaId: string) {
        return ServiceUrls.servicePrefix + "doctype/getCategory/getCategoryValues.json?token=" + token + "&parentId=" + parentId + "&dtaId=" + dtaId;
    }
    public static viewDependenciesForLearningCheck(token:string, learningCheckId:string) {
        return ServiceUrls.servicePrefix + "icelearningcheck/" + learningCheckId + "/viewDependenciesForLearningCheck.json?token=" + token;
    }
    public static getAllICELearningCheckByCourseId(token:string, courseId:string) {
        return ServiceUrls.servicePrefix + "icelearningcheck/" + courseId + "/viewICELearningCheckListByCourseId.json?token=" + token;
    }
    public static getAllICELearningCheckByTopicId(token:string, topicId:string) {
        return ServiceUrls.servicePrefix + "icelearningcheck/" + topicId + "/viewICELearningCheckListByTopicId.json?token=" + token;
    }
    public static getAllICELearningCheckByTopicContentId(token:string, topicContentId:string) {
        return ServiceUrls.servicePrefix + "icelearningcheck/" + topicContentId + "/getAllICELearningCheckByTopicContentId.json?token=" + token;
    }
    public static reorderICELearningChecks(token:string) {
        return ServiceUrls.servicePrefix + "icelearningcheck/learningcheck/reorderICELearningChecks.json?token=" + token;
    }
    public static deleteICELearningChecks(token:string, learningCheckId:string) {
        return ServiceUrls.servicePrefix + "icelearningcheck/" + learningCheckId + "/destroy.json?token=" + token;
    }
    public static saveLearningCheckQuestionList(token:string, learningCheckId:string) {
        return ServiceUrls.servicePrefix + "icelearningcheck/" + learningCheckId + "/saveLearningCheckData.json?token=" + token;
    }
    public static getLearningCheckDataByLCId(token:string, learningCheckId:string) {
        return ServiceUrls.servicePrefix + "icelearningcheck/" + learningCheckId + "/viewLCDataByLCId.json?token=" + token;
    }
    public static copyLearningCheckByLearningcheckId(token:string, thisCourseId:string) {
        return ServiceUrls.servicePrefix + "icelearningcheck/" + thisCourseId + "/copyLearningCheckByLearningcheckId.json?token=" + token;
    }

    // Game Urls
    public static copyGameByCMGameId(token:string, thisCourseId:string) {
        return ServiceUrls.servicePrefix + "icegame/" + thisCourseId + "/copyGameByGameId.json?token=" + token;
    }

    public static UPDATE_TAG_CLASSIFICATION_NAME = ServiceUrls.servicePrefix + "tags/classnameupdate/updateTagClassificationName.json";

    public static GET_TOTAL_TOPIC_COUNT = ServiceUrls.servicePrefix + "reports/topics/viewTotalTopics.json";

    public static GET_REPORT_TOPIC_LIST = ServiceUrls.servicePrefix + "reports/topics/viewReportTopicList.json";

    // public static GET_REPORT_USED_DOCUMENT_LIST = ServiceUrls.servicePrefix + "reports/used/viewDocumentList.json";

    // public static GET_REPORT_DOCUMENT_LIST = ServiceUrls.servicePrefix + "reports/total/viewDocumentList.json";
    public static GET_REPORT_USED_DOCUMENT_LIST = ServiceUrls.servicePrefix + "reports/used/viewDocumentListForReports.json";

    public static GET_REPORT_DOCUMENT_LIST = ServiceUrls.servicePrefix + "reports/total/viewDocumentListForReports.json";

    public static GET_REPORT_TOPIC_REVISION_COUNT = ServiceUrls.servicePrefix +  "reports/topics/viewTopicRevisionCount.json";

    public static GET_REPORT_TOPIC_REVISION_LIST = ServiceUrls.servicePrefix +  "reports/topics/viewTopicRevisionList.json";

    public static GET_REPORT_DOCUMENT_TYPE_REFERENCE = ServiceUrls.servicePrefix + "reports/document/viewTotalDocumentTypes.json";

    public static GET_TOTAL_COURSE_REFERENCE = ServiceUrls.servicePrefix + "reports/course/viewTotalCourse.json";

    public static GET_TOPIC_COUNT_REFERENCE = ServiceUrls.servicePrefix + "reports/course/viewTopicCount.json";

    public static GET_TOPIC_CONTENT_COUNT_REFERENCE = ServiceUrls.servicePrefix + "reports/course/viewTopicContentCount.json";

    public static GET_LEARNING_CHECK_COUNT_REFERENCE = ServiceUrls.servicePrefix + "reports/course/viewLearningCheckCount.json";

    public static GET_GAME_COUNT_REFERENCE = ServiceUrls.servicePrefix + "reports/course/viewGameCount.json";

    public static GET_TOTAL_3D_MODEL_REFERENCE = ServiceUrls.servicePrefix + "reports/course/viewTotal3dModel.json";

    public static GET_TOTAL_VIDEOS_REFERENCE = ServiceUrls.servicePrefix + "reports/course/viewTotalVideos.json";

    public static GET_REPORT_USED_DOCUMENT_TYPE_LIST = ServiceUrls.servicePrefix + "reports/used/viewDocumentTypeList.json";

    public static GET_REPORT_DOCUMENT_TYPE_LIST = ServiceUrls.servicePrefix + "reports/total/viewDocumentTypeList.json";

    public static DELETE_TAG_CLASSIFICATION = ServiceUrls.servicePrefix + "tags/delclass/deleteTagClassification.json";

    public static GET_REPORT_IMAGES_LIST = ServiceUrls.servicePrefix + "reports/image/viewImagesList.json"   

    public static GET_PROCEDURE_LIST = ServiceUrls.servicePrefix + "reports/procedure/viewProcedureList.json"

    public static getCategoryListByCategoryType(categoryType: string){
        return ServiceUrls.servicePrefix +"categories/" + categoryType + "/viewAllCategoriesByCategoryType.json"
    }

    public static ADD_NEW_CATEGORY = ServiceUrls.servicePrefix +"categories/add/addNewCategory.json"

    public static DELETE_CATEGORY = ServiceUrls.servicePrefix +"categories/delete/deleteCategory.json"

    public static GET_CATEGORY_DEPNDENCIES = ServiceUrls.servicePrefix +"categories/cat/getDependencies.json"

    public static DISABLE_CATEGORY = ServiceUrls.servicePrefix + "categories/status/changeCategoryStatus.json"

    public static UPDATE_CATEGORY = ServiceUrls.servicePrefix + "categories/update/updateCategory.json"

    public static getDowonloadContentURL(contentId: string){
        return  ServiceUrls.servicePrefix +"contents/"+contentId+"/downloadContentItemById.json"
    }

    public static VIEW_CONTENT_ITEM = ServiceUrls.servicePrefix + "contents/contentName/viewContentItem.json"


    public static changeTagStatus(token: string, tagId: string, disable: string) {
        return ServiceUrls.servicePrefix + "tags/"+ tagId +"/changeTagStatus.json?token=" + token + "&disable=" + disable;
    }

    public static deleteTagById(token: string, tagId: string) {
        return ServiceUrls.servicePrefix + "tags/"+ tagId +"/deleteTag.json?token=" + token;
    }

    public static getAllICEGlossaryListByCourseId(token:string, courseId:string) {
        return ServiceUrls.servicePrefix + "icecourse/" + courseId + "/getAllICEGlossariesByCourseId.json?token=" + token;
    }

    public static createICEGame(token:string) {
        return ServiceUrls.servicePrefix + "icegame/game/create.json?token=" + token;
    }

    public static getICEGameInfoByCourseId(token:string, courseId:string) {
        return ServiceUrls.servicePrefix + "icegame/" + courseId + "/getICEGameInfoByCourseId.json?token=" + token;
    }

    public static getICEGameInfoByTopicId(token:string, topicId:string) {
        return ServiceUrls.servicePrefix + "icegame/" + topicId + "/getICEGameInfoByTopicId.json?token=" + token;
    }

    public static saveICEGameList(token:string) {
        return ServiceUrls.servicePrefix + "icecourse/course/saveICEGameList.json?token=" + token;
    }

    public static saveICEGameData(token:string, gameId:string) {
        return ServiceUrls.servicePrefix + "icegame/" + gameId + "/update.json?token=" + token;
    }

    public static deleteICEGame(token:string, gameId:string) {
        return ServiceUrls.servicePrefix + "icegame/" + gameId + "/destroy.json?token=" + token;
    }

    public static getAllICEGameInfoById(token:string, gameId:string) {
        return ServiceUrls.servicePrefix + "icegame/" + gameId + "/show.json?token=" + token;
    }

    public static createICEGlossary(token:string) {
        return ServiceUrls.servicePrefix + "iceglossary/glossary/create.json?token=" + token;
    }

    public static saveICEGlossaryList(token:string) {
        return ServiceUrls.servicePrefix + "iceglossary/glossary/saveGlossaryList.json?token=" + token;
    }

    public static deleteICEGlossary(token:string, glossaryId:string) {
        return ServiceUrls.servicePrefix + "iceglossary/" + glossaryId + "/destroy.json?token=" + token;
    }

    //3D model urls
    public static ADD_NEW_THREED_MODEL = ServiceUrls.servicePrefix + "folderprop/add3dmodel/addNewThreeDModel.json";
    public static THREED_MODELS = ServiceUrls.servicePrefix + "threedmodel/";
    public static CHECK_IN_THREED_MODEL = ServiceUrls.servicePrefix + "threedmodel/3DmodelCheckin/checkInThreeDModel.json";
    public static CHECK_OUT_THREED_MODEL = ServiceUrls.servicePrefix + "threedmodel/3DmodelCheckout/checkOutThreeDModel.json";
    public static UNDO_CHECK_OUT_THREED_MODEL = ServiceUrls.servicePrefix + "threedmodel/3DmodelCheckout/undoCheckoutThreeDModel.json";
    public static VIEW_THREED_MODEL = ServiceUrls.servicePrefix + "threedmodel/3DmodelSearch/getThreeDModelOnClick.json"

    public static THREED_MODEL_SEARCH = ServiceUrls.servicePrefix + "threedmodel/threeDModelSearch/getThreeDModelListOnSearch.json";
    public static THREED_MODEL_SEARCH_THREED_MODEL = ServiceUrls.servicePrefix + "threedmodel/threeDModelSearch/getThreeDModelThumbnail.json";
    public static THREED_MODEL_SEARCH_THREED_MODEL_ON_CLICK = ServiceUrls.servicePrefix + "threedmodel/threeDModelSearch/getThreeDModelOnClick.json";
    public static GET_THREED_MODEL_VIEW = ServiceUrls.servicePrefix + "threedmodel/threeDModelSearch/threeDModelView.json";
	public static GET_THREED_MODEL_INFO = ServiceUrls.servicePrefix + "threedmodel/threeDModel/getThreeDModelInfoById.json";

    
    //Video urls
    public static VIDEOS = ServiceUrls.servicePrefix + "video/";
    public static VIDEO_SEARCH = ServiceUrls.servicePrefix + "video/videos/getVideoListOnSearch.json";
    public static VIDEO_SEARCH_VIDEO_URL = ServiceUrls.servicePrefix + "video/videoSearch/getVideoURL.json";
    public static VIDEO_SEARCH_VIDEO_THUMBNAIL = ServiceUrls.servicePrefix + "video/videoSearch/getVideoThumbnail.json";
    public static VIDEO_SEARCH_VIDEO_ON_CLICK = ServiceUrls.servicePrefix + "video/videoSearch/getVideoOnClick.json";
    public static GET_VIDEO_VIEW = ServiceUrls.servicePrefix + "video/videoSearch/getVideoView.json";
    public static CHECK_IN_VIDEO = ServiceUrls.servicePrefix + "video/videoCheckIn/checkInVideo.json";
    public static CHECK_OUT_VIDEO = ServiceUrls.servicePrefix + "video/VideoCheckout/checkOutVideo";
    public static UNDO_CHECK_OUT_VIDEO = ServiceUrls.servicePrefix + "video/VideoCheckout/undoCheckoutVideo.json";
    public static VIDEO_SEARCH_VIDEO_POSTERFRAME = ServiceUrls.servicePrefix + "video/videoSearch/getVideoPosterFrame.json";

    /**
     * Achini Randeni
     * @param token 
     * @param videoId 
     */
    public static getVideoInfoById(token: string, videoId: string) {
        return ServiceUrls.servicePrefix + "video/" + videoId + "/getVideoInfoById.json?token=" + token + "&videoId=" + videoId;
    }

    //Multiple images urls
    public static ADD_NEW_MULTIPLE_IMAGE = ServiceUrls.servicePrefix + "folderprop/addMultipleImage/addNewMultipleImages.json"

    public static addNewPunchlist(token: string): string {
        return ServiceUrls.servicePrefix + "punchlist/addPunchlist/addPunchlist.json?token=" + token;
    }

    public static getPunchlistByPunchlistId(token: String, punchlistId: String) {
        return ServiceUrls.servicePrefix + "punchlist/getPunchlist/getPunchlistByPunchlistId.json?token=" + token + "&id=" + punchlistId;
    }

    public static updatePunchlist(token: string): string {
        return ServiceUrls.servicePrefix + "punchlist/updatePunchlist/updatePunchlist.json?token=" + token;
    }

    public static ADD_NEW_PUNCHLIST_DOCUMENT = ServiceUrls.servicePrefix + "folderprop/addNewPunchlistDocument/addNewPunchlistDocument.json";

    public static getPunchCodes(projectId: String): string {
        return ServiceUrls.servicePrefix + "projects/" + projectId + "/getPunchcodeForProject.json";
    }

    public static addNewRole(token: string): string {
        return ServiceUrls.servicePrefix + "role/addrole/addRole.json?token=" + token;
    }
    public static GET_ROLE_LIST = ServiceUrls.servicePrefix + "role/getRoles/getRoles.json";

    public static updateRole(token: string): string {
        return ServiceUrls.servicePrefix + "role/updaterole/updateRole.json?token=" + token;
    }

    public static updateStatus(token: string): string {
        return ServiceUrls.servicePrefix + "role/updatestatus/updateStatus.json?token=" + token;
    }

    public static deleteRole(token: string): string {
        return ServiceUrls.servicePrefix + "role/deleterole/deleteRole.json?token=" + token;
    }

    public static getRoleAssignUsers(token: string): string {
        return ServiceUrls.servicePrefix + "role/getroleassignusers/getRoleAssignUsers.json?token=" + token;
    }

    public static getRoleCapabilities(token: string): string {
        return ServiceUrls.servicePrefix + "role/getallcapabilities/getAllCapabilities.json?token=" + token;
    }

    public static getRoleCapCategories(token: string): string {
        return ServiceUrls.servicePrefix + "role/getcapabilitycategory/getCapabilityCategoryByScope.json?token=" + token;
    }
    public static SAVE_DEFAULT_CAPABILITY = ServiceUrls.servicePrefix + "/role/savedefaultrolecapabilities/saveDefaultRoleCapabilities.json";

    public static SAVE_CUSTOM_CAPABILITY = ServiceUrls.servicePrefix + "/role/savecustomrolecapabilitiesforrole/saveCustomRoleCapabilitiesForRole.json";
    
    public static getSelectedProcedureInfo(token:string, procedureId:string) {
        return ServiceUrls.servicePrefix + "procedures/" + procedureId + "/getSelectedProcedureInfo.json?token=" + token;
    }

    public static getSelectedStoreDocumentInfo(token:string, documentId:string) {
        return ServiceUrls.servicePrefix + "documentStore/" + documentId + "/getSelectedStoreDocumentInfo.json?token=" + token;
    }

    public static getV2ComProject(projectId: string){
        return ServiceUrls.v2Prefix + "Symbiosis/pages/ComProjectPage?projectId=" + projectId;
    }
    
    public static GET_CLIENTS_LIST = ServiceUrls.servicePrefix + "role/getallclients/getAllClients.json";
    public static USER_ASSIGNMENT = ServiceUrls.servicePrefix + "/role/getuserroleassignmentsbyuserid/getUserRoleAssignmentsByUserId.json";

    public static ROLES_BY_USER_ID = ServiceUrls.servicePrefix + "role/";   
    public static PROJECTS_LIST = ServiceUrls.servicePrefix + "role/";
    public static CLIENT_LIST = ServiceUrls.servicePrefix + "role/"
    public static SAVE_USER_ROLE_ASSIGNMENT = ServiceUrls.servicePrefix + "/role/saveuserroles/saveUserRoles.json";

    //IRN urls
    public static IRNS = ServiceUrls.servicePrefix + "irns/";
    public static ADD_IRN = ServiceUrls.servicePrefix + "irns.json";
    public static ADD_NEW_IRN_DOCUMENT = ServiceUrls.servicePrefix + "folderprop/addNewIRNDocument/addNewIRNDocument.json";
    public static GET_ALL_IRN = ServiceUrls.servicePrefix + "irns";
    public static IRN_SEARCH_VIEW = ServiceUrls.servicePrefix +  "irns/IRNSearch/getIRNListBySearch.json";
    public static getIrnByIrnId(token: String, irnId: String) {
        return ServiceUrls.servicePrefix + "irns/getIRN/getIRNByIRNId.json?token=" + token + "&id=" + irnId;
    }
    public static getIrnByAssetId(token: String, assetId: String) {
        return ServiceUrls.servicePrefix + "irns/getIRN/getIRNListByAssetId.json?token=" + token + "&assetId=" + assetId;
    }
    public static getMCCListByIRNId(token: String, irnId: String) {
        return ServiceUrls.servicePrefix + "irns/getMCCListByIRNId/getMCCListByIRNId.json?token=" + token + "&id=" + irnId;
    }

    public static getOTNListByIRNId(token: String, irnId: String) {
        return ServiceUrls.servicePrefix + "irns/getOTNListByIRNId/getOTNListByIRNId.json?token=" + token + "&id=" + irnId;
    }

    public static GET_IRN_TARGETCOMPLETION_DATES = ServiceUrls.servicePrefix + "irns/getAllTargetCompletionDatesForIRNFilter/getAllTargetCompletionDatesForIRNFilter.json";
    
    //MCC urls
    public static GET_ALL_MC = ServiceUrls.servicePrefix + "mechanicalcompletion.json";
    public static ADD_MECHANICAL_COMPLETION = ServiceUrls.servicePrefix + "mechanicalcompletion.json";
    public static getDeleteMCC(mccId: string) {
        return ServiceUrls.servicePrefix + "mechanicalcompletion/" + mccId + "/deleteMechanicalCompletion.json";
    }
    public static getMCByMcId(token: string, mcId: string) {
        return ServiceUrls.servicePrefix + "mechanicalcompletion/" + mcId + ".json?token=" + token;
    }
    public static ADD_NEW_MC_DOCUMENT = ServiceUrls.servicePrefix + "folderprop/addMCDocument/addNewMCDocument.json";
    public static revokeMechanicalCompletion(id: string){
        return ServiceUrls.servicePrefix + "mechanicalcompletion/"+id+"/revokeMechanicalCompletion.json";
    }
    public static GET_MC_SEARCH_RESULTS = ServiceUrls.servicePrefix + "mechanicalcompletion/mccSearch/mccSearch.json";
    public static UPDATE_MC_DATA = ServiceUrls.servicePrefix + "mechanicalcompletion/updatemechanicalcompletion/updateMCData.json";
    public static APPROVE_MC = ServiceUrls.servicePrefix + "mechanicalcompletion/mcApprove/saveMechanicalCompletionApprovalData.json";
    public static GET_MCC_TARGETCOMPLETION_DATES = ServiceUrls.servicePrefix + "mechanicalcompletion/getAllTargetCompletionDatesForMCCFilter/getAllTargetCompletionDatesForMCCFilter.json";
    public static GET_ALL_SYSTEM_LIST_FOR_CSHFILTER = ServiceUrls.servicePrefix + "systems/getAllActiveSystemsWithParentForCSHFilter/getAllActiveSystemsWithParentForCSHFilter.json";
    
    public static checkIncompleteItemsForSystemList = ServiceUrls.servicePrefix + "systems/checkIncompleteItemsForSystemList/checkIncompleteItemsForSystemList.json";
    // public static checkIncompleteItemsForSystemList() {
    //     return ServiceUrls.servicePrefix + "systems/checkIncompleteItemsForSystemList/checkIncompleteItemsForSystemList.json";
    // }

    
    public static GET_OTN_SEARCH_RESULTS = ServiceUrls.servicePrefix + "onshoreturnovernotice/otnSearch/otnSearch.json";
    public static GET_OTN_TARGETCOMPLETION_DATES = ServiceUrls.servicePrefix + "onshoreturnovernotice/getAllTargetCompletionDatesForOTNFilter/getAllTargetCompletionDatesForOTNFilter.json";
    
    public static getOTNByAssetId(token: String, assetId: String) {
        return ServiceUrls.servicePrefix + "onshoreturnovernotice/getOTN/getOTNListByAssetId.json?token=" + token + "&assetId=" + assetId;
    }
    public static addNewOTN(token: string): string {
        return ServiceUrls.servicePrefix + "onshoreturnovernotice.json?token=" + token;
    }
    public static updateOTN(token: string, otnId: String): string {
        return ServiceUrls.servicePrefix + "onshoreturnovernotice/" + otnId + ".json?token=" + token;
    }
    public static isAssetsHasIRN(token: string) {
        return ServiceUrls.servicePrefix + "tags/checkAssetHasIRN/isAssetHasIRN.json?token=" + token;
    }
    public static isAllPunchlistItemsClosedByCategoryAndAsset(token: string) {
        return ServiceUrls.servicePrefix + "tags/checkAssetsPunchlists/isAllPunchlistItemsClosedByCategoryAndAsset.json?token=" + token;
    }
    public static getMCCListByOTNId(token: String, otnId: String) {
        return ServiceUrls.servicePrefix + "onshoreturnovernotice/" + otnId + "/getMCCListByOTNId.json?token=" + token;
    }
    public static getOTNByOTNId(token: String, otnId: String) {
        return ServiceUrls.servicePrefix + "onshoreturnovernotice/" + otnId + "/getOTNByOTNId.json?token=" + token;
    }
    public static deleteOrRevokeOTN(token: String, otnId: String) {
        return ServiceUrls.servicePrefix + "onshoreturnovernotice/" + otnId + "/deleteOrRevokeOTN.json?token=" + token;
    }
    public static approveOTN(token: String) {
        return ServiceUrls.servicePrefix + "onshoreturnovernotice/otnApprove/saveOTNApprovalData.json?token=" + token;
    }
    public static ADD_NEW_OTN_DOCUMENT = ServiceUrls.servicePrefix + "folderprop/addOTNDocument/addNewOTNDocument.json";

    public static checkAssetVendorCombination(token: String) {
        return ServiceUrls.servicePrefix + "onshoreturnovernotice/validate/checkAssetVendorCombination.json?token=" + token;
    }
	public static getPunchlistsInSameGroup(token: String, punchlistId: String) {
        return ServiceUrls.servicePrefix + "punchlist/" + punchlistId + "/getPunchlistsInSameGroup.json?token=" + token;
    }

    public static WORKFLOWS = ServiceUrls.servicePrefix + "workflow/";

    public static GET_ALL_WORKFLOW_TEMPLATES = ServiceUrls.servicePrefix + "workflow/getAllWorkflowTemplates/getAllWorkflowTemplates.json";

    public static addWorkflowTemplate(token: string): string {
        return ServiceUrls.servicePrefix + "workflow/manageworkflowtemplate/addWorkflowTemplate.json?token=" + token;
    }
    
    public static getWorkflowTemplateById(token: string, wfTemplateId: string) {
        return ServiceUrls.servicePrefix + "workflow/" + wfTemplateId + "/getWorkflowTemplateById.json?token=" + token;
    }

    public static updateWorkflowTemplate(token: string): string {
        return ServiceUrls.servicePrefix + "workflow/manageworkflowtemplate/updateWorkflowTemplate.json?token=" + token;
    }

    public static deleteWorkflowTemplate(token: string): string {
        return ServiceUrls.servicePrefix + "workflow/manageworkflowtemplate/deleteWorkflowTemplate.json?token=" + token;
    }

    public static getItemTypes(token: string) {
        return ServiceUrls.servicePrefix + "workflow/getItemTypes/getItemTypes.json?token=" + token;
    }

    public static GET_ACTIVE_WORKFLOW_TEMPLATES_BY_ITEM_TYPE = ServiceUrls.servicePrefix + "workflow/getActiveWorkFlowsPerProjectByItemType/getActiveWorkFlowsPerProjectByItemType.json";

    public static GET_WORKFLOW_TEMPLATES_WITHOUT_MAPPING_BY_PROJECT = ServiceUrls.servicePrefix + "workflow/getWorkflowTemplateListsWithoutMappingByProjectId/getWorkflowTemplateListsWithoutMappingByProjectId.json";

    public static GET_CONFIG_TASKS_BY_ITEM_TYPE = ServiceUrls.servicePrefix + "workflow/getConfigTasksByItemType/getConfigTasksByItemType.json";

    public static getAllActiveUsers(projectId: string): string {
		return ServiceUrls.servicePrefix + "projects/" + projectId + "/getAllActiveUsersForProjectToConfigureWorkflow.json";
    }
    
    public static GET_TASKS_BY_TEMPLATE_ID = ServiceUrls.servicePrefix + "workflow/getTasksByTemplateId/getTasksByTemplateId.json";

    public static getTasksByTemplateId(token: string, templateId: string){
        return ServiceUrls.servicePrefix + "workflow/" + templateId +"/getTasksByTemplateId.json?token=" + token;
    }

    public static assignWorkflowTemplate(token: string): string{
        return ServiceUrls.servicePrefix + "workflow/configureworkflowtemplate/assignWorkflowTemplateToProject.json?token=" + token;
    }

    public static updateWorkflowTemplateTasks(token: string): string{
        return ServiceUrls.servicePrefix + "workflow/configureworkflowtemplate/updateWorkflowTemplateTasks.json?token=" + token;
    }

    public static getDocusignWorkFlowEntriesByProjectWorkflowTemplateId(token: string, projWFTemplateId: string){
        return ServiceUrls.servicePrefix + "docusign/" + projWFTemplateId +"/getDocusignWorkFlowEntriesByProjectWorkflowTemplateId.json?token=" + token;
    }

    public static getStnById(stnId: string) {
		return ServiceUrls.servicePrefix + "stns/" + stnId + ".json";
    }
    
    public static getPCCById(pccId: string) {
		return ServiceUrls.servicePrefix + "precommissioning/" + pccId + ".json";
	}

    public static getCommCompletionById(ccId: string) {
		return ServiceUrls.servicePrefix + "commcompletion/" + ccId + ".json"
    }
    
	public static getAllReportDesignsByModuleAndProjectId(token: string): string {
		return ServiceUrls.servicePrefix + "adhocreports/getReports/getAllReportDesignsByModuleAndProjectId.json?token=" + token;
	}

	public static addNewReportDesign(token: string): string {
		return ServiceUrls.servicePrefix + "adhocreports/addNewReport/addNewReportDesign.json?token=" + token;
	}
	public static checkReportNameAvailability(token: string): string {
		return ServiceUrls.servicePrefix + "adhocreports/checkavailability/checkReportNameAvailability.json?token=" + token;
	}
    public static deleteAdhocReport(itemId: String): string {
      return ServiceUrls.servicePrefix + "adhocreports/" + itemId + ".json";
    }

    public static addCheck(token: string): string{
        return ServiceUrls.servicePrefix + "checks/addCheck/addCheck.json?token=" + token;
    }

    public static getCheckById(token: string, checkId: string): string{
        return ServiceUrls.servicePrefix + "checks/" + checkId + "/getCheckByCheckId.json?token=" + token;
    }

    public static updateCheck(token: string): string{
        return ServiceUrls.servicePrefix + "checks/updateCheck/updateCheck.json?token=" + token;
    }

    public static getAllChecksheets(token: string) {
        return ServiceUrls.servicePrefix + "checksheets/getAllActiveCheckSheetByProjectId/getAllActiveCheckSheetByProjectId.json?token=" + token;
    }

    public static getCheckSheetFilterResult(token: string){
        return ServiceUrls.servicePrefix + "checksheets/checksheetFilter/getCheckSheetFilterResult.json?token=" + token;
    }

    public static getReceiveCheckSheetFilterResult(token: string){
        return ServiceUrls.servicePrefix + "checksheets/checksheetFilter/getReceiveCheckSheetFilterResult.json?token=" + token;
    }

    public static getChecksheetInstanceData(token: string){
        return ServiceUrls.servicePrefix + "checksheets/checksheets/getChecksheetInstanceData.json?token=" + token;
    }

    public static saveChecksheetInstanceData(token: string){
        return ServiceUrls.servicePrefix + "checksheets/checksheetInstanceDataSave/saveChecksheetInstanceData.json?token=" + token;
    }

    public static removeCheckSheetInstance(token: string){
        return ServiceUrls.servicePrefix + "checksheets/checksheets/removeCheckSheetInstance.json?token=" + token;
    }

    public static removeChecksheetPunchlistRelation(token: string){
        return ServiceUrls.servicePrefix + "punchlist/removeChecksheetFromPunchlist/removeChecksheetFromPunchlist.json?token=" + token;
    }

    public static ADD_NEW_CS_DOCUMENT = ServiceUrls.servicePrefix + "folderprop/addCSDocument/addNewChecksheetDocument.json";

    //Manage Checks URLs
    public static CHECKS = ServiceUrls.servicePrefix + "checks/";
    public static CHECKS_SEARCH_VIEW = ServiceUrls.servicePrefix + "checks/checksSearch/getChecksSearchResults.json";

    public static getCSInstanceCountByCheckId(token: String, checkId: String) {
        return ServiceUrls.servicePrefix + "checks/" + checkId + "/getCheckSheetInstanceCount.json?token=" + token;
    }

    public static getAllTargetCompletionDatesForPCC(token: string){
        return ServiceUrls.servicePrefix + "precommissioning/getAllTargetCompletionDatesForPCCFilter/getAllTargetCompletionDatesForPCCFilter.json?token=" + token;
    }

    public static getAllUsedInCommissioningSystems(token: string){
        return ServiceUrls.servicePrefix + "systems/getAllActiveUsedInCommissioningSystemsForFilter/getAllActiveUsedInCommissioningSystemsForFilter.json?token=" + token;
    }

    public static getSearchPCCURL(token: string){
        return ServiceUrls.servicePrefix + "precommissioning/PCCSearch/getPrecommissioningCompletionSearchResults.json?token=" + token;
    }

    public static getPCById(token: string, pcId: string) {
        return ServiceUrls.servicePrefix + "precommissioning/" + pcId + ".json?token=" + token;
    }

    public static ADD_PRECOMMISSIONING_COMPLETION = ServiceUrls.servicePrefix + "precommissioning.json";

    public static UPDATE_PRECOMMISSIONING_COMPLETION = ServiceUrls.servicePrefix + "precommissioning/updateprecommissioningcompletion/updatePCData.json";

    public static getPCDeleteURL(pcId: string) {
        return ServiceUrls.servicePrefix + "precommissioning/" + pcId + "/deletePrecommissioningCompletion.json";
    }

    public static getPCCRevokeUrl(pcId: string){
        return ServiceUrls.servicePrefix + "precommissioning/" + pcId + "/revokePrecommissioningCompletion.json";
    }

    public static APPROVE_PCC = ServiceUrls.servicePrefix + "precommissioning/pccApprove/savePrecommissioningApprovalData.json";
    public static ADD_NEW_PCC_DOCUMENT = ServiceUrls.servicePrefix + "folderprop/addPCCDocument/addNewPCCDocument.json";

    public static getPCCApprovalData(token: string, pcId: string) {
        return ServiceUrls.servicePrefix + "/precommissioning/precommissioning/getPCCApprovalData.json?token=" + token + "&id=" + pcId;
    }
}
