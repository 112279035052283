import { Component, ViewChild, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';

@Component({
    selector: 'pcc-docusign',
    templateUrl: './pcc-docusign-cell-renderer.component.html'
})
export class PCCDocusignCellRendererComponent implements ICellRendererAngularComp{
    @ViewChild('docusignTemplate') docusignTemplate: TemplateRef<any>
    type: string;
    params: any;
    docuSignId: any;
    system: any;
    status: any;
    docuSignStatus: any = "";
    modalRef: BsModalRef;
    isPresent: boolean = false;
    columnDefs: any[];
    public rowData = [];
    gridParams: any;
    workflow: boolean = false;


    public modalConfig: any = {
        backdrop: true,
        class: 'modal-lg',
    }

    constructor(private modalService: BsModalService) { }

    agInit(params: any): void {
        this.gridParams = params;

        if (params.value == true) {
            this.workflow = true
        } else {
            //show docusign status
            if (params.data.docusignStatus != null) {
                this.docuSignStatus = this.statusRender(params.data.docusignStatus.toLowerCase())
                this.isPresent = true;
                this.docuSignId = params.data.docuSignId;
                this.system = params.data.system;
            } else {
                this.workflow = true
            }
        }
    }

    refresh(): boolean {
        return false;
    }

    /**
   * Get the workflow label by docusign status for the Initial punchlist
   * @param status 
   */
    public statusRender(status: any) {
        switch (status) {
            case 'waiting':
                return '<span class="badge badge-warning px-2 py-1">Waiting</span>'
            case 'completed':
                return '<span class="badge badge-success px-2 py-1">Completed</span>'
            case 'sent':
                return '<span class="badge badge-warning px-2 py-1">Sent</span>'
            case 'declined':
                return '<span class="badge badge-danger px-2 py-1">Declined</span>'
            case 'delegated':
                return '<span class="badge badge-danger px-2 py-1">Delegated</span>'
            case 'error':
                return '<span class="badge badge-danger px-2 py-1">Error</span>'
            case 'failed':
                return '<span class="badge badge-danger px-2 py-1">Failed</span>'
            default:
                return ''
        }
    }

    viewPersonStatus() {
        this.type = 'PRECOMMISSIONING_COMPLETION'
        event.stopPropagation();
        this.modalRef = this.modalService.show(this.docusignTemplate, this.modalConfig);
    }

    public modelRefCloseEvent() {
        this.modalRef.hide()
    }

    ngOnInit(): void {
    }

}