import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Constants } from '../../../util/constants';
import { Router } from '@angular/router';
import { DocumentTypesService } from './document-types.service';
import { AlertService } from '../../../util/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { DTDeleteButtonRenderer } from './cell-renderer/delete-button/delete-button.component';
import { DTDependencyButtonRenderer } from './cell-renderer/dependency-button/dependency-button.component';
import { Document } from './document.model';
import { WsCallback } from 'src/app/util/ws-callback.interface';
import { WsResponse } from 'src/app/util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';
import { UserVariable } from 'src/app/util/common/user-variable';
import { GridOptions, Module, AllModules } from "@ag-grid-enterprise/all-modules";
import { AttributeTypeRenderComponent } from 'src/app/reusables/tags/tag-classification/attribute-type-render/attribute-type-render.component';
import { DotNotationRenderComponent } from 'src/app/reusables/tags/tag-classification/dot-notation-render/dot-notation-render.component';
import { LoadingService } from '../../../util/loading/loading.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { CapabilityService } from '../../../util/capability.service';
import { DTStatusButtonRenderer } from './cell-renderer/status-button/status-button-components';
import { SharedService } from "../../../util/shared.service";
import { DependenciesmodalComponent } from '../../../util/dependenciesmodal/dependenciesmodal.component';
import { CommonUtil } from 'src/app/util/common/common-util';
import { DependenciesModalService } from 'src/app/util/dependenciesmodal/dependenciesmodal.service';
declare var $: any;

@Component({
	selector: 'app-document-types',
	templateUrl: './document-types.component.html',
	styleUrls: ['./document-types.component.css']
})


export class DocumentTypesComponent implements OnInit, WsCallback {
	public data: any = {
		'data': [
			{ "id": "0", "parent": "#", "text": "Document Types", icon: "fa fa-folder-o", type: "folder" },
		]
	}

	public data_n: any = {
		'data': [
		]
	}

	public selectedFolderId: string = null
	public treeRef: any = null;
	public selectedTreeItem: any = null
	public folderListPayload: any = null;
	public divHeight: number = window.innerHeight;
	public tabSplit: number = Constants.UI_LAYOUT.UIL_1.tW;
	public editorLayoutSplit: number = Constants.UI_LAYOUT.UIL_1.ruW
	selectedElementName: any;
	public columnDefs;
	public rowSelection;
	public rowData: any = [];
	public rowData2: any = [];
	public gridApi;
	public gridColumnApi;
	public getRowNodeId;
	doctype_translated_data: any = null;
	public selectedAttributeType: Array<any> = [{ 'id': 'Select', 'text': 'Select' }]
	public dotNotation: Array<any> = [{ 'id': 'Select Category', 'text': 'Select Category' }]
	///public typeList: any = [];
	document: Document;
	public docAttributeId: string;
	public modules: Module[] = AllModules;

	private documentTypeList: any = [];
	private token: string;

	public attributeList: any = [];
	public dotNotations: any = [];
	public categoryList: any = [];
	public gridOptions: any;
	public defaultColDef: any;
	public frameworkComponents;
	public commonCategoryList: any[] = [];
	public selectedDocType: boolean = false;

	public attributeSelectList: Array<any> = [];
	public defaultValueList: Array<any> = [];
	public selectedAttribute: Array<any> = [{ 'id': 'addAtribute', 'text': 'Select Attribute' }]
	public selectedDefValue: string;
	public sequence: string;

	public modifyTopicType: boolean = false

	public title: string;
	public dependencyType: string;
	public status: string = "";
	public createNode: boolean = false

	public isDocTypeActive: boolean = false;
	public isDocTypeActiveBool: boolean = false;
	public selectedData: any = null
	public rootSelected: boolean = true
	public categoryType: string;
	public tempCategoryItem: any = null
	docTypeAttrId: string;


	@ViewChild('docAttributeModal') docAttributeModal: TemplateRef<any>;
	@ViewChild('allCategoryModel') docAttributeDotNotationModal: TemplateRef<any>;
	bsModalRef: BsModalRef;

	constructor(private documentTypesService: DocumentTypesService,
		private alertService: AlertService, private translate: TranslateService, private loadingService: LoadingService,
		private modalService: BsModalService, private sharedService: SharedService, private dependenciesmodalService: DependenciesModalService, 
		private capabilityService: CapabilityService, private commonUtil: CommonUtil) {
		this.token = UserVariable.getUserToken();
		if (!this.commonUtil.validateLogin(this.token)) {
			return;
		}
		this.modifyTopicType = this.capabilityService.isCapabilityAssigned("modifyTopicType");
		this.documentTypesService.getTopicTypes().subscribe(
			data => {
				this.loadData(data.payload);
			},
			error => {
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			}
		)
		let browserLang = translate.getBrowserLang();
		translate.getTranslation(browserLang).subscribe((res: string) => {
			this.doctype_translated_data = res['DOCUMENT_TYPE']
			/*this.columnDefs = [
				{ headerName: "", suppressFiltersToolPanel: true, field: 'attribute', cellClass: "cell-wrap-text", minWidth: 200, autoHeight: true, cellRendererFramework: DTDeleteButtonRenderer },

				{ headerName: this.doctype_translated_data.REQUIRED, field: 'attribute', suppressFiltersToolPanel: true, cellClass: "cell-wrap-text", minWidth: 200, autoHeight: true },
				{ headerName: this.doctype_translated_data.ATTRIBUTE, field: 'attribute', cellClass: "cell-wrap-text", minWidth: 300, autoHeight: true, },
				{
					headerName: this.doctype_translated_data.ATTRIBUTE_TYPE, field: 'attributeType', cellClass: "cell-wrap-text", minWidth: 100, autoHeight: true, editable: true,
					cellEditor: "agRichSelectCellEditor",
					cellEditorParams: {
						values: this.typeList,
					},
					singleClickEdit: true
				},
				{
					headerName: this.doctype_translated_data.DOT_NOTATION, field: 'dotNotation', cellClass: "cell-wrap-text", minWidth: 100, autoHeight: true, editable: true,
					cellEditor: "agRichSelectCellEditor",
					cellEditorParams: {
						values: ["Project Information", "Units of Measure"],
					},
					singleClickEdit: true
				}
			];*/
		});
		//grid options
		this.gridOptions = <GridOptions>{
			context: {
				parent: this
			}
		}
		this.defaultColDef = {
			resizable: true,
			sortable: true,
		}
		this.documentTypesService.setParent(this);
	}

	ngOnInit() {
		console.log('init');
		//this.documentTypesService.getTagClassAttributeList(this.token, this);

		//Components data for the ag-grid
		this.frameworkComponents = {
			countryCellRenderer: AttributeTypeRenderComponent,
			dotCellRenderer: DotNotationRenderComponent
		};

		if (this.documentTypesService.getRowData() != null) {
			this.rowData = this.documentTypesService.getRowData();
		} else {
			// do nothing
			// console.log('[ngOnInit] (else)');
		}
		this.sharedService.setLoadedComponent("documenttype")
	}

	private prepareDataForDocumentAttributeTable() {
		var rowData: any[] = [];
		this.sequence = this.attributeList.length
		this.attributeList.forEach(attribute => {
			let categoryPath = this.getCategoryPath(attribute.categoryListAdded, attribute.attributeModel.attributeType)
			let attriType = attribute.attributeModel.attributeType;
			if (attriType == null) {
				attriType = "";
			}
			rowData.push({
				attributeId: attribute.attributeModel.attributeId,
				required: attribute.attributeModel.required,
				attributeName: attribute.attributeModel.attributeName,
				selectedAttributeType:attriType,
				docTypeAttrId: attribute.attributeModel.docTypeAttrId,
				editable: attribute.attributeModel.dependencyCount > 0 ? false : true,
				categoryId: attribute.attributeModel.categoryId,
				categoryList: attribute.categoryList,
				dotNotationAdded: categoryPath,
				// attribute.categoryListAdded.length > 0 ? attribute.categoryListAdded[0].categoryName : '',
				dotNotationToBeAdded: attribute.categoryListToBeAdded,
				attributeTypeList: attribute.typeList,
				commonCategoryList: this.commonCategoryList,
				newItem: false,
				dependencies:"disable: true"
			});
		});
		this.rowData = rowData;
		this.gridApi.sizeColumnsToFit()
	}

	private getCategoryPath(attributeList: any, attributeType: string): string{
		if(attributeList.length == 0 && attributeType == 'Component'){
			return '--Select Category--';
		} else if (attributeList.length == 0) {
			return '';
		} else if (attributeList.length > 0) {
			let path = attributeList[attributeList.length-1].categoryPath.replace("All > ", "");
			path = path.split(">").join("/");
			return path;
		}
		return ''
	}

	private prepareDocumentAttributeTable() {
		this.columnDefs = [
			{
				headerName: 'Dependencies',
				// type: 'Delete',
				width: 100,
				autoHeight: true,
				// cellStyle: {"white-space": "normal"},
				cellClass: "cell-wrap-text", 
				editable: true,
				field: "dependencies",
				cellStyle: { 'text-align': 'center' },
				cellRendererFramework: DTDependencyButtonRenderer,
			},
			{
				headerName: 'Delete',
				// type: 'Delete',
				width: 100,
				//maxWidth: 100,
				autoHeight: true,
				// cellStyle: {"white-space": "normal"},
				cellClass: "cell-wrap-text", 
				editable: true,
				field: "docTypeAttrIdbuteId",
				//cellStyle: { 'text-align': 'center' },
				cellRendererFramework: DTDeleteButtonRenderer,
				// rowDrag: true,
				cellStyle: function (params) {
					if (params.value == 'Police') {
						//mark police cells as red
						return { color: 'red', backgroundColor: 'green','text-align': 'center', "white-space": "normal" };
					} else {
						return { 'text-align': 'center', "white-space": "normal" };
					}
				}
			},
			{
				headerName: 'Required',
				// type: 'Required',
				width: 100,
				//maxWidth: 100,
				autoHeight: true,
				cellClass: "cell-wrap-text", 
				editable: true,
				field: "required",
				cellStyle: { 'text-align': 'center', "white-space": "normal" },
				cellRendererFramework: DTStatusButtonRenderer
			},
			{
				headerName: 'Attribute',
				// maxWidth: 200,
				autoHeight: true,
				cellStyle: { 'text-align': 'left', "white-space": "normal"},
				cellClass: "cell-wrap-text", 
				field: "attributeName",
				singleClickEdit: true,
				editable: function (params) {
					if (params.data.newItem) {
						return true;
					} else {
						return false;
					}
				},
				cellEditor: 'agRichSelectCellEditor',
				cellEditorParams: function (params) {
					var attributeValues: any[] = [];
					params.data.classAttributeList.forEach(tag => {
						attributeValues[attributeValues.length] = tag.tagAttributeName;
					});
					return { values: attributeValues }
				},
				// cellStyle: { 'text-align': 'center' }
			},
			{
				headerName: 'Attribute Type',
				// maxWidth: 200,
				autoHeight: true,
				cellStyle: { 'text-align': 'left', "white-space": "normal"},
				cellClass: "cell-wrap-text", 
				field: 'selectedAttributeType',
				singleClickEdit: true,
				editable: function (params) {
					return params.data.editable;
				},
				cellEditor: 'agRichSelectCellEditor',
				cellEditorParams: function (params) {
					return { values: params.data.attributeTypeList }
				}
			},
			{
				headerName: 'Dot Notation',
				field: 'dotNotationAdded',
				// cellClass: 'vAlign',
				// maxWidth: 200,
				autoHeight: true,
				cellStyle: { 'text-align': 'left', "white-space": "normal"},
				cellClass: "cell-wrap-text", 
				// cellEditor: 'agRichSelectCellEditorselect',
				// editable: function (params) {
				// 	if (params.data.selectedAttributeType === 'Component') {
				// 		return true;
				// 	}
				// 	return false;
				// },
				// cellEditorParams: function (params) {
				// 	if (params.data.selectedAttributeType === 'Component') {
				// 		var items: any[] = [];
				// 		params.data.dotNotationToBeAdded.forEach(item => {
				// 			items.push(item.categoryName);
				// 		});
				// 	}

					// if (params.data.dotNotationAdded.length == 0) {
					//    params.data.commonCategoryList.forEach(item => {
					// 		items.push(item.categoryName);
					// 	});						
					// } else {

					//}
					// return { values: items }
				// },
				// singleClickEdit: true
			}
		];

		this.getRowNodeId = function (data) {
			return data.attributeId;
		};
	}

	public prepareCategoryList() {
		// this.dotNotations = [];
		// this.categoryList.forEach(category => {
		// 	this.dotNotations[this.dotNotations.length] = category.categoryName;
		// });
		this.prepareDocumentAttributeTable();
	}

	onSuccess(data: WsResponse, serviceType: WsType) {
		if (serviceType == WsType.GET_DOCUMENT_TYPES) {
			this.documentTypeList = data.payload;
			console.log(this.documentTypeList.length);
			//var indexedArray: {[key: string]: string} = data.payload;
			//console.log(indexedArray);
		} else if (serviceType == WsType.GET_CATEGORIES_BY_CLASSIFICATION) {

		} else if (serviceType == WsType.GET_TAG_CLASSIFICATION_ATTRIBUTE_LIST) {

		} else if (serviceType == WsType.GET_DOCUMENT_TYPE_ATTRIBUTE_LIST) {
			this.loadingService.hideLoading()
			this.selectedDocType = true;
			var out = data.payload;
			// console.log(out);
			if (out.length > 0) {
				this.attributeList = out;
				//how to get other values
				//this.attributeList.categoryList
				//this.attributeList.typeList
			} else {
				this.attributeList = [];
			}
			// this.prepareCategoryList();
			this.prepareDocumentAttributeTable();
			this.prepareDataForDocumentAttributeTable();
		} else if (serviceType == WsType.GET_CATEGORY_VALUES) {
			this.commonCategoryList = data.payload;
		} else if (serviceType == WsType.UPDATE_DOCUMENT_TYPE) {
			this.reloadTree();
			// this.loadingService.hideLoading()
			this.alertService.success("Successfully Renamed the Document type");
		} else if (serviceType == WsType.DELETE_ITEM) {
			this.reloadTree();
			// this.loadingService.hideLoading()
			this.alertService.success("Successfully Deleted the Document type");
		} else if (serviceType == WsType.ADD_DOCUMENT_TYPE) {
			this.reloadTree();
			// this.loadingService.hideLoading()
			this.alertService.success("Successfully Added a Document type");
		} else if (serviceType == WsType.GET_DOC_TYPE_AVAILABILITY) {
			this.loadingService.hideLoading()
		} else if (serviceType == WsType.DISABLE_DOCUMENT_TYPE) {
			this.reloadTree();
			// this.loadingService.hideLoading()
		} else if(serviceType == WsType.REMOVE_DOCUMENT_TYPE_ATTRIBUTE){
			this.alertService.success("Successfully Deleted the Document type Attribute");
			this.refreshDocTypeAttributeList();
		} else if(serviceType == WsType.TOGGLE_DOCUMENT_TYPE_ATTRIBUTE){
			this.alertService.success("Successfully Changed the Document type Status");
			this.refreshDocTypeAttributeList();
		}else if (data.payload != null && serviceType == WsType.ITEM_DEPENDENCY_LIST) {
			let items = data.payload.split("_")
			  if(items[1]=="Yes")
			  {
				   for(let i=0; i<this.rowData.length; i++)
				   {
						this.rowData[i]["dependencies"] = "disable: false"
				   }
				   this.gridApi.setRowData(this.rowData);
			  }
		 }
	}

	onFail(data: WsResponse, serviceType: WsType) {
		if (serviceType == WsType.GET_DOCUMENT_TYPES) {
			this.alertService.error(data.statusDescription);
		} else if (serviceType == WsType.GET_CATEGORIES_BY_CLASSIFICATION) {
			this.alertService.error(data.statusDescription);
		} else if (serviceType == WsType.GET_TAG_CLASSIFICATION_ATTRIBUTE_LIST) {
			this.alertService.error(data.statusDescription);
		} else if (serviceType == WsType.GET_DOCUMENT_TYPE_ATTRIBUTE_LIST) {
			this.loadingService.hideLoading()
			this.alertService.error(data.statusDescription);
		} else if (serviceType == WsType.GET_CATEGORY_VALUES) {
			this.alertService.error(data.statusDescription);
		} else if (serviceType == WsType.UPDATE_DOCUMENT_TYPE) {
			this.reloadTree();
			// this.loadingService.hideLoading()
			this.alertService.error(data.statusDescription);
		} else if (serviceType == WsType.DELETE_ITEM) {
			this.reloadTree();
			// this.loadingService.hideLoading()
			this.alertService.error(data.statusDescription);
		} else if (serviceType == WsType.ADD_DOCUMENT_TYPE) {
			this.reloadTree();
			// this.loadingService.hideLoading()
			this.alertService.error(data.statusDescription);
		} else if (serviceType == WsType.DISABLE_DOCUMENT_TYPE) {
			// this.loadingService.hideLoading()
			this.reloadTree();
			this.alertService.error(data.statusDescription);
		} else if(serviceType == WsType.REMOVE_DOCUMENT_TYPE_ATTRIBUTE){
			this.refreshDocTypeAttributeList();
			this.alertService.error(data.statusDescription);
		} else if(serviceType == WsType.TOGGLE_DOCUMENT_TYPE_ATTRIBUTE){
			this.refreshDocTypeAttributeList();
			this.alertService.error(data.statusDescription);
		}
	}

	/**
	 * Loading data to the tree view.
	 * @param payload data to be shown.
	 */
	loadData(payload: any) {
		if (payload != null) {

			var item = payload.filter(item => item["parentId"] == "0");
			if (item != null && item.length > 0) {

				payload.forEach(item => {
					let iconType: string = "";
					let itemStatus = item["status"].toUpperCase()
					if (itemStatus == "INACTIVE") {
						iconType = "fa fa-ban"
					} else {
						iconType = "fa fa-file-o"
					}

					this.data["data"].push({
						id: item["typeId"],
						parent: item["parentId"] == "0" ? "0" : item["parentId"],
						text: item["topicType"],
						type: "doc",
						icon: iconType,
						status: item["status"]
					})
				});
				this.treeRef = $('#foldertree').jstree({
					core: {
						themes: {
							variant: 'normal'
						},
						strings: {
							'New node': 'New Document'
						},
						data: this.data["data"],
						check_callback: function (operation, node, node_parent) {
							switch (operation) {
								case 'move_node':
									var returnValue = false;
									if (node_parent.id == '0') {
										returnValue = false;
									} else {
										returnValue = true;
									}
									return returnValue
								case 'copy_node':
									var returnValue = false;
									if (node_parent.id == '0') {
										returnValue = false;
									} else {
										returnValue = true;
									}
									return returnValue
								case 'rename_node ':
									var returnValue = false;
									if (node_parent.id == '0') {
										returnValue = false;
									} else {
										returnValue = true;
									}
									return returnValue
								default:
									break;
							}
						},
						force_text: true
					},
					types: {
						default: {
							// "icon" : "fa fa-file-o "
						}
					},
					plugins: ["dnd", "search", "state", "types", "wholerow", "sort"],
					search: {
						show_only_matches: true,
						show_only_matches_children: true
					}
				});
				setTimeout(() => $("#foldertree").jstree("open_node", item[0]["id"]), 250);
				//$("#foldertree").jstree("open_node", item[0]["id"])
				$('#foldertree').bind("select_node.jstree", (evt, data) => this.docSelectEvent(data))
				// //drag and drop functionality
				// $('#foldertree').bind("move_node.jstree", (evt, data) => this.folderMoveEvent(evt, data))
				// //triggered when a node is renamed
				$('#foldertree').bind("rename_node.jstree", (evt, data) => this.renameCallbackEvent(data))
				// //triggered when a node is deleted
				$('#foldertree').bind("delete_node.jstree", (evt, data) => this.deleteCallbackEvent(data))
				// //triggered when a node is created
				$('#foldertree').bind("create_node.jstree", (evt, data) => this.addCallbackEvent(data))


			}
			this.sharedService.documentTypeJstreeRefreshResponse().subscribe(
				() => {
					console.log("reload jstree");
					this.rootSelected = true;
					this.isDocTypeActive = false;
					this.rowData = [];
					this.gridApi.sizeColumnsToFit()
					this.reloadTree();
				}
			)
		}
	}

	selectJSTreeItem(event: any): void {
		//console.log('[selectJSTreeItem] (event) ' + JSON.stringify(event));
		console.log(event)
		$("#foldertree").jstree().deselect_all(true);
		if (event.id != null) {
			$("#foldertree").jstree().select_node(event.id);
		} else {
			//do nothing
		}
	}

	//manageAttributes(event: any) {
	//this.router.navigateByUrl('documentAttributes');
	//}

	public createElement() {
		console.log('[createElement]');
		let ref = $('#foldertree').jstree(true)
		let sel = ref.get_selected()
		console.log(sel)
		if (!sel.length) { return false }
		sel = sel[0]
		sel = ref.create_node(sel, { icon: "fa fa-file-o", type: "doc" });
		if (sel) {
			ref.edit(sel);

		}
	}

	public renameElement() {
		console.log('[renameElement]');
		let ref = $('#foldertree').jstree(true)
		let sel = ref.get_selected()
		if (sel == "0") {
		} else {
			if (!sel.length) { return false }
			sel = sel[0]
			ref.edit(sel)
		}

	}

	public deleteElement() {
		console.log('[deleteDocType]');
		this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.DELETE;
		this.translate.get('DOCUMENT_TYPE.DELETE_CONFLICTS').subscribe((res: string) => {
			this.title = res;
		});
		let nodedata: any = this.selectedData;
		localStorage.setItem("isDelete", "true");
		this.viewDependencies(true, nodedata.node.id)

		// console.log(localStorage.getItem("Confirm"));
		// if (localStorage.getItem("Confirm") == "ok") {
		// 	let ref = $('#foldertree').jstree(true)
		// 	let sel = ref.get_selected()
		// 	if (!sel.length) { return false }
		// 	localStorage.setItem("Confirm", "cancel");
		// 	console.log(localStorage.getItem("Confirm"))
		// 	ref.delete_node(sel);
		// }
		// else {
		// 	// do nothing
		// }
	}

	public searchElement(event: any) {
		console.log('[searchElement] ' + event.target.value);
		let to: any = false;
		if (to) { clearTimeout(to); }
		to = setTimeout(function () {
			let v = event.target.value
			$('#foldertree').jstree(true).search(v);
		}, 250);
	}

	/**
	 * Fires when user clicks on the tree node.
	 * @param evt event
	 * @param data data model
	 */
	public docSelectEvent(data: any) {
		console.log('item was selected');
		// console.log("data "+ JSON.stringify(data.node))
		if(data.node.id == "0"){
			this.rootSelected = true;
		}else{
			this.rootSelected = false;
		}

		this.selectedData = data;
		let nodeStatus = "INACTIVE"
		if (data.node.id != "0"){
			nodeStatus = data.node.original.status.toUpperCase();
		}
		if (data.node.id != "0" && nodeStatus != "INACTIVE") {
			this.docAttributeId = data.node.id
			this.loadingService.showLoading(true, null, "", null)
			this.isDocTypeActive = true
			this.isDocTypeActiveBool = true;
		} else if (data.node.id == "0") {
			this.isDocTypeActive = true
			this.isDocTypeActiveBool = false;
		} else {
			this.isDocTypeActive = false;
			this.isDocTypeActiveBool = false;
		}
		this.documentTypesService.getDocumentTypeAttributeList(this.token, data.node.id, this);
		this.dependenciesmodalService.setCallback(this);
		this.dependenciesmodalService.getDependeciesWithId(data.node.id, Constants.DEPENDENCY_ITEM_TYPE.DOCUMENTTYPE);
	}

	public addCallbackEvent(data: any) {
		console.log(data.node)
		localStorage.setItem("newDocumentType", "true");
		if (!data.node.state.selected) {
			this.createNode = true;
		}
		else { //rename folder
			this.document = new Document(data.node.text, data.node.id, data.node.parent)
		}
	}

	public deleteCallbackEvent(data: any) {
		/* this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.DELETE;
		this.translate.get('DOCUMENT_TYPE.DELETE_CONFLICTS').subscribe((res: string) => {
			this.title = res;
		});
		let nodedata: any = this.selectedData;
		localStorage.setItem("isDelete", "true");
		this.viewDependencies(true, data.node.id) */
		console.log('deleteCallbackEvent] ' + JSON.stringify(data.node.id));

		//this.documentTypesService.deleteDocType(data.node.id,this);
	}

	public toggleDocTypeStatus() {
		console.log('[toggleDocTypeStatus]');
		let ref = $('#foldertree').jstree(true)
		let sel = ref.get_selected()
		if (!sel.length) { return false }
		ref.disable_node(sel);

		//console.log("toggleDocTypeStatus")
		//this.loadingService.showLoading(true, null, "", null)
		//this.documentTypesService.toggleDocTypeStatus(data.node.id);
	}

	

	public disableDocTypeStatusCallBack(data: any) {
		let status: string = '';
		let currentStatus = data.node.original.status.toUpperCase();
		if (currentStatus == 'ACTIVE') {
			status = 'Inactive'
		} else {
			status = 'Active'
		}
		this.documentTypesService.disableDocTypeStatus(data.node.id, status, this);
	}


	public renameCallbackEvent(data: any) {
		console.log("renameCallbackEvent")
		let docName = data.node.text.replace(/\\/g, "\\\\");
		let parentId = data.node.parent;
		let docTypePrevName: string = data.node.original.text;
		let docTypeNewName: string = data.node.text.replace(/\\/g, "\\\\");
		let nodeId = data.node.id;
		if (this.createNode) {
			this.documentTypesService.getDocAvailability(docName, data.node.id, parentId, this.token, this).subscribe(
				data => {
					console.log(data);
					if (data.payload == "SUCCESS") {
						this.documentTypesService.addDocument(docName, parentId, this);
					} else {
						this.reloadTree()
						this.alertService.error(data.payload)
						// this.renameElement;
					}
				},
				error => {
					this.reloadTree()
					this.alertService.clear()
					this.alertService.error(error.statusDescription)
				}
			)
			this.createNode = false;
		} else if (docTypePrevName != docTypeNewName) {
			this.documentTypesService.getDocAvailability(docName, data.node.id, parentId, this.token, this).subscribe(
				data => {
					console.log(data);
					if (data.payload == "SUCCESS") {
						this.documentTypesService.updateDocumentType(nodeId, docTypeNewName, this);
					} else {
						this.reloadTree()
						this.alertService.error(data.payload)
						// this.renameElement;
					}
				},
				error => {
					this.reloadTree()
					this.alertService.clear()
					this.alertService.error(error.statusDescription)
				}
			)
		}
	}

	public reloadTree(){
		this.documentTypesService.getTopicTypes().subscribe(
			data => {
				this.refreshTreeData(data.payload);
			},
			error => {
				this.loadingService.hideLoading()
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			}
		)
	}


	public refreshTreeData(payload: any){
		if (payload != null) {
			this.data = {
				'data': [
					{ "id": "0", "parent": "#", "text": "Document Types", icon: "fa fa-folder-o", type: "folder" },
				]
			};
			var item = payload.filter(item => item["parentId"] == "0");
			if (item != null && item.length > 0) {
				payload.forEach(item => {
					let iconType: string = "";
					let docTypeStatus = item["status"].toUpperCase();
					if (docTypeStatus == "INACTIVE") {
						iconType = "fa fa-ban"
					} else {
						iconType = "fa fa-file-o"
					}

					this.data["data"].push({
						id: item["typeId"],
						parent: item["parentId"] == "0" ? "0" : item["parentId"],
						text: item["topicType"],
						type: "doc",
						icon: iconType,
						status: item["status"]
					})
				});
			}
			this.treeRef = $('#foldertree').jstree(true).settings.core.data = this.data["data"];
			this.treeRef = $('#foldertree').jstree(true).refresh();
		}
		this.loadingService.hideLoading()
	}



	onGridReady(params) {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		//this.gridApi.showLoadingOverlay()
		setTimeout(() => {
			params.api.sizeColumnsToFit();
		}, 500);
		// window.addEventListener("resize", function () {
		// 	setTimeout(function () {
		// 		params.api.sizeColumnsToFit();
		// 	});
		// });
		window.addEventListener("resize", this.onWindowResize);
	}

	private onWindowResize = () => {
   
		setTimeout(() => {
			if (this.gridApi) {
				this.gridApi.sizeColumnsToFit();
			}
		}); 
	};
	ngOnDestroy() {

        window.removeEventListener("resize", this.onWindowResize);
		this.gridApi.destroy();
    	this.gridApi = null;
    }

	public onCellValueChanged(event: any) {
		if (event.colDef.headerName === "Attribute Type") {
			if(event.newValue == event.oldValue){
				return
			}
			this.documentTypesService.doUpdateAttribute(event.data.docTypeAttrId, event.data.selectedAttributeType).subscribe(
				() => {
					this.loadingService.hideLoading()
					this.refreshDocTypeAttributeList();
					this.alertService.success("Attribute Type updated successfully")
					// this.documentTypesService.getCategoryValues(this.token, '0', event.data.docTypeAttrId, this);
				},
				error => {
					this.loadingService.hideLoading()
					this.refreshDocTypeAttributeList();
					this.alertService.clear()
					this.alertService.error(error.statusDescription)
				}
			)
		}
	}


	onCellClicked(event) {
		this.docTypeAttrId = event.data.docTypeAttrId; 
		if(event.column.colId == 'dotNotationAdded' && event.data.selectedAttributeType == 'Component'){
			this.categoryType = "comp_category";		
			this.bsModalRef = this.modalService.show(this.docAttributeDotNotationModal, {class: 'modal-lg'})
		}
	}

	closeButtonClickEvent(){
		this.bsModalRef.hide()
	}

	selectedCategory(params: any) {
		// console.log('[selectedCategory] (params) ' + JSON.stringify(params));
		this.documentTypesService.updateDotNotationCategoryId(this.docTypeAttrId, params.catId).subscribe(
			data => {
				console.log(data);
				this.refreshDocTypeAttributeList();
			},
			error => {
				this.refreshDocTypeAttributeList();
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			}
		)
		this.bsModalRef.hide()
 	}

	public addAttribute() {
		this.loadingService.showLoading(true, null, "", null)
		this.documentTypesService.addDocumentTypeAttribute(this.docAttributeId).subscribe(
			data => {
				this.loadingService.hideLoading()
				this.attributeSelectList = []
				this.defaultValueList = []
				this.attributeSelectList.push({ 'id': 'addAtribute', 'text': 'Select Attribute' })
				if (data.payload != null) {
					data.payload.forEach(element => {
						this.attributeSelectList.push({ 'id': element.attributeId, 'text': element.attributeName })
						this.defaultValueList.push({ 'id': element.attributeId, 'text': element.defaultType })
					});
				}
				this.openModal(this.docAttributeModal);
			},
			error => {
				this.loadingService.hideLoading()
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			}
		)
	}

	public openModal(template: TemplateRef<any>) {
		this.bsModalRef = this.modalService.show(template);
	}

	public selectedAttributeId(event) {
		if (event.id != "addAtribute") {
			if (this.defaultValueList != null) {
				this.defaultValueList.forEach(f => {
					if (f.id == event.id) {
						this.selectedDefValue = f.text
					}
				});
			}
			this.loadingService.showLoading(true, null, "", null)
			this.documentTypesService.doAddAttribute(this.guid(), this.docAttributeId, event.id, this.selectedDefValue).subscribe(
				() => {
					this.refreshDocTypeAttributeList()
					//this.loadingService.hideLoading()
					this.bsModalRef.hide()
				},
				error => {
					this.loadingService.hideLoading()
					this.bsModalRef.hide()
					this.alertService.clear()
					this.alertService.error(error.statusDescription)
				}
			)
		}
	}

	private S4() {
		return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
	}

	private guid() {
		return (this.S4() + this.S4() + "-" + this.S4() + "-" + this.S4() + "-" + this.S4() + "-" + this.S4() + this.S4() + this.S4());
	}

	public refreshDocTypeAttributeList() {
		this.loadingService.showLoading(true, null, "", null)
		this.documentTypesService.getDocumentTypeAttributeList(this.token, this.docAttributeId, this);
	}

	public viewDependencies(dflag: boolean, id: string) {
		this.dependenciesModalWithComponent(this.title, dflag, true, id);
	}

	public dependenciesModalWithComponent(header: String, dflag: boolean, cflag: boolean, id: string) {
		const initialState : object = {
			headerTitle: header,
			delFlag: dflag,
			cancelFlag: cflag,
			checkboxVal: this.status == "inactive" ? true : false
		};
		this.sharedService.setFileId(id);
		this.sharedService.setFileType(Constants.DEPENDENCY_ITEM_TYPE.DOCUMENTTYPE);
		this.sharedService.setDependencyMessageType(this.dependencyType);
		this.sharedService.setParent(this);
		this.bsModalRef = this.modalService.show(DependenciesmodalComponent, { initialState, class: 'modal-lg' });
	}

	public disableDocTypeStatus() {	
		localStorage.setItem("isDelete", "false");
		let nodedata: any = this.selectedData;
		let nodeStatus = nodedata.node.original.status.toUpperCase();
		if (nodeStatus == 'ACTIVE') {
			this.translate.get('DOCUMENT_TYPE.DISABLE_CONFIRM').subscribe((res: string) => {
				this.title = res;
			});
			this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.DISABLE;
			this.status = 'active'
		} else {
			this.translate.get('DOCUMENT_TYPE.ENABLE_CONFIRM').subscribe((res: string) => {
				this.title = res;
			});
			this.status = 'inactive'
			this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.ENABLE;
		}
		this.viewDependencies(false, nodedata.node.id)
	}

}
