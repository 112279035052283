import { Component, OnInit, EventEmitter } from '@angular/core';
import { ReportWidget } from '../report-widget.model'
import { AlertService } from '../../util/alert/alert.service';
import { Router } from '@angular/router';
import { ReportDataService } from '../report-data-service.service';
import { TranslateService } from '@ngx-translate/core';



@Component({
	selector: 'app-number-widget',
	templateUrl: './number-widget.component.html',
	styleUrls: ['./number-widget.component.css'],
	inputs: ['widget'],
	outputs: ['selectedReport']
})
export class NumberWidgetComponent implements OnInit {

	public widget: ReportWidget
	public isLoadingData: boolean = true
	public isPresentUsed: boolean = false
	public displayNum: string = '5190 (31574)'
	public total: string = '00'
	public used: string = '00'
	private widgetId: string;
	selectedReport = new EventEmitter<any>()
	public isUsed: boolean = false;
	public tooltip: string;
	translated_data: any;


	constructor(private translate: TranslateService, private alertService: AlertService, 
		private reportDataService: ReportDataService, private router: Router) { 
			translate.addLangs(["es", "en"]);
			translate.setDefaultLang("en");
			let browserLang = translate.getBrowserLang();
		}

	

	ngOnInit() {
		this.isUsed = this.widget.used
		this.loadData(this.widget.widgetId)
	}
	


	public loadData(widgetId: string) {
		this.widgetId = widgetId;
		switch (widgetId) {
			case 'tags_total_tag_reference':
				// this.isPresentUsed = true;
				this.reportDataService.getDataToReport(widgetId)
					.subscribe(data => {
						if(data.payload.length != 0){
							this.tooltip = this.translate.instant('REPORTS.TOOLTIP.TOTAL_TAGS');

							this.used = data.payload["usedTagCount"];
							this.total = data.payload["tagCount"];
							if(this.isUsed){
								this.tooltip = this.translate.instant('REPORTS.TOOLTIP.USED_TAGS');
								this.total  = data.payload["usedTagCount"];
							}							
							this.isLoadingData = false;
						}
						},
						error => {
						this.alertService.clear()
						this.alertService.error(error.statusDescription)
					});
				break;

				case 'tags_total_tag_reference_ref_view':
				// this.isPresentUsed = true;
				this.reportDataService.getDataToReport(widgetId)
					.subscribe(data => {
						if(data.payload.length != 0){
							this.tooltip = this.translate.instant('REPORTS.TOOLTIP.USED_TAGS');

							this.total = data.payload["usedTagCountForreferenceView"];

							this.isLoadingData = false;
						}
						},
						error => {
							this.alertService.clear()
							this.alertService.error(error.statusDescription)
					});
				break;

			case 'tags_total_authors':
				// this.isPresentUsed = false;
				this.reportDataService.getDataToReport(widgetId)
					.subscribe(data => {
						if(data.payload.length != 0){
							this.tooltip = this.translate.instant('REPORTS.TOOLTIP.TOTAL_AUTHORS');
							this.total = data.payload["authorsCount"];							
							this.isLoadingData = false;
						}
						},
						error => {
						this.alertService.clear()
						this.alertService.error(error.statusDescription)
					});
				break;

			case 'statements_word_count':
				this.displayNum = '0'
				break;
			case 'statements_total_reference':
				// this.isPresentUsed = true;
				this.reportDataService.getDataToReport(widgetId)
					.subscribe(data => {
						if(data.payload.length != 0){
							this.tooltip = this.translate.instant('REPORTS.TOOLTIP.TOTAL_STATEMENTS');
							this.used = data.payload["usedStatementCount"];
							this.total = data.payload["statementCount"];
							if(this.isUsed){
								this.tooltip = this.translate.instant('REPORTS.TOOLTIP.USED_STATEMENTS');
								this.total = data.payload["usedStatementCount"];
							}
							this.isLoadingData = false;
							// this.displayNum = usedStatementCount + " (" + statementCount + ")";
						}
						},
						error => {
							this.alertService.clear()
							this.alertService.error(error.statusDescription)
					});

				// this.displayNum = '52382 (1151)'
				break;
			case 'topic_total_topics':
				// this.isPresentUsed = false;
				this.reportDataService.getDataToReport(widgetId)
					.subscribe(data => {
						if(data.payload.length != 0){
							this.tooltip = this.translate.instant('REPORTS.TOOLTIP.TOTAL_TOPICS');
							this.total = data.payload["topicCount"];
							this.isLoadingData = false;
							// console.log(JSON.stringify("number widget total topic " + data));
						}
						},
						error => {
							this.alertService.clear()
							this.alertService.error(error.statusDescription)
							// console.log(JSON.stringify("number widget total topic " + error));
						}
						
					);
				// this.displayNum = '8402'
				break;
			case 'document_total_reference':
				// this.isPresentUsed = true;
				this.reportDataService.getDataToReport(widgetId)
					.subscribe(data => {
						if(data.payload.length != 0){
							this.tooltip = this.translate.instant('REPORTS.TOOLTIP.TOTAL_DOCUMENTS');
							this.used = data.payload["usedDocumentCount"];
							this.total = data.payload["documentCount"];
							if(this.isUsed){
								this.tooltip = this.translate.instant('REPORTS.TOOLTIP.USED_DOCUMENTS');
								this.total = data.payload["usedDocumentCount"];
							}
							this.isLoadingData = false;
							// this.displayNum = usedDocumentCount + " (" + documentCount + ")";
						}
						},
						error => {
						this.alertService.clear()
						this.alertService.error(error.statusDescription)
					});
				// this.displayNum = '35611 (785)'
				break;
			case 'topic_total_revisions':
				// this.isPresentUsed = false;
				this.reportDataService.getDataToReport(widgetId)
					.subscribe(data => {
						if(data.payload.length != 0){
							this.tooltip = this.translate.instant('REPORTS.TOOLTIP.TOPIC_REVISIONS');
							this.total = data.payload["revisionCount"];
							this.isLoadingData = false;
							// this.displayNum = usedDocumentCount + " (" + documentCount + ")";
						}
						},
						error => {
						this.alertService.clear()
						this.alertService.error(error.statusDescription)
					});
				// this.displayNum = '106639'
				break;
			case 'document_total_types':
				// this.isPresentUsed = true;
				this.reportDataService.getDataToReport(widgetId)
					.subscribe(data => {
						if(data.payload.length != 0){
							this.tooltip = this.translate.instant('REPORTS.TOOLTIP.TOTAL_DOCUMENT_TYPES');
							this.used = data.payload["usedDocumentTypeCount"];
							this.total = data.payload["documentTypeCount"];
							if(this.isUsed){
								this.tooltip = this.translate.instant('REPORTS.TOOLTIP.USED_DOCUMENT_TYPES');
								this.total  = data.payload["usedDocumentTypeCount"];
							}
							this.isLoadingData = false;
							// this.displayNum = usedDocumentCount + " (" + documentCount + ")";
						}
						},
						error => {
						this.alertService.clear()
						this.alertService.error(error.statusDescription)
					});
			
				// this.displayNum = '17 (27)'
				break;

            case 'total_course':
                this.reportDataService.getDataToReport(widgetId)
                    .subscribe(data => {
                        if (data.payload.length != 0) {
                            this.tooltip = this.translate.instant('REPORTS.TOOLTIP.TOTAL_COURSE');
                            this.total = data.payload["courseCount"];
                            this.isLoadingData = false;
                        }
                    },
                        error => {
                            this.alertService.clear()
                            this.alertService.error(error.statusDescription)
                        });

                break;
            case 'topic_count':
                this.reportDataService.getDataToReport(widgetId)
                    .subscribe(data => {
                        if (data.payload.length != 0) {
                            this.tooltip = this.translate.instant('REPORTS.TOOLTIP.TOPIC_COUNT');
                            this.total = data.payload["topicCount"];
                            this.isLoadingData = false;
                        }
                    },
                        error => {
                            this.alertService.clear()
                            this.alertService.error(error.statusDescription)
                        });

                break;
            case 'topic_content_count':
                this.reportDataService.getDataToReport(widgetId)
                    .subscribe(data => {
                        if (data.payload.length != 0) {
                            this.tooltip = this.translate.instant('REPORTS.TOOLTIP.TOPIC_CONTENT_COUNT');
                            this.total = data.payload["topicContentCount"];
                            this.isLoadingData = false;
                        }
                    },
                        error => {
                            this.alertService.clear()
                            this.alertService.error(error.statusDescription)
                        });

                break;
            case 'learning_check_count':
                this.reportDataService.getDataToReport(widgetId)
                    .subscribe(data => {
                        if (data.payload.length != 0) {
                            this.tooltip = this.translate.instant('REPORTS.TOOLTIP.LEARNING_CHECK_COUNT');
                            this.total = data.payload["learningCheckCount"];
                            this.isLoadingData = false;
                        }
                    },
                        error => {
                            this.alertService.clear()
                            this.alertService.error(error.statusDescription)
                        });

                break;
            case 'game_count':
                this.reportDataService.getDataToReport(widgetId)
                    .subscribe(data => {
                        if (data.payload.length != 0) {
                            this.tooltip = this.translate.instant('REPORTS.TOOLTIP.GAME_COUNT');
                            this.total = data.payload["gameCount"];
                            this.isLoadingData = false;
                        }
                    },
                        error => {
                            this.alertService.clear()
                            this.alertService.error(error.statusDescription)
                        });

                break;
            case 'total_3d_model':
                this.reportDataService.getDataToReport(widgetId)
                    .subscribe(data => {
                        if (data.payload.length != 0) {
                            this.tooltip = this.translate.instant('REPORTS.TOOLTIP.TOTAL_3D_MODEL');
                            this.total = data.payload["threedModel"];
                            this.isLoadingData = false;
                        }
                    },
                        error => {
                            this.alertService.clear()
                            this.alertService.error(error.statusDescription)
                        });

                break;
            case 'total_videos':
                this.reportDataService.getDataToReport(widgetId)
                    .subscribe(data => {
                        if (data.payload.length != 0) {
                            this.tooltip = this.translate.instant('REPORTS.TOOLTIP.TOTAL_VIDEOS');
                            this.total = data.payload["totalVideos"];
                            this.isLoadingData = false;
                        }
                    },
                        error => {
                            this.alertService.clear()
                            this.alertService.error(error.statusDescription)
                        });

                break;
			case 'component_total_reference':
				// this.isPresentUsed = true;
				this.reportDataService.getDataToReport(widgetId)
					.subscribe(data => {
						if(data.payload.length != 0){
							this.tooltip = this.translate.instant('REPORTS.TOOLTIP.TOTAL_COMPONENTS');
							this.used = data.payload["usedComponentCount"];
							this.total = data.payload["componentCount"];
							if(this.isUsed){
								this.tooltip = this.translate.instant('REPORTS.TOOLTIP.USED_COMPONENTS');
								this.total  = data.payload["usedComponentCount"];
							}
							this.isLoadingData = false;
							// this.displayNum = usedComponentCount + " (" + componentCount + ")";
						}
						},
						error => {
						this.alertService.clear()
						this.alertService.error(error.statusDescription)
					});
				// this.displayNum = '11 (785)'
				break;

			case 'system_total_reference':
				// this.isPresentUsed = true;
				this.reportDataService.getDataToReport(widgetId)
					.subscribe(data => {
						if(data.payload.length != 0){
							this.tooltip = this.translate.instant('REPORTS.TOOLTIP.TOTAL_SYSTEMS');
							this.used = data.payload["usedSystemCount"];
							this.total = data.payload["systemCount"];
							if(this.isUsed){
								this.tooltip = this.translate.instant('REPORTS.TOOLTIP.USED_SYSTEMS');
								this.total  = data.payload["usedSystemCount"];
							}
							this.isLoadingData = false;
						}
						},
						error => {
							this.alertService.clear()
							this.alertService.error(error.statusDescription)
					});
				break;
				case 'system_total_reference_sys_view':
				// this.isPresentUsed = true;
				this.reportDataService.getDataToReport(widgetId)
					.subscribe(data => {
						if(data.payload.length != 0){
							this.tooltip = this.translate.instant('REPORTS.TOOLTIP.USED_SYSTEMS');

							this.total = data.payload["usedSystemForSystemViewCount"];

							this.isLoadingData = false;
						}
						},
						error => {
							this.alertService.clear()
							this.alertService.error(error.statusDescription)
					});
				break;
			case 'facility_total_reference':
				// this.isPresentUsed = true;
				this.reportDataService.getDataToReport(widgetId)
					.subscribe(data => {
						if(data.payload.length != 0){
							this.tooltip = this.translate.instant('REPORTS.TOOLTIP.TOTAL_FACILITIES');
							this.total = data.payload["facilityCount"];
							this.used = data.payload["usedFacilityCount"];
							if(this.isUsed){
								this.tooltip = this.translate.instant('REPORTS.TOOLTIP.USED_FACILITIES');
								this.total  = data.payload["usedFacilityCount"];
							}
							this.isLoadingData = false;
						}
						},
						error => {
							this.alertService.clear()
							this.alertService.error(error.statusDescription)
					});
				break;
			case 'procedure_Graphic_reference':
				// this.isPresentUsed = false;
				this.reportDataService.getDataToReport(widgetId)
					.subscribe(data => {
						if(data.payload.length != 0){
							this.tooltip = this.translate.instant('REPORTS.TOOLTIP.PROCEDURE_GRAPHIC_REFERENCE');
							this.total = data.payload["graphicCount"];
							//this.used = data.payload["graphicCount"];
							this.isLoadingData = false;
						}
						},
						error => {
							this.alertService.clear()
							this.alertService.error(error.statusDescription)
					});
				// this.displayNum = '10 (12)'
				break;
			case 'procedure_total_reference':
				// this.isPresentUsed = false;
				this.reportDataService.getDataToReport(widgetId)
					.subscribe(data => {
						if(data.payload.length != 0){
							this.tooltip = this.translate.instant('REPORTS.TOOLTIP.TOTAL_PROCEDURES');
							this.total = data.payload["procedureCount"];
							this.isLoadingData = false;
							// console.log(JSON.stringify("number widget total topic " + data));
						}
						},
						error => {
							this.alertService.clear()
							this.alertService.error(error.statusDescription)
							// console.log(JSON.stringify("number widget total topic " + error));
						}
						
					);
				// this.displayNum = '8402'
				break;
			default:
				break;
		}
	}


	viewUsed(){
		localStorage.setItem("widgetId", this.widgetId+ "," + this.isUsed);
		this.viewReportTable()
		// this.router.navigateByUrl('viewreportdata');
	}

	view(){
		localStorage.setItem("widgetId", this.widgetId+ "," +this.isUsed);
		this.viewReportTable()
		// this.router.navigateByUrl('viewreportdata');
	}



	public viewReportTable() {
		console.log("clicled " + this.widgetId)
		this.selectedReport.emit(
			{
				widgetId: this.widgetId
			}
		) 
	}


	ngOnDestroy(){
		localStorage.removeItem('widgetId');
	}
}
