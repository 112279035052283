import { Component, OnInit, ViewChild, ElementRef, OnDestroy, AfterViewInit } from '@angular/core';
import { SystemsService } from '../systems/systems.service'
import { AlertService } from '../../util/alert/alert.service';
import { SharedService } from '../../util/shared.service';
import { LoadingService } from '../../util/loading/loading.service';
import { WsResponse } from '../../util/ws-response.model';
import { WsType } from '../../util/ws-type';
import { GridOptions } from "@ag-grid-enterprise/all-modules";
import { TranslateService } from '@ngx-translate/core';
import { SYSTEM_UI_VIEW, LocalStorageConst, UI_LAYOUT, Constants, SessionStorageConst } from '../../util/constants';
import { HistoryService } from '../history/history.service';
import { ColDef } from "@ag-grid-enterprise/all-modules";
import { SystemsCellRenderComponent } from './systems-cell-render-component.component';
import { Router } from '@angular/router'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AddeditsystemsComponent } from './addeditsystems/addeditsystems.component';
import { Subscription } from 'rxjs';
import { DependenciesModalService } from './../../util/dependenciesmodal/dependenciesmodal.service';
import { CapabilityService } from '../../util/capability.service';

@Component({
    selector: 'app-systems',
    templateUrl: './systems.component.html',
    styleUrls: ['./systems.component.css']
})
export class SystemsComponent implements OnInit, AfterViewInit, OnDestroy {

    public expandedView: string;
    public suv: any = SYSTEM_UI_VIEW;
    public columnDefs: any;
    rowData: any = [];
    private expandSubscription: any;
    private filteredData: Array<any> = [];
    public gridApi: any;
    public getRowNodeId: any;
    private gridColumnApi: any;
    public overlayLoadingTemplate: any;
    public overlayNoRowsTemplate: any;

    public gridOptions: GridOptions;

    public expandComponents: boolean = false;
    changeLayout: boolean = false

    private count: number = 0;

    private reusableType: string = "";
    private resuableText: string = "";
    private reusableView: string = "";
    private reusableXML: string = null;
    private systemName: string = "";
    private systemNo: string = "";
    private facilityName: string = "";
    private isCommissioning: boolean;
    private status: string = "";
    private edit: string = "";
    private delete: string = "";
    private hasText: string = "";

      // Capabilities
	public modifyCap: boolean = false;
	public queryCap: boolean = false;
	public deleteCap: boolean = false;
	public disableCap: boolean = false;
	public createCap: boolean = false;

    //enable disable button/icon
	public enableAddSystem: boolean = false;
	public enableSearchSystem: boolean = false;
    public enableChangeSystemStatus: boolean = false;
    public enableEditSystem: boolean = false;
	public enableDeleteSystem: boolean = false;

    private bsModalRef: BsModalRef
    private subsVar: Subscription

    constructor(private systemsService: SystemsService, private alertService: AlertService,
        private loadingService: LoadingService, private sharedService: SharedService, private modalService: BsModalService,
        private translate: TranslateService, private historyService: HistoryService, private router: Router, 
        private dependenciesmodalService: DependenciesModalService, private capabilityService: CapabilityService
    ) {
        translate.addLangs(["es", "en"]);
        translate.setDefaultLang("en");
        let browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');

        // this.translate.get('SYSTEM_SEARCH_VIEW.SYSTEM_NAME').subscribe((res: string) => {
        //     this.systemName = res;
        //     this.translate.get('SYSTEM_SEARCH_VIEW.SYSTEM_NUMBER').subscribe((res: string) => {
        //         this.systemNo = res;
        //         this.translate.get('SYSTEM_SEARCH_VIEW.FACILITY').subscribe((res: string) => {
        //             this.facilityName = res;
        //             this.translate.get('SYSTEM_SEARCH_VIEW.STATUS').subscribe((res: string) => {
        //                 this.status = res;
        //                 this.translate.get('SYSTEM_SEARCH_VIEW.EDIT').subscribe((res: string) => {
        //                     this.edit = res;
        //                     this.translate.get('SYSTEM_SEARCH_VIEW.DELETE').subscribe((res: string) => {
        //                         this.delete = res;
        //                     });
        //                 });
        //             });
        //         });
        //     });
        // });

        //check the user capability and enable/disable buttons
        this.enableDisableButtons();

        this.columnDefs = [
            {
                headerName: 'Dependencies',
                field: "dependencies",
                width: 100,
                cellStyle: { 'text-align': 'center' },
                cellRendererFramework: SystemsCellRenderComponent
            },
            {
                headerName: this.translate.instant('SYSTEM_SEARCH_VIEW.SYSTEM_NUMBER'),
                field: 'systemNo',
                checkboxSelection: false,
                hide: false,
                suppressColumnsToolPanel: false
            },
            {
                headerName: this.translate.instant('SYSTEM_SEARCH_VIEW.SYSTEM_NAME'),
                field: 'systemName',
                checkboxSelection: false,
                hide: false,
                suppressColumnsToolPanel: false,
                sort: 'asc'
            },
            {
                headerName: this.translate.instant('SYSTEM_SEARCH_VIEW.FACILITY'),
                field: 'facilityName',
                checkboxSelection: false,
                hide: false,
                suppressColumnsToolPanel: false
            },
            {
                headerName: this.translate.instant('SYSTEM_SEARCH_VIEW.USED_IN_COMMISSIONING'),
                field: 'isCommissioning',
                checkboxSelection: false,
                hide: false,
                width: 200,
                cellRenderer: function(params) {
                    if(params.value === true){
                      return("Yes");
                    }
                    else{
                      return("No");
                    }
                  
                }
            },
            // {
            //     headerName: 'Last Update Timestamp',
            //     field: 'lastUpdateTimeStamp',
            //     checkboxSelection: false,
            //     hide: true,
            //     suppressColumnsToolPanel: true
            // },
            {
                //headerName: this.status,
                headerName: this.translate.instant('SYSTEM_SEARCH_VIEW.STATUS'),
                field: "status",
                width: 85,
                hide:!this.disableCap,
                cellStyle: { 'text-align': 'center' },
                cellRendererFramework: SystemsCellRenderComponent
            },
            {
                //headerName: this.edit,
                headerName: this.translate.instant('SYSTEM_SEARCH_VIEW.EDIT'),
                field: "edit",
                width: 85,
                hide:!this.enableEditSystem,
                cellStyle: { 'text-align': 'center' },
                cellRendererFramework: SystemsCellRenderComponent
            },
            {
                //headerName: this.delete,
                headerName: this.translate.instant('SYSTEM_SEARCH_VIEW.DELETE'),
                field: "delete",
                width: 85,
                hide:!this.enableDeleteSystem,
                cellStyle: { 'text-align': 'center' },
                cellRendererFramework: SystemsCellRenderComponent
            }
        ];

        this.getRowNodeId = function (data) {
            return data.id;
        };

        this.gridOptions = {
            rowData: this.rowData,
            columnDefs: this.columnDefs,
            defaultColDef: {
                resizable: true,
                sortable: true
            },
            rowSelection: "single",
            rowMultiSelectWithClick: false,
            isExternalFilterPresent: this.externalFilterPresent,
            doesExternalFilterPass: this.externalFilterPass,
            onRowClicked: this.rowClicked,
        };

        //custom layout templates for loading and empty data sets
        this.overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Loading data...</span>';
        this.overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">No rows to show</span>';
    }

    onGridReady(params) {
        const allColumnIds = [];
        this.columnDefs.forEach((columnDef) => {
            allColumnIds.push(<ColDef>columnDef);
        });
        this.gridColumnApi = params.columnApi;
        this.gridApi = params.api;
        this.systemsService.setApiGridData(this.gridApi);
        params.api.sizeColumnsToFit();
        // window.addEventListener("resize", function () {
        //     setTimeout(function () {
        //         params.api.sizeColumnsToFit();
        //     });
        // });
        window.addEventListener("resize", this.onWindowResize);
    }

    private onWindowResize = () => {
   
		setTimeout(() => {
			if (this.gridApi) {
				this.gridApi.sizeColumnsToFit();
			}
		}); 
	};

    ngOnInit() {
        this.expandedView = SYSTEM_UI_VIEW.EXPANDED_VIEW;
        if (this.systemsService.getRowData() != null) {
            this.rowData = this.systemsService.getRowData();
        } else {
            // do nothing
        }

        let uiLayout = JSON.parse(sessionStorage.getItem(SessionStorageConst.UI_LAYOUT));
        if (uiLayout !== UI_LAYOUT.UIL_6) {
            this.columnDefs = [
                {
                    headerName: this.translate.instant('SYSTEM_SEARCH_VIEW.SYSTEM_NUMBER'),
                    field: 'systemNo',
                    checkboxSelection: false,
                    hide: false,
                    width: 250,
                    suppressColumnsToolPanel: false
                },
                {
                    headerName: this.translate.instant('SYSTEM_SEARCH_VIEW.SYSTEM_NAME'),
                    field: 'systemName',
                    checkboxSelection: false,
                    hide: false,
                    width: 500,
                    suppressColumnsToolPanel: false,
                    sort: 'asc'
                },
                // {
                //     headerName: this.translate.instant('SYSTEM_SEARCH_VIEW.FACILITY'),
                //     field: 'facilityName',
                //     checkboxSelection: false,
                //     hide: false,
                //     width: 200,
                //     suppressColumnsToolPanel: false
                // },
            ];
        } else {
            //do nothing
        }
        if (uiLayout == UI_LAYOUT.UIL_2 || uiLayout == UI_LAYOUT.UIL_4) {
            this.expandedView = SYSTEM_UI_VIEW.SMALL_VIEW;
            this.changeLayout = false
        } else {
            this.changeLayout = true
        }
    }

    ngAfterViewInit(): void {
        //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
        //Add 'implements AfterViewInit' to the class.
        this.subsVar = this.sharedService.expandReusablesExcute().subscribe((option: any) => this.changeComponent(option))
        this.subsVar = this.sharedService.openSystemsEditRespond().subscribe((option: any) => this.openAddEditSystemsModal())
    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        if(this.subsVar) {
            this.subsVar.unsubscribe()
        }

        window.removeEventListener("resize", this.onWindowResize);
		this.gridApi.destroy();
    	this.gridApi = null;
    }

    changeComponent(option: any) {
        // console.log('[changeComponent] (option) ' + JSON.stringify(option));
    }

    externalFilterPresent() {
        return this.systemName != "";
    }

    externalFilterPass(node: any) {
        return true;
    }

    rowClicked() {
    }

    cellClicked(event) {
        let status = event.data.status.split(":")[2];
        console.log("System insert : " +event.data);
		if (status != null) {
			if (status == "Active" || status == "active") {
                if (event.colDef.field == "systemName") {
                    event.data.type = event.colDef.field;
                    let tabType = SharedService.getTabComponentType();
                    if (tabType == 'Editor') {
                        SharedService.insertEditorRequest(event.data);
                    } else if (tabType == 'Procedures') {
                        SharedService.insertProceduresRequest(event.data);
                    } else if (tabType == 'IceCourse') {
                        SharedService.insertICECourseRequest(event.data);
                    }
                    this.reusableType = "System Name";
                    this.resuableText = "sys";
                    this.reusableView = event.data.systemName;
                    this.reusableXML = SharedService.getHtmlString();
                    this.save(this.reusableXML, this.resuableText, this.reusableType, this.reusableView);
        
                } else if (event.colDef.field == "systemNo") {
                    event.data.type = event.colDef.field;
                    let tabType = SharedService.getTabComponentType();
                    if (tabType == 'Editor') {
                        SharedService.insertEditorRequest(event.data);
                    } else if (tabType == 'Procedures') {
                        SharedService.insertProceduresRequest(event.data);
                    } else if (tabType == 'IceCourse') {
                        SharedService.insertICECourseRequest(event.data);
                    }
                    this.reusableType = "System Number";
                    this.resuableText = "sno";
                    this.reusableView = event.data.systemNo;
                    this.reusableXML = SharedService.getHtmlString();
                    this.save(this.reusableXML, this.resuableText, this.reusableType, this.reusableView);
                } else if (event.colDef.field == "facilityName") {
                    event.data.type = "SystemFacility";
                    let tabType = SharedService.getTabComponentType();
                    if (tabType == 'Editor') {
                        SharedService.insertEditorRequest(event.data);
                    } else if (tabType == 'Procedures') {
                        SharedService.insertProceduresRequest(event.data);
                    } else if (tabType == 'IceCourse') {
                        SharedService.insertICECourseRequest(event.data);
                    }
                    this.reusableType = "Facility_System";
                    this.resuableText = "fac";
                    this.reusableView = event.data.facilityName;
                    this.reusableXML = SharedService.getHtmlString();
                    this.save(this.reusableXML, this.resuableText, this.reusableType, this.reusableView);
                }
			} else {
				this.translate.get('REUSABLES.COULD_NOT_ENTER_DISABLED_REUSABLES').subscribe((res: string) => {
                    this.alertService.error(res, false);
                });
			}
		}

       
    }

    save(reusableXML: any, reusableText: any, reusableType: any, reusableView: any) {
        if (this.reusableXML != null) {
            let xml = this.reusableXML.replace(/insid=.{38}/gi, function insId() {
                function s4() {
                    return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
                }
                return 'insId="' + s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4() + '"';
            });
            let to: any = false;
            if (to) { clearTimeout(to); }
            to = setTimeout(() => {
                this.historyService.saveToHistory(this.reusableType, this.resuableText, xml, this.reusableView).subscribe(
                    data => {
                        //
                    },
                    error => {
                        this.alertService.clear()
                        this.alertService.error(error.statusDescription)
                    });;
            }, 200);
        }
    }
    /**
     * privious search
     */
    // search(event: any) {
    //     this.systemNo = "";
    //     this.facilityName = "";
    //     this.systemName = "";
    //     this.count = 0;
    //     let docArray = event.target.value.split(",");
    //     for (var l = 0; l <= docArray.length - 1; l++) {
    //         let letter = docArray[l].split(":");
    //         if (letter != "") {
    //             for (var i = 0; i < letter.length - 1; i++) {
    //                 if (letter[i] == "sno") {
    //                     this.systemNo = letter[i + 1];
    //                 } else if (letter[i] == "fac") {
    //                     this.facilityName = letter[i + 1];
    //                 } else if (letter[i] == "sys") {
    //                     this.systemName = letter[i + 1];
    //                 } else {
    //                     if (this.count == 0) {
    //                         this.count = 1;
    //                         this.error();
    //                     } else {
    //                         // do nothing dname fac tno sName desc
    //                     }
    //                     this.count = 1;
    //                 }
    //             }
    //         }
    //     }
    //     if (this.systemNo != "" || this.facilityName != "" || this.systemName != "") {
    //         let to: any = false;
    //         if (to) { clearTimeout(to); }
    //         to = setTimeout(() => {
    //             this.gridOptions.api.onFilterChanged();
    //             this.systemsService.searchSystems(this.systemNo, this.systemName, this.facilityName).subscribe(
    //                 data => {
    //                     this.setDocumentSearchTableData(data.payload)
    //                 },
    //                 error => {
    //                     this.alertService.clear()
    //                     this.alertService.error(error.statusDescription)
    //                 });
    //         }, 200);
    //     }

    // }

    error() {
        this.alertService.error("wrong value inserted");
    }

    setDocumentSearchTableData(payload: any) {
        this.loadingService.hideLoading();
        // console.log(payload);
        this.filteredData = [];
        this.rowData = [];
        if (payload != null) {
			this.dependenciesmodalService.setCallback(this);
            if (this.expandComponents == false) {
                for (let i = 0; i < payload.length; i++) {
                    this.filteredData.push({
                        'id': payload[i]["systemId"],
                        dependencies: "disable_dependencies:" + payload[i]["systemId"],
                        'systemNo': payload[i]["systemNo"],
                        'systemName': payload[i]["systemName"],
                        'facilityName': payload[i]["facilityName"],
                        'type': payload[i]["type"],
                        'systemId': payload[i]["systemId"],
                        'lastUpdateTimeStamp': payload[i]["lastUpdateTimeStamp"],
                        'systemNoLastUpdateTimeStamp': payload[i]["systemNoLastUpdateTimeStamp"],
                        'facilityLastUpdateTimeStamp': payload[i]["facilityLastUpdateTimeStamp"],
                        'facilityId': payload[i]["facilityId"],
                        'isCommissioning': payload[i]["isCommissioning"],
                        status: "status:" + payload[i]["systemId"] + ":" + payload[i]["status"],
                        edit: "edit:" + payload[i]["systemId"] + ":" + payload[i]["status"] + ":" + payload[i]["facilityId"] + ":" + payload[i]["parentId"] ,
                        delete: "delete:" + payload[i]["systemId"] + ":" + payload[i]["status"],
                        systemStatus: payload[i]["status"],
					});
					
					this.dependenciesmodalService.getDependeciesWithId( payload[i]["systemId"], "System");
                }
            }
            this.rowData = this.filteredData;
            this.gridApi.paginationProxy.currentPage = 0;
            this.gridOptions.api.sizeColumnsToFit();
        } else {
            // no row data to show
            this.rowData = [];
        }
        this.systemsService.setRowData(this.rowData);
	}
	
	onSuccess(data: WsResponse, serviceType: WsType): void {
		// console.log(data.payload)
		console.log("data.payload "  + serviceType)	
		if (data.payload != null && serviceType == WsType.ITEM_DEPENDENCY_LIST) {
		   let items = data.payload.split("_")
		   if(items[1]=="Yes")
		   {
				for(let i=0; i<this.rowData.length; i++)
				{
					if(this.rowData[i].id == items[0])
					{
						//var rowNode = this.gridApi.getRowNode(items[0]);
						//rowNode.setDataValue("dependencies", "dependencies:" + items[0])
                        this.rowData[i].dependencies = "dependencies:" + items[0];
                        if(!this.gridApi.destroyCalled) {
                            this.gridApi.setRowData(this.rowData);
                        }
					}
				}
		   }
        }
	}
	
	onFail(res: WsResponse, serviceType: WsType): void {
       
    }

    /**
     * previos search for systems
     */

    // searchBySystemNumber(event: any) {

    //     let to: any = false;
    //     if (to) { clearTimeout(to); }
    //     to = setTimeout(() => {
    //         this.systemNo = event.target.value;
    //         this.gridOptions.api.onFilterChanged();
    //         this.systemsService.searchSystems(this.systemNo, this.systemName, this.facilityName).subscribe(
    //             data => {
    //                 this.setDocumentSearchTableData(data.payload)
    //             },
    //             error => {
    //                 this.alertService.clear()
    //                 this.alertService.error(error.statusDescription)
    //             });
    //     }, 200);

    // }

    // searchBySystemName(event: any) {
    //     let to: any = false;
    //     if (to) { clearTimeout(to); }
    //     to = setTimeout(() => {
    //         this.systemName = event.target.value;
    //         this.gridOptions.api.onFilterChanged();
    //         this.systemsService.searchSystems(this.systemNo, this.systemName, this.facilityName).subscribe(
    //             data => {
    //                 this.setDocumentSearchTableData(data.payload)
    //             },
    //             error => {
    //                 this.alertService.clear()
    //                 this.alertService.error(error.statusDescription)
    //             });
    //     }, 200);

    // }

    // searchByFacility(event: any) {
    //     let to: any = false;
    //     if (to) { clearTimeout(to); }
    //     to = setTimeout(() => {
    //         this.facilityName = event.target.value;
    //         this.gridOptions.api.onFilterChanged();
    //         this.systemsService.searchSystems(this.systemNo, this.systemName, this.facilityName).subscribe(
    //             data => {
    //                 this.setDocumentSearchTableData(data.payload)
    //             },
    //             error => {
    //                 this.alertService.clear()
    //                 this.alertService.error(error.statusDescription)
    //             });
    //     }, 200);

    // }

    searchFunc(data: any) {
        console.log('[searchFunc] (data) ' + JSON.stringify(data));
        this.alertService.clear();
        this.systemNo = "";
        this.facilityName = "";
        this.systemName = "";
        this.isCommissioning = null;
        this.hasText = "";
        this.count = 0;
        this.gridApi.showLoadingOverlay();

        //assign value for the fields from searchWordMap
        var sysnum = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_SYSTEM_SYS_NUMBER];
        if (sysnum != undefined) {
            this.systemNo = sysnum;
        } else {
            //no systemNo
        } 
        var sysname = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_SYSTEM_SYS_NAME];
        if (sysname != undefined) {
            this.systemName = sysname;
        } else {
            //no sysname
        } 

        var sysfac = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_SYSTEM_SYS_FACILITY];
        if (sysfac != undefined) {
            this.facilityName = sysfac;
        } else {
            //no facilityName
        }

        var isComm = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_SYSTEM_SYS_COMMISSIONING];
        if (isComm != undefined) {
            this.isCommissioning = (isComm==="Yes"? true:false);
        } else {
            //no isCommissioning
        }

        var hasText = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_HAS_THE_WORDS];
        if (hasText != undefined) {
            this.hasText = hasText;
        } else {
            //no has text
        }

        if (this.systemNo != "" || this.facilityName != "" || this.systemName != "" || this.hasText != "" || this.isCommissioning != undefined) {
            let to: any = false;
            if (to) { clearTimeout(to); }
            to = setTimeout(() => {
                // this.gridOptions.api.onFilterChanged();
                this.systemsService.searchSystems(this.systemNo, this.systemName, this.facilityName, this.isCommissioning, this.hasText).subscribe(
                    data => {
                        this.setDocumentSearchTableData(data.payload)
                    },
                    error => {
                        this.alertService.clear()
                        this.alertService.error(error.statusDescription)
                    });
            }, 200);
        } else {
            //search bar has empty values
            this.gridApi.hideOverlay()
            this.setDocumentSearchTableData(null)
        }


        // let docArray = data.searchTxt.split(" ");
        // for (var l = 0; l <= docArray.length - 1; l++) {
        //     let letter = docArray[l].split(":");
        //     if (letter != "") {
        //         for (var i = 0; i < letter.length - 1; i++) {
        //             if (letter[i] == "sno") {
        //                 this.systemNo = letter[i + 1];
        //             } else if (letter[i] == "fac") {
        //                 this.facilityName = letter[i + 1];
        //             } else if (letter[i] == "sys") {
        //                 this.systemName = letter[i + 1];
        //             } else {
        //                 if (this.count == 0) {
        //                     this.count = 1;
        //                     this.error();
        //                 } else {
        //                     // do nothing dname fac tno sName desc
        //                 }
        //                 this.count = 1;
        //             }
        //         }
        //     }
        // }
        // if (this.systemNo != "" || this.facilityName != "" || this.systemName != "") {
        //     let to: any = false;
        //     if (to) { clearTimeout(to); }
        //     to = setTimeout(() => {
        //         // this.gridOptions.api.onFilterChanged();
        //         this.systemsService.searchSystems(this.systemNo, this.systemName, this.facilityName).subscribe(
        //             data => {
        //                 this.setDocumentSearchTableData(data.payload)
        //             },
        //             error => {
        //                 this.alertService.clear()
        //                 this.alertService.error(error.statusDescription)
        //             });
        //     }, 200);
        // }

    }

    searchBarEnterFunc(data: any) {
        console.log('[searchBarEnterFunc] (data) ' + JSON.stringify(data));
        this.alertService.clear();
        this.systemNo = "";
        this.facilityName = "";
        this.systemName = "";
        this.isCommissioning;
        this.count = 0;
        this.gridApi.showLoadingOverlay();
        //check fro empty values in search bar
        if (this.checkForEmptyValuesInSearchBar(data)) {
            let docArray = data.split(" ");
            for (var l = 0; l <= docArray.length - 1; l++) {
                let letter = docArray[l].split(":");
                if (letter != "") {
                    for (var i = 0; i < letter.length - 1; i++) {
                        if (letter[i] == "sno") {
                            this.systemNo = letter[i + 1];
                        } else if (letter[i] == "fac") {
                            this.facilityName = letter[i + 1];
                        } else if (letter[i] == "sys") {
                            this.systemName = letter[i + 1];
                        } else {
                            if (this.count == 0) {
                                this.count = 1;
                                this.error();
                            } else {
                                // do nothing dname fac tno sName desc
                            }
                            this.count = 1;
                        }
                    }
                }
            }
            if (this.systemNo != "" || this.facilityName != "" || this.systemName != "") {
                let to: any = false;
                if (to) { clearTimeout(to); }
                to = setTimeout(() => {
                    this.gridOptions.api.onFilterChanged();
                    this.systemsService.searchSystems(this.systemNo, this.systemName, this.facilityName, this.isCommissioning, this.hasText).subscribe(
                        data => {
                            this.setDocumentSearchTableData(data.payload)
                        },
                        error => {
                            this.alertService.clear()
                            this.alertService.error(error.statusDescription)
                        });
                }, 200);
            }
        } else {
            //search bar has empty values
            this.gridApi.hideOverlay()
            this.setDocumentSearchTableData(null)
        }
    }

    /**
     * check for empty values in search bar
     * @param data 
     */
    public checkForEmptyValuesInSearchBar(data: any): boolean {
        let docArray = data.split(" ");
        if (docArray.length > 1) {
            return true
        } else {
            if (docArray[0] != '') {
                return true
            } else {
                return false
            }
        }
    }

    add() {
        this.sharedService.setFileId("-1");
        // this.router.navigateByUrl('addeditsystems');
        this.openAddEditSystemsModal()
    }

    /**
     * open add/edit facility modal 
     * to solve data mission issue when save or cancel
     */
     private openAddEditSystemsModal() {
        console.log('[openAddEditSystemsModal]');
        this.bsModalRef = this.modalService.show(AddeditsystemsComponent, { backdrop: 'static', keyboard : false })
    }

    /**
     * enable/disable buttons or columns
     */
    private enableDisableButtons(){
        this.capabilityCheck();
        this.enableAddSystem = this.createCap;
        this.enableSearchSystem = this.queryCap;
        this.enableEditSystem = this.modifyCap;
        this.enableDeleteSystem = this.deleteCap;
        this.enableChangeSystemStatus = this.disableCap;
    }

    /**
     * check the capabilities
     */
    public capabilityCheck(){
        let modifyCapability: string = "modifySystem";
        let queryCapability: string = "querySystem";
        let deleteCapability: string = "deleteSystem";
        let disableCapability: string = "disableSystem";
        let createCapability: string = "createSystem";

        this.modifyCap = this.capabilityService.isCapabilityAssigned(modifyCapability);
        this.queryCap = this.capabilityService.isCapabilityAssigned(queryCapability);
        this.deleteCap = this.capabilityService.isCapabilityAssigned(deleteCapability);
        this.disableCap = this.capabilityService.isCapabilityAssigned(disableCapability);
        this.createCap = this.capabilityService.isCapabilityAssigned(createCapability);
    }

}
