import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserVariable } from '../../util/common/user-variable';
import { ServiceUrls } from '../../util/service-urls';
import { WsResponse } from '../../util/ws-response.model';
import { WsCallback } from '../../util/ws-callback.interface';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators'
import { CommonUtil } from 'src/app/util/common/common-util';

@Injectable({
    providedIn: 'root'
})
export class ImagesService {
    public token: string;
    private rowData: any = null;
    private images: any = null;
    private imageSearchState:any = null;
    private apiGridData: any = null;
    private callback: WsCallback;
    constructor(private http: HttpClient, private commonUtil: CommonUtil) { }
    public setCallback(callBack: WsCallback) {
        this.callback = callBack;
    }



    searchImages(documentTypeId: String, imagesystem: String, description: String, imagedoc: string, hasText: string) {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        let url = this.getsystemsBySearch(token);
        return this.http.post(url, JSON.stringify(new Request(documentTypeId, imagesystem, description, imagedoc, hasText))).
            pipe(
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                }),
                catchError(error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    return throwError(res)
                })
            );

    }

    getImages(imagedata: any) {
        let a = JSON.stringify(imagedata);
        console.log('a', a);
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        let url = this.getImage(token);
        return this.http.post(url, JSON.stringify(new ImageRequest(a))).
            pipe(
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                }),
                catchError(error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    return throwError(res)
                })
            );

    }

    getThumbnailImages(imagedata: any) {
        let a = JSON.stringify(imagedata);
        console.log('a', a);
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        let url = this.getThumbnailImage(token);
        return this.http.post(url, JSON.stringify(new ImageRequest(a))).
            pipe(
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                }),
                catchError(error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    return throwError(res)
                })
            );

    }

    /**
     * Achini Randeni
     * Get image detail by image id
     * @param imageId 
     */
    getImageInfoById(imageId: any) {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }

        return this.http.get(ServiceUrls.getImageInfoById(token, imageId)).pipe(
            map(response => {
                var modified = JSON.parse(JSON.stringify(response));
                return new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload
                )
            }),
            catchError(error => {
                var modified = JSON.parse(JSON.stringify(error.error));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload
                )
                return throwError(res)
            })
        );
    }

    public getsystemsBySearch(token: String) {
        let url = ServiceUrls.IMAGE_SEARCH + '?token=' + token;
        return url;

    }
    public getImage(token: String) {
        let url = ServiceUrls.IMAGE_SEARCH_IMAGE + '?token=' + token;
        return url;

    }
    public getThumbnailImage(token: String) {
        let url = ServiceUrls.IMAGE_SEARCH_THUMBNAIL_IMAGE + '?token=' + token;
        return url;

    }

    public setApiGridData(apiGridData: any) {
        this.apiGridData = apiGridData;
    }

    public getApiGridData() {
        return this.apiGridData;
    }

    public setRowData(rowData: any) {
        this.rowData = rowData;
    }

    public getRowData() {
        return this.rowData;
    }

    public setImageList(images: any) {
        this.images = images;
    }

    public getImageList() {
        return this.images;
    }

    public setImageSearchState(imageSearchState: any) {
        this.imageSearchState = imageSearchState;
    }

    public getImageSearchState() {
        return this.imageSearchState;
    }
}
class Request {
    constructor(private documentTypeId: String, private imagesystem: String, private description: String, private imagedoc, private hasText: String,) { }
}

class ImageRequest {
    constructor(private imagedata: any) { }
}

