import { Component, ViewChild, ElementRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FilesService } from '../files.service';
import { AlertService } from '../../../util/alert/alert.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from '../../../util/loading/loading.service';
import { FormBuilder, FormControl, FormGroup, Validator, Validators } from '@angular/forms';
import {ManagemccService} from "../../../commissioning/managemcc/managemcc.service";
import { UserVariable } from 'src/app/util/common/user-variable';
import { DocumentStoreService } from 'src/app/systemsetting/documentstore/documentstore.service';
import { ManageotnService } from 'src/app/commissioning/manageotn/manageotn.service';
import { IssueChecksheetService } from 'src/app/commissioning/checksheets/issuechecksheets/issuechecksheets.service';
import { ManagePCCService } from 'src/app/commissioning/managepcc/managepcc.service';


@Component({
   selector: 'outputgenerator-modal',
   templateUrl: './output-generate-component.component.html',
   styleUrls: ['./output-generate-component.component.css']
})

export class OPGConfigInputsViewModalComponent {
   om_translated_data: any = null;
   public loadingMessage: string;
   public title: string = "Output Generator"
   public itemConfigList: any[] = [];
   public facilityList: Array<any>;
   public itemList: Array<any>;
   public multipleLogoList: Array<any>;
   public documentList: Array<any>;
   public systemList: Array<any>;
   public itemIdList: string[] = [];
   public item: string;
   public projectmapId: string;
   public outputType: string;
   public templateId: string;
   public isEnabled_CreateDraftDocuments: string;
   public isEnabled_SaveOutputTemplateParams: string
   public invisibleItemConfigList: any[] = [];
   public selectedFileName: string;
   public isChecked: boolean = true;
   public sendMail: string = null;
   public isShowDraftDocument: boolean = true;
   public isShowParameterValues: boolean = true;

   outputGenerateForm: FormGroup;

   constructor(public bsModalRef: BsModalRef, private filesService: FilesService, private alertService: AlertService, private router: Router,
      private translate: TranslateService, private loadingService: LoadingService, private fb: FormBuilder,private managemccService: ManagemccService,
      private documentStoreService: DocumentStoreService, private manageotnService: ManageotnService, private issueChecksheetService: IssueChecksheetService,
      private managePCCService: ManagePCCService) {

      let browserLang = this.translate.getBrowserLang();
      this.translate.getTranslation(browserLang).subscribe((res: string) => {
      });
   }

   ngOnInit() {
      
         this.outputGenerateForm = new FormGroup({});
         this.outputGenerateForm.addControl("saveParameter", new FormControl(this.setParameterValue("false")))
         this.outputGenerateForm.addControl("draftDocument", new FormControl());
         this.outputGenerateForm.get("draftDocument").setValue(this.setCheckboxValue("1"));
         if (this.itemConfigList.length > 0) {
         this.itemConfigList.forEach(f => {
            if (f.type == 'image' || f.type == 'text') {
               if ((this.outputType=='XML_TOPICMAP_ZIP' || this.outputType=='STORE_DOC_ZIP_PDF' || this.outputType=='DOCX') && f.name == "documentTitle" && f.parameterValue == ""){
                  f.parameterValue = this.selectedFileName;
               }
               this.outputGenerateForm.addControl(f.name, new FormControl({ value: this.setValue(f.outputFileName, f.parameterValue), disabled: this.checkEditable(f.editable) }))
            } else if (f.type == 'list') {
               this.outputGenerateForm.addControl(f.name, new FormControl())
               if (f.parameterValue != undefined && f.parameterValue != "") {
                  this.outputGenerateForm.get(f.name).setValue([{ id: f.parameterValue, text: this.getSelectedDocumentElement(f.parameterValue) }])
               } else {
                  this.outputGenerateForm.get(f.name).setValue([{ id: f.parameterValue, text: f.parameterValue }])
               }
            } 

            else if (f.type == 'itemList') {
            this.outputGenerateForm.addControl(f.name, new FormControl())
            if (f.parameterValue != undefined && f.parameterValue != "") {
               this.outputGenerateForm.get(f.name).setValue([{ id: f.title, text: f.parameterValue }])
            } else {
               this.outputGenerateForm.get(f.name).setValue([{ id: f.title, text: this.getSelectedItemElement() }])
            }
         }

         else if (f.type == 'imageList') {
            this.outputGenerateForm.addControl(f.name, new FormControl())
            if (f.parameterValue != undefined && f.parameterValue != "") {
               this.outputGenerateForm.get(f.name).setValue([{ id: f.title, text: this.getSavedLogo(f.parameterValue)}])
            } else {
               this.outputGenerateForm.get(f.name).setValue([{ id: f.title, text: this.getSelectedLogo()}])
            }
         }
            
            else if (f.type == 'facility') {
               this.outputGenerateForm.addControl(f.name, new FormControl())
               if (f.parameterValue != undefined && f.parameterValue != "") {
                  this.outputGenerateForm.get(f.name).setValue([{ id: f.parameterValue, text: this.getSelectedFacilityElement(f.parameterValue) }])
                  this.updateSystemList(f.parameterValue, true);
               } else {
                  this.outputGenerateForm.get(f.name).setValue([{ id: f.parameterValue, text: f.parameterValue }])
                  this.updateSystemList("0", true); //systems list with no facilities
               }
            }  else if (f.type == 'system') {
              this.outputGenerateForm.addControl(f.name, new FormControl())
               if (f.parameterValue != undefined && f.parameterValue != "") {
                  this.outputGenerateForm.get(f.name).setValue([{ id: f.parameterValue, text: this.getSelectedSystemElement(f.parameterValue) }])
               } else {
                  this.outputGenerateForm.get(f.name).setValue([{ id: f.parameterValue, text: f.parameterValue }])
               }
            } else {
               this.outputGenerateForm.addControl(f.name, new FormControl())
               this.outputGenerateForm.get(f.name).setValue(this.setCheckboxValue(f.parameterValue))
            }
         })
      }

      //enable/disable draft document and save parameter options
      this.isVisibleDraftDocumentAndSaveParameters();

         // this.outputGenerateForm.addControl("saveParameter", new FormControl());
         // this.outputGenerateForm.get("saveParameter").setValue(this.setCheckboxValue("false"))
         // this.outputGenerateForm.addControl("draftDocument", new FormControl(this.setParameterValue("false")))
    
   }

   /**
    * Enable/disable draft document and save parameter options
    */
   isVisibleDraftDocumentAndSaveParameters(){
      if(this.item == 'Mechanical Completion' || this.item == 'Onshore Turnover Notice' || this.item == 'CheckSheet' || this.item == 'Static Completion'){
         this.isShowDraftDocument = false;
         this.isShowParameterValues = false;
      } else{
         this.isShowDraftDocument = true;
         this.isShowParameterValues = true;
      }
   }

   public setParameterValue(value: string): boolean {
      if (value == 'yes') {
         return true
      } else {
         return false
      }
   }

   public setCheckboxValue(value: string): boolean {
      if (value == '1') {
         return true
      } else {
         return false
      }
   }

   public checkEditable(editable: string): boolean {
      if (editable == 'yes') {
         return false
      } else {
         return true
      }
   }

   public getSelectedDocumentElement(id: string): string {
      let selectedValue
      this.documentList.forEach(f => {
         if (f.id == id) {
            selectedValue = f.text
         }
      });
      return selectedValue
   }
   public getSelectedItemElement(): string {
      let selectedValue
      selectedValue = this.itemList[0].text;
      return selectedValue
   }

   public getSelectedLogo(): string {
      let selectedValue
      selectedValue = this.multipleLogoList[0].text;
      return selectedValue
   }

   public getSavedLogo(id: string): string {
      let selectedValue
      this.multipleLogoList.forEach(f => {
         if (f.path == id) {
            selectedValue = f.text
         }
      });
      return selectedValue
   }
   public getSelectedLogoPath(): string {
      let selectedValue
      selectedValue = this.multipleLogoList[0].path;
      return selectedValue
   }

   public getSelectedFacilityElement(id: string): string {
      let selectedValue
      this.facilityList.forEach(f => {
         if (f.id == id) {
            selectedValue = f.text
         }
      });
      return selectedValue
   }

   public getSelectedSystemElement(id: string): string {
      let selectedValue
      this.systemList.forEach(f => {
         if (f.id == id) {
            selectedValue = f.text
         }
      });
      return selectedValue
   }

   public setValue(fileName: string, paraVal: string): string {
      var value
      if (fileName == undefined) {
         value = paraVal
      } else {
         value = fileName
      }
      return value
   }

   public saveOutputValues() {
      let validationSuccess:boolean = true;
      this.loadingService.showLoading(true, null, "", null)
      var configXMl = "<Config>"
      var saveDocValue
      var saveDraftDoc
      var isMoveFiguresToLeft
      var isBreakPagesForFigure
      var doPutWatermark

      this.itemConfigList.forEach(f => {
         if ((f.name == 'breakPagesForFigure') || (f.name == 'moveFiguresToLeft') || (f.name == 'WaterMark')
            || (f.name == 'NCWROutputType') || (f.name == 'noPageBreaks') || (f.name == 'inReview') || (f.name == 'useAltDocNo')) {
            if (this.outputGenerateForm.get(f.name).value != null) {
               var booleanResponse;
               if (this.outputGenerateForm.get(f.name).value == true) {
                  booleanResponse = 1
               } else {
                  booleanResponse = 0
               }
               configXMl += '<input  name="' + f.name + '" confType="' + f.listname + '" >'
                  + booleanResponse + '</input>'
            }
         } else if (f.name == "DocumentType") {
            if (this.outputGenerateForm.get(f.name).value != null) {
               if (this.outputGenerateForm.get(f.name).value.length > 0) {
                  var selectedValue = this.outputGenerateForm.get(f.name).value[0].id
                  configXMl += '<input name="' + f.name + '" confType="' + f.listname + '" >'
                     + selectedValue + '</input>';
               }
            }
         } else if (f.name == "facility") {
            if (this.outputGenerateForm.get(f.name).value != null) {
               if (this.outputGenerateForm.get(f.name).value.length > 0) {
                  var selectedFacilityValue = this.outputGenerateForm.get(f.name).value[0].id
                  configXMl += '<input name="' + f.name + '" confType="' + f.listname + '" >'
                     + selectedFacilityValue + '</input>';
               }
            }
         } else if (f.name == "CoverPageHeaderFixedVal") {
            if (this.outputGenerateForm.get(f.name).value != null) {
               const value = this.outputGenerateForm.get(f.name).value;
               if (Array.isArray(value)) {
                  var selectedItemValue = this.outputGenerateForm.get(f.name).value[0].text
                  configXMl += '<input name="' + f.name + '" confType="' + f.listname + '" >'
                     + selectedItemValue + '</input>';
               } else {
                  var selectedItemValue = this.outputGenerateForm.get(f.name).value;
                  configXMl += '<input name="' + f.name + '" confType="' + f.listname + '" >'
                     + selectedItemValue + '</input>';

               }
            }
            else if (this.outputGenerateForm.get(f.name).value == null || this.outputGenerateForm.get(f.name).value == undefined || this.outputGenerateForm.get(f.name).value == "") {

                  configXMl += '<input name="' + f.name + '" confType="' + f.listname + '" >'
                     + this.itemList[0].text + '</input>';
            }
         } else if (f.name == "MultipleLogos") {
            if (this.outputGenerateForm.get(f.name).value != null) {
               const value = this.outputGenerateForm.get(f.name).value;
                  var selectedItemId = this.outputGenerateForm.get(f.name).value[0].id
                  if(selectedItemId == 'MultipleLogos'){
                     // var selectedItemValue = this.multipleLogoList[0].path;
                     var selectedItemValue = this.multipleLogoList.find(item => item.text === value[0].text).path;

                     

                  } else {
                     var selectedItemValue = this.multipleLogoList.find(item => item.id === selectedItemId).path;

                  }
                  
                  configXMl += '<input name="' + f.name + '" confType="' + f.listname + '" >'
                     + selectedItemValue + '</input>';

            }
            else if (this.outputGenerateForm.get(f.name).value == null || this.outputGenerateForm.get(f.name).value == undefined || this.outputGenerateForm.get(f.name).value == "") {

                  configXMl += '<input name="' + f.name + '" confType="' + f.listname + '" >'
                     + this.multipleLogoList[0].path + '</input>';
            }
         } else if (f.name == "system") {
            if (this.outputGenerateForm.get(f.name).value != null) {
             if (this.outputGenerateForm.get(f.name).value[0]==undefined || this.outputGenerateForm.get(f.name).value[0].id === "") {
                //Validate the System field value when Safety proceudres adding to the store 
               if (this.title === this.translate.instant('PDF_ADD_TO_PROCEDURE_STORE.ADD_TO_PROCEDURE_STORE'))
                  {
                     validationSuccess = false;
                  }
             } else {
               if (this.outputGenerateForm.get(f.name).value.length > 0) {
                  var selectedSystemValue = this.outputGenerateForm.get(f.name).value[0].id
                  configXMl += '<input name="' + f.name + '" confType="' + f.listname + '" >'
                     + selectedSystemValue + '</input>';
               } 
             }
            }
         } else if (f.name == "documentTitle") {
            if (this.outputGenerateForm.get(f.name).value != null) {
               if (this.outputGenerateForm.get(f.name).value==undefined || this.outputGenerateForm.get(f.name).value === "") {
                  //Validate the System field value when Safety proceudres adding to the store 
                  console.log(this.outputType);
                  if (this.outputType == 'XML_TOPICMAP_ZIP' || this.outputType == 'STORE_DOC_ZIP_PDF') {
                     console.log("validationSuccess false");
                       validationSuccess = false;
                  }
               } else {
                  var textValue = this.outputGenerateForm.get(f.name).value
                  configXMl += '<input name="' + f.name + '" confType="' + f.listname + '" >'
                     + this.decodeHTMLEntities(textValue) + '</input>';
               }
            }
         } else {
            var textValue = this.outputGenerateForm.get(f.name).value
            configXMl += '<input name="' + f.name + '" confType="' + f.listname + '" >'
               + this.decodeHTMLEntities(textValue) + '</input>';
         }
      })
      // read hidden input data
      if (this.invisibleItemConfigList.length > 0) {
         this.invisibleItemConfigList.forEach(f => {
            if ((f.name == 'breakPagesForFigure') || (f.name == 'moveFiguresToLeft') || (f.name == 'WaterMark')
               || (f.name == 'NCWROutputType') || (f.name == 'noPageBreaks') || (f.name == 'inReview') || (f.name == 'useAltDocNo')) {
               var booleanResponse = f.parameterValue;
               configXMl += '<input  name="' + f.name + '" confType="' + f.listname + '" >'
                  + booleanResponse + '</input>'
            } else if (f.name == "DocumentType") {
               var selectedValue = f.parameterValue
               configXMl += '<input name="' + f.name + '" confType="' + f.listname + '" >'
                  + selectedValue + '</input>';
            } else if (f.name == "facility") {
               var selectedFacilityValue = f.parameterValue
               configXMl += '<input name="' + f.name + '" confType="' + f.listname + '" >'
                  + selectedFacilityValue + '</input>';
            } else if (f.name == "system") {
               var selectedSystemValue = f.parameterValue
               configXMl += '<input name="' + f.name + '" confType="' + f.listname + '" >'
                  + selectedSystemValue + '</input>';
            } else {
               var textValue
               if(f.parameterValue != undefined){
                  textValue = f.parameterValue
               }else{
                  textValue = f.outputFileName
               }
               configXMl += '<input name="' + f.name + '" confType="' + f.listname + '" >'
                  + this.decodeHTMLEntities(textValue) + '</input>';
            }
         })
      } else {
         //nothing to do
      }
      configXMl += "</Config>";

      this.itemConfigList.forEach(f => {
         if (f.name == 'moveFiguresToLeft') {
            if (this.outputGenerateForm.get(f.name).value == true) {
               isMoveFiguresToLeft = 'yes'
            } else {
               isMoveFiguresToLeft = 'no'
            }
         } else if (f.name == 'breakPagesForFigure') {
            if (this.outputGenerateForm.get(f.name).value == true) {
               isBreakPagesForFigure = 'yes'
            } else {
               isBreakPagesForFigure = 'no'
            }
         } else if (f.name == 'WaterMark') {
            if (this.outputGenerateForm.get(f.name).value == true) {
               doPutWatermark = 'yes'
            } else {
               doPutWatermark = 'no'
            }
         } else {
            //mothing to do here
         }
      })

      //read hidden input data
      this.invisibleItemConfigList.forEach(f => {
         if (f.name == 'moveFiguresToLeft') {
            isMoveFiguresToLeft = f.parameterValue
         } else if (f.name == 'breakPagesForFigure') {
            isBreakPagesForFigure = f.parameterValue
         } else if (f.name == 'WaterMark') {
            if(f.parameterValue == 0){
               doPutWatermark = 'no'
            }else{
               doPutWatermark = 'yes'
            }
            
         } else {
            //mothing to do here
         }
      })

      if (this.outputGenerateForm.get("saveParameter").value == true) {
         saveDocValue = 'yes'
      } else {
         saveDocValue = 'no'
      }

      if (this.outputGenerateForm.get("draftDocument").value == true) {
         saveDraftDoc = 'yes'
      } else {
         saveDraftDoc = 'no'
      }


      var inputData = {
         "projectTemplateMapId": this.projectmapId,
         "templateId": this.templateId,
         "item": this.item,
         "outputType": this.outputType,
         "saveParameterValues": saveDocValue,
         "createDraftDocuments": saveDraftDoc,
         "moveFiguresToLeft": isMoveFiguresToLeft,
         "breakPagesForFigure": isBreakPagesForFigure,
         "watermark": doPutWatermark,
         "configXML": configXMl,
         "sendMail": this.sendMail
      }
      //Validation added
      if (validationSuccess) {
         if(this.itemIdList.length>0){
            if(this.item != null && this.item != "" && this.item != undefined){
               //check the item type call the generate output method.
               if(this.item == 'Mechanical Completion' || this.item == 'Onshore Turnover Notice' || this.item == 'CheckSheet' || this.item == 'Static Completion'){
                  this.generateMultipleOutput(inputData);
               } else{
                  this.generateOutPutItem(0, inputData,"")
               }
            } else{
               //do nothing
            }
         }

      } else {
         this.loadingService.hideLoading()
         this.alertService.error(this.translate.instant('OUTPUT_MANAGEMENT.FILL_REQUIRED_FIELDS_MESSAGE'));
      }
      
   }

   /**
    * Generate output for multiple items
    * @param inputData 
    */
   private generateMultipleOutput(inputData){
      let commItemList = [];
      for(let i = 0; i < this.itemIdList.length; i++){
         var inputDatanew = {
            "projectTemplateMapId": inputData.projectTemplateMapId,
            "templateId": inputData.templateId,
            "item": inputData.item,
            "outputType": inputData.outputType,
            "saveParameterValues": inputData.saveParameterValues,
            "createDraftDocuments": inputData.createDraftDocuments,
            "moveFiguresToLeft": inputData.moveFiguresToLeft,
            "breakPagesForFigure": inputData.breakPagesForFigure,
            "watermark": inputData.watermark,
            "configXML": inputData.configXML,
            "itemId": this.itemIdList[i],
            "sendMail": inputData.sendMail
         }
         commItemList.push(inputDatanew);
      }
      
      var data = {
         itemType: inputData.item,
         outPutList: commItemList
      }
      
      this.filesService.generateMultipleOutput(data)
      .subscribe(data => {
         this.loadingService.hideLoading()
         this.bsModalRef.hide()
         this.alertService.success("Successfully added the request(s) to the output queue.")
         if(inputData.item == "Mechanical Completion"){
            this.managemccService.updateUIafterMccGenerateRequest();
         } else if(inputData.item == "Onshore Turnover Notice"){
            this.manageotnService.updateUIafterOTNGenerateRequest();
         } else if(inputData.item == "CheckSheet"){
            this.issueChecksheetService.updateUIafterChecksheetGenerateRequest();
         } else if(inputData.item == "Static Completion"){
            this.managePCCService.updateUIAfterPCCGenerateRequest();
         } else{
            //do nothing
         }
      },
      error => {
         this.bsModalRef.hide()
         this.loadingService.hideLoading()
         this.alertService.clear()
         this.alertService.error(error.statusDescription)
      });
   }

   private generateOutPutItem(position, inputData ,dataVal){
      if(position<this.itemIdList.length){

         var inputDatanew = {
            "projectTemplateMapId": inputData.projectTemplateMapId,
            "templateId": inputData.templateId,
            "item": inputData.item,
            "outputType": inputData.outputType,
            "saveParameterValues": inputData.saveParameterValues,
            "createDraftDocuments": inputData.createDraftDocuments,
            "moveFiguresToLeft": inputData.moveFiguresToLeft,
            "breakPagesForFigure": inputData.breakPagesForFigure,
            "watermark": inputData.watermark,
            "configXML": inputData.configXML,
            "itemId": this.itemIdList[position],
            "sendMail": inputData.sendMail
         }
         this.filesService.generateOutput(inputDatanew)
             .subscribe(data => {
                    this.generateOutPutItem(position+1, inputData,data);
                 },
                 error => {
                    this.bsModalRef.hide()
                    this.loadingService.hideLoading()
                    this.alertService.clear()
                    this.alertService.error(error.statusDescription)
                 });
      }else {
         this.loadingService.hideLoading()
         this.bsModalRef.hide()
         this.alertService.success(dataVal.statusDescription)
      }
   }

   private decodeHTMLEntities(str: string) {
      if(str != null && str != undefined) {
         str = str.toString().replace(/&/g, "&amp;");
         str = str.toString().replace(/>/g, "&gt;");
         str = str.toString().replace(/</g, "&lt;");
         str = str.toString().replace(/\\/g, "\\\\");
         str = str.toString().replace(/\"/g, "&#34;");
         str = str.toString().replace(/'/g, "&apos;");
         
      }
      return str;
   }

   public selectedItem(value: any): void {
      console.log("item selected");
      if (value != undefined && value != null && value.id != undefined && value.id != null){

               this.outputGenerateForm.get("CoverPageHeaderFixedVal").setValue([{ id: value.id, text: value.text }]);

      }

   }

   public removedItem(event) {
      let defaultId = this.itemList[0].id;
      let defaultText = this.itemList[0].text;
      this.outputGenerateForm.get("CoverPageHeaderFixedVal").setValue([{ id: defaultId, text: defaultText }]);
   }

   public selectedLogo(value: any): void {
      if (value != undefined && value != null && value.id != undefined && value.id != null){

               this.outputGenerateForm.get("MultipleLogos").setValue([{ id: value.id, text: value.text}]);

      }

   }

   public removedLogo(event) {
      let defaultId = this.multipleLogoList[0].id;
      let defaultText = this.multipleLogoList[0].text;
      let defaultPath = this.multipleLogoList[0].path;
      this.outputGenerateForm.get("MultipleLogos").setValue([{ id: defaultId, text: defaultText}]);
   }

   public selectedFacility(value: any): void {
      console.log("facility selected");
      //reset selected system and get the system list for the selected facility
      if (value != undefined && value != null && value.id != undefined && value.id != null){
         this.updateSystemList(value.id, false);
      }
      else{

      }

   }

   public removedFacility(event) {
      //reset selected system and get the system list with no facility
      this.updateSystemList("0", false);
   }
   public updateSystemList(facilityId: string, init: boolean){
      this.loadingService.showLoading(true, null, this.loadingMessage, null);
      
      if (!init){
         if(this.outputGenerateForm.get("system") != null){
            this.outputGenerateForm.get("system").setValue([{ id: "", text: "" }]);
         }
      }

      var id = facilityId == '' ? "0" : facilityId;

      var token = UserVariable.getUserToken();
      this.documentStoreService.getSystemsByFacilityId(token, id).subscribe(
         systemData => {
            this.systemList = []
            this.systemList.push({ 'id': '', 'text': 'Select' })
            if (systemData.payload != null) {
               systemData.payload.forEach(element => {
                  this.systemList.push({ 'id': element.id, 'text': element.systemNo + ' ' + element.systemName })
               });
            } else {
               // do nothing
            }
            this.loadingService.hideLoading();
         },
         error => {
            this.alertService.clear()
            this.alertService.error(error.statusDescription)
         }
      );
   }
      

}
