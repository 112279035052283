import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ReceiveChecksheetService } from '../receivechecksheets.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DatePipe } from '@angular/common';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { SharedService } from 'src/app/util/shared.service';
import { AddpunchlistComponent } from 'src/app/commissioning/punchlist/addpunchlist/addpunchlist.component';
import { LocalStorage } from 'src/app/util/localstorage.service';
import { LoggedUser } from 'src/app/user/logged-user.model';
import { WsResponse } from 'src/app/util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';
import { LoadingService } from 'src/app/util/loading/loading.service';
import { AlertService } from 'src/app/util/alert/alert.service';
import { DecodeHtmlString } from 'src/app/util/common/decode-html-string.pipe';
import { UserVariable } from 'src/app/util/common/user-variable';
import { CommonUtil } from 'src/app/util/common/common-util';
import { PunchlistService } from 'src/app/commissioning/punchlist/punchlist.service';

@Component({
    selector: 'app-approvechecksheet',
    templateUrl: './approvechecksheet.component.html',
    styleUrls: ['./approvechecksheet.component.scss']
})
export class ApproveCheckSheetComponent implements OnInit {
    csInstanceId: string;
    checkSheetId: string;
    checkSheetInstanceData: Array<any> = [];
    punchlists: any = [];
    checkSheetCode: string;
    checkSheetDiscipline: string;
    checkSheetName: string;
    isUploaded: string;
    comments: string;
    companyRepDate: any;
    companyRepName: string;
    contracterRepDate: any;
    contracterRepName: string;
    dataSheet: string;
    datePrinted: string;
    drawingReference: string;
    equipmentDescription: string;
    formType: string;
    assetId: string;
    itemTagNo: string;
    location: string;
    MCCRToSCDBDate: any;
    MCCRToSCDBName: string;
    moduleDetail: string;
    phase: string;
    projectName: string;
    systemId: string;
    subSystemDescription: string;
    subSystemNo: string;
    systemNo: string;
    vendorName: string;
    status: string;
    comRepSignature: string;
    conRepSignature: string;
    public equipmentDescriptionJSON: any = {};
    public bsConfig: Partial<BsDatepickerConfig>
    isApproved: boolean = false;
    hide: boolean = false;
    public componentName: string = "ApproveCheckSheetComponent";
    selectedChecksheetCheck: any = null;
    public userFullName: string = "";
    public loggedInUser: LoggedUser;
    saveType: string;
    isNewPunchlistAdded: boolean = false;
    public csDocumentUpload: any = null;
    public projectId: string = null;
    @ViewChild("replaceFileConfirmModal") replaceFileConfirmModal: TemplateRef<any>;
    public fileReplaceModalRef: BsModalRef;
    fileUploadData: any;
    @ViewChild("removePunchlistConfirmModal") removePunchlistConfirmModal: TemplateRef<any>;
    public removePunchlistModalRef: BsModalRef;
    newPunchlists: any = [];
    isAlreadyUploaded: boolean = false;

    constructor(private translate: TranslateService, private receiveChecksheetService: ReceiveChecksheetService,
        private bsModalRef: BsModalRef, public bsModalRefPunch: BsModalRef, private datePipe: DatePipe,
        private sharedService: SharedService, private modalService: BsModalService, private loadingService: LoadingService,
        private alertService: AlertService, private decodeHTMLString: DecodeHtmlString, private commonUtil: CommonUtil,
        private punchlistService: PunchlistService) {
        this.loggedInUser = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER));
        this.userFullName = this.loggedInUser.firstName + " " + this.loggedInUser.lastName;
        this.bsConfig = Object.assign({}, { containerClass: 'theme-default' });
        translate.addLangs(["es", "en"]);
        translate.setDefaultLang("en");
        let browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
        this.projectId = UserVariable.projectId;
    }

    ngOnInit(): void {
        this.receiveChecksheetService.getChecksheetInstanceData(this.csInstanceId, this);
    }

    /**
     * Save the checksheet Data and change the status to Approved 
     */
    saveApproval() {
        this.saveType = "Approval";
        //validate inputs before send it to approval
        if (this.contracterRepName == "" || this.companyRepName == "" || this.contracterRepDate == null || this.companyRepDate == null) {
            this.alertService.error("Please fill all the fields");
        } else if (this.hasEmptyCheckInput()) {
            this.alertService.error("Please enter values for all the checks");
        } else {
            this.saveInstanceData();
        }
    }

    /**
     * Save the checksheet Data and change the status to DataSaved
     */
    saveLater() {
        this.saveType = "ForLater";
        this.saveInstanceData();
    }

    /**
     * Save the checksheet data
     */
    saveInstanceData() {
        //format the date values before saving
        var contracterRepDate = this.datePipe.transform(this.contracterRepDate, 'yyyy-MM-dd');
        var companyRepDate = this.datePipe.transform(this.companyRepDate, 'yyyy-MM-dd');
        var MCCRToSCDBDate = this.datePipe.transform(this.MCCRToSCDBDate, 'yyyy-MM-dd');
        let checkInputValues = new Map();
        this.checkSheetInstanceData.forEach(element => {
            checkInputValues.set(element.sequenceNum, element.checkInput);
        });
        //convert checkInputValues into json object
        let checkInputsJsonObject = {};
        checkInputValues.forEach((value, key) => {
            checkInputsJsonObject[key] = value
        });
        let data = {
            checkSheetInstanceId: this.csInstanceId,
            contracterRepName: this.contracterRepName,
            companyRepName: this.companyRepName,
            contracterRepDate: contracterRepDate,
            companyRepDate: companyRepDate,
            MCCRToSCDBName: this.MCCRToSCDBName,
            MCCRToSCDBDate: MCCRToSCDBDate,
            comments: this.comments,
            checkSheetInstanceData: checkInputsJsonObject,
            saveType: this.saveType
        }
        var json = JSON.stringify(data);
        console.log(json);
        this.loadingService.showLoading(true, false, this.translate.instant('SAVING'), 0);
        this.receiveChecksheetService.saveChecksheetInstanceData(json, this);
    }

    fileChanged(event){
        let i: number = 0;
        for (i = 0; i < event.target.files.length; i++) {
            let newFile = <File>event.target.files[i];
            if (newFile != undefined) {
                let parts = newFile.name.split('.')
                if (parts.length > 1) {
                    let fileExtension = parts.pop();
                    if (fileExtension == 'pdf') {
                        let fileName = parts.join('.');
                        fileName = fileName.trim();
                        if (this.validateFile(newFile)) {
                            //set for uploading to the local file system
                            var csDocumentUpload =
                            {
                                docName: fileName,
                                docType: fileExtension,
                                file: newFile,
                                isAdded: true,
                                isDeleted: false
                            };
                            this.csDocumentUpload = csDocumentUpload;
                        } else {
                            //file size is too large
                        }
                    } else {
                        //file is not pdf
                        this.alertService.clear();
                        this.translate.get('NEW_CHECKSHEET_DOCUMENT.INVALID_FILE_TYPE').subscribe((res: string) => {
                            this.alertService.error(res, false);
                        });

                    }
                } else {
                    this.translate.get('NEW_CHECKSHEET_DOCUMENT.INVALID_FILE_NAME').subscribe((res: string) => {
                        this.alertService.error(res, false);
                    });
                }
            } else {
                //file is not valid
                this.alertService.clear();
                this.translate.get('NEW_CHECKSHEET_DOCUMENT.INVALID_FILE').subscribe((res: string) => {
                    this.alertService.error(res, false);
                });
                this.alertService.error("invalid file");
            }
        }
    }

    onFileChanged(event) {
        this.fileUploadData = event;
        if(this.isUploaded == "true"){
            this.fileReplaceModalRef = this.modalService.show(this.replaceFileConfirmModal)
        } else{
           this.fileChanged(event);
        }
        this.fileChanged(event);
    }

    confirmedReplaceFile(){
        this.fileChanged(this.fileUploadData);
        this.fileReplaceModalRef.hide();
    }

    canceledFileUpload(){
        this.csDocumentUpload = null;
        this.fileReplaceModalRef.hide();
    }

    validateFile(file: File): boolean {
        if (file.size > 104857601) {
            this.translate.get('NEW_CHECKSHEET_DOCUMENT.SIZE_LIMIT_EXCEEDED').subscribe((res: string) => {
                this.alertService.error(res, false);
            });
            return false;
        } else {
            return true;
        }
    }

    clickUpload(){
        this.isAlreadyUploaded = false;
        this.onUpload(this.csInstanceId);
    }

    onUpload(csInstanceId: string) {
        var loadingMessage;

        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }

        if (this.csDocumentUpload && this.csDocumentUpload.isAdded && !this.csDocumentUpload.isDeleted) {
            this.translate.get('NEW_CHECKSHEET_DOCUMENT.LOADING_MESSAGE').subscribe((res: string) => {
                loadingMessage = res;
                this.loadingService.showLoading(true, null, loadingMessage, null);
            });
            
            let element = this.csDocumentUpload;
            const uploadData = new FormData();

            uploadData.append("csInstanceId", csInstanceId);
            uploadData.append("projectId", this.projectId);
            uploadData.append("filePath", '');
            uploadData.append("fileName", element.docName);
            uploadData.append("contentType", element.docType);
            uploadData.append("token", token);
            uploadData.append("file", element.file, element.docName);
            uploadData.append("addedUserId", '');
            uploadData.append("isAdded", element.isAdded);
            uploadData.append("isDeleted", element.isDeleted);
            this.receiveChecksheetService.uploadFile(uploadData, this);
        } else{
            this.alertService.clear()
            this.alertService.error("Please attach a file to upload")
        }
    }

    cancel() {
        if(this.newPunchlists.length > 0){
            this.removePunchlistModalRef = this.modalService.show(this.removePunchlistConfirmModal);
        } else{
            this.bsModalRef.hide();
        } 
    }

    confirmedRemovePunchlist(){
        let punchlistIds = "";
        for(let i = 0; i < this.newPunchlists.length; i++){
            if(i == this.newPunchlists.length - 1){
                punchlistIds += this.newPunchlists[i].id + ",";
            } else{
                punchlistIds += this.newPunchlists[i].id;
            }
        }
        this.punchlistService.removeChecksheetPunchlistRelation(punchlistIds, this);
    }

    canceledRemovePunchlist(){
        this.removePunchlistModalRef.hide();
    }

    /**
     * Validate checkInput values
     */
    hasEmptyCheckInput(): boolean {
        var isEmpty = false;
        this.checkSheetInstanceData.forEach(element => {
            if (element.checkInput == null && element.checkType !== "" && element.checkType !== null) {
                isEmpty = true;
            }
        });
        return isEmpty;
    }

    /**
     * Set values for the Enter Checksheet Data modal
     * @param payload 
     */
    loadData(payload: any) {
        let parser = new DOMParser();
        if (payload != null) {
            this.status = payload.status;
            this.assetId = payload.assetId;
            this.systemId = payload.systemId;
            this.checkSheetId = payload.checkSheetId;
            this.isUploaded = payload.isUploaded;
            this.comRepSignature = payload.companySignature;
            this.conRepSignature = payload.contractorSignature;
            if (this.status === 'Approved') {
                this.isApproved = true;
            } else {
                this.isApproved = false;
            }
            if (payload.headerInfoList != null) {
                this.checkSheetCode = payload.headerInfoList.CheckSheetCode;
                this.checkSheetDiscipline = payload.headerInfoList.CheckSheetDiscipline;
                this.checkSheetName = payload.headerInfoList.CheckSheetName;
                this.comments = payload.headerInfoList.Comments;
                if (payload.headerInfoList.CompanyRepDate != null && payload.headerInfoList.CompanyRepDate != "") {
                    if (!this.isApproved) {
                        this.companyRepDate = new Date(payload.headerInfoList.CompanyRepDate);
                    } else {
                        this.companyRepDate = this.datePipe.transform(payload.headerInfoList.CompanyRepDate, 'MM/dd/yyyy');
                    }
                } else {
                    this.companyRepDate = null;
                }
                this.companyRepName = payload.headerInfoList.CompanyRepName;
                if (payload.headerInfoList.ContracterRepDate != null && payload.headerInfoList.ContracterRepDate != "") {
                    if (!this.isApproved) {
                        this.contracterRepDate = new Date(payload.headerInfoList.ContracterRepDate);
                    } else {
                        this.contracterRepDate = this.datePipe.transform(payload.headerInfoList.ContracterRepDate, 'MM/dd/yyyy');
                    }
                } else {
                    this.contracterRepDate = null;
                }
                this.contracterRepName = payload.headerInfoList.ContracterRepName;
                this.dataSheet = payload.headerInfoList.DataSheet;
                this.datePrinted = payload.headerInfoList.DatePrinted;
                if (this.datePrinted) {
                    this.datePrinted = this.datePipe.transform(this.datePrinted, 'MM/dd/yyyy hh:mm:ss');
                }
                this.drawingReference = payload.headerInfoList.DrawingReference;
                if (payload.headerInfoList.EquipmentDescription != null && payload.headerInfoList.EquipmentDescription != "" && payload.headerInfoList.EquipmentDescription != "NA") {
                    let desXml = parser.parseFromString(payload.headerInfoList.EquipmentDescription, "text/xml");
                    this.equipmentDescription = desXml.getElementsByTagName("txt")[0].childNodes[0].nodeValue;
                } else {
                    this.equipmentDescription = payload.headerInfoList.EquipmentDescription;
                }
                this.formType = payload.headerInfoList.FormType;
                this.itemTagNo = payload.headerInfoList.ItemTagNo;
                this.location = payload.headerInfoList.Location;
                if (payload.headerInfoList.MCCRToSCDBDate != null && payload.headerInfoList.MCCRToSCDBDate != "") {
                    this.MCCRToSCDBDate = this.datePipe.transform(payload.headerInfoList.MCCRToSCDBDate, 'MM/dd/yyyy');
                } else {
                    this.MCCRToSCDBDate = this.datePipe.transform(Date.now(), 'MM/dd/yyyy');
                }
                if (payload.headerInfoList.MCCRToSCDBName != null && payload.headerInfoList.MCCRToSCDBName != "") {
                    this.MCCRToSCDBName = payload.headerInfoList.MCCRToSCDBName;
                } else {
                    this.MCCRToSCDBName = this.userFullName;
                }
                this.moduleDetail = payload.headerInfoList.ModuleDetail;
                this.phase = payload.headerInfoList.Phase;
                this.projectName = payload.headerInfoList.ProjectName;
                this.subSystemDescription = payload.headerInfoList.SubSystemDescription;
                this.subSystemNo = payload.headerInfoList.SubSystemNo;
                this.systemNo = payload.headerInfoList.SystemNo;
                this.vendorName = payload.headerInfoList.VendorName;
            }
        } else {
            //do nothing
        }

        if (payload.checkSheetInstanceData != null) {
            this.checkSheetInstanceData = payload.checkSheetInstanceData;
            this.checkSheetInstanceData.forEach(element => {
                    element.checkedOK = false;
                    element.checkedNA = false;
                    element.checkedPL = false;
                if (element.checkInput != null && element.checkInput != "") {
                    if (element.checkInput == "OK") {
                        element.checkedOK = true;
                    } else if (element.checkInput == "NA") {
                        element.checkedNA = true;
                    } else if (element.checkInput == "PL") {
                        element.checkedPL = true;
                    } else {
                        //do nothing
                    }
                } else {
                    //do nothing
                }

                if (element.checkDescription != null) {
                    element.checkDescription = this.decodeHTMLString.transform(element.checkDescription);
                } else {
                    //do nothing
                }
            });
        } else {
            //do nothing
        }

        if (payload.punchlists != null) {
            this.punchlists = payload.punchlists;
            this.isCheckItemHasPunchlist();
        } else {
            //do nothing
        }
    }

    /**
     * Fire this event when the checkbox is checked
     * @param data 
     * @param instanceData 
     * @param type 
     */
    checkboxChecked(data: any, instanceData: any, type: string) {
        var checkboxValue = data.currentTarget.checked
        if (checkboxValue == undefined) {
            checkboxValue = false;
        }
        this.selectedChecksheetCheck = instanceData;
        this.checkUncheckCheckboxValue(checkboxValue, type);
    }

    /**
     * Change checkbox value
     * @param checkboxValue 
     * @param type 
     */
    checkUncheckCheckboxValue(checkboxValue: boolean, type: string) {
        this.checkSheetInstanceData.forEach(element => {
            if (element.checkSheetCheckId == this.selectedChecksheetCheck.checkSheetCheckId) {
                if (checkboxValue) {
                    element.checkInput = type;
                } else {
                    element.checkInput = null;
                }
                switch (type) {
                    case "OK":
                        element.checkedOK = checkboxValue;
                        element.checkedNA = false;
                        element.checkedPL = false;
                        break;
                    case "NA":
                        element.checkedOK = false;
                        element.checkedNA = checkboxValue;
                        element.checkedPL = false;
                        break;
                    case "PL":
                        element.checkedOK = false;
                        element.checkedNA = false;
                        element.checkedPL = checkboxValue;
                        break;
                    default:
                        break;
                }
                //navigate to the Add Punchlist when the P/L checkbox is checked
                if (element.checkInput == "PL" && element.checkedPL) {
                    this.addPunchList()
                } else {
                    //do nothing
                }
                return;
            } else {
                //do nothing
            }
        });
    }

    /**
     * Check whether the check item(s) has punchlist and disable the checkbox accordingly
     */
    isCheckItemHasPunchlist() {
        this.punchlists.forEach(elementPL => {
            this.checkSheetInstanceData.forEach(elementCI => {
                if (elementCI.checkSheetCheckId == elementPL.checkSheetCheckId) {
                    elementCI.isDisabled = true;
                    elementCI.checkedPL = true;
                }
            });
        });
    }

    /**
     * Navigate to the Add Punchlist
     */
    addPunchList() {
        this.isNewPunchlistAdded = false;
        this.sharedService.setParent(this);
        this.bsModalRefPunch = this.modalService.show(AddpunchlistComponent, { class: 'modal-xl', backdrop: 'static', keyboard: false });
        this.hide = true;
        this.bsModalRefPunch.content.onClose.subscribe(result => {
            this.hide = false;
            //uncheck the P/L checkbox value when user closes the Add Punchlist pop-up without adding a punchlist
            if (!this.isNewPunchlistAdded) {
                this.checkUncheckCheckboxValue(false, "PL");
            }
            var evObj = document.createEvent('Events');
            evObj.initEvent("click", true, false);
            document.getElementById("approveChecksheet").dispatchEvent(evObj);
        });
    }

    onSuccess(data: WsResponse, serviceType: WsType) {
        this.loadingService.hideLoading();
        if (serviceType == WsType.GET_CHECKSHEET_INSTANCE_DATA) {
            if (data != null) {
                this.loadData(data.payload);
            } else {
                //do nothing
            }
        } else if (serviceType == WsType.GET_PUNCHLIST_DATA_TO_CHECKSHEET) {
            if (data.payload != null) {
                this.sharedService.setParent(null);
                this.isNewPunchlistAdded = true; //this is true when the new punchlist is added
                let punchlist = {
                    id: data.payload.punchlistId,
                    punchlistDescription: data.payload.punchlistDes,
                    category: data.payload.punchlistCategory,
                    checkSheetCheckId: data.payload.checkSheetCheckId
                }
                this.punchlists.push(punchlist);
                this.newPunchlists.push(punchlist);
                this.isCheckItemHasPunchlist();
            } else {
                //do nothing
            }
        } else if (serviceType == WsType.SAVE_CHECKSHEET_INSTANCE_DATA) {
            if (data.payload != null) {
                if(this.saveType == "ForLater"){
                    this.alertService.success("Successfully saved the checksheet data");
                } else if(this.saveType == "Approval"){
                    this.alertService.success("Successfully saved the checksheet data and it's approved");
                } else{
                    //do nothing
                }

                if(this.csDocumentUpload != null && !this.isAlreadyUploaded){
                    this.onUpload(this.csInstanceId);
                } else{
                    this.bsModalRef.hide();
                }
            } else {
                //do nothing
            }
        } else if(serviceType == WsType.ADD_NEW_CS_DOCUMENT){
            if(data.payload){
                this.isAlreadyUploaded = true;
                this.alertService.success("Successfully uploaded the file");
                if(this.saveType == "Approval" || this.saveType == "ForLater"){
                    this.bsModalRef.hide();
                } else if(this.isApproved){ //enble the fields for editing when the user uploads a document when the checksheet is approved
                    this.isApproved = false;
                } else{
                    //do nothing
                }
            } else{
                //do nothing
            }
        } else if(serviceType == WsType.REMOVE_CS_RELATION_FROM_PUNCHLIST){
            if(data.payload){
                this.alertService.success("Successfully removed the relation from punchlist");
                this.removePunchlistModalRef.hide();
                this.bsModalRef.hide();
            } else{
                //do nothing
            }
        }
    }

    onFail(data: WsResponse, serviceType: WsType) {
        this.loadingService.hideLoading();
        if (serviceType == WsType.GET_CHECKSHEET_INSTANCE_DATA) {
            this.alertService.error(data.statusDescription);
        } else if (serviceType == WsType.GET_PUNCHLIST_DATA_TO_CHECKSHEET) {
            this.alertService.error(data.statusDescription);
        } else if (serviceType == WsType.SAVE_CHECKSHEET_INSTANCE_DATA) {
            this.alertService.error(data.statusDescription);
        } else if(serviceType == WsType.ADD_NEW_CS_DOCUMENT){
            this.alertService.error(data.statusDescription);
        } else if(serviceType == WsType.REMOVE_CS_RELATION_FROM_PUNCHLIST){
            this.alertService.error(data.statusDescription);
        }
    }
}

