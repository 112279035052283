
<div class="main-panel pt-0 pb-4" style="overflow: hidden;">
	<div class="container-fluid">
		<div class="col-sm-12 px-0">
			<h3>
				{{ 'DOCUMENT_STORE.DS_SIDEBAR_DOCUMENT_STORE' | translate }}
			</h3>
			<hr class="mt-0 mb-3" />
		</div>
		<div class="col-sm-12 px-0">
			<form>
				<div class="form-group">
					<label class="mb-0">{{ 'DOCUMENT_STORE.FACILITY' | translate }} :</label>
					<ng-select  [active]="selectedFacility" [allowClear]="true"
						(removed)="removedFacility($event)" (selected)="selectedFacilityId($event)"
						[items]="facilityList" name="Facility" id="facility">
					</ng-select>
				</div>
			</form>
		</div>
		<hr class="mt-0 mb-3" />
		<div class="col-sm-12 px-0">
			<ag-grid-angular #agGrid style="width: 100%; height: 400px;" id="procedureStore" [rowData]="rowData"  [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }"
				class="ag-theme-balham" [columnDefs]="columnDefs" [frameworkComponents]="frameworkComponents"
				[enableRangeSelection]="true" [paginationPageSize]="10" [defaultColDef]="defaultColDef" accentedSort = true
				[pagination]="true" (columnResized)="onColumnResized($event)" (gridReady)="onGridReady($event)"
				[suppressMenuHide]="true" [gridOptions]="gridOptions" [enableFilter]="true" [floatingFilter]="true"></ag-grid-angular>
		</div>
	</div>
</div>
