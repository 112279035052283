<!--Alert-->
<alert class="floating-alert"></alert>

<!-- header -->
<nav id="navPanel" class="bg-light border-bottom box-shadow fixed-top navbar-expand-lg" #navPanel>
	<app-header [minimalOption]="true"></app-header>
</nav>

<div class="container-fluid">
	<main role="main" class="col-md-12 px-0" #main [style.padding-top.px]="paddingtop">
		<!-- title and tag classification button -->
		<div class="col-sm-12 px-0 align-self-end">
			<div class="row align-items-center">
				<div class="col-sm-8">
					<h4 class="" id="classification_title">
						Manage Attributes
					</h4>
				</div>
				<div class="col-sm-4">
					<!-- manage attribute button -->
					<button type="button" class="btn btn-sm btn-primary float-right"
						(click)="tagClassificationClick()">Tag Classification
					</button>
					<button type="button" class="btn btn-sm btn-primary float-right mx-2"
						(click)="addTagAttribute(template)">Add Attribute
					</button>
				</div>
			</div>
			<hr class="mt-0 mb-3" />
		</div>
		<div class="row">
			<div class="col-sm-12">
				<ag-grid-angular style="width: 100%; height: 410px;" class="ag-theme-balham" [rowData]="rowData" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }"
					[gridOptions]="gridOptions" [columnDefs]="columnDefs" [enableRangeSelection]="true" [pagination]="true" 
					[paginationPageSize]="10" [overlayLoadingTemplate]="overlayLoadingTemplate" [overlayNoRowsTemplate]="overlayNoRowsTemplate"
					(gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents" [suppressCellSelection]="true"
					[rowDragManaged]="false" [animateRows]="true" >
				</ag-grid-angular>				
			</div>
		</div>
	</main>
</div>

<!-- <div>
	<div class="row">
		<button type="button" class="btn btn-warning" (click)="addTagAttribute(template)">Add</button>
	</div>
	<div class="row">
		<ag-grid-angular style="width: 100%; height: 250px;" class="ag-theme-balham" [rowData]="rowData"  [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }"
			[gridOptions]="gridOptions" [columnDefs]="columnDefs" [enableRangeSelection]="true"
			[frameworkComponents]="frameworkComponents" [rowDragManaged]="true" [animateRows]="true"></ag-grid-angular>
	</div>
</div> -->

<!--Tag Attribute Add Modal-->
<ng-template #template>
	<div class="modal-header">
		<h5 class="modal-title">Tag Attributes</h5>
		<button type="button" class="close" aria-label="Close" (click)="bsModalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<form>
			<div class="form-group">
				<label for="classificationName">Attribute Name : </label><div class="badge-required mx-2"></div>
				<input type="text" class="form-control" id="tagAttributeName" name="tagAttributeName"
					placeholder="Attribute Name" [(ngModel)]="newTagAttributeName">
			</div>
			<div class="form-group">
				<label for="classificationDescription">Default Type : </label><div class="badge-required mx-2"></div>
				<!--Item select input-->
				<ng-select [items]="tagAttributeTypeList" id="tagAttributeSelect" [options]="gridOptions"
					name="tagAttributeSelect" bindLabel="text" autofocus bindValue="id"
					[(ngModel)]="selectedTagAttribute">
				</ng-select>
			</div>
		</form>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary btn-sm" (click)="addButtonClick()">Add</button>
		<button type="button" class="btn btn-secondary btn-sm" (click)="bsModalRef.hide()">Cancel</button>
	</div>
</ng-template>

<!--Tag Attribute Update Modal-->
<ng-template #update_template>
	<div class="modal-header">
		<h5 class="modal-title">Tag Attributes</h5>
		<button type="button" class="close" aria-label="Close" (click)="bsModalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="form-group">
			<label for="classificationName">Attribute Name : </label><div class="badge-required mx-2"></div>
			<input type="text" class="form-control" id="tagAttributeName" name="tagAttributeName" placeholder=""
				[(ngModel)]="newTagAttributeName">
		</div>
		<div class="form-group">
			<label for="classificationDescription">Default Type : </label><div class="badge-required mx-2"></div>
			<!--Item select input-->
			<ng-select [items]="tagAttributeTypeList" id="tagAttributeSelect" [options]="gridOptions"
				name="tagAttributeSelect" bindLabel="text" autofocus bindValue="id" (ngModelChange)="onChange($event)" [(ngModel)]="selectedTagAttribute">
			</ng-select>
		</div>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary btn-sm" (click)="updateButtonClick()">Submit</button>
		<button type="button" class="btn btn-secondary btn-sm" (click)="bsModalRef.hide()">Cancel</button>
	</div>
</ng-template>