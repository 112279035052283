import { OnInit, Component } from '@angular/core';
import { ManageWorkflowTemplatesService } from './manage-workflow-templates.service';
import { TranslateService } from '@ngx-translate/core';
import { GridOptions, ColDef } from '@ag-grid-enterprise/all-modules';
import { WorkflowTemplatesCellRendererComponent } from './workflow-templates-cell-renderer/workflow-templates-cell-renderer.component';
import { WsType } from 'src/app/util/ws-type';
import { WsResponse } from 'src/app/util/ws-response.model';
import { AlertService } from 'src/app/util/alert/alert.service';
import { SharedService } from 'src/app/util/shared.service';

@Component({
    selector: 'app-manage-workflow-templates',
    templateUrl: './manage-workflow-templates.component.html'
  })

export class ManageWorkflowTemplatesComponent implements OnInit{
    public columnDefs: any;
    public defaultColDef: any;
    public rowData: any;
    private gridApi;
    private gridColumnApi
    public gridOptions: GridOptions;
    public overlayLoadingTemplate: any;
    public overlayNoRowsTemplate: any;
    public getRowNodeId:any;

    private dependencies: string;
    private fileName: string;
    private itemType: string;
    private version: string;
    private status: string;
    private edit: string;
    private delete: string;


    constructor(private manageWorkflowTemplatesService: ManageWorkflowTemplatesService, private translate: TranslateService,
        private alertService: AlertService, private sharedService: SharedService){
        translate.addLangs(["es", "en"]);
        translate.setDefaultLang("en");
        let browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
        this.translate.get('WF_TEMPLATES.DEPENDENCIES').subscribe((res: string) => {
            this.dependencies = res;
            this.translate.get('WF_TEMPLATES.WORKFLOW_NAME').subscribe((res: string) => {
                this.fileName = res;
                this.translate.get('WF_TEMPLATES.ITEM_TYPE').subscribe((res: string) => {
                    this.itemType = res;
                    this.translate.get('WF_TEMPLATES.VERSION').subscribe((res: string) => {
                        this.version = res;
                        this.translate.get('WF_TEMPLATES.STATUS').subscribe((res: string) => {
                            this.status = res;
                            this.translate.get('WF_TEMPLATES.EDIT').subscribe((res: string) => {
                                this.edit = res;
                                this.translate.get('WF_TEMPLATES.DELETE').subscribe((res: string) => {
                                    this.delete = res;
                                });
                            });
                        });
                    });
                });
            });
        });

        this.columnDefs = [
            {
                headerName: this.dependencies,
                field: 'dependencies',
                width: 80, minWidth: 80, maxWidth: 80,
                cellRendererFramework: WorkflowTemplatesCellRendererComponent
            },
            {
                headerName: this.fileName,
                field: 'fileName',
                width:40  
            },
            {
                headerName: this.itemType,
                field: 'itemType',
                width:40
            },
            {
                headerName: this.version,
                field: 'version',
                width: 120, minWidth: 120, maxWidth: 120
            },
            {
                headerName: this.status,
                field: 'status',
                width: 80, minWidth: 80, maxWidth: 80,
                cellRendererFramework: WorkflowTemplatesCellRendererComponent
            },
            {
                headerName: this.edit,
                field: 'edit',
                width: 80, minWidth: 80, maxWidth: 80,
                cellRendererFramework: WorkflowTemplatesCellRendererComponent
            },
            {
                headerName: this.delete,
                field: 'delete',
                width: 80, minWidth: 80, maxWidth: 80,
                cellRendererFramework: WorkflowTemplatesCellRendererComponent
            }
        ]

        this.getRowNodeId = function (data) {
            return data.id;
        };
        
        this.gridOptions = {
            rowData: this.rowData,
            columnDefs: this.columnDefs,
            rowSelection: "single",
            rowMultiSelectWithClick: false
        };
        this.defaultColDef = {
			resizable: true,
			sortable: true,
		};
        //custom layout templates for loading and empty data sets
        this.overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Loading data...</span>';
        this.overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">No rows to show</span>';
    }

    ngOnInit(): void {
       this.manageWorkflowTemplatesService.getAllWorkflowTemplates(this);
    }

    onGridReady(params) {
        this.gridColumnApi = params.columnApi;
        this.gridApi = params.api;
        params.api.sizeColumnsToFit();
        // window.addEventListener("resize", function () {
        //     setTimeout(function () {
        //         params.api.sizeColumnsToFit();
        //     });
        // });
        window.addEventListener("resize", this.onWindowResize);
    }

    private onWindowResize = () => {
   
		setTimeout(() => {
			if (this.gridApi) {
				this.gridApi.sizeColumnsToFit();
			}
		}); 
	};

     ngOnDestroy(){
        window.removeEventListener("resize", this.onWindowResize);
        this.gridApi.destroy();
        this.gridApi = null;
     }

    loadData(data: any) {
        this.rowData = [];
        for (let i = 0; i < data.length; i++) {
            this.rowData.push({
                referenceCount: data[i]["referenceCount"],
                id: data[i]["id"],
                dependencies: data[i]["hasDependencies"] ? "dependencies:" + data[i]["id"] : "disable_dependencies:" + data[i]["id"],
                fileName: data[i]["fileName"],
                itemType: data[i]["itemType"],
                version: data[i]["version"],
                status: "status:" + data[i]["id"] + ":" + data[i]["status"],
                edit: "edit:" + data[i]["id"] + ":" + data[i]["status"],
                delete: "delete:" + data[i]["id"] + ":" + data[i]["status"]
            })
        }
        console.log("load" + this.rowData.length)
    }    

    onSuccess(response: WsResponse, type: WsType, ){
        if(type == WsType.GET_ALL_WORKFLOW_TEMPLATES){
            if(response){
                this.loadData(response.payload);
            }
        }
    }
    onFail(response: WsResponse, type: WsType){
        if(type == WsType.GET_ALL_WORKFLOW_TEMPLATES){
            this.alertService.error(response.statusDescription);  
        }
    }
}