<div class="page-container with-right-panel ">
  <nav class="page-header">
    <div class="row">
      <div class="col mobile-hide">
        <p class="mb-2 h5">
          <b>Manage roles</b>
        </p>
      </div>
      <div class="col">
        <button type="submit" *ngIf="createRole" id="new_role" class="btn  btn-custom lg float-right add-role " 
        (click)="addRoleButtonClick(addtemplate)">Add new role</button>
        <button type="submit" *ngIf="assignUsersToRoles" id="assign_user" [routerLink]="['assignuser']"
          class="btn  btn-custom lg float-right mr-2" (click)="pageToggle()">Assign users</button>

      </div>
    </div>
  </nav>
  <div class="container role-list-wrapper pl-0">
      <ag-grid-angular #agGrid style="width: 100%; height: 375px;" class="ag-theme-balham" [rowData]="rowData" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }"
      [columnDefs]="columnDefs" [gridOptions]="gridOptions" (rowClicked)="onRowClick($event)" [pagination]="true" [paginationPageSize]="10"
      [overlayLoadingTemplate]="overlayLoadingTemplate" [overlayNoRowsTemplate]="overlayNoRowsTemplate"
      [getRowNodeId]="getRowNodeId" (gridReady)="onGridReady($event)" [suppressCellSelection]="true" [routerLink]="['capabilities']" >
      </ag-grid-angular>
  </div>

  <ng-template #addtemplate>
    <div class="modal-header">
      <h5 class="modal-title">
        {{ 'ROLES.ADD_NEW_ROLE' | translate }}
      </h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <form ngNativeValidate>
      <div class="modal-body">
        
          <div class="form-group">
            <label for="roleName">
              {{ 'ROLES.ROLE_NAME' | translate }}
            </label>
            <div class="badge-required mx-2"></div>
            <input type="text" class="form-control" id="roleName" name="roleName" placeholder=""
              [(ngModel)]="newRoleName" required/>
          </div>
          <div class="form-group">
            <label for="roleDescription">
              {{ 'ROLES.ROLE_DESCRIPTION' | translate }}
            </label>
            <input type="text" class="form-control" id="roleDescription" name="roleDescription"
              placeholder="" [(ngModel)]="newRoleDescription"/>
          </div>
          <div class="col-sm-6">
            <label for="status"> {{ 'ROLES.ROLE_STATUS' | translate }}</label>
            <div class="badge-required mx-2"></div>
            <div class="clearfix">
              <div class="radio-group">
                <input type="radio" name="addrb" id="rb3" value="Active"/>
                <label for="rb3">Active</label>
                <input type="radio" name="addrb" id="rb4" value="Inactive"/>
                <label for="rb4">Inactive</label>
              </div>
            </div>
          </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary btn-sm" (click)="saveButtonClick()">
          {{ 'ROLES.BTN_TXT_SAVE' | translate }}
        </button>
        <button type="button" formnovalidate class="btn btn-secondary btn-sm" data-dismiss="modal"
          (click)="bsModalRef.hide()">
          {{ 'ROLES.BTN_TXT_CANCEL' | translate }}
        </button>
      </div>
    </form>	
  </ng-template>

  <aside class="right-slide-panel">
    <div class="right-slide-panel-wrapper">
      <router-outlet></router-outlet>

    </div>
  </aside>
</div>