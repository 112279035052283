<div *ngIf="!disable">
    <button id="landing-table-buttons" class="btn btn-sm btn-outline-dark py-0 my-1 px-1 border-0"
        (click)="invoke(type, data)">
        <i class="{{icon}}" aria-hidden="true"></i>
    </button>
</div>
<div *ngIf="disable">
    <a class="btn btn-sm btn-outline-dark py-0 my-1 px-1 border-0" (click)="invoke(type, data)">
        <i class="text-black-50 {{icon}}" aria-hidden="true"></i>
    </a>
</div>

<ng-template #confirmation_warn>
    <div class="modal-header">
        <h4 *ngIf="type == 'delete'" class="modal-title pull-left">{{'CS_CELL_RENDER.DELETE_CONFIRM' | translate}}</h4>
        <h4 *ngIf="type == 'edit'" class="modal-title pull-left">{{'CS_CELL_RENDER.EDIT_WARN_CONFIRM' | translate}}</h4>
        <h4 *ngIf="type == 'deleteAsset'" class="modal-title pull-left">{{'CS_CELL_RENDER.DELETE_CONFIRM' | translate}}
        </h4>
    </div>
    <div *ngIf="displayInsWarn" class="modal-body pb-0">
        <div>
            <h5>{{warningMsg}}</h5>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" id="warn_confirm" class="btn btn-primary"
            (click)="warnModalRef.hide(); warningConfirm();">
            {{ 'OK' | translate}} </button>
        <button type="button" id="warn_cancel" class="btn btn-secondary" data-dismiss="modal"
            (click)="warnModalRef.hide()">
            {{ 'CANCEL' | translate}} </button>
    </div>
</ng-template>

<ng-template #assetDelete_warn>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{ 'CS_ASSIGN_CHECKS.DELETE' | translate}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="warnModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">{{ 'CS_CELL_RENDER.DELETE_ASSET_CONFIRM' | translate}}</div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="warnModalRef.hide()">{{ 'OK' | translate}}</button>
    </div>
</ng-template>