import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { OutputManagementService } from '../outputmanagement.service';
import { UserVariable } from '../../../util/common/user-variable';
import { AlertService } from '../../../util/alert/alert.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from '../../../util/loading/loading.service';

@Component({
   selector: 'editoutputcnfig-modal',
   template: `
   <div class="modal-header">
   <h4 class="modal-title pull-left">{{ title | translate }}</h4>
   <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
   </button>
   </div>
   <div class="modal-body">
   <div class="container">
      <h6>
         {{ bodyText | translate }}
      </h6>
   </div>
   </div>
   <div class="modal-footer">      
      <button type="button" class="btn btn-primary" (click)="bsModalRef.hide()">{{ 'CANCEL' | translate}}</button>
   </div>
`
})
export class GetMoreOutputInfoModalComponent {
   public title: string = "More Info";
   om_translated_data: any = null;
   public bodyText: String;

   constructor(public bsModalRef: BsModalRef, private outputManagementService: OutputManagementService, private alertService: AlertService, private router: Router,
      private translate: TranslateService, private loadingService: LoadingService) { }

   ngOnInit() {

   }

 
}