import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from 'src/app/util/shared.service';
import { Constants } from 'src/app/util/constants';
import { DependenciesmodalComponent } from 'src/app/util/dependenciesmodal/dependenciesmodal.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
	selector: 'app-cell-render',
	templateUrl: './workflow-templates-cell-renderer.component.html'
})

export class WorkflowTemplatesCellRendererComponent{
    icon: string;
	type: string;
	disable: boolean = false;
	bsModalRef: BsModalRef;
	title: string;
	dependencyType: string;
	data: string = "";
	status: string = "";
	refCount: number = 0;
    
    constructor(private translate: TranslateService, private sharedService: SharedService, private modalService: BsModalService){

    }

    agInit(params: any): void {
		let items = params.value.split(':')
		this.refCount = params.data.referenceCount;
		if (items.length >= 2) {
			this.data = items[1];
			if (items.length == 3) {
				this.status = items[2];
			}
		}
		this.setType(items[0])
		if (this.type == 'dependencies') {
            this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.VIEW;
			this.translate.get('WORKFLOW_CELL_RENDER.DEPENDENCIES_LIST').subscribe((res: string) => {
				this.title = res;
			});
		} else if (this.type == 'status') {
			if (this.status == "0") {
                this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.ENABLE;
				this.translate.get('WORKFLOW_CELL_RENDER.ENABLE_CONFIRM').subscribe((res: string) => {
					this.title = res;
				});
			} else {
                this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.DISABLE;
				this.translate.get('WORKFLOW_CELL_RENDER.DISABLE_CONFIRM').subscribe((res: string) => {
					this.title = res;
				});
			}
		} else if (this.type == 'delete') {
            this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.DELETE;
			this.translate.get('WORKFLOW_CELL_RENDER.DELETE_CONFLICTS').subscribe((res: string) => {
				this.title = res;
			});
		} else if (this.type == 'edit') {
            this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.EDIT;
			this.translate.get('WORKFLOW_CELL_RENDER.EDIT_CONFIRM').subscribe((res: string) => {
				this.title = res;
			});
		}
		this.sharedService.disableCellRendererExecute().subscribe(option => this.setDisable(option));
	}

	viewDependencies(dflag: boolean) {
		this.status = this.status == "0" ? 'Inactive' : 'Active';
		this.sharedService.setStatus(this.status);
		this.dependenciesModalWithComponent(this.title, dflag, true);
	}

	dependenciesModalWithComponent(header: String, dflag: boolean, cflag: boolean) {
		const initialState : object = {
			headerTitle: header,
			delFlag: dflag,
			cancelFlag: cflag,
			checkboxVal: this.status == "Inactive" ? true : false
		};
		
		this.sharedService.setFileId(this.data);
		this.sharedService.setFileType(Constants.DEPENDENCY_ITEM_TYPE.WORKFLOW);
        this.sharedService.setAction("getDependencies");
		this.sharedService.setDependencyMessageType(this.dependencyType);
		this.sharedService.setParent(this);
		this.bsModalRef = this.modalService.show(DependenciesmodalComponent, { initialState, class: 'modal-lg' });
	} 

    public invoke(type: String, data: any) {
		switch (type) {
			case 'dependencies':
				localStorage.setItem("isDelete", "false");
				this.viewDependencies(false);
				break;
			case 'status':
				localStorage.setItem("isDelete", "false");
				localStorage.setItem("isEdit", "false");
				this.viewDependencies(false);
				break;
			case 'edit':
				localStorage.setItem("isDelete", "false");
				localStorage.setItem("isEdit", "true");
				this.viewDependencies(false);
				break;
			case 'delete':
				localStorage.setItem("isDelete", "true");
				localStorage.setItem("isEdit", "false");
				this.viewDependencies(true);
				break;
			default:
				break;
		}
	}
    
	private setType(params) {
		this.type = params;
		if (params == 'status') {
			if (this.status == "0") {
				this.disable = true;
				this.icon = 'far fa-times-circle';
			} else {
				this.icon = 'far fa-check-circle';
			}
		} else if (params == "disable_dependencies") {
			if (this.refCount > 0) {
				this.disable = false;
				this.icon = 'far fa-link';
			}else{
				this.disable = true;
				this.icon = 'text-black-50 far fa-link';
			}
		} else {
			this.icon = params == 'dependencies' ? 'far fa-link' :
				params == 'edit' ? "far fa-edit" :
					params == 'delete' ? 'far fa-trash-alt' : "";
		}
	}

	private setDisable(option: any) {
		let items = this.data.split(",")
		if (option != null && option.id == items[0] && this.type == 'status') {
			this.disable = option.data;
			this.icon = this.disable ? 'far fa-times-circle' : 'far fa-check-circle';
			this.status = this.disable ? 'Inactive' : 'Active';
			if (this.status == "Inactive") {
                this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.ENABLE;
				this.translate.get('WORKFLOW_CELL_RENDER.ENABLE_CONFIRM').subscribe((res: string) => {
					this.title = res;
				});
			} else {
                this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.DISABLE;
				this.translate.get('WORKFLOW_CELL_RENDER.DISABLE_CONFIRM').subscribe((res: string) => {
					this.title = res;
				});
			}
		}
    }
}