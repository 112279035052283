import { AllModules, GridOptions, Module } from '@ag-grid-enterprise/all-modules';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AlertService } from 'src/app/util/alert/alert.service';
import { LoadingService } from 'src/app/util/loading/loading.service';
import { AddAdhocReportComponent } from './add-adhoc-report/add-adhoc-report.component';
import { ManageAdhocReportDeleteCellComponent } from './cell-render/delete-cell.component';
import { ManageAdhocReportsService } from './manage-adhoc-reports.service';

@Component({
    selector: 'app-manage-adhoc-reports',
    templateUrl: './manage-adhoc-reports.component.html',
    styleUrls: ['./manage-adhoc-reports.component.css']
})
export class ManageAdhocReportsComponent implements OnInit {

    public gridOptions: GridOptions;
    public defaultColDef: any;
    public rowData: any[] = []
    public columnDefs: any;
    public modules: Module[] = AllModules;
    public bsModalRef: BsModalRef;
    public overlayLoadingTemplate: any;
    public overlayNoRowsTemplate: any;

    private gridApi;
    private gridColumnApi;
    private adhocreports_translated_data: any = null;

    constructor(
        private translate: TranslateService,
        private alertService: AlertService,
        private loadingService: LoadingService,
        private manageAdhocReportsService: ManageAdhocReportsService,
        private modalService: BsModalService) {

        this.gridOptions = <GridOptions>{
            context: {
                componentParent: this
            },
            doesExternalFilterPass: this.externalFilterPass
        };
        this.defaultColDef = {
            resizable: true,
            sortable: true,
            floatingFilter: true
        }
      

        let browserLang = translate.getBrowserLang();
        translate.getTranslation(browserLang).subscribe((res: string) => {
            this.adhocreports_translated_data = res['MANAGE_ADHOC_REPORTS']
            this.columnDefs = [
                { headerName: this.adhocreports_translated_data.REPORT_NAME, field: 'reportName', autoHeight: true, cellClass: "cell-wrap-text", width: 400, filter: "agTextColumnFilter" },
                { headerName: this.adhocreports_translated_data.CATEGORY, field: 'category', autoHeight: true, cellClass: "cell-wrap-text", width: 150, filter: "agTextColumnFilter" },
                { headerName: this.adhocreports_translated_data.MODULE, field: 'module', autoHeight: true, cellClass: "cell-wrap-text", width: 400, filter: "agTextColumnFilter" },
                {
                    headerName: this.adhocreports_translated_data.DELETE,
                    field: "delete",
                    minWidth: 75,
                    cellStyle: { 'text-align': 'center' },
                    cellRendererFramework: ManageAdhocReportDeleteCellComponent,
                }
            ];
        });
        //custom layout templates for loading and empty data sets
        this.overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Loading data...</span>';
        this.overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">No rows to show</span>';
    }

    ngOnInit(): void {
        this.manageAdhocReportsService.loadTableDataResponse().subscribe((option: any) => this.loadData());
    }

    externalFilterPass(node: any) {
        return true;
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.loadData();
    }

    onColumnResized(event) {
        if (event.finished) {
            this.gridApi.resetRowHeights();
        }
    }


    public loadData() {
        this.gridApi.showLoadingOverlay();
        this.manageAdhocReportsService.getAllReportDesignsByModuleAndProjectId(null)
            .subscribe(data => {
                if (data.payload) {
                    var rowData = [];
                    var payload = data.payload;

                    for (let i = 0; i < payload.length; i++) {
                        rowData.push({
                            id: payload[i]["id"],
                            reportName: payload[i]["reportName"],
                            category: payload[i]["category"],
                            module: payload[i]["modules"],
                        });
                    }
                    this.rowData = rowData;
                }
            },
                error => {
                    console.log('[getAllReportDesignsByModuleAndProjectId] (error) ' + JSON.stringify(error))
                    this.alertService.error(error.statusDescription);
                });
    }

    onAdd() {
        this.openAddModal();
    }

    private openAddModal() {
        const initialState: object = {
            edit: false
        };
        this.bsModalRef = this.modalService.show(AddAdhocReportComponent, { initialState, class: 'modal-md', backdrop: 'static', keyboard: false })
    }

    deleteReport(id: string) {
		this.loadingService.showLoading(false, false, '', 0)
		this.manageAdhocReportsService.deleteReport(id)
			.subscribe(
			data => {
				this.loadingService.hideLoading();
				this.alertService.success(this.translate.instant('MANAGE_ADHOC_REPORTS.DELETE_SUCCESS'));
				this.loadData();
			},
			error => {
				this.loadingService.hideLoading();
				this.alertService.clear();
				this.alertService.error(error.statusDescription);
			});
	}

}
