<div class="container-fluid px-0">
    <form (ngSubmit)="onSubmit()" [formGroup]="form">
        <div *ngFor="let sp of searchParams" class="form-group">
            <symbiosis-search-form-item [searchParams]="sp" [form]="form" (categorySelected)="categorySelected($event)" (documentSelected)="documentSelected($event)" (taginputSeleted)="taginputSeleted($event)"></symbiosis-search-form-item>
        </div>
        <div class="form-group mb-2">
            <button class="btn btn-primary btn-sm float-right" type="submit">Search</button>
        </div>
        <!-- <div class="form-group mb-2" *ngIf="payLoad">
            <p>
                <strong>Search values</strong><br>{{payLoad}}
            </p>
        </div> -->
    </form>
</div>
