import { Component, Input, ViewChild, ViewContainerRef, ComponentRef, ComponentFactoryResolver } from '@angular/core';
import { IceCourseItem } from './ice-course-item';
import { DynamicIceCourseItem } from './dynamic-ice-course-item';
import { IceCourseLoaderService } from './ice-course-loader.service';
import { SharedService } from 'src/app/util/shared.service';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';

@Component({
	selector: 'app-ice-course-loader',
	templateUrl: './ice-course-loader.component.html',
	styleUrls: ['./ice-course-loader.component.scss']
})
export class IceCourseLoaderComponent {

	@Input() component: IceCourseItem
	@Input() data: any;

	@ViewChild('iceCourseHost', { read: ViewContainerRef, static: true }) componentHost: ViewContainerRef
	componentRef: ComponentRef<Component>
	private insertSubscription: any;

	constructor(private resolver: ComponentFactoryResolver,
		private iceService: IceCourseLoaderService,
		private sharedService: SharedService) {
		//this.component = this.iceService.loadComponentWithData('IceCourse', data)
	}

	ngOnInit() {
		//alert(JSON.stringify(this.data));
		this.component = this.iceService.loadComponentWithData('IceCourse', this.data)
		if(SharedService.getNewICECourseSubscriptions() != null){
			SharedService.getNewICECourseSubscriptions().unsubscribe();
		}
	}

	ngAfterContentInit() {

		//Called after ngOnInit when the component's or directive's content has been initialized.
		//Add 'implements AfterContentInit' to the class.
		this.updateComponent()
	    this.insertSubscription=this.sharedService.setNewCourseIdRespond().subscribe(option => this.setNewCourseId(option))
		this.sharedService.changeIceItemRespond().subscribe(option => this.changeIceCourseItem(option))
		SharedService.setNewICECourseSubscriptions(this.insertSubscription);
	}


	ngOnChanges(): void {
		//Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
		//Add '${implements OnChanges}' to the class.
	}

	ngOnDestroy(): void {
		//Called once, before the instance is destroyed.
		//Add 'implements OnDestroy' to the class.
		if (this.componentRef) {
			this.componentRef.destroy()
		}
	}

	updateComponent() {
		this.componentHost.clear()
		const authFormFactory = this.resolver.resolveComponentFactory(this.component.component)
		const componentRef = this.componentHost.createComponent(authFormFactory);
		(<DynamicIceCourseItem>componentRef.instance).data = this.component.data
	}

	setNewCourseId(option: any) {
		console.log('[openTab] (option)' + JSON.stringify(option));
		//let dyeComponent = this.iceService.loadComponentWithData(option.componentType, option.data)
		//this.tabsComponent.openTabWithId(option.tabId, option.tabTitle, null, option.componentType, option.data, true);
		this.data.id = option.data.id;
	}

	changeIceCourseItem(data: any) {
		//alert(JSON.stringify(this.data));
		if(this.data.id== data.data.courseId){
			this.component = this.iceService.loadComponentWithData(data.componentType, data.data)
			this.updateComponent()
		}else{
			console.log("Not generating ice component" + "data :" +this.data.id + "courseId :" + data.data.courseId );
			console.log("Not generating ice component" + "data JSON :" + JSON.stringify(this.data.id) );
		}
	}

}
