<div class="modal-header">
   <h4 class="modal-title pull-left">{{title}}</h4>
   <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
         <span aria-hidden="true">&times;</span>
   </button>
   </div>
   <div class="modal-body">
      <form [formGroup]="outputGenerateForm">
      <div *ngFor="let item of itemConfigList; let i = index">
         <div class="form-group row" *ngIf="item.type == 'text'">
            <label for="name" class="col-sm-2 col-form-label">{{ item.title | translate }}
               <div *ngIf="item.title=='Document Title' && (outputType=='XML_TOPICMAP_ZIP' || outputType=='STORE_DOC_ZIP_PDF')" class="badge-required mx-2">
               </div>
            </label>     
            <div class="col-sm-10 ">
               <input  type="text" formControlName="{{item.name}}" class="form-control" name="f{{i}}"
                [maxlength]="item.title=='Version No' ? 20 : 0">
            </div>
         </div>
         <div class="form-group row" *ngIf="item.type == 'image'">
            <label for="name" class="col-sm-2 col-form-label">{{ item.title | translate }}</label>         
            <div class="col-sm-10">
               <input type="text" class="form-control" id="OG_item.name" formControlName="{{item.name}}" name="f{{i}}">
            </div>
         </div>
         <div class="form-group row" *ngIf="item.type == 'check'">
            <label for="name" class="col-sm-2 col-form-label">{{ item.title | translate }}</label>         
            <div class="col-sm-10">
            <input type="checkbox" formControlName="{{item.name}}" [checked]="item.parameterValue == '1'">
            </div>
         </div>
         <div class="form-group row" *ngIf="item.type == 'itemList'">
            <label for="name" class="col-sm-2 col-form-label">Coverpage Header</label>         
            <div class="col-sm-10">
               <ng-select [allowClear]="true" formControlName="{{item.name}}" [items]="itemList" name="items" id="items" (selected)="selectedItem($event)"
               (removed)="removedItem($event)">
               </ng-select>
            </div>
         </div>
         <div class="form-group row" *ngIf="item.type == 'imageList'">
            <label for="name" class="col-sm-2 col-form-label">Logo </label>         
            <div class="col-sm-10">
               <ng-select [allowClear]="true" formControlName="{{item.name}}" [items]="multipleLogoList" name="images" id="images" (selected)="selectedLogo($event)"
               (removed)="removedLogo($event)">
               </ng-select>
            </div>
         </div>
         <div class="form-group row" *ngIf="item.type == 'list'">
            <label for="name" class="col-sm-2 col-form-label">{{ item.title | translate }}</label>         
            <div class="col-sm-10">
               <ng-select [allowClear]="true" formControlName="{{item.name}}"
                [items]="documentList" name="documentlist" id="documentlist">
               </ng-select>
            </div>
         </div>
         <div class="form-group row" *ngIf="item.type == 'facility'">
            <label for="name" class="col-sm-2 col-form-label">{{ item.title | translate }}</label>         
            <div class="col-sm-10">
               <ng-select  [allowClear]="true"  formControlName="{{item.name}}"
               [items]="facilityList" name="facility" id="facility" (selected)="selectedFacility($event)"
               (removed)="removedFacility($event)">
               </ng-select>
            </div>
         </div>
         <div class="form-group row" *ngIf="item.type == 'system'">
            <label for="name" class="col-sm-2 col-form-label">{{ item.title | translate }}
               <div  *ngIf="title!='Add to Document Store'" class="badge-required mx-2">
               </div>
            </label>         
            <div class="col-sm-10">
               <ng-select  [allowClear]="true"  formControlName="{{item.name}}"
               [items]="systemList" name="system" id="system" required>
               </ng-select>
            </div>
         </div>              
      </div>
      <div class="form-group row" *ngIf="isShowParameterValues">
            <label for="name" class="col-sm-2 col-form-label">{{ "Save Parameter Values" | translate }}</label>         
            <div class="col-sm-10 checkclass">
            <input type="checkbox" formControlName="saveParameter">
            </div>
      </div>
      <div class="form-group row" *ngIf="isShowDraftDocument">
            <label for="name" class="col-sm-2 col-form-label">{{ "Draft Document" | translate }}</label>         
            <div class="col-sm-10 ">
            <input type="checkbox" formControlName="draftDocument" [checked]="isChecked">
            </div>
         </div>
      </form>
   </div>
<div class="modal-footer">  
      <button *ngIf="title=='Output Generator'" type="button" class="btn btn-primary" (click)="saveOutputValues()">{{ 'Generate' | translate}}</button>
      <button *ngIf="title=='Add to Procedure Store'" type="button" class="btn btn-primary" (click)="saveOutputValues()">{{ 'Add to Procedure Store' | translate}}</button>
      <button *ngIf="title=='Add to Document Store'" type="button" class="btn btn-primary" (click)="saveOutputValues()">{{ 'Add to Document Store' | translate}}</button>
      <button type="button" class="btn btn-secondary" (click)="bsModalRef.hide()">{{ 'Cancel' | translate}}</button>
</div>
