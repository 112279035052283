import { IceLearningCheck } from './../ice-course/ice-learning-check/ice-learning-check.model';
import { Content } from './../procedure/procedure.model';
/**
 * This service is use to communicate between components
 * using output
 */
import { Injectable, Output, EventEmitter } from '@angular/core';
import { Constants } from '../util/constants';
import { ServiceUrls } from './service-urls';
import { UserVariable } from './common/user-variable';
import { WsCallback } from './ws-callback.interface';
import { HttpClient, HttpParams, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { WsResponse } from './ws-response.model';
import { WsType } from './ws-type';
import { CommonUtil } from './common/common-util';
import { IceLCQuestion } from './../ice-course/ice-learning-check/ice-lc-question.model';
import { map } from 'rxjs/operators';


export interface IHash{
	[key:string]:boolean;
}

export interface BreadCrumbHash{
	[key:string]:Array<any>;
}

@Injectable({
	providedIn: 'root'
})

export class SharedService {

	constructor(private http: HttpClient, private commonUtil: CommonUtil) {
	}

	@Output() selectFolderItem: EventEmitter<any> = new EventEmitter()
	@Output() showFullScreen: EventEmitter<any> = new EventEmitter()
	@Output() static openReusableTab: EventEmitter<any> = new EventEmitter()
	@Output() expandReusables: EventEmitter<any> = new EventEmitter()
	@Output() updateTimeStamp: EventEmitter<any> = new EventEmitter()
	@Output() disableCellRenderer: EventEmitter<any> = new EventEmitter()
	@Output() deleteUpdate: EventEmitter<any> = new EventEmitter()
	@Output() showDetailPanel: EventEmitter<any> = new EventEmitter()
	@Output() static insertEditor: EventEmitter<any> = new EventEmitter()
	@Output() static insertProcedures: EventEmitter<any> = new EventEmitter()
	@Output() static insertICECourse: EventEmitter<any> = new EventEmitter()
	@Output() static insertICETopic: EventEmitter<any> = new EventEmitter()
	@Output() selectTopicMapBuilder: EventEmitter<any> = new EventEmitter()
	@Output() selectTopicMapViewer: EventEmitter<any> = new EventEmitter()
	@Output() private toggleNotification: EventEmitter<any> = new EventEmitter()
	@Output() selectTreeItem: EventEmitter<any> = new EventEmitter()
	@Output() changeLeftSideItem: EventEmitter<any> = new EventEmitter()
	@Output() selectTabWhenFEClick: EventEmitter<any> = new EventEmitter()
	@Output() selectTabWhenTMBClick: EventEmitter<any> = new EventEmitter()
	@Output() tabCloseBtnClick: EventEmitter<any> = new EventEmitter()
	@Output() selectTopicMapTab: EventEmitter<any> = new EventEmitter()
	@Output() selectTopicMapFolderTab: EventEmitter<any> = new EventEmitter()
	@Output() selectICECourseTab: EventEmitter<any> = new EventEmitter()
	@Output() changeIceItem: EventEmitter<any> = new EventEmitter()
	@Output() setNewCourseId: EventEmitter<any> = new EventEmitter()
	@Output() imageStatus: EventEmitter<any> = new EventEmitter()
	//reusable components open edit event emitters 
	@Output() openTagsEdit: EventEmitter<any> = new EventEmitter()
	@Output() openStatementsEdit: EventEmitter<any> = new EventEmitter()
	@Output() openComponentEdit: EventEmitter<any> = new EventEmitter()
	@Output() openSystemsEdit: EventEmitter<any> = new EventEmitter()
	@Output() openDocumentsEdit: EventEmitter<any> = new EventEmitter()
	@Output() openFacilitiesEdit: EventEmitter<any> = new EventEmitter()
	@Output() openDocumentAtributeEdit: EventEmitter<any> = new EventEmitter()
	//change the active component of output management
	@Output() changeOMComponents: EventEmitter<any> = new EventEmitter()
	//change the active component of Dictionary
	@Output() changeDictionaryComponents: EventEmitter<any> = new EventEmitter()
	//folder refresh event emitter
	@Output() folderRefresh: EventEmitter<any> = new EventEmitter()
	@Output() activeTabCloseClick: EventEmitter<any> = new EventEmitter()
	//deleted tab close event
	@Output() deletedTabClose: EventEmitter<any> = new EventEmitter
	@Output() checkedInCheckedOutTabClose: EventEmitter<any> = new EventEmitter
	@Output() deletedTabListClose: EventEmitter<any> = new EventEmitter()
	//rename tab event 
	@Output() renameTab: EventEmitter<any> = new EventEmitter
	@Output() docuSaveEvent: EventEmitter <any> =new EventEmitter()
	@Output() modalCloseEvent: EventEmitter <any> =new EventEmitter()
	@Output() viewSideBar: EventEmitter <any> =new EventEmitter()
	@Output() static searchComponent: EventEmitter <any> =new EventEmitter()
	@Output() moveFolderItems: EventEmitter<any> = new EventEmitter()
	@Output() disablePunchlistId: EventEmitter<any> = new EventEmitter
	@Output() deletePunchlistId: EventEmitter<any> = new EventEmitter
	@Output() editPunchlistId: EventEmitter<any> = new EventEmitter
	@Output() documentTypeJstree: EventEmitter<any> = new EventEmitter
	@Output() setRoleTable: EventEmitter<any> = new EventEmitter()
	@Output() static activatedTab: EventEmitter<any> = new EventEmitter()
	@Output() setICEModuleChanges: EventEmitter<any> = new EventEmitter()
	@Output() setICEModuleVariables: EventEmitter<any> = new EventEmitter()
	@Output() setOpenedICEModule: EventEmitter<any> = new EventEmitter()
	@Output() setOpenedICEVariables: EventEmitter<any> = new EventEmitter()
	@Output() setSelectImagetoMainObject: EventEmitter<any> = new EventEmitter()
	@Output() resetEnableDisableButton: EventEmitter<any> = new EventEmitter();
	@Output() deleteICETopicDone: EventEmitter<any> = new EventEmitter();
	@Output() editIrnId: EventEmitter<any> = new EventEmitter
	@Output() deleteIrnPunchlistId: EventEmitter<any> = new EventEmitter
	@Output() loadAttributeList: EventEmitter<any> =  new EventEmitter();
	@Output() enableReferencePane: EventEmitter<any> = new EventEmitter();
	@Output() auth0Login: EventEmitter<any> = new EventEmitter();
	@Output() enableTopicMapPane: EventEmitter<any> = new EventEmitter();
	@Output() selectedFileDataChanged: EventEmitter<any> = new EventEmitter();
	@Output() editmcc: EventEmitter<any> = new EventEmitter();
	@Output() deleteFoldersFromFolderTree: EventEmitter<any> = new EventEmitter()
	@Output() enableFolderDelete: EventEmitter<any> = new EventEmitter()
	@Output() changeModalSize: EventEmitter<any> = new EventEmitter()
	@Output() editCheckId: EventEmitter<any> = new EventEmitter
	@Output() loadFolderTree: EventEmitter<any> = new EventEmitter()
 	private fileId: any = null;
	private fileType: any = null;
	private courseId: any = null;
	private topicIndex: number = null;
	private viewOnlyStatus:boolean = false;
	private dependencyMessageType: string = null;
	private static stepNo: any = null;
	private static step: any = null;
	private static stepListSize: any = null;
	private isCheckedIn: boolean = false;
	private action: string = null;
	private revisionContent: string = null;	
	private openedTabs: Array<any> = [];
	private revisionDataShared = [];
	private status: any = null;
	private course: any = null;
	private static topicInsertsubsc: any = null;
	private static proceInsertsubsc: any = null;
	private static iceCourseInsertsubsc: any = null;
	private static iceMemoryGameInsertsubsc: any = null;
	private static iceMemoryGameInsertsubsc2: any = null;
	private static iceEinsteinGameInsertsubsc: any = null;
	private static iceSpeedMatchGameInsertsubsc: any = null;
	private static iceLCInsertsubsc: any = null;
	private static iceTopicInsertsubsc: any = null;
	private static iceTopicContentInsertsubsc: any = null;
	private static iceThreeDModelInsertsubsc: any = null;
	private static newiceCourseInsertsubsc: any = null;
	private static activeTabType: any = null;
	private parent: any = null;
	private editor: any = null;
	//private htmlString: any = null;
	static htmlString: any;
	static state: any;
	static content: any;
	static cState: any;
	static tData: any;
	static sccData: any;
	static statementHtmlString: any;
	private topicMapId: string = null;
	private topicMapName: string = null;
	private topicMapType: string = null;
	private topicMapContent: string = null;
	static selectTopicMapBuilder: any;
	private static tabId: string = null;
	private static closingTabId: string = null;
	private static closingTabType: string = null;
	private myhash: IHash = {};
	private modifiedhash: IHash = {};
	private checkoutUserId: string = null;   
	private openedICEModules: Array<any> = [];
	private pictureReferences: any;
	
	private breadCrumbHash: BreadCrumbHash = {};     
	private revParent: any = null; 
	private newICETabId:any; 
	private roleId:any; 
	private isNeedRefresh:boolean;
	private applyClients:boolean;
	private isICETopic:boolean;
	private iceTopicList: Array<any> = []
	static selectedNode:HTMLElement;
	private commonICEModuleVariableNames: Array<any> = [];
	private commonICEModuleVariableValues: Array<any> = [];
	private loadedComponent: string
	private dependencyTypeList: Array<string> = null;
	private multipleSelectedList: string;
	//Achini Randeni
	//to keep selected tag input list
	private tagInputList: Array<any> = [];
	private folderTree:any; 

	public openTabRequest(tabTitle: string, componentType: string) {
		this.selectFolderItem.emit(
			{
				tabTitle: tabTitle,
				componentType: componentType
			}
		)
	}

	public openTabRequestWithId(tabId: string, tabTitle: string, componentType: string, data: any, path: string) {
		this.selectFolderItem.emit(
			{
				tabId: tabId,
				tabTitle: tabTitle,
				componentType: componentType,
				data: data,
				path: path
			}
		)
		// if we try to open an already opened "new" course, it should not be added into the openedTab array [!(this.newICETabId == tabId)]
		if ((componentType == "Editor" || componentType == "Procedures" || componentType == "TopicMapBuilder" ||
		 componentType == "IceCourse") && !this.openedTabs.includes(tabId) && !(this.newICETabId == tabId)) { 
			this.openedTabs.push(tabId)
		}
	}

	public openTabRespond() {
		return this.selectFolderItem
	}

	public enableReferencePaneRequest(enable : boolean){
		this.enableReferencePane.emit(enable);
	}

	public enableReferencePaneRespond() {
		return this.enableReferencePane;
	}

	public enableTopicMapPaneRequest(enable : boolean){
		this.enableTopicMapPane.emit(enable);
	}

	public enableTopicMapPaneRespond() {
		return this.enableTopicMapPane;
	}
	
	public setNewCourseIdRequest( data: any) {
		this.setNewCourseId.emit(
			{
				data: data
			}
		)
	}

	public setNewCourseIdRespond() {
		return this.setNewCourseId
	}

	public static openReusablepanel(reusableType: any) {
		this.openReusableTab.emit({ data: reusableType })
	}
	public static openImageReusablepanel(reusableType: any) {
		this.openReusableTab.emit({ data: reusableType })
	}
	public static openVideoReusablepanel(reusableType: any) {
		this.openReusableTab.emit({ data: reusableType })
	}
	public static openThreeDModelReusablepanel(reusableType: any) {
		this.openReusableTab.emit({ data: reusableType })
	}

	public static openReusablepanelRespond() {
		;
		return this.openReusableTab
	}


	public fullScreenRequest(layout: any) {
		this.showFullScreen.emit({ uiLayout: layout })
	}

	public fullScreenExecute() {
		return this.showFullScreen
	}

	public tnRequest() {
		this.toggleNotification.emit()
	}

	public tnExecute() {
		return this.toggleNotification
	}

	public expandReusablesRequest(layout: any) {
		this.expandReusables.emit({ uiLayout: layout })
	}

	public expandReusablesExcute() {
		return this.expandReusables
	}

	public static insertEditorRequest(payload: any) {
		this.insertEditor.emit({ data: payload })
	}

	public static insertEditorExecute() {
		return this.insertEditor
	}

	public static insertProceduresRequest(payload: any) {
		this.insertProcedures.emit({ data: payload })
	}

	public static insertProceduresExecute() {
		return this.insertProcedures
	}

	public static insertICECourseRequest(payload: any) {
		this.insertICECourse.emit({ data: payload })
	}

	public static insertICECourseExecute() {
		return this.insertICECourse
	}

	public static insertICEGameExecute() {
		return this.insertICECourse
	}

	public static insertICELCExecute() {
		return this.insertICECourse
	}

	public static insertICETopicExecute() {
		return this.insertICETopic
	}

	public updateTimeStampRequest(timeStamp: any, type: String) {
		this.updateTimeStamp.emit({ data: timeStamp, fileType: type })
	}

	public updateTimeStampExecute() {
		return this.updateTimeStamp
	}

	public disableCellRendererRequest(disable: any, fileId: String) {
		this.disableCellRenderer.emit({ data: disable, id: fileId })
	}

	public disableCellRendererExecute() {
		return this.disableCellRenderer
	}

	public deleteUpdateRequest(value: boolean, checkbox: boolean, text: String) {
		this.deleteUpdate.emit({ flag: value, checkboxVal: checkbox, tooltip: text })
	}

	public deleteUpdateExecute() {
		return this.deleteUpdate
	}

	public showDetailPanelRequest(flag: any) {
		this.showDetailPanel.emit({ showDetailPanel: flag })
	}

	public showDetailPanelExecute() {
		return this.showDetailPanel
	}

	public setFileId(fileId: any) {
		this.fileId = fileId
	}

	public getFileId() {
		return this.fileId
	}

	public getCheckoutUserId(): string {
		return this.checkoutUserId;
	}
	
	public setCheckoutUserId(value: string) {
		this.checkoutUserId = value;
	}

	public setViewOnlyStatus(tabId:string, viewOnlyStatus: boolean) {
		this.myhash[tabId] = viewOnlyStatus
	}

	public getViewOnlyStatus(tabId:string) {
		return this.myhash[tabId]
	}

	public setModifiedStatus(tabId:string, status: boolean) {
		this.modifiedhash[tabId] = status
	}

	public getModifiedStatus(tabId:string) {
		return this.modifiedhash[tabId]
	}

	public setICEBreadCrumb(courseId:string, data: any) {
		if(this.breadCrumbHash[courseId]!= null){
			this.breadCrumbHash[courseId].push(data)
		}else{
			this.breadCrumbHash[courseId] = new Array();
			this.breadCrumbHash[courseId].push(data)
		}
	}

	public getICEBreadCrumb(courseId:string) {
		return this.breadCrumbHash[courseId]
	}

	public removeListFromICEBreadCrumb(courseId:string) {
		this.breadCrumbHash[courseId] = null;
	}

	public setFileType(fileType: any) {
		this.fileType = fileType
	}

	public getFileType() {
		return this.fileType
	}

	public getDependencyMessageType(): string {
		return this.dependencyMessageType;
	}
	public setDependencyMessageType(value: string) {
		this.dependencyMessageType = value;
	}

	public setStatus(status: any) {
		this.status = status
	}

	public getStatus() {
		return this.status
	}

	public setCourse(course: any) {
		this.course = course
	}

	public getCourse() {
		return this.course
	}

	public static setTopicSubscriptions(subscription: any) {
		SharedService.topicInsertsubsc = subscription;
	}

	public static getTopicSubscriptions() {
		return SharedService.topicInsertsubsc
	}

	public static setRevisionSubscriptions(subscription: any) {
		SharedService.proceInsertsubsc = subscription
	}

	public static getRevisionSubscriptions() {
		return SharedService.topicInsertsubsc
	}

	public static setProceSubscriptions(subscription: any) {
		SharedService.proceInsertsubsc = subscription
	}

	public static getProceSubscriptions() {
		return SharedService.proceInsertsubsc
	}

	public static setIceCourseSubscriptions(subscription: any) {
		console.log(subscription);
		SharedService.iceCourseInsertsubsc = subscription
	}

	public static setIceMemoryGameSubscriptions(subscription: any) {
		SharedService.iceMemoryGameInsertsubsc = subscription
	}

	public static setIceMemoryGameSubscriptions2(subscription: any) {
		SharedService.iceMemoryGameInsertsubsc2 = subscription
	}

	public static setIceEinsteinGameSubscriptions(subscription: any) {
		SharedService.iceEinsteinGameInsertsubsc = subscription
	}

	public static setIceSpeedMatchGameSubscriptions(subscription: any) {
		SharedService.iceSpeedMatchGameInsertsubsc = subscription
	}

	public static setIceLCSubscriptions(subscription: any) {
		SharedService.iceLCInsertsubsc = subscription
	}

	public static setIceTopicSubscriptions(subscription: any) {
		SharedService.iceTopicInsertsubsc = subscription
	}

	public static setIceTopicContentSubscriptions(subscription: any) {
		SharedService.iceTopicContentInsertsubsc = subscription
	}

	public static setICEThreeDModelSubscriptions(subscription: any) {
		SharedService.iceThreeDModelInsertsubsc = subscription
	}

	public static setNewICECourseSubscriptions(subscription: any) {
		SharedService.newiceCourseInsertsubsc = subscription
	}

	public static getNewICECourseSubscriptions() {
		return SharedService.newiceCourseInsertsubsc
	}

	public static getIceCourseSubscriptions() {
		return SharedService.iceCourseInsertsubsc
	}

	public static getIceMemoryGameSubscriptions() {
		return SharedService.iceMemoryGameInsertsubsc
	}

	public static getIceMemoryGameSubscriptions2() {
		return SharedService.iceMemoryGameInsertsubsc2
	}

	public static getIceEinsteinGameSubscriptions() {
		return SharedService.iceEinsteinGameInsertsubsc
	}

	public static getIceSpeedMatchGameSubscriptions() {
		return SharedService.iceSpeedMatchGameInsertsubsc
	}

	public static getIceLCSubscriptions() {
		return SharedService.iceLCInsertsubsc
	}

	public static getIceTopicSubscriptions() {
		return SharedService.iceTopicInsertsubsc
	}

	public static getIceTopicContentSubscriptions() {
		return SharedService.iceTopicContentInsertsubsc
	}

	public static getICEThreeDModelSubscriptions() {
		return SharedService.iceThreeDModelInsertsubsc
	}

	public static setTabComponentType(type: any) {
		this.activeTabType = type;
	}

	public static getTabComponentType() {
		return this.activeTabType
	}

	public setParent(parent: any) {
		this.parent = parent;
	}

	public getParent() {
		return this.parent;
	}

	public setEditor(editor: any) {
		this.editor = editor;
	}

	public getEditor() {
		return this.editor;
	}

	public getIsCheckedIn(): boolean {
		return this.isCheckedIn;
	}

	public setIsCheckedIn(isCheckedIn: boolean) {
		this.isCheckedIn = isCheckedIn;
	}
	public static getHtmlString() {
		return this.htmlString
	}

	public static setHtmlString(htmlString: any) {
		this.htmlString = htmlString;
	}

	public getAction() {
		return this.action;
	}

	public setAction(action: any) {
		this.action = action;
	}

	public static setStepNo(stepNo: any) {
		this.stepNo = stepNo;
	}

	public static getStepNo() {
		return this.stepNo;
	}

	public static setStep(step: any) {
		this.step = step;
	}

	public static getStep() {
		return this.step;
	}

	public static setStepListSize(stepListSize: any) {
		this.stepListSize = stepListSize;
	}

	public static getStepListSize() {
		return this.stepListSize;
	}
	
	public setCourseId(courseId: any) {
		this.courseId = courseId;
	}

	public getCourseId() {
		return this.courseId;
	}

	public setDeleteTopicIndex(index: number) {
		this.topicIndex = index;
	}

	public getDeleteTopicIndex() {
		return this.topicIndex;
	}

	public setTopicList(list: Array<any>) {
		this.iceTopicList = list;
	}

	public getTopicList() {
		return this.iceTopicList;
	}
	public static setselectedNode(selectedNode){
		console.log(selectedNode);
		this.selectedNode=selectedNode
	}
	public static nodeSelected(){
		if(this.selectedNode!=null){
			return true
		}else{
			return false
		}
	}
	public static getselectedNode(){
		return this.selectedNode;
	}
	public static resetselectedNode(){
		this.selectedNode=null;
	}
	//Topic map builder
	public openTopicMapBuilder(topicMapId: string, topicMapName: string, topicMapType: string, topicmapContent: string) {
		let xmlDoc = $.parseXML(topicmapContent);
		let mapNode = $(xmlDoc).find('map');

		var mapContent = new XMLSerializer().serializeToString(mapNode[0]);
		this.setTopicMapType(topicMapType)
		this.setTopicMapId(topicMapId);
		this.setTopicMapName(topicMapName);
		this.setTopicMapContent(mapContent);
		this.selectTopicMapBuilder.emit(
			{
				topicMapId: topicMapId,
				topicMapName: topicMapName,
				topicMapType: topicMapType,
				mapContent: mapContent
			}
		)

	}

	public openTopicMapBuilderRespond() {
		return this.selectTopicMapBuilder;
	}

	public openTopicMapViewer(topicMapId: string, topicMapName: string, topicMapType: string) {
		this.setTopicMapType(topicMapType)
		//this.setTopicMapId(topicMapId);
		this.setTopicMapName(topicMapName);
		this.selectTopicMapViewer.emit(
			{
				topicMapId: topicMapId,
				topicMapName: topicMapName,
				topicMapType: topicMapType
			}
		)

	}

	public openTopicMapViewerRespond() {
		return this.selectTopicMapViewer;
	}

	public setTopicMapId(topicMapId: string) {
		this.topicMapId = topicMapId;
	}

	public getTopicMapId() {
		return this.topicMapId;
	}

	public setTopicMapName(topicMapName: string) {
		this.topicMapName = topicMapName;
	}

	public getTopicMapName() {
		return this.topicMapName;
	}

	public setTopicMapContent(topicMapContent: string) {
		this.topicMapContent = topicMapContent;
	}

	public getTopicMapContent() {
		return this.topicMapContent;
	}

	public setTopicMapType(topicMapType: string) {
		this.topicMapType = topicMapType
	}

	public getTopicMapType() {
		return this.topicMapType
	}

	public static setState(state: any) {
		this.state = state;
	}

	public static getState() {
		return this.state;
	}

	public static setContent(content: any) {
		this.content = content;
	}

	public static getContent() {
		return this.content;
	}
	public static setStatementHtmlString(statementHtmlString: any) {
		this.statementHtmlString = statementHtmlString;
	}

	public static getStatementHtmlString() {
		return this.statementHtmlString;
	}

	/**
	 * select appropriate folder in jstree event emitter
	 * @param itemId 
	 */
	public selectTreeItemRequest(itemId: string) {
		this.selectTreeItem.emit({ id: itemId })
	}
	public selectTreeItemRespond() {
		return this.selectTreeItem
	}

	/**
	 * change left side panel item 
	 * @param activeItem 
	 */
	public changeLeftSideItemRequest(activeItem: string, isSelectTab: boolean) {
		this.changeLeftSideItem.emit(
			{
				item: activeItem,
				isSelectTab: isSelectTab
			}
		)
	}

	public updatePreviousICEModuleChangesRequest( selTab: string) {
		this.setICEModuleChanges.emit(selTab);
	}

	public updatePreviousICEModuleVariablesRequest( selTab: string) {
		this.setICEModuleVariables.emit(selTab);
	}

	public setOpenedICEModuleRequest( selTab: string) {
		this.setOpenedICEModule.emit(selTab);
	}

	public setOpenedICEVariablesRequest( selTab: string) {
		this.setOpenedICEVariables.emit(selTab);
	}

	public updateOpenedTabsListAfterCloseTab( selTab: string) {
		delete this.openedICEModules[selTab];
		delete this.commonICEModuleVariableValues[selTab];
	}

	public changeLeftSideItemRespond() {
		return this.changeLeftSideItem
	}

	public setSelectedImageRequest( option: any) {
		this.setSelectImagetoMainObject.emit(option);
	}

	/**
	 * select tab when folder explorer button click
	 */
	public selectTabWhenFEClickRequest() {
		this.selectTabWhenFEClick.emit()
	}
	public selectTabWhenFEClickRespond() {
		return this.selectTabWhenFEClick
	}

	/**
	 * select tab when topic map builder button click
	 */
	public selectTabWhenTMBClickRequest() {
		this.selectTabWhenTMBClick.emit()
	}
	public selectTabWhenTMBClickRespond() {
		return this.selectTabWhenTMBClick
	}

	/**
	 * tab close button click event respond and request
	 */
	public tabCloseBtnClickRequest() {
		this.tabCloseBtnClick.emit()
		var index = this.openedTabs.indexOf(SharedService.closingTabId); 
    	if (index > -1) {
			this.openedTabs.splice(index, 1);
		}			
		console.log("Selected tab" + SharedService.tabId);
		console.log("Opened tabs" + this.openedTabs);
	}
	
	public tabCloseBtnClickRespond() {
		return this.tabCloseBtnClick
	}

	/**
	 * clear all open tab ids when close all tabs event
	 */
	public closAlltabs(){
		this.openedTabs = [];
	}

	/**
	 * active tab close event respond and request
	 * Used in topicmap builder to close the folder list after checkin
	 * Could be be use in the editor  
	 */
	public activeTabCloseRequest() {
		this.activeTabCloseClick.emit()
	}
	public activeTabCloseRespond() {
		return this.activeTabCloseClick
	}
	/**
	 * select topic map tab respond and request
	 */
	public selectTopicMapTabRequest(tab: any) {
		this.selectTopicMapTab.emit(
			{
				tab: tab
			}
		)
	}
	public selectTopicMapTabRespond() {
		return this.selectTopicMapTab
	}

	public selectTopicMapFoldeTabRequest(tab: any) {
		this.selectTopicMapFolderTab.emit(
			{
				tab: tab
			}
		)
	}
	public selectTopicMapFoldeTabRespond() {
		return this.selectTopicMapFolderTab
	}

	public static setStateCheck(cState: any) {
		this.cState = cState;
	}

	public static getStateCheck() {
		return this.cState;

	}
	public static setTable(tData: any) {
		this.tData = tData;
	}

	public static getTable() {
		return this.tData;

	}
	public static setSCCdata(sccData: any) {
		this.sccData = sccData;
	}

	public static getSCCdata() {
		return this.sccData;

	}

	public static setTabId(tabId: string) {
		this.tabId = tabId;
	}

	public static setClosingTabIdClosingTabId(closingTabId: string) {
		this.closingTabId = closingTabId;
	}

	public static setClosingTabType(closingTabType: string) {
		this.closingTabType = closingTabType;
	}

	public static getClosingTabType() {
		return this.closingTabType;
	}
	public static getTabId() {
		return this.tabId;
	}

	public static getClosingTabId() {
		return this.closingTabId;
	}

	/**
	 * Set editor height according to window height. 
	 */
	public static setTopicEditorHeight() {
		let height = $(window).height();
		return height-270;
	}

	public setNewICETabId(tabId: string){
		this.newICETabId = tabId;
	}

	public getNewICETabId(){
		return this.newICETabId;
	}

	public getOpenedTabs(){
		return this.openedTabs;
	}
	public setNeedRefresh(isNeedRefresh: boolean){
		this.isNeedRefresh= isNeedRefresh;
	}

	public getNeedRefresh(){
		return this.isNeedRefresh;
	}

	/**
	 * Get unique identifier 
	 */
	public getUniqueidentifier(callBack: WsCallback) {
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
		let url = ServiceUrls.UTILITY + 'get/generateUniqueidentifier.json?token=' + token;
		const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
		const options = { params: new HttpParams().set("token", token) }
		return this.http.get(url, options)
			.pipe(map(res => res));
	}

	/**
	 * Get unique identifiers list
	 */
	public getUniqueidentifierList(identifierCount: any,callBack: WsCallback) {
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
		let url = ServiceUrls.UTILITY + 'get/generateMultipleUniqueIdentifiers.json?token=' + token;
		const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
		const options = { params: new HttpParams().set("token", token).set("identifierCount", identifierCount) }
		this.http.get(url, options).
			subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.code,
					modified.status.name,
					modified.status.description,
					modified.payload);
					callBack.onSuccess(res, WsType.GET_UNIQUE_IDENTIFIER_LIST);
			},
			error => {
				var val = (error as HttpErrorResponse).error;
				var modified = JSON.parse(JSON.stringify(val));
				var res = new WsResponse(
					modified.status.code,
					modified.status.name,
					modified.status.description,
					modified.payload);
					callBack.onFail(res, WsType.GET_UNIQUE_IDENTIFIER_LIST);
			});
	}

	public changeIceItemRequest(componentType: string, data: any) {
			this.changeIceItem.emit(
				{
					componentType: componentType,
					data: data,
				}
			)
	}
	

	public changeIceItemRespond() {
		return this.changeIceItem
	}

	//reusable components
	/**
	 * open tags edit modal event emitter request 
	 */
	public openTagsEditRequest(tagId: string) {
		this.openTagsEdit.emit(
			{
				tagId: tagId
			}
		)
	}
	/**
	 * open tags edit modal event emitter respond
	 */
	public openTagsEditRespond() {
		return this.openTagsEdit
	}

	/**
	 * open statements edit modal event emitter request 
	 */
	public openStatementsEditRequest() {
		this.openStatementsEdit.emit()
	}
	/**
	 * open statements edit modal event emitter respond
	 */
	public openStatementsEditRespond() {
		return this.openStatementsEdit
	}

	/**
	 * open component edit modal request 
	 */
	public openComponentEditRequest() {
		this.openComponentEdit.emit()
	}

	/**
	 * open component edit modal request 
	 */
	public opencomponentEditRespond() {
		return this.openComponentEdit
	}

	/**
	 * open systems edit modal event emitter request 
	 */
	public openSystemsEditRequest() {
		this.openSystemsEdit.emit()
	}
	/**
	 * open systems edit modal event emitter respond
	 */
	public openSystemsEditRespond() {
		return this.openSystemsEdit
	}

	/**
	 * open documents edit modal event emitter request 
	 */
	public openDocumentsEditRequest(itemId: string) {
		this.openDocumentsEdit.emit(
			{
				itemId: itemId
			}
		)
	}
	/**
	 * open documents edit modal event emitter respond
	 */
	public openDocumentsEditRespond() {
		return this.openDocumentsEdit
	}

	/**
	 * open facilities edit modal event emitter request 
	 */
	public openFacilitiesEditRequest() {
		this.openFacilitiesEdit.emit()
	}
	/**
	 * open facilities edit modal event emitter respond
	 */
	public openFacilitiesEditRespond() {
		return this.openFacilitiesEdit
	}

	/**
	 * open document attribute edit modal event emitter request 
	 */
	public openDocumentAtributeEditRequest() {
		this.openDocumentAtributeEdit.emit()
	}
	/**
	 * open document attribute edit modal event emitter respond
	 */
	public openDocumentAtributeEditRespond() {
		return this.openDocumentAtributeEdit
	}
	

	/**
	 * change the active component of output management - request
	 * @param componentName 
	 */
	public changeOMComponentsRequest(componentName: string) {
		this.changeOMComponents.emit(
			{
				componentName: componentName
			}
		)
	}

	/**
	 * change the active component of output management - respond
	 */
	public changeOMComponentsRespond() {
		return this.changeOMComponents
	}

	/**
	 * change the active component of Dictionary - Request
	 * @param componentName 
	 */
	public changeDictionaryComponentsRequest(componentName: string) {
		this.changeDictionaryComponents.emit(
			{
				componentName: componentName
			}
		)
	}
	/**
	 * hange the active component of Dictionary - Respond
	 */
	public changeDictionaryComponentsRespond() {
		return this.changeDictionaryComponents
	}

	/**
	 * folder Refresh event request
 	 */
	public folderRefreshRequest(folderId: string) {
		this.folderRefresh.emit(
			{
				folderId: folderId
			}
		)
	}
	/**
	 * folder Refresh event respond
	 */
	public folderRefreshRespond() {
		return this.folderRefresh
	}

	/**
	 * This called when user delete the folder to remove the open tab
	 * @param folderId 
	 */
	public deletedTabCloseRequest(folderId: string) {
		this.deletedTabClose.emit(
			{
				folderId: folderId
			}
		)
	}
	/**
	 * remove already open tab when user deleted the folder from 
	 * folder tree.
	 */
	public  deletedTabCloseRespond() {
		return this.deletedTabClose
	}
	
		/**
	 * This called when user delete the folder to remove the open tab
	 * @param tabId 
	 */
	public checkedInCheckedOutTabCloseRequest(tabId: string) {
		this.checkedInCheckedOutTabClose.emit(
			{
				tabId: tabId
			}
		)
	}
	/**
	 * remove already open tab when user deleted the folder from 
	 * folder tree.
	 */
	public  checkedInCheckedOutTabCloseRespond() {
		return this.checkedInCheckedOutTabClose
	}

	public deletedTabListCloseRequest(tabIdList: Array<string>) {
		this.deletedTabListClose.emit(
			{
				tabIdList: tabIdList
			}
		)
	}

	public  deletedTabListCloseRespond() {
		return this.deletedTabListClose
	}

	/**
	 * rename folder tab request
	 * @param folderId 
	 */
	public renameTabRequest(folderId: string, folderName: string) {
		this.renameTab.emit(
			{
				folderId: folderId,
				folderName: folderName
			}
		)
	}
	/**
	 * rename tab respond
	 */
	public renameTabRespond() {
		return this.renameTab
	}	

	/**
	 * revision restore parent
	 */
	public setRevisionParent(revParent: any) {
		this.revParent = revParent;
	}

	/**
	 * revision restore parent
	 */
	public getRevisionParent() {
		return this.revParent;
	}

	public imageStatusRequest(status: string, tabId:string) {
		this.imageStatus.emit(
			{
				status: status,
				tabId :tabId
			}
		)
	}

	public setOpenedICEModules(selectedTab: string, selectedModuleType: string, selectedModule : any) {
		this.openedICEModules[selectedTab] = {type : selectedModuleType, module : selectedModule};
	}

	public getOpenedICEModules() {
		return this.openedICEModules;
	}

	public setPictureReferences(selectedRow: number, selectedSeq: number, selectedType : string, LCQuestion: IceLCQuestion = null, 
		LCSide: string = null, LCIndex: number = null, LCTabId: string = null, LCDdqId: number = null, compType : string = null) {
		this.pictureReferences = {
			selectedRow : selectedRow, 
			selectedSeq : selectedSeq, 
			selectedType : selectedType,
			LCQuestion : LCQuestion,
			LCSide : LCSide,
			LCIndex : LCIndex,
			LCTabId : LCTabId,
			LCDdqId : LCDdqId,
			compType : compType
		};
	}

	public getPictureReferences() {
		return this.pictureReferences;
	}

	public  imageStatusRespond() {
		return this.imageStatus
	}

	/**
	 * called when save document after edit 
	 */
	public docuSaveEventEmit(){
		this.docuSaveEvent.emit()
	}
	public docuSaveEventResponse(){
		return this.docuSaveEvent;
	}

	public viewSideBarEventEmit(item:any){
		this.viewSideBar.emit({
			item:item
		})
		
	}
	public viewSideBarResponse(){
		return this.viewSideBar;
	}

	public static searchComponentEventEmit(item:any){
		this.searchComponent.emit({
			item:item
		})
		
	}
	public static searchComponentResponse(){
		return this.searchComponent;
	}

	//Folder move event
	public moveFolderItemsEvent(itemIdList: any, moveItems: boolean, itemCount: any) {
		
		this.moveFolderItems.emit(
			{
				itemIdList: itemIdList,
				moveItems: moveItems,
				itemCount:itemCount
			}
		)

	}

	public moveFolderItemsResponse() {
		return this.moveFolderItems;
	}

	//Delete folders in folder tree
	public deleteFolderFromFolderTreeRequest(folders : any){
		this.deleteFoldersFromFolderTree.emit(folders);
	}

	public deleteFolderFromFolderTreeResponse(){
		return this.deleteFoldersFromFolderTree;
	}

	/**
	 * enable and disable the folder delete icon in folder tree
	 * @param enable boolean
	 */
	public enableFolderDeleteRequest(enable: boolean){
		this.enableFolderDelete.emit(enable);
	}
	public enableFolderDeleteResponse(){
		return this.enableFolderDelete;
	}

	public setRevisionContent(content: string) {
		this.revisionContent = content;
	}

	public getRevisionContent() {
		return this.revisionContent;
	}
	public setRevisionData(revisionData) {
		this.revisionDataShared = revisionData;
	}

	public getRevisionData() {
		return this.revisionDataShared;
	}

	public setFolderTree(data : any){
		this.loadFolderTree.emit(data);
	}

	public getFolderTree(){
		return this.loadFolderTree;
	}

	public modalCloseEventEmit(){
		this.modalCloseEvent.emit()
	}
	public modalCloseEventResponse(){
		return this.modalCloseEvent;
	}

	public disablePunchlistRequest(punchlistId: string, status: string) {
		this.disablePunchlistId.emit({
			punchlistId: punchlistId,
			status: status
		})
	}

	public disablePunchlistResponse() {
		return this.disablePunchlistId;
	}

	public deletePunchlistRequest() {
		this.deletePunchlistId.emit();
	}

	public deletePunchlistResponse() {
		return this.deletePunchlistId;
	}

	/*public editPunchlistRequest(punchlistId: String) {
		this.editPunchlistId.emit({
			punchlistId: punchlistId
		})
	}*/

	public editPunchlistRequest() {
		this.editPunchlistId.emit();
	}

	public editPunchlistResponse() {
		return this.editPunchlistId;
	}

	public editIrnRequest() {
		this.editIrnId.emit();
	}

	public editIrnResponse() {
		return this.editIrnId;
	}

	public editMCCRequest() {
		this.editmcc.emit();
	}

	public editMCCResponse() {
		return this.editmcc;
	}
	public deleteIrnPunchlistIdRequest(punchlistId) {
		this.deleteIrnPunchlistId.emit(punchlistId);
	}

	public deleteIrnPunchlistIdResponse() {
		return this.deleteIrnPunchlistId;
	}
	public documentTypeJstreeRefreshRequest(){
		this.documentTypeJstree.emit();
	}

	public documentTypeJstreeRefreshResponse(){
		return this.documentTypeJstree;
	}

	/**
	 * Setting data to ag grid table after edit event emitter request 
	 */
	public setRoleTableEditRequest(data: any) {
		this.setRoleTable.emit(
			{
				data: data
			}
		)
	}
	/**
	 * open statements edit modal event emitter respond
	 */
	public setRoleTableEditRespond() {
		return this.setRoleTable
	}

	public setRoleId(roleId: string){
		this.roleId = roleId;
	}

	public getRoleId(){
		return this.roleId;
	}

	public static tabIdRequest() {
		this.activatedTab.emit();
	}

	public static tabIdRespond() {
		return this.activatedTab;
	}

	public resetEnableDisableButtonsEventEmit() {
		this.resetEnableDisableButton.emit();
	}

	public resetEnableDisableButtonsEventResponse() {
		return this.resetEnableDisableButton;
	}

	/**
	 * delete ice topic done request 
	 */
	public deleteICETopicDoneRequest(isTopic :boolean) {
		this.deleteICETopicDone.emit({
			isTopic : isTopic
		})
	}

	/**
	 * delete ice topic response response  
	 */
	public deleteICETopicDoneResponse() {
		return this.deleteICETopicDone
	}

	/**
	 * set isICETopic
	 */
	public setIsICETopic(isICETopic: boolean) {
		this.isICETopic = isICETopic;
	}

	/**
	 * get isICETopic 
	 */
	public getIsICETopic() {
		return this.isICETopic;
	}

	public setCommonICEModuleVariableNames() {
		this.commonICEModuleVariableNames['course'] = [
			'isShowIceTopic', 'isShowLearningChecks', 'isShowIceGames', 'isViewOnly', 'topicsModified', 
			'learningChecksModified', 'gameModified', 'topicsEnabled', 'learningCheckEnabled','gameEnabled', 
			'navigateToT', 'loadtopiclist', 'loadLearningChecklist', 'loadGameList', 'LearningCheckData',
			'isEnableReordering', 'isDeleteEnable', 'topicDependencies', 'topicContentDependencies', 
			'isLCDeleteEnable', 'isGameDeleteEnable', 'questionDependencies', 'gamesCount', 'modalRef',
			'selectedGameType', 'iceTopicList', 'iceGameList', 'insertSubscription',
			'iceCMCourseList','iceCMLearningCheckList', 'iceCMGameList', 'speCharList', 'editorList', 
			'editorDivList', 'iceTopicModel', 'iceLearningCheckModel', 'iceGameModel', 'topicToDelete',
			'learningCheckToDelete', 'gameToDelete', 'indexOfLearningCheckToDelete', 'indexOfGameToDelete', 
			'newLearningCheck', 'newGame', 'iceCMCoursesAvailable', 'cmLearningCheckListAvailable', 
			'cmGameListAvailable', 'selectedCMIceCourse', 'selectedCMIceCourseVersion', 'selectedCMLearningCheck',
			'selectedCMGame', 'selectedConfirmed', 'getCMLearningCheckData', 'getCMGameData', 
			'copyLearningCheckData', 'copyGameData', 'selectedCourseVersion', 'toolbarId', 'desceditor', 
			'titleditor', 'html', 'tagContent', 'selection', 'ranges', 'userId', 'rowData', 'tabId',
			'isImageReusablePanelOpened', 'newTopic', 'addFractionDIvId', 'addSpecialCharDivId', 'xmlContent', 
			'stmHtml', 'gridApi', 'getRowNodeId', 'gridColumnApi', 'rowSelection', 'imageBackground', 
			'imageBorder', 'selectedImage','selectedModalTemplate', 'modalConfig', 'glossaryEnabled', 'elementRef','editor','CKEDITOR'
		];
		this.commonICEModuleVariableNames['topic'] = [
			'isShowLearningChecks', 'isShowIceGames', 'isEnableReordering', 'isDeleteEnable', 'isViewOnly', 
			'topicsModified', 'learningChecksModified', 'gameModified', 'loadtopiclist', 'loadGameList', 
			'loadLearningChecklist', 'navigateToT', 'topicDependencies', 'topicContentDependencies', 'BsModalRef',
			'topicsEnabled', 'learningCheckEnabled', 'gameEnabled', 'isLCDeleteEnable', 'gamesCount', 
			'selectedGameType', 'LearningCheckData', 'insertSubscription',
			'iceTopicList', 'iceGameList', 'iceTopicModel', 'iceLearningCheckModel', 'learningCheckToDelete', 
			'topicToDelete', 'gameToDelete', 'indexOfLearningCheckToDelete', 'indexOfGameToDelete', 'speCharList', 
			'editorList', 'editorDivList', 'iceCMCourseList', 'iceCMLearningCheckList', 'iceCMGameList', 
			'toolbarId', 'desceditor', 'titleditor', 'html', 'tagContent', 'questionDependencies', 'selection', 
			'ranges', 'userId', 'tabId', 'newTopic', 'newLearningCheck', 'newGame', 'addFractionDIvId', 
			'addSpecialCharDivId', 'iceGameModel', 'selectedConfirmed', 'iceCMCoursesAvailable', 
			'cmLearningCheckListAvailable', 'cmGameListAvailable','selectedCMIceCourse', 'selectedCMIceCourseVersion', 
			'selectedCMLearningCheck', 'selectedCMGame', 'getCMLearningCheckData', 'getCMGameData', 
			'copyLearningCheckData', 'copyGameData', 'selectedCourseVersion', 'xmlContent', 'rowData', 'stmHtml', 
			'gridApi', 'getRowNodeId', 'gridColumnApi', 'rowSelection', 'imageBackground', 'imageBorder', 
			'selectedModalTemplate', 'modalConfig', 'selectedImage', 'gameData', 'iceLearningCheckList', 'iceCourseList',
			'breadCrumbList', 'iceCourseModel', 'iceCoursesAvailable', 'linkedCourse','topic_translated_data', 'elementRef','editor','CKEDITOR'
		];
		this.commonICEModuleVariableNames['topicContent'] = [
			'isShowLearningChecks', 'isShowIceGames', 'isViewOnly', 'modalRef', 'iceGameList',
			'speCharList', 'toolbarId', 'desceditor', 'html', 'tagContent', 'insertSubscription',
			'selection', 'ranges', 'addFractionDIvId', 'addSpecialCharDivId', 'xmlContent', 'userId', 'rowData',
			'stmHtml', 'gridApi', 'getRowNodeId', 'gridColumnApi', 'rowSelection', 'tabId', 'selectedConfirmed',
			'modalConfig', 'selectedImage', 'selectedModalTemplate', 'breadCrumbList', 'topic_translated_data',
			'iceCourseModel', 'iceCoursesAvailable', 'linkedCourse', 'iceCourseList', 'elementRef'
		];
		this.commonICEModuleVariableNames['learningCheck'] = [
			'userId', 'tabId', 'newLearningCheck', 'speCharList', 'isViewOnly', 'editorList', 'insertSubscription',
			'editorDivList', 'xmlContent', 'modalRef', 'addSpecialCharDivId', 'addFractionDIvId', 'selectedImage',
			'selectedModalTemplate', 'modalConfig', 'breadCrumbList', 'imageData','isImageDataAvailable', 'elementRef'
		];
		this.commonICEModuleVariableNames['einsteinGame'] = [
			'userId', 'tabId', 'speCharList', 'isViewOnly', 'desceditor', 'editorList', 'insertSubscription',
			'editorDivList', 'xmlContent', 'modalRef', 'addSpecialCharDivId', 'addFractionDIvId', 'gameEnabled',
			'selectedImage', 'selectedModalTemplate', 'modalConfig', 'breadCrumbList', 'gameData', 'imageData',
			'isImageDataAvailable', 'iceGame', 'gameDescriptionShowInDetails', 'gameDescription', 'currentRow',
			'currentImageType', 'gameModified', 'numOfRows', 'numOfSequences', 'numOfRowsinView', 'gameGenarated',
			'existingGameData', 'currentSeq', 'numOfSequencesinVIew', 'elementRef'
		];
		this.commonICEModuleVariableNames['memoryGame'] = [
			'userId', 'tabId', 'addSpecialCharDivId', 'addFractionDIvId', 'speCharList', 'gameEnabled', 'isViewOnly',
			'desceditor', 'isImageReusablePanelOpened', 'modalRef', 'editorList', 'insertSubscription',
			'editorDivList', 'xmlContent', 'selectedImage', 'selectedModalTemplate', 'modalConfig', 'breadCrumbList',
			'gameData', 'imageData', 'isImageDataAvailable', 'iceGame', 'gameDescription', 'gameDescriptionShowInDetails',
			'gameModified', 'gameGenarated', 'currentRow', 'currentSeq', 'currentImageType', 'numOfRows', 
			'numOfSequences', 'numOfRowsinView', 'existingGameData', 'elementRef'
		];
		this.commonICEModuleVariableNames['SpeedMatchGame'] = [
			'userId', 'tabId', 'speCharList', 'isViewOnly', 'desceditor', 'editorList', 'insertSubscription',
			'editorDivList', 'xmlContent', 'modalRef', 'addSpecialCharDivId', 'addFractionDIvId', 'gameEnabled',
			'selectedImage', 'selectedModalTemplate', 'modalConfig', 'breadCrumbList', 'gameData', 'imageData',
			'isImageDataAvailable', 'iceGame', 'gameDescriptionShowInDetails', 'gameDescription', 'currentRow',
			'currentImageType', 'gameModified', 'numOfRows', 'numOfSequences', 'numOfRowsinView', 'numOfSequencesinVIew',
			'gameGenarated', 'existingGameData', 'elementRef'
		];
	}

	public getCommonICEModuleVariableNames(iceComponentType: string) {
		return this.commonICEModuleVariableNames[iceComponentType];
	}

	public setCommonICEModuleVariableValues(selectedTab: string, selectedModuleType: string, variableValues : any, varName : string = null) {
		if(varName == null) {
			this.commonICEModuleVariableValues[selectedTab] = variableValues;
		} else {
			this.commonICEModuleVariableValues[selectedTab][varName] = variableValues;
		}
	}

	public getCommonICEModuleVariableValues(selectedTab: string) {
		return this.commonICEModuleVariableValues[selectedTab];
	}

	public setLoadedComponent(loadedComponent: string) {
		this.loadedComponent = loadedComponent
	}

	public getLoadedComponent() {
		return this.loadedComponent;
	}

	public loadAttributeListEventEmit(){
		this.loadAttributeList.emit();		
	}

	public loadAttributeListEventResponse(){
		return this.loadAttributeList;
	}

	/**
	 * Achini Randeni
	 * set selected tag inputs for a given key
	 * @param key 
	 * @param tagInputs 
	 */
	public setTagInputList(tagInputKey: string, tagInputs: any) {
		let tempTagInputList = this.tagInputList.filter(i=>i.key == tagInputKey)
		//update only the value for a existing key
		if(tempTagInputList.length > 0){
			tempTagInputList[0].value = tagInputs
		}else{
			this.tagInputList.push({key: tagInputKey, value: tagInputs})
		}	
	}

	/**
	 * Achini Randeni
	 * Get selected tag input list
	 */
	public getTagInputList() {
		return this.tagInputList;
	}

	/**
	 * Achini Randeni
	 * Remove tag input for a given index
	 * @param index 
	 */
	public removeTagInput(index: number){
		if (index !== -1) {
			this.tagInputList.splice(index, 1);
		}  
	}

	public setDependencyTypeList(dependencyTypeList: Array<string>){
		this.dependencyTypeList = dependencyTypeList;
	}
	public getDependencyTypeList(){
		return this.dependencyTypeList
	}
	public resetDependencyTypeList(){
		this.dependencyTypeList = null;
	}
	/**
	 * set multiple selected items
	 * @param multipleItems 
	 */
	public setMultipleSelectedItems(multipleItems: any){
			this.multipleSelectedList = multipleItems;
		}
	
	/**
	 * get multiple selected items
	 */
	public getMultipleSelectedItems(){
		return this.multipleSelectedList;
	}

	public auth0LoginRequest( userEmail:string, emailValidated:string) {
		let userData = {'userEmail': userEmail, 'emailValidated' :emailValidated}
		this.auth0Login.emit(userData);
	}

	public auth0LoginResponse() {
		return this.auth0Login;
	}

	public selectedFileDataChangedEmit(){
		this.selectedFileDataChanged.emit();
	}

	public selectedFileDataChangedResponse(){
		return this.selectedFileDataChanged;
	}

	public changeModalSizeRequest() {
		this.changeModalSize.emit();
	}

	public changeModalSizeExecute() {
		return this.changeModalSize;
	}

	public editCheckRequest(){
		this.editCheckId.emit();
	}

	public editCheckResponse(){
		return this.editCheckId;
	}
}

