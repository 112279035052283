import { Component, EventEmitter, OnInit } from '@angular/core';
import { LoadingService } from '../../util/loading/loading.service';
//import { OutputManagementService } from '../../systemsetting/outputmanagement/outputmanagement.service';
import { AlertService } from '../../util/alert/alert.service';
import * as moment from 'moment';
//import { ReusabletypedropdownComponent } from '../reusabletypedropdown/reusabletypedropdown.component';
import { TranslateService } from '@ngx-translate/core';
import { GridOptions, RowNode, Module, AllModules } from "@ag-grid-enterprise/all-modules";
import {ReusableUpdateService} from './reusableupdateservice';
import {ReusableUpdateCellRenderComponent}  from './reusableupdate-cell-render-component.component'
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-reusableupdate',
  templateUrl: './reusableupdate.component.html',
  styleUrls: ['./reusableupdate.component.css']
})
export class ReusableupdateComponent implements OnInit {

   bsEndValue: Date = new Date();
   bsStartValue: Date = new Date();
   startValue: any;
   endValue: any;
   onChangedStatusId: String = "-1";
   onChangeItemTypeId: String = "selectedItemType";
   selectedReusableType: String = "";
   rowData: any[] = [];
   public modules: Module[] = AllModules;
   loadingMessage: string;
   columnDefs;
   public frameworkComponents;
   private gridApi;
   private gridColumnApi;
   public gridOptions: GridOptions;
   public defaultColDef: any;
   public reusableTypeList: Array<any> = [];
   reusableUpdate_data: any = null;
   public statusList: Array<any> = [];
   public isMinimized: boolean = false
   isDateChanged: boolean = false;
   firstTimeLoadData: boolean = true;
   requestSent: boolean = false;
   cancelPreviousRequests: EventEmitter<any> = new EventEmitter()
  // private bsModalRef: BsModalRef;
  
  constructor(private loadingService: LoadingService, private reusableUpdateService: ReusableUpdateService,
  private alertService: AlertService , private translate: TranslateService ) {

	let browserLang = translate.getBrowserLang();
	this.translate.get('REUSABLE_UPDATE.LOADING_MESSAGE').subscribe((res: string) => {
		this.loadingMessage = res;
	 });

	 translate.getTranslation(browserLang).subscribe((res: string) => {
		this.reusableUpdate_data = res['REUSABLE_UPDATE']
		this.statusList = [{ 'id': '-1', 'text': 'All' }, 
		{ 'id': '0', 'text': this.reusableUpdate_data.NEW },
		{ 'id': '1', 'text': this.reusableUpdate_data.PROCESSING },
		{ 'id': '2', 'text': this.reusableUpdate_data.RE_QUEUED },
		{ 'id': '3', 'text': this.reusableUpdate_data.COMPLETED },
		{ 'id': '4', 'text': this.reusableUpdate_data.FAILED },
		{ 'id': '5', 'text': this.reusableUpdate_data.OVER_RIDDEN }]

	this.reusableTypeList = [{ 'id': "All", 'text': 'All' },
	{ 'id': 'TagNumber', 'text': this.reusableUpdate_data.TAG_NUMBER },
	{ 'id': 'TagDescription', 'text': this.reusableUpdate_data.TAG_DESCRIPTION },
	{ 'id': 'TagDocument', 'text': this.reusableUpdate_data.TAG_DOCUMENT },
	{ 'id': 'TagSystem', 'text': this.reusableUpdate_data.TAG_SYSTEM },
	{ 'id': 'TagAttribute', 'text': this.reusableUpdate_data.TAG_ATTRIBUTE },
	{ 'id': 'Document', 'text': this.reusableUpdate_data.DOCUMENT },
	{ 'id': 'System', 'text': this.reusableUpdate_data.SYSTEM },
	{ 'id': 'SystemNo', 'text': this.reusableUpdate_data.SYSTEM_NO },
	{ 'id': 'Statement', 'text': this.reusableUpdate_data.STATEMENT },
	{ 'id': 'Component', 'text': this.reusableUpdate_data.COMPONENT },
	//{ 'id': 'Image', 'text': this.reusableUpdate_data.IMAGE },
	{ 'id': 'Facility', 'text': this.reusableUpdate_data.FACILITY },
	]

		 this.columnDefs = [
			 {
				 headerName: "", field: "dependencies", autoHeight: true, cellClass: "cell-wrap-text", 
				 minWidth: 50, suppressFiltersToolPanel: true, cellRendererFramework: ReusableUpdateCellRenderComponent,
			 },
			 {
				 headerName: this.reusableUpdate_data.REUSABLE_ID, field: 'reusableId', autoHeight: true, 
				 cellClass: "cell-wrap-text", minWidth: 250, suppressFiltersToolPanel: true,
			 },
			 {
				 headerName: this.reusableUpdate_data.REUSABLE_TYPE, field: 'reusableType', 
				 autoHeight: true, cellClass: "cell-wrap-text", minWidth: 130,
			 },
			 {
				 headerName: this.reusableUpdate_data.OLD_VALUE, field: 'oldValue', 
				 autoHeight: true, cellClass: "cell-wrap-text", minWidth: 150, suppressFiltersToolPanel: true,
				 cellRenderer: function (params: any) {
					var eDiv = document.createElement('div');
					eDiv.innerHTML = params.data.oldValue;
					return eDiv;
				}
			 },
			 {
				 headerName: this.reusableUpdate_data.NEW_VALUE, field: 'newValue', 
				 autoHeight: true, cellClass: "cell-wrap-text", minWidth: 150, suppressFiltersToolPanel: true,
				 cellRenderer: function (params: any) {
					var eDiv = document.createElement('div');
					eDiv.innerHTML = params.data.newValue;
					return eDiv;
				}
			 },
			 {
				 headerName: this.reusableUpdate_data.INITIATED_USER, field: 'initiatedUserName', 
				 autoHeight: true, cellClass: "cell-wrap-text", minWidth: 140,
			 },
			 {
				 headerName: this.reusableUpdate_data.INITIATED_TIME, field: 'initiatedTimeStamp', 
				 autoHeight: true, cellClass: "cell-wrap-text", minWidth: 160, suppressFiltersToolPanel: true,
			 },
			 {
				 headerName: this.reusableUpdate_data.STATUS, field: 'status', 
				 autoHeight: true, cellClass: "cell-wrap-text", minWidth: 100, suppressFiltersToolPanel: true,
			 },
			 {
				 headerName: "", field: 'moreInfo', autoHeight: true, cellClass: "cell-wrap-text", 
				 minWidth: 50, suppressFiltersToolPanel: true, cellRendererFramework: ReusableUpdateCellRenderComponent,
			 },
			 {
				 headerName: "", field: 'details', autoHeight: true, cellClass: "cell-wrap-text", 
				 minWidth: 50, suppressFiltersToolPanel: true, cellRendererFramework: ReusableUpdateCellRenderComponent,
			 },
			 {
				 headerName: this.reusableUpdate_data.COMPLETED_PERCENTAGE, field: 'percentageComplete', 
				 autoHeight: true, cellClass: "cell-wrap-text", minWidth: 170,
			 },
			 {
				 headerName: this.reusableUpdate_data.COMPLTED_TIME, field: 'completedTimeStamp', 
				 autoHeight: true, cellClass: "cell-wrap-text", minWidth: 160, suppressFiltersToolPanel: true,
			 },
			 {
				 headerName: this.reusableUpdate_data.ACTION, field: 'action', autoHeight: true, 
				 cellClass: "cell-wrap-text", minWidth: 130, suppressFiltersToolPanel: true, cellRendererFramework: ReusableUpdateCellRenderComponent
			 }]
	 });

	 this.defaultColDef = {
		resizable: true,
		sortable: true,
	}

	}

  ngOnInit() { 
	  this.bsStartValue.setDate(this.bsStartValue.getDate() - 7);
	  this.gridOptions = {
		getRowNodeId: (data) => { return data.id; }
	  }
	  setTimeout(() => {
		this.getReusableUpdateData(true, true);
	  }, 10);
  }

  public reusableItemChanged(event) {
	this.selectedReusableType = event.id
	this.firstTimeLoadData = true;
	if(this.requestSent){
	   this.cancelPreviousRequests.emit();
	   this.getReusableUpdateData(true, true)
	}
	else{
	   this.getReusableUpdateData(true, false)
	}
 }

 public selectedStatusChanged(event) {
	this.onChangedStatusId = event.id
	 this.firstTimeLoadData = true;
	 if(this.requestSent){
		this.cancelPreviousRequests.emit();
		this.getReusableUpdateData(true, true)
	 }
	 else{
		this.getReusableUpdateData(true, false)
	 }
 }
  
  public onStartValueChange(value: Date): void {
	this.startValue = value
	this.firstTimeLoadData = true;
	if(this.requestSent){
	   this.cancelPreviousRequests.emit();
	   this.getReusableUpdateData(true, true)
	}
	else{
	   this.getReusableUpdateData(true, false)
	}
	this.isDateChanged = true;
 }

 public onEndValueChange(value: Date): void {
	this.endValue = value
	this.firstTimeLoadData = true;
	if(this.requestSent){
	   this.cancelPreviousRequests.emit();
	   this.getReusableUpdateData(true, true)
	}
	else{
	   this.getReusableUpdateData(true, false)
	}
	this.isDateChanged = true;
 }

 public loadData(data: any, looping: boolean) {
	 if(this.firstTimeLoadData || (data.length < this.rowData.length)){
		this.rowData = [];
		for (let i = 0; i < data.length; i++) {
			let requeList = [];
			let processingList = [];
			let mostRecentUpdate: any;
			requeList = data.filter(obj => obj.reusableId == data[i]["reusableId"] && obj.reusableType == data[i]["reusableType"] && obj.status == "4");
			if (requeList.length > 0) {
				let mostRecentDate = new Date(Math.max.apply(null, requeList.map(e => {
					return new Date(e.initiatedTimeStampUTC);
				})));
				mostRecentUpdate = requeList.find( e => { 
					let d = new Date(e.initiatedTimeStampUTC); 
					return d.getTime() == mostRecentDate.getTime();
				});
				if(mostRecentUpdate != undefined) {
					processingList = requeList.reduce((a, o) => ((o.id != mostRecentUpdate.id) && a.push(o.id), a), []); 
				}
			}			
			this.rowData.push({
				id: data[i]["id"],
				dependencies: "dependencies:" + data[i]["reusableId"] + ':' + data[i]["reusableType"],
				reusableId: data[i]["reusableId"],
				reusableType: data[i]["reusableType"],
				oldValue: data[i]["oldValue"],
				newValue: data[i]["newValue"],
				initiatedUserName: data[i]["initiatedUserName"],
				initiatedTimeStamp: data[i]["initiatedTimeStamp"],
				status: this.processData(data[i]["status"]),
				moreInfo: data[i]["status"] == "4" ? "moreInfo:" + data[i]["reusableId"] + ':' + data[i]["reusableType"] : "noMoreInfo:" + data[i]["reusableId"],
				details: data[i]["status"] == "4" ? "details:" + data[i]["reusableId"] + ':' + data[i]["reusableType"] : "noDetails:" + data[i]["reusableId"],
				percentageComplete: data[i].percentageComplete + ".0%",
				completedTimeStamp: data[i]["completedTimeStamp"],
				action: data[i]["status"] == "4" &&  mostRecentUpdate != undefined && mostRecentUpdate.initiatedTimeStamp == data[i]["initiatedTimeStamp"]? "action:" + data[i]["reusableId"] + ":" + data[i]["id"] + ":" + processingList : "antiaction:" + data[i]["reusableId"]
			})
			this.firstTimeLoadData=false;
		}
	}
	else{
		for (let i = 0; i < data.length; i++) {
			let requeList = [];
			let processingList = [];
			let mostRecentUpdate: any;
			requeList = data.filter(obj => obj.reusableId == data[i]["reusableId"] && obj.reusableType == data[i]["reusableType"] && obj.status == "4");
			if (requeList.length > 0) {
				let mostRecentDate = new Date(Math.max.apply(null, requeList.map(e => {
					return new Date(e.initiatedTimeStampUTC);
				})));
				mostRecentUpdate = requeList.find( e => { 
					let d = new Date(e.initiatedTimeStampUTC); 
					return d.getTime() == mostRecentDate.getTime();
				});
				if(mostRecentUpdate != undefined) {
					processingList = requeList.reduce((a, o) => ((o.id != mostRecentUpdate.id) && a.push(o.id), a), []); 
				}
			}	
			let rowNode : RowNode = this.gridApi.getRowNode(data[i]["id"]);
			if(rowNode !=null){
				rowNode.data.id = data[i]["id"],
				rowNode.data.dependencies = "dependencies:" + data[i]["reusableId"] + ':' + data[i]["reusableType"],
				rowNode.data.reusableId = data[i]["reusableId"],
				rowNode.data.reusableType = data[i]["reusableType"],
				rowNode.data.oldValue = data[i]["oldValue"],
				rowNode.data.newValue = data[i]["newValue"],
				rowNode.data.initiatedUserName  = data[i]["initiatedUserName"],
				rowNode.data.initiatedTimeStamp = data[i]["initiatedTimeStamp"],
				rowNode.data.status = this.processData(data[i]["status"]),
				rowNode.data.moreInfo = data[i]["status"] == "4" ? "moreInfo:" + data[i]["reusableId"] + ':' + data[i]["reusableType"] : "noMoreInfo:" + data[i]["reusableId"],
				rowNode.data.details = data[i]["status"] == "4" ? "details:" + data[i]["reusableId"] + ':' + data[i]["reusableType"] : "noDetails:" + data[i]["reusableId"],
				rowNode.data.percentageComplete = data[i].percentageComplete + ".0%",
				rowNode.data.completedTimeStamp = data[i]["completedTimeStamp"],
				rowNode.data.action = data[i]["status"] == "4" &&  mostRecentUpdate != undefined && mostRecentUpdate.initiatedTimeStamp == data[i]["initiatedTimeStamp"]? "action:" + data[i]["reusableId"] + ":" + data[i]["id"] + ":" + processingList : "antiaction:" + data[i]["reusableId"]
				this.gridApi.applyTransaction({ update: [rowNode.data] });
			}
			else{
				let newRow = {
					id: data[i]["id"],
					dependencies: "dependencies:" + data[i]["reusableId"] + ':' + data[i]["reusableType"],
					reusableId: data[i]["reusableId"],
					reusableType: data[i]["reusableType"],
					oldValue: data[i]["oldValue"],
					newValue: data[i]["newValue"],
					initiatedUserName: data[i]["initiatedUserName"],
					initiatedTimeStamp: data[i]["initiatedTimeStamp"],
					status: this.processData(data[i]["status"]),
					moreInfo: data[i]["status"] == "4" ? "moreInfo:" + data[i]["reusableId"] + ':' + data[i]["reusableType"] : "noMoreInfo:" + data[i]["reusableId"],
					details: data[i]["status"] == "4" ? "details:" + data[i]["reusableId"] + ':' + data[i]["reusableType"] : "noDetails:" + data[i]["reusableId"],
					percentageComplete: data[i].percentageComplete + ".0%",
					completedTimeStamp: data[i]["completedTimeStamp"],
					action: data[i]["status"] == "4" &&  mostRecentUpdate != undefined && mostRecentUpdate.initiatedTimeStamp == data[i]["initiatedTimeStamp"]? "action:" + data[i]["reusableId"] + ":" + data[i]["id"] + ":" + processingList : "antiaction:" + data[i]["reusableId"]
				}
				this.gridApi.applyTransaction({ add: [newRow], addIndex: 0 });
			}
		}

	}
	this.requestSent = false;
	if(looping){
		setTimeout(() => { this.getReusableUpdateData(false, true) }, 10000);
	}
}

 refreshTable()
 {
	 this.getReusableUpdateData(true, false);
 }

 processData(status: any)
 {
		 console.log("process");
		 if( status == 0)
		 {
		  return "New";
		 }else if(status == 1)
		 {
		  return "Processing";
		 }else if( status == 2)
		 {
		  return "Re queued";
		 }else if(status == 3)
		 {
		  return "Completed";
		 }else if(status == 4)
		 {
		  return "Failed";
		 }
		 else if(status == 5)
		 {
		  return "Over Ridden";
		 }
 }

 public getReusableUpdateData(showLoading: boolean, looping: boolean) {
	let now = moment(this.endValue);
	let then = moment(this.startValue);
	if ((now > then)) {
		if(showLoading)
		{
		  this.loadingService.showLoading(true, null, this.loadingMessage, null)
		}
		  if( this.selectedReusableType == 'All')
		  {
			  this.selectedReusableType = "";
		  }
		  this.requestSent = true;
		  this.reusableUpdateService.getReusableSearchResults(this.selectedReusableType, "", "",this.onChangedStatusId,then.format("MM/DD/YYYY"), now.format("MM/DD/YYYY"))
			 .pipe(takeUntil(this.cancelPreviousRequests)).subscribe(data => {
				if(showLoading)
				{
				this.loadingService.hideLoading()
				}
				//this.rowData = data.payload;
				//this.processData();
				//console.log(this.rowData)
				for (let i = 0; i < data.payload.length; i++) {
					data.payload[i].newValue != null ? data.payload[i].newValue = data.payload[i].newValue.replace("<tagdesc><txt>", "") : "";
					data.payload[i].newValue != null ? data.payload[i].newValue = data.payload[i].newValue.replace("</txt></tagdesc>", "") : "";
					data.payload[i].oldValue != null ? data.payload[i].oldValue = data.payload[i].oldValue.replace("<tagdesc><txt>", "") : "";
					data.payload[i].oldValue != null ? data.payload[i].oldValue = data.payload[i].oldValue.replace("</txt></tagdesc>", "") : "";
				}
				this.loadData(data.payload, looping);
			 },
				error => {
					if(showLoading)
					{
				   this.loadingService.hideLoading()
					}
				   this.alertService.clear()
				   this.alertService.error(error.statusDescription)
				});
	//    } else {
	// 	  this.alertService.clear()
	// 	  this.alertService.error("Please select an item type.")
	   //}
	} else if ((now <= then) && this.isDateChanged){
	   this.alertService.clear()
	   this.alertService.error("End Date should be greater than Start date.")
	}
 }

 onColumnResized(event) {
	if (event.finished) {
	   this.gridApi.resetRowHeights();
	}
 }

 onGridReady(params) {
	this.gridApi = params.api;
	this.gridColumnApi = params.columnApi;
	params.api.sizeColumnsToFit();
	//this.getAllOutputs();
	setTimeout(function () {
	   params.api.sizeColumnsToFit();
	   params.api.resetRowHeights();
	}, 500);
 }

 ngOnDestroy() {
	this.gridApi.destroy();
	this.gridApi = null;
}




}
