import { Component, OnInit, TemplateRef } from '@angular/core';
import { AlertService } from 'src/app/util/alert/alert.service';
import { ReportDataService } from '../../report-data-service.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal'
import { TranslateService } from '@ngx-translate/core';



@Component({
	selector: 'app-report-image-cell-render',
	templateUrl: './report-image-cell-render.component.html',
	styleUrls: ['./report-image-cell-render.component.css']
})
export class ReportImageCellRenderComponent implements OnInit {

	params: any;
	id: any;
	fullImagePath: any;
	thumbnailImagePath: any;
	base64thumb: any = null;   //thumbnaild image data
	base64FullImage: any = null;  //full image data
	public title: any;
	loadedThumb: boolean = false;
	loadedImage: boolean = false;
	modalRef: BsModalRef;
	

	public modalConfig: any = {
		backdrop: true,
		// ignoreBackdropClick: true,
		class: 'modal-lg',
		// keyboard: false
	}

	constructor(private translate: TranslateService, private alertService: AlertService, private reportDataService: ReportDataService, private modalService: BsModalService) { }

	

 	ngOnInit() {
 	}

	agInit(params: any): void{
		this.params = params;
		let items = params.value.split(':')
		this.id = items[0];
		this.fullImagePath = items[1];
		this.thumbnailImagePath = items[2];
		this.title = items[3];
		this.getImageThumb();
	}


	showLargeImage(template: TemplateRef<any>) {
		console.log('[showLargeImage]');
		if(this.base64FullImage == null ){
			this.getFullImage();
		}
		this.modalRef = this.modalService.show(template, this.modalConfig);
	}

	public modelRefCloseEvent() {
		this.modalRef.hide()
	}

	getImageThumb() {
		let to: any = false;
		if (to) { clearTimeout(to); }
		to = setTimeout(() => {
			this.reportDataService.getImage(this.id, this.thumbnailImagePath).subscribe(
				data => {
					if (data.payload) {
						this.base64thumb = data.payload;
						this.loadedThumb = true;
					} else {
						//do nothing
					}
				},
				error => {
					this.alertService.error(error.statusDescription)
				});
		}, 200);
	}
		
	getFullImage() {
		let to: any = false;
		if (to) { clearTimeout(to); }
		to = setTimeout(() => {
			this.reportDataService.getImage(this.id, this.fullImagePath).subscribe(
				data => {
					if (data.payload) {
						this.base64FullImage = data.payload;
						this.loadedImage = true;
						// console.log("received= " + JSON.stringify(data.payload) );
					} else {
						//do nothing
					}
				},
				error => {
					this.alertService.error(error.statusDescription)
				});
		}, 200);
	}

}
