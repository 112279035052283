import { Component, OnInit, QueryList, ElementRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FloatingButtonCallback } from '../floating-button-callback.interface';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/util/alert/alert.service';
import { DocumenttypetreeComponent } from 'src/app/util/documenttypetree/documenttypetree.component';
import { LoadingService } from 'src/app/util/loading/loading.service';
import { CommonUtil } from 'src/app/util/common/common-util';
import { UserVariable } from 'src/app/util/common/user-variable';
import {FileUploadService} from 'src/app/home/files/new-multiple-images/file-upload.service';
import { WsCallback } from '../../../util/ws-callback.interface';
import { WsResponse } from '../../../util/ws-response.model';
import { WsType } from '../../../util/ws-type';
import { DocumentTypeService } from 'src/app/util/documenttypetree/documenttype.service';

@Component({
  selector: 'app-new-multiple-images',
  templateUrl: './new-multiple-images.component.html',
  styleUrls: ['./new-multiple-images.component.css']
})
export class NewMultipleImagesComponent implements OnInit {
  
  public title: string;
	public selectedFolder: any;
	public floatingButtonCallback: FloatingButtonCallback;
	public attributeList: any;
	public haveProperties: boolean = true;
	public documentType: string;
	public uploadFileName: string;
	public uploadFileExt: string;
	public fileComment: string;
	public fileTitle: string;

	private treeBsModalRef: BsModalRef;
	private documentTypeId: string;
 	private uploadingFile: File[] = [];
	private uploadingFileName: string[]=[];
	selectedType: string;
  constructor(public bsModalRef: BsModalRef, private translate: TranslateService, private alert: AlertService, private modalService: BsModalService, 
    private loadingService: LoadingService, private commonUtil: CommonUtil, private fileUploadService: FileUploadService,  private documentTypeService: DocumentTypeService) { }

  ngOnInit() {
  }

  fileAdded(event) {
    if (event.target.files.length) {
		
		for (let i =  this.uploadingFile.length; i >=0; i--) {
			this.uploadingFile.splice(i,1);
        }
        for (let i = 0; i < event.target.files.length; i++) {
			let file = <File>event.target.files[i];
			this.uploadingFile.splice(i,0,file);
			this.uploadingFileName.splice(i,0,file.name);
        }
    }
}
  onFileChanged(event){
    this.uploadingFile.pop[0] = <File>event.target.files[0];
    if (this.uploadingFile.pop[0] != undefined) {
		// let parts = this.uploadingFile.name.split('.')
        let parts = this.uploadingFile.pop[0].name.split('.')
        if (parts.length > 1) {
            this.uploadFileExt = parts.pop()
            this.uploadFileName = parts.join('.')
            this.fileTitle = parts.join('.')
			}else{
				this.translate.get('NEW_IMAGE.INVALID_FILE_NAME').subscribe((res: string) => {
					this.alert.error(res, false);
				});
		}
	} else {
		this.uploadFileName = '';
    }
  }

  /**
	 * Web service call to load document types.
	 */
	public loadDocumentTypes() {
		const initialState = {
			title: 'Select a Document Type',
			treeListener: this,
			initial: true
		};
		this.treeBsModalRef = this.modalService.show(DocumenttypetreeComponent, { initialState, class: 'modal-lg' });
		this.treeBsModalRef.content.closeBtnName = 'Close';
  }
  
  /**
	 * Clicks on the submit button
	 */
	public onSubmitButtonClick() {
		this.onUpload();
	}

  /**
	 * Call web services to upload file and data.
	 */
	onUpload() {
		if (!this.validateForm()) {
			return;
		}

		var loadingMessage;
		this.translate.get('NEW_MLTIPLE_IMAGE.LOADING_MESSAGE').subscribe((res: string) => {
			loadingMessage = res;
		});
		this.loadingService.showLoading(true, null, loadingMessage, null);
		var attributeValueXml: string = '';
		//creating attribute xml string.
		attributeValueXml = '<attrs>';
		if (this.attributeList != undefined && this.attributeList.length != undefined && this.attributeList.length > 0) {
			this.attributeList.forEach(item => {
				attributeValueXml += "<attr type=\"text\" name='" + item.attributeName + "' typeattributeid='" + item.attributeId + "'>" + item.value + "</attr>";
			});
		}
		attributeValueXml += '</attrs>';
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
		const uploadData = new FormData();
		// uploadData.append("fileName", this.uploadFileName);
		// uploadData.append("fileNameExt", this.uploadFileExt);
		// uploadData.append("title", this.fileTitle);
		uploadData.append("typeId", this.documentTypeId);
		uploadData.append("comment", this.fileComment);
		uploadData.append("parentFolderId", this.selectedFolder.id);
		uploadData.append("attributes", attributeValueXml);
        uploadData.append("token", token);
		// uploadData.append("file", this.uploadingFile, this.uploadFileName);
    if (this.uploadingFile.length) {
      for (let i = 0; i < this.uploadingFile.length; i++) {
		  uploadData.append("file", this.uploadingFile[i], this.uploadingFile[i].name);
		  uploadData.append("fileName",this.uploadingFileName[i]);
          console.log("file name: " + this.uploadingFile[i].name);
      }
  }
		this.fileUploadService.uploadFile(uploadData, this);
  }
  
  /**
	 * Validate the form for compulsory fields.
	 */
	validateForm(): boolean {
		if (this.uploadingFile.pop[0] == undefined) {
			return false;
		}
		if(this.fileTitle != undefined) {
			this.fileTitle = this.fileTitle.trim()
		}
		if (this.fileTitle) {
		} else {
			return false;
		}
		if (this.documentTypeId) {
		} else {
			this.documentType = "";
			return false;
		}
		// if (this.uploadingFile.size > 104857601 ){
		// 	this.translate.get('NEW_MLTIPLE_IMAGE.SIZE_LIMIT_EXCEEDED').subscribe((res: string) => {
		// 	  this.alert.error(res, false);
		// 	});
		// return false;
		// }

		//Achini Randeni
		//validate the file type before submitting
		if(!this.isValidFileType()){
			this.translate.get('NEW_MLTIPLE_IMAGE.INVALID_FILE_TYPE').subscribe((res: string) => {
				this.alert.error(res, false);
			  });
			  return false;
		}
		
		if (this.selectedType != this.documentType) {
			this.documentType = "";
			return false;
		}

		//Check for attribute values
		if (this.attributeList != undefined && this.attributeList.length != undefined && this.attributeList.length > 0) {
			var isEmptyAttribute: boolean = false;
			this.attributeList.forEach(item => {
				if(item.required == 'yes' && (item.value == null || item.value == "")){
					this.alert.error(this.translate.instant('NEW_MLTIPLE_IMAGE.ATTRIBUTE.EMPTY_ATTRIBUTE_ERROR_MESSAGE'));
					isEmptyAttribute = true;
				}
				// if (item.attributeName == 'Document') {
				// 	if (item.documents != undefined) {
				// 		isEmptyAttribute = false;
				// 	}
				// } else if (item.attributeName == 'c1') {
				// 	if (item.components != undefined) {
				// 		isEmptyAttribute = false;
				// 	}
				// } else if (item.attributeName == 'System') {
				// 	if (item.systems != undefined) {
				// 		isEmptyAttribute = false;
				// 	}
				// }
			});
			if (isEmptyAttribute) {
				return false;
			}
		}

		return true;
	}
  
  /**
	 * Callback method for the successful web service calls.
	 * @param data web service response data
	 * @param serviceType service type id
	 */
	onSuccess(data: WsResponse, serviceType: WsType) {
		if (serviceType == WsType.DOCUMENT_TYPE_ATTRIBUTES_VIEW) {
			this.attributeList = data.payload;
			if (data.payload.length == 0) {
				this.haveProperties = false;
			} else {
				this.haveProperties = true;
			}
		} else if (serviceType == WsType.ADD_NEW_MULTIPLE_IMAGE) {
			this.alert.success(data.statusDescription);
			this.floatingButtonCallback.hide();
			this.loadingService.hideLoading();
		}
	}

	/**
	 * Callback method for the unsuccessful web service calls.
	 * @param data web service response data
	 * @param serviceType service type id
	 */
	onFail(data: WsResponse, serviceType: WsType) {
		if (serviceType == WsType.DOCUMENT_TYPE_ATTRIBUTES_VIEW) {
			this.alert.error(data.statusDescription, false);
		} else if (serviceType == WsType.ADD_NEW_MULTIPLE_IMAGE) {
			this.alert.error(data.statusDescription, false);
			this.loadingService.hideLoading();
		}
  }
  
    /**
     * Clicks on the save button of the tree view popup
     * @param file selected file
     * @param texts text element
     * @param options options element
     * @param documentTypeAttributes selected attributes
     */
    onSaveButtonClick(file: any, texts: QueryList<ElementRef>, options: QueryList<ElementRef>, documentTypeAttributes: any) {
      this.documentTypeService.getDocumentTypeAttributes(file.node.id, this.selectedFolder, this);
      this.documentType = file.node.text;
      this.selectedType = file.node.text;
      this.documentTypeId = file.node.id;
      this.treeBsModalRef.hide();
  }

  checkComponent(type:any, name:any){
	if(type == 'Component' || name == 'c1' ){
	   return true;
   }
}

checkSystem(type:any, name:any){
	 if(type == 'System' || name == 'System' ){
	   return true;
   }
}
checkDocument(type:any, name:any){
	 if(type == 'Document' || name == 'Document'){
	   return true;
   }
}

/**
 * Achini Randeni
 * validate file type
 */
isValidFileType() : boolean{
	var isValid = false;
	for(let i = 0; i < this.uploadingFile.length; i++){
		if(this.uploadingFile[i].type == 'image/png' || this.uploadingFile[i].type == 'image/jpg' || this.uploadingFile[i].type == 'image/jpeg' || this.uploadingFile[i].type == 'image/gif'){
			isValid = true;
		}else{
			isValid = false;
			break;
		}
	}
	return isValid;
}

}
