import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { LoadingService } from 'src/app/util/loading/loading.service';
import { ReceiveChecksheetService } from '../receivechecksheets.service';
import { AlertService } from 'src/app/util/alert/alert.service';
import { UserVariable } from 'src/app/util/common/user-variable';
import * as FileSaver from 'file-saver';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ApproveCheckSheetComponent } from '../approvechecksheet/approvechecksheet.component';

@Component({
    selector: 'receivechecksheet-cell-renderer',
    templateUrl: './receivechecksheet-cell-renderer.component.html'
})
export class ReceiveChecksheetCellRendererComponent{
    icon: string;
    status: string;
    type: string;
    data: string
    isUploaded: string;
    csInstanceId: string;
    assetName: string;
    systemName: string;
    checksheetName: string;
    bsModalRef: BsModalRef

    constructor(private loadingService: LoadingService, private receiveChecksheetService: ReceiveChecksheetService,
        private alertService: AlertService, private modalService: BsModalService){

    }

    agInit(params: any){
        if(params.data){
            this.csInstanceId = params.data.csInstanceId;
            this.assetName = params.data.assetNo;
            this.systemName = params.data.systemName;
            this.checksheetName = params.data.checksheet;
        } else{
            //do nothing
        }
        
        let items = params.value.split(':')
        if (items.length >= 2) {
            this.status = items[1];
            if (items.length == 3) {
                this.isUploaded = items[2];
            }
        }
        this.setType(items[0]);
    }

    /**
     * set the icon
     * @param params 
     */
    setType(params: any){
        this.type = params;
        if(params == 'enterData'){
            this.icon = 'fas fa-keyboard';
        } else if(params == 'sentPdf' && (this.status == "Issued" || this.status == "Approved" || this.status == "DataSaved")){
            this.icon = 'fas fa-file-pdf';
        } else if(params == 'returnedPdf' && (this.status == "Approved" || this.status == "DataSaved") && this.isUploaded == "true"){
            this.icon = 'fas fa-file-pdf';
        } else{
            // do nothing
        }
    }

    /**
     * execute the button click events
     * @param type 
     */
    invoke(type: string){
        const initialState = {
            csInstanceId : this.csInstanceId
        }
        switch(type){
            case 'enterData':
                this.bsModalRef = this.modalService.show(ApproveCheckSheetComponent, { class: 'modal-xl', backdrop: 'static', keyboard: false, initialState })
            break;
            case 'sentPdf':
                this.downloadSentPDF()
            break;
            case 'returnedPdf':
                this.downloadReturnedPdf()
            break;
        }
    }

    /**
     * download the sent pdf
     */
    downloadSentPDF(){
        this.receiveChecksheetService.downloadSentFile(UserVariable.projectId, this.csInstanceId)
        .subscribe(response => {
                this.loadingService.hideLoading()
                let file = new Blob([response], { type: 'application/pdf' });
                if(this.assetName){
                    FileSaver.saveAs(file, this.systemName + "_" + this.assetName + "_" + this.checksheetName + ".pdf");
                } else{
                    FileSaver.saveAs(file, this.systemName + "_" + this.checksheetName + ".pdf");
                }
                
            },
            error => {
                this.loadingService.hideLoading()
                this.alertService.clear()
                this.alertService.error("could not download the sent file")
            });
    }  
    
    /**
     * download the returned pdf
     */
    downloadReturnedPdf(){
        this.loadingService.showLoading(false, false, '', 0)
        this.receiveChecksheetService.downloadApprovalFile(UserVariable.projectId, this.csInstanceId)
        .subscribe(response => {
                this.loadingService.hideLoading()
                let file = new Blob([response], { type: 'application/pdf' });
                if(this.assetName){
                    FileSaver.saveAs(file, this.systemName + "_" + this.assetName + "_" + this.checksheetName + ".pdf");
                } else{
                    FileSaver.saveAs(file, this.systemName + "_" + this.checksheetName + ".pdf");
                }
            },
            error => {
                this.loadingService.hideLoading()
                this.alertService.clear()
                this.alertService.error("Could not download the approved file")
            });
    }
}