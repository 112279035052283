import { Component, OnInit } from '@angular/core';
import { GridOptions, Module, AllModules } from "@ag-grid-enterprise/all-modules";
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DictionaryService} from './dictionary.service';
import { UserVariable } from '../../util/common/user-variable';
import { AlertService } from '../../util/alert/alert.service';
import { CapabilityService } from '../../util/capability.service';
import { PSDeleteButtonRender} from '../procedurestore/cell-render/delete-button/delete-button.component';
import { SharedService } from '../../util/shared.service';
import { LoadingService } from '../../util/loading/loading.service';
import { DictionaryCellRenderComponent} from './dictionary-cell-render/dictionary-cell-render.component'
import { DictionarysharedService } from './dictionaryshared.service';

@Component({
	selector: 'app-dictionary',
	templateUrl: './dictionary.component.html',
	styleUrls: ['./dictionary.component.css']
})
export class DictionaryComponent implements OnInit {

	public isMinimized: boolean = false;
	public gridOptions: GridOptions;
	public defaultColDef: any;
	ps_translated_data: any = null;
	columnDefs;
	addNewWordToDictionary: boolean = true;
	public frameworkComponents;
	private gridApi;
	private gridColumnApi;
	public modules: Module[] = AllModules;
	public selectedLanguage: string = "en-US";
	rowData: any[] = [];
	public languageList: Array<any> =[{'id': '0', 'text':'en-US'}, {'id' : '1' , 'text':'en-ES'}];
	filteredData: any[];
	public overlayLoadingTemplate: any;
    public overlayNoRowsTemplate: any;

	// capabilities
	public deleteWord: boolean = false
	public queryWord: boolean = false

	// enable/disable buttons
	public enableDeleteWord: boolean = false
	public enableQueryWord: boolean = false

	constructor(private alertService: AlertService, private translate: TranslateService,private router: Router,private dictionaryService: DictionaryService, private sharedService: SharedService, private loadingService: LoadingService, private dictionarysharedService: DictionarysharedService, private capabilityService: CapabilityService) { 
		//translate.addLangs(["es", "en"]);
		this.enableDisableButton()
		translate.setDefaultLang("en");
		let browserLang = translate.getBrowserLang();
		translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
		

		this.gridOptions = <GridOptions>{
			context: {
				componentParent: this
			}
		};

		this.defaultColDef = {
			resizable: true,
			sortable: true,
		}

		translate.getTranslation(browserLang).subscribe((res: string) => {
			this.ps_translated_data = res['DICTIONARY']
			this.columnDefs = [
				{
					headerName: this.ps_translated_data.WORD, field: 'word', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 200, suppressFiltersToolPanel: true
				},
				{
					headerName: this.ps_translated_data.DELETE, field: "delete", cellRendererFramework: DictionaryCellRenderComponent,
					autoHeight: true, minWidth: 50, width: 50, cellStyle: { 'text-align': 'center' },
					hide: !this.enableDeleteWord
				}
			]
			
			//custom layout templates for loading and empty data sets
			this.overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Loading data...</span>';
       		this.overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">No rows to show</span>';
		});

  	}


	ngOnInit() {
		this.dictionarysharedService.$isUpdated.subscribe((data)=>{
			if(data){
				this.getWordListByProjectIdAndLanguage();
				this.dictionarysharedService.clearFlag();
			}
		})
	}

	onColumnResized(event) {
		if (event.finished) {
		this.gridApi.resetRowHeights();
		}
	}

	onGridReady(params) {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		// window.addEventListener("resize", function () {
        //     setTimeout(function () {
        //         params.api.sizeColumnsToFit();
        //     });
		// });
		window.addEventListener("resize", this.onWindowResize);
		if (this.enableQueryWord) {
			this.getWordListByProjectIdAndLanguage();
			console.log("enable")
		} else {
			console.log("disable")
		}
		//this.getWordListByProjectIdAndLanguage();
		setTimeout(function () {
			params.api.sizeColumnsToFit();
			params.api.resetRowHeights();
		}, 500);
	}
	private onWindowResize = () => {
   
		setTimeout(() => {
			if (this.gridApi) {
				this.gridApi.sizeColumnsToFit();
			}
		}); 
	};

     ngOnDestroy(){
        window.removeEventListener("resize", this.onWindowResize);
        this.gridApi.destroy();
        this.gridApi = null;
     }

	public getSelectedLanguage(event) {
		//console.log(this.translate.getDefaultLang());
		this.selectedLanguage = event.text
		if (this.selectedLanguage.localeCompare("en-US") && this.selectedLanguage.localeCompare("en-ES")) {
		} else {
		    this.getWordListByProjectIdAndLanguage();
		}
	 }

	public addDictionaryWord(){
		this.router.navigateByUrl('addnewwordtodictionary');
	}

	private getWordListByProjectIdAndLanguage(){
		this.gridApi.showLoadingOverlay()
      	this.dictionaryService.getWordListByProjectIdAndLanguage(this.selectedLanguage)
         .subscribe(data => {
			//this.rowData = data.payload;
			this.setWordListToTable(data.payload);
        },
            error => {
				this.rowData = [];
				this.gridOptions.api.sizeColumnsToFit();
               	this.alertService.clear()
               	this.alertService.error(error.statusDescription)
        });
	}

	private setWordListToTable(payload: any){
		this.filteredData = [];
		this.rowData = [];
		if(payload.length != 0){
			for(let i = 0; i < payload.length; i++){
				this.filteredData.push({
					'word' : payload[i]["word"], 
					delete : "delete:" + payload[i]["id"]	
				});
			}
			this.rowData = this.filteredData;
			this.gridOptions.api.sizeColumnsToFit();
		}else{
			// no row data to show
		}
		//this.systemsService.setRowData(this.rowData);	
	}

	 /**
     * enable/disable buttons or columns
     */
	private enableDisableButton() {
		this.checkCapability()
		this.enableDeleteWord = this.deleteWord
		this.enableQueryWord = this.queryWord
    }

	/**
     * check the capabilities for given type
     */
    public checkCapability() {
		let deleteWordCapability: string = "deleteWord"
		let queryWordCapability: string = "queryWord"
		this.deleteWord = this.capabilityService.isCapabilityAssigned(deleteWordCapability)
		this.queryWord = this.capabilityService.isCapabilityAssigned(queryWordCapability)
    }


}
