<!--Alert-->
<alert class="floating-alert"></alert>

<!-- header -->
<nav id="navPanel" class="bg-light border-bottom box-shadow fixed-top navbar-expand-lg" #navPanel>
	<app-header [minimalOption]="true"></app-header>
</nav>

<div class="container-fluid">
	<main role="main" class="col-md-12 px-0" #main [style.padding-top.px]="paddingtop">
		<!-- title and  -->
		<div class="col-sm-12 px-0 align-self-end">
			<div class="row align-items-center">
				<div class="col-sm-10">
					<h4 class="" id="value_title">
						{{ 'TAG_VALUE_LIST.MANAGE_VALUE_LIST' | translate }}
					</h4>
				</div>
				<div class="col-sm-2"></div>
			</div>
			<hr class="mt-0 mb-3" />
		</div>
		<div class="row">
			<!-- left side panel (search box and value list) -->
			<div class="col-sm-4">
				<!-- search box and add new button -->
				<div class="form-group row mb-1">
					<div class="col-sm-6 align-self-end">
						<label class="h5"
							for="exampleInputEmail1">{{ 'TAG_VALUE_LIST.SEARCH_VALUE' | translate }}</label>
					</div>
					<div class="col-sm-6 align-self-end">
						<button id="btn_add_valuelist" type="button" class="btn btn-sm btn-primary my-1 float-right"
							(click)="addValueButtonClick(template)">
							{{ 'TAG_VALUE_LIST.ADD_VALUE_LIST' | translate }}
						</button>
					</div>
					<div class="col-sm-12">
						<input type="text" class="form-control form-control-sm" aria-label="Sizing example input"
							placeholder="Search Value" aria-describedby="inputGroup-sizing-default"
							[(ngModel)]="filterText">
					</div>
				</div>
				<hr class="my-2" />
				<!-- value list with 2 buttons -->
				<div [style.max-height.px]="divHeight" style="overflow: auto;">
				<div class="mt-2" *ngFor="let tagValue of filter(tagValueList)">
					<div class="card my-1 cursor-pointer"
						[ngClass]="{'card-primary': tagValue.valueListId != selectedValueListItem.valueListId, 'card-secondary': tagValue.valueListId == selectedValueListItem.valueListId }">
						<div class="card-body px-2 py-1" (click)="onValueSelect(tagValue)">
							<div class="col-sm-12 float-left px-0">
								<p class="pb-0 h6">{{tagValue.valueListName}}</p>
							</div>
							<div class="col-sm-12 float-left px-0">
								<div class="col-sm-3 float-right px-0">
									<button id="btnDeleteTagValue" type="button"
										class="my-1 mx-2 close close-list float-right"
										(click)="$event.stopPropagation();deleteValueListItem(tagValue)">
										<i class="far fa-trash-alt"></i>
									</button>
									<button id="btn_edit_value_list" type="button"
										class="my-1 mx-2 close close-list float-right"
										(click)="$event.stopPropagation();editValueListItem(tagValue, editTemplate)">
										<i class="far fa-edit"></i>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			</div>
			<!-- right side panel (description, attributes table and assign value list table) -->
			<div class="col-sm-8">
				<!-- value description -->
				<div class="col-sm-12 px-0" id="value_description">
					<div class="form-group row" id="value_description">
						<div class="col-sm-9 align-self-end">
							<label class="h5"
								for="exampleInputEmail1">{{ 'TAG_VALUE_LIST.DESCRIPTION' | translate }}</label>
						</div>
						<div class="col-sm-3 align-self-end">
							<button id="btnSaveTagDescription" type="button"
								class="btn btn-sm btn-primary my-1 float-right" (click)="saveDescription()"
								[disabled]="selectedValueListItem.valueListId == ''">{{ 'TAG_VALUE_LIST.SAVE' | translate }}
							</button>
						</div>
						<div class="col-sm-12">
							<textarea class="form-control" id="value_description" rows="3"
								[(ngModel)]="valueListDescription" (click)="editDescription()"
								[disabled]="selectedValueListItem.valueListId == ''"></textarea>
						</div>
					</div>
				</div>
				<hr class="my-2" />
				<!--attributes table-->
				<div class="col-sm-12 px-0" id="value_list_attributes">
					<div class="form-group row mt-3" id="value_description">
						<div class="col-sm-8 align-self-end">
							<label class="h5"> {{ 'TAG_VALUE_LIST.VALUES' | translate }} :
								<small class="mx-2">
									<!-- Components only    -->
									{{selectedValueListItem.valueListName}}</small>
							</label>
						</div>
						<div class="col-sm-4 align-self-end">
							<button id="btnEditTagValue" type="button" class="btn btn-sm btn-primary my-1 float-right"
								(click)="addAttribute()" [disabled]="selectedValueListItem.valueListId == ''">
								{{ 'TAG_VALUE_LIST.ADD_VALUE' | translate }}
							</button>
						</div>
						<div class="col-sm-12">
							<ag-grid-angular style="width: 100%; height: 250px;" class="ag-theme-balham"
								[gridOptions]="gridOptions" [rowData]="attributeList" [columnDefs]="columnDefs"
								[enableRangeSelection]="true" (gridReady)="onGridReady($event)" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }"
								[frameworkComponents]="frameworkComponents" [rowDragManaged]="true" [animateRows]="true"
								(rowDragEnd)="onRowDragEnd($event)" (cellValueChanged)="onCellValueChanged($event)"
								(cellEditingStopped)="cellEditCompleted($event)"></ag-grid-angular>
						</div>
					</div>
				</div>
				<hr class="my-2" *ngIf="selectedValueListItem != undefined" />
				<!--field table-->
				<div class="col-sm-12 px-0" id="value_list_fields" *ngIf="selectedValueListItem != undefined">
					<div class="form-group row mt-3" id="value_description">
						<div class="col-sm-12">
							<label class="h5"> {{ 'TAG_VALUE_LIST.ASSIGN_VALUE_LIST_TO_FIELD' | translate }} :
								<small class="mx-2"> {{selectedValueListItem.valueListName}} </small>
							</label>
						</div>
						<div class="col-sm-12">
							<ag-grid-angular style="width: 100%; height: 250px;" class="ag-theme-balham"
								[gridOptions]="gridOptions" [rowData]="rowDataForValueList"
								[modules]="modules" [sideBar]="{ toolPanels: ['columns'] }"
								(gridReady)="onValueListGridReady($event)" [columnDefs]="valueListColumnDefs"
								[enableRangeSelection]="true" (columnResized)="onColumnResized($event)"
								(rowDataChanged)="onRowDataChanged()" [frameworkComponents]="frameworkComponents"
								[rowDragManaged]="true" [animateRows]="true"
								(cellValueChanged)="onValueListCellValueChanged($event)"></ag-grid-angular>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
</div>

<!-- <div class="container mt-5">
	<div class="row">
		<div class="col-sm" id="value_list">
			<div class="input-group mb-3">
				<div class="input-group-prepend">
					<span class="input-group-text" id="inputGroup-sizing-default">Filter</span>
				</div>
				<input type="text" class="form-control" aria-label="Sizing example input"
					aria-describedby="inputGroup-sizing-default" [(ngModel)]="filterText">
			</div>

			<div class="list-group" id="list-tab" role="tablist">
				<div *ngFor="let tagValue of filter(tagValueList)">
					<a class="list-group-item list-group-item-action" id="list-home-list" data-toggle="list"
						(click)="onValueSelect(tagValue)">{{tagValue.valueListName}}</a>
					<button type="button" class="btn btn-danger" (click)="deleteValueListItem(tagValue)">Delete</button>
					<button type="button" class="btn btn-warning"
						(click)="editValueListItem(tagValue, editTemplate)">Edit</button>
				</div>
				<button type="button" class="btn btn-warning" (click)="addValueButtonClick(template)">Add</button>
			</div>
		</div>

		<div class="col-sm">
			<div class="row" id="value_description">
				Manage Value Lists
				<div class="form-group">
					<label for="exampleFormControlTextarea1">Description</label>
					<textarea class="form-control" id="value_description" rows="3"
						[(ngModel)]="valueListDescription"></textarea>
					<button type="button" class="btn btn-warning" (click)="saveDescription()">Save</button>
				</div>
			</div>
		</div>
	</div>

	<div class="row">
		<div class="row container-fluid" id="value_list_attributes">
			Tag Attributes:components only
			<ag-grid-angular style="width: 100%; height: 250px;" class="ag-theme-balham" [gridOptions]="gridOptions"
				[rowData]="attributeList" [columnDefs]="columnDefs" [enableRangeSelection]="true"
				(gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents" [rowDragManaged]="true"
				[animateRows]="true" (rowDragEnd)="onRowDragEnd($event)" (cellValueChanged)="onCellValueChanged($event)"
				(cellEditingStopped)="cellEditCompleted($event)"></ag-grid-angular>
		</div>
		<button type="button" class="btn btn-warning" (click)="addAttribute()">Add Attribute</button>
	</div>

	<div class="row" *ngIf="selectedValueListItem != undefined">
		<div class="row container-fluid" id="value_list_fields">
			Assign Valuelist to Field : {{selectedValueListItem.valueListName}}
			<ag-grid-angular style="width: 100%; height: 250px;" class="ag-theme-balham" [gridOptions]="gridOptions"
				[rowData]="rowDataForValueList" [columnDefs]="valueListColumnDefs" [enableRangeSelection]="true"
				[frameworkComponents]="frameworkComponents" [rowDragManaged]="true" [animateRows]="true"
				(cellValueChanged)="onValueListCellValueChanged($event)"></ag-grid-angular>
		</div>
	</div>
</div> -->

<!--Add value list template-->
<ng-template #template>
	<div class="modal-header">
		<h5 class="modal-title">{{ 'TAG_VALUE_LIST.ADD_NEW_VALUE_LIST' | translate }}</h5>
		<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancelButtonClick()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<form ngNativeValidate>
		<div class="modal-body">
	
			<div class="form-group">
				<label for="newValueListName">{{ 'TAG_VALUE_LIST.NAME' | translate }} </label>
				<div class="badge-required mx-2"></div>
				<input type="text" class="form-control form-control-sm" id="newValueListName" name="newValueListName"
					placeholder="" [(ngModel)]="newValueListName" required>
			</div>
			<div class="form-group">
				<label for="newValueListDescription">{{ 'TAG_VALUE_LIST.DESCRIPTION' | translate }}  </label>
				<div class="badge-required mx-2"></div>
				<input type="text" class="form-control form-control-sm" id="newValueListDescription"
					name="newValueListDescription" placeholder="" [(ngModel)]="newValueListDescription" required>
			</div>
		</div>
		<div class="modal-footer">
			<button type="submit" class="btn btn-primary btn-sm" (click)="submitButtonClick()">{{ 'TAG_VALUE_LIST.SUBMIT' | translate }}</button>
			<button type="button" formnovalidate class="btn btn-secondary btn-sm" data-dismiss="modal" (click)="cancelButtonClick()">{{ 'TAG_VALUE_LIST.CANCEL' | translate }}</button>
		</div>
	</form>
</ng-template>

<!--Edit Value List Template-->
<ng-template #editTemplate>
	<div class="modal-header">
		<h5 class="modal-title">{{ 'TAG_VALUE_LIST.EDIT_VALUE_LIST' | translate }}</h5>
		<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancelButtonClick()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<form ngNativeValidate>
		<div class="modal-body">
			
				<div class="form-group">
					<label for="newValueListName">{{ 'TAG_VALUE_LIST.NAME' | translate }} : </label>
					<div class="badge-required mx-2"></div>
					<input type="text" class="form-control" id="newValueListName" name="newValueListName" placeholder=""
						[(ngModel)]="newValueListName" required>
				</div>
			
		</div>
		<div class="modal-footer">
			<button type="submit" class="btn btn-primary btn-sm"
				(click)="editSubmitButtonClick()">{{ 'TAG_VALUE_LIST.SUBMIT' | translate }}</button>
			<button type="button" formnovalidate class="btn btn-secondary btn-sm"
				(click)="editCancelButtonClick()">{{ 'TAG_VALUE_LIST.CANCEL' | translate }}</button>
		</div>
	</form>
</ng-template>