import { Component, OnInit, Input, HostListener, ElementRef, TemplateRef } from '@angular/core';
import { SharedService } from 'src/app/util/shared.service';
import { ICECKEditor } from '../../ice-course';
import { TranslateService } from '@ngx-translate/core';
import { IceGame, ICEGameImage} from '../ice-game-model.model';
import { AlertService } from '../../../util/alert/alert.service';
import { WsResponse } from '../../../util/ws-response.model';
import { WsType } from '../../../util/ws-type';
import { ICECourseService } from '../../ice-course-service.service';
import { LocalStorage } from '../../../util/localstorage.service';
import { LoadingService } from '../../../util/loading/loading.service';
import { UserVariable } from '../../../util/common/user-variable';
import { CK_Editor_Type  } from '../../../util/constants';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal/';

declare var CKEDITOR: any;
var editor;

@Component({
	selector: 'app-memory-game',
	templateUrl: './memory-game.component.html',
	styleUrls: ['./memory-game.component.scss']
})
export class MemoryGameComponent implements OnInit {

	public divHeight: number = (window.innerHeight) * 4 / 5;
	@HostListener('window:resize', ['$event'])
	onresize(event?) {
		this.divHeight = (window.innerHeight) * 4 / 5
	}

	public iceGame: IceGame;
	public userId: String;
	public tabId: string;
	public addSpecialCharDivId: String = "";
	public addFractionDIvId: String = "";
	public speCharList: Array<any> = [];
	public gameDescription: string ;
	public breadCrumbList:Array<any> = [];
	public gameDescriptionShowInDetails:any = null;
	public gameEnabled: boolean = true;
	public gameModified: boolean = false;
	public isViewOnly: boolean = false;
	public desceditor: any;
	private isImageReusablePanelOpened: boolean = false;
	private insertSubscription: any;
	private insertSubscription2: any;
	modalRef: BsModalRef;
	public gameGenarated: boolean = false;
	public editorList: Array<any> = [];
	public editorDivList: Array<any> = [];
	public currentRow: number;
	public currentSeq: number;
	public currentImageType: String;
	public imageData: any;
	public isImageDataAvailable: boolean = false;
	public isAddingImage: boolean = false;
	public isAddingPicture: boolean = false;
	private xmlContent: string = '';
	public numOfRows: number;
	public numOfSequences: number;
	public numOfRowsinView: number;
	public gameData: any;
	public existingGameData: string; 
	public selectedImage: any = {
		'id': '',
		'thumbnailImage': '',
		'largeImage': '',
		'title': ''
    };
    public selectedModalTemplate: TemplateRef<any>;

	public gameDetails = {
		target: '',
		matchContent: []
	}

	public modalConfig: any = {
		backdrop: true,
		// ignoreBackdropClick: true,
		class: 'modal-lg',
		// keyboard: false
    }

	private inlineConfig = {
		toolbar: [
			{ name: 'clipboard', groups: ['clipboard', 'undo'], items: ['Cut', 'Copy',  '-', 'Undo', 'Redo'] },
			{ name: 'basicstyles', groups: ['basicstyles'], items: ['Bold', 'Italic', 'Underline', 'Subscript', 'Superscript'] },
			//{ name: 'paragraph', groups: ['list'], items: ['NumberedList', 'BulletedList'] },
			{ name: 'insert', groups: ['insert'], items: ['addsymbol', 'addfraction'] }
			//{ name: 'editing', groups: ['spellchecker'], items: ['Scayt'] }
		],
		autoParagraph: false,
		readOnly: false,
		removePlugins: 'liststyle, magicline, maximize, resize',
		pasteFilter: 'plain-text',
		extraPlugins: 'sharedspace, addsymbol, addfraction',
    	title: false,
    	tabID: SharedService.getTabId(),
		enterMode: CKEDITOR.ENTER_BR,
		startupFocus: 'end'
	  };
	  
	constructor(private translate: TranslateService, private sharedService: SharedService,  private alert: AlertService,
		private iceCKEditor: ICECKEditor,  private courseService: ICECourseService, private alertService: AlertService,
		private loadingService: LoadingService, private elementRef: ElementRef, private modalService: BsModalService) { 
		// 	let browserLang = translate.getBrowserLang();
		// 	translate.getTranslation(browserLang).subscribe((res: string) => {
		// 	// this.tdQuestionType = res["ICE_COURSE"]
		// });
		this.iceGame = new IceGame("0");
		this.userId = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER)).userId;
		this.tabId = SharedService.getTabId();
		this.addFractionDIvId = 'webeditor_fraction_' +SharedService.getTabId();
		this.addSpecialCharDivId = 'webeditor_special_char_' +SharedService.getTabId();
		}
	@Input() data: any;
	ngOnInit() {
		this.speCharList = [
			{ data: { symbol: this.getSymbolFromDecimal(169), decVal: 169, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(8482), decVal: 8482, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(174), decVal: 174, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(176), decVal: 176, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(163), decVal: 163, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(8364), decVal: 8364, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(10016), decVal: 10016, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(9746), decVal: 9746, type: "speChar" } },
			// There was an issue in LC related to this special character. Therefore it was removed from all the Ck Editors in ICE Course
			// { data: { symbol: this.getSymbolFromDecimal(9744), decVal: 9744, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(167), decVal: 167, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(937), decVal: 937, type: "speChar" } }
		];
		
		if (this.data) {
			if(this.data.iceGame != null && this.data.iceGame != undefined ){
				//set game details from parent page
				this.iceGame = this.data.iceGame;
			  	var html = this.iceGame.descriptionHTML;
			  	var div = document.createElement("div");
			  	div.innerHTML = html;
			  	div.innerText;
			  	this.gameDescription = div.innerText;
			  	setTimeout(() => {
					//set game description editor content
					let e1  = this.elementRef.nativeElement.querySelector('.games_ck_editor' + this.tabId);
					e1.innerHTML = this.iceGame.description ;
				}, 200);
			  //set child details
			  this.iceGame = this.data.iceGame;
			  if (this.iceGame.id == '0'){
			  }
			  else{				
				//show loading message
				var loadingMessage = null;
				this.translate.get('ICE_GAME.LOADING_MESSAGE').subscribe((res: string) => {
					loadingMessage = res;
				});
				this.loadingService.showLoading(true, null, loadingMessage, null);  
			  	this.courseService.getAllICEGameInfoById(this.iceGame.id, UserVariable.getUserToken(), this);
			}
			} else {
			  	// data.game is null or undefined
			}
			this.breadCrumbList = this.sharedService.getICEBreadCrumb(this.iceGame.courseId);
			var html = this.iceGame.descriptionHTML;
			var div = document.createElement("div");
			div.innerHTML = html;
			this.gameDescriptionShowInDetails = div.textContent || div.innerText || "";
			this.isViewOnly = this.data.viewOnly
		} else {
			//no data
		}
		//set course enabled true
		this.gameEnabled = true;
		this.sharedService.setModifiedStatus(this.tabId, this.gameModified)
	}

	ngAfterViewInit() {
		let e1 = this.elementRef.nativeElement.querySelector('.games_ck_editor' + this.tabId);
		if (this.isViewOnly == true) {
			e1.style.backgroundColor = "#e9ecef";
		} 
	}

	ngAfterContentInit() {

		//unsubscribe
		if (SharedService.getIceMemoryGameSubscriptions() != null) {
				SharedService.getIceMemoryGameSubscriptions().unsubscribe();
			}
		if (SharedService.getIceMemoryGameSubscriptions2() != null) {
			SharedService.getIceMemoryGameSubscriptions2().unsubscribe();
		}

		// Update game data changes to the shared service when a nother tab is selected
		this.sharedService.setICEModuleChanges.subscribe(prevTab => {
			if (prevTab == this.tabId) {
				//get opened modules
				let openedICEModules = this.sharedService.getOpenedICEModules();
				//check if tab type is ICELearningCheck
				if (openedICEModules[prevTab] != undefined) {
					// get tab type
					var type = openedICEModules[prevTab]['type'];
					if (type == "ICEGame-Memory") {
						var name = this.getEditorContent('.games_ck_editor' + this.tabId);
						this.iceGame.description = name;
						if (this.gameData.qaPanels != undefined) {
							for (var row = 0; row < Object.keys(this.gameData.qaPanels).length; row++) {
								for (var seq = 0; seq < Object.keys(this.gameData.qaPanels[row + '_' + this.tabId].qaPanelData).length; seq++) {
									if (this.gameData.qaPanels[row + '_' + this.tabId].qaPanelData[seq + '_' + this.tabId].questionIsText) {
										var question = this.getEditorContent('.qpanel_ck_editor' + row + '' + seq + this.tabId);
										this.gameData.qaPanels[row + '_' + this.tabId].qaPanelData[seq + '_' + this.tabId].questionTextData = question;
									} else if (this.gameData.qaPanels[row + '_' + this.tabId].qaPanelData[seq + '_' + this.tabId].questionIsImage) {
										this.gameData.qaPanels[row + '_' + this.tabId].qaPanelData[seq + '_' + this.tabId].questionTextData = "";
									} else {
										// Nothing to do
									}
									if (this.gameData.qaPanels[row + '_' + this.tabId].qaPanelData[seq + '_' + this.tabId].answerIsText) {
										var answer = this.getEditorContent('.apanel_ck_editor' + row + '' + seq + this.tabId);
										this.gameData.qaPanels[row + '_' + this.tabId].qaPanelData[seq + '_' + this.tabId].answerTextData = answer;
									} else if (this.gameData.qaPanels[row + '_' + this.tabId].qaPanelData[seq + '_' + this.tabId].answerIsImage) {
										this.gameData.qaPanels[row + '_' + this.tabId].qaPanelData[seq + '_' + this.tabId].answerTextData = "";
									} else {
										// Nothing to do
									}
								}
							}
						}
						this.iceGame.gameData = JSON.stringify(this.gameData);
						// Update openedCourses list saved in shared service with gameData changes.
						this.sharedService.setOpenedICEModules(prevTab, 'ICEGame-Memory', this.iceGame);
					} else {
						//differnt type
					}
				} else {
					//do nothing
				}
			}
		});

		this.sharedService.setICEModuleVariables.subscribe(prevTab => {
			if(this.tabId == prevTab) {
				let self = this;
				let commonVariables = this.sharedService.getCommonICEModuleVariableNames('memoryGame');
				var commonVariableValues: Array<any> = [];
				commonVariables.forEach(function (varName) {
					commonVariableValues[varName] = self[varName];
				}); 
				this.sharedService.setCommonICEModuleVariableValues(prevTab, 'memoryGame', commonVariableValues);
			}
		});

		// Get the current tab's iceGame object
		this.sharedService.setOpenedICEModule.subscribe(newTab => {
			let openedICEModules = this.sharedService.getOpenedICEModules();
			if(openedICEModules[newTab] != undefined) {
				// set iceGame object for the selected tab
				this.iceGame = openedICEModules[newTab]['module'];
				// set gameData object for the selected tab
				this.setSelectedTabsGameData(openedICEModules[newTab]['module'].gameData);
			}
		});

		this.sharedService.setOpenedICEVariables.subscribe(newTab => {
			if (this.tabId == newTab) {
				let commonVariables = this.sharedService.getCommonICEModuleVariableNames('memoryGame');
				var commonVariableValues: Array<any> = [];
				commonVariableValues = this.sharedService.getCommonICEModuleVariableValues(newTab);
				console.log(commonVariableValues);
				if(commonVariableValues != undefined) {
					let self = this;
					commonVariables.forEach(function (varName) {
						self[varName] = commonVariableValues[varName];
					}); 
				}
			}
		});

		this.insertSubscription = SharedService.insertICECourseExecute().subscribe(option => {
			//get opened modules
			let openedICEModules = this.sharedService.getOpenedICEModules();
			//get current tab
			var currentTab = SharedService.getTabId();

			//check if current tab type is ICEGame-Memory
			if (openedICEModules[currentTab] != undefined) {
				// get tab type
				var type = openedICEModules[currentTab]['type'];
				if (type == "ICEGame-Memory") {
					if (option.data.type != "imageAsImage" && option.data.type != "imageAsFigure") {
						this.alertService.warn(this.translate.instant('ICE_GAME.ADD_REUSABLES_WARNING'));
					}
					let pictureReferencesMG = this.sharedService.getPictureReferences();
					if (pictureReferencesMG != undefined) {
						if (pictureReferencesMG['compType'] == 'QA') {
							this.isAddingPicture = true;
							this.isAddingImage = false;
						} else if (pictureReferencesMG['compType'] == 'GI') {
							this.isAddingPicture = false;
							this.isAddingImage = true;
						} else {
							this.isAddingPicture = false;
							this.isAddingImage = false;
						}
						if (this.isAddingImage && pictureReferencesMG['compType'] == 'GI') {
							if (option.data.type == "imageAsImage" || option.data.type == "imageAsFigure") {
								this.iceCKEditor.insertTagEditArea(option, this.elementRef, editor, CKEDITOR, this.iceGame, "ICEMemoryGame");
							}
						}
					}
				}
			}

		});
	
			//Called after ngOnInit when the component's or directive's content has been initialized.
		//Add 'implements AfterContentInit' to the class.

		this.insertSubscription2 = SharedService.insertICECourseExecute().subscribe(option => {
			//get opened modules
			let openedICEModules = this.sharedService.getOpenedICEModules();
			//get current tab
			var currentTab = SharedService.getTabId();

			//check if current tab type is ICEGame-Memory
			if (openedICEModules[currentTab] != undefined) {
				// get tab type
				var type = openedICEModules[currentTab]['type'];
				if (type == "ICEGame-Memory") {
					if (option.data.type != "imageAsImage" && option.data.type != "imageAsFigure") {
						this.alertService.warn(this.translate.instant('ICE_GAME.ADD_REUSABLES_WARNING'));
					}
					let pictureReferencesMG = this.sharedService.getPictureReferences();
					if (pictureReferencesMG != undefined) {
						if (pictureReferencesMG['compType'] == 'QA') {
							this.isAddingPicture = true;
							this.isAddingImage = false;
						} else if (pictureReferencesMG['compType'] == 'GI') {
							this.isAddingPicture = false;
							this.isAddingImage = true;
						} else {
							this.isAddingPicture = false;
							this.isAddingImage = false;
						}
						if (this.isAddingPicture && (option.data.type == "imageAsImage" || option.data.type == "imageAsFigure")) {
							// call setSelectImagetoMainObject event to set selected image data to gameData of current tab
							this.sharedService.setSelectedImageRequest(option);
						}
					}
				} else {
					//differnt type
				}
			} else {
				//do nothing
			}
		}); 
		
		// set selected image data to gameData object of current tab
		this.sharedService.setSelectImagetoMainObject.subscribe(option => {
			this.imageData = option;
			this.isImageDataAvailable= true;
			//get the row index, sequence index and selected image type from shared service to update gameData object with inserted image details
			let pictureReferencesMG = this.sharedService.getPictureReferences();
			this.currentRow = pictureReferencesMG['selectedRow'];
			this.currentSeq = pictureReferencesMG['selectedSeq'];
			this.currentImageType = pictureReferencesMG['selectedType'];
			// get tab Id of the current tab from shared service
			let tabIDforPictures = SharedService.getTabId();
			if (this.gameData.qaPanels != undefined && this.gameData.qaPanels[this.currentRow+"_"+tabIDforPictures] != undefined) {
				if(this.currentImageType == 'q' && !this.gameData.qaPanels[this.currentRow+"_"+tabIDforPictures].questionIsText) {
					this.gameData.qaPanels[this.currentRow+"_"+tabIDforPictures].qaPanelData[this.currentSeq+"_"+tabIDforPictures].questionImageData.id = this.imageData.data.id;
					this.gameData.qaPanels[this.currentRow+"_"+tabIDforPictures].qaPanelData[this.currentSeq+"_"+tabIDforPictures].questionImageData.thumbnailImage = this.imageData.data.thumbnailImage;
					this.gameData.qaPanels[this.currentRow+"_"+tabIDforPictures].qaPanelData[this.currentSeq+"_"+tabIDforPictures].questionImageData.largeImage = "";
					this.gameData.qaPanels[this.currentRow+"_"+tabIDforPictures].qaPanelData[this.currentSeq+"_"+tabIDforPictures].questionImageData.title = this.imageData.data.title;
					this.gameData.qaPanels[this.currentRow+"_"+tabIDforPictures].qaPanelData[this.currentSeq+"_"+tabIDforPictures].questionIsImage = true;
				} else {
					if(!this.gameData.qaPanels[this.currentRow+"_"+tabIDforPictures].answerIsText) {
						this.gameData.qaPanels[this.currentRow+"_"+tabIDforPictures].qaPanelData[this.currentSeq+"_"+tabIDforPictures].answerImageData.id = this.imageData.data.id;
						this.gameData.qaPanels[this.currentRow+"_"+tabIDforPictures].qaPanelData[this.currentSeq+"_"+tabIDforPictures].answerImageData.thumbnailImage = this.imageData.data.thumbnailImage;
						this.gameData.qaPanels[this.currentRow+"_"+tabIDforPictures].qaPanelData[this.currentSeq+"_"+tabIDforPictures].answerImageData.largeImage = "";
						this.gameData.qaPanels[this.currentRow+"_"+tabIDforPictures].qaPanelData[this.currentSeq+"_"+tabIDforPictures].answerImageData.title = this.imageData.data.title;
						this.gameData.qaPanels[this.currentRow+"_"+tabIDforPictures].qaPanelData[this.currentSeq+"_"+tabIDforPictures].answerIsImage = true;
					}
				}
			}
		});
		  
		this.sharedService.imageStatusRespond().subscribe(imageStatus => this.setImageStatus(imageStatus));
		SharedService.setIceMemoryGameSubscriptions(this.insertSubscription);
		SharedService.setIceMemoryGameSubscriptions2(this.insertSubscription2);
	  }

	public setSelectedTabsGameData(obj : any) {
		if(obj != undefined) {
			this.gameData = JSON.parse(obj);
			this.gameGenarated = true;
			setTimeout(() => {
				this.setEditor("",CK_Editor_Type.GAME_EDITOR);
			}, 200);
			setTimeout(() => {
				this.setEditor("",CK_Editor_Type.DDQ_CELL_EDITOR);
			}, 200);
		}
	}

	setImageStatus(type){
	  if(type.status =="ICEMemoryGame" && type.tabId == this.tabId ){
			this.gameModified	= true;
			this.sharedService.setModifiedStatus(this.tabId, this.gameModified)
		}
	}

	// update description of game with CK Editor
	updateDescription(){
		let el = this.elementRef.nativeElement.querySelector('.games_ck_editor' + this.tabId);
		if (el != null) {
			let name = this.iceGame.description; 
			if (name != undefined) {
				el.innerHTML = name;
			}
		}
	}

	// descriptionClick() {
	// 	//set editor
	// 	var self = this;
	// 	$('.games_ck_editor' + SharedService.getTabId()).dblclick(function (e) {
	// 		editor = self.desceditor;
	// 	})
	// }

	clearContent(row, seq, type){
		if(type == 'q') {
			this.gameData.qaPanels[row+'_'+this.tabId].qaPanelData[seq+'_'+this.tabId].questionIsImage = false;
			this.gameData.qaPanels[row+'_'+this.tabId].qaPanelData[seq+'_'+this.tabId].questionImageData.id = "";
			this.gameData.qaPanels[row+'_'+this.tabId].qaPanelData[seq+'_'+this.tabId].questionImageData.thumbnailImage = "";
			this.gameData.qaPanels[row+'_'+this.tabId].qaPanelData[seq+'_'+this.tabId].questionImageData.largeImage = "";
			this.gameData.qaPanels[row+'_'+this.tabId].qaPanelData[seq+'_'+this.tabId].questionImageData.title = "";
			this.gameData.qaPanels[row+'_'+this.tabId].qaPanelData[seq+'_'+this.tabId].questionIsText = false;
			this.gameData.qaPanels[row+'_'+this.tabId].qaPanelData[seq+'_'+this.tabId].questionTextData = "";
		} else {
			this.gameData.qaPanels[row+'_'+this.tabId].qaPanelData[seq+'_'+this.tabId].answerIsImage = false;
			this.gameData.qaPanels[row+'_'+this.tabId].qaPanelData[seq+'_'+this.tabId].answerImageData.id = "";
			this.gameData.qaPanels[row+'_'+this.tabId].qaPanelData[seq+'_'+this.tabId].answerImageData.thumbnailImage = "";
			this.gameData.qaPanels[row+'_'+this.tabId].qaPanelData[seq+'_'+this.tabId].answerImageData.largeImage = "";
			this.gameData.qaPanels[row+'_'+this.tabId].qaPanelData[seq+'_'+this.tabId].answerImageData.title = "";
			this.gameData.qaPanels[row+'_'+this.tabId].qaPanelData[seq+'_'+this.tabId].answerIsText = false;
			this.gameData.qaPanels[row+'_'+this.tabId].qaPanelData[seq+'_'+this.tabId].answerTextData = "";
		}
		this.gameModified = true;
		this.sharedService.setModifiedStatus(this.tabId, this.gameModified)
	}

	setGameEditors(e1) {
		let self = this;
		if (e1.innerHTML == '') {
			e1.innerHTML = "&nbsp;";
		} else {
			//do nothing
		}
		if (self.iceGame.description != undefined) {
			e1.innerHTML = self.iceGame.description;
		} else {
			//do nothing
		}
		$('.desc_ck_editor'+ self.tabId).dblclick(function (e) {
			if (e.target.nodeName != "PH" || (self.desceditor == null) || (self.desceditor == undefined)) {
				self.gameModified = true;
				self.sharedService.setModifiedStatus(self.tabId, self.gameModified)
				if (self.desceditor) {
					self.desceditor.destroy();
				} else {
					//no editor
				}
				self.desceditor = CKEDITOR.inline(e1 , self.inlineConfig);
				//set description editable true
				e1.setAttribute("contenteditable", "true");		
				self.setEditorFunctions(self.desceditor);
			} else {
				$(document).on('dblclick','ph', function(){
					let item = $(this).attr('restriction');
					let catId = $(this).attr('catId');
					self.iceCKEditor.getRestrictionValueOndblClick(item,catId);
				});
				self.setEditorFunctions(self.desceditor);
			}
		});
	}

	setEditorFunctions(editor) {
		var self = this;
		if (editor) {
			editor.on('instanceReady', function (evt) {
				var range = editor.createRange();
				if (range != null) {
					range.moveToElementEditEnd(range.root);
					editor.getSelection().selectRanges([range]);
				}
			});			
			editor.on('key', function (e) {
				self.iceCKEditor.editorKeyDown(e,editor);
			});
			editor.on('paste', function (evt) {
				evt.stop();
				var data: string = evt.data.dataValue;
				let pasteData: string = self.iceCKEditor.processPasteData(data);
				if(self.iceCKEditor.gethasResuables()) {
					self.alertService.clear();
					self.alertService.toast(self.translate.instant('ICE_COURSE.DISABLED_EDITOR_COPY'));
				}
				evt.editor.insertHtml(pasteData);
			});
		} else {
			//no editor
		}
	}

	/**
	 * generate memoty game with squares
	 */
	public generateGame() {
		this.numOfRows = 2;
		this.numOfSequences = 5;
		this.gameData = {};
		this.gameData.numOfRows = 2;
		this.gameData.numOfSequences = 5;
		var qaPanels = {};
		for(let i = 0; i < this.numOfRows; i++) {
			qaPanels[i+'_'+this.tabId] = {};
			qaPanels[i+'_'+this.tabId].description = "";
			qaPanels[i+'_'+this.tabId].qaPanelData = {};
			for(let x = 0; x < this.numOfSequences; x++) {
				qaPanels[i+'_'+this.tabId].qaPanelData[x+'_'+this.tabId] = {};
				qaPanels[i+'_'+this.tabId].qaPanelData[x+'_'+this.tabId].questionIsText = false;
				qaPanels[i+'_'+this.tabId].qaPanelData[x+'_'+this.tabId].questionIsImage = false;
				qaPanels[i+'_'+this.tabId].qaPanelData[x+'_'+this.tabId].questionTextData = "";
				qaPanels[i+'_'+this.tabId].qaPanelData[x+'_'+this.tabId].questionImageData = new ICEGameImage;
				qaPanels[i+'_'+this.tabId].qaPanelData[x+'_'+this.tabId].answerIsText = false;
				qaPanels[i+'_'+this.tabId].qaPanelData[x+'_'+this.tabId].answerIsImage = false;
				qaPanels[i+'_'+this.tabId].qaPanelData[x+'_'+this.tabId].answerTextData = "";
				qaPanels[i+'_'+this.tabId].qaPanelData[x+'_'+this.tabId].answerImageData = new ICEGameImage;
			}
		}
		this.gameData.qaPanels = qaPanels;
		this.gameGenarated = true;
		// Save iceGame object and its gameData with selected tab id in shared service when opening the game, if new game or gameData not defined yet
		this.iceGame.gameData = JSON.stringify(this.gameData);
		this.sharedService.setOpenedICEModules(SharedService.getTabId(), 'ICEGame-Memory', this.iceGame);
		let self = this;
				let commonVariables = this.sharedService.getCommonICEModuleVariableNames('memoryGame');
				var commonVariableValues: Array<any> = [];
				commonVariables.forEach(function (varName) {
					commonVariableValues[varName] = self[varName];
				}); 
				this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'memoryGame', commonVariableValues);
	}

	addText(row, seq, type) {
		this.isAddingImage = false;
		this.isAddingPicture = false;
		if(type == 'q') {
			this.gameData.qaPanels[row+'_'+this.tabId].qaPanelData[seq+'_'+this.tabId].questionIsText = true;
			this.gameData.qaPanels[row+'_'+this.tabId].qaPanelData[seq+'_'+this.tabId].questionIsImage = false;
			this.gameData.qaPanels[row+'_'+this.tabId].qaPanelData[seq+'_'+this.tabId].questionImageData = {};
			setTimeout(() => {
				this.focusNewEditor('.qpanel_ck_editor'+ (row+''+seq) + this.tabId , CK_Editor_Type.DDQ_CELL_EDITOR);
			}, 200);
		} else {
			this.gameData.qaPanels[row+'_'+this.tabId].qaPanelData[seq+'_'+this.tabId].answerIsText = true;
			this.gameData.qaPanels[row+'_'+this.tabId].qaPanelData[seq+'_'+this.tabId].answerIsImage = false;
			this.gameData.qaPanels[row+'_'+this.tabId].qaPanelData[seq+'_'+this.tabId].answerImageData = {};
			setTimeout(() => {
				this.focusNewEditor('.apanel_ck_editor'+ (row+''+seq) + this.tabId , CK_Editor_Type.DDQ_CELL_EDITOR);
			}, 200);
		}
		this.gameModified = true;
		this.sharedService.setModifiedStatus(this.tabId, this.gameModified)
		this.sharedService.setPictureReferences(null,null,null,null,null,null,null,null,null);
	}

	// add picture content
	addPicture(row, seq, type) {
		this.isAddingPicture = true;
		this.isAddingImage = false;
		this.changeToImagePanel(); 
		this.currentRow = row;
		this.currentSeq = seq;
		this.currentImageType = type;
		if(type == 'q') {
			this.gameData.qaPanels[row+'_'+this.tabId].qaPanelData[seq+'_'+this.tabId].questionIsText = false;
			this.gameData.qaPanels[row+'_'+this.tabId].qaPanelData[seq+'_'+this.tabId].questionTextData = "";
			// this.gameData.qaPanels[row+'_'+this.tabId].qaPanelData[seq+'_'+this.tabId].questionIsImage = false;
			// this.gameData.qaPanels[row+'_'+this.tabId].qaPanelData[seq+'_'+this.tabId].questionImageData = {};
		} else {
			this.gameData.qaPanels[row+'_'+this.tabId].qaPanelData[seq+'_'+this.tabId].answerIsText = false;
			this.gameData.qaPanels[row+'_'+this.tabId].qaPanelData[seq+'_'+this.tabId].answerTextData = "";
			// this.gameData.qaPanels[row+'_'+this.tabId].qaPanelData[seq+'_'+this.tabId].answerIsImage = false;
			// this.gameData.qaPanels[row+'_'+this.tabId].qaPanelData[seq+'_'+this.tabId].answerImageData = {};
		}
		this.gameModified = true;
		this.sharedService.setModifiedStatus(this.tabId, this.gameModified)
		this.sharedService.setPictureReferences(row,seq,type,null,null,null,null,null,'QA');
	}
	
	focusNewEditor(className: string, CK_Editor_Variable) {
		let self = this;
		//set editor content
		this.setEditor(className, CK_Editor_Variable);
		let node = $(".toggle-edit" + this.tabId + this.userId + className);
		console.log("fn length"+this.editorList.length);
		for (let i in this.editorList) {
			if (CKEDITOR.instances[this.editorList[i].name] != undefined) {
				CKEDITOR.instances[this.editorList[i].name].destroy();
			}
		}
		for (let j in self.editorDivList) {
			if (self.editorDivList[j].attributes['contenteditable'].nodeValue == "true") {
				self.editorDivList[j].setAttribute("contenteditable", "false");									
				if ($(self.editorDivList[j])[0].innerHTML == '') {
					$(self.editorDivList[j])[0].innerHTML = '<br>';
				}
			}
		}
		$(node)[0].setAttribute("contenteditable", "true");
		editor = CKEDITOR.inline($(node)[0], this.inlineConfig);
		this.editorList.push(editor);
		this.editorDivList.push($(node)[0]);
			editor.on('instanceReady', function (evt) {
			var range = editor.createRange();
			if (range != null) {
				range.moveToElementEditEnd(range.root);
				editor.getSelection().selectRanges([range]);
			}
		});
		$(document).on('dblclick','ph', function(){
		  let item = $(this).attr('restriction');
		  let catId = $(this).attr('catId');
		  self.iceCKEditor.getRestrictionValueOndblClick(item,catId);
		});
		editor.on('key', function (e) {
		  self.iceCKEditor.editorKeyDown(e,editor);
		});
		editor.on('paste', function (evt) {
			evt.stop();
			var data: string = evt.data.dataValue;
			let pasteData: string = self.iceCKEditor.processPasteData(data);
			if(self.iceCKEditor.gethasResuables()) {
				self.alertService.clear();
				self.alertService.toast(self.translate.instant('ICE_COURSE.DISABLED_EDITOR_COPY'));
			}
			evt.editor.insertHtml(pasteData);
		});
	}

	setEditor(className:string, CK_Editor_Variable) {
		var self = this;
		$('.toggle-edit' + SharedService.getTabId() + this.userId).on('copy', function() {
			if ($(this)[0].attributes['contenteditable'].nodeValue == "false") {
				localStorage.setItem('disabledEditor', "true"); 
			}
		});		
		//set editor content
		this.setEditorContent(className,CK_Editor_Variable);
		if(this.isViewOnly == true) {
			//view only
		} else if( CK_Editor_Variable == 'ice_ddq_cell_ck_editor' || CK_Editor_Variable == 'games_ck_editor') {
			$('.toggle-edit' + this.tabId  + this.userId).dblclick(function (e) {
				self.gameModified = true;
				self.sharedService.setModifiedStatus(self.tabId, self.gameModified)
				for (let i in self.editorList) {
				  	if (CKEDITOR.instances[self.editorList[i].name] != undefined) {
						CKEDITOR.instances[self.editorList[i].name].destroy();
				  	}
				}
				for (let j in self.editorDivList) {
					if (self.editorDivList[j].attributes['contenteditable'].nodeValue == "true") {
						self.editorDivList[j].setAttribute("contenteditable", "false");									
						if ($(self.editorDivList[j])[0].innerHTML == '') {
							$(self.editorDivList[j])[0].innerHTML = '<br>';
						}
					}
				}
				$(this)[0].setAttribute("contenteditable", "true");
				if(CK_Editor_Variable == 'ice_ddq_cell_ck_editor'){
					let  questionId = $(this)[0].getAttribute("id");
					editor = CKEDITOR.inline($(this)[0], self.inlineConfig);
				} else if(CK_Editor_Variable == 'games_ck_editor'){
					let  questionId = $(this)[0].getAttribute("id");
					editor = CKEDITOR.inline($(this)[0], self.inlineConfig);
				}
				self.editorList.push(editor);
				self.editorDivList.push($(this)[0]);	
				editor.on('instanceReady', function (evt) {
				  	var range = editor.createRange();
				  	if (range != null) {
						range.moveToElementEditEnd(range.root);
						editor.getSelection().selectRanges([range]);
				  	}
				});
				editor.on('key', function (e) {
				  	self.iceCKEditor.editorKeyDown(e,editor);
				});
				editor.on('paste', function (evt) {
					evt.stop();
					var data: string = evt.data.dataValue;
					let pasteData: string = self.iceCKEditor.processPasteData(data);
					if(self.iceCKEditor.gethasResuables()) {
						self.alertService.clear();
						self.alertService.toast(self.translate.instant('ICE_COURSE.DISABLED_EDITOR_COPY'));
					}
					evt.editor.insertHtml(pasteData);
				});
			});
		}
	}

	setEditorContent(className, CK_Editor_Variable) {
		if(CK_Editor_Variable=='ice_ddq_cell_ck_editor'){
			if(className) {
				// Nothing to do
			} else {
				if (this.gameData.qaPanels != undefined) {
					for(let i = 0; i < Object.keys(this.gameData.qaPanels).length; i++){
						if(this.gameData.qaPanels[i+'_'+this.tabId] != undefined) {
						for(let x = 0; x < Object.keys(this.gameData.qaPanels[i+'_'+this.tabId].qaPanelData).length; x++){
						let e1 = this.elementRef.nativeElement.querySelector('.qpanel_ck_editor' + i + '' + x + this.tabId);
						let e2 = this.elementRef.nativeElement.querySelector('.apanel_ck_editor' + i + '' + x + this.tabId);
						if(this.gameData.qaPanels[i+'_'+this.tabId].qaPanelData[x+'_'+this.tabId].questionIsText) {
							if (e1 != null) {
								let name = this.gameData.qaPanels[i+'_'+this.tabId].qaPanelData[x+'_'+this.tabId].questionTextData;
								if (name != undefined) {
								e1.innerHTML = name;
								} else {
								//el.innerHTML = "<p>&nbsp;</p>";
								}
							} else {
								//no element
							}
							if(this.isViewOnly == true) {
								if (e1 != null) {
								e1.setAttribute("contenteditable", "false");
								e1.style.backgroundColor = "#e9ecef";
								} else {
									e1.style.backgroundColor = "#ffffff";
								}
							} else {
								if (e1 != null) {
								e1.style.backgroundColor = "";
								} else {
								//no element
								}
							}
						}
						if(this.gameData.qaPanels[i+'_'+this.tabId].qaPanelData[x+'_'+this.tabId].answerIsText) {
							if (e2 != null) {
								let name = this.gameData.qaPanels[i+'_'+this.tabId].qaPanelData[x+'_'+this.tabId].answerTextData;
								if (name != undefined) {
								e2.innerHTML = name;
								} else {
								//el.innerHTML = "<p>&nbsp;</p>";
								}
							} else {
								//no element
							}
							if(this.isViewOnly == true) {
								if (e2 != null) {
								e2.setAttribute("contenteditable", "false");
								e2.style.backgroundColor = "#e9ecef";
								} else {
									e2.style.backgroundColor = "#ffffff";
								}
							} else {
								if (e2 != null) {
								e2.style.backgroundColor = "";
								} else {
								//no element
								}
							}
						}
					}
						}
				}
			}
		}
		} else if(CK_Editor_Variable == 'games_ck_editor'){
			if(className) {
				let el = this.elementRef.nativeElement.querySelector(className);
				// Nothing to do
			} else {
				// for(let i = 0; i < Object.keys(this.gameData.qaPanels).length; i++){
				// 	let el = this.elementRef.nativeElement.querySelector('.seqDesc_ck_editor' + i + this.tabId);
				// 	if (el != null) {
				// 		let name = this.gameData.qaPanels[i].description;
				// 		if (name != undefined) {
				// 			el.innerHTML = name;
				// 		} else {
				// 			el.innerHTML = "<p>&nbsp;</p>";
				// 		}
				// 	} else {
				// 		//no element
				// 	}
				// 	if(this.isViewOnly == true) {
				// 		if (el != null) {
				// 		el.setAttribute("contenteditable", "false");
				// 			el.style.backgroundColor = "#e9ecef";
				// 		} else {
				// 			el.style.backgroundColor = "#ffffff";
				// 		//no element
				// 		}
				// 	} else {
				// 		if (el != null) {
				// 		el.style.backgroundColor = "";
				// 		} else {
				// 			//el.style.backgroundColor = "#ffffff";
				// 		}
				// 	}
				// }
			}
		}
	}

	/**
	 * back to main course detail page
	 */
	public backtoCourse() {
		this.sharedService.changeIceItemRequest('IceCourse', null)
	}

	private getSymbolFromDecimal(value: number): string {
		if ((33 <= value) &&  (value < 65536)) {
            return String.fromCharCode(value);
        } else {
            this.alertService.error("Please enter valid Unicode character entity reference. Range is between 33 to 65535", true);
        }
	}

	returnTyping() {
		return false;
	}

	addGameImage() {
		this.isAddingImage = true;
		this.isAddingPicture = false;
		this.changeToImagePanel();
		this.sharedService.setPictureReferences(null,null,null,null,null,null,null,null,"GI");
	}

	removeGameImage() {
		this.iceGame.gameImage = null;
		//set course modified ture
		this.gameModified = true;
		this.sharedService.setModifiedStatus(this.tabId, this.gameModified)
	}

	showLargeImage(template: TemplateRef<any>, selectedImageId: any) {
        this.selectedImage = {
			'id': '',
			'thumbnailImage': '',
			'largeImage': '',
			'title': ''
		}
        //show loading message
        var loadingMessage = null;
        this.translate.get('ICE_COURSE.COURSE_PREVIEW_IMAGE_LOADING').subscribe((res: string) => {
            loadingMessage = res;
        });
		this.loadingService.showLoading(true, null, loadingMessage, null);
        this.courseService.getSelectedImage(selectedImageId, UserVariable.getUserToken(), this);
        this.selectedModalTemplate = template;
    }

	public modelRefCloseEvent() {
		this.modalRef.hide()
		this.selectedImage = {
			'id': '',
			'thumbnailImage': '',
			'largeImage': '',
			'title': ''
		}
	}

	private changeToImagePanel() {
		SharedService.openReusablepanel('images');
		this.isImageReusablePanelOpened = true;
	}

	openModal(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template);
	}

	addFraction(num: string, den: string) {
		this.modalRef.hide()
			if (den != "" && num != "") {
				let denominator = parseInt(den);
				if (denominator != 0) {
					const frac = {
						numerator: num,
						denominator: den,
						type: 'fraction'
					};
					const fracStr = JSON.stringify(frac);
					let event = JSON.parse(fracStr);
					const fracObj = {
						data: event
					};
					const fracObjStr = JSON.stringify(fracObj);
					let data = JSON.parse(fracObjStr);
					this.iceCKEditor.insertTagEditArea(data, this.elementRef,editor,CKEDITOR, this.iceGame, "ICEMemoryGame" )
				} else {
					this.alertService.clear();
					this.alertService.error(this.translate.instant('WEB_EDITOR.DENOMINATOR_ZERO'));
				}
			} else {
				this.alertService.clear();
				this.alertService.error(this.translate.instant('WEB_EDITOR.NUMERATOR_AND_DENOMINATOR_EMPTY'));
			}
	  }

	addSpecialCharacter(option: any){
		this.modalRef.hide()
			if(option != ""){
				this.iceCKEditor.insertTagEditArea(option, this.elementRef,editor,CKEDITOR, this.iceGame, "ICEICEMemoryGame")
			}
	}

	shuffle(array: Array<string>) {
		var tmp, current, top = array.length;
		if(top) while(--top) {
			current = Math.floor(Math.random() * (top + 1));
			tmp = array[current];
			array[current] = array[top];
			array[top] = tmp;
		}
		return array;
	}

	// save game details
	public gameDataSave(){

		if (!this.validateForm()) {
			return;
		}
		else {

		this.updateGameDescription(); 
		this.gameModified = false;
		this.sharedService.setModifiedStatus(this.tabId, this.gameModified)
		// var gameDetails = {
		// 	target: this.iceGame.gameImage.id,
		// 	matchContent: []
		// }
		if(this.iceGame.gameImage != null){
			this.gameDetails.target = this.iceGame.gameImage.id;
		}else{
			this.gameDetails.target = "";
		}
				
		var positionArray=[];
		for (var i=0;i<20;i++){
			positionArray[i]= (i+1).toString();
		} 
		positionArray = this.shuffle(positionArray);
		for (var row = 0 ; row < Object.keys(this.gameData.qaPanels).length; row++) {
			for (var seq = 0 ; seq < Object.keys(this.gameData.qaPanels[row+'_'+this.tabId].qaPanelData).length; seq++) {
				var qIndex = row * 10 + seq;
				var aIndex = qIndex + 5;
				var tagIndex = seq + 1 + row * 5;
				if(this.gameData.qaPanels[row+'_'+this.tabId].qaPanelData[seq+'_'+this.tabId].questionIsText) {
					//gameDetails.matchContent[qIndex].tag = "2";
					this.gameDetails.matchContent[qIndex] ={}
					this.gameDetails.matchContent[qIndex].id = positionArray[qIndex];
					this.gameDetails.matchContent[qIndex].type = "text";
					this.gameDetails.matchContent[qIndex].tag = tagIndex.toString();
					this.xmlContent ="";
					//check if edior text is empty
					var editortext = $('.qpanel_ck_editor' + row + '' + seq + this.tabId).text();
					if(this.isNullOrWhitespace(editortext) == true) {
						this.gameDetails.matchContent[qIndex].text = '<ice></ice>';
					} else {
						//process name
						var name = this.getEditorContent('.qpanel_ck_editor' + row + '' + seq + this.tabId);
						this.iceCKEditor.processXML(name,false,this.xmlContent);
						this.xmlContent = this.iceCKEditor.getXMLContent();
						// this.xmlContent = this.xmlContent.replace(/'/g, '"');
						this.gameDetails.matchContent[qIndex].text =  '<ice>'+ this.xmlContent + '</ice>';
					}
				} else if(this.gameData.qaPanels[row+'_'+this.tabId].qaPanelData[seq+'_'+this.tabId].questionIsImage) {
					//	gameDetails.matchContent[qIndex].tag = "2";
					this.gameDetails.matchContent[qIndex] = {}
					this.gameDetails.matchContent[qIndex].id = positionArray[qIndex];
					this.gameDetails.matchContent[qIndex].type = "image";
					this.gameDetails.matchContent[qIndex].tag = tagIndex.toString();
					this.gameDetails.matchContent[qIndex].text = this.gameData.qaPanels[row+'_'+this.tabId].qaPanelData[seq+'_'+this.tabId].questionImageData.id;
				} else {
					this.gameDetails.matchContent[qIndex] = {}
					this.gameDetails.matchContent[qIndex].id = "";
					this.gameDetails.matchContent[qIndex].type = "";
					this.gameDetails.matchContent[qIndex].tag = "";
					this.gameDetails.matchContent[qIndex].text = "";
				}
				if(this.gameData.qaPanels[row+'_'+this.tabId].qaPanelData[seq+'_'+this.tabId].answerIsText) {
				//	gameDetails.matchContent[aIndex].tag = "2";
					this.gameDetails.matchContent[aIndex] = {}
					this.gameDetails.matchContent[aIndex].id = positionArray[aIndex];
					this.gameDetails.matchContent[aIndex].type = "text";
					this.gameDetails.matchContent[aIndex].tag = tagIndex.toString();
					this.xmlContent ="";
					//check if edior text is empty
					var editortext = $('.apanel_ck_editor' + row + '' + seq + this.tabId).text();
					if(this.isNullOrWhitespace(editortext) == true) {
						this.gameDetails.matchContent[aIndex].text = '<ice></ice>';
					} else {
						//process name
						var name = this.getEditorContent('.apanel_ck_editor' + row + '' + seq + this.tabId);
						this.iceCKEditor.processXML(name,false,this.xmlContent);
						this.xmlContent = this.iceCKEditor.getXMLContent();
						this.gameDetails.matchContent[aIndex].text = '<ice>'+ this.xmlContent + '</ice>';
					}
				} else if (this.gameData.qaPanels[row+'_'+this.tabId].qaPanelData[seq+'_'+this.tabId].answerIsImage) {
					//	gameDetails.matchContent[aIndex].tag = "2";
					this.gameDetails.matchContent[aIndex] = {}
					this.gameDetails.matchContent[aIndex].id = positionArray[aIndex];
					this.gameDetails.matchContent[aIndex].type = "image";
					this.gameDetails.matchContent[aIndex].tag = tagIndex.toString();
					this.gameDetails.matchContent[aIndex].text = this.gameData.qaPanels[row+'_'+this.tabId].qaPanelData[seq+'_'+this.tabId].answerImageData.id;
				} else {
					this.gameDetails.matchContent[aIndex] = {}
					this.gameDetails.matchContent[aIndex].id = "";
					this.gameDetails.matchContent[aIndex].type = "";
					this.gameDetails.matchContent[aIndex].tag = "";
					this.gameDetails.matchContent[aIndex].text = "";
				}
			}
		}
		var sendingGameData = {
			matchGame : this.gameDetails
		}

		var request = this.iceGame;
		request.gameData = JSON.stringify(sendingGameData);
		var loadingMessage = null;
		this.translate.get('ICE_GAME.MEMORY_GAME.SAVE_GAME_INFO').subscribe((res: string) => {
			loadingMessage = res;
		});
		this.loadingService.showLoading(true, null, loadingMessage, null);
		this.courseService.saveICEGameData(JSON.stringify(request), this.iceGame.id, UserVariable.getUserToken(), this);	
	}
}

	// update games description with CK Editor
	updateGameDescription() {
		this.xmlContent ="";
		//check if edior text is empty
		var editortext = $('.games_ck_editor' + this.tabId).text();
		if(this.isNullOrWhitespace(editortext) == true) {
			this.iceGame.description = '<ice></ice>';
		} else {
			//process name
			var name = this.getEditorContent('.games_ck_editor' + this.tabId); 
			this.iceCKEditor.processXML(name,false,this.xmlContent);
			this.xmlContent = this.iceCKEditor.getXMLContent();
			this.iceGame.description = '<ice>'+ this.xmlContent + '</ice>';
			this.xmlContent = this.xmlContent.substring(3, this.xmlContent.length - 4);
			this.gameDescription = this.xmlContent;
		}
	}

	numberOnly(event): boolean {
		const charCode = (event.which) ? event.which : event.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}

	isNullOrWhitespace(input) {
		console.log("===null")
		return !/\S/.test(input);
	}

	private getEditorContent(editorName: string): string {
		let el = this.elementRef.nativeElement.querySelector(editorName);
		if(el != null){
			return el.innerHTML;
		}
	}

	showPreview(){

	}

	// Cancel changes
	cancel() {
		let index = this.breadCrumbList.length-1;
		this.sharedService.changeIceItemRequest(this.breadCrumbList[index].iceType, this.breadCrumbList[index]);
		this.breadCrumbList.length = index;
	}

	public openCancelConfirmationModel(template: TemplateRef<any>) {
		if(this.sharedService.getModifiedStatus(this.tabId)){
		  this.modalRef = this.modalService.show(template, this.modalConfig);
		}else{
		  this.cancel();
		}
	}
	
	public clickBreadCrumb(data:any,index:number){
		let self = this;
		if(this.gameModified == false){
			if(data != null){
				this.sharedService.changeIceItemRequest(data.iceType, data);
				this.breadCrumbList.length = index;
			} else{
			  //nothing to do
			}
		} else{
			self.alertService.warn(self.translate.instant('ICE_GAME.SAVE_GAMEDATA'));
		}
	}

	updateBreadCrumb(){
		this.breadCrumbList = this.sharedService.getICEBreadCrumb(this.iceGame.courseId);
		var html = this.iceGame.descriptionHTML;
		var div = document.createElement("div");
		div.innerHTML = html;
		this.gameDescriptionShowInDetails = div.textContent || div.innerText || "";
	}

	onSuccess(data: WsResponse, serviceType: WsType) {
		if(serviceType == WsType.SAVE_ICE_GAME_DATA){
			this.showSuccessAlert('ICE_GAME.GAME_UPDATED_ALERT');  
			console.log("=====Game successfully updated=====");	
			this.loadingService.hideLoading();
		} else if(serviceType == WsType.GET_ALL_ICE_GAME_INFO_BY_ID){
			this.iceGame.description = data.payload.description;
			this.iceGame.descriptionHTML = data.payload.descriptionHTML;
			this.updateBreadCrumb();
			 this.updateDescription();
			 setTimeout(() => {
				this.setEditor("",CK_Editor_Type.GAME_EDITOR);
			}, 200);
			if(data.payload.gameData != null && data.payload.gameData != "") {
				if(this.loadGameDetails(data.payload)) {
					this.gameGenarated = true;
					setTimeout(() => {
						this.setEditor("",CK_Editor_Type.DDQ_CELL_EDITOR);
					}, 200);
				}
			} else {
				this.generateGame();
			}	
			this.loadingService.hideLoading();
		} else if (serviceType == WsType.GET_SELECTED_IMAGE_DATA) {
            this.selectedImage = JSON.parse(data.payload);
            this.loadingService.hideLoading();
            this.modalRef = this.modalService.show(this.selectedModalTemplate, this.modalConfig);
        }
	}

	onFail(data: WsResponse, serviceType:WsType){
		if (serviceType == WsType.SAVE_ICE_GAME_DATA) {
			this.alertService.error(data.statusDescription, false);
			console.log("=====Failed to save memory game data=====");
			this.loadingService.hideLoading();
		} else if (serviceType == WsType.GET_ALL_ICE_GAME_INFO_BY_ID) {
			this.alertService.error(data.statusDescription, false);
			console.log("=====Failed to get ice game data by Id=====");
			this.loadingService.hideLoading();
   		} else if (serviceType == WsType.GET_SELECTED_IMAGE_DATA) {
            this.loadingService.hideLoading();
        }
	}
	
	// set game details with UI
	loadGameDetails(payload:WsResponse['payload']){
		this.gameData = {};
		this.gameGenarated = false;
		if(payload.gameData != null && payload.gameData != "") {
			let gameDataObject = JSON.parse(payload.gameData);	
			this.existingGameData = payload.gameData;
			this.numOfRows = 2;
			this.numOfSequences = 5;
			this.gameData.numOfRows = 2;
			this.gameData.numOfSequences = 5;
			var qaPanels = {};

			for(let i = 0; i < this.numOfRows; i++) {
				qaPanels[i+'_'+this.tabId] = {};
				qaPanels[i+'_'+this.tabId].qaPanelData = {};
				for(let x = 0; x < this.numOfSequences; x++) {
					var qIndex = i * 10 + x;
					var aIndex = qIndex + 5;
					var tagIndex = x + 1 + i * 5;
					qaPanels[i+'_'+this.tabId].qaPanelData[x+'_'+this.tabId] = {};
					if(gameDataObject.matchGame.matchContent[qIndex] != undefined){
						if(gameDataObject.matchGame.matchContent[qIndex].type == "text"){
							qaPanels[i+'_'+this.tabId].qaPanelData[x+'_'+this.tabId].questionIsText = true;
							qaPanels[i+'_'+this.tabId].qaPanelData[x+'_'+this.tabId].questionIsImage = false;
							qaPanels[i+'_'+this.tabId].qaPanelData[x+'_'+this.tabId].questionTextData = gameDataObject.matchGame.matchContent[qIndex].text ;
							qaPanels[i+'_'+this.tabId].qaPanelData[x+'_'+this.tabId].questionImageData = {};
						}else if(gameDataObject.matchGame.matchContent[qIndex].type == "image"){
							qaPanels[i+'_'+this.tabId].qaPanelData[x+'_'+this.tabId].questionIsText = false;
							qaPanels[i+'_'+this.tabId].qaPanelData[x+'_'+this.tabId].questionIsImage = true;
							qaPanels[i+'_'+this.tabId].qaPanelData[x+'_'+this.tabId].questionTextData = "";
							qaPanels[i+'_'+this.tabId].qaPanelData[x+'_'+this.tabId].questionImageData = {};
							qaPanels[i+'_'+this.tabId].qaPanelData[x+'_'+this.tabId].questionImageData = gameDataObject.matchGame.matchContent[qIndex].text ;
						}else{
							qaPanels[i+'_'+this.tabId].qaPanelData[x+'_'+this.tabId].questionIsText = false;
							qaPanels[i+'_'+this.tabId].qaPanelData[x+'_'+this.tabId].questionIsImage = false;
							qaPanels[i+'_'+this.tabId].qaPanelData[x+'_'+this.tabId].questionTextData = "";
							qaPanels[i+'_'+this.tabId].qaPanelData[x+'_'+this.tabId].questionImageData = {};
						}
					}

					if(gameDataObject.matchGame.matchContent[aIndex] != undefined){
						if(gameDataObject.matchGame.matchContent[aIndex].type == "text"){
							qaPanels[i+'_'+this.tabId].qaPanelData[x+'_'+this.tabId].answerIsText = true;
							qaPanels[i+'_'+this.tabId].qaPanelData[x+'_'+this.tabId].answerIsImage = false;
							qaPanels[i+'_'+this.tabId].qaPanelData[x+'_'+this.tabId].answerTextData = gameDataObject.matchGame.matchContent[aIndex].text ;
							qaPanels[i+'_'+this.tabId].qaPanelData[x+'_'+this.tabId].answerImageData = {};
						}else if(gameDataObject.matchGame.matchContent[aIndex].type == "image"){
							qaPanels[i+'_'+this.tabId].qaPanelData[x+'_'+this.tabId].answerIsText = false;
							qaPanels[i+'_'+this.tabId].qaPanelData[x+'_'+this.tabId].answerIsImage = true;
							qaPanels[i+'_'+this.tabId].qaPanelData[x+'_'+this.tabId].answerTextData = "";
							qaPanels[i+'_'+this.tabId].qaPanelData[x+'_'+this.tabId].answerImageData = {};
							qaPanels[i+'_'+this.tabId].qaPanelData[x+'_'+this.tabId].answerImageData = gameDataObject.matchGame.matchContent[aIndex].text ;
						}else{
							qaPanels[i+'_'+this.tabId].qaPanelData[x+'_'+this.tabId].answerIsText = false;
							qaPanels[i+'_'+this.tabId].qaPanelData[x+'_'+this.tabId].answerIsImage = false;
							qaPanels[i+'_'+this.tabId].qaPanelData[x+'_'+this.tabId].answerTextData = "";
							qaPanels[i+'_'+this.tabId].qaPanelData[x+'_'+this.tabId].answerImageData = {};
						}
					}

				}
			}
			this.gameData.qaPanels = qaPanels
			if(gameDataObject.matchGame.target.id != null && gameDataObject.matchGame.target.id != ""){
				this.iceGame.gameImage = new ICEGameImage(gameDataObject.matchGame.target.id,gameDataObject.matchGame.target.thumbnailImage,
					gameDataObject.matchGame.target.largeImage,gameDataObject.matchGame.target.title);
			}
			this.gameDetails = gameDataObject.matchGame
		}
		this.iceGame.gameData = JSON.stringify(this.gameData);
		// Save iceGame object and gameData in shared service with tab id when openinng the game in a tab
		this.sharedService.setOpenedICEModules(SharedService.getTabId(), 'ICEGame-Memory', this.iceGame);
		let self = this;
		let commonVariables = this.sharedService.getCommonICEModuleVariableNames('memoryGame');
		var commonVariableValues: Array<any> = [];
		commonVariables.forEach(function (varName) {
			commonVariableValues[varName] = self[varName];
		}); 
		this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'memoryGame', commonVariableValues);
		return true;
	}

	showSuccessAlert(en:string){
		var alert = null;
		this.translate.get(en).subscribe((res: string) => {
			alert = res;
		});
		this.alertService.success(alert);
	}

	replaceWhiteSpace(str) {
		return str.replace(/[\s\u200B]/g,'');
	}

	/**
	 * validate form
	 */
	validateForm(): boolean {
		let self = this;
		var editortext_desc = this.replaceWhiteSpace($(".games_ck_editor"+ self.tabId).text());
		var length_desc = editortext_desc.length;

		if (length_desc < 1) {
			this.alertService.error(this.translate.instant('ICE_GAME.DESCRIPTION_ERROR'));
			return false;		
		}
		
		return true; 
	}

}
