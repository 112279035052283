import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {BsDatepickerConfig} from "ngx-bootstrap/datepicker";
import {AlertService} from "../../../util/alert/alert.service";
import {LoadingService} from "../../../util/loading/loading.service";
import {TranslateService} from "@ngx-translate/core";
import {DatePipe} from "@angular/common";
import {ManagemccService} from "../managemcc.service";
import {WsResponse} from "../../../util/ws-response.model";
import {WsType} from "../../../util/ws-type";
import {UserVariable} from "../../../util/common/user-variable";
import {CommonUtil} from "../../../util/common/common-util";

@Component({
  selector: 'app-approvemcc',
  templateUrl: './approvemcc.component.html',
  styleUrls: ['./approvemcc.component.css']
})
export class ApprovemccComponent implements OnInit {
  public mccId: string;
  clickedRow:string

  endConDate : any;
  endComDate : any;
  appConDate : any;
  appComDate : any;
  documentUpload : any;
  public bsConfig: Partial<BsDatepickerConfig>
  @ViewChild('endContractorName') endContractorName: ElementRef;
  @ViewChild('endCompanyName') endCompanyName: ElementRef;
  @ViewChild('appContractorName') appContractorName: ElementRef;
  @ViewChild('appCompanyName') appCompanyName: ElementRef;
  public mcDocumentUpload: any;
  public projectId: string = null;
  constructor(
      public bsModalRef: BsModalRef,
      private alertService: AlertService,
      private loadingService: LoadingService,
      private translate: TranslateService,
      private managemccService:ManagemccService,
      private commonUtil: CommonUtil,
  ) {
    this.bsConfig = Object.assign({}, { containerClass: 'theme-default'  });
    this.projectId = UserVariable.projectId;
  }

  ngOnInit() {
  }
  cancel(){
    this.bsModalRef.hide()
  }
  save(){
    if (this.endContractorName.nativeElement.value == "" || this.endContractorName.nativeElement.value == "" || this.appContractorName.nativeElement.value == "" || this.appCompanyName.nativeElement.value == "" ) {
      this.alertService.clear();
      this.alertService.error("Please fill all the required fields")
    } else if (!this.endContractorName.nativeElement.value || !this.endContractorName.nativeElement.value || !this.appContractorName.nativeElement.value || !this.appCompanyName.nativeElement.value  ) {
      this.alertService.clear();
      this.alertService.error("Please fill all the required fields")
    }
    else if (!this.endConDate || !this.endComDate || !this.appConDate || !this.appComDate  ) {
      this.alertService.clear();
      this.alertService.error("Please fill all the required fields")
    }else if(this.endConDate == 'Invalid Date' || this.endComDate == 'Invalid Date'  || this.appConDate== 'Invalid Date'  || this.appComDate== 'Invalid Date' ){
      this.alertService.clear();
      this.alertService.error("Invalid date selected")
    }else if(this.mcDocumentUpload == null){
      this.alertService.clear();
      this.alertService.error("Please upload returned PDF file.");
    }
    else{
      this.loadingService.showLoading(true, false, 'Saving approval data', 0);
      let data = {
        mcId:  this.mccId,
        contractorLeadName: this.endContractorName.nativeElement.value,
        contractorLeadDate: new DatePipe("en-US").transform(new Date(this.endConDate), 'yyyy-MM-dd'),
        companySubseaEngName: this.endContractorName.nativeElement.value,
        companySubseaEngDate: new DatePipe("en-US").transform(new Date(this.endComDate), 'yyyy-MM-dd'),
        contractorPMName: this.appContractorName.nativeElement.value ,
        contractorPMDate: new DatePipe("en-US").transform(new Date(this.appConDate), 'yyyy-MM-dd'),
        companyLeadName: this.appCompanyName.nativeElement.value,
        companyLeadDate: new DatePipe("en-US").transform(new Date(this.appComDate), 'yyyy-MM-dd'),
        type: "Approve"
      }
      this.managemccService.approveMC(data,this)
    }
  }
  changeColor(val){
    // this.clickedRow = val;
  }

  onFail(data: WsResponse, serviceType: WsType) {
    if(serviceType == WsType.APPROVE_MC){
      this.alertService.error(data.statusDescription);
      this.loadingService.hideLoading();
    }else if(serviceType == WsType.ADD_NEW_MC_DOCUMENT){
      this.alertService.error(data.statusDescription);
      this.loadingService.hideLoading()
    }
  }

  onSuccess(data: WsResponse, serviceType: WsType) {
    if(serviceType == WsType.APPROVE_MC){
      if(data.payload){
        console.log(data.payload)
      }
      if(this.mcDocumentUpload!=null){
        this.onUpload(this.mccId)
      }else {
        this.alertService.success("Sucessfully Approved MCC");
        this.loadingService.hideLoading();
        this.bsModalRef.hide()
        this.managemccService.updateUIafterApprovalRequest(this.mccId,false);
      }

    }else if(serviceType == WsType.ADD_NEW_MC_DOCUMENT){
      // this.translate.get('NEW_MCC_DOCUMENT.ADD_SUCCESS').subscribe((res: string) => {
      //   this.alertService.success(res, false);
      // });
      this.alertService.success("Sucessfully Approved MCC");
      this.loadingService.hideLoading()
      this.mcDocumentUpload = null;
      this.bsModalRef.hide()
      this.managemccService.updateUIafterApprovalRequest(this.mccId,true);
    }
  }

  onFileChanged(event) {
    let i: number = 0;
    for (i = 0; i < event.target.files.length; i++) {
      let newFile = <File>event.target.files[i];
      if (newFile != undefined) {
        let parts = newFile.name.split('.')
        if (parts.length > 1) {
          let fileExtension = parts.pop();
          if (fileExtension == 'pdf') {
            let fileName = parts.join('.');
            fileName = fileName.trim();
            if (this.validateFile(newFile)) {
              //set for uploading to the local file system
              var mcDocumentUpload =
                  {
                    docName: fileName,
                    docType: fileExtension,
                    file: newFile,
                    isAdded: true,
                    isDeleted: false
                  };
              this.mcDocumentUpload = mcDocumentUpload;
            } else {
              //file size is too large
            }
          } else {
            //file is not pdf
            this.alertService.clear();
            this.translate.get('NEW_MCC_DOCUMENT.INVALID_FILE_TYPE').subscribe((res: string) => {
              this.alertService.error(res, false);
            });

          }
        } else {
          this.translate.get('NEW_MCC_DOCUMENT.INVALID_FILE_NAME').subscribe((res: string) => {
            this.alertService.error(res, false);
          });
        }
      } else {
        //file is not valid
        this.alertService.clear();
        this.translate.get('NEW_MCC_DOCUMENT.INVALID_FILE').subscribe((res: string) => {
          this.alertService.error(res, false);
        });
        this.alertService.error("invalid file");
      }
    }
  }

  validateFile(file: File): boolean {
    if (file.size > 104857601) {
      this.translate.get('NEW_MCC_DOCUMENT.SIZE_LIMIT_EXCEEDED').subscribe((res: string) => {
        this.alertService.error(res, false);
      });
      return false;
    } else {
      return true;
    }
  }
  onUpload(mcId: string) {
    var loadingMessage;

    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    this.translate.get('NEW_MCC_DOCUMENT.LOADING_MESSAGE').subscribe((res: string) => {
      loadingMessage = res;
      this.loadingService.showLoading(true, null, loadingMessage, null);
    });

    if (this.mcDocumentUpload && this.mcDocumentUpload.isAdded && !this.mcDocumentUpload.isDeleted) {
      let element = this.mcDocumentUpload;
      let uploadFileNameWithExt = element.docName + "." + element.docType;
      const uploadData = new FormData();

      uploadData.append("mcId", mcId);
      uploadData.append("projectId", this.projectId);
      uploadData.append("filePath", '');
      uploadData.append("fileName", element.docName);
      uploadData.append("contentType", element.docType);
      uploadData.append("token", token);
      uploadData.append("file", element.file, element.docName);
      uploadData.append("addedUserId", '');
      uploadData.append("isAdded", element.isAdded);
      uploadData.append("isDeleted", element.isDeleted);
      this.managemccService.uploadFile(uploadData, this);
    }
  }
}
