<div *ngIf="!disable && !isAction">
    <button id="landing-table-buttons" class="btn btn-sm btn-outline-dark py-0 my-1 px-1 border-0" (click)="invoke(type, data, facilityId)">
        <i class={{icon}} aria-hidden="true"></i>
    </button>
</div>
<div *ngIf="disable">
    <a class="btn btn-sm btn-outline-dark py-0 my-1 px-1 border-0" (click)="invoke(type, data, facilityId)">
        <i class="text-black-50 {{icon}}" aria-hidden="true"></i>
    </a>
</div>

<div *ngIf="isAction">
    <button type="button" class="btn btn-secondary" (click)="reQueue()">
		{{ 'REUSABLE_UPDATE.RE_QUEUE' | translate}}
	</button>
</div>

<ng-template #revisionTemplate>
	<div class="modal-header">
		<h4 class="modal-title pull-left">{{ 'REUSABLE_UPDATE.MORE_INFO' | translate}}</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		{{moreInfo}}
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="bsModalRef.hide()">
			{{ 'REUSABLE_UPDATE.OK' | translate}}
		</button>
	</div>
</ng-template>

<ng-template #detailTemplate>
	<div class="modal-header">
		<h4 class="modal-title pull-left">{{ 'REUSABLE_UPDATE.DETAILS' | translate}}</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
			<ag-grid-angular #agGrid style="width: 100%; height: 400px;" id="procedureStore" [rowData]="rowData" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }" class="ag-theme-balham"
			[columnDefs]="columnDefs" [frameworkComponents]="frameworkComponents" [defaultColDef]="defaultColDef"
			[enableRangeSelection]="true"  [paginationPageSize]="100" [pagination]="true"
			[suppressMenuHide]="true" [gridOptions]="gridOptions"
			>
		   </ag-grid-angular>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="bsModalRef.hide()">
			{{ 'REUSABLE_UPDATE.OK' | translate}}
		</button>
	</div>
</ng-template>
