import { Component, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { RolesService } from './../roles.service';
import { LoadingService } from 'src/app/util/loading/loading.service';
import { CommonUtil } from 'src/app/util/common/common-util';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/util/alert/alert.service';
import { SharedService } from '../../../../util/shared.service';
import { ClientService } from '../../../../client/client.service';
import { NgxSelectModule, INgxSelectOptions } from 'ngx-select-ex';
@Component({
  selector: 'app-assign-user',
  templateUrl: './assign-user.component.html',
  styleUrls: ['./assign-user.component.css']
})
export class AssignUserComponent implements OnInit {
  public clientList: Array<any> = [];
  public clientListGrid: Array<any> = [];
  selectedClient: any;
  public selectedClients: Array<any> = [{ 'id': '', 'text': 'Select' }];
  public selectedClientIdValue: any;
  public selectedClientName: any;
  public clientItems: Array<any>;

  public userList: Array<any> = [];
  public selectedUser: Array<any> = [{ 'id': '', 'text': 'Select' }];
  public selectedUserIdValue: any;
  public selectedUserName: any;

  public showDiv: boolean;
  public roleList: Array<any> = [];
  public projectList: Array<any> = [];
  public allList: Array<any> = [];
  public clientAll: Array<any> = [];
  public projectAll: Array<any> = [];
  public assignedClients: Array<any> = [];
  public assignedProjects: Array<any> = [];
  
  public saveRoles: string;
  public saveClients: string;
  public saveProjects: string;
  public isInternalUser: boolean = false;
  constructor(private clientService: ClientService, private roleService: RolesService, private sharedService: SharedService, private translate: TranslateService, private alertService: AlertService,
		private modalService: BsModalService, private router: Router, private loadingService: LoadingService, private commonUtil: CommonUtil) {
		translate.addLangs(["es", "en"]);
		translate.setDefaultLang("en");
		let browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
    this.showDiv = false;
  }

  ngOnInit() {
		console.log("data loading=================")

		this.clientService.getClientList().subscribe(
			data => {

				this.loadClients(data.payload);
				if (localStorage.getItem("clientId") != null && localStorage.getItem("clientId") != "") {
					this.selectedClient = localStorage.getItem("clientId")
					let list: Array<any> = [];
					list.push(
						{ id: this.selectedClient, text: localStorage.getItem("clientText") }
					);

					this.clientItems = list;
					// this.getAllUsersByClientId();
				}
			},
			error => {
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
      });  
	}
  status: boolean = false;
  pageToggle() {
    this.status = !this.status;
    $('.page-container').toggleClass('toggled');
    $('li.role').removeClass('active-role');
  }
  collapseAll(event) {
    let target = event.target || event.srcElement || event.currentTarget;
    if (!$(target).hasClass("expand") ) {
      $(target).addClass('expand');
      $('#accordionExample .collapse').removeClass('show');  
    }else{
      $(target).removeClass('expand'); 
      $('#accordionExample .collapse').addClass('show'); 
    }
    
    
    //$('.panel-collapse.in').collapse('hide');
  }

  loadClients(data: any) {
		//   console.log(data);
		let list: Array<any> = []
		list.push({ id: "-1", text: "--Select Client--" })
		list.push({ id: "null", text: "Internal Users" })

		var sortedArray: any[] = data.sort((n1,n2) => {
			if (n1.clientName > n2.clientName) {
				return 1;
			}
		
			if (n1.clientName < n2.clientName) {
				return -1;
			}
		
			return 0;
		});

		sortedArray.forEach(item => {
			list.push(
				{ id: item.clientId, text: item.clientName }
      )     
		});
		this.clientList = list


		let list1: Array<any> = [];
		list1.push(
			{ id: "-1", text: "--Select Client--" }
		);

		this.clientItems = list1;

	}

  removedClient(event) {
    this.selectedClients = [{ 'id': '', 'text': 'Select' }]
    this.selectedClientName = '';
    this.selectedClientIdValue = '';

  }

  selectedClientId(event) {
    this.selectedClientName = event[0].data.text;
  if(this.selectedClientName == 'Internal Users'){
        this.isInternalUser = true;

    }else{
      this.isInternalUser = false;
    }
    this.selectedClientIdValue = event[0].data.id;
    this.loadingService.showLoading(true,null,"Loading..",null);
    this.roleService.getAllUsersByClientId(this.selectedClientIdValue)
			.subscribe(data => {
        this.loadingService.hideLoading();
        if (data.payload != null) {
          this.userList = [];
          this.userList.push({ 'id': '', 'text': 'Select' })
          data.payload.forEach(element => {
              this.userList.push({ 
              'id': element.id,
              'text': element.userName,
              'emailAddress': element.emailAddress,
              'status': element.status,
              'clientUser': element.clientUser,
              });
            });
          }
			},
			error => {
        this.loadingService.hideLoading();
        this.alertService.clear()
				this.alertService.error(error.statusDescription)

			});

  }
  removedUser(event) {
    // this.selectedClient = [{ 'id': '', 'text': 'Select' }]
    // this.selectedClientName = '';
    // this.selectedClientIdValue = '';

  }
  selectedUserId(event) {
    this.selectedUserIdValue = event[0].data.id;
    this.showDiv = true;
    this.allList = [];
    this.clientAll =[];
    this.projectAll =[];

    this.roleService.getRolesByUserId(this.selectedUserIdValue).subscribe(data => {
      this.loadingService.hideLoading();
      if (data.payload != null) {
        this.roleList = [];
        data.payload.forEach(element => {
            this.roleList.push({ 
            'roleID': element.roleID,
            'roleName': element.roleName,
            'addedBy': element.addedBy,
            'added': element.added,
            'addedDate': element.addedDate,
            'description': element.description,
            });
          });
        }
    },
			error => {
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
      });

 

      this.roleService.getClientsByUserId(this.selectedUserIdValue).subscribe(
        data => {  
          if (data.payload != null) {
            this.clientListGrid = [];
            data.payload.forEach(element => {
              this.clientListGrid.push(
                { id: element.clientId, clientName: element.clientName }
              )
              });
              
              this.roleService.getProjectList(this.selectedUserIdValue).
              subscribe(data => {
                this.loadProject(data.payload);
            },
            error => {
      
            });
            } 
        },
        error => {
          this.alertService.clear()
          this.alertService.error(error.statusDescription)
        });        
        
        this.roleService.getUserRoleAssignmentsByUserId(this.selectedUserIdValue, "CLIENT").subscribe(
          data => {  
            if (data.payload != null) {
              this.assignedClients = [];
              data.payload.forEach(element => {
                this.assignedClients.push(
                  { roleID: element.roleID, refId: element.refId }
                )
                });} 
          },
          error => {
            this.alertService.clear()
            this.alertService.error(error.statusDescription)
          });        

          this.roleService.getUserRoleAssignmentsByUserId(this.selectedUserIdValue, "PROJECT").subscribe(
            data => {  
              if (data.payload != null) {
                this.assignedProjects = [];
                data.payload.forEach(element => {
                  this.assignedProjects.push(
                    { roleID: element.roleID, refId: element.refId }
                  )
                  });} 
            },
            error => {
              this.alertService.clear()
              this.alertService.error(error.statusDescription)
            });        
  }
  
  loadProject(data: any) {
    if (data != null) {
      this.projectList = [];
      data.forEach(element => {
          this.projectList.push({ 
          'projectId': element.id,
          'projectName': element.projectName,
          'clientName': element.clientName,
          'clientId': element.clientId,
          });
        });

        // Putting roles, clients and projects into a list. List within list
        //Role
        // -- Client
        // -----Project
        let added = false;
        let projectAdded = false;
        for (let i=0; i< this.roleList.length; i++) {
          added = false;
          projectAdded = false;
          for(let j=0; j< this.clientListGrid.length; j++) {
            if (this.roleList[i].added == false) {
              for (let l=0; l< this.assignedClients.length; l++) {
                if((this.roleList[i].roleID == this.assignedClients[l].roleID) && 
                          (this.clientListGrid[j].id == this.assignedClients[l].refId)){
                    added = true;
                    break;
                }
              }
            } else if (this.roleList[i].added ==true){
              added = true;
            }

            for(let k=0; k< this.projectList.length; k++) {
              if (this.roleList[i].added == false && added == false) {
              for (let m=0; m< this.assignedProjects.length; m++) {
                if((this.roleList[i].roleID == this.assignedProjects[m].roleID) && 
                          (this.projectList[k].projectId == this.assignedProjects[m].refId)){
                    projectAdded = true;
                    break;
                }
              }
            }
            else if (this.roleList[i].added ==true || added == true){
              projectAdded = true;
            }
            if (this.projectList[k].clientId == this.clientListGrid[j].id)
              this.projectAll.push(
                { projectId: this.projectList[k].projectId, 
                  projectName: this.projectList[k].projectName,
                  clientId: this.projectList[k].clientId,
                  roleID: this.roleList[i].roleID,
                  added:projectAdded,
                  role_project_id: this.roleList[i].roleID + "|" + this.projectList[k].projectId
                }
              )
              if (this.roleList[i].added ==false || added == false) {
                projectAdded = false;
              }
            }
            this.clientAll.push(
              { clientId: this.clientListGrid[j].id, 
                clientName: this.clientListGrid[j].clientName,
                roleID: this.roleList[i].roleID,
                added:added,
                role_client_id: this.roleList[i].roleID + "|" + this.clientListGrid[j].id,
                projectList: this.projectAll
              }
            )
            if (this.roleList[i].added ==false) {
              added = false;
            }
            this.projectAll = []; 
          }
         
          this.allList.push({'roleId': this.roleList[i].roleID,
                      'roleName':this.roleList[i].roleName,
                      'added':this.roleList[i].added,
                      'clientlist': this.clientAll });
          this.clientAll = [];     
           
          
        }

        }
      }
      
      onChange(event, j, roleId, k, clientId, l, projectId) {
        if (projectId != null && projectId != "") {
          this.allList[j].clientlist[k].projectList[l].added = event;
        } else if (clientId != null && clientId != "") {
          this.allList[j].clientlist[k].added = event;
          for ( let a = 0; a < this.allList[j].clientlist[k].projectList.length; a++) {
            this.allList[j].clientlist[k].projectList[a].added = event;
          }
        } else if (roleId != null && roleId != "") {
          this.allList[j].added = event;
          for ( let a = 0; a < this.allList[j].clientlist.length; a++) {
            this.allList[j].clientlist[a].added = event;
            for (let b = 0; b < this.allList[j].clientlist[a].projectList.length; b++) {
              this.allList[j].clientlist[a].projectList[b].added =event;
            }
          }
        }

      }

      save () {
        this.saveRoles = "";
        this.saveClients = "";
        this.saveProjects = "";
        for (let i = 0; i< this.allList.length; i++) {
          if(this.allList[i].added == true) {
            this.saveRoles = this.saveRoles + "_" + this.allList[i].roleId;
          } else {
            for (let j = 0; j< this.allList[i].clientlist.length; j++) {
              if(this.allList[i].clientlist[j].added == true) {
                this.saveClients = this.saveClients + "_" + this.allList[i].clientlist[j].role_client_id;
              } else {
                for (let k = 0; k< this.allList[i].clientlist[j].projectList.length; k++){
                  if(this.allList[i].clientlist[j].projectList[k].added == true) {
                    this.saveProjects = this.saveProjects + "_" + this.allList[i].clientlist[j].projectList[k].role_project_id;
                  }
                }
              }
            }
          }
        }
        this.roleService.saveUserRoleAssignments(this.selectedUserIdValue, this.saveRoles, this.saveClients, this.saveProjects).subscribe(
          data => {
            //  this.capList ="";
             this.loadingService.hideLoading()         
             this.alertService.clear()
             this.alertService.success("Successfully Assigned role for the user")
          },
          error => {
            // this.capList ="";
             this.loadingService.hideLoading()
             this.alertService.clear()
             this.alertService.error(error.statusDescription)
          });

      }

}
