import { WorkflowComponent } from './workflow.component';
import { AddProjectWorkflowTemplateComponent } from './addprojectworkflowtemplate/add-project-workflow-template.component';
import { WorkflowTemplatesComponent } from './workfowtemplates/workflow-templates.component';
import { Injectable } from '@angular/core';


@Injectable({
	providedIn: 'root'
})
export class WorkflowLoaderService{
    constructor(){

    }
    
    public loadComponentByName(componentName: string){
        switch(componentName){
           /* case 'workflow':
                return WorkflowComponent;*/
            case 'workflowTemplates':
                return WorkflowTemplatesComponent;
            case 'addProjectWorkflowTemplate':
                return AddProjectWorkflowTemplateComponent;
            default:
                break;
        }
    }
}