import { Component } from "@angular/core";
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { CapabilityService } from '../../../../../util/capability.service'
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DependenciesmodalComponent } from '../../../../../util/dependenciesmodal/dependenciesmodal.component'


@Component({
    selector: 'child-cell',
    template: ``

})
export class DTDotnotaionRenderer implements ICellRendererAngularComp {
    public params: any;
    public modifyTopicType: boolean = false
    public bsModalRef: BsModalRef;

    constructor(private capabilityService: CapabilityService, private modalService: BsModalService){
      this.modifyTopicType = this.capabilityService.isCapabilityAssigned("modifyTopicType");
    }

    agInit(params: any): void {
        this.params = params;
        console.log(params)
    }

	public disabledDeleteBtn() {
		if (this.modifyTopicType) {
         return false
      }else{
         return true
      }
	}

    public delete() {
      const initialState : object = {
			itemId: this.params.data.docTypeAttrId,
			itemType: 'DocumentTypeAttribute'
		};
      this.bsModalRef = this.modalService.show(DependenciesmodalComponent, { initialState, class: 'modal-lg' });
   }

    refresh(): boolean {
        return false;
    }
}