<div class="container-fluid px-0">
    <div class="row px-3">
        <div class="col-sm-8 px-0">
            <p class="mb-2 h5">
                <b>{{ 'IMAGE_SEARCH_VIEW.HEADER' | translate }}</b>
            </p>
        </div>
        <div class="col-sm-4 px-0">
            <!-- <button *ngIf="changeLayout" type="submit" class="btn btn-primary btn-sm float-right reusable_btn my-1" (click)="newTagButtonClick()">
				<i class="fas fa-plus"></i>
			</button> -->
        </div>
    </div>
    <div class="col-sm-12 px-0">
        <symbiosis-search [comp]="'image'" id="image_search" (searchFunc)="searchFunc($event)"
            (showSearchOptionEE)="showSearchOptionEE($event)" (searchBarEnterFunc)="searchBarEnterFunc($event)">
        </symbiosis-search>
    </div>
    <hr class="my-2" />
</div>

<div class="container-fluid px-0" style="overflow: auto" [style.height.px]="divHeight">
    <div *ngIf="imageSearchState == constants.LOADING_STATES.LOADING"
        class="alert alert-light-dark-border mx-auto p-2 loading-alert ">
        {{ 'LOADING' | translate }}
    </div>
    <div *ngIf="imageSearchState == constants.LOADING_STATES.EMPTY_RESULT"
        class="alert alert-light-dark-border mx-auto p-2 no-images-alert ">
        {{ 'IMAGE_SEARCH_VIEW.NO_IMG_TO_SHOW' | translate }}
    </div>
    <div *ngIf="imageSearchState == constants.LOADING_STATES.HAS_RESULT" class="card-columns flip-card-board">
        <div class="card flip-card" *ngFor="let image of images; let i=index"
            [ngClass]="{'voted': flippedImage.id == image.id}">
            <div class="front"
                [ngClass]="{'d-none': flippedImage.id == image.id, 'd-block': flippedImage.id != image.id}">
                
                <img class="card-img-top img-fluid pointer-cursor" src="data:image/jpeg;base64,{{image.thumbnailImage}}"
                    (click)="showLargeImage(largeImage, image)" alt="{{image.title}}">
                <div class="card-body">
                    <!-- <p class="card-text dotted-text pointer-cursor" [popover]="popTemplate" container="body"
                        (onShown)="onShown(image)" [outsideClick]="true">{{image.title}}</p> -->
                    <p class="card-text dotted-text pointer-cursor" (click)="flipImage(image)">{{image.title}}</p>
                </div>
            </div>
            <div class="back"
                [ngClass]="{'d-none': flippedImage.id != image.id, 'd-block': flippedImage.id == image.id}">
                <img class="card-img-top blur-img img-fluid pointer-cursor"
                    src="data:image/jpeg;base64,{{image.thumbnailImage}}" alt="{{image.title}}">
                <div class="card-img-overlay text-center align-items-center d-flex flex-column justify-content-center" style="z-index: 1051;" (click)="unFlipImage()">
                    <button type="button" class="btn btn-sm my-1 btn-primary" (click)="$event.stopPropagation();getFigureInfo(flippedImage)" [disabled]="flippedImage.path==undefined ? 'disabled': ''">
                        {{ 'IMAGE_SEARCH_VIEW.BTN_TEXT_INSERT_AS_FIGURE' | translate }}
                    </button>
                    <button type="button" class="btn btn-sm my-1 btn-primary" (click)="$event.stopPropagation();getImageInfo(flippedImage)" [disabled]="flippedImage.path==undefined ? 'disabled': ''">
                        {{ 'IMAGE_SEARCH_VIEW.BTN_TEXT_INSERT_AS_IMAGE' | translate }}
                    </button>
                </div>
                <div class="card-body z-index-1050">
                    <p class="card-text dotted-text pointer-cursor z-index-1050" (click)="unFlipImage()">
                        {{image.title}}</p>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #largeImage>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{ selectedImage.title }}</h4>
        <button type="button" id="image_close" class="close pull-right" aria-label="Close" (click)="modelRefCloseEvent()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="card p-0">
            <img class="card-img-top" src="data:image/jpeg;base64,{{base64DataImage}}">
        </div>
        <div class="row mt-2 ml-2">
            <h6>Path: </h6>
            <p class="folder-path ml-1" >{{ selectedImage.folderPath }}{{ selectedImage.title }}</p>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-primary mr-auto" (click)="goToImageLocation()">Go to Path</button>
        <button type="button" class="btn btn-sm btn-secondary" (click)="modelRefCloseEvent()">{{
                'IMAGE_SEARCH_VIEW.BTN_TEXT_CLOSE' | translate }}</button>
        <button type="button" class="btn btn-sm btn-primary" (click)="getFigureInfo(selectedImage)"  [disabled]="selectedImage.path==undefined ? 'disabled': ''">{{
                'IMAGE_SEARCH_VIEW.BTN_TEXT_INSERT_AS_FIGURE' |
                translate }}</button>
        <button type="button" class="btn btn-sm btn-primary" (click)="getImageInfo(selectedImage)" [disabled]="selectedImage.path==undefined ? 'disabled': ''">{{
                'IMAGE_SEARCH_VIEW.BTN_TEXT_INSERT_AS_IMAGE' |
                translate }}</button>
    </div>
</ng-template>

<ng-template #popTemplate>
    <div class="card col-sm-8 rounded-0 p-0 border-0 mt-2 mb-1 mx-auto">
        <div class="card-body row p-2">
            <button type="button" class="btn btn-sm btn-outline-dark btn-block m-2"
                (click)="getFigureInfo(selectedImage)">{{
                    'IMAGE_SEARCH_VIEW.BTN_TEXT_INSERT_AS_FIGURE' | translate }}</button>
            <button type="button" class="btn btn-sm btn-outline-dark btn-block m-2"
                (click)="getImageInfo(selectedImage)">{{
                    'IMAGE_SEARCH_VIEW.BTN_TEXT_INSERT_AS_IMAGE' | translate }}</button>
        </div>
    </div>
</ng-template>
