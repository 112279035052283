import { Component, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { TagsService } from '../tags.service';
import { TranslateService } from '@ngx-translate/core';
import { UserVariable } from 'src/app/util/common/user-variable';
import { WsCallback } from 'src/app/util/ws-callback.interface';
import { WsResponse } from 'src/app/util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';
import { AlertService } from 'src/app/util/alert/alert.service';
import { ClassificationCellRenderComponent } from '../classification-cell-render/classification-cell-render.component';
import { AttributeTypeRenderComponent } from './attribute-type-render/attribute-type-render.component';
import { DotNotationRenderComponent } from './dot-notation-render/dot-notation-render.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { GridOptions, Module, AllModules } from "@ag-grid-enterprise/all-modules";
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { DependencyModalComponent } from './dependency-modal/dependency-modal.component';
import { LoadingService } from 'src/app/util/loading/loading.service';
import { CommonUtil } from 'src/app/util/common/common-util';

@Component({
	selector: 'app-tag-classification',
	templateUrl: './tag-classification.component.html',
	styleUrls: ['./tag-classification.component.css']
})
export class TagClassificationComponent implements OnInit, WsCallback {
	private token: any;
	public tagClassificationList = [];
	public filterText: string = '';
	public classificationEditItem: any;
	public selectedClassification: any = {
		classDesc: '', classId: '', className: ''
	}
	public tagDescription = '';
	public tagDescriptionData = '';
	public tagClassNameData = '';
	public categoryList: any = [];
	public attributeList: any = [];
	public modules: Module[] = AllModules;
	public rowData: any[];
	public tagAttributeTypeList: any[] = [];
	public dotNotations = [];
	public classAttributeList: any[] = [];
	public classAttributeAvailableList: any[] = [];
	public divHeight = window.innerHeight-230;

	private gridApi;
	private gridColumnApi;
	public overlayNoRowsTemplate;

	public frameworkComponents;
	public columnDefs;
	private bsModalRef: BsModalRef;
	public gridOptions: any;

	//New classification
	public newClassificationName: string;
	public newClassificationNameData: string;
	public newClassificationDescription: string;

	public editableMode: boolean[] = [];
	private toBeDeletedClassId: any;
	private attributeTypeUpdate: boolean = false;
	private oldClassName: any = null;
	private oldDescription: any = null;
	public paddingtop: number = 60;
	public i: number = 0;

	public selectedTagClassSpecId: string = '';
	public tempCategoryItem: any = null
    public categoryType: string = null;
	public selectedCategoryItem: any = { 'catName': '', 'catId': '' }
	
	@ViewChild('navPanel', {static: true}) navPanel: ElementRef
	
	//adding constant value
	private constSelectCategory: string = "--Select Category--";

	constructor(private tagService: TagsService, private translate: TranslateService, private alertService: AlertService,
		private modalService: BsModalService, private router: Router, private loadingService: LoadingService, private commonUtil: CommonUtil) {
		translate.addLangs(["es", "en"]);
		translate.setDefaultLang("en");
		let browserLang = translate.getBrowserLang();
		translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');

		//Initialization of variables
        this.token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(this.token)) {
            return;
        }
		//grid options
		this.gridOptions = <GridOptions>{
			context: {
				parent: this
			}
		}
	}

	ngOnInit() {
		this.init();

		//Components data for the ag-grid
		this.frameworkComponents = {
			countryCellRenderer: AttributeTypeRenderComponent,
			dotCellRenderer: DotNotationRenderComponent
		};

		if (this.navPanel.nativeElement.offsetHeight > 55) {
			this.paddingtop = 78
		} else {
			this.paddingtop = 50
		}
	}

	private init() {
		console.log("on init")
		this.tagService.getTagAttributeTypes(this);
		this.tagService.getTagClassifications(this.token, this);
	}

	public filter(itemList: any[]): any[] {
		let result: any[] = [];
		itemList.forEach(item => {
			if (item.className.toLowerCase().includes(this.filterText.toLowerCase())) {
				result[result.length] = item;
			}
		});
		return result;
	}

	/**
	 * Click on the class item.
	 * @param tagClassification 
	 */
	onClassificationSelect(tagClassification: any): void {
		console.log(tagClassification);
		this.selectedClassification = tagClassification;
		this.tagDescription = this.selectedClassification.classDesc;
		//load data for the selected classification
		this.gridApi.showLoadingOverlay()
		this.tagService.getCategoriesByClassification(this.token, this.selectedClassification.classId, this);
	}

	/**
	 * Filters attribute types that should be visible to users when click on the add button.
	 */
	updateAvailableClassAttributeList() {
		this.classAttributeAvailableList = [];
		this.classAttributeList.forEach(attribute => {
			this.classAttributeAvailableList.push(attribute);
			this.attributeList.forEach(item => {
				if (attribute.tagAttributeName === item.attributeName) {
					this.classAttributeAvailableList.splice(this.classAttributeAvailableList.length - 1, 1);
				}
			});
		});
	}

	saveDescription() {
		this.tagDescription = this.tagDescription.trim();
		if(this.tagDescription){
			if (this.tagDescription != this.oldDescription) {
				if (this.tagDescription.includes("\\")) {
					this.tagDescriptionData  = this.tagDescription.replace(/\\/g, "\\\\");
				} else {
					this.tagDescriptionData  = this.tagDescription;
				}
			}
		} else{
			this.tagDescription = this.oldDescription 
			return false
		}
		if (this.selectedClassification.className.includes("\\")) {
			for (; this.i <= 0; this.i++){
				if (this.selectedClassification.className != null) {
						this.tagClassNameData  = this.selectedClassification.className.replace(/\\/g, "\\\\");					
				}
			}
		} else {
			this.tagClassNameData  = this.selectedClassification.className;
		}	
		var data = { userToken: this.token, description: this.tagDescriptionData, classId: this.selectedClassification.classId, className: this.tagClassNameData  };
		this.tagService.updateClassificationDescription(data, this);				
	}

	/**
	 * keep existing description to restore if error occured
	 */
	editTagDescription(){
		this.oldDescription = this.tagDescription
	}

	onSuccess(data: WsResponse, serviceType: WsType) {				
		if (serviceType == WsType.GET_TAG_CLASSIFICATION) {
			this.tagClassificationList = data.payload;
			this.initEditableStatusArrayForEditButtons(this.tagClassificationList.length);
		} else if (serviceType == WsType.UPDATE_CLASSIFICATION_DESCRIPTION) {
			let alert = this.translate.instant('TAG_CLASSIFICATION.UPDATE_CLASSIFICATION_DESCRIPTION');
			this.alertService.success(alert);
			this.tagService.getTagClassifications(this.token, this);
		} else if (serviceType == WsType.GET_CATEGORIES_BY_CLASSIFICATION) {
			this.categoryList = data.payload;
			//this.prepareCategoryList();
			this.tagService.getAttributesByClassification(this.token, this.selectedClassification.classId, this);
		} else if (serviceType == WsType.GET_ATTRIBUTES_BY_CLASSIFICATION) {
			this.attributeList = data.payload;
			//this.createRowData();
			this.tagService.getTagClassAttributeList(this.token, this);
		} else if (serviceType == WsType.GET_TAG_ATTRIBUTE_TYPES) {
			this.tagAttributeTypeList = []
			const valList = data.payload;
			valList.forEach(val => {
				this.tagAttributeTypeList.push(val);
			});
		} else if (serviceType == WsType.UPDATE_TAG_ATTRIBUTE_SEQUENCE) {
			let alert = this.translate.instant('TAG_CLASSIFICATION.UPDATE_TAG_ATTRIBUTE_SEQUENCE');
			this.alertService.success(alert);
			// this.alertService.success(data.statusDescription);
		} else if (serviceType == WsType.ADD_NEW_CLASSIFICATION) {
			let alert = this.translate.instant('TAG_CLASSIFICATION.ADD_NEW_CLASSIFICATION');
			this.alertService.success(alert);
			this.init();
			/**
			 * Fix the issue Tag Classification popup disappearing for exsisting tag classification name
			 * Trello Card - https://trello.com/c/7DiYu9j7
			 */
			this.bsModalRef.hide();
		} else if (serviceType == WsType.UPDATE_TAG_ATTRIBUTE_NOTATION) {
			if(this.attributeTypeUpdate){
				let alert = this.translate.instant('TAG_CLASSIFICATION.ATTRIBUTE_TYPE_UPDATE_SUCCESS');
				this.alertService.success(alert);
				this.attributeTypeUpdate = false
			}else{
				let alert = this.translate.instant('TAG_CLASSIFICATION.DOT_NOTATION_UPDATE_SUCCESS');
				this.alertService.success(alert);
			}
			this.selectedCategoryItem = {
				'catName': '',
				'catId': ''
			}
			this.selectedTagClassSpecId = '';
			// this.alertService.success(data.statusDescription);
			this.tagService.getCategoriesByClassification(this.token, this.selectedClassification.classId, this);
		} else if (serviceType == WsType.GET_TAG_CLASSIFICATION_ATTRIBUTE_LIST) {
			this.classAttributeList = data.payload;
			this.prepareCategoryList();
			this.createRowData();
			this.updateAvailableClassAttributeList();
		} else if (serviceType == WsType.ASSIGN_TAG_ATTRIBUTE) {
			let alert = this.translate.instant('TAG_CLASSIFICATION.ASSIGNED_ATTRIBUTE');
			this.alertService.success(alert);
			this.tagService.getCategoriesByClassification(this.token, this.selectedClassification.classId, this);
		} else if (serviceType == WsType.UPDATE_TAG_CLASSIFICATION_NAME) {
			let alert = this.translate.instant('TAG_CLASSIFICATION.UPDATE_TAG_CLASSIFICATION_NAME');
			this.alertService.success(alert);
			if(this.selectedClassification.classId == this.classificationEditItem.classId){
				this.selectedClassification.className = this.newClassificationName
				this.newClassificationName = ''
			}
			setTimeout(() => {
				this.loadingService.hideLoading();
			}, 1000);
			this.bsModalRef.hide();
			this.tagService.getTagClassifications(this.token, this);
		} else if (serviceType == WsType.GET_TAG_DEPENDENCIES_BY_TAG) {
			var res = JSON.parse(data.payload);
			if (res.HasDependencies == 0) {
				//There is no dependencies
				this.showDeleteConfirmation();
			} else {
				//There are dependencies
				this.dependenciesModalWithComponent(res.TagDependency);
			}
			console.log("dependencies");
		} else if (serviceType == WsType.DELETE_TAG_CLASSIFICATION) {
			console.log('item deleted!')
			let alert = this.translate.instant('TAG_CLASSIFICATION.DELETE_TAG_CLASSIFICATION');
			this.alertService.success(alert);
			this.selectedClassification = {classDesc: '', classId: '', className: ''};
			this.tagDescription = '';
			if (this.tagClassificationList.length != 0) {
				for( var i = 0; i < this.tagClassificationList.length; i++){ 
					if ( this.tagClassificationList[i].classId === this.toBeDeletedClassId) {
						this.tagClassificationList.splice(i, 1); 
					}
				}
			}					
			this.init();
		}else if (serviceType == WsType.UPDATE_TAG_CLASS_ATTRIBUTE_TYPE) {
				let alert = this.translate.instant('TAG_CLASSIFICATION.ATTRIBUTE_TYPE_UPDATE_SUCCESS');
				this.alertService.success(alert);
		}


	}

	onFail(data: WsResponse, serviceType: WsType) {
		this.loadingService.hideLoading();
		if (serviceType == WsType.GET_TAG_CLASSIFICATION) {
			this.alertService.error(data.statusDescription);
		} else if (serviceType == WsType.UPDATE_CLASSIFICATION_DESCRIPTION) {
			this.alertService.error(data.statusDescription);
		} else if (serviceType == WsType.GET_CATEGORIES_BY_CLASSIFICATION) {
			this.alertService.error(data.statusDescription);
		} else if (serviceType == WsType.GET_ATTRIBUTES_BY_CLASSIFICATION) {
			this.alertService.error(data.statusDescription);
		} else if (serviceType == WsType.GET_TAG_ATTRIBUTE_TYPES) {
			this.alertService.error(data.statusDescription);
		} else if (serviceType == WsType.UPDATE_TAG_ATTRIBUTE_SEQUENCE) {
			this.alertService.error(data.statusDescription);
		} else if (serviceType == WsType.ADD_NEW_CLASSIFICATION) {
			if (data.statusCode == "0") {				
				this.alertService.clear();
				this.alertService.error(this.translate.instant('TAG_CLASSIFICATION.CONNECTION_LOST'), true);
			}
			else{
				this.alertService.error(data.statusDescription);
			}
		} else if (serviceType == WsType.UPDATE_TAG_ATTRIBUTE_NOTATION) {
			this.alertService.error(data.statusDescription);
		} else if (serviceType == WsType.GET_TAG_CLASSIFICATION_ATTRIBUTE_LIST) {
			this.alertService.error(data.statusDescription);
		} else if (serviceType == WsType.ASSIGN_TAG_ATTRIBUTE) {
			this.alertService.error(data.statusDescription);
		} else if (serviceType == WsType.UPDATE_TAG_CLASSIFICATION_NAME) {
			this.alertService.error(data.statusDescription);
		} else if (serviceType == WsType.GET_TAG_DEPENDENCIES_BY_TAG) {
			this.alertService.error(data.statusDescription);
		} else if (serviceType == WsType.DELETE_TAG_CLASSIFICATION) {
			this.alertService.error(data.statusDescription);
		}else if (serviceType == WsType.UPDATE_TAG_CLASS_ATTRIBUTE_TYPE) {
			this.alertService.error(data.statusDescription);
			this.init();
		}
	}


	
	/**
	 * AG-GRID
	 */
	private prepareAgGrid() {
		this.columnDefs = [
			{	//column for drag, change sequence
				headerName: '',
				field: 'drag',
				minWidth: 50,
				maxWidth: 50,
				autoHeight: true,
				rowDrag: true
				
			},
			
			{
				headerName: 'Attribute',
				autoHeight: true,
				field: "attributeName",
				singleClickEdit: true,
				editable: function (params) {
					if (params.data.newItem) {
						return true;
					} else {
						return false;
					}
				},
				cellEditor: 'agRichSelectCellEditor',
				cellEditorParams: function (params) {
					var attributeValues: any[] = [];
					params.data.classAttributeList.forEach(tag => {
						attributeValues[attributeValues.length] = tag.tagAttributeName;
					});
					return { values: attributeValues }
				},
				resizable: true,
				cellClass: "cell-wrap-text", 
				cellStyle: { 'text-align': 'left', "white-space": "normal" }
			},
			{
				headerName: 'Attribute Type',
				maxWidth: 150,
				autoHeight: true,
				field: 'selectedAttributeType',
				singleClickEdit: true,
				//Trello Card - https://trello.com/c/zqcHzIlF
				/*editable: function (params) {
					return params.data.editable;
				},*/
				cellClass: "cell-wrap-text", 
				cellStyle: {"white-space": "normal"},
				resizable: true,
				cellEditor: 'agRichSelectCellEditor',
				/*cellEditorParams: {
					values: this.tagAttributeTypeList
				},*/
				
			},
			{
				headerName: 'Dot Notation',
				field: 'categoryPath',
				// cellClass: 'vAlign',
				cellClass: "cell-wrap-text", 
				cellStyle: {"white-space": "normal"},
				autoHeight: true,
				editable: function (params){
					if (params.data.selectedAttributeType == "Component") {
						return true
					} else {
						return false
					}
				},
				resizable: true,
				cellEditor: 'agPopupSelectCellEditor', 
				// cellRenderer: 'dotCellRenderer',   
				cellEditorParams: function (params) {
					/*if (params.data.selectedAttributeType === "Component") {
						return { values: params.data.dotNotation  }
					} else {
						return { values: [] }
					}*/
				},
				singleClickEdit: true,
				tooltip: function (params: any) {
					return params.value
				}
			},
			{
				headerName: 'Required',
				minWidth: 50,
				maxWidth: 100,
				autoHeight: true,
				resizable: true,
				// editable: true,
				field: "required",
				cellClass: "cell-wrap-text", 
				cellStyle: { 'text-align': 'center', "white-space": "normal" },
				cellRendererFramework: ClassificationCellRenderComponent
			},
			{
				headerName: 'Delete',
				minWidth: 50,
				maxWidth: 100,
				autoHeight: true,
				resizable: true,
				// editable: true,
				field: "tagClassSpecId",
				cellStyle: { 'text-align': 'center', "white-space": "normal"},
				cellClass: "cell-wrap-text", 
				cellRendererFramework: ClassificationCellRenderComponent
			}
			
		];
		this.overlayNoRowsTemplate = "<span>There are no attributes.</span>";
		this.gridApi.sizeColumnsToFit();
	}



	// Set items dynamically
	public prepareCategoryList() {
		this.dotNotations = [];
		//binding "--Select Category--" to dotNotations
		this.dotNotations[0] = this.constSelectCategory;
		console.log("prepare category list")
		this.categoryList.forEach(category => {
			category.categoryPath = category.categoryPath.replace("All > ", "")
			category.categoryPath = category.categoryPath.split(">").join("/"); 
			if(category.status != "Inactive"){
				this.dotNotations[this.dotNotations.length] = category.categoryPath;
			}
		});
	}

	public lookupValue(mappings: any[], key: any): any {
		return mappings[key];
	}

	/**
	 * Initialize row data.
	 */
	private createRowData() {
		var rowData: any[] = [];
		console.log("createRowData()")
		this.attributeList.forEach(attribute => {
			let dotNotationCategoryPath = this.setAttributeCategoryType(attribute.categoryId, attribute.attributeType)
			rowData.push({
				attributeId: attribute.attributeId,
				tagClasSpecId: attribute.tagClsSpecId,
				required: attribute.required,
				sequence: attribute.sequence,
				attributeName: attribute.attributeName,
				selectedAttributeType: attribute.attributeType,
				attributeId_: attribute.attributeId,
				editable: attribute.dependencyCount > 0 ? false : true,
				categoryId: attribute.categoryId,
				categoryList: this.categoryList,
				dotNotation: this.dotNotations,
				newItem: false,
				categoryPath: dotNotationCategoryPath
			});
		});
		this.rowData = rowData;
		this.prepareAgGrid();
	}

	setAttributeCategoryType(categoryId, attributeType) {
		if (this.categoryList.length != 0){
			if (attributeType == 'Component') {
					if(categoryId == "0"){
						return this.constSelectCategory
					}
					for(let i =0 ; i<this.categoryList.length; i++){
						if (this.categoryList[i].categoryId == categoryId) {
							return this.categoryList[i].categoryPath;
						}
					}	
					return this.constSelectCategory
				}
		} else {
			return ""
		}
	}


	attributeTypeRenderer(params) {
		return params.value.name.selectedAttributeType;
	}

	onGridReady(params: any) {
		console.log("ongrid ready")
		this.gridApi = params.api
		this.gridColumnApi = params.columnApi;
		this.gridApi.hideOverlay()
	}

	onColumnResized(event) {
		if (event.finished) {
		this.gridApi.resetRowHeights();
		}
	}

	/**
	 * after load data set columns width to fit
	 */
	onRowDataChanged(event){
		this.gridApi.sizeColumnsToFit();
	}

	/**
	 * cell click event to capture the dot notation 
	 */
	onCellClick(event:any, template: TemplateRef<any>) {
		var colId = event.column.getId();
		console.log("event.column.getId()= "+colId)
		var selectedAttributeType = event.data.selectedAttributeType;
		if(colId == 'categoryPath' && selectedAttributeType == 'Component'){
			this.categoryType = "comp_category";
			this.selectedTagClassSpecId = event.data.tagClasSpecId;
			this.selectedCategoryItem.catId = event.data.categoryId;
			if (this.selectedCategoryItem.catId != null && this.selectedCategoryItem.catId != '') {
				this.tempCategoryItem = this.selectedCategoryItem
				this.selectedCategoryItem = {
					'catName': '',
					'catId': ''
				}
			} else {
				this.tempCategoryItem = {
					'catName': '',
					'catId': ''
				}
			}
			this.bsModalRef = this.modalService.show(template, { class: 'modal-lg', backdrop: 'static', keyboard : false })
		}
	}

	 /**
     * close button click event on modal
     * hide model
     */
    closeButtonClickEvent() {
		this.tagService.getCategoriesByClassification(this.token, this.selectedClassification.classId, this);
        this.bsModalRef.hide()
	}
	
	 /**
    * select category output event
    * @param params 
    */
   selectedCategory(params: any) {
	console.log('[selectedCategory] (params) ' + JSON.stringify(params));
	this.selectedCategoryItem = params
	//params.node.setDataValue("categoryPath", this.constSelectCategory);
	const data = { categoryId: this.selectedCategoryItem.catId, tagClsSpecId: this.selectedTagClassSpecId, userToken: this.token };
	this.tagService.updateTagAttributeDotNotation(data, this);
	this.bsModalRef.hide()
}

	/**
	 * update Attribute Type and dot notation
	 * @param params 
	 */
	onCellValueChanged(params) {
		if(params.oldValue == params.newValue){
			return false
		}
		if(params.data.selectedAttributeType ==  "Component"){
			this.gridApi.sizeColumnsToFit();
		}
		var colId = params.column.getId();
		console.log("params.column.getId()= "+colId)
		if (colId == "selectedAttributeType") {
			if(params.data.selectedAttributeType == "Component"){
				params.node.setDataValue("categoryPath", this.constSelectCategory);
				this.attributeTypeUpdate = true;
				const data = { categoryId:"0", tagClsSpecId: params.data.tagClasSpecId, userToken: this.token };
				this.tagService.updateTagAttributeDotNotation(data, this);
			}else{
				params.node.setDataValue("categoryPath", null);
				const data = {type: params.data.selectedAttributeType, tagClsSpecId: params.data.tagClasSpecId, userToken: this.token };
				this.tagService.updateTagClassAttributeType(data, this);
			}
		}else if( colId == 'categoryPath'){
			if(params.data.categoryPath == this.constSelectCategory){
				params.node.setDataValue("categoryPath", this.constSelectCategory);
				const data = { categoryId:"0", tagClsSpecId: params.data.tagClasSpecId, userToken: this.token };
				this.tagService.updateTagAttributeDotNotation(data, this);
			}
			else{
				const catPath = params.data.categoryPath;
				const list = params.data.categoryList;
				list.forEach(item => {
					if (item.categoryPath === catPath) {
						this.attributeList.forEach(attribute => {
							if (attribute != undefined) {
								if (attribute.attributeId == params.data.attributeId) {
									const data = { categoryId: item.categoryId, tagClsSpecId: attribute.tagClsSpecId, userToken: this.token };
									this.tagService.updateTagAttributeDotNotation(data, this);
								};
							}
						});
						// const data = { categoryId: item.categoryId, attributeId: params.data.attributeId, userToken: this.token };
						// this.tagService.updateTagAttributeDotNotation(data, this);
					}
				});
			}
		}
	}

	public onRowDragEnd(e: any) {
		// e.overIndex = moved position in the new list (move 4th item to 0th position).
		// -1 can be returned for invalid movements.
		if (e.overIndex == -1) {
			// valid movement
			return;
		}
		let moveToIndex = e.overIndex;
		let moveFromIndex = 0;
		let overNode = e.overNode;
		this.attributeList.forEach(attribute => {
			if (attribute != undefined) {
				if (attribute.attributeId == overNode.data.attributeId) {
					moveFromIndex = attribute.sequence - 1;
				};
			}
		});
		if (moveFromIndex === moveToIndex) {
			console.log('There is no change');
			return;
		}
		//move items
		this.moveAttribute(moveFromIndex, moveToIndex);
		//reset sequences
		let orderList = this.updateAttributeSequences();
		//prepare the json data
		var data = { userToken: this.token, orders: orderList };
		// call update service
		this.tagService.updateTagAttributeSequence(data, this);
	}

	/**
	 * Move attribute along the attribute array list. This is achieved by splitting the array into three parts
	 * based on moving position and replace position. Then merge them all together.
	 * [first_part][from/to][middle_part][to/from][last_part]
	 *
	 * @param moveFromIndex index of the item that we want to move
	 * @param moveToIndex index of the place where the item should be moved
	 */
	private moveAttribute(moveFromIndex: number, moveToIndex: number) {
		// list will be divided into three parts
		if (moveFromIndex > moveToIndex) {
			var firstPart = this.attributeList.slice(0, moveToIndex);
			var middlePart = this.attributeList.slice(moveToIndex + 1, moveFromIndex);
			var lastPart = this.attributeList.slice(moveFromIndex + 1);
			var movingItem = this.attributeList[moveFromIndex];
			var replacedItem = this.attributeList[moveToIndex];
			// concatenate all items to create the final one.
			this.attributeList = [...firstPart, movingItem, replacedItem, ...middlePart, ...lastPart];
		} else {
			var firstPart = this.attributeList.slice(0, moveFromIndex);
			var middlePart = this.attributeList.slice(moveFromIndex + 1, moveToIndex);
			var lastPart = this.attributeList.slice(moveToIndex + 1);
			var movingItem = this.attributeList[moveFromIndex];
			var replacedItem = this.attributeList[moveToIndex];
			// concatenate all items to create the final one.
			this.attributeList = [...firstPart, ...middlePart, replacedItem, movingItem, ...lastPart];
		}
	}

	/**
	 * Set the sequence value.
	 */
	private updateAttributeSequences(): any[] {
		let index = 1;
		let orderList = [];
		this.attributeList.forEach(attribute => {
			if (attribute != undefined) {
				attribute.sequence = index;
				orderList.push({ tagClassSpecId: attribute.tagClsSpecId, order: index });
				index++;
			}
		});

		return orderList;
	}

	addClassificationButtonClick(template: TemplateRef<any>) {
		console.log("Add Tag Classification button clicked...");
		this.newClassificationDescription = "";
		this.newClassificationName = "";
		this.bsModalRef = this.modalService.show(template, { class: 'modal-lg', backdrop: 'static', keyboard : false });
	}

	submitButtonClick(): void {
		this.newClassificationDescription = this.newClassificationDescription.trim()
		this.newClassificationName = this.newClassificationName.trim()
		if (this.newClassificationDescription.includes("\\")) {
			this.newClassificationDescription  = this.newClassificationDescription.replace(/\\/g, "\\\\");
		}
		if (this.newClassificationName.includes("\\")) {
			this.newClassificationName  = this.newClassificationName.replace(/\\/g, "\\\\");
		}
		if (this.newClassificationDescription.length === 0 || this.newClassificationName.length === 0) {
			return;
		}	

		var data = { userToken: this.token, name: this.newClassificationName, description: this.newClassificationDescription };
		this.tagService.addNewClassification(data, this);
	}

	/**
	 * Click on the add attribute button.
	 * @param item 
	 */
	addAttribute(item: any) {
		if (this.classAttributeList == undefined || this.classAttributeList.length == 0) {
			this.alertService.warn('Please select a classification');
			return;
		}
		//Check whether user has already added an item without completing it.
		let isAvailable = false;
		this.attributeList.forEach(item => {
			if (item.newItem) {
				isAvailable = true;
				return;
			}
		});
		if (isAvailable) {
			this.alertService.warn('Please complete the already added item before add another one', false);
			return;
		}

		var defaultItem2 = {
			attributeId: "000",
			attributeName: item.tagAttributeName,
			attributeType: item.dfType,
			selectedAttributeType: item.dfType,
			categoryId: null,
			checkedoutTopic: 0,
			classDesc: null,
			classId: null,
			className: null,
			dependencyCount: 0,
			description: null,
			lastTagAttrValue: null,
			lastUpdateTimeStamp: null,
			lastUpdateTimeStampString: null,
			projectId: null,
			required: "yes",
			sequence: this.attributeList.length + 1 + "",
			tagClassSpecModelList: null,
			tagClsSpecId: null,
			tagId: null,
			tagSpecId: null,
			value: null,
		};

		this.attributeList.push(defaultItem2);
		this.gridApi.applyTransaction({add: [defaultItem2], addIndex: this.attributeList.length});
		this.updateAvailableClassAttributeList();
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
		var data = { userToken: token, addAttrId: item.id, classId: this.selectedClassification.classId };
		this.tagService.assignTagAttribute(data, this);
	}

	manageAttributeClick() {
		this.router.navigateByUrl('manageAttributes');
	}

	/**
	 * Delete newly added item.
	 */
	public deleteNewItem() {
		console.log('deleted');
		this.tagService.getCategoriesByClassification(this.token, this.selectedClassification.classId, this);
	}

	/**
	 * Change the required status.
	 */
	public setRequiredField() {
		if (this.attributeList[this.attributeList.length - 1].required === 'yes') {
			this.attributeList[this.attributeList.length - 1].required = 'no';
		} else {
			this.attributeList[this.attributeList.length - 1].required = 'yes';
		}
	}

	private initEditableStatusArrayForEditButtons(count: number) {
		for (let index = 0; index < count; index++) {
		}
	}
	/**
	 * Rename button click event.
	 * @param tagClassification tag classification model
	 */
	public renameButtonClick(tagClassification: any, index: number, template: TemplateRef<any>) {
		console.log(tagClassification);
		if(this.oldClassName != null){
			this.alertService.error( "Editor is already open "+ "\""+ this.oldClassName + "\"");
			return false
		}
		this.oldClassName  = this.tagClassificationList[index].className
		this.classificationEditItem = tagClassification;
		this.newClassificationName = tagClassification.className;
		this.bsModalRef = this.modalService.show(template, { class: 'modal-lg', backdrop: 'static', keyboard: false });		
	}

	/**
	 * Delete button click event.
	 * @param tagClassification tag classification model
	 */
	public deleteButtonClick(tagClassification: any, index: number) {
		this.toBeDeletedClassId = this.filter(this.tagClassificationList)[index].classId;
		var data = { itemId: this.toBeDeletedClassId, itemType: "TagClassification", action: "edit", userToken: this.token };
		this.tagService.getTagDependenciesByTag(JSON.stringify(data), this);
	}

	/**
	 * Enter key event
	 * @param event event model
	 * @param index index of the tag classification edit location
	 */
	public editSubmitButtonClick(): void {	
		if (this.newClassificationName != undefined && this.newClassificationName != '') {
			this.newClassificationName = this.newClassificationName.trim()
			if(this.newClassificationName == ''){
				return
			}
			this.oldClassName = null
			this.loadingService.showLoading(true, false, 'Saving', 0);
			if (this.newClassificationName.includes("\\")) {
				this.newClassificationNameData  = this.newClassificationName.replace(/\\/g, "\\\\");
			} else {		
			this.newClassificationNameData  = this.newClassificationName;
			}
		} else {
			this.classificationEditItem.className = this.oldClassName
			this.oldClassName = null
			return
		}
		
		var id = this.classificationEditItem.classId;		
		var data = { userToken: this.token, classId: id, className: this.newClassificationNameData };
		this.tagService.updateTagClassificationName(data, this);			
	}

	editCancelButtonClick() {
		this.newClassificationName = ''
		this.oldClassName = null
		this.bsModalRef.hide();
	}
	/**
	 *edit close button event
	 * @param {*} tagClassification
	 * @param {number} index
	 * @memberof TagClassificationComponent
	 */
	public editCloseButtonClick(index: number){
		this.tagClassificationList[index].className = this.oldClassName
		this.oldClassName = null
	}

	/**
   * Shows delete confirmation popup
   */
	showDeleteConfirmation() {
		let title = this.translate.instant('TAG_CLASSIFICATION.DELETE_CONFIRM');
		let message =	 this.translate.instant('TAG_CLASSIFICATION.CLASS_DELETE_CONFIRM_MESSAGE');
		const initialState = {
			token: this.token,
			attributeId: '',
			confirmationTitle: title,
			confirmationMessage: message,
			callBack: this
		};
		this.bsModalRef = this.modalService.show(ConfirmationModalComponent, { initialState });
	}

	/**
	* User clicks on the delete button in the deletion confirmation popup
	*/
	onDeleteConfirm() {
		console.log('please delete me');
		var data: any = {
			"classId": this.toBeDeletedClassId,
			"userToken": this.token
		};
		this.tagService.deleteTagClassification(data, this);
		this.bsModalRef.hide();
	}

	/**
	 * User clicks on the decline button in the deletion confirmation popup
	 */
	onDeleteDecline() {
		this.bsModalRef.hide()
	}

	/**
	 * Shows dependency list modal.
	 * @param tagList dependency list
	 */
	dependenciesModalWithComponent(tagList: any[]) {
		const initialState = {
			tagDependency: tagList,
			title: 'Delete Conflicts'
		};
		this.bsModalRef = this.modalService.show(DependencyModalComponent, { initialState });
		this.bsModalRef.content.closeBtnName = 'Close';
	}
}
