export class TagAttribute{
    constructor(
        public attributeId: string = null,
        public attributeName: string = null,
        public attributeType: string = null,
        public tagClassSpecId: string = null,
        public classId: string = null,
        public tagId: string = null,
        public value: any = null,
        public oldValue: any = null,
        public oldTagClassSpecId: any = null,
        public required: string = null,
        public dependencyCount: number = 0,
        public isMapped: boolean = false
    ){}
}

export class TagAttributesMapping{
    constructor(
        public oldAttributeId: string = null,
        public oldAttributeName: string = null,
        public oldAttributeType: string = null,
        public oldTagClassSpecId: string = null,
        public oldValue: any,
        public newAttributeId: string = null,
        public newAttributeName: string = null,
        public newAttributeType: string = null,
        public newTagClassSpecId: string = null,
        public newValue: any,
    ){}
}