<div class="container-fluid px-0">
    <div class="row px-3">
        <div class="col-sm-8 px-0">
            <p class="mb-2 h5">
                <b>{{ 'SYSTEM_SEARCH_VIEW.HEADER' | translate }}</b>
            </p>
        </div>
        <div class="col-sm-4 px-0">
            <button *ngIf="changeLayout" type="submit" class="btn btn-primary btn-sm float-right reusable_btn my-1"
            [disabled]="!enableAddSystem" (click)="add()">
                <i class="fas fa-plus"></i>
            </button>
        </div>
    </div>
    <div class="col-sm-12 px-0">
    <div class="input-with-icon" *ngIf="!enableSearchSystem">
        <input #symbiosis_search class="form-control" name="system_search" placeholder="Search" style="padding-right: 60px;" 
        [disabled]="!enableSearchSystem"/>
    </div>
    <div class="col-sm-12 px-0" *ngIf="enableSearchSystem">
        <symbiosis-search [comp]="'system'" id="system_search" (searchFunc)="searchFunc($event)" (searchBarEnterFunc)="searchBarEnterFunc($event)"></symbiosis-search>
    </div>
    </div>
    <hr class="my-2" />
</div>

<div *ngIf="expandedView == suv.EXPANDED_VIEW">
    <ag-grid-angular #agGrid style="width: 100%; height: 375px;" class="ag-theme-balham" [rowData]="rowData" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }" [columnDefs]="columnDefs"
        [gridOptions]="gridOptions" [pagination]="true" [paginationPageSize]="10"
        [overlayLoadingTemplate]="overlayLoadingTemplate" [overlayNoRowsTemplate]="overlayNoRowsTemplate"
        [getRowNodeId]="getRowNodeId" (gridReady)="onGridReady($event)">
    </ag-grid-angular>
</div>

<div *ngIf="expandedView != suv.EXPANDED_VIEW">
    <ag-grid-angular #agGrid style="width: 100%; height: 375px;" class="ag-theme-balham" [rowData]="rowData" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }" [columnDefs]="columnDefs"
        (cellClicked)="cellClicked($event)" [gridOptions]="gridOptions" [pagination]="true" [paginationPageSize]="10"
        [overlayLoadingTemplate]="overlayLoadingTemplate" [overlayNoRowsTemplate]="overlayNoRowsTemplate"
        [getRowNodeId]="getRowNodeId" (gridReady)="onGridReady($event)">
    </ag-grid-angular>
</div>


