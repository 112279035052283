import { Component, ɵConsole } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams, IAfterGuiAttachedParams } from "@ag-grid-enterprise/all-modules";

@Component({
  selector: 'app-button-renderer',
  template: `
    <button type="button" (click)="onClick()">{{label}}</button>
    `
})

export class ButtonRendererComponent implements ICellRendererAngularComp {

    constructor(){}

    ngOnInit(){
      console.log("====ButtonRendererComponent=======ngOnInit=====")
    }

    params;
    label: string;

    
    agInit(params): void {
    this.params = params;
    this.label = this.params.label || null;
  }

  afterGuiAttached(params?: IAfterGuiAttachedParams) : void {}

  refresh(params?: any): boolean {
    return true;
  }

  onClick() {
      window.open('/commissioning');
  }
}