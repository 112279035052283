<!-- <alert class="floating-alert"></alert>

<nav id="navPanel" class="bg-light border-bottom box-shadow fixed-top navbar-expand-lg">
	<app-header [minimalOption]="true"></app-header>
</nav>

<div class="main-panel" [style.padding-top.px]="60" style="overflow: hidden;">
	<div class="form-inline my-2 my-lg-0 float-center">
		<div class="my-2 my-sm-0 mr-1 ml-2">
			<button type="button" [disabled]="!addProcedureToStore" id="ps_addtostore" (click)="addProcedureStore()"
				class="btn btn-outline-primary btn-sm ml-1 mr-1">
				<i class="fa fa-plus-square" aria-hidden="true"></i> Add Procedure
			</button>
		</div>
		<div class="my-2 my-sm-0 mr-1 ml-2">
			<button type="button" [disabled]="!deleteProcedureFromStore" id="ps_deleteProcedurefromStore"
				(click)="deletedProcedureView()" class="btn btn-outline-primary btn-sm ml-1 mr-1">
				<i class="fa fa-trash" aria-hidden="true"></i> Deleted Procedures
			</button>
		</div>
		<div class="my-2 my-sm-0 mr-1 ml-2">
			<button type="button" [disabled]="!viewProcedureInstanceOutputList" id="ps_viewProcedureInstance"
				(click)="viewProcedureInstance()" class="btn btn-outline-primary btn-sm ml-1 mr-1">
				<i class="fa fa-table" aria-hidden="true"></i> Procedure Instances
			</button>
		</div>
	</div>

	<div class="card-block card-body mt-2 pb-1 pt-1 card-primary"
		[ngClass]="{'content-hide': isMinimized, 'content-show': !isMinimized}">
		<div class="form-group col">
			<label>{{ 'PROCEDURE_STORE.FACILITY' | translate }} :</label>
			<ng-select [disabled]="!facilityLoaded" [active]="selectedFacility" [allowClear]="true"
				(removed)="removedFacility($event)" (selected)="selectedFacilityId($event)" [items]="facilityList"
				name="Facility" id="facility">
			</ng-select>
		</div>
		<ag-grid-angular #agGrid style="width: 100%; height: 400px;" id="procedureStore" [rowData]="rowData"  [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }"
			class="ag-theme-balham" [columnDefs]="columnDefs" [frameworkComponents]="frameworkComponents"
			  [enableRangeSelection]="true" [paginationPageSize]="10"
			[pagination]="true" (columnResized)="onColumnResized($event)" (gridReady)="onGridReady($event)"
			[suppressMenuHide]="true" [gridOptions]="gridOptions"></ag-grid-angular>
	</div>
</div> -->

<div class="main-panel pt-0 pb-4" style="overflow: hidden;">
	<div class="container-fluid">
		<div class="col-sm-12 px-0">
			<h3>
				{{ 'PROCEDURE_STORE.PS_SIDEBAR_PROCEDURE_STORE' | translate }}
			</h3>
			<hr class="mt-0 mb-3" />
		</div>
		<div class="col-sm-12 px-0">
			<form>
				<div class="form-group">
					<label class="mb-0">{{ 'PROCEDURE_STORE.FACILITY' | translate }} :</label>
					<ng-select  [active]="selectedFacility" [allowClear]="true"
						(removed)="removedFacility($event)" (selected)="selectedFacilityId($event)"
						[items]="facilityList" name="Facility" id="facility">
					</ng-select>
				</div>
			</form>
		</div>
		<hr class="mt-0 mb-3" />
		<div class="col-sm-12 px-0">
			<ag-grid-angular #agGrid style="width: 100%; height: 400px;" id="procedureStore" [rowData]="rowData" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }"
				class="ag-theme-balham" [columnDefs]="columnDefs" [frameworkComponents]="frameworkComponents"
				  [enableRangeSelection]="true" [paginationPageSize]="10" [defaultColDef]="defaultColDef"
				[pagination]="true" (columnResized)="onColumnResized($event)" (gridReady)="onGridReady($event)"
				[suppressMenuHide]="true" [gridOptions]="gridOptions"></ag-grid-angular>
		</div>
	</div>
</div>
