<div class="modal-header">
	<!--Title-->
	<h4 class="modal-title pull-left">{{title}}</h4>
	<button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>

<!--Body-->
<div class="modal-body">
	<form ngNativeValidate id="newProcedure_form">
		<!--Procedure Name-->
		<div class="form-group">
			<label for="newProcedure_procedureName">{{ 'NEW_PROCEDURE.PROCEDURE_NAME.LABEL' | translate }}<div
					class="badge-required mx-2"></div></label>
			<input type="text" [(ngModel)]="procedureName" name="procedureName" class="form-control"
				id="procedureNameId" placeholder="{{ 'NEW_PROCEDURE.PROCEDURE_NAME.PLACE_HOLDER' | translate }}"
				required>
		</div>

		<!--Procedure Type-->
		<div class="form-group">
			<label for="newProcedure_procedureType">{{ 'NEW_PROCEDURE.PROCEDURE_TYPE.LABEL' | translate }}<div
					class="badge-required mx-2"></div></label>
			<input type="text" [(ngModel)]="procedureType" name="procedureType" class="form-control"  autocomplete="off"
				id="procedureTypeId" placeholder="{{ 'NEW_PROCEDURE.PROCEDURE_TYPE.PLACE_HOLDER' | translate }}"
				(focus)="onProcedureTypeFocus()" required>
		</div>

		<!--System-->
		<div class="form-group">
			<label for="newProcedure_system">{{ 'NEW_PROCEDURE.PROCEDURE_SYSTEM.LABEL' | translate }}<div
					class="badge-required mx-2"></div></label>
			<div class="ml-0">
				<app-custom-typeahead [initial_value]="" (text_changed)="onSelectSystemItem($event)"
					[typeahead_list]="systemList" [scrollable]="true" [scrollable_options_view]="10" [options_limit]="systemsLength" [option_field]="'fullname'" [place_holder]="'select system'"
					[requiredField]='false' id="systemId"></app-custom-typeahead>
			</div>
		</div>

		<!--Output config-->
		<div class="form-group">
			<label for="newProcedure_output_config">{{ 'NEW_PROCEDURE.PROCEDURE_OUT_CONFIG.LABEL' | translate }}</label>
			<div class="ml-0">
				<app-custom-typeahead [initial_value]="" (text_changed)="onSelectConfigItem($event)"
					[typeahead_list]="outputConfigList" [option_field]="'name'" [place_holder]="'select config'"
					[requiredField]='false' id="outputConfigId"></app-custom-typeahead>
			</div>
		</div>

		<!--Attribute List-->
		<div class="form-group">
			<label for="exampleInputPassword1">{{ 'NEW_TOPIC.ATTRIBUTE.NAME' | translate }}</label>
			<div class="alert alert-info" role="alert" *ngIf="!haveProperties">
				No attributes for the selected file
			</div>
			<!--Attribute Table-->
			<div *ngIf="attributeList != undefined">
				<div *ngFor="let attibute of attributeList">
					<div *ngIf="attibute.attributeType != 'null'">  
					<br>
					<table>
						<th></th>
						<th></th>
						<tr>
							<td width="100">
								<label>{{attibute.attributeName}}
									<sup>
										<i *ngIf="attibute.required=='yes'" class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i>
									</sup>
								</label>
							</td>
							<td width="300" >
								<input type="text"  *ngIf="(attibute.attributeType=='Number' || attibute.attributeType=='Text') && (attibute.attributeName != 'Document' && attibute.attributeName != 'c1' && attibute.attributeName != 'System')" 
                #texts name ="attribute" autocomplete="off" [(ngModel)]="attibute.value" style="width:100%" >
								<input type="date"  *ngIf="attibute.attributeType == 'Date'" name ="attribute" [(ngModel)]="attibute.value" style="width:100%">
								<div *ngIf= "checkDocument(attibute.attributeType,attibute.attributeName)">
								<select #options name ="attribute" [(ngModel)]="attibute.value" style="width:100%" >
									<option *ngFor="let item of attibute.documents" [ngValue]="item.id">
										{{item.documentName}}
									</option>
								</select>
								</div>
								<div *ngIf= "checkComponent(attibute.attributeType,attibute.attributeName)">
									<select  #options name ="attribute" [(ngModel)]="attibute.value" style="width:100%" >
										<option *ngFor="let item of attibute.components" [ngValue]="item.id">
											{{item.componentName}}
										</option>
									</select>
									</div>
									<div *ngIf= "checkSystem(attibute.attributeType,attibute.attributeName)">
										<select  #options name ="attribute" [(ngModel)]="attibute.value" style="width:100%" >
											<option *ngFor="let item of attibute.systems" [ngValue]="item.systemId">
												{{item.systemName}}
											</option>
										</select>
										</div>
							</td>
						</tr>
					</table>
					</div>
				</div>
			</div>

		</div>
		<!--Submit Button-->
		<div class="form-group row float-right mr-0">
			<button id="submit" type="submit" class="btn btn-primary"
				(click)="onSubmitButtonClick()">{{ 'SUBMIT' | translate }}</button>
		</div>
	</form>
</div>

<!--Close button-->
<!--<div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">{{closeBtnName}}</button>
  </div>-->