import { Component, OnInit } from '@angular/core';
import {ProjectService} from './project.service';
import { TranslateService } from '@ngx-translate/core';
import { GridOptions, ColDef, Module, AllModules } from "@ag-grid-enterprise/all-modules";
import { ProjectCellRenderComponent } from './project-cell-render-component.component';
import { SharedService } from '../util/shared.service';
import { Router } from '@angular/router';
import { CapabilityService } from '../util/capability.service';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css']
})
export class ProjectComponent implements OnInit {

	private projectName: string = "";
	private clientShortName: string = "";
	private status: string = "";
    private edit: string = "";
	private delete: string = "";
	private gridApi;
    public getRowNodeId;
    private gridColumnApi
    public gridOptions: GridOptions;
    public defaultColDef: any;
    public columnDefs: any;
    rowData: any = [];
    public modules: Module[] = AllModules;
    private filteredData: Array<any> = [];
    public overlayLoadingTemplate: any;
    public overlayNoRowsTemplate: any;

    // capabilities
    public deleteProject: boolean = false
	public disableProject: boolean = false
	public modifyProject: boolean = false
    
    // enable/disable buttons
    public enableDeleteProject: boolean = false
	public enableChangeProjectStatus: boolean = false
	public enableEditProject: boolean = false
	
  constructor(private projectService: ProjectService, private sharedService: SharedService, private router: Router, private translate: TranslateService, private capabilityService: CapabilityService) {
    this.enableDisableButton()
	translate.addLangs(["es", "en"]);
        translate.setDefaultLang("en");
        let browserLang = translate.getBrowserLang();
		translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
		this.translate.get('PROJECT.PROJECT_NAME').subscribe((res: string) => {
            this.projectName = res;
            this.translate.get('PROJECT.SHORT_NAME').subscribe((res: string) => {
                this.clientShortName = res;
                this.translate.get('PROJECT.STATUS').subscribe((res: string) => {
                    this.status = res;
                    this.translate.get('PROJECT.EDIT').subscribe((res: string) => {
                        this.edit = res;
                        this.translate.get('PROJECT.DELETE').subscribe((res: string) => {
                            this.delete = res;
                        });
                    });
                });
            });
        });
		this.columnDefs = [
			{
                headerName: '',
                field: "dependencies",
                width: 40,
                cellStyle: { 'text-align': 'center' },
                cellRendererFramework: ProjectCellRenderComponent
            },
            {
                headerName: this.projectName,
                field: "projectName",
                checkboxSelection: false,
            },
            {
                headerName: this.clientShortName,
                field: "clientShortName",
                checkboxSelection: false,
                suppressColumnsToolPanel: true
            },
            {
                headerName: this.status,
                field: "status",
                width: 85,
                cellStyle: { 'text-align': 'center' },
                hide: !this.enableChangeProjectStatus,
                cellRendererFramework: ProjectCellRenderComponent
            },
            {
                headerName: this.edit,
                field: "edit",
                width: 85,
                cellStyle: { 'text-align': 'center' },
                hide: !this.enableEditProject,
                cellRendererFramework: ProjectCellRenderComponent
            },
            {
                headerName: this.delete,
                field: "delete",
                width: 85,
                cellStyle: { 'text-align': 'center' },
                hide: !this.enableDeleteProject,
                cellRendererFramework: ProjectCellRenderComponent
            }
		];
		
		this.getRowNodeId = function (data) {
            return data.id;
		};
		
        this.gridOptions = {
            rowData: this.rowData,
            columnDefs: this.columnDefs,
            rowSelection: "single",
            rowMultiSelectWithClick: false,
            isExternalFilterPresent: this.externalFilterPresent,
            doesExternalFilterPass: this.externalFilterPass,

        };
        this.defaultColDef = {
			resizable: true,
			sortable: true,
		};
        //custom layout templates for loading and empty data sets
        this.overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Loading data...</span>';
        this.overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">No rows to show</span>';
	}
	
  ngOnInit() {

	this.projectService.viewProjectList().
	subscribe(data => {

		this.loadData(data.payload);
	},
	error => {

	});

  }


  loadData(data: any) {
	this.rowData = [];
	for (let i = 0; i < data.length; i++) {

		this.rowData.push({
			id: data[i]["id"],
			dependencies: data[i]["hasDependencies"] == "false" ? "disable_dependencies:" + data[i]["id"] : "dependencies:" + data[i]["id"],
			projectName: data[i]["projectName"],
			clientShortName: data[i]["clientName"],
			status: "status:" + data[i]["id"] + ":" + data[i]["status"],
			edit: "edit:" + data[i]["id"] + ":" + data[i]["status"],
			delete: "delete:" + data[i]["id"] + ":" + data[i]["status"]
		})
	}
	console.log("load" + this.rowData.length)
}

add() {
	this.sharedService.setFileId("-1");
	this.router.navigateByUrl('addProject');
}

  onGridReady(params) {
	const allColumnIds = [];
	this.columnDefs.forEach((columnDef) => {
		allColumnIds.push(<ColDef>columnDef);
	});
	this.gridColumnApi = params.columnApi;
	this.gridApi = params.api;
	//gridOptions.api.hideOverlay();
	this.projectService.setApiGridData(this.gridApi);
	params.api.sizeColumnsToFit();
	window.addEventListener("resize", this.onWindowResize);
}

private onWindowResize = () => {
   
    setTimeout(() => {
        if (this.gridApi) {
            this.gridApi.sizeColumnsToFit();
        }
    }); 
};

ngOnDestroy() {
    // Remove the window resize event listener
    window.removeEventListener("resize", this.onWindowResize);
    this.gridApi.destroy();
    this.gridApi = null;
}

externalFilterPresent() {
	return this.projectName != "";
}

externalFilterPass(node: any) {
	return true;
}

/**
* enable/disable buttons or columns
*/
private enableDisableButton() {
    this.checkCapability()
    this.enableDeleteProject = this.deleteProject
    this.enableChangeProjectStatus = this.disableProject
    this.enableEditProject = this.modifyProject
}

/**
* check the capabilities for given type
*/
public checkCapability() {
    let deleteCapability: string = "deleteProject"
    let disableCapability: string = "disableProject"
    let modifyCapability: string = "modifyProject"

    this.deleteProject = this.capabilityService.isCapabilityAssigned(deleteCapability)
    this.disableProject = this.capabilityService.isCapabilityAssigned(disableCapability)
    this.modifyProject = this.capabilityService.isCapabilityAssigned(modifyCapability)   
}


}
