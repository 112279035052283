import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserVariable } from '../../util/common/user-variable';
import { ServiceUrls } from '../../util/service-urls';
import { WsCallback } from '../../util/ws-callback.interface';
import { WsType } from '../../util/ws-type';
import { WsResponse } from '../../util/ws-response.model';
import { LocalStorage } from '../../util/localstorage.service';
import { map } from 'rxjs/operators'
import { moment } from 'ngx-bootstrap/chronos/test/chain';
import { CommonUtil } from 'src/app/util/common/common-util';

@Injectable()
export class ReusableUpdateService {

   constructor(private http: HttpClient, private commonUtil: CommonUtil) {
   }

   getReusableSearchResults(reusableType: any, oldValue: any, newValue: any, statusId: any, startDate: any, endDate: any): Observable<WsResponse> {      
	let token = UserVariable.getUserToken();
	if(!this.commonUtil.validateToken(token)){
		return;
	}
	console.log("item search")
	const options = { params: new HttpParams().set("token", token).set("reusableType", reusableType).set("oldValue", oldValue).set("newValue", newValue).set("statusId", statusId)
	.set("startDate", startDate).set("endDate", endDate) }
	return this.http.get(ServiceUrls.GET_REUSABLE_SEARCH_RESULTS, options).
	   pipe(
		  map(response => {
			 var modified = JSON.parse(JSON.stringify(response));
			 return new WsResponse(
				modified.status.description,
				modified.status.code,
				modified.status.name,
				modified.payload);
		  }),
		  catchError(error => {
			 var modified = JSON.parse(JSON.stringify(error.error));
			 var res = new WsResponse(
				modified.status.description,
				modified.status.code,
				modified.status.name,
				modified.payload);
			 return throwError(res)
		  })
	   );
 }


 getReusableErrorMessage(reusableType: any, reusableId: any): Observable<WsResponse> {      
	let token = UserVariable.getUserToken();
	if(!this.commonUtil.validateToken(token)){
		return;
	}
	const options = { params: new HttpParams().set("token", token).set("reusableType", reusableType) }
	let url  = ServiceUrls.REUSABLE_UPDATES + reusableId + "/getReusableErrorMessage.json";
	console.log("item search" + reusableType + reusableId )
	return this.http.get(url, options).
	   pipe(
		  map(response => {
			 var modified = JSON.parse(JSON.stringify(response));
			 return new WsResponse(
				modified.status.description,
				modified.status.code,
				modified.status.name,
				modified.payload);
		  }),
		  catchError(error => {
			 var modified = JSON.parse(JSON.stringify(error.error));
			 var res = new WsResponse(
				modified.status.description,
				modified.status.code,
				modified.status.name,
				modified.payload);
			 return throwError(res)
		  })
	   );
 }

 getReusableUpdateData(reusableType: any, reusableId: any): Observable<WsResponse> {      
	let token = UserVariable.getUserToken();
	if(!this.commonUtil.validateToken(token)){
		return;
	}
	const options = { params: new HttpParams().set("token", token).set("reusableType", reusableType) }
	let url  = ServiceUrls.REUSABLE_UPDATES + reusableId + "/getReusableUpdateData.json";
	console.log("item search" + reusableType + reusableId )
	return this.http.get(url, options).
	   pipe(
		  map(response => {
			 var modified = JSON.parse(JSON.stringify(response));
			 return new WsResponse(
				modified.status.description,
				modified.status.code,
				modified.status.name,
				modified.payload);
		  }),
		  catchError(error => {
			 var modified = JSON.parse(JSON.stringify(error.error));
			 var res = new WsResponse(
				modified.status.description,
				modified.status.code,
				modified.status.name,
				modified.payload);
			 return throwError(res)
		  })
	   );
 }


 doRequeueReusableUpdate(failedReusableUpdateIds: any, reusableUpdateId: any, reusableId: any): Observable<WsResponse> {
	let token = UserVariable.getUserToken();
	if (!this.commonUtil.validateToken(token)) {
		return;
	}
	const options = { params: new HttpParams().set("token", token).set("reusableUpdateId", reusableUpdateId).set("failedReusableUpdateIds", failedReusableUpdateIds) }
	let url = ServiceUrls.REUSABLE_UPDATES + reusableId + "/doRequeueReusableUpdate.json";
	//console.log("item search" + reusableType + reusableId )
	return this.http.get(url, options).
		pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
		);
}

}