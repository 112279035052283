<!-- 
<nav id="navPanel" class="bg-light border-bottom box-shadow fixed-top navbar-expand-lg">
	 <app-header></app-header>
</nav> -->



<!-- <div class="main-panel" [style.padding-top.px]="40" style="overflow: hidden;"> -->
<div class="main-panel pt-0 pb-4" style="overflow: hidden;">
	<div class="row px-3">
		<div class="col-sm-10 px-0">
			<h3>{{ title }} </h3>
			<hr class="mt-0 mb-3" />
		</div>
		<div class="col-sm-2 px-0">
			<button id="button_comos_export" type="submit" class="btn btn-primary btn-sm float-right my-1 mx-1" (click)="onBtnExcel()">
			Excel
			</button>
			<button id="button_comos_export" type="submit" class="btn btn-primary btn-sm float-right my-1 mx-1" (click)="onBtCopy()">
			Copy
			</button>
		</div>
	</div>
	
	<div class="card-block card-body mt-2 pb-1 pt-1 px-0 card-primary" >
		<ag-grid-angular #agGrid style="width: 100%; " [style.height.px]="divHeight" id="reportDataTable" [rowData]="rowData" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }" class="ag-theme-balham"
				[columnDefs]="columnDefs" [defaultColDef]="defaultColDef" 
				[enableRangeSelection]="true" [paginationPageSize]="10" [pagination]="true" (cellClicked)="onCellClicked($event)" (columnResized)="onColumnResized($event)"
				(gridReady)="onGridReady($event)" [suppressMenuHide]="true" [gridOptions] ="gridOptions" [overlayLoadingTemplate]="overlayLoadingTemplate"
				[overlayNoRowsTemplate]="overlayNoRowsTemplate">
		</ag-grid-angular>
	</div>


	<div class="card-block card-body mt-2 pb-1 pt-1 px-0 card-primary" >
		<!-- <div class="col-sm-10 px-0">
			<h3> Reference Details </h3>
			<hr class="mt-0 mb-3" />
		</div> -->

	<div *ngIf="(isShowReferences && hasRefs && refClicked)" class="modal-body">	
		<div class="row px-3">
		<div class="col-sm-10 px-0">
			<h3> Reference Details </h3>
			<hr class="mt-0 mb-3" />
		</div>		
		<div class="col-sm-2 px-0">
			<button id="button_comos_export" type="submit" class="btn btn-primary btn-sm float-right my-1 mx-1" (click)="onBtnExcelRef()">
			Excel
			</button>
			<button id="button_comos_export" type="submit" class="btn btn-primary btn-sm float-right my-1 mx-1" (click)="onBtCopyRef()">
			Copy
			</button>
		</div>
		</div>
		<ag-grid-angular #agGrid style="width: 100%; height: 300px;" class="ag-theme-balham"
			[enableFilter]="true" [rowData]="rowDataRef" [columnDefs]="columnDefsRef" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }"
			[rowMultiSelectWithClick]="false" (rowClicked)="onRowClickTag($event)" [defaultColDef]="defaultColDef"
			[pagination]="true" [paginationPageSize]="10" [getRowNodeId]="getRowNodeId" (gridReady)="onReferenceGridReady($event)" [gridOptions] ="gridOptions1" 
			[overlayLoadingTemplate]="overlayLoadingTemplate" [overlayNoRowsTemplate]="overlayNoRowsTemplate">
		</ag-grid-angular>						
	</div>
	<div *ngIf="(loading && refClicked)" class="modal-body">	
			<h5><b><center>Loading</center></b></h5>
	</div>
	<div *ngIf="(isShowReferences && !hasRefs && !loading && refClicked && !tagAttributeGrid)" class="modal-body">	
			<h5><b><center>No References</center></b></h5>
	</div>



	<!-- tag attributes -->

	<div *ngIf="(isShowReferences && hasTagAttreRefs && refClicked && hasTagAttr && tagAttributeGrid)" class="modal-body">
		<div class="row px-3">
			<div class="col-sm-10 px-0">
				<h3> Tag Attribute Reference Details </h3>
				<hr class="mt-0 mb-3" />
			</div>		
			<div class="col-sm-2 px-0">
				<button id="button_comos_export" type="submit" class="btn btn-primary btn-sm float-right my-1 mx-1" (click)="onBtnExcelTagAttrRef()">
				Excel
				</button>
				<button id="button_comos_export" type="submit" class="btn btn-primary btn-sm float-right my-1 mx-1" (click)="onBtCopyTagAttrRef()">
				Copy
				</button>
			</div>
			</div>			
		<ag-grid-angular #agGrid style="width: 100%; height: 300px;" class="ag-theme-balham"
			[enableFilter]="true" [rowData]="rowDataTagAttr" [columnDefs]="columnDefsTagAttr" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }"
			[rowMultiSelectWithClick]="false" (rowClicked)="onRowClickTag($event)" [defaultColDef]="defaultColDef"
			[pagination]="true" [paginationPageSize]="10" [getRowNodeId]="getRowNodeId" (gridReady)="onTagAttrReferenceGridReady($event)" [gridOptions] ="gridOptions2" 
			[overlayLoadingTemplate]="overlayLoadingTemplate" [overlayNoRowsTemplate]="overlayNoRowsTemplate">
		</ag-grid-angular>						
	</div>
	<div *ngIf="(loadingTagAttr && refClicked && tagAttributeGrid)" class="modal-body">	
			<h5><b><center>Loading</center></b></h5>
	</div>
	<div *ngIf="(isShowReferences && !hasTagAttreRefs && !loadingTagAttr && refClicked && tagAttributeGrid)" class="modal-body">	
			<h5><b><center>No Tag Attribute References</center></b></h5>
	</div>

	</div>

</div>

<!-- [ngClass]="{'content-hide': isMinimized, 'content-show': !isMinimized}" -->