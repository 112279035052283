<!-- <div class="card-block card-body mt-2 pb-1 pt-1 card-primary">
	<div class="row">
		<div class="col-sm-12 my-2">
			<div class="row px-2">
				<div class="form-group col">
					<button type="button" class="btn btn-outline-primary btn-sm ml-1 mr-1" (click)="refreshTable()">
						{{ 'REUSABLE_UPDATE.REFRESH_TABLE' | translate}}
					</button>
				</div>
			</div>
			<div class="row px-2">
				<div class="form-group col">
					<label>Reusable Type :</label>
					<ng-select [allowClear]="true" [items]="reusableTypeList" name="itemType" id="itemType"
						(selected)="reusableItemChanged($event)">
					</ng-select>
				</div>
				<div class="form-group col">
					<label>Status :</label>
					<ng-select [allowClear]="true" [items]="statusList" name="status" id="status"
						(selected)="selectedStatusChanged($event)">
					</ng-select>
				</div>
			</div>
			<div class="row px-2">
				<div class="form-group col">
					<label>{{ 'REUSABLE_UPDATE.START_DATE' | translate }} :</label>
					<input type="text" class="form-control" bsDatepicker (bsValueChange)="onStartValueChange($event)"
						[bsValue]="bsStartValue"
						[bsConfig]="{ dateInputFormat: 'MM/DD/YYYY', containerClass: 'theme-default' }" id="startDate">
				</div>
				<div class="form-group col">
					<label>{{ 'REUSABLE_UPDATE.END_DATE' | translate }} :</label>
					<input type="text" class="form-control" bsDatepicker (bsValueChange)="onEndValueChange($event)"
						[bsValue]="bsEndValue"
						[bsConfig]="{ dateInputFormat: 'MM/DD/YYYY', containerClass: 'theme-default' }" id="endDate">
				</div>
			</div>
		</div>
	</div>

	<ag-grid-angular #agGrid style="width: 100%; height: 400px;" id="procedureStore" [rowData]="rowData"  [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }"
		class="ag-theme-balham" [columnDefs]="columnDefs" [frameworkComponents]="frameworkComponents"
		  [enableRangeSelection]="true" [paginationPageSize]="100"
		[pagination]="true" (columnResized)="onColumnResized($event)" (gridReady)="onGridReady($event)"
		[suppressMenuHide]="true" [gridOptions]="gridOptions">
	</ag-grid-angular>
</div> -->

<div class="main-panel pt-0 pb-4" style="overflow: hidden;">
	<div class="container-fluid">
		<div class="col-sm-12 px-0 align-self-end">
			<div class="row align-items-center">
				<div class="col-sm-10">
					<h3 class="">
						{{ 'REUSABLE_UPDATE.RU_SIDEBAR_REUSABE_UPDATE' | translate }}
					</h3>
				</div>
				<div class="col-sm-2">
					<button type="button" (click)="refreshTable()" class="btn btn-primary btn-sm float-right">
						{{ 'REUSABLE_UPDATE.REFRESH_TABLE' | translate}}
					</button>
				</div>
			</div>
			<hr class="mt-0 mb-3" />
		</div>
		<div class="col-sm-12 px-0">
			<form>
				<div class="row px-2">
					<div class="form-group col">
						<label>Reusable Type :</label>
						<ng-select [allowClear]="true" [items]="reusableTypeList" name="itemType" id="itemType"
							(selected)="reusableItemChanged($event)">
						</ng-select>
					</div>
					<div class="form-group col">
						<label>Status :</label>
						<ng-select [allowClear]="true" [items]="statusList" name="status" id="status"
							(selected)="selectedStatusChanged($event)">
						</ng-select>
					</div>
				</div>
				<div class="row px-2">
					<div class="form-group col">
						<label>{{ 'REUSABLE_UPDATE.START_DATE' | translate }} :</label>
						<input type="text" class="form-control" bsDatepicker
							(bsValueChange)="onStartValueChange($event)" [bsValue]="bsStartValue"
							[bsConfig]="{ dateInputFormat: 'MM/DD/YYYY', containerClass: 'theme-default' }"
							id="startDate">
					</div>
					<div class="form-group col">
						<label>{{ 'REUSABLE_UPDATE.END_DATE' | translate }} :</label>
						<input type="text" class="form-control" bsDatepicker (bsValueChange)="onEndValueChange($event)"
							[bsValue]="bsEndValue"
							[bsConfig]="{ dateInputFormat: 'MM/DD/YYYY', containerClass: 'theme-default' }"
							id="endDate">
					</div>
				</div>
			</form>
		</div>
		<hr class="mt-0 mb-3" />
		<div class="col-sm-12 px-0">
			<ag-grid-angular #agGrid style="width: 100%; height: 400px;" id="procedureStore" [rowData]="rowData" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }"
				class="ag-theme-balham" [columnDefs]="columnDefs" [frameworkComponents]="frameworkComponents"
				[enableRangeSelection]="true" [paginationPageSize]="100" [defaultColDef]="defaultColDef"
				[pagination]="true" (columnResized)="onColumnResized($event)" (gridReady)="onGridReady($event)"
				[suppressMenuHide]="true" [gridOptions]="gridOptions">
			</ag-grid-angular>
		</div>
	</div>
</div>