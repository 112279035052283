import { Component } from "@angular/core";
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { CapabilityService } from '../../../../../util/capability.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { OutputManagementService } from '../../../outputmanagement.service';
import { AlertService } from '../../../../../util/alert/alert.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { LoadingService } from '../../../../../util/loading/loading.service';
import { AddTemplateModalComponent } from '../../../outputviewmodal/addtemplatemodal';

@Component({
   selector: 'child-cell',
   template: '<input type="radio" name="addtemp" id="OM_addtemp" [value]="id" (change)="onSelectionChange()">'
})

export class AddTemplateRender implements ICellRendererAngularComp {
   public params: any;
   bsModalRef: BsModalRef;   
   loadingMessage: string;
   id: String;
   templatename: string;
  
   constructor(private capabilityService: CapabilityService, private modalService: BsModalService, private translate: TranslateService,
      private outputManagementService: OutputManagementService, private alertService: AlertService, private loadingService: LoadingService) {
   }

   agInit(params: any): void {
      this.params = params
      this.id = this.params.data.id
      this.templatename = this.params.data.displayName
   }

   onSelectionChange(){
      this.bsModalRef = this.modalService.show(AddTemplateModalComponent, { class: 'modal-lg' });
      this.bsModalRef.content.id = this.id;
      this.bsModalRef.content.templatename = this.templatename;
   }

   refresh(): boolean {
      return true;
   }

}