export class SearchParams<T> {
    value: T
    key: string
    label: string
    placeholder: string
    type: string
    order: number
    controlType: string
    disable: boolean
    options: any

    constructor(options: {
        value?: T,
        key?: string,
        label?: string,
        placeholder?: string,
        type?: string,
        order?: number,
        controlType?: string,
        disable?: boolean
        
    } = {}) {
        this.value = options.value
        this.key = options.key || ''
        this.label = options.label || ''
        this.placeholder = options.placeholder || ''
        this.type = options.type || ''
        this.order = options.order === undefined ? 1 : options.order
        this.controlType = options.controlType || ''
        this.disable = options.disable
        
    }
}