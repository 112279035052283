
<!-- <div class="main-panel" [style.padding-top.px]="40" style="overflow: hidden;"> -->
<div class="main-panel pt-0 pb-4" style="overflow: hidden;">
	<div class="container-fluid">

		<div class="col-sm-12 px-0">
			<h3>{{ title }} </h3>
			<hr class="mt-0 mb-3" />
		</div>
	
		<div class="card-block card-body mt-2 pb-1 pt-1  px-0 card-primary" >
			<ag-grid-angular #agGrid style="width: 100%; " 	[style.height.px]="divHeight"	id="reportDataTable" [rowData]="rowData" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }" class="ag-theme-balham"
					[columnDefs]="columnDefs" [defaultColDef]="defaultColDef" 
					[enableRangeSelection]="true" [paginationPageSize]="10" [pagination]="true" (paginationChanged)="onPageChanged($event)" (columnResized)="onColumnResized($event)"
					(gridReady)="onGridReady($event)" [suppressMenuHide]="true" [gridOptions] ="gridOptions" [overlayLoadingTemplate]="overlayLoadingTemplate"
					[overlayNoRowsTemplate]="overlayNoRowsTemplate">
			</ag-grid-angular>
		</div>

		<!-- [style.height.px]="divHeight" -->
		<!-- height: 400px; -->
	</div>
</div>
