<div class="main-panel pt-0 pb-4" style="overflow: hidden;">
	<div class="container-fluid" [ngClass]="{'content-hide': isMinimized, 'content-show': !isMinimized}">
		<div class="col-sm-12 px-0">
			<h3 >
				{{ 'OUTPUT_MANAGEMENT.OM_SIDEBAR_OUTPUT_MANAGEMENT' | translate }}
			</h3>
			<hr class="mt-0 mb-3"/>
		</div>
		<div class="col-sm-12 px-0">
			<form>
				<div class="form-row">
					<div class="form-group col">
						<label class="mb-0">{{ 'OUTPUT_MANAGEMENT.ITEM_TYPE' | translate }} :</label>
						<ng-select [active]="selectedItemType" [allowClear]="true" (removed)="removedItemType($event)"
							(selected)="selectedItemTypeId($event)" [items]="itemTypeList" name="itemType"
							id="itemType">
						</ng-select>
					</div>
					<div class="form-group col">
						<label class="mb-0">{{ 'OUTPUT_MANAGEMENT.STATUS' | translate }} :</label>
						<ng-select [active]="selectedStatus" [allowClear]="true" (removed)="removedStatus($event)"
							(selected)="selectedStatusId($event)" [items]="statusList" name="status" id="status">
						</ng-select>
					</div>
				</div>
				<div class="form-row">
					<div class="form-group col">
						<label class="mb-0">{{ 'OUTPUT_MANAGEMENT.START_DATE' | translate }} :</label>
						<input type="text" class="form-control" bsDatepicker
							(bsValueChange)="onStartValueChange($event)" [bsValue]="bsStartValue"
							[bsConfig]="{ dateInputFormat: 'MM/DD/YYYY', containerClass: 'theme-default' }"
							id="startDate">
					</div>
					<div class="form-group col">
						<label class="mb-0">{{ 'OUTPUT_MANAGEMENT.END_DATE' | translate }} :</label>
						<input type="text" class="form-control" bsDatepicker (bsValueChange)="onEndValueChange($event)"
							[bsValue]="bsEndValue"
							[bsConfig]="{ dateInputFormat: 'MM/DD/YYYY', containerClass: 'theme-default' }"
							id="endDate">
					</div>
					<div class="form-group col">
						<label class="mb-0">{{ 'OUTPUT_MANAGEMENT.OUTPUT_TYPE' | translate }} :</label>
						<ng-select [active] = "selectedOutputType" [allowClear]="true" 
							(selected)="selectedOutputTypeId($event)" [items]="outputTypeList" name="outputType" id="outputType">
						</ng-select>
					</div>
				</div>
			</form>
		</div>
		<hr class="mt-0 mb-3" />
		<!-- body content -->
		<div class="col-sm-12 px-0">
			<ag-grid-angular #agGrid style="width: 100%; height: 400px;" id="procedureStore" [rowData]="rowData" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }"
				class="ag-theme-balham" [columnDefs]="columnDefs" [frameworkComponents]="frameworkComponents"
				  [enableRangeSelection]="true" [paginationPageSize]="100" [defaultColDef]="defaultColDef"
				[pagination]="true" (columnResized)="onColumnResized($event)" (gridReady)="onGridReady($event)"
				[suppressMenuHide]="true" [gridOptions]="gridOptions"></ag-grid-angular>
		</div>
	</div>
</div>