import { Component, TemplateRef, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService } from 'src/app/util/alert/alert.service';
import { WsCallback } from 'src/app/util/ws-callback.interface';
import { WsResponse } from 'src/app/util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';
import { TagsService } from '../../tags.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.css']
})
export class ConfirmModalComponent implements OnInit, WsCallback {
  public token;
  public attributeId;
  public tagClasSpecId;
  public callBack;

  ngOnInit(): void {
  }

  constructor(public bsModalRef: BsModalRef, private alertService: AlertService, private tagService: TagsService, private translate: TranslateService) {}

  confirm(): void {
    var data = {userToken: this.token, itemId: this.tagClasSpecId};
    this.tagService.removeTagClassificationAttributeMap(data, this);
  }

  decline(): void {
    this.bsModalRef.hide();
  }

  onSuccess(data: WsResponse, serviceType: WsType) {
    if (serviceType == WsType.REMOVE_TAG_CLASSIFICATION_ATTRIBUTE_MAP) {
      let alert = this.translate.instant('TAG_CLASSIFICATION.REMOVE_TAG_CLASSIFICATION_ATTRIBUTE_MAP');
      this.alertService.success(alert);
      this.bsModalRef.hide();
      this.callBack.deleteNewItem();
    }
  }
  onFail(data: WsResponse, serviceType: WsType) {
    if (serviceType == WsType.REMOVE_TAG_CLASSIFICATION_ATTRIBUTE_MAP) {
      this.alertService.error(data.statusDescription);
      this.bsModalRef.hide();
    }
  }

}
