import { OnInit, ViewChild, Component } from '@angular/core';
import { WorkflowService } from '../workflow.service';
import { ClientSideRowModelModule } from '@ag-grid-community/all-modules';
import { SelectProjectWorkflowTemplateCellRenderer } from '../cell-renderer/addworkflowtemplate/select-workflow-template-renderer.component';
import { TranslateService } from '@ngx-translate/core';
import { GridOptions, Module, AllModules } from '@ag-grid-enterprise/all-modules';
import { LoadingService } from 'src/app/util/loading/loading.service';
import { AlertService } from 'src/app/util/alert/alert.service';

@Component({
    selector: 'add-project-workflow-template',
    templateUrl: './add-project-workflow-template.component.html'
  })
export class AddProjectWorkflowTemplateComponent implements OnInit{
    public columnDefs: any
    private gridApi:any
    private gridColumnApi:any
    public rowData: any = [];
    public gridOptions: GridOptions
    public overlayLoadingTemplate: any
    public overlayNoRowsTemplate:any
    public getRowNodeId: any
    modules: Module[] = AllModules;
    public defaultColDef: any;

    private select: string;
    private templateName: string;
    private itemType: string;
    private version: string;
    
    constructor(private wfService: WorkflowService,  private translate: TranslateService, private loadingService: LoadingService, private alertService: AlertService){
        translate.addLangs(["es", "en"]);
        translate.setDefaultLang("en");
        let browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
        this.translate.get('WORKFLOW_CONFIGURATION.WORKFLOW_NAME').subscribe((res: string) => {
            this.templateName = res;
            this.translate.get('WORKFLOW_CONFIGURATION.ITEM_TYPE').subscribe((res: string) => {
                this.itemType = res;
                this.translate.get('WORKFLOW_CONFIGURATION.VERSION').subscribe((res: string) => {
                    this.version = res;
                    this.translate.get('WORKFLOW_CONFIGURATION.SELECT').subscribe((res: string) => {
                        this.select = res;
                    });
                });
            });
        });

        this.gridOptions = <GridOptions>{
            context: {
                componentParent: this
            }
        };

        this.defaultColDef = {
			resizable: true,
			sortable: true,
        };
        
        this.columnDefs = [
            {
                headerName: 'Id',
                field: 'id',
                hide: true
            },
            {
                headerName: 'Select',
                field: '',
                cellClass: "cell-wrap-text", width: 85,
                cellRendererFramework: SelectProjectWorkflowTemplateCellRenderer
            },
            {
                headerName: this.templateName,
                field: 'templateName',
                cellClass: "cell-wrap-text"
            },
            {
                headerName: this.itemType,
                field: 'itemType',
                cellClass: "cell-wrap-text"
            },
            {
                headerName: this.version,
                field: 'version',
                cellClass: "cell-wrap-text"
            }
        ]

        this.gridOptions = {
            rowData: this.rowData,
            columnDefs: this.columnDefs,
            rowSelection: "single",
            rowMultiSelectWithClick: false
        };

        //custom layout templates for loading and empty data sets
        this.overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Loading data...</span>';
        this.overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">No rows to show</span>';
    }

    ngOnInit(){
        this.getWorkflowTemplateListsWithoutMappingByProjectId();
    }

    onGridReady(params) {
        this.gridColumnApi = params.columnApi;
        this.gridApi = params.api;
        this.gridOptions.api.setRowData(this.rowData);
        params.api.sizeColumnsToFit();
        // window.addEventListener("resize", function () {
        //     setTimeout(function () {
        //         params.api.sizeColumnsToFit();
        //     });
        // });
        window.addEventListener("resize", this.onWindowResize);
     }
     private onWindowResize = () => {
   
		setTimeout(() => {
			if (this.gridApi) {
				this.gridApi.sizeColumnsToFit();
			}
		}); 
	};

     ngOnDestroy(){
        window.removeEventListener("resize", this.onWindowResize);
        this.gridApi.destroy();
        this.gridApi = null;
     }

    getWorkflowTemplateListsWithoutMappingByProjectId(){
        //get workflow templates which are not assigned to the selected project
        this.wfService.getWorkflowTemplateListsWithoutMappingByProjectId().subscribe(data => {
            this.loadData(data.payload);
        }, error => {
            this.alertService.clear()
            this.alertService.error(error.statusDescription)
        });
    }

    loadData(data: any){
        this.rowData = []
        if(data != null){
            for(let i = 0; i < data.length; i++){
                this.rowData.push({
                    id: data[i]["id"],
                    templateName: data[i]['fileName'],
                    itemType: data[i]['itemType'],
                    version: data[i]['version'],
                    configTasks: data[i]['configTasks'],
                    configParameters: data[i]['configParameters']
                })
            }
        }
    }
}