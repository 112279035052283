import { Injectable } from '@angular/core';
import { AddwordtodictionaryComponent } from '../addwordtodictionary/addwordtodictionary.component';
import { DictionaryComponent } from '../dictionary.component';

@Injectable({
	providedIn: 'root'
})
export class DictionaryLoaderService {

	constructor() { }

	public loadComponentByName(compName: string) {
		switch (compName) {
            case 'dictionary':
                return DictionaryComponent
            case 'addword':
                return AddwordtodictionaryComponent
            default:
                break;
        }
	}
}
