import { Component, TemplateRef,ViewChild } from "@angular/core";
import { LandingService } from '../../../../landing/landing.service';
import { WsResponse } from '../../../../util/ws-response.model';
import { WsType } from '../../../../util/ws-type';
import { AlertService } from '../../../../util/alert/alert.service';
import { Router } from '@angular/router';
import { LocalStorage } from '../../../../util/localstorage.service';
import { RecentProjectModel } from '../../../../landing/recent-project.model';
import { LoggedUser } from '../../../../user/logged-user.model';
import { RecentProject } from '../../../../landing/recent-project.service';
import { SharedService } from "../../../../util/shared.service";
import { WsCallback } from "../../../../util/ws-callback.interface";
import { FilesService } from "../../files.service";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { XmlmodelComponent } from "../../../../util/xmlmodel/xmlmodel.component";
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from '../../../../util/loading/loading.service';
import * as FileSaver from 'file-saver';
import { ViewmodelComponent } from 'src/app/util/viewmodel/viewmodel.component';

@Component({
	selector: 'app-cell-render',
	templateUrl: './revision-cell-render-component.component.html'
})
// @Injectable({
// 	providedIn: 'root'
// })
export class RevisionCellRenderComponent implements WsCallback {
	@ViewChild('viewVideoTemplate') viewVideoTemplate: TemplateRef<any>
	@ViewChild('noViewTemplate') noViewTemplate: TemplateRef<any>
	@ViewChild('viewThreeDModelTemplate') viewThreeDModelTemplate: TemplateRef<any>
	@ViewChild('tabOpenedWarningTemplate') tabOpenedWarningTemplate: TemplateRef<any>
	// @Output() onHide = new EventEmitter<void>();
	icon: string;
	data: string;
	type: string;
	disable: boolean = false;
	restore: boolean = false;
	IsFile: boolean = false;
	isText: boolean = false;
	private loggedInUser: LoggedUser;
	public recentProjectList: Array<RecentProjectModel> = new Array<RecentProjectModel>();
	bsModalRef: BsModalRef;
	okActionText: string;
	alertTitle: string;
	alertMessageTopic: string;
	alertMessageTopicMap: string;
	alertMessageProcedure: string;
	alertMessageContent: string;
	alertMessageImage: string;
	alertMessageIceCourse: string;
	errorMessage: string;
	cancelText: string;
	fileName: string;
	alertMessageThreeDModel:string;
	alertMessageVideo:string;
	url:string;
	viewModalRef: BsModalRef;
	alertMessageVideoView:string;
	tabOpenedWarnModalRef: BsModalRef;
	params : any

	constructor(private alertService: AlertService, private filesService: FilesService,
		private recentProjectService: RecentProject, private modalService: BsModalService, private sharedService: SharedService, private translateService: TranslateService, private loadingService: LoadingService) {
		translateService.addLangs(["es", "en"]);
		translateService.setDefaultLang("en");
		let browserLang = translateService.getBrowserLang();
		translateService.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
		this.translateService.get('REVISION_CELL_RENDERER.OK').subscribe((res: string) => {
			this.okActionText = res;
			this.translateService.get('REVISION_CELL_RENDERER.ALERT').subscribe((res: string) => {
				this.alertTitle = res;
				this.translateService.get('REVISION_CELL_RENDERER.ALERT_MESSAGE_TOPIC').subscribe((res: string) => {
					this.alertMessageTopic = res;
					this.translateService.get('REVISION_CELL_RENDERER.CANCEL').subscribe((res: string) => {
						this.cancelText = res;
						this.translateService.get('REVISION_CELL_RENDERER.ERROR').subscribe((res: string) => {
							this.errorMessage = res;
							this.translateService.get('REVISION_CELL_RENDERER.ALERT_MESSAGE_PROCEDURE').subscribe((res: string) => {
								this.alertMessageProcedure = res;
								this.translateService.get('REVISION_CELL_RENDERER.ALERT_MESSAGE_TOPICMAP').subscribe((res: string) => {
									this.alertMessageTopicMap = res;
									this.translateService.get('REVISION_CELL_RENDERER.ALERT_MESSAGE_CONTENT').subscribe((res: string) => {
										this.alertMessageContent = res;
										this.translateService.get('REVISION_CELL_RENDERER.ALERT_MESSAGE_IMAGE').subscribe((res: string) => {
											this.alertMessageImage = res;
											this.translateService.get('REVISION_CELL_RENDERER.ALERT_MESSAGE_ICECOURSE').subscribe((res: string) => {
												this.alertMessageIceCourse = res;
												this.translateService.get('REVISION_CELL_RENDERER.ALERT_MESSAGE_THREEDMODEL').subscribe((res: string) => {
													this.alertMessageThreeDModel = res;
													this.translateService.get('REVISION_CELL_RENDERER.ALERT_MESSAGE_VIDEO').subscribe((res: string) => {
														this.alertMessageVideo = res;
														
													});
												});
											});
										});
									});
								});
							});
						});
					});
				});
			});
		});
		//recentProjectService.setCallback(this);
	}

	public agInit(params: any): void {
		this.params = params;
		this.loggedInUser = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER)) as LoggedUser;

		let items = params.value.split(":");
		if (items != null && items.length > 0) {
			let params = items[1].split(",");
			this.setType(items[0], params[2]);
			this.type = items[0]
			if (items.length > 1) {
				this.data = items[1]
				//this.loadDisabledFiles(this.data);
			} else {
				//not in project panel
			}
		} else {
			//nothing to process
		}
		this.sharedService.disableCellRendererExecute().subscribe(option => this.setDisable(option));
	}

	

	openModalWithComponent(data: any, title: any, closeBtn: any, actionBtn: any, itemId: any, cmRevisionNo: any, itemType: any) {
		const initialState = {
			data: data,
			title: title,
			itemId: itemId,
			cmRevisionNo: cmRevisionNo,
			itemType: itemType
		};
		this.bsModalRef = this.modalService.show(XmlmodelComponent, { initialState, class: 'modal-xl' });
		this.bsModalRef.content.closeBtnName = closeBtn;
		this.bsModalRef.content.actionBtn = actionBtn;
				
	}


	openModalWithComponentTopic(data:any,title: any,topicId:string,closeBtn: any, actionBtn: any){
		const initialState={
			data:data,
			title:title,
			topicId:topicId
		};
		this.bsModalRef=this.modalService.show(XmlmodelComponent,{initialState, class: 'modal-xl'});
		this.bsModalRef.content.closeBtnName = closeBtn;
		this.bsModalRef.content.actionBtn = actionBtn;
		console.log("Title is :"+title)
		console.log(" Topic Id is :" +topicId)
		//console.log(data)

	}

	openModalWithComponentTopicXML(data:any,title:any,topicId:string,closeBtn:any,actionBtn:any){
		const initialState={
			data:data,
			title:title,
			topicId:topicId
		};
		this.bsModalRef=this.modalService.show(ViewmodelComponent,{initialState, class: 'modal-xl'});
		this.bsModalRef.content.closeBtnName=closeBtn;
		this.bsModalRef.content.actionBtn=actionBtn;
	}

	openModalWithComponentRevisionXML(data:any,title:any,revisionId:string,closeBtn:any,actionBtn:any,cmRevisionNo: any, itemType: any){
		const initialState={
			data:data,
			title:title,
			revisionId:revisionId,
			cmRevisionNo:cmRevisionNo,
			itemType:itemType
		};
		this.bsModalRef=this.modalService.show(ViewmodelComponent,{initialState, class: 'modal-xl'});
		this.bsModalRef.content.closeBtnName=closeBtn;
		this.bsModalRef.content.actionBtn=actionBtn;
	}

	OpenModalwithComponentProcedure(data:any,title:any,procedureId:string,closeBtn:any,actionBtn:any){
		const initialState={
			data:data,
			title:title,
			procedureId:procedureId
		};
		this.bsModalRef=this.modalService.show(XmlmodelComponent,{initialState, class: 'modal-xl'});
		this.bsModalRef.content.closeBtnName = closeBtn;
		this.bsModalRef.content.actionBtn = actionBtn;
		
	}

	OpenModalWithComponentProcedureView(data:any,title:any,procedureId:string,closeBtn:any,actionBtn:any){
		const initialState={
			data:data,
			title:title,
			procedureId:procedureId
		};
		this.bsModalRef=this.modalService.show(ViewmodelComponent,{initialState, class: 'modal-xl'});
		this.bsModalRef.content.closeBtnName=closeBtn;
		this.bsModalRef.content.actionBtn=actionBtn;
	}

	openModalWithComponentProcedureRevisionView(data:any,title:any,revisionId:string,closeBtn:any,actionBtn:any,cmRevisionNo: any, itemType: any){
		const initialState={
			data:data,
			title:title,
			revisionId:revisionId,
			cmRevisionNo:cmRevisionNo,
			itemType:itemType
		};
		this.bsModalRef=this.modalService.show(ViewmodelComponent,{initialState, class: 'modal-xl'});
		this.bsModalRef.content.closeBtnName=closeBtn;
		this.bsModalRef.content.actionBtn=actionBtn;
	}

	//

	public invoke(type: String, data: any) {
		console.log("invoke")
		let items = data.split(",")
		if (items != null) {
			console.log("invoke123" + type)
			// for( let i=0;items.length;i++)
			// console.log("items :" +i+ items[i])

			//UserVariable.projectId = items[0];
			switch (type) {
				case 'view':
					
					if (items[2] == 'Image') {
						this.fileName = items[4];
						this.filesService.getImageContent(this, items[1], items[4], items[0])
					}else if(items[2] == 'ContentItem'){
						this.fileName = items[3];
						console.log("this.fileName= "+ this.fileName)
						this.downloadContentItem(items[0], items[1], items[3])

					}else if (items[3] == 'Topic0') {

						if (items[2] == "Topic") {
							this.filesService.getTopicContent(this, items[0], items[1])
							console.log("Topic Content XML");

						}else if (items[2] == "Procedure") {
							this.filesService.getProcedureContent(this, items[2], items[1])
							console.log("Procedure Content XML");
						}
						
					}else if (items[3] == 'viewTopicContent') {

						if (items[2]=="Topic") {
							this.filesService.getTopicContentView(this, items[2], items[1])
							console.log("Topic Content");

						}else if (items[2] == "Procedure") {
							this.filesService.getProcedureContentView(this, items[2], items[1])
							console.log("Procedure Content");
						}

					}else if (items[3] == 'viewRevisionContent') {

						if (items[2] == 'Topic') {
							console.log("Revision Content Topics")
							this.filesService.getRevisionContentView(this, items[1], items[2], items[0])

						}else if (items[2] == 'Procedure') {
							console.log("Revision Content Procedure")
							this.filesService.getProcedureRevisionContentView(this, items[1], items[2], items[0])
						}	
					}
					else if (items[2] == 'Video') {
							this.filesService.getS3Content(this, items[0], items[1],items[2]).subscribe(data => {
								if (data.payload) {
									this.url = data.payload;
									console.log("url " + this.url)
									 if (this.url != null){
									 	this.viewModalRef= this.modalService.show(this.viewVideoTemplate, { backdrop: true, class: 'modal-lg' });
									 }
								} else {
								 if (this.url == null){
									this.viewModalRef= this.modalService.show(this.noViewTemplate, { backdrop: true, class: 'modal-lg' });
								 }
								}
								
							 },
								error => {
								   this.alertService.error(error.statusDescription)
								});
							console.log("Video View");
								
						
					}
					else if (items[2] == 'ThreeDModel') {
						this.filesService.getS3Content(this, items[0], items[1],items[2]).subscribe(data => {
							if (data.payload) {
								this.url = data.payload;
								console.log("url " + this.url)
								 if (this.url != null){
									 this.viewModalRef= this.modalService.show(this.viewThreeDModelTemplate, { backdrop: true, class: 'modal-lg' });
								 }
							} else {
							 if (this.url == null){
								this.viewModalRef= this.modalService.show(this.noViewTemplate, { backdrop: true, class: 'modal-lg' });
							 }
							}
							
						 },
							error => {
							   this.alertService.error(error.statusDescription)
							});
						console.log("Video View");
							
					
				}
					else {
						this.filesService.getRevisionContent(this, items[1], items[2], items[0])
						console.log(items[0]+" "+items[1]+" "+items[2]+" "+items[3]+" "+items[4])
					}
					break;
				case 'restore':
					let openedTabs = this.sharedService.getOpenedTabs();
					let selectedFileId = items[1];
					if ((items[2] == 'Topic' || items[2] == 'Procedure') && openedTabs.indexOf(selectedFileId) > -1) {
						this.tabOpenedWarnModalRef = this.modalService.show(this.tabOpenedWarningTemplate, { class: 'modal-lg' });
					}else{
						let message = '';
						if (items[2] == 'Topic') {
							message = this.alertMessageTopic;
						}
						else if (items[2] == 'Procedure') {
							message = this.alertMessageProcedure;
						}
						else if (items[2] == 'TopicMap') {
							message = this.alertMessageTopicMap;
						}
						else if (items[2] == 'ContentItem') {
							message = this.alertMessageContent;
						}
						else if (items[2] == 'Image') {
							message = this.alertMessageImage;
						}
						else if (items[2] == 'ICECourse') {
							message = this.alertMessageIceCourse;
						}
						else if (items[2] == 'ThreeDModel') {
							message = this.alertMessageThreeDModel;
						}
						else if (items[2] == 'Video') {
							message = this.alertMessageVideo;
						}
						console.log("invoke234")
						this.openModalWithComponent(message + " " + items[0] + " ? ", this.alertTitle, this.cancelText, this.okActionText, items[1], items[0], items[2]);
						//Marking the project as recently visited
					}
					break;
				default:
					break;
			}
		}
	}

	closeRevision(){
		this.params.context.componentParent.emitClose();
	}

	/**
     * openTab button click event
     */
	public openTabBtnEvent() {
		let fileData = this.filesService.getSelectedFileData();
		let data = {
			id: fileData.id,
			viewOnly: false
		}
		let folderPath: string = fileData.folderPath + fileData.name;
		switch (fileData.type) {
			case 'Topic':
				this.sharedService.openTabRequestWithId(fileData.id, fileData.name, "Editor", null, folderPath);
				break;
			case 'Procedure':
				this.sharedService.openTabRequestWithId(fileData.id, fileData.name, "Procedures", null, folderPath);
				break;
			case 'TopicMap':
				this.sharedService.openTopicMapBuilder(fileData.id, fileData.name, 'edit', fileData.content)
				this.sharedService.openTabRequestWithId(fileData.id, fileData.name, 'TopicMapBuilder', null, folderPath)
				break;
			case 'ICECourse':
				this.sharedService.openTabRequestWithId(fileData.id, fileData.name, 'IceCourse', data, folderPath);
				break;
			default:
				break;
		}
	}

	saveFile(data) {
		let base64 = data;

		// Naming the image
		const imageName = this.fileName;
		let ext = imageName.split('.');

		// call method that creates a blob from dataUri
		const imageBlob = this.dataURItoBlob(base64);

		if (data != null && navigator.msSaveBlob)
			return navigator.msSaveBlob(imageBlob, imageName);
		var a = $("<a style='display: none;'/>");
		var url = window.URL.createObjectURL(imageBlob);
		a.attr("href", url);
		a.attr("download", imageName);
		$("body").append(a);
		a[0].click();
		window.URL.revokeObjectURL(url);
		a.remove();
	}


	/**
	 * download pdf, doc, docx, ppt, pptx
	 */
	private downloadContentItem(revisionNo, contentId, contentName){
		let parts = contentName.split('.')
		let type = parts.pop().toUpperCase()
		console.log("type= "+type)
		this.loadingService.showLoading(true, null, "Downloading", null)
		// let projectId = localStorage.getItem('project_id')
		let fileType = null
		switch(type){
			case('PDF'):
				fileType = 'application/pdf'
				break
			case('DOC'):
				fileType = 'application/doc'
				break
			case('DOCX'):
				fileType = 'application/docx'
				break
			case('PPT'):
				fileType = 'application/ppt'
				break
			case('PPTX'):
				fileType = 'application/pptx'
				break
			default: 
				this.loadingService.hideLoading()
				return false
		}//(revisionNo: string, contentId: string, documentType:
			this.filesService.getContentItemContent(revisionNo,contentId, type)
			.subscribe(data => {
				let base64 = data.payload;
				const byteString = atob(base64);
				const arrayBuffer = new ArrayBuffer(byteString.length);
				const int8Array = new Uint8Array(arrayBuffer);

				for (let i = 0; i < byteString.length; i++) {
					int8Array[i] = byteString.charCodeAt(i);
				}

				let file = new Blob([arrayBuffer], { type: fileType });
				FileSaver.saveAs(file, contentName);
				this.loadingService.hideLoading()
			},
			   error => {
				console.log(error.statusDescription)
				this.loadingService.hideLoading()
				let alert = this.translateService.instant('FILES_DATA.ERROR_DOWNLOAD_FILE');
				this.alertService.error(alert)
			});
	}
 	

	dataURItoBlob(dataURI) {
		const byteString = atob(dataURI);
		const arrayBuffer = new ArrayBuffer(byteString.length);
		const int8Array = new Uint8Array(arrayBuffer);

		for (let i = 0; i < byteString.length; i++) {
			int8Array[i] = byteString.charCodeAt(i);
		}
		const blob = new Blob([arrayBuffer], { type: 'image/jpeg' });
		return blob;
	}

	onSuccess(data: WsResponse, serviceType: WsType): void {
		console.log("Inside onsuccess")
		
		if (serviceType == WsType.ADD_UPDATE_RECENT_PROJECT) {
			//refresh the recent list again
			this.recentProjectService.getAllRecentProjects(this.loggedInUser.userId, this);
		}
		else if (serviceType == WsType.GET_ALL_RECENT_PROJECTS) {
			this.recentProjectList = data.payload;
			this.recentProjectService.setDefaultRecentProjects(data.payload, this);
		}
		else if (serviceType === WsType.REVISION_CONTENT_VIEW) {
			this.openModalWithComponent(data.payload, "XML", this.okActionText, null, null, null, null);
			
		}
		else if (serviceType === WsType.DOWNLOAD_IMAGE) {
			this.saveFile(data.payload);
		}
		else if (serviceType === WsType.VIEW_TOPIC_CONTENT) {
			this.openModalWithComponentTopic(data.payload,"XML",null,this.okActionText,null);
		}
		else if (serviceType == WsType.VIEW_TOPIC_CONTENT_XML) {
			this.openModalWithComponentTopicXML(data.payload,"Topic View",null,this.okActionText,null);
		}
		else if (serviceType == WsType.VIEW_REVISION_CONTENT_XML) {
			this.openModalWithComponentRevisionXML(data.payload,"Revision View",null,this.okActionText,null,null,null);
		}
		else if (serviceType == WsType.VIEW_PROCEDURE_CONTENT) {
			this.OpenModalwithComponentProcedure(data.payload,"XML",null,this.okActionText,null);
		}
		else if (serviceType == WsType.VIEW_PROCEDURE_CONTENT_OUTPUT) {
			this.OpenModalWithComponentProcedureView(data.payload,"Procedure View",null,this.okActionText,null);
		}
		else if (serviceType == WsType.VIEW_PROCEDURE_REVISION_CONTENT_OUTPUT) {
			this.openModalWithComponentProcedureRevisionView(data.payload,"Revision View",null,this.okActionText,null,null,null);
		}
		else {

		}
	}

	onFail(res: WsResponse, serviceType: WsType): void {
		console.log("Recieved a failed service type "+ serviceType)
		if (serviceType == WsType.GET_ALL_RECENT_PROJECTS) {
			this.alertService.error(res.statusDescription);
		} else if (serviceType == WsType.ADD_UPDATE_RECENT_PROJECT) {
			//We don't show the error message here since it is out of user control.
			console.log("=======error======" + res.statusDescription);
		}else if (serviceType === WsType.REVISION_CONTENT_VIEW) {
			this.openModalWithComponent(this.errorMessage + " - " + res.statusDescription, this.alertTitle, this.okActionText, null, null, null, null);
		}else if(serviceType == WsType.DOWNLOAD_IMAGE){
			this.alertService.error(res.statusDescription);
		}else if(serviceType===WsType.VIEW_TOPIC_CONTENT){
			this.openModalWithComponentTopic(this.errorMessage + " - " + res.statusDescription,this.alertTitle,null,this.okActionText,null);
		}else if(serviceType==WsType.VIEW_TOPIC_CONTENT_XML){
			this.openModalWithComponentTopicXML(this.errorMessage + " - " + res.statusDescription,this.alertTitle,null,this.okActionText,null);
		}else if(serviceType==WsType.VIEW_REVISION_CONTENT_XML){
			this.openModalWithComponentRevisionXML(this.errorMessage + " - " + res.statusDescription, this.alertTitle, this.okActionText, null, null, null, null);
		}else if(serviceType==WsType.VIEW_PROCEDURE_CONTENT){
			this.OpenModalwithComponentProcedure(this.errorMessage+" - "+res.statusDescription,this.alertTitle,null,this.okActionText,null);
		}else if(serviceType==WsType.VIEW_PROCEDURE_CONTENT_OUTPUT){
			this.OpenModalWithComponentProcedureView(this.errorMessage+" - "+res.statusDescription,this.alertTitle,null,this.okActionText,null);
		}else if(serviceType=WsType.VIEW_PROCEDURE_REVISION_CONTENT_OUTPUT){
			this.openModalWithComponentProcedureRevisionView(this.errorMessage+" - "+res.statusDescription,this.alertTitle,null,this.okActionText,null,null,null);
		}
		else {

		}
	}

	private setType(params, params1) {
		if (params == 'disableRestore') {
			console.log("disableRestore")
			this.disable = true;
			this.restore = true;
		}
		else if (params == 'restore') {
			this.restore = true;
		}
		else if (params == 'tooltip') {
			this.isText = true;
		}
		
		this.icon = params == 'view' ? params1 == "Image" ? 'fa fa-download' : 'fa fa-file' : params == 'restore' ? 'fa fa-undo ' :
			params == 'disableRestore' ? 'fa fa-undo' : '';
			// console.log("this.icon " + this.disable + " " + this.restore + " " + this.isText + " "  + this.icon);
	}

	private setDisable(option: any) {
		let items = this.data.split(",")
		if (option != null && option.id == items[0]) {
			this.disable = option.data;
		}
	}


	public close() {
		this.viewModalRef.hide();
    }
}  
