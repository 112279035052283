<div class="main-panel pt-0 pb-4"  style="overflow: hidden" >
	<div class="container-fluid">
		<div class="row my-2 mx-1 border-bottom">		
			<div class="col-sm-12 px-0 mb-1 ">
				<div class="float-right" >
					<button [disabled]="(rootSelected || disableAllButtons) || !enableDisableCategory" type="button" class="btn btn-outline-dark btn-sm ml-1 mr-1 border-0"
						(click)="disableElement(dependency)" tooltip="{{ activeTooltip }}" placement="bottom">
						<i *ngIf="selectedNodeStatusActive"  class="fas fa-times fa-sm" aria-hidden="true"></i>
						<i *ngIf="!selectedNodeStatusActive"  class="fas fa-check fa-sm" aria-hidden="true" ></i>
					</button>
					<button [disabled]="((!selectedNodeStatusActive) || (!isCatSelected) || disableAllButtons) || !enableAddCategory" type="button" class="btn btn-outline-dark btn-sm ml-1 mr-1 border-0"
						(click)="createElement()"  tooltip="{{ addTooltip }}" placement="bottom">
						<i  class="fas fa-plus fa-sm" aria-hidden="true"></i>
					</button>
					<button [disabled]="((!selectedNodeStatusActive) || rootSelected ||disableAllButtons) || !enableModifyCategory " type="button" class="btn btn-outline-dark btn-sm ml-1 mr-1 border-0"
						(click)="renameElement()" tooltip="{{ renameTooltip }}" placement="bottom">
						<i  class="fas fa-edit fa-sm" aria-hidden="true" ></i>
					</button>
					<button [disabled]="((!selectedNodeStatusActive) || rootSelected || disableAllButtons) || !enableDeleteCategory" type="button" class="btn btn-outline-dark btn-sm ml-1 mr-1 border-0"
						(click)="deleteElement(dependency)" tooltip="{{ deleteTooltip }}" placement="bottom">
						<i  class="fas fa-trash-alt fa-sm" aria-hidden="true" ></i>
					</button>
				</div>
			</div>		
		</div>

		<div class="col-sm-12 px-0 " style="margin-bottom:200px" >
			<form >	
				<div class="form-group">
					<label class="mb-0">{{ 'AS_CATEGORY.CATEGORY' | translate }} :</label>
					<ng-select [active]="categoryList" [allowClear]="false" (selected)="getSelectedCategoryType($event)"
						[items]="categoryList" name="categoryType" id="categoryType" >
					</ng-select>
				</div>
			</form>
			<hr class="mt-0 mb-3" />	
			<div id="catFoldertree" class="my-2 mx-1" ></div>		
		</div>	
	</div>
</div>


<ng-template #dependency>
    <div *ngIf="showDependencyModel" class="modal-header">
		<h4 *ngIf="toDeleteViewDependency"  class="modal-title pull-left">{{'AS_CATEGORY.DELETE_CONFLICTS' | translate  }} - {{ selectedNodeName }} </h4>

		<h4 *ngIf="(!toDeleteViewDependency) && (selectedNodeStatusActive)" class="modal-title pull-left">{{'AS_CATEGORY.DISABLE_CONFLICTS' | translate  }} - {{ selectedNodeName }} </h4>

		<h4 *ngIf="(!toDeleteViewDependency) && (!selectedNodeStatusActive)" class="modal-title pull-left">{{'AS_CATEGORY.ENABLE_CONFLICTS' | translate  }} - {{ selectedNodeName }} </h4>

        <button type="button" id="image_close" class="close pull-right" aria-label="Close" (click)="modelRefCloseEvent()">
            <span aria-hidden="true">&times;</span>
        </button>
	</div>
	
	<div *ngIf="!showDependencyModel" class="modal-header">
		<h4 class="modal-title pull-left">{{'AS_CATEGORY.DELETE_CONFIRM_MESSAGE' | translate  }} - {{ selectedNodeName }} </h4>
	</div>



    <div  class="modal-body">
		<div *ngIf="!showDependencyModel" >
			<h5 class="text-center">
				<label>{{ 'AS_CATEGORY.NO_ANY_DEPENDENCIES' | translate }}</label>
			</h5>
		</div>

        <div *ngIf="showDependencyModel" class="container">
			<div *ngIf="isDependenComp">
				<h5 class="text-center">
					{{ 'AS_CATEGORY.COMPONENTS' | translate }}
				</h5>
				<!-- <label class="font-weight-bold">{{ 'AS_CATEGORY.COMPONENTS' | translate }} </label> -->

				<div  *ngFor="let items of dependComplist; index as i">
					<h7 class="mx-1" >{{items}}</h7>
					<ul>
						<li *ngFor="let item of dependComplist_2[i]" >
							{{item}}
						</li> 
					</ul>
				</div>
			</div>
			<div *ngIf="isDependSte">
				<h5 class="text-center">
					{{ 'AS_CATEGORY.STATEMENTS' | translate }}
				</h5>
				<!-- <label class="font-weight-bold">{{ 'AS_CATEGORY.STATEMENTS' | translate }} </label> -->
				<div  *ngFor="let items of dependStelist; index as i">
					<h7 class="mx-1" >{{items}}</h7>
					<ul>
						<li *ngFor="let item of dependStelist_2[i]" >
							{{item}}
						</li> 
					</ul>
				</div>
			</div>
			<div *ngIf="isDepnedCheck">
				<h5 class="text-center">
					{{ 'AS_CATEGORY.CHECKS' | translate }}
				</h5>
				<!-- <label class="font-weight-bold">{{ 'AS_CATEGORY.CHECKS' | translate }} </label> -->
				<div  *ngFor="let items of depnedChecklist; index as i">
					<h7 class="mx-1" >{{items}}</h7>
					<ul>
						<li *ngFor="let item of depnedChecklist_2[i]" >
							{{item}}
						</li> 
					</ul>
				</div>
			</div>
			<div *ngIf="isDependenCat">
				<h5 class="text-center">
					{{ 'AS_CATEGORY.CATEGORIES' | translate }}
				</h5>
				<!-- <label class="font-weight-bold">{{ 'AS_CATEGORY.CATEGORIES' | translate }} </label> -->
				<ul>
					<li *ngFor="let cat of dependCatList" >
						{{cat}}
					</li> 
				</ul>		
			</div>
		</div>		
	</div>
	
    <div *ngIf="showDependencyModel"  class="modal-footer">
        <button type="button" class="btn btn-default" (click)="modelRefCloseEvent()">{{'AS_CATEGORY.BTN_TEXT_CANCEL' | translate }}</button>

		<button *ngIf="selectedNodeStatusActive" type="button" class="btn btn-warning" (click)="modelRefDisableEvent('Inactive')">
			{{ 'AS_CATEGORY.BTN_TEXT_DISABLE' | translate  }}</button>

		<button *ngIf="!selectedNodeStatusActive" type="button" class="btn btn-warning" (click)="modelRefDisableEvent('active')">
			{{ 'AS_CATEGORY.BTN_TEXT_ENABLE' | translate  }}</button>
	</div>
	
	<div *ngIf="!showDependencyModel"  class="modal-footer">
			<button type="button" class="btn btn-default" (click)="modelRefCloseEvent()"> {{'AS_CATEGORY.BTN_TEXT_CANCEL' | translate }} </button>
			<!-- btn btn-sm btn btn-secondary -->
			<!-- btn btn-sm btn-primary -->
			<button type="button" class="btn btn-warning" (click)="deleteConfirm()"> {{ 'AS_CATEGORY.OK' | translate  }}</button>
	</div>
</ng-template>