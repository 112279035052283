import { Component, OnInit } from '@angular/core';
import { ViewChild, ViewContainerRef, ComponentRef, ComponentFactoryResolver } from '@angular/core'

import { SharedService } from '../util/shared.service';
import { ReusablesService } from './reusables.service';
import { Constants, UI_LAYOUT, LocalStorageConst, SessionStorageConst } from '../util/constants';

@Component({
	selector: 'app-reusables',
	templateUrl: './reusables.component.html',
	styleUrls: ['./reusables.component.css']
})
export class ReusablesComponent implements OnInit {

	//public uiLayout: string = JSON.parse(localStorage.getItem(LocalStorageConst.UI_LAYOUT))
	public uiLayout: string = JSON.parse(sessionStorage.getItem(SessionStorageConst.UI_LAYOUT))
	public selectedItem: string = ''
	public isOpen: boolean = false;
	private openDocument: any;
	private touchTime = 0
	public tooltipPlacement: string = 'left'
	touchedItem: string 
	public divHeight: number = (window.innerHeight) * 8 / 9

	@ViewChild('componentHost', { read: ViewContainerRef }) componentHost: ViewContainerRef
	compoenntRef: ComponentRef<Component>

	constructor(private sharedService: SharedService, private reusableService: ReusablesService, private resolver: ComponentFactoryResolver) {

	}

	ngOnInit() {
	}

	ngAfterViewInit() {
		//Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
		//Add 'implements AfterViewInit' to the class.
		this.updateComponent(this.selectedItem);
		this.openDocument = SharedService.openReusablepanelRespond().subscribe(option => this.reusableButtonEvent(option.data));
	}

	ngOnDestroy(): void {
		//Called once, before the instance is destroyed.
		//Add 'implements OnDestroy' to the class.
		if (this.compoenntRef) {
			this.compoenntRef.destroy()
		}
		if (this.openDocument) {
			this.openDocument.unsubscribe();
		}
	}

	ngOnChanges() {
		//Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
		//Add 'implements OnChanges' to the class.
		this.updateComponent(this.selectedItem)
	}

	public reusableButtonEvent(event: string) {
		 console.log('[reusableButtonEvent] (event) ' + JSON.stringify(event));
		this.selectedItem = event
		// console.log('event of load',this.selectedItem);
		//let uiLayout = JSON.parse(localStorage.getItem(LocalStorageConst.UI_LAYOUT))
		let uiLayout = JSON.parse(sessionStorage.getItem(SessionStorageConst.UI_LAYOUT))
		// console.log('[reusableButtonEvent] (event) ' + JSON.stringify(uiLayout));
		let newLayout: any = null
		switch (uiLayout) {
			case UI_LAYOUT.UIL_1:
				newLayout = Constants.UI_LAYOUT.UIL_2;
				uiLayout = UI_LAYOUT.UIL_2
				this.isOpen = !this.isOpen
				this.tooltipPlacement = 'left'
				break;
			case UI_LAYOUT.UIL_2:
				newLayout = Constants.UI_LAYOUT.UIL_2;
				uiLayout = UI_LAYOUT.UIL_2
				this.tooltipPlacement = 'left'
				break;
			case UI_LAYOUT.UIL_3:
				newLayout = Constants.UI_LAYOUT.UIL_4;
				uiLayout = UI_LAYOUT.UIL_4
				this.isOpen = !this.isOpen
				this.tooltipPlacement = 'left'
				break;
			case UI_LAYOUT.UIL_4:
				newLayout = Constants.UI_LAYOUT.UIL_4;
				uiLayout = UI_LAYOUT.UIL_4
				this.tooltipPlacement = 'left'
				break;
			case UI_LAYOUT.UIL_6:
				newLayout = Constants.UI_LAYOUT.UIL_6;
				uiLayout = UI_LAYOUT.UIL_6
				this.tooltipPlacement = 'right'
				break;
		}
		//localStorage.setItem(LocalStorageConst.UI_LAYOUT, JSON.stringify(uiLayout))
		sessionStorage.setItem(SessionStorageConst.UI_LAYOUT, JSON.stringify(uiLayout))
		this.uiLayout = uiLayout
		// console.log('[reusableButtonEvent] (this.uiLayout) ' + JSON.stringify(this.uiLayout));
		this.sharedService.expandReusablesRequest(newLayout)

		this.updateComponent(this.selectedItem)

	}

	/**
	 * reusable button event with double click event 
	 * implement to solve double click not working on mobile devices
	 * @param event 
	 */
	public reusableBtnEventWithDoubleClick(event: string) {
		console.log('[reusableBtnEventWithDoubleClick] (event) ' + JSON.stringify(event));
		
		if (this.touchTime == 0) {
			this.reusableButtonEvent(event)
			this.touchTime = new Date().getTime();
			this.touchedItem = event;
		} else {
			if ((((new Date().getTime()) - this.touchTime) < 800) && this.touchedItem == event) {
				this.reusableFS()
				this.touchTime = 0;
			} else {
				this.reusableButtonEvent(event)
				this.touchTime = new Date().getTime();
				this.touchedItem = event;
			}
		}
	}

	public hideReusable() {
		//let uiLayout = JSON.parse(localStorage.getItem(LocalStorageConst.UI_LAYOUT))
		let uiLayout = JSON.parse(sessionStorage.getItem(SessionStorageConst.UI_LAYOUT))
		// console.log('[hideReusable] (uiLayout) ' + JSON.stringify(uiLayout));
		let newLayout: any = null
		switch (uiLayout) {
			case UI_LAYOUT.UIL_1:
				newLayout = Constants.UI_LAYOUT.UIL_1;
				uiLayout = UI_LAYOUT.UIL_1
				break;
			case UI_LAYOUT.UIL_2:
				newLayout = Constants.UI_LAYOUT.UIL_1;
				uiLayout = UI_LAYOUT.UIL_1
				break;
			case UI_LAYOUT.UIL_3:
				newLayout = Constants.UI_LAYOUT.UIL_3;
				uiLayout = UI_LAYOUT.UIL_3
				break;
			case UI_LAYOUT.UIL_4:
				newLayout = Constants.UI_LAYOUT.UIL_3;
				uiLayout = UI_LAYOUT.UIL_3
				break;
			case UI_LAYOUT.UIL_6:
				this.showProcToolbar();
				newLayout = Constants.UI_LAYOUT.UIL_1;
				uiLayout = UI_LAYOUT.UIL_1
				break;
		}
		//localStorage.setItem(LocalStorageConst.UI_LAYOUT, JSON.stringify(uiLayout))
		sessionStorage.setItem(SessionStorageConst.UI_LAYOUT, JSON.stringify(uiLayout))
		this.uiLayout = uiLayout
		this.isOpen = !this.isOpen
		this.selectedItem = ''
		this.sharedService.expandReusablesRequest(newLayout)
		this.tooltipPlacement = 'left'
	}

	private hideProcToolbar() {
		$("div[id^='toolbar_']").each(function (i, el) {
			el.style.display = 'none';
		});
	}

	private showProcToolbar() {
		$("div[id^='toolbar_']").each(function (i, el) {
			el.style.display = 'block';
		});
	}

	/**
	 * full screen reusable event
	 */
	public reusableFS() {
		//let uiLayout = JSON.parse(localStorage.getItem(LocalStorageConst.UI_LAYOUT))
		let uiLayout = JSON.parse(sessionStorage.getItem(SessionStorageConst.UI_LAYOUT))
		// console.log('[reusableFS] (uiLayout) ' + JSON.stringify(uiLayout));
		let newLayout: any = null
		switch (uiLayout) {
			case UI_LAYOUT.UIL_1:
				this.hideProcToolbar();
				newLayout = Constants.UI_LAYOUT.UIL_6;
				uiLayout = UI_LAYOUT.UIL_6
				this.tooltipPlacement = 'right'
				break;
			case UI_LAYOUT.UIL_2:
				this.hideProcToolbar();
				newLayout = Constants.UI_LAYOUT.UIL_6;
				uiLayout = UI_LAYOUT.UIL_6
				this.tooltipPlacement = 'right'
				break;
			case UI_LAYOUT.UIL_3:
				this.hideProcToolbar();
				newLayout = Constants.UI_LAYOUT.UIL_6;
				uiLayout = UI_LAYOUT.UIL_6
				this.tooltipPlacement = 'right'
				break;
			case UI_LAYOUT.UIL_4:
				this.hideProcToolbar();
				newLayout = Constants.UI_LAYOUT.UIL_6;
				uiLayout = UI_LAYOUT.UIL_6
				this.tooltipPlacement = 'right'
				break;
			case UI_LAYOUT.UIL_6:
				this.showProcToolbar();
				newLayout = Constants.UI_LAYOUT.UIL_2;
				uiLayout = UI_LAYOUT.UIL_2
				this.tooltipPlacement = 'left'
				break;
		}
		//localStorage.setItem(LocalStorageConst.UI_LAYOUT, JSON.stringify(uiLayout))
		sessionStorage.setItem(SessionStorageConst.UI_LAYOUT, JSON.stringify(uiLayout))
		this.uiLayout = uiLayout
		// console.log('[reusableFS] (this.uiLayout) ' + JSON.stringify(this.uiLayout));
		this.sharedService.expandReusablesRequest(newLayout)
		this.updateComponent(this.selectedItem)

	}

	private updateComponent(componentName: string) {
		console.log("update" + componentName)				
		let componentToLoad: any = this.reusableService.loadComponentByName(componentName)

			if (componentToLoad != undefined) {
					this.componentHost.clear()	
					const authFormFactory = this.resolver.resolveComponentFactory(componentToLoad)
					const component = this.componentHost.createComponent(authFormFactory)
			}		
	}

}
