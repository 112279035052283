import { Injectable } from '@angular/core';
import { UserVariable } from 'src/app/util/common/user-variable';
import { CommonUtil } from 'src/app/util/common/common-util';
import { HttpClient, HttpParams, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { ServiceUrls } from 'src/app/util/service-urls';
import { map, catchError } from 'rxjs/operators';
import { WsResponse } from 'src/app/util/ws-response.model';
import { throwError } from 'rxjs';
import { WsCallback } from 'src/app/util/ws-callback.interface';
import { WsType } from 'src/app/util/ws-type';

@Injectable({
    providedIn: 'root'
})
export class ReceiveChecksheetService {
    constructor(private http: HttpClient, private commonUtil: CommonUtil) {

    }

    getReceiveCheckSheetFilterResult(csVendors: string, checksheets: string, csAssets: string, csDescriptions: string, csSystems: string,
        csAssetGroups: string, csPhases: string, csFunctionCodes: string, hasText: string) {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }

        let url = ServiceUrls.getReceiveCheckSheetFilterResult(token);
        return this.http.post(url, JSON.stringify(new ChecksheetFilterRequest(csVendors, checksheets, csAssets, csDescriptions, csSystems,
            csAssetGroups, csPhases, null, csFunctionCodes, hasText))).
            pipe(
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                }),
                catchError(error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    return throwError(res)
                })
            );
    }

    getChecksheetInstanceData(csInstanceId: string, wsCallBack: WsCallback) {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }

        let url = ServiceUrls.getChecksheetInstanceData(token);
        this.http.post(url, new HttpParams().set("csInstanceId", csInstanceId)).subscribe(response => {
            var modified = JSON.parse(JSON.stringify(response));
            var res = new WsResponse(
                modified.status.description,
                modified.status.name,
                modified.status.code,
                modified.payload);
            wsCallBack.onSuccess(res, WsType.GET_CHECKSHEET_INSTANCE_DATA);
        }, error => {
            if (error.status != '') {
                var val = (error as HttpErrorResponse).error;
                var modified = JSON.parse(JSON.stringify(val));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                wsCallBack.onFail(res, WsType.GET_CHECKSHEET_INSTANCE_DATA);
            } else {
                //browser related issues
                var res = new WsResponse("Unknown error happened");
                wsCallBack.onFail(res, WsType.GET_CHECKSHEET_INSTANCE_DATA);
            }
        });
    }

    saveChecksheetInstanceData(data: string, wsCallBack: WsCallback) {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }

        let url = ServiceUrls.saveChecksheetInstanceData(token);
        this.http.post(url, data).subscribe(response => {
            var modified = JSON.parse(JSON.stringify(response));
            var res = new WsResponse(
                modified.status.description,
                modified.status.name,
                modified.status.code,
                modified.payload);
            wsCallBack.onSuccess(res, WsType.SAVE_CHECKSHEET_INSTANCE_DATA);
        },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    wsCallBack.onFail(res, WsType.SAVE_CHECKSHEET_INSTANCE_DATA);
                } else {
                    //browser related issues
                    var res = new WsResponse("Unknown error happened");
                    wsCallBack.onFail(res, WsType.SAVE_CHECKSHEET_INSTANCE_DATA);
                }
            });
    }

    public uploadFile(fileUploadData: FormData, callBack: WsCallback) {
        var token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
        this.http.post(ServiceUrls.ADD_NEW_CS_DOCUMENT, fileUploadData, {
            reportProgress: true,
            observe: 'events'
        }).subscribe(
            event => {
                if (event.type == HttpEventType.UploadProgress) {
                    //progress
                } else if (event.type == HttpEventType.Response) {
                    var modified = JSON.parse(JSON.stringify(event.body));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onSuccess(res, WsType.ADD_NEW_CS_DOCUMENT);
                }
            },
            error => {
                if (error.error.payload != undefined) {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.ADD_NEW_CS_DOCUMENT);
                } else {
                    var res: WsResponse;
                    if (error.error.actionErrors != undefined) {
                        res = new WsResponse(error.statusText + ' - ' + error.error.actionErrors);
                    } else {
                        res = new WsResponse(error.message);
                    }
                    callBack.onFail(res, WsType.ADD_NEW_CS_DOCUMENT);
                }
            }
        );
    }

    downloadSentFile(projectId: string, csInstanceId: string) {
        return this.http.get(ServiceUrls.DOWNLOAD_FILE,
            {
                responseType: "blob", params: new HttpParams().set("projectId", projectId)
                    .set("documentId", csInstanceId).set("mode", "ViewCommOutput").set("type", "CheckSheet").set("fileName", "")
            });
    }

    downloadApprovalFile(projectId: string, csInstanceId: string) {
        return this.http.get(ServiceUrls.DOWNLOAD_FILE,
            {
                responseType: "blob", params: new HttpParams().set("projectId", projectId).set("documentId", csInstanceId)
                    .set("mode", "ViewApprovedOutput").set("type", "CheckSheet").set("fileName", "")
            });
    }
}

class ChecksheetFilterRequest {
    constructor(private csVendors: string, private checksheets: string, private csAssets: string, private csDescriptions: string,
        private csSystems: string, private csAssetGroups: string, private csPhases: string, private csAssetParents: string,
        private csFunctionCodes: string, private hasText: string) { }
}