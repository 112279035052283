import { Component, OnInit, ViewChild, TemplateRef , ElementRef} from '@angular/core';
import { SharedService } from '../../util/shared.service';
import { ProjectService } from '../project.service';
import { LoadingService } from '../../util/loading/loading.service';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { DependenciesmodalComponent } from '../../util/dependenciesmodal/dependenciesmodal.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { WsCallback } from 'src/app/util/ws-callback.interface';
import { WsResponse } from 'src/app/util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';
import { AlertService } from '../../util/alert/alert.service';
import { Constants } from "src/app/util/constants";

@Component({
	selector: 'app-edit-project',
	templateUrl: './edit-project.component.html',
	styleUrls: ['./edit-project.component.css']
})
export class EditProjectComponent implements OnInit {

	@ViewChild('checkingTemplate') checkingTemplate: TemplateRef<any>
	@ViewChild('cmmsTracking') cmmsTracking: ElementRef;
	public project: any;
	editProjectForm: FormGroup;
	public clientList: Array<any>;
	public projectTypes: Array<any> = [];
	public selctedProjectType: Array<any>;
	public selctedElement: Array<any>;
	public mask1: Array<any>;
	public mask2: Array<any>;
	public mask3: Array<any>;
	public mask4: Array<any>;
	public mask5: Array<any>;
	public mask6: Array<any>;
	public mask7: Array<any>;
	public mask8: Array<any>;
	public mask9: Array<any>;
	public mask10: Array<any>;
	checkAutoGenerate: boolean;
	allowDifferentProcInitiatedUsers: boolean;
	equipmentNo: any;
	isCmmsTracking: boolean;
	hasDependencies: boolean;
	numberofMasks: any;
	bsModalRef: BsModalRef;
	dependencyType: string;
	//isChecked: boolean = true;
	public selectedCharacter1: any = "";
	public selectedCharacter2: any = "";
	public selectedCharacter3: any = "";
	public selectedCharacter4: any = "";
	public selectedCharacter5: any = "";
	public selectedCharacter6: any = "";
	public selectedCharacter7: any = "";
	public selectedCharacter8: any = "";
	public selectedCharacter9: any = "";
	public selectedCharacter10: any = "";
	public elements: Array<any> = [];
	public selectedElements: Array<any>;
	public selectedElement: any = 0;
	public selectedProjectType: any = "";
	public characterPerElement: Array<any> = [];
	public currentProjectName: any;
	public nameEmpty: any;
	public msgStartingEquipmentNumber: any;
	public msgNoOfElements: any;
	public msgNoOfCharacters: any;
	public msgClient: any;
	public msgProjectType: any;
	public additionalNotifiedParty: any;

	constructor(private sharedService: SharedService, private translate: TranslateService,
		private projectService: ProjectService, private modalService: BsModalService, private formBuilder: FormBuilder,
		private loadingService: LoadingService, private router: Router, private alertService: AlertService) {

		translate.addLangs(["es", "en"]);
		translate.setDefaultLang("en");
		let browserLang = translate.getBrowserLang();
		translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');

		this.translate.get('EDIT_PROJECT.NAME_EMPTY').subscribe((res: string) => {
			this.nameEmpty = res;
			this.translate.get('EDIT_PROJECT.MSG_STARTING_EQUIPMENT_NUMBER').subscribe((res: string) => {
				this.msgStartingEquipmentNumber = res;
				this.translate.get('EDIT_PROJECT.MSG_NO_OF_ELEMENTS').subscribe((res: string) => {
					this.msgNoOfElements = res;
					this.translate.get('EDIT_PROJECT.MSG_NO_OF_CHARACTERS').subscribe((res: string) => {
						this.msgNoOfCharacters = res;
						this.translate.get('EDIT_PROJECT.MSG_SELECT_CLIENT').subscribe((res: string) => {
							this.msgClient = res;
							this.translate.get('EDIT_PROJECT.MSG_PROJECT_TYPE').subscribe((res: string) => {
								this.msgProjectType = res;
							});
						});
					});
				});
			});
		});

		this.projectTypes = [
			{ 'id': '1', 'text': 'OA' },
			{ 'id': '2', 'text': 'COM' },
			{ 'id': '3', 'text': 'Both' }
		];

		this.elements = [
			{ 'id': '1', 'text': '1' },
			{ 'id': '2', 'text': '2' },
			{ 'id': '3', 'text': '3' },
			{ 'id': '4', 'text': '4' },
			{ 'id': '5', 'text': '5' },
			{ 'id': '6', 'text': '6' },
			{ 'id': '7', 'text': '7' },
			{ 'id': '8', 'text': '8' },
			{ 'id': '9', 'text': '9' },
			{ 'id': '10', 'text': '10' }
		];

		this.characterPerElement = [
			{ 'id': '1', 'text': '1' },
			{ 'id': '2', 'text': '2' },
			{ 'id': '3', 'text': '3' },
			{ 'id': '4', 'text': '4' },
			{ 'id': '5', 'text': '5' },
			{ 'id': '6', 'text': '6' },
			{ 'id': '7', 'text': '7' },
			{ 'id': '8', 'text': '8' },
			{ 'id': '9', 'text': '9' },
			{ 'id': '10', 'text': '10' },
			{ 'id': '11', 'text': '11' },
			{ 'id': '12', 'text': '12' },
			{ 'id': '13', 'text': '13' },
			{ 'id': '14', 'text': '14' },
			{ 'id': '15', 'text': '15' },
			{ 'id': '16', 'text': '16' },
			{ 'id': '17', 'text': '17' },
			{ 'id': '18', 'text': '18' },
			{ 'id': '19', 'text': '19' },
			{ 'id': '20', 'text': '20' }
		];


	}

	ngOnInit() {
		this.editProjectForm = this.formBuilder.group({
			projectName: new FormControl(null),
			clientList: new FormControl(null),
			projectType: new FormControl(null),
			additionalNotifiedParty: new FormControl(null),	
			checkAutoGenerated: new FormControl(null),
			allowDifferentProcInitiatedUsers: new FormControl(null),
			equipmentNumber: new FormControl(null),
			cmmsTracking: new FormControl(null),
			numOfElements: new FormControl(null),
			characters1: new FormControl(null),
			characters2: new FormControl(null),
			characters3: new FormControl(null),
			characters4: new FormControl(null),
			characters5: new FormControl(null),
			characters6: new FormControl(null),
			characters7: new FormControl(null),
			characters8: new FormControl(null),
			characters9: new FormControl(null),
			characters10: new FormControl(null),

		});

		if (this.sharedService.getFileId() != "-1" && this.sharedService.getFileId() != -1) {
			this.loadingService.showLoading(true, true, "Loading", 100);
			this.projectService.getProject(this.sharedService.getFileId()).subscribe(
				data => {
					if (data.payload != null) {
						this.isCmmsTracking = data.payload.usedInCMMSTrac;
						this.numberofMasks = data.payload.noOfMasks;
						this.loadingService.hideLoading();
						this.project = data.payload;
						this.checkAutoGenerate = data.payload.autoGenerateEquipNo;
						this.hasDependencies = data.payload.hasTagdependency;
						//this.checkAutoGenerate = data.payload.autoGenerateEquipNo;
						this.currentProjectName = data.payload.projectName;
						this.additionalNotifiedParty = data.payload.additionalNotifiedParty;
						this.allowDifferentProcInitiatedUsers = data.payload.allowDifferentProcInitiatedUsers;

						let list: Array<any> = [];
						list.push(
							{ id: 0, text: data.payload.clientName }
						);

						this.clientList = list;
						this.editProjectForm.controls['clientList'].setValue(this.clientList);
						let selectedProjectType = ""
						if (data.payload.projectType == 1) {
							this.selectedProjectType = "OA";
							selectedProjectType = "OA";
						} else if (data.payload.projectType == 2) {
							this.selectedProjectType = "COM";
							selectedProjectType = "COM";
						}
						else {
							this.selectedProjectType = "Both";
							selectedProjectType = "Both";
						}

						let projectTypeList: Array<any> = [];
						projectTypeList.push(
							{ id: 0, text: selectedProjectType }
						);
						this.selctedProjectType = projectTypeList;
						this.selectedProjectType = data.payload.projectType;

						this.editProjectForm.controls['projectType'].setValue(this.selctedProjectType);
						//if (this.isCmmsTracking) {
							let elementTypeList: Array<any> = [];
							elementTypeList.push(
								{ id: data.payload.noOfMasks, text: data.payload.noOfMasks }
							);
							console.log('[elementTypeList] ' + JSON.stringify(elementTypeList));

							this.selctedElement = elementTypeList;
							this.editProjectForm.controls['numOfElements'].setValue(this.selctedElement);
							if (this.numberofMasks > 0) {
								let mask: Array<any> = [];
								mask.push(
									{ id: 0, text: data.payload.cmmsMaskSpecificationInfo[0].charactersPerElement }
								);
								this.selectedCharacter1 = data.payload.cmmsMaskSpecificationInfo[0].charactersPerElement;
								this.mask1 = mask;
								this.editProjectForm.controls['characters1'].setValue(this.mask1);
								console.log('[mask] ' + JSON.stringify(mask));
							}
							if (this.numberofMasks > 1) {
								let mask: Array<any> = [];
								mask.push(
									{ id: 0, text: data.payload.cmmsMaskSpecificationInfo[1].charactersPerElement }
								);
								this.selectedCharacter2 = data.payload.cmmsMaskSpecificationInfo[1].charactersPerElement;
								this.mask2 = mask;
								this.editProjectForm.controls['characters2'].setValue(this.mask2);
								console.log('[mask] ' + JSON.stringify(mask));
							}
							if (this.numberofMasks > 2) {
								let mask: Array<any> = [];
								mask.push(
									{ id: 0, text: data.payload.cmmsMaskSpecificationInfo[2].charactersPerElement }
								);
								this.selectedCharacter3 = data.payload.cmmsMaskSpecificationInfo[2].charactersPerElement;
								this.mask3 = mask;
								this.editProjectForm.controls['characters3'].setValue(this.mask3);
								console.log('[mask] ' + JSON.stringify(mask));
							}
							if (this.numberofMasks > 3) {
								let mask: Array<any> = [];
								mask.push(
									{ id: 0, text: data.payload.cmmsMaskSpecificationInfo[3].charactersPerElement }
								);
								this.selectedCharacter4 = data.payload.cmmsMaskSpecificationInfo[3].charactersPerElement;
								this.mask4 = mask;
								this.editProjectForm.controls['characters4'].setValue(this.mask4);
								console.log('[mask] ' + JSON.stringify(mask));
							}
							if (this.numberofMasks > 4) {
								let mask: Array<any> = [];
								mask.push(
									{ id: 0, text: data.payload.cmmsMaskSpecificationInfo[4].charactersPerElement }
								);
								this.selectedCharacter5 = data.payload.cmmsMaskSpecificationInfo[4].charactersPerElement;
								this.mask5 = mask;
								this.editProjectForm.controls['characters5'].setValue(this.mask5);
								console.log('[mask] ' + JSON.stringify(mask));
							}
							if (this.numberofMasks > 5) {
								let mask: Array<any> = [];
								mask.push(
									{ id: 0, text: data.payload.cmmsMaskSpecificationInfo[5].charactersPerElement }
								);
								this.selectedCharacter6 = data.payload.cmmsMaskSpecificationInfo[5].charactersPerElement;
								this.mask6 = mask;
								this.editProjectForm.controls['characters6'].setValue(this.mask6);
								console.log('[mask] ' + JSON.stringify(mask));
							}
							if (this.numberofMasks > 6) {
								let mask: Array<any> = [];
								mask.push(
									{ id: 0, text: data.payload.cmmsMaskSpecificationInfo[6].charactersPerElement }
								);
								this.selectedCharacter7 = data.payload.cmmsMaskSpecificationInfo[6].charactersPerElement;
								this.mask7 = mask;
								this.editProjectForm.controls['characters7'].setValue(this.mask7);
								console.log('[mask] ' + JSON.stringify(mask));
							}
							if (this.numberofMasks > 7) {
								let mask: Array<any> = [];
								mask.push(
									{ id: 0, text: data.payload.cmmsMaskSpecificationInfo[7].charactersPerElement }
								);
								this.selectedCharacter8 = data.payload.cmmsMaskSpecificationInfo[7].charactersPerElement;
								this.mask8 = mask;
								this.editProjectForm.controls['characters8'].setValue(this.mask8);
								console.log('[mask] ' + JSON.stringify(mask));
							}
							if (this.numberofMasks > 8) {
								let mask: Array<any> = [];
								mask.push(
									{ id: 0, text: data.payload.cmmsMaskSpecificationInfo[8].charactersPerElement }
								);
								this.selectedCharacter9 = data.payload.cmmsMaskSpecificationInfo[8].charactersPerElement;
								this.mask9 = mask;
								this.editProjectForm.controls['characters9'].setValue(this.mask9);
								console.log('[mask] ' + JSON.stringify(mask));
							}
							if (this.numberofMasks > 9) {
								let mask: Array<any> = [];
								mask.push(
									{ id: 0, text: data.payload.cmmsMaskSpecificationInfo[9].charactersPerElement }
								);
								this.selectedCharacter10 = data.payload.cmmsMaskSpecificationInfo[9].charactersPerElement;
								this.mask10 = mask;
								this.editProjectForm.controls['characters10'].setValue(this.mask10);
								console.log('[mask] ' + JSON.stringify(mask));
							}
						//}


						console.log("this.selctedElement" + data.payload.noOfMasks)

					}
				},
				error => {
					//this.alertService.clear()
					//this.alertService.error(error.statusDescription)
				});
		}
	}

	projectTypeSelected(event: any) {
		this.selectedProjectType = event["id"]
	}

	save() {
		console.log("this.isCmmsTracking " + this.isCmmsTracking + "this.editProjectForm.controls.numOfElements.value" + this.editProjectForm.controls.numOfElements.value)
		if(this.editProjectForm.controls.projectName.value == "" || this.editProjectForm.controls.projectName.value == null)
		{
			this.alertService.clear();
			this.alertService.error(this.nameEmpty);
		} else if (this.editProjectForm.controls.clientList.value == "" || this.editProjectForm.controls.clientList.value == null) {
			this.alertService.clear();
			this.alertService.error(this.msgClient);
		} else if(this.editProjectForm.controls.projectType.value == "" || this.editProjectForm.controls.projectType.value== null || this.selectedProjectType == "" || this.selectedProjectType == null) {
			this.alertService.clear();
			this.alertService.error(this.msgProjectType);
		}
		else if( this.checkAutoGenerate && (this.editProjectForm.controls.equipmentNumber.value == "" || this.editProjectForm.controls.equipmentNumber.value == null) )
		{
			this.alertService.clear();
			this.alertService.error(this.msgStartingEquipmentNumber);
		}
		else if(this.isCmmsTracking && (this.editProjectForm.controls.numOfElements.value == null || this.editProjectForm.controls.numOfElements.value == ""))
		{
			this.alertService.clear();
			this.alertService.error(this.msgNoOfElements);
		}
		else if(this.editProjectForm.controls.additionalNotifiedParty.invalid){
			this.alertService.clear();
			this.alertService.error("Please enter a valid e-mail address");
		}
		else
		{
			if(this.selectedElement > 0 && this.selectedCharacter1 == "")
			{
				this.alertService.clear();
			this.alertService.error(this.msgNoOfCharacters);
			}else if(this.selectedElement > 1 && this.selectedCharacter2 == "")
			{
				this.alertService.clear();
			this.alertService.error(this.msgNoOfCharacters);
			}else if(this.selectedElement > 2 && this.selectedCharacter3 == "")
			{
				this.alertService.clear();
			this.alertService.error(this.msgNoOfCharacters);
			}else if(this.selectedElement > 3 && this.selectedCharacter4 == "")
			{
				this.alertService.clear();
			this.alertService.error(this.msgNoOfCharacters);
			}else if(this.selectedElement > 4 && this.selectedCharacter5 == "")
			{
				this.alertService.clear();
			this.alertService.error(this.msgNoOfCharacters);
			}else if(this.selectedElement > 5 && this.selectedCharacter6 == "")
			{
				this.alertService.clear();
			this.alertService.error(this.msgNoOfCharacters);
			}else if(this.selectedElement > 6 && this.selectedCharacter7 == "")
			{
				this.alertService.clear();
			this.alertService.error(this.msgNoOfCharacters);
			}else if(this.selectedElement > 7 && this.selectedCharacter8 == "")
			{
				this.alertService.clear();
			this.alertService.error(this.msgNoOfCharacters);
			}else if(this.selectedElement > 8 && this.selectedCharacter9 == "")
			{
				this.alertService.clear();
			this.alertService.error(this.msgNoOfCharacters);
			}else if(this.selectedElement > 9 && this.selectedCharacter10 == "")
			{
				this.alertService.clear();
			this.alertService.error(this.msgNoOfCharacters);
			}
			else{
		let projectId = this.project.id;
		let projectName = this.editProjectForm.controls.projectName.value;
		///let projectType = this.editProjectForm.controls.projectType.value.id;
		//console.log("projectType " + projectType)
		//this.currentProjectName
		//this.isCmmsTracking
		//this.numberofMasks
		let cmmsNumbers = "";
		for (let i = 0; i < this.numberofMasks; i++) {
			switch (i) {
				case 0:
					cmmsNumbers = this.selectedCharacter1;
					//if()
					break;
				case 1:
					cmmsNumbers = cmmsNumbers + "," + this.selectedCharacter2;
					break;
				case 2:
					cmmsNumbers = cmmsNumbers + "," + this.selectedCharacter3;
					break;
				case 3:
					cmmsNumbers = cmmsNumbers + "," + this.selectedCharacter4;
					break;
				case 4:
					cmmsNumbers = cmmsNumbers + "," + this.selectedCharacter5;
					break;
				case 5:
					cmmsNumbers = cmmsNumbers + "," + this.selectedCharacter6;
					break;
				case 6:
					cmmsNumbers = cmmsNumbers + "," + this.selectedCharacter7;
					break;
				case 7:
					cmmsNumbers = cmmsNumbers + "," + this.selectedCharacter8;
					break;
				case 8:
					cmmsNumbers = cmmsNumbers + "," + this.selectedCharacter9;
					break;
				case 9:
					cmmsNumbers = cmmsNumbers + "," + this.selectedCharacter10;
					break;
				default:
					break;

			}

		}

		//this.checkAutoGenerate
		let startingEquipmentNo = this.editProjectForm.controls.equipmentNumber.value != null ? this.editProjectForm.controls.equipmentNumber.value : null
		let additionalNotifiedPartyEmail = this.editProjectForm.controls.additionalNotifiedParty.value;
		if (additionalNotifiedPartyEmail == "") {
			additionalNotifiedPartyEmail = null;
		}
		if(this.isCmmsTracking)
		{
		this.alertService.clear();
		this.projectService.updateProject(projectId, projectName, this.selectedProjectType,additionalNotifiedPartyEmail,
			 this.currentProjectName, this.isCmmsTracking, this.numberofMasks,
			cmmsNumbers, this.checkAutoGenerate, startingEquipmentNo, this.allowDifferentProcInitiatedUsers, this);
		this.loadingService.showLoading(true,null,"Saving..",null);
		}else{
			this.alertService.clear();
			this.projectService.updateProject(projectId, projectName, this.selectedProjectType,additionalNotifiedPartyEmail,
				 this.currentProjectName, this.isCmmsTracking, "",
				"", this.checkAutoGenerate, startingEquipmentNo, this.allowDifferentProcInitiatedUsers, this);
			this.loadingService.showLoading(true,null,"Saving..",null);
		}
		}
	}
	}

	elementSelected(event: any) {
		this.selectedElement = event["text"];
		this.numberofMasks = event["text"];
		console.log("this.numberofMasks" + this.numberofMasks)
	}

	characterSelected1(event: any) {
		this.selectedCharacter1 = event["text"]
		console.log("this.selectedCharacter1  " + this.selectedCharacter1)
	}

	characterSelected2(event: any) {
		this.selectedCharacter2 = event["text"]
	}

	characterSelected3(event: any) {
		this.selectedCharacter3 = event["text"]
	}

	characterSelected4(event: any) {
		this.selectedCharacter4 = event["text"]
	}

	characterSelected5(event: any) {
		this.selectedCharacter5 = event["text"]
	}

	characterSelected6(event: any) {
		this.selectedCharacter6 = event["text"]
	}

	characterSelected7(event: any) {
		this.selectedCharacter7 = event["text"]
	}

	characterSelected8(event: any) {
		this.selectedCharacter8 = event["text"]
	}

	characterSelected9(event: any) {
		this.selectedCharacter9 = event["text"]
	}

	characterSelected10(event: any) {
		this.selectedCharacter10 = event["text"]
	}

	public invoke() {
		this.viewDependencies(false);
	}

	viewDependencies(dflag: boolean) {
		this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.VIEW;
		this.dependenciesModalWithComponent("Dependencies List", dflag, true);
	}

	dependenciesModalWithComponent(header: String, dflag: boolean, cflag: boolean) {
		const initialState : object = {
			headerTitle: header,
			delFlag: dflag,
			cancelFlag: cflag,
			checkboxVal: false
		};
		// console.log("this.data " + this.data)
		//this.sharedService.setFileId(this.data);
		this.sharedService.setFileType(Constants.DEPENDENCY_ITEM_TYPE.PROJECT);
		this.sharedService.setDependencyMessageType(this.dependencyType);
		this.sharedService.setParent(this);
		this.bsModalRef = this.modalService.show(DependenciesmodalComponent, { initialState, class: 'modal-lg' });
	}


	CheckAutoGenerated(event: any) {
		if (event == true) {
			this.checkAutoGenerate = true;
		}
		else {
			this.checkAutoGenerate = false;
		}
	}

	checkAllowDifferentProcInitiatedUsers(event: any) {
		if (event == true) {
			this.allowDifferentProcInitiatedUsers = true;
		}
		else {
			this.allowDifferentProcInitiatedUsers = false;
		}
	}

	CheckCmmsTracking(event: any) {
		console.log(event)
		if (event == true) {
			console.log("cmms ")
			this.isCmmsTracking = true;
		}
		else {
			console.log("cmms 123")
			this.isCmmsTracking = false;
			if (this.hasDependencies) {
				this.bsModalRef = this.modalService.show(this.checkingTemplate, { class: 'modal-sm' })
				//this.isChecked = true;
				//this.isCmmsTracking = true;
			} else {
				//this.isCmmsTracking = false;

			}
		}	//break;

	}

	check() {
		//this.isChecked = true;
		//console.log("this.isChecked" + this.isChecked)
		this.isCmmsTracking = true;
		//this.editProjectForm.controls.cmmsTracking["checked"] = true;
	}

	uncheck() {
		this.isCmmsTracking = false;
		//this.isChecked = false;
		//console.log("this.isChecked" + this.isChecked)
	}
	
	projectTypeRemoved() {
		this.selectedProjectType = null;
	}

	onSuccess(data: WsResponse, serviceType: WsType) {
		console.log("on success")
		if (serviceType == WsType.ADD_PROJECT) {
			//this.router.navigateByUrl("project");
			this.alertService.clear();
			this.alertService.success("Project edited successfully");
			this.loadingService.hideLoading();
			this.sharedService.viewSideBarEventEmit("projects")
		}
		if (serviceType == WsType.EDIT_PROJECT) {
			//this.router.navigateByUrl("project");
			this.alertService.clear();
			this.alertService.success("Project edited successfully");
			this.loadingService.hideLoading();
			this.sharedService.viewSideBarEventEmit("projects")
		}
	}

	onFail(data: WsResponse, serviceType: WsType) {
		console.log("on fail")
		if (serviceType == WsType.ADD_PROJECT) {
			this.alertService.clear();
			this.alertService.error(data.statusDescription);
			this.loadingService.hideLoading();
		}
		if (serviceType == WsType.EDIT_PROJECT) {
			this.alertService.clear();
			this.alertService.error(data.statusDescription);
			this.loadingService.hideLoading();
		}
	}


	cancel() {
		//this.router.navigateByUrl("project")
		this.alertService.clear();
		this.sharedService.viewSideBarEventEmit("projects")
	}

}
