<!-- <alert></alert> -->
<div class="modal-header py-2">
	<h5 class="modal-title">
		{{ 'FACILITY_ADD_EDIT.FACILITIES' | translate }}
	</h5>
	<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>

<div class="modal-body">
	<!-- Dependency Details -->
	<div *ngIf="facility!=null" class="card p-0 mx-auto col-sm-12">
		<div class="card-body py-2 mx-auto">
			<label class="mb-1 col-sm-12">
				{{facility.referenceCount}} {{ 'STATEMENT_ADD_EDIT.PLACES' | translate }},
				{{ 'STATEMENT_ADD_EDIT.IN' | translate }} {{facility.dependencyCount}}
				{{ 'STATEMENT_ADD_EDIT.TOPICS' | translate }}, {{ 'STATEMENT_ADD_EDIT.IN' | translate }}
				{{facility.procDependencyCount}}
				{{ 'STATEMENT_ADD_EDIT.PROCEDURES' | translate }}, {{ 'STATEMENT_ADD_EDIT.IN' | translate }}
				{{facility.iceCourseDependencyCount}}
				{{ 'STATEMENT_ADD_EDIT.ICECOURSES' | translate }}
			</label>
			<label class="mb-0 col-sm-12">
				<span class="badge border-badge-danger mx-2 py-2"
					tooltip="{{ 'STATEMENT_ADD_EDIT.CHECKED_OUT' | translate }}">
					<i class="fa fa-upload mx-2 fa-lg" aria-hidden="true"></i>
					<label class="mx-2 mb-0">{{facility.checkedOutTopicCount}}</label>
				</span>
				<span class="badge border-badge-success mx-2 py-2"
					tooltip="{{ 'STATEMENT_ADD_EDIT.CHECKED_IN' | translate }}">
					<i class="fa fa-download mx-2 fa-lg" aria-hidden="true"></i>
					<label class="mx-2 mb-0">{{facility.checkedInTopicCount}}</label>
				</span>
			</label>			
		</div>
	</div>

	<form class="mt-2">			
		<!-- facility name -->
		<div class="form-group mb-2">
			<label for="facilityName" class="mb-0">{{ 'FACILITY_ADD_EDIT.FACILITY_NAME' | translate }}</label>
			<div class="badge-required mx-2"></div>
			<input *ngIf="facility!=null" id="facility_edit_name" type="text" class="form-control form-control-sm"
				id="facilityName" name="facilityName" [(ngModel)]="facility.facilityName">
			<input #newName *ngIf="facility==null" id="facility_add_name" type="text"
				class="form-control form-control-sm" id="facilityName">
			<div *ngIf="reusable" class="card bg-danger my-2">
				<div class="card-body py-1 px-2">
					<p class="card-text my-1">{{reusablesName}}</p>
					<p class="card-text my-1">{{reusablesModifiedBy}}</p>
					<p class="card-text my-1">{{reusableModifiedTime}}</p>
				</div>
			</div>
		</div>
		
		<!-- email -->
		<div class="form-group mb-2">
			<label for="email" class="mb-0">{{ 'FACILITY_ADD_EDIT.EMAIL_ADDRESS' | translate }}</label>
			<input *ngIf="facility!=null" id="facility_edit_email" type="text" class="form-control form-control-sm"
				id="email" name="email" [(ngModel)]="facility.emailAddress">
			<input #newEmail *ngIf="facility==null" id="facility_add_email" type="text"
				class="form-control form-control-sm" id="email">
		</div>
		<!-- secondary email -->
		<div class="form-group mb-2">
			<label for="secondaryEmail"
				class="mb-0">{{ 'FACILITY_ADD_EDIT.SECONDARY_EMAIL_ADDRESS' | translate }}</label>
			<input *ngIf="facility!=null" id="facility_edit_semail" type="text" class="form-control form-control-sm"
				id="secondaryEmail" name="secondaryEmail" [(ngModel)]="facility.secondaryEmailAddress">
			<input #newsecondaryEmail *ngIf="facility==null" id="facility_add_semail" type="text"
				class="form-control form-control-sm" id="secondaryEmail">
		</div>
	</form>
</div>
<div class="modal-footer">
	<button type="button" id="facility_cancel" class="btn btn-secondary btn-sm float-left"
		(click)="cancel()">{{ 'CANCEL' | translate}}</button>
	<button type="submit" id="facility_save" class="btn btn-primary btn-sm float-left"
		(click)="save()">{{ 'SAVE' | translate}}</button>

</div>