import { OnInit, Component, Input } from '@angular/core';
import { Module } from '@ag-grid-community/core';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { TranslateService } from '@ngx-translate/core';
import { WorkflowService } from '../workflow.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UserVariable } from 'src/app/util/common/user-variable';
import { AlertService } from 'src/app/util/alert/alert.service';
import { ProjectWorflowTemplateMap } from '../projectworkflowtemplate.model';
import { LoadingService } from 'src/app/util/loading/loading.service';
import { WsResponse } from 'src/app/util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';
import xml2js from 'xml2js'; 
import { SharedService } from 'src/app/util/shared.service';

@Component({
    selector: 'app-configure-workflow-template',
    templateUrl: './configure-workflow.component.html'
})
export class ConfigureWorkflowTemplateComponent implements OnInit{
    public projWFTemplate: ProjectWorflowTemplateMap = new ProjectWorflowTemplateMap();
    modules: Module[] = AllModules;
    public hasTasks: boolean = false;
    public userList:any[] = [];
    selectedPM: string = null;
    public selectedUser: Array<any> = [];
    public selectedProjectManager:Array<any> = [];

    constructor(private translate: TranslateService, private workflowService: WorkflowService, private bsModalRef: BsModalRef,
        private alertService: AlertService, public loadingService: LoadingService, private sharedService: SharedService){
           
    }

    @Input() taskList: any
    @Input() templateId: string
    @Input() projectWorkflowTemplateId: string
    @Input() configParameters: string

    ngOnInit(){
        this.getActiveUsers();
    }

    /**
     * Get workflow tasks by the project workflow template id
     * @param projectWfTemplateId 
     */
    getTasksByTemplateId(projectWfTemplateId: string){
        this.taskList = []
        this.workflowService.getTasksByTemplateId(projectWfTemplateId).subscribe(data => {
            if(data.payload){
                data.payload.forEach(task => {
                    this.getSelectedUser(task); //get the selected user
                    this.taskList.push({id: task.id, taskName: task.taskName, orderNo: task.orderNo, selected: this.selectedUser});
                    this.projWFTemplate.tasks.push({id: task.id, templateId: task.templateId, projectId: task.projectId, processName: task.processName, taskName: task.taskName, automatic: task.automatic, role: null, userId: task.userId, orderNo: task.orderNo});
                })
            }
        }, error => {
            this.alertService.clear()
			this.alertService.error(error.statusDescription)
        })
    }

    /**
     * Get active users in the project
     */
    getActiveUsers(){
        this.loadingService.showLoading(true, null, "Loading...", null);
        this.workflowService.getAllActiveUsersForProject(UserVariable.projectId).subscribe(data => {
                this.userList = []
				if (data.payload) {
					data.payload.forEach(element => {
                        this.userList.push({ "id": element.id, "text": element.userName})
                    })
                    this.getSelectedPM();
                }
                //get workflow tasks by the project workflow template id
                if(this.projectWorkflowTemplateId){
                    this.getTasksByTemplateId(this.projectWorkflowTemplateId)
                }
                this.loadingService.hideLoading();
        }, error => {
            this.loadingService.hideLoading();
            this.alertService.clear()
			this.alertService.error(error.statusDescription)
        });
    }
    
    removePM(){
        this.selectedPM = null;
    }

    selectPM(event: any){
        this.selectedPM = event.id;
    }

    /**
     * Remove the selected user
     * @param task 
     */
    removeUser(task: any){
        let index = this.taskList.findIndex(item => item.taskName == task.taskName && item.orderNo == task.orderNo)
        this.taskList[index].userId = '';
        if(this.projectWorkflowTemplateId){
            let index = this.projWFTemplate.tasks.findIndex(item => item.taskName == task.taskName && item.orderNo == task.orderNo)
            this.projWFTemplate.tasks[index].userId = '';
        }
    }

    /**
     * Select default user from existing users
     * @param event 
     * @param task 
     */
    selectUser(event: any, task: any){
        let index = this.taskList.findIndex(item => item.taskName == task.taskName && item.orderNo == task.orderNo)
        this.taskList[index].userId = event.id;
        if(this.projectWorkflowTemplateId){
            let index = this.projWFTemplate.tasks.findIndex(item => item.taskName == task.taskName && item.orderNo == task.orderNo)
            this.projWFTemplate.tasks[index].userId = event.id;
        } else{
            //do nothing
        }
    }

    /**
     * Set the selected user
     * @param item 
     */
    getSelectedUser(item: any){
        this.selectedUser = []
        if(item.userId){
            this.userList.forEach(user => {
                if(user.id == item.userId){
                    this.selectedUser.push({"id": user.id, "text": user.text})
                }
            })
        }
    }

    /**
     * Get the selected PM
     */
    getSelectedPM(){
        this.selectedProjectManager = []
        this.parseXML(this.configParameters).then(data => {
            if(data[0]){
                this.selectedPM = data[0].userId
                if(this.selectedPM){
                    this.selectedProjectManager = this.userList.filter(user => user.id == this.selectedPM);
                } else{
                    //do nothing
                }
            } else{
                //do nothing
            }   
        }); 
    }

    /**
     * add/update the workflow template
     */
    save(){
        if(this.projectWorkflowTemplateId){
            this.update();
        } else{
            //get config parameter xml 
            this.projWFTemplate.configParameters = this.getConfigParameterXML(this.selectedPM);
            let data = {
                id: null,
                templateId: this.templateId,
                projectId: this.projWFTemplate.projectId,
                configParameters: this.projWFTemplate.configParameters,
                workflowTasks: this.taskList
            };
            var json = JSON.stringify(data);
            this.loadingService.showLoading(true, false, this.translate.instant('SAVING'), 0);
            this.workflowService.assignWorkflowTemplateToProject(json, this);
        }
    }

    /**
     * Update default users/PM in existing template
     */
    update(){
        //get config parameter xml 
        this.projWFTemplate.configParameters = this.getConfigParameterXML(this.selectedPM);
        let data = {
            id: this.projectWorkflowTemplateId,
            templateId: this.templateId,
            projectId: this.projWFTemplate.projectId,
            configParameters: this.projWFTemplate.configParameters,
            workflowTasks: this.projWFTemplate.tasks
        };
        var json = JSON.stringify(data);
        this.loadingService.showLoading(true, false, this.translate.instant('SAVING'), 0);
        this.workflowService.updateWorkflowTemplateTasks(json, this);
    }

    /**
     * Get config parameter XML
     * @param selectedPM 
     */
    getConfigParameterXML(selectedPM: string): string{
        let confParamXML = '';
        confParamXML += '<Parameters>'
        confParamXML += '<ProjectManager type="user">'
        if(selectedPM){
            confParamXML += selectedPM
        }
        confParamXML += '</ProjectManager>'
        confParamXML += '</Parameters>'
        return confParamXML;
    }

    /**
     * Process XML and get values
     * @param data 
     */
    parseXML(data: any){
        return new Promise(resolve => {
          var k: string | number,
            arr = [],
            parser = new xml2js.Parser(
              {
                trim: true,
                explicitArray: true
              });
          parser.parseString(data, function (err, result) {
             if(result != null && result != undefined){
                var obj = result.Parameters;
                for (k in obj.ProjectManager) {
                var item = obj.ProjectManager[k];
                if(item._){
                  arr.push({
                    userId: item._
                  });
                } else{
                 //do nothing
                }
                }
             } else{
                //do nothing
             }
             resolve(arr);
          });
        });
      }

    cancellClick(){
        this.bsModalRef.hide()	
    }
    
    onSuccess(data: WsResponse, serviceType: WsType){
        if(serviceType == WsType.ASSIGN_WORKFLOW_TEMPLATE){
            this.loadingService.hideLoading();
            if(data.payload){
                this.alertService.success("Successfully assigned the workflow template to the project");
                this.bsModalRef.hide();
                this.sharedService.viewSideBarEventEmit("workflowTemplates")
            } 
        } else if(serviceType == WsType.UPDATE_WORKFLOW_TEMPLATE_TASKS){
            this.loadingService.hideLoading();
            if(data.payload){
                this.alertService.success("Successfully updated the workflow template tasks");
                this.bsModalRef.hide();
                this.sharedService.viewSideBarEventEmit("workflowTemplates")
            } else{
                this.alertService.error(data.statusDescription);
                this.bsModalRef.hide();
            }
        }
    }

    onFail(data: WsResponse, serviceType: WsType){
        if(serviceType == WsType.ASSIGN_WORKFLOW_TEMPLATE){
            this.loadingService.hideLoading();
            this.alertService.error(data.statusDescription);
        } else if(serviceType == WsType.UPDATE_WORKFLOW_TEMPLATE_TASKS){
            this.loadingService.hideLoading();
            this.alertService.error(data.statusDescription);
        }
    }
}