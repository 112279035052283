import { Component, OnInit} from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FilesService } from "../../home/files/files.service";
import { WsCallback } from "../../util/ws-callback.interface";
import { WsResponse } from '../../util/ws-response.model';
import { WsType } from '../../util/ws-type';
import { AlertService } from '../../util/alert/alert.service';
import { SharedService } from '../shared.service';
import { LoadingService } from '../loading/loading.service';
import { Pipe } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({name: 'safeHtml'})
export class SafeViewModal {
    constructor(private sanitizer:DomSanitizer){}
  
    transform(value: any, args?: any): any {
      return this.sanitizer.bypassSecurityTrustHtml(value);
    }
  }

@Component({
  selector: 'app-viewmodel',
  templateUrl: './viewmodel.component.html',
  styleUrls: ['./viewmodel.component.css']
})
export class ViewmodelComponent implements OnInit {

  title: string;
	closeBtnName: string;
	actionBtn: string;
	data: any;
	itemId: any;
	cmRevisionNo: any;
	itemType: any;

  constructor(public bsModalRef: BsModalRef, private filesService: FilesService, private alertService: AlertService, private sharedService: SharedService, private loadingService: LoadingService) { }

  ngOnInit() {
  }

  onSuccess(data: WsResponse, serviceType: WsType): void {
		console.log("fail serviceType= " + serviceType)
		console.log("^^^^^^^^^^^^^^^^^^^^^^^", JSON.stringify(data))
	}

	confirm() {

		if (this.itemType == 'ICECourse') {
			this.loadingService.showLoading(true, false, "Revision restoring", 100);
			this.filesService.restoreICECourseRevision(this.itemId, this.cmRevisionNo, this);
		} else {
			this.loadingService.showLoading(true, false, "Revision restoring", 100);
			this.filesService.setCallback(this.sharedService.getRevisionParent());
			this.filesService.restoreRevision(this.itemId, this.itemType, this.cmRevisionNo)
		}
	}

  onFail(res: WsResponse, serviceType: WsType): void {
		console.log("fail serviceType= " + serviceType)
		this.alertService.clear()
		this.alertService.error(res.statusDescription);
	}
}
