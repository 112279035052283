import {Component, OnInit, AfterViewInit, OnDestroy, ElementRef, EventEmitter, QueryList, ViewChild, ViewChildren } from '@angular/core';
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {IrnService} from "../irn.service";
import {WsCallback} from "../../../util/ws-callback.interface";
import {WsResponse} from "../../../util/ws-response.model";
import {WsType} from "../../../util/ws-type";
import {SelectComponent} from "../../../util/ng2-select/select";
import {AlertService} from "../../../util/alert/alert.service";
import {LoadingService} from "../../../util/loading/loading.service";
import {UserVariable} from "../../../util/common/user-variable";
import {CommonUtil} from "../../../util/common/common-util";
import {TranslateService} from "@ngx-translate/core";
import {SharedService} from "../../../util/shared.service";
import {GridOptions, ColDef, Module, AllModules} from "@ag-grid-enterprise/all-modules";
import {AddpunchlistComponent} from "../../punchlist/addpunchlist/addpunchlist.component";
import {Observable, of, Subscription} from "rxjs";
import {IrnPunchlistsCellRenderer} from "./irn-punchlists-cell-renderer.component";
import * as FileSaver from 'file-saver';
import { DatePipe } from '@angular/common';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { Console } from 'console';
import * as moment from 'moment';

@Component({
  selector: 'app-addeditirn',
  templateUrl: './addeditirn.component.html',
  styleUrls: ['./addeditirn.component.css']
})
export class AddeditirnComponent implements OnInit, WsCallback, AfterViewInit, OnDestroy {
  hide :boolean = false;
  public edit : boolean;
  disableform:boolean=false;
  private token;
  public projectId: string = null;

  public columnDefs: any;
  public gridOptions: GridOptions;
  rowData: any = [];
  private gridColumnApi: any;
  public gridApi: any;
  public modules: Module[] = AllModules;

  public irn ={irnId:null ,irnNumber:null ,description:null, vendorId:null, systemId:null, assets:[], isUploaded:false,punchListDesc:null,punchLists:[],
  irnStatus:null, targetCompletionDate:null};
  public systemList = [];
  public selectedSystem;
  public systemDisable: boolean = true;


  public selectedAssetList = [];
  public dropdownAssetList = [];
  public initialValueList  = [];
  public assetList = [];


  public vendorList = [];
  public selectedVendor;

  public irnDocumentUpload: any;

  private loadingHandler: any;

  // subscriptions
  subsVar:Subscription;
  loadingIRNSub:Subscription;

  public componentName:string = "AddeditirnComponent" ;

  public getRowNodeId: any;

  punchListsIds = [];
  public irnStatusList = [];
  public selectedIRNStatusId;
  public selectedIRNStatusText;

  public targetCompletionDate : any;
  public approvedDate : any;
  public approvedDateDisabled : boolean = true;

  public bsConfig: Partial<BsDatepickerConfig>;

  disableAssets : boolean =false;
  disableSystems : boolean =false;
  @ViewChildren(SelectComponent) selectElem: QueryList<SelectComponent>
  @ViewChild('newNumber') newNumber: ElementRef;
  @ViewChild('newDesc') newDescription: ElementRef;

  //messages
  emptyFieldError:any
  emptyAsset:any

  savedAssetIdList: string [];
  public irnfromDb ={irnId:null ,irnNumber:null ,description:null, vendorId:null, systemId:null, assets:[], isUploaded:false,punchListDesc:null,punchLists:[],vendorName:"",systemName:"",
                    irnStatus:null, targetCompletionDate:null, approvedDate:null};
  cancelForm : boolean =  false;
  deletePunchListMes :string =""
  constructor(
      public bsModalRef: BsModalRef,
      public bsModalRefPunch: BsModalRef,
      private irnService:IrnService,
      private alertService: AlertService,
      private loadingService: LoadingService,
      private translate: TranslateService,
      private commonUtil: CommonUtil,
      private sharedService: SharedService,
      private modalService: BsModalService
  ) {
    translate.addLangs(["es", "en"]);
    translate.setDefaultLang("en");
    let browserLang = translate.getBrowserLang();
    this.translate.get('IRN_ADD_EDIT.FIELD_EMPTY').subscribe((res: string) => {
      this.emptyFieldError = res;
    });
    this.translate.get('IRN_ADD_EDIT.ASSET_EMPTY').subscribe((res: string) => {
      this.emptyAsset = res;
    });
    this.translate.get('IRN_ADD_EDIT.DELETE_PUNCHLIST').subscribe((res: string) => {
      this.deletePunchListMes = res;
    });
    this.token = UserVariable.getUserToken();
    if(!this.commonUtil.validateToken(this.token)){
      return;
    }
    this.projectId = UserVariable.projectId;
    this.columnDefs = [
      // {
      //   headerName: "System",
      //   field: 'system',
      //   autoHeight: true,
      //   cellClass: "cell-wrap-text",
      //   minWidth: 100,
      // },
      {
        headerName: this.translate.instant('IRN_PUNCHLIST_VIEW.PUNCHLIST_ASSET'),
        field: 'asset',
        autoHeight: true,
        filter: "agTextColumnFilter",
        cellClass: "cell-wrap-text",
        minWidth: 100,
      },
      {
        headerName: this.translate.instant('IRN_PUNCHLIST_VIEW.PUNCHLIST_DESCRIPTION'),
        field: 'punchlistDes',
        autoHeight: true,
        filter: "agTextColumnFilter",
        cellClass: "cell-wrap-text",
        minWidth: 100,
      },
      {
        headerName: this.translate.instant('IRN_PUNCHLIST_VIEW.DELETE'),
        field: "delete",
        filter: true,
        minWidth: 85,
        cellStyle: { 'text-align': 'center' },
        cellRendererFramework: IrnPunchlistsCellRenderer,
      }
    ]
    this.getRowNodeId = function (data) {
      return data.id;
    };
    this.gridOptions = {
      rowData: this.rowData,
      columnDefs: this.columnDefs,
      defaultColDef: {
        resizable: true,
        sortable: true,
        floatingFilter: true
      },
      rowSelection: "single",
      rowMultiSelectWithClick: false,
      doesExternalFilterPass: this.externalFilterPass,
      onRowClicked: this.rowClicked,
      animateRows: true,
      rowBuffer: 20,
      context: {
        componentParent: this
      },
      paginationPageSize: 10
    };
    this.bsConfig = Object.assign({}, { containerClass: 'theme-default' });
  }

  ngOnInit() {
    
    this.irnStatusList = [
      { 'id': '1', 'text': 'Initial' },
      { 'id': '2', 'text': 'Approved' }
    ];
    // this.subsVar = this.irnService.deleteIrnPunchlistIdResponse().subscribe(() => this.deletePunchlist());
    this.irnService.setAddEditComponent(this);
    //load irn data to be edited
    if (this.sharedService.getFileId() != "-1" && this.sharedService.getFileId() != -1) {
      this.savedAssetIdList =[];
      this.loadingHandler = new AddeditirnComponent.LoadingHandler();
      // loading IRN after loading all other items
      this.loadingIRNSub = this.loadingHandler.getLoadedSub().subscribe(() => {
        this.loadingService.showLoading(true, false, 'Loading IRN data', 0);
        this.irnService.getIrnDataByIrnId(this.sharedService.getFileId(),this)
      });
      this.loadingHandler.init(3, this.loadingService);
      this.irnService.getSystemList(this);
      // this.irnService.getAllVendors(this);
      this.irnService.getAllVendorswithDisables(this);
      this.irnService.getAssetListWithIRNTargetCompletionDate(this);

      localStorage.setItem("value", "0");
      this.edit=true;
    }else {
      this.edit=false;
      this.loadingHandler = new AddeditirnComponent.LoadingHandler();
      this.loadingHandler.init(3, this.loadingService);
      this.irnService.getSystemList(this);
      this.irnService.getAllVendors(this);
      this.irnService.getAssetListWithIRNTargetCompletionDate(this);
    }
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    // remove subscriptions
    if (this.subsVar) {
      this.subsVar.unsubscribe();
    }
    if (this.loadingIRNSub) {
      this.loadingIRNSub.unsubscribe();
    }
    this.sharedService.setParent(null);

    window.removeEventListener("resize", this.onWindowResize);
    this.gridApi.destroy();
    this.gridApi = null;
  }

  externalFilterPass(node: any) {
    return true;
  }
  rowClicked() {
  }
  cancel() {
    this.cancelForm = true;
    if(this.edit){
      let irnId=  this.irn.irnId;
      if (this.rowData) {
        let punchlistCount = this.rowData.length;
        this.irnService.setPunclistCount(irnId,punchlistCount);
      }
      if(this.rowData && this.rowData.length > 0){
        let newAssetsList  = []
        let newAssetNames = [];
        this.rowData.forEach((pl)=>{
          if(!this.savedAssetIdList.includes(pl.assetId)){
            newAssetsList.push(pl.assetId);
            newAssetNames.push(pl.asset)
          }
        })
        if(newAssetsList.length>0){
          this.savedAssetIdList = [...this.savedAssetIdList, ...newAssetsList]
          let assetNamesList = []
          this.irnfromDb.assets.forEach((asset) => {
            if (asset.assetId) {
              assetNamesList.push(asset.assetName)
            }
          });
          assetNamesList = [...assetNamesList,...newAssetNames]
          let assetsNames = []
          if (assetNamesList.length > 0) {
            let len = assetNamesList.length;
            assetNamesList.forEach((item, id) => {
              if (id == len - 1) {
                assetsNames.push(item)
              } else {
                assetsNames.push(item + ",")
              }
            })
          }
          let updatedRow = {
            irnId: this.irnfromDb.irnId,
            irnNumber: this.irnfromDb.irnNumber,
            description: this.irnfromDb.description,
            systemId: this.irnfromDb.systemId,
            assets: this.savedAssetIdList,
            vendorId: this.irnfromDb.vendorId,
            vendorName: this.irnfromDb.vendorName,
            selectedAssetsStr: "",
            systemName: this.irnfromDb.systemName,
            punchlistCount: this.rowData.length,
            Isuploaded: this.irn.isUploaded,
            assetsNames: assetsNames,
            irnStatus: this.irnfromDb.irnStatus,
            targetCompletionDate: this.irnfromDb.targetCompletionDate
          }
          let selectedTargetCompletionDate = null;
          selectedTargetCompletionDate  = new DatePipe("en-US").transform(new Date(this.targetCompletionDate), 'yyyy-MM-dd')

          this.irnService.applyTransaction(updatedRow);
          this.loadingService.showLoading(true, null, "Updating", null);
          this.irnService.updateIrn(
              this.irnfromDb.irnId,
              this.irnfromDb.irnNumber,
              this.irnfromDb.description,
              this.irnfromDb.systemId,
              this.savedAssetIdList,
              this.irnfromDb.vendorId,
              "",
              this.irnfromDb.irnStatus,
              this.irnfromDb.targetCompletionDate,
              this.approvedDate,
              this
          )
        }else{
          this.bsModalRef.hide()
        }
      }else{
        this.bsModalRef.hide()
      }
    }else{
      this.bsModalRef.hide()
    }
  }
  onDelete(event){

  }

  onGridReady(params) {
    const allColumnIds = [];
    this.columnDefs.forEach((columnDef) => {
      allColumnIds.push(<ColDef>columnDef);
    });
    this.gridColumnApi = params.columnApi;
    this.gridApi = params.api;
    params.api.sizeColumnsToFit();
    // window.addEventListener("resize", function () {
    //   setTimeout(function () {
    //     params.api.sizeColumnsToFit();
    //   });
    // });
    window.addEventListener("resize", this.onWindowResize);
  }

  private onWindowResize = () => {
   
		setTimeout(() => {
			if (this.gridApi) {
				this.gridApi.sizeColumnsToFit();
			}
		}); 
	};

  addPunchList(){
    this.sharedService.setParent(this);
    this.bsModalRefPunch = this.modalService.show(AddpunchlistComponent, { class: 'modal-xl', backdrop: 'static', keyboard: false });
    this.hide = true;
    this.bsModalRefPunch.content.onClose.subscribe(result => {
      this.hide = false;
      var evObj = document.createEvent( 'Events' );
      evObj.initEvent( "click", true, false );
      document.getElementById( "irnaddedit" ).dispatchEvent( evObj );
    });
  }
  consoleClick(){
    console.log("come to this page");
  }
  setValues(payload){
    this.irn.irnId = payload.irnId;
    this.irnfromDb.irnId = payload.irnId;
    this.irn.irnNumber = payload.irnNumber;
    this.irnfromDb.irnNumber = payload.irnNumber;
    this.irn.description = payload.description;
    this.irnfromDb.description = payload.description;
    this.irn.vendorId = payload.vendorId;
    this.irnfromDb.vendorId = payload.vendorId;
    this.irnfromDb.systemName = payload.systemName;
    this.irnfromDb.vendorName = payload.vendorName;
    this.irn.punchLists = payload.punchlists;
    if(this.irn.vendorId ){
      this.selectedVendor = this.vendorList.filter(x => x.id == this.irn.vendorId);
    }
    this.vendorList = this.vendorList.filter(x => (x.status == "Active" || x.id == this.irn.vendorId));

    this.irn.irnStatus = payload.irnstatus;
    this.irnfromDb.irnStatus = payload.irnstatus;
    this.selectedIRNStatusText = payload.irnstatus;
    if(this.irn.irnStatus ){
      switch (this.irn.irnStatus) {
        case 'Initial':
            this.selectedIRNStatusId = this.irnStatusList.filter(x => (x.id == 1));
            this.approvedDateDisabled = true;
            break;
        case 'Approved':
            this.selectedIRNStatusId = this.irnStatusList.filter(x => (x.id == 2));
            this.approvedDateDisabled = false;
            break;
        
        default:
            this.selectedIRNStatusId = null;
            this.approvedDateDisabled = true;
            break;
    }
    
    }

    this.irnfromDb.targetCompletionDate = payload.targetCompletionDate;
    if (payload.targetCompletionDate) {
      this.targetCompletionDate = moment.utc(payload.targetCompletionDate).format("M/D/YYYY")
    }

    this.irnfromDb.approvedDate = payload.approvedDate;
    if (payload.approvedDate) {
      this.approvedDate = moment.utc(payload.approvedDate).format("M/D/YYYY")
    }

    this.irn.systemId = payload.systemId;
    this.irnfromDb.systemId = payload.systemId;
    if(this.irn.systemId){
      this.selectedSystem = [{text:payload.systemName,id:this.irn.systemId}];
    }
    this.irn.assets = payload.assets;
    this.irnfromDb.assets = payload.assets;
    if (this.irn.assets.length > 0) {
      let assetsIdList = [];
      this.irn.assets.forEach((asset) => {
        if (asset.assetId) {
          assetsIdList.push(asset.assetId)
        }
      })
      this.savedAssetIdList = assetsIdList;
    }
    this.irn.assets.forEach((asset) => {
      if (asset.assetId) {
        this.selectedAssetList.push({display: asset.assetName, value: asset.assetId})
      }
    })
    this.initialValueList = this.selectedAssetList;
    if (this.selectedAssetList.length > 0) {
      this.disableSystems = true;
    }
    if (this.selectedSystem && this.selectedSystem.length > 0) {
      this.disableAssets = true;
    }
    if (this.irn.punchLists.length > 0) {
      this.rowData = [];
      this.irn.punchLists.forEach((punchlist) => {
        this.rowData.push({
          id: punchlist.id,
          punchlistDes: punchlist.punchlistDescription,
          asset: punchlist.assetName,
          system: punchlist.subSystemName,
          assetId: punchlist.assetId,
          systemId: punchlist.subSystemId,
          delete: "delete:" + punchlist.id + ":" + "false",
        })
        if (punchlist.assetId) {
          let selectedasests = []
          this.selectedAssetList.forEach((element) => {
            if (element.value == punchlist.assetId) {
              selectedasests.push({...element, readonly: true})
            } else {
              selectedasests.push(element);
            }
          });
          this.selectedAssetList = selectedasests;
        } else if (punchlist.subSystemId && this.selectedSystem && this.selectedSystem.length > 0) {
          if (punchlist.subSystemId == this.selectedSystem[0].id) {
            this.disableSystems = true;
          }
        }
      });

    }
    if (payload.isUploaded) {
      this.irn.isUploaded = true;
    }
  }

  save() {
    if (this.edit) {
      if (this.irn.irnNumber == "") {
        this.alertService.clear();
        this.alertService.error(this.emptyFieldError)
      } else if (this.irn.description == "") {
        this.alertService.clear();
        this.alertService.error(this.emptyFieldError)
      } else if (!this.selectedSystem && (this.selectedAssetList.length == 0)) {
        this.alertService.clear();
        this.alertService.error(this.emptyAsset);
     // } 
      //else if (this.selectedIRNStatusText == "Approved" && (this.targetCompletionDate == null || this.targetCompletionDate == "")) {
       // this.alertService.clear();
       // this.alertService.error(this.emptyFieldError)
      } else if (this.selectedIRNStatusText == "Approved" && (this.approvedDate == null || this.approvedDate == "")) {
        this.alertService.clear();
        this.alertService.error(this.emptyFieldError)
      } else {
        this.addIrn()
      }

    } else {
      if (this.newNumber.nativeElement.value == "") {
        this.alertService.clear();
        this.alertService.error(this.emptyFieldError)
      } else if (this.newDescription.nativeElement.value == "") {
        this.alertService.clear();
        this.alertService.error(this.emptyFieldError)
      } else if (!this.selectedSystem && (this.selectedAssetList.length == 0)) {
        this.alertService.clear();
        this.alertService.error(this.emptyAsset);
     // } else if (this.selectedIRNStatusText == "Approved" && (this.targetCompletionDate == null || this.targetCompletionDate == "")) {
        //this.alertService.clear();
       // this.alertService.error(this.emptyFieldError)
      } else if (this.selectedIRNStatusText == "Approved" && (this.approvedDate == null || this.approvedDate == "")) {
        this.alertService.clear();
        this.alertService.error(this.emptyFieldError)
      } else {
        this.addIrn()
      }
    }
  }
  // methods for assets selection
  public requestAutocompleteItems = (text: string): Observable<any> => {
    return this.irnService.getAssetList(text,this.edit,this.initialValueList);
  }

  selectItemFromSuggestions(event, data) {
    if (this.selectedAssetList.length > 0) {
      this.disableSystems = true;
    } else {
      this.disableSystems = false;
    }
    this.irnService.getIrnListByAssetId(event.value, this);
  }

  removeSelectedItem(event, data) {
    if (this.selectedAssetList.length > 0) {
      this.disableSystems = true;
    } else {
      this.disableSystems = false;
    }
  }

  selectSystem(value: any): void {
    this.selectedSystem = this.systemList.filter(x => x.id == value.id);
    if (this.selectedSystem.length > 0) {
      this.disableAssets = true;
    }
  }

  removedSystem(value: any): void {
    this.selectedSystem = null;
    this.disableAssets = false;
  }

  selectVendor(value: any): void {
    this.selectedVendor = this.vendorList.filter(x => x.id == value.id);
  }

  removedVendor(value: any): void {
    this.selectedVendor = null;
  }
 
  selectIRNStatus(value: any): void {
    this.selectedIRNStatusId = this.irnStatusList.filter(x =>  x.id == value.id);
    this.selectedIRNStatusText  = JSON.parse(JSON.stringify(value)).text;
   // this.selectedIRNStatusId  = JSON.parse(JSON.stringify(value)).id;
    
    if (this.selectedIRNStatusText == 'Approved'){
      if (this.irnfromDb.approvedDate != null ){
        //do nothing
      } else {
        this.approvedDate = new Date();
      }
      
      this.approvedDateDisabled = false;
    } 
    if (this.selectedIRNStatusText == 'Initial' || this.selectedIRNStatusText == "" || this.selectedIRNStatusText == null){
      this.approvedDate = null;
      this.approvedDateDisabled = true;
      this.irnfromDb.approvedDate = null;
    }


    
  }

  removedIRNStatus(value: any): void {
    this.selectedIRNStatusText = null;
    this.selectedIRNStatusId = null;

    this.approvedDate = null;
    this.approvedDateDisabled = true;
    this.irnfromDb.approvedDate = null;
  }

  public closeOtherElems(element: any) {
    let a = this.selectElem.filter(function (el) {
      return (el != element)
    });

    a.forEach(function (e: SelectComponent) {
      e.hideOptions();
    })
  }

  onFileChanged(event) {
    let i: number = 0;
    for (i = 0; i < event.target.files.length; i++) {
      let newFile = <File>event.target.files[i];
      if (newFile != undefined) {
        let parts = newFile.name.split('.')
        if (parts.length > 1) {
          let fileExtension = parts.pop();
          if (fileExtension == 'pdf' || fileExtension == 'PDF') {
            let fileName = parts.join('.');
            fileName = fileName.trim();
            if (this.validateFile(newFile)) {
              //set for uploading to the local file system
              var irnDocumentUpload =
                  {
                    docName: fileName,
                    docType: 'pdf',
                    file: newFile,
                    isAdded: true,
                    isDeleted: false
                  };
              this.irnDocumentUpload = irnDocumentUpload;
            } else {
              //file size is too large
            }
          } else {
            //file is not pdf
            this.alertService.clear();
            this.translate.get('NEW_IRN_DOCUMENT.INVALID_FILE_TYPE').subscribe((res: string) => {
              this.alertService.error(res, false);
            });
          }
        } else {
          this.translate.get('NEW_IRN_DOCUMENT.INVALID_FILE_NAME').subscribe((res: string) => {
            this.alertService.error(res, false);
          });
        }
      } else {
        //file is not valid
        this.alertService.clear();
        this.translate.get('NEW_IRN_DOCUMENT.INVALID_FILE').subscribe((res: string) => {
          this.alertService.error(res, false);
        });
      }
    }
  }

  validateFile(file: File): boolean {
    if (file.size > 104857601) {
      this.translate.get('NEW_IRN_DOCUMENT.SIZE_LIMIT_EXCEEDED').subscribe((res: string) => {
        this.alertService.error(res, false);
      });
      return false;
    } else {
      return true;
    }
  }

  setEditMode(irnId: string) {
    let selectedVendorId = null;
    let selectedVendorName = "";
    if (this.selectedVendor && this.selectedVendor.length > 0) {
      selectedVendorId = this.selectedVendor[0].id;
      selectedVendorName = this.selectedVendor[0].text;
    }
    this.edit = true;
    this.irn.irnId = irnId;
    this.irn.irnNumber = this.newNumber.nativeElement.value;
    this.irn.description = this.newDescription.nativeElement.value;
    this.irnfromDb.irnId = irnId;
    this.irnfromDb.irnNumber = this.newNumber.nativeElement.value;
    this.irnfromDb.description = this.newDescription.nativeElement.value;
    this.irnfromDb.vendorId = selectedVendorId;
    this.irnfromDb.systemName = null;
    this.irnfromDb.vendorName = selectedVendorName;
    this.irnfromDb.systemId = null;

    if (this.selectedAssetList.length > 0) {
      let assetsIdList = [];
      this.selectedAssetList.forEach((asset) => {
        if (asset.value) {
          assetsIdList.push(asset.value)
        }
      })
      this.savedAssetIdList = assetsIdList;
    }
  }

  onSuccess(data: WsResponse, serviceType: WsType) {
    //
    if (serviceType == WsType.ADD_NEW_IRN) {
      if (data.payload) {
        let message = ""
        this.translate.get('IRN_ADD_EDIT.ADD_SUCCESS').subscribe((res: string) => {
          message = res;
        });
        this.setEditMode(data.payload);
        this.onUpload(data.payload);
        this.irn.irnId = data.payload;
        this.loadingService.hideLoading();
        this.alertService.success(message);
      } else {
        let message = ""
        this.translate.get('IRN_ADD_EDIT.ADD_FAILED').subscribe((res: string) => {
          message = res;
        });
        this.alertService.clear();
        this.alertService.error(message);
      }
    } else if (serviceType == WsType.UPDATE_IRN) {
      let message = ""
      this.translate.get('IRN_ADD_EDIT.UPDATE_SUCCESS').subscribe((res: string) => {
        message = res;
      });
      if (!this.cancelForm) {
        this.onUpload(data.payload);
      }
      this.loadingService.hideLoading();
      this.alertService.success(message);
      this.irnService.setTableRow(data.payload);
      this.bsModalRef.hide();
    } else if (serviceType == WsType.GET_SYSTEMS_BY_PROJECT_ID) {
      this.systemList = [];
      if (data.payload != null) {
        data.payload.forEach(element => {
          this.systemList.push({'id': element.systemId, 'text': element.systemNo + ' ' + element.systemName});
        });
        this.systemDisable = false;
      }
      this.loadingHandler.handle();
    } else if (serviceType == WsType.GET_ALL_VENDORS) {
      this.vendorList = [];
      if (data.payload != null) {
        data.payload.forEach(element => {
          this.vendorList.push({'id': element.vendorId, 'text': element.vendorName});
        });
      }
      this.loadingHandler.handle();
    } else if (serviceType == WsType.ADD_NEW_IRN_DOCUMENT) {
      let message = ""
      this.translate.get('NEW_IRN_DOCUMENT.ADD_SUCCESS').subscribe((res: string) => {
        message = res;
      });
      this.alertService.success(message);
      this.irn.isUploaded = true;
      if (data.payload != null) {
        this.irnService.setIrnDocUploadStatus(true,data.payload);
      }
      this.irnDocumentUpload = null;
      this.loadingService.hideLoading()
    } else if (serviceType == WsType.GET_IRN_BY_IRN_ID) {
      this.loadingService.hideLoading();
      if (data.payload != null) {
        this.setValues(data.payload)
      }
    } else if (serviceType == WsType.GET_PUNCHLIST_DATA_TO_IRN) {
      if (data.payload != null) {
        this.sharedService.setParent(null);
        this.rowData.push({
          id: data.payload.punchlistId,
          punchlistDes: data.payload.punchlistDes,
          asset: data.payload.assetName,
          system: data.payload.systemName,
          assetId: data.payload.assetId,
          systemId: data.payload.systemId,
          delete: "delete:" + data.payload.punchlistId + ":" + "false",
        });
        if (data.payload.assetId) {
          let updateDropdown = []
          let selectedasests = []
          this.dropdownAssetList.forEach((element) => {
            if (element.value == data.payload.assetId) {
              updateDropdown.push({...element, readonly: true})
            } else {
              updateDropdown.push(element)
            }
          });
          this.selectedAssetList.forEach((element) => {
            if (element.value == data.payload.assetId) {
              selectedasests.push({...element, readonly: true})
            } else {
              selectedasests.push(element)
            }
          });
          this.dropdownAssetList = updateDropdown;
          this.selectedAssetList = selectedasests;
        } else if (data.payload.systemId) {
          if (data.payload.systemId == this.selectedSystem[0].id) {
            this.disableSystems = true;
          }
        }
        this.gridApi.paginationProxy.currentPage = 0;
        this.gridOptions.api.sizeColumnsToFit();
        this.gridApi.setRowData(this.rowData);
      }
    } else if (serviceType == WsType.GET_IRN_BY_ASSET_ID) {
      if (data.payload.length > 0) {
        this.alertService.clear()
        this.translate.get('IRN_ADD_EDIT.ASSETS_ADD_WARNING').subscribe((res: string) => {
          this.alertService.warn(res);
        });
      }
    }else if (serviceType == WsType.DELETE_PL_FROM_IRN) {
      if (data.payload != null) {
        let punchlistId = data.payload;
        let selectedAssets = [];
        let deletingrow;
        for (let row of this.rowData) {
          if (row.id == punchlistId) {
            deletingrow = row;
            break;
          }
        }
        this.rowData = this.rowData.filter(x => x.id != punchlistId);
        let morepunchlists = false;
        for (let row of this.rowData) {
          if (row.assetId && row.assetId == deletingrow.assetId) {
            morepunchlists = true;
            break;
          } else if (row.systemId && row.systemId == deletingrow.systemId) {
            morepunchlists = true;
            break;
          }
        }
        if (!morepunchlists) {
          this.selectedAssetList.forEach((element) => {
            if (element.value == deletingrow.assetId) {
              let {readonly, ...filteredObject} = element;
              selectedAssets.push(filteredObject)
            } else {
              selectedAssets.push(element)
            }
          })
          this.selectedAssetList = selectedAssets;
          if (this.selectedSystem) {
            if (deletingrow.systemId == this.selectedSystem[0].id) {
              this.disableSystems = false;
            }
          }
        }
        // this.alertService.clear();
        this.alertService.success(this.deletePunchListMes);
        this.loadingService.hideLoading();
        var evObj = document.createEvent( 'Events' );
        evObj.initEvent( "click", true, false );
        document.getElementById( "irnaddedit" ).dispatchEvent( evObj );
      }
    }else if (serviceType == WsType.GET_ALL_VENDORS_FOR_IRN) {
      this.loadingHandler.handle();
      if(data.payload){
        this.vendorList = [];
        if (data.payload != null) {
          data.payload.forEach(element => {
            this.vendorList.push({'id': element.vendorId, 'text': element.vendorName, 'status': element.status});
          });
        }
      }
    } 
    else if (serviceType == WsType.GET_ASSETS) {
      this.assetList = [];
      if (data.payload != null) {
        data.payload.forEach(element => {
          this.assetList.push({ 'value': element.tagId, 'display': element.tagNo });
        });
      }
      this.loadingHandler.handle();
    }
  }

  onFail(data: WsResponse, serviceType: WsType) {
    this.loadingService.hideLoading();
    if (serviceType == WsType.GET_SYSTEMS_BY_PROJECT_ID) {
      this.alertService.error(data.statusDescription);
      this.loadingHandler.handle();
    } else if (serviceType == WsType.GET_ALL_VENDORS) {
      this.alertService.error(data.statusDescription);
      this.loadingHandler.handle();
    } else if (serviceType == WsType.ADD_NEW_IRN_DOCUMENT) {
      this.alertService.error(data.statusDescription);
      this.loadingService.hideLoading()
    } else if (serviceType == WsType.ADD_NEW_IRN) {
      this.alertService.error(data.statusDescription);
    } else if (serviceType == WsType.UPDATE_IRN) {
      this.alertService.clear()
      this.alertService.error(data.statusDescription);
    }else if (serviceType == WsType.DELETE_PL_FROM_IRN) {
      this.alertService.clear()
      this.alertService.error(data.statusDescription);
    }else if (serviceType == WsType.GET_ALL_VENDORS_FOR_IRN) {
      this.alertService.clear()
      this.alertService.error(data.statusDescription);
      this.loadingHandler.handle();
    } else if (serviceType == WsType.GET_ASSETS) {
      this.alertService.clear()
      this.alertService.error(data.statusDescription)
    }
  }

  addIrn() {
    let seletedAssetsIdList = [];
    let selectedAssetsStr = ""
    let assetsNames = []
    if (this.selectedAssetList.length > 0) {
      let len = this.selectedAssetList.length;
      this.selectedAssetList.forEach((item, id) => {
        seletedAssetsIdList.push(item.value)
        if (id == len - 1) {
          selectedAssetsStr = selectedAssetsStr + item.display
          assetsNames.push(item.display)
        } else {
          selectedAssetsStr = selectedAssetsStr + item.display + ","
          assetsNames.push(item.display + ",")
        }
      })
    }
    let selectedSystemId = null;
    let selectedSystemName = null;
    if (this.selectedSystem && this.selectedSystem.length > 0) {
      selectedSystemId = this.selectedSystem[0].id
      selectedSystemName = this.selectedSystem[0].text
    }
    let selectedVendorId = null;
    let selectedVendorName = "";
    if (this.selectedVendor && this.selectedVendor.length > 0) {
      selectedVendorId = this.selectedVendor[0].id;
      selectedVendorName = this.selectedVendor[0].text;
    }
    let selectedPunchlistId = null;
    if (this.punchListsIds && this.punchListsIds.length > 0) {
      selectedPunchlistId = this.punchListsIds[0]
    }

    let selectedTargetCompletionDate = null;
    let selectedTargetCompletionDateDataRow = null;
    if (this.targetCompletionDate != null){
      selectedTargetCompletionDate  = new DatePipe("en-US").transform(new Date(this.targetCompletionDate), 'yyyy-MM-dd')
      selectedTargetCompletionDateDataRow  = new DatePipe("en-US").transform(new Date(this.targetCompletionDate), 'M/dd/yyyy');
    }

    let selectedApprovedDate = null;
    if (this.approvedDate != null){
      selectedApprovedDate  = new DatePipe("en-US").transform(new Date(this.approvedDate), 'yyyy-MM-dd')
    }
    
    if (this.edit) {
      let punchlistCount = 0;
      if (this.rowData) {
        punchlistCount = this.rowData.length;
      }
      let updatedRow = {
        irnId: this.irn.irnId,
        irnNumber: this.irn.irnNumber,
        description: this.irn.description,
        systemId: selectedSystemId,
        assets: seletedAssetsIdList,
        vendorId: selectedVendorId,
        vendorName: selectedVendorName,
        selectedAssetsStr: selectedAssetsStr,
        systemName: selectedSystemName,
        punchlistCount: punchlistCount,
        Isuploaded: this.irn.isUploaded,
        assetsNames: assetsNames,
        irnStatus: this.selectedIRNStatusText,
        targetCompletionDate: selectedTargetCompletionDateDataRow
      }
      this.irnService.applyTransaction(updatedRow);
      this.loadingService.showLoading(true, null, "Updating", null);
      this.irnService.updateIrn(
          this.irn.irnId,
          this.irn.irnNumber,
          this.irn.description,
          selectedSystemId,
          seletedAssetsIdList,
          selectedVendorId,
          selectedPunchlistId,
          this.selectedIRNStatusText,
          selectedTargetCompletionDate,
          selectedApprovedDate,
          this
      );

    } else {
      this.loadingService.showLoading(true, null, "Saving", null);
      this.irnService.addIrn(
          this.newNumber.nativeElement.value,
          this.newDescription.nativeElement.value,
          selectedSystemId,
          seletedAssetsIdList,
          selectedVendorId,
          selectedPunchlistId,
          this.selectedIRNStatusText,
          selectedTargetCompletionDate,
          selectedApprovedDate,
          this
      )
    }

  }

  onUpload(IrnId: string) {
    var loadingMessage;
    this.translate.get('NEW_IRN_DOCUMENT.LOADING_MESSAGE').subscribe((res: string) => {
      loadingMessage = res;
    });
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    this.loadingService.showLoading(true, null, loadingMessage, null);
    if (this.irnDocumentUpload && this.irnDocumentUpload.isAdded && !this.irnDocumentUpload.isDeleted) {
      let element = this.irnDocumentUpload;
      let uploadFileNameWithExt = element.docName + "." + element.docType;
      const uploadData = new FormData();

      uploadData.append("irnId", IrnId);
      uploadData.append("projectId", this.projectId);
      uploadData.append("filePath", '');
      uploadData.append("fileName", uploadFileNameWithExt);
      uploadData.append("contentType", element.docType);
      uploadData.append("token", token);
      uploadData.append("file", element.file, element.docName);
      uploadData.append("addedUserId", '');
      uploadData.append("isAdded", element.isAdded);
      uploadData.append("isDeleted", element.isDeleted);
      this.irnService.uploadFile(uploadData, this);
    }
  }

  downloadIrn() {
    this.irnService.downloadFile(this.irn.irnId, this.projectId, this.irn.irnId + "irnDoc")
        .subscribe(response => {
              this.loadingService.hideLoading()
              let file = new Blob([response], {type: 'application/pdf'});
              FileSaver.saveAs(file, "IRN_" + this.irn.irnNumber);
            },
            error => {
              this.loadingService.hideLoading()
              this.alertService.clear()
              this.translate.get('NEW_IRN_DOCUMENT.FILE_DOWNLOAD_ERROR').subscribe((res: string) => {
                this.alertService.error(res);
              });

            });
  }

  /**
   * This class handles parallel calling of web services. loadingService initiate the loading service which is required to show and hide functionalities.
   * Then, parallelServiceCount indicate the number of parallel services that we want to call. init() method is used to initialize the handler object.
   * handle() method will be called on onSuccess() and onFail() methods of required service calls. This method will hide the loading view when the counter
   * becomes zero which means no more service response to be arrived.
   */
  static LoadingHandler = class {
    parallelServiceCount: number = 0;
    loadingService: LoadingService = null;
    loaded = new EventEmitter<any>()

    init(parallelServiceCount: number, loadingService: LoadingService) {
      this.parallelServiceCount = parallelServiceCount
      this.loadingService = loadingService;
      this.loadingService.showLoading(true, false, 'Loading data', 0);
    }

    handle() {
      --this.parallelServiceCount;
      if (this.parallelServiceCount == 0) {
        this.loadingService.hideLoading();
        this.loaded.emit("");
      }
    }

    getLoadedSub() {
      return this.loaded;
    }
  }
}
