import { Component, VERSION } from '@angular/core';
import { setTheme } from 'ngx-bootstrap/utils';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SessionValidationService } from './user/login/session-validation-request.service';
import { AppRoutingModule } from './app-routing.module';
import { RouteUtil } from './util/route-util';
import { SessionUtil } from './util/session-util';
import { LocalStorage } from './util/localstorage.service';
import { UserVariable } from './util/common/user-variable';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Constants } from './util/constants';
import { CommonUtil } from './util/common/common-util';
import { Observable, Observer, fromEvent, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { AlertService } from './util/alert/alert.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent {
	title = 'Symbiosis';
	constructor(private translate: TranslateService, private router: Router, public sessionValidationRequest: SessionValidationService,
        private route: ActivatedRoute, private dds: DeviceDetectorService, private commonUtil: CommonUtil, private alertService: AlertService) {
		setTheme('bs4');
		translate.setDefaultLang('en');
		translate.use('en');

		//check device type
		this.checkDeviceType()
		this.checkOnline().subscribe(isOnline => {
			if(!isOnline){
				this.alertService.clear();
				this.alertService.error("Please check your internet connection.");
			}
		});

		//get the verions
		console.log('[Angular Version] ' + VERSION.full);
		//Setting the session id for the current browser session
		SessionUtil.setSessionId();

		var pathName: string = window.location.pathname.replace('/', '');
		console.log('AppComponent = ' + pathName);
		if (RouteUtil.canProceedWithoutSession().allowed) {
			const search = window.location.search;
			//this.router.navigate([pathName + search], { queryParamsHandling: 'preserve' });
			this.router.navigateByUrl(pathName + search);
		} else {
			if (pathName != '' && pathName != 'splash' && pathName != 'login' && pathName != 'auth0') {
				console.log('AppComponent A1= ' + pathName);
				//To handle browser reloading
				if (!pathName.includes('fwd')) {
					console.log('AppComponent A2= ' + pathName);
					console.log('AppComponent A2 x= LocalStorage.USER_ID=' + LocalStorage.USER_ID);
					console.log('AppComponent A2 x= LocalStorage.LOGGED_USER=' + LocalStorage.LOGGED_USER);
					console.log('AppComponent A2 x= LocalStorage.CLIENT_ID=' + LocalStorage.CLIENT_ID);

					console.log('AppComponent A2 y= LocalStorage.USER_ID=' + localStorage.getItem(LocalStorage.USER_ID));
					console.log('AppComponent A2 y= LocalStorage.LOGGED_USER=' + localStorage.getItem(LocalStorage.LOGGED_USER));
					console.log('AppComponent A2 y= LocalStorage.CLIENT_ID=' + localStorage.getItem(LocalStorage.CLIENT_ID));

					// console.log('AppComponent A2 a= UserVariable.userId=' + UserVariable.userId);
					// console.log('AppComponent A2 a= UserVariable.clientId=' + UserVariable.clientId);
					// console.log('AppComponent A2 a= UserVariable.projectId=' + UserVariable.projectId);


					// #1 validate login
					var canProceed = true;
					let token = UserVariable.getUserToken();
					if(!this.commonUtil.validateLogin(token, false)){
						//user not logged in
						let search = window.location.search;
						canProceed = false;
						this.router.navigate(['login', { 'fwd': pathName+search }]);
						return;
					}else{
						var loggedInUser = localStorage.getItem(LocalStorage.LOGGED_USER);
						if ((loggedInUser != null) && (loggedInUser != undefined)) {
							//console.log('AppComponent A3 a= UserVariable.userId=' + UserVariable.userId);
							//Need to set user
							if(JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER)).userId != UserVariable.userId){
								//token user userId != logged in user id
								console.log('AppComponent token user userId != logged in user id');
								canProceed = false;
								this.router.navigateByUrl('login');
								return;
							}
							console.log('AppComponent has user Id ');
							localStorage.setItem(LocalStorage.USER_ID, JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER)).userId);
							console.log('AppComponent A3 b= UserVariable.userId=' + UserVariable.userId);
							
						} else {
							//Don't know the user, send to login.
							console.log('AppComponent A6= sending to login ');
							canProceed = false;
							let search = window.location.search;
							this.router.navigate(['login', { 'fwd': pathName+search }]);
						}
					}


					// #2 if request to landing or adminsettings
                    if(pathName == 'landing' || pathName == 'adminSettings'){
						canProceed = false;
						this.router.navigate(['splash', { 'fwd': pathName }]);
					}

					// # 3 check for change project
					// if url contains project id and client id project need to be changed
					if(this.checkUrlParameters()){
						let search = window.location.search;
						canProceed = false;
						this.router.navigate(['splash', { 'fwd': pathName+search }]);
						return
					}

					// #4 if this is new tab, set default project data to tab
					let defaultProjectId = localStorage.getItem(LocalStorage.DEFAULT_PROJECT_ID);
					console.log("default project id = "+ defaultProjectId)
					if((pathName == 'home') && (defaultProjectId != null) && (defaultProjectId != undefined)){
						if((UserVariable.projectId == '') || (UserVariable.projectId == null)){
							//tab doesn't have poject
							//set user default project
							UserVariable.projectId = localStorage.getItem(LocalStorage.DEFAULT_PROJECT_ID);
							UserVariable.clientId = localStorage.getItem(LocalStorage.DEFAULT_CLIENT_ID);
							UserVariable.projectName = localStorage.getItem(LocalStorage.DEFAULT_PROJECT_NAME);
							localStorage.setItem(LocalStorage.PROJECT_ID, UserVariable.projectId);
							localStorage.setItem(LocalStorage.CLIENT_ID, UserVariable.clientId);
							//continue to home
							console.log("continue to home - default project");
						}else{
							console.log("continue to home - current project")
							//tab has selected projec
						}
					}		
                
					// #5 Now check if we have a projectName
					if (canProceed) {
						if( UserVariable.projectName != null &&  UserVariable.projectName != '' ) {
							localStorage.setItem(LocalStorage.PROJECT_NAME,UserVariable.projectName)
							console.log('AppComponent has project name');
							console.log('AppComponent A2 a= LocalStorage.PROJECT_NAME=' + localStorage.getItem(LocalStorage.PROJECT_NAME));
						} else {
							if (performance.navigation.type == 1) {
								console.info( "This page is reloaded" );
							} else {
								console.info( "This page is not reloaded");
								console.log('AppComponent A4= sending to landing ');
								canProceed = false;
								this.router.navigateByUrl('landing');
							}
                        }
	
					}  else {
						//Should be forwarded to a different page already.
					}

					// #6 Now check if we have a clientId.
					if (canProceed) {
						if (UserVariable.clientId != null && UserVariable.clientId != '') {
							//can navigate
							localStorage.setItem(LocalStorage.CLIENT_ID, UserVariable.clientId);
							console.log('AppComponent has client Id ');
							console.log('AppComponent A5 a= LocalStorage.CLIENT_ID= '+ localStorage.getItem(LocalStorage.CLIENT_ID));
						} else {
							if (performance.navigation.type == 1) {
								console.info( "This page is reloaded" );
							} else {
								console.info( "This page is not reloaded");
								console.log('AppComponent A5= sending to landing ');
								canProceed = false;
								this.router.navigateByUrl('landing');
							}
                            localStorage.setItem(LocalStorage.IS_ADMINSETTINGS_CLICKED,"")	
                        }
	
					} else {
						//Should be forwarded to a different page already.
					}


					// #7 Now check the projectId.
					if (canProceed) {
						if (UserVariable.projectId != null && UserVariable.projectId != '') {
							//can navigate
							localStorage.setItem(LocalStorage.PROJECT_ID, UserVariable.projectId);
							console.log('AppComponent has project Id ');
							console.log('AppComponent A4 a= LocalStorage.PROJECT_ID= '+ localStorage.getItem(LocalStorage.PROJECT_ID));
						} else {
							//No project, so send to change project page.
                            if(!(pathName == 'adminSettings' || pathName == 'landing')){
                                console.log('AppComponent A4 b= sending to landing ');
                                canProceed = false;
                                this.router.navigateByUrl('landing');
                            }
						}
					} else {
						//Should be forwarded to a different page already.
					}

					// #8 navigate splash 
					// splash will foward to requested path
					if (canProceed) {
						console.log('forward to splash');
						let search = window.location.search;
						this.router.navigate(['splash', { 'fwd': pathName+search }]);
					} else {
						//Should be forwarded to a different page already.
					}
				} else {
					console.log('AppComponent A3= ' + pathName);
					//fwd is already contain.
					var modified = pathName.substring(pathName.indexOf('fwd=') + 4)
					this.router.navigate(['splash', { 'fwd': modified }]);
				}
			} else if (pathName == 'auth0') {
				console.log('AppComponent A41= ' + pathName);
				//login page will be loaded through the splash screen
				//this.router.navigateByUrl(pathName);
			} else {
				console.log('AppComponent A4= ' + pathName);
				//login page will be loaded through the splash screen
				this.router.navigateByUrl('splash');
			}
		}
	}


	private checkDeviceType() {
		let isMobile: boolean = this.dds.isMobile()
		let isTablet: boolean = this.dds.isTablet()
		let isDesktopDevice: boolean = this.dds.isDesktop()
		let deviceType: string = Constants.DEVICE_TYPES.DESKTOP
		if(isDesktopDevice) {
			deviceType = Constants.DEVICE_TYPES.DESKTOP
		} else if (isMobile) {
			deviceType = Constants.DEVICE_TYPES.MOBILE
		} else if (isTablet) {
			deviceType = Constants.DEVICE_TYPES.TABLET
		} else {
			//do nothing
		}
		localStorage.setItem(LocalStorage.DEVICE_TYPE, deviceType)

	}

	private checkUrlParameters() : boolean{
		let url_string = window.location.href
		let url = new URL(url_string);
		let needToChangeProject: boolean = false
		let projectId1 = url.searchParams.get("projectid");
		let projectId2 = url.searchParams.get("projectId");
		let clientId1 = url.searchParams.get("clientid");
		let clientId2 = url.searchParams.get("clientId");
		if((projectId1 != null || projectId2 != null) && (clientId1 != null || clientId2 != null)){
			needToChangeProject = true;
		} else {
			needToChangeProject = false;
		}
		return needToChangeProject;
	}

	checkOnline() {
		return merge<boolean>(
		  fromEvent(window, 'offline').pipe(map(() => false)),
		  fromEvent(window, 'online').pipe(map(() => true)),
		  new Observable((sub: Observer<boolean>) => {
			sub.next(navigator.onLine);
			sub.complete();
		  }));
	  }

}