<div class="container-fluid px-0" id="web_editor"
    [style.height.px]="(this.configData == null || this.configData == undefined)?divHeight:''">
    <div *ngIf="this.configData == null || this.configData == undefined" class="row">
        <div class="col form-group mb-2">
            <div class="editor-title px-0" tooltip="{{ 'WEB_EDITOR.EDIT_TITLE' | translate }}" container="body"
                placement="bottom">
                <div contenteditable="true" id="title_editor" class="toggle-edit title_editor"></div>
            </div>
        </div>
    </div>
    <div *ngIf="this.configData == null || this.configData == undefined" class="col-sm-12 px-0 mb-2" hidden="true">
        <button tooltip="{{ 'WEB_EDITOR.SAVE' | translate }}" id={{saveDivId}} container="body" type="button"
            hidden="true" (click)="checkForTables('save')" class="btn btn-outline-primary btn-sm ml-1 mr-1 mb-1">
            <i class="fas fa-save" aria-hidden="true"></i>
        </button>
        <button tooltip="{{ 'WEB_EDITOR.CHECK_IN' | translate }}" id={{checkinDivId}} container="body" type="button"
            hidden="true" (click)="checkForTables('checkin')" class="btn btn-outline-primary btn-sm ml-1 mr-1 mb-1">
            <i class="fas fa-download" aria-hidden="true"></i>
        </button>
        <button tooltip="{{ 'WEB_EDITOR.CHECK_OUT' | translate }}" id={{checkoutDivId}} container="body" hidden="true"
            type="button" (click)="checkoutTopic()" class="btn btn-outline-primary btn-sm ml-1 mr-1 mb-1">
            <i class="fas fa-upload" aria-hidden="true"></i>
        </button>
        <button tooltip="{{ 'WEB_EDITOR.UNDO_CHECK_OUT' | translate }}" id={{undoCheckoutDivId}} hidden="true"
            container="body" type="button" (click)="openModalUndoCheckoutConfirm();"
            class="btn btn-outline-primary btn-sm ml-1 mr-1 mb-1">
            <i class="far fa-undo-alt" aria-hidden="true"></i>
        </button>
        <button tooltip="{{ 'WEB_EDITOR.ADD_SPE_CHAR' | translate }}" id="{{addSpecialCharDivId}}" container="body"
            type="button" hidden="true" (click)="openModal(spec_char)" class="btn btn-outline-primary btn-sm ml-1 mr-1">
            <i class="fas fa-omega" aria-hidden="true"></i>
        </button>
        <button tooltip="{{ 'WEB_EDITOR.ADD_FRACTION' | translate }}" id={{addFractionDIvId}} container="body"
            type="button" hidden="true" (click)="openModal(fraction)" class="btn btn-outline-primary btn-sm ml-1 mr-1">
            <i class="fas fa-divide" aria-hidden="true"></i>
        </button>
        <button tooltip="{{ 'WEB_EDITOR.COMMISSIONING' | translate }}" id="webeditor_commissioning" container="body"
            type="button" hidden="true" (click)="openModal2(template); punchlist();"
            class="btn btn-outline-primary btn-sm ml-1 mr-1">
            <i class="fa fa-th-list" aria-hidden="true"></i>
        </button>
        <!-- <button tooltip="{{ 'WEB_EDITOR.INSERT' | translate }}" id="webeditor_inserteditor" container="body"
            type="button" (click)="insertEditor()" class="btn btn-outline-primary btn-sm ml-1 mr-1 float-right">
            <i class="fas fa-plus-square" aria-hidden="true"></i>
        </button>
        <button tooltip="{{ 'WEB_EDITOR.CLEAR' | translate }}" id="webeditor_clear_tag_edit_area" container="body"
            type="button" (click)="clearTagEditArea()" class="btn btn-outline-primary btn-sm ml-1 mr-1 float-right">
            <i class="fas fa-eraser" aria-hidden="true"></i>
        </button> -->
    </div>
    <!-- <div *ngIf="this.configData == null || this.configData == undefined" class="col-sm-12 px-2">

        <div class="border rounded px-0 my-1">
            <div contenteditable="true" class="toggle-tagedit tag_editor"></div>
        </div>
    </div> -->
    <div id="{{tabId}}" class="col-sm-12 px-0 mb-2"></div>
    <div id="web_editor" class="col-sm-12 px-0 mb-2">
        <div #editor>{{value}}</div>
    </div>
</div>



<!-- <div class="col px-0">
    <div class="float-left p-0" [ngClass]="{'col-sm-8': showEditorLayoutPanel, 'col-sm-12' : !showEditorLayoutPanel}">
        <div #editor>{{value}}</div>
    </div>
	<div *ngIf="this.configData == null || this.configData == undefined">
	<button type="button" class="btn btn-warning" (click)="save()">{{ 'WEB_EDITOR.SAVE' | translate}}</button>
    <button type="button" style="margin-left:5px " class="btn btn-warning" (click)="openModal(checkin_comments)">{{ 'WEB_EDITOR.CHECK_IN' | translate}}</button>
    <button type="button" style="margin-left:5px " class="btn btn-warning" (click)="undoCheckout()">{{ 'WEB_EDITOR.UNDO_CHECK_OUT' | translate}}</button>
    </div> -->

<!-- <div class="vertical-button-panel">
        <button type="button" class="btn btn-primary btn-sm rotate">Tags</button>
        <button type="button" class="btn btn-primary btn-sm rotate">Statements</button>
        <button type="button" class="btn btn-primary btn-sm rotate">Components</button>
    </div> -->
<!-- <div class="vertical-button-panel">
        <div class="vertical-button-item ">
            <p class="p-2 rotate" style="margin-top: 20px">Tags</p>
        </div>
        <div class="vertical-button-item ">
            <p class="p-2 rotate" style="margin-top: 0px">Statements</p>
        </div>
        <div class="vertical-button-item ">
            <p class="p-2 rotate" style="margin-top: 0px">Components</p>
        </div>  
        <div class="vertical-button-item ">
            <p class="p-2 rotate" style="margin-top: 10px">Systems</p>
        </div>
        <div class="vertical-button-item ">
            <p class="p-2 rotate" style="margin-top: 10px">Documents</p>
        </div>
        <div class="vertical-button-item ">
            <p class="p-2 rotate" style="margin-top: 10px">Facilities</p>
        </div>
        <div class="vertical-button-item ">
            <p class="p-2 rotate" style="margin-top: 10px">Images</p>
        </div>
    </div>
    <div class="col-sm-4 float-right " *ngIf="showEditorLayoutPanel"></div> -->
<!-- </div> -->


<!-- checkin comments modal -->
<ng-template #checkin_comments>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{ 'WEB_EDITOR.CHECK_IN' | translate}}</h4>
        <button type="button" id="webeditor_bs_model_ref_close" class="close pull-right" aria-label="Close"
            (click)="bsModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <label>{{ 'WEB_EDITOR.COMMENTS' | translate}}</label>
        <textarea class="form-control" #comments></textarea>
    </div>
    <div class="modal-footer">
        <button type="button" id="webeditor_checkin" class="btn btn-primary" (click)="checkIn(comments.value)">
            {{ 'WEB_EDITOR.OK' | translate}}</button>
        <button type="button" id="webeditor_bs_model_ref_modal" class="btn btn-secondary" data-dismiss="modal"
            (click)="bsModalRef.hide()">
            {{ 'WEB_EDITOR.CANCEL' | translate}}</button>
    </div>
</ng-template>

<!-- table warning modal topic save-->
<ng-template #save_warn_for_tables>
    <div class="modal-header">
        <button type="button" id="webeditor_topic_save" class="close pull-right" aria-label="Close"
            (click)="tableWarnModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <label>{{ 'WEB_EDITOR.TABLE_WARNING' | translate}}</label>
    </div>
    <div class="modal-footer">
        <button type="button" id="webeditor_modal_footer_topic_save" class="btn btn-primary"
            (click)="tableWarnModalRef.hide(); save()">
            {{ 'WEB_EDITOR.OK' | translate}}</button>
        <button type="button" id="webeditor_modal_footer_modal" class="btn btn-secondary" data-dismiss="modal"
            (click)="tableWarnModalRef.hide()">
            {{ 'WEB_EDITOR.CANCEL' | translate}}</button>
    </div>
</ng-template>

<!-- table warning modal topic checkin-->
<ng-template #checkin_warn_for_tables>
    <div class="modal-header">
        <button type="button" id="webeditor_modal_header_topic_checkin" class="close pull-right" aria-label="Close"
            (click)="tableWarnModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <label>{{ 'WEB_EDITOR.SAVE_TABLE_WARNING' | translate}}</label>
    </div>
    <div class="modal-footer">
        <button type="button" id="webeditor_topic_checkin" class="btn btn-primary"
            (click)="tableWarnModalRef.hide(); openModal(checkin_comments); ">
            {{ 'WEB_EDITOR.OK' | translate}}</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="tableWarnModalRef.hide()">
            {{ 'WEB_EDITOR.CANCEL' | translate}}</button>
    </div>
</ng-template>

<!-- table warning modal-->
<ng-template #warn_for_tables>
    <div class="modal-header">
        <button type="button" id="webeditor_modal_header_topic_checkin" class="close pull-right" aria-label="Close"
            (click)="tableWarnModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <label>{{ 'WEB_EDITOR.TABLE_WARNING' | translate}}</label>
    </div>
    <div class="modal-footer">
        <button type="button" id="webeditor_topic_checkin" class="btn btn-primary" (click)="tableWarnModalRef.hide();">
            {{ 'WEB_EDITOR.OK' | translate}}</button>
    </div>
</ng-template>

<!-- special characters modal -->
<ng-template #spec_char>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{ 'WEB_EDITOR.SPE_CHAR' | translate}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-2" *ngFor="let speChar of speCharList; let n=index">
                    <button type="button" class="btn default" (click)="insertTagEditArea(speChar)"
                        style="background-color: inherit;">
                        {{speChar.data.symbol}}</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer" hidden="true">
        <label>{{ 'WEB_EDITOR.DEC_INPUT' | translate}}</label>
        <input (keypress)="numberOnly($event)" class="form-control input-sm" #dec_value type="text">
        <button type="button" class="btn btn-primary" (click)="addSpeChar(dec_value.value)">
            <i class="fa fa-plus" aria-hidden="true"></i>
        </button>
    </div>
</ng-template>

<!-- <ng-template #fraction>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{ 'WEB_EDITOR.FRACTION' | translate}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-2">
                    <input (keypress)="numberOnly($event)" class="form-control" #dec_value type="text">
                    <hr width="`100%">
                    <input (keypress)="numberOnly($event)" class="form-control" #dec_valueD type="text">
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary"
                            (click)="addSpeCharfraction(dec_value.value,dec_valueD.value)">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template> -->
<ng-template #fraction>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{ 'WEB_EDITOR.FRACTION' | translate}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid">
            <div class="row">
                <div class="input-group input-group-sm ">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Numerator / Denominator</span>
                    </div>
                    <input (keypress)="numberOnly($event)" #dec_value type="text" aria-label="Numerator"
                        class="form-control" [(ngModel)]="numerator">
                    <input (keypress)="numberOnly($event)" #dec_valueD type="text" aria-label="Denominator"
                        class="form-control" [(ngModel)]="denominator">
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer p-2">
        <div class="col-sm-12 px-0">
            <div class="badge badge-warning float-left"
                *ngIf="numerator != '' && denominator != '' && numerator != null && denominator != null">
                <span class="h6 m-0">{{numerator}} / {{denominator}}</span>
            </div>
            <button type="button" class="btn btn-secondary btn-sm float-right mx-1" (click)="bsModalRef.hide()">{{ 'CANCEL' |
								translate}}</button>
            <button type="button" class="btn btn-primary btn-sm float-right mx-1"
                (click)="bsModalRef.hide();addSpeCharfraction(dec_value.value,dec_valueD.value);">{{
								'ADD' | translate}}</button>
        </div>
    </div>
</ng-template>

<!-- commissioning modal -->
<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Commissioning</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body py-1">
        <div class="row mb-2">
            <div class="btn-group btn-group-sm my-1 mx-auto" role="group" aria-label="Basic example">
                <button type="button" class="btn " (click)="punchlist()"
                    [ngClass]="{'btn-dark': selectedComItem == 'punchlist', 'btn-outline-dark': selectedComItem != 'punchlist'}">Punchlist</button>
                <button type="button" class="btn btn-outline-dark" (click)="mcc()"
                    [ngClass]="{'btn-dark': selectedComItem == 'mcc', 'btn-outline-dark': selectedComItem != 'mcc'}">MCC</button>
                <button type="button" class="btn btn-outline-dark" (click)="scc()"
                    [ngClass]="{'btn-dark': selectedComItem == 'scc', 'btn-outline-dark': selectedComItem != 'scc'}">PCC</button>
                <button type="button" class="btn btn-outline-dark" (click)="stnptn()"
                    [ngClass]="{'btn-dark': selectedComItem == 'stnptn', 'btn-outline-dark': selectedComItem != 'stnptn'}">STN/PTN</button>
                <button type="button" class="btn btn-outline-dark" (click)="checksheet()"
                    [ngClass]="{'btn-dark': selectedComItem == 'checksheet', 'btn-outline-dark': selectedComItem != 'checksheet'}">
                    Check Sheet</button>
                <button type="button" class="btn btn-outline-dark" (click)="checksheetstatus()"
                    [ngClass]="{'btn-dark': selectedComItem == 'ccstatus', 'btn-outline-dark': selectedComItem != 'ccstatus'}">
                    Check Sheet Status</button>
                <button type="button" class="btn btn-outline-dark" (click)="checksheetsummary()"
                    [ngClass]="{'btn-dark': selectedComItem == 'ccsummary', 'btn-outline-dark': selectedComItem != 'ccsummary'}">
                    Check Sheet Summary</button>
            </div>
        </div>

        <hr class="mt-0 mb-2" />
        <!-- <tab heading="Punchlist" id="tab1" (click)="onSelect($event)">    -->

        <div class="form-group col" *ngIf="showCategory">
            <label>{{ 'WEB_EDITOR.CATEGORY' | translate }} :</label>
            <ng-select [active]="selected" (selected)="selectedCategory($event)" [items]="categoryList"
                placeholder="{{ 'WEB_EDITOR.ALL' | translate }}" name="punchlist_category" id="punchlist_category">
            </ng-select>
        </div>
        <div class="form-group col" *ngIf="showPunchlistStatus">
            <label>{{ 'WEB_EDITOR.PUNCHLIST_STATUS' | translate }} :</label>
            <ng-select [active]="selected" (selected)="selectedPunchlistStatus($event)" [items]="punchlistStausList"
                placeholder="{{ 'WEB_EDITOR.PUNCHLIST_STATUS_OPEN' | translate }}" name="punchlist_status"
                id="punchlist_status">
            </ng-select>
        </div>
        <div class="form-group col" *ngIf="showTypeChecksheets">
            <label>{{ 'WEB_EDITOR.TYPE' | translate }} :</label>
            <ng-select [active]="selected" [allowClear]="true" (removed)="removedType($event)"
                (selected)="selectedType($event)" [items]="typeList" placeholder="A" name="type" id="type">
            </ng-select>
        </div>
        <div class="form-group col" *ngIf="showTypeChecksheetStatus">
            <label>{{ 'WEB_EDITOR.TYPE' | translate }} :</label>
            <ng-select [active]="selected" [allowClear]="true" (removed)="removedType($event)"
                (selected)="selectedType($event)" [items]="typeList" placeholder="A" name="type" id="type">
            </ng-select>
        </div>
        <div class="form-group col" *ngIf="showTypeChecksheetSummary">
            <label>{{ 'WEB_EDITOR.TYPE' | translate }} :</label>
            <ng-select [active]="selected" [allowClear]="true" (removed)="removedType($event)"
                (selected)="selectedType($event)" [items]="typeList" placeholder="A" name="type" id="type">
            </ng-select>
        </div>

        <div class="form-group col" *ngIf="showVendor">
            <label>{{ 'WEB_EDITOR.VENDOR' | translate }} :</label>
            <ng-select [active]="selected" [allowClear]="true" (removed)="removedVendor($event)"
                (selected)="selectedVendor($event)" [multiple]="true" [items]="vendorList" name="vendor" id="vendor">
            </ng-select>
        </div>
        <label>{{ 'WEB_EDITOR.SYSTEMS' | translate }} :</label>
        <ag-grid-angular #agGrid style="width: 100%; height: 400px;" [rowData]="rowData"  [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }" class="ag-theme-balham"
            [columnDefs]="columnDefs"  [enableFilter]="false" [defaultColDef]="defaultColDef"
            [enableRangeSelection]="true" [pagination]="false"
            (columnResized)="onColumnResized($event)" (gridReady)="onGridReady($event)"
            [suppressMenuHide]="true" rowSelection="multiple" 
            [rowMultiSelectWithClick]="true" (rowSelected)="onRowSelected($event)" [getRowNodeId]="getRowNodeId"
            (selectionChanged)="onSelectionChanged()"
            [rowSelection]="rowSelection"></ag-grid-angular>

        <button class="btn btn-sm btn-outline-dark float-right my-2" (click)="insertCommissioningTables($event)">Add
            Table</button>


    </div>


</ng-template>
<!-- multi level list warning modal topic save-->
<ng-template #save_warn_for_lists_save>
    <div class="modal-header">
        <button type="button" id="webeditor_topic_checkin_list_warn" class="close pull-right" aria-label="Close"
            (click)="listWarnModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <label>{{ 'WEB_EDITOR.LIST_WARNING' | translate}}</label>
    </div>
    <div class="modal-footer">
        <button type="button" id="webeditor_modal_footer_save_list_warn" class="btn btn-primary"
            (click)="listWarnModalRef.hide(); save()">
            {{ 'WEB_EDITOR.OK' | translate}}</button>
        <button type="button" id="webeditor_modal_footer_save_list_warn" class="btn btn-secondary" data-dismiss="modal"
            (click)="listWarnModalRef.hide()">
            {{ 'WEB_EDITOR.CANCEL' | translate}}</button>
    </div>
</ng-template>

<!-- multi level lists warning modal topic checkin-->
<ng-template #save_warn_for_lists_checkin>
    <div class="modal-header">
        <button type="button" id="webeditor_topic_checkin_list_warn" class="close pull-right" aria-label="Close"
            (click)="listWarnModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <label>{{ 'WEB_EDITOR.LIST_WARNING' | translate}}</label>
    </div>
    <div class="modal-footer">
        <button type="button" id="webeditor_modal_footer_chekin_list_warn" class="btn btn-primary"
            (click)="listWarnModalRef.hide(); openModal(checkin_comments);">
            {{ 'WEB_EDITOR.OK' | translate}}</button>
        <button type="button" id="webeditor_modal_footer_chekin_list_warn" class="btn btn-secondary"
            data-dismiss="modal" (click)="listWarnModalRef.hide()">
            {{ 'WEB_EDITOR.CANCEL' | translate}}</button>
    </div>
</ng-template>
<!-- multi level list warning modal-->
<ng-template #warn_for_lists>
    <div class="modal-header">
        <button type="button" id="webeditor_modal_header_list_warn" class="close pull-right" aria-label="Close"
            (click)="listWarnModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <label>{{ 'WEB_EDITOR.LIST_WARNING_LOAD' | translate}}</label>
    </div>
    <div class="modal-footer">
        <button type="button" id="webeditor_list_warn" class="btn btn-primary" (click)="listWarnModalRef.hide();">
            {{ 'WEB_EDITOR.OK' | translate}}</button>
    </div>
</ng-template>

<!-- conflict checker modal -->
<ng-template #conflict_checker>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Conflicts</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body py-1">
        <label>{{ 'WEB_EDITOR.CONFLICTS_CHECK_MSG' | translate }}</label>
        <ag-grid-angular #agGrid style="width: 100%; height: 400px;" [rowData]="rowData" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }" class="ag-theme-balham"
            [columnDefs]="columnCCDefs"  [enableFilter]="false" [defaultColDef]="defaultColDef"
            [enableRangeSelection]="true" [pagination]="false"
            (gridReady)="onGridReady($event)" rowSelection="multiple" 
            [rowMultiSelectWithClick]="true" [getRowNodeId]="getRowNodeId" [rowSelection]="rowSelection">
        </ag-grid-angular>
        <button class="btn btn-sm btn-outline-dark float-right my-2" (click)="modalRef.hide();">Cancel</button>
        <button class="btn btn-sm btn-outline-dark float-right my-2 mx-2" (click)="autoUpdateProc();">Auto
            Update</button>
    </div>
</ng-template>

<!-- undo checkout warning modal-->
<ng-template #undo_checkout_warn>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{'PROCEDURE_EDITOR.UNDO_CHECKOUT' | translate }}</h4>
        <button type="button" id="webeditor_modal_header_list_warn" class="close pull-right" aria-label="Close"
            (click)="undocheckoutWarningModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h5 class='warn-text-editor'>{{ 'WARNING' | translate}}</h5>
        <h5>{{ 'WEB_EDITOR.UNDO_CHECK_OUT_CONFIRM' | translate}}</h5>
    </div>
    <div class="modal-footer">
        <button type="button" id="webeditor_modal_footer_chekin_list_warn" class="btn btn-primary"
            (click)="undocheckoutWarningModalRef.hide(); undoCheckout();">
            {{ 'WEB_EDITOR.OK' | translate}}</button>
        <button type="button" id="webeditor_modal_footer_chekin_list_warn" class="btn btn-secondary"
            data-dismiss="modal" (click)="undocheckoutWarningModalRef.hide()">
            {{ 'WEB_EDITOR.CANCEL' | translate}}</button>
    </div>
</ng-template>