import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { NgcFloatItemBtnComponent } from './ngc-float-item-btn/ngc-float-item-btn.component';
import { NgcFloatBtnComponent } from './ngc-float-btn/ngc-float-btn.component'

@NgModule({
	declarations: [
		NgcFloatItemBtnComponent, 
		NgcFloatBtnComponent
	],
	imports: [
		CommonModule,
		MatIconModule
	],
	exports: [
		NgcFloatItemBtnComponent, 
		NgcFloatBtnComponent
	]
})
export class NgcFloatBtnModule { }
