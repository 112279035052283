<div class="main-panel pt-0 pb-4" style="overflow: hidden;">
	<div class="container-fluid">
		<div class="col-sm-12 px-0">
			<h3>
				{{ 'DOCUMENT_STORE.DS_SIDEBAR_DELETED_DOCUMENT' | translate }}
			</h3>
			<hr class="mt-0 mb-3" />
			<div class="col-sm-12 px-0">
				<ag-grid-angular #agGrid style="width: 100%; height: 400px;" id="deletedDocumenteStore"
					[rowData]="rowData" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }" class="ag-theme-balham" [columnDefs]="columnDefs"
					[enableRangeSelection]="true" [paginationPageSize]="10" [pagination]="true" accentedSort = true
					(columnResized)="onColumnResized($event)" (gridReady)="onGridReady($event)" [defaultColDef]="defaultColDef"
					[suppressMenuHide]="true" [gridOptions]="gridOptions" [enableFilter]="true" [floatingFilter]="true"></ag-grid-angular>
			</div>
		</div>
	</div>
</div>