import {Injectable ,EventEmitter} from '@angular/core';
import {UserVariable} from "../../util/common/user-variable";
import {catchError, map} from "rxjs/operators";
import {WsResponse} from "../../util/ws-response.model";
import {Observable, throwError} from "rxjs";
import {ServiceUrls} from "../../util/service-urls";
import {HttpClient, HttpErrorResponse, HttpEventType, HttpParams} from "@angular/common/http";
import {CommonUtil} from "../../util/common/common-util";
import {SharedService} from "../../util/shared.service";
import {WsCallback} from "../../util/ws-callback.interface";
import {WsType} from "../../util/ws-type";
@Injectable({
  providedIn: 'root'
})
export class ManagemccService {
  mccComp:WsCallback;
  private apiGridData: any = null;
  private rowData: any = [];
  loadTableData = new EventEmitter<any>()
  updateUIafterApproval = new EventEmitter<any>();
  updateUIafterMccGenerate  = new EventEmitter<any>();
  constructor(private http: HttpClient, private commonUtil: CommonUtil,private sharedService: SharedService) { }
  public setApiGridData(apiGridData: any) {
    this.apiGridData = apiGridData;
  }
  public static getMCCService(){
      return this;
  }

  public loadTableDataRequest(){
    this.loadTableData.emit()
  }
  public loadTableDataResponse(){
    return this.loadTableData;
  }
    public updateUIafterApprovalRequest(mccId,uploaded){
        this.updateUIafterApproval.emit({mccId,uploaded})
    }
    public updateUIafterApprovalResponse(){
        return this.updateUIafterApproval;
    }
    public  updateUIafterMccGenerateRequest(){
        this.updateUIafterMccGenerate.emit()
    }
    public  updateUIafterMccGenerateResponse(){
        return this.updateUIafterMccGenerate;
    }
  public getApiGridData() {
    return this.apiGridData;
  }
  public setRowData(rowData: any) {
    this.rowData = rowData;
  }
  public getRowData() {
    return this.rowData;
  }
  public setMCCcomp(mccComp:WsCallback){
    this.mccComp = mccComp
  }

  public searchMCCList(json){
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    let url = this.getSearchMCCURL(token);
      return this.http.post(url, json).
      pipe(
        map(response => {
          var modified = JSON.parse(JSON.stringify(response));
          return new WsResponse(
              modified.status.description,
              modified.status.name,
              modified.status.code,
              modified.payload);
        }),
        catchError(error => {
          var modified = JSON.parse(JSON.stringify(error));
          var res = new WsResponse(
              modified.error.status.description,
              modified.error.status.name,
              modified.error.status.code,
              modified.error.payload);
          return throwError(res)
        })
    );
  }

  public getSearchMCCURL(token: String) {
        let url = ServiceUrls.GET_MC_SEARCH_RESULTS + '?token=' + token;
        return url;
  }

  updateMCDataURL(token){
    let url = ServiceUrls.UPDATE_MC_DATA + '?token=' + token ;
    return url;
  }
  updateMCData(data : any, callback: WsCallback) {
    let token = UserVariable.getUserToken();
    if(!this.commonUtil.validateToken(token)){
      return;
    }
    data = {
      ...data,
      userToken : token
    }
    let url = this.updateMCDataURL(token);
    this.http.post(url, JSON.stringify(data)).
    subscribe(
        data => {
          var modified = JSON.parse(JSON.stringify(data));
          var res = new WsResponse(
              modified.status.description,
              modified.status.name,
              modified.status.code,
              modified.payload);
          console.log("[addFacility] request was successful.")
          callback.onSuccess(res, WsType.UPDATE_MC_DATA);
        },
        error => {
          var modified = JSON.parse(JSON.stringify(error));
          var res = new WsResponse(
              modified.error.status.description,
              modified.error.status.name,
              modified.error.status.code,
              modified.error.payload);
          console.log("[addFacility] request was failed.")
          callback.onFail(res, WsType.UPDATE_MC_DATA);
        }
    );
  }

  revokeMechanicalCompletion(mcId: string ) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    let data = {
      mcId : mcId,
      userToken : token
    }
    let url  = ServiceUrls.revokeMechanicalCompletion(mcId)
    return this.http.post(url, JSON.stringify(data)).
    subscribe(
        data => {
          var modified = JSON.parse(JSON.stringify(data));
          var res = new WsResponse(
              modified.status.description,
              modified.status.name,
              modified.status.code,
              mcId);
          this.mccComp.onSuccess(res, WsType.REVOKE_MCC);
        },
        error => {
          var modified = JSON.parse(JSON.stringify(error));
          var res = new WsResponse(
              modified.error.status.description,
              modified.error.status.name,
              modified.error.status.code,
              modified.error.payload);
          this.mccComp.onFail(res, WsType.REVOKE_MCC);
        }
    );
  }

  approveMC(data : any, callback: WsCallback) {
    let token = UserVariable.getUserToken();
    if(!this.commonUtil.validateToken(token)){
      return;
    }

    let url = this.approveMCURL(token);
    this.http.post(url, JSON.stringify(data)).
    subscribe(
        data => {
          var modified = JSON.parse(JSON.stringify(data));
          var res = new WsResponse(
              modified.status.description,
              modified.status.name,
              modified.status.code,
              modified.payload);
          console.log("[addFacility] request was successful.")
          callback.onSuccess(res, WsType.APPROVE_MC);
        },
        error => {
          var modified = JSON.parse(JSON.stringify(error));
          var res = new WsResponse(
              modified.error.status.description,
              modified.error.status.name,
              modified.error.status.code,
              modified.error.payload);
          console.log("[addFacility] request was failed.")
          callback.onFail(res, WsType.APPROVE_MC);
        }
    );
  }
  approveMCURL(token){
    let url = ServiceUrls.APPROVE_MC + '?token=' + token ;
    return url;
  }

  downloadApprovalFile(projectId: string, mccId: string) {
    return this.http.get(ServiceUrls.DOWNLOAD_FILE,
        {
          responseType: "blob", params: new HttpParams().set("projectId", projectId).set("documentId", mccId)
              .set("mode", "ViewApprovedOutput").set("type", "MechanicalCompletion").set("fileName", "MCC")
        });
  }

  downloadSentFile(projectId: string, mccId: string) {
    return this.http.get(ServiceUrls.DOWNLOAD_FILE,
        {
          responseType: "blob", params: new HttpParams().set("projectId", projectId)
              .set("documentId", mccId).set("mode", "ViewCommOutput").set("type", "MechanicalCompletion").set("fileName", "MCC")
        });
  }

  public uploadFile(fileUploadData: FormData, callBack: WsCallback) {
    var token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    this.http.post(ServiceUrls.ADD_NEW_MC_DOCUMENT, fileUploadData, {
      reportProgress: true,
      observe: 'events'
    }).subscribe(
        event => {
          if (event.type == HttpEventType.UploadProgress) {
            //progress
          } else if (event.type == HttpEventType.Response) {
            var modified = JSON.parse(JSON.stringify(event.body));
            var res = new WsResponse(
                modified.status.description,
                modified.status.code,
                modified.status.name,
                modified.payload);
            callBack.onSuccess(res, WsType.ADD_NEW_MC_DOCUMENT);
          }
        },
        error => {
          if (error.error.payload != undefined) {
            var val = (error as HttpErrorResponse).error;
            var modified = JSON.parse(JSON.stringify(val));
            var res = new WsResponse(
                modified.status.description,
                modified.status.code,
                modified.status.name,
                modified.payload);
            callBack.onFail(res, WsType.ADD_NEW_MC_DOCUMENT);
          } else {
            var res: WsResponse;
            if (error.error.actionErrors != undefined) {
              res = new WsResponse(error.statusText + ' - ' + error.error.actionErrors);
            } else {
              res = new WsResponse(error.message);
            }
            callBack.onFail(res, WsType.ADD_NEW_MC_DOCUMENT);
          }
        }
    );
  }

  getMcByMcId(stnId, callBack: WsCallback) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    this.http.get(ServiceUrls.getMCByMcId(token, stnId)).subscribe(
        data => {
          var modified = JSON.parse(JSON.stringify(data));
          var res = new WsResponse(
              modified.status.code,
              modified.status.name,
              modified.status.description,
              modified.payload);
          callBack.onSuccess(res, WsType.GET_MC_BY_ID);
        },
        error => {
          var modified = JSON.parse(JSON.stringify(error));
          var res = new WsResponse(
              modified.status.code,
              modified.status.name,
              modified.status.description,
              modified.payload);
          callBack.onSuccess(res, WsType.GET_MC_BY_ID);
        });
  }

  updateMechanicalCompletion(mechanicalCompletion: any): Observable<WsResponse> {
    let token = UserVariable.getUserToken();
    // const headers = new Headers({ 'Content-Type': 'application/json' });
    delete mechanicalCompletion['isStart']
    delete mechanicalCompletion['newtargetCompletionDate']
    mechanicalCompletion['userToken'] = token
    return this.http.post(ServiceUrls.UPDATE_MC_DATA, JSON.stringify(mechanicalCompletion)).
    pipe( map(response => {
          var modified = JSON.parse(JSON.stringify(response));
          return new WsResponse(
              modified.status.description,
              modified.status.code,
              modified.status.name,
              modified.payload);
        }),
        catchError(error => {
          var modified = JSON.parse(JSON.stringify(error.error));
          var res = new WsResponse(
              modified.status.description,
              modified.status.code,
              modified.status.name,
              modified.payload);
          return throwError(res)
        })
    );
  }

  addMechanicalCompletion(mechanicalCompletion: any): Observable<WsResponse> {
    let token = UserVariable.getUserToken();
    // const headers = new Headers({ 'Content-Type': 'application/json' });
    delete mechanicalCompletion['isStart']
    mechanicalCompletion['userToken'] = token
    return this.http.post(ServiceUrls.ADD_MECHANICAL_COMPLETION, JSON.stringify(mechanicalCompletion)).
    pipe(
        map(response => {
          var modified = JSON.parse(JSON.stringify(response));
          return new WsResponse(
              modified.status.description,
              modified.status.code,
              modified.status.name,
              modified.payload);
        }),
        catchError(error => {
          var modified = JSON.parse(JSON.stringify(error.error));
          var res = new WsResponse(
              modified.status.description,
              modified.status.code,
              modified.status.name,
              modified.payload);
          return throwError(res)
        })
    );
  }

  deleteMechanicalCompletion(mccId: any) {
    let token = UserVariable.getUserToken();
    const headers = new Headers({ 'Content-Type': 'application/json' });
    let mechanicalCompletion = { 'userToken': token }
    return this.http.post(ServiceUrls.getDeleteMCC(mccId), JSON.stringify(mechanicalCompletion)).
    pipe(
        map(response => {
          var modified = JSON.parse(JSON.stringify(response));
          return new WsResponse(
              modified.status.description,
              modified.status.code,
              modified.status.name,
              modified.payload);
        }),
        catchError(error => {
          var modified = JSON.parse(JSON.stringify(error.error));
          var res = new WsResponse(
              modified.status.description,
              modified.status.code,
              modified.status.name,
              modified.payload);
          return throwError(res)
        })
    );
  }

    // checkIncompleteItemsForSystemList(systemIds: string, projectId: string, userId: string, clientId: string): Observable<WsResponse> {
    //     let token = UserVariable.getUserToken();
    //     const options = { params: new HttpParams().set("token", token).set("projectId", projectId).set("userId", userId).set("clientId", clientId).set("systemList", systemIds) }

    //     return this.http.post(ServiceUrls.checkIncompleteItemsForSystemList(), options).
    //     pipe(
    //         map(response => {
    //             var modified = JSON.parse(JSON.stringify(response));
    //             return new WsResponse(
    //                 modified.status.description,
    //                 modified.status.code,
    //                 modified.status.name,
    //                 modified.payload);
    //         }),
    //         catchError(error => {
    //             var modified = JSON.parse(JSON.stringify(error.error));
    //             var res = new WsResponse(
    //                 modified.status.description,
    //                 modified.status.code,
    //                 modified.status.name,
    //                 modified.payload);
    //             return throwError(res);
    //         })
    //     );
    // }




    checkIncompleteItemsForSystemList(systemIds: string, projectId: string, userId: string, clientId: string) {
      let token = UserVariable.getUserToken();
      if (!this.commonUtil.validateToken(token)) {
        return;
      }
      let url = this.checkIncompleteItemsForSystemListURL(token);
      //const options = { params: new HttpParams().set("token", token).set("projectId", projectId).set("userId", userId).set("clientId", clientId).set("systemList", systemIds) }
      console.log("system ids==="+systemIds)
      var systemList = systemIds
      return this.http.post(url, JSON.stringify(new Request(systemIds))).
      pipe(
          map(response => {
              var modified = JSON.parse(JSON.stringify(response));
              return new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
          }),
          catchError(error => {
              var modified = JSON.parse(JSON.stringify(error.error));
              var res = new WsResponse(
                  modified.status.description,
                  modified.status.code,
                  modified.status.name,
                  modified.payload);
              return throwError(res);
          })
      );
  }
  public checkIncompleteItemsForSystemListURL(token: String) {
    let url = ServiceUrls.checkIncompleteItemsForSystemList + '?token=' + token;
    return url;
  }

  
}
class Request {
  constructor(private systemIds: String) { }
}
