import { Component, OnInit } from '@angular/core';
import { FilesService } from 'src/app/home/files/files.service';
import * as FileSaver from 'file-saver';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { LoadingService } from '../loading/loading.service';
import { AlertService } from '../alert/alert.service';

@Component({
  selector: 'app-file-download',
  templateUrl: './file-download.component.html',
  styleUrls: ['./file-download.component.css']
})
export class FileDownloadComponent implements OnInit {
  documentId: string = null;
  isParamsEmpty: boolean;
  isSideBar: boolean = true;
  queryParams: Params
  constructor(private filesService: FilesService, private route: ActivatedRoute, private loadingService: LoadingService,
    private alertService: AlertService) { }

  ngOnInit() {
    this.route.queryParams.subscribe(
      (params: Params) => {
        this.documentId = params.documentId;
        this.queryParams = params;
      }
    );
    this.filesService.getFileDownloadDataByFileId(this.documentId).subscribe(
      data => {
        this.downloadFile(data.payload.outputFriendlyName);
      },
      error => {

      }
    );
  }

  downloadFile(fileName: string) {
    this.loadingService.showLoading(true, null, "Downloading", null);
    if (this.queryParams.hasOwnProperty("mode") && this.queryParams.hasOwnProperty("documentId")) {
      this.filesService.downloadFile(this.documentId, fileName).subscribe(
        response => {
          let file = new Blob([response]);
          FileSaver.saveAs(file, fileName);
          this.alertService.success("File downloaded succesfully!")
          this.loadingService.hideLoading();
        },
        error => {
          this.alertService.error("Error downloading file!")
        });
      this.isParamsEmpty = false;
    } else {
      this.isParamsEmpty = true;
    }
  }
}
