import { UserRolesComponent } from '.././user-roles.component';
import { Component, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { RolesService } from '.././roles.service';
import { WsCallback } from 'src/app/util/ws-callback.interface';
import { WsResponse } from 'src/app/util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';
import { LoadingService } from 'src/app/util/loading/loading.service';
import { CommonUtil } from 'src/app/util/common/common-util';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserVariable } from 'src/app/util/common/user-variable';
import { AlertService } from 'src/app/util/alert/alert.service';
import { SharedService } from '../../../../util/shared.service';
import { GridOptions } from "@ag-grid-enterprise/all-modules";
import { ColDef } from "@ag-grid-enterprise/all-modules";
import { CapabilityService } from 'src/app/util/capability.service';

@Component({
  selector: 'app-change-capabilities',
  templateUrl: './change-capabilities.component.html',
  styleUrls: ['./change-capabilities.component.css']
})
export class ChangeCapabilitiesComponent implements OnInit, WsCallback  {

  public projectCatList: any = [];
  public systemCatList: any = [];
  public clientCatList: any = [];
  private bsModalRef: BsModalRef;
  private token: any;
  private scope: string;
  private capList: string;
  public assignRoleCapabilities: boolean = false;
  //private customSave: boolean;

  constructor(private roleService: RolesService, private translate: TranslateService, private alertService: AlertService, private sharedService: SharedService,
		private modalService: BsModalService, private router: Router, private loadingService: LoadingService, private commonUtil: CommonUtil, public userRolesComponent: UserRolesComponent,
    private capabilityService: CapabilityService) { 
      this.checkCapability()
      translate.addLangs(["es", "en"]);
      translate.setDefaultLang("en");
      let browserLang = translate.getBrowserLang();
      translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
  
      //Initialization of variables
          this.token = UserVariable.getUserToken();
          if (!this.commonUtil.validateLogin(this.token)) {
              return;
          }
          this.capList ="";
  }

  ngOnInit() {
    var data: any = {
      "scope": "PROJECT"
		};
		var json = JSON.stringify(data);
    this.roleService.getCapCategoryList(this.token, json, this);

    var data: any = {
      "scope": "SYSTEM"
		};
		var json = JSON.stringify(data);
    this.roleService.getCapCategoryList(this.token, json, this);

    var data: any = {
      "scope": "CLIENT"
		};
		var json = JSON.stringify(data);
    this.roleService.getCapCategoryList(this.token, json, this);
    
  }

  status: boolean = false;
  pageToggle() {
    this.status = !this.status;
    $('.page-container').toggleClass('toggled');
    $('li.role').removeClass('active-role');
  }

  collapseAll(event) {
    let target = event.target || event.srcElement || event.currentTarget;
    if (!$(target).hasClass("expand") ) {
      $(target).addClass('expand');
      $('#accordionExample .collapse').removeClass('show');  
    }else{
      $(target).removeClass('expand'); 
      $('#accordionExample .collapse').addClass('show'); 
    }
    
   // this.customSave = false;
    //$('.panel-collapse.in').collapse('hide');
  }

  onSuccess(data: WsResponse, serviceType: WsType) {		
    if(serviceType == WsType.GET_ROLE_CATEGORY){
      this.setRoleCategory(data.payload);  
  }
}

  onFail(data: WsResponse, serviceType: WsType) {
    this.loadingService.hideLoading();
    if(serviceType == WsType.GET_ROLE_CATEGORY){
      this.alertService.error(data.statusDescription);
    }
  }

  setRoleCategory(payload: any) {
    this.loadingService.hideLoading();
    //console.log(payload[0]["category"]);
    if (payload[0].scope == "PROJECT") {
      this.projectCatList = [];
    } else if (payload[0].scope == "SYSTEM") {
      this.systemCatList = [];
    } else if (payload[0].scope == "CLIENT") {
      this.clientCatList = [];
    } 
    // this.rowData = [];
    if (payload != null) {
        
            if (payload != null) {
            payload.forEach(element => {
              if(element.scope == "PROJECT") {
                this.projectCatList.push({ 
                'id': element.category.replace(/\s/g, ''),
                'category': element.category,
                'scope': element.scope,
                });
              } else if(element.scope == "SYSTEM") {
                this.systemCatList.push({ 
                'id': element.category.replace(/\s/g, ''),
                'category': element.category,
                'scope': element.scope,
                });
              } else if(element.scope == "CLIENT") {
                this.clientCatList.push({ 
                'id': element.category.replace(/\s/g, ''),
                'category': element.category,
                });
              }
            });
        }   
    } 
  }

  onChange(event, j, id, scope) {
    this.scope = scope;
    if (event == true) {
      if (scope == "SYSTEM") {
        this.userRolesComponent.systemCapbilityList[j].added = true;
      } else if (scope == "PROJECT") {
        this.userRolesComponent.projectCapbilityList[j].added = true;
      } else if (scope == "CLIENT") {
        this.userRolesComponent.clientCapbilityList[j].added = true;
      }
     // this.capList = this.capList + "_" + id;
    } else {
      if (scope == "SYSTEM") {
        this.userRolesComponent.systemCapbilityList[j].added = false;
      } else if (scope == "PROJECT") {
        this.userRolesComponent.projectCapbilityList[j].added = false;
      } else if (scope == "CLIENT") {
        this.userRolesComponent.clientCapbilityList[j].added = false;
      }
    }
   
  }

  onChangeApplyToAll(event){   
    this.scope = "PROJECT";
    this.userRolesComponent.applyAll = true; 
    //this.customSave = true;
    this.userRolesComponent.applyClients = true;
    /*this.scope = "PROJECT";
    if (event == true) {
      this.userRolesComponent.applyAll = true;
      for ( let i = 0; i < this.userRolesComponent.systemCapbilityList.length; i++) {
        this.userRolesComponent.systemCapbilityList[i].added = true;
      }
      for ( let i = 0; i < this.userRolesComponent.projectCapbilityList.length; i++) {
        this.userRolesComponent.projectCapbilityList[i].added = true;
      }
      for ( let i = 0; i < this.userRolesComponent.clientCapbilityList.length; i++) {
        this.userRolesComponent.clientCapbilityList[i].added = true;
      }
    } else {
      for ( let i = 0; i < this.userRolesComponent.systemCapbilityList.length; i++) {
        this.userRolesComponent.systemCapbilityList[i].added = false;
      }
      for ( let i = 0; i < this.userRolesComponent.projectCapbilityList.length; i++) {
        this.userRolesComponent.projectCapbilityList[i].added = false;
      }
      for ( let i = 0; i < this.userRolesComponent.clientCapbilityList.length; i++) {
        this.userRolesComponent.clientCapbilityList[i].added = false;
      }
    }
    this.customCapability(event);*/
  }

  /*customCapability(event) {
    if (event == true) {
      this.customSave = true;
      this.userRolesComponent.applyClients = true;
    } else {
      this.userRolesComponent.applyClients = false;
      this.customSave = false;
    }
  }*/

  saveCapability() {    
    this.loadingService.showLoading(true, false, 'Saving', 0);
    for ( let i = 0; i < this.userRolesComponent.systemCapbilityList.length; i++) {
      if (this.userRolesComponent.systemCapbilityList[i].added == true) {
        this.capList = this.capList + "_" + this.userRolesComponent.systemCapbilityList[i].id;
      }
    }
    for ( let i = 0; i < this.userRolesComponent.projectCapbilityList.length; i++) {
      if (this.userRolesComponent.projectCapbilityList[i].added == true) {
        this.capList = this.capList + "_" + this.userRolesComponent.projectCapbilityList[i].id;
      }
    }
    for ( let i = 0; i < this.userRolesComponent.clientCapbilityList.length; i++) {
      if (this.userRolesComponent.clientCapbilityList[i].added == true) {
        this.capList = this.capList + "_" + this.userRolesComponent.clientCapbilityList[i].id;
      }
    }
    console.log("Capability list" + this.capList);
   
    this.roleService.saveDefaultRoleCapabilities(this.scope, this.sharedService.getRoleId(), this.capList).subscribe(
      data => {         
        //  if (this.userRolesComponent.applyClients) {
          this.roleService.saveCustomRoleCapabilities(this.sharedService.getRoleId(), this.capList).subscribe(
            data => {
               this.capList ="";
               this.userRolesComponent.applyClients = false;
               this.loadingService.hideLoading()         
               this.alertService.clear()
               this.alertService.success("Successfully added capabilities for the role");
               this.userRolesComponent.applyAll = false; 
               this.bsModalRef.hide()
            },
            error => {
              // this.capList ="";
               this.loadingService.hideLoading()
               this.alertService.clear()
               this.alertService.error(error.statusDescription)
            });
        // }
        // else {
        //  this.capList ="";
        //  this.loadingService.hideLoading()         
        //  this.alertService.clear()
        //  this.alertService.success("Successfully added capabilities for the role")
        //  this.bsModalRef.hide()
        // }
      },
      error => {
        // this.capList ="";
         this.loadingService.hideLoading()
         this.alertService.clear()
         this.alertService.error(error.statusDescription)
      });
  }

  /**
   * Achini Randeni
   * Select all capabilities
   */
  selectAll(){
    for ( let i = 0; i < this.userRolesComponent.systemCapbilityList.length; i++) {
      this.userRolesComponent.systemCapbilityList[i].added = true;
    }
    for ( let i = 0; i < this.userRolesComponent.projectCapbilityList.length; i++) {
      this.userRolesComponent.projectCapbilityList[i].added = true;
    }
    for ( let i = 0; i < this.userRolesComponent.clientCapbilityList.length; i++) {
      this.userRolesComponent.clientCapbilityList[i].added = true;
    }

    this.scope = "PROJECT";
  }

  /**
   * Achini Randeni
   * Deselect all capabilities
   */
  deselectAll(){
    for ( let i = 0; i < this.userRolesComponent.systemCapbilityList.length; i++) {
      this.userRolesComponent.systemCapbilityList[i].added = false;
    }
    for ( let i = 0; i < this.userRolesComponent.projectCapbilityList.length; i++) {
      this.userRolesComponent.projectCapbilityList[i].added = false;
    }
    for ( let i = 0; i < this.userRolesComponent.clientCapbilityList.length; i++) {
      this.userRolesComponent.clientCapbilityList[i].added = false;
    }

    this.scope = "PROJECT";
  }

  public checkCapability() {

    let assignCapabilitiesCapability: string = "assignRoleCapabilities"
  
    this.assignRoleCapabilities = this.capabilityService.isCapabilityAssigned(assignCapabilitiesCapability)


	}
}
