import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse ,HttpParams } from '@angular/common/http';
import { UserVariable } from '../../util/common/user-variable';
import { ServiceUrls } from '../../util/service-urls';
import { WsResponse } from '../../util/ws-response.model';
import { WsCallback } from '../../util/ws-callback.interface';
import { WsType } from '../../util/ws-type';
import { CommonUtil } from '../common/common-util';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { SharedService } from '../shared.service';

@Injectable()
export class DependenciesModalService {
    private callback: WsCallback;
    private courseId: String;

    constructor(private http: HttpClient, private commonUtil: CommonUtil, private sharedService: SharedService) {
    }

    public setCallback(callBack: WsCallback) {
        this.callback = callBack;
    }

	/**
     * Get dependencies. 
     * @param itemId 
     * @param itemType 
     */
    getDependecies(itemId: string, itemType: string, action: string) {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateLogin(token)) {
            return;
        }

        const options = { params: new HttpParams().set("token", token).set("itemId", itemId).set("itemType", itemType).set("action", action) }
        let url = this.getDependenciesURL(itemId, itemType);
        this.http.get(url, options).
            subscribe(
                data => {
                    var modified = JSON.parse(JSON.stringify(data));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                    this.callback.onSuccess(res, WsType.ITEM_DEPENDENCY_LIST);
                },
                error => {
                    if (error.status != '') {
                        var val = (error as HttpErrorResponse).error;
                        var modified = JSON.parse(JSON.stringify(val));
                        var res = new WsResponse(
                            modified.status.description,
                            modified.status.name,
                            modified.status.code,
                            modified.payload);
                        this.callback.onFail(res, WsType.ITEM_DEPENDENCY_LIST);
                    } else {
                        //browser related issues
                        if (navigator.onLine == false) {
                            var res = new WsResponse("We lost the connection with the server");
                        } else {
                            var res = new WsResponse("Unknown error happened");
                        }
                        this.callback.onFail(res, WsType.ITEM_DEPENDENCY_LIST);
                    }
                });
    }


	/**
     * Get dependencies. 
     * @param itemId 
     * @param itemType 
     */
    getDependeciesWithId(itemId: String, itemType: String) {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateLogin(token)) {
            return;
        }
        const options = { params: new HttpParams().set("token", token) }
        //let url = ServiceUrls.COMPONENTS + itemId + '/getDependeciesWithId.json';
        let url = this.getDependenciesWithIdURL(itemId, itemType);
        this.http.get(url, options).
            subscribe(
                data => {
                    var modified = JSON.parse(JSON.stringify(data));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload + "_" + itemId);
                    this.callback.onSuccess(res, WsType.ITEM_DEPENDENCY_LIST);
                },
                error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                    this.callback.onFail(res, WsType.ITEM_DEPENDENCY_LIST);
                });
    }

    /**
     * Disable item by id. 
     * @param itemId 
     * @param itemType 
     */
    disableItem(itemId: String, itemType: String) {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateLogin(token)) {
            return;
        }
        const options = { params: new HttpParams().set("token", token) }
        let url = this.disableItemByIdURL(itemId, itemType);
        this.http.get(url, options).
            subscribe(
                data => {
                    var modified = JSON.parse(JSON.stringify(data));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                    this.callback.onSuccess(res, WsType.ITEM_DISABLE_ENABLE);
                },
                error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    this.callback.onFail(res, WsType.ITEM_DISABLE_ENABLE);
                });
    }

    /**
     * Enable item by id. 
     * @param itemId 
     * @param itemType 
     */
    enableItem(itemId: String, itemType: String) {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateLogin(token)) {
            return;
        }
        const options = { params: new HttpParams().set("token", token) }
        let url = this.enableItemByIdURL(itemId, itemType);
        this.http.get(url, options).
            subscribe(
                data => {
                    var modified = JSON.parse(JSON.stringify(data));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                    this.callback.onSuccess(res, WsType.ITEM_DISABLE_ENABLE);
                },
                error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                    this.callback.onFail(res, WsType.ITEM_DISABLE_ENABLE);
                });
    }

    /**
     * Delete item by id. 
     * @param itemId 
     * @param itemType 
     */
    deleteItem(itemId: string, itemType: String) {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateLogin(token)) {
            return;
        }
        const options = { params: new HttpParams().set("token", token) }
        if (itemType == "ICETopic") {
            var request = {
                "courseId": this.courseId,
                "topicParentId": null,
                "sequence": null
            }
            this.http.post(ServiceUrls.deleteICETopic(token, itemId), JSON.stringify(request)).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                this.callback.onSuccess(res, WsType.DELETE_ITEM);
            },
            error => {
                var modified = JSON.parse(JSON.stringify(error));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                this.callback.onFail(res, WsType.DELETE_ITEM);               
            });
        } else {
            let url = this.deleteItemByIdURL(itemId, itemType);
            this.http.delete(url, options).
                subscribe(
                    data => {
                        var modified = JSON.parse(JSON.stringify(data));
                        console.log("deleteItem: " + JSON.stringify(data));
                        if (modified.payload != false) {
                            modified.payload = itemId;
                        }
                        var res = new WsResponse(
                            modified.status.description,
                            modified.status.name,
                            modified.status.code,
                            modified.payload);
                        this.callback.onSuccess(res, WsType.DELETE_ITEM);
                    },
                    error => {
                        var modified = JSON.parse(JSON.stringify(error));
                        var res = new WsResponse(
                            modified.status.description,
                            modified.status.code,
                            modified.status.name,
                            modified.payload);
                        this.callback.onFail(res, WsType.DELETE_ITEM);
                    });
            }
    }

    getDependeciesMultipleItems(selectedItems: string, action: string) {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateLogin(token)) {
            return;
        }
        const options = { params: new HttpParams().set("token", token).set("multipleSelectedItems", selectedItems).set("action", action) }
        let url = this.getDependenciesMultipleItemsURL();
        this.http.get(url, options).
            subscribe(
                data => {
                    var modified = JSON.parse(JSON.stringify(data));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                    this.callback.onSuccess(res, WsType.ITEM_DEPENDENCY_LIST);
                },
                error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                    this.callback.onFail(res, WsType.ITEM_DEPENDENCY_LIST);
                });
    }

    deleteAll(itemList: any, callback: WsCallback = this.callback) {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        let url = ServiceUrls.DELETE_ALL;
        const options = { params: new HttpParams().set("token", token).set("itemList", itemList) }

        this.http.get(url, options).
            subscribe(
                data => {
                    var modified = JSON.parse(JSON.stringify(data));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                    callback.onSuccess(res, WsType.MULTIPLE_ACTIONS);
                },
                error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    callback.onFail(res, WsType.MULTIPLE_ACTIONS);
                });
    }

    public getDependenciesMultipleItemsURL() {
        let url: any;
        url = ServiceUrls.DEPENDENCY + 'getdependency/getDependenciesMultipleItems.json';
        return url;
    }

    /**
    * Define URL for get dependencies url.
    */
    public getDependenciesURL(itemId: String, itemType: String) {
        let url: any;
        if (itemType == "Topic") {
            url = ServiceUrls.DEPENDENCY + itemId + '/getDependencies.json';
        } else if (itemType == "Document") {
            url = ServiceUrls.DEPENDENCY + itemId + '/getDependencies.json';
        } else if (itemType == "Facility") {
            url = ServiceUrls.DEPENDENCY + itemId + '/getDependencies.json';
        } else if (itemType == "Procedure") {
            url = ServiceUrls.DEPENDENCY + "/" + itemId + '/getDependencies.json';
        } else if (itemType == "Statement") {
            url = ServiceUrls.DEPENDENCY + "/" + itemId + '/getDependencies.json';
        } else if (itemType == "Component") {
            url = ServiceUrls.DEPENDENCY + itemId + '/getDependencies.json';
        } else if (itemType == "System") {
            url = ServiceUrls.DEPENDENCY + itemId + '/getDependencies.json';
        } else if (itemType == "ContentItem") {
            url = ServiceUrls.DEPENDENCY + itemId + '/getDependencies.json';
        } else if (itemType == 'TagClassificationAttributeMap') {
            url = ServiceUrls.CONTENTS + itemId + '/getDependencies.json';
        } else if (itemType == 'TagClassificationAttribute') {
            url = ServiceUrls.CONTENTS + itemId + '/getDependencies.json';
        } else if (itemType == "TopicMap") {
            url = ServiceUrls.DEPENDENCY + itemId + '/getDependencies.json';
        } else if (itemType == "Reusableupdates") {
            url = ServiceUrls.DEPENDENCY + itemId + '/getDependencies.json';
        } else if (itemType == "Image") {
            url = ServiceUrls.DEPENDENCY + itemId + '/getDependencies.json';
        } else if (itemType == "Client") {
            url = ServiceUrls.DEPENDENCY + itemId + '/getDependencies.json';
        } else if (itemType == "Project") {
            url = ServiceUrls.DEPENDENCY + itemId + '/getDependencies.json';
        } else if (itemType == "ICECourse") {
            url = ServiceUrls.DEPENDENCY + itemId + '/getDependencies.json';
        } else if (itemType == "Folder") {
            url = ServiceUrls.DEPENDENCY + itemId + '/getDependencies.json';
        } else if (itemType == "DocumentType") {
            if (this.sharedService.getLoadedComponent() == "documenttype") {
                url = ServiceUrls.DEPENDENCY + itemId + '/getDependencies.json';
            } else if (this.sharedService.getLoadedComponent() == "manageattributes") {
                url = ServiceUrls.DOCUMENT_TYPES_ATTRIBUTES_VIEW + itemId + '/getDependencies.json';
            }
        } else if (itemType == "ThreeDModel") {
            url = ServiceUrls.DEPENDENCY + itemId + '/getDependencies.json';
        } else if (itemType == "Video") {
            url = ServiceUrls.DEPENDENCY + itemId + '/getDependencies.json';
        } else if (itemType == "PunchList") {
            url = ServiceUrls.DEPENDENCY + itemId + '/getDependencies.json';
        } else if (itemType == "User") {
            url = ServiceUrls.DEPENDENCY + itemId + '/getDependencies.json';
        } else if (itemType == "ICETopic") {
            url = ServiceUrls.DEPENDENCY + itemId + '/getDependencies.json';
        } else if (itemType == "ICELearningCheckData") {
            url = ServiceUrls.DEPENDENCY + itemId + '/getDependencies.json';
        } else if(itemType == "Workflow"){
            url = ServiceUrls.DEPENDENCY + itemId + '/getDependencies.json';
        } else {
            url = ServiceUrls.DEPENDENCY + itemId + '/getDependencies.json';
        }

        return url;
    }

	/**
* Define URL for get dependencies url.
*/
    public getDependenciesWithIdURL(itemId: String, itemType: String) {
        console.log("type " + itemType)
        let url: any;
        if (itemType == "Topic") {
            url = ServiceUrls.TOPICS + itemId + '/getDependeciesWithId.json';
        } else if (itemType == "Document") {
            url = ServiceUrls.DOCUMENTS + itemId + '/getDependeciesWithId.json';
        } else if (itemType == "Facility") {
            url = ServiceUrls.FACILITIES + itemId + '/getDependeciesWithId.json';
        } else if (itemType == "Procedure") {
            url = ServiceUrls.PROCEDURES + "/" + itemId + '/getDependeciesWithId.json';
        } else if (itemType == "Statement") {
            url = ServiceUrls.STATEMENTS + "/" + itemId + '/getDependeciesWithId.json';
        } else if (itemType == "Component") {
            url = ServiceUrls.COMPONENTS + itemId + '/getDependeciesWithId.json';
        } else if (itemType == "System") {
            url = ServiceUrls.SYSTEMS + itemId + '/getDependeciesWithId.json';
        } else if (itemType == "ContentItem") {
            url = ServiceUrls.CONTENTS + itemId + '/getDependeciesWithId.json';
        } else if (itemType == 'TagClassificationAttributeMap') {
            url = ServiceUrls.CONTENTS + itemId + '/getDependeciesWithId.json';
        } else if (itemType == 'TagClassificationAttribute') {
            url = ServiceUrls.CONTENTS + itemId + '/getDependeciesWithId.json';
        } else if (itemType == "TopicMap") {
            url = ServiceUrls.TOPIC_MAPS + itemId + '/getDependeciesWithId.json';
        } else if (itemType == "Reusableupdates") {
            url = ServiceUrls.REUSABLE_UPDATES + itemId + '/getDependeciesWithId.json';
        } else if (itemType == "Image") {
            url = ServiceUrls.IMAGES + itemId + '/getDependeciesWithId.json';
        } else if (itemType == "Client") {
            url = ServiceUrls.CLIENTS + itemId + '/getDependeciesWithId.json';
        } else if (itemType == "Project") {
            url = ServiceUrls.PROJECTS + itemId + '/getDependeciesWithId.json';
        } else if (itemType == "Tag") {
            url = ServiceUrls.TAGS + '/' + itemId + '/getDependeciesWithId.json';
        } else if (itemType == "ThreeDModel") {
            url = ServiceUrls.THREED_MODELS + itemId + '/getDependeciesWithId.json';
        } else if (itemType == "DocumentType") {
            if (this.sharedService.getLoadedComponent() == "documenttype") {
                url = ServiceUrls.DOCUMENT_TYPES_ATTRIBUTES_VIEW + itemId + '/getDocumentTypeDependeciesWithId.json';
            } else if (this.sharedService.getLoadedComponent() == "manageattributes") {
                url = ServiceUrls.DOCUMENT_TYPES_ATTRIBUTES_VIEW + itemId + '/getDependeciesWithId.json';
            }
        } else if (itemType == "PunchList") {
            url = ServiceUrls.PUNCHLISTS + itemId + '/getDependeciesWithId.json';
        } else if (itemType == "Workflow") {
            url = ServiceUrls.WORKFLOWS + itemId + '/getDependeciesWithId.json';
        } else {
            url = ServiceUrls.IMAGES + itemId + '/getDependeciesWithId.json';
        }

        return url;
    }
    /**
    * Define URL to disable item by item id.
    */
    public disableItemByIdURL(itemId: String, itemType: String) {
        let url: any;
        console.log("disable item type:" + itemType);
        if (itemType == "Topic") {
            url = ServiceUrls.TOPICS + itemId + '/disableTopic.json';
        }
        else if (itemType == "TopicMap") {
            url = ServiceUrls.TOPIC_MAPS + itemId + '/disableTopicMap.json';
        }
        else if (itemType == "Statement") {
            url = ServiceUrls.STATEMENTS + "/" + itemId + '/disableStatement.json';
        }
        else if (itemType == "Facility") {
            url = ServiceUrls.FACILITIES + itemId + '/disableFacility.json';
        }
        else if (itemType == "Procedure") {
            url = ServiceUrls.PROCEDURES + itemId + '/disableProcedure.json';
        }
        else if (itemType == "System") {
            url = ServiceUrls.SYSTEMS + itemId + '/disableSystem.json';
        }
        else if (itemType == "Document") {
            url = ServiceUrls.DOCUMENTS + itemId + '/disableDocument.json';
        }
        else if (itemType == "Component") {
            url = ServiceUrls.COMPONENTS + itemId + '/disableComponent.json';
        }
        else if (itemType == "ContentItem") {
            url = ServiceUrls.CONTENTS + itemId + '/disableContent.json';
        }
        else if (itemType == "Image") {
            url = ServiceUrls.IMAGES + itemId + '/disableImage.json';
        }
        else if (itemType == "Client") {
            url = ServiceUrls.CLIENTS + itemId + '/disableClient.json';
        }
        else if (itemType == "User") {
            url = ServiceUrls.USER_PROJECT_VIEW + itemId + '/disableUser.json';
        }
        else if (itemType == "Project") {
            url = ServiceUrls.PROJECTS + itemId + '/disableProject.json';
        }
        else if (itemType == "ICECourse") {
            url = ServiceUrls.ICECOURSE + itemId + '/disableICECourse.json';
        }
        else if (itemType == "ThreeDModel") {
            url = ServiceUrls.THREED_MODELS + itemId + '/disableThreeDModel.json';
        }
        else if (itemType == "Video") {
            url = ServiceUrls.VIDEOS + itemId + '/disableVideo.json';
        } else if (itemType == "PunchList") {
            url = ServiceUrls.PUNCHLISTS + itemId + '/disablePunchlist.json';
        } else if (itemType == "DocumentType") {
            url = ServiceUrls.DISABLE_DOC_TYPE + '?' + 'docTypeId=' + itemId + '&' + "status=inactive";
        } else if(itemType == "Workflow"){
            url = ServiceUrls.WORKFLOWS + itemId + '/disableWorkflowTemplate.json';
        } else if(itemType == "CheckSheet"){
            url = ServiceUrls.CHECKSHEET + itemId + '/disableChecksheet.json';
        }
        else if(itemType == "Check"){
            url = ServiceUrls.CHECKS + itemId + '/disableCheck.json';
        }
        console.log("disable url:" + url);
        // console.log("url " + url)
        return url;
    }

    /**
    * Define URL to enable item by item id.  c2 v
    */
    public enableItemByIdURL(itemId: String, itemType: String) {
        let url: any;
        if (itemType == "Topic") {
            url = ServiceUrls.TOPICS + itemId + '/enableTopic.json';
        }
        if (itemType == "TopicMap") {
            url = ServiceUrls.TOPIC_MAPS + itemId + '/enableTopicMap.json';
        }
        else if (itemType == "Statement") {
            url = ServiceUrls.STATEMENTS + "/" + itemId + '/enableStatement.json';
        }
        else if (itemType == "Facility") {
            url = ServiceUrls.FACILITIES + itemId + '/enableFacility.json';
        }
        else if (itemType == "Procedure") {
            url = ServiceUrls.PROCEDURES + itemId + '/enableProcedure.json';
        }
        else if (itemType == "System") {
            url = ServiceUrls.SYSTEMS + itemId + '/enableSystem.json';
        }
        else if (itemType == "Document") {
            url = ServiceUrls.DOCUMENTS + itemId + '/enableDocument.json';
        }
        else if (itemType == "Component") {
            url = ServiceUrls.COMPONENTS + itemId + '/enableComponent.json';
        }
        else if (itemType == "ContentItem") {
            url = ServiceUrls.CONTENTS + itemId + '/enableContent.json';
        }
        else if (itemType == "Image") {
            url = ServiceUrls.IMAGES + itemId + '/enableImage.json';
        }
        else if (itemType == "Client") {
            url = ServiceUrls.CLIENTS + itemId + '/enableClient.json';
        }
        else if (itemType == "User") {
            url = ServiceUrls.USER_PROJECT_VIEW + itemId + '/enableUser.json';
        }
        else if (itemType == "Project") {
            url = ServiceUrls.PROJECTS + itemId + '/enableProject.json';
        }
        else if (itemType == "ICECourse") {
            url = ServiceUrls.ICECOURSE + itemId + '/enableICECourse.json';
        }
        else if (itemType == "ThreeDModel") {
            url = ServiceUrls.THREED_MODELS + itemId + '/enableThreeDModel.json';
        }
        else if (itemType == "Video") {
            url = ServiceUrls.VIDEOS + itemId + '/enableVideo.json';
        } else if (itemType == "PunchList") {
            url = ServiceUrls.PUNCHLISTS + itemId + '/enablePunchlist.json';
        } else if (itemType == "DocumentType") {
            url = ServiceUrls.DISABLE_DOC_TYPE + '?' + 'docTypeId=' + itemId + '&' + "status=active";
        } else if(itemType == "Workflow"){
            url = ServiceUrls.WORKFLOWS + itemId + '/enableWorkflowTemplate.json';
        } else if(itemType == "CheckSheet"){
            url = ServiceUrls.CHECKSHEET + itemId + '/enableChecksheet.json';
        } else if(itemType == "Check"){
            url = ServiceUrls.CHECKS + itemId + '/enableCheck.json';
        }

        return url;
    }

    /**
    * Define URL to enable item by item id.
    */
    public deleteItemByIdURL(itemId: String, itemType: String) {
        console.log("deleteItemByIdURL = itemType=" + itemType + " itemId=" + itemId);
        let url: any;
        if (itemType == "Topic") {
            url = ServiceUrls.TOPICS + itemId + '.json';
        }
        else if (itemType == "TopicMap") {
            url = ServiceUrls.TOPIC_MAPS + itemId + '.json';
        }
        else if (itemType == "Statement") {
            url = ServiceUrls.STATEMENTS + "/" + itemId + '.json';
        }
        else if (itemType == "Facility") {
            console.log("fac-del");
            url = ServiceUrls.FACILITIES + itemId + '.json';
        }
        else if (itemType == "Procedure") {
            url = ServiceUrls.PROCEDURES + itemId + '.json';
        }
        else if (itemType == "System") {
            url = ServiceUrls.SYSTEMS + "/" + itemId + '.json';
        }
        else if (itemType == "Document") {
            console.log("doc-del");
            url = ServiceUrls.DOCUMENTS + itemId + '.json';
        }
        else if (itemType == "Component") {
            console.log("comp-del");
            url = ServiceUrls.COMPONENTS + itemId + '.json';
        }
        else if (itemType == "ContentItem") {
            url = ServiceUrls.CONTENTS + itemId + '.json';
        }
        else if (itemType == "Image") {
            url = ServiceUrls.IMAGES + itemId + '.json';
        }
        else if (itemType == "Client") {
            url = ServiceUrls.CLIENTS + itemId + '.json';
        }
        else if (itemType == "User") {
            url = ServiceUrls.USER_PROJECT_VIEW + itemId + '/deleteUser.json';
        }
        else if (itemType == "Project") {
            url = ServiceUrls.PROJECTS + itemId + '/deleteProject.json';
        }
        else if (itemType == "Folder") {
            url = ServiceUrls.DELETE_FOLDER + itemId + '.json';
        }
        else if (itemType == "ICECourse") {
            url = ServiceUrls.ICECOURSE + itemId + '/destroy.json';
        }
        else if (itemType == "DocumentType") {
            if (this.sharedService.getLoadedComponent() == "documenttype") {
                url = ServiceUrls.deleteDocumentType(itemId);
            } else if (this.sharedService.getLoadedComponent() == "manageattributes") {
                url = ServiceUrls.deleteDocumentTypeAttribute(itemId)
            }
        }
        else if (itemType == "ThreeDModel") {
            url = ServiceUrls.THREED_MODELS + itemId + '.json';
        } else if (itemType == "Video") {
            url = ServiceUrls.VIDEOS + itemId + '.json';
        } else if (itemType == "PunchList") {
            url = ServiceUrls.PUNCHLISTS + itemId + '/destroy.json';
        }  else if (itemType == "IRN") {
            url = ServiceUrls.IRNS + itemId + '.json';
        }
        /*else if (itemType == "ICETopic") {
            url = ServiceUrls.ICETOPIC + itemId + '/destroy.json?courseId=' + this.courseId;
        }*/
        else if(itemType == "CheckSheet"){
            url = ServiceUrls.CHECKSHEET + itemId + '.json';
        } else if(itemType == "Check"){
            url = ServiceUrls.CHECKS + itemId + '/destroy.json';
        } else if(itemType == "Workflow"){
            url = ServiceUrls.WORKFLOWS + itemId + '/deleteWorkflowTemplate.json';
        }
        console.log(url);
        return url;
    }

    getCourseId(courseId){
        this.courseId = courseId;
    }
}
