<div class="main-panel pt-0 pb-4" style="overflow: hidden;">
    <div class="row px-3">
		<div class="col-sm-10 px-0">
			<h3> Manage Ad Hoc Reports </h3>
			<hr class="mt-0 mb-3" />
		</div>
		<div class="col-sm-2 px-0">
			<button id="button_comos_export" type="submit" class="btn btn-primary btn-sm float-right my-1 mx-1" (click)="onAdd()">
			Add
			</button>
		</div>
	</div>
    <div class="card-block card-body mt-2 pb-1 pt-1 px-0 card-primary">
        <ag-grid-angular #agGrid style="width: 100%; height: 450px;" id="manageAdhocReports" [rowData]="rowData"
            class="ag-theme-balham" [columnDefs]="columnDefs" [enableSorting]="true" [enableFilter]="true"
            [enableColResize]="true" [enableColResize]="true" [floatingFilter]="true" [enableRangeSelection]="true"
            [pagination]="true" [paginationPageSize]="15" (columnResized)="onColumnResized($event)" (gridReady)="onGridReady($event)"
            [gridOptions]="gridOptions" [suppressMenuHide]="true" [defaultColDef]="defaultColDef" [modules]="modules"
            [overlayLoadingTemplate]="overlayLoadingTemplate" [overlayNoRowsTemplate]="overlayNoRowsTemplate">
        </ag-grid-angular>
    </div>
</div>