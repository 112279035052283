import { Component, OnInit, Input, TemplateRef, ElementRef, HostListener, ViewChild, Type } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from 'src/app/util/shared.service';
import { IceGame, ICEGameImage } from '../ice-game-model.model';
import { AlertService } from '../../../util/alert/alert.service';
import { WsResponse } from '../../../util/ws-response.model';
import { WsType } from '../../../util/ws-type';
import { ICECKEditor } from '../../ice-course';
import { ICECourseService } from '../../ice-course-service.service';
import { LocalStorage } from '../../../util/localstorage.service';
import { LoadingService } from '../../../util/loading/loading.service';
import { UserVariable } from '../../../util/common/user-variable';
import { CK_Editor_Type  } from '../../../util/constants';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal/';
import { count } from 'rxjs/operators';

declare var CKEDITOR: any;
var editor;

@Component({
	selector: 'app-speed-match-game',
	templateUrl: './speed-match-game.component.html',
	styleUrls: ['./speed-match-game.component.scss']
})
export class SpeedMatchGameComponent implements OnInit {

	public divHeight: number = (window.innerHeight) * 4 / 5;
	@HostListener('window:resize', ['$event'])
	onresize(event?) {
		this.divHeight = (window.innerHeight) * 4 / 5
	}

	tdQuestionType: any = null
	public selectedQuestionId: string = null
	public iceGame: IceGame;
	public temporyGame: IceGame;
	public userId: String;
	public tabId: string;
	public breadCrumbList:Array<any> = [];
	public newGame: IceGame;
	public tfqStatus: boolean = true;
	public speCharList: Array<any> = [];
	public isViewOnly: boolean = false;
	private insertSubscription: any;
	public desceditor: any;
	public editorList: Array<any> = [];
	public editorDivList: Array<any> = [];
	private xmlContent: string = '';
	public gameDescriptionShowInDetails:any = null;
	public collapseOpen: boolean = false;
	public slotNumberList: Array<number> = [4];
	public slotNumberLengthList: Array<number> = [];
	public gameDescription: string ;
	public cellList: Array<number> = [0,1];
	public imageData: any;
	public isImageDataAvailable: boolean = false;
	public currentRow: number;
	public currentImageType: String;
	public gameModified: boolean = false;
	public isTest: boolean = true;
	public isQuiz: boolean = false;
	public category: string;
	modalRef: BsModalRef;
	public addSpecialCharDivId: String = "";
	public addFractionDIvId: String = "";
	public numOfRows: number;
	public numOfSequences: number;
	public numOfRowsinView: number;
	public numOfSequencesinVIew: number;
	public gameEnabled: boolean = true;
	public gameData: Array<any> = [];
	public gameGenarated: boolean = false;
	public existingGameData: string = "";
	public selectedImage: any = {
		'id': '',
		'thumbnailImage': '',
		'largeImage': '',
		'title': ''
    };
	public selectedModalTemplate: TemplateRef<any>;
	public countOfQuestions = 0;

	public modalConfig: any = {
		backdrop: true,
		// ignoreBackdropClick: true,
		class: 'modal-lg',
		// keyboard: false
  	}

	private inlineConfig = {
		toolbar: [
			{ name: 'clipboard', groups: ['clipboard', 'undo'], items: ['Cut', 'Copy', '-', 'Undo', 'Redo'] },
			{ name: 'basicstyles', groups: ['basicstyles'], items: ['Bold', 'Italic', 'Underline', 'Subscript', 'Superscript'] },
			//{ name: 'paragraph', groups: ['list'], items: ['NumberedList', 'BulletedList'] },
			{ name: 'insert', groups: ['insert'], items: ['addsymbol', 'addfraction'] }
			//{ name: 'editing', groups: ['spellchecker'], items: ['Scayt'] }
		],
		autoParagraph: false,
		readOnly: false,
		removePlugins: 'liststyle, magicline, maximize, resize',
		pasteFilter: 'plain-text',
		extraPlugins: 'statementclickhandler, sharedspace, addsymbol, addfraction, disablepartialbui, reusablepanelhandler, deletezws',
		title: false,
		tabID: SharedService.getTabId(),
		enterMode: CKEDITOR.ENTER_BR,
		startupFocus: 'end'
};

	constructor(private translate: TranslateService, private sharedService: SharedService,  private alert: AlertService,
		private iceCKEditor: ICECKEditor,  private courseService: ICECourseService, private alertService: AlertService,
		private loadingService: LoadingService, private elementRef: ElementRef, private modalService: BsModalService) {
		// 	let browserLang = translate.getBrowserLang();
		// 	translate.getTranslation(browserLang).subscribe((res: string) => {
		// 	// this.tdQuestionType = res["ICE_COURSE"]
		// });
		this.iceGame = new IceGame("0");
		this.userId = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER)).userId;
		this.tabId = SharedService.getTabId();
		this.addFractionDIvId = 'webeditor_fraction_' +SharedService.getTabId();
		this.addSpecialCharDivId = 'webeditor_special_char_' +SharedService.getTabId();
		}

	@Input() data: any;

	ngOnInit() {
		this.speCharList = [
			{ data: { symbol: this.getSymbolFromDecimal(169), decVal: 169, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(8482), decVal: 8482, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(174), decVal: 174, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(176), decVal: 176, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(163), decVal: 163, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(8364), decVal: 8364, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(10016), decVal: 10016, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(9746), decVal: 9746, type: "speChar" } },
			// There was an issue in LC related to this special character. Therefore it was removed from all the Ck Editors in ICE Course
			// { data: { symbol: this.getSymbolFromDecimal(9744), decVal: 9744, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(167), decVal: 167, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(937), decVal: 937, type: "speChar" } }
		];
		
		if (this.data) {
			if(this.data.iceGame != null && this.data.iceGame != undefined ){
			//set game details from parent page
				this.iceGame = this.data.iceGame;
				var html = this.iceGame.descriptionHTML;
				var div = document.createElement("div");
				div.innerHTML = html;
				div.innerText;
				this.gameDescription = div.innerText;

				setTimeout(() => {
					//set game description editor content
					let e1  = this.elementRef.nativeElement.querySelector('.games_ck_editor' + this.tabId);
					e1.innerHTML = this.iceGame.description ;
					}, 200);
			//set child details
			this.iceGame = this.data.iceGame;
			if (this.iceGame.id == '0'){
			}
			else{
				//show loading message
				var loadingMessage = null;
				this.translate.get('ICE_GAME.LOADING_MESSAGE').subscribe((res: string) => {
				loadingMessage = res;
				});
				this.loadingService.showLoading(true, null, loadingMessage, null);
				this.courseService.getAllICEGameInfoById(this.iceGame.id, UserVariable.getUserToken(), this);
			}
			} else {
			// data.game is null or undefined
			}
			this.breadCrumbList = this.sharedService.getICEBreadCrumb(this.iceGame.courseId);
			var html = this.iceGame.description;
			var div = document.createElement("div");
			div.innerHTML = html;
			this.gameDescriptionShowInDetails = div.textContent || div.innerText || "";
			this.isViewOnly = this.data.viewOnly
		} else {
			//no data
		}
		//set course enabled true
		this.gameEnabled = true;
		this.sharedService.setModifiedStatus(this.tabId, this.gameModified)
	}

	ngAfterViewInit() {
		let self = this;
		let e1 = self.elementRef.nativeElement.querySelector('.games_ck_editor' + self.tabId);
		if (self.isViewOnly == true) {
			e1.style.backgroundColor = "#e9ecef";
		}
	}

	ngAfterContentInit() {
		//unsubscribe
		if (SharedService.getIceSpeedMatchGameSubscriptions() != null) {
				SharedService.getIceSpeedMatchGameSubscriptions().unsubscribe();
			}
		// Update game data changes to the shared service when a nother tab is selected
		this.sharedService.setICEModuleChanges.subscribe(prevTab => {
			if (prevTab == this.tabId) {
				//get opened modules
				let openedICEModules = this.sharedService.getOpenedICEModules();
				//check if tab type is ICELearningCheck
				if (openedICEModules[prevTab] != undefined) {
					// get tab type
					var type = openedICEModules[prevTab]['type'];
					if (type == "ICEGame-SpeedMatch") {
						var name = this.getEditorContent('.games_ck_editor' + this.tabId);
						this.iceGame.description = name;
						var tempGameDataEdited = [];
						for (var row = 0; row < Object.keys(this.gameData).length; row++) {
							if (this.gameData[row + '_' + this.tabId].questionIsText) {
								var question = this.getEditorContent('.qpanel_ck_editor' + row + this.tabId);
								this.gameData[row + '_' + this.tabId].questionTextData = question;
							}
							if (this.gameData[row + '_' + this.tabId].answerIsText) {
								var answer = this.getEditorContent('.apanel_ck_editor' + row + this.tabId);
								this.gameData[row + '_' + this.tabId].answerTextData = answer;
							}
							tempGameDataEdited.push(this.gameData[row + '_' + this.tabId]);
						}
						// Update iceGame with the updated gameData when leaving the tab as new tab has selected.
						this.iceGame.gameData = JSON.stringify(tempGameDataEdited);
						// Update openedCourses list saved in shared service with gameData changes.
						this.sharedService.setOpenedICEModules(prevTab, 'ICEGame-SpeedMatch', this.iceGame);
					} else {
						//differnt type
					}
				} else {
					//do nothing
				}
			}
		});

		this.sharedService.setICEModuleVariables.subscribe(prevTab => {
			if(this.tabId == prevTab) {
				let self = this;
				let commonVariables = this.sharedService.getCommonICEModuleVariableNames('SpeedMatchGame');
				var commonVariableValues: Array<any> = [];
				commonVariables.forEach(function (varName) {
					commonVariableValues[varName] = self[varName];
				}); 
				this.sharedService.setCommonICEModuleVariableValues(prevTab, 'SpeedMatchGame', commonVariableValues);
			}
		});

		// Get the current tab's iceGame object
		this.sharedService.setOpenedICEModule.subscribe(newTab => {
			let openedICEModules = this.sharedService.getOpenedICEModules();
			if(openedICEModules[newTab] != undefined) {
				// set iceGame object for the selected tab
				this.iceGame = openedICEModules[newTab]['module'];
				// set gameData object for the selected tab
				this.setSelectedTabsGameData(openedICEModules[newTab]['module'].gameData);
			}
		});

		this.sharedService.setOpenedICEVariables.subscribe(newTab => {
			if (this.tabId == newTab) {
				let commonVariables = this.sharedService.getCommonICEModuleVariableNames('SpeedMatchGame');
				var commonVariableValues: Array<any> = [];
				commonVariableValues = this.sharedService.getCommonICEModuleVariableValues(newTab);
				console.log(commonVariableValues);
				if(commonVariableValues != undefined) {
					let self = this;
					commonVariables.forEach(function (varName) {
						self[varName] = commonVariableValues[varName];
					}); 
				}
			}
		});

		//Called after ngOnInit when the component's or directive's content has been initialized.
		//Add 'implements AfterContentInit' to the class.
		this.insertSubscription = SharedService.insertICECourseExecute().subscribe(option => {
			//get opened modules
			let openedICEModules = this.sharedService.getOpenedICEModules();
			//get current tab
			var currentTab = SharedService.getTabId();

			//check if current tab type is ICEGame-Memory
			if (openedICEModules[currentTab] != undefined) {
				// get tab type
				var type = openedICEModules[currentTab]['type'];
				if (type == "ICEGame-SpeedMatch") {
					if (option.data.type != "imageAsImage" && option.data.type != "imageAsFigure") {
						this.alertService.warn(this.translate.instant('ICE_GAME.ADD_REUSABLES_WARNING'));
					} else {
						// call setSelectImagetoMainObject event to set selected image data to gameData of current tab
						this.sharedService.setSelectedImageRequest(option);
					}
				} else {
					//differnt type
				}
			} else {
				//do nothing
			}
		});

		// set selected image data to gameData object of current tab
		this.sharedService.setSelectImagetoMainObject.subscribe(option => {
			this.imageData = option;
			this.isImageDataAvailable= true;
			//get the row index, sequence index and selected image type from shared service to update gameData object with inserted image details
			let pictureReferencesSMSG = this.sharedService.getPictureReferences();
			if(pictureReferencesSMSG != undefined) {
				this.currentRow = pictureReferencesSMSG['selectedRow'];
				this.currentImageType = pictureReferencesSMSG['selectedType'];
				// get tab Id of the current tab from shared service
				let tabIDforPictures = SharedService.getTabId();
				if(this.gameData[this.currentRow+'_'+tabIDforPictures] != undefined) {
					if(this.currentImageType == 'q' && !this.gameData[this.currentRow+'_'+tabIDforPictures].questionIsText) {
						this.gameData[this.currentRow+'_'+tabIDforPictures].questionImageData.id = this.imageData.data.id;
						this.gameData[this.currentRow+'_'+tabIDforPictures].questionImageData.thumbnailImage = this.imageData.data.thumbnailImage;
						this.gameData[this.currentRow+'_'+tabIDforPictures].questionImageData.largeImage = "";
						this.gameData[this.currentRow+'_'+tabIDforPictures].questionImageData.title = this.imageData.data.title;
						this.gameData[this.currentRow+'_'+tabIDforPictures].questionIsImage = true;
					} else {
						if(!this.gameData[this.currentRow+'_'+tabIDforPictures].answerIsText) {
							this.gameData[this.currentRow+'_'+tabIDforPictures].answerImageData.id = this.imageData.data.id;
							this.gameData[this.currentRow+'_'+tabIDforPictures].answerImageData.thumbnailImage = this.imageData.data.thumbnailImage;
							this.gameData[this.currentRow+'_'+tabIDforPictures].answerImageData.largeImage = "";
							this.gameData[this.currentRow+'_'+tabIDforPictures].answerImageData.title = this.imageData.data.title;
							this.gameData[this.currentRow+'_'+tabIDforPictures].answerIsImage = true;
						}
					}
				}
			}
		});
		
		this.sharedService.imageStatusRespond().subscribe(imageStatus => this.setImageStatus(imageStatus));
		SharedService.setIceSpeedMatchGameSubscriptions(this.insertSubscription);     
	}

	public setSelectedTabsGameData(obj : any) {
		if(obj != undefined) {
			this.gameData = [];
			let gameDataObject = JSON.parse(obj);
			for(let i = 0; i < gameDataObject.length; i++) {
				this.gameData[i+'_'+this.tabId] = gameDataObject[i]
			}
			this.countOfQuestions = Object.keys(this.gameData).length;
			this.isImageDataAvailable = true;
			this.gameGenarated = true;
			setTimeout(() => {
				this.setEditor("",CK_Editor_Type.DDQ_CELL_EDITOR);
			}, 200);
		}
	}

	/**
	 * generate game
	 */
	public generateGame() {
		let qaPanel = {"questionIsText": false, "questionIsImage" : false, "questionTextData" : "", "questionImageData" : new ICEGameImage, "answerIsText" : false, "answerIsImage" : false, "answerTextData" : "", "answerImageData" : new ICEGameImage};
		this.gameData[this.countOfQuestions+'_'+this.tabId] = qaPanel;
		this.countOfQuestions++;
		this.gameGenarated = true;
		this.gameModified = true;
		this.sharedService.setModifiedStatus(this.tabId, this.gameModified)
		this.sharedService.setOpenedICEModules(this.tabId, 'ICEGame-SpeedMatch', this.iceGame);
	}

	// add text content
	addText(row, type){
		if(type == 'q') {
			this.gameData[row+'_'+this.tabId].questionIsText = true;
			this.gameData[row+'_'+this.tabId].questionIsImage = false;
			this.gameData[row+'_'+this.tabId].questionImageData = {};
			setTimeout(() => {
				this.focusNewEditor('.qpanel_ck_editor'+ (row) + this.tabId , CK_Editor_Type.DDQ_CELL_EDITOR);
			}, 200);
		} else {
			this.gameData[row+'_'+this.tabId].answerIsText = true;
			this.gameData[row+'_'+this.tabId].answerIsImage = false;
			this.gameData[row+'_'+this.tabId].answerImageData = {};
			setTimeout(() => {
				this.focusNewEditor('.apanel_ck_editor'+ (row) + this.tabId , CK_Editor_Type.DDQ_CELL_EDITOR);
			}, 200);
		}
		console.log(this.gameData);
		this.gameModified = true;
		this.sharedService.setModifiedStatus(this.tabId, this.gameModified)
		this.sharedService.setPictureReferences(null,null,null);
	}
	
	// add image content
	addPicture(row, type) { 
		this.changeToImagePanel();
		this.currentRow = row;
		this.currentImageType = type;
		if(type == 'q') {
			this.gameData[row+'_'+this.tabId].questionIsText = false;
			this.gameData[row+'_'+this.tabId].questionTextData = "";
			// this.gameData[row].questionIsImage = false;
		} else {
			this.gameData[row+'_'+this.tabId].answerIsText = false;
			this.gameData[row+'_'+this.tabId].answerTextData = "";
			// this.gameData[row].answerIsImage = false;
		}
		this.gameModified = true;
		this.sharedService.setModifiedStatus(this.tabId, this.gameModified)
		this.sharedService.setPictureReferences(row,null,type);
	}

	// clear image content
	clearImage(row, type){
		if(type == 'q') {
			this.gameData[row+'_'+this.tabId].questionIsImage = false;
			this.gameData[row+'_'+this.tabId].questionImageData.id = "";
			this.gameData[row+'_'+this.tabId].questionImageData.thumbnailImage = "";
			this.gameData[row+'_'+this.tabId].questionImageData.largeImage = "";
			this.gameData[row+'_'+this.tabId].questionImageData.title = "";
		} else {
			this.gameData[row+'_'+this.tabId].answerIsImage = "";
			this.gameData[row+'_'+this.tabId].answerImageData.id = "";
			this.gameData[row+'_'+this.tabId].answerImageData.thumbnailImage = "";
			this.gameData[row+'_'+this.tabId].answerImageData.largeImage = "";
			this.gameData[row+'_'+this.tabId].answerImageData.title = "";
		}
		this.gameModified = true;
		this.sharedService.setModifiedStatus(this.tabId, this.gameModified)
	}

	removeQuestion(row) {
		this.gameData[row+'_'+this.tabId].questionIsText = false;
		this.gameData[row+'_'+this.tabId].questionTextData = "";
		this.gameData[row+'_'+this.tabId].questionIsImage = false;
		this.gameData[row+'_'+this.tabId].questionImageData.id = "";
		this.gameData[row+'_'+this.tabId].questionImageData.thumbnailImage = "";
		this.gameData[row+'_'+this.tabId].questionImageData.largeImage = "";
		this.gameData[row+'_'+this.tabId].questionImageData.title = "";
		this.gameModified = true;
		this.sharedService.setModifiedStatus(this.tabId, this.gameModified)
	}

	removeAnswer(row) {
		this.gameData[row+'_'+this.tabId].answerIsText = false;
		this.gameData[row+'_'+this.tabId].answerTextData = "";
		this.gameData[row+'_'+this.tabId].answerIsImage = false;
		this.gameData[row+'_'+this.tabId].answerImageData.id = "";
		this.gameData[row+'_'+this.tabId].answerImageData.thumbnailImage = "";
		this.gameData[row+'_'+this.tabId].answerImageData.largeImage = "";
		this.gameData[row+'_'+this.tabId].answerImageData.title = "";
		this.gameModified = true;
		this.sharedService.setModifiedStatus(this.tabId, this.gameModified)
	}
	
	/**
	 * open image search tab
	*/
	private changeToImagePanel() {
		SharedService.openReusablepanel('images');
	}

	/**
	 * back to main course detail page
	 */
	public backtoCourse() {
		this.sharedService.changeIceItemRequest('IceCourse', null)
	}

	private getSymbolFromDecimal(value: number): string {
		if ((33 <= value) &&  (value < 65536)) {
			return String.fromCharCode(value);
		} else {
			this.alertService.error("Please enter valid Unicode character entity reference. Range is between 33 to 65535", true);
		}
	}

	setImageStatus(type){
		if(type.status =="ICEGame" && type.tabId == this.tabId ){
	this.gameModified	= true;
	this.sharedService.setModifiedStatus(this.tabId, this.gameModified)
		}
	}

	openModal(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template);
	}

	public clickBreadCrumb(data:any,index:number){
		let self = this;
		if(this.gameModified == false){
			if(data != null){
				this.sharedService.changeIceItemRequest(data.iceType, data);
				this.breadCrumbList.length = index;
			} else{
			//nothing to do
			}
		} else{
			self.alertService.warn(self.translate.instant('ICE_GAME.SAVE_GAMEDATA'));
		}
	}

	updateBreadCrumb(){
		this.breadCrumbList = this.sharedService.getICEBreadCrumb(this.iceGame.courseId);
		var html = this.iceGame.descriptionHTML;
		var div = document.createElement("div");
		div.innerHTML = html;
		this.gameDescriptionShowInDetails = div.textContent || div.innerText || "";
	}

	updateDescription(){
		let el = this.elementRef.nativeElement.querySelector('.games_ck_editor' + this.tabId);
		if (el != null) {
			let name = this.iceGame.description; 
			if (name != undefined) {
				el.innerHTML = name;
			}
		}
	}

	setGameEditors(e1) {
		let self = this;
		if (e1.innerHTML == '') {
			e1.innerHTML = "&nbsp;";
		} else {
			//do nothing
		}
		if (self.iceGame.description != undefined) {
			e1.innerHTML = self.iceGame.description;
		} else {
			//do nothing
		}
		$('.desc_ck_editor'+ self.tabId).dblclick(function (e) {
			if (e.target.nodeName != "PH" || (self.desceditor == null) || (self.desceditor == undefined)) {
				self.gameModified = true;
				self.sharedService.setModifiedStatus(self.tabId, self.gameModified)
				if (self.desceditor) {
					self.desceditor.destroy();
				} else {
					//no editor
				}
				self.desceditor = CKEDITOR.inline(e1 , self.inlineConfig);
				
				//set description editable true
				e1.setAttribute("contenteditable", "true");			
				self.setEditorFunctions(self.desceditor);
		} else {
				$(document).on('dblclick','ph', function(){
					let item = $(this).attr('restriction');
					let catId = $(this).attr('catId');
					self.iceCKEditor.getRestrictionValueOndblClick(item,catId);
				});
				self.setEditorFunctions(self.desceditor);
			}
		});
	}

	setEditorFunctions(editor) {
		var self = this;
		if (editor) {
			editor.on('instanceReady', function (evt) {
				var range = editor.createRange();
				if (range != null) {
				range.moveToElementEditEnd(range.root);
				editor.getSelection().selectRanges([range]);
				
				}
			});			
			editor.on('key', function (e) {
				self.iceCKEditor.editorKeyDown(e,editor);
			});
			editor.on('paste', function (evt) {
				evt.stop();
				var data: string = evt.data.dataValue;
				let pasteData: string = self.iceCKEditor.processPasteData(data);
				if(self.iceCKEditor.gethasResuables()) {
					self.alertService.clear();
					self.alertService.toast(self.translate.instant('ICE_COURSE.DISABLED_EDITOR_COPY'));
				}
				evt.editor.insertHtml(pasteData);
			});
		} else {
			//no editor
		}
	}

	onSuccess(data: WsResponse, serviceType: WsType) {
		if(serviceType == WsType.SAVE_ICE_GAME_DATA){
			this.updateBreadCrumb();
			this.loadingService.hideLoading();
			this.showSuccessAlert('ICE_GAME.GAME_UPDATED_ALERT');  
			console.log("=====Game successfully updated=====");	
			this.loadingService.hideLoading();
		} else if(serviceType == WsType.GET_ALL_ICE_GAME_INFO_BY_ID){
			this.iceGame.description = data.payload.description;
			this.iceGame.descriptionHTML = data.payload.descriptionHTML;
			this.updateBreadCrumb();
			this.updateDescription();
			setTimeout(() => {
				this.setEditor("",CK_Editor_Type.GAME_EDITOR);
			}, 200);
			if(data.payload.gameData != null && data.payload.gameData != "") {
				if(this.loadGameDetails(data.payload)) {
					this.gameGenarated = true;
					setTimeout(() => {
						this.setEditor("",CK_Editor_Type.DDQ_CELL_EDITOR);
					}, 200);
				}
			}	
			this.loadingService.hideLoading();
		} else if (serviceType == WsType.GET_SELECTED_IMAGE_DATA) {
            this.selectedImage = JSON.parse(data.payload);
            this.loadingService.hideLoading();
            this.modalRef = this.modalService.show(this.selectedModalTemplate, this.modalConfig);
        }
	}

	onFail(data: WsResponse, serviceType:WsType){
		if (serviceType == WsType.SAVE_ICE_GAME_DATA) {
			this.alertService.error(data.statusDescription, false);
			console.log("=====Failed to save Einstein game data=====");
			this.loadingService.hideLoading();
		} else if (serviceType == WsType.GET_ALL_ICE_GAME_INFO_BY_ID) {
			this.alertService.error(data.statusDescription, false);
			console.log("=====Failed to get ice game data by Id=====");
			this.loadingService.hideLoading();
		} else if (serviceType == WsType.GET_SELECTED_IMAGE_DATA) {
            this.loadingService.hideLoading();
        }
	}

	// Set saved game details to the UI
	loadGameDetails(payload:WsResponse['payload']){
		this.gameData = [];
		this.gameGenarated = false;
		this.existingGameData = "";
		if(payload.gameData != null && payload.gameData != undefined) {
			let gameDataObject = JSON.parse(payload.gameData);
			this.existingGameData = payload.gameData;
			for(let i = 0; i < gameDataObject.length; i++) {
				this.gameData[i+'_'+this.tabId] = gameDataObject[i]
			}
		}this.iceGame.gameData = payload.gameData
		this.countOfQuestions = Object.keys(this.gameData).length;
		this.isImageDataAvailable = true;
		// Save iceGame object and gameData in shared service with tab id when openinng the game in a tab
		this.sharedService.setOpenedICEModules(SharedService.getTabId(), 'ICEGame-SpeedMatch', this.iceGame);
		let self = this;
		let commonVariables = this.sharedService.getCommonICEModuleVariableNames('SpeedMatchGame');
		var commonVariableValues: Array<any> = [];
		commonVariables.forEach(function (varName) {
			commonVariableValues[varName] = self[varName];
		}); 
		this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'SpeedMatchGame', commonVariableValues);
		return true;
	}

	showSuccessAlert(en:string){
		var alert = null;
		this.translate.get(en).subscribe((res: string) => {
			alert = res;
		});
		this.alertService.success(alert);
	}

	isNullOrWhitespace(input) {
		console.log("===null")
		return !/\S/.test(input);
	}
	
	private getEditorContent(editorName: string): string {
		let el = this.elementRef.nativeElement.querySelector(editorName);
		if(el != null){
			return el.innerHTML;
		}
	}

	focusNewEditor(className: string, CK_Editor_Variable) {
		let self = this;
		//set editor content
		this.setEditor(className, CK_Editor_Variable);
		let node = $(".toggle-edit" + this.tabId + this.userId + className);
		console.log("fn length"+this.editorList.length);
		for (let i in this.editorList) {
			if (CKEDITOR.instances[this.editorList[i].name] != undefined) {
				CKEDITOR.instances[this.editorList[i].name].destroy();
			}
		}
		for (let j in self.editorDivList) {
			if (self.editorDivList[j].attributes['contenteditable'].nodeValue == "true") {
				self.editorDivList[j].setAttribute("contenteditable", "false");									
				if ($(self.editorDivList[j])[0].innerHTML == '') {
					$(self.editorDivList[j])[0].innerHTML = '<br>';
				}
			}
		}
		$(node)[0].setAttribute("contenteditable", "true");
		editor = CKEDITOR.inline($(node)[0], this.inlineConfig);
		this.editorList.push(editor);
		this.editorDivList.push($(node)[0]);
		editor.on('instanceReady', function (evt) {
			var range = editor.createRange();
			if (range != null) {
				range.moveToElementEditEnd(range.root);
				editor.getSelection().selectRanges([range]);
			}
		});
		$(document).on('dblclick','ph', function(){
		let item = $(this).attr('restriction');
		let catId = $(this).attr('catId');
		self.iceCKEditor.getRestrictionValueOndblClick(item,catId);
		});
		editor.on('key', function (e) {
		self.iceCKEditor.editorKeyDown(e,editor);
		});
		editor.on('paste', function (evt) {
			evt.stop();
			var data: string = evt.data.dataValue;
			let pasteData: string = self.iceCKEditor.processPasteData(data);
			if(self.iceCKEditor.gethasResuables()) {
				self.alertService.clear();
				self.alertService.toast(self.translate.instant('ICE_COURSE.DISABLED_EDITOR_COPY'));
			}
			evt.editor.insertHtml(pasteData);
		}); 
	}

	// Save game details
	public gameDataSave(){

		if (!this.validateForm()) {
			return;
		}
		else {

		this.updateGameDescription(); 
		this.gameModified = false;
		this.sharedService.setModifiedStatus(this.tabId, this.gameModified)
		var tempGameDetails = [];
		for (var row = 0 ; row < Object.keys(this.gameData).length; row++) {
			if(this.gameData[row+'_'+this.tabId].questionIsText) {
				this.xmlContent ="";
				var editortext = $('.qpanel_ck_editor' + row + this.tabId).text();
				if(this.isNullOrWhitespace(editortext) == true) {
					this.gameData[row+'_'+this.tabId].questionTextData = '<ice></ice>';
				} else {
					var name = this.getEditorContent('.qpanel_ck_editor' + row + this.tabId);
					this.iceCKEditor.processXML(name,false,this.xmlContent);
					this.xmlContent = this.iceCKEditor.getXMLContent();
					this.gameData[row+'_'+this.tabId].questionTextData = '<ice>'+ this.xmlContent + '</ice>';
				}
			}
			if(this.gameData[row+'_'+this.tabId].answerIsText) {
				this.xmlContent ="";
				var editortext = $('.apanel_ck_editor' + row + this.tabId).text();
				if(this.isNullOrWhitespace(editortext) == true) {
					this.gameData[row+'_'+this.tabId].answerTextData = '<ice></ice>';
				} else {
					var name = this.getEditorContent('.apanel_ck_editor' + row + this.tabId);
					this.iceCKEditor.processXML(name,false,this.xmlContent);
					this.xmlContent = this.iceCKEditor.getXMLContent();
					this.gameData[row+'_'+this.tabId].answerTextData = '<ice>'+ this.xmlContent + '</ice>';
				}
			}
			tempGameDetails.push(this.gameData[row+'_'+this.tabId])
		}
		var request = this.iceGame;
		request.gameData = JSON.stringify(tempGameDetails);
		var loadingMessage = null;
		this.translate.get('ICE_GAME.SPEED_MATCH_GAME.SAVE_GAME_INFO').subscribe((res: string) => {
			loadingMessage = res;
		});
		this.loadingService.showLoading(true, null, loadingMessage, null);
		this.courseService.saveICEGameData(JSON.stringify(request), this.iceGame.id, UserVariable.getUserToken(), this);	
	}
}

	// Update games description with CKEditor
	updateGameDescription() {
		this.xmlContent ="";
		//check if edior text is empty
		var editortext = $('.games_ck_editor' + this.tabId).text();
		if(this.isNullOrWhitespace(editortext) == true) {
			this.iceGame.description = '<ice></ice>';
		} else {
			//process name
			var name = this.getEditorContent('.games_ck_editor' + this.tabId); 
			this.iceCKEditor.processXML(name,false,this.xmlContent);
			this.xmlContent = this.iceCKEditor.getXMLContent();
			this.iceGame.description = '<ice>'+ this.xmlContent + '</ice>';
			this.xmlContent = this.xmlContent.substring(3, this.xmlContent.length - 4);
			this.gameDescription = this.xmlContent;
		}
	}

	setEditor(className:string, CK_Editor_Variable) {
		var self = this;
		$('.toggle-edit' + SharedService.getTabId() + this.userId).on('copy', function() {
			if ($(this)[0].attributes['contenteditable'].nodeValue == "false") {
				localStorage.setItem('disabledEditor', "true"); 
			}
		});	
		//set editor content
		this.setEditorContent(className,CK_Editor_Variable);
		if(this.isViewOnly == true) {
			//view only
		} else if( CK_Editor_Variable == 'ice_ddq_cell_ck_editor' || CK_Editor_Variable == 'games_ck_editor') {
			$('.toggle-edit' + this.tabId  + this.userId).dblclick(function (e) {
				self.gameModified = true;
				self.sharedService.setModifiedStatus(self.tabId, self.gameModified)
				for (let i in self.editorList) {
					if (CKEDITOR.instances[self.editorList[i].name] != undefined) {
						CKEDITOR.instances[self.editorList[i].name].destroy();
					}
				}
				for (let j in self.editorDivList) {
					if (self.editorDivList[j].attributes['contenteditable'].nodeValue == "true") {
						self.editorDivList[j].setAttribute("contenteditable", "false");									
						if ($(self.editorDivList[j])[0].innerHTML == '') {
							$(self.editorDivList[j])[0].innerHTML = '<br>';
						}
					}
				}
				$(this)[0].setAttribute("contenteditable", "true");
				if(CK_Editor_Variable == 'ice_ddq_cell_ck_editor'){
					let  questionId = $(this)[0].getAttribute("id");
					editor = CKEDITOR.inline($(this)[0], self.inlineConfig);
				} else if(CK_Editor_Variable == 'games_ck_editor'){
					let  questionId = $(this)[0].getAttribute("id");
					editor = CKEDITOR.inline($(this)[0], self.inlineConfig);
				}
				self.editorList.push(editor);
				self.editorDivList.push($(this)[0]);	
				editor.on('instanceReady', function (evt) {
				var range = editor.createRange();
				if (range != null) {
					range.moveToElementEditEnd(range.root);
					editor.getSelection().selectRanges([range]);
				}
				});
				editor.on('key', function (e) {
				self.iceCKEditor.editorKeyDown(e,editor);
				});
				editor.on('paste', function (evt) {
					evt.stop();
					var data: string = evt.data.dataValue;
					let pasteData: string = self.iceCKEditor.processPasteData(data);
					if(self.iceCKEditor.gethasResuables()) {
						self.alertService.clear();
						self.alertService.toast(self.translate.instant('ICE_COURSE.DISABLED_EDITOR_COPY'));
					}
					evt.editor.insertHtml(pasteData);
				});			
			});
		}
	}

	setEditorContent(className, CK_Editor_Variable) {
		if(CK_Editor_Variable=='ice_ddq_cell_ck_editor'){
			if(className) {
				// Nothing to do
			} else {
				for(let i = 0; i < Object.keys(this.gameData).length; i++) {
					let e1 = this.elementRef.nativeElement.querySelector('.qpanel_ck_editor' + i + this.tabId);
					let e2 = this.elementRef.nativeElement.querySelector('.apanel_ck_editor' + i + this.tabId);
					if(this.gameData[i+'_'+this.tabId] != undefined && this.gameData[i+'_'+this.tabId].questionIsText) {
						if (e1 != null) {
							let name = this.gameData[i+'_'+this.tabId].questionTextData;
							if (name != undefined) {
								e1.innerHTML = name;
							} else {
								//el.innerHTML = "<p>&nbsp;</p>";
							}
						} else {
							//no element
						}
						if(this.isViewOnly == true) {
							if (e1 != null) {
								e1.setAttribute("contenteditable", "false");
								e1.style.backgroundColor = "#e9ecef";
							} else {
								e1.style.backgroundColor = "#ffffff";
							}
						} else {
							if (e1 != null) {
								e1.style.backgroundColor = "";
							} else {
							//no element
							}
						}
					}
					if(this.gameData[i+'_'+this.tabId] != undefined && this.gameData[i+'_'+this.tabId].answerIsText) {
						if (e2 != null) {
							let name = this.gameData[i+'_'+this.tabId].answerTextData;
							if (name != undefined) {
								e2.innerHTML = name;
							} else {
								//el.innerHTML = "<p>&nbsp;</p>";
							}
						} else {
							//no element
						}
						if(this.isViewOnly == true) {
							if (e2 != null) {
								e2.setAttribute("contenteditable", "false");
								e2.style.backgroundColor = "#e9ecef";
							} else {
								e2.style.backgroundColor = "#ffffff";
							}
						} else {
							if (e2 != null) {
								e2.style.backgroundColor = "";
							} else {
							//no element
							}
						}
					}
			}
		}
		} 
	}

	reArrangeGameDataAfterDelete(row) {
		// this.gameData.splice(row, 1)
		if(this.gameData != null && this.gameData != undefined) {
			for(let i = 0; i < Object.keys(this.gameData).length; i++) {
				if(i >= row) {
					if((Object.keys(this.gameData).length-1) != row && this.gameData[(i+1)+'_'+this.tabId] != undefined) {
						if(this.gameData[(i+1)+'_'+this.tabId].questionIsText) {
							this.gameData[(i+1)+'_'+this.tabId].questionTextData = this.getEditorContent('.qpanel_ck_editor' + (i+1) + this.tabId);
						}
						if(this.gameData[(i+1)+'_'+this.tabId].answerIsText) {
							this.gameData[(i+1)+'_'+this.tabId].answerTextData = this.getEditorContent('.apanel_ck_editor' + (i+1) + this.tabId);
						}
						this.gameData[i+'_'+this.tabId] = this.gameData[(i+1)+'_'+this.tabId]
					}
				} else {
					if(this.gameData[i+'_'+this.tabId].questionIsText) {
						this.gameData[i+'_'+this.tabId].questionTextData = this.getEditorContent('.qpanel_ck_editor' + i + this.tabId);
					}
					if(this.gameData[i+'_'+this.tabId].answerIsText) {
						this.gameData[i+'_'+this.tabId].answerTextData = this.getEditorContent('.apanel_ck_editor' + i + this.tabId);
					}
				}
			}
			delete this.gameData[(Object.keys(this.gameData).length-1)+'_'+this.tabId];
		}
		this.countOfQuestions = Object.keys(this.gameData).length;
		setTimeout(() => {
			this.setEditor("",CK_Editor_Type.DDQ_CELL_EDITOR);
		}, 200);
		this.gameModified = true;
		this.sharedService.setModifiedStatus(this.tabId, this.gameModified)
	}

	// cancel changes
	cancel() {
		let index = this.breadCrumbList.length-1;
		let self = this;
		this.sharedService.changeIceItemRequest(this.breadCrumbList[index].iceType, this.breadCrumbList[index]);
		this.breadCrumbList.length = index;
	}

	public openCancelConfirmationModel(template: TemplateRef<any>) {
		if(this.sharedService.getModifiedStatus(this.tabId)){
		  this.modalRef = this.modalService.show(template, this.modalConfig);
		}else{
		  this.cancel();
		}
	}

	addSpecialCharacter(option: any){
		this.modalRef.hide()
		if(option != ""){
			this.iceCKEditor.insertTagEditArea(option, this.elementRef,editor,CKEDITOR, this.iceGame, "ICECourse")
		}
	}

	addFraction(num: string, den: string) {
		this.modalRef.hide()
		if (den != "" && num != "") {
			let denominator = parseInt(den);
			if (denominator != 0) {
				const frac = {
					numerator: num,
					denominator: den,
					type: 'fraction'
				};
				const fracStr = JSON.stringify(frac);
				let event = JSON.parse(fracStr);
				const fracObj = {
					data: event
				};
				const fracObjStr = JSON.stringify(fracObj);
				let data = JSON.parse(fracObjStr);
				this.iceCKEditor.insertTagEditArea(data, this.elementRef,editor,CKEDITOR, this.iceGame, "ICECourse" )
			} else {
				this.alertService.clear();
				this.alertService.error(this.translate.instant('WEB_EDITOR.DENOMINATOR_ZERO'));
			}
		} else {
			this.alertService.clear();
			this.alertService.error(this.translate.instant('WEB_EDITOR.NUMERATOR_AND_DENOMINATOR_EMPTY'));
		}
	}

	numberOnly(event): boolean {
		const charCode = (event.which) ? event.which : event.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}

	showLargeImage(template: TemplateRef<any>, selectedImageId: any) {
        this.selectedImage = {
			'id': '',
			'thumbnailImage': '',
			'largeImage': '',
			'title': ''
		}
        //show loading message
        var loadingMessage = null;
        this.translate.get('ICE_COURSE.COURSE_PREVIEW_IMAGE_LOADING').subscribe((res: string) => {
            loadingMessage = res;
        });
		this.loadingService.showLoading(true, null, loadingMessage, null);
        this.courseService.getSelectedImage(selectedImageId, UserVariable.getUserToken(), this);
        this.selectedModalTemplate = template;
	}
	
	public modelRefCloseEvent() {
		this.modalRef.hide()
		this.selectedImage = {
			'id': '',
			'thumbnailImage': '',
			'largeImage': '',
			'title': ''
		}
	}

	replaceWhiteSpace(str) {
		return str.replace(/[\s\u200B]/g,'');
	}

	/**
	 * validate form
	 */
	validateForm(): boolean {
		let self = this;
		var editortext_desc = this.replaceWhiteSpace($(".games_ck_editor"+ self.tabId).text());
		var length_desc = editortext_desc.length;

		if (length_desc < 1) {
			this.alertService.error(this.translate.instant('ICE_GAME.DESCRIPTION_ERROR'));
			return false;		
		}

		return true; 
	}
}
