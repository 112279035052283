import { Component, OnInit } from '@angular/core';
import { ResourceLoader } from '@angular/compiler';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DocumentTypesService } from '../../../document-types.service';
import { AlertService } from '../../../../../../util/alert/alert.service';
import { Router } from '@angular/router';
import { LoadingService } from '../../../../../../util/loading/loading.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
   selector: 'edit-modal',
   template: `   
   <div class="modal-header">
      <h4 class="modal-title pull-left">{{ title | translate }}</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <div class="container">
         <div class="form-group row">
            <label for="name" class="col-sm-3 col-form-label">{{ 'Attribute Name' | translate }}
            <div class="badge-required mx-1"></div>
            </label>         
               <div class="col-sm-8">
                  <input type="text" class="form-control" id="name" [(ngModel)]="name" name="name" >
               </div>
         </div>
         <div class="form-group row">
            <label for="name" class="col-sm-3 col-form-label">{{ 'Default Type' | translate }}
            <div class="badge-required mx-1"></div>
            </label>         
            <div class="col-sm-8">
               <ng-select  [active]="selectedAttribute" [allowClear]="true"
                  (removed)="removedAttribute($event)" (selected)="selectedAttributeId($event)"
                  [items]="attributeList" name="attribute" id="attribute">
               </ng-select>
            </div>
         </div>
      </div>
   </div>
   <div class="modal-footer">     
      <button type="button" class="btn btn-primary btn-sm" (click)="saveEditClick()">{{ 'SAVE' | translate}}</button>
      <button type="button" class="btn btn-secondary btn-sm" (click)="bsModalRef.hide()">{{ 'CANCEL' | translate}}</button>
   </div>  
   `
})
export class EditDocAttributeTypeModalComponent implements OnInit {
   public title: string = 'Edit Attributes';
   public id: string;
   public name: string;
   public attributeType: string;
   public selectedAttribute: Array<any> = []
   public attributeList: Array<any> = []
   public defAttributeType: string
   public editDocumentTypeAttribute: string;

   constructor(public bsModalRef: BsModalRef, private documentTypesService: DocumentTypesService,
      private alertService: AlertService, private router: Router, private loadingService: LoadingService,
      private translate: TranslateService) {
         translate.addLangs(["es", "en"]);
         translate.setDefaultLang("en");
         let browserLang = translate.getBrowserLang();
         translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
         this.translate.get('DOCUMENT_TYPE_ATTRIBUTES.EDIT_DOCUMENT_TYPE_ATTRIBUTE').subscribe((res: string) => {
            this.editDocumentTypeAttribute = res;
         });
      this.attributeList = [{ 'id': 'Number', 'text': 'Number' }, { 'id': 'Text', 'text': 'Text' }, { 'id': 'System', 'text': 'System' },
      { 'id': 'Document', 'text': 'Document' }, { 'id': 'Component', 'text': 'Component' }]
   }

   ngOnInit() {
      this.selectedAttribute = [{ 'id': this.attributeType, 'text': this.attributeType }]
   }

   public removedAttribute(event) {
      this.selectedAttribute = [{ 'id': this.attributeType, 'text': this.attributeType }]
   }

   public selectedAttributeId(event) {
      this.defAttributeType = event.text
   }

   public saveEditClick() {
      if (this.name != "" && this.name != undefined) {
         this.loadingService.showLoading(true, null, "", null)
         this.documentTypesService.attTypeNameAvailability(this.id, this.name)
            .subscribe(data => {
               this.loadingService.hideLoading()
               this.alertService.clear()
               if(this.defAttributeType == undefined){
                  this.defAttributeType = this.attributeType
               }
               if (data.payload == true) {
                  this.loadingService.showLoading(true, null, "", null)
                  this.bsModalRef.hide()
                  this.documentTypesService.doEditAttribute(this.id, this.name, this.defAttributeType)
                     .subscribe(data => {
                           this.loadingService.hideLoading()
                           this.alertService.success(this.editDocumentTypeAttribute);
                           this.documentTypesService.setItemData(this);
                        },
                        error => {
                           this.bsModalRef.hide()
                           this.loadingService.hideLoading()
                           this.alertService.error(error.statusDescription)
                        });

               } else {
                  this.alertService.clear();
                  this.alertService.error(data.statusDescription);
               }
            },
               error => {
                  this.bsModalRef.hide()
                  this.loadingService.hideLoading()
                  this.alertService.error(error.statusDescription)
               });
      }else{
         this.alertService.error("Please enter attribute name.")
      }
   }

}