import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AgGridAngular } from '@ag-grid-community/angular';
import { TranslateService } from '@ngx-translate/core';
import { TagsService } from '../tags.service';
import { WsCallback } from 'src/app/util/ws-callback.interface';
import { WsResponse } from 'src/app/util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';
import { LoadingService } from 'src/app/util/loading/loading.service';
import { UserVariable } from 'src/app/util/common/user-variable';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DependenciesmodalComponent } from 'src/app/util/dependenciesmodal/dependenciesmodal.component';
import { SharedService } from 'src/app/util/shared.service';
import { AlertService } from 'src/app/util/alert/alert.service';
import { initialState } from 'ngx-bootstrap/timepicker/reducer/timepicker.reducer';
import { Observable, of } from 'rxjs';
import { filter } from'rxjs/operators';
import { CommonUtil } from 'src/app/util/common/common-util';
import { Module, AllModules } from "@ag-grid-enterprise/all-modules";
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { DatePipe } from '@angular/common';

@Component({
	selector: 'app-add-tag',
	templateUrl: './add-tag.component.html',
	styleUrls: ['./add-tag.component.css']
})
export class AddTagComponent implements OnInit, WsCallback {

	public showSC: boolean = false
	public showFC: boolean = false 
	public caretPos: number = 0;
	public descIteration: number = 0;
	public rowCount: number = 4
	private token;

	// Variables to hold form values
	private tagId: string = '';
	private searchText: string = '';
	public projectName: string = '';
	public isUsedCmmsTracking: boolean = false;
	public functionalDeleteConfirm: boolean = false;
	public tagNumber: string = '';
	public cadNo: string = '';
	public numberAttr: boolean = false;
	public tagDescription: string = '';
	public equipmentDescription: string = '';
	public locationDescription: string = '';
	public numerator;
	public denominator;
	rowData: any = [];
	public modules: Module[] = AllModules;
	public defaultColDef: any;
	private facilityDependencyList = [];

	// Main dropdown lists
	public facilityTagInputList = [];
	public documentTagInputList = [];
	public dataSheetTagInputList = [];
	public systemTagInputList = [];

	// Selected items
	public selectedFacilityList = [];
	public selectedDocumentList = [];
	public selectedDataSheetList = [];
	public selectedSystemList = [];

	private selectedFacility: any;

	public tagClassList = [];
	public allTagClassList = [];
	public tagAttrClassList = [];
	public selectedTagClassList = [];

	// Attributes of the selected classification
	public tagAttributes = [];

	//Tag attribute related variables
	public allSystems = [];
	public systemListForNgSelect = [];
	public systemsWithoutFacilities = [];

	// Component lists
	public allComponents = [];
	public componentListForNgSelect = [];

	// Document lists
	public allDocuments = [];
	public documentListForNgSelect = [];
	public documentsWithoutFacilities = [];

	// Datasheets
	public datasheetsWithoutFacilities = [];

	public selectedAttributes = [];

	// Location parent
	@ViewChild('agGrid') agGrid: AgGridAngular;
	private columnDefs = [];
	private tagNo: string;
	private description: string;
	public allLocationParent = [];
	public filteredLocationParent = [];
	public selectedLocationParent = [];
	public selectedLocationParentRaw = [];
	public enableMore: boolean = false;

	// Error-class dropdown lists
	public failureClassList = [];
	public criticalityList = [];
	public safetyCriticalityList = [];
	public disciplineList = [];
	public equipmentTypeList = [];

	// Selected error-class
	public selectedFailureClasses = [];
	public selectedCriticalityList = [];
	public selectedSafetyCriticalityList = [];
	public selectedDisciplineList = [];
	public selectedEquipmentTypeList = [];

	public textValues: string;
	public alternateTagNoValue: string;
	public nextAvailableEquipmentNo: string;
	public isEquipmentNoCheckboxSelected:boolean = false;

	// Used in Commisioning
	public usedInCMMSTrac: boolean = false;
	public assetGroupsList = [];
	public selectedAssetGroupsList = [];
	public assetParentList = [];
	public selectedParentList = [];
	public assetAttributeList = [];
	public functionCodeList = [];
	public selectedFunctionCodeList = [];
	public vendorList = [];
	public selectedVendorList = [];

	// Functional location
	private columnDefsFunctionalLocation = [];
	private alternateTagNo: string;
	rowDataFunctionalLocation: any = [];
	public enableMoreFunctional: boolean = false;
	public suggestionListMore: any[] = [];
	public selectedFunctionalLocationRaw = [];
	public selectedIndex: number;

	// Asset attributes
	public assetAttributes = [];
	public assetListNgSelect = [];
	public selectedAssetAttributes = [];
	public valueListValues = [];
	public valueListNgSelect = [];
	public selectedValueListValues = [];
	public textTypeValue: any;
	public assetAttributeSelectionList =[]

	// Loading view to handle parallel loading
	private loadingHandler: any;

	// Pre conditions
	public tagAddPreConditions: any = {};

	// Alternate tag search
	public suggestionOriginalList: any[] = [];
	public suggestionList: any[] = [];
	public tagNoCount: number = 0;
	public addedSuggestionList: any[] = [];
	//public selectedSuggestionItem: any[] = [];

	//This has been initialized for 15 items assuming that it can contain maximum of 15 items.
	// If the maximum cound is increased then this values should also be increased.
	public enableDisableItems: boolean[] = new Array(15).fill(true);
	public enable: boolean[] = new Array(15).fill(true);
	public calculatedFunctionalLocationValue: string = '';

	public otnTargetCompletionDate: any;
	public irnTargetCompletionDate:any;
	public bsConfig: Partial<BsDatepickerConfig>;

	/**
	 * Tag - Implement tag 'Remember Data' functionality.
	 * Trello Card - https://trello.com/c/RnnKq96z
	 */
	public isRememberData:boolean = true;

	constructor(private translate: TranslateService, private tagService: TagsService, private loadingService: LoadingService,
		private bsModalService: BsModalService, private sharedService: SharedService, private alertService: AlertService, 
		public bsModalRef: BsModalRef, public bsModalRefLocationParent: BsModalRef, public bsModalRefFunctionalLocation: BsModalRef, private commonUtil: CommonUtil) {
		translate.addLangs(["es", "en"]);
		translate.setDefaultLang("en");
		let browserLang = translate.getBrowserLang();
		this.token = UserVariable.getUserToken();
		if(!this.commonUtil.validateToken(this.token)){
            return;
        }
		this.enableDisableItems[0] = false;
		this.enable[0] = false;
		translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
		this.translate.get('TAG_ADD.ALTERNATE_TAG_NO').subscribe((res: string) => {
			this.alternateTagNo = res;
			this.translate.get('TAG_ADD.TAG_NO').subscribe((res: string) => {
				this.tagNo = res;	
				this.translate.get('TAG_ADD.DESCRIPTION').subscribe((res: string) => {
					this.description = res;				
				});			
			});
		});

		this.columnDefs = [	
			{ headerName: this.tagNo, field: "tagNo", width: 150 },
			{ headerName: this.description, field: "description", width: 520 }
			
		];

		this.defaultColDef = {
			resizable: true,
			sortable: true,
		};

		this.columnDefsFunctionalLocation = [	
			{ headerName: this.alternateTagNo, field: "alternateTagNo", width: 170 },
			{ headerName: this.tagNo, field: "tagNo", width: 150 },
			{ headerName: this.description, field: "description", width: 420 }			
		];
		this.bsConfig = Object.assign({}, { containerClass: 'theme-default' });
	}

	ngOnInit() {
		this.loadingHandler = new AddTagComponent.LoadingHandler();
		this.loadingHandler.init(13, this.loadingService);

		this.tagId = this.createUUID();
		/*
		**
		Loading initial Data
		**
		*/

		this.tagService.getTagAddPreConditions(this.token, this);
		this.tagService.getProject(this.token ,this);

		// This wwill return all the facilities
		this.tagService.getAllFacility(this);

		this.tagService.getTagClassifications(this.token, this);
		//this.tagService.getTagClassificationListWithAttributes(this.token, this);
		

		// This will return systems with no facilities
		this.tagService.getInitialSystemsByFacilityId(this.token, "0").subscribe(
			data => {
				this.systemsWithoutFacilities = [];
				if(data.payload != null){
					this.systemsWithoutFacilities = data.payload;
					/*** 
			 		* Display System Number and System Name in the drop down items
			 		* Trello Card - https://trello.com/c/F9e5rKLy
					*/
					//this.systemTagInputList = data.payload;
					this.systemsWithoutFacilities.forEach(system => {
						this.systemTagInputList.push({ "id": system.id, "systemNoandsystemName": system.systemNo + ' ' + system.systemName });
					});
				}
				this.loadingHandler.handle();
			},
			error => {
				this.alertService.error(error.statusDescription);
				this.loadingHandler.handle();
			}
		);

		// This will return documents with no facilities
		this.tagService.getInitialDocumentsByFacilityId(this.token, "0").subscribe(
			data => {
				this.documentsWithoutFacilities = [];
				if (data.payload != null) {
					this.documentsWithoutFacilities = data.payload;
					this.documentTagInputList = data.payload;

					this.documentListForNgSelect = [];
					this.allDocuments = data.payload;
					this.allDocuments.forEach(document => {
						this.documentListForNgSelect.push({ "id": document.id, "text": document.documentName });
					});
				}
				this.loadingHandler.handle();
			},
			error => {
				this.alertService.error(error.statusDescription);
				this.loadingHandler.handle();
			}
		);

		// This will return datasheets with no facilities
		this.tagService.getInitialDataSheetsByFacilityId(this.token, "0").subscribe(
			data => {
				this.datasheetsWithoutFacilities = [];
				if(data.payload != null){
					this.datasheetsWithoutFacilities = data.payload;
					this.dataSheetTagInputList = data.payload;
				}
				this.loadingHandler.handle();
			},
			error => {
				this.alertService.error(error.statusDescription);
				this.loadingHandler.handle();
			}
		);

		// This will return all the systems/components/documents in the projects as the component
		this.tagService.getSystemsByProjectId(this.token, this);
		this.tagService.getComponentsByProjectId(this.token, this);

		// This will return tags for parent tag lists
		// this.tagService.getAllTagSuggestionModelList(this.token, null, this);

		this.tagService.getFailureClassList(this.token, this);
		this.tagService.getSafetyCriticalList(this.token, this);
		this.tagService.getCriticalityList(this.token, this);
		this.tagService.getEquipmentTypeList(this.token, this);
		this.tagService.getDisciplineList(this.token, this);

		this.tagService.getAllActiveAssetGroups(this.token, this);
		this.tagService.getAllFunctionCodes(this.token, this);
		this.tagService.getAllVendors(this.token, this);

		/**
		 * get all the active tags which are marked as used in commissioning (to bind the Asset Parent list) 
		 * Issue - Two different queries running in V2 and V3 after selecting Asset Group to Populate Asset Parent Drop Down
		 *  Trello Card - https://trello.com/c/eUyEMcCb
		 * */
		this.tagService.getTagListByProjectIdUsedInCommissioning(this.token, this);
	}

	public showSpecialCharacters() {
		this.showFC = false;
		this.showSC = !this.showSC;
		this.rowCount = 2;
	}

	public showFractions() {
		this.showSC = false;
		this.showFC = !this.showFC;
		this.rowCount = 2;
	}

	private hidePanels() {
		this.showFC = false;
		this.showSC = false;
		this.rowCount = 4;
	}

	/**
	 * Click event for the add button
	 */
	public tagAddSaveButtonClick() {

		console.log(JSON.stringify(this.selectedAttributes));
		//Validate required fields
		//Enable this later
		if (this.tagNumber == undefined || this.tagNumber.trim() == "") {
			this.alertService.error("Tag number is required");
			return;
		}
		if (this.tagDescription == undefined || this.tagDescription.trim() == "") {
			this.alertService.error("Tag description is required");
			return;
		}
		if (this.selectedSystemList.length == 0) {
			this.alertService.error("System is required");
			return;
		}

		if (this.isUsedCmmsTracking && this.alternateTagNoValue != undefined && this.alternateTagNoValue != null) {
			if (/\s/.test(this.alternateTagNoValue)) {
				this.alertService.error("Alternate Tag No can't have whitespace.");
				return;
			} else {
				console.log("valid alt tag no.")
			}
		}

        if ((this.usedInCMMSTrac && this.selectedAssetGroupsList == undefined)
            || (this.usedInCMMSTrac && this.selectedAssetGroupsList.length == 0)) {
            this.alertService.error("Asset group cannot be empty when the commissioning is enabled");
            return;
        }

		if ((!this.IsValidDate(this.otnTargetCompletionDate) || !this.IsValidDate(this.irnTargetCompletionDate))) {
			this.alertService.clear;
            this.alertService.error("Invalid date selected");
            return;
        }

		//Process asset atributes
        if (this.assetAttributeList != null) {
			let emptySelectedAttributeCount = 0;
            for (let i = 0; i < this.assetAttributeList.length; i++){
                let requiredField = this.assetAttributeList[i].required;
                if (requiredField == "Yes") {
					if (this.selectedAssetAttributes[i] != null) {
						let selectedAttributeValue = this.selectedAssetAttributes[i].value;
						if (selectedAttributeValue == null || selectedAttributeValue.trim() == "") {
							emptySelectedAttributeCount++;
						}
					}else{
						emptySelectedAttributeCount++;
					}
                }
            }
            if (emptySelectedAttributeCount > 0) {
                this.alertService.error("Asset Attribute cannot be empty");
                return;
            } else {

            }
		}

		//Process tag atributes
        if (this.tagAttributes != null) {
            let requiredFieldCount = 0;
            for (let i = 0; i < this.tagAttributes.length; i++){
                let requiredField = this.tagAttributes[i].required;
                if (requiredField == "yes") {
                    requiredFieldCount++;
                }
            }
            let emptySelectedAttributeCount = 0;
            for (let i = 0; i < this.selectedAttributes.length; i++) {
                let selectedAttributeValue = this.selectedAttributes[i].value.trim();
                if (selectedAttributeValue == "") {
                    emptySelectedAttributeCount++;
                }
            }
            if (this.selectedAttributes.length < requiredFieldCount || emptySelectedAttributeCount > 0) {
                this.alertService.error("Attribute cannot be empty");
                return;
            } else {
                //All required fields filled.
            }
        }
		//convert special characters (like '<') to html entities
		this.tagDescription = $('<div/>').text(this.tagDescription).html();	
		let tagDesc = this.tagDescription.replace(/\\/g, "\\\\"); 	
		let tagNum = this.tagNumber.replace(/\\/g, "\\\\");
		let CADNum = this.cadNo.replace(/\\/g, "\\\\");
		let altTagNo = null;
		if (this.alternateTagNoValue != null) {
			altTagNo = this.alternateTagNoValue.replace(/\\/g, "\\\\");
		}
		let equipmentDesc = this.equipmentDescription.replace(/\\/g, "\\\\");
		let locationDesc = this.locationDescription.replace(/\\/g, "\\\\");
		if (!this.isUsedCmmsTracking) {
			this.alternateTagNoValue = null;
		}
		let otnTargetCompletionDate = null;
		let irnTargetCompletionDate = null;
		if (this.otnTargetCompletionDate != null) {
			otnTargetCompletionDate = new DatePipe("en-US").transform(new Date(this.otnTargetCompletionDate), 'yyyy-MM-dd')
		}
		if (this.irnTargetCompletionDate != null) {
			irnTargetCompletionDate = new DatePipe("en-US").transform(new Date(this.irnTargetCompletionDate), 'yyyy-MM-dd')
		}
		let data = {
			tagId: this.tagId,
			tagNumber: tagNum,
			cadNo: CADNum.trim().length != 0 ? CADNum: null,
			tagDescription: "<tagdesc><txt>" + tagDesc + "</txt></tagdesc>",
			facility: this.selectedFacilityList.length != 0? this.selectedFacilityList[0].facilityId: null,
			document: this.selectedDocumentList.length != 0? this.selectedDocumentList[0].id: null,
			system: this.selectedSystemList.length != 0? this.selectedSystemList[0].id: "",
			dataSheet: this.selectedDataSheetList.length != 0? this.selectedDataSheetList[0].id: null,
			classification: this.selectedTagClassList.length != 0? this.selectedTagClassList[0].classId: "",
			equipmentDescription: equipmentDesc,
			locationDescription: locationDesc,
			locationParent: this.selectedLocationParent.length != 0? this.selectedLocationParent[0].id: "",
			failureClass: this.selectedFailureClasses.length != 0? this.selectedFailureClasses[0].id: null,
			criticality: this.selectedCriticalityList.length != 0? this.selectedCriticalityList[0].id: null,
			safetyCritical: this.selectedSafetyCriticalityList.length != 0? this.selectedSafetyCriticalityList[0].id: null,
			discipline: this.selectedDisciplineList.length != 0? this.selectedDisciplineList[0].id: null,
			equipmentType: this.selectedEquipmentTypeList.length != 0? this.selectedEquipmentTypeList[0].id: null,
			usedInCommissioning: this.usedInCMMSTrac != undefined? this.usedInCMMSTrac: "",
			assetGroup: this.selectedAssetGroupsList.length != 0? this.selectedAssetGroupsList[0].assetGroupId:"",
			assetParent: this.selectedParentList.length != 0? this.selectedParentList[0].id: "",
			functionCode: this.selectedFunctionCodeList.length != 0? this.selectedFunctionCodeList[0].functionCodeId: "",
			vendor: this.selectedVendorList.length != 0? this.selectedVendorList[0].vendorId: "",
			//need to send assetAttributeId
			/*assetAttribute: {
				assetAttributeAsset: this.selectedAssetAttributes.length != 0? this.selectedAssetAttributes[0].id: "",
				assetAttributeText: this.textTypeValue != undefined? this.textTypeValue: "",
				assetAttributeValue: this.selectedValueListValues.length != 0? this.selectedValueListValues[0].id: ""
			},*/
			assetAttribute: this.selectedAssetAttributes,
			tagAttributes: this.selectedAttributes != undefined? this.selectedAttributes: "",
			equipmentNo: this.nextAvailableEquipmentNo,
			alternateTagNo: altTagNo,
			functionalLocation: this.calculatedFunctionalLocationValue,
			numOfMasks: this.tagAddPreConditions.cmmsNoOfMasks != undefined? this.tagAddPreConditions.cmmsNoOfMasks.noOfElements: 0,
			funLocTagIdList: this.getSelectedTagIds(),
			funLocTagNoList: this.getSelectedAlternateTagNo(),
			otnTargetCompletionDate:otnTargetCompletionDate,
			irnTargetCompletionDate:irnTargetCompletionDate
		};

		var json = JSON.stringify(data);
		this.loadingService.showLoading(true, false, this.translate.instant('SAVING'), 0);
		this.tagService.addNewTag(this.token, json, this);
	}

	IsValidDate(value: Date): boolean {
		if(value != undefined){
		  var date = new Date(value);
		  if(date.toString() == 'Invalid Date'){
			return false;
		  }
		  else{
			return true;
		  }
		}else{
		  return true;
		}
	  }

	public getValueChanges(val, type: string, attributeId: string, tagSpecId: string = "") {
		console.log(JSON.stringify(val));
		if (this.numberAttr) {
			$('#'+attributeId).val('');
			this.numberAttr = false;
		}
		switch (type) {
			case 'component':
				//this.selectedComponentOptions[this.selectedComponentOptions.length] = val;
				this.selectedAttributes[this.selectedAttributes.length] = { attributeId: attributeId, value: val.id, tagClassSpecId: tagSpecId };
				break;
			case 'system':
				//this.selectedSystemOptions[this.selectedSystemOptions.length] = val;
				this.selectedAttributes[this.selectedAttributes.length] = { attributeId: attributeId, value: val.id, tagClassSpecId: tagSpecId };
				break;
			case 'document':
				//this.selectedDocumentOptions[this.selectedDocumentOptions.length] = val;
				this.selectedAttributes[this.selectedAttributes.length] = { attributeId: attributeId, value: val.id, tagClassSpecId: tagSpecId };
				break;
			case 'text':
				let index = -1;
				for (let i = 0; i < this.selectedAttributes.length; i++) {
					if (this.selectedAttributes[i].attributeId == attributeId) {
						index = i;
					}
				}

				if (index == -1) {
					//if the item is not existed.
					this.selectedAttributes[this.selectedAttributes.length] = { attributeId: attributeId, value: val.currentTarget.value, tagClassSpecId: tagSpecId };
					//this.selectedAttributes[this.selectedAttributes.length] = { attributeId: attributeId, value: val.currentTarget.value, tagClassSpecId: tagSpecId };
				} else {
					//if the item is existed.
					this.selectedAttributes[index] = { attributeId: attributeId, value: val.currentTarget.value, tagClassSpecId: tagSpecId };
					//this.selectedAttributes[index] = { attributeId: attributeId, value: val.currentTarget.value, tagClassSpecId: tagSpecId };
				}
				break;
			case 'date':
				this.selectedAttributes[this.selectedAttributes.length] = { attributeId: attributeId, value: val.currentTarget.value, tagClassSpecId: tagSpecId };
				break;
			case 'asset_attribute_asset':
				console.log('asset - value=' + val.id + ' attributeId=' +  attributeId);
				this.selectedAssetAttributes[tagSpecId] = { attributeId: attributeId, value: val.id };
				break;
			case 'asset_attribute_value':
				console.log('value - value=' + val.id + ' attributeId=' +  attributeId);
				this.selectedAssetAttributes[tagSpecId] = { attributeId: attributeId, value: val.id };
				break;
			case 'asset_attribute_text':
				console.log('text - value=' + val.currentTarget.value + ' attributeId=' +  attributeId);
				this.selectedAssetAttributes[tagSpecId] = { attributeId: attributeId, value: val.currentTarget.value };
				break;
			default:
				break;
		}
	}

	/**
	 * Click event for the cancel button
	 */
	public tagAddCancelButtonClick() {
		this.tagNumber = '';
		this.tagDescription = '';
		this.locationDescription = '';
		this.equipmentDescription = '';
		this.numerator = '';
		this.denominator = '';
		this.selectedDataSheetList = [];
		this.selectedDocumentList = [];
		this.selectedFacilityList = [];
		this.selectedSystemList = [];
		this.bsModalRef.hide()
		this.alertService.clear()
	}

	public locationParentCancel() {
		this.bsModalRefLocationParent.hide();		
	}

	getCaretPos(oField) {		
		if (oField.selectionStart || oField.selectionStart == '0') {
		   this.caretPos = oField.selectionStart;
		}
	}

	/**
	 * Set fraction
	 */
	public setNumeratorDenominator() {
		//check the validity of this.numerator and this.denominator
		if(this.numerator != null && this.denominator != null && this.numerator != undefined && this.denominator != undefined) {
			if (this.caretPos != 0 || this.descIteration > 0) {	
				this.tagDescription = [this.tagDescription.slice(0, this.caretPos), '' + this.numerator +'/'+ this.denominator, 
				this.tagDescription.slice(this.caretPos)].join('');
			} else {
			this.tagDescription = this.tagDescription + '' +  this.numerator +'/'+ this.denominator;
			}
			this.descIteration++;
		}
		setTimeout(() => {
			this.hidePanels();
		}, 100);
	}

	/**
	 * Set special character
	 * @param val
	 */
	public setSpecialCharacter(val: any) {
		if (this.caretPos != 0 || this.descIteration > 0) {	
			this.tagDescription = [this.tagDescription.slice(0, this.caretPos), val.target.innerText, 
			this.tagDescription.slice(this.caretPos)].join('');
		} else {
			this.tagDescription = this.tagDescription + '' +  val.target.innerText;
		}		
		this.descIteration++;
		this.hidePanels();
	}

	/**
	 * Set more values in Location Parent dropdown box
	 * @param val
	 */
	public setMoreLocationParent(template: TemplateRef<any>) {	
		this.rowData = [];
		this.selectedLocationParentRaw = [];
		for (let i = 0; i < this.allLocationParent.length; i++) {			
			this.allLocationParent[i]["description"] = this.allLocationParent[i]["description"].replace("<tagdesc><txt>", "")
			this.allLocationParent[i]["description"] = this.allLocationParent[i]["description"].replace("</txt></tagdesc>", "")
				this.rowData.push({
					id: this.allLocationParent[i]["id"],					
					tagNo: this.allLocationParent[i]["tagNo"],
					description: this.allLocationParent[i]["description"]
				})

		}
		this.bsModalRefLocationParent = this.bsModalService.show(template, { class: 'modal-lg', backdrop: 'static', keyboard: false });
	}

	onSuccess(data: WsResponse, serviceType: WsType) {
		if (serviceType == WsType.GET_ALL_FACILITY) {
			// Setting facility dropdown
			this.facilityTagInputList = data.payload;
			this.loadingHandler.handle();
		} else if (serviceType == WsType.GET_SYSTEMS_BY_FACILITY_ID) {
			// Setting facility dropdown according to the facility
			/*** 
			 	* Display System Number and System Name in the drop down items
			 	* Trello Card - https://trello.com/c/F9e5rKLy
			*/
			//this.systemTagInputList = data.payload;
			data.payload.forEach(system => {
				this.systemTagInputList.push({ "id": system.id, "systemNoandsystemName": system.systemNo + ' ' + system.systemName });
			});
			this.loadingHandler.handle();
		} else if (serviceType == WsType.GET_DOCUMENTS_BY_FACILITY_ID) {
			// Setting documents dropdown according to the facility
			this.documentTagInputList = data.payload;

			this.loadingHandler.handle();
		} else if (serviceType == WsType.GET_DATA_SHEETS_BY_FACILITY_ID) {
			//completed data loading process for the selected facility
			this.dataSheetTagInputList = data.payload;
			this.loadingHandler.handle();
        } else if (serviceType == WsType.GET_TAG_CLASSIFICATION) {
            this.allTagClassList = data.payload;
            this.tagClassList = this.allTagClassList;
            //this.tagService.getTagClassificationListWithAttributes(this.token, this);
        } else if (serviceType == WsType.GET_ATTRIBUTES_BY_CLASSIFICATION) {
            this.tagAttributes = data.payload;
		} else if (serviceType == WsType.GET_TAG_CLASSIFICATION_LIST_WITH_ATTRIBUTES) {
			// this.tagAttrClassList = data.payload;
			// console.log("=tagAttrClassList=============");
			// console.log(this.tagAttrClassList);

			// Array.prototype.push.apply(this.tagAttrClassList, this.allTagClassList); 
			// this.tagAttrClassList = this.getUnique(this.tagAttrClassList,'classId');
			// console.log("=tagAttrClassList===========UNQ==");
			// console.log(this.tagAttrClassList);
			// this.tagClassList = this.tagAttrClassList;
			// this.loadingHandler.handle();
		} else if (serviceType == WsType.GET_SYSTEMS_BY_PROJECT_ID) {
			this.systemListForNgSelect = [];
			this.allSystems = data.payload;
			this.allSystems.forEach(system => {
				this.systemListForNgSelect.push({ "id": system.id, "text": system.systemNo + ' ' + system.systemName });
			});
			//this is commented out to fix the issue  https://trello.com/c/msZNSnEG
			//this.systemTagInputList = data.payload; 
			this.loadingHandler.handle();
		} else if (serviceType == WsType.GET_COMPONENTS_BY_PROJECT_ID) {
			this.componentListForNgSelect = [];
			this.allComponents = data.payload.components;
			this.isUsedCmmsTracking = data.payload.isUsedCmmsTracking;
			this.allComponents.forEach(component => {
				this.componentListForNgSelect.push({ "id": component.id, "text": component.componentValue });
			});
			this.loadingHandler.handle();
		} else if (serviceType == WsType.GET_DOCUMENTS) {
			this.documentListForNgSelect = [];
			this.allDocuments = data.payload;
			this.allDocuments.forEach(document => {
				this.documentListForNgSelect.push({ "id": document.id, "text": document.documentName });
			});
			this.loadingHandler.handle();
		} else if (serviceType == WsType.GET_ALL_TAG_SUGGESTION_MODEL_LIST) {
			this.allLocationParent = data.payload;
			if (this.allLocationParent.length > 10)	{
				this.enableMore = true;
			}
			this.createInitialParentList();
			this.loadingHandler.handle();
		} else if (serviceType == WsType.GET_FAILURE_CLASS_LIST) {
			this.failureClassList = data.payload;
			this.loadingHandler.handle();
		} else if (serviceType == WsType.GET_SAFETY_CRITICAL_LIST) {
			this.safetyCriticalityList = data.payload;
			this.loadingHandler.handle();
		} else if (serviceType == WsType.GET_CRITICALITY_LIST) {
			this.criticalityList = data.payload;
			this.loadingHandler.handle();
		} else if (serviceType == WsType.GET_EQUIPMENT_TYPE_LIST) {
			this.equipmentTypeList = data.payload;
			this.loadingHandler.handle();
		} else if (serviceType == WsType.GET_DISCIPLINE_LIST) {
			this.disciplineList = data.payload;
			this.loadingHandler.handle();
		} else if (serviceType == WsType.GET_ALL_ACTIVE_ASSET_GROUPS) {
			this.assetGroupsList = data.payload;
			this.loadingService.hideLoading();	
		} else if (serviceType == WsType.GET_ASSET_GROUP_ATTRIBUTES) {
			this.assetAttributeList = data.payload;
			this.assetAttributeSelectionList = [];
			if ((this.assetAttributeList != null) && (this.assetAttributeList != undefined)) {
				this.assetAttributeList.forEach((element, i) => {
					this.assetAttributeSelectionList[i]  = {attributeType: "default", attributeId:null, assetListNgSelect: [], valueListNgSelect: []}
					if ((element.attributeType == "Asset") && (element.selectedDataId != '0') && (element.selectedDataId != null)) {
						this.assetAttributeSelectionList[i] = {selectedDataId:element.selectedDataId, attributeId:element.assetAttributeId, attributeType: "Asset", assetListNgSelect: []}
						this.tagService.getAssetAttributes(this.token, element.selectedDataId, element.assetAttributeId, this);
					}
					if ((element.attributeType == "ValueList") && (element.selectedDataId != '0') && (element.selectedDataId != null)) {
						this.assetAttributeSelectionList[i] = {selectedDataId:element.selectedDataId, attributeId:element.assetAttributeId, attributeType: "ValueList", valueListNgSelect: []}
						this.tagService.getValueListValues(this.token, element.selectedDataId, element.assetAttributeId, this);
					}
				});
			}
			this.loadingService.hideLoading();	
		} else if (serviceType == WsType.GET_ASSET_GROUP_PARENTS) {
			//this.assetParentList = data.payload;
			this.loadingHandler.handle();
		} else if (serviceType == WsType.GET_ALL_FUNCTION_CODES) {
			this.functionCodeList = data.payload;
			this.loadingHandler.handle();
		} else if (serviceType == WsType.GET_ALL_VENDORS) {
			this.vendorList = data.payload;
			this.loadingHandler.handle();
		} else if (serviceType == WsType.GET_ASSET_ATTRIBUTES) {
			this.assetListNgSelect = [];
			if (data.payload != null) {
				if (data.payload.assetList != null) {
					this.assetAttributes = data.payload.assetList;
					let arr = []
					// this.assetAttributes.forEach(element => {
					// 	this.assetListNgSelect.push({ "id": element.assetId, "text": element.assetName });
					// });
					if ((this.assetAttributes[0] != null) && (this.assetAttributes[0] != undefined)) {
						this.assetAttributes.forEach(element => {
							arr.push({ "id": element.assetId, "text": element.assetName });
						});
					}
					for (let i = 0; i < this.assetAttributeSelectionList.length; i++) {
						if ((this.assetAttributeSelectionList != null) && (this.assetAttributeSelectionList != undefined)) {
							if (this.assetAttributeSelectionList[i].attributeType ==  "Asset") {
								if ((this.assetAttributes[0] != null) && (this.assetAttributes[0] != undefined)) {
									if (this.assetAttributeSelectionList[i].attributeId == data.payload.attributeId) {
										this.assetAttributeSelectionList[i].assetListNgSelect = arr;
										break;
									}
								}
							}
						}
					}
				}
			}
			this.loadingService.hideLoading();
		} else if (serviceType == WsType.GET_VALUE_LIST_VALUES) {
			this.valueListNgSelect = [];
			if (data.payload != null) {
				if (data.payload.valueList != null) {
					this.valueListValues = data.payload.valueList;
					// this.valueListValues.forEach(element => {
					// 	this.valueListNgSelect.push({ "id": element.id, "text": element.valueListValue });
					// });
					let arr = []
					if ((this.valueListValues != null) && (this.valueListValues != undefined)) {
						this.valueListValues.forEach(element => {
							arr.push({ "id": element.id, "text": element.valueListValue });
						});
					}
					if ((this.assetAttributeSelectionList != null) && (this.assetAttributeSelectionList != undefined)) {
						for (let i = 0; i < this.assetAttributeSelectionList.length; i++) {
							if (this.assetAttributeSelectionList[i].attributeType ==  "ValueList") {
								if ((this.valueListValues[0] != null) && (this.valueListValues[0] != undefined)) {
									if (this.assetAttributeSelectionList[i].attributeId == data.payload.attributeId) {
										this.assetAttributeSelectionList[i].valueListNgSelect = arr;
										break;
									}
								}
							}
						}
					}
				}
			}
			this.loadingHandler.handle();
		} else if (serviceType == WsType.ADD_NEW_TAG) {
			this.alertService.info(data.statusDescription +". " +this.translate.instant("REUSABLES.REFRESH_REUSABLE_TABLE"));				
			this.reinitializeVariable();
			this.loadingService.hideLoading();
			/**
			 * Tag - Implement tag 'Remember Data' functionality.
			 * Trello Card - https://trello.com/c/RnnKq96z
			 */
			if(!this.isRememberData){
				this.bsModalRef.hide();
			}
			else {
				this.tagId = this.createUUID();
				
				// return location parents
				// this.tagService.getAllTagSuggestionModelList(this.token, null, this);
				/**
		 		* get all the active tags which are marked as used in commissioning (to bind the Asset Parent list) 
		 		* Issue - Two different queries running in V2 and V3 after selecting Asset Group to Populate Asset Parent Drop Down
		 		*  Trello Card - https://trello.com/c/eUyEMcCb
		 		* */
				this.tagService.getTagListByProjectIdUsedInCommissioning(this.token, this);
			}
		} else if (serviceType == WsType.GET_PROJECT) {
			this.projectName = data.payload.projectName;
		}  else if (serviceType == WsType.GET_TAG_ADD_PRE_CONDITIONS) {
			this.tagAddPreConditions = data.payload;
			console.log('data loaded');
		} else if (serviceType == WsType.SEARCH_ALTERNATE_TAG) {
			this.tagNoCount = data.payload.tagNoCount;
			this.suggestionOriginalList = data.payload.tagList;			
			this.suggestionList = data.payload.tagList;			
			this.setSuggestionList();
		} else if (serviceType == WsType.GET_NEXT_AVAILABLE_EQUIPMENT_NUMBER) {
			if(this.isEquipmentNoCheckboxSelected)
			this.nextAvailableEquipmentNo = data.payload;
		}
		/**
		 * set all the active tags which are marked as used in commissioning
		 * Issue - Two different queries running in V2 and V3 after selecting Asset Group to Populate Asset Parent Drop Down
		 *  Trello Card - https://trello.com/c/eUyEMcCb
		 * */
		else if(serviceType == WsType.GET_TAG_BY_TAG_ID_USED_IN_COMMISSIONING){
			if(data.payload != null)
			data.payload.forEach(tag => {
				this.assetParentList.push({ "id": tag.tagId, "tagNoandtagDescription": tag.tagNo + ' ' + tag.description.replace("<tagdesc><txt>", "").replace("</txt></tagdesc>","") });
			});
		}		
		this.loadingHandler.handle();
	}

	onFail(data: WsResponse, serviceType: WsType) {
		this.loadingService.hideLoading();
		if (serviceType == WsType.GET_ALL_FACILITY) {
			this.alertService.error(data.statusDescription);
			this.loadingHandler.handle();
		} else if (serviceType == WsType.GET_SYSTEMS_BY_FACILITY_ID) {
			this.alertService.error(data.statusDescription);
			this.loadingHandler.handle();
		} else if (serviceType == WsType.GET_DOCUMENTS_BY_FACILITY_ID) {
			this.alertService.error(data.statusDescription);
			this.loadingHandler.handle();
		} else if (serviceType == WsType.GET_DATA_SHEETS_BY_FACILITY_ID) {
			this.alertService.error(data.statusDescription);
			this.loadingHandler.handle();
		} else if (serviceType == WsType.GET_TAG_CLASSIFICATION_LIST_WITH_ATTRIBUTES) {
			this.alertService.error(data.statusDescription);
			this.loadingHandler.handle();
		} else if (serviceType == WsType.GET_SYSTEMS_BY_PROJECT_ID) {
			this.alertService.error(data.statusDescription);
			this.loadingHandler.handle();
		} else if (serviceType == WsType.GET_COMPONENTS_BY_PROJECT_ID) {
			this.alertService.error(data.statusDescription);
			this.loadingHandler.handle();
		} else if (serviceType == WsType.GET_DOCUMENTS) {
			this.alertService.error(data.statusDescription);
			this.loadingHandler.handle();
		} else if (serviceType == WsType.GET_ALL_TAG_SUGGESTION_MODEL_LIST) {
			this.alertService.error(data.statusDescription);
			this.loadingHandler.handle();
		} else if (serviceType == WsType.GET_FAILURE_CLASS_LIST) {
			this.alertService.error(data.statusDescription);
			this.loadingHandler.handle();
		} else if (serviceType == WsType.GET_SAFETY_CRITICAL_LIST) {
			this.alertService.error(data.statusDescription);
			this.loadingHandler.handle();
		} else if (serviceType == WsType.GET_CRITICALITY_LIST) {
			this.alertService.error(data.statusDescription);
			this.loadingHandler.handle();
		} else if (serviceType == WsType.GET_EQUIPMENT_TYPE_LIST) {
			this.alertService.error(data.statusDescription);
			this.loadingHandler.handle();
		} else if (serviceType == WsType.GET_DISCIPLINE_LIST) {
			this.alertService.error(data.statusDescription);
			this.loadingHandler.handle();
		} else if (serviceType == WsType.GET_ALL_ACTIVE_ASSET_GROUPS) {
			this.alertService.error(data.statusDescription);
			this.loadingHandler.handle();
		} else if (serviceType == WsType.GET_ASSET_GROUP_ATTRIBUTES) {
			this.alertService.error(data.statusDescription);
			this.loadingHandler.handle();
		} else if (serviceType == WsType.GET_ASSET_GROUP_PARENTS) {
			this.alertService.error(data.statusDescription);
			this.loadingHandler.handle();
		} else if (serviceType == WsType.GET_ALL_FUNCTION_CODES) {
			this.alertService.error(data.statusDescription);
			this.loadingHandler.handle();
		} else if (serviceType == WsType.GET_ALL_VENDORS) {
			this.alertService.error(data.statusDescription);
			this.loadingHandler.handle();
		} else if (serviceType == WsType.GET_ASSET_ATTRIBUTES) {
			this.alertService.error(data.statusDescription);
			this.loadingHandler.handle();
		} else if (serviceType == WsType.GET_VALUE_LIST_VALUES) {
			this.alertService.error(data.statusDescription);
			this.loadingHandler.handle();
		} else if (serviceType == WsType.ADD_NEW_TAG) {
			this.alertService.error(data.statusDescription);
		} else if (serviceType == WsType.GET_PROJECT) {
			this.alertService.error(data.statusDescription);
		} else if (serviceType == WsType.GET_TAG_ADD_PRE_CONDITIONS) {
			this.tagAddPreConditions = data.payload.projectName;
		} else if (serviceType == WsType.SEARCH_ALTERNATE_TAG) {
			this.alertService.error(data.statusDescription);
		}  else if (serviceType == WsType.GET_NEXT_AVAILABLE_EQUIPMENT_NUMBER) {
			this.alertService.info(data.statusDescription);
		}else if(serviceType == WsType.GET_TAG_BY_TAG_ID_USED_IN_COMMISSIONING){
			this.alertService.info(data.statusDescription);
		}	
	}

	public getUnique(arr, comp) {
		const unique = arr.map(e => e[comp]).map((e, i, final) => final.indexOf(e) === i && i).filter(e => arr[e]).map(e => arr[e]);	  
		return unique;
	}

	public selectItemFromSuggestions(val: any, type: string) {
		switch (type) {
			case 'facility':
			console.log('selected item = ' + val);
			//this.selectedFacilityList[this.selectedFacilityList.length] = val;
				//this.prepareDocumentListForTagInput();
				//this.prepareSystemListForTagInput();
				//this.prepareDataSheetListForTagInput();
				this.selectedFacility = val;
				this.loadingHandler.init(4, this.loadingService);

				// Clear dropdowns
				this.documentTagInputList = [];
				this.dataSheetTagInputList = [];
				this.systemTagInputList = [];

				// Clear selected items
				this.selectedDocumentList = [];
				this.selectedDataSheetList = []
				this.selectedSystemList = [];

				// Get systems, documents and datasheets relevant to the facility
				this.tagService.getSystemsByFacilityId(this.token, this.selectedFacility.facilityId, this);
				this.tagService.getDocumentsByFacilityId(this.token, this.selectedFacility.facilityId, this);
				this.tagService.getDataSheetsByFacilityId(this.token, this.selectedFacility.facilityId, this);
				
				//this.tagService.getDocuments(this.token, this.selectedFacility.facilityId, this);
				break;
			case 'system':
				//this.selectedSystemList[this.selectedSystemList.length] = val;
				break;
			case 'document':
				//this.selectedDocumentList[this.selectedDocumentList.length] = val;
				break;
			case 'classification':
                //this.selectedTagClassList[this.selectedTagClassList.length] = val;
                //this.tagAttributes = val.tagClassSpecModelList;
                //this.tagService.getTagClassificationListWithAttributes(this.token, val, this);
                this.tagService.getAttributesByClassification(this.token, val.classId, this);
                break;
			case 'data_sheet':
				console.log('adding data sheet :- ' + this.selectedDataSheetList);
				//this.selectedDataSheetList[this.selectedDataSheetList.length] = val;
				break;
			case 'location_parent':
				console.log('add location parent');
				break;
			case 'failure_class':
				console.log('add failure class');
				//this.selectedFailureClasses[0] = val;
				/*this.failureClassList.forEach(element => {
					if (element.id == val.id) {
						this.selectedFailureClasses[0] = element;
					}
				});*/
				break;
			case 'criticality':
				console.log('add criticality');
				break;
			case 'safety_criticality':
				console.log('add safety criticality');
				break;
			case 'discipline':
				console.log('add discipline');
				break;
			case 'equipment_type':
				console.log('add equipmentType');
				break;
			case 'asset_group':
				console.log('add asset group');
				this.loadingService.showLoading(true, null, "Adding asset group", null);
				this.tagService.getAssetGroupAttributes(this.token, val.assetGroupId, this);				
				//this.loadingService.showLoading(true, null, "Loading asset group attributes", null);
				//this.tagService.getAssetGroupParents(this.token, val.assetGroupId, this);
				//this.tagService.getAssetAttributes(this.token, val.assetGroupId, this);//id of the widget
				//this.loadingService.showLoading(true, null, "Loading asset attributes", null);
				//this.tagService.getValueListValues(this.token, val.assetGroupId, this);//id of the widget
				//this.loadingService.showLoading(true, null, "Loading value lists", null);
				break;
			case 'function_code':
				console.log('add function code');
				break;
			case 'vendor':
				console.log('add vendor');
				break;
			default:
				break;
		}
	}

	public removeSelectedItem(val: any, type: string) {
		console.log(val);
		switch (type) {
			case 'facility':
				console.log('remove facility selection ');
				
				// Clear selected items
				this.selectedFacility = undefined;
				this.selectedFacilityList = [];
				this.selectedSystemList = [];
				this.selectedDocumentList = [];
				this.selectedDataSheetList = [];

				// Change dcument/ datasheets/ system dropdown
				this.documentTagInputList = this.documentsWithoutFacilities;
				this.dataSheetTagInputList = this.datasheetsWithoutFacilities;
				
				/*** 
			 	* Display System Number and System Name in the drop down items
			 	* Trello Card - https://trello.com/c/F9e5rKLy
				*/
				//this.systemTagInputList = this.systemsWithoutFacilities;
				//Remove previous system list and bind the systems without facilities
				this.systemTagInputList = [];
				this.systemsWithoutFacilities.forEach(system => {
					this.systemTagInputList.push({ "id": system.id, "systemNoandsystemName": system.systemNo + ' ' + system.systemName });
				});
				break;
			case 'system':
				console.log('remove system selection ');
				this.selectedSystemList = [];
				break;
			case 'document':
				console.log('remove document selection ');
				this.selectedDocumentList = [];
				break;
			case 'classification':
				console.log('remove classification selection ');
				this.selectedTagClassList = [];
				this.tagAttributes = [];
				//this.selectedSystemOptions = [];
				//this.selectedDocumentOptions = [];
				//this.selectedComponentOptions = [];
				this.selectedAttributes = [];
				break;
			case 'data_sheet':
				console.log('remove data sheet selection ');
				this.selectedDataSheetList = [];
				break;
			case 'location_parent':
				console.log('remove location parent');
				//this.filteredLocationParent = [];
				this.createInitialParentList();
				break;
			case 'failure_class':
				console.log('remove failure class');
				break;
			case 'criticality':
				console.log('remove criticality');
				break;
			case 'safety_criticality':
				console.log('remove safety criticality');
				break;
			case 'discipline':
				console.log('remove discipline');
				break;
			case 'equipment_type':
				console.log('remove equipmentType');
			case 'asset_group':
				console.log('remove asset group');
				this.assetAttributeList = [];
				this.valueListValues = [];
				// this.assetGroupsList = [];
				this.selectedAssetGroupsList = [];
				this.selectedAssetAttributes = [];
				break;
			case 'asset_parent':
				console.log('remove asset parent');
				this.selectedParentList = [];
				break;
			case 'function_code':
				console.log('remove function code');
				break;
			case 'vendor':
				console.log('remove vendor');
			default:
				break;
		}
	}
	
	public getComponentListByCategory(categoryId: string){
		let compListBycategory = []
		if(categoryId != null && categoryId != ''){
			this.allComponents.forEach(component => {
				if(component.categoryId == categoryId){
					let node = { "id": component.id, "text": component.componentValue };
					compListBycategory.push(node)
				}
			})
		}
		else{
			//set all the components when the tag attribute type category is not selected
			compListBycategory = this.componentListForNgSelect
		}
		return compListBycategory

	}

	/**
	 * Load only 10 items initially to handle the memory issues
	 */
	private createInitialParentList() {
		this.filteredLocationParent = [];		
		for (const parent of this.allLocationParent) {
			this.filteredLocationParent[this.filteredLocationParent.length] = parent;
			if (this.filteredLocationParent.length == 10) {
				break;
			}
		}		
	}

	/**
	 * This method will be called when user enter text. Then suggestion items are filtered based on user inputs.
	 * @param val text entered
	 */
	public filterItems(val: any) {		
		if (val == '' || val == undefined) {
			this.createInitialParentList();
			return;
		}
		this.filteredLocationParent = [];
		if (val != '') {		
			this.enableMore = false;			
			this.tagService.getAllTagSuggestionModelList(this.token, null, val, this);
		}	
	}

	/**
	 * This class handles parallel calling of web services. loadingService initiate the loading service which is required to show and hide functionalities.
	 * Then, parallelServiceCount indicate the number of parallel services that we want to call. init() method is used to initialize the handler object.
	 * handle() method will be called on onSuccess() and onFail() methods of required service calls. This method will hide the loading view when the counter
	 * becomes zero which means no more service response to be arrived.
	 */
	static LoadingHandler = class {
		parallelServiceCount: number = 0;
		loadingService: LoadingService = null;
		init(parallelServiceCount: number, loadingService: LoadingService) {
			this.parallelServiceCount = parallelServiceCount
			this.loadingService = loadingService;
			this.loadingService.showLoading(true, false, 'Loading data', 0);
		}

		handle() {
			--this.parallelServiceCount;
			//console.log('service count = ' + this.parallelServiceCount);
			if (this.parallelServiceCount == 0) {
				this.loadingService.hideLoading();
			}
		}
	}

	/**
	 * Remove selected items from the whole list.
	 */
	private setSuggestionList() {
		// this method should not be executed if the added suggestion list is empty.
		/*if (this.addedSuggestionList.length == 0) {
			return;
		}*/
		this.suggestionList = [];		
		this.suggestionListMore = [];
		var includeCurrent = false;
			for (let index = 0; index < this.addedSuggestionList.length; index++) {	
				if (this.addedSuggestionList[index] != '') {
					if (this.tagId === this.addedSuggestionList[index][0].tagId) {
						includeCurrent = true;
					}
				}			
			}	
		var regEx = new RegExp(this.searchText, "gi");
		if ((this.tagNumber != undefined && this.tagNumber != "") && (this.alternateTagNoValue != undefined && this.alternateTagNoValue != "")) {			
			if ((this.tagNumber.search(regEx) != -1) && (!includeCurrent)) {
				this.suggestionList.push({ "tagId": this.tagId, "alternateTagNo": this.alternateTagNoValue, "tagNo": this.tagNumber, "altNoAndTagNo": this.alternateTagNoValue + ' ' + '['+ this.tagNumber + ']'});
				this.suggestionListMore.push({ "tagId": this.tagId, "alternateTagNo": this.alternateTagNoValue, "tagNo": this.tagNumber, "description": this.tagDescription, "altNoAndTagNo": this.alternateTagNoValue + ' ' + '['+ this.tagNumber + ']'});
			}
		}
		this.suggestionOriginalList.forEach(element => {
			var include = false;
			for (let index = 0; index < this.addedSuggestionList.length; index++) {	
				if (this.addedSuggestionList[index] != '') {
					if (element.tagId === this.addedSuggestionList[index][0].tagId) {
						include = true;
					}
				}			
			}				
			// Add only previously not added items.
			if (!include) {							
				this.suggestionList.push({ "tagId": element.tagId, "alternateTagNo": element.alternateTagNo, "tagNo": element.tagNo, "description": element.description, "altNoAndTagNo": element.alternateTagNo + ' ' + '['+ element.tagNo + ']'});				
				this.suggestionListMore.push({ "tagId": element.tagId, "alternateTagNo": element.alternateTagNo, "tagNo": element.tagNo, "description": element.description, "altNoAndTagNo": element.alternateTagNo + ' ' + '['+ element.tagNo + ']'});
			}	
		});		
		this.enableMoreFunctional = true;		
	}

	/**
	 * Fires when user type on the text input.
	 * @param searchText typed text
	 */
	public onTextChange(searchText: any, index: number) {
		this.searchText = searchText;
		if (searchText != '') {
			this.selectedIndex = index;
			this.suggestionList = [];	
			if (searchText.includes("#")) {
				searchText = searchText.replace("#","%23");
			}		
			this.tagService.searchAlternateTag(this.token, searchText, '', 10, 0, this);
			this.enableMoreFunctional = false;
		}
	}

	/**
	 * Fires when user selects an item from the suggestion list.
	 * @param item selected item.
	 */
	public onSuggestionSelected(item: any, index: number) {
		/*this.addedSuggestionList[index] = item;
		this.setSuggestionList();
		this.enableDisableItems[index + 1] = false;
		this.prepareFunctionalLocationValue(item.alternateTagNo, index);*/	
		this.enableMoreFunctional = false;	
		var array = new Array(1);
		item.display = item.display.substring(0, item.display.lastIndexOf('['));
		array[0] = item;
		this.addedSuggestionList[index] = array;
		this.enableDisableItems[index + 1] = false;
		this.enable[index + 1] = false;
		this.enable[index] = true;
		this.prepareFunctionalLocationValue(item.alternateTagNo, index);
	}

	/**
	 * Fires when user remove an item from the selection.
	 * @param item deleted item
	 */
	public onSuggestionRemoved(item: any, index: number) {
		this.enableMoreFunctional = false;
		for (let i = index; i < this.addedSuggestionList.length; i++) {
			this.addedSuggestionList[i] = new Array(0);
		}
		//changing enable disable status except the current one.
		for (let i = index + 1; i < this.enableDisableItems.length; i++) {
			this.enableDisableItems[i] = true;
			this.enable[i] = true;
			this.enable[index] = false;
		}				
		this.prepareFunctionalLocationValue(item.alternateTagNo, index);
	}

	/**
	 * Prepare the functional location value
	 * @param item added or removed item
	 * @param index added or removed index
	 */
	private prepareFunctionalLocationValue(tagValue: string, index: number) {
		/*if (index != 0) {
			this.calculatedFunctionalLocationValue += '-';
		}
		this.calculatedFunctionalLocationValue += tagValue.substring(0,
			this.tagAddPreConditions.cmmsMaskSpecificationInfo[index].charactersPerElement);*/
		this.calculatedFunctionalLocationValue = '';
		for (let index = 0; index < this.addedSuggestionList.length; index++) {
			let tagNo ='';
			if (this.addedSuggestionList[index] != '') {
				tagNo = this.addedSuggestionList[index][0].alternateTagNo;
			
			if (index != 0 && tagNo != '') {
				this.calculatedFunctionalLocationValue += '-';
			}
			tagNo = tagNo.trim();
			let charactersInTag = tagNo.length;
			if (this.tagAddPreConditions.cmmsMaskSpecificationInfo[index].charactersPerElement > charactersInTag) {
				let difference = this.tagAddPreConditions.cmmsMaskSpecificationInfo[index].charactersPerElement - charactersInTag;
				let addZero ='';
				for (let i = 0; i < difference; i++) {
					addZero = addZero + 0;
				}
				tagNo = addZero + tagNo;
			}
			this.calculatedFunctionalLocationValue += tagNo.substring(0, this.tagAddPreConditions.cmmsMaskSpecificationInfo[index].charactersPerElement);
		}
		}
		console.log('value - ' + this.calculatedFunctionalLocationValue);
	}

	/**
	 * Fires when user clicks on the equipment checkbox.
	 * @param event event
	 */
	public onCheckBoxCheck(event: any) {
		if (event.srcElement.checked) {
			this.tagService.getNextAvailableEquipmentNo(this.token, this);
			this.isEquipmentNoCheckboxSelected = true;
		} else {
			this.nextAvailableEquipmentNo = '';
			this.isEquipmentNoCheckboxSelected = false;
		}
	}

	/**
	 * Get selected tag id list.
	 */
	private getSelectedTagIds(): string {
		console.log('[(getSelectedTagIds)]');
		var res = '';
		if (this.addedSuggestionList != undefined) {
			for (let index = 0; index < this.addedSuggestionList.length; index++) {
				if (this.addedSuggestionList[index] == undefined) {
					res += ',';
				} else {
					res +=  this.addedSuggestionList[index][0].tagId + ',';
				}
			}
		}
		return res;
	}

	/**
	 * Get selected alternate tag no list.
	 */
	private getSelectedAlternateTagNo(): string {
		console.log('[(getSelectedAlternateTagNo)]');
		var res = '';
		if (this.addedSuggestionList != undefined) {
			for (let index = 0; index < this.addedSuggestionList.length; index++) {
				if (this.addedSuggestionList[index] == undefined) {
					res += ',';
				} else {
					res +=  this.addedSuggestionList[index][0].alternateTagNo + ',';
				}
			}
		}
		return res;
	}

	onRowSelected(event) {	
		//If we implement in this, two times this method is getting called. That is why I have commented.
		// if (event.data != null) {
		// 	event.data.description = "<tagdesc><txt>" + event.data.description + "</txt></tagdesc>";
		// 	this.selectedLocationParentRaw = event.data;
		// }	
	}
	onRowClick(event) {
		this.selectedLocationParentRaw = [];
		if (event.data != null) {
			event.data.description = "<tagdesc><txt>" + event.data.description + "</txt></tagdesc>";
			this.selectedLocationParentRaw = event.data;
		}	
	}

	public okMoreLocationParent() {
		if ((this.selectedLocationParent != null) && (this.selectedLocationParentRaw.length != 0)) {
			this.selectedLocationParent = [];
		}	
		if (this.selectedLocationParentRaw.length != 0) {
			this.selectedLocationParent.push(this.selectedLocationParentRaw);
		}		
		this.bsModalRefLocationParent.hide();
	}

	/**
	 * Values that should be reset after successful tag creations.
	 */
	private reinitializeVariable() {
		this.assetParentList = []; //reset the asset parent dropdown after adding a tag
		if(this.isEquipmentNoCheckboxSelected) //This condition is added to support Remember Data functionality
		this.tagService.getNextAvailableEquipmentNo(this.token, this);
	}

	public altTagchange() {
		this.alternateTagNoValue = this.tagNumber;
		this.altTagNoChange();
	}

	public onRemoving(tag): Observable<any> {
        const confirm = window.confirm('Deleting this element will remove all the subsequent elements of the functional location. Are you sure?');
		return of(tag).pipe(filter(() => confirm));
	}
	
	/**
	 * Set more values in Functional Location dropdown box
	 * @param val
	 */
	public setMoreFunctionalLocation(template: TemplateRef<any>) {	
		this.rowDataFunctionalLocation = [];
		this.selectedFunctionalLocationRaw = [];
		for (let i = 0; i < this.suggestionListMore.length; i++) {			
			this.suggestionListMore[i]["description"] = this.suggestionListMore[i]["description"].replace("<tagdesc><txt>", "")
			this.suggestionListMore[i]["description"] = this.suggestionListMore[i]["description"].replace("</txt></tagdesc>", "")
				this.rowDataFunctionalLocation.push({
					altNoAndTagNo: this.suggestionListMore[i]["altNoAndTagNo"],
					alternateTagNo: this.suggestionListMore[i]["alternateTagNo"],	
					description: this.suggestionListMore[i]["description"],
					display: this.suggestionListMore[i]["alternateTagNo"],
					tagId: this.suggestionListMore[i]["tagId"],									
					tagNo: this.suggestionListMore[i]["tagNo"]				
				})

		}
		this.bsModalRefFunctionalLocation = this.bsModalService.show(template, { class: 'modal-lg', backdrop: 'static', keyboard: false });
	}

	public fucnctionalLocationCancel() {
		this.bsModalRefFunctionalLocation.hide();		
	}

	onRowClickFunctional(event) {
		this.selectedFunctionalLocationRaw = [];
		if (event.data != null) {
			event.data.description = "<tagdesc><txt>" + event.data.description + "</txt></tagdesc>";
			this.selectedFunctionalLocationRaw = event.data;
		}	
	}

	public okMoreFunctionLocation() {					
		if ((this.addedSuggestionList[this.selectedIndex] != null) && (this.selectedFunctionalLocationRaw.length != 0)) {
			this.addedSuggestionList[this.selectedIndex] = null;
		}	
		if (this.selectedFunctionalLocationRaw.length != 0) {
			this.enableMoreFunctional = false;	
			var array = new Array(1);
			array[0] = this.selectedFunctionalLocationRaw;
			this.addedSuggestionList[this.selectedIndex] = array;
			this.enableDisableItems[this.selectedIndex + 1] = false;		
			this.enable[this.selectedIndex + 1] = false;
			this.enable[this.selectedIndex] = true;
			this.prepareFunctionalLocationValue(this.addedSuggestionList[this.selectedIndex].alternateTagNo, this.selectedIndex);
			this.bsModalRefFunctionalLocation.hide();	
		}		
		else {
			this.bsModalRefFunctionalLocation.hide();
		}		
	}

	public createUUID(){
		var dt = new Date().getTime();
		var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
			var r = (dt + Math.random()*16)%16 | 0;
			dt = Math.floor(dt/16);
			return (c=='x' ? r :(r&0x3|0x8)).toString(16);
		});
		return uuid;
	}	

	public altTagNoChange() {
		for (let index = 0; index < this.addedSuggestionList.length; index++) {	
			if (this.addedSuggestionList[index].length != 0) {
				if (this.tagId === this.addedSuggestionList[index][0].tagId) {
					this.addedSuggestionList[index][0].alternateTagNo = this.alternateTagNoValue;
					this.addedSuggestionList[index][0].display = this.alternateTagNoValue;
					this.prepareFunctionalLocationValue(this.alternateTagNo, index);
				}
			}			
		}		
	}

	checkIfNumber(param:any) {
		if ((param.keyCode >= 48 && param.keyCode <= 57)) { 
			// 0-9 only
		} else {
			//this.numberAttr = true;			
			this.alertService.error("The value should be numeric");
			return false;
		}
	}
}
