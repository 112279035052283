import { Component, OnInit, ViewChild, ElementRef, AfterContentInit, OnDestroy } from '@angular/core';
import { GridOptions,ColDef } from "@ag-grid-enterprise/all-modules";
import { LoadingService } from '../../util/loading/loading.service';
import { SharedService } from '../../util/shared.service';
import { AlertService } from '../../util/alert/alert.service';
import { DocumentsService } from './documents.service';
import { DOCUMENT_UI_VIEW, SessionStorageConst } from '../../util/constants';
import { HistoryService } from '../history/history.service';
import { Constants, UI_LAYOUT, LocalStorageConst } from '../../util/constants';
import { DocumentsCellRenderComponent } from './documents-cell-render-component.component';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { WsCallback } from '../../util/ws-callback.interface';
import { WsType } from '../../util/ws-type';
import { WsResponse } from '../../util/ws-response.model';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AddeditdocumentsComponent } from './addeditdocuments/addeditdocuments.component';
import { DependenciesModalService } from './../../util/dependenciesmodal/dependenciesmodal.service';
import { Subscription } from 'rxjs';
import * as FileSaver from 'file-saver';
import { CapabilityService } from '../../util/capability.service';


@Component({
    selector: 'app-documents',
    templateUrl: './documents.component.html',
    styleUrls: ['./documents.component.css']
})

export class DocumentsComponent implements OnInit, AfterContentInit, OnDestroy {

    public expandComponents: boolean = false;
    private filteredData: Array<any> = [];
    public gridOptions: GridOptions;
    public expandedView: string;
    public fuv: any = DOCUMENT_UI_VIEW;
    private count = 0;
    private reusableType: string = "";
    private resuableText: string = "";
    private reusableXML: string = null;
    private reusableView: string = null;
    private gridApi;
    public getRowNodeId;
    private gridColumnApi;
    changeLayout: boolean = false
    public columnDefs: any;
    public overlayLoadingTemplate: any;
    public overlayNoRowsTemplate: any;
    rowData: any = [];

    private facilityName: string = "";
    private documentName: string = "";
    private documentType: string = "";
    private revision: string = "";
    private status: string = "";
    private edit: string = "";
    private delete: string = "";
    private hasText: string = "";
    private altdocno: string = "";
    private filename: string = "";

    // Capabilities
	public modifyCap: boolean = false;
	public queryCap: boolean = false;
	public deleteCap: boolean = false;
	public disableCap: boolean = false;
	public createCap: boolean = false;

    //enable disable button/icon
	public enableAddDocument: boolean = false;
	public enableSearchDocument: boolean = false;
    public enableChangeDocumentStatus: boolean = false;
    public enableEditDocument: boolean = false;
	public enableDeleteDocument: boolean = false;

    public bsModalRef: BsModalRef
    private subsVar: Subscription


    constructor(private documentsService:
        DocumentsService,
        private alertService: AlertService,
        private loadingService: LoadingService,
        private sharedService: SharedService,
        private translate: TranslateService,
        private historyService: HistoryService,
        private router: Router,
		private modalService: BsModalService,
        private dependenciesmodalService: DependenciesModalService,
        private capabilityService: CapabilityService
    ) {

        // this.translate.get('DOCUMENT_SEARCH_VIEW.DOCUMENT_NAME').subscribe((res: string) => {
        //     this.documentName = res;
        //     this.translate.get('DOCUMENT_SEARCH_VIEW.DOCUMENT_TYPE').subscribe((res: string) => {
        //         this.documentType = res;
        //         this.translate.get('DOCUMENT_SEARCH_VIEW.FACILITY').subscribe((res: string) => {
        //             this.facilityName = res;
        //             this.translate.get('DOCUMENT_SEARCH_VIEW.REVISION').subscribe((res: string) => {
        //                 this.revision = res;
        //                 this.translate.get('DOCUMENT_SEARCH_VIEW.STATUS').subscribe((res: string) => {
        //                     this.status = res;
        //                     this.translate.get('DOCUMENT_SEARCH_VIEW.EDIT').subscribe((res: string) => {
        //                         this.edit = res;
        //                         this.translate.get('DOCUMENT_SEARCH_VIEW.DELETE').subscribe((res: string) => {
        //                             this.delete = res;
        //                         });
        //                     });
        //                 });
        //             });
        //         });
        //     });
        // });

        //check the user capability and enable/disable buttons
        this.enableDisableButtons();

        this.columnDefs = [
            {
                headerName: 'Dependencies',
                field: "dependencies",
                width: 40,
                cellStyle: { 'text-align': 'center' },
                cellRendererFramework: DocumentsCellRenderComponent
            },
            {
                headerName: 'Type', field: 'type', checkboxSelection: false, hide: true,
                suppressColumnsToolPanel: true
            },
            // {
            //     headerName: 'Document Id', field: 'documentId', checkboxSelection: false, hide: true,
            //     suppressColumnsToolPanel: true
            // },
            {
                headerName: 'Document Name', field: 'documentName', checkboxSelection: false, hide: false,
                suppressColumnsToolPanel: false, sort: 'asc'
            },
            {
                headerName: 'Alternate Doc No', field: 'altDocNo', checkboxSelection: false, hide: false,
                suppressColumnsToolPanel: false
            },
            {
                headerName: 'Facility', field: 'facilityName', checkboxSelection: false, hide: false,
                suppressColumnsToolPanel: false
            },
            {
                headerName: 'Document Type', field: 'documentType', checkboxSelection: false, hide: false,
                suppressColumnsToolPanel: false
            },
            {
                headerName: 'Revision', field: 'revision', checkboxSelection: false, hide: false,
                suppressColumnsToolPanel: false
            },
            {
                headerName: 'PDF File', field: 'contentItemId', checkboxSelection: false, hide: false,
                suppressColumnsToolPanel: false,
                cellRenderer: function (params: any) {
                  var eDiv = document.createElement('div');
                  if (params.data.contentItemId != "") {
                     eDiv.innerHTML = '<button class="btn btn-sm btn-outline-dark py-0 my-1 px-0 border-0">' +
								'<i class="far fa-file-pdf mx-2" aria-hidden="true"></i>' +
								'</button>';
                                var eButton = eDiv.querySelectorAll('.btn-sm')[0];
                                eButton.addEventListener('click', function () {
                                loadingService.showLoading(true, null, "Downloading", null)
                                documentsService.downloadFile(params.data.projectId, params.data.contentItemId)
                                .subscribe(response => {
                                  console.log(params.data)
                                 let file = new Blob([response], { type: 'application/pdf' });
                                 FileSaver.saveAs(file, params.data.pdfFile);
                                 loadingService.hideLoading()
                              },
                                 error => {
                                    loadingService.hideLoading()
                                    alertService.error(this.fa_translated_data.ERR_DOWNLOAD_FILE)
                                 });
                        
                     });
                  }else{
                     eDiv.innerHTML = '';
                  }
                  return eDiv;
               }
            },
            // {
            //     headerName: 'Last Update Timestamp', field: 'lastUpdateTimeStamp', checkboxSelection: false, hide: true,
            //     suppressColumnsToolPanel: true
            // },
            {
                headerName: 'Status',
                field: "status",
                width: 85,
                hide:!this.enableChangeDocumentStatus,
                cellStyle: { 'text-align': 'center' },
                cellRendererFramework: DocumentsCellRenderComponent
            },
            {
                headerName: 'Edit',
                field: "edit",
                width: 85,
                hide:!this.enableEditDocument,
                cellStyle: { 'text-align': 'center' },
                cellRendererFramework: DocumentsCellRenderComponent
            },
            {
                headerName: 'Delete',
                field: "delete",
                width: 85,
                hide:!this.enableDeleteDocument,
                cellStyle: { 'text-align': 'center' },
                cellRendererFramework: DocumentsCellRenderComponent
            }
        ];

        this.gridOptions = {
            rowData: this.rowData,
            columnDefs: this.columnDefs,
            defaultColDef: {
                resizable: true,
                sortable: true
            },
            rowSelection: "single",
            rowMultiSelectWithClick: false,
            isExternalFilterPresent: this.externalFilterPresent,
            doesExternalFilterPass: this.externalFilterPass,

        };
        //custom layout templates for loading and empty data sets
        this.overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Loading data...</span>';
        this.overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">No rows to show</span>';

        this.getRowNodeId = function (data) {
            return data.id;
        };

    }


    ngOnInit() {
        this.expandedView = DOCUMENT_UI_VIEW.EXPANDED_VIEW;
        if (this.documentsService.getRowData() != null) {
            this.rowData = this.documentsService.getRowData();
        } else {
            // do nothing
            // console.log('[ngOnInit] (else)');

        }
        let ui = JSON.parse(sessionStorage.getItem(SessionStorageConst.UI_LAYOUT));
        if (ui !== UI_LAYOUT.UIL_6) {
            this.columnDefs = [
                //{
                    //headerName: 'Dependencies',
                    //field: "dependencies",
                   // width: 40,
                    //cellStyle: { 'text-align': 'center' },
                    //cellRendererFramework: DocumentsCellRenderComponent
               // },
                //{
                    //headerName: 'Dependencies',
                    //field: "dependencies",
                    //width: 100,
                    //cellStyle: { 'text-align': 'center' },
                    //cellRendererFramework: DocumentsCellRenderComponent
                //},
                {
                    headerName: 'Type',
                    field: 'type',
                    checkboxSelection: false,
                    hide: true,
                    suppressColumnsToolPanel: true
                },
                // {
                //     headerName: 'Document Id',
                //     field: 'documentId',
                //     checkboxSelection: false,
                //     hide: true,
                //     suppressColumnsToolPanel: true
                // },
                {
                    headerName: 'Document Name', field: 'documentName', checkboxSelection: false, hide: false, width: 300,
                    suppressColumnsToolPanel: false, sort: 'asc'
                },
                // {
                //     headerName: 'Facility', field: 'facilityName', checkboxSelection: false, hide: false, width: 200,
                //     suppressColumnsToolPanel: false
                // }
                // },
                // {
                //     headerName: 'Last Update Timestamp',
                //     field: 'lastUpdateTimeStamp',
                //     checkboxSelection: false,
                //     hide: true,
                //     suppressColumnsToolPanel: true
                // }
            ];
        } else {
            //do nothing
        }
        if (ui == UI_LAYOUT.UIL_2 || ui == UI_LAYOUT.UIL_4) {
            this.expandedView = DOCUMENT_UI_VIEW.SMALL_VIEW;
            this.changeLayout = false
        } else {
            this.changeLayout = true
        }
    }

    ngAfterViewInit(): void {
        //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
        //Add 'implements AfterViewInit' to the class.
        this.subsVar = this.sharedService.expandReusablesExcute().subscribe((option: any) => this.expandReusables(option))
        this.subsVar = this.sharedService.expandReusablesExcute().subscribe((option: any) => this.changeComponent(option))
        this.subsVar = this.sharedService.openDocumentsEditRespond().subscribe(
            (option: any) => this.openAddEditDocumentModel(option)
        )
    }

    ngAfterContentInit() {
        //Called after ngOnInit when the component's or directive's content has been initialized.
        //Add 'implements AfterContentInit' to the class.
        this.subsVar = this.sharedService.docuSaveEventResponse().subscribe(() =>this.docuSaveClickButtonEvent())
    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        if (this.subsVar) {
            this.subsVar.unsubscribe()
        }

        window.removeEventListener("resize", this.onWindowResize);
		this.gridApi.destroy();
    	this.gridApi = null;
    }

    changeComponent(option: any) {
        // console.log('[changeComponent] (option) ' + JSON.stringify(option));
    }

    docuSaveClickButtonEvent(){
        console.log('[documentSaveBtnClickEvent]')
        this.documentsService.searchDocuments(this.documentName, this.documentType, this.facilityName, this.revision, this.hasText, "", "").subscribe(
            data => {
                this.setDocumentSearchTableData(data.payload)
            },
            error => {
                this.alertService.clear()
                this.alertService.error(error.statusDescription)
            });
    }

    onGridReady(params) {
        const allColumnIds = [];
        this.columnDefs.forEach((columnDef) => {
            allColumnIds.push(<ColDef>columnDef);
        });
        this.gridColumnApi = params.columnApi;
        this.gridApi = params.api;
        //gridOptions.api.hideOverlay();
        this.documentsService.setApiGridData(this.gridApi);
        params.api.sizeColumnsToFit();
        // window.addEventListener("resize", function () {
        //     setTimeout(function () {
        //         params.api.sizeColumnsToFit();
        //     });
        // });
        window.addEventListener("resize", this.onWindowResize);
    }

    private onWindowResize = () => {
   
		setTimeout(() => {
			if (this.gridApi) {
				this.gridApi.sizeColumnsToFit();
			}
		}); 
	};
    /**
     * previoes search
     */
    // search(event: any) {
    //     this.documentName = "";
    //     this.facilityName = "";
    //     this.count = 0;
    //     let docArray = event.target.value.split(",");
    //     for (var l = 0; l <= docArray.length - 1; l++) {
    //         let letter = docArray[l].split(":");
    //         if (letter != "") {
    //             for (var i = 0; i < letter.length - 1; i++) {
    //                 if (letter[i] == "doc") {
    //                     this.documentName = letter[i + 1];
    //                 }
    //                 else if (letter[i] == "fac") {
    //                     this.facilityName = letter[i + 1];
    //                 } else {
    //                     if (this.count == 0) {
    //                         this.count = 1;
    //                         this.error();
    //                     } else {
    //                         // do nothing
    //                     }
    //                     this.count = 1;
    //                 }
    //             }
    //         }
    //     }
    //     if (this.documentName != "" || this.facilityName != "") {
    //         let to: any = false;
    //         if (to) { clearTimeout(to); }
    //         to = setTimeout(() => {
    //             this.gridOptions.api.onFilterChanged();
    //             this.documentsService.searchDocuments(this.documentName, this.documentType, this.facilityName, this.revision).subscribe(
    //                 data => {
    //                     this.setDocumentSearchTableData(data.payload)
    //                 },
    //                 error => {
    //                     this.alertService.clear()
    //                     this.alertService.error(error.statusDescription)
    //                 });;
    //         }, 200);
    //     }
    // }

    error() {
        this.alertService.error("wrong value inserted");
	}
	
	


    cellClicked(event) {
        //console.log('row' + JSON.stringify(event.data));
        let status = event.data.status.split(":")[2];
        //console.log("Field :"+event.colDef.field)
        //console.log("STATUS___________________" +status);
		if (status != null) {
			if (status == "Active" || status == "active") {

                if(event.colDef.field=="documentName"){
                    event.data.type="Document";
                    let tabType = SharedService.getTabComponentType();
                    if (tabType == 'Editor') {
                        SharedService.insertEditorRequest(event.data);
                     } else if (tabType == 'Procedures') {
                         SharedService.insertProceduresRequest(event.data);
                     } else if (tabType == 'IceCourse') {
                         SharedService.insertICECourseRequest(event.data);
                     }

                     this.reusableView = event.data.documentName;
                     this.reusableXML = SharedService.getHtmlString();
                     this.save(this.reusableXML, this.reusableView);
                     console.log("to save");
                     
                }else if(event.colDef.field=="facilityName"){
                    event.data.type="documentFacility";
                    let tabType = SharedService.getTabComponentType();
                    if (tabType == 'Editor') {
                        SharedService.insertEditorRequest(event.data);
                     } else if (tabType == 'Procedures') {
                         SharedService.insertProceduresRequest(event.data);
                     } else if (tabType == 'IceCourse') {
                         SharedService.insertICECourseRequest(event.data);
                     }
                     
                     this.reusableView = event.data.facilityName;
                     this.reusableXML = SharedService.getHtmlString();
                     this.save(this.reusableXML, this.reusableView);
                     console.log("to save");
                }
                
                //event.data.type = "Document";
                //console.log("tabType : "+tabType)
                
			} else {
				this.translate.get('REUSABLES.COULD_NOT_ENTER_DISABLED_REUSABLES').subscribe((res: string) => {
                    this.alertService.error(res, false);
                });
			}
		}
    }

    //save inserted value to the history table
    save(htmalString: any, reusableView: any) {
        if (this.reusableXML != null) {           
            let to: any = false;
            let xml = this.reusableXML.replace(/insid=.{38}/gi, function insId() {
                function s4() {
                    return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
                }
                return 'insId="' + s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4() + '"';
            });
            if (to) { clearTimeout(to); }
            to = setTimeout(() => {
                this.reusableType = "Document Name";
                this.resuableText = "doc";
                this.historyService.saveToHistory(this.reusableType, this.resuableText, xml, this.reusableView).subscribe(
                    data => {
                        // console.log("success");
                    },
                    error => {
                        this.alertService.clear()
                        this.alertService.error(error.statusDescription)
                    });
            }, 200);
        }
    }

    externalFilterPresent() {
        return this.documentName != "";
    }

    externalFilterPass(node: any) {
        return true;
    }

    setDocumentSearchTableData(payload: any) {
        this.loadingService.hideLoading();
        // console.log(payload);
        this.filteredData = [];
        this.rowData = [];
        if (payload != null) {
			this.dependenciesmodalService.setCallback(this);
            if (this.expandComponents == false) {
                for (let i = 0; i < payload.length; i++) {
                    this.filteredData.push({
                        id: payload[i]["id"],
                        dependencies:  "disable_dependencies:" + payload[i]["id"] ,
                        documentName: payload[i]["documentName"],
                        documentType: payload[i]["documentType"],
                        facilityId: payload[i]["facilityId"],
                        facilityName: payload[i]["facilityName"],
                        altDocNo: payload[i]["altDocNo"],
                        revision: payload[i]["revision"],
                        pdfFile: payload[i]["attachedFileName"],
                        contentItemId: payload[i]['contentItemId']!= null ? payload[i]["contentItemId"] : "",
                        projectId: payload[i]['projectId']!= null ? payload[i]["projectId"] : "",
                        lastUpdateTimeStamp: payload[i]["lastUpdateTimeStamp"],
                        facilityLastUpdateTimeStamp: payload[i]["facilityLastUpdateTimeStamp"],
                        status: "status:" + payload[i]["id"] + ":" + payload[i]["status"],
                        edit: "edit:" + payload[i]["id"] + ":" + payload[i]["status"] + ":" + payload[i]["facilityId"],
                        delete: "delete:" + payload[i]["id"] + ":" + payload[i]["status"],
                        documentStatus: payload[i]["status"],
					});
					
					this.dependenciesmodalService.getDependeciesWithId( payload[i]["id"], "Document");
				}	
            }
            this.rowData = this.filteredData;
            //this.gridOptions.api.sizeColumnsToFit();
            this.gridApi.paginationProxy.currentPage = 0;
            this.documentsService.setRowData(this.rowData);
        } else {
            // no row data to show
            this.documentsService.setRowData(this.rowData);
        }
	}
	
	onSuccess(data: WsResponse, serviceType: WsType): void {
		// console.log(data.payload)
		console.log("data.payload "  + serviceType)	
		if (data.payload != null && serviceType == WsType.ITEM_DEPENDENCY_LIST) {
		   let items = data.payload.split("_")
		   if(items[1]=="Yes")
		   {
				for(let i=0; i<this.rowData.length; i++)
				{
					if(this.rowData[i].id == items[0])
					{
						//var rowNode = this.gridApi.getRowNode(items[0]);
						//rowNode.setDataValue("dependencies", "dependencies:" + items[0])
                        this.rowData[i].dependencies = "dependencies:" + items[0];
                        if(!this.gridApi.destroyCalled) {
                            this.gridApi.setRowData(this.rowData);
                        }
					}
				}
		   }
        }else if (serviceType == WsType.DELETE_ITEM) {
         this.alertService.clear()
         this.alertService.success(data.statusDescription)
        }
	}
	
   onFail(res: WsResponse, serviceType: WsType): void {
      this.alertService.clear()
      if (serviceType == WsType.DELETE_ITEM) {
         this.alertService.error(res.statusDescription, false);
      }
   }

    /**
     * 
     * previous search
     */
    // searchDocumentByFacilityName(event: any) {
    //     let to: any = false;
    //     if (to) { clearTimeout(to); }
    //     to = setTimeout(() => {
    //         this.facilityName = event.target.value;
    //         this.gridOptions.api.onFilterChanged();
    //         this.documentsService.searchDocuments(this.documentName, this.documentType, this.facilityName, this.revision).subscribe(
    //             data => {
    //                 this.setDocumentSearchTableData(data.payload)
    //             },
    //             error => {
    //                 this.alertService.clear()
    //                 this.alertService.error(error.statusDescription)
    //             });;
    //     }, 200);
    // }
    // searchDocumentByDocumentName(event: any) {
    //     let to: any = false;
    //     if (to) { clearTimeout(to); }
    //     to = setTimeout(() => {
    //         this.documentName = event.target.value;
    //         this.gridOptions.api.onFilterChanged();
    //         this.documentsService.searchDocuments(this.documentName, this.documentType, this.facilityName, this.revision).subscribe(
    //             data => {
    //                 this.setDocumentSearchTableData(data.payload)
    //             },
    //             error => {
    //                 this.alertService.clear()
    //                 this.alertService.error(error.statusDescription)
    //             });;
    //     }, 200);
    // }

    searchDocumentByDocumentType(event: any) {
        let to: any = false;
        if (to) { clearTimeout(to); }
        to = setTimeout(() => {
            this.documentType = event.target.value;
            // this.gridOptions.api.onFilterChanged();
            this.documentsService.searchDocuments(this.documentName, this.documentType, this.facilityName, this.revision, "", "", "")
                .subscribe(
                    data => {
                        this.setDocumentSearchTableData(data.payload)
                    },
                    error => {
                        this.alertService.clear()
                        this.alertService.error(error.statusDescription)
                    });;
        }, 200);
    }

    searchDocumentByRevision(event: any) {
        let to: any = false;
        if (to) { clearTimeout(to); }
        to = setTimeout(() => {
            this.revision = event.target.value;
            // this.gridOptions.api.onFilterChanged();
            this.documentsService.searchDocuments(this.documentName, this.documentType, this.facilityName, this.revision, "", "", "")
                .subscribe(
                    data => {
                        this.setDocumentSearchTableData(data.payload)
                    },
                    error => {
                        this.alertService.clear()
                        this.alertService.error(error.statusDescription)
                    });;
        }, 200);
    }

    add() {
        //this.sharedService.setFileId("-1");
        // this.router.navigateByUrl('addeditdocuments');
        this.bsModalRef = this.modalService.show(AddeditdocumentsComponent, { backdrop: 'static', keyboard : false })
    }
    add1(){
        console.log("Addd 1")
    }
    /**
     * open add/edit document modal 
     * to solve data mission issue when save or cancel
     */
    private openAddEditDocumentModel(option: any) {
        console.log('[openAddEditDocumentModel] (option) ' + JSON.stringify(option));
        this.bsModalRef = this.modalService.show(AddeditdocumentsComponent,{
            initialState: {
                itemId: option.itemId
            }, backdrop: 'static', keyboard : false
        })
        // this.bsModalRef.content.itemId = option.itemId
    }

    private expandReusables(option: any) {
    }

    searchFunc(data: any) {
        console.log('[searchFunc] (data) ' + JSON.stringify(data));
        this.alertService.clear();
        this.documentName = "";
        this.documentType = "";
        this.facilityName = "";
        this.altdocno = "";
        this.revision = "";
        this.filename = "";
        this.hasText = "";
        this.count = 0;
        this.gridApi.showLoadingOverlay();

        //assign value for the fields from searchWordMap
        var docname = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_DOCUMENT_DOC_NAME];
        if (docname != undefined) {
            this.documentName = docname;
        } else {
            //no sysname
        }

        var doctype = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_DOCUMENT_DOC_DOCTYPE];
        if (doctype != undefined) {
            this.documentType = doctype;
        } else {
            //no documentType
        }

        var docfac = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_DOCUMENT_DOC_FACILITY];
        if (docfac != undefined) {
            this.facilityName = docfac;
        } else {
            //no facilityName
        }

        var altdocno = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_DOCUMENT_DOC_ALT_DOC_NO];
        if (altdocno != undefined) {
            this.altdocno = altdocno;
        } else {
            //no altdocno
        }

        var revision = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_DOCUMENT_DOC_REVISION];
        if (revision != undefined) {
            this.revision = revision;
        } else {
            //no revision
        }

        var filename = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_DOCUMENT_DOC_FILENAME];
        if (filename != undefined) {
            this.filename = filename;
        } else {
            //no filename
        }

        var hasText = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_HAS_THE_WORDS];
        if (hasText != undefined) {
            this.hasText = hasText;
        } else {
            //no has text
        }


        /*data.jsonData.forEach(item => {
            switch (item.key) {
                case Constants.SEARCH_KEY_WORDS.SEARCH_DOCUMENT_DOC_NAME:
                    this.documentName = item.value
                    break;
                case Constants.SEARCH_KEY_WORDS.SEARCH_DOCUMENT_DOC_FACILITY:
                    this.facilityName = item.value
                    break;
                default:
                    break;
            }
        });*/

        if (this.documentName != "" || this.documentType != "" || this.facilityName != "" || this.hasText != "" || this.altdocno != "" || this.revision != "" || this.filename != "") {
            let to: any = false;
            if (to) { clearTimeout(to); }
            to = setTimeout(() => {
                // this.gridOptions.api.onFilterChanged();
                this.documentsService.searchDocuments(this.documentName, this.documentType, this.facilityName, this.revision, this.altdocno, this.filename, this.hasText).subscribe(
                    data => {
                        this.setDocumentSearchTableData(data.payload)
                    },
                    error => {
                        this.alertService.clear()
                        this.alertService.error(error.statusDescription)
                    });;
            }, 200);
        } else {
            //search bar has empty values
            this.gridApi.hideOverlay()
            this.setDocumentSearchTableData(null)
        }

        // let docArray = data.searchTxt.split(" ");
        // for (var l = 0; l <= docArray.length - 1; l++) {
        //     let letter = docArray[l].split(":");
        //     if (letter != "") {
        //         for (var i = 0; i < letter.length - 1; i++) {
        //             if (letter[i] == "doc") {
        //                 this.documentName = letter[i + 1];
        //             }
        //             else if (letter[i] == "fac") {
        //                 this.facilityName = letter[i + 1];
        //             } else {
        //                 if (this.count == 0) {
        //                     this.count = 1;
        //                     this.error();
        //                 } else {
        //                     // do nothing
        //                 }
        //                 this.count = 1;
        //             }
        //         }
        //     }
        // }

    }

    searchBarEnterFunc(data: any) {
        console.log('[searchBarEnterFunc] (data) ' + JSON.stringify(data));
        this.alertService.clear();
        this.documentName = "";
        this.facilityName = "";
        this.count = 0;
        this.gridApi.showLoadingOverlay();
        //check fro empty values in search bar
        if (this.checkForEmptyValuesInSearchBar(data)) {
            let docArray = data.split(" ");
            for (var l = 0; l <= docArray.length - 1; l++) {
                let letter = docArray[l].split(":");
                if (letter != "") {
                    for (var i = 0; i < letter.length - 1; i++) {
                        if (letter[i] == "doc") {
                            this.documentName = letter[i + 1];
                        }
                        else if (letter[i] == "fac") {
                            this.facilityName = letter[i + 1];
                        } else {
                            if (this.count == 0) {
                                this.count = 1;
                                this.error();
                            } else {
                                // do nothing
                            }
                            this.count = 1;
                        }
                    }
                }
            }
            if (this.documentName != "" || this.facilityName != "") {
                let to: any = false;
                if (to) { clearTimeout(to); }
                to = setTimeout(() => {
                    // this.gridOptions.api.onFilterChanged();
                    this.documentsService.searchDocuments(this.documentName, this.documentType, this.facilityName, this.revision, this.hasText, "", "").subscribe(
                        data => {
                            this.setDocumentSearchTableData(data.payload)
                        },
                        error => {
                            this.alertService.clear()
                            this.alertService.error(error.statusDescription)
                        });;
                }, 200);
            }
        } else {
            //search bar has empty values
            this.gridApi.hideOverlay()
            this.setDocumentSearchTableData(null)
        }
    }

	/**
     * check for empty values in search bar
     * @param data 
     */
    public checkForEmptyValuesInSearchBar(data: any): boolean {
        let docArray = data.split(" ");
        if (docArray.length > 1) {
            return true
        } else {
            if (docArray[0] != '') {
                return true
            } else {
                return false
            }
        }
    }

     /**
     * enable/disable buttons or columns
     */
    private enableDisableButtons(){
        this.capabilityCheck();
        this.enableAddDocument = this.createCap;
        this.enableSearchDocument = this.queryCap;
        this.enableEditDocument = this.modifyCap;
        this.enableDeleteDocument = this.deleteCap;
        this.enableChangeDocumentStatus = this.disableCap;
    }

    /**
     * check the capabilities
     */
    public capabilityCheck(){
        let modifyCapability: string = "modifyDocument";
        let queryCapability: string = "queryDocument";
        let deleteCapability: string = "deleteDocument";
        let disableCapability: string = "disableDocument";
        let createCapability: string = "createDocument";

        this.modifyCap = this.capabilityService.isCapabilityAssigned(modifyCapability);
        this.queryCap = this.capabilityService.isCapabilityAssigned(queryCapability);
        this.deleteCap = this.capabilityService.isCapabilityAssigned(deleteCapability);
        this.disableCap = this.capabilityService.isCapabilityAssigned(disableCapability);
        this.createCap = this.capabilityService.isCapabilityAssigned(createCapability);
    }

}