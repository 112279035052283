
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpHeaders, HttpEventType } from '@angular/common/http';
import { UserVariable } from '../../util/common/user-variable';
import { ServiceUrls } from '../../util/service-urls';
import { WsResponse } from '../../util/ws-response.model';
import { WsCallback } from '../../util/ws-callback.interface';
import { WsType } from '../../util/ws-type';
import { FolderService } from '../../foldertree/foldertree.service'
import { SharedService } from '../../util/shared.service';
import { LocalStorage } from '../../util/localstorage.service';
import { LoggedUser } from '../../user/logged-user.model';
import { LoadingService } from '../../util/loading/loading.service';
import { catchError } from 'rxjs/operators';
import { map, timeout } from 'rxjs/operators'
import { Observable, throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { CommonUtil } from 'src/app/util/common/common-util';


@Injectable()
export class FilesService {
	
	private callback: WsCallback;
	private selectedFileData: any = null;
	private apiGridData: any = null;
	private selectedDocumentType: String;
	private showProperties: boolean = false;
	private revisionGridData: any = null;

	constructor(private http: HttpClient, private folderService: FolderService, private sharedService: SharedService, private loadingService: LoadingService, private commonUtil: CommonUtil) {
	}

	public setCallback(callBack: WsCallback) {
		this.callback = callBack;
	}

	/**
	 * Get image Content. 
	 */
	getImageContent(callBack: WsCallback, itemId: string, imageName: string, cmRevisionNo: string) {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
		const options = { params: new HttpParams().set("token", token).set("imageName", imageName).set("revisionNo", cmRevisionNo) }
		let url = ServiceUrls.IMAGES + itemId + "/downloadImage"
		this.http.get(url, options).
			subscribe(
				data => {

					var modified = JSON.parse(JSON.stringify(data));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					callBack.onSuccess(res, WsType.DOWNLOAD_IMAGE);
				},
				error => {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					callBack.onFail(res, WsType.DOWNLOAD_IMAGE);
				});
	}


/**
	 * Get Topic Content XML for current version. 
	 */

	getTopicContent(callBack:WsCallback,version:string,topicId:string){
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
		const options={params:new HttpParams().set("version",version).set("topicId",topicId)}
		let url=ServiceUrls.CONTENTS + topicId + "/getTopicContent"
		this.http.get(url,options).
			subscribe(
				data => {

					var modified = JSON.parse(JSON.stringify(data));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					callBack.onSuccess(res, WsType.VIEW_TOPIC_CONTENT);
				},
				error => {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					callBack.onFail(res, WsType.VIEW_TOPIC_CONTENT);
				}
			);

			

	}

	/**
		 * Get Topic Content output View for current version. 
		 */

	getTopicContentView(callBack:WsCallback,documentType:string,topicId:string){
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
		const options={params:new HttpParams().set("token", token).set("documentType",documentType).set("topicId",topicId)}
		let url=ServiceUrls.CONTENTS+topicId + "/getTopicContentView"
		this.http.get(url,options).
			subscribe(
				data=>{
					var modified=JSON.parse(JSON.stringify(data));
					var res=new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload
					);
					callBack.onSuccess(res,WsType.VIEW_TOPIC_CONTENT_XML);
				},
				error=>{
					var val=(error as HttpErrorResponse).error;
					var modified=JSON.parse(JSON.stringify(val));
					var res=new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload
					);
					callBack.onFail(res,WsType.VIEW_TOPIC_CONTENT_XML)
				}
			);
	}

	/**
		 * Get revision Content Output View. 
		 */

	getRevisionContentView(callBack: WsCallback, itemId: string, documentType: string, cmRevisionNo: string){
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
		const options={params:new HttpParams().set("token", token).set("documentType", documentType).set("revisionNo", cmRevisionNo)}
		let url=ServiceUrls.CONTENTS + itemId + "/getRevisionContentView"
		this.http.get(url,options).
			subscribe(
				data=>{
					var modified=JSON.parse(JSON.stringify(data));
					var res=new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload
					);
					callBack.onSuccess(res,WsType.VIEW_REVISION_CONTENT_XML)
				},
				error=>{
					var val=(error as HttpErrorResponse).error;
					var modified=JSON.parse(JSON.stringify(val));
					var res=new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload
					);
					callBack.onFail(res,WsType.VIEW_REVISION_CONTENT_XML)
				}
			);
	}

//

	/**
	 * Get revision Content. 
	 */
	getRevisionContent(callBack: WsCallback, itemId: string, documentType: string, cmRevisionNo: string) {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
		const options = { params: new HttpParams().set("token", token).set("documentType", documentType).set("revisionNo", cmRevisionNo) }
		let url = ServiceUrls.CONTENTS + itemId + "/getRevisionContent"
		this.http.get(url, options).
			subscribe(
				data => {

					var modified = JSON.parse(JSON.stringify(data));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					callBack.onSuccess(res, WsType.REVISION_CONTENT_VIEW);
				},
				error => {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					callBack.onFail(res, WsType.REVISION_CONTENT_VIEW);
				});
	}

	/**
	 * Get Procedure Content XML for current version. 
	 */


	getProcedureContent(callBack:WsCallback,documentType:string,procedureId:string){
		console.log("Files_Procedure XML")

        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
		const options={params:new HttpParams().set("token", token).set("documentType",documentType)}
		let url=ServiceUrls.CONTENTS + procedureId +"/getProcedureContent"
		this.http.get(url,options).
			subscribe(
				data=>{
					var modified=JSON.parse(JSON.stringify(data));
					var res=new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload
					);
					callBack.onSuccess(res,WsType.VIEW_PROCEDURE_CONTENT)
				},
				error=>{
					var val=(error as HttpErrorResponse).error;
					var modified=JSON.parse(JSON.stringify(val));
					var res=new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload
					);
					callBack.onFail(res,WsType.VIEW_PROCEDURE_CONTENT)
				}
			);

	}

	/**
		 * Get Procedure Content output View for current version. 
		 */

	getProcedureContentView(callBack:WsCallback,documentType:"string",procedureId:string){
		console.log("Files_Procedure content")

        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
		const options={params:new HttpParams().set("token",token).set("documentType",documentType)}
		let url=ServiceUrls.CONTENTS + procedureId + "/getProcedureContentView"
		this.http.get(url,options).
			subscribe(
				data=>{
					var modified=JSON.parse(JSON.stringify(data));
					var res=new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload
					);
					callBack.onSuccess(res,WsType.VIEW_PROCEDURE_CONTENT_OUTPUT)
				},
				error=>{
					var val=(error as HttpErrorResponse).error;
					var modified=JSON.parse(JSON.stringify(val));
					var res=new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload
					);
					callBack.onFail(res,WsType.VIEW_PROCEDURE_CONTENT_OUTPUT)
				}
			);
	}
	/**
		 * Get Procedure Revision Content Output View. 
		 */

	getProcedureRevisionContentView(callBack:WsCallback,procedureId:string,documentType:string,cmRevisionNo:string){
		console.log("Files_Procedure revisions")

        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
		const options={params:new HttpParams().set("token",token).set("documentType",documentType).set("revisionNo",cmRevisionNo)}
		let url=ServiceUrls.CONTENTS + procedureId + "/getProcedureRevisionContentView"
		this.http.get(url,options).
			subscribe(
				data=>{
					var modified=JSON.parse(JSON.stringify(data));
					var res=new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload
					);
					callBack.onSuccess(res,WsType.VIEW_PROCEDURE_REVISION_CONTENT_OUTPUT)
				},
				error=>{
					var val=(error as HttpErrorResponse).error;
					var modified=JSON.parse(JSON.stringify(val));
					var res=new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload
					);
					callBack.onFail(res,WsType.VIEW_PROCEDURE_REVISION_CONTENT_OUTPUT)
				}
			);
	}

	/**
	 * Get revision Content. 
	 */
	restoreRevisionContent(callBack: WsCallback, itemId: String, documentType: string, cmRevisionNo: string) {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
		let selectedFolderData = this.folderService.getSelectedFoldeData();
		const options = { params: new HttpParams().set("token", token).set("documentType", documentType).set("revisionNo", cmRevisionNo).set("folderId", selectedFolderData.id) }
		let url = ServiceUrls.CONTENTS + itemId + "/doVersionRestore"
		this.http.get(url, options).
			subscribe(
				data => {

					var modified = JSON.parse(JSON.stringify(data));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					callBack.onSuccess(res, WsType.RESTORE_REVISION);
				},
				error => {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					callBack.onFail(res, WsType.RESTORE_REVISION);
				});
	}
	/**
	 * restore revision
	 */
	restoreRevision(itemId: String, documentType: string, cmRevisionNo: string) {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
		let selectedFolderData = this.folderService.getSelectedFoldeData();
		const options = { params: new HttpParams().set("token", token).set("documentType", documentType).set("revisionNo", cmRevisionNo).set("folderId", selectedFolderData.id) }
		let url = ServiceUrls.CONTENTS + itemId + "/doVersionRestore"
		this.http.get(url, options).pipe(timeout(120000)).
			subscribe(
				data => {
					var modified = JSON.parse(JSON.stringify(data));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					this.callback.onSuccess(res, WsType.RESTORE_REVISION);
				},
				error => {
					if (error.message == "TimeoutError") {
						var res = new WsResponse("", "", error.message, "");
						this.callback.onFail(res, WsType.RESTORE_REVISION);
					}
					else if (error.status == "0") {
						var res = new WsResponse("", error.status, "", "");
						this.callback.onFail(res, WsType.RESTORE_REVISION);
					}
					else{
						var val = (error as HttpErrorResponse).error;
						var modified = JSON.parse(JSON.stringify(val));
						var res = new WsResponse(
							modified.status.description,
							modified.status.code,
							modified.status.name,
							modified.payload);
						this.callback.onFail(res, WsType.RESTORE_REVISION);
					}
				});
	}


	/**
	 * Get folder content. 
	 */
	getFolderContentByFolderId(callBack: WsCallback) {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }

		const options = { params: new HttpParams().set("token", token) }
		let folderId = this.folderService.getSelectedFoldeData().id;
		let url = this.getFolderContentByFolderIdURL(folderId);
		this.http.get(url, options).
			subscribe(
				data => {

					var modified = JSON.parse(JSON.stringify(data));
					var res = new WsResponse(
						modified.status.code,
						modified.status.name,
						modified.status.description,
						modified.payload);
					callBack.onSuccess(res, WsType.FILE_VIEW);
				},
				error => {
					var modified = JSON.parse(JSON.stringify(error));
					var res = new WsResponse(
						modified.status.code,
						modified.status.name,
						modified.status.description,
						modified.payload);
					callBack.onFail(res, WsType.FILE_VIEW);
				});
	}

	getTabContentByFolderId(callBack: WsCallback, folderId: any) {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }

		const options = { params: new HttpParams().set("token", token) }
		//let folderId = this.folderService.getSelectedFoldeData().id;
		let url = this.getFolderContentByFolderIdURL(folderId);
		this.http.get(url, options).
			subscribe(
				data => {

					var modified = JSON.parse(JSON.stringify(data));
					var res = new WsResponse(
						modified.status.code,
						modified.status.name,
						modified.status.description,
						modified.payload);
					callBack.onSuccess(res, WsType.FILE_VIEW);
				},
				error => {
					var modified = JSON.parse(JSON.stringify(error));
					var res = new WsResponse(
						modified.status.code,
						modified.status.name,
						modified.status.description,
						modified.payload);
					callBack.onFail(res, WsType.FILE_VIEW);
				});
	}

	/**
	 * search files. 
	 */
	
	searchFiles(fileName: String, checkOutBy: String, lastUpdated: String, fileType: String, hasText: string) {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
		let url = this.getFileSearchURL(token);
		this.http.post(url, JSON.stringify(new Request(fileName, checkOutBy, lastUpdated, fileType, hasText))).
			subscribe(
				data => {
					console.log("response " + JSON.stringify(data));
					var modified = JSON.parse(JSON.stringify(data));
					var res = new WsResponse(
						modified.status.description,
						modified.status.name,
						modified.status.code,
						modified.payload);
					this.callback.onSuccess(res, WsType.FILE_SEARCH);
				},
				error => {
					var modified = JSON.parse(JSON.stringify(error));
					var res = new WsResponse(
						modified.error.status.description,
						modified.error.status.name,
						modified.error.status.code,
						modified.error.payload);
					this.callback.onFail(res, WsType.FILE_SEARCH);
				});
	}

	/**
    * Define URL for get file list by file search criteria.
    */
   public getFileSearchURL(token: String) {
	let url = ServiceUrls.GET_FILE_SEARCH_RESULTS + '?token=' + token;
	return url;
	}

	/**
	 * Get file data. 
	 * @param fileId.
	 */
	getFileDataByFileId(fileData: any, callBack: WsCallback) {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
		const options = { params: new HttpParams().set("token", token) }
		let folderId = this.folderService.getSelectedFoldeData().id;
		console.log("file id in req: "+ fileData.id)
		let url = this.getUrl(fileData.id, fileData.type, false);
		this.http.get(url, options).
			subscribe(
				data => {
					var modified = JSON.parse(JSON.stringify(data));
					var res = new WsResponse(
						modified.status.code,
						modified.status.name,
						modified.status.description,
						modified.payload);
					// console.log("response " + JSON.stringify(data));
					callBack.onSuccess(res, WsType.FILE_DATA_VIEW);
				},
				error => {
					var modified = JSON.parse(JSON.stringify(error));
					var res = new WsResponse(
						modified.status.code,
						modified.status.name,
						modified.status.description,
						modified.payload);
					callBack.onFail(res, WsType.FILE_DATA_VIEW);
				});
	}

	getICECourse(courseId: string, callBack: WsCallback) {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
		this.http.get(ServiceUrls.getBasicICECourse(token, courseId)).subscribe(
				data => {
						var modified = JSON.parse(JSON.stringify(data));
						var res = new WsResponse(
								modified.status.description,
								modified.status.code,
								modified.status.name,
								modified.payload);
						callBack.onSuccess(res, WsType.GET_ICE_COURSE);
				},
				error => {
						if (error.status != '') {
								var val = (error as HttpErrorResponse).error;
								var modified = JSON.parse(JSON.stringify(val));
								var res = new WsResponse(
										modified.status.description,
										modified.status.code,
										modified.status.name,
										modified.payload);
								callBack.onFail(res, WsType.GET_ICE_COURSE);
						} else {
								//browser related issues
								var res = new WsResponse("Unknown error happened");
								callBack.onFail(res, WsType.GET_ICE_COURSE);
						}
				}
		);
	}
	
	getICECourseRevisionsData(courseId: string, callBack: WsCallback) {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
		this.http.get(ServiceUrls.getICECourseRevisonData(token, courseId)).subscribe(
				data => {
						var modified = JSON.parse(JSON.stringify(data));
						var res = new WsResponse(
								modified.status.description,
								modified.status.code,
								modified.status.name,
								modified.payload);
						callBack.onSuccess(res, WsType.GET_ICE_COURSE_REVISIONS_DATA);
				},
				error => {
						if (error.status != '') {
								var val = (error as HttpErrorResponse).error;
								var modified = JSON.parse(JSON.stringify(val));
								var res = new WsResponse(
										modified.status.description,
										modified.status.code,
										modified.status.name,
										modified.payload);
								callBack.onFail(res, WsType.GET_ICE_COURSE_REVISIONS_DATA);
						} else {
								//browser related issues
								var res = new WsResponse("Unknown error happened");
								callBack.onFail(res, WsType.GET_ICE_COURSE_REVISIONS_DATA);
						}
				}
		);
	}
	
	restoreICECourseRevision(courseId: string,courseVersion: string, callBack: WsCallback) {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
		let url = ServiceUrls.restoreICECourseRevision(token);
		this.http.post(url, JSON.stringify(new ICECourseRequest(courseId, courseVersion))).pipe(timeout(120000)).
			subscribe(
				data => {
					console.log("response " + JSON.stringify(data));
					var modified = JSON.parse(JSON.stringify(data));
					var res = new WsResponse(
						modified.status.description,
						modified.status.name,
						modified.status.code,
						modified.payload);
					this.callback.onSuccess(res, WsType.RESTORE_ICE_COURSE_REVISION);
				},
				error => {
					if (error.message == "TimeoutError") {
						var res = new WsResponse("", "", error.message, "");
						this.callback.onFail(res, WsType.RESTORE_ICE_COURSE_REVISION);
					}
					else if (error.status == "0") {
						var res = new WsResponse("", error.status, "", "");
						this.callback.onFail(res, WsType.RESTORE_ICE_COURSE_REVISION);
					}
					else{
						var modified = JSON.parse(JSON.stringify(error));
						var res = new WsResponse(
							modified.error.status.description,
							modified.error.status.name,
							modified.error.status.code,
							modified.error.payload);
						this.callback.onFail(res, WsType.RESTORE_ICE_COURSE_REVISION);
					}
				});
  }

	/**
	 * Get revision data. 
	 * @param fileId.
	 */
	getRevisionDataByFileId(fileData: any, callBack: WsCallback) {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
		const options = { params: new HttpParams().set("token", token) }
		let folderId = this.folderService.getSelectedFoldeData().id;
		let url = this.getUrl(fileData.id, fileData.type, true);
		this.http.get(url, options).
			subscribe(
				data => {
					var modified = JSON.parse(JSON.stringify(data));
					var res = new WsResponse(
						modified.status.code,
						modified.status.name,
						modified.status.description,
						modified.payload);
					//console.log("response " + JSON.stringify(data));
					callBack.onSuccess(res, WsType.REVISION_DATA_VIEW);
				},
				error => {
					var modified = JSON.parse(JSON.stringify(error));
					var res = new WsResponse(
						modified.status.code,
						modified.status.name,
						modified.status.description,
						modified.payload);
					callBack.onFail(res, WsType.REVISION_DATA_VIEW);
				});
	}

	/**
	 * Update topic. 
	 * @param topicModel.
	 * @param topicId. 
	 */
	updateFile(fileModel: String, id: String, type: String, callBack: WsCallback) {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
		const options = { params: new HttpParams().set("token", token) }
		let url = this.getUrl(id, type, false);

		this.http.put(url, fileModel, options).subscribe(
			data => {
				// this may be incorrect
				//var val = (data as HttpErrorResponse).message;
				console.log( "===data=="+JSON.parse(JSON.stringify(data)))
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				callBack.onSuccess(res, WsType.FILE_UPDATE);
			},
			error => {
				console.log( "===error=="+JSON.parse(JSON.stringify(error)))
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
						console.log(error);
					callBack.onFail(res, WsType.FILE_UPDATE);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					callBack.onFail(res, WsType.FILE_UPDATE);
				}
			}
		);

	}

	duplicateTopic( id: String, name: String, folderId: String, callback: WsCallback) {
        console.log("addProject");
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
		let url =ServiceUrls.DUPLICATES  + 'duplicate/duplicateTheTopic.json?token=' + token;
		var data = {id: id, name:name, folderId: folderId  }
		this.http.post(url, data).
			subscribe(
				data => {
					var modified:any =  data;
					var res = new WsResponse(
						modified.status.description,
						modified.status.name,
						modified.status.code,
						modified.payload);
					callback.onSuccess(res, WsType.DUPLICATE_ITEM);
				},
				error => {
					var modified = JSON.parse(JSON.stringify(error));
					var res = new WsResponse(
						modified.error.status.description,
						modified.error.status.name,
						modified.error.status.code,
						modified.error.payload);
					callback.onFail(res, WsType.DUPLICATE_ITEM);
				});
	}

	duplicateICECourse( id: String, name: String, folderId: String, callback: WsCallback) {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
	let url =ServiceUrls.DUPLICATES  + 'duplicate/duplicateTheICECourse.json?token=' + token;
	var data = {id: id, name:name, folderId: folderId  }
	this.http.post(url, data).
		subscribe(
			data => {
				var modified:any =  data;
				var res = new WsResponse(
					modified.status.description,
					modified.status.name,
					modified.status.code,
					modified.payload);
				callback.onSuccess(res, WsType.DUPLICATE_ITEM);
			},
			error => {
				var modified = JSON.parse(JSON.stringify(error));
				var res = new WsResponse(
					modified.error.status.description,
					modified.error.status.name,
					modified.error.status.code,
					modified.error.payload);
				callback.onFail(res, WsType.DUPLICATE_ITEM);
			});	
	}

	duplicateProcedure( id: String, name: String, folderId: String, callback: WsCallback) {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
	let url =ServiceUrls.DUPLICATES  + 'duplicate/duplicateTheProcedure.json?token=' + token;
	var data = {id: id, name:name, folderId: folderId  }
	this.http.post(url, data).
		subscribe(
			data => {
				var modified:any =  data;
				var res = new WsResponse(
					modified.status.description,
					modified.status.name,
					modified.status.code,
					modified.payload);
				callback.onSuccess(res, WsType.DUPLICATE_ITEM);
			},
			error => {
				var modified = JSON.parse(JSON.stringify(error));
				var res = new WsResponse(
					modified.error.status.description,
					modified.error.status.name,
					modified.error.status.code,
					modified.error.payload);
				callback.onFail(res, WsType.DUPLICATE_ITEM);
			});
}

duplicateTopicMap( id: String, name: String, folderId: String, callback: WsCallback) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
        return;
    }
let url =ServiceUrls.DUPLICATES  + 'duplicate/duplicateTheTopicMap.json?token=' + token;
var data = {id: id, name:name, folderId: folderId  }
this.http.post(url, data).
	subscribe(
		data => {
			var modified:any =  data;
			var res = new WsResponse(
				modified.status.description,
				modified.status.name,
				modified.status.code,
				modified.payload);
			callback.onSuccess(res, WsType.DUPLICATE_ITEM);
		},
		error => {
			var modified = JSON.parse(JSON.stringify(error));
			var res = new WsResponse(
				modified.error.status.description,
				modified.error.status.name,
				modified.error.status.code,
				modified.error.payload);
			callback.onFail(res, WsType.DUPLICATE_ITEM);
		});
}

duplicateImage( id: String, name: String, folderId: String, callback: WsCallback) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
        return;
    }
let url =ServiceUrls.DUPLICATES  + 'duplicate/duplicateTheImage.json?token=' + token;
var data = {id: id, name:name, folderId: folderId  }
this.http.post(url, data).
	subscribe(
		data => {
			var modified:any =  data;
			var res = new WsResponse(
				modified.status.description,
				modified.status.name,
				modified.status.code,
				modified.payload);
			callback.onSuccess(res, WsType.DUPLICATE_ITEM);
		},
		error => {
			var modified = JSON.parse(JSON.stringify(error));
			var res = new WsResponse(
				modified.error.status.description,
				modified.error.status.name,
				modified.error.status.code,
				modified.error.payload);
			callback.onFail(res, WsType.DUPLICATE_ITEM);
		});
}

duplicateFile( id: String, name: String, folderId: String, callback: WsCallback) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
        return;
    }
let url =ServiceUrls.DUPLICATES  + 'duplicate/duplicateTheFile.json?token=' + token;
var data = {id: id, name:name, folderId: folderId  }
this.http.post(url, data).
	subscribe(
		data => {
			var modified:any =  data;
			var res = new WsResponse(
				modified.status.description,
				modified.status.name,
				modified.status.code,
				modified.payload);
			callback.onSuccess(res, WsType.DUPLICATE_ITEM);
		},
		error => {
			var modified = JSON.parse(JSON.stringify(error));
			var res = new WsResponse(
				modified.error.status.description,
				modified.error.status.name,
				modified.error.status.code,
				modified.error.payload);
			callback.onFail(res, WsType.DUPLICATE_ITEM);
		});
}



	/**
	 * Get converted xml for ck editor. 
	 * @param topicId doument Id. 
	 */
	getEditorXMLByTopicId(topicId: any, topicType: any) {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
		const options = { params: new HttpParams().set("token", token) }
		let url = "";
		if (topicType == "Topic") {
			url = this.getTopicForEditorURL(topicId);
		} else if (topicType == "Procedure") {
			url = this.getProcedureForEditorURL(topicId);
		}

		this.http.get(url, options).
			subscribe(
				data => {
					var modified = JSON.parse(JSON.stringify(data));
					var res = new WsResponse(
						modified.status.code,
						modified.status.name,
						modified.status.description,
						modified.payload);
					//console.log("response " + JSON.stringify(data))
					this.callback.onSuccess(res, WsType.FILE_EDITOR_VIEW);
				},
				error => {
					var modified = JSON.parse(JSON.stringify(error));
					var res = new WsResponse(
						modified.status.code,
						modified.status.name,
						modified.status.description,
						modified.payload);
					this.callback.onFail(res, WsType.FILE_EDITOR_VIEW);
				});
	}


	public setApiGridData(apiGridData: any) {
		this.apiGridData = apiGridData;
	}

	public setRevisionGridData(revisionGridData: any) {
		this.revisionGridData = revisionGridData;
		console.log("set rev data" + revisionGridData)
	}

	public addNewRow(item: any) {
		var rowData = [];
		rowData.push({
			revisionNo: item["cmRevisionNo"],
			view: item["itemType"] != 'Image' ? "view:" + item["cmRevisionNo"] + "," + item["itemId"] + "," + item["itemType"] : "view:" + item["cmRevisionNo"] + "," + item["itemId"] + "," + item["itemType"] + "," + item["itemName"] + "," + item["versionFile"],
			restore: item["status"] == "No" ? "disableRestore:" + item["cmRevisionNo"] + "," + item["itemId"] + "," + item["itemType"] : 'restore:' + item["cmRevisionNo"] + "," + item["itemId"] + "," + item["itemType"],
			checkedInBy: item["userId"],
			checkedInDate: new Date(item["cmTimeStamp"]).toLocaleString()
		});
		this.revisionGridData.forEachNode(function (node) {
			rowData.push(node.data);
			// rowData.push(node.data);
		});



		console.log("Row Data:");
		console.log(rowData);

		this.revisionGridData.setRowData(rowData);

		//var newItems = [item];
		//var res = this.revisionGridData.applyTransaction({ add: newItems, addIndex: 0 });
		//console.log("item" + newItems)
	}

	public loadFiles(fileName: String, fileId: any, column: any) {
		if (this.apiGridData != null && this.apiGridData != undefined && fileId != null) {
			var rowNode = this.apiGridData.getRowNode(fileId);
			if (rowNode != null && rowNode != undefined) {
				rowNode.setDataValue(column, fileName);
			}
		}
	}

	public updateFileTableDataUndoCheckout(checkoutBy: String, lastUpdateTimeStamp: number, fileId: any, fileType: String, fileName: String = null) {		
		console.log("updateFileTableDataUndoCheckout[fileId, fileType, checkoutBy]: " + fileId + ", " + fileType + ", " + checkoutBy);
		if (this.apiGridData != null && this.apiGridData != undefined && fileId != null) {
			var rowNode = this.apiGridData.getRowNode(fileId);
			if (rowNode != null && rowNode != undefined) {
				let checkoutByVal: String = null;
				let loggedInUser: LoggedUser;
				let preferences = localStorage.getItem('preferences');
				var json = JSON.parse(preferences);
				var innerJson = (JSON.parse(json)).UserPreference.Portal;
				let zone = innerJson.General.TimeZone;
				switch (fileType) {
					case "Topic": {
						checkoutByVal = checkoutBy;
						rowNode.setDataValue("lastUpdateTimeStamp", new Date(lastUpdateTimeStamp).toLocaleString("en-US", {timeZone: zone}));
						break;
					}
					case "ContentItem": {
						checkoutByVal = checkoutBy;
						rowNode.setDataValue("lastUpdateTimeStamp", new Date(lastUpdateTimeStamp).toLocaleString("en-US", {timeZone: zone}));
						if(fileName!=null){
							rowNode.setDataValue("fileName", "tooltip:" + fileName);
						}
						break;
					}
					case "Image": {
						checkoutByVal = checkoutBy;
						rowNode.setDataValue("lastUpdateTimeStamp", new Date(lastUpdateTimeStamp).toLocaleString("en-US", {timeZone: zone}));
						if(fileName!=null){
							rowNode.setDataValue("fileName", "tooltip:" + fileName);
						}
						break;
					}
					case "ThreeDModel": {
						checkoutByVal = checkoutBy;
						rowNode.setDataValue("lastUpdateTimeStamp", new Date(lastUpdateTimeStamp).toLocaleString("en-US", {timeZone: zone}));
						if(fileName!=null){
							rowNode.setDataValue("fileName", "tooltip:" + fileName);
						}
						break;
					}
					case "Video": {
						checkoutByVal = checkoutBy;
						rowNode.setDataValue("lastUpdateTimeStamp", new Date(lastUpdateTimeStamp).toLocaleString("en-US", {timeZone: zone}));
						if(fileName!=null){
							rowNode.setDataValue("fileName", "tooltip:" + fileName);
						}
						break;
					}
					case "Procedure": {
						checkoutByVal = checkoutBy;
						rowNode.setDataValue("lastUpdateTimeStamp", new Date(lastUpdateTimeStamp).toLocaleString("en-US", {timeZone: zone}));
						break;
					}
					case "TopicMap": {
						checkoutByVal = checkoutBy;
						rowNode.setDataValue("lastUpdateTimeStamp", new Date(lastUpdateTimeStamp).toLocaleString("en-US", {timeZone: zone}));
						break;
					}
					case "ICECourse": {
						checkoutByVal = checkoutBy;
						rowNode.setDataValue("lastUpdateTimeStamp", new Date(lastUpdateTimeStamp).toLocaleString("en-US", {timeZone: zone}));
						break;
					}
				}
				console.log(checkoutByVal);
				if (checkoutByVal == "" || checkoutByVal == " " || checkoutByVal == null || checkoutByVal == undefined) {
					rowNode.setDataValue("checkoutBy", "");
				} else{
					rowNode.setDataValue("checkoutBy", checkoutByVal);
				}
			}
		}
	}


	public updateFileTableData(checkoutBy: String, lastUpdateTimeStamp: number, fileId: any, fileType: String, fileName: String = null) {
		console.log("updateFileTableData - checked out");
		if (this.apiGridData != null && this.apiGridData != undefined && fileId != null) {
			var rowNode = this.apiGridData.getRowNode(fileId);
			if (rowNode != null && rowNode != undefined) {
				let checkoutByVal: String;
				let preferences = localStorage.getItem('preferences');
				var json = JSON.parse(preferences);
				var innerJson = (JSON.parse(json)).UserPreference.Portal;
				let zone = innerJson.General.TimeZone;
				switch (fileType) {
					case "Topic": {
						checkoutByVal = checkoutBy;
						rowNode.setDataValue("lastUpdateTimeStamp", new Date(lastUpdateTimeStamp).toLocaleString("en-US", {timeZone: zone}));
						break;
					}
					case "ContentItem": {
						checkoutByVal = checkoutBy;
						rowNode.setDataValue("lastUpdateTimeStamp", new Date(lastUpdateTimeStamp).toLocaleString("en-US", {timeZone: zone}));
						if(fileName!=null){
							rowNode.setDataValue("fileName", "tooltip:" + fileName);
						}
						break;
					}
					case "Image": {
						checkoutByVal = checkoutBy;
						rowNode.setDataValue("lastUpdateTimeStamp", new Date(lastUpdateTimeStamp).toLocaleString("en-US", {timeZone: zone}));
						if(fileName!=null){
							rowNode.setDataValue("fileName", "tooltip:" + fileName);
						}
						break;
					}
					case "ThreeDModel":
					{
						checkoutByVal = checkoutBy;
						rowNode.setDataValue("lastUpdateTimeStamp", new Date(lastUpdateTimeStamp).toLocaleString("en-US", {timeZone: zone}));
						if(fileName!=null){
							rowNode.setDataValue("fileName", "tooltip:" + fileName);
						}
						break;
					}
					case "Video":
					{
						checkoutByVal = checkoutBy;
						rowNode.setDataValue("lastUpdateTimeStamp", new Date(lastUpdateTimeStamp).toLocaleString("en-US", {timeZone: zone}));
						if(fileName!=null){
							rowNode.setDataValue("fileName", "tooltip:" + fileName);
						}
						break;
					}
					case "Procedure": {
						checkoutByVal = checkoutBy;
						rowNode.setDataValue("lastUpdateTimeStamp", new Date(lastUpdateTimeStamp).toLocaleString("en-US", {timeZone: zone}));
						break;
					}
					case "TopicMap": {
						checkoutByVal = checkoutBy;
						rowNode.setDataValue("lastUpdateTimeStamp", new Date(lastUpdateTimeStamp).toLocaleString("en-US", {timeZone: zone}));
						break;
					}
					case "ICECourse": {
						checkoutByVal = checkoutBy;
						rowNode.setDataValue("lastUpdateTimeStamp", new Date(lastUpdateTimeStamp).toLocaleString("en-US", {timeZone: zone}));
						break;
					}
				}
				console.log(checkoutByVal);
				if (checkoutByVal == "" || checkoutByVal == " " || checkoutByVal == null || checkoutByVal == undefined) {
					rowNode.setDataValue("checkoutBy", "");
				} else{
					rowNode.setDataValue("checkoutBy", checkoutByVal);
				}
			}
		}
	}

	public updateFileTableDataCheckin(checkoutBy: String, lastUpdateTimeStamp: number, fileId: any, fileType: String, fileName: String = null) {
		console.log("updateFileTableDataCheckin[fileId, fileType]: " + fileId + ", " + fileType);
		if (this.apiGridData != null && this.apiGridData != undefined && fileId != null) {
			var rowNode = this.apiGridData.getRowNode(fileId);
			if (rowNode != null && rowNode != undefined) {
				let preferences = localStorage.getItem('preferences');
				var json = JSON.parse(preferences);
				var innerJson = (JSON.parse(json)).UserPreference.Portal;
				let zone = innerJson.General.TimeZone;
				switch (fileType) {
					case "Topic":
						{
							rowNode.setDataValue("lastUpdateTimeStamp", new Date(lastUpdateTimeStamp).toLocaleString("en-US", {timeZone: zone}));
							rowNode.setDataValue("checkoutBy", "");
							break;
						}
					case "Procedure":
						{
							rowNode.setDataValue("lastUpdateTimeStamp", new Date(lastUpdateTimeStamp).toLocaleString("en-US", {timeZone: zone}));
							rowNode.setDataValue("checkoutBy", "");
							break;
						}
					case "ContentItem":
						{
							rowNode.setDataValue("lastUpdateTimeStamp", new Date(lastUpdateTimeStamp).toLocaleString("en-US", {timeZone: zone}));
							rowNode.setDataValue("checkoutBy", "");
							if(fileName!=null){
								rowNode.setDataValue("fileName", "tooltip:" + fileName);
							}
							break;
						}
					case "Image":
						{
							rowNode.setDataValue("lastUpdateTimeStamp", new Date(lastUpdateTimeStamp).toLocaleString("en-US", {timeZone: zone}));
							rowNode.setDataValue("checkoutBy", "");
							if(fileName!=null){
								rowNode.setDataValue("fileName", "tooltip:" + fileName);
							}
							break;
						}
					case "ThreeDModel":
						{
							rowNode.setDataValue("lastUpdateTimeStamp", new Date(lastUpdateTimeStamp).toLocaleString("en-US", {timeZone: zone}));
							rowNode.setDataValue("checkoutBy", "");
							if(fileName!=null){
								rowNode.setDataValue("fileName", "tooltip:" + fileName);
							}
							break;
						}
					case "Video":
						{
							rowNode.setDataValue("lastUpdateTimeStamp", new Date(lastUpdateTimeStamp).toLocaleString("en-US", {timeZone: zone}));
							rowNode.setDataValue("checkoutBy", "");
							if(fileName!=null){
								rowNode.setDataValue("fileName", "tooltip:" + fileName);
							}
							break;
						}
					case "TopicMap": 
						{
							rowNode.setDataValue("lastUpdateTimeStamp", new Date(lastUpdateTimeStamp).toLocaleString("en-US", {timeZone: zone}));
							rowNode.setDataValue("checkoutBy", "");
							break;
						}
					case "ICECourse": 
						{
							rowNode.setDataValue("lastUpdateTimeStamp", new Date(lastUpdateTimeStamp).toLocaleString("en-US", {timeZone: zone}));
							rowNode.setDataValue("checkoutBy", "");
							break;
						}
				}				
			}
		}
	}


	public disableEnableFileTableData(lastUpdateTimeStamp: number, fileId: any, title: String, status: String) {
		if (this.apiGridData != null && this.apiGridData != undefined && fileId != null) {
			var rowNode = this.apiGridData.getRowNode(fileId);
			if (rowNode != null && rowNode != undefined) {
				console.log("disableEnableFileTableData");
				let preferences = localStorage.getItem('preferences');
				var json = JSON.parse(preferences);
				var innerJson = (JSON.parse(json)).UserPreference.Portal;
				let zone = innerJson.General.TimeZone;
				let date = new Date(lastUpdateTimeStamp).toLocaleString("en-US", {timeZone: zone}) != "Invalid Date" ? new Date(lastUpdateTimeStamp).toLocaleString("en-US", {timeZone: zone}) : lastUpdateTimeStamp;
				console.log("Last Updated timestamp: " + date);
				rowNode.setDataValue("lastUpdateTimeStamp", date);
				if (title == 'Disable Confirm') {
					this.sharedService.setStatus("Inactive");
					this.sharedService.disableCellRendererRequest(true, fileId);
				} else if (title == 'Enable Confirm') {
					this.sharedService.setStatus("Active");
					this.sharedService.disableCellRendererRequest(false, fileId);
				} else if (title == 'Delete Conflicts') {
					if (status == 'Active') {
						this.sharedService.setStatus("Inactive");
						this.sharedService.disableCellRendererRequest(true, fileId);
						this.sharedService.deleteUpdateRequest(false, true, "Inactive");
					} else if (status == 'Inactive') {
						this.sharedService.setStatus("Active");
						this.sharedService.disableCellRendererRequest(false, fileId);
						this.sharedService.deleteUpdateRequest(true, false, "Active");
					}
				}
			}
		}
	}

	public deleteFileTableData(fileId: any) {
		if (this.apiGridData != null && this.apiGridData != undefined && fileId != null) {
			var rowdata = [];
			var rowNode = this.apiGridData.getRowNode(fileId);
			if (rowNode != null && rowNode != undefined) {
				rowdata.push(rowNode.data);
				this.apiGridData.applyTransaction({ remove: rowdata });
				this.sharedService.showDetailPanelRequest(false);

				/* enable folder delete icon in folder tree if the folder is empty */
				if(this.apiGridData.rowModel.rowsToDisplay != undefined
					&& this.apiGridData.rowModel.rowsToDisplay.length == 0){
						this.sharedService.enableFolderDeleteRequest(true);
				}
			}
		}
	}

	/**
	* Define URL according to file type.
	* @param topicId 
	*/
	public getUrl(id: String, type: String, isRevision: boolean) {
		let itemtype = type.split(':')
		switch (itemtype[0]) {
			case "Topic":
				return isRevision ? ServiceUrls.TOPICS + id + '/getRevisionById.json' : ServiceUrls.TOPICS + id + '.json'
			case "Procedure":
				return isRevision ? ServiceUrls.PROCEDURES + id + '/getRevisionById.json' : ServiceUrls.PROCEDURES + id + '.json';
			case "TopicMap":
				return isRevision ? ServiceUrls.TOPIC_MAPS + id + '/getRevisionById.json' : ServiceUrls.TOPIC_MAPS + id + '.json'
			case "ContentItem":
				return isRevision ? ServiceUrls.CONTENTS + id + '/getRevisionById.json' : ServiceUrls.CONTENTS + id + '.json'
			case "Image":
				return isRevision ? ServiceUrls.IMAGES + id + '/getRevisionById.json' : ServiceUrls.IMAGES + id + '.json'
			case "ICECourse":
				return ServiceUrls.ICECOURSE + id + '.json'
			case "ThreeDModel":
				return isRevision ? ServiceUrls.THREED_MODELS+ id + '/getRevisionById.json' : ServiceUrls.THREED_MODELS + id + '.json'
			case "Video":
				return isRevision ? ServiceUrls.VIDEOS+ id + '/getRevisionById.json' : ServiceUrls.VIDEOS + id + '.json'
			default:
				this.loadingService.hideLoading();
		}
	}

	/**
	 * Define URL for get folder content.
	 * @param folderId 
	 */
	public getFolderContentByFolderIdURL(folderId: string) {
		let url = ServiceUrls.FILE_BY_FOLDER_ID_VIEW_URL + folderId + '/files.json'
		return url;
	}

	public setSelectedFileData(selectedFileData: any) {
		this.selectedFileData = selectedFileData;
	}

	public getSelectedFileData() {
		return this.selectedFileData;
	}

	public setSelectedDocumentType(selectedDocumentType: String) {
		this.selectedDocumentType = selectedDocumentType
	}

	public getSelectedDocumentType() {
		return this.selectedDocumentType;
	}

	public setShowProperties(showProperties) {
		this.showProperties = showProperties;
	}

	public getShowProperties() {
		return this.showProperties;
	}

	/**
	 * Define URL for get editor topic xml.
	 * @param fileId 
	 */
	public getTopicForEditorURL(fileId: string) {
		let url = ServiceUrls.FILE_EDITOR_VIEW + 'topics/' + fileId + '/GetEditorTopicContent.json'
		return url;
	}

	/**
	 * Define URL for get editor procedure xml.
	 * @param fileId 
	 */
	public getProcedureForEditorURL(fileId: string) {
		let url = ServiceUrls.FILE_EDITOR_VIEW + 'procedure/' + fileId + '/GetEditorProcedureContent.json'
		return url;
	}

	/**
	  * Get template for document. 
	  */
    public getTemplateForDoc(docId: string, docType: string, outoutType: string): Observable<WsResponse> {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
		const options = { params: new HttpParams().set("token", token).set("docId", docId).set("docType", docType).set("outoutType", outoutType) }
		return this.http.get(ServiceUrls.GET_TEMPLATE_FOR_DOC, options).
			pipe(
				map(response => {
					var modified = JSON.parse(JSON.stringify(response));
					return new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
				}),
				catchError(error => {
					var modified = JSON.parse(JSON.stringify(error.error));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					return throwError(res)
				})
			);
	}

	/**
	* Get template configuration inputs. 
	*/
	public getTemplateConfigInputs(docId: string, docType: string, outputType: string, projectTemplateMapId: string): Observable<WsResponse> {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
		const options = {
			params: new HttpParams().set("token", token).set("docId", docId).set("docType", docType)
				.set("outputType", outputType).set("templateId", projectTemplateMapId)
		}
		return this.http.get(ServiceUrls.GET_TEMPLATE_CONFIG_INPUTS, options).
			pipe(
				map(response => {
					var modified = JSON.parse(JSON.stringify(response));
					return new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
				}),
				catchError(error => {
					var modified = JSON.parse(JSON.stringify(error.error));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					return throwError(res)
				})
			);
	}

	/**
	* Generate output file. 
	*/
	generateOutput(inputData: any) {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
		inputData['userToken'] = token
		return this.http.post(ServiceUrls.GENERATE_OUTPUT, JSON.stringify(inputData)).
			pipe(
				map(response => {
					var modified = JSON.parse(JSON.stringify(response));
					return new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
				}),
				catchError(error => {
					var modified = JSON.parse(JSON.stringify(error.error));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					return throwError(res)
				})
			);
	}

	/**
	* Generate multiple output 
	*/
	generateMultipleOutput(inputData: any) {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
		inputData['userToken'] = token
		return this.http.post(ServiceUrls.GENERATE_MULTIPLE_OUTPUT, JSON.stringify(inputData)).
			pipe(
				map(response => {
					var modified = JSON.parse(JSON.stringify(response));
					return new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
				}),
				catchError(error => {
					var modified = JSON.parse(JSON.stringify(error.error));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					return throwError(res)
				})
			);
	}

	/**
	* View output file. 
	*/
	downloadFile(outputId: string, outputFile: string) {
		return this.http.get(ServiceUrls.DOWNLOAD_FILE, {
			responseType: "blob", params: new HttpParams().
				set("documentId", outputId).set("mode", "ViewOutput").set("fileName", outputFile)
		});
	}

	/**
	 * Check-in a file
	 * @param fileUploadData file upload form data
	 * @param callBack callback class
	 */
	public checkInFile(fileUploadData: FormData, callBack: WsCallback) {
		this.http.post(ServiceUrls.FILE_CHECK_IN, fileUploadData, {
			reportProgress: true,
			observe: 'events'
		}).subscribe(
			event => {
				if (event.type == HttpEventType.UploadProgress) {
					//progress
				} else if (event.type == HttpEventType.Response) {
					var modified = JSON.parse(JSON.stringify(event.body));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					callBack.onSuccess(res, WsType.FILE_CHECK_IN);
				}
			},
			error => {
				if (error.error.payload != undefined) {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					callBack.onFail(res, WsType.FILE_CHECK_IN);
				} else {
					var res: WsResponse;
					if (error.error.actionErrors != undefined) {
						res = new WsResponse(error.statusText + ' - ' + error.error.actionErrors);
					} else {
						res = new WsResponse(error.message);
					}
					callBack.onFail(res, WsType.FILE_CHECK_IN);  
				}
			}
		);
	}



	/**
     *  view/ download content items (pdf, doc, docx, ppt, pptx)
     * @param revisionNo 
     * @param contentId 
     * @param documentType 
	*/
	getContentItemContent(revisionNo: string, contentId: string, documentType: string){
        let token = UserVariable.getUserToken()
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
		let url = ServiceUrls.getDowonloadContentURL(contentId)
		const options = { params: new HttpParams().set("token", token).set("revisionNo", revisionNo).set("documentType", documentType)}
		return this.http.get(url, options).pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
		);
	}
	compareRevisionContent(callBack: WsCallback, revision1: string, revision2: string, documentType:string, itemId:string) {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
		const options = { params: new HttpParams().set("token", token).set("revision1", revision1).set("revision2", revision2).set("documentType", documentType).set("itemId", itemId) }
		let url = ServiceUrls.CONTENTS + "revision/compareRevisionContent"
		this.http.get(url, options).
			subscribe(
				data => {

					var modified = JSON.parse(JSON.stringify(data));
					var res = new WsResponse(
						 modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					 callBack.onSuccess(res, WsType.REVISION_COMPARE_VIEW);
				},
				error => {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					 callBack.onFail(res, WsType.REVISION_COMPARE_VIEW);
				});
				
				
	}

	threeDModelView(type:String, modelId:String){
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
		const options={params:new HttpParams().set("token", token)}
		//var data = {id: modelId, projectId:UserVariable.projectId}
		let url = ServiceUrls.GET_THREED_MODEL_VIEW;
		return this.http.get(url, options ).	
	pipe(
		map(response => {
			var modified = JSON.parse(JSON.stringify(response));
			return new WsResponse(
				modified.status.description,
				modified.status.name,
				modified.status.code,
				modified.payload);
		}),
		catchError(error => {
			var modified = JSON.parse(JSON.stringify(error));
			var res = new WsResponse(
				modified.error.status.description,
				modified.error.status.name,
				modified.error.status.code,
				modified.error.payload);
			return throwError(res)
		})
	);
}


getThreeDModelURL(data: any) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
        return;
    }
	let url = ServiceUrls.GET_THREED_MODEL_VIEW + '?token=' + token;
	
	console.log(data);
	return this.http.post(url,  new ThumbnailRequest(data)).	
	pipe(
		map(response => {
			var modified = JSON.parse(JSON.stringify(response));
			return new WsResponse(
				modified.status.description,
				modified.status.name,
				modified.status.code,
				modified.payload);
		}),
		catchError(error => {
			var modified = JSON.parse(JSON.stringify(error));
			var res = new WsResponse(
				modified.error.status.description,
				modified.error.status.name,
				modified.error.status.code,
				modified.error.payload);
			return throwError(res)
		})
	);
}

getVideoURL(data: any) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
        return;
    }
	let url = ServiceUrls.VIDEO_SEARCH_VIDEO_URL + '?token=' + token;
	
	console.log(data);
	return this.http.post(url,  new ThumbnailRequest(data)).	
	pipe(
		map(response => {
			var modified = JSON.parse(JSON.stringify(response));
			return new WsResponse(
				modified.status.description,
				modified.status.name,
				modified.status.code,
				modified.payload);
		}),
		catchError(error => {
			var modified = JSON.parse(JSON.stringify(error));
			var res = new WsResponse(
				modified.error.status.description,
				modified.error.status.name,
				modified.error.status.code,
				modified.error.payload);
			return throwError(res)
		})
	);
}
getPosterframeURL(data: any) {
	let token = UserVariable.getUserToken();
	if(!this.commonUtil.validateToken(token)){
		return;
	}
	let url = ServiceUrls.VIDEO_SEARCH_VIDEO_POSTERFRAME + '?token=' + token;
	return this.http.post(url, new PosterframeRequest(data)).
		pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.name,
					modified.status.code,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error));
				var res = new WsResponse(
					modified.error.status.description,
					modified.error.status.name,
					modified.error.status.code,
					modified.error.payload);
				return throwError(res)
			})
		);
}
getS3Content(callBack:WsCallback,version:string,id:string,type:string) {
	let token=UserVariable.getUserToken();
	const options={params:new HttpParams().set("version", version).set("id",id)}
	var url;
	if (type == 'Video'){ 
	url=ServiceUrls.VIDEOS + id + "/getVideoContent"
	} else if (type == 'ThreeDModel' ){
		url = ServiceUrls.THREED_MODELS + id + "/getThreeDModelContent"
	}
	return this.http.get(url,  options).	
	pipe(
		map(response => {
			var modified = JSON.parse(JSON.stringify(response));
			return new WsResponse(
				modified.status.description,
				modified.status.name,
				modified.status.code,
				modified.payload);
				
		}),
		catchError(error => {
			var modified = JSON.parse(JSON.stringify(error));
			var res = new WsResponse(
				modified.error.status.description,
				modified.error.status.name,
				modified.error.status.code,
				modified.error.payload);
			return throwError(res)
		})
	);
}

getFileDownloadDataByFileId(fileId: any) {
	let token = UserVariable.getUserToken();
	if (!this.commonUtil.validateToken(token)) {
		return;
	}
	const options = { params: new HttpParams().set("token", token).set("id", fileId) }
	return this.http.get(ServiceUrls.FILE_DOWNLOAD_INFO, options).
		pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
		);
}

getFileDataByFolderId(fileData: any, callBack: WsCallback) {
	let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
        return;
	}
	const options = { params: new HttpParams().set("token", token) }
	let folderId = fileData.folderId;
	let url = ServiceUrls.FOLDERS+ folderId + '.json';
	this.http.get(url, options).
			subscribe(
				data => {
					var modified = JSON.parse(JSON.stringify(data));
					var res = new WsResponse(
						modified.status.code,
						modified.status.name,
						modified.status.description,
						modified.payload);
					// console.log("response " + JSON.stringify(data));
					callBack.onSuccess(res, WsType.FILE_DATA_FOLDER_PATH);
				},
				error => {
					var modified = JSON.parse(JSON.stringify(error));
					var res = new WsResponse(
						modified.status.code,
						modified.status.name,
						modified.status.description,
						modified.payload);
					callBack.onFail(res, WsType.FILE_DATA_FOLDER_PATH);
				});
}

	/**
	 * Get procedure info for selected pdf
	 * @param procedureId procedureId/ pdf id
	 */
	public getSelectedProcedureInfo(procedureId: string) {
		let token=UserVariable.getUserToken();
		if (!this.commonUtil.validateToken(token)) {
			return;
		}
		return this.http.get(ServiceUrls.getSelectedProcedureInfo(token, procedureId)).pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);					
				return throwError(res);
				} )
		);
	}

	/**
	 * Get store document info for selected pdf
	 * @param documentId documentId/ pdf id
	 */
	public getSelectedStoreDocumentInfo(documentId: string) {
		let token=UserVariable.getUserToken();
		if (!this.commonUtil.validateToken(token)) {
			return;
		}
		return this.http.get(ServiceUrls.getSelectedStoreDocumentInfo(token, documentId)).pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);					
				return throwError(res);
				} )
		);
	}

// restoreVideoRevision(itemId: any, itemType: any, cmRevisionNo: any) {
// 	let token = UserVariable.getUserToken();
// 		let selectedFolderData = this.folderService.getSelectedFoldeData();
// 		const options = { params: new HttpParams().set("token", token).set("itemType", itemType).set("revisionNo", cmRevisionNo).set("folderId", selectedFolderData.id) }
// 		let url = ServiceUrls.VIDEOS + itemId + "/doVideoVersionRestore"
// 		this.http.get(url, options).
// 			subscribe(
// 				data => {

// 					var modified = JSON.parse(JSON.stringify(data));
// 					var res = new WsResponse(
// 						modified.status.description,
// 						modified.status.code,
// 						modified.status.name,
// 						modified.payload);
// 					this.callback.onSuccess(res, WsType.RESTORE_VIDEO_REVISION);
// 				},
// 				error => {
// 					var val = (error as HttpErrorResponse).error;
// 					var modified = JSON.parse(JSON.stringify(val));
// 					var res = new WsResponse(
// 						modified.status.description,
// 						modified.status.code,
// 						modified.status.name,
// 						modified.payload);
// 					this.callback.onFail(res, WsType.RESTORE_VIDEO_REVISION);
// 				});
// }

/**
* get Procedure Template Id
 */
public getProcedureTemplateId(inputData: any, callBack: WsCallback) {
    let token = UserVariable.getUserToken();
    if(!this.commonUtil.validateToken(token)){
        return;
    }
	inputData['userToken'] = token
	const options = { params: new HttpParams().set("token", token).set("item", inputData.item).set("itemId", inputData.itemId).set("outputType", inputData.outputType).set("documentType", inputData.documentType)}	
    let url = this.getProcedureTemplateIdURL(token);
	this.http.get(url, options).subscribe(
		data => {
			var modified = JSON.parse(JSON.stringify(data));
			var res = new WsResponse(
				modified.status.description,
				modified.status.code,
				modified.status.name,
				modified.payload);
			callBack.onSuccess(res, WsType.GET_PROCEDURE_PDF_TEMPLATE);
		},
		error => {
			var val = (error as HttpErrorResponse).error;
			var modified = JSON.parse(JSON.stringify(val));
			var res = new WsResponse(
				modified.status.description,
				modified.status.code,
				modified.status.name,
				modified.payload);
			callBack.onFail(res, WsType.GET_PROCEDURE_PDF_TEMPLATE);
		});
	}

	/**
	* get Procedure Template Id
	*/
	public getStoreDocumentTemplateId(inputData: any, callBack: WsCallback) {
		let token = UserVariable.getUserToken();
    if(!this.commonUtil.validateToken(token)){
        return;
    }
	inputData['userToken'] = token
	const options = { params: new HttpParams().set("token", token).set("item", inputData.item).set("itemId", inputData.itemId).set("outputType", inputData.outputType).set("documentType", inputData.documentType)}	
    let url = this.getDocumentStoreTemplateIdURL(token);
	this.http.get(url, options).subscribe(
		data => {
			var modified = JSON.parse(JSON.stringify(data));
			var res = new WsResponse(
				modified.status.description,
				modified.status.code,
				modified.status.name,
				modified.payload);
			callBack.onSuccess(res, WsType.GET_DOCUMENT_STORE_PDF_TEMPLATE);
		},
		error => {
			var val = (error as HttpErrorResponse).error;
			var modified = JSON.parse(JSON.stringify(val));
			var res = new WsResponse(
				modified.status.description,
				modified.status.code,
				modified.status.name,
				modified.payload);
			callBack.onFail(res, WsType.GET_DOCUMENT_STORE_PDF_TEMPLATE);
		});
	}

	/**
     * Get Latest Version By  item id and item type.
     * @param itemId Item Id
     * @param itemType Item type
     */
    getLatestVersionByItemIdAndType(itemId: String, itemType: String) {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
		let url = this.getLatestVersionByItemIdAndTypeURL(token);
        return this.http.post(url, JSON.stringify(new CMRequest(itemId, itemType))).
            pipe(
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                }),
                catchError(error => {
                    var modified = JSON.parse(JSON.stringify(error.error));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    return throwError(res)
                })
            );
    }
	
	public getProcedureTemplateIdURL(token: String) {
		let url = ServiceUrls.GET_TEMPLATES_FOR_PROCEDURE_CONTENT_ITEMS + '?token=' + token;
		return url;
	}
	public getDocumentStoreTemplateIdURL(token: String) {
		let url = ServiceUrls.GET_TEMPLATES_FOR_DOCUMENT_STORE_CONTENT_ITEMS + '?token=' + token;
		return url;
	}
	
	public getLatestVersionByItemIdAndTypeURL(token: String) {
		let url =  ServiceUrls.CM_SYSTEM + 'getLatestVersionByItemIdAndType.json?token=' + token;
		return url;
	}
}


class CMRequest {
    constructor(private itemId: String, private itemType: String) { }
}

class Request {
	constructor(private name: String, private checkoutBy: String, private lastUpdateTimeStampStr: String, private type: String, private hasText: String) { }
}

class ICECourseRequest {
	constructor(private id: String, private courseVersion: String) { }
}

class ThumbnailRequest{
	constructor(private id: any){}
}

class PosterframeRequest{
	constructor(private id: any){}
}

