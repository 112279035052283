import { Directive, HostListener } from '@angular/core';

@Directive({
	selector: "[prevent-parent-click]"
})
export class PreventParentClickEvent {
	@HostListener('click', ['$event'])
	public onClick(event: any): void {
		event.stopPropagation()
	}

	@HostListener('valueChanged', ['$event'])
	public onValueChanged(event: any): void {
		event.stopPropagation()
	}
}
