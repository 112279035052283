<div class="front">
	<!-- <button id="landing-table-buttons" class="btn btn-sm btn-outline-light py-0 my-1 px-1 border-0" (click)="invoke()" > -->

		<img  *ngIf="loadedThumb" class="card-img-top img-fluid pointer-cursor" style= "width: 100px; height: 100px;" src="data:image/jpeg;base64,{{base64thumb}}" 
		(click)="showLargeImage(largeImage)" alt="{{title}}">

		

		<div *ngIf="!loadedThumb" class="spinner-border  text-center" role="status">
			<span class="sr-only"> {{ 'REPORTS.REPORT_THUMB_LOADING' | translate }}</span>
		</div>

	<!-- </button> -->

</div>


<ng-template #largeImage>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{ title }}</h4>
        <button type="button" id="image_close" class="close pull-right" aria-label="Close" (click)="modelRefCloseEvent()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div  class="modal-body">
        <div class="card p-0">
            <img *ngIf="loadedImage" class="card-img-top" src="data:image/jpeg;base64,{{base64FullImage}}" alt="{{title}}">

            <div *ngIf="!loadedImage" class="alert alert-warning" role="alert">
                {{ 'REPORTS.REPORT_IMAGE_LOADING' | translate }}
            </div>
		</div>
		

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-secondary" (click)="modelRefCloseEvent()">{{
                'IMAGE_SEARCH_VIEW.BTN_TEXT_CLOSE' | translate }}</button>
        <!-- <button type="button" class="btn btn-sm btn-primary" (click)="insertAsFigure(selectedImage)">{{
                'IMAGE_SEARCH_VIEW.BTN_TEXT_INSERT_AS_FIGURE' |
                translate }}</button>
        <button type="button" class="btn btn-sm btn-primary" (click)="insertAsImage(selectedImage)">{{
                'IMAGE_SEARCH_VIEW.BTN_TEXT_INSERT_AS_IMAGE' |
                translate }}</button> -->
    </div>
</ng-template>