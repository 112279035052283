<!-- <alert class="floating-alert"></alert> -->
<div class="row">
	<div class="col-sm-12">
		<!-- <div class="col-sm-11 float-left" *ngIf="showDetailPanel">
            <app-files-data [inputData]="inputData"></app-files-data>
        </div>*ngIf="!showDetailPanel" -->
		<div class="btn-toolbar float-left my-1" *ngIf="!multipleSelections">
			<!-- section-1 -->
			<div class="btn-group btn-group-sm mr-2" role="group" aria-label="first_section">
				<button *ngIf="selectedFile != null" container="body" tooltip="{{ 'FILES_DATA.CHECK_IN' | translate }}"
					id="checkin" type="button" [disabled]="disable || !enableCheckIn" class="btn btn-outline-primary"
					(click)="openCheckinModal(selectedFile.type, selectedFile.id)" [triggers]="triggersType">
					<i class="fas fa-download" aria-hidden="true"></i>
				</button>
				<button tooltip="{{ 'FILES_DATA.CHECK_OUT' | translate }}" container="body" id="checkout" type="button"
					[disabled]="disable || !enableCheckOut" class="btn btn-outline-primary"
					(click)="checkoutBtnEvent()" [triggers]="triggersType">
					<i class="fas fa-upload" aria-hidden="true"></i>
				</button>
				<button type="button" tooltip="{{ 'FILES_DATA.UNDO_CHECK_OUT' | translate }}" container="body"
					id="undoCheckout" [disabled]="disable || !enableUndo" class="btn btn-outline-primary"
					(click)="openModalUndoCheckoutConfirm()" [triggers]="triggersType">
					<i class="far fa-undo-alt" aria-hidden="true"></i>
				</button>
			</div>
			<!-- section-2 -->
			<div class="btn-group btn-group-sm mr-2" role="group" aria-label="second_section">
				<button type="button" tooltip="{{activeBtnToolTip}}" container="body" id="activate"
					[disabled]="disable || !enableStatus" class="btn btn-outline-primary" (click)="activeBtnEvent()"
					[triggers]="triggersType">
					<i *ngIf="enableStatus && !isFileActive" class="fas fa-times" aria-hidden="true"></i>
					<i *ngIf="enableStatus && isFileActive" class="fas fa-check" aria-hidden="true"></i>
					<i *ngIf="!enableStatus" class="fas fa-check" aria-hidden="true"></i>
				</button>
				<button type="button" tooltip="{{ 'FILES_DATA.DELETE' | translate }}" container="body" id="delete"
					 class="btn btn-outline-primary" (click)="deleteBtnEvent()"
					[triggers]="triggersType" [disabled]="disable || !enableDelete">
					<i class="fas fa-trash-alt" aria-hidden="true"></i> 
				</button>
			</div>
			<!-- section-3 -->
			<div class="btn-group btn-group-sm mr-2" role="group" aria-label="third_section"> <!--[disabled]="disable || !enableDuplicate"-->
				<button type="button" tooltip="{{ 'FILES_DATA.DUPLICATE_FILES' | translate }}" container="body"
					id="duplicate"  class="btn btn-outline-primary" [disabled]="disable || !enableDuplicate"
					(click)="duplicateBtnEvent()" [triggers]="triggersType">
					<i class="fas fa-copy" aria-hidden="true"></i>
				</button>
				<button tooltip="{{ 'FILES_DATA.GENERATE_OUTPUT' | translate }}" container="body" id="generateOp"
					[disabled]="disable || !enableGenerateOP || !enableGenerateOutput" type="button" class="btn btn-outline-primary"
					(click)="generateOutputBtnEvent()" [triggers]="triggersType">
					<i class="fas fa-forward" aria-hidden="true"></i>
				</button>
				<!--button *ngIf="showDonload" tooltip="{{ 'FILES_DATA.DOWNLOAD' | translate }}" id="downloadFile"
					type="button" [disabled]="disable" class="btn btn-outline-primary" container="body"
					(click)="downloadFileBtnEvent()">
					<i class="fas fa-file-download" aria-hidden="true"></i>
				</button-->
				<!-- <button *ngIf="enableAddToProcStore" tooltip="{{ 'PDF_ADD_TO_PROCEDURE_STORE.ADD_TO_PROCEDURE_STORE' | translate }}" container="body" id="addToProcStore"
					[disabled]="disable || !isFileActive" type="button" class="btn btn-outline-primary"
					(click)="addToProcStoreBtnEvent()" [triggers]="triggersType">
					<i class="fas fa-list-alt" aria-hidden="true"></i>
				</button> -->
				<button *ngIf="enableAddToDocumentStore" tooltip="{{ 'PDF_ADD_TO_DOCUMENT_STORE.ADD_TO_DOCUMENT_STORE' | translate }}" container="body" id="addToDocumentStore"
					[disabled]="disable || !isFileActive" type="button" class="btn btn-outline-primary"
					(click)="addToDocumentStoreBtnEvent()" [triggers]="triggersType">
					<i class="fas fa-list-alt" aria-hidden="true"></i>
				</button>
			</div>

			<!-- section-4 -->
			<div class="btn-group btn-group-sm mr-2" role="group" aria-label="fourth_section">
					<button type="button" tooltip="{{ 'FILES_DATA.MOVE_FILE' | translate }}" container="body"
						id="moveFile" [disabled]="disable || !enableMoveFile" class="btn btn-outline-primary"
						(click)="moveFileEvent()" [triggers]="triggersType">
						<i class="fal fa-arrows" aria-hidden="true"></i>
					</button>
			</div>
			

			<!-- section-5 -->
			<div class="btn-group btn-group-sm mr-2" role="group" aria-label="fifth_section">
				<!-- in future topic map build button function will move to edit button so remove topic map build button -->
				<!-- <button *ngIf="showTopicMap" tooltip="{{ 'FILES_DATA.BUILD_TOPIC_Map' | translate }}" id="buildTopicMap"
					type="button" [disabled]="disable" class="btn btn-outline-primary" container="body"
					(click)="buildTopicMap()">
					<i class="fas fa-code-merge" aria-hidden="true"></i>
				</button> -->
				<div class="btn-group btn-group-sm" tooltip="{{ 'FILES_DATA.EDIT' | translate }}">
					<button type="button" id="edit"
						[disabled]="disable || !enableEdit" class="btn btn-outline-primary" container="body"
						(click)="editBtnEvent()" [triggers]="triggersType">
						<i class="fas fa-file-edit" aria-hidden="true"></i>
					</button>
				</div>
				<div class="btn-group btn-group-sm" tooltip="{{ 'FILES_DATA.VIEW' | translate }}">
					<button type="button" id="viewTopicMap"
						[disabled]="!enableView" class="btn btn-outline-primary" container="body" (click)="viewTopicMap()"
						[triggers]="triggersType">
						<i class="fas fa-eye" aria-hidden="true"></i>
					</button>
				</div>
			</div>
			<!-- section-6 -->
			<div class="btn-group btn-group-sm" role="group" aria-label="sixth_section">
				<button type="button" tooltip="{{ 'FILES_DATA.REVISIONS' | translate }}" id="revisions"
					[disabled]="disable || !enableRevision" class="btn btn-outline-info" container="body"
					(click)="showRevisionBtnEvent()" [triggers]="triggersType" >
					<i class="fas fa-scroll-old" aria-hidden="true"></i>
				</button>
				<button type="button" id="propertiesAndAttributes" container="body"
					tooltip="{{ 'FILES_DATA.PROPERTIES_AND_ATTRIBUTES' | translate }}"
					[disabled]="disable || !enableProperties" class="btn btn-outline-info"
					(click)="showPropertiesAttributesBtnEvent()" [triggers]="triggersType">
					<i class="fas fa-info mx-1" aria-hidden="true"></i>
				</button>
			</div>

			<!-- still not implemented workflow action functinality on the systems  -->
			<!-- <button tooltip="{{ 'FILES_DATA.WORKFLOW_ACTION' | translate }}" id="workflowAction"
				[disabled]="disable || !enableWorkFlow" type="button" class="btn btn-outline-primary btn-sm ml-1 mr-1 mb-1">
				<i class="fas fa-project-diagram" aria-hidden="true"></i>
			</button> -->
		</div>

		<div class="btn-toolbar float-left my-1" *ngIf="multipleSelections">
			<!-- section-1 -->
			<div class="btn-group btn-group-sm mr-2" role="group" aria-label="first_section" >
				<button type="button" id="checkinAll" tooltip="{{ 'FILES_DATA.CHECKIN_ALL' | translate }}"
					(click)="openModal(checkinAllTemplate, 'lg')" class="btn btn-outline-primary" container="body"
					[disabled]="allImages || allVideos || all3Dmodels">
					<i class="fa fa-download" aria-hidden="true"></i>
				</button>
				<button type="button" id="checkoutAll" tooltip="{{ 'FILES_DATA.CHECKOUT_ALL' | translate }}"
					(click)="checkoutAll()" class="btn btn-outline-primary" container="body">
					<i class="fa fa-upload" aria-hidden="true"></i>
				</button>
				<button type="button" id="undoCheckoutAll" tooltip="{{ 'FILES_DATA.UNDO_CHECKOUT_ALL' | translate }}"
					(click)="undoCheckoutAll()" class="btn btn-outline-primary" container="body">
					<i class="fa fa-undo" aria-hidden="true"></i>
				</button>
			</div>
			<!-- section-2 -->
			<div class="btn-group btn-group-sm mr-2" role="group" aria-label="second_section">
				<button type="button" id="enableAll" tooltip="{{ 'FILES_DATA.ENABLE_ALL' | translate }}"
					(click)="openMultipleConfirmationModal('enable', 'lg')" class="btn btn-outline-primary" container="body">
					<i class="fa fa-check-circle" aria-hidden="true"></i>
				</button>
				<button type="button" id="disableAll" tooltip="{{ 'FILES_DATA.DISABLE_ALL' | translate }}"
					(click)="openMultipleConfirmationModal('disable', 'lg')" class="btn btn-outline-primary" container="body">
					<i class="fa fa-times-circle" aria-hidden="true"></i>
				</button>
				<button type="button" id="deleteAll" tooltip="{{ 'FILES_DATA.DELETE_ALL' | translate }}"
					(click)="openMultipleConfirmationModal('delete', 'lg')" class="btn btn-outline-primary" container="body">
					<i class="fas fa-trash-alt" aria-hidden="true"></i>
				</button>
			</div>
			<!-- section-3 -->
			<div class="btn-group btn-group-sm mr-2" role="group" aria-label="third_section">
				<button type="button" id="duplicateAll" tooltip="{{ 'FILES_DATA.DUPLICATE_ALL' | translate }}"
					(click)="duplicateAll()" class="btn btn-outline-primary" container="body">
					<i class="fas fa-copy" aria-hidden="true"></i>
				</button>
			</div>

			<!-- section-4 -->
			<div class="btn-group btn-group-sm mr-2" role="group" aria-label="forth_section">
				<button type="button" id="moveFiles"
					tooltip="{{ 'FILES_DATA.MOVE_ALL' | translate }}" container="body"
					(click)="moveFileEvent()" class="btn btn-outline-primary ">
					<i class="fas fa-file-export" aria-hidden="true"></i>
				</button>
			</div>

			<!-- section-5 -->
			<div class="btn-group btn-group-sm mr-2" role="group" aria-label="fifth_section">
				<button type="button" id="checkinMultipleImages"
					tooltip="{{ 'FILES_DATA.CHECKIN_MULTIPLE_IMAGES' | translate }}" container="body"
					(click)="openModal(getMultipleImagesExistanceTemplate, 'lg')" class="btn btn-outline-primary ">
					<i class="far fa-images" aria-hidden="true"></i>
				</button>
			</div>
			<div class="btn-group btn-group-sm mr-2" role="group" aria-label="fifth_section">
				<button type="button" id="checkinMultipleThreeDModels"
					tooltip="{{ 'FILES_DATA.CHECK_IN_MULTIPLE_THREEDMODELS' | translate }}" container="body"
					(click)="openModal(checkinMultipleThreeDModelTemplate, 'lg')" class="btn btn-outline-primary ">
					<i class="fa fa-cubes" aria-hidden="true"></i>
				</button>
			</div>
			<div class="btn-group btn-group-sm mr-2" role="group" aria-label="fifth_section">
				<button type="button" id="checkinMultipleVideos"
					tooltip="{{ 'FILES_DATA.CHECK_IN_MULTIPLE_VIDEOS' | translate }}" container="body"
					(click)="openModal(checkinMultipleVideoTemplate, 'lg')" class="btn btn-outline-primary ">
					<i class="fa fa-desktop" aria-hidden="true"></i>
				</button>
			</div>
			<!-- still not implemented workflow action functinality on the systems  -->
			<!-- <button type="button" id="workflowActionAll" tooltip="{{ 'FILES_DATA.WORKFLOW_ACTION_ALL' | translate }}"
				(click)="workFlowActionAll()" class="btn btn-outline-primary btn-sm ml-1 mr-1">
				<i class="fa fa-link" aria-hidden="true"></i>
			</button> -->
		</div>
		<ngc-float-button *ngIf="addFileShow" class="mb-2" id="files_floating_button" icon="add" direction="bottom" color="#e59e2e">
			<ngc-float-item-button id="files_topic_map" icon="map"
				[content]="'file.floating_buttons.new_topic_map' | translate" (click)="addNewTopicMap()">
			</ngc-float-item-button>
			<ngc-float-item-button id="files_topic" icon="inbox"
				[content]="'file.floating_buttons.new_topic' | translate" (click)="addNewTopic()">
			</ngc-float-item-button>
			<ngc-float-item-button id="files_file" icon="dehaze"
				[content]="'file.floating_buttons.new_file' | translate" (click)="addNewFile()"></ngc-float-item-button>
			<ngc-float-item-button id="files_image" icon="image"
				[content]="'file.floating_buttons.new_image' | translate" (click)="addNewImage()">
			</ngc-float-item-button>
			<ngc-float-item-button id="files_procedure" icon="layers"
				[content]="'file.floating_buttons.new_procedure' | translate" (click)="addNewProcedure()">
			</ngc-float-item-button>
			<ngc-float-item-button id="files_course" icon="school"
				[content]="'file.floating_buttons.new_course' | translate" (click)="addNewCourse()">
			</ngc-float-item-button>
			<ngc-float-item-button id="files_multiple_images" icon="image_aspect_ratio "
				[content]="'file.floating_buttons.new_multiple_images' | translate" (click)="addNewMultipleImages()">
			</ngc-float-item-button>
			<ngc-float-item-button id="files_new_threed_model" icon="3d_rotation" 
			[content]="'file.floating_buttons.new_threed_model' | translate" (click)="addNewThreeDModel()">    
			</ngc-float-item-button>
			<ngc-float-item-button id="files_new_videos" icon="desktop_windows" 
			[content]="'file.floating_buttons.new_videos' | translate" (click)="addNewVideo()">    
            </ngc-float-item-button>
		<!--
			<ngc-float-item-button id="files_video"  icon="school"
			[content]="'file.floating_buttons.new_videos' | translate" (click)="addNewvideo()" >
			</ngc-float-item-button>

		-->	

		</ngc-float-button>
	</div>
	<!-- properties and attributes panel -->
	<div class="col-sm-12" *ngIf="showPropertiesPanel">
		<div class="panel panel-default">
			<div class="panel-heading panel-header-border-bottom py-1">
				<div class="row px-2">
					<div class="col-sm-12 px-0" (click)="isMinimized = !isMinimized">
						<div class="float-left pt-1 px-1">
							<p class="m-0 pl-1"><b>{{ 'FILES_DATA.PROPERTIES_AND_ATTRIBUTES' | translate }}</b>
							</p>
						</div>
						<div class="float-right pt-1 px-0">
							<button type="button"
								class="btn btn-sm btn-outline-dark float-right pl-1 pr-1 pt-0 pb-0 ml-1 mr-1 mb-1 pointer-cursor border-0"
								(click)="$event.stopPropagation(); showPropertiesAttributesBtnEvent();">
								<i class="fa fa-times" aria-hidden="true"></i>
							</button>
							<button type="button"
								class="btn btn-sm btn-outline-dark float-right pl-1 pr-1 pt-0 pb-0 ml-1 mr-1 mb-1 pointer-cursor border-0"
								(click)="$event.stopPropagation(); minimizePropertiesAttributesBtnEvent();">
								<i class="fa " aria-hidden="true"
									[ngClass]="{'fa-chevron-down': isMinimized, 'fa-chevron-up': !isMinimized}"></i>
							</button>
						</div>
					</div>
				</div>
			</div>
			<div class="card-block card-body mt-1 mb-4 py-1 card-primary"
				[ngClass]="{'content-hide': isMinimized, 'content-show': !isMinimized}">
				<tabset [justified]="true">
					<tab heading="{{ 'FILES_DATA.PROPERTIES' | translate }}" id="tab1">
						<app-files-properties [fileData]="selectedFile"></app-files-properties>
					</tab>
					<tab *ngIf="selectedFile.type!='ICECourse'" heading="{{ 'FILES_DATA.ATTRIBUTES' | translate }}">
						<app-files-attributes [fileData]="selectedFile"></app-files-attributes>
					</tab>
				</tabset>
				<!-- <ngb-tabset [justify]="'justified'" (tabChange)="change($event)">
					<ngb-tab id="properties">
						<ng-template *ngIf="activeId =='properties'" ngbTabTitle>
							<span class="select-tab-title">{{ 'FILES_DATA.PROPERTIES' | translate }}</span>
						</ng-template>
						<ng-template *ngIf="activeId != 'properties'" ngbTabTitle>
							<span class="tab-title">{{ 'FILES_DATA.PROPERTIES' | translate }}</span>
						</ng-template>
						<ng-template ngbTabContent>
							<app-files-properties [fileData]="selectedFile"></app-files-properties>
						</ng-template>
					</ngb-tab>
					<ngb-tab id="attributes">
						<ng-template *ngIf="activeId=='attributes'" ngbTabTitle>
							<span class="select-tab-title">{{ 'FILES_DATA.ATTRIBUTES' | translate }}</span>
						</ng-template>
						<ng-template *ngIf="activeId!='attributes'" ngbTabTitle>
							<span class="tab-title">{{ 'FILES_DATA.ATTRIBUTES' | translate }}</span>
						</ng-template>
						<ng-template ngbTabContent>
							<app-files-attributes [fileData]="selectedFile"></app-files-attributes>
						</ng-template>
					</ngb-tab>
				</ngb-tabset> -->
			</div>
		</div>
	</div>
	<div class="col-sm-12" [style.height.px]="divHeight">
		<ag-grid-angular #agGrid style="width: 100%;" [style.height.px]="divHeight" class="ag-theme-balham" 
		(cellClicked)='onCellClicked($event)'
			[enableFilter]="true" [rowData]="rowData" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }" [columnDefs]="columnDefs"
			[rowSelection]="'multiple'"  [rowMultiSelectWithClick]="false" [defaultColDef]="defaultColDef"
			(rowSelected)="onRowSelected($event)"  [getRowNodeId]="getRowNodeId"
			(gridReady)="onGridReady($event)" (lastChildChanged)="onlastChildChanged($event)"
			[rowSelection]="rowSelection">
		</ag-grid-angular>
	</div>
</div>

<!-- checkin comments modal -->
<ng-template #checkingTemplate>
	<div class="modal-header">
		<h4 class="modal-title pull-left">{{ 'FILES_DATA.CHECK_IN' | translate}}</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<label>{{ 'FILES_DATA.COMMENTS' | translate}}</label>
		<textarea class="form-control" #comments></textarea>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="checkIn(comments.value);bsModalRef.hide()">
			{{ 'FILES_DATA.OK' | translate}}</button>
		<button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="bsModalRef.hide()">
			{{ 'FILES_DATA.CANCEL' | translate}}
		</button>
	</div>
</ng-template>

<!-- checkin warning modal -->
<ng-template #checkinWarningTemplate>
	<div class="modal-header">
		<h4 class="modal-title pull-left">{{ 'FILES_DATA.WARNING' | translate}}</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<label>{{ 'FILES_DATA.WARNING_MESSAGE' | translate}}</label>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="editBtnEvent();bsModalRef.hide()">
			{{ 'FILES_DATA.YES' | translate}}</button>
		<button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="bsModalRef.hide()">
			{{ 'FILES_DATA.NO' | translate}}
		</button>
	</div>
</ng-template>

<!-- course checkin warning modal(added temporarily) -->
<ng-template #checkinCouseWarningTemplate>
	<div class="modal-header">
		<h4 class="modal-title pull-left">{{ 'FILES_DATA.WARNING' | translate}}</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<label>{{ 'FILES_DATA.WARNING_MESSAGE_ICECOURSE' | translate}}</label>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="editBtnEvent();bsModalRef.hide()">
			{{ 'FILES_DATA.YES' | translate}}</button>
		<button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="bsModalRef.hide()">
			{{ 'FILES_DATA.NO' | translate}}
		</button>
	</div>
</ng-template>

<!-- checkin all warning modal -->
<ng-template #checkinAllWarningTemplate>
	<div class="modal-header">
		<h4 class="modal-title pull-left">{{ 'FILES_DATA.WARNING' | translate}}</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<label>{{ 'FILES_DATA.WARNING_MESSAGE_CHECKIN_ALL' | translate}}</label>
	</div>
	<div class="modal-body">
		<div class="mb-3" >
			<table border="1" width="50%">
				<tr>
					<th>File Name</th>
					<th>Type</th>
				</tr>				
				<tr *ngFor="let item of selectedOpenedItems">
					<td> {{ item.fileName }} </td>
					<td> {{ item.type }} </td>
				</tr>
		</table>
		</div>		
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="ok();bsModalRef.hide()">
			{{ 'FILES_DATA.OK' | translate}}</button>
	</div>
</ng-template>

<ng-template #contentTemplate>
	<div class="modal-header">
		<!--Title-->
		<h4 class="modal-title pull-left">{{ 'NEW_FILE.CREATE_NEW_FILE' | translate }}</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>

	<!--Body-->
	<div class="modal-body">
		<form ngNativeValidate id="new_file_form">
			<!--File upload-->
			<div class="form-group">
				<label for="NewFile_FileUpload">{{ 'NEW_FILE.USER_FILE.NAME' | translate }}</label>
				<input type="file" class="form-control-file" id="NewFile_FileUpload"
					accept=".doc, .docx, .ppt, .pptx, .pdf" (change)="onFileChanged($event)" required>
				<small id="emailHelp"
					class="form-text text-muted">{{ 'NEW_FILE.USER_FILE.MESSAGE' | translate }}</small>
			</div>

			<!--Title-->
			<div class="form-group">
				<label for="NewFile_Title">{{ 'NEW_FILE.TITLE.NAME' | translate }}</label>
				<input type="text" class="form-control" id="NewFile_Title" [(ngModel)]="uploadFileName" name="docTitle"
					aria-describedby="title" placeholder="{{ 'NEW_FILE.TITLE.PLACE_HOLDER' | translate }}" required>
			</div>

			<!--User comments-->
			<div class="form-group">
				<label for="NewFile_Comments">{{ 'NEW_FILE.COMMENTS.NAME' | translate }}</label>
				<textarea type="text" class="form-control" id="NewFile_Comments" name="newFile"
					[(ngModel)]="fileComment" aria-describedby="comments"
					placeholder="{{ 'NEW_FILE.COMMENTS.PLACE_HOLDER' | translate }}"></textarea>
			</div>

			<!--Submit Button-->
			<div class="form-group row float-right mr-0">
				<button type="submit" class="btn btn-primary"
					(click)="onSubmitButtonClick('contentTemplate')">{{ 'SUBMIT' | translate }}</button>
			</div>

		</form>
	</div>

</ng-template>

<!-- revisions modal -->
<ng-template #revisionTemplate>
	<div class="modal-header">
		<h4 class="modal-title pull-left">{{ 'FILES_DATA.REVISIONS' | translate}}</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<app-files-revisions (closeRevision)="bsModalRef.hide()"></app-files-revisions>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="bsModalRef.hide(); resetSharedServiceParent()">
			{{ 'FILES_DATA.CANCEL' | translate}}
		</button>
	</div>
</ng-template>

<!-- checkin All comments modal -->
<ng-template #checkinAllTemplate>
	<div class="modal-header">
		<h4 class="modal-title pull-left">{{ 'FILES_DATA.CHECK_IN' | translate}}</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<label>{{ 'FILES_DATA.COMMENTS' | translate}}</label>
		<textarea class="form-control" #comments></textarea>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="checkinAll(comments.value)">
			{{ 'FILES_DATA.OK' | translate}}</button>
		<button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cancel()">{{ 'FILES_DATA.CANCEL'
			| translate}}</button>
	</div>
</ng-template>

<!-- get Images Existance modal -->
<ng-template #getMultipleImagesExistanceTemplate>
	<div class="modal-header">
		<h4 class="modal-title pull-left">{{ 'FILES_DATA.CREAT_IMAGES' | translate}}</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="cancel()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div>
			<label>{{ 'FILES_DATA.CREAT_IMAGES' | translate}}</label>
			<input #image type="file" ng2FileSelect [uploader]="uploader" multiple
				accept="image/png,image/gif,video/mp4,image/jpeg"
				(change)="handleImageFileInput($event.target.files)" /><br />
		</div>
		<label>{{ 'FILES_DATA.COMMENTS' | translate}}</label>
		<textarea class="form-control" #comments></textarea>
		<label>{{ 'FILES_DATA.ATTRIBUTES' | translate}}</label>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="getImageExistence(comments.value)">
			{{ 'FILES_DATA.CONTINUE' | translate}}</button>
		<button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cancel()">{{ 'FILES_DATA.CANCEL'
			| translate}}</button>
	</div>
</ng-template>

<!-- checkin Multiple Images modal -->
<!--ng-template #checkinMultipleImagesTemplate>
	<div class="modal-header">
		<h4 class="modal-title pull-left">{{ 'FILES_DATA.MULTIPLE_IMAGES' | translate}}</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div>
			<label>You can only checkin the images that exist in the system.</label>
		</div>
		<table id="classTable" class="table table-bordered">
			<thead>
				<th>{{ 'FILES_DATA.FILE_NAME' | translate}}</th>
				<th>{{ 'FILES_DATA.FILE_STATUS' | translate}}</th>
			</thead>
			<tbody *ngFor="let data of rowData">
				<tr>
					<td>{{data.fileName}}</td>
					<td>{{data.fileStatus}}</td>
				</tr>
			</tbody>
		</table>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="checkinMultipleImages()">
			{{ 'FILES_DATA.CHECK_IN' | translate}}</button>
		<button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cancel()">{{ 'FILES_DATA.CANCEL'
			| translate}}</button>
	</div>
</ng-template-->

<!-- Multiple Action Alert modal -->
<ng-template #alertToShowMultipleActions>
	<div class="modal-header">
		<h4 class="modal-title pull-left">{{ 'FILES_DATA.ALERT' | translate}}</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="ok();bsModalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="mb-3" *ngFor="let heading of headings; let i = index">
			<h5 class="text-center">{{heading}}</h5>
			<div *ngFor="let item of items[i]; let j = index">
				<li [ngStyle]="{'color': itemStatus[i][j] == 0 ? 'red' : 'black'}">{{item}}</li>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="ok();bsModalRef.hide()">
			{{ 'FILES_DATA.OK' | translate}}</button>
	</div>
</ng-template>

<!-- Mulitple Action Confirmation modal -->
<ng-template #multipleActionConfirmationTemplate>
	<div class="modal-header">
		<h4 *ngIf="multipleConfirmMessage=='delete'" class="modal-title pull-left">{{ 'MULTIPLE_ACTION.DELETE' | translate}}</h4>
		<h4 *ngIf="multipleConfirmMessage=='disable'" class="modal-title pull-left">{{ 'MULTIPLE_ACTION.DISABLE' | translate}}</h4>
		<h4 *ngIf="multipleConfirmMessage=='enable'" class="modal-title pull-left">{{ 'MULTIPLE_ACTION.ENABLE' | translate}}</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide();multipleConfirmMessage=undefined;">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="multipleActionSelection();bsModalRef.hide();">
			{{ 'FILES_DATA.OK' | translate}}</button>
		<button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="bsModalRef.hide();multipleConfirmMessage=undefined;">
			{{ 'FILES_DATA.CANCEL' | translate}}
		</button>
	</div>
	<!--<ng-container *ngTemplateOutlet="multipleActionConfirmationTemplate; context:{ multipleConfirmMessage: multipleConfirmMessage}"></ng-container>-->
</ng-template>


<!--Image Checkin Template-->
<ng-template #imageCheckInTemplate>
	<div class="modal-header">
		<!--Title-->
		<h4 class="modal-title pull-left">{{ 'CHECK_IN_IMAGE.CHECK_IN_IMAGE_TITLE' | translate}}</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<!--Body-->
	<div class="modal-body">
		<form ngNativeValidate id="new_file_form">
			<!--File upload-->
			<div class="form-group">
				<label for="NewImage_FileUpload">{{ 'CHECK_IN_IMAGE.IMAGE_FILE.NAME' | translate }}</label>
				<input type="file" class="form-control-file" id="NewImage_FileUpload" [accept]="selectedFile.extension"
					(change)="onImageFileChanged($event)" required>
				<small id="emailHelp"
					class="form-text text-muted"> Supporting file type is {{ selectedFile.extension.substring(1) | uppercase }} </small>
			</div>
			<!--Title-->
			<div class="form-group">
				<label for="NewImage_Title">{{ 'CHECK_IN_IMAGE.TITLE.NAME' | translate }}</label>
				<input type="text" class="form-control" id="NewImage_Title" [(ngModel)]="this.selectedFile.title"
					name="docTitle" aria-describedby="title"
					placeholder="{{ 'CHECK_IN_IMAGE.TITLE.PLACE_HOLDER' | translate }}" required>
			</div>
			<!--User comments-->
			<div class="form-group">
				<label for="NewImage_Comments">{{ 'CHECK_IN_IMAGE.COMMENTS.NAME' | translate }}</label>
				<input type="text" class="form-control" id="NewImage_Comments" name="newFile"
					[(ngModel)]="this.selectedFile.description" aria-describedby="comments"
					placeholder="{{ 'CHECK_IN_IMAGE.COMMENTS.PLACE_HOLDER' | translate }}">
			</div>
			<!--Attributes-->
			<!--div class="form-group">
				<label for="exampleInputPassword1">{{ 'CHECK_IN_IMAGE.ATTRIBUTE.NAME' | translate }}</label>
				<div class="alert alert-warning" role="alert" *ngIf="this.selectedFile.attributes.length == 0">
					{{ 'CHECK_IN_IMAGE.ATTRIBUTE.EMPTY_MESSAGE' | translate }}
				</div>
				<--Attribute Table->
				<table>
					<tr *ngFor="let attribute of this.selectedFile.attributes">
						<td>{{attribute.attributeName}}</td>
						<td><input name="aa" type="text" class="form-control" [(ngModel)]="attribute.value" /></td>
					</tr>
				</table>
			</div-->
			<!--Submit Button-->
			<div class="form-group row float-right mr-0">
				<button type="submit" class="btn btn-primary"
					(click)="onImageCheckInSubmitButtonClick()">{{ 'SUBMIT' | translate }}</button>
			</div>
		</form>
	</div>
</ng-template>

<ng-template #fileCheckInTemplate>
	<div class="modal-header">
		<!--Title-->
		<h4 class="modal-title pull-left">{{ 'CHECKIN_FILE.MAIN_TITLE' | translate }}</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>

	<!--Body-->
	<div class="modal-body">
		<form ngNativeValidate id="new_file_form">
			<!--File upload-->
			<div class="form-group">
				<label for="NewFile_FileUpload">{{ 'CHECKIN_FILE.USER_FILE.NAME' | translate }}</label>
				<input type="file" class="form-control-file" id="NewFile_FileUpload"
					accept=".doc, .docx, .ppt, .pptx, .pdf" (change)="onFileChanged($event)" required>
				<small id="emailHelp"
					class="form-text text-muted">{{ 'CHECKIN_FILE.USER_FILE.MESSAGE' | translate }}</small>
			</div>

			<!--Title-->
			<div class="form-group">
				<label for="NewFile_Title">{{ 'CHECKIN_FILE.TITLE.NAME' | translate }}</label>
				<input type="text" class="form-control" id="NewFile_Title" [(ngModel)]="fileCheckInTitle"
					name="docTitle" aria-describedby="title"
					placeholder="{{ 'CHECKIN_FILE.TITLE.PLACE_HOLDER' | translate }}" required>
			</div>

			<!--User comments-->
			<div class="form-group">
				<label for="NewFile_Comments">{{ 'CHECKIN_FILE.COMMENTS.NAME' | translate }}</label>
				<input type="text" class="form-control" id="NewFile_Comments" name="newFile" [(ngModel)]="fileComment"
					aria-describedby="comments" placeholder="{{ 'CHECKIN_FILE.COMMENTS.PLACE_HOLDER' | translate }}">
			</div>

			<!--Submit Button-->
			<div class="form-group row float-right mr-0">
				<button type="submit" class="btn btn-primary"
					(click)="onSubmitButtonClick('fileCheckInTemplate')">{{ 'SUBMIT' | translate }}</button>
			</div>

		</form>
	</div>

	<!--Close button-->
	<!--<div class="modal-footer">
			<button type="button" class="btn btn-default" (click)="bsModalRef.hide()">{{closeBtnName}}</button>
		</div>-->
</ng-template>


<ng-template #viewImageTemplate>
	<div class="modal-header">
		<h4 class="modal-title pull-left">{{ title }}</h4>
		<button type="button" id="image_close" class="close pull-right" aria-label="Close"
			(click)="modelRefCloseEvent()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="card p-0">
			<img *ngIf="isLoadedImage" class="card-img-top" src="data:image/jpeg;base64,{{base64FullImage}}"
				alt="{{title}}">

			<div *ngIf="!isLoadedImage" class="alert alert-warning" role="alert">
				{{ 'IMAGE_SEARCH_VIEW.IMAGE_LOADING' | translate }}
			</div>
		</div>


	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-sm btn-secondary" (click)="modelRefCloseEvent()">{{
                'IMAGE_SEARCH_VIEW.BTN_TEXT_CLOSE' | translate }}</button>
	</div>
</ng-template>

<!--3D Model Checkin Template-->
<ng-template #threeDModelCheckInTemplate>
	<div class="modal-header">
		<!--Title-->
		<h4 class="modal-title pull-left">{{ 'CHECK_IN_THREED_MODEL.CHECK_IN_THREED_MODEL_TITLE' | translate}}</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<!--Body-->
	<div class="modal-body">
		<form ngNativeValidate id="new_file_form">
			<!--File upload-->
			<div class="form-group">
				<label for="NewThreeDModel_FileUpload">{{ 'CHECK_IN_THREED_MODEL.THREED_MODEL_FILE.NAME' | translate }}</label>
				<input type="file" class="form-control-file" id="NewThreeDModel_FileUpload" accept=".zip"
					(change)="onThreeDModelFileChanged($event)" required>
				<small id="emailHelp"
					class="form-text text-muted">{{ 'CHECK_IN_THREED_MODEL.THREED_MODEL_FILE.MESSAGE' | translate }}</small>
			</div>
			<!--Title-->
			<div class="form-group">
				<label for="NewThreeDModel_Title">{{ 'CHECK_IN_THREED_MODEL.TITLE.NAME' | translate }}</label>
				<input type="text" class="form-control" id="NewThreeDModel_Title" [(ngModel)]="this.selectedFile.title"
					name="docTitle" aria-describedby="title"
					placeholder="{{ 'CHECK_IN_THREED_MODEL.TITLE.PLACE_HOLDER' | translate }}" required>
			</div>
			<!--User comments-->
			<div class="form-group">
				<label for="NewThreeDModel_Comments">{{ 'CHECK_IN_THREED_MODEL.COMMENTS.NAME' | translate }}</label>
				<input type="text" class="form-control" id="NewThreeDModel_Comments" name="newFile"
					[(ngModel)]="this.selectedFile.description" aria-describedby="comments"
					placeholder="{{ 'CHECK_IN_THREED_MODEL.COMMENTS.PLACE_HOLDER' | translate }}">
			</div>
			<!--Attributes-->
			<!--div class="form-group">
				<label for="exampleInputPassword1">{{ 'CHECK_IN_THREED_MODEL.ATTRIBUTE.NAME' | translate }}</label>
				<div class="alert alert-warning" role="alert" *ngIf="this.selectedFile.attributes.length == 0">
					{{ 'CHECK_IN_THREED_MODEL.ATTRIBUTE.EMPTY_MESSAGE' | translate }}
				</div>
				<--Attribute Table->
				<table>
					<tr *ngFor="let attribute of this.selectedFile.attributes">
						<td>{{attribute.attributeName}}</td>
						<td><input name="aa" type="text" class="form-control" [(ngModel)]="attribute.value" /></td>
					</tr>
				</table>
			</div-->
			<!--Submit Button-->
			<div class="form-group row float-right mr-0">
				<button type="submit" class="btn btn-primary"
					(click)="onThreeDModelCheckInSubmitButtonClick()">{{ 'SUBMIT' | translate }}</button>
			</div>
		</form>
	</div>
</ng-template>

<ng-template #viewThreeDModelTemplate>
<div class="modal-header">
	<h4 class="modal-title pull-left">{{ title }}</h4>
	<button type="button" id="image_close" class="close pull-right" aria-label="Close"
		(click)="modelRefCloseEvent()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body">
	<div class="card p-0">
		<img *ngIf="isLoadedModel" class="card-img-top" src="{{url}}"
			alt="{{title}}">

		<div *ngIf="!isLoadedModel" class="alert alert-warning" role="alert">
			{{ 'IMAGE_SEARCH_VIEW.IMAGE_LOADING' | translate }}
		</div>
	</div>


</div>
<div class="modal-footer">
	<button type="button" class="btn btn-sm btn-secondary" (click)="modelRefCloseEvent()">{{
			'IMAGE_SEARCH_VIEW.BTN_TEXT_CLOSE' | translate }}</button>
</div>

</ng-template>


<!--Video Checkin Template-->
<ng-template #videoCheckInTemplate>
	<div class="modal-header">
		<!--Title-->
		<h4 class="modal-title pull-left">{{ 'CHECK_IN_VIDEO.CHECK_IN_VIDEO_TITLE' | translate}}</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="closeVideoCheckinModal()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<!--Body-->
	<div class="modal-body">
		<form ngNativeValidate id="new_file_form">
			<!--File upload-->
			<div class="form-group">
				<label for="NewThreeDModel_FileUpload">{{ 'CHECK_IN_VIDEO.CHECK_IN_VIDEO_FILE.NAME' | translate }}<div class="badge-required mx-2"></div></label>
				<input type="file" class="form-control-file" id="NewThreeDModel_FileUpload" accept=".mp4"
					(change)="onVideoFileChanged($event)" required>
				<small id="emailHelp"
					class="form-text text-muted">{{ 'CHECK_IN_VIDEO.CHECK_IN_VIDEO_FILE.MESSAGE' | translate }}</small>
			</div>
			<!--Thumbnail file-->
			<div class="form-group">
			  <label for="NewVideo_FileUpload">{{ 'NEW_VIDEO.THUMBNAIL.NAME' | translate }}<div class="badge-required mx-2"></div></label>
			  <input  type="file"  class="form-control-file" id="NewThumbnail_FileUpload" accept=".png" (change)="thumbfileAdded($event)" required>
			  <small id="emailHelp" class="form-text text-muted">{{ 'NEW_VIDEO.THUMBNAIL.MESSAGE' | translate }}</small>
			</div>
			 <!--PosterFrame file-->
			 <div class="form-group">
			  <label for="NewVideo_FileUpload">{{ 'NEW_VIDEO.POSTERFRAME.NAME' | translate }}<div class="badge-required mx-2"></div></label>
			  <input  type="file"  class="form-control-file" id="NewThumbnail_FileUpload" accept=".png" (change)="posterframeFileAdded($event)" required >
			  <small id="emailHelp" class="form-text text-muted">{{ 'NEW_VIDEO.POSTERFRAME.MESSAGE' | translate }}</small>
			</div>
			<!--Title-->
			<div class="form-group">
				<label for="NewThreeDModel_Title">{{ 'CHECK_IN_VIDEO.TITLE.NAME' | translate }}</label>
				<input type="text" class="form-control" id="NewThreeDModel_Title" [(ngModel)]="this.selectedFile.title"
					name="docTitle" aria-describedby="title"
					placeholder="{{ 'CHECK_IN_VIDEO.TITLE.PLACE_HOLDER' | translate }}" required>
			</div>
			<!--User comments-->
			<div class="form-group">
				<label for="NewThreeDModel_Comments">{{ 'CHECK_IN_VIDEO.COMMENTS.NAME' | translate }}</label>
				<input type="text" class="form-control" id="NewThreeDModel_Comments" name="newFile"
					[(ngModel)]="this.selectedFile.description" aria-describedby="comments"
					placeholder="{{ 'CHECK_IN_VIDEO.COMMENTS.PLACE_HOLDER' | translate }}">
			</div>
			<!--Attributes-->
			<!--div class="form-group">
				<label for="exampleInputPassword1">{{ 'CHECK_IN_THREED_MODEL.ATTRIBUTE.NAME' | translate }}</label>
				<div class="alert alert-warning" role="alert" *ngIf="this.selectedFile.attributes.length == 0">
					{{ 'CHECK_IN_VIDEO.ATTRIBUTE.EMPTY_MESSAGE' | translate }}
				</div>
				<--Attribute Table->
				<table>
					<tr *ngFor="let attribute of this.selectedFile.attributes">
						<td>{{attribute.attributeName}}</td>
						<td><input name="aa" type="text" class="form-control" [(ngModel)]="attribute.value" /></td>
					</tr>
				</table>
			</div-->
			<!--Submit Button-->
			<div class="form-group row float-right mr-0">
				<button type="submit" class="btn btn-primary"
					(click)="onVideoCheckInSubmitButtonClick()">{{ 'SUBMIT' | translate }}</button>
			</div>
		</form>
	</div>
</ng-template>

 <ng-template #viewVideoTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{ selectedVideoTitle }}</h4>
        <button type="button" id="video_close" class="close pull-right" aria-label="Close" (click)="modelRefCloseEvent()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <video class="card p-0" controls width = "760" height="400" [poster]="selectedVideoPosterframeURL" [src]="url" type="video/mp4">
			<!-- <source [src]="url" type="video/mp4"> -->
		</video>
    </div>
    <div class="modal-footer" height="100">
        <button type="button" class="btn btn-sm btn-secondary" (click)="modelRefCloseEvent()">{{
                'VIDEO_SEARCH_VIEW.BTN_TEXT_CLOSE' | translate }}</button>
    </div>
  </ng-template>

  <!-- checkin Multiple 3D model  -->
<ng-template #checkinMultipleThreeDModelTemplate>
	<div class="modal-header">
		<h4 class="modal-title pull-left">{{ 'FILES_DATA.MULTIPLE_THREED_MODELS' | translate}}</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="cancel()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div>
			<label>{{ 'FILES_DATA.CREAT_THREED_MODELS' | translate}}</label>
			<input #threedmodel type="file" ng2FileSelect [uploader]="uploader" multiple
				accept="zip"
				(change)="handleThreeDModelFileInput($event.target.files)" /><br />
		</div>
		<label>{{ 'FILES_DATA.COMMENTS' | translate}}</label>
		<textarea class="form-control" #comments></textarea>
		<label>{{ 'FILES_DATA.ATTRIBUTES' | translate}}</label>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="checkinMultipleThreeDModels(comments.value)">
			{{ 'FILES_DATA.CONTINUE' | translate}}</button>
		<button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cancel()">{{ 'FILES_DATA.CANCEL'
			| translate}}</button>
	</div>
</ng-template>


 <!-- checkin Multiple videos  -->
 <ng-template #checkinMultipleVideoTemplate>
	<div class="modal-header">
		<h4 class="modal-title pull-left">{{ 'FILES_DATA.MULTIPLE_VIDEOS' | translate}}</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="cancel()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div>
			<label>{{ 'FILES_DATA.CREAT_VIDEOS' | translate}}</label>
			<input #VIDEO type="file" ng2FileSelect [uploader]="uploader" multiple
				accept=".mp4"
				(change)="handleVideoFileInput($event.target.files)" /><br />
		</div>
		<label>{{ 'FILES_DATA.COMMENTS' | translate}}</label>
		<textarea class="form-control" #comments></textarea>
		<label>{{ 'FILES_DATA.ATTRIBUTES' | translate}}</label>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="checkinMultipleVideos(comments.value)">
			{{ 'FILES_DATA.CONTINUE' | translate}}</button>
		<button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cancel()">{{ 'FILES_DATA.CANCEL'
			| translate}}</button>
	</div>
</ng-template>

<ng-template #undo_checkout_warn>
	<div class="modal-header">
		<h4 class="modal-title pull-left">{{'PROCEDURE_EDITOR.UNDO_CHECKOUT' | translate }}</h4>
	</div>
	<div class="modal-body">
		<h5 class='warn-text-editor'>{{ 'WARNING' | translate}}</h5>
		<h5>{{ "Are you sure you want to undo checkout this " +this.fileTypeUndoCheckout+ "?"  | translate}}</h5>
	</div>
	<div class="modal-footer">
		<button type="button" id="webeditor_modal_footer_chekin_list_warn" class="btn btn-primary"
				(click)="undocheckoutWarningModalRef.hide(); undoCheckOut();">
			{{ 'OK' | translate}}</button>
		<button type="button" id="webeditor_modal_footer_chekin_list_warn" class="btn btn-secondary"
				data-dismiss="modal" (click)="undocheckoutWarningModalRef.hide()">
			{{ 'CANCEL' | translate}}</button>
	</div>
</ng-template>

<!--template for model checkin dependencies-->
<ng-template #threedmodelDepTemplate>
	<div class="modal-header">
		<div>
			<h4 class="modal-title pull-left">{{ 'ICE_TOPIC_CONTENT.TOPIC_CONTENT.MODEL_DEPENDECIES_VIEW' | translate }} </h4>
		</div>
		<button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide();threedmodelDepClickOK()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>

	<div class="modal-body">
		<div class="card p-0 alert alert-warning" role="alert">
			<ul *ngFor="let dep of modelMissingViews">
				<li class="m-1">
					<span>{{dep.fullPath}}</span>
					<div *ngIf="dep.view != null">View : {{dep.view}}</div>
					<div *ngIf="dep.layers != null">Layers : {{dep.layers}}</div>
				</li>
			</ul>
		</div>
	</div>

	<div class="modal-footer">
		<button type="button" class="btn btn-warning" (click)="modalRef.hide();threedmodelDepClickOK()">{{'OK' | translate}}</button>
	</div>
</ng-template>

<!--template for warn model view coordinate changes-->
<ng-template #threedmodelViewWarnTemplate>
	<div class="modal-header">
		<div>
			<h4 class="modal-title pull-left">{{ 'ICE_TOPIC_CONTENT.TOPIC_CONTENT.MODEL_CHANGED_VIEWS' | translate }} </h4>
		</div>
		<button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide();threedmodelViewWarnClickOK()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>

	<div class="modal-body">
		<h5>You need to re-add model view and save following Bullets</h5>
		<div class="card p-0 alert alert-warning" role="alert">
			<ul *ngFor="let dep of modelChangedViews">
				<li class="m-1">
					<span>{{dep.fullPath}}</span>
					<div *ngIf="dep.view != null">View : {{dep.view}}</div>
					<div *ngIf="dep.viewDetails != null">View Details : {{dep.viewDetails}}</div>
				</li>
			</ul>
		</div>
	</div>

	<div class="modal-footer">
		<button type="button" class="btn btn-warning" (click)="modalRef.hide();threedmodelViewWarnClickOK()">{{'OK' | translate}}</button>
	</div>
</ng-template>

<!--template for warn model view coordinate changes-->
<ng-template #restoreRevision3DModelTemplate>
	<div class="modal-header">
		<div>
			<h4 class="modal-title pull-left">{{ 'ICE_TOPIC_CONTENT.TOPIC_CONTENT.MODEL_CHANGED_VIEWS_LAYERS_OLD' | translate }} </h4>
		</div>
		<button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide();restoreRevisionClickOK()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>

	<div class="modal-body">
		<h5>You need to check model view/layers and save following Bullets</h5>
		<div class="card p-0 alert alert-warning" role="alert">
			<ul *ngFor="let dep of modelInfo">
				<li class="m-1">
					<span>{{dep.fullPath}}</span>
					<div *ngIf="dep.view != null">View : {{dep.view}}</div>
					<div *ngIf="dep.layers != null">Layers : {{dep.layers}}</div>
					<div *ngIf="dep.viewDetails != null">View Details : {{dep.viewDetails}}</div>
					<div *ngIf="dep.deletedModel != null">Model is deleted</div>
				</li>
			</ul>
		</div>
	</div>

	<div class="modal-footer">
		<button type="button" class="btn btn-warning" (click)="modalRef.hide();restoreRevisionClickOK()">{{'OK' | translate}}</button>
	</div>
</ng-template>


