export class ProcedureStoreModel {
   constructor(
      private procedureId: string,      
      private systemId: string,
      private version: string,
      private folderPath: string,
      private notes: string,      
      private keywords: string,
      private outputId: string,
      private availability: string,
      private inReview: string,
      private description: string){};
}