import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { UserVariable } from '../../util/common/user-variable';
import { ServiceUrls } from '../../util/service-urls';
import { WsResponse } from '../../util/ws-response.model';
import { map } from 'rxjs/operators'
import { catchError } from 'rxjs/operators';
import { WsCallback } from '../../util/ws-callback.interface';
import { WsType } from '../../util/ws-type';
import { CommonUtil } from 'src/app/util/common/common-util';



@Injectable()
export class DictionaryService {
	

   private callback: WsCallback;

   constructor(private http: HttpClient, private commonUtil: CommonUtil) {
       
   }

   /**
   * Call service method to getWordListByProjectIdAndLanguageusing GET request.
   * @param projectId project Id   
   */

  getWordListByProjectIdAndLanguage(language: string): Observable<WsResponse>{
   let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
   const options = { params: new HttpParams().set("token", token).set("language", language) }

   return this.http.get(ServiceUrls. GET_DICTIONARY_BY_LANGUAGE_AND_PROJECT, options).
      pipe(
         map(response => {
            var modified = JSON.parse(JSON.stringify(response));
            return new WsResponse(
               modified.status.description,
               modified.status.code,
               modified.status.name,
               modified.payload);
          }),
         catchError(error => {
            var modified = JSON.parse(JSON.stringify(error.error));
            var res = new WsResponse(
               modified.status.description,
               modified.status.code,
               modified.status.name,
               modified.payload);
            return throwError(res)
          })
       );
  }

  addWordToDictionary(language: string, word: string, callBack: WsCallback){
      let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
      let url = this.addWordToDictionaryURL(token);
      this.http.post(url, JSON.stringify(new Request(word, language))).
      subscribe(
         data => {
            var modified = JSON.parse(JSON.stringify(data));
            var res = new WsResponse(
               modified.status.description,
               modified.status.name,
               modified.status.code,
               modified.payload);
               callBack.onSuccess(res, WsType.ADD_WORD_TO_DICTIONARY);
         },
         error => {
            var modified = JSON.parse(JSON.stringify(error));
            var res = new WsResponse(
               modified.error.status.description,
               modified.error.status.name,
               modified.error.status.code,
               modified.error.payload);
               callBack.onFail(res, WsType.ADD_WORD_TO_DICTIONARY);
         }
      );
  }

   public addWordToDictionaryURL(token : String){
      let url = ServiceUrls.ADD_WORD + '?token=' + token;
      return url;
   }

   deleteWordById(wordId: String, callBack: WsCallback): any {
      let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
      let url = ServiceUrls.DELETE_WORD + wordId;
      const options = {params: new HttpParams().set("token", token)}
      this.http.delete(url, options).subscribe(
         data => {
            var modified = JSON.parse(JSON.stringify(data));
            var res = new WsResponse(
               modified.status.description,
               modified.status.code,
               modified.status.name,
               modified.payload);
            callBack.onSuccess(res, WsType.DELETE_WORD_DICTIONARY);
         },
         error => {
            if (error.status != '') {
               var val = (error as HttpErrorResponse).error;
               var modified = JSON.parse(JSON.stringify(val));
               var res = new WsResponse(
                     modified.status.description,
                     modified.status.code,
                     modified.status.name,
                     modified.payload);
               callBack.onFail(res, WsType.DELETE_WORD_DICTIONARY);
            } else {
               //browser related issues
               var res = new WsResponse("Unknown error happened");
               callBack.onFail(res, WsType.DELETE_WORD_DICTIONARY);
            }
         }
      );
	}

}


class Request {
   constructor(private word: String, private language: String) { }
}

