import { SearchParams } from './search-params.modal'

export class SearchTagInput extends SearchParams<string> {
    controlType = 'taginput'
    type: string

    constructor(options: {} = {}) {
        super(options)
        this.type = options['type'] || ''
        this.disable = options['disable'] || false
    }
}
