import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FacilitiesService } from './../facilities.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SharedService } from '../../../util/shared.service';
import { FACILITY_UI_VIEW } from '../../../util/constants';
import { HistoryService } from '../../history/history.service';
import { Constants, UI_LAYOUT, LocalStorageConst } from '../../../util/constants';
import { AlertService } from '../../../util/alert/alert.service';
import { DependenciesmodalComponent } from '../../../util/dependenciesmodal/dependenciesmodal.component';
import { LocalStorage } from '../../../util/localstorage.service';
import { WsCallback } from '../../../util/ws-callback.interface';
import { WsType } from '../../../util/ws-type';
import { WsResponse } from '../../../util/ws-response.model';

@Component({
    selector: 'app-addedfacilities',
    templateUrl: './addedfacilities.component.html',
    styleUrls: ['./addedfacilities.component.css']
})
export class AddedfacilitiesComponent implements OnInit {

    public facility: any = null;
    private modalRef: BsModalRef;
    public dataLoad: boolean = false;
    public isEdit: boolean = false;
    private dependencyType: string;
    
    @ViewChild('newName') newName: ElementRef;
    @ViewChild('newEmail') newEmail: ElementRef;
    @ViewChild('newsecondaryEmail') newsecondaryEmail: ElementRef;
    @ViewChild('myForm') formValues;

    public fuv: any = FACILITY_UI_VIEW;
	public expandedView: string; //common variable
	public nameRequired: string;
	public emailNotValid: string;
	public secondryEmailNotValid: string;
    public successfullyEdit: string; 
    public successfullyAdd: string; 
    

    // Reusable Change Details
    public reusablesName: any;
    public reusablesModifiedBy: any;
    public reusableModifiedTime: any;
    public reusable: boolean = false;
    public reusableNo: boolean = false;
    public reusablesNumber: any;
    public reusablesNoModifiedBy: any;
    public reusableNoModifiedTime: any;

    constructor(private translate: TranslateService,
        private router: Router,
        private facilitiesService: FacilitiesService,
        private sharedService: SharedService,
        private modalService: BsModalService,
        private alertService: AlertService,
        public bsModalRef: BsModalRef) {

			this.translate.get('ADD_EDIT_FACILITY.NAME_REQUIRED').subscribe((res: string) => {
				this.nameRequired = res;
				this.translate.get('ADD_EDIT_FACILITY.EMAIL_NOT_VALID').subscribe((res: string) => {
					this.emailNotValid = res;
					this.translate.get('ADD_EDIT_FACILITY.SECONDRY_EMAIL_NOT_VALID').subscribe((res: string) => {
						this.secondryEmailNotValid = res;
						this.translate.get('ADD_EDIT_FACILITY.EDIT_SUCCESSFULLY').subscribe((res: string) => {
                            this.successfullyEdit = res;
                            this.translate.get('ADD_EDIT_FACILITY.ADD_SUCCESSFULLY').subscribe((res: string) => {
                                this.successfullyAdd = res;
                            });
						});
					});
				});
			});
			//facilitiesService.setCallback(this);
    }

    getType() {
        return localStorage.getItem("value");
    }

    ngOnInit() {
        if (this.sharedService.getFileId() != "-1" && this.sharedService.getFileId() != -1) {
            this.isEdit = true;
            this.facilitiesService.getFacilityDataByFacilityId(this) //load facility data to be edited
            localStorage.setItem("value", "0");
        }
        else {
            this.dataLoad = true;
        }
    }

    viewDependencies(dflag: boolean) {
        this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.VIEW;
        this.dependenciesModalWithComponent('Dependencies List', false, true);
    }

    dependenciesModalWithComponent(header: String, dflag: boolean, cflag: boolean) {
        const initialState : object = {
            headerTitle: header,
            delFlag: dflag,
            cancelFlag: cflag,
            checkboxVal: false
        };
        this.sharedService.setFileId(this.facility.facilityId);
        this.sharedService.setFileType(Constants.DEPENDENCY_ITEM_TYPE.FACILITY);
        this.sharedService.setAction("getDependencies");
		this.sharedService.setDependencyMessageType(this.dependencyType);
        this.sharedService.setParent(this);
        this.modalRef = this.modalService.show(DependenciesmodalComponent, { initialState, class: 'modal-lg' });
    }

    save() {
        if (this.sharedService.getFileId() != "-1" && this.sharedService.getFileId() != -1) {
            //console.log("JSON.stringify(this.facility) " + JSON.stringify(this.facility))
            if (this.facility.facilityName == "") {
                this.alertService.clear();
                this.alertService.error(this.nameRequired);
            }
			else if(this.facility.emailAddress !== "" && (this.facility.emailAddress.indexOf('@') === -1 || this.facility.emailAddress.indexOf('.') === -1 ))
			{
                this.alertService.clear();
				this.alertService.error(this.emailNotValid)
				console.log("email address is not valid")
			} 
			else if(this.facility.secondaryEmailAddress !== "" && (this.facility.secondaryEmailAddress.indexOf('@') === -1 || this.facility.secondaryEmailAddress.indexOf('.') === -1) )
			{
				this.alertService.clear();
				this.alertService.error(this.secondryEmailNotValid)
				console.log("Scondry email address is not valid")
            } else {
                this.facilitiesService.setFacility(this.facility);
                this.facilitiesService.updateFacility(JSON.stringify(this.facility), this.facility.facilityId, this);
                // this.bsModalRef.hide();
            }
        } else {
            if (this.newName.nativeElement.value == "") {
                this.alertService.clear();
                this.alertService.error(this.nameRequired)
			}
			else if (this.newEmail.nativeElement.value == "" && this.newsecondaryEmail.nativeElement.value == "") {
				this.facilitiesService.addFacility(
                    this.newName.nativeElement.value,
                    this.newEmail.nativeElement.value,
					this.newsecondaryEmail.nativeElement.value,
					this
                );
                // this.router.navigateByUrl("home")
                // this.bsModalRef.hide()
			}
			else if(this.newEmail.nativeElement.value !== "" && (this.newEmail.nativeElement.value.indexOf('@') === -1 || this.newEmail.nativeElement.value.indexOf('.') === -1 ))
			{
				this.alertService.clear();
				this.alertService.error(this.emailNotValid)
				console.log("email address is not valid")
			} 
			else if(this.newsecondaryEmail.nativeElement.value !== "" && (this.newsecondaryEmail.nativeElement.value.indexOf('@') === -1 || this.newsecondaryEmail.nativeElement.value.indexOf('.') === -1) )
			{
				this.alertService.clear();
				this.alertService.error(this.secondryEmailNotValid)
				console.log("Scondry email address is not valid")
			} else {
                this.facilitiesService.addFacility(
                    this.newName.nativeElement.value,
                    this.newEmail.nativeElement.value,
					this.newsecondaryEmail.nativeElement.value,
					this
                );
                // this.router.navigateByUrl("home")
                // this.bsModalRef.hide();
            }
        }
    }

    cancel() {
        // this.router.navigateByUrl("home")
        this.bsModalRef.hide()
        this.alertService.clear()
    }


    onSuccess(data: WsResponse, serviceType: WsType): void {
		console.log("success")
        if (data.payload != null && serviceType == WsType.FACILITY_DATA_VIEW) {
            this.facility = data.payload;

            if (data.payload.reusableChangeInfo != null) {
                this.reusable = true;
                this.reusablesName = "Old Value : " + data.payload.reusableChangeInfo.oldValue;
                this.reusablesModifiedBy = "Modified By : " + data.payload.reusableChangeInfo.changeInitiatedUserName;
                if (data.payload.reusableChangeInfo.changeInitiatedTimeStampFormatted != null) {
                    this.reusableModifiedTime = "Modified Time : " + data.payload.reusableChangeInfo.changeInitiatedTimeStampFormatted;
                }
                else{
                    // nothing to do
                }
                this.facility.facilityName = data.payload.reusableChangeInfo.newValue;
            } else {
                this.reusable = false;
            }


            console.log("Facility: " + JSON.stringify(this.facility));
            this.dataLoad = true;
        } else if (serviceType == WsType.FACILITY_ADD) {
            this.bsModalRef.hide();
            this.alertService.clear();
            this.alertService.info(this.successfullyAdd +". " +this.translate.instant("REUSABLES.REFRESH_REUSABLE_TABLE"));				              
        } else if (serviceType == WsType.FACILITY_UPDATE) {
            //Comment this to remove updating UI and add user message
            //this.facilitiesService.setItemData();
            this.bsModalRef.hide();
            this.alertService.clear();
            this.alertService.info(this.successfullyEdit +". " +this.translate.instant("REUSABLES.REFRESH_REUSABLE_TABLE"));	
        }
    }

    onFail(res: WsResponse, serviceType: WsType): void {
		console.log("status desc")
        if(serviceType == WsType.FACILITY_DATA_VIEW) {
            this.bsModalRef.hide();
            this.alertService.clear();
            this.alertService.success(res.statusDescription);
        } else if (serviceType == WsType.FACILITY_ADD) {
            this.alertService.clear();
            this.alertService.error(res.statusDescription);
        } else if (serviceType == WsType.FACILITY_UPDATE) {
            this.alertService.clear();
            this.alertService.error(res.statusDescription);
		}
    }
}
