import {
	Component, OnInit, ViewChild, ViewEncapsulation, OnDestroy,
	ViewContainerRef, ComponentRef, ComponentFactoryResolver, HostListener
} from '@angular/core';
import { TabhostComponent } from '../tabhost/tabhost.component';
import { SharedService } from '../util/shared.service';
import { Constants, UI_LAYOUT, LocalStorageConst, SessionStorageConst } from '../util/constants';
import { FoldertreeComponent } from '../foldertree/foldertree.component';
import { InsertReferenceComponent } from '../insert-reference/insert-reference.component';
import { TopicMapBuilderComponent } from '../topic-map-builder/topic-map-builder.component';
import { IceCourseLoaderService } from '../ice-course/ice-course-loader/ice-course-loader.service';

import { log } from 'util';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorage } from '../util/localstorage.service';


@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit, OnDestroy {

	@ViewChild(TabhostComponent) tabsComponent
	public showFullScreen: boolean = false

	public divHeight: number = window.innerHeight;

	public folderExplorerSplit: number = Constants.UI_LAYOUT.UIL_1.fxW
	public tabSplit: number = Constants.UI_LAYOUT.UIL_1.tW
	public editorLayoutSplit: number = Constants.UI_LAYOUT.UIL_1.ruW

	private openTabSubscription: any;

	public selectedLeftItem: string = 'FOLDER_EXPLORER'
	public selectedLeftSideComponent: any = FoldertreeComponent
	@ViewChild('leftComponentHost', { read: ViewContainerRef }) componentHost: ViewContainerRef
	compoenntRef: ComponentRef<Component>

	public referenceEnabled: boolean = false;
	public topicMapEnabled: boolean = false;
	public gutterDragStarted:boolean = false;

	@HostListener('window:resize', ['$event'])
	onresize(event?) {
		this.divHeight = window.innerHeight;
	}

	constructor(private sharedService: SharedService,private iceCourseLaoderService: IceCourseLoaderService, private resolver: ComponentFactoryResolver,
		private translate: TranslateService) {
		// let isDesktop: boolean = JSON.parse(localStorage.getItem(LocalStorage.IS_DESKTOP))
		// console.log('[HomeComponent] (isDesktop) ' + JSON.stringify(isDesktop));
		
		//localStorage.setItem(LocalStorageConst.UI_LAYOUT, JSON.stringify(UI_LAYOUT.UIL_1))
		sessionStorage.setItem(SessionStorageConst.UI_LAYOUT, JSON.stringify(UI_LAYOUT.UIL_1))
	}

	ngOnInit() {
	}

	openTab(option: any) {
		console.log('[openTab] (option)' + JSON.stringify(option));
		this.tabsComponent.openTabWithId(option.tabId, option.tabTitle, null, option.componentType, option.data, true, option.path);
	}



	ngAfterContentInit() {
		//Called after ngOnInit when the component's or directive's content has been initialized.
		//Add 'implements AfterContentInit' to the class.
		this.openTabSubscription = this.sharedService.openTabRespond().subscribe(option => this.openTab(option))

		this.sharedService.fullScreenExecute().subscribe(option => this.fullScreen(option))
		this.sharedService.expandReusablesExcute().subscribe(option => this.expandReusables(option))
		this.sharedService.openTopicMapBuilderRespond().subscribe(option => this.topicMap(true))
		this.sharedService.openTopicMapViewerRespond().subscribe(option => this.topicMap(true))
		this.sharedService.changeLeftSideItemRespond().subscribe(option => this.changeItem(option))
		this.sharedService.enableReferencePaneRespond().subscribe(enable => this.referenceEnabled=enable);
		this.sharedService.enableTopicMapPaneRespond().subscribe(enable => this.topicMapEnabled=enable);
	}

	ngAfterViewInit(): void {
		//Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
		//Add 'implements AfterViewInit' to the class.
		// this.updateComponent(this.selectedLeftSideComponent)
	}

	ngOnDestroy() {
		//Called once, before the instance is destroyed.
		//Add 'implements OnDestroy' to the class.
		if (this.compoenntRef) {
			this.compoenntRef.destroy()
		}
		this.openTabSubscription.unsubscribe();
	}

	private fullScreen(option: any) {
		if (option !== null) {
			// console.log('[fullScreen] (option) ' + JSON.stringify(option));
			this.folderExplorerSplit = option.uiLayout.fxW
			this.tabSplit = option.uiLayout.tW
			this.editorLayoutSplit = option.uiLayout.ruW
		}

	}

	private expandReusables(option: any) {
		if (option !== null) {
			// console.log('[expandReusables] (option) ' + JSON.stringify(option));
			this.folderExplorerSplit = option.uiLayout.fxW
			this.tabSplit = option.uiLayout.tW
			this.editorLayoutSplit = option.uiLayout.ruW
		}

	}

	public changeItem(option: any) {
		let isSelectTab = option.isSelectTab
		if (option.item == 'FOLDER_EXPLORER') {
			// this.folderExplorer(isSelectTab)
			this.selectedLeftItem = 'FOLDER_EXPLORER'
			// this.changeUILayout()
		} else {
			this.selectedLeftItem = 'TOPIC_MAP'
			// this.changeUILayout()
			// this.topicMap(isSelectTab)
		}
	}

	folderExplorer(isSelectTab: boolean) {
		// console.log('[folderExplorer] (LocalStorageConst.UI_LAYOUT) ' + localStorage.getItem(LocalStorageConst.UI_LAYOUT))
		this.selectedLeftItem = 'FOLDER_EXPLORER'
		this.changeUILayout()
		if (isSelectTab) {
			this.sharedService.selectTabWhenFEClickRequest()
			this.sharedService.enableReferencePaneRequest(false);
		}

	}

	topicMap(isSelectTab: boolean) {
		// console.log('[topicMap] (LocalStorageConst.UI_LAYOUT) ' + localStorage.getItem(LocalStorageConst.UI_LAYOUT))
		this.selectedLeftItem = 'TOPIC_MAP'
		this.changeUILayout()
		let topicMapType: string = this.sharedService.getTopicMapType()
		if (isSelectTab) {
			if (topicMapType == 'edit') {
				this.sharedService.selectTabWhenTMBClickRequest()
			} else {
				//do nothing
			}
		}
	}

	reference(isSelectTab: boolean) {
		this.selectedLeftItem = 'REFERENCE'
		this.changeUILayout()
	}

	/**
	 * change UI layout 
	 */
	private changeUILayout() {
		let uiLayout = JSON.parse(sessionStorage.getItem(SessionStorageConst.UI_LAYOUT))
		// console.log('[fullScreen] (uiLayout)' +  JSON.stringify(uiLayout));
		let newLayout: any = null
		switch (uiLayout) {
			case UI_LAYOUT.UIL_1:
				newLayout = Constants.UI_LAYOUT.UIL_1;
				sessionStorage.setItem(SessionStorageConst.UI_LAYOUT, JSON.stringify(UI_LAYOUT.UIL_1))
				break;
			case UI_LAYOUT.UIL_2:
				newLayout = Constants.UI_LAYOUT.UIL_2;
				sessionStorage.setItem(SessionStorageConst.UI_LAYOUT, JSON.stringify(UI_LAYOUT.UIL_2))
				break;
			case UI_LAYOUT.UIL_3:
				newLayout = Constants.UI_LAYOUT.UIL_1;
				sessionStorage.setItem(SessionStorageConst.UI_LAYOUT, JSON.stringify(UI_LAYOUT.UIL_1))
				break;
			case UI_LAYOUT.UIL_4:
				newLayout = Constants.UI_LAYOUT.UIL_2;
				sessionStorage.setItem(SessionStorageConst.UI_LAYOUT, JSON.stringify(UI_LAYOUT.UIL_2))
				break;
			case UI_LAYOUT.UIL_6:
				newLayout = Constants.UI_LAYOUT.UIL_2;
				sessionStorage.setItem(SessionStorageConst.UI_LAYOUT, JSON.stringify(UI_LAYOUT.UIL_2))
				break;
		}
		// console.log('[fullScreen] (newLayout)' +  JSON.stringify(newLayout));

		this.fullScreen({ uiLayout: newLayout })
	}

	private updateComponent(componentToLoad: any) {
		if (componentToLoad != undefined) {
			this.componentHost.clear()
			const authFormFactory = this.resolver.resolveComponentFactory(componentToLoad)
			const component = this.componentHost.createComponent(authFormFactory)
		}
	}

	gutterDragStart(){
		this.gutterDragStarted = true;
	}
	gutterDragEnd(){
		this.gutterDragStarted=false;
	}
	gutterClick(){
		this.gutterDragStarted=false;
	}
}
