import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
	selector: '[appDynamicOmItem]'
})
export class DynamicOmItemDirective {

	constructor(public viewContainer: ViewContainerRef) { }

}
