import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DictionarysharedService } from '../dictionaryshared.service';
import { WsCallback } from 'src/app/util/ws-callback.interface';
import { WsType } from '../../../util/ws-type';
import { WsResponse } from '../../../util/ws-response.model';
import { AlertService } from 'src/app/util/alert/alert.service';
import { DictionaryService } from '../dictionary.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
	selector: 'app-deleteword',
	templateUrl: './deleteword.component.html',
	styleUrls: ['./deleteword.component.css']
})
export class DeletewordComponent implements OnInit, WsCallback {
	
	private wordId: String;
	constructor(public bsModalRef: BsModalRef, private dictionarysharedService: DictionarysharedService, private dictionaryService: DictionaryService, private alertService: AlertService, private translate: TranslateService) { }

	ngOnInit() {
	}

	confirm(){
		this.wordId = this.dictionarysharedService.getWordId();
		console.log("==delete " + this.wordId);
		this.dictionaryService.deleteWordById(this.wordId, this);
	}
	
	onSuccess(data: WsResponse, serviceType: WsType): void {
		if (serviceType == WsType.DELETE_WORD_DICTIONARY){
			// console.log("===onSuccess " + serviceType);
			// console.log("payload= "+data.payload);
			// console.log("statusCode= "+data.statusCode);
			// console.log("statusDescription= "+data.statusDescription);
			// console.log("statusName= "+data.statusName);
			this.alertService.success(this.translate.instant('DICTIONARY_DELETE.DELETE_SUCCESS'), true);
			this.dictionarysharedService.dataUpdated(true);
		}
	}


	onFail(data: WsResponse, serviceType: WsType): void {
		if (serviceType == WsType.DELETE_WORD_DICTIONARY){
			// console.log("===onFail " + serviceType);
			// console.log("payload= "+data.payload);
			// console.log("statusCode= "+data.statusCode);
			// console.log("statusDescription= "+data.statusDescription);
			// console.log("statusName= "+data.statusName);
			this.alertService.error(this.translate.instant('DICTIONARY_DELETE.DELETE_FAILED'), true);
			this.dictionarysharedService.dataUpdated(true);
		}
	}
}
