import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError, from } from 'rxjs';
import { LocalStorage } from './localstorage.service';
import { Constants } from './constants';
import { tap } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from './alert/alert.service';
import { SessionUtil } from './session-util';
import { SessionValidationService } from '../user/login/session-validation-request.service';
import { WsType } from './ws-type';
import { WsResponse } from './ws-response.model';
import { WsCallback } from './ws-callback.interface';
import { ServiceUrls } from './service-urls';
import { AuthRequest } from '../user/login/auth-request.model';
import { UserVariable } from './common/user-variable';
import { LoggedUser } from '../user/logged-user.model';
import { map } from 'rxjs/operators'
import { catchError, retry } from 'rxjs/operators';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
    private token: string = "";
    private deviceId: string = "";
    
    constructor(private router: Router, private injector: Injector, private route: ActivatedRoute,private http:HttpClient,
        private alertService: AlertService, private sessionValidationService: SessionValidationService,  public sessionValidationRequest: SessionValidationService) {}

    
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        //get url
        var requestURL = JSON.parse(JSON.stringify(request)).url;

        return from(this.handle(request, next))

           /* return next.handle(request).pipe(
                tap(event => {
                    //ignore this
                    
                }, err =>  {
                if (
                    request.url.includes("refreshtoken") ||
                    request.url.includes("auth") 
                ) {
                } else {
                    
                }
                return Observable.throw(err);
            }));*/
    }
    

  /*  onSuccess(data: WsResponse, serviceType: WsType): void {
		if (serviceType == WsType.REFRESH_TOKEN) {
            var userData = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER));
            this.token = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER)).authtoken;

            if (data.payload != "" && data.payload != null) {
                var user = new LoggedUser(userData.userName,
                userData.userId, data.payload, userData.firstName, userData.lastName);
                localStorage.setItem(LocalStorage.LOGGED_USER, JSON.stringify(user));
            }
           
         }
	
    }
    
	onFail(data: WsResponse, serviceType: WsType): void {
		
    }
    
    public refreshToken(refreshTokenRequest: String, callBack: WsCallback) {
        this.http.post(ServiceUrls.REFRESH_TOKEN, refreshTokenRequest).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                callBack.onSuccess(res, WsType.REFRESH_TOKEN);
            },
            error => {
                if (error.status != null) {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.REFRESH_TOKEN);
                } else {
                    //browser related issues
                    var res = new WsResponse("Unknown error happened");
                    callBack.onFail(res, WsType.REFRESH_TOKEN);
                }
            }
        );
    }*/

    public refreshToken(refreshTokenRequest: string) {
        return this.http.post(ServiceUrls.REFRESH_TOKEN, refreshTokenRequest).pipe(
            map(response => {
                var modified = JSON.parse(JSON.stringify(response));
                return new WsResponse(
                   modified.status.code,
                   modified.status.name,
                   modified.status.description,
                   modified.payload);
             }),
             catchError(error => {
                var modified = JSON.parse(JSON.stringify(error.error));
                var res = new WsResponse(
                   modified.status.code,
                   modified.status.name,
                   modified.status.description,
                   modified.payload);
                return throwError(res)
             })
        );
    }

    async handle(request: HttpRequest<any>, next: HttpHandler) {

        let userId =  "";
        let appType = Constants.APP_TYPE;
        let deviceId = localStorage.getItem(LocalStorage.DEVICE_ID);
        let userApp = Constants.APP_TYPE + '_' + Math.random();
        
        if (localStorage.getItem(LocalStorage.LOGGED_USER) != null) {
            this.token = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER)).authtoken;
            userId = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER)).userId;
        }
        let logRequest = new refreshTokenRequest(userId,appType,deviceId,userApp);
        if (localStorage.getItem(LocalStorage.DEVICE_ID) != null) {
            this.deviceId = localStorage.getItem(LocalStorage.DEVICE_ID);
            //console.log('basic request - ' + this.deviceId);
        }
        if(this.token !== "")  {
            var base64Url = this.token.split('.')[1];
            var tokenString = atob(base64Url);
            var decoded = JSON.parse(tokenString);
        }
        
        request = request.clone({
            setHeaders: {
                Authorization: this.token,
                'User-App': btoa(Constants.APP_TYPE + '_' + Math.random()),
                'Accept-Language': 'en-US',
                'Device-Id': btoa(this.deviceId)
            }
        });

        if (request.url.includes("refreshtoken") || request.url.includes("auth")) {
        } else {
            if (this.token !== "") {
                if (decoded.exp < new Date().getTime()/1000 ){
                    //refresh token is expired
                    //this.refreshToken(JSON.stringify(logRequest), this);
                    await this.refreshToken(JSON.stringify(logRequest)).toPromise().then(
                        data => {
                            request = request.clone({
                                setHeaders: {
                                    Authorization: data.payload,
                                    'User-App': btoa(Constants.APP_TYPE + '_' + Math.random()),
                                    'Accept-Language': 'en-US',
                                    'Device-Id': btoa(this.deviceId)
                                }
                            });
                            let userData = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER));
                            this.token = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER)).authtoken;
                            if (data.payload != "" && data.payload != null) {
                                let user = new LoggedUser(userData.userName,
                                    userData.userId, data.payload, userData.firstName, userData.lastName);
                                localStorage.setItem(LocalStorage.LOGGED_USER, JSON.stringify(user));
                            }
                        }
                        
                    );
                // return next.handle(request).toPromise()
                } else {
                    //refresh token is not expired
                }
            }
        }
        return next.handle(request).toPromise();
      }
}

class refreshTokenRequest {
    constructor(private userId: String,private appType: String,private deviceId: String,private userApp: String) { }
}