export class PdfToProcedurestoreModel {
    constructor(
    private DocumentNo: string = null,
	private DocumentTitle: string = null,
	private DocumentSubTitle: string = null,
	private DocumentProject: string = null,
	private DocumentOutputFileName: string = null,
	private DocumentVersionNo: string = null,
	private DocumentNotes: string = null,
	private DocumentKeywords: string = null,
	private facilityId: string = null,
	private systemId: string = null,
	private DocumentInReview: boolean =false,
	private DocumentParVal: boolean =false,
	private DocumentDraft: boolean =false){}
}
