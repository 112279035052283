import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { UserVariable } from '../../util/common/user-variable';
import { ServiceUrls } from '../../util/service-urls';
import { WsResponse } from '../../util/ws-response.model';
import { WsCallback } from '../../util/ws-callback.interface';
import { WsType } from '../../util/ws-type';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { CommonUtil } from 'src/app/util/common/common-util';

@Injectable()
export class TagsService {
	public token: string;
	private callback: WsCallback;
	private rowData: any = null;
	private apiGridData: any = null;

	constructor(private http: HttpClient, private commonUtil: CommonUtil) {
	}

	public setCallback(callBack: WsCallback) {
		this.callback = callBack;
	}

	searchTags(tagNo: String, cadNo: String, description: String, systemName: String, documentName: String, facilityName: String, hasText: string) {
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
		let url = this.getTagSearchURL(token);

		//Achini Randeni
		//pass null for tag UsedInComm
		this.http.post(url, JSON.stringify(new Request(tagNo, cadNo, description, systemName, documentName, facilityName, hasText, null))).
			subscribe(
				data => {
					var modified = JSON.parse(data.toString());
					var res = new WsResponse(
						modified.status.description,
						modified.status.name,
						modified.status.code,
						modified.payload);
					this.callback.onSuccess(res, WsType.TAG_SEARCH_VIEW);
				},
				error => {
					var modified = JSON.parse(JSON.stringify(error));
					var res = new WsResponse(
						modified.error.status.description,
						modified.error.status.name,
						modified.error.status.code,
						modified.error.payload);
					this.callback.onFail(res, WsType.TAG_SEARCH_VIEW);
				});
	}
	public setApiGridData(apiGridData: any) {
		this.apiGridData = apiGridData;
	}

	public getApiGridData() {
		return this.apiGridData;
	}

	public setRowData(rowData: any) {
		this.rowData = rowData;
	}

	public getRowData() {
		return this.rowData;
	}
    /**
     * Get tag dependencies.
     * @param request string such as {"itemId":"", "itemType":"", "action":"", "userToken":""}
     * @param wsCallBack callback component.
     */
	public getTagDependenciesByTag(request: string, wsCallBack: WsCallback) {
		console.log(request);
		this.http.post(ServiceUrls.GET_TAG_DEPENDENCIES_BY_TAG, request).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_TAG_DEPENDENCIES_BY_TAG);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_TAG_DEPENDENCIES_BY_TAG);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_TAG_DEPENDENCIES_BY_TAG);
				}
			}
		);
	}

	/**
     * check tag classification dependencies with id.
     * @param request string such as {"itemId":"", "itemType":"", "action":"", "userToken":""}
     * @param wsCallBack callback component.
     */
	public getClassificationAttributeDependeciesWithId(request: string, wsCallBack: WsCallback) {
		console.log(request);
		this.http.post(ServiceUrls.GET_CLASSIFICATION_ATTRIBUTE_DEPENDENCIES_WITH_ID, request).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_CLASSIFICATION_ATTRIBUTE_DEPENDENCIES_WITH_ID);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_CLASSIFICATION_ATTRIBUTE_DEPENDENCIES_WITH_ID);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_CLASSIFICATION_ATTRIBUTE_DEPENDENCIES_WITH_ID);
				}
			}
		);
	}

    /**
     * Get tag by tag id.
     * @param request request
     * @param wsCallBack call back object
     */
	public getTagByTagId(request: string, wsCallBack: WsCallback) {
		console.log(request);

		this.http.post(ServiceUrls.GET_TAG_BY_TAG_ID, request).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_TAG_BY_TAG_ID);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_TAG_BY_TAG_ID);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_TAG_BY_TAG_ID);
				}
			}
		);
	}

	public getFacilityWithDependencies(request: string, wsCallBack: WsCallback) {
		this.http.post(ServiceUrls.GET_FACILITY_WITH_DEPENDENCIES, request).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_FACILITY_WITH_DEPENDENCIES);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_FACILITY_WITH_DEPENDENCIES);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_FACILITY_WITH_DEPENDENCIES);
				}
			}
		);
	}

	/**
	 * Get all facilities for the token
	 * @param wsCallBack callback class
	 */
	public getAllFacility(wsCallBack: WsCallback) {
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
		const options = { params: new HttpParams().set("token", token) }
		this.http.get(ServiceUrls.GET_ALL_FACILITY, options).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_ALL_FACILITY);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_ALL_FACILITY);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_ALL_FACILITY);
				}
			}
		);
	}

	getInitialSystemsByFacilityId(token: string, facilityId: any) {
        //let url = this.parentSystemURL();
        return this.http.get(ServiceUrls.getSystemsByFacilityId(token, facilityId)).
            pipe(
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.code,
                        modified.status.name,
                        modified.status.description,
                        modified.payload);
                }),
                catchError(error => {
                    var modified = JSON.parse(JSON.stringify(error.error));
                    var res = new WsResponse(
                        modified.status.code,
                        modified.status.name,
                        modified.status.description,
                        modified.payload);
                    return throwError(res)
                })
            );
    }

	/**
	 * Gets system list by facility id.
	 * @param token token of the user
	 * @param facilityId  id of the facility
	 * @param wsCallBack callback class
	 */
	public getSystemsByFacilityId(token: string, facilityId: string, wsCallBack: WsCallback) {
		this.http.get(ServiceUrls.getSystemsByFacilityId(token, facilityId)).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_SYSTEMS_BY_FACILITY_ID);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_SYSTEMS_BY_FACILITY_ID);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_SYSTEMS_BY_FACILITY_ID);
				}
			}
		);
	}

	public getInitialDataSheetsByFacilityId(token: string, facilityId: string) {
		return this.http.get(ServiceUrls.getDataSheetsByFacilityId(token, facilityId)).
		pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res);
			})
		);
	}
	/**
	 * Gets data sheet list by facility id.
	 * @param token token of the user
	 * @param facilityId  id of the facility
	 * @param wsCallBack callback class
	 */
	public getDataSheetsByFacilityId(token: string, facilityId: string, wsCallBack: WsCallback) {
		this.http.get(ServiceUrls.getDataSheetsByFacilityId(token, facilityId)).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_DATA_SHEETS_BY_FACILITY_ID);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_DATA_SHEETS_BY_FACILITY_ID);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_DATA_SHEETS_BY_FACILITY_ID);
				}
			}
		);
	}

	public getInitialDocumentsByFacilityId(token: string, facilityId: string) {
		return this.http.get(ServiceUrls.getDocumentsByFacilityId(token, facilityId)).
		pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);					
				return throwError(res);
			})
		);
	}

	/**
	 * Gets documents list by facility id.
	 * @param token token of the user
	 * @param facilityId  id of the facility
	 * @param wsCallBack callback class
	 */
	public getDocumentsByFacilityId(token: string, facilityId: string, wsCallBack: WsCallback) {
		this.http.get(ServiceUrls.getDocumentsByFacilityId(token, facilityId)).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_DOCUMENTS_BY_FACILITY_ID);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_DOCUMENTS_BY_FACILITY_ID);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_DOCUMENTS_BY_FACILITY_ID);
				}
			}
		);
	}

	public getTagClassificationListWithAttributes(token: string, tagClassId: string, wsCallBack: WsCallback) {
		this.http.get(ServiceUrls.getTagClassificationListWithAttributes(token)).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_TAG_CLASSIFICATION_LIST_WITH_ATTRIBUTES);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_TAG_CLASSIFICATION_LIST_WITH_ATTRIBUTES);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_TAG_CLASSIFICATION_LIST_WITH_ATTRIBUTES);
				}
			}
		);
	}

	/**
	 * Get all systems for the project
	 * @param token user token
	 * @param wsCallBack  call back class
	 */
	public getSystemsByProjectId(token: string, wsCallBack: WsCallback) {
		this.http.get(ServiceUrls.getSystemsByProjectId(token)).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_SYSTEMS_BY_PROJECT_ID);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_SYSTEMS_BY_PROJECT_ID);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_SYSTEMS_BY_PROJECT_ID);
				}
			}
		);
	}

	/**
	 * Gets all components for a project.
	 * @param token token
	 * @param wsCallBack call back class
	 */
	public getComponentsByProjectId(token: string, wsCallBack: WsCallback) {
		this.http.get(ServiceUrls.getComponentsByProjectId(token)).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_COMPONENTS_BY_PROJECT_ID);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_COMPONENTS_BY_PROJECT_ID);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_COMPONENTS_BY_PROJECT_ID);
				}
			}
		);
	}

	/**
	 * Get documents for a project.
	 * @param token user token
	 * @param wsCallBack call back class
	 */
	public getDocuments(token: string, facilityId: string, wsCallBack: WsCallback) {
		this.http.get(ServiceUrls.getDocuments(token, facilityId)).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_DOCUMENTS);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_DOCUMENTS);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_DOCUMENTS);
				}
			}
		);
	}

	/**
	 * Get all tag suggestion mode list by token
	 * @param token user token
	 * @param wsCallBack  call back class
	 */
	public getAllTagSuggestionModelList(token: string, tagId: string, searchText: string, wsCallBack: WsCallback) {
		this.http.get(ServiceUrls.getAllTagSuggestionModelList(token, tagId, searchText)).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_ALL_TAG_SUGGESTION_MODEL_LIST);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_ALL_TAG_SUGGESTION_MODEL_LIST);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_ALL_TAG_SUGGESTION_MODEL_LIST);
				}
			}
		);
	}

	/**
	 * Get failure class by token
	 * @param token user token
	 * @param wsCallBack  call back class
	 */
	public getFailureClassList(token: string, wsCallBack: WsCallback) {
		this.http.get(ServiceUrls.getFailureClassList(token)).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_FAILURE_CLASS_LIST);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_FAILURE_CLASS_LIST);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_FAILURE_CLASS_LIST);
				}
			}
		);
	}

	/**
	 * Get safety critical list by token
	 * @param token user token
	 * @param wsCallBack  call back class
	 */
	public getSafetyCriticalList(token: string, wsCallBack: WsCallback) {
		this.http.get(ServiceUrls.getSafetyCriticalList(token)).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_SAFETY_CRITICAL_LIST);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_SAFETY_CRITICAL_LIST);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_SAFETY_CRITICAL_LIST);
				}
			}
		);
	}

	/**
	 * Get criticality list by token
	 * @param token user token
	 * @param wsCallBack  call back class
	 */
	public getCriticalityList(token: string, wsCallBack: WsCallback) {
		this.http.get(ServiceUrls.getCriticalityList(token)).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_CRITICALITY_LIST);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_CRITICALITY_LIST);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_CRITICALITY_LIST);
				}
			}
		);
	}

	/**
	 * Get equipment type list by token
	 * @param token user token
	 * @param wsCallBack  call back class
	 */
	public getEquipmentTypeList(token: string, wsCallBack: WsCallback) {
		this.http.get(ServiceUrls.getEquipmentTypeList(token)).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_EQUIPMENT_TYPE_LIST);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_EQUIPMENT_TYPE_LIST);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_EQUIPMENT_TYPE_LIST);
				}
			}
		);
	}

	/**
	 * Get discipline list by token
	 * @param token user token
	 * @param wsCallBack  call back class
	 */
	public getDisciplineList(token: string, wsCallBack: WsCallback) {
		this.http.get(ServiceUrls.getDisciplineList(token)).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_DISCIPLINE_LIST);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_DISCIPLINE_LIST);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_DISCIPLINE_LIST);
				}
			}
		);
	}

	/**
	 * Get all active asset groups
	 * @param token user token
	 * @param wsCallBack  callback class
	 */
	public getAllActiveAssetGroups(token: string, wsCallBack: WsCallback) {
		this.http.get(ServiceUrls.getAllActiveAssetGroups(token)).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_ALL_ACTIVE_ASSET_GROUPS);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_ALL_ACTIVE_ASSET_GROUPS);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_ALL_ACTIVE_ASSET_GROUPS);
				}
			}
		);
	}

	/**
	 * Get attributes by asset group
	 * @param token user token
	 * @param groupId group id
	 * @param wsCallBack callback method
	 */
	public getAssetGroupAttributes(token: string, groupId: string, wsCallBack: WsCallback) {
		this.http.get(ServiceUrls.getAssetGroupAttributes(token, groupId)).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_ASSET_GROUP_ATTRIBUTES);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_ASSET_GROUP_ATTRIBUTES);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_ASSET_GROUP_ATTRIBUTES);
				}
			}
		);
	}

	/**
	 * Get parents by asset group
	 * @param token user token
	 * @param groupId group id
	 * @param wsCallBack callback method
	 */
	public getAssetGroupParents(token: string, groupId: string, wsCallBack: WsCallback) {
		this.http.get(ServiceUrls.getAssetGroupParents(token, groupId)).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_ASSET_GROUP_PARENTS);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_ASSET_GROUP_PARENTS);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_ASSET_GROUP_PARENTS);
				}
			}
		);
	}

	/**
	 * Get all function codes
	 * @param token user token
	 * @param wsCallBack  callback class
	 */
	public getAllFunctionCodes(token: string, wsCallBack: WsCallback) {
		this.http.get(ServiceUrls.getAllFunctionCodes(token)).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_ALL_FUNCTION_CODES);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_ALL_FUNCTION_CODES);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_ALL_FUNCTION_CODES);
				}
			}
		);
	}

	/**
	 * Get all active vendors
	 * @param token user token
	 * @param wsCallBack  callback class
	 */
	public getAllVendors(token: string, wsCallBack: WsCallback) {
		this.http.get(ServiceUrls.getAllVendors(token)).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_ALL_VENDORS);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_ALL_VENDORS);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_ALL_VENDORS);
				}
			}
		);
	}

	/**
	 * Get attributes for an asset group
	 * @param token user token
	 * @param groupId  id of the asset group
	 * @param reqAttributeId  id of the asset group
	 * @param wsCallBack  callback class
	 */
	public getAssetAttributes(token: string, groupId: string, reqAttributeId: string, wsCallBack: WsCallback) {
		this.http.get(ServiceUrls.getAssetAttributes(token, groupId, reqAttributeId)).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_ASSET_ATTRIBUTES);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_ASSET_ATTRIBUTES);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_ASSET_ATTRIBUTES);
				}
			}
		);
	}

	/**
	 * Get value list by value list id
	 * @param token user token
	 * @param valueListId value list id
	 * @param reqAttributeId  id of attribute
	 * @param wsCallBack callback class
	 */
	public getValueListValues(token: string, valueListId: string, reqAttributeId: string, wsCallBack: WsCallback) {
		this.http.get(ServiceUrls.getValueListValues(token, valueListId, reqAttributeId)).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_VALUE_LIST_VALUES);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_VALUE_LIST_VALUES);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_VALUE_LIST_VALUES);
				}
			}
		);
	}

	/**
	 * Add a new tag
	 * @param token user token
	 * @param data post data
	 * @param wsCallBack callback class
	 */
	public addNewTag(token: string, data: string, wsCallBack: WsCallback) {
		this.http.post(ServiceUrls.addNewTag(token), data).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.ADD_NEW_TAG);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.ADD_NEW_TAG);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.ADD_NEW_TAG);
				}
			}
		);
	}

	/**
	 * Update an existing tag
	 * @param token user token
	 * @param data post data
	 * @param wsCallBack callback class
	 */
	public editNewTag(token: string, data: string, wsCallBack: WsCallback) {
		this.http.post(ServiceUrls.updateTag(token), data).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.UPDATE_TAG);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.UPDATE_TAG);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.UPDATE_TAG);
				}
			}
		);
	}

	/**
	 * Get project by project ID (token)
	 * @param token user token
	 * @param wsCallBack callback class
	 */
	public getProject(token: string, wsCallBack: WsCallback) {
		this.http.get(ServiceUrls.getProject(token)).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_PROJECT);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_PROJECT);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_PROJECT);
				}
			}
		);
	}

	public setItemData(id: string,  tagNo: string, cadNo: string, tagDesc: string, systemname: string, 
		documentName: string, facilityname: string, classificationId: string) {         
		  if (this.apiGridData != null && this.apiGridData != undefined && id != null) {
			  var rowNode = this.apiGridData.getRowNode(id);
			  if (rowNode != null && rowNode != undefined) {
			  
			  rowNode.setDataValue("tagNo", tagNo);
			  rowNode.setDataValue("cadNo", cadNo);
			  rowNode.setDataValue("description", tagDesc);
			  rowNode.setDataValue("systemName", systemname);
			  rowNode.setDataValue("documentName", documentName);
			  rowNode.setDataValue("facilityName", facilityname);
			  rowNode.setDataValue("classification",classificationId);
			  }
		  }
	 }

	/**
	 * Gets classifications for a project.
	 * @param token user token
	 * @param wsCallBack callback class
	 */
	public getTagClassifications(token: string, wsCallBack: WsCallback) {
		this.http.get(ServiceUrls.getTagClassifications(token)).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_TAG_CLASSIFICATION);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_TAG_CLASSIFICATION);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_TAG_CLASSIFICATION);
				}
			}
		);
	}

	/**
	 * Update description of a classification
	 * @param data {"userToken": "", "description": "", "classId": ""}
	 * @param wsCallBack callback class
	 */
	public updateClassificationDescription(data: any, wsCallBack: WsCallback) {
		this.http.post(ServiceUrls.UPDATE_CLASSIFICATION_DESCRIPTION, data).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.UPDATE_CLASSIFICATION_DESCRIPTION);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.UPDATE_CLASSIFICATION_DESCRIPTION);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.UPDATE_CLASSIFICATION_DESCRIPTION);
				}
			}
		);
	}

	/**
	 * Get categories of a classification
	 * @param token user token
	 * @param classId classification id
	 * @param wsCallBack callback class
	 */
	public getCategoriesByClassification(token: string, classId: string, wsCallBack: WsCallback) {
		this.http.get(ServiceUrls.getCategoriesByClassification(token, classId)).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_CATEGORIES_BY_CLASSIFICATION);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_CATEGORIES_BY_CLASSIFICATION);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_CATEGORIES_BY_CLASSIFICATION);
				}
			}
		);
	}

	/**
	 * Get attributes of a classification
	 * @param token user token
	 * @param classId classification id
	 * @param wsCallBack callback class
	 */
	public getAttributesByClassification(token: string, classId: string, wsCallBack: WsCallback) {
		this.http.get(ServiceUrls.getAttributesByClassification(token, classId)).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_ATTRIBUTES_BY_CLASSIFICATION);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_ATTRIBUTES_BY_CLASSIFICATION);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_ATTRIBUTES_BY_CLASSIFICATION);
				}
			}
		);
	}

	/**
	 * Get tag attributes list
	 * @param wsCallBack callback class
	 */
	public getTagAttributeTypes(wsCallBack: WsCallback) {
		this.http.get(ServiceUrls.GET_TAG_ATTRIBUTE_TYPES).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_TAG_ATTRIBUTE_TYPES);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_TAG_ATTRIBUTE_TYPES);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_TAG_ATTRIBUTE_TYPES);
				}
			}
		);
	}

	/**
	 * Update tag attribute sequence.
	 * @param data sequence update request
	 * @param wsCallBack callback class
	 */
	public updateTagAttributeSequence(data: any, wsCallBack: WsCallback) {
		this.http.post(ServiceUrls.UPDATE_TAG_ATTRIBUTE_SEQUENCE, data).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.UPDATE_TAG_ATTRIBUTE_SEQUENCE);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.UPDATE_TAG_ATTRIBUTE_SEQUENCE);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.UPDATE_TAG_ATTRIBUTE_SEQUENCE);
				}
			}
		);
	}

	public toggleTagClassificationAttributeRequireStatus(data: any, wsCallBack: WsCallback) {
		this.http.post(ServiceUrls.TOGGLE_TAG_CLASSIFICATION_ATTRIBUTE_REQUIRE_STATUS, data).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.TOGGLE_TAG_CLASSIFICATION_ATTRIBUTE_REQUIRE_STATUS);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.TOGGLE_TAG_CLASSIFICATION_ATTRIBUTE_REQUIRE_STATUS);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.TOGGLE_TAG_CLASSIFICATION_ATTRIBUTE_REQUIRE_STATUS);
				}
			}
		);
	}

	/**
	 * Remove tag classification attribute.
	 * @param data {userToken: '', itemId: ''}
	 * @param wsCallBack callback class
	 */
	public removeTagClassificationAttributeMap(data: any, wsCallBack: WsCallback) {
		this.http.post(ServiceUrls.REMOVE_TAG_CLASSIFICATION_ATTRIBUTE_MAP, data).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.REMOVE_TAG_CLASSIFICATION_ATTRIBUTE_MAP);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.REMOVE_TAG_CLASSIFICATION_ATTRIBUTE_MAP);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.REMOVE_TAG_CLASSIFICATION_ATTRIBUTE_MAP);
				}
			}
		);
	}

	/**
	 * Add a new classification
	 * @param data {userToken: '', name: '', description: ''}
	 * @param wsCallBack callback class
	 */
	public addNewClassification(data: any, wsCallBack: WsCallback) {
		this.http.post(ServiceUrls.ADD_NEW_CLASSIFICATION, data).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.ADD_NEW_CLASSIFICATION);
			},
			error => {
				if (error.status == "0") {
					var res = new WsResponse("", error.status, "", "");
					wsCallBack.onFail(res, WsType.ADD_NEW_CLASSIFICATION);
					return;
				}
				else if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.ADD_NEW_CLASSIFICATION);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.ADD_NEW_CLASSIFICATION);
				}
			}
		);
	}

	/**
	 * Get classification attributes for a project
	 * @param token user token
	 * @param wsCallBack callback class
	 */
	public getTagClassAttributeList(token: string, wsCallBack: WsCallback) {
		this.http.get(ServiceUrls.getTagClassAttributeList(token)).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_TAG_CLASSIFICATION_ATTRIBUTE_LIST);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_TAG_CLASSIFICATION_ATTRIBUTE_LIST);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_TAG_CLASSIFICATION_ATTRIBUTE_LIST);
				}
			}
		);
	}

	/**
	 * Check whether the given attribute name is already available.
	 * @param token use token
	 * @param attributeName name of the attribute
	 * @param wsCallBack callback class
	 */
	public tagAttTypeNameAvailability(data: any, wsCallBack: WsCallback) {
		this.http.post(ServiceUrls.tagAttTypeNameAvailability(), data).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.TAG_ATTRIBUTE_TYPE_NAME_AVAILABILITY);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.TAG_ATTRIBUTE_TYPE_NAME_AVAILABILITY);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.TAG_ATTRIBUTE_TYPE_NAME_AVAILABILITY);
				}
			}
		);
	}

	/**
	 * Add a new tag attribute.
	 * @param data {"userToken":"","attributeName": "","attributeType": ""}
	 * @param wsCallBack callback class
	 */
	public addNewTagAttributeType(data: any, wsCallBack: WsCallback) {
		this.http.post(ServiceUrls.ADD_TAG_ATTRIBUTE, data).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.ADD_TAG_ATTRIBUTE);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.ADD_TAG_ATTRIBUTE);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.ADD_TAG_ATTRIBUTE);
				}
			}
		);
	}

	/**
	 * Update an existing tag attribute.
	 * @param data {"userToken":"","id":"","name":"","type":""}
	 * @param wsCallBack callback class
	 */
	public updateTagAttributeType(data: any, wsCallBack: WsCallback) {
		this.http.post(ServiceUrls.UPDATE_TAG_ATTRIBUTE, data).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.UPDATE_TAG_ATTRIBUTE);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.UPDATE_TAG_ATTRIBUTE);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.UPDATE_TAG_ATTRIBUTE);
				}
			}
		);
	}

	/**
	 * Delete an existing tag attribute.
	 * @param data {"userToken":"","id":"<Required>","name":"","type":""}
	 * @param wsCallBack callback class
	 */
	public deleteTagAttributeType(data: any, wsCallBack: WsCallback) {
		this.http.post(ServiceUrls.DELETE_TAG_ATTRIBUTE, data).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.DELETE_TAG_ATTRIBUTE);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.DELETE_TAG_ATTRIBUTE);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.DELETE_TAG_ATTRIBUTE);
				}
			}
		);
	}

	/**
	 * Update dot notation.
	 * @param data {categoryId: '', attributeId: '', userToken" ''}
	 * @param wsCallBack callback class
	 */
	public updateTagAttributeDotNotation(data: any, wsCallBack: WsCallback) {
		this.http.post(ServiceUrls.UPDATE_TAG_ATTRIBUTE_NOTATION, data).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.UPDATE_TAG_ATTRIBUTE_NOTATION);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.UPDATE_TAG_ATTRIBUTE_NOTATION);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.UPDATE_TAG_ATTRIBUTE_NOTATION);
				}
			}
		);
	}

	/**
	 * Update Tag Class Attribute Type
	 * @param data {type: '', attributeId: '', userToken" ''}
	 * @param wsCallBack callback class
	 */
	public updateTagClassAttributeType(data: any, wsCallBack: WsCallback) {
		this.http.post(ServiceUrls.UPDATE_TAG_CLASS_ATTRIBUTE_TYPE, data).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.UPDATE_TAG_CLASS_ATTRIBUTE_TYPE);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.UPDATE_TAG_CLASS_ATTRIBUTE_TYPE);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.UPDATE_TAG_CLASS_ATTRIBUTE_TYPE);
				}
			}
		);
	}

	/**
	 * Update Tag Class Attribute Value
	 * @param token user token
	 * @param data {tagId:'', tagAttributes:[]}
	 * @param wsCallBack callback class
	 */
	public updateTagClassAttributeValue(token:string, data: any, wsCallBack: WsCallback) {
		console.log("updateTagClassAttributeValue");
		this.http.post(ServiceUrls.updateTagAttributeValue(token), data).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.UPDATE_TAG_CLASS_ATTRIBUTE_VALUE);
			},
			error => {
				console.log(error.status)
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					console.log(val)
				 	var modified = JSON.parse(JSON.stringify(val));
				 	console.log(modified)
				 	var res = new WsResponse(
				 		modified.status.description,
				 		modified.status.code,
				 		modified.status.name,
				 		modified.payload);
				 	wsCallBack.onFail(res, WsType.UPDATE_TAG_CLASS_ATTRIBUTE_VALUE);
				 } else {
				 	//browser related issues
				 	var res = new WsResponse("Unknown error happened");
				 	wsCallBack.onFail(res, WsType.UPDATE_TAG_CLASS_ATTRIBUTE_VALUE);
				 }
			}
		);
	}

	/**
	 * Get value list.
	 * @param token user token
	 * @param wsCallBack callback class
	 */
	public getValueList(token: string, wsCallBack: WsCallback) {
		this.http.get(ServiceUrls.getValueList(token)).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_CMMS_VALUE_LIST);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_CMMS_VALUE_LIST);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_CMMS_VALUE_LIST);
				}
			}
		);
	}

	/**
	 * Add a new value list item.
	 * @param data {userToken: '', name: '', description:'', id: ''}
	 * @param wsCallBack callback class
	 */
	public addValueList(data: any, wsCallBack: WsCallback) {
		this.http.post(ServiceUrls.ADD_VALUE_LIST, data).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.ADD_VALUE_LIST);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.ADD_VALUE_LIST);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.ADD_VALUE_LIST);
				}
			}
		);
	}

	/**
	 * Update existing value list.
	 * @param data {userToken: '', name: '', description:'', id: ''}
	 * @param wsCallBack callback class
	 */
	public updateValueList(data: any, wsCallBack: WsCallback) {
		this.http.post(ServiceUrls.UPDATE_VALUE_LIST, data).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.UPDATE_VALUE_LIST);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.UPDATE_VALUE_LIST);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.UPDATE_VALUE_LIST);
				}
			}
		);
	}

	/**
	 * Gets default items for value list.
	 */
	public getDefaultItemsForValueList(token: any, wsCallBack: WsCallback) {
		this.http.get(ServiceUrls.getDefaultItemsForValueList(token)).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_DEFAULT_ITEMS_FOR_VALUE_LIST);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_DEFAULT_ITEMS_FOR_VALUE_LIST);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_DEFAULT_ITEMS_FOR_VALUE_LIST);
				}
			}
		);
	}

	/**
	 * Get attribute list for a value list.
	 * @param token user token
	 * @param valueListId value list id
	 * @param wsCallBack callback class
	 */
	public getValueListAttributes(token: any,valueListId: any, wsCallBack: WsCallback) {
		this.http.get(ServiceUrls.getValueListAttributes(token, valueListId)).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_VALUE_LIST_ATTRIBUTES);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_VALUE_LIST_ATTRIBUTES);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_VALUE_LIST_ATTRIBUTES);
				}
			}
		);
	}

	/**
	 * Update value list attribute's sequence.
	 * @param data {token: '', orders: ''}
	 * @param wsCallBack callback class
	 */
	public setValueListAttrOrder(data: any, wsCallBack: WsCallback) {
		this.http.post(ServiceUrls.UPDATE_VALUE_LIST_SEQUENCE, data).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.UPDATE_VALUE_LIST_SEQUENCE);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.UPDATE_VALUE_LIST_SEQUENCE);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.UPDATE_VALUE_LIST_SEQUENCE);
				}
			}
		);
	}

	/**
	 * Update value list attribute.
	 * @param data {"userToken":"","attributeId":"","attributeName":""}
	 * @param wsCallBack callback class
	 */
	public updateValueListAttribute(data: any, wsCallBack: WsCallback) {
		this.http.post(ServiceUrls.UPDATE_VALUE_LIST_ATTRIBUTE, data).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.UPDATE_VALUE_LIST_ATTRIBUTE);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.UPDATE_VALUE_LIST_ATTRIBUTE);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.UPDATE_VALUE_LIST_ATTRIBUTE);
				}
			}
		);
	}

	/**
	 * Add a new value list attribute.
	 * @param data {"userToken":"","valueListId":"","attributeName":"","sequence":""}
	 * @param wsCallBack callback class
	 */
	public addValueListAttribute(data: any, wsCallBack: WsCallback) {
		this.http.post(ServiceUrls.ADD_VALUE_LIST_ATTRIBUTE, data).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.ADD_VALUE_LIST_ATTRIBUTE);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.ADD_VALUE_LIST_ATTRIBUTE);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.ADD_VALUE_LIST_ATTRIBUTE);
				}
			}
		);
	}

	/**
	 * Delete value list attribute.
	 * @param data {"userToken":"","attributeId":""}
	 * @param wsCallBack callback class
	 */
	public deleteValueListAttribute(data: any, wsCallBack: WsCallback) {
		this.http.post(ServiceUrls.DELETE_VALUE_LIST_ATTRIBUTE, data).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.DELETE_VALUE_LIST_ATTRIBUTE);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.DELETE_VALUE_LIST_ATTRIBUTE);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.DELETE_VALUE_LIST_ATTRIBUTE);
				}
			}
		);
	}

	/**
	 * Add or update equipment field for value list.
	 * @param data {"userToken":"","valueListId":"","equipmentFieldId":"Discipline"}
	 * @param wsCallBack callback class
	 */
	public addEquipmentField(data: any, wsCallBack: WsCallback) {
		this.http.post(ServiceUrls.ADD_EQUIPMENT_FIELD, data).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.ADD_EQUIPMENT_FIELD);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.ADD_EQUIPMENT_FIELD);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.ADD_EQUIPMENT_FIELD);
				}
			}
		);
	}

	/**
	 * Update value list name.
	 * @param data {userToken: '', name: '', description:'', id: ''}
	 * @param wsCallBack callback class
	 */
	public updateValueListName(data: any, wsCallBack: WsCallback) {
		this.http.post(ServiceUrls.UPDATE_VALUE_LIST_NAME, data).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.UPDATE_VALUE_LIST_NAME);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.UPDATE_VALUE_LIST_NAME);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.UPDATE_VALUE_LIST_NAME);
				}
			}
		);
	}

	/**
	 * Delete value list item.
	 * @param data {userToken: '', name: '', description:'', id: ''}
	 * @param wsCallBack callback class
	 */
	public deleteValueList(data: any, wsCallBack: WsCallback) {
		this.http.post(ServiceUrls.DELETE_VALUE_LIST, data).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.DELETE_VALUE_LIST);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.DELETE_VALUE_LIST);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.DELETE_VALUE_LIST);
				}
			}
		);
	}

	/**
	 * Assign a new attribute to a classification.
	 * @param data {userToken:"",documentTypeId:"",addAttrId:"",classId:""}.
	 * @param wsCallBack callback class.
	 */
	public assignTagAttribute(data: any, wsCallBack: WsCallback) {
		this.http.post(ServiceUrls.ASSIGN_TAG_ATTRIBUTE, data).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.ASSIGN_TAG_ATTRIBUTE);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.ASSIGN_TAG_ATTRIBUTE);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.ASSIGN_TAG_ATTRIBUTE);
				}
			}
		);
	}

	/**
	 * Get preconditions of the tag add process.
	 * @param token  token.
	 * @param wsCallBack  callback class.
	 */
	public getTagAddPreConditions(token: string, wsCallBack: WsCallback) {
		this.http.get(ServiceUrls.getTagAddPreConditions(token)).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_TAG_ADD_PRE_CONDITIONS);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_TAG_ADD_PRE_CONDITIONS);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_TAG_ADD_PRE_CONDITIONS);
				}
			}
		);
	}

	/**
	 * Search alternate tag.
	 * @param token user token
	 * @param tagNumber  search string
	 * @param facilityId facility id (nullable)
	 * @param startIndex  start index
	 * @param size  size
	 * @param wsCallBack callback class
	 */
	public searchAlternateTag(token: string, tagNumber: string, facilityId: string, startIndex: number, size: number, wsCallBack: WsCallback) {
		this.http.get(ServiceUrls.searchAlternateTag(token, tagNumber, facilityId, startIndex, size)).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.SEARCH_ALTERNATE_TAG);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.SEARCH_ALTERNATE_TAG);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.SEARCH_ALTERNATE_TAG);
				}
			}
		);
	}

	/**
	 * Get next available equipment number for the project.
	 * @param token user token
	 * @param wsCallBack callback class
	 */
	public getNextAvailableEquipmentNo(token: string, wsCallBack: WsCallback) {
		this.http.get(ServiceUrls.getNextAvailableEquipmentNo(token)).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_NEXT_AVAILABLE_EQUIPMENT_NUMBER);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_NEXT_AVAILABLE_EQUIPMENT_NUMBER);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_NEXT_AVAILABLE_EQUIPMENT_NUMBER);
				}
			}
		);
	}

	/**
	 * Update classification name.
	 * @param data {"userToken":"","classId":"","className":""}
	 * @param wsCallBack callback class
	 */
	public updateTagClassificationName(data: any, wsCallBack: WsCallback) {
		this.http.post(ServiceUrls.UPDATE_TAG_CLASSIFICATION_NAME, data).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.UPDATE_TAG_CLASSIFICATION_NAME);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.UPDATE_TAG_CLASSIFICATION_NAME);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.UPDATE_TAG_CLASSIFICATION_NAME);
				}
			}
		);
	}

	/**
	 * Delete a tag classification.
	 * @param data {"userToken":"","classId":""}
	 * @param wsCallBack callback class
	 */
	public deleteTagClassification(data: any, wsCallBack: WsCallback) {
		this.http.post(ServiceUrls.DELETE_TAG_CLASSIFICATION, data).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.DELETE_TAG_CLASSIFICATION);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.DELETE_TAG_CLASSIFICATION);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.DELETE_TAG_CLASSIFICATION);
				}
			}
		);
	}

	/**
	 * 
	 * @param {string} token
	 * @param {string} tagId
	 * @param {string} disable
	 * @memberof TagsService
	 */
	public changeTagStatus(token: string, tagId: string, disable: string) {
		this.http.get(ServiceUrls.changeTagStatus(token, tagId, disable)).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
					this.callback.onSuccess(res, WsType.CHANGE_TAG_STATUS);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
						this.callback.onFail(res, WsType.CHANGE_TAG_STATUS);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					this.callback.onFail(res, WsType.CHANGE_TAG_STATUS);
				}
			}
		);
	}

	public deleteTag(token: string, tagId: string) {
		this.http.get(ServiceUrls.deleteTagById(token, tagId)).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
					this.callback.onSuccess(res, WsType.DELETE_TAG);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
						this.callback.onFail(res, WsType.DELETE_TAG);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					this.callback.onFail(res, WsType.DELETE_TAG);
				}
			}
		);
	}

	/**
     * Get tag by tag id used in commissioning.
     * @param request request
     * @param wsCallBack call back object
     */
	public getTagListByProjectIdUsedInCommissioning(token: string, wsCallBack: WsCallback) {
		console.log(token);
		this.http.get(ServiceUrls.getTagListByProjectIdUsedInCommissioning(token)).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_TAG_BY_TAG_ID_USED_IN_COMMISSIONING);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_TAG_BY_TAG_ID_USED_IN_COMMISSIONING);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_TAG_BY_TAG_ID_USED_IN_COMMISSIONING);
				}
			}
		);
	}

	 /**
     * Get tag by tag id.
     * @param request request
     * @param wsCallBack call back object
     */
	public getTagByTagIdNew(request: string, wsCallBack: WsCallback) {
		console.log(request);

		this.http.post(ServiceUrls.GET_TAG_BY_TAG_ID, request).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_TAG_BY_TAG_ID_NEW);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_TAG_BY_TAG_ID_NEW);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_TAG_BY_TAG_ID);
				}
			}
		);
	}

    /**
    * Define URL for get tag list by tag search criteria.
    */
	public getTagSearchURL(token: String) {
		let url = ServiceUrls.TAGS_SEARCH_VIEW + '?token=' + token;
		return url;
	}
}

class Request {
	constructor(private tagNo: String, private cadNo: String, private description: String, private systemName: String, private documentName: String, private facilityName: String, private hasText: String, private usedInCommissioning: Boolean) { }
}
