import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CommonUtil } from 'src/app/util/common/common-util';
import { UserVariable } from 'src/app/util/common/user-variable';
import { ServiceUrls } from 'src/app/util/service-urls';
import { WsResponse } from 'src/app/util/ws-response.model';

@Injectable({
  providedIn: 'root'
})
export class ManagePunchlistImageService {

  constructor(private http: HttpClient, private commonUtil: CommonUtil) { }

  //get project list
  getProjectList(id: any) {
		console.log("============PROJECT LIST==========");
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateLogin(token)){
            return;
        }
		let url = this.getProjectListByUrl(id, token);
        return this.http.post(url, JSON.stringify(new Request())).
            pipe(
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                }),
                catchError(error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    return throwError(res)
                })
            );
		
	}

  public getProjectListByUrl(id: any, token: String) {
		let url = ServiceUrls.PROJECTS_LIST +  id + "/getProjectListRoles.json"  + '?token=' + token;;
        return url;
    }

  //get punchlist with photos
  getAllPunchlistsWithPhotosToUploadToS3(projectid: any) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateLogin(token)) {
      return;
    }
    const options = {
      params: new HttpParams().set("token", token).set("projectid", projectid)
    }

    return this.http.get(ServiceUrls.GET_PUNCHLIST_IMAGE_DATA_S3, options).
      pipe(
        map(response => {
          var modified = JSON.parse(JSON.stringify(response));
          return new WsResponse(
            modified.status.description,
            modified.status.name,
            modified.status.code,
            modified.payload);
        }),
        catchError(error => {
          var modified = JSON.parse(JSON.stringify(error));
          var res = new WsResponse(
            modified.error.status.description,
            modified.error.status.name,
            modified.error.status.code,
            modified.error.payload);
          return throwError(res)
        })
      )
  }

  public getPLImageUrl(id: any) {
    let url = ServiceUrls.USER_PROJECT_VIEW + id + "/getAllPunchlistsWithPhotosToUploadToS3.json";
    return url
  }

  
  copyImagesToS3(projectId: string) {
    let token = UserVariable.getUserToken();
    if(!this.commonUtil.validateToken(token)){
      return;
    }
    let url = this.copyImagesToS3URL();
    const options = { params: new HttpParams().set("token", token).set("projectid", projectId) }
    return this.http.get(url, options).
        pipe(
            map(response => {
                var modified = JSON.parse(JSON.stringify(response));
                return new WsResponse(
                    modified.status.description,
                    modified.status.name,
                    modified.status.code,
                    modified.payload);
            }),
            catchError(error => {
                var modified = JSON.parse(JSON.stringify(error));
                var res = new WsResponse(
                    modified.error.status.description,
                    modified.error.status.name,
                    modified.error.status.code,
                    modified.error.payload);
                return throwError(res)
            })
        );
  }

  
  public copyImagesToS3URL() {
    let url = ServiceUrls.PUNCHLISTS + "copyimages/copyPunchlistImagesToS3.json";
    return url;
  }

}

class Request {
  constructor() { }
}
