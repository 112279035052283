<div class="modal-header py-2">
    <h5 *ngIf="!edit" class="modal-title">
        {{ 'CS_ADD_EDIT.ADD_CS' | translate }}
    </h5>
    <h5 *ngIf="edit" class="modal-title">
        {{ 'CS_ADD_EDIT.EDIT_CS' | translate }}
    </h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body" id="csaddedit">
    <form>
        <fieldset>
            <div class="form-group mb-2">
                <div class="mb-1">
                    <label for="csName">{{ 'CS_ADD_EDIT.CS_NAME' | translate }}</label>
                    <div class="badge-required mx-2"></div>
                    <input *ngIf="edit" id="cs_edit_name" type="text" class="form-control form-control-sm"
                        [value]="name" maxlength="50" (change)="change($event, 'name')">
                    <input #csName *ngIf="!edit" type="text" class="form-control form-control-sm" id="csName"
                        maxlength="50">
                </div>
                <div class="mt-4 dropdown-100 ">
                    <label>{{ 'CS_ADD_EDIT.CS_TYPE' | translate }}</label>
                    <div class="badge-required mx-2"></div>
                    <ng-select #type id="cs_type" class="select-sm" [allowClear]="true" [items]="typeList"
                        (dropdownOpened)="closeOtherElems(type)"
                        placeholder="{{ 'CS_ADD_EDIT.SELECT_TYPE' | translate }}" [active]="selectType"
                        (selected)="selectedType($event)" (removed)="removedType($event)"> </ng-select>
                </div>
                <div class="mt-4">
                    <label for="csCode">{{ 'CS_ADD_EDIT.CS_CODE' | translate }}</label>
                    <div class="badge-required mx-2"></div>
                    <input *ngIf="edit" id="cs_edit_code" type="text" class="form-control form-control-sm"
                        [value]="code" maxlength="50" (change)="change($event, 'code')">
                    <input #csCode *ngIf="!edit" type="text" class="form-control form-control-sm" id="csCode"
                        maxlength="50">
                </div>
                <div class="mt-4 dropdown-100 ">
                    <label>{{ 'CS_ADD_EDIT.CS_DISCIPLINE' | translate }}</label>
                    <div class="badge-required mx-2"></div>
                    <ng-select #discipline class="select-more-height select-sm" id="cs_discipline"
                        (dropdownOpened)="closeOtherElems(discipline)" [allowClear]="true" [items]="disciplineList"
                        [active]="selectDiscipline" placeholder="{{ 'CS_ADD_EDIT.SELECT_DISCIPLINE' | translate }}"
                        (selected)="selectedDiscipline($event)" (removed)="removedDiscipline($event)">
                    </ng-select>
                </div>
                <div class="mt-4 dropdown-100">
                    <div class="form-group">
                        <label for="cs_funccodes" class="mb-1">{{ 'CS_ADD_EDIT.CS_FUNC_CODES' | translate }}</label>
                        <tag-input name="cs_funccodes" id="cs_funccodes" [removable]='true'
                            secondaryPlaceholder="{{'CS_ADD_EDIT.SELECT_FUNC_CODE' | translate}}"
                            [(ngModel)]='selectedFunctionCodeList'
                            placeholder="{{'CS_ADD_EDIT.SELECT_FUNC_CODE' | translate}}" [onlyFromAutocomplete]="true"
                            [clearOnBlur]="true" [displayBy]="'text'" [identifyBy]="'id'"
                            (onAdd)="selectItemFromSuggestions($event)" (onRemove)="removeSelectedFuncCode($event)">
                            <tag-input-dropdown [showDropdownIfEmpty]="true" [keepOpen]="false"
                                [autocompleteItems]="functionCodeList" [displayBy]="'text'" [identifyBy]="'id'"
                                [limitItemsTo]=10 [appendToBody]="false">
                            </tag-input-dropdown>

                        </tag-input>
                    </div>
                </div>
            </div>
        </fieldset>
    </form>
</div>
<div class="modal-footer">
    <button type="button" id="cs_cancel" class="btn btn-secondary btn-sm float-left" (click)="cancel()">{{
        'CS_ADD_EDIT.CANCEL'| translate }}</button>
    <button *ngIf="!edit" type="submit" id="cs_add" class="btn btn-primary btn-sm float-left"
        (click)="saveChecksheet()">{{ 'CS_ADD_EDIT.ADD' | translate }}</button>
    <button *ngIf="edit" type="submit" id="cs_update" class="btn btn-primary btn-sm float-left"
        (click)="saveChecksheet()">{{ 'CS_ADD_EDIT.SAVE' | translate }}</button>
</div>