<div class="flex-row" style="overflow-y: auto; overflow-x: hidden" [style.height.px]="divHeight">
    <div class="col-sm-12">
        <ag-grid-angular #agGrid style="width: 100%;height: 465px;" class="ag-theme-balham" id="myGrid"
             [enableFilter]="true" [rowData]="rowData" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }" [columnDefs]="columnDefs"
            [rowSelection]="'multiple'"  [rowMultiSelectWithClick]="false" [defaultColDef]="defaultColDef"
            (rowSelected)="onRowSelected($event)"  [getRowNodeId]="getRowNodeId"
            (gridReady)="onGridReady($event)" (lastChildChanged)="onlastChildChanged($event)"
            [rowSelection]="rowSelection" [enableRangeSelection]="true" [treeData]="true" 
            [animateRows]="true" [getDataPath]="getDataPath" [groupDefaultExpanded]="groupDefaultExpanded" 
            [autoGroupColumnDef]="autoGroupColumnDef" [frameworkComponents]="frameworkComponents" [overlayLoadingTemplate]="overlayLoadingTemplate" [overlayNoRowsTemplate]="overlayNoRowsTemplate">
        </ag-grid-angular>
    </div>
    <div class="col-sm-12">
        <div class="card my-3" *ngIf= "detailedPreviewType == 'Course'">
            <div class="card-header py-1">
                <p class="col-sm-6 my-1 float-left h6 px-0">{{ 'ICE_COURSE.COURSE_DETAILS' | translate }}</p>
            </div>
            <div class="card-body py-1">
                <div class="my-2" *ngIf= "displayCourseData != null">
                    <div class="form-group form-row mb-2">
                    <div class="col-sm-2 col-sm-25 p-1">
                        <div class="card card-secondary p-2" style="background-color: #f1f1f1;">
                            <div class="card eing-card-body mb-1" style="background-color: #fafafa;border: 0px">
                                <div class="card-header p-1" style="background-color: #fafafa;border: 0px">
                                    <span class="badge badge-secondary">
                                        {{ 'ICE_COURSE.COURSE.IMAGE_LABEL' | translate }}
                                    </span>
                                </div>
                                <div class="card-body" *ngIf= "displayCourseData.CourseImage == ''">
                                    {{'ICE_COURSE.NOIMAGE_DISPLAY' | translate }}
                                </div>
                                <div *ngIf= "displayCourseData.CourseImage != ''">
                                <div class="card-body pl-0 pr-1 pb-0" style="padding-bottom: .5rem !important" (click)="showLargeImage(largeImage,displayCourseData.CourseImageId)">
                                    <img class="img-fluid" 
                                    style="width: 8rem;display: block;margin-left: auto;margin-right: auto;"
                                    src="data:image/jpeg;base64,{{displayCourseData.CourseImage}}"
                                    alt="">
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-2 col-sm-25 p-1">
                        <div class="card card-secondary p-2" style="background-color: #f1f1f1;">
                            <div class="card eing-card-body mb-1" style="background-color: #fafafa;border: 0px">
                                <div class="card-header p-1" style="background-color: #fafafa;border: 0px">
                                    <span class="badge badge-secondary">{{ 'ICE_COURSE.COURSE.ICON_LABEL' | translate }}</span>
                                </div>
                                <div class="card-body" *ngIf= "displayCourseData.CourseIcon == ''">
                                    {{'ICE_COURSE.NOICON_DISPLAY' | translate }}
                                </div>
                                <div *ngIf= "displayCourseData.CourseIcon != ''">
                                <div class="card-body pl-0 pr-1 pb-0" style="padding-bottom: .5rem !important" (click)="showLargeImage(largeImage,displayCourseData.CourseIconId)">
                                    <img class="img-fluid" 
                                    style="width: 8rem;display: block;margin-left: auto;margin-right: auto;"
                                    src="data:image/jpeg;base64,{{displayCourseData.CourseIcon}}"
                                    alt="">
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card my-3" *ngIf= "detailedPreviewType == 'Topic'">
            <div class="card-header py-1">
                <p class="col-sm-6 my-1 float-left h6 px-0">{{ 'ICE_COURSE.TOPIC_DETAILS' | translate }}</p>
            </div>
            <div class="card-body py-1" *ngIf= "displayCourseData.TopicImage==''">
            <div class="my-2">
                <div class="col-sm-12">
                    {{ 'ICE_COURSE.NOIMAGE_DISPLAY' | translate }}
                </div>
            </div>
            </div>
            <div class="card-body py-1" *ngIf= "displayCourseData.TopicImage !=''">
                <div class="my-2">
                    <div class="col-sm-12 px-0">
                        <div class="p-1">
                            <div class="card card-secondary p-2" style="background-color: #f1f1f1;">
                                <div class="card eing-card-body mb-1" style="background-color: #fafafa;border: 0px">
                                <div >
                                    <div class="card-body pl-0 pr-1 pb-0" style="padding-bottom: .5rem !important" (click)="showLargeImage(largeImage,displayCourseData.TopicImageId)">
                                    <img class="img-fluid" 
                                    style="width: 8rem;display: block;margin-left: auto;margin-right: auto;"
                                    src="data:image/jpeg;base64,{{displayCourseData.TopicImage}}"
                                    alt="">
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card my-3" *ngIf= "detailedPreviewType == 'TopicContent'">
            <div *ngIf= "displayCourseData.TopicContentImage != ''"  class="card-header py-1">
                <p class="col-sm-6 my-1 float-left h6 px-0">{{ 'ICE_COURSE.TOPICCONTENT_DETAILS_IMAGE' | translate }}</p>
            </div>
            <div *ngIf= "displayCourseData.TopicContentVedio != ''"  class="card-header py-1">
                <p class="col-sm-6 my-1 float-left h6 px-0">{{ 'ICE_COURSE.TOPICCONTENT_DETAILS_VIDEO' | translate }}</p>
            </div>
            <div *ngIf= "displayCourseData.TopicContentModel != ''"  class="card-header py-1">
                <p class="col-sm-6 my-1 float-left h6 px-0">{{ 'ICE_COURSE.TOPICCONTENT_DETAILS_THREEDMODEL' | translate }}</p>
            </div>
            <div class="card-body py-1" *ngIf= "displayCourseData.TopicContentImage=='' 
                && displayCourseData.TopicContentVedio=='' && displayCourseData.TopicContentModel==''">
            <div class="my-2">
                <div class="col-sm-12">
                    {{ 'ICE_COURSE.NOTC_DISPLAY' | translate }}
                </div>
            </div>
            </div>
            <div class="card-body py-1" *ngIf= "displayCourseData.TopicContentImage != '' 
                || displayCourseData.TopicContentVedio != '' || displayCourseData.TopicContentModel != ''">
                <div class="my-2">
                    <div class="col-sm-12 px-0">
                        <div class="p-1">      
                            <div *ngIf="displayCourseData.TopicContentImage != ''" class="card card-secondary p-2">
                                <div class="card eing-card-body mb-1" style="background-color: #fafafa;border: 0px">
                                <div>
                                    <div class="card-body pl-0 pr-1 pb-0" style="padding-bottom: .5rem !important; background-color: #fafafa;border: 0px" (click)="showLargeImage(largeImage,displayCourseData.TopicContentImageId)">
                                    <img class="img-fluid" 
                                    style="width: 8rem;display: block;margin-left: auto;margin-right: auto;"
                                    src="data:image/jpeg;base64,{{displayCourseData.TopicContentImage}}"
                                    alt="">
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div *ngIf="displayCourseData.TopicContentVedio != ''" class="card" 
                            style="width: 16rem; height: 10rem">
                                <video class="img-fluid" style="width: 16rem; height: 10rem" controls [poster]="displayCourseData.TopicContentVedioPosterFrameURL"
                                    [src]="displayCourseData.TopicContentVedio" type="video/mp4"> 
                                </video>
                            </div>
                            <div *ngIf="displayCourseData.TopicContentModel != ''" class="card" style="width: 8rem;">
                                <div class="form-group">
                                <img class="img-fluid"  style="width: 8rem;" [src]="displayCourseData.TopicContentModel">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card my-3" *ngIf= "detailedPreviewType == 'LearningCheck'">
            <div class="card-header py-1">
                <p class="col-sm-6 my-1 float-left h6 px-0">{{ 'ICE_COURSE.LEARNINGCHECK_DETAILS' | translate }}</p>
            </div>
            <div class="card-body py-1" *ngIf= "displayLCData == ''">
            <div class="my-2">
                <div class="col-sm-12">
                    {{ 'ICE_COURSE.NOLCDATA_DISPLAY' | translate }}
                </div>
            </div>
        </div>
            <div class="my-2" *ngIf= "displayLCData != '' && displayLCData != undefined">
                <div class="card-body p-0">
                    <div *ngFor="let question of displayLCData | orderBy : 'orderNum'; let q = index" class="card my-2">
                        <div class="card-body container-fluid p-2">
                            <div class="col-sm-12 float-left px-0">
                                <div class="input-group input-group-sm float-left">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1">
                                            <span class="badge badge-warning mr-2">
                                                {{question.orderNumber}} 
                                            </span>
                                            {{getStringFromType(question.questionType)}} 
                                        </span>
                                    </div>
                                    <div class="col-sm-9 float-left pl-0 pr-0">
                                        <div class="border rounded" style="padding: .350em; margin-bottom: 0em !important;">
                                            <div rows="2" [innerHTML]="question.descriptionXML"> 
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                            <!-- question section -->
                            <div class="col-sm-12 float-left px-0 my-2">
                                <div [ngSwitch]="question.questionType">
                                    <div *ngSwitchCase="'singleanswer'"> 
                                        <form *ngFor="let answer of question.answers | orderBy : 'orderNum'; let sa = index">
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="saqRadioAnswer"
                                                value="True" [checked]="answer.status" disabled>
                                                <label class="form-check-label" for="saqRadioAnswer" [innerHTML]="answer.answerTextXML">
                                                </label>
                                            </div>
                                        </form>
                                    </div>
                                    <div *ngSwitchCase="'multipleanswer'">
                                        <form *ngFor="let answer of question.answers  | orderBy : 'orderNum'; let ma = index" >
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" name="maqCheckBoxAnswer"
                                                [checked]="answer.status" disabled>
                                                <label class="form-check-label" for="maqCheckBoxAnswer" [innerHTML]="answer.answerTextXML">
                                                </label>
                                            </div>
                                        </form>
                                    </div>
                                    <div *ngSwitchCase="'truefalse'">
                                        <form class="ml-4">
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="tfqAnswer"
                                                value="True" [checked]=" question.answers[0].isCorrect == 'y'" disabled>
                                                <label class="form-check-label" for="tfqTrue">
                                                {{ 'ICE_LEARNING_CHECK.TRUE' | translate }}
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="tfqAnswer"
                                                value="False" [checked]=" question.answers[0].isCorrect == 'n'" disabled>
                                                <label class="form-check-label" for="tfqFalse">
                                                {{ 'ICE_LEARNING_CHECK.FALSE' | translate }}
                                                </label>
                                            </div>
                                        </form>
                                    </div>
                                    <div *ngSwitchCase="'dragdrop'">
                                        <form class="">
                                            <div class="form-group form-row mb-2">
                                                <div class="col-sm-3 p-1" 
                                                *ngFor="let number of question.answers[0].answerSlots | orderBy : 'orderNum'; let j = index">
                                                <div class="card card-secondary p-2" style="background-color: #f1f1f1;">
                                                <div class="card ddq-card-body mb-1" style="background-color: #fafafa;border: 0px">
                                                    <div class="card-header p-1" style="background-color: #fafafa;border: 0px">
                                                    <span class="badge badge-secondary">Left</span>
                                                    </div>
                                                    <div *ngIf= " 
                                                    question.answers[0].answerSlots[j].answerCells[0].isTextEnabled ">
                                                    <div class="card-body " [innerHTML]= "question.answers[0].answerSlots[j].answerCells[0].cellText"> 
                                                    </div>
                                                    </div>
                                                    <div *ngIf= "question.answers[0].answerSlots[j].answerCells[0].isPictureEnabled">
                                                    <div class="card-body pl-0 pr-1 pb-0" style="padding-bottom: .5rem !important" (click)="showLargeImage(largeImage,question.answers[0].answerSlots[j].answerCells[0].graphicId)"> 
                                                        <img class="img-fluid" style="width: 8rem;display: block;margin-left: auto;margin-right: auto;" 
                                                        src="data:image/jpeg;base64,{{question.answers[0].answerSlots[j].answerCells[0].thumbnailImage}}"
                                                        alt="{{question.answers[0].answerSlots[j].answerCells[0].title}}">
                                                    </div>
                                                    </div>
                                                </div>
                                                <div class="card ddq-card-body mt-1" style="background-color: #fafafa;border: 0px">
                                                    <div class="card-header p-1" style="background-color: #fafafa;border: 0px">
                                                    <span class="badge badge-secondary">Right</span>
                                                    </div>
                                                    <div *ngIf= " 
                                                    question.answers[0].answerSlots[j].answerCells[1].isTextEnabled ">
                                                    <div class="card-body" [innerHTML]= "question.answers[0].answerSlots[j].answerCells[1].cellText">
                                                        </div>
                                                    </div>
                                                    <div *ngIf= "question.answers[0].answerSlots[j].answerCells[1].isPictureEnabled">
                                                        <div class="card-body pl-0 pr-1 pb-0" style="padding-bottom: .5rem !important" (click)="showLargeImage(largeImage,question.answers[0].answerSlots[j].answerCells[1].graphicId)">
                                                        <img class="img-fluid" style="width: 8rem;display: block;margin-left: auto;margin-right: auto;"
                                                        src="data:image/jpeg;base64,{{question.answers[0].answerSlots[j].answerCells[1].thumbnailImage}}"
                                                        alt="{{question.answers[0].answerSlots[j].answerCells[1].title}}">
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div *ngSwitchCase="'fillintheblank'">
                                    <form>
                                        <div class="form-group">
                                        <label for="fbqAnswerlabel mb-1">
                                            {{ 'ICE_LEARNING_CHECK.ANSWER.TITLE_LABEL' | translate }}
                                        </label>
                                        <div [innerHTML]= "">

                                        </div>
                                        </div>
                                    </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card my-3" *ngIf= "detailedPreviewType == 'GameEIG'">
            <div class="card-header py-1">
                <p class="col-sm-6 my-1 float-left h6 px-0">{{ 'ICE_COURSE.GameEIG_DETAILS' | translate }}</p>
            </div>
            <div class="card-body py-1" *ngIf= "displayEIGData == ''">
                <div class="my-2">
                    <div class="col-sm-12">
                        {{ 'ICE_COURSE.NOGAMEDATA_DISPLAY' | translate }}
                    </div>
                </div>
            </div>
            <div class="card-body py-1" *ngIf= "displayEIGData != '' && displayEIGData != undefined">
                <div class="my-2">
                    <div class="form-group form-row mb-2" *ngFor="let row of [].constructor(displayEIGData.numOfRows); let i = index" style="background-color: #f1f1f1; border-radius: .25rem; padding-top: .5rem;border: 1px solid rgba(0,0,0,.125);">
                        <div class="col-sm-12" >
                            <div class="form-group">
                                <label>
                                {{ "ICE_GAME.EINSTEIN_GAME.SEQUENCE.TITLE_LABEL" | translate }} {{1+i}}
                                </label>
                                <div class="border rounded" style="padding: .350em; margin-bottom: 0em !important; background-color: white">
                                <div rows="2" [innerHTML]="displayEIGData.qaPanels[i].description">
                                </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-2 col-sm-25 p-1" *ngFor="let seq of [].constructor(displayEIGData.numOfSequences); let x = index">
                            <div class="card card-secondary p-2" style="background-color: #f1f1f1;">
                            <div class="card eing-card-body mb-1" style="background-color: #fafafa;border: 0px">
                                <div class="card-header p-1" style="background-color: #fafafa;border: 0px">
                                    <span class="badge badge-secondary">Question</span>
                                    Value : ${{(1+x)*100}}
                                </div>
                                <div class="card-body" *ngIf= "!displayEIGData.qaPanels[i].qaPanelData[x].questionIsText 
                                    && !displayEIGData.qaPanels[i].qaPanelData[x].questionIsImage">
                                </div>
                                <div *ngIf= "displayEIGData.qaPanels[i].qaPanelData[x].questionIsText">
                                <div class="card-body" [innerHTML]="displayEIGData.qaPanels[i].qaPanelData[x].questionTextData">
                                </div>
                                </div>
                                <div *ngIf= "displayEIGData.qaPanels[i].qaPanelData[x].questionIsImage">
                                <div class="card-body pl-0 pr-1 pb-0" style="padding-bottom: .5rem !important" (click)="showLargeImage(largeImage,displayEIGData.qaPanels[i].qaPanelData[x].questionImageData.id)">
                                    <img class="img-fluid" style="width: 8rem;display: block;margin-left: auto;margin-right: auto;"
                                    src="data:image/jpeg;base64,{{displayEIGData.qaPanels[i].qaPanelData[x].questionImageData.thumbnailImage}}"
                                    alt="{{displayEIGData.qaPanels[i].qaPanelData[x].questionImageData.title}}">
                                </div>
                                </div>
                            </div>
                            <div class="card eing-card-body mt-1" style="background-color: #fafafa;border: 0px">
                                <div class="card-header p-1" style="background-color: #fafafa;border: 0px">
                                <span class="badge badge-secondary">Answer</span>
                                </div>
                                <div class="card-body" *ngIf= "!displayEIGData.qaPanels[i].qaPanelData[x].answerIsText 
                                    && !displayEIGData.qaPanels[i].qaPanelData[x].answerIsImage">
                                </div>
                                <div *ngIf= "displayEIGData.qaPanels[i].qaPanelData[x].answerIsText">
                                <div class="card-body" [innerHTML]="displayEIGData.qaPanels[i].qaPanelData[x].answerTextData">
                                </div>
                                </div>
                                <div *ngIf= "displayEIGData.qaPanels[i].qaPanelData[x].answerIsImage">
                                <div class="card-body pl-0 pr-1 pb-0" style="padding-bottom: .5rem !important" (click)="showLargeImage(largeImage,displayEIGData.qaPanels[i].qaPanelData[x].answerImageData.id)">
                                    <img class="img-fluid" style="width: 8rem;display: block;margin-left: auto;margin-right: auto;"
                                    src="data:image/jpeg;base64,{{displayEIGData.qaPanels[i].qaPanelData[x].answerImageData.thumbnailImage}}"
                                    alt="{{displayEIGData.qaPanels[i].qaPanelData[x].answerImageData.title}}">
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card my-3" *ngIf= "detailedPreviewType == 'GameSMSG'">
            <div class="card-header py-1">
                <p class="col-sm-6 my-1 float-left h6 px-0">{{ 'ICE_COURSE.GameSMSG_DETAILS' | translate }}</p>
            </div>
            <div class="card-body py-1" *ngIf= "displaySMIGData == ''">
                <div class="my-2">
                    <div class="col-sm-12">
                        {{ 'ICE_COURSE.NOGAMEDATA_DISPLAY' | translate }}
                    </div>
                </div>
            </div>
            <div class="card-body py-1" *ngIf= "displaySMIGData != '' && displaySMIGData != undefined">
                <div class="my-2">
                    <div class="col-sm-12 px-0" *ngFor="let row of [].constructor(displaySMIGData.length); let i = index">
						<div class="p-1">
                            <div class="card card-secondary p-2" style="background-color: #f1f1f1;">
                                <div class="card eing-card-body mb-1" style="background-color: #fafafa;border: 0px">
                                    <div class="card-header p-1" style="background-color: #fafafa;border: 0px">
                                        <span class="badge badge-secondary">Question</span>
                                    </div>
                                    <div class="card-body" *ngIf= "!displaySMIGData[i].questionIsText && !displaySMIGData[i].questionIsImage">
                                        <!-- need to implement the contenet -->
                                    </div>
                                    <div *ngIf= "displaySMIGData[i].questionIsText">
                                        <div class="card-body" [innerHTML]="displaySMIGData[i].questionTextData">
                                        </div>
                                    </div>
                                    <div *ngIf= "displaySMIGData[i].questionIsImage">
                                        <div class="card-body pl-0 pr-1 pb-0" style="padding-bottom: .5rem !important" (click)="showLargeImage(largeImage,displaySMIGData[i].questionImageData.id)">
                                        <img class="img-fluid" style="width: 8rem;display: block;margin-left: auto;margin-right: auto;"
                                        src="data:image/jpeg;base64,{{displaySMIGData[i].questionImageData.thumbnailImage}}"
                                        alt="{{displaySMIGData[i].questionImageData.title}}">
                                        </div>
                                    </div>
                                </div>
                                <div class="card eing-card-body mt-1" style="background-color: #fafafa;border: 0px">
                                    <div class="card-header p-1" style="background-color: #fafafa;border: 0px">
                                        <span class="badge badge-secondary">Answer</span>
                                    </div>
                                    <div class="card-body" *ngIf= "!displaySMIGData[i].answerIsText 
                                                    && !displaySMIGData[i].answerIsImage" [innerHTML]="displaySMIGData[i].questionTextData">
                                                <!-- need to implement the contenet -->
                                                </div>
                                                <div *ngIf= "displaySMIGData[i].answerIsText">
                                                <div class="card-body" [innerHTML]="displaySMIGData[i].answerTextData">
                                                </div>
                                                </div>
                                                <div *ngIf= "displaySMIGData[i].answerIsImage">
                                                <div class="card-body pl-0 pr-1 pb-0" style="padding-bottom: .5rem !important" (click)="showLargeImage(largeImage,displaySMIGData[i].answerImageData.id)">
                                                    <img class="img-fluid" style="width: 8rem;display: block;margin-left: auto;margin-right: auto;"
                                                    src="data:image/jpeg;base64,{{displaySMIGData[i].answerImageData.thumbnailImage}}"
                                                    alt="{{displaySMIGData[i].answerImageData.title}}">
                                                </div>
                                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card my-3" *ngIf= "detailedPreviewType == 'GameMG'">
            <div class="card-header py-1">
                <p class="col-sm-6 my-1 float-left h6 px-0">{{ 'ICE_COURSE.GameMG_DETAILS' | translate }}</p>
            </div>
            <div class="card-body py-1" *ngIf= "displayMGData == ''">
                <div class="my-2">
                    <div class="col-sm-12">
                        {{ 'ICE_COURSE.NOGAMEDATA_DISPLAY' | translate }}
                    </div>
                </div>
            </div>
            <div class="card-body py-1" *ngIf= "displayMGData != '' && displayMGData != undefined">
                <form>
                    <div class="form-row my-1 align-items-end">
                        <div class="col-sm-6">
                            <div class="form-group mb-1">
                                <label class="col-sm-12 px-0 mb-1">
                                    {{ 'ICE_GAME.MEMORY_GAME.GAME_IMAGE.TITLE_LABEL' | translate }}
                                </label>
                                <div class="card-body" *ngIf="displayMGData.target == null">
                                    {{ICE_COURSE.NOIMAGE_DISPLAY}}
                                </div>
                                <div *ngIf="displayMGData.target != null" class="card" style="width: 8rem; " (click)="showLargeImage(largeImage,displayMGData.target.id)">
                                    <img class="img-fluid" style="width: 8rem;"
                                    src="data:image/jpeg;base64,{{displayMGData.target.thumbnailImage}}"
                                    alt="{{displayMGData.target.title}}">
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-sm-6">
                            <div class="form-group mb-1">
                                <label class="mb-1">
                                {{ 'ICE_GAME.MEMORY_GAME.NUMBER_OF_SQUARES' | translate }}
                                </label>
                                <div [innerHTML]="20"></div>
                            </div>
                        </div> -->
                    </div>
                </form>
                <div class="my-2">
                    <div class="form-group form-row mb-2" *ngFor="let row of [].constructor(numOfRowsMG); let i = index">
                        <div class="col-sm-2 col-sm-25 p-1" *ngFor="let seq of [].constructor(numOfSequencesMG); let x = index">
                            <div class="card card-secondary p-2" style="background-color: #f1f1f1;">
                            <div class="card eing-card-body mb-1" style="background-color: #fafafa;border: 0px">
                                <div class="card-body" *ngIf= "!displayMGData.qaPanels[i].qaPanelData[x].questionIsText 
                                    && !displayMGData.qaPanels[i].qaPanelData[x].questionIsImage">
                                </div>
                                <div *ngIf= "displayMGData.qaPanels[i].qaPanelData[x].questionIsText">
                                <div class="card-body" [innerHTML] = "displayMGData.qaPanels[i].qaPanelData[x].questionTextData">
                                </div>
                                </div>
                                <div *ngIf= "displayMGData.qaPanels[i].qaPanelData[x].questionIsImage">
                                <div class="card-body pl-0 pr-1 pb-0" style="padding-bottom: .5rem !important" (click)="showLargeImage(largeImage,displayMGData.qaPanels[i].qaPanelData[x].questionImageData.id)">
                                    <img class="img-fluid" style="width: 8rem;display: block;margin-left: auto;margin-right: auto;"
                                    src="data:image/jpeg;base64,{{displayMGData.qaPanels[i].qaPanelData[x].questionImageData.thumbnailImage}}"
                                    alt="{{displayMGData.qaPanels[i].qaPanelData[x].questionImageData.title}}">
                                </div>
                                </div>
                            </div>
                            <div class="card eing-card-body mt-1" style="background-color: #fafafa;border: 0px">
                                <div class="card-body" *ngIf= "!displayMGData.qaPanels[i].qaPanelData[x].answerIsText 
                                    && !displayMGData.qaPanels[i].qaPanelData[x].answerIsImage">
                                </div>
                                <div *ngIf= "displayMGData.qaPanels[i].qaPanelData[x].answerIsText">
                                <div class="card-body" [innerHTML] = "displayMGData.qaPanels[i].qaPanelData[x].answerTextData">
                                </div>
                                </div>
                                <div *ngIf= "displayMGData.qaPanels[i].qaPanelData[x].answerIsImage">
                                <div class="card-body pl-0 pr-1 pb-0" style="padding-bottom: .5rem !important" (click)="showLargeImage(largeImage,displayMGData.qaPanels[i].qaPanelData[x].answerImageData.id)">
                                    <img class="img-fluid" style="width: 8rem;display: block;margin-left: auto;margin-right: auto;"
                                    src="data:image/jpeg;base64,{{displayMGData.qaPanels[i].qaPanelData[x].answerImageData.thumbnailImage}}"
                                    alt="{{displayMGData.qaPanels[i].qaPanelData[x].answerImageData.title}}">
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #largeImage>
	<div class="modal-header">
		<h4 class="modal-title pull-left">{{ selectedImage.title }}</h4>
		<button type="button" id="image_close" class="close pull-right" aria-label="Close"
			(click)="modelRefCloseEvent()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="card p-0">
			<img class="card-img-top" src="data:image/jpeg;base64,{{selectedImage.largeImage}}">
		</div>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-sm btn-secondary" (click)="modelRefCloseEvent()">{{
                'IMAGE_SEARCH_VIEW.BTN_TEXT_CLOSE' | translate }}</button>
	</div>
</ng-template>