import { Component, OnInit } from '@angular/core';
import { GridOptions, Module, AllModules } from "@ag-grid-enterprise/all-modules";
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/util/alert/alert.service';
import { ReportDataService } from '../report-data-service.service';
import { Image } from './image.model';
import { ReportImageCellRenderComponent } from './report-image-cell-render/report-image-cell-render.component';
import { LoadingService } from 'src/app/util/loading/loading.service';



@Component({
	selector: 'app-image-grid',
	templateUrl: './image-grid.component.html',
	styleUrls: ['./image-grid.component.css']
})
export class ImageGridComponent implements OnInit {

	public overlayLoadingTemplate: any;
    public overlayNoRowsTemplate: any;
	public gridOptions: GridOptions;
	public defaultColDef: any;
	ps_translated_data: any = null;
	columnDefs: any;
	rowData: any[] = [];
	public modules: Module[] = AllModules;
	private gridApi;
	public isMinimized: boolean = false;
	public title: string = "Images";
	private filteredData: any[] = [];
	base64Data: any;
	base64DataImage: any;
	public width: string = "";
	public height: string = "";
	public fullimagearr: Array<any> = [];
	gridColumnApi: any;
	public image2: Image;
	public loaded: boolean = false;
	public divHeight: number = (window.innerHeight) * 5 / 7
	totalRows: number =0;
	loadedRows: number = 0;
	rowsCount: number = 0;
	previosPageNo: number = 0;
	newRows: any[];
	addedRowsCount: number = 0;

	  constructor(private translate: TranslateService, private alertService: AlertService, 
		private reportDataService: ReportDataService, private loadingService: LoadingService) {
		translate.setDefaultLang("en");
		let browserLang = translate.getBrowserLang();
		translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
		
		this.gridOptions = <GridOptions>{
			context: {
				componentParent: this
			}
		};
		translate.getTranslation(browserLang).subscribe((res: string) => {
			// this.gridOptions = <GridOptions>{
			// 	context: {
			// 		componentParent: this
			// 	}
			// };

			this.ps_translated_data = res['REPORTS']
			this.columnDefs = [
				
				{
					headerName: this.ps_translated_data.REPORT_TABLE_COL_ID, field : 'id', 
					cellClass: "cell-wrap-text", hide: true, cellStyle: { "white-space": "normal" }, filter: "agTextColumnFilter" //, maxWidth : 250//, minWidth: 40, maxWidth : 75, autoHeight: true,
					
					//suppressSizeToFit : true ,suppressFiltersToolPanel: true
				},
				{
					headerName: this.ps_translated_data.REPORT_IMAGE_TITLE, field: 'title',  cellClass: "cell-wrap-text" , cellStyle: { "white-space": "normal" }, filter: "agTextColumnFilter" //, maxWidth : 250//suppressSizeToFit : true, width: 300  autoHeight: true,
				},
				{
					headerName: this.ps_translated_data.REPORT_IMAGE_FOLDER, field: 'folder', autoHeight: true, cellClass: "cell-wrap-text",  cellStyle: { "white-space": "normal" }, filter: "agTextColumnFilter" //, minWidth: 200 
					//suppressFilter: true
				},
				{
					headerName: this.ps_translated_data.REPORT_IMAGE_THUMBNAIL, field: 'image' ,  cellClass: "cell-wrap-text",  cellRendererFramework: ReportImageCellRenderComponent, filter: "agTextColumnFilter" //,  cellStyle: { "white-space": "normal" } //Width: 100, maxWidth : 100,autoHeight: true,
				}
			]
			this.defaultColDef = {
				resizable: true,
				sortable: true,
				floatingFilter: true
			}
			this.gridOptions.rowHeight = 100;
			let projectName = localStorage.getItem("selectedProjectName");
			this.title = this.ps_translated_data.REPORT_IMAGE_PAGE_TITLE + " - " + projectName;
			//custom layout templates for loading and empty data sets
			this.overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Loading data...</span>';
       		this.overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">No rows to show</span>';
		});
		// localStorage.setItem("thumbsLoaded", "false");
  	}

	ngOnInit() { 
	}

	ngOnDestroy(){

		window.removeEventListener("resize", this.onWindowResize);
		this.gridApi.destroy();
		this.gridApi = null;
	}

	onColumnResized(event) {
		console.log("image grid- on Column Resized ");
		if (event.finished) {
			this.gridOptions.rowHeight = 100;
		}
	}

	onGridReady(params) {
		this.gridApi = params.api;
		this.gridApi.showLoadingOverlay()
		params.api.sizeColumnsToFit();
		// window.addEventListener("resize", function () {
        //     setTimeout(function () {
		// 		params.api.sizeColumnsToFit();
		// 		// params.api.resetRowHeights();
        //     });
		// });
		window.addEventListener("resize", this.onWindowResize);
		
		this.loadData();
		setTimeout(function () {
			params.api.sizeColumnsToFit();
			// params.api.resetRowHeights();
		}, 500);
		
		params.api.sizeColumnsToFit();
		// params.api.resetRowHeights();
		this.gridOptions.rowHeight = 100;
		
	}

	private onWindowResize = () => {
   
		setTimeout(() => {
			if (this.gridApi) {
				this.gridApi.sizeColumnsToFit();
			}
		}); 
	};

	private loadData() {
		this.reportDataService.getImageList()
			.subscribe(data => {
				this.getImageDetail(data.payload);
			},
			error => {
				this.rowData = [];
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			});
	}

	getImageDetail(payload : any) {
		this.filteredData = [];
		for(let i = 0; i < payload.length; i++){
			this.filteredData.push({
				'id' : payload[i]["id"],
				'title' : payload[i]["title"],
				'folder' : payload[i]["folderPath"],
				//to pass image details to cell renderer
				'image' :  payload[i]["id"]+":"+payload[i]["imagePath"]+":"+ payload[i]["pathToThumb"]+":"+ payload[i]["title"]
			});

		}
		this.rowsCount = this.filteredData.length
		// this.rowData = this.filteredData;
		this.setDataToTable(20)
	}

	onPageChanged(event){
		// console.log("event.newPage= "+ event.newPage)
		if(this.rowsCount){
			if(event.newPage){
				let totalPages = this.gridApi.paginationGetTotalPages()
				let currentPage = this.gridApi.paginationGetCurrentPage()
				if(currentPage == totalPages-1){
					this.setDataToTable(10)
				}
			}
		}else{
			//all data loaded to table
		}

		

	}


	private setDataToTable(increment: number){
		
		this.newRows = []
		for(let i=0; i< increment ; i++){
			if(this.rowsCount){
				this.rowsCount--
				this.addedRowsCount++
				this.newRows.push(this.filteredData.pop())
			}else{
				break
			}
		}
		if(this.addedRowsCount<21){
			this.gridApi.setRowData(this.newRows)
		}else{
			this.gridApi.applyTransaction({ add: this.newRows });
		}
	}


}


