export class IceTopic {
    constructor(
        public id?: string,
        public topicName?: string,//topicName
        public topicNameHTML?: string,//topicName
        public courseId?: string,
        public sequence?: number,
        public topicParentId?: string,
        public topicContentParentId?: string,
        public description?: string,
        public courseVersion?: number,
        public isTopicModified?: boolean,
        public linkedCourseId?: string,

        // Image Details
        public topicImage?: ProcImage,
        public selectedType?: string,
        //public iceTopicList?: any,
        //public iceContentList?: any,
        public parentType?: string,
        public parent?: any,
        public orderNumber?: number,
        public copyTopicId?: string,
        public noOfGames?: number
    ){}
}

export class ProcImage {
    constructor(
        public id: string,
        public thumbnailImage?: string,
        public largeImage?: string,
        public title?: string
    ){}
    
}

export class ProcVideo {
    constructor(
        public id: string,
        public title?: string,
        public selectedURL?: string,
        public thumbnailVideo?: string,
        public posterframeURL?: string,
        public largeVideo?: string
        
    ){}
}

export class ProcThreeDModel {
    constructor(
        public id: string,
        public title?: string,
        public selectedURL?: string,
        public layerContent?: any,
        public viewContent?: any,
        public thumbnailThreeDModel?: string,
        public largeThreeDModel?: string
    ){}
}
