<div class="modal-header">
	<h4 class="modal-title pull-left">{{title}}</h4>
	<button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body">
	<!-- {{data}} -->
	<div [innerHTML] = "data | safeHtml"></div>
</div>
<div class="modal-footer">
	<button *ngIf='actionBtn!== null' type="btn" class="btn btn-warning" (click)="bsModalRef.hide();confirm()">{{actionBtn}}</button>
	<button *ngIf='actionBtn=== null' type="btn" class="btn btn-warning" (click)="bsModalRef.hide()">{{closeBtnName}}</button>
	<button *ngIf='actionBtn!== null' type="btn" class="btn btn-default" (click)="bsModalRef.hide()">{{closeBtnName}}</button>	
</div>