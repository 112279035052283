import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '../../../util/alert/alert.service';
import { WsCallback } from '../../../util/ws-callback.interface';
import { WsResponse } from '../../../util/ws-response.model';
import { WsType } from '../../../util/ws-type';
import { Keycode } from './keycode.model';
import { Keycodeverify } from './keycodeverify.service';
import { LocalStorage } from '../../../util/localstorage.service';
import { LoggedUser } from '../../logged-user.model';
import { UserVariable } from 'src/app/util/common/user-variable';
import { RecentProject } from '../../../landing/recent-project.service';
import { CapabilityService } from '../../../util/capability.service';

@Component({
	selector: 'app-keycodeverification',
	templateUrl: './keycodeverification.component.html',
	styleUrls: ['./keycodeverification.component.css']
})

export class KeycodeverificationComponent implements OnInit, WsCallback {

	private keyCodeRequest: Keycode;
	public keyCode: String;

	constructor(private router: Router, private alertService: AlertService, private keyCodeService: Keycodeverify,
		public recentProjectService: RecentProject, private capabilityService: CapabilityService) { }

	ngOnInit() {
		this.keyCodeService.setCallback(this);
		this.alertService.error('this is the name', false);
		this.keyCodeRequest = new Keycode();
	}

	public cancelBtnClickEvent(): void {
		localStorage.removeItem(LocalStorage.LOGGED_USER);
		this.router.navigateByUrl('login');
	}

	public verifyKeyCode() {
		//console.log(this.keyCode);
		console.log(this.keyCodeRequest)
		this.keyCodeRequest.keycode = this.keyCode;
		this.keyCodeRequest.userid = localStorage.getItem(LocalStorage.USER_ID);
		this.keyCodeRequest.deviceid = localStorage.getItem(LocalStorage.DEVICE_ID);
		//this.keyCodeRequest.authtoken = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER)).authtoken;
		this.keyCodeService.verifyKeyCode(JSON.stringify(this.keyCodeRequest));
	}

	onSuccess(data: WsResponse, serviceType: WsType): void {
		if (serviceType == WsType.KEY_CODE_VERIFICATION) {
			var userJason = JSON.parse(JSON.stringify(data.payload));
			console.log(userJason);
			var user = new LoggedUser(userJason.currentusername, userJason.currentuserid, userJason.authtoken, userJason.firstName, userJason.lastName);
			let deviceId = localStorage.getItem(LocalStorage.DEVICE_ID);
			localStorage.clear();
			localStorage.setItem(LocalStorage.DEVICE_ID, deviceId);
			localStorage.setItem(LocalStorage.LOGGED_USER, JSON.stringify(user));
			UserVariable.clientId = data.payload.clientid != null ? JSON.stringify(data.payload.clientid) : "";
			UserVariable.isClient = data.payload.isclient;
			UserVariable.userId = data.payload.currentuserid;
			UserVariable.preferences = data.payload.preferences != null ? JSON.stringify(data.payload.preferences) : "";
			localStorage.removeItem(LocalStorage.USER_ID);

			//add capabilities
			this.capabilityService.getAllCapabilityList()
				.subscribe(data => {
					localStorage.setItem(LocalStorage.CAPABILITY_LIST, JSON.stringify(data.payload))
					if (data.payload.length !== 0) {
						localStorage.setItem(LocalStorage.USER_ACCESSIBILITY, "system_level");
					} else {
						localStorage.setItem(LocalStorage.USER_ACCESSIBILITY, "project_client_level");
					}
				},
				() => {
					
			})
			console.log(userJason.recentproject);
			if ((userJason.recentproject != null || userJason.recentproject != undefined)  && (userJason.recentproject.projectType == 1)) {
				UserVariable.tokenTimeout();
				UserVariable.projectId = userJason.recentproject.projectId;
				UserVariable.projectName = userJason.recentproject.projectName;
				UserVariable.clientId = userJason.recentproject.clientId;
				localStorage.setItem(LocalStorage.PROJECT_NAME, userJason.recentproject.projectName);
				localStorage.setItem(LocalStorage.DEFAULT_PROJECT_ID, userJason.recentproject.projectId);
				localStorage.setItem(LocalStorage.PROJECT_TYPE, '1');
				sessionStorage.setItem(LocalStorage.PROJECT_TYPE, '1');
				localStorage.setItem(LocalStorage.DEFAULT_PROJECT_TYPE, '1');
				localStorage.setItem(LocalStorage.PROJECT_ID, userJason.recentproject.projectId);
				localStorage.setItem(LocalStorage.CLIENT_ID, userJason.recentproject.clientId);
				localStorage.setItem(LocalStorage.DEFAULT_PROJECT_NAME, userJason.recentproject.projectName);
				localStorage.setItem(LocalStorage.DEFAULT_CLIENT_ID, userJason.recentproject.clientId);
				this.router.navigateByUrl('home');
			} else {
				UserVariable.tokenTimeout();
				this.router.navigateByUrl('landing');
			}

			//check th default project
			//this.recentProjectService.getDefaultRecentProject(userJason.currentuserid);
		}
		if (serviceType == WsType.GET_DEFAULT_RECENT_PROJECT) {
			console.log("=============  AA 3");
			if (data.payload != null) {
				console.log("=============  AA 4");
				//There is a default project
				if(data.payload.isDefaultProject == '1'){
					localStorage.setItem(LocalStorage.DEFAULT_PROJECT_ID, data.payload.projectId);
					localStorage.setItem(LocalStorage.DEFAULT_PROJECT_TYPE, '1');
					localStorage.setItem(LocalStorage.DEFAULT_PROJECT_NAME, data.payload.projectName);
					localStorage.setItem(LocalStorage.DEFAULT_CLIENT_ID, data.payload.clientId);
				}
				sessionStorage.setItem(LocalStorage.PROJECT_TYPE, '1');
				localStorage.removeItem(LocalStorage.PROJECT_NAME);
				UserVariable.clientId = data.payload.clientId;
				UserVariable.projectId = data.payload.projectId;
				UserVariable.projectName = data.payload.projectName;
				this.router.navigateByUrl('home');
			} else {
				console.log("=============  AA 5");
				//There is no default project
				this.router.navigateByUrl('landing');
			}
		}
	}

	onFail(data: WsResponse, serviceType: WsType): void {
		if (serviceType == WsType.KEY_CODE_VERIFICATION) {
			//console.log("error => " + data);console.log(this.keyCodeRequest)
			this.alertService.error("Invalid! Please check the code sent to your email");
			if (this.keyCodeRequest.keycode != undefined) {
				setTimeout(() => {this.router.navigateByUrl('login');},2000)	
			}
		} if (serviceType == WsType.GET_DEFAULT_RECENT_PROJECT) {
			localStorage.removeItem(LocalStorage.PROJECT_NAME);
			this.alertService.error(data.statusDescription, false);
		}
	}
}
