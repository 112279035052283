<div class="container-fluid px-0">
	<div class="row px-3">
		<div class="col-sm-8 px-0">
			<p class="mb-2 h5">
				<b>{{ 'TAGS_SEARCH_VIEW.HEADER' | translate }}</b>
			</p>
		</div>
		<div class="col-sm-4 px-0">
			<button id="button_show_addtag" *ngIf="changeLayout" type="submit" class="btn btn-primary btn-sm float-right reusable_btn my-1"
			[disabled]="!enableAddTag" (click)="newTagButtonClick()">
				<i class="fas fa-plus"></i>
			</button>
			<button id="button_show_bulkimport" *ngIf="changeLayout" type="submit" class="btn btn-primary btn-sm float-right reusable_btn my-1 mx-1"
				(click)="bulkImportClick()">
				{{ 'TAGS_SEARCH_VIEW.BTN_TXT_BULKIMPORT' | translate }}
			</button>
			<button id="button_show_classifacation" *ngIf="changeLayout" type="submit" class="btn btn-primary btn-sm float-right reusable_btn my-1 mx-1"
				[disabled]="!enableSearchTagClassification" (click)="classificationClick()">
				{{ 'TAGS_SEARCH_VIEW.BTN_TXT_CLASSIFICATION' | translate }}
			</button>
			<button id="button_show_valuelist" *ngIf="changeLayout" type="submit" class="btn btn-primary btn-sm float-right reusable_btn my-1 mx-1"
				[disabled]="!enableSearchValueList" (click)="valueListClick()">
				{{ 'TAGS_SEARCH_VIEW.BTN_TXT_VALUELIST' | translate }}
			</button>
		</div>
	</div>
	<div class="col-sm-12 px-0">
		<div class="input-with-icon" *ngIf="!enableSearchTag">
				<input #symbiosis_search class="form-control" name="tag_search" placeholder="Search" style="padding-right: 60px;" 
				[disabled]="!enableSearchTag"/>
		</div>
		<div class="input-with-icon" *ngIf="enableSearchTag">
			<symbiosis-search [comp]="'tag'" id="tag_search" (searchFunc)="searchFunc($event)"
			(searchBarEnterFunc)="searchBarEnterFunc($event)"></symbiosis-search>
		</div>
	</div>
	<hr class="my-2" />
</div>
<ag-grid-angular #agGrid style="width: 100%; height: 375px;" class="ag-theme-balham" [rowData]="rowData" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }"
	[columnDefs]="columnDefs" [gridOptions]="gridOptions" (rowClicked)="onRowClick($event)" [pagination]="true" [paginationPageSize]="10"
	[overlayLoadingTemplate]="overlayLoadingTemplate" [overlayNoRowsTemplate]="overlayNoRowsTemplate" [defaultColDef]="defaultColDef"
	[getRowNodeId]="getRowNodeId" (gridReady)="onGridReady($event)" [suppressCellSelection]="true">
</ag-grid-angular>

<!--Tag Attributes-->
<div class="row mb-5 pb-2" *ngIf="(!smallView && isShowTagAttributes)">
	<div class="col-sm-12">
		<label class="mb-1 h6">{{ 'TAG_ADD.TAG_ATTRIBUTES' | translate }}</label>
	</div>
	<div class="col-sm-12">
		<div class="card" id="table-scroll">
			<div class="class-body p-2">
					<p class="h4 text-muted" *ngIf="tagAttributes == undefined || tagAttributes.length == 0"
					name="no_tag_attribute_label">No
					tag attributes</p>
				<!--Tag Attribute List-->
				<div *ngFor="let attribute of tagAttributes; let t = index">
					<div
						*ngIf="attribute.attributeType == 'Text'">
						<table>
							<th></th>
							<th></th>
							<tr>
								<td width="400">
									<label>{{attribute.attributeName}}
										<div *ngIf="attribute.required.toLowerCase()=='yes'" class="badge-required mx-2"></div>
									</label>
								</td>
								<td width="500">
									<input value="{{attribute.value}}" id="{{attribute.attributeId}}" type="text" maxlength="300" class="form-control form-control-sm"
												(change)="getValueChanges($event, t, 'tag_attribute_text', attribute.attributeId, attribute.tagClsSpecId, attribute.required)">
								</td>
								<td width="50">
									<button type="button" class="btn btn-warning btn-sm float-right my-1 btn-sm-form-dependancy"
									(click)="dependencyButtonClickEvent('TagAttribute')" [disabled]="attribute.dependencyCount == '0'"><i
									class="fa fa-link" aria-hidden="true"></i></button>
								</td>
							</tr>
						</table>
					</div>
					<div
						*ngIf="attribute.attributeType == 'Number'">
						<table>
							<th></th>
							<th></th>
							<tr>
								<td width="400">
									<label>{{attribute.attributeName}}
										<div *ngIf="attribute.required.toLowerCase()=='yes'" class="badge-required mx-2"></div>
									</label>
								</td>
								<td width="500">
									<input value="{{attribute.value}}" id="{{attribute.attributeId}}" type="number" (keypress)="checkIfNumber($event)" maxlength="300" class="form-control form-control-sm"
												(change)="getValueChanges($event, t, 'tag_attribute_text', attribute.attributeId, attribute.tagClsSpecId, attribute.required)">
								</td>
								<td width="50">
									<button type="button" class="btn btn-warning btn-sm float-right my-1 btn-sm-form-dependancy"
									(click)="dependencyButtonClickEvent('TagAttribute')" [disabled]="attribute.dependencyCount == '0'"><i
									class="fa fa-link" aria-hidden="true"></i></button>
								</td>
							</tr>
						</table>
					</div>
					<div
						*ngIf="attribute.attributeType != 'Text' && attribute.attributeType != 'Number'">
						<table>
							<th></th>
							<th></th>
							<tr>
								<td width="400">
									<label>{{attribute.attributeName}}
										<div *ngIf="attribute.required.toLowerCase()=='yes'" class="badge-required mx-2"></div>
									</label>
								</td>
								<td width="500">
									<!--Documents-->
									<app-attribute-select *ngIf="attribute.attributeType == 'Document'"
										[selectedItem]="selectedTagAttributeValue[t].selectedDataId"
										[viewItems]="documentListForNgSelect"
										id="sysdoc_{{attribute.attributeId}}"
										name="sysdoc_{{attribute.attributeId}}"
										(emitSelected)="getValueChanges($event, t, 'tag_attribute_document', attribute.attributeId, attribute.tagClsSpecId, attribute.required)">
									</app-attribute-select>

									<!--Systems-->
									<app-attribute-select *ngIf="attribute.attributeType == 'System'"
										[selectedItem]="selectedTagAttributeValue[t].selectedDataId"
										[viewItems]="systemListForNgSelect"
										id="sysatt_{{attribute.attributeId}}"
										name="sysatt_{{attribute.attributeId}}"
										(emitSelected)="getValueChanges($event, t, 'tag_attribute_system', attribute.attributeId, attribute.tagClsSpecId, attribute.required)">
									</app-attribute-select>

									<!--Components-->
									<app-attribute-select *ngIf="attribute.attributeType == 'Component'"
										[selectedItem]="selectedTagAttributeValue[t].selectedDataId"
										[viewItems]="getComponentListByCategory(attribute.categoryId)"
										id="syscom_{{attribute.attributeId}}"
										name="syscom_{{attribute.attributeId}}"
										(emitSelected)="getValueChanges($event, t, 'tag_attribute_component', attribute.attributeId, attribute.tagClsSpecId, attribute.required)">
									</app-attribute-select>

									<!--Date-->
									<input *ngIf="attribute.attributeType == 'Date'" value="{{attribute.value}}" id="{{attribute.attributeId}}"
										type="date" (change)="getValueChanges($event, t, 'tag_attribute_date', attribute.attributeId, attribute.tagClsSpecId, attribute.required)"
										class="form-control form-control-sm"/>

								</td>
								<td width="50">
									<button type="button" class="btn btn-warning btn-sm float-right my-1 btn-sm-form-dependancy"
									(click)="dependencyButtonClickEvent('TagAttribute')" [disabled]="attribute.dependencyCount == '0'"><i
									class="fa fa-link" aria-hidden="true"></i></button>
								</td>
							</tr>
						</table>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<button type="submit" id="addtagattr_save" class="btn btn-primary btn-sm" (click)="tagAttributeSaveButtonClick()">
					{{ 'TAG_ADD.SAVE_BUTTON_TEXT' | translate}}
				</button>
				<button type="button" id="addtagattr_cancel" class="btn btn-secondary btn-sm" (click)="tagAttributeCancelButtonClick()">
					{{ 'TAG_ADD.CANCEL_BUTTON_TEXT' | translate}}
				</button>
			</div>
		</div>
	</div>
</div>

<div *ngIf="(smallView && isShowTagAttributes && hasAttr && classificationClicked)" class="modal-body">			
	<ag-grid-angular #agGrid style="width: 100%; height: 300px;" class="ag-theme-balham"
		[enableFilter]="true" [rowData]="rowDataTagAttr" [columnDefs]="columnDefsAttr" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }"
		[rowMultiSelectWithClick]="false" (rowClicked)="onRowClickTag($event)" [defaultColDef]="defaultColDef"
		[pagination]="true" [paginationPageSize]="10" [getRowNodeId]="getRowNodeId" 
		[overlayLoadingTemplate]="overlayLoadingTemplate" [overlayNoRowsTemplate]="overlayNoRowsTemplate">
	</ag-grid-angular>						
</div>
<div *ngIf="(smallView && loading && classificationClicked)" class="modal-body">	
		<h5><b><center>Loading</center></b></h5>
</div>
<div *ngIf="(smallView && isShowTagAttributes && !hasAttr && !loading && classificationClicked)" class="modal-body">	
		<h5><b><center>No Attributes</center></b></h5>
</div>