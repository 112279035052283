/**
 * A single tab page. It renders the passed template
 * via the @Input properties by using the ngTemplateOutlet
 * and ngOutletContext directives.
 */
import { Component, Input, ViewChild, ViewContainerRef, ComponentRef, ComponentFactoryResolver } from '@angular/core';
import { TabItem } from '../tab-item';
import { DynamicTab } from '../dynamic-tab';

@Component({
	selector: 'app-tab',
	templateUrl: './tab.component.html',
	styleUrls: ['./tab.component.css']
})
export class TabComponent {

	@Input() id: string
	@Input('tabTitle') title: string
	@Input() active: boolean = false
	@Input() isCloseable: boolean = false
	@Input() template: any
	@Input() dataContext: any
	@Input() component: TabItem
	@Input() toolTip: string;
	@Input() componentType: string;
	@Input() path: string;
	@Input() startIndex: number;

	@ViewChild('componentHost', { read: ViewContainerRef, static: true}) componentHost: ViewContainerRef
	compoenntRef: ComponentRef<Component>

	constructor(private resolver: ComponentFactoryResolver) { }

	ngAfterContentInit() {
		//Called after ngOnInit when the component's or directive's content has been initialized.
		//Add 'implements AfterContentInit' to the class.
		this.updateComponent()
	}
	
	ngOnChanges(): void {
		//Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
		//Add '${implements OnChanges}' to the class.
		
	}

	ngOnDestroy(): void {
		//Called once, before the instance is destroyed.
		//Add 'implements OnDestroy' to the class.
		if(this.compoenntRef) {
			this.compoenntRef.destroy()
		}
	}

	updateComponent() {
		this.componentHost.clear()
		const authFormFactory = this.resolver.resolveComponentFactory(this.component.component)
		const componentRef = this.componentHost.createComponent(authFormFactory);
		(<DynamicTab>componentRef.instance).data = this.component.data
	}

}
