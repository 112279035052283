import {EventEmitter, Injectable, Output} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpEventType, HttpParams} from "@angular/common/http";
import {SharedService} from "../../util/shared.service";
import {CommonUtil} from "../../util/common/common-util";
import {UserVariable} from "../../util/common/user-variable";
import {WsCallback} from "../../util/ws-callback.interface";
import {ServiceUrls} from "../../util/service-urls";
import {WsResponse} from "../../util/ws-response.model";
import {WsType} from "../../util/ws-type";
import {catchError, map} from "rxjs/operators";
import {throwError} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class IrnService {
  public token: string;
  private callback: WsCallback;
  private apiGridData: any = null;
  private rowData: any = null;
  private UpdatedRow: any = null;
  private addEditComponent:WsCallback;
  @Output() deleteIrnPunchlistId: EventEmitter<any> = new EventEmitter;

  constructor(private http: HttpClient, private sharedService: SharedService, private commonUtil: CommonUtil) {
    this.token = UserVariable.getUserToken();
    if (!this.commonUtil.validateLogin(this.token)) {
      return;
    }
  }

  public setCallback(callBack: WsCallback) {
    this.callback = callBack;
  }

  public setAddEditComponent(callBack: WsCallback) {
    this.addEditComponent = callBack;
  }
  public setApiGridData(apiGridData: any) {
    this.apiGridData = apiGridData;
  }

  public setRowData(rowData: any) {
    this.rowData = rowData;
  }

  public applyTransaction(row: any) {
    this.UpdatedRow = row;
  }

  public setIrnDocUploadStatus(val: boolean, irnId :string) {
    if (this.UpdatedRow && this.UpdatedRow.irnId == irnId) {
      this.UpdatedRow.Isuploaded = val;
      this.setTableRow(this.UpdatedRow.irnId)
    }
  }

  public deleteFileTableData(irnId: any) {
    if (this.apiGridData != null && this.apiGridData != undefined && irnId != null) {
      var selectedData = this.apiGridData.getSelectedRows();
      if (selectedData != null && selectedData != undefined) {
        this.apiGridData.applyTransaction({remove: selectedData});
        let newRowData: any = [];
        this.apiGridData.forEachNode(function (rowNode, index) {
          newRowData.push(rowNode.data);
        });
        this.setRowData(newRowData);
      }
      this.deleteIrnFile(irnId);
    }
  }

  /**
   *Get punchlist count for warning message
   *
   * @param id
   */
  getPunchlistCount(id) {
    let punchlistCount = 0;
    if (this.apiGridData != null && this.apiGridData != undefined && id != null) {
      var rowNode = this.apiGridData.getRowNode(id);
      if (rowNode != null && rowNode != undefined && rowNode.data.punchlistCount) {
        punchlistCount = rowNode.data.punchlistCount
      }
    }
    return punchlistCount;
  }

  /**
   *Set table data when updating
   *
   * @param id
   */
  setTableRow(id) {
    if (this.rowData != null && this.apiGridData != null && this.apiGridData != undefined && id != null) {
      let itemIndex = this.rowData.findIndex(item => item.id == id);
      if (this.rowData[itemIndex]) {
        this.rowData[itemIndex].irnNumber = this.UpdatedRow.irnNumber;
        this.rowData[itemIndex].description = this.UpdatedRow.description;
        this.rowData[itemIndex].vendorName = this.UpdatedRow.vendorName;
        this.rowData[itemIndex].assets = this.UpdatedRow.assetsNames;
        this.rowData[itemIndex].systemName = this.UpdatedRow.systemName;
        this.rowData[itemIndex].punchlistCount = this.UpdatedRow.punchlistCount;
        if (this.UpdatedRow.Isuploaded) {
          this.rowData[itemIndex].doc = "doc:" + id + ":" + "true";
        }
        this.rowData[itemIndex].irnStatus = this.UpdatedRow.irnStatus;
        this.rowData[itemIndex].targetCompletionDate = this.UpdatedRow.targetCompletionDate;
      }
      this.apiGridData.setRowData(this.rowData);
    }
  }

  setPunclistCount(id, punchlistcount){
    if (this.rowData != null && this.apiGridData != null && this.apiGridData != undefined && id != null) {
      let itemIndex = this.rowData.findIndex(item => item.id == id);
      if (this.rowData[itemIndex]) {
        this.rowData[itemIndex].punchlistCount = punchlistcount;
      }
    }
    this.apiGridData.setRowData(this.rowData);
  }
  public getTagSearchURL(token: String) {
    let url = ServiceUrls.TAGS_SEARCH_VIEW + '?token=' + token;
    return url;
  }

  public getsystemsBySearch(token: String) {
    let url = ServiceUrls.SYSTEMS_SEARCH_VIEW + '?token=' + token;
    return url;
  }

  /**
   * Get assets List
   *
   * @param tagNo
   */
  getAssetList(tagNo: String,edit:boolean,assetIds) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    let url = this.getTagSearchURL(token);

    //Achini Randeni
    //append '*' to get tag list which contains tagNo from solr
    tagNo = '*' + tagNo + '*';

    return this.http.post<any>(url, JSON.stringify(new AssetRequest(tagNo, null, "", null, null, null, null, true)))
        .pipe(map(payload => JSON.parse(payload).payload.map(item =>
        {
          if(item.status == "Active"){
            return new Object({
              value: item.tagId,
              display: item.tagNo
            })
          }else{
            let include = assetIds.some((f) => {
              return f.value == item.tagId;
            });
            if(include){
              return new Object({
                value: item.tagId,
                display: item.tagNo
              })
            }else{
              return new Object({
                display: ""
              })
            }

          }
        }
        )))

  }

  /**
   * Get all assets used in commissioning with irn target completion date
   * @param wsCallBack  callback class
   */
   getAssetListWithIRNTargetCompletionDate(wsCallBack: WsCallback) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    const options = { params: new HttpParams().set("token", token) }
    this.http.get(ServiceUrls.GET_ASSET_LIST_WITH_IRN_TARGET_COMPLETION_DATE, options).subscribe(
      data => {
        var modified = JSON.parse(JSON.stringify(data));
        var res = new WsResponse(
          modified.status.description,
          modified.status.code,
          modified.status.name,
          modified.payload);
        wsCallBack.onSuccess(res, WsType.GET_ASSETS);
      },
      error => {
        if (error.status != '') {
          var val = (error as HttpErrorResponse).error;
          var modified = JSON.parse(JSON.stringify(val));
          var res = new WsResponse(
            modified.status.description,
            modified.status.code,
            modified.status.name,
            modified.payload);
          wsCallBack.onFail(res, WsType.GET_ASSETS);
        } else {
          //browser related issues
          var res = new WsResponse("Unknown error happened");
          wsCallBack.onFail(res, WsType.GET_ASSETS);
        }
      }
    );
  }

  /**
   *
   * @param wsCallBack
   */
  getSystemList(wsCallBack: WsCallback) {
    let token = UserVariable.getUserToken();
    if(!this.commonUtil.validateToken(token)){
      return;
    }
    let url = this.getsystemsBySearch(token);
    return this.http.post(url, JSON.stringify(new SystemSearchRequest(null, null, null, true, null))).subscribe(
        data => {
          var modified = JSON.parse(JSON.stringify(data));
          var res = new WsResponse(
              modified.status.description,
              modified.status.code,
              modified.status.name,
              modified.payload);
          wsCallBack.onSuccess(res, WsType.GET_SYSTEMS_BY_PROJECT_ID);
        },
        error => {
          if (error.status != '') {
            var val = (error as HttpErrorResponse).error;
            var modified = JSON.parse(JSON.stringify(val));
            var res = new WsResponse(
                modified.status.description,
                modified.status.code,
                modified.status.name,
                modified.payload);
            wsCallBack.onFail(res, WsType.GET_SYSTEMS_BY_PROJECT_ID);
          } else {
            //browser related issues
            var res = new WsResponse("Unknown error happened");
            wsCallBack.onFail(res, WsType.GET_SYSTEMS_BY_PROJECT_ID);
          }
        }
    );
  }

  /**
   * Get all active vendors
   * @param token user token
   * @param wsCallBack  callback class
   */
  public getAllVendors( wsCallBack: WsCallback) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    this.http.get(ServiceUrls.getAllVendors(token)).subscribe(
        data => {
          var modified = JSON.parse(JSON.stringify(data));
          var res = new WsResponse(
              modified.status.description,
              modified.status.code,
              modified.status.name,
              modified.payload);
          wsCallBack.onSuccess(res, WsType.GET_ALL_VENDORS);
        },
        error => {
          if (error.status != '') {
            var val = (error as HttpErrorResponse).error;
            var modified = JSON.parse(JSON.stringify(val));
            var res = new WsResponse(
                modified.status.description,
                modified.status.code,
                modified.status.name,
                modified.payload);
            wsCallBack.onFail(res, WsType.GET_ALL_VENDORS);
          } else {
            //browser related issues
            var res = new WsResponse("Unknown error happened");
            wsCallBack.onFail(res, WsType.GET_ALL_VENDORS);
          }
        }
    );
  }

  /**
   * getAllVendorswithDisables
   * @param wsCallBack
   */
  public getAllVendorswithDisables(wsCallBack: WsCallback) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    this.http.get(ServiceUrls.getAllVendorswithDisables(token)).subscribe(
        data => {
          var modified = JSON.parse(JSON.stringify(data));
          var res = new WsResponse(
              modified.status.description,
              modified.status.code,
              modified.status.name,
              modified.payload);
          wsCallBack.onSuccess(res, WsType.GET_ALL_VENDORS_FOR_IRN);
        },
        error => {
          if (error.status != '') {
            var val = (error as HttpErrorResponse).error;
            var modified = JSON.parse(JSON.stringify(val));
            var res = new WsResponse(
                modified.status.description,
                modified.status.code,
                modified.status.name,
                modified.payload);
            wsCallBack.onFail(res, WsType.GET_ALL_VENDORS_FOR_IRN);
          } else {
            //browser related issues
            var res = new WsResponse("Unknown error happened");
            wsCallBack.onFail(res, WsType.GET_ALL_VENDORS_FOR_IRN);
          }
        }
    );
  }
  /**
   * Call service method to add irn using POST request
   *
   * @param irnNumber
   * @param irnDescription
   * @param selectedSystemId
   * @param seletedAssetsIdList
   * @param selectedVendorId
   * @param selectedPunchlistId
   * @param callback
   */

  addIrn(irnNumber: String, irnDescription: String, selectedSystemId: String, seletedAssetsIdList: String [], selectedVendorId: String, 
    selectedPunchlistId: String, irnStatus : String, targetCompletionDate : any, approvedDate :any, callback: WsCallback) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    let url = this.addIrnURL(token);
console.log('--------addIrnURL------------'+JSON.stringify(new Request(null, irnNumber, irnDescription, selectedVendorId, selectedSystemId, seletedAssetsIdList,irnStatus , targetCompletionDate,approvedDate)))
    this.http.post(url, JSON.stringify(new Request(null, irnNumber, irnDescription, selectedVendorId, selectedSystemId, seletedAssetsIdList,irnStatus , targetCompletionDate,approvedDate))).subscribe(
        data => {
          var modified = JSON.parse(JSON.stringify(data));
          var res = new WsResponse(
              modified.status.description,
              modified.status.name,
              modified.status.code,
              modified.payload);
          callback.onSuccess(res, WsType.ADD_NEW_IRN);
        },
        error => {
          var modified = JSON.parse(JSON.stringify(error));
          var res = new WsResponse(
              modified.error.status.description,
              modified.error.status.name,
              modified.error.status.code,
              modified.error.payload);
          callback.onFail(res, WsType.ADD_NEW_IRN);
        }
    );
  }

  addIrnURL(token) {
    let url = ServiceUrls.ADD_IRN + '?token=' + token;
    return url;
  }

  /**
   *Delete IRN File
   *
   * @param irnId
   */
  deleteIrnFile(irnId: string) {
    var token = UserVariable.getUserToken();
    var projectId = UserVariable.projectId;
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    let deletingFileData = new FormData();
    deletingFileData.append("irnId", irnId);
    deletingFileData.append("projectId", projectId);
    deletingFileData.append("filePath", '');
    deletingFileData.append("fileName", "");
    deletingFileData.append("contentType", "pdf");
    deletingFileData.append("token", token);
    deletingFileData.append("addedUserId", '');
    // @ts-ignore
    deletingFileData.append("isAdded", false);
    // @ts-ignore
    deletingFileData.append("isDeleted", true);
    this.http.post(ServiceUrls.ADD_NEW_IRN_DOCUMENT, deletingFileData, {
      reportProgress: true,
      observe: 'events'
    }).subscribe(
        event => {
          if (event.type == HttpEventType.UploadProgress) {
          } else if (event.type == HttpEventType.Response) {
            var modified = JSON.parse(JSON.stringify(event.body));
            var res = new WsResponse(
                modified.status.description,
                modified.status.code,
                modified.status.name,
                modified.payload);
            this.callback.onSuccess(res, WsType.DELETE_IRN_DOCUMENT)
          }
        },
        error => {
          if (error.error.payload != undefined) {
            var val = (error as HttpErrorResponse).error;
            var modified = JSON.parse(JSON.stringify(val));
            var res = new WsResponse(
                modified.status.description,
                modified.status.code,
                modified.status.name,
                modified.payload);
            this.callback.onFail(res, WsType.DELETE_IRN_DOCUMENT);
          } else {
            var res: WsResponse;
            if (error.error.actionErrors != undefined) {
              res = new WsResponse(error.statusText + ' - ' + error.error.actionErrors);
            } else {
              res = new WsResponse(error.message);
            }
            this.callback.onFail(res, WsType.DELETE_IRN_DOCUMENT);
          }
        }
    );
  }

  /**
   *Upload IRN document
   *
   * @param fileUploadData
   * @param callBack
   */
  public uploadFile(fileUploadData: FormData, callBack: WsCallback) {
    var token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    this.http.post(ServiceUrls.ADD_NEW_IRN_DOCUMENT, fileUploadData, {
      reportProgress: true,
      observe: 'events'
    }).subscribe(
        event => {
          if (event.type == HttpEventType.UploadProgress) {
            //progress
          } else if (event.type == HttpEventType.Response) {
            var modified = JSON.parse(JSON.stringify(event.body));
            var res = new WsResponse(
                modified.status.description,
                modified.status.code,
                modified.status.name,
                fileUploadData.get("irnId"));
            callBack.onSuccess(res, WsType.ADD_NEW_IRN_DOCUMENT);
          }
        },
        error => {
          if (error.error.payload != undefined) {
            var val = (error as HttpErrorResponse).error;
            var modified = JSON.parse(JSON.stringify(val));
            var res = new WsResponse(
                modified.status.description,
                modified.status.code,
                modified.status.name,
                modified.payload);
            callBack.onFail(res, WsType.ADD_NEW_IRN_DOCUMENT);
          } else {
            var res: WsResponse;
            if (error.error.actionErrors != undefined) {
              res = new WsResponse(error.statusText + ' - ' + error.error.actionErrors);
            } else {
              res = new WsResponse(error.message);
            }
            callBack.onFail(res, WsType.ADD_NEW_IRN_DOCUMENT);
          }
        }
    );
  }

  /**
   * Download IRN document
   *
   * @param irnId
   * @param projectId
   * @param fileName
   */
  public downloadFile(irnId: string, projectId: string, fileName: string) {
    return this.http.get(ServiceUrls.DOWNLOAD_FILE, {
      responseType: "blob",
      params: new HttpParams().set("irnId", irnId).set("projectId", projectId).set("mode", "DownloadIRN").set("fileName", fileName)
    });
  }

  /**
   * Search IRN
   *
   * @param json
   */
  public searchIrns(json) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    let url = this.getIrnSearchUrl(token);
    return this.http.post(url, json).pipe(
        map(response => {
          var modified = JSON.parse(JSON.stringify(response));
          return new WsResponse(
              modified.status.description,
              modified.status.name,
              modified.status.code,
              modified.payload);
        }),
        catchError(error => {
          var modified = JSON.parse(JSON.stringify(error));
          var res = new WsResponse(
              modified.error.status.description,
              modified.error.status.name,
              modified.error.status.code,
              modified.error.payload);
          return throwError(res)
        })
    );
  }

  public getIrnSearchUrl(token: String) {
    let url = ServiceUrls.IRN_SEARCH_VIEW + '?token=' + token;
    return url;
  }

  /**
   * Get IRN data
   *
   * @param callBack
   */
  getIrnDataByIrnId(irnId, callBack: WsCallback) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    // let irnId = this.sharedService.getFileId();
    this.http.get(ServiceUrls.getIrnByIrnId(token, irnId)).subscribe(
        data => {
          var modified = JSON.parse(JSON.stringify(data));
          var res = new WsResponse(
              modified.status.code,
              modified.status.name,
              modified.status.description,
              modified.payload);
          callBack.onSuccess(res, WsType.GET_IRN_BY_IRN_ID);
        },
        error => {
          var modified = JSON.parse(JSON.stringify(error));
          var res = new WsResponse(
              modified.status.code,
              modified.status.name,
              modified.status.description,
              modified.payload);
          callBack.onSuccess(res, WsType.GET_IRN_BY_IRN_ID);
        });
  }

  /**
   * Update IRN by ID
   *
   * @param irnId
   * @param irnNumber
   * @param irnDescription
   * @param selectedSystemId
   * @param seletedAssetsIdList
   * @param selectedVendorId
   * @param selectedPunchlistId
   * @param callback
   */
  updateIrn(irnId: String, irnNumber: String, irnDescription: String, selectedSystemId: String, seletedAssetsIdList: String [], selectedVendorId: String, selectedPunchlistId: String, 
    irnStatus : String, targetCompletionDate : any, approvedDate : any, callback: WsCallback) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    const options = {params: new HttpParams().set("token", token)}
    let url = ServiceUrls.IRNS + irnId + '.json'
    let dataMOdel = JSON.stringify(new Request(irnId, irnNumber, irnDescription, selectedVendorId, selectedSystemId, seletedAssetsIdList,irnStatus,targetCompletionDate,approvedDate))
    this.http.put(url, dataMOdel, options).subscribe(
        data => {
          var modified = JSON.parse(JSON.stringify(data));
          var res = new WsResponse(
              modified.status.description,
              modified.status.code,
              modified.status.name,
              modified.payload);
          callback.onSuccess(res, WsType.UPDATE_IRN);
        },
        error => {
          if (error.status != '') {
            var val = (error as HttpErrorResponse).error;
            var modified = JSON.parse(JSON.stringify(val));
            var res = new WsResponse(
                modified.status.description,
                modified.status.code,
                modified.status.name,
                modified.payload);
            callback.onFail(res, WsType.UPDATE_IRN);
          } else {
            var res = new WsResponse("Unknown error happened");
            callback.onFail(res, WsType.UPDATE_IRN);
          }
        }
    );
  }


  /**
   * Delete punchlist by id
   * @param punchlistId
   */
  deletePunchlist(punchlistId: string) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateLogin(token)) {
      return;
    }
    const options = {params: new HttpParams().set("token", token)}
    let url = ServiceUrls.PUNCHLISTS + punchlistId + '/destroy.json';
    this.http.delete(url, options).subscribe(
        data => {
          var modified = JSON.parse(JSON.stringify(data));
          console.log("deleteItem: " + JSON.stringify(data));
          if (modified.payload != false) {
            modified.payload = punchlistId;
          }
          var res = new WsResponse(
              modified.status.description,
              modified.status.name,
              modified.status.code,
              modified.payload);
          this.addEditComponent.onSuccess(res, WsType.DELETE_PL_FROM_IRN);
        },
        error => {
          var modified = JSON.parse(JSON.stringify(error));
          var res = new WsResponse(
              modified.status.description,
              modified.status.code,
              modified.status.name,
              modified.payload);
          this.addEditComponent.onFail(res, WsType.DELETE_PL_FROM_IRN);
        }
        );
  }

  public deleteIrnPunchlistIdResponse() {
    return this.deleteIrnPunchlistId;
  }
  public deleteIrnPunchlistIdRequest() {
    this.deleteIrnPunchlistId.emit();
  }
  /**
   * Get IRN List By Asset ID
   *
   * @param assetId
   * @param callBack
   */
  getIrnListByAssetId(assetId: string, callBack: WsCallback) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    this.http.get(ServiceUrls.getIrnByAssetId(token, assetId)).subscribe(
        data => {
          var modified = JSON.parse(JSON.stringify(data));
          var res = new WsResponse(
              modified.status.code,
              modified.status.name,
              modified.status.description,
              modified.payload);
          callBack.onSuccess(res, WsType.GET_IRN_BY_ASSET_ID);
        },
        error => {
          var modified = JSON.parse(JSON.stringify(error));
          var res = new WsResponse(
              modified.status.code,
              modified.status.name,
              modified.status.description,
              modified.payload);
          callBack.onSuccess(res, WsType.GET_IRN_BY_ASSET_ID);
        });
  }

  /**
   * Get MCC List By IRN ID
   *
   * @param irnId
   * @param callBack
   */
  getMCCListByIRNId(irnId: string, callBack: WsCallback) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    this.http.get(ServiceUrls.getMCCListByIRNId(token, irnId)).subscribe(
        data => {
          var modified = JSON.parse(JSON.stringify(data));
          var res = new WsResponse(
              modified.status.code,
              modified.status.name,
              modified.status.description,
              modified.payload);
          callBack.onSuccess(res, WsType.GET_MCC_DATA_BY_IRN_ID);
        },
        error => {
          var modified = JSON.parse(JSON.stringify(error));
          var res = new WsResponse(
              modified.status.code,
              modified.status.name,
              modified.status.description,
              modified.payload);
          callBack.onSuccess(res, WsType.GET_MCC_DATA_BY_IRN_ID);
        });
  }

  /**
   * Get OTN List By IRN ID
   *
   * @param irnId
   * @param callBack
   */
  getOTNListByIRNId(irnId: string, callBack: WsCallback) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    this.http.get(ServiceUrls.getOTNListByIRNId(token, irnId)).subscribe(
        data => {
          var modified = JSON.parse(JSON.stringify(data));
          var res = new WsResponse(
              modified.status.code,
              modified.status.name,
              modified.status.description,
              modified.payload);
          callBack.onSuccess(res, WsType.GET_OTN_DATA_BY_IRN_ID);
        },
        error => {
          var modified = JSON.parse(JSON.stringify(error));
          var res = new WsResponse(
              modified.status.code,
              modified.status.name,
              modified.status.description,
              modified.payload);
          callBack.onSuccess(res, WsType.GET_OTN_DATA_BY_IRN_ID);
        });
  }

}
class AssetRequest {
  constructor(private tagNo: String, private cadNo: String, private description: String, private systemName: String, private documentName: String, private facilityName: String, private hasText: String,private usedInCommissioning: Boolean) { }
}
class SystemSearchRequest {
  constructor(private systemNo: String, private systemName: String, private facilityName: String, private isCommissioning: boolean, private hasText: String) { }
}
class Request {
  constructor( private irnId: String, private irnNumber: String, private description: String,private vendorId : String,private systemId : String,private assetIds : String[],
    private irnstatus: String, private targetCompletionDate: any, private approvedDate: any) { }
}
