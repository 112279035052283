export class DocumentStoreModel {
   constructor(
      private documentId: string,
      private documentTitle: string,
      private documentNo: string,
      private systemId: string,
      private facilityIdList: string[],
      private version: string,
      private location: string,
      private notes: string,      
      private outputGeneratorId: string,
      private availability: string,
      private inReview: string,
      private documentType: string){};
}