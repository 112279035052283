import { NgModule } from '@angular/core';
import { RouterModule, Routes, Route } from '@angular/router';
import { LoginComponent } from './user/login/login.component';
import { LandingComponent } from './landing/landing.component';
import { HomeComponent } from './home/home.component';
import { KeycodeverificationComponent } from './user/login/keycodeverification/keycodeverification.component';
import { ChangepasswordComponent } from './user/login/changepassword/changepassword.component';
import { ForgotpasswordComponent } from './user/login/forgotpassword/forgotpassword.component';
import { PasswordChangeStatusComponent } from './user/login/changepassword/password-change-status/password-change-status.component';
import { ProcedureComponent } from './procedure/procedure.component';
import { TestComponent } from './open-access/test/test.component';
import { EditTagsComponent } from './reusables/tags/edit-tags/edit-tags.component';
import { AddTagComponent } from './reusables/tags/add-tag/add-tag.component';
import { AddeditstatementComponent } from './reusables/statements/addeditstatement/addeditstatement.component';
import { AddedfacilitiesComponent } from './reusables/facilities/addedfacilities/addedfacilities.component';
import { SplashComponent } from './splash/splash.component';
import { PageNotFoundComponent } from './util/page-not-found/page-not-found.component';
import { ProcedureStoreComponent } from './systemsetting/procedurestore/procedurestore.component';
import { AddProcedureStoreComponent } from './systemsetting/procedurestore/addprocedurestore/addprocedurestore.component';
import { DeletedProcedureStoreComponent } from './systemsetting/procedurestore/deletedprocedurestore/deletedprocedurestore.component';
import { ProcedureInstancesComponent } from './systemsetting/procedurestore/procedureinstances/procedureinstances.component';
import { AddeditsystemsComponent } from './reusables/systems/addeditsystems/addeditsystems.component';
import { AddeditdocumentsComponent } from './reusables/documents/addeditdocuments/addeditdocuments.component';
import { AddeditcomponentsComponent } from './reusables/components/addeditcomponents/addeditcomponents.component';
import { UserComponent } from './user/user.component';
import { ClientComponent } from './client/client.component';
import { OutputQueueComponent } from './systemsetting/outputmanagement/outputqueue/outputqueue.component'
import { OutputConfigComponent } from './systemsetting/outputmanagement/outputconfig/outputconfig.component'

import { OutputAddProjectTempComponent } from './systemsetting/outputmanagement/outputaddprojecttemp/outputaddprojecttemp.component'
import { OutputHomeComponent } from './systemsetting/outputmanagement/outputhome/outputhome.component'
import { OMAddTemplateComponent } from './systemsetting/outputmanagement/outputaddprojecttemp/addtemplate/addtemplate.component'
import { TagClassificationComponent } from './reusables/tags/tag-classification/tag-classification.component';
import { TagValueListComponent } from './reusables/tags/tag-value-list/tag-value-list.component';
import { AdminSettingsComponent } from './systemsetting/admin-settings/admin-settings.component';
import { AssignUserComponent } from './systemsetting/admin-settings/user-roles/assign-user/assign-user.component';
import { ChangeCapabilitiesComponent } from './systemsetting/admin-settings/user-roles/change-capabilities/change-capabilities.component';
import { DocumentTypesComponent } from './systemsetting/admin-settings/document-types/document-types.component';
import { DocumentAttributesComponent } from './systemsetting/admin-settings/document-types/document-attributes/document-attributes.component';
import { ManageAttributesComponent } from './reusables/tags/manage-attributes/manage-attributes.component';
import { ReportsComponent } from './reports/reports.component';
import { ViewreportdataComponent } from './reports/viewreportdata/viewreportdata.component';
import { DictionaryComponent } from './systemsetting/dictionary/dictionary.component';
import { AddwordtodictionaryComponent } from './systemsetting/dictionary/addwordtodictionary/addwordtodictionary.component';
import { ReusableupdateComponent } from './reusables/reusableupdate/reusableupdate.component';
import { AddeditclientComponent } from './client/addeditclient/addeditclient.component';
import { EdituserComponent } from './user/edituser/edituser.component';
import { AdduserComponent } from './user/adduser/adduser.component';
import { ProjectComponent } from './project/project.component';
import { AddProjectComponent } from './project/add-project/add-project.component';
import { EditProjectComponent } from './project/edit-project/edit-project.component';
import { OutputManagementComponent } from './systemsetting/outputmanagement/outputmanagement.component';
import { PsLoaderComponent } from './systemsetting/procedurestore/ps-loader/ps-loader.component';
import { DictionaryLoaderComponent } from './systemsetting/dictionary/dictionary-loader/dictionary-loader.component';
import { IceTopicComponent } from './ice-course/ice-topic/ice-topic.component';
import { IceTopicContentComponent } from './ice-course/ice-topic-content/ice-topic-content.component';
import { RuLoaderComponent } from './reusables/reusableupdate/ru-loader/ru-loader.component';
import { CommissioningComponent } from './commissioning/commissioning.component';
import { AddpunchlistComponent } from './commissioning/punchlist/addpunchlist/addpunchlist.component';
import { EditpunchlistComponent } from './commissioning/punchlist/editpunchlist/editpunchlist.component';
import { PunchlistComponent } from './commissioning/punchlist/punchlist.component';
import { FileDownloadComponent } from './util/file-download/file-download.component';
import { DsLoaderComponent } from './systemsetting/documentstore/ds-loader/ds-loader.component';
import { Auth0Component } from './user/login/auth0/auth0.component';
import { AddDocumentStoreComponent } from './systemsetting/documentstore/adddocumentstore/adddocumentstore.component';
import { DeletedDocumentStoreComponent } from './systemsetting/documentstore/deleteddocumentstore/deleteddocumentstore.component';
import { ProcedureInstancesDSComponent } from './systemsetting/documentstore/procedureinstances/procedureinstances.component';

//add path
import { OptDataComponent } from './opt-data/opt-data.component';
import { WorkflowComponent } from './systemsetting/workflow/workflow.component';
/**
 * Basic routing request with different parameters, each parameter has it's own purpose.
 * data{allowed: true/false} is a custom field which is used to indicate whether the user
 * can proceeds to the particular url without login into the system.
 */
const routes: Routes = [
	{ path: '', redirectTo: '/splash', pathMatch: 'full', data: { allowed: false } },
	{ path: 'login', component: LoginComponent, data: { allowed: false } },
	{ path: 'auth0', component: Auth0Component, data: { allowed: false } },
	{ path: 'home', component: HomeComponent, data: { allowed: false } },
	{ path: 'kvc', component: KeycodeverificationComponent, data: { allowed: false } },
	{ path: 'changepassword', component: ChangepasswordComponent, data: { allowed: false } },
	{ path: 'forgotpassword', component: ForgotpasswordComponent, data: { allowed: false } },
	{ path: 'forgotpassword/resetpage/loadPasswordResetPage.json', component: ForgotpasswordComponent, data: { allowed: true } },
	{ path: 'landing', component: LandingComponent, data: { allowed: false } },
	{ path: 'changePasswordStatus', component: PasswordChangeStatusComponent, data: { allowed: false } },
	{ path: 'procedure', component: ProcedureComponent, data: { allowed: false } },
	{ path: 'open', component: TestComponent, data: { allowed: true } },
	{ path: 'edittags', component: EditTagsComponent, data: { allowed: true } },
	{ path: 'addtags', component: AddTagComponent, data: { allowed: true } },
	{ path: 'addeditstatemet', component: AddeditstatementComponent, data: { allowed: false } },
	{ path: 'addedfacilities', component: AddedfacilitiesComponent, data: { allowed: false } },
	{ path: 'addeditsystems', component: AddeditsystemsComponent, data: { allowed: false } },
	{ path: 'addeditdocuments', component: AddeditdocumentsComponent, data: { allowed: false } },
	{ path: 'addeditcomponents', component: AddeditcomponentsComponent, data: { allowed: false } },
	{ path: 'splash', component: SplashComponent, data: { allowed: false } },
	{ path: 'procedurestore', component: PsLoaderComponent, data: { allowed: false } },
	{ path: 'addprocedurestore', component: AddProcedureStoreComponent, data: { allowed: false } },
	{ path: 'deletedprocedurestore', component: DeletedProcedureStoreComponent, data: { allowed: false } },
	{ path: 'procedureinstance', component: ProcedureInstancesComponent, data: { allowed: false } },
	{ path: 'documentstore', component: DsLoaderComponent, data: { allowed: false } },
	{ path: 'adddocumentstore', component: AddDocumentStoreComponent, data: { allowed: false } },
	{ path: 'deleteddocumentstore', component: DeletedDocumentStoreComponent, data: { allowed: false } },
	{ path: 'procedureinstances', component: ProcedureInstancesDSComponent, data: { allowed: false } },
	{ path: 'outputhome', component: OutputManagementComponent, data: { allowed: false } },
	{ path: 'outputaddprojecttemp', component: OutputAddProjectTempComponent, data: { allowed: false } },
	{ path: 'addtemplate', component: OMAddTemplateComponent, data: { allowed: false } },
	{ path: 'outputqueue', component: OutputQueueComponent, data: { allowed: false } },
	{ path: 'tagvaluelist', component: TagValueListComponent, data: { allowed: false } },
	{ path: 'outputconfig', component: OutputConfigComponent, data: { allowed: false } },
	{ path: 'reusableUpdate', component: RuLoaderComponent, data: { allowed: false } },
	{ path: 'tagclassification', component: TagClassificationComponent, data: { allowed: false } },
	{ path: 'outputconfig', component: OutputConfigComponent, data: { allowed: false } },
	{ path: 'adminSettings', component: AdminSettingsComponent,
	children: [
		{
		  path: 'assignuser',
		  component: AssignUserComponent
		},
		{
			path: 'capabilities',
			component: ChangeCapabilitiesComponent
		  }
		
	  ],
	data: { allowed: false } },
	{ path: 'user', component: UserComponent, data: { allowed: false } },
	{ path: 'client', component: ClientComponent, data: { allowed: false } },
	{ path: 'project', component: ProjectComponent, data: { allowed: false } },
	{ path: 'documentTypes', component: DocumentTypesComponent, data: { allowed: false } },
	{ path: 'documentAttributes', component: DocumentAttributesComponent, data: { allowed: false } },
	{ path: 'manageAttributes', component: ManageAttributesComponent, data: { allowed: false } },
	{ path: 'reports', component: ReportsComponent, data: { allowed: false } },
	{ path: 'dictionary', component: DictionaryLoaderComponent, data: { allowed: false } },
	{ path: 'addnewwordtodictionary', component: AddwordtodictionaryComponent, data: { allowed: false } },
	{ path: 'addeditclient', component: AddeditclientComponent, data: { allowed: false } },
	{ path: 'edituser', component: EdituserComponent, data: { allowed: false } },
	{ path: 'adduser', component: AdduserComponent, data: { allowed: false } },
	{ path: 'addProject', component: AddProjectComponent, data: { allowed: false } },
	{ path: 'editProject', component: EditProjectComponent, data: { allowed: false } },
	{ path: 'icetopiccontent', component: IceTopicContentComponent, data: { allowed: true} },
	{ path: 'viewreportdata', component: ViewreportdataComponent, data: { allowed: false } },
	{ path: 'commissioning', component: CommissioningComponent, data: { allowed: false } },
	{ path: 'addpunchlists', component: AddpunchlistComponent, data: { allowed: false }},
	{ path: 'editpunchlists', component: EditpunchlistComponent, data: { allowed: false }},
	{ path: 'searchpunchlist', component: PunchlistComponent, data: { allowed: true } },
	{ path: 'fileDownload', component: FileDownloadComponent, data: { allowed: false }},
	{ path: 'OptData', component: OptDataComponent, data: { allowed: false } },
	{ path: 'workflow', component: WorkflowComponent, data: { allowed: false } },
	{ path: '**', component: PageNotFoundComponent, data: { allowed: true } }
	
]

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {

    /**
     * Get the routing list
     */
	public static getRoutingPaths(): Route[] {
		return routes;
	}
}