import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { CustomModalService } from "./custommodal.service";
import { BsModalComponent } from 'ng2-bs3-modal';
import { CustomModal } from "./custommodal.modal";
import { AlertService } from '../alert/alert.service';
import * as $ from 'jquery';

@Component({
	selector: "app-modal",
	templateUrl: "custommodal.component.html",
	styleUrls: ["./custommodal.component.scss"]
})

export class CustomModalComponent implements OnInit {
	@ViewChild("disableWithDependencyModal")
	disableWithDependencyModal: BsModalComponent;
	// @ViewChild("deleteCancellationModal")
	// deleteCancellationModal: BsModalModule;
	// @ViewChild("deleteModal")
	// deleteModal: BsModalModule;
	// @ViewChild("statusChangeModal")
	// statusChangeModal: BsModalModule;
	// @ViewChild("showHideChangeModal")
	// showHideChangeModal: BsModalModule;
	// @ViewChild("dependencyModal")
	// dependencyModal: BsModalModule;
	// @ViewChild("copytModal")
	// copytModal: BsModalModule;

	private element: JQuery;
	public modal: CustomModal;
	public statusText: any;
	public statusTitle: any;
	public folderName: any;

	constructor(
		private alertService: AlertService,
		private CustomModalService: CustomModalService,
		private el: ElementRef
	) {
		this.element = $(el.nativeElement);
	}

	ngOnInit() {
		// move element to bottom of page (just before </body>) so it can be displayed above everything else
		console.log("kkkkk")
		this.element.appendTo("body");

		// add self (this modal instance) to the modal service so it's accessible from controllers
		this.modal = <CustomModal>{ title: "", folderName: "" };
		this.CustomModalService.add(this);
	}

	// public statusChanege() {
	// 	this.modal.component.changeStatus(this.modal.itemId, this.modal.status);
	// }

	// public delete() {
	// 	this.modal.component.delete();
	// }

	// public showHideChanege() {
	// 	this.modal.component.showHideEvent(this.modal.itemId, this.modal.status);
	// }
	requestModalClose() {
		this.disableWithDependencyModal.close();



	}

	save() {

	}

	public open(modalId: string) {
		this.folderName = "hhh"
		this.disableWithDependencyModal.open('lg')
		// if (modalId == 'statusChange') {
		// 	if (this.modal.status == 0 || this.modal.dependency == null || this.modal.dependency.length == 0) {
		// 		if (this.modal.title == 'Org Code') {
		// 			this.modal.component.changeStatus(this.modal.itemId, this.modal.status);
		// 		}
		// 		else {
		// 			this.statusTitle = this.modal.status == 0 ? "Enable" : "Disable";
		// 			this.statusText = this.statusTitle.toLowerCase();
		// 			//this.statusChangeModal.open();
		// 			//this.modal.open();
		// 		}
		// 	}
		// 	else if (this.modal.dependency != null && this.modal.dependency.length > 0) {
		// 		if (this.modal.title == 'Org Code') {
		// 			this.modal.component.close();
		// 		}
		// 		this.disableWithDependencyModal.open('lg')
		// 	}
		// }
		// else if (modalId == 'delete') {
		// 	if (this.modal.dependency != null && this.modal.dependency.length > 0) {
		// 		if (this.modal.title == 'Role') {
		// 		//	this.deleteCancellationModal.open('lg');
		// 			this.modal.status.checked = true;
		// 			this.modal.component.access = true;
		// 		}
		// 		else if (this.modal.title == 'Attribute') {
		// 			this.alertService.clear();
		// 			//this.alertService.error(Constants.ERROR_ONGOING_ASSESSMENTS);
		// 			this.modal.status[0].checked = true;
		// 			this.modal.status[1].checked = false;
		// 		}
		// 		else {
		// 			//this.deleteCancellationModal.open('lg');
		// 		}
		// 	}
		// 	else if (this.modal.title != 'Role' && this.modal.title != 'Attribute') {
		// 		//this.deleteModal.open();
		// 	}
		// }
		// else if (modalId == 'showHide') {
		// 	this.statusTitle = this.modal.status == 0 ? "Show" : "Hide";
		// 	this.statusText = this.statusTitle.toLowerCase();
		// 	//this.showHideChangeModal.open();
		// }
		// else if (modalId == 'edit') {
		// 	//this.dependencyModal.open('lg');
		// }
		// else if (modalId == 'copy') {
		// 	//this.copytModal.open('lg');
		// }
	}
}
