import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AllModules, GridOptions, Module } from "@ag-grid-enterprise/all-modules";
import { BsModalService } from 'ngx-bootstrap/modal';
import { AlertService } from 'src/app/util/alert/alert.service';
import { CapabilityService } from 'src/app/util/capability.service';
import { UserVariable } from 'src/app/util/common/user-variable';
import { LoadingService } from 'src/app/util/loading/loading.service';
import { SharedService } from 'src/app/util/shared.service';
import { AddToDocumentStoreButtonRender } from '../cell-render/addtostorebutton/addtostorebutton.component';
import { DeleteButtonAddedDocumentStoreRender } from '../cell-render/addtostorebutton/deleteaddedstore.component';
import { DocumentStoreService } from '../documentstore.service';

@Component({
   selector: 'app-adddocumentstore',
   templateUrl: './adddocumentstore.component.html',
   styleUrls: ['./adddocumentstore.component.css']
})
export class AddDocumentStoreComponent implements OnInit {
   public isMinimized: boolean = false
   public rowData: any[] = [];
   private storeDocumentData: any[] = [];
   private gridApi;
   private gridColumnApi;
   public columnDefs;
   private customColumn;
   private ds_translated_data: any = null;
   public frameworkComponents;
   public gridOptions: GridOptions;
   public defaultColDef: any;
   private projectId: string = UserVariable.projectId
   public facilityList: Array<any> = []
   public selectedFacility: Array<any> = [{ 'id': 'ALL', 'text': 'ALL' }, { 'id': 'None', 'text': 'None' }]  
   public facilityId: string;
   public facilityName: string;
   public modules: Module[] = AllModules;

   constructor(private documentStoreService: DocumentStoreService, private sharedService: SharedService, private translate: TranslateService, private alertService: AlertService,
      private capabilityService: CapabilityService, private modalService: BsModalService, private loadingService: LoadingService) {

      let browserLang = translate.getBrowserLang();
      this.gridOptions = <GridOptions>{
         context: {
            componentParent: this
         }
      };
      this.defaultColDef = {
			resizable: true,
			sortable: true,
		};
      translate.getTranslation(browserLang).subscribe((res: string) => {
         this.ds_translated_data = res['DOCUMENT_STORE']

         this.columnDefs = [
            {
               headerName: this.ds_translated_data.DOCUMENT, field: 'documentTitle', autoHeight: true, cellClass: "cell-wrap-text", filter: "agTextColumnFilter"
            },
            {
               headerName: this.ds_translated_data.FOLDER_PATH, field: 'folderPath', autoHeight: true, cellClass: "cell-wrap-text"
            },
            {
               headerName: this.ds_translated_data.FACILITY, field: 'facilityName', autoHeight: true, cellClass: "cell-wrap-text",
               cellRenderer: function (params) {
               var eDiv = document.createElement('div');
               if(params.data.facilityList != null){
                  var i=0;
                  var listSize = Object.keys(params.data.facilityList).length
                  for (var key in params.data.facilityList) {
                     eDiv.innerHTML += params.data.facilityList[key];
                     i++;
                     if(i < listSize){
                     eDiv.innerHTML += "<br/>";
                     }
                  }
               }
               return eDiv;
               }
            },
            {
               headerName: this.ds_translated_data.VERSION, field: 'versionNo', autoHeight: true, cellClass: "cell-wrap-text", filter: "agTextColumnFilter"
            },
            {
               headerName: this.ds_translated_data.ADDED_DATE, field: 'publishedTime', autoHeight: true, cellClass: "cell-wrap-text", comparator: this.dateTimeComparator,
               filter: "agDateColumnFilter",
               filterParams: {
                  comparator: function (filterLocalDateAtMidnight, cellValue) {
                     var dateAsString = cellValue.substring(0,10);
                     var cellDateTime = new Date(dateAsString);
                     var cellDate = new Date(cellDateTime.getFullYear(), cellDateTime.getMonth(), cellDateTime.getDate())
                     if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                        return 0;
                     }
                     if (cellDate < filterLocalDateAtMidnight) {
                        return -1;
                     }
                     if (cellDate > filterLocalDateAtMidnight) {
                        return 1;
                     }
                  }
               }
            },
            {
               headerName: this.ds_translated_data.ADD_TO_STORE, field: '', autoHeight: true, cellClass: "cell-wrap-text", width: 85, suppressFiltersToolPanel: true,
               cellRendererFramework: AddToDocumentStoreButtonRender
            },
            {
               headerName: this.ds_translated_data.DELETE, field: '', autoHeight: true, cellClass: "cell-wrap-text", width: 85, suppressFiltersToolPanel: true,
               cellRendererFramework: DeleteButtonAddedDocumentStoreRender
            }
            

         ]
      });
   }
   dateTimeComparator(date1:any, date2:any) {
      let parts = date1.match(/(\d{1,2})-(\d{1,2})-(\d{4}) (\d{1,2}):(\d{2}):(\d{2})/);
      let date1Number = Date.UTC(+parts[3], parts[1]-1, +parts[2], +parts[4], +parts[5], +parts[6]);

      parts = date2.match(/(\d{1,2})-(\d{1,2})-(\d{4}) (\d{1,2}):(\d{2}):(\d{2})/);
      let date2Number = Date.UTC(+parts[3], parts[1]-1, +parts[2], +parts[4], +parts[5], +parts[6]);

      if (date1Number === null && date2Number === null) {
         return 0;
      }
      if (date1Number === null) {
         return -1;   
      }
      if (date2Number === null) {
         return 1;
      }

      return date1Number - date2Number;
   }

   ngOnInit() {
   }

   onColumnResized(event) {
      if (event.finished) {
         this.gridApi.resetRowHeights();
      }
   }

   onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      params.api.sizeColumnsToFit();
      this.getAddDocumentStoreViewData();
      setTimeout(function () {
         params.api.sizeColumnsToFit();
         params.api.resetRowHeights();
      }, 500);
      this.documentStoreService.tableReloadRespond().subscribe(
         (option: any) => {
            console.log("reload table event= "+ option.event);
            if(this.facilityId==null){
               this.getAddDocumentStoreViewData();
            }
            else {
               this.getDocumentStoreItemsByFacility();
            }
         }
      )
   }
   removedFacility(event) {
      this.selectedFacility = [{ 'id': 'ALL', 'text': 'ALL' }]
   }

   selectedFacilityId(event) {
      this.facilityId = event.id    
      this.facilityName = event.text        
      this.getDocumentStoreItemsByFacility()
   }

   public getDocumentStoreItemsByFacility() {
      this.loadingService.showLoading(true, null, "", null)
      this.rowData = []
      this.documentStoreService.getDocumentStoreItemsByFacility(this.facilityId)
         .subscribe(data => {
            this.loadingService.hideLoading()
            this.rowData = data.payload;
            this.gridApi.sizeColumnsToFit();
         },
            error => {
               this.loadingService.hideLoading()
               this.alertService.clear()
               this.alertService.error(error.statusDescription)
            });
   }

   public getAddDocumentStoreViewData() {
      this.loadingService.showLoading(true, null, "", null)
      this.rowData = []
      this.documentStoreService.getAddDocumentStoreViewData(this.projectId)
         .subscribe(data => {
            this.storeDocumentData = []
            this.rowData = []
            if (data.payload != null) {
            data.payload.forEach(element => {
                  if (element.id != null) {
                  this.storeDocumentData.push( element)
                  }
            });
            this.rowData = this.storeDocumentData;
            this.gridApi.sizeColumnsToFit();
            this.facilityList = []
            this.facilityList.push({ 'id': 'ALL', 'text': 'ALL' }, { 'id': 'None', 'text': 'None' })
            data.payload.forEach(element => {
                  if (element.id == null && element.facilityList != null) {                  
                  for (let key of Object.keys(element.facilityList)) {                     
                     this.facilityList.push({ 'id': key, 'text': element.facilityList[key] })
                  }
                  }
            });
            } else {
            //nothing to do here
            }
            
            this.loadingService.hideLoading()
         
         },
         error => {
            this.loadingService.hideLoading()
            this.alertService.clear()
            this.alertService.error(error.statusDescription)
         });
   }

}
