<div class="modal-header py-2">
    <h5 class="modal-title">
        {{ 'COMPONENT_ADD_EDIT.COMPONENTS' | translate }}
    </h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form>
        <!--Component name-->
        <div class="form-group mb-2">
			<div *ngIf="component!=null">
				<label class="mb-0">
						{{component.referenceCount}} {{ 'STATEMENT_ADD_EDIT.PLACES' | translate }},
						{{ 'STATEMENT_ADD_EDIT.IN' | translate }} {{component.dependencyCount}}
						{{ 'STATEMENT_ADD_EDIT.TOPICS' | translate }}, {{ 'STATEMENT_ADD_EDIT.IN' | translate }}
						{{component.procDependencyCount}}
						{{ 'STATEMENT_ADD_EDIT.PROCEDURES' | translate }}, {{ 'STATEMENT_ADD_EDIT.IN' | translate }}
						{{component.iceCourseDependencyCount}}
                        {{ 'STATEMENT_ADD_EDIT.ICECOURSES' | translate }}
                </label>
                <label class="mb-0">
                    <span class="badge border-badge-danger mx-2 py-2"
                        tooltip="{{ 'STATEMENT_ADD_EDIT.CHECKED_OUT' | translate }}">
                        <i class="fa fa-upload mx-2 fa-lg" aria-hidden="true"></i>
                        <label class="mx-2 mb-0">{{component.checkedOutTopicCount}}</label>
                    </span>
                    <span class="badge border-badge-success mx-2 py-2"
                        tooltip="{{ 'STATEMENT_ADD_EDIT.CHECKED_IN' | translate }}">
                        <i class="fa fa-download mx-2 fa-lg" aria-hidden="true"></i>
                        <label class="mx-2 mb-0">{{component.checkedInTopicCount}}</label>
                    </span>
                </label>
				</div>
            <label for="componentName" class="mb-0">{{ 'COMPONENT_ADD_EDIT.COMPONENT_NAME' | translate }}<div class="badge-required mx-2"></div></label>
            <input *ngIf="component!=null" id="componentName" type="text" class="form-control form-control-sm"
                name="componentName" [(ngModel)]="component.componentName">
            <input #componentName *ngIf="component==null" id="componentName" type="text"
                class="form-control form-control-sm">
        </div>

        <!--Component value-->
        <div *ngIf="!reusable" class="form-group mb-2">
            <label for="componentValue" class="mb-0">{{ 'COMPONENT_ADD_EDIT.COMPONENT_VALUE' | translate }}<div class="badge-required mx-2"></div></label>
            <button type="button" class="btn btn-sm float-right my-1 btn-sm-form-dependancy mx-1"
            [ngClass]="{'btn-outline-dark': !showSC, 'btn-dark': showSC}" (click)="showSpecialCharacters()">Ω</button>
            <div *ngIf="showSC">
                <button type="button" class="btn btn-light btn-sm my-1 btn-sm-form-dependancy mx-1"
                    id="special_char_1" name="special_char_1" (click)="setSpecialCharacter($event)">@</button>
                <button type="button" class="btn btn-light btn-sm my-1 btn-sm-form-dependancy mx-1"
                    id="special_char_2" name="special_char_2" (click)="setSpecialCharacter($event)">©</button>
                <button type="button" class="btn btn-light btn-sm my-1 btn-sm-form-dependancy mx-1"
                    id="special_char_3" name="special_char_3" (click)="setSpecialCharacter($event)">Ã</button>
                <button type="button" class="btn btn-light btn-sm my-1 btn-sm-form-dependancy mx-1"
                    id="special_char_4" name="special_char_4" (click)="setSpecialCharacter($event)">Ø</button>
                <button type="button" class="btn btn-light btn-sm my-1 btn-sm-form-dependancy mx-1"
                    id="special_char_5" name="special_char_5" (click)="setSpecialCharacter($event)">è</button>
                <button type="button" class="btn btn-light btn-sm my-1 btn-sm-form-dependancy mx-1"
                    id="special_char_6" name="special_char_6" (click)="setSpecialCharacter($event)">µ</button>
                <button type="button" class="btn btn-light btn-sm my-1 btn-sm-form-dependancy mx-1"
                    id="special_char_7" name="special_char_7" (click)="setSpecialCharacter($event)">¶</button>
                <button type="button" class="btn btn-light btn-sm my-1 btn-sm-form-dependancy mx-1"
                    id="special_char_8" name="special_char_8" (click)="setSpecialCharacter($event)">æ</button>
                <button type="button" class="btn btn-light btn-sm my-1 btn-sm-form-dependancy mx-1"
                    id="special_char_9" name="special_char_9" (click)="setSpecialCharacter($event)">£</button>
                <button type="button" class="btn btn-light btn-sm my-1 btn-sm-form-dependancy mx-1"
                    id="special_char_0" name="special_char_0" (click)="setSpecialCharacter($event)">#</button>
                <button type="button" class="close float-right mt-1" aria-label="Close" (click)="hidePanels()">
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                
            <input #componentValue *ngIf="component!=null" id="componentValue" type="text" class="form-control form-control-sm"
                name="componentValue" [(ngModel)]="component.componentValue"(click)="getCaretPos(componentValue)"
                (keyup)="getCaretPos(componentValue)" (keydown)="getCaretPos(componentValue)">
            <input #componentValue *ngIf="component==null" id="componentValue" type="text"
                class="form-control form-control-sm" (click)="getCaretPos(componentValue)"(keyup)="getCaretPos(componentValue)"
                (keydown)="getCaretPos(componentValue)">
        </div>
        <div *ngIf="reusable" class="form-group mb-2">
            <label for="componentValue" class="mb-0">{{ 'COMPONENT_ADD_EDIT.COMPONENT_VALUE' | translate }}<div class="badge-required mx-2"></div></label>
            <button type="button" class="btn btn-sm float-right my-1 btn-sm-form-dependancy mx-1"
            [ngClass]="{'btn-outline-dark': !showSC, 'btn-dark': showSC}" (click)="showSpecialCharacters()">Ω</button>
            <div *ngIf="showSC">
                <button type="button" class="btn btn-light btn-sm my-1 btn-sm-form-dependancy mx-1"
                    id="special_char_1" name="special_char_1" (click)="setSpecialCharacter($event)">@</button>
                <button type="button" class="btn btn-light btn-sm my-1 btn-sm-form-dependancy mx-1"
                    id="special_char_2" name="special_char_2" (click)="setSpecialCharacter($event)">©</button>
                <button type="button" class="btn btn-light btn-sm my-1 btn-sm-form-dependancy mx-1"
                    id="special_char_3" name="special_char_3" (click)="setSpecialCharacter($event)">Ã</button>
                <button type="button" class="btn btn-light btn-sm my-1 btn-sm-form-dependancy mx-1"
                    id="special_char_4" name="special_char_4" (click)="setSpecialCharacter($event)">Ø</button>
                <button type="button" class="btn btn-light btn-sm my-1 btn-sm-form-dependancy mx-1"
                    id="special_char_5" name="special_char_5" (click)="setSpecialCharacter($event)">è</button>
                <button type="button" class="btn btn-light btn-sm my-1 btn-sm-form-dependancy mx-1"
                    id="special_char_6" name="special_char_6" (click)="setSpecialCharacter($event)">µ</button>
                <button type="button" class="btn btn-light btn-sm my-1 btn-sm-form-dependancy mx-1"
                    id="special_char_7" name="special_char_7" (click)="setSpecialCharacter($event)">¶</button>
                <button type="button" class="btn btn-light btn-sm my-1 btn-sm-form-dependancy mx-1"
                    id="special_char_8" name="special_char_8" (click)="setSpecialCharacter($event)">æ</button>
                <button type="button" class="btn btn-light btn-sm my-1 btn-sm-form-dependancy mx-1"
                    id="special_char_9" name="special_char_9" (click)="setSpecialCharacter($event)">£</button>
                <button type="button" class="btn btn-light btn-sm my-1 btn-sm-form-dependancy mx-1"
                    id="special_char_0" name="special_char_0" (click)="setSpecialCharacter($event)">#</button>
                <button type="button" class="close float-right mt-1" aria-label="Close" (click)="hidePanels()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <input #componentValue *ngIf="component!=null" id="componentValue" type="text" class="form-control form-control-sm"
                name="componentValue" [(ngModel)]="component.componentValue" (click)="getCaretPos(componentValue)"
                 (keyup)="getCaretPos(componentValue)" (keydown)="getCaretPos(componentValue)">
            <input #componentValue *ngIf="component==null" id="componentValue" type="text"
                class="form-control form-control-sm" (click)="getCaretPos(componentValue)" (keyup)="getCaretPos(componentValue)"
                (keydown)="getCaretPos(componentValue)">
            <!-- modified values & time -->
            <div class="card bg-danger my-2" >
                <div class="card-body py-1 px-2">
                    <p class="card-text my-1">{{reusablesName}}</p>
                    <p class="card-text my-1">{{reusablesModifiedBy}}</p>
                    <p class="card-text my-1">{{rusableModifiedTime}}</p>
                </div>
            </div>
        </div>

        <!--Category-->
        <div class="form-group mb-2">
            <label for="categoryId">{{ 'COMPONENT_ADD_EDIT.CATEGORY' | translate }}<div class="badge-required mx-2"></div></label>
            <input *ngIf="component!=null" type="text" class="form-control" id="categoryId" aria-describedby="category"
                name="categoryId" [(ngModel)]="component.category" (click)="showCategoryList(allCategoryModel)" 
                required>
            <input *ngIf="component==null" class="form-control form-control-sm" [id]="categoryId" readonly
                (click)="showCategoryList(allCategoryModel)" [value]="selectedCategoryItem.catName">
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" id="category_cancel" class="btn btn-secondary btn-sm" (click)="cancel()">{{
        'CANCEL' | translate}}</button>
    <button type="submit" id="category_save" class="btn btn-primary btn-sm" (click)="save()">{{
        'SAVE' | translate}}</button>

</div>

<!--Model to be passed-->
<ng-template #allCategoryModel>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Select a Category</h4>
        <button type="button" id="image_close" class="close pull-right" aria-label="Close"
            (click)="closeButtonClickEvent()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <app-select-category-tree (categorySelected)="selectedCategory($event)" [categoryType]="categoryType"
    [selectedCategoryItem]="tempCategoryItem" [showIncludeChildren]=false (closeModal)="closeButtonClickEvent()">
    </app-select-category-tree>
</ng-template>