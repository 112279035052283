<div>
    <button id="landing-table-buttons" class="btn btn-sm btn-outline-dark py-0 my-1 px-1 border-0"
        (click)="invoke(type)">
        <i class="{{icon}}" aria-hidden="true"></i>
    </button>
</div>
<div *ngIf="disable">
    <a class="btn btn-sm btn-outline-dark py-0 my-1 px-1 border-0" (click)="invoke(type, data)">
        <i class="text-black-50 {{icon}}" aria-hidden="true"></i>
    </a>
</div>

<ng-template #confirmation_warn>
    <div class="modal-header">
        <h4  class="modal-title pull-left">Edit Confirm</h4>
    </div>
    <div class="modal-body pb-0">
        <div>
            <h5>{{warningMsg}}</h5>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" id="warn_confirm" class="btn btn-primary"
            (click)="warnModalRef.hide(); warningConfirm();">
            OK</button>
        <button type="button" id="warn_cancel" class="btn btn-secondary" data-dismiss="modal"
            (click)="warnModalRef.hide()">
            Cancel </button>
    </div>
</ng-template>

