import { 
	Component, 
	OnInit,
	EventEmitter,
	ViewChild,
	ChangeDetectionStrategy, 
	Input,
	Output
} from '@angular/core';


@Component({
	selector: 'ngc-float-item-button',
	templateUrl: './ngc-float-item-btn.component.html',
	styleUrls: ['./ngc-float-item-btn.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NgcFloatItemBtnComponent implements OnInit {

	@Input() icon: string;
	@Input() content: string;
	@Input() color: string = 'white';
	@Output() clicked: EventEmitter<any> = new EventEmitter();
	@Input() disabled: boolean = false;
	@ViewChild('elementref', {static: true}) elementref;
	@ViewChild('contentref', {static: true}) contentref;

	constructor() { }

	ngOnInit() {
	}

	emitClickEvent($event: Event) {
		if (this.disabled)
			return this.disabled;

		this.clicked.emit($event);
	}

}
