import { Injectable } from '@angular/core';
import { WsCallback } from '../../../util/ws-callback.interface';
import { ServiceUrls } from '../../../util/service-urls';
import { WsResponse } from '../../../util/ws-response.model';
import { WsType } from '../../../util/ws-type';
import { HttpErrorResponse, HttpClient, HttpEventType } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
    constructor(private http: HttpClient) { }

    public uploadFile(fileUploadData: FormData, callBack: WsCallback) {
      this.http.post(ServiceUrls.ADD_NEW_MULTIPLE_IMAGE, fileUploadData, {
        reportProgress: true,
        observe: 'events'
      }).subscribe(
        event => {
          if (event.type == HttpEventType.UploadProgress) {
            //progress
          } else if (event.type == HttpEventType.Response) {
            var modified = JSON.parse(JSON.stringify(event.body));
            var res = new WsResponse(
              modified.status.description,
              modified.status.code,
              modified.status.name,
              modified.payload);
            callBack.onSuccess(res, WsType.ADD_NEW_MULTIPLE_IMAGE);
          }
        },
        error => {
          if (error.error.payload != undefined) {
            var val = (error as HttpErrorResponse).error;
            var modified = JSON.parse(JSON.stringify(val));
            var res = new WsResponse(
              modified.status.description,
              modified.status.code,
              modified.status.name,
              modified.payload);
            callBack.onFail(res, WsType.ADD_NEW_MULTIPLE_IMAGE);
          } else {
            var res: WsResponse;
            if (error.error.actionErrors != undefined) {
              res = new WsResponse(error.statusText + ' - ' + error.error.actionErrors);
            } else {
              res = new WsResponse(error.message);
            }
            callBack.onFail(res, WsType.ADD_NEW_MULTIPLE_IMAGE);
          }
        }
      );
    }
}