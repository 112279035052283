<alert class="floating-alert"></alert>
<nav id="navPanel" class="bg-light border-bottom box-shadow fixed-top navbar-expand-lg">
	<app-header [minimalOption]="false"></app-header>
</nav>

<div class="main-panel" [style.padding-top.px]="60" style="overflow: hidden;">
	<div class="row home-row">
		<div class="home-left-icon-panel home-left-panel-color" [style.height.px]="divHeight">
			<div class="my-4 home-left-button" [ngClass]="{'home-left-button-selected': selectedLeftItem == 'FOLDER_EXPLORER'}"
			 (click)="folderExplorer(true)" tooltip="Folder Explorer" container="body" placement="right">
				<img class="mx-auto" src="../../assets/icons/folder_icon.svg">
			</div>
			<div class="my-4 home-left-button" [ngClass]="{'home-left-button-selected': selectedLeftItem == 'TOPIC_MAP', 'disabled': !topicMapEnabled}"
			 (click)="topicMap(true)" tooltip="Topic Map" container="body" placement="right">
				<img class="mx-auto" src="../../assets/icons/topic_map_icon.svg" alt="">
			</div>
			<div class="my-4 home-left-button" [ngClass]="{'home-left-button-selected': selectedLeftItem == 'REFERENCE', 'disabled': !referenceEnabled}"
			 (click)="reference(true)" tooltip="Reference" container="body" placement="right">
			 <img class="mx-auto" src="../../assets/icons/reference.svg" alt="">
			</div>
		</div>
		<div class="col-sm-12 ex-b" [style.z-index]="0" [style.height.px]="divHeight" [style.padding-left.px]="36"
		 [style.padding-right.px]="0">
			<as-split [direction]="'horizontal'" [gutterSize]="2" restrictMove="true" (dragStart)="gutterDragStart()" (dragEnd)="gutterDragEnd()" (gutterClick)="gutterClick()">
				<as-split-area *ngIf="!showFullScreen" [order]="0" [size]="folderExplorerSplit" class="overflow-hidden">
					<!-- <div #leftComponentHost></div> -->
					<div class="pb-5" [ngClass]="{'content-show' : selectedLeftItem == 'FOLDER_EXPLORER', 'content-hide': !(selectedLeftItem == 'FOLDER_EXPLORER') }">
						<app-foldertree></app-foldertree>
					</div>
					<!-- *ngIf="selectedLeftItem == 'FOLDER_EXPLORER'"  -->
					<div [ngClass]="{'content-show' : selectedLeftItem == 'TOPIC_MAP', 'content-hide': !(selectedLeftItem == 'TOPIC_MAP') }">
						<app-topic-map-builder></app-topic-map-builder>
					</div>
					<!-- *ngIf="selectedLeftItem == 'TOPIC_MAP'"  -->
					<div class="pb-5" [ngClass]="{'content-show' : selectedLeftItem == 'REFERENCE', 'content-hide': !(selectedLeftItem == 'REFERENCE') }">
						<app-insert-reference></app-insert-reference>
					</div>
				</as-split-area>
				<as-split-area [order]="1" [size]="tabSplit">
					<app-tabhost [style.pointer-events]="gutterDragStarted ? 'none':'all'"></app-tabhost>
				</as-split-area>
				<as-split-area [order]="2" [size]="editorLayoutSplit" style="overflow-y:auto">
					<app-reusables></app-reusables>
				</as-split-area>
			</as-split>
		</div>
	</div>
</div>