import { CapabilityService } from 'src/app/util/capability.service';
import { Component, OnInit, ViewChild, ElementRef, Input, ViewContainerRef, AfterContentInit, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { ComponentRef, ComponentFactoryResolver } from '@angular/core';
import { AsLoaderService } from './as-loader.service';

@Component({
	selector: 'app-admin-settings',
	templateUrl: './admin-settings.component.html',
	styleUrls: ['./admin-settings.component.scss']
})
export class AdminSettingsComponent implements OnInit, AfterContentInit, OnChanges, OnDestroy {

	public paddingtop: number = 60;
    @ViewChild('navPanel', {static: true}) navPanel: ElementRef

    component: any;
    @ViewChild('asHost', { read: ViewContainerRef, static: true }) componentHost: ViewContainerRef
    componentRef: ComponentRef<Component>
    
    public isSideBar: boolean = true
    public componentToLoad: string = null

    constructor(private resolver: ComponentFactoryResolver, private aslService: AsLoaderService, private capabilityService:CapabilityService) {
        this.loadComponent()
        //this.component = this.aslService.loadComponentByName('documenttype')
        this.component = this.aslService.loadComponentByName(this.componentToLoad)
     }

    ngOnInit() { 
		localStorage.setItem("clientId", "");
        console.log(this.navPanel.nativeElement.offsetHeight);
		if (this.navPanel.nativeElement.offsetHeight > 55) {
			this.paddingtop = 78
		} else {
			this.paddingtop = 50
		}
    }

    public changeSideBarItem(event: any) {
        console.log('[changeSideBarItem] (event) ' + JSON.stringify(event));
        this.component = this.aslService.loadComponentByName(event.selectedItem)
        this.updateComponent()
    }
    
    ngAfterContentInit(): void {
        //Called after ngOnInit when the component's or directive's content has been initialized.
        //Add 'implements AfterContentInit' to the class.
        this.updateComponent()
    }

    ngOnChanges(changes: SimpleChanges): void {
        //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
        //Add '${implements OnChanges}' to the class.
    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        if (this.componentRef) {
			this.componentRef.destroy()
		}
    }

    updateComponent() {
        this.componentHost.clear()
		const authFormFactory = this.resolver.resolveComponentFactory(this.component)
		const componentRef = this.componentHost.createComponent(authFormFactory);
    }

    private loadComponent() {
        if (this.capabilityService.isCapabilityAssigned("queryTopicType")) {
            this.componentToLoad = "documenttype"
        } else if (this.capabilityService.isCapabilityAssigned("queryDocTypeAttribute")) {
            this.componentToLoad = "manageattributes"
        } else if (this.capabilityService.isCapabilityAssigned("queryUser")) {
            this.componentToLoad = "users"
        } else if (this.capabilityService.isCapabilityAssigned("createUsers")) {
            this.componentToLoad = "adduser"
        } else if (this.capabilityService.isCapabilityAssigned("queryClients")) {
            this.componentToLoad = "clients"
        } else if (this.capabilityService.isCapabilityAssigned("createClient")) {
            this.componentToLoad = "aeclient"
        } else if (this.capabilityService.isCapabilityAssigned("queryProjects")) {
            this.componentToLoad = "projects"
        } else if (this.capabilityService.isCapabilityAssigned("createProject")) {
            this.componentToLoad = "addprojects"
        } else if (this.capabilityService.isCapabilityAssigned("queryCategory")) {
            this.componentToLoad = "categorysetting"
        }  
    }

}
