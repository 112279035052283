import { Component, OnInit } from '@angular/core';
import { CategoryTreeService } from './categorytree.service';
import { WsResponse } from '../../util/ws-response.model';
import { WsCallback } from '../../util/ws-callback.interface';
import { WsType } from '../../util/ws-type';
import { AlertService } from '../../util/alert/alert.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
declare var $: any;

@Component({
	selector: 'app-categorytree',
	templateUrl: './categorytree.component.html',
	styleUrls: ['./categorytree.component.css']
})
export class CategorytreeComponent implements OnInit {
	public selectedItem: any = null;
	//	public documentTypeAttributes: any = [];
	public title: string = '';
	public treeListener: any;
	public data_n: any = {
		'data': [
		]
	}
	public sc: any = null

	constructor(private categoryTreeService: CategoryTreeService, private alertService: AlertService, public bsModalRef: BsModalRef) { }

	ngOnInit() {
		console.log("[CategorytreeComponent] (selectedItem) " + JSON.stringify(this.selectedItem))
		this.categoryTreeService.getCategories(this);
	}

	public clickOnCloseButton() {
		this.bsModalRef.hide();
	}

	loadCategoryTree(payload: any) {
		if (payload != null) {
			this.data_n['data'] = [
			];

			this.data_n["data"].push({ "id": "0", "parent": "#", "text": "Statement", icon: "fal fa-clipboard", type: "Statement", state: { disabled: true } })
			for (let i = 0; i < payload.length; i++) {
				this.data_n["data"].push({ 
				"id": payload[i]["categoryId"], 
				"parent": payload[i]["parentId"], 
				"text": payload[i]["categoryName"], 
				icon: payload[i]["status"] == "Inactive" ? "fas fa-clipboard" : "fal fa-clipboard", type: payload[i]["categoryType"] ,
				state : payload[i]["status"] == "Inactive" ? { "disabled" : true } : { "disabled" : false }
			})
			}
			// console.log("this.data_n " + JSON.stringify(this.data_n["data"]))
			$('#categorytree').jstree({
				core: {
					themes: {
						variant: 'normal'
					},
					data: this.data_n["data"],
					check_callback: true,
					force_text: true,
					initially_open: ['#' + this.selectedItem.id] 
				},
				ui: {
					initially_select: ['#' + this.selectedItem.id]
				},
				types: {
					default: {
						// "icon" : "fa fa-file-o "
					}
				},
				plugins: ["contextmenu", "dnd", "search", "state", "types", "wholerow", "ui"],
				search: {
					show_only_matches: true,
					show_only_matches_children: true
				}
			});

			// if (selectedTypeCount > 0) {
			// 	localStorage.setItem("selectedDocType", this.data_n["data"][selectedTypeCount]["typeId"])
			// 	setTimeout(() => $("#documenttypetree").jstree("select_node", this.data_n["data"][selectedTypeCount]["id"]), 200);
			// }

			// if (deselect_node > 0 && deselect_node != selectedTypeCount) {
			// 	setTimeout(() => $("#documenttypetree").jstree("deselect_node", this.data_n["data"][deselect_node]["id"]), 200);
			// }

			// $('#categorytree').bind("select_node.jstree", (evt, data) => this.categorySelectEvent(evt, data))
			$('#categorytree').bind("changed.jstree", (node: any, action: any, selected: any, event: any) =>
				this.categoryChanged(node, action, selected, event))
			$('#categorytree').bind("ready.jstree", (event: any, data: any) => this.jsTreeReady(event, data))
			$('#categorytree').bind("loaded.jstree", (event: any, data: any) => this.jsTreeLoaded(event, data))
			// $('#categorytree').bind("loaded.jstree", function (event, data) {
			// 	$(this).jstree("open_all");
			// })
		}
	}

	private jsTreeLoaded(event: any, data: any) {
		console.log('[jsTreeLoaded]');
	}

	private jsTreeReady(event: any, data: any) {
		$('#categorytree').jstree(true).deselect_all(true);
		console.log('[jsTreeReady]');
		$('#categorytree').jstree("open_all");
		let isLoaded = $('#categorytree').jstree(true).is_loaded(this.selectedItem.id)
		console.log('[jsTreeLoaded] (isLoaded) ' + JSON.stringify(isLoaded));
		let selectedNode = null
		let jsT = $('#categorytree').jstree(true).get_json('#', {flat:true})
		if(this.selectedItem.id != null && isLoaded) {
			selectedNode = jsT.find((i: { id: any; }) => i.id == this.selectedItem.id)
			$('#categorytree').jstree(true).select_node(selectedNode, true, true)
		} else {
			console.log("No Selected Category.");
		}
	}

	/**
	 * Callback method for select a node in the tree.
	 */
	public categorySelectEvent(evt: any, data: any) {
		this.treeListener.statement.categoryName = data.node.text;
		this.treeListener.statement.categoryId = data.node.id;
		this.bsModalRef.hide();
	}

	private categoryChanged(node: any, action: any, selected: any, event: any) {
		console.log('[categoryChanged] (action) ' + JSON.stringify(action.action));
		// console.log(action)
		if (action.action == 'select_node') {
			// this.treeListener.statement.categoryName = action.node.text;
			// this.treeListener.statement.categoryId = action.node.id;
			console.log(node);
			this.sc = {
				text: action.node.text,
				id: action.node.id
			}
		}
	}


	onSuccess(data: WsResponse, serviceType: WsType): void {
		if (data != null && WsType.CATEGORY_VIEW == serviceType) {
			this.loadCategoryTree(data.payload);
		}
	}

	onFail(res: WsResponse, serviceType: WsType): void {
		if (res != null && WsType.CATEGORY_VIEW == serviceType) {
			if (res.statusCode == "600") {
				this.categoryTreeService.getCategories(this);
			} else {
				this.alertService.error(res.statusDescription, false);
			}
		}
	}

	public selectCategory() {
		if(this.sc != null) {
			this.treeListener.statement.categoryName = this.sc.text;
			this.treeListener.statement.categoryId = this.sc.id;
			this.bsModalRef.hide();
			$('#categorytree').jstree().destroy(true);
			this.sc = null
		} else {
			this.alertService.error('Please select a category.');
		}
	}

	public closeButtonClickEvent() {
		this.sc = null;
		this.bsModalRef.hide();
		$('#categorytree').jstree().destroy(true);
	}
}
