import { Injectable } from '@angular/core';
import { UserVariable } from "../common/user-variable";
import { catchError, map } from "rxjs/operators";
import { ServiceUrls } from "../service-urls";
import { CommonUtil } from "../common/common-util";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { WsResponse } from "../ws-response.model";
import { throwError } from "rxjs";
import { WsCallback } from "../ws-callback.interface";
import { WsType } from "../ws-type";
import { SharedService } from '../shared.service';
import { Constants } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class TaginputitemService {
  assetGroupList = [];

  constructor(private commonUtil: CommonUtil, private http: HttpClient, private sharedService: SharedService,) { }

  public getTagSearchURL(token: String) {
    let url = ServiceUrls.TAGS_SEARCH_VIEW + '?token=' + token;
    return url;
  }

  getAssetList(tagNo: String) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    let url = this.getTagSearchURL(token);

    //Achini Randeni
    //append '*' to get tag list which contains tagNo from solr
    tagNo = '*' + tagNo + '*';

    return this.http.post<any>(url, JSON.stringify(new AssetRequest(tagNo, null, "", null, null, null, null, true)))
      .pipe(map(payload => JSON.parse(payload).payload.map(item => new Object({ value: item.tagId, display: item.tagNo }))))

  }

  getAttributeList(tagNo: String) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }

    this.setAssetGroupTagInputs(Constants.SEARCH_KEY_WORDS.SEARCH_ASSET_GROUP);
    console.log("XXXXX", this.assetGroupList.length)

    let url = ServiceUrls.getAssetAttributeValues(token, this.assetGroupList[0].value);

    return this.http.get<any>(url)
      .pipe(map(payload => payload.payload.map(item => new Object({ value: item.attributeValue, display: item.attributeValueTxt }))))

  }

  setAssetGroupTagInputs(key: string) {
    var tempTagInputList = []
    var tempvalue = []
    this.assetGroupList = []
    //get selected tag inputs from shared service
    tempTagInputList = this.sharedService.getTagInputList();
    if (tempTagInputList.length > 0) {
      //extract the list by key
      tempTagInputList = tempTagInputList.filter(i => i.key == key)
      if (tempTagInputList.length > 0) {
        //get the values array
        tempvalue = tempTagInputList[0].value
        tempvalue.forEach(i => {
          this.assetGroupList.push({ value: i.value, display: i.display });
        });
      } else {
        //do nothing
      }
    } else {
      //do nothing
    }
  }

  public getIrnNumberList(irnNo: String) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    let data = {
      irnNumbers: "%" + irnNo + "%",
      irnDescriptions: "",
      irnAssets: "",
      irnVendors: "",
      irnSystems: ""
    }
    var json = JSON.stringify(data);
    let url = this.getIrnSearchUrl(token);
    return this.http.post<any>(url, json).
      pipe(map(payload => payload.payload.map(item => new Object({ value: item.irnId, display: item.irnNumber }))))
  }

  public getIrnDescriptionList(irnDesc: String) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    let data = {
      irnNumbers: "",
      irnDescriptions: "%" + irnDesc + "%",
      irnAssets: "",
      irnVendors: "",
      irnSystems: ""
    }
    var json = JSON.stringify(data);
    let url = this.getIrnSearchUrl(token);
    return this.http.post<any>(url, json).
      pipe(map(payload => payload.payload.map(item => new Object({ value: item.irnId, display: item.description }))))
  }
  public getIrnSearchUrl(token: String) {
    let url = ServiceUrls.IRN_SEARCH_VIEW + '?token=' + token;
    return url;
  }
  /**
   * Get all active vendors
   * @param token user token
   * @param wsCallBack  callback class
   */
  public getAllVendors(wsCallBack: WsCallback) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    this.http.get(ServiceUrls.getAllVendors(token)).subscribe(
        data => {
          var modified = JSON.parse(JSON.stringify(data));
          var res = new WsResponse(
              modified.status.description,
              modified.status.code,
              modified.status.name,
              modified.payload);
          wsCallBack.onSuccess(res, WsType.GET_ALL_VENDORS);
        },
        error => {
          if (error.status != '') {
            var val = (error as HttpErrorResponse).error;
            var modified = JSON.parse(JSON.stringify(val));
            var res = new WsResponse(
                modified.status.description,
                modified.status.code,
                modified.status.name,
                modified.payload);
            wsCallBack.onFail(res, WsType.GET_ALL_VENDORS);
          } else {
            //browser related issues
            var res = new WsResponse("Unknown error happened");
            wsCallBack.onFail(res, WsType.GET_ALL_VENDORS);
          }
        }
    );
  }

  public getsystemsBySearch(token: String) {
    let url = ServiceUrls.SYSTEMS_SEARCH_VIEW + '?token=' + token;
    return url;
  }
  /**
   * Get all commissioning systems
   * @param token user token
   * @param wsCallBack  callback class
   */
  getSystemList(wsCallBack: WsCallback) {
    let token = UserVariable.getUserToken();
    if(!this.commonUtil.validateToken(token)){
      return;
    }
    let url = this.getsystemsBySearch(token);
    return this.http.post(url, JSON.stringify(new SystemSearchRequest(null, null, null, true, null))).subscribe(
        data => {
          var modified = JSON.parse(JSON.stringify(data));
          var res = new WsResponse(
              modified.status.description,
              modified.status.code,
              modified.status.name,
              modified.payload);
          wsCallBack.onSuccess(res, WsType.GET_SYSTEMS_BY_PROJECT_ID);
        },
        error => {
          if (error.status != '') {
            var val = (error as HttpErrorResponse).error;
            var modified = JSON.parse(JSON.stringify(val));
            var res = new WsResponse(
                modified.status.description,
                modified.status.code,
                modified.status.name,
                modified.payload);
            wsCallBack.onFail(res, WsType.GET_SYSTEMS_BY_PROJECT_ID);
          } else {
            //browser related issues
            var res = new WsResponse("Unknown error happened");
            wsCallBack.onFail(res, WsType.GET_SYSTEMS_BY_PROJECT_ID);
          }
        }
    );
  }
  public getAllTargetCompletionDatesForMCCFilterURL( token :string) {
    let url = ServiceUrls.GET_MCC_TARGETCOMPLETION_DATES+ '?token=' + token;
    return url;
  }
  public getAllTargetCompletionDatesForMCCFilter( wsCallBack: WsCallback) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    let url = this.getAllTargetCompletionDatesForMCCFilterURL(token);
    this.http.get(url).subscribe(
        data => {
          var modified = JSON.parse(JSON.stringify(data));
          var res = new WsResponse(
              modified.status.description,
              modified.status.code,
              modified.status.name,
              modified.payload);
          wsCallBack.onSuccess(res, WsType.GET_MCC_TARGETCOMPLETION_DATES);
        },
        error => {
          if (error.status != '') {
            var val = (error as HttpErrorResponse).error;
            var modified = JSON.parse(JSON.stringify(val));
            var res = new WsResponse(
                modified.status.description,
                modified.status.code,
                modified.status.name,
                modified.payload);
            wsCallBack.onFail(res, WsType.GET_MCC_TARGETCOMPLETION_DATES);
          } else {
            //browser related issues
            var res = new WsResponse("Unknown error happened");
            wsCallBack.onFail(res, WsType.GET_MCC_TARGETCOMPLETION_DATES);
          }
        }
    );
  }
  // getAllActiveSystemsWithParentForCSHFilter
  public getAllActiveSystemsWithParentForCSHFilter( wsCallBack: WsCallback) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    let url = this.getAllActiveSystemsWithParentForCSHFilterrURL(token);
    this.http.get(url).subscribe(
        data => {
          var modified = JSON.parse(JSON.stringify(data));
          var res = new WsResponse(
              modified.status.description,
              modified.status.code,
              modified.status.name,
              modified.payload);
          wsCallBack.onSuccess(res, WsType.GET_ALL_SYSTEM_LIST_FOR_CSHFILTER);
        },
        error => {
          if (error.status != '') {
            var val = (error as HttpErrorResponse).error;
            var modified = JSON.parse(JSON.stringify(val));
            var res = new WsResponse(
                modified.status.description,
                modified.status.code,
                modified.status.name,
                modified.payload);
            wsCallBack.onFail(res, WsType.GET_ALL_SYSTEM_LIST_FOR_CSHFILTER);
          } else {
            //browser related issues
            var res = new WsResponse("Unknown error happened");
            wsCallBack.onFail(res, WsType.GET_ALL_SYSTEM_LIST_FOR_CSHFILTER);
          }
        }
    );
  }
  public getAllActiveSystemsWithParentForCSHFilterrURL( token :string) {
    let url = ServiceUrls.GET_ALL_SYSTEM_LIST_FOR_CSHFILTER+ '?token=' + token;
    return url;
  }
  
  public getAssetsForFilters(wsCallBack: WsCallback) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    let url = this.getAssetsForOTNFilterURL(token);
    this.http.get(url).subscribe(
      data => {
        var modified = JSON.parse(JSON.stringify(data));
        var res = new WsResponse(
          modified.status.description,
          modified.status.code,
          modified.status.name,
          modified.payload);
        wsCallBack.onSuccess(res, WsType.GET_ASSETS);
      },
      error => {
        if (error.status != '') {
          var val = (error as HttpErrorResponse).error;
          var modified = JSON.parse(JSON.stringify(val));
          var res = new WsResponse(
            modified.status.description,
            modified.status.code,
            modified.status.name,
            modified.payload);
          wsCallBack.onFail(res, WsType.GET_ASSETS);
        } else {
          //browser related issues
          var res = new WsResponse("Unknown error happened");
          wsCallBack.onFail(res, WsType.GET_ASSETS);
        }
      }
    );
  }
  public getAssetsForOTNFilter( wsCallBack: WsCallback) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    let url = this.getAssetsForOTNFilterURL(token);
    this.http.get(url).subscribe(
        data => {
          var modified = JSON.parse(JSON.stringify(data));
          var res = new WsResponse(
              modified.status.description,
              modified.status.code,
              modified.status.name,
              modified.payload);
          wsCallBack.onSuccess(res, WsType.GET_ASSETS);
        },
        error => {
          if (error.status != '') {
            var val = (error as HttpErrorResponse).error;
            var modified = JSON.parse(JSON.stringify(val));
            var res = new WsResponse(
                modified.status.description,
                modified.status.code,
                modified.status.name,
                modified.payload);
            wsCallBack.onFail(res, WsType.GET_ASSETS);
          } else {
            //browser related issues
            var res = new WsResponse("Unknown error happened");
            wsCallBack.onFail(res, WsType.GET_ASSETS);
          }
        }
    );
  }
  public getAssetsForOTNFilterURL( token :string) {
    let url = ServiceUrls.GET_ASSET_LIST+ '?token=' + token;
    return url;
  }
  
  public getAllTargetCompletionDatesForaOTNFilterURL( token :string) {
    let url = ServiceUrls.GET_OTN_TARGETCOMPLETION_DATES+ '?token=' + token;
    return url;
  }
  public getAllTargetCompletionDatesForOTNFilter( wsCallBack: WsCallback) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    let url = this.getAllTargetCompletionDatesForaOTNFilterURL(token);
    this.http.get(url).subscribe(
        data => {
          var modified = JSON.parse(JSON.stringify(data));
          var res = new WsResponse(
              modified.status.description,
              modified.status.code,
              modified.status.name,
              modified.payload);
          wsCallBack.onSuccess(res, WsType.GET_OTN_TARGETCOMPLETION_DATES);
        },
        error => {
          if (error.status != '') {
            var val = (error as HttpErrorResponse).error;
            var modified = JSON.parse(JSON.stringify(val));
            var res = new WsResponse(
                modified.status.description,
                modified.status.code,
                modified.status.name,
                modified.payload);
            wsCallBack.onFail(res, WsType.GET_OTN_TARGETCOMPLETION_DATES);
          } else {
            //browser related issues
            var res = new WsResponse("Unknown error happened");
            wsCallBack.onFail(res, WsType.GET_OTN_TARGETCOMPLETION_DATES);
          }
        }
    );
  }

  public getSystemsForFilters(wsCallBack: WsCallback) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    var request = { "userToken": token };

    this.http.post(ServiceUrls.GET_SYSTEM_LIST, JSON.stringify(request)).subscribe(
      data => {
        var modified = JSON.parse(JSON.stringify(data));
        var res = new WsResponse(
          modified.status.description,
          modified.status.code,
          modified.status.name,
          modified.payload);
          wsCallBack.onSuccess(res, WsType.GET_SYSTEM_LIST);
      },
      error => {
        if (error.status != '') {
          var val = (error as HttpErrorResponse).error;
          var modified = JSON.parse(JSON.stringify(val));
          var res = new WsResponse(
            modified.status.description,
            modified.status.code,
            modified.status.name,
            modified.payload);
            wsCallBack.onFail(res, WsType.GET_SYSTEM_LIST);
        } else {
          //browser related issues
          var res = new WsResponse("Unknown error happened");
          wsCallBack.onFail(res, WsType.GET_SYSTEM_LIST);
        }
      }
    );
  }

  public getAllTargetCompletionDatesForIRNFilterURL( token :string) {
    let url = ServiceUrls.GET_IRN_TARGETCOMPLETION_DATES+ '?token=' + token;
    return url;
  }

  public getAllTargetCompletionDatesForIRNFilter( wsCallBack: WsCallback) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    let url = this.getAllTargetCompletionDatesForIRNFilterURL(token);
    this.http.get(url).subscribe(
        data => {
          var modified = JSON.parse(JSON.stringify(data));
          var res = new WsResponse(
              modified.status.description,
              modified.status.code,
              modified.status.name,
              modified.payload);
          wsCallBack.onSuccess(res, WsType.GET_IRN_TARGETCOMPLETION_DATES);
        },
        error => {
          if (error.status != '') {
            var val = (error as HttpErrorResponse).error;
            var modified = JSON.parse(JSON.stringify(val));
            var res = new WsResponse(
                modified.status.description,
                modified.status.code,
                modified.status.name,
                modified.payload);
            wsCallBack.onFail(res, WsType.GET_IRN_TARGETCOMPLETION_DATES);
          } else {
            //browser related issues
            var res = new WsResponse("Unknown error happened");
            wsCallBack.onFail(res, WsType.GET_IRN_TARGETCOMPLETION_DATES);
          }
        }
    );
  }

  public getAllTargetCompletionDatesForPCCFilter( wsCallBack: WsCallback) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    let url = ServiceUrls.getAllTargetCompletionDatesForPCC(token);
    this.http.get(url).subscribe(
        data => {
          var modified = JSON.parse(JSON.stringify(data));
          var res = new WsResponse(
              modified.status.description,
              modified.status.code,
              modified.status.name,
              modified.payload);
          wsCallBack.onSuccess(res, WsType.GET_PCC_TARGETCOMPLETION_DATES);
        },
        error => {
          if (error.status != '') {
            var val = (error as HttpErrorResponse).error;
            var modified = JSON.parse(JSON.stringify(val));
            var res = new WsResponse(
                modified.status.description,
                modified.status.code,
                modified.status.name,
                modified.payload);
            wsCallBack.onFail(res, WsType.GET_PCC_TARGETCOMPLETION_DATES);
          } else {
            //browser related issues
            var res = new WsResponse("Unknown error happened");
            wsCallBack.onFail(res, WsType.GET_PCC_TARGETCOMPLETION_DATES);
          }
        }
    );
  }

  public getAllActiveUsedInCommissioningSystemsWithParentForFilter(wsCallBack: WsCallback){
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }

    let url = ServiceUrls.getAllUsedInCommissioningSystems(token);
    this.http.get(url).subscribe(
      data => {
        var modified = JSON.parse(JSON.stringify(data));
        var res = new WsResponse(
            modified.status.description,
            modified.status.code,
            modified.status.name,
            modified.payload);
        wsCallBack.onSuccess(res, WsType.GET_ACTIVE_USED_IN_COMMISSIONING_SYSTEMS);
      },
      error => {
        if (error.status != '') {
          var val = (error as HttpErrorResponse).error;
          var modified = JSON.parse(JSON.stringify(val));
          var res = new WsResponse(
              modified.status.description,
              modified.status.code,
              modified.status.name,
              modified.payload);
          wsCallBack.onFail(res, WsType.GET_ACTIVE_USED_IN_COMMISSIONING_SYSTEMS);
        } else {
          //browser related issues
          var res = new WsResponse("Unknown error happened");
          wsCallBack.onFail(res, WsType.GET_ACTIVE_USED_IN_COMMISSIONING_SYSTEMS);
        }
      }
    );
  }
}


class AssetRequest {
  constructor(private tagNo: String, private cadNo: String, private description: String, private systemName: String, private documentName: String, private facilityName: String, private hasText: String,private usedInCommissioning: Boolean) { }
}
class SystemSearchRequest {
  constructor(private systemNo: String, private systemName: String, private facilityName: String, private isCommissioning: boolean, private hasText: String) { }
}

class Request {
  constructor(private groupId: String) { }
}
