import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserVariable } from '../../util/common/user-variable';
import { ServiceUrls } from '../../util/service-urls';
import { WsResponse } from '../../util/ws-response.model';
import { WsCallback } from '../../util/ws-callback.interface';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { CommonUtil } from 'src/app/util/common/common-util';


@Injectable({
    providedIn: 'root'
})
export class HistoryService {
    public token: string;
    constructor(private http: HttpClient, private commonUtil: CommonUtil) { }
    saveToHistory(reusableType: string, reusableText: string, reusableXML: string, reusableView: any) {
        console.log("saveToHistory");
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        let url = this.saveToTable(token);
        return this.http.post(url, JSON.stringify(new Request(reusableType, reusableText, reusableXML, reusableView))).
            pipe(
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                }),
                catchError(error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    return throwError(res)
                })
            );
    }


    getHistoryList() {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        let url = this.getHistoryToTable(token);
        console.log('getHistoryList', url);
        return this.http.get(url).
            pipe(
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                }),
                catchError(error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    return throwError(res)
                })
            );
    }
    public saveToTable(token: String) {
        let url = ServiceUrls.HISTORY_SAVE_TO + '?token=' + token;
        return url;
    }
    public getHistoryToTable(token: String) {
        let url = ServiceUrls.GET_HISTORY_TO + '?token=' + token;
        return url;
    }
}
class Request {
    constructor(private reusableType: String, private reusableText: String, private reusableXML: String, private reusableView: String) { }
}
