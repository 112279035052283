import { LoadingService } from 'src/app/util/loading/loading.service';
import { Subscription } from 'rxjs';
import { Component, OnInit, ViewChild, ElementRef, } from '@angular/core';
import { SharedService } from '../../util/shared.service';
import { ClientService } from '../client.service';
import { Router } from '@angular/router';
import { AlertService } from '../../util/alert/alert.service';
import { WsCallback } from '../../util/ws-callback.interface';
import { WsResponse } from '../../util/ws-response.model';
import { WsType } from '../../util/ws-type';
import { AsSidebarComponent } from 'src/app/systemsetting/admin-settings/as-sidebar/as-sidebar.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-addeditclient',
  templateUrl: './addeditclient.component.html',
  styleUrls: ['./addeditclient.component.css']
})
export class AddeditclientComponent implements OnInit {

	@ViewChild('clientName') clientName : ElementRef;
	@ViewChild('shortName') shortName: ElementRef;
	client: any = null;
	public base64Data: string = "";
	active: boolean = false;
	editable: boolean = false;
	private uploadingFile: File;
	public uploadFileName: string;
  	public uploadFileExt: string;
	public fileTitle: string;
	public url:any;
	public val: number;
	public isSideBar: boolean = true
	public fileChanged: boolean =  false;
	public isAddOrEditClient:string =null;
	public fileType: any;
	public msgMandatory: any;
	private subsVar: Subscription

  constructor(private sharedService: SharedService, private alertService: AlertService, private router: Router,  private clientService: ClientService,
	private asSideabar: AsSidebarComponent, private translate: TranslateService, private loadingService: LoadingService) {
		translate.addLangs(["es", "en"]);
		translate.setDefaultLang("en");
		let browserLang = translate.getBrowserLang();
		translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');

		this.translate.get('CLIENT_ADD_EDIT.FILETYPE').subscribe((res: string) => {
			this.fileType = res;
			this.translate.get('CLIENT_ADD_EDIT.MANDATORY_FIELDS').subscribe((res: string) => {
				this.msgMandatory = res;
			})
		});

  }

  ngOnInit() {
	  if(this.sharedService.getFileId() != "-1" && this.sharedService.getFileId() != -1)
	  {	
		  console.log("editble")
		  this.editable = true;
		  console.log("Item added")
		  this.val = this.sharedService.getFileId(); 
		  this.loadingService.showLoading(true,null,"Loading..",null);
		  this.clientService.getClient(this.sharedService.getFileId()).subscribe(
			data => {
				if (data.payload != null) {
					this.client = data.payload;
					console.log(this.client)
					if(this.client.status=="Active")
					{
						this.active = true
					}
					this.loadingService.hideLoading();
				}
			},
			error => {
				//this.alertService.clear()
			   //this.alertService.error(error.statusDescription)
			   this.loadingService.hideLoading();
		   });
		   

	//this.clientLogo();

	  }
	  else
	  {	this.val=0;
		  this.editable = true;
		  this.active = true;
		  //this.client = "";
	  }
  }
	/*ngAfterContentInit(){
		this.subsVar = this.sharedService.loadSideBarComponentResponse().subscribe(
			(options) => {
				this.componentName(options)		
			}
		)
	}

	public componentName(options:any) {
		console.log('[isAddOrEditClient] '+JSON.stringify(options))
		this.isAddOrEditClient = options.item;
		console.log(this.isAddOrEditClient)
	}*/

  public clientLogo(){
	this.clientService.getClientLogo().subscribe(
		data => {
			console.log(data.payload)
			if (data.payload) {
				this.base64Data = data.payload;
				// localStorage.setItem(LocalStorage.NOTIFICATION_COUNT, data.payload);
			} else {
				//do nothing
			}
		},
		error => {
			this.alertService.clear()
			this.alertService.error(error.statusDescription)
		});

}

  /**
   * Fires the method when change the file selection.
   * @param event event type
   */
  onFileChanged(event) {
	this.uploadingFile = <File>event.target.files[0];
	console.log(event.target)
	this.fileChanged = true;
    if (this.uploadingFile != undefined) {
      this.uploadFileName = this.uploadingFile.name.split('.')[0];
      this.uploadFileExt = this.uploadingFile.name.split('.')[1];
	  this.fileTitle = this.uploadingFile.name.split('.')[0];
    } else {
      this.uploadFileName = '';
	}
	if (event.target.files && event.target.files[0]) {
		if (this.uploadFileExt == 'png') {
			let reader = new FileReader();
			reader.readAsDataURL(event.target.files[0]); // read file as data url
			reader.onload = (event : ProgressEvent) => { // called once readAsDataURL is completed
				this.url = reader.result;
			}
		} else {
			this.alertService.error(this.fileType)
		}
	  }
  }


  save() {
	if (this.sharedService.getFileId() != "-1" && this.sharedService.getFileId() != -1) {
		//console.log("saving Item " + this.component.componentName + " this.component.componentValue " + this.component.componentValue, + "this.categoryId" +  this.component.categoryId)
		if(this.client.clientName!= "" && this.client.clientShortName != "") {	
			const uploadData = new FormData();
			uploadData.append("clientId", this.sharedService.getFileId());
			uploadData.append("clientName", this.client.clientName);
			uploadData.append("clientShortName", this.client.clientShortName);
			uploadData.append("status", this.client.status);
			console.log(this.uploadingFile+" "+this.uploadFileName)
			if (this.uploadingFile != undefined || this.uploadFileName != undefined) {
				uploadData.append("file", this.uploadingFile, this.uploadFileName); 
			}  
			if (this.uploadFileExt == 'png') {
				this.alertService.clear();
				this.loadingService.showLoading(true,null,"Saving..",null);
				this.clientService.updateClient(uploadData, this);
			} else if (this.client.clientName != null && this.client.clientShortName != null && this.client.status != null && this.uploadFileExt == undefined) {
				this.alertService.clear();
				this.loadingService.showLoading(true,null,"Saving..",null);
				this.clientService.updateClient(uploadData, this);
			} else {
				this.alertService.clear();
				this.alertService.error(this.fileType);
			}
		} else {
			this.alertService.clear();
			this.alertService.error(this.msgMandatory)
		}		
	}
	else {
		if(this.clientName.nativeElement.value != "" && this.shortName.nativeElement.value != "")
		{
			const uploadData = new FormData();
			uploadData.append("clientId", this.sharedService.getFileId());
			uploadData.append("clientName", this.clientName.nativeElement.value);
			uploadData.append("clientShortName", this.shortName.nativeElement.value);
			uploadData.append("status", this.active ? "Active" : "Inactive");
			if (this.uploadingFile != undefined || this.uploadFileName != undefined) {
				uploadData.append("file", this.uploadingFile, this.uploadFileName);
			}
			if (this.uploadFileExt == 'png') {
				this.alertService.clear();
				this.clientService.addClient( uploadData, this);
				this.loadingService.showLoading(true,null,"Saving..",null);
			} else if (this.uploadFileExt == undefined) {
				this.alertService.clear();
				this.clientService.addClient(uploadData, this);
				this.loadingService.showLoading(true,null,"Saving..",null);
			} else {
				this.alertService.clear();
				this.alertService.error(this.fileType);
			}	
		}else{
			this.alertService.clear();
			this.alertService.error(this.msgMandatory);
		}
	}
}

 onSuccess(data: WsResponse, serviceType: WsType) {
	 console.log("success")
 	if (serviceType == WsType.ADD_CLIENT) {
		// this.router.navigateByUrl("client")
		this.alertService.clear()
		this.alertService.success("Client added successfully")
		this.loadingService.hideLoading();
		this.sharedService.viewSideBarEventEmit("clients")
	}
	else if (serviceType == WsType.EDIT_CLIENT) {
		//this.router.navigateByUrl("client")
		this.alertService.clear()
		this.alertService.success("Client edited successfully")
		this.loadingService.hideLoading();
		this.sharedService.viewSideBarEventEmit("clients")
   }

	}

 	onFail(data: WsResponse, serviceType: WsType) {
		if (serviceType == WsType.ADD_CLIENT) {
		 this.alertService.clear();
		 this.alertService.error(data.statusDescription)
		 this.loadingService.hideLoading();;
	   }
	   if (serviceType == WsType.EDIT_CLIENT ) {
		this.alertService.clear();
		this.alertService.error(data.statusDescription)
		this.loadingService.hideLoading();
   }
 	}
	

radioClick()
{
	this.active = !this.active;
	if(this.active && this.client != null)
	{
		this.client.status="Active";
	}
	else if(this.client != null){
		this.client.status="Inactive";
	}
}


  cancel() {
	this.alertService.clear();
	this.sharedService.viewSideBarEventEmit("clients")
	//this.router.navigateByUrl("client")
  }

}
