import { Injectable } from '@angular/core';
import { UserVariable } from '../util/common/user-variable';
import { map } from 'rxjs/operators'
import { WsResponse } from '../util/ws-response.model'
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { ServiceUrls } from '../util/service-urls';
import { SharedService } from '../util/shared.service';
import { WsCallback } from '../util/ws-callback.interface';
import { WsType } from '../util/ws-type';
import { CommonUtil } from '../util/common/common-util';


@Injectable({
	providedIn: 'root'
  })
  export class ProjectService {

	private apiGridData: any = null;
	private callback: WsCallback;
	constructor(private http: HttpClient, private sharedService: SharedService, private commonUtil: CommonUtil) { }

	viewProjectList()
	{
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateLogin(token)){
            return;
        }
		const options = { params: new HttpParams().set("token", token) }
		let url = ServiceUrls.PROJECTS + "getp/viewProjectList.json";
        return this.http.get(url, options).
            pipe(
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                }),
                catchError(error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    return throwError(res)
                })
            )
	}

	getProject(id: any)
	{
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateLogin(token)){
            return;
        }
		const options = { params: new HttpParams().set("token", token) }
		let url = this.getProjectURL(id);
        return this.http.get(url, options).
            pipe(
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                }),
                catchError(error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    return throwError(res)
                })
            );

	}

	public getProjectURL(id:any)
	{
		let url = ServiceUrls.PROJECTS +  id + "/viewProject.json";
		return url
	} 

	addProject( projectname: String, clientId: String, projectType: String, additionalNotifiedParty: string, useCmmsTracking: boolean, noOfMasks: String, 
		charactersPerMaskArr: String, autoGenerateEquipNo: boolean, startingEquipmentNo:  String, allowDifferentProcInitiatedUsers:boolean, callback: WsCallback) {
			console.log("addProject");
			let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateLogin(token)){
            return;
        }
		let url =ServiceUrls.PROJECT_MODALS  + 'add/addProject.json?token=' + token;
		var data = {projectname : projectname, clientId: clientId, projectType: projectType, useCmmsTracking: useCmmsTracking, noOfMasks: noOfMasks,
			 	charactersPerMaskArr: charactersPerMaskArr,  autoGenerateEquipNo: autoGenerateEquipNo,  startingEquipmentNo: startingEquipmentNo, 
				 additionalNotifiedParty:additionalNotifiedParty,  allowDifferentProcInitiatedUsers:  allowDifferentProcInitiatedUsers }
		this.http.post(url, data).
			subscribe(
				data => {
					var modified:any =  data;
					var res = new WsResponse(
						modified.status.description,
						modified.status.name,
						modified.status.code,
						modified.payload);
					callback.onSuccess(res, WsType.ADD_PROJECT);
				},
				error => {
					var modified = JSON.parse(JSON.stringify(error));
					var res = new WsResponse(
						modified.error.status.description,
						modified.error.status.name,
						modified.error.status.code,
						modified.error.payload);
					callback.onFail(res, WsType.ADD_PROJECT);
				});
	}


	updateProject( projectId:String, projectname: String, projectType: String, additionalNotifiedParty:String,
		 currentProjectName: String, useCmmsTracking: boolean, noOfMasks: String, 
		charactersPerMaskArr: String, autoGenerateEquipNo: boolean, startingEquipmentNo:  String, allowDifferentProcInitiatedUsers:boolean, callback: WsCallback) {
			console.log("addProject")
		let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateLogin(token)){
            return;
        }
		let url = ServiceUrls.PROJECT_MODALS  + 'update/updateProject.json?token=' + token;
		var data = {projectId: projectId, projectname : projectname, projectType: projectType, currentProjectName: currentProjectName, useCmmsTracking: useCmmsTracking, noOfMasks: noOfMasks,
			charactersPerMaskArr: charactersPerMaskArr,  autoGenerateEquipNo: autoGenerateEquipNo,  startingEquipmentNo: startingEquipmentNo, additionalNotifiedParty,
			allowDifferentProcInitiatedUsers:  allowDifferentProcInitiatedUsers  }
			this.http.post(url, data).
			subscribe(
				data => {
					var modified:any =  data;
					var res = new WsResponse(
						modified.status.description,
						modified.status.name,
						modified.status.code,
						modified.payload);
					callback.onSuccess(res, WsType.EDIT_PROJECT);
				},
				error => {
					var modified = JSON.parse(JSON.stringify(error));
					var res = new WsResponse(
						modified.error.status.description,
						modified.error.status.name,
						modified.error.status.code,
						modified.error.payload);
					callback.onFail(res, WsType.EDIT_PROJECT);
				});
	}


	public deleteFileTableData(fileId: any) {
		if (this.apiGridData != null && this.apiGridData != undefined && fileId != null) {
			var selectedData = this.apiGridData.getSelectedRows();
			if (selectedData != null && selectedData != undefined) {
				this.apiGridData.applyTransaction({ remove: selectedData });
			}
		}
	}

	public disableEnableFileTableData(fileId: any, title: String, status: String) {
		console.log("hhh" + status + title + fileId)
		if (this.apiGridData != null && this.apiGridData != undefined && fileId != null) {
			var rowNode = this.apiGridData.getRowNode(fileId);
			console.log("hhh" + status + "title " + title)
			if (rowNode != null && rowNode != undefined) {
				if (title == 'Disable Confirm User') {
					console.log("bhhh")
					this.sharedService.disableCellRendererRequest(true, fileId);
					rowNode.setDataValue("status", "status:" + fileId + ":inactive");
					rowNode.setDataValue("delete", "delete:" + fileId + ":inactive");
				} else if (title == 'Enable Confirm User') {
					console.log("hhh" + status + title + fileId)
					this.sharedService.disableCellRendererRequest(false, fileId);
					rowNode.setDataValue("status", "status:" + fileId + ":Active");
					rowNode.setDataValue("delete", "delete:" + fileId + ":Active");
				}
				else {
					if (status == "Inactive") {
						this.sharedService.disableCellRendererRequest(false, fileId);
						rowNode.setDataValue("status", "status:" + fileId + ":Active");
						rowNode.setDataValue("delete", "delete:" + fileId + ":Active");
					}
					else {
						this.sharedService.disableCellRendererRequest(true, fileId);
						rowNode.setDataValue("status", "status:" + fileId + ":Inactive");
						rowNode.setDataValue("delete", "delete:" + fileId + ":Inactive");
					}
				}
			}
		}
	}

	public setApiGridData(apiGridData: any) {
        this.apiGridData = apiGridData;
    }

    public getApiGridData() {
        return this.apiGridData;
    }


  }