import { OnInit, Component, ViewChild, TemplateRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { IssueChecksheetService } from '../issuechecksheets.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/util/alert/alert.service';
import { LoadingService } from 'src/app/util/loading/loading.service';

@Component({
	selector: 'app-checksheet-delete-cell-renderer',
	templateUrl: './delete-cell-renderer.component.html'
})
export class ChecksheetDeleteCellRendererComponent{
  status: string;
  isShow: boolean = false;
  public deleteModalRef: BsModalRef;
  deleteMsg: string;
  @ViewChild("deleteCSInstanceModal") deleteCSInstanceModal: TemplateRef<any>;
  checkSheetAssetSystemId: string;
  public isDeleteVisible: boolean;

  constructor(private modalService: BsModalService, private translate: TranslateService, private loadingService: LoadingService,
    private issueChecksheetService: IssueChecksheetService, private alertService: AlertService){
    translate.addLangs(["es", "en"]);
    translate.setDefaultLang("en");
    let browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
  }
  
    agInit(params: any) {
      this.checkSheetAssetSystemId = params.data.assetSystemId;
      let items = params.value.split(':')
      if (items.length >= 2) {
          this.status = items[1];
          if(this.status == "Issued" || this.status == "Error"){
            this.isDeleteVisible = true;
          } else{
            //do nothing
          }
      }
    }

    public deleteConfirm(){
        if(this.status == "Issued" || this.status == "Error"){
            this.deleteMsg = "Deleting this will result in data being lost for this checksheet that has been generated. Do you want to delete this checksheet?"
        } else{
           // do nothing
        }
        this.deleteModalRef = this.modalService.show(this.deleteCSInstanceModal)
    }

    public deleteCSInstance(){
      this.loadingService.showLoading(true, null, "", null);
      this.issueChecksheetService.removeCheckSheetInstance(this.checkSheetAssetSystemId);
      this.deleteModalRef.hide();
    }

    public cancelDeleteCSInstance(){
      this.deleteMsg = null;
      if(this.deleteModalRef)
        this.deleteModalRef.hide();
    }
}