import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/util/shared.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-dependency-modal',
  templateUrl: './dependency-modal.component.html',
  styleUrls: ['./dependency-modal.component.css']
})
export class DependencyModalComponent implements OnInit {
  public tagDependency: any;
  public tagAttributeDependency: any[];
  public title;

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
    console.log(this.tagDependency);
    console.log(this.tagAttributeDependency);
  }

}
