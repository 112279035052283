import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '../../../../util/alert/alert.service';
import { SharedService } from '../../../../util/shared.service';
import { GridOptions, Module, AllModules } from "@ag-grid-enterprise/all-modules";
import { CapabilityService } from '../../../../util/capability.service'
import { BsModalService } from 'ngx-bootstrap/modal';
import { OutputManagementService } from '../../outputmanagement.service'
import { LoadingService } from '../../../../util/loading/loading.service';
import { AddTemplateRender } from '../../cell-render/addprojecttemplaterender/addtemplate/selecttemprender.component'

@Component({
   selector: 'app-addtemplate',
   templateUrl: './addtemplate.component.html',
   styleUrls: ['./addtemplate.component.css']
})

export class OMAddTemplateComponent implements OnInit {
   public isMinimized: boolean = false
   rowData: any[] = [];
   private gridApi;
   private gridColumnApi;
   columnDefs;
   public frameworkComponents;
   public gridOptions: GridOptions;
   public defaultColDef: any;
   om_translated_data: any = null;
   loadingMessage: string;
   public modules: Module[] = AllModules;
   
   // capabilities
   public editTemplateName: boolean = false

   // enable/disable buttons
   public enableEditTempalteName: boolean = false

   constructor(private outputManagementService: OutputManagementService, private sharedService: SharedService, private translate: TranslateService, private alertService: AlertService,
      private capabilityService: CapabilityService, private modalService: BsModalService, private router: Router, private loadingService: LoadingService) {
      this.enableDisableButton()
      let browserLang = translate.getBrowserLang();
      this.gridOptions = <GridOptions>{
         context: {
            componentParent: this
         }
      };
      this.defaultColDef = {
			resizable: true,
			sortable: true,
		};
      this.translate.get('OUTPUT_MANAGEMENT.LOADING_MESSAGE').subscribe((res: string) => {
         this.loadingMessage = res;
      });
      translate.getTranslation(browserLang).subscribe((res: string) => {
         this.om_translated_data = res['OUTPUT_MANAGEMENT']
         this.columnDefs = [
            {
               headerName: this.om_translated_data.SELECT, field: '', autoHeight: true, cellClass: "cell-wrap-text", width: 85,
               cellRendererFramework: AddTemplateRender, hide:!this.enableEditTempalteName
            },
            {
               headerName: this.om_translated_data.FILENAME, field: 'displayName', autoHeight: true, cellClass: "cell-wrap-text"
               
            },
            {
               headerName: this.om_translated_data.OUTPUT_TYPE, field: 'outputType', autoHeight: true, cellClass: "cell-wrap-text"
               
            },
            {
               headerName: this.om_translated_data.OUTPUT_DOCUMENT, field: 'documentType', autoHeight: true, cellClass: "cell-wrap-text"
               
            },
         ]
      });
   }
   
   ngOnInit() {
   }

   onColumnResized(event) {
      if (event.finished) {
         this.gridApi.resetRowHeights();
      }
   }

   onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      params.api.sizeColumnsToFit();
      this.getTemplates();
      setTimeout(function () {
         params.api.sizeColumnsToFit();
         params.api.resetRowHeights();
      }, 500);
   }

   public getTemplates() {
      this.loadingService.showLoading(true, null, this.loadingMessage, null)
      this.outputManagementService.getTemplates()
         .subscribe(data => {
            this.loadingService.hideLoading()
            this.rowData = data.payload;
            this.gridApi.sizeColumnsToFit();
         },
            error => {
               this.loadingService.hideLoading()
               this.alertService.clear()
               this.alertService.error(error.statusDescription)
            });

   }
   private enableDisableButton() {
      this.checkCapability()
      this.enableEditTempalteName = this.editTemplateName

   }
   public checkCapability() {
      let editTempalteCapability: string = "manageProjectTemplates"
      this.editTemplateName = this.capabilityService.isCapabilityAssigned(editTempalteCapability)
   }

}