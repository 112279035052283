import { Component, OnInit, AfterContentInit, OnChanges, OnDestroy, SimpleChanges, ViewChild, ElementRef, ViewContainerRef } from '@angular/core';
import { ComponentRef, ComponentFactoryResolver } from '@angular/core';
import { RuLoaderService } from './ru-loader-service.service';

@Component({
	selector: 'app-ru-loader',
	templateUrl: './ru-loader.component.html',
	styleUrls: ['./ru-loader.component.scss']
})
export class RuLoaderComponent implements OnInit, AfterContentInit, OnChanges, OnDestroy {

	public paddingtop: number = 60;
	@ViewChild('navPanel', {static: true}) navPanel: ElementRef
	
	component: any;
    @ViewChild('ruHost', { read: ViewContainerRef, static: true }) componentHost: ViewContainerRef
	componentRef: ComponentRef<Component>

	public isSideBar: boolean = true
	
	constructor(private resolver: ComponentFactoryResolver, private rulService: RuLoaderService) { 
		this.component = this.rulService.loadComponentByName('reusableupdate')
	}

	public changeSideBarItem(event: any) {
        console.log('[changeSideBarItem] (event) ' + JSON.stringify(event));
        this.component = this.rulService.loadComponentByName(event.selectedItem)
        this.updateComponent()
    }

	ngOnInit() {
		console.log(this.navPanel.nativeElement.offsetHeight);
		if (this.navPanel.nativeElement.offsetHeight > 55) {
			this.paddingtop = 78
		} else {
			this.paddingtop = 50
		}
	}

	ngAfterContentInit(): void {
		//Called after ngOnInit when the component's or directive's content has been initialized.
		//Add 'implements AfterContentInit' to the class.
		this.updateComponent()
	}

	ngOnChanges(changes: SimpleChanges): void {
		//Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
		//Add '${implements OnChanges}' to the class.
		
	}

	ngOnDestroy(): void {
		//Called once, before the instance is destroyed.
		//Add 'implements OnDestroy' to the class.
		if (this.componentRef) {
			this.componentRef.destroy()
		}
	}

	updateComponent() {
		this.componentHost.clear()
		const authFormFactory = this.resolver.resolveComponentFactory(this.component)
		const componentRef = this.componentHost.createComponent(authFormFactory);
	}

}
