<!-- <pre class="card card-block card-header">Delete Confirm</pre>
<div>
	<div class="modal-body text-center">
		<p>Are you sure you want to delete this TagClassificationAttributeMap?</p>
		<button type="button" class="btn btn-primary" (click)="confirm()">Yes</button>
		<button type="button" class="btn btn-primary" (click)="decline()">No</button>
	</div>
</div> -->
<div class="modal-header  px-3 py-2">
	<h5 class="modal-title ">{{ 'TAG_CLASSIFICATION.DELETE_CONFIRM' | translate }}</h5>
	<button type="button" class="close " aria-label="Close" (click)="bsModalRef.hide()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body">
	<p>{{ 'TAG_CLASSIFICATION.DELETE_CONFIRM_MESSAGE' | translate }}</p>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-primary btn-sm" (click)="confirm()">Yes</button>
	<button type="button" class="btn btn-secondary btn-sm" (click)="decline()">No</button>
</div>