import { Component, OnInit, QueryList, ElementRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FloatingButtonCallback } from '../floating-button-callback.interface';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DocumenttypetreeComponent } from '../../../util/documenttypetree/documenttypetree.component';
import { DocumentTypeService } from '../../../util/documenttypetree/documenttype.service';
import { WsCallback } from '../../../util/ws-callback.interface';
import { WsResponse } from '../../../util/ws-response.model';
import { WsType } from '../../../util/ws-type';
import { AlertService } from '../../../util/alert/alert.service';
import { HttpClient } from '@angular/common/http';
import { FileUploadService } from './file-upload.service';
import { LoadingService } from '../../../util/loading/loading.service';
import { TranslateService } from '@ngx-translate/core';
import { UserVariable } from 'src/app/util/common/user-variable';
import { CommonUtil } from '../../../util/common/common-util';

@Component({
	selector: 'app-new-image',
	templateUrl: './new-image.component.html',
	styleUrls: ['./new-image.component.css']
})
export class NewImageComponent implements OnInit, WsCallback {
	public title: string;
	public selectedFolder: any;
	public floatingButtonCallback: FloatingButtonCallback;
	public attributeList: any;
	public haveProperties: boolean = true;
	public documentType: string;
	public uploadFileName: string;
	public uploadFileExt: string;
	public fileComment: string;
	public fileTitle: string;

	private treeBsModalRef: BsModalRef;
	private documentTypeId: string;
	private uploadingFile: File;
	selectedType: string;

	constructor(public bsModalRef: BsModalRef, private modalService: BsModalService, private documentTypeService: DocumentTypeService,
		private alert: AlertService, private http: HttpClient, private fileUploadService: FileUploadService, private loadingService: LoadingService,
        private translate: TranslateService, private commonUtil: CommonUtil) { }

	ngOnInit() {
	}

	/**
	 * Web service call to load document types.
	 */
	public loadDocumentTypes() {
		const initialState = {
			title: 'Select a Document Type',
			treeListener: this,
			initial: true
		};
		this.treeBsModalRef = this.modalService.show(DocumenttypetreeComponent, { initialState, class: 'modal-lg' });
		this.treeBsModalRef.content.closeBtnName = 'Close';
	}

	/**
	 * Clicks on the save button of the tree view popup
	 * @param file selected file
	 * @param texts text element
	 * @param options options element
	 * @param documentTypeAttributes selected attributes
	 */
	onSaveButtonClick(file: any, texts: QueryList<ElementRef>, options: QueryList<ElementRef>, documentTypeAttributes: any) {
		this.documentTypeService.getDocumentTypeAttributes(file.node.id, this.selectedFolder, this);
		this.documentType = file.node.text;
		this.selectedType = file.node.text;
		this.documentTypeId = file.node.id;
		this.treeBsModalRef.hide();
	}

	/**
	 * Fires the method when change the file selection.
	 * @param event event type
	 */
	onFileChanged(event) {
		this.uploadingFile = <File>event.target.files[0];
		if (this.uploadingFile != undefined) {
			// this.uploadFileName = this.uploadingFile.name.split('.')[0];
			// this.uploadFileExt = this.uploadingFile.name.split('.')[1];
			// this.fileTitle = this.uploadingFile.name.split('.')[0];
			let parts = this.uploadingFile.name.split('.')
     		if(parts.length>1){
				this.uploadFileExt = parts.pop()
				this.uploadFileName = parts.join('.')
				this.fileTitle = parts.join('.')
			}else{
				this.translate.get('NEW_IMAGE.INVALID_FILE_NAME').subscribe((res: string) => {
					this.alert.error(res, false);
				});
			}
		} else {
			this.uploadFileName = '';
		}
	}

	/**
	 * Validate the form for compulsory fields.
	 */
	validateForm(): boolean {
		if (this.uploadingFile == undefined) {
			return false;
		}
		if(this.fileTitle != undefined) {
			this.fileTitle = this.fileTitle.trim()
		}
		if(this.fileTitle){
		}else{
			return false;
		}
		if(this.documentTypeId){
		}else{
			this.documentType = "";
			return false;
		}
		if (this.uploadingFile.size > 104857601 ){
			this.translate.get('NEW_IMAGE.SIZE_LIMIT_EXCEEDED').subscribe((res: string) => {
			  this.alert.error(res, false);
			});
			return false;
		}

		//Achini Randeni
		//validate the file type before submitting
		if(this.uploadingFile.type != 'image/png' && this.uploadingFile.type != 'image/jpg' && this.uploadingFile.type != 'image/jpeg' && this.uploadingFile.type != 'image/gif'){
			this.translate.get('NEW_IMAGE.INVALID_FILE_TYPE').subscribe((res: string) => {
			  this.alert.error(res, false);
			});
			return false;
		  }

		if(this.selectedType != this.documentType){
			this.documentType = "";
			return false;
		}
		
	// 	//Check for attribute values
	// 	if (this.attributeList.length > 0) {
	// 		var isEmptyAttribute : boolean = false;
	// 		this.attributeList.forEach(item => {
	// 		   if (item.attributeId != '' && (item.value == '' || item.value == null)) {
	// 			this.translate.get('NEW_IMAGE.ATTRIBUTE.EMPTY_ATTRIBUTE_ERROR_MESSAGE').subscribe((res: string) => {
	// 			  this.alert.error(res, false);
	// 			});
				
	// 			isEmptyAttribute = true;
	// 		   }
	// 		});
	// 		if (isEmptyAttribute){
	// 		  return false;
	// 		}
	// 	  }

	// 	return true;
	// }

	//Check for attribute values
	if (this.attributeList != undefined && this.attributeList.length != undefined && this.attributeList.length > 0) {
		var isEmptyAttribute : boolean = false;
		this.attributeList.forEach(item => {
			if(item.required == 'yes' && (item.value == null || item.value == "")){
				this.alert.error(this.translate.instant('NEW_IMAGE.ATTRIBUTE.EMPTY_ATTRIBUTE_ERROR_MESSAGE'));
			  isEmptyAttribute = true;
			}
			// if(item.attributeName == 'Document'){
			// 	if(item.documents != undefined){
			// 		isEmptyAttribute = false;
			// 	}
			// } else if(item.attributeName == 'c1'){
			// 	if(item.components != undefined){
			// 	isEmptyAttribute = false;
			// 	}
			// } else if(item.attributeName == 'System'){
			// 	if(item.systems != undefined){
			// 	isEmptyAttribute = false;
			// 	}
			// }
		});
		if (isEmptyAttribute){
		  return false;
		}
	  }

	return true;
}

	/**
	 * Call web services to upload file and data.
	 */
	onUpload() {
		if (!this.validateForm()) {
			return;
		}

		var loadingMessage;
		this.translate.get('NEW_IMAGE.LOADING_MESSAGE').subscribe((res: string) => {
			loadingMessage = res;
		});
		this.loadingService.showLoading(true, null, loadingMessage, null);
		var attributeValueXml: string = '';
		//creating attribute xml string.
		attributeValueXml = '<attrs>';
		if (this.attributeList != undefined && this.attributeList.length != undefined && this.attributeList.length > 0) {
			this.attributeList.forEach(item => {
				attributeValueXml += "<attr type=\"text\" name='" + item.attributeName + "' typeattributeid='" + item.attributeId + "'>" + item.value + "</attr>";
			});
		}
		attributeValueXml += '</attrs>';
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
		const uploadData = new FormData();
		uploadData.append("fileName", this.uploadFileName);
		uploadData.append("fileNameExt", this.uploadFileExt);
		uploadData.append("title", this.fileTitle);
		uploadData.append("typeId", this.documentTypeId);
		uploadData.append("comment", this.fileComment);
		uploadData.append("parentFolderId", this.selectedFolder.id);
		uploadData.append("attributes", attributeValueXml);
        uploadData.append("token", token);
		uploadData.append("file", this.uploadingFile, this.uploadFileName);

		this.fileUploadService.uploadFile(uploadData, this);
	}

	/**
	 * Clicks on the submit button
	 */
	public onSubmitButtonClick() {
		this.onUpload();
	}

	/**
	 * Callback method for the successful web service calls.
	 * @param data web service response data
	 * @param serviceType service type id
	 */
	onSuccess(data: WsResponse, serviceType: WsType) {
		if (serviceType == WsType.DOCUMENT_TYPE_ATTRIBUTES_VIEW) {
			this.attributeList = data.payload;
			if (data.payload.length == 0) {
				this.haveProperties = false;
			} else {
				this.haveProperties = true;
			}
		} else if (serviceType == WsType.ADD_NEW_IMAGE) {
			this.alert.success(data.statusDescription);
			this.floatingButtonCallback.hide();
			this.loadingService.hideLoading();
		}
	}

	/**
	 * Callback method for the unsuccessful web service calls.
	 * @param data web service response data
	 * @param serviceType service type id
	 */
	onFail(data: WsResponse, serviceType: WsType) {
		if (serviceType == WsType.DOCUMENT_TYPE_ATTRIBUTES_VIEW) {
			this.alert.error(data.statusDescription, false);
		} else if (serviceType == WsType.ADD_NEW_IMAGE) {
			this.alert.error(data.statusDescription, false);
			this.loadingService.hideLoading();
		}
	}

	checkComponent(type:any, name:any){
		if(type == 'Component' || name == 'c1' ){
		   return true;
	   }
   }

   checkSystem(type:any, name:any){
		 if(type == 'System' || name == 'System' ){
		   return true;
	   }
   }
   checkDocument(type:any, name:any){
		 if(type == 'Document' || name == 'Document'){
		   return true;
	   }
   }

}
