import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { UserVariable } from '../util/common/user-variable';
import { ServiceUrls } from '../util/service-urls';
import { WsCallback } from '../util/ws-callback.interface';
import { WsType } from '../util/ws-type';
import { WsResponse } from '../util/ws-response.model';
import { LocalStorage } from '../util/localstorage.service';
import { CommonUtil } from '../util/common/common-util';

@Injectable()
export class LandingService {
	public token: string;

	constructor(private http: HttpClient, private commonUtil: CommonUtil) {
	}

	public setSelectedProjectName(selectedProjectName: string) {
		localStorage.setItem(LocalStorage.PROJECT_NAME, selectedProjectName);
	}

	public getSelectedProjectName() {
		return localStorage.getItem(LocalStorage.PROJECT_NAME);
	}

	getAllProjects(userId : any, callBack: WsCallback) {
		console.log("landingService.getAllProjects() == > ServiceURL= " + ServiceUrls.USER_PROJECT_VIEW  + userId + "/projects.json")
		let token = UserVariable.getUserToken();
		if (!this.commonUtil.validateLogin(token)) {
			return;
		}
		const options = { params: new HttpParams().set("token", token) }
		let url = this.getProjectListByUserURL(userId);
		this.http.get(url, options).
			subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				callBack.onSuccess(res, WsType.LANDING);
			},
			error => {
				if (error.status == "0") {
					var res = new WsResponse("", error.status, "", "");
					callBack.onFail(res, WsType.LANDING);
                    return;
                }
				var modified = JSON.parse(JSON.stringify(error));
				var res = new WsResponse(
					modified.status.description != undefined ? modified.status.description: "Error",
					modified.status.code,
					modified.status.name,
					modified.payload);
				callBack.onFail(res, WsType.LANDING);
			}
			);
	}

	public getProjectListByUserURL(userId: string) {
		let url = ServiceUrls.USER_PROJECT_VIEW + userId + '/getDetailActiveProjectsByUser.json'
		return url;
	}
}

