export class Constants {
    public static MAX_DEPENDENCY_DISPLAY_COUNT = 10;
    /**
     * UIL_1 - initial
     * UIL_2 - folder explorer + tab + reusable content
     * UIL_3 - full screen tab + reusabel button only, no folder explorer 
     * UIL_4 - full screen tab + reusable content, no folder explorer 
     * UIL_5 - folder explorer + reusable content, no tab
     * UIL_6 - full screen reusable content, no tab, no folder explorer
     */
    public static UI_LAYOUT_INITIAL = {
        UIL_1: { fxW: 24, tW: 72, ruW: 4 }, //initial 
        UIL_2: { fxW: 24, tW: 48, ruW: 28 }, //folder explorer + tab + reusable content
        UIL_3: { fxW: 0, tW: 96, ruW: 4 }, //full screen tab + reusabel button only, no folder explorer 
        UIL_4: { fxW: 0, tW: 76, ruW: 24 }, //full screen tab + reusable content, no folder explorer 
        UIL_5: { fxW: 24, tW: 0, ruW: 76 }, //folder explorer + reusable content, no tab
        UIL_6: { fxW: 0, tW: 0, ruW: 100 } //full screen reusable content, no tab, no folder explorer
    }

    public static UI_LAYOUT_MOBILE = {
        UIL_1: { fxW: 24, tW: 67, ruW: 9 }, //initial 
        UIL_2: { fxW: 24, tW: 44, ruW: 32 }, //folder explorer + tab + reusable content
        UIL_3: { fxW: 0, tW: 91, ruW: 9 }, //full screen tab + reusabel button only, no folder explorer 
        UIL_4: { fxW: 0, tW: 68, ruW: 32 }, //full screen tab + reusable content, no folder explorer 
        UIL_5: { fxW: 24, tW: 0, ruW: 76 }, //folder explorer + reusable content, no tab
        UIL_6: { fxW: 0, tW: 0, ruW: 100 } //full screen reusable content, no tab, no folder explorer
    }

    public static UI_LAYOUT = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? Constants.UI_LAYOUT_MOBILE : Constants.UI_LAYOUT_INITIAL

    public static COLOR_PALETTE_COLORS = "1ABC9C,2ECC71,3498DB,9B59B6,4E5F70,F1C40F,16A085,27AE60,2980B9,8E44AD,2C3E50,F39C12,E67E22,E74C3C,E5B8B7,FF5050,DDD,FFFFCC,D35400,C0392B,FF6483,FF0000,999,F7F734,ECF0F1,BDC3C7,95A5A6,7F8C8D,FFF,000";

    public static DOCUMENTTYPE_PANDID_GUID = "fcd6642b-57a7-45f1-8c78-74142ba9b93b";
    //public static GOOGLE_AUTH_CLIENT_ID = "692921478250-hodf54f3amjr8160u43l8l7kr9qtnlas.apps.googleusercontent.com";
    //public static GOOGLE_AUTH_SECRET_KEY = "AsTzI2gPsmAksfhHFqeGrwJ6";
    //public static GOOGLE_AUTH_SCOPE = 'https://www.googleapis.com/auth/plus.login https://www.googleapis.com/auth/plus.profile.emails.read';
    public static APP_TYPE = 'Web';

    public static SEARCH_KEY_WORDS = {
        SEARCH_HAS_THE_WORDS: 'htw',
        SEARCH_WITH_CHILDREN: 'includechildren',
        //tag search key word
        SEARCH_TAG_TAG_NUM: 'tag',
        SEARCH_TAG_TAG_DESCRIPTION: 'tagdescription',
        SEARCH_TAG_TAG_SYSTEM: 'tagsystem',
        SEARCH_TAG_TAG_DOCUMENT: 'tagdocument',
        SEARCH_TAG_TAG_FACILITY: 'tagfacility',
        SEARCH_TAG_TAG_CAD_NUMBER: 'tagcadno',
        //component search key word
        SEARCH_COMPONENT_COMP_NAME: 'componentname',
        SEARCH_COMPONENT_COMP_VALUE: 'componentvalue',
        SEARCH_COMPONENT_COMP_CATEGORY: 'componentcategory',
        //document search key word
        SEARCH_DOCUMENT_DOC_NAME: 'documentname',
        SEARCH_DOCUMENT_DOC_FACILITY: 'documentfacility',
        SEARCH_DOCUMENT_DOC_REVISION: 'revision',
        SEARCH_DOCUMENT_DOC_ALT_DOC_NO: 'altdocno',
        SEARCH_DOCUMENT_DOC_DOCTYPE: 'documenttypename',
        SEARCH_DOCUMENT_DOC_FILENAME: 'attachedFileName',
        //system search key word
        SEARCH_SYSTEM_SYS_NUMBER: 'systemno',
        SEARCH_SYSTEM_SYS_NAME: 'systemname',
        SEARCH_SYSTEM_SYS_FACILITY: 'systemfacility',
        SEARCH_SYSTEM_SYS_COMMISSIONING: 'systemcomm',
        //facility search key word
        SEARCH_FACILITY_FAC_NAME: 'facilityname',
        SEARCH_FACILITY_EMAIL: 'emailaddress',
        SEARCH_FACILITY_SEC_EMAIL: 'secondaryemail',
        //image search key word
        SEARCH_IMAGE_IMG_DOCTYPE: 'imgDocType',
        SEARCH_IMAGE_IMG_DESCRIPTION: 'imgDescription',
        SEARCH_IMAGE_IMG_SYSTEM: 'imgSystem',
        SEARCH_IMAGE_IMG_DOCUMENT: 'imgDocument',
        //statement search key word
        SEARCH_STATEMENT_STM_TITLE: 'statementtitle',
        SEARCH_STATEMENT_STM: 'statement',
        SEARCH_STATEMENT_STM_CATEGORY: 'statementcategory',
        //header search key word
        SEARCH_HEADER_FILE_NAME: 'filename',
        SEARCH_HEADER_FILE_CHECKOUT_BY: 'checkoutby',
        SEARCH_HEADER_FILE_LAST_UPDATE_DATE: 'lastupdatetimestamp',
        SEARCH_HEADER_FILE_ITEM_TYPE: 'filetype',
        //punchlist search key words
        SEARCH_PUNCHLIST_CATEGORY: 'category',
        SEARCH_PUNCHLIST_ASSET: 'punchlistAsset',
        SEARCH_PUNCHLIST_DISCIPLINE: 'punchlistDiscipline',
        SEARCH_PUNCHLIST_DESCRIPTION: 'punchlistDescription',
        SEARCH_PUNCHLIST_DATE_RAISED: 'dateRaised',
        SEARCH_PUNCHLIST_DATE_CLOSED: 'dateClosed',
        SEARCH_PUNCHLIST_COMPLETION_DATE: 'estimatedCompletionDate',
        SEARCH_PUNCHLIST_RAISED_BY: 'raisedBy',
        SEARCH_PUNCHLIST_MATERIAL_REQUIRED: 'materialsRequired',
        SEARCH_PUNCHLIST_SUB_SYSTEMS: 'punchlistSystem',
        SEARCH_PUNCHLIST_EST_MAN_HOURS: 'estimatedManHours',
        SEARCH_PUNCHLIST_ITEM_STATUS: 'itemStatus',
        //threed model search key word
        SEARCH_THREED_MODEL_DOCTYPE: 'threedModelDocType',
        SEARCH_THREED_MODEL_DESCRIPTION: 'threeDModelDescription',
        //video search key word
        SEARCH_VIDEO_DOCTYPE: 'videoDocType',
        SEARCH_VIDEO_DESCRIPTION: 'videoDescription',
        //IRN search key word
        SEARCH_IRN_IRN_NUMBER: 'irnnumber',
        SEARCH_IRN_IRN_DESCRIPTION: 'irndescription',
        SEARCH_IRN_ASSET: 'assets',
        SEARCH_IRN_VENDOR: 'vendors',
        SEARCH_IRN_SYSTEM: 'systems',
        SEARCH_IRN_STATUS: 'irnStatus',
        SEARCH_IRN_COMPLETION_DATE: 'irnCompletionDates',

        // mcc search options
        SEARCH_MCC_SYSTEM: 'mccSystems',
        SEARCH_MCC_STATUS: 'mccStatus',
        SEARCH_MCC_COMPLETION_DATE: 'mccCompletionDates',

        // otn search options
        SEARCH_OTN_ASSET: 'otnAssets',
        SEARCH_OTN_VENDOR: 'otnVendors',
        SEARCH_OTN_STATUS: 'otnStatus',
        SEARCH_OTN_COMPLETION_DATE: 'otnCompletionDates',

        //checksheets search options
        SEARCH_CS_NAME: 'checksheetName',
        SEARCH_CS_CODE: 'checksheetCode',
        SEARCH_CS_DISCIPLINE: 'checksheetDiscipline',
        SEARCH_CS_TYPE: 'checksheetType',

        //checks search options
        SEARCH_CHK_CATEGORY: 'checkCategory',
        SEARCH_CHK_DESC: 'checkDescription',
        SEARCH_CHK_TYPE: 'checkType',

        //assets search options
        SEARCH_ASSET_NO: 'assetNo',
        SEARCH_ASSET_DESC: 'assetDescription',
        SEARCH_ASSET_SYS: 'system',
        SEARCH_ASSET_GROUP: 'assetGroup',
        SEARCH_ASSET_PARENT: 'assetParent',
        SEARCH_ASSET_FUNCCODE: 'functionCode',
        SEARCH_ASSET_ATTRIBUTE: 'attributeValue', 

        //checksheet systems search options
        SEARCH_SYS_NO: 'sysNo',
        SEARCH_SYS_NAME: 'sysName',
        SEARCH_SYS_PARENT: 'sysParent',

        //search options for checksheet issuing
        ISSUE_CS_VENDOR: 'csVendor',
        ISSUE_CS_CHECKSHEET: 'csChecksheet',
        ISSUE_CS_ASSET: 'csAsset',
        ISSUE_CS_ASSET_DESCRIPTION: 'csAssetDescription',
        ISSUE_CS_SYSTEM: 'csSystem',
        ISSUE_CS_ASSET_GROUP: 'csAssetGroup',
        ISSUE_CS_PHASE: 'csPhase',
        ISSUE_CS_ASSET_PARENT: 'csAssetParent',
        ISSUE_CS_FUNCTION_CODE: 'csFunctionCode',

         //search options for checksheet receiving
         RECEIVE_CS_VENDOR: 'csrVendor',
         RECEIVE_CS_CHECKSHEET: 'csrChecksheet',
         RECEIVE_CS_ASSET: 'csrAsset',
         RECEIVE_CS_ASSET_DESCRIPTION: 'csrAssetDescription',
         RECEIVE_CS_SYSTEM: 'csrSystem',
         RECEIVE_CS_ASSET_GROUP: 'csrAssetGroup',
         RECEIVE_CS_PHASE: 'csrPhase',
         RECEIVE_CS_FUNCTION_CODE: 'csrFunctionCode',

        //PCC search options
        SEARCH_PCC_SYSTEM: 'pccSystems',
        SEARCH_PCC_STATUS: 'pccStatus',
        SEARCH_PCC_COMPLETION_DATE: 'pccCompletionDates',
    }

    public static LOADING_STATES = {
        INITIAL: 'initial',
        LOADING: 'loading',
        HAS_RESULT: 'has_result',
        EMPTY_RESULT: 'empty_result',
        ERROR: 'error'
    }

    public static DEVICE_TYPES = {
        DESKTOP: 'desktop',
        MOBILE: 'mobile',
        TABLET: 'tablet'
    }

    public static DEPENDENCY_MESSAGE_TYPE = {
        EDIT: 'Edit',
        ENABLE: 'Enable',
        DELETE: 'Delete',
        DISABLE: 'Disable',
        VIEW: 'View'
    }

    public static DEPENDENCY_ITEM_TYPE = {
        CLIENT: 'Client',
        PROJECT: 'Project',
        USER: 'User',
        WORKFLOW: 'Workflow',

        COMPONENT: 'Component',
        DOCUMENT: 'Document',
        FACILITY: 'Facility',
        SYSTEM: 'System',
        STATEMENT: 'Statement',
        TAG: 'Tag',

        CONTENTITEM: 'ContentItem',
        COURSE: 'Course',
        FOLDER: 'Folder',
        IMAGE: 'Image',
        PROCEDURE: 'Procedure',
        TOPIC: 'Topic',
        TOPICMAP: 'TopicMap',
        DOCUMENTTYPE: 'DocumentType',
        DOCUMENTTYPEATTRIBUTE: 'DocumentTypeAttribute',
        THREEDMODEL: 'ThreeDModel',
        PUNCHLIST: 'PunchList',
        IRN: 'IRN',
        CHECKSHEET: 'CheckSheet',
        CHECK: 'Check'
    }

}

/**
 * UIL_1 - initial
 * UIL_2 - folder explorer + tab + reusable content
 * UIL_3 - full screen tab + reusabel button only, no folder explorer 
 * UIL_4 - full screen tab + reusable content, no folder explorer 
 * UIL_5 - folder explorer + reusable content, no tab
 * UIL_6 - full screen reusable content, no tab, no folder explorer
 */
export enum UI_LAYOUT {
    UIL_1 = 'UIL_1',
    UIL_2 = 'UIL_2',
    UIL_3 = 'UIL_3',
    UIL_4 = 'UIL_4',
    UIL_5 = 'UIL_5',
    UIL_6 = 'UIL_6'
}

export enum FACILITY_UI_VIEW {
    SMALL_VIEW = 'small_view',
    EXPANDED_VIEW = 'expanded_view',
    ADD_FORM_VIEW = 'add_form_view',
    RESULT_VIEW = 'result_view'
}

export enum SYSTEM_UI_VIEW {
    SMALL_VIEW = 'small_view',
    EXPANDED_VIEW = 'expanded_view',
    ADD_FORM_VIEW = 'add_form_view',
    RESULT_VIEW = 'result_view'
}

export enum DOCUMENT_UI_VIEW{
    SMALL_VIEW = 'small_view',
    EXPANDED_VIEW = 'expanded_view',
    ADD_FORM_VIEW = 'add_form_view',
    RESULT_VIEW = 'result_view'
}

export enum STATEMENT_UI_VIEW {
    SMALL_VIEW = 'small_view',
    EXPANDED_VIEW = 'expanded_view',
    ADD_FORM_VIEW = 'add_form_view',
    RESULT_VIEW = 'result_view'
}

export enum COMPONENT_UI_VIEW {
    SMALL_VIEW = 'small_view',
    EXPANDED_VIEW = 'expanded_view',
    ADD_FORM_VIEW = 'add_form_view',
    RESULT_VIEW = 'result_view'
}

export enum IMAGE_UI_VIEW {
    SMALL_VIEW = 'small_view',
    EXPANDED_VIEW = 'expanded_view',
    ADD_FORM_VIEW = 'add_form_view',
    RESULT_VIEW = 'result_view'
}
export enum THREED_MODEL_UI_VIEW {
    SMALL_VIEW = 'small_view',
    EXPANDED_VIEW = 'expanded_view',
    ADD_FORM_VIEW = 'add_form_view',
    RESULT_VIEW = 'result_view'
}

/**
 * local storage constants
 */
export class LocalStorageConst {
    public static UI_LAYOUT: string = 'uiLayout'
}
/**
 * session storage constants
 */
export class SessionStorageConst {
    public static UI_LAYOUT: string = 'uiLayout';
}
/**
 * CK editor constants
 */
export const CK_Editor_Type = Object.freeze({
    TOPIC_EDITOR : 'topics_ck_editor',
    LEARNING_CHECK_EDITOR: "learning_checks_ck_editor",
    TOPIC_CONTENT_EDITOR : 'topic_content_ck_editor',
    GAME_EDITOR: "games_ck_editor",
    ICE_QUESTION_EDITOR: "ice_question_ck_editor",
    ICE_ANSWER_EDITOR: "ice_answer_ck_editor",
    DDQ_CELL_EDITOR: "ice_ddq_cell_ck_editor",
    GLOSSARY_EDITOR: "glossary_ck_editor",
    GLOSSARY_DESC_EDITOR: "glossary_desc_ck_editor",
});

export const LearningCheckData_Details = Object.freeze({
    LCDATA_AVAILABLE : 'Have learning check data',
    MAXIMUM_ANSWER_COUNT: 5
    //... more variables
});

export const IceCourse_Details = Object.freeze({
    ACTIVE : 'Active'
    //... more variables
});
