<div class="modal-header">
	<h4 class="modal-title pull-left">{{title}}</h4>
	<button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<!-- <div class="modal-bod">
	<div class="container">
		<div class="row justify-content">
			<div class="col-sm-6">
				<div id="categorytree" class="mt-2 ml-1">
				</div>
			</div>

			<div class="col-sm-6">
				
			</div>
		</div>
	</div>
</div>
<div class="modal-footer">
	 <button type="button" class="btn btn-default" (click)="clickOnCloseButton()">Close</button>
	</div> -->

<div class="modal-body py-2">
	<div id="categorytree" class="mt-2 ml-1"></div>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-primary btn-sm" (click)="selectCategory()">
		Select
	</button>
	<button type="button" class="btn btn-secondary btn-sm" (click)="closeButtonClickEvent()">
		Close
	</button>
</div>