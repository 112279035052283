import { Component, OnInit, Input, TemplateRef, ElementRef, HostListener, ViewChild} from '@angular/core';
import { IceTopic, ProcImage } from './ice-topic-model.model';
import { IceTopicContent } from '../ice-topic-content/ice-topic-content-model.model';
import { IceLearningCheck } from '../ice-learning-check/ice-learning-check.model';
import { IceGame } from '../ice-game/ice-game-model.model';
import { SharedService } from '../../util/shared.service';
import { AlertService } from '../../util/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from '../../util/loading/loading.service';
import { FolderService } from '../../foldertree/foldertree.service';
import { WsResponse } from '../../util/ws-response.model';
import { WsType } from '../../util/ws-type';
import { UserVariable } from '../../util/common/user-variable';
import { ICECourseService } from '../ice-course-service.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal'
import { ICECKEditor } from '../ice-course';
import { LocalStorage } from '../../util/localstorage.service';
import { CK_Editor_Type  } from '../../util/constants';
import { LearningCheckData_Details  } from '../../util/constants';
import { ICECourse } from '../ice-course.model';
import { CommonUtil } from 'src/app/util/common/common-util';
import { AngularSplitModule } from 'angular-split';
import { DependenciesModalService } from '../../util/dependenciesmodal/dependenciesmodal.service';
import { DependenciesmodalComponent } from '../../util/dependenciesmodal/dependenciesmodal.component'
import { Subscription } from 'rxjs';
import { GridOptions, Module, AllModules } from "@ag-grid-enterprise/all-modules";


declare var CKEDITOR: any;
var editor;

@Component({
  selector: 'app-ice-topic',
  templateUrl: './ice-topic.component.html',
  styleUrls: ['./ice-topic.component.scss']
})
export class IceTopicComponent implements OnInit {

  public divHeight: number = (window.innerHeight) * 4 / 5;
	@HostListener('window:resize', ['$event'])
	onresize(event?) {
		this.divHeight = (window.innerHeight) * 4 / 5
  }
  @ViewChild('conflict_checker') conflict_checker: TemplateRef<any>;
  public isShowIceTopicDetails: boolean = true;
  public isShowIceTopic: boolean = false;
  public isShowIceTopicContents: boolean = false;
  public isShowLearningChecks: boolean = false;
  public isShowIceGames: boolean = false;
  public isEnableReordering: boolean = true;
  public isDeleteEnable: boolean = true;
  public isViewOnly: boolean = false;
  public topicModified: boolean = false;
  public topicsModified: boolean = false;
  public learningChecksModified: boolean =false;
  public gameModified: boolean =false;
  public topicContentsModified: boolean = false;
  public navigateToTC: boolean = false;
  public navigateToParent: boolean = false;	
  public topicContentData: any;
  public iceTopicData: any; 
  public iceLearningCheckData: any;
  public loadtopiclist: boolean = false;
  public loadGameList: boolean = false;
  public loadLearningChecklist: boolean = false;
  public navigateToT: boolean = false;	
  public tc_data: any;
  public tc_index: any;
	public topicDependencies: number;
	public topicContentDependencies: number;
  modalRef: BsModalRef;
  public topicEnabled: boolean = false;
  public topicsEnabled: boolean = false;
  public topicContentsEnabled: boolean = false;
  public learningCheckEnabled:boolean = false;
  public gameEnabled:boolean = false;
  public isLCDeleteEnable: boolean = true;
  public gamesCount: number;
  public selectedGameType: string;
  public gameData: any;

  private insertSubscription: any;
  public LearningCheckData: any;
  private deleteTopicSubscription: Subscription;

  public iceTopicList: Array<any> = [];
  public iceTopicContentList: Array<any> = [];
  public iceLearningCheckList: Array<any> = [];
  public iceCourseList: Array<any> = [];
  public iceGameList: Array<any> = [];
  public indexTopicToDelete: number;

  public topic: IceTopic;

  public iceTopicModel: IceTopic;
  public iceLearningCheckModel: IceLearningCheck;
  public learningCheckToDelete:IceLearningCheck;
  public topicToDelete: IceTopic;
  public gameToDelete: IceGame;
  public iceTopicContentModel: IceTopicContent;
  public deleteIceTopicContentId: string;
  public deleteIceTopicContentIndex: number;
  public indexOfLearningCheckToDelete: number;
  public indexOfGameToDelete: number;
	public isShowIceTopicContentReorderButton: boolean = false;
  public speCharList: Array<any> = [];
  public editorList: Array<any> = [];
  public editorDivList: Array<any> = [];
  public iceCMCourseList: Array<any> = []
  public iceCMLearningCheckList: Array<any> = [];
  public iceCMGameList: Array<any> = [];
  public isLearningCheckList: boolean = true;
	public isLearningCheckDesc: boolean = true;
	public isGameList: boolean = true;
	public isGameDesc: boolean = true;

	public toolbarId: string;
  public desceditor: any;
  public titleditor: any;
	private html: string;
  private tagContent: String = '';
  public questionDependencies: string;
	private selection: any;
  private ranges: any;
  public userId: String;
  public tabId: string;
  public breadCrumbList:Array<any> = [];
  private newTopic:IceTopic;
  private newTopicContent:IceTopicContent;
  private newLearningCheck: IceLearningCheck;
  private newGame: IceGame;
  public topicNameShowInDetails:any = null;
  public addFractionDIvId: String = "";
  public addSpecialCharDivId: String = "";
  public iceGameModel:IceGame;
  public iceCourseModel: ICECourse;
  public iceCoursesAvailable: boolean = false;
  public selectedIceCourse: string = null;
  public selectedConfirmed: boolean = false;
  public linkedCourse: string = null;
  public haveChildItems: boolean = false;
  public iceCMCoursesAvailable: boolean = false;
  public cmLearningCheckListAvailable: boolean = false;
  public cmGameListAvailable: boolean = false;
	public selectedCMIceCourse: string = null;
	public selectedCMIceCourseVersion: string = null;
  public selectedCMLearningCheck: string = null;
  public selectedCMGame: string = null;
  public getCMLearningCheckData: boolean = false;
  public getCMGameData: boolean = false;
  public copyLearningCheckData: boolean = false;
  public copyGameData: boolean = false;
	public selectedCourseVersion: number = null;

  private xmlContent: string = '';
  
  public rowData: any[] = [];
  public modules: Module[] = AllModules;
  private stmHtml: string;
	private gridApi;
  public getRowNodeId;
	private gridColumnApi;
  private rowSelection
  public imageBackground: string = "#ffffff";
  public imageBorder: string = "solid #f0ba0a";
  public topicImageChecked: any = false;
  public activeEditor: string = '';

  topic_translated_data: any = null;

  public selectedModalTemplate: TemplateRef<any>;

  public modalConfig: any = {
		backdrop: true,
		// ignoreBackdropClick: true,
		class: 'modal-lg',
		// keyboard: false
  }

  public selectedImage: any = {
		'id': '',
		'thumbnailImage': '',
		'largeImage': '',
		'title': ''
	};
  
  private inlineConfig = {
		toolbar: [
			{ name: 'clipboard', groups: ['clipboard', 'undo'], items: ['Cut', 'Copy', '-', 'Undo', 'Redo'] },
			{ name: 'basicstyles', groups: ['basicstyles'], items: ['Bold', 'Italic', 'Underline', 'Subscript', 'Superscript'] },
			{ name: 'paragraph', groups: ['list'], items: ['NumberedList', 'BulletedList'] },
			{ name: 'insert', groups: ['insert'], items: ['addsymbol', 'addfraction'] }
			//{ name: 'editing', groups: ['spellchecker'], items: ['Scayt'] }
		],
		autoParagraph: false,
		readOnly: false,
		removePlugins: 'liststyle, magicline, maximize, resize, wsc',
		pasteFilter: 'plain-text',
		extraPlugins: 'statementclickhandler, sharedspace, addsymbol, addfraction, disablepartialbui, reusablepanelhandler, deletezws, deletehandler',
    title: false,
    tabID: SharedService.getTabId(),
		enterMode: CKEDITOR.ENTER_BR,
		startupFocus: 'end'
  };
  
  private inlineConfig_topic = {
		toolbar: [
			{ name: 'clipboard', groups: ['clipboard', 'undo'], items: ['Cut', 'Copy', '-', 'Undo', 'Redo'] },
			{ name: 'basicstyles', groups: ['basicstyles'], items: ['Bold', 'Italic', 'Underline', 'Subscript', 'Superscript'] },
			//{ name: 'paragraph', groups: ['list'], items: ['NumberedList', 'BulletedList'] },
			{ name: 'insert', groups: ['insert'], items: ['addsymbol', 'addfraction'] }
			//{ name: 'editing', groups: ['spellchecker'], items: ['Scayt'] }
		],
		autoParagraph: false,
		readOnly: false,
		removePlugins: 'liststyle, magicline, maximize, resize, wsc',
		pasteFilter: 'plain-text',
		extraPlugins: 'statementclickhandler, sharedspace, addsymbol, addfraction, disablepartialbui, reusablepanelhandler, deletezws, deletehandler',
    title: false,
    tabID: SharedService.getTabId(),
		enterMode: CKEDITOR.ENTER_BR,
		startupFocus: 'end'
  };

  public columnDefs = [
		{ headerName: '', field: 'cbox', width: 50, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
		{ headerName: 'Type', field: 'type', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 100, enableRowGroup: true },
		{ headerName: 'Last Value', field: 'lastVal', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 100, enableRowGroup: true, tooltipField: "lastVal" },
		{ headerName: 'New Value', field: 'newVal', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 100, enableRowGroup: true, tooltipField: "newVal" },
		{ headerName: 'Id', field: 'id', hide: true },
		{ headerName: 'Name', field: 'name', hide: true },
		{ headerName: 'LastUpdateTimeStamp', field: 'lastUpdateTimeStamp', hide: true },
		{ headerName: 'isDeleted', field: 'isDeleted', hide: true }
  ]

  public defaultColDef = {
    resizable: true,
    sortable: true,
  }

  constructor(private sharedService: SharedService, 
    private alert: AlertService, private translate: TranslateService, private iceCKEditor: ICECKEditor,
    private loadingService: LoadingService, 
    private courseService: ICECourseService, private modalService: BsModalService,private alertService: AlertService, private dependenciesService: DependenciesModalService,
    private elementRef: ElementRef, private commonUtil: CommonUtil) {
    this.topic = new IceTopic("0");
    this.userId = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER)).userId;
    this.tabId = SharedService.getTabId();
    this.addFractionDIvId = 'webeditor_fraction_' +SharedService.getTabId();
    this.addSpecialCharDivId = 'webeditor_special_char_' +SharedService.getTabId();

    /*let browserLang = translate.getBrowserLang();
    translate.getTranslation(browserLang).subscribe((res: string) => {
      this.topic_translated_data = res['ICE_TOPIC'];
    });*/

    // Adding dummy details
    //this.addDummyLCS(this.iceLearningCheckList);
    // this.addDummyIceGames(this.iceGameList);
    this.rowSelection = "multiple";
   }


   @Input() data: any;

  ngOnInit(){
		this.speCharList = [
			{ data: { symbol: this.getSymbolFromDecimal(169), decVal: 169, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(8482), decVal: 8482, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(174), decVal: 174, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(176), decVal: 176, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(163), decVal: 163, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(8364), decVal: 8364, type: "speChar" } },
			{ data: { symbol: this.getSymbolFromDecimal(10016), decVal: 10016, type: "speChar" } },
      { data: { symbol: this.getSymbolFromDecimal(9746), decVal: 9746, type: "speChar" } },
      // There was an issue in LC related to this special character. Therefore it was removed from all the Ck Editors in ICE Course
			// { data: { symbol: this.getSymbolFromDecimal(9744), decVal: 9744, type: "speChar" } },
      { data: { symbol: this.getSymbolFromDecimal(167), decVal: 167, type: "speChar" } },
      { data: { symbol: this.getSymbolFromDecimal(937), decVal: 937, type: "speChar" } }
		];
    console.log(this.data);
    if (this.data) {
      if(this.data.iceTopic != null && this.data.iceTopic != undefined ){
        //set topic details from parent page
        this.topic = this.data.iceTopic;
        this.sharedService.setOpenedICEModules(SharedService.getTabId(), 'ICETopic', this.topic);
        this.gamesCount = this.iceGameList.length;

        //set child details
        if (this.topic.id == '0'){
          //this.addDummyTopics(this.iceTopicList);
          //this.addDummyTopicContents(this.iceTopicContentList);
        }
        else{
          //show loading message
          var loadingMessage = null;
          this.translate.get('ICE_TOPIC.LOADING_MESSAGE').subscribe((res: string) => {
            loadingMessage = res;
          });
          this.loadingService.showLoading(true, null, loadingMessage, null);
          
          let token = UserVariable.getUserToken();
          if(!this.commonUtil.validateToken(token)){
              return;
          }
          //get this topic details
          this.courseService.getICETopic(this.topic.id, UserVariable.getUserToken(), this);
          
          //get topic details 
          this.courseService.getAllICETopicsByTopicId(this.topic.id, UserVariable.getUserToken(), this);
          
          //get topic contents
          this.courseService.getICETopicContentListByICETopicId(this.topic.id, UserVariable.getUserToken(), this);

			  	//get Learning Check details 
				  this.courseService.getAllICELearningCheckByTopicId(this.topic.id, UserVariable.getUserToken(), this);
          
          //get available course list
          this.courseService.getAllActiveICECourses(this.topic.id, UserVariable.getUserToken(), this);

          //get all latest checked in ice courses 
				  this.courseService.getLatestCMCourses( UserVariable.getUserToken(), this);
        }
        let self = this;
				let commonVariables = this.sharedService.getCommonICEModuleVariableNames('topic');
				var commonVariableValues: Array<any> = [];
				commonVariables.forEach(function (varName) {
					commonVariableValues[varName] = self[varName];
				}); 
				this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'topic', commonVariableValues)
      }
      else {
        // data.iceTopic is null or undefined
      }
      this.isViewOnly = this.data.viewOnly;
      this.breadCrumbList = this.sharedService.getICEBreadCrumb(this.topic.courseId);
		} else {
			//no data
    }
    //set topic enabled true
    this.topicEnabled = true;
    this.sharedService.setModifiedStatus(this.tabId, this.topicModified)
  }

  ngAfterViewInit() {
    let self = this;
    let e1 = self.elementRef.nativeElement.querySelector('.ckeditor_topic_description' + self.tabId);
    let e2 = self.elementRef.nativeElement.querySelector('.ckeditor_topic_title' + self.tabId);
    
		if (self.isViewOnly == true) {
			e1 .style.backgroundColor = "#e9ecef";
      e2 .style.backgroundColor = "#e9ecef";
		} else {
			self.setTopicEditors(e1,e2);
    }

    //this is called when topic is deleted from dependancy model
    this.deleteTopicSubscription = this.sharedService.deleteICETopicDoneResponse().subscribe(
			option => {
        if (option.isTopic == true) {
          if (this.tabId == SharedService.getTabId()) {
            this.deleteTopicProcess();
          } else {
            //diferent tab
          }
        } 
      }
		);

	}

  ngAfterContentInit() {

    //unsubscribe
    if (SharedService.getIceTopicSubscriptions() != null) {
			SharedService.getIceTopicSubscriptions().unsubscribe();
    }

    this.sharedService.setICEModuleChanges.subscribe(prevTab => {
      //get opened modules
      let openedICEModules = this.sharedService.getOpenedICEModules();
      //check if tab type is ICELearningCheck
      if (openedICEModules[prevTab] != undefined) {
        // get tab type
        var type = openedICEModules[prevTab]['type'];
        if (type == "ICETopic") {
          this.sharedService.setOpenedICEModules(prevTab, 'ICETopic', this.topic);
        } else {
					//differnt type
				}
      } else {
				//do nothing
			}
    });
    
    this.sharedService.setICEModuleVariables.subscribe(prevTab => {
			if(this.tabId == prevTab) {
				let self = this;
				let commonVariables = this.sharedService.getCommonICEModuleVariableNames('topic');
				var commonVariableValues: Array<any> = [];
				commonVariables.forEach(function (varName) {
					commonVariableValues[varName] = self[varName];
				}); 
				this.sharedService.setCommonICEModuleVariableValues(prevTab, 'topic', commonVariableValues);
			}
		});

		this.sharedService.setOpenedICEModule.subscribe(newTab => {
			let openedICEModules = this.sharedService.getOpenedICEModules();
			if(openedICEModules[newTab] != undefined) {
				this.topic = openedICEModules[newTab]['module'];
      }
    });
    
    this.sharedService.setOpenedICEVariables.subscribe(newTab => {
			if (this.tabId == newTab) {
				let commonVariables = this.sharedService.getCommonICEModuleVariableNames('topic');
				var commonVariableValues: Array<any> = [];
				commonVariableValues = this.sharedService.getCommonICEModuleVariableValues(newTab);
				console.log(commonVariableValues);
				if(commonVariableValues != undefined) {
					let self = this;
					commonVariables.forEach(function (varName) {
						self[varName] = commonVariableValues[varName];
					}); 
        }
			}
		});

		//Called after ngOnInit when the component's or directive's content has been initialized.
    //Add 'implements AfterContentInit' to the class.
    
    this.insertSubscription = SharedService.insertICECourseExecute().subscribe(option => {
      //get opened modules
      let openedICEModules = this.sharedService.getOpenedICEModules();
      //get current tab
      var currentTab = SharedService.getTabId();

      //check if current tab type is ICECourse
      if (openedICEModules[currentTab] != undefined) {
        // get tab type
        var type = openedICEModules[currentTab]['type'];

        if (type == "ICETopic") {
          let commonVariableValues = this.sharedService.getCommonICEModuleVariableValues(SharedService.getTabId());
          //reusables not available for learning checks, check the available editor before adding reusables
          // if(this.learningCheckEnabled == false ){
          //   this.iceCKEditor.insertTagEditArea(option, this.elementRef,editor,CKEDITOR, this.topic, "ICETopic" )
          // } else if(this.learningCheckEnabled == true){
          //   this.alertService.warn(this.translate.instant('ICE_LEARNING_CHECK.ADD_REUSABLES_WARNING'));
          // }
          if (commonVariableValues.learningCheckEnabled == false && commonVariableValues.gameEnabled == false) {
            this.iceCKEditor.insertTagEditArea(option, commonVariableValues.elementRef, commonVariableValues.editor, CKEDITOR, this.topic, "ICECourse");
          } else if (commonVariableValues.learningCheckEnabled == true) {
            this.alertService.warn(this.translate.instant('ICE_LEARNING_CHECK.ADD_REUSABLES_WARNING'));
          } else if (commonVariableValues.gameEnabled == true) {
            this.alertService.warn(this.translate.instant('ICE_GAME.ADD_REUSABLES_WARNING'));
          }
        } else {
          //differnt type
        }
      } else {
        //do nothing
      }
    });
      
    this.sharedService.imageStatusRespond().subscribe(imageStatus => this.setImageStatus(imageStatus));
    SharedService.setIceTopicSubscriptions(this.insertSubscription);
  }

  openModalCC(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template, { class: 'modal-lg', backdrop: 'static', keyboard: false });
	}

	onGridReady(params) {
		this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
	}
  
  setImageStatus(type){
		if(type.status =="ICETopic" && type.tabId == this.tabId ){
      this.topicModified	= true;
      this.sharedService.setModifiedStatus(this.tabId, this.topicModified)
		}
	}
  
  /**
   * Back to parent component
   */
  public backToParent() {
    if (this.topic.parentType == "course"){
      let data = {  
        courseId  : this.topic.courseId,      
        viewOnly : this.isViewOnly,
        id       : this.topic.parent.courseId
      }
      this.sharedService.changeIceItemRequest('IceCourse', data);
    }
    else if (this.topic.parentType == "topic"){
      let data = {
        courseId  : this.topic.courseId, 
        iceTopic : this.topic.parent 
      }
      this.sharedService.changeIceItemRequest('topic', data);
    }
    else if (this.topic.parentType == "topicContent"){
      this.sharedService.changeIceItemRequest('topicContent', this.topic.parent);
    }
    else{
      // nothing to do here
    }
  }

  public clickBreadCrumb(data:any,index:number){
    let self = this;
    if(data != null){
      if (self.topicModified == true) {
        self.alertService.warn(self.translate.instant('ICE_TOPIC.TOPIC.TOPIC_SAVE'));
      } else if (self.topicsModified == true) {
        self.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_TOPICS'));
      } else if (self.topicContentsModified == true) {
        self.alertService.warn(self.translate.instant('ICE_COURSE.COURSE.SAVE_TOPIC_CONTENTS'));
      } else if (self.learningChecksModified == true) {
        self.alertService.warn(self.translate.instant('ICE_COURSE.COURSE.SAVE_LEARNINGCHECKS'));
      } else if (self.gameModified == true) {
        self.alertService.warn(self.translate.instant('ICE_COURSE.COURSE.SAVE_GAMES'));
      } else {
        this.sharedService.changeIceItemRequest(data.iceType, data);
        this.breadCrumbList.length = index;
      }
    }else{
      //nothing to do
    }
  }

  /*public clickBreadCrumb(data:any,index:number){
    if(data != null){
      this.tc_data = data;
      this.tc_index = index;
      if (this.isViewOnly == true || (this.topicContentsModified == false && this.topicsModified == false)) {
        this.sharedService.changeIceItemRequest(data.iceType, data);
        this.breadCrumbList.length = index;
      } else {
        if (this.iceTopicList.length == 0 && (this.iceTopicContentList.length == 0 || this.isShowIceTopicContents == false)) {
          this.sharedService.changeIceItemRequest(data.iceType, data);
          this.breadCrumbList.length = index;
        } else {
          //save topics and topic contents
          this.topicsSave(false);
          this.navigateToParent = true;
          if (this.isShowIceTopicContents == true) {
            this.navigateToTC = false;
            this.topicContentsSave(false);
          } else {
            //do nothing
          }
        }
      }
    }else{
      //nothing to do
    }
  }*/

  showIceTopicDetails(isShowIceTopicDetails) {
		let self = this;
		if (isShowIceTopicDetails == true) {
      if (self.topicModified == true) {
        self.alertService.warn(self.translate.instant('ICE_TOPIC.TOPIC.TOPIC_SAVE'));
      } else {
        self.isShowIceTopicDetails = false;
      }
		} else {
			self.isShowIceTopicDetails = true;
			setTimeout(() => {
				let e1  = this.elementRef.nativeElement.querySelector('.ckeditor_topic_description' + self.tabId);
        e1.innerHTML = this.topic.description;
        let e2  = this.elementRef.nativeElement.querySelector('.ckeditor_topic_title' + self.tabId);
        e2.innerHTML = this.topic.topicName;
        if(self.topicEnabled == false) {
					self.disableTopicEditors();
				} else {
					self.setTopicEditors(e1,e2);
				}
			}, 200);
		}
	}

  setTopicEditors(e1,e2) {
    let self = this;
    if (self.topic.description != undefined) {
			e1.innerHTML = self.topic.description;
		} else {
			//do nothing
		}
		if (self.topic.topicName != undefined) {
			e2.innerHTML = self.topic.topicName;
		} else {
			//do nothing
		}

		
		$('.title_ck_editor' + this.tabId).dblclick(function (e) {
      if (e.target.nodeName != "PH" || (self.titleditor == null) || (self.titleditor == undefined)) {
      if ($(this)[0].attributes['contenteditable'].nodeValue == "false") {
      if (self.topicEnabled == true) {
        //set topicModified true
        self.topicModified = true;
        self.sharedService.setModifiedStatus(self.tabId, self.topicModified)
        if (self.titleditor) {
          self.titleditor.destroy();
        } else {
          //no editor
        }
        self.titleditor = CKEDITOR.inline(e2 , self.inlineConfig_topic);
        if (self.desceditor) {
          self.desceditor.destroy();
        } else {
          //no editor
        }
        //set description editable false
        e1 .setAttribute("contenteditable", "false");
        //set title editable true
        e2 .setAttribute("contenteditable", "true");      							
        if ($(e1)[0].innerHTML == '') {
            $(e1)[0].innerHTML = '<br>';
        }
        self.setEditorFunctions(self.titleditor);
      } else {
        //do nothing
      }
    }
    } else {
      if ($(this)[0].attributes['contenteditable'].nodeValue == "true") {
          SharedService.setselectedNode(e.target);
        let item = e.target.attributes['restriction'].nodeValue;
        let catId = e.target.attributes['catId']
        if (e.target.attributes['catId'] != undefined && e.target.attributes['catId'] != null) {
            catId = e.target.attributes['catId'].nodeValue;
        }                       
        self.iceCKEditor.getRestrictionValueOndblClick(item,catId);
      }
        self.setEditorFunctions(self.titleditor);
      }
		});
		$('.desc_ck_editor' + this.tabId).dblclick(function (e) {
      if (e.target.nodeName != "PH" || (self.desceditor == null) || (self.desceditor == undefined)) {
      if ($(this)[0].attributes['contenteditable'].nodeValue == "false") {
      if (self.topicEnabled == true) {
        //set topicModified true
        self.topicModified = true;
        self.sharedService.setModifiedStatus(self.tabId, self.topicModified)
        if (self.desceditor) {
          self.desceditor.destroy();
        } else {
          //no editor
        }
        self.desceditor = CKEDITOR.inline(e1 , self.inlineConfig);
        if (self.titleditor) {
          self.titleditor.destroy();
        } else {
          //no editor
        }
        //set description editable true
        e1 .setAttribute("contenteditable", "true");
        //set title editable false
        e2 .setAttribute("contenteditable", "false");
        if ($(e2)[0].innerHTML == '') {
            $(e2)[0].innerHTML = '<br>';
        }
        self.setEditorFunctions(self.desceditor);
      } else {
        
      }
    }
    } else {
      if ($(this)[0].attributes['contenteditable'].nodeValue == "true") {
          SharedService.setselectedNode(e.target);
        let item = e.target.attributes['restriction'].nodeValue;
        let catId = e.target.attributes['catId']
        if (e.target.attributes['catId'] != undefined && e.target.attributes['catId'] != null) {
            catId = e.target.attributes['catId'].nodeValue;
        }                       
        self.iceCKEditor.getRestrictionValueOndblClick(item,catId);
      }
        self.setEditorFunctions(self.desceditor);
    }
		});
	}

	setEditorFunctions(editor) {
    let self = this;
		if (editor) {
			editor.on('instanceReady', function (evt) {
				var range = editor.createRange();
				if (range != null) {
				range.moveToElementEditEnd(range.root);
				editor.getSelection().selectRanges([range]);
				}
      });
      
      editor.on('key', function (e) {
				self.iceCKEditor.editorKeyDown(e,editor);
      });
      
			editor.on('focus', function (evt) {
				if(navigator.userAgent.toLowerCase().indexOf("firefox") > -1){
					let wintitles = editor.container.getElementsByTag("wintitle");
					for(let i=0; i<wintitles.$.length; i++){
						wintitles.$[i].removeAttribute("contenteditable");
					}
				}
			});

			editor.on('paste', function (evt) {
		        evt.stop();
		        var data: string = evt.data.dataValue;
		        let pasteData: string = self.iceCKEditor.processPasteData(data);
            if(self.iceCKEditor.gethasResuables()) {
              self.alertService.clear();
              self.alertService.toast(self.translate.instant('ICE_COURSE.DISABLED_EDITOR_COPY'));
            }
		        evt.editor.insertHtml(pasteData);
      });
      this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'topic', editor, 'editor');
		} else {
			//no editor
		}
	}

  setInputKeyUp() {
		let self = this;
		$('.topiclisttitle').keyup(function() {
      //set topics modified true
      self.topicsModified = true;
      self.sharedService.setModifiedStatus(self.tabId, self.topicsModified)
		});
	}

	showICETopic(isShowIceTopic) {
		let self = this;
		if (isShowIceTopic == true) {
      if (self.topicsModified == true) {
        self.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_TOPICS'));
      } else {
        self.isShowIceTopic = false;
      }
		} else {
      //remove unsaved topic 
      console.log("self.iceTopicList.length"+self.iceTopicList.length);
      self.iceTopicList = self.iceTopicList.filter(function(a){
        return a.topicName != undefined;
      });
			self.isShowIceTopic = true;
			setTimeout(() => {
        self.setEditor(CK_Editor_Type.TOPIC_EDITOR, "");
      }, 200);
			
		}
  }

  setEditor( CK_Editor_Variable:string, className) {
    var self = this;
    $('.toggle-edit' + SharedService.getTabId() + this.userId).on('copy', function() {
			if ($(this)[0].attributes['contenteditable'].nodeValue == "false") {
				localStorage.setItem('disabledEditor', "true"); 
			}
		});	
    //set editor content
    this.setEditorContent(CK_Editor_Variable, className);

    if(this.isViewOnly == true) {
        //view only
    } else {
      
        $('.toggle-edit' + SharedService.getTabId() + this.userId).dblclick(function (e) {
          if (self.topicsEnabled == true || self.topicContentsEnabled == true || self.learningCheckEnabled == true || self.gameEnabled == true) {
            //set modified true
            if (self.activeEditor == 'topics_ck_editor' && CK_Editor_Variable == 'topics_ck_editor') {
              self.topicsModified = true;
              self.sharedService.setModifiedStatus(self.tabId, self.topicsModified)
            }else if(self.activeEditor == 'topic_content_ck_editor' && CK_Editor_Variable == 'topic_content_ck_editor') {
              self.topicContentsModified = true;
              self.sharedService.setModifiedStatus(self.tabId,  self.topicContentsModified)
            } else if(self.activeEditor == 'learning_checks_ck_editor' && CK_Editor_Variable == 'learning_checks_ck_editor'){
              self.learningChecksModified =true;
              self.sharedService.setModifiedStatus(self.tabId,  self.learningChecksModified)
            } else if(self.activeEditor == 'games_ck_editor' && CK_Editor_Variable == 'games_ck_editor'){
              self.gameModified = true;
              self.sharedService.setModifiedStatus(self.tabId, self.gameModified) 
            }else{
                  //////////
            }
            if (e.target.nodeName != "PH" || (editor == null) || (editor == undefined)) {
              if ($(this)[0].attributes['contenteditable'].nodeValue == "false") {
            for (let i in self.editorList) {
              if (CKEDITOR.instances[self.editorList[i].name] != undefined) {
                CKEDITOR.instances[self.editorList[i].name].destroy();
              }
            }
            for (let j in self.editorDivList) {
              if (self.editorDivList[j].attributes['contenteditable'].nodeValue == "true") {
                self.editorDivList[j].setAttribute("contenteditable", "false");								
                if ($(self.editorDivList[j])[0].innerHTML == '') {
                  $(self.editorDivList[j])[0].innerHTML = '<br>';
                }
              }
            }
            $(this)[0].setAttribute("contenteditable", "true");
            if (CK_Editor_Variable == 'topics_ck_editor') {
              let topicId = $(this)[0].getAttribute("id");
              let topic = self.iceTopicList.find(obj => obj.id == topicId);
              //set topic modified true
              topic.isTopicModified = true;
              editor = CKEDITOR.inline($(this)[0], self.inlineConfig_topic);
              self.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'topic', editor, 'editor');
            } else if(CK_Editor_Variable == 'topic_content_ck_editor') {
              let topiccontentId = $(this)[0].getAttribute("id");
              let topiccontent = self.iceTopicContentList.find(obj => obj.id == topiccontentId);
              //set topic content modified true
              topiccontent.isTopicContentModified = true
              editor = CKEDITOR.inline($(this)[0], self.inlineConfig);
              self.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'topic', editor, 'editor');
            } else if(CK_Editor_Variable == 'learning_checks_ck_editor'){
              let learningCheckId = $(this)[0].getAttribute("id");
              let learningcheck = self.iceLearningCheckList.find(obj => obj.id == learningCheckId);
              //set LC modified true
              learningcheck.isLearningCheckModified = true
              editor = CKEDITOR.inline($(this)[0], self.inlineConfig_topic);
              self.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'topic', editor, 'editor');
            } else if(CK_Editor_Variable=='games_ck_editor'){
              //self.gameModified = true;
              //self.sharedService.setModifiedStatus(self.tabId, self.gameModified);
              
              let gameId = $(this)[0].getAttribute("id"); 
               let iceGameDetail = self.iceGameList.find(obj => obj.id == gameId);
              iceGameDetail.isGameModified = true; 
              editor = CKEDITOR.inline($(this)[0], self.inlineConfig_topic);
              self.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'topic', editor, 'editor');
            }
            self.editorList.push(editor);
            self.editorDivList.push($(this)[0]);
          } 
          } else {
            if ($(this)[0]. attributes['contenteditable'].nodeValue == "true") {
                SharedService.setselectedNode(e.target);
              let item = e.target.attributes['restriction'].nodeValue;
              let catId = e.target.attributes['catId']
              if (e.target.attributes['catId'] != undefined && e.target.attributes['catId'] != null) {
                  catId = e.target.attributes['catId'].nodeValue;
              }                       
              self.iceCKEditor.getRestrictionValueOndblClick(item,catId);
            }
          }

            editor.on('instanceReady', function (evt) {
              var range = editor.createRange();
              if (range != null) {
                range.moveToElementEditEnd(range.root);
                editor.getSelection().selectRanges([range]);
              }
            });

            editor.on('key', function (e) {
              self.iceCKEditor.editorKeyDown(e,editor);
            });
            editor.on('focus', function (evt) {
              if(navigator.userAgent.toLowerCase().indexOf("firefox") > -1){
                let wintitles = editor.container.getElementsByTag("wintitle");
                for(let i=0; i<wintitles.$.length; i++){
                  wintitles.$[i].removeAttribute("contenteditable");
                }
              }
            });

            editor.on('paste', function (evt) {
              evt.stop();
              var data: string = evt.data.dataValue;
              let pasteData: string = self.iceCKEditor.processPasteData(data);
              if(self.iceCKEditor.gethasResuables()) {
                self.alertService.clear();
                self.alertService.toast(self.translate.instant('ICE_COURSE.DISABLED_EDITOR_COPY'));
              }
              evt.editor.insertHtml(pasteData);
            });
          } else {
            //do nothing
          }
          self.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'topic', editor, 'editor');
        });
    }
  }

  setEditorContent(CK_Editor_Variable, className) {
    if (CK_Editor_Variable == 'topics_ck_editor') {
        if(className) {
            let el = this.elementRef.nativeElement.querySelector(className);
            if (el != null) {
              let name = this.iceTopicList[this.newTopic.sequence - 1].topicName;
              if (name != undefined) {
                el.innerHTML = name;
              } else {
                //el.innerHTML = "<p>&nbsp;</p>";
              }
              } else {
              //no element
            }
        } else {
          for(let i = 1; i <= this.iceTopicList.length; i++){
              let el = this.elementRef.nativeElement.querySelector('.t_ck_editor' + i + this.tabId);
              if (el != null) {
                let name = this.iceTopicList[i - 1].topicName;
                if (name != undefined) {
                  el.innerHTML = name;
                } else {
                  //el.innerHTML = "<p>&nbsp;</p>";
                }
              } else {
                //no element
              }

              if(this.isViewOnly == true|| this.topicsEnabled == false) {
                if (el != null) {
                  el.setAttribute("contenteditable", "false");
                  el.style.backgroundColor = "#e9ecef";
                } else {
                  //no element
                }
              } else {
                if (el != null) {
                  el.style.backgroundColor = "";
                } else {
                  //no element
                }
              }
          }
      }
    } else if(CK_Editor_Variable=='topic_content_ck_editor') {
        if(className) {
          let el = this.elementRef.nativeElement.querySelector(className);
          if (el != null) {
            let desc = this.iceTopicContentList[this.newTopicContent.sequence - 1].description;
            if (desc != undefined) {
              el.innerHTML = desc;
            } else {
              //el.innerHTML = "<p>&nbsp;</p>";
            }
            } else {
            //no element
          }
      } else {
          for(let i = 1; i <= this.iceTopicContentList.length; i++){
            let el = this.elementRef.nativeElement.querySelector('.tc_ck_editor' + i + this.tabId); 
            if (el != null) {
              let desc = this.iceTopicContentList[i - 1].description;

              if (desc != undefined) {
                el.innerHTML = desc;
              } else {
                //el.innerHTML = "<p>&nbsp;</p>";
              }
            } else {
              //no element
            }

            if(this.isViewOnly == true|| this.topicContentsEnabled == false) {
              if (el != null) {
                el.setAttribute("contenteditable", "false");
                el.style.backgroundColor = "#e9ecef";
              } else {
                //no element
              }
            } else {
              if (el != null) {
                el.style.backgroundColor = "";
              } else {
                //no element
              }
            }
          }
      }
    }else if(CK_Editor_Variable=='learning_checks_ck_editor'){
      if(className) {
        let el = this.elementRef.nativeElement.querySelector(className);
        if (el != null) {
          let name = this.iceLearningCheckList[this.newLearningCheck.sequence - 1].description; 
          if (name != undefined) {
            el.innerHTML = name;
          } else {
            //el.innerHTML = "<p>&nbsp;</p>";
          }
          } else {
          //no element
        }
    } else {
      for(let i = 1; i <= this.iceLearningCheckList.length; i++){
          let el = this.elementRef.nativeElement.querySelector('.l_ck_editor' + i + this.tabId);
          if (el != null) {
            let name = this.iceLearningCheckList[i - 1].description;
            if (name != undefined) {
              el.innerHTML = name;
            } else {
              //el.innerHTML = "<p>&nbsp;</p>";
            }
          } else {
            //no element
          }

          if(this.isViewOnly == true|| this.learningCheckEnabled == false) {
            if (el != null) {
              el.setAttribute("contenteditable", "false");
              el.style.backgroundColor = "#e9ecef";
            } else {
              //no element
            }
          } else {
            if (el != null) {
              el.style.backgroundColor = "";
            } else {
              //no element
            }
          }
        }
     }
    }  else if(CK_Editor_Variable=='games_ck_editor'){
			if(className) {
				let el = this.elementRef.nativeElement.querySelector(className);
				if (el != null) {
					let game = this.iceGameList.find(obj => obj.id == this.newGame.id);
					let desc = game.description;
					if (desc != undefined) {
						el.innerHTML = desc;
					} else {
						// do nothing
					}
				} else {
					//no element
				}
			} else {
				for(let i = 1; i <= this.iceGameList.length; i++){
          let e2 = this.elementRef.nativeElement.querySelector('.games_ck_editor' + this.iceGameList[i-1].id + this.tabId);
					if (e2 != null) {
						let desc = this.iceGameList[i - 1].description;
						if (desc != undefined) {
							e2.innerHTML = desc;
						} else {
							// do nothing
						}
					} else {
					//no element
					}
					if(this.isViewOnly == true || this.gameEnabled == false) {
						if (e2 != null) {
							e2.setAttribute("contenteditable", "false");
							e2.style.backgroundColor = "#e9ecef";
						} else {
              //no element
              //e2.style.backgroundColor = "#ffffff";
						}
					} else {
						if (e2 != null) {
							e2.style.backgroundColor = "";
						} else {
							//no element
						}
					}
        }
			}
		}
  }

  focusNewEditor(className: string, CK_Editor_Variable) {
    let self = this;
    //set editor content
    this.setEditor(CK_Editor_Variable, className);

    let node = $(".toggle-edit" + this.tabId + this.userId + className);
    console.log("fn length"+this.editorList.length);
    for (let i in this.editorList) {
			if (CKEDITOR.instances[this.editorList[i].name] != undefined) {
				CKEDITOR.instances[this.editorList[i].name].destroy();
			}
    }
    for (let j in self.editorDivList) {
			if (self.editorDivList[j].attributes['contenteditable'].nodeValue == "true") {
        self.editorDivList[j].setAttribute("contenteditable", "false");								
				if ($(self.editorDivList[j])[0].innerHTML == '') {
					$(self.editorDivList[j])[0].innerHTML = '<br>';
				}
			}
		}
    $(node)[0].setAttribute("contenteditable", "true");
    if (CK_Editor_Variable == 'topics_ck_editor' || CK_Editor_Variable == 'learning_checks_ck_editor'|| CK_Editor_Variable == 'games_ck_editor') {
      editor = CKEDITOR.inline($(node)[0], this.inlineConfig_topic);
    } else {
      editor = CKEDITOR.inline($(node)[0], this.inlineConfig);
    }
		this.editorList.push(editor);
		this.editorDivList.push($(node)[0]);

		editor.on('instanceReady', function (evt) {
			var range = editor.createRange();
			if (range != null) {
				range.moveToElementEditEnd(range.root);
				editor.getSelection().selectRanges([range]);
			}
    });

    if ($(node)[0].attributes['contenteditable'].nodeValue == "true") {
      $(document).on('dblclick','ph', function(){
      let item = $(node).attr('restriction');
      let catId = $(node).attr('catId');	                        
      self.iceCKEditor.getRestrictionValueOndblClick(item,catId);
    });
    }
    
    editor.on('key', function (e) {
      self.iceCKEditor.editorKeyDown(e,editor);
    });
    editor.on('focus', function (evt) {
      if(navigator.userAgent.toLowerCase().indexOf("firefox") > -1){
        let wintitles = editor.container.getElementsByTag("wintitle");
        for(let i=0; i<wintitles.$.length; i++){
          wintitles.$[i].removeAttribute("contenteditable");
        }
      }
    });

		editor.on('paste', function (evt) {
	      evt.stop();
	      var data: string = evt.data.dataValue;
	      let pasteData: string = self.iceCKEditor.processPasteData(data);
        if(self.iceCKEditor.gethasResuables()) {
					self.alertService.clear();
					self.alertService.toast(self.translate.instant('ICE_COURSE.DISABLED_EDITOR_COPY'));
				}
	      evt.editor.insertHtml(pasteData);
	    });
    this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'topic', editor, 'editor');
	}

	private getSymbolFromDecimal(value: number): string {
		if ((33 <= value) &&  (value < 65536)) {
            return String.fromCharCode(value);
        } else {
            this.alertService.error("Please enter valid Unicode character entity reference. Range is between 33 to 65535", true);
        }
  }
  
  openModal(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template);
		}

  descriptionClick() {
    //set editor
    var self = this;
    $('.ckeditor_topic_description' + SharedService.getTabId()).dblclick(function (e) {
      editor = self.desceditor;
      self.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'topic', editor, 'editor');
      })
  }
  
  titleClick() {
    //set editor
    var self = this;
    $('.ckeditor_topic_title' + SharedService.getTabId()).dblclick(function (e) {
      editor = self.titleditor;
      self.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'topic', editor, 'editor');
      })
  }
  
  editTopic() {
    let self = this;
    if (self.topicsModified == true) {
      self.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_TOPICS'));
    } else if (self.topicContentsModified == true) {
      self.alertService.warn(self.translate.instant('ICE_COURSE.COURSE.SAVE_TOPIC_CONTENTS'));
    } else if (self.learningChecksModified == true) {
      self.alertService.warn(self.translate.instant('ICE_COURSE.COURSE.SAVE_LEARNINGCHECKS'));
    } else if (self.gameModified == true) {
      self.alertService.warn(self.translate.instant('ICE_COURSE.COURSE.SAVE_GAMES'));
    } else {
      self.topicEnabled = true;
      self.topicsEnabled = false;
      self.topicContentsEnabled = false;
      self.learningCheckEnabled = false;
      self.gameEnabled = false;
      this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'topic', true, 'topicEnabled');
			this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'topic', false, 'topicsEnabled');
			this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'topic', false, 'topicContentsEnabled');
			this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'topic', false, 'learningCheckEnabled');
			this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'topic', false, 'gameEnabled');

      //destroy editors
			for (let i in this.editorList) {
				if (CKEDITOR.instances[this.editorList[i].name] != undefined) {
					CKEDITOR.instances[this.editorList[i].name].destroy();
				}
      }
      
      self.isShowIceTopicDetails = true
      setTimeout(() => {
				//set background colors
				let e1  = self.elementRef.nativeElement.querySelector('.ckeditor_topic_description' + self.tabId);
        let e2  = self.elementRef.nativeElement.querySelector('.ckeditor_topic_title' + self.tabId);
				e1.style.backgroundColor = "";
				e2.style.backgroundColor = "";
        self.setTopicEditors(e1,e2)
      }, 200);

      //collapse other sections
      self.isShowIceTopic = false;
      self.isShowIceTopicContents = false;
      self.isShowLearningChecks = false;
    }
  }

  editTopics() {
    let self = this;
    self.activeEditor = CK_Editor_Type.TOPIC_EDITOR;
    if (self.topicModified == true) {
      self.alertService.warn(self.translate.instant('ICE_TOPIC.TOPIC.TOPIC_SAVE'));
    } else if (self.topicContentsModified == true) {
      self.alertService.warn(self.translate.instant('ICE_COURSE.COURSE.SAVE_TOPIC_CONTENTS'));
    } else if (self.learningChecksModified == true) {
      self.alertService.warn(self.translate.instant('ICE_COURSE.COURSE.SAVE_LEARNINGCHECKS'));
    } else if (self.gameModified == true) {
      self.alertService.warn(self.translate.instant('ICE_COURSE.COURSE.SAVE_GAMES'));
    } else {
      self.topicsEnabled = true;
      self.topicEnabled = false;
      self.topicContentsEnabled = false;
      self.learningCheckEnabled = false;
      self.gameEnabled = false;
      this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'topic', false, 'topicEnabled');
			this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'topic', true, 'topicsEnabled');
			this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'topic', false, 'topicContentsEnabled');
			this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'topic', false, 'learningCheckEnabled');
			this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'topic', false, 'gameEnabled');
      //destroy editors
			if (self.titleditor) {
				self.titleditor.destroy();
			} else {
				//no editor
			}
			if (self.desceditor) {
				self.desceditor.destroy();
			} else {
				//no editor
      }
      
      self.isShowIceTopic = true;
      setTimeout(() => {
        self.setEditor(CK_Editor_Type.TOPIC_EDITOR, "");
      }, 200);

      //collapse other sections
      self.isShowIceTopicDetails = false;
      self.isShowIceTopicContents = false;
      self.isShowLearningChecks= false;
    }
  }

  editTopicContents() {
    let self = this;
    self.activeEditor = CK_Editor_Type.TOPIC_CONTENT_EDITOR;
    if (self.topicModified == true) {
      self.alertService.warn(self.translate.instant('ICE_TOPIC.TOPIC.TOPIC_SAVE'));
    } else if (self.topicsModified == true) {
      self.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_TOPICS'));
    } else if (self.learningChecksModified == true) {
      self.alertService.warn(self.translate.instant('ICE_COURSE.COURSE.SAVE_LEARNINGCHECKS'));
    } else if (self.gameModified == true) {
      self.alertService.warn(self.translate.instant('ICE_COURSE.COURSE.SAVE_GAMES'));
    } else {
      self.topicContentsEnabled = true;
      self.topicEnabled = false;
      self.topicsEnabled = false;
      self.learningCheckEnabled = false;
      self.gameEnabled = false;
      this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'topic', false, 'topicEnabled');
			this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'topic', false, 'topicsEnabled');
			this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'topic', true, 'topicContentsEnabled');
			this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'topic', false, 'learningCheckEnabled');
			this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'topic', false, 'gameEnabled');
      //destroy editors
			if (self.titleditor) {
				self.titleditor.destroy();
			} else {
				//no editor
			}
			if (self.desceditor) {
				self.desceditor.destroy();
			} else {
				//no editor
      }

      self.isShowIceTopicContents = true;
      setTimeout(() => {
        self.setEditor(CK_Editor_Type.TOPIC_CONTENT_EDITOR, "");
      }, 200);

      //collapse other sections
      self.isShowIceTopicDetails = false;
      self.isShowIceTopic = false;
      self.isShowLearningChecks= false;
    }
  }

  disableTopicEditors() {
		let self = this;
		//set background colors in course editors
		let e1 = self.elementRef.nativeElement.querySelector('.ckeditor_topic_description' + self.tabId);
		let e2 = self.elementRef.nativeElement.querySelector('.ckeditor_topic_title' + self.tabId);
		if (e1) {
			e1.style.backgroundColor = "#e9ecef";
			e1.setAttribute("contenteditable", "false");
		} else {
			//do nothing
		}

		if(e2) {
			e2.style.backgroundColor = "#e9ecef";
			e2.setAttribute("contenteditable", "false");
		} else {
			//do nothing
		}
	}

  /**
	 * Show topic image from selected item of image search
	 */
	displayImage(option) {
		if (this.topic.selectedType == "Image") {
			//set course image
			this.topic.topicImage = new ProcImage(option.data.id, option.data.thumbnailImage, option.data.title);
    } 
    else{
      // nothing to do here( topic has only images, no icons)
    }
  }

  showLargeImage(template: TemplateRef<any>, selectedImageId: any) {
    this.selectedImage = {
      'id': '',
      'thumbnailImage': '',
      'largeImage': '',
      'title': ''
    }
    //show loading message
    var loadingMessage = null;
    this.translate.get('ICE_COURSE.COURSE_PREVIEW_IMAGE_LOADING').subscribe((res: string) => {
        loadingMessage = res;
    });
    this.loadingService.showLoading(true, null, loadingMessage, null);
    this.courseService.getSelectedImage(selectedImageId, UserVariable.getUserToken(), this);
    this.selectedModalTemplate = template;
  }

	public modelRefCloseEvent() {
		this.modalRef.hide()
		this.selectedImage = {
			'id': '',
			'thumbnailImage': '',
			'largeImage': '',
			'title': ''
		}
	}
  
  /**
	 * Open image search tab
	 */
	private changeToImagePanel() {
		SharedService.openReusablepanel('images');
	}


  // Main Topic

  /**
	 * Restrict user typing
	 */
  returnTyping() {
		return false;
  }

  /**
	 * Topic image text box click event
	 */
	addTopicImage() {
		this.topic.selectedType = "Image";
    this.changeToImagePanel();
  }
  
  /**
	 * Topic image remove button click event
	 */
	removeTopicImage() {
    this.topic.topicImage = null;
    //set topicModified true
    this.topicModified = true;
    this.sharedService.setModifiedStatus(this.tabId, this.topicModified)
  }
  
  /**
	 * Validate form
	 */
	validateForm(): boolean {
    let self = this;
    var editortext_title = self.replaceWhiteSpace($(".ckeditor_topic_title" + self.tabId).text());
		var length_desc = editortext_title.length;
		/*if (this.topic.topicName == undefined || this.topic.topicName == "") {
      console.log('TopicName is undefined|null|empty!!!');
			return false;
    }*/
    if (length_desc < 1) {
      self.alertService.error(self.translate.instant('ICE_TOPIC.TOPIC.TITLE_ERROR'));
			return false;
    }
    /*if (this.topic.topicImage == null) {
      console.log('TopicName is null!!!');
			return false;
    }*/
    console.log('[Details are valid]');
		return true;
  }
  
  ifChecked(){
    if(this.topicImageChecked == true){
      this.topic.selectedType = "";
      this.removeTopicImage();
      this.topicImageChecked = false;
      this.imageBackground = "#ffffff";
      this.imageBorder = "solid #f0ba0a";
    } else if(this.topicImageChecked == false){
      this.addTopicImage();
      this.imageBackground = "#f0ba0a";
      this.imageBorder = "none";
      this.topicImageChecked = true;
    }
  }


  isNullOrWhitespace(input) {
		return !/\S/.test(input);
    }
    
  replaceWhiteSpace(str) {
    return str.replace(/[\s\u200B]/g,'');
  }  

	private getEditorContent(editorName: string): string {
		let el = this.elementRef.nativeElement.querySelector(editorName);
		return el.innerHTML;
	}

  /**
	 * Topic detail save button click event
	 */
  public topicDetailSave() {
    this.xmlContent ="";
    console.log('[topicDetailSave]');
    //get ice topic description ck editor content
    let content_description = this.getEditorContent('.ckeditor_topic_description' + this.tabId);
    //process content
		this.iceCKEditor.processXML(content_description,false,this.xmlContent);
    this.xmlContent = this.iceCKEditor.getXMLContent();
    //set topic description
    this.topic.description = this.xmlContent;
    
    this.xmlContent ="";
    //get ice topic title ck editor content
    let content_title = this.getEditorContent('.ckeditor_topic_title' + this.tabId);
    //process content
		this.iceCKEditor.processXML(content_title,false,this.xmlContent);
    this.xmlContent = this.iceCKEditor.getXMLContent();
    //set topic description
    this.topic.topicName = this.xmlContent;
    
    // Validate form
    if (!this.validateForm()){
      return;
    }
    else{
      console.log('Validated topic : ' + JSON.stringify(this.topic));
      //show loading message
      var loadingMessage = null;
      this.translate.get('ICE_TOPIC.SAVING_MESSAGE').subscribe((res: string) => {
        loadingMessage = res;
      });
      this.loadingService.showLoading(true, null, loadingMessage, null);
      // Request
      var request = {
        "id" : this.topic.id,
        "topicName": "<ice>" + this.topic.topicName + "</ice>",
        "courseId": this.topic.courseId,
        "sequence": this.topic.sequence != null ? this.topic.sequence : 0,
        "topicParentId": this.topic.topicParentId,
        "topicContentParentId": this.topic.topicContentParentId,
        "graphicId": this.topic.topicImage != null ? this.topic.topicImage.id : null,
        "description": "<ice>" + this.topic.description + "</ice>",
        "courseVersion": this.topic.courseVersion != null ? this.topic.courseVersion : 0,
        "linkedCourseId": this.topic.linkedCourseId
      };
      if(request.id != "0"){
        // Update Topic
        console.log('[Update topic() was called]');
        
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        this.courseService.updateICETopic(JSON.stringify(request), token, this);
      }
      else{
        // Add New Topic
        console.log('[Create topic() was called]');
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        this.courseService.createICETopic(JSON.stringify(request), token, this);
      }
      //set topicModified false
      this.topicModified = false;
      this.sharedService.setModifiedStatus(this.tabId, this.topicModified)
    }
  }

  /**
	 * click event of link button
	 * @param AssociateCoursetemplate 
	*/
  addAssociateCourse(template: TemplateRef<any>){
    if(this.selectedConfirmed == false){
      this.selectedIceCourse = this.topic.linkedCourseId;
    }
    this.modalRef = this.modalService.show(template, this.modalConfig);
  }

  /**
	 * select a course from course list
	 * @param selectedCourseId 
	*/
  selectCourse(courseId: string){
    this.selectedConfirmed = false;
    if(this.selectedIceCourse == null){
      this.selectedIceCourse = courseId;
    } else{
      if( this.selectedIceCourse == courseId){
        this.selectedIceCourse == null;
      } else{
        this.selectedIceCourse = courseId;
      }
    }
    
  }

  // /**
	//  * confirm course association
	//  * OK button click event
	// */
  confirmSelected(){
    this.selectedConfirmed = true;
    this.topicModified = true;
    this.sharedService.setModifiedStatus(this.tabId, this.topicModified)
    this.topic.linkedCourseId = this.selectedIceCourse;
    this.iceCourseList.forEach((courseItem : ICECourse) => {
      if(courseItem.courseId == this.topic.linkedCourseId){
        //this.linkedCourse = courseItem;
        this.linkedCourse = courseItem.fileName;
      }
    });
  }

  /**
	 * clear selected course
	*/
  clearSelected(){
    this.linkedCourse = null;
    this.selectedIceCourse = null;
    this.topic.linkedCourseId = null;
    this.selectedConfirmed = false;
    this.topicModified = true;
    this.sharedService.setModifiedStatus(this.tabId, this.topicModified)
  }

  //save topic list under topic 
	topicsSave(showValidation) {
    
    //update topic name
    this.updateTopicName();
    
		if(this.iceTopicList.length > 0) {
			var request = { 
      "courseId":this.topic.courseId,
			"courseVersion":this.topic.courseVersion,
			"topics": this.iceTopicList};

			//show loading message
			var loadingMessage = null;
			this.translate.get('ICE_COURSE.SAVE_TOPICS_MESSAGE').subscribe((res: string) => {
				loadingMessage = res;
			});
			this.loadingService.showLoading(true, null, loadingMessage, null);

      let token = UserVariable.getUserToken();
      if(!this.commonUtil.validateToken(token)){
          return;
      }
			this.courseService.saveTopicList(JSON.stringify(request), token, this);
    
      //settopicsmodified false
      this.topicsModified = false;
      this.sharedService.setModifiedStatus(this.tabId, this.topicsModified )
    } else {
      if (showValidation == true) {
        this.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.NO_TOPICS_ERROR'));
      } else {
        //do not show
      }
    }
    if (showValidation == true) {
        this.loadtopiclist = true;
    } else {
      //navigate
    }
  }
  
  //save topic content list under topic 
	topicContentsSave(showValidation) {
    if(this.iceTopicContentList.length > 0) {

      //update topic content description
      this.updateTopicContentDescription();
      this.escapeBackslahesInTopicContentLayers();

			var request = { 
      "id":this.topic.id,
      "courseId":this.topic.courseId,
			"courseVersion":this.topic.courseVersion,
			"topiccontents": this.iceTopicContentList};

			//show loading message
			var loadingMessage = null;
			this.translate.get('ICE_TOPIC.SAVE_TOPICCONTENTS_MESSAGE').subscribe((res: string) => {
				loadingMessage = res;
			});
			this.loadingService.showLoading(true, null, loadingMessage, null);

      let token = UserVariable.getUserToken();
      if(!this.commonUtil.validateToken(token)){
          return;
      }
      this.courseService.saveTopicContentsList(JSON.stringify(request), token, this);
      //topicContentsModified false
      this.topicContentsModified = false;
      this.sharedService.setModifiedStatus(this.tabId,  this.topicContentsModified)
		} else {
      if (showValidation == true) {
        this.alertService.warn(this.translate.instant('ICE_TOPIC.NO_TOPICCONTENTS_ERROR'));
      } else  {
        //do not show
      }
		}
  }

  //Replace \ with \\ in blink layers and hidden layers of 3D models in topic contents
  escapeBackslahesInTopicContentLayers(){
    for(let i = 0; i < this.iceTopicContentList.length; i++){
      if(this.iceTopicContentList[i].layerState != null){
        this.iceTopicContentList[i].layerState = this.iceTopicContentList[i].layerState.replace(/\\/g, "\\\\");
      }
      if(this.iceTopicContentList[i].blinkLayerState != null){
        this.iceTopicContentList[i].blinkLayerState = this.iceTopicContentList[i].blinkLayerState.replace(/\\/g, "\\\\");
      }
    }
  }

  updateTopicContentDescription() {
    for(let i = 1; i <= this.iceTopicContentList.length; i++){
      if (this.iceTopicContentList[i - 1].isTopicContentModified == true) {
        this.xmlContent ="";
        //check if edior text is empty
        var editortext = $('.tc_ck_editor' + i + this.tabId).text();
        if(this.isNullOrWhitespace(editortext) == true) {
          this.iceTopicContentList[i - 1].description = '<ice></ice>';
        } else {
          //process description
          var desc = this.getEditorContent('.tc_ck_editor' + i + this.tabId);
          this.iceCKEditor.processXML(desc,false,this.xmlContent);
          this.xmlContent = this.iceCKEditor.getXMLContent();
          this.iceTopicContentList[i - 1].description = '<ice>'+ this.xmlContent + '</ice>';
        }
      } else {
        //not modified
      }
    }
  }

  updateTopicContentDescriptionAfterDelete() {
    for(let i = 1; i <= this.iceTopicContentList.length; i++){
      if (this.iceTopicContentList[i - 1].isTopicContentModified == true) {
        var editortext = this.getEditorContent('.tc_ck_editor' + i + this.tabId);
        this.iceTopicContentList[i - 1].description = editortext;
      } else {
        //not modified
      }
    }
  }

  updateTopicName() {
    for(let i = 1; i <= this.iceTopicList.length; i++){
        if (this.iceTopicList[i - 1].isTopicModified == true) {
          this.xmlContent ="";
          //check if edior text is empty
          var editortext = $('.t_ck_editor' + i + this.tabId ).text();
          if(this.isNullOrWhitespace(editortext) == true) {
            this.iceTopicList[i - 1].topicName = '<ice></ice>';
          } else {
            //process name
            var name = this.getEditorContent('.t_ck_editor' + i + this.tabId);
            this.iceCKEditor.processXML(name,false,this.xmlContent);
            this.xmlContent = this.iceCKEditor.getXMLContent();
            this.iceTopicList[i - 1].topicName = '<ice>'+ this.xmlContent + '</ice>';
          }
      } else {
        //topic not modified 
      }
    }
  }

  updateTopicNameAfterDelete() {
    this.iceTopicList = this.sharedService.getTopicList();
    for(let i = 1; i <= this.iceTopicList.length; i++){
      if (this.iceTopicList[i - 1].isTopicModified == true) {
				this.xmlContent ="";
				var editorcontent = this.getEditorContent('.t_ck_editor' + i + this.tabId);
				this.iceTopicList[i - 1].topicName = editorcontent;
			} else {
				//topic not modified 
			}
    }
  }
  
  // Topic
  public addNewIceTopic() {
    //show loading
    var loadingMessage = null;
    this.translate.get('ICE_TOPIC.SAVING_MESSAGE').subscribe((res: string) => {
      loadingMessage = res;
    });
    this.loadingService.showLoading(true, null, loadingMessage, null);
    let self = this;
    //check ice topic contents before adding an ice topic
   this.courseService.getAllICETopicContentsByParentTopicId(this.topic.id, UserVariable.getUserToken()).subscribe(data=>{
    this.loadICETopicContentList(data.payload, false);
    if (this.iceTopicContentList.length > 0) {
      self.alert.warn(self.translate.instant('ICE_TOPIC.TOPIC.TOPIC_OR_TC_ERROR'), false);
      return;
    } else {
        if (self.isShowIceTopic == true) {
          //do nothing
        } else {
          self.isShowIceTopic = true;
          setTimeout(() => {
            self.setEditor(CK_Editor_Type.TOPIC_EDITOR, "");
          }, 200);
        }
        //set topic modified
        this.topicsModified = true;
        this.sharedService.setModifiedStatus(this.tabId, this.topicsModified )
        console.log('[addNewIceTopic]');
        let listLength: number = this.iceTopicList.length;

        this.newTopic = {
          id:'0',
          topicName: "<ice><p>New Topic Menu</p></ice>",
          courseId: this.topic.courseId,
          topicParentId: this.topic.id,
          sequence: (listLength + 1),
          description:"<ice></ice>",
          courseVersion: + this.topic.courseVersion,
          isTopicModified:true
        }

        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        this.courseService.createICETopic(JSON.stringify(this.newTopic), token, this);
        
        /*this.iceTopicList.push(newTopic);
        setTimeout(() => {
          this.focusNewEditor('.t_ck_editor'+ (listLength + 1), true);
        }, 200);*/

        // Handling visbility of divs
        this.isShowIceTopic = true;
        this.isShowIceTopicDetails = false;
        this.isShowIceTopicContents = false;
        this.isShowLearningChecks = false;
        this.isShowIceGames = false;
    }
   });
  }

  public showInfoIceTopic(iceTopic: IceTopic){
    if (this.topicModified == true) {
			this.alertService.warn(this.translate.instant('ICE_TOPIC.TOPIC.TOPIC_SAVE'));
		} else if (this.learningChecksModified == true) {
			this.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_LEARNINGCHECKS'));
		} else if (this.topicContentsModified == true) {
			this.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_TOPIC_CONTENTS'));
		} else if (this.gameModified == true) {
      this.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_GAMES'));
    } else {
      this.navigateToT = true;
      this.navigateToParent = false;
      this.iceTopicData = iceTopic;
      if (this.isViewOnly == true|| this.topicsModified == false) {
        //do not save
        iceTopic.topicParentId = this.topic.id;
        iceTopic.parentType = "topic";
        iceTopic.parent = this.topic;
        let data = {
          courseId  : this.topic.courseId,
          iceTopic : iceTopic	,
          viewOnly :this.isViewOnly,
        }

      var html = this.topic.topicNameHTML;
      var div = document.createElement("div");
      div.innerHTML = html;
      var text = div.textContent || div.innerText || "";
        let data2 = {
          courseId  : this.topic.courseId,
          iceTopic : this.topic	,
          viewOnly :this.isViewOnly,
          iceType : 'topic',
          title : text
        }
        this.sharedService.changeIceItemRequest('topic', data);
        this.sharedService.setICEBreadCrumb(this.topic.courseId, data2);
      } else {
        //auto save topics
        this.topicsSave(false);
      }

      console.log('[showInfoIceTopic] (topic) ' + JSON.stringify(iceTopic));
   }
    
  }

  public showIceTopicContents(isShowIceTopicContents) {

    var self = this;

    if (isShowIceTopicContents == true) {
      if (self.topicContentsModified == true) {
        self.alertService.warn(self.translate.instant('ICE_COURSE.COURSE.SAVE_TOPIC_CONTENTS'));
      } else {
        self.isShowIceTopicContents = false
      }
    } else {
       //remove unsaved topic contents
      console.log("self.iceTopicContentList.length"+self.iceTopicContentList.length);
      self.iceTopicContentList = self.iceTopicContentList.filter(function(a){
        return a.description != undefined;
      });
      self.isShowIceTopicContents = true;
      setTimeout(() => {
        self.setEditor(CK_Editor_Type.TOPIC_CONTENT_EDITOR, "");
      }, 200);
    }
  }

  public deleteIceTopic(iceTopic: IceTopic, index: number, template: TemplateRef<any>){
    console.log('[deleteIceTopic]');
    if (this.topicsModified == false || (this.topicsModified == true && this.iceTopicList.length == 1)) {
      //process topic list before delete
      this.processTopicList();
      if(iceTopic.id != '0'){
        //show loading message
        var loadingMessage = null;
        this.translate.get('LOADING').subscribe((res: string) => {
          loadingMessage = res;
        });
        this.loadingService.showLoading(true, null, loadingMessage, null);
        this.sharedService.setFileId(iceTopic.id);
        this.sharedService.setFileType("ICETopic");
        this.sharedService.setAction("getDependencies");
        this.sharedService.setCourseId(iceTopic.courseId);
        this.sharedService.setDependencyMessageType("Delete");
        this.sharedService.setIsICETopic(true);
        localStorage.setItem("isDelete", "true");
        
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
          return;
        }
        //this.courseService.viewDependenciesForTopic(iceTopic.id, token, this);
        //debugger;
        // this.dependenciesService.getDependecies(iceTopic.id, "ICETopic", "get dependencies")
        // this.topicDependencies = 0;
        // this.topicContentDependencies = 0;
        // this.isDeleteEnable = true;
        this.topicToDelete = iceTopic;
        this.sharedService.setDeleteTopicIndex(index);
        // this.modalRef = this.modalService.show(template, this.modalConfig);
        this.modalRef = this.modalService.show(DependenciesmodalComponent, { class: 'modal-lg' });
      }
      else{
        this.deleteItemFromTopicList(index);     
      }
    } else {
      this.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_TOPICS_BEFORE_DELETE'));
    }
  }

  //process topic list before delete a topic 
	processTopicList() {
		this.courseService.getAllICETopicsByTopicId(this.topic.id, UserVariable.getUserToken(), this); 				
  }
  
  public confirmDeleteTopic(){
    // Delete Topic
    console.log('[Delete topic() was called]');
    //show loading message
    var loadingMessage = null;
    this.translate.get('ICE_TOPIC.TOPIC_DELETING_MESSAGE').subscribe((res: string) => {
      loadingMessage = res;
    });

    var request = {
      "id": this.topicToDelete.id,
      "courseId": this.topicToDelete.courseId,
      "topicParentId": this.topicToDelete.topicParentId,
      "sequence": this.topicToDelete.sequence
    }
    this.loadingService.showLoading(true, null, loadingMessage, null);
    console.log(JSON.stringify(this.topicToDelete.id));
    
    let token = UserVariable.getUserToken();
    if(!this.commonUtil.validateToken(token)){
        return;
    }
    this.courseService.deleteICETopic(JSON.stringify(request),this.topicToDelete.id, token, this);
  }

  public changePositionIceTopic(topic: IceTopic, index: number, dir: string){
    this.isEnableReordering = false;
    console.log('[changePositionIceTopic] (iceTopic/dir) ' + JSON.stringify(topic) + '/' + dir);
    this.iceTopicList.splice(index, 1);
    if(dir == "up"){   
      let iceTopic:IceTopic = this.iceTopicList[index-1];
      this.iceTopicList.splice(index-1, 0, topic);
      if(iceTopic.id != "0"){
        // Request
        var request = {
          "id": iceTopic.id,
          "courseId": iceTopic.courseId,
          "topicParentId":iceTopic.topicParentId,
          "sequence": iceTopic.sequence
        };
        console.log(JSON.stringify(request));
        this.iceTopicList[index-1].sequence -= 1;
        this.iceTopicList[index].sequence += 1;
                
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        this.courseService.reorderICETopic(JSON.stringify(request), token, this);
      }
      else{
        // nothing to do here
      }
      
    }
    else if(dir == "down"){
      this.iceTopicList.splice(index+1, 0, topic);
      if(topic.id != "0"){
        // Request
        var request = {
          "id": topic.id,
          "courseId": topic.courseId,
          "topicParentId": topic.topicParentId,
          "sequence": topic.sequence
        };
        this.iceTopicList[index].sequence -= 1;
        this.iceTopicList[index+1].sequence += 1;
        
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        this.courseService.reorderICETopic(JSON.stringify(request), token, this);
      }
      else{
        // nothing to do here
      }      
    }
    else{
      // nothing to do here
    }
  }
  
  public copyOfIceTopic(iceTopic){
    console.log('[copyOfIceTopic] ' + JSON.stringify(iceTopic));
  }

  
  // Topic Content
  public addNewIceTopicContent() {
    //show loading
    var loadingMessage = null;
    this.translate.get('ICE_TOPIC_CONTENT.SAVING_MESSAGE').subscribe((res: string) => {
      loadingMessage = res;
    });
    this.loadingService.showLoading(true, null, loadingMessage, null);
    let self = this;
    //check ice topics before adding an ice topic content
    this.courseService.getAllICETopicsByParentTopicId(this.topic.id, UserVariable.getUserToken()).subscribe(data=>{
      this.loadtopiclist = false;
      this.loadTopicDetails(data.payload, false);
      if (this.iceTopicList.length > 0) {
        self.alert.warn(self.translate.instant('ICE_TOPIC.TOPIC.TOPIC_OR_TC_ERROR'), false);
        return;
      } else {
        if (self.isShowIceTopicContents == true) {
          //do nothing
        } else {
          self.isShowIceTopicContents = true;
          setTimeout(() => {
            self.setEditor(CK_Editor_Type.TOPIC_CONTENT_EDITOR, "");
          }, 200);
        }
        //set topic modified
        this.topicContentsModified = true;
        this.sharedService.setModifiedStatus(this.tabId,  this.topicContentsModified)
        console.log('[addNewIceTopicContent]');    
        let listLength: number = this.iceTopicContentList.length;
  
        this.newTopicContent = {
          id:'0',
          topicId: this.topic.id,
          courseId:this.topic.courseId,
          description:"<ice><p>New Bullet</p></ice>",
          sequence: (listLength + 1),
          courseVersion: this.topic.courseVersion,
          isTopicContentModified:true
        }    
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        //add new topic content
        this.courseService.createICETopicContent(JSON.stringify(this.newTopicContent), token, this);
  
        /*this.iceTopicContentList.push(newTopicContent);
        setTimeout(() => {
          this.focusNewEditor('.tc_ck_editor'+ (listLength + 1), false);
        }, 200);*/
  
        // Handling visbility of divs
        this.isShowIceTopicContents = true;
        this.isShowIceTopicDetails = false;
        this.isShowIceTopic = false;
        this.isShowLearningChecks = false;
        this.isShowIceGames = false;
      }
    });
  }

  public showInfoIceTopicContent(topicContent: IceTopicContent){
    if (this.topicModified == true) {
			this.alertService.warn(this.translate.instant('ICE_TOPIC.TOPIC.TOPIC_SAVE'));
		} else if (this.topicsModified == true) {
			this.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_TOPICS'));
		} else if (this.learningChecksModified == true) {
			this.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_LEARNINGCHECKS'));
		} else if (this.gameModified == true) {
      this.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_GAMES'));
    } else {
      this.topicContentData = topicContent;
      this.navigateToTC = true;
      this.navigateToParent = false;
      if (this.isViewOnly == true|| this.topicContentsModified == false) {
        //do not save
        let data = {
          courseId  : this.topic.courseId,
          iceTopicContent : this.topicContentData,
          iceTopic        :	this.topic,
          viewOnly        :this.isViewOnly
        }

      var html = this.topic.topicNameHTML;
      var div = document.createElement("div");
      div.innerHTML = html;
      var text = div.textContent || div.innerText || "";
      let data2 = {
        courseId  : this.topic.courseId,
        iceTopic  :	this.topic,
        viewOnly :this.isViewOnly,
        iceType : 'topic',
        title : text
      }
      this.sharedService.changeIceItemRequest('topicContent', data);
      this.sharedService.setICEBreadCrumb(this.topic.courseId, data2);
    } else {
      //auto save topics
      this.topicContentsSave(false);
      }
    }
  }

  public deleteIceTopicContent(iceTopicContent: IceTopicContent, index: number, template: TemplateRef<any>){
    console.log('[deleteIceTopicContent]');
    if(iceTopicContent.id != '0'){      
      this.modalRef = this.modalService.show(template, this.modalConfig);
      this.deleteIceTopicContentId = iceTopicContent.id ;
      this.deleteIceTopicContentIndex = index ;
    }
    else{
      this.iceTopicContentList.splice(index,1);
      if(this.iceTopicContentList.length == 0){
        this.isShowIceTopicContents = false;
      }
      else{
          // let i:number = index;
          // while(i< this.iceTopicContentList.length){
          //   this.iceTopicContentList[i].sequence -= 1;
          // }
        
      }
    }
  }

  public confirmDeleteTopicContent(){
    var loadingMessage = null;
    this.translate.get('ICE_TOPIC_CONTENT.TOPIC_CONTENT_DELETING_MESSAGE').subscribe((res: string) => {
      loadingMessage = res;
    });
    this.loadingService.showLoading(true, null, loadingMessage, null);
    if(this.deleteIceTopicContentId != "0" && this.deleteIceTopicContentIndex != null){
      console.log('[deleteIcetopicContetn() was called]');
      var request = {
        "id":this.iceTopicContentList[this.deleteIceTopicContentIndex].id,
        "topicContentName":this.iceTopicContentList[this.deleteIceTopicContentIndex].topicContentName,	
        "topicId": this.iceTopicContentList[this.deleteIceTopicContentIndex].topicId,
        "courseId": this.iceTopicContentList[this.deleteIceTopicContentIndex].courseId,	
        "description":this.iceTopicContentList[this.deleteIceTopicContentIndex].description,
        "graphicId":this.iceTopicContentList[this.deleteIceTopicContentIndex].topicContentImage != null ? this.iceTopicContentList[this.deleteIceTopicContentIndex].topicContentImage.id : null, 		
        "sequence": this.iceTopicContentList[this.deleteIceTopicContentIndex].sequence,
        "courseVersion": 0
      };
      if(request.id !="0"){
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        this.courseService.deleteICETopicContent(JSON.stringify(request),this.deleteIceTopicContentId, token, this);
      }        
    }else{
       //do nothing
    }
  }

  public changePositionIceTopicContent(iceTopicContent: IceTopicContent, dir: string, index:number){
    console.log('[changePositionIceTopicContent] (iceTopicContent/dir) ' + JSON.stringify(iceTopicContent) + '/' + dir);
    this.iceTopicContentList.splice(index,1);
    this.isShowIceTopicContentReorderButton = true;
    if(dir == "up"){

        var request1 = {
          "id":this.iceTopicContentList[index-1].id,
          "topicContentName":this.iceTopicContentList[index-1].topicContentName,	
          "topicId": this.iceTopicContentList[index-1].topicId,
          "courseId": this.iceTopicContentList[index-1].courseId,	
          "description":this.iceTopicContentList[index-1].description,
          "graphicId":this.iceTopicContentList[index-1].topicContentImage != null ? this.iceTopicContentList[index-1].topicContentImage.id : null, 		
          "sequence": this.iceTopicContentList[index-1].sequence,
          "courseVersion": 0
        };
        if(request1.id !="0"){
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
          this.courseService.reorderICETopicContent(JSON.stringify(request1), token, this); 
        }
        this.iceTopicContentList.splice(index-1, 0, iceTopicContent); 
        this.iceTopicContentList[index-1].sequence = this.iceTopicContentList[index-1].sequence - 1; 
        this.iceTopicContentList[index].sequence = this.iceTopicContentList[index].sequence + 1; 
    }else if(dir=="down"){

        var request2 = {
          "id":iceTopicContent.id,
          "topicContentName":iceTopicContent.topicContentName,	
          "topicId": iceTopicContent.topicId,
          "courseId": iceTopicContent.courseId,	
          "description":iceTopicContent.description,
          "graphicId":iceTopicContent.topicContentImage != null ? iceTopicContent.topicContentImage.id : null, 		
          "sequence": iceTopicContent.sequence,
          "courseVersion": 0
        };
        if(request2.id !="0"){
          let token = UserVariable.getUserToken();
          if(!this.commonUtil.validateToken(token)){
              return;
          }
          this.courseService.reorderICETopicContent(JSON.stringify(request2), token, this); 
        }
        this.iceTopicContentList.splice(index+1, 0, iceTopicContent); 
        this.iceTopicContentList[index+1].sequence = this.iceTopicContentList[index+1].sequence + 1; 
        this.iceTopicContentList[index].sequence = this.iceTopicContentList[index].sequence - 1; 
    }else{ 
      //nothing to do
    }
  }
  
  public copyOfIceTopicContent(iceTopicContent: IceTopicContent){
    console.log('[copyOfIceTopicContent] ' + JSON.stringify(iceTopicContent));
  }

  //learning checks

  public addNewLearningCheck() {
    //show loading
    var loadingMessage = null;
    this.translate.get('ICE_LEARNING_CHECK.SAVING_MESSAGE').subscribe((res: string) => {
      loadingMessage = res;
    });
    this.loadingService.showLoading(true, null, loadingMessage, null);
   
    let self = this;
    if (self.isShowLearningChecks == true) {
       //do nothing
    } else {
      self.isShowLearningChecks = true;
      setTimeout(() => {
        self.setEditor(CK_Editor_Type.LEARNING_CHECK_EDITOR, "");
      }, 200);
     }
   
    //set lc modified
    this.learningChecksModified = true;
    this.sharedService.setModifiedStatus(this.tabId, this.learningChecksModified )
    console.log('[addNewIce LC]');
    let listLength: number = this.iceLearningCheckList.length;
   
    this.newLearningCheck = {
      id:'0',
      sequence: (listLength + 1),
      courseId: this.topic.courseId,
      topicId: this.topic.id,
      description: "<ice><p>New Learning Check</p></ice>",
      courseVersion: + this.topic.courseVersion,
      isLearningCheckModified:true
    }
    this.courseService.createLearningCheck(JSON.stringify(this.newLearningCheck), UserVariable.getUserToken(), this);
   
    // Handling visbility of divs
    this.isShowLearningChecks = true;
    this.isShowIceTopic = false;
    this.isShowIceTopicDetails = false;
    this.isShowIceTopicContents = false;
    this.isShowIceGames = false;
    }

  //edit learning checks
  editLearningChecks(){
    let self = this;
    self.activeEditor = CK_Editor_Type.LEARNING_CHECK_EDITOR;
    if (self.topicModified == true) {
      self.alertService.warn(self.translate.instant('ICE_TOPIC.TOPIC.TOPIC_SAVE'));
    } else if (self.topicsModified == true) {
      self.alertService.warn(self.translate.instant('ICE_COURSE.COURSE.SAVE_TOPICS'));
    } else if (self.topicContentsModified == true) {
      self.alertService.warn(self.translate.instant('ICE_COURSE.COURSE.SAVE_TOPIC_CONTENTS'));
    } else if (this.gameModified == true) {
      this.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_GAMES'));
    } else {
      self.learningCheckEnabled = true;
      self.topicsEnabled = false;
      self.topicEnabled = false;
      self.topicContentsEnabled = false;
      self.gameEnabled = false;
      this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'topic', false, 'topicEnabled');
			this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'topic', false, 'topicsEnabled');
			this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'topic', false, 'topicContentsEnabled');
			this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'topic', true, 'learningCheckEnabled');
			this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'topic', false, 'gameEnabled');
      //destroy editors
      if (self.titleditor) {
        self.titleditor.destroy();
      } else {
          //no editor
      }
      if (self.desceditor) {
        self.desceditor.destroy();
      } else {
         //no editor
      }
        
      self.isShowLearningChecks = true;
      setTimeout(() => {
        self.setEditor(CK_Editor_Type.LEARNING_CHECK_EDITOR, "");
      }, 200);
  
      //collapse other sections
      self.isShowIceTopic = false;
      self.isShowIceTopicDetails = false;
      self.isShowIceTopicContents = false;
      }
  
    }

  // show ice learning checks
    public showICELearningCheck(isShowLearningChecks){
      let self = this;
      if (isShowLearningChecks == true) {
        if (self.learningChecksModified == true) {
          self.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_LEARNINGCHECKS'));
        } else {
          self.isShowLearningChecks = false;
        }
      } else {
        //remove unsaved lcs 
        console.log("self.iceLearningCheckList.length"+self.iceLearningCheckList.length);
        self.iceLearningCheckList = self.iceLearningCheckList.filter(function(a){
          return a.description != undefined;
        });
        self.isShowLearningChecks = true;
        setTimeout(() => {
          self.setEditor(CK_Editor_Type.LEARNING_CHECK_EDITOR, "");
        }, 200);
        
      }
    }

  //save learning checks
  public learningChecksSave(showValidation){

    //update lc description
    this.updateLearningCheckDiscription();
    
    if(this.iceLearningCheckList.length > 0) {
      var request = { 
      "courseId":this.topic.courseId,
      "courseVersion":this.topic.courseVersion,
      "learningChecks": this.iceLearningCheckList};
  
      //show loading message
      var loadingMessage = null;
      this.translate.get('ICE_COURSE.SAVE_LEARNING_CHECKS_MESSAGE').subscribe((res: string) => {
      loadingMessage = res;
      });
      this.loadingService.showLoading(true, null, loadingMessage, null);
      this.courseService.saveLearningCheckList(JSON.stringify(request), UserVariable.getUserToken(), this);
      
      //sett lcsmodified false
        this.learningChecksModified = false;
        this.sharedService.setModifiedStatus(this.tabId, this.learningChecksModified )
      }else {
        if (showValidation == true) {
          this.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.NO_LEARNINGCHECK_ERROR'));
        } else {
          //do not show
        }
      }
      if (showValidation == true) {
        this.loadLearningChecklist = true;
      } else {
        //navigate
      }
  }

  updateLearningCheckDiscription() {
    for(let i = 1; i <= this.iceLearningCheckList.length; i++){
      if(this.iceLearningCheckList[i - 1].isLearningCheckModified == true){
        this.xmlContent ="";
        //check if edior text is empty
        var editortext = $('.l_ck_editor' + i + this.tabId ).text();
        if(this.isNullOrWhitespace(editortext) == true) {
          this.iceLearningCheckList[i - 1].description = '<ice></ice>';
        } else {
          //process name
          var name = this.getEditorContent('.l_ck_editor' + i + this.tabId);
          this.iceCKEditor.processXML(name,false,this.xmlContent);
          this.xmlContent = this.iceCKEditor.getXMLContent();
          this.iceLearningCheckList[i - 1].description = '<ice>'+ this.xmlContent + '</ice>';
        }
      }
    }
  }

  /**
	 * delete ice lc , view depencies
	 * @param IceLearningCheck 
	 * @param index
	 */
	public deleteIceLearningCheck(learningCheck: IceLearningCheck, index: number, template: TemplateRef<any>) {
		console.log('[deleteIceLearningCheck]');
		if(learningCheck.id != '0'){
			//show loading message
			var loadingMessage = null;
			this.translate.get('LOADING').subscribe((res: string) => {
			  loadingMessage = res;
			});
			this.loadingService.showLoading(true, null, loadingMessage, null);
			this.courseService.viewDependenciesForLearningCheck(learningCheck.id, UserVariable.getUserToken(), this);
	
			this.questionDependencies = '';
			this.isLCDeleteEnable = true;
			this.learningCheckToDelete = learningCheck;
			this.indexOfLearningCheckToDelete = index;
			this.modalRef = this.modalService.show(template, this.modalConfig);
		} else{
			this.deleteItemFromLearningCheckList(index); //no need 
		}
	}

	// remove learning check from the list
	deleteItemFromLearningCheckList(index: number){
		this.iceLearningCheckList.splice(index,1);
		  if(this.iceLearningCheckList.length == 0){
        this.isShowLearningChecks = false;
        this.haveChildItems = false;
		  }
		  else{
        this.iceLearningCheckList.forEach((learningCheckItem: IceLearningCheck) => {
          if(index < learningCheckItem.sequence){
            learningCheckItem.sequence -= 1;
          }
        });
		  }
    }
    
  //delete LC
	public confirmDeleteLearningChecks(){
		console.log('[Delete learningcheck() was called]');
		//show loading message
		var loadingMessage = null;
		this.translate.get('ICE_LEARNING_CHECK.LEARNING_CHECK_DELETING_MESSAGE').subscribe((res: string) => {
		  loadingMessage = res;
		});
		var request = {
			"id": this.learningCheckToDelete.id,
			"courseId": this.learningCheckToDelete.courseId,
			"topicId": this.learningCheckToDelete.topicId,
			"topicContentId": this.learningCheckToDelete.topicContentId,
			"sequence": this.learningCheckToDelete.sequence
		  }
		this.loadingService.showLoading(true, null, loadingMessage, null);
		console.log(JSON.stringify(this.learningCheckToDelete.id));
		this.courseService.deleteICELearningCheck(JSON.stringify(request), this.learningCheckToDelete.id, UserVariable.getUserToken(), this);
	  }

  //reoder ice LCs
  public changePositionLearningCheck(learningCheck: IceLearningCheck, index: number, dir: string){
    this.isEnableReordering = false;
    console.log('[changePositionLearningCheck] (iceLearningChecks/dir) ' + JSON.stringify(learningCheck) + '/' + dir);
    this.iceLearningCheckList.splice(index, 1);
    if(dir == "up"){    
      let iceLearningCheck:IceLearningCheck = this.iceLearningCheckList[index-1];
      this.iceLearningCheckList.splice(index-1, 0, learningCheck);
      if(iceLearningCheck.id != "0"){
        // Request
        var request = {
					"id" : iceLearningCheck.id,
					"courseId" : iceLearningCheck.courseId,
					"topicId" : iceLearningCheck.topicId,
					"topicContentId" : iceLearningCheck.topicContentId,
					"sequence" : iceLearningCheck.sequence
				};
        console.log(JSON.stringify(request));
        this.iceLearningCheckList[index-1].sequence -= 1;
        this.iceLearningCheckList[index].sequence += 1;
        this.courseService.reorderICELearningChecks(JSON.stringify(request), UserVariable.getUserToken(), this);
      }
      else{
        // nothing to do here
      }
      
    }
    else if(dir == "down"){
      this.iceLearningCheckList.splice(index+1, 0, learningCheck);
      if(learningCheck.id != "0"){
        // Request
        var request = {
					"id" : learningCheck.id,
					"courseId" : learningCheck.courseId,
					"topicId" : learningCheck.topicId,
					"topicContentId" : learningCheck.topicContentId,
					"sequence" : learningCheck.sequence
				};
        this.iceLearningCheckList[index].sequence -= 1;
        this.iceLearningCheckList[index+1].sequence += 1;
        this.courseService.reorderICELearningChecks(JSON.stringify(request), UserVariable.getUserToken(), this);
      }
      else{
        // nothing to do here
      }      
    }
    else{
      // nothing to do here
    }
  }

	/**
	 * show more info button click 
	 * arrow right button click event
	 * @param learningCheck 
	 */
	public showInfoIceLearningCheck(IceLearningCheck: IceLearningCheck, questionId: string) {
    if (this.topicModified == true) {
			this.alertService.warn(this.translate.instant('ICE_TOPIC.TOPIC.TOPIC_SAVE'));
		} else if (this.topicContentsModified == true) {
			this.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_TOPIC_CONTENTS'));
		} else if (this.topicsModified == true) {
			this.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_TOPICS'));
		} else if (this.gameModified == true) {
      this.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_GAMES'));
    } else {
			this.navigateToT = true;
      this.LearningCheckData = IceLearningCheck;
      if(this.LearningCheckData.descriptionHTML == undefined){
				let el = this.elementRef.nativeElement.querySelector('.l_ck_editor' + IceLearningCheck.sequence + this.tabId);
				this.LearningCheckData.descriptionHTML = el.innerHTML; 
			}
			if (this.isViewOnly == true || this.learningChecksModified == false) {
				this.LearningCheckData.topicId = this.topic.id;
				this.LearningCheckData.parentType = 'topic';
        this.LearningCheckData.parent = this.topic; 
        if( questionId == 'null'){
          let data = {
            courseId  : this.topic.courseId,
            iceLearningCheck  : this.LearningCheckData,
            viewOnly  : this.isViewOnly,
            iceType: 'IceLearningCheck',
            quickSelectQuestionId: null
          }
          this.sharedService.changeIceItemRequest('IceLearningCheck', data);
        } else{
          let data = {
            courseId  : this.topic.courseId,
            iceLearningCheck  : this.LearningCheckData,
            viewOnly  : this.isViewOnly,
            iceType: 'IceLearningCheck',
            quickSelectQuestionId: questionId
          }
          this.sharedService.changeIceItemRequest('IceLearningCheck', data);
        }
				var html = this.topic.topicNameHTML;
				var div = document.createElement("div");
				div.innerHTML = html;
				var text = div.textContent || div.innerText || div.style || "";
				let data2 = {
					courseId  : this.topic.courseId,
					iceTopic : this.topic,
					viewOnly : this.isViewOnly,
					iceType : 'topic',
					title: text,
					id: this.topic.id,
        }
				this.sharedService.setICEBreadCrumb(this.topic.courseId, data2);
				//do not save
			} else {
        //auto save LC
				this.learningChecksSave(false);
			}

			console.log('[showInfoIceLearningCheck] (learningCheck) ' + JSON.stringify(IceLearningCheck));
		}
  }

  // copy learning check from a checked in course
	copyLearningCheck(template: TemplateRef<any>){
    if (this.learningChecksModified) {
			this.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_LEARNINGCHECKS'));
		} else {
		  this.goBack();
      this.modalRef = this.modalService.show(template, this.modalConfig);
    }
	}

	// select checkedin course from list
	selectCMCourse(courseId: string){
		this.selectedConfirmed = false;
		if(this.selectedCMIceCourse == null){
			this.selectedCMIceCourse = courseId;
		} else{
			this.selectedCMIceCourse = courseId;
		}
	}

	// copy lc modal OK button click event
	confirmSelectedCM(){
		if(this.iceCMCoursesAvailable == true){
			this.selectedConfirmed = true;
			this.iceCMCourseList.forEach((cmCourseItem: any) => {
				if(cmCourseItem.id == this.selectedCMIceCourse){
					this.getCMLearningCheckData = true;
					this.selectedCMIceCourseVersion = cmCourseItem.courseVersion;
				}
			});
			if(this.getCMLearningCheckData == true){
				var request = {
					"id": this.selectedCMIceCourse,
					"courseVersion": this.selectedCMIceCourseVersion
				}
				this.courseService.getCMLearningCheckList(JSON.stringify(request), UserVariable.getUserToken(), this);
			}
		} else if(this.cmLearningCheckListAvailable == true){
			this.iceCMLearningCheckList.forEach((cmLearningCheckItem: any)=> {
				if(cmLearningCheckItem.id == this.selectedCMLearningCheck){
					this.copyLearningCheckData = true;
				}
			});
			if(this.copyLearningCheckData == true){
				this.iceCMCourseList.forEach((courseItem: any)=>{
					if(courseItem.id == this.selectedCMIceCourse){
						this.selectedCourseVersion = courseItem.courseVersion
					}
				});

				var request2 = {
					"id": this.selectedCMLearningCheck,
					"courseId": this.selectedCMIceCourse,
					"courseVersion": this.selectedCourseVersion,
          "sequence": this.iceLearningCheckList.length,
          "topicId": this.topic.id
				}
				this.courseService.copyLearningCheckByLearningcheckId(this.topic.courseId, JSON.stringify(request2), UserVariable.getUserToken(), this);
				this.modalRef.hide();
			}
		}
	}

	goBack(){
		this.iceCMCoursesAvailable = true;
		this.cmLearningCheckListAvailable = false;
    this.isLearningCheckDesc = false;
		this.isLearningCheckList = true;
	}

	selectLearningCheck(learningCheckId: string){
		this.selectedCMLearningCheck = learningCheckId;
  }

  // copy learning check from a checked in course
	copyGame(template: TemplateRef<any>){
    if (this.gameModified == true) {
			this.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_GAMES'));
		} else {
		  this.back();
      this.modalRef = this.modalService.show(template, this.modalConfig);
    }
  }
  
  back(){
		this.iceCMCoursesAvailable = true;
		this.cmGameListAvailable = false;
    this.isGameDesc = false;
		this.isGameList = true;
	}
  
  selectGame(gameId: string){
		this.selectedCMGame = gameId;
  }
  
  confirmSelectedItem(){
		if(this.iceCMCoursesAvailable == true){
			this.selectedConfirmed = true;
			this.iceCMCourseList.forEach((cmCourseItem: any) => {
				if(cmCourseItem.id == this.selectedCMIceCourse){
					this.getCMGameData = true;
					this.selectedCMIceCourseVersion = cmCourseItem.courseVersion;
				}
			});
			if(this.getCMGameData == true){
				var request = {
					"id": this.selectedCMIceCourse,
					"courseVersion": this.selectedCMIceCourseVersion
				}
				this.courseService.getCMGameList(JSON.stringify(request), UserVariable.getUserToken(), this);
			}
		} else if(this.cmGameListAvailable == true){
			this.iceCMGameList.forEach((cmGameItem: any)=> {
				if(cmGameItem.id == this.selectedCMGame){
					this.copyGameData = true;
				}
			});
			if(this.copyGameData == true){
				this.iceCMCourseList.forEach((courseItem: any)=>{
					if(courseItem.id == this.selectedCMIceCourse){
						this.selectedCourseVersion = courseItem.courseVersion
					}
				});
				var request2 = {
					"id": this.selectedCMGame,
					"courseId": this.selectedCMIceCourse,
          "courseVersion": this.selectedCourseVersion,
          "topicId": this.topic.id
					//"sequence": this.iceGameList.length
				}
				this.courseService.copyGameByCMGameId(this.topic.courseId, JSON.stringify(request2), UserVariable.getUserToken(), this);
				this.modalRef.hide();
			}
		}
	}
  
  // // ICE Game
  // public addNewIceGame(gameType: string){
  //   console.log('[addNewIceGame] (gameType) ' + gameType);
	// 	let random = Math.floor(Math.random() * (999999 - 100000)) + 100000;
	// 	let newGame: IceGame = {
	// 		id: JSON.stringify(random), description: '', gameType: gameType
	// 	};
	// 	this.iceGameList.push(newGame);
  // }

  /**
	 * add button click event in ice game list
	 * add new game
	 * @param gameType 
	 */
	public addNewIceGame(gameType: string) {
		let self = this;
		if (self.isShowIceGames == true) {
			//do nothing
		} else {
			this.isShowIceGames = true;
			setTimeout(() => {
				self.setEditor(CK_Editor_Type.GAME_EDITOR, "");
			}, 200);
		}
		//set game modified
		this.selectedGameType = gameType;
		this.gameModified = true;
		this.sharedService.setModifiedStatus(this.tabId, this.gameModified)
		let listLength: number = this.iceGameList.length;
		//show loading
		var loadingMessage = null;
      	this.translate.get('ICE_GAME.SAVING_MESSAGE').subscribe((res: string) => {
        	loadingMessage = res;
		  });
    this.loadingService.showLoading(true, null, loadingMessage, null);
    let gameString = this.getStringFromType(gameType);
    //to get gameName
    let gameNameString = this.getGameNameFromType(gameType);
		if (this.selectedGameType == 'MG') {
        gameString = "New Memory Game";
		}
		if (this.selectedGameType == 'SMSG') {
        gameString = "New Speed Match Game";
		}
		if (this.selectedGameType == 'EIG') {
        gameString = "New Einstein Game";
		}
		if (this.selectedGameType == 'WAMG') {
        gameString = "New Whack a Mole Game";
		}
		// Request
		this.newGame = {
			id: '0',
			courseId: this.topic.courseId,
			gameName: gameNameString,
			description: "<ice><p>"+gameString+"</p></ice>",
			isGameModified: true,
      gameType: gameType,
      topicId: this.topic.id,
      courseVersion: +this.topic.courseVersion
		}
		this.courseService.createICEGame(JSON.stringify(this.newGame), UserVariable.getUserToken(), this);
		this.isShowLearningChecks = false;
		this.isShowIceGames = true;
		this.isShowIceTopic = false;
	}

	// On expand button click show ICE Game by Course Id
	showICEGames(isShowIceGames) {
		//get glossary details 
		let self = this;
		if (isShowIceGames == true) {
			if(self.gameModified == true) {
				self.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_GAMES'));
			} else {
				self.isShowIceGames = false;
			}
		} else {
			// Loading message
			var loadingMessage = null;
			this.translate.get('LOADING').subscribe((res: string) => {
				loadingMessage = res;
			});
			this.loadingService.showLoading(true, null, loadingMessage, null);
			this.courseService.getICEGameInfoByTopicId(this.topic.id, UserVariable.getUserToken(), this);
			//remove unsaved glossary items 
			self.iceGameList = self.iceGameList.filter(function(a){
				  return a.description != undefined;
			});
			self.isShowIceGames = true;
			setTimeout(() => {
				self.setEditor(CK_Editor_Type.GAME_EDITOR, "");
			}, 200);
    }
	}

	// Set Edit ICE Game UI
	editGame() {
    let self = this;
    self.activeEditor = CK_Editor_Type.GAME_EDITOR;
    if (self.topicsModified == true) {
      self.alertService.warn(self.translate.instant('ICE_COURSE.COURSE.SAVE_TOPICS'));
    } else if (self.learningChecksModified == true) {
			self.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_LEARNINGCHECKS'));
		} else if(self.topicModified == true){
			self.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_TOPICS'));
		}  else if (self.topicContentsModified == true) {
			this.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_TOPIC_CONTENTS'));
		} else {
			self.topicsEnabled = false;
			self.learningCheckEnabled = false;
      self.gameEnabled = true
      self.topicEnabled = false;
      self.topicContentsEnabled = false;
      this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'topic', false, 'topicEnabled');
			this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'topic', false, 'topicsEnabled');
			this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'topic', false, 'topicContentsEnabled');
			this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'topic', false, 'learningCheckEnabled');
			this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'topic', true, 'gameEnabled');

			if(self.isShowIceGames == false && this.iceGameList.length == 0)
			{
				var loadingMessage = null;
				this.translate.get('LOADING').subscribe((res: string) => {
					loadingMessage = res;
				});
				this.loadingService.showLoading(true, null, loadingMessage, null);
				this.courseService.getICEGameInfoByTopicId(this.topic.id, UserVariable.getUserToken(), this);
			}
			//destroy editors
			if (self.titleditor) {
				self.titleditor.destroy();
			} else {
				//no editor
			}
			if (self.desceditor) {
				self.desceditor.destroy();
			} else {
				//no editor
			}
			self.isShowIceGames = true;
			setTimeout(() => {
				self.setEditor(CK_Editor_Type.GAME_EDITOR, "");
			}, 200);
			// Handling visbility of divs
			self.isShowLearningChecks=false;
      self.isShowIceTopicDetails = false;
      self.isShowIceTopicContents = false;
    }
	}

	// Save ICE Games
	iceGameListSave(showValidation) {
		//update game model
		this.updateGameDescription();
		if(this.iceGameList.length > 0) {
			var request = { 
				"courseId":this.topic.courseId,
				"courseVersion":this.topic.courseVersion,
				"iceGames": this.iceGameList
			};
			//show loading message
			var loadingMessage = null;
			this.translate.get('ICE_GAME.SAVE_GAME_MESSAGE').subscribe((res: string) => {
				loadingMessage = res;
			});
			this.loadingService.showLoading(true, null, loadingMessage, null);
			this.courseService.saveICEGameList(JSON.stringify(request), UserVariable.getUserToken(), this);
			//setglossarymodified false
			this.gameModified = false;
			this.sharedService.setModifiedStatus(this.tabId, this.gameModified)
		} else {
			if (showValidation == true) {
				this.alertService.warn(this.translate.instant('ICE_GAME.NO_GAME_ERROR'));
			  } else {
				//do not show
			}
		}
		if (showValidation == true) {
			this.loadGameList = true;
		} else {
		  //navigate
		}
	}

	// Update ICE Glossary Model with modified values
	updateGameDescription() {
		for(let i = 1; i <= this.iceGameList.length; i++){
			if (this.iceGameList[i - 1].isGameModified == true) {
				this.xmlContent ="";
				//check if edior text is empty
				var editortext = $('.games_ck_editor' + this.iceGameList[i - 1].id + this.tabId).text();
				if(this.isNullOrWhitespace(editortext) == true) {
					this.iceGameList[i - 1].description = '<ice></ice>';
				} else {
					//process name
					var name = this.getEditorContent('.games_ck_editor' + this.iceGameList[i - 1].id  + this.tabId);
					this.iceCKEditor.processXML(name,false,this.xmlContent);
					this.xmlContent = this.iceCKEditor.getXMLContent();
					this.iceGameList[i - 1].description = '<ice>'+ this.xmlContent + '</ice>';
				}
			} else {
				//game not modified 
			}
		}
	} 

	// Delete ICE Game by Game Id
	public deleteIceGame(game: IceGame, index: number, template: TemplateRef<any>) {
		if(game.id != '0') {
			//show loading message
			var loadingMessage = null;
			this.translate.get('LOADING').subscribe((res: string) => {
			  	loadingMessage = res;
			});
			this.loadingService.showLoading(true, null, loadingMessage, null);
			this.isDeleteEnable = true;
			this.gameToDelete = game;
			this.indexOfGameToDelete = index;
			this.loadingService.hideLoading();
			this.modalRef = this.modalService.show(template, this.modalConfig);
		}
		else{
			this.deleteItemFromGameList(index);  
		}
	}

	// Update iceGameList after delete ICE Game
	deleteItemFromGameList(index: number){
		this.iceGameList.splice(index,1);
		if(this.iceGameList.length == 0){
			this.isShowIceGames = false;
		}
	}

	// Delete ICE Game after confirmation
	public confirmDeleteGame(){
		//show loading message
		var loadingMessage = null;
		this.translate.get('ICE_GAME.GAME_DELETING_MESSAGE').subscribe((res: string) => {
		  	loadingMessage = res;
		});
		var request = {
			"id": this.gameToDelete.id,
			"courseId": this.gameToDelete.courseId,
		}
		this.loadingService.showLoading(true, null, loadingMessage, null);
		this.courseService.deleteICEGame(JSON.stringify(request), this.gameToDelete.id, UserVariable.getUserToken(), this);
	}

	/**
	 * show more info button click 
	 * arrow right button click event
	//  * @param iceGame 
	 */
	public showInfoIceGame(iceGame: IceGame) {
    if (this.topicModified == true) {
			this.alertService.warn(this.translate.instant('ICE_TOPIC.TOPIC.TOPIC_SAVE'));
		} else if (this.topicsModified == true) {
			this.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_TOPICS'));
		} else if (this.learningChecksModified == true) {
			this.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_LEARNINGCHECKS'));
		} else if (this.topicContentsModified == true) {
			this.alertService.warn(this.translate.instant('ICE_COURSE.COURSE.SAVE_TOPIC_CONTENTS'));
		} else {
      this.navigateToT = true;
      this.gameData = iceGame;
			let el = this.elementRef.nativeElement.querySelector('.games_ck_editor' + iceGame.id + this.tabId);
      this.gameData.descriptionHTML = el.innerHTML; 
      if( this.gameModified == false || this.isViewOnly == true ) {
        this.gameData.courseId = this.topic.courseId;
        this.gameData.topicId = this.topic.id;
        let data = {
          courseId  : this.topic.courseId,
          iceGame  : iceGame,
          viewOnly  : this.isViewOnly,
          iceType: iceGame.gameType
        }
        var html = this.topic.topicNameHTML;
        var div = document.createElement("div");
        div.innerHTML = html;
        var text = div.textContent || div.innerText || div.style || "";
        let data2 = {
          courseId  : this.topic.courseId,
          iceTopic : this.topic,
          viewOnly :this.isViewOnly,
          iceType : 'topic',
          title: text,
          id: this.topic.id,
        }
        this.sharedService.setICEBreadCrumb(this.topic.courseId, data2);
        switch (iceGame.gameType) {
          case 'EIG':
            this.sharedService.changeIceItemRequest('EinsteinGame', data)
            break;
          case 'SMSG':
            this.sharedService.changeIceItemRequest('SpeedMatchGame', data)
            break;
          case 'MG':
            this.sharedService.changeIceItemRequest('MemoryGame', data)
            break;
    
          default:
            break;
        } 
        console.log('[showInfoIceGame] (iceGame) ' + JSON.stringify(iceGame));
      }else{
           this.iceGameListSave(false);
      }
    }
	}

  public getStringFromType(gameType: string): string {
		switch (gameType) {
			case 'EIG':
				return "Einstein (Instructor)"
			case 'SMSG':
				return "Speed Match (Student)"
			case 'MG':
        return "Memory (Instructor)"
      case 'WAMG':
        return "Whack a Mole (Instructor)"
			default:
				return ""
		}
  }
  
  //get game name by game type
  public getGameNameFromType(gameType: string): string {
		switch (gameType) {
			case 'EIG':
				return "Einstein"
			case 'SMSG':
				return "Speed Match"
			case 'MG':
				return "Memory"
			case 'WAMG':
				return "Whack A Mole"
			default:
				return ""
		}
	}

  // Methods for adding dummy details
  /*private addDummyTopics(topicList: any) {
		let topic_1: IceTopic = {
      id: '0', 
      topicName: 'Sample ICE Topic - 1',
      courseId: this.topic.courseId,
			orderNumber: 0, copyTopicId: ''
		}
		topicList.push(topic_1)
		let topic_2: IceTopic = {
      id: '0', topicName: 'Sample ICE Topic - 2',
      courseId: this.topic.courseId,
			orderNumber: 1, copyTopicId: ''
		}
		topicList.push(topic_2)
  }
  
  private addDummyTopicContents(topicContentList: any) {
		let topicContent_1: IceTopicContent = {
			id: '0', topicContentName: 'Sample ICE Topic Content - 1',
			orderNumber: 1, copyTopicContentId: '0023'
		}
		topicContentList.push(topicContent_1)
		let topicContent_2: IceTopicContent = {
			id: '0', topicContentName: 'Sample ICE Topic Content - 2',
			orderNumber: 2, copyTopicContentId: ''
		}
		topicContentList.push(topicContent_2)
  }*/
  
  // private addDummyLCS(learningCheckList: any) {
	// 	let lc_1: IceLearningCheck = {
	// 		id: '001', description: 'Sample Learning Check - 1',
	// 		orderNumber: 1, questionLength: 37
	// 	}
	// 	learningCheckList.push(lc_1)
	// 	let lc_2: IceLearningCheck = {
	// 		id: '002', description: 'Sample Learning Check - 2',
	// 		orderNumber: 2, questionLength: 6
	// 	}
	// 	learningCheckList.push(lc_2)
	// }

	// private addDummyIceGames(iceGameList: any) {
	// 	let iceGame_1: IceGame = {
	// 		id: '001', description: 'Sample Game - 1', gameType: 'EIG'
	// 	}
	// 	iceGameList.push(iceGame_1)
	// 	let iceGame_2: IceGame = {
	// 		id: '002', description: 'Sample Game - 2', gameType: 'SMSG'
	// 	}
	// 	iceGameList.push(iceGame_2)
  // }
  
  onSuccess(data: WsResponse, serviceType: WsType) {
		console.log(data.payload);
		console.log("serviceType"+serviceType);
		if (serviceType == WsType.CREATE_ICE_TOPIC) {
      if (this.newTopic.id) {
        this.newTopic.id = data.payload;
        this.newTopic.topicName = "New Topic Menu";
        this.iceTopicList.push(this.newTopic);
        this.haveChildItems = true;
        setTimeout(() => {
          this.focusNewEditor('.t_ck_editor'+ (this.newTopic.sequence) + this.tabId , CK_Editor_Type.TOPIC_EDITOR);
          this.loadingService.hideLoading();
          }, 200);
      } else {
        this.topic.id = data.payload;
        this.showSuccessAlert('ICE_TOPIC.TOPIC_SAVED_ALERT');
        this.loadingService.hideLoading();
      }  
      console.log("=====Topic is succesfully created=====");
    } else if(serviceType==WsType.CREATE_LEARNING_CHECK){
      if (this.newLearningCheck.id) {
        this.newLearningCheck.id = data.payload;
        this.newLearningCheck.description = "New Learning Check";
        this.iceLearningCheckList.push(this.newLearningCheck);
        this.haveChildItems = true;
        setTimeout(() => {
          this.focusNewEditor('.l_ck_editor'+ (this.newLearningCheck.sequence) + this.tabId , CK_Editor_Type.LEARNING_CHECK_EDITOR);
          this.loadingService.hideLoading();
          }, 200);
      } else {
        this.newLearningCheck.id = data.payload;
        this.showSuccessAlert('ICE_LEARNING_CHECK.LEARNINGCHECK_SAVED_ALERT');
        this.loadingService.hideLoading();
      }  
      console.log("=====LC is succesfully created=====");
    }else if (serviceType == WsType.UPDATE_ICE_TOPIC){
      this.courseService.getICETopic(this.topic.id, UserVariable.getUserToken(), this);
      this.showSuccessAlert('ICE_TOPIC.TOPIC_UPDATED_ALERT');  
      console.log("=====Topic is succesfully updated=====");
      this.loadingService.hideLoading();
    } else if (serviceType == WsType.GET_ICE_TOPIC) {
      let self = this;
      self.topic.description = data.payload.description;
      self.topic.topicName = data.payload.topicName;
      self.topic.topicImage = data.payload.topicImage;
      self.topic.linkedCourseId = data.payload.linkedCourseId;
      this.topic.topicNameHTML = data.payload.topicNameHTML;
      
      this.topic.topicName = this.topic.topicName.replace(/(<wintitle)([\s]+([^>]+)\/>)/g,
						"<wintitle contenteditable='true'$2</wintitle>");
      this.topic.description = this.topic.description.replace(/(<wintitle)([\s]+([^>]+)\/>)/g,
						"<wintitle contenteditable='true'$2</wintitle>");
			this.topic.topicNameHTML = this.topic.topicNameHTML.replace(/(<wintitle)([\s]+([^>]+)\/>)/g,
            "<wintitle contenteditable='true'$2</wintitle>");
            
      var html = this.topic.topicNameHTML;
      this.gamesCount = data.payload.noOfGames;
      var div = document.createElement("div");
      div.innerHTML = html;
      this.topicNameShowInDetails = div.textContent || div.innerText || "";
      console.log(self.topic);
      //destroy active editors
			if (this.desceditor) {
				this.desceditor.destroy();
			} else {
				//no editor
			}
			if (this.titleditor) {
				this.titleditor.destroy();
			} else {
				//no editor
      }
      if(self.topic.topicImage != null){
        this.imageBackground = "#f0ba0a";
        this.imageBorder = "none";
				this.topicImageChecked = true;
			} else{
				this.topicImageChecked = false;
        this.imageBackground = "#ffffff"
        this.imageBorder = "solid #f0ba0a"
			}
      //console.log(this.topic);
      let e1  = this.elementRef.nativeElement.querySelector('.ckeditor_topic_description' + this.tabId);
      if(e1 != null){
        e1.innerHTML = this.topic.description;
        e1.setAttribute("contenteditable", "false");
      }
      let e2  = this.elementRef.nativeElement.querySelector('.ckeditor_topic_title' + this.tabId);
      if(e2 != null){
        e2.innerHTML = this.topic.topicName;
        e2.setAttribute("contenteditable", "false");
      }
      // if(this.iceCourseList.length > 0){
      //   this.iceCoursesAvailable = true;
      //   if(this.topic.linkedCourseId != undefined || this.topic.linkedCourseId != null){
      //     this.selectedIceCourse = this.topic.linkedCourseId;
      //     this.iceCourseList.forEach((courseItem : ICECourse) => {
      //       if(courseItem.courseId == this.topic.linkedCourseId){
      //         this.linkedCourse = courseItem;
      //       }
      //     });
      //   }
      // }
      if(this.topic.linkedCourseId != undefined || this.topic.linkedCourseId != null){
        this.iceCoursesAvailable = true;
        this.selectedIceCourse = this.topic.linkedCourseId;
        this.linkedCourse = data.payload.linkedCourseFileName;
      }
      this.sharedService.setOpenedICEModules(SharedService.getTabId(), 'ICETopic', this.topic);
      let commonVariables = this.sharedService.getCommonICEModuleVariableNames('topic');
      var commonVariableValues: Array<any> = [];
      commonVariables.forEach(function (varName) {
        commonVariableValues[varName] = self[varName];
      }); 
      this.sharedService.setCommonICEModuleVariableValues(SharedService.getTabId(), 'topic', commonVariableValues)
      this.loadingService.hideLoading(); 
    } else if (serviceType == WsType.GET_ALL_ICE_TOPICS){
      this.loadtopiclist = false;
      this.loadTopicDetails(data.payload,false);
      this.loadingService.hideLoading();
    } else if (serviceType == WsType.GET_ICE_TOPIC_CONTENT) {
      this.loadICETopicContentList(data.payload, false);
      this.loadingService.hideLoading();
    } else if (serviceType == WsType.GET_ICE_LEARNINGCHECKS){
      this.loadLearningChecklist = false;
      this.loadICELearningCheckList(data.payload);
      this.loadingService.hideLoading();
    } else if (serviceType == WsType.DELETE_ICE_TOPIC){
      this.deleteTopicProcess();
    }	else if (serviceType == WsType.DELETE_ICE_TOPIC_CONTENT) {
      this.updateTopicContentDescriptionAfterDelete();
      this.showSuccessAlert('ICE_TOPIC_CONTENT.TOPIC_CONTENT_DELETED_ALERT');  
      console.log("=====Topic Content is succesfully deleted=====");
       this.deleteIceTopicContentFromList(); 

       //remove addedtopiccontents and reload
			 var topiccontentlist = this.iceTopicContentList;
			 this.iceTopicContentList = [];
			 //update sequence
			 this.updateSequence(topiccontentlist);
			 this.loadICETopicContentList(topiccontentlist,true);
       
       this.loadingService.hideLoading();
       if(this.iceTopicContentList.length == 0) {
          this.topicContentsModified = false;
          this.sharedService.setModifiedStatus(this.tabId,  this.topicContentsModified)
       } else {
         //do nothing
       }
    } else if (serviceType == WsType.REORDER_ICE_TOPIC){
      this.isEnableReordering = true;
     // this.showSuccessAlert('ICE_TOPIC.TOPIC_REORDER_ALERT');    
      console.log("=====Topic are successfully reordered=====");
    } else if (serviceType == WsType.CHECK_ICE_TOPIC_DEPENDENCY){
        this.topicDependencies = data.payload[0];
        this.topicContentDependencies = data.payload[1];
        if(this.topicDependencies != 0 || this.topicContentDependencies != 0){
          this.isDeleteEnable = false;
        }       
        else{
          // nothing to do here
        }       
       this.loadingService.hideLoading();
    } else if (serviceType == WsType.REORDER_ICE_TOPIC_CONTENT) {
      this.isShowIceTopicContentReorderButton = false;
     // this.showSuccessAlert('ICE_TOPIC_CONTENT.TOPIC_CONTENT_REORDER_ALERT');    
      console.log("=====Topic Contents are successfully reordered=====");
    } else if (serviceType == WsType.SAVE_ICE_TOPICS) {
      if(this.loadtopiclist == true) {
          this.iceTopicList = [];
          //get topic details 
          this.courseService.getAllICETopicsByTopicId(this.topic.id, UserVariable.getUserToken(), this);
          //this.loadingService.hideLoading();
      } else {
			  //do nothing
      }
      if (this.navigateToT) {
        this.iceTopicData.topicParentId = this.topic.id;
        this.iceTopicData.parentType = "topic";
        this.iceTopicData.parent = this.topic;
        let data = {
          courseId  : this.topic.courseId,
          iceTopic : this.iceTopicData	,
          viewOnly :this.isViewOnly,
        }
        var html = this.topic.topicNameHTML;
        var div = document.createElement("div");
        div.innerHTML = html;
        var text = div.textContent || div.innerText || "";
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateToken(token)){
            return;
        }
        this.courseService.getICETopic(this.topic.id, token, this);
        let data2 = {
          courseId  : this.topic.courseId,
          iceTopic : this.topic	,
          viewOnly :this.isViewOnly,
          iceType : 'topic',
          title : text
        }
        this.sharedService.changeIceItemRequest('topic', data);
        this.sharedService.setICEBreadCrumb(this.topic.courseId, data2);
      } else if (this.navigateToParent == true) {
        if (this.isShowIceTopicContents == false) {
          this.sharedService.changeIceItemRequest(this.tc_data.iceType, this.tc_data);
          this.breadCrumbList.length = this.tc_index;
        } else {
          //do noting
        }
      } else {
        //do nothing
      }
    } else if (serviceType == WsType.SAVE_ICE_LEARNING_CHECKS) {
      this.iceLearningCheckList=[];
      if(this.loadLearningChecklist == true) {
      //get lc details 
      this.courseService.getAllICELearningCheckByTopicId(this.topic.id, UserVariable.getUserToken(), this);
      }else {
          //do nothing
      }
      if(this.navigateToT){
        this.LearningCheckData.topicId = this.topic.id;
				this.LearningCheckData.parentType = 'topic';
        this.LearningCheckData.parent = this.topic; 
        let data = {
            courseId  : this.topic.courseId,
            iceLearningCheck  : this.LearningCheckData,
            viewOnly  : this.isViewOnly,
            iceType: 'IceLearningCheck',
            quickSelectQuestionId: null
        }
				var html = this.topic.topicNameHTML;
				var div = document.createElement("div");
				div.innerHTML = html;
				var text1 = div.textContent || div.innerText || div.style || "";
				let data2 = {
					courseId  : this.topic.courseId,
					iceTopic : this.topic,
					viewOnly : this.isViewOnly,
					iceType : 'topic',
					title: text1,
					id: this.topic.id,
        }
        this.sharedService.changeIceItemRequest('IceLearningCheck', data);
				this.sharedService.setICEBreadCrumb(this.topic.courseId, data2);
      }
      else if (this.navigateToParent == true) {
        if (this.isShowIceTopicContents == false) {
          this.sharedService.changeIceItemRequest(this.tc_data.iceType, this.tc_data);
          this.breadCrumbList.length = this.tc_index;
        } else {
          //do noting
        }
      } else {
        //do nothing
      }
    } else if (serviceType == WsType.REORDER_ICE_LEARNING_CHECK){
			this.isEnableReordering = true; 
			console.log("=====Learning Checks are successfully reordered=====");
		} else if (serviceType == WsType.SAVE_ICE_TOPICCONTENTS) {
      //this.iceTopicContentList = [];
      if (this.navigateToTC == true) {
        console.log('[showInfoIceTopicContent]');
        let data = {
          courseId  : this.topic.courseId,
          iceTopicContent : this.topicContentData,
          iceTopic        :	this.topic,
          viewOnly        :this.isViewOnly
        }

        var html = this.topic.topicNameHTML;
        var div = document.createElement("div");
        div.innerHTML = html;
        var text = div.textContent || div.innerText || "";
        let data2 = {
          courseId  : this.topic.courseId,
          iceTopic  :	this.topic,
          viewOnly :this.isViewOnly,
          iceType : 'topic',
          title : text
        }
        
        this.sharedService.changeIceItemRequest('topicContent', data);
        this.sharedService.setICEBreadCrumb(this.topic.courseId, data2);
      } else if (this.navigateToParent == true) {
        this.sharedService.changeIceItemRequest(this.tc_data.iceType, this.tc_data);
        this.breadCrumbList.length = this.tc_index;
      } else {
        this.iceTopicContentList = [];
        //get topic details 
        this.courseService.getICETopicContentListByICETopicId(this.topic.id, UserVariable.getUserToken(), this);
        //this.loadingService.hideLoading();
      }
		} else if (serviceType == WsType.CHECK_ICE_LEARNINGCHECKS_DEPENDENCY){
			if(data.payload == true){
				this.questionDependencies= LearningCheckData_Details.LCDATA_AVAILABLE;
				this.isLCDeleteEnable = false;
			}
			else{
				// nothing to do here
			}             
			this.loadingService.hideLoading();
		} else if (serviceType == WsType.DELETE_ICE_LEARNING_CHECK){
			this.deleteItemFromLearningCheckList(this.indexOfLearningCheckToDelete);
			this.learningCheckToDelete = undefined;
			this.indexOfLearningCheckToDelete = undefined;
			this.showSuccessAlert('ICE_LEARNING_CHECK.LEARNING_CHECK_DELETED_ALERT');    
     		console.log("=====Learning Check is succesfully deleted=====");
			this.loadingService.hideLoading();
			if(this.iceLearningCheckList.length == 0) {
				this.learningChecksModified = false;
			  } else {
				//do nothing
			  }
		} if (serviceType == WsType.CREATE_ICE_TOPIC_CONTENT) {
      this.newTopicContent.id = data.payload;
      this.newTopicContent.description = "New Bullet";
      this.iceTopicContentList.push(this.newTopicContent);
      this.haveChildItems = true;
      setTimeout(() => {
        this.focusNewEditor('.tc_ck_editor'+ this.newTopicContent.sequence + this.tabId, CK_Editor_Type.TOPIC_CONTENT_EDITOR);
        this.loadingService.hideLoading();
      }, 200);
      console.log("=====Topic content is succesfully created=====");
		} else if(serviceType == WsType.GET_ALL_ACTIVE_ICE_COURSES){
      this.loadICECourseList(data.payload);
		}else if (serviceType == WsType.CREATE_ICE_GAME) {
      let gameString = this.getStringFromType(this.selectedGameType);
      //to get game name
      let gameNameString = this.getGameNameFromType(this.selectedGameType);
			if (this.selectedGameType == 'MG') {
				gameString = "New Memory Game";
			}
			if (this.selectedGameType == 'SMSG') {
				gameString = "New Speed Match Game";
			}
			if (this.selectedGameType == 'EIG') {
				gameString = "New Einstein Game";
      }
      if (this.selectedGameType == 'WAMG') {
				gameString = "New Whack a Mole Game";
			}
			this.newGame.id = data.payload;
			this.newGame.gameName = gameNameString;
			this.newGame.description = gameString;
			this.newGame.gameType = this.selectedGameType;
      this.newGame.topicId = this.topic.id,
			this.iceGameList.push(this.newGame);
			this.gamesCount = this.iceGameList.length;
			setTimeout(() => {
				this.focusNewEditor('.games_ck_editor'+ (this.newGame.id) + this.tabId ,CK_Editor_Type.GAME_EDITOR); 
			}, 200);
			this.showSuccessAlert('ICE_GAME.GAME_SAVED_ALERT');  
			console.log("=====Game is succesfully created=====");	
      this.loadingService.hideLoading(); 
		} else if (serviceType == WsType.GET_ALL_ICE_GAME_LIST) {
			this.loadGameList = false;
			this.loadGameListDetails(data.payload);	
      this.loadingService.hideLoading();
      this.gamesCount = this.iceGameList.length;
		} else if (serviceType == WsType.SAVE_ICE_GAMES) {
			this.iceGameList = [];
			if(this.loadGameList == true) {
				this.courseService.getICEGameInfoByTopicId(this.topic.id, UserVariable.getUserToken(), this);
        //this.loadingService.hideLoading();
      } else {
					//do nothing
      }
      if(this.navigateToT == true){
        this.gameData.courseId = this.topic.courseId;
        this.gameData.topicId = this.topic.id;
        let data = {
          courseId  : this.topic.courseId,
          iceGame  : this.gameData,
          viewOnly  : this.isViewOnly,
          iceType: this.gameData.gameType
        }
        var html = this.topic.topicNameHTML;
        var div = document.createElement("div");
        div.innerHTML = html;
        var text3 = div.textContent || div.innerText || div.style || "";
        let data2 = {
          courseId  : this.topic.courseId,
          iceTopic : this.topic,
          viewOnly :this.isViewOnly,
          iceType : 'topic',
          title: text3,
          id: this.topic.id,
        }
        this.sharedService.setICEBreadCrumb(this.topic.courseId, data2);
        switch (this.gameData.gameType) {
          case 'EIG':
            this.sharedService.changeIceItemRequest('EinsteinGame', data)
            break;
          case 'SMSG':
            this.sharedService.changeIceItemRequest('SpeedMatchGame', data)
            break;
          case 'MG':
            this.sharedService.changeIceItemRequest('MemoryGame', data)
            break;
    
          default:
            break;
        } 
      }else{
        //nothing to do
      }
			// this.showSuccessAlert('ICE_GAME.GAME_UPDATED_ALERT');    
     	// 	console.log("=====Games List is successfully updated=====");
		} else if (serviceType == WsType.DELETE_ICE_GAME) {
			this.deleteItemFromGameList(this.indexOfGameToDelete);
			this.gamesCount = this.iceGameList.length;
			this.gameToDelete = undefined;
			this.indexOfGameToDelete = undefined;
			this.showSuccessAlert('ICE_GAME.GAME_DELETED_ALERT');    
     		console.log("=====Game is succesfully deleted=====");
			this.loadingService.hideLoading();
			if(this.iceGameList.length == 0) {
				this.gameModified = false;
			} else {
				//do nothing
			}
		} else if (serviceType == WsType.GET_ALL_CHECKEDIN_ICE_COURSES){
			this.iceCMCoursesAvailable = true;
      this.iceCMCourseList = data.payload;
      //remove file name empty items
      this.iceCMCourseList = this.iceCMCourseList.filter((item => item.fileName !== ""));
      this.iceCMCourseList.sort((a, b) => (a.fileName.toLowerCase() > b.fileName.toLowerCase()) ? 1 : -1);

		} else if (serviceType == WsType.GET_CM_LEARNINGCHECK_LIST){
			this.iceCMCoursesAvailable = false;
			this.cmLearningCheckListAvailable = true;
			this.iceCMLearningCheckList = data.payload;
			this.getCMLearningCheckData = false;
			//this.selectedCMIceCourse = null;
      this.isLearningCheckDesc = true;
			this.isLearningCheckList = false;

		} else if(serviceType == WsType.COPY_CM_LEARNINGCHECK){
			this.iceLearningCheckList = [];
			this.courseService.getAllICELearningCheckByTopicId(this.topic.id, UserVariable.getUserToken(), this);
			this.selectedCMIceCourse = null;
			this.selectedCMLearningCheck = null;
			this.showSuccessAlert('ICE_LEARNING_CHECK.LEARNING_CHECK_COPY_ALERT');
		} else if( serviceType == WsType.GET_CM_GAME_LIST){
			this.iceCMCoursesAvailable = false;
			this.cmGameListAvailable = true;
			this.iceCMGameList = data.payload;
			this.getCMGameData = false;
      this.isGameDesc = true;
			this.isGameList = false;
		} else if(serviceType == WsType.COPY_CM_GAME){
      this.iceGameList = [];
      this.courseService.getICEGameInfoByTopicId(this.topic.id, UserVariable.getUserToken(), this);
      this.gamesCount = this.iceGameList.length;
			this.selectedCMIceCourse = null;
			this.selectedCMGame = null;
			this.showSuccessAlert('ICE_GAME.GAME_COPY_ALERT');
		} else if (serviceType == WsType.GET_SELECTED_IMAGE_DATA) {
      this.selectedImage = JSON.parse(data.payload);
      this.loadingService.hideLoading();
      this.modalRef = this.modalService.show(this.selectedModalTemplate, this.modalConfig);
    } else{
      // nothing to do here
    }
	}

	onFail(data: WsResponse, serviceType: WsType) {
		if (serviceType == WsType.CREATE_ICE_TOPIC) {
      this.alert.error(data.statusDescription, false);
      console.log("=====Failed to create topic=====");
    } else if (serviceType == WsType.UPDATE_ICE_TOPIC) {
      console.log("=====Failed to update topic details=====");
      if (data.statusCode == "543") {
				this.loadConflictData(data.payload)
				this.openModalCC(this.conflict_checker);
				this.loadingService.hideLoading();
			} else {
				this.alertService.error(data.statusDescription, false);
			}
    } else if (serviceType == WsType.GET_ICE_TOPIC) {
			this.alert.error(data.statusDescription, false);
      console.log("=====Failed to get topic details=====");
    } else if (serviceType == WsType.GET_ALL_ICE_TOPICS){
			this.alert.error(data.statusDescription, false);
      console.log("=====Failed to get topic list details=====");
    } else if (serviceType == WsType.GET_ICE_TOPIC_CONTENT) {
			this.alert.error(data.statusDescription, false);
      console.log("=====Failed to get topic content list details=====");
    } else if (serviceType == WsType.DELETE_ICE_TOPIC) {
			this.alert.error(data.statusDescription, false);
      console.log("=====Failed to delete topic=====");
    } else if (serviceType == WsType.DELETE_ICE_TOPIC_CONTENT) {
			this.alert.error(data.statusDescription, false);
      console.log("=====Failed to delete topic content=====");
    } else if (serviceType == WsType.REORDER_ICE_TOPIC) {
			this.alert.error(data.statusDescription, false);
      console.log("=====Failed to reorder topics=====");
    } else if (serviceType == WsType.REORDER_ICE_TOPIC_CONTENT) {
			this.alert.error(data.statusDescription, false);
      console.log("=====Failed to reorder topics content=====");
    } else if (serviceType == WsType.CHECK_ICE_TOPIC_DEPENDENCY) {
			this.alert.error(data.statusDescription, false);
      console.log("=====Failed to check dependencies for topics=====");
      this.modalRef.hide();
    } else if (serviceType == WsType.SAVE_ICE_TOPICS) {
      console.log("=====Failed to save topics=====");
      if (data.statusCode == "543") {
				this.loadConflictData(data.payload)
				this.openModalCC(this.conflict_checker);
				this.loadingService.hideLoading();
			} else {
				this.alertService.error(data.statusDescription, false);
			}
		} else if (serviceType == WsType.SAVE_ICE_TOPICCONTENTS) {
      console.log("=====Failed to save topic contents=====");
      if (data.statusCode == "543") {
				this.loadConflictData(data.payload)
				this.openModalCC(this.conflict_checker);
				this.loadingService.hideLoading();
			} else {
				this.alertService.error(data.statusDescription, false);
			}
		} else if (serviceType == WsType.CREATE_ICE_TOPIC_CONTENT) {
      this.alert.error(data.statusDescription, false);
      console.log("=====Failed to save topic content=====");
		} else if(serviceType==WsType.CREATE_LEARNING_CHECK){
      this.alert.error(data.statusDescription, false);
      console.log("=====Failed to create learning check=====");
    } else if (serviceType == WsType.SAVE_ICE_LEARNING_CHECKS){
      this.alert.error(data.statusDescription, false);
      console.log("=====Failed to save learning check=====");
    } else if (serviceType == WsType.GET_ICE_LEARNINGCHECKS){
      this.alert.error(data.statusDescription, false);
      console.log("=====Failed to get learning check=====");
    } else if (serviceType == WsType.REORDER_ICE_LEARNING_CHECK){
			this.alertService.error(data.statusDescription, false);
			console.log("=====Failed to reorder learning checks=====");
		} else if (serviceType == WsType.CHECK_ICE_LEARNINGCHECKS_DEPENDENCY){
			this.alertService.error(data.statusDescription, false);
			console.log("=====Failed to check dependency learning checks=====");
		} else if (serviceType == WsType.DELETE_ICE_LEARNING_CHECK){
			this.alertService.error(data.statusDescription, false);
			console.log("=====Failed to delete learning checks=====");
		} else if (serviceType == WsType.CREATE_ICE_GAME){
			this.alertService.error(data.statusDescription, false);
			console.log("=====Failed to save game=====");
		} else if (serviceType == WsType.GET_ALL_ICE_GAME_LIST){
			this.alertService.error(data.statusDescription, false);
			console.log("=====Failed to get games list=====");
		} else if (serviceType == WsType.SAVE_ICE_GAMES){
			this.alertService.error(data.statusDescription, false);
			console.log("=====Failed to save games=====");
		} else if (serviceType == WsType.DELETE_ICE_GAME){
			this.alertService.error(data.statusDescription, false);
			console.log("=====Failed to delete game=====");
		} else if (serviceType == WsType.GET_SELECTED_IMAGE_DATA) {
      // do nothing
    }
		this.loadingService.hideLoading();
  }

  deleteTopicProcess() {
    this.updateTopicNameAfterDelete();
    this.indexTopicToDelete = this.sharedService.getDeleteTopicIndex();
    this.deleteItemFromTopicList(this.indexTopicToDelete);
    this.topicToDelete = undefined;

    //remove addedtopics and reload
     var topiclist = this.iceTopicList;
     this.iceTopicList = [];
     this.loadTopicDetails(topiclist,true);

    this.showSuccessAlert('ICE_TOPIC.TOPIC_DELETED_ALERT');    
    console.log("=====Topic is succesfully deleted=====");
    this.loadingService.hideLoading();
    if(this.iceTopicList.length == 0) {
      this.topicsModified = false;
      this.sharedService.setModifiedStatus(this.tabId, this.topicsModified )
    } else {
      //do nothing
    }
  }

  updateSequence(itemlist) {
		for(let i = 0; i < itemlist.length; i++){
			itemlist[i].sequence = i + 1;
		}
	}
  
  /**
	 * load topic list details
	 * @param payload 
	 */
	loadTopicDetails(payload:WsResponse['payload'], afterdelete){
    this.iceTopicModel = null;
    this.iceTopicList = [];
		if(payload.length != 0){
			for(let i = 0; i < payload.length; i++){
        this.iceTopicModel = payload[i];
        this.iceTopicModel.sequence = i + 1;
				this.iceTopicModel.topicName = this.iceTopicModel.topicName.replace(/(<wintitle)([\s]+([^>]+)\/>)/g,
						"<wintitle contenteditable='true'$2</wintitle>");
				this.iceTopicModel.topicNameHTML = this.iceTopicModel.topicNameHTML.replace(/(<wintitle)([\s]+([^>]+)\/>)/g,
						"<wintitle contenteditable='true'$2</wintitle>");
        if (afterdelete == false) {
          this.iceTopicModel.isTopicModified = false;
				} else {
					//do nothing
				}
        this.iceTopicList.push(this.iceTopicModel);
				//console.log(payload[i]);
      }
      this.sharedService.setTopicList(this.iceTopicList);
		}else{
			// no row data to show
    }
    
    setTimeout(() => {
      this.setEditor(CK_Editor_Type.TOPIC_EDITOR, "");
    }, 200);
    if(this.iceTopicList.length > 0){
      this.haveChildItems = true;
    }
  }
  
  loadICETopicContentList(payload:WsResponse['payload'], afterdelete){
    this.iceTopicContentModel = null;
    if(payload.length != 0){
			for(let i = 0; i < payload.length; i++){
        this.iceTopicContentModel = payload[i];
        if (afterdelete == false) {
          this.iceTopicContentModel.isTopicContentModified = false;
          this.iceTopicContentModel.description = this.iceTopicContentModel.description.replace(/(<wintitle)([\s]+([^>]+)\/>)/g,
                  "<wintitle contenteditable='true'$2</wintitle>");
        } else {
          //do nothing
        }
        this.iceTopicContentList.push(this.iceTopicContentModel);
			}
		}else{
			// no row data to show
    }

    setTimeout(() => {
      this.setEditor(CK_Editor_Type.TOPIC_CONTENT_EDITOR, "");
    }, 200);
    if(this.iceTopicContentList.length > 0){
      this.haveChildItems = true;
    }
  }

  // Load ICE Game to iceGamesList
	loadGameListDetails(payload:WsResponse['payload']){
		this.iceGameList = [];
		this.iceGameModel = null;
		if(payload.length != 0){
			for(let i = 0; i < payload.length; i++){
				this.iceGameModel = payload[i];
				this.iceGameModel.isGameModified = false;
				this.iceGameList.push(this.iceGameModel);
			}
		}else{
			// no row data to show
    }
    setTimeout(() => {
      this.setEditor(CK_Editor_Type.GAME_EDITOR, "");
    }, 200);
	}

  /**
	 * load learningcheck details
	 * @param payload 
	 */
	loadICELearningCheckList(payload:WsResponse['payload']){
    this.iceLearningCheckModel = null;
		if(payload.length != 0){
			for(let i = 0; i < payload.length; i++){
        this.iceLearningCheckModel = payload[i];
        this.iceLearningCheckModel.isLearningCheckModified = false;
        this.iceLearningCheckList.push(this.iceLearningCheckModel);
			}
		}else{
			// no row data to show
    }
    
    setTimeout(() => {
      this.setEditor(CK_Editor_Type.LEARNING_CHECK_EDITOR, "");
    }, 200);
    if(this.iceLearningCheckList.length > 0){
      this.haveChildItems = true;
    }
  }

  /**
	 * load basic details of courses
	 * @param payload 
	 */
	loadICECourseList(payload:WsResponse['payload']){
    this.iceCourseModel = null;
		if(payload.length != 0){
			for(let i = 0; i < payload.length; i++){
        this.iceCourseModel = payload[i];
        this.iceCourseList.push(this.iceCourseModel);
      }
      this.iceCourseList.sort((a, b) => (a.fileName.toLowerCase() > b.fileName.toLowerCase()) ? 1 : -1);
		}else{
			// no row data to show
    }
    if(this.iceCourseList.length > 0){
      this.iceCoursesAvailable = true;
      if(this.topic.linkedCourseId != undefined || this.topic.linkedCourseId != null){
        this.selectedIceCourse = this.topic.linkedCourseId;
        // this.iceCourseList.forEach((courseItem : ICECourse) => {
        //   if(courseItem.courseId == this.topic.linkedCourseId){
        //     this.linkedCourse = courseItem.fileName;
        //   }
        // });
      }
    }
    
  }

  deleteItemFromTopicList(index: number){
    this.iceTopicList.splice(index,1);
      if(this.iceTopicList.length == 0){
        this.isShowIceTopic = false;
        this.haveChildItems = false;
      }
      else{
        // nothing to do here
      }
  }

  deleteIceTopicContentFromList(){
    if(this.iceTopicContentList.length > 0){
      this.iceTopicContentList.splice(this.deleteIceTopicContentIndex,1);
      if(this.iceTopicContentList.length == 0){
        this.isShowIceTopicContents = false;
        this.haveChildItems = false;
      }
      else{
        //nothing to do
      }
    }
	}
	
	addSpeChar(value: string) {
		if (value != "") {
			let decVal = Number(value);
			this.speCharList.push({ data: { symbol: this.getSymbolFromDecimal(decVal), decVal: decVal, type: "speChar" } });
		}
	}

	numberOnly(event): boolean {
		const charCode = (event.which) ? event.which : event.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}

	addFraction(num: string, den: string) {
    this.modalRef.hide()
		if (den != "" && num != "") {
			let denominator = parseInt(den);
			if (denominator != 0) {
				const frac = {
					numerator: num,
					denominator: den,
					type: 'fraction'
				};
				const fracStr = JSON.stringify(frac);
				let event = JSON.parse(fracStr);
				const fracObj = {
					data: event
				};
				const fracObjStr = JSON.stringify(fracObj);
				let data = JSON.parse(fracObjStr);
				this.iceCKEditor.insertTagEditArea(data, this.elementRef,editor,CKEDITOR, this.topic, "ICETopic" )
			} else {
				this.alertService.clear();
				this.alertService.error(this.translate.instant('WEB_EDITOR.DENOMINATOR_ZERO'));
			}
		} else {
			this.alertService.clear();
			this.alertService.error(this.translate.instant('WEB_EDITOR.NUMERATOR_AND_DENOMINATOR_EMPTY'));
		}
  }
  
  addSpecialCharacter(option: any){
    this.modalRef.hide()
		if(option != ""){
			this.iceCKEditor.insertTagEditArea(option, this.elementRef,editor,CKEDITOR, this.topic, "ICETopic")
		}
	}

  showSuccessAlert(en:string){
    var alert = null;
    this.translate.get(en).subscribe((res: string) => {
      alert = res;
    });
    this.alert.success(alert);
	}
	
	ngOnDestroy() {
		if (this.desceditor) {
			this.desceditor.destroy();
		} else {
			//no editor
    }
    if (this.titleditor) {
			this.titleditor.destroy();
		} else {
			//no editor
    }
    for (let i in this.editorList) {
			if (CKEDITOR.instances[this.editorList[i].name] != undefined) {
				CKEDITOR.instances[this.editorList[i].name].destroy();
			}
    }
    
    if (this.deleteTopicSubscription) {
			this.deleteTopicSubscription.unsubscribe()
      }

  }
  
  loadConflictData(payload: any) {       
		this.rowData = [];
		for (let i = 0; i < payload.length; i++) {
			this.rowData.push({
				type: payload[i]["type"],
				newVal: payload[i]["isDeleted"] == "false" ? payload[i]["newValue"] : "DELETED",
				lastVal: payload[i]["lastValue"],
				id: payload[i]["id"],
				name: payload[i]["name"],
				lastUpdateTimeStamp: payload[i]["lastUpdateTimeStamp"],
				isDeleted: payload[i]["isDeleted"]
			})
		}
		for (let x = 0; x < this.rowData.length; x++) {
			if (this.rowData[x].type == "Statement") {
				this.rowData.push(this.rowData.splice(x, 1)[0]);
			}
		}
	}

	public autoUpdateICE() {
		let selectedNodes = this.gridApi.getSelectedRows();
    let length = this.gridApi.getSelectedRows().length;
    if (length == 0) {
      this.alertService.clear();
			this.alertService.error(this.translate.instant('WEB_EDITOR.SELECT_REUSABLES_TO_UPDATE'));
    } else {
      this.modalRef.hide();      
      for (let i = 0; i < length; i++) {
        let id = selectedNodes[i].id;
        let type = selectedNodes[i].type;
        let flag = selectedNodes[i].isDeleted;
        if (type == 'TagNo') {
          let nodes = $(".toggle-edit" + this.tabId + this.userId).find("tag#" + id);
          for (let j = 0; j < nodes.length; j++) {
            let fldNode = nodes[j].childNodes[0];
            let fldName = $(fldNode).attr("name");
            if (fldName == 'tagNo') {
              if (flag == 'false') {
                $(fldNode).attr("lastupdatetimestamp", selectedNodes[i].lastUpdateTimeStamp);
                $(fldNode).attr("value", selectedNodes[i].newVal);
                fldNode.childNodes[0].textContent = selectedNodes[i].newVal;
              } else {
                let node = nodes[j];
                $(node).remove();
              }
            }
          }
        } if (type == 'TagSystem') {
          let nodes = $(".toggle-edit" + this.tabId + this.userId).find("tag#" + id);
          for (let j = 0; j < nodes.length; j++) {
            let fldNode = nodes[j].childNodes[0];
            let fldName = $(fldNode).attr("name");
            if (fldName == 'sysName') {
              if (flag == 'false') {
                $(fldNode).attr("lastupdatetimestamp", selectedNodes[i].lastUpdateTimeStamp);
                $(fldNode).attr("value", selectedNodes[i].newVal);
                fldNode.childNodes[0].textContent = selectedNodes[i].newVal;
              } else {
                let node = nodes[j];
                $(node).remove();
              }
            }
          }
        } if (type == 'TagDocument') {
          let nodes = $(".toggle-edit" + this.tabId + this.userId).find("tag#" + id);
          for (let j = 0; j < nodes.length; j++) {
            let fldNode = nodes[j].childNodes[0];
            let fldName = $(fldNode).attr("name");
            if (fldName == 'docName') {
              if (flag == 'false') {
                $(fldNode).attr("lastupdatetimestamp", selectedNodes[i].lastUpdateTimeStamp);
                $(fldNode).attr("value", selectedNodes[i].newVal);
                fldNode.childNodes[0].textContent = selectedNodes[i].newVal;
              } else {
                let node = nodes[j];
                $(node).remove();
              }
            }
          }
        } else if (type == 'TagDesc') {
          let nodes = $(".toggle-edit" + this.tabId + this.userId).find("tag#" + id)
          for (let j = 0; j < nodes.length; j++) {
            let fldNode = nodes[j].childNodes[0];
            let fldName = $(fldNode).attr("name");
            if (fldName == 'tagName') {
              if (flag == 'false') {
                let txtNode = fldNode.childNodes[0].childNodes[0]
                $(fldNode).attr("lastupdatetimestamp", selectedNodes[i].lastUpdateTimeStamp);
                txtNode.textContent = selectedNodes[i].newVal;
              } else {
                let node = nodes[j];
                $(node).remove();
              }
            }
          }
        } else if (type == 'Component' || type == 'Document' || type == 'Facility') {
          let nodes = $(".toggle-edit" + this.tabId + this.userId).find("com#" + id)
          for (let j = 0; j < nodes.length; j++) {
            let node = nodes[j];
            let compType = $(node).attr("compType");
            if (compType == 'doc' || compType == 'com' || compType == 'fac') {
              if (flag == 'false') {
                $(node).attr("lastupdatetimestamp", selectedNodes[i].lastUpdateTimeStamp);
                $(node).attr("compValue", selectedNodes[i].newVal);
                node.textContent = selectedNodes[i].newVal;
              } else {
                $(node).remove();
              }
            }
          }
        } else if (type == 'System Number') {
          let nodes = $(".toggle-edit" + this.tabId + this.userId).find("com#" + id)
          for (let j = 0; j < nodes.length; j++) {
            let node = nodes[j];
            let compType = $(node).attr("compName");
            if (compType == 'SystemNo') {
              if (flag == 'false') {
                $(node).attr("lastupdatetimestamp", selectedNodes[i].lastUpdateTimeStamp);
                $(node).attr("compvalue", selectedNodes[i].newVal);
                node.textContent = selectedNodes[i].newVal;
              } else {
                $(node).remove();
              }
            }
          }
        } else if (type == 'System Name') {
          let nodes = $(".toggle-edit" + this.tabId + this.userId).find("com#" + id)
          for (let j = 0; j < nodes.length; j++) {
            let node = nodes[j];
            let compType = $(node).attr("compName");
            if (compType == 'SystemName') {
              if (flag == 'false') {
                $(node).attr("lastupdatetimestamp", selectedNodes[i].lastUpdateTimeStamp);
                $(node).attr("compvalue", selectedNodes[i].newVal);
                node.textContent = selectedNodes[i].newVal;
              } else {
                $(node).remove();
              }
            }
          }
        } else if (type == 'Statement') {
          let oldStmNode: any;
          let newStmHTML: string = '';
          let nodes = $(".toggle-edit" + this.tabId + this.userId).find("statement#" + id)
          for (let j = 0; j < nodes.length; j++) {
            oldStmNode = nodes[j];
            if (flag == 'false') {
              newStmHTML = this.iceCKEditor.createEditedStatement(selectedNodes[i].name, selectedNodes[i].lastUpdateTimeStamp, oldStmNode)
              this.iceCKEditor.setOuterHTML(oldStmNode, newStmHTML);
            } else {
              $(oldStmNode).remove();
            }
          }
        } else if (type == 'TagAttribute') {
                  let nodes =  $(".toggle-edit" + this.tabId + this.userId).find("attr");
                  for (let j = 0; j < nodes.length; j++) {
                      let node = nodes[j];
                      let specId = $(node).attr("specid");
                      if(specId == id) {
                          if (flag == 'false') {
                              $(node).attr("lastupdatetimestamp", selectedNodes[i].lastUpdateTimeStamp);
                              $(node).attr("value", selectedNodes[i].newVal);
                              node.textContent = selectedNodes[i].newVal;
                          } else {
                              $(node).remove();
                          }
                      }
                  }
              }
      }
      this.alertService.clear();
      this.alertService.warn(this.translate.instant('PROCEDURE_EDITOR.CONTENT_UPDATE_MSG'), true);

    }		
  }
  
  cancel() {
		let index = this.breadCrumbList.length-1;
		this.sharedService.changeIceItemRequest(this.breadCrumbList[index].iceType, this.breadCrumbList[index]);
		this.breadCrumbList.length = index;
  }
  
  public openCancelConfirmationModel(template: TemplateRef<any>) {
      if(this.sharedService.getModifiedStatus(this.tabId)){
        this.modalRef = this.modalService.show(template, this.modalConfig);
      }else{
        this.cancel();
      }
	}
}
