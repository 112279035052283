import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams, IAfterGuiAttachedParams } from '@ag-grid-enterprise/all-modules';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-punchlist-docu-sign-cell-renderer',
  templateUrl: './punchlist-docu-sign-cell-renderer.component.html',
  styleUrls: ['./punchlist-docu-sign-cell-renderer.component.css']
})
export class PunchlistDocuSignCellRendererComponent implements ICellRendererAngularComp{

  docuSignStatus: any = "";
    docuSignId: any;
    workflow: boolean = false;
    gridParams: any;
    @ViewChild('docusignTemplate') docusignTemplate: TemplateRef<any>
    type: string;
    modalRef: BsModalRef;
    assetOrSystemName: string = "";
    system: any;
    
    public modalConfig: any = {
		backdrop: true,
		// ignoreBackdropClick: true,
		class: 'modal-lg',
		// keyboard: false
    }
    refresh(): boolean {
      return false;
    }
  constructor(private modalService: BsModalService) { }
  // refresh(): boolean {
  //   return false;
  // }
  agInit(params: any) : void{
    this.gridParams = params;
    if(params.data.itemStatus == 'Initial'){
        //if there is a docusign status for the Initial punchlist then the workflow label will be displayed
        //otherwise workflow button will be displayed
        if(params.data.docuSignStatus != null){
            this.docuSignStatus = this.statusRenderInitialPunchlist(params.data.docuSignStatus.toLowerCase())	
            this.docuSignId = params.data.docuSignId;
        } else{
            this.workflow = true;
        }
    } else if(params.data.itemStatus == 'Open'){
        //if there is a docusign status for the Open punchlist then the workflow label will be displayed
        //otherwise workflow button will be displayed
        if(params.data.docuSignStatus != null && params.data.docuSignStatus != 'completed'){
            this.docuSignStatus = this.statusRenderOpenPunchlist(params.data.docuSignStatus.toLowerCase())	
            this.docuSignId = params.data.docuSignId;
        } else{
            this.workflow = true;
        }
    } else if(params.data.itemStatus == 'Closed'){ 
        this.docuSignStatus = ''; //the workflow will be blank for the Closed punchlist
    } else{
        //do nothing
    }

    //check the item is asset/system
    if(params.data.assetName != '' && params.data.assetName != null && params.data.assetName != undefined){
        this.assetOrSystemName = params.data.assetName;
        this.system = params.data.assetName
    } else{
        this.assetOrSystemName = params.data.subSystemName
        this.system = params.data.subSystemName
    }
}

/**
 * Get the workflow label by docusign status for the Initial punchlist
 * @param status 
 */
public statusRenderInitialPunchlist(status: any) {
switch (status) {
  case 'waiting':
    return '<span class="badge badge-warning px-2 py-1">Open Waiting</span>'
  case 'sent':
    return '<span class="badge badge-warning px-2 py-1">Open Sent</span>'
  case 'declined':
            return '<span class="badge badge-danger px-2 py-1">Open Declined</span>'
        case 'delegated':
            return '<span class="badge badge-danger px-2 py-1">Open Delegated</span>'
  case 'error':
    return '<span class="badge badge-danger px-2 py-1">Open Error</span>'
  case 'failed':
    return '<span class="badge badge-danger px-2 py-1">Open Failed</span>'
  default:
    return ''
}
}

/**
 * Get the workflow label by docusign status for the Open punchlist
 * @param status 
 */
public statusRenderOpenPunchlist(status: any) {
switch (status) {
  case 'waiting':
    return '<span class="badge badge-warning px-2 py-1">Close Waiting</span>'
  case 'sent':
    return '<span class="badge badge-warning px-2 py-1">Close Sent</span>'
  case 'declined':
            return '<span class="badge badge-danger px-2 py-1">Close Declined</span>'
        case 'delegated':
            return '<span class="badge badge-danger px-2 py-1">Open Delegated</span>'
  case 'error':
    return '<span class="badge badge-danger px-2 py-1">Close Error</span>'
  case 'failed':
    return '<span class="badge badge-danger px-2 py-1">Close Failed</span>'
  default:
    return ''
}
}
viewPersonStatus(){
  this.type = 'PUNCHLIST_WORKFLOW'
  event.stopPropagation();
  this.modalRef = this.modalService.show(this.docusignTemplate, this.modalConfig);
}

public modelRefCloseEvent() {
  this.modalRef.hide()
}

}
