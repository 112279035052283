import { Component, OnInit, ViewChild, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SystemsService } from './../systems.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SharedService } from '../../../util/shared.service';
import { FACILITY_UI_VIEW } from '../../../util/constants';
import { HistoryService } from '../../history/history.service';
import { Constants, UI_LAYOUT, LocalStorageConst } from '../../../util/constants';
import { AlertService } from '../../../util/alert/alert.service';
import { DependenciesmodalComponent } from '../../../util/dependenciesmodal/dependenciesmodal.component';
import { LocalStorage } from '../../../util/localstorage.service';
import { WsCallback } from '../../../util/ws-callback.interface';
import { WsType } from '../../../util/ws-type';
import { WsResponse } from '../../../util/ws-response.model';
import { LoadingService } from '../../../util/loading/loading.service';
import { System, ReusableChangeInfo, ParentSystem, Facility } from "../system.model";
import { SelectComponent } from 'src/app/util/ng2-select/select';


@Component({
    selector: 'app-addeditsystems',
    templateUrl: './addeditsystems.component.html',
    styleUrls: ['./addeditsystems.component.css']
})
export class AddeditsystemsComponent implements OnInit {

    private modalRef: BsModalRef;
    public dataLoad: boolean = false;
    public isEdit: boolean = false;
    public showFilterOptions: boolean = false
    public system: System = new System();
    public savedSystem: any = null;
    //private systemId: String = null;
    public idForSelectedSystem: String;
    public idForSelectedFacility: String;
    private dependencyType: string;
    public defaultValue: string = "0";

    public facilityList: Array<any> = []
    public systemList: Array<any> = []

    /*public selectedFacility: Array<any> = [{ 'id': '-----', 'text': '-----' }]
    public selectedSystem: Array<any> = [{ 'id': '-----', 'text': '-----' , 'number': '-----'}]*/
    public selectedFacility: Array<any> = null;
    public selectedSystem: Array<any> = null;

    public facilityDisable: boolean = true;
    public systemDisable: boolean = true;

    public selectedFacilityIdValue: any;
    public selectedSystemIdValue: any;
    public selectedFacilityName: any;

    // Reusable Change Details
    public reusablesName: any;
    public reusablesModifiedBy: any;
    public reusableModifiedTime: any;
    public reusable: boolean = false;
    public reusableNo: boolean = false;
    public reusableParent: boolean = false;
    public reusablesNumber: any;
    public reusablesNoModifiedBy: any;
    public reusableNoModifiedTime: any;
    public reusablesParent: any;
    public reusablesParentModifiedBy: any;
    public reusableParentModifiedTime: any;

    public systemNum: any;
    public systemNamee: any;
    public parentSystem: any = null;
    public systemNoEmptyError: any;
    public systemNoSpaceError: any;
    public systemNameError: any;
    siblingDisplay: boolean = false;
    siblings: any;
    childDisplay: boolean = false;
    children: any;

    // Used in Commisioning
    public isCommissioning: boolean;

    //@ViewChild('myForm') formValues;
    @ViewChild('systemNo') systemNo: ElementRef;
    @ViewChild('systemName') systemName: ElementRef;
    //@ViewChild('facilityId') facilityID: ElementRef;
    //@ViewChild('parentSystemNo') parentSystemNo;
    @ViewChildren(SelectComponent) selectElem: QueryList<SelectComponent>

    constructor(private translate: TranslateService,
        private router: Router,
        private systemsService: SystemsService,
        private sharedService: SharedService,
        private modalService: BsModalService,
        private alertService: AlertService,
        public bsModalRef: BsModalRef,
        private loadingService: LoadingService
    ) {

        translate.addLangs(["es", "en"]);
        translate.setDefaultLang("en");
        let browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');

        this.translate.get('ADD_EDIT_SYSTEM.SYSTEM_NUMBER_EMPTY').subscribe((res: string) => {
            this.systemNoEmptyError = res;
            this.translate.get('ADD_EDIT_SYSTEM.SYSTEM_NAME_EMPTY').subscribe((res: string) => {
                this.systemNameError = res;
                this.translate.get('ADD_EDIT_SYSTEM.SYSTEM_NUMBER_SPACE').subscribe((res: string) => {
                    this.systemNoSpaceError = res;
                });
            });
        });
    }

    getType() {
        return localStorage.getItem("value");
    }

    ngOnInit() {

        /*
        //show system dropdown
        this.systemsService.getParentSystemList().subscribe(
            data => {
            this.systemList = [];
            if (data.payload != null) {
                data.payload.forEach(element => {
                    this.systemList.push({ 'id': element.systemId, 'text': element.systemName })
                    });
                    console.log("this.idForSelectedSystem " + this.idForSelectedSystem);
                    if(this.idForSelectedSystem != undefined){
                        this.selectedSystemDisplay(this.idForSelectedSystem);    
                    }
                    else{
                        // do nothing
                    }                                    
                } else {
                    // do nothing
            }
            },
            error => {
            //console.log('[getParentSystemList] (error) ' + JSON.stringify(error.statusDescription));
            this.alertService.error(error.statusDescription)
        });*/

        //show facility dropdown
        this.systemsService.getFacilityList().subscribe(
            data => {
                this.facilityList = [];
                if (data.payload != null) {
                    data.payload.forEach(element => {
                        if (element.status != "Inactive") {
                           this.facilityList.push({ 'id': element.facilityId, 'text': element.facilityName });
                        }
                        else {
                            // do nothing
                        }
                    });
                    this.facilityDisable = false;
                } else {
                    // do nothing
                }
            },
            error => {
                this.alertService.error(error.statusDescription)
            });

        // Previously saved system
        if (this.sharedService.getFileId() != "-1" && this.sharedService.getFileId() != -1) {
            this.savedSystem = true;
            this.isEdit = true;

            // Setting Basic Details of a System
            this.system.systemId = this.sharedService.getFileId();
            this.system.facilityId = localStorage.getItem("selectedFacilityIdSystem");
            this.system.parentId = localStorage.getItem("selectedParentIdSystem");
           // console.log(JSON.stringify(this.system));
            console.log(this.system)
            // Get Details of the System
            this.systemsService.getSystemDataBySystemId(this.system.systemId, this.system.facilityId, this.system.parentId).subscribe(
                data => {
                    if (data.payload != null) {

                        // Inserting data.payload to system model
                        this.system = this.setSystem(data.payload);
                        this.isCommissioning = data.payload.isCommissioning;
                        console.log("System: " + JSON.stringify(this.system));

                        //Load Siblings.
                        this.systemsService.searchChildandSibling(this.system.systemId, this.system.facilityId, this.system.parentId, this);

                        // Handling if there is reusable changes
                        if (this.system.reusableChangeInfo != null) {
                            this.reusable = true;
                            this.reusablesName = "Old Value : " + this.system.reusableChangeInfo.oldValue;
                            this.reusablesModifiedBy = "Modified By : " + this.system.reusableChangeInfo.changeInitiatedUserName;
                            if (this.system.reusableChangeInfo.changeInitiatedTimeStampFormatted != null) {
                                this.reusableModifiedTime = "Modified Time : " + this.system.reusableChangeInfo.changeInitiatedTimeStampFormatted;
                            }
                            else {
                                // nothing to do
                            }
                            this.system.systemName = this.system.reusableChangeInfo.newValue;
                            //this.isCommissioning = this.system.reusableChangeInfo.newValue;
                        } else {
                            this.reusable = false;
                        }
                        if (this.system.reusableChangeInfoNo != null) {
                            this.reusableNo = true;
                            this.reusablesNumber = "Old Value : " + this.system.reusableChangeInfoNo.oldValue;
                            this.reusablesNoModifiedBy = "Modified By : " + this.system.reusableChangeInfoNo.changeInitiatedUserName;
                            if (this.system.reusableChangeInfoNo.changeInitiatedTimeStampFormatted != null) {
                                this.reusableNoModifiedTime = "Modified Time : " + this.system.reusableChangeInfoNo.changeInitiatedTimeStampFormatted;
                            }
                            else {
                                // nothing to do
                            }
                            this.system.systemNo = this.system.reusableChangeInfoNo.newValue;
                            //this.isCommissioning = this.system.reusableChangeInfo.newValue;
                        } else {
                            this.reusableNo = false;
                        }                        
                        if (this.system.reusableChangeInfoParent != null) {
                            this.reusableParent = true;
                            this.reusablesParent = "Old Value : " + this.system.reusableChangeInfoParent.oldValue;
                            this.reusablesParentModifiedBy = "Modified By : " + this.system.reusableChangeInfoParent.changeInitiatedUserName;
                            if (this.system.reusableChangeInfoParent.changeInitiatedTimeStampFormatted != null) {
                                this.reusableParentModifiedTime = "Modified Time : " + this.system.reusableChangeInfoParent.changeInitiatedTimeStampFormatted;
                            }
                            else {
                                // nothing to do
                            }
                            this.system.selectedParentSystem = this.system.reusableChangeInfoParent.newValue;
                            this.system.parentId = this.system.reusableChangeInfoParent.newValue;
                        } else {
                            this.reusableParent = false;
                        }

                        console.log("this.idForSelectedSystem = " + this.system.parentId);
                        if (this.system.facilityId != undefined && this.system.facilityId != null) {
                            this.selectedFacilityDisplay(this.system.facilityId);

                            this.systemsService.getAllActiveParentSystemsByFacility(this.system.facilityId).subscribe(
                                data => {
                                    this.systemList = [];
                                    if (data.payload != null) {
                                        data.payload.forEach(element => {
                                            if (element.id != this.system.systemId) {
                                                if (element.parentId != this.system.systemId) {
                                                    let sysNo: string = element.systemNo;
                                                    if (element.parentSystemNo != null) {
                                                        sysNo = this.removeParentSysNo(sysNo, element.parentSystemNo);
                                                        this.systemList.push({ 'id': element.id, 'text': element.parentSystemNo + "-" + sysNo + " "+ element.systemName, 'number': sysNo });
                                                    } else {
                                                        this.systemList.push({ 'id': element.id, 'text': sysNo + " " + element.systemName, 'number': sysNo });
                                                    }
                                                } else {
                                                    //Child Systems of current System
                                                }
                                            } else {
                                                // do nothing
                                            }
                                        });
                                        this.systemDisable = false;
                                        if (this.system.parentId != undefined && this.system.parentId != null) {
                                            this.setParentSystem(); //set parent system to the system list
                                            this.selectedSystemDisplay(this.system.parentId);
                                            this.systemsService.searchChildandSibling(this.system.systemId, this.system.facilityId, this.system.parentId, this);
                                        } else {
                                            // nothing to do
                                        }
                                    } else {
                                        // do nothing
                                    }
                                },
                                error => {
                                    //console.log('[getParentSystemList] (error) ' + JSON.stringify(error.statusDescription));
                                    this.alertService.error(error.statusDescription)
                                });
                        } else {
                            this.systemsService.getAllActiveParentSystemsByFacility(this.defaultValue).subscribe(
                                data => {
                                    this.systemList = [];
                                    if (data.payload != null) {
                                        data.payload.forEach(element => {
                                            if (element.id != this.system.systemId) {
                                                if (element.parentId != this.system.systemId) {
                                                    let sysNo: string = element.systemNo;
                                                    if (element.parentSystemNo != null) {
                                                        let search: string = element.parentSystemNo + "-";
                                                        sysNo = sysNo.replace(search, "");
                                                        this.systemList.push({ 'id': element.id, 'text': search+sysNo+"  "+element.systemName, 'number': sysNo });
                                                    } else{
                                                        console.log("Edit Systems without facility")
                                                        this.systemList.push({ 'id': element.id, 'text': sysNo + " " + element.systemName, 'number': sysNo });
                                                    }
                                                } else {
                                                    //Child Systems of current System
                                                }
                                            } else {
                                                // do nothing
                                            }
                                        });
                                        this.systemDisable = false;
                                        if (this.system.parentId != undefined && this.system.parentId != null) {
                                            this.setParentSystem(); //set parent system to the system list
                                            this.selectedSystemDisplay(this.system.parentId);
                                        } else {
                                            // do nothing
                                        }
                                    } else {
                                        // do nothing
                                    }
                                },
                                error => {
                                    this.alertService.error(error.statusDescription)
                                });
                        }
                        console.log("this.idForSelectedFacility = " + this.system.facilityId);
                    } else {
                        //do nothing
                    }
                },
                error => {
                    this.alertService.clear()
                    this.alertService.error(error.statusDescription)
                });
            localStorage.setItem("value", "0");
        }
        // New System
        else {
            this.dataLoad = true;

            // Get systems which there are no facilities => selectedFalicilityIdValue = "0"
            this.systemsService.getAllActiveParentSystemsByFacility(this.defaultValue).subscribe(
                data => {
                    this.systemList = [];
                    if (data.payload != null) {
                        data.payload.forEach(element => {
                            let sysNo: string = element.systemNo;
                            if (element.parentSystemNo != null) {
                                let search: string = element.parentSystemNo + "-";
                                sysNo = sysNo.replace(search, "");
                                this.systemList.push({ 'id': element.id, 'text': search+sysNo+"  "+element.systemName, 'number': sysNo });
                            } else {
                                this.systemList.push({ 'id': element.id, 'text': sysNo+"  "+element.systemName, 'number': sysNo });
                            }
                           // this.systemList.push({ 'id': element.id, 'text': element.systemName, 'number': sysNo });
                        });
                        this.systemDisable = false;
                    } else {
                        // do nothing
                    }
                },
                error => {
                    this.alertService.error(error.statusDescription)
                });

        }


    }

    /**
     * Achini Randeni
     * Set already assigned parent system to the system list
     */
    setParentSystem(){
        var hasParentIdInList: boolean = false;
        //check whether the system list contains the parent system
        this.systemList.forEach(system => {
            if(system.id === this.system.parentId){
                hasParentIdInList = true;
            }
        })

        //if the system list doesn't contain the parent system, then add it into the system list
        if(!hasParentIdInList){
            this.systemList.push({ 'id': this.system.parentId, 'text': this.system.parentSystemNo + " " + this.system.parentSystemName, 'number': this.system.parentSystemNo });
        }
    }

    selectedFacilityDisplay(facilityId) {
        console.log("selectedFacilityDisplay() - facilityId: " + facilityId);

        // Set selected facility
        this.system.selectedFacility = this.getFacilityNameById(facilityId);
        console.log("facilityId " + JSON.stringify(this.system.selectedFacility));

    }

    selectedSystemDisplay(parentId) {
        console.log("selectedSystemDisplay() - parentId: " + parentId);

        // Set selected parent system
        this.system.selectedParentSystem = this.getParentSystemNameById(parentId);
        console.log("parent: " + JSON.stringify(this.system.selectedParentSystem));

    }

    viewDependencies(dflag: boolean) {
        this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.VIEW;
        this.dependenciesModalWithComponent('Dependencies List', false, true);
    }

    dependenciesModalWithComponent(header: String, dflag: boolean, cflag: boolean) {
        const initialState : object = {
            headerTitle: header,
            delFlag: dflag,
            cancelFlag: cflag,
            checkboxVal: false
        };
        this.sharedService.setFileId(this.system.systemId);
        this.sharedService.setFileType(Constants.DEPENDENCY_ITEM_TYPE.SYSTEM);
        this.sharedService.setAction("getDependencies");
        this.sharedService.setDependencyMessageType(this.dependencyType);
        this.sharedService.setParent(this);
        this.modalRef = this.modalService.show(DependenciesmodalComponent, { initialState, class: 'modal-lg' });
    }

    public selectedFacilityId(value: any): void {
        console.log("Selected Facility Value: " + JSON.stringify(value));
       
        if (this.system.facilityId != value.id) {
            //Achini Randeni
            //Display warning message if the system has children
            if(this.system.children != undefined && this.system.children.length > 0){
                this.alertService.clear()
                this.translate.get('SYSTEM_ADD_EDIT.FACILITY_CHANGE_WARNING').subscribe((res: string) => {
                  this.alertService.warn(res);
                });
            }

            this.systemDisable = true;
            // Update facility details
            this.system.facilityName = value.text;
            this.system.facilityId = value.id;
            this.system.selectedFacility = this.facilityList.filter(x => x.id == this.system.facilityId);

            // Remove selected parent system details
            this.system.selectedParentSystem = null;
            this.system.parentId = null;
            this.system.parentSystemNo = null;

            // Remmove sibilings and children
            this.siblingDisplay = false;
            this.childDisplay = false;
            this.system.siblings = [];
            this.system.children = [];

            // Load system list
            this.systemsService.getAllActiveParentSystemsByFacility(this.system.facilityId).subscribe(
                data => {
                    this.systemList = [];
                    if (data.payload != null) {
                        data.payload.forEach(element => {
                            if (element.id != this.system.systemId) {
                                let sysNo: string = element.systemNo;
                                if (element.parentSystemNo != null) {
                                    let search: string = element.parentSystemNo + "-";
                                    sysNo = sysNo.replace(search, "");
                                    this.systemList.push({ 'id': element.id, 'text': search+sysNo+"  "+element.systemName, 'number': sysNo });
                                } else {
                                    this.systemList.push({ 'id': element.id, 'text': sysNo+"  "+element.systemName, 'number': sysNo });
                                }
                            }
                            else {
                                // do nothing
                            }
                        });
                        this.systemDisable = false;
                        if (this.system.parentId != undefined && this.system.parentId != null) {
                            this.selectedSystemDisplay(this.system.parentId);
                        }
                        else {
                            this.systemsService.searchChildandSibling(this.system.systemId, this.system.facilityId, this.system.parentId, this);
                        }
                    } else {
                        // do nothing
                    }
                },
                error => {
                    //console.log('[getParentSystemList] (error) ' + JSON.stringify(error.statusDescription));
                    this.alertService.error(error.statusDescription)
                });
        }
        else {
            // nothing to do
        }
    }

    public selectedSystemId(value: any): void {
        console.log("select system:" + JSON.stringify(value));

        // Update parent system details        
        this.system.parentId = value.id;
        this.system.selectedParentSystem = this.systemList.filter(x => x.id == this.system.parentId);
        this.system.parentSystemNo = this.system.selectedParentSystem["number"];

        console.log("Selected Parent System: " + JSON.stringify(this.system));
        // Get sibilings/ children details
        if (this.systemNoEmptyError.facilityID == null) {
            this.systemsService.searchChildandSibling(this.system.systemId, this.defaultValue, this.system.parentId, this);
        } else {
            this.systemsService.searchChildandSibling(this.system.systemId, this.system.facilityId, this.system.parentId, this)
        }
    }

    public removedFacility(value: any): void {
        console.log("removeSelectedFacility");
        //Achini Randeni
        //Display warning message if the system has children
        if(this.system.children != undefined && this.system.children.length > 0){
            this.alertService.clear()
            this.translate.get('SYSTEM_ADD_EDIT.FACILITY_CHANGE_WARNING').subscribe((res: string) => {
              this.alertService.warn(res);
            });
        }

        this.systemDisable = true;

        // Update facility details
        this.system.facilityName = "";
        this.system.facilityId = null;
        this.system.selectedFacility = null;

        // Remove selected parent system details
        this.system.selectedParentSystem = null;
        this.system.parentId = null;
        this.system.parentSystemNo = null;

        // Remmove sibilings and children
        this.siblingDisplay = false;
        this.childDisplay = false;
        this.system.siblings = [];
        this.system.children = [];

        console.log("Removed Facility Value: " + value);

        this.systemsService.getAllActiveParentSystemsByFacility(this.defaultValue).subscribe(
            data => {
                this.systemList = [];
                if (data.payload != null) {
                    data.payload.forEach(element => {
                        if (element.id != this.system.systemId) {
                            let sysNo: string = element.systemNo;
                            if (element.parentSystemNo != null) {
                                let search: string = element.parentSystemNo + "-";
                                sysNo = sysNo.replace(search, "");
                                this.systemList.push({ 'id': element.id, 'text': search+sysNo+"  "+element.systemName, 'number': sysNo });
                            } else {
                                this.systemList.push({ 'id': element.id, 'text': sysNo + " " + element.systemName, 'number': sysNo });
                            }
                            
                        }
                        else {
                            // do nothing
                        }
                    });
                    this.systemDisable = false;
                    if (this.system.parentId != undefined && this.system.parentId != null) {
                        this.selectedSystemDisplay(this.system.parentId);
                    }
                    else {
                        // do nothing
                    }
                } else {
                    // do nothing
                }
            },
            error => {
                //console.log('[getParentSystemList] (error) ' + JSON.stringify(error.statusDescription));
                this.alertService.error(error.statusDescription)
            });
    }

    public removedSystem(value: any): void {
        console.log("removeSelectedSystem: " + JSON.stringify(value));

        // Remove selected parent system details
        this.system.selectedParentSystem = null;
        this.system.parentId = null;
        this.system.parentSystemNo = null;

        // Remmove sibilings and children
        this.siblingDisplay = false;
        this.childDisplay = false;
        this.system.siblings = [];
        this.system.children = [];


    }

    getFacilityNameById(id) {
        //this.selectedFacilityIdValue = id;
        return this.facilityList.filter(x => (x.id === id));
    }

    getParentSystemNameById(id) {
        //this.selectedSystemIdValue = id;
        return this.systemList.filter(x => (x.id === id));
    }

    save() {
        if (this.sharedService.getFileId() != "-1" && this.sharedService.getFileId() != -1) {
            console.log("System " + JSON.stringify(this.system));

            // Validating
            if (this.system.systemNo == undefined || this.system.systemNo == null || this.system.systemNo == "" || this.system.systemNo.trim() == "") {
                this.alertService.clear();
                this.alertService.error(this.systemNoEmptyError);
            } else if (this.system.systemName == undefined || this.system.systemName == null || this.system.systemName == "" || this.system.systemName.trim() == "") {
                this.alertService.clear();
                this.alertService.error(this.systemNameError);
            } else {
                this.loadingService.showLoading(true, false, this.translate.instant('SAVING'), 0);

                let systemNumber: any = "";
                if (this.system.selectedParentSystem != null) {
                    if (this.system.selectedParentSystem[0]["number"] != null) {
                        systemNumber += this.system.selectedParentSystem[0]["number"] + "-";
                    }
                }
                systemNumber += this.system.systemNo;
                console.log("this is save" + this.isCommissioning);
                // Sending the update request and handling the response
                this.systemsService.updateSystem(this.sharedService.getFileId(), this.system.systemNo,
                    this.system.systemName, this.system.facilityId, this.system.parentId, this.isCommissioning).subscribe(
                        data => {
                            //Comment this to remove updating UI and add user message
                            /*this.systemsService.setItemData(this.sharedService.getFileId(), systemNumber,
                                this.system.systemName, this.system.facilityName, this.system.isCommissioning);*/
                            this.bsModalRef.hide();
                            this.alertService.clear();
                            this.alertService.info("System Edited Successfully" +". " +this.translate.instant("REUSABLES.REFRESH_REUSABLE_TABLE"));				
                            this.loadingService.hideLoading();
                        },
                        error => {
                            this.alertService.clear();
                            this.alertService.error(error.statusDescription);
                            this.loadingService.hideLoading();
                        });
            }
        } else {
            console.log("System: " + JSON.stringify(this.system));

            // Validating
            if (this.system.systemNo == undefined || this.system.systemNo == null || this.system.systemNo == "" || this.system.systemNo.trim() == "") {
                this.alertService.clear();
                this.alertService.error(this.systemNoEmptyError)
            } else if (this.system.systemName == undefined || this.system.systemName == null || this.system.systemName == "" || this.system.systemName.trim() == "") {
                this.alertService.clear();
                this.alertService.error(this.systemNameError)
            } else {
                this.loadingService.showLoading(true, false, this.translate.instant('SAVING'), 0);

                // Sending the create request and handle the response
                /*let to: any = false;
                if (to) { clearTimeout(to); }
                to = setTimeout(()=>{
                    this.systemsService.addSystem(
                        this.system.systemNo,
                        this.system.systemName,
                        this.system.facilityId,
                        this.system.parentId).subscribe(
                            data => {
                                this.alertService.clear();
                                this.alertService.success("System Added Successfully");
                                this.loadingService.hideLoading();
                            },
                            error => {
                                this.alertService.clear();
                                this.alertService.error(error.statusDescription);
                                this.loadingService.hideLoading();
                            });
                },400)*/
                this.systemsService.addSystem(
                    this.system.systemNo,
                    this.system.systemName,
                    this.system.facilityId,
                    this.system.parentId,
                    this.isCommissioning).subscribe(
                        data => {
                            this.alertService.clear();
                            this.alertService.info("System Added Successfully" +". " +this.translate.instant("REUSABLES.REFRESH_REUSABLE_TABLE"));			
                            this.loadingService.hideLoading();
                            this.bsModalRef.hide();
                        },
                        error => {
                            this.alertService.clear();
                            this.alertService.error(error.statusDescription);
                            this.loadingService.hideLoading();
                        });
                // this.bsModalRef.hide()
            }
        }
    }

    cancel() {
        this.alertService.clear()
        this.bsModalRef.hide()
    }

    onSuccess(data: WsResponse, serviceType: WsType): void {
        if (data.payload != null && serviceType == WsType.SYSTEM_DATA_VIEW) {
            this.system = data.payload;
            this.dataLoad = true;
        } else if (serviceType == WsType.SYSTEM_UPDATE) {
            // this.router.navigateByUrl("home")
            this.bsModalRef.hide()
        } else if (data.payload != null && serviceType == WsType.GET_SIBLING) {
            if (data.payload[0] != null && data.payload[0].length > 0) {
                this.siblingDisplay = true;
                this.system.siblings = data.payload[0];
                console.log(this.system.siblings)
            } else {
                this.siblingDisplay = false;
            }
            if (data.payload[1] != null && data.payload[1].length > 0) {
                this.childDisplay = true;
                this.system.children = data.payload[1];
            } else {
                this.childDisplay = false;
            }
        }
        else if (data.payload == null && serviceType == WsType.GET_SIBLING) {
            this.siblingDisplay = false;
            this.childDisplay = false;
        }
    }

    onFail(res: WsResponse, serviceType: WsType): void {
    }

    setSystem(data: any): System {
        let system = new System();
        system.reusableChangeInfo = new ReusableChangeInfo();
        system.reusableChangeInfoNo = new ReusableChangeInfo();
        if (data != null) {
            system = data;
            if (data.reusableChangeInfo != null) {
                system.reusableChangeInfo = data.reusableChangeInfo;
            }
            else {
                // nothing to do
            }
            if (data.reusableChangeInfoNo != null) {
                system.reusableChangeInfoNo = data.reusableChangeInfoNo;
            }
            else {
                // nothing to do
            }
        }
        return system;
    }

    removeParentSysNo(str: string, search: string): string {
        if (search != null) {
            str = str.replace(search + "-", "");
        }
        else {
            // nothing to do
        }
        return str;
    }

    public closeOtherElems(element: any) {
        let a = this.selectElem.filter(function (el) {
            return (el != element)
        });

        a.forEach(function (e: SelectComponent) {
            e.hideOptions();
        })
    }
}
