import {Component, TemplateRef, ViewChild} from "@angular/core";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TagAttributesMapping } from 'src/app/reusables/tags/edit-tags/change-tagclassification.model';
import { AddEditPCCComponent } from './addeditpcc/addeditpcc.component';
import { ApprovepccComponent } from "./approvepcc/approvepcc.component";
import { LoadingService } from 'src/app/util/loading/loading.service';
import { ManagePCCService } from './managepcc.service';
import { WsResponse } from 'src/app/util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';
import { AlertService } from 'src/app/util/alert/alert.service';
import * as FileSaver from 'file-saver';
import { UserVariable } from 'src/app/util/common/user-variable';

@Component({
    selector: 'app-pcc-cell-renderer',
    templateUrl: './managepcc-cell-renderer.component.html'
})
export class ManagePCCCellRendererComponent{
    public type: string;
    public data: any;
    public icon: any;
    private status: any;
    private isUploaded: string;
    private pccId: string;
    private systemName: string;
    private systemId: string;
    bsModalRef: BsModalRef;
    private docuSignStatus: string;
    public pccDeleteMsg: string;
    @ViewChild("deletePCCModal") deletePCCModal: TemplateRef<any>;
    public deleteModalRef: BsModalRef;
    approved: boolean = false

    constructor(private modalService: BsModalService, private loadingService: LoadingService, 
        private managePCCService: ManagePCCService, private alertService: AlertService){
    }

    agInit(params: any){
        if(params.data){
            this.pccId = params.data.pcId;
            this.systemId = params.data.systemId;
            this.systemName = params.data.system;
            this.docuSignStatus = params.data.docusignStatus;
        }

        let items = params.value.split(':')
        if (items.length >= 2) {
            this.status = items[1];
            if (items.length == 3) {
                this.isUploaded = items[2];
            }
        }
        this.setType(items[0]);
    }

    private setType(type: string){
        this.type = type;
        if(type == 'edit'){
            this.icon = 'far fa-edit';
        } else if(type == 'delete'){
            if(this.status  == 'Issued' || this.status  == 'Approved' || this.status  == "Error"){
                this.icon = 'far fa-undo'
            } else{
                //do nothing
            }
        } else if(type == 'approve'){
            if(this.status == 'Issued'){
                this.icon = 'far fa-check-circle';
                if(this.docuSignStatus != null){
                    if(this.docuSignStatus.toLowerCase() == 'declined'){
                        this.approved = true
                    }
                }
            } else if(this.status == 'Approved'){
                this.icon = 'far fa-check-circle';
            } else{
                //do nothing
            }
        } else if(type == 'sentpdf'){
            if(this.status == 'Issued' || this.status == 'Approved'){
                this.icon = 'fas fa-file-pdf';
            } else{
                //do nothing
            }
        } else if(type == 'returnedpdf'){
            if((this.status == 'Issued' || this.status == 'Approved') && this.isUploaded == 'true'){
                this.icon = 'fas fa-file-pdf';
            } else{
                //do nothing
            }
        } else{
            //do nothing
        }
    }

    public invoke(type: string, data: any){
        const initialState = {
            pccId : this.pccId,
            systemName : this.systemName,
            systemId : this.systemId,
            pccStatus: this.status,
            isUploaded: this.isUploaded == "true" ? true : false
        }
        switch (type) {
            case 'sentpdf':
                this.downloadSentFile(this.pccId, this.systemName);
                break;
            case 'returnedpdf':
                this.downloadApprovalFile(this.pccId, this.systemName);
                break;
            case 'approve':
                this.bsModalRef = this.modalService.show(ApprovepccComponent, { class: 'modal-xl', backdrop: 'static', keyboard: false, initialState })
                break;
			case 'edit':
                this.bsModalRef = this.modalService.show(AddEditPCCComponent, {  backdrop: 'static', keyboard: false, initialState })
                break;
			case 'delete':
			    this.deleteConfirm();
                break;
            default:
                break;
        }
    }

    public deleteConfirm() {
        this.pccDeleteMsg = "Are you sure you want to Revoke the Precommissioning Completion Certificate?"
        this.deleteModalRef = this.modalService.show(this.deletePCCModal)
    }

    public  revokeDeletePCC(){
        this.loadingService.showLoading(true, null, "", null);
        this.managePCCService.revokeProcommissioningCompletion(this.pccId);
        this.deleteModalRef.hide()
    }

    public cancelDeltePCC(){
        this.pccDeleteMsg = null;
        if(this.deleteModalRef){
            this.deleteModalRef.hide()
        }
    }

    downloadSentFile(pccId: string, systemName: string){
        this.loadingService.showLoading(false, false, '', 0)
		this.managePCCService.downloadSentFile(UserVariable.projectId, pccId).subscribe(
			response => {
				this.loadingService.hideLoading()
				let file = new Blob([response], { type: 'application/pdf' });
				FileSaver.saveAs(file, "PCC_" + systemName + ".pdf");
			},
			error => {
				this.loadingService.hideLoading()
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			});
    }
    
    downloadApprovalFile(pccId: string, systemName: string){
        this.loadingService.showLoading(false, false, '', 0)
		this.managePCCService.downloadApprovedFile(UserVariable.projectId, pccId).subscribe(
			response => {
				this.loadingService.hideLoading()
				let file = new Blob([response], { type: 'application/pdf' });
				FileSaver.saveAs(file, "PCC_" + systemName + ".pdf");
			},
			error => {
				this.loadingService.hideLoading()
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			});
	}
}