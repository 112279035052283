import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DocumentTypesService } from '../document-types.service';
import { UserVariable } from '../../../../util/common/user-variable';
import { AlertService } from '../../../../util/alert/alert.service';
import { Router } from '@angular/router';
import { LoadingService } from '../../../../util/loading/loading.service';

@Component({
   selector: 'delete-modal',
   template: `   
   <div class="modal-header">
      <h4 class="modal-title pull-left">{{ title | translate }}</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <div class="container">
         <h6 class="text-center">
            {{ bodyText | translate }}
         </h6>
      </div>
   </div>
   <div class="modal-footer">     
      <button type="button" class="btn btn-primary btn-sm" (click)="deleteItemClick(); bsModalRef.hide()">{{ 'OK' | translate}}</button>
      <button type="button" class="btn btn-secondary btn-sm" (click)="bsModalRef.hide()">{{ 'CANCEL' | translate}}</button>
   </div>  
   `
})
export class DeleteDocAttributeTypeModalComponent {
   public title: string = 'Delete Confirm';
   public bodyText: string = 'Are you sure you want to delete this DocumentTypeAttribute?'
   public itemId: string
   public dependencylist: Array<any> = [];

   constructor(public bsModalRef: BsModalRef, private documentTypesService: DocumentTypesService, 
      private alertService: AlertService, private router: Router, private loadingService: LoadingService) { 

      }

   ngOnit(){

   }

   public deleteItemClick() {
      this.loadingService.showLoading(true, null, "", null)
         this.documentTypesService.deleteDocTypeAttribute(this.itemId)
         .subscribe(data => {
            this.loadingService.hideLoading()
            this.alertService.success(data.statusDescription)
            this.documentTypesService.deleteRowData(this.itemId)
            //location.reload()
         },
         error => {
            this.loadingService.hideLoading()
            this.alertService.error(error.statusDescription)
         });
   }
      
}