import { Component, OnInit, Input, HostListener, TemplateRef } from '@angular/core';
import { SharedService } from '../../util/shared.service';
import { THREED_MODEL_UI_VIEW, SessionStorageConst } from '../../util/constants';
import { Constants, UI_LAYOUT, LocalStorageConst } from '../../util/constants';
import { BsModalService } from 'ngx-bootstrap/modal'
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService } from '../../util/alert/alert.service';
import { GridOptions } from "@ag-grid-enterprise/all-modules";
import { ThreedModelService } from './threed-model.service';
import { SystemsService } from '../systems/systems.service';
import { TranslateService } from '@ngx-translate/core';
import { observable } from 'rxjs';

@Component({
  selector: 'app-threed-model',
  templateUrl: './threed-model.component.html',
  styleUrls: ['./threed-model.component.css']
})
export class ThreedModelComponent implements OnInit {

	public divHeight: number = (window.innerHeight) * 4 / 5;
	base64Data: any;
	base64DataImage: any;
	@HostListener('window:resize', ['$event'])
	onresize(event?) {
		this.divHeight = (window.innerHeight) * 4 / 5
	}

	public constants: any

	public threeDModelName: string = '3D Model Name'
	modalRef: BsModalRef;
	private docType: string = "";
	private description: string = "";
	private id: string = "";
	public title: string = "";
	public width: string = "";
	public height: string = "";
	private count = 0;
	changeLayout: boolean = false
	public imageUIV: any = THREED_MODEL_UI_VIEW;
	private hasText: string = "";
	public gridOptions: GridOptions;
	public threedmodel: Array<any> = [];
	public url: String;
	public urlList: Array<any> = [];
	public selectedURL: any;
	public layerContent: any;
	public viewContent: any;
	public modelId: String;

	thumbnailThreeDModel: any;
	fullThreeDModel: any;
	threeDModelPath: any;
	threeDModelThumbPath: any;
	threeDModelSrc: any;
	thumbPath: any;
	threed: any;
	public selectedThreeDModel: any = {
		'id': '',
		'largeThreeDModel': '',
		'thumbnailThreeDModel': '',
		'title': '',
		'folderID': '',
		'folderPath': ''
	};
	public flippedThreeDModel: any = {
		'id': '',
		'largeThreeDModel': '',
		'thumbnailThreeDModel': '',
		'title': '',
		'folderID': '',
		'folderPath': ''
	}
	public modalConfig: any = {
		backdrop: true,
		ignoreBackdropClick: true,
		class: 'modal-lg',
		keyboard: false
	}
	public isOpen: boolean = false
	public threeDModelSearchState: string = Constants.LOADING_STATES.INITIAL

	constructor(private sharedService: SharedService,
		private modalService: BsModalService, private alertService: AlertService, private ThreeDModelService: ThreedModelService,
		private systemsService: SystemsService, private translate: TranslateService
	) {
		this.constants = Constants;
		translate.addLangs(["es", "en"]);
		translate.setDefaultLang("en");
		let browserLang = translate.getBrowserLang();
		translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
	}

	ngOnInit() {
		let uiLayout = JSON.parse(sessionStorage.getItem(SessionStorageConst.UI_LAYOUT));
		this.threedmodel = this.ThreeDModelService.getThreeDModelList();
		this.threeDModelSearchState = this.ThreeDModelService.getThreeDModelSearchState();
		if (uiLayout == UI_LAYOUT.UIL_2) {
			this.changeLayout = false
		} else {
			this.changeLayout = true
		}
		this.urlList = this.ThreeDModelService.getURLList();
	}

	ngAfterViewInit(): void {
		//Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
		//Add 'implements AfterViewInit' to the class.
		this.threedmodel = this.ThreeDModelService.getThreeDModelList();
		this.threeDModelSearchState = this.ThreeDModelService.getThreeDModelSearchState();
		this.sharedService.expandReusablesExcute().subscribe(option => this.changeComponent(option))
	}

	changeComponent(option: any) {
		console.log('[changeComponent] (option) ' + JSON.stringify(option));
	}

	searchFunc(event: any) {
		this.resetSelected()
		this.threedmodel = [];
		console.log('[searchFunc] (event) ' + JSON.stringify(event));
		console.log('[searchFunc] (threedmodel.length) ' + JSON.stringify(this.threedmodel.length));
		this.divHeight = (window.innerHeight) * 4 / 5;

		this.docType = "";
		this.description = "";
		this.hasText = "";
		this.count = 0;

		this.threeDModelSearchState = Constants.LOADING_STATES.LOADING

		//assign value for the fields from searchWordMap
		var docType = event.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_THREED_MODEL_DOCTYPE];
		if (docType != undefined) {
			this.docType = docType;
		} else {
			//no docType
		}
		var description = event.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_THREED_MODEL_DESCRIPTION];
		if (description != undefined) {
			this.description = description;
		} else {
			//no description
		}
		var hasText = event.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_HAS_THE_WORDS];
		if (hasText != undefined) {
			this.hasText = hasText;
		} else {
			//no has text
		}
		if (this.docType != "" || this.description != "" || this.hasText != "") {
			let to: any = false;
			if (to) { clearTimeout(to); }
			to = setTimeout(() => {
				this.ThreeDModelService.searchThreeDModel(this.docType, this.description, this.hasText).subscribe(
					data => {
						var obj = JSON.parse(data.payload);
						console.log(obj);
						if (obj.threedmodels.threedmodel) {
							if (obj.threedmodels.threedmodel.length > 0 || obj.threedmodels.threedmodel.id) {		
								this.getThreeDModelDetail(obj)
							} else {
								this.threeDModelSearchState = Constants.LOADING_STATES.EMPTY_RESULT
							}
						} else {
							//no images
							this.threeDModelSearchState = Constants.LOADING_STATES.EMPTY_RESULT
						}
					},
					error => {
						this.alertService.clear()
						this.alertService.error(error.statusDescription)
					});
			}, 200);
		} else {
			this.threeDModelSearchState = Constants.LOADING_STATES.EMPTY_RESULT
		}

	}

	error() {
		this.alertService.error("wrong value inserted");
	}

	getThreeDModelDetail(obj: any) {
		this.threeDModelSearchState = Constants.LOADING_STATES.HAS_RESULT;
		this.ThreeDModelService.setThreeDModelSearchState(this.threeDModelSearchState)
		let threeDModelList = obj.threedmodels.threedmodel;
		if (obj.threedmodels.threedmodel.id) {
			let threeDM = obj.threedmodels.threedmodel;
			//only one element
			console.log("getThreeDModelDetail================1");
			let threeDModel = {
				'id': threeDM.id,
				'largeThreeDModel': threeDM.fullThreeDModel,
				'thumbnailThreeDModel': '',
				'title': threeDM.title,
				'folderId': threeDM.folderId,
				'folderPath': threeDM.folderPath
			}
			console.log(threeDModel);
			this.threedmodel.push(threeDModel);
		} else {
			console.log("getThreeDModelDetail================2");
			//list of threedModels
			threeDModelList.forEach((item: { id: string; fullThreeDModel: string; thumbnailThreeDModel: string; title: string;
				 folderId: string; folderPath: string;}) => {
				let threeDModel = {
					'id': item.id,
					'largeThreeDModel': item.fullThreeDModel,
					'thumbnailThreeDModel':'',
					'title': item.title,
					'folderId': item.folderId,
					'folderPath': item.folderPath
				}
				console.log("getThreeDModelDetail================2 a");
				console.log(threeDModel);
				this.threedmodel.push(threeDModel)
			});
			
		}
		this.ThreeDModelService.setThreeDModelList(this.threedmodel)
		this.getURL(obj);
	}

	public getURL(obj: any) {
		this.urlList = [];
		console.log("getURL================A");
		this.threeDModelSearchState = Constants.LOADING_STATES.HAS_RESULT;
		this.ThreeDModelService.setThreeDModelSearchState(this.threeDModelSearchState)
		let threeDModelList = obj.threedmodels.threedmodel;
		if (obj.threedmodels.threedmodel.id) {
			console.log("getURL================1");
			let threeDM = obj.threedmodels.threedmodel;
			console.log("getURL================threeDM.id=" + threeDM.id);
			//only one element
			this.ThreeDModelService.getThumbnailURL(threeDM.id).subscribe(
				data => {
					if (data.payload != null) {
						this.url = data.payload;
						let urls = {
							'id': threeDM.id,
							'url': this.url,
						}
						console.log("getURL================urls=");
						console.log(urls);
						this.updateArrayThreedModel(threeDM.id, data.payload);
						this.urlList.push(urls);
					} else {
						//do nothing
					}
				},
				error => {
					this.alertService.error(error.statusDescription);
			});

		} else {
			console.log("getURL================2");
			//list of threedModels
			threeDModelList.forEach((item: { id: string; fullThreeDModel: string; thumbnailThreeDModel: string; title: string; }) => {
				console.log("getURL================2 item.id=" + item.id);
				this.ThreeDModelService.getThumbnailURL(item.id).subscribe(
					data => {
						if (data.payload != null) {
							this.url = data.payload;
							let urls = {
								'id': item.id,
								'url': this.url,
							}
							console.log("getURL================urls=");
							
							console.log(urls);
							this.updateArrayThreedModel(item.id, data.payload);
							this.urlList.push(urls);
						} else {
							//do nothing
						}
					},
					error => {
						this.alertService.error(error.statusDescription)
				});
			});
		}
			this.ThreeDModelService.setURLList(this.urlList)
	}

	public updateArrayThreedModel(id: string, url: String) {
		let model = this.threedmodel.find(item => item.id === id)
		model.thumbUrl = url
	}

	// imageArray(thumb: any) {
	// 	this.fullimagearr.push({
	// 		'image': thumb
	// 	});
	// 	console.log('this.fullimagearr', this.fullimagearr)
	// }

	searchBarEnterFunc(event: any) {
		this.resetSelected()
		console.log('[searchBarEnterFunc] (event) ' + JSON.stringify(event));
		this.threedmodel = [];
		this.divHeight = (window.innerHeight) * 4 / 5;
		this.threeDModelSearchState = Constants.LOADING_STATES.LOADING
		this.docType = "";
		this.description = "";
		this.count = 0;
		//check fro empty values in search bar
		if (this.checkForEmptyValuesInSearchBar(event)) {
			let docArray = event.split(" ");
			for (var l = 0; l <= docArray.length - 1; l++) {
				let letter = docArray[l].split(":");
				if (letter != "") {
					for (var i = 0; i < letter.length - 1; i++) {
						if (letter[i] == "docType") {
							this.docType = letter[i + 1];
						} else if (letter[i] == "description") {
							this.description = letter[i + 1];
						} else {
							if (this.count == 0) {
								this.count = 1;
								this.error();
							} else {
								// do nothing
							}
							this.count = 1;
						}
					}
				}
			}
			if (this.docType != "" || this.description != "") {
				let to: any = false;
				if (to) { clearTimeout(to); }
				to = setTimeout(() => {
					this.ThreeDModelService.searchThreeDModel(this.docType, this.description, this.hasText).subscribe(
						data => {
							var obj = JSON.parse(data.payload);
							this.getThreeDModelDetail(obj)
						},
						error => {
							this.alertService.clear()
							this.alertService.error(error.statusDescription)
						});
				}, 200);
			}
		} else {
			this.threeDModelSearchState = Constants.LOADING_STATES.EMPTY_RESULT
		}
	}

	/**
     * check for empty values in search bar
     * @param data 
     */
	public checkForEmptyValuesInSearchBar(data: any): boolean {
		let docArray = data.split(" ");
		if (docArray.length > 1) {
			return true
		} else {
			if (docArray[0] != '') {
				return true
			} else {
				return false
			}
		}
	}

	showSearchOptionEE(event: any) {
		console.log('[showSearchOptionEE] (event) ' + JSON.stringify(event));
		if (event) {
			this.divHeight = (window.innerHeight) * 1.7 / 5
		} else {
			this.divHeight = (window.innerHeight) * 4 / 5
		}

	}

	showLargeThreeDModel(template: TemplateRef<any>, selectedThreeDModel: any, selectedURL: any) {
		this.selectedThreeDModel = selectedThreeDModel
		this.selectedURL = selectedURL
		this.modalRef = this.modalService.show(template, this.modalConfig);
	}

	public modelRefCloseEvent() {
		this.modalRef.hide()
		this.selectedThreeDModel = {
			'id': '',
			'largeThreeDModel': '',
			'thumbnailThreeDModel': '',
			'title': '',
			'folderID': '',
			'folderPath': ''
		}
	}

	public getThreeDModelInfo(threeDM: any, selectedURL: any){
		this.ThreeDModelService.getThreeDModelInfoById(threeDM.id).subscribe(
			data => {
				if (data.payload != null) {
					if(data.payload.threeDModelStatus != null && data.payload.threeDModelStatus != 'Active'){
						this.alertService.error("Cannot insert disabled 3D model");
					}else{
						this.layerContent =  data.payload.layerContent
						this.viewContent =  data.payload.viewContent
						this.insertAsModel(threeDM, selectedURL, this.layerContent, this.viewContent)
					}
				} else {
					//do nothing
				}
			},
			error => {
				this.alertService.error(error.statusDescription)
		});
	}


	/**
	 * insert image as figure button click event 
	 * both modal button event and popover button event
	 * @param image 
	 */
	public insertAsModel(threeDM: any, selectedURL: any, layerContent: any, viewContent: any) {
			const myObj = {
				id: threeDM.id,
				largeThreeDModel: threeDM.largeThreeDModel,
				thumbnailThreeDModel: threeDM.thumbnailThreeDModel,
				title: threeDM.title,
				width: threeDM.width,
				height: threeDM.height,
				path: threeDM.path,
				thumbPath: threeDM.thumbPath,
				type: 'modelAsFigure',
				imgsrc: threeDM.threeDModelSrc, 
				selectedURL: selectedURL,
				layerContent: layerContent,
				viewContent: viewContent
	
			};
			const myObjStr = JSON.stringify(myObj);
			let event = JSON.parse(myObjStr);


		let tabType = SharedService.getTabComponentType();
		if (tabType == 'Editor') {
			SharedService.insertEditorRequest(event);
		} else if (tabType == 'Procedures') {
			SharedService.insertProceduresRequest(event);
		} else if (tabType == 'IceCourse') {
			SharedService.insertICECourseRequest(event);
		}
	}

	/**
	 * ngx-bootstrap popover onshown event 
	 * Emits an event when the popover is shown
	 * @param threeDM 
	 */
	public onShown(threeDM: any) {
		console.log('[onShown] (threeDM.title) ' + JSON.stringify(threeDM.title));
		this.selectedThreeDModel = threeDM
	}

	/**
	 * flip image when user click on image title
	 * @param threeDModel 
	 */
	public flipThreeDModel(threeDM: any) {
		this.flippedThreeDModel = threeDM
		if(this.urlList.length > 0){
			this.urlList.forEach((url: { id: string; url: string; }) => {
				if(url.id == threeDM.id){
					this.selectedURL = url.url
				}
			});
		}
	}

	/**
	 * unflip image 
	 */
	public unFlipThreeDModel() {
		this.flippedThreeDModel = {
			'id': '',
			'largeThreeDModel': '',
			'thumbnailThreeDModel': '',
			'title': '',
			'folderID': '',
			'folderPath': ''
		}
	}

	public resetSelected() {
		this.flippedThreeDModel = {
			'id': '',
			'largeThreeDModel': '',
			'thumbnailThreeDModel': '',
			'title': '',
			'folderID': '',
			'folderPath': ''
		}

		this.selectedThreeDModel = {
			'id': '',
			'largeThreeDModel': '',
			'thumbnailThreeDModel': '',
			'title': '',
			'folderID': '',
			'folderPath': ''
		}
	}

	public goToModelLocation(){
		let data = {
			isSearch: false
		}
		let folderPath = this.selectedThreeDModel.folderPath;
		if(this.selectedThreeDModel.folderPath.substring(this.selectedThreeDModel.folderPath.length - 1) == "/" ) {
			folderPath = this.selectedThreeDModel.folderPath.substring(0,this.selectedThreeDModel.folderPath.length - 1);
		}
		let folderName = folderPath.substring(folderPath.lastIndexOf('/')+1);
		let folderId = this.selectedThreeDModel.folderId;
		this.modelRefCloseEvent();
		this.sharedService.openTabRequestWithId(folderId, folderName, "Files", data, folderPath);
        //window.open('/home', '_blank').localStorage.setItem("foldertree",'{"state":{"core":{"selected":["'+this.selectedImage.folderId+'"]}}}');
	}

}

