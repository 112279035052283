import { UserVariable } from 'src/app/util/common/user-variable';
import { HttpClient, HttpErrorResponse, HttpEventType, HttpParams } from '@angular/common/http';
import { CommonUtil } from 'src/app/util/common/common-util';
import { WsResponse } from 'src/app/util/ws-response.model';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ServiceUrls } from 'src/app/util/service-urls';
import { Injectable, EventEmitter } from '@angular/core';
import { WsCallback } from 'src/app/util/ws-callback.interface';
import { WsType } from 'src/app/util/ws-type';

@Injectable({
    providedIn: 'root'
})
export class ManagePCCService {
    updateUIAfterPCCGenerate = new EventEmitter<any>();
    loadTableData = new EventEmitter<any>()
    updateUIafterApproval = new EventEmitter<any>();
    updateUIafterDocumentUpload = new EventEmitter<any>();
    private apiGridData: any = null;
    pccComp: WsCallback;

    constructor(private http: HttpClient, private commonUtil: CommonUtil) {
    }

    public setApiGridData(apiGridData: any) {
        this.apiGridData = apiGridData;
    }

    public loadTableDataRequest() {
        this.loadTableData.emit()
    }

    public loadTableDataResponse() {
        return this.loadTableData;
    }

    public updateUIAfterPCCGenerateRequest() {
        this.updateUIAfterPCCGenerate.emit()
    }

    public updateUIAfterPCCGenerateResponse() {
        return this.updateUIAfterPCCGenerate;
    }

    public setPCCcomp(pccComp:WsCallback){
        this.pccComp = pccComp
      }

    public searchPCCList(data: any) {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
        let url = ServiceUrls.getSearchPCCURL(token);
        return this.http.post(url, data).
            pipe(
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                }),
                catchError(error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    return throwError(res)
                })
            );
    }

    public getPCById(pcId: any, wsCallback: WsCallback) {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }

        this.http.get(ServiceUrls.getPCById(token, pcId)).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                wsCallback.onSuccess(res, WsType.GET_PC_BY_ID);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    wsCallback.onFail(res, WsType.GET_PC_BY_ID);
                } else {
                    //browser related issues
                    var res = new WsResponse("Unknown error happened");
                    wsCallback.onFail(res, WsType.GET_PC_BY_ID);
                }
            })
    }

    public addPrecommissioningCompletion(data: any, wsCallback: WsCallback) {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }

        data['userToken'] = token;
        this.http.post(ServiceUrls.ADD_PRECOMMISSIONING_COMPLETION, JSON.stringify(data)).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                wsCallback.onSuccess(res, WsType.ADD_NEW_PCC);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    wsCallback.onFail(res, WsType.ADD_NEW_PCC);
                } else {
                    //browser related issues
                    var res = new WsResponse("Unknown error happened");
                    wsCallback.onFail(res, WsType.ADD_NEW_PCC);
                }
            })
    }

    public updatePrecommissioningCompletion(data: any, wsCallback: WsCallback) {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }

        data['userToken'] = token;
        this.http.post(ServiceUrls.UPDATE_PRECOMMISSIONING_COMPLETION, JSON.stringify(data)).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                wsCallback.onSuccess(res, WsType.UPDATE_PCC);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    wsCallback.onFail(res, WsType.UPDATE_PCC);
                } else {
                    //browser related issues
                    var res = new WsResponse("Unknown error happened");
                    wsCallback.onFail(res, WsType.UPDATE_PCC);
                }
            })
    }

    public deletePrecommissioningCompletion(pcId: any, wsCallback: WsCallback) {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }

        let data = { 'userToken': token }
        this.http.post(ServiceUrls.getPCDeleteURL(pcId), JSON.stringify(data)).subscribe(
            response => {
                var modified = JSON.parse(JSON.stringify(response));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                wsCallback.onSuccess(res, WsType.DELETE_PCC);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    wsCallback.onFail(res, WsType.DELETE_PCC);
                } else {
                    //browser related issues
                    var res = new WsResponse("Unknown error happened");
                    wsCallback.onFail(res, WsType.DELETE_PCC);
                }
            })
    }

    public revokeProcommissioningCompletion(pcId: string) {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }

        let data = { 'userToken': token }
        return this.http.post(ServiceUrls.getPCCRevokeUrl(pcId), JSON.stringify(data)).subscribe(
            response => {
                var modified = JSON.parse(JSON.stringify(response));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                this.pccComp.onSuccess(res, WsType.REVOKE_PCC);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    this.pccComp.onFail(res, WsType.REVOKE_PCC);
                } else {
                    //browser related issues
                    var res = new WsResponse("Unknown error happened");
                    this.pccComp.onFail(res, WsType.REVOKE_PCC);
                }
            }
        )
    }

    approvePCC(data: any, callback: WsCallback) {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }

        let url = this.approvePCCURL(token);
        this.http.post(url, JSON.stringify(data)).
            subscribe(
                data => {
                    var modified = JSON.parse(JSON.stringify(data));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                    callback.onSuccess(res, WsType.APPROVE_PCC);
                },
                error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    callback.onFail(res, WsType.APPROVE_PCC);
                }
            );
    }
    approvePCCURL(token) {
        let url = ServiceUrls.APPROVE_PCC + '?token=' + token;
        return url;
    }

    public uploadFile(fileUploadData: FormData, callBack: WsCallback) {
        var token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }
        this.http.post(ServiceUrls.ADD_NEW_PCC_DOCUMENT, fileUploadData, {
            reportProgress: true,
            observe: 'events'
        }).subscribe(
            event => {
                if (event.type == HttpEventType.UploadProgress) {
                    //progress
                } else if (event.type == HttpEventType.Response) {
                    var modified = JSON.parse(JSON.stringify(event.body));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onSuccess(res, WsType.ADD_NEW_PCC_DOCUMENT);
                }
            },
            error => {
                if (error.error.payload != undefined) {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    callBack.onFail(res, WsType.ADD_NEW_PCC_DOCUMENT);
                } else {
                    var res: WsResponse;
                    if (error.error.actionErrors != undefined) {
                        res = new WsResponse(error.statusText + ' - ' + error.error.actionErrors);
                    } else {
                        res = new WsResponse(error.message);
                    }
                    callBack.onFail(res, WsType.ADD_NEW_PCC_DOCUMENT);
                }
            }
        );
    }

    public getPCCApprovalData(pcId: any, wsCallback: WsCallback) {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateToken(token)) {
            return;
        }

        this.http.get(ServiceUrls.getPCCApprovalData(token, pcId)).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                wsCallback.onSuccess(res, WsType.GET_PCC_APPROVAL_DATA);
            },
            error => {
                if (error.status != '') {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    wsCallback.onFail(res, WsType.GET_PCC_APPROVAL_DATA);
                } else {
                    //browser related issues
                    var res = new WsResponse("Unknown error happened");
                    wsCallback.onFail(res, WsType.GET_PCC_APPROVAL_DATA);
                }
            })
    }

    downloadSentFile(projectId: string, pccId: string) {
        return this.http.get(ServiceUrls.DOWNLOAD_FILE,
            {
                responseType: "blob", params: new HttpParams().set("projectId", projectId)
                    .set("documentId", pccId).set("mode", "ViewCommOutput").set("type", "StaticCompletion").set("fileName", "PCC")
            });
    }

    downloadApprovedFile(projectId: string, pccId: string) {
        return this.http.get(ServiceUrls.DOWNLOAD_FILE,
            {
                responseType: "blob", params: new HttpParams().set("projectId", projectId).set("documentId", pccId)
                    .set("mode", "ViewApprovedOutput").set("type", "StaticCompletion").set("fileName", "PCC")
            });
    }

    public updateUIafterApprovalRequest(pccId, uploaded) {
        this.updateUIafterApproval.emit({ pccId, uploaded })
    }
    public updateUIafterApprovalResponse() {
        return this.updateUIafterApproval;
    }
    public updateUIafterDocumentUploadRequest(pccId, uploaded) {
        this.updateUIafterDocumentUpload.emit({ pccId, uploaded })
    }
    public updateUIafterDocumentUploadResponse() {
        return this.updateUIafterDocumentUpload;
    }
}