<div>
  <div class="modal-header py-2">
    <h5 class="modal-title">
      {{ 'CS_ASSIGN_ASSETS.TITLE' | translate }}
    </h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="container-fluid modal-container">
    <div class="row my-2">
      <div class="col-sm-6 border-right">
        <div class="row">
          <div class="col-sm-12 mb-3">
            <div style="font-size: large;">{{treeTitle}}</div>
          </div>
        </div>
        <div class="row" [style.height.px]="treeheight">
          <div class="col" ondrop="gridDrop(event)" ondragover="gridDragOver(event)">
            <ag-grid-angular #csGrid style="width: 100%; height: 95%;" class="ag-theme-balham"
              [rowData]="assetRowData" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }"
              [columnDefs]="assetColumnDefs" [gridOptions]="assetGridOptions" [pagination]="true"
              [paginationPageSize]="12" [overlayLoadingTemplate]="overlayLoadingTemplate"
              [overlayNoRowsTemplate]="overlayNoRowsTemplate" [getRowNodeId]="getRowNodeId" (gridReady)="initializeGrid($event)">
            </ag-grid-angular>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="row">
          <div class="col-sm-12 mb-2">
            <symbiosis-search [comp]="'searchasset'" id="asset_search" (searchFunc)="searchFunc($event)">
            </symbiosis-search>
          </div>
        </div>
        <div class="row" [style.height.px]="treeheight">
          <div class="col" ondrop="gridDrop(event)" ondragover="gridDragOver(event)">
            <ag-grid-angular #agGrid style="width: 100%; height: 95%;" class="ag-theme-balham" [rowData]="rowData"
              [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }" [columnDefs]="columnDefs"
              [gridOptions]="gridOptions" [pagination]="true" [paginationPageSize]="12"
              [overlayLoadingTemplate]="overlayLoadingTemplate" [overlayNoRowsTemplate]="overlayNoRowsTemplate"
              [getRowNodeId]="getRowNodeId" (gridReady)="onGridReady($event)">
            </ag-grid-angular>
          </div>
        </div>
        <div class="row">
          <a class="btn btn-sm btn-outline-primary my-1 mx-2 border-0" (click)="moveAll()">Move All</a>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="submit" id="irn_update" class="btn btn-primary btn-sm float-left" (click)="saveAssignAssets()">{{
      'CS_ASSIGN_ASSETS.SAVE' |
      translate}}</button>
    <button type="button" id="irn_cancel" class="btn btn-secondary btn-sm float-left" (click)="cancel()">{{ 'CANCEL' |
      translate}}</button>
  </div>
</div>

<ng-template #closeConfirmModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ 'CLOSE' | translate}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">If you close without saving you will loose data. Do you want to close?</div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="confirmModal()">{{ 'OK' | translate}}</button>
    <button type="button" class="btn btn-secondary" (click)="closeModal()">{{ 'CANCEL' | translate}}</button>
  </div>
</ng-template>