import { Component } from "@angular/core";
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { CapabilityService } from '../../../../../../util/capability.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DocumentTypesService } from '../../../document-types.service';
import { AlertService } from '../../../../../../util/alert/alert.service';
import { LoadingService } from '../../../../../../util/loading/loading.service';
import { SharedService } from "../../../../../../util/shared.service";
import { Constants } from "src/app/util/constants";
import { DependenciesmodalComponent } from '../../../../../../util/dependenciesmodal/dependenciesmodal.component';
import { TranslateService } from '@ngx-translate/core';
import { param } from "jquery";

@Component({
    selector: 'child-cell',
    template: '<button id="edit" [disabled]="disable" (click)="showDependencies()" class="btn btn-sm btn-outline-dark py-0 my-1 px-0 border-0"> <i class="far fa-link mx-2" aria-hidden="true"></i> </button>'

})
export class DTADependencyButtonRenderer implements ICellRendererAngularComp {
    public params: any;
    public bsModalRef: BsModalRef;
    public dependencyType: string;
    public status: string = "";
    public title: string;
    public disable: boolean;

    constructor(private capabilityService: CapabilityService, private modalService: BsModalService, private documentTypesService: DocumentTypesService, 
      private alertService: AlertService, private loadingService: LoadingService, private sharedService: SharedService, private translate: TranslateService){
    }

    agInit(params: any): void {
        this.params = params;
        this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.VIEW;
        this.translate.get('DOCUMENT_TYPE.DEPENDENCIES_LIST').subscribe((res: string) => {
            this.title = res;
        });
        if(params.value == 'disable: false'){
          this.disable = false;
        }
        else{
          this.disable = true;
        }
        
    }

    public showDependencies() {
      localStorage.setItem("isDelete", "false");
      this.dependenciesModalWithComponent(this.title, false, true);
    }

    public dependenciesModalWithComponent(header: String, dflag: boolean, cflag: boolean) {
      const initialState : object = {
          headerTitle: header,
          delFlag: dflag,
          cancelFlag: cflag,
          checkboxVal: this.status == "Inactive" ? true : false
      };
      this.sharedService.setFileId(this.params.data.attributeId);
      this.sharedService.setFileType(Constants.DEPENDENCY_ITEM_TYPE.DOCUMENTTYPE);
      this.sharedService.setDependencyMessageType(this.dependencyType);
      this.sharedService.setParent(this);
      this.bsModalRef = this.modalService.show(DependenciesmodalComponent, { initialState, class: 'modal-lg' });
    }

    refresh(): boolean {
        return false;
    }
}