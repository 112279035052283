export class TagUtil {
}

export enum DEPENDENCY_ACTION {
    DELETE = 'delete',
    EDIT = 'edit',
    DISABLE = 'disable',
    GET_DEPENDENCIES = 'getDependencies',
    ENABLE = "enable"
}

export enum ATTRIBUTE_TYPES {
    COMPONENT,//drop down
    DOCUMENT,//drop down
    NUMBER,//text view
    SYSTEM,//drop down
    TEXT//text view
}
