import { Injectable } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { SearchParams } from './search-params.modal'

@Injectable()
export class SearchControlService {
    constructor() { }

    toFormGroup(searchItems: SearchParams<any>[]) {
        let group: any = {};

        searchItems.forEach(item => {
            group[item.key] = new FormControl(item.value || '', Validators.required)
        });
        return new FormGroup(group);
    }
}