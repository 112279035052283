import { LoadingService } from 'src/app/util/loading/loading.service';
import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../util/shared.service';
import { UserService } from '../user.service';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from "@angular/forms";
import { ClientService } from '../../client/client.service';
import { Router } from '@angular/router';
import { AlertService } from '../../util/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-adduser',
	templateUrl: './adduser.component.html',
	styleUrls: ['./adduser.component.css']
})
export class AdduserComponent implements OnInit {

	addUserForm: FormGroup;
	public statusList: Array<any> = [];
	disableClientList: boolean = true;
	checkOpenId: boolean = false;
	passwordExpire: boolean = false;
	clientList: any;
	public statusItems: Array<any>;
	selectedStatus: any = "";
	selectedClientId: string = null;
	expiryDate: any = "";
	omitDeviceId: any;
	bsStartValue: Date = new Date();
	startDate: string;
	defaultDate: Date = new Date();
	isClientChecked: boolean = false;
	isClientRemoved: boolean = false;
	constructor(private formBuilder: FormBuilder, private alertService: AlertService, private translate: TranslateService, 
		private userService: UserService,  private clientService: ClientService, private router: Router, private sharedService: SharedService, private loadingService: LoadingService) {

		translate.addLangs(["es", "en"]);
		translate.setDefaultLang("en");
		let browserLang = translate.getBrowserLang();
		translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');

		this.statusList = [
		{ 'id': '0', 'text': 'Active' },
		{ 'id': '1', 'text': 'Inactive' }
		];
	}

	ngOnInit() {
		this.bsStartValue.setDate(this.bsStartValue.getDate() + 90);
		this.startDate = this.bsStartValue.toLocaleString().substring(0,this.bsStartValue.toLocaleString().indexOf(','))
		this.addUserForm = this.formBuilder.group({
			firstName: new FormControl(null),
			lastName: new FormControl(null),
			userName: new FormControl(null),
			checkClient: new FormControl(null),
			clientList: new FormControl(null),
			checkOpenId: new FormControl(null),
			status: new FormControl(null),
			email: new FormControl(null),
			newPassword: new FormControl(null),
			confirmPassword: new FormControl(null),
			passwordExpire: new FormControl(null),
			expiryDate: new FormControl(this.bsStartValue),
			authCode: new FormControl(null)
			// trueorfalse: new FormControl(null),
			// answers: this.formBuilder.array([

			// ])
		});

		this.clientService.getClientList().subscribe(
			data => {

				this.loadClients(data.payload);

				//this.setClientsTable(data.payload)
			},
			error => {
				//this.alertService.clear()
				//this.alertService.error(error.statusDescription)
			});;

	}

	save() {
		if (this.addUserForm.controls.firstName.value == "" || this.addUserForm.controls.firstName.value == null
			|| this.addUserForm.controls.lastName.value == "" || this.addUserForm.controls.lastName.value == null ||
			this.addUserForm.controls.userName.value == "" || this.addUserForm.controls.userName.value == null ||
			this.selectedStatus == "" || this.selectedStatus == null ||
			(!this.checkOpenId && (this.addUserForm.controls.email.value == "" || this.addUserForm.controls.email.value == null))||
			(!this.checkOpenId && (this.addUserForm.controls.newPassword.value == "" || this.addUserForm.controls.newPassword.value == null))
			||(!this.checkOpenId && ( this.addUserForm.controls.confirmPassword.value == "" || this.addUserForm.controls.confirmPassword.value == null))) {
			this.alertService.clear();
			this.alertService.error("Please fill all the mandatory fields.");
		}
		else if (this.checkOpenId  && (this.addUserForm.controls.userName.value.indexOf('@') === -1 || this.addUserForm.controls.userName.value.indexOf('.') === -1)) {
			this.alertService.clear();
			this.alertService.error("For OpenId Authentication, username should be an email address");
		}
		else if (!this.checkOpenId && this.addUserForm.controls.email.invalid) {
			this.alertService.clear();
			this.alertService.error("Please enter a valid e-mail address");
		}
		else if( this.selectedStatus === "" || this.selectedStatus === null)
		{
			this.alertService.clear();
			this.alertService.error("Please select user status");
		}
		else if(!this.checkOpenId  && (this.addUserForm.controls.newPassword.value !==  this.addUserForm.controls.confirmPassword.value))
		{
			this.alertService.clear();
			this.alertService.error("Password and the Confirm Password should match");	
		}
		else if(this.passwordExpire && this.expiryDate == null)
		{
			this.alertService.clear();
			this.alertService.error("Please select password expiry date");
		}
		else if ((this.isClientChecked && this.selectedClientId == null) || (this.isClientChecked && this.isClientRemoved)) {
			this.alertService.clear();
			this.alertService.error("Please select client User");
		}
		else {
			let firstName = this.addUserForm.controls.firstName.value;
			let lastName = this.addUserForm.controls.lastName.value;
			let userName = this.addUserForm.controls.userName.value;
			let emailAddress = "";
			let authType;
			if (this.checkOpenId) {
				authType = "1";
				emailAddress = userName;
			}
			else {
				authType = "2";
				emailAddress = this.addUserForm.controls.email.value;
			}
			let password = this.addUserForm.controls.newPassword.value;
			let status = this.selectedStatus;
			
			let clientStatus;
			if (!this.disableClientList) {
				clientStatus = "1";
			}
			else {
				clientStatus = "0";
			}
			//console.log("Status" + JSON.stringify(Status))
			this.alertService.clear();
			this.userService.addUser( firstName, lastName, authType, userName, password,
				status, emailAddress, clientStatus, this.selectedClientId, this.expiryDate, this.omitDeviceId).
				subscribe(data => {
					//this.router.navigateByUrl("user")
							this.alertService.clear();
							this.alertService.success("User added successfully");
							this.loadingService.hideLoading();
							this.sharedService.viewSideBarEventEmit('users');
					},
						error => {
							this.alertService.clear();
							this.alertService.error(error.statusDescription)
							this.loadingService.hideLoading();
					});
			this.loadingService.showLoading(true,null,"Saving..",null);
		}
	}

	setOmitDeviceId(event: any) {
		if (event == true) {
			this.omitDeviceId = 1;
		}
		else {
			this.omitDeviceId = 0;
		}

	}

	onStartValueChange(event: any) {
		this.expiryDate = event;
	}


	clientSelected(event: any) {
		this.selectedClientId = event.id
		this.isClientRemoved = false
	}



	statusSelected(event: any)
	{
		this.selectedStatus = event["text"];

	}

	loadClients(data: any) {
		//console.log(data);
		let list: Array<any> = []
		//list.push({id:"-1", text:"Internal Users"})
	
		data.forEach(item => {
			if (item.status !== "Inactive") {
				list.push(
					{ id: item.clientId, text: item.clientName }
				)
			}	
		});
		this.clientList = list;
		console.log(this.clientList)

	}

	removed(event: any) {
		console.log(event)
		this.selectedClientId = null;
		this.isClientRemoved = true;
	}



	cancel() {
		this.alertService.clear();
		this.sharedService.viewSideBarEventEmit('users')
		//this.router.navigateByUrl("user")

	}

	setPasswordExpire(event: any) {
		if (event == true) {
			this.passwordExpire = true;
		}
		else {
			this.passwordExpire = false;
		}
	}


	CheckOpenId(event: any) {
		if (event == true) {
			this.checkOpenId = true;
		}
		else {
			this.checkOpenId = false;
		}

	}

	Changed(event: any) {
		if (event == true) {
			this.disableClientList = false;
			this.isClientChecked =  true;
		}
		else {
			this.disableClientList = true;
			this.isClientChecked =  false;
		}
	}


}
