<div class="container-fluid px-0">
    <div class="row px-3">
        <div class="col-sm-8 px-0">
            <p class="mb-2 h5">
                <b>{{ 'DOCUMENT_SEARCH_VIEW.HEADER' | translate }}</b>
            </p>
        </div>
        <div class="col-sm-4 px-0">
            <button *ngIf="changeLayout" type="submit" class="btn btn-primary btn-sm float-right reusable_btn my-1"
            [disabled]="!enableAddDocument" (click)="add()" id="document_add">
                <i class="fas fa-plus"></i>
            </button>
        </div>
    </div>
    <div class="col-sm-12 px-0">
        <div class="input-with-icon" *ngIf="!enableSearchDocument">
            <input #symbiosis_search class="form-control" name="document_search" placeholder="Search" style="padding-right: 60px;" 
            [disabled]="!enableSearchDocument"/>
        </div>
        <div class="input-with-icon" *ngIf="enableSearchDocument">
                <symbiosis-search [comp]="'document'" id="document_search" (searchFunc)="searchFunc($event)"
                (searchBarEnterFunc)="searchBarEnterFunc($event)">
            </symbiosis-search>
        </div>
    </div>
    <hr class="my-2" />
</div>

<!-- <ag-grid-angular #agGrid style="width: 100%; height: 570px;" class="ag-theme-balham" [rowData]="rowData"  [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }" [columnDefs]="columnDefs"
    (cellClicked)="cellClicked($event)" [gridOptions]="gridOptions" [pagination]="true" [paginationPageSize]="12"
    (gridReady)="onGridReady($event)" [overlayLoadingTemplate]="overlayLoadingTemplate" [overlayNoRowsTemplate]="overlayNoRowsTemplate">

    <div class="row">
        <div class="col-sm-12 float-right">
            <button type="submit" class="btn btn-warning btn-sm float-left" (click)="add()">{{
                'DOCUMENT_SEARCH_VIEW.ADD' | translate }}</button>
        </div>
    </div> -->
<!-- 
<ag-grid-angular style="width: 100%; height: 100%;" class="ag-theme-balham" [rowData]="rowData"  [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }" [columnDefs]="columnDefs"
    (cellClicked)="cellClicked($event)" [gridOptions]="gridOptions">
</ag-grid-angular> -->
<div *ngIf="expandedView == fuv.EXPANDED_VIEW">
    <ag-grid-angular #agGrid style="width: 100%; height: 375px;" id="procedureStore" [rowData]="rowData" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }" class="ag-theme-balham"
    [enableFilter]="true" [columnDefs]="columnDefs" 
     [enableRangeSelection]="true" [paginationPageSize]="10" [pagination]="true" 
    (gridReady)="onGridReady($event)" [suppressMenuHide]="true" [getRowNodeId]="getRowNodeId" rowSelection="multiple"
    [rowMultiSelectWithClick]="false"  [gridOptions]="gridOptions"></ag-grid-angular>
</div>

<div *ngIf="expandedView != fuv.EXPANDED_VIEW">
    <ag-grid-angular #agGrid style="width: 100%; height: 375px;" id="procedureStore" [rowData]="rowData" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }" class="ag-theme-balham"
    [enableFilter]="true" [columnDefs]="columnDefs" 
     [enableRangeSelection]="true" [paginationPageSize]="10" [pagination]="true" 
    (gridReady)="onGridReady($event)" [suppressMenuHide]="true" [getRowNodeId]="getRowNodeId" rowSelection="multiple"
    (cellClicked)="cellClicked($event)" [rowMultiSelectWithClick]="false"  [gridOptions]="gridOptions"></ag-grid-angular>
</div>
<!--ag-grid-angular #agGrid style="width: 100%; height: 570px;" class="ag-theme-balham" 
    [enableFilter]="true" [rowData]="rowData"  [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }" [columnDefs]="columnDefs" rowSelection="multiple" 
    [rowMultiSelectWithClick]="false"   [getRowNodeId]="getRowNodeId" 
    (gridReady)="onGridReady($event)" (cellClicked)="cellClicked($event)"  [pagination]="true" [paginationPageSize]="10" -->