import { Component, OnInit, Output, EventEmitter,TemplateRef, AfterViewInit, ViewChild } from '@angular/core';
import { ElementRef, HostListener } from '@angular/core'
import { TranslateService } from '@ngx-translate/core';
import { FilesService } from './../home/files/files.service';
import { UserVariable } from '../util/common/user-variable';
import { SharedService } from '../util/shared.service';
import { LoadingService } from '../util/loading/loading.service';
import { FolderService } from '../foldertree/foldertree.service';
import { WsCallback } from '../util/ws-callback.interface';
import { WsType } from '../util/ws-type';
import { WsResponse } from '../util/ws-response.model'
import { Router } from '@angular/router';
import { AlertService } from '../util/alert/alert.service';
import { LandingService } from '../landing/landing.service';
import { Folder } from '../foldertree/folder.model'
import { RecentProject } from '../landing/recent-project.service';
import { LocalStorage } from '../util/localstorage.service';
import { LoggedUser } from '../user/logged-user.model';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DependenciesmodalComponent } from '../util/dependenciesmodal/dependenciesmodal.component';
import { Subscription } from 'rxjs';
import { Constants } from "src/app/util/constants";


declare var $: any;

@Component({
	selector: 'app-foldertree',
	templateUrl: './foldertree.component.html',
	styleUrls: ['./foldertree.component.css']
})
export class FoldertreeComponent implements OnInit, AfterViewInit {
	//private userId: string = "c9dfd4d5-d8a9-41c6-bd08-e7f4ae70e423";
	//private clientId: string = "f344586b-6c99-45e6-a957-c7f82938e402";
	public data: any = {
		'data': [
			{ "id": "ajson1", "parent": "#", "text": "ROOT NODE", icon: "fa fa-folder-o", type: "folder" },
			{ "id": "ajson2", "parent": "#", "text": "Root node 2", icon: "fa fa-folder-o", type: "folder" },
			{ "id": "ajson3", "parent": "ajson2", "text": "Child 1", icon: "fa fa-file-o", type: "file" },
			{ "id": "ajson4", "parent": "ajson2", "text": "Child 2", icon: "fa fa-file-image-o", type: "image" }
		]
	}

	public data_n: any = {
		'data': [
		]
	}

	public selectedFolderId: string = null
	public treeRef: any = null;
	public selectedTreeItem: any = null
	public folder: Folder;
	public folderListPayload: any = null;
	private parentProjectName: string;
	private loggedInUser: LoggedUser;
	private wordList: any;
	private wordListString: string = null;
	private status: string = "";
	bsModalRef: BsModalRef;
	dependencyType: string;
	private subsVar: Subscription
	deletedFolder: any = null
	private newFolderId: string = null
	private renameDisable = true;
	private deleteDisable = true;
	private moveFolder:boolean = false;
	private moveItemsList:any = null;
	private itemCount:any = null;
	filePath: string;
	private folderSubscription: Subscription;

	@ViewChild('moveFolderItemsTemplate') moveFolderItemsTemplate: TemplateRef<any>;
	@ViewChild('ftHeader') ftHeader: ElementRef
	treeheight: number =  (window.innerHeight) * 3 / 4;

	constructor(private alertService: AlertService, private translate: TranslateService, private landingService: LandingService, 
		private router: Router, private filesService: FilesService, private folderService: FolderService, private modalService: BsModalService, 
		private sharedService: SharedService, private loadingService: LoadingService, private defaultProject: RecentProject) {
		//folderService.setCallback(this);
		translate.addLangs(["es", "en"]);
		translate.setDefaultLang("en");
		let browserLang = translate.getBrowserLang();
		translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
		this.loggedInUser = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER)) as LoggedUser;
		
	}

	ngOnInit() {
		//UserVariable.userId = localStorage.getItem()
		//UserVariable.clientId = this.clientId;
		//check the selected project or default project
		// if (localStorage.getItem(LocalStorage.PROJECT_NAME) == null) {
		// 	this.defaultProject.getDefaultRecentProject(this.loggedInUser.userId as string, this);
		// } else {
		// 	this.parentProjectName = localStorage.getItem(LocalStorage.PROJECT_NAME);
		// 	this.folderService.getAllFolders(this);
		// }
		 //console.log("********************foldertree******************")

		this.folderService.getAllFolders(this);
		this.getWordlist();
	}

	ngAfterViewInit(): void {
		//Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
		//Add 'implements AfterViewInit' to the class.
		this.changeFolderTreeHeight()
	}

	@HostListener('window:resize', ['$event'])
	public changeFolderTreeHeight(event?: any) {
		this.treeheight = (window.innerHeight) * 3 / 4;
	}

	
	loadData(payload: any) {
		if (payload != null) {
			this.data_n['data'] = [];
			var item = payload.filter(item => item["parent"] === true);
			if (item != null && item.length > 0) {
				item[0]["parentId"] = "#"
				for (let i = 0; i < payload.length; i++) {
					var icon = "far fa-folder";
					var state = {};
					if (payload[i]["status"] == "Inactive") {
						icon += "far fa-folder disabled";
						state = { disabled: true };
					}
					this.data_n["data"].push({ id: payload[i]["id"], parent: payload[i]["parentId"], text: payload[i]["name"], path: payload[i]["path"], icon: icon, type: "folder", state: state })
				}
				console.log("this.data_n " + this.data_n["data"])
				this.treeRef = $('#foldertree').jstree({
					core: {
						themes: {
							variant: 'normal'
						},
						strings: {
							'New node': 'New Folder'
						},
						data: this.data_n["data"],
						check_callback: function (operation, node, node_parent, node_position, more) {
							switch (operation) {
								case 'move_node':
									if (node_parent.parent != null) {
										return true
									} else {
										return false
									}
								case 'copy_node':
									return false
								default:
									break;
							} 
						},
						force_text: true
						
					},
					types: {
						default: {
							// "icon" : "fa fa-file-o "
						}
					},
					plugins: ["dnd", "search", "state", "types", "wholerow", "contextmenu", "sort"],
					search: {
						show_only_matches: true,
						show_only_matches_children: true
					},
					contextmenu: {
						items: ''
					},
					state: {
						key:'foldertree'
					},
					'sort' : function(a, b) {
						 let a1 = this.get_node(a).text;
						 let b1 = this.get_node(b).text;
						 return a1.localeCompare(b1, undefined, {numeric: true, sensitivity: 'base'})
				}
					
				});
				// setTimeout(() => $("#foldertree").jstree("open_node", item[0]["id"]), 250);
				setTimeout(() => {
					$("#foldertree").jstree("open_node", item[0]["id"])

					if (this.newFolderId != null) {
						// console.log('[loadData] this.newFolderId != null ' + this.newFolderId);
						$('#foldertree').jstree().deselect_all(true);
						$('#foldertree').jstree().select_node(this.newFolderId, false, true);
						this.newFolderId = null
					} else {
						//do nothing
						// console.log('[loadData] this.newFolderId == null');
					}
				}, 250);
				//$("#foldertree").jstree("open_node", item[0]["id"])
				$('#foldertree').bind("select_node.jstree", (evt, data) => this.folderSelectEvent(evt, data))
				//drag and drop functionality
				$('#foldertree').bind("move_node.jstree", (evt, data) => this.folderMoveEvent(evt, data))
				//triggered when a node is renamed
				$('#foldertree').bind("rename_node.jstree", (evt, data) => this.renameCallbackEvent(evt, data))
				//triggered when a node is deleted
				$('#foldertree').bind("delete_node.jstree", (evt, data) => this.deleteCallbackEvent(evt, data))
			}
			
		}
	}

	ngAfterContentInit(): void {
		//Called after ngOnInit when the component's or directive's content has been initialized.
		//Add 'implements AfterContentInit' to the class.
		this.subsVar = this.sharedService.selectTreeItemRespond().subscribe(option => this.selectJSTreeItem(option))
		this.folderSubscription = this.sharedService.selectTabWhenFEClickRespond().subscribe(option => {
			//console.log(this.selectedTreeItem)
			if (this.selectedTreeItem !== null) {
				this.openTabsEvent(this.selectedTreeItem.node.id, this.selectedTreeItem.node.text, "Files", null)
			}
			
		})
		this.sharedService.deleteFolderFromFolderTreeResponse().subscribe(option => this.deleteFoldersFromFolderTreeByIds(option))
		this.sharedService.enableFolderDeleteResponse().subscribe(enable => this.deleteDisable = !enable)
		this.subsVar = this.sharedService.moveFolderItemsResponse().subscribe(option => this.moveFolderItemsResponse(option))
		this.sharedService.getFolderTree()
			.subscribe(data => {
				$("#foldertree").jstree("destroy");
				this.folderListPayload = data.payload;
				this.loadData(data.payload);
			});
	}


	
	ngOnDestroy(): void {
		//Called once, before the instance is destroyed.
		//Add 'implements OnDestroy' to the class.
		if (this.subsVar) {
			this.subsVar.unsubscribe()
		}
		if (this.folderSubscription) {
			this.folderSubscription.unsubscribe();
		}
	}

	/**
	 * select jsTree item when user select a tab
	 * @param event - tab id or null
	 */
	selectJSTreeItem(event: any): void {
		//console.log('[selectJSTreeItem] (event) ' + JSON.stringify(event));
		$("#foldertree").jstree().deselect_all(true);
		if (event.id != null) {
			$("#foldertree").jstree().select_node(event.id);
		} else {
			//do nothing
		}
	}

	moveFolderItemsResponse(option: any): void {
		this.moveFolder = option["moveItems"];
		this.moveItemsList = option["itemIdList"];
		this.itemCount = option["itemCount"];
	}


	moveFolderItems(){
		//this.bsModalRef.hide();
		if(localStorage.getItem("isMoveFileEvent") === "true"){
			this.folderService.moveFolderItems(this.selectedFolderId,this.moveItemsList,this);
		} {
		}
	
	}

	onSuccess(data: WsResponse, serviceType: WsType): void {
		if (serviceType == WsType.FOLDER_VIEW) {
			this.folderListPayload = data.payload;
			this.loadData(data.payload);
			this.filePath = data.payload.path;
		}
		else if (serviceType == WsType.FOLDER_ADD) {
			$("#foldertree").jstree("destroy");
			// console.log('[FOLDER_ADD] (payload) ' + JSON.stringify(data.payload));
			this.newFolderId = data.payload
			this.folderService.getAllFolders(this);
			this.alertService.success("Folder added successfully");
		}
		else if (serviceType == WsType.DELETE_ITEM){
			console.log("DELETE_ITEM - Folder " + JSON.stringify(data.payload));
			this.filesService.deleteFileTableData(data.payload);
			this.deleteElementFolderFromFolderTree();
			/*this.sharedService.deletedTabCloseRequest(data.payload);
			this.sharedService.setParent(null);			
			this.loadingService.hideLoading();*/
		}
		else if (serviceType == WsType.FOLDER_DELETE) {
			if (this.deletedFolder != null) {
				//remove opened tab 
				this.sharedService.deletedTabCloseRequest(this.deletedFolder.id)
				this.sharedService.setParent(null);			
				this.loadingService.hideLoading();
				this.alertService.success("Folder deleted successfully");
			} else {
				//deleted folder null do nothing
			}
		}
		else if (serviceType == WsType.FOLDER_UPDATE) {
			this.alertService.success("Folder renamed successfully");
		}else if (serviceType == WsType.FOLDER_MOVE) {
			this.alertService.success("Folder moved successfully");
		} else if (serviceType == WsType.GET_DEFAULT_RECENT_PROJECT) {
			this.parentProjectName = data.payload.projectName;
			this.folderService.getAllFolders(this);
		} else if (serviceType == WsType.MOVE_FILES_FROM_FOLDER) {
			this.translate.get('You have successfully moved '+this.itemCount+' item(s).').subscribe((res: string) => {
				this.alertService.success(res, false);
			});
			$("#foldertree").jstree("destroy");
			this.folderService.getAllFolders(this);
		}
	}

	onFail(res: WsResponse, serviceType: WsType): void {
		if (serviceType == WsType.FOLDER_VIEW) {
			if (res.statusCode == "532") {
				//this.router.navigateByUrl('login');
			}
			else if (res.statusCode == "600") {
				this.folderService.getAllFolders(this);
			} else {
				//show the error message
				//this.alertService.error(res.statusDescription.toString(), false);
			}
		}
		else if (serviceType == WsType.FOLDER_ADD) {
			if (res.statusCode == "600") {
				this.folderService.addFolder(JSON.stringify(this.folder), this)	
			} else {
				$("#foldertree").jstree("destroy");
				this.loadData(this.folderListPayload);
				this.alertService.error(res.statusDescription)
				//this.alertService.error(res.statusDescription.toString(), false);
			}
		}
		if (serviceType == WsType.FOLDER_UPDATE) {
			this.alertService.error(res.statusDescription);
			if (res.statusCode == "600") {
				this.folderService.updateFolder(JSON.stringify(this.folder),"rename", this)
			} else {
				$("#foldertree").jstree("destroy");
				this.loadData(this.folderListPayload);
				//this.alertService.error(res.statusDescription.toString(), false);
			}
		}
		else if (serviceType == WsType.FOLDER_MOVE) {
			this.alertService.error(res.statusDescription);
			if (res.statusCode == "600") {
				this.folderService.updateFolder(JSON.stringify(this.folder),"move", this)
			} else {
				$("#foldertree").jstree("destroy");
				this.loadData(this.folderListPayload);
			}
		}		
		else if (serviceType == WsType.DELETE_ITEM){			
			this.loadingService.hideLoading();
		}
		else if (serviceType == WsType.FOLDER_DELETE) {
			if (res.statusCode == "600") {
				this.folderService.deleteFolder(this);
			} else {
				$("#foldertree").jstree("destroy");
				this.loadData(this.folderListPayload);
				//this.alertService.error(res.statusDescription.toString(), false);
			}
			this.alertService.error(res.statusDescription);
		} else if (serviceType == WsType.GET_DEFAULT_RECENT_PROJECT) {
			this.alertService.error(res.statusDescription);
		} else if (serviceType == WsType.MOVE_FILES_FROM_FOLDER) {
			if(this.moveItemsList.split("::").length>2){
				if(res.statusDescription=="File name already exists"){
					this.alertService.error("Could not move some files. File name already exists", false);
					$("#foldertree").jstree("destroy");
					this.folderService.getAllFolders(this);
				}
			}
			else{
				this.alertService.error(res.statusDescription, false);
			}
			this.sharedService.folderRefreshRequest(this.selectedFolderId);
		}
	}

	public folderSelectEvent(evt: any, data: any) {
		console.log('[select_node.jstree] (data.node) ' + JSON.stringify(data.node.original.parent));
		this.selectedTreeItem = data
		let folderType: string = data.node.original.type
		this.renameDisable = (data.node.original.parent != "#")? false: true;
		this.deleteDisable = (data.node.original.parent != "#")? false: true;

		if(this.moveFolder && localStorage.getItem("fileMoveCancelled") === "false") {
			if(localStorage.getItem("isMoveFileEvent") === "true"){
				let prevFolderId = this.selectedFolderId;
				this.selectedFolderId = data.node.id
				if(this.selectedFolderId != prevFolderId){
					let selectedItems: any =  JSON.stringify(this.moveItemsList);
					if (selectedItems.search(this.selectedFolderId) > 0){
						this.translate.get('Please select a different folder').subscribe((res: string) => {
							this.alertService.warn(res, false);
						});
					} else {
						this.folderService.moveFolderItems(this.selectedFolderId,this.moveItemsList,this);
						this.moveFolder = false;
					}
					localStorage.setItem("isMoveFileEvent","false")
				}
			} {

			}
			//this.openModal(this.moveFolderItemsTemplate);
        } else {
            switch (folderType) {
                case 'folder':
                    this.selectedFolderId = data.node.id
					this.folderService.setSelectedFolderData(data.node);
                    if(this.sharedService.getNeedRefresh()){
                        this.sharedService.folderRefreshRequest(this.selectedFolderId);
					}
                    this.openTabsEvent(data.node.id, data.node.text, "Files",data.node.original.path)
                    break;
                case 'image':
                    this.selectedFolderId = data.node.id
                    break;
                case 'file':
                    this.selectedFolderId = null
                    break;
                case 'threeDModel':
                    this.selectedFolderId = data.node.id
                    break;	
                default:
					break;
				
			}
			//this.sharedService.setFolderPath(data.node.original.path+"/"+data.node.text);
        }
    }

	public openModal(template: TemplateRef<any>) {
		this.bsModalRef = this.modalService.show(template);
	}

	public cancelMoveFolder() {
		this.bsModalRef.hide();
	}

	public openTabsEvent(tabId: string, tabTitle: string, type: string, path): void {
		let data = {
			isSearch: false
		}
		this.sharedService.openTabRequestWithId(tabId, tabTitle, type, data, path)
	}

	/**
	 * drag and drop functionality
	 */
	public folderMoveEvent(evt: any, data: any) {
		//console.log('[move_node.jstree] (data.node) ' + JSON.stringify(data.node) + "event " + evt);
		this.folderService.setSelectedFolderData(data.node);
		this.folder = new Folder(data.node.text, data.node.parent, data.node.id)
		this.folder.lastUpdatedBy = UserVariable.userId;
		this.folderService.updateFolder(JSON.stringify(this.folder), "move", this)
	}

	public renameCallbackEvent(evt: any, data: any) {
		if (!data.node.state.selected) { //add folder
			this.folder = new Folder(data.node.text, data.node.parent)
			this.folderService.addFolder(JSON.stringify(this.folder), this)
		}
		else { //rename folder
			this.folder = new Folder(data.node.text, data.node.parent, data.node.id)
			this.folderService.updateFolder(JSON.stringify(this.folder), "rename", this)
			this.sharedService.renameTabRequest(data.node.id, data.node.text)
		}
	}

	public deleteCallbackEvent(evt: any, data: any) {
		console.log('[delete_node.jstree] (data.node.id) ' + JSON.stringify(data.node.id));
		this.deletedFolder = data.node
		if(this.folderService.getSelectedFoldeData().id == data.node.id){
			//folderService.deleteFolder(this) deletes only the selected folder
			this.folderService.deleteFolder(this);
		}
	}

	public renameElement() {
		//console.log('[renameElement]');
		let ref = $('#foldertree').jstree(true)
		let sel = ref.get_selected()
		if (!sel.length) { return false }
		sel = sel[0]
		ref.edit(sel)
	}

	public createElement() {
		console.log('[createElement]');
		let ref = $('#foldertree').jstree(true)
		let sel = ref.get_selected()
		if (!sel.length) { return false }
		sel = sel[0]
		sel = ref.create_node(sel, { icon: "far fa-folder p-1", type: "folder" });
		console.log('[createElement] (sel) ');
		console.log(sel);
		if (sel) {
			ref.edit(sel);
		}
	}

	viewDependencies(dflag: boolean) {
		this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.DELETE;
		this.dependenciesModalWithComponent("Delete Conflicts", dflag, true);
	}

	dependenciesModalWithComponent(header: String, dflag: boolean, cflag: boolean) {
		const initialState : object = {
			headerTitle: header,
			delFlag: dflag,
			cancelFlag: cflag,
			checkboxVal: this.status == "Inactive" ? true : false
		};
		this.sharedService.setFileId(this.selectedFolderId);
		this.sharedService.setFileType(Constants.DEPENDENCY_ITEM_TYPE.FOLDER);
        this.sharedService.setAction("getDependencies");
		this.sharedService.setDependencyMessageType(this.dependencyType);
		this.sharedService.setParent(this);
		this.bsModalRef = this.modalService.show(DependenciesmodalComponent, { initialState, class: 'modal-lg' });
	}

	public deleteElement() {
		//	localStorage.setItem("Confirm", "cancel");
		this.viewDependencies(true);
		//console.log('[deleteElement]');
		/*setTimeout(function () {
			console.log(localStorage.getItem("Confirm"))
			if (localStorage.getItem("Confirm") == "ok") {
				let ref = $('#foldertree').jstree(true)
				let sel = ref.get_selected()
				if (!sel.length) { return false }
				localStorage.setItem("Confirm", "cancel");
				console.log(localStorage.getItem("Confirm"))
				ref.delete_node(sel);
			}
		}, 10000);*/
	}

	public deleteElementFolderFromFolderTree(){
		console.log(localStorage.getItem("Confirm"));
		if (localStorage.getItem("Confirm") == "ok") {
			let ref = $('#foldertree').jstree(true)
			let sel = ref.get_selected()
			if (!sel.length) { return false }
			localStorage.setItem("Confirm", "cancel");
			console.log(localStorage.getItem("Confirm"))
			ref.delete_node(sel);
		}
		else{
			// do nothing
		}
	}

	/* Delete folders in folder tree by folder id list */
	public deleteFoldersFromFolderTreeByIds(folders : any){
		let ref = $('#foldertree').jstree(true)

		if (!folders.length) {
			return false 
		}

		ref.delete_node(folders);
	}

	public searchElement(event: any) {
		//console.log('[searchElement] ' + event.target.value);
		let to: any = false;
		if (to) { clearTimeout(to); }
		to = setTimeout(function () {
			let v = event.target.value
			$('#foldertree').jstree(true).search(v);
		}, 250);
	}

	public getWordlist() {
		this.folderService.getWordList().subscribe(data => {
			this.setWordListToString(data.payload);
			localStorage.setItem(LocalStorage.DICTIONARY, "array<$>" + this.wordListString);
		},
			error => {
				//this.alertService.clear()
				//this.alertService.error(error.statusDescription)
			});
	}

	setWordListToString(payload: any) {
		if (payload.length != 0) {
			this.wordListString = payload[0]["word"];
			for (let i = 1; i < payload.length; i++) {
				this.wordListString += ",";
				this.wordListString += payload[i]["word"];
			};
		}
		console.log(this.wordListString);
	}

	public refreshFolderTree() {
		$("#foldertree").jstree("destroy");
		this.folderService.getAllFolders(this);
	}

}
