<div>
    <!-- top button panel with  -->
    <div id="{{toolbarId}}" style="position:absolute; z-index:1050; left:28%;">
        <!-- This div will handle all toolbars. -->
    </div>
    <div class="container-fluid">
        <div class="col-sm-12 my-1 px-0 d-flex justify-content-end">
            <button type="button" class="btn btn-sm btn-outline-dark ml-1 mr-1 pointer-cursor border"
                (click)="collapseAndExpandAll()" [disabled]="!isCheckOut">
                {{ collapseExpandBtnText }}
            </button>
            <button type="button" class="btn btn-sm btn-outline-dark ml-1 mr-1 pointer-cursor border" (click)="addNewStep()"
                [disabled]="!isCheckOut || isCheckOutByOthers ">
                <i class="fa fa-plus" aria-hidden="true"></i> {{ "PROCEDURE_EDITOR.NEW_STEP" | translate }}
            </button>
            <button tooltip="{{ 'WEB_EDITOR.SAVE' | translate }}" [disabled]="!isCheckOut || isCheckOutByOthers"
                container="body" type="button" (click)="save()" class="btn btn-outline-dark btn-sm ml-1 mr-1 border">
                <i class="fas fa-save" aria-hidden="true"></i>
            </button>
            <button tooltip="{{ 'WEB_EDITOR.CHECK_IN' | translate }}" [disabled]="!isCheckOut || isCheckOutByOthers"
                container="body" type="button" (click)="openModal(checkin_comments)"
                class="btn btn-outline-dark btn-sm ml-1 mr-1 border">
                <i class="fas fa-download" aria-hidden="true"></i>
            </button>
            <button tooltip="{{ 'WEB_EDITOR.CHECK_OUT' | translate }}" [disabled]="isCheckOut" container="body"
                type="button" (click)="checkout()" class="btn btn-outline-dark btn-sm ml-1 mr-1 border">
                <i class="fas fa-upload" aria-hidden="true"></i>
            </button>
            <button tooltip="{{ 'WEB_EDITOR.UNDO_CHECK_OUT' | translate }}"
                [disabled]="!isCheckOut || isCheckOutByOthers" container="body" type="button" (click)="openModalUndoCheckoutConfirm()"
                class="btn btn-outline-dark btn-sm ml-1 mr-1 border">
                <i class="far fa-undo-alt" aria-hidden="true"></i>
            </button>
            <button tooltip="{{ 'WEB_EDITOR.ADD_SPE_CHAR' | translate }}" id="{{addSpecialCharDivId}}" container="body"
                type="button" (click)="openModal(spec_char)" hidden="true"
                class="btn btn-outline-primary btn-sm ml-1 mr-1 border">
                <i class="fas fa-omega" aria-hidden="true"></i>
            </button>
            <button tooltip="{{ 'WEB_EDITOR.ADD_FRACTION' | translate }}" id="{{addFractionDIvId}}" container="body"
                type="button" (click)="openModal(fraction)" hidden="true"
                class="btn btn-outline-primary btn-sm ml-1 mr-1 border">
                <i class="fas fa-divide" aria-hidden="true"></i>
            </button>
        </div>
        <div id="hide-div" class="col-sm-12 px-0 my-2">
            <div class="border rounded px-0 my-1">
                <div contenteditable="false" class="toggle-tagedit tag_editor"></div>
            </div>
        </div>
    </div>

    <div class="flex-row" id="proc_editor" style="overflow-y: auto; overflow-x: hidden;" [style.height.px]="divHeight*heightFactor">
        <div class="container-fluid my-2" >
            <div class="card">
                <div class="card-header py-1">
                    <p class="float-left m-0 pl-1">{{ "PROCEDURE_EDITOR.HEADER" | translate}}</p>
                    <button type="button"
                        class="btn btn-sm btn-outline-dark float-right pl-1 pr-1 pt-0 pb-0 ml-1 mr-1 mb-1 pointer-cursor border-0"
                        (click)="showProHeaderDetail = !showProHeaderDetail">
                        <i class="fa" aria-hidden="true"
                            [ngClass]="{'fa-chevron-up': showProHeaderDetail, 'fa-chevron-down': !showProHeaderDetail}"></i>
                    </button>
                </div>
                <div class="card-block card-body py-1"
                    [ngClass]="{'ph-show': showProHeaderDetail, 'ph-hide': !showProHeaderDetail}">
                    <form>
                        <div class="row">
                            <div class="col">
                                <div class="form-group mb-2">
                                    <label for="system_name"
                                        class="mb-0">{{ "PROCEDURE_EDITOR.SYSTEM_NAME" | translate}}</label>
                                    <input type="text" class="form-control form-control-sm" id="ckeditor_system_name"
                                        value="{{systemName}}" readonly>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group mb-2">
                                    <label for="procedure_type" class="mb-0">{{ "PROCEDURE_EDITOR.PROCEDURE_TYPE" |
                                    translate}}</label>
                                    <input type="text" class="form-control form-control-sm" id="ckeditor_procedure_type"
                                        value="{{proceType}}" readonly>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col form-group mb-2">
                                <label for="ckeditor_folder"
                                    class="mb-0">{{ "PROCEDURE_EDITOR.FOLDER" | translate}}</label>
                                <input type="text" class="form-control form-control-sm" id="ckeditor_folder"
                                    value="{{folderPath}}" readonly>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col form-group mb-2">
                                <label for="ckeditor_folder"
                                    class="mb-0">{{ "PROCEDURE_EDITOR.TITLE" | translate}}</label>
                                <div class="border rounded">
                                    <div contenteditable="false"
                                        class="toggle-edit{{tabId}}{{userId}} cke_editable_inline cke_contents_ltr ckeditor_title" (dblclick)="activeEvent($event.target)">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row my-2" [ngClass]="{'ph-show': imagePanelFlag, 'ph-hide': !imagePanelFlag}">
                            <div class="col form-group mb-2">
                                <div class="px-1 py-1">
                                    <label for="ckeditor_folder float-left" class="mb-0">{{ "PROCEDURE_EDITOR.IMAGES" |
                                    translate}}</label>
                                    <!---span class="ml-2 mr-1 border-0 float-right" (click)="imagePanelFlag = false">
                                        <i class="fas fa-times fa-sm" aria-hidden="true"></i>
                                    </span-->
                                    <button type="button"  class="btn-outline-secondary mx-2 border-0 float-right"
                                        [disabled]="!isCheckOut || isCheckOutByOthers" (click)="addToHeaderImage()">
                                        <i class="fas fa-plus fa-sm" aria-hidden="true"></i>
                                    </button>
                                </div>
                                <div class="border rounded px-3 py-3">
                                    <div class="card-columns form-group" *ngFor="let image of imageList; let i=index">
                                        <!-- iterate image list -->
                                        <div class="card" style="width: 12rem;">
                                            <button class="float-right" (click)="removeHeaderImage(i)">
                                                <i class="fas fa-times fa-sm" aria-hidden="true"></i>
                                            </button>
                                            <img class="card-img-top img-fluid pointer-cursor"
                                                src="data:image/jpeg;base64,{{image.thumbnailImage}}"
                                                alt="{{image.title}}">
                                            <div class="card-body p-1" *ngIf="image.type=='imageAsFigure'">
                                                <div class="border rounded col-sm-12 my-1 p-1">
                                                    <div contenteditable="false"
                                                        class="toggle-edit{{tabId}}{{userId}} cke_editable_inline cke_contents_ltr img_editor{{i}}" (dblclick)="activeEvent($event.target)">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col form-group mb-2">
                                <div class="float-right">
                                    <button type="button" class="btn btn-sm mx-1 float-right"
                                        [disabled]="!isCheckOut || isCheckOutByOthers"
                                        tooltip="{{ 'PROCEDURE_EDITOR.BTN_TT_ADD_NEW_IMAGE' | translate }}"
                                        (click)="imagePanelFlag = !imagePanelFlag"
                                        [ngClass]="{'btn-outline-dark': !imagePanelFlag, 'btn-dark': imagePanelFlag}">
                                        <i class="fa fa-camera-retro" aria-hidden="true"></i>
                                    </button>
                                    <div class="dropdown mx-1 float-right">
                                        <button class="btn btn-outline-dark btn-sm dropdown-toggle" type="button"
                                            id="procedure_dropdown_ncwr" data-toggle="dropdown" aria-haspopup="true"
                                            aria-expanded="false" container="body"
                                            [disabled]="!isCheckOut || isCheckOutByOthers"
                                            tooltip="{{ 'PROCEDURE_EDITOR.BTN_TT_NEW_NCWR' | translate }}">
                                            <i class="fas fa-cubes"></i>
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right"
                                            aria-labelledby="procedure_dropdown_ncwr">
                                            <li *ngFor="let option of optionList; let n=index" id="{{n}}"
                                                (click)="addNCWR(option.text)">
                                                <a class="dropdown-item">{{option.text}}</a>
                                            </li>
                                        </div>
                                    </div>
                                    <button type="button" class="btn btn-outline-dark btn-sm mx-1 float-right"
                                        (click)="addAO('Assumption')" [disabled]="!isCheckOut || isCheckOutByOthers" 
                                        tooltip="{{ 'PROCEDURE_EDITOR.BTN_TT_ADD_NEW_ASSUMPTION' | translate }}">
                                        <label for="ckeditor_assumption" class="mb-0">A+</label>
                                    </button>
                                    <button type="button" class="btn btn-outline-dark btn-sm mx-1 float-right"
                                        (click)="addAO('Objective')" [disabled]="!isCheckOut || isCheckOutByOthers"
                                         tooltip="{{ 'PROCEDURE_EDITOR.BTN_TT_ADD_NEW_OBJECTIVE' | translate }}">
                                        <label for="ckeditor_objective" class="mb-0">O+</label>
                                    </button>
                                    <!-- <div class="btn-group" dropdown [isDisabled]="!isCheckOut">
                                        <button id="procedure_dropdown_ncwr" dropdownToggle type="button"
                                            class="btn btn-outline-dark btn-sm dropdown-toggle" container="body"
                                            tooltip="{{ 'PROCEDURE_EDITOR.BTN_TT_NEW_NCWR' | translate }}"
                                            aria-controls="dropdown-ncwr">
                                            <i class="fas fa-cubes"></i>
                                            <span class="caret"></span>
                                        </button>
                                        <ul id="dropdown-ncwr" *dropdownMenu class="dropdown-menu" role="menu"
                                            aria-labelledby="dropdown-ncwr">
                                            <li *ngFor="let option of optionList; let n=index" id="{{n}}"
                                                (click)="addNCWR(option.text)">
                                                <a class="dropdown-item">{{option.text}}</a>
                                            </li>
                                        </ul>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col form-group mb-2" *ngFor="let item of assList; let n=index">
                                <label for="ckeditor_assumption" class="mb-0">
                                    {{ "PROCEDURE_EDITOR.ASSUMPTION" | translate }}
                                </label>
                                <div class="float-right col-sm-6 px-0">
                                    <button type="button" class="btn btn-outline-secondary btn-sm border-0 float-right"
                                        (click)="deleteAO('Assumption')">
                                        <i class="fa fa-times" aria-hidden="true"></i>
                                    </button>
                                </div>
                                <div class="border rounded col-sm-12 my-1 p-1">
                                    <div contenteditable="false"
                                        class="toggle-edit{{tabId}}{{userId}} cke_editable_inline cke_contents_ltr ack_editor{{n}}" (dblclick)="activeEvent($event.target)">
                                    </div>
                                </div>
                            </div>
                            <div class="col form-group mb-2" *ngFor="let item of objList; let n=index">
                                <label for="ckeditor_objective"
                                    class="mb-0">{{ "PROCEDURE_EDITOR.OBJECTIVE" | translate }}</label>
                                <div class="float-right col-sm-6 px-0">
                                    <button type="button" class="btn btn-outline-secondary btn-sm border-0 float-right"
                                        (click)="deleteAO('Objective')" [disabled]="!isCheckOut || isCheckOutByOthers">
                                        <i class="fa fa-times" aria-hidden="true"></i>
                                    </button>
                                </div>
                                <div class="border rounded col-sm-12 my-1 p-1">
                                    <div contenteditable="false"
                                        class="toggle-edit{{tabId}}{{userId}} cke_editable_inline cke_contents_ltr ock_editor{{n}}" (dblclick)="activeEvent($event.target)">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="col-sm-12 px-0 mb-2"
                                *ngFor="let ncwr of ncwrList | orderBy : 'seq'; let n=index" id="{{n}}">

                                <div class="card mb-2 bg-light">
                                    <div class="d-flex flex-row no-gutters align-items-center flex-xl-wrap">
                                        <div class="float-left px-1">
                                            <h5 class="text-center header-ncwr my-1">{{ncwr.type}}</h5>
                                        </div>
                                        <div class="flex-fill border-left border-right px-1">
                                            <div contenteditable="false"
                                                class="toggle-edit{{tabId}}{{userId}} cke_editable_inline cke_contents_ltr ck_editor{{n}}" (dblclick)="activeEvent($event.target)">
                                            </div>
                                        </div>
                                        <div class="float-right d-flex">
                                            <div class="btn-group btn-group-sm">
                                                <button type="button" class="btn btn-sm float-right mx-2 btn-outline-dark dropdown-toggle"
                                                     data-toggle="dropdown"
                                                     [disabled]="!isCheckOut || isCheckOutByOthers"
                                                     tooltip="{{ 'PROCEDURE_EDITOR.BTN_TT_BG_COLOR' | translate }}" container="body">
                                                    <span [style.background-color]="ncwr.backgroundColor" class="selected-color"></span></button>
                                                <div class="dropdown-menu dropdown-menu-right"
                                                    [disabled]="!isCheckOut || isCheckOutByOthers">
                                                    <div class="automatic" [ngClass]="{'selected-color-item': (!ncwr.backgroundColor || ncwr.backgroundColor=='')}"
                                                        (click)="ncwr.backgroundColor = ''">
                                                        <span style="background-color: white;" class="selected-color"></span>
                                                        <span class="automatic-text">Automatic</span>
                                                    </div> 
                                                    <div class="color-dropdown">
                                                        <div *ngFor="let color of predefinedColors" class="color-box"
                                                            (click)="ncwr.backgroundColor = color" [style.background-color]="color"
                                                            [ngClass]="{'white-color': color == '#FFF', 'selected-color-item': ncwr.backgroundColor==color}"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <button prevent-parent-click
                                                type="button" class="close close-ncwr float-right mx-2"
                                                (click)="moveDownNCWR(ncwr)"
                                                [disabled]="!isCheckOut || isCheckOutByOthers || !((ncwrList.length > 1) && (n != (ncwrList.length - 1)))">
                                                <i class="fa fa-arrow-down" aria-hidden="true"></i>
                                            </button>
                                            <button prevent-parent-click 
                                                type="button" class="close close-ncwr float-right mx-2"
                                                (click)="moveUpNCWR(ncwr)"
                                                [disabled]="!isCheckOut || isCheckOutByOthers || !((ncwrList.length > 1) && (n != 0))">
                                                <i class="fa fa-arrow-up" aria-hidden="true"></i>
                                            </button>
                                            <button type="button" class="close close-ncwr float-right mx-2"
                                                (click)="deleteNCWR(ncwr)"
                                                [disabled]="!isCheckOut || isCheckOutByOthers">
                                                <i class="fa fa-times" aria-hidden="true"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <!-- <div class="input-group input-group-sm ">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="inputGroupFileAddon01">{{ncwr.type}}</span>
                                    </div>
                                    <div class="custom-file border border-secondary d-flex align-items-start ">
                                        <div class="col-sm-12 px-1">
                                            <div contenteditable="false"
                                                class="toggle-edit{{tabId}}{{userId}} cke_editable_inline cke_contents_ltr ck_editor{{n}}">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="input-group-append">
                                        <button prevent-parent-click *ngIf="(ncwrList.length > 1) && (n != 0)"
                                            type="button" class="btn btn-outline-secondary" (click)="moveUpNCWR(ncwr)">
                                            <i class="fa fa-arrow-up" aria-hidden="true"></i>
                                        </button>
                                        <button prevent-parent-click
                                            *ngIf="(ncwrList.length > 1) && (n != (ncwrList.length - 1))" type="button"
                                            class="btn btn-outline-secondary" (click)="moveDownNCWR(ncwr)">
                                            <i class="fa fa-arrow-down" aria-hidden="true"></i>
                                        </button>
                                        <button type="button" class="btn btn-outline-secondary"
                                            (click)="deleteNCWR(ncwr)">
                                            <i class="fa fa-times" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                </div> -->

                                <!-- <div class="col-sm-12">
                                    <div class="float-left col-sm-4 px-0">
                                        <label for="ckeditor_folder" class="mb-0">{{ncwr.type}}</label>
                                    </div>
                                    <div class="float-right col-sm-6 px-0">
                                        <button type="button"
                                            class="btn btn-outline-secondary btn-sm border-0 float-right"
                                            (click)="deleteNCWR(ncwr)">
                                            <i class="fa fa-times" aria-hidden="true"></i>
                                        </button>
                                        <button prevent-parent-click *ngIf="(ncwrList.length > 1) && (n != 0)"
                                            type="button"
                                            class="btn btn-outline-secondary btn-sm border-0 float-right mx-1"
                                            (click)="moveUpNCWR(ncwr)">
                                            <i class="fa fa-arrow-up" aria-hidden="true"></i>
                                        </button>
                                        <button prevent-parent-click
                                            *ngIf="(ncwrList.length > 1) && (n != (ncwrList.length - 1))" type="button"
                                            class="btn btn-outline-secondary btn-sm border-0 float-right mx-1"
                                            (click)="moveDownNCWR(ncwr)">
                                            <i class="fa fa-arrow-down" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="col-sm-12 my-1">
                                    <div class="border rounded border-secondary col-sm-12 my-1 mx-2">
                                        <div contenteditable="false"
                                            class="toggle-edit{{tabId}}{{userId}} cke_editable_inline cke_contents_ltr ck_editor{{n}}">
                                        </div>
                                    </div>
                                </div> -->

                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div class="container-fluid my-4 pb-4">
            <div class="col-sm-12 border-bottom px-2 d-flex flex-row">
                <div class="col float-left px-0">
                    <p class="float-left m-0 ">{{ "PROCEDURE_EDITOR.STEPS" | translate }}
                        <span class="badge badge-warning mx-1">{{stepsList.length}}</span>
                    </p>
                </div>
                <!-- <button type="button" class="btn btn-sm btn-outline-dark px-1 py-0 mx-3 mb-1 pointer-cursor"
                    (click)="addNewStep()" [disabled]="!isCheckOut">
                    <i class="fa fa-plus" aria-hidden="true"></i> {{ "PROCEDURE_EDITOR.NEW_STEP" | translate }}
                </button> -->
                <div class="col float-right px-0">
                    <!-- <button type="button" class="btn btn-sm btn-outline-dark px-1 py-0 mb-1 pointer-cursor float-right"
                        (click)="collapseAndExpandAll()" [disabled]="!isCheckOut">
                        {{ collapseExpandBtnText }}
                    </button> -->
                </div>
            </div>

            <!-- step style="overflow-y: auto; overflow-x: hidden" [style.height.px]="divHeight" -->
            <div class="col-sm-12 mx-auto my-2 px-1">
                <div class="row" *ngFor="let step of stepsList | orderBy : 'stepNo'; let n=index" id="{{n}}">
                    <div class="col-sm-12 my-2">
                        <div class="card ">  
                            <!--div class="card-header ch-procedure py-1 px-2">                               
                            </div-->                         
                            <div class="card-body py-1">
                                <div class="row">
                                    <!--Group Title -->
                                    <div class="col-sm-12 px-1" *ngIf="step.groupTitle != null">
                                        <div class="card card-gt my-2">
                                            <div class="card-header ch-procedure p-1">
                                                <div class="float-left">
                                                    <div class="px-0">
                                                        <span class="badge badge-secondary mx-1 mt-2 h6">Group
                                                            Title</span>
                                                    </div>
                                                </div>
                                                <div class="float-right px-0">
                                                    <button type="button"
                                                        class="btn btn-outline-secondary btn-sm border-0 float-right"
                                                        (click)="deleteGroupTitle(step)"
                                                        [disabled]="!isCheckOut || isCheckOutByOthers">
                                                        <i class="fa fa-times" aria-hidden="true"></i>
                                                    </button>
                                                    <div class="dropdown mx-2 float-right">
                                                        <button class="btn btn-outline-dark btn-sm dropdown-toggle"
                                                            type="button" id="procedure_dropdown_ncwr"
                                                            [disabled]="!isCheckOut || isCheckOutByOthers"
                                                            data-toggle="dropdown" aria-haspopup="true"
                                                            aria-expanded="false" container="body"
                                                            tooltip="{{ 'PROCEDURE_EDITOR.BTN_TT_NEW_NCWR' | translate }}">
                                                            <i class="fas fa-cubes"></i>
                                                        </button>
                                                        <div class="dropdown-menu dropdown-menu-right"
                                                            aria-labelledby="procedure_dropdown_ncwr">
                                                            <li *ngFor="let option of titleOptionList; let n=index"
                                                                id="{{n}}" (click)="addGtNCWR(option.text, step)">
                                                                <a class="dropdown-item">{{option.text}}</a>
                                                            </li>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="btn-group float-right mx-2" dropdown
                                                        [isDisabled]="!isCheckOut">
                                                        <button id="procedure_dropdown_ncwr" dropdownToggle
                                                            type="button" aria-controls="dropdown-ncwr"
                                                            class="btn btn-dark btn-sm dropdown-toggle px-1">
                                                            <i class="fa fa-exclamation-triangle"
                                                                aria-hidden="true"></i>
                                                            <span class="caret mx-2"></span>
                                                        </button>
                                                        <ul id="dropdown-ncwr" *dropdownMenu
                                                            class="dropdown-menu dropdown-menu-right" role="menu"
                                                            aria-labelledby="dropdown-ncwr">
                                                            <li *ngFor="let option of titleOptionList; let n=index"
                                                                (click)="addGtNCWR(option.text, step)">
                                                                <a class="dropdown-item">{{option.text}}</a>
                                                            </li>
                                                        </ul>
                                                    </div> -->
                                                </div>
                                            </div>
                                            <div class="card-body px-2 pt-0 pb-2">
                                                <div class="border rounded border-secondary col-sm-12 my-1">
                                                    <div contenteditable="false"
                                                        class="toggle-edit{{tabId}}{{userId}} cke_editable_inline cke_contents_ltr gtck_editor{{n}}" (dblclick)="activeEvent($event.target)">
                                                    </div>
                                                </div>



                                                <!-- <div class="card my-1"
                                            [ngClass]="{'ncwr-si-show': isExpandAll, 'ph-hide': !isExpandAll}"
                                            *ngFor="let ncwr of step.groupTitle.ncwrs | orderBy : 'seq'; let m=index"
                                            id="{{m}}">
                                            <div class="card-header p-1">
                                                <div class="float-left px-0 mt-1 px-2">
                                                    <label for="ckeditor_folder" class="mb-0">{{ncwr.type}}</label>
                                                </div>
                                                <div class="float-right px-0">
                                                    <button type="button"
                                                        class="btn btn-outline-secondary btn-sm border-0 float-right"
                                                        (click)="deleteGtNCWR(ncwr, step)">
                                                        <i class="fa fa-times" aria-hidden="true"></i>
                                                    </button>
                                                    <button prevent-parent-click
                                                        *ngIf="(step.groupTitle.ncwrs.length > 1) && (m != 0)"
                                                        type="button"
                                                        class="btn btn-outline-secondary btn-sm border-0 float-right mx-1"
                                                        (click)="moveUpNCWR(ncwr, step)">
                                                        <i class="fa fa-arrow-up" aria-hidden="true"></i>
                                                    </button>
                                                    <button prevent-parent-click
                                                        *ngIf="(step.groupTitle.ncwrs.length > 1) && (m != (step.groupTitle.ncwrs.length - 1))"
                                                        type="button"
                                                        class="btn btn-outline-secondary btn-sm border-0 float-right mx-1"
                                                        (click)="moveDownNCWR(ncwr, step)">
                                                        <i class="fa fa-arrow-down" aria-hidden="true"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="card-body px-2 py-2">
                                                <div class="border rounded border-secondary col-sm-12 my-1 mx-2 p-1">
                                                    <div contenteditable="false"
                                                        class="toggle-edit{{tabId}}{{userId}} cke_editable_inline cke_contents_ltr gtck_ncwr_editor{{n}}{{m}}">
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->

                                                <div class="card mb-2 bg-light"
                                                    [ngClass]="{'ncwr-si-show': isExpandAll, 'ph-hide': !isExpandAll}"
                                                    *ngFor="let ncwr of step.groupTitle.ncwrs | orderBy : 'seq'; let m=index"
                                                    id="{{m}}">
                                                    <div
                                                        class="d-flex flex-row no-gutters align-items-center flex-xl-wrap">
                                                        <div class="float-left px-1">
                                                            <h5 class="text-center header-ncwr my-1">{{ncwr.type}}</h5>
                                                        </div>
                                                        <div class="flex-fill border-left border-right px-1">
                                                            <div contenteditable="false"
                                                                class="toggle-edit{{tabId}}{{userId}} cke_editable_inline cke_contents_ltr gtck_ncwr_editor{{n}}{{m}}" (dblclick)="activeEvent($event.target)">
                                                            </div>
                                                        </div>
                                                        <div class="float-right d-flex">
                                                            <div class="btn-group btn-group-sm">
                                                                <button type="button" class="btn btn-sm float-right mx-2 btn-outline-dark dropdown-toggle"
                                                                     data-toggle="dropdown"
                                                                     [disabled]="!isCheckOut || isCheckOutByOthers"
                                                                     tooltip="{{ 'PROCEDURE_EDITOR.BTN_TT_BG_COLOR' | translate }}" container="body">
                                                                    <span [style.background-color]="ncwr.backgroundColor" class="selected-color"></span></button>
                                                                <div class="dropdown-menu dropdown-menu-right"
                                                                    [disabled]="!isCheckOut || isCheckOutByOthers">
                                                                    <div class="automatic" [ngClass]="{'selected-color-item': (!ncwr.backgroundColor || ncwr.backgroundColor=='')}"
                                                                        (click)="ncwr.backgroundColor = ''">
                                                                        <span style="background-color: white;" class="selected-color"></span>
                                                                        <span class="automatic-text">Automatic</span>
                                                                    </div> 
                                                                    <div class="color-dropdown">
                                                                        <div *ngFor="let color of predefinedColors" class="color-box"
                                                                            (click)="ncwr.backgroundColor = color" [style.background-color]="color"
                                                                            [ngClass]="{'white-color': color == '#FFF', 'selected-color-item': ncwr.backgroundColor==color}"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <button prevent-parent-cl type="button" class="close close-ncwr float-right mx-2" (click)="moveDownNCWR(ncwr, step)"
                                                                [disabled]="!isCheckOut || isCheckOutByOthers || !((step.groupTitle.ncwrs.length > 1) && (m != (step.groupTitle.ncwrs.length - 1)))">
                                                                <i class="fa fa-arrow-down" aria-hidden="true"></i>
                                                            </button>
                                                            <button prevent-parent-click type="button" class="close close-ncwr float-right mx-2" (click)="moveUpNCWR(ncwr, step)"
                                                                [disabled]="!isCheckOut || isCheckOutByOthers || !((step.groupTitle.ncwrs.length > 1) && (m != 0))">
                                                                <i class="fa fa-arrow-up" aria-hidden="true"></i>
                                                            </button>
                                                            <button type="button"
                                                                class="close close-ncwr float-right mx-2"
                                                                (click)="deleteGtNCWR(ncwr, step)"
                                                                [disabled]="!isCheckOut || isCheckOutByOthers">
                                                                <i class="fa fa-times" aria-hidden="true"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="edit-input-wrapper" >
                                        <div class="px-0 edit-link" *ngIf="isShowBadgeItem != n">
                                            <span class="badge badge-warning mx-1 mt-2 h6" (dblclick)="badgeDoubleClickToEdit($event,step)">
                                                {{n + 1}}</span>
                                        </div>
                                        <div class="edit-input hide" >
                                                <small  class="form-text text-muted">
                                                        Move to 
                                                    </small>
                                            <input type="number" id="stepNum" [value]="n + 1" class="form-control form-control-sm">
                                            <small id="passwordHelpBlock" class="form-text text-muted hint-edit">
                                                ENTER to save & ESC to cancel
                                            </small>
                                        </div>
                                        <div class="col-sm-2 px-1" *ngIf="isShowBadgeItem == n">
                                            <input type="number" id="stepNum" class="form-control form-control-sm"
                                                aria-describedby="passwordHelpBlock" (keydown)="onKeydownStepNum($event)"
                                                [(ngModel)]="selectedItemStepNum">
                                            <small id="passwordHelpBlock" class="form-text text-muted">
                                                ENTER to save & ESC to cancel
                                            </small>
                                        </div>
                                    </div>
                                    <div class="ml-auto">
                                        <button type="button" class="btn btn-outline-secondary btn-sm border-0 float-right"
                                            (click)="deleteStep(step)" [disabled]="!isCheckOut || isCheckOutByOthers"
                                            tooltip="{{ 'PROCEDURE_EDITOR.BTN_TT_CLOSE' | translate }}">
                                            <i class="fa fa-times" aria-hidden="true"></i>
                                        </button>
                                        <button prevent-parent-click
                                            type="button" class="btn btn-outline-secondary btn-sm border-0 float-right mx-1"
                                            (click)="moveUpStep(step)" [disabled]="!isCheckOut || isCheckOutByOthers || !((stepsList.length > 1) && (n != 0))"
                                            tooltip="{{ 'PROCEDURE_EDITOR.BTN_TT_UP' | translate }}">
                                            <i class="fa fa-arrow-up" aria-hidden="true"></i>
                                        </button>
                                        <button prevent-parent-click
                                            type="button" class="btn btn-outline-secondary btn-sm border-0 float-right mx-1"(click)="moveDownStep(step)" 
                                            [disabled]="!isCheckOut || isCheckOutByOthers || !((stepsList.length > 1) && (n != (stepsList.length - 1)))"
                                            tooltip="{{ 'PROCEDURE_EDITOR.BTN_TT_DOWN' | translate }}">
                                            <i class="fa fa-arrow-down" aria-hidden="true"></i>
                                        </button>
    
                                        <div class="btn-group btn-group-sm float-right mx-2">
                                            <button type="button" class="btn btn-outline-dark"
                                                [disabled]="!isCheckOut || isCheckOutByOthers"
                                                tooltip="{{ 'PROCEDURE_EDITOR.BTN_TT_GROUP_TITLE' | translate }}"
                                                (click)="addStepItem('Group Title', step)" container="body">
                                                <i class="far fa-heading"></i>
                                            </button>
                                            <button type="button" class="btn btn-outline-dark"
                                                [disabled]="!isCheckOut || isCheckOutByOthers"
                                                tooltip="{{ 'PROCEDURE_EDITOR.BTN_TT_SUB_STEP' | translate }}"
                                                (click)="addStepItem('Sub Step', step)" container="body">
                                                <i class="far fa-stream"></i>
                                            </button>
                                            <button type="button" class="btn btn-outline-dark"
                                                [disabled]="!isCheckOut || isCheckOutByOthers"
                                                tooltip="{{ 'PROCEDURE_EDITOR.BTN_TT_LOCATION' | translate }}"
                                                (click)="addStepItem('Location', step)" container="body">
                                                <i class="far fa-map-marker-alt"></i>
                                            </button>
                                            <button type="button" class="btn btn-outline-dark"
                                                [disabled]="!isCheckOut || isCheckOutByOthers"
                                                tooltip="{{ 'PROCEDURE_EDITOR.BTN_TT_DOCUMENTS' | translate }}"
                                                (click)="addStepItem('Document', step)" container="body">
                                                <i class="far fa-file-alt"></i>
                                            </button>
                                            <button type="button" class="btn btn-outline-dark"
                                                [disabled]="!isCheckOut || isCheckOutByOthers"
                                                tooltip="{{ 'PROCEDURE_EDITOR.BTN_TT_DEPENDENCY' | translate }}"
                                                (click)="addStepItem('Dependency', step)" container="body">
                                                <i class="far fa-link"></i>
                                            </button>
                                            <button type="button" class="btn btn-outline-dark"
                                                [disabled]="!isCheckOut || isCheckOutByOthers"
                                                tooltip="{{ 'PROCEDURE_EDITOR.BTN_TT_DUPLICATE' | translate }}"
                                                (click)="addStepItem('Duplicate', step)" container="body">
                                                <i class="far fa-clone"></i>
                                            </button>
                                            <div class="btn-group btn-group-sm">
                                                <button type="button" class="btn btn-outline-dark dropdown-toggle"
                                                    data-toggle="dropdown" [disabled]="!isCheckOut || isCheckOutByOthers"
                                                    container="body"
                                                    tooltip="{{ 'PROCEDURE_EDITOR.BTN_TT_NEW_NCWR' | translate }}">
                                                    <i class="fas fa-cubes"></i>
                                                </button>
                                                <div class="dropdown-menu dropdown-menu-right"
                                                    [disabled]="!isCheckOut || isCheckOutByOthers">
                                                    <div *ngFor="let option of stepOptionList; let n=index"
                                                        (click)="addStepItem(option.text, step)">
                                                        <a class="dropdown-item">{{option.text}}</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <button type="button" class="btn btn-outline-dark"
                                                [disabled]="!isCheckOut || isCheckOutByOthers"
                                                tooltip="{{ 'PROCEDURE_EDITOR.BTN_TT_RESPONSIBLE_PARTY' | translate }}"
                                                (click)="addStepItem('Responsible Party', step)" container="body">
                                                <i class="fas fa-user-friends"></i>
                                            </button>
                                            <button type="button" class="btn btn-outline-dark"
                                                [disabled]="!isCheckOut || isCheckOutByOthers"
                                                tooltip="{{ 'PROCEDURE_EDITOR.BTN_TT_OPERATING_DATA' | translate }}"
                                                (click)="addStepItem('Operating Data', step)" container="body">
                                                <i class="fas fa-file-spreadsheet"></i>
                                            </button>
                                        </div>
    
                                        <!-- <div class="btn-group float-right mx-2" dropdown [isDisabled]="!isCheckOut">
                                            <button id="procedure_dropdown_ncwr" dropdownToggle type="button"
                                                aria-controls="dropdown-ncwr"
                                                class="btn btn-dark btn-sm dropdown-toggle px-1">
                                                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                                <span class="caret mx-1"></span>
                                            </button>
                                            <ul id="dropdown-ncwr" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                                                role="menu" aria-labelledby="dropdown-ncwr">
                                                <li *ngFor="let option of stepOptionList; let n=index"
                                                    (click)="addStepItem(option.text, step)">
                                                    <a class="dropdown-item">{{option.text}}</a>
                                                </li>
                                            </ul>
                                        </div> -->                                        
                                        <div class="btn-group btn-group-sm">
                                            <button type="button" class="btn btn-sm mx-1 btn-outline-dark dropdown-toggle"
                                                 data-toggle="dropdown"
                                                 [disabled]="!isCheckOut || isCheckOutByOthers"
                                                 tooltip="{{ 'PROCEDURE_EDITOR.BTN_TT_BG_COLOR' | translate }}" container="body">
                                                <span [style.background-color]="step.backgroundColor" class="selected-color"></span></button>
                                            <div class="dropdown-menu dropdown-menu-right"
                                                [disabled]="!isCheckOut || isCheckOutByOthers">
                                                <div class="automatic" [ngClass]="{'selected-color-item': (!step.backgroundColor || step.backgroundColor=='')}"
                                                    (click)="step.backgroundColor = ''">
                                                    <span style="background-color: white;" class="selected-color"></span>
                                                    <span class="automatic-text">Automatic</span>
                                                </div> 
                                                <div class="color-dropdown">
                                                    <div *ngFor="let color of predefinedColors" class="color-box"
                                                        (click)="step.backgroundColor = color" [style.background-color]="color"
                                                        [ngClass]="{'white-color': color == '#FFF', 'selected-color-item': step.backgroundColor==color}"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="btn-group btn-group-sm">
                                            <button type="button" class="btn btn-sm mx-1 btn-outline-dark dropdown-toggle"
                                                data-toggle="dropdown"
                                                [disabled]="!isCheckOut || isCheckOutByOthers"
                                                tooltip="{{ 'PROCEDURE_EDITOR.BTN_TT_ACTION' | translate }}"
                                                [ngClass]="{'btn-outline-dark': !step.action, 'btn-dark': step.action}">
                                                <i class="fa fa-clipboard-list-check" aria-hidden="true"></i>
                                            </button>         
                                            <div class="dropdown-menu dropdown-menu-right"
                                                [disabled]="!isCheckOut || isCheckOutByOthers">
                                                <div *ngFor="let option of actionMenuOptionList; let n=index"
                                                    (click)="addAction(option.text, step)">
                                                    <a class="dropdown-item"
                                                    [class.active]="step.action === option.text || (!step.action && option.text === 'Nothing')">{{option.text}}</a>
                                                </div>
                                            </div>
                                        </div>
                                        <button type="button" class="btn btn-sm mx-1"
                                            [disabled]="!isCheckOut || isCheckOutByOthers"
                                            tooltip="{{ 'PROCEDURE_EDITOR.BTN_TT_IMAGE' | translate }}"
                                            (click)="step.imagePanelFlag = !step.imagePanelFlag"
                                            [ngClass]="{'btn-outline-dark': !step.imagePanelFlag, 'btn-dark': step.imagePanelFlag}">
                                            <i class="fa fa-camera-retro" aria-hidden="true"></i>
                                        </button>
                                    </div>

                                    <!-- ck editor -->
                                    <div class="col-sm-12 bg-grey-25 br-bt-grey-50 pt-1 pb-2">
                                        <div class="border rounded border-secondary col-sm-12 my-1 p-1">
                                            <div contenteditable="false"
                                                class="toggle-edit{{tabId}}{{userId}} cke_editable_inline cke_contents_ltr step_ck_editor{{n}}" (dblclick)="activeEvent($event.target)">
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Action -->
                                    <div class="col-sm-12 my-1" *ngIf="step.action"
                                        [ngClass]="{'ph-show': isExpandAll, 'ph-hide': !isExpandAll}">
                                        <div class="card my-1 bg-light">
                                            <div class="d-flex flex-row no-gutters align-items-center flex-xl-wrap">
                                                <div class="float-left px-1">
                                                    <h5 class="text-center header-ncwr my-1">
                                                        Action
                                                    </h5>
                                                </div>
                                                <div class="flex-fill border-left border-right px-1">
                                                    <div contenteditable="false"
                                                        class="cke_contents_ltr ck_loc_editor{{n}}"> {{ step.action }}
                                                    </div>
                                                </div>
                                                <div class="float-right">
                                                    <button type="button" class="close close-ncwr float-right mx-2"
                                                        (click)="step.action = ''"
                                                        [disabled]="!isCheckOut || isCheckOutByOthers">
                                                        <i class="fa fa-times" aria-hidden="true"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!--NCWRS-->
                                    <div class="col-sm-12 my-1" *ngIf="step.ncwrs.length != 0"
                                        [ngClass]="{'ph-show': isExpandAll, 'ph-hide': !isExpandAll}">
                                        <div *ngFor="let ncwr of step.ncwrs | orderBy : 'seq'; let x=index" id="{{x}}">
                                            <hr *ngIf="ncwr.type == 'StepLine'" class="style-1 my-2" />
                                            <!-- <div *ngIf="ncwr.type == 'StepLine'" class="sym-hr my-3"><span>Step Line</span></div> -->

                                            <!-- <div class="card my-2" *ngIf="ncwr.type != 'StepLine'">
                                                <div class="card-header p-1">
                                                    <div class="float-left px-0 mt-1 px-2">
                                                        <label for="ckeditor_folder" class="mb-0">{{ncwr.type}}</label>
                                                    </div>
                                                    <div class="float-right px-0">
                                                        <button type="button"
                                                            class="btn btn-outline-secondary btn-sm border-0 float-right"
                                                            (click)="deleteStepNCWR(ncwr, step)">
                                                            <i class="fa fa-times" aria-hidden="true"></i>
                                                        </button>
                                                        <button prevent-parent-click
                                                            *ngIf="(step.ncwrs.length > 1) && (x != 0)" type="button"
                                                            class="btn btn-outline-secondary btn-sm border-0 float-right mx-1"
                                                            (click)="moveUpStepNCWR(ncwr, step)">
                                                            <i class="fa fa-arrow-up" aria-hidden="true"></i>
                                                        </button>
                                                        <button prevent-parent-click
                                                            *ngIf="(step.ncwrs.length > 1) && (x != (step.ncwrs.length - 1))"
                                                            type="button"
                                                            class="btn btn-outline-secondary btn-sm border-0 float-right mx-1"
                                                            (click)="moveDownStepNCWR(ncwr, step)">
                                                            <i class="fa fa-arrow-down" aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="card-body px-2 py-2">
                                                    <div class="border rounded border-secondary col-sm-12 my-1 p-1">
                                                        <div contenteditable="false"
                                                            class="toggle-edit{{tabId}}{{userId}} cke_editable_inline cke_contents_ltr ck_ncwr_editor{{n}}{{x}}">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> -->

                                            <div class="card mb-2 bg-light" *ngIf="ncwr.type != 'StepLine'">
                                                <div class="d-flex flex-row no-gutters align-items-center flex-xl-wrap">
                                                    <div class="float-left px-1">
                                                        <h5 class="text-center header-ncwr my-1">{{ncwr.type}}</h5>
                                                    </div>
                                                    <div class="flex-fill border-left border-right px-1">
                                                        <div contenteditable="false"
                                                            class="toggle-edit{{tabId}}{{userId}} cke_editable_inline cke_contents_ltr ck_ncwr_editor{{n}}{{x}}" (dblclick)="activeEvent($event.target)">
                                                        </div>
                                                    </div>
                                                    <div class="float-right d-flex">
                                                        <div class="btn-group btn-group-sm">
                                                            <button type="button" class="btn btn-sm float-right mx-2 btn-outline-dark dropdown-toggle"
                                                                 data-toggle="dropdown"
                                                                 [disabled]="!isCheckOut || isCheckOutByOthers"
                                                                 tooltip="{{ 'PROCEDURE_EDITOR.BTN_TT_BG_COLOR' | translate }}" container="body">
                                                                <span [style.background-color]="ncwr.backgroundColor" class="selected-color"></span></button>
                                                            <div class="dropdown-menu dropdown-menu-right"
                                                                [disabled]="!isCheckOut || isCheckOutByOthers">
                                                                <div class="automatic" [ngClass]="{'selected-color-item': (!ncwr.backgroundColor || ncwr.backgroundColor=='')}"
                                                                    (click)="ncwr.backgroundColor = ''">
                                                                    <span style="background-color: white;" class="selected-color"></span>
                                                                    <span class="automatic-text">Automatic</span>
                                                                </div> 
                                                                <div class="color-dropdown">
                                                                    <div *ngFor="let color of predefinedColors" class="color-box"
                                                                        (click)="ncwr.backgroundColor = color" [style.background-color]="color"
                                                                        [ngClass]="{'white-color': color == '#FFF', 'selected-color-item': ncwr.backgroundColor==color}"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <button prevent-parent-click
                                                            [disabled]="!isCheckOut || isCheckOutByOthers || !((step.ncwrs.length > 1) && (x != (step.ncwrs.length - 1)))"
                                                            type="button" class="close close-ncwr float-right mx-2"
                                                            (click)="moveDownStepNCWR(ncwr, step)">
                                                            <i class="fa fa-arrow-down" aria-hidden="true"></i>
                                                        </button>
                                                        <button prevent-parent-click
                                                            [disabled]="!isCheckOut || isCheckOutByOthers || !((step.ncwrs.length > 1) && (x != 0))"
                                                            type="button" class="close close-ncwr float-right mx-2"
                                                            (click)="moveUpStepNCWR(ncwr, step)">
                                                            <i class="fa fa-arrow-up" aria-hidden="true"></i>
                                                        </button>
                                                        <button type="button" class="close close-ncwr float-right mx-2"
                                                            (click)="deleteStepNCWR(ncwr, step)"
                                                            [disabled]="!isCheckOut || isCheckOutByOthers">
                                                            <i class="fa fa-times" aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <!-- images -->
                                    <div class="col-sm-12 my-1" [ngClass]="{'ph-show': step.imagePanelFlag, 'ph-hide': !step.imagePanelFlag}">
                                        <div class="card">
                                            <div class="card-header p-1">
                                                <div class="float-left px-0 mt-1 px-2">
                                                    <label for="ckeditor_folder" class="mb-0">{{"PROCEDURE_EDITOR.IMAGES" |
                                                    translate }}</label>
                                                </div>
                                                <div class="float-right px-0">
                                                    <button type="button" [disabled]="!isCheckOut || isCheckOutByOthers"
                                                        class="btn btn-outline-secondary btn-sm border-0 float-right"
                                                        (click)="addToStepImage(step)">
                                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="card-body mx-auto">
                                                <div class="card-columns form-group"
                                                    *ngFor="let image of step.images; let i=index">
                                                    <!-- iterate image list -->
                                                    <div class="card" style="width: 12rem;">
                                                        <button class="float-right" (click)="removeStepImage(i,step)"
                                                            [disabled]="!isCheckOut || isCheckOutByOthers">
                                                            <i class="fas fa-times fa-sm" aria-hidden="true"></i>
                                                        </button>
                                                        <img class="card-img-top img-fluid pointer-cursor"
                                                            src="data:image/jpeg;base64,{{image.thumbnailImage}}"
                                                            alt="{{image.title}}">
                                                        <div class="card-body p-1"
                                                            *ngIf="image.type == 'imageAsFigure'">
                                                            <div class="border rounded col-sm-12 my-1 p-1">
                                                                <div contenteditable="false"
                                                                    class="toggle-edit{{tabId}}{{userId}} cke_editable_inline cke_contents_ltr step_img_editor{{i}}{{n}}" (dblclick)="activeEvent($event.target)">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!--Documents-->
                                    <div class="col-sm-12 my-1" *ngIf="step.documents.length != 0"
                                        [ngClass]="{'ph-show': isExpandAll, 'ph-hide': !isExpandAll}">
                                        <div *ngFor="let doc of step.documents | orderBy : 'seq'; let t=index"
                                            id="{{t}}">

                                            <!-- <div class="card my-2">
                                                <div class="card-header p-1">
                                                    <div class="float-left px-0 mt-1 px-2">
                                                        <label for="ckeditor_folder" class="mb-0">
                                                            {{"PROCEDURE_EDITOR.DOCUMENT" | translate }}</label>
                                                    </div>
                                                    <div class="float-right px-0">
                                                        <button type="button"
                                                            class="btn btn-outline-secondary btn-sm border-0 float-right"
                                                            (click)="deleteDoc(doc, step)">
                                                            <i class="fa fa-times" aria-hidden="true"></i>
                                                        </button>
                                                        <button prevent-parent-click
                                                            *ngIf="(step.documents.length > 1) && (t != 0)"
                                                            type="button"
                                                            class="btn btn-outline-secondary btn-sm border-0 float-right mx-1"
                                                            (click)="moveUpDoc(doc, step)">
                                                            <i class="fa fa-arrow-up" aria-hidden="true"></i>
                                                        </button>
                                                        <button prevent-parent-click
                                                            *ngIf="(step.documents.length > 1) && (t != (step.documents.length - 1))"
                                                            type="button"
                                                            class="btn btn-outline-secondary btn-sm border-0 float-right mx-1"
                                                            (click)="moveDownDoc(doc, step)">
                                                            <i class="fa fa-arrow-down" aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="card-body px-2 py-2">
                                                    <div class="border rounded border-secondary col-sm-12 my-1 p-1">
                                                        <div contenteditable="false"
                                                            class="toggle-edit{{tabId}}{{userId}} cke_editable_inline cke_contents_ltr ck_doc_editor{{n}}{{t}}">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> -->

                                            <div class="card my-1 bg-light">
                                                <div class="d-flex flex-row no-gutters align-items-center flex-xl-wrap">
                                                    <div class="float-left px-1">
                                                        <h5 class="text-center header-ncwr my-1">
                                                            {{"PROCEDURE_EDITOR.DOCUMENT" | translate }}
                                                        </h5>
                                                    </div>
                                                    <div class="flex-fill border-left border-right px-1">
                                                        <div contenteditable="false"
                                                            class="toggle-edit{{tabId}}{{userId}} cke_editable_inline cke_contents_ltr ck_doc_editor{{n}}{{t}}" (dblclick)="activeEvent($event.target)">
                                                        </div>
                                                    </div>
                                                    <div class="float-right">
                                                        <button type="button" class="close close-ncwr float-right mx-2"
                                                            (click)="deleteDoc(doc, step)"
                                                            [disabled]="!isCheckOut || isCheckOutByOthers">
                                                            <i class="fa fa-times" aria-hidden="true"></i>
                                                        </button>
                                                        <button prevent-parent-click
                                                            [disabled]="!isCheckOut || isCheckOutByOthers || !((step.documents.length > 1) && (t != 0))"
                                                            type="button" class="close close-ncwr float-right mx-2"
                                                            (click)="moveUpDoc(doc, step)">
                                                            <i class="fa fa-arrow-up" aria-hidden="true"></i>
                                                        </button>
                                                        <button prevent-parent-click
                                                            [disabled]="!isCheckOut || isCheckOutByOthers || !((step.documents.length > 1) && (t != (step.documents.length - 1)))"
                                                            type="button" class="close close-ncwr float-right mx-2"
                                                            (click)="moveDownDoc(doc, step)">
                                                            <i class="fa fa-arrow-down" aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <!--DependencySteps-->
                                    <div class="col-sm-12 my-1" *ngIf="step.dependencyFlag"
                                        [ngClass]="{'ph-show': isExpandAll, 'ph-hide': !isExpandAll}">
                                        <div class="card my-2">
                                            <div class="card-header p-1">
                                                <div class="float-right px-0">
                                                    <button type="button"
                                                        class="btn btn-outline-secondary btn-sm border-0 float-right"
                                                        (click)="deleteDepSteps(step)"
                                                        [disabled]="!isCheckOut || isCheckOutByOthers">
                                                        <i class="fa fa-times" aria-hidden="true"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="card-body px-2 py-2">
                                                <tag-input [(ngModel)]="step.dependencySteps"
                                                    [errorMessages]="errorMessages" [validators]="validators"
                                                    [removable]='true'  maxItems="1"
                                                    (onAdd)="onDepStepAdded($event)"
                                                    (onRemove)="onDepStepRemoved($event)"
                                                    (onFocus)="onDepStepFocused(n, step)">
                                                </tag-input>
                                            </div>
                                        </div>
                                    </div>

                                    <!--Location-->
                                    <div class="col-sm-12 my-1" *ngIf="step.locationDesc != null"
                                        [ngClass]="{'ph-show': isExpandAll, 'ph-hide': !isExpandAll}">

                                        <!-- <div class="card my-2">
                                            <div class="card-header p-1">
                                                <div class="float-left px-0 mt-1 px-2">
                                                    <label for="ckeditor_folder" class="mb-0">
                                                        {{ "PROCEDURE_EDITOR.LOCATION" | translate }}
                                                    </label>
                                                </div>
                                                <div class="float-right px-0">
                                                    <button type="button"
                                                        class="btn btn-outline-secondary btn-sm border-0 float-right"
                                                        (click)="deleteLoc(step)">
                                                        <i class="fa fa-times" aria-hidden="true"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="card-body px-2 py-2">
                                                <div class="border rounded border-secondary col-sm-12 my-1 p-1">
                                                    <div contenteditable="false"
                                                        class="toggle-edit{{tabId}}{{userId}} cke_editable_inline cke_contents_ltr ck_loc_editor{{n}}">
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->

                                        <div class="card my-1 bg-light">
                                            <div class="d-flex flex-row no-gutters align-items-center flex-xl-wrap">
                                                <div class="float-left px-1">
                                                    <h5 class="text-center header-ncwr my-1">
                                                        {{ "PROCEDURE_EDITOR.LOCATION" | translate }}
                                                    </h5>
                                                </div>
                                                <div class="flex-fill border-left border-right px-1">
                                                    <div contenteditable="false"
                                                        class="toggle-edit{{tabId}}{{userId}} cke_editable_inline cke_contents_ltr ck_loc_editor{{n}}" (dblclick)="activeEvent($event.target)">
                                                    </div>
                                                </div>
                                                <div class="float-right">
                                                    <button type="button" class="close close-ncwr float-right mx-2"
                                                        (click)="deleteLoc(step)"
                                                        [disabled]="!isCheckOut || isCheckOutByOthers">
                                                        <i class="fa fa-times" aria-hidden="true"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <!--Responsible Party-->
                                    <div class="col-sm-12 my-1" *ngIf="step.responsiblePartyDesc != undefined && step.responsiblePartyDesc.length != 0"
                                        [ngClass]="{'ph-show': isExpandAll, 'ph-hide': !isExpandAll}">
                                        <div *ngFor="let resp of step.responsiblePartyDesc | orderBy : 'seq'; let m=index"
                                            id="{{m}}">
                                            <div class="card my-1 bg-light">
                                                <div class="d-flex flex-row no-gutters align-items-center flex-xl-wrap">
                                                    <div class="float-left px-1">
                                                        <h5 class="text-center header-ncwr my-1">
                                                            {{"PROCEDURE_EDITOR.RESPONSIBLE_PARTY" | translate }}
                                                        </h5>
                                                    </div>
                                                    <div class="flex-fill border-left border-right px-1">
                                                        <div contenteditable="false"
                                                            class="toggle-edit{{tabId}}{{userId}} cke_editable_inline cke_contents_ltr ck_resp_editor{{n}}{{m}}" (dblclick)="activeEvent($event.target)">
                                                        </div>
                                                    </div>
                                                    <div class="float-right">
                                                        <button type="button" class="close close-ncwr float-right mx-2"
                                                            (click)="deleteResponisibleParty(resp, step)"
                                                            [disabled]="!isCheckOut || isCheckOutByOthers">
                                                            <i class="fa fa-times" aria-hidden="true"></i>
                                                        </button>
                                                        <button prevent-parent-click
                                                            [disabled]="!isCheckOut || isCheckOutByOthers || !((step.responsiblePartyDesc.length > 1) && (m != 0))"
                                                            type="button" class="close close-ncwr float-right mx-2"
                                                            (click)="moveUpResp(resp, step)">
                                                            <i class="fa fa-arrow-up" aria-hidden="true"></i>
                                                        </button>
                                                        <button prevent-parent-click
                                                            [disabled]="!isCheckOut || isCheckOutByOthers || !((step.responsiblePartyDesc.length > 1) && (m != (step.responsiblePartyDesc.length - 1)))"
                                                            type="button" class="close close-ncwr float-right mx-2"
                                                            (click)="moveDownResp(resp, step)">
                                                            <i class="fa fa-arrow-down" aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!--Operating Data-->
                                    <div class="col-sm-12 my-1" *ngIf="step.operatingDataFlag"
                                        [ngClass]="{'ph-show': isExpandAll, 'ph-hide': !isExpandAll}">
                                        <div class="card my-2">
                                            <div class="card-header p-1">
                                                <div class="float-left px-1">
                                                    <h5 class="text-center header-ncwr my-1">
                                                        {{"PROCEDURE_EDITOR.OPERATING_DATA" | translate }}
                                                    </h5>
                                                </div>
                                                <div class="float-right px-0">
                                                    <button type="button"
                                                        class="btn btn-outline-secondary btn-sm border-0 float-right"
                                                        (click)="deleteOperatingData(step)"
                                                        [disabled]="!isCheckOut || isCheckOutByOthers">
                                                        <i class="fa fa-times" aria-hidden="true"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="card-body px-2 py-2">
                                                <tag-input [(ngModel)]="step.operatingData"
                                                    [disable]="!isCheckOut || isCheckOutByOthers"
                                                    [removable]='true'  maxItems="100"
                                                    [displayBy]="'operatingDataName'" [identifyBy]="'id'"
                                                    (onAdd)="onOperatingDataAdded($event)"
                                                    (onRemove)="onOperatingDataRemoved($event)"
                                                    [onlyFromAutocomplete]="true"
                                                    placeholder="{{'PROCEDURE_EDITOR.ADD_OPERATING_DATA' | translate}}" 
                                                    secondaryPlaceholder="{{'PROCEDURE_EDITOR.ADD_OPERATING_DATA' | translate}}"
                                                    [clearOnBlur]="true">
                                                    <tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="operatingDataList"
                                                            [displayBy]="'operatingDataName'" [identifyBy]="'id'" 
                                                            [limitItemsTo]=10 [appendToBody]="false"></tag-input-dropdown>
                                                </tag-input>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- sub steps -->
                                    <div class="col-sm-12" *ngIf="step.substeps.length != 0">
                                        <div class="card my-2"
                                            *ngFor="let substep of step.substeps | orderBy : 'stepNo'; let k=index"
                                            id="{{k}}">
                                            <div class="card-header px-2 py-1">
                                                <div class="float-left">
                                                    <div class="px-0" *ngIf="isShowBadgeItem != n">
                                                        <span class="badge badge-warning mx-1 mt-2 h6">
                                                            {{n + 1}}.{{k + 1}}</span>
                                                    </div>
                                                    <div class="col-sm-2 px-1" *ngIf="false">
                                                        <input type="number" id="stepNum"
                                                            class="form-control form-control-sm"
                                                            aria-describedby="passwordHelpBlock"
                                                            (keydown)="onKeydownStepNum($event)"
                                                            [(ngModel)]="selectedItemStepNum">
                                                        <small id="passwordHelpBlock" class="form-text text-muted">
                                                            ENTER to save & ESC to cancel
                                                        </small>
                                                    </div>
                                                </div>
                                                <div class="float-right">
                                                    <button type="button" [disabled]="!isCheckOut || isCheckOutByOthers"
                                                        class="btn btn-outline-secondary btn-sm border-0 float-right"
                                                        (click)="deleteSubStep(step,substep)">
                                                        <i class="fa fa-times" aria-hidden="true"></i>
                                                    </button>
                                                    <button prevent-parent-click
                                                        [disabled]="!isCheckOut || isCheckOutByOthers || !((step.substeps.length > 1) && (k != 0))" type="button"
                                                        class="btn btn-outline-secondary btn-sm border-0 float-right mx-1"
                                                        (click)="moveUpSubStep(step,substep)">
                                                        <i class="fa fa-arrow-up" aria-hidden="true"></i>
                                                    </button>
                                                    <button prevent-parent-click
                                                        type="button" [disabled]="!isCheckOut || isCheckOutByOthers || !((step.substeps.length > 1) && (k != (step.substeps.length - 1)))"
                                                        class="btn btn-outline-secondary btn-sm border-0 float-right mx-1"
                                                        (click)="moveDownSubStep(step,substep)">
                                                        <i class="fa fa-arrow-down" aria-hidden="true"></i>
                                                    </button>
                                                    <div class="btn-group btn-group-sm float-right mx-1">
                                                        <button type="button" class="btn btn-outline-dark"
                                                            [disabled]="!isCheckOut || isCheckOutByOthers"
                                                            tooltip="{{ 'PROCEDURE_EDITOR.BTN_TT_GROUP_TITLE' | translate }}"
                                                            (click)="addSubStepItem('Group Title', step, substep)"
                                                            container="body">
                                                            <i class="far fa-heading"></i>
                                                        </button>
                                                        <button type="button" class="btn btn-outline-dark"
                                                            [disabled]="!isCheckOut || isCheckOutByOthers"
                                                            tooltip="{{ 'PROCEDURE_EDITOR.BTN_TT_LOCATION' | translate }}"
                                                            (click)="addSubStepItem('Location', step, substep)"
                                                            container="body">
                                                            <i class="far fa-map-marker-alt"></i>
                                                        </button>
                                                        <button type="button" class="btn btn-outline-dark"
                                                            [disabled]="!isCheckOut || isCheckOutByOthers"
                                                            tooltip="{{ 'PROCEDURE_EDITOR.BTN_TT_DOCUMENTS' | translate }}"
                                                            (click)="addSubStepItem('Document', step, substep)"
                                                            container="body">
                                                            <i class="far fa-file-alt"></i>
                                                        </button>
                                                        <button type="button" class="btn btn-outline-dark"
                                                            [disabled]="!isCheckOut || isCheckOutByOthers"
                                                            tooltip="{{ 'PROCEDURE_EDITOR.BTN_TT_DUPLICATE' | translate }}"
                                                            (click)="addSubStepItem('Duplicate', step, substep)"
                                                            container="body">
                                                            <i class="far fa-clone"></i>
                                                        </button>
                                                        <div class="btn-group btn-group-sm">
                                                            <button type="button"
                                                                class="btn btn-outline-dark dropdown-toggle"
                                                                data-toggle="dropdown"
                                                                [disabled]="!isCheckOut || isCheckOutByOthers"
                                                                container="body"
                                                                tooltip="{{ 'PROCEDURE_EDITOR.BTN_TT_NEW_NCWR' | translate }}">
                                                                <i class="fas fa-cubes"></i>
                                                            </button>
                                                            <div class="dropdown-menu dropdown-menu-right"
                                                                [disabled]="!isCheckOut || isCheckOutByOthers">
                                                                <div *ngFor="let option of subStepOptionList; let n=index"
                                                                    (click)="addSubStepItem(option.text, step, substep)">
                                                                    <a class="dropdown-item">{{option.text}}</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <button type="button" class="btn btn-outline-dark"
                                                            [disabled]="!isCheckOut || isCheckOutByOthers"
                                                            tooltip="{{ 'PROCEDURE_EDITOR.BTN_TT_OPERATING_DATA' | translate }}"
                                                            (click)="addSubStepItem('Operating Data', step, substep)" container="body">
                                                            <i class="fas fa-file-spreadsheet"></i>
                                                        </button>
                                                    </div>
                                                    <!-- <div class="btn-group float-right mx-2" dropdown>
                                                        <button id="procedure_dropdown_ncwr" dropdownToggle
                                                            type="button" aria-controls="dropdown-ncwr"
                                                            class="btn btn-dark btn-sm dropdown-toggle px-1">
                                                            <i class="fa fa-exclamation-triangle"
                                                                aria-hidden="true"></i>
                                                            <span class="caret mx-2"></span>
                                                        </button>
                                                        <ul id="dropdown-ncwr" *dropdownMenu
                                                            class="dropdown-menu dropdown-menu-right" role="menu"
                                                            aria-labelledby="dropdown-ncwr">
                                                            <li *ngFor="let option of subStepOptionList; let n=index"
                                                                (click)="addSubStepItem(option.text,step,substep)">
                                                                <a class="dropdown-item">{{option.text}}</a>
                                                            </li>
                                                        </ul>
                                                    </div> -->
                                                    
                                                    <!-- Removed Action menu from substeps
                                                    <div class="btn-group btn-group-sm">
                                                        <button type="button" class="btn btn-sm mx-1 btn-outline-dark dropdown-toggle"
                                                            data-toggle="dropdown"
                                                            [disabled]="!isCheckOut || isCheckOutByOthers"
                                                            tooltip="{{ 'PROCEDURE_EDITOR.BTN_TT_ACTION' | translate }}"
                                                            [ngClass]="{'btn-outline-dark': !substep.action, 'btn-dark': substep.action}">
                                                            <i class="fa fa-clipboard-list-check" aria-hidden="true"></i>
                                                        </button>         
                                                        <div class="dropdown-menu dropdown-menu-right"
                                                            [disabled]="!isCheckOut || isCheckOutByOthers">
                                                            <div *ngFor="let option of actionMenuOptionList; let n=index"
                                                                (click)="addAction(option.text, substep)">
                                                                <a class="dropdown-item"
                                                                [class.active]="substep.action === option.text || (!substep.action && option.text === 'Nothing')">{{option.text}}</a>
                                                            </div>
                                                        </div>
                                                    </div> -->
                                                    <button type="button" class="btn btn-sm mx-1"
                                                        [disabled]="!isCheckOut || isCheckOutByOthers"
                                                        tooltip="{{ 'PROCEDURE_EDITOR.BTN_TT_IMAGE' | translate }}"
                                                        (click)="substep.imagePanelFlag = !substep.imagePanelFlag"
                                                        [ngClass]="{'btn-outline-dark': !substep.imagePanelFlag, 'btn-dark': substep.imagePanelFlag}">
                                                        <i class="fa fa-camera-retro" aria-hidden="true"></i>
                                                    </button>
                                                </div>
                                            </div>

                                            <div class="card-body px-2 py-2">
                                                <!--Group Title -->
                                                <div class="row">
                                                    <div class="col-sm-12 my-1" *ngIf="substep.groupTitle != null">
                                                        <div class="card">
                                                            <div class="card-header py-1 px-2">
                                                                <div class="float-right px-0">
                                                                    <button type="button"
                                                                        class="btn btn-outline-secondary btn-sm border-0 float-right"
                                                                        (click)="deleteSubStepGroupTitle(step, substep)">
                                                                        <i class="fa fa-times" aria-hidden="true"></i>
                                                                    </button>
                                                                    <div class="dropdown mx-2 float-right">
                                                                        <button
                                                                            [disabled]="!isCheckOut || isCheckOutByOthers"
                                                                            class="btn btn-outline-dark btn-sm dropdown-toggle"
                                                                            type="button" id="procedure_dropdown_ncwr"
                                                                            data-toggle="dropdown" aria-haspopup="true"
                                                                            aria-expanded="false" container="body"
                                                                            tooltip="{{ 'PROCEDURE_EDITOR.BTN_TT_NEW_NCWR' | translate }}">
                                                                            <i class="fas fa-cubes"></i>
                                                                        </button>
                                                                        <div class="dropdown-menu dropdown-menu-right"
                                                                            aria-labelledby="procedure_dropdown_ncwr">
                                                                            <li *ngFor="let option of titleOptionList; let n=index"
                                                                                id="{{n}}"
                                                                                (click)="addSubStepGtNCWR(option.text, step, substep)">
                                                                                <a
                                                                                    class="dropdown-item">{{option.text}}</a>
                                                                            </li>
                                                                        </div>
                                                                    </div>

                                                                    <!-- <div class="btn-group float-right mx-2" dropdown
                                                                        [isDisabled]="!isCheckOut">
                                                                        <button id="procedure_dropdown_ncwr"
                                                                            dropdownToggle type="button"
                                                                            aria-controls="dropdown-ncwr"
                                                                            class="btn btn-dark btn-sm dropdown-toggle px-1">
                                                                            <i class="fa fa-exclamation-triangle"
                                                                                aria-hidden="true"></i>
                                                                            <span class="caret mx-2"></span>
                                                                        </button>
                                                                        <ul id="dropdown-ncwr" *dropdownMenu
                                                                            class="dropdown-menu dropdown-menu-right"
                                                                            role="menu" aria-labelledby="dropdown-ncwr">
                                                                            <li *ngFor="let option of titleOptionList; let n=index"
                                                                                (click)="addSubStepGtNCWR(option.text, step, substep)">
                                                                                <a
                                                                                    class="dropdown-item">{{option.text}}</a>
                                                                            </li>
                                                                        </ul>
                                                                    </div> -->
                                                                </div>
                                                            </div>
                                                            <div class="card-body px-2 py-2">
                                                                <div
                                                                    class="border rounded border-secondary col-sm-12 my-1 p-1">
                                                                    <div contenteditable="false"
                                                                        class="toggle-edit{{tabId}}{{userId}} cke_editable_inline cke_contents_ltr gtck_substep_editor{{n}}{{k}}" (dblclick)="activeEvent($event.target)">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!-- <div class="card my-2"
                                                            [ngClass]="{'ncwr-si-show': isExpandAll, 'ph-hide': !isExpandAll}"
                                                            *ngFor="let ncwr of substep.groupTitle.ncwrs | orderBy : 'seq'; let m=index"
                                                            id="{{m}}">
                                                            <div class="card-header p-1">
                                                                <div class="float-left px-0 mt-1 px-2">
                                                                    <label for="ckeditor_folder"
                                                                        class="mb-0">{{ncwr.type}}</label>
                                                                </div>
                                                                <div class="float-right px-0">
                                                                    <button type="button"
                                                                        class="btn btn-outline-secondary btn-sm border-0 float-right"
                                                                        (click)="deleteSubStepGtNCWR(ncwr,step,substep)">
                                                                        <i class="fa fa-times" aria-hidden="true"></i>
                                                                    </button>
                                                                    <button prevent-parent-click
                                                                        *ngIf="(substep.groupTitle.ncwrs.length > 1) && (m != 0)"
                                                                        type="button"
                                                                        class="btn btn-outline-secondary btn-sm border-0 float-right mx-1"
                                                                        (click)="moveUpNCWR(ncwr,step,substep)">
                                                                        <i class="fa fa-arrow-up"
                                                                            aria-hidden="true"></i>
                                                                    </button>
                                                                    <button prevent-parent-click
                                                                        *ngIf="(substep.groupTitle.ncwrs.length > 1) && (m != (substep.groupTitle.ncwrs.length - 1))"
                                                                        type="button"
                                                                        class="btn btn-outline-secondary btn-sm border-0 float-right mx-1"
                                                                        (click)="moveDownNCWR(ncwr,step,substep)">
                                                                        <i class="fa fa-arrow-down"
                                                                            aria-hidden="true"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div class="card-body px-2 py-2">
                                                                <div
                                                                    class="border rounded border-secondary col-sm-12 my-1 mx-2 p-1">
                                                                    <div contenteditable="false"
                                                                        class="toggle-edit{{tabId}}{{userId}} cke_editable_inline cke_contents_ltr gtck_substep_ncwr_editor{{n}}{{k}}{{m}}">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> -->

                                                        <div class="card my-2 bg-light"
                                                            [ngClass]="{'ncwr-si-show': isExpandAll, 'ph-hide': !isExpandAll}"
                                                            *ngFor="let ncwr of substep.groupTitle.ncwrs | orderBy : 'seq'; let m=index"
                                                            id="{{m}}">
                                                            <div
                                                                class="d-flex flex-row no-gutters align-items-center flex-xl-wrap">
                                                                <div class="float-left px-1">
                                                                    <h5 class="text-center header-ncwr my-1">
                                                                        {{ncwr.type}}
                                                                    </h5>
                                                                </div>
                                                                <div class="flex-fill border-left border-right px-1">
                                                                    <div contenteditable="false"
                                                                        class="toggle-edit{{tabId}}{{userId}} cke_editable_inline cke_contents_ltr gtck_substep_ncwr_editor{{n}}{{k}}{{m}}" (dblclick)="activeEvent($event.target)">
                                                                    </div>
                                                                </div>
                                                                <div class="float-right d-flex">
                                                                    <div class="btn-group btn-group-sm">
                                                                        <button type="button" class="btn btn-sm float-right mx-2 btn-outline-dark dropdown-toggle"
                                                                             data-toggle="dropdown"
                                                                             [disabled]="!isCheckOut || isCheckOutByOthers"
                                                                             tooltip="{{ 'PROCEDURE_EDITOR.BTN_TT_BG_COLOR' | translate }}" container="body">
                                                                            <span [style.background-color]="ncwr.backgroundColor" class="selected-color"></span></button>
                                                                        <div class="dropdown-menu dropdown-menu-right"
                                                                            [disabled]="!isCheckOut || isCheckOutByOthers">
                                                                            <div class="automatic" [ngClass]="{'selected-color-item': (!ncwr.backgroundColor || ncwr.backgroundColor=='')}"
                                                                                (click)="ncwr.backgroundColor = ''">
                                                                                <span style="background-color: white;" class="selected-color"></span>
                                                                                <span class="automatic-text">Automatic</span>
                                                                            </div> 
                                                                            <div class="color-dropdown">
                                                                                <div *ngFor="let color of predefinedColors" class="color-box"
                                                                                    (click)="ncwr.backgroundColor = color" [style.background-color]="color"
                                                                                    [ngClass]="{'white-color': color == '#FFF', 'selected-color-item': ncwr.backgroundColor==color}"></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <button prevent-parent-click type="button"
                                                                        [disabled]="!isCheckOut || isCheckOutByOthers || !((substep.groupTitle.ncwrs.length > 1) && (m != (substep.groupTitle.ncwrs.length - 1)))"
                                                                        class="close close-ncwr float-right mx-2"
                                                                        (click)="moveDownNCWR(ncwr, step, substep)">
                                                                        <i class="fa fa-arrow-down"
                                                                            aria-hidden="true"></i>
                                                                    </button>
                                                                    <button prevent-parent-click type="button"
                                                                        [disabled]="!isCheckOut || isCheckOutByOthers || !((substep.groupTitle.ncwrs.length > 1) && (m != 0))"
                                                                        (click)="moveUpNCWR(ncwr, step, substep)"
                                                                        class="close close-ncwr float-right mx-2">
                                                                        <i class="fa fa-arrow-up"
                                                                            aria-hidden="true"></i>
                                                                    </button>
                                                                    <button type="button"
                                                                        [disabled]="!isCheckOut || isCheckOutByOthers"
                                                                        class="close close-ncwr float-right mx-2"
                                                                        (click)="deleteSubStepGtNCWR(ncwr, step, substep)">
                                                                        <i class="fa fa-times" aria-hidden="true"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <!-- ck editor -->
                                                    <div class="col-sm-12 my-1">
                                                        <div class="border rounded border-secondary col-sm-12 my-1 p-1">
                                                            <div contenteditable="false"
                                                                class="toggle-edit{{tabId}}{{userId}} cke_editable_inline cke_contents_ltr substep_ck_editor{{n}}{{k}}" (dblclick)="activeEvent($event.target)">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- Removed Action menu from substeps
                                                    <div class="col-sm-12 my-1" *ngIf="substep.action"
                                                        [ngClass]="{'ph-show': isExpandAll, 'ph-hide': !isExpandAll}">
                                                        <div class="card my-1 bg-light">
                                                            <div class="d-flex flex-row no-gutters align-items-center flex-xl-wrap">
                                                                <div class="float-left px-1">
                                                                    <h5 class="text-center header-ncwr my-1">
                                                                        Action
                                                                    </h5>
                                                                </div>
                                                                <div class="flex-fill border-left border-right px-1">
                                                                    <div contenteditable="false"
                                                                        class="cke_contents_ltr ck_loc_editor{{n}}"> {{ substep.action }}
                                                                    </div>
                                                                </div>
                                                                <div class="float-right">
                                                                    <button type="button" class="close close-ncwr float-right mx-2"
                                                                        (click)="substep.action = ''"
                                                                        [disabled]="!isCheckOut || isCheckOutByOthers">
                                                                        <i class="fa fa-times" aria-hidden="true"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> -->                                                    

                                                    <!--NCWRS-->
                                                    <div class="col-sm-12 my-1" *ngIf="substep.ncwrs.length != 0"
                                                        [ngClass]="{'ph-show': isExpandAll, 'ph-hide': !isExpandAll}">
                                                        <div *ngFor="let ncwr of substep.ncwrs | orderBy : 'seq'; let y=index"
                                                            id="{{y}}">
                                                            <!-- <hr *ngIf="ncwr.type == 'StepLine'" style="height:2px;background-color:black;"> -->
                                                            <hr *ngIf="ncwr.type == 'StepLine'" class="style-1 my-2" />

                                                            <!-- <div class="card my-2" *ngIf="ncwr.type != 'StepLine'">
                                                                <div class="card-header p-1">
                                                                    <div class="float-left px-0 mt-1 px-2">
                                                                        <label for="ckeditor_folder"
                                                                            class="mb-0">{{ncwr.type}}</label>
                                                                    </div>
                                                                    <div class="float-right px-0">
                                                                        <button type="button"
                                                                            class="btn btn-outline-secondary btn-sm border-0 float-right"
                                                                            (click)="deleteSubStepNCWR(ncwr, step, substep)">
                                                                            <i class="fa fa-times"
                                                                                aria-hidden="true"></i>
                                                                        </button>
                                                                        <button prevent-parent-click
                                                                            *ngIf="(substep.ncwrs.length > 1) && (y != 0)"
                                                                            type="button"
                                                                            class="btn btn-outline-secondary btn-sm border-0 float-right mx-1"
                                                                            (click)="moveUpStepNCWR(ncwr, step, substep)">
                                                                            <i class="fa fa-arrow-up"
                                                                                aria-hidden="true"></i>
                                                                        </button>
                                                                        <button prevent-parent-click
                                                                            *ngIf="(substep.ncwrs.length > 1) && (y != (substep.ncwrs.length - 1))"
                                                                            type="button"
                                                                            class="btn btn-outline-secondary btn-sm border-0 float-right mx-1"
                                                                            (click)="moveDownStepNCWR(ncwr, step, substep)">
                                                                            <i class="fa fa-arrow-down"
                                                                                aria-hidden="true"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div class="card-body px-2 py-2">
                                                                    <div
                                                                        class="border rounded border-secondary col-sm-12 my-1 p-1">
                                                                        <div contenteditable="false"
                                                                            class="toggle-edit{{tabId}}{{userId}} cke_editable_inline cke_contents_ltr substep_ck_ncwr_editor{{n}}{{k}}{{y}}">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> -->

                                                            <div class="card my-2 bg-light"
                                                                *ngIf="ncwr.type != 'StepLine'">
                                                                <div
                                                                    class="d-flex flex-row no-gutters align-items-center flex-xl-wrap">
                                                                    <div class="float-left px-1">
                                                                        <h5 class="text-center header-ncwr my-1">
                                                                            {{ncwr.type}}
                                                                        </h5>
                                                                    </div>
                                                                    <div
                                                                        class="flex-fill border-left border-right px-1">
                                                                        <div contenteditable="false"
                                                                            class="toggle-edit{{tabId}}{{userId}} cke_editable_inline cke_contents_ltr substep_ck_ncwr_editor{{n}}{{k}}{{y}}" (dblclick)="activeEvent($event.target)">
                                                                        </div>
                                                                    </div>
                                                                    <div class="float-right d-flex">
                                                                        <div class="btn-group btn-group-sm">
                                                                            <button type="button" class="btn btn-sm float-right mx-2 btn-outline-dark dropdown-toggle"
                                                                                 data-toggle="dropdown"
                                                                                 [disabled]="!isCheckOut || isCheckOutByOthers"
                                                                                 tooltip="{{ 'PROCEDURE_EDITOR.BTN_TT_BG_COLOR' | translate }}" container="body">
                                                                                <span [style.background-color]="ncwr.backgroundColor" class="selected-color"></span></button>
                                                                            <div class="dropdown-menu dropdown-menu-right"
                                                                                [disabled]="!isCheckOut || isCheckOutByOthers">
                                                                                <div class="automatic" [ngClass]="{'selected-color-item': (!ncwr.backgroundColor || ncwr.backgroundColor=='')}"
                                                                                    (click)="ncwr.backgroundColor = ''">
                                                                                    <span style="background-color: white;" class="selected-color"></span>
                                                                                    <span class="automatic-text">Automatic</span>
                                                                                </div> 
                                                                                <div class="color-dropdown">
                                                                                    <div *ngFor="let color of predefinedColors" class="color-box"
                                                                                        (click)="ncwr.backgroundColor = color" [style.background-color]="color"
                                                                                        [ngClass]="{'white-color': color == '#FFF', 'selected-color-item': ncwr.backgroundColor==color}"></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <button prevent-parent-click type="button"
                                                                            [disabled]="!isCheckOut || isCheckOutByOthers || !((substep.ncwrs.length > 1) && (y != (substep.ncwrs.length - 1)))"
                                                                            class="close close-ncwr float-right mx-2"
                                                                            (click)="moveDownStepNCWR(ncwr, step, substep)">
                                                                            <i class="fa fa-arrow-down"
                                                                                aria-hidden="true"></i>
                                                                        </button>
                                                                        <button prevent-parent-click type="button"
                                                                            [disabled]="!isCheckOut || isCheckOutByOthers || !((substep.ncwrs.length > 1) && (y != 0))"
                                                                            (click)="moveUpStepNCWR(ncwr, step, substep)"
                                                                            class="close close-ncwr float-right mx-2">
                                                                            <i class="fa fa-arrow-up"
                                                                                aria-hidden="true"></i>
                                                                        </button>
                                                                        <button type="button"
                                                                            [disabled]="!isCheckOut || isCheckOutByOthers"
                                                                            class="close close-ncwr float-right mx-2"
                                                                            (click)="deleteSubStepNCWR(ncwr, step, substep)">
                                                                            <i class="fa fa-times"
                                                                                aria-hidden="true"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <!-- images -->
                                                    <div class="col-sm-12 my-1" [ngClass]="{'ph-show': substep.imagePanelFlag, 'ph-hide': !substep.imagePanelFlag}">
                                                        <div class="card">
                                                            <div class="card-header p-1">
                                                                <div class="float-left px-0 mt-1 px-2">
                                                                    <label for="ckeditor_folder" class="mb-0">{{"PROCEDURE_EDITOR.IMAGES"|
                                                                    translate }}</label>
                                                                </div>
                                                                <div class="float-right px-0">
                                                                    <button type="button"
                                                                        [disabled]="!isCheckOut || isCheckOutByOthers"
                                                                        class="btn btn-outline-secondary btn-sm border-0 float-right"
                                                                        (click)="addToSubstepImage(substep, step)">
                                                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div class="card-body mx-auto">
                                                                <div class="card-columns form-group"
                                                                    *ngFor="let image of substep.images; let i=index">
                                                                    <!-- iterate image list -->
                                                                    <div class="card" style="width: 12rem;">
                                                                        <button class="float-right"
                                                                            [disabled]="!isCheckOut || isCheckOutByOthers"
                                                                            (click)="removeSubstepImage(i, step, substep)">
                                                                            <i class="fas fa-times fa-sm"
                                                                                aria-hidden="true"></i>
                                                                        </button>
                                                                        <img class="card-img-top img-fluid pointer-cursor"
                                                                            src="data:image/jpeg;base64,{{image.thumbnailImage}}"
                                                                            alt="{{image.title}}">
                                                                        <div class="card-body p-1"
                                                                            *ngIf="image.type == 'imageAsFigure'">
                                                                            <div
                                                                                class="border rounded col-sm-12 my-1 p-1">
                                                                                <div contenteditable="false"
                                                                                    class="toggle-edit{{tabId}}{{userId}} cke_editable_inline cke_contents_ltr substep_img_editor{{i}}{{n}}{{k}}" (dblclick)="activeEvent($event.target)">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <!--Documents-->
                                                    <div class="col-sm-12 my-1" *ngIf="substep.documents.length != 0">
                                                        <div *ngFor="let doc of substep.documents | orderBy : 'seq'; let t=index"
                                                            id="{{t}}">

                                                            <!-- <div class="card my-2">
                                                                <div class="card-header p-1">
                                                                    <div class="float-left px-0 mt-1 px-2">
                                                                        <label for="ckeditor_folder" class="mb-0">{{"PROCEDURE_EDITOR.DOCUMENT"
                                                                        | translate }}</label>
                                                                    </div>
                                                                    <div class="float-right px-0">
                                                                        <button type="button"
                                                                            class="btn btn-outline-secondary btn-sm border-0 float-right"
                                                                            (click)="deleteSubStepDoc(doc, step, substep)">
                                                                            <i class="fa fa-times"
                                                                                aria-hidden="true"></i>
                                                                        </button>
                                                                        <button prevent-parent-click
                                                                            *ngIf="(substep.documents.length > 1) && (t != 0)"
                                                                            type="button"
                                                                            class="btn btn-outline-secondary btn-sm border-0 float-right mx-1"
                                                                            (click)="moveUpDoc(doc, step, substep)">
                                                                            <i class="fa fa-arrow-up"
                                                                                aria-hidden="true"></i>
                                                                        </button>
                                                                        <button prevent-parent-click
                                                                            *ngIf="(substep.documents.length > 1) && (t != (substep.documents.length - 1))"
                                                                            type="button"
                                                                            class="btn btn-outline-secondary btn-sm border-0 float-right mx-1"
                                                                            (click)="moveDownDoc(doc, step, substep)">
                                                                            <i class="fa fa-arrow-down"
                                                                                aria-hidden="true"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div class="card-body px-2 py-2">
                                                                    <div
                                                                        class="border rounded border-secondary col-sm-12 my-1 p-1">
                                                                        <div contenteditable="false"
                                                                            class="toggle-edit{{tabId}}{{userId}} cke_editable_inline cke_contents_ltr substep_ck_doc_editor{{n}}{{k}}{{t}}">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> -->

                                                            <div class="card my-1 bg-light">
                                                                <div
                                                                    class="d-flex flex-row no-gutters align-items-center flex-xl-wrap">
                                                                    <div class="float-left px-1">
                                                                        <h5 class="text-center header-ncwr my-1">
                                                                            {{"PROCEDURE_EDITOR.DOCUMENT" | translate }}
                                                                        </h5>
                                                                    </div>
                                                                    <div
                                                                        class="flex-fill border-left border-right px-1">
                                                                        <div contenteditable="false"
                                                                            class="toggle-edit{{tabId}}{{userId}} cke_editable_inline cke_contents_ltr substep_ck_doc_editor{{n}}{{k}}{{t}}" (dblclick)="activeEvent($event.target)">
                                                                        </div>
                                                                    </div>
                                                                    <div class="float-right">
                                                                        <button type="button"
                                                                            [disabled]="!isCheckOut || isCheckOutByOthers"
                                                                            class="close close-ncwr float-right mx-2"
                                                                            (click)="deleteSubStepDoc(doc, step, substep)">
                                                                            <i class="fa fa-times"
                                                                                aria-hidden="true"></i>
                                                                        </button>
                                                                        <button prevent-parent-click type="button"
                                                                            [disabled]="!isCheckOut || isCheckOutByOthers || !((substep.documents.length > 1) && (t != 0))"
                                                                            class="close close-ncwr float-right mx-2"
                                                                            (click)="moveUpDoc(doc, step, substep)">
                                                                            <i class="fa fa-arrow-up"
                                                                                aria-hidden="true"></i>
                                                                        </button>
                                                                        <button prevent-parent-click type="button"
                                                                            [disabled]="!isCheckOut || isCheckOutByOthers || !((substep.documents.length > 1) && (t != (substep.documents.length - 1)))"
                                                                            class="close close-ncwr float-right mx-2"
                                                                            (click)="moveDownDoc(doc, step, substep)">
                                                                            <i class="fa fa-arrow-down"
                                                                                aria-hidden="true"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <!--Location-->
                                                    <div class="col-sm-12 my-1" *ngIf="substep.locationDesc != null">

                                                        <!-- <div class="card my-2">
                                                            <div class="card-header p-1">
                                                                <div class="float-left px-0 mt-1 px-2">
                                                                    <label for="ckeditor_folder" class="mb-0">{{"PROCEDURE_EDITOR.LOCATION"
                                                                    | translate }}</label>
                                                                </div>
                                                                <div class="float-right px-0">
                                                                    <button type="button"
                                                                        class="btn btn-outline-secondary btn-sm border-0 float-right"
                                                                        (click)="deleteSubStepLoc(step, substep)">
                                                                        <i class="fa fa-times" aria-hidden="true"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div class="card-body px-2 py-2">
                                                                <div
                                                                    class="border rounded border-secondary col-sm-12 my-1 p-1">
                                                                    <div contenteditable="false"
                                                                        class="toggle-edit{{tabId}}{{userId}} cke_editable_inline cke_contents_ltr substep_ck_loc_editor{{n}}{{k}}">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> -->

                                                        <div class="card my-1 bg-light">
                                                            <div
                                                                class="d-flex flex-row no-gutters align-items-center flex-xl-wrap">
                                                                <div class="float-left px-1">
                                                                    <h5 class="text-center header-ncwr my-1">
                                                                        {{ "PROCEDURE_EDITOR.LOCATION" | translate }}
                                                                    </h5>
                                                                </div>
                                                                <div class="flex-fill border-left border-right px-1">
                                                                    <div contenteditable="false"
                                                                        class="toggle-edit{{tabId}}{{userId}} cke_editable_inline cke_contents_ltr substep_ck_loc_editor{{n}}{{k}}" (dblclick)="activeEvent($event.target)">
                                                                    </div>
                                                                </div>
                                                                <div class="float-right">
                                                                    <button type="button"
                                                                        [disabled]="!isCheckOut || isCheckOutByOthers"
                                                                        class="close close-ncwr float-right mx-2"
                                                                        (click)="deleteSubStepLoc(step, substep)">
                                                                        <i class="fa fa-times" aria-hidden="true"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <!--Operating Data-->
                                                    <div class="col-sm-12 my-1" *ngIf="substep.operatingDataFlag"
                                                        [ngClass]="{'ph-show': isExpandAll, 'ph-hide': !isExpandAll}">
                                                        <div class="card my-2">
                                                            <div class="card-header p-1">
                                                                <div class="float-left px-1">
                                                                    <h5 class="text-center header-ncwr my-1">
                                                                        {{"PROCEDURE_EDITOR.OPERATING_DATA" | translate }}
                                                                    </h5>
                                                                </div>
                                                                <div class="float-right px-0">
                                                                    <button type="button"
                                                                        class="btn btn-outline-secondary btn-sm border-0 float-right"
                                                                        (click)="deleteOperatingData(substep)"
                                                                        [disabled]="!isCheckOut || isCheckOutByOthers">
                                                                        <i class="fa fa-times" aria-hidden="true"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div class="card-body px-2 py-2">
                                                                <tag-input [(ngModel)]="substep.operatingData"
                                                                [disable]="!isCheckOut || isCheckOutByOthers"
                                                                    [removable]='true'  maxItems="100"
                                                                    [displayBy]="'operatingDataName'" [identifyBy]="'id'"
                                                                    (onAdd)="onOperatingDataAdded($event)"
                                                                    (onRemove)="onOperatingDataRemoved($event)"
                                                                    [onlyFromAutocomplete]="true"
                                                                    placeholder="{{'PROCEDURE_EDITOR.ADD_OPERATING_DATA' | translate}}" 
                                                                    secondaryPlaceholder="{{'PROCEDURE_EDITOR.ADD_OPERATING_DATA' | translate}}"
                                                                    [clearOnBlur]="true">
                                                                    <tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="operatingDataList"
                                                                            [displayBy]="'operatingDataName'" [identifyBy]="'id'" 
                                                                            [limitItemsTo]=10 [appendToBody]="false"></tag-input-dropdown>
                                                                </tag-input>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- checkin comments modal -->
<ng-template #checkin_comments>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{ 'CHECK_IN' | translate}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <label>{{ 'PROCEDURE_EDITOR.COMMENTS' | translate}}</label>
        <textarea class="form-control" #comments></textarea>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="checkIn(comments.value)">
            {{ 'OK' | translate}}</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="bsModalRef.hide()">
            {{ 'CANCEL' | translate}}</button>
    </div>
</ng-template>

<!-- delete step modal -->
<ng-template #delete_step>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{"PROCEDURE_EDITOR.DELETE_CONFIRM" | translate }}</h4>
    </div>
    <div class="modal-body">
        <label>{{delContent}}</label>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="confirmStepDel()">
            {{"PROCEDURE_EDITOR.YES" | translate }}</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="bsModalRef.hide()">
            {{"PROCEDURE_EDITOR.NO" | translate }}</button>
    </div>
</ng-template>

<!-- delete substep modal -->
<ng-template #delete_substep>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{"PROCEDURE_EDITOR.DELETE_CONFIRM" | translate }}</h4>
    </div>
    <div class="modal-body">
        <label>{{delContent}}</label>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="confirmSubstepDel()">
            {{"PROCEDURE_EDITOR.YES" | translate }}</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="bsModalRef.hide()">
            {{"PROCEDURE_EDITOR.NO" | translate }}</button>
    </div>
</ng-template>

<!-- is dependency -->
<ng-template #is_dep>
    <div class="modal-header"></div>
    <div class="modal-body">
        <label>{{"PROCEDURE_EDITOR.DEP_MSG" | translate }}</label>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="bsModalRef.hide()">
            {{ 'OK' | translate}}</button>
    </div>
</ng-template>

<!-- special characters modal -->
<ng-template #spec_char>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{ 'WEB_EDITOR.SPE_CHAR' | translate}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-2" *ngFor="let speChar of speCharList; let n=index">
                    <button type="button" class="btn default" (click)="insertTagEditArea(speChar)"
                        style="background-color: inherit;">
                        {{speChar.data.symbol}}</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer" hidden="true">
        <label>{{ 'WEB_EDITOR.DEC_INPUT' | translate}}</label>
        <input (keypress)="numberOnly($event)" class="form-control input-sm" #dec_value type="text">
        <button type="button" class="btn btn-primary" (click)="addSpeChar(dec_value.value)">
            <i class="fa fa-plus" aria-hidden="true"></i>
        </button>
    </div>
</ng-template>

<!--fraction modal-->
<!-- <ng-template #fraction>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{ 'WEB_EDITOR.FRACTION' | translate}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid">
            <div class="row">
                <div>
                    <input (keypress)="numberOnly($event)" class="form-control input-sm" #dec_value type="text">
                    <hr width="`100%">
                    <input (keypress)="numberOnly($event)" class="form-control input-sm" #dec_valueD type="text">
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary"
                            (click)="addFraction(dec_value.value,dec_valueD.value)">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template> -->
<ng-template #fraction>
	<div class="modal-header">
		<h4 class="modal-title pull-left">{{ 'WEB_EDITOR.FRACTION' | translate}}</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
    <div class="modal-body">
		<div class="container-fluid">
			<div class="row">
				<div class="input-group input-group-sm ">
					<div class="input-group-prepend">
						<span class="input-group-text">Numerator / Denominator</span>
					</div>
					<input (keypress)="numberOnly($event)" #dec_value type="text" aria-label="Numerator"
						class="form-control" [(ngModel)]="numerator">
					<input (keypress)="numberOnly($event)" #dec_valueD type="text" aria-label="Denominator"
						class="form-control" [(ngModel)]="denominator">
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer p-2">                     
		<div class="col-sm-12 px-0"> 
			<div class="badge badge-warning float-left" *ngIf="numerator != '' && denominator != '' && numerator != null && denominator != null">
				<span class="h6 m-0">{{numerator}} / {{denominator}}</span>
			</div>
			<button type="button" class="btn btn-secondary btn-sm float-right mx-1" (click)="bsModalRef.hide()">{{ 'CANCEL' |
								translate}}</button>
			<button type="button" class="btn btn-primary btn-sm float-right mx-1"
				(click)="bsModalRef.hide();addFraction(dec_value.value,dec_valueD.value);">{{
								'ADD' | translate}}</button>
		</div>
	</div>
</ng-template>
<!-- image/figure popover -->
<ng-template #popTemplate>
    <div class="card col-sm-8 rounded-0 p-0 border-0 mt-2 mb-1 mx-auto">
        <div class="card-body row p-2">
            <button type="button" class="btn btn-sm btn-outline-dark btn-block m-2" (click)="insertFigure()">
                {{'PROCEDURE_EDITOR.BTN_TEXT_INSERT_FIGURE' | translate }}</button>
            <button type="button" class="btn btn-sm btn-outline-dark btn-block m-2" (click)="insertImage()">
                {{'PROCEDURE_EDITOR.BTN_TEXT_INSERT_IMAGE' | translate }}</button>
        </div>
    </div>
</ng-template>

<!-- conflict checker modal -->
<ng-template #conflict_checker>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Conflicts</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body py-1">
        <label>{{ 'PROCEDURE_EDITOR.CONFLICTS_CHECK_MSG' | translate }}</label>
        <ag-grid-angular #agGrid style="width: 100%; height: 400px;" [rowData]="rowData" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }" class="ag-theme-balham"
            [columnDefs]="columnDefs"  [enableFilter]="false" [defaultColDef]="defaultColDef"
            [enableRangeSelection]="true" [pagination]="false"
            (gridReady)="onGridReady($event)" rowSelection="multiple" 
            [rowMultiSelectWithClick]="true" [getRowNodeId]="getRowNodeId" [rowSelection]="rowSelection">
        </ag-grid-angular>
        <button class="btn btn-sm btn-outline-dark float-right my-2" (click)="modalRef.hide();">Cancel</button>
        <button class="btn btn-sm btn-outline-dark float-right my-2 mx-2"
            (click)="autoUpdateProc();">Auto
            Update</button>
    </div>
</ng-template>

<!-- undo checkout warning modal-->
<ng-template #undo_checkout_warn>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{'PROCEDURE_EDITOR.UNDO_CHECKOUT' | translate }}</h4>
    </div>
    <div class="modal-body">
        <h5 class='warn-text-editor'>{{ 'WARNING' | translate}}</h5>
        <h5>{{ 'PROCEDURE_EDITOR.UNDO_CHECKOUT_CONFIRM' | translate}}</h5>
    </div>
    <div class="modal-footer">
        <button type="button" id="webeditor_modal_footer_chekin_list_warn" class="btn btn-primary"
            (click)="undocheckoutWarningModalRef.hide(); undoCheckout();">
            {{ 'OK' | translate}}</button>
        <button type="button" id="webeditor_modal_footer_chekin_list_warn" class="btn btn-secondary"
            data-dismiss="modal" (click)="undocheckoutWarningModalRef.hide()">
            {{ 'CANCEL' | translate}}</button>
    </div>
</ng-template>
