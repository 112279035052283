import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { UserVariable } from '../util/common/user-variable';
import { ServiceUrls } from '../util/service-urls';
import { WsResponse } from '../util/ws-response.model';
import { WsCallback } from '../util/ws-callback.interface';
import { WsType } from '../util/ws-type'
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators'
import { SharedService } from '../util/shared.service';
import { CommonUtil } from '../util/common/common-util';

@Injectable({
    providedIn: 'root'
})
export class ClientService {
    public token: string;
    private rowData: any = null;
    private apiGridData: any = null;
    private eventValue: any = null;
    private callback: WsCallback;

    constructor(private http: HttpClient, private sharedService: SharedService, private commonUtil: CommonUtil) { }

    public setCallback(callBack: WsCallback) {
        this.callback = callBack;
    }

    getClientList() {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateLogin(token)){
            return;
        }
        let url = this.getClientListByUrl(token);
        return this.http.post(url, JSON.stringify(new Request())).
            pipe(
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                }),
                catchError(error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    return throwError(res)
                })
            );
	}

	getClient(id: any)
	{
		let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateLogin(token)) {
            return;
        }
		const options = { params: new HttpParams().set("token", token) }
		let url = this.getClientByUrl(id);
        return this.http.get(url, options).
            pipe(
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                }),
                catchError(error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    return throwError(res)
                })
            );

	}


	updateClient(fileUploadData: FormData,  callback: WsCallback) {
		let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateLogin(token)) {
            return;
        }
		let url = ServiceUrls.CLIENTS  + 'update/updateClient.json?token=' + token;
		this.http.post(url, fileUploadData).
		subscribe(
			data => {
				var modified:any =  data;
				var res = new WsResponse(
					modified.status.description,
					modified.status.name,
					modified.status.code,
					modified.payload);
				callback.onSuccess(res, WsType.EDIT_CLIENT);
			},
			error => {
				var modified = JSON.parse(JSON.stringify(error));
				var res = new WsResponse(
					modified.error.status.description,
					modified.error.status.name,
					modified.error.status.code,
					modified.error.payload);
				callback.onFail(res, WsType.EDIT_CLIENT);
			});
	}

	public deleteFileTableData(fileId: any) {
		if (this.apiGridData != null && this.apiGridData != undefined && fileId != null) {
			var selectedData = this.apiGridData.getSelectedRows();
			if (selectedData != null && selectedData != undefined) {
				this.apiGridData.applyTransaction({ remove: selectedData });
			}
		}
	}

	getClientLogo(): Observable<WsResponse> {
		let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateLogin(token)) {
            return;
        }
		const options = { params: new HttpParams().set("token", token)}
		let url = this.getClientLogoIfModifiedByUserURL();
		return this.http.get(ServiceUrls.GET_CLIENT_LOGO, options).pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.code,
					modified.status.name,
					modified.status.description,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.code,
					modified.status.name,
					modified.status.description,
					modified.payload);
				return throwError(res)
			})
		);
	}

	public getClientLogoIfModifiedByUserURL() {
		let url = ServiceUrls.GET_CLIENT_LOGO;
		return url;
	}


	addClient(fileUploadData: FormData, callback: WsCallback) {
		let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateLogin(token)) {
            return;
        }
		let url = ServiceUrls.CLIENTS  + 'add/addClient.json?token=' + token;
		this.http.post(url, fileUploadData).
		subscribe(
			data => {
				var modified:any =  data;
				var res = new WsResponse(
					modified.status.description,
					modified.status.name,
					modified.status.code,
					modified.payload);
				callback.onSuccess(res, WsType.ADD_CLIENT);
			},
			error => {
				var modified = JSON.parse(JSON.stringify(error));
				var res = new WsResponse(
					modified.error.status.description,
					modified.error.status.name,
					modified.error.status.code,
					modified.error.payload);
				callback.onFail(res, WsType.ADD_CLIENT);
			});
	}
	
	
	public disableEnableFileTableData(fileId: any, title: String, status: String) {
		console.log("hhh" + status + title + fileId)
		if (this.apiGridData != null && this.apiGridData != undefined && fileId != null) {
			var rowNode = this.apiGridData.getRowNode(fileId);
			console.log("hhh" + rowNode)
			if (rowNode != null && rowNode != undefined) {
				if (title == 'Disable Confirm Client') {
					this.sharedService.disableCellRendererRequest(true, fileId);
					rowNode.setDataValue("status", "status:" + fileId + ":Inactive");
					rowNode.setDataValue("delete", "delete:" + fileId + ":Inactive");
				} else if (title == 'Enable Confirm Client') {
					console.log("hhh" + status + title + fileId)
					this.sharedService.disableCellRendererRequest(false, fileId);
					rowNode.setDataValue("status", "status:" + fileId + ":Active");
					rowNode.setDataValue("delete", "delete:" + fileId + ":Active");
				}
				else {
					if (status == "Inactive") {
						this.sharedService.disableCellRendererRequest(false, fileId);
						rowNode.setDataValue("status", "status:" + fileId + ":Active");
						rowNode.setDataValue("delete", "delete:" + fileId + ":Active");
					}
					else {
						this.sharedService.disableCellRendererRequest(true, fileId);
						rowNode.setDataValue("status", "status:" + fileId + ":Inactive");
						rowNode.setDataValue("delete", "delete:" + fileId + ":Inactive");
					}
				}
			}
		}
	}

	public getClientByUrl(id:any)
	{
		let url = ServiceUrls.CLIENTS +  id + ".json";
		return url
	} 

    public getClientListByUrl(token: String) {
        let url = ServiceUrls.GET_CLIENT_LIST + '?token=' + token;
        return url;
    }

    public setApiGridData(apiGridData: any) {
        this.apiGridData = apiGridData;
    }

    public getApiGridData() {
        return this.apiGridData;
    }

    public setRowData(rowData: any) {
        this.rowData = rowData;
    }

    public getRowData() {
        return this.rowData;
    }

}

class Request {
    constructor() { }
}

class UpdateRequest {
	constructor(private clientId: String, private clientName: String, private clientShortName: String, private status: String) { }
}