import { LoadingService } from 'src/app/util/loading/loading.service';
import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../util/shared.service';
import { UserService } from '../user.service';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from "@angular/forms";
import { ClientService } from '../../client/client.service';
import { Router } from '@angular/router';
import { AlertService } from '../../util/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-edituser',
	templateUrl: './edituser.component.html',
	styleUrls: ['./edituser.component.css']
})
export class EdituserComponent implements OnInit {

	public user: any
	editUserForm: FormGroup;
	clientList: any;
	
	disableClientList: boolean = false;
	checkOpenId: boolean = false;
	public statusList: Array<any> = [];
	passwordExpire: boolean = false;
	public currentUName: any;
	selectedClientId: string = null;
	selectedClient: Array<any> ;
	expiryDate: any = "";
	omitDeviceId: any;
	public omitDeviceIdStatus: boolean;
	public clientIdStatus: boolean;
	public clientId: string;
	public clientArray: Array<any> = [];
	selectedStatus: any;
	public openIdStatus: boolean;
	isClientChecked: boolean = false;
	isClientRemoved: boolean = false;
	hasClient: boolean;
	public passwordExpiredDate: any ;
	//public status:Array<any> = [];
	public statusItems: Array<any>; // = [{ 'id': '0', 'text': 'Active' }]
	constructor(private sharedService: SharedService, private alertService: AlertService, private router: Router, 
		private clientService: ClientService, private userService: UserService, private formBuilder: FormBuilder,
		private translate: TranslateService, private loadingService: LoadingService) {

		translate.addLangs(["es", "en"]);
		translate.setDefaultLang("en");
		let browserLang = translate.getBrowserLang();
		translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');


		this.statusList = [
		{ 'id': '0', 'text': 'Active' },
		{ 'id': '1', 'text': 'Inactive' }
		];
	}

	ngOnInit() {

		this.editUserForm = this.formBuilder.group({
			firstName: new FormControl(null),
			lastName: new FormControl(null),
			userName: new FormControl(null),
			checkClient: new FormControl(null),
			clientList: new FormControl(null),
			checkOpenId: new FormControl(null),
			status: new FormControl(null),
			email: new FormControl(null),
			newPassword: new FormControl(null),
			confirmPassword: new FormControl(null),
			passwordExpire: new FormControl(null),
			expiryDate: new FormControl(null),
			authCode: new FormControl(null)
			// trueorfalse: new FormControl(null),
			// answers: this.formBuilder.array([

			// ])
		});

		this.clientService.getClientList().subscribe(
			data => {
				this.loadClients(data.payload);
			},
			error => {
				//this.alertService.clear()
				//this.alertService.error(error.statusDescription)
			});;
		

		if (this.sharedService.getFileId() != "-1" && this.sharedService.getFileId() != -1) {
			this.userService.getUser(this.sharedService.getFileId()).subscribe(
				data => {
					if (data.payload != null) {
						this.user = data.payload;
						let date: string = new Date(data.payload.expiredTime).toLocaleString()
						console.log(date.substring(0,date.indexOf(',')))
						if (data.payload.expiredTime !== null) {
							this.passwordExpiredDate = date.substring(0,date.indexOf(','));
						} else {
							this.passwordExpiredDate = null
						}	
						this.clientService.getClientList().subscribe(
							data => {
								data.payload.forEach(item => {
									this.clientArray.push(
										{ id: item.clientId, text: item.clientName }
									)
								});
								this.selectedClient = this.clientArray.filter(x => x.id == this.user.clientId)
								if (this.selectedClient.length != 0) {
									this.selectedClientId = this.selectedClient[0].id;
								}
								console.log(this.selectedClient)
							},
							error => {});
						this.clientId = this.user.clientId
						if  (this.clientId !== "null" && this.clientId !== null) {
							this.isClientChecked = true;
						}
						if ((this.clientId == null && this.clientId != "null") || (this.clientId == "null" && this.clientId != null)) {
							this.hasClient = false;
						} else  {
							this.hasClient = true;
						}
						this.omitDeviceId =  this.user.omitDeviceToken
						console.log("this.omitDeviceId : "+this.omitDeviceId)
						console.log(this.clientId)
						this.setOpenIdStatus(this.user.authType)
						this.currentUName = data.payload.userName;
						this.selectedStatus = data.payload.status;
						this.setOmitDeviceIdStatus(data.payload.omitDeviceToken)
						this.setClientIdStatus(data.payload.clientId)
						let list: Array<any> = [];
						list.push(
							{ id: 0, text: data.payload.status }
						);

						this.statusItems = list;
					}
				},
				error => {
					//this.alertService.clear()
					//this.alertService.error(error.statusDescription)
				});
		}
		
	}

	setOmitDeviceIdStatus(omitDeviceId: number) {
		if (omitDeviceId == 0) {
			this.omitDeviceIdStatus = false;
		} else if (omitDeviceId ==1) {
			this.omitDeviceIdStatus = true;
		}
	}

	setOpenIdStatus(authType: number) {
		if (authType == 1) {
			this.openIdStatus = true
			this.checkOpenId = true
			console.log("open Id true")
		} else if (authType == 2) {
			this.openIdStatus = false
			console.log("open Id false")
		}
	}

	setClientIdStatus(clientId:any) {
		if (clientId == "null" || clientId ==  null){
			this.clientIdStatus = false
		} else {
			this.clientIdStatus = true
		}
 	}

	statusSelected(event: any)
	{
		this.selectedStatus = event["text"];
		console.log("selected" + JSON.stringify(event));
	}

	save() {
		console.log(this.editUserForm.controls.email.invalid)
		console.log(this.editUserForm.controls.email.value)
		if (this.checkOpenId == false) {
			if (((this.editUserForm.controls.newPassword.value === ""  || this.editUserForm.controls.newPassword.value == null) && this.editUserForm.controls.confirmPassword.value  != null )
			|| ((this.editUserForm.controls.confirmPassword.value === "" || this.editUserForm.controls.confirmPassword.value  == null) &&  this.editUserForm.controls.newPassword.value != null)) {
				this.alertService.clear();
				this.alertService.error("To change the password, all the password fields should be filled properly");
			} 
			else if (this.editUserForm.controls.firstName.value === "" || this.editUserForm.controls.firstName.value === null
			|| this.editUserForm.controls.lastName.value === "" || this.editUserForm.controls.lastName.value === null ||
			this.editUserForm.controls.userName.value === "" || this.editUserForm.controls.userName.value === null ||
			this.selectedStatus === "" || this.selectedStatus === null || this.editUserForm.controls.email.value === "" || this.editUserForm.controls.email.value === null) {
				this.alertService.clear();
				this.alertService.error("Please fill all the mandatory fields");
			}
			else if( this.editUserForm.controls.newPassword.value != this.editUserForm.controls.confirmPassword.value)
			{
				this.alertService.clear();
				this.alertService.error("New password and confirm password should match");
			}
			// else if (!this.disableClientList && this.editUserForm.controls.clientList.value == null) {
			// 	this.alertService.clear();
			// 	this.alertService.error("Please select Client User");
			// }
			/*else if(this.editUserForm.controls.email.value == "" || this.editUserForm.controls.email.value == null)
			{
				this.alertService.clear();
				this.alertService.error("Please enter user email address");
			}*/
			else if (this.editUserForm.controls.email.invalid) {
				this.alertService.clear();
				this.alertService.error("Please enter valid e-mail address");
			}
			else if(this.passwordExpire && this.expiryDate == null)
			{
			this.alertService.clear();
			this.alertService.error("Please select password expiry date");
			} else if ((this.isClientChecked && (this.selectedClientId == null || this.selectedClientId == "null" )) || (this.isClientChecked && this.isClientRemoved)) {
				this.alertService.clear();
				this.alertService.error("Please select client User");				
		/*	} else if (this.isClientChecked && this.selectedClientId != "null" && this.editUserForm.controls.clientList.value == null) {
				this.alertService.clear();
				this.alertService.error("Please select client User");*/
			 
			} else if(!this.isClientChecked && this.hasClient) {
				this.alertService.clear();
				this.alertService.error("Cannot deselect the client");	
			} else {
				let firstName = this.editUserForm.controls.firstName.value;
				let lastName = this.editUserForm.controls.lastName.value;
				let authType;
				if (this.checkOpenId) {
					authType = "1";
				}
				else {
					authType = "2";
				}
				let userName = this.editUserForm.controls.userName.value;
				let password = this.editUserForm.controls.newPassword.value;
				let status = this.selectedStatus;
				let emailAddress = this.editUserForm.controls.email.value;
				let clientStatus;
				if (!this.disableClientList) {
					clientStatus = "1";
				}
				else {
					clientStatus = "0";
				}
				//console.log("Status" + JSON.stringify(Status))
				this.alertService.clear();
				this.userService.updateUser(this.sharedService.getFileId(), firstName, lastName, authType, userName, password,
					this.currentUName, status, emailAddress, clientStatus, this.selectedClientId, this.expiryDate, this.omitDeviceId).
					subscribe(data => {
						//this.router.navigateByUrl("user")
							this.alertService.clear();
							this.alertService.success("User edited successfully");
							this.loadingService.hideLoading();
							this.sharedService.viewSideBarEventEmit("users");
					},
					   error => {
						 	this.alertService.clear()
							this.alertService.error(error.statusDescription)
							this.loadingService.hideLoading();
					   });
				this.loadingService.showLoading(true,null,"Saving..",null);
			}
		}
		else if (this.editUserForm.controls.userName.value.indexOf('@') === -1 || this.editUserForm.controls.userName.value.indexOf('.') === -1) {
			this.alertService.clear();
			this.alertService.error("For OpenId Authentication, username should be an email address");
		}
		/*else if (!this.disableClientList && this.editUserForm.controls.clientList.value == null) {
			this.alertService.clear();
			this.alertService.error("Please select client User");
		}
		else if (this.isClientChecked && this.editUserForm.controls.clientList.value == null) {
			this.alertService.clear();
			this.alertService.error("Please select client User");
		}*/
		else if ((this.isClientChecked && (this.selectedClientId == null || this.selectedClientId == "null" )) || (this.isClientChecked && this.isClientRemoved)) {
			this.alertService.clear();
			this.alertService.error("Please select client User");
		} 
		else if(this.passwordExpire && this.expiryDate == null)
		{
			this.alertService.clear();
			this.alertService.error("Please select password expiry date");
		} 
		else if(!this.isClientChecked && this.hasClient) {
			this.alertService.clear();
			this.alertService.error("Cannot deselect the client");	
		} else {
			let firstName = this.editUserForm.controls.firstName.value;
			let lastName = this.editUserForm.controls.lastName.value;
			let authType;
			if (this.checkOpenId) {
				authType = "1";
			}
			else {
				authType = "2";
			}
			let userName = this.editUserForm.controls.userName.value;

			let password = this.editUserForm.controls.newPassword.value;
			let status = this.selectedStatus;
			let emailAddress = userName;
			let clientStatus;
			if (!this.disableClientList) {
				clientStatus = "1";
			}
			else {
				clientStatus = "0";
			}
			this.alertService.clear();
			this.userService.updateUser(this.sharedService.getFileId(), firstName, lastName, authType, userName, password,
				this.currentUName, status, emailAddress, clientStatus, this.selectedClientId, this.expiryDate, this.omitDeviceId).
				subscribe(data => {
					//this.router.navigateByUrl("user")
						this.alertService.clear();
						this.alertService.success("User edited successfully");
						this.loadingService.hideLoading();
						this.sharedService.viewSideBarEventEmit('users')
				 },
					error => {
						this.alertService.clear()
						this.alertService.error(error.statusDescription)
						this.loadingService.hideLoading();
					});
				this.loadingService.showLoading(true,null,"Saving..",null);
		}
	}

	setOmitDeviceId(event: any) {

		if (event == true) {
			this.omitDeviceId = 1;
		}
		else if (event == false) {
			this.omitDeviceId = 0;
		}

	}
	removed(event:any) {
		console.log(event)
		this.selectedClientId = null;
		this.isClientRemoved = true;
	}

	cancel() {
		//this.router.navigateByUrl("user")
		this.alertService.clear();
		this.sharedService.viewSideBarEventEmit("users");
	}

	onStartValueChange(event: any) {
		this.expiryDate = event;
	}


	setPasswordExpire(event: any) {
		if (event == true) {
			this.passwordExpire = true;
		}
		else {
			this.passwordExpire = false;
		}
	}


	CheckOpenId(event: any) {
		if (event == true) {
			this.checkOpenId = true;
		}
		else {
			this.checkOpenId = false;
			this.openIdStatus = false;
		}

	}

	clientSelected(event: any) {
		//console.log(event)
		this.selectedClientId = event.id
		this.isClientRemoved = false
	}

	Changed(event: any) {
		if (event == true) {
			this.disableClientList = false;
			this.clientId = "1"
			this.isClientChecked = true
		}
		else {
			this.disableClientList = true;
			this.selectedClientId = null
			this.isClientChecked = false
		}
	}

	loadClients(data: any) {
		//   console.log(data);
		let list: Array<any> = []
		//list.push({id:"-1", text:"Internal Users"})
		data.forEach(item => {
			list.push(
				{ id: item.clientId, text: item.clientName }
			)
		});
		this.clientList = list
	

		// for(let i=0; i<data.length; i++)
		// {
		// 	// console.log("loading " + data[i].clientId + '/'+ data[i].clientName)
		// 	this.clientList.push({ id:data[i].clientId, text:data[i].clientName});
		// }
		// console.log(JSON.stringify(this.clientList.length));

	}


}
