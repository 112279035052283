import { CapabilityService } from 'src/app/util/capability.service';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AlertService } from 'src/app/util/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import  { CategorySettingService} from './category-setting.service'
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal'
import { DependencyStep } from 'src/app/procedure/procedure.model';
declare var $: any;

enum categoryTypes {
    Statement =1 ,
    Component = 2,
    Check = 3,
}



@Component({
	selector: 'app-category-setting',
	templateUrl: './category-setting.component.html',
	styleUrls: ['./category-setting.component.scss']
})

export class CategorySettingComponent implements OnInit {
	public data: any = {
		'data': [
			{ "id": "rootId", "parent": "#", "text": "Category Types", icon: "fa fa-folder-o", type: "folder" },
		]
	}

	dependency: TemplateRef<any>	
	typesCount: number = 0;
	catList: any[][] =[];
	public treeRef: any = null;
	selectedCategoryType: any;
	selectedNodePath: any;
	selectedCategoryTypeId: any;
	modalRef: BsModalRef;
	selectedNodeName: string;
	dependCat: any[] = [];
	dependSte: any[] = []; 
	depnedCheck: any[] = [];
	dependenComp: any[] = []; 
	
	dependComplist: any[] = [];  //clent name + project name
	dependComplist_2: any[][];   // component dependency list
	isDependenComp: boolean = false;

	isDependenCat: boolean;
	dependCatList: any[] = [];

	isDependSte: boolean;
	dependStelist: any[];   //clent name + project name
	dependStelist_2: any;	// statement dependency list

	isDepnedCheck: boolean; 
	depnedChecklist: any[]; //clent name + project name
	depnedChecklist_2: any; // check dependency list
	selectedNodeId: string;
	isSelectedNode: boolean = false;
	selectedNodeStatusActive: boolean = true;
	dependencyData: any;



	public modalConfig: any = {
		backdrop: true,
		// ignoreBackdropClick: true,
		class: 'modal-lg',
		// keyboard: false
	}
	
	public categoryList: Array<any> =[{'id': '0', 'text':'Select Category Type'}];
	toDeleteViewDependency: boolean = false;
	selected: any;
	reference: any;
	previousId: any = null;
	previousTxt: any = null;
	newTxt: any = null;
	previousDeleteId: any = null;
	showDeleteConfirmModel: boolean;
	showDependencyModel: boolean;
	previousNodeId: any;
	previousDeleteText: any;
	rootSelected: boolean = true;
	isCatSelected: boolean = false;
	disableAllButtons: boolean = true;
	enableTooltip: string;
	disableTooltip: string;
	addTooltip: string;
	deleteTooltip: string;
	renameTooltip: string;
	activeTooltip: string;
	addCatTooltip: string;
	addSubCatTooltip: string;
	translated_data: any;
	
	//capabilities
	public createCategory: boolean = false 
	public modifyCategory: boolean = false 
	public disableCategory: boolean = false 
	public delCategory: boolean = false 

	//enable/disable buttons
	public enableAddCategory: boolean = false 
	public enableModifyCategory: boolean = false 
	public enableDisableCategory: boolean = false 
	public enableDeleteCategory: boolean = false

	constructor(private alertService: AlertService, private translate: TranslateService, private categorySettingService: CategorySettingService,  private modalService: BsModalService,
		private capabilityService: CapabilityService) {
		translate.setDefaultLang("en");
		let browserLang = translate.getBrowserLang();
		translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
		this.categorySettingService.getCategoryTypes();
		this.dependComplist_2 =[]
		this.translate.getTranslation(browserLang).subscribe((res: string) => {
			this.translated_data = res['AS_CATEGORY']
			this.enableTooltip = this.translated_data.TOOLTIP.ENABLE;
			this.disableTooltip = this.translated_data.TOOLTIP.DISABLE;
			this.addCatTooltip  = this.translated_data.TOOLTIP.ADD;
			this.addSubCatTooltip = this.translated_data.TOOLTIP.ADD_SUB_CATEGORY
			this.renameTooltip = this.translated_data.TOOLTIP.RENAME;
			this.deleteTooltip = this.translated_data.TOOLTIP.DELETE;
		});
		this.enableDisableButton()
	}
	

	ngOnInit() {
		var catTypes = categoryTypes;		
		this.setSelectionList(catTypes)
	}


	getSelectedCategoryType(event){
		// console.log("getSelectedCategoryType= " + event.text + "id= "+ event.id)
		this.isSelectedNode = false;
		this.selectedCategoryType = event.text
		this.selectedCategoryTypeId = event.id
		if(this.catList[event.id] == null){
			this.categorySettingService.getCategoryList(event.text)
				.subscribe(data => {
				if(data.payload != null){
					// console.log("getSelectedCategoryType= " + JSON.stringify(data.payload))
					this.loadData(data.payload, event.id)
				}
				},
				error => {
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			});
		}else{
			this.treeRef = $('#catFoldertree').jstree(true).settings.core.data = this.catList[event.id]["data"];
			this.treeRef = $('#catFoldertree').jstree(true).refresh();
			this.treeRef =$("#catFoldertree").jstree(true).deselect_all(true);
			this.disableAllButtons = true;


		}	
	}

	/**
	 * Loading data to the tree view.
	 * @param payload data to be shown.
	 */
	loadData(payload: any, id: any) {
		this.data = {
			'data': [
				{ "id": "rootId", "parent": "#", "text": "Category Type - "+categoryTypes[id], icon: "fa fa-folder-o", "type": "folder", "folderPath": "All" },
			]
		}
		if (payload != null) {
			var item = payload.filter(item => item["parentId"] == "0");
			if (item != null && item.length > 0) {

				payload.forEach(item => {
					this.data["data"].push({
						id: item["categoryId"],
						parent: item["parentId"] == "0" ? "rootId" : item["parentId"],
						text: item["categoryName"],
						type: item["categoryType"],
						icon: item["status"] == "Inactive" ? "fas fa-folder" : "far fa-folder",
						folderPath: item["categoryPath"],
						status: item["status"]
					})
				});
				this.catList[id] = this.data;
				this.treeRef = $('#catFoldertree').jstree({
					core: {
						themes: {
							variant: 'normal'
						},
						strings: {
							'New node': 'New Category'
						},
						data: this.data["data"],
						check_callback: function (operation, node, node_parent, node_position, more) {
							switch (operation) {
								case 'move_node':
									return true
								case 'copy_node':
									return false
								default:
									break;
							}
						},
						force_text: true
					},
					types: {
						default: {
							// "icon" : "fa fa-file-o "
						}
					},
					plugins: ["dnd", "search", "state", "types", "wholerow"],
					search: {
						show_only_matches: true,
						show_only_matches_children: true
					}
				});
				this.treeRef = $('#catFoldertree').jstree(true).settings.core.data = this.data["data"];
				this.treeRef = $('#catFoldertree').jstree(true).refresh();
				this.disableAllButtons = true;
				// '$j("#demo2").jstree("refresh");
				setTimeout(() => $("#catFoldertree").jstree("open_node", item[0]["id"]), 250);
				//$("#catFoldertree").jstree("open_node", item[0]["id"])
				$('#catFoldertree').bind("select_node.jstree", (evt, data) => this.catSelectEvent(evt, data))
				// //triggered when a node is renamed
				$('#catFoldertree').bind("rename_node.jstree", (evt, data) => this.renameCallbackEvent(evt, data))
				// //triggered when a node is deleted
				$('#catFoldertree').bind("delete_node.jstree", (evt, data) => this.deleteCallbackEvent(evt, data))
				$('#catFoldertree').bind("deselect_all.jstree", (evt, data) => this.deselectCallbackEvent(evt, data))
				// //triggered when a node is created
				// $('#catFoldertree').bind("create_node.jstree", (evt, data) => this.addCallbackEvent(evt, data))
			}
		}
	}

	public deselectCallbackEvent(evt: any, data: any){
		// console.log("deselect all callback")
		this.disableAllButtons = true;
	}


	public renameCallbackEvent(evt: any, data: any) {
		//rename event triggered several times for a rename
		//couldn't find a way to stop that
		//https://www.jstree.com/api/#/?f=rename_node.jstree
		// console.log("data.node.id= "+ data.node.id )
		if((this.previousId == data.node.parent) && (this.newTxt == data.text) && (this.previousTxt == data.old) && (this.previousDeleteText != data.text)){
			// console.log("renameCallbackEvent repeats ")
			if(data.node.id != this.selectedNodeId){
				this.reference.delete_node(data.node.id);
			}
			return false
		}else{
			this.previousNodeId = data.node.id
			this.previousId = data.node.parent
			this.newTxt = data.text
			this.previousTxt = data.old
			this.previousDeleteText = null
		}
		if (!data.node.state.selected) { //add category
			this.addCategory(data)
		}
		else { //rename category
			this.renameCategory(data)
		}
	}

	public deleteCallbackEvent(evt: any, data: any) {
		//delete event triggered several times for a delete
		//the solution which is implemented to stop renameCallbackEvent is also increases deleteCallbackEvent s
		//couldn't find a way to stop that
		//https://www.jstree.com/api/#/?f=delete_node.jstree
		if(data.node.id != this.selectedNodeId || this.previousDeleteId == data.node.id ){
			// console.log("deleteCallbackEvent repeats")
			return false
		}else{
			this.previousDeleteId = data.node.id
			this.previousDeleteText = data.node.text
		}
		// console.log("delete callback= " + data.node.id);
		this.deleteCategory(data.node.id)
	}

	public catSelectEvent(event: any, data: any) {
		// console.log("selectJSTreeItem= "+JSON.stringify(data.node))
		this.selectedNodePath = data.node.original.folderPath
		this.selectedNodeName = data.node.text
		this.selectedNodeId = data.node.id
		this.rootSelected = data.node.id == 'rootId' ? true : false
		if(this.rootSelected){
			this.addTooltip = this.addCatTooltip 
		}else{
			this.addTooltip = this.addSubCatTooltip 
		}
		this.selectedNodeStatusActive = data.node.original.status == "Inactive" ? false : true
		if(this.selectedNodeStatusActive){
			this.activeTooltip = this.disableTooltip 
		}else{
			this.activeTooltip = this.enableTooltip 
		}
		this.isSelectedNode = true;
		this.isCatSelected = true;
		this.disableAllButtons = false;

	}

	private getDependencies(nodeId: string){
		this.categorySettingService.getCategoryDependencies(this.selectedCategoryType, nodeId, "DELETE")
		.subscribe(
			data => {
				if(data.payload != null){
					this.clearDependencyData()	
					//check payload contains following dependencies
					if(data.payload.Category){
						this.dependCat = data.payload.Category
					}
					if(data.payload.Statement){
						this.dependSte = data.payload.Statement
					}
					if(data.payload.Check){
						this.depnedCheck = data.payload.Check
					}
					if(data.payload.Component){
						this.dependenComp = data.payload.Component
					}
					if(this.depnedCheck.length != 0 || this.dependSte.length != 0 ||this.dependCat.length != 0 || this.dependenComp.length != 0){
						//there exists depedencies for category
						//delete disabled
						//model show enable or disable	
						this.selectedNodeId = nodeId
						this.showDependencies()
						this.dependencyData = data.payload
					}else{
						//no dependencies 
						//delete or change status		
						if(this.toDeleteViewDependency){
							// console.log("no dependencies delete "+ this.selected)
							this.showDependencyModel = false
							this.modalRef = this.modalService.show(this.dependency, this.modalConfig);
						}else{
							if(this.selectedNodeStatusActive){
								this.disableCategoryStatus("Inactive")
							}else{
								this.disableCategoryStatus("active")}
						}						
					}
				}else{
					//error
				}
			},
			error => {
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
				this.reloadTree()
			}
		)
	}

	public deleteConfirm(){
		this.reference.delete_node(this.selected);
		this.modelHide()
		this.toDeleteViewDependency = false
	}
	private showDependencies(){
		//component dependencies
		if(this.dependenComp.length != 0){
			this.isDependenComp = true;
			this.dependComplist = [] 
			let k = 0
			for(let i = 0; i<this.dependenComp.length; i++){
				this.dependenComp[i].clientName = this.dependenComp[i].clientName+" - "+this.dependenComp[i].projectName
				if(this.dependComplist.indexOf(this.dependenComp[i].clientName) === -1){
					this.dependComplist[k++] = this.dependenComp[i].clientName
				}		
			}
			
			for(let i = 0; i<this.dependComplist.length; i++){
				this.dependComplist_2[i] =[]
				let k = 0;
				for(let j = 0 ; j<this.dependenComp.length; j++){
					if(this.dependenComp[j].clientName == this.dependComplist[i]){
						if(this.dependComplist_2[i].indexOf(this.dependenComp[j].componentName) === -1){
							this.dependComplist_2[i][k++] = this.dependenComp[j].componentName
						}
					}
				}
				this.dependComplist[i] += " ["+ k + "]"
			}
		}else{
			//no components dependencies
		}
		//Category dependencies
		if(this.dependCat.length != 0){
			this.dependCatList = []
			this.isDependenCat = true;
			let k = 0
			for(let i = 0; i<this.dependCat.length; i++){
				this.dependCatList[k++] = this.dependCat[i].categoryName
			}
		}else{
			//no category dependencies
		}
		
		//statement dependencies
		if(this.dependSte.length != 0 ){
			this.isDependSte = true
			this.dependStelist = []
			let k = 0
			for(let i = 0; i<this.dependSte.length; i++){
				this.dependSte[i].clientName = this.dependSte[i].clientName+" - "+this.dependSte[i].projectName
				if(this.dependStelist.indexOf(this.dependSte[i].clientName) === -1){
					this.dependStelist[k++] = this.dependSte[i].clientName
				}		
			}
			
			for(let i = 0; i<this.dependStelist.length; i++){
				this.dependStelist_2[i] =[]
				let k = 0;
				for(let j = 0 ; j<this.dependSte.length; j++){
					if(this.dependSte[j].clientName == this.dependStelist[i]){
						if(this.dependStelist_2[i].indexOf(this.dependSte[j].title) === -1){
							this.dependStelist_2[i][k++] = this.dependSte[j].title
						}
					}
				}
				this.dependStelist[i] += " ["+ k + "]"
			}
		}else{
			//no statement dependencies
		}

		//check dependencies
		if(this.depnedCheck.length != 0 ){
			this.isDepnedCheck = true
			this.depnedChecklist = []
			let k = 0
			for(let i = 0; i<this.depnedCheck.length; i++){
				if(this.depnedCheck[i].clientName){
					this.depnedCheck[i].clientName = this.depnedCheck[i].clientName+" - "+this.depnedCheck[i].projectName
				}else{
					this.depnedCheck[i].clientName = this.depnedCheck[i].projectName
				}
				this.depnedCheck[i].checkDescription = this.getCheckDescription(this.depnedCheck[i].checkDescription)
				if(this.depnedChecklist.indexOf(this.depnedCheck[i].clientName) === -1){
					this.depnedChecklist[k++] = this.depnedCheck[i].clientName
				}		
			}
			
			for(let i = 0; i<this.depnedChecklist.length; i++){
				this.depnedChecklist_2[i] =[]
				let k = 0;
				for(let j = 0 ; j<this.depnedCheck.length; j++){
					if(this.depnedCheck[j].clientName == this.depnedChecklist[i]){
						if(this.depnedChecklist_2[i].indexOf(this.depnedCheck[j].checkDescription) === -1){
							this.depnedChecklist_2[i][k++] = this.depnedCheck[j].checkDescription
						}
					}
				}
				this.depnedChecklist[i] += " ["+ k + "]"
			}
		}else{
			//no check dependencies
		}
		this.showDependencyModel = true
		this.modalRef = this.modalService.show(this.dependency, this.modalConfig);
	}

	public modelRefCloseEvent() {
		this.modelHide()
	}

	public modelRefDisableEvent(status: any){
		// console.log("===disable" + this.selectedNodeName)
		this.disableCategoryStatus(status)
		this.modelHide()
	}

	public modelHide(){
		this.modalRef.hide()	
		// this.reloadTree()
	}

	public deleteElement(template: TemplateRef<any>){
		if(this.selectedNodeId == 'rootId'){return false}
		this.dependency = template; 
		this.reference = $('#catFoldertree').jstree(true)
		this.selected = this.reference.get_selected()		
		if (!this.selected.length) { return false }
		// console.log('deleteElement sel '+ this.selected);
		this.toDeleteViewDependency = true
		this.getDependencies(this.selected)
	}

	public createElement() {
		// console.log('[createElement]');
		this.reference = $('#catFoldertree').jstree(true)
		this.selected = this.reference.get_selected()
		// console.log(sel)
		if (!this.selected.length) { return false }
		this.selected = this.selected[0]
		this.selected = this.reference.create_node(this.selected, { icon: "far fa-folder", type: "doc" });
		if (this.selected) {
			this.reference.edit(this.selected);
		}
	} 

	public renameElement(){
		if(this.selectedNodeId == 'rootId'){return false}
		// console.log("renameElement")
		let ref = $('#catFoldertree').jstree(true)
		let sel = ref.get_selected()
		if (!sel.length) { return false }
		sel = sel[0]
		ref.edit(sel)
	}

	public disableElement(template: TemplateRef<any>){
		if(this.selectedNodeId == 'rootId'){return false}
		// console.log("disableElement")
		let ref = $('#catFoldertree').jstree(true)
		let sel = ref.get_selected()
		if (!sel.length) { return false }
		sel = sel[0]
		this.dependency = template; 
		this.toDeleteViewDependency = false	
		this.getDependencies(this.selectedNodeId)	
	}

	private setSelectionList(catTypes: any){
		this.typesCount = Object.keys(catTypes).length / 2
		for(let i= 1; i <= this.typesCount; i++){
			this.categoryList.push(
				{
					'id': i, 
					'text': catTypes[i]
				}
			)
		}
	}

	private reloadTree(){
		this.catList[this.selectedCategoryTypeId] = null
		// this.destroyTree()
		this.getSelectedCategoryType({text: this.selectedCategoryType, id: this.selectedCategoryTypeId})
	}
	
	private destroyTree(){
		$("#catFoldertree").jstree("destroy");
	}
	private deselectAll(){
		$("#catFoldertree").jstree("deselect_all");
	}

	private getCheckDescription(description: any){
		let txt = description.replace(/<\/?[^>]+(>|$)/g, "");
		// let txt2 = description.match(/<span [^>]+>([^<]+)<\/span>/)[1];
		return txt;
	}

	
	private renameCategory(data: any){
		// console.log("renameCategory data= "+JSON.stringify(data.node))
		console.log("renameCategory")
		let catName = data.node.text;
		let parentId = data.node.parent == "rootId" ? "0" : data.node.parent
		this.categorySettingService.renameCategory(catName, this.selectedNodeId , parentId, this.selectedCategoryType).subscribe(
			data => {
			console.log("renameCategory= "+ data.payload)
			if (data.payload == "SUCCESS") {
					this.reloadTree();
			}else{
				this.reloadTree();
			}
			},
			error => {
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
				this.reloadTree();
			}
			
		)
	}

	public addCategory(data: any) {
		console.log("addCategory")
		let catName = data.node.text;
		// let parentId = data.node.parent;
		let parentId = data.node.parent == "rootId" ? "0" : data.node.parent
		let path = this.selectedNodePath + " > "  + catName
		this.categorySettingService.addNewCategory(catName, data.node.id, parentId, this.selectedCategoryType, path).subscribe(
			data => {
			console.log("addCategory= " + data.payload);
			if (data.payload == "SUCCESS") {
				this.reloadTree();
			}else{
				this.reloadTree();
			}
			},
			error => {
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
				this.reloadTree();
			}			
		)
	}

	private deleteCategory(nodeId: string){
		console.log("deleteCategory")
		this.categorySettingService.deleteCategory(nodeId)
		.subscribe(
			data => {
			console.log("deleteCategory= " + data.payload);
			if (data.payload == "SUCCESS") {
				this.reloadTree();
			}else{
				this.reloadTree();
			}
			},
			error => {
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
				this.reloadTree();
			}	
		)
	}
	
	private disableCategoryStatus(newStatus: any){
		//Inactive, active
		console.log("disableCategoryStatus")
		if(this.selectedNodeId != null){
			this.categorySettingService.disableCategory(this.selectedNodeId, newStatus)
			.subscribe(
				data => {
				console.log("disableCategoryStatus= " + data.payload);
				if (data.payload == "SUCCESS") {
					this.reloadTree();
				}else{
					this.reloadTree();
				}
				},
				error => {
					this.alertService.clear()
					this.alertService.error(error.statusDescription)
					this.reloadTree();
				}	
			)
		}else{
			//nothing to do
		}	
	}

	private clearDependencyData(){
		this.dependCat = []
		this.dependSte = []
		this.depnedCheck = []
		this.dependenComp = []
		this.dependComplist = []
		this.dependComplist_2 =[]
		this.dependStelist =[]
		this.dependStelist_2 =[]
		this.depnedChecklist =[]
		this.depnedChecklist_2 =[]
		this.isDependSte =false
		this.isDependenCat =false
		this.isDependenComp = false
		this.isDepnedCheck = false
	}

	/**
     * enable/disable buttons or columns
     */
	private enableDisableButton() {
		this.checkCapabilities()
		this.enableAddCategory = this.createCategory
		this.enableModifyCategory = this.modifyCategory
		this.enableDisableCategory = this.disableCategory
		this.enableDeleteCategory = this.delCategory
	}

	/**
     * check the capabilities for given type
     */
	public checkCapabilities() {
		this.createCategory = this.capabilityService.isCapabilityAssigned("createCategory")
		this.modifyCategory = this.capabilityService.isCapabilityAssigned("modifyCategory")
		this.disableCategory = this.capabilityService.isCapabilityAssigned("disableCategory")
		this.delCategory = this.capabilityService.isCapabilityAssigned("deleteCategory")
	}
}
