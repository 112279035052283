import { CapabilityService } from 'src/app/util/capability.service';
import { Component, OnInit, EventEmitter, AfterContentInit } from '@angular/core';
import { SharedService } from 'src/app/util/shared.service';

@Component({
	selector: 'app-dictionary-sidebar',
	templateUrl: './dictionary-sidebar.component.html',
	styleUrls: ['./dictionary-sidebar.component.scss'],
	outputs: ['selectSideBarItem']
})
export class DictionarySidebarComponent implements OnInit, AfterContentInit {

	public selectedMenuItem: string = 'dictionary'
	selectSideBarItem = new EventEmitter<any>()

	// capabilities
	public addWord: boolean = false
	public queryWord: boolean = false

	// enable/disable buttons
	public enableAddWord: boolean = false
	public enableQeuryWord: boolean = false

	constructor(private sharedService: SharedService, private capabilityService: CapabilityService) { 
		this.enableSideBarItem()
	}

	ngOnInit() {
	}

	ngAfterContentInit(): void {
		//Called after ngOnInit when the component's or directive's content has been initialized.
		//Add 'implements AfterContentInit' to the class.
		this.sharedService.changeDictionaryComponentsRespond().subscribe(
			(option: any) => this.sideItemClick(option.componentName)
		)
	}

	public sideItemClick(selectedItem: string) {
		console.log(selectedItem);
		this.selectedMenuItem = selectedItem
		this.selectSideBarItem.emit(
			{
				selectedItem: selectedItem
			}
		)
	}

	 /**
     * enable/disable buttons or columns
     */
	private enableSideBarItem() {
		this.checkCapabilities()
		this.enableAddWord = this.addWord
		this.enableQeuryWord = this.queryWord
	}

	 /**
     * enable/disable buttons or columns
     */
	public checkCapabilities() {
		let addWordCapability: string = "addWord"
		let queryWordCapability: string = "queryWord"
		this.addWord = this.capabilityService.isCapabilityAssigned(addWordCapability)
		this.queryWord = this.capabilityService.isCapabilityAssigned(queryWordCapability)
	}

}
