import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';

@Component({
  selector: 'app-button-renderer',
  template: `
  <button *ngIf= "(params.data.itemType == 'Course' || params.data.itemType == 'Topic Menu' || params.data.itemType == 'Bullet' || params.data.itemType == 'Learning Check') && params.data.actions.RefID != undefined" type="button" (click)="onClick($event)" class="btn btn-sm btn-outline-dark py-0 my-1 px-1 border-0"><i class="fas fa-window-maximize"></i></button>
  <button *ngIf= "params.data.itemType == 'Game' && params.data.actions.Type != 'GameWAMG' && params.data.actions.RefID != undefined" type="button" (click)="onClick($event)" class="btn btn-sm btn-outline-dark py-0 my-1 px-1 border-0"><i class="fas fa-window-maximize"></i></button>
    `
})

export class CoursePreviewButtonRenderer  implements ICellRendererAngularComp {
    params;
    label: string;
  
    agInit(params): void {
      this.params = params;
      this.label = this.params.label || null;
    }
  
    refresh(params?: any): boolean {
      return true;
    }
  
    onClick($event) {
      if (this.params.onClick instanceof Function) {
        // put anything into params u want pass into parents component
        const params = {
          event: $event,
          rowData: this.params.node.data
          // ...something
        }
        this.params.onClick(params);
      }
    }
}
