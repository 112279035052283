import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorage } from '../../../util/localstorage.service';
import { TranslateService } from '@ngx-translate/core';
import { UserVariable } from '../../../util/common/user-variable';
import { AlertService } from '../../../util/alert/alert.service';
import { SharedService } from '../../../util/shared.service';
import { GridOptions, Module, AllModules } from "@ag-grid-enterprise/all-modules";
import { ProcedureStoreService } from '../procedurestore.service';
import { CapabilityService } from '../../../util/capability.service'
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PSDeleteButtonRender } from '../../procedurestore/cell-render/delete-button/delete-button.component'
import * as FileSaver from 'file-saver';
import { ProcessStatusRender } from '../cell-render/processstatusRender/process-statusbutton.component'
import { LoadingService } from '../../../util/loading/loading.service';

@Component({
   selector: 'app-procedureinstances',
   templateUrl: './procedureinstances.component.html',
   styleUrls: ['./procedureinstances.component.css']
})

export class ProcedureInstancesComponent implements OnInit {
   public systemList: Array<any> = []
   public selectedSystem: Array<any> = [{ 'id': 'ALL', 'text': 'ALL' }]
   public isMinimized: boolean = false
   rowData: any[] = [];
   private gridApi;
   private gridColumnApi;
   columnDefs;
   ps_translated_data: any = null;
   public frameworkComponents;
   public gridOptions: GridOptions;
   public defaultColDef: any;
   private projectId: string = UserVariable.projectId
   private selectedStatusId: string = "10"
   public modules: Module[] = AllModules;

   constructor(private procedureStoreService: ProcedureStoreService, private sharedService: SharedService, private translate: TranslateService, private alertService: AlertService,
      private capabilityService: CapabilityService, private modalService: BsModalService, private router: Router, private loadingService: LoadingService) {
      let browserLang = translate.getBrowserLang();
      this.gridOptions = <GridOptions>{
         context: {
            componentParent: this
         }
      };
      this.defaultColDef = {
			resizable: true,
			sortable: true,
		};
      translate.getTranslation(browserLang).subscribe((res: string) => {
         this.ps_translated_data = res['PROCEDURE_STORE']
         this.systemList = [{ 'id': '10', 'text': 'ALL' },
         { 'id': '0', 'text': this.ps_translated_data.NEW },
         { 'id': '1', 'text': this.ps_translated_data.PROCESSING },         
         { 'id': '3', 'text': this.ps_translated_data.SUCCESSFULLY_COMPLETED },
         { 'id': '4', 'text': this.ps_translated_data.PROCESSING_FAILED },
         { 'id': '5', 'text': this.ps_translated_data.OUTPUT_EMAILED },
         { 'id': '6', 'text': this.ps_translated_data.EMAIL_SENDING_FAILED },
         ]
      });

      translate.getTranslation(browserLang).subscribe((res: string) => {
         this.ps_translated_data = res['PROCEDURE_STORE']
         this.columnDefs = [
            {
               headerName: this.ps_translated_data.DOCUMENT_NAME, field: 'documentName', autoHeight: true, cellClass: "cell-wrap-text"
            },
            {
               headerName: this.ps_translated_data.ADDED_DATE, field: 'addedTime', autoHeight: true, cellClass: "cell-wrap-text"
            },
            {
               headerName: this.ps_translated_data.INITIATED_USER, field: 'initiatedUserFullName', autoHeight: true, cellClass: "cell-wrap-text"
            },
            {
               headerName: this.ps_translated_data.OUTPUT_FILE, field: 'prcStatus', autoHeight: true, cellClass: "cell-wrap-text", width: 85, suppressFiltersToolPanel: true,
               cellRenderer: function (params) {
                  var eDiv = document.createElement('div');
                  if (params.data.prcStatus == 3 || params.data.prcStatus == 5 || params.data.prcStatus == 6 || params.data.prcStatus == 7) {
                     eDiv.innerHTML = '<button class="btn btn-sm btn-outline-dark py-0 my-1 px-0 border-0">' +
								'<i class="far fa-file-pdf mx-2" aria-hidden="true"></i>' +
								'</button>';
							var eButton = eDiv.querySelectorAll('.btn-sm')[0];

							eButton.addEventListener('click', function () {
                        loadingService.showLoading(true, null, "", null)
                        procedureStoreService.downloadFile(params.data.id)
									.subscribe(response => {
                              let file = new Blob([response], { type: 'application/pdf' });
                              FileSaver.saveAs(file, params.data.documentName + ".pdf");
                              loadingService.hideLoading()
									},
									error => {
                              loadingService.hideLoading()
										alertService.error(this.fa_translated_data.ERR_DOWNLOAD_FILE)
									});
                     });
                  }else{
                     eDiv.innerHTML = '';
                  }
                  return eDiv;
               }
            },
            {
               headerName: this.ps_translated_data.STATUS, field: 'prcStatus', autoHeight: true, cellClass: "cell-wrap-text", 
               cellRendererFramework:ProcessStatusRender
            }
         ]

      });
   }


   ngOnInit() {

   }

   onColumnResized(event) {
      if (event.finished) {
         this.gridApi.resetRowHeights();
      }
   }

   onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      params.api.sizeColumnsToFit();
      this.getProcedureInstanceViewData();
      setTimeout(function () {
         params.api.sizeColumnsToFit();
         params.api.resetRowHeights();
      }, 500);
   }

   removedSystem(event) {
      this.selectedSystem = [{ 'id': 'ALL', 'text': 'ALL' }]
      this.getProcedureInstanceViewData()
   }

   selectedSystemId(event) {
      this.selectedStatusId = event.id
      this.getProcedureInstanceViewData()
   }

   getProcedureInstanceViewData() {
      this.loadingService.showLoading(true, null, "", null)
      this.rowData = []
      this.procedureStoreService.getProcedureInstancesSearchResults(this.selectedStatusId)
         .subscribe(data => {
            this.loadingService.hideLoading()
            this.rowData = data.payload;
            this.gridApi.sizeColumnsToFit();
         },
         error => {
            this.loadingService.hideLoading()
            this.alertService.clear()
            this.alertService.error(error.statusDescription)
         });
   }

   
}