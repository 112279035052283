import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ColDef, GridOptions, Module } from '@ag-grid-community/core';
import { TranslateService } from '@ngx-translate/core';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { Constants } from 'src/app/util/constants';
import { LoadingService } from 'src/app/util/loading/loading.service';
import { AlertService } from 'src/app/util/alert/alert.service';
import { ManagePCCService } from './managepcc.service';
import { ManagePCCCellRendererComponent } from './managepcc-cell-renderer.component';
import { FilesDataService } from 'src/app/home/files/filesdata/filesdata.service';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { OutputGenerateModalComponent } from 'src/app/home/files/outputgenerator/outputgeneratemodal.component';
import { SharedService } from 'src/app/util/shared.service';
import { PCCDocusignCellRendererComponent } from './pcc-docusign-cell-renderer/pcc-docusign-cell-renderer.component';
import { WsResponse } from 'src/app/util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';

@Component({
  selector: 'manage-pcc',
  templateUrl: './managepcc.component.html',
  styleUrls: ['./managepcc.component.css']
})
export class ManagePCCComponent implements OnInit {
  @ViewChild("incompletePCCModal") incompletePCCModal: TemplateRef<any>;
  public incompleteModalRef: BsModalRef;
  
  public columnDefs: any;
  public rowData: any;
  public gridOptions: GridOptions;
  public overlayLoadingTemplate: any;
  public overlayNoRowsTemplate: any;
  public getRowNodeId: any;
  public modules: Module[] = AllModules;
  public gridApi: any;
  private gridColumnApi: any;
  generateOut: boolean = false;
  private systemSearch: any;
  private pccStatusSearch: any;
  private targetCompletionDateSearch: any;
  private hasText: any;
  private checkingPCCNotIssuedList: any = [];
  private selectedOutputType: any = []
  public pcListWithIncompleteItems: any = [];
  private bsModalRef: BsModalRef;

  constructor(private translate: TranslateService, private loadingService: LoadingService, private alertService: AlertService,
    private managePCCService: ManagePCCService, private filesDataService: FilesDataService, private modalService: BsModalService,
    private sharedService: SharedService) {
    translate.addLangs(["es", "en"])
    translate.setDefaultLang("en");
    let browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
    this.columnDefs = [
      {
        headerName: this.translate.instant('PCC_SEARCH_VIEW.SYSTEM'),
        field: 'system',
        autoHeight: true,
        cellClass: "cell-wrap-text",
        minWidth: 250,
        sortable: true
      },
      {
        headerName: this.translate.instant('PCC_SEARCH_VIEW.PCC_STATUS'),
        field: 'pcStatus',
        autoHeight: true,
        cellClass: "cell-wrap-text",
        minWidth: 150,
        sortable: true
      },
      {
        headerName: this.translate.instant('PCC_SEARCH_VIEW.TERGETCOMPLETIONDATE'),
        field: 'targetCompletionDate',
        autoHeight: true,
        cellClass: "cell-wrap-text",
        minWidth: 150,
        sortable: true
      },
      {
        headerName: this.translate.instant('PCC_SEARCH_VIEW.EDIT'),
        field: "edit",
        minWidth: 75,
        //hide:!this.enableEditPCC,
        cellStyle: { 'text-align': 'center' },
        cellRendererFramework: ManagePCCCellRendererComponent,
      },
      {
        headerName: this.translate.instant('PCC_SEARCH_VIEW.WORKFLOW_STATUS'),
        field: 'docusignStatus',
        autoHeight: true,
        cellClass: "cell-wrap-text",
        minWidth: 125,
        cellRendererFramework: PCCDocusignCellRendererComponent
      },
      {
        headerName: this.translate.instant('PCC_SEARCH_VIEW.DELETE'),
        field: "delete",
        minWidth: 75,
        //hide:!this.enableDeletePCC,
        cellStyle: { 'text-align': 'center' },
        cellRendererFramework: ManagePCCCellRendererComponent,
      },
      {
        headerName: this.translate.instant('PCC_SEARCH_VIEW.APPROVE'),
        field: "approve",
        minWidth: 75,
        //hide:!this.disableCap,
        cellStyle: { 'text-align': 'center' },
        cellRendererFramework: ManagePCCCellRendererComponent,
      },
      {
        headerName: this.translate.instant('PCC_SEARCH_VIEW.SENT_PDF'),
        field: "sentpdf",
        minWidth: 75,
        //hide:!this.disableCap,
        cellStyle: { 'text-align': 'center' },
        cellRendererFramework: ManagePCCCellRendererComponent,
      },
      {
        headerName: this.translate.instant('PCC_SEARCH_VIEW.RETURNED_PDF'),
        field: "returnedpdf",
        minWidth: 75,
        //hide:!this.disableCap,
        cellStyle: { 'text-align': 'center' },
        cellRendererFramework: ManagePCCCellRendererComponent,
      }
    ];

    this.getRowNodeId = function (data) {
      return data.id;
    };

    this.gridOptions = {
      rowData: this.rowData,
      columnDefs: this.columnDefs,
      defaultColDef: {
        resizable: true,
        sortable: true
      },
      rowSelection: "single",
      rowMultiSelectWithClick: false,
      animateRows: true,
      rowBuffer: 20,
      context: {
        componentParent: this
      },
      paginationPageSize: 10,
      getRowStyle: (params) => {
        if (params.data.pcStatus == "OnQueue") {
          return { background: '#FFDD77' };
        } else if (params.data.pcStatus == "Issued") {
          return { background: '#FCE2BE' };
        } else if (params.data.pcStatus == "Approved") {
          return { background: '#DEDEDE' };
        } else if (params.data.pcStatus == "Error") {
          return { background: '#FCCDC4' };
        } else {
          return { background: '#ffffff' };
        }
      }
    };

    //custom layout templates for loading and empty data sets
    this.overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Loading data...</span>';
    this.overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">No rows to show</span>';
  }

  ngOnInit(): void {
    this.managePCCService.loadTableDataResponse().subscribe((option: any) => this.reloadData())
    this.managePCCService.updateUIAfterPCCGenerateResponse().subscribe((option: any) => this.updateUIAfterGenerateOutPut())
    this.managePCCService.updateUIafterApprovalResponse().subscribe((option: any) => this.updateUIAfterApprove(option.pccId, option.uploaded))
    this.managePCCService.updateUIafterDocumentUploadResponse().subscribe((option: any) => this.updateUIAfterDocumentUpload(option.pccId, option.uploaded));
    this.managePCCService.setPCCcomp(this);
  }

  onGridReady(params) {
    const allColumnIds = [];
    this.columnDefs.forEach((columnDef) => {
      allColumnIds.push(<ColDef>columnDef);
    });
    this.gridColumnApi = params.columnApi;
    this.gridApi = params.api;
    this.managePCCService.setApiGridData(this.gridApi);
    params.api.sizeColumnsToFit();
    // window.addEventListener("resize", function () {
    //   setTimeout(function () {
    //     params.api.sizeColumnsToFit();
    //   });
    // });
    window.addEventListener("resize", this.onWindowResize);
    this.gridApi.showNoRowsOverlay();
  }
  private onWindowResize = () => {
   
		setTimeout(() => {
			if (this.gridApi) {
				this.gridApi.sizeColumnsToFit();
			}
		}); 
	};

  /**
   * Search PCCs with multiple inputs
   */
  searchFunc(data: any) {
    this.systemSearch = "";
    this.pccStatusSearch = "";
    this.targetCompletionDateSearch = "";
    this.hasText = "";
    this.generateOut = false;

    var systemSearch = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_PCC_SYSTEM];
    if (systemSearch != undefined) {
      this.systemSearch = systemSearch
    } else {
      //do nothing
    }

    var pccStatusSearch = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_PCC_STATUS];
    if (pccStatusSearch != undefined) {
      this.pccStatusSearch = pccStatusSearch
    } else {
      //do nothing
    }

    var targetCompletionDateSearch = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_PCC_COMPLETION_DATE];
    if (targetCompletionDateSearch != undefined) {
      this.targetCompletionDateSearch = targetCompletionDateSearch
    } else {
      //do nothing
    }

    var hasText = data.searchWordMap[Constants.SEARCH_KEY_WORDS.SEARCH_HAS_THE_WORDS];
    if (hasText != undefined) {
      this.hasText = hasText;
    } else {
      //no has text
    }

    if (this.systemSearch != "" || this.pccStatusSearch != "" || this.targetCompletionDateSearch != "" || this.hasText != "") {
      let to: any = false;
      let data = {}
      data = {
        pccSystems: this.systemSearch,
        pccStatuses: this.pccStatusSearch,
        pccTargetComDates: this.targetCompletionDateSearch,
        hasText: this.hasText,
      }
      var json = JSON.stringify(data);
      if (to) { clearTimeout(to); }
      this.loadingService.showLoading(true, null, "Searching", null);
      to = setTimeout(() => {
        this.managePCCService.searchPCCList(json).subscribe(
          data => {
            this.setDocumentSearchTableData(data.payload)
            console.log(data.payload);
          },
          error => {
            this.alertService.clear()
            this.alertService.error(error.statusDescription)
          },
          () => {
            this.loadingService.hideLoading()
          });
      }, 200);
    } else {
      this.rowData = [];
      this.gridApi.hideOverlay();
    }
  }

  /**
   * Add search result into the grid
   */
  private setDocumentSearchTableData(payload: any) {
    this.gridApi.hideOverlay();
    this.rowData = [];
    this.generateOut = false;
    if (payload != null) {
      for (let i = 0; i < payload.length; i++) {
        this.rowData.push({
          pcId: payload[i]["pcId"],
          systemId: payload[i]["systemId"],
          system: payload[i]["systemNo"] + " " + payload[i]["systemName"],
          pcStatus: payload[i]["pcstatus"],
          targetCompletionDate: this.getDateFromTimestampDisplay(payload[i]["targetCompletionDate"]),
          docuSignId: payload[i]["docuSignId"],
          docusignStatus: payload[i]["docuSignStatus"],
          edit: "edit:" + payload[i]["pcstatus"],
          delete: "delete:" + payload[i]["pcstatus"],
          approve: "approve:" + payload[i]["pcstatus"]+ ":" + payload[i]["isUploaded"],
          sentpdf: "sentpdf:" + payload[i]["pcstatus"],
          returnedpdf: "returnedpdf:" + payload[i]["pcstatus"] + ":" + payload[i]["isUploaded"],
          allChecksheetsCompleted: payload[i]["allChecksheetsCompleted"],
          allPunchlistsCompleted: payload[i]["allPunchlistsCompleted"]
        })
      }
      this.gridOptions.columnApi.getColumn('system').setSort("asc");
      this.gridApi.paginationProxy.currentPage = 0;
      this.gridOptions.api.sizeColumnsToFit();

      if(this.rowData.length > 0){
        //Check whether there is atleast one item which status is NotIssued and enable the generate button accordingly
        this.rowData.forEach(element => {
          if(element.pcStatus == "NotIssued"){
            this.generateOut = true;
          }
        });
      } else{
        this.generateOut = false;
      } 
    } else {
      //do nothing
    }
  }

  generateOutputClick(){
    this.checkingPCCNotIssuedList = [];
    this.pcListWithIncompleteItems = [];
    this.rowData.forEach(element => {
      if(element.pcStatus == "NotIssued"){
        this.checkingPCCNotIssuedList.push({pcId: element.pcId})
      } else{
        //do nothing
      }

      if(element.pcStatus == "NotIssued" && (!element.allChecksheetsCompleted || !element.allPunchlistsCompleted)){
        this.pcListWithIncompleteItems.push({pcId: element.pcId, systemId: element.systemId, systemName: element.system})
      } else{
        //do nothing
      }
    });

    if(this.checkingPCCNotIssuedList.length > 0){
      if(this.pcListWithIncompleteItems.length > 0){
        this.pcListWithIncompleteItems = this.sortBySystemName(this.pcListWithIncompleteItems);
        this.incompleteModalRef = this.modalService.show(this.incompletePCCModal, { class: 'modal-lg' })
      } else{
        this.generateOutput();
      }
    } else{
      //do nothing
    }
  }

  continue(){
    this.incompleteModalRef.hide();
    this.generateOutput();
  }

  cancelOutGenerete(){
    this.incompleteModalRef.hide();
  }

  /**
     * Generate the precommissioning completion certificate
     */
    public generateOutput() {
      let ids = [];
      this.checkingPCCNotIssuedList.forEach((item) => {
        ids.push(item.pcId);
      })
      let type = "Static Completion";
      this.loadingService.showLoading(true, null, "", null);
      this.filesDataService.getOutputTypeListByProjectIdDocType(type)
        .subscribe(data => {
          this.loadingService.hideLoading()
          this.selectedOutputType = []
          if (data.payload != null && data.payload.length > 0) {
            data.payload.forEach(element => {
              this.selectedOutputType.push({ 'id': element, 'text': element })
            });
            const initialState: object = {
              selectedOutputType: this.selectedOutputType,
              outputData: "",
              idList: ids,
              type: type,
              outputType: this.selectedOutputType[0].text,
              outputId: this.selectedOutputType[0].id,
              showTable: false
            }
            this.bsModalRef = this.modalService.show(OutputGenerateModalComponent, { class: 'modal-lg', initialState });
  
          } else {
            this.alertService.warn("Please add the Static Completion template into this project");
          }
        },
          error => {
            this.loadingService.hideLoading()
            this.alertService.clear()
            this.alertService.error(error.statusDescription)
          });
    }

    public ngOnDestroy() {
      this.removeSelectedSearchPCCTagInputs();
      window.removeEventListener("resize", this.onWindowResize);
      this.gridApi.destroy();
      this.gridApi = null;
    }

  /**
  * Clear taginput items
  */
  removeSelectedSearchPCCTagInputs(){
    this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.SEARCH_PCC_SYSTEM, []);
    this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.SEARCH_PCC_STATUS, []);
    this.sharedService.setTagInputList(Constants.SEARCH_KEY_WORDS.SEARCH_PCC_COMPLETION_DATE, []);
  }

  /**
	 * sort data alphabetically
	 * @param array 
	 */
	private sortBySystemName(array: any): any {
		if (array != null && array.length != 0) {
			array.sort((a, b) => {
				if (a.systemName.toLowerCase() > b.systemName.toLowerCase()) {
					return 1;
				} else if (a.systemName.toLowerCase() < b.systemName.toLowerCase()) {
					return -1;
				} else {
					return 0
				}
			});
			return array
		} else {
			return array
		}
  }
  
  /**
   * Convert the target completion date from string to Date
   */
  private getDateFromTimestampDisplay(targetCompletionDate: string) {
    if(targetCompletionDate != null){
      return new Date(targetCompletionDate).toLocaleDateString();
    } else{
      return null;
    }
  }

  reloadData(){
    this.fireEvent("mainsearchinput", "click");
  }

  private fireEvent( ElementId, EventName ) {
    if( document.getElementById(ElementId) != null ) {
      const ke = new KeyboardEvent("keyup", {
        bubbles: true, cancelable: true, key:"Enter"
      });
      document.getElementById( ElementId ).dispatchEvent( ke );
    }
  }

  updateUIAfterGenerateOutPut(){
    this.generateOut = false;
    if(this.rowData.length > 0 ){
      this.rowData.forEach((row) => {
        if(row.pcStatus == "NotIssued" ){
          row.pcStatus = "OnQueue";
          row.approve= "approve:OnQueue"
          row.edit= "edit:OnQueue"
          row.delete= "delete:OnQueue"
          row.sentpdf= "sentpdf:OnQueue"
          row.returnedpdf= "returnedpdf:OnQueue:false"
        }
      })
    }
    this.gridApi.setRowData(this.rowData);
  }

  updateUIAfterApprove(pcId, uploaded){
    let itemIndex = this.rowData.findIndex(item => item.pcId == pcId);
    if (this.rowData[itemIndex]) {
      this.rowData[itemIndex].pcStatus = "Approved";
      this.rowData[itemIndex].approve= "approve:Approved:"+uploaded
      this.rowData[itemIndex].edit= "edit:Approved"
      this.rowData[itemIndex].delete= "delete:Approved"
      this.rowData[itemIndex].sentpdf= "sentpdf:Approved"
      this.rowData[itemIndex].returnedpdf= "returnedpdf:Approved:"+uploaded
      this.gridApi.setRowData(this.rowData);
    }
  }

  updateUIAfterDocumentUpload(pcId, uploaded){
    let itemIndex = this.rowData.findIndex(item => item.pcId == pcId);
    if (this.rowData[itemIndex]) {
      this.rowData[itemIndex].approve= "approve:"+ this.rowData[itemIndex].pcStatus +":"+uploaded
      this.rowData[itemIndex].returnedpdf= "returnedpdf:"+ this.rowData[itemIndex].pcStatus +":"+uploaded
      this.gridApi.setRowData(this.rowData);
    }
  }

  onSuccess(data: WsResponse, type: WsType){
    if(type == WsType.REVOKE_PCC){
        this.loadingService.hideLoading();
        this.alertService.success("Successfully revoked the precommissioning");
        this.reloadData();
    }
}

onFail(error: WsResponse, type: WsType){
    if(type == WsType.REVOKE_PCC){
        this.loadingService.hideLoading()
        this.alertService.clear()
        this.alertService.error(error.statusDescription);
    }
}
}