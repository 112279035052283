import { Component, OnInit, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { AgGridAngular } from '@ag-grid-community/angular';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/util/alert/alert.service';
import { TagsService } from '../tags.service';
import { WsCallback } from 'src/app/util/ws-callback.interface';
import { WsResponse } from 'src/app/util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';
import { UserVariable } from 'src/app/util/common/user-variable';
import { LoadingService } from 'src/app/util/loading/loading.service';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { SharedService } from 'src/app/util/shared.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DependenciesmodalComponent } from 'src/app/util/dependenciesmodal/dependenciesmodal.component';
import { Constants } from "src/app/util/constants";
import { TagDependencyComponent } from '../tag-dependency/tag-dependency.component';
import { DEPENDENCY_ACTION } from '../tag-util';
import { Observable, of } from 'rxjs';
import { filter } from'rxjs/operators';
import { CommonUtil } from 'src/app/util/common/common-util';
import { TagAttribute, TagAttributesMapping } from 'src/app/reusables/tags/edit-tags/change-tagclassification.model'
import { Module, AllModules } from "@ag-grid-enterprise/all-modules";
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';


@Component({
	selector: 'app-edit-tags',
	templateUrl: './edit-tags.component.html',
	styleUrls: ['./edit-tags.component.css']
})
export class EditTagsComponent implements OnInit, WsCallback {
	@ViewChild('occurrenceLabel') occurrenceLabel: ElementRef;
	@ViewChild('auto') matAutocomplete: MatAutocomplete;
	@ViewChild('edittags_facility') chipInput: ElementRef<HTMLInputElement>;
	@ViewChild('changingTagClassificationTemplate') tagAttributesTemplate: TemplateRef<any>;
	
	public showSC: boolean = false;
	public showFC: boolean = false;
	public rowCount: number = 4;
	public caretPos: number = 0;
	public tagId: any;
	private token: any;
	//location parent
	public enableMore: boolean = false;
	rowData: any = [];
	@ViewChild('agGrid') agGrid: AgGridAngular;
	public modules: Module[] = AllModules;
	public defaultColDef: any;
	private columnDefs = [];
	private tagNo: string;
	public numberAttr: boolean = false;
	private tagNoNew: string;
	private cadNoNew: string;
	private descriptionNew: string;
	private description: string;
	private locationParentId: string;
	public allLocationParent = [];
	public filteredLocationParent = [];
	public selectedLocationParentRaw = [];
	//initial variable
	public tagNumber: string = '';
	public cadNo: string = '';
	public tagDescription: string = '';
	public equipmentDescription: string = '';
	public locationDescription: string = '';
	public projectName: string = '';
	//data sets
	public tagModel: any = {};
	public tagDescriptionJSON: any = {};
	public assetAttributeList = [];
	public tagAttributes = [];
	private systems = [];
	public documents = [];
	public documentListAll = [];
	private components = [];
	public facilityList = [];
	public selectedFacilityList = [];
	public systemList = [];
	public systemsWithoutFacilityList = [];
	public selectedSystemList = [];
	public selectedDocumentList = [];
	public tagClassList = [];
	public allTagClassList = [];
	public tagAttrClassList = [];
	public selectedTagClassList = [];
	public attributeModelList = [];
	public dataSheetList = [];
	public dataSheetListAll = [];
	public selectedDataSheetList = [];
	public locationParent = [];
	public currentLocationParentId;
	public selectedLocationParent = [];
	public failureClassList = [];
	public selectedFailureClasses = [];
	public criticalityList = [];
	public selectedCriticalityList = [];
	public safetyCriticalityList = [];
	public selectedSafetyCriticalityList = [];
	public disciplineList = [];
	public selectedDisciplineList = [];
	public equipmentTypeList = [];
	public selectedEquipmentTypeList = [];
	public assetGroupsList = [];
	public selectedAssetGroupsList = [];
	public csFunctionCodeList = [];
	public selectedCsFunctionCodeList = [];
	public vendorList = [];
	public selectedVendorList = [];
	public valueList = [];
	public activeAllAssestList = [];
	public assetParentList = [];
	public selectedParentList = [];
	public selectedFacility;
	public allSystems = [];
	//outputs
	public occurrenceText: string;
	public occurrenceCheckIn: string;
	public occurrenceCheckOut: string;

	//For attributes drop-downs
	public assetAttributeSelectionList =[]
	public assetAttributes = [];
	public valueListValues = [];
	private selectedAttributeComponentList: any = [];
	private selectedAttributeSystemList: any = [];
	private selectedAttributeDocumentList: any = [];

	//list
	public systemListForNgSelect: Array<any> = [];
	//public selectedSystemOptions: Array<any> = [];
	public componentListForNgSelect: Array<any> = [];
	//public selectedComponentOptions: Array<any> = [];
	public documentListForNgSelect: Array<any> = [];
    //public selectedDocumentOptions = [];
    public selectedTagAttributeValue: Array<any> = [];
	public selectedValueListOptions = [];
	public selectedAssetOptions = [];
	public valueListNgSelect = [];
	public assetListNgSelect = [];
	public textTypeValue: any;

	//Functional location
	private searchText: string = '';
	public hasFunctionalLocation: boolean;
	public autoGenerateEquipNo: boolean;
	public usedInCMMSTrac: boolean = false;
	public isUsedCmmsTracking: boolean = false;
	public hasEquipmentNumber:boolean = false;
	private columnDefsFunctionalLocation = [];
	rowDataFunctionalLocation: any = [];
	public enableMoreFunctional: boolean = false;
	public suggestionListMore: any[] = [];
	public selectedFunctionalLocationRaw = [];
	public selectedIndex: number;
	public isEquipmentNoCheckboxSelected:boolean = false;

	public selectedAssetAttributes = [];
	public selectedAttributes = [];
	// this array will be using to store attribute values when changed the previously saved classification
	public selectedChangedAttributes = []; 

	//alternate tag search
	public suggestionOriginalList: any[] = [];
	public suggestionList: any[] = [];
	public tagNoCount: number = 0;
	public addedSuggestionList: Array<Array<any>> = [];
	public enableDisableItems: boolean[] = new Array(15).fill(false);
	public enable: boolean[] = new Array(15).fill(true);
	public calculatedFunctionalLocationValue: string = '';
	//public textValues: string;
	public alternateTagNo: string;
	public nextAvailableEquipmentNo: string;
	public tagAddPreConditions: any = [];
	public cmmsFunctionalLocationInfo: any[] = [];
	//public oldFunctionalLocations: Array<Array<any>> = [];

	public otnTargetCompletionDate: any = null;
	public irnTargetCompletionDate:any= null;
	public bsConfig: Partial<BsDatepickerConfig>;

	// Reusable Details
	private reusablesNo: string;
	private reusablesNoModifiedBy: string;
	private reusablesNoModifiedTime:string;
	private reusablesDescription: string;
	private reusablesDescModifiedBy: string;
	private reusablesDescModifiedTime: string;
	private reusablesDocument: string;
	private reusablesDocModifiedBy: string;
	private reusablesDocModifiedTime: string;
	private reusablesSystem: string;
	private reusablesSysModifiedBy: string;
	private reusablesSysModifiedTime: string;

	public numerator;
	public denominator;

	//field Dependency Count Deta
	public fieldDependencyCountTagNumber: string;
	public fieldDependencyCountTagDescription: string;
	public fieldDependencyCountAlternateTagNo: string;
	public fieldDependencyCountTagDocument: string;
	public fieldDependencyCountTagFacility: string;
	public fieldDependencyCountTagSystem: string;

	public classIsAlreadyAdded: boolean = false;
	
	//tag classification change data
	public oldTagAttributes: Array<TagAttribute> = [];
	public newTagAttributes: Array<TagAttribute> = [];
	public tagAttributesMapped: Array<TagAttributesMapping> = [];
	public tagAttributesToBeDeleted: Array<TagAttribute> = [];
	public selectedOldTagAttributeValue: Array<any> = [];

	constructor(private translate: TranslateService, private route: ActivatedRoute, private alertService: AlertService,
		 private tagService: TagsService, private loadingService: LoadingService, private sharedService: SharedService,
		 private bsModalService: BsModalService, public bsModalRef: BsModalRef, public bsModalRefLocationParent: BsModalRef, 
		 public bsModalRefFunctionalLocation: BsModalRef, public bsModalRefDependencyView: BsModalRef, 
		 public bsModalRefTagAttributes: BsModalRef, private commonUtil: CommonUtil) {
		translate.addLangs(["es", "en"]);
		translate.setDefaultLang("en");
		let browserLang = translate.getBrowserLang();
		translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
		this.translate.get('TAG_ADD.ALTERNATE_TAG_NO').subscribe((res: string) => {
			this.alternateTagNo = res;
			this.translate.get('TAG_ADD.TAG_NO').subscribe((res: string) => {
				this.tagNo = res;	
				this.translate.get('TAG_ADD.DESCRIPTION').subscribe((res: string) => {
					this.description = res;				
				});			
			});
		});

		this.columnDefs = [	
			{ headerName: this.tagNo, field: "tagNo", width: 150 },
			{ headerName: this.description, field: "description", width: 520 }			
		];

		this.defaultColDef = {
			resizable: true,
			sortable: true,
		};

		this.columnDefsFunctionalLocation = [	
			{ headerName: this.alternateTagNo, field: "alternateTagNo", width: 170 },
			{ headerName: this.tagNo, field: "tagNo", width: 150 },
			{ headerName: this.description, field: "description", width: 420 }			
		];
		this.bsConfig = Object.assign({}, { containerClass: 'theme-default' });
	}

	ngOnInit() {
		this.loadingService.showLoading(true, false, 'Loading', 0);
		//remove this due to use modals
		// this.route.paramMap.subscribe(params => {
		// 	if (params.get('tagId') != null) {
		// 		this.tagId = params.get('tagId');
		// 		this.token = UserVariable.getUserToken();
		// 		let request: any = { 'tagId': this.tagId, 'userToken': this.token };
		// 		this.tagService.getTagByTagId(request, this);
		// 	} else {
		// 		this.alertService.error('tag id is not available');
		// 	}
		// });
		console.log('[EditTagsComponent] (itemId) ' + JSON.stringify(this.tagId));
		if(this.tagId != null) {
			this.token = UserVariable.getUserToken();
			if (!this.commonUtil.validateToken(this.token)) {
				return;
			}
			let request: any = { 'tagId': this.tagId, 'userToken': this.token };
			this.tagService.getTagByTagId(request, this);
		} else {
			this.alertService.error('tag id is not available');
		}

		this.tagService.getTagClassifications(this.token, this);
		this.tagService.getProject(this.token ,this);
		this.tagService.getTagAddPreConditions(this.token, this);
		// this.tagService.getAllTagSuggestionModelList(this.token, this.tagId, this);

		/*No need of calling this request since those details are already retrieved using getTagById*/
		//this.tagService.getSystemsByProjectId(this.token, this);

		/**
		 * get all the active tags which are marked as used in commissioning (to bind the Asset Parent list) 
		 * Issue - Two different queries running in V2 and V3 after selecting Asset Group to Populate Asset Parent Drop Down
		 *  Trello Card - https://trello.com/c/eUyEMcCb
		 * */
		this.tagService.getTagListByProjectIdUsedInCommissioning(this.token, this);
		this.tagService.getAllActiveAssetGroups(this.token, this);
	}

	onSuccess(data: WsResponse, serviceType: WsType) {
		if (serviceType == WsType.GET_TAG_CLASSIFICATION) {
			this.allTagClassList = data.payload;
		}
		if (serviceType == WsType.GET_TAG_BY_TAG_ID) {
			this.tagModel = data.payload.currentTagModel;
			this.assetAttributeList = data.payload.assetAttributeList;
			this.tagAttributes = data.payload.tagAttributes;
			this.systems = data.payload.systems;
			this.documents = data.payload.documents;
			this.documentListAll = data.payload.documentListAll;
			this.components = data.payload.components;
			this.facilityList = data.payload.facilityList;

			/*** 
			 * Display System Number and System Name in the drop down items
			 * Trello Card - https://trello.com/c/F9e5rKLy
			*/
			// if facilityId is Null or set systemList will be returned according to that.
			//this.systemList = data.payload.systemList;
			data.payload.systemList.forEach(system =>{
					this.systemList.push({"id":system.id, "systemNoandsystemName":system.systemNo + ' ' + system.systemName})
			});

			// all the systems in project
			this.allSystems = data.payload.systems;

			// all the systems with no facilities
			this.systemsWithoutFacilityList = data.payload.systemListAll;

			this.tagAttrClassList = data.payload.tagClassList;
			Array.prototype.push.apply(this.tagAttrClassList, this.allTagClassList);
			this.tagAttrClassList = this.getUnique(this.tagAttrClassList,'classId');
			this.tagClassList = this.tagAttrClassList;
			this.attributeModelList = data.payload.attributeModelList;
			this.tagDescriptionJSON = JSON.parse(this.tagModel.description);
			this.tagDescription = this.tagDescriptionJSON.tagdesc.txt;
			this.dataSheetList = data.payload.datasheetList;
			this.dataSheetListAll = data.payload.datasheetListAll;
			if (data.payload.locationParent != null) {
				this.locationParent[0] = data.payload.locationParent;
			}
			this.currentLocationParentId = data.payload.currentTagModel.locationParent;
			this.failureClassList = data.payload.failureClassList;
			this.criticalityList = data.payload.criticalityList;
			this.safetyCriticalityList = data.payload.safetycriticalList;
			this.disciplineList = data.payload.disciplineList;
			this.equipmentTypeList = data.payload.equipmentTypeList;
			this.usedInCMMSTrac = data.payload.usedInCMMSTrac;
			this.isUsedCmmsTracking = data.payload.isUsedCmmsTracking;
			this.assetGroupsList = data.payload.assetGroupsList;
			this.csFunctionCodeList = data.payload.csFunctionCodeList;
			this.vendorList = data.payload.vendorList;
			this.valueList = data.payload.valueList;
			this.activeAllAssestList = data.payload.activeAllAssestList;
			//this.assetParentList = data.payload.assetParentList;
			this.hasFunctionalLocation = data.payload.assetParentList;
			this.autoGenerateEquipNo = data.payload.autoGenerateEquipNo;
			//this.hasFunctionalLocation = data.payload.assetParentList;
			this.nextAvailableEquipmentNo = this.tagModel.equipmentNo;


			if (data.payload.otnTargetCompletionDate) {
				this.otnTargetCompletionDate = new Date(moment.utc(data.payload.otnTargetCompletionDate).format("M/D/YYYY"))
			}
			if (data.payload.irnTargetCompletionDate) {
				this.irnTargetCompletionDate = new Date(moment.utc(data.payload.irnTargetCompletionDate).format("M/D/YYYY"));
				
			}
			if(this.nextAvailableEquipmentNo == null || this.nextAvailableEquipmentNo == ""){
				this.hasEquipmentNumber = false;
				this.isEquipmentNoCheckboxSelected = false;
			}

			else{
				this.hasEquipmentNumber = true;
				this.isEquipmentNoCheckboxSelected = true;
			}
			this.alternateTagNo = this.tagModel.alternateTagNo;
			this.calculatedFunctionalLocationValue = this.tagModel.functionalLocation;
			this.cmmsFunctionalLocationInfo = data.payload.cmmsFunctionalLocationInfo;

			// Setting pending reusable changes details
			this.setReusableDetails();

			this.setFieldDependenctCount(this.tagModel.fieldDependencyCount);
			//sets values to addedSuggestionList.
			this.setInitialFunctionalLocations(data.payload.cmmsFunctionalLocationInfo);
			this.setFacility(this.tagModel.facilityId);
			if (this.tagModel.reusableChangeModelSystem != null) {
				this.setSystem(this.tagModel.reusableChangeModelSystem.newValue);
			} else {
				this.setSystem(this.tagModel.systemId);
			}
			if (this.tagModel.reusableChangeModelDocument != null) {
				this.setDocument(this.tagModel.reusableChangeModelDocument.newValue);
			} else {
				this.setDocument(this.tagModel.documentId);
			}			
			this.setDataSheet(this.tagModel.dataSheetId);
			this.setClassification(this.tagModel.classId);
			this.setFailureClass(data.payload.currentTagModel.failureClassId);
			this.setCriticality(data.payload.currentTagModel.criticalityId);
			this.setSafetyCriticality(data.payload.currentTagModel.safetyCriticalId);
			this.setDiscipline(data.payload.currentTagModel.disciplineId);
			this.setEquipmentType(data.payload.currentTagModel.equipmentTypeId);
			this.setAssetGroupsList(data.payload.currentTagModel.assetGroupId);
			this.setCsFunctionCodeList(data.payload.currentTagModel.functionCodeId);
			this.setVendor(data.payload.currentTagModel.vendorId);
			this.setAssetParent(data.payload.currentTagModel.assetParentId);

			// this.prepareNgSelectSystems();
			//  this.prepareNgSelectDocuments();
			// this.prepareNgSelectComponents();
			this.prepareTagAttributes();
			//this.prepareNgSelectAssetsAttributeAssets();
			this.prepareAssetAttributes();
			//Data for the save request
			this.setInitialSaveData();		
			this.setOldTagAttributes();
			this.prepareOldTagAttributes();
			this.loadingService.hideLoading();
			
		} else if (serviceType == WsType.UPDATE_TAG) {            
			setTimeout(() => {
				this.loadingService.hideLoading();
				//Comment this to remove updating UI and add user message
				/*this.tagService.setItemData(this.tagId, this.tagNoNew, this.cadNoNew, this.descriptionNew, this.selectedSystemList.length != 0 ? this.selectedSystemList[0].systemNoandsystemName : "",
				this.selectedDocumentList.length != 0 ? this.selectedDocumentList[0].documentName : null, this.selectedFacilityList.length != 0 ? this.selectedFacilityList[0].facilityName : null, this.selectedTagClassList.length != 0 ? this.selectedTagClassList[0].className : "");*/
				this.alertService.info(this.translate.instant("ADD_EDIT_TAG.EDIT_SUCCESSFULLY") +". " +this.translate.instant("REUSABLES.REFRESH_REUSABLE_TABLE"));
				// this.sharedService.docuSaveEventEmit()
				this.bsModalRef.hide();
			}, 6000);		
			
		} else if (serviceType == WsType.GET_ALL_TAG_SUGGESTION_MODEL_LIST) {
			this.allLocationParent = data.payload;
			if (this.allLocationParent.length > 10)	{
				this.enableMore = true;
			}
			this.createInitialParentList();
		} else if (serviceType == WsType.GET_PROJECT) {
			this.projectName = data.payload.projectName;
		} else if (serviceType == WsType.GET_NEXT_AVAILABLE_EQUIPMENT_NUMBER) {
			this.nextAvailableEquipmentNo = data.payload;
		} else if (serviceType == WsType.GET_TAG_ADD_PRE_CONDITIONS) {
			this.tagAddPreConditions = data.payload;
			console.log(JSON.stringify(this.tagAddPreConditions));
		}  else if (serviceType == WsType.SEARCH_ALTERNATE_TAG) {
			this.tagNoCount = data.payload.tagNoCount;
			this.suggestionOriginalList = data.payload.tagList;
			//this.suggestionList = data.payload.tagList;
			/*var list = data.payload.tagList;
			list.forEach(item => {
				this.suggestionOriginalList.push(item);
			});*/
			this.setSuggestionList();
		} else if (serviceType == WsType.GET_SYSTEMS_BY_FACILITY_ID) {
			/*** 
			 * Display System Number and System Name in the drop down items
			 * Trello Card - https://trello.com/c/F9e5rKLy
			*/
			//this.systemList = data.payload;
			data.payload.forEach(system =>{
					this.systemList.push({"id":system.id, "systemNoandsystemName":system.systemNo + ' ' + system.systemName})
			});
			//this.tagService.getDocumentsByFacilityId(this.token, this.selectedFacility.facilityId, this);
			//this.tagService.getDocuments(this.token, this.selectedFacility.facilityId, this);
			//this.loadingHandler.handle();
		}  else if (serviceType == WsType.GET_DOCUMENTS_BY_FACILITY_ID) {
			this.documents = data.payload;
		} else if (serviceType == WsType.GET_DATA_SHEETS_BY_FACILITY_ID) {
			this.dataSheetList = data.payload;
		}else if (serviceType == WsType.GET_SYSTEMS_BY_PROJECT_ID) {
			this.allSystems = data.payload;
			/*this.systemListForNgSelect = [];
			this.allSystems.forEach(system => {
				this.systemListForNgSelect.push({ "id": system.id, "text": system.systemName });
			});*/

			if(this.tagModel.facilityId == null && this.selectedFacilityList.length == 0){
				/*** 
			 	* Display System Number and System Name in the drop down items
			 	* Trello Card - https://trello.com/c/F9e5rKLy
				*/
				//this.systemList = data.payload;
				data.payload.forEach(system =>{
					this.systemList.push({"id":system.id, "systemNoandsystemName":system.systemNo + ' ' + system.systemName})
				});
			}
		
			//this.loadingHandler.handle();
		} else if (serviceType == WsType.GET_ATTRIBUTES_BY_CLASSIFICATION){
			this.tagAttributes = data.payload;
			// this.selectedComponentOptions = [];
			// this.selectedSystemOptions = [];
            // this.selectedDocumentOptions = [];
            this.selectedTagAttributeValue = [];
            // if (this.tagAttributes != null) {
            //     this.tagAttributes.forEach(element => {
            //         this.selectedAttributes[this.selectedAttributes.length] = { tagId: this.tagModel.tagId, tagClassSpecId: element.tagClsSpecId,
            //             classId: this.tagModel.classId, attributeId: element.attributeId, value: element.value, oldValue: element.value, required: element.required };
            //     });
			// }
			this.setClassificationEdit();
			this.prepareTagAttributes();
			this.setNewTagAttributes();
			this.loadingService.hideLoading();
			
			//load Attribute popup to show the old and new attibutes
			if(this.tagModel.classId != null && this.tagModel.classId != "" && this.oldTagAttributes.length > 0){
				this.bsModalRefTagAttributes = this.bsModalService.show(this.tagAttributesTemplate, { class: 'modal-lg', backdrop: 'static', keyboard : false });
			}
		}
		/**
		 * set all the active tags which are marked as used in commissioning
		 * Issue - Two different queries running in V2 and V3 after selecting Asset Group to Populate Asset Parent Drop Down
		 *  Trello Card - https://trello.com/c/eUyEMcCb
		 * */
		else if (serviceType == WsType.GET_TAG_BY_TAG_ID_USED_IN_COMMISSIONING) {
			if (data.payload != null) {
				data.payload.forEach(tag => {
					if(tag.tagId != this.tagId)
					this.assetParentList.push({ "id": tag.tagId, "tagNoandtagDescription": tag.tagNo + ' ' + tag.description.replace("<tagdesc><txt>", "").replace("</txt></tagdesc>","") });
				});
			}
		} else if (serviceType == WsType.GET_ALL_ACTIVE_ASSET_GROUPS) {
			this.assetGroupsList = data.payload;	
		} else if (serviceType == WsType.GET_ASSET_GROUP_ATTRIBUTES) {
			this.assetAttributeList = data.payload;
			this.assetAttributeSelectionList = [];
			if ((this.assetAttributeList != null) && (this.assetAttributeList != undefined)) {
				this.assetAttributeList.forEach((element, i) => {
                    this.assetAttributeSelectionList[i]  = {selectedDataId:[], assetListNgSelect: [], valueListNgSelect: []};
					if ((element.attributeType == "Asset") && (element.selectedDataId != '0') && (element.selectedDataId != null)) {
                        this.assetAttributeSelectionList[i] = {selectedDataId:[], attributeId:element.assetAttributeId, attributeType: "Asset", assetListNgSelect: []}
						this.tagService.getAssetAttributes(this.token, element.selectedDataId, element.assetAttributeId, this);
					}
					if ((element.attributeType == "ValueList") && (element.selectedDataId != '0') && (element.selectedDataId != null)) {
                        this.assetAttributeSelectionList[i] = {selectedDataId:[], attributeId:element.assetAttributeId, attributeType: "ValueList", valueListNgSelect: []}
						this.tagService.getValueListValues(this.token, element.selectedDataId, element.assetAttributeId, this);
                    }
					this.selectedAssetAttributes[this.selectedAssetAttributes.length] = {attributeId: element.assetAttributeId, value: null };
				});
			}
		} else if (serviceType == WsType.GET_ASSET_ATTRIBUTES) {
			this.assetListNgSelect = [];
			if (data.payload != null) {
				if (data.payload.assetList != null) {
					this.assetAttributes = data.payload.assetList;
					let arr = [];
					// this.assetAttributes.forEach(element => {
					// 	this.assetListNgSelect.push({ "id": element.assetId, "text": element.assetName });
					// });
					if ((this.assetAttributes[0] != null) && (this.assetAttributes[0] != undefined)) {
						this.assetAttributes.forEach(element => {
							arr.push({ "id": element.assetId, "text": element.assetName });
						});
					}
					for (let i = 0; i < this.assetAttributeSelectionList.length; i++) {
						if ((this.assetAttributeSelectionList != null) && (this.assetAttributeSelectionList != undefined)) {
							if (this.assetAttributeSelectionList[i].attributeType ==  "Asset") {
								if ((this.assetAttributes[0] != null) && (this.assetAttributes[0] != undefined)) {
									if (this.assetAttributeSelectionList[i].attributeId == data.payload.attributeId) {
										this.assetAttributeSelectionList[i].assetListNgSelect = arr;
										break;
									}
								}
							}
						}
					}
				}
			}
		} else if (serviceType == WsType.GET_VALUE_LIST_VALUES) {
			this.valueListNgSelect = [];
			if (data.payload != null) {
				if (data.payload.valueList != null) {
					this.valueListValues = data.payload.valueList;
					// this.valueListValues.forEach(element => {
					// 	this.valueListNgSelect.push({ "id": element.id, "text": element.valueListValue });
					// });
					let arr = []
					if ((this.valueListValues != null) && (this.valueListValues != undefined)) {
						this.valueListValues.forEach(element => {
							arr.push({ "id": element.id, "text": element.valueListValue });
						});
					}
					if ((this.assetAttributeSelectionList != null) && (this.assetAttributeSelectionList != undefined)) {
						for (let i = 0; i < this.assetAttributeSelectionList.length; i++) {
							if (this.assetAttributeSelectionList[i].attributeType ==  "ValueList") {
								if ((this.valueListValues[0] != null) && (this.valueListValues[0] != undefined)) {
									if (this.assetAttributeSelectionList[i].attributeId == data.payload.attributeId) {
										this.assetAttributeSelectionList[i].valueListNgSelect = arr;
										break;
									}
								}
							}
						}
					}
				}
			}
		}		
	}
	setFieldDependenctCount(fieldDependencyCount: any) {
		if (fieldDependencyCount.TagNumber == 0 || fieldDependencyCount.TagNumber == null) {
			this.fieldDependencyCountTagNumber = "0";
		} else {
			this.fieldDependencyCountTagNumber = fieldDependencyCount.TagNumber;
		}
		if (fieldDependencyCount.TagDescription == 0 || fieldDependencyCount.TagDescription == null) {
			this.fieldDependencyCountTagDescription = "0";
		} else {
			this.fieldDependencyCountTagDescription = fieldDependencyCount.TagDescription;
		}
		if (fieldDependencyCount.AlternateTagNo == 0 || fieldDependencyCount.AlternateTagNo == null) {
			this.fieldDependencyCountAlternateTagNo = "0";
		} else {
			this.fieldDependencyCountAlternateTagNo = fieldDependencyCount.AlternateTagNo;
		}
		if (fieldDependencyCount.TagDocument == 0 || fieldDependencyCount.TagDocument == null) {
			this.fieldDependencyCountTagDocument = "0";
		} else {
			this.fieldDependencyCountTagDocument = fieldDependencyCount.TagDocument;
		}
		if (fieldDependencyCount.TagSystem == 0 || fieldDependencyCount.TagSystem == null) {
			this.fieldDependencyCountTagSystem = "0";
		} else {
			this.fieldDependencyCountTagSystem = fieldDependencyCount.TagSystem;
		}
		if (fieldDependencyCount.TagFacility == 0 || fieldDependencyCount.TagFacility == null) {
			this.fieldDependencyCountTagFacility = "0";
		} else {
			this.fieldDependencyCountTagFacility = fieldDependencyCount.TagFacility;
		}
	}

	onFail(data: WsResponse, serviceType: WsType) {
		
		this.loadingService.hideLoading();
		
		if (serviceType == WsType.GET_TAG_BY_TAG_ID) {
			this.alertService.error(data.statusDescription);
		} else if (serviceType == WsType.UPDATE_TAG) {
			this.alertService.error(data.statusDescription);
		} else if (serviceType == WsType.GET_PROJECT) {
			this.alertService.error(data.statusDescription);
		}  else if (serviceType == WsType.GET_NEXT_AVAILABLE_EQUIPMENT_NUMBER) {
			this.alertService.error(data.statusDescription);
		} else if (serviceType == WsType.GET_TAG_ADD_PRE_CONDITIONS) {
			this.alertService.error(data.statusDescription);
		} else if (serviceType == WsType.SEARCH_ALTERNATE_TAG) {
			this.alertService.error(data.statusDescription);
		} else if(serviceType == WsType.GET_TAG_BY_TAG_ID_USED_IN_COMMISSIONING){
			this.alertService.info(data.statusDescription);
		} else if (serviceType == WsType.GET_ASSET_GROUP_ATTRIBUTES) {
			this.alertService.error(data.statusDescription);
		} else if (serviceType == WsType.GET_ASSET_ATTRIBUTES) {
			this.alertService.error(data.statusDescription);
		}
		this.loadingService.hideLoading();
		
	}

	setClassificationEdit() {
		//Tag attributes
        if (this.tagAttributes != null) {
            this.tagAttributes.forEach(element => {
                this.selectedAttributes[this.selectedAttributes.length] = { tagId: this.tagModel.tagId, tagClassSpecId: element.tagClsSpecId,
					classId: this.tagModel.classId, attributeId: element.attributeId, value: element.value, oldValue: element.value, 
					oldTagClassSpecId: element.oldTagClassSpecId, required: element.required };
				//Achini Randeni
				//set dependencyCount to 0 when changing the tag classification
				element.dependencyCount = '0';
			});
		}
	}

	setReusableDetails(){
		// Tag No.
		if(this.tagModel.hasPendingChange){
			if (this.tagModel.reusableChangeModel != null) {
				this.reusablesNo = "Old Value : " + this.tagModel.reusableChangeModel.oldValue;
				this.reusablesNoModifiedBy = "Modified By : " + this.tagModel.reusableChangeModel.changeInitiatedUserName;
				if (this.tagModel.reusableChangeModel.changeInitiatedTimeStampFormatted != null) {
					this.reusablesNoModifiedTime = "Modified Time : " + this.tagModel.reusableChangeModel.changeInitiatedTimeStampFormatted;
				}
				else{
					// nothing to do
				}
				this.tagModel.tagNo = this.tagModel.reusableChangeModel.newValue;
			} else {
				this.tagModel.hasPendingChange = false;
			}
		}

		// Tag Description
		if(this.tagModel.hasPendingChangeDescription){
			if (this.tagModel.reusableChangeModelDescription != null) {				

				var html = this.tagModel.reusableChangeModelDescription.oldValue;
				var div = document.createElement("div");
				div.innerHTML = html;

				this.reusablesDescription = "Old Value : " + div.textContent || div.innerText || "";
				this.reusablesDescModifiedBy = "Modified By : " + this.tagModel.reusableChangeModelDescription.changeInitiatedUserName;
				if (this.tagModel.reusableChangeModelDescription.changeInitiatedTimeStampFormatted != null) {
					this.reusablesDescModifiedTime = "Modified Time : " + this.tagModel.reusableChangeModelDescription.changeInitiatedTimeStampFormatted;
				}
				else{
					// nothing to do
				}
				html = this.tagModel.reusableChangeModelDescription.newValue;
				div.innerHTML = html;
				this.tagDescription = div.textContent || div.innerText || "";
			} else {
				this.tagModel.hasPendingChangeDescription = false;
			}
		}

		// System
		if(this.tagModel.hasPendingChangeSystem){
			if (this.tagModel.reusableChangeModelSystem != null) {
				this.reusablesSystem = "Old Value : " + this.tagModel.reusableChangeModelSystem.oldValueName;
				this.reusablesSysModifiedBy = "Modified By : " + this.tagModel.reusableChangeModelSystem.changeInitiatedUserName;
				if (this.tagModel.reusableChangeModelSystem.changeInitiatedTimeStampFormatted != null) {
					this.reusablesSysModifiedTime = "Modified Time : " + this.tagModel.reusableChangeModelSystem.changeInitiatedTimeStampFormatted;
				}
				else{
					// nothing to do
				}
				this.setSystem(this.tagModel.reusableChangeModelSystem.newValue);
			} else {
				this.tagModel.hasPendingChangeSystem = false;
			}
		}

		// Document
		if(this.tagModel.hasPendingChangeDocument){
			if (this.tagModel.reusableChangeModelDocument != null) {
				this.reusablesDocument = "Old Value : " + this.tagModel.reusableChangeModelDocument.oldValueName;
				this.reusablesDocModifiedBy = "Modified By : " + this.tagModel.reusableChangeModelDocument.changeInitiatedUserName;
				if (this.tagModel.reusableChangeModelDocument.changeInitiatedTimeStampFormatted != null) {
					this.reusablesDocModifiedTime = "Modified Time : " + this.tagModel.reusableChangeModelDocument.changeInitiatedTimeStampFormatted;
				}
				else{
					// nothing to do
				}
				this.setDocument(this.tagModel.reusableChangeModelDocument.newValue);
			} else {
				this.tagModel.hasPendingChangeDocument = false;
			}
		}

		console.dir(JSON.stringify(this.tagAttributes));
		// TagAttribute
		if(this.tagAttributes != null && this.tagAttributes.length > 0){
			let tagAttributeList = this.tagAttributes;
			tagAttributeList.forEach((tagAttribute, index) =>{
				//let index = tagAttributeList.findIndex(tagAttribute);
				if(tagAttribute.hasPendingChange){
					if (tagAttribute.reusableChangeModel != null) {
						tagAttribute.reusableOldValue = "Old Value : " + tagAttribute.reusableChangeModel.oldValueName;
						tagAttribute.reusableModifiedBy = "Modified By : " + tagAttribute.reusableChangeModel.changeInitiatedUserName;
						if (tagAttribute.reusableChangeModel != null) {
							tagAttribute.reusableModifiedTime = "Modified Time : " + tagAttribute.reusableChangeModel.changeInitiatedTimeStampFormatted;
						}
						else{
							// nothing to do
						}
						tagAttribute.value = tagAttribute.reusableChangeModel.newValue;
					} else {
						tagAttribute.hasPendingChangeDocument = false;
					}
				}
				else{
					// nothing to do
				}
				this.tagAttributes[index] = tagAttribute;
			});
			console.dir(JSON.stringify(this.tagAttributes));
		}
	}

	//for the save request
	setInitialSaveData() {
		//Tag attributes
        if (this.tagAttributes != null) {
            this.tagAttributes.forEach(element => {
                this.selectedAttributes[this.selectedAttributes.length] = { tagId: this.tagModel.tagId, tagClassSpecId: element.tagClsSpecId,
					classId: this.tagModel.classId, attributeId: element.attributeId, value: element.value, oldValue: element.value, 
					oldTagClassSpecId: element.oldTagClassSpecId, required: element.required };
            });
        }
		//Asset attributes
		if (this.assetAttributeList != null) {
			this.assetAttributeList.forEach(asset => {
				//need to fill this
				this.selectedAssetAttributes[this.selectedAssetAttributes.length] = {attributeId: asset.assetAttributeId, value: asset.attributeValue };
			});
		}

		//Location Parent
		if (this.locationParent != null) {
			this.locationParent.forEach(parent => {
				if (parent.tagId == this.currentLocationParentId) {
					this.selectedLocationParent[0] = parent;
				}
			});
		}
	}

	public showSpecialCharacters() {
		this.showFC = false
		this.showSC = !this.showSC
		this.rowCount = 2
	}

	public showFractions() {
		this.showSC = false
		this.showFC = !this.showFC
		this.rowCount = 2
	}

	private hidePanels() {
		this.showFC = false
		this.showSC = false
		this.rowCount = 4
	}

	private setFacility(facilityId: any) {
		if (this.facilityList != null) {
			this.facilityList.forEach(facility => {
				if (facilityId == facility.facilityId) {
					this.selectedFacilityList[this.selectedFacilityList.length] = facility;
				}
			});
		}
	}

	private setSystem(systemId: any) {
		if (this.systemList != null) {
			this.systemList.forEach(system => {
				if (systemId == system.id) {
					//this.selectedSystemList[this.selectedSystemList.length] = system;
					this.selectedSystemList[0] = system;
				}
			});
		}
	}

	private setDocument(documentId: any) {
		if (this.documents != null) {
			this.documents.forEach(document => {
				if (documentId == document.id) {
					this.selectedDocumentList[0] = document;
				}
			});
		}
	}

	private setDataSheet(dataSheetId: any) {
		if (this.dataSheetList != null) {
			this.dataSheetList.forEach(dataSheet => {
				if (dataSheet.id == dataSheetId) {
					this.selectedDataSheetList[this.selectedDataSheetList.length] = dataSheet;
				}
			});
		}
	}

	private setClassification(classId: any) {
		if (this.tagClassList != null) {
			this.tagClassList.forEach(selectedClass => {
				if (selectedClass.classId == classId) {
					this.selectedTagClassList[this.selectedTagClassList.length] = selectedClass;
					if(this.hasDependency()){
						this.classIsAlreadyAdded = true;
					}
				}
			});
		}
	}

	public setSelectedSystem(id: any) {
		if (this.selectedAttributeSystemList != null) {
			this.selectedAttributeSystemList.forEach(system => {
				if (system.id == id) {
					//this.selectedSystemList[this.selectedSystemList.length] = system; 
					this.selectedSystemList[0] = system; 
				}
			});
		}
	}

	public setFailureClass(failureClassId: any) {
		if (this.failureClassList != null) {
			this.failureClassList.forEach(failureClass => {
				if (failureClass.id == failureClassId) {
					this.selectedFailureClasses[this.selectedFailureClasses.length] = failureClass;
				}
			});
		}
	}

	public setCriticality(criticalityId: any) {
		if (this.criticalityList != null) {
			this.criticalityList.forEach(criticality => {
				if (criticality.id == criticalityId) {
					this.selectedCriticalityList[this.selectedCriticalityList.length] = criticality;
				}
			});
		}
	}

	public setSafetyCriticality(safetyCriticalityId: any) {
		if (this.safetyCriticalityList != null) {
			this.safetyCriticalityList.forEach(criticality => {
				if (criticality.id == safetyCriticalityId) {
					this.selectedSafetyCriticalityList[this.selectedSafetyCriticalityList.length] = criticality;
				}
			});
		}
	}

	public setDiscipline(disciplineId: any) {
		if (this.disciplineList != null) {
			this.disciplineList.forEach(discipline => {
				if (discipline.id == disciplineId) {
					this.selectedDisciplineList[0] = discipline;
				}
			});
		}
	}

	public setEquipmentType(equipmentTypeId: any) {
		if (this.equipmentTypeList != null) {
			this.equipmentTypeList.forEach(equipmentType => {
				if (equipmentType.id == equipmentTypeId) {
					this.selectedEquipmentTypeList[0] = equipmentType;
				}
			});
		}
	}

	public setAssetGroupsList(assetGroupId: any) {
		if (this.assetGroupsList != null) {
			this.assetGroupsList.forEach(assetGroup => {
				if (assetGroup.assetGroupId == assetGroupId) {
					this.selectedAssetGroupsList[0] = assetGroup;
				}
			});
		}
	}

	public setCsFunctionCodeList(functionCodeId: any) {
		if (this.csFunctionCodeList != null) {
			this.csFunctionCodeList.forEach(functionCode => {
				if (functionCode.functionCodeId == functionCodeId) {
					this.selectedCsFunctionCodeList[0] = functionCode;
				}
			});
		}
	}

	public setVendor(vendorId: any) {
		if (this.vendorList != null) {
			this.vendorList.forEach(vendor => {
				if (vendor.vendorId == vendorId) {
					this.selectedVendorList[this.selectedVendorList.length] = vendor;
				}
			});
		}
	}

	public setAssetParent(parentId: any) {
		if (this.assetParentList != null) {
			/**
		 	* set selected asset parent id
		 	* Issue - Two different queries running in V2 and V3 after selecting Asset Group to Populate Asset Parent Drop Down
		 	*  Trello Card - https://trello.com/c/eUyEMcCb
		 	* */
			this.assetParentList.forEach(parent => {
				if (parent.id == parentId) {
					this.selectedParentList[this.selectedParentList.length] = parent;
				}
			});
		}
	}

	//TAG ATTRIBUTES
	/**
	 * Prepare list items for the system drop-down list
	 */
	// private prepareNgSelectSystems() {
	// 	this.systems.forEach(system => {
	// 		this.systemListForNgSelect.push({ "id": system.id, "text": system.systemName });
	// 		this.tagAttributes.forEach(sys => {
	// 			if (sys.value == system.id) {
	// 				this.selectedSystemOptions.push({ "id": sys.attributeId + '_' + sys.value, "text": system.systemName });
	// 				//console.log('Systems - ' + this.selectedSystemOptions.length);
	// 			}
	// 		});
	// 	});
	// }

	/**
	 * Prepare the list items for the document drop-down list.
	 */
	// private prepareNgSelectDocuments() {
	// 	this.documentListAll.forEach(doc => {
	// 		this.documentListForNgSelect.push({ "id": doc.id, "text": doc.documentName });
	// 		this.tagAttributes.forEach(selectedDoc => {
	// 			if (selectedDoc.value == doc.id) {
	// 				this.selectedDocumentOptions.push({ "id": selectedDoc.attributeId + '_' + selectedDoc.value, "text": doc.documentName });
	// 			}
	// 		});
	// 	});
	// }

	// private prepareNgSelectComponents() {
	// 	this.components.forEach(comp => {
	// 		this.componentListForNgSelect.push({ "id": comp.id, "text": comp.componentValue });
	// 		console.log("===comp.id=" + comp.id + "============ comp.componentValue=" +  comp.componentValue);
	// 		this.tagAttributes.forEach(selectedCmp => {
	// 			console.log("===selectedCmp.id=" + selectedCmp.id + "============ selectedCmp.value=" +  selectedCmp.value);
	// 			if (selectedCmp.value == comp.id) {
	// 				this.selectedComponentOptions.push({ "id": selectedCmp.attributeId + '_' + selectedCmp.value, "text": comp.componentValue });
	// 			}
	// 		});
	// 	});
		
	// 	// this.components.forEach(comp => {
	// 	// 	console.log("===comp.id=" + comp.id + "============ comp.componentValue=" +  comp.componentValue);
	// 	// 	this.componentListForNgSelect.push({ "id": comp.id, "text": comp.componentValue });
	// 	// });
	// 	// this.tagAttributes.forEach(selectedCmp => {
	// 	// 		this.components.forEach(comp => {
	// 	// 		console.log("===selectedCmp.id=" + selectedCmp.id + "============ (selectedCmp.value=" +  (selectedCmp.value);
	// 	// 		if (selectedCmp.value == comp.id) {
	// 	// 			this.selectedComponentOptions.push({ "id": selectedCmp.attributeId + '_' + selectedCmp.value, "text": comp.componentValue });
	// 	// 		}
	// 	// 	});
	// 	// });

	// }

	private prepareTagAttributes() {
		this.tagAttributes.forEach((attrib, i) => {
            this.selectedTagAttributeValue[i] = { "id": attrib.attributeId + '_' + '', "text": ''};
            var selectedTagAttrib = [];
            if (attrib.attributeType === "Component") {
                //Add the component List as componentListForNgSelect
                this.components.forEach(comp => {
					if (this.componentListForNgSelect.length < this.components.length) {
						   this.componentListForNgSelect.push({ "id": comp.id, "text": comp.componentValue });
					}
                    //Set the Selected Value to selectedItemList
                    if (attrib.value == comp.id) {
                        selectedTagAttrib.push({ "id": attrib.attributeId + '_' + attrib.value, "text": comp.componentValue });
                    }
                });
			} else if (attrib.attributeType === "System") {
                this.systems.forEach(system => {
					if (this.systemListForNgSelect.length < this.systems.length) {
						this.systemListForNgSelect.push({ "id": system.id, "text": system.systemNo + ' ' + system.systemName });
					}
                    //Set the Selected Value to selectedItemList
                    if (attrib.value == system.id) {
                        selectedTagAttrib.push({ "id": attrib.attributeId + '_' + attrib.value, "text": system.systemNo + ' ' + system.systemName });
                    }
                });
			} else if (attrib.attributeType === "Document") {
                this.documentListAll.forEach(doc => {
					if (this.documentListForNgSelect.length < this.documentListAll.length) {
						this.documentListForNgSelect.push({ "id": doc.id, "text": doc.documentName });
					}
                    //Set the Selected Value to selectedItemList
                    if (attrib.value == doc.id) {
                        selectedTagAttrib.push({ "id": attrib.attributeId + '_' + attrib.value, "text": doc.documentName });
                    }
                });
            }
            this.selectedTagAttributeValue[i] = {selectedDataId: selectedTagAttrib};
		});
	}

	/**
	 * set selected values in old tag attributes
	 */
	private prepareOldTagAttributes() {
		this.oldTagAttributes.forEach((attrib, i) => {
            this.selectedOldTagAttributeValue[i] = { "id": attrib.attributeId + '_' + '', "text": ''};
			var selectedOldTagAttrib = [];
            if (attrib.attributeType === "Component") {
                //Add the component List as componentListForNgSelect
                this.components.forEach(comp => {
                    //Set the Selected Value to selectedItemList
                    if (attrib.value == comp.id) {
                        selectedOldTagAttrib.push({ "id": attrib.attributeId + '_' + attrib.value, "text": comp.componentValue });
                    }
                });
			} else if (attrib.attributeType === "System") {
                this.systems.forEach(system => {
                    //Set the Selected Value to selectedItemList
                    if (attrib.value == system.id) {
                        selectedOldTagAttrib.push({ "id": attrib.attributeId + '_' + attrib.value, "text": system.systemName });
                    }
                });
			} else if (attrib.attributeType === "Document") {
                this.documentListAll.forEach(doc => {
                    //Set the Selected Value to selectedItemList
                    if (attrib.value == doc.id) {
                        selectedOldTagAttrib.push({ "id": attrib.attributeId + '_' + attrib.value, "text": doc.documentName });
                    }
                });
            }
            this.selectedOldTagAttributeValue[i] = {selectedDataId: selectedOldTagAttrib};
		});
	}

	/*
	private prepareNgSelectComponents() {
		this.valueList.forEach(value => {
			this.valueListNgSelect.push({ "id": value.valueListId, "text": value.valueListName });
			this.tagAttributes.forEach(selectedCmp => {
				if (selectedCmp.value == value.valueListId) {
					this.selectedComponentOptions.push({ "id": selectedCmp.attributeId + '_' + selectedCmp.value, "text": value.systemName });
				}
			});
		});
	}
	*/

	public getComponentListByCategory(categoryId: string){
		let compListBycategory = []
		if(categoryId != null && categoryId != ''){
			this.components.forEach(component => {
				if(component.categoryId == categoryId){
					let node = { "id": component.id, "text": component.componentValue };
					compListBycategory.push(node)
				}
			})
		}
		else{
			//set all the components when the tag attribute type category is not selected
			compListBycategory = this.componentListForNgSelect
		}
		return compListBycategory
	}

	private prepareAssetAttributes() {
		this.assetAttributeSelectionList = [];
		if (this.assetAttributeList != null) {
			this.assetAttributeList.forEach((assetAttribute, i) => {
				this.assetAttributeSelectionList[i]  = {attributeType: "default", attributeId:null, assetListNgSelect: [], valueListNgSelect: []}
				var selectedItem = null;
				switch (assetAttribute.attributeType) {
					case "Asset":
						if (assetAttribute.assetList != null) {
							this.assetListNgSelect = [];
							this.selectedAssetOptions = [];
							assetAttribute.assetList.forEach(asset => {
								var item = { "id": asset.assetId, "text": asset.assetName };
								this.assetListNgSelect.push(item);
								if (asset.assetId == assetAttribute.attributeValue) {
									//console.log('asset = ' + item);
									this.selectedAssetOptions[this.selectedAssetOptions.length] = item;
								}
							});
							this.assetAttributeSelectionList[i] = {selectedDataId:this.selectedAssetOptions, attributeId:assetAttribute.assetAttributeId, attributeType: "Asset", assetListNgSelect: this.assetListNgSelect}
						}
						break;
					case "ValueList":
						if (assetAttribute.valueListValues != null) {
							this.valueListNgSelect = [];
							this.selectedValueListOptions = [];
							assetAttribute.valueListValues.forEach(valueList => {
								var item = { "id": valueList.id, "text": valueList.valueListValue };
								this.valueListNgSelect.push(item);
								if (valueList.id == assetAttribute.attributeValue) {
									//console.log('value list = ' + item);
									this.selectedValueListOptions[this.selectedValueListOptions.length] = item;
								}
							});
							this.assetAttributeSelectionList[i] = {selectedDataId:this.selectedValueListOptions, attributeId:assetAttribute.assetAttributeId, attributeType: "ValueList", valueListNgSelect: this.valueListNgSelect}
						}
						break;
					case "Text":
						var attributeValue = assetAttribute.attributeValue;
						this.textTypeValue = attributeValue;
						break;

					default:
						break;
				}
			});
		}
	}

	public selectItemFromSuggestions(val: any, type: string) {
		switch (type) {
			case 'facility':
				console.log('add facility selection ');
				this.selectedFacility = val;

				// Clear dropdowns
				this.documents = [];
				this.dataSheetList = [];
				this.systemList = [];

				// Clear selected items
				this.selectedDocumentList = [];
				this.selectedDataSheetList = []
				this.selectedSystemList = [];

				// Get systems, documents and datasheets relevant to the facility
				this.tagService.getSystemsByFacilityId(this.token, this.selectedFacility.facilityId, this);
				this.tagService.getDocumentsByFacilityId(this.token, this.selectedFacility.facilityId, this);
				this.tagService.getDataSheetsByFacilityId(this.token, this.selectedFacility.facilityId, this);

				break;
			case 'system':
				console.log('add system selection ');
				break;
			case 'document':
				console.log('add document selection ');
				break;
			case 'classification':
				console.log('add classification selection ');
				//add attributes
				this.loadingService.showLoading(true, false, 'Loading', 0);
				this.tagService.getAttributesByClassification(this.token, this.selectedTagClassList[0].classId, this)
				console.log('selected model');
				break;
			case 'data_sheet':
				this.dataSheetList.forEach(element => {
					if (element.id == val.id) {
						this.selectedDataSheetList[0] = element;
					}
				});
				break;
			case 'location_parent':
				this.locationParent.forEach(element => {
					if (element.id == val.id) {
						this.selectedLocationParent[0] = element;
					}
				});
				break;
			case 'failure_class':
				this.failureClassList.forEach(element => {
					if (element.id == val.id) {
						this.selectedFailureClasses[0] = element;
					}
				});
				break;
			case 'criticality':
				this.criticalityList.forEach(element => {
					if (element.id == val.id) {
						this.selectedCriticalityList[0] = element;
					}
				});
				break;
			case 'safety_criticality':
				this.safetyCriticalityList.forEach(element => {
					if (element.id == val.id) {
						this.selectedSafetyCriticalityList[0] = element;
					}
				});
				break;
			case 'discipline':
				console.log('add discipline');
				this.disciplineList.forEach(element => {
					if (element.id == val.id) {
						this.selectedDisciplineList[0] = element;
					}
				});
				break;
			case 'equipment_type':
				this.equipmentTypeList.forEach(element => {
					if (element.id == val.id) {
						this.selectedEquipmentTypeList[0] = element;
					}
				});
				break;
			case 'asset_group':
				console.log("val.assetGroupId= "+ val.assetGroupId)
				this.assetGroupsList.forEach(element => {
					if (element.assetGroupId == val.assetGroupId) {
						this.selectedAssetGroupsList[0] = element;
						this.tagService.getAssetGroupAttributes(this.token, val.assetGroupId, this);
						//this.tagService.getAssetAttributes(this.token, val.assetGroupId, this);//id of the widget
						//this.tagService.getValueListValues(this.token, val.assetGroupId, this);//id of the widget
					}
				});
				break;
			case 'asset_parent':
				this.assetParentList.forEach(element => {
					if (element.id == val.id) {
						this.selectedParentList[0] = element;
					}
				});
				break;
			case 'function_code':
				console.log('add function code');
				break;
			case 'vendor':
				console.log('add vendor');
				break;
			default:
				break;
		}
	}

	public removeSelectedItem(val: any, type: string) {
		console.log("[(removeSelectedItem)] - (val/type): " + val + "/" + type);
		switch (type) {
			case 'facility':
				console.log('remove facility selection ');

				// Clear selected items
				this.selectedFacility = undefined;
				this.selectedFacilityList = [];
				this.selectedDocumentList = [];
				this.selectedDataSheetList = [];
				this.selectedSystemList = [];

				// Change document/ datasheets/ system dropdown
				this.documents = this.documentListAll;
				this.dataSheetList = this.dataSheetListAll;
				
				/*** 
			 	* Display System Number and System Name in the drop down items
			 	* Trello Card - https://trello.com/c/F9e5rKLy
				*/
				//this.systemList = this.systemsWithoutFacilityList;
				this.systemList = [];
				this.systemsWithoutFacilityList.forEach(system =>{
					this.systemList.push({"id":system.id, "systemNoandsystemName":system.systemNo + ' ' + system.systemName})
				});
				break;
			case 'system':
				console.log('remove system selection ');
				break;
			case 'document':
				console.log('remove document selection ');
				console.log(val);
				break;
			case 'classification':
                console.log('remove classification selection ');
                //remove attributes
                this.tagAttributes = [];
				this.selectedAttributes = [];
				//remove values in the dropdowns
				this.systemListForNgSelect = [];
				this.componentListForNgSelect = [];
				this.documentListForNgSelect = [];
                break;
			case 'data_sheet':
				console.log('remove data sheet selection ');
				break;
			case 'location_parent':
				console.log('remove location parent');
				break;
			case 'failure_class':
				console.log('remove failure class');
				break;
			case 'criticality':
				console.log('remove criticality');
				break;
			case 'safety_criticality':
				console.log('remove safety criticality');
				break;
			case 'discipline':
				console.log('remove discipline');
				break;
			case 'equipment_type':
				console.log('remove equipmentType');
			case 'asset_group':
				console.log('remove asset group');
				this.assetAttributeList = [];
				this.selectedAssetAttributes = [];
				break;
			case 'asset_parent':
				console.log('remove asset parent');
				break;
			case 'function_code':
				console.log('remove function code');
				break;
			case 'vendor':
				console.log('remove vendor');
			default:
				break;
		}
	}

	public getValueChanges(val: any, type: string, attributeId: string, tagClassSpecId: string = "", required: string) {
		console.log(JSON.stringify(val));
		//checking existing attributes from the selectedChangedAttributes list
		let index = -1;
		for (let i = 0; i < this.selectedChangedAttributes.length; i++) {
			if (this.selectedChangedAttributes[i].attributeId == attributeId) {
				index = i;
			}
		}
		switch (type) {
			case 'tag_attribute_component':
                this.selectedAttributes.forEach(item => {
                    if (item.attributeId == attributeId) {
                        item.value = val.id;
                        item.classId = this.selectedTagClassList[0].classId;
                    }
                });
				// if (this.selectedTagClassList[0].classId == this.tagModel.classId) {
				// 	this.selectedAttributes.forEach(item => {
				// 		if (item.attributeId == attributeId) {
				// 			item.value = val.id;
				// 			item.classId = this.selectedTagClassList[0].classId;
				// 		}
				// 	});
				// } else {
				// 	if (index == -1) {
				// 		//if the item is not existed.
				// 		this.selectedChangedAttributes[this.selectedChangedAttributes.length] = { 
				// 			attributeId: attributeId, 
				// 			value: val.id, 
				// 			classId: this.selectedTagClassList[0].classId,
				// 			tagClassSpecId: tagClassSpecId,
				// 			tagId: this.tagId,
				// 			oldValue: null,
				// 			required: required
				// 		};
				// 	} else {
				// 		//if the item is existed.
				// 		this.selectedChangedAttributes[index] = { 
				// 			attributeId: attributeId, 
				// 			value: val.id, 
				// 			classId: this.selectedTagClassList[0].classId,
				// 			tagClassSpecId: tagClassSpecId,
				// 			tagId: this.tagId,
				// 			oldValue: null,
				// 			required: required
				// 		};
				// 	}
				// }
				break;
			case 'tag_attribute_system':
                this.selectedAttributes.forEach(item => {
                    if (item.attributeId == attributeId) {
                        item.value = val.id;
                        item.classId = this.selectedTagClassList[0].classId;
                    }
                });
				// if (this.selectedTagClassList[0].classId == this.tagModel.classId) {
				// 	this.selectedAttributes.forEach(item => {
				// 		if (item.attributeId == attributeId) {
				// 			item.value = val.id;
				// 			item.classId = this.selectedTagClassList[0].classId;
				// 		}
				// 	});
				// } else {
				// 	if (index == -1) {
				// 		//if the item is not existed.
				// 		this.selectedChangedAttributes[this.selectedChangedAttributes.length] = { 
				// 			attributeId: attributeId, 
				// 			value: val.id, 
				// 			classId: this.selectedTagClassList[0].classId,
				// 			tagClassSpecId: tagClassSpecId,
				// 			tagId: this.tagId,
				// 			oldValue: null,
				// 			required: required
				// 		};
				// 	} else {
				// 		//if the item is existed.
				// 		this.selectedChangedAttributes[index] = { 
				// 			attributeId: attributeId, 
				// 			value: val.id, 
				// 			classId: this.selectedTagClassList[0].classId,
				// 			tagClassSpecId: tagClassSpecId,
				// 			tagId: this.tagId,
				// 			oldValue: null,
				// 			required: required
				// 		};
				// 	}
				// }
				break;
			case 'tag_attribute_document':
                this.selectedAttributes.forEach(item => {
                    if (item.attributeId == attributeId) {
                    item.value = val.id;
                    item.classId = this.selectedTagClassList[0].classId;
                    }
                });
				// if (this.selectedTagClassList[0].classId == this.tagModel.classId) {
				// 	this.selectedAttributes.forEach(item => {
				// 		if (item.attributeId == attributeId) {
				// 			item.value = val.id;
				// 			item.classId = this.selectedTagClassList[0].classId;
				// 		}
				// 	});
				// } else {
				// 	if (index == -1) {
				// 		//if the item is not existed.
				// 		this.selectedChangedAttributes[this.selectedChangedAttributes.length] = { 
				// 			attributeId: attributeId, 
				// 			value: val.id, 
				// 			classId: this.selectedTagClassList[0].classId,
				// 			tagClassSpecId: tagClassSpecId,
				// 			tagId: this.tagId,
				// 			oldValue: null,
				// 			required: required
				// 		};
				// 	} else {
				// 		//if the item is existed.
				// 		this.selectedChangedAttributes[index] = { 
				// 			attributeId: attributeId, 
				// 			value: val.id, 
				// 			classId: this.selectedTagClassList[0].classId,
				// 			tagClassSpecId: tagClassSpecId,
				// 			tagId: this.tagId,
				// 			oldValue: null,
				// 			required: required
				// 		};
				// 	}
				// }
				break;
			case 'tag_attribute_date':
                this.selectedAttributes.forEach(item => {
                    if (item.attributeId == attributeId) {
                        item.value = val.currentTarget.value;
                        item.classId = this.selectedTagClassList[0].classId;
                    }
                });
					// 	if (this.selectedTagClassList[0].classId == this.tagModel.classId) {
				// 	this.selectedAttributes.forEach(item => {
				// 		if (item.attributeId == attributeId) {
				// 			item.value = val.currentTarget.value;
				// 			item.classId = this.selectedTagClassList[0].classId;
				// 		}
				// 	});
				// } else {
				// 	if (index == -1) {
				// 		//if the item is not existed.
				// 		this.selectedChangedAttributes[this.selectedChangedAttributes.length] = {
				// 			attributeId: attributeId, 
				// 			value: val.currentTarget.value, 
				// 			classId: this.selectedTagClassList[0].classId,
				// 			tagClassSpecId: tagClassSpecId,
				// 			tagId: this.tagId,
				// 			oldValue: null,
				// 			required: required
				// 		};
				// 	} else {
				// 		//if the item is existed.
				// 		this.selectedChangedAttributes[index] = {
				// 			attributeId: attributeId, 
				// 			value: val.currentTarget.value, 
				// 			classId: this.selectedTagClassList[0].classId,
				// 			tagClassSpecId: tagClassSpecId,
				// 			tagId: this.tagId,
				// 			oldValue: null,
				// 			required: required
				// 		};
				// 	}
				// }
				break;
			case 'tag_attribute_text':
					if (this.numberAttr) {
						val.currentTarget.value = "";
						this.numberAttr = false;
					}
                this.selectedAttributes.forEach(item => {
                    if (item.attributeId == attributeId) {
                        item.value = val.currentTarget.value;
                        item.classId = this.selectedTagClassList[0].classId;
                    }
                });
				// if (this.selectedTagClassList[0].classId == this.tagModel.classId) {
				// 	this.selectedAttributes.forEach(item => {
				// 		if (item.attributeId == attributeId) {
				// 			item.value = val.currentTarget.value;
				// 			item.classId = this.selectedTagClassList[0].classId;
				// 		}
				// 	});
				// } else {
				// 	if (index == -1) {
				// 		//if the item is not existed.
				// 		this.selectedChangedAttributes[this.selectedChangedAttributes.length] = {
				// 			attributeId: attributeId, 
				// 			value: val.currentTarget.value, 
				// 			classId: this.selectedTagClassList[0].classId,
				// 			tagClassSpecId: tagClassSpecId,
				// 			tagId: this.tagId,
				// 			oldValue: null,
				// 			required: required
				// 		};
				// 	} else {
				// 		//if the item is existed.
				// 		this.selectedChangedAttributes[index] = {
				// 			attributeId: attributeId, 
				// 			value: val.currentTarget.value, 
				// 			classId: this.selectedTagClassList[0].classId,
				// 			tagClassSpecId: tagClassSpecId,
				// 			tagId: this.tagId,
				// 			oldValue: null,
				// 			required: required
				// 		};
				// 	}
				// }
				break;
			case 'asset_attribute_asset':
					// console.log('asset - value=' + val.id + ' attributeId=' +  attributeId);
					// this.selectedAssetAttributes[tagSpecId] = { attributeId: attributeId, value: val.id };
					// break;
				this.selectedAssetAttributes.forEach(item => {
					if (item.attributeId == attributeId) {
						item.value = val.id;
					}
				});
				break;
			case 'asset_attribute_value':
				this.selectedAssetAttributes.forEach(item => {
					if (item.attributeId == attributeId) {
						item.value = val.id;
					}
				});
				break;
			case 'asset_attribute_text':
				this.selectedAssetAttributes.forEach(item => {
					if (item.attributeId == attributeId) {
						item.value = val.currentTarget.value;
					}
				});
				break;
			default:
				break;
		}
	}

	public tagEditSaveButtonClick() {
		console.log("selectedAttributes Array: " + JSON.stringify(this.selectedAttributes));		
		console.log("selectedChangedAttributes Array: " + JSON.stringify(this.selectedChangedAttributes));
		if (this.tagModel.tagNo == undefined || this.tagModel.tagNo.trim() == "") {
			this.alertService.error("Tag number is required");
			return;
		}
		var tagDesc:String = "" + this.tagDescription;
		if (this.tagDescription == undefined || tagDesc.trim() == "") {
			this.alertService.error("Tag description is required");
			return;
		}
		if (this.selectedSystemList.length == 0) {
			this.alertService.error("System is required");
			return;
		}
		if (this.isUsedCmmsTracking && this.alternateTagNo != undefined && this.alternateTagNo != null) {
			if (/\s/.test(this.alternateTagNo)) {
				this.alertService.error("Alternate Tag No can't have whitespace.");
				return;
			} else {
				console.log("valid alt tag no.")
			}
		}

		//this.tagModel.tagNo = this.tagModel.tagNo.replace(/\\/g, "\\\\"); 
		//this.tagDescription = this.tagDescription.replace(/\\/g, "\\\\"); 
		let tagNum =  this.tagModel.tagNo.replace(/\\/g, "\\\\");	
		this.tagNoNew =tagNum;
		let CADNum = "";
		if (this.tagModel.cadNo != null) {
			CADNum = this.tagModel.cadNo.replace(/\\/g, "\\\\");
		}
		this.cadNoNew = CADNum;
		let tagDescription = this.tagDescription.replace(/\\/g, "\\\\");
		this.descriptionNew = tagDescription;
		let altTagNo = "";
		if (this.alternateTagNo != null) {
			altTagNo = this.alternateTagNo.replace(/\\/g, "\\\\");
		}
		let locationDesc = "";
		if (this.tagModel.locationDescription != null) {
			locationDesc = this.tagModel.locationDescription.replace(/\\/g, "\\\\"); 
		}	
		let equipmentDesc = "";
		if (this.tagModel.equipmentDescription != null) {
			equipmentDesc = this.tagModel.equipmentDescription.replace(/\\/g, "\\\\");
		}

		if ((this.usedInCMMSTrac && this.selectedAssetGroupsList == undefined)
			|| (this.usedInCMMSTrac && this.selectedAssetGroupsList.length == 0)) {
			this.alertService.error("Asset group cannot be empty when the commissioning is enabled");
			return;
		}
		if ((!this.IsValidDate(this.otnTargetCompletionDate) || !this.IsValidDate(this.irnTargetCompletionDate))) {
			this.alertService.clear;
            this.alertService.error("Invalid date selected");
            return;
        }
		
		//  Process asset attributes
		if ((this.assetAttributeList != null) && (this.assetAttributeList.length > 0)) {
			for (let i = 0; i < this.assetAttributeList.length; i++) {
                let requiredField = this.assetAttributeList[i].required;
                if (requiredField == "Yes") {
                    let selectedAttributeValue = this.selectedAssetAttributes[i].value;
                    if (selectedAttributeValue == null || selectedAttributeValue.trim() == "") {
                        this.alertService.error("Asset Attribute cannot be empty");
                        return;
                    }
                }
            }
		} else {
			this.selectedAssetAttributes = [];
		}


		let numberOfMasks: any = null;
		if(this.tagAddPreConditions.cmmsNoOfMasks != null){
			if(this.tagAddPreConditions.cmmsNoOfMasks.noOfElements != null){
				numberOfMasks = this.tagAddPreConditions.cmmsNoOfMasks.noOfElements;
			}
		}
		// let tagAttributes = [];
		// if (this.selectedTagClassList.length != 0) {
		// 	if (this.tagModel.classId != this.selectedTagClassList[0].classId) {
		// 		tagAttributes = this.selectedChangedAttributes != undefined ? this.selectedChangedAttributes : [];
		// 	} else {
		// 		tagAttributes = this.selectedAttributes != undefined ? this.selectedAttributes : [];
		// 	}
		// } else{
		// 	tagAttributes = [];
		// }

        //Check required fields of tag atributes
        console.log("tagAttributes.length===" + this.tagAttributes.length);
        if (this.tagAttributes != null && this.tagAttributes.length > 0) {
            for (let i = 0; i < this.tagAttributes.length; i++) {
                console.log("tagAttributes[i].required===" + this.tagAttributes[i].required);
                let requiredField = this.tagAttributes[i].required;
                if (requiredField == "yes") {
                    console.log("this.selectedAttributes[i].value===" + this.selectedAttributes[i].value);
                    let selectedAttributeValue = this.selectedAttributes[i].value;
                    if (selectedAttributeValue == null || selectedAttributeValue == "") {
                        this.alertService.error("Attribute cannot be empty");
                        return;
                    }
                }
            }
        }

		this.loadingService.showLoading(true, false, this.translate.instant('SAVING'), 0);

		//convert special characters (like '<') to html entities
		tagDesc = $('<div/>').text(tagDescription).html();
		if (!this.isUsedCmmsTracking) {
			this.alternateTagNo = null;
		}

		if (this.selectedLocationParent.length != 0) {
			if (!(this.selectedLocationParent[0].tagId == undefined || this.selectedLocationParent[0].tagId == "")) {
				this.locationParentId = this.selectedLocationParent[0].tagId;
			} else if (!(this.selectedLocationParent[0].id == undefined || this.selectedLocationParent[0].id == "")){
				this.locationParentId = this.selectedLocationParent[0].id;
			}
		}

		
		let otnTargetCompletionDate = null;
		let irnTargetCompletionDate = null;
		if (this.otnTargetCompletionDate != null) {
			otnTargetCompletionDate = new DatePipe("en-US").transform(new Date(this.otnTargetCompletionDate), 'yyyy-MM-dd')
		}
		if (this.irnTargetCompletionDate != null) {
			irnTargetCompletionDate = new DatePipe("en-US").transform(new Date(this.irnTargetCompletionDate), 'yyyy-MM-dd')
		}

		let data = {
			tagId: this.tagId,
			tagNumber: tagNum,
			cadNo: CADNum.trim().length != 0 ? CADNum: null,
			tagDescription: "<tagdesc><txt>" + tagDesc + "</txt></tagdesc>",
			facility: this.selectedFacilityList.length != 0 ? this.selectedFacilityList[0].facilityId : null,
			document: this.selectedDocumentList.length != 0 ? this.selectedDocumentList[0].id : null,
			system: this.selectedSystemList.length != 0 ? this.selectedSystemList[0].id : "",
			dataSheet: this.selectedDataSheetList.length != 0 ? this.selectedDataSheetList[0].id : null,
			classification: this.selectedTagClassList.length != 0 ? this.selectedTagClassList[0].classId : "",
			equipmentDescription: equipmentDesc,
			locationDescription: locationDesc,
			locationParent: this.selectedLocationParent.length != 0 ? this.locationParentId : "",
			failureClass: this.selectedFailureClasses.length != 0 ? this.selectedFailureClasses[0].id : "",
			criticality: this.selectedCriticalityList.length != 0 ? this.selectedCriticalityList[0].id : "",
			safetyCritical: this.selectedSafetyCriticalityList.length != 0 ? this.selectedSafetyCriticalityList[0].id : "",
			discipline: this.selectedDisciplineList.length != 0 ? this.selectedDisciplineList[0].id : "",
			equipmentType: this.selectedEquipmentTypeList.length != 0 ? this.selectedEquipmentTypeList[0].id : "",
			usedInCommissioning: this.usedInCMMSTrac != undefined ? this.usedInCMMSTrac : "",
			assetGroup: this.selectedAssetGroupsList.length != 0 ? this.selectedAssetGroupsList[0].assetGroupId : "",
			assetParent: this.selectedParentList.length != 0 ? this.selectedParentList[0].id : "",
			functionCode: this.selectedCsFunctionCodeList.length != 0 ? this.selectedCsFunctionCodeList[0].functionCodeId : "",
			vendor: this.selectedVendorList.length != 0 ? this.selectedVendorList[0].vendorId : "",
			assetAttribute: this.selectedAssetAttributes,
            tagAttributes: this.selectedAttributes,
			equipmentNo: this.nextAvailableEquipmentNo,
			alternateTagNo: altTagNo,
			functionalLocation: this.calculatedFunctionalLocationValue,
			numOfMasks: numberOfMasks,
			funLocTagIdList: this.getSelectedTagIds(),
			funLocTagNoList: this.getSelectedAlternateTagNo(),
			otnTargetCompletionDate:otnTargetCompletionDate,
			irnTargetCompletionDate:irnTargetCompletionDate
		};

		//console.table(data);
		var json = JSON.stringify(data);
		this.tagService.editNewTag(this.token, json, this);
	}

	public tagEditCancelButtonClick() {
		 /*this.tagNumber = '';
		 this.tagDescription = '';
		 this.locationDescription = '';
		 this.equipmentDescription = '';
		 this.numerator = '';
		 this.denominator = '';
		 this.selectedDataSheetList = [];
		 this.selectedDocumentList = [];
		 this.selectedFacilityList = [];
		 this.selectedSystemList = [];*/
		 this.bsModalRef.hide()
	}

	getCaretPos(oField) {		
		if (oField.selectionStart || oField.selectionStart == '0') {
		   this.caretPos = oField.selectionStart;
		}
	}

	IsValidDate(value: Date): boolean {
		if(value != undefined){
		  var date = new Date(value);
		  if(date.toString() == 'Invalid Date'){
			return false;
		  }
		  else{
			return true;
		  }
		}else{
		  return true;
		}
	}


	/**
	 * Set fraction
	 */
	public setNumeratorDenominator() {
		//check the validity of this.numerator and this.denominator
		if (this.numerator != null && this.denominator != null && this.numerator != undefined && this.denominator != undefined) {			
			this.tagDescription = [this.tagDescription.slice(0, this.caretPos), '' + this.numerator +'/'+ this.denominator, 
			this.tagDescription.slice(this.caretPos)].join('');
		}
		setTimeout(() => {
			this.hidePanels();
		}, 100);
	}

	/**
	 * Set special character
	 * @param val
	 */
	public setSpecialCharacter(val: any) {
		this.tagDescription = [this.tagDescription.slice(0, this.caretPos), val.target.innerText, 
			this.tagDescription.slice(this.caretPos)].join('');
		this.hidePanels();
	}

	/**
	 * Click event for the dependency button
	 */
	public dependencyButtonClickEvent(type) {
		console.log('clicked on a dependency button');
		const initialState = {
            headerTitle: 'Dependencies List',
            delFlag: false,
            cancelFlag: true,
            checkboxVal: false
		};
		//Set data
		console.log('showing Dependencies List');

		// if(type == "Tag" || type == "TagNumber" || type == "AlternateTagNo" || type == "TagDescription"){
		// 	this.sharedService.setFileId(this.tagModel.tagId);
		// 	this.sharedService.setFileType(type);

		// 	this.viewTagDependencies();
		// 	return;
		// } else if( type == "Facility"){
		// 	this.sharedService.setFileId(this.selectedFacilityList[0].facilityId);
		// 	this.sharedService.setFileType("Facility");
		// } else if( type == "Document"){
		// 	this.sharedService.setFileId(this.selectedDocumentList[0].id);
        // this.sharedService.setFileType("Document");
		// } else if( type == "System"){
		// 	this.sharedService.setFileId(this.tagModel.tagId);
        // 	this.sharedService.setFileType("TagSystem");
		// }
		this.sharedService.setFileId(this.tagModel.tagId);
        this.sharedService.setFileType(type);
        this.sharedService.setParent(this);
		//this.bsModalService.show(DependenciesmodalComponent, { initialState, class: 'modal-lg' });

		//this.sharedService.setDependencyMessageType(this.dependencyType);
		this.sharedService.setAction(DEPENDENCY_ACTION.GET_DEPENDENCIES);
		this.bsModalRefDependencyView = this.bsModalService.show(TagDependencyComponent, { initialState, class: 'modal-lg' });



	}

	/**
	 * Set data to load tag-dependency.model
	 */
	public viewTagDependencies(){
		let dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.VIEW;
		let action = DEPENDENCY_ACTION.GET_DEPENDENCIES;
		let title: string;
		this.translate.get('TAGS_SEARCH_VIEW.CONFIRM_POPUP.DEPENDENCY_TITLE').subscribe((res: string) => {
			title = res;
		});

		const initialState = {
			headerTitle: title,
			delFlag: false,
			cancelFlag: true,
			checkboxVal: false
		};

		this.sharedService.setDependencyMessageType(dependencyType);
		this.sharedService.setAction(action);
		this.sharedService.setParent(this);
		this.bsModalService.show(TagDependencyComponent, { initialState, class: 'modal-lg' });
	}


	/**
	 * Set initial functional locations. (CONFIRMED SUCCESS)
	 */
	public setInitialFunctionalLocations(cmmsFunctionalLocationInfo: any): void {
		if (cmmsFunctionalLocationInfo == null) {
			return;
		}
		var index = 0;
		cmmsFunctionalLocationInfo.forEach(item => {
			var itemArray = [];
			itemArray[0] = item;
			if (itemArray[0].functionalLocationTagId != "") {
				this.addedSuggestionList[index] = itemArray;
			} else {
				this.addedSuggestionList[index] = [];				
				this.enable[0] = false;
				this.enableDisableItems[0] = false;
				this.enableDisableItems[index+1] = true;
			}			
			this.suggestionOriginalList[index] = item;			
			index++;
		});
	}

	/**
	 * Filter original suggestions with already selected suggestions.
	 */
	private setSuggestionList() {
		this.suggestionList = [];
		this.suggestionListMore = [];
		var includeCurrent = false;
			for (let index = 0; index < this.addedSuggestionList.length; index++) {	
				if (this.addedSuggestionList[index].length != 0) {
					if (this.tagModel.tagId === this.addedSuggestionList[index][0].functionalLocationTagId) {
						includeCurrent = true;
					}
				}			
			}
		var regEx = new RegExp(this.searchText, "gi");
		if ((this.tagModel.tagNo != undefined && this.tagModel.tagNo != "") && (this.alternateTagNo != undefined && this.alternateTagNo != "")) {			
			if ((this.tagModel.tagNo.search(regEx) != -1) && (!includeCurrent)) {
				this.suggestionList.push({ "tagId": this.tagId, "alternateTagNo": this.alternateTagNo, "tagNo": this.tagModel.tagNo, "altNoAndTagNo": this.alternateTagNo + ' ' + '['+ this.tagModel.tagNo + ']'});
				this.suggestionListMore.push({ "tagId": this.tagId, "alternateTagNo": this.alternateTagNo, "tagNo": this.tagModel.tagNo, "description": this.tagDescription, "altNoAndTagNo": this.alternateTagNo + ' ' + '['+ this.tagModel.tagNo + ']'});
				
			}
		}
		
		this.suggestionOriginalList.forEach(element => {
			var include = false;
			for (let index = 0; index < this.addedSuggestionList.length; index++) {	
				if (this.addedSuggestionList[index].length != 0) {
					if (element.tagId === this.addedSuggestionList[index][0].functionalLocationTagId) {
						include = true;
					}
					if (this.suggestionList.length != 0) {
						if(element.tagId === this.suggestionList[0].tagId) {
							include = true;
						}
					}
				}			
			}	
			// Add only previously not added items.
			if (!include && !(element.alternateTagNo == '' || element.alternateTagNo == 'undefined')) {					
				this.suggestionList.push({ "tagId": element.tagId, "alternateTagNo": element.alternateTagNo, "tagNo": element.tagNo, "description": element.description, "altNoAndTagNo": element.alternateTagNo + ' ' + '['+ element.tagNo + ']'});
				this.suggestionListMore.push({ "tagId": element.tagId, "alternateTagNo": element.alternateTagNo, "tagNo": element.tagNo, "description": element.description, "altNoAndTagNo": element.alternateTagNo + ' ' + '['+ element.tagNo + ']'});
			}
		});		
		this.enableMoreFunctional = true;
		this.enable[0] = true;
		this.enable[this.selectedIndex] =false;	
	}

	/**
	 * Fires when user type on the text input.
	 * @param searchText typed text
	 */
	public onTextChange(searchText: any, index: number) {
		this.searchText = searchText;
		if (searchText != '') {
			this.suggestionList = [];
			this.suggestionListMore = [];
			this.selectedIndex = index;		
			if (searchText.includes("#")) {
				searchText = searchText.replace("#","%23");
			}			
			this.tagService.searchAlternateTag(this.token, searchText, '', 10, 0, this);
			this.enableMoreFunctional = false;
		}
	}

	/**
	 * Fires when user selects an item from the suggestion list.
	 * @param item selected item.
	 */
	public onSuggestionSelected(item: any, index: number) {
		this.enableMoreFunctional = false;	
		var array = new Array(1);
		item.functionalLocationTagNo = item.functionalLocationTagNo.substring(0, item.functionalLocationTagNo.lastIndexOf('['));
		array[0] = item;
		this.addedSuggestionList[index] = array;
		this.addedSuggestionList[index][0].functionalLocationTagId = array[0].tagId;
	    this.setSuggestionList();
		this.enableDisableItems[index + 1] = false;
		this.enable[index + 1] = false;
		this.enable[index] = true;
		this.enableMoreFunctional = false;
		this.prepareFunctionalLocationValue(item.alternateTagNo, index);
	}

	/**
	 * Fires when user remove an item from the selection.
	 * @param item deleted item
	 */
	public onSuggestionRemoved(item: any, index: number) {
		this.enableMoreFunctional = false;
		for (let i = index; i < this.addedSuggestionList.length; i++) {
			this.addedSuggestionList[i] = new Array(0);
		}
		//changing enable disable status except the current one.
		for (let i = index + 1; i < this.enableDisableItems.length; i++) {
			this.enableDisableItems[i] = true;
			this.enable[i] = true;
			this.enable[index] = false;
		}					
		this.prepareFunctionalLocationValue(item.functionalLocationTagNo, index);
	}

	/**
	 * Prepare the functional location value
	 * @param item added or removed item
	 * @param index added or removed index
	 */
	private prepareFunctionalLocationValue(tagValue: string, index: number) {
		this.calculatedFunctionalLocationValue = '';
		for (let index = 0; index < this.addedSuggestionList.length; index++) {	
			let tagNo ='';
			if (this.addedSuggestionList[index].length != 0) {
				tagNo = this.addedSuggestionList[index][0].functionalLocationTagNo;
				if (index != 0 && tagNo != '') {
					this.calculatedFunctionalLocationValue += '-';
				}
				tagNo = tagNo.trim();
				let charactersInTag = tagNo.length;
				if (this.tagAddPreConditions.cmmsMaskSpecificationInfo[index].charactersPerElement > charactersInTag) {
					let difference = this.tagAddPreConditions.cmmsMaskSpecificationInfo[index].charactersPerElement - charactersInTag;
					let addZero ='';
					for (let i = 0; i < difference; i++) {
						addZero = addZero + 0;
					}
					tagNo = addZero + tagNo;
				}
				this.calculatedFunctionalLocationValue += tagNo.substring(0, this.tagAddPreConditions.cmmsMaskSpecificationInfo[index].charactersPerElement);
			}
		}
	}

	/**
	 * Fires when user clicks on the equipment checkbox.
	 * @param event event
	 */
	public onCheckBoxCheck(event: any) {
		if (event.srcElement.checked) {
			this.tagService.getNextAvailableEquipmentNo(this.token, this);
			this.isEquipmentNoCheckboxSelected = true;
		} else {
			this.nextAvailableEquipmentNo = '';
			this.isEquipmentNoCheckboxSelected = false;
		}
	}

	/**
	 * Get selected tag id list.
	 */
	private getSelectedTagIds(): string {
		console.log('----tag ids----');
		var res = '';
		if (this.addedSuggestionList != null) {
			for (let index = 0; index < this.suggestionOriginalList.length; index++) {
				if (this.addedSuggestionList[index] == undefined) {
					res += ',';
				} else if (this.addedSuggestionList[index].length == 0) {
					res += ',';
				} else {
					res +=  this.addedSuggestionList[index][0].functionalLocationTagId + ',';
				}
			}		
			return res;
		}
	}

	/**
	 * Get selected alternate tag no list.
	 */
	private getSelectedAlternateTagNo(): string {
		console.log('----tag no----');
		var res = '';
		for (let index = 0; index < this.suggestionOriginalList.length; index++) {
			if (this.addedSuggestionList[index] == undefined) {
				res += ',';
			} else if (this.addedSuggestionList[index].length == 0) {
					res += ',';
			} else {
				res +=  this.addedSuggestionList[index][0].functionalLocationTagNo + ',';
			}
		}

		return res;
	}

	/**
	 * Values that should be reset after successful tag creations.
	 */
	private reinitializeVariable() {
		this.tagService.getNextAvailableEquipmentNo(this.token, this);
	}

	public getUnique(arr, comp) {
		const unique = arr.map(e => e[comp]).map((e, i, final) => final.indexOf(e) === i && i).filter(e => arr[e]).map(e => arr[e]);	  
		return unique;
	}

	/**
	 * Load only 10 items initially to handle the memory issues
	 */
	private createInitialParentList() {
		this.filteredLocationParent = [];		
		for (const parent of this.allLocationParent) {
			this.filteredLocationParent[this.filteredLocationParent.length] = parent;
			if (this.filteredLocationParent.length == 10) {
				break;
			}
		}		
	}

	/**
	 * Set more values in Location Parent dropdown box
	 * @param val
	 */
	public setMoreLocationParent(template: TemplateRef<any>) {	
		this.rowData = [];
		this.selectedLocationParentRaw = [];
		for (let i = 0; i < this.allLocationParent.length; i++) {			
			this.allLocationParent[i]["description"] = this.allLocationParent[i]["description"].replace("<tagdesc><txt>", "")
			this.allLocationParent[i]["description"] = this.allLocationParent[i]["description"].replace("</txt></tagdesc>", "")
				this.rowData.push({
					id: this.allLocationParent[i]["id"],					
					tagNo: this.allLocationParent[i]["tagNo"],
					description: this.allLocationParent[i]["description"]
				})

		}
		this.bsModalRefLocationParent = this.bsModalService.show(template, { class: 'modal-lg', backdrop: 'static', keyboard: false });
	}

	public locationParentCancel() {
		this.bsModalRefLocationParent.hide();		
	}

	onRowSelected(event) {	
		//If we implement in this, two times this method is getting called. That is why I have commented.
		// if (event.data != null) {
		// 	event.data.description = "<tagdesc><txt>" + event.data.description + "</txt></tagdesc>";
		// 	this.selectedLocationParentRaw = event.data;
		// }	
	}
	onRowClick(event) {
		this.selectedLocationParentRaw =[];
		if (event.data != null) {
			event.data.description = "<tagdesc><txt>" + event.data.description + "</txt></tagdesc>";
			this.selectedLocationParentRaw = event.data;
		}	
	}

	public okMoreLocationParent() {
		if ((this.selectedLocationParent != null) && (this.selectedLocationParentRaw.length != 0)) {
			this.selectedLocationParent = [];
		}
		if (this.selectedLocationParentRaw.length != 0) {
			this.selectedLocationParent.push(this.selectedLocationParentRaw);
		}	
		this.bsModalRefLocationParent.hide();
	}

	/**
	 * This method will be called when user enter text. Then suggestion items are filtered based on user inputs.
	 * @param val text entered
	 */
	public filterItems(val: any) {		
		if (val == '' || val == undefined) {
			this.createInitialParentList();
			return;
		}		
		this.filteredLocationParent = [];
		if (val != '') {	
			this.enableMore = false;				
			this.tagService.getAllTagSuggestionModelList(this.token, this.tagId, val, this);
		}	
	}

	public altTagchange() {
		this.alternateTagNo = this.tagModel.tagNo;
		this.altTagNoChange();
	}

	public onRemoving(tag): Observable<any> {
        const confirm = window.confirm('Deleting this element will remove all the subsequent elements of the functional location. Are you sure?');
		return of(tag).pipe(filter(() => confirm));
	}

	/**
	 * Set more values in Functional Location dropdown box
	 * @param val
	 */
	public setMoreFunctionalLocation(template: TemplateRef<any>) {	
		this.rowDataFunctionalLocation = [];
		this.selectedFunctionalLocationRaw = [];
		for (let i = 0; i < this.suggestionListMore.length; i++) {			
			this.suggestionListMore[i]["description"] = this.suggestionListMore[i]["description"].replace("<tagdesc><txt>", "")
			this.suggestionListMore[i]["description"] = this.suggestionListMore[i]["description"].replace("</txt></tagdesc>", "")
				this.rowDataFunctionalLocation.push({
					altNoAndTagNo: this.suggestionListMore[i]["altNoAndTagNo"],
					alternateTagNo: this.suggestionListMore[i]["alternateTagNo"],	
					description: this.suggestionListMore[i]["description"],
					functionalLocationTagNo: this.suggestionListMore[i]["alternateTagNo"],
					tagId: this.suggestionListMore[i]["tagId"],									
					tagNo: this.suggestionListMore[i]["tagNo"]				
				})

		}
		this.bsModalRefFunctionalLocation = this.bsModalService.show(template, { class: 'modal-lg', backdrop: 'static', keyboard: false });
	}

	public fucnctionalLocationCancel() {
		this.bsModalRefFunctionalLocation.hide();		
	}

	onRowClickFunctional(event) {
		this.selectedFunctionalLocationRaw = [];
		if (event.data != null) {
			event.data.description = "<tagdesc><txt>" + event.data.description + "</txt></tagdesc>";
			this.selectedFunctionalLocationRaw = event.data;
		}	
	}

	public okMoreFunctionLocation() {					
		if ((this.addedSuggestionList[this.selectedIndex] != null) && (this.selectedFunctionalLocationRaw.length != 0)) {
			this.addedSuggestionList[this.selectedIndex] = null;
		}	
		if (this.selectedFunctionalLocationRaw.length != 0) {
				
			var array = new Array(1);
			array[0] = this.selectedFunctionalLocationRaw;
			var altTagNo = array[0].functionalLocationTagNo;
			this.addedSuggestionList[this.selectedIndex] = array;
			this.addedSuggestionList[this.selectedIndex][0].functionalLocationTagId = array[0].tagId;
			this.enableDisableItems[this.selectedIndex + 1] = false;		
			this.enable[this.selectedIndex + 1] = false;
			this.enable[this.selectedIndex] = true;
			this.prepareFunctionalLocationValue(altTagNo, this.selectedIndex);	
			this.enableMoreFunctional = false;
			this.bsModalRefFunctionalLocation.hide();			
		}	
		else {
			this.bsModalRefFunctionalLocation.hide();			
		}	
			
	}

	public altTagNoChange() {
		for (let index = 0; index < this.addedSuggestionList.length; index++) {	
			if (this.addedSuggestionList[index].length != 0) {
				if (this.tagModel.tagId === this.addedSuggestionList[index][0].functionalLocationTagId) {
					this.addedSuggestionList[index][0].functionalLocationTagNo = this.alternateTagNo;
					this.prepareFunctionalLocationValue(this.alternateTagNo, index);
				}
			}			
		}		
	}

	checkIfNumber(param:any) {
		if ((param.keyCode >= 48 && param.keyCode <= 57)) { 
			// 0-9 only
		} else {
			//this.numberAttr = true;			
			this.alertService.error("The value should be numeric");
			return false;
		}
	}

	/**
	 * set old tag attributes when the tag is loading
	 */
	private setOldTagAttributes(){
		this.oldTagAttributes = []
		this.selectedOldTagAttributeValue = []
		if (this.tagAttributes != null) {
			this.tagAttributes.forEach(element => {
				let tagAttr:TagAttribute = new TagAttribute(element.attributeId, element.attributeName, element.attributeType, 
					element.tagClsSpecId, element.classId, element.tagId, element.value, element.value, element.oldTagClassSpecId, element.required);
				this.oldTagAttributes.push(tagAttr);

				this.selectedOldTagAttributeValue[this.selectedAttributes.length] = { tagId: this.tagModel.tagId, tagClassSpecId: element.tagClsSpecId,
					classId: this.tagModel.classId, attributeId: element.attributeId, value: element.value, oldValue: element.value, 
					oldTagClassSpecId: element.oldTagClassSpecId, required: element.required };
			});
		}
	}

	/**
	 * set new tag attributes when the classification is changed
	 */
	private setNewTagAttributes(){
		this.newTagAttributes = []
		this.tagAttributes.forEach(element => {
			let tagAttr:TagAttribute = new TagAttribute(element.attributeId, element.attributeName, element.attributeType, 
				element.tagClsSpecId, element.classId, element.tagId, element.value, element.value, element.oldTagClassSpecId, element.required);
			this.newTagAttributes.push(tagAttr);
		});
		this.getMappingTagAttributes();
		//set default selected values in the new classification 
		this.prepareTagAttributes();
	}

	/**
	 * mapping tag attributes in the old and new tag classifications
	 */
	private getMappingTagAttributes(){
		this.oldTagAttributes.forEach(oldAttr => {
			this.newTagAttributes.forEach(newAttr => {
				if(!newAttr.isMapped && !oldAttr.isMapped && oldAttr.attributeType == newAttr.attributeType){
					newAttr.value = oldAttr.value;
					//set default tag attribute values in the new tag classification
					this.setDefaultTagAttributeValuesForNewClassification(newAttr.attributeId, newAttr.value);
					let tagAttributeMapping: TagAttributesMapping = new TagAttributesMapping(
						oldAttr.attributeId, oldAttr.attributeName, oldAttr.attributeType, oldAttr.tagClassSpecId, oldAttr.value,
						newAttr.attributeId, newAttr.attributeName, newAttr.attributeType, newAttr.tagClassSpecId, newAttr.value
					);
					this.tagAttributesMapped.push(tagAttributeMapping);
					newAttr.isMapped = true;
					oldAttr.isMapped = true;
				}
			});
		});
	}

	/**
	 * get tag attributes to be deleted from old classification
	 */
	private getTagAttributesToBeDeleted(){
		this.oldTagAttributes.forEach(oldAttr => {
			if(!oldAttr.isMapped){
				oldAttr.value = null;
				this.tagAttributesToBeDeleted.push(oldAttr);
			}
		});
	}

	/**
	 * check required field validation in the Attribute popup
	 */
	private checkRequiredFieldValidation(): boolean{
		 //Check required fields of tag atributes
		 console.log("tagAttributes.length===" + this.tagAttributes.length);
		 if (this.tagAttributes != null && this.tagAttributes.length > 0) {
			 for (let i = 0; i < this.tagAttributes.length; i++) {
				 console.log("tagAttributes[i].required===" + this.tagAttributes[i].required);
				 let requiredField = this.tagAttributes[i].required;
				 if (requiredField == "yes") {
					 console.log("this.selectedAttributes[i].value===" + this.selectedAttributes[i].value);
					 let selectedAttributeValue = this.selectedAttributes[i].value;
					 if (selectedAttributeValue == null || selectedAttributeValue == "") {
						 this.alertService.error("Attribute cannot be empty");
						 return false;
					 }
				 }
			 }
		 }
		 return true;
	}

	/**
	 * submit data in the Attribute popup
	 */
	public submitButtonClick(){
		if(this.checkRequiredFieldValidation()){
			//set selected values from Attribute popup to the tag edit page
			this.tagAttributes.forEach(attribute => {
				this.selectedAttributes.forEach(selectedAttribute => {
					if(attribute.attributeId == selectedAttribute.attributeId){
						attribute.value = selectedAttribute.value;
					}	
				});
			});
			this.prepareTagAttributes();

			//set oldTagClassSpecId for the mapped attributes
			this.selectedAttributes.forEach(selectedAttribute => {
				this.tagAttributesMapped.forEach(mappedAttribute => {
					if(selectedAttribute.attributeId == mappedAttribute.newAttributeId){
						selectedAttribute.oldTagClassSpecId = mappedAttribute.oldTagClassSpecId
					}
				});	
			});

			//set attributes to be deleted from the tagspecification table
			this.getTagAttributesToBeDeleted();
			this.tagAttributesToBeDeleted.forEach(element => {
				this.selectedAttributes[this.selectedAttributes.length] = { tagId: this.tagModel.tagId, tagClassSpecId: element.tagClassSpecId,
					classId: this.tagModel.classId, attributeId: element.attributeId, value: element.value, oldValue: element.value, 
					oldTagClassSpecId: element.oldTagClassSpecId, required: element.required };
			});

			this.bsModalRefTagAttributes.hide();
		}
	}

	/**
	 * check dependency of all attributes before changing tag classification
	 */
	private hasDependency(): boolean{
		var hasDependency = false;
		this.tagAttributes.forEach(attr => {
			if(attr.dependencyCount > 0){
				hasDependency = true;
			}
		});
		return hasDependency;
	}

	/**
	 * close Attribute popup when clicked on cancel button
	 */
	public tagAttributesCancel() {
		//remove selected values from tag attributes
		this.selectedAttributes.forEach(item => {
			item.value = '';
		});
		this.tagAttributes.forEach(attr => {
			attr.value = '';
		});
		this.selectedTagAttributeValue.forEach(itr => {
			itr.selectedDataId = [];
		});
		this.bsModalRefTagAttributes.hide();		
	}

	/**
	 * Achini Randeni
	 * set default selected tag attribute values for the new classification
	 * @param attributeId 
	 * @param value 
	 */
	private setDefaultTagAttributeValuesForNewClassification(attributeId: string, value: any){
		//set selected attributes
		this.selectedAttributes.forEach(item => {
			if (item.attributeId == attributeId) {
				item.value = value;
				item.classId = this.selectedTagClassList[0].classId;
			}
		});

		//set default value for the new tag attribute
		this.tagAttributes.forEach(attr => {
			if(attr.attributeId == attributeId){
				attr.value = value;
			}
		});
	}
}
