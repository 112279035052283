import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams, IAfterGuiAttachedParams } from "@ag-grid-enterprise/all-modules";
import { SharedService } from 'src/app/util/shared.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { WsCallback } from 'src/app/util/ws-callback.interface';
import { WsResponse } from 'src/app/util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';
import { TagsService } from '../tags.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DependenciesmodalComponent } from 'src/app/util/dependenciesmodal/dependenciesmodal.component';
import { TagDependencyComponent } from '../tag-dependency/tag-dependency.component';
import { DEPENDENCY_ACTION } from '../tag-util';
import { Constants } from "src/app/util/constants";

@Component({
	selector: 'app-tag-cell-renderer',
	template: `
		<div *ngIf="!disable">
			<button id="landing-table-buttons" class="btn btn-sm btn-outline-dark py-0 my-1 px-1 border-0" (click)="invoke(type, data)">
				<i class={{icon}} aria-hidden="true"></i>
			</button>
		</div>
		<div *ngIf="disable">
			<a class="btn btn-sm btn-outline-dark py-0 my-1 px-1 border-0 disabled " (click)="invoke(type, data)">
				<i class="text-black-50 {{icon}}" aria-hidden="true"></i>
			</a>
		</div>
  `,
	styles: []
})
export class TagCellRendererComponent implements ICellRendererAngularComp, WsCallback {

	private data: string;
	private status: string;
	private icon: string;
	private type: string;
	public disable: boolean = false;
	private title: string;
	private dependencyType: string;
	private bsModalRef: BsModalRef;
	private action: string;

	constructor(private sharedService: SharedService, private translate: TranslateService, private router: Router, private tagsService: TagsService,
		private modalService: BsModalService) { }

	refresh(params: any): boolean {
		return true;
	}

	//
	agInit(params: ICellRendererParams): void {
		console.log('=========params==========>' + params);

		let items = params.value.split(':')
		if (items.length >= 2) {
			this.data = items[1];
			if (items.length == 3) {
				this.status = items[2];
			}
		}
		this.setType(items[0])
		if (this.type == 'dependencies') {
			this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.VIEW;
			this.translate.get('TAGS_SEARCH_VIEW.CONFIRM_POPUP.DEPENDENCY_TITLE').subscribe((res: string) => {
				this.title = res;
			});
		} else if (this.type == 'status') {
			if (this.status == "Inactive") {
				this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.ENABLE;
				this.translate.get('TAGS_SEARCH_VIEW.CONFIRM_POPUP.ENABLE_CONFIRM').subscribe((res: string) => {
					this.title = res;
				});
			} else {
				this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.DISABLE;
				this.translate.get('TAGS_SEARCH_VIEW.CONFIRM_POPUP.DISABLE_CONFIRM').subscribe((res: string) => {
					this.title = res;
				});
			}
		} else if (this.type == 'delete') {
			this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.DELETE;
			this.translate.get('TAGS_SEARCH_VIEW.CONFIRM_POPUP.DELETE_CONFIRM').subscribe((res: string) => {
				this.title = res;
			});
		} else if (this.type == 'edit') {
			this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.EDIT;
			this.translate.get('TAGS_SEARCH_VIEW.CONFIRM_POPUP.EDIT_CONFIRM').subscribe((res: string) => {
				this.title = res;
			});
		}
		// this.sharedService.disableCellRendererExecute().subscribe(option => this.setDisable(option));
	}

	//
	afterGuiAttached?(params?: IAfterGuiAttachedParams): void {

	}

	private setType(params) {
		this.type = params;
		if (params == 'status') {
			if (this.status == "Inactive") {
				// this.disable = true;
				this.icon = 'far fa-times-circle';
			}
			else {
				this.icon = 'far fa-check-circle';
			}
		}
		else if (params == "disable_dependencies") {
			this.disable = true;
			this.icon = 'far fa-link';
		}
		else {
			this.icon = params == 'dependencies' ? 'far fa-link' : params == 'edit' ? "far fa-edit" : params == 'delete' ? 'far fa-trash-alt' : "";
		}
	}

	private setDisable(option: any) {
		let items = this.data.split(",")
		if (option != null && option.id == items[0] && this.type == 'status') {
			this.disable = option.data;
			this.icon = this.disable ? 'far fa-times-circle' : 'far fa-check-circle';
			this.status = this.disable ? 'Inactive' : 'Active';
			if (this.status == "Inactive") {
				this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.ENABLE;
				this.translate.get('STATEMENT_CELL_RENDER.ENABLE_CONFIRM').subscribe((res: string) => {
					this.title = res;
				});
			} else {
				this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.DISABLE;
				this.translate.get('STATEMENT_CELL_RENDER.DISABLE_CONFIRM').subscribe((res: string) => {
					this.title = res;
				});
			}
		}
	}

	/**
	 * Fires when a user clicks on a button on the grid view.
	 * @param type edit, status, delete etc
	 * @param data tag id will be returned
	 */
	public invoke(type: String, data: any) {
		switch (type) {
			case 'dependencies':
				this.action = DEPENDENCY_ACTION.GET_DEPENDENCIES;
				localStorage.setItem("isDelete", "false");
				this.viewDependencies(false);
				break;
			case 'status':
				if(this.status == "Inactive"){
					this.action = DEPENDENCY_ACTION.ENABLE;
				}else{
					this.action = DEPENDENCY_ACTION.DISABLE;
				}
				localStorage.setItem("isDelete", "false");
				this.viewDependencies(false);
				break;
			case 'edit':
				this.action = DEPENDENCY_ACTION.EDIT;
				localStorage.setItem("isDelete", "false");
				this.viewDependencies(false);
				break;
			case 'delete':
				this.action = DEPENDENCY_ACTION.DELETE;
				localStorage.setItem("isDelete", "true");
				this.viewDependencies(true);
				break;
			default:
				this.viewDependencies(true);
				break;
		}
	}

	onSuccess(data: WsResponse, serviceType: WsType) {
		if (serviceType == WsType.GET_TAG_DEPENDENCIES_BY_TAG) {
		}else if(serviceType == WsType.CHANGE_TAG_STATUS){}
	}
	onFail(data: WsResponse, serviceType: WsType) {
		if (serviceType == WsType.GET_TAG_DEPENDENCIES_BY_TAG) {
		}
	}

	viewDependencies(dflag: boolean) {
		this.dependenciesModalWithComponent(this.title, dflag, true);
	}

	dependenciesModalWithComponent(header: String, dflag: boolean, cflag: boolean) {
		const initialState = {
			headerTitle: header,
			delFlag: dflag,
			cancelFlag: cflag,
			checkboxVal: this.status == "Inactive" ? true : false
		};
		this.sharedService.setFileId(this.data);
		this.sharedService.setFileType(Constants.DEPENDENCY_ITEM_TYPE.TAG);
		this.sharedService.setDependencyMessageType(this.dependencyType);
		this.sharedService.setParent(this);
		this.sharedService.setAction(this.action);
		this.bsModalRef = this.modalService.show(TagDependencyComponent, { initialState, class: 'modal-lg' });
	}
}
