import { Component, OnInit, QueryList, ElementRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FloatingButtonCallback } from '../floating-button-callback.interface';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DocumenttypetreeComponent } from '../../../util/documenttypetree/documenttypetree.component';
import { DocumentTypeService } from '../../../util/documenttypetree/documenttype.service';
import { TreeListener } from '../../../util/documenttypetree/tree-listener.interface';
import { WsCallback } from '../../../util/ws-callback.interface';
import { WsResponse } from '../../../util/ws-response.model';
import { WsType } from '../../../util/ws-type';
import { AlertService } from '../../../util/alert/alert.service';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpEventType } from '@angular/common/http';
import { LocalStorage } from '../../../util/localstorage.service';
import { ServiceUrls } from '../../../util/service-urls';
import { FileUploadService } from './file-upload.service';
import { LoadingService } from '../../../util/loading/loading.service';
import { TranslateService } from '@ngx-translate/core';
import { UserVariable } from 'src/app/util/common/user-variable';
import { CommonUtil } from '../../../util/common/common-util';

@Component({
  selector: 'app-new-file',
  templateUrl: './new-file.component.html',
  styleUrls: ['./new-file.component.css']
})
export class NewFileComponent implements OnInit, TreeListener, WsCallback {
  public title: string;
  public selectedFolder: any;
  public floatingButtonCallback: FloatingButtonCallback;
  public attributeList: any;
  public haveProperties: boolean = true;
  public documentType: string;
  public uploadFileName: string;
  public fileComment: string;

  private treeBsModalRef: BsModalRef;
  private documentTypeId: string;
  private uploadingFile: File;
	private fileExtension: string;
  selectedType: any;
  uploadFileNameWithExt: string;

  constructor(public bsModalRef: BsModalRef, private modalService: BsModalService, private documentTypeService: DocumentTypeService,
    private alert: AlertService, private http: HttpClient, private fileUploadService: FileUploadService, private loadingService: LoadingService,
    private translate: TranslateService, private commonUtil: CommonUtil) { }

  ngOnInit() {
  }

  public loadDocumentTypes() {
    const initialState = {
      title: 'Select a Document Type',
      treeListener: this,
      initial: true
    };
    this.treeBsModalRef = this.modalService.show(DocumenttypetreeComponent, { initialState, class: 'modal-lg' });
    this.treeBsModalRef.content.closeBtnName = 'Close';
  }

  /**
 * Clicks on the save button of the tree view popup
 * @param file selected file
 */
  onSaveButtonClick(file: any, texts: QueryList<ElementRef>, options: QueryList<ElementRef>, documentTypeAttributes: any) {
    this.documentTypeService.getDocumentTypeAttributes(file.node.id, this.selectedFolder, this);
    this.documentType = file.node.text;
    this.selectedType = file.node.text;
    this.documentTypeId = file.node.id;
    this.treeBsModalRef.hide();
  }

  onFileChanged(event) {
    console.log("onfile change= ")
    this.uploadingFile = <File>event.target.files[0];
    if (this.uploadingFile != undefined) {
			let parts = this.uploadingFile.name.split('.')
      if(parts.length>1){
				this.fileExtension = parts.pop()
        this.uploadFileName = parts.join('.')
      }else{
        this.translate.get('NEW_FILE.INVALID_FILE_NAME').subscribe((res: string) => {
          this.alert.error(res, false);
        });
			}
    } else {
      this.uploadFileName = '';
	}
  }

  validateForm(): boolean {
    if(this.uploadFileName != undefined) {
      this.uploadFileName = this.uploadFileName.trim()
    }
    if (this.uploadFileName) {
    } else {
      return false;
    }
    if (this.documentTypeId) {
    } else {
      this.documentType = "";
      return false;
    }
    if (this.uploadingFile == undefined) {
      return false;
    }
    if (this.uploadingFile.size > 104857601) {
      this.translate.get('NEW_FILE.SIZE_LIMIT_EXCEEDED').subscribe((res: string) => {
        this.alert.error(res, false);
      });
      return false;
    }
    if (this.selectedType != this.documentType) {
      this.documentType = "";
      return false;
    }

    //Check for attribute values
    if (this.attributeList != undefined && this.attributeList.length != undefined && this.attributeList.length > 0) {
      var isEmptyAttribute: boolean = false;
      this.attributeList.forEach(item => {
        if(item.required == 'yes' && (item.value == null || item.value == "")){
					this.alert.error(this.translate.instant('NEW_FILE.ATTRIBUTE.EMPTY_ATTRIBUTE_ERROR_MESSAGE'));
          isEmptyAttribute = true;
        }
        // if (item.attributeName == 'Document') {
        //   if (item.documents != undefined) {
        //     isEmptyAttribute = false;
        //   }
        // } else if (item.attributeName == 'c1') {
        //   if (item.components != undefined) {
        //     isEmptyAttribute = false;
        //   }
        // } else if (item.attributeName == 'System') {
        //   if (item.systems != undefined) {
        //     isEmptyAttribute = false;
        //   }
        // }
      });
      if (isEmptyAttribute) {
        return false;
      }
    }

    return true;
  }

  onUpload() {
    if (!this.validateForm()) {
      return;
    }

    var loadingMessage;
    this.translate.get('NEW_FILE.LOADING_MESSAGE').subscribe((res: string) => {
			loadingMessage = res;
		});
    this.loadingService.showLoading(true, null, loadingMessage, null);
    var attributeValueXml: string = '';
    //creating attribute xml string.
    if (this.attributeList != undefined && this.attributeList.length != undefined && this.attributeList.length > 0) {
      attributeValueXml = '<attrs>';
      this.attributeList.forEach(item => {
        attributeValueXml += "<attr type=\"text\" name='" + item.attributeName + "' typeattributeid='" + item.attributeId + "'>" + item.value + "</attr>";
      });
      attributeValueXml += '</attrs>';
    }
    this.uploadFileNameWithExt = this.uploadFileName + "."+this.fileExtension;
    let token = UserVariable.getUserToken();
    if(!this.commonUtil.validateToken(token)){
            return;
    }
    const uploadData = new FormData();
    uploadData.append("fileName", this.uploadFileNameWithExt);
    uploadData.append("typeId", this.documentTypeId);
    uploadData.append("comment", this.fileComment);
    uploadData.append("parentFolderId", this.selectedFolder.id);
    uploadData.append("attributes", attributeValueXml);
    uploadData.append("token", token);
    uploadData.append("file", this.uploadingFile, this.uploadFileName);

    this.fileUploadService.uploadFile(uploadData, this);
  }

  public onSubmitButtonClick() {
    this.onUpload();
  }
  onSuccess(data: WsResponse, serviceType: WsType) {
    if (serviceType == WsType.DOCUMENT_TYPE_ATTRIBUTES_VIEW) {
      this.attributeList = data.payload;
      if (data.payload.length == 0) {
        this.haveProperties = false;
      } else {
        this.haveProperties = true;
      }
    } else if (serviceType == WsType.FILE_UPLOAD) {
      this.alert.success(data.statusDescription);
      this.floatingButtonCallback.hide();
      this.loadingService.hideLoading();
    }
  }

  onFail(data: WsResponse, serviceType: WsType) {
    if (serviceType == WsType.DOCUMENT_TYPE_ATTRIBUTES_VIEW) {
      this.alert.error(data.statusDescription, false);
    } else if (serviceType == WsType.FILE_UPLOAD) {
      this.alert.error(data.statusDescription, false);
      this.loadingService.hideLoading();
    }
  }

  checkComponent(type:any, name:any){
		if(type == 'Component' || name == 'c1' ){
		   return true;
	   }
   }

   checkSystem(type:any, name:any){
		 if(type == 'System' || name == 'System' ){
		   return true;
	   }
   }
   checkDocument(type:any, name:any){
		 if(type == 'Document' || name == 'Document'){
		   return true;
	   }
   }
}
