import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { DictionaryService } from '../dictionary.service';
import { WsCallback } from '../../../util/ws-callback.interface';
import { WsType } from '../../../util/ws-type';
import { WsResponse } from '../../../util/ws-response.model';
import { AlertService } from '../../../util/alert/alert.service';
import { SharedService } from 'src/app/util/shared.service';


@Component({
	selector: 'app-addwordtodictionary',
	templateUrl: './addwordtodictionary.component.html',
	styleUrls: ['./addwordtodictionary.component.css']
})
export class AddwordtodictionaryComponent implements OnInit, WsCallback {

	public languageList: Array<any> = [
		{ 'id': '', 'text': 'Select Language' },
		{ 'id': '0', 'text': 'en-US' },
		{ 'id': '1', 'text': 'en-ES' }];
	private language: any = null;
	private languageId: any = null;
	public placeholder: any = "";

	@ViewChild('newWord') newWord: ElementRef;


	constructor(private translate: TranslateService,
		private router: Router, private sharedService: SharedService,
		private dictionaryService: DictionaryService, private alertService: AlertService) { }

	ngOnInit() {
	}

	save() {
		if (this.newWord.nativeElement.value == undefined || this.newWord.nativeElement.value.trim() == "") {
			this.alertService.error(this.translate.instant('DICTIONARY_ADD.NULL_WORD'), true);
			return;
		} else if (!(this.language == "en-US" || this.language == "en-ES")) {
			this.alertService.error(this.translate.instant('DICTIONARY_ADD.LANGUAGE_NOT_SELECTED'), true);
		} else {
			this.dictionaryService.addWordToDictionary(this.language, this.newWord.nativeElement.value, this)
		}
	}

	cancel() {
		this.router.navigateByUrl('dictionary');
	}

	onSuccess(data: WsResponse, serviceType: WsType): void {
		if (serviceType == WsType.ADD_WORD_TO_DICTIONARY) {
			//console.log("===onSuccess " + data);
			// console.log("payload= "+data.payload);
			// console.log("statusCode= "+data.statusCode);
			// console.log("statusDescription= "+data.statusDescription);
			// console.log("statusName= "+data.statusName);
			this.alertService.success(this.translate.instant('DICTIONARY_ADD.SUCCESS'), true);
			this.newWord.nativeElement.value = null;
			this.sharedService.changeDictionaryComponentsRequest('dictionary')
		}
	}

	onFail(data: WsResponse, serviceType: WsType): void {
		if (serviceType == WsType.ADD_WORD_TO_DICTIONARY) {
			// console.log("===onFail " + serviceType);
			// console.log("payload= "+data.payload);
			// console.log("statusCode= "+data.statusCode);
			// console.log("statusDescription= "+data.statusDescription);
			// console.log("statusName= "+data.statusName);
			if (data.statusDescription == "Word_page_error_word_already_exists_in_project") {
				this.alertService.error(this.translate.instant('DICTIONARY_ADD.ALREADY_EXISTS'), true);
			} else {
				this.alertService.error(this.translate.instant('DICTIONARY_ADD.FAILED'), true);
			}
		}
	}

	public languageSeleted(value: any): void {
		this.language = value.text;
		this.languageId = value.id;
	}
}
