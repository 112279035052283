export class System {
    constructor(
        public systemId?: String,

        public projectId?: String,
        public projectName?: any,
        public clientName?: any,

        // Status (Enable/Disable)
        public status?: String,

        // System details
        public systemNo?: String,
        public systemName?: String,

        // Facility details
        public selectedFacility?: any,
        public facilityId: String = null,
        public facilityName: String = "",

        // Parent system details
        public selectedParentSystem?: any,
        public parentId: String = null,
        public parentSystemNo?: String,
        public parentSystemName?: String,

        // Reusable change info
        public reusableChangeInfo?: ReusableChangeInfo,
        public reusableChangeInfoNo?: ReusableChangeInfo,
        public reusableChangeInfoParent?: ReusableChangeInfo,
        
        // Sibilings and Children
        public siblings?: any,  // Parent system children
        public children?: any,   // System Children

        //isCommisioning
        public isCommissioning?: boolean,
        
        public referenceCount?: number,
        public dependencyCount?: number,
        public procDependencyCount?: number,
        public iceCourseDependencyCount?: number,
        public checkedOutTopicCount?: number,
        public checkedInTopicCount?: number
    ) {

    }
}

export class ReusableChangeInfo{
    constructor(
        public oldValue?: any,
        public newValue?: any,
        public changeInitiatedUserName?: any,
        public changeInitiatedTimeStampFormatted?: any
    ){

    }
}

export class ParentSystem{
    constructor(
        public id?: any,
        public text?: any,
        public number?: any
    ){

    }
}

export class Facility{
    constructor(
        public id?: any,
        public text?: any
    ){

    }
}