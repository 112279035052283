import { Component, OnInit, Input, TemplateRef, EventEmitter, ViewChild, ElementRef, QueryList } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CategorytreeComponent } from '../../../util/categorytree/categorytree.component';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SharedService } from '../../../util/shared.service';
import { HistoryService } from '../../history/history.service';
import { ComponentsService } from './../components.service';
import { Constants, UI_LAYOUT, LocalStorageConst } from '../../../util/constants';
import { AlertService } from '../../../util/alert/alert.service';
import { DependenciesmodalComponent } from '../../../util/dependenciesmodal/dependenciesmodal.component';
import { LocalStorage } from '../../../util/localstorage.service';
import { WsCallback } from '../../../util/ws-callback.interface';
import { WsType } from '../../../util/ws-type';
import { WsResponse } from '../../../util/ws-response.model';

@Component({
    selector: 'app-addeditcomponents',
    templateUrl: './addeditcomponents.component.html',
    styleUrls: ['./addeditcomponents.component.css']
})
export class AddeditcomponentsComponent implements OnInit {

    private modalRef: BsModalRef;
    public dataLoad: boolean = false;
    public isEdit: boolean = false;
	private dependencyType: string;
    public component: any = null;
    public componentId: any = null;

    categorySelected = new EventEmitter<any>()
    tempCategoryItem: any = null
    public categoryType: string = null;
    selectedCategoryItem: any = { 'catName': '', 'catId': '' }
    public reusablesName: any;
    public reusablesModifiedBy: any;
    public rusableModifiedTime: any;
	public reusable: boolean = false;
	public messageCategoryEmpty: any;
	public messageSuccessfulEdit: any;
	public messageNameCannotEmpty: any;
	public messageValueCannotEmpty: any;
    public messageAddedSuccessfully: any;
    
    public showSC: boolean = false    
    public rowCount: number = 4    
	public caretPos: number = 0;
	public descIteration: number = 0;

    @ViewChild('componentName') componentName: ElementRef;
    @ViewChild('componentValue') componentValue: ElementRef;
    @ViewChild('categoryId') categoryId: ElementRef;
	@ViewChild('myForm') formValues;
	@ViewChild('checkingTemplate') checkingTemplate: TemplateRef<any>

    constructor(private translate: TranslateService,
        private router: Router,
        private componentsService: ComponentsService,
        private sharedService: SharedService,
        private modalService: BsModalService,
        private alertService: AlertService,
        public bsModalRef: BsModalRef) {

			this.translate.get('ADD_EDIT_COMPONENT.CATEGORY_CANNOT_BE_EMPTY').subscribe((res: string) => {
				this.messageCategoryEmpty = res;
				this.translate.get('ADD_EDIT_COMPONENT.SUCCESSFULLY_EDIT').subscribe((res: string) => {
					this.messageSuccessfulEdit = res;
					this.translate.get('ADD_EDIT_COMPONENT.NAME_CANNOT_BE_EMPTY').subscribe((res: string) => {
						this.messageNameCannotEmpty = res;
						this.translate.get('ADD_EDIT_COMPONENT.VALUE_CANNOT_BE_EMPTY').subscribe((res: string) => {
							this.messageValueCannotEmpty = res;
							this.translate.get('ADD_EDIT_COMPONENT.COMPONENT_ADDED_SUCCESSFULLY').subscribe((res: string) => {
								this.messageAddedSuccessfully = res;
							});
						});
					});
				});
			});
    }

    getType() {
        return localStorage.getItem("value");
    }

    ngOnInit() {
        if (this.sharedService.getFileId() != "-1" && this.sharedService.getFileId() != -1) {
            this.isEdit = true;
            this.componentId = this.sharedService.getFileId();
            this.componentsService.getComponentDataByComponentId(this.sharedService.getFileId()).subscribe(
                data => {
                    if (data.payload != null) {
                        this.component = data.payload;
                        if (data.payload.reusableChangeInfo != null) {
                            this.reusable = true;
                            this.reusablesName = "Old Value : " + data.payload.reusableChangeInfo.oldValue;
                            this.reusablesModifiedBy = "Modified By : " + data.payload.reusableChangeInfo.changeInitiatedUserName;
                            if (data.payload.reusableChangeInfo.changeInitiatedTimeStampFormatted != null) {
                                this.rusableModifiedTime = "Modified Time : " + data.payload.reusableChangeInfo.changeInitiatedTimeStampFormatted;
                            }
                            this.component.componentValue = data.payload.reusableChangeInfo.newValue;
                        }
                        else {

                            this.component.componentValue = data.payload.componentValue;
                        }
                        //             // console.log(data.payload);

                        this.component.category = data.payload.categoryName;
                        this.component.categoryId = data.payload.categoryId;
                        this.selectedCategoryItem.catId = data.payload.categoryId;
                        this.selectedCategoryItem.catName = data.payload.categoryName;
                        console.log('selectedCategoryItem.catId=' + this.selectedCategoryItem.catId);
                        console.log('selectedCategoryItem.catName=' + this.selectedCategoryItem.catName);
                        this.tempCategoryItem = this.selectedCategoryItem
                    } else {
                        //do nothing
                    }
                },
                error => {
                    this.alertService.clear()
                    this.alertService.error(error.statusDescription)
                });
            //localStorage.setItem("value", "0");
        }
        else {
            this.dataLoad = true;
        }
    }

    public showCategoryList(template: TemplateRef<any>) {
        this.categoryType = "comp_category";
        if (this.selectedCategoryItem.catName != '') {
            this.tempCategoryItem = this.selectedCategoryItem
            this.selectedCategoryItem = {
                'catName': '',
                'catId': ''
            }
        } else {
            this.tempCategoryItem = {
                'catName': '',
                'catId': ''
            }
        }
        
        this.modalRef = this.modalService.show(template, { class: 'modal-lg', backdrop: 'static', keyboard : false })
    }

    /**
    * select category output event
    * @param event 
    */
    selectedCategory(event: any) {
        console.log('[selectedCategory] (event) ' + JSON.stringify(event));
		this.selectedCategoryItem = event
		if(this.component != null)
		{
		 	this.component.category = event.catName;
		}
        //this.component.
        this.tempCategoryItem = event
        this.categoryId = this.tempCategoryItem.catId;
        console.log('catId=' + this.tempCategoryItem.catId);
        console.log('selectedCategoryItem.catId=' + this.selectedCategoryItem.catId);
        console.log('selectedCategoryItem.catName=' + this.selectedCategoryItem.catName);
        this.categorySelected.emit(event)
        this.modalRef.hide()
    }

    /**
     * close button click event on modal
     * hide model
     */
    closeButtonClickEvent() {
        if (this.tempCategoryItem != null) {
            this.selectedCategoryItem = this.tempCategoryItem
        } else {
        }
        this.modalRef.hide()
    }

    viewDependencies(dflag: boolean) {
        this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.VIEW;
        this.dependenciesModalWithComponent('Dependencies List', false, true);
    }

    dependenciesModalWithComponent(header: String, dflag: boolean, cflag: boolean) {
        const initialState : object = {
            headerTitle: header,
            delFlag: dflag,
            cancelFlag: cflag,
            checkboxVal: false
        };
        this.sharedService.setFileId(this.component.componentId);
        this.sharedService.setFileType(Constants.DEPENDENCY_ITEM_TYPE.COMPONENT);
        this.sharedService.setAction("getDependencies");
		this.sharedService.setDependencyMessageType(this.dependencyType);
        this.sharedService.setParent(this);
        this.modalRef = this.modalService.show(DependenciesmodalComponent, { initialState, class: 'modal-lg' });
	}
	
	load()
	{
		this.bsModalRef = this.modalService.show(this.checkingTemplate, { class: 'modal-sm' })
	}

    save() {
        if (this.sharedService.getFileId() != "-1" && this.sharedService.getFileId() != -1) {
            console.log("saving Item " + this.component.componentName + " this.component.componentValue " + this.component.componentValue, + "this.categoryId" +  this.component.categoryId)
            if (this.component == undefined || this.component.componentName == undefined
                    || this.component.componentName == "" || this.component.componentName.trim() == "") {
                this.alertService.clear();
                this.alertService.error(this.messageNameCannotEmpty);
            } else if (this.component.componentValue == undefined || this.component.componentValue == ""
                    || this.component.componentValue.trim() == "") {
                this.alertService.clear();
                this.alertService.error(this.messageValueCannotEmpty);
            } else if (this.component.category == undefined || this.component.category == "" 
                    || this.component.category.trim() == "") {
                this.alertService.clear();
                this.alertService.error(this.messageCategoryEmpty);
            } else {
                console.log('selectedCategoryItem.catId=' + this.selectedCategoryItem.catId);
                this.componentsService.updateComponent(
                    this.sharedService.getFileId(),
                    this.component.componentName,
                    this.component.componentValue,
                    this.selectedCategoryItem.catId).subscribe(
                        data => {
							console.log("this.selectedCategoryItem" + this.selectedCategoryItem.catName)
                            //Comment this to remove updating UI and add user message
                            //this.componentsService.setItemData(this.sharedService.getFileId(), this.component.componentName, this.component.componentValue, this.selectedCategoryItem.catName);
                            console.log("success");
                            // this.router.navigateByUrl("home")
							this.bsModalRef.hide()
                            this.alertService.clear();
                            this.alertService.info(this.messageSuccessfulEdit +". " +this.translate.instant("REUSABLES.REFRESH_REUSABLE_TABLE"));				
                        },
                        error => {
                            this.alertService.clear()
                            this.alertService.error(error.statusDescription);
                        });
            }
        } else {
			console.log("categoryId " + this.categoryId);
            if (this.componentName.nativeElement.value == undefined || this.componentName.nativeElement.value == ""
                || this.componentName.nativeElement.value.trim() == "")
			{
				this.alertService.clear();
                this.alertService.error(this.messageNameCannotEmpty);
			}
            else if(this.componentValue.nativeElement.value == undefined || this.componentValue.nativeElement.value == ""
                || this.componentValue.nativeElement.value.trim() == "")
			{
				this.alertService.clear();
                this.alertService.error(this.messageValueCannotEmpty);
            }
            else if (this.categoryId == undefined || ((this.categoryId != undefined ) && (this.selectedCategoryItem.catId == ""))) 
            {
                this.alertService.clear();
                this.alertService.error(this.messageCategoryEmpty);
            } else {
                let to: any = false;
                if (to) { clearTimeout(to); }
                to = setTimeout(() => {
                    this.componentsService.addComponent(
                        this.componentName.nativeElement.value,
                        this.componentValue.nativeElement.value,
                        this.selectedCategoryItem.catId).subscribe(
                            data => {
                                this.alertService.clear()
                                this.alertService.info(this.messageAddedSuccessfully +". " +this.translate.instant("REUSABLES.REFRESH_REUSABLE_TABLE"));
                                this.bsModalRef.hide();				
                            },
                            error => {
                                this.alertService.clear()
                                this.alertService.error(error.statusDescription);
                            });
                }, 200);
                // this.router.navigateByUrl("home")
                // this.bsModalRef.hide()
            }
        }
    }
    cancel() {
        this.alertService.clear()
        this.tempCategoryItem = null
        console.log('[cancel]');
        this.bsModalRef.hide();
        // this.router.navigateByUrl("home")
    }
    onSuccess(data: WsResponse, serviceType: WsType): void {
        if (data.payload != null && serviceType == WsType.COMPONENT_DATA_VIEW) {
            this.component = data.payload;
            this.dataLoad = true;
        } else if (serviceType == WsType.COMPONENT_UPDATE) {
            // this.router.navigateByUrl("home")
            this.bsModalRef.hide();
        }
    }
    onFail(res: WsResponse, serviceType: WsType): void {
    }

    public showSpecialCharacters() {
		this.showSC = !this.showSC;
		this.rowCount = 2;
    }
    
    getCaretPos(oField) {		
		if (oField.selectionStart || oField.selectionStart == '0') {
		   this.caretPos = oField.selectionStart;
		}
    }
    
    /**
	 * Set special character
	 * @param val
	 */
	public setSpecialCharacter(val: any) {
        if (this.sharedService.getFileId() != "-1" && this.sharedService.getFileId() != -1) {
            this.component.componentValue = [this.component.componentValue.slice(0, this.caretPos), val.target.innerText, 
                this.component.componentValue.slice(this.caretPos)].join('');
            this.hidePanels();
        } else {           
            if (this.caretPos != 0 || this.descIteration > 0) {	
                this.componentValue.nativeElement.value  = [this.componentValue.nativeElement.value.slice(0, this.caretPos), val.target.innerText, 
                    this.componentValue.nativeElement.value.slice(this.caretPos)].join('');
            } else {
                this.componentValue.nativeElement.value = this.componentValue.nativeElement.value + '' +  val.target.innerText;
            }		
            this.descIteration++;
            this.hidePanels();
        }   
    }
    
    private hidePanels() {
		this.showSC = false;
		this.rowCount = 4;
	}
}
