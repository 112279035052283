import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[dynamic-ice-item]'
})
export class DynamicIceCourseItemDirective {

  constructor(public viewContainer: ViewContainerRef) { }

}
