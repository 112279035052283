import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DocumentStoreService } from './documentstore.service';
import { UserVariable } from '../../util/common/user-variable';
import { AlertService } from '../../util/alert/alert.service';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { LoadingService } from '../../util/loading/loading.service';

@Component({
  selector: 'app-alert-model',
  template: `   
   <div class="modal-header">
      <h4 class="modal-title pull-left">{{ title | translate }}</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <div class="container">
         <h6>
            {{ bodyText | translate }}
         </h6>
      </div>
   </div>
   <div *ngIf="showFooter()" class="modal-footer">
      <button type="button" class="btn btn-primary btn-sm" (click)="nextbuttonClick(); bsModalRef.hide()">{{ 'Next' | translate}}</button>
      <button type="button" class="btn btn-secondary btn-sm" (click)="bsModalRef.hide()">{{ 'CANCEL' | translate}}</button>
   </div>
   `
  })
export class AlertModelComponent {

  public title: string = 'More Info';
   private projectId: string = UserVariable.projectId
   public bodyText: string
   public showFooterEle: string
   public id: string
   public prcStatus: string
   public click: string
   constructor(public bsModalRef: BsModalRef, private documentStoreService: DocumentStoreService, private alertService: AlertService, private router: Router,
      private modalService: BsModalService, private loadingService: LoadingService) { }

   ngOnit() {     
   }

   public showFooter(){
      if (this.showFooterEle == "Yes") {
         return true;
      } else {
         return false;
      }
   }

   public nextbuttonClick() {
      if (this.click == "regOutput") {
         this.loadingService.showLoading(true, null, "", null)
         this.documentStoreService.regenarateOutput(this.id, "0")
            .subscribe(data => {
               this.loadingService.hideLoading()               
               this.bsModalRef.content.bodyText = data.payload;              
               this.bsModalRef = this.modalService.show(AlertModelComponent);               
               //this.router.navigateByUrl('/documentstore')
               location.reload();
            },
               error => {
                  this.loadingService.hideLoading()
                  this.alertService.clear()
                  this.alertService.error(error.statusDescription)
               });
      } else {
         this.loadingService.showLoading(true, null, "", null)
         this.documentStoreService.terminateOutput(this.id, "4")
            .subscribe(data => {
               this.loadingService.hideLoading()               
               this.bsModalRef.content.bodyText = data.payload;              
               this.bsModalRef = this.modalService.show(AlertModelComponent);               
               //this.router.navigateByUrl('/documentstore')
               location.reload();
            },
               error => {
                  this.loadingService.hideLoading()
                  this.alertService.clear()
                  this.alertService.error(error.statusDescription)
               });

      }

   }
}
