import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, QueryList, ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AlertService } from 'src/app/util/alert/alert.service';
import { LoadingService } from 'src/app/util/loading/loading.service';
import { SelectComponent } from 'src/app/util/ng2-select/select';
import { WsCallback } from 'src/app/util/ws-callback.interface';
import { WsResponse } from 'src/app/util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';
import { ManageotnService } from '../manageotn.service';
import * as moment from 'moment';

@Component({
  selector: 'app-addeditotn',
  templateUrl: './addeditotn.component.html',
  styleUrls: ['./addeditotn.component.css']
})
export class AddeditotnComponent implements OnInit, WsCallback {

  public edit: boolean;
  public bsConfig: Partial<BsDatepickerConfig>
  @ViewChildren(SelectComponent) selectElem: QueryList<SelectComponent>

  disableform: boolean = false;
  disableAssets: boolean = false;
  disableVendor: boolean = false;
  disableSaveButton: boolean = false;
  loadingOTNSub: Subscription;

  public selectedAssetList = [];
  public assetList = [];

  public vendorList = [];
  public selectedVendor;

  public otnStatus: string = "NotIssued";
  public targetCompletionDate: any;

  private loadingHandler: any;
  private otnId: string;

  constructor(
    private manageotnService: ManageotnService,
    private loadingService: LoadingService,
    private alertService: AlertService,
    private translate: TranslateService,
    public bsModalRef: BsModalRef,
  ) {

    this.bsConfig = Object.assign({}, { containerClass: 'theme-default', customTodayClass: 'custom-today-class' });
  }

  ngOnInit(): void {
    if (this.edit) {
      this.loadingHandler = new AddeditotnComponent.LoadingHandler();
      // loading OTN after loading all other items
      this.loadingOTNSub = this.loadingHandler.getLoadedSub().subscribe(() => {
        this.loadingService.showLoading(true, false, 'Loading OTN data', 0);
        this.manageotnService.getOTNDataByOTNId(this.otnId, this)
      });
      this.loadingHandler.init(2, this.loadingService);
      this.manageotnService.getAssetListWithOTNTargetCompletionDate(this);
      this.manageotnService.getAllVendors(this);

      localStorage.setItem("value", "0");
    }
    else {
      this.loadingHandler = new AddeditotnComponent.LoadingHandler();
      this.loadingHandler.init(2, this.loadingService);
      this.manageotnService.getAssetListWithOTNTargetCompletionDate(this);
      this.manageotnService.getAllVendors(this);
    }
  }

  onSuccess(data: WsResponse, serviceType: WsType) {
    if (serviceType == WsType.GET_ALL_VENDORS) {
      this.vendorList = [];
      if (data.payload != null) {
        data.payload.forEach(element => {
          this.vendorList.push({ 'id': element.vendorId, 'text': element.vendorName });
        });
      }
      this.loadingHandler.handle();
    }
    else if (serviceType == WsType.GET_ASSETS) {
      this.assetList = [];
      if (data.payload != null) {
        data.payload.forEach(element => {
          this.assetList.push({ 'id': element.tagId, 'text': element.tagNo });
        });
      }
      this.loadingHandler.handle();
    }
    else if (serviceType == WsType.GET_OTN_BY_ASSET_ID) {
      if (data.payload != null && data.payload.length > 0) {
        this.alertService.clear()
        this.translate.get('OTN_ADD_EDIT.ASSETS_ADD_WARNING').subscribe((res: string) => {
          this.alertService.warn(res);
        });
      }
    }
    else if (serviceType == WsType.ADD_NEW_OTN) {
      this.loadingHandler.handle();
      this.alertService.clear()
      this.translate.get('OTN_ADD_EDIT.ADD_SUCCESS').subscribe((res: string) => {
        this.alertService.success(res);
      });
      this.bsModalRef.hide();
    }
    else if (serviceType == WsType.GET_OTN_BY_OTN_ID) {
      this.loadingService.hideLoading();
      if (data.payload != null) {
        this.setValues(data.payload)
      }
    }
    else if (serviceType == WsType.UPDATE_OTN) {
      this.loadingHandler.handle();
      this.alertService.clear()
      this.translate.get('OTN_ADD_EDIT.UPDATE_SUCCESS').subscribe((res: string) => {
        this.alertService.success(res);
      });
      this.bsModalRef.hide();
      this.manageotnService.loadTableDataRequest();
    }
  }

  onFail(data: WsResponse, serviceType: WsType) {
    if (serviceType == WsType.GET_ALL_VENDORS) {
      this.alertService.error(data.statusDescription);
      this.loadingHandler.handle();
    }
    else if (serviceType == WsType.GET_ASSETS) {
      this.alertService.clear()
      this.alertService.error(data.statusDescription)
    }
    else if (serviceType == WsType.ADD_NEW_OTN) {
      this.loadingHandler.handle();
      if (data.statusDescription != null) {
        this.alertService.clear()
        this.alertService.error(data.statusDescription)
      }
      else {
        this.alertService.clear()
        this.translate.get('OTN_ADD_EDIT.ADD_FAILED').subscribe((res: string) => {
          this.alertService.error(res);
        });
      }
    }
    else if (serviceType == WsType.ADD_NEW_OTN) {
      if (data.statusDescription != null) {
        this.alertService.clear()
        this.alertService.error(data.statusDescription)
      }
      else {
        this.alertService.clear()
        this.translate.get('OTN_ADD_EDIT.UPDATE_FAILED').subscribe((res: string) => {
          this.alertService.error(res);
        });
      }
    }
  }


  setValues(payload) {
    if (payload != undefined || payload != null) {
      var selectedAssets = [];
      if (payload.assetList != null && payload.assetList.length > 0) {
        payload.assetList.forEach(asset => {
          selectedAssets.push({'id' : asset.id, 'text': asset.name});
        });
      }
      this.selectedAssetList = selectedAssets;

      if (payload.vendorID) {
        this.selectedVendor = this.vendorList.filter(x => x.id == payload.vendorID);
      }

      if (payload.targetCompletionDate) {
        this.targetCompletionDate = moment.utc(payload.targetCompletionDate).format("M/D/YYYY");
      }
      if (payload.otnstatus) {
        this.otnStatus = payload.otnstatus
      }
      
      if (this.otnStatus != "NotIssued") {
        this.disableAssets = true;
        this.disableVendor = true;
      }

    }
  }

  //check whether the selected asset is already added in onther otn
  selectItemFromSuggestions(event, data) {
    if (this.selectedVendor != null && this.selectedVendor.length > 0) {
      let seletedAssetsIdList = [];
      if (this.selectedAssetList.length > 0) {
        this.selectedAssetList.forEach((item) => {
          seletedAssetsIdList.push(item.id)
        })
      }
      let data = {
        vendorId: this.selectedVendor[0].id,
        assetIdList: seletedAssetsIdList,
        id: this.edit ? this.otnId : null
      }
      this.manageotnService.checkAssetVendorCombination(JSON.stringify(data)).subscribe(
        res => {
          var payload = res.payload;
          this.dispayAssetVendorCombinationError(payload)
          if (payload == null || payload.length == 0) {
            this.manageotnService.getOTNListByAssetId(event.id, this);
          }
        },
        error => {
          this.disableSaveButton = true;
          this.alertService.clear()
          this.alertService.error(error.statusDescription)
        });
    }
    else {
      this.manageotnService.getOTNListByAssetId(event.id, this);
    }
  }

  removeSelectedAsset(event, data) {
    if (this.selectedAssetList != null && this.selectedAssetList.length > 0) {
      this.validateVendorAssetCombinations();
    }
  }

  //set selected vendor
  selectVendor(value: any): void {
    this.selectedVendor = this.vendorList.filter(x => x.id == value.id);
    if (this.selectedAssetList != null && this.selectedAssetList.length > 0) {
      this.validateVendorAssetCombinations();
    }
  }

  validateVendorAssetCombinations() {
    let seletedAssetsIdList = [];
    if (this.selectedAssetList.length > 0) {
      this.selectedAssetList.forEach((item) => {
        seletedAssetsIdList.push(item.id)
      })
    }
    let data = {
      vendorId: this.selectedVendor[0].id,
      assetIdList: seletedAssetsIdList,
      id: this.edit ? this.otnId : null
    }
    this.manageotnService.checkAssetVendorCombination(JSON.stringify(data)).subscribe(
      res => {
        var payload = res.payload;
        this.dispayAssetVendorCombinationError(payload)
      },
      error => {
        this.disableSaveButton = true;
        this.alertService.clear()
        this.alertService.error(error.statusDescription)
      });
  }

  dispayAssetVendorCombinationError(payload) {
    if (payload != null && payload.length > 0) {
      let message = this.translate.instant('OTN_ADD_EDIT.VENDOR_ASSET_COMBINATION_ERROR');
      let length = payload.length;
      let i = 0;
      payload.forEach(asset => {
        message += asset.tagNo;
        i++;
        if (i < length) {
          message += ", "
        }
      });
      this.disableSaveButton = true;

      this.alertService.clear();
      this.alertService.error(message);
    }
    else {
      this.disableSaveButton = false;
      this.alertService.clear();
    }
  }

  //set selected vendor as null
  removedVendor(value: any): void {
    this.selectedVendor = null;
  }

  public closeOtherElems(element: any) {
    let a = this.selectElem.filter(function (el) {
      return (el != element)
    });

    a.forEach(function (e: SelectComponent) {
      e.hideOptions();
    })
  }

  save() {
    if (this.selectedAssetList == undefined || this.selectedAssetList.length == 0) {
      this.alertService.clear();
      this.alertService.error(this.translate.instant('OTN_ADD_EDIT.ASSET_EMPTY'));
    }
    else if (this.selectedVendor == undefined || this.selectedVendor == null) {
      this.alertService.clear();
      this.alertService.error(this.translate.instant('OTN_ADD_EDIT.FIELD_EMPTY'));
    }
    /* else if (this.targetCompletionDate == undefined || this.targetCompletionDate.toString().trim() == "") {
      this.alertService.clear();
      this.alertService.error(this.translate.instant('OTN_ADD_EDIT.FIELD_EMPTY'));
    } */
    else if (this.otnStatus == undefined || this.otnStatus.trim() == "") {
      this.alertService.clear();
      this.alertService.error(this.translate.instant('OTN_ADD_EDIT.FIELD_EMPTY'));
    }
    else {
      this.loadingHandler.init(1, this.loadingService);
      let data = this.getProcessedData();
      if (this.edit) {
        //call update service
        this.manageotnService.updateOTN(JSON.stringify(data), this.otnId, this);
      }
      else {
        this.manageotnService.addNewOTN(JSON.stringify(data), this);
      }
    }
  }

  //process and create data object to save
  getProcessedData() {
    let seletedAssetsIdList = [];
    if (this.selectedAssetList.length > 0) {
      this.selectedAssetList.forEach((item) => {
        seletedAssetsIdList.push(item.id)
      })
    }

    var vendorId;
    if (this.selectedVendor != null && this.selectVendor.length > 0) {
      vendorId = this.selectedVendor[0].id;
    }

    let data = {
      id: this.otnId,
      assetIdList: seletedAssetsIdList,
      vendorId: vendorId,
      otnstatus: this.otnStatus,
      targetCompletionDate: null
    }
    return data;
  }

  //hides the this modal
  cancel() {
    this.bsModalRef.hide()
  }



  /**
   * This class handles parallel calling of web services. loadingService initiate the loading service which is required to show and hide functionalities.
   * Then, parallelServiceCount indicate the number of parallel services that we want to call. init() method is used to initialize the handler object.
   * handle() method will be called on onSuccess() and onFail() methods of required service calls. This method will hide the loading view when the counter
   * becomes zero which means no more service response to be arrived.
   */
  static LoadingHandler = class {
    parallelServiceCount: number = 0;
    loadingService: LoadingService = null;
    loaded = new EventEmitter<any>()

    init(parallelServiceCount: number, loadingService: LoadingService) {
      this.parallelServiceCount = parallelServiceCount
      this.loadingService = loadingService;
      this.loadingService.showLoading(true, false, 'Loading data', 0);
    }

    handle() {
      --this.parallelServiceCount;
      if (this.parallelServiceCount == 0) {
        this.loadingService.hideLoading();
        this.loaded.emit("");
      }
    }

    getLoadedSub() {
      return this.loaded;
    }
  }

}
