import { Pipe, PipeTransform } from '@angular/core';
/*
 * Reorder an array for a given type
 * Takes string as an argumnet to sort array
*/

@Pipe({
	name: 'orderBy',
	pure: false
})
export class OrderArrayByType implements PipeTransform {
	transform(array: any, type: string): any {
		switch (type) {
			case 'seq':
				array = this.orderBySeqNumber(array)
				break;
			case 'stepNo':
				array = this.orderByStepNumber(array)
				break;
			case 'orderNum': 
				array = this.orderByOrderNumber(array)
				break;
		}
		return array
	}

	private orderBySeqNumber(array: any): any {
		if (array != null && array.length != 0) {
			array.sort((a, b) => {
				if (a.seq < b.seq) {
					return -1;
				} else if (a.seq > b.seq) {
					return 1;
				} else {
					return 0
				}
			});
			return array
		} else {
			return array
		}
	}

	private orderByStepNumber(array: any): any {
		if (array != null && array.length != 0) {
			array.sort((a, b) => {
				if (a.stepNo < b.stepNo) {
					return -1;
				} else if (a.stepNo > b.stepNo) {
					return 1;
				} else {
					return 0
				}
			});
			return array
		} else {
			return array
		}
	}

	private orderByOrderNumber(array: any): any {
		if (array != null && array.length != 0) {
			array.sort((a, b) => {
				if (a.orderNumber < b.orderNumber) {
					return -1;
				} else if (a.orderNumber > b.orderNumber) {
					return 1;
				} else {
					return 0
				}
			});
			return array
		} else {
			return array
		}
	}
}
