<div class="modal-header">
	<!--Title-->
	<h4 class="modal-title pull-left">{{title}}</h4>
	<button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>

<!--Body-->
<div class="modal-body py-2">
	<form ngNativeValidate id="newCourse_form">
		<!--COURSE Name-->
		<div class="form-group">
			<label for="newCourse_courseName">{{ 'NEW_COURSE.FILE_NAME.LABEL' | translate }}</label>
			<div class="badge-required mx-2"></div>
			<input type="text" [(ngModel)]="fileName" name="fileName" class="form-control" id="fileNameId"
				placeholder="{{ 'NEW_COURSE.FILE_NAME.PLACE_HOLDER' | translate }}" required>
		</div>
		<div class="col-sm-6 checkboxes">
			<label>Import from different project </label>
            <input type="checkbox" id="importFromDifferentProject" (change)="checkboxChecked($event)"></div>
			<!-- <div class="col-lg-6"></div> -->
		<div class="form-group">
				<button *ngIf = "!isImport" id="submit" type="submit" class="btn btn-primary btn-sm float-right mb-3"
					(click)="onSubmitButtonClick()">{{ 'Continue' | translate }}</button>
		</div>

		<div *ngIf = "isImport" >
			<div class="form-group">
				<label class="mb-0">Project Name</label>
				<ng-select [active]="selectedProject" [allowClear]="true" (removed)="removedProject($event)"
					(selected)="selectedProjectId($event)" [items]="projectList" name="project" placeholder="Select the project"
					id="id">
				</ng-select>
			</div>
			<div class = "row">
				<div class="col-lg-12 col-12">
				
				<ag-grid-angular style="width: 100%; height: 250px;" class="ag-theme-balham" [rowData]="rowData" [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }" [enableFilter]="true" [floatingFilter]="true" [enableColResize]="true"
				[columnDefs]="columnDefs" [gridOptions]="gridOptions" [pagination]="true" [paginationPageSize]="10" (rowSelected)="onRowSelected($event)" (selectionChanged)="onSelectionChanged($event)" [getRowNodeId]="getRowNodeId" 
				(gridReady)="onGridReady($event)" [overlayLoadingTemplate]="overlayLoadingTemplate" [overlayNoRowsTemplate]="overlayNoRowsTemplate" (cellClicked)='onDelete($event)'>
				</ag-grid-angular>
				
			  </div>
			</div>
			<div class="row media">
				<p><b>Media Files Location: </b><p>
				<p class="folder-path ml-1" >{{mediaFolderPath}}{{fileName}} - Media Files</p>
			</div>
			<div class="form-group">
				<button *ngIf = "isImport" id="submit" type="submit" class="btn btn-primary btn-sm float-right mb-3"
					(click)="onImportButtonClick()">Import and Continue</button>

			</div>
		
		</div>
		<!-- <div class="form-group">
			<button *ngIf = "isImport" id="submit" type="submit" class="btn btn-primary btn-sm float-right mb-3"
				(click)="onImportButtonClick()">Import and Continue</button>
			<button *ngIf = "!isImport" id="submit" type="submit" class="btn btn-primary btn-sm float-right mb-3"
				(click)="onSubmitButtonClick()">{{ 'Continue' | translate }}</button>
		</div> -->
	</form>
</div>

<!-- footer -->
<!--<div class="modal-footer">
	<button id="submit" type="submit" class="btn btn-primary btn-sm"
		(click)="onSubmitButtonClick()">{{ 'SUBMIT' | translate }}</button>
</div>-->