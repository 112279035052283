import { Injectable } from '@angular/core';
import { DocumentTypesComponent } from './document-types/document-types.component';
import { DocumentAttributesComponent } from './document-types/document-attributes/document-attributes.component';
import { UserComponent } from 'src/app/user/user.component';
import { AdduserComponent } from 'src/app/user/adduser/adduser.component';
import { ClientComponent } from 'src/app/client/client.component';
import { AddeditclientComponent } from 'src/app/client/addeditclient/addeditclient.component';
import { ProjectComponent } from 'src/app/project/project.component';
import { AddProjectComponent } from 'src/app/project/add-project/add-project.component';
import { CategorySettingComponent } from './category-setting/category-setting.component';
import { UserRolesComponent } from './user-roles/user-roles.component';

import { EditProjectComponent } from 'src/app/project/edit-project/edit-project.component';
import { EdituserComponent } from 'src/app/user/edituser/edituser.component';
import { ManagePunchlistImagesComponent } from './manage-punchlist-images/manage-punchlist-images.component';
import { ManageWorkflowTemplatesComponent } from './manage-workflow-templates/manage-workflow-templates.component';
import { AddEditWorkflowTemplateComponent } from './manage-workflow-templates/add-edit-workflow-template/add-edit-workflow-template.component';

@Injectable({
	providedIn: 'root'
})
export class AsLoaderService {

	constructor() { }

	public loadComponentByName(compName: string) {
		switch (compName) {
			case 'documenttype':
				return DocumentTypesComponent
			case 'manageattributes':
				return DocumentAttributesComponent
			case 'users':
				return UserComponent
			case 'adduser':
				return AdduserComponent
			case 'edituser':
				return EdituserComponent
			case 'clients':
				return ClientComponent
			case 'aeclient':
				return AddeditclientComponent
			case 'projects':
				return ProjectComponent
			case 'addprojects':
				return AddProjectComponent
			case 'editprojects':
				return EditProjectComponent
			case 'categorysetting': 
				return CategorySettingComponent
			case 'userroles': 
				return UserRolesComponent
			case 'managePLImages': 
				return ManagePunchlistImagesComponent
			case 'manageWfTemplates': 
				return ManageWorkflowTemplatesComponent
			case 'addEditWfTemplate': 
				return AddEditWorkflowTemplateComponent
			default:
				break;
		}
	}
}
