<div>
	<div class="py-2 px-0 mt-2">
		<ul class="col-sm-12 px-0 py-2 list-group mt-2">
			<li *ngIf="enableWorkflows" class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
				[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'workflowTemplates', 'custom-list-item-dark': selectedMenuItem != 'documenttype'}"
				(click)="sideItemClick('workflowTemplates',true)">
				<h6 class="flaot-left ml-2 mb-0 sidebar-list-text">
					<i class="far fa-paste mx-2" aria-hidden="true"></i>
					{{ 'WORKFLOW_CONFIGURATION.WORKFLOW' | translate }}
				</h6>
			</li>
			<ul class="col-sm-12 px-0 py-0 list-group rounded-0">
				<li *ngIf="enableAddWorkflowTemplate" class="list-group-item border-0 rounded-0 px-0 py-2 list-item-bg list-cursor"
					(click)="sideItemClick('addProjectWorkflowTemplate',true)"
					[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'addProjectWorkflowTemplate', 'custom-list-item-dark': selectedMenuItem != 'manageattributes'}">
					<h6 class="flaot-left mb-0 ml-4">
						<i class="far fa-file-edit mx-2" aria-hidden="true"></i>
						{{ 'WORKFLOW_CONFIGURATION.ADD_WORKFLOW_TEMPLATE' | translate }}
					</h6>
				</li>
            </ul>
        </ul>
    </div>
</div>