<div class="container">
	<div class="row">
		<!--Delete button-->
		<div class="col-sm" *ngIf="isDelete">
			<button type="button" [disabled]="false" class="btn btn-sm btn-outline-dark py-0 my-1 px-1 border-0"
				(click)="deleteButtonClick()">
				<i class="far fa-trash-alt" aria-hidden="true"></i>
			</button>
		</div>

		<!--Required button-->
		<div class="col-sm" *ngIf="isRequired">
			<button [disabled]="false" type="button" class="btn btn-sm btn-outline-dark py-0 my-1 px-1 border-0" 
			(click)="toggleButtonClick()">
				<i class="far " [ngClass]="{'fa-check-circle': isR, 'fa-circle': !isR}" aria-hidden="true"></i>
			</button>
		</div>

		<!--up/down button -->
		<div class="col-sm" *ngIf="isUpDownButton">
			<button type="button" tooltip="{{ 'FILES_DATA.ACTIVE_AND_INACTIVE' | translate }}" [disabled]="false"
				class="btn btn-outline-dark btn-sm ml-1 mr-1 mb-1">
				<i class="fas fa-shield-alt" aria-hidden="true"></i>
			</button>
		</div>

		<!--attribute text-->
		<div class="col-sm" *ngIf="isAttributeName">
			<p>{{value}}</p>
		</div>

		<div class="col-sm"></div>
		<!--attribute type drop-down-->
		<!--<div class="col-sm" *ngIf="isAttributeType">
      //<ng-select [items]="attributeTypeList" bindLabel="name" bindValue="id" [(ngModel)]="selectedAttributeType"></ng-select>
      <select class="custom-select">
        <div *ngFor="let val of value">
        </div>
      </select>
    </div>-->

		<!--category drop down-->
		<div class="col-sm" *ngIf="isCategory"></div>
	</div>
</div>