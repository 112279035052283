<div class="page-container with-right-panel ">
    <nav class="page-header">
      <div class="row">
        <div class="col mobile-hide">
          <p class="mb-2 h5">
            <b>{{ 'WF_TEMPLATES.HEADER' | translate }}</b>
          </p>
        </div>
      </div>
    </nav>
    <div class="container role-list-wrapper pl-0">
        <ag-grid-angular #agGrid style="width: 100%; height: 375px;" class="ag-theme-balham" [rowData]="rowData" [sideBar]="{ toolPanels: ['columns'] }"
        [columnDefs]="columnDefs" [gridOptions]="gridOptions" [pagination]="true" [paginationPageSize]="10"
        [overlayLoadingTemplate]="overlayLoadingTemplate" [overlayNoRowsTemplate]="overlayNoRowsTemplate" [modules]="modules"
        [getRowNodeId]="getRowNodeId" (gridReady)="onGridReady($event)" [suppressCellSelection]="true" >
        </ag-grid-angular>
    </div>
</div>