import { OnInit, Component, EventEmitter } from '@angular/core';
import { AlertService } from 'src/app/util/alert/alert.service';
import { SharedService } from 'src/app/util/shared.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-wf-sidebar',
	templateUrl: './wf-sidebar.component.html',
	styleUrls: ['./wf-sidebar.component.scss'],
	outputs: ['selectSideBarItem']
})
export class WfSidebarComponent implements OnInit{
    public selectedMenuItem: string = 'workflowTemplates'
    selectSideBarItem = new EventEmitter<any>()
    enableWorkflows: boolean = false;
    enableAddWorkflowTemplate: boolean = false;
    private subsVar: Subscription
    
    constructor(private sharedService: SharedService, private alertService: AlertService, private translate: TranslateService){
        /*translate.addLangs(["es", "en"]);
        translate.setDefaultLang("en");
        let browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');*/
        this.enableDisableSideBarItem()
    }

    ngOnInit(){

    }

    ngAfterContentInit(){
		this.subsVar = this.sharedService.viewSideBarResponse().subscribe(
			(options) => {
				this.sideItemClick(options.item, false);		
			}
		)
	}

    public sideItemClick(selectedItem: string, isItemClicked: boolean) {
		console.log('[sideItemClick] (selectedItem) ' + selectedItem);
		if (isItemClicked) {
			this.alertService.clear();
		} 
		if (selectedItem == "workflowTemplates") {
			//selectedItem = selectedItem.substring(0,8)
			console.log(selectedItem)
		} else if(selectedItem == "addProjectWorkflowTemplate") {
			console.log(selectedItem)
		} else {
			this.sharedService.setFileId("-1");
		}
		this.sharedService.setLoadedComponent(selectedItem)
		this.selectedMenuItem = selectedItem
		this.selectSideBarItem.emit(
			{
				selectedItem: selectedItem
			}
		) 
    }
    
    public enableDisableSideBarItem(){
        //this.checkCapabilities()
        this.enableWorkflows = true;
        this.enableAddWorkflowTemplate = true;
    }

    public checkCapabilities(){

    }
}