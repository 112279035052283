import { HttpClient, HttpErrorResponse, HttpEventType, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CommonUtil } from 'src/app/util/common/common-util';
import { UserVariable } from 'src/app/util/common/user-variable';
import { ServiceUrls } from 'src/app/util/service-urls';
import { WsCallback } from 'src/app/util/ws-callback.interface';
import { WsResponse } from 'src/app/util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';

@Injectable({
  providedIn: 'root'
})
export class ManageotnService {
  otnComp:WsCallback;
  private rowData: any = [];
  loadTableData = new EventEmitter<any>();
  updateUIafterOTNGenerate  = new EventEmitter<any>();
  updateUIafterApproval = new EventEmitter<any>();
  
  constructor(
    private http: HttpClient,
    private commonUtil: CommonUtil
  ) { }

  public setRowData(rowData: any) {
    this.rowData = rowData;
  }
  public setOTNcomp(otnComp:WsCallback){
    this.otnComp = otnComp
  }

  public loadTableDataRequest(){
    this.loadTableData.emit()
  }
  public loadTableDataResponse(){
    return this.loadTableData;
  }
  public  updateUIafterOTNGenerateRequest(){
      this.updateUIafterOTNGenerate.emit()
  }
  public  updateUIafterOTNGenerateResponse(){
      return this.updateUIafterOTNGenerate;
  }
  public updateUIafterApprovalRequest(otnId, uploaded){
      this.updateUIafterApproval.emit({otnId, uploaded})
  }
  public updateUIafterApprovalResponse(){
      return this.updateUIafterApproval;
  }

  /**
   * Get all assets used in commissioning
   * @param wsCallBack  callback class
   */
  getAssetList(wsCallBack: WsCallback) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    const options = { params: new HttpParams().set("token", token) }
    this.http.get(ServiceUrls.GET_ASSET_LIST, options).subscribe(
      data => {
        var modified = JSON.parse(JSON.stringify(data));
        var res = new WsResponse(
          modified.status.description,
          modified.status.code,
          modified.status.name,
          modified.payload);
        wsCallBack.onSuccess(res, WsType.GET_ASSETS);
      },
      error => {
        if (error.status != '') {
          var val = (error as HttpErrorResponse).error;
          var modified = JSON.parse(JSON.stringify(val));
          var res = new WsResponse(
            modified.status.description,
            modified.status.code,
            modified.status.name,
            modified.payload);
          wsCallBack.onFail(res, WsType.GET_ASSETS);
        } else {
          //browser related issues
          var res = new WsResponse("Unknown error happened");
          wsCallBack.onFail(res, WsType.GET_ASSETS);
        }
      }
    );
  }

  /**
   * Get all assets used in commissioning with otn target completion date
   * @param wsCallBack  callback class
   */
   getAssetListWithOTNTargetCompletionDate(wsCallBack: WsCallback) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    const options = { params: new HttpParams().set("token", token) }
    this.http.get(ServiceUrls.GET_ASSET_LIST_WITH_OTN_TARGET_COMPLETION_DATE, options).subscribe(
      data => {
        var modified = JSON.parse(JSON.stringify(data));
        var res = new WsResponse(
          modified.status.description,
          modified.status.code,
          modified.status.name,
          modified.payload);
        wsCallBack.onSuccess(res, WsType.GET_ASSETS);
      },
      error => {
        if (error.status != '') {
          var val = (error as HttpErrorResponse).error;
          var modified = JSON.parse(JSON.stringify(val));
          var res = new WsResponse(
            modified.status.description,
            modified.status.code,
            modified.status.name,
            modified.payload);
          wsCallBack.onFail(res, WsType.GET_ASSETS);
        } else {
          //browser related issues
          var res = new WsResponse("Unknown error happened");
          wsCallBack.onFail(res, WsType.GET_ASSETS);
        }
      }
    );
  }

  /**
   * Get all active vendors
   * @param wsCallBack  callback class
   */
  public getAllVendors(wsCallBack: WsCallback) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    this.http.get(ServiceUrls.getAllVendors(token)).subscribe(
      data => {
        var modified = JSON.parse(JSON.stringify(data));
        var res = new WsResponse(
          modified.status.description,
          modified.status.code,
          modified.status.name,
          modified.payload);
        wsCallBack.onSuccess(res, WsType.GET_ALL_VENDORS);
      },
      error => {
        if (error.status != '') {
          var val = (error as HttpErrorResponse).error;
          var modified = JSON.parse(JSON.stringify(val));
          var res = new WsResponse(
            modified.status.description,
            modified.status.code,
            modified.status.name,
            modified.payload);
          wsCallBack.onFail(res, WsType.GET_ALL_VENDORS);
        } else {
          //browser related issues
          var res = new WsResponse("Unknown error happened");
          wsCallBack.onFail(res, WsType.GET_ALL_VENDORS);
        }
      }
    );
  }

  /**
   * Get OTN List By Asset ID
   *
   * @param assetId
   * @param callBack
   */
  getOTNListByAssetId(assetId: string, callBack: WsCallback) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    this.http.get(ServiceUrls.getOTNByAssetId(token, assetId)).subscribe(
      data => {
        var modified = JSON.parse(JSON.stringify(data));
        var res = new WsResponse(
          modified.status.code,
          modified.status.name,
          modified.status.description,
          modified.payload);
        callBack.onSuccess(res, WsType.GET_OTN_BY_ASSET_ID);
      },
      error => {
        var modified = JSON.parse(JSON.stringify(error));
        var res = new WsResponse(
          modified.status.code,
          modified.status.name,
          modified.status.description,
          modified.payload);
        callBack.onSuccess(res, WsType.GET_OTN_BY_ASSET_ID);
      });
  }

  public addNewOTN(data: string, wsCallBack: WsCallback) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    this.http.post(ServiceUrls.addNewOTN(token), data).subscribe(
      data => {
        var modified = JSON.parse(JSON.stringify(data));
        var res = new WsResponse(
          modified.status.description,
          modified.status.code,
          modified.status.name,
          modified.payload);
        wsCallBack.onSuccess(res, WsType.ADD_NEW_OTN);
      },
      error => {
        if (error.status != '') {
          var val = (error as HttpErrorResponse).error;
          var modified = JSON.parse(JSON.stringify(val));
          var res = new WsResponse(
            modified.status.description,
            modified.status.code,
            modified.status.name,
            modified.payload);
          wsCallBack.onFail(res, WsType.ADD_NEW_OTN);
        } else {
          //browser related issues
          var res = new WsResponse("Unknown error happened");
          wsCallBack.onFail(res, WsType.ADD_NEW_OTN);
        }
      }
    );
  }

  /**
   * Update OTN by ID
   *
   * @param data
   * @param wsCallBack
   */
  updateOTN(data: string, otnId: string, wsCallBack: WsCallback) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    let url = ServiceUrls.updateOTN(token, otnId);
    this.http.put(url,data).subscribe(
        data => {
          var modified = JSON.parse(JSON.stringify(data));
          var res = new WsResponse(
              modified.status.description,
              modified.status.code,
              modified.status.name,
              modified.payload);
          wsCallBack.onSuccess(res, WsType.UPDATE_OTN);
        },
        error => {
          if (error.status != '') {
            var val = (error as HttpErrorResponse).error;
            var modified = JSON.parse(JSON.stringify(val));
            var res = new WsResponse(
                modified.status.description,
                modified.status.code,
                modified.status.name,
                modified.payload);
            wsCallBack.onFail(res, WsType.UPDATE_OTN);
          } else {
            var res = new WsResponse("Unknown error happened");
            wsCallBack.onFail(res, WsType.UPDATE_OTN);
          }
        }
    );
  }

  public searchOTNList(json) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    let url = this.getSearchOTNURL(token);
    return this.http.post(url, json).
      pipe(
        map(response => {
          var modified = JSON.parse(JSON.stringify(response));
          return new WsResponse(
            modified.status.description,
            modified.status.name,
            modified.status.code,
            modified.payload);
        }),
        catchError(error => {
          var modified = JSON.parse(JSON.stringify(error));
          var res = new WsResponse(
            modified.error.status.description,
            modified.error.status.name,
            modified.error.status.code,
            modified.error.payload);
          return throwError(res)
        })
      );
  }

  public getSearchOTNURL(token: String) {
    let url = ServiceUrls.GET_OTN_SEARCH_RESULTS + '?token=' + token;
    return url;
  }

  public isAssetsHasIRN(assetIds) {
    let token = UserVariable.getUserToken();
    const options = { params: new HttpParams().set("assetList", assetIds) }

    return this.http.get(ServiceUrls.isAssetsHasIRN(token), options).
      pipe(
        map(response => {
          var modified = JSON.parse(JSON.stringify(response));
          return new WsResponse(
            modified.status.description,
            modified.status.code,
            modified.status.name,
            modified.payload);
        }),
        catchError(error => {
          var modified = JSON.parse(JSON.stringify(error.error));
          var res = new WsResponse(
            modified.status.description,
            modified.status.code,
            modified.status.name,
            modified.payload);
          return throwError(res);
        })
      );
  }

  public isAllPunchlistItemsClosedByCategoryAndAsset(category, assetIds) {
    let token = UserVariable.getUserToken();
    const options = { params: new HttpParams().set("category", category).set("assetList", assetIds) }

    return this.http.get(ServiceUrls.isAllPunchlistItemsClosedByCategoryAndAsset(token), options).
      pipe(
        map(response => {
          var modified = JSON.parse(JSON.stringify(response));
          return new WsResponse(
            modified.status.description,
            modified.status.code,
            modified.status.name,
            modified.payload);
        }),
        catchError(error => {
          var modified = JSON.parse(JSON.stringify(error.error));
          var res = new WsResponse(
            modified.status.description,
            modified.status.code,
            modified.status.name,
            modified.payload);
          return throwError(res);
        })
      );
  }

  /**
   * Get MCC List By OTN ID
   *
   * @param otnId
   * @param callBack
   */
  getMCCListByOTNId(otnId: string, callBack: WsCallback) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    this.http.get(ServiceUrls.getMCCListByOTNId(token, otnId)).subscribe(
      data => {
        var modified = JSON.parse(JSON.stringify(data));
        var res = new WsResponse(
          modified.status.code,
          modified.status.name,
          modified.status.description,
          modified.payload);
        callBack.onSuccess(res, WsType.GET_MCC_DATA_BY_OTN_ID);
      },
      error => {
        var modified = JSON.parse(JSON.stringify(error));
        var res = new WsResponse(
          modified.status.code,
          modified.status.name,
          modified.status.description,
          modified.payload);
        callBack.onSuccess(res, WsType.GET_MCC_DATA_BY_OTN_ID);
      });
  }

  /**
   * Get IRN data
   *
   * @param callBack
   */
  getOTNDataByOTNId(otnId, callBack: WsCallback) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }

    this.http.get(ServiceUrls.getOTNByOTNId(token, otnId)).subscribe(
      data => {
        var modified = JSON.parse(JSON.stringify(data));
        var res = new WsResponse(
          modified.status.code,
          modified.status.name,
          modified.status.description,
          modified.payload);
        callBack.onSuccess(res, WsType.GET_OTN_BY_OTN_ID);
      },
      error => {
        var modified = JSON.parse(JSON.stringify(error));
        var res = new WsResponse(
          modified.status.code,
          modified.status.name,
          modified.status.description,
          modified.payload);
        callBack.onSuccess(res, WsType.GET_OTN_BY_OTN_ID);
      });
  }

  deleteOrRevokeOTN(otnId: string, action: string ) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    let data = {
      id : otnId,
    }
    let url  = ServiceUrls.deleteOrRevokeOTN(token, otnId)
    return this.http.post(url, JSON.stringify(data)).
    subscribe(
        data => {
          var modified = JSON.parse(JSON.stringify(data));
          var res = new WsResponse(
              modified.status.description,
              modified.status.name,
              modified.status.code,
              {
                id: otnId,
                action: action
              });
          this.otnComp.onSuccess(res, WsType.DELETE_REVOKE_OTN);
        },
        error => {
          var modified = JSON.parse(JSON.stringify(error));
          var res = new WsResponse(
              modified.error.status.description,
              modified.error.status.name,
              modified.error.status.code,
              {
                id: otnId,
                action: action
              });
          this.otnComp.onFail(res, WsType.DELETE_REVOKE_OTN);
        }
    );
  }

  approveOTN(data : any, callback: WsCallback) {
    let token = UserVariable.getUserToken();
    if(!this.commonUtil.validateToken(token)){
      return;
    }

    let url = ServiceUrls.approveOTN(token);
    this.http.post(url, JSON.stringify(data)).
    subscribe(
        data => {
          var modified = JSON.parse(JSON.stringify(data));
          var res = new WsResponse(
              modified.status.description,
              modified.status.name,
              modified.status.code,
              modified.payload);
          callback.onSuccess(res, WsType.APPROVE_OTN);
        },
        error => {
          var modified = JSON.parse(JSON.stringify(error));
          var res = new WsResponse(
              modified.error.status.description,
              modified.error.status.name,
              modified.error.status.code,
              modified.error.payload);
          callback.onFail(res, WsType.APPROVE_OTN);
        }
    );
  }

  public uploadFile(fileUploadData: FormData, callBack: WsCallback) {
    var token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    this.http.post(ServiceUrls.ADD_NEW_OTN_DOCUMENT, fileUploadData, {
      reportProgress: true,
      observe: 'events'
    }).subscribe(
        event => {
          if (event.type == HttpEventType.UploadProgress) {
            //progress
          } else if (event.type == HttpEventType.Response) {
            var modified = JSON.parse(JSON.stringify(event.body));
            var res = new WsResponse(
                modified.status.description,
                modified.status.code,
                modified.status.name,
                modified.payload);
            callBack.onSuccess(res, WsType.ADD_NEW_OTN_DOCUMENT);
          }
        },
        error => {
          if (error.error.payload != undefined) {
            var val = (error as HttpErrorResponse).error;
            var modified = JSON.parse(JSON.stringify(val));
            var res = new WsResponse(
                modified.status.description,
                modified.status.code,
                modified.status.name,
                modified.payload);
            callBack.onFail(res, WsType.ADD_NEW_OTN_DOCUMENT);
          } else {
            var res: WsResponse;
            if (error.error.actionErrors != undefined) {
              res = new WsResponse(error.statusText + ' - ' + error.error.actionErrors);
            } else {
              res = new WsResponse(error.message);
            }
            callBack.onFail(res, WsType.ADD_NEW_OTN_DOCUMENT);
          }
        }
    );
  }
  
  public checkAssetVendorCombination(data) {
    let token = UserVariable.getUserToken();
    if (!this.commonUtil.validateToken(token)) {
      return;
    }
    return this.http.post(ServiceUrls.checkAssetVendorCombination(token), data).
      pipe(
        map(response => {
          var modified = JSON.parse(JSON.stringify(response));
          return new WsResponse(
            modified.status.description,
            modified.status.name,
            modified.status.code,
            modified.payload);
        }),
        catchError(error => {
          var modified = JSON.parse(JSON.stringify(error));
          var res = new WsResponse(
            modified.error.status.description,
            modified.error.status.name,
            modified.error.status.code,
            modified.error.payload);
          return throwError(res)
        })
      );
  }
  
  downloadSentFile(projectId: string, otnId: string) {
    return this.http.get(ServiceUrls.DOWNLOAD_FILE,
        {
          responseType: "blob", params: new HttpParams().set("projectId", projectId)
              .set("documentId", otnId).set("mode", "ViewCommOutput").set("type", "OnshoreTurnoverNotice").set("fileName", "OTN")
        });
  }

  downloadApprovalFile(projectId: string, otnId: string) {
    return this.http.get(ServiceUrls.DOWNLOAD_FILE,
        {
          responseType: "blob", params: new HttpParams().set("projectId", projectId).set("documentId", otnId)
              .set("mode", "ViewApprovedOutput").set("type", "OnshoreTurnoverNotice").set("fileName", "OTN")
        });
  }
}
