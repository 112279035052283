<!--Alert-->
<alert class="floating-alert"></alert>

<!-- header -->
<nav id="navPanel" class="bg-light border-bottom box-shadow fixed-top navbar-expand-lg" #navPanel>
	<app-header [minimalOption]="true"></app-header>
</nav>

<div class="container-fluid">
	<main role="main" class="col-md-12 px-0" #main [style.padding-top.px]="paddingtop">
		<!-- title and operatingdata button -->
		<div class="col-sm-12 px-0 align-self-end">
			<div class="row align-items-center">
				<div class="col-sm-8">
					<h4 class="" id="classification_title">
						{{ 'OPERATING_DATA.OPERATING_DATA' | translate }}
					</h4>
				</div>
				<div class="col-sm-4">
					<!-- Operating data button -->
					<button type="button" class="btn btn-sm btn-primary float-right mx-2"
						(click)="addOperatingDataButtonClick(template)">{{ 'OPERATING_DATA.ADD_OPERATING_DATA' |
						translate }}
					</button>
				</div>
			</div>
			<hr class="mt-0 mb-3" />
		</div>
		<div class="row">
			<div class="col-sm-12">
				<ag-grid-angular style="width: 100%; height: 410px;" class="ag-theme-balham" [rowData]="rowData"  [modules]="modules" [sideBar]="{ toolPanels: ['columns'] }"
					[gridOptions]="gridOptions" [columnDefs]="columnDefs" [enableRangeSelection]="true" [defaultColDef]="defaultColDef"
					[pagination]="true" [paginationPageSize]="10" [overlayLoadingTemplate]="overlayLoadingTemplate"
					[overlayNoRowsTemplate]="overlayNoRowsTemplate" (gridReady)="onGridReady($event)"
					[frameworkComponents]="frameworkComponents" [suppressCellSelection]="true" [rowDragManaged]="false"
					[animateRows]="true">
				</ag-grid-angular>
			</div>
		</div>
	</main>
</div>

<!--Operating Data Add Modal-->
<ng-template #template>
	<div class="modal-header">
		<h5 class="modal-title">{{ 'OPERATING_DATA.ADD_OPERATING_DATA' | translate }}</h5>
		<button type="button" class="close pull-right" aria-label="Close" (click)="closeOperatingDataModal()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<form>
			<div class="form-group">
				<label for="operatingDataName">{{ 'OPERATING_DATA.OPERATING_DATA_NAME' | translate }} : </label>
				<div class="badge-required mx-2"></div>
				<input type="text" class="form-control" id="operatingDataName" name="operatingDataName"
					placeholder="Operating Data Name" [(ngModel)]="newOperatingDataName" maxlength="200">
			</div>
			<div class="form-group">
				<label for="operatingDataType">{{ 'OPERATING_DATA.OPERATING_DATA_TYPE' | translate }} : </label>
				<div class="badge-required mx-2"></div>
				<!--Item select input-->
				<ng-select #selectedOperatingDataType [items]="operatingDataTypeList" id="selectedOperatingDataType"
					[options]="gridOptions" (selected)="selectedOperatingDataTypeChange($event)"
					[(ngModel)]="selectedOperatingData" name="selectedOperatingDataType" bindLabel="text" autofocus
					bindValue="id">
				</ng-select>
			</div>
			<div class="form-group" *ngIf="valueListDataType">
				<button type="button" class="btn btn-sm btn-primary float-right mx-2" (click)="addValueListValue()"
									style="margin-right:10px"> <i class="fas fa-plus"></i></button>
				<table *ngIf="valueListDataType">
					<thead>
						<tr>
							<th>{{ 'OPERATING_DATA.OPERATING_DATA_VALUE_LIST_VALUES' | translate }}
							</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let field of fieldArray; let i = index">
							<td>
								<div class="input-group">
									<div class="input-group-prepend">
										<div (click)="deleteValueListItem(i)" class="input-group-text">
											<i class="fa fa-times"></i>
										</div>
									</div>
									<input class="form-control" type="text" id= i
										name="{{field.name}}" placeholder="Value List Item"
										(change)="onValueListItemChange($event.target.value, i)">
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</form>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary btn-sm" (click)="addButtonClick()">Add</button>
		<button type="button" class="btn btn-secondary btn-sm" (click)="closeOperatingDataModal()">Cancel</button>
	</div>
</ng-template>

<!--Operating Data Update Modal-->
<ng-template #update_template>
	<div class="modal-header">
		<h5 class="modal-title">Edit Operating Data</h5>
		<button type="button" class="close" aria-label="Close" (click)="closeOperatingDataModal()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="form-group">
			<label for="operatingDataName">Operating Data Name : </label>
			<div class="badge-required mx-2"></div>
			<input type="text" class="form-control" id="operatingDataName" name="operatingDataName" placeholder=""
				[(ngModel)]="newOperatingDataName" maxlength="200">
		</div>
		<div class="form-group">
			<label for="operatingDataType">Type : </label>
			<div class="badge-required mx-2"></div>
			<!--Item select input-->
			<ng-select #selectedOperatingDataType [items]="operatingDataTypeList" id="selectedOperatingDataType" [options]="gridOptions" [disabled] = "valueListDataType && editOrignalvalueListDataType"
				name="operatingDataSelect" bindLabel="text" autofocus bindValue="id" (ngModelChange)="onChange($event)"
				[(ngModel)]="selectedOperatingData" (selected)="selectedOperatingDataTypeChange($event)">
			</ng-select>
		</div>
		<div class="form-group" *ngIf="valueListDataType">
			<button type="button" class="btn btn-sm btn-primary float-right mx-2" (click)="addValueListValue()"
								style="margin-right:10px"> <i class="fas fa-plus"></i></button>
			<table *ngIf="valueListDataType">
				<thead>
					<tr>
						<th>{{ 'OPERATING_DATA.OPERATING_DATA_VALUE_LIST_VALUES' | translate }}
						</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let valueList of fieldArray; let i = index">
						
						 <td>
							<div class="input-group">
								<div class="input-group-prepend">
									<div (click)="deleteValueListItem(i)" class="input-group-text">
										<i class="fa fa-times"></i>
									</div>
								</div>
								<input class="form-control" type="text" id = i
									name={{valueList.valueListItem}} [(ngModel)]=valueList.valueListItem
									(change)="onValueListItemChange($event.target.value,i )">
							</div>
						</td> 
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary btn-sm" (click)="updateButtonClick()">Submit</button>
		<button type="button" class="btn btn-secondary btn-sm" (click)="closeOperatingDataModal()">Cancel</button>
	</div>
</ng-template>