<div class="modal-header">
  <h4 class="modal-title pull-left">{{ 'PDF_ADD_TO_PROCEDURE_STORE.ADD_TO_PROCEDURE_STORE' | translate}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form >	
    <div class="form-group row" >
      <label for="DocuNumber" class="col-sm-2 col-form-label">{{ 'PDF_ADD_TO_PROCEDURE_STORE.DOCUMENT_NO' | translate }}</label>         
      <div class="col-sm-10">
         <input type="text" class="form-control" id="DocuNumber" [(ngModel)]="DocumentNo" name="DocNo">
      </div>
     </div>
     <div class="form-group row" >
      <label for="DocuTitle" class="col-sm-2 col-form-label">{{ 'PDF_ADD_TO_PROCEDURE_STORE.DOCUMENT_TITLE' | translate }}</label>         
      <div class="col-sm-10">
         <input type="text" class="form-control" id="DocuTitle" [(ngModel)]="DocumentTitle" name="DocTitle">
      </div>
     </div>
     <div class="form-group row" >
      <label for="DocuSubTitle" class="col-sm-2 col-form-label">{{ 'PDF_ADD_TO_PROCEDURE_STORE.SUB_TITLE' | translate }}</label>         
      <div class="col-sm-10">
         <input type="text" class="form-control" id="DocuSubTitle" [(ngModel)]="DocumentSubTitle" name="DocSubTitle">
      </div>
     </div>
     <div class="form-group row" >
      <label for="DocuProject" class="col-sm-2 col-form-label">{{ 'PDF_ADD_TO_PROCEDURE_STORE.PROJECT' | translate }}</label>         
      <div class="col-sm-10">
         <input type="text" class="form-control" id="DocuProject" [(ngModel)]="DocumentProject" name="DocProject">
      </div>
     </div>
     <div class="form-group row" >
      <label for="DocuOutputFileName" class="col-sm-2 col-form-label">{{ 'PDF_ADD_TO_PROCEDURE_STORE.OUTPUT_FILE_NAME' | translate }}</label>         
      <div class="col-sm-10">
         <input [disabled]=true type="text" class="form-control" id="DocuOutputFileName" [(ngModel)]="DocumentOutputFileName" name="DocOutputFileName">
      </div>
     </div>
     <div class="form-group row" >
      <label for="DocuVersionNo" class="col-sm-2 col-form-label">{{ 'PDF_ADD_TO_PROCEDURE_STORE.VERSION_NO' | translate }}</label>         
      <div class="col-sm-10">
         <input type="text" class="form-control" id="DocuVersionNo" [(ngModel)]="DocumentVersionNo" name="DocVersionNo">
      </div>
     </div>
     <div class="form-group row" >
      <label for="DocuFacility" class="col-sm-2 col-form-label">{{ 'PDF_ADD_TO_PROCEDURE_STORE.FACILITY' | translate }}</label>         
      <div class="col-sm-10">
        <ng-select  [active]="selectedFacility" [allowClear]="true"
        (removed)="removedFacility($event)" (selected)="selectedFacilityId($event)" [items]="facilityList"
        placeholder="{{ 'PDF_ADD_TO_PROCEDURE_STORE.SELECT_FACILITY' | translate }}"
        name="facilityId" id="facilityId" [disabled]="facilityDisable">
      </ng-select>
      </div>
     </div>
     <div class="form-group row" >
      <label for="DocuInReview" class="col-sm-2 col-form-label">{{ 'PDF_ADD_TO_PROCEDURE_STORE.IN_REVIEW' | translate }}</label>         
      <div class="col-sm-10">
         <input type="checkbox" id="DocuInReview" [(ngModel)]="DocumentInReview" name="DocInReview">
      </div>
     </div>
     <div class="form-group row" >
      <label for="DocuSystem" class="col-sm-2 col-form-label">{{ 'PDF_ADD_TO_PROCEDURE_STORE.SYSTEM' | translate }}</label>         
      <div class="col-sm-10">
        <ng-select  [active]="selectedSystem"
         (selected)="selectedSystemId($event)"  [items]="systemList"
        placeholder="{{ 'SYSTEM_ADD_EDIT.SELECT_SYSTEM' | translate }}" name="systemId" id="DocuSystem" [disabled]="systemDisable">
      </ng-select>
      </div>
     </div>
     <div class="form-group row" >
      <label for="DocuNotes" class="col-sm-2 col-form-label">{{ 'PDF_ADD_TO_PROCEDURE_STORE.NOTES' | translate }}</label>         
      <div class="col-sm-10">
         <input type="text" class="form-control" id="DocuNotes" [(ngModel)]="DocumentNotes" name="DocNotes">
      </div>
     </div>
     <div class="form-group row" >
      <label for="DocuKeywords" class="col-sm-2 col-form-label">{{ 'PDF_ADD_TO_PROCEDURE_STORE.KEYWORDS' | translate }}</label>         
      <div class="col-sm-10">
         <input type="text" class="form-control" id="DocuKeywords" [(ngModel)]="DocumentKeywords" name="DocKeywords">
      </div>
     </div>
     <div class="form-group row" >
      <label for="DocuSaveParVal" class="col-sm-2 col-form-label">{{ 'PDF_ADD_TO_PROCEDURE_STORE.SAVE_PARAMETER_VALUES' | translate }}</label>         
      <div class="col-sm-10">
         <input type="checkbox" [(ngModel)]="DocumentParVal" id="DocuSaveParVal"  name="DocParVal">
      </div>
     </div>
     <div class="form-group row" >
      <label for="DocuDraft" class="col-sm-2 col-form-label">{{ 'PDF_ADD_TO_PROCEDURE_STORE.DRAFT_DOCUMENT' | translate }}</label>         
      <div class="col-sm-10">
         <input type="checkbox"  [(ngModel)]="DocumentDraft" id="DocuDraft"  name="DocNo">
      </div>
     </div>
  </form>
  <!-- <app-files-revisions></app-files-revisions> -->
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="addToProcedureStore(); bsModalRef.hide()">{{ 'PDF_ADD_TO_PROCEDURE_STORE.ADD_TO_PROCEDURE_STORE' | translate}}</button>
  <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="bsModalRef.hide()">
    {{ 'PDF_ADD_TO_PROCEDURE_STORE.CANCEL' | translate}}
  </button>
</div>